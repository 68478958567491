import { CityState, initialState } from './city.state';
import { Action, createReducer } from '@ngrx/store';
import { cityReducersGeneratedFunctions } from './city-generated.reducer';

const cityReducersFunctions = [...cityReducersGeneratedFunctions];

const cityReducer = createReducer(initialState, ...cityReducersFunctions);

export function reducer(state: CityState | undefined, action: Action) {
  return cityReducer(state, action);
}
