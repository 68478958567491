import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidencePriceGridActions from './residence-price-grid.actions';
import { adapter, initialState, ResidencePriceGridState } from './residence-price-grid.state';
import { Dictionary } from '@ngrx/entity';
import { ResidencePriceGridEntityState } from '@_model/interfaces/residence-price-grid.model';

export const residencePriceGridReducersGeneratedFunctions: ReducerTypes<
  ResidencePriceGridState,
  readonly ActionCreator[]
>[] = [
  on(ResidencePriceGridActions.getOneResidencePriceGrid, (state: ResidencePriceGridState) =>
    setLoadingsState(state, true)
  ),
  on(ResidencePriceGridActions.getManyResidencePriceGrids, (state: ResidencePriceGridState) =>
    setLoadingsState(state, true)
  ),
  on(ResidencePriceGridActions.upsertOneResidencePriceGrid, (state: ResidencePriceGridState) =>
    setLoadingsState(state, true)
  ),

  on(
    ResidencePriceGridActions.upsertManyResidencePriceGridsSuccess,
    (state: ResidencePriceGridState, { residencePriceGrids }) =>
      adapter.upsertMany(residencePriceGrids, setLoadingsState(state, false))
  ),
  on(ResidencePriceGridActions.deleteOneResidencePriceGrid, (state: ResidencePriceGridState) =>
    setLoadingsState(state, true)
  ),
  on(
    ResidencePriceGridActions.deleteOneResidencePriceGridSuccess,
    (state: ResidencePriceGridState, { idResidencePriceGrid }) =>
      adapter.removeOne(idResidencePriceGrid, setLoadingsState(state, false))
  ),
  on(ResidencePriceGridActions.clearActive, (state: ResidencePriceGridState) => ({ ...state, actives: [] })),
  on(ResidencePriceGridActions.addManyActives, (state: ResidencePriceGridState, { idResidencePriceGrids }) => ({
    ...state,
    actives: state.actives.concat(idResidencePriceGrids)
  })),
  on(ResidencePriceGridActions.deleteOneActive, (state: ResidencePriceGridState, { idResidencePriceGrid }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idResidencePriceGrid)
  })),

  on(ResidencePriceGridActions.clearStore, () => initialState),

  on(
    ResidencePriceGridActions.addManyStratalotPriceListSuccess,
    (state: ResidencePriceGridState, { idResidencePriceGrid, idStratalotPriceLists }) => {
      if (!state.entities[idResidencePriceGrid]) {
        return state;
      }
      const stratalotPriceLists = (state.entities[idResidencePriceGrid]?.stratalotPriceLists as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidencePriceGrid]: {
            ...state.entities[idResidencePriceGrid],
            stratalotPriceLists: stratalotPriceLists.concat(
              idStratalotPriceLists.filter(id => stratalotPriceLists.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidencePriceGridActions.deleteManyStratalotPriceListSuccess,
    (state: ResidencePriceGridState, { idStratalotPriceLists, idResidencePriceGrids }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidencePriceGrids.reduce((entities, idResidencePriceGrid) => {
            if (!state.entities[idResidencePriceGrid]?.stratalotPriceLists) {
              return entities;
            }
            entities[idResidencePriceGrid] = {
              ...state.entities[idResidencePriceGrid],
              stratalotPriceLists: (state.entities[idResidencePriceGrid]?.stratalotPriceLists as number[])?.filter(
                (idStratalotPriceList: number) =>
                  !idStratalotPriceLists.some((id: number) => id === idStratalotPriceList)
              )
            } as ResidencePriceGridEntityState;
            return entities;
          }, {} as Dictionary<ResidencePriceGridEntityState>)
        }
      };
    }
  ),

  on(
    ResidencePriceGridActions.addManyStratalotPriceSuccess,
    (state: ResidencePriceGridState, { idResidencePriceGrid, idStratalotPrices }) => {
      if (!state.entities[idResidencePriceGrid]) {
        return state;
      }
      const stratalotPrices = (state.entities[idResidencePriceGrid]?.stratalotPrices as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidencePriceGrid]: {
            ...state.entities[idResidencePriceGrid],
            stratalotPrices: stratalotPrices.concat(idStratalotPrices.filter(id => stratalotPrices.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    ResidencePriceGridActions.deleteManyStratalotPriceSuccess,
    (state: ResidencePriceGridState, { idStratalotPrices, idResidencePriceGrids }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidencePriceGrids.reduce((entities, idResidencePriceGrid) => {
            if (!state.entities[idResidencePriceGrid]?.stratalotPrices) {
              return entities;
            }
            entities[idResidencePriceGrid] = {
              ...state.entities[idResidencePriceGrid],
              stratalotPrices: (state.entities[idResidencePriceGrid]?.stratalotPrices as number[])?.filter(
                (idStratalotPrice: number) => !idStratalotPrices.some((id: number) => id === idStratalotPrice)
              )
            } as ResidencePriceGridEntityState;
            return entities;
          }, {} as Dictionary<ResidencePriceGridEntityState>)
        }
      };
    }
  ),

  on(
    ResidencePriceGridActions.addManyResidencePriceGridModifierSuccess,
    (state: ResidencePriceGridState, { idResidencePriceGrid, idResidencePriceGridModifiers }) => {
      if (!state.entities[idResidencePriceGrid]) {
        return state;
      }
      const residencePriceGridModifiers =
        (state.entities[idResidencePriceGrid]?.residencePriceGridModifiers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidencePriceGrid]: {
            ...state.entities[idResidencePriceGrid],
            residencePriceGridModifiers: residencePriceGridModifiers.concat(
              idResidencePriceGridModifiers.filter(id => residencePriceGridModifiers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidencePriceGridActions.deleteManyResidencePriceGridModifierSuccess,
    (state: ResidencePriceGridState, { idResidencePriceGridModifiers, idResidencePriceGrids }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidencePriceGrids.reduce((entities, idResidencePriceGrid) => {
            if (!state.entities[idResidencePriceGrid]?.residencePriceGridModifiers) {
              return entities;
            }
            entities[idResidencePriceGrid] = {
              ...state.entities[idResidencePriceGrid],
              residencePriceGridModifiers: (
                state.entities[idResidencePriceGrid]?.residencePriceGridModifiers as number[]
              )?.filter(
                (idResidencePriceGridModifier: number) =>
                  !idResidencePriceGridModifiers.some((id: number) => id === idResidencePriceGridModifier)
              )
            } as ResidencePriceGridEntityState;
            return entities;
          }, {} as Dictionary<ResidencePriceGridEntityState>)
        }
      };
    }
  ),

  on(
    ResidencePriceGridActions.addManyResidencePriceGridHistorySuccess,
    (state: ResidencePriceGridState, { idResidencePriceGrid, idResidencePriceGridHistories }) => {
      if (!state.entities[idResidencePriceGrid]) {
        return state;
      }
      const residencePriceGridHistories =
        (state.entities[idResidencePriceGrid]?.residencePriceGridHistories as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidencePriceGrid]: {
            ...state.entities[idResidencePriceGrid],
            residencePriceGridHistories: residencePriceGridHistories.concat(
              idResidencePriceGridHistories.filter(id => residencePriceGridHistories.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidencePriceGridActions.deleteManyResidencePriceGridHistorySuccess,
    (state: ResidencePriceGridState, { idResidencePriceGridHistories, idResidencePriceGrids }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidencePriceGrids.reduce((entities, idResidencePriceGrid) => {
            if (!state.entities[idResidencePriceGrid]?.residencePriceGridHistories) {
              return entities;
            }
            entities[idResidencePriceGrid] = {
              ...state.entities[idResidencePriceGrid],
              residencePriceGridHistories: (
                state.entities[idResidencePriceGrid]?.residencePriceGridHistories as number[]
              )?.filter(
                (idResidencePriceGridHistory: number) =>
                  !idResidencePriceGridHistories.some((id: number) => id === idResidencePriceGridHistory)
              )
            } as ResidencePriceGridEntityState;
            return entities;
          }, {} as Dictionary<ResidencePriceGridEntityState>)
        }
      };
    }
  ),

  on(
    ResidencePriceGridActions.addResidenceSuccess,
    (state: ResidencePriceGridState, { idResidencePriceGrid, idResidence }) => {
      if (!state.entities[idResidencePriceGrid]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidencePriceGrid]: {
            ...state.entities[idResidencePriceGrid],
            residence: idResidence
          }
        }
      };
    }
  ),

  on(
    ResidencePriceGridActions.deleteManyResidenceSuccess,
    (state: ResidencePriceGridState, { idResidences, idResidencePriceGrids }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidencePriceGrids.reduce((entities, idResidencePriceGrid) => {
            if (!state.entities[idResidencePriceGrid]?.residence) {
              return entities;
            }
            entities[idResidencePriceGrid] = {
              ...state.entities[idResidencePriceGrid],
              residence: idResidences.some(
                (idResidence: number) => idResidence === state.entities[idResidencePriceGrid]?.residence
              )
                ? undefined
                : state.entities[idResidencePriceGrid]?.residence
            } as ResidencePriceGridEntityState;
            return entities;
          }, {} as Dictionary<ResidencePriceGridEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidencePriceGridState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidencePriceGridState {
  return { ...state, isLoaded, isLoading };
}
