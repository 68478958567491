import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidenceContactActions from './residence-contact.actions';
import { adapter, initialState, ResidenceContactState } from './residence-contact.state';
import { Dictionary } from '@ngrx/entity';
import { ResidenceContactEntityState } from '@_model/interfaces/residence-contact.model';

export const residenceContactReducersGeneratedFunctions: ReducerTypes<
  ResidenceContactState,
  readonly ActionCreator[]
>[] = [
  on(ResidenceContactActions.getOneResidenceContact, (state: ResidenceContactState) => setLoadingsState(state, true)),
  on(ResidenceContactActions.getManyResidenceContacts, (state: ResidenceContactState) => setLoadingsState(state, true)),
  on(ResidenceContactActions.upsertOneResidenceContact, (state: ResidenceContactState) =>
    setLoadingsState(state, true)
  ),

  on(
    ResidenceContactActions.upsertManyResidenceContactsSuccess,
    (state: ResidenceContactState, { residenceContacts }) =>
      adapter.upsertMany(residenceContacts, setLoadingsState(state, false))
  ),
  on(ResidenceContactActions.deleteOneResidenceContact, (state: ResidenceContactState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceContactActions.deleteOneResidenceContactSuccess, (state: ResidenceContactState, { idResidenceContact }) =>
    adapter.removeOne(idResidenceContact, setLoadingsState(state, false))
  ),
  on(ResidenceContactActions.clearActive, (state: ResidenceContactState) => ({ ...state, actives: [] })),
  on(ResidenceContactActions.addManyActives, (state: ResidenceContactState, { idResidenceContacts }) => ({
    ...state,
    actives: state.actives.concat(idResidenceContacts)
  })),
  on(ResidenceContactActions.deleteOneActive, (state: ResidenceContactState, { idResidenceContact }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idResidenceContact)
  })),

  on(ResidenceContactActions.clearStore, () => initialState),

  on(
    ResidenceContactActions.addResidenceSuccess,
    (state: ResidenceContactState, { idResidenceContact, idResidence }) => {
      if (!state.entities[idResidenceContact]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceContact]: {
            ...state.entities[idResidenceContact],
            residence: idResidence
          }
        }
      };
    }
  ),

  on(
    ResidenceContactActions.deleteManyResidenceSuccess,
    (state: ResidenceContactState, { idResidences, idResidenceContacts }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceContacts.reduce((entities, idResidenceContact) => {
            if (!state.entities[idResidenceContact]?.residence) {
              return entities;
            }
            entities[idResidenceContact] = {
              ...state.entities[idResidenceContact],
              residence: idResidences.some(
                (idResidence: number) => idResidence === state.entities[idResidenceContact]?.residence
              )
                ? undefined
                : state.entities[idResidenceContact]?.residence
            } as ResidenceContactEntityState;
            return entities;
          }, {} as Dictionary<ResidenceContactEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceContactActions.addOrganizationThirdPartySuccess,
    (state: ResidenceContactState, { idResidenceContact, idOrganizationThirdParty }) => {
      if (!state.entities[idResidenceContact]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceContact]: {
            ...state.entities[idResidenceContact],
            organizationThirdParty: idOrganizationThirdParty
          }
        }
      };
    }
  ),

  on(
    ResidenceContactActions.deleteManyOrganizationThirdPartySuccess,
    (state: ResidenceContactState, { idOrganizationThirdParties, idResidenceContacts }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceContacts.reduce((entities, idResidenceContact) => {
            if (!state.entities[idResidenceContact]?.organizationThirdParty) {
              return entities;
            }
            entities[idResidenceContact] = {
              ...state.entities[idResidenceContact],
              organizationThirdParty: idOrganizationThirdParties.some(
                (idOrganizationThirdParty: number) =>
                  idOrganizationThirdParty === state.entities[idResidenceContact]?.organizationThirdParty
              )
                ? undefined
                : state.entities[idResidenceContact]?.organizationThirdParty
            } as ResidenceContactEntityState;
            return entities;
          }, {} as Dictionary<ResidenceContactEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidenceContactState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidenceContactState {
  return { ...state, isLoaded, isLoading };
}
