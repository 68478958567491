import { RepositoryService } from '@_services/api/repository.service';
import { StratalotAssociation } from '@_model/interfaces/stratalot-association.model';
import { Observable } from 'rxjs';

export class GeneratedStratalotAssociationApiService {
  constructor(protected repo: RepositoryService) {}

  public getStratalotAssociations(params?: any): Observable<StratalotAssociation[]> {
    return this.repo.getData<StratalotAssociation[]>('stratalot-association', params);
  }

  public getStratalotAssociation(params: {
    idStratalotAssociation: number;
    params?: any;
  }): Observable<StratalotAssociation> {
    return this.repo.getData<StratalotAssociation>(
      'stratalot-association/' + params.idStratalotAssociation,
      params.params
    );
  }

  public addStratalotAssociation(
    stratalotAssociation: Partial<StratalotAssociation>
  ): Observable<StratalotAssociation> {
    return this.repo.create<StratalotAssociation>('stratalot-association', stratalotAssociation);
  }

  public updateStratalotAssociation(
    stratalotAssociation: Partial<StratalotAssociation>
  ): Observable<StratalotAssociation> {
    return this.repo.update('stratalot-association', stratalotAssociation);
  }

  public deleteStratalotAssociation(idStratalotAssociation: number): Observable<number> {
    return this.repo.delete('stratalot-association/' + +idStratalotAssociation);
  }
}
