import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StepProgress, StepProgressEntityState } from '@_model/interfaces/step-progress.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { Step, StepEntityState } from '@_model/interfaces/step.model';
import { StepProgressFamily, StepProgressFamilyEntityState } from '@_model/interfaces/step-progress-family.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, stepProgressFeatureKey, StepProgressState } from './step-progress.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const stepProgressRelations: string[] = ['stratalots', 'steps', 'stepProgressesFamilies'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectStepProgressState = createFeatureSelector<StepProgressState>(stepProgressFeatureKey);

export const selectIsLoadedStepProgress = createSelector(
  selectStepProgressState,
  (state: StepProgressState) => state.isLoaded
);

export const selectIsLoadingStepProgress = createSelector(
  selectStepProgressState,
  (state: StepProgressState) => state.isLoading
);

export const selectIsReadyStepProgress = createSelector(
  selectStepProgressState,
  (state: StepProgressState) => !state.isLoading
);

export const selectIsReadyAndLoadedStepProgress = createSelector(
  selectStepProgressState,
  (state: StepProgressState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const StepProgressModel: SelectorModel = {
  name: 'stepProgresses',
  getSelector: selectAllStepProgressesDictionary,
  isReady: selectIsReadyStepProgress
};

export const selectStepProgressesEntities = createSelector(selectStepProgressState, selectEntities);

export const selectStepProgressesArray = createSelector(selectStepProgressState, selectAll);

export const selectIdStepProgressesActive = createSelector(
  selectStepProgressState,
  (state: StepProgressState) => state.actives
);

const stepProgressesInObject = (stepProgresses: Dictionary<StepProgressEntityState>) => ({ stepProgresses });

const selectStepProgressesEntitiesDictionary = createSelector(selectStepProgressesEntities, stepProgressesInObject);

const selectAllStepProgressesObject = createSelector(selectStepProgressesEntities, stepProgresses => {
  return hydrateAll({ stepProgresses });
});

const selectOneStepProgressDictionary = (idStepProgress: number) =>
  createSelector(selectStepProgressesEntities, stepProgresses => {
    return { stepProgresses: { [idStepProgress]: stepProgresses[idStepProgress] } };
  });

const selectOneStepProgressDictionaryWithoutChild = (idStepProgress: number) =>
  createSelector(selectStepProgressesEntities, stepProgresses => {
    return { stepProgress: stepProgresses[idStepProgress] };
  });

const selectActiveStepProgressesEntities = createSelector(
  selectIdStepProgressesActive,
  selectStepProgressesEntities,
  (actives: number[], stepProgresses: Dictionary<StepProgressEntityState>) =>
    getStepProgressesFromActives(actives, stepProgresses)
);

function getStepProgressesFromActives(
  actives: number[],
  stepProgresses: Dictionary<StepProgressEntityState>
): Dictionary<StepProgressEntityState> {
  return actives.reduce((acc, idActive) => {
    if (stepProgresses[idActive]) {
      acc[idActive] = stepProgresses[idActive];
    }
    return acc;
  }, {} as Dictionary<StepProgressEntityState>);
}

const selectAllStepProgressesSelectors: Dictionary<Selector> = {};
export function selectAllStepProgresses(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<StepProgress>(
      schema,
      selectAllStepProgressesSelectors,
      selectStepProgressesEntitiesDictionary,
      getRelationSelectors,
      stepProgressRelations,
      hydrateAll,
      'stepProgress'
    );
  } else {
    return selectAllStepProgressesObject;
  }
}

export function selectAllStepProgressesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'stepProgresses'
): Selector {
  return createSelector(selectAllStepProgresses(schema), result => {
    const res = { [customKey]: {} as Dictionary<StepProgressEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.stepProgresses.length; i++) {
      res[customKey][result.stepProgresses[i].idStepProgress] = result.stepProgresses[i];
    }
    return res;
  });
}

export function selectOneStepProgress(schema: SelectSchema = {}, idStepProgress: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneStepProgressDictionary(idStepProgress)];
    selectors.push(...getRelationSelectors(schema, stepProgressRelations, 'stepProgress'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneStepProgressDictionaryWithoutChild(idStepProgress);
  }
}

export function selectActiveStepProgresses(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveStepProgressesEntities, stepProgresses => ({ stepProgresses }))
  ];
  selectors.push(...getRelationSelectors(schema, stepProgressRelations, 'stepProgress'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  stepProgresses: Dictionary<StepProgressEntityState>;
  steps?: Dictionary<StepEntityState>;
  stepProgressesFamilies?: Dictionary<StepProgressFamilyEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { stepProgresses: (StepProgress | null)[] } {
  const { stepProgresses, steps, stepProgressesFamilies, stratalots } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    stepProgresses: Object.keys(stepProgresses).map(idStepProgress =>
      hydrate(stepProgresses[idStepProgress] as StepProgressEntityState, steps, stepProgressesFamilies, stratalots)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { stepProgress: StepProgressEntityState | null } {
  const { stepProgresses, steps, stepProgressesFamilies, stratalots } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const stepProgress = Object.values(stepProgresses)[0];
  return { stepProgress: hydrate(stepProgress as StepProgressEntityState, steps, stepProgressesFamilies, stratalots) };
}

function hydrate(
  stepProgress: StepProgressEntityState,
  stepEntities?: Dictionary<StepEntityState>,
  stepProgressFamilyEntities?: Dictionary<StepProgressFamilyEntityState>,
  stratalotEntities?: Dictionary<StratalotEntityState>
): StepProgress | null {
  if (!stepProgress) {
    return null;
  }

  const stepProgressHydrated: StepProgressEntityState = { ...stepProgress };
  if (stepEntities) {
    stepProgressHydrated.step = stepEntities[stepProgress.step as number] as Step;
  } else {
    delete stepProgressHydrated.step;
  }
  if (stepProgressFamilyEntities) {
    stepProgressHydrated.stepProgressFamily = stepProgressFamilyEntities[
      stepProgress.stepProgressFamily as number
    ] as StepProgressFamily;
  } else {
    delete stepProgressHydrated.stepProgressFamily;
  }

  if (stratalotEntities) {
    stepProgressHydrated.stratalots = ((stepProgressHydrated.stratalots as number[]) || []).map(
      id => stratalotEntities[id]
    ) as Stratalot[];
  } else {
    delete stepProgressHydrated.stratalots;
  }

  return stepProgressHydrated as StepProgress;
}
