import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as AppState from '@_store/index.reducers';
import { GeneratedStepProgressService } from './step-progress-generated.service';
import { Actions } from '@ngrx/effects';
import { StepProgress } from '@_shared/models/interfaces/step-progress.model';

@Injectable({
  providedIn: 'root'
})
export class StepProgressService extends GeneratedStepProgressService {
  stepProgresses: StepProgress[];

  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }
}
