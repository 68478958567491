import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationLeadTodo } from '@_model/interfaces/organization-lead-todo.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationLeadTodoApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationLeadTodos(params?: any): Observable<OrganizationLeadTodo[]> {
    return this.repo.getData<OrganizationLeadTodo[]>('organization-lead-todo', params);
  }

  public getOrganizationLeadTodo(params: {
    idOrganizationLeadTodo: number;
    params?: any;
  }): Observable<OrganizationLeadTodo> {
    return this.repo.getData<OrganizationLeadTodo>(
      'organization-lead-todo/' + params.idOrganizationLeadTodo,
      params.params
    );
  }

  public addOrganizationLeadTodo(
    organizationLeadTodo: Partial<OrganizationLeadTodo>
  ): Observable<OrganizationLeadTodo> {
    return this.repo.create<OrganizationLeadTodo>('organization-lead-todo', organizationLeadTodo);
  }

  public updateOrganizationLeadTodo(
    organizationLeadTodo: Partial<OrganizationLeadTodo>
  ): Observable<OrganizationLeadTodo> {
    return this.repo.update('organization-lead-todo', organizationLeadTodo);
  }

  public deleteOrganizationLeadTodo(idOrganizationLeadTodo: number): Observable<number> {
    return this.repo.delete('organization-lead-todo/' + +idOrganizationLeadTodo);
  }
}
