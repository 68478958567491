import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { LeadStratalot, LeadStratalotEntityState } from '@_model/interfaces/lead-stratalot.model';
import { LeadStratalotApiService } from '@_services/api/lead-stratalot-api.service';
import * as leadStratalotActions from '@_store/lead-stratalot/lead-stratalot.actions';
import { getActionsToNormalizeLeadStratalot } from '@_config/store/normalization.generated';
import { selectLeadStratalotState } from './lead-stratalot-generated.selectors';
import * as leadActions from '@_store/lead/lead.actions';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';

export interface LeadStratalotRelationsIds {
  lead?: number;
  stratalot?: number;
}

export function getDefaultAddLeadStratalotActions(
  leadStratalot: LeadStratalotEntityState,
  ids?: LeadStratalotRelationsIds
): Action[] {
  const actions: Action[] = [
    leadStratalotActions.normalizeManyLeadStratalotsAfterUpsert({ leadStratalots: [leadStratalot] })
  ];

  if (ids?.lead) {
    actions.push(
      leadActions.addManyLeadStratalotSuccess({
        idLead: ids.lead,
        idLeadStratalots: [leadStratalot.idLeadStratalot]
      })
    );
    actions.push(
      leadStratalotActions.addLeadSuccess({
        idLeadStratalot: leadStratalot.idLeadStratalot,
        idLead: ids.lead
      })
    );
  }

  if (ids?.stratalot) {
    actions.push(
      stratalotActions.addManyLeadStratalotSuccess({
        idStratalot: ids.stratalot,
        idLeadStratalots: [leadStratalot.idLeadStratalot]
      })
    );
    actions.push(
      leadStratalotActions.addStratalotSuccess({
        idLeadStratalot: leadStratalot.idLeadStratalot,
        idStratalot: ids.stratalot
      })
    );
  }

  return actions;
}

export function getDefaultDeleteLeadStratalotActions(leadStratalot: LeadStratalotEntityState): Action[] {
  const actions: Action[] = [
    leadStratalotActions.deleteOneLeadStratalotSuccess({ idLeadStratalot: leadStratalot.idLeadStratalot })
  ];

  if (leadStratalot.lead) {
    actions.push(
      leadActions.deleteManyLeadStratalotSuccess({
        idLeadStratalots: [leadStratalot.idLeadStratalot],
        idLeads: [leadStratalot.lead as number]
      })
    );
  }

  if (leadStratalot.stratalot) {
    actions.push(
      stratalotActions.deleteManyLeadStratalotSuccess({
        idLeadStratalots: [leadStratalot.idLeadStratalot],
        idStratalots: [leadStratalot.stratalot as number]
      })
    );
  }

  return actions;
}

export class GeneratedLeadStratalotEffects {
  constructor(
    protected actions$: Actions,
    protected leadStratalotApiService: LeadStratalotApiService,
    protected store$: Store<AppState>
  ) {}

  getManyLeadStratalots$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadStratalotActions.getManyLeadStratalots),
      switchMap(({ params }) =>
        this.leadStratalotApiService.getLeadStratalots(params).pipe(
          map((leadStratalots: LeadStratalot[]) => {
            return leadStratalotActions.normalizeManyLeadStratalotsAfterUpsert({ leadStratalots });
          }),
          catchError(error => of(leadStratalotActions.leadStratalotsFailure({ error })))
        )
      )
    );
  });

  getOneLeadStratalot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadStratalotActions.getOneLeadStratalot),
      switchMap(idLeadStratalot =>
        this.leadStratalotApiService.getLeadStratalot(idLeadStratalot).pipe(
          map((leadStratalot: LeadStratalot) => {
            return leadStratalotActions.normalizeManyLeadStratalotsAfterUpsert({ leadStratalots: [leadStratalot] });
          }),
          catchError(error => of(leadStratalotActions.leadStratalotsFailure({ error })))
        )
      )
    );
  });

  upsertOneLeadStratalot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadStratalotActions.upsertOneLeadStratalot),
      concatMap(
        ({ leadStratalot, ids }: { leadStratalot: Partial<LeadStratalot>; ids?: LeadStratalotRelationsIds }) => {
          if (leadStratalot.idLeadStratalot) {
            return this.leadStratalotApiService.updateLeadStratalot(leadStratalot).pipe(
              map((leadStratalotReturned: LeadStratalot) => {
                return leadStratalotActions.normalizeManyLeadStratalotsAfterUpsert({
                  leadStratalots: [leadStratalotReturned]
                });
              }),
              catchError(error => of(leadStratalotActions.leadStratalotsFailure({ error })))
            );
          } else {
            return this.leadStratalotApiService.addLeadStratalot(leadStratalot).pipe(
              mergeMap((leadStratalotReturned: LeadStratalot) =>
                getDefaultAddLeadStratalotActions(leadStratalotReturned, ids)
              ),
              catchError(error => of(leadStratalotActions.leadStratalotsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneLeadStratalot$ = createEffect(() => {
    const selectLeadStratalotState$ = this.store$.select(selectLeadStratalotState);
    return this.actions$.pipe(
      ofType(leadStratalotActions.deleteOneLeadStratalot),
      withLatestFrom(selectLeadStratalotState$),
      concatMap(([{ idLeadStratalot }, state]) =>
        this.leadStratalotApiService.deleteLeadStratalot(idLeadStratalot).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteLeadStratalotActions(state.entities[idLeadStratalot] as LeadStratalotEntityState),
              leadStratalotActions.deleteOneLeadStratalot.type
            )
          ]),
          catchError(error => of(leadStratalotActions.leadStratalotsFailure({ error })))
        )
      )
    );
  });

  normalizeManyLeadStratalotsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadStratalotActions.normalizeManyLeadStratalotsAfterUpsert),
      concatMap(({ leadStratalots }) => {
        const actions: Action[] = getActionsToNormalizeLeadStratalot(leadStratalots, StoreActionType.upsert);
        return [getMultiAction(actions, '[LeadStratalot] Normalization After Upsert Success')];
      })
    );
  });
}
