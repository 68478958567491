import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceForecastValue } from '@_model/interfaces/residence-forecast-value.model';
import { Observable } from 'rxjs';

export class GeneratedResidenceForecastValueApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidenceForecastValues(params?: any): Observable<ResidenceForecastValue[]> {
    return this.repo.getData<ResidenceForecastValue[]>('residence-forecast-value', params);
  }

  public getResidenceForecastValue(params: {
    idResidenceForecastValue: number;
    params?: any;
  }): Observable<ResidenceForecastValue> {
    return this.repo.getData<ResidenceForecastValue>(
      'residence-forecast-value/' + params.idResidenceForecastValue,
      params.params
    );
  }

  public addResidenceForecastValue(
    residenceForecastValue: Partial<ResidenceForecastValue>
  ): Observable<ResidenceForecastValue> {
    return this.repo.create<ResidenceForecastValue>('residence-forecast-value', residenceForecastValue);
  }

  public updateResidenceForecastValue(
    residenceForecastValue: Partial<ResidenceForecastValue>
  ): Observable<ResidenceForecastValue> {
    return this.repo.update('residence-forecast-value', residenceForecastValue);
  }

  public deleteResidenceForecastValue(idResidenceForecastValue: number): Observable<number> {
    return this.repo.delete('residence-forecast-value/' + +idResidenceForecastValue);
  }
}
