import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationResidenceTodoRuleActions from './organization-residence-todo-rule.actions';
import { adapter, initialState, OrganizationResidenceTodoRuleState } from './organization-residence-todo-rule.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationResidenceTodoRuleEntityState } from '@_model/interfaces/organization-residence-todo-rule.model';

export const organizationResidenceTodoRuleReducersGeneratedFunctions: ReducerTypes<
  OrganizationResidenceTodoRuleState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationResidenceTodoRuleActions.getOneOrganizationResidenceTodoRule,
    (state: OrganizationResidenceTodoRuleState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationResidenceTodoRuleActions.getManyOrganizationResidenceTodoRules,
    (state: OrganizationResidenceTodoRuleState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationResidenceTodoRuleActions.upsertOneOrganizationResidenceTodoRule,
    (state: OrganizationResidenceTodoRuleState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationResidenceTodoRuleActions.upsertManyOrganizationResidenceTodoRulesSuccess,
    (state: OrganizationResidenceTodoRuleState, { organizationResidenceTodoRules }) =>
      adapter.upsertMany(organizationResidenceTodoRules, setLoadingsState(state, false))
  ),
  on(
    OrganizationResidenceTodoRuleActions.deleteOneOrganizationResidenceTodoRule,
    (state: OrganizationResidenceTodoRuleState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationResidenceTodoRuleActions.deleteOneOrganizationResidenceTodoRuleSuccess,
    (state: OrganizationResidenceTodoRuleState, { idOrganizationResidenceTodoRule }) =>
      adapter.removeOne(idOrganizationResidenceTodoRule, setLoadingsState(state, false))
  ),
  on(OrganizationResidenceTodoRuleActions.clearActive, (state: OrganizationResidenceTodoRuleState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationResidenceTodoRuleActions.addManyActives,
    (state: OrganizationResidenceTodoRuleState, { idOrganizationResidenceTodoRules }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationResidenceTodoRules)
    })
  ),
  on(
    OrganizationResidenceTodoRuleActions.deleteOneActive,
    (state: OrganizationResidenceTodoRuleState, { idOrganizationResidenceTodoRule }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationResidenceTodoRule)
    })
  ),

  on(OrganizationResidenceTodoRuleActions.clearStore, () => initialState),

  on(
    OrganizationResidenceTodoRuleActions.addManyOrganizationResidenceTodoSuccess,
    (state: OrganizationResidenceTodoRuleState, { idOrganizationResidenceTodoRule, idOrganizationResidenceTodos }) => {
      if (!state.entities[idOrganizationResidenceTodoRule]) {
        return state;
      }
      const organizationResidenceTodos =
        (state.entities[idOrganizationResidenceTodoRule]?.organizationResidenceTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodoRule]: {
            ...state.entities[idOrganizationResidenceTodoRule],
            organizationResidenceTodos: organizationResidenceTodos.concat(
              idOrganizationResidenceTodos.filter(id => organizationResidenceTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoRuleActions.deleteManyOrganizationResidenceTodoSuccess,
    (state: OrganizationResidenceTodoRuleState, { idOrganizationResidenceTodos, idOrganizationResidenceTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodoRules.reduce((entities, idOrganizationResidenceTodoRule) => {
            if (!state.entities[idOrganizationResidenceTodoRule]?.organizationResidenceTodos) {
              return entities;
            }
            entities[idOrganizationResidenceTodoRule] = {
              ...state.entities[idOrganizationResidenceTodoRule],
              organizationResidenceTodos: (
                state.entities[idOrganizationResidenceTodoRule]?.organizationResidenceTodos as number[]
              )?.filter(
                (idOrganizationResidenceTodo: number) =>
                  !idOrganizationResidenceTodos.some((id: number) => id === idOrganizationResidenceTodo)
              )
            } as OrganizationResidenceTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoRuleActions.addManyOrganizationResidenceTodoAppliedRuleSuccess,
    (
      state: OrganizationResidenceTodoRuleState,
      { idOrganizationResidenceTodoRule, idOrganizationResidenceTodoAppliedRules }
    ) => {
      if (!state.entities[idOrganizationResidenceTodoRule]) {
        return state;
      }
      const organizationResidenceTodoAppliedRules =
        (state.entities[idOrganizationResidenceTodoRule]?.organizationResidenceTodoAppliedRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodoRule]: {
            ...state.entities[idOrganizationResidenceTodoRule],
            organizationResidenceTodoAppliedRules: organizationResidenceTodoAppliedRules.concat(
              idOrganizationResidenceTodoAppliedRules.filter(
                id => organizationResidenceTodoAppliedRules.indexOf(id) < 0
              )
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoRuleActions.deleteManyOrganizationResidenceTodoAppliedRuleSuccess,
    (
      state: OrganizationResidenceTodoRuleState,
      { idOrganizationResidenceTodoAppliedRules, idOrganizationResidenceTodoRules }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodoRules.reduce((entities, idOrganizationResidenceTodoRule) => {
            if (!state.entities[idOrganizationResidenceTodoRule]?.organizationResidenceTodoAppliedRules) {
              return entities;
            }
            entities[idOrganizationResidenceTodoRule] = {
              ...state.entities[idOrganizationResidenceTodoRule],
              organizationResidenceTodoAppliedRules: (
                state.entities[idOrganizationResidenceTodoRule]?.organizationResidenceTodoAppliedRules as number[]
              )?.filter(
                (idOrganizationResidenceTodoAppliedRule: number) =>
                  !idOrganizationResidenceTodoAppliedRules.some(
                    (id: number) => id === idOrganizationResidenceTodoAppliedRule
                  )
              )
            } as OrganizationResidenceTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoRuleActions.addOrganizationSuccess,
    (state: OrganizationResidenceTodoRuleState, { idOrganizationResidenceTodoRule, idOrganization }) => {
      if (!state.entities[idOrganizationResidenceTodoRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodoRule]: {
            ...state.entities[idOrganizationResidenceTodoRule],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoRuleActions.deleteManyOrganizationSuccess,
    (state: OrganizationResidenceTodoRuleState, { idOrganizations, idOrganizationResidenceTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodoRules.reduce((entities, idOrganizationResidenceTodoRule) => {
            if (!state.entities[idOrganizationResidenceTodoRule]?.organization) {
              return entities;
            }
            entities[idOrganizationResidenceTodoRule] = {
              ...state.entities[idOrganizationResidenceTodoRule],
              organization: idOrganizations.some(
                (idOrganization: number) =>
                  idOrganization === state.entities[idOrganizationResidenceTodoRule]?.organization
              )
                ? undefined
                : state.entities[idOrganizationResidenceTodoRule]?.organization
            } as OrganizationResidenceTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoRuleActions.addOrganizationSaleCategorySuccess,
    (state: OrganizationResidenceTodoRuleState, { idOrganizationResidenceTodoRule, idOrganizationSaleCategory }) => {
      if (!state.entities[idOrganizationResidenceTodoRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodoRule]: {
            ...state.entities[idOrganizationResidenceTodoRule],
            organizationSaleCategory: idOrganizationSaleCategory
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoRuleActions.deleteManyOrganizationSaleCategorySuccess,
    (state: OrganizationResidenceTodoRuleState, { idOrganizationSaleCategories, idOrganizationResidenceTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodoRules.reduce((entities, idOrganizationResidenceTodoRule) => {
            if (!state.entities[idOrganizationResidenceTodoRule]?.organizationSaleCategory) {
              return entities;
            }
            entities[idOrganizationResidenceTodoRule] = {
              ...state.entities[idOrganizationResidenceTodoRule],
              organizationSaleCategory: idOrganizationSaleCategories.some(
                (idOrganizationSaleCategory: number) =>
                  idOrganizationSaleCategory ===
                  state.entities[idOrganizationResidenceTodoRule]?.organizationSaleCategory
              )
                ? undefined
                : state.entities[idOrganizationResidenceTodoRule]?.organizationSaleCategory
            } as OrganizationResidenceTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoRuleActions.addSaleCategoryFamilySuccess,
    (state: OrganizationResidenceTodoRuleState, { idOrganizationResidenceTodoRule, idSaleCategoryFamily }) => {
      if (!state.entities[idOrganizationResidenceTodoRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodoRule]: {
            ...state.entities[idOrganizationResidenceTodoRule],
            saleCategoryFamily: idSaleCategoryFamily
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoRuleActions.deleteManySaleCategoryFamilySuccess,
    (state: OrganizationResidenceTodoRuleState, { idFamilySaleCategories, idOrganizationResidenceTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodoRules.reduce((entities, idOrganizationResidenceTodoRule) => {
            if (!state.entities[idOrganizationResidenceTodoRule]?.saleCategoryFamily) {
              return entities;
            }
            entities[idOrganizationResidenceTodoRule] = {
              ...state.entities[idOrganizationResidenceTodoRule],
              saleCategoryFamily: idFamilySaleCategories.some(
                (idSaleCategoryFamily: number) =>
                  idSaleCategoryFamily === state.entities[idOrganizationResidenceTodoRule]?.saleCategoryFamily
              )
                ? undefined
                : state.entities[idOrganizationResidenceTodoRule]?.saleCategoryFamily
            } as OrganizationResidenceTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoRuleActions.addCompanySuccess,
    (state: OrganizationResidenceTodoRuleState, { idOrganizationResidenceTodoRule, idCompany }) => {
      if (!state.entities[idOrganizationResidenceTodoRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodoRule]: {
            ...state.entities[idOrganizationResidenceTodoRule],
            company: idCompany
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoRuleActions.deleteManyCompanySuccess,
    (state: OrganizationResidenceTodoRuleState, { idCompanies, idOrganizationResidenceTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodoRules.reduce((entities, idOrganizationResidenceTodoRule) => {
            if (!state.entities[idOrganizationResidenceTodoRule]?.company) {
              return entities;
            }
            entities[idOrganizationResidenceTodoRule] = {
              ...state.entities[idOrganizationResidenceTodoRule],
              company: idCompanies.some(
                (idCompany: number) => idCompany === state.entities[idOrganizationResidenceTodoRule]?.company
              )
                ? undefined
                : state.entities[idOrganizationResidenceTodoRule]?.company
            } as OrganizationResidenceTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoRuleEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationResidenceTodoRuleState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationResidenceTodoRuleState {
  return { ...state, isLoaded, isLoading };
}
