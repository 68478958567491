import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { ResidencePriceGrid, ResidencePriceGridEntityState } from '@_model/interfaces/residence-price-grid.model';
import { ResidencePriceGridApiService } from '@_services/api/residence-price-grid-api.service';
import * as residencePriceGridActions from '@_store/residence-price-grid/residence-price-grid.actions';
import { getActionsToNormalizeResidencePriceGrid } from '@_config/store/normalization.generated';
import { selectResidencePriceGridState } from './residence-price-grid-generated.selectors';
import * as residenceActions from '@_store/residence/residence.actions';
import * as stratalotPriceListActions from '@_store/stratalot-price-list/stratalot-price-list.actions';
import { StratalotPriceList } from '@_model/interfaces/stratalot-price-list.model';
import * as stratalotPriceActions from '@_store/stratalot-price/stratalot-price.actions';
import { StratalotPrice } from '@_model/interfaces/stratalot-price.model';
import * as residencePriceGridModifierActions from '@_store/residence-price-grid-modifier/residence-price-grid-modifier.actions';
import { ResidencePriceGridModifier } from '@_model/interfaces/residence-price-grid-modifier.model';
import * as residencePriceGridHistoryActions from '@_store/residence-price-grid-history/residence-price-grid-history.actions';
import { ResidencePriceGridHistory } from '@_model/interfaces/residence-price-grid-history.model';

export interface ResidencePriceGridRelationsIds {
  stratalotPriceLists?: number | number[];
  stratalotPrices?: number | number[];
  residencePriceGridModifiers?: number | number[];
  residencePriceGridHistories?: number | number[];
  residence?: number;
}

export function getDefaultAddResidencePriceGridActions(
  residencePriceGrid: ResidencePriceGridEntityState,
  ids?: ResidencePriceGridRelationsIds
): Action[] {
  const actions: Action[] = [
    residencePriceGridActions.normalizeManyResidencePriceGridsAfterUpsert({ residencePriceGrids: [residencePriceGrid] })
  ];

  if (ids?.residence) {
    actions.push(
      residenceActions.addManyResidencePriceGridSuccess({
        idResidence: ids.residence,
        idResidencePriceGrids: [residencePriceGrid.idResidencePriceGrid]
      })
    );
    actions.push(
      residencePriceGridActions.addResidenceSuccess({
        idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
        idResidence: ids.residence
      })
    );
  }

  if (ids?.stratalotPriceLists) {
    if (!Array.isArray(ids.stratalotPriceLists)) {
      actions.push(
        stratalotPriceListActions.upsertOneStratalotPriceList({
          stratalotPriceList: {
            idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
            idStratalotPriceList: ids.stratalotPriceLists as number
          } as StratalotPriceList
        })
      );
      actions.push(
        residencePriceGridActions.addManyStratalotPriceListSuccess({
          idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
          idStratalotPriceLists: [ids.stratalotPriceLists as number]
        })
      );
    } else {
      actions.push(
        stratalotPriceListActions.upsertManyStratalotPriceLists({
          stratalotPriceLists: (ids.stratalotPriceLists as number[]).map((idStratalotPriceList: number) => ({
            idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
            idStratalotPriceList
          })) as StratalotPriceList[]
        })
      );
      actions.push(
        residencePriceGridActions.addManyStratalotPriceListSuccess({
          idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
          idStratalotPriceLists: ids.stratalotPriceLists as number[]
        })
      );
    }
  }

  if (ids?.stratalotPrices) {
    if (!Array.isArray(ids.stratalotPrices)) {
      actions.push(
        stratalotPriceActions.upsertOneStratalotPrice({
          stratalotPrice: {
            idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
            idStratalotPrice: ids.stratalotPrices as number
          } as StratalotPrice
        })
      );
      actions.push(
        residencePriceGridActions.addManyStratalotPriceSuccess({
          idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
          idStratalotPrices: [ids.stratalotPrices as number]
        })
      );
    } else {
      actions.push(
        stratalotPriceActions.upsertManyStratalotPrices({
          stratalotPrices: (ids.stratalotPrices as number[]).map((idStratalotPrice: number) => ({
            idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
            idStratalotPrice
          })) as StratalotPrice[]
        })
      );
      actions.push(
        residencePriceGridActions.addManyStratalotPriceSuccess({
          idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
          idStratalotPrices: ids.stratalotPrices as number[]
        })
      );
    }
  }

  if (ids?.residencePriceGridModifiers) {
    if (!Array.isArray(ids.residencePriceGridModifiers)) {
      actions.push(
        residencePriceGridModifierActions.upsertOneResidencePriceGridModifier({
          residencePriceGridModifier: {
            idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
            idResidencePriceGridModifier: ids.residencePriceGridModifiers as number
          } as ResidencePriceGridModifier
        })
      );
      actions.push(
        residencePriceGridActions.addManyResidencePriceGridModifierSuccess({
          idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
          idResidencePriceGridModifiers: [ids.residencePriceGridModifiers as number]
        })
      );
    } else {
      actions.push(
        residencePriceGridModifierActions.upsertManyResidencePriceGridModifiers({
          residencePriceGridModifiers: (ids.residencePriceGridModifiers as number[]).map(
            (idResidencePriceGridModifier: number) => ({
              idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
              idResidencePriceGridModifier
            })
          ) as ResidencePriceGridModifier[]
        })
      );
      actions.push(
        residencePriceGridActions.addManyResidencePriceGridModifierSuccess({
          idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
          idResidencePriceGridModifiers: ids.residencePriceGridModifiers as number[]
        })
      );
    }
  }

  if (ids?.residencePriceGridHistories) {
    if (!Array.isArray(ids.residencePriceGridHistories)) {
      actions.push(
        residencePriceGridHistoryActions.upsertOneResidencePriceGridHistory({
          residencePriceGridHistory: {
            idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
            idResidencePriceGridHistory: ids.residencePriceGridHistories as number
          } as ResidencePriceGridHistory
        })
      );
      actions.push(
        residencePriceGridActions.addManyResidencePriceGridHistorySuccess({
          idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
          idResidencePriceGridHistories: [ids.residencePriceGridHistories as number]
        })
      );
    } else {
      actions.push(
        residencePriceGridHistoryActions.upsertManyResidencePriceGridHistories({
          residencePriceGridHistories: (ids.residencePriceGridHistories as number[]).map(
            (idResidencePriceGridHistory: number) => ({
              idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
              idResidencePriceGridHistory
            })
          ) as ResidencePriceGridHistory[]
        })
      );
      actions.push(
        residencePriceGridActions.addManyResidencePriceGridHistorySuccess({
          idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid,
          idResidencePriceGridHistories: ids.residencePriceGridHistories as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteResidencePriceGridActions(residencePriceGrid: ResidencePriceGridEntityState): Action[] {
  const actions: Action[] = [
    residencePriceGridActions.deleteOneResidencePriceGridSuccess({
      idResidencePriceGrid: residencePriceGrid.idResidencePriceGrid
    })
  ];

  if (residencePriceGrid.residence) {
    actions.push(
      residenceActions.deleteManyResidencePriceGridSuccess({
        idResidencePriceGrids: [residencePriceGrid.idResidencePriceGrid],
        idResidences: [residencePriceGrid.residence as number]
      })
    );
  }

  if (residencePriceGrid.stratalotPriceLists) {
    actions.push(
      stratalotPriceListActions.deleteManyResidencePriceGridSuccess({
        idResidencePriceGrids: [residencePriceGrid.idResidencePriceGrid],
        idStratalotPriceLists: residencePriceGrid.stratalotPriceLists as number[]
      })
    );
  }

  if (residencePriceGrid.stratalotPrices) {
    actions.push(
      stratalotPriceActions.deleteManyResidencePriceGridSuccess({
        idResidencePriceGrids: [residencePriceGrid.idResidencePriceGrid],
        idStratalotPrices: residencePriceGrid.stratalotPrices as number[]
      })
    );
  }

  if (residencePriceGrid.residencePriceGridModifiers) {
    actions.push(
      residencePriceGridModifierActions.deleteManyResidencePriceGridSuccess({
        idResidencePriceGrids: [residencePriceGrid.idResidencePriceGrid],
        idResidencePriceGridModifiers: residencePriceGrid.residencePriceGridModifiers as number[]
      })
    );
  }

  if (residencePriceGrid.residencePriceGridHistories) {
    actions.push(
      residencePriceGridHistoryActions.deleteManyResidencePriceGridSuccess({
        idResidencePriceGrids: [residencePriceGrid.idResidencePriceGrid],
        idResidencePriceGridHistories: residencePriceGrid.residencePriceGridHistories as number[]
      })
    );
  }

  return actions;
}

export class GeneratedResidencePriceGridEffects {
  constructor(
    protected actions$: Actions,
    protected residencePriceGridApiService: ResidencePriceGridApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidencePriceGrids$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridActions.getManyResidencePriceGrids),
      switchMap(({ params }) =>
        this.residencePriceGridApiService.getResidencePriceGrids(params).pipe(
          map((residencePriceGrids: ResidencePriceGrid[]) => {
            return residencePriceGridActions.normalizeManyResidencePriceGridsAfterUpsert({ residencePriceGrids });
          }),
          catchError(error => of(residencePriceGridActions.residencePriceGridsFailure({ error })))
        )
      )
    );
  });

  getOneResidencePriceGrid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridActions.getOneResidencePriceGrid),
      switchMap(idResidencePriceGrid =>
        this.residencePriceGridApiService.getResidencePriceGrid(idResidencePriceGrid).pipe(
          map((residencePriceGrid: ResidencePriceGrid) => {
            return residencePriceGridActions.normalizeManyResidencePriceGridsAfterUpsert({
              residencePriceGrids: [residencePriceGrid]
            });
          }),
          catchError(error => of(residencePriceGridActions.residencePriceGridsFailure({ error })))
        )
      )
    );
  });

  upsertOneResidencePriceGrid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridActions.upsertOneResidencePriceGrid),
      concatMap(
        ({
          residencePriceGrid,
          ids
        }: {
          residencePriceGrid: Partial<ResidencePriceGrid>;
          ids?: ResidencePriceGridRelationsIds;
        }) => {
          if (residencePriceGrid.idResidencePriceGrid) {
            return this.residencePriceGridApiService.updateResidencePriceGrid(residencePriceGrid).pipe(
              map((residencePriceGridReturned: ResidencePriceGrid) => {
                return residencePriceGridActions.normalizeManyResidencePriceGridsAfterUpsert({
                  residencePriceGrids: [residencePriceGridReturned]
                });
              }),
              catchError(error => of(residencePriceGridActions.residencePriceGridsFailure({ error })))
            );
          } else {
            return this.residencePriceGridApiService.addResidencePriceGrid(residencePriceGrid).pipe(
              mergeMap((residencePriceGridReturned: ResidencePriceGrid) =>
                getDefaultAddResidencePriceGridActions(residencePriceGridReturned, ids)
              ),
              catchError(error => of(residencePriceGridActions.residencePriceGridsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneResidencePriceGrid$ = createEffect(() => {
    const selectResidencePriceGridState$ = this.store$.select(selectResidencePriceGridState);
    return this.actions$.pipe(
      ofType(residencePriceGridActions.deleteOneResidencePriceGrid),
      withLatestFrom(selectResidencePriceGridState$),
      concatMap(([{ idResidencePriceGrid }, state]) =>
        this.residencePriceGridApiService.deleteResidencePriceGrid(idResidencePriceGrid).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidencePriceGridActions(
                state.entities[idResidencePriceGrid] as ResidencePriceGridEntityState
              ),
              residencePriceGridActions.deleteOneResidencePriceGrid.type
            )
          ]),
          catchError(error => of(residencePriceGridActions.residencePriceGridsFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidencePriceGridsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridActions.normalizeManyResidencePriceGridsAfterUpsert),
      concatMap(({ residencePriceGrids }) => {
        const actions: Action[] = getActionsToNormalizeResidencePriceGrid(residencePriceGrids, StoreActionType.upsert);
        return [getMultiAction(actions, '[ResidencePriceGrid] Normalization After Upsert Success')];
      })
    );
  });
}
