import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyCommunicationGeneratedDocumentEntityState } from '@_model/interfaces/company-communication-generated-document.model';

export interface CompanyCommunicationGeneratedDocumentState
  extends EntityState<CompanyCommunicationGeneratedDocumentEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyCommunicationGeneratedDocumentEntityState> =
  createEntityAdapter<CompanyCommunicationGeneratedDocumentEntityState>({
    selectId: o => o.idCompanyCommunicationGeneratedDocument
  });
export const initialState: CompanyCommunicationGeneratedDocumentState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyCommunicationGeneratedDocumentFeatureKey = 'companyCommunicationGeneratedDocument';
