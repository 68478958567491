import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StepProgressApiService } from '@_services/api/step-progress-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedStepProgressEffects } from './step-progress-generated.effects';

@Injectable()
export class StepProgressEffects extends GeneratedStepProgressEffects {
  constructor(actions$: Actions, stepProgressApiService: StepProgressApiService, store$: Store<AppState>) {
    super(actions$, stepProgressApiService, store$);
  }
}
