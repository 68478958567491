import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ResidenceForecastValue,
  ResidenceForecastValueEntityState
} from '@_model/interfaces/residence-forecast-value.model';
import { ResidenceForecast, ResidenceForecastEntityState } from '@_model/interfaces/residence-forecast.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  residenceForecastValueFeatureKey,
  ResidenceForecastValueState
} from './residence-forecast-value.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residenceForecastValueRelations: string[] = ['residenceForecasts'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidenceForecastValueState = createFeatureSelector<ResidenceForecastValueState>(
  residenceForecastValueFeatureKey
);

export const selectIsLoadedResidenceForecastValue = createSelector(
  selectResidenceForecastValueState,
  (state: ResidenceForecastValueState) => state.isLoaded
);

export const selectIsLoadingResidenceForecastValue = createSelector(
  selectResidenceForecastValueState,
  (state: ResidenceForecastValueState) => state.isLoading
);

export const selectIsReadyResidenceForecastValue = createSelector(
  selectResidenceForecastValueState,
  (state: ResidenceForecastValueState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidenceForecastValue = createSelector(
  selectResidenceForecastValueState,
  (state: ResidenceForecastValueState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidenceForecastValueModel: SelectorModel = {
  name: 'residenceForecastValues',
  getSelector: selectAllResidenceForecastValuesDictionary,
  isReady: selectIsReadyResidenceForecastValue
};

export const selectResidenceForecastValuesEntities = createSelector(selectResidenceForecastValueState, selectEntities);

export const selectResidenceForecastValuesArray = createSelector(selectResidenceForecastValueState, selectAll);

export const selectIdResidenceForecastValuesActive = createSelector(
  selectResidenceForecastValueState,
  (state: ResidenceForecastValueState) => state.actives
);

const residenceForecastValuesInObject = (residenceForecastValues: Dictionary<ResidenceForecastValueEntityState>) => ({
  residenceForecastValues
});

const selectResidenceForecastValuesEntitiesDictionary = createSelector(
  selectResidenceForecastValuesEntities,
  residenceForecastValuesInObject
);

const selectAllResidenceForecastValuesObject = createSelector(
  selectResidenceForecastValuesEntities,
  residenceForecastValues => {
    return hydrateAll({ residenceForecastValues });
  }
);

const selectOneResidenceForecastValueDictionary = (idResidenceForecastValue: number) =>
  createSelector(selectResidenceForecastValuesEntities, residenceForecastValues => {
    return {
      residenceForecastValues: { [idResidenceForecastValue]: residenceForecastValues[idResidenceForecastValue] }
    };
  });

const selectOneResidenceForecastValueDictionaryWithoutChild = (idResidenceForecastValue: number) =>
  createSelector(selectResidenceForecastValuesEntities, residenceForecastValues => {
    return { residenceForecastValue: residenceForecastValues[idResidenceForecastValue] };
  });

const selectActiveResidenceForecastValuesEntities = createSelector(
  selectIdResidenceForecastValuesActive,
  selectResidenceForecastValuesEntities,
  (actives: number[], residenceForecastValues: Dictionary<ResidenceForecastValueEntityState>) =>
    getResidenceForecastValuesFromActives(actives, residenceForecastValues)
);

function getResidenceForecastValuesFromActives(
  actives: number[],
  residenceForecastValues: Dictionary<ResidenceForecastValueEntityState>
): Dictionary<ResidenceForecastValueEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residenceForecastValues[idActive]) {
      acc[idActive] = residenceForecastValues[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidenceForecastValueEntityState>);
}

const selectAllResidenceForecastValuesSelectors: Dictionary<Selector> = {};
export function selectAllResidenceForecastValues(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidenceForecastValue>(
      schema,
      selectAllResidenceForecastValuesSelectors,
      selectResidenceForecastValuesEntitiesDictionary,
      getRelationSelectors,
      residenceForecastValueRelations,
      hydrateAll,
      'residenceForecastValue'
    );
  } else {
    return selectAllResidenceForecastValuesObject;
  }
}

export function selectAllResidenceForecastValuesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residenceForecastValues'
): Selector {
  return createSelector(selectAllResidenceForecastValues(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidenceForecastValueEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residenceForecastValues.length; i++) {
      res[customKey][result.residenceForecastValues[i].idResidenceForecastValue] = result.residenceForecastValues[i];
    }
    return res;
  });
}

export function selectOneResidenceForecastValue(schema: SelectSchema = {}, idResidenceForecastValue: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidenceForecastValueDictionary(idResidenceForecastValue)];
    selectors.push(...getRelationSelectors(schema, residenceForecastValueRelations, 'residenceForecastValue'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidenceForecastValueDictionaryWithoutChild(idResidenceForecastValue);
  }
}

export function selectActiveResidenceForecastValues(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidenceForecastValuesEntities, residenceForecastValues => ({
      residenceForecastValues
    }))
  ];
  selectors.push(...getRelationSelectors(schema, residenceForecastValueRelations, 'residenceForecastValue'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residenceForecastValues: Dictionary<ResidenceForecastValueEntityState>;
  residenceForecasts?: Dictionary<ResidenceForecastEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { residenceForecastValues: (ResidenceForecastValue | null)[] } {
  const { residenceForecastValues, residenceForecasts } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    residenceForecastValues: Object.keys(residenceForecastValues).map(idResidenceForecastValue =>
      hydrate(
        residenceForecastValues[idResidenceForecastValue] as ResidenceForecastValueEntityState,
        residenceForecasts
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { residenceForecastValue: ResidenceForecastValueEntityState | null } {
  const { residenceForecastValues, residenceForecasts } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const residenceForecastValue = Object.values(residenceForecastValues)[0];
  return {
    residenceForecastValue: hydrate(residenceForecastValue as ResidenceForecastValueEntityState, residenceForecasts)
  };
}

function hydrate(
  residenceForecastValue: ResidenceForecastValueEntityState,
  residenceForecastEntities?: Dictionary<ResidenceForecastEntityState>
): ResidenceForecastValue | null {
  if (!residenceForecastValue) {
    return null;
  }

  const residenceForecastValueHydrated: ResidenceForecastValueEntityState = { ...residenceForecastValue };
  if (residenceForecastEntities) {
    residenceForecastValueHydrated.residenceForecast = residenceForecastEntities[
      residenceForecastValue.residenceForecast as number
    ] as ResidenceForecast;
  } else {
    delete residenceForecastValueHydrated.residenceForecast;
  }

  return residenceForecastValueHydrated as ResidenceForecastValue;
}
