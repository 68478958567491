import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DroitInterneEnum } from '@_model/enums/droit.enum';
import { AncienModulePathEnum } from '@_utils/router/path-enum/ancien-module-path-enum';
import { DashBoardPathEnum } from '@_utils/router/path-enum/dash-board-path-enum';
import { NeufModulePathEnum } from '@_utils/router/path-enum/neuf-module-path-enum';
import { UpModulePathEnum } from '@_utils/router/path-enum/up-module-path-enum';
import { IsPageAvailableForUserGuard } from '../../core/guards/is-page-available-for-user-guard.service';
import { UpdateStoreGuard } from '../../core/guards/update-store.guard';
import { studyTabMenuItems } from '../study/utils/tab-menu.utils';
import { UserNoAccessPageComponent } from '../user-no-access-page/user-no-access-page.component';
import { LayoutComponent } from './layout.component';

export const routesLayout: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [IsPageAvailableForUserGuard],
    children: [
      {
        path: '',
        canActivate: [IsPageAvailableForUserGuard],
        redirectTo: `/app/${UpModulePathEnum.ancien}/${NeufModulePathEnum.dashboard}/${DashBoardPathEnum.resultsAtDate}`,
        pathMatch: 'full'
      },
      {
        path: UpModulePathEnum.neuf,

        data: {
          breadcrumb: 'Neuf'
        },
        children: [
          {
            path: NeufModulePathEnum.dashboard,
            canActivate: [IsPageAvailableForUserGuard],
            loadChildren: () => import('app/modules/dashboard/dashboard.module').then(m => m.DashboardModule),
            data: {
              breadcrumb: 'Tableau de bord',
              right: DroitInterneEnum.accessDashboardPage
            }
          },
          {
            path: NeufModulePathEnum.salePlan,
            canActivate: [IsPageAvailableForUserGuard],
            loadChildren: () => import('app/modules/sale-plan/sale-plan.module').then(m => m.SalePlanModule),
            data: {
              breadcrumb: 'Tous les programmes',
              right: DroitInterneEnum.accessResidencePage
            }
          },
          {
            path: NeufModulePathEnum.programCommercialisation,
            canActivate: [IsPageAvailableForUserGuard],
            loadChildren: () =>
              import('app/modules/sale-plan-unbuilt/sale-plan-unbuilt.module').then(m => m.SalePlanUnbuiltModule),
            data: {
              breadcrumb: 'Programmes',
              right: DroitInterneEnum.accessFreeStratalotPage
            }
          },
          {
            path: NeufModulePathEnum.prospects,
            loadChildren: () => import('app/modules/prospect/prospect.module').then(m => m.ProspectModule),
            data: {
              breadcrumb: 'Liste prospects'
            }
          },
          {
            path: NeufModulePathEnum.salesInProgress,
            canActivate: [IsPageAvailableForUserGuard],
            loadChildren: () =>
              import('app/modules/sale-in-progress-unbuilt/sale-in-progress-unbuilt.module').then(
                m => m.SaleInProgressUnbuiltModule
              ),
            data: {
              breadcrumb: 'Ventes en cours',
              right: DroitInterneEnum.accessSaleInProgressPage
            }
          },
          {
            path: NeufModulePathEnum.validateOffer,
            canActivate: [IsPageAvailableForUserGuard],
            loadChildren: () =>
              import('app/modules/validate-offer/validate-offer.module').then(m => m.ValidateOfferModule),
            data: {
              breadcrumb: 'Options à valider',
              right: DroitInterneEnum.accessToValidateOfferPage
            }
          },
          {
            path: '**',
            canActivate: [IsPageAvailableForUserGuard],
            redirectTo: `/app/${UpModulePathEnum.neuf}/${NeufModulePathEnum.dashboard}/${DashBoardPathEnum.resultsAtDate}`,
            pathMatch: 'full'
          }
        ]
      },
      {
        path: UpModulePathEnum.ancien,

        data: {
          breadcrumb: 'Ancien'
        },
        children: [
          {
            path: AncienModulePathEnum.dashboard,
            canActivate: [IsPageAvailableForUserGuard],
            loadChildren: () => import('app/modules/dashboard/dashboard.module').then(m => m.DashboardModule),
            data: {
              breadcrumb: 'Tableau de bord',
              right: DroitInterneEnum.accessDashboardPage
            }
          },
          {
            path: AncienModulePathEnum.study,
            canActivate: [IsPageAvailableForUserGuard],
            loadChildren: () => import('app/modules/study/study.module').then(m => m.StudyModule),
            data: {
              breadcrumb: 'Etude',
              mainMenuItems: studyTabMenuItems.mainMenuItems,
              right: DroitInterneEnum.accessStudy
            }
          },
          {
            path: AncienModulePathEnum.salePlan,
            canActivate: [IsPageAvailableForUserGuard],
            loadChildren: () => import('app/modules/sale-plan/sale-plan.module').then(m => m.SalePlanModule),
            data: {
              breadcrumb: 'En montage',
              right: DroitInterneEnum.accessResidenceMontagePage
            }
          },
          {
            path: AncienModulePathEnum.residenceForSale,
            canActivate: [IsPageAvailableForUserGuard],
            loadChildren: () =>
              import('app/modules/residence-for-sale/residence-for-sale.module').then(m => m.ResidenceForSaleModule),
            data: {
              breadcrumb: 'En vente',
              right: DroitInterneEnum.accessResidenceVentePage
            }
          },
          {
            path: AncienModulePathEnum.occupedStratalots,
            canActivate: [IsPageAvailableForUserGuard],
            loadChildren: () =>
              import('app/modules/occuped-stratalots/occuped-stratalots.module').then(m => m.OccupedStratalotsModule),
            data: {
              breadcrumb: 'Liste occupants',
              right: DroitInterneEnum.accessOccupiedStratalotPage
            }
          },
          {
            path: AncienModulePathEnum.freeStratalots,
            canActivate: [IsPageAvailableForUserGuard],
            loadChildren: () =>
              import('app/modules/free-stratalot/free-stratalot.module').then(m => m.FreeStratalotModule),
            data: {
              breadcrumb: 'Liste lots libres',
              right: DroitInterneEnum.accessFreeStratalotPage
            }
          },
          {
            path: AncienModulePathEnum.prospects,
            canActivate: [IsPageAvailableForUserGuard],
            loadChildren: () => import('app/modules/prospect/prospect.module').then(m => m.ProspectModule),
            data: {
              breadcrumb: 'Liste prospects',
              right: DroitInterneEnum.accessProspectPage
            }
          },
          {
            path: AncienModulePathEnum.salesInProgress,
            canActivate: [IsPageAvailableForUserGuard],
            loadChildren: () =>
              import('app/modules/sale-in-progress/sale-in-progress.module').then(m => m.SaleInProgressModule),
            data: {
              breadcrumb: 'Ventes en cours',
              right: DroitInterneEnum.accessSaleInProgressPage
            }
          },
          {
            path: AncienModulePathEnum.validateOffer,
            canActivate: [IsPageAvailableForUserGuard],
            loadChildren: () =>
              import('app/modules/validate-offer/validate-offer.module').then(m => m.ValidateOfferModule),
            data: {
              breadcrumb: 'Offres à valider',
              right: DroitInterneEnum.accessToValidateOfferPage
            }
          },
          {
            path: '**',
            canActivate: [IsPageAvailableForUserGuard],
            redirectTo: `/app/${UpModulePathEnum.ancien}/${NeufModulePathEnum.dashboard}/${DashBoardPathEnum.resultsAtDate}`,
            pathMatch: 'full'
          }
        ]
      },
      {
        path: UpModulePathEnum.admin,
        canActivate: [IsPageAvailableForUserGuard],
        canDeactivate: [UpdateStoreGuard],
        loadChildren: () => import('app/modules/admin/admin.module').then(m => m.AdminModule),
        data: {
          breadcrumb: 'Administration',
          right: DroitInterneEnum.manageAdmin
        }
      },
      {
        path: UpModulePathEnum.user,
        loadChildren: () => import('app/modules/user/user.module').then(m => m.UserModule),
        data: {
          breadcrumb: 'Utilisateur'
        }
      },
      {
        path: UpModulePathEnum.noAccess,
        component: UserNoAccessPageComponent,
        data: {
          breadcrumb: 'no-access'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routesLayout)],
  exports: [RouterModule]
})
export class LayoutRoutingModule {}
