import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResidenceStudyTodo, ResidenceStudyTodoEntityState } from '@_model/interfaces/residence-study-todo.model';
import {
  OrganizationResidenceStudyTodo,
  OrganizationResidenceStudyTodoEntityState
} from '@_model/interfaces/organization-residence-study-todo.model';
import { ResidenceStudy, ResidenceStudyEntityState } from '@_model/interfaces/residence-study.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, residenceStudyTodoFeatureKey, ResidenceStudyTodoState } from './residence-study-todo.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residenceStudyTodoRelations: string[] = ['organizationResidenceStudyTodos', 'residenceStudies'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidenceStudyTodoState =
  createFeatureSelector<ResidenceStudyTodoState>(residenceStudyTodoFeatureKey);

export const selectIsLoadedResidenceStudyTodo = createSelector(
  selectResidenceStudyTodoState,
  (state: ResidenceStudyTodoState) => state.isLoaded
);

export const selectIsLoadingResidenceStudyTodo = createSelector(
  selectResidenceStudyTodoState,
  (state: ResidenceStudyTodoState) => state.isLoading
);

export const selectIsReadyResidenceStudyTodo = createSelector(
  selectResidenceStudyTodoState,
  (state: ResidenceStudyTodoState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidenceStudyTodo = createSelector(
  selectResidenceStudyTodoState,
  (state: ResidenceStudyTodoState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidenceStudyTodoModel: SelectorModel = {
  name: 'residenceStudyTodos',
  getSelector: selectAllResidenceStudyTodosDictionary,
  isReady: selectIsReadyResidenceStudyTodo
};

export const selectResidenceStudyTodosEntities = createSelector(selectResidenceStudyTodoState, selectEntities);

export const selectResidenceStudyTodosArray = createSelector(selectResidenceStudyTodoState, selectAll);

export const selectIdResidenceStudyTodosActive = createSelector(
  selectResidenceStudyTodoState,
  (state: ResidenceStudyTodoState) => state.actives
);

const residenceStudyTodosInObject = (residenceStudyTodos: Dictionary<ResidenceStudyTodoEntityState>) => ({
  residenceStudyTodos
});

const selectResidenceStudyTodosEntitiesDictionary = createSelector(
  selectResidenceStudyTodosEntities,
  residenceStudyTodosInObject
);

const selectAllResidenceStudyTodosObject = createSelector(selectResidenceStudyTodosEntities, residenceStudyTodos => {
  return hydrateAll({ residenceStudyTodos });
});

const selectOneResidenceStudyTodoDictionary = (idResidenceStudyTodo: number) =>
  createSelector(selectResidenceStudyTodosEntities, residenceStudyTodos => {
    return { residenceStudyTodos: { [idResidenceStudyTodo]: residenceStudyTodos[idResidenceStudyTodo] } };
  });

const selectOneResidenceStudyTodoDictionaryWithoutChild = (idResidenceStudyTodo: number) =>
  createSelector(selectResidenceStudyTodosEntities, residenceStudyTodos => {
    return { residenceStudyTodo: residenceStudyTodos[idResidenceStudyTodo] };
  });

const selectActiveResidenceStudyTodosEntities = createSelector(
  selectIdResidenceStudyTodosActive,
  selectResidenceStudyTodosEntities,
  (actives: number[], residenceStudyTodos: Dictionary<ResidenceStudyTodoEntityState>) =>
    getResidenceStudyTodosFromActives(actives, residenceStudyTodos)
);

function getResidenceStudyTodosFromActives(
  actives: number[],
  residenceStudyTodos: Dictionary<ResidenceStudyTodoEntityState>
): Dictionary<ResidenceStudyTodoEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residenceStudyTodos[idActive]) {
      acc[idActive] = residenceStudyTodos[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidenceStudyTodoEntityState>);
}

const selectAllResidenceStudyTodosSelectors: Dictionary<Selector> = {};
export function selectAllResidenceStudyTodos(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidenceStudyTodo>(
      schema,
      selectAllResidenceStudyTodosSelectors,
      selectResidenceStudyTodosEntitiesDictionary,
      getRelationSelectors,
      residenceStudyTodoRelations,
      hydrateAll,
      'residenceStudyTodo'
    );
  } else {
    return selectAllResidenceStudyTodosObject;
  }
}

export function selectAllResidenceStudyTodosDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residenceStudyTodos'
): Selector {
  return createSelector(selectAllResidenceStudyTodos(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidenceStudyTodoEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residenceStudyTodos.length; i++) {
      res[customKey][result.residenceStudyTodos[i].idResidenceStudyTodo] = result.residenceStudyTodos[i];
    }
    return res;
  });
}

export function selectOneResidenceStudyTodo(schema: SelectSchema = {}, idResidenceStudyTodo: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidenceStudyTodoDictionary(idResidenceStudyTodo)];
    selectors.push(...getRelationSelectors(schema, residenceStudyTodoRelations, 'residenceStudyTodo'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidenceStudyTodoDictionaryWithoutChild(idResidenceStudyTodo);
  }
}

export function selectActiveResidenceStudyTodos(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidenceStudyTodosEntities, residenceStudyTodos => ({ residenceStudyTodos }))
  ];
  selectors.push(...getRelationSelectors(schema, residenceStudyTodoRelations, 'residenceStudyTodo'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residenceStudyTodos: Dictionary<ResidenceStudyTodoEntityState>;
  organizationResidenceStudyTodos?: Dictionary<OrganizationResidenceStudyTodoEntityState>;
  residenceStudies?: Dictionary<ResidenceStudyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { residenceStudyTodos: (ResidenceStudyTodo | null)[] } {
  const { residenceStudyTodos, organizationResidenceStudyTodos, residenceStudies } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    residenceStudyTodos: Object.keys(residenceStudyTodos).map(idResidenceStudyTodo =>
      hydrate(
        residenceStudyTodos[idResidenceStudyTodo] as ResidenceStudyTodoEntityState,
        organizationResidenceStudyTodos,
        residenceStudies
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { residenceStudyTodo: ResidenceStudyTodoEntityState | null } {
  const { residenceStudyTodos, organizationResidenceStudyTodos, residenceStudies } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const residenceStudyTodo = Object.values(residenceStudyTodos)[0];
  return {
    residenceStudyTodo: hydrate(
      residenceStudyTodo as ResidenceStudyTodoEntityState,
      organizationResidenceStudyTodos,
      residenceStudies
    )
  };
}

function hydrate(
  residenceStudyTodo: ResidenceStudyTodoEntityState,
  organizationResidenceStudyTodoEntities?: Dictionary<OrganizationResidenceStudyTodoEntityState>,
  residenceStudyEntities?: Dictionary<ResidenceStudyEntityState>
): ResidenceStudyTodo | null {
  if (!residenceStudyTodo) {
    return null;
  }

  const residenceStudyTodoHydrated: ResidenceStudyTodoEntityState = { ...residenceStudyTodo };
  if (organizationResidenceStudyTodoEntities) {
    residenceStudyTodoHydrated.organizationResidenceStudyTodo = organizationResidenceStudyTodoEntities[
      residenceStudyTodo.organizationResidenceStudyTodo as number
    ] as OrganizationResidenceStudyTodo;
  } else {
    delete residenceStudyTodoHydrated.organizationResidenceStudyTodo;
  }
  if (residenceStudyEntities) {
    residenceStudyTodoHydrated.residenceStudy = residenceStudyEntities[
      residenceStudyTodo.residenceStudy as number
    ] as ResidenceStudy;
  } else {
    delete residenceStudyTodoHydrated.residenceStudy;
  }

  return residenceStudyTodoHydrated as ResidenceStudyTodo;
}
