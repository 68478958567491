<div mat-dialog-title>
  <p>{{ title }}</p>
</div>
<div *ngIf="!isQuestionArray" mat-dialog-content>
  <p class="text-padding">{{ question }}</p>
</div>
<div *ngIf="isQuestionArray" mat-dialog-content>
  <p *ngFor="let q of question" class="text-padding">{{ q }}</p>
</div>
<div mat-dialog-actions fxLayoutGap="10px">
  <button mat-button [mat-dialog-close]>Ok</button>
</div>
