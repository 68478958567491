import { ProfilState, initialState } from './profil.state';
import { Action, createReducer } from '@ngrx/store';
import { profilReducersGeneratedFunctions } from './profil-generated.reducer';

const profilReducersFunctions = [...profilReducersGeneratedFunctions];

const profilReducer = createReducer(initialState, ...profilReducersFunctions);

export function reducer(state: ProfilState | undefined, action: Action) {
  return profilReducer(state, action);
}
