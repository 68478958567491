import { RepositoryService } from '@_services/api/repository.service';
import { Residence } from '@_model/interfaces/residence.model';
import { Observable } from 'rxjs';

export class GeneratedResidenceApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidences(params?: any): Observable<Residence[]> {
    return this.repo.getData<Residence[]>('residence', params);
  }

  public getResidence(params: { idResidence: number; params?: any }): Observable<Residence> {
    return this.repo.getData<Residence>('residence/' + params.idResidence, params.params);
  }

  public addResidence(residence: Partial<Residence>): Observable<Residence> {
    return this.repo.create<Residence>('residence', residence);
  }

  public updateResidence(residence: Partial<Residence>): Observable<Residence> {
    return this.repo.update('residence', residence);
  }

  public deleteResidence(idResidence: number): Observable<number> {
    return this.repo.delete('residence/' + +idResidence);
  }
}
