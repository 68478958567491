import { RepositoryService } from '@_services/api/repository.service';
import { DiffusionVisual } from '@_model/interfaces/diffusion-visual.model';
import { Observable } from 'rxjs';

export class GeneratedDiffusionVisualApiService {
  constructor(protected repo: RepositoryService) {}

  public getDiffusionVisuals(params?: any): Observable<DiffusionVisual[]> {
    return this.repo.getData<DiffusionVisual[]>('diffusion-visual', params);
  }

  public getDiffusionVisual(params: { idDiffusionVisual: number; params?: any }): Observable<DiffusionVisual> {
    return this.repo.getData<DiffusionVisual>('diffusion-visual/' + params.idDiffusionVisual, params.params);
  }

  public addDiffusionVisual(diffusionVisual: Partial<DiffusionVisual>): Observable<DiffusionVisual> {
    return this.repo.create<DiffusionVisual>('diffusion-visual', diffusionVisual);
  }

  public updateDiffusionVisual(diffusionVisual: Partial<DiffusionVisual>): Observable<DiffusionVisual> {
    return this.repo.update('diffusion-visual', diffusionVisual);
  }

  public deleteDiffusionVisual(idDiffusionVisual: number): Observable<number> {
    return this.repo.delete('diffusion-visual/' + +idDiffusionVisual);
  }
}
