import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProfilApiService } from '@_services/api/profil-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedProfilEffects } from './profil-generated.effects';

@Injectable()
export class ProfilEffects extends GeneratedProfilEffects {
  constructor(actions$: Actions, profilApiService: ProfilApiService, store$: Store<AppState>) {
    super(actions$, profilApiService, store$);
  }
}
