import { CompanyCommunicationResidenceTodoState, initialState } from './company-communication-residence-todo.state';
import { Action, createReducer } from '@ngrx/store';
import { companyCommunicationResidenceTodoReducersGeneratedFunctions } from './company-communication-residence-todo-generated.reducer';

const companyCommunicationResidenceTodoReducersFunctions = [
  ...companyCommunicationResidenceTodoReducersGeneratedFunctions
];

const companyCommunicationResidenceTodoReducer = createReducer(
  initialState,
  ...companyCommunicationResidenceTodoReducersFunctions
);

export function reducer(state: CompanyCommunicationResidenceTodoState | undefined, action: Action) {
  return companyCommunicationResidenceTodoReducer(state, action);
}
