import { CompanyStratalotTypeState, initialState } from './company-stratalot-type.state';
import { Action, createReducer } from '@ngrx/store';
import { companyStratalotTypeReducersGeneratedFunctions } from './company-stratalot-type-generated.reducer';

const companyStratalotTypeReducersFunctions = [...companyStratalotTypeReducersGeneratedFunctions];

const companyStratalotTypeReducer = createReducer(initialState, ...companyStratalotTypeReducersFunctions);

export function reducer(state: CompanyStratalotTypeState | undefined, action: Action) {
  return companyStratalotTypeReducer(state, action);
}
