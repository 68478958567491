import { ResidenceShareRcpState, initialState } from './residence-share-rcp.state';
import { Action, createReducer } from '@ngrx/store';
import { residenceShareRcpReducersGeneratedFunctions } from './residence-share-rcp-generated.reducer';

const residenceShareRcpReducersFunctions = [...residenceShareRcpReducersGeneratedFunctions];

const residenceShareRcpReducer = createReducer(initialState, ...residenceShareRcpReducersFunctions);

export function reducer(state: ResidenceShareRcpState | undefined, action: Action) {
  return residenceShareRcpReducer(state, action);
}
