import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ResidenceStudyWork, ResidenceStudyWorkEntityState } from '@_model/interfaces/residence-study-work.model';
import { ResidenceStudyWorkRelationsIds } from './residence-study-work-generated.effects';

export const getOneResidenceStudyWork = createAction(
  '[ResidenceStudyWork] Get One ResidenceStudyWork',
  props<{ idResidenceStudyWork: number; params?: any }>()
);

export const getManyResidenceStudyWorks = createAction(
  '[ResidenceStudyWork] Get Many ResidenceStudyWorks',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ResidenceStudyWork] Add Many Actives ResidenceStudyWork',
  props<{ idResidenceStudyWorks: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidenceStudyWork] Delete One Active ResidenceStudyWork',
  props<{ idResidenceStudyWork: number }>()
);

export const clearActive = createAction('[ResidenceStudyWork] Clear Active ResidenceStudyWork');

export const upsertOneResidenceStudyWork = createAction(
  '[ResidenceStudyWork] Upsert One ResidenceStudyWork',
  props<{
    residenceStudyWork: Partial<ResidenceStudyWork>;
    ids?: ResidenceStudyWorkRelationsIds;
  }>()
);

export const upsertManyResidenceStudyWorks = createAction(
  '[ResidenceStudyWork] Upsert Many ResidenceStudyWorks',
  props<{
    residenceStudyWorks: Partial<ResidenceStudyWork>[];
    ids?: ResidenceStudyWorkRelationsIds;
  }>()
);

export const upsertManyResidenceStudyWorksSuccess = createAction(
  '[ResidenceStudyWork] Create Many ResidenceStudyWorks Success',
  props<{ residenceStudyWorks: ResidenceStudyWorkEntityState[] }>()
);

export const deleteOneResidenceStudyWork = createAction(
  '[ResidenceStudyWork] Delete One ResidenceStudyWork',
  props<{ idResidenceStudyWork: number }>()
);

export const deleteOneResidenceStudyWorkSuccess = createAction(
  '[ResidenceStudyWork] Delete One ResidenceStudyWork Success',
  props<{ idResidenceStudyWork: number }>()
);

export const normalizeManyResidenceStudyWorksAfterUpsert = createAction(
  '[ResidenceStudyWork] Normalize Many ResidenceStudyWorks After Upsert',
  props<{ residenceStudyWorks: ResidenceStudyWorkEntityState[] }>()
);

export const residenceStudyWorksFailure = createAction(
  '[ResidenceStudyWork] ResidenceStudyWorks Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidenceStudyWork] Clear ResidenceStudyWorks');

export const addResidenceStudySuccess = createAction(
  '[ResidenceStudyWork] Add ResidenceStudy',
  props<{ idResidenceStudyWork: number; idResidenceStudy: number }>()
);

export const deleteManyResidenceStudySuccess = createAction(
  '[ResidenceStudyWork] Delete Many ResidenceStudy',
  props<{ idResidenceStudies: number[]; idResidenceStudyWorks: number[] }>()
);
