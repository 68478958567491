import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { StratalotPrice, StratalotPriceEntityState } from '@_model/interfaces/stratalot-price.model';
import { StratalotPriceRelationsIds } from './stratalot-price-generated.effects';

export const getOneStratalotPrice = createAction(
  '[StratalotPrice] Get One StratalotPrice',
  props<{ idStratalotPrice: number; params?: any }>()
);

export const getManyStratalotPrices = createAction(
  '[StratalotPrice] Get Many StratalotPrices',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[StratalotPrice] Add Many Actives StratalotPrice',
  props<{ idStratalotPrices: number[] }>()
);

export const deleteOneActive = createAction(
  '[StratalotPrice] Delete One Active StratalotPrice',
  props<{ idStratalotPrice: number }>()
);

export const clearActive = createAction('[StratalotPrice] Clear Active StratalotPrice');

export const upsertOneStratalotPrice = createAction(
  '[StratalotPrice] Upsert One StratalotPrice',
  props<{
    stratalotPrice: Partial<StratalotPrice>;
    ids?: StratalotPriceRelationsIds;
  }>()
);

export const upsertManyStratalotPrices = createAction(
  '[StratalotPrice] Upsert Many StratalotPrices',
  props<{
    stratalotPrices: Partial<StratalotPrice>[];
    ids?: StratalotPriceRelationsIds;
  }>()
);

export const upsertManyStratalotPricesSuccess = createAction(
  '[StratalotPrice] Create Many StratalotPrices Success',
  props<{ stratalotPrices: StratalotPriceEntityState[] }>()
);

export const deleteOneStratalotPrice = createAction(
  '[StratalotPrice] Delete One StratalotPrice',
  props<{ idStratalotPrice: number }>()
);

export const deleteOneStratalotPriceSuccess = createAction(
  '[StratalotPrice] Delete One StratalotPrice Success',
  props<{ idStratalotPrice: number }>()
);

export const normalizeManyStratalotPricesAfterUpsert = createAction(
  '[StratalotPrice] Normalize Many StratalotPrices After Upsert',
  props<{ stratalotPrices: StratalotPriceEntityState[] }>()
);

export const stratalotPricesFailure = createAction(
  '[StratalotPrice] StratalotPrices Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[StratalotPrice] Clear StratalotPrices');

export const addManyCompanyPriceLabelSuccess = createAction(
  '[StratalotPrice] Add Many company-price-label',
  props<{ idStratalotPrice: number; idCompanyPriceLabels: number[] }>()
);

export const deleteManyCompanyPriceLabelSuccess = createAction(
  '[StratalotPrice] Delete Many CompanyPriceLabels',
  props<{ idCompanyPriceLabels: number[]; idStratalotPrices: number[] }>()
);

export const addManyStratalotPriceValueSuccess = createAction(
  '[StratalotPrice] Add Many stratalot-price-value',
  props<{ idStratalotPrice: number; idStratalotPriceValues: number[] }>()
);

export const deleteManyStratalotPriceValueSuccess = createAction(
  '[StratalotPrice] Delete Many StratalotPriceValues',
  props<{ idStratalotPriceValues: number[]; idStratalotPrices: number[] }>()
);

export const addStratalotSuccess = createAction(
  '[StratalotPrice] Add Stratalot',
  props<{ idStratalotPrice: number; idStratalot: number }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[StratalotPrice] Delete Many Stratalot',
  props<{ idStratalots: number[]; idStratalotPrices: number[] }>()
);

export const addResidencePriceGridSuccess = createAction(
  '[StratalotPrice] Add ResidencePriceGrid',
  props<{ idStratalotPrice: number; idResidencePriceGrid: number }>()
);

export const deleteManyResidencePriceGridSuccess = createAction(
  '[StratalotPrice] Delete Many ResidencePriceGrid',
  props<{ idResidencePriceGrids: number[]; idStratalotPrices: number[] }>()
);
