import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OccupantActions from './occupant.actions';
import { adapter, initialState, OccupantState } from './occupant.state';
import { Dictionary } from '@ngrx/entity';
import { OccupantEntityState } from '@_model/interfaces/occupant.model';

export const occupantReducersGeneratedFunctions: ReducerTypes<OccupantState, readonly ActionCreator[]>[] = [
  on(OccupantActions.getOneOccupant, (state: OccupantState) => setLoadingsState(state, true)),
  on(OccupantActions.getManyOccupants, (state: OccupantState) => setLoadingsState(state, true)),
  on(OccupantActions.upsertOneOccupant, (state: OccupantState) => setLoadingsState(state, true)),

  on(OccupantActions.upsertManyOccupantsSuccess, (state: OccupantState, { occupants }) =>
    adapter.upsertMany(occupants, setLoadingsState(state, false))
  ),
  on(OccupantActions.deleteOneOccupant, (state: OccupantState) => setLoadingsState(state, true)),
  on(OccupantActions.deleteOneOccupantSuccess, (state: OccupantState, { idOccupant }) =>
    adapter.removeOne(idOccupant, setLoadingsState(state, false))
  ),
  on(OccupantActions.clearActive, (state: OccupantState) => ({ ...state, actives: [] })),
  on(OccupantActions.addManyActives, (state: OccupantState, { idOccupants }) => ({
    ...state,
    actives: state.actives.concat(idOccupants)
  })),
  on(OccupantActions.deleteOneActive, (state: OccupantState, { idOccupant }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idOccupant)
  })),

  on(OccupantActions.clearStore, () => initialState),

  on(OccupantActions.addManyStratalotSuccess, (state: OccupantState, { idOccupant, idStratalots }) => {
    if (!state.entities[idOccupant]) {
      return state;
    }
    const stratalots = (state.entities[idOccupant]?.stratalots as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idOccupant]: {
          ...state.entities[idOccupant],
          stratalots: stratalots.concat(idStratalots.filter(id => stratalots.indexOf(id) < 0))
        }
      }
    };
  }),

  on(OccupantActions.deleteManyStratalotSuccess, (state: OccupantState, { idStratalots, idOccupants }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idOccupants.reduce((entities, idOccupant) => {
          if (!state.entities[idOccupant]?.stratalots) {
            return entities;
          }
          entities[idOccupant] = {
            ...state.entities[idOccupant],
            stratalots: (state.entities[idOccupant]?.stratalots as number[])?.filter(
              (idStratalot: number) => !idStratalots.some((id: number) => id === idStratalot)
            )
          } as OccupantEntityState;
          return entities;
        }, {} as Dictionary<OccupantEntityState>)
      }
    };
  }),

  on(OccupantActions.addManyProspectSuccess, (state: OccupantState, { idOccupant, idProspects }) => {
    if (!state.entities[idOccupant]) {
      return state;
    }
    const prospects = (state.entities[idOccupant]?.prospects as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idOccupant]: {
          ...state.entities[idOccupant],
          prospects: prospects.concat(idProspects.filter(id => prospects.indexOf(id) < 0))
        }
      }
    };
  }),

  on(OccupantActions.deleteManyProspectSuccess, (state: OccupantState, { idProspects, idOccupants }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idOccupants.reduce((entities, idOccupant) => {
          if (!state.entities[idOccupant]?.prospects) {
            return entities;
          }
          entities[idOccupant] = {
            ...state.entities[idOccupant],
            prospects: (state.entities[idOccupant]?.prospects as number[])?.filter(
              (idProspect: number) => !idProspects.some((id: number) => id === idProspect)
            )
          } as OccupantEntityState;
          return entities;
        }, {} as Dictionary<OccupantEntityState>)
      }
    };
  }),

  on(OccupantActions.addCompanySuccess, (state: OccupantState, { idOccupant, idCompany }) => {
    if (!state.entities[idOccupant]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idOccupant]: {
          ...state.entities[idOccupant],
          company: idCompany
        }
      }
    };
  }),

  on(OccupantActions.deleteManyCompanySuccess, (state: OccupantState, { idCompanies, idOccupants }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idOccupants.reduce((entities, idOccupant) => {
          if (!state.entities[idOccupant]?.company) {
            return entities;
          }
          entities[idOccupant] = {
            ...state.entities[idOccupant],
            company: idCompanies.some((idCompany: number) => idCompany === state.entities[idOccupant]?.company)
              ? undefined
              : state.entities[idOccupant]?.company
          } as OccupantEntityState;
          return entities;
        }, {} as Dictionary<OccupantEntityState>)
      }
    };
  })
];

export function setLoadingsState(state: OccupantState, isLoading: boolean, isLoaded: boolean = true): OccupantState {
  return { ...state, isLoaded, isLoading };
}
