import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as DroitActions from './droit.actions';
import { adapter, initialState, DroitState } from './droit.state';
import { Dictionary } from '@ngrx/entity';
import { DroitEntityState } from '@_model/interfaces/droit.model';

export const droitReducersGeneratedFunctions: ReducerTypes<DroitState, readonly ActionCreator[]>[] = [
  on(DroitActions.getOneDroit, (state: DroitState) => setLoadingsState(state, true)),
  on(DroitActions.getManyDroits, (state: DroitState) => setLoadingsState(state, true)),
  on(DroitActions.upsertOneDroit, (state: DroitState) => setLoadingsState(state, true)),

  on(DroitActions.upsertManyDroitsSuccess, (state: DroitState, { droits }) =>
    adapter.upsertMany(droits, setLoadingsState(state, false))
  ),
  on(DroitActions.deleteOneDroit, (state: DroitState) => setLoadingsState(state, true)),
  on(DroitActions.deleteOneDroitSuccess, (state: DroitState, { idDroit }) =>
    adapter.removeOne(idDroit, setLoadingsState(state, false))
  ),
  on(DroitActions.clearActive, (state: DroitState) => ({ ...state, actives: [] })),
  on(DroitActions.addManyActives, (state: DroitState, { idDroits }) => ({
    ...state,
    actives: state.actives.concat(idDroits)
  })),
  on(DroitActions.deleteOneActive, (state: DroitState, { idDroit }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idDroit)
  })),

  on(DroitActions.clearStore, () => initialState),

  on(DroitActions.addManyProfilSuccess, (state: DroitState, { idDroit, idProfils }) => {
    if (!state.entities[idDroit]) {
      return state;
    }
    const profils = (state.entities[idDroit]?.profils as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idDroit]: {
          ...state.entities[idDroit],
          profils: profils.concat(idProfils.filter(id => profils.indexOf(id) < 0))
        }
      }
    };
  }),

  on(DroitActions.deleteManyProfilSuccess, (state: DroitState, { idProfils, idDroits }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idDroits.reduce((entities, idDroit) => {
          if (!state.entities[idDroit]?.profils) {
            return entities;
          }
          entities[idDroit] = {
            ...state.entities[idDroit],
            profils: (state.entities[idDroit]?.profils as number[])?.filter(
              (idProfil: number) => !idProfils.some((id: number) => id === idProfil)
            )
          } as DroitEntityState;
          return entities;
        }, {} as Dictionary<DroitEntityState>)
      }
    };
  })
];

export function setLoadingsState(state: DroitState, isLoading: boolean, isLoaded: boolean = true): DroitState {
  return { ...state, isLoaded, isLoading };
}
