import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  CompanyTerritoireUser,
  CompanyTerritoireUserEntityState
} from '@_model/interfaces/company-territoire-user.model';
import { CompanyTerritoireUserApiService } from '@_services/api/company-territoire-user-api.service';
import * as companyTerritoireUserActions from '@_store/company-territoire-user/company-territoire-user.actions';
import { getActionsToNormalizeCompanyTerritoireUser } from '@_config/store/normalization.generated';
import { selectCompanyTerritoireUserState } from './company-territoire-user-generated.selectors';
import * as companyTerritoireActions from '@_store/company-territoire/company-territoire.actions';
import * as userActions from '@_store/user/user.actions';

export interface CompanyTerritoireUserRelationsIds {
  companyTerritoire?: number;
  user?: number;
}

export function getDefaultAddCompanyTerritoireUserActions(
  companyTerritoireUser: CompanyTerritoireUserEntityState,
  ids?: CompanyTerritoireUserRelationsIds
): Action[] {
  const actions: Action[] = [
    companyTerritoireUserActions.normalizeManyCompanyTerritoireUsersAfterUpsert({
      companyTerritoireUsers: [companyTerritoireUser]
    })
  ];

  if (ids?.companyTerritoire) {
    actions.push(
      companyTerritoireActions.addManyCompanyTerritoireUserSuccess({
        idCompanyTerritoire: ids.companyTerritoire,
        idCompanyTerritoireUsers: [companyTerritoireUser.idCompanyTerritoireUser]
      })
    );
    actions.push(
      companyTerritoireUserActions.addCompanyTerritoireSuccess({
        idCompanyTerritoireUser: companyTerritoireUser.idCompanyTerritoireUser,
        idCompanyTerritoire: ids.companyTerritoire
      })
    );
  }

  if (ids?.user) {
    actions.push(
      userActions.addManyCompanyTerritoireUserSuccess({
        idUser: ids.user,
        idCompanyTerritoireUsers: [companyTerritoireUser.idCompanyTerritoireUser]
      })
    );
    actions.push(
      companyTerritoireUserActions.addUserSuccess({
        idCompanyTerritoireUser: companyTerritoireUser.idCompanyTerritoireUser,
        idUser: ids.user
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCompanyTerritoireUserActions(
  companyTerritoireUser: CompanyTerritoireUserEntityState
): Action[] {
  const actions: Action[] = [
    companyTerritoireUserActions.deleteOneCompanyTerritoireUserSuccess({
      idCompanyTerritoireUser: companyTerritoireUser.idCompanyTerritoireUser
    })
  ];

  if (companyTerritoireUser.companyTerritoire) {
    actions.push(
      companyTerritoireActions.deleteManyCompanyTerritoireUserSuccess({
        idCompanyTerritoireUsers: [companyTerritoireUser.idCompanyTerritoireUser],
        idCompanyTerritoires: [companyTerritoireUser.companyTerritoire as number]
      })
    );
  }

  if (companyTerritoireUser.user) {
    actions.push(
      userActions.deleteManyCompanyTerritoireUserSuccess({
        idCompanyTerritoireUsers: [companyTerritoireUser.idCompanyTerritoireUser],
        idUsers: [companyTerritoireUser.user as number]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyTerritoireUserEffects {
  constructor(
    protected actions$: Actions,
    protected companyTerritoireUserApiService: CompanyTerritoireUserApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyTerritoireUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyTerritoireUserActions.getManyCompanyTerritoireUsers),
      switchMap(({ params }) =>
        this.companyTerritoireUserApiService.getCompanyTerritoireUsers(params).pipe(
          map((companyTerritoireUsers: CompanyTerritoireUser[]) => {
            return companyTerritoireUserActions.normalizeManyCompanyTerritoireUsersAfterUpsert({
              companyTerritoireUsers
            });
          }),
          catchError(error => of(companyTerritoireUserActions.companyTerritoireUsersFailure({ error })))
        )
      )
    );
  });

  getOneCompanyTerritoireUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyTerritoireUserActions.getOneCompanyTerritoireUser),
      switchMap(idCompanyTerritoireUser =>
        this.companyTerritoireUserApiService.getCompanyTerritoireUser(idCompanyTerritoireUser).pipe(
          map((companyTerritoireUser: CompanyTerritoireUser) => {
            return companyTerritoireUserActions.normalizeManyCompanyTerritoireUsersAfterUpsert({
              companyTerritoireUsers: [companyTerritoireUser]
            });
          }),
          catchError(error => of(companyTerritoireUserActions.companyTerritoireUsersFailure({ error })))
        )
      )
    );
  });

  upsertOneCompanyTerritoireUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyTerritoireUserActions.upsertOneCompanyTerritoireUser),
      concatMap(
        ({
          companyTerritoireUser,
          ids
        }: {
          companyTerritoireUser: Partial<CompanyTerritoireUser>;
          ids?: CompanyTerritoireUserRelationsIds;
        }) => {
          if (companyTerritoireUser.idCompanyTerritoireUser) {
            return this.companyTerritoireUserApiService.updateCompanyTerritoireUser(companyTerritoireUser).pipe(
              map((companyTerritoireUserReturned: CompanyTerritoireUser) => {
                return companyTerritoireUserActions.normalizeManyCompanyTerritoireUsersAfterUpsert({
                  companyTerritoireUsers: [companyTerritoireUserReturned]
                });
              }),
              catchError(error => of(companyTerritoireUserActions.companyTerritoireUsersFailure({ error })))
            );
          } else {
            return this.companyTerritoireUserApiService.addCompanyTerritoireUser(companyTerritoireUser).pipe(
              mergeMap((companyTerritoireUserReturned: CompanyTerritoireUser) =>
                getDefaultAddCompanyTerritoireUserActions(companyTerritoireUserReturned, ids)
              ),
              catchError(error => of(companyTerritoireUserActions.companyTerritoireUsersFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCompanyTerritoireUser$ = createEffect(() => {
    const selectCompanyTerritoireUserState$ = this.store$.select(selectCompanyTerritoireUserState);
    return this.actions$.pipe(
      ofType(companyTerritoireUserActions.deleteOneCompanyTerritoireUser),
      withLatestFrom(selectCompanyTerritoireUserState$),
      concatMap(([{ idCompanyTerritoireUser }, state]) =>
        this.companyTerritoireUserApiService.deleteCompanyTerritoireUser(idCompanyTerritoireUser).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCompanyTerritoireUserActions(
                state.entities[idCompanyTerritoireUser] as CompanyTerritoireUserEntityState
              ),
              companyTerritoireUserActions.deleteOneCompanyTerritoireUser.type
            )
          ]),
          catchError(error => of(companyTerritoireUserActions.companyTerritoireUsersFailure({ error })))
        )
      )
    );
  });

  normalizeManyCompanyTerritoireUsersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyTerritoireUserActions.normalizeManyCompanyTerritoireUsersAfterUpsert),
      concatMap(({ companyTerritoireUsers }) => {
        const actions: Action[] = getActionsToNormalizeCompanyTerritoireUser(
          companyTerritoireUsers,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CompanyTerritoireUser] Normalization After Upsert Success')];
      })
    );
  });
}
