import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as StratalotActions from './stratalot.actions';
import { adapter, initialState, StratalotState } from './stratalot.state';
import { Dictionary } from '@ngrx/entity';
import { StratalotEntityState } from '@_model/interfaces/stratalot.model';

export const stratalotReducersGeneratedFunctions: ReducerTypes<StratalotState, readonly ActionCreator[]>[] = [
  on(StratalotActions.getOneStratalot, (state: StratalotState) => setLoadingsState(state, true)),
  on(StratalotActions.getManyStratalots, (state: StratalotState) => setLoadingsState(state, true)),
  on(StratalotActions.upsertOneStratalot, (state: StratalotState) => setLoadingsState(state, true)),

  on(StratalotActions.upsertManyStratalotsSuccess, (state: StratalotState, { stratalots }) =>
    adapter.upsertMany(stratalots, setLoadingsState(state, false))
  ),
  on(StratalotActions.deleteOneStratalot, (state: StratalotState) => setLoadingsState(state, true)),
  on(StratalotActions.deleteOneStratalotSuccess, (state: StratalotState, { idStratalot }) =>
    adapter.removeOne(idStratalot, setLoadingsState(state, false))
  ),
  on(StratalotActions.clearActive, (state: StratalotState) => ({ ...state, actives: [] })),
  on(StratalotActions.addManyActives, (state: StratalotState, { idStratalots }) => ({
    ...state,
    actives: state.actives.concat(idStratalots)
  })),
  on(StratalotActions.deleteOneActive, (state: StratalotState, { idStratalot }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idStratalot)
  })),

  on(StratalotActions.clearStore, () => initialState),

  on(
    StratalotActions.addManyStratalotCampaignSuccess,
    (state: StratalotState, { idStratalot, idStratalotCampaigns }) => {
      if (!state.entities[idStratalot]) {
        return state;
      }
      const stratalotCampaigns = (state.entities[idStratalot]?.stratalotCampaigns as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalot]: {
            ...state.entities[idStratalot],
            stratalotCampaigns: stratalotCampaigns.concat(
              idStratalotCampaigns.filter(id => stratalotCampaigns.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    StratalotActions.deleteManyStratalotCampaignSuccess,
    (state: StratalotState, { idStratalotCampaigns, idStratalots }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalots.reduce((entities, idStratalot) => {
            if (!state.entities[idStratalot]?.stratalotCampaigns) {
              return entities;
            }
            entities[idStratalot] = {
              ...state.entities[idStratalot],
              stratalotCampaigns: (state.entities[idStratalot]?.stratalotCampaigns as number[])?.filter(
                (idStratalotCampaign: number) => !idStratalotCampaigns.some((id: number) => id === idStratalotCampaign)
              )
            } as StratalotEntityState;
            return entities;
          }, {} as Dictionary<StratalotEntityState>)
        }
      };
    }
  ),

  on(StratalotActions.addManyDiffusionVisualSuccess, (state: StratalotState, { idStratalot, idDiffusionVisuals }) => {
    if (!state.entities[idStratalot]) {
      return state;
    }
    const diffusionVisuals = (state.entities[idStratalot]?.diffusionVisuals as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalot]: {
          ...state.entities[idStratalot],
          diffusionVisuals: diffusionVisuals.concat(idDiffusionVisuals.filter(id => diffusionVisuals.indexOf(id) < 0))
        }
      }
    };
  }),

  on(
    StratalotActions.deleteManyDiffusionVisualSuccess,
    (state: StratalotState, { idDiffusionVisuals, idStratalots }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalots.reduce((entities, idStratalot) => {
            if (!state.entities[idStratalot]?.diffusionVisuals) {
              return entities;
            }
            entities[idStratalot] = {
              ...state.entities[idStratalot],
              diffusionVisuals: (state.entities[idStratalot]?.diffusionVisuals as number[])?.filter(
                (idDiffusionVisual: number) => !idDiffusionVisuals.some((id: number) => id === idDiffusionVisual)
              )
            } as StratalotEntityState;
            return entities;
          }, {} as Dictionary<StratalotEntityState>)
        }
      };
    }
  ),

  on(
    StratalotActions.addManyStratalotAssociationSuccess,
    (state: StratalotState, { idStratalot, idStratalotAssociations }) => {
      if (!state.entities[idStratalot]) {
        return state;
      }
      const stratalotAssociations = (state.entities[idStratalot]?.stratalotAssociations as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalot]: {
            ...state.entities[idStratalot],
            stratalotAssociations: stratalotAssociations.concat(
              idStratalotAssociations.filter(id => stratalotAssociations.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    StratalotActions.deleteManyStratalotAssociationSuccess,
    (state: StratalotState, { idStratalotAssociations, idStratalots }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalots.reduce((entities, idStratalot) => {
            if (!state.entities[idStratalot]?.stratalotAssociations) {
              return entities;
            }
            entities[idStratalot] = {
              ...state.entities[idStratalot],
              stratalotAssociations: (state.entities[idStratalot]?.stratalotAssociations as number[])?.filter(
                (idStratalotAssociation: number) =>
                  !idStratalotAssociations.some((id: number) => id === idStratalotAssociation)
              )
            } as StratalotEntityState;
            return entities;
          }, {} as Dictionary<StratalotEntityState>)
        }
      };
    }
  ),

  on(StratalotActions.addManyStratalotFeeSuccess, (state: StratalotState, { idStratalot, idStratalotFees }) => {
    if (!state.entities[idStratalot]) {
      return state;
    }
    const stratalotFees = (state.entities[idStratalot]?.stratalotFees as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalot]: {
          ...state.entities[idStratalot],
          stratalotFees: stratalotFees.concat(idStratalotFees.filter(id => stratalotFees.indexOf(id) < 0))
        }
      }
    };
  }),

  on(StratalotActions.deleteManyStratalotFeeSuccess, (state: StratalotState, { idStratalotFees, idStratalots }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idStratalots.reduce((entities, idStratalot) => {
          if (!state.entities[idStratalot]?.stratalotFees) {
            return entities;
          }
          entities[idStratalot] = {
            ...state.entities[idStratalot],
            stratalotFees: (state.entities[idStratalot]?.stratalotFees as number[])?.filter(
              (idStratalotFee: number) => !idStratalotFees.some((id: number) => id === idStratalotFee)
            )
          } as StratalotEntityState;
          return entities;
        }, {} as Dictionary<StratalotEntityState>)
      }
    };
  }),

  on(StratalotActions.addManyAssociationSuccess, (state: StratalotState, { idStratalot, idAssociations }) => {
    if (!state.entities[idStratalot]) {
      return state;
    }
    const associations = (state.entities[idStratalot]?.associations as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalot]: {
          ...state.entities[idStratalot],
          associations: associations.concat(idAssociations.filter(id => associations.indexOf(id) < 0))
        }
      }
    };
  }),

  on(StratalotActions.deleteManyAssociationSuccess, (state: StratalotState, { idAssociations, idStratalots }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idStratalots.reduce((entities, idStratalot) => {
          if (!state.entities[idStratalot]?.associations) {
            return entities;
          }
          entities[idStratalot] = {
            ...state.entities[idStratalot],
            associations: (state.entities[idStratalot]?.associations as number[])?.filter(
              (idAssociation: number) => !idAssociations.some((id: number) => id === idAssociation)
            )
          } as StratalotEntityState;
          return entities;
        }, {} as Dictionary<StratalotEntityState>)
      }
    };
  }),

  on(StratalotActions.addManyLeadSuccess, (state: StratalotState, { idStratalot, idLeads }) => {
    if (!state.entities[idStratalot]) {
      return state;
    }
    const leads = (state.entities[idStratalot]?.leads as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalot]: {
          ...state.entities[idStratalot],
          leads: leads.concat(idLeads.filter(id => leads.indexOf(id) < 0))
        }
      }
    };
  }),

  on(StratalotActions.deleteManyLeadSuccess, (state: StratalotState, { idLeads, idStratalots }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idStratalots.reduce((entities, idStratalot) => {
          if (!state.entities[idStratalot]?.leads) {
            return entities;
          }
          entities[idStratalot] = {
            ...state.entities[idStratalot],
            leads: (state.entities[idStratalot]?.leads as number[])?.filter(
              (idLead: number) => !idLeads.some((id: number) => id === idLead)
            )
          } as StratalotEntityState;
          return entities;
        }, {} as Dictionary<StratalotEntityState>)
      }
    };
  }),

  on(StratalotActions.addManyLeadStratalotSuccess, (state: StratalotState, { idStratalot, idLeadStratalots }) => {
    if (!state.entities[idStratalot]) {
      return state;
    }
    const leadStratalots = (state.entities[idStratalot]?.leadStratalots as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalot]: {
          ...state.entities[idStratalot],
          leadStratalots: leadStratalots.concat(idLeadStratalots.filter(id => leadStratalots.indexOf(id) < 0))
        }
      }
    };
  }),

  on(StratalotActions.deleteManyLeadStratalotSuccess, (state: StratalotState, { idLeadStratalots, idStratalots }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idStratalots.reduce((entities, idStratalot) => {
          if (!state.entities[idStratalot]?.leadStratalots) {
            return entities;
          }
          entities[idStratalot] = {
            ...state.entities[idStratalot],
            leadStratalots: (state.entities[idStratalot]?.leadStratalots as number[])?.filter(
              (idLeadStratalot: number) => !idLeadStratalots.some((id: number) => id === idLeadStratalot)
            )
          } as StratalotEntityState;
          return entities;
        }, {} as Dictionary<StratalotEntityState>)
      }
    };
  }),

  on(StratalotActions.addManyStratalotPriceSuccess, (state: StratalotState, { idStratalot, idStratalotPrices }) => {
    if (!state.entities[idStratalot]) {
      return state;
    }
    const stratalotPrices = (state.entities[idStratalot]?.stratalotPrices as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalot]: {
          ...state.entities[idStratalot],
          stratalotPrices: stratalotPrices.concat(idStratalotPrices.filter(id => stratalotPrices.indexOf(id) < 0))
        }
      }
    };
  }),

  on(StratalotActions.deleteManyStratalotPriceSuccess, (state: StratalotState, { idStratalotPrices, idStratalots }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idStratalots.reduce((entities, idStratalot) => {
          if (!state.entities[idStratalot]?.stratalotPrices) {
            return entities;
          }
          entities[idStratalot] = {
            ...state.entities[idStratalot],
            stratalotPrices: (state.entities[idStratalot]?.stratalotPrices as number[])?.filter(
              (idStratalotPrice: number) => !idStratalotPrices.some((id: number) => id === idStratalotPrice)
            )
          } as StratalotEntityState;
          return entities;
        }, {} as Dictionary<StratalotEntityState>)
      }
    };
  }),

  on(StratalotActions.addStratalotRcpSuccess, (state: StratalotState, { idStratalot, idStratalotRcp }) => {
    if (!state.entities[idStratalot]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalot]: {
          ...state.entities[idStratalot],
          stratalotRcp: idStratalotRcp
        }
      }
    };
  }),

  on(StratalotActions.deleteStratalotRcpSuccess, (state: StratalotState, { idStratalotRcps, idStratalots }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idStratalots.reduce((entities, idStratalot) => {
          if (!state.entities[idStratalot]?.stratalotRcp) {
            return entities;
          }
          entities[idStratalot] = {
            ...state.entities[idStratalot],
            stratalotRcp: idStratalotRcps.some(
              (idStratalotRcp: number) => idStratalotRcp === state.entities[idStratalot].stratalotRcp
            )
              ? undefined
              : state.entities[idStratalot].stratalotRcp
          };
          return entities;
        }, {})
      }
    };
  }),
  on(StratalotActions.addManyStratalotTodoSuccess, (state: StratalotState, { idStratalot, idStratalotTodos }) => {
    if (!state.entities[idStratalot]) {
      return state;
    }
    const stratalotTodos = (state.entities[idStratalot]?.stratalotTodos as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalot]: {
          ...state.entities[idStratalot],
          stratalotTodos: stratalotTodos.concat(idStratalotTodos.filter(id => stratalotTodos.indexOf(id) < 0))
        }
      }
    };
  }),

  on(StratalotActions.deleteManyStratalotTodoSuccess, (state: StratalotState, { idStratalotTodos, idStratalots }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idStratalots.reduce((entities, idStratalot) => {
          if (!state.entities[idStratalot]?.stratalotTodos) {
            return entities;
          }
          entities[idStratalot] = {
            ...state.entities[idStratalot],
            stratalotTodos: (state.entities[idStratalot]?.stratalotTodos as number[])?.filter(
              (idStratalotTodo: number) => !idStratalotTodos.some((id: number) => id === idStratalotTodo)
            )
          } as StratalotEntityState;
          return entities;
        }, {} as Dictionary<StratalotEntityState>)
      }
    };
  }),

  on(StratalotActions.addResidenceSuccess, (state: StratalotState, { idStratalot, idResidence }) => {
    if (!state.entities[idStratalot]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalot]: {
          ...state.entities[idStratalot],
          residence: idResidence
        }
      }
    };
  }),

  on(StratalotActions.deleteManyResidenceSuccess, (state: StratalotState, { idResidences, idStratalots }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idStratalots.reduce((entities, idStratalot) => {
          if (!state.entities[idStratalot]?.residence) {
            return entities;
          }
          entities[idStratalot] = {
            ...state.entities[idStratalot],
            residence: idResidences.some(
              (idResidence: number) => idResidence === state.entities[idStratalot]?.residence
            )
              ? undefined
              : state.entities[idStratalot]?.residence
          } as StratalotEntityState;
          return entities;
        }, {} as Dictionary<StratalotEntityState>)
      }
    };
  }),

  on(StratalotActions.addOccupantSuccess, (state: StratalotState, { idStratalot, idOccupant }) => {
    if (!state.entities[idStratalot]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalot]: {
          ...state.entities[idStratalot],
          occupant: idOccupant
        }
      }
    };
  }),

  on(StratalotActions.deleteManyOccupantSuccess, (state: StratalotState, { idOccupants, idStratalots }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idStratalots.reduce((entities, idStratalot) => {
          if (!state.entities[idStratalot]?.occupant) {
            return entities;
          }
          entities[idStratalot] = {
            ...state.entities[idStratalot],
            occupant: idOccupants.some((idOccupant: number) => idOccupant === state.entities[idStratalot]?.occupant)
              ? undefined
              : state.entities[idStratalot]?.occupant
          } as StratalotEntityState;
          return entities;
        }, {} as Dictionary<StratalotEntityState>)
      }
    };
  }),

  on(
    StratalotActions.addCompanyStratalotTypeSuccess,
    (state: StratalotState, { idStratalot, idCompanyStratalotType }) => {
      if (!state.entities[idStratalot]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalot]: {
            ...state.entities[idStratalot],
            companyStratalotType: idCompanyStratalotType
          }
        }
      };
    }
  ),

  on(
    StratalotActions.deleteManyCompanyStratalotTypeSuccess,
    (state: StratalotState, { idCompanyStratalotTypes, idStratalots }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalots.reduce((entities, idStratalot) => {
            if (!state.entities[idStratalot]?.companyStratalotType) {
              return entities;
            }
            entities[idStratalot] = {
              ...state.entities[idStratalot],
              companyStratalotType: idCompanyStratalotTypes.some(
                (idCompanyStratalotType: number) =>
                  idCompanyStratalotType === state.entities[idStratalot]?.companyStratalotType
              )
                ? undefined
                : state.entities[idStratalot]?.companyStratalotType
            } as StratalotEntityState;
            return entities;
          }, {} as Dictionary<StratalotEntityState>)
        }
      };
    }
  ),

  on(
    StratalotActions.addCompanyStratalotVacantSuccess,
    (state: StratalotState, { idStratalot, idCompanyStratalotVacant }) => {
      if (!state.entities[idStratalot]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalot]: {
            ...state.entities[idStratalot],
            companyStratalotVacant: idCompanyStratalotVacant
          }
        }
      };
    }
  ),

  on(
    StratalotActions.deleteManyCompanyStratalotVacantSuccess,
    (state: StratalotState, { idCompanyStratalotVacants, idStratalots }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalots.reduce((entities, idStratalot) => {
            if (!state.entities[idStratalot]?.companyStratalotVacant) {
              return entities;
            }
            entities[idStratalot] = {
              ...state.entities[idStratalot],
              companyStratalotVacant: idCompanyStratalotVacants.some(
                (idCompanyStratalotVacant: number) =>
                  idCompanyStratalotVacant === state.entities[idStratalot]?.companyStratalotVacant
              )
                ? undefined
                : state.entities[idStratalot]?.companyStratalotVacant
            } as StratalotEntityState;
            return entities;
          }, {} as Dictionary<StratalotEntityState>)
        }
      };
    }
  ),

  on(
    StratalotActions.addOrganizationSaleCategorySuccess,
    (state: StratalotState, { idStratalot, idOrganizationSaleCategory }) => {
      if (!state.entities[idStratalot]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalot]: {
            ...state.entities[idStratalot],
            organizationSaleCategory: idOrganizationSaleCategory
          }
        }
      };
    }
  ),

  on(
    StratalotActions.deleteManyOrganizationSaleCategorySuccess,
    (state: StratalotState, { idOrganizationSaleCategories, idStratalots }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalots.reduce((entities, idStratalot) => {
            if (!state.entities[idStratalot]?.organizationSaleCategory) {
              return entities;
            }
            entities[idStratalot] = {
              ...state.entities[idStratalot],
              organizationSaleCategory: idOrganizationSaleCategories.some(
                (idOrganizationSaleCategory: number) =>
                  idOrganizationSaleCategory === state.entities[idStratalot]?.organizationSaleCategory
              )
                ? undefined
                : state.entities[idStratalot]?.organizationSaleCategory
            } as StratalotEntityState;
            return entities;
          }, {} as Dictionary<StratalotEntityState>)
        }
      };
    }
  ),

  on(StratalotActions.addStepProgressSuccess, (state: StratalotState, { idStratalot, idStepProgress }) => {
    if (!state.entities[idStratalot]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalot]: {
          ...state.entities[idStratalot],
          stepProgress: idStepProgress
        }
      }
    };
  }),

  on(StratalotActions.deleteManyStepProgressSuccess, (state: StratalotState, { idStepProgresses, idStratalots }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idStratalots.reduce((entities, idStratalot) => {
          if (!state.entities[idStratalot]?.stepProgress) {
            return entities;
          }
          entities[idStratalot] = {
            ...state.entities[idStratalot],
            stepProgress: idStepProgresses.some(
              (idStepProgress: number) => idStepProgress === state.entities[idStratalot]?.stepProgress
            )
              ? undefined
              : state.entities[idStratalot]?.stepProgress
          } as StratalotEntityState;
          return entities;
        }, {} as Dictionary<StratalotEntityState>)
      }
    };
  }),

  on(StratalotActions.addResponsableSuccess, (state: StratalotState, { idStratalot, idResponsable }) => {
    if (!state.entities[idStratalot]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalot]: {
          ...state.entities[idStratalot],
          responsable: idResponsable
        }
      }
    };
  }),

  on(StratalotActions.deleteManyResponsableSuccess, (state: StratalotState, { idResponsable, idStratalots }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idStratalots.reduce((entities, idStratalot) => {
          if (!state.entities[idStratalot]?.responsable) {
            return entities;
          }
          entities[idStratalot] = {
            ...state.entities[idStratalot],
            user: idResponsable.some((idUser: number) => idUser === state.entities[idStratalot]?.responsable)
              ? undefined
              : state.entities[idStratalot]?.responsable
          } as StratalotEntityState;
          return entities;
        }, {} as Dictionary<StratalotEntityState>)
      }
    };
  }),

  on(
    StratalotActions.addOrganizationStratalotAvancementSuccess,
    (state: StratalotState, { idStratalot, idOrganizationStratalotAvancement }) => {
      if (!state.entities[idStratalot]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalot]: {
            ...state.entities[idStratalot],
            organizationStratalotAvancement: idOrganizationStratalotAvancement
          }
        }
      };
    }
  ),

  on(
    StratalotActions.deleteManyOrganizationStratalotAvancementSuccess,
    (state: StratalotState, { idOrganizationStratalotAvancements, idStratalots }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalots.reduce((entities, idStratalot) => {
            if (!state.entities[idStratalot]?.organizationStratalotAvancement) {
              return entities;
            }
            entities[idStratalot] = {
              ...state.entities[idStratalot],
              organizationStratalotAvancement: idOrganizationStratalotAvancements.some(
                (idOrganizationStratalotAvancement: number) =>
                  idOrganizationStratalotAvancement === state.entities[idStratalot]?.organizationStratalotAvancement
              )
                ? undefined
                : state.entities[idStratalot]?.organizationStratalotAvancement
            } as StratalotEntityState;
            return entities;
          }, {} as Dictionary<StratalotEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(state: StratalotState, isLoading: boolean, isLoaded: boolean = true): StratalotState {
  return { ...state, isLoaded, isLoading };
}
