import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuItem, MenuSection } from '@_shared/interfaces/menu-section.interface';
import { Company } from '@_shared/models/interfaces/company.model';
import { Organization } from '@_shared/models/interfaces/organization.model';
import { SaleFamilyCategoryRoutingEnum } from '@_utils/router/path-enum/sale-family-category-routing.enum';
import RouterUtils from '@_utils/router/router.utils';
import SaleFamilyCategoryUtils from '@_utils/router/sale-family-category.util';
import MenuUtils from '@_utils/up-utils/menu.utils';
import { combineLatest, ReplaySubject } from 'rxjs';
import { filter, startWith, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideMenuComponent implements OnInit, OnChanges {
  @Input() public menuSections: MenuSection[];
  @Input() public companies: Company[];
  @Input() public organization: Organization;
  @Input() public categorieVentes: SaleFamilyCategoryRoutingEnum[];
  @Input() public activeSaleCategorie: SaleFamilyCategoryRoutingEnum = SaleFamilyCategoryRoutingEnum.ancien;
  @Input() public isUserAdmin = false;
  @Output() private navigateToLoginPage = new EventEmitter<void>();
  @Output() private companyChange = new EventEmitter<Company[]>();
  @Output() private saleCategoryChange = new EventEmitter<SaleFamilyCategoryRoutingEnum>();
  @Output() private sectionChange = new EventEmitter<void>();
  public activeSection: MenuItem;
  public companiesFilters = new FormControl();
  public categorieVenteFilter = new FormControl();
  private allMenuItems: MenuItem[];
  public isUnbuilt: boolean;
  public hasAccessToUnbuilt: boolean;

  private sectionsUpdated$ = new ReplaySubject<void>(1);
  private routeUpdated$ = new ReplaySubject<void>(1);

  constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeSaleCategorie?.currentValue) {
      this.categorieVenteFilter.patchValue(changes.activeSaleCategorie.currentValue, { emitEvent: false });
      this.isUnbuilt = SaleFamilyCategoryUtils.isUnbuilt();
    }

    if (changes.menuSections?.currentValue.length) {
      if (!this.companiesFilters.dirty) {
        this.companiesFilters.patchValue(this.companies, { emitEvent: false });
        this.changeDetectorRef.markForCheck();
      }
      this.sectionsUpdated$.next();
    }
  }

  public ngOnInit(): void {
    this.hasAccessToUnbuilt = this.organization.accesNeuf;
    this.companiesFilters.valueChanges
      .pipe(startWith(this.companiesFilters.value), untilDestroyed(this))
      .subscribe((companiesFiltersValue: Company[] | Company) => {
        if (companiesFiltersValue) {
          this.companyChange.emit(
            Array.isArray(companiesFiltersValue) ? companiesFiltersValue : [companiesFiltersValue]
          );
        }
      });
    this.categorieVenteFilter.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((contextsFilterValue: SaleFamilyCategoryRoutingEnum) => {
        this.saleCategoryChange.emit(contextsFilterValue);
      });

    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        startWith(true),
        tap(() => {
          this.activeSection = this.allMenuItems?.find((section: MenuItem): boolean =>
            this.router.url.includes(RouterUtils.getSaleCategoryUrl(section.route))
          );
          this.menuSections.forEach((menuSection: MenuSection) => {
            menuSection.opened = menuSection?.children?.map(child => child?.id).includes(this.activeSection?.id);
          });

          this.changeDetectorRef.markForCheck();

          this.routeUpdated$.next();
        }),

        untilDestroyed(this)
      )
      .subscribe();

    combineLatest([this.sectionsUpdated$, this.routeUpdated$])
      .pipe(
        tap(() => {
          const allChildItems: MenuItem[] = [];
          this.menuSections
            .filter((menuSection: MenuSection) => menuSection.children?.length)
            .forEach((menuSection: MenuSection) => {
              allChildItems.push(...menuSection.children);
            });
          this.allMenuItems = [
            ...(this.menuSections.filter(section => !!section.route) as MenuItem[]),
            ...allChildItems
          ].filter(item => !item.admin);

          this.closeAllSections();
          this.openCurrentSection();
          this.changeDetectorRef.markForCheck();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private closeAllSections(section?: MenuSection): void {
    this.menuSections
      .filter(menuSection => (section ? menuSection.id !== section.id : true))
      .forEach(menuSection => {
        menuSection.opened = false;
      });
  }

  private openCurrentSection(): void {
    this.activeSection = this.allMenuItems?.find((section: MenuItem): boolean =>
      this.router.url.includes(RouterUtils.getSaleCategoryUrl(section.route))
    );
    this.menuSections.forEach((menuSection: MenuSection) => {
      if (menuSection?.children?.map(child => child?.id).includes(this.activeSection?.id)) {
        menuSection.opened = true;
      }
    });
  }

  public onClick(section: MenuItem | MenuSection): void {
    const selectedMenuSection = section as MenuSection;

    if (selectedMenuSection.children?.length) {
      this.closeAllSections(selectedMenuSection);
      selectedMenuSection.opened = !selectedMenuSection.opened;
      this.openCurrentSection();
      this.changeDetectorRef.markForCheck();
    } else if (selectedMenuSection.label === MenuUtils.LogOutLabel) {
      this.navigateToLoginPage.emit();
    } else if (selectedMenuSection.route) {
      this.sectionChange.emit();
      this.router.navigateByUrl(RouterUtils.getSaleCategoryUrl(selectedMenuSection.route));
    } else {
      window.open(selectedMenuSection.url);
    }
  }

  public computeMaxHeight(elementRef: Element): string {
    return elementRef.scrollHeight + 'px';
  }

  public get defaultSections(): MenuSection[] {
    return this.menuSections.filter(
      (menuSection: MenuSection) => menuSection.align === 'default' || !menuSection.align
    );
  }

  public get fixedOnBottomSections(): MenuSection[] {
    return this.menuSections.filter((menuSection: MenuSection) => menuSection.align === 'bottom');
  }

  public compareWith(a, b): boolean {
    if (Array.isArray(a) && Array.isArray(b)) {
      return true;
    }
    return a?.idCompany === b?.idCompany;
  }

  public compare(a, b): boolean {
    return a === b;
  }
}
