import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SaleCategoryFamilyApiService } from '@_services/api/sale-category-family-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedSaleCategoryFamilyEffects } from './sale-category-family-generated.effects';

@Injectable()
export class SaleCategoryFamilyEffects extends GeneratedSaleCategoryFamilyEffects {
  constructor(actions$: Actions, saleCategoryFamilyApiService: SaleCategoryFamilyApiService, store$: Store<AppState>) {
    super(actions$, saleCategoryFamilyApiService, store$);
  }
}
