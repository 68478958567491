import { OrganizationLeadTodoProfilState, initialState } from './organization-lead-todo-profil.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationLeadTodoProfilReducersGeneratedFunctions } from './organization-lead-todo-profil-generated.reducer';

const organizationLeadTodoProfilReducersFunctions = [...organizationLeadTodoProfilReducersGeneratedFunctions];

const organizationLeadTodoProfilReducer = createReducer(initialState, ...organizationLeadTodoProfilReducersFunctions);

export function reducer(state: OrganizationLeadTodoProfilState | undefined, action: Action) {
  return organizationLeadTodoProfilReducer(state, action);
}
