import { StratalotPriceState, initialState } from './stratalot-price.state';
import { Action, createReducer } from '@ngrx/store';
import { stratalotPriceReducersGeneratedFunctions } from './stratalot-price-generated.reducer';

const stratalotPriceReducersFunctions = [...stratalotPriceReducersGeneratedFunctions];

const stratalotPriceReducer = createReducer(initialState, ...stratalotPriceReducersFunctions);

export function reducer(state: StratalotPriceState | undefined, action: Action) {
  return stratalotPriceReducer(state, action);
}
