import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationProspectOrigin,
  OrganizationProspectOriginEntityState
} from '@_model/interfaces/organization-prospect-origin.model';
import { Prospect, ProspectEntityState } from '@_model/interfaces/prospect.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationProspectOriginFeatureKey,
  OrganizationProspectOriginState
} from './organization-prospect-origin.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationProspectOriginRelations: string[] = ['prospects', 'organizations'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationProspectOriginState = createFeatureSelector<OrganizationProspectOriginState>(
  organizationProspectOriginFeatureKey
);

export const selectIsLoadedOrganizationProspectOrigin = createSelector(
  selectOrganizationProspectOriginState,
  (state: OrganizationProspectOriginState) => state.isLoaded
);

export const selectIsLoadingOrganizationProspectOrigin = createSelector(
  selectOrganizationProspectOriginState,
  (state: OrganizationProspectOriginState) => state.isLoading
);

export const selectIsReadyOrganizationProspectOrigin = createSelector(
  selectOrganizationProspectOriginState,
  (state: OrganizationProspectOriginState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationProspectOrigin = createSelector(
  selectOrganizationProspectOriginState,
  (state: OrganizationProspectOriginState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationProspectOriginModel: SelectorModel = {
  name: 'organizationProspectOrigins',
  getSelector: selectAllOrganizationProspectOriginsDictionary,
  isReady: selectIsReadyOrganizationProspectOrigin
};

export const selectOrganizationProspectOriginsEntities = createSelector(
  selectOrganizationProspectOriginState,
  selectEntities
);

export const selectOrganizationProspectOriginsArray = createSelector(selectOrganizationProspectOriginState, selectAll);

export const selectIdOrganizationProspectOriginsActive = createSelector(
  selectOrganizationProspectOriginState,
  (state: OrganizationProspectOriginState) => state.actives
);

const organizationProspectOriginsInObject = (
  organizationProspectOrigins: Dictionary<OrganizationProspectOriginEntityState>
) => ({ organizationProspectOrigins });

const selectOrganizationProspectOriginsEntitiesDictionary = createSelector(
  selectOrganizationProspectOriginsEntities,
  organizationProspectOriginsInObject
);

const selectAllOrganizationProspectOriginsObject = createSelector(
  selectOrganizationProspectOriginsEntities,
  organizationProspectOrigins => {
    return hydrateAll({ organizationProspectOrigins });
  }
);

const selectOneOrganizationProspectOriginDictionary = (idOrganizationProspectOrigin: number) =>
  createSelector(selectOrganizationProspectOriginsEntities, organizationProspectOrigins => {
    return {
      organizationProspectOrigins: {
        [idOrganizationProspectOrigin]: organizationProspectOrigins[idOrganizationProspectOrigin]
      }
    };
  });

const selectOneOrganizationProspectOriginDictionaryWithoutChild = (idOrganizationProspectOrigin: number) =>
  createSelector(selectOrganizationProspectOriginsEntities, organizationProspectOrigins => {
    return { organizationProspectOrigin: organizationProspectOrigins[idOrganizationProspectOrigin] };
  });

const selectActiveOrganizationProspectOriginsEntities = createSelector(
  selectIdOrganizationProspectOriginsActive,
  selectOrganizationProspectOriginsEntities,
  (actives: number[], organizationProspectOrigins: Dictionary<OrganizationProspectOriginEntityState>) =>
    getOrganizationProspectOriginsFromActives(actives, organizationProspectOrigins)
);

function getOrganizationProspectOriginsFromActives(
  actives: number[],
  organizationProspectOrigins: Dictionary<OrganizationProspectOriginEntityState>
): Dictionary<OrganizationProspectOriginEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationProspectOrigins[idActive]) {
      acc[idActive] = organizationProspectOrigins[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationProspectOriginEntityState>);
}

const selectAllOrganizationProspectOriginsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationProspectOrigins(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationProspectOrigin>(
      schema,
      selectAllOrganizationProspectOriginsSelectors,
      selectOrganizationProspectOriginsEntitiesDictionary,
      getRelationSelectors,
      organizationProspectOriginRelations,
      hydrateAll,
      'organizationProspectOrigin'
    );
  } else {
    return selectAllOrganizationProspectOriginsObject;
  }
}

export function selectAllOrganizationProspectOriginsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationProspectOrigins'
): Selector {
  return createSelector(selectAllOrganizationProspectOrigins(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationProspectOriginEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationProspectOrigins.length; i++) {
      res[customKey][result.organizationProspectOrigins[i].idOrganizationProspectOrigin] =
        result.organizationProspectOrigins[i];
    }
    return res;
  });
}

export function selectOneOrganizationProspectOrigin(
  schema: SelectSchema = {},
  idOrganizationProspectOrigin: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationProspectOriginDictionary(idOrganizationProspectOrigin)];
    selectors.push(...getRelationSelectors(schema, organizationProspectOriginRelations, 'organizationProspectOrigin'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationProspectOriginDictionaryWithoutChild(idOrganizationProspectOrigin);
  }
}

export function selectActiveOrganizationProspectOrigins(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationProspectOriginsEntities, organizationProspectOrigins => ({
      organizationProspectOrigins
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationProspectOriginRelations, 'organizationProspectOrigin'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationProspectOrigins: Dictionary<OrganizationProspectOriginEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  prospects?: Dictionary<ProspectEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationProspectOrigins: (OrganizationProspectOrigin | null)[];
} {
  const { organizationProspectOrigins, organizations, prospects } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationProspectOrigins: Object.keys(organizationProspectOrigins).map(idOrganizationProspectOrigin =>
      hydrate(
        organizationProspectOrigins[idOrganizationProspectOrigin] as OrganizationProspectOriginEntityState,
        organizations,
        prospects
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationProspectOrigin: OrganizationProspectOriginEntityState | null;
} {
  const { organizationProspectOrigins, organizations, prospects } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationProspectOrigin = Object.values(organizationProspectOrigins)[0];
  return {
    organizationProspectOrigin: hydrate(
      organizationProspectOrigin as OrganizationProspectOriginEntityState,
      organizations,
      prospects
    )
  };
}

function hydrate(
  organizationProspectOrigin: OrganizationProspectOriginEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  prospectEntities?: Dictionary<ProspectEntityState>
): OrganizationProspectOrigin | null {
  if (!organizationProspectOrigin) {
    return null;
  }

  const organizationProspectOriginHydrated: OrganizationProspectOriginEntityState = { ...organizationProspectOrigin };
  if (organizationEntities) {
    organizationProspectOriginHydrated.organization = organizationEntities[
      organizationProspectOrigin.organization as number
    ] as Organization;
  } else {
    delete organizationProspectOriginHydrated.organization;
  }

  if (prospectEntities) {
    organizationProspectOriginHydrated.prospects = (
      (organizationProspectOriginHydrated.prospects as number[]) || []
    ).map(id => prospectEntities[id]) as Prospect[];
  } else {
    delete organizationProspectOriginHydrated.prospects;
  }

  return organizationProspectOriginHydrated as OrganizationProspectOrigin;
}
