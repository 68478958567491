import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CompanyStudyNextAction,
  CompanyStudyNextActionEntityState
} from '@_model/interfaces/company-study-next-action.model';
import { ResidenceStudy, ResidenceStudyEntityState } from '@_model/interfaces/residence-study.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  companyStudyNextActionFeatureKey,
  CompanyStudyNextActionState
} from './company-study-next-action.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyStudyNextActionRelations: string[] = ['residenceStudies', 'companies'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyStudyNextActionState = createFeatureSelector<CompanyStudyNextActionState>(
  companyStudyNextActionFeatureKey
);

export const selectIsLoadedCompanyStudyNextAction = createSelector(
  selectCompanyStudyNextActionState,
  (state: CompanyStudyNextActionState) => state.isLoaded
);

export const selectIsLoadingCompanyStudyNextAction = createSelector(
  selectCompanyStudyNextActionState,
  (state: CompanyStudyNextActionState) => state.isLoading
);

export const selectIsReadyCompanyStudyNextAction = createSelector(
  selectCompanyStudyNextActionState,
  (state: CompanyStudyNextActionState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyStudyNextAction = createSelector(
  selectCompanyStudyNextActionState,
  (state: CompanyStudyNextActionState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyStudyNextActionModel: SelectorModel = {
  name: 'companyStudyNextActions',
  getSelector: selectAllCompanyStudyNextActionsDictionary,
  isReady: selectIsReadyCompanyStudyNextAction
};

export const selectCompanyStudyNextActionsEntities = createSelector(selectCompanyStudyNextActionState, selectEntities);

export const selectCompanyStudyNextActionsArray = createSelector(selectCompanyStudyNextActionState, selectAll);

export const selectIdCompanyStudyNextActionsActive = createSelector(
  selectCompanyStudyNextActionState,
  (state: CompanyStudyNextActionState) => state.actives
);

const companyStudyNextActionsInObject = (companyStudyNextActions: Dictionary<CompanyStudyNextActionEntityState>) => ({
  companyStudyNextActions
});

const selectCompanyStudyNextActionsEntitiesDictionary = createSelector(
  selectCompanyStudyNextActionsEntities,
  companyStudyNextActionsInObject
);

const selectAllCompanyStudyNextActionsObject = createSelector(
  selectCompanyStudyNextActionsEntities,
  companyStudyNextActions => {
    return hydrateAll({ companyStudyNextActions });
  }
);

const selectOneCompanyStudyNextActionDictionary = (idCompanyStudyNextAction: number) =>
  createSelector(selectCompanyStudyNextActionsEntities, companyStudyNextActions => {
    return {
      companyStudyNextActions: { [idCompanyStudyNextAction]: companyStudyNextActions[idCompanyStudyNextAction] }
    };
  });

const selectOneCompanyStudyNextActionDictionaryWithoutChild = (idCompanyStudyNextAction: number) =>
  createSelector(selectCompanyStudyNextActionsEntities, companyStudyNextActions => {
    return { companyStudyNextAction: companyStudyNextActions[idCompanyStudyNextAction] };
  });

const selectActiveCompanyStudyNextActionsEntities = createSelector(
  selectIdCompanyStudyNextActionsActive,
  selectCompanyStudyNextActionsEntities,
  (actives: number[], companyStudyNextActions: Dictionary<CompanyStudyNextActionEntityState>) =>
    getCompanyStudyNextActionsFromActives(actives, companyStudyNextActions)
);

function getCompanyStudyNextActionsFromActives(
  actives: number[],
  companyStudyNextActions: Dictionary<CompanyStudyNextActionEntityState>
): Dictionary<CompanyStudyNextActionEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyStudyNextActions[idActive]) {
      acc[idActive] = companyStudyNextActions[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyStudyNextActionEntityState>);
}

const selectAllCompanyStudyNextActionsSelectors: Dictionary<Selector> = {};
export function selectAllCompanyStudyNextActions(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyStudyNextAction>(
      schema,
      selectAllCompanyStudyNextActionsSelectors,
      selectCompanyStudyNextActionsEntitiesDictionary,
      getRelationSelectors,
      companyStudyNextActionRelations,
      hydrateAll,
      'companyStudyNextAction'
    );
  } else {
    return selectAllCompanyStudyNextActionsObject;
  }
}

export function selectAllCompanyStudyNextActionsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyStudyNextActions'
): Selector {
  return createSelector(selectAllCompanyStudyNextActions(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyStudyNextActionEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyStudyNextActions.length; i++) {
      res[customKey][result.companyStudyNextActions[i].idCompanyStudyNextAction] = result.companyStudyNextActions[i];
    }
    return res;
  });
}

export function selectOneCompanyStudyNextAction(schema: SelectSchema = {}, idCompanyStudyNextAction: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyStudyNextActionDictionary(idCompanyStudyNextAction)];
    selectors.push(...getRelationSelectors(schema, companyStudyNextActionRelations, 'companyStudyNextAction'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyStudyNextActionDictionaryWithoutChild(idCompanyStudyNextAction);
  }
}

export function selectActiveCompanyStudyNextActions(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyStudyNextActionsEntities, companyStudyNextActions => ({
      companyStudyNextActions
    }))
  ];
  selectors.push(...getRelationSelectors(schema, companyStudyNextActionRelations, 'companyStudyNextAction'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyStudyNextActions: Dictionary<CompanyStudyNextActionEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  residenceStudies?: Dictionary<ResidenceStudyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { companyStudyNextActions: (CompanyStudyNextAction | null)[] } {
  const { companyStudyNextActions, companies, residenceStudies } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    companyStudyNextActions: Object.keys(companyStudyNextActions).map(idCompanyStudyNextAction =>
      hydrate(
        companyStudyNextActions[idCompanyStudyNextAction] as CompanyStudyNextActionEntityState,
        companies,
        residenceStudies
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { companyStudyNextAction: CompanyStudyNextActionEntityState | null } {
  const { companyStudyNextActions, companies, residenceStudies } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const companyStudyNextAction = Object.values(companyStudyNextActions)[0];
  return {
    companyStudyNextAction: hydrate(
      companyStudyNextAction as CompanyStudyNextActionEntityState,
      companies,
      residenceStudies
    )
  };
}

function hydrate(
  companyStudyNextAction: CompanyStudyNextActionEntityState,
  companyEntities?: Dictionary<CompanyEntityState>,
  residenceStudyEntities?: Dictionary<ResidenceStudyEntityState>
): CompanyStudyNextAction | null {
  if (!companyStudyNextAction) {
    return null;
  }

  const companyStudyNextActionHydrated: CompanyStudyNextActionEntityState = { ...companyStudyNextAction };
  if (companyEntities) {
    companyStudyNextActionHydrated.company = companyEntities[companyStudyNextAction.company as number] as Company;
  } else {
    delete companyStudyNextActionHydrated.company;
  }

  if (residenceStudyEntities) {
    companyStudyNextActionHydrated.residenceStudies = (
      (companyStudyNextActionHydrated.residenceStudies as number[]) || []
    ).map(id => residenceStudyEntities[id]) as ResidenceStudy[];
  } else {
    delete companyStudyNextActionHydrated.residenceStudies;
  }

  return companyStudyNextActionHydrated as CompanyStudyNextAction;
}
