import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationStratalotTodoProfilActions from './organization-stratalot-todo-profil.actions';
import {
  adapter,
  initialState,
  OrganizationStratalotTodoProfilState
} from './organization-stratalot-todo-profil.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationStratalotTodoProfilEntityState } from '@_model/interfaces/organization-stratalot-todo-profil.model';

export const organizationStratalotTodoProfilReducersGeneratedFunctions: ReducerTypes<
  OrganizationStratalotTodoProfilState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationStratalotTodoProfilActions.getOneOrganizationStratalotTodoProfil,
    (state: OrganizationStratalotTodoProfilState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationStratalotTodoProfilActions.getManyOrganizationStratalotTodoProfils,
    (state: OrganizationStratalotTodoProfilState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationStratalotTodoProfilActions.upsertOneOrganizationStratalotTodoProfil,
    (state: OrganizationStratalotTodoProfilState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationStratalotTodoProfilActions.upsertManyOrganizationStratalotTodoProfilsSuccess,
    (state: OrganizationStratalotTodoProfilState, { organizationStratalotTodoProfils }) =>
      adapter.upsertMany(organizationStratalotTodoProfils, setLoadingsState(state, false))
  ),
  on(
    OrganizationStratalotTodoProfilActions.deleteOneOrganizationStratalotTodoProfil,
    (state: OrganizationStratalotTodoProfilState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationStratalotTodoProfilActions.deleteOneOrganizationStratalotTodoProfilSuccess,
    (state: OrganizationStratalotTodoProfilState, { idOrganizationStratalotTodoProfil }) =>
      adapter.removeOne(idOrganizationStratalotTodoProfil, setLoadingsState(state, false))
  ),
  on(OrganizationStratalotTodoProfilActions.clearActive, (state: OrganizationStratalotTodoProfilState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationStratalotTodoProfilActions.addManyActives,
    (state: OrganizationStratalotTodoProfilState, { idOrganizationStratalotTodoProfils }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationStratalotTodoProfils)
    })
  ),
  on(
    OrganizationStratalotTodoProfilActions.deleteOneActive,
    (state: OrganizationStratalotTodoProfilState, { idOrganizationStratalotTodoProfil }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationStratalotTodoProfil)
    })
  ),

  on(OrganizationStratalotTodoProfilActions.clearStore, () => initialState),

  on(
    OrganizationStratalotTodoProfilActions.addProfilSuccess,
    (state: OrganizationStratalotTodoProfilState, { idOrganizationStratalotTodoProfil, idProfil }) => {
      if (!state.entities[idOrganizationStratalotTodoProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodoProfil]: {
            ...state.entities[idOrganizationStratalotTodoProfil],
            profil: idProfil
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoProfilActions.deleteManyProfilSuccess,
    (state: OrganizationStratalotTodoProfilState, { idProfils, idOrganizationStratalotTodoProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodoProfils.reduce((entities, idOrganizationStratalotTodoProfil) => {
            if (!state.entities[idOrganizationStratalotTodoProfil]?.profil) {
              return entities;
            }
            entities[idOrganizationStratalotTodoProfil] = {
              ...state.entities[idOrganizationStratalotTodoProfil],
              profil: idProfils.some(
                (idProfil: number) => idProfil === state.entities[idOrganizationStratalotTodoProfil]?.profil
              )
                ? undefined
                : state.entities[idOrganizationStratalotTodoProfil]?.profil
            } as OrganizationStratalotTodoProfilEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoProfilEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoProfilActions.addOrganizationStratalotTodoSuccess,
    (
      state: OrganizationStratalotTodoProfilState,
      { idOrganizationStratalotTodoProfil, idOrganizationStratalotTodo }
    ) => {
      if (!state.entities[idOrganizationStratalotTodoProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodoProfil]: {
            ...state.entities[idOrganizationStratalotTodoProfil],
            organizationStratalotTodo: idOrganizationStratalotTodo
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoProfilActions.deleteManyOrganizationStratalotTodoSuccess,
    (
      state: OrganizationStratalotTodoProfilState,
      { idOrganizationStratalotTodos, idOrganizationStratalotTodoProfils }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodoProfils.reduce((entities, idOrganizationStratalotTodoProfil) => {
            if (!state.entities[idOrganizationStratalotTodoProfil]?.organizationStratalotTodo) {
              return entities;
            }
            entities[idOrganizationStratalotTodoProfil] = {
              ...state.entities[idOrganizationStratalotTodoProfil],
              organizationStratalotTodo: idOrganizationStratalotTodos.some(
                (idOrganizationStratalotTodo: number) =>
                  idOrganizationStratalotTodo ===
                  state.entities[idOrganizationStratalotTodoProfil]?.organizationStratalotTodo
              )
                ? undefined
                : state.entities[idOrganizationStratalotTodoProfil]?.organizationStratalotTodo
            } as OrganizationStratalotTodoProfilEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoProfilEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationStratalotTodoProfilState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationStratalotTodoProfilState {
  return { ...state, isLoaded, isLoading };
}
