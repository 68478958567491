import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { UserEntityState } from '@_model/interfaces/user.model';

export interface UserState extends EntityState<UserEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<UserEntityState> = createEntityAdapter<UserEntityState>({
  selectId: o => o.idUser
});
export const initialState: UserState = adapter.getInitialState({ isLoaded: false, isLoading: false, actives: [] });

export const userFeatureKey = 'user';
