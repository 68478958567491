import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { OrganizationSociete, OrganizationSocieteEntityState } from '@_model/interfaces/organization-societe.model';
import { OrganizationSocieteApiService } from '@_services/api/organization-societe-api.service';
import * as organizationSocieteActions from '@_store/organization-societe/organization-societe.actions';
import { getActionsToNormalizeOrganizationSociete } from '@_config/store/normalization.generated';
import { selectOrganizationSocieteState } from './organization-societe-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as organizationThirdPartyActions from '@_store/organization-third-party/organization-third-party.actions';
import { OrganizationThirdParty } from '@_model/interfaces/organization-third-party.model';

export interface OrganizationSocieteRelationsIds {
  organizationThirdParties?: number | number[];
  organization?: number;
}

export function getDefaultAddOrganizationSocieteActions(
  organizationSociete: OrganizationSocieteEntityState,
  ids?: OrganizationSocieteRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationSocieteActions.normalizeManyOrganizationSocietesAfterUpsert({
      organizationSocietes: [organizationSociete]
    })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationSocieteSuccess({
        idOrganization: ids.organization,
        idOrganizationSocietes: [organizationSociete.idOrganizationSociete]
      })
    );
    actions.push(
      organizationSocieteActions.addOrganizationSuccess({
        idOrganizationSociete: organizationSociete.idOrganizationSociete,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.organizationThirdParties) {
    if (!Array.isArray(ids.organizationThirdParties)) {
      actions.push(
        organizationThirdPartyActions.upsertOneOrganizationThirdParty({
          organizationThirdParty: {
            idOrganizationSociete: organizationSociete.idOrganizationSociete,
            idOrganizationThirdParty: ids.organizationThirdParties as number
          } as OrganizationThirdParty
        })
      );
      actions.push(
        organizationSocieteActions.addManyOrganizationThirdPartySuccess({
          idOrganizationSociete: organizationSociete.idOrganizationSociete,
          idOrganizationThirdParties: [ids.organizationThirdParties as number]
        })
      );
    } else {
      actions.push(
        organizationThirdPartyActions.upsertManyOrganizationThirdParties({
          organizationThirdParties: (ids.organizationThirdParties as number[]).map(
            (idOrganizationThirdParty: number) => ({
              idOrganizationSociete: organizationSociete.idOrganizationSociete,
              idOrganizationThirdParty
            })
          ) as OrganizationThirdParty[]
        })
      );
      actions.push(
        organizationSocieteActions.addManyOrganizationThirdPartySuccess({
          idOrganizationSociete: organizationSociete.idOrganizationSociete,
          idOrganizationThirdParties: ids.organizationThirdParties as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationSocieteActions(
  organizationSociete: OrganizationSocieteEntityState
): Action[] {
  const actions: Action[] = [
    organizationSocieteActions.deleteOneOrganizationSocieteSuccess({
      idOrganizationSociete: organizationSociete.idOrganizationSociete
    })
  ];

  if (organizationSociete.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationSocieteSuccess({
        idOrganizationSocietes: [organizationSociete.idOrganizationSociete],
        idOrganizations: [organizationSociete.organization as number]
      })
    );
  }

  if (organizationSociete.organizationThirdParties) {
    actions.push(
      organizationThirdPartyActions.deleteManyOrganizationSocieteSuccess({
        idOrganizationSocietes: [organizationSociete.idOrganizationSociete],
        idOrganizationThirdParties: organizationSociete.organizationThirdParties as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationSocieteEffects {
  constructor(
    protected actions$: Actions,
    protected organizationSocieteApiService: OrganizationSocieteApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationSocietes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationSocieteActions.getManyOrganizationSocietes),
      switchMap(({ params }) =>
        this.organizationSocieteApiService.getOrganizationSocietes(params).pipe(
          map((organizationSocietes: OrganizationSociete[]) => {
            return organizationSocieteActions.normalizeManyOrganizationSocietesAfterUpsert({ organizationSocietes });
          }),
          catchError(error => of(organizationSocieteActions.organizationSocietesFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationSociete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationSocieteActions.getOneOrganizationSociete),
      switchMap(idOrganizationSociete =>
        this.organizationSocieteApiService.getOrganizationSociete(idOrganizationSociete).pipe(
          map((organizationSociete: OrganizationSociete) => {
            return organizationSocieteActions.normalizeManyOrganizationSocietesAfterUpsert({
              organizationSocietes: [organizationSociete]
            });
          }),
          catchError(error => of(organizationSocieteActions.organizationSocietesFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationSociete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationSocieteActions.upsertOneOrganizationSociete),
      concatMap(
        ({
          organizationSociete,
          ids
        }: {
          organizationSociete: Partial<OrganizationSociete>;
          ids?: OrganizationSocieteRelationsIds;
        }) => {
          if (organizationSociete.idOrganizationSociete) {
            return this.organizationSocieteApiService.updateOrganizationSociete(organizationSociete).pipe(
              map((organizationSocieteReturned: OrganizationSociete) => {
                return organizationSocieteActions.normalizeManyOrganizationSocietesAfterUpsert({
                  organizationSocietes: [organizationSocieteReturned]
                });
              }),
              catchError(error => of(organizationSocieteActions.organizationSocietesFailure({ error })))
            );
          } else {
            return this.organizationSocieteApiService.addOrganizationSociete(organizationSociete).pipe(
              mergeMap((organizationSocieteReturned: OrganizationSociete) =>
                getDefaultAddOrganizationSocieteActions(organizationSocieteReturned, ids)
              ),
              catchError(error => of(organizationSocieteActions.organizationSocietesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationSociete$ = createEffect(() => {
    const selectOrganizationSocieteState$ = this.store$.select(selectOrganizationSocieteState);
    return this.actions$.pipe(
      ofType(organizationSocieteActions.deleteOneOrganizationSociete),
      withLatestFrom(selectOrganizationSocieteState$),
      concatMap(([{ idOrganizationSociete }, state]) =>
        this.organizationSocieteApiService.deleteOrganizationSociete(idOrganizationSociete).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOrganizationSocieteActions(
                state.entities[idOrganizationSociete] as OrganizationSocieteEntityState
              ),
              organizationSocieteActions.deleteOneOrganizationSociete.type
            )
          ]),
          catchError(error => of(organizationSocieteActions.organizationSocietesFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationSocietesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationSocieteActions.normalizeManyOrganizationSocietesAfterUpsert),
      concatMap(({ organizationSocietes }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationSociete(
          organizationSocietes,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationSociete] Normalization After Upsert Success')];
      })
    );
  });
}
