import { GeneratedDocumentsCompanyState, initialState } from './generated-documents-company.state';
import { Action, createReducer } from '@ngrx/store';
import { generatedDocumentsCompanyReducersGeneratedFunctions } from './generated-documents-company-generated.reducer';

const generatedDocumentsCompanyReducersFunctions = [...generatedDocumentsCompanyReducersGeneratedFunctions];

const generatedDocumentsCompanyReducer = createReducer(initialState, ...generatedDocumentsCompanyReducersFunctions);

export function reducer(state: GeneratedDocumentsCompanyState | undefined, action: Action) {
  return generatedDocumentsCompanyReducer(state, action);
}
