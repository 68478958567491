import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ForecastPeriodActions from './forecast-period.actions';
import { adapter, initialState, ForecastPeriodState } from './forecast-period.state';
import { Dictionary } from '@ngrx/entity';
import { ForecastPeriodEntityState } from '@_model/interfaces/forecast-period.model';

export const forecastPeriodReducersGeneratedFunctions: ReducerTypes<ForecastPeriodState, readonly ActionCreator[]>[] = [
  on(ForecastPeriodActions.getOneForecastPeriod, (state: ForecastPeriodState) => setLoadingsState(state, true)),
  on(ForecastPeriodActions.getManyForecastPeriods, (state: ForecastPeriodState) => setLoadingsState(state, true)),
  on(ForecastPeriodActions.upsertOneForecastPeriod, (state: ForecastPeriodState) => setLoadingsState(state, true)),

  on(ForecastPeriodActions.upsertManyForecastPeriodsSuccess, (state: ForecastPeriodState, { forecastPeriods }) =>
    adapter.upsertMany(forecastPeriods, setLoadingsState(state, false))
  ),
  on(ForecastPeriodActions.deleteOneForecastPeriod, (state: ForecastPeriodState) => setLoadingsState(state, true)),
  on(ForecastPeriodActions.deleteOneForecastPeriodSuccess, (state: ForecastPeriodState, { idForecastPeriod }) =>
    adapter.removeOne(idForecastPeriod, setLoadingsState(state, false))
  ),
  on(ForecastPeriodActions.clearActive, (state: ForecastPeriodState) => ({ ...state, actives: [] })),
  on(ForecastPeriodActions.addManyActives, (state: ForecastPeriodState, { idForecastPeriods }) => ({
    ...state,
    actives: state.actives.concat(idForecastPeriods)
  })),
  on(ForecastPeriodActions.deleteOneActive, (state: ForecastPeriodState, { idForecastPeriod }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idForecastPeriod)
  })),

  on(ForecastPeriodActions.clearStore, () => initialState),

  on(
    ForecastPeriodActions.addManyResidenceForecastSuccess,
    (state: ForecastPeriodState, { idForecastPeriod, idResidenceForecasts }) => {
      if (!state.entities[idForecastPeriod]) {
        return state;
      }
      const residenceForecasts = (state.entities[idForecastPeriod]?.residenceForecasts as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idForecastPeriod]: {
            ...state.entities[idForecastPeriod],
            residenceForecasts: residenceForecasts.concat(
              idResidenceForecasts.filter(id => residenceForecasts.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ForecastPeriodActions.deleteManyResidenceForecastSuccess,
    (state: ForecastPeriodState, { idResidenceForecasts, idForecastPeriods }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idForecastPeriods.reduce((entities, idForecastPeriod) => {
            if (!state.entities[idForecastPeriod]?.residenceForecasts) {
              return entities;
            }
            entities[idForecastPeriod] = {
              ...state.entities[idForecastPeriod],
              residenceForecasts: (state.entities[idForecastPeriod]?.residenceForecasts as number[])?.filter(
                (idResidenceForecast: number) => !idResidenceForecasts.some((id: number) => id === idResidenceForecast)
              )
            } as ForecastPeriodEntityState;
            return entities;
          }, {} as Dictionary<ForecastPeriodEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ForecastPeriodState,
  isLoading: boolean,
  isLoaded: boolean = true
): ForecastPeriodState {
  return { ...state, isLoaded, isLoading };
}
