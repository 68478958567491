import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationStratalotTodoProfil,
  OrganizationStratalotTodoProfilEntityState
} from '@_model/interfaces/organization-stratalot-todo-profil.model';
import { OrganizationStratalotTodoProfilApiService } from '@_services/api/organization-stratalot-todo-profil-api.service';
import * as organizationStratalotTodoProfilActions from '@_store/organization-stratalot-todo-profil/organization-stratalot-todo-profil.actions';
import { getActionsToNormalizeOrganizationStratalotTodoProfil } from '@_config/store/normalization.generated';
import { selectOrganizationStratalotTodoProfilState } from './organization-stratalot-todo-profil-generated.selectors';
import * as profilActions from '@_store/profil/profil.actions';
import * as organizationStratalotTodoActions from '@_store/organization-stratalot-todo/organization-stratalot-todo.actions';

export interface OrganizationStratalotTodoProfilRelationsIds {
  profil?: number;
  organizationStratalotTodo?: number;
}

export function getDefaultAddOrganizationStratalotTodoProfilActions(
  organizationStratalotTodoProfil: OrganizationStratalotTodoProfilEntityState,
  ids?: OrganizationStratalotTodoProfilRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationStratalotTodoProfilActions.normalizeManyOrganizationStratalotTodoProfilsAfterUpsert({
      organizationStratalotTodoProfils: [organizationStratalotTodoProfil]
    })
  ];

  if (ids?.profil) {
    actions.push(
      profilActions.addManyOrganizationStratalotTodoProfilSuccess({
        idProfil: ids.profil,
        idOrganizationStratalotTodoProfils: [organizationStratalotTodoProfil.idOrganizationStratalotTodoProfil]
      })
    );
    actions.push(
      organizationStratalotTodoProfilActions.addProfilSuccess({
        idOrganizationStratalotTodoProfil: organizationStratalotTodoProfil.idOrganizationStratalotTodoProfil,
        idProfil: ids.profil
      })
    );
  }

  if (ids?.organizationStratalotTodo) {
    actions.push(
      organizationStratalotTodoActions.addManyOrganizationStratalotTodoProfilSuccess({
        idOrganizationStratalotTodo: ids.organizationStratalotTodo,
        idOrganizationStratalotTodoProfils: [organizationStratalotTodoProfil.idOrganizationStratalotTodoProfil]
      })
    );
    actions.push(
      organizationStratalotTodoProfilActions.addOrganizationStratalotTodoSuccess({
        idOrganizationStratalotTodoProfil: organizationStratalotTodoProfil.idOrganizationStratalotTodoProfil,
        idOrganizationStratalotTodo: ids.organizationStratalotTodo
      })
    );
  }

  return actions;
}

export function getDefaultDeleteOrganizationStratalotTodoProfilActions(
  organizationStratalotTodoProfil: OrganizationStratalotTodoProfilEntityState
): Action[] {
  const actions: Action[] = [
    organizationStratalotTodoProfilActions.deleteOneOrganizationStratalotTodoProfilSuccess({
      idOrganizationStratalotTodoProfil: organizationStratalotTodoProfil.idOrganizationStratalotTodoProfil
    })
  ];

  if (organizationStratalotTodoProfil.profil) {
    actions.push(
      profilActions.deleteManyOrganizationStratalotTodoProfilSuccess({
        idOrganizationStratalotTodoProfils: [organizationStratalotTodoProfil.idOrganizationStratalotTodoProfil],
        idProfils: [organizationStratalotTodoProfil.profil as number]
      })
    );
  }

  if (organizationStratalotTodoProfil.organizationStratalotTodo) {
    actions.push(
      organizationStratalotTodoActions.deleteManyOrganizationStratalotTodoProfilSuccess({
        idOrganizationStratalotTodoProfils: [organizationStratalotTodoProfil.idOrganizationStratalotTodoProfil],
        idOrganizationStratalotTodos: [organizationStratalotTodoProfil.organizationStratalotTodo as number]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationStratalotTodoProfilEffects {
  constructor(
    protected actions$: Actions,
    protected organizationStratalotTodoProfilApiService: OrganizationStratalotTodoProfilApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationStratalotTodoProfils$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotTodoProfilActions.getManyOrganizationStratalotTodoProfils),
      switchMap(({ params }) =>
        this.organizationStratalotTodoProfilApiService.getOrganizationStratalotTodoProfils(params).pipe(
          map((organizationStratalotTodoProfils: OrganizationStratalotTodoProfil[]) => {
            return organizationStratalotTodoProfilActions.normalizeManyOrganizationStratalotTodoProfilsAfterUpsert({
              organizationStratalotTodoProfils
            });
          }),
          catchError(error =>
            of(organizationStratalotTodoProfilActions.organizationStratalotTodoProfilsFailure({ error }))
          )
        )
      )
    );
  });

  getOneOrganizationStratalotTodoProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotTodoProfilActions.getOneOrganizationStratalotTodoProfil),
      switchMap(idOrganizationStratalotTodoProfil =>
        this.organizationStratalotTodoProfilApiService
          .getOrganizationStratalotTodoProfil(idOrganizationStratalotTodoProfil)
          .pipe(
            map((organizationStratalotTodoProfil: OrganizationStratalotTodoProfil) => {
              return organizationStratalotTodoProfilActions.normalizeManyOrganizationStratalotTodoProfilsAfterUpsert({
                organizationStratalotTodoProfils: [organizationStratalotTodoProfil]
              });
            }),
            catchError(error =>
              of(organizationStratalotTodoProfilActions.organizationStratalotTodoProfilsFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneOrganizationStratalotTodoProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotTodoProfilActions.upsertOneOrganizationStratalotTodoProfil),
      concatMap(
        ({
          organizationStratalotTodoProfil,
          ids
        }: {
          organizationStratalotTodoProfil: Partial<OrganizationStratalotTodoProfil>;
          ids?: OrganizationStratalotTodoProfilRelationsIds;
        }) => {
          if (organizationStratalotTodoProfil.idOrganizationStratalotTodoProfil) {
            return this.organizationStratalotTodoProfilApiService
              .updateOrganizationStratalotTodoProfil(organizationStratalotTodoProfil)
              .pipe(
                map((organizationStratalotTodoProfilReturned: OrganizationStratalotTodoProfil) => {
                  return organizationStratalotTodoProfilActions.normalizeManyOrganizationStratalotTodoProfilsAfterUpsert(
                    { organizationStratalotTodoProfils: [organizationStratalotTodoProfilReturned] }
                  );
                }),
                catchError(error =>
                  of(organizationStratalotTodoProfilActions.organizationStratalotTodoProfilsFailure({ error }))
                )
              );
          } else {
            return this.organizationStratalotTodoProfilApiService
              .addOrganizationStratalotTodoProfil(organizationStratalotTodoProfil)
              .pipe(
                mergeMap((organizationStratalotTodoProfilReturned: OrganizationStratalotTodoProfil) =>
                  getDefaultAddOrganizationStratalotTodoProfilActions(organizationStratalotTodoProfilReturned, ids)
                ),
                catchError(error =>
                  of(organizationStratalotTodoProfilActions.organizationStratalotTodoProfilsFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationStratalotTodoProfil$ = createEffect(() => {
    const selectOrganizationStratalotTodoProfilState$ = this.store$.select(selectOrganizationStratalotTodoProfilState);
    return this.actions$.pipe(
      ofType(organizationStratalotTodoProfilActions.deleteOneOrganizationStratalotTodoProfil),
      withLatestFrom(selectOrganizationStratalotTodoProfilState$),
      concatMap(([{ idOrganizationStratalotTodoProfil }, state]) =>
        this.organizationStratalotTodoProfilApiService
          .deleteOrganizationStratalotTodoProfil(idOrganizationStratalotTodoProfil)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteOrganizationStratalotTodoProfilActions(
                  state.entities[idOrganizationStratalotTodoProfil] as OrganizationStratalotTodoProfilEntityState
                ),
                organizationStratalotTodoProfilActions.deleteOneOrganizationStratalotTodoProfil.type
              )
            ]),
            catchError(error =>
              of(organizationStratalotTodoProfilActions.organizationStratalotTodoProfilsFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyOrganizationStratalotTodoProfilsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotTodoProfilActions.normalizeManyOrganizationStratalotTodoProfilsAfterUpsert),
      concatMap(({ organizationStratalotTodoProfils }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationStratalotTodoProfil(
          organizationStratalotTodoProfils,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationStratalotTodoProfil] Normalization After Upsert Success')];
      })
    );
  });
}
