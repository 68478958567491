import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyPriceLabelActions from './company-price-label.actions';
import { adapter, initialState, CompanyPriceLabelState } from './company-price-label.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyPriceLabelEntityState } from '@_model/interfaces/company-price-label.model';

export const companyPriceLabelReducersGeneratedFunctions: ReducerTypes<
  CompanyPriceLabelState,
  readonly ActionCreator[]
>[] = [
  on(CompanyPriceLabelActions.getOneCompanyPriceLabel, (state: CompanyPriceLabelState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyPriceLabelActions.getManyCompanyPriceLabels, (state: CompanyPriceLabelState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyPriceLabelActions.upsertOneCompanyPriceLabel, (state: CompanyPriceLabelState) =>
    setLoadingsState(state, true)
  ),

  on(
    CompanyPriceLabelActions.upsertManyCompanyPriceLabelsSuccess,
    (state: CompanyPriceLabelState, { companyPriceLabels }) =>
      adapter.upsertMany(companyPriceLabels, setLoadingsState(state, false))
  ),
  on(CompanyPriceLabelActions.deleteOneCompanyPriceLabel, (state: CompanyPriceLabelState) =>
    setLoadingsState(state, true)
  ),
  on(
    CompanyPriceLabelActions.deleteOneCompanyPriceLabelSuccess,
    (state: CompanyPriceLabelState, { idCompanyPriceLabel }) =>
      adapter.removeOne(idCompanyPriceLabel, setLoadingsState(state, false))
  ),
  on(CompanyPriceLabelActions.clearActive, (state: CompanyPriceLabelState) => ({ ...state, actives: [] })),
  on(CompanyPriceLabelActions.addManyActives, (state: CompanyPriceLabelState, { idCompanyPriceLabels }) => ({
    ...state,
    actives: state.actives.concat(idCompanyPriceLabels)
  })),
  on(CompanyPriceLabelActions.deleteOneActive, (state: CompanyPriceLabelState, { idCompanyPriceLabel }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCompanyPriceLabel)
  })),

  on(CompanyPriceLabelActions.clearStore, () => initialState),

  on(
    CompanyPriceLabelActions.addManyStratalotPriceSuccess,
    (state: CompanyPriceLabelState, { idCompanyPriceLabel, idStratalotPrices }) => {
      if (!state.entities[idCompanyPriceLabel]) {
        return state;
      }
      const stratalotPrices = (state.entities[idCompanyPriceLabel]?.stratalotPrices as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyPriceLabel]: {
            ...state.entities[idCompanyPriceLabel],
            stratalotPrices: stratalotPrices.concat(idStratalotPrices.filter(id => stratalotPrices.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CompanyPriceLabelActions.deleteManyStratalotPriceSuccess,
    (state: CompanyPriceLabelState, { idStratalotPrices, idCompanyPriceLabels }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyPriceLabels.reduce((entities, idCompanyPriceLabel) => {
            if (!state.entities[idCompanyPriceLabel]?.stratalotPrices) {
              return entities;
            }
            entities[idCompanyPriceLabel] = {
              ...state.entities[idCompanyPriceLabel],
              stratalotPrices: (state.entities[idCompanyPriceLabel]?.stratalotPrices as number[])?.filter(
                (idStratalotPrice: number) => !idStratalotPrices.some((id: number) => id === idStratalotPrice)
              )
            } as CompanyPriceLabelEntityState;
            return entities;
          }, {} as Dictionary<CompanyPriceLabelEntityState>)
        }
      };
    }
  ),

  on(
    CompanyPriceLabelActions.addManyStratalotPriceValueSuccess,
    (state: CompanyPriceLabelState, { idCompanyPriceLabel, idStratalotPriceValues }) => {
      if (!state.entities[idCompanyPriceLabel]) {
        return state;
      }
      const stratalotPriceValues = (state.entities[idCompanyPriceLabel]?.stratalotPriceValues as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyPriceLabel]: {
            ...state.entities[idCompanyPriceLabel],
            stratalotPriceValues: stratalotPriceValues.concat(
              idStratalotPriceValues.filter(id => stratalotPriceValues.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyPriceLabelActions.deleteManyStratalotPriceValueSuccess,
    (state: CompanyPriceLabelState, { idStratalotPriceValues, idCompanyPriceLabels }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyPriceLabels.reduce((entities, idCompanyPriceLabel) => {
            if (!state.entities[idCompanyPriceLabel]?.stratalotPriceValues) {
              return entities;
            }
            entities[idCompanyPriceLabel] = {
              ...state.entities[idCompanyPriceLabel],
              stratalotPriceValues: (state.entities[idCompanyPriceLabel]?.stratalotPriceValues as number[])?.filter(
                (idStratalotPriceValue: number) =>
                  !idStratalotPriceValues.some((id: number) => id === idStratalotPriceValue)
              )
            } as CompanyPriceLabelEntityState;
            return entities;
          }, {} as Dictionary<CompanyPriceLabelEntityState>)
        }
      };
    }
  ),

  on(
    CompanyPriceLabelActions.addManyResidencePriceGridModifierSuccess,
    (state: CompanyPriceLabelState, { idCompanyPriceLabel, idResidencePriceGridModifiers }) => {
      if (!state.entities[idCompanyPriceLabel]) {
        return state;
      }
      const residencePriceGridModifiers =
        (state.entities[idCompanyPriceLabel]?.residencePriceGridModifiers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyPriceLabel]: {
            ...state.entities[idCompanyPriceLabel],
            residencePriceGridModifiers: residencePriceGridModifiers.concat(
              idResidencePriceGridModifiers.filter(id => residencePriceGridModifiers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyPriceLabelActions.deleteManyResidencePriceGridModifierSuccess,
    (state: CompanyPriceLabelState, { idResidencePriceGridModifiers, idCompanyPriceLabels }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyPriceLabels.reduce((entities, idCompanyPriceLabel) => {
            if (!state.entities[idCompanyPriceLabel]?.residencePriceGridModifiers) {
              return entities;
            }
            entities[idCompanyPriceLabel] = {
              ...state.entities[idCompanyPriceLabel],
              residencePriceGridModifiers: (
                state.entities[idCompanyPriceLabel]?.residencePriceGridModifiers as number[]
              )?.filter(
                (idResidencePriceGridModifier: number) =>
                  !idResidencePriceGridModifiers.some((id: number) => id === idResidencePriceGridModifier)
              )
            } as CompanyPriceLabelEntityState;
            return entities;
          }, {} as Dictionary<CompanyPriceLabelEntityState>)
        }
      };
    }
  ),

  on(
    CompanyPriceLabelActions.addManyOrganizationProfilSuccess,
    (state: CompanyPriceLabelState, { idCompanyPriceLabel, idOrganizationProfils }) => {
      if (!state.entities[idCompanyPriceLabel]) {
        return state;
      }
      const organizationProfils = (state.entities[idCompanyPriceLabel]?.organizationProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyPriceLabel]: {
            ...state.entities[idCompanyPriceLabel],
            organizationProfils: organizationProfils.concat(
              idOrganizationProfils.filter(id => organizationProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyPriceLabelActions.deleteManyOrganizationProfilSuccess,
    (state: CompanyPriceLabelState, { idOrganizationProfils, idCompanyPriceLabels }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyPriceLabels.reduce((entities, idCompanyPriceLabel) => {
            if (!state.entities[idCompanyPriceLabel]?.organizationProfils) {
              return entities;
            }
            entities[idCompanyPriceLabel] = {
              ...state.entities[idCompanyPriceLabel],
              organizationProfils: (state.entities[idCompanyPriceLabel]?.organizationProfils as number[])?.filter(
                (idOrganizationProfil: number) =>
                  !idOrganizationProfils.some((id: number) => id === idOrganizationProfil)
              )
            } as CompanyPriceLabelEntityState;
            return entities;
          }, {} as Dictionary<CompanyPriceLabelEntityState>)
        }
      };
    }
  ),

  on(
    CompanyPriceLabelActions.addCompanySuccess,
    (state: CompanyPriceLabelState, { idCompanyPriceLabel, idCompany }) => {
      if (!state.entities[idCompanyPriceLabel]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyPriceLabel]: {
            ...state.entities[idCompanyPriceLabel],
            company: idCompany
          }
        }
      };
    }
  ),

  on(
    CompanyPriceLabelActions.deleteManyCompanySuccess,
    (state: CompanyPriceLabelState, { idCompanies, idCompanyPriceLabels }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyPriceLabels.reduce((entities, idCompanyPriceLabel) => {
            if (!state.entities[idCompanyPriceLabel]?.company) {
              return entities;
            }
            entities[idCompanyPriceLabel] = {
              ...state.entities[idCompanyPriceLabel],
              company: idCompanies.some(
                (idCompany: number) => idCompany === state.entities[idCompanyPriceLabel]?.company
              )
                ? undefined
                : state.entities[idCompanyPriceLabel]?.company
            } as CompanyPriceLabelEntityState;
            return entities;
          }, {} as Dictionary<CompanyPriceLabelEntityState>)
        }
      };
    }
  ),

  on(
    CompanyPriceLabelActions.addSaleCategoryFamilySuccess,
    (state: CompanyPriceLabelState, { idCompanyPriceLabel, idSaleCategoryFamily }) => {
      if (!state.entities[idCompanyPriceLabel]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyPriceLabel]: {
            ...state.entities[idCompanyPriceLabel],
            saleCategoryFamily: idSaleCategoryFamily
          }
        }
      };
    }
  ),

  on(
    CompanyPriceLabelActions.deleteManySaleCategoryFamilySuccess,
    (state: CompanyPriceLabelState, { idFamilySaleCategories, idCompanyPriceLabels }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyPriceLabels.reduce((entities, idCompanyPriceLabel) => {
            if (!state.entities[idCompanyPriceLabel]?.saleCategoryFamily) {
              return entities;
            }
            entities[idCompanyPriceLabel] = {
              ...state.entities[idCompanyPriceLabel],
              saleCategoryFamily: idFamilySaleCategories.some(
                (idSaleCategoryFamily: number) =>
                  idSaleCategoryFamily === state.entities[idCompanyPriceLabel]?.saleCategoryFamily
              )
                ? undefined
                : state.entities[idCompanyPriceLabel]?.saleCategoryFamily
            } as CompanyPriceLabelEntityState;
            return entities;
          }, {} as Dictionary<CompanyPriceLabelEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyPriceLabelState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyPriceLabelState {
  return { ...state, isLoaded, isLoading };
}
