import { ResidenceWorkState, initialState } from './residence-work.state';
import { Action, createReducer } from '@ngrx/store';
import { residenceWorkReducersGeneratedFunctions } from './residence-work-generated.reducer';

const residenceWorkReducersFunctions = [...residenceWorkReducersGeneratedFunctions];

const residenceWorkReducer = createReducer(initialState, ...residenceWorkReducersFunctions);

export function reducer(state: ResidenceWorkState | undefined, action: Action) {
  return residenceWorkReducer(state, action);
}
