export enum Profil {
  locataireDuParc = 'Locataire du parc',
  decohabitant = 'Décohabitant',
  heberge = 'Hébergé',
  locataireBailleurSocial = 'Locataire bailleur social',
  locataireBailleurPrive = 'Locataire bailleur privé',
  proprietaire = 'Propriétaire',
  exproprie = 'Exproprié',
  gardienBailleurSocial = 'Gardien bailleur social',
  personneMoraleDroitPrive = 'Personne morale droit privé',
  personneMoraleDroitPublic = 'Personne morale droit public',
  autre = 'Autre'
}

export enum ProfilProspectOccupant {
  non = 'Non',
  occupant = 'Occupant',
  ascendantOccupant = 'Ascendant',
  descendantOccupant = 'Descendant',
  conjointOccupant = 'Conjoint'
}
