import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { NotificationService } from '../notification.service';
import { ErrorService } from './error.service';
import { UnauthorizedActionError } from './unauthorized-action-error';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  public handleError(error: Error | UnauthorizedActionError | HttpErrorResponse): void {
    const errorService = this.injector.get(ErrorService);
    const notifier = this.injector.get(NotificationService);

    if (error instanceof UnauthorizedActionError) {
      const message = errorService.getClientErrorMessage(error);
      notifier.showError(message);
    } else if (error instanceof HttpErrorResponse) {
      if (error.error.message) {
        notifier.showError(error.error.message);
      }
    } else {
      console.error(error);
    }
  }
}
