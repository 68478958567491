import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationActions from './organization.actions';
import { adapter, initialState, OrganizationState } from './organization.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationEntityState } from '@_model/interfaces/organization.model';

export const organizationReducersGeneratedFunctions: ReducerTypes<OrganizationState, readonly ActionCreator[]>[] = [
  on(OrganizationActions.getOneOrganization, (state: OrganizationState) => setLoadingsState(state, true)),
  on(OrganizationActions.getManyOrganizations, (state: OrganizationState) => setLoadingsState(state, true)),
  on(OrganizationActions.upsertOneOrganization, (state: OrganizationState) => setLoadingsState(state, true)),

  on(OrganizationActions.upsertManyOrganizationsSuccess, (state: OrganizationState, { organizations }) =>
    adapter.upsertMany(organizations, setLoadingsState(state, false))
  ),
  on(OrganizationActions.deleteOneOrganization, (state: OrganizationState) => setLoadingsState(state, true)),
  on(OrganizationActions.deleteOneOrganizationSuccess, (state: OrganizationState, { idOrganization }) =>
    adapter.removeOne(idOrganization, setLoadingsState(state, false))
  ),
  on(OrganizationActions.clearActive, (state: OrganizationState) => ({ ...state, actives: [] })),
  on(OrganizationActions.addManyActives, (state: OrganizationState, { idOrganizations }) => ({
    ...state,
    actives: state.actives.concat(idOrganizations)
  })),
  on(OrganizationActions.deleteOneActive, (state: OrganizationState, { idOrganization }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idOrganization)
  })),

  on(OrganizationActions.clearStore, () => initialState),

  on(OrganizationActions.addManyCompanySuccess, (state: OrganizationState, { idOrganization, idCompanies }) => {
    if (!state.entities[idOrganization]) {
      return state;
    }
    const companies = (state.entities[idOrganization]?.companies as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idOrganization]: {
          ...state.entities[idOrganization],
          companies: companies.concat(idCompanies.filter(id => companies.indexOf(id) < 0))
        }
      }
    };
  }),

  on(OrganizationActions.deleteManyCompanySuccess, (state: OrganizationState, { idCompanies, idOrganizations }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idOrganizations.reduce((entities, idOrganization) => {
          if (!state.entities[idOrganization]?.companies) {
            return entities;
          }
          entities[idOrganization] = {
            ...state.entities[idOrganization],
            companies: (state.entities[idOrganization]?.companies as number[])?.filter(
              (idCompany: number) => !idCompanies.some((id: number) => id === idCompany)
            )
          } as OrganizationEntityState;
          return entities;
        }, {} as Dictionary<OrganizationEntityState>)
      }
    };
  }),

  on(OrganizationActions.addManyUserSuccess, (state: OrganizationState, { idOrganization, idUsers }) => {
    if (!state.entities[idOrganization]) {
      return state;
    }
    const users = (state.entities[idOrganization]?.users as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idOrganization]: {
          ...state.entities[idOrganization],
          users: users.concat(idUsers.filter(id => users.indexOf(id) < 0))
        }
      }
    };
  }),

  on(OrganizationActions.deleteManyUserSuccess, (state: OrganizationState, { idUsers, idOrganizations }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idOrganizations.reduce((entities, idOrganization) => {
          if (!state.entities[idOrganization]?.users) {
            return entities;
          }
          entities[idOrganization] = {
            ...state.entities[idOrganization],
            users: (state.entities[idOrganization]?.users as number[])?.filter(
              (idUser: number) => !idUsers.some((id: number) => id === idUser)
            )
          } as OrganizationEntityState;
          return entities;
        }, {} as Dictionary<OrganizationEntityState>)
      }
    };
  }),

  on(
    OrganizationActions.addManyOrganizationMetierSuccess,
    (state: OrganizationState, { idOrganization, idOrganizationMetiers }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationMetiers = (state.entities[idOrganization]?.organizationMetiers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationMetiers: organizationMetiers.concat(
              idOrganizationMetiers.filter(id => organizationMetiers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationMetierSuccess,
    (state: OrganizationState, { idOrganizationMetiers, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationMetiers) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationMetiers: (state.entities[idOrganization]?.organizationMetiers as number[])?.filter(
                (idOrganizationMetier: number) =>
                  !idOrganizationMetiers.some((id: number) => id === idOrganizationMetier)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationSocieteSuccess,
    (state: OrganizationState, { idOrganization, idOrganizationSocietes }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationSocietes = (state.entities[idOrganization]?.organizationSocietes as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationSocietes: organizationSocietes.concat(
              idOrganizationSocietes.filter(id => organizationSocietes.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationSocieteSuccess,
    (state: OrganizationState, { idOrganizationSocietes, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationSocietes) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationSocietes: (state.entities[idOrganization]?.organizationSocietes as number[])?.filter(
                (idOrganizationSociete: number) =>
                  !idOrganizationSocietes.some((id: number) => id === idOrganizationSociete)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationLeadTodoSuccess,
    (state: OrganizationState, { idOrganization, idOrganizationLeadTodos }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationLeadTodos = (state.entities[idOrganization]?.organizationLeadTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationLeadTodos: organizationLeadTodos.concat(
              idOrganizationLeadTodos.filter(id => organizationLeadTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationLeadTodoSuccess,
    (state: OrganizationState, { idOrganizationLeadTodos, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationLeadTodos) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationLeadTodos: (state.entities[idOrganization]?.organizationLeadTodos as number[])?.filter(
                (idOrganizationLeadTodo: number) =>
                  !idOrganizationLeadTodos.some((id: number) => id === idOrganizationLeadTodo)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationLeadTodoRuleSuccess,
    (state: OrganizationState, { idOrganization, idOrganizationLeadTodoRules }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationLeadTodoRules = (state.entities[idOrganization]?.organizationLeadTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationLeadTodoRules: organizationLeadTodoRules.concat(
              idOrganizationLeadTodoRules.filter(id => organizationLeadTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationLeadTodoRuleSuccess,
    (state: OrganizationState, { idOrganizationLeadTodoRules, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationLeadTodoRules) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationLeadTodoRules: (
                state.entities[idOrganization]?.organizationLeadTodoRules as number[]
              )?.filter(
                (idOrganizationLeadTodoRule: number) =>
                  !idOrganizationLeadTodoRules.some((id: number) => id === idOrganizationLeadTodoRule)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationStratalotTodoRuleSuccess,
    (state: OrganizationState, { idOrganization, idOrganizationStratalotTodoRules }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationStratalotTodoRules =
        (state.entities[idOrganization]?.organizationStratalotTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationStratalotTodoRules: organizationStratalotTodoRules.concat(
              idOrganizationStratalotTodoRules.filter(id => organizationStratalotTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationStratalotTodoRuleSuccess,
    (state: OrganizationState, { idOrganizationStratalotTodoRules, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationStratalotTodoRules) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationStratalotTodoRules: (
                state.entities[idOrganization]?.organizationStratalotTodoRules as number[]
              )?.filter(
                (idOrganizationStratalotTodoRule: number) =>
                  !idOrganizationStratalotTodoRules.some((id: number) => id === idOrganizationStratalotTodoRule)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationResidenceTodoRuleSuccess,
    (state: OrganizationState, { idOrganization, idOrganizationResidenceTodoRules }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationResidenceTodoRules =
        (state.entities[idOrganization]?.organizationResidenceTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationResidenceTodoRules: organizationResidenceTodoRules.concat(
              idOrganizationResidenceTodoRules.filter(id => organizationResidenceTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationResidenceTodoRuleSuccess,
    (state: OrganizationState, { idOrganizationResidenceTodoRules, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationResidenceTodoRules) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationResidenceTodoRules: (
                state.entities[idOrganization]?.organizationResidenceTodoRules as number[]
              )?.filter(
                (idOrganizationResidenceTodoRule: number) =>
                  !idOrganizationResidenceTodoRules.some((id: number) => id === idOrganizationResidenceTodoRule)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationResidenceTodoSuccess,
    (state: OrganizationState, { idOrganization, idOrganizationResidenceTodos }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationResidenceTodos = (state.entities[idOrganization]?.organizationResidenceTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationResidenceTodos: organizationResidenceTodos.concat(
              idOrganizationResidenceTodos.filter(id => organizationResidenceTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationResidenceTodoSuccess,
    (state: OrganizationState, { idOrganizationResidenceTodos, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationResidenceTodos) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationResidenceTodos: (
                state.entities[idOrganization]?.organizationResidenceTodos as number[]
              )?.filter(
                (idOrganizationResidenceTodo: number) =>
                  !idOrganizationResidenceTodos.some((id: number) => id === idOrganizationResidenceTodo)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationStratalotTodoSuccess,
    (state: OrganizationState, { idOrganization, idOrganizationStratalotTodos }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationStratalotTodos = (state.entities[idOrganization]?.organizationStratalotTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationStratalotTodos: organizationStratalotTodos.concat(
              idOrganizationStratalotTodos.filter(id => organizationStratalotTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationStratalotTodoSuccess,
    (state: OrganizationState, { idOrganizationStratalotTodos, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationStratalotTodos) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationStratalotTodos: (
                state.entities[idOrganization]?.organizationStratalotTodos as number[]
              )?.filter(
                (idOrganizationStratalotTodo: number) =>
                  !idOrganizationStratalotTodos.some((id: number) => id === idOrganizationStratalotTodo)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationResidenceStudyTodoSuccess,
    (state: OrganizationState, { idOrganization, idOrganizationResidenceStudyTodos }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationResidenceStudyTodos =
        (state.entities[idOrganization]?.organizationResidenceStudyTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationResidenceStudyTodos: organizationResidenceStudyTodos.concat(
              idOrganizationResidenceStudyTodos.filter(id => organizationResidenceStudyTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationResidenceStudyTodoSuccess,
    (state: OrganizationState, { idOrganizationResidenceStudyTodos, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationResidenceStudyTodos) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationResidenceStudyTodos: (
                state.entities[idOrganization]?.organizationResidenceStudyTodos as number[]
              )?.filter(
                (idOrganizationResidenceStudyTodo: number) =>
                  !idOrganizationResidenceStudyTodos.some((id: number) => id === idOrganizationResidenceStudyTodo)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationProfilSuccess,
    (state: OrganizationState, { idOrganization, idOrganizationProfils }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationProfils = (state.entities[idOrganization]?.organizationProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationProfils: organizationProfils.concat(
              idOrganizationProfils.filter(id => organizationProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationProfilSuccess,
    (state: OrganizationState, { idOrganizationProfils, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationProfils) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationProfils: (state.entities[idOrganization]?.organizationProfils as number[])?.filter(
                (idOrganizationProfil: number) =>
                  !idOrganizationProfils.some((id: number) => id === idOrganizationProfil)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationProspectOriginSuccess,
    (state: OrganizationState, { idOrganization, idOrganizationProspectOrigins }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationProspectOrigins =
        (state.entities[idOrganization]?.organizationProspectOrigins as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationProspectOrigins: organizationProspectOrigins.concat(
              idOrganizationProspectOrigins.filter(id => organizationProspectOrigins.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationProspectOriginSuccess,
    (state: OrganizationState, { idOrganizationProspectOrigins, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationProspectOrigins) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationProspectOrigins: (
                state.entities[idOrganization]?.organizationProspectOrigins as number[]
              )?.filter(
                (idOrganizationProspectOrigin: number) =>
                  !idOrganizationProspectOrigins.some((id: number) => id === idOrganizationProspectOrigin)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(OrganizationActions.addManyProspectSuccess, (state: OrganizationState, { idOrganization, idProspects }) => {
    if (!state.entities[idOrganization]) {
      return state;
    }
    const prospects = (state.entities[idOrganization]?.prospects as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idOrganization]: {
          ...state.entities[idOrganization],
          prospects: prospects.concat(idProspects.filter(id => prospects.indexOf(id) < 0))
        }
      }
    };
  }),

  on(OrganizationActions.deleteManyProspectSuccess, (state: OrganizationState, { idProspects, idOrganizations }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idOrganizations.reduce((entities, idOrganization) => {
          if (!state.entities[idOrganization]?.prospects) {
            return entities;
          }
          entities[idOrganization] = {
            ...state.entities[idOrganization],
            prospects: (state.entities[idOrganization]?.prospects as number[])?.filter(
              (idProspect: number) => !idProspects.some((id: number) => id === idProspect)
            )
          } as OrganizationEntityState;
          return entities;
        }, {} as Dictionary<OrganizationEntityState>)
      }
    };
  }),

  on(OrganizationActions.addManyProfilSuccess, (state: OrganizationState, { idOrganization, idProfils }) => {
    if (!state.entities[idOrganization]) {
      return state;
    }
    const profils = (state.entities[idOrganization]?.profils as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idOrganization]: {
          ...state.entities[idOrganization],
          profils: profils.concat(idProfils.filter(id => profils.indexOf(id) < 0))
        }
      }
    };
  }),

  on(OrganizationActions.deleteManyProfilSuccess, (state: OrganizationState, { idProfils, idOrganizations }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idOrganizations.reduce((entities, idOrganization) => {
          if (!state.entities[idOrganization]?.profils) {
            return entities;
          }
          entities[idOrganization] = {
            ...state.entities[idOrganization],
            profils: (state.entities[idOrganization]?.profils as number[])?.filter(
              (idProfil: number) => !idProfils.some((id: number) => id === idProfil)
            )
          } as OrganizationEntityState;
          return entities;
        }, {} as Dictionary<OrganizationEntityState>)
      }
    };
  }),

  on(
    OrganizationActions.addManyGeneratedDocumentSuccess,
    (state: OrganizationState, { idOrganization, idGeneratedDocuments }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const generatedDocuments = (state.entities[idOrganization]?.generatedDocuments as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            generatedDocuments: generatedDocuments.concat(
              idGeneratedDocuments.filter(id => generatedDocuments.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyGeneratedDocumentSuccess,
    (state: OrganizationState, { idGeneratedDocuments, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.generatedDocuments) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              generatedDocuments: (state.entities[idOrganization]?.generatedDocuments as number[])?.filter(
                (idGeneratedDocument: number) => !idGeneratedDocuments.some((id: number) => id === idGeneratedDocument)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationThirdPartySuccess,
    (state: OrganizationState, { idOrganization, idOrganizationThirdParties }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationThirdParties = (state.entities[idOrganization]?.organizationThirdParties as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationThirdParties: organizationThirdParties.concat(
              idOrganizationThirdParties.filter(id => organizationThirdParties.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationThirdPartySuccess,
    (state: OrganizationState, { idOrganizationThirdParties, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationThirdParties) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationThirdParties: (state.entities[idOrganization]?.organizationThirdParties as number[])?.filter(
                (idOrganizationThirdParty: number) =>
                  !idOrganizationThirdParties.some((id: number) => id === idOrganizationThirdParty)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationStratalotAvancementSuccess,
    (state: OrganizationState, { idOrganization, idOrganizationStratalotAvancements }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationStratalotAvancements =
        (state.entities[idOrganization]?.organizationStratalotAvancements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationStratalotAvancements: organizationStratalotAvancements.concat(
              idOrganizationStratalotAvancements.filter(id => organizationStratalotAvancements.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationStratalotAvancementSuccess,
    (state: OrganizationState, { idOrganizationStratalotAvancements, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationStratalotAvancements) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationStratalotAvancements: (
                state.entities[idOrganization]?.organizationStratalotAvancements as number[]
              )?.filter(
                (idOrganizationStratalotAvancement: number) =>
                  !idOrganizationStratalotAvancements.some((id: number) => id === idOrganizationStratalotAvancement)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationLeadAvancementSuccess,
    (state: OrganizationState, { idOrganization, idOrganizationLeadAvancements }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationLeadAvancements =
        (state.entities[idOrganization]?.organizationLeadAvancements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationLeadAvancements: organizationLeadAvancements.concat(
              idOrganizationLeadAvancements.filter(id => organizationLeadAvancements.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationLeadAvancementSuccess,
    (state: OrganizationState, { idOrganizationLeadAvancements, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationLeadAvancements) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationLeadAvancements: (
                state.entities[idOrganization]?.organizationLeadAvancements as number[]
              )?.filter(
                (idOrganizationLeadAvancement: number) =>
                  !idOrganizationLeadAvancements.some((id: number) => id === idOrganizationLeadAvancement)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationSaleCategorySuccess,
    (state: OrganizationState, { idOrganization, idOrganizationSaleCategories }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationSaleCategories = (state.entities[idOrganization]?.organizationSaleCategories as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationSaleCategories: organizationSaleCategories.concat(
              idOrganizationSaleCategories.filter(id => organizationSaleCategories.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationSaleCategorySuccess,
    (state: OrganizationState, { idOrganizationSaleCategories, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationSaleCategories) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationSaleCategories: (
                state.entities[idOrganization]?.organizationSaleCategories as number[]
              )?.filter(
                (idOrganizationSaleCategory: number) =>
                  !idOrganizationSaleCategories.some((id: number) => id === idOrganizationSaleCategory)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationBuyingWishSuccess,
    (state: OrganizationState, { idOrganization, idOrganizationBuyingWishes }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationBuyingWishes = (state.entities[idOrganization]?.organizationBuyingWishes as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationBuyingWishes: organizationBuyingWishes.concat(
              idOrganizationBuyingWishes.filter(id => organizationBuyingWishes.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationBuyingWishSuccess,
    (state: OrganizationState, { idOrganizationBuyingWishes, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationBuyingWishes) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationBuyingWishes: (state.entities[idOrganization]?.organizationBuyingWishes as number[])?.filter(
                (idOrganizationBuyingWish: number) =>
                  !idOrganizationBuyingWishes.some((id: number) => id === idOrganizationBuyingWish)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationEnergieSuccess,
    (state: OrganizationState, { idOrganization, idOrganizationEnergies }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationEnergies = (state.entities[idOrganization]?.organizationEnergies as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationEnergies: organizationEnergies.concat(
              idOrganizationEnergies.filter(id => organizationEnergies.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationEnergieSuccess,
    (state: OrganizationState, { idOrganizationEnergies, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationEnergies) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationEnergies: (state.entities[idOrganization]?.organizationEnergies as number[])?.filter(
                (idOrganizationEnergie: number) =>
                  !idOrganizationEnergies.some((id: number) => id === idOrganizationEnergie)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationActions.addManyOrganizationForecastRateSuccess,
    (state: OrganizationState, { idOrganization, idOrganizationForecastRates }) => {
      if (!state.entities[idOrganization]) {
        return state;
      }
      const organizationForecastRates = (state.entities[idOrganization]?.organizationForecastRates as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganization]: {
            ...state.entities[idOrganization],
            organizationForecastRates: organizationForecastRates.concat(
              idOrganizationForecastRates.filter(id => organizationForecastRates.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationActions.deleteManyOrganizationForecastRateSuccess,
    (state: OrganizationState, { idOrganizationForecastRates, idOrganizations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizations.reduce((entities, idOrganization) => {
            if (!state.entities[idOrganization]?.organizationForecastRates) {
              return entities;
            }
            entities[idOrganization] = {
              ...state.entities[idOrganization],
              organizationForecastRates: (
                state.entities[idOrganization]?.organizationForecastRates as number[]
              )?.filter(
                (idOrganizationForecastRate: number) =>
                  !idOrganizationForecastRates.some((id: number) => id === idOrganizationForecastRate)
              )
            } as OrganizationEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationState {
  return { ...state, isLoaded, isLoading };
}
