import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CompanyCommunicationRecipientMetier,
  CompanyCommunicationRecipientMetierEntityState
} from '@_model/interfaces/company-communication-recipient-metier.model';
import {
  CompanyCommunicationRecipient,
  CompanyCommunicationRecipientEntityState
} from '@_model/interfaces/company-communication-recipient.model';
import { OrganizationMetier, OrganizationMetierEntityState } from '@_model/interfaces/organization-metier.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  companyCommunicationRecipientMetierFeatureKey,
  CompanyCommunicationRecipientMetierState
} from './company-communication-recipient-metier.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyCommunicationRecipientMetierRelations: string[] = [
  'companyCommunicationRecipients',
  'organizationMetiers'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyCommunicationRecipientMetierState =
  createFeatureSelector<CompanyCommunicationRecipientMetierState>(companyCommunicationRecipientMetierFeatureKey);

export const selectIsLoadedCompanyCommunicationRecipientMetier = createSelector(
  selectCompanyCommunicationRecipientMetierState,
  (state: CompanyCommunicationRecipientMetierState) => state.isLoaded
);

export const selectIsLoadingCompanyCommunicationRecipientMetier = createSelector(
  selectCompanyCommunicationRecipientMetierState,
  (state: CompanyCommunicationRecipientMetierState) => state.isLoading
);

export const selectIsReadyCompanyCommunicationRecipientMetier = createSelector(
  selectCompanyCommunicationRecipientMetierState,
  (state: CompanyCommunicationRecipientMetierState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyCommunicationRecipientMetier = createSelector(
  selectCompanyCommunicationRecipientMetierState,
  (state: CompanyCommunicationRecipientMetierState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyCommunicationRecipientMetierModel: SelectorModel = {
  name: 'companyCommunicationRecipientMetiers',
  getSelector: selectAllCompanyCommunicationRecipientMetiersDictionary,
  isReady: selectIsReadyCompanyCommunicationRecipientMetier
};

export const selectCompanyCommunicationRecipientMetiersEntities = createSelector(
  selectCompanyCommunicationRecipientMetierState,
  selectEntities
);

export const selectCompanyCommunicationRecipientMetiersArray = createSelector(
  selectCompanyCommunicationRecipientMetierState,
  selectAll
);

export const selectIdCompanyCommunicationRecipientMetiersActive = createSelector(
  selectCompanyCommunicationRecipientMetierState,
  (state: CompanyCommunicationRecipientMetierState) => state.actives
);

const companyCommunicationRecipientMetiersInObject = (
  companyCommunicationRecipientMetiers: Dictionary<CompanyCommunicationRecipientMetierEntityState>
) => ({ companyCommunicationRecipientMetiers });

const selectCompanyCommunicationRecipientMetiersEntitiesDictionary = createSelector(
  selectCompanyCommunicationRecipientMetiersEntities,
  companyCommunicationRecipientMetiersInObject
);

const selectAllCompanyCommunicationRecipientMetiersObject = createSelector(
  selectCompanyCommunicationRecipientMetiersEntities,
  companyCommunicationRecipientMetiers => {
    return hydrateAll({ companyCommunicationRecipientMetiers });
  }
);

const selectOneCompanyCommunicationRecipientMetierDictionary = (idCompanyCommunicationRecipientMetier: number) =>
  createSelector(selectCompanyCommunicationRecipientMetiersEntities, companyCommunicationRecipientMetiers => {
    return {
      companyCommunicationRecipientMetiers: {
        [idCompanyCommunicationRecipientMetier]:
          companyCommunicationRecipientMetiers[idCompanyCommunicationRecipientMetier]
      }
    };
  });

const selectOneCompanyCommunicationRecipientMetierDictionaryWithoutChild = (
  idCompanyCommunicationRecipientMetier: number
) =>
  createSelector(selectCompanyCommunicationRecipientMetiersEntities, companyCommunicationRecipientMetiers => {
    return {
      companyCommunicationRecipientMetier: companyCommunicationRecipientMetiers[idCompanyCommunicationRecipientMetier]
    };
  });

const selectActiveCompanyCommunicationRecipientMetiersEntities = createSelector(
  selectIdCompanyCommunicationRecipientMetiersActive,
  selectCompanyCommunicationRecipientMetiersEntities,
  (
    actives: number[],
    companyCommunicationRecipientMetiers: Dictionary<CompanyCommunicationRecipientMetierEntityState>
  ) => getCompanyCommunicationRecipientMetiersFromActives(actives, companyCommunicationRecipientMetiers)
);

function getCompanyCommunicationRecipientMetiersFromActives(
  actives: number[],
  companyCommunicationRecipientMetiers: Dictionary<CompanyCommunicationRecipientMetierEntityState>
): Dictionary<CompanyCommunicationRecipientMetierEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyCommunicationRecipientMetiers[idActive]) {
      acc[idActive] = companyCommunicationRecipientMetiers[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyCommunicationRecipientMetierEntityState>);
}

const selectAllCompanyCommunicationRecipientMetiersSelectors: Dictionary<Selector> = {};
export function selectAllCompanyCommunicationRecipientMetiers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyCommunicationRecipientMetier>(
      schema,
      selectAllCompanyCommunicationRecipientMetiersSelectors,
      selectCompanyCommunicationRecipientMetiersEntitiesDictionary,
      getRelationSelectors,
      companyCommunicationRecipientMetierRelations,
      hydrateAll,
      'companyCommunicationRecipientMetier'
    );
  } else {
    return selectAllCompanyCommunicationRecipientMetiersObject;
  }
}

export function selectAllCompanyCommunicationRecipientMetiersDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyCommunicationRecipientMetiers'
): Selector {
  return createSelector(selectAllCompanyCommunicationRecipientMetiers(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyCommunicationRecipientMetierEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyCommunicationRecipientMetiers.length; i++) {
      res[customKey][result.companyCommunicationRecipientMetiers[i].idCompanyCommunicationRecipientMetier] =
        result.companyCommunicationRecipientMetiers[i];
    }
    return res;
  });
}

export function selectOneCompanyCommunicationRecipientMetier(
  schema: SelectSchema = {},
  idCompanyCommunicationRecipientMetier: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [
      selectOneCompanyCommunicationRecipientMetierDictionary(idCompanyCommunicationRecipientMetier)
    ];
    selectors.push(
      ...getRelationSelectors(
        schema,
        companyCommunicationRecipientMetierRelations,
        'companyCommunicationRecipientMetier'
      )
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyCommunicationRecipientMetierDictionaryWithoutChild(idCompanyCommunicationRecipientMetier);
  }
}

export function selectActiveCompanyCommunicationRecipientMetiers(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyCommunicationRecipientMetiersEntities, companyCommunicationRecipientMetiers => ({
      companyCommunicationRecipientMetiers
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, companyCommunicationRecipientMetierRelations, 'companyCommunicationRecipientMetier')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyCommunicationRecipientMetiers: Dictionary<CompanyCommunicationRecipientMetierEntityState>;
  companyCommunicationRecipients?: Dictionary<CompanyCommunicationRecipientEntityState>;
  organizationMetiers?: Dictionary<OrganizationMetierEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  companyCommunicationRecipientMetiers: (CompanyCommunicationRecipientMetier | null)[];
} {
  const { companyCommunicationRecipientMetiers, companyCommunicationRecipients, organizationMetiers } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    companyCommunicationRecipientMetiers: Object.keys(companyCommunicationRecipientMetiers).map(
      idCompanyCommunicationRecipientMetier =>
        hydrate(
          companyCommunicationRecipientMetiers[
            idCompanyCommunicationRecipientMetier
          ] as CompanyCommunicationRecipientMetierEntityState,
          companyCommunicationRecipients,
          organizationMetiers
        )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  companyCommunicationRecipientMetier: CompanyCommunicationRecipientMetierEntityState | null;
} {
  const { companyCommunicationRecipientMetiers, companyCommunicationRecipients, organizationMetiers } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const companyCommunicationRecipientMetier = Object.values(companyCommunicationRecipientMetiers)[0];
  return {
    companyCommunicationRecipientMetier: hydrate(
      companyCommunicationRecipientMetier as CompanyCommunicationRecipientMetierEntityState,
      companyCommunicationRecipients,
      organizationMetiers
    )
  };
}

function hydrate(
  companyCommunicationRecipientMetier: CompanyCommunicationRecipientMetierEntityState,
  companyCommunicationRecipientEntities?: Dictionary<CompanyCommunicationRecipientEntityState>,
  organizationMetierEntities?: Dictionary<OrganizationMetierEntityState>
): CompanyCommunicationRecipientMetier | null {
  if (!companyCommunicationRecipientMetier) {
    return null;
  }

  const companyCommunicationRecipientMetierHydrated: CompanyCommunicationRecipientMetierEntityState = {
    ...companyCommunicationRecipientMetier
  };
  if (companyCommunicationRecipientEntities) {
    companyCommunicationRecipientMetierHydrated.companyCommunicationRecipient = companyCommunicationRecipientEntities[
      companyCommunicationRecipientMetier.companyCommunicationRecipient as number
    ] as CompanyCommunicationRecipient;
  } else {
    delete companyCommunicationRecipientMetierHydrated.companyCommunicationRecipient;
  }
  if (organizationMetierEntities) {
    companyCommunicationRecipientMetierHydrated.organizationMetier = organizationMetierEntities[
      companyCommunicationRecipientMetier.organizationMetier as number
    ] as OrganizationMetier;
  } else {
    delete companyCommunicationRecipientMetierHydrated.organizationMetier;
  }

  return companyCommunicationRecipientMetierHydrated as CompanyCommunicationRecipientMetier;
}
