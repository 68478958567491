import { StepState, initialState } from './step.state';
import { Action, createReducer } from '@ngrx/store';
import { stepReducersGeneratedFunctions } from './step-generated.reducer';

const stepReducersFunctions = [...stepReducersGeneratedFunctions];

const stepReducer = createReducer(initialState, ...stepReducersFunctions);

export function reducer(state: StepState | undefined, action: Action) {
  return stepReducer(state, action);
}
