import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  CompanyCommunicationFile,
  CompanyCommunicationFileEntityState
} from '@_model/interfaces/company-communication-file.model';
import { CompanyCommunicationFileApiService } from '@_services/api/company-communication-file-api.service';
import * as companyCommunicationFileActions from '@_store/company-communication-file/company-communication-file.actions';
import { getActionsToNormalizeCompanyCommunicationFile } from '@_config/store/normalization.generated';
import { selectCompanyCommunicationFileState } from './company-communication-file-generated.selectors';
import * as companyCommunicationActions from '@_store/company-communication/company-communication.actions';

export interface CompanyCommunicationFileRelationsIds {
  companyCommunication?: number;
}

export function getDefaultAddCompanyCommunicationFileActions(
  companyCommunicationFile: CompanyCommunicationFileEntityState,
  ids?: CompanyCommunicationFileRelationsIds
): Action[] {
  const actions: Action[] = [
    companyCommunicationFileActions.normalizeManyCompanyCommunicationFilesAfterUpsert({
      companyCommunicationFiles: [companyCommunicationFile]
    })
  ];

  if (ids?.companyCommunication) {
    actions.push(
      companyCommunicationActions.addManyCompanyCommunicationFileSuccess({
        idCompanyCommunication: ids.companyCommunication,
        idCompanyCommunicationFiles: [companyCommunicationFile.idCompanyCommunicationFile]
      })
    );
    actions.push(
      companyCommunicationFileActions.addCompanyCommunicationSuccess({
        idCompanyCommunicationFile: companyCommunicationFile.idCompanyCommunicationFile,
        idCompanyCommunication: ids.companyCommunication
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCompanyCommunicationFileActions(
  companyCommunicationFile: CompanyCommunicationFileEntityState
): Action[] {
  const actions: Action[] = [
    companyCommunicationFileActions.deleteOneCompanyCommunicationFileSuccess({
      idCompanyCommunicationFile: companyCommunicationFile.idCompanyCommunicationFile
    })
  ];

  if (companyCommunicationFile.companyCommunication) {
    actions.push(
      companyCommunicationActions.deleteManyCompanyCommunicationFileSuccess({
        idCompanyCommunicationFiles: [companyCommunicationFile.idCompanyCommunicationFile],
        idCompanyCommunications: [companyCommunicationFile.companyCommunication as number]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyCommunicationFileEffects {
  constructor(
    protected actions$: Actions,
    protected companyCommunicationFileApiService: CompanyCommunicationFileApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyCommunicationFiles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationFileActions.getManyCompanyCommunicationFiles),
      switchMap(({ params }) =>
        this.companyCommunicationFileApiService.getCompanyCommunicationFiles(params).pipe(
          map((companyCommunicationFiles: CompanyCommunicationFile[]) => {
            return companyCommunicationFileActions.normalizeManyCompanyCommunicationFilesAfterUpsert({
              companyCommunicationFiles
            });
          }),
          catchError(error => of(companyCommunicationFileActions.companyCommunicationFilesFailure({ error })))
        )
      )
    );
  });

  getOneCompanyCommunicationFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationFileActions.getOneCompanyCommunicationFile),
      switchMap(idCompanyCommunicationFile =>
        this.companyCommunicationFileApiService.getCompanyCommunicationFile(idCompanyCommunicationFile).pipe(
          map((companyCommunicationFile: CompanyCommunicationFile) => {
            return companyCommunicationFileActions.normalizeManyCompanyCommunicationFilesAfterUpsert({
              companyCommunicationFiles: [companyCommunicationFile]
            });
          }),
          catchError(error => of(companyCommunicationFileActions.companyCommunicationFilesFailure({ error })))
        )
      )
    );
  });

  upsertOneCompanyCommunicationFile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationFileActions.upsertOneCompanyCommunicationFile),
      concatMap(
        ({
          companyCommunicationFile,
          ids
        }: {
          companyCommunicationFile: Partial<CompanyCommunicationFile>;
          ids?: CompanyCommunicationFileRelationsIds;
        }) => {
          if (companyCommunicationFile.idCompanyCommunicationFile) {
            return this.companyCommunicationFileApiService
              .updateCompanyCommunicationFile(companyCommunicationFile)
              .pipe(
                map((companyCommunicationFileReturned: CompanyCommunicationFile) => {
                  return companyCommunicationFileActions.normalizeManyCompanyCommunicationFilesAfterUpsert({
                    companyCommunicationFiles: [companyCommunicationFileReturned]
                  });
                }),
                catchError(error => of(companyCommunicationFileActions.companyCommunicationFilesFailure({ error })))
              );
          } else {
            return this.companyCommunicationFileApiService.addCompanyCommunicationFile(companyCommunicationFile).pipe(
              mergeMap((companyCommunicationFileReturned: CompanyCommunicationFile) =>
                getDefaultAddCompanyCommunicationFileActions(companyCommunicationFileReturned, ids)
              ),
              catchError(error => of(companyCommunicationFileActions.companyCommunicationFilesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCompanyCommunicationFile$ = createEffect(() => {
    const selectCompanyCommunicationFileState$ = this.store$.select(selectCompanyCommunicationFileState);
    return this.actions$.pipe(
      ofType(companyCommunicationFileActions.deleteOneCompanyCommunicationFile),
      withLatestFrom(selectCompanyCommunicationFileState$),
      concatMap(([{ idCompanyCommunicationFile }, state]) =>
        this.companyCommunicationFileApiService.deleteCompanyCommunicationFile(idCompanyCommunicationFile).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCompanyCommunicationFileActions(
                state.entities[idCompanyCommunicationFile] as CompanyCommunicationFileEntityState
              ),
              companyCommunicationFileActions.deleteOneCompanyCommunicationFile.type
            )
          ]),
          catchError(error => of(companyCommunicationFileActions.companyCommunicationFilesFailure({ error })))
        )
      )
    );
  });

  normalizeManyCompanyCommunicationFilesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationFileActions.normalizeManyCompanyCommunicationFilesAfterUpsert),
      concatMap(({ companyCommunicationFiles }) => {
        const actions: Action[] = getActionsToNormalizeCompanyCommunicationFile(
          companyCommunicationFiles,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CompanyCommunicationFile] Normalization After Upsert Success')];
      })
    );
  });
}
