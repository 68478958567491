import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreadcrumbModule } from '@_shared/dumb/breadcrumb/breadcrumb.module';

@Component({
  selector: 'app-user-no-access-page',
  templateUrl: './user-no-access-page.component.html',
  styleUrls: ['./user-no-access-page.component.scss']
})
export class UserNoAccessPageComponent {}
@NgModule({
  declarations: [UserNoAccessPageComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatButtonModule,
    FlexLayoutModule,
    MatSlideToggleModule,
    BreadcrumbModule,
    AgGridModule
  ]
})
export class UserNoAccessPageModule {}
