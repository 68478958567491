import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { GeneratedDocument, GeneratedDocumentEntityState } from '@_model/interfaces/generated-document.model';
import { GeneratedDocumentRelationsIds } from './generated-document-generated.effects';

export const getOneGeneratedDocument = createAction(
  '[GeneratedDocument] Get One GeneratedDocument',
  props<{ idGeneratedDocument: number; params?: any }>()
);

export const getManyGeneratedDocuments = createAction(
  '[GeneratedDocument] Get Many GeneratedDocuments',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[GeneratedDocument] Add Many Actives GeneratedDocument',
  props<{ idGeneratedDocuments: number[] }>()
);

export const deleteOneActive = createAction(
  '[GeneratedDocument] Delete One Active GeneratedDocument',
  props<{ idGeneratedDocument: number }>()
);

export const clearActive = createAction('[GeneratedDocument] Clear Active GeneratedDocument');

export const upsertOneGeneratedDocument = createAction(
  '[GeneratedDocument] Upsert One GeneratedDocument',
  props<{
    generatedDocument: Partial<GeneratedDocument>;
    ids?: GeneratedDocumentRelationsIds;
  }>()
);

export const upsertManyGeneratedDocuments = createAction(
  '[GeneratedDocument] Upsert Many GeneratedDocuments',
  props<{
    generatedDocuments: Partial<GeneratedDocument>[];
    ids?: GeneratedDocumentRelationsIds;
  }>()
);

export const upsertManyGeneratedDocumentsSuccess = createAction(
  '[GeneratedDocument] Create Many GeneratedDocuments Success',
  props<{ generatedDocuments: GeneratedDocumentEntityState[] }>()
);

export const deleteOneGeneratedDocument = createAction(
  '[GeneratedDocument] Delete One GeneratedDocument',
  props<{ idGeneratedDocument: number }>()
);

export const deleteOneGeneratedDocumentSuccess = createAction(
  '[GeneratedDocument] Delete One GeneratedDocument Success',
  props<{ idGeneratedDocument: number }>()
);

export const normalizeManyGeneratedDocumentsAfterUpsert = createAction(
  '[GeneratedDocument] Normalize Many GeneratedDocuments After Upsert',
  props<{ generatedDocuments: GeneratedDocumentEntityState[] }>()
);

export const generatedDocumentsFailure = createAction(
  '[GeneratedDocument] GeneratedDocuments Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[GeneratedDocument] Clear GeneratedDocuments');

export const addManyGeneratedDocumentsCompanySuccess = createAction(
  '[GeneratedDocument] Add Many generated-documents-company',
  props<{ idGeneratedDocument: number; idGeneratedDocumentsCompanies: number[] }>()
);

export const deleteManyGeneratedDocumentsCompanySuccess = createAction(
  '[GeneratedDocument] Delete Many GeneratedDocumentsCompanies',
  props<{ idGeneratedDocumentsCompanies: number[]; idGeneratedDocuments: number[] }>()
);

export const addManyCompanySuccess = createAction(
  '[GeneratedDocument] Add Many company',
  props<{ idGeneratedDocument: number; idCompanies: number[] }>()
);

export const deleteManyCompanySuccess = createAction(
  '[GeneratedDocument] Delete Many Companies',
  props<{ idCompanies: number[]; idGeneratedDocuments: number[] }>()
);

export const addManyResidenceSuccess = createAction(
  '[GeneratedDocument] Add Many residence',
  props<{ idGeneratedDocument: number; idResidences: number[] }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[GeneratedDocument] Delete Many Residences',
  props<{ idResidences: number[]; idGeneratedDocuments: number[] }>()
);

export const addManyGeneratedDocumentsResidenceSuccess = createAction(
  '[GeneratedDocument] Add Many generated-documents-residence',
  props<{ idGeneratedDocument: number; idGeneratedDocumentsResidences: number[] }>()
);

export const deleteManyGeneratedDocumentsResidenceSuccess = createAction(
  '[GeneratedDocument] Delete Many GeneratedDocumentsResidences',
  props<{ idGeneratedDocumentsResidences: number[]; idGeneratedDocuments: number[] }>()
);

export const addManyCompanyCommunicationSuccess = createAction(
  '[GeneratedDocument] Add Many company-communication',
  props<{ idGeneratedDocument: number; idCompanyCommunications: number[] }>()
);

export const deleteManyCompanyCommunicationSuccess = createAction(
  '[GeneratedDocument] Delete Many CompanyCommunications',
  props<{ idCompanyCommunications: number[]; idGeneratedDocuments: number[] }>()
);

export const addManyCompanyCommunicationGeneratedDocumentSuccess = createAction(
  '[GeneratedDocument] Add Many company-communication-generated-document',
  props<{ idGeneratedDocument: number; idCompanyCommunicationGeneratedDocuments: number[] }>()
);

export const deleteManyCompanyCommunicationGeneratedDocumentSuccess = createAction(
  '[GeneratedDocument] Delete Many CompanyCommunicationGeneratedDocuments',
  props<{ idCompanyCommunicationGeneratedDocuments: number[]; idGeneratedDocuments: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[GeneratedDocument] Add Organization',
  props<{ idGeneratedDocument: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[GeneratedDocument] Delete Many Organization',
  props<{ idOrganizations: number[]; idGeneratedDocuments: number[] }>()
);
