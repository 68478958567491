import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyActions from './company.actions';
import { adapter, initialState, CompanyState } from './company.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyEntityState } from '@_model/interfaces/company.model';

export const companyReducersGeneratedFunctions: ReducerTypes<CompanyState, readonly ActionCreator[]>[] = [
  on(CompanyActions.getOneCompany, (state: CompanyState) => setLoadingsState(state, true)),
  on(CompanyActions.getManyCompanies, (state: CompanyState) => setLoadingsState(state, true)),
  on(CompanyActions.upsertOneCompany, (state: CompanyState) => setLoadingsState(state, true)),

  on(CompanyActions.upsertManyCompaniesSuccess, (state: CompanyState, { companies }) =>
    adapter.upsertMany(companies, setLoadingsState(state, false))
  ),
  on(CompanyActions.deleteOneCompany, (state: CompanyState) => setLoadingsState(state, true)),
  on(CompanyActions.deleteOneCompanySuccess, (state: CompanyState, { idCompany }) =>
    adapter.removeOne(idCompany, setLoadingsState(state, false))
  ),
  on(CompanyActions.clearActive, (state: CompanyState) => ({ ...state, actives: [] })),
  on(CompanyActions.addManyActives, (state: CompanyState, { idCompanies }) => ({
    ...state,
    actives: state.actives.concat(idCompanies)
  })),
  on(CompanyActions.deleteOneActive, (state: CompanyState, { idCompany }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCompany)
  })),

  on(CompanyActions.clearStore, () => initialState),

  on(CompanyActions.addManyOccupantSuccess, (state: CompanyState, { idCompany, idOccupants }) => {
    if (!state.entities[idCompany]) {
      return state;
    }
    const occupants = (state.entities[idCompany]?.occupants as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCompany]: {
          ...state.entities[idCompany],
          occupants: occupants.concat(idOccupants.filter(id => occupants.indexOf(id) < 0))
        }
      }
    };
  }),

  on(CompanyActions.deleteManyOccupantSuccess, (state: CompanyState, { idOccupants, idCompanies }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idCompanies.reduce((entities, idCompany) => {
          if (!state.entities[idCompany]?.occupants) {
            return entities;
          }
          entities[idCompany] = {
            ...state.entities[idCompany],
            occupants: (state.entities[idCompany]?.occupants as number[])?.filter(
              (idOccupant: number) => !idOccupants.some((id: number) => id === idOccupant)
            )
          } as CompanyEntityState;
          return entities;
        }, {} as Dictionary<CompanyEntityState>)
      }
    };
  }),

  on(CompanyActions.addManyCompanyMediaSuccess, (state: CompanyState, { idCompany, idCompanyMedias }) => {
    if (!state.entities[idCompany]) {
      return state;
    }
    const companyMedias = (state.entities[idCompany]?.companyMedias as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCompany]: {
          ...state.entities[idCompany],
          companyMedias: companyMedias.concat(idCompanyMedias.filter(id => companyMedias.indexOf(id) < 0))
        }
      }
    };
  }),

  on(CompanyActions.deleteManyCompanyMediaSuccess, (state: CompanyState, { idCompanyMedias, idCompanies }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idCompanies.reduce((entities, idCompany) => {
          if (!state.entities[idCompany]?.companyMedias) {
            return entities;
          }
          entities[idCompany] = {
            ...state.entities[idCompany],
            companyMedias: (state.entities[idCompany]?.companyMedias as number[])?.filter(
              (idCompanyMedia: number) => !idCompanyMedias.some((id: number) => id === idCompanyMedia)
            )
          } as CompanyEntityState;
          return entities;
        }, {} as Dictionary<CompanyEntityState>)
      }
    };
  }),

  on(CompanyActions.addManyCitySuccess, (state: CompanyState, { idCompany, idCities }) => {
    if (!state.entities[idCompany]) {
      return state;
    }
    const cities = (state.entities[idCompany]?.cities as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCompany]: {
          ...state.entities[idCompany],
          cities: cities.concat(idCities.filter(id => cities.indexOf(id) < 0))
        }
      }
    };
  }),

  on(CompanyActions.deleteManyCitySuccess, (state: CompanyState, { idCities, idCompanies }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idCompanies.reduce((entities, idCompany) => {
          if (!state.entities[idCompany]?.cities) {
            return entities;
          }
          entities[idCompany] = {
            ...state.entities[idCompany],
            cities: (state.entities[idCompany]?.cities as number[])?.filter(
              (idCity: number) => !idCities.some((id: number) => id === idCity)
            )
          } as CompanyEntityState;
          return entities;
        }, {} as Dictionary<CompanyEntityState>)
      }
    };
  }),

  on(
    CompanyActions.addManyCompanyCommunicationSuccess,
    (state: CompanyState, { idCompany, idCompanyCommunications }) => {
      if (!state.entities[idCompany]) {
        return state;
      }
      const companyCommunications = (state.entities[idCompany]?.companyCommunications as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompany]: {
            ...state.entities[idCompany],
            companyCommunications: companyCommunications.concat(
              idCompanyCommunications.filter(id => companyCommunications.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyActions.deleteManyCompanyCommunicationSuccess,
    (state: CompanyState, { idCompanyCommunications, idCompanies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanies.reduce((entities, idCompany) => {
            if (!state.entities[idCompany]?.companyCommunications) {
              return entities;
            }
            entities[idCompany] = {
              ...state.entities[idCompany],
              companyCommunications: (state.entities[idCompany]?.companyCommunications as number[])?.filter(
                (idCompanyCommunication: number) =>
                  !idCompanyCommunications.some((id: number) => id === idCompanyCommunication)
              )
            } as CompanyEntityState;
            return entities;
          }, {} as Dictionary<CompanyEntityState>)
        }
      };
    }
  ),

  on(CompanyActions.addManyResidenceSuccess, (state: CompanyState, { idCompany, idResidences }) => {
    if (!state.entities[idCompany]) {
      return state;
    }
    const residences = (state.entities[idCompany]?.residences as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCompany]: {
          ...state.entities[idCompany],
          residences: residences.concat(idResidences.filter(id => residences.indexOf(id) < 0))
        }
      }
    };
  }),

  on(CompanyActions.deleteManyResidenceSuccess, (state: CompanyState, { idResidences, idCompanies }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idCompanies.reduce((entities, idCompany) => {
          if (!state.entities[idCompany]?.residences) {
            return entities;
          }
          entities[idCompany] = {
            ...state.entities[idCompany],
            residences: (state.entities[idCompany]?.residences as number[])?.filter(
              (idResidence: number) => !idResidences.some((id: number) => id === idResidence)
            )
          } as CompanyEntityState;
          return entities;
        }, {} as Dictionary<CompanyEntityState>)
      }
    };
  }),

  on(CompanyActions.addManyCompanyPriceLabelSuccess, (state: CompanyState, { idCompany, idCompanyPriceLabels }) => {
    if (!state.entities[idCompany]) {
      return state;
    }
    const companyPriceLabels = (state.entities[idCompany]?.companyPriceLabels as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCompany]: {
          ...state.entities[idCompany],
          companyPriceLabels: companyPriceLabels.concat(
            idCompanyPriceLabels.filter(id => companyPriceLabels.indexOf(id) < 0)
          )
        }
      }
    };
  }),

  on(
    CompanyActions.deleteManyCompanyPriceLabelSuccess,
    (state: CompanyState, { idCompanyPriceLabels, idCompanies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanies.reduce((entities, idCompany) => {
            if (!state.entities[idCompany]?.companyPriceLabels) {
              return entities;
            }
            entities[idCompany] = {
              ...state.entities[idCompany],
              companyPriceLabels: (state.entities[idCompany]?.companyPriceLabels as number[])?.filter(
                (idCompanyPriceLabel: number) => !idCompanyPriceLabels.some((id: number) => id === idCompanyPriceLabel)
              )
            } as CompanyEntityState;
            return entities;
          }, {} as Dictionary<CompanyEntityState>)
        }
      };
    }
  ),

  on(CompanyActions.addManyCompanyTerritoireSuccess, (state: CompanyState, { idCompany, idCompanyTerritoires }) => {
    if (!state.entities[idCompany]) {
      return state;
    }
    const companyTerritoires = (state.entities[idCompany]?.companyTerritoires as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCompany]: {
          ...state.entities[idCompany],
          companyTerritoires: companyTerritoires.concat(
            idCompanyTerritoires.filter(id => companyTerritoires.indexOf(id) < 0)
          )
        }
      }
    };
  }),

  on(
    CompanyActions.deleteManyCompanyTerritoireSuccess,
    (state: CompanyState, { idCompanyTerritoires, idCompanies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanies.reduce((entities, idCompany) => {
            if (!state.entities[idCompany]?.companyTerritoires) {
              return entities;
            }
            entities[idCompany] = {
              ...state.entities[idCompany],
              companyTerritoires: (state.entities[idCompany]?.companyTerritoires as number[])?.filter(
                (idCompanyTerritoire: number) => !idCompanyTerritoires.some((id: number) => id === idCompanyTerritoire)
              )
            } as CompanyEntityState;
            return entities;
          }, {} as Dictionary<CompanyEntityState>)
        }
      };
    }
  ),

  on(
    CompanyActions.addManyOrganizationLeadTodoRuleSuccess,
    (state: CompanyState, { idCompany, idOrganizationLeadTodoRules }) => {
      if (!state.entities[idCompany]) {
        return state;
      }
      const organizationLeadTodoRules = (state.entities[idCompany]?.organizationLeadTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompany]: {
            ...state.entities[idCompany],
            organizationLeadTodoRules: organizationLeadTodoRules.concat(
              idOrganizationLeadTodoRules.filter(id => organizationLeadTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyActions.deleteManyOrganizationLeadTodoRuleSuccess,
    (state: CompanyState, { idOrganizationLeadTodoRules, idCompanies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanies.reduce((entities, idCompany) => {
            if (!state.entities[idCompany]?.organizationLeadTodoRules) {
              return entities;
            }
            entities[idCompany] = {
              ...state.entities[idCompany],
              organizationLeadTodoRules: (state.entities[idCompany]?.organizationLeadTodoRules as number[])?.filter(
                (idOrganizationLeadTodoRule: number) =>
                  !idOrganizationLeadTodoRules.some((id: number) => id === idOrganizationLeadTodoRule)
              )
            } as CompanyEntityState;
            return entities;
          }, {} as Dictionary<CompanyEntityState>)
        }
      };
    }
  ),

  on(
    CompanyActions.addManyOrganizationStratalotTodoRuleSuccess,
    (state: CompanyState, { idCompany, idOrganizationStratalotTodoRules }) => {
      if (!state.entities[idCompany]) {
        return state;
      }
      const organizationStratalotTodoRules =
        (state.entities[idCompany]?.organizationStratalotTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompany]: {
            ...state.entities[idCompany],
            organizationStratalotTodoRules: organizationStratalotTodoRules.concat(
              idOrganizationStratalotTodoRules.filter(id => organizationStratalotTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyActions.deleteManyOrganizationStratalotTodoRuleSuccess,
    (state: CompanyState, { idOrganizationStratalotTodoRules, idCompanies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanies.reduce((entities, idCompany) => {
            if (!state.entities[idCompany]?.organizationStratalotTodoRules) {
              return entities;
            }
            entities[idCompany] = {
              ...state.entities[idCompany],
              organizationStratalotTodoRules: (
                state.entities[idCompany]?.organizationStratalotTodoRules as number[]
              )?.filter(
                (idOrganizationStratalotTodoRule: number) =>
                  !idOrganizationStratalotTodoRules.some((id: number) => id === idOrganizationStratalotTodoRule)
              )
            } as CompanyEntityState;
            return entities;
          }, {} as Dictionary<CompanyEntityState>)
        }
      };
    }
  ),

  on(
    CompanyActions.addManyCompanyStratalotTypeSuccess,
    (state: CompanyState, { idCompany, idCompanyStratalotTypes }) => {
      if (!state.entities[idCompany]) {
        return state;
      }
      const companyStratalotTypes = (state.entities[idCompany]?.companyStratalotTypes as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompany]: {
            ...state.entities[idCompany],
            companyStratalotTypes: companyStratalotTypes.concat(
              idCompanyStratalotTypes.filter(id => companyStratalotTypes.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyActions.deleteManyCompanyStratalotTypeSuccess,
    (state: CompanyState, { idCompanyStratalotTypes, idCompanies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanies.reduce((entities, idCompany) => {
            if (!state.entities[idCompany]?.companyStratalotTypes) {
              return entities;
            }
            entities[idCompany] = {
              ...state.entities[idCompany],
              companyStratalotTypes: (state.entities[idCompany]?.companyStratalotTypes as number[])?.filter(
                (idCompanyStratalotType: number) =>
                  !idCompanyStratalotTypes.some((id: number) => id === idCompanyStratalotType)
              )
            } as CompanyEntityState;
            return entities;
          }, {} as Dictionary<CompanyEntityState>)
        }
      };
    }
  ),

  on(
    CompanyActions.addManyOrganizationResidenceTodoRuleSuccess,
    (state: CompanyState, { idCompany, idOrganizationResidenceTodoRules }) => {
      if (!state.entities[idCompany]) {
        return state;
      }
      const organizationResidenceTodoRules =
        (state.entities[idCompany]?.organizationResidenceTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompany]: {
            ...state.entities[idCompany],
            organizationResidenceTodoRules: organizationResidenceTodoRules.concat(
              idOrganizationResidenceTodoRules.filter(id => organizationResidenceTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyActions.deleteManyOrganizationResidenceTodoRuleSuccess,
    (state: CompanyState, { idOrganizationResidenceTodoRules, idCompanies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanies.reduce((entities, idCompany) => {
            if (!state.entities[idCompany]?.organizationResidenceTodoRules) {
              return entities;
            }
            entities[idCompany] = {
              ...state.entities[idCompany],
              organizationResidenceTodoRules: (
                state.entities[idCompany]?.organizationResidenceTodoRules as number[]
              )?.filter(
                (idOrganizationResidenceTodoRule: number) =>
                  !idOrganizationResidenceTodoRules.some((id: number) => id === idOrganizationResidenceTodoRule)
              )
            } as CompanyEntityState;
            return entities;
          }, {} as Dictionary<CompanyEntityState>)
        }
      };
    }
  ),

  on(
    CompanyActions.addManyCompanyStratalotVacantSuccess,
    (state: CompanyState, { idCompany, idCompanyStratalotVacants }) => {
      if (!state.entities[idCompany]) {
        return state;
      }
      const companyStratalotVacants = (state.entities[idCompany]?.companyStratalotVacants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompany]: {
            ...state.entities[idCompany],
            companyStratalotVacants: companyStratalotVacants.concat(
              idCompanyStratalotVacants.filter(id => companyStratalotVacants.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyActions.deleteManyCompanyStratalotVacantSuccess,
    (state: CompanyState, { idCompanyStratalotVacants, idCompanies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanies.reduce((entities, idCompany) => {
            if (!state.entities[idCompany]?.companyStratalotVacants) {
              return entities;
            }
            entities[idCompany] = {
              ...state.entities[idCompany],
              companyStratalotVacants: (state.entities[idCompany]?.companyStratalotVacants as number[])?.filter(
                (idCompanyStratalotVacant: number) =>
                  !idCompanyStratalotVacants.some((id: number) => id === idCompanyStratalotVacant)
              )
            } as CompanyEntityState;
            return entities;
          }, {} as Dictionary<CompanyEntityState>)
        }
      };
    }
  ),

  on(
    CompanyActions.addManyGeneratedDocumentsCompanySuccess,
    (state: CompanyState, { idCompany, idGeneratedDocumentsCompanies }) => {
      if (!state.entities[idCompany]) {
        return state;
      }
      const generatedDocumentsCompanies = (state.entities[idCompany]?.generatedDocumentsCompanies as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompany]: {
            ...state.entities[idCompany],
            generatedDocumentsCompanies: generatedDocumentsCompanies.concat(
              idGeneratedDocumentsCompanies.filter(id => generatedDocumentsCompanies.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyActions.deleteManyGeneratedDocumentsCompanySuccess,
    (state: CompanyState, { idGeneratedDocumentsCompanies, idCompanies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanies.reduce((entities, idCompany) => {
            if (!state.entities[idCompany]?.generatedDocumentsCompanies) {
              return entities;
            }
            entities[idCompany] = {
              ...state.entities[idCompany],
              generatedDocumentsCompanies: (state.entities[idCompany]?.generatedDocumentsCompanies as number[])?.filter(
                (idGeneratedDocumentsCompany: number) =>
                  !idGeneratedDocumentsCompanies.some((id: number) => id === idGeneratedDocumentsCompany)
              )
            } as CompanyEntityState;
            return entities;
          }, {} as Dictionary<CompanyEntityState>)
        }
      };
    }
  ),

  on(CompanyActions.addManyGeneratedDocumentSuccess, (state: CompanyState, { idCompany, idGeneratedDocuments }) => {
    if (!state.entities[idCompany]) {
      return state;
    }
    const generatedDocuments = (state.entities[idCompany]?.generatedDocuments as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCompany]: {
          ...state.entities[idCompany],
          generatedDocuments: generatedDocuments.concat(
            idGeneratedDocuments.filter(id => generatedDocuments.indexOf(id) < 0)
          )
        }
      }
    };
  }),

  on(
    CompanyActions.deleteManyGeneratedDocumentSuccess,
    (state: CompanyState, { idGeneratedDocuments, idCompanies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanies.reduce((entities, idCompany) => {
            if (!state.entities[idCompany]?.generatedDocuments) {
              return entities;
            }
            entities[idCompany] = {
              ...state.entities[idCompany],
              generatedDocuments: (state.entities[idCompany]?.generatedDocuments as number[])?.filter(
                (idGeneratedDocument: number) => !idGeneratedDocuments.some((id: number) => id === idGeneratedDocument)
              )
            } as CompanyEntityState;
            return entities;
          }, {} as Dictionary<CompanyEntityState>)
        }
      };
    }
  ),

  on(CompanyActions.addManyCompanyUbiflowSuccess, (state: CompanyState, { idCompany, idCompanyUbiflows }) => {
    if (!state.entities[idCompany]) {
      return state;
    }
    const companyUbiflows = (state.entities[idCompany]?.companyUbiflows as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCompany]: {
          ...state.entities[idCompany],
          companyUbiflows: companyUbiflows.concat(idCompanyUbiflows.filter(id => companyUbiflows.indexOf(id) < 0))
        }
      }
    };
  }),

  on(CompanyActions.deleteManyCompanyUbiflowSuccess, (state: CompanyState, { idCompanyUbiflows, idCompanies }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idCompanies.reduce((entities, idCompany) => {
          if (!state.entities[idCompany]?.companyUbiflows) {
            return entities;
          }
          entities[idCompany] = {
            ...state.entities[idCompany],
            companyUbiflows: (state.entities[idCompany]?.companyUbiflows as number[])?.filter(
              (idCompanyUbiflow: number) => !idCompanyUbiflows.some((id: number) => id === idCompanyUbiflow)
            )
          } as CompanyEntityState;
          return entities;
        }, {} as Dictionary<CompanyEntityState>)
      }
    };
  }),

  on(CompanyActions.addManyCompanyMyNotarySuccess, (state: CompanyState, { idCompany, idCompanyMyNotaries }) => {
    if (!state.entities[idCompany]) {
      return state;
    }
    const companyMyNotaries = (state.entities[idCompany]?.companyMyNotaries as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCompany]: {
          ...state.entities[idCompany],
          companyMyNotaries: companyMyNotaries.concat(
            idCompanyMyNotaries.filter(id => companyMyNotaries.indexOf(id) < 0)
          )
        }
      }
    };
  }),

  on(CompanyActions.deleteManyCompanyMyNotarySuccess, (state: CompanyState, { idCompanyMyNotaries, idCompanies }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idCompanies.reduce((entities, idCompany) => {
          if (!state.entities[idCompany]?.companyMyNotaries) {
            return entities;
          }
          entities[idCompany] = {
            ...state.entities[idCompany],
            companyMyNotaries: (state.entities[idCompany]?.companyMyNotaries as number[])?.filter(
              (idCompanyMyNotary: number) => !idCompanyMyNotaries.some((id: number) => id === idCompanyMyNotary)
            )
          } as CompanyEntityState;
          return entities;
        }, {} as Dictionary<CompanyEntityState>)
      }
    };
  }),

  on(
    CompanyActions.addManyCompanyStudyNextActionSuccess,
    (state: CompanyState, { idCompany, idCompanyStudyNextActions }) => {
      if (!state.entities[idCompany]) {
        return state;
      }
      const companyStudyNextActions = (state.entities[idCompany]?.companyStudyNextActions as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompany]: {
            ...state.entities[idCompany],
            companyStudyNextActions: companyStudyNextActions.concat(
              idCompanyStudyNextActions.filter(id => companyStudyNextActions.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyActions.deleteManyCompanyStudyNextActionSuccess,
    (state: CompanyState, { idCompanyStudyNextActions, idCompanies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanies.reduce((entities, idCompany) => {
            if (!state.entities[idCompany]?.companyStudyNextActions) {
              return entities;
            }
            entities[idCompany] = {
              ...state.entities[idCompany],
              companyStudyNextActions: (state.entities[idCompany]?.companyStudyNextActions as number[])?.filter(
                (idCompanyStudyNextAction: number) =>
                  !idCompanyStudyNextActions.some((id: number) => id === idCompanyStudyNextAction)
              )
            } as CompanyEntityState;
            return entities;
          }, {} as Dictionary<CompanyEntityState>)
        }
      };
    }
  ),

  on(
    CompanyActions.addManyCompanyStudyConclusionSuccess,
    (state: CompanyState, { idCompany, idCompanyStudyConclusions }) => {
      if (!state.entities[idCompany]) {
        return state;
      }
      const companyStudyConclusions = (state.entities[idCompany]?.companyStudyConclusions as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompany]: {
            ...state.entities[idCompany],
            companyStudyConclusions: companyStudyConclusions.concat(
              idCompanyStudyConclusions.filter(id => companyStudyConclusions.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyActions.deleteManyCompanyStudyConclusionSuccess,
    (state: CompanyState, { idCompanyStudyConclusions, idCompanies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanies.reduce((entities, idCompany) => {
            if (!state.entities[idCompany]?.companyStudyConclusions) {
              return entities;
            }
            entities[idCompany] = {
              ...state.entities[idCompany],
              companyStudyConclusions: (state.entities[idCompany]?.companyStudyConclusions as number[])?.filter(
                (idCompanyStudyConclusion: number) =>
                  !idCompanyStudyConclusions.some((id: number) => id === idCompanyStudyConclusion)
              )
            } as CompanyEntityState;
            return entities;
          }, {} as Dictionary<CompanyEntityState>)
        }
      };
    }
  ),

  on(CompanyActions.addManyCompanyStudyReasonSuccess, (state: CompanyState, { idCompany, idCompanyStudyReasons }) => {
    if (!state.entities[idCompany]) {
      return state;
    }
    const companyStudyReasons = (state.entities[idCompany]?.companyStudyReasons as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCompany]: {
          ...state.entities[idCompany],
          companyStudyReasons: companyStudyReasons.concat(
            idCompanyStudyReasons.filter(id => companyStudyReasons.indexOf(id) < 0)
          )
        }
      }
    };
  }),

  on(
    CompanyActions.deleteManyCompanyStudyReasonSuccess,
    (state: CompanyState, { idCompanyStudyReasons, idCompanies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanies.reduce((entities, idCompany) => {
            if (!state.entities[idCompany]?.companyStudyReasons) {
              return entities;
            }
            entities[idCompany] = {
              ...state.entities[idCompany],
              companyStudyReasons: (state.entities[idCompany]?.companyStudyReasons as number[])?.filter(
                (idCompanyStudyReason: number) =>
                  !idCompanyStudyReasons.some((id: number) => id === idCompanyStudyReason)
              )
            } as CompanyEntityState;
            return entities;
          }, {} as Dictionary<CompanyEntityState>)
        }
      };
    }
  ),

  on(
    CompanyActions.addManyCompanyStudyCriteriaSuccess,
    (state: CompanyState, { idCompany, idCompanyStudyCriterias }) => {
      if (!state.entities[idCompany]) {
        return state;
      }
      const companyStudyCriterias = (state.entities[idCompany]?.companyStudyCriterias as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompany]: {
            ...state.entities[idCompany],
            companyStudyCriterias: companyStudyCriterias.concat(
              idCompanyStudyCriterias.filter(id => companyStudyCriterias.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyActions.deleteManyCompanyStudyCriteriaSuccess,
    (state: CompanyState, { idCompanyStudyCriterias, idCompanies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanies.reduce((entities, idCompany) => {
            if (!state.entities[idCompany]?.companyStudyCriterias) {
              return entities;
            }
            entities[idCompany] = {
              ...state.entities[idCompany],
              companyStudyCriterias: (state.entities[idCompany]?.companyStudyCriterias as number[])?.filter(
                (idCompanyStudyCriteria: number) =>
                  !idCompanyStudyCriterias.some((id: number) => id === idCompanyStudyCriteria)
              )
            } as CompanyEntityState;
            return entities;
          }, {} as Dictionary<CompanyEntityState>)
        }
      };
    }
  ),

  on(CompanyActions.addManyResidenceStudySuccess, (state: CompanyState, { idCompany, idResidenceStudies }) => {
    if (!state.entities[idCompany]) {
      return state;
    }
    const residenceStudies = (state.entities[idCompany]?.residenceStudies as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCompany]: {
          ...state.entities[idCompany],
          residenceStudies: residenceStudies.concat(idResidenceStudies.filter(id => residenceStudies.indexOf(id) < 0))
        }
      }
    };
  }),

  on(CompanyActions.deleteManyResidenceStudySuccess, (state: CompanyState, { idResidenceStudies, idCompanies }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idCompanies.reduce((entities, idCompany) => {
          if (!state.entities[idCompany]?.residenceStudies) {
            return entities;
          }
          entities[idCompany] = {
            ...state.entities[idCompany],
            residenceStudies: (state.entities[idCompany]?.residenceStudies as number[])?.filter(
              (idResidenceStudy: number) => !idResidenceStudies.some((id: number) => id === idResidenceStudy)
            )
          } as CompanyEntityState;
          return entities;
        }, {} as Dictionary<CompanyEntityState>)
      }
    };
  }),

  on(CompanyActions.addOrganizationSuccess, (state: CompanyState, { idCompany, idOrganization }) => {
    if (!state.entities[idCompany]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCompany]: {
          ...state.entities[idCompany],
          organization: idOrganization
        }
      }
    };
  }),

  on(CompanyActions.deleteManyOrganizationSuccess, (state: CompanyState, { idOrganizations, idCompanies }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idCompanies.reduce((entities, idCompany) => {
          if (!state.entities[idCompany]?.organization) {
            return entities;
          }
          entities[idCompany] = {
            ...state.entities[idCompany],
            organization: idOrganizations.some(
              (idOrganization: number) => idOrganization === state.entities[idCompany]?.organization
            )
              ? undefined
              : state.entities[idCompany]?.organization
          } as CompanyEntityState;
          return entities;
        }, {} as Dictionary<CompanyEntityState>)
      }
    };
  })
];

export function setLoadingsState(state: CompanyState, isLoading: boolean, isLoaded: boolean = true): CompanyState {
  return { ...state, isLoaded, isLoading };
}
