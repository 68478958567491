import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { StratalotAssociation, StratalotAssociationEntityState } from '@_model/interfaces/stratalot-association.model';
import { StratalotAssociationApiService } from '@_services/api/stratalot-association-api.service';
import * as stratalotAssociationActions from '@_store/stratalot-association/stratalot-association.actions';
import { getActionsToNormalizeStratalotAssociation } from '@_config/store/normalization.generated';
import { selectStratalotAssociationState } from './stratalot-association-generated.selectors';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import * as associationActions from '@_store/association/association.actions';

export interface StratalotAssociationRelationsIds {
  stratalot?: number;
  association?: number;
}

export function getDefaultAddStratalotAssociationActions(
  stratalotAssociation: StratalotAssociationEntityState,
  ids?: StratalotAssociationRelationsIds
): Action[] {
  const actions: Action[] = [
    stratalotAssociationActions.normalizeManyStratalotAssociationsAfterUpsert({
      stratalotAssociations: [stratalotAssociation]
    })
  ];

  if (ids?.stratalot) {
    actions.push(
      stratalotActions.addManyStratalotAssociationSuccess({
        idStratalot: ids.stratalot,
        idStratalotAssociations: [stratalotAssociation.idStratalotAssociation]
      })
    );
    actions.push(
      stratalotAssociationActions.addStratalotSuccess({
        idStratalotAssociation: stratalotAssociation.idStratalotAssociation,
        idStratalot: ids.stratalot
      })
    );
  }

  if (ids?.association) {
    actions.push(
      associationActions.addManyStratalotAssociationSuccess({
        idAssociation: ids.association,
        idStratalotAssociations: [stratalotAssociation.idStratalotAssociation]
      })
    );
    actions.push(
      stratalotAssociationActions.addAssociationSuccess({
        idStratalotAssociation: stratalotAssociation.idStratalotAssociation,
        idAssociation: ids.association
      })
    );
  }

  return actions;
}

export function getDefaultDeleteStratalotAssociationActions(
  stratalotAssociation: StratalotAssociationEntityState
): Action[] {
  const actions: Action[] = [
    stratalotAssociationActions.deleteOneStratalotAssociationSuccess({
      idStratalotAssociation: stratalotAssociation.idStratalotAssociation
    })
  ];

  if (stratalotAssociation.stratalot) {
    actions.push(
      stratalotActions.deleteManyStratalotAssociationSuccess({
        idStratalotAssociations: [stratalotAssociation.idStratalotAssociation],
        idStratalots: [stratalotAssociation.stratalot as number]
      })
    );
  }

  if (stratalotAssociation.association) {
    actions.push(
      associationActions.deleteManyStratalotAssociationSuccess({
        idStratalotAssociations: [stratalotAssociation.idStratalotAssociation],
        idAssociations: [stratalotAssociation.association as number]
      })
    );
  }

  return actions;
}

export class GeneratedStratalotAssociationEffects {
  constructor(
    protected actions$: Actions,
    protected stratalotAssociationApiService: StratalotAssociationApiService,
    protected store$: Store<AppState>
  ) {}

  getManyStratalotAssociations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotAssociationActions.getManyStratalotAssociations),
      switchMap(({ params }) =>
        this.stratalotAssociationApiService.getStratalotAssociations(params).pipe(
          map((stratalotAssociations: StratalotAssociation[]) => {
            return stratalotAssociationActions.normalizeManyStratalotAssociationsAfterUpsert({ stratalotAssociations });
          }),
          catchError(error => of(stratalotAssociationActions.stratalotAssociationsFailure({ error })))
        )
      )
    );
  });

  getOneStratalotAssociation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotAssociationActions.getOneStratalotAssociation),
      switchMap(idStratalotAssociation =>
        this.stratalotAssociationApiService.getStratalotAssociation(idStratalotAssociation).pipe(
          map((stratalotAssociation: StratalotAssociation) => {
            return stratalotAssociationActions.normalizeManyStratalotAssociationsAfterUpsert({
              stratalotAssociations: [stratalotAssociation]
            });
          }),
          catchError(error => of(stratalotAssociationActions.stratalotAssociationsFailure({ error })))
        )
      )
    );
  });

  upsertOneStratalotAssociation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotAssociationActions.upsertOneStratalotAssociation),
      concatMap(
        ({
          stratalotAssociation,
          ids
        }: {
          stratalotAssociation: Partial<StratalotAssociation>;
          ids?: StratalotAssociationRelationsIds;
        }) => {
          if (stratalotAssociation.idStratalotAssociation) {
            return this.stratalotAssociationApiService.updateStratalotAssociation(stratalotAssociation).pipe(
              map((stratalotAssociationReturned: StratalotAssociation) => {
                return stratalotAssociationActions.normalizeManyStratalotAssociationsAfterUpsert({
                  stratalotAssociations: [stratalotAssociationReturned]
                });
              }),
              catchError(error => of(stratalotAssociationActions.stratalotAssociationsFailure({ error })))
            );
          } else {
            return this.stratalotAssociationApiService.addStratalotAssociation(stratalotAssociation).pipe(
              mergeMap((stratalotAssociationReturned: StratalotAssociation) =>
                getDefaultAddStratalotAssociationActions(stratalotAssociationReturned, ids)
              ),
              catchError(error => of(stratalotAssociationActions.stratalotAssociationsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneStratalotAssociation$ = createEffect(() => {
    const selectStratalotAssociationState$ = this.store$.select(selectStratalotAssociationState);
    return this.actions$.pipe(
      ofType(stratalotAssociationActions.deleteOneStratalotAssociation),
      withLatestFrom(selectStratalotAssociationState$),
      concatMap(([{ idStratalotAssociation }, state]) =>
        this.stratalotAssociationApiService.deleteStratalotAssociation(idStratalotAssociation).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteStratalotAssociationActions(
                state.entities[idStratalotAssociation] as StratalotAssociationEntityState
              ),
              stratalotAssociationActions.deleteOneStratalotAssociation.type
            )
          ]),
          catchError(error => of(stratalotAssociationActions.stratalotAssociationsFailure({ error })))
        )
      )
    );
  });

  normalizeManyStratalotAssociationsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotAssociationActions.normalizeManyStratalotAssociationsAfterUpsert),
      concatMap(({ stratalotAssociations }) => {
        const actions: Action[] = getActionsToNormalizeStratalotAssociation(
          stratalotAssociations,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[StratalotAssociation] Normalization After Upsert Success')];
      })
    );
  });
}
