export enum LeadAvancementEnum {
  pasDate = 'Pas de dates renseignés', //

  premierContact = 'Premier contact', //
  depotOffre = "Dépôt d'offre", //

  offreSoumiseValidation = 'Offre soumise à validation', //
  offreAcceptee = 'Offre acceptée', //
  offreExamineeNonRetenue = 'Offre examinée non retenue', //

  compromisPlanifie = 'Offre acceptée - Compromis planifié',
  compromisSigne = 'Compromis', //
  acteAncienPlanifie = 'Compromis - Vente planifiée', //

  reservationPlanifiee = 'Offre acceptée - Réservation planifiée', //
  reservation = 'Réservation', //
  contratLocationAccessionPlanifie = 'Réservation - Contrat location accession planifié',
  contratLocationAccession = 'Contrat location accession', //
  leveeOptionPlanifiee = 'Contrat location accession - Levée option planifiée',
  leveeOption = 'Levée option', //
  actePslaPlanifie = 'Levée option - Vente planifiée', //
  acteVefaPlanifie = 'Réservation - Vente planifiée', //

  nonLeveeOptionPlanifiee = 'Non levée option planifiée', //
  nonLeveeOption = 'Non levée option',
  resiliationPlanifiee = 'Non levée option - Résiliation planifiée', //
  resiliation = 'Résiliation',

  acteSigne = 'Vente à clôturer', //

  venteArchivee = 'Vente archivée' //
}
