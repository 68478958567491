import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationSocieteApiService } from '@_services/api/organization-societe-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedOrganizationSocieteEffects } from './organization-societe-generated.effects';

@Injectable()
export class OrganizationSocieteEffects extends GeneratedOrganizationSocieteEffects {
  constructor(
    actions$: Actions,
    organizationSocieteApiService: OrganizationSocieteApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationSocieteApiService, store$);
  }
}
