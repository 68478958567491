import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  GeneratedDocumentsCompany,
  GeneratedDocumentsCompanyEntityState
} from '@_model/interfaces/generated-documents-company.model';
import { GeneratedDocumentsCompanyApiService } from '@_services/api/generated-documents-company-api.service';
import * as generatedDocumentsCompanyActions from '@_store/generated-documents-company/generated-documents-company.actions';
import { getActionsToNormalizeGeneratedDocumentsCompany } from '@_config/store/normalization.generated';
import { selectGeneratedDocumentsCompanyState } from './generated-documents-company-generated.selectors';
import * as companyActions from '@_store/company/company.actions';
import * as generatedDocumentActions from '@_store/generated-document/generated-document.actions';

export interface GeneratedDocumentsCompanyRelationsIds {
  company?: number;
  generatedDocument?: number;
}

export function getDefaultAddGeneratedDocumentsCompanyActions(
  generatedDocumentsCompany: GeneratedDocumentsCompanyEntityState,
  ids?: GeneratedDocumentsCompanyRelationsIds
): Action[] {
  const actions: Action[] = [
    generatedDocumentsCompanyActions.normalizeManyGeneratedDocumentsCompaniesAfterUpsert({
      generatedDocumentsCompanies: [generatedDocumentsCompany]
    })
  ];

  if (ids?.company) {
    actions.push(
      companyActions.addManyGeneratedDocumentsCompanySuccess({
        idCompany: ids.company,
        idGeneratedDocumentsCompanies: [generatedDocumentsCompany.idGeneratedDocumentsCompany]
      })
    );
    actions.push(
      generatedDocumentsCompanyActions.addCompanySuccess({
        idGeneratedDocumentsCompany: generatedDocumentsCompany.idGeneratedDocumentsCompany,
        idCompany: ids.company
      })
    );
  }

  if (ids?.generatedDocument) {
    actions.push(
      generatedDocumentActions.addManyGeneratedDocumentsCompanySuccess({
        idGeneratedDocument: ids.generatedDocument,
        idGeneratedDocumentsCompanies: [generatedDocumentsCompany.idGeneratedDocumentsCompany]
      })
    );
    actions.push(
      generatedDocumentsCompanyActions.addGeneratedDocumentSuccess({
        idGeneratedDocumentsCompany: generatedDocumentsCompany.idGeneratedDocumentsCompany,
        idGeneratedDocument: ids.generatedDocument
      })
    );
  }

  return actions;
}

export function getDefaultDeleteGeneratedDocumentsCompanyActions(
  generatedDocumentsCompany: GeneratedDocumentsCompanyEntityState
): Action[] {
  const actions: Action[] = [
    generatedDocumentsCompanyActions.deleteOneGeneratedDocumentsCompanySuccess({
      idGeneratedDocumentsCompany: generatedDocumentsCompany.idGeneratedDocumentsCompany
    })
  ];

  if (generatedDocumentsCompany.company) {
    actions.push(
      companyActions.deleteManyGeneratedDocumentsCompanySuccess({
        idGeneratedDocumentsCompanies: [generatedDocumentsCompany.idGeneratedDocumentsCompany],
        idCompanies: [generatedDocumentsCompany.company as number]
      })
    );
  }

  if (generatedDocumentsCompany.generatedDocument) {
    actions.push(
      generatedDocumentActions.deleteManyGeneratedDocumentsCompanySuccess({
        idGeneratedDocumentsCompanies: [generatedDocumentsCompany.idGeneratedDocumentsCompany],
        idGeneratedDocuments: [generatedDocumentsCompany.generatedDocument as number]
      })
    );
  }

  return actions;
}

export class GeneratedGeneratedDocumentsCompanyEffects {
  constructor(
    protected actions$: Actions,
    protected generatedDocumentsCompanyApiService: GeneratedDocumentsCompanyApiService,
    protected store$: Store<AppState>
  ) {}

  getManyGeneratedDocumentsCompanies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(generatedDocumentsCompanyActions.getManyGeneratedDocumentsCompanies),
      switchMap(({ params }) =>
        this.generatedDocumentsCompanyApiService.getGeneratedDocumentsCompanies(params).pipe(
          map((generatedDocumentsCompanies: GeneratedDocumentsCompany[]) => {
            return generatedDocumentsCompanyActions.normalizeManyGeneratedDocumentsCompaniesAfterUpsert({
              generatedDocumentsCompanies
            });
          }),
          catchError(error => of(generatedDocumentsCompanyActions.generatedDocumentsCompaniesFailure({ error })))
        )
      )
    );
  });

  getOneGeneratedDocumentsCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(generatedDocumentsCompanyActions.getOneGeneratedDocumentsCompany),
      switchMap(idGeneratedDocumentsCompany =>
        this.generatedDocumentsCompanyApiService.getGeneratedDocumentsCompany(idGeneratedDocumentsCompany).pipe(
          map((generatedDocumentsCompany: GeneratedDocumentsCompany) => {
            return generatedDocumentsCompanyActions.normalizeManyGeneratedDocumentsCompaniesAfterUpsert({
              generatedDocumentsCompanies: [generatedDocumentsCompany]
            });
          }),
          catchError(error => of(generatedDocumentsCompanyActions.generatedDocumentsCompaniesFailure({ error })))
        )
      )
    );
  });

  upsertOneGeneratedDocumentsCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(generatedDocumentsCompanyActions.upsertOneGeneratedDocumentsCompany),
      concatMap(
        ({
          generatedDocumentsCompany,
          ids
        }: {
          generatedDocumentsCompany: Partial<GeneratedDocumentsCompany>;
          ids?: GeneratedDocumentsCompanyRelationsIds;
        }) => {
          if (generatedDocumentsCompany.idGeneratedDocumentsCompany) {
            return this.generatedDocumentsCompanyApiService
              .updateGeneratedDocumentsCompany(generatedDocumentsCompany)
              .pipe(
                map((generatedDocumentsCompanyReturned: GeneratedDocumentsCompany) => {
                  return generatedDocumentsCompanyActions.normalizeManyGeneratedDocumentsCompaniesAfterUpsert({
                    generatedDocumentsCompanies: [generatedDocumentsCompanyReturned]
                  });
                }),
                catchError(error => of(generatedDocumentsCompanyActions.generatedDocumentsCompaniesFailure({ error })))
              );
          } else {
            return this.generatedDocumentsCompanyApiService
              .addGeneratedDocumentsCompany(generatedDocumentsCompany)
              .pipe(
                mergeMap((generatedDocumentsCompanyReturned: GeneratedDocumentsCompany) =>
                  getDefaultAddGeneratedDocumentsCompanyActions(generatedDocumentsCompanyReturned, ids)
                ),
                catchError(error => of(generatedDocumentsCompanyActions.generatedDocumentsCompaniesFailure({ error })))
              );
          }
        }
      )
    );
  });

  deleteOneGeneratedDocumentsCompany$ = createEffect(() => {
    const selectGeneratedDocumentsCompanyState$ = this.store$.select(selectGeneratedDocumentsCompanyState);
    return this.actions$.pipe(
      ofType(generatedDocumentsCompanyActions.deleteOneGeneratedDocumentsCompany),
      withLatestFrom(selectGeneratedDocumentsCompanyState$),
      concatMap(([{ idGeneratedDocumentsCompany }, state]) =>
        this.generatedDocumentsCompanyApiService.deleteGeneratedDocumentsCompany(idGeneratedDocumentsCompany).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteGeneratedDocumentsCompanyActions(
                state.entities[idGeneratedDocumentsCompany] as GeneratedDocumentsCompanyEntityState
              ),
              generatedDocumentsCompanyActions.deleteOneGeneratedDocumentsCompany.type
            )
          ]),
          catchError(error => of(generatedDocumentsCompanyActions.generatedDocumentsCompaniesFailure({ error })))
        )
      )
    );
  });

  normalizeManyGeneratedDocumentsCompaniesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(generatedDocumentsCompanyActions.normalizeManyGeneratedDocumentsCompaniesAfterUpsert),
      concatMap(({ generatedDocumentsCompanies }) => {
        const actions: Action[] = getActionsToNormalizeGeneratedDocumentsCompany(
          generatedDocumentsCompanies,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[GeneratedDocumentsCompany] Normalization After Upsert Success')];
      })
    );
  });
}
