import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { ProspectBuyingWish, ProspectBuyingWishEntityState } from '@_model/interfaces/prospect-buying-wish.model';
import { ProspectBuyingWishApiService } from '@_services/api/prospect-buying-wish-api.service';
import * as prospectBuyingWishActions from '@_store/prospect-buying-wish/prospect-buying-wish.actions';
import { getActionsToNormalizeProspectBuyingWish } from '@_config/store/normalization.generated';
import { selectProspectBuyingWishState } from './prospect-buying-wish-generated.selectors';
import * as prospectActions from '@_store/prospect/prospect.actions';
import * as organizationBuyingWishActions from '@_store/organization-buying-wish/organization-buying-wish.actions';
import * as cityActions from '@_store/city/city.actions';
import { City } from '@_model/interfaces/city.model';
import * as residenceActions from '@_store/residence/residence.actions';
import { Residence } from '@_model/interfaces/residence.model';
import * as stratalotTypeActions from '@_store/stratalot-type/stratalot-type.actions';
import { StratalotType } from '@_model/interfaces/stratalot-type.model';

export interface ProspectBuyingWishRelationsIds {
  cities?: number | number[];
  residences?: number | number[];
  stratalotTypes?: number | number[];
  prospect?: number;
  organizationBuyingWish?: number;
}

export function getDefaultAddProspectBuyingWishActions(
  prospectBuyingWish: ProspectBuyingWishEntityState,
  ids?: ProspectBuyingWishRelationsIds
): Action[] {
  const actions: Action[] = [
    prospectBuyingWishActions.normalizeManyProspectBuyingWishsAfterUpsert({ prospectBuyingWishs: [prospectBuyingWish] })
  ];

  if (ids?.prospect) {
    actions.push(
      prospectActions.addManyProspectBuyingWishSuccess({
        idProspect: ids.prospect,
        idProspectBuyingWishs: [prospectBuyingWish.idProspectBuyingWish]
      })
    );
    actions.push(
      prospectBuyingWishActions.addProspectSuccess({
        idProspectBuyingWish: prospectBuyingWish.idProspectBuyingWish,
        idProspect: ids.prospect
      })
    );
  }

  if (ids?.organizationBuyingWish) {
    actions.push(
      organizationBuyingWishActions.addManyProspectBuyingWishSuccess({
        idOrganizationBuyingWish: ids.organizationBuyingWish,
        idProspectBuyingWishs: [prospectBuyingWish.idProspectBuyingWish]
      })
    );
    actions.push(
      prospectBuyingWishActions.addOrganizationBuyingWishSuccess({
        idProspectBuyingWish: prospectBuyingWish.idProspectBuyingWish,
        idOrganizationBuyingWish: ids.organizationBuyingWish
      })
    );
  }

  if (ids?.cities) {
    if (!Array.isArray(ids.cities)) {
      actions.push(
        cityActions.upsertOneCity({
          city: {
            idProspectBuyingWish: prospectBuyingWish.idProspectBuyingWish,
            idCity: ids.cities as number
          } as City & any
        })
      );
      actions.push(
        prospectBuyingWishActions.addManyCitySuccess({
          idProspectBuyingWish: prospectBuyingWish.idProspectBuyingWish,
          idCities: [ids.cities as number]
        })
      );
    } else {
      actions.push(
        cityActions.upsertManyCities({
          cities: (ids.cities as number[]).map((idCity: number) => ({
            idProspectBuyingWish: prospectBuyingWish.idProspectBuyingWish,
            idCity
          })) as City[] & any[]
        })
      );
      actions.push(
        prospectBuyingWishActions.addManyCitySuccess({
          idProspectBuyingWish: prospectBuyingWish.idProspectBuyingWish,
          idCities: ids.cities as number[]
        })
      );
    }
  }

  if (ids?.residences) {
    if (!Array.isArray(ids.residences)) {
      actions.push(
        residenceActions.upsertOneResidence({
          residence: {
            idProspectBuyingWish: prospectBuyingWish.idProspectBuyingWish,
            idResidence: ids.residences as number
          } as Residence & any
        })
      );
      actions.push(
        prospectBuyingWishActions.addManyResidenceSuccess({
          idProspectBuyingWish: prospectBuyingWish.idProspectBuyingWish,
          idResidences: [ids.residences as number]
        })
      );
    } else {
      actions.push(
        residenceActions.upsertManyResidences({
          residences: (ids.residences as number[]).map((idResidence: number) => ({
            idProspectBuyingWish: prospectBuyingWish.idProspectBuyingWish,
            idResidence
          })) as Residence[] & any[]
        })
      );
      actions.push(
        prospectBuyingWishActions.addManyResidenceSuccess({
          idProspectBuyingWish: prospectBuyingWish.idProspectBuyingWish,
          idResidences: ids.residences as number[]
        })
      );
    }
  }

  if (ids?.stratalotTypes) {
    if (!Array.isArray(ids.stratalotTypes)) {
      actions.push(
        stratalotTypeActions.upsertOneStratalotType({
          stratalotType: {
            idProspectBuyingWish: prospectBuyingWish.idProspectBuyingWish,
            idStratalotType: ids.stratalotTypes as number
          } as StratalotType & any
        })
      );
      actions.push(
        prospectBuyingWishActions.addManyStratalotTypeSuccess({
          idProspectBuyingWish: prospectBuyingWish.idProspectBuyingWish,
          idStratalotTypes: [ids.stratalotTypes as number]
        })
      );
    } else {
      actions.push(
        stratalotTypeActions.upsertManyStratalotTypes({
          stratalotTypes: (ids.stratalotTypes as number[]).map((idStratalotType: number) => ({
            idProspectBuyingWish: prospectBuyingWish.idProspectBuyingWish,
            idStratalotType
          })) as StratalotType[] & any[]
        })
      );
      actions.push(
        prospectBuyingWishActions.addManyStratalotTypeSuccess({
          idProspectBuyingWish: prospectBuyingWish.idProspectBuyingWish,
          idStratalotTypes: ids.stratalotTypes as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteProspectBuyingWishActions(prospectBuyingWish: ProspectBuyingWishEntityState): Action[] {
  const actions: Action[] = [
    prospectBuyingWishActions.deleteOneProspectBuyingWishSuccess({
      idProspectBuyingWish: prospectBuyingWish.idProspectBuyingWish
    })
  ];

  if (prospectBuyingWish.prospect) {
    actions.push(
      prospectActions.deleteManyProspectBuyingWishSuccess({
        idProspectBuyingWishs: [prospectBuyingWish.idProspectBuyingWish],
        idProspects: [prospectBuyingWish.prospect as number]
      })
    );
  }

  if (prospectBuyingWish.organizationBuyingWish) {
    actions.push(
      organizationBuyingWishActions.deleteManyProspectBuyingWishSuccess({
        idProspectBuyingWishs: [prospectBuyingWish.idProspectBuyingWish],
        idOrganizationBuyingWishes: [prospectBuyingWish.organizationBuyingWish as number]
      })
    );
  }

  if (prospectBuyingWish.cities) {
    actions.push(
      cityActions.deleteManyProspectBuyingWishSuccess({
        idProspectBuyingWishs: [prospectBuyingWish.idProspectBuyingWish],
        idCities: prospectBuyingWish.cities as number[]
      })
    );
  }

  if (prospectBuyingWish.residences) {
    actions.push(
      residenceActions.deleteManyProspectBuyingWishSuccess({
        idProspectBuyingWishs: [prospectBuyingWish.idProspectBuyingWish],
        idResidences: prospectBuyingWish.residences as number[]
      })
    );
  }

  if (prospectBuyingWish.stratalotTypes) {
    actions.push(
      stratalotTypeActions.deleteManyProspectBuyingWishSuccess({
        idProspectBuyingWishs: [prospectBuyingWish.idProspectBuyingWish],
        idStratalotTypes: prospectBuyingWish.stratalotTypes as number[]
      })
    );
  }

  return actions;
}

export class GeneratedProspectBuyingWishEffects {
  constructor(
    protected actions$: Actions,
    protected prospectBuyingWishApiService: ProspectBuyingWishApiService,
    protected store$: Store<AppState>
  ) {}

  getManyProspectBuyingWishs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(prospectBuyingWishActions.getManyProspectBuyingWishs),
      switchMap(({ params }) =>
        this.prospectBuyingWishApiService.getProspectBuyingWishs(params).pipe(
          map((prospectBuyingWishs: ProspectBuyingWish[]) => {
            return prospectBuyingWishActions.normalizeManyProspectBuyingWishsAfterUpsert({ prospectBuyingWishs });
          }),
          catchError(error => of(prospectBuyingWishActions.prospectBuyingWishsFailure({ error })))
        )
      )
    );
  });

  getOneProspectBuyingWish$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(prospectBuyingWishActions.getOneProspectBuyingWish),
      switchMap(idProspectBuyingWish =>
        this.prospectBuyingWishApiService.getProspectBuyingWish(idProspectBuyingWish).pipe(
          map((prospectBuyingWish: ProspectBuyingWish) => {
            return prospectBuyingWishActions.normalizeManyProspectBuyingWishsAfterUpsert({
              prospectBuyingWishs: [prospectBuyingWish]
            });
          }),
          catchError(error => of(prospectBuyingWishActions.prospectBuyingWishsFailure({ error })))
        )
      )
    );
  });

  upsertOneProspectBuyingWish$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(prospectBuyingWishActions.upsertOneProspectBuyingWish),
      concatMap(
        ({
          prospectBuyingWish,
          ids
        }: {
          prospectBuyingWish: Partial<ProspectBuyingWish>;
          ids?: ProspectBuyingWishRelationsIds;
        }) => {
          if (prospectBuyingWish.idProspectBuyingWish) {
            return this.prospectBuyingWishApiService.updateProspectBuyingWish(prospectBuyingWish).pipe(
              map((prospectBuyingWishReturned: ProspectBuyingWish) => {
                return prospectBuyingWishActions.normalizeManyProspectBuyingWishsAfterUpsert({
                  prospectBuyingWishs: [prospectBuyingWishReturned]
                });
              }),
              catchError(error => of(prospectBuyingWishActions.prospectBuyingWishsFailure({ error })))
            );
          } else {
            return this.prospectBuyingWishApiService.addProspectBuyingWish(prospectBuyingWish).pipe(
              mergeMap((prospectBuyingWishReturned: ProspectBuyingWish) =>
                getDefaultAddProspectBuyingWishActions(prospectBuyingWishReturned, ids)
              ),
              catchError(error => of(prospectBuyingWishActions.prospectBuyingWishsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneProspectBuyingWish$ = createEffect(() => {
    const selectProspectBuyingWishState$ = this.store$.select(selectProspectBuyingWishState);
    return this.actions$.pipe(
      ofType(prospectBuyingWishActions.deleteOneProspectBuyingWish),
      withLatestFrom(selectProspectBuyingWishState$),
      concatMap(([{ idProspectBuyingWish }, state]) =>
        this.prospectBuyingWishApiService.deleteProspectBuyingWish(idProspectBuyingWish).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteProspectBuyingWishActions(
                state.entities[idProspectBuyingWish] as ProspectBuyingWishEntityState
              ),
              prospectBuyingWishActions.deleteOneProspectBuyingWish.type
            )
          ]),
          catchError(error => of(prospectBuyingWishActions.prospectBuyingWishsFailure({ error })))
        )
      )
    );
  });

  normalizeManyProspectBuyingWishsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(prospectBuyingWishActions.normalizeManyProspectBuyingWishsAfterUpsert),
      concatMap(({ prospectBuyingWishs }) => {
        const actions: Action[] = getActionsToNormalizeProspectBuyingWish(prospectBuyingWishs, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProspectBuyingWish] Normalization After Upsert Success')];
      })
    );
  });
}
