import { OrganizationProspectOriginState, initialState } from './organization-prospect-origin.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationProspectOriginReducersGeneratedFunctions } from './organization-prospect-origin-generated.reducer';

const organizationProspectOriginReducersFunctions = [...organizationProspectOriginReducersGeneratedFunctions];

const organizationProspectOriginReducer = createReducer(initialState, ...organizationProspectOriginReducersFunctions);

export function reducer(state: OrganizationProspectOriginState | undefined, action: Action) {
  return organizationProspectOriginReducer(state, action);
}
