import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedOrganizationProfilApiService } from './organization-profil-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationProfilApiService extends GeneratedOrganizationProfilApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
