import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as LeadStratalotActions from './lead-stratalot.actions';
import { adapter, initialState, LeadStratalotState } from './lead-stratalot.state';
import { Dictionary } from '@ngrx/entity';
import { LeadStratalotEntityState } from '@_model/interfaces/lead-stratalot.model';

export const leadStratalotReducersGeneratedFunctions: ReducerTypes<LeadStratalotState, readonly ActionCreator[]>[] = [
  on(LeadStratalotActions.getOneLeadStratalot, (state: LeadStratalotState) => setLoadingsState(state, true)),
  on(LeadStratalotActions.getManyLeadStratalots, (state: LeadStratalotState) => setLoadingsState(state, true)),
  on(LeadStratalotActions.upsertOneLeadStratalot, (state: LeadStratalotState) => setLoadingsState(state, true)),

  on(LeadStratalotActions.upsertManyLeadStratalotsSuccess, (state: LeadStratalotState, { leadStratalots }) =>
    adapter.upsertMany(leadStratalots, setLoadingsState(state, false))
  ),
  on(LeadStratalotActions.deleteOneLeadStratalot, (state: LeadStratalotState) => setLoadingsState(state, true)),
  on(LeadStratalotActions.deleteOneLeadStratalotSuccess, (state: LeadStratalotState, { idLeadStratalot }) =>
    adapter.removeOne(idLeadStratalot, setLoadingsState(state, false))
  ),
  on(LeadStratalotActions.clearActive, (state: LeadStratalotState) => ({ ...state, actives: [] })),
  on(LeadStratalotActions.addManyActives, (state: LeadStratalotState, { idLeadStratalots }) => ({
    ...state,
    actives: state.actives.concat(idLeadStratalots)
  })),
  on(LeadStratalotActions.deleteOneActive, (state: LeadStratalotState, { idLeadStratalot }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idLeadStratalot)
  })),

  on(LeadStratalotActions.clearStore, () => initialState),

  on(LeadStratalotActions.addLeadSuccess, (state: LeadStratalotState, { idLeadStratalot, idLead }) => {
    if (!state.entities[idLeadStratalot]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idLeadStratalot]: {
          ...state.entities[idLeadStratalot],
          lead: idLead
        }
      }
    };
  }),

  on(LeadStratalotActions.deleteManyLeadSuccess, (state: LeadStratalotState, { idLeads, idLeadStratalots }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idLeadStratalots.reduce((entities, idLeadStratalot) => {
          if (!state.entities[idLeadStratalot]?.lead) {
            return entities;
          }
          entities[idLeadStratalot] = {
            ...state.entities[idLeadStratalot],
            lead: idLeads.some((idLead: number) => idLead === state.entities[idLeadStratalot]?.lead)
              ? undefined
              : state.entities[idLeadStratalot]?.lead
          } as LeadStratalotEntityState;
          return entities;
        }, {} as Dictionary<LeadStratalotEntityState>)
      }
    };
  }),

  on(LeadStratalotActions.addStratalotSuccess, (state: LeadStratalotState, { idLeadStratalot, idStratalot }) => {
    if (!state.entities[idLeadStratalot]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idLeadStratalot]: {
          ...state.entities[idLeadStratalot],
          stratalot: idStratalot
        }
      }
    };
  }),

  on(
    LeadStratalotActions.deleteManyStratalotSuccess,
    (state: LeadStratalotState, { idStratalots, idLeadStratalots }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idLeadStratalots.reduce((entities, idLeadStratalot) => {
            if (!state.entities[idLeadStratalot]?.stratalot) {
              return entities;
            }
            entities[idLeadStratalot] = {
              ...state.entities[idLeadStratalot],
              stratalot: idStratalots.some(
                (idStratalot: number) => idStratalot === state.entities[idLeadStratalot]?.stratalot
              )
                ? undefined
                : state.entities[idLeadStratalot]?.stratalot
            } as LeadStratalotEntityState;
            return entities;
          }, {} as Dictionary<LeadStratalotEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: LeadStratalotState,
  isLoading: boolean,
  isLoaded: boolean = true
): LeadStratalotState {
  return { ...state, isLoaded, isLoading };
}
