import { ForecastPeriodState, initialState } from './forecast-period.state';
import { Action, createReducer } from '@ngrx/store';
import { forecastPeriodReducersGeneratedFunctions } from './forecast-period-generated.reducer';

const forecastPeriodReducersFunctions = [...forecastPeriodReducersGeneratedFunctions];

const forecastPeriodReducer = createReducer(initialState, ...forecastPeriodReducersFunctions);

export function reducer(state: ForecastPeriodState | undefined, action: Action) {
  return forecastPeriodReducer(state, action);
}
