import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StratalotCampaign, StratalotCampaignEntityState } from '@_model/interfaces/stratalot-campaign.model';
import { CampaignMedia, CampaignMediaEntityState } from '@_model/interfaces/campaign-media.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, stratalotCampaignFeatureKey, StratalotCampaignState } from './stratalot-campaign.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const stratalotCampaignRelations: string[] = ['campaignMedias', 'stratalots'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectStratalotCampaignState = createFeatureSelector<StratalotCampaignState>(stratalotCampaignFeatureKey);

export const selectIsLoadedStratalotCampaign = createSelector(
  selectStratalotCampaignState,
  (state: StratalotCampaignState) => state.isLoaded
);

export const selectIsLoadingStratalotCampaign = createSelector(
  selectStratalotCampaignState,
  (state: StratalotCampaignState) => state.isLoading
);

export const selectIsReadyStratalotCampaign = createSelector(
  selectStratalotCampaignState,
  (state: StratalotCampaignState) => !state.isLoading
);

export const selectIsReadyAndLoadedStratalotCampaign = createSelector(
  selectStratalotCampaignState,
  (state: StratalotCampaignState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const StratalotCampaignModel: SelectorModel = {
  name: 'stratalotCampaigns',
  getSelector: selectAllStratalotCampaignsDictionary,
  isReady: selectIsReadyStratalotCampaign
};

export const selectStratalotCampaignsEntities = createSelector(selectStratalotCampaignState, selectEntities);

export const selectStratalotCampaignsArray = createSelector(selectStratalotCampaignState, selectAll);

export const selectIdStratalotCampaignsActive = createSelector(
  selectStratalotCampaignState,
  (state: StratalotCampaignState) => state.actives
);

const stratalotCampaignsInObject = (stratalotCampaigns: Dictionary<StratalotCampaignEntityState>) => ({
  stratalotCampaigns
});

const selectStratalotCampaignsEntitiesDictionary = createSelector(
  selectStratalotCampaignsEntities,
  stratalotCampaignsInObject
);

const selectAllStratalotCampaignsObject = createSelector(selectStratalotCampaignsEntities, stratalotCampaigns => {
  return hydrateAll({ stratalotCampaigns });
});

const selectOneStratalotCampaignDictionary = (idStratalotCampaign: number) =>
  createSelector(selectStratalotCampaignsEntities, stratalotCampaigns => {
    return { stratalotCampaigns: { [idStratalotCampaign]: stratalotCampaigns[idStratalotCampaign] } };
  });

const selectOneStratalotCampaignDictionaryWithoutChild = (idStratalotCampaign: number) =>
  createSelector(selectStratalotCampaignsEntities, stratalotCampaigns => {
    return { stratalotCampaign: stratalotCampaigns[idStratalotCampaign] };
  });

const selectActiveStratalotCampaignsEntities = createSelector(
  selectIdStratalotCampaignsActive,
  selectStratalotCampaignsEntities,
  (actives: number[], stratalotCampaigns: Dictionary<StratalotCampaignEntityState>) =>
    getStratalotCampaignsFromActives(actives, stratalotCampaigns)
);

function getStratalotCampaignsFromActives(
  actives: number[],
  stratalotCampaigns: Dictionary<StratalotCampaignEntityState>
): Dictionary<StratalotCampaignEntityState> {
  return actives.reduce((acc, idActive) => {
    if (stratalotCampaigns[idActive]) {
      acc[idActive] = stratalotCampaigns[idActive];
    }
    return acc;
  }, {} as Dictionary<StratalotCampaignEntityState>);
}

const selectAllStratalotCampaignsSelectors: Dictionary<Selector> = {};
export function selectAllStratalotCampaigns(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<StratalotCampaign>(
      schema,
      selectAllStratalotCampaignsSelectors,
      selectStratalotCampaignsEntitiesDictionary,
      getRelationSelectors,
      stratalotCampaignRelations,
      hydrateAll,
      'stratalotCampaign'
    );
  } else {
    return selectAllStratalotCampaignsObject;
  }
}

export function selectAllStratalotCampaignsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'stratalotCampaigns'
): Selector {
  return createSelector(selectAllStratalotCampaigns(schema), result => {
    const res = { [customKey]: {} as Dictionary<StratalotCampaignEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.stratalotCampaigns.length; i++) {
      res[customKey][result.stratalotCampaigns[i].idStratalotCampaign] = result.stratalotCampaigns[i];
    }
    return res;
  });
}

export function selectOneStratalotCampaign(schema: SelectSchema = {}, idStratalotCampaign: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneStratalotCampaignDictionary(idStratalotCampaign)];
    selectors.push(...getRelationSelectors(schema, stratalotCampaignRelations, 'stratalotCampaign'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneStratalotCampaignDictionaryWithoutChild(idStratalotCampaign);
  }
}

export function selectActiveStratalotCampaigns(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveStratalotCampaignsEntities, stratalotCampaigns => ({ stratalotCampaigns }))
  ];
  selectors.push(...getRelationSelectors(schema, stratalotCampaignRelations, 'stratalotCampaign'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  stratalotCampaigns: Dictionary<StratalotCampaignEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
  campaignMedias?: Dictionary<CampaignMediaEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { stratalotCampaigns: (StratalotCampaign | null)[] } {
  const { stratalotCampaigns, stratalots, campaignMedias } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    stratalotCampaigns: Object.keys(stratalotCampaigns).map(idStratalotCampaign =>
      hydrate(stratalotCampaigns[idStratalotCampaign] as StratalotCampaignEntityState, stratalots, campaignMedias)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { stratalotCampaign: StratalotCampaignEntityState | null } {
  const { stratalotCampaigns, stratalots, campaignMedias } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const stratalotCampaign = Object.values(stratalotCampaigns)[0];
  return { stratalotCampaign: hydrate(stratalotCampaign as StratalotCampaignEntityState, stratalots, campaignMedias) };
}

function hydrate(
  stratalotCampaign: StratalotCampaignEntityState,
  stratalotEntities?: Dictionary<StratalotEntityState>,
  campaignMediaEntities?: Dictionary<CampaignMediaEntityState>
): StratalotCampaign | null {
  if (!stratalotCampaign) {
    return null;
  }

  const stratalotCampaignHydrated: StratalotCampaignEntityState = { ...stratalotCampaign };
  if (stratalotEntities) {
    stratalotCampaignHydrated.stratalot = stratalotEntities[stratalotCampaign.stratalot as number] as Stratalot;
  } else {
    delete stratalotCampaignHydrated.stratalot;
  }

  if (campaignMediaEntities) {
    stratalotCampaignHydrated.campaignMedias = ((stratalotCampaignHydrated.campaignMedias as number[]) || []).map(
      id => campaignMediaEntities[id]
    ) as CampaignMedia[];
  } else {
    delete stratalotCampaignHydrated.campaignMedias;
  }

  return stratalotCampaignHydrated as StratalotCampaign;
}
