import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyCommunicationLeadTodoEntityState } from '@_model/interfaces/company-communication-lead-todo.model';

export interface CompanyCommunicationLeadTodoState extends EntityState<CompanyCommunicationLeadTodoEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyCommunicationLeadTodoEntityState> =
  createEntityAdapter<CompanyCommunicationLeadTodoEntityState>({
    selectId: o => o.idCompanyCommunicationLeadTodo
  });
export const initialState: CompanyCommunicationLeadTodoState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyCommunicationLeadTodoFeatureKey = 'companyCommunicationLeadTodo';
