import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  CompanyCommunicationLeadTodo,
  CompanyCommunicationLeadTodoEntityState
} from '@_model/interfaces/company-communication-lead-todo.model';
import { CompanyCommunicationLeadTodoRelationsIds } from './company-communication-lead-todo-generated.effects';

export const getOneCompanyCommunicationLeadTodo = createAction(
  '[CompanyCommunicationLeadTodo] Get One CompanyCommunicationLeadTodo',
  props<{ idCompanyCommunicationLeadTodo: number; params?: any }>()
);

export const getManyCompanyCommunicationLeadTodos = createAction(
  '[CompanyCommunicationLeadTodo] Get Many CompanyCommunicationLeadTodos',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyCommunicationLeadTodo] Add Many Actives CompanyCommunicationLeadTodo',
  props<{ idCompanyCommunicationLeadTodos: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyCommunicationLeadTodo] Delete One Active CompanyCommunicationLeadTodo',
  props<{ idCompanyCommunicationLeadTodo: number }>()
);

export const clearActive = createAction('[CompanyCommunicationLeadTodo] Clear Active CompanyCommunicationLeadTodo');

export const upsertOneCompanyCommunicationLeadTodo = createAction(
  '[CompanyCommunicationLeadTodo] Upsert One CompanyCommunicationLeadTodo',
  props<{
    companyCommunicationLeadTodo: Partial<CompanyCommunicationLeadTodo>;
    ids?: CompanyCommunicationLeadTodoRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationLeadTodos = createAction(
  '[CompanyCommunicationLeadTodo] Upsert Many CompanyCommunicationLeadTodos',
  props<{
    companyCommunicationLeadTodos: Partial<CompanyCommunicationLeadTodo>[];
    ids?: CompanyCommunicationLeadTodoRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationLeadTodosSuccess = createAction(
  '[CompanyCommunicationLeadTodo] Create Many CompanyCommunicationLeadTodos Success',
  props<{ companyCommunicationLeadTodos: CompanyCommunicationLeadTodoEntityState[] }>()
);

export const deleteOneCompanyCommunicationLeadTodo = createAction(
  '[CompanyCommunicationLeadTodo] Delete One CompanyCommunicationLeadTodo',
  props<{ idCompanyCommunicationLeadTodo: number }>()
);

export const deleteOneCompanyCommunicationLeadTodoSuccess = createAction(
  '[CompanyCommunicationLeadTodo] Delete One CompanyCommunicationLeadTodo Success',
  props<{ idCompanyCommunicationLeadTodo: number }>()
);

export const normalizeManyCompanyCommunicationLeadTodosAfterUpsert = createAction(
  '[CompanyCommunicationLeadTodo] Normalize Many CompanyCommunicationLeadTodos After Upsert',
  props<{ companyCommunicationLeadTodos: CompanyCommunicationLeadTodoEntityState[] }>()
);

export const companyCommunicationLeadTodosFailure = createAction(
  '[CompanyCommunicationLeadTodo] CompanyCommunicationLeadTodos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyCommunicationLeadTodo] Clear CompanyCommunicationLeadTodos');

export const addCompanyCommunicationSuccess = createAction(
  '[CompanyCommunicationLeadTodo] Add CompanyCommunication',
  props<{ idCompanyCommunicationLeadTodo: number; idCompanyCommunication: number }>()
);

export const deleteManyCompanyCommunicationSuccess = createAction(
  '[CompanyCommunicationLeadTodo] Delete Many CompanyCommunication',
  props<{ idCompanyCommunications: number[]; idCompanyCommunicationLeadTodos: number[] }>()
);

export const addOrganizationLeadTodoSuccess = createAction(
  '[CompanyCommunicationLeadTodo] Add OrganizationLeadTodo',
  props<{ idCompanyCommunicationLeadTodo: number; idOrganizationLeadTodo: number }>()
);

export const deleteManyOrganizationLeadTodoSuccess = createAction(
  '[CompanyCommunicationLeadTodo] Delete Many OrganizationLeadTodo',
  props<{ idOrganizationLeadTodos: number[]; idCompanyCommunicationLeadTodos: number[] }>()
);
