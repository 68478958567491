import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyStudyReasonApiService } from '@_services/api/company-study-reason-api.service';
import { GeneratedCompanyStudyReasonEffects } from './company-study-reason-generated.effects';

@Injectable()
export class CompanyStudyReasonEffects extends GeneratedCompanyStudyReasonEffects {
  constructor(actions$: Actions, companyStudyReasonApiService: CompanyStudyReasonApiService, store$: Store<AppState>) {
    super(actions$, companyStudyReasonApiService, store$);
  }
}
