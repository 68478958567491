import { CompanyCommunicationStratalotTodoState, initialState } from './company-communication-stratalot-todo.state';
import { Action, createReducer } from '@ngrx/store';
import { companyCommunicationStratalotTodoReducersGeneratedFunctions } from './company-communication-stratalot-todo-generated.reducer';

const companyCommunicationStratalotTodoReducersFunctions = [
  ...companyCommunicationStratalotTodoReducersGeneratedFunctions
];

const companyCommunicationStratalotTodoReducer = createReducer(
  initialState,
  ...companyCommunicationStratalotTodoReducersFunctions
);

export function reducer(state: CompanyCommunicationStratalotTodoState | undefined, action: Action) {
  return companyCommunicationStratalotTodoReducer(state, action);
}
