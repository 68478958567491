import { Injectable } from '@angular/core';
import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedResidenceWorkApiService } from './residence-work-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ResidenceWorkApiService extends GeneratedResidenceWorkApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
