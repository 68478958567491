import { ResidenceStudyTodoState, initialState } from './residence-study-todo.state';
import { Action, createReducer } from '@ngrx/store';
import { residenceStudyTodoReducersGeneratedFunctions } from './residence-study-todo-generated.reducer';

const residenceStudyTodoReducersFunctions = [...residenceStudyTodoReducersGeneratedFunctions];

const residenceStudyTodoReducer = createReducer(initialState, ...residenceStudyTodoReducersFunctions);

export function reducer(state: ResidenceStudyTodoState | undefined, action: Action) {
  return residenceStudyTodoReducer(state, action);
}
