import { MenuSection } from '@_shared/interfaces/menu-section.interface';
import { ModulePathKeyEnum } from '@_utils/router/path-enum/module-path-key.enum';
import { DroitInterneEnum } from '@_model/enums/droit.enum';

namespace MenuUtils {
  export const LogOutLabel = 'Déconnexion';

  const commonMenu: MenuSection[] = [
    {
      id: '7',
      label: 'Administration',
      dataCy: 'administration',
      icon: 'settings',
      align: 'bottom',
      route: ModulePathKeyEnum.admin,
      materialIcon: false,
      admin: true,
      right: DroitInterneEnum.manageAdmin
    },
    {
      id: '8',
      label: 'User',
      icon: 'profil',
      dataCy: 'profil',
      align: 'bottom',
      opened: false,
      children: [
        {
          id: '8-0',
          label: 'Profil',
          dataCy: 'profil',
          route: ModulePathKeyEnum.user
        },
        {
          id: '8-1',
          label: LogOutLabel,
          dataCy: 'logout',
          route: ModulePathKeyEnum.user
        }
      ]
    },
    {
      id: '9',
      label: "Centre d'aide",
      dataCy: 'aide',
      icon: 'help',
      align: 'bottom',
      url: 'https://upbycellance.tawk.help/',
      materialIcon: false
    }
  ];
  export const existingMenu: MenuSection[] = [
    {
      id: '0',
      label: 'Tableau de bord',
      icon: 'dashboard',
      route: ModulePathKeyEnum.dashboard,
      dataCy: 'dashboard',
      materialIcon: false,
      right: DroitInterneEnum.accessDashboardPage
    },
    {
      id: '1',
      label: 'Plan de vente',
      icon: 'etude',
      dataCy: 'study',
      route: ModulePathKeyEnum.study,
      materialIcon: false,
      right: DroitInterneEnum.accessStudy
    },
    {
      id: '2',
      label: 'Montage',
      icon: 'montage',
      dataCy: 'sale-plan',
      route: ModulePathKeyEnum.salePlan,
      materialIcon: false,
      right: DroitInterneEnum.accessResidenceMontagePage
    },
    {
      id: '3',
      label: 'Résidences en vente',
      icon: 'planDeVente',
      dataCy: 'residence-for-sale',
      route: ModulePathKeyEnum.residenceForSale,
      materialIcon: false,
      right: DroitInterneEnum.accessResidenceVentePage
    },
    {
      id: '4',
      label: 'Commercialisation',
      icon: 'commercialisation',
      dataCy: 'commercialisation',
      opened: false,
      children: [
        {
          id: '4-0',
          label: 'Lots occupés',
          route: ModulePathKeyEnum.occupedStratalots,
          dataCy: 'occuped-stratalots',
          right: DroitInterneEnum.accessOccupiedStratalotPage
        },
        {
          id: '4-1',
          label: 'Lots libres',
          route: ModulePathKeyEnum.freeStratalots,
          dataCy: 'free-stratalots',
          right: DroitInterneEnum.accessFreeStratalotPage
        },
        {
          id: '4-2',
          label: 'Base prospects',
          route: ModulePathKeyEnum.prospects,
          dataCy: 'prospects',
          right: DroitInterneEnum.accessProspectPage
        }
      ]
    },
    {
      id: '5',
      label: 'Offres à valider',
      dataCy: 'validate-offer',
      icon: 'offresAValider',
      route: ModulePathKeyEnum.validateOffer,
      materialIcon: false,
      right: DroitInterneEnum.accessToValidateOfferPage
    },
    {
      id: '6',
      label: 'Ventes en cours',
      dataCy: 'sales-in-progress',
      icon: 'dossierEnCours',
      route: ModulePathKeyEnum.salesInProgress,
      materialIcon: false,
      right: DroitInterneEnum.accessSaleInProgressPage
    },
    ...commonMenu
  ];
  export const newMenu: MenuSection[] = [
    {
      id: '0',
      label: 'Tableau de bord',
      icon: 'dashboard',
      route: ModulePathKeyEnum.dashboard,
      dataCy: 'dashboard',
      materialIcon: false,
      right: DroitInterneEnum.accessDashboardPage
    },
    {
      id: '1',
      label: 'Tous les programmes',
      icon: 'planDeVente',
      route: ModulePathKeyEnum.salePlan,
      dataCy: 'sale-plan',
      materialIcon: false,
      right: DroitInterneEnum.accessResidencePage
    },
    {
      id: '2',
      label: 'Commercialisation',
      icon: 'commercialisation',
      dataCy: 'commercialisation',
      opened: false,
      children: [
        {
          id: '2-1',
          label: 'Programmes',
          route: ModulePathKeyEnum.programCommercialisation,
          dataCy: 'free-stratalots',
          right: DroitInterneEnum.accessFreeStratalotPage
        },
        {
          id: '2-2',
          label: 'Base prospects',
          route: ModulePathKeyEnum.prospects,
          dataCy: 'prospects',
          right: DroitInterneEnum.accessProspectPage
        }
      ]
    },
    {
      id: '3',
      label: 'Options à valider',
      dataCy: 'validate-offer',
      icon: 'offresAValider',
      route: ModulePathKeyEnum.validateOffer,
      materialIcon: false,
      right: DroitInterneEnum.accessToValidateOfferPage
    },
    {
      id: '4',
      label: 'Ventes en cours',
      dataCy: 'sales-in-progress',
      icon: 'dossierEnCours',
      route: ModulePathKeyEnum.salesInProgress,
      materialIcon: false,
      right: DroitInterneEnum.accessSaleInProgressPage
    },
    ...commonMenu
  ];
}
export default MenuUtils;
