import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationStratalotAvancementEntityState } from '@_model/interfaces/organization-stratalot-avancement.model';

export interface OrganizationStratalotAvancementState extends EntityState<OrganizationStratalotAvancementEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationStratalotAvancementEntityState> =
  createEntityAdapter<OrganizationStratalotAvancementEntityState>({
    selectId: o => o.idOrganizationStratalotAvancement
  });
export const initialState: OrganizationStratalotAvancementState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationStratalotAvancementFeatureKey = 'organizationStratalotAvancement';
