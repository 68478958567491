export enum OrganizationLeadTodoType {
  acte = 'Acte authentique signé',
  compromis = 'Compromis',
  offreAcceptee = 'Offre acceptée',
  examenOffres = 'Examen des offres',
  offreSoumiseValidation = 'Offre soumise à validation',
  // eslint-disable-next-line @typescript-eslint/quotes
  depotOffre = "Dépôt d'offre",
  premierContact = '1er contact',
  reservation = 'Réservation',
  contratLocationAccession = 'Contrat location accession',
  leveeOption = 'Levée option',
  nonLeveeOption = 'Non levée option',
  edls = 'Etat des lieux sortie',
  edle = 'Etat des lieux entrée',
  resiliation = 'Résiliation',
  envoiNotaire = 'envoiNotaire',
  envoiSRU = 'envoiSRU'
}
