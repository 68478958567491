import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { CompanyTerritoire, CompanyTerritoireEntityState } from '@_model/interfaces/company-territoire.model';
import { CompanyTerritoireApiService } from '@_services/api/company-territoire-api.service';
import * as companyTerritoireActions from '@_store/company-territoire/company-territoire.actions';
import { getActionsToNormalizeCompanyTerritoire } from '@_config/store/normalization.generated';
import { selectCompanyTerritoireState } from './company-territoire-generated.selectors';
import * as companyActions from '@_store/company/company.actions';
import * as userActions from '@_store/user/user.actions';
import * as companyTerritoireUserActions from '@_store/company-territoire-user/company-territoire-user.actions';
import { CompanyTerritoireUser } from '@_model/interfaces/company-territoire-user.model';
import * as residenceActions from '@_store/residence/residence.actions';
import { Residence } from '@_model/interfaces/residence.model';

export interface CompanyTerritoireRelationsIds {
  companyTerritoireUsers?: number | number[];
  residences?: number | number[];
  company?: number;
  responsable?: number;
}

export function getDefaultAddCompanyTerritoireActions(
  companyTerritoire: CompanyTerritoireEntityState,
  ids?: CompanyTerritoireRelationsIds
): Action[] {
  const actions: Action[] = [
    companyTerritoireActions.normalizeManyCompanyTerritoiresAfterUpsert({ companyTerritoires: [companyTerritoire] })
  ];

  if (ids?.company) {
    actions.push(
      companyActions.addManyCompanyTerritoireSuccess({
        idCompany: ids.company,
        idCompanyTerritoires: [companyTerritoire.idCompanyTerritoire]
      })
    );
    actions.push(
      companyTerritoireActions.addCompanySuccess({
        idCompanyTerritoire: companyTerritoire.idCompanyTerritoire,
        idCompany: ids.company
      })
    );
  }

  if (ids?.responsable) {
    actions.push(
      userActions.addManyCompanyTerritoireSuccess({
        idUser: ids.responsable,
        idCompanyTerritoire: [companyTerritoire.idCompanyTerritoire]
      })
    );
    actions.push(
      companyTerritoireActions.addResponsableSuccess({
        idCompanyTerritoire: companyTerritoire.idCompanyTerritoire,
        idResponsable: ids.responsable
      })
    );
  }

  if (ids?.companyTerritoireUsers) {
    if (!Array.isArray(ids.companyTerritoireUsers)) {
      actions.push(
        companyTerritoireUserActions.upsertOneCompanyTerritoireUser({
          companyTerritoireUser: {
            idCompanyTerritoire: companyTerritoire.idCompanyTerritoire,
            idCompanyTerritoireUser: ids.companyTerritoireUsers as number
          } as CompanyTerritoireUser
        })
      );
      actions.push(
        companyTerritoireActions.addManyCompanyTerritoireUserSuccess({
          idCompanyTerritoire: companyTerritoire.idCompanyTerritoire,
          idCompanyTerritoireUsers: [ids.companyTerritoireUsers as number]
        })
      );
    } else {
      actions.push(
        companyTerritoireUserActions.upsertManyCompanyTerritoireUsers({
          companyTerritoireUsers: (ids.companyTerritoireUsers as number[]).map((idCompanyTerritoireUser: number) => ({
            idCompanyTerritoire: companyTerritoire.idCompanyTerritoire,
            idCompanyTerritoireUser
          })) as CompanyTerritoireUser[]
        })
      );
      actions.push(
        companyTerritoireActions.addManyCompanyTerritoireUserSuccess({
          idCompanyTerritoire: companyTerritoire.idCompanyTerritoire,
          idCompanyTerritoireUsers: ids.companyTerritoireUsers as number[]
        })
      );
    }
  }

  if (ids?.residences) {
    if (!Array.isArray(ids.residences)) {
      actions.push(
        residenceActions.upsertOneResidence({
          residence: {
            idCompanyTerritoire: companyTerritoire.idCompanyTerritoire,
            idResidence: ids.residences as number
          } as Residence & any
        })
      );
      actions.push(
        companyTerritoireActions.addManyResidenceSuccess({
          idCompanyTerritoire: companyTerritoire.idCompanyTerritoire,
          idResidences: [ids.residences as number]
        })
      );
    } else {
      actions.push(
        residenceActions.upsertManyResidences({
          residences: (ids.residences as number[]).map((idResidence: number) => ({
            idCompanyTerritoire: companyTerritoire.idCompanyTerritoire,
            idResidence
          })) as Residence[] & any[]
        })
      );
      actions.push(
        companyTerritoireActions.addManyResidenceSuccess({
          idCompanyTerritoire: companyTerritoire.idCompanyTerritoire,
          idResidences: ids.residences as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCompanyTerritoireActions(companyTerritoire: CompanyTerritoireEntityState): Action[] {
  const actions: Action[] = [
    companyTerritoireActions.deleteOneCompanyTerritoireSuccess({
      idCompanyTerritoire: companyTerritoire.idCompanyTerritoire
    })
  ];

  if (companyTerritoire.company) {
    actions.push(
      companyActions.deleteManyCompanyTerritoireSuccess({
        idCompanyTerritoires: [companyTerritoire.idCompanyTerritoire],
        idCompanies: [companyTerritoire.company as number]
      })
    );
  }

  if (companyTerritoire.responsable) {
    actions.push(
      userActions.deleteManyCompanyTerritoireSuccess({
        idCompanyTerritoire: [companyTerritoire.idCompanyTerritoire],
        idUsers: [companyTerritoire.responsable as number]
      })
    );
  }

  if (companyTerritoire.companyTerritoireUsers) {
    actions.push(
      companyTerritoireUserActions.deleteManyCompanyTerritoireSuccess({
        idCompanyTerritoires: [companyTerritoire.idCompanyTerritoire],
        idCompanyTerritoireUsers: companyTerritoire.companyTerritoireUsers as number[]
      })
    );
  }

  if (companyTerritoire.residences) {
    actions.push(
      residenceActions.deleteManyCompanyTerritoireSuccess({
        idCompanyTerritoires: [companyTerritoire.idCompanyTerritoire],
        idResidences: companyTerritoire.residences as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyTerritoireEffects {
  constructor(
    protected actions$: Actions,
    protected companyTerritoireApiService: CompanyTerritoireApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyTerritoires$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyTerritoireActions.getManyCompanyTerritoires),
      switchMap(({ params }) =>
        this.companyTerritoireApiService.getCompanyTerritoires(params).pipe(
          map((companyTerritoires: CompanyTerritoire[]) => {
            return companyTerritoireActions.normalizeManyCompanyTerritoiresAfterUpsert({ companyTerritoires });
          }),
          catchError(error => of(companyTerritoireActions.companyTerritoiresFailure({ error })))
        )
      )
    );
  });

  getOneCompanyTerritoire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyTerritoireActions.getOneCompanyTerritoire),
      switchMap(idCompanyTerritoire =>
        this.companyTerritoireApiService.getCompanyTerritoire(idCompanyTerritoire).pipe(
          map((companyTerritoire: CompanyTerritoire) => {
            return companyTerritoireActions.normalizeManyCompanyTerritoiresAfterUpsert({
              companyTerritoires: [companyTerritoire]
            });
          }),
          catchError(error => of(companyTerritoireActions.companyTerritoiresFailure({ error })))
        )
      )
    );
  });

  upsertOneCompanyTerritoire$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyTerritoireActions.upsertOneCompanyTerritoire),
      concatMap(
        ({
          companyTerritoire,
          ids
        }: {
          companyTerritoire: Partial<CompanyTerritoire>;
          ids?: CompanyTerritoireRelationsIds;
        }) => {
          if (companyTerritoire.idCompanyTerritoire) {
            return this.companyTerritoireApiService.updateCompanyTerritoire(companyTerritoire).pipe(
              map((companyTerritoireReturned: CompanyTerritoire) => {
                return companyTerritoireActions.normalizeManyCompanyTerritoiresAfterUpsert({
                  companyTerritoires: [companyTerritoireReturned]
                });
              }),
              catchError(error => of(companyTerritoireActions.companyTerritoiresFailure({ error })))
            );
          } else {
            return this.companyTerritoireApiService.addCompanyTerritoire(companyTerritoire).pipe(
              mergeMap((companyTerritoireReturned: CompanyTerritoire) =>
                getDefaultAddCompanyTerritoireActions(companyTerritoireReturned, ids)
              ),
              catchError(error => of(companyTerritoireActions.companyTerritoiresFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCompanyTerritoire$ = createEffect(() => {
    const selectCompanyTerritoireState$ = this.store$.select(selectCompanyTerritoireState);
    return this.actions$.pipe(
      ofType(companyTerritoireActions.deleteOneCompanyTerritoire),
      withLatestFrom(selectCompanyTerritoireState$),
      concatMap(([{ idCompanyTerritoire }, state]) =>
        this.companyTerritoireApiService.deleteCompanyTerritoire(idCompanyTerritoire).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCompanyTerritoireActions(
                state.entities[idCompanyTerritoire] as CompanyTerritoireEntityState
              ),
              companyTerritoireActions.deleteOneCompanyTerritoire.type
            )
          ]),
          catchError(error => of(companyTerritoireActions.companyTerritoiresFailure({ error })))
        )
      )
    );
  });

  normalizeManyCompanyTerritoiresAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyTerritoireActions.normalizeManyCompanyTerritoiresAfterUpsert),
      concatMap(({ companyTerritoires }) => {
        const actions: Action[] = getActionsToNormalizeCompanyTerritoire(companyTerritoires, StoreActionType.upsert);
        return [getMultiAction(actions, '[CompanyTerritoire] Normalization After Upsert Success')];
      })
    );
  });
}
