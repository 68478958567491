import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationLeadAvancementActions from './organization-lead-avancement.actions';
import { adapter, initialState, OrganizationLeadAvancementState } from './organization-lead-avancement.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationLeadAvancementEntityState } from '@_model/interfaces/organization-lead-avancement.model';

export const organizationLeadAvancementReducersGeneratedFunctions: ReducerTypes<
  OrganizationLeadAvancementState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationLeadAvancementActions.getOneOrganizationLeadAvancement, (state: OrganizationLeadAvancementState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationLeadAvancementActions.getManyOrganizationLeadAvancements, (state: OrganizationLeadAvancementState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationLeadAvancementActions.upsertOneOrganizationLeadAvancement, (state: OrganizationLeadAvancementState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationLeadAvancementActions.upsertManyOrganizationLeadAvancementsSuccess,
    (state: OrganizationLeadAvancementState, { organizationLeadAvancements }) =>
      adapter.upsertMany(organizationLeadAvancements, setLoadingsState(state, false))
  ),
  on(OrganizationLeadAvancementActions.deleteOneOrganizationLeadAvancement, (state: OrganizationLeadAvancementState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationLeadAvancementActions.deleteOneOrganizationLeadAvancementSuccess,
    (state: OrganizationLeadAvancementState, { idOrganizationLeadAvancement }) =>
      adapter.removeOne(idOrganizationLeadAvancement, setLoadingsState(state, false))
  ),
  on(OrganizationLeadAvancementActions.clearActive, (state: OrganizationLeadAvancementState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationLeadAvancementActions.addManyActives,
    (state: OrganizationLeadAvancementState, { idOrganizationLeadAvancements }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationLeadAvancements)
    })
  ),
  on(
    OrganizationLeadAvancementActions.deleteOneActive,
    (state: OrganizationLeadAvancementState, { idOrganizationLeadAvancement }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationLeadAvancement)
    })
  ),

  on(OrganizationLeadAvancementActions.clearStore, () => initialState),

  on(
    OrganizationLeadAvancementActions.addManyLeadSuccess,
    (state: OrganizationLeadAvancementState, { idOrganizationLeadAvancement, idLeads }) => {
      if (!state.entities[idOrganizationLeadAvancement]) {
        return state;
      }
      const leads = (state.entities[idOrganizationLeadAvancement]?.leads as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadAvancement]: {
            ...state.entities[idOrganizationLeadAvancement],
            leads: leads.concat(idLeads.filter(id => leads.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadAvancementActions.deleteManyLeadSuccess,
    (state: OrganizationLeadAvancementState, { idLeads, idOrganizationLeadAvancements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadAvancements.reduce((entities, idOrganizationLeadAvancement) => {
            if (!state.entities[idOrganizationLeadAvancement]?.leads) {
              return entities;
            }
            entities[idOrganizationLeadAvancement] = {
              ...state.entities[idOrganizationLeadAvancement],
              leads: (state.entities[idOrganizationLeadAvancement]?.leads as number[])?.filter(
                (idLead: number) => !idLeads.some((id: number) => id === idLead)
              )
            } as OrganizationLeadAvancementEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadAvancementEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadAvancementActions.addStepSuccess,
    (state: OrganizationLeadAvancementState, { idOrganizationLeadAvancement, idStep }) => {
      if (!state.entities[idOrganizationLeadAvancement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadAvancement]: {
            ...state.entities[idOrganizationLeadAvancement],
            step: idStep
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadAvancementActions.deleteManyStepSuccess,
    (state: OrganizationLeadAvancementState, { idSteps, idOrganizationLeadAvancements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadAvancements.reduce((entities, idOrganizationLeadAvancement) => {
            if (!state.entities[idOrganizationLeadAvancement]?.step) {
              return entities;
            }
            entities[idOrganizationLeadAvancement] = {
              ...state.entities[idOrganizationLeadAvancement],
              step: idSteps.some((idStep: number) => idStep === state.entities[idOrganizationLeadAvancement]?.step)
                ? undefined
                : state.entities[idOrganizationLeadAvancement]?.step
            } as OrganizationLeadAvancementEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadAvancementEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadAvancementActions.addOrganizationSuccess,
    (state: OrganizationLeadAvancementState, { idOrganizationLeadAvancement, idOrganization }) => {
      if (!state.entities[idOrganizationLeadAvancement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadAvancement]: {
            ...state.entities[idOrganizationLeadAvancement],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadAvancementActions.deleteManyOrganizationSuccess,
    (state: OrganizationLeadAvancementState, { idOrganizations, idOrganizationLeadAvancements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadAvancements.reduce((entities, idOrganizationLeadAvancement) => {
            if (!state.entities[idOrganizationLeadAvancement]?.organization) {
              return entities;
            }
            entities[idOrganizationLeadAvancement] = {
              ...state.entities[idOrganizationLeadAvancement],
              organization: idOrganizations.some(
                (idOrganization: number) =>
                  idOrganization === state.entities[idOrganizationLeadAvancement]?.organization
              )
                ? undefined
                : state.entities[idOrganizationLeadAvancement]?.organization
            } as OrganizationLeadAvancementEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadAvancementEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationLeadAvancementState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationLeadAvancementState {
  return { ...state, isLoaded, isLoading };
}
