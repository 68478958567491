import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Occupant, OccupantEntityState } from '@_model/interfaces/occupant.model';
import { OccupantRelationsIds } from './occupant-generated.effects';

export const getOneOccupant = createAction(
  '[Occupant] Get One Occupant',
  props<{ idOccupant: number; params?: any }>()
);

export const getManyOccupants = createAction('[Occupant] Get Many Occupants', props<{ params: any }>());

export const addManyActives = createAction('[Occupant] Add Many Actives Occupant', props<{ idOccupants: number[] }>());

export const deleteOneActive = createAction('[Occupant] Delete One Active Occupant', props<{ idOccupant: number }>());

export const clearActive = createAction('[Occupant] Clear Active Occupant');

export const upsertOneOccupant = createAction(
  '[Occupant] Upsert One Occupant',
  props<{
    occupant: Partial<Occupant>;
    ids?: OccupantRelationsIds;
  }>()
);

export const upsertManyOccupants = createAction(
  '[Occupant] Upsert Many Occupants',
  props<{
    occupants: Partial<Occupant>[];
    ids?: OccupantRelationsIds;
  }>()
);

export const upsertManyOccupantsSuccess = createAction(
  '[Occupant] Create Many Occupants Success',
  props<{ occupants: OccupantEntityState[] }>()
);

export const deleteOneOccupant = createAction('[Occupant] Delete One Occupant', props<{ idOccupant: number }>());

export const deleteOneOccupantSuccess = createAction(
  '[Occupant] Delete One Occupant Success',
  props<{ idOccupant: number }>()
);

export const normalizeManyOccupantsAfterUpsert = createAction(
  '[Occupant] Normalize Many Occupants After Upsert',
  props<{ occupants: OccupantEntityState[] }>()
);

export const occupantsFailure = createAction('[Occupant] Occupants Failure', props<{ error: HttpErrorResponse }>());

export const clearStore = createAction('[Occupant] Clear Occupants');

export const addManyStratalotSuccess = createAction(
  '[Occupant] Add Many stratalot',
  props<{ idOccupant: number; idStratalots: number[] }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[Occupant] Delete Many Stratalots',
  props<{ idStratalots: number[]; idOccupants: number[] }>()
);

export const addManyProspectSuccess = createAction(
  '[Occupant] Add Many prospect',
  props<{ idOccupant: number; idProspects: number[] }>()
);

export const deleteManyProspectSuccess = createAction(
  '[Occupant] Delete Many Prospects',
  props<{ idProspects: number[]; idOccupants: number[] }>()
);

export const addCompanySuccess = createAction(
  '[Occupant] Add Company',
  props<{ idOccupant: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[Occupant] Delete Many Company',
  props<{ idCompanies: number[]; idOccupants: number[] }>()
);
