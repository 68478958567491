import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { Droit, DroitEntityState } from '@_model/interfaces/droit.model';
import { DroitApiService } from '@_services/api/droit-api.service';
import * as droitActions from '@_store/droit/droit.actions';
import { getActionsToNormalizeDroit } from '@_config/store/normalization.generated';
import { selectDroitState } from './droit-generated.selectors';
import * as profilActions from '@_store/profil/profil.actions';
import { Profil } from '@_model/interfaces/profil.model';

export interface DroitRelationsIds {
  profils?: number | number[];
}

export function getDefaultAddDroitActions(droit: DroitEntityState, ids?: DroitRelationsIds): Action[] {
  const actions: Action[] = [droitActions.normalizeManyDroitsAfterUpsert({ droits: [droit] })];

  if (ids?.profils) {
    if (!Array.isArray(ids.profils)) {
      actions.push(
        profilActions.upsertOneProfil({
          profil: {
            idDroit: droit.idDroit,
            idProfil: ids.profils as number
          } as Profil & any
        })
      );
      actions.push(
        droitActions.addManyProfilSuccess({
          idDroit: droit.idDroit,
          idProfils: [ids.profils as number]
        })
      );
    } else {
      actions.push(
        profilActions.upsertManyProfils({
          profils: (ids.profils as number[]).map((idProfil: number) => ({
            idDroit: droit.idDroit,
            idProfil
          })) as Profil[] & any[]
        })
      );
      actions.push(
        droitActions.addManyProfilSuccess({
          idDroit: droit.idDroit,
          idProfils: ids.profils as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteDroitActions(droit: DroitEntityState): Action[] {
  const actions: Action[] = [droitActions.deleteOneDroitSuccess({ idDroit: droit.idDroit })];

  if (droit.profils) {
    actions.push(
      profilActions.deleteManyDroitSuccess({
        idDroits: [droit.idDroit],
        idProfils: droit.profils as number[]
      })
    );
  }

  return actions;
}

export class GeneratedDroitEffects {
  constructor(
    protected actions$: Actions,
    protected droitApiService: DroitApiService,
    protected store$: Store<AppState>
  ) {}

  getManyDroits$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(droitActions.getManyDroits),
      switchMap(({ params }) =>
        this.droitApiService.getDroits(params).pipe(
          map((droits: Droit[]) => {
            return droitActions.normalizeManyDroitsAfterUpsert({ droits });
          }),
          catchError(error => of(droitActions.droitsFailure({ error })))
        )
      )
    );
  });

  getOneDroit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(droitActions.getOneDroit),
      switchMap(idDroit =>
        this.droitApiService.getDroit(idDroit).pipe(
          map((droit: Droit) => {
            return droitActions.normalizeManyDroitsAfterUpsert({ droits: [droit] });
          }),
          catchError(error => of(droitActions.droitsFailure({ error })))
        )
      )
    );
  });

  upsertOneDroit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(droitActions.upsertOneDroit),
      concatMap(({ droit, ids }: { droit: Partial<Droit>; ids?: DroitRelationsIds }) => {
        if (droit.idDroit) {
          return this.droitApiService.updateDroit(droit).pipe(
            map((droitReturned: Droit) => {
              return droitActions.normalizeManyDroitsAfterUpsert({ droits: [droitReturned] });
            }),
            catchError(error => of(droitActions.droitsFailure({ error })))
          );
        } else {
          return this.droitApiService.addDroit(droit).pipe(
            mergeMap((droitReturned: Droit) => getDefaultAddDroitActions(droitReturned, ids)),
            catchError(error => of(droitActions.droitsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneDroit$ = createEffect(() => {
    const selectDroitState$ = this.store$.select(selectDroitState);
    return this.actions$.pipe(
      ofType(droitActions.deleteOneDroit),
      withLatestFrom(selectDroitState$),
      concatMap(([{ idDroit }, state]) =>
        this.droitApiService.deleteDroit(idDroit).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteDroitActions(state.entities[idDroit] as DroitEntityState),
              droitActions.deleteOneDroit.type
            )
          ]),
          catchError(error => of(droitActions.droitsFailure({ error })))
        )
      )
    );
  });

  normalizeManyDroitsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(droitActions.normalizeManyDroitsAfterUpsert),
      concatMap(({ droits }) => {
        const actions: Action[] = getActionsToNormalizeDroit(droits, StoreActionType.upsert);
        return [getMultiAction(actions, '[Droit] Normalization After Upsert Success')];
      })
    );
  });
}
