import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as StratalotPriceValueActions from './stratalot-price-value.actions';
import { adapter, initialState, StratalotPriceValueState } from './stratalot-price-value.state';
import { Dictionary } from '@ngrx/entity';
import { StratalotPriceValueEntityState } from '@_model/interfaces/stratalot-price-value.model';

export const stratalotPriceValueReducersGeneratedFunctions: ReducerTypes<
  StratalotPriceValueState,
  readonly ActionCreator[]
>[] = [
  on(StratalotPriceValueActions.getOneStratalotPriceValue, (state: StratalotPriceValueState) =>
    setLoadingsState(state, true)
  ),
  on(StratalotPriceValueActions.getManyStratalotPriceValues, (state: StratalotPriceValueState) =>
    setLoadingsState(state, true)
  ),
  on(StratalotPriceValueActions.upsertOneStratalotPriceValue, (state: StratalotPriceValueState) =>
    setLoadingsState(state, true)
  ),

  on(
    StratalotPriceValueActions.upsertManyStratalotPriceValuesSuccess,
    (state: StratalotPriceValueState, { stratalotPriceValues }) =>
      adapter.upsertMany(stratalotPriceValues, setLoadingsState(state, false))
  ),
  on(StratalotPriceValueActions.deleteOneStratalotPriceValue, (state: StratalotPriceValueState) =>
    setLoadingsState(state, true)
  ),
  on(
    StratalotPriceValueActions.deleteOneStratalotPriceValueSuccess,
    (state: StratalotPriceValueState, { idStratalotPriceValue }) =>
      adapter.removeOne(idStratalotPriceValue, setLoadingsState(state, false))
  ),
  on(StratalotPriceValueActions.clearActive, (state: StratalotPriceValueState) => ({ ...state, actives: [] })),
  on(StratalotPriceValueActions.addManyActives, (state: StratalotPriceValueState, { idStratalotPriceValues }) => ({
    ...state,
    actives: state.actives.concat(idStratalotPriceValues)
  })),
  on(StratalotPriceValueActions.deleteOneActive, (state: StratalotPriceValueState, { idStratalotPriceValue }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idStratalotPriceValue)
  })),

  on(StratalotPriceValueActions.clearStore, () => initialState),

  on(
    StratalotPriceValueActions.addCompanyPriceLabelSuccess,
    (state: StratalotPriceValueState, { idStratalotPriceValue, idCompanyPriceLabel }) => {
      if (!state.entities[idStratalotPriceValue]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotPriceValue]: {
            ...state.entities[idStratalotPriceValue],
            companyPriceLabel: idCompanyPriceLabel
          }
        }
      };
    }
  ),

  on(
    StratalotPriceValueActions.deleteManyCompanyPriceLabelSuccess,
    (state: StratalotPriceValueState, { idCompanyPriceLabels, idStratalotPriceValues }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotPriceValues.reduce((entities, idStratalotPriceValue) => {
            if (!state.entities[idStratalotPriceValue]?.companyPriceLabel) {
              return entities;
            }
            entities[idStratalotPriceValue] = {
              ...state.entities[idStratalotPriceValue],
              companyPriceLabel: idCompanyPriceLabels.some(
                (idCompanyPriceLabel: number) =>
                  idCompanyPriceLabel === state.entities[idStratalotPriceValue]?.companyPriceLabel
              )
                ? undefined
                : state.entities[idStratalotPriceValue]?.companyPriceLabel
            } as StratalotPriceValueEntityState;
            return entities;
          }, {} as Dictionary<StratalotPriceValueEntityState>)
        }
      };
    }
  ),

  on(
    StratalotPriceValueActions.addStratalotPriceSuccess,
    (state: StratalotPriceValueState, { idStratalotPriceValue, idStratalotPrice }) => {
      if (!state.entities[idStratalotPriceValue]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotPriceValue]: {
            ...state.entities[idStratalotPriceValue],
            stratalotPrice: idStratalotPrice
          }
        }
      };
    }
  ),

  on(
    StratalotPriceValueActions.deleteManyStratalotPriceSuccess,
    (state: StratalotPriceValueState, { idStratalotPrices, idStratalotPriceValues }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotPriceValues.reduce((entities, idStratalotPriceValue) => {
            if (!state.entities[idStratalotPriceValue]?.stratalotPrice) {
              return entities;
            }
            entities[idStratalotPriceValue] = {
              ...state.entities[idStratalotPriceValue],
              stratalotPrice: idStratalotPrices.some(
                (idStratalotPrice: number) => idStratalotPrice === state.entities[idStratalotPriceValue]?.stratalotPrice
              )
                ? undefined
                : state.entities[idStratalotPriceValue]?.stratalotPrice
            } as StratalotPriceValueEntityState;
            return entities;
          }, {} as Dictionary<StratalotPriceValueEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: StratalotPriceValueState,
  isLoading: boolean,
  isLoaded: boolean = true
): StratalotPriceValueState {
  return { ...state, isLoaded, isLoading };
}
