import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as StratalotPriceListActions from './stratalot-price-list.actions';
import { adapter, initialState, StratalotPriceListState } from './stratalot-price-list.state';
import { Dictionary } from '@ngrx/entity';
import { StratalotPriceListEntityState } from '@_model/interfaces/stratalot-price-list.model';

export const stratalotPriceListReducersGeneratedFunctions: ReducerTypes<
  StratalotPriceListState,
  readonly ActionCreator[]
>[] = [
  on(StratalotPriceListActions.getOneStratalotPriceList, (state: StratalotPriceListState) =>
    setLoadingsState(state, true)
  ),
  on(StratalotPriceListActions.getManyStratalotPriceLists, (state: StratalotPriceListState) =>
    setLoadingsState(state, true)
  ),
  on(StratalotPriceListActions.upsertOneStratalotPriceList, (state: StratalotPriceListState) =>
    setLoadingsState(state, true)
  ),

  on(
    StratalotPriceListActions.upsertManyStratalotPriceListsSuccess,
    (state: StratalotPriceListState, { stratalotPriceLists }) =>
      adapter.upsertMany(stratalotPriceLists, setLoadingsState(state, false))
  ),
  on(StratalotPriceListActions.deleteOneStratalotPriceList, (state: StratalotPriceListState) =>
    setLoadingsState(state, true)
  ),
  on(
    StratalotPriceListActions.deleteOneStratalotPriceListSuccess,
    (state: StratalotPriceListState, { idStratalotPriceList }) =>
      adapter.removeOne(idStratalotPriceList, setLoadingsState(state, false))
  ),
  on(StratalotPriceListActions.clearActive, (state: StratalotPriceListState) => ({ ...state, actives: [] })),
  on(StratalotPriceListActions.addManyActives, (state: StratalotPriceListState, { idStratalotPriceLists }) => ({
    ...state,
    actives: state.actives.concat(idStratalotPriceLists)
  })),
  on(StratalotPriceListActions.deleteOneActive, (state: StratalotPriceListState, { idStratalotPriceList }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idStratalotPriceList)
  })),

  on(StratalotPriceListActions.clearStore, () => initialState),

  on(
    StratalotPriceListActions.addResidencePriceGridSuccess,
    (state: StratalotPriceListState, { idStratalotPriceList, idResidencePriceGrid }) => {
      if (!state.entities[idStratalotPriceList]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotPriceList]: {
            ...state.entities[idStratalotPriceList],
            residencePriceGrid: idResidencePriceGrid
          }
        }
      };
    }
  ),

  on(
    StratalotPriceListActions.deleteManyResidencePriceGridSuccess,
    (state: StratalotPriceListState, { idResidencePriceGrids, idStratalotPriceLists }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotPriceLists.reduce((entities, idStratalotPriceList) => {
            if (!state.entities[idStratalotPriceList]?.residencePriceGrid) {
              return entities;
            }
            entities[idStratalotPriceList] = {
              ...state.entities[idStratalotPriceList],
              residencePriceGrid: idResidencePriceGrids.some(
                (idResidencePriceGrid: number) =>
                  idResidencePriceGrid === state.entities[idStratalotPriceList]?.residencePriceGrid
              )
                ? undefined
                : state.entities[idStratalotPriceList]?.residencePriceGrid
            } as StratalotPriceListEntityState;
            return entities;
          }, {} as Dictionary<StratalotPriceListEntityState>)
        }
      };
    }
  ),

  on(
    StratalotPriceListActions.addCompanyStratalotTypeSuccess,
    (state: StratalotPriceListState, { idStratalotPriceList, idCompanyStratalotType }) => {
      if (!state.entities[idStratalotPriceList]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotPriceList]: {
            ...state.entities[idStratalotPriceList],
            companyStratalotType: idCompanyStratalotType
          }
        }
      };
    }
  ),

  on(
    StratalotPriceListActions.deleteManyCompanyStratalotTypeSuccess,
    (state: StratalotPriceListState, { idCompanyStratalotTypes, idStratalotPriceLists }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotPriceLists.reduce((entities, idStratalotPriceList) => {
            if (!state.entities[idStratalotPriceList]?.companyStratalotType) {
              return entities;
            }
            entities[idStratalotPriceList] = {
              ...state.entities[idStratalotPriceList],
              companyStratalotType: idCompanyStratalotTypes.some(
                (idCompanyStratalotType: number) =>
                  idCompanyStratalotType === state.entities[idStratalotPriceList]?.companyStratalotType
              )
                ? undefined
                : state.entities[idStratalotPriceList]?.companyStratalotType
            } as StratalotPriceListEntityState;
            return entities;
          }, {} as Dictionary<StratalotPriceListEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: StratalotPriceListState,
  isLoading: boolean,
  isLoaded: boolean = true
): StratalotPriceListState {
  return { ...state, isLoaded, isLoading };
}
