import { ResidenceStudyCriteriaState, initialState } from './residence-study-criteria.state';
import { Action, createReducer } from '@ngrx/store';
import { residenceStudyCriteriaReducersGeneratedFunctions } from './residence-study-criteria-generated.reducer';

const residenceStudyCriteriaReducersFunctions = [...residenceStudyCriteriaReducersGeneratedFunctions];

const residenceStudyCriteriaReducer = createReducer(initialState, ...residenceStudyCriteriaReducersFunctions);

export function reducer(state: ResidenceStudyCriteriaState | undefined, action: Action) {
  return residenceStudyCriteriaReducer(state, action);
}
