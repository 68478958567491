import { DiagnosticState, initialState } from './diagnostic.state';
import { Action, createReducer } from '@ngrx/store';
import { diagnosticReducersGeneratedFunctions } from './diagnostic-generated.reducer';

const diagnosticReducersFunctions = [...diagnosticReducersGeneratedFunctions];

const diagnosticReducer = createReducer(initialState, ...diagnosticReducersFunctions);

export function reducer(state: DiagnosticState | undefined, action: Action) {
  return diagnosticReducer(state, action);
}
