import { OrganizationState, initialState } from './organization.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationReducersGeneratedFunctions } from './organization-generated.reducer';

const organizationReducersFunctions = [...organizationReducersGeneratedFunctions];

const organizationReducer = createReducer(initialState, ...organizationReducersFunctions);

export function reducer(state: OrganizationState | undefined, action: Action) {
  return organizationReducer(state, action);
}
