import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CompanyCommunicationResidenceTodo,
  CompanyCommunicationResidenceTodoEntityState
} from '@_model/interfaces/company-communication-residence-todo.model';
import { CompanyCommunication, CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';
import {
  OrganizationResidenceTodo,
  OrganizationResidenceTodoEntityState
} from '@_model/interfaces/organization-residence-todo.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  companyCommunicationResidenceTodoFeatureKey,
  CompanyCommunicationResidenceTodoState
} from './company-communication-residence-todo.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyCommunicationResidenceTodoRelations: string[] = [
  'companyCommunications',
  'organizationResidenceTodos'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyCommunicationResidenceTodoState =
  createFeatureSelector<CompanyCommunicationResidenceTodoState>(companyCommunicationResidenceTodoFeatureKey);

export const selectIsLoadedCompanyCommunicationResidenceTodo = createSelector(
  selectCompanyCommunicationResidenceTodoState,
  (state: CompanyCommunicationResidenceTodoState) => state.isLoaded
);

export const selectIsLoadingCompanyCommunicationResidenceTodo = createSelector(
  selectCompanyCommunicationResidenceTodoState,
  (state: CompanyCommunicationResidenceTodoState) => state.isLoading
);

export const selectIsReadyCompanyCommunicationResidenceTodo = createSelector(
  selectCompanyCommunicationResidenceTodoState,
  (state: CompanyCommunicationResidenceTodoState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyCommunicationResidenceTodo = createSelector(
  selectCompanyCommunicationResidenceTodoState,
  (state: CompanyCommunicationResidenceTodoState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyCommunicationResidenceTodoModel: SelectorModel = {
  name: 'companyCommunicationResidenceTodos',
  getSelector: selectAllCompanyCommunicationResidenceTodosDictionary,
  isReady: selectIsReadyCompanyCommunicationResidenceTodo
};

export const selectCompanyCommunicationResidenceTodosEntities = createSelector(
  selectCompanyCommunicationResidenceTodoState,
  selectEntities
);

export const selectCompanyCommunicationResidenceTodosArray = createSelector(
  selectCompanyCommunicationResidenceTodoState,
  selectAll
);

export const selectIdCompanyCommunicationResidenceTodosActive = createSelector(
  selectCompanyCommunicationResidenceTodoState,
  (state: CompanyCommunicationResidenceTodoState) => state.actives
);

const companyCommunicationResidenceTodosInObject = (
  companyCommunicationResidenceTodos: Dictionary<CompanyCommunicationResidenceTodoEntityState>
) => ({ companyCommunicationResidenceTodos });

const selectCompanyCommunicationResidenceTodosEntitiesDictionary = createSelector(
  selectCompanyCommunicationResidenceTodosEntities,
  companyCommunicationResidenceTodosInObject
);

const selectAllCompanyCommunicationResidenceTodosObject = createSelector(
  selectCompanyCommunicationResidenceTodosEntities,
  companyCommunicationResidenceTodos => {
    return hydrateAll({ companyCommunicationResidenceTodos });
  }
);

const selectOneCompanyCommunicationResidenceTodoDictionary = (idCompanyCommunicationResidenceTodo: number) =>
  createSelector(selectCompanyCommunicationResidenceTodosEntities, companyCommunicationResidenceTodos => {
    return {
      companyCommunicationResidenceTodos: {
        [idCompanyCommunicationResidenceTodo]: companyCommunicationResidenceTodos[idCompanyCommunicationResidenceTodo]
      }
    };
  });

const selectOneCompanyCommunicationResidenceTodoDictionaryWithoutChild = (
  idCompanyCommunicationResidenceTodo: number
) =>
  createSelector(selectCompanyCommunicationResidenceTodosEntities, companyCommunicationResidenceTodos => {
    return {
      companyCommunicationResidenceTodo: companyCommunicationResidenceTodos[idCompanyCommunicationResidenceTodo]
    };
  });

const selectActiveCompanyCommunicationResidenceTodosEntities = createSelector(
  selectIdCompanyCommunicationResidenceTodosActive,
  selectCompanyCommunicationResidenceTodosEntities,
  (actives: number[], companyCommunicationResidenceTodos: Dictionary<CompanyCommunicationResidenceTodoEntityState>) =>
    getCompanyCommunicationResidenceTodosFromActives(actives, companyCommunicationResidenceTodos)
);

function getCompanyCommunicationResidenceTodosFromActives(
  actives: number[],
  companyCommunicationResidenceTodos: Dictionary<CompanyCommunicationResidenceTodoEntityState>
): Dictionary<CompanyCommunicationResidenceTodoEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyCommunicationResidenceTodos[idActive]) {
      acc[idActive] = companyCommunicationResidenceTodos[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyCommunicationResidenceTodoEntityState>);
}

const selectAllCompanyCommunicationResidenceTodosSelectors: Dictionary<Selector> = {};
export function selectAllCompanyCommunicationResidenceTodos(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyCommunicationResidenceTodo>(
      schema,
      selectAllCompanyCommunicationResidenceTodosSelectors,
      selectCompanyCommunicationResidenceTodosEntitiesDictionary,
      getRelationSelectors,
      companyCommunicationResidenceTodoRelations,
      hydrateAll,
      'companyCommunicationResidenceTodo'
    );
  } else {
    return selectAllCompanyCommunicationResidenceTodosObject;
  }
}

export function selectAllCompanyCommunicationResidenceTodosDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyCommunicationResidenceTodos'
): Selector {
  return createSelector(selectAllCompanyCommunicationResidenceTodos(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyCommunicationResidenceTodoEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyCommunicationResidenceTodos.length; i++) {
      res[customKey][result.companyCommunicationResidenceTodos[i].idCompanyCommunicationResidenceTodo] =
        result.companyCommunicationResidenceTodos[i];
    }
    return res;
  });
}

export function selectOneCompanyCommunicationResidenceTodo(
  schema: SelectSchema = {},
  idCompanyCommunicationResidenceTodo: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [
      selectOneCompanyCommunicationResidenceTodoDictionary(idCompanyCommunicationResidenceTodo)
    ];
    selectors.push(
      ...getRelationSelectors(schema, companyCommunicationResidenceTodoRelations, 'companyCommunicationResidenceTodo')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyCommunicationResidenceTodoDictionaryWithoutChild(idCompanyCommunicationResidenceTodo);
  }
}

export function selectActiveCompanyCommunicationResidenceTodos(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyCommunicationResidenceTodosEntities, companyCommunicationResidenceTodos => ({
      companyCommunicationResidenceTodos
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, companyCommunicationResidenceTodoRelations, 'companyCommunicationResidenceTodo')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyCommunicationResidenceTodos: Dictionary<CompanyCommunicationResidenceTodoEntityState>;
  companyCommunications?: Dictionary<CompanyCommunicationEntityState>;
  organizationResidenceTodos?: Dictionary<OrganizationResidenceTodoEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  companyCommunicationResidenceTodos: (CompanyCommunicationResidenceTodo | null)[];
} {
  const { companyCommunicationResidenceTodos, companyCommunications, organizationResidenceTodos } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    companyCommunicationResidenceTodos: Object.keys(companyCommunicationResidenceTodos).map(
      idCompanyCommunicationResidenceTodo =>
        hydrate(
          companyCommunicationResidenceTodos[
            idCompanyCommunicationResidenceTodo
          ] as CompanyCommunicationResidenceTodoEntityState,
          companyCommunications,
          organizationResidenceTodos
        )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  companyCommunicationResidenceTodo: CompanyCommunicationResidenceTodoEntityState | null;
} {
  const { companyCommunicationResidenceTodos, companyCommunications, organizationResidenceTodos } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const companyCommunicationResidenceTodo = Object.values(companyCommunicationResidenceTodos)[0];
  return {
    companyCommunicationResidenceTodo: hydrate(
      companyCommunicationResidenceTodo as CompanyCommunicationResidenceTodoEntityState,
      companyCommunications,
      organizationResidenceTodos
    )
  };
}

function hydrate(
  companyCommunicationResidenceTodo: CompanyCommunicationResidenceTodoEntityState,
  companyCommunicationEntities?: Dictionary<CompanyCommunicationEntityState>,
  organizationResidenceTodoEntities?: Dictionary<OrganizationResidenceTodoEntityState>
): CompanyCommunicationResidenceTodo | null {
  if (!companyCommunicationResidenceTodo) {
    return null;
  }

  const companyCommunicationResidenceTodoHydrated: CompanyCommunicationResidenceTodoEntityState = {
    ...companyCommunicationResidenceTodo
  };
  if (companyCommunicationEntities) {
    companyCommunicationResidenceTodoHydrated.companyCommunication = companyCommunicationEntities[
      companyCommunicationResidenceTodo.companyCommunication as number
    ] as CompanyCommunication;
  } else {
    delete companyCommunicationResidenceTodoHydrated.companyCommunication;
  }
  if (organizationResidenceTodoEntities) {
    companyCommunicationResidenceTodoHydrated.organizationResidenceTodo = organizationResidenceTodoEntities[
      companyCommunicationResidenceTodo.organizationResidenceTodo as number
    ] as OrganizationResidenceTodo;
  } else {
    delete companyCommunicationResidenceTodoHydrated.organizationResidenceTodo;
  }

  return companyCommunicationResidenceTodoHydrated as CompanyCommunicationResidenceTodo;
}
