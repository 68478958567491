export * from './stratalot-generated.actions';

import { createAction, props } from '@ngrx/store';
import { Stratalot } from '@_model/interfaces/stratalot.model';
import { SaisieConge } from '@_shared/dumb/free-stratalot-modal/SaisieConge';
import { StratalotAnnonce } from '@_shared/interfaces/annonce.interface';
import { GetManyStratalotsByStepProgressesDTO } from '@_shared/interfaces/get-many-stratalots-by-step-progresses.dto';

// OSTypes Stands for OrganizationStratalotTypes which is too long for a URL
export const getManyFreeStratalotsOfProgram = createAction(
  '[Stratalot] Get Many Free Stratalots of program',
  props<{ params: Partial<Stratalot> & { idsOSTypes: number[] } }>()
);
export const getManyFreeStratalots = createAction(
  '[Stratalot] Get Many Free Stratalots',
  props<{ params: Partial<Stratalot> & { idsCompanies: number[]; idsOSTypes: number[] } }>()
);

export const getManyUbiflowMonitoringStratalots = createAction(
  '[Stratalot] Get Many Ubiflow Monitoring Stratalots',
  props<{ params: Partial<Stratalot> & { idsCompanies: number[]; idsOSTypes: number[] } }>()
);

export const getManyOccupedStratalots = createAction(
  '[Stratalot] Get Many Occuped Stratalots',
  props<{ params: Partial<Stratalot> & { idsResidence: number[] } }>()
);

export const getAllOccupedStratalots = createAction(
  '[Stratalot] Get All Occuped Stratalots',
  props<{ params: Partial<Stratalot> & { idsCompanies: number[] } }>()
);

export const getManyStratalotsFromResidence = createAction(
  '[Stratalot] Get Many Stratalots From Residence',
  props<{ params: Partial<Stratalot> & { idResidence: number } }>()
);

export const getManyStratalotsForValidateOfferBoard = createAction(
  '[Stratalot] Get many stratalots for validation of Offer board',
  props<{ params: any }>()
);

export const getManyStratalotsByStepProgresses = createAction(
  '[Stratalot] Get many stratalots by step progress',
  props<GetManyStratalotsByStepProgressesDTO>()
);

export const activateStratalotFromCampaign = createAction(
  '[Stratalot] Activate one stratalot from a campaign',
  props<{
    idCompany: number;
    envoiUbiflowDateDebut: Date;
    envoiUbiflowDateFin: Date;
    envoiUbiflowIdPriceLabel: number;
    idStratalot: number;
  }>()
);

export const updateStratalotAd = createAction(
  '[Stratalot] Update one stratalot ad',
  props<{
    idStratalot: number;
    stratalotAnnonce: StratalotAnnonce;
  }>()
);

export const updateVacance = createAction(
  '[Stratalot] Update one stratalot vacance',
  props<{
    idStratalot: number;
    saisieconge: SaisieConge;
  }>()
);

export const duplicateDiffusionVisualsFromResidence = createAction(
  '[Stratalot] Duplicate diffusion visuals from residence',
  props<{ idResidence: number; idStratalot: number }>()
);
