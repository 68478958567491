import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { StratalotRcp, StratalotRcpEntityState } from '@_model/interfaces/stratalot-rcp.model';
import { StratalotRcpApiService } from '@_services/api/stratalot-rcp-api.service';
import * as stratalotRcpActions from '@_store/stratalot-rcp/stratalot-rcp.actions';
import { getActionsToNormalizeStratalotRcp } from '@_config/store/normalization.generated';
import { selectStratalotRcpState } from './stratalot-rcp-generated.selectors';
import * as residenceActions from '@_store/residence/residence.actions';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import * as residenceShareActions from '@_store/residence-share/residence-share.actions';
import { ResidenceShare } from '@_model/interfaces/residence-share.model';
import * as residenceShareRcpActions from '@_store/residence-share-rcp/residence-share-rcp.actions';
import { ResidenceShareRcp } from '@_model/interfaces/residence-share-rcp.model';

export interface StratalotRcpRelationsIds {
  residenceShares?: number | number[];
  residenceShareRcps?: number | number[];
  residence?: number;
  stratalot?: number;
}

export function getDefaultAddStratalotRcpActions(
  stratalotRcp: StratalotRcpEntityState,
  ids?: StratalotRcpRelationsIds
): Action[] {
  const actions: Action[] = [
    stratalotRcpActions.normalizeManyStratalotRcpsAfterUpsert({ stratalotRcps: [stratalotRcp] })
  ];

  if (ids?.residence) {
    actions.push(
      residenceActions.addManyStratalotRcpSuccess({
        idResidence: ids.residence,
        idStratalotRcps: [stratalotRcp.idStratalotRcp]
      })
    );
    actions.push(
      stratalotRcpActions.addResidenceSuccess({
        idStratalotRcp: stratalotRcp.idStratalotRcp,
        idResidence: ids.residence
      })
    );
  }

  if (ids?.stratalot) {
    actions.push(
      stratalotActions.addStratalotRcpSuccess({
        idStratalot: ids.stratalot,
        idStratalotRcp: stratalotRcp.idStratalotRcp
      })
    );
    actions.push(
      stratalotRcpActions.addStratalotSuccess({
        idStratalotRcp: stratalotRcp.idStratalotRcp,
        idStratalot: ids.stratalot
      })
    );
  }

  if (ids?.residenceShares) {
    if (!Array.isArray(ids.residenceShares)) {
      actions.push(
        residenceShareActions.upsertOneResidenceShare({
          residenceShare: {
            idStratalotRcp: stratalotRcp.idStratalotRcp,
            idResidenceShare: ids.residenceShares as number
          } as ResidenceShare & any
        })
      );
      actions.push(
        stratalotRcpActions.addManyResidenceShareSuccess({
          idStratalotRcp: stratalotRcp.idStratalotRcp,
          idResidenceShares: [ids.residenceShares as number]
        })
      );
    } else {
      actions.push(
        residenceShareActions.upsertManyResidenceShares({
          residenceShares: (ids.residenceShares as number[]).map((idResidenceShare: number) => ({
            idStratalotRcp: stratalotRcp.idStratalotRcp,
            idResidenceShare
          })) as ResidenceShare[] & any[]
        })
      );
      actions.push(
        stratalotRcpActions.addManyResidenceShareSuccess({
          idStratalotRcp: stratalotRcp.idStratalotRcp,
          idResidenceShares: ids.residenceShares as number[]
        })
      );
    }
  }

  if (ids?.residenceShareRcps) {
    if (!Array.isArray(ids.residenceShareRcps)) {
      actions.push(
        residenceShareRcpActions.upsertOneResidenceShareRcp({
          residenceShareRcp: {
            idStratalotRcp: stratalotRcp.idStratalotRcp,
            idResidenceShareRcp: ids.residenceShareRcps as number
          } as ResidenceShareRcp
        })
      );
      actions.push(
        stratalotRcpActions.addManyResidenceShareRcpSuccess({
          idStratalotRcp: stratalotRcp.idStratalotRcp,
          idResidenceShareRcps: [ids.residenceShareRcps as number]
        })
      );
    } else {
      actions.push(
        residenceShareRcpActions.upsertManyResidenceShareRcps({
          residenceShareRcps: (ids.residenceShareRcps as number[]).map((idResidenceShareRcp: number) => ({
            idStratalotRcp: stratalotRcp.idStratalotRcp,
            idResidenceShareRcp
          })) as ResidenceShareRcp[]
        })
      );
      actions.push(
        stratalotRcpActions.addManyResidenceShareRcpSuccess({
          idStratalotRcp: stratalotRcp.idStratalotRcp,
          idResidenceShareRcps: ids.residenceShareRcps as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteStratalotRcpActions(stratalotRcp: StratalotRcpEntityState): Action[] {
  const actions: Action[] = [
    stratalotRcpActions.deleteOneStratalotRcpSuccess({ idStratalotRcp: stratalotRcp.idStratalotRcp })
  ];

  if (stratalotRcp.residence) {
    actions.push(
      residenceActions.deleteManyStratalotRcpSuccess({
        idStratalotRcps: [stratalotRcp.idStratalotRcp],
        idResidences: [stratalotRcp.residence as number]
      })
    );
  }

  if (stratalotRcp.stratalot) {
    actions.push(
      stratalotActions.deleteStratalotRcpSuccess({
        idStratalotRcps: [stratalotRcp.idStratalotRcp],
        idStratalots: [stratalotRcp.stratalot as number]
      })
    );
  }

  if (stratalotRcp.residenceShares) {
    actions.push(
      residenceShareActions.deleteManyStratalotRcpSuccess({
        idStratalotRcps: [stratalotRcp.idStratalotRcp],
        idResidenceShares: stratalotRcp.residenceShares as number[]
      })
    );
  }

  if (stratalotRcp.residenceShareRcps) {
    actions.push(
      residenceShareRcpActions.deleteManyStratalotRcpSuccess({
        idStratalotRcps: [stratalotRcp.idStratalotRcp],
        idResidenceShareRcps: stratalotRcp.residenceShareRcps as number[]
      })
    );
  }

  return actions;
}

export class GeneratedStratalotRcpEffects {
  constructor(
    protected actions$: Actions,
    protected stratalotRcpApiService: StratalotRcpApiService,
    protected store$: Store<AppState>
  ) {}

  getManyStratalotRcps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotRcpActions.getManyStratalotRcps),
      switchMap(({ params }) =>
        this.stratalotRcpApiService.getStratalotRcps(params).pipe(
          map((stratalotRcps: StratalotRcp[]) => {
            return stratalotRcpActions.normalizeManyStratalotRcpsAfterUpsert({ stratalotRcps });
          }),
          catchError(error => of(stratalotRcpActions.stratalotRcpsFailure({ error })))
        )
      )
    );
  });

  getOneStratalotRcp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotRcpActions.getOneStratalotRcp),
      switchMap(idStratalotRcp =>
        this.stratalotRcpApiService.getStratalotRcp(idStratalotRcp).pipe(
          map((stratalotRcp: StratalotRcp) => {
            return stratalotRcpActions.normalizeManyStratalotRcpsAfterUpsert({ stratalotRcps: [stratalotRcp] });
          }),
          catchError(error => of(stratalotRcpActions.stratalotRcpsFailure({ error })))
        )
      )
    );
  });

  upsertOneStratalotRcp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotRcpActions.upsertOneStratalotRcp),
      concatMap(({ stratalotRcp, ids }: { stratalotRcp: Partial<StratalotRcp>; ids?: StratalotRcpRelationsIds }) => {
        if (stratalotRcp.idStratalotRcp) {
          return this.stratalotRcpApiService.updateStratalotRcp(stratalotRcp).pipe(
            map((stratalotRcpReturned: StratalotRcp) => {
              return stratalotRcpActions.normalizeManyStratalotRcpsAfterUpsert({
                stratalotRcps: [stratalotRcpReturned]
              });
            }),
            catchError(error => of(stratalotRcpActions.stratalotRcpsFailure({ error })))
          );
        } else {
          return this.stratalotRcpApiService.addStratalotRcp(stratalotRcp).pipe(
            mergeMap((stratalotRcpReturned: StratalotRcp) =>
              getDefaultAddStratalotRcpActions(stratalotRcpReturned, ids)
            ),
            catchError(error => of(stratalotRcpActions.stratalotRcpsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneStratalotRcp$ = createEffect(() => {
    const selectStratalotRcpState$ = this.store$.select(selectStratalotRcpState);
    return this.actions$.pipe(
      ofType(stratalotRcpActions.deleteOneStratalotRcp),
      withLatestFrom(selectStratalotRcpState$),
      concatMap(([{ idStratalotRcp }, state]) =>
        this.stratalotRcpApiService.deleteStratalotRcp(idStratalotRcp).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteStratalotRcpActions(state.entities[idStratalotRcp] as StratalotRcpEntityState),
              stratalotRcpActions.deleteOneStratalotRcp.type
            )
          ]),
          catchError(error => of(stratalotRcpActions.stratalotRcpsFailure({ error })))
        )
      )
    );
  });

  normalizeManyStratalotRcpsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotRcpActions.normalizeManyStratalotRcpsAfterUpsert),
      concatMap(({ stratalotRcps }) => {
        const actions: Action[] = getActionsToNormalizeStratalotRcp(stratalotRcps, StoreActionType.upsert);
        return [getMultiAction(actions, '[StratalotRcp] Normalization After Upsert Success')];
      })
    );
  });
}
