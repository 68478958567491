import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CompanyMyNotary, CompanyMyNotaryEntityState } from '@_model/interfaces/company-my-notary.model';
import { CompanyMyNotaryRelationsIds } from './company-my-notary-generated.effects';

export const getOneCompanyMyNotary = createAction(
  '[CompanyMyNotary] Get One CompanyMyNotary',
  props<{ idCompanyMyNotary: number; params?: any }>()
);

export const getManyCompanyMyNotaries = createAction(
  '[CompanyMyNotary] Get Many CompanyMyNotaries',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyMyNotary] Add Many Actives CompanyMyNotary',
  props<{ idCompanyMyNotaries: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyMyNotary] Delete One Active CompanyMyNotary',
  props<{ idCompanyMyNotary: number }>()
);

export const clearActive = createAction('[CompanyMyNotary] Clear Active CompanyMyNotary');

export const upsertOneCompanyMyNotary = createAction(
  '[CompanyMyNotary] Upsert One CompanyMyNotary',
  props<{
    companyMyNotary: Partial<CompanyMyNotary>;
    ids?: CompanyMyNotaryRelationsIds;
  }>()
);

export const upsertManyCompanyMyNotaries = createAction(
  '[CompanyMyNotary] Upsert Many CompanyMyNotaries',
  props<{
    companyMyNotaries: Partial<CompanyMyNotary>[];
    ids?: CompanyMyNotaryRelationsIds;
  }>()
);

export const upsertManyCompanyMyNotariesSuccess = createAction(
  '[CompanyMyNotary] Create Many CompanyMyNotaries Success',
  props<{ companyMyNotaries: CompanyMyNotaryEntityState[] }>()
);

export const deleteOneCompanyMyNotary = createAction(
  '[CompanyMyNotary] Delete One CompanyMyNotary',
  props<{ idCompanyMyNotary: number }>()
);

export const deleteOneCompanyMyNotarySuccess = createAction(
  '[CompanyMyNotary] Delete One CompanyMyNotary Success',
  props<{ idCompanyMyNotary: number }>()
);

export const normalizeManyCompanyMyNotariesAfterUpsert = createAction(
  '[CompanyMyNotary] Normalize Many CompanyMyNotaries After Upsert',
  props<{ companyMyNotaries: CompanyMyNotaryEntityState[] }>()
);

export const companyMyNotariesFailure = createAction(
  '[CompanyMyNotary] CompanyMyNotaries Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyMyNotary] Clear CompanyMyNotaries');

export const addCompanySuccess = createAction(
  '[CompanyMyNotary] Add Company',
  props<{ idCompanyMyNotary: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[CompanyMyNotary] Delete Many Company',
  props<{ idCompanies: number[]; idCompanyMyNotaries: number[] }>()
);
