import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { SaleCategoryFamilyEntityState } from '@_model/interfaces/sale-category-family.model';

export interface SaleCategoryFamilyState extends EntityState<SaleCategoryFamilyEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<SaleCategoryFamilyEntityState> = createEntityAdapter<SaleCategoryFamilyEntityState>(
  {
    selectId: o => o.idSaleCategoryFamily
  }
);
export const initialState: SaleCategoryFamilyState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const saleCategoryFamilyFeatureKey = 'saleCategoryFamily';
