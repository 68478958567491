import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { City, CityEntityState } from '@_model/interfaces/city.model';
import { CityRelationsIds } from './city-generated.effects';

export const getOneCity = createAction('[City] Get One City', props<{ idCity: number; params?: any }>());

export const getManyCities = createAction('[City] Get Many Cities', props<{ params: any }>());

export const addManyActives = createAction('[City] Add Many Actives City', props<{ idCities: number[] }>());

export const deleteOneActive = createAction('[City] Delete One Active City', props<{ idCity: number }>());

export const clearActive = createAction('[City] Clear Active City');

export const upsertOneCity = createAction(
  '[City] Upsert One City',
  props<{
    city: Partial<City>;
    ids?: CityRelationsIds;
  }>()
);

export const upsertManyCities = createAction(
  '[City] Upsert Many Cities',
  props<{
    cities: Partial<City>[];
    ids?: CityRelationsIds;
  }>()
);

export const upsertManyCitiesSuccess = createAction(
  '[City] Create Many Cities Success',
  props<{ cities: CityEntityState[] }>()
);

export const deleteOneCity = createAction('[City] Delete One City', props<{ idCity: number }>());

export const deleteOneCitySuccess = createAction('[City] Delete One City Success', props<{ idCity: number }>());

export const normalizeManyCitiesAfterUpsert = createAction(
  '[City] Normalize Many Cities After Upsert',
  props<{ cities: CityEntityState[] }>()
);

export const citiesFailure = createAction('[City] Cities Failure', props<{ error: HttpErrorResponse }>());

export const clearStore = createAction('[City] Clear Cities');

export const addManyProspectBuyingWishSuccess = createAction(
  '[City] Add Many prospect-buying-wish',
  props<{ idCity: number; idProspectBuyingWishs: number[] }>()
);

export const deleteManyProspectBuyingWishSuccess = createAction(
  '[City] Delete Many ProspectBuyingWishs',
  props<{ idProspectBuyingWishs: number[]; idCities: number[] }>()
);

export const addManyResidenceSuccess = createAction(
  '[City] Add Many residence',
  props<{ idCity: number; idResidences: number[] }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[City] Delete Many Residences',
  props<{ idResidences: number[]; idCities: number[] }>()
);

export const addCompanySuccess = createAction('[City] Add Company', props<{ idCity: number; idCompany: number }>());

export const deleteManyCompanySuccess = createAction(
  '[City] Delete Many Company',
  props<{ idCompanies: number[]; idCities: number[] }>()
);
