import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StratalotPriceApiService } from '@_services/api/stratalot-price-api.service';
import { StratalotPrice } from '@_shared/models/interfaces/stratalot-price.model';
import { AppState } from '@_store/index.reducers';
import * as stratalotPricesActions from '@_store/stratalot-price/stratalot-price.actions';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { GeneratedStratalotPriceEffects } from './stratalot-price-generated.effects';

@Injectable()
export class StratalotPriceEffects extends GeneratedStratalotPriceEffects {
  constructor(actions$: Actions, stratalotPriceApiService: StratalotPriceApiService, store$: Store<AppState>) {
    super(actions$, stratalotPriceApiService, store$);
  }

  generatePriceValues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotPricesActions.generatePriceValues),
      concatMap(({ params }) =>
        this.stratalotPriceApiService.generatePriceValues(params).pipe(
          map((stratalotPrices: StratalotPrice[]) => {
            return stratalotPricesActions.normalizeManyStratalotPricesAfterUpsert({
              stratalotPrices
            });
          }),
          catchError(error => of(stratalotPricesActions.stratalotPricesFailure({ error })))
        )
      )
    );
  });
}
