import { ProspectState, initialState, adapter } from './prospect.state';
import { Action, createReducer, on } from '@ngrx/store';
import { prospectReducersGeneratedFunctions, setLoadingsState } from './prospect-generated.reducer';
import * as prospectActions from '@_store/prospect/prospect.actions';

const prospectReducersFunctions = [
  ...prospectReducersGeneratedFunctions,
  on(prospectActions.deleteManyProspectsSuccess, (state: ProspectState, { idProspects }) =>
    adapter.removeMany(idProspects, setLoadingsState(state, false))
  )
];

const prospectReducer = createReducer(initialState, ...prospectReducersFunctions);

export function reducer(state: ProspectState | undefined, action: Action) {
  return prospectReducer(state, action);
}
