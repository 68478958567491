import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyCommunicationRecipientEntityState } from '@_model/interfaces/company-communication-recipient.model';

export interface CompanyCommunicationRecipientState extends EntityState<CompanyCommunicationRecipientEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyCommunicationRecipientEntityState> =
  createEntityAdapter<CompanyCommunicationRecipientEntityState>({
    selectId: o => o.idCompanyCommunicationRecipient
  });
export const initialState: CompanyCommunicationRecipientState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyCommunicationRecipientFeatureKey = 'companyCommunicationRecipient';
