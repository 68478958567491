import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceStudyReason } from '@_model/interfaces/residence-study-reason.model';
import { Observable } from 'rxjs';

export class GeneratedResidenceStudyReasonApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidenceStudyReasons(params?: any): Observable<ResidenceStudyReason[]> {
    return this.repo.getData<ResidenceStudyReason[]>('residence-study-reason', params);
  }

  public getResidenceStudyReason(params: {
    idResidenceStudyReason: number;
    params?: any;
  }): Observable<ResidenceStudyReason> {
    return this.repo.getData<ResidenceStudyReason>(
      'residence-study-reason/' + params.idResidenceStudyReason,
      params.params
    );
  }

  public addResidenceStudyReason(
    residenceStudyReason: Partial<ResidenceStudyReason>
  ): Observable<ResidenceStudyReason> {
    return this.repo.create<ResidenceStudyReason>('residence-study-reason', residenceStudyReason);
  }

  public updateResidenceStudyReason(
    residenceStudyReason: Partial<ResidenceStudyReason>
  ): Observable<ResidenceStudyReason> {
    return this.repo.update('residence-study-reason', residenceStudyReason);
  }

  public deleteResidenceStudyReason(idResidenceStudyReason: number): Observable<number> {
    return this.repo.delete('residence-study-reason/' + +idResidenceStudyReason);
  }
}
