import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationProfilApiService } from '@_services/api/organization-profil-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedOrganizationProfilEffects } from './organization-profil-generated.effects';

@Injectable()
export class OrganizationProfilEffects extends GeneratedOrganizationProfilEffects {
  constructor(actions$: Actions, organizationProfilApiService: OrganizationProfilApiService, store$: Store<AppState>) {
    super(actions$, organizationProfilApiService, store$);
  }
}
