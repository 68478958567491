import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyCommunicationResidenceTodoEntityState } from '@_model/interfaces/company-communication-residence-todo.model';

export interface CompanyCommunicationResidenceTodoState
  extends EntityState<CompanyCommunicationResidenceTodoEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyCommunicationResidenceTodoEntityState> =
  createEntityAdapter<CompanyCommunicationResidenceTodoEntityState>({
    selectId: o => o.idCompanyCommunicationResidenceTodo
  });
export const initialState: CompanyCommunicationResidenceTodoState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyCommunicationResidenceTodoFeatureKey = 'companyCommunicationResidenceTodo';
