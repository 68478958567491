import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OrganizationResidenceStudyTodoApiService } from '@_services/api/organization-residence-study-todo-api.service';
import { GeneratedOrganizationResidenceStudyTodoEffects } from './organization-residence-study-todo-generated.effects';

@Injectable()
export class OrganizationResidenceStudyTodoEffects extends GeneratedOrganizationResidenceStudyTodoEffects {
  constructor(
    actions$: Actions,
    organizationResidenceStudyTodoApiService: OrganizationResidenceStudyTodoApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationResidenceStudyTodoApiService, store$);
  }
}
