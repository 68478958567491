import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as StepActions from './step.actions';
import { adapter, initialState, StepState } from './step.state';
import { Dictionary } from '@ngrx/entity';
import { StepEntityState } from '@_model/interfaces/step.model';

export const stepReducersGeneratedFunctions: ReducerTypes<StepState, readonly ActionCreator[]>[] = [
  on(StepActions.getOneStep, (state: StepState) => setLoadingsState(state, true)),
  on(StepActions.getManySteps, (state: StepState) => setLoadingsState(state, true)),
  on(StepActions.upsertOneStep, (state: StepState) => setLoadingsState(state, true)),

  on(StepActions.upsertManyStepsSuccess, (state: StepState, { steps }) =>
    adapter.upsertMany(steps, setLoadingsState(state, false))
  ),
  on(StepActions.deleteOneStep, (state: StepState) => setLoadingsState(state, true)),
  on(StepActions.deleteOneStepSuccess, (state: StepState, { idStep }) =>
    adapter.removeOne(idStep, setLoadingsState(state, false))
  ),
  on(StepActions.clearActive, (state: StepState) => ({ ...state, actives: [] })),
  on(StepActions.addManyActives, (state: StepState, { idSteps }) => ({
    ...state,
    actives: state.actives.concat(idSteps)
  })),
  on(StepActions.deleteOneActive, (state: StepState, { idStep }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idStep)
  })),

  on(StepActions.clearStore, () => initialState),

  on(StepActions.addManyStepProgressSuccess, (state: StepState, { idStep, idStepProgresses }) => {
    if (!state.entities[idStep]) {
      return state;
    }
    const stepProgresses = (state.entities[idStep]?.stepProgresses as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStep]: {
          ...state.entities[idStep],
          stepProgresses: stepProgresses.concat(idStepProgresses.filter(id => stepProgresses.indexOf(id) < 0))
        }
      }
    };
  }),

  on(StepActions.deleteManyStepProgressSuccess, (state: StepState, { idStepProgresses, idSteps }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idSteps.reduce((entities, idStep) => {
          if (!state.entities[idStep]?.stepProgresses) {
            return entities;
          }
          entities[idStep] = {
            ...state.entities[idStep],
            stepProgresses: (state.entities[idStep]?.stepProgresses as number[])?.filter(
              (idStepProgress: number) => !idStepProgresses.some((id: number) => id === idStepProgress)
            )
          } as StepEntityState;
          return entities;
        }, {} as Dictionary<StepEntityState>)
      }
    };
  }),

  on(StepActions.addManyOrganizationLeadTodoSuccess, (state: StepState, { idStep, idOrganizationLeadTodos }) => {
    if (!state.entities[idStep]) {
      return state;
    }
    const organizationLeadTodos = (state.entities[idStep]?.organizationLeadTodos as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStep]: {
          ...state.entities[idStep],
          organizationLeadTodos: organizationLeadTodos.concat(
            idOrganizationLeadTodos.filter(id => organizationLeadTodos.indexOf(id) < 0)
          )
        }
      }
    };
  }),

  on(StepActions.deleteManyOrganizationLeadTodoSuccess, (state: StepState, { idOrganizationLeadTodos, idSteps }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idSteps.reduce((entities, idStep) => {
          if (!state.entities[idStep]?.organizationLeadTodos) {
            return entities;
          }
          entities[idStep] = {
            ...state.entities[idStep],
            organizationLeadTodos: (state.entities[idStep]?.organizationLeadTodos as number[])?.filter(
              (idOrganizationLeadTodo: number) =>
                !idOrganizationLeadTodos.some((id: number) => id === idOrganizationLeadTodo)
            )
          } as StepEntityState;
          return entities;
        }, {} as Dictionary<StepEntityState>)
      }
    };
  }),

  on(
    StepActions.addManyOrganizationStratalotAvancementSuccess,
    (state: StepState, { idStep, idOrganizationStratalotAvancements }) => {
      if (!state.entities[idStep]) {
        return state;
      }
      const organizationStratalotAvancements =
        (state.entities[idStep]?.organizationStratalotAvancements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStep]: {
            ...state.entities[idStep],
            organizationStratalotAvancements: organizationStratalotAvancements.concat(
              idOrganizationStratalotAvancements.filter(id => organizationStratalotAvancements.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    StepActions.deleteManyOrganizationStratalotAvancementSuccess,
    (state: StepState, { idOrganizationStratalotAvancements, idSteps }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSteps.reduce((entities, idStep) => {
            if (!state.entities[idStep]?.organizationStratalotAvancements) {
              return entities;
            }
            entities[idStep] = {
              ...state.entities[idStep],
              organizationStratalotAvancements: (
                state.entities[idStep]?.organizationStratalotAvancements as number[]
              )?.filter(
                (idOrganizationStratalotAvancement: number) =>
                  !idOrganizationStratalotAvancements.some((id: number) => id === idOrganizationStratalotAvancement)
              )
            } as StepEntityState;
            return entities;
          }, {} as Dictionary<StepEntityState>)
        }
      };
    }
  ),

  on(
    StepActions.addManyOrganizationLeadAvancementSuccess,
    (state: StepState, { idStep, idOrganizationLeadAvancements }) => {
      if (!state.entities[idStep]) {
        return state;
      }
      const organizationLeadAvancements = (state.entities[idStep]?.organizationLeadAvancements as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStep]: {
            ...state.entities[idStep],
            organizationLeadAvancements: organizationLeadAvancements.concat(
              idOrganizationLeadAvancements.filter(id => organizationLeadAvancements.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    StepActions.deleteManyOrganizationLeadAvancementSuccess,
    (state: StepState, { idOrganizationLeadAvancements, idSteps }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSteps.reduce((entities, idStep) => {
            if (!state.entities[idStep]?.organizationLeadAvancements) {
              return entities;
            }
            entities[idStep] = {
              ...state.entities[idStep],
              organizationLeadAvancements: (state.entities[idStep]?.organizationLeadAvancements as number[])?.filter(
                (idOrganizationLeadAvancement: number) =>
                  !idOrganizationLeadAvancements.some((id: number) => id === idOrganizationLeadAvancement)
              )
            } as StepEntityState;
            return entities;
          }, {} as Dictionary<StepEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(state: StepState, isLoading: boolean, isLoaded: boolean = true): StepState {
  return { ...state, isLoaded, isLoading };
}
