import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ForecastPeriodApiService } from '@_services/api/forecast-period-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedForecastPeriodEffects } from './forecast-period-generated.effects';

@Injectable()
export class ForecastPeriodEffects extends GeneratedForecastPeriodEffects {
  constructor(actions$: Actions, forecastPeriodApiService: ForecastPeriodApiService, store$: Store<AppState>) {
    super(actions$, forecastPeriodApiService, store$);
  }
}
