import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationLeadTodoRuleApiService } from '@_services/api/organization-lead-todo-rule-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedOrganizationLeadTodoRuleEffects } from './organization-lead-todo-rule-generated.effects';

@Injectable()
export class OrganizationLeadTodoRuleEffects extends GeneratedOrganizationLeadTodoRuleEffects {
  constructor(
    actions$: Actions,
    organizationLeadTodoRuleApiService: OrganizationLeadTodoRuleApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationLeadTodoRuleApiService, store$);
  }
}
