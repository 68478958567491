import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationProspectOrigin,
  OrganizationProspectOriginEntityState
} from '@_model/interfaces/organization-prospect-origin.model';
import { OrganizationProspectOriginApiService } from '@_services/api/organization-prospect-origin-api.service';
import * as organizationProspectOriginActions from '@_store/organization-prospect-origin/organization-prospect-origin.actions';
import { getActionsToNormalizeOrganizationProspectOrigin } from '@_config/store/normalization.generated';
import { selectOrganizationProspectOriginState } from './organization-prospect-origin-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as prospectActions from '@_store/prospect/prospect.actions';
import { Prospect } from '@_model/interfaces/prospect.model';

export interface OrganizationProspectOriginRelationsIds {
  prospects?: number | number[];
  organization?: number;
}

export function getDefaultAddOrganizationProspectOriginActions(
  organizationProspectOrigin: OrganizationProspectOriginEntityState,
  ids?: OrganizationProspectOriginRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationProspectOriginActions.normalizeManyOrganizationProspectOriginsAfterUpsert({
      organizationProspectOrigins: [organizationProspectOrigin]
    })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationProspectOriginSuccess({
        idOrganization: ids.organization,
        idOrganizationProspectOrigins: [organizationProspectOrigin.idOrganizationProspectOrigin]
      })
    );
    actions.push(
      organizationProspectOriginActions.addOrganizationSuccess({
        idOrganizationProspectOrigin: organizationProspectOrigin.idOrganizationProspectOrigin,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.prospects) {
    if (!Array.isArray(ids.prospects)) {
      actions.push(
        prospectActions.upsertOneProspect({
          prospect: {
            idOrganizationProspectOrigin: organizationProspectOrigin.idOrganizationProspectOrigin,
            idProspect: ids.prospects as number
          } as Prospect
        })
      );
      actions.push(
        organizationProspectOriginActions.addManyProspectSuccess({
          idOrganizationProspectOrigin: organizationProspectOrigin.idOrganizationProspectOrigin,
          idProspects: [ids.prospects as number]
        })
      );
    } else {
      actions.push(
        prospectActions.upsertManyProspects({
          prospects: (ids.prospects as number[]).map((idProspect: number) => ({
            idOrganizationProspectOrigin: organizationProspectOrigin.idOrganizationProspectOrigin,
            idProspect
          })) as Prospect[]
        })
      );
      actions.push(
        organizationProspectOriginActions.addManyProspectSuccess({
          idOrganizationProspectOrigin: organizationProspectOrigin.idOrganizationProspectOrigin,
          idProspects: ids.prospects as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationProspectOriginActions(
  organizationProspectOrigin: OrganizationProspectOriginEntityState
): Action[] {
  const actions: Action[] = [
    organizationProspectOriginActions.deleteOneOrganizationProspectOriginSuccess({
      idOrganizationProspectOrigin: organizationProspectOrigin.idOrganizationProspectOrigin
    })
  ];

  if (organizationProspectOrigin.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationProspectOriginSuccess({
        idOrganizationProspectOrigins: [organizationProspectOrigin.idOrganizationProspectOrigin],
        idOrganizations: [organizationProspectOrigin.organization as number]
      })
    );
  }

  if (organizationProspectOrigin.prospects) {
    actions.push(
      prospectActions.deleteManyOrganizationProspectOriginSuccess({
        idOrganizationProspectOrigins: [organizationProspectOrigin.idOrganizationProspectOrigin],
        idProspects: organizationProspectOrigin.prospects as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationProspectOriginEffects {
  constructor(
    protected actions$: Actions,
    protected organizationProspectOriginApiService: OrganizationProspectOriginApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationProspectOrigins$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationProspectOriginActions.getManyOrganizationProspectOrigins),
      switchMap(({ params }) =>
        this.organizationProspectOriginApiService.getOrganizationProspectOrigins(params).pipe(
          map((organizationProspectOrigins: OrganizationProspectOrigin[]) => {
            return organizationProspectOriginActions.normalizeManyOrganizationProspectOriginsAfterUpsert({
              organizationProspectOrigins
            });
          }),
          catchError(error => of(organizationProspectOriginActions.organizationProspectOriginsFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationProspectOrigin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationProspectOriginActions.getOneOrganizationProspectOrigin),
      switchMap(idOrganizationProspectOrigin =>
        this.organizationProspectOriginApiService.getOrganizationProspectOrigin(idOrganizationProspectOrigin).pipe(
          map((organizationProspectOrigin: OrganizationProspectOrigin) => {
            return organizationProspectOriginActions.normalizeManyOrganizationProspectOriginsAfterUpsert({
              organizationProspectOrigins: [organizationProspectOrigin]
            });
          }),
          catchError(error => of(organizationProspectOriginActions.organizationProspectOriginsFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationProspectOrigin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationProspectOriginActions.upsertOneOrganizationProspectOrigin),
      concatMap(
        ({
          organizationProspectOrigin,
          ids
        }: {
          organizationProspectOrigin: Partial<OrganizationProspectOrigin>;
          ids?: OrganizationProspectOriginRelationsIds;
        }) => {
          if (organizationProspectOrigin.idOrganizationProspectOrigin) {
            return this.organizationProspectOriginApiService
              .updateOrganizationProspectOrigin(organizationProspectOrigin)
              .pipe(
                map((organizationProspectOriginReturned: OrganizationProspectOrigin) => {
                  return organizationProspectOriginActions.normalizeManyOrganizationProspectOriginsAfterUpsert({
                    organizationProspectOrigins: [organizationProspectOriginReturned]
                  });
                }),
                catchError(error => of(organizationProspectOriginActions.organizationProspectOriginsFailure({ error })))
              );
          } else {
            return this.organizationProspectOriginApiService
              .addOrganizationProspectOrigin(organizationProspectOrigin)
              .pipe(
                mergeMap((organizationProspectOriginReturned: OrganizationProspectOrigin) =>
                  getDefaultAddOrganizationProspectOriginActions(organizationProspectOriginReturned, ids)
                ),
                catchError(error => of(organizationProspectOriginActions.organizationProspectOriginsFailure({ error })))
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationProspectOrigin$ = createEffect(() => {
    const selectOrganizationProspectOriginState$ = this.store$.select(selectOrganizationProspectOriginState);
    return this.actions$.pipe(
      ofType(organizationProspectOriginActions.deleteOneOrganizationProspectOrigin),
      withLatestFrom(selectOrganizationProspectOriginState$),
      concatMap(([{ idOrganizationProspectOrigin }, state]) =>
        this.organizationProspectOriginApiService.deleteOrganizationProspectOrigin(idOrganizationProspectOrigin).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOrganizationProspectOriginActions(
                state.entities[idOrganizationProspectOrigin] as OrganizationProspectOriginEntityState
              ),
              organizationProspectOriginActions.deleteOneOrganizationProspectOrigin.type
            )
          ]),
          catchError(error => of(organizationProspectOriginActions.organizationProspectOriginsFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationProspectOriginsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationProspectOriginActions.normalizeManyOrganizationProspectOriginsAfterUpsert),
      concatMap(({ organizationProspectOrigins }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationProspectOrigin(
          organizationProspectOrigins,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationProspectOrigin] Normalization After Upsert Success')];
      })
    );
  });
}
