import { RepositoryService } from '@_services/api/repository.service';
import { StratalotPriceValue } from '@_model/interfaces/stratalot-price-value.model';
import { Observable } from 'rxjs';

export class GeneratedStratalotPriceValueApiService {
  constructor(protected repo: RepositoryService) {}

  public getStratalotPriceValues(params?: any): Observable<StratalotPriceValue[]> {
    return this.repo.getData<StratalotPriceValue[]>('stratalot-price-value', params);
  }

  public getStratalotPriceValue(params: {
    idStratalotPriceValue: number;
    params?: any;
  }): Observable<StratalotPriceValue> {
    return this.repo.getData<StratalotPriceValue>(
      'stratalot-price-value/' + params.idStratalotPriceValue,
      params.params
    );
  }

  public addStratalotPriceValue(stratalotPriceValue: Partial<StratalotPriceValue>): Observable<StratalotPriceValue> {
    return this.repo.create<StratalotPriceValue>('stratalot-price-value', stratalotPriceValue);
  }

  public updateStratalotPriceValue(stratalotPriceValue: Partial<StratalotPriceValue>): Observable<StratalotPriceValue> {
    return this.repo.update('stratalot-price-value', stratalotPriceValue);
  }

  public deleteStratalotPriceValue(idStratalotPriceValue: number): Observable<number> {
    return this.repo.delete('stratalot-price-value/' + +idStratalotPriceValue);
  }
}
