import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationLeadAvancementEntityState } from '@_model/interfaces/organization-lead-avancement.model';

export interface OrganizationLeadAvancementState extends EntityState<OrganizationLeadAvancementEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationLeadAvancementEntityState> =
  createEntityAdapter<OrganizationLeadAvancementEntityState>({
    selectId: o => o.idOrganizationLeadAvancement
  });
export const initialState: OrganizationLeadAvancementState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationLeadAvancementFeatureKey = 'organizationLeadAvancement';
