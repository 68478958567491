export enum DashboardStockAgGridRowType {
  enMontage = 'En montage',
  enEtude = "A l'étude",
  enProjet = 'En projet',
  enVente = 'Total intermédiaire (En vente)',
  enVenteOccupe = 'En vente occupé',
  enVenteLibre = 'En vente libre',
  enVenteBloque = 'En vente bloque',
  suspendu = 'Suspendu',
  termine = 'Terminé (fin mandat-marché)',
  total = 'Grand total',
  vendu = 'Vendu',
  abandonne = 'Abandonné'
}
