import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  CompanyCommunicationResidenceTodo,
  CompanyCommunicationResidenceTodoEntityState
} from '@_model/interfaces/company-communication-residence-todo.model';
import { CompanyCommunicationResidenceTodoRelationsIds } from './company-communication-residence-todo-generated.effects';

export const getOneCompanyCommunicationResidenceTodo = createAction(
  '[CompanyCommunicationResidenceTodo] Get One CompanyCommunicationResidenceTodo',
  props<{ idCompanyCommunicationResidenceTodo: number; params?: any }>()
);

export const getManyCompanyCommunicationResidenceTodos = createAction(
  '[CompanyCommunicationResidenceTodo] Get Many CompanyCommunicationResidenceTodos',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyCommunicationResidenceTodo] Add Many Actives CompanyCommunicationResidenceTodo',
  props<{ idCompanyCommunicationResidenceTodos: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyCommunicationResidenceTodo] Delete One Active CompanyCommunicationResidenceTodo',
  props<{ idCompanyCommunicationResidenceTodo: number }>()
);

export const clearActive = createAction(
  '[CompanyCommunicationResidenceTodo] Clear Active CompanyCommunicationResidenceTodo'
);

export const upsertOneCompanyCommunicationResidenceTodo = createAction(
  '[CompanyCommunicationResidenceTodo] Upsert One CompanyCommunicationResidenceTodo',
  props<{
    companyCommunicationResidenceTodo: Partial<CompanyCommunicationResidenceTodo>;
    ids?: CompanyCommunicationResidenceTodoRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationResidenceTodos = createAction(
  '[CompanyCommunicationResidenceTodo] Upsert Many CompanyCommunicationResidenceTodos',
  props<{
    companyCommunicationResidenceTodos: Partial<CompanyCommunicationResidenceTodo>[];
    ids?: CompanyCommunicationResidenceTodoRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationResidenceTodosSuccess = createAction(
  '[CompanyCommunicationResidenceTodo] Create Many CompanyCommunicationResidenceTodos Success',
  props<{ companyCommunicationResidenceTodos: CompanyCommunicationResidenceTodoEntityState[] }>()
);

export const deleteOneCompanyCommunicationResidenceTodo = createAction(
  '[CompanyCommunicationResidenceTodo] Delete One CompanyCommunicationResidenceTodo',
  props<{ idCompanyCommunicationResidenceTodo: number }>()
);

export const deleteOneCompanyCommunicationResidenceTodoSuccess = createAction(
  '[CompanyCommunicationResidenceTodo] Delete One CompanyCommunicationResidenceTodo Success',
  props<{ idCompanyCommunicationResidenceTodo: number }>()
);

export const normalizeManyCompanyCommunicationResidenceTodosAfterUpsert = createAction(
  '[CompanyCommunicationResidenceTodo] Normalize Many CompanyCommunicationResidenceTodos After Upsert',
  props<{ companyCommunicationResidenceTodos: CompanyCommunicationResidenceTodoEntityState[] }>()
);

export const companyCommunicationResidenceTodosFailure = createAction(
  '[CompanyCommunicationResidenceTodo] CompanyCommunicationResidenceTodos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyCommunicationResidenceTodo] Clear CompanyCommunicationResidenceTodos');

export const addCompanyCommunicationSuccess = createAction(
  '[CompanyCommunicationResidenceTodo] Add CompanyCommunication',
  props<{ idCompanyCommunicationResidenceTodo: number; idCompanyCommunication: number }>()
);

export const deleteManyCompanyCommunicationSuccess = createAction(
  '[CompanyCommunicationResidenceTodo] Delete Many CompanyCommunication',
  props<{ idCompanyCommunications: number[]; idCompanyCommunicationResidenceTodos: number[] }>()
);

export const addOrganizationResidenceTodoSuccess = createAction(
  '[CompanyCommunicationResidenceTodo] Add OrganizationResidenceTodo',
  props<{ idCompanyCommunicationResidenceTodo: number; idOrganizationResidenceTodo: number }>()
);

export const deleteManyOrganizationResidenceTodoSuccess = createAction(
  '[CompanyCommunicationResidenceTodo] Delete Many OrganizationResidenceTodo',
  props<{ idOrganizationResidenceTodos: number[]; idCompanyCommunicationResidenceTodos: number[] }>()
);
