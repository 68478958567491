import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ProspectBuyingWishActions from './prospect-buying-wish.actions';
import { adapter, initialState, ProspectBuyingWishState } from './prospect-buying-wish.state';
import { Dictionary } from '@ngrx/entity';
import { ProspectBuyingWishEntityState } from '@_model/interfaces/prospect-buying-wish.model';

export const prospectBuyingWishReducersGeneratedFunctions: ReducerTypes<
  ProspectBuyingWishState,
  readonly ActionCreator[]
>[] = [
  on(ProspectBuyingWishActions.getOneProspectBuyingWish, (state: ProspectBuyingWishState) =>
    setLoadingsState(state, true)
  ),
  on(ProspectBuyingWishActions.getManyProspectBuyingWishs, (state: ProspectBuyingWishState) =>
    setLoadingsState(state, true)
  ),
  on(ProspectBuyingWishActions.upsertOneProspectBuyingWish, (state: ProspectBuyingWishState) =>
    setLoadingsState(state, true)
  ),

  on(
    ProspectBuyingWishActions.upsertManyProspectBuyingWishsSuccess,
    (state: ProspectBuyingWishState, { prospectBuyingWishs }) =>
      adapter.upsertMany(prospectBuyingWishs, setLoadingsState(state, false))
  ),
  on(ProspectBuyingWishActions.deleteOneProspectBuyingWish, (state: ProspectBuyingWishState) =>
    setLoadingsState(state, true)
  ),
  on(
    ProspectBuyingWishActions.deleteOneProspectBuyingWishSuccess,
    (state: ProspectBuyingWishState, { idProspectBuyingWish }) =>
      adapter.removeOne(idProspectBuyingWish, setLoadingsState(state, false))
  ),
  on(ProspectBuyingWishActions.clearActive, (state: ProspectBuyingWishState) => ({ ...state, actives: [] })),
  on(ProspectBuyingWishActions.addManyActives, (state: ProspectBuyingWishState, { idProspectBuyingWishs }) => ({
    ...state,
    actives: state.actives.concat(idProspectBuyingWishs)
  })),
  on(ProspectBuyingWishActions.deleteOneActive, (state: ProspectBuyingWishState, { idProspectBuyingWish }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idProspectBuyingWish)
  })),

  on(ProspectBuyingWishActions.clearStore, () => initialState),

  on(
    ProspectBuyingWishActions.addManyCitySuccess,
    (state: ProspectBuyingWishState, { idProspectBuyingWish, idCities }) => {
      if (!state.entities[idProspectBuyingWish]) {
        return state;
      }
      const cities = (state.entities[idProspectBuyingWish]?.cities as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProspectBuyingWish]: {
            ...state.entities[idProspectBuyingWish],
            cities: cities.concat(idCities.filter(id => cities.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    ProspectBuyingWishActions.deleteManyCitySuccess,
    (state: ProspectBuyingWishState, { idCities, idProspectBuyingWishs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProspectBuyingWishs.reduce((entities, idProspectBuyingWish) => {
            if (!state.entities[idProspectBuyingWish]?.cities) {
              return entities;
            }
            entities[idProspectBuyingWish] = {
              ...state.entities[idProspectBuyingWish],
              cities: (state.entities[idProspectBuyingWish]?.cities as number[])?.filter(
                (idCity: number) => !idCities.some((id: number) => id === idCity)
              )
            } as ProspectBuyingWishEntityState;
            return entities;
          }, {} as Dictionary<ProspectBuyingWishEntityState>)
        }
      };
    }
  ),

  on(
    ProspectBuyingWishActions.addManyResidenceSuccess,
    (state: ProspectBuyingWishState, { idProspectBuyingWish, idResidences }) => {
      if (!state.entities[idProspectBuyingWish]) {
        return state;
      }
      const residences = (state.entities[idProspectBuyingWish]?.residences as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProspectBuyingWish]: {
            ...state.entities[idProspectBuyingWish],
            residences: residences.concat(idResidences.filter(id => residences.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    ProspectBuyingWishActions.deleteManyResidenceSuccess,
    (state: ProspectBuyingWishState, { idResidences, idProspectBuyingWishs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProspectBuyingWishs.reduce((entities, idProspectBuyingWish) => {
            if (!state.entities[idProspectBuyingWish]?.residences) {
              return entities;
            }
            entities[idProspectBuyingWish] = {
              ...state.entities[idProspectBuyingWish],
              residences: (state.entities[idProspectBuyingWish]?.residences as number[])?.filter(
                (idResidence: number) => !idResidences.some((id: number) => id === idResidence)
              )
            } as ProspectBuyingWishEntityState;
            return entities;
          }, {} as Dictionary<ProspectBuyingWishEntityState>)
        }
      };
    }
  ),

  on(
    ProspectBuyingWishActions.addManyStratalotTypeSuccess,
    (state: ProspectBuyingWishState, { idProspectBuyingWish, idStratalotTypes }) => {
      if (!state.entities[idProspectBuyingWish]) {
        return state;
      }
      const stratalotTypes = (state.entities[idProspectBuyingWish]?.stratalotTypes as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProspectBuyingWish]: {
            ...state.entities[idProspectBuyingWish],
            stratalotTypes: stratalotTypes.concat(idStratalotTypes.filter(id => stratalotTypes.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    ProspectBuyingWishActions.deleteManyStratalotTypeSuccess,
    (state: ProspectBuyingWishState, { idStratalotTypes, idProspectBuyingWishs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProspectBuyingWishs.reduce((entities, idProspectBuyingWish) => {
            if (!state.entities[idProspectBuyingWish]?.stratalotTypes) {
              return entities;
            }
            entities[idProspectBuyingWish] = {
              ...state.entities[idProspectBuyingWish],
              stratalotTypes: (state.entities[idProspectBuyingWish]?.stratalotTypes as number[])?.filter(
                (idStratalotType: number) => !idStratalotTypes.some((id: number) => id === idStratalotType)
              )
            } as ProspectBuyingWishEntityState;
            return entities;
          }, {} as Dictionary<ProspectBuyingWishEntityState>)
        }
      };
    }
  ),

  on(
    ProspectBuyingWishActions.addProspectSuccess,
    (state: ProspectBuyingWishState, { idProspectBuyingWish, idProspect }) => {
      if (!state.entities[idProspectBuyingWish]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProspectBuyingWish]: {
            ...state.entities[idProspectBuyingWish],
            prospect: idProspect
          }
        }
      };
    }
  ),

  on(
    ProspectBuyingWishActions.deleteManyProspectSuccess,
    (state: ProspectBuyingWishState, { idProspects, idProspectBuyingWishs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProspectBuyingWishs.reduce((entities, idProspectBuyingWish) => {
            if (!state.entities[idProspectBuyingWish]?.prospect) {
              return entities;
            }
            entities[idProspectBuyingWish] = {
              ...state.entities[idProspectBuyingWish],
              prospect: idProspects.some(
                (idProspect: number) => idProspect === state.entities[idProspectBuyingWish]?.prospect
              )
                ? undefined
                : state.entities[idProspectBuyingWish]?.prospect
            } as ProspectBuyingWishEntityState;
            return entities;
          }, {} as Dictionary<ProspectBuyingWishEntityState>)
        }
      };
    }
  ),

  on(
    ProspectBuyingWishActions.addOrganizationBuyingWishSuccess,
    (state: ProspectBuyingWishState, { idProspectBuyingWish, idOrganizationBuyingWish }) => {
      if (!state.entities[idProspectBuyingWish]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProspectBuyingWish]: {
            ...state.entities[idProspectBuyingWish],
            organizationBuyingWish: idOrganizationBuyingWish
          }
        }
      };
    }
  ),

  on(
    ProspectBuyingWishActions.deleteManyOrganizationBuyingWishSuccess,
    (state: ProspectBuyingWishState, { idOrganizationBuyingWishes, idProspectBuyingWishs }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProspectBuyingWishs.reduce((entities, idProspectBuyingWish) => {
            if (!state.entities[idProspectBuyingWish]?.organizationBuyingWish) {
              return entities;
            }
            entities[idProspectBuyingWish] = {
              ...state.entities[idProspectBuyingWish],
              organizationBuyingWish: idOrganizationBuyingWishes.some(
                (idOrganizationBuyingWish: number) =>
                  idOrganizationBuyingWish === state.entities[idProspectBuyingWish]?.organizationBuyingWish
              )
                ? undefined
                : state.entities[idProspectBuyingWish]?.organizationBuyingWish
            } as ProspectBuyingWishEntityState;
            return entities;
          }, {} as Dictionary<ProspectBuyingWishEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ProspectBuyingWishState,
  isLoading: boolean,
  isLoaded: boolean = true
): ProspectBuyingWishState {
  return { ...state, isLoaded, isLoading };
}
