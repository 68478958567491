import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ResidenceStudyCriteriaApiService } from '@_services/api/residence-study-criteria-api.service';
import { GeneratedResidenceStudyCriteriaEffects } from './residence-study-criteria-generated.effects';

@Injectable()
export class ResidenceStudyCriteriaEffects extends GeneratedResidenceStudyCriteriaEffects {
  constructor(
    actions$: Actions,
    residenceStudyCriteriaApiService: ResidenceStudyCriteriaApiService,
    store$: Store<AppState>
  ) {
    super(actions$, residenceStudyCriteriaApiService, store$);
  }
}
