import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyMyNotaryActions from './company-my-notary.actions';
import { adapter, initialState, CompanyMyNotaryState } from './company-my-notary.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyMyNotaryEntityState } from '@_model/interfaces/company-my-notary.model';

export const companyMyNotaryReducersGeneratedFunctions: ReducerTypes<CompanyMyNotaryState, readonly ActionCreator[]>[] =
  [
    on(CompanyMyNotaryActions.getOneCompanyMyNotary, (state: CompanyMyNotaryState) => setLoadingsState(state, true)),
    on(CompanyMyNotaryActions.getManyCompanyMyNotaries, (state: CompanyMyNotaryState) => setLoadingsState(state, true)),
    on(CompanyMyNotaryActions.upsertOneCompanyMyNotary, (state: CompanyMyNotaryState) => setLoadingsState(state, true)),

    on(
      CompanyMyNotaryActions.upsertManyCompanyMyNotariesSuccess,
      (state: CompanyMyNotaryState, { companyMyNotaries }) =>
        adapter.upsertMany(companyMyNotaries, setLoadingsState(state, false))
    ),
    on(CompanyMyNotaryActions.deleteOneCompanyMyNotary, (state: CompanyMyNotaryState) => setLoadingsState(state, true)),
    on(CompanyMyNotaryActions.deleteOneCompanyMyNotarySuccess, (state: CompanyMyNotaryState, { idCompanyMyNotary }) =>
      adapter.removeOne(idCompanyMyNotary, setLoadingsState(state, false))
    ),
    on(CompanyMyNotaryActions.clearActive, (state: CompanyMyNotaryState) => ({ ...state, actives: [] })),
    on(CompanyMyNotaryActions.addManyActives, (state: CompanyMyNotaryState, { idCompanyMyNotaries }) => ({
      ...state,
      actives: state.actives.concat(idCompanyMyNotaries)
    })),
    on(CompanyMyNotaryActions.deleteOneActive, (state: CompanyMyNotaryState, { idCompanyMyNotary }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCompanyMyNotary)
    })),

    on(CompanyMyNotaryActions.clearStore, () => initialState),

    on(CompanyMyNotaryActions.addCompanySuccess, (state: CompanyMyNotaryState, { idCompanyMyNotary, idCompany }) => {
      if (!state.entities[idCompanyMyNotary]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyMyNotary]: {
            ...state.entities[idCompanyMyNotary],
            company: idCompany
          }
        }
      };
    }),

    on(
      CompanyMyNotaryActions.deleteManyCompanySuccess,
      (state: CompanyMyNotaryState, { idCompanies, idCompanyMyNotaries }) => {
        return {
          ...state,
          entities: {
            ...state.entities,
            ...idCompanyMyNotaries.reduce((entities, idCompanyMyNotary) => {
              if (!state.entities[idCompanyMyNotary]?.company) {
                return entities;
              }
              entities[idCompanyMyNotary] = {
                ...state.entities[idCompanyMyNotary],
                company: idCompanies.some(
                  (idCompany: number) => idCompany === state.entities[idCompanyMyNotary]?.company
                )
                  ? undefined
                  : state.entities[idCompanyMyNotary]?.company
              } as CompanyMyNotaryEntityState;
              return entities;
            }, {} as Dictionary<CompanyMyNotaryEntityState>)
          }
        };
      }
    )
  ];

export function setLoadingsState(
  state: CompanyMyNotaryState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyMyNotaryState {
  return { ...state, isLoaded, isLoading };
}
