import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { CompanyRelationsIds } from './company-generated.effects';

export const getOneCompany = createAction('[Company] Get One Company', props<{ idCompany: number; params?: any }>());

export const getManyCompanies = createAction('[Company] Get Many Companies', props<{ params: any }>());

export const addManyActives = createAction('[Company] Add Many Actives Company', props<{ idCompanies: number[] }>());

export const deleteOneActive = createAction('[Company] Delete One Active Company', props<{ idCompany: number }>());

export const clearActive = createAction('[Company] Clear Active Company');

export const upsertOneCompany = createAction(
  '[Company] Upsert One Company',
  props<{
    company: Partial<Company>;
    ids?: CompanyRelationsIds;
  }>()
);

export const upsertManyCompanies = createAction(
  '[Company] Upsert Many Companies',
  props<{
    companies: Partial<Company>[];
    ids?: CompanyRelationsIds;
  }>()
);

export const upsertManyCompaniesSuccess = createAction(
  '[Company] Create Many Companies Success',
  props<{ companies: CompanyEntityState[] }>()
);

export const deleteOneCompany = createAction('[Company] Delete One Company', props<{ idCompany: number }>());

export const deleteOneCompanySuccess = createAction(
  '[Company] Delete One Company Success',
  props<{ idCompany: number }>()
);

export const normalizeManyCompaniesAfterUpsert = createAction(
  '[Company] Normalize Many Companies After Upsert',
  props<{ companies: CompanyEntityState[] }>()
);

export const companiesFailure = createAction('[Company] Companies Failure', props<{ error: HttpErrorResponse }>());

export const clearStore = createAction('[Company] Clear Companies');

export const addManyOccupantSuccess = createAction(
  '[Company] Add Many occupant',
  props<{ idCompany: number; idOccupants: number[] }>()
);

export const deleteManyOccupantSuccess = createAction(
  '[Company] Delete Many Occupants',
  props<{ idOccupants: number[]; idCompanies: number[] }>()
);

export const addManyCompanyMediaSuccess = createAction(
  '[Company] Add Many company-media',
  props<{ idCompany: number; idCompanyMedias: number[] }>()
);

export const deleteManyCompanyMediaSuccess = createAction(
  '[Company] Delete Many CompanyMedias',
  props<{ idCompanyMedias: number[]; idCompanies: number[] }>()
);

export const addManyCitySuccess = createAction(
  '[Company] Add Many city',
  props<{ idCompany: number; idCities: number[] }>()
);

export const deleteManyCitySuccess = createAction(
  '[Company] Delete Many Cities',
  props<{ idCities: number[]; idCompanies: number[] }>()
);

export const addManyCompanyCommunicationSuccess = createAction(
  '[Company] Add Many company-communication',
  props<{ idCompany: number; idCompanyCommunications: number[] }>()
);

export const deleteManyCompanyCommunicationSuccess = createAction(
  '[Company] Delete Many CompanyCommunications',
  props<{ idCompanyCommunications: number[]; idCompanies: number[] }>()
);

export const addManyResidenceSuccess = createAction(
  '[Company] Add Many residence',
  props<{ idCompany: number; idResidences: number[] }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[Company] Delete Many Residences',
  props<{ idResidences: number[]; idCompanies: number[] }>()
);

export const addManyCompanyPriceLabelSuccess = createAction(
  '[Company] Add Many company-price-label',
  props<{ idCompany: number; idCompanyPriceLabels: number[] }>()
);

export const deleteManyCompanyPriceLabelSuccess = createAction(
  '[Company] Delete Many CompanyPriceLabels',
  props<{ idCompanyPriceLabels: number[]; idCompanies: number[] }>()
);

export const addManyCompanyTerritoireSuccess = createAction(
  '[Company] Add Many company-territoire',
  props<{ idCompany: number; idCompanyTerritoires: number[] }>()
);

export const deleteManyCompanyTerritoireSuccess = createAction(
  '[Company] Delete Many CompanyTerritoires',
  props<{ idCompanyTerritoires: number[]; idCompanies: number[] }>()
);

export const addManyOrganizationLeadTodoRuleSuccess = createAction(
  '[Company] Add Many organization-lead-todo-rule',
  props<{ idCompany: number; idOrganizationLeadTodoRules: number[] }>()
);

export const deleteManyOrganizationLeadTodoRuleSuccess = createAction(
  '[Company] Delete Many OrganizationLeadTodoRules',
  props<{ idOrganizationLeadTodoRules: number[]; idCompanies: number[] }>()
);

export const addManyOrganizationStratalotTodoRuleSuccess = createAction(
  '[Company] Add Many organization-stratalot-todo-rule',
  props<{ idCompany: number; idOrganizationStratalotTodoRules: number[] }>()
);

export const deleteManyOrganizationStratalotTodoRuleSuccess = createAction(
  '[Company] Delete Many OrganizationStratalotTodoRules',
  props<{ idOrganizationStratalotTodoRules: number[]; idCompanies: number[] }>()
);

export const addManyCompanyStratalotTypeSuccess = createAction(
  '[Company] Add Many company-stratalot-type',
  props<{ idCompany: number; idCompanyStratalotTypes: number[] }>()
);

export const deleteManyCompanyStratalotTypeSuccess = createAction(
  '[Company] Delete Many CompanyStratalotTypes',
  props<{ idCompanyStratalotTypes: number[]; idCompanies: number[] }>()
);

export const addManyOrganizationResidenceTodoRuleSuccess = createAction(
  '[Company] Add Many organization-residence-todo-rule',
  props<{ idCompany: number; idOrganizationResidenceTodoRules: number[] }>()
);

export const deleteManyOrganizationResidenceTodoRuleSuccess = createAction(
  '[Company] Delete Many OrganizationResidenceTodoRules',
  props<{ idOrganizationResidenceTodoRules: number[]; idCompanies: number[] }>()
);

export const addManyCompanyStratalotVacantSuccess = createAction(
  '[Company] Add Many company-stratalot-vacant',
  props<{ idCompany: number; idCompanyStratalotVacants: number[] }>()
);

export const deleteManyCompanyStratalotVacantSuccess = createAction(
  '[Company] Delete Many CompanyStratalotVacants',
  props<{ idCompanyStratalotVacants: number[]; idCompanies: number[] }>()
);

export const addManyGeneratedDocumentsCompanySuccess = createAction(
  '[Company] Add Many generated-documents-company',
  props<{ idCompany: number; idGeneratedDocumentsCompanies: number[] }>()
);

export const deleteManyGeneratedDocumentsCompanySuccess = createAction(
  '[Company] Delete Many GeneratedDocumentsCompanies',
  props<{ idGeneratedDocumentsCompanies: number[]; idCompanies: number[] }>()
);

export const addManyGeneratedDocumentSuccess = createAction(
  '[Company] Add Many generated-document',
  props<{ idCompany: number; idGeneratedDocuments: number[] }>()
);

export const deleteManyGeneratedDocumentSuccess = createAction(
  '[Company] Delete Many GeneratedDocuments',
  props<{ idGeneratedDocuments: number[]; idCompanies: number[] }>()
);

export const addManyCompanyUbiflowSuccess = createAction(
  '[Company] Add Many company-ubiflow',
  props<{ idCompany: number; idCompanyUbiflows: number[] }>()
);

export const deleteManyCompanyUbiflowSuccess = createAction(
  '[Company] Delete Many CompanyUbiflows',
  props<{ idCompanyUbiflows: number[]; idCompanies: number[] }>()
);

export const addManyCompanyMyNotarySuccess = createAction(
  '[Company] Add Many company-my-notary',
  props<{ idCompany: number; idCompanyMyNotaries: number[] }>()
);

export const deleteManyCompanyMyNotarySuccess = createAction(
  '[Company] Delete Many CompanyMyNotaries',
  props<{ idCompanyMyNotaries: number[]; idCompanies: number[] }>()
);

export const addManyCompanyStudyNextActionSuccess = createAction(
  '[Company] Add Many company-study-next-action',
  props<{ idCompany: number; idCompanyStudyNextActions: number[] }>()
);

export const deleteManyCompanyStudyNextActionSuccess = createAction(
  '[Company] Delete Many CompanyStudyNextActions',
  props<{ idCompanyStudyNextActions: number[]; idCompanies: number[] }>()
);

export const addManyCompanyStudyConclusionSuccess = createAction(
  '[Company] Add Many company-study-conclusion',
  props<{ idCompany: number; idCompanyStudyConclusions: number[] }>()
);

export const deleteManyCompanyStudyConclusionSuccess = createAction(
  '[Company] Delete Many CompanyStudyConclusions',
  props<{ idCompanyStudyConclusions: number[]; idCompanies: number[] }>()
);

export const addManyCompanyStudyReasonSuccess = createAction(
  '[Company] Add Many company-study-reason',
  props<{ idCompany: number; idCompanyStudyReasons: number[] }>()
);

export const deleteManyCompanyStudyReasonSuccess = createAction(
  '[Company] Delete Many CompanyStudyReasons',
  props<{ idCompanyStudyReasons: number[]; idCompanies: number[] }>()
);

export const addManyCompanyStudyCriteriaSuccess = createAction(
  '[Company] Add Many company-study-criteria',
  props<{ idCompany: number; idCompanyStudyCriterias: number[] }>()
);

export const deleteManyCompanyStudyCriteriaSuccess = createAction(
  '[Company] Delete Many CompanyStudyCriterias',
  props<{ idCompanyStudyCriterias: number[]; idCompanies: number[] }>()
);

export const addManyResidenceStudySuccess = createAction(
  '[Company] Add Many residence-study',
  props<{ idCompany: number; idResidenceStudies: number[] }>()
);

export const deleteManyResidenceStudySuccess = createAction(
  '[Company] Delete Many ResidenceStudies',
  props<{ idResidenceStudies: number[]; idCompanies: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[Company] Add Organization',
  props<{ idCompany: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[Company] Delete Many Organization',
  props<{ idOrganizations: number[]; idCompanies: number[] }>()
);
