import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { User, UserEntityState } from '@_model/interfaces/user.model';
import { UserApiService } from '@_services/api/user-api.service';
import * as userActions from '@_store/user/user.actions';
import { getActionsToNormalizeUser } from '@_config/store/normalization.generated';
import { selectUserState } from './user-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as boardStateActions from '@_store/board-state/board-state.actions';
import { BoardState } from '@_model/interfaces/board-state.model';
import * as companyTerritoireUserActions from '@_store/company-territoire-user/company-territoire-user.actions';
import { CompanyTerritoireUser } from '@_model/interfaces/company-territoire-user.model';
import * as residencePriceGridHistoryActions from '@_store/residence-price-grid-history/residence-price-grid-history.actions';
import { ResidencePriceGridHistory } from '@_model/interfaces/residence-price-grid-history.model';
import * as companyTerritoireActions from '@_store/company-territoire/company-territoire.actions';
import { CompanyTerritoire } from '@_model/interfaces/company-territoire.model';
import * as prospectActions from '@_store/prospect/prospect.actions';
import { Prospect } from '@_model/interfaces/prospect.model';
import * as residenceActions from '@_store/residence/residence.actions';
import { Residence } from '@_model/interfaces/residence.model';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import { Stratalot } from '@_model/interfaces/stratalot.model';
import * as prospectEventActions from '@_store/prospect-event/prospect-event.actions';
import { ProspectEvent } from '@_model/interfaces/prospect-event.model';
import * as profilActions from '@_store/profil/profil.actions';
import { Profil } from '@_model/interfaces/profil.model';
import * as companyCommunicationRecipientActions from '@_store/company-communication-recipient/company-communication-recipient.actions';
import { CompanyCommunicationRecipient } from '@_model/interfaces/company-communication-recipient.model';
import * as companyCommunicationRecipientUserActions from '@_store/company-communication-recipient-user/company-communication-recipient-user.actions';
import { CompanyCommunicationRecipientUser } from '@_model/interfaces/company-communication-recipient-user.model';

export interface UserRelationsIds {
  boardStates?: number | number[];
  companyTerritoireUsers?: number | number[];
  residencePriceGridHistories?: number | number[];
  companyTerritoires?: number | number[];
  prospectResponsibleOfs?: number | number[];
  prospectUpdaters?: number | number[];
  prospectCreators?: number | number[];
  residences?: number | number[];
  stratalots?: number | number[];
  prospectEvents?: number | number[];
  profils?: number | number[];
  companyCommunicationRecipients?: number | number[];
  companyCommunicationRecipientUsers?: number | number[];
  organization?: number;
}

export function getDefaultAddUserActions(user: UserEntityState, ids?: UserRelationsIds): Action[] {
  const actions: Action[] = [userActions.normalizeManyUsersAfterUpsert({ users: [user] })];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyUserSuccess({
        idOrganization: ids.organization,
        idUsers: [user.idUser]
      })
    );
    actions.push(
      userActions.addOrganizationSuccess({
        idUser: user.idUser,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.boardStates) {
    if (!Array.isArray(ids.boardStates)) {
      actions.push(
        boardStateActions.upsertOneBoardState({
          boardState: {
            idUser: user.idUser,
            idBoardState: ids.boardStates as number
          } as BoardState
        })
      );
      actions.push(
        userActions.addManyBoardStateSuccess({
          idUser: user.idUser,
          idBoardStates: [ids.boardStates as number]
        })
      );
    } else {
      actions.push(
        boardStateActions.upsertManyBoardStates({
          boardStates: (ids.boardStates as number[]).map((idBoardState: number) => ({
            idUser: user.idUser,
            idBoardState
          })) as BoardState[]
        })
      );
      actions.push(
        userActions.addManyBoardStateSuccess({
          idUser: user.idUser,
          idBoardStates: ids.boardStates as number[]
        })
      );
    }
  }

  if (ids?.companyTerritoireUsers) {
    if (!Array.isArray(ids.companyTerritoireUsers)) {
      actions.push(
        companyTerritoireUserActions.upsertOneCompanyTerritoireUser({
          companyTerritoireUser: {
            idUser: user.idUser,
            idCompanyTerritoireUser: ids.companyTerritoireUsers as number
          } as CompanyTerritoireUser
        })
      );
      actions.push(
        userActions.addManyCompanyTerritoireUserSuccess({
          idUser: user.idUser,
          idCompanyTerritoireUsers: [ids.companyTerritoireUsers as number]
        })
      );
    } else {
      actions.push(
        companyTerritoireUserActions.upsertManyCompanyTerritoireUsers({
          companyTerritoireUsers: (ids.companyTerritoireUsers as number[]).map((idCompanyTerritoireUser: number) => ({
            idUser: user.idUser,
            idCompanyTerritoireUser
          })) as CompanyTerritoireUser[]
        })
      );
      actions.push(
        userActions.addManyCompanyTerritoireUserSuccess({
          idUser: user.idUser,
          idCompanyTerritoireUsers: ids.companyTerritoireUsers as number[]
        })
      );
    }
  }

  if (ids?.residencePriceGridHistories) {
    if (!Array.isArray(ids.residencePriceGridHistories)) {
      actions.push(
        residencePriceGridHistoryActions.upsertOneResidencePriceGridHistory({
          residencePriceGridHistory: {
            idUser: user.idUser,
            idResidencePriceGridHistory: ids.residencePriceGridHistories as number
          } as ResidencePriceGridHistory
        })
      );
      actions.push(
        userActions.addManyResidencePriceGridHistorySuccess({
          idUser: user.idUser,
          idResidencePriceGridHistories: [ids.residencePriceGridHistories as number]
        })
      );
    } else {
      actions.push(
        residencePriceGridHistoryActions.upsertManyResidencePriceGridHistories({
          residencePriceGridHistories: (ids.residencePriceGridHistories as number[]).map(
            (idResidencePriceGridHistory: number) => ({
              idUser: user.idUser,
              idResidencePriceGridHistory
            })
          ) as ResidencePriceGridHistory[]
        })
      );
      actions.push(
        userActions.addManyResidencePriceGridHistorySuccess({
          idUser: user.idUser,
          idResidencePriceGridHistories: ids.residencePriceGridHistories as number[]
        })
      );
    }
  }

  if (ids?.companyTerritoires) {
    if (!Array.isArray(ids.companyTerritoires)) {
      actions.push(
        companyTerritoireActions.upsertOneCompanyTerritoire({
          companyTerritoire: {
            idResponsable: user.idUser,
            idCompanyTerritoire: ids.companyTerritoires as number
          } as CompanyTerritoire
        })
      );
      actions.push(
        userActions.addManyCompanyTerritoireSuccess({
          idUser: user.idUser,
          idCompanyTerritoire: [ids.companyTerritoires as number]
        })
      );
    } else {
      actions.push(
        companyTerritoireActions.upsertManyCompanyTerritoires({
          companyTerritoires: (ids.companyTerritoires as number[]).map((idCompanyTerritoire: number) => ({
            idResponsable: user.idUser,
            idCompanyTerritoire
          })) as CompanyTerritoire[]
        })
      );
      actions.push(
        userActions.addManyCompanyTerritoireSuccess({
          idUser: user.idUser,
          idCompanyTerritoire: ids.companyTerritoires as number[]
        })
      );
    }
  }

  if (ids?.prospectResponsibleOfs) {
    if (!Array.isArray(ids.prospectResponsibleOfs)) {
      actions.push(
        prospectActions.upsertOneProspect({
          prospect: {
            idResponsable: user.idUser,
            idProspect: ids.prospectResponsibleOfs as number
          } as Prospect
        })
      );
      actions.push(
        userActions.addManyProspectResponsibleOfSuccess({
          idUser: user.idUser,
          idProspectResponsibleOf: [ids.prospectResponsibleOfs as number]
        })
      );
    } else {
      actions.push(
        prospectActions.upsertManyProspects({
          prospects: (ids.prospectResponsibleOfs as number[]).map((idProspect: number) => ({
            idResponsable: user.idUser,
            idProspect
          })) as Prospect[]
        })
      );
      actions.push(
        userActions.addManyProspectResponsibleOfSuccess({
          idUser: user.idUser,
          idProspectResponsibleOf: ids.prospectResponsibleOfs as number[]
        })
      );
    }
  }

  if (ids?.prospectUpdaters) {
    if (!Array.isArray(ids.prospectUpdaters)) {
      actions.push(
        prospectActions.upsertOneProspect({
          prospect: {
            idUpdatedUser: user.idUser,
            idProspect: ids.prospectUpdaters as number
          } as Prospect
        })
      );
      actions.push(
        userActions.addManyProspectUpdaterSuccess({
          idUser: user.idUser,
          idProspectUpdater: [ids.prospectUpdaters as number]
        })
      );
    } else {
      actions.push(
        prospectActions.upsertManyProspects({
          prospects: (ids.prospectUpdaters as number[]).map((idProspect: number) => ({
            idUpdatedUser: user.idUser,
            idProspect
          })) as Prospect[]
        })
      );
      actions.push(
        userActions.addManyProspectUpdaterSuccess({
          idUser: user.idUser,
          idProspectUpdater: ids.prospectUpdaters as number[]
        })
      );
    }
  }

  if (ids?.prospectCreators) {
    if (!Array.isArray(ids.prospectCreators)) {
      actions.push(
        prospectActions.upsertOneProspect({
          prospect: {
            idCreatedUser: user.idUser,
            idProspect: ids.prospectCreators as number
          } as Prospect
        })
      );
      actions.push(
        userActions.addManyProspectCreatorSuccess({
          idUser: user.idUser,
          idProspectCreator: [ids.prospectCreators as number]
        })
      );
    } else {
      actions.push(
        prospectActions.upsertManyProspects({
          prospects: (ids.prospectCreators as number[]).map((idProspect: number) => ({
            idCreatedUser: user.idUser,
            idProspect
          })) as Prospect[]
        })
      );
      actions.push(
        userActions.addManyProspectCreatorSuccess({
          idUser: user.idUser,
          idProspectCreator: ids.prospectCreators as number[]
        })
      );
    }
  }

  if (ids?.residences) {
    if (!Array.isArray(ids.residences)) {
      actions.push(
        residenceActions.upsertOneResidence({
          residence: {
            idResponsable: user.idUser,
            idResidence: ids.residences as number
          } as Residence
        })
      );
      actions.push(
        userActions.addManyResidenceSuccess({
          idUser: user.idUser,
          idResidence: [ids.residences as number]
        })
      );
    } else {
      actions.push(
        residenceActions.upsertManyResidences({
          residences: (ids.residences as number[]).map((idResidence: number) => ({
            idResponsable: user.idUser,
            idResidence
          })) as Residence[]
        })
      );
      actions.push(
        userActions.addManyResidenceSuccess({
          idUser: user.idUser,
          idResidence: ids.residences as number[]
        })
      );
    }
  }

  if (ids?.stratalots) {
    if (!Array.isArray(ids.stratalots)) {
      actions.push(
        stratalotActions.upsertOneStratalot({
          stratalot: {
            idResponsable: user.idUser,
            idStratalot: ids.stratalots as number
          } as Stratalot
        })
      );
      actions.push(
        userActions.addManyStratalotSuccess({
          idUser: user.idUser,
          idStratalot: [ids.stratalots as number]
        })
      );
    } else {
      actions.push(
        stratalotActions.upsertManyStratalots({
          stratalots: (ids.stratalots as number[]).map((idStratalot: number) => ({
            idResponsable: user.idUser,
            idStratalot
          })) as Stratalot[]
        })
      );
      actions.push(
        userActions.addManyStratalotSuccess({
          idUser: user.idUser,
          idStratalot: ids.stratalots as number[]
        })
      );
    }
  }

  if (ids?.prospectEvents) {
    if (!Array.isArray(ids.prospectEvents)) {
      actions.push(
        prospectEventActions.upsertOneProspectEvent({
          prospectEvent: {
            idUser: user.idUser,
            idProspectEvent: ids.prospectEvents as number
          } as ProspectEvent
        })
      );
      actions.push(
        userActions.addManyProspectEventSuccess({
          idUser: user.idUser,
          idProspectEvents: [ids.prospectEvents as number]
        })
      );
    } else {
      actions.push(
        prospectEventActions.upsertManyProspectEvents({
          prospectEvents: (ids.prospectEvents as number[]).map((idProspectEvent: number) => ({
            idUser: user.idUser,
            idProspectEvent
          })) as ProspectEvent[]
        })
      );
      actions.push(
        userActions.addManyProspectEventSuccess({
          idUser: user.idUser,
          idProspectEvents: ids.prospectEvents as number[]
        })
      );
    }
  }

  if (ids?.profils) {
    if (!Array.isArray(ids.profils)) {
      actions.push(
        profilActions.upsertOneProfil({
          profil: {
            idUser: user.idUser,
            idProfil: ids.profils as number
          } as Profil & any
        })
      );
      actions.push(
        userActions.addManyProfilSuccess({
          idUser: user.idUser,
          idProfils: [ids.profils as number]
        })
      );
    } else {
      actions.push(
        profilActions.upsertManyProfils({
          profils: (ids.profils as number[]).map((idProfil: number) => ({
            idUser: user.idUser,
            idProfil
          })) as Profil[] & any[]
        })
      );
      actions.push(
        userActions.addManyProfilSuccess({
          idUser: user.idUser,
          idProfils: ids.profils as number[]
        })
      );
    }
  }

  if (ids?.companyCommunicationRecipients) {
    if (!Array.isArray(ids.companyCommunicationRecipients)) {
      actions.push(
        companyCommunicationRecipientActions.upsertOneCompanyCommunicationRecipient({
          companyCommunicationRecipient: {
            idUser: user.idUser,
            idCompanyCommunicationRecipient: ids.companyCommunicationRecipients as number
          } as CompanyCommunicationRecipient & any
        })
      );
      actions.push(
        userActions.addManyCompanyCommunicationRecipientSuccess({
          idUser: user.idUser,
          idCompanyCommunicationRecipients: [ids.companyCommunicationRecipients as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationRecipientActions.upsertManyCompanyCommunicationRecipients({
          companyCommunicationRecipients: (ids.companyCommunicationRecipients as number[]).map(
            (idCompanyCommunicationRecipient: number) => ({
              idUser: user.idUser,
              idCompanyCommunicationRecipient
            })
          ) as CompanyCommunicationRecipient[] & any[]
        })
      );
      actions.push(
        userActions.addManyCompanyCommunicationRecipientSuccess({
          idUser: user.idUser,
          idCompanyCommunicationRecipients: ids.companyCommunicationRecipients as number[]
        })
      );
    }
  }

  if (ids?.companyCommunicationRecipientUsers) {
    if (!Array.isArray(ids.companyCommunicationRecipientUsers)) {
      actions.push(
        companyCommunicationRecipientUserActions.upsertOneCompanyCommunicationRecipientUser({
          companyCommunicationRecipientUser: {
            idUser: user.idUser,
            idCompanyCommunicationRecipientUser: ids.companyCommunicationRecipientUsers as number
          } as CompanyCommunicationRecipientUser
        })
      );
      actions.push(
        userActions.addManyCompanyCommunicationRecipientUserSuccess({
          idUser: user.idUser,
          idCompanyCommunicationRecipientUsers: [ids.companyCommunicationRecipientUsers as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationRecipientUserActions.upsertManyCompanyCommunicationRecipientUsers({
          companyCommunicationRecipientUsers: (ids.companyCommunicationRecipientUsers as number[]).map(
            (idCompanyCommunicationRecipientUser: number) => ({
              idUser: user.idUser,
              idCompanyCommunicationRecipientUser
            })
          ) as CompanyCommunicationRecipientUser[]
        })
      );
      actions.push(
        userActions.addManyCompanyCommunicationRecipientUserSuccess({
          idUser: user.idUser,
          idCompanyCommunicationRecipientUsers: ids.companyCommunicationRecipientUsers as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteUserActions(user: UserEntityState): Action[] {
  const actions: Action[] = [userActions.deleteOneUserSuccess({ idUser: user.idUser })];

  if (user.organization) {
    actions.push(
      organizationActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idOrganizations: [user.organization as number]
      })
    );
  }

  if (user.boardStates) {
    actions.push(
      boardStateActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idBoardStates: user.boardStates as number[]
      })
    );
  }

  if (user.companyTerritoireUsers) {
    actions.push(
      companyTerritoireUserActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idCompanyTerritoireUsers: user.companyTerritoireUsers as number[]
      })
    );
  }

  if (user.residencePriceGridHistories) {
    actions.push(
      residencePriceGridHistoryActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idResidencePriceGridHistories: user.residencePriceGridHistories as number[]
      })
    );
  }

  if (user.companyTerritoires) {
    actions.push(
      companyTerritoireActions.deleteManyResponsableSuccess({
        idResponsable: [user.idUser],
        idCompanyTerritoires: user.companyTerritoires as number[]
      })
    );
  }

  if (user.prospectResponsibleOf) {
    actions.push(
      prospectActions.deleteManyResponsableSuccess({
        idResponsable: [user.idUser],
        idProspects: user.prospectResponsibleOf as number[]
      })
    );
  }

  if (user.prospectUpdater) {
    actions.push(
      prospectActions.deleteManyUpdatedUserSuccess({
        idUpdatedUser: [user.idUser],
        idProspects: user.prospectUpdater as number[]
      })
    );
  }

  if (user.prospectCreator) {
    actions.push(
      prospectActions.deleteManyCreatedUserSuccess({
        idCreatedUser: [user.idUser],
        idProspects: user.prospectCreator as number[]
      })
    );
  }

  if (user.residences) {
    actions.push(
      residenceActions.deleteManyResponsableSuccess({
        idResponsable: [user.idUser],
        idResidences: user.residences as number[]
      })
    );
  }

  if (user.stratalots) {
    actions.push(
      stratalotActions.deleteManyResponsableSuccess({
        idResponsable: [user.idUser],
        idStratalots: user.stratalots as number[]
      })
    );
  }

  if (user.prospectEvents) {
    actions.push(
      prospectEventActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idProspectEvents: user.prospectEvents as number[]
      })
    );
  }

  if (user.profils) {
    actions.push(
      profilActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idProfils: user.profils as number[]
      })
    );
  }

  if (user.companyCommunicationRecipients) {
    actions.push(
      companyCommunicationRecipientActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idCompanyCommunicationRecipients: user.companyCommunicationRecipients as number[]
      })
    );
  }

  if (user.companyCommunicationRecipientUsers) {
    actions.push(
      companyCommunicationRecipientUserActions.deleteManyUserSuccess({
        idUsers: [user.idUser],
        idCompanyCommunicationRecipientUsers: user.companyCommunicationRecipientUsers as number[]
      })
    );
  }

  return actions;
}

export class GeneratedUserEffects {
  constructor(
    protected actions$: Actions,
    protected userApiService: UserApiService,
    protected store$: Store<AppState>
  ) {}

  getManyUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.getManyUsers),
      switchMap(({ params }) =>
        this.userApiService.getUsers(params).pipe(
          map((users: User[]) => {
            return userActions.normalizeManyUsersAfterUpsert({ users });
          }),
          catchError(error => of(userActions.usersFailure({ error })))
        )
      )
    );
  });

  getOneUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.getOneUser),
      switchMap(idUser =>
        this.userApiService.getUser(idUser).pipe(
          map((user: User) => {
            return userActions.normalizeManyUsersAfterUpsert({ users: [user] });
          }),
          catchError(error => of(userActions.usersFailure({ error })))
        )
      )
    );
  });

  upsertOneUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.upsertOneUser),
      concatMap(({ user, ids }: { user: Partial<User>; ids?: UserRelationsIds }) => {
        if (user.idUser) {
          return this.userApiService.updateUser(user).pipe(
            map((userReturned: User) => {
              return userActions.normalizeManyUsersAfterUpsert({ users: [userReturned] });
            }),
            catchError(error => of(userActions.usersFailure({ error })))
          );
        } else {
          return this.userApiService.addUser(user).pipe(
            mergeMap((userReturned: User) => getDefaultAddUserActions(userReturned, ids)),
            catchError(error => of(userActions.usersFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneUser$ = createEffect(() => {
    const selectUserState$ = this.store$.select(selectUserState);
    return this.actions$.pipe(
      ofType(userActions.deleteOneUser),
      withLatestFrom(selectUserState$),
      concatMap(([{ idUser }, state]) =>
        this.userApiService.deleteUser(idUser).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteUserActions(state.entities[idUser] as UserEntityState),
              userActions.deleteOneUser.type
            )
          ]),
          catchError(error => of(userActions.usersFailure({ error })))
        )
      )
    );
  });

  normalizeManyUsersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.normalizeManyUsersAfterUpsert),
      concatMap(({ users }) => {
        const actions: Action[] = getActionsToNormalizeUser(users, StoreActionType.upsert);
        return [getMultiAction(actions, '[User] Normalization After Upsert Success')];
      })
    );
  });
}
