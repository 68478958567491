import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationLeadTodoRule } from '@_model/interfaces/organization-lead-todo-rule.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationLeadTodoRuleApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationLeadTodoRules(params?: any): Observable<OrganizationLeadTodoRule[]> {
    return this.repo.getData<OrganizationLeadTodoRule[]>('organization-lead-todo-rule', params);
  }

  public getOrganizationLeadTodoRule(params: {
    idOrganizationLeadTodoRule: number;
    params?: any;
  }): Observable<OrganizationLeadTodoRule> {
    return this.repo.getData<OrganizationLeadTodoRule>(
      'organization-lead-todo-rule/' + params.idOrganizationLeadTodoRule,
      params.params
    );
  }

  public addOrganizationLeadTodoRule(
    organizationLeadTodoRule: Partial<OrganizationLeadTodoRule>
  ): Observable<OrganizationLeadTodoRule> {
    return this.repo.create<OrganizationLeadTodoRule>('organization-lead-todo-rule', organizationLeadTodoRule);
  }

  public updateOrganizationLeadTodoRule(
    organizationLeadTodoRule: Partial<OrganizationLeadTodoRule>
  ): Observable<OrganizationLeadTodoRule> {
    return this.repo.update('organization-lead-todo-rule', organizationLeadTodoRule);
  }

  public deleteOrganizationLeadTodoRule(idOrganizationLeadTodoRule: number): Observable<number> {
    return this.repo.delete('organization-lead-todo-rule/' + +idOrganizationLeadTodoRule);
  }
}
