import { OccupedStratalotFilterState } from '@_shared/models/interfaces/filter/occuped-stratalot-filter';
import { ProspectFilterState } from '@_shared/models/interfaces/filter/prospect-filter';
import { FreeStratalotFilterState } from '@_shared/models/interfaces/filter/free-stratalot-filter';

export interface FilterState {
  isLoaded: boolean;
  isLoading: boolean;
  activeOccupedStratalotFilter: OccupedStratalotFilterState;
  activeProspectFilter: ProspectFilterState;
  activeFreeStratalotFilter: FreeStratalotFilterState;
}

export const initialState: FilterState = {
  isLoaded: false,
  isLoading: false,
  activeOccupedStratalotFilter: null,
  activeProspectFilter: null,
  activeFreeStratalotFilter: null
};
