import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationSaleCategory,
  OrganizationSaleCategoryEntityState
} from '@_model/interfaces/organization-sale-category.model';
import { OrganizationSaleCategoryRelationsIds } from './organization-sale-category-generated.effects';

export const getOneOrganizationSaleCategory = createAction(
  '[OrganizationSaleCategory] Get One OrganizationSaleCategory',
  props<{ idOrganizationSaleCategory: number; params?: any }>()
);

export const getManyOrganizationSaleCategories = createAction(
  '[OrganizationSaleCategory] Get Many OrganizationSaleCategories',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationSaleCategory] Add Many Actives OrganizationSaleCategory',
  props<{ idOrganizationSaleCategories: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationSaleCategory] Delete One Active OrganizationSaleCategory',
  props<{ idOrganizationSaleCategory: number }>()
);

export const clearActive = createAction('[OrganizationSaleCategory] Clear Active OrganizationSaleCategory');

export const upsertOneOrganizationSaleCategory = createAction(
  '[OrganizationSaleCategory] Upsert One OrganizationSaleCategory',
  props<{
    organizationSaleCategory: Partial<OrganizationSaleCategory>;
    ids?: OrganizationSaleCategoryRelationsIds;
  }>()
);

export const upsertManyOrganizationSaleCategories = createAction(
  '[OrganizationSaleCategory] Upsert Many OrganizationSaleCategories',
  props<{
    organizationSaleCategories: Partial<OrganizationSaleCategory>[];
    ids?: OrganizationSaleCategoryRelationsIds;
  }>()
);

export const upsertManyOrganizationSaleCategoriesSuccess = createAction(
  '[OrganizationSaleCategory] Create Many OrganizationSaleCategories Success',
  props<{ organizationSaleCategories: OrganizationSaleCategoryEntityState[] }>()
);

export const deleteOneOrganizationSaleCategory = createAction(
  '[OrganizationSaleCategory] Delete One OrganizationSaleCategory',
  props<{ idOrganizationSaleCategory: number }>()
);

export const deleteOneOrganizationSaleCategorySuccess = createAction(
  '[OrganizationSaleCategory] Delete One OrganizationSaleCategory Success',
  props<{ idOrganizationSaleCategory: number }>()
);

export const normalizeManyOrganizationSaleCategoriesAfterUpsert = createAction(
  '[OrganizationSaleCategory] Normalize Many OrganizationSaleCategories After Upsert',
  props<{ organizationSaleCategories: OrganizationSaleCategoryEntityState[] }>()
);

export const organizationSaleCategoriesFailure = createAction(
  '[OrganizationSaleCategory] OrganizationSaleCategories Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationSaleCategory] Clear OrganizationSaleCategories');

export const addManyStratalotSuccess = createAction(
  '[OrganizationSaleCategory] Add Many stratalot',
  props<{ idOrganizationSaleCategory: number; idStratalots: number[] }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[OrganizationSaleCategory] Delete Many Stratalots',
  props<{ idStratalots: number[]; idOrganizationSaleCategories: number[] }>()
);

export const addManyOrganizationLeadTodoRuleSuccess = createAction(
  '[OrganizationSaleCategory] Add Many organization-lead-todo-rule',
  props<{ idOrganizationSaleCategory: number; idOrganizationLeadTodoRules: number[] }>()
);

export const deleteManyOrganizationLeadTodoRuleSuccess = createAction(
  '[OrganizationSaleCategory] Delete Many OrganizationLeadTodoRules',
  props<{ idOrganizationLeadTodoRules: number[]; idOrganizationSaleCategories: number[] }>()
);

export const addManyOrganizationStratalotTodoRuleSuccess = createAction(
  '[OrganizationSaleCategory] Add Many organization-stratalot-todo-rule',
  props<{ idOrganizationSaleCategory: number; idOrganizationStratalotTodoRules: number[] }>()
);

export const deleteManyOrganizationStratalotTodoRuleSuccess = createAction(
  '[OrganizationSaleCategory] Delete Many OrganizationStratalotTodoRules',
  props<{ idOrganizationStratalotTodoRules: number[]; idOrganizationSaleCategories: number[] }>()
);

export const addManyOrganizationResidenceTodoRuleSuccess = createAction(
  '[OrganizationSaleCategory] Add Many organization-residence-todo-rule',
  props<{ idOrganizationSaleCategory: number; idOrganizationResidenceTodoRules: number[] }>()
);

export const deleteManyOrganizationResidenceTodoRuleSuccess = createAction(
  '[OrganizationSaleCategory] Delete Many OrganizationResidenceTodoRules',
  props<{ idOrganizationResidenceTodoRules: number[]; idOrganizationSaleCategories: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[OrganizationSaleCategory] Add Organization',
  props<{ idOrganizationSaleCategory: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationSaleCategory] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationSaleCategories: number[] }>()
);

export const addSaleCategoryFamilySuccess = createAction(
  '[OrganizationSaleCategory] Add SaleCategoryFamily',
  props<{ idOrganizationSaleCategory: number; idSaleCategoryFamily: number }>()
);

export const deleteManySaleCategoryFamilySuccess = createAction(
  '[OrganizationSaleCategory] Delete Many SaleCategoryFamily',
  props<{ idFamilySaleCategories: number[]; idOrganizationSaleCategories: number[] }>()
);
