import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as GeneratedDocumentActions from './generated-document.actions';
import { adapter, initialState, GeneratedDocumentState } from './generated-document.state';
import { Dictionary } from '@ngrx/entity';
import { GeneratedDocumentEntityState } from '@_model/interfaces/generated-document.model';

export const generatedDocumentReducersGeneratedFunctions: ReducerTypes<
  GeneratedDocumentState,
  readonly ActionCreator[]
>[] = [
  on(GeneratedDocumentActions.getOneGeneratedDocument, (state: GeneratedDocumentState) =>
    setLoadingsState(state, true)
  ),
  on(GeneratedDocumentActions.getManyGeneratedDocuments, (state: GeneratedDocumentState) =>
    setLoadingsState(state, true)
  ),
  on(GeneratedDocumentActions.upsertOneGeneratedDocument, (state: GeneratedDocumentState) =>
    setLoadingsState(state, true)
  ),

  on(
    GeneratedDocumentActions.upsertManyGeneratedDocumentsSuccess,
    (state: GeneratedDocumentState, { generatedDocuments }) =>
      adapter.upsertMany(generatedDocuments, setLoadingsState(state, false))
  ),
  on(GeneratedDocumentActions.deleteOneGeneratedDocument, (state: GeneratedDocumentState) =>
    setLoadingsState(state, true)
  ),
  on(
    GeneratedDocumentActions.deleteOneGeneratedDocumentSuccess,
    (state: GeneratedDocumentState, { idGeneratedDocument }) =>
      adapter.removeOne(idGeneratedDocument, setLoadingsState(state, false))
  ),
  on(GeneratedDocumentActions.clearActive, (state: GeneratedDocumentState) => ({ ...state, actives: [] })),
  on(GeneratedDocumentActions.addManyActives, (state: GeneratedDocumentState, { idGeneratedDocuments }) => ({
    ...state,
    actives: state.actives.concat(idGeneratedDocuments)
  })),
  on(GeneratedDocumentActions.deleteOneActive, (state: GeneratedDocumentState, { idGeneratedDocument }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idGeneratedDocument)
  })),

  on(GeneratedDocumentActions.clearStore, () => initialState),

  on(
    GeneratedDocumentActions.addManyGeneratedDocumentsCompanySuccess,
    (state: GeneratedDocumentState, { idGeneratedDocument, idGeneratedDocumentsCompanies }) => {
      if (!state.entities[idGeneratedDocument]) {
        return state;
      }
      const generatedDocumentsCompanies =
        (state.entities[idGeneratedDocument]?.generatedDocumentsCompanies as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idGeneratedDocument]: {
            ...state.entities[idGeneratedDocument],
            generatedDocumentsCompanies: generatedDocumentsCompanies.concat(
              idGeneratedDocumentsCompanies.filter(id => generatedDocumentsCompanies.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    GeneratedDocumentActions.deleteManyGeneratedDocumentsCompanySuccess,
    (state: GeneratedDocumentState, { idGeneratedDocumentsCompanies, idGeneratedDocuments }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idGeneratedDocuments.reduce((entities, idGeneratedDocument) => {
            if (!state.entities[idGeneratedDocument]?.generatedDocumentsCompanies) {
              return entities;
            }
            entities[idGeneratedDocument] = {
              ...state.entities[idGeneratedDocument],
              generatedDocumentsCompanies: (
                state.entities[idGeneratedDocument]?.generatedDocumentsCompanies as number[]
              )?.filter(
                (idGeneratedDocumentsCompany: number) =>
                  !idGeneratedDocumentsCompanies.some((id: number) => id === idGeneratedDocumentsCompany)
              )
            } as GeneratedDocumentEntityState;
            return entities;
          }, {} as Dictionary<GeneratedDocumentEntityState>)
        }
      };
    }
  ),

  on(
    GeneratedDocumentActions.addManyCompanySuccess,
    (state: GeneratedDocumentState, { idGeneratedDocument, idCompanies }) => {
      if (!state.entities[idGeneratedDocument]) {
        return state;
      }
      const companies = (state.entities[idGeneratedDocument]?.companies as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idGeneratedDocument]: {
            ...state.entities[idGeneratedDocument],
            companies: companies.concat(idCompanies.filter(id => companies.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    GeneratedDocumentActions.deleteManyCompanySuccess,
    (state: GeneratedDocumentState, { idCompanies, idGeneratedDocuments }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idGeneratedDocuments.reduce((entities, idGeneratedDocument) => {
            if (!state.entities[idGeneratedDocument]?.companies) {
              return entities;
            }
            entities[idGeneratedDocument] = {
              ...state.entities[idGeneratedDocument],
              companies: (state.entities[idGeneratedDocument]?.companies as number[])?.filter(
                (idCompany: number) => !idCompanies.some((id: number) => id === idCompany)
              )
            } as GeneratedDocumentEntityState;
            return entities;
          }, {} as Dictionary<GeneratedDocumentEntityState>)
        }
      };
    }
  ),

  on(
    GeneratedDocumentActions.addManyResidenceSuccess,
    (state: GeneratedDocumentState, { idGeneratedDocument, idResidences }) => {
      if (!state.entities[idGeneratedDocument]) {
        return state;
      }
      const residences = (state.entities[idGeneratedDocument]?.residences as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idGeneratedDocument]: {
            ...state.entities[idGeneratedDocument],
            residences: residences.concat(idResidences.filter(id => residences.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    GeneratedDocumentActions.deleteManyResidenceSuccess,
    (state: GeneratedDocumentState, { idResidences, idGeneratedDocuments }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idGeneratedDocuments.reduce((entities, idGeneratedDocument) => {
            if (!state.entities[idGeneratedDocument]?.residences) {
              return entities;
            }
            entities[idGeneratedDocument] = {
              ...state.entities[idGeneratedDocument],
              residences: (state.entities[idGeneratedDocument]?.residences as number[])?.filter(
                (idResidence: number) => !idResidences.some((id: number) => id === idResidence)
              )
            } as GeneratedDocumentEntityState;
            return entities;
          }, {} as Dictionary<GeneratedDocumentEntityState>)
        }
      };
    }
  ),

  on(
    GeneratedDocumentActions.addManyGeneratedDocumentsResidenceSuccess,
    (state: GeneratedDocumentState, { idGeneratedDocument, idGeneratedDocumentsResidences }) => {
      if (!state.entities[idGeneratedDocument]) {
        return state;
      }
      const generatedDocumentsResidences =
        (state.entities[idGeneratedDocument]?.generatedDocumentsResidences as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idGeneratedDocument]: {
            ...state.entities[idGeneratedDocument],
            generatedDocumentsResidences: generatedDocumentsResidences.concat(
              idGeneratedDocumentsResidences.filter(id => generatedDocumentsResidences.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    GeneratedDocumentActions.deleteManyGeneratedDocumentsResidenceSuccess,
    (state: GeneratedDocumentState, { idGeneratedDocumentsResidences, idGeneratedDocuments }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idGeneratedDocuments.reduce((entities, idGeneratedDocument) => {
            if (!state.entities[idGeneratedDocument]?.generatedDocumentsResidences) {
              return entities;
            }
            entities[idGeneratedDocument] = {
              ...state.entities[idGeneratedDocument],
              generatedDocumentsResidences: (
                state.entities[idGeneratedDocument]?.generatedDocumentsResidences as number[]
              )?.filter(
                (idGeneratedDocumentsResidence: number) =>
                  !idGeneratedDocumentsResidences.some((id: number) => id === idGeneratedDocumentsResidence)
              )
            } as GeneratedDocumentEntityState;
            return entities;
          }, {} as Dictionary<GeneratedDocumentEntityState>)
        }
      };
    }
  ),

  on(
    GeneratedDocumentActions.addManyCompanyCommunicationSuccess,
    (state: GeneratedDocumentState, { idGeneratedDocument, idCompanyCommunications }) => {
      if (!state.entities[idGeneratedDocument]) {
        return state;
      }
      const companyCommunications = (state.entities[idGeneratedDocument]?.companyCommunications as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idGeneratedDocument]: {
            ...state.entities[idGeneratedDocument],
            companyCommunications: companyCommunications.concat(
              idCompanyCommunications.filter(id => companyCommunications.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    GeneratedDocumentActions.deleteManyCompanyCommunicationSuccess,
    (state: GeneratedDocumentState, { idCompanyCommunications, idGeneratedDocuments }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idGeneratedDocuments.reduce((entities, idGeneratedDocument) => {
            if (!state.entities[idGeneratedDocument]?.companyCommunications) {
              return entities;
            }
            entities[idGeneratedDocument] = {
              ...state.entities[idGeneratedDocument],
              companyCommunications: (state.entities[idGeneratedDocument]?.companyCommunications as number[])?.filter(
                (idCompanyCommunication: number) =>
                  !idCompanyCommunications.some((id: number) => id === idCompanyCommunication)
              )
            } as GeneratedDocumentEntityState;
            return entities;
          }, {} as Dictionary<GeneratedDocumentEntityState>)
        }
      };
    }
  ),

  on(
    GeneratedDocumentActions.addManyCompanyCommunicationGeneratedDocumentSuccess,
    (state: GeneratedDocumentState, { idGeneratedDocument, idCompanyCommunicationGeneratedDocuments }) => {
      if (!state.entities[idGeneratedDocument]) {
        return state;
      }
      const companyCommunicationGeneratedDocuments =
        (state.entities[idGeneratedDocument]?.companyCommunicationGeneratedDocuments as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idGeneratedDocument]: {
            ...state.entities[idGeneratedDocument],
            companyCommunicationGeneratedDocuments: companyCommunicationGeneratedDocuments.concat(
              idCompanyCommunicationGeneratedDocuments.filter(
                id => companyCommunicationGeneratedDocuments.indexOf(id) < 0
              )
            )
          }
        }
      };
    }
  ),

  on(
    GeneratedDocumentActions.deleteManyCompanyCommunicationGeneratedDocumentSuccess,
    (state: GeneratedDocumentState, { idCompanyCommunicationGeneratedDocuments, idGeneratedDocuments }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idGeneratedDocuments.reduce((entities, idGeneratedDocument) => {
            if (!state.entities[idGeneratedDocument]?.companyCommunicationGeneratedDocuments) {
              return entities;
            }
            entities[idGeneratedDocument] = {
              ...state.entities[idGeneratedDocument],
              companyCommunicationGeneratedDocuments: (
                state.entities[idGeneratedDocument]?.companyCommunicationGeneratedDocuments as number[]
              )?.filter(
                (idCompanyCommunicationGeneratedDocument: number) =>
                  !idCompanyCommunicationGeneratedDocuments.some(
                    (id: number) => id === idCompanyCommunicationGeneratedDocument
                  )
              )
            } as GeneratedDocumentEntityState;
            return entities;
          }, {} as Dictionary<GeneratedDocumentEntityState>)
        }
      };
    }
  ),

  on(
    GeneratedDocumentActions.addOrganizationSuccess,
    (state: GeneratedDocumentState, { idGeneratedDocument, idOrganization }) => {
      if (!state.entities[idGeneratedDocument]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idGeneratedDocument]: {
            ...state.entities[idGeneratedDocument],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    GeneratedDocumentActions.deleteManyOrganizationSuccess,
    (state: GeneratedDocumentState, { idOrganizations, idGeneratedDocuments }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idGeneratedDocuments.reduce((entities, idGeneratedDocument) => {
            if (!state.entities[idGeneratedDocument]?.organization) {
              return entities;
            }
            entities[idGeneratedDocument] = {
              ...state.entities[idGeneratedDocument],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idGeneratedDocument]?.organization
              )
                ? undefined
                : state.entities[idGeneratedDocument]?.organization
            } as GeneratedDocumentEntityState;
            return entities;
          }, {} as Dictionary<GeneratedDocumentEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: GeneratedDocumentState,
  isLoading: boolean,
  isLoaded: boolean = true
): GeneratedDocumentState {
  return { ...state, isLoaded, isLoading };
}
