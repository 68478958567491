import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { OrganizationMetier, OrganizationMetierEntityState } from '@_model/interfaces/organization-metier.model';
import { OrganizationMetierRelationsIds } from './organization-metier-generated.effects';

export const getOneOrganizationMetier = createAction(
  '[OrganizationMetier] Get One OrganizationMetier',
  props<{ idOrganizationMetier: number; params?: any }>()
);

export const getManyOrganizationMetiers = createAction(
  '[OrganizationMetier] Get Many OrganizationMetiers',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationMetier] Add Many Actives OrganizationMetier',
  props<{ idOrganizationMetiers: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationMetier] Delete One Active OrganizationMetier',
  props<{ idOrganizationMetier: number }>()
);

export const clearActive = createAction('[OrganizationMetier] Clear Active OrganizationMetier');

export const upsertOneOrganizationMetier = createAction(
  '[OrganizationMetier] Upsert One OrganizationMetier',
  props<{
    organizationMetier: Partial<OrganizationMetier>;
    ids?: OrganizationMetierRelationsIds;
  }>()
);

export const upsertManyOrganizationMetiers = createAction(
  '[OrganizationMetier] Upsert Many OrganizationMetiers',
  props<{
    organizationMetiers: Partial<OrganizationMetier>[];
    ids?: OrganizationMetierRelationsIds;
  }>()
);

export const upsertManyOrganizationMetiersSuccess = createAction(
  '[OrganizationMetier] Create Many OrganizationMetiers Success',
  props<{ organizationMetiers: OrganizationMetierEntityState[] }>()
);

export const deleteOneOrganizationMetier = createAction(
  '[OrganizationMetier] Delete One OrganizationMetier',
  props<{ idOrganizationMetier: number }>()
);

export const deleteOneOrganizationMetierSuccess = createAction(
  '[OrganizationMetier] Delete One OrganizationMetier Success',
  props<{ idOrganizationMetier: number }>()
);

export const normalizeManyOrganizationMetiersAfterUpsert = createAction(
  '[OrganizationMetier] Normalize Many OrganizationMetiers After Upsert',
  props<{ organizationMetiers: OrganizationMetierEntityState[] }>()
);

export const organizationMetiersFailure = createAction(
  '[OrganizationMetier] OrganizationMetiers Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationMetier] Clear OrganizationMetiers');

export const addManyOrganizationThirdPartySuccess = createAction(
  '[OrganizationMetier] Add Many organization-third-party',
  props<{ idOrganizationMetier: number; idOrganizationThirdParties: number[] }>()
);

export const deleteManyOrganizationThirdPartySuccess = createAction(
  '[OrganizationMetier] Delete Many OrganizationThirdParties',
  props<{ idOrganizationThirdParties: number[]; idOrganizationMetiers: number[] }>()
);

export const addManyCompanyCommunicationRecipientSuccess = createAction(
  '[OrganizationMetier] Add Many company-communication-recipient',
  props<{ idOrganizationMetier: number; idCompanyCommunicationRecipients: number[] }>()
);

export const deleteManyCompanyCommunicationRecipientSuccess = createAction(
  '[OrganizationMetier] Delete Many CompanyCommunicationRecipients',
  props<{ idCompanyCommunicationRecipients: number[]; idOrganizationMetiers: number[] }>()
);

export const addManyCompanyCommunicationRecipientMetierSuccess = createAction(
  '[OrganizationMetier] Add Many company-communication-recipient-metier',
  props<{ idOrganizationMetier: number; idCompanyCommunicationRecipientMetiers: number[] }>()
);

export const deleteManyCompanyCommunicationRecipientMetierSuccess = createAction(
  '[OrganizationMetier] Delete Many CompanyCommunicationRecipientMetiers',
  props<{ idCompanyCommunicationRecipientMetiers: number[]; idOrganizationMetiers: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[OrganizationMetier] Add Organization',
  props<{ idOrganizationMetier: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationMetier] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationMetiers: number[] }>()
);

export const addMetierFamilieSuccess = createAction(
  '[OrganizationMetier] Add MetierFamilie',
  props<{ idOrganizationMetier: number; idMetierFamilie: number }>()
);

export const deleteManyMetierFamilieSuccess = createAction(
  '[OrganizationMetier] Delete Many MetierFamilie',
  props<{ idMetierFamilies: number[]; idOrganizationMetiers: number[] }>()
);
