import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceForecast } from '@_model/interfaces/residence-forecast.model';
import { Observable } from 'rxjs';

export class GeneratedResidenceForecastApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidenceForecasts(params?: any): Observable<ResidenceForecast[]> {
    return this.repo.getData<ResidenceForecast[]>('residence-forecast', params);
  }

  public getResidenceForecast(params: { idResidenceForecast: number; params?: any }): Observable<ResidenceForecast> {
    return this.repo.getData<ResidenceForecast>('residence-forecast/' + params.idResidenceForecast, params.params);
  }

  public addResidenceForecast(residenceForecast: Partial<ResidenceForecast>): Observable<ResidenceForecast> {
    return this.repo.create<ResidenceForecast>('residence-forecast', residenceForecast);
  }

  public updateResidenceForecast(residenceForecast: Partial<ResidenceForecast>): Observable<ResidenceForecast> {
    return this.repo.update('residence-forecast', residenceForecast);
  }

  public deleteResidenceForecast(idResidenceForecast: number): Observable<number> {
    return this.repo.delete('residence-forecast/' + +idResidenceForecast);
  }
}
