import { createAction, props } from '@ngrx/store';
import { CloseSaleDTO } from '@_shared/interfaces/close-sale.dto';
import { Lead } from '@_shared/models/interfaces/lead.model';
export * from './lead-generated.actions';

export const getAllLeadsForSalesInProgressBoard = createAction(
  '[Lead] Load leads for sales in progress board',
  props<{ params: any }>()
);
export const getOneLeadWithLeadStratalots = createAction(
  '[Lead] Load One lead  With Leadstatalots',
  props<{ idLead: number; params?: any }>()
);
export const setMainStratalot = createAction(
  '[Lead] Set main stratalot',
  props<{ idLead: number; idStratalot: number }>()
);

export const updateManyLeads = createAction(
  '[Lead] Update many leads',
  props<{ partialLead: Partial<Lead>; ids: number[] }>()
);

export const closeSale = createAction('[Lead] Close sale', props<CloseSaleDTO>());

export const deleteManyLeadsByProspectSuccess = createAction(
  '[Lead] Delete Many Leads By Prospect',
  props<{ idProspect: number }>()
);
export const deleteOneLeadWithUpdateStratalot = createAction(
  '[Lead] Delete One Lead With Update Stratalot',
  props<{ idLead: number }>()
);

export const calculateTodos = createAction('[Lead] Calculate All Todo');
