import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationEnergieEntityState } from '@_model/interfaces/organization-energie.model';

export interface OrganizationEnergieState extends EntityState<OrganizationEnergieEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationEnergieEntityState> =
  createEntityAdapter<OrganizationEnergieEntityState>({
    selectId: o => o.idOrganizationEnergie
  });
export const initialState: OrganizationEnergieState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationEnergieFeatureKey = 'organizationEnergie';
