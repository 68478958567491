import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { BoardState, BoardStateEntityState } from '@_model/interfaces/board-state.model';
import { BoardStateRelationsIds } from './board-state-generated.effects';

export const getOneBoardState = createAction(
  '[BoardState] Get One BoardState',
  props<{ idBoardState: number; params?: any }>()
);

export const getManyBoardStates = createAction('[BoardState] Get Many BoardStates', props<{ params: any }>());

export const addManyActives = createAction(
  '[BoardState] Add Many Actives BoardState',
  props<{ idBoardStates: number[] }>()
);

export const deleteOneActive = createAction(
  '[BoardState] Delete One Active BoardState',
  props<{ idBoardState: number }>()
);

export const clearActive = createAction('[BoardState] Clear Active BoardState');

export const upsertOneBoardState = createAction(
  '[BoardState] Upsert One BoardState',
  props<{
    boardState: Partial<BoardState>;
    ids?: BoardStateRelationsIds;
  }>()
);

export const upsertManyBoardStates = createAction(
  '[BoardState] Upsert Many BoardStates',
  props<{
    boardStates: Partial<BoardState>[];
    ids?: BoardStateRelationsIds;
  }>()
);

export const upsertManyBoardStatesSuccess = createAction(
  '[BoardState] Create Many BoardStates Success',
  props<{ boardStates: BoardStateEntityState[] }>()
);

export const deleteOneBoardState = createAction(
  '[BoardState] Delete One BoardState',
  props<{ idBoardState: number }>()
);

export const deleteOneBoardStateSuccess = createAction(
  '[BoardState] Delete One BoardState Success',
  props<{ idBoardState: number }>()
);

export const normalizeManyBoardStatesAfterUpsert = createAction(
  '[BoardState] Normalize Many BoardStates After Upsert',
  props<{ boardStates: BoardStateEntityState[] }>()
);

export const boardStatesFailure = createAction(
  '[BoardState] BoardStates Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[BoardState] Clear BoardStates');

export const addUserSuccess = createAction('[BoardState] Add User', props<{ idBoardState: number; idUser: number }>());

export const deleteManyUserSuccess = createAction(
  '[BoardState] Delete Many User',
  props<{ idUsers: number[]; idBoardStates: number[] }>()
);

export const addSaleCategoryFamilySuccess = createAction(
  '[BoardState] Add SaleCategoryFamily',
  props<{ idBoardState: number; idSaleCategoryFamily: number }>()
);

export const deleteManySaleCategoryFamilySuccess = createAction(
  '[BoardState] Delete Many SaleCategoryFamily',
  props<{ idFamilySaleCategories: number[]; idBoardStates: number[] }>()
);
