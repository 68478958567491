import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationResidenceTodo,
  OrganizationResidenceTodoEntityState
} from '@_model/interfaces/organization-residence-todo.model';
import { OrganizationResidenceTodoApiService } from '@_services/api/organization-residence-todo-api.service';
import * as organizationResidenceTodoActions from '@_store/organization-residence-todo/organization-residence-todo.actions';
import { getActionsToNormalizeOrganizationResidenceTodo } from '@_config/store/normalization.generated';
import { selectOrganizationResidenceTodoState } from './organization-residence-todo-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as diagnosticActions from '@_store/diagnostic/diagnostic.actions';
import * as residenceTodoActions from '@_store/residence-todo/residence-todo.actions';
import { ResidenceTodo } from '@_model/interfaces/residence-todo.model';
import * as organizationResidenceTodoRuleActions from '@_store/organization-residence-todo-rule/organization-residence-todo-rule.actions';
import { OrganizationResidenceTodoRule } from '@_model/interfaces/organization-residence-todo-rule.model';
import * as organizationResidenceTodoAppliedRuleActions from '@_store/organization-residence-todo-applied-rule/organization-residence-todo-applied-rule.actions';
import { OrganizationResidenceTodoAppliedRule } from '@_model/interfaces/organization-residence-todo-applied-rule.model';
import * as organizationResidenceTodoProfilActions from '@_store/organization-residence-todo-profil/organization-residence-todo-profil.actions';
import { OrganizationResidenceTodoProfil } from '@_model/interfaces/organization-residence-todo-profil.model';
import * as companyCommunicationActions from '@_store/company-communication/company-communication.actions';
import { CompanyCommunication } from '@_model/interfaces/company-communication.model';
import * as companyCommunicationResidenceTodoActions from '@_store/company-communication-residence-todo/company-communication-residence-todo.actions';
import { CompanyCommunicationResidenceTodo } from '@_model/interfaces/company-communication-residence-todo.model';

export interface OrganizationResidenceTodoRelationsIds {
  residenceTodos?: number | number[];
  organizationResidenceTodoRules?: number | number[];
  organizationResidenceTodoAppliedRules?: number | number[];
  organizationResidenceTodoProfils?: number | number[];
  companyCommunications?: number | number[];
  companyCommunicationResidenceTodos?: number | number[];
  organization?: number;
  diagnostic?: number;
}

export function getDefaultAddOrganizationResidenceTodoActions(
  organizationResidenceTodo: OrganizationResidenceTodoEntityState,
  ids?: OrganizationResidenceTodoRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationResidenceTodoActions.normalizeManyOrganizationResidenceTodosAfterUpsert({
      organizationResidenceTodos: [organizationResidenceTodo]
    })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationResidenceTodoSuccess({
        idOrganization: ids.organization,
        idOrganizationResidenceTodos: [organizationResidenceTodo.idOrganizationResidenceTodo]
      })
    );
    actions.push(
      organizationResidenceTodoActions.addOrganizationSuccess({
        idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.diagnostic) {
    actions.push(
      diagnosticActions.addManyOrganizationResidenceTodoSuccess({
        idDiagnostic: ids.diagnostic,
        idOrganizationResidenceTodos: [organizationResidenceTodo.idOrganizationResidenceTodo]
      })
    );
    actions.push(
      organizationResidenceTodoActions.addDiagnosticSuccess({
        idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
        idDiagnostic: ids.diagnostic
      })
    );
  }

  if (ids?.residenceTodos) {
    if (!Array.isArray(ids.residenceTodos)) {
      actions.push(
        residenceTodoActions.upsertOneResidenceTodo({
          residenceTodo: {
            idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
            idResidenceTodo: ids.residenceTodos as number
          } as ResidenceTodo
        })
      );
      actions.push(
        organizationResidenceTodoActions.addManyResidenceTodoSuccess({
          idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
          idResidenceTodos: [ids.residenceTodos as number]
        })
      );
    } else {
      actions.push(
        residenceTodoActions.upsertManyResidenceTodos({
          residenceTodos: (ids.residenceTodos as number[]).map((idResidenceTodo: number) => ({
            idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
            idResidenceTodo
          })) as ResidenceTodo[]
        })
      );
      actions.push(
        organizationResidenceTodoActions.addManyResidenceTodoSuccess({
          idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
          idResidenceTodos: ids.residenceTodos as number[]
        })
      );
    }
  }

  if (ids?.organizationResidenceTodoRules) {
    if (!Array.isArray(ids.organizationResidenceTodoRules)) {
      actions.push(
        organizationResidenceTodoRuleActions.upsertOneOrganizationResidenceTodoRule({
          organizationResidenceTodoRule: {
            idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
            idOrganizationResidenceTodoRule: ids.organizationResidenceTodoRules as number
          } as OrganizationResidenceTodoRule & any
        })
      );
      actions.push(
        organizationResidenceTodoActions.addManyOrganizationResidenceTodoRuleSuccess({
          idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
          idOrganizationResidenceTodoRules: [ids.organizationResidenceTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationResidenceTodoRuleActions.upsertManyOrganizationResidenceTodoRules({
          organizationResidenceTodoRules: (ids.organizationResidenceTodoRules as number[]).map(
            (idOrganizationResidenceTodoRule: number) => ({
              idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
              idOrganizationResidenceTodoRule
            })
          ) as OrganizationResidenceTodoRule[] & any[]
        })
      );
      actions.push(
        organizationResidenceTodoActions.addManyOrganizationResidenceTodoRuleSuccess({
          idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
          idOrganizationResidenceTodoRules: ids.organizationResidenceTodoRules as number[]
        })
      );
    }
  }

  if (ids?.organizationResidenceTodoAppliedRules) {
    if (!Array.isArray(ids.organizationResidenceTodoAppliedRules)) {
      actions.push(
        organizationResidenceTodoAppliedRuleActions.upsertOneOrganizationResidenceTodoAppliedRule({
          organizationResidenceTodoAppliedRule: {
            idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
            idOrganizationResidenceTodoAppliedRule: ids.organizationResidenceTodoAppliedRules as number
          } as OrganizationResidenceTodoAppliedRule
        })
      );
      actions.push(
        organizationResidenceTodoActions.addManyOrganizationResidenceTodoAppliedRuleSuccess({
          idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
          idOrganizationResidenceTodoAppliedRules: [ids.organizationResidenceTodoAppliedRules as number]
        })
      );
    } else {
      actions.push(
        organizationResidenceTodoAppliedRuleActions.upsertManyOrganizationResidenceTodoAppliedRules({
          organizationResidenceTodoAppliedRules: (ids.organizationResidenceTodoAppliedRules as number[]).map(
            (idOrganizationResidenceTodoAppliedRule: number) => ({
              idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
              idOrganizationResidenceTodoAppliedRule
            })
          ) as OrganizationResidenceTodoAppliedRule[]
        })
      );
      actions.push(
        organizationResidenceTodoActions.addManyOrganizationResidenceTodoAppliedRuleSuccess({
          idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
          idOrganizationResidenceTodoAppliedRules: ids.organizationResidenceTodoAppliedRules as number[]
        })
      );
    }
  }

  if (ids?.organizationResidenceTodoProfils) {
    if (!Array.isArray(ids.organizationResidenceTodoProfils)) {
      actions.push(
        organizationResidenceTodoProfilActions.upsertOneOrganizationResidenceTodoProfil({
          organizationResidenceTodoProfil: {
            idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
            idOrganizationResidenceTodoProfil: ids.organizationResidenceTodoProfils as number
          } as OrganizationResidenceTodoProfil
        })
      );
      actions.push(
        organizationResidenceTodoActions.addManyOrganizationResidenceTodoProfilSuccess({
          idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
          idOrganizationResidenceTodoProfils: [ids.organizationResidenceTodoProfils as number]
        })
      );
    } else {
      actions.push(
        organizationResidenceTodoProfilActions.upsertManyOrganizationResidenceTodoProfils({
          organizationResidenceTodoProfils: (ids.organizationResidenceTodoProfils as number[]).map(
            (idOrganizationResidenceTodoProfil: number) => ({
              idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
              idOrganizationResidenceTodoProfil
            })
          ) as OrganizationResidenceTodoProfil[]
        })
      );
      actions.push(
        organizationResidenceTodoActions.addManyOrganizationResidenceTodoProfilSuccess({
          idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
          idOrganizationResidenceTodoProfils: ids.organizationResidenceTodoProfils as number[]
        })
      );
    }
  }

  if (ids?.companyCommunications) {
    if (!Array.isArray(ids.companyCommunications)) {
      actions.push(
        companyCommunicationActions.upsertOneCompanyCommunication({
          companyCommunication: {
            idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
            idCompanyCommunication: ids.companyCommunications as number
          } as CompanyCommunication & any
        })
      );
      actions.push(
        organizationResidenceTodoActions.addManyCompanyCommunicationSuccess({
          idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
          idCompanyCommunications: [ids.companyCommunications as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationActions.upsertManyCompanyCommunications({
          companyCommunications: (ids.companyCommunications as number[]).map((idCompanyCommunication: number) => ({
            idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
            idCompanyCommunication
          })) as CompanyCommunication[] & any[]
        })
      );
      actions.push(
        organizationResidenceTodoActions.addManyCompanyCommunicationSuccess({
          idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
          idCompanyCommunications: ids.companyCommunications as number[]
        })
      );
    }
  }

  if (ids?.companyCommunicationResidenceTodos) {
    if (!Array.isArray(ids.companyCommunicationResidenceTodos)) {
      actions.push(
        companyCommunicationResidenceTodoActions.upsertOneCompanyCommunicationResidenceTodo({
          companyCommunicationResidenceTodo: {
            idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
            idCompanyCommunicationResidenceTodo: ids.companyCommunicationResidenceTodos as number
          } as CompanyCommunicationResidenceTodo
        })
      );
      actions.push(
        organizationResidenceTodoActions.addManyCompanyCommunicationResidenceTodoSuccess({
          idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
          idCompanyCommunicationResidenceTodos: [ids.companyCommunicationResidenceTodos as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationResidenceTodoActions.upsertManyCompanyCommunicationResidenceTodos({
          companyCommunicationResidenceTodos: (ids.companyCommunicationResidenceTodos as number[]).map(
            (idCompanyCommunicationResidenceTodo: number) => ({
              idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
              idCompanyCommunicationResidenceTodo
            })
          ) as CompanyCommunicationResidenceTodo[]
        })
      );
      actions.push(
        organizationResidenceTodoActions.addManyCompanyCommunicationResidenceTodoSuccess({
          idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo,
          idCompanyCommunicationResidenceTodos: ids.companyCommunicationResidenceTodos as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationResidenceTodoActions(
  organizationResidenceTodo: OrganizationResidenceTodoEntityState
): Action[] {
  const actions: Action[] = [
    organizationResidenceTodoActions.deleteOneOrganizationResidenceTodoSuccess({
      idOrganizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo
    })
  ];

  if (organizationResidenceTodo.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationResidenceTodoSuccess({
        idOrganizationResidenceTodos: [organizationResidenceTodo.idOrganizationResidenceTodo],
        idOrganizations: [organizationResidenceTodo.organization as number]
      })
    );
  }

  if (organizationResidenceTodo.diagnostic) {
    actions.push(
      diagnosticActions.deleteManyOrganizationResidenceTodoSuccess({
        idOrganizationResidenceTodos: [organizationResidenceTodo.idOrganizationResidenceTodo],
        idDiagnostics: [organizationResidenceTodo.diagnostic as number]
      })
    );
  }

  if (organizationResidenceTodo.residenceTodos) {
    actions.push(
      residenceTodoActions.deleteManyOrganizationResidenceTodoSuccess({
        idOrganizationResidenceTodos: [organizationResidenceTodo.idOrganizationResidenceTodo],
        idResidenceTodos: organizationResidenceTodo.residenceTodos as number[]
      })
    );
  }

  if (organizationResidenceTodo.organizationResidenceTodoRules) {
    actions.push(
      organizationResidenceTodoRuleActions.deleteManyOrganizationResidenceTodoSuccess({
        idOrganizationResidenceTodos: [organizationResidenceTodo.idOrganizationResidenceTodo],
        idOrganizationResidenceTodoRules: organizationResidenceTodo.organizationResidenceTodoRules as number[]
      })
    );
  }

  if (organizationResidenceTodo.organizationResidenceTodoAppliedRules) {
    actions.push(
      organizationResidenceTodoAppliedRuleActions.deleteManyOrganizationResidenceTodoSuccess({
        idOrganizationResidenceTodos: [organizationResidenceTodo.idOrganizationResidenceTodo],
        idOrganizationResidenceTodoAppliedRules:
          organizationResidenceTodo.organizationResidenceTodoAppliedRules as number[]
      })
    );
  }

  if (organizationResidenceTodo.organizationResidenceTodoProfils) {
    actions.push(
      organizationResidenceTodoProfilActions.deleteManyOrganizationResidenceTodoSuccess({
        idOrganizationResidenceTodos: [organizationResidenceTodo.idOrganizationResidenceTodo],
        idOrganizationResidenceTodoProfils: organizationResidenceTodo.organizationResidenceTodoProfils as number[]
      })
    );
  }

  if (organizationResidenceTodo.companyCommunications) {
    actions.push(
      companyCommunicationActions.deleteManyOrganizationResidenceTodoSuccess({
        idOrganizationResidenceTodos: [organizationResidenceTodo.idOrganizationResidenceTodo],
        idCompanyCommunications: organizationResidenceTodo.companyCommunications as number[]
      })
    );
  }

  if (organizationResidenceTodo.companyCommunicationResidenceTodos) {
    actions.push(
      companyCommunicationResidenceTodoActions.deleteManyOrganizationResidenceTodoSuccess({
        idOrganizationResidenceTodos: [organizationResidenceTodo.idOrganizationResidenceTodo],
        idCompanyCommunicationResidenceTodos: organizationResidenceTodo.companyCommunicationResidenceTodos as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationResidenceTodoEffects {
  constructor(
    protected actions$: Actions,
    protected organizationResidenceTodoApiService: OrganizationResidenceTodoApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationResidenceTodos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceTodoActions.getManyOrganizationResidenceTodos),
      switchMap(({ params }) =>
        this.organizationResidenceTodoApiService.getOrganizationResidenceTodos(params).pipe(
          map((organizationResidenceTodos: OrganizationResidenceTodo[]) => {
            return organizationResidenceTodoActions.normalizeManyOrganizationResidenceTodosAfterUpsert({
              organizationResidenceTodos
            });
          }),
          catchError(error => of(organizationResidenceTodoActions.organizationResidenceTodosFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationResidenceTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceTodoActions.getOneOrganizationResidenceTodo),
      switchMap(idOrganizationResidenceTodo =>
        this.organizationResidenceTodoApiService.getOrganizationResidenceTodo(idOrganizationResidenceTodo).pipe(
          map((organizationResidenceTodo: OrganizationResidenceTodo) => {
            return organizationResidenceTodoActions.normalizeManyOrganizationResidenceTodosAfterUpsert({
              organizationResidenceTodos: [organizationResidenceTodo]
            });
          }),
          catchError(error => of(organizationResidenceTodoActions.organizationResidenceTodosFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationResidenceTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceTodoActions.upsertOneOrganizationResidenceTodo),
      concatMap(
        ({
          organizationResidenceTodo,
          ids
        }: {
          organizationResidenceTodo: Partial<OrganizationResidenceTodo>;
          ids?: OrganizationResidenceTodoRelationsIds;
        }) => {
          if (organizationResidenceTodo.idOrganizationResidenceTodo) {
            return this.organizationResidenceTodoApiService
              .updateOrganizationResidenceTodo(organizationResidenceTodo)
              .pipe(
                map((organizationResidenceTodoReturned: OrganizationResidenceTodo) => {
                  return organizationResidenceTodoActions.normalizeManyOrganizationResidenceTodosAfterUpsert({
                    organizationResidenceTodos: [organizationResidenceTodoReturned]
                  });
                }),
                catchError(error => of(organizationResidenceTodoActions.organizationResidenceTodosFailure({ error })))
              );
          } else {
            return this.organizationResidenceTodoApiService
              .addOrganizationResidenceTodo(organizationResidenceTodo)
              .pipe(
                mergeMap((organizationResidenceTodoReturned: OrganizationResidenceTodo) =>
                  getDefaultAddOrganizationResidenceTodoActions(organizationResidenceTodoReturned, ids)
                ),
                catchError(error => of(organizationResidenceTodoActions.organizationResidenceTodosFailure({ error })))
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationResidenceTodo$ = createEffect(() => {
    const selectOrganizationResidenceTodoState$ = this.store$.select(selectOrganizationResidenceTodoState);
    return this.actions$.pipe(
      ofType(organizationResidenceTodoActions.deleteOneOrganizationResidenceTodo),
      withLatestFrom(selectOrganizationResidenceTodoState$),
      concatMap(([{ idOrganizationResidenceTodo }, state]) =>
        this.organizationResidenceTodoApiService.deleteOrganizationResidenceTodo(idOrganizationResidenceTodo).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOrganizationResidenceTodoActions(
                state.entities[idOrganizationResidenceTodo] as OrganizationResidenceTodoEntityState
              ),
              organizationResidenceTodoActions.deleteOneOrganizationResidenceTodo.type
            )
          ]),
          catchError(error => of(organizationResidenceTodoActions.organizationResidenceTodosFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationResidenceTodosAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceTodoActions.normalizeManyOrganizationResidenceTodosAfterUpsert),
      concatMap(({ organizationResidenceTodos }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationResidenceTodo(
          organizationResidenceTodos,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationResidenceTodo] Normalization After Upsert Success')];
      })
    );
  });
}
