import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CompanyTerritoireUser,
  CompanyTerritoireUserEntityState
} from '@_model/interfaces/company-territoire-user.model';
import { CompanyTerritoire, CompanyTerritoireEntityState } from '@_model/interfaces/company-territoire.model';
import { User, UserEntityState } from '@_model/interfaces/user.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, companyTerritoireUserFeatureKey, CompanyTerritoireUserState } from './company-territoire-user.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyTerritoireUserRelations: string[] = ['companyTerritoires', 'users'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyTerritoireUserState = createFeatureSelector<CompanyTerritoireUserState>(
  companyTerritoireUserFeatureKey
);

export const selectIsLoadedCompanyTerritoireUser = createSelector(
  selectCompanyTerritoireUserState,
  (state: CompanyTerritoireUserState) => state.isLoaded
);

export const selectIsLoadingCompanyTerritoireUser = createSelector(
  selectCompanyTerritoireUserState,
  (state: CompanyTerritoireUserState) => state.isLoading
);

export const selectIsReadyCompanyTerritoireUser = createSelector(
  selectCompanyTerritoireUserState,
  (state: CompanyTerritoireUserState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyTerritoireUser = createSelector(
  selectCompanyTerritoireUserState,
  (state: CompanyTerritoireUserState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyTerritoireUserModel: SelectorModel = {
  name: 'companyTerritoireUsers',
  getSelector: selectAllCompanyTerritoireUsersDictionary,
  isReady: selectIsReadyCompanyTerritoireUser
};

export const selectCompanyTerritoireUsersEntities = createSelector(selectCompanyTerritoireUserState, selectEntities);

export const selectCompanyTerritoireUsersArray = createSelector(selectCompanyTerritoireUserState, selectAll);

export const selectIdCompanyTerritoireUsersActive = createSelector(
  selectCompanyTerritoireUserState,
  (state: CompanyTerritoireUserState) => state.actives
);

const companyTerritoireUsersInObject = (companyTerritoireUsers: Dictionary<CompanyTerritoireUserEntityState>) => ({
  companyTerritoireUsers
});

const selectCompanyTerritoireUsersEntitiesDictionary = createSelector(
  selectCompanyTerritoireUsersEntities,
  companyTerritoireUsersInObject
);

const selectAllCompanyTerritoireUsersObject = createSelector(
  selectCompanyTerritoireUsersEntities,
  companyTerritoireUsers => {
    return hydrateAll({ companyTerritoireUsers });
  }
);

const selectOneCompanyTerritoireUserDictionary = (idCompanyTerritoireUser: number) =>
  createSelector(selectCompanyTerritoireUsersEntities, companyTerritoireUsers => {
    return { companyTerritoireUsers: { [idCompanyTerritoireUser]: companyTerritoireUsers[idCompanyTerritoireUser] } };
  });

const selectOneCompanyTerritoireUserDictionaryWithoutChild = (idCompanyTerritoireUser: number) =>
  createSelector(selectCompanyTerritoireUsersEntities, companyTerritoireUsers => {
    return { companyTerritoireUser: companyTerritoireUsers[idCompanyTerritoireUser] };
  });

const selectActiveCompanyTerritoireUsersEntities = createSelector(
  selectIdCompanyTerritoireUsersActive,
  selectCompanyTerritoireUsersEntities,
  (actives: number[], companyTerritoireUsers: Dictionary<CompanyTerritoireUserEntityState>) =>
    getCompanyTerritoireUsersFromActives(actives, companyTerritoireUsers)
);

function getCompanyTerritoireUsersFromActives(
  actives: number[],
  companyTerritoireUsers: Dictionary<CompanyTerritoireUserEntityState>
): Dictionary<CompanyTerritoireUserEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyTerritoireUsers[idActive]) {
      acc[idActive] = companyTerritoireUsers[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyTerritoireUserEntityState>);
}

const selectAllCompanyTerritoireUsersSelectors: Dictionary<Selector> = {};
export function selectAllCompanyTerritoireUsers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyTerritoireUser>(
      schema,
      selectAllCompanyTerritoireUsersSelectors,
      selectCompanyTerritoireUsersEntitiesDictionary,
      getRelationSelectors,
      companyTerritoireUserRelations,
      hydrateAll,
      'companyTerritoireUser'
    );
  } else {
    return selectAllCompanyTerritoireUsersObject;
  }
}

export function selectAllCompanyTerritoireUsersDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyTerritoireUsers'
): Selector {
  return createSelector(selectAllCompanyTerritoireUsers(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyTerritoireUserEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyTerritoireUsers.length; i++) {
      res[customKey][result.companyTerritoireUsers[i].idCompanyTerritoireUser] = result.companyTerritoireUsers[i];
    }
    return res;
  });
}

export function selectOneCompanyTerritoireUser(schema: SelectSchema = {}, idCompanyTerritoireUser: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyTerritoireUserDictionary(idCompanyTerritoireUser)];
    selectors.push(...getRelationSelectors(schema, companyTerritoireUserRelations, 'companyTerritoireUser'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyTerritoireUserDictionaryWithoutChild(idCompanyTerritoireUser);
  }
}

export function selectActiveCompanyTerritoireUsers(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyTerritoireUsersEntities, companyTerritoireUsers => ({ companyTerritoireUsers }))
  ];
  selectors.push(...getRelationSelectors(schema, companyTerritoireUserRelations, 'companyTerritoireUser'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyTerritoireUsers: Dictionary<CompanyTerritoireUserEntityState>;
  companyTerritoires?: Dictionary<CompanyTerritoireEntityState>;
  users?: Dictionary<UserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { companyTerritoireUsers: (CompanyTerritoireUser | null)[] } {
  const { companyTerritoireUsers, companyTerritoires, users } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    companyTerritoireUsers: Object.keys(companyTerritoireUsers).map(idCompanyTerritoireUser =>
      hydrate(
        companyTerritoireUsers[idCompanyTerritoireUser] as CompanyTerritoireUserEntityState,
        companyTerritoires,
        users
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { companyTerritoireUser: CompanyTerritoireUserEntityState | null } {
  const { companyTerritoireUsers, companyTerritoires, users } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const companyTerritoireUser = Object.values(companyTerritoireUsers)[0];
  return {
    companyTerritoireUser: hydrate(companyTerritoireUser as CompanyTerritoireUserEntityState, companyTerritoires, users)
  };
}

function hydrate(
  companyTerritoireUser: CompanyTerritoireUserEntityState,
  companyTerritoireEntities?: Dictionary<CompanyTerritoireEntityState>,
  userEntities?: Dictionary<UserEntityState>
): CompanyTerritoireUser | null {
  if (!companyTerritoireUser) {
    return null;
  }

  const companyTerritoireUserHydrated: CompanyTerritoireUserEntityState = { ...companyTerritoireUser };
  if (companyTerritoireEntities) {
    companyTerritoireUserHydrated.companyTerritoire = companyTerritoireEntities[
      companyTerritoireUser.companyTerritoire as number
    ] as CompanyTerritoire;
  } else {
    delete companyTerritoireUserHydrated.companyTerritoire;
  }
  if (userEntities) {
    companyTerritoireUserHydrated.user = userEntities[companyTerritoireUser.user as number] as User;
  } else {
    delete companyTerritoireUserHydrated.user;
  }

  return companyTerritoireUserHydrated as CompanyTerritoireUser;
}
