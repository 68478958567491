import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  ResidencePriceGridModifier,
  ResidencePriceGridModifierEntityState
} from '@_model/interfaces/residence-price-grid-modifier.model';
import { ResidencePriceGridModifierRelationsIds } from './residence-price-grid-modifier-generated.effects';

export const getOneResidencePriceGridModifier = createAction(
  '[ResidencePriceGridModifier] Get One ResidencePriceGridModifier',
  props<{ idResidencePriceGridModifier: number; params?: any }>()
);

export const getManyResidencePriceGridModifiers = createAction(
  '[ResidencePriceGridModifier] Get Many ResidencePriceGridModifiers',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ResidencePriceGridModifier] Add Many Actives ResidencePriceGridModifier',
  props<{ idResidencePriceGridModifiers: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidencePriceGridModifier] Delete One Active ResidencePriceGridModifier',
  props<{ idResidencePriceGridModifier: number }>()
);

export const clearActive = createAction('[ResidencePriceGridModifier] Clear Active ResidencePriceGridModifier');

export const upsertOneResidencePriceGridModifier = createAction(
  '[ResidencePriceGridModifier] Upsert One ResidencePriceGridModifier',
  props<{
    residencePriceGridModifier: Partial<ResidencePriceGridModifier>;
    ids?: ResidencePriceGridModifierRelationsIds;
  }>()
);

export const upsertManyResidencePriceGridModifiers = createAction(
  '[ResidencePriceGridModifier] Upsert Many ResidencePriceGridModifiers',
  props<{
    residencePriceGridModifiers: Partial<ResidencePriceGridModifier>[];
    ids?: ResidencePriceGridModifierRelationsIds;
  }>()
);

export const upsertManyResidencePriceGridModifiersSuccess = createAction(
  '[ResidencePriceGridModifier] Create Many ResidencePriceGridModifiers Success',
  props<{ residencePriceGridModifiers: ResidencePriceGridModifierEntityState[] }>()
);

export const deleteOneResidencePriceGridModifier = createAction(
  '[ResidencePriceGridModifier] Delete One ResidencePriceGridModifier',
  props<{ idResidencePriceGridModifier: number }>()
);

export const deleteOneResidencePriceGridModifierSuccess = createAction(
  '[ResidencePriceGridModifier] Delete One ResidencePriceGridModifier Success',
  props<{ idResidencePriceGridModifier: number }>()
);

export const normalizeManyResidencePriceGridModifiersAfterUpsert = createAction(
  '[ResidencePriceGridModifier] Normalize Many ResidencePriceGridModifiers After Upsert',
  props<{ residencePriceGridModifiers: ResidencePriceGridModifierEntityState[] }>()
);

export const residencePriceGridModifiersFailure = createAction(
  '[ResidencePriceGridModifier] ResidencePriceGridModifiers Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidencePriceGridModifier] Clear ResidencePriceGridModifiers');

export const addResidencePriceGridSuccess = createAction(
  '[ResidencePriceGridModifier] Add ResidencePriceGrid',
  props<{ idResidencePriceGridModifier: number; idResidencePriceGrid: number }>()
);

export const deleteManyResidencePriceGridSuccess = createAction(
  '[ResidencePriceGridModifier] Delete Many ResidencePriceGrid',
  props<{ idResidencePriceGrids: number[]; idResidencePriceGridModifiers: number[] }>()
);

export const addCompanyPriceLabelSuccess = createAction(
  '[ResidencePriceGridModifier] Add CompanyPriceLabel',
  props<{ idResidencePriceGridModifier: number; idCompanyPriceLabel: number }>()
);

export const deleteManyCompanyPriceLabelSuccess = createAction(
  '[ResidencePriceGridModifier] Delete Many CompanyPriceLabel',
  props<{ idCompanyPriceLabels: number[]; idResidencePriceGridModifiers: number[] }>()
);
