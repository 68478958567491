import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as UserActions from './user.actions';
import { adapter, initialState, UserState } from './user.state';
import { Dictionary } from '@ngrx/entity';
import { UserEntityState } from '@_model/interfaces/user.model';

export const userReducersGeneratedFunctions: ReducerTypes<UserState, readonly ActionCreator[]>[] = [
  on(UserActions.getOneUser, (state: UserState) => setLoadingsState(state, true)),
  on(UserActions.getManyUsers, (state: UserState) => setLoadingsState(state, true)),
  on(UserActions.upsertOneUser, (state: UserState) => setLoadingsState(state, true)),

  on(UserActions.upsertManyUsersSuccess, (state: UserState, { users }) =>
    adapter.upsertMany(users, setLoadingsState(state, false))
  ),
  on(UserActions.deleteOneUser, (state: UserState) => setLoadingsState(state, true)),
  on(UserActions.deleteOneUserSuccess, (state: UserState, { idUser }) =>
    adapter.removeOne(idUser, setLoadingsState(state, false))
  ),
  on(UserActions.clearActive, (state: UserState) => ({ ...state, actives: [] })),
  on(UserActions.addManyActives, (state: UserState, { idUsers }) => ({
    ...state,
    actives: state.actives.concat(idUsers)
  })),
  on(UserActions.deleteOneActive, (state: UserState, { idUser }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idUser)
  })),

  on(UserActions.clearStore, () => initialState),

  on(UserActions.addManyBoardStateSuccess, (state: UserState, { idUser, idBoardStates }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const boardStates = (state.entities[idUser]?.boardStates as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          boardStates: boardStates.concat(idBoardStates.filter(id => boardStates.indexOf(id) < 0))
        }
      }
    };
  }),

  on(UserActions.deleteManyBoardStateSuccess, (state: UserState, { idBoardStates, idUsers }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUsers.reduce((entities, idUser) => {
          if (!state.entities[idUser]?.boardStates) {
            return entities;
          }
          entities[idUser] = {
            ...state.entities[idUser],
            boardStates: (state.entities[idUser]?.boardStates as number[])?.filter(
              (idBoardState: number) => !idBoardStates.some((id: number) => id === idBoardState)
            )
          } as UserEntityState;
          return entities;
        }, {} as Dictionary<UserEntityState>)
      }
    };
  }),

  on(UserActions.addManyCompanyTerritoireUserSuccess, (state: UserState, { idUser, idCompanyTerritoireUsers }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const companyTerritoireUsers = (state.entities[idUser]?.companyTerritoireUsers as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          companyTerritoireUsers: companyTerritoireUsers.concat(
            idCompanyTerritoireUsers.filter(id => companyTerritoireUsers.indexOf(id) < 0)
          )
        }
      }
    };
  }),

  on(UserActions.deleteManyCompanyTerritoireUserSuccess, (state: UserState, { idCompanyTerritoireUsers, idUsers }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUsers.reduce((entities, idUser) => {
          if (!state.entities[idUser]?.companyTerritoireUsers) {
            return entities;
          }
          entities[idUser] = {
            ...state.entities[idUser],
            companyTerritoireUsers: (state.entities[idUser]?.companyTerritoireUsers as number[])?.filter(
              (idCompanyTerritoireUser: number) =>
                !idCompanyTerritoireUsers.some((id: number) => id === idCompanyTerritoireUser)
            )
          } as UserEntityState;
          return entities;
        }, {} as Dictionary<UserEntityState>)
      }
    };
  }),

  on(
    UserActions.addManyResidencePriceGridHistorySuccess,
    (state: UserState, { idUser, idResidencePriceGridHistories }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const residencePriceGridHistories = (state.entities[idUser]?.residencePriceGridHistories as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            residencePriceGridHistories: residencePriceGridHistories.concat(
              idResidencePriceGridHistories.filter(id => residencePriceGridHistories.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserActions.deleteManyResidencePriceGridHistorySuccess,
    (state: UserState, { idResidencePriceGridHistories, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.residencePriceGridHistories) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              residencePriceGridHistories: (state.entities[idUser]?.residencePriceGridHistories as number[])?.filter(
                (idResidencePriceGridHistory: number) =>
                  !idResidencePriceGridHistories.some((id: number) => id === idResidencePriceGridHistory)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(UserActions.addManyCompanyTerritoireSuccess, (state: UserState, { idUser, idCompanyTerritoire }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const companyTerritoires = (state.entities[idUser]?.companyTerritoires as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          companyTerritoires: companyTerritoires.concat(
            idCompanyTerritoire.filter(id => companyTerritoires.indexOf(id) < 0)
          )
        }
      }
    };
  }),

  on(
    UserActions.deleteManyCompanyTerritoireSuccess,
    (state: UserState, { idCompanyTerritoire: idCompanyTerritoires, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.companyTerritoires) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              companyTerritoires: (state.entities[idUser]?.companyTerritoires as number[])?.filter(
                (idCompanyTerritoire: number) => !idCompanyTerritoires.some((id: number) => id === idCompanyTerritoire)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(UserActions.addManyProspectResponsibleOfSuccess, (state: UserState, { idUser, idProspectResponsibleOf }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const prospects = (state.entities[idUser]?.prospectResponsibleOf as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          prospects: prospects.concat(idProspectResponsibleOf.filter(id => prospects.indexOf(id) < 0))
        }
      }
    };
  }),

  on(
    UserActions.deleteManyProspectResponsibleOfSuccess,
    (state: UserState, { idProspectResponsibleOf: idProspectResponsibleOfs, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.prospectResponsibleOf) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              prospects: (state.entities[idUser]?.prospectResponsibleOf as number[])?.filter(
                (idProspect: number) => !idProspectResponsibleOfs.some((id: number) => id === idProspect)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(UserActions.addManyProspectUpdaterSuccess, (state: UserState, { idUser, idProspectUpdater }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const prospects = (state.entities[idUser]?.prospectUpdater as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          prospects: prospects.concat(idProspectUpdater.filter(id => prospects.indexOf(id) < 0))
        }
      }
    };
  }),

  on(
    UserActions.deleteManyProspectUpdaterSuccess,
    (state: UserState, { idProspectUpdater: idProspectUpdaters, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.prospectUpdater) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              prospects: (state.entities[idUser]?.prospectUpdater as number[])?.filter(
                (idProspect: number) => !idProspectUpdaters.some((id: number) => id === idProspect)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(UserActions.addManyProspectCreatorSuccess, (state: UserState, { idUser, idProspectCreator }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const prospects = (state.entities[idUser]?.prospectCreator as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          prospects: prospects.concat(idProspectCreator.filter(id => prospects.indexOf(id) < 0))
        }
      }
    };
  }),

  on(
    UserActions.deleteManyProspectCreatorSuccess,
    (state: UserState, { idProspectCreator: idProspectCreators, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.prospectCreator) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              prospects: (state.entities[idUser]?.prospectCreator as number[])?.filter(
                (idProspect: number) => !idProspectCreators.some((id: number) => id === idProspect)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(UserActions.addManyResidenceSuccess, (state: UserState, { idUser, idResidence }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const residences = (state.entities[idUser]?.residences as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          residences: residences.concat(idResidence.filter(id => residences.indexOf(id) < 0))
        }
      }
    };
  }),

  on(UserActions.deleteManyResidenceSuccess, (state: UserState, { idResidence: idResidences, idUsers }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUsers.reduce((entities, idUser) => {
          if (!state.entities[idUser]?.residences) {
            return entities;
          }
          entities[idUser] = {
            ...state.entities[idUser],
            residences: (state.entities[idUser]?.residences as number[])?.filter(
              (idResidence: number) => !idResidences.some((id: number) => id === idResidence)
            )
          } as UserEntityState;
          return entities;
        }, {} as Dictionary<UserEntityState>)
      }
    };
  }),

  on(UserActions.addManyStratalotSuccess, (state: UserState, { idUser, idStratalot }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const stratalots = (state.entities[idUser]?.stratalots as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          stratalots: stratalots.concat(idStratalot.filter(id => stratalots.indexOf(id) < 0))
        }
      }
    };
  }),

  on(UserActions.deleteManyStratalotSuccess, (state: UserState, { idStratalot: idStratalots, idUsers }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUsers.reduce((entities, idUser) => {
          if (!state.entities[idUser]?.stratalots) {
            return entities;
          }
          entities[idUser] = {
            ...state.entities[idUser],
            stratalots: (state.entities[idUser]?.stratalots as number[])?.filter(
              (idStratalot: number) => !idStratalots.some((id: number) => id === idStratalot)
            )
          } as UserEntityState;
          return entities;
        }, {} as Dictionary<UserEntityState>)
      }
    };
  }),

  on(UserActions.addManyProspectEventSuccess, (state: UserState, { idUser, idProspectEvents }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const prospectEvents = (state.entities[idUser]?.prospectEvents as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          prospectEvents: prospectEvents.concat(idProspectEvents.filter(id => prospectEvents.indexOf(id) < 0))
        }
      }
    };
  }),

  on(UserActions.deleteManyProspectEventSuccess, (state: UserState, { idProspectEvents, idUsers }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUsers.reduce((entities, idUser) => {
          if (!state.entities[idUser]?.prospectEvents) {
            return entities;
          }
          entities[idUser] = {
            ...state.entities[idUser],
            prospectEvents: (state.entities[idUser]?.prospectEvents as number[])?.filter(
              (idProspectEvent: number) => !idProspectEvents.some((id: number) => id === idProspectEvent)
            )
          } as UserEntityState;
          return entities;
        }, {} as Dictionary<UserEntityState>)
      }
    };
  }),

  on(UserActions.addManyProfilSuccess, (state: UserState, { idUser, idProfils }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    const profils = (state.entities[idUser]?.profils as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          profils: profils.concat(idProfils.filter(id => profils.indexOf(id) < 0))
        }
      }
    };
  }),

  on(UserActions.deleteManyProfilSuccess, (state: UserState, { idProfils, idUsers }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUsers.reduce((entities, idUser) => {
          if (!state.entities[idUser]?.profils) {
            return entities;
          }
          entities[idUser] = {
            ...state.entities[idUser],
            profils: (state.entities[idUser]?.profils as number[])?.filter(
              (idProfil: number) => !idProfils.some((id: number) => id === idProfil)
            )
          } as UserEntityState;
          return entities;
        }, {} as Dictionary<UserEntityState>)
      }
    };
  }),

  on(
    UserActions.addManyCompanyCommunicationRecipientSuccess,
    (state: UserState, { idUser, idCompanyCommunicationRecipients }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const companyCommunicationRecipients = (state.entities[idUser]?.companyCommunicationRecipients as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            companyCommunicationRecipients: companyCommunicationRecipients.concat(
              idCompanyCommunicationRecipients.filter(id => companyCommunicationRecipients.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserActions.deleteManyCompanyCommunicationRecipientSuccess,
    (state: UserState, { idCompanyCommunicationRecipients, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.companyCommunicationRecipients) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              companyCommunicationRecipients: (
                state.entities[idUser]?.companyCommunicationRecipients as number[]
              )?.filter(
                (idCompanyCommunicationRecipient: number) =>
                  !idCompanyCommunicationRecipients.some((id: number) => id === idCompanyCommunicationRecipient)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(
    UserActions.addManyCompanyCommunicationRecipientUserSuccess,
    (state: UserState, { idUser, idCompanyCommunicationRecipientUsers }) => {
      if (!state.entities[idUser]) {
        return state;
      }
      const companyCommunicationRecipientUsers =
        (state.entities[idUser]?.companyCommunicationRecipientUsers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idUser]: {
            ...state.entities[idUser],
            companyCommunicationRecipientUsers: companyCommunicationRecipientUsers.concat(
              idCompanyCommunicationRecipientUsers.filter(id => companyCommunicationRecipientUsers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    UserActions.deleteManyCompanyCommunicationRecipientUserSuccess,
    (state: UserState, { idCompanyCommunicationRecipientUsers, idUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idUsers.reduce((entities, idUser) => {
            if (!state.entities[idUser]?.companyCommunicationRecipientUsers) {
              return entities;
            }
            entities[idUser] = {
              ...state.entities[idUser],
              companyCommunicationRecipientUsers: (
                state.entities[idUser]?.companyCommunicationRecipientUsers as number[]
              )?.filter(
                (idCompanyCommunicationRecipientUser: number) =>
                  !idCompanyCommunicationRecipientUsers.some((id: number) => id === idCompanyCommunicationRecipientUser)
              )
            } as UserEntityState;
            return entities;
          }, {} as Dictionary<UserEntityState>)
        }
      };
    }
  ),

  on(UserActions.addOrganizationSuccess, (state: UserState, { idUser, idOrganization }) => {
    if (!state.entities[idUser]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idUser]: {
          ...state.entities[idUser],
          organization: idOrganization
        }
      }
    };
  }),

  on(UserActions.deleteManyOrganizationSuccess, (state: UserState, { idOrganizations, idUsers }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idUsers.reduce((entities, idUser) => {
          if (!state.entities[idUser]?.organization) {
            return entities;
          }
          entities[idUser] = {
            ...state.entities[idUser],
            organization: idOrganizations.some(
              (idOrganization: number) => idOrganization === state.entities[idUser]?.organization
            )
              ? undefined
              : state.entities[idUser]?.organization
          } as UserEntityState;
          return entities;
        }, {} as Dictionary<UserEntityState>)
      }
    };
  })
];

export function setLoadingsState(state: UserState, isLoading: boolean, isLoaded: boolean = true): UserState {
  return { ...state, isLoaded, isLoading };
}
