import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { StorageService } from '@_services/storage.service';
import { Observable, timer } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AuthService } from '@_services/auth.service';
import { LoginDataUser } from '@_model/interfaces/login-data-user.interface';

@Injectable({
  providedIn: 'root'
})
export class IsUserLogged implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private storageService: StorageService) {}

  private renewTryNb = 0;

  public canActivate(): Observable<boolean> | boolean {
    if (!this.authService.isLogged) {
      this.redirectToLogin();
      return false;
    }
    return this.authService.renew().pipe(
      map((userData: LoginDataUser): boolean => {
        this.storageService.setStorage('login', { token: userData.data.jwtToken });
        this.authService.getLoginStorage();
        return true;
      }),
      catchError((_err, caught) =>
        timer(500).pipe(
          mergeMap(() => {
            this.renewTryNb++;
            if (this.renewTryNb > 3) {
              this.redirectToLogin();
            }
            return caught;
          })
        )
      )
    );
  }

  private redirectToLogin(): void {
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }
}
