import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Lead } from '@_model/interfaces/lead.model';
import { LeadApiService } from '@_services/api/lead-api.service';
import { LeadStratalot } from '@_shared/models/interfaces/lead-stratalot.model';
import { LeadTodo } from '@_shared/models/interfaces/lead-todo.model';
import { ProspectEvent } from '@_shared/models/interfaces/prospect-event.model';
import { Stratalot } from '@_shared/models/interfaces/stratalot.model';
import { getMultiAction } from '@_store/batched-actions';
import { AppState } from '@_store/index.reducers';
import * as leadStratalotActions from '@_store/lead-stratalot/lead-stratalot.actions';
import * as leadTodoActions from '@_store/lead-todo/lead-todo.actions';
import * as leadActions from '@_store/lead/lead.actions';
import * as organizationLeadAvancementActions from '@_store/organization-lead-avancement/organization-lead-avancement.actions';
import * as prospectEventActions from '@_store/prospect-event/prospect-event.actions';
import * as prospectActions from '@_store/prospect/prospect.actions';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import ReduceUtils from '@_utils/reduce.utils';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { GeneratedLeadEffects, getDefaultDeleteLeadActions, LeadRelationsIds } from './lead-generated.effects';
import { selectLeadState } from './lead-generated.selectors';

@Injectable()
export class LeadEffects extends GeneratedLeadEffects {
  constructor(actions$: Actions, leadApiService: LeadApiService, store$: Store<AppState>) {
    super(actions$, leadApiService, store$);
  }

  public upsertOneLead$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadActions.upsertOneLead),
      concatMap(({ lead, ids }: { lead: Partial<Lead>; ids?: LeadRelationsIds }) => {
        if (lead.idLead) {
          return this.leadApiService.updateLeadWithSideEffect(lead).pipe(
            map((leadReturned: Lead | Lead[]) => {
              if (Array.isArray(leadReturned)) {
                return leadActions.normalizeManyLeadsAfterUpsert({ leads: leadReturned });
              }
              return leadActions.normalizeManyLeadsAfterUpsert({ leads: [leadReturned] });
            }),
            catchError(error => of(leadActions.leadsFailure({ error })))
          );
        } else {
          return this.leadApiService.addLead(lead).pipe(
            map((leadReturned: Lead | Lead[]) => {
              if (!Array.isArray(leadReturned)) {
                return [leadReturned];
              }
              return leadReturned;
            }),
            concatMap((leadReturned: Lead[]) => {
              // TODO : revoir les actions car créer nbLiaisons*nbLeadRetournés ça fait beaucoup d'actions alors qu'un seule pourrait suffir
              const actions: Action[] = [leadActions.normalizeManyLeadsAfterUpsert({ leads: leadReturned })];
              leadReturned.map(itLeadReturned => {
                if (ids.prospect) {
                  const action = prospectActions.addManyLeadSuccess({
                    idProspect: ids.prospect,
                    idLeads: [itLeadReturned.idLead]
                  });
                  actions.push(action);
                }

                if (ids.organizationLeadAvancement) {
                  const action = organizationLeadAvancementActions.addManyLeadSuccess({
                    idOrganizationLeadAvancement: ids.organizationLeadAvancement,
                    idLeads: [itLeadReturned.idLead]
                  });
                  actions.push(action);
                }

                if (ids.prospectEvents) {
                  if (!Array.isArray(ids.prospectEvents)) {
                    const action = prospectEventActions.upsertOneProspectEvent({
                      prospectEvent: {
                        idLead: itLeadReturned.idLead,
                        idProspectEvent: ids.prospectEvents as number
                      } as ProspectEvent
                    });
                    actions.push(action);
                  } else {
                    const action = prospectEventActions.upsertManyProspectEvents({
                      prospectEvents: (ids.prospectEvents as number[]).map((idProspectEvent: number) => ({
                        idLead: itLeadReturned.idLead,
                        idProspectEvent
                      })) as ProspectEvent[]
                    });
                    actions.push(action);
                  }
                }

                if (ids.stratalots) {
                  if (!Array.isArray(ids.stratalots)) {
                    const action = stratalotActions.upsertOneStratalot({
                      stratalot: {
                        idLead: itLeadReturned.idLead,
                        idStratalot: ids.stratalots as number
                      } as Stratalot & any
                    });
                    actions.push(action);
                  } else {
                    const action = stratalotActions.upsertManyStratalots({
                      stratalots: (ids.stratalots as number[]).map((idStratalot: number) => ({
                        idLead: itLeadReturned.idLead,
                        idStratalot
                      })) as Stratalot[] & any[]
                    });
                    actions.push(action);
                  }
                }

                if (ids.leadStratalots) {
                  if (!Array.isArray(ids.leadStratalots)) {
                    const action = leadStratalotActions.upsertOneLeadStratalot({
                      leadStratalot: {
                        idLead: itLeadReturned.idLead,
                        idLeadStratalot: ids.leadStratalots as number
                      } as LeadStratalot
                    });
                    actions.push(action);
                  } else {
                    const action = leadStratalotActions.upsertManyLeadStratalots({
                      leadStratalots: (ids.leadStratalots as number[]).map((idLeadStratalot: number) => ({
                        idLead: itLeadReturned.idLead,
                        idLeadStratalot
                      })) as LeadStratalot[]
                    });
                    actions.push(action);
                  }
                }

                if (ids.leadTodos) {
                  if (!Array.isArray(ids.leadTodos)) {
                    const action = leadTodoActions.upsertOneLeadTodo({
                      leadTodo: {
                        idLead: itLeadReturned.idLead,
                        idLeadTodo: ids.leadTodos as number
                      } as LeadTodo
                    });
                    actions.push(action);
                  } else {
                    const action = leadTodoActions.upsertManyLeadTodos({
                      leadTodos: (ids.leadTodos as number[]).map((idLeadTodo: number) => ({
                        idLead: itLeadReturned.idLead,
                        idLeadTodo
                      })) as LeadTodo[]
                    });
                    actions.push(action);
                  }
                }
              });

              return actions;
            }),
            catchError(error => of(leadActions.leadsFailure({ error })))
          );
        }
      })
    );
  });

  public getAllLeadsForSalesInProgressBoard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadActions.getAllLeadsForSalesInProgressBoard),
      switchMap(({ params }) =>
        this.leadApiService.getLeadsForSaleInProgressBoard(params).pipe(
          map((leads: Lead[]) => {
            return leadActions.normalizeManyLeadsAfterUpsert({ leads });
          }),
          catchError(error => of(leadActions.leadsFailure({ error })))
        )
      )
    );
  });

  public setMainStratalot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadActions.setMainStratalot),
      switchMap(({ idLead, idStratalot }) =>
        this.leadApiService.setMainStratalot(idLead, idStratalot).pipe(
          map(leadStratalots => {
            return leadStratalotActions.normalizeManyLeadStratalotsAfterUpsert({ leadStratalots });
          }),
          catchError(error => of(leadActions.leadsFailure({ error })))
        )
      )
    );
  });

  public updateManyLeads$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadActions.updateManyLeads),
      switchMap(({ partialLead, ids }) =>
        this.leadApiService.updateManyLeads(partialLead, ids).pipe(
          map(leads => {
            return leadActions.normalizeManyLeadsAfterUpsert({ leads });
          }),
          catchError(error => of(leadActions.leadsFailure({ error })))
        )
      )
    );
  });

  public getOneLeadWithLeadStratalots$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadActions.getOneLeadWithLeadStratalots),
      switchMap(({ idLead, params }) =>
        this.leadApiService.getOneLeadWithLeadStratalots({ idLead, params }).pipe(
          map((lead: Lead) => {
            return leadActions.normalizeManyLeadsAfterUpsert({ leads: [lead] });
          }),
          catchError(error => of(leadActions.leadsFailure({ error })))
        )
      )
    );
  });
  public deleteOneLeadWithUpdateStratalot$ = createEffect(() => {
    const selectLeadState$ = this.store$.select(selectLeadState);
    return this.actions$.pipe(
      ofType(leadActions.deleteOneLeadWithUpdateStratalot),
      withLatestFrom(selectLeadState$),
      map(([{ idLead }, state]) => [{ idLead }, { lead: state.entities[idLead] }]),
      concatMap(([{ idLead }, { lead }]) =>
        this.leadApiService.deleteOneLeadWithUpdateStratalot(idLead).pipe(
          mergeMap(objectReturn => [
            getMultiAction(
              [
                leadActions.upsertManyLeads({ leads: objectReturn.leads }),
                stratalotActions.upsertManyStratalots({ stratalots: objectReturn.stratalots }),
                ...getDefaultDeleteLeadActions(lead)
              ],
              leadActions.deleteOneLeadWithUpdateStratalot.type
            )
          ]),
          catchError(error => of(leadActions.leadsFailure({ error })))
        )
      )
    );
  });
  public closeSale$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadActions.closeSale),
      switchMap(patch =>
        this.leadApiService.closeSale(patch).pipe(
          mergeMap(lead => {
            const deleteOneLeadStratalotSuccessActions = lead.stratalots
              .map(s =>
                leadStratalotActions.deleteOneLeadStratalotSuccess({
                  idLeadStratalot: (s as any).leadStratalot.idLeadStratalot
                })
              )
              .reduce(ReduceUtils.arrayConcatenation, []);

            return [
              getMultiAction(
                [
                  leadActions.deleteOneLeadSuccess({ idLead: lead.idLead }),
                  prospectActions.deleteOneProspectSuccess({ idProspect: lead.idProspect }),
                  stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots: lead.stratalots }),
                  ...deleteOneLeadStratalotSuccessActions
                ],
                '[Lead] Close sale Success'
              )
            ];
          }),
          catchError(error => {
            return of(leadActions.leadsFailure({ error }));
          })
        )
      )
    );
  });
  public calculateTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadActions.calculateTodos),
      switchMap(() =>
        this.leadApiService.calculateTodo().pipe(
          mergeMap(() => {
            return [getMultiAction([], '[Lead]Calculate All Todo Success')];
          }),
          catchError(error => {
            return of(leadActions.leadsFailure({ error }));
          })
        )
      )
    );
  });
}
