import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  ResidenceForecastValue,
  ResidenceForecastValueEntityState
} from '@_model/interfaces/residence-forecast-value.model';
import { ResidenceForecastValueApiService } from '@_services/api/residence-forecast-value-api.service';
import * as residenceForecastValueActions from '@_store/residence-forecast-value/residence-forecast-value.actions';
import { getActionsToNormalizeResidenceForecastValue } from '@_config/store/normalization.generated';
import { selectResidenceForecastValueState } from './residence-forecast-value-generated.selectors';
import * as residenceForecastActions from '@_store/residence-forecast/residence-forecast.actions';

export interface ResidenceForecastValueRelationsIds {
  residenceForecast?: number;
}

export function getDefaultAddResidenceForecastValueActions(
  residenceForecastValue: ResidenceForecastValueEntityState,
  ids?: ResidenceForecastValueRelationsIds
): Action[] {
  const actions: Action[] = [
    residenceForecastValueActions.normalizeManyResidenceForecastValuesAfterUpsert({
      residenceForecastValues: [residenceForecastValue]
    })
  ];

  if (ids?.residenceForecast) {
    actions.push(
      residenceForecastActions.addManyResidenceForecastValueSuccess({
        idResidenceForecast: ids.residenceForecast,
        idResidenceForecastValues: [residenceForecastValue.idResidenceForecastValue]
      })
    );
    actions.push(
      residenceForecastValueActions.addResidenceForecastSuccess({
        idResidenceForecastValue: residenceForecastValue.idResidenceForecastValue,
        idResidenceForecast: ids.residenceForecast
      })
    );
  }

  return actions;
}

export function getDefaultDeleteResidenceForecastValueActions(
  residenceForecastValue: ResidenceForecastValueEntityState
): Action[] {
  const actions: Action[] = [
    residenceForecastValueActions.deleteOneResidenceForecastValueSuccess({
      idResidenceForecastValue: residenceForecastValue.idResidenceForecastValue
    })
  ];

  if (residenceForecastValue.residenceForecast) {
    actions.push(
      residenceForecastActions.deleteManyResidenceForecastValueSuccess({
        idResidenceForecastValues: [residenceForecastValue.idResidenceForecastValue],
        idResidenceForecasts: [residenceForecastValue.residenceForecast as number]
      })
    );
  }

  return actions;
}

export class GeneratedResidenceForecastValueEffects {
  constructor(
    protected actions$: Actions,
    protected residenceForecastValueApiService: ResidenceForecastValueApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidenceForecastValues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceForecastValueActions.getManyResidenceForecastValues),
      switchMap(({ params }) =>
        this.residenceForecastValueApiService.getResidenceForecastValues(params).pipe(
          map((residenceForecastValues: ResidenceForecastValue[]) => {
            return residenceForecastValueActions.normalizeManyResidenceForecastValuesAfterUpsert({
              residenceForecastValues
            });
          }),
          catchError(error => of(residenceForecastValueActions.residenceForecastValuesFailure({ error })))
        )
      )
    );
  });

  getOneResidenceForecastValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceForecastValueActions.getOneResidenceForecastValue),
      switchMap(idResidenceForecastValue =>
        this.residenceForecastValueApiService.getResidenceForecastValue(idResidenceForecastValue).pipe(
          map((residenceForecastValue: ResidenceForecastValue) => {
            return residenceForecastValueActions.normalizeManyResidenceForecastValuesAfterUpsert({
              residenceForecastValues: [residenceForecastValue]
            });
          }),
          catchError(error => of(residenceForecastValueActions.residenceForecastValuesFailure({ error })))
        )
      )
    );
  });

  upsertOneResidenceForecastValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceForecastValueActions.upsertOneResidenceForecastValue),
      concatMap(
        ({
          residenceForecastValue,
          ids
        }: {
          residenceForecastValue: Partial<ResidenceForecastValue>;
          ids?: ResidenceForecastValueRelationsIds;
        }) => {
          if (residenceForecastValue.idResidenceForecastValue) {
            return this.residenceForecastValueApiService.updateResidenceForecastValue(residenceForecastValue).pipe(
              map((residenceForecastValueReturned: ResidenceForecastValue) => {
                return residenceForecastValueActions.normalizeManyResidenceForecastValuesAfterUpsert({
                  residenceForecastValues: [residenceForecastValueReturned]
                });
              }),
              catchError(error => of(residenceForecastValueActions.residenceForecastValuesFailure({ error })))
            );
          } else {
            return this.residenceForecastValueApiService.addResidenceForecastValue(residenceForecastValue).pipe(
              mergeMap((residenceForecastValueReturned: ResidenceForecastValue) =>
                getDefaultAddResidenceForecastValueActions(residenceForecastValueReturned, ids)
              ),
              catchError(error => of(residenceForecastValueActions.residenceForecastValuesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneResidenceForecastValue$ = createEffect(() => {
    const selectResidenceForecastValueState$ = this.store$.select(selectResidenceForecastValueState);
    return this.actions$.pipe(
      ofType(residenceForecastValueActions.deleteOneResidenceForecastValue),
      withLatestFrom(selectResidenceForecastValueState$),
      concatMap(([{ idResidenceForecastValue }, state]) =>
        this.residenceForecastValueApiService.deleteResidenceForecastValue(idResidenceForecastValue).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidenceForecastValueActions(
                state.entities[idResidenceForecastValue] as ResidenceForecastValueEntityState
              ),
              residenceForecastValueActions.deleteOneResidenceForecastValue.type
            )
          ]),
          catchError(error => of(residenceForecastValueActions.residenceForecastValuesFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidenceForecastValuesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceForecastValueActions.normalizeManyResidenceForecastValuesAfterUpsert),
      concatMap(({ residenceForecastValues }) => {
        const actions: Action[] = getActionsToNormalizeResidenceForecastValue(
          residenceForecastValues,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[ResidenceForecastValue] Normalization After Upsert Success')];
      })
    );
  });
}
