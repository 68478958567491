import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { StepProgress, StepProgressEntityState } from '@_model/interfaces/step-progress.model';
import { StepProgressApiService } from '@_services/api/step-progress-api.service';
import * as stepProgressActions from '@_store/step-progress/step-progress.actions';
import { getActionsToNormalizeStepProgress } from '@_config/store/normalization.generated';
import { selectStepProgressState } from './step-progress-generated.selectors';
import * as stepActions from '@_store/step/step.actions';
import * as stepProgressFamilyActions from '@_store/step-progress-family/step-progress-family.actions';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import { Stratalot } from '@_model/interfaces/stratalot.model';

export interface StepProgressRelationsIds {
  stratalots?: number | number[];
  step?: number;
  stepProgressFamily?: number;
}

export function getDefaultAddStepProgressActions(
  stepProgress: StepProgressEntityState,
  ids?: StepProgressRelationsIds
): Action[] {
  const actions: Action[] = [
    stepProgressActions.normalizeManyStepProgressesAfterUpsert({ stepProgresses: [stepProgress] })
  ];

  if (ids?.step) {
    actions.push(
      stepActions.addManyStepProgressSuccess({
        idStep: ids.step,
        idStepProgresses: [stepProgress.idStepProgress]
      })
    );
    actions.push(
      stepProgressActions.addStepSuccess({
        idStepProgress: stepProgress.idStepProgress,
        idStep: ids.step
      })
    );
  }

  if (ids?.stepProgressFamily) {
    actions.push(
      stepProgressFamilyActions.addManyStepProgressSuccess({
        idStepProgressFamily: ids.stepProgressFamily,
        idStepProgresses: [stepProgress.idStepProgress]
      })
    );
    actions.push(
      stepProgressActions.addStepProgressFamilySuccess({
        idStepProgress: stepProgress.idStepProgress,
        idStepProgressFamily: ids.stepProgressFamily
      })
    );
  }

  if (ids?.stratalots) {
    if (!Array.isArray(ids.stratalots)) {
      actions.push(
        stratalotActions.upsertOneStratalot({
          stratalot: {
            idStepProgress: stepProgress.idStepProgress,
            idStratalot: ids.stratalots as number
          } as Stratalot
        })
      );
      actions.push(
        stepProgressActions.addManyStratalotSuccess({
          idStepProgress: stepProgress.idStepProgress,
          idStratalots: [ids.stratalots as number]
        })
      );
    } else {
      actions.push(
        stratalotActions.upsertManyStratalots({
          stratalots: (ids.stratalots as number[]).map((idStratalot: number) => ({
            idStepProgress: stepProgress.idStepProgress,
            idStratalot
          })) as Stratalot[]
        })
      );
      actions.push(
        stepProgressActions.addManyStratalotSuccess({
          idStepProgress: stepProgress.idStepProgress,
          idStratalots: ids.stratalots as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteStepProgressActions(stepProgress: StepProgressEntityState): Action[] {
  const actions: Action[] = [
    stepProgressActions.deleteOneStepProgressSuccess({ idStepProgress: stepProgress.idStepProgress })
  ];

  if (stepProgress.step) {
    actions.push(
      stepActions.deleteManyStepProgressSuccess({
        idStepProgresses: [stepProgress.idStepProgress],
        idSteps: [stepProgress.step as number]
      })
    );
  }

  if (stepProgress.stepProgressFamily) {
    actions.push(
      stepProgressFamilyActions.deleteManyStepProgressSuccess({
        idStepProgresses: [stepProgress.idStepProgress],
        idStepProgressesFamilies: [stepProgress.stepProgressFamily as number]
      })
    );
  }

  if (stepProgress.stratalots) {
    actions.push(
      stratalotActions.deleteManyStepProgressSuccess({
        idStepProgresses: [stepProgress.idStepProgress],
        idStratalots: stepProgress.stratalots as number[]
      })
    );
  }

  return actions;
}

export class GeneratedStepProgressEffects {
  constructor(
    protected actions$: Actions,
    protected stepProgressApiService: StepProgressApiService,
    protected store$: Store<AppState>
  ) {}

  getManyStepProgresses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stepProgressActions.getManyStepProgresses),
      switchMap(({ params }) =>
        this.stepProgressApiService.getStepProgresses(params).pipe(
          map((stepProgresses: StepProgress[]) => {
            return stepProgressActions.normalizeManyStepProgressesAfterUpsert({ stepProgresses });
          }),
          catchError(error => of(stepProgressActions.stepProgressesFailure({ error })))
        )
      )
    );
  });

  getOneStepProgress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stepProgressActions.getOneStepProgress),
      switchMap(idStepProgress =>
        this.stepProgressApiService.getStepProgress(idStepProgress).pipe(
          map((stepProgress: StepProgress) => {
            return stepProgressActions.normalizeManyStepProgressesAfterUpsert({ stepProgresses: [stepProgress] });
          }),
          catchError(error => of(stepProgressActions.stepProgressesFailure({ error })))
        )
      )
    );
  });

  upsertOneStepProgress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stepProgressActions.upsertOneStepProgress),
      concatMap(({ stepProgress, ids }: { stepProgress: Partial<StepProgress>; ids?: StepProgressRelationsIds }) => {
        if (stepProgress.idStepProgress) {
          return this.stepProgressApiService.updateStepProgress(stepProgress).pipe(
            map((stepProgressReturned: StepProgress) => {
              return stepProgressActions.normalizeManyStepProgressesAfterUpsert({
                stepProgresses: [stepProgressReturned]
              });
            }),
            catchError(error => of(stepProgressActions.stepProgressesFailure({ error })))
          );
        } else {
          return this.stepProgressApiService.addStepProgress(stepProgress).pipe(
            mergeMap((stepProgressReturned: StepProgress) =>
              getDefaultAddStepProgressActions(stepProgressReturned, ids)
            ),
            catchError(error => of(stepProgressActions.stepProgressesFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneStepProgress$ = createEffect(() => {
    const selectStepProgressState$ = this.store$.select(selectStepProgressState);
    return this.actions$.pipe(
      ofType(stepProgressActions.deleteOneStepProgress),
      withLatestFrom(selectStepProgressState$),
      concatMap(([{ idStepProgress }, state]) =>
        this.stepProgressApiService.deleteStepProgress(idStepProgress).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteStepProgressActions(state.entities[idStepProgress] as StepProgressEntityState),
              stepProgressActions.deleteOneStepProgress.type
            )
          ]),
          catchError(error => of(stepProgressActions.stepProgressesFailure({ error })))
        )
      )
    );
  });

  normalizeManyStepProgressesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stepProgressActions.normalizeManyStepProgressesAfterUpsert),
      concatMap(({ stepProgresses }) => {
        const actions: Action[] = getActionsToNormalizeStepProgress(stepProgresses, StoreActionType.upsert);
        return [getMultiAction(actions, '[StepProgress] Normalization After Upsert Success')];
      })
    );
  });
}
