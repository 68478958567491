import { OrganizationStratalotAvancementState, initialState } from './organization-stratalot-avancement.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationStratalotAvancementReducersGeneratedFunctions } from './organization-stratalot-avancement-generated.reducer';

const organizationStratalotAvancementReducersFunctions = [...organizationStratalotAvancementReducersGeneratedFunctions];

const organizationStratalotAvancementReducer = createReducer(
  initialState,
  ...organizationStratalotAvancementReducersFunctions
);

export function reducer(state: OrganizationStratalotAvancementState | undefined, action: Action) {
  return organizationStratalotAvancementReducer(state, action);
}
