import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as LeadActions from './lead.actions';
import { adapter, initialState, LeadState } from './lead.state';
import { Dictionary } from '@ngrx/entity';
import { LeadEntityState } from '@_model/interfaces/lead.model';

export const leadReducersGeneratedFunctions: ReducerTypes<LeadState, readonly ActionCreator[]>[] = [
  on(LeadActions.getOneLead, (state: LeadState) => setLoadingsState(state, true)),
  on(LeadActions.getManyLeads, (state: LeadState) => setLoadingsState(state, true)),
  on(LeadActions.upsertOneLead, (state: LeadState) => setLoadingsState(state, true)),

  on(LeadActions.upsertManyLeadsSuccess, (state: LeadState, { leads }) =>
    adapter.upsertMany(leads, setLoadingsState(state, false))
  ),
  on(LeadActions.deleteOneLead, (state: LeadState) => setLoadingsState(state, true)),
  on(LeadActions.deleteOneLeadSuccess, (state: LeadState, { idLead }) =>
    adapter.removeOne(idLead, setLoadingsState(state, false))
  ),
  on(LeadActions.clearActive, (state: LeadState) => ({ ...state, actives: [] })),
  on(LeadActions.addManyActives, (state: LeadState, { idLeads }) => ({
    ...state,
    actives: state.actives.concat(idLeads)
  })),
  on(LeadActions.deleteOneActive, (state: LeadState, { idLead }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idLead)
  })),

  on(LeadActions.clearStore, () => initialState),

  on(LeadActions.addManyProspectEventSuccess, (state: LeadState, { idLead, idProspectEvents }) => {
    if (!state.entities[idLead]) {
      return state;
    }
    const prospectEvents = (state.entities[idLead]?.prospectEvents as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idLead]: {
          ...state.entities[idLead],
          prospectEvents: prospectEvents.concat(idProspectEvents.filter(id => prospectEvents.indexOf(id) < 0))
        }
      }
    };
  }),

  on(LeadActions.deleteManyProspectEventSuccess, (state: LeadState, { idProspectEvents, idLeads }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idLeads.reduce((entities, idLead) => {
          if (!state.entities[idLead]?.prospectEvents) {
            return entities;
          }
          entities[idLead] = {
            ...state.entities[idLead],
            prospectEvents: (state.entities[idLead]?.prospectEvents as number[])?.filter(
              (idProspectEvent: number) => !idProspectEvents.some((id: number) => id === idProspectEvent)
            )
          } as LeadEntityState;
          return entities;
        }, {} as Dictionary<LeadEntityState>)
      }
    };
  }),

  on(LeadActions.addManyStratalotSuccess, (state: LeadState, { idLead, idStratalots }) => {
    if (!state.entities[idLead]) {
      return state;
    }
    const stratalots = (state.entities[idLead]?.stratalots as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idLead]: {
          ...state.entities[idLead],
          stratalots: stratalots.concat(idStratalots.filter(id => stratalots.indexOf(id) < 0))
        }
      }
    };
  }),

  on(LeadActions.deleteManyStratalotSuccess, (state: LeadState, { idStratalots, idLeads }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idLeads.reduce((entities, idLead) => {
          if (!state.entities[idLead]?.stratalots) {
            return entities;
          }
          entities[idLead] = {
            ...state.entities[idLead],
            stratalots: (state.entities[idLead]?.stratalots as number[])?.filter(
              (idStratalot: number) => !idStratalots.some((id: number) => id === idStratalot)
            )
          } as LeadEntityState;
          return entities;
        }, {} as Dictionary<LeadEntityState>)
      }
    };
  }),

  on(LeadActions.addManyLeadStratalotSuccess, (state: LeadState, { idLead, idLeadStratalots }) => {
    if (!state.entities[idLead]) {
      return state;
    }
    const leadStratalots = (state.entities[idLead]?.leadStratalots as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idLead]: {
          ...state.entities[idLead],
          leadStratalots: leadStratalots.concat(idLeadStratalots.filter(id => leadStratalots.indexOf(id) < 0))
        }
      }
    };
  }),

  on(LeadActions.deleteManyLeadStratalotSuccess, (state: LeadState, { idLeadStratalots, idLeads }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idLeads.reduce((entities, idLead) => {
          if (!state.entities[idLead]?.leadStratalots) {
            return entities;
          }
          entities[idLead] = {
            ...state.entities[idLead],
            leadStratalots: (state.entities[idLead]?.leadStratalots as number[])?.filter(
              (idLeadStratalot: number) => !idLeadStratalots.some((id: number) => id === idLeadStratalot)
            )
          } as LeadEntityState;
          return entities;
        }, {} as Dictionary<LeadEntityState>)
      }
    };
  }),

  on(LeadActions.addManyLeadTodoSuccess, (state: LeadState, { idLead, idLeadTodos }) => {
    if (!state.entities[idLead]) {
      return state;
    }
    const leadTodos = (state.entities[idLead]?.leadTodos as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idLead]: {
          ...state.entities[idLead],
          leadTodos: leadTodos.concat(idLeadTodos.filter(id => leadTodos.indexOf(id) < 0))
        }
      }
    };
  }),

  on(LeadActions.deleteManyLeadTodoSuccess, (state: LeadState, { idLeadTodos, idLeads }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idLeads.reduce((entities, idLead) => {
          if (!state.entities[idLead]?.leadTodos) {
            return entities;
          }
          entities[idLead] = {
            ...state.entities[idLead],
            leadTodos: (state.entities[idLead]?.leadTodos as number[])?.filter(
              (idLeadTodo: number) => !idLeadTodos.some((id: number) => id === idLeadTodo)
            )
          } as LeadEntityState;
          return entities;
        }, {} as Dictionary<LeadEntityState>)
      }
    };
  }),

  on(LeadActions.addProspectSuccess, (state: LeadState, { idLead, idProspect }) => {
    if (!state.entities[idLead]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idLead]: {
          ...state.entities[idLead],
          prospect: idProspect
        }
      }
    };
  }),

  on(LeadActions.deleteManyProspectSuccess, (state: LeadState, { idProspects, idLeads }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idLeads.reduce((entities, idLead) => {
          if (!state.entities[idLead]?.prospect) {
            return entities;
          }
          entities[idLead] = {
            ...state.entities[idLead],
            prospect: idProspects.some((idProspect: number) => idProspect === state.entities[idLead]?.prospect)
              ? undefined
              : state.entities[idLead]?.prospect
          } as LeadEntityState;
          return entities;
        }, {} as Dictionary<LeadEntityState>)
      }
    };
  }),

  on(LeadActions.addOrganizationLeadAvancementSuccess, (state: LeadState, { idLead, idOrganizationLeadAvancement }) => {
    if (!state.entities[idLead]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idLead]: {
          ...state.entities[idLead],
          organizationLeadAvancement: idOrganizationLeadAvancement
        }
      }
    };
  }),

  on(
    LeadActions.deleteManyOrganizationLeadAvancementSuccess,
    (state: LeadState, { idOrganizationLeadAvancements, idLeads }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idLeads.reduce((entities, idLead) => {
            if (!state.entities[idLead]?.organizationLeadAvancement) {
              return entities;
            }
            entities[idLead] = {
              ...state.entities[idLead],
              organizationLeadAvancement: idOrganizationLeadAvancements.some(
                (idOrganizationLeadAvancement: number) =>
                  idOrganizationLeadAvancement === state.entities[idLead]?.organizationLeadAvancement
              )
                ? undefined
                : state.entities[idLead]?.organizationLeadAvancement
            } as LeadEntityState;
            return entities;
          }, {} as Dictionary<LeadEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(state: LeadState, isLoading: boolean, isLoaded: boolean = true): LeadState {
  return { ...state, isLoaded, isLoading };
}
