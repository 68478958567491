import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedOrganizationProspectOriginApiService } from './organization-prospect-origin-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationProspectOriginApiService extends GeneratedOrganizationProspectOriginApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
