import { OrganizationStratalotTodoState, initialState } from './organization-stratalot-todo.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationStratalotTodoReducersGeneratedFunctions } from './organization-stratalot-todo-generated.reducer';

const organizationStratalotTodoReducersFunctions = [...organizationStratalotTodoReducersGeneratedFunctions];

const organizationStratalotTodoReducer = createReducer(initialState, ...organizationStratalotTodoReducersFunctions);

export function reducer(state: OrganizationStratalotTodoState | undefined, action: Action) {
  return organizationStratalotTodoReducer(state, action);
}
