import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as AssociationActions from './association.actions';
import { adapter, initialState, AssociationState } from './association.state';
import { Dictionary } from '@ngrx/entity';
import { AssociationEntityState } from '@_model/interfaces/association.model';

export const associationReducersGeneratedFunctions: ReducerTypes<AssociationState, readonly ActionCreator[]>[] = [
  on(AssociationActions.getOneAssociation, (state: AssociationState) => setLoadingsState(state, true)),
  on(AssociationActions.getManyAssociations, (state: AssociationState) => setLoadingsState(state, true)),
  on(AssociationActions.upsertOneAssociation, (state: AssociationState) => setLoadingsState(state, true)),

  on(AssociationActions.upsertManyAssociationsSuccess, (state: AssociationState, { associations }) =>
    adapter.upsertMany(associations, setLoadingsState(state, false))
  ),
  on(AssociationActions.deleteOneAssociation, (state: AssociationState) => setLoadingsState(state, true)),
  on(AssociationActions.deleteOneAssociationSuccess, (state: AssociationState, { idAssociation }) =>
    adapter.removeOne(idAssociation, setLoadingsState(state, false))
  ),
  on(AssociationActions.clearActive, (state: AssociationState) => ({ ...state, actives: [] })),
  on(AssociationActions.addManyActives, (state: AssociationState, { idAssociations }) => ({
    ...state,
    actives: state.actives.concat(idAssociations)
  })),
  on(AssociationActions.deleteOneActive, (state: AssociationState, { idAssociation }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idAssociation)
  })),

  on(AssociationActions.clearStore, () => initialState),

  on(
    AssociationActions.addManyStratalotAssociationSuccess,
    (state: AssociationState, { idAssociation, idStratalotAssociations }) => {
      if (!state.entities[idAssociation]) {
        return state;
      }
      const stratalotAssociations = (state.entities[idAssociation]?.stratalotAssociations as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idAssociation]: {
            ...state.entities[idAssociation],
            stratalotAssociations: stratalotAssociations.concat(
              idStratalotAssociations.filter(id => stratalotAssociations.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    AssociationActions.deleteManyStratalotAssociationSuccess,
    (state: AssociationState, { idStratalotAssociations, idAssociations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idAssociations.reduce((entities, idAssociation) => {
            if (!state.entities[idAssociation]?.stratalotAssociations) {
              return entities;
            }
            entities[idAssociation] = {
              ...state.entities[idAssociation],
              stratalotAssociations: (state.entities[idAssociation]?.stratalotAssociations as number[])?.filter(
                (idStratalotAssociation: number) =>
                  !idStratalotAssociations.some((id: number) => id === idStratalotAssociation)
              )
            } as AssociationEntityState;
            return entities;
          }, {} as Dictionary<AssociationEntityState>)
        }
      };
    }
  ),

  on(AssociationActions.addManyStratalotSuccess, (state: AssociationState, { idAssociation, idStratalots }) => {
    if (!state.entities[idAssociation]) {
      return state;
    }
    const stratalots = (state.entities[idAssociation]?.stratalots as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idAssociation]: {
          ...state.entities[idAssociation],
          stratalots: stratalots.concat(idStratalots.filter(id => stratalots.indexOf(id) < 0))
        }
      }
    };
  }),

  on(AssociationActions.deleteManyStratalotSuccess, (state: AssociationState, { idStratalots, idAssociations }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idAssociations.reduce((entities, idAssociation) => {
          if (!state.entities[idAssociation]?.stratalots) {
            return entities;
          }
          entities[idAssociation] = {
            ...state.entities[idAssociation],
            stratalots: (state.entities[idAssociation]?.stratalots as number[])?.filter(
              (idStratalot: number) => !idStratalots.some((id: number) => id === idStratalot)
            )
          } as AssociationEntityState;
          return entities;
        }, {} as Dictionary<AssociationEntityState>)
      }
    };
  })
];

export function setLoadingsState(
  state: AssociationState,
  isLoading: boolean,
  isLoaded: boolean = true
): AssociationState {
  return { ...state, isLoaded, isLoading };
}
