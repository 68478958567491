import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidenceStudyTodoActions from './residence-study-todo.actions';
import { adapter, initialState, ResidenceStudyTodoState } from './residence-study-todo.state';
import { Dictionary } from '@ngrx/entity';
import { ResidenceStudyTodoEntityState } from '@_model/interfaces/residence-study-todo.model';

export const residenceStudyTodoReducersGeneratedFunctions: ReducerTypes<
  ResidenceStudyTodoState,
  readonly ActionCreator[]
>[] = [
  on(ResidenceStudyTodoActions.getOneResidenceStudyTodo, (state: ResidenceStudyTodoState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceStudyTodoActions.getManyResidenceStudyTodos, (state: ResidenceStudyTodoState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceStudyTodoActions.upsertOneResidenceStudyTodo, (state: ResidenceStudyTodoState) =>
    setLoadingsState(state, true)
  ),

  on(
    ResidenceStudyTodoActions.upsertManyResidenceStudyTodosSuccess,
    (state: ResidenceStudyTodoState, { residenceStudyTodos }) =>
      adapter.upsertMany(residenceStudyTodos, setLoadingsState(state, false))
  ),
  on(ResidenceStudyTodoActions.deleteOneResidenceStudyTodo, (state: ResidenceStudyTodoState) =>
    setLoadingsState(state, true)
  ),
  on(
    ResidenceStudyTodoActions.deleteOneResidenceStudyTodoSuccess,
    (state: ResidenceStudyTodoState, { idResidenceStudyTodo }) =>
      adapter.removeOne(idResidenceStudyTodo, setLoadingsState(state, false))
  ),
  on(ResidenceStudyTodoActions.clearActive, (state: ResidenceStudyTodoState) => ({ ...state, actives: [] })),
  on(ResidenceStudyTodoActions.addManyActives, (state: ResidenceStudyTodoState, { idResidenceStudyTodos }) => ({
    ...state,
    actives: state.actives.concat(idResidenceStudyTodos)
  })),
  on(ResidenceStudyTodoActions.deleteOneActive, (state: ResidenceStudyTodoState, { idResidenceStudyTodo }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idResidenceStudyTodo)
  })),

  on(ResidenceStudyTodoActions.clearStore, () => initialState),

  on(
    ResidenceStudyTodoActions.addOrganizationResidenceStudyTodoSuccess,
    (state: ResidenceStudyTodoState, { idResidenceStudyTodo, idOrganizationResidenceStudyTodo }) => {
      if (!state.entities[idResidenceStudyTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudyTodo]: {
            ...state.entities[idResidenceStudyTodo],
            organizationResidenceStudyTodo: idOrganizationResidenceStudyTodo
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyTodoActions.deleteManyOrganizationResidenceStudyTodoSuccess,
    (state: ResidenceStudyTodoState, { idOrganizationResidenceStudyTodos, idResidenceStudyTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudyTodos.reduce((entities, idResidenceStudyTodo) => {
            if (!state.entities[idResidenceStudyTodo]?.organizationResidenceStudyTodo) {
              return entities;
            }
            entities[idResidenceStudyTodo] = {
              ...state.entities[idResidenceStudyTodo],
              organizationResidenceStudyTodo: idOrganizationResidenceStudyTodos.some(
                (idOrganizationResidenceStudyTodo: number) =>
                  idOrganizationResidenceStudyTodo ===
                  state.entities[idResidenceStudyTodo]?.organizationResidenceStudyTodo
              )
                ? undefined
                : state.entities[idResidenceStudyTodo]?.organizationResidenceStudyTodo
            } as ResidenceStudyTodoEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyTodoEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceStudyTodoActions.addResidenceStudySuccess,
    (state: ResidenceStudyTodoState, { idResidenceStudyTodo, idResidenceStudy }) => {
      if (!state.entities[idResidenceStudyTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudyTodo]: {
            ...state.entities[idResidenceStudyTodo],
            residenceStudy: idResidenceStudy
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyTodoActions.deleteManyResidenceStudySuccess,
    (state: ResidenceStudyTodoState, { idResidenceStudies, idResidenceStudyTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudyTodos.reduce((entities, idResidenceStudyTodo) => {
            if (!state.entities[idResidenceStudyTodo]?.residenceStudy) {
              return entities;
            }
            entities[idResidenceStudyTodo] = {
              ...state.entities[idResidenceStudyTodo],
              residenceStudy: idResidenceStudies.some(
                (idResidenceStudy: number) => idResidenceStudy === state.entities[idResidenceStudyTodo]?.residenceStudy
              )
                ? undefined
                : state.entities[idResidenceStudyTodo]?.residenceStudy
            } as ResidenceStudyTodoEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyTodoEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidenceStudyTodoState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidenceStudyTodoState {
  return { ...state, isLoaded, isLoading };
}
