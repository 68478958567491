import { SaleCategoryFamilyState, initialState } from './sale-category-family.state';
import { Action, createReducer } from '@ngrx/store';
import { saleCategoryFamilyReducersGeneratedFunctions } from './sale-category-family-generated.reducer';

const saleCategoryFamilyReducersFunctions = [...saleCategoryFamilyReducersGeneratedFunctions];

const saleCategoryFamilyReducer = createReducer(initialState, ...saleCategoryFamilyReducersFunctions);

export function reducer(state: SaleCategoryFamilyState | undefined, action: Action) {
  return saleCategoryFamilyReducer(state, action);
}
