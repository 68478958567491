import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationStratalotAvancement,
  OrganizationStratalotAvancementEntityState
} from '@_model/interfaces/organization-stratalot-avancement.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { Step, StepEntityState } from '@_model/interfaces/step.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationStratalotAvancementFeatureKey,
  OrganizationStratalotAvancementState
} from './organization-stratalot-avancement.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationStratalotAvancementRelations: string[] = ['stratalots', 'organizations', 'steps'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationStratalotAvancementState = createFeatureSelector<OrganizationStratalotAvancementState>(
  organizationStratalotAvancementFeatureKey
);

export const selectIsLoadedOrganizationStratalotAvancement = createSelector(
  selectOrganizationStratalotAvancementState,
  (state: OrganizationStratalotAvancementState) => state.isLoaded
);

export const selectIsLoadingOrganizationStratalotAvancement = createSelector(
  selectOrganizationStratalotAvancementState,
  (state: OrganizationStratalotAvancementState) => state.isLoading
);

export const selectIsReadyOrganizationStratalotAvancement = createSelector(
  selectOrganizationStratalotAvancementState,
  (state: OrganizationStratalotAvancementState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationStratalotAvancement = createSelector(
  selectOrganizationStratalotAvancementState,
  (state: OrganizationStratalotAvancementState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationStratalotAvancementModel: SelectorModel = {
  name: 'organizationStratalotAvancements',
  getSelector: selectAllOrganizationStratalotAvancementsDictionary,
  isReady: selectIsReadyOrganizationStratalotAvancement
};

export const selectOrganizationStratalotAvancementsEntities = createSelector(
  selectOrganizationStratalotAvancementState,
  selectEntities
);

export const selectOrganizationStratalotAvancementsArray = createSelector(
  selectOrganizationStratalotAvancementState,
  selectAll
);

export const selectIdOrganizationStratalotAvancementsActive = createSelector(
  selectOrganizationStratalotAvancementState,
  (state: OrganizationStratalotAvancementState) => state.actives
);

const organizationStratalotAvancementsInObject = (
  organizationStratalotAvancements: Dictionary<OrganizationStratalotAvancementEntityState>
) => ({ organizationStratalotAvancements });

const selectOrganizationStratalotAvancementsEntitiesDictionary = createSelector(
  selectOrganizationStratalotAvancementsEntities,
  organizationStratalotAvancementsInObject
);

const selectAllOrganizationStratalotAvancementsObject = createSelector(
  selectOrganizationStratalotAvancementsEntities,
  organizationStratalotAvancements => {
    return hydrateAll({ organizationStratalotAvancements });
  }
);

const selectOneOrganizationStratalotAvancementDictionary = (idOrganizationStratalotAvancement: number) =>
  createSelector(selectOrganizationStratalotAvancementsEntities, organizationStratalotAvancements => {
    return {
      organizationStratalotAvancements: {
        [idOrganizationStratalotAvancement]: organizationStratalotAvancements[idOrganizationStratalotAvancement]
      }
    };
  });

const selectOneOrganizationStratalotAvancementDictionaryWithoutChild = (idOrganizationStratalotAvancement: number) =>
  createSelector(selectOrganizationStratalotAvancementsEntities, organizationStratalotAvancements => {
    return { organizationStratalotAvancement: organizationStratalotAvancements[idOrganizationStratalotAvancement] };
  });

const selectActiveOrganizationStratalotAvancementsEntities = createSelector(
  selectIdOrganizationStratalotAvancementsActive,
  selectOrganizationStratalotAvancementsEntities,
  (actives: number[], organizationStratalotAvancements: Dictionary<OrganizationStratalotAvancementEntityState>) =>
    getOrganizationStratalotAvancementsFromActives(actives, organizationStratalotAvancements)
);

function getOrganizationStratalotAvancementsFromActives(
  actives: number[],
  organizationStratalotAvancements: Dictionary<OrganizationStratalotAvancementEntityState>
): Dictionary<OrganizationStratalotAvancementEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationStratalotAvancements[idActive]) {
      acc[idActive] = organizationStratalotAvancements[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationStratalotAvancementEntityState>);
}

const selectAllOrganizationStratalotAvancementsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationStratalotAvancements(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationStratalotAvancement>(
      schema,
      selectAllOrganizationStratalotAvancementsSelectors,
      selectOrganizationStratalotAvancementsEntitiesDictionary,
      getRelationSelectors,
      organizationStratalotAvancementRelations,
      hydrateAll,
      'organizationStratalotAvancement'
    );
  } else {
    return selectAllOrganizationStratalotAvancementsObject;
  }
}

export function selectAllOrganizationStratalotAvancementsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationStratalotAvancements'
): Selector {
  return createSelector(selectAllOrganizationStratalotAvancements(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationStratalotAvancementEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationStratalotAvancements.length; i++) {
      res[customKey][result.organizationStratalotAvancements[i].idOrganizationStratalotAvancement] =
        result.organizationStratalotAvancements[i];
    }
    return res;
  });
}

export function selectOneOrganizationStratalotAvancement(
  schema: SelectSchema = {},
  idOrganizationStratalotAvancement: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [
      selectOneOrganizationStratalotAvancementDictionary(idOrganizationStratalotAvancement)
    ];
    selectors.push(
      ...getRelationSelectors(schema, organizationStratalotAvancementRelations, 'organizationStratalotAvancement')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationStratalotAvancementDictionaryWithoutChild(idOrganizationStratalotAvancement);
  }
}

export function selectActiveOrganizationStratalotAvancements(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationStratalotAvancementsEntities, organizationStratalotAvancements => ({
      organizationStratalotAvancements
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, organizationStratalotAvancementRelations, 'organizationStratalotAvancement')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationStratalotAvancements: Dictionary<OrganizationStratalotAvancementEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  steps?: Dictionary<StepEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationStratalotAvancements: (OrganizationStratalotAvancement | null)[];
} {
  const { organizationStratalotAvancements, organizations, steps, stratalots } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationStratalotAvancements: Object.keys(organizationStratalotAvancements).map(
      idOrganizationStratalotAvancement =>
        hydrate(
          organizationStratalotAvancements[
            idOrganizationStratalotAvancement
          ] as OrganizationStratalotAvancementEntityState,
          organizations,
          steps,
          stratalots
        )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationStratalotAvancement: OrganizationStratalotAvancementEntityState | null;
} {
  const { organizationStratalotAvancements, organizations, steps, stratalots } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationStratalotAvancement = Object.values(organizationStratalotAvancements)[0];
  return {
    organizationStratalotAvancement: hydrate(
      organizationStratalotAvancement as OrganizationStratalotAvancementEntityState,
      organizations,
      steps,
      stratalots
    )
  };
}

function hydrate(
  organizationStratalotAvancement: OrganizationStratalotAvancementEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  stepEntities?: Dictionary<StepEntityState>,
  stratalotEntities?: Dictionary<StratalotEntityState>
): OrganizationStratalotAvancement | null {
  if (!organizationStratalotAvancement) {
    return null;
  }

  const organizationStratalotAvancementHydrated: OrganizationStratalotAvancementEntityState = {
    ...organizationStratalotAvancement
  };
  if (organizationEntities) {
    organizationStratalotAvancementHydrated.organization = organizationEntities[
      organizationStratalotAvancement.organization as number
    ] as Organization;
  } else {
    delete organizationStratalotAvancementHydrated.organization;
  }
  if (stepEntities) {
    organizationStratalotAvancementHydrated.step = stepEntities[organizationStratalotAvancement.step as number] as Step;
  } else {
    delete organizationStratalotAvancementHydrated.step;
  }

  if (stratalotEntities) {
    organizationStratalotAvancementHydrated.stratalots = (
      (organizationStratalotAvancementHydrated.stratalots as number[]) || []
    ).map(id => stratalotEntities[id]) as Stratalot[];
  } else {
    delete organizationStratalotAvancementHydrated.stratalots;
  }

  return organizationStratalotAvancementHydrated as OrganizationStratalotAvancement;
}
