import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StratalotRcpApiService } from '@_services/api/stratalot-rcp-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedStratalotRcpEffects } from './stratalot-rcp-generated.effects';

@Injectable()
export class StratalotRcpEffects extends GeneratedStratalotRcpEffects {
  constructor(actions$: Actions, stratalotRcpApiService: StratalotRcpApiService, store$: Store<AppState>) {
    super(actions$, stratalotRcpApiService, store$);
  }
}
