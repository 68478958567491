import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { DiffusionVisual, DiffusionVisualEntityState } from '@_model/interfaces/diffusion-visual.model';
import { ResidenceTodo, ResidenceTodoEntityState } from '@_model/interfaces/residence-todo.model';
import { ResidenceWork, ResidenceWorkEntityState } from '@_model/interfaces/residence-work.model';
import {
  ResidenceSaleCategoryFamily,
  ResidenceSaleCategoryFamilyEntityState
} from '@_model/interfaces/residence-sale-category-family.model';
import { ResidenceContact, ResidenceContactEntityState } from '@_model/interfaces/residence-contact.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { ResidencePriceGrid, ResidencePriceGridEntityState } from '@_model/interfaces/residence-price-grid.model';
import { ResidenceShare, ResidenceShareEntityState } from '@_model/interfaces/residence-share.model';
import { StratalotRcp, StratalotRcpEntityState } from '@_model/interfaces/stratalot-rcp.model';
import { SaleCategoryFamily, SaleCategoryFamilyEntityState } from '@_model/interfaces/sale-category-family.model';
import { ProspectBuyingWish, ProspectBuyingWishEntityState } from '@_model/interfaces/prospect-buying-wish.model';
import { CompanyTerritoire, CompanyTerritoireEntityState } from '@_model/interfaces/company-territoire.model';
import { ResidenceForecast, ResidenceForecastEntityState } from '@_model/interfaces/residence-forecast.model';
import {
  GeneratedDocumentsResidence,
  GeneratedDocumentsResidenceEntityState
} from '@_model/interfaces/generated-documents-residence.model';
import { GeneratedDocument, GeneratedDocumentEntityState } from '@_model/interfaces/generated-document.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { User, UserEntityState } from '@_model/interfaces/user.model';
import {
  OrganizationForecastRate,
  OrganizationForecastRateEntityState
} from '@_model/interfaces/organization-forecast-rate.model';
import { City, CityEntityState } from '@_model/interfaces/city.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, residenceFeatureKey, ResidenceState } from './residence.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residenceRelations: string[] = [
  'diffusionVisuals',
  'residenceTodos',
  'residenceWorks',
  'residenceSaleCategoryFamilies',
  'residenceContacts',
  'stratalots',
  'residencePriceGrids',
  'residenceShares',
  'stratalotRcps',
  'familySaleCategories',
  'prospectBuyingWishs',
  'companyTerritoires',
  'residenceForecasts',
  'generatedDocumentsResidences',
  'generatedDocuments',
  'companies',
  'responsable',
  'organizationForecastRates',
  'cities'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidenceState = createFeatureSelector<ResidenceState>(residenceFeatureKey);

export const selectIsLoadedResidence = createSelector(selectResidenceState, (state: ResidenceState) => state.isLoaded);

export const selectIsLoadingResidence = createSelector(
  selectResidenceState,
  (state: ResidenceState) => state.isLoading
);

export const selectIsReadyResidence = createSelector(selectResidenceState, (state: ResidenceState) => !state.isLoading);

export const selectIsReadyAndLoadedResidence = createSelector(
  selectResidenceState,
  (state: ResidenceState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidenceModel: SelectorModel = {
  name: 'residences',
  getSelector: selectAllResidencesDictionary,
  isReady: selectIsReadyResidence
};

export const selectResidencesEntities = createSelector(selectResidenceState, selectEntities);

export const selectResidencesArray = createSelector(selectResidenceState, selectAll);

export const selectIdResidencesActive = createSelector(selectResidenceState, (state: ResidenceState) => state.actives);

const residencesInObject = (residences: Dictionary<ResidenceEntityState>) => ({ residences });

const selectResidencesEntitiesDictionary = createSelector(selectResidencesEntities, residencesInObject);

const selectAllResidencesObject = createSelector(selectResidencesEntities, residences => {
  return hydrateAll({ residences });
});

const selectOneResidenceDictionary = (idResidence: number) =>
  createSelector(selectResidencesEntities, residences => {
    return { residences: { [idResidence]: residences[idResidence] } };
  });

const selectOneResidenceDictionaryWithoutChild = (idResidence: number) =>
  createSelector(selectResidencesEntities, residences => {
    return { residence: residences[idResidence] };
  });

const selectActiveResidencesEntities = createSelector(
  selectIdResidencesActive,
  selectResidencesEntities,
  (actives: number[], residences: Dictionary<ResidenceEntityState>) => getResidencesFromActives(actives, residences)
);

function getResidencesFromActives(
  actives: number[],
  residences: Dictionary<ResidenceEntityState>
): Dictionary<ResidenceEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residences[idActive]) {
      acc[idActive] = residences[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidenceEntityState>);
}

const selectAllResidencesSelectors: Dictionary<Selector> = {};
export function selectAllResidences(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Residence>(
      schema,
      selectAllResidencesSelectors,
      selectResidencesEntitiesDictionary,
      getRelationSelectors,
      residenceRelations,
      hydrateAll,
      'residence'
    );
  } else {
    return selectAllResidencesObject;
  }
}

export function selectAllResidencesDictionary(schema: SelectSchema = {}, customKey: string = 'residences'): Selector {
  return createSelector(selectAllResidences(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidenceEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residences.length; i++) {
      res[customKey][result.residences[i].idResidence] = result.residences[i];
    }
    return res;
  });
}

export function selectOneResidence(schema: SelectSchema = {}, idResidence: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidenceDictionary(idResidence)];
    selectors.push(...getRelationSelectors(schema, residenceRelations, 'residence'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidenceDictionaryWithoutChild(idResidence);
  }
}

export function selectActiveResidences(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [createSelector(selectActiveResidencesEntities, residences => ({ residences }))];
  selectors.push(...getRelationSelectors(schema, residenceRelations, 'residence'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residences: Dictionary<ResidenceEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  responsable?: Dictionary<UserEntityState>;
  organizationForecastRates?: Dictionary<OrganizationForecastRateEntityState>;
  cities?: Dictionary<CityEntityState>;
  diffusionVisuals?: Dictionary<DiffusionVisualEntityState>;
  residenceTodos?: Dictionary<ResidenceTodoEntityState>;
  residenceWorks?: Dictionary<ResidenceWorkEntityState>;
  residenceSaleCategoryFamilies?: Dictionary<ResidenceSaleCategoryFamilyEntityState>;
  residenceContacts?: Dictionary<ResidenceContactEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
  residencePriceGrids?: Dictionary<ResidencePriceGridEntityState>;
  residenceShares?: Dictionary<ResidenceShareEntityState>;
  stratalotRcps?: Dictionary<StratalotRcpEntityState>;
  familySaleCategories?: Dictionary<SaleCategoryFamilyEntityState>;
  prospectBuyingWishs?: Dictionary<ProspectBuyingWishEntityState>;
  companyTerritoires?: Dictionary<CompanyTerritoireEntityState>;
  residenceForecasts?: Dictionary<ResidenceForecastEntityState>;
  generatedDocumentsResidences?: Dictionary<GeneratedDocumentsResidenceEntityState>;
  generatedDocuments?: Dictionary<GeneratedDocumentEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { residences: (Residence | null)[] } {
  const {
    residences,
    companies,
    responsable,
    organizationForecastRates,
    cities,
    diffusionVisuals,
    residenceTodos,
    residenceWorks,
    residenceSaleCategoryFamilies,
    residenceContacts,
    stratalots,
    residencePriceGrids,
    residenceShares,
    stratalotRcps,
    familySaleCategories,
    prospectBuyingWishs,
    companyTerritoires,
    residenceForecasts,
    generatedDocumentsResidences,
    generatedDocuments
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    residences: Object.keys(residences).map(idResidence =>
      hydrate(
        residences[idResidence] as ResidenceEntityState,
        companies,
        responsable,
        organizationForecastRates,
        cities,
        diffusionVisuals,
        residenceTodos,
        residenceWorks,
        residenceSaleCategoryFamilies,
        residenceContacts,
        stratalots,
        residencePriceGrids,
        residenceShares,
        stratalotRcps,
        familySaleCategories,
        prospectBuyingWishs,
        companyTerritoires,
        residenceForecasts,
        generatedDocumentsResidences,
        generatedDocuments
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { residence: ResidenceEntityState | null } {
  const {
    residences,
    companies,
    responsable,
    organizationForecastRates,
    cities,
    diffusionVisuals,
    residenceTodos,
    residenceWorks,
    residenceSaleCategoryFamilies,
    residenceContacts,
    stratalots,
    residencePriceGrids,
    residenceShares,
    stratalotRcps,
    familySaleCategories,
    prospectBuyingWishs,
    companyTerritoires,
    residenceForecasts,
    generatedDocumentsResidences,
    generatedDocuments
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const residence = Object.values(residences)[0];
  return {
    residence: hydrate(
      residence as ResidenceEntityState,
      companies,
      responsable,
      organizationForecastRates,
      cities,
      diffusionVisuals,
      residenceTodos,
      residenceWorks,
      residenceSaleCategoryFamilies,
      residenceContacts,
      stratalots,
      residencePriceGrids,
      residenceShares,
      stratalotRcps,
      familySaleCategories,
      prospectBuyingWishs,
      companyTerritoires,
      residenceForecasts,
      generatedDocumentsResidences,
      generatedDocuments
    )
  };
}

function hydrate(
  residence: ResidenceEntityState,
  companyEntities?: Dictionary<CompanyEntityState>,
  responsableEntities?: Dictionary<UserEntityState>,
  organizationForecastRateEntities?: Dictionary<OrganizationForecastRateEntityState>,
  cityEntities?: Dictionary<CityEntityState>,
  diffusionVisualEntities?: Dictionary<DiffusionVisualEntityState>,
  residenceTodoEntities?: Dictionary<ResidenceTodoEntityState>,
  residenceWorkEntities?: Dictionary<ResidenceWorkEntityState>,
  residenceSaleCategoryFamilyEntities?: Dictionary<ResidenceSaleCategoryFamilyEntityState>,
  residenceContactEntities?: Dictionary<ResidenceContactEntityState>,
  stratalotEntities?: Dictionary<StratalotEntityState>,
  residencePriceGridEntities?: Dictionary<ResidencePriceGridEntityState>,
  residenceShareEntities?: Dictionary<ResidenceShareEntityState>,
  stratalotRcpEntities?: Dictionary<StratalotRcpEntityState>,
  saleCategoryFamilyEntities?: Dictionary<SaleCategoryFamilyEntityState>,
  prospectBuyingWishEntities?: Dictionary<ProspectBuyingWishEntityState>,
  companyTerritoireEntities?: Dictionary<CompanyTerritoireEntityState>,
  residenceForecastEntities?: Dictionary<ResidenceForecastEntityState>,
  generatedDocumentsResidenceEntities?: Dictionary<GeneratedDocumentsResidenceEntityState>,
  generatedDocumentEntities?: Dictionary<GeneratedDocumentEntityState>
): Residence | null {
  if (!residence) {
    return null;
  }

  const residenceHydrated: ResidenceEntityState = { ...residence };
  if (companyEntities) {
    residenceHydrated.company = companyEntities[residence.company as number] as Company;
  } else {
    delete residenceHydrated.company;
  }
  if (responsableEntities) {
    residenceHydrated.responsable = responsableEntities[residence.responsable as number] as User;
  } else {
    delete residenceHydrated.responsable;
  }
  if (organizationForecastRateEntities) {
    residenceHydrated.organizationForecastRate = organizationForecastRateEntities[
      residence.organizationForecastRate as number
    ] as OrganizationForecastRate;
  } else {
    delete residenceHydrated.organizationForecastRate;
  }
  if (cityEntities) {
    residenceHydrated.city = cityEntities[residence.city as number] as City;
  } else {
    delete residenceHydrated.city;
  }

  if (diffusionVisualEntities) {
    residenceHydrated.diffusionVisuals = ((residenceHydrated.diffusionVisuals as number[]) || []).map(
      id => diffusionVisualEntities[id]
    ) as DiffusionVisual[];
  } else {
    delete residenceHydrated.diffusionVisuals;
  }

  if (residenceTodoEntities) {
    residenceHydrated.residenceTodos = ((residenceHydrated.residenceTodos as number[]) || []).map(
      id => residenceTodoEntities[id]
    ) as ResidenceTodo[];
  } else {
    delete residenceHydrated.residenceTodos;
  }

  if (residenceWorkEntities) {
    residenceHydrated.residenceWorks = ((residenceHydrated.residenceWorks as number[]) || []).map(
      id => residenceWorkEntities[id]
    ) as ResidenceWork[];
  } else {
    delete residenceHydrated.residenceWorks;
  }

  if (residenceSaleCategoryFamilyEntities) {
    residenceHydrated.residenceSaleCategoryFamilies = (
      (residenceHydrated.residenceSaleCategoryFamilies as number[]) || []
    ).map(id => residenceSaleCategoryFamilyEntities[id]) as ResidenceSaleCategoryFamily[];
  } else {
    delete residenceHydrated.residenceSaleCategoryFamilies;
  }

  if (residenceContactEntities) {
    residenceHydrated.residenceContacts = ((residenceHydrated.residenceContacts as number[]) || []).map(
      id => residenceContactEntities[id]
    ) as ResidenceContact[];
  } else {
    delete residenceHydrated.residenceContacts;
  }

  if (stratalotEntities) {
    residenceHydrated.stratalots = ((residenceHydrated.stratalots as number[]) || []).map(
      id => stratalotEntities[id]
    ) as Stratalot[];
  } else {
    delete residenceHydrated.stratalots;
  }

  if (residencePriceGridEntities) {
    residenceHydrated.residencePriceGrids = ((residenceHydrated.residencePriceGrids as number[]) || []).map(
      id => residencePriceGridEntities[id]
    ) as ResidencePriceGrid[];
  } else {
    delete residenceHydrated.residencePriceGrids;
  }

  if (residenceShareEntities) {
    residenceHydrated.residenceShares = ((residenceHydrated.residenceShares as number[]) || []).map(
      id => residenceShareEntities[id]
    ) as ResidenceShare[];
  } else {
    delete residenceHydrated.residenceShares;
  }

  if (stratalotRcpEntities) {
    residenceHydrated.stratalotRcps = ((residenceHydrated.stratalotRcps as number[]) || []).map(
      id => stratalotRcpEntities[id]
    ) as StratalotRcp[];
  } else {
    delete residenceHydrated.stratalotRcps;
  }

  if (saleCategoryFamilyEntities) {
    residenceHydrated.familySaleCategories = ((residenceHydrated.familySaleCategories as number[]) || []).map(
      id => saleCategoryFamilyEntities[id]
    ) as SaleCategoryFamily[];
  } else {
    delete residenceHydrated.familySaleCategories;
  }

  if (prospectBuyingWishEntities) {
    residenceHydrated.prospectBuyingWishs = ((residenceHydrated.prospectBuyingWishs as number[]) || []).map(
      id => prospectBuyingWishEntities[id]
    ) as ProspectBuyingWish[];
  } else {
    delete residenceHydrated.prospectBuyingWishs;
  }

  if (companyTerritoireEntities) {
    residenceHydrated.companyTerritoires = ((residenceHydrated.companyTerritoires as number[]) || []).map(
      id => companyTerritoireEntities[id]
    ) as CompanyTerritoire[];
  } else {
    delete residenceHydrated.companyTerritoires;
  }

  if (residenceForecastEntities) {
    residenceHydrated.residenceForecasts = ((residenceHydrated.residenceForecasts as number[]) || []).map(
      id => residenceForecastEntities[id]
    ) as ResidenceForecast[];
  } else {
    delete residenceHydrated.residenceForecasts;
  }

  if (generatedDocumentsResidenceEntities) {
    residenceHydrated.generatedDocumentsResidences = (
      (residenceHydrated.generatedDocumentsResidences as number[]) || []
    ).map(id => generatedDocumentsResidenceEntities[id]) as GeneratedDocumentsResidence[];
  } else {
    delete residenceHydrated.generatedDocumentsResidences;
  }

  if (generatedDocumentEntities) {
    residenceHydrated.generatedDocuments = ((residenceHydrated.generatedDocuments as number[]) || []).map(
      id => generatedDocumentEntities[id]
    ) as GeneratedDocument[];
  } else {
    delete residenceHydrated.generatedDocuments;
  }

  return residenceHydrated as Residence;
}
