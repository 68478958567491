import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationThirdParty,
  OrganizationThirdPartyEntityState
} from '@_model/interfaces/organization-third-party.model';
import { ResidenceContact, ResidenceContactEntityState } from '@_model/interfaces/residence-contact.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { OrganizationSociete, OrganizationSocieteEntityState } from '@_model/interfaces/organization-societe.model';
import { OrganizationMetier, OrganizationMetierEntityState } from '@_model/interfaces/organization-metier.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationThirdPartyFeatureKey,
  OrganizationThirdPartyState
} from './organization-third-party.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationThirdPartyRelations: string[] = [
  'residenceContacts',
  'organizations',
  'organizationSocietes',
  'organizationMetiers'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationThirdPartyState = createFeatureSelector<OrganizationThirdPartyState>(
  organizationThirdPartyFeatureKey
);

export const selectIsLoadedOrganizationThirdParty = createSelector(
  selectOrganizationThirdPartyState,
  (state: OrganizationThirdPartyState) => state.isLoaded
);

export const selectIsLoadingOrganizationThirdParty = createSelector(
  selectOrganizationThirdPartyState,
  (state: OrganizationThirdPartyState) => state.isLoading
);

export const selectIsReadyOrganizationThirdParty = createSelector(
  selectOrganizationThirdPartyState,
  (state: OrganizationThirdPartyState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationThirdParty = createSelector(
  selectOrganizationThirdPartyState,
  (state: OrganizationThirdPartyState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationThirdPartyModel: SelectorModel = {
  name: 'organizationThirdParties',
  getSelector: selectAllOrganizationThirdPartiesDictionary,
  isReady: selectIsReadyOrganizationThirdParty
};

export const selectOrganizationThirdPartiesEntities = createSelector(selectOrganizationThirdPartyState, selectEntities);

export const selectOrganizationThirdPartiesArray = createSelector(selectOrganizationThirdPartyState, selectAll);

export const selectIdOrganizationThirdPartiesActive = createSelector(
  selectOrganizationThirdPartyState,
  (state: OrganizationThirdPartyState) => state.actives
);

const organizationThirdPartiesInObject = (organizationThirdParties: Dictionary<OrganizationThirdPartyEntityState>) => ({
  organizationThirdParties
});

const selectOrganizationThirdPartiesEntitiesDictionary = createSelector(
  selectOrganizationThirdPartiesEntities,
  organizationThirdPartiesInObject
);

const selectAllOrganizationThirdPartiesObject = createSelector(
  selectOrganizationThirdPartiesEntities,
  organizationThirdParties => {
    return hydrateAll({ organizationThirdParties });
  }
);

const selectOneOrganizationThirdPartyDictionary = (idOrganizationThirdParty: number) =>
  createSelector(selectOrganizationThirdPartiesEntities, organizationThirdParties => {
    return {
      organizationThirdParties: { [idOrganizationThirdParty]: organizationThirdParties[idOrganizationThirdParty] }
    };
  });

const selectOneOrganizationThirdPartyDictionaryWithoutChild = (idOrganizationThirdParty: number) =>
  createSelector(selectOrganizationThirdPartiesEntities, organizationThirdParties => {
    return { organizationThirdParty: organizationThirdParties[idOrganizationThirdParty] };
  });

const selectActiveOrganizationThirdPartiesEntities = createSelector(
  selectIdOrganizationThirdPartiesActive,
  selectOrganizationThirdPartiesEntities,
  (actives: number[], organizationThirdParties: Dictionary<OrganizationThirdPartyEntityState>) =>
    getOrganizationThirdPartiesFromActives(actives, organizationThirdParties)
);

function getOrganizationThirdPartiesFromActives(
  actives: number[],
  organizationThirdParties: Dictionary<OrganizationThirdPartyEntityState>
): Dictionary<OrganizationThirdPartyEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationThirdParties[idActive]) {
      acc[idActive] = organizationThirdParties[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationThirdPartyEntityState>);
}

const selectAllOrganizationThirdPartiesSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationThirdParties(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationThirdParty>(
      schema,
      selectAllOrganizationThirdPartiesSelectors,
      selectOrganizationThirdPartiesEntitiesDictionary,
      getRelationSelectors,
      organizationThirdPartyRelations,
      hydrateAll,
      'organizationThirdParty'
    );
  } else {
    return selectAllOrganizationThirdPartiesObject;
  }
}

export function selectAllOrganizationThirdPartiesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationThirdParties'
): Selector {
  return createSelector(selectAllOrganizationThirdParties(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationThirdPartyEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationThirdParties.length; i++) {
      res[customKey][result.organizationThirdParties[i].idOrganizationThirdParty] = result.organizationThirdParties[i];
    }
    return res;
  });
}

export function selectOneOrganizationThirdParty(schema: SelectSchema = {}, idOrganizationThirdParty: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationThirdPartyDictionary(idOrganizationThirdParty)];
    selectors.push(...getRelationSelectors(schema, organizationThirdPartyRelations, 'organizationThirdParty'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationThirdPartyDictionaryWithoutChild(idOrganizationThirdParty);
  }
}

export function selectActiveOrganizationThirdParties(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationThirdPartiesEntities, organizationThirdParties => ({
      organizationThirdParties
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationThirdPartyRelations, 'organizationThirdParty'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationThirdParties: Dictionary<OrganizationThirdPartyEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  organizationSocietes?: Dictionary<OrganizationSocieteEntityState>;
  organizationMetiers?: Dictionary<OrganizationMetierEntityState>;
  residenceContacts?: Dictionary<ResidenceContactEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationThirdParties: (OrganizationThirdParty | null)[] } {
  const { organizationThirdParties, organizations, organizationSocietes, organizationMetiers, residenceContacts } =
    args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationThirdParties: Object.keys(organizationThirdParties).map(idOrganizationThirdParty =>
      hydrate(
        organizationThirdParties[idOrganizationThirdParty] as OrganizationThirdPartyEntityState,
        organizations,
        organizationSocietes,
        organizationMetiers,
        residenceContacts
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationThirdParty: OrganizationThirdPartyEntityState | null } {
  const { organizationThirdParties, organizations, organizationSocietes, organizationMetiers, residenceContacts } =
    args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationThirdParty = Object.values(organizationThirdParties)[0];
  return {
    organizationThirdParty: hydrate(
      organizationThirdParty as OrganizationThirdPartyEntityState,
      organizations,
      organizationSocietes,
      organizationMetiers,
      residenceContacts
    )
  };
}

function hydrate(
  organizationThirdParty: OrganizationThirdPartyEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  organizationSocieteEntities?: Dictionary<OrganizationSocieteEntityState>,
  organizationMetierEntities?: Dictionary<OrganizationMetierEntityState>,
  residenceContactEntities?: Dictionary<ResidenceContactEntityState>
): OrganizationThirdParty | null {
  if (!organizationThirdParty) {
    return null;
  }

  const organizationThirdPartyHydrated: OrganizationThirdPartyEntityState = { ...organizationThirdParty };
  if (organizationEntities) {
    organizationThirdPartyHydrated.organization = organizationEntities[
      organizationThirdParty.organization as number
    ] as Organization;
  } else {
    delete organizationThirdPartyHydrated.organization;
  }
  if (organizationSocieteEntities) {
    organizationThirdPartyHydrated.organizationSociete = organizationSocieteEntities[
      organizationThirdParty.organizationSociete as number
    ] as OrganizationSociete;
  } else {
    delete organizationThirdPartyHydrated.organizationSociete;
  }
  if (organizationMetierEntities) {
    organizationThirdPartyHydrated.organizationMetier = organizationMetierEntities[
      organizationThirdParty.organizationMetier as number
    ] as OrganizationMetier;
  } else {
    delete organizationThirdPartyHydrated.organizationMetier;
  }

  if (residenceContactEntities) {
    organizationThirdPartyHydrated.residenceContacts = (
      (organizationThirdPartyHydrated.residenceContacts as number[]) || []
    ).map(id => residenceContactEntities[id]) as ResidenceContact[];
  } else {
    delete organizationThirdPartyHydrated.residenceContacts;
  }

  return organizationThirdPartyHydrated as OrganizationThirdParty;
}
