import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyUbiflowEntityState } from '@_model/interfaces/company-ubiflow.model';

export interface CompanyUbiflowState extends EntityState<CompanyUbiflowEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyUbiflowEntityState> = createEntityAdapter<CompanyUbiflowEntityState>({
  selectId: o => o.idCompanyUbiflow
});
export const initialState: CompanyUbiflowState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyUbiflowFeatureKey = 'companyUbiflow';
