import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedForecastPeriodApiService } from './forecast-period-api-generated.service';
import { Observable } from 'rxjs';
import { ForecastPeriod } from '@_model/interfaces/forecast-period.model';

@Injectable({
  providedIn: 'root'
})
export class ForecastPeriodApiService extends GeneratedForecastPeriodApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  fillAllResidenceForecast(): Observable<void> {
    return this.repo.create<void>('forecast-period/fillAllResidenceForecast', {});
  }

  getLockedYearForOrganization(organizationId: number): Observable<ForecastPeriod[]> {
    return this.repo.getData<ForecastPeriod[]>('forecast-period/locked/' + organizationId, {});
  }

  lockForecastPeriod(param: { idOrganization: number; idForecastPeriod: number }): Observable<ForecastPeriod[]> {
    return this.repo.create<ForecastPeriod[]>('forecast-period/lockForecastPeriodForOrganization', param);
  }

  unLockForecastPeriod(param: { idOrganization: number; idForecastPeriod: number }): Observable<ForecastPeriod[]> {
    return this.repo.create<ForecastPeriod[]>('forecast-period/unLockForecastPeriodForOrganization', param);
  }
}
