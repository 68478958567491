import { createAction, props } from '@ngrx/store';
import { City } from '@_shared/models/interfaces/city.model';
import { CityRelationsIds } from './city-generated.effects';

export * from './city-generated.actions';

export const getOneCity = createAction('[City] Get One City', props<{ idCity: number; params? }>());

export const getManyCitiesByCompany = createAction(
  '[City] Get Many Cities By Company',
  props<{ idCompany: number; params? }>()
);

export const getManyCities = createAction('[City] Get Many Cities', props<{ params: any }>());

export const addManyActives = createAction('[City] Add Many Actives City', props<{ idCities: number[] }>());

export const deleteOneActive = createAction('[City] Delete One Active City', props<{ idCity: number }>());

export const clearActive = createAction('[City] Clear Active City');

export const upsertOneCity = createAction(
  '[City] Upsert One City',
  props<{
    city: Partial<City>;
    ids?: CityRelationsIds;
  }>()
);

export const deleteOneCity = createAction('[City] Delete One City', props<{ idCity: number }>());
