import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CompanyCommunicationRecipient,
  CompanyCommunicationRecipientEntityState
} from '@_model/interfaces/company-communication-recipient.model';
import { CompanyCommunication, CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';
import { User, UserEntityState } from '@_model/interfaces/user.model';
import {
  CompanyCommunicationRecipientUser,
  CompanyCommunicationRecipientUserEntityState
} from '@_model/interfaces/company-communication-recipient-user.model';
import { OrganizationMetier, OrganizationMetierEntityState } from '@_model/interfaces/organization-metier.model';
import {
  CompanyCommunicationRecipientMetier,
  CompanyCommunicationRecipientMetierEntityState
} from '@_model/interfaces/company-communication-recipient-metier.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  companyCommunicationRecipientFeatureKey,
  CompanyCommunicationRecipientState
} from './company-communication-recipient.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyCommunicationRecipientRelations: string[] = [
  'companyCommunications',
  'users',
  'companyCommunicationRecipientUsers',
  'organizationMetiers',
  'companyCommunicationRecipientMetiers'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyCommunicationRecipientState = createFeatureSelector<CompanyCommunicationRecipientState>(
  companyCommunicationRecipientFeatureKey
);

export const selectIsLoadedCompanyCommunicationRecipient = createSelector(
  selectCompanyCommunicationRecipientState,
  (state: CompanyCommunicationRecipientState) => state.isLoaded
);

export const selectIsLoadingCompanyCommunicationRecipient = createSelector(
  selectCompanyCommunicationRecipientState,
  (state: CompanyCommunicationRecipientState) => state.isLoading
);

export const selectIsReadyCompanyCommunicationRecipient = createSelector(
  selectCompanyCommunicationRecipientState,
  (state: CompanyCommunicationRecipientState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyCommunicationRecipient = createSelector(
  selectCompanyCommunicationRecipientState,
  (state: CompanyCommunicationRecipientState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyCommunicationRecipientModel: SelectorModel = {
  name: 'companyCommunicationRecipients',
  getSelector: selectAllCompanyCommunicationRecipientsDictionary,
  isReady: selectIsReadyCompanyCommunicationRecipient
};

export const selectCompanyCommunicationRecipientsEntities = createSelector(
  selectCompanyCommunicationRecipientState,
  selectEntities
);

export const selectCompanyCommunicationRecipientsArray = createSelector(
  selectCompanyCommunicationRecipientState,
  selectAll
);

export const selectIdCompanyCommunicationRecipientsActive = createSelector(
  selectCompanyCommunicationRecipientState,
  (state: CompanyCommunicationRecipientState) => state.actives
);

const companyCommunicationRecipientsInObject = (
  companyCommunicationRecipients: Dictionary<CompanyCommunicationRecipientEntityState>
) => ({ companyCommunicationRecipients });

const selectCompanyCommunicationRecipientsEntitiesDictionary = createSelector(
  selectCompanyCommunicationRecipientsEntities,
  companyCommunicationRecipientsInObject
);

const selectAllCompanyCommunicationRecipientsObject = createSelector(
  selectCompanyCommunicationRecipientsEntities,
  companyCommunicationRecipients => {
    return hydrateAll({ companyCommunicationRecipients });
  }
);

const selectOneCompanyCommunicationRecipientDictionary = (idCompanyCommunicationRecipient: number) =>
  createSelector(selectCompanyCommunicationRecipientsEntities, companyCommunicationRecipients => {
    return {
      companyCommunicationRecipients: {
        [idCompanyCommunicationRecipient]: companyCommunicationRecipients[idCompanyCommunicationRecipient]
      }
    };
  });

const selectOneCompanyCommunicationRecipientDictionaryWithoutChild = (idCompanyCommunicationRecipient: number) =>
  createSelector(selectCompanyCommunicationRecipientsEntities, companyCommunicationRecipients => {
    return { companyCommunicationRecipient: companyCommunicationRecipients[idCompanyCommunicationRecipient] };
  });

const selectActiveCompanyCommunicationRecipientsEntities = createSelector(
  selectIdCompanyCommunicationRecipientsActive,
  selectCompanyCommunicationRecipientsEntities,
  (actives: number[], companyCommunicationRecipients: Dictionary<CompanyCommunicationRecipientEntityState>) =>
    getCompanyCommunicationRecipientsFromActives(actives, companyCommunicationRecipients)
);

function getCompanyCommunicationRecipientsFromActives(
  actives: number[],
  companyCommunicationRecipients: Dictionary<CompanyCommunicationRecipientEntityState>
): Dictionary<CompanyCommunicationRecipientEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyCommunicationRecipients[idActive]) {
      acc[idActive] = companyCommunicationRecipients[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyCommunicationRecipientEntityState>);
}

const selectAllCompanyCommunicationRecipientsSelectors: Dictionary<Selector> = {};
export function selectAllCompanyCommunicationRecipients(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyCommunicationRecipient>(
      schema,
      selectAllCompanyCommunicationRecipientsSelectors,
      selectCompanyCommunicationRecipientsEntitiesDictionary,
      getRelationSelectors,
      companyCommunicationRecipientRelations,
      hydrateAll,
      'companyCommunicationRecipient'
    );
  } else {
    return selectAllCompanyCommunicationRecipientsObject;
  }
}

export function selectAllCompanyCommunicationRecipientsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyCommunicationRecipients'
): Selector {
  return createSelector(selectAllCompanyCommunicationRecipients(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyCommunicationRecipientEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyCommunicationRecipients.length; i++) {
      res[customKey][result.companyCommunicationRecipients[i].idCompanyCommunicationRecipient] =
        result.companyCommunicationRecipients[i];
    }
    return res;
  });
}

export function selectOneCompanyCommunicationRecipient(
  schema: SelectSchema = {},
  idCompanyCommunicationRecipient: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyCommunicationRecipientDictionary(idCompanyCommunicationRecipient)];
    selectors.push(
      ...getRelationSelectors(schema, companyCommunicationRecipientRelations, 'companyCommunicationRecipient')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyCommunicationRecipientDictionaryWithoutChild(idCompanyCommunicationRecipient);
  }
}

export function selectActiveCompanyCommunicationRecipients(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyCommunicationRecipientsEntities, companyCommunicationRecipients => ({
      companyCommunicationRecipients
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, companyCommunicationRecipientRelations, 'companyCommunicationRecipient')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyCommunicationRecipients: Dictionary<CompanyCommunicationRecipientEntityState>;
  companyCommunications?: Dictionary<CompanyCommunicationEntityState>;
  users?: Dictionary<UserEntityState>;
  companyCommunicationRecipientUsers?: Dictionary<CompanyCommunicationRecipientUserEntityState>;
  organizationMetiers?: Dictionary<OrganizationMetierEntityState>;
  companyCommunicationRecipientMetiers?: Dictionary<CompanyCommunicationRecipientMetierEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  companyCommunicationRecipients: (CompanyCommunicationRecipient | null)[];
} {
  const {
    companyCommunicationRecipients,
    companyCommunications,
    users,
    companyCommunicationRecipientUsers,
    organizationMetiers,
    companyCommunicationRecipientMetiers
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    companyCommunicationRecipients: Object.keys(companyCommunicationRecipients).map(idCompanyCommunicationRecipient =>
      hydrate(
        companyCommunicationRecipients[idCompanyCommunicationRecipient] as CompanyCommunicationRecipientEntityState,
        companyCommunications,
        users,
        companyCommunicationRecipientUsers,
        organizationMetiers,
        companyCommunicationRecipientMetiers
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  companyCommunicationRecipient: CompanyCommunicationRecipientEntityState | null;
} {
  const {
    companyCommunicationRecipients,
    companyCommunications,
    users,
    companyCommunicationRecipientUsers,
    organizationMetiers,
    companyCommunicationRecipientMetiers
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const companyCommunicationRecipient = Object.values(companyCommunicationRecipients)[0];
  return {
    companyCommunicationRecipient: hydrate(
      companyCommunicationRecipient as CompanyCommunicationRecipientEntityState,
      companyCommunications,
      users,
      companyCommunicationRecipientUsers,
      organizationMetiers,
      companyCommunicationRecipientMetiers
    )
  };
}

function hydrate(
  companyCommunicationRecipient: CompanyCommunicationRecipientEntityState,
  companyCommunicationEntities?: Dictionary<CompanyCommunicationEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  companyCommunicationRecipientUserEntities?: Dictionary<CompanyCommunicationRecipientUserEntityState>,
  organizationMetierEntities?: Dictionary<OrganizationMetierEntityState>,
  companyCommunicationRecipientMetierEntities?: Dictionary<CompanyCommunicationRecipientMetierEntityState>
): CompanyCommunicationRecipient | null {
  if (!companyCommunicationRecipient) {
    return null;
  }

  const companyCommunicationRecipientHydrated: CompanyCommunicationRecipientEntityState = {
    ...companyCommunicationRecipient
  };

  if (companyCommunicationEntities) {
    companyCommunicationRecipientHydrated.companyCommunication = companyCommunicationEntities[
      companyCommunicationRecipient.companyCommunication as number
    ] as CompanyCommunication;
  } else {
    delete companyCommunicationRecipientHydrated.companyCommunication;
  }

  if (userEntities) {
    companyCommunicationRecipientHydrated.users = ((companyCommunicationRecipientHydrated.users as number[]) || []).map(
      id => userEntities[id]
    ) as User[];
  } else {
    delete companyCommunicationRecipientHydrated.users;
  }

  if (companyCommunicationRecipientUserEntities) {
    companyCommunicationRecipientHydrated.companyCommunicationRecipientUsers = (
      (companyCommunicationRecipientHydrated.companyCommunicationRecipientUsers as number[]) || []
    ).map(id => companyCommunicationRecipientUserEntities[id]) as CompanyCommunicationRecipientUser[];
  } else {
    delete companyCommunicationRecipientHydrated.companyCommunicationRecipientUsers;
  }

  if (organizationMetierEntities) {
    companyCommunicationRecipientHydrated.organizationMetiers = (
      (companyCommunicationRecipientHydrated.organizationMetiers as number[]) || []
    ).map(id => organizationMetierEntities[id]) as OrganizationMetier[];
  } else {
    delete companyCommunicationRecipientHydrated.organizationMetiers;
  }

  if (companyCommunicationRecipientMetierEntities) {
    companyCommunicationRecipientHydrated.companyCommunicationRecipientMetiers = (
      (companyCommunicationRecipientHydrated.companyCommunicationRecipientMetiers as number[]) || []
    ).map(id => companyCommunicationRecipientMetierEntities[id]) as CompanyCommunicationRecipientMetier[];
  } else {
    delete companyCommunicationRecipientHydrated.companyCommunicationRecipientMetiers;
  }

  return companyCommunicationRecipientHydrated as CompanyCommunicationRecipient;
}
