import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule as NativeStoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@_environments/environment';
import { NgrxHelperService } from '@_services/ngrx-helper.service';
import { effects } from './index.effects';
import * as Reducers from './index.reducers';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NativeStoreModule.forRoot(Reducers.appState, {
      metaReducers: Reducers.metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionSerializability: false,
        strictStateImmutability: true,
        strictStateSerializability: true
      }
    }),
    EffectsModule.forRoot(effects),
    environment.useDevtool || !environment.production ? StoreDevtoolsModule.instrument() : []
  ],
  providers: [NgrxHelperService]
})
export class StoreModule {}
