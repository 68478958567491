import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { Association, AssociationEntityState } from '@_model/interfaces/association.model';
import { AssociationApiService } from '@_services/api/association-api.service';
import * as associationActions from '@_store/association/association.actions';
import { getActionsToNormalizeAssociation } from '@_config/store/normalization.generated';
import { selectAssociationState } from './association-generated.selectors';
import * as stratalotAssociationActions from '@_store/stratalot-association/stratalot-association.actions';
import { StratalotAssociation } from '@_model/interfaces/stratalot-association.model';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import { Stratalot } from '@_model/interfaces/stratalot.model';

export interface AssociationRelationsIds {
  stratalotAssociations?: number | number[];
  stratalots?: number | number[];
}

export function getDefaultAddAssociationActions(
  association: AssociationEntityState,
  ids?: AssociationRelationsIds
): Action[] {
  const actions: Action[] = [associationActions.normalizeManyAssociationsAfterUpsert({ associations: [association] })];

  if (ids?.stratalotAssociations) {
    if (!Array.isArray(ids.stratalotAssociations)) {
      actions.push(
        stratalotAssociationActions.upsertOneStratalotAssociation({
          stratalotAssociation: {
            idAssociation: association.idAssociation,
            idStratalotAssociation: ids.stratalotAssociations as number
          } as StratalotAssociation
        })
      );
      actions.push(
        associationActions.addManyStratalotAssociationSuccess({
          idAssociation: association.idAssociation,
          idStratalotAssociations: [ids.stratalotAssociations as number]
        })
      );
    } else {
      actions.push(
        stratalotAssociationActions.upsertManyStratalotAssociations({
          stratalotAssociations: (ids.stratalotAssociations as number[]).map((idStratalotAssociation: number) => ({
            idAssociation: association.idAssociation,
            idStratalotAssociation
          })) as StratalotAssociation[]
        })
      );
      actions.push(
        associationActions.addManyStratalotAssociationSuccess({
          idAssociation: association.idAssociation,
          idStratalotAssociations: ids.stratalotAssociations as number[]
        })
      );
    }
  }

  if (ids?.stratalots) {
    if (!Array.isArray(ids.stratalots)) {
      actions.push(
        stratalotActions.upsertOneStratalot({
          stratalot: {
            idAssociation: association.idAssociation,
            idStratalot: ids.stratalots as number
          } as Stratalot & any
        })
      );
      actions.push(
        associationActions.addManyStratalotSuccess({
          idAssociation: association.idAssociation,
          idStratalots: [ids.stratalots as number]
        })
      );
    } else {
      actions.push(
        stratalotActions.upsertManyStratalots({
          stratalots: (ids.stratalots as number[]).map((idStratalot: number) => ({
            idAssociation: association.idAssociation,
            idStratalot
          })) as Stratalot[] & any[]
        })
      );
      actions.push(
        associationActions.addManyStratalotSuccess({
          idAssociation: association.idAssociation,
          idStratalots: ids.stratalots as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteAssociationActions(association: AssociationEntityState): Action[] {
  const actions: Action[] = [
    associationActions.deleteOneAssociationSuccess({ idAssociation: association.idAssociation })
  ];

  if (association.stratalotAssociations) {
    actions.push(
      stratalotAssociationActions.deleteManyAssociationSuccess({
        idAssociations: [association.idAssociation],
        idStratalotAssociations: association.stratalotAssociations as number[]
      })
    );
  }

  if (association.stratalots) {
    actions.push(
      stratalotActions.deleteManyAssociationSuccess({
        idAssociations: [association.idAssociation],
        idStratalots: association.stratalots as number[]
      })
    );
  }

  return actions;
}

export class GeneratedAssociationEffects {
  constructor(
    protected actions$: Actions,
    protected associationApiService: AssociationApiService,
    protected store$: Store<AppState>
  ) {}

  getManyAssociations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(associationActions.getManyAssociations),
      switchMap(({ params }) =>
        this.associationApiService.getAssociations(params).pipe(
          map((associations: Association[]) => {
            return associationActions.normalizeManyAssociationsAfterUpsert({ associations });
          }),
          catchError(error => of(associationActions.associationsFailure({ error })))
        )
      )
    );
  });

  getOneAssociation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(associationActions.getOneAssociation),
      switchMap(idAssociation =>
        this.associationApiService.getAssociation(idAssociation).pipe(
          map((association: Association) => {
            return associationActions.normalizeManyAssociationsAfterUpsert({ associations: [association] });
          }),
          catchError(error => of(associationActions.associationsFailure({ error })))
        )
      )
    );
  });

  upsertOneAssociation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(associationActions.upsertOneAssociation),
      concatMap(({ association, ids }: { association: Partial<Association>; ids?: AssociationRelationsIds }) => {
        if (association.idAssociation) {
          return this.associationApiService.updateAssociation(association).pipe(
            map((associationReturned: Association) => {
              return associationActions.normalizeManyAssociationsAfterUpsert({ associations: [associationReturned] });
            }),
            catchError(error => of(associationActions.associationsFailure({ error })))
          );
        } else {
          return this.associationApiService.addAssociation(association).pipe(
            mergeMap((associationReturned: Association) => getDefaultAddAssociationActions(associationReturned, ids)),
            catchError(error => of(associationActions.associationsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneAssociation$ = createEffect(() => {
    const selectAssociationState$ = this.store$.select(selectAssociationState);
    return this.actions$.pipe(
      ofType(associationActions.deleteOneAssociation),
      withLatestFrom(selectAssociationState$),
      concatMap(([{ idAssociation }, state]) =>
        this.associationApiService.deleteAssociation(idAssociation).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteAssociationActions(state.entities[idAssociation] as AssociationEntityState),
              associationActions.deleteOneAssociation.type
            )
          ]),
          catchError(error => of(associationActions.associationsFailure({ error })))
        )
      )
    );
  });

  normalizeManyAssociationsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(associationActions.normalizeManyAssociationsAfterUpsert),
      concatMap(({ associations }) => {
        const actions: Action[] = getActionsToNormalizeAssociation(associations, StoreActionType.upsert);
        return [getMultiAction(actions, '[Association] Normalization After Upsert Success')];
      })
    );
  });
}
