import { SaleCategoryFamilyName } from '@_shared/models/enums/sale-category-family.enum';
import { SaleFamilyCategoryRoutingEnum } from './path-enum/sale-family-category-routing.enum';
namespace SaleFamilyCategoryUtils {
  export const categorieVentes: SaleFamilyCategoryRoutingEnum[] = [
    SaleFamilyCategoryRoutingEnum.ancien,
    SaleFamilyCategoryRoutingEnum.neuf
  ];

  let _activeCategorieVente: SaleFamilyCategoryRoutingEnum = SaleFamilyCategoryRoutingEnum.ancien;

  export function getActiveSaleFamilyCategory(): SaleFamilyCategoryRoutingEnum {
    return _activeCategorieVente;
  }
  export function setActiveSaleFamilyCategory(categorieVente: SaleFamilyCategoryRoutingEnum): void {
    _activeCategorieVente = categorieVente;
  }

  export function isUnbuilt(): boolean {
    return _activeCategorieVente === SaleFamilyCategoryRoutingEnum.neuf;
  }

  export function getActiveSaleCategoryFamilyNameByRoutingEnum(): SaleCategoryFamilyName[] {
    return isUnbuilt() ? [SaleCategoryFamilyName.psla, SaleCategoryFamilyName.vefa] : [SaleCategoryFamilyName.ancien];
  }
}
export default SaleFamilyCategoryUtils;
