import { RepositoryService } from '@_services/api/repository.service';
import { CompanyTerritoire } from '@_model/interfaces/company-territoire.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyTerritoireApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyTerritoires(params?: any): Observable<CompanyTerritoire[]> {
    return this.repo.getData<CompanyTerritoire[]>('company-territoire', params);
  }

  public getCompanyTerritoire(params: { idCompanyTerritoire: number; params?: any }): Observable<CompanyTerritoire> {
    return this.repo.getData<CompanyTerritoire>('company-territoire/' + params.idCompanyTerritoire, params.params);
  }

  public addCompanyTerritoire(companyTerritoire: Partial<CompanyTerritoire>): Observable<CompanyTerritoire> {
    return this.repo.create<CompanyTerritoire>('company-territoire', companyTerritoire);
  }

  public updateCompanyTerritoire(companyTerritoire: Partial<CompanyTerritoire>): Observable<CompanyTerritoire> {
    return this.repo.update('company-territoire', companyTerritoire);
  }

  public deleteCompanyTerritoire(idCompanyTerritoire: number): Observable<number> {
    return this.repo.delete('company-territoire/' + +idCompanyTerritoire);
  }
}
