import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { MetierFamilie, MetierFamilieEntityState } from '@_model/interfaces/metier-familie.model';
import { MetierFamilieApiService } from '@_services/api/metier-familie-api.service';
import * as metierFamilieActions from '@_store/metier-familie/metier-familie.actions';
import { getActionsToNormalizeMetierFamilie } from '@_config/store/normalization.generated';
import { selectMetierFamilieState } from './metier-familie-generated.selectors';
import * as organizationMetierActions from '@_store/organization-metier/organization-metier.actions';
import { OrganizationMetier } from '@_model/interfaces/organization-metier.model';

export interface MetierFamilieRelationsIds {
  organizationMetiers?: number | number[];
}

export function getDefaultAddMetierFamilieActions(
  metierFamilie: MetierFamilieEntityState,
  ids?: MetierFamilieRelationsIds
): Action[] {
  const actions: Action[] = [
    metierFamilieActions.normalizeManyMetierFamiliesAfterUpsert({ metierFamilies: [metierFamilie] })
  ];

  if (ids?.organizationMetiers) {
    if (!Array.isArray(ids.organizationMetiers)) {
      actions.push(
        organizationMetierActions.upsertOneOrganizationMetier({
          organizationMetier: {
            idMetierFamilie: metierFamilie.idMetierFamilie,
            idOrganizationMetier: ids.organizationMetiers as number
          } as OrganizationMetier
        })
      );
      actions.push(
        metierFamilieActions.addManyOrganizationMetierSuccess({
          idMetierFamilie: metierFamilie.idMetierFamilie,
          idOrganizationMetiers: [ids.organizationMetiers as number]
        })
      );
    } else {
      actions.push(
        organizationMetierActions.upsertManyOrganizationMetiers({
          organizationMetiers: (ids.organizationMetiers as number[]).map((idOrganizationMetier: number) => ({
            idMetierFamilie: metierFamilie.idMetierFamilie,
            idOrganizationMetier
          })) as OrganizationMetier[]
        })
      );
      actions.push(
        metierFamilieActions.addManyOrganizationMetierSuccess({
          idMetierFamilie: metierFamilie.idMetierFamilie,
          idOrganizationMetiers: ids.organizationMetiers as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteMetierFamilieActions(metierFamilie: MetierFamilieEntityState): Action[] {
  const actions: Action[] = [
    metierFamilieActions.deleteOneMetierFamilieSuccess({ idMetierFamilie: metierFamilie.idMetierFamilie })
  ];

  if (metierFamilie.organizationMetiers) {
    actions.push(
      organizationMetierActions.deleteManyMetierFamilieSuccess({
        idMetierFamilies: [metierFamilie.idMetierFamilie],
        idOrganizationMetiers: metierFamilie.organizationMetiers as number[]
      })
    );
  }

  return actions;
}

export class GeneratedMetierFamilieEffects {
  constructor(
    protected actions$: Actions,
    protected metierFamilieApiService: MetierFamilieApiService,
    protected store$: Store<AppState>
  ) {}

  getManyMetierFamilies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(metierFamilieActions.getManyMetierFamilies),
      switchMap(({ params }) =>
        this.metierFamilieApiService.getMetierFamilies(params).pipe(
          map((metierFamilies: MetierFamilie[]) => {
            return metierFamilieActions.normalizeManyMetierFamiliesAfterUpsert({ metierFamilies });
          }),
          catchError(error => of(metierFamilieActions.metierFamiliesFailure({ error })))
        )
      )
    );
  });

  getOneMetierFamilie$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(metierFamilieActions.getOneMetierFamilie),
      switchMap(idMetierFamilie =>
        this.metierFamilieApiService.getMetierFamilie(idMetierFamilie).pipe(
          map((metierFamilie: MetierFamilie) => {
            return metierFamilieActions.normalizeManyMetierFamiliesAfterUpsert({ metierFamilies: [metierFamilie] });
          }),
          catchError(error => of(metierFamilieActions.metierFamiliesFailure({ error })))
        )
      )
    );
  });

  upsertOneMetierFamilie$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(metierFamilieActions.upsertOneMetierFamilie),
      concatMap(
        ({ metierFamilie, ids }: { metierFamilie: Partial<MetierFamilie>; ids?: MetierFamilieRelationsIds }) => {
          if (metierFamilie.idMetierFamilie) {
            return this.metierFamilieApiService.updateMetierFamilie(metierFamilie).pipe(
              map((metierFamilieReturned: MetierFamilie) => {
                return metierFamilieActions.normalizeManyMetierFamiliesAfterUpsert({
                  metierFamilies: [metierFamilieReturned]
                });
              }),
              catchError(error => of(metierFamilieActions.metierFamiliesFailure({ error })))
            );
          } else {
            return this.metierFamilieApiService.addMetierFamilie(metierFamilie).pipe(
              mergeMap((metierFamilieReturned: MetierFamilie) =>
                getDefaultAddMetierFamilieActions(metierFamilieReturned, ids)
              ),
              catchError(error => of(metierFamilieActions.metierFamiliesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneMetierFamilie$ = createEffect(() => {
    const selectMetierFamilieState$ = this.store$.select(selectMetierFamilieState);
    return this.actions$.pipe(
      ofType(metierFamilieActions.deleteOneMetierFamilie),
      withLatestFrom(selectMetierFamilieState$),
      concatMap(([{ idMetierFamilie }, state]) =>
        this.metierFamilieApiService.deleteMetierFamilie(idMetierFamilie).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteMetierFamilieActions(state.entities[idMetierFamilie] as MetierFamilieEntityState),
              metierFamilieActions.deleteOneMetierFamilie.type
            )
          ]),
          catchError(error => of(metierFamilieActions.metierFamiliesFailure({ error })))
        )
      )
    );
  });

  normalizeManyMetierFamiliesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(metierFamilieActions.normalizeManyMetierFamiliesAfterUpsert),
      concatMap(({ metierFamilies }) => {
        const actions: Action[] = getActionsToNormalizeMetierFamilie(metierFamilies, StoreActionType.upsert);
        return [getMultiAction(actions, '[MetierFamilie] Normalization After Upsert Success')];
      })
    );
  });
}
