import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { GeneratedDocument } from '@_model/interfaces/generated-document.model';
import { GeneratedDocumentApiService } from '@_services/api/generated-document-api.service';
import * as generatedDocumentActions from '@_store/generated-document/generated-document.actions';
import { AppState } from '@_store/index.reducers';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { GeneratedGeneratedDocumentEffects } from './generated-document-generated.effects';

@Injectable()
export class GeneratedDocumentEffects extends GeneratedGeneratedDocumentEffects {
  constructor(actions$: Actions, generatedDocumentApiService: GeneratedDocumentApiService, store$: Store<AppState>) {
    super(actions$, generatedDocumentApiService, store$);
  }

  addOneGeneratedDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(generatedDocumentActions.addOneGeneratedDocument),
      concatMap(
        ({
          generatedDocument,
          idCompany,
          idResidence
        }: {
          generatedDocument: Partial<GeneratedDocument>;
          idCompany?: number[];
          idResidence?: number;
        }) => {
          return this.generatedDocumentApiService
            .addGeneratedDocumentWithCompanyOrResidence(generatedDocument, idCompany, idResidence)
            .pipe(
              concatMap((generatedDocumentReturned: GeneratedDocument) => {
                const actions: Action[] = [
                  generatedDocumentActions.normalizeManyGeneratedDocumentsAfterUpsert({
                    generatedDocuments: [generatedDocumentReturned]
                  })
                ];

                return actions;
              }),
              catchError(error => of(generatedDocumentActions.generatedDocumentsFailure({ error })))
            );
        }
      )
    );
  });

  getManyGeneratedDocumentsCompanies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(generatedDocumentActions.getManyGeneratedDocumentsByCompanies),
      switchMap(({ idsCompanies, page }) =>
        this.generatedDocumentApiService.getListByCompany({ idsCompanies, page }).pipe(
          map((generatedDocuments: GeneratedDocument[]) => {
            return generatedDocumentActions.normalizeManyGeneratedDocumentsAfterUpsert({ generatedDocuments });
          }),
          catchError(error => of(generatedDocumentActions.generatedDocumentsFailure({ error })))
        )
      )
    );
  });
  getManyGeneratedDocumentsResidences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(generatedDocumentActions.getManyGeneratedDocumentsByResidences),
      switchMap(({ idsResidences }) =>
        this.generatedDocumentApiService.getListByResidence({ idsResidences }).pipe(
          map((generatedDocuments: GeneratedDocument[]) => {
            return generatedDocumentActions.normalizeManyGeneratedDocumentsAfterUpsert({ generatedDocuments });
          }),
          catchError(error => of(generatedDocumentActions.generatedDocumentsFailure({ error })))
        )
      )
    );
  });
}
