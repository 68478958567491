import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationStratalotTodo,
  OrganizationStratalotTodoEntityState
} from '@_model/interfaces/organization-stratalot-todo.model';
import { StratalotTodo, StratalotTodoEntityState } from '@_model/interfaces/stratalot-todo.model';
import {
  OrganizationStratalotTodoRule,
  OrganizationStratalotTodoRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-rule.model';
import {
  OrganizationStratalotTodoAppliedRule,
  OrganizationStratalotTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-applied-rule.model';
import {
  OrganizationStratalotTodoProfil,
  OrganizationStratalotTodoProfilEntityState
} from '@_model/interfaces/organization-stratalot-todo-profil.model';
import { CompanyCommunication, CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';
import {
  CompanyCommunicationStratalotTodo,
  CompanyCommunicationStratalotTodoEntityState
} from '@_model/interfaces/company-communication-stratalot-todo.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { Diagnostic, DiagnosticEntityState } from '@_model/interfaces/diagnostic.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationStratalotTodoFeatureKey,
  OrganizationStratalotTodoState
} from './organization-stratalot-todo.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationStratalotTodoRelations: string[] = [
  'stratalotTodos',
  'organizationStratalotTodoRules',
  'organizationStratalotTodoAppliedRules',
  'organizationStratalotTodoProfils',
  'companyCommunications',
  'companyCommunicationStratalotTodos',
  'organizations',
  'diagnostics'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationStratalotTodoState = createFeatureSelector<OrganizationStratalotTodoState>(
  organizationStratalotTodoFeatureKey
);

export const selectIsLoadedOrganizationStratalotTodo = createSelector(
  selectOrganizationStratalotTodoState,
  (state: OrganizationStratalotTodoState) => state.isLoaded
);

export const selectIsLoadingOrganizationStratalotTodo = createSelector(
  selectOrganizationStratalotTodoState,
  (state: OrganizationStratalotTodoState) => state.isLoading
);

export const selectIsReadyOrganizationStratalotTodo = createSelector(
  selectOrganizationStratalotTodoState,
  (state: OrganizationStratalotTodoState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationStratalotTodo = createSelector(
  selectOrganizationStratalotTodoState,
  (state: OrganizationStratalotTodoState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationStratalotTodoModel: SelectorModel = {
  name: 'organizationStratalotTodos',
  getSelector: selectAllOrganizationStratalotTodosDictionary,
  isReady: selectIsReadyOrganizationStratalotTodo
};

export const selectOrganizationStratalotTodosEntities = createSelector(
  selectOrganizationStratalotTodoState,
  selectEntities
);

export const selectOrganizationStratalotTodosArray = createSelector(selectOrganizationStratalotTodoState, selectAll);

export const selectIdOrganizationStratalotTodosActive = createSelector(
  selectOrganizationStratalotTodoState,
  (state: OrganizationStratalotTodoState) => state.actives
);

const organizationStratalotTodosInObject = (
  organizationStratalotTodos: Dictionary<OrganizationStratalotTodoEntityState>
) => ({ organizationStratalotTodos });

const selectOrganizationStratalotTodosEntitiesDictionary = createSelector(
  selectOrganizationStratalotTodosEntities,
  organizationStratalotTodosInObject
);

const selectAllOrganizationStratalotTodosObject = createSelector(
  selectOrganizationStratalotTodosEntities,
  organizationStratalotTodos => {
    return hydrateAll({ organizationStratalotTodos });
  }
);

const selectOneOrganizationStratalotTodoDictionary = (idOrganizationStratalotTodo: number) =>
  createSelector(selectOrganizationStratalotTodosEntities, organizationStratalotTodos => {
    return {
      organizationStratalotTodos: {
        [idOrganizationStratalotTodo]: organizationStratalotTodos[idOrganizationStratalotTodo]
      }
    };
  });

const selectOneOrganizationStratalotTodoDictionaryWithoutChild = (idOrganizationStratalotTodo: number) =>
  createSelector(selectOrganizationStratalotTodosEntities, organizationStratalotTodos => {
    return { organizationStratalotTodo: organizationStratalotTodos[idOrganizationStratalotTodo] };
  });

const selectActiveOrganizationStratalotTodosEntities = createSelector(
  selectIdOrganizationStratalotTodosActive,
  selectOrganizationStratalotTodosEntities,
  (actives: number[], organizationStratalotTodos: Dictionary<OrganizationStratalotTodoEntityState>) =>
    getOrganizationStratalotTodosFromActives(actives, organizationStratalotTodos)
);

function getOrganizationStratalotTodosFromActives(
  actives: number[],
  organizationStratalotTodos: Dictionary<OrganizationStratalotTodoEntityState>
): Dictionary<OrganizationStratalotTodoEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationStratalotTodos[idActive]) {
      acc[idActive] = organizationStratalotTodos[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationStratalotTodoEntityState>);
}

const selectAllOrganizationStratalotTodosSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationStratalotTodos(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationStratalotTodo>(
      schema,
      selectAllOrganizationStratalotTodosSelectors,
      selectOrganizationStratalotTodosEntitiesDictionary,
      getRelationSelectors,
      organizationStratalotTodoRelations,
      hydrateAll,
      'organizationStratalotTodo'
    );
  } else {
    return selectAllOrganizationStratalotTodosObject;
  }
}

export function selectAllOrganizationStratalotTodosDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationStratalotTodos'
): Selector {
  return createSelector(selectAllOrganizationStratalotTodos(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationStratalotTodoEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationStratalotTodos.length; i++) {
      res[customKey][result.organizationStratalotTodos[i].idOrganizationStratalotTodo] =
        result.organizationStratalotTodos[i];
    }
    return res;
  });
}

export function selectOneOrganizationStratalotTodo(
  schema: SelectSchema = {},
  idOrganizationStratalotTodo: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationStratalotTodoDictionary(idOrganizationStratalotTodo)];
    selectors.push(...getRelationSelectors(schema, organizationStratalotTodoRelations, 'organizationStratalotTodo'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationStratalotTodoDictionaryWithoutChild(idOrganizationStratalotTodo);
  }
}

export function selectActiveOrganizationStratalotTodos(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationStratalotTodosEntities, organizationStratalotTodos => ({
      organizationStratalotTodos
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationStratalotTodoRelations, 'organizationStratalotTodo'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationStratalotTodos: Dictionary<OrganizationStratalotTodoEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  diagnostics?: Dictionary<DiagnosticEntityState>;
  stratalotTodos?: Dictionary<StratalotTodoEntityState>;
  organizationStratalotTodoRules?: Dictionary<OrganizationStratalotTodoRuleEntityState>;
  organizationStratalotTodoAppliedRules?: Dictionary<OrganizationStratalotTodoAppliedRuleEntityState>;
  organizationStratalotTodoProfils?: Dictionary<OrganizationStratalotTodoProfilEntityState>;
  companyCommunications?: Dictionary<CompanyCommunicationEntityState>;
  companyCommunicationStratalotTodos?: Dictionary<CompanyCommunicationStratalotTodoEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationStratalotTodos: (OrganizationStratalotTodo | null)[];
} {
  const {
    organizationStratalotTodos,
    organizations,
    diagnostics,
    stratalotTodos,
    organizationStratalotTodoRules,
    organizationStratalotTodoAppliedRules,
    organizationStratalotTodoProfils,
    companyCommunications,
    companyCommunicationStratalotTodos
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationStratalotTodos: Object.keys(organizationStratalotTodos).map(idOrganizationStratalotTodo =>
      hydrate(
        organizationStratalotTodos[idOrganizationStratalotTodo] as OrganizationStratalotTodoEntityState,
        organizations,
        diagnostics,
        stratalotTodos,
        organizationStratalotTodoRules,
        organizationStratalotTodoAppliedRules,
        organizationStratalotTodoProfils,
        companyCommunications,
        companyCommunicationStratalotTodos
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationStratalotTodo: OrganizationStratalotTodoEntityState | null;
} {
  const {
    organizationStratalotTodos,
    organizations,
    diagnostics,
    stratalotTodos,
    organizationStratalotTodoRules,
    organizationStratalotTodoAppliedRules,
    organizationStratalotTodoProfils,
    companyCommunications,
    companyCommunicationStratalotTodos
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationStratalotTodo = Object.values(organizationStratalotTodos)[0];
  return {
    organizationStratalotTodo: hydrate(
      organizationStratalotTodo as OrganizationStratalotTodoEntityState,
      organizations,
      diagnostics,
      stratalotTodos,
      organizationStratalotTodoRules,
      organizationStratalotTodoAppliedRules,
      organizationStratalotTodoProfils,
      companyCommunications,
      companyCommunicationStratalotTodos
    )
  };
}

function hydrate(
  organizationStratalotTodo: OrganizationStratalotTodoEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  diagnosticEntities?: Dictionary<DiagnosticEntityState>,
  stratalotTodoEntities?: Dictionary<StratalotTodoEntityState>,
  organizationStratalotTodoRuleEntities?: Dictionary<OrganizationStratalotTodoRuleEntityState>,
  organizationStratalotTodoAppliedRuleEntities?: Dictionary<OrganizationStratalotTodoAppliedRuleEntityState>,
  organizationStratalotTodoProfilEntities?: Dictionary<OrganizationStratalotTodoProfilEntityState>,
  companyCommunicationEntities?: Dictionary<CompanyCommunicationEntityState>,
  companyCommunicationStratalotTodoEntities?: Dictionary<CompanyCommunicationStratalotTodoEntityState>
): OrganizationStratalotTodo | null {
  if (!organizationStratalotTodo) {
    return null;
  }

  const organizationStratalotTodoHydrated: OrganizationStratalotTodoEntityState = { ...organizationStratalotTodo };
  if (organizationEntities) {
    organizationStratalotTodoHydrated.organization = organizationEntities[
      organizationStratalotTodo.organization as number
    ] as Organization;
  } else {
    delete organizationStratalotTodoHydrated.organization;
  }
  if (diagnosticEntities) {
    organizationStratalotTodoHydrated.diagnostic = diagnosticEntities[
      organizationStratalotTodo.diagnostic as number
    ] as Diagnostic;
  } else {
    delete organizationStratalotTodoHydrated.diagnostic;
  }

  if (stratalotTodoEntities) {
    organizationStratalotTodoHydrated.stratalotTodos = (
      (organizationStratalotTodoHydrated.stratalotTodos as number[]) || []
    ).map(id => stratalotTodoEntities[id]) as StratalotTodo[];
  } else {
    delete organizationStratalotTodoHydrated.stratalotTodos;
  }

  if (organizationStratalotTodoRuleEntities) {
    organizationStratalotTodoHydrated.organizationStratalotTodoRules = (
      (organizationStratalotTodoHydrated.organizationStratalotTodoRules as number[]) || []
    ).map(id => organizationStratalotTodoRuleEntities[id]) as OrganizationStratalotTodoRule[];
  } else {
    delete organizationStratalotTodoHydrated.organizationStratalotTodoRules;
  }

  if (organizationStratalotTodoAppliedRuleEntities) {
    organizationStratalotTodoHydrated.organizationStratalotTodoAppliedRules = (
      (organizationStratalotTodoHydrated.organizationStratalotTodoAppliedRules as number[]) || []
    ).map(id => organizationStratalotTodoAppliedRuleEntities[id]) as OrganizationStratalotTodoAppliedRule[];
  } else {
    delete organizationStratalotTodoHydrated.organizationStratalotTodoAppliedRules;
  }

  if (organizationStratalotTodoProfilEntities) {
    organizationStratalotTodoHydrated.organizationStratalotTodoProfils = (
      (organizationStratalotTodoHydrated.organizationStratalotTodoProfils as number[]) || []
    ).map(id => organizationStratalotTodoProfilEntities[id]) as OrganizationStratalotTodoProfil[];
  } else {
    delete organizationStratalotTodoHydrated.organizationStratalotTodoProfils;
  }

  if (companyCommunicationEntities) {
    organizationStratalotTodoHydrated.companyCommunications = (
      (organizationStratalotTodoHydrated.companyCommunications as number[]) || []
    ).map(id => companyCommunicationEntities[id]) as CompanyCommunication[];
  } else {
    delete organizationStratalotTodoHydrated.companyCommunications;
  }

  if (companyCommunicationStratalotTodoEntities) {
    organizationStratalotTodoHydrated.companyCommunicationStratalotTodos = (
      (organizationStratalotTodoHydrated.companyCommunicationStratalotTodos as number[]) || []
    ).map(id => companyCommunicationStratalotTodoEntities[id]) as CompanyCommunicationStratalotTodo[];
  } else {
    delete organizationStratalotTodoHydrated.companyCommunicationStratalotTodos;
  }

  return organizationStratalotTodoHydrated as OrganizationStratalotTodo;
}
