import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidencePriceGridHistoryActions from './residence-price-grid-history.actions';
import { adapter, initialState, ResidencePriceGridHistoryState } from './residence-price-grid-history.state';
import { Dictionary } from '@ngrx/entity';
import { ResidencePriceGridHistoryEntityState } from '@_model/interfaces/residence-price-grid-history.model';

export const residencePriceGridHistoryReducersGeneratedFunctions: ReducerTypes<
  ResidencePriceGridHistoryState,
  readonly ActionCreator[]
>[] = [
  on(ResidencePriceGridHistoryActions.getOneResidencePriceGridHistory, (state: ResidencePriceGridHistoryState) =>
    setLoadingsState(state, true)
  ),
  on(ResidencePriceGridHistoryActions.getManyResidencePriceGridHistories, (state: ResidencePriceGridHistoryState) =>
    setLoadingsState(state, true)
  ),
  on(ResidencePriceGridHistoryActions.upsertOneResidencePriceGridHistory, (state: ResidencePriceGridHistoryState) =>
    setLoadingsState(state, true)
  ),

  on(
    ResidencePriceGridHistoryActions.upsertManyResidencePriceGridHistoriesSuccess,
    (state: ResidencePriceGridHistoryState, { residencePriceGridHistories }) =>
      adapter.upsertMany(residencePriceGridHistories, setLoadingsState(state, false))
  ),
  on(ResidencePriceGridHistoryActions.deleteOneResidencePriceGridHistory, (state: ResidencePriceGridHistoryState) =>
    setLoadingsState(state, true)
  ),
  on(
    ResidencePriceGridHistoryActions.deleteOneResidencePriceGridHistorySuccess,
    (state: ResidencePriceGridHistoryState, { idResidencePriceGridHistory }) =>
      adapter.removeOne(idResidencePriceGridHistory, setLoadingsState(state, false))
  ),
  on(ResidencePriceGridHistoryActions.clearActive, (state: ResidencePriceGridHistoryState) => ({
    ...state,
    actives: []
  })),
  on(
    ResidencePriceGridHistoryActions.addManyActives,
    (state: ResidencePriceGridHistoryState, { idResidencePriceGridHistories }) => ({
      ...state,
      actives: state.actives.concat(idResidencePriceGridHistories)
    })
  ),
  on(
    ResidencePriceGridHistoryActions.deleteOneActive,
    (state: ResidencePriceGridHistoryState, { idResidencePriceGridHistory }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idResidencePriceGridHistory)
    })
  ),

  on(ResidencePriceGridHistoryActions.clearStore, () => initialState),

  on(
    ResidencePriceGridHistoryActions.addResidencePriceGridSuccess,
    (state: ResidencePriceGridHistoryState, { idResidencePriceGridHistory, idResidencePriceGrid }) => {
      if (!state.entities[idResidencePriceGridHistory]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidencePriceGridHistory]: {
            ...state.entities[idResidencePriceGridHistory],
            residencePriceGrid: idResidencePriceGrid
          }
        }
      };
    }
  ),

  on(
    ResidencePriceGridHistoryActions.deleteManyResidencePriceGridSuccess,
    (state: ResidencePriceGridHistoryState, { idResidencePriceGrids, idResidencePriceGridHistories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidencePriceGridHistories.reduce((entities, idResidencePriceGridHistory) => {
            if (!state.entities[idResidencePriceGridHistory]?.residencePriceGrid) {
              return entities;
            }
            entities[idResidencePriceGridHistory] = {
              ...state.entities[idResidencePriceGridHistory],
              residencePriceGrid: idResidencePriceGrids.some(
                (idResidencePriceGrid: number) =>
                  idResidencePriceGrid === state.entities[idResidencePriceGridHistory]?.residencePriceGrid
              )
                ? undefined
                : state.entities[idResidencePriceGridHistory]?.residencePriceGrid
            } as ResidencePriceGridHistoryEntityState;
            return entities;
          }, {} as Dictionary<ResidencePriceGridHistoryEntityState>)
        }
      };
    }
  ),

  on(
    ResidencePriceGridHistoryActions.addUserSuccess,
    (state: ResidencePriceGridHistoryState, { idResidencePriceGridHistory, idUser }) => {
      if (!state.entities[idResidencePriceGridHistory]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidencePriceGridHistory]: {
            ...state.entities[idResidencePriceGridHistory],
            user: idUser
          }
        }
      };
    }
  ),

  on(
    ResidencePriceGridHistoryActions.deleteManyUserSuccess,
    (state: ResidencePriceGridHistoryState, { idUsers, idResidencePriceGridHistories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidencePriceGridHistories.reduce((entities, idResidencePriceGridHistory) => {
            if (!state.entities[idResidencePriceGridHistory]?.user) {
              return entities;
            }
            entities[idResidencePriceGridHistory] = {
              ...state.entities[idResidencePriceGridHistory],
              user: idUsers.some((idUser: number) => idUser === state.entities[idResidencePriceGridHistory]?.user)
                ? undefined
                : state.entities[idResidencePriceGridHistory]?.user
            } as ResidencePriceGridHistoryEntityState;
            return entities;
          }, {} as Dictionary<ResidencePriceGridHistoryEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidencePriceGridHistoryState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidencePriceGridHistoryState {
  return { ...state, isLoaded, isLoading };
}
