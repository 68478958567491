import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { LeadTodo, LeadTodoEntityState } from '@_model/interfaces/lead-todo.model';
import { LeadTodoApiService } from '@_services/api/lead-todo-api.service';
import * as leadTodoActions from '@_store/lead-todo/lead-todo.actions';
import { getActionsToNormalizeLeadTodo } from '@_config/store/normalization.generated';
import { selectLeadTodoState } from './lead-todo-generated.selectors';
import * as organizationLeadTodoActions from '@_store/organization-lead-todo/organization-lead-todo.actions';
import * as leadActions from '@_store/lead/lead.actions';

export interface LeadTodoRelationsIds {
  organizationLeadTodo?: number;
  lead?: number;
}

export function getDefaultAddLeadTodoActions(leadTodo: LeadTodoEntityState, ids?: LeadTodoRelationsIds): Action[] {
  const actions: Action[] = [leadTodoActions.normalizeManyLeadTodosAfterUpsert({ leadTodos: [leadTodo] })];

  if (ids?.organizationLeadTodo) {
    actions.push(
      organizationLeadTodoActions.addManyLeadTodoSuccess({
        idOrganizationLeadTodo: ids.organizationLeadTodo,
        idLeadTodos: [leadTodo.idLeadTodo]
      })
    );
    actions.push(
      leadTodoActions.addOrganizationLeadTodoSuccess({
        idLeadTodo: leadTodo.idLeadTodo,
        idOrganizationLeadTodo: ids.organizationLeadTodo
      })
    );
  }

  if (ids?.lead) {
    actions.push(
      leadActions.addManyLeadTodoSuccess({
        idLead: ids.lead,
        idLeadTodos: [leadTodo.idLeadTodo]
      })
    );
    actions.push(
      leadTodoActions.addLeadSuccess({
        idLeadTodo: leadTodo.idLeadTodo,
        idLead: ids.lead
      })
    );
  }

  return actions;
}

export function getDefaultDeleteLeadTodoActions(leadTodo: LeadTodoEntityState): Action[] {
  const actions: Action[] = [leadTodoActions.deleteOneLeadTodoSuccess({ idLeadTodo: leadTodo.idLeadTodo })];

  if (leadTodo.organizationLeadTodo) {
    actions.push(
      organizationLeadTodoActions.deleteManyLeadTodoSuccess({
        idLeadTodos: [leadTodo.idLeadTodo],
        idOrganizationLeadTodos: [leadTodo.organizationLeadTodo as number]
      })
    );
  }

  if (leadTodo.lead) {
    actions.push(
      leadActions.deleteManyLeadTodoSuccess({
        idLeadTodos: [leadTodo.idLeadTodo],
        idLeads: [leadTodo.lead as number]
      })
    );
  }

  return actions;
}

export class GeneratedLeadTodoEffects {
  constructor(
    protected actions$: Actions,
    protected leadTodoApiService: LeadTodoApiService,
    protected store$: Store<AppState>
  ) {}

  getManyLeadTodos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadTodoActions.getManyLeadTodos),
      switchMap(({ params }) =>
        this.leadTodoApiService.getLeadTodos(params).pipe(
          map((leadTodos: LeadTodo[]) => {
            return leadTodoActions.normalizeManyLeadTodosAfterUpsert({ leadTodos });
          }),
          catchError(error => of(leadTodoActions.leadTodosFailure({ error })))
        )
      )
    );
  });

  getOneLeadTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadTodoActions.getOneLeadTodo),
      switchMap(idLeadTodo =>
        this.leadTodoApiService.getLeadTodo(idLeadTodo).pipe(
          map((leadTodo: LeadTodo) => {
            return leadTodoActions.normalizeManyLeadTodosAfterUpsert({ leadTodos: [leadTodo] });
          }),
          catchError(error => of(leadTodoActions.leadTodosFailure({ error })))
        )
      )
    );
  });

  upsertOneLeadTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadTodoActions.upsertOneLeadTodo),
      concatMap(({ leadTodo, ids }: { leadTodo: Partial<LeadTodo>; ids?: LeadTodoRelationsIds }) => {
        if (leadTodo.idLeadTodo) {
          return this.leadTodoApiService.updateLeadTodo(leadTodo).pipe(
            map((leadTodoReturned: LeadTodo) => {
              return leadTodoActions.normalizeManyLeadTodosAfterUpsert({ leadTodos: [leadTodoReturned] });
            }),
            catchError(error => of(leadTodoActions.leadTodosFailure({ error })))
          );
        } else {
          return this.leadTodoApiService.addLeadTodo(leadTodo).pipe(
            mergeMap((leadTodoReturned: LeadTodo) => getDefaultAddLeadTodoActions(leadTodoReturned, ids)),
            catchError(error => of(leadTodoActions.leadTodosFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneLeadTodo$ = createEffect(() => {
    const selectLeadTodoState$ = this.store$.select(selectLeadTodoState);
    return this.actions$.pipe(
      ofType(leadTodoActions.deleteOneLeadTodo),
      withLatestFrom(selectLeadTodoState$),
      concatMap(([{ idLeadTodo }, state]) =>
        this.leadTodoApiService.deleteLeadTodo(idLeadTodo).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteLeadTodoActions(state.entities[idLeadTodo] as LeadTodoEntityState),
              leadTodoActions.deleteOneLeadTodo.type
            )
          ]),
          catchError(error => of(leadTodoActions.leadTodosFailure({ error })))
        )
      )
    );
  });

  normalizeManyLeadTodosAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadTodoActions.normalizeManyLeadTodosAfterUpsert),
      concatMap(({ leadTodos }) => {
        const actions: Action[] = getActionsToNormalizeLeadTodo(leadTodos, StoreActionType.upsert);
        return [getMultiAction(actions, '[LeadTodo] Normalization After Upsert Success')];
      })
    );
  });
}
