import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval, concat } from 'rxjs';
import { first } from 'rxjs/operators';
import { NotificationService } from './notification.service';

@Injectable()
export class CheckForUpdateService {
  constructor(appRef: ApplicationRef, updates: SwUpdate, notifications: NotificationService) {
    if (updates?.isEnabled) {
      // Vérification que l'app est stable
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
      // Emission d'event toutes les 3 mins
      const everyQuarter$ = interval(3 * 60 * 1000);
      const everyQuarterOnceAppIsStable$ = concat(appIsStable$, everyQuarter$);

      // Toutes les 3 mins quand l'app est stable on vérifie si une nouvelle version de l'app est dispo
      everyQuarterOnceAppIsStable$.subscribe(() => updates.checkForUpdate());

      // Traitement en cas de problème du service worker
      updates.unrecoverable.subscribe(event => {
        notifications.showError(
          `An error occurred that we cannot recover from:\n${event.reason}\n\n` + 'Please reload the page.'
        );
      });
    }
  }
}
