import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { StratalotPrice, StratalotPriceEntityState } from '@_model/interfaces/stratalot-price.model';
import { StratalotPriceApiService } from '@_services/api/stratalot-price-api.service';
import * as stratalotPriceActions from '@_store/stratalot-price/stratalot-price.actions';
import { getActionsToNormalizeStratalotPrice } from '@_config/store/normalization.generated';
import { selectStratalotPriceState } from './stratalot-price-generated.selectors';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import * as residencePriceGridActions from '@_store/residence-price-grid/residence-price-grid.actions';
import * as companyPriceLabelActions from '@_store/company-price-label/company-price-label.actions';
import { CompanyPriceLabel } from '@_model/interfaces/company-price-label.model';
import * as stratalotPriceValueActions from '@_store/stratalot-price-value/stratalot-price-value.actions';
import { StratalotPriceValue } from '@_model/interfaces/stratalot-price-value.model';

export interface StratalotPriceRelationsIds {
  companyPriceLabels?: number | number[];
  stratalotPriceValues?: number | number[];
  stratalot?: number;
  residencePriceGrid?: number;
}

export function getDefaultAddStratalotPriceActions(
  stratalotPrice: StratalotPriceEntityState,
  ids?: StratalotPriceRelationsIds
): Action[] {
  const actions: Action[] = [
    stratalotPriceActions.normalizeManyStratalotPricesAfterUpsert({ stratalotPrices: [stratalotPrice] })
  ];

  if (ids?.stratalot) {
    actions.push(
      stratalotActions.addManyStratalotPriceSuccess({
        idStratalot: ids.stratalot,
        idStratalotPrices: [stratalotPrice.idStratalotPrice]
      })
    );
    actions.push(
      stratalotPriceActions.addStratalotSuccess({
        idStratalotPrice: stratalotPrice.idStratalotPrice,
        idStratalot: ids.stratalot
      })
    );
  }

  if (ids?.residencePriceGrid) {
    actions.push(
      residencePriceGridActions.addManyStratalotPriceSuccess({
        idResidencePriceGrid: ids.residencePriceGrid,
        idStratalotPrices: [stratalotPrice.idStratalotPrice]
      })
    );
    actions.push(
      stratalotPriceActions.addResidencePriceGridSuccess({
        idStratalotPrice: stratalotPrice.idStratalotPrice,
        idResidencePriceGrid: ids.residencePriceGrid
      })
    );
  }

  if (ids?.companyPriceLabels) {
    if (!Array.isArray(ids.companyPriceLabels)) {
      actions.push(
        companyPriceLabelActions.upsertOneCompanyPriceLabel({
          companyPriceLabel: {
            idStratalotPrice: stratalotPrice.idStratalotPrice,
            idCompanyPriceLabel: ids.companyPriceLabels as number
          } as CompanyPriceLabel & any
        })
      );
      actions.push(
        stratalotPriceActions.addManyCompanyPriceLabelSuccess({
          idStratalotPrice: stratalotPrice.idStratalotPrice,
          idCompanyPriceLabels: [ids.companyPriceLabels as number]
        })
      );
    } else {
      actions.push(
        companyPriceLabelActions.upsertManyCompanyPriceLabels({
          companyPriceLabels: (ids.companyPriceLabels as number[]).map((idCompanyPriceLabel: number) => ({
            idStratalotPrice: stratalotPrice.idStratalotPrice,
            idCompanyPriceLabel
          })) as CompanyPriceLabel[] & any[]
        })
      );
      actions.push(
        stratalotPriceActions.addManyCompanyPriceLabelSuccess({
          idStratalotPrice: stratalotPrice.idStratalotPrice,
          idCompanyPriceLabels: ids.companyPriceLabels as number[]
        })
      );
    }
  }

  if (ids?.stratalotPriceValues) {
    if (!Array.isArray(ids.stratalotPriceValues)) {
      actions.push(
        stratalotPriceValueActions.upsertOneStratalotPriceValue({
          stratalotPriceValue: {
            idStratalotPrice: stratalotPrice.idStratalotPrice,
            idStratalotPriceValue: ids.stratalotPriceValues as number
          } as StratalotPriceValue
        })
      );
      actions.push(
        stratalotPriceActions.addManyStratalotPriceValueSuccess({
          idStratalotPrice: stratalotPrice.idStratalotPrice,
          idStratalotPriceValues: [ids.stratalotPriceValues as number]
        })
      );
    } else {
      actions.push(
        stratalotPriceValueActions.upsertManyStratalotPriceValues({
          stratalotPriceValues: (ids.stratalotPriceValues as number[]).map((idStratalotPriceValue: number) => ({
            idStratalotPrice: stratalotPrice.idStratalotPrice,
            idStratalotPriceValue
          })) as StratalotPriceValue[]
        })
      );
      actions.push(
        stratalotPriceActions.addManyStratalotPriceValueSuccess({
          idStratalotPrice: stratalotPrice.idStratalotPrice,
          idStratalotPriceValues: ids.stratalotPriceValues as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteStratalotPriceActions(stratalotPrice: StratalotPriceEntityState): Action[] {
  const actions: Action[] = [
    stratalotPriceActions.deleteOneStratalotPriceSuccess({ idStratalotPrice: stratalotPrice.idStratalotPrice })
  ];

  if (stratalotPrice.stratalot) {
    actions.push(
      stratalotActions.deleteManyStratalotPriceSuccess({
        idStratalotPrices: [stratalotPrice.idStratalotPrice],
        idStratalots: [stratalotPrice.stratalot as number]
      })
    );
  }

  if (stratalotPrice.residencePriceGrid) {
    actions.push(
      residencePriceGridActions.deleteManyStratalotPriceSuccess({
        idStratalotPrices: [stratalotPrice.idStratalotPrice],
        idResidencePriceGrids: [stratalotPrice.residencePriceGrid as number]
      })
    );
  }

  if (stratalotPrice.companyPriceLabels) {
    actions.push(
      companyPriceLabelActions.deleteManyStratalotPriceSuccess({
        idStratalotPrices: [stratalotPrice.idStratalotPrice],
        idCompanyPriceLabels: stratalotPrice.companyPriceLabels as number[]
      })
    );
  }

  if (stratalotPrice.stratalotPriceValues) {
    actions.push(
      stratalotPriceValueActions.deleteManyStratalotPriceSuccess({
        idStratalotPrices: [stratalotPrice.idStratalotPrice],
        idStratalotPriceValues: stratalotPrice.stratalotPriceValues as number[]
      })
    );
  }

  return actions;
}

export class GeneratedStratalotPriceEffects {
  constructor(
    protected actions$: Actions,
    protected stratalotPriceApiService: StratalotPriceApiService,
    protected store$: Store<AppState>
  ) {}

  getManyStratalotPrices$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotPriceActions.getManyStratalotPrices),
      switchMap(({ params }) =>
        this.stratalotPriceApiService.getStratalotPrices(params).pipe(
          map((stratalotPrices: StratalotPrice[]) => {
            return stratalotPriceActions.normalizeManyStratalotPricesAfterUpsert({ stratalotPrices });
          }),
          catchError(error => of(stratalotPriceActions.stratalotPricesFailure({ error })))
        )
      )
    );
  });

  getOneStratalotPrice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotPriceActions.getOneStratalotPrice),
      switchMap(idStratalotPrice =>
        this.stratalotPriceApiService.getStratalotPrice(idStratalotPrice).pipe(
          map((stratalotPrice: StratalotPrice) => {
            return stratalotPriceActions.normalizeManyStratalotPricesAfterUpsert({ stratalotPrices: [stratalotPrice] });
          }),
          catchError(error => of(stratalotPriceActions.stratalotPricesFailure({ error })))
        )
      )
    );
  });

  upsertOneStratalotPrice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotPriceActions.upsertOneStratalotPrice),
      concatMap(
        ({ stratalotPrice, ids }: { stratalotPrice: Partial<StratalotPrice>; ids?: StratalotPriceRelationsIds }) => {
          if (stratalotPrice.idStratalotPrice) {
            return this.stratalotPriceApiService.updateStratalotPrice(stratalotPrice).pipe(
              map((stratalotPriceReturned: StratalotPrice) => {
                return stratalotPriceActions.normalizeManyStratalotPricesAfterUpsert({
                  stratalotPrices: [stratalotPriceReturned]
                });
              }),
              catchError(error => of(stratalotPriceActions.stratalotPricesFailure({ error })))
            );
          } else {
            return this.stratalotPriceApiService.addStratalotPrice(stratalotPrice).pipe(
              mergeMap((stratalotPriceReturned: StratalotPrice) =>
                getDefaultAddStratalotPriceActions(stratalotPriceReturned, ids)
              ),
              catchError(error => of(stratalotPriceActions.stratalotPricesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneStratalotPrice$ = createEffect(() => {
    const selectStratalotPriceState$ = this.store$.select(selectStratalotPriceState);
    return this.actions$.pipe(
      ofType(stratalotPriceActions.deleteOneStratalotPrice),
      withLatestFrom(selectStratalotPriceState$),
      concatMap(([{ idStratalotPrice }, state]) =>
        this.stratalotPriceApiService.deleteStratalotPrice(idStratalotPrice).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteStratalotPriceActions(state.entities[idStratalotPrice] as StratalotPriceEntityState),
              stratalotPriceActions.deleteOneStratalotPrice.type
            )
          ]),
          catchError(error => of(stratalotPriceActions.stratalotPricesFailure({ error })))
        )
      )
    );
  });

  normalizeManyStratalotPricesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotPriceActions.normalizeManyStratalotPricesAfterUpsert),
      concatMap(({ stratalotPrices }) => {
        const actions: Action[] = getActionsToNormalizeStratalotPrice(stratalotPrices, StoreActionType.upsert);
        return [getMultiAction(actions, '[StratalotPrice] Normalization After Upsert Success')];
      })
    );
  });
}
