import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationStratalotTodoProfil,
  OrganizationStratalotTodoProfilEntityState
} from '@_model/interfaces/organization-stratalot-todo-profil.model';
import { OrganizationStratalotTodoProfilRelationsIds } from './organization-stratalot-todo-profil-generated.effects';

export const getOneOrganizationStratalotTodoProfil = createAction(
  '[OrganizationStratalotTodoProfil] Get One OrganizationStratalotTodoProfil',
  props<{ idOrganizationStratalotTodoProfil: number; params?: any }>()
);

export const getManyOrganizationStratalotTodoProfils = createAction(
  '[OrganizationStratalotTodoProfil] Get Many OrganizationStratalotTodoProfils',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationStratalotTodoProfil] Add Many Actives OrganizationStratalotTodoProfil',
  props<{ idOrganizationStratalotTodoProfils: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationStratalotTodoProfil] Delete One Active OrganizationStratalotTodoProfil',
  props<{ idOrganizationStratalotTodoProfil: number }>()
);

export const clearActive = createAction(
  '[OrganizationStratalotTodoProfil] Clear Active OrganizationStratalotTodoProfil'
);

export const upsertOneOrganizationStratalotTodoProfil = createAction(
  '[OrganizationStratalotTodoProfil] Upsert One OrganizationStratalotTodoProfil',
  props<{
    organizationStratalotTodoProfil: Partial<OrganizationStratalotTodoProfil>;
    ids?: OrganizationStratalotTodoProfilRelationsIds;
  }>()
);

export const upsertManyOrganizationStratalotTodoProfils = createAction(
  '[OrganizationStratalotTodoProfil] Upsert Many OrganizationStratalotTodoProfils',
  props<{
    organizationStratalotTodoProfils: Partial<OrganizationStratalotTodoProfil>[];
    ids?: OrganizationStratalotTodoProfilRelationsIds;
  }>()
);

export const upsertManyOrganizationStratalotTodoProfilsSuccess = createAction(
  '[OrganizationStratalotTodoProfil] Create Many OrganizationStratalotTodoProfils Success',
  props<{ organizationStratalotTodoProfils: OrganizationStratalotTodoProfilEntityState[] }>()
);

export const deleteOneOrganizationStratalotTodoProfil = createAction(
  '[OrganizationStratalotTodoProfil] Delete One OrganizationStratalotTodoProfil',
  props<{ idOrganizationStratalotTodoProfil: number }>()
);

export const deleteOneOrganizationStratalotTodoProfilSuccess = createAction(
  '[OrganizationStratalotTodoProfil] Delete One OrganizationStratalotTodoProfil Success',
  props<{ idOrganizationStratalotTodoProfil: number }>()
);

export const normalizeManyOrganizationStratalotTodoProfilsAfterUpsert = createAction(
  '[OrganizationStratalotTodoProfil] Normalize Many OrganizationStratalotTodoProfils After Upsert',
  props<{ organizationStratalotTodoProfils: OrganizationStratalotTodoProfilEntityState[] }>()
);

export const organizationStratalotTodoProfilsFailure = createAction(
  '[OrganizationStratalotTodoProfil] OrganizationStratalotTodoProfils Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationStratalotTodoProfil] Clear OrganizationStratalotTodoProfils');

export const addProfilSuccess = createAction(
  '[OrganizationStratalotTodoProfil] Add Profil',
  props<{ idOrganizationStratalotTodoProfil: number; idProfil: number }>()
);

export const deleteManyProfilSuccess = createAction(
  '[OrganizationStratalotTodoProfil] Delete Many Profil',
  props<{ idProfils: number[]; idOrganizationStratalotTodoProfils: number[] }>()
);

export const addOrganizationStratalotTodoSuccess = createAction(
  '[OrganizationStratalotTodoProfil] Add OrganizationStratalotTodo',
  props<{ idOrganizationStratalotTodoProfil: number; idOrganizationStratalotTodo: number }>()
);

export const deleteManyOrganizationStratalotTodoSuccess = createAction(
  '[OrganizationStratalotTodoProfil] Delete Many OrganizationStratalotTodo',
  props<{ idOrganizationStratalotTodos: number[]; idOrganizationStratalotTodoProfils: number[] }>()
);
