import { RepositoryService } from '@_services/api/repository.service';
import { Lead } from '@_model/interfaces/lead.model';
import { Observable } from 'rxjs';

export class GeneratedLeadApiService {
  constructor(protected repo: RepositoryService) {}

  public getLeads(params?: any): Observable<Lead[]> {
    return this.repo.getData<Lead[]>('lead', params);
  }

  public getLead(params: { idLead: number; params?: any }): Observable<Lead> {
    return this.repo.getData<Lead>('lead/' + params.idLead, params.params);
  }

  public addLead(lead: Partial<Lead>): Observable<Lead> {
    return this.repo.create<Lead>('lead', lead);
  }

  public updateLead(lead: Partial<Lead>): Observable<Lead> {
    return this.repo.update('lead', lead);
  }

  public deleteLead(idLead: number): Observable<number> {
    return this.repo.delete('lead/' + +idLead);
  }
}
