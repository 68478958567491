import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyCommunicationRecipientUserActions from './company-communication-recipient-user.actions';
import {
  adapter,
  initialState,
  CompanyCommunicationRecipientUserState
} from './company-communication-recipient-user.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyCommunicationRecipientUserEntityState } from '@_model/interfaces/company-communication-recipient-user.model';

export const companyCommunicationRecipientUserReducersGeneratedFunctions: ReducerTypes<
  CompanyCommunicationRecipientUserState,
  readonly ActionCreator[]
>[] = [
  on(
    CompanyCommunicationRecipientUserActions.getOneCompanyCommunicationRecipientUser,
    (state: CompanyCommunicationRecipientUserState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationRecipientUserActions.getManyCompanyCommunicationRecipientUsers,
    (state: CompanyCommunicationRecipientUserState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationRecipientUserActions.upsertOneCompanyCommunicationRecipientUser,
    (state: CompanyCommunicationRecipientUserState) => setLoadingsState(state, true)
  ),

  on(
    CompanyCommunicationRecipientUserActions.upsertManyCompanyCommunicationRecipientUsersSuccess,
    (state: CompanyCommunicationRecipientUserState, { companyCommunicationRecipientUsers }) =>
      adapter.upsertMany(companyCommunicationRecipientUsers, setLoadingsState(state, false))
  ),
  on(
    CompanyCommunicationRecipientUserActions.deleteOneCompanyCommunicationRecipientUser,
    (state: CompanyCommunicationRecipientUserState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationRecipientUserActions.deleteOneCompanyCommunicationRecipientUserSuccess,
    (state: CompanyCommunicationRecipientUserState, { idCompanyCommunicationRecipientUser }) =>
      adapter.removeOne(idCompanyCommunicationRecipientUser, setLoadingsState(state, false))
  ),
  on(CompanyCommunicationRecipientUserActions.clearActive, (state: CompanyCommunicationRecipientUserState) => ({
    ...state,
    actives: []
  })),
  on(
    CompanyCommunicationRecipientUserActions.addManyActives,
    (state: CompanyCommunicationRecipientUserState, { idCompanyCommunicationRecipientUsers }) => ({
      ...state,
      actives: state.actives.concat(idCompanyCommunicationRecipientUsers)
    })
  ),
  on(
    CompanyCommunicationRecipientUserActions.deleteOneActive,
    (state: CompanyCommunicationRecipientUserState, { idCompanyCommunicationRecipientUser }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCompanyCommunicationRecipientUser)
    })
  ),

  on(CompanyCommunicationRecipientUserActions.clearStore, () => initialState),

  on(
    CompanyCommunicationRecipientUserActions.addCompanyCommunicationRecipientSuccess,
    (
      state: CompanyCommunicationRecipientUserState,
      { idCompanyCommunicationRecipientUser, idCompanyCommunicationRecipient }
    ) => {
      if (!state.entities[idCompanyCommunicationRecipientUser]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationRecipientUser]: {
            ...state.entities[idCompanyCommunicationRecipientUser],
            companyCommunicationRecipient: idCompanyCommunicationRecipient
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationRecipientUserActions.deleteManyCompanyCommunicationRecipientSuccess,
    (
      state: CompanyCommunicationRecipientUserState,
      { idCompanyCommunicationRecipients, idCompanyCommunicationRecipientUsers }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationRecipientUsers.reduce((entities, idCompanyCommunicationRecipientUser) => {
            if (!state.entities[idCompanyCommunicationRecipientUser]?.companyCommunicationRecipient) {
              return entities;
            }
            entities[idCompanyCommunicationRecipientUser] = {
              ...state.entities[idCompanyCommunicationRecipientUser],
              companyCommunicationRecipient: idCompanyCommunicationRecipients.some(
                (idCompanyCommunicationRecipient: number) =>
                  idCompanyCommunicationRecipient ===
                  state.entities[idCompanyCommunicationRecipientUser]?.companyCommunicationRecipient
              )
                ? undefined
                : state.entities[idCompanyCommunicationRecipientUser]?.companyCommunicationRecipient
            } as CompanyCommunicationRecipientUserEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationRecipientUserEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationRecipientUserActions.addUserSuccess,
    (state: CompanyCommunicationRecipientUserState, { idCompanyCommunicationRecipientUser, idUser }) => {
      if (!state.entities[idCompanyCommunicationRecipientUser]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationRecipientUser]: {
            ...state.entities[idCompanyCommunicationRecipientUser],
            user: idUser
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationRecipientUserActions.deleteManyUserSuccess,
    (state: CompanyCommunicationRecipientUserState, { idUsers, idCompanyCommunicationRecipientUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationRecipientUsers.reduce((entities, idCompanyCommunicationRecipientUser) => {
            if (!state.entities[idCompanyCommunicationRecipientUser]?.user) {
              return entities;
            }
            entities[idCompanyCommunicationRecipientUser] = {
              ...state.entities[idCompanyCommunicationRecipientUser],
              user: idUsers.some(
                (idUser: number) => idUser === state.entities[idCompanyCommunicationRecipientUser]?.user
              )
                ? undefined
                : state.entities[idCompanyCommunicationRecipientUser]?.user
            } as CompanyCommunicationRecipientUserEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationRecipientUserEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyCommunicationRecipientUserState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyCommunicationRecipientUserState {
  return { ...state, isLoaded, isLoading };
}
