export enum DetailLotPathEnum {
  lot = 'lot',
  lotDetail = 'lot-detail',
  process = 'process',
  documents = 'documents',
  diffusion = 'diffusion',
  etatVente = 'etat-vente',
  leadsHistory = 'leads-history',
  contacts = 'contacts',
  offres = 'offres'
}
