import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CityApiService } from '@_services/api/city-api.service';
import { City } from '@_shared/models/interfaces/city.model';
import { AppState } from '@_store/index.reducers';
import { switchMap, map, catchError, of } from 'rxjs';
import { GeneratedCityEffects } from './city-generated.effects';
import * as cityActions from '@_store/city/city.actions';

@Injectable()
export class CityEffects extends GeneratedCityEffects {
  constructor(actions$: Actions, cityApiService: CityApiService, store$: Store<AppState>) {
    super(actions$, cityApiService, store$);
  }

  getManyCitiesByCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cityActions.getManyCitiesByCompany),
      switchMap(({ idCompany, params }) =>
        this.cityApiService.getCitiesByCompany({ idCompany, params }).pipe(
          map((cities: City[]) => {
            return cityActions.normalizeManyCitiesAfterUpsert({ cities });
          }),
          catchError(error => of(cityActions.citiesFailure({ error })))
        )
      )
    );
  });
}
