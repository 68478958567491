import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyStratalotVacantApiService } from '@_services/api/company-stratalot-vacant-api.service';
import { GeneratedCompanyStratalotVacantEffects } from './company-stratalot-vacant-generated.effects';

@Injectable()
export class CompanyStratalotVacantEffects extends GeneratedCompanyStratalotVacantEffects {
  constructor(
    actions$: Actions,
    companyStratalotVacantApiService: CompanyStratalotVacantApiService,
    store$: Store<AppState>
  ) {
    super(actions$, companyStratalotVacantApiService, store$);
  }
}
