import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ProspectEvent, ProspectEventEntityState } from '@_model/interfaces/prospect-event.model';
import { ProspectEventRelationsIds } from './prospect-event-generated.effects';

export const getOneProspectEvent = createAction(
  '[ProspectEvent] Get One ProspectEvent',
  props<{ idProspectEvent: number; params?: any }>()
);

export const getManyProspectEvents = createAction('[ProspectEvent] Get Many ProspectEvents', props<{ params: any }>());

export const addManyActives = createAction(
  '[ProspectEvent] Add Many Actives ProspectEvent',
  props<{ idProspectEvents: number[] }>()
);

export const deleteOneActive = createAction(
  '[ProspectEvent] Delete One Active ProspectEvent',
  props<{ idProspectEvent: number }>()
);

export const clearActive = createAction('[ProspectEvent] Clear Active ProspectEvent');

export const upsertOneProspectEvent = createAction(
  '[ProspectEvent] Upsert One ProspectEvent',
  props<{
    prospectEvent: Partial<ProspectEvent>;
    ids?: ProspectEventRelationsIds;
  }>()
);

export const upsertManyProspectEvents = createAction(
  '[ProspectEvent] Upsert Many ProspectEvents',
  props<{
    prospectEvents: Partial<ProspectEvent>[];
    ids?: ProspectEventRelationsIds;
  }>()
);

export const upsertManyProspectEventsSuccess = createAction(
  '[ProspectEvent] Create Many ProspectEvents Success',
  props<{ prospectEvents: ProspectEventEntityState[] }>()
);

export const deleteOneProspectEvent = createAction(
  '[ProspectEvent] Delete One ProspectEvent',
  props<{ idProspectEvent: number }>()
);

export const deleteOneProspectEventSuccess = createAction(
  '[ProspectEvent] Delete One ProspectEvent Success',
  props<{ idProspectEvent: number }>()
);

export const normalizeManyProspectEventsAfterUpsert = createAction(
  '[ProspectEvent] Normalize Many ProspectEvents After Upsert',
  props<{ prospectEvents: ProspectEventEntityState[] }>()
);

export const prospectEventsFailure = createAction(
  '[ProspectEvent] ProspectEvents Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ProspectEvent] Clear ProspectEvents');

export const addProspectSuccess = createAction(
  '[ProspectEvent] Add Prospect',
  props<{ idProspectEvent: number; idProspect: number }>()
);

export const deleteManyProspectSuccess = createAction(
  '[ProspectEvent] Delete Many Prospect',
  props<{ idProspects: number[]; idProspectEvents: number[] }>()
);

export const addLeadSuccess = createAction(
  '[ProspectEvent] Add Lead',
  props<{ idProspectEvent: number; idLead: number }>()
);

export const deleteManyLeadSuccess = createAction(
  '[ProspectEvent] Delete Many Lead',
  props<{ idLeads: number[]; idProspectEvents: number[] }>()
);

export const addUserSuccess = createAction(
  '[ProspectEvent] Add User',
  props<{ idProspectEvent: number; idUser: number }>()
);

export const deleteManyUserSuccess = createAction(
  '[ProspectEvent] Delete Many User',
  props<{ idUsers: number[]; idProspectEvents: number[] }>()
);
