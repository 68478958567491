import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { StratalotTodo, StratalotTodoEntityState } from '@_model/interfaces/stratalot-todo.model';
import { StratalotTodoRelationsIds } from './stratalot-todo-generated.effects';

export const getOneStratalotTodo = createAction(
  '[StratalotTodo] Get One StratalotTodo',
  props<{ idStratalotTodo: number; params?: any }>()
);

export const getManyStratalotTodos = createAction('[StratalotTodo] Get Many StratalotTodos', props<{ params: any }>());

export const addManyActives = createAction(
  '[StratalotTodo] Add Many Actives StratalotTodo',
  props<{ idStratalotTodos: number[] }>()
);

export const deleteOneActive = createAction(
  '[StratalotTodo] Delete One Active StratalotTodo',
  props<{ idStratalotTodo: number }>()
);

export const clearActive = createAction('[StratalotTodo] Clear Active StratalotTodo');

export const upsertOneStratalotTodo = createAction(
  '[StratalotTodo] Upsert One StratalotTodo',
  props<{
    stratalotTodo: Partial<StratalotTodo>;
    ids?: StratalotTodoRelationsIds;
  }>()
);

export const upsertManyStratalotTodos = createAction(
  '[StratalotTodo] Upsert Many StratalotTodos',
  props<{
    stratalotTodos: Partial<StratalotTodo>[];
    ids?: StratalotTodoRelationsIds;
  }>()
);

export const upsertManyStratalotTodosSuccess = createAction(
  '[StratalotTodo] Create Many StratalotTodos Success',
  props<{ stratalotTodos: StratalotTodoEntityState[] }>()
);

export const deleteOneStratalotTodo = createAction(
  '[StratalotTodo] Delete One StratalotTodo',
  props<{ idStratalotTodo: number }>()
);

export const deleteOneStratalotTodoSuccess = createAction(
  '[StratalotTodo] Delete One StratalotTodo Success',
  props<{ idStratalotTodo: number }>()
);

export const normalizeManyStratalotTodosAfterUpsert = createAction(
  '[StratalotTodo] Normalize Many StratalotTodos After Upsert',
  props<{ stratalotTodos: StratalotTodoEntityState[] }>()
);

export const stratalotTodosFailure = createAction(
  '[StratalotTodo] StratalotTodos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[StratalotTodo] Clear StratalotTodos');

export const addOrganizationStratalotTodoSuccess = createAction(
  '[StratalotTodo] Add OrganizationStratalotTodo',
  props<{ idStratalotTodo: number; idOrganizationStratalotTodo: number }>()
);

export const deleteManyOrganizationStratalotTodoSuccess = createAction(
  '[StratalotTodo] Delete Many OrganizationStratalotTodo',
  props<{ idOrganizationStratalotTodos: number[]; idStratalotTodos: number[] }>()
);

export const addStratalotSuccess = createAction(
  '[StratalotTodo] Add Stratalot',
  props<{ idStratalotTodo: number; idStratalot: number }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[StratalotTodo] Delete Many Stratalot',
  props<{ idStratalots: number[]; idStratalotTodos: number[] }>()
);
