import { StratalotPriceValueState, initialState } from './stratalot-price-value.state';
import { Action, createReducer } from '@ngrx/store';
import { stratalotPriceValueReducersGeneratedFunctions } from './stratalot-price-value-generated.reducer';

const stratalotPriceValueReducersFunctions = [...stratalotPriceValueReducersGeneratedFunctions];

const stratalotPriceValueReducer = createReducer(initialState, ...stratalotPriceValueReducersFunctions);

export function reducer(state: StratalotPriceValueState | undefined, action: Action) {
  return stratalotPriceValueReducer(state, action);
}
