import { Injectable } from '@angular/core';
import { RepositoryService } from '@_services/api/repository.service';
import { ProspectEvent } from '@_shared/models/interfaces/prospect-event.model';
import { Observable } from 'rxjs';
import { GeneratedProspectEventApiService } from './prospect-event-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ProspectEventApiService extends GeneratedProspectEventApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
  public addProspectForPublipostageEventByLead(idLead: number, idGeneratedDocument: number): Observable<ProspectEvent> {
    return this.repo.create<ProspectEvent>('prospect-event/forPublipostageByLead', {
      idLead,
      idGeneratedDocument
    });
  }
}
