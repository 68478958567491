import { RepositoryService } from '@_services/api/repository.service';
import { Prospect } from '@_model/interfaces/prospect.model';
import { Observable } from 'rxjs';

export class GeneratedProspectApiService {
  constructor(protected repo: RepositoryService) {}

  public getProspects(params?: any): Observable<Prospect[]> {
    return this.repo.getData<Prospect[]>('prospect', params);
  }

  public getProspect(params: { idProspect: number; params?: any }): Observable<Prospect> {
    return this.repo.getData<Prospect>('prospect/' + params.idProspect, params.params);
  }

  public addProspect(prospect: Partial<Prospect>): Observable<Prospect> {
    return this.repo.create<Prospect>('prospect', prospect);
  }

  public updateProspect(prospect: Partial<Prospect>): Observable<Prospect> {
    return this.repo.update('prospect', prospect);
  }

  public deleteProspect(idProspect: number): Observable<number> {
    return this.repo.delete('prospect/' + +idProspect);
  }
}
