import { StratalotTodoState, initialState } from './stratalot-todo.state';
import { Action, createReducer } from '@ngrx/store';
import { stratalotTodoReducersGeneratedFunctions } from './stratalot-todo-generated.reducer';

const stratalotTodoReducersFunctions = [...stratalotTodoReducersGeneratedFunctions];

const stratalotTodoReducer = createReducer(initialState, ...stratalotTodoReducersFunctions);

export function reducer(state: StratalotTodoState | undefined, action: Action) {
  return stratalotTodoReducer(state, action);
}
