import { createAction, props } from '@ngrx/store';

export * from './prospect-event-generated.actions';

export const deleteManyProspectEventByProspectSuccess = createAction(
  '[ProspectEvent] Delete Many Prospect Event By Prospect',
  props<{ idProspect: number }>()
);

export const addOnePropectEventForPublipostage = createAction(
  '[ProspectEvent] Add One PropectEvent For Publipostage By Lead',
  props<{ idLead: number; idGeneratedDocument: number }>()
);
