import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ProspectActions from './prospect.actions';
import { adapter, initialState, ProspectState } from './prospect.state';
import { Dictionary } from '@ngrx/entity';
import { ProspectEntityState } from '@_model/interfaces/prospect.model';

export const prospectReducersGeneratedFunctions: ReducerTypes<ProspectState, readonly ActionCreator[]>[] = [
  on(ProspectActions.getOneProspect, (state: ProspectState) => setLoadingsState(state, true)),
  on(ProspectActions.getManyProspects, (state: ProspectState) => setLoadingsState(state, true)),
  on(ProspectActions.upsertOneProspect, (state: ProspectState) => setLoadingsState(state, true)),

  on(ProspectActions.upsertManyProspectsSuccess, (state: ProspectState, { prospects }) =>
    adapter.upsertMany(prospects, setLoadingsState(state, false))
  ),
  on(ProspectActions.deleteOneProspect, (state: ProspectState) => setLoadingsState(state, true)),
  on(ProspectActions.deleteOneProspectSuccess, (state: ProspectState, { idProspect }) =>
    adapter.removeOne(idProspect, setLoadingsState(state, false))
  ),
  on(ProspectActions.clearActive, (state: ProspectState) => ({ ...state, actives: [] })),
  on(ProspectActions.addManyActives, (state: ProspectState, { idProspects }) => ({
    ...state,
    actives: state.actives.concat(idProspects)
  })),
  on(ProspectActions.deleteOneActive, (state: ProspectState, { idProspect }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idProspect)
  })),

  on(ProspectActions.clearStore, () => initialState),

  on(ProspectActions.addManyProspectEventSuccess, (state: ProspectState, { idProspect, idProspectEvents }) => {
    if (!state.entities[idProspect]) {
      return state;
    }
    const prospectEvents = (state.entities[idProspect]?.prospectEvents as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProspect]: {
          ...state.entities[idProspect],
          prospectEvents: prospectEvents.concat(idProspectEvents.filter(id => prospectEvents.indexOf(id) < 0))
        }
      }
    };
  }),

  on(ProspectActions.deleteManyProspectEventSuccess, (state: ProspectState, { idProspectEvents, idProspects }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idProspects.reduce((entities, idProspect) => {
          if (!state.entities[idProspect]?.prospectEvents) {
            return entities;
          }
          entities[idProspect] = {
            ...state.entities[idProspect],
            prospectEvents: (state.entities[idProspect]?.prospectEvents as number[])?.filter(
              (idProspectEvent: number) => !idProspectEvents.some((id: number) => id === idProspectEvent)
            )
          } as ProspectEntityState;
          return entities;
        }, {} as Dictionary<ProspectEntityState>)
      }
    };
  }),

  on(
    ProspectActions.addManyProspectBuyingWishSuccess,
    (state: ProspectState, { idProspect, idProspectBuyingWishs }) => {
      if (!state.entities[idProspect]) {
        return state;
      }
      const prospectBuyingWishs = (state.entities[idProspect]?.prospectBuyingWishs as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProspect]: {
            ...state.entities[idProspect],
            prospectBuyingWishs: prospectBuyingWishs.concat(
              idProspectBuyingWishs.filter(id => prospectBuyingWishs.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProspectActions.deleteManyProspectBuyingWishSuccess,
    (state: ProspectState, { idProspectBuyingWishs, idProspects }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProspects.reduce((entities, idProspect) => {
            if (!state.entities[idProspect]?.prospectBuyingWishs) {
              return entities;
            }
            entities[idProspect] = {
              ...state.entities[idProspect],
              prospectBuyingWishs: (state.entities[idProspect]?.prospectBuyingWishs as number[])?.filter(
                (idProspectBuyingWish: number) =>
                  !idProspectBuyingWishs.some((id: number) => id === idProspectBuyingWish)
              )
            } as ProspectEntityState;
            return entities;
          }, {} as Dictionary<ProspectEntityState>)
        }
      };
    }
  ),

  on(ProspectActions.addManyLeadSuccess, (state: ProspectState, { idProspect, idLeads }) => {
    if (!state.entities[idProspect]) {
      return state;
    }
    const leads = (state.entities[idProspect]?.leads as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProspect]: {
          ...state.entities[idProspect],
          leads: leads.concat(idLeads.filter(id => leads.indexOf(id) < 0))
        }
      }
    };
  }),

  on(ProspectActions.deleteManyLeadSuccess, (state: ProspectState, { idLeads, idProspects }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idProspects.reduce((entities, idProspect) => {
          if (!state.entities[idProspect]?.leads) {
            return entities;
          }
          entities[idProspect] = {
            ...state.entities[idProspect],
            leads: (state.entities[idProspect]?.leads as number[])?.filter(
              (idLead: number) => !idLeads.some((id: number) => id === idLead)
            )
          } as ProspectEntityState;
          return entities;
        }, {} as Dictionary<ProspectEntityState>)
      }
    };
  }),

  on(ProspectActions.addOccupantSuccess, (state: ProspectState, { idProspect, idOccupant }) => {
    if (!state.entities[idProspect]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProspect]: {
          ...state.entities[idProspect],
          occupant: idOccupant
        }
      }
    };
  }),

  on(ProspectActions.deleteManyOccupantSuccess, (state: ProspectState, { idOccupants, idProspects }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idProspects.reduce((entities, idProspect) => {
          if (!state.entities[idProspect]?.occupant) {
            return entities;
          }
          entities[idProspect] = {
            ...state.entities[idProspect],
            occupant: idOccupants.some((idOccupant: number) => idOccupant === state.entities[idProspect]?.occupant)
              ? undefined
              : state.entities[idProspect]?.occupant
          } as ProspectEntityState;
          return entities;
        }, {} as Dictionary<ProspectEntityState>)
      }
    };
  }),

  on(ProspectActions.addOrganizationSuccess, (state: ProspectState, { idProspect, idOrganization }) => {
    if (!state.entities[idProspect]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProspect]: {
          ...state.entities[idProspect],
          organization: idOrganization
        }
      }
    };
  }),

  on(ProspectActions.deleteManyOrganizationSuccess, (state: ProspectState, { idOrganizations, idProspects }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idProspects.reduce((entities, idProspect) => {
          if (!state.entities[idProspect]?.organization) {
            return entities;
          }
          entities[idProspect] = {
            ...state.entities[idProspect],
            organization: idOrganizations.some(
              (idOrganization: number) => idOrganization === state.entities[idProspect]?.organization
            )
              ? undefined
              : state.entities[idProspect]?.organization
          } as ProspectEntityState;
          return entities;
        }, {} as Dictionary<ProspectEntityState>)
      }
    };
  }),

  on(
    ProspectActions.addOrganizationProspectOriginSuccess,
    (state: ProspectState, { idProspect, idOrganizationProspectOrigin }) => {
      if (!state.entities[idProspect]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProspect]: {
            ...state.entities[idProspect],
            organizationProspectOrigin: idOrganizationProspectOrigin
          }
        }
      };
    }
  ),

  on(
    ProspectActions.deleteManyOrganizationProspectOriginSuccess,
    (state: ProspectState, { idOrganizationProspectOrigins, idProspects }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProspects.reduce((entities, idProspect) => {
            if (!state.entities[idProspect]?.organizationProspectOrigin) {
              return entities;
            }
            entities[idProspect] = {
              ...state.entities[idProspect],
              organizationProspectOrigin: idOrganizationProspectOrigins.some(
                (idOrganizationProspectOrigin: number) =>
                  idOrganizationProspectOrigin === state.entities[idProspect]?.organizationProspectOrigin
              )
                ? undefined
                : state.entities[idProspect]?.organizationProspectOrigin
            } as ProspectEntityState;
            return entities;
          }, {} as Dictionary<ProspectEntityState>)
        }
      };
    }
  ),

  on(ProspectActions.addResponsableSuccess, (state: ProspectState, { idProspect, idResponsable }) => {
    if (!state.entities[idProspect]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProspect]: {
          ...state.entities[idProspect],
          responsable: idResponsable
        }
      }
    };
  }),

  on(ProspectActions.deleteManyResponsableSuccess, (state: ProspectState, { idResponsable, idProspects }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idProspects.reduce((entities, idProspect) => {
          if (!state.entities[idProspect]?.responsable) {
            return entities;
          }
          entities[idProspect] = {
            ...state.entities[idProspect],
            user: idResponsable.some((idUser: number) => idUser === state.entities[idProspect]?.responsable)
              ? undefined
              : state.entities[idProspect]?.responsable
          } as ProspectEntityState;
          return entities;
        }, {} as Dictionary<ProspectEntityState>)
      }
    };
  }),

  on(ProspectActions.addUpdatedUserSuccess, (state: ProspectState, { idProspect, idUpdatedUser }) => {
    if (!state.entities[idProspect]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProspect]: {
          ...state.entities[idProspect],
          updatedUser: idUpdatedUser
        }
      }
    };
  }),

  on(ProspectActions.deleteManyUpdatedUserSuccess, (state: ProspectState, { idUpdatedUser, idProspects }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idProspects.reduce((entities, idProspect) => {
          if (!state.entities[idProspect]?.updatedUser) {
            return entities;
          }
          entities[idProspect] = {
            ...state.entities[idProspect],
            user: idUpdatedUser.some((idUser: number) => idUser === state.entities[idProspect]?.updatedUser)
              ? undefined
              : state.entities[idProspect]?.updatedUser
          } as ProspectEntityState;
          return entities;
        }, {} as Dictionary<ProspectEntityState>)
      }
    };
  }),

  on(ProspectActions.addCreatedUserSuccess, (state: ProspectState, { idProspect, idCreatedUser }) => {
    if (!state.entities[idProspect]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProspect]: {
          ...state.entities[idProspect],
          createdUser: idCreatedUser
        }
      }
    };
  }),

  on(ProspectActions.deleteManyCreatedUserSuccess, (state: ProspectState, { idCreatedUser, idProspects }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idProspects.reduce((entities, idProspect) => {
          if (!state.entities[idProspect]?.createdUser) {
            return entities;
          }
          entities[idProspect] = {
            ...state.entities[idProspect],
            user: idCreatedUser.some((idUser: number) => idUser === state.entities[idProspect]?.createdUser)
              ? undefined
              : state.entities[idProspect]?.createdUser
          } as ProspectEntityState;
          return entities;
        }, {} as Dictionary<ProspectEntityState>)
      }
    };
  })
];

export function setLoadingsState(state: ProspectState, isLoading: boolean, isLoaded: boolean = true): ProspectState {
  return { ...state, isLoaded, isLoading };
}
