import { RepositoryService } from '@_services/api/repository.service';
import { CompanyCommunication } from '@_model/interfaces/company-communication.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyCommunicationApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyCommunications(params?: any): Observable<CompanyCommunication[]> {
    return this.repo.getData<CompanyCommunication[]>('company-communication', params);
  }

  public getCompanyCommunication(params: {
    idCompanyCommunication: number;
    params?: any;
  }): Observable<CompanyCommunication> {
    return this.repo.getData<CompanyCommunication>(
      'company-communication/' + params.idCompanyCommunication,
      params.params
    );
  }

  public addCompanyCommunication(
    companyCommunication: Partial<CompanyCommunication>
  ): Observable<CompanyCommunication> {
    return this.repo.create<CompanyCommunication>('company-communication', companyCommunication);
  }

  public updateCompanyCommunication(
    companyCommunication: Partial<CompanyCommunication>
  ): Observable<CompanyCommunication> {
    return this.repo.update('company-communication', companyCommunication);
  }

  public deleteCompanyCommunication(idCompanyCommunication: number): Observable<number> {
    return this.repo.delete('company-communication/' + +idCompanyCommunication);
  }
}
