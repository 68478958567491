import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { SaleCategoryFamily, SaleCategoryFamilyEntityState } from '@_model/interfaces/sale-category-family.model';
import { SaleCategoryFamilyApiService } from '@_services/api/sale-category-family-api.service';
import * as saleCategoryFamilyActions from '@_store/sale-category-family/sale-category-family.actions';
import { getActionsToNormalizeSaleCategoryFamily } from '@_config/store/normalization.generated';
import { selectSaleCategoryFamilyState } from './sale-category-family-generated.selectors';
import * as organizationSaleCategoryActions from '@_store/organization-sale-category/organization-sale-category.actions';
import { OrganizationSaleCategory } from '@_model/interfaces/organization-sale-category.model';
import * as boardStateActions from '@_store/board-state/board-state.actions';
import { BoardState } from '@_model/interfaces/board-state.model';
import * as companyPriceLabelActions from '@_store/company-price-label/company-price-label.actions';
import { CompanyPriceLabel } from '@_model/interfaces/company-price-label.model';
import * as organizationLeadTodoRuleActions from '@_store/organization-lead-todo-rule/organization-lead-todo-rule.actions';
import { OrganizationLeadTodoRule } from '@_model/interfaces/organization-lead-todo-rule.model';
import * as residenceSaleCategoryFamilyActions from '@_store/residence-sale-category-family/residence-sale-category-family.actions';
import { ResidenceSaleCategoryFamily } from '@_model/interfaces/residence-sale-category-family.model';
import * as residenceActions from '@_store/residence/residence.actions';
import { Residence } from '@_model/interfaces/residence.model';
import * as organizationStratalotTodoRuleActions from '@_store/organization-stratalot-todo-rule/organization-stratalot-todo-rule.actions';
import { OrganizationStratalotTodoRule } from '@_model/interfaces/organization-stratalot-todo-rule.model';
import * as organizationResidenceTodoRuleActions from '@_store/organization-residence-todo-rule/organization-residence-todo-rule.actions';
import { OrganizationResidenceTodoRule } from '@_model/interfaces/organization-residence-todo-rule.model';

export interface SaleCategoryFamilyRelationsIds {
  organizationSaleCategories?: number | number[];
  boardStates?: number | number[];
  companyPriceLabels?: number | number[];
  organizationLeadTodoRules?: number | number[];
  residenceSaleCategoryFamilies?: number | number[];
  residences?: number | number[];
  organizationStratalotTodoRules?: number | number[];
  organizationResidenceTodoRules?: number | number[];
}

export function getDefaultAddSaleCategoryFamilyActions(
  saleCategoryFamily: SaleCategoryFamilyEntityState,
  ids?: SaleCategoryFamilyRelationsIds
): Action[] {
  const actions: Action[] = [
    saleCategoryFamilyActions.normalizeManyFamilySaleCategoriesAfterUpsert({
      familySaleCategories: [saleCategoryFamily]
    })
  ];

  if (ids?.organizationSaleCategories) {
    if (!Array.isArray(ids.organizationSaleCategories)) {
      actions.push(
        organizationSaleCategoryActions.upsertOneOrganizationSaleCategory({
          organizationSaleCategory: {
            idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
            idOrganizationSaleCategory: ids.organizationSaleCategories as number
          } as OrganizationSaleCategory
        })
      );
      actions.push(
        saleCategoryFamilyActions.addManyOrganizationSaleCategorySuccess({
          idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
          idOrganizationSaleCategories: [ids.organizationSaleCategories as number]
        })
      );
    } else {
      actions.push(
        organizationSaleCategoryActions.upsertManyOrganizationSaleCategories({
          organizationSaleCategories: (ids.organizationSaleCategories as number[]).map(
            (idOrganizationSaleCategory: number) => ({
              idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
              idOrganizationSaleCategory
            })
          ) as OrganizationSaleCategory[]
        })
      );
      actions.push(
        saleCategoryFamilyActions.addManyOrganizationSaleCategorySuccess({
          idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
          idOrganizationSaleCategories: ids.organizationSaleCategories as number[]
        })
      );
    }
  }

  if (ids?.boardStates) {
    if (!Array.isArray(ids.boardStates)) {
      actions.push(
        boardStateActions.upsertOneBoardState({
          boardState: {
            idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
            idBoardState: ids.boardStates as number
          } as BoardState
        })
      );
      actions.push(
        saleCategoryFamilyActions.addManyBoardStateSuccess({
          idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
          idBoardStates: [ids.boardStates as number]
        })
      );
    } else {
      actions.push(
        boardStateActions.upsertManyBoardStates({
          boardStates: (ids.boardStates as number[]).map((idBoardState: number) => ({
            idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
            idBoardState
          })) as BoardState[]
        })
      );
      actions.push(
        saleCategoryFamilyActions.addManyBoardStateSuccess({
          idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
          idBoardStates: ids.boardStates as number[]
        })
      );
    }
  }

  if (ids?.companyPriceLabels) {
    if (!Array.isArray(ids.companyPriceLabels)) {
      actions.push(
        companyPriceLabelActions.upsertOneCompanyPriceLabel({
          companyPriceLabel: {
            idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
            idCompanyPriceLabel: ids.companyPriceLabels as number
          } as CompanyPriceLabel
        })
      );
      actions.push(
        saleCategoryFamilyActions.addManyCompanyPriceLabelSuccess({
          idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
          idCompanyPriceLabels: [ids.companyPriceLabels as number]
        })
      );
    } else {
      actions.push(
        companyPriceLabelActions.upsertManyCompanyPriceLabels({
          companyPriceLabels: (ids.companyPriceLabels as number[]).map((idCompanyPriceLabel: number) => ({
            idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
            idCompanyPriceLabel
          })) as CompanyPriceLabel[]
        })
      );
      actions.push(
        saleCategoryFamilyActions.addManyCompanyPriceLabelSuccess({
          idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
          idCompanyPriceLabels: ids.companyPriceLabels as number[]
        })
      );
    }
  }

  if (ids?.organizationLeadTodoRules) {
    if (!Array.isArray(ids.organizationLeadTodoRules)) {
      actions.push(
        organizationLeadTodoRuleActions.upsertOneOrganizationLeadTodoRule({
          organizationLeadTodoRule: {
            idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
            idOrganizationLeadTodoRule: ids.organizationLeadTodoRules as number
          } as OrganizationLeadTodoRule
        })
      );
      actions.push(
        saleCategoryFamilyActions.addManyOrganizationLeadTodoRuleSuccess({
          idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
          idOrganizationLeadTodoRules: [ids.organizationLeadTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationLeadTodoRuleActions.upsertManyOrganizationLeadTodoRules({
          organizationLeadTodoRules: (ids.organizationLeadTodoRules as number[]).map(
            (idOrganizationLeadTodoRule: number) => ({
              idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
              idOrganizationLeadTodoRule
            })
          ) as OrganizationLeadTodoRule[]
        })
      );
      actions.push(
        saleCategoryFamilyActions.addManyOrganizationLeadTodoRuleSuccess({
          idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
          idOrganizationLeadTodoRules: ids.organizationLeadTodoRules as number[]
        })
      );
    }
  }

  if (ids?.residenceSaleCategoryFamilies) {
    if (!Array.isArray(ids.residenceSaleCategoryFamilies)) {
      actions.push(
        residenceSaleCategoryFamilyActions.upsertOneResidenceSaleCategoryFamily({
          residenceSaleCategoryFamily: {
            idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
            idResidenceSaleCategoryFamily: ids.residenceSaleCategoryFamilies as number
          } as ResidenceSaleCategoryFamily
        })
      );
      actions.push(
        saleCategoryFamilyActions.addManyResidenceSaleCategoryFamilySuccess({
          idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
          idResidenceSaleCategoryFamilies: [ids.residenceSaleCategoryFamilies as number]
        })
      );
    } else {
      actions.push(
        residenceSaleCategoryFamilyActions.upsertManyResidenceSaleCategoryFamilies({
          residenceSaleCategoryFamilies: (ids.residenceSaleCategoryFamilies as number[]).map(
            (idResidenceSaleCategoryFamily: number) => ({
              idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
              idResidenceSaleCategoryFamily
            })
          ) as ResidenceSaleCategoryFamily[]
        })
      );
      actions.push(
        saleCategoryFamilyActions.addManyResidenceSaleCategoryFamilySuccess({
          idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
          idResidenceSaleCategoryFamilies: ids.residenceSaleCategoryFamilies as number[]
        })
      );
    }
  }

  if (ids?.residences) {
    if (!Array.isArray(ids.residences)) {
      actions.push(
        residenceActions.upsertOneResidence({
          residence: {
            idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
            idResidence: ids.residences as number
          } as Residence & any
        })
      );
      actions.push(
        saleCategoryFamilyActions.addManyResidenceSuccess({
          idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
          idResidences: [ids.residences as number]
        })
      );
    } else {
      actions.push(
        residenceActions.upsertManyResidences({
          residences: (ids.residences as number[]).map((idResidence: number) => ({
            idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
            idResidence
          })) as Residence[] & any[]
        })
      );
      actions.push(
        saleCategoryFamilyActions.addManyResidenceSuccess({
          idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
          idResidences: ids.residences as number[]
        })
      );
    }
  }

  if (ids?.organizationStratalotTodoRules) {
    if (!Array.isArray(ids.organizationStratalotTodoRules)) {
      actions.push(
        organizationStratalotTodoRuleActions.upsertOneOrganizationStratalotTodoRule({
          organizationStratalotTodoRule: {
            idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
            idOrganizationStratalotTodoRule: ids.organizationStratalotTodoRules as number
          } as OrganizationStratalotTodoRule
        })
      );
      actions.push(
        saleCategoryFamilyActions.addManyOrganizationStratalotTodoRuleSuccess({
          idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
          idOrganizationStratalotTodoRules: [ids.organizationStratalotTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotTodoRuleActions.upsertManyOrganizationStratalotTodoRules({
          organizationStratalotTodoRules: (ids.organizationStratalotTodoRules as number[]).map(
            (idOrganizationStratalotTodoRule: number) => ({
              idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
              idOrganizationStratalotTodoRule
            })
          ) as OrganizationStratalotTodoRule[]
        })
      );
      actions.push(
        saleCategoryFamilyActions.addManyOrganizationStratalotTodoRuleSuccess({
          idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
          idOrganizationStratalotTodoRules: ids.organizationStratalotTodoRules as number[]
        })
      );
    }
  }

  if (ids?.organizationResidenceTodoRules) {
    if (!Array.isArray(ids.organizationResidenceTodoRules)) {
      actions.push(
        organizationResidenceTodoRuleActions.upsertOneOrganizationResidenceTodoRule({
          organizationResidenceTodoRule: {
            idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
            idOrganizationResidenceTodoRule: ids.organizationResidenceTodoRules as number
          } as OrganizationResidenceTodoRule
        })
      );
      actions.push(
        saleCategoryFamilyActions.addManyOrganizationResidenceTodoRuleSuccess({
          idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
          idOrganizationResidenceTodoRules: [ids.organizationResidenceTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationResidenceTodoRuleActions.upsertManyOrganizationResidenceTodoRules({
          organizationResidenceTodoRules: (ids.organizationResidenceTodoRules as number[]).map(
            (idOrganizationResidenceTodoRule: number) => ({
              idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
              idOrganizationResidenceTodoRule
            })
          ) as OrganizationResidenceTodoRule[]
        })
      );
      actions.push(
        saleCategoryFamilyActions.addManyOrganizationResidenceTodoRuleSuccess({
          idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily,
          idOrganizationResidenceTodoRules: ids.organizationResidenceTodoRules as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteSaleCategoryFamilyActions(saleCategoryFamily: SaleCategoryFamilyEntityState): Action[] {
  const actions: Action[] = [
    saleCategoryFamilyActions.deleteOneSaleCategoryFamilySuccess({
      idSaleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily
    })
  ];

  if (saleCategoryFamily.organizationSaleCategories) {
    actions.push(
      organizationSaleCategoryActions.deleteManySaleCategoryFamilySuccess({
        idFamilySaleCategories: [saleCategoryFamily.idSaleCategoryFamily],
        idOrganizationSaleCategories: saleCategoryFamily.organizationSaleCategories as number[]
      })
    );
  }

  if (saleCategoryFamily.boardStates) {
    actions.push(
      boardStateActions.deleteManySaleCategoryFamilySuccess({
        idFamilySaleCategories: [saleCategoryFamily.idSaleCategoryFamily],
        idBoardStates: saleCategoryFamily.boardStates as number[]
      })
    );
  }

  if (saleCategoryFamily.companyPriceLabels) {
    actions.push(
      companyPriceLabelActions.deleteManySaleCategoryFamilySuccess({
        idFamilySaleCategories: [saleCategoryFamily.idSaleCategoryFamily],
        idCompanyPriceLabels: saleCategoryFamily.companyPriceLabels as number[]
      })
    );
  }

  if (saleCategoryFamily.organizationLeadTodoRules) {
    actions.push(
      organizationLeadTodoRuleActions.deleteManySaleCategoryFamilySuccess({
        idFamilySaleCategories: [saleCategoryFamily.idSaleCategoryFamily],
        idOrganizationLeadTodoRules: saleCategoryFamily.organizationLeadTodoRules as number[]
      })
    );
  }

  if (saleCategoryFamily.residenceSaleCategoryFamilies) {
    actions.push(
      residenceSaleCategoryFamilyActions.deleteManySaleCategoryFamilySuccess({
        idFamilySaleCategories: [saleCategoryFamily.idSaleCategoryFamily],
        idResidenceSaleCategoryFamilies: saleCategoryFamily.residenceSaleCategoryFamilies as number[]
      })
    );
  }

  if (saleCategoryFamily.residences) {
    actions.push(
      residenceActions.deleteManySaleCategoryFamilySuccess({
        idFamilySaleCategories: [saleCategoryFamily.idSaleCategoryFamily],
        idResidences: saleCategoryFamily.residences as number[]
      })
    );
  }

  if (saleCategoryFamily.organizationStratalotTodoRules) {
    actions.push(
      organizationStratalotTodoRuleActions.deleteManySaleCategoryFamilySuccess({
        idFamilySaleCategories: [saleCategoryFamily.idSaleCategoryFamily],
        idOrganizationStratalotTodoRules: saleCategoryFamily.organizationStratalotTodoRules as number[]
      })
    );
  }

  if (saleCategoryFamily.organizationResidenceTodoRules) {
    actions.push(
      organizationResidenceTodoRuleActions.deleteManySaleCategoryFamilySuccess({
        idFamilySaleCategories: [saleCategoryFamily.idSaleCategoryFamily],
        idOrganizationResidenceTodoRules: saleCategoryFamily.organizationResidenceTodoRules as number[]
      })
    );
  }

  return actions;
}

export class GeneratedSaleCategoryFamilyEffects {
  constructor(
    protected actions$: Actions,
    protected saleCategoryFamilyApiService: SaleCategoryFamilyApiService,
    protected store$: Store<AppState>
  ) {}

  getManyFamilySaleCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saleCategoryFamilyActions.getManyFamilySaleCategories),
      switchMap(({ params }) =>
        this.saleCategoryFamilyApiService.getFamilySaleCategories(params).pipe(
          map((familySaleCategories: SaleCategoryFamily[]) => {
            return saleCategoryFamilyActions.normalizeManyFamilySaleCategoriesAfterUpsert({ familySaleCategories });
          }),
          catchError(error => of(saleCategoryFamilyActions.familySaleCategoriesFailure({ error })))
        )
      )
    );
  });

  getOneSaleCategoryFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saleCategoryFamilyActions.getOneSaleCategoryFamily),
      switchMap(idSaleCategoryFamily =>
        this.saleCategoryFamilyApiService.getSaleCategoryFamily(idSaleCategoryFamily).pipe(
          map((saleCategoryFamily: SaleCategoryFamily) => {
            return saleCategoryFamilyActions.normalizeManyFamilySaleCategoriesAfterUpsert({
              familySaleCategories: [saleCategoryFamily]
            });
          }),
          catchError(error => of(saleCategoryFamilyActions.familySaleCategoriesFailure({ error })))
        )
      )
    );
  });

  upsertOneSaleCategoryFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saleCategoryFamilyActions.upsertOneSaleCategoryFamily),
      concatMap(
        ({
          saleCategoryFamily,
          ids
        }: {
          saleCategoryFamily: Partial<SaleCategoryFamily>;
          ids?: SaleCategoryFamilyRelationsIds;
        }) => {
          if (saleCategoryFamily.idSaleCategoryFamily) {
            return this.saleCategoryFamilyApiService.updateSaleCategoryFamily(saleCategoryFamily).pipe(
              map((saleCategoryFamilyReturned: SaleCategoryFamily) => {
                return saleCategoryFamilyActions.normalizeManyFamilySaleCategoriesAfterUpsert({
                  familySaleCategories: [saleCategoryFamilyReturned]
                });
              }),
              catchError(error => of(saleCategoryFamilyActions.familySaleCategoriesFailure({ error })))
            );
          } else {
            return this.saleCategoryFamilyApiService.addSaleCategoryFamily(saleCategoryFamily).pipe(
              mergeMap((saleCategoryFamilyReturned: SaleCategoryFamily) =>
                getDefaultAddSaleCategoryFamilyActions(saleCategoryFamilyReturned, ids)
              ),
              catchError(error => of(saleCategoryFamilyActions.familySaleCategoriesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneSaleCategoryFamily$ = createEffect(() => {
    const selectSaleCategoryFamilyState$ = this.store$.select(selectSaleCategoryFamilyState);
    return this.actions$.pipe(
      ofType(saleCategoryFamilyActions.deleteOneSaleCategoryFamily),
      withLatestFrom(selectSaleCategoryFamilyState$),
      concatMap(([{ idSaleCategoryFamily }, state]) =>
        this.saleCategoryFamilyApiService.deleteSaleCategoryFamily(idSaleCategoryFamily).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteSaleCategoryFamilyActions(
                state.entities[idSaleCategoryFamily] as SaleCategoryFamilyEntityState
              ),
              saleCategoryFamilyActions.deleteOneSaleCategoryFamily.type
            )
          ]),
          catchError(error => of(saleCategoryFamilyActions.familySaleCategoriesFailure({ error })))
        )
      )
    );
  });

  normalizeManyFamilySaleCategoriesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saleCategoryFamilyActions.normalizeManyFamilySaleCategoriesAfterUpsert),
      concatMap(({ familySaleCategories }) => {
        const actions: Action[] = getActionsToNormalizeSaleCategoryFamily(familySaleCategories, StoreActionType.upsert);
        return [getMultiAction(actions, '[SaleCategoryFamily] Normalization After Upsert Success')];
      })
    );
  });
}
