export enum DashboardPrevisonalAgGridRowType {
  vendu = 'vendu',
  acteSigne = 'acteSigne',
  sousComprisDansLaPeriode = 'sousComprisDansLaPeriode',
  sousLeveeOptionDansLaPeriode = 'sousLeveeOptionDansLaPeriode',
  sousTotalvenduDansPeriode = 'sousTotalvenduDansPeriode',
  sousComprisHorsPeriode = 'sousComprisHorsPeriode',
  sousLeveeOptionHorsPeriode = 'sousLeveeOptionHorsPeriode',
  sousContratLocationAccession = 'sousContratLocationAccession',
  reserveDansLaPeriode = 'reserveDansLaPeriode',
  reserveHorsPeriode = 'reserveHorsPeriode',
  offreAcceptee = 'offreAcceptee',
  nonDisponible = 'nonDisponible',
  conges = 'conges',
  sousTotalAConcrestiserDansPeriode = 'sousTotalAConcrestiserDansPeriode',
  groupeExamen = 'groupeExamen',
  libre = 'libre',
  sousTotalvenduEnVente = 'sousTotalvenduEnVente',
  nonLeveeOption = 'nonLeveeOption',
  bloque = 'bloque',
  grandTotal = 'grandTotal'
}
