import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { OrganizationLeadTodo, OrganizationLeadTodoEntityState } from '@_model/interfaces/organization-lead-todo.model';
import { OrganizationLeadTodoApiService } from '@_services/api/organization-lead-todo-api.service';
import * as organizationLeadTodoActions from '@_store/organization-lead-todo/organization-lead-todo.actions';
import { getActionsToNormalizeOrganizationLeadTodo } from '@_config/store/normalization.generated';
import { selectOrganizationLeadTodoState } from './organization-lead-todo-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as stepActions from '@_store/step/step.actions';
import * as leadTodoActions from '@_store/lead-todo/lead-todo.actions';
import { LeadTodo } from '@_model/interfaces/lead-todo.model';
import * as organizationLeadTodoRuleActions from '@_store/organization-lead-todo-rule/organization-lead-todo-rule.actions';
import { OrganizationLeadTodoRule } from '@_model/interfaces/organization-lead-todo-rule.model';
import * as organizationLeadTodoAppliedRuleActions from '@_store/organization-lead-todo-applied-rule/organization-lead-todo-applied-rule.actions';
import { OrganizationLeadTodoAppliedRule } from '@_model/interfaces/organization-lead-todo-applied-rule.model';
import * as organizationLeadTodoProfilActions from '@_store/organization-lead-todo-profil/organization-lead-todo-profil.actions';
import { OrganizationLeadTodoProfil } from '@_model/interfaces/organization-lead-todo-profil.model';
import * as companyCommunicationActions from '@_store/company-communication/company-communication.actions';
import { CompanyCommunication } from '@_model/interfaces/company-communication.model';
import * as companyCommunicationLeadTodoActions from '@_store/company-communication-lead-todo/company-communication-lead-todo.actions';
import { CompanyCommunicationLeadTodo } from '@_model/interfaces/company-communication-lead-todo.model';

export interface OrganizationLeadTodoRelationsIds {
  leadTodos?: number | number[];
  organizationLeadTodoRules?: number | number[];
  organizationLeadTodoAppliedRules?: number | number[];
  organizationLeadTodoProfils?: number | number[];
  companyCommunications?: number | number[];
  companyCommunicationLeadTodos?: number | number[];
  organization?: number;
  step?: number;
}

export function getDefaultAddOrganizationLeadTodoActions(
  organizationLeadTodo: OrganizationLeadTodoEntityState,
  ids?: OrganizationLeadTodoRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationLeadTodoActions.normalizeManyOrganizationLeadTodosAfterUpsert({
      organizationLeadTodos: [organizationLeadTodo]
    })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationLeadTodoSuccess({
        idOrganization: ids.organization,
        idOrganizationLeadTodos: [organizationLeadTodo.idOrganizationLeadTodo]
      })
    );
    actions.push(
      organizationLeadTodoActions.addOrganizationSuccess({
        idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.step) {
    actions.push(
      stepActions.addManyOrganizationLeadTodoSuccess({
        idStep: ids.step,
        idOrganizationLeadTodos: [organizationLeadTodo.idOrganizationLeadTodo]
      })
    );
    actions.push(
      organizationLeadTodoActions.addStepSuccess({
        idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
        idStep: ids.step
      })
    );
  }

  if (ids?.leadTodos) {
    if (!Array.isArray(ids.leadTodos)) {
      actions.push(
        leadTodoActions.upsertOneLeadTodo({
          leadTodo: {
            idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
            idLeadTodo: ids.leadTodos as number
          } as LeadTodo
        })
      );
      actions.push(
        organizationLeadTodoActions.addManyLeadTodoSuccess({
          idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
          idLeadTodos: [ids.leadTodos as number]
        })
      );
    } else {
      actions.push(
        leadTodoActions.upsertManyLeadTodos({
          leadTodos: (ids.leadTodos as number[]).map((idLeadTodo: number) => ({
            idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
            idLeadTodo
          })) as LeadTodo[]
        })
      );
      actions.push(
        organizationLeadTodoActions.addManyLeadTodoSuccess({
          idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
          idLeadTodos: ids.leadTodos as number[]
        })
      );
    }
  }

  if (ids?.organizationLeadTodoRules) {
    if (!Array.isArray(ids.organizationLeadTodoRules)) {
      actions.push(
        organizationLeadTodoRuleActions.upsertOneOrganizationLeadTodoRule({
          organizationLeadTodoRule: {
            idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
            idOrganizationLeadTodoRule: ids.organizationLeadTodoRules as number
          } as OrganizationLeadTodoRule & any
        })
      );
      actions.push(
        organizationLeadTodoActions.addManyOrganizationLeadTodoRuleSuccess({
          idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
          idOrganizationLeadTodoRules: [ids.organizationLeadTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationLeadTodoRuleActions.upsertManyOrganizationLeadTodoRules({
          organizationLeadTodoRules: (ids.organizationLeadTodoRules as number[]).map(
            (idOrganizationLeadTodoRule: number) => ({
              idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
              idOrganizationLeadTodoRule
            })
          ) as OrganizationLeadTodoRule[] & any[]
        })
      );
      actions.push(
        organizationLeadTodoActions.addManyOrganizationLeadTodoRuleSuccess({
          idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
          idOrganizationLeadTodoRules: ids.organizationLeadTodoRules as number[]
        })
      );
    }
  }

  if (ids?.organizationLeadTodoAppliedRules) {
    if (!Array.isArray(ids.organizationLeadTodoAppliedRules)) {
      actions.push(
        organizationLeadTodoAppliedRuleActions.upsertOneOrganizationLeadTodoAppliedRule({
          organizationLeadTodoAppliedRule: {
            idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
            idOrganizationLeadTodoAppliedRule: ids.organizationLeadTodoAppliedRules as number
          } as OrganizationLeadTodoAppliedRule
        })
      );
      actions.push(
        organizationLeadTodoActions.addManyOrganizationLeadTodoAppliedRuleSuccess({
          idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
          idOrganizationLeadTodoAppliedRules: [ids.organizationLeadTodoAppliedRules as number]
        })
      );
    } else {
      actions.push(
        organizationLeadTodoAppliedRuleActions.upsertManyOrganizationLeadTodoAppliedRules({
          organizationLeadTodoAppliedRules: (ids.organizationLeadTodoAppliedRules as number[]).map(
            (idOrganizationLeadTodoAppliedRule: number) => ({
              idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
              idOrganizationLeadTodoAppliedRule
            })
          ) as OrganizationLeadTodoAppliedRule[]
        })
      );
      actions.push(
        organizationLeadTodoActions.addManyOrganizationLeadTodoAppliedRuleSuccess({
          idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
          idOrganizationLeadTodoAppliedRules: ids.organizationLeadTodoAppliedRules as number[]
        })
      );
    }
  }

  if (ids?.organizationLeadTodoProfils) {
    if (!Array.isArray(ids.organizationLeadTodoProfils)) {
      actions.push(
        organizationLeadTodoProfilActions.upsertOneOrganizationLeadTodoProfil({
          organizationLeadTodoProfil: {
            idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
            idOrganizationLeadTodoProfil: ids.organizationLeadTodoProfils as number
          } as OrganizationLeadTodoProfil
        })
      );
      actions.push(
        organizationLeadTodoActions.addManyOrganizationLeadTodoProfilSuccess({
          idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
          idOrganizationLeadTodoProfils: [ids.organizationLeadTodoProfils as number]
        })
      );
    } else {
      actions.push(
        organizationLeadTodoProfilActions.upsertManyOrganizationLeadTodoProfils({
          organizationLeadTodoProfils: (ids.organizationLeadTodoProfils as number[]).map(
            (idOrganizationLeadTodoProfil: number) => ({
              idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
              idOrganizationLeadTodoProfil
            })
          ) as OrganizationLeadTodoProfil[]
        })
      );
      actions.push(
        organizationLeadTodoActions.addManyOrganizationLeadTodoProfilSuccess({
          idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
          idOrganizationLeadTodoProfils: ids.organizationLeadTodoProfils as number[]
        })
      );
    }
  }

  if (ids?.companyCommunications) {
    if (!Array.isArray(ids.companyCommunications)) {
      actions.push(
        companyCommunicationActions.upsertOneCompanyCommunication({
          companyCommunication: {
            idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
            idCompanyCommunication: ids.companyCommunications as number
          } as CompanyCommunication & any
        })
      );
      actions.push(
        organizationLeadTodoActions.addManyCompanyCommunicationSuccess({
          idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
          idCompanyCommunications: [ids.companyCommunications as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationActions.upsertManyCompanyCommunications({
          companyCommunications: (ids.companyCommunications as number[]).map((idCompanyCommunication: number) => ({
            idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
            idCompanyCommunication
          })) as CompanyCommunication[] & any[]
        })
      );
      actions.push(
        organizationLeadTodoActions.addManyCompanyCommunicationSuccess({
          idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
          idCompanyCommunications: ids.companyCommunications as number[]
        })
      );
    }
  }

  if (ids?.companyCommunicationLeadTodos) {
    if (!Array.isArray(ids.companyCommunicationLeadTodos)) {
      actions.push(
        companyCommunicationLeadTodoActions.upsertOneCompanyCommunicationLeadTodo({
          companyCommunicationLeadTodo: {
            idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
            idCompanyCommunicationLeadTodo: ids.companyCommunicationLeadTodos as number
          } as CompanyCommunicationLeadTodo
        })
      );
      actions.push(
        organizationLeadTodoActions.addManyCompanyCommunicationLeadTodoSuccess({
          idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
          idCompanyCommunicationLeadTodos: [ids.companyCommunicationLeadTodos as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationLeadTodoActions.upsertManyCompanyCommunicationLeadTodos({
          companyCommunicationLeadTodos: (ids.companyCommunicationLeadTodos as number[]).map(
            (idCompanyCommunicationLeadTodo: number) => ({
              idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
              idCompanyCommunicationLeadTodo
            })
          ) as CompanyCommunicationLeadTodo[]
        })
      );
      actions.push(
        organizationLeadTodoActions.addManyCompanyCommunicationLeadTodoSuccess({
          idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo,
          idCompanyCommunicationLeadTodos: ids.companyCommunicationLeadTodos as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationLeadTodoActions(
  organizationLeadTodo: OrganizationLeadTodoEntityState
): Action[] {
  const actions: Action[] = [
    organizationLeadTodoActions.deleteOneOrganizationLeadTodoSuccess({
      idOrganizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo
    })
  ];

  if (organizationLeadTodo.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationLeadTodoSuccess({
        idOrganizationLeadTodos: [organizationLeadTodo.idOrganizationLeadTodo],
        idOrganizations: [organizationLeadTodo.organization as number]
      })
    );
  }

  if (organizationLeadTodo.step) {
    actions.push(
      stepActions.deleteManyOrganizationLeadTodoSuccess({
        idOrganizationLeadTodos: [organizationLeadTodo.idOrganizationLeadTodo],
        idSteps: [organizationLeadTodo.step as number]
      })
    );
  }

  if (organizationLeadTodo.leadTodos) {
    actions.push(
      leadTodoActions.deleteManyOrganizationLeadTodoSuccess({
        idOrganizationLeadTodos: [organizationLeadTodo.idOrganizationLeadTodo],
        idLeadTodos: organizationLeadTodo.leadTodos as number[]
      })
    );
  }

  if (organizationLeadTodo.organizationLeadTodoRules) {
    actions.push(
      organizationLeadTodoRuleActions.deleteManyOrganizationLeadTodoSuccess({
        idOrganizationLeadTodos: [organizationLeadTodo.idOrganizationLeadTodo],
        idOrganizationLeadTodoRules: organizationLeadTodo.organizationLeadTodoRules as number[]
      })
    );
  }

  if (organizationLeadTodo.organizationLeadTodoAppliedRules) {
    actions.push(
      organizationLeadTodoAppliedRuleActions.deleteManyOrganizationLeadTodoSuccess({
        idOrganizationLeadTodos: [organizationLeadTodo.idOrganizationLeadTodo],
        idOrganizationLeadTodoAppliedRules: organizationLeadTodo.organizationLeadTodoAppliedRules as number[]
      })
    );
  }

  if (organizationLeadTodo.organizationLeadTodoProfils) {
    actions.push(
      organizationLeadTodoProfilActions.deleteManyOrganizationLeadTodoSuccess({
        idOrganizationLeadTodos: [organizationLeadTodo.idOrganizationLeadTodo],
        idOrganizationLeadTodoProfils: organizationLeadTodo.organizationLeadTodoProfils as number[]
      })
    );
  }

  if (organizationLeadTodo.companyCommunications) {
    actions.push(
      companyCommunicationActions.deleteManyOrganizationLeadTodoSuccess({
        idOrganizationLeadTodos: [organizationLeadTodo.idOrganizationLeadTodo],
        idCompanyCommunications: organizationLeadTodo.companyCommunications as number[]
      })
    );
  }

  if (organizationLeadTodo.companyCommunicationLeadTodos) {
    actions.push(
      companyCommunicationLeadTodoActions.deleteManyOrganizationLeadTodoSuccess({
        idOrganizationLeadTodos: [organizationLeadTodo.idOrganizationLeadTodo],
        idCompanyCommunicationLeadTodos: organizationLeadTodo.companyCommunicationLeadTodos as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationLeadTodoEffects {
  constructor(
    protected actions$: Actions,
    protected organizationLeadTodoApiService: OrganizationLeadTodoApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationLeadTodos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadTodoActions.getManyOrganizationLeadTodos),
      switchMap(({ params }) =>
        this.organizationLeadTodoApiService.getOrganizationLeadTodos(params).pipe(
          map((organizationLeadTodos: OrganizationLeadTodo[]) => {
            return organizationLeadTodoActions.normalizeManyOrganizationLeadTodosAfterUpsert({ organizationLeadTodos });
          }),
          catchError(error => of(organizationLeadTodoActions.organizationLeadTodosFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationLeadTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadTodoActions.getOneOrganizationLeadTodo),
      switchMap(idOrganizationLeadTodo =>
        this.organizationLeadTodoApiService.getOrganizationLeadTodo(idOrganizationLeadTodo).pipe(
          map((organizationLeadTodo: OrganizationLeadTodo) => {
            return organizationLeadTodoActions.normalizeManyOrganizationLeadTodosAfterUpsert({
              organizationLeadTodos: [organizationLeadTodo]
            });
          }),
          catchError(error => of(organizationLeadTodoActions.organizationLeadTodosFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationLeadTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadTodoActions.upsertOneOrganizationLeadTodo),
      concatMap(
        ({
          organizationLeadTodo,
          ids
        }: {
          organizationLeadTodo: Partial<OrganizationLeadTodo>;
          ids?: OrganizationLeadTodoRelationsIds;
        }) => {
          if (organizationLeadTodo.idOrganizationLeadTodo) {
            return this.organizationLeadTodoApiService.updateOrganizationLeadTodo(organizationLeadTodo).pipe(
              map((organizationLeadTodoReturned: OrganizationLeadTodo) => {
                return organizationLeadTodoActions.normalizeManyOrganizationLeadTodosAfterUpsert({
                  organizationLeadTodos: [organizationLeadTodoReturned]
                });
              }),
              catchError(error => of(organizationLeadTodoActions.organizationLeadTodosFailure({ error })))
            );
          } else {
            return this.organizationLeadTodoApiService.addOrganizationLeadTodo(organizationLeadTodo).pipe(
              mergeMap((organizationLeadTodoReturned: OrganizationLeadTodo) =>
                getDefaultAddOrganizationLeadTodoActions(organizationLeadTodoReturned, ids)
              ),
              catchError(error => of(organizationLeadTodoActions.organizationLeadTodosFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationLeadTodo$ = createEffect(() => {
    const selectOrganizationLeadTodoState$ = this.store$.select(selectOrganizationLeadTodoState);
    return this.actions$.pipe(
      ofType(organizationLeadTodoActions.deleteOneOrganizationLeadTodo),
      withLatestFrom(selectOrganizationLeadTodoState$),
      concatMap(([{ idOrganizationLeadTodo }, state]) =>
        this.organizationLeadTodoApiService.deleteOrganizationLeadTodo(idOrganizationLeadTodo).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOrganizationLeadTodoActions(
                state.entities[idOrganizationLeadTodo] as OrganizationLeadTodoEntityState
              ),
              organizationLeadTodoActions.deleteOneOrganizationLeadTodo.type
            )
          ]),
          catchError(error => of(organizationLeadTodoActions.organizationLeadTodosFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationLeadTodosAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadTodoActions.normalizeManyOrganizationLeadTodosAfterUpsert),
      concatMap(({ organizationLeadTodos }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationLeadTodo(
          organizationLeadTodos,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationLeadTodo] Normalization After Upsert Success')];
      })
    );
  });
}
