import { CompanyCommunicationFileState, initialState } from './company-communication-file.state';
import { Action, createReducer } from '@ngrx/store';
import { companyCommunicationFileReducersGeneratedFunctions } from './company-communication-file-generated.reducer';

const companyCommunicationFileReducersFunctions = [...companyCommunicationFileReducersGeneratedFunctions];

const companyCommunicationFileReducer = createReducer(initialState, ...companyCommunicationFileReducersFunctions);

export function reducer(state: CompanyCommunicationFileState | undefined, action: Action) {
  return companyCommunicationFileReducer(state, action);
}
