import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyStudyReasonActions from './company-study-reason.actions';
import { adapter, initialState, CompanyStudyReasonState } from './company-study-reason.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyStudyReasonEntityState } from '@_model/interfaces/company-study-reason.model';

export const companyStudyReasonReducersGeneratedFunctions: ReducerTypes<
  CompanyStudyReasonState,
  readonly ActionCreator[]
>[] = [
  on(CompanyStudyReasonActions.getOneCompanyStudyReason, (state: CompanyStudyReasonState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyStudyReasonActions.getManyCompanyStudyReasons, (state: CompanyStudyReasonState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyStudyReasonActions.upsertOneCompanyStudyReason, (state: CompanyStudyReasonState) =>
    setLoadingsState(state, true)
  ),

  on(
    CompanyStudyReasonActions.upsertManyCompanyStudyReasonsSuccess,
    (state: CompanyStudyReasonState, { companyStudyReasons }) =>
      adapter.upsertMany(companyStudyReasons, setLoadingsState(state, false))
  ),
  on(CompanyStudyReasonActions.deleteOneCompanyStudyReason, (state: CompanyStudyReasonState) =>
    setLoadingsState(state, true)
  ),
  on(
    CompanyStudyReasonActions.deleteOneCompanyStudyReasonSuccess,
    (state: CompanyStudyReasonState, { idCompanyStudyReason }) =>
      adapter.removeOne(idCompanyStudyReason, setLoadingsState(state, false))
  ),
  on(CompanyStudyReasonActions.clearActive, (state: CompanyStudyReasonState) => ({ ...state, actives: [] })),
  on(CompanyStudyReasonActions.addManyActives, (state: CompanyStudyReasonState, { idCompanyStudyReasons }) => ({
    ...state,
    actives: state.actives.concat(idCompanyStudyReasons)
  })),
  on(CompanyStudyReasonActions.deleteOneActive, (state: CompanyStudyReasonState, { idCompanyStudyReason }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCompanyStudyReason)
  })),

  on(CompanyStudyReasonActions.clearStore, () => initialState),

  on(
    CompanyStudyReasonActions.addManyResidenceStudyReasonSuccess,
    (state: CompanyStudyReasonState, { idCompanyStudyReason, idResidenceStudyReasons }) => {
      if (!state.entities[idCompanyStudyReason]) {
        return state;
      }
      const residenceStudyReasons = (state.entities[idCompanyStudyReason]?.residenceStudyReasons as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStudyReason]: {
            ...state.entities[idCompanyStudyReason],
            residenceStudyReasons: residenceStudyReasons.concat(
              idResidenceStudyReasons.filter(id => residenceStudyReasons.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyStudyReasonActions.deleteManyResidenceStudyReasonSuccess,
    (state: CompanyStudyReasonState, { idResidenceStudyReasons, idCompanyStudyReasons }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStudyReasons.reduce((entities, idCompanyStudyReason) => {
            if (!state.entities[idCompanyStudyReason]?.residenceStudyReasons) {
              return entities;
            }
            entities[idCompanyStudyReason] = {
              ...state.entities[idCompanyStudyReason],
              residenceStudyReasons: (state.entities[idCompanyStudyReason]?.residenceStudyReasons as number[])?.filter(
                (idResidenceStudyReason: number) =>
                  !idResidenceStudyReasons.some((id: number) => id === idResidenceStudyReason)
              )
            } as CompanyStudyReasonEntityState;
            return entities;
          }, {} as Dictionary<CompanyStudyReasonEntityState>)
        }
      };
    }
  ),

  on(
    CompanyStudyReasonActions.addManyResidenceStudySuccess,
    (state: CompanyStudyReasonState, { idCompanyStudyReason, idResidenceStudies }) => {
      if (!state.entities[idCompanyStudyReason]) {
        return state;
      }
      const residenceStudies = (state.entities[idCompanyStudyReason]?.residenceStudies as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStudyReason]: {
            ...state.entities[idCompanyStudyReason],
            residenceStudies: residenceStudies.concat(idResidenceStudies.filter(id => residenceStudies.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CompanyStudyReasonActions.deleteManyResidenceStudySuccess,
    (state: CompanyStudyReasonState, { idResidenceStudies, idCompanyStudyReasons }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStudyReasons.reduce((entities, idCompanyStudyReason) => {
            if (!state.entities[idCompanyStudyReason]?.residenceStudies) {
              return entities;
            }
            entities[idCompanyStudyReason] = {
              ...state.entities[idCompanyStudyReason],
              residenceStudies: (state.entities[idCompanyStudyReason]?.residenceStudies as number[])?.filter(
                (idResidenceStudy: number) => !idResidenceStudies.some((id: number) => id === idResidenceStudy)
              )
            } as CompanyStudyReasonEntityState;
            return entities;
          }, {} as Dictionary<CompanyStudyReasonEntityState>)
        }
      };
    }
  ),

  on(
    CompanyStudyReasonActions.addCompanySuccess,
    (state: CompanyStudyReasonState, { idCompanyStudyReason, idCompany }) => {
      if (!state.entities[idCompanyStudyReason]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStudyReason]: {
            ...state.entities[idCompanyStudyReason],
            company: idCompany
          }
        }
      };
    }
  ),

  on(
    CompanyStudyReasonActions.deleteManyCompanySuccess,
    (state: CompanyStudyReasonState, { idCompanies, idCompanyStudyReasons }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStudyReasons.reduce((entities, idCompanyStudyReason) => {
            if (!state.entities[idCompanyStudyReason]?.company) {
              return entities;
            }
            entities[idCompanyStudyReason] = {
              ...state.entities[idCompanyStudyReason],
              company: idCompanies.some(
                (idCompany: number) => idCompany === state.entities[idCompanyStudyReason]?.company
              )
                ? undefined
                : state.entities[idCompanyStudyReason]?.company
            } as CompanyStudyReasonEntityState;
            return entities;
          }, {} as Dictionary<CompanyStudyReasonEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyStudyReasonState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyStudyReasonState {
  return { ...state, isLoaded, isLoading };
}
