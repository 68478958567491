import { createAction, props } from '@ngrx/store';
import { ResidencePriceGridHistoryEntityState } from '@_shared/models/interfaces/residence-price-grid-history.model';

export * from './residence-price-grid-history-generated.actions';

export const getLastUpdateResidencePriceGridHistories = createAction(
  '[ResidencePriceGridHistory] Get Many Last Updated ResidencePriceGridHistories',
  props<{ params: any }>()
);

export const normalizeLastUpdateResidencePriceGridHistoriesAfterUpsert = createAction(
  '[ResidencePriceGridHistory] Normalize Many Last ResidencePriceGridHistories After Upsert',
  props<{ residencePriceGridHistories: ResidencePriceGridHistoryEntityState[] }>()
);
