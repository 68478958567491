import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResidenceForecastApiService } from '@_services/api/residence-forecast-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedResidenceForecastEffects } from './residence-forecast-generated.effects';

@Injectable()
export class ResidenceForecastEffects extends GeneratedResidenceForecastEffects {
  constructor(actions$: Actions, residenceForecastApiService: ResidenceForecastApiService, store$: Store<AppState>) {
    super(actions$, residenceForecastApiService, store$);
  }
}
