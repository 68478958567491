import { RepositoryService } from '@_services/api/repository.service';
import { CompanyStratalotType } from '@_model/interfaces/company-stratalot-type.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyStratalotTypeApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyStratalotTypes(params?: any): Observable<CompanyStratalotType[]> {
    return this.repo.getData<CompanyStratalotType[]>('company-stratalot-type', params);
  }

  public getCompanyStratalotType(params: {
    idCompanyStratalotType: number;
    params?: any;
  }): Observable<CompanyStratalotType> {
    return this.repo.getData<CompanyStratalotType>(
      'company-stratalot-type/' + params.idCompanyStratalotType,
      params.params
    );
  }

  public addCompanyStratalotType(
    companyStratalotType: Partial<CompanyStratalotType>
  ): Observable<CompanyStratalotType> {
    return this.repo.create<CompanyStratalotType>('company-stratalot-type', companyStratalotType);
  }

  public updateCompanyStratalotType(
    companyStratalotType: Partial<CompanyStratalotType>
  ): Observable<CompanyStratalotType> {
    return this.repo.update('company-stratalot-type', companyStratalotType);
  }

  public deleteCompanyStratalotType(idCompanyStratalotType: number): Observable<number> {
    return this.repo.delete('company-stratalot-type/' + +idCompanyStratalotType);
  }
}
