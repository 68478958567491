import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ResidenceStudyLot, ResidenceStudyLotEntityState } from '@_model/interfaces/residence-study-lot.model';
import { ResidenceStudyLotRelationsIds } from './residence-study-lot-generated.effects';

export const getOneResidenceStudyLot = createAction(
  '[ResidenceStudyLot] Get One ResidenceStudyLot',
  props<{ idResidenceStudyLot: number; params?: any }>()
);

export const getManyResidenceStudyLots = createAction(
  '[ResidenceStudyLot] Get Many ResidenceStudyLots',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ResidenceStudyLot] Add Many Actives ResidenceStudyLot',
  props<{ idResidenceStudyLots: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidenceStudyLot] Delete One Active ResidenceStudyLot',
  props<{ idResidenceStudyLot: number }>()
);

export const clearActive = createAction('[ResidenceStudyLot] Clear Active ResidenceStudyLot');

export const upsertOneResidenceStudyLot = createAction(
  '[ResidenceStudyLot] Upsert One ResidenceStudyLot',
  props<{
    residenceStudyLot: Partial<ResidenceStudyLot>;
    ids?: ResidenceStudyLotRelationsIds;
  }>()
);

export const upsertManyResidenceStudyLots = createAction(
  '[ResidenceStudyLot] Upsert Many ResidenceStudyLots',
  props<{
    residenceStudyLots: Partial<ResidenceStudyLot>[];
    ids?: ResidenceStudyLotRelationsIds;
  }>()
);

export const upsertManyResidenceStudyLotsSuccess = createAction(
  '[ResidenceStudyLot] Create Many ResidenceStudyLots Success',
  props<{ residenceStudyLots: ResidenceStudyLotEntityState[] }>()
);

export const deleteOneResidenceStudyLot = createAction(
  '[ResidenceStudyLot] Delete One ResidenceStudyLot',
  props<{ idResidenceStudyLot: number }>()
);

export const deleteOneResidenceStudyLotSuccess = createAction(
  '[ResidenceStudyLot] Delete One ResidenceStudyLot Success',
  props<{ idResidenceStudyLot: number }>()
);

export const normalizeManyResidenceStudyLotsAfterUpsert = createAction(
  '[ResidenceStudyLot] Normalize Many ResidenceStudyLots After Upsert',
  props<{ residenceStudyLots: ResidenceStudyLotEntityState[] }>()
);

export const residenceStudyLotsFailure = createAction(
  '[ResidenceStudyLot] ResidenceStudyLots Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidenceStudyLot] Clear ResidenceStudyLots');

export const addResidenceStudySuccess = createAction(
  '[ResidenceStudyLot] Add ResidenceStudy',
  props<{ idResidenceStudyLot: number; idResidenceStudy: number }>()
);

export const deleteManyResidenceStudySuccess = createAction(
  '[ResidenceStudyLot] Delete Many ResidenceStudy',
  props<{ idResidenceStudies: number[]; idResidenceStudyLots: number[] }>()
);
