import { RepositoryService } from '@_services/api/repository.service';
import { LeadTodo } from '@_model/interfaces/lead-todo.model';
import { Observable } from 'rxjs';

export class GeneratedLeadTodoApiService {
  constructor(protected repo: RepositoryService) {}

  public getLeadTodos(params?: any): Observable<LeadTodo[]> {
    return this.repo.getData<LeadTodo[]>('lead-todo', params);
  }

  public getLeadTodo(params: { idLeadTodo: number; params?: any }): Observable<LeadTodo> {
    return this.repo.getData<LeadTodo>('lead-todo/' + params.idLeadTodo, params.params);
  }

  public addLeadTodo(leadTodo: Partial<LeadTodo>): Observable<LeadTodo> {
    return this.repo.create<LeadTodo>('lead-todo', leadTodo);
  }

  public updateLeadTodo(leadTodo: Partial<LeadTodo>): Observable<LeadTodo> {
    return this.repo.update('lead-todo', leadTodo);
  }

  public deleteLeadTodo(idLeadTodo: number): Observable<number> {
    return this.repo.delete('lead-todo/' + +idLeadTodo);
  }
}
