import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  CompanyCommunicationLeadTodo,
  CompanyCommunicationLeadTodoEntityState
} from '@_model/interfaces/company-communication-lead-todo.model';
import { CompanyCommunicationLeadTodoApiService } from '@_services/api/company-communication-lead-todo-api.service';
import * as companyCommunicationLeadTodoActions from '@_store/company-communication-lead-todo/company-communication-lead-todo.actions';
import { getActionsToNormalizeCompanyCommunicationLeadTodo } from '@_config/store/normalization.generated';
import { selectCompanyCommunicationLeadTodoState } from './company-communication-lead-todo-generated.selectors';
import * as companyCommunicationActions from '@_store/company-communication/company-communication.actions';
import * as organizationLeadTodoActions from '@_store/organization-lead-todo/organization-lead-todo.actions';

export interface CompanyCommunicationLeadTodoRelationsIds {
  companyCommunication?: number;
  organizationLeadTodo?: number;
}

export function getDefaultAddCompanyCommunicationLeadTodoActions(
  companyCommunicationLeadTodo: CompanyCommunicationLeadTodoEntityState,
  ids?: CompanyCommunicationLeadTodoRelationsIds
): Action[] {
  const actions: Action[] = [
    companyCommunicationLeadTodoActions.normalizeManyCompanyCommunicationLeadTodosAfterUpsert({
      companyCommunicationLeadTodos: [companyCommunicationLeadTodo]
    })
  ];

  if (ids?.companyCommunication) {
    actions.push(
      companyCommunicationActions.addManyCompanyCommunicationLeadTodoSuccess({
        idCompanyCommunication: ids.companyCommunication,
        idCompanyCommunicationLeadTodos: [companyCommunicationLeadTodo.idCompanyCommunicationLeadTodo]
      })
    );
    actions.push(
      companyCommunicationLeadTodoActions.addCompanyCommunicationSuccess({
        idCompanyCommunicationLeadTodo: companyCommunicationLeadTodo.idCompanyCommunicationLeadTodo,
        idCompanyCommunication: ids.companyCommunication
      })
    );
  }

  if (ids?.organizationLeadTodo) {
    actions.push(
      organizationLeadTodoActions.addManyCompanyCommunicationLeadTodoSuccess({
        idOrganizationLeadTodo: ids.organizationLeadTodo,
        idCompanyCommunicationLeadTodos: [companyCommunicationLeadTodo.idCompanyCommunicationLeadTodo]
      })
    );
    actions.push(
      companyCommunicationLeadTodoActions.addOrganizationLeadTodoSuccess({
        idCompanyCommunicationLeadTodo: companyCommunicationLeadTodo.idCompanyCommunicationLeadTodo,
        idOrganizationLeadTodo: ids.organizationLeadTodo
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCompanyCommunicationLeadTodoActions(
  companyCommunicationLeadTodo: CompanyCommunicationLeadTodoEntityState
): Action[] {
  const actions: Action[] = [
    companyCommunicationLeadTodoActions.deleteOneCompanyCommunicationLeadTodoSuccess({
      idCompanyCommunicationLeadTodo: companyCommunicationLeadTodo.idCompanyCommunicationLeadTodo
    })
  ];

  if (companyCommunicationLeadTodo.companyCommunication) {
    actions.push(
      companyCommunicationActions.deleteManyCompanyCommunicationLeadTodoSuccess({
        idCompanyCommunicationLeadTodos: [companyCommunicationLeadTodo.idCompanyCommunicationLeadTodo],
        idCompanyCommunications: [companyCommunicationLeadTodo.companyCommunication as number]
      })
    );
  }

  if (companyCommunicationLeadTodo.organizationLeadTodo) {
    actions.push(
      organizationLeadTodoActions.deleteManyCompanyCommunicationLeadTodoSuccess({
        idCompanyCommunicationLeadTodos: [companyCommunicationLeadTodo.idCompanyCommunicationLeadTodo],
        idOrganizationLeadTodos: [companyCommunicationLeadTodo.organizationLeadTodo as number]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyCommunicationLeadTodoEffects {
  constructor(
    protected actions$: Actions,
    protected companyCommunicationLeadTodoApiService: CompanyCommunicationLeadTodoApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyCommunicationLeadTodos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationLeadTodoActions.getManyCompanyCommunicationLeadTodos),
      switchMap(({ params }) =>
        this.companyCommunicationLeadTodoApiService.getCompanyCommunicationLeadTodos(params).pipe(
          map((companyCommunicationLeadTodos: CompanyCommunicationLeadTodo[]) => {
            return companyCommunicationLeadTodoActions.normalizeManyCompanyCommunicationLeadTodosAfterUpsert({
              companyCommunicationLeadTodos
            });
          }),
          catchError(error => of(companyCommunicationLeadTodoActions.companyCommunicationLeadTodosFailure({ error })))
        )
      )
    );
  });

  getOneCompanyCommunicationLeadTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationLeadTodoActions.getOneCompanyCommunicationLeadTodo),
      switchMap(idCompanyCommunicationLeadTodo =>
        this.companyCommunicationLeadTodoApiService
          .getCompanyCommunicationLeadTodo(idCompanyCommunicationLeadTodo)
          .pipe(
            map((companyCommunicationLeadTodo: CompanyCommunicationLeadTodo) => {
              return companyCommunicationLeadTodoActions.normalizeManyCompanyCommunicationLeadTodosAfterUpsert({
                companyCommunicationLeadTodos: [companyCommunicationLeadTodo]
              });
            }),
            catchError(error => of(companyCommunicationLeadTodoActions.companyCommunicationLeadTodosFailure({ error })))
          )
      )
    );
  });

  upsertOneCompanyCommunicationLeadTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationLeadTodoActions.upsertOneCompanyCommunicationLeadTodo),
      concatMap(
        ({
          companyCommunicationLeadTodo,
          ids
        }: {
          companyCommunicationLeadTodo: Partial<CompanyCommunicationLeadTodo>;
          ids?: CompanyCommunicationLeadTodoRelationsIds;
        }) => {
          if (companyCommunicationLeadTodo.idCompanyCommunicationLeadTodo) {
            return this.companyCommunicationLeadTodoApiService
              .updateCompanyCommunicationLeadTodo(companyCommunicationLeadTodo)
              .pipe(
                map((companyCommunicationLeadTodoReturned: CompanyCommunicationLeadTodo) => {
                  return companyCommunicationLeadTodoActions.normalizeManyCompanyCommunicationLeadTodosAfterUpsert({
                    companyCommunicationLeadTodos: [companyCommunicationLeadTodoReturned]
                  });
                }),
                catchError(error =>
                  of(companyCommunicationLeadTodoActions.companyCommunicationLeadTodosFailure({ error }))
                )
              );
          } else {
            return this.companyCommunicationLeadTodoApiService
              .addCompanyCommunicationLeadTodo(companyCommunicationLeadTodo)
              .pipe(
                mergeMap((companyCommunicationLeadTodoReturned: CompanyCommunicationLeadTodo) =>
                  getDefaultAddCompanyCommunicationLeadTodoActions(companyCommunicationLeadTodoReturned, ids)
                ),
                catchError(error =>
                  of(companyCommunicationLeadTodoActions.companyCommunicationLeadTodosFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneCompanyCommunicationLeadTodo$ = createEffect(() => {
    const selectCompanyCommunicationLeadTodoState$ = this.store$.select(selectCompanyCommunicationLeadTodoState);
    return this.actions$.pipe(
      ofType(companyCommunicationLeadTodoActions.deleteOneCompanyCommunicationLeadTodo),
      withLatestFrom(selectCompanyCommunicationLeadTodoState$),
      concatMap(([{ idCompanyCommunicationLeadTodo }, state]) =>
        this.companyCommunicationLeadTodoApiService
          .deleteCompanyCommunicationLeadTodo(idCompanyCommunicationLeadTodo)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteCompanyCommunicationLeadTodoActions(
                  state.entities[idCompanyCommunicationLeadTodo] as CompanyCommunicationLeadTodoEntityState
                ),
                companyCommunicationLeadTodoActions.deleteOneCompanyCommunicationLeadTodo.type
              )
            ]),
            catchError(error => of(companyCommunicationLeadTodoActions.companyCommunicationLeadTodosFailure({ error })))
          )
      )
    );
  });

  normalizeManyCompanyCommunicationLeadTodosAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationLeadTodoActions.normalizeManyCompanyCommunicationLeadTodosAfterUpsert),
      concatMap(({ companyCommunicationLeadTodos }) => {
        const actions: Action[] = getActionsToNormalizeCompanyCommunicationLeadTodo(
          companyCommunicationLeadTodos,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CompanyCommunicationLeadTodo] Normalization After Upsert Success')];
      })
    );
  });
}
