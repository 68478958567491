import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { CompanyStudyReason, CompanyStudyReasonEntityState } from '@_model/interfaces/company-study-reason.model';
import { CompanyStudyReasonApiService } from '@_services/api/company-study-reason-api.service';
import * as companyStudyReasonActions from '@_store/company-study-reason/company-study-reason.actions';
import { getActionsToNormalizeCompanyStudyReason } from '@_config/store/normalization.generated';
import { selectCompanyStudyReasonState } from './company-study-reason-generated.selectors';
import * as companyActions from '@_store/company/company.actions';
import * as residenceStudyReasonActions from '@_store/residence-study-reason/residence-study-reason.actions';
import { ResidenceStudyReason } from '@_model/interfaces/residence-study-reason.model';
import * as residenceStudyActions from '@_store/residence-study/residence-study.actions';
import { ResidenceStudy } from '@_model/interfaces/residence-study.model';

export interface CompanyStudyReasonRelationsIds {
  residenceStudyReasons?: number | number[];
  residenceStudies?: number | number[];
  company?: number;
}

export function getDefaultAddCompanyStudyReasonActions(
  companyStudyReason: CompanyStudyReasonEntityState,
  ids?: CompanyStudyReasonRelationsIds
): Action[] {
  const actions: Action[] = [
    companyStudyReasonActions.normalizeManyCompanyStudyReasonsAfterUpsert({ companyStudyReasons: [companyStudyReason] })
  ];

  if (ids?.company) {
    actions.push(
      companyActions.addManyCompanyStudyReasonSuccess({
        idCompany: ids.company,
        idCompanyStudyReasons: [companyStudyReason.idCompanyStudyReason]
      })
    );
    actions.push(
      companyStudyReasonActions.addCompanySuccess({
        idCompanyStudyReason: companyStudyReason.idCompanyStudyReason,
        idCompany: ids.company
      })
    );
  }

  if (ids?.residenceStudyReasons) {
    if (!Array.isArray(ids.residenceStudyReasons)) {
      actions.push(
        residenceStudyReasonActions.upsertOneResidenceStudyReason({
          residenceStudyReason: {
            idCompanyStudyReason: companyStudyReason.idCompanyStudyReason,
            idResidenceStudyReason: ids.residenceStudyReasons as number
          } as ResidenceStudyReason
        })
      );
      actions.push(
        companyStudyReasonActions.addManyResidenceStudyReasonSuccess({
          idCompanyStudyReason: companyStudyReason.idCompanyStudyReason,
          idResidenceStudyReasons: [ids.residenceStudyReasons as number]
        })
      );
    } else {
      actions.push(
        residenceStudyReasonActions.upsertManyResidenceStudyReasons({
          residenceStudyReasons: (ids.residenceStudyReasons as number[]).map((idResidenceStudyReason: number) => ({
            idCompanyStudyReason: companyStudyReason.idCompanyStudyReason,
            idResidenceStudyReason
          })) as ResidenceStudyReason[]
        })
      );
      actions.push(
        companyStudyReasonActions.addManyResidenceStudyReasonSuccess({
          idCompanyStudyReason: companyStudyReason.idCompanyStudyReason,
          idResidenceStudyReasons: ids.residenceStudyReasons as number[]
        })
      );
    }
  }

  if (ids?.residenceStudies) {
    if (!Array.isArray(ids.residenceStudies)) {
      actions.push(
        residenceStudyActions.upsertOneResidenceStudy({
          residenceStudy: {
            idCompanyStudyReason: companyStudyReason.idCompanyStudyReason,
            idResidenceStudy: ids.residenceStudies as number
          } as ResidenceStudy & any
        })
      );
      actions.push(
        companyStudyReasonActions.addManyResidenceStudySuccess({
          idCompanyStudyReason: companyStudyReason.idCompanyStudyReason,
          idResidenceStudies: [ids.residenceStudies as number]
        })
      );
    } else {
      actions.push(
        residenceStudyActions.upsertManyResidenceStudies({
          residenceStudies: (ids.residenceStudies as number[]).map((idResidenceStudy: number) => ({
            idCompanyStudyReason: companyStudyReason.idCompanyStudyReason,
            idResidenceStudy
          })) as ResidenceStudy[] & any[]
        })
      );
      actions.push(
        companyStudyReasonActions.addManyResidenceStudySuccess({
          idCompanyStudyReason: companyStudyReason.idCompanyStudyReason,
          idResidenceStudies: ids.residenceStudies as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCompanyStudyReasonActions(companyStudyReason: CompanyStudyReasonEntityState): Action[] {
  const actions: Action[] = [
    companyStudyReasonActions.deleteOneCompanyStudyReasonSuccess({
      idCompanyStudyReason: companyStudyReason.idCompanyStudyReason
    })
  ];

  if (companyStudyReason.company) {
    actions.push(
      companyActions.deleteManyCompanyStudyReasonSuccess({
        idCompanyStudyReasons: [companyStudyReason.idCompanyStudyReason],
        idCompanies: [companyStudyReason.company as number]
      })
    );
  }

  if (companyStudyReason.residenceStudyReasons) {
    actions.push(
      residenceStudyReasonActions.deleteManyCompanyStudyReasonSuccess({
        idCompanyStudyReasons: [companyStudyReason.idCompanyStudyReason],
        idResidenceStudyReasons: companyStudyReason.residenceStudyReasons as number[]
      })
    );
  }

  if (companyStudyReason.residenceStudies) {
    actions.push(
      residenceStudyActions.deleteManyCompanyStudyReasonSuccess({
        idCompanyStudyReasons: [companyStudyReason.idCompanyStudyReason],
        idResidenceStudies: companyStudyReason.residenceStudies as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyStudyReasonEffects {
  constructor(
    protected actions$: Actions,
    protected companyStudyReasonApiService: CompanyStudyReasonApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyStudyReasons$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStudyReasonActions.getManyCompanyStudyReasons),
      switchMap(({ params }) =>
        this.companyStudyReasonApiService.getCompanyStudyReasons(params).pipe(
          map((companyStudyReasons: CompanyStudyReason[]) => {
            return companyStudyReasonActions.normalizeManyCompanyStudyReasonsAfterUpsert({ companyStudyReasons });
          }),
          catchError(error => of(companyStudyReasonActions.companyStudyReasonsFailure({ error })))
        )
      )
    );
  });

  getOneCompanyStudyReason$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStudyReasonActions.getOneCompanyStudyReason),
      switchMap(idCompanyStudyReason =>
        this.companyStudyReasonApiService.getCompanyStudyReason(idCompanyStudyReason).pipe(
          map((companyStudyReason: CompanyStudyReason) => {
            return companyStudyReasonActions.normalizeManyCompanyStudyReasonsAfterUpsert({
              companyStudyReasons: [companyStudyReason]
            });
          }),
          catchError(error => of(companyStudyReasonActions.companyStudyReasonsFailure({ error })))
        )
      )
    );
  });

  upsertOneCompanyStudyReason$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStudyReasonActions.upsertOneCompanyStudyReason),
      concatMap(
        ({
          companyStudyReason,
          ids
        }: {
          companyStudyReason: Partial<CompanyStudyReason>;
          ids?: CompanyStudyReasonRelationsIds;
        }) => {
          if (companyStudyReason.idCompanyStudyReason) {
            return this.companyStudyReasonApiService.updateCompanyStudyReason(companyStudyReason).pipe(
              map((companyStudyReasonReturned: CompanyStudyReason) => {
                return companyStudyReasonActions.normalizeManyCompanyStudyReasonsAfterUpsert({
                  companyStudyReasons: [companyStudyReasonReturned]
                });
              }),
              catchError(error => of(companyStudyReasonActions.companyStudyReasonsFailure({ error })))
            );
          } else {
            return this.companyStudyReasonApiService.addCompanyStudyReason(companyStudyReason).pipe(
              mergeMap((companyStudyReasonReturned: CompanyStudyReason) =>
                getDefaultAddCompanyStudyReasonActions(companyStudyReasonReturned, ids)
              ),
              catchError(error => of(companyStudyReasonActions.companyStudyReasonsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCompanyStudyReason$ = createEffect(() => {
    const selectCompanyStudyReasonState$ = this.store$.select(selectCompanyStudyReasonState);
    return this.actions$.pipe(
      ofType(companyStudyReasonActions.deleteOneCompanyStudyReason),
      withLatestFrom(selectCompanyStudyReasonState$),
      concatMap(([{ idCompanyStudyReason }, state]) =>
        this.companyStudyReasonApiService.deleteCompanyStudyReason(idCompanyStudyReason).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCompanyStudyReasonActions(
                state.entities[idCompanyStudyReason] as CompanyStudyReasonEntityState
              ),
              companyStudyReasonActions.deleteOneCompanyStudyReason.type
            )
          ]),
          catchError(error => of(companyStudyReasonActions.companyStudyReasonsFailure({ error })))
        )
      )
    );
  });

  normalizeManyCompanyStudyReasonsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStudyReasonActions.normalizeManyCompanyStudyReasonsAfterUpsert),
      concatMap(({ companyStudyReasons }) => {
        const actions: Action[] = getActionsToNormalizeCompanyStudyReason(companyStudyReasons, StoreActionType.upsert);
        return [getMultiAction(actions, '[CompanyStudyReason] Normalization After Upsert Success')];
      })
    );
  });
}
