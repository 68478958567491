import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  ResidencePriceGridHistory,
  ResidencePriceGridHistoryEntityState
} from '@_model/interfaces/residence-price-grid-history.model';
import { ResidencePriceGridHistoryRelationsIds } from './residence-price-grid-history-generated.effects';

export const getOneResidencePriceGridHistory = createAction(
  '[ResidencePriceGridHistory] Get One ResidencePriceGridHistory',
  props<{ idResidencePriceGridHistory: number; params?: any }>()
);

export const getManyResidencePriceGridHistories = createAction(
  '[ResidencePriceGridHistory] Get Many ResidencePriceGridHistories',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ResidencePriceGridHistory] Add Many Actives ResidencePriceGridHistory',
  props<{ idResidencePriceGridHistories: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidencePriceGridHistory] Delete One Active ResidencePriceGridHistory',
  props<{ idResidencePriceGridHistory: number }>()
);

export const clearActive = createAction('[ResidencePriceGridHistory] Clear Active ResidencePriceGridHistory');

export const upsertOneResidencePriceGridHistory = createAction(
  '[ResidencePriceGridHistory] Upsert One ResidencePriceGridHistory',
  props<{
    residencePriceGridHistory: Partial<ResidencePriceGridHistory>;
    ids?: ResidencePriceGridHistoryRelationsIds;
  }>()
);

export const upsertManyResidencePriceGridHistories = createAction(
  '[ResidencePriceGridHistory] Upsert Many ResidencePriceGridHistories',
  props<{
    residencePriceGridHistories: Partial<ResidencePriceGridHistory>[];
    ids?: ResidencePriceGridHistoryRelationsIds;
  }>()
);

export const upsertManyResidencePriceGridHistoriesSuccess = createAction(
  '[ResidencePriceGridHistory] Create Many ResidencePriceGridHistories Success',
  props<{ residencePriceGridHistories: ResidencePriceGridHistoryEntityState[] }>()
);

export const deleteOneResidencePriceGridHistory = createAction(
  '[ResidencePriceGridHistory] Delete One ResidencePriceGridHistory',
  props<{ idResidencePriceGridHistory: number }>()
);

export const deleteOneResidencePriceGridHistorySuccess = createAction(
  '[ResidencePriceGridHistory] Delete One ResidencePriceGridHistory Success',
  props<{ idResidencePriceGridHistory: number }>()
);

export const normalizeManyResidencePriceGridHistoriesAfterUpsert = createAction(
  '[ResidencePriceGridHistory] Normalize Many ResidencePriceGridHistories After Upsert',
  props<{ residencePriceGridHistories: ResidencePriceGridHistoryEntityState[] }>()
);

export const residencePriceGridHistoriesFailure = createAction(
  '[ResidencePriceGridHistory] ResidencePriceGridHistories Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidencePriceGridHistory] Clear ResidencePriceGridHistories');

export const addResidencePriceGridSuccess = createAction(
  '[ResidencePriceGridHistory] Add ResidencePriceGrid',
  props<{ idResidencePriceGridHistory: number; idResidencePriceGrid: number }>()
);

export const deleteManyResidencePriceGridSuccess = createAction(
  '[ResidencePriceGridHistory] Delete Many ResidencePriceGrid',
  props<{ idResidencePriceGrids: number[]; idResidencePriceGridHistories: number[] }>()
);

export const addUserSuccess = createAction(
  '[ResidencePriceGridHistory] Add User',
  props<{ idResidencePriceGridHistory: number; idUser: number }>()
);

export const deleteManyUserSuccess = createAction(
  '[ResidencePriceGridHistory] Delete Many User',
  props<{ idUsers: number[]; idResidencePriceGridHistories: number[] }>()
);
