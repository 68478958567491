import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationLeadAvancement,
  OrganizationLeadAvancementEntityState
} from '@_model/interfaces/organization-lead-avancement.model';
import { Lead, LeadEntityState } from '@_model/interfaces/lead.model';
import { Step, StepEntityState } from '@_model/interfaces/step.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationLeadAvancementFeatureKey,
  OrganizationLeadAvancementState
} from './organization-lead-avancement.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationLeadAvancementRelations: string[] = ['leads', 'steps', 'organizations'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationLeadAvancementState = createFeatureSelector<OrganizationLeadAvancementState>(
  organizationLeadAvancementFeatureKey
);

export const selectIsLoadedOrganizationLeadAvancement = createSelector(
  selectOrganizationLeadAvancementState,
  (state: OrganizationLeadAvancementState) => state.isLoaded
);

export const selectIsLoadingOrganizationLeadAvancement = createSelector(
  selectOrganizationLeadAvancementState,
  (state: OrganizationLeadAvancementState) => state.isLoading
);

export const selectIsReadyOrganizationLeadAvancement = createSelector(
  selectOrganizationLeadAvancementState,
  (state: OrganizationLeadAvancementState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationLeadAvancement = createSelector(
  selectOrganizationLeadAvancementState,
  (state: OrganizationLeadAvancementState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationLeadAvancementModel: SelectorModel = {
  name: 'organizationLeadAvancements',
  getSelector: selectAllOrganizationLeadAvancementsDictionary,
  isReady: selectIsReadyOrganizationLeadAvancement
};

export const selectOrganizationLeadAvancementsEntities = createSelector(
  selectOrganizationLeadAvancementState,
  selectEntities
);

export const selectOrganizationLeadAvancementsArray = createSelector(selectOrganizationLeadAvancementState, selectAll);

export const selectIdOrganizationLeadAvancementsActive = createSelector(
  selectOrganizationLeadAvancementState,
  (state: OrganizationLeadAvancementState) => state.actives
);

const organizationLeadAvancementsInObject = (
  organizationLeadAvancements: Dictionary<OrganizationLeadAvancementEntityState>
) => ({ organizationLeadAvancements });

const selectOrganizationLeadAvancementsEntitiesDictionary = createSelector(
  selectOrganizationLeadAvancementsEntities,
  organizationLeadAvancementsInObject
);

const selectAllOrganizationLeadAvancementsObject = createSelector(
  selectOrganizationLeadAvancementsEntities,
  organizationLeadAvancements => {
    return hydrateAll({ organizationLeadAvancements });
  }
);

const selectOneOrganizationLeadAvancementDictionary = (idOrganizationLeadAvancement: number) =>
  createSelector(selectOrganizationLeadAvancementsEntities, organizationLeadAvancements => {
    return {
      organizationLeadAvancements: {
        [idOrganizationLeadAvancement]: organizationLeadAvancements[idOrganizationLeadAvancement]
      }
    };
  });

const selectOneOrganizationLeadAvancementDictionaryWithoutChild = (idOrganizationLeadAvancement: number) =>
  createSelector(selectOrganizationLeadAvancementsEntities, organizationLeadAvancements => {
    return { organizationLeadAvancement: organizationLeadAvancements[idOrganizationLeadAvancement] };
  });

const selectActiveOrganizationLeadAvancementsEntities = createSelector(
  selectIdOrganizationLeadAvancementsActive,
  selectOrganizationLeadAvancementsEntities,
  (actives: number[], organizationLeadAvancements: Dictionary<OrganizationLeadAvancementEntityState>) =>
    getOrganizationLeadAvancementsFromActives(actives, organizationLeadAvancements)
);

function getOrganizationLeadAvancementsFromActives(
  actives: number[],
  organizationLeadAvancements: Dictionary<OrganizationLeadAvancementEntityState>
): Dictionary<OrganizationLeadAvancementEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationLeadAvancements[idActive]) {
      acc[idActive] = organizationLeadAvancements[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationLeadAvancementEntityState>);
}

const selectAllOrganizationLeadAvancementsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationLeadAvancements(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationLeadAvancement>(
      schema,
      selectAllOrganizationLeadAvancementsSelectors,
      selectOrganizationLeadAvancementsEntitiesDictionary,
      getRelationSelectors,
      organizationLeadAvancementRelations,
      hydrateAll,
      'organizationLeadAvancement'
    );
  } else {
    return selectAllOrganizationLeadAvancementsObject;
  }
}

export function selectAllOrganizationLeadAvancementsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationLeadAvancements'
): Selector {
  return createSelector(selectAllOrganizationLeadAvancements(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationLeadAvancementEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationLeadAvancements.length; i++) {
      res[customKey][result.organizationLeadAvancements[i].idOrganizationLeadAvancement] =
        result.organizationLeadAvancements[i];
    }
    return res;
  });
}

export function selectOneOrganizationLeadAvancement(
  schema: SelectSchema = {},
  idOrganizationLeadAvancement: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationLeadAvancementDictionary(idOrganizationLeadAvancement)];
    selectors.push(...getRelationSelectors(schema, organizationLeadAvancementRelations, 'organizationLeadAvancement'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationLeadAvancementDictionaryWithoutChild(idOrganizationLeadAvancement);
  }
}

export function selectActiveOrganizationLeadAvancements(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationLeadAvancementsEntities, organizationLeadAvancements => ({
      organizationLeadAvancements
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationLeadAvancementRelations, 'organizationLeadAvancement'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationLeadAvancements: Dictionary<OrganizationLeadAvancementEntityState>;
  steps?: Dictionary<StepEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  leads?: Dictionary<LeadEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationLeadAvancements: (OrganizationLeadAvancement | null)[];
} {
  const { organizationLeadAvancements, steps, organizations, leads } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationLeadAvancements: Object.keys(organizationLeadAvancements).map(idOrganizationLeadAvancement =>
      hydrate(
        organizationLeadAvancements[idOrganizationLeadAvancement] as OrganizationLeadAvancementEntityState,
        steps,
        organizations,
        leads
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationLeadAvancement: OrganizationLeadAvancementEntityState | null;
} {
  const { organizationLeadAvancements, steps, organizations, leads } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationLeadAvancement = Object.values(organizationLeadAvancements)[0];
  return {
    organizationLeadAvancement: hydrate(
      organizationLeadAvancement as OrganizationLeadAvancementEntityState,
      steps,
      organizations,
      leads
    )
  };
}

function hydrate(
  organizationLeadAvancement: OrganizationLeadAvancementEntityState,
  stepEntities?: Dictionary<StepEntityState>,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  leadEntities?: Dictionary<LeadEntityState>
): OrganizationLeadAvancement | null {
  if (!organizationLeadAvancement) {
    return null;
  }

  const organizationLeadAvancementHydrated: OrganizationLeadAvancementEntityState = { ...organizationLeadAvancement };
  if (stepEntities) {
    organizationLeadAvancementHydrated.step = stepEntities[organizationLeadAvancement.step as number] as Step;
  } else {
    delete organizationLeadAvancementHydrated.step;
  }
  if (organizationEntities) {
    organizationLeadAvancementHydrated.organization = organizationEntities[
      organizationLeadAvancement.organization as number
    ] as Organization;
  } else {
    delete organizationLeadAvancementHydrated.organization;
  }

  if (leadEntities) {
    organizationLeadAvancementHydrated.leads = ((organizationLeadAvancementHydrated.leads as number[]) || []).map(
      id => leadEntities[id]
    ) as Lead[];
  } else {
    delete organizationLeadAvancementHydrated.leads;
  }

  return organizationLeadAvancementHydrated as OrganizationLeadAvancement;
}
