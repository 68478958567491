import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResidenceSaleCategoryFamilyApiService } from '@_services/api/residence-sale-category-family-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedResidenceSaleCategoryFamilyEffects } from './residence-sale-category-family-generated.effects';

@Injectable()
export class ResidenceSaleCategoryFamilyEffects extends GeneratedResidenceSaleCategoryFamilyEffects {
  constructor(
    actions$: Actions,
    residenceSaleCategoryFamilyApiService: ResidenceSaleCategoryFamilyApiService,
    store$: Store<AppState>
  ) {
    super(actions$, residenceSaleCategoryFamilyApiService, store$);
  }
}
