import { StudySheetPages } from '@_shared/enum/pages/study-page-enum';
import { MenuComponentData } from '@_shared/interfaces/menu-component-data.interface';
import { StudyPathEnum } from '@_utils/router/path-enum/study-path-enum';

export const studyTabMenuItems: MenuComponentData<StudySheetPages> = {
  mainMenuItems: [
    {
      key: StudySheetPages.funnel,
      route: StudyPathEnum.funnel,
      label: 'Segmentation résidences',
      status: 'disabled'
    },
    {
      key: StudySheetPages.residenceStudy,
      route: StudyPathEnum.residenceStudy,
      label: "Résidences à l'étude",
      status: 'disabled'
    }
  ],
  defaultPage: { racine: '', pageKey: StudySheetPages.funnel }
};

export const residenceStudyTabMenuItems: MenuComponentData<StudySheetPages> = {
  mainMenuItems: [
    {
      key: StudySheetPages.details,
      route: StudyPathEnum.details,
      label: 'Caractéristiques',
      status: 'disabled'
    },
    {
      key: StudySheetPages.todosStudy,
      route: StudyPathEnum.todosStudy,
      label: 'Etapes et diag.',
      status: 'disabled'
    },
    {
      key: StudySheetPages.travauxResidenceEtude,
      route: StudyPathEnum.residenceStudyWorks,
      label: 'Travaux',
      status: 'disabled'
    },
    {
      key: StudySheetPages.documentResidenceEtude,
      route: StudyPathEnum.documentResidenceStudy,
      label: 'Documents',
      status: 'disabled'
    }
  ],
  defaultPage: { racine: '', pageKey: StudySheetPages.details }
};
