import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LeadTodo, LeadTodoEntityState } from '@_model/interfaces/lead-todo.model';
import { OrganizationLeadTodo, OrganizationLeadTodoEntityState } from '@_model/interfaces/organization-lead-todo.model';
import { Lead, LeadEntityState } from '@_model/interfaces/lead.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, leadTodoFeatureKey, LeadTodoState } from './lead-todo.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const leadTodoRelations: string[] = ['organizationLeadTodos', 'leads'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectLeadTodoState = createFeatureSelector<LeadTodoState>(leadTodoFeatureKey);

export const selectIsLoadedLeadTodo = createSelector(selectLeadTodoState, (state: LeadTodoState) => state.isLoaded);

export const selectIsLoadingLeadTodo = createSelector(selectLeadTodoState, (state: LeadTodoState) => state.isLoading);

export const selectIsReadyLeadTodo = createSelector(selectLeadTodoState, (state: LeadTodoState) => !state.isLoading);

export const selectIsReadyAndLoadedLeadTodo = createSelector(
  selectLeadTodoState,
  (state: LeadTodoState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const LeadTodoModel: SelectorModel = {
  name: 'leadTodos',
  getSelector: selectAllLeadTodosDictionary,
  isReady: selectIsReadyLeadTodo
};

export const selectLeadTodosEntities = createSelector(selectLeadTodoState, selectEntities);

export const selectLeadTodosArray = createSelector(selectLeadTodoState, selectAll);

export const selectIdLeadTodosActive = createSelector(selectLeadTodoState, (state: LeadTodoState) => state.actives);

const leadTodosInObject = (leadTodos: Dictionary<LeadTodoEntityState>) => ({ leadTodos });

const selectLeadTodosEntitiesDictionary = createSelector(selectLeadTodosEntities, leadTodosInObject);

const selectAllLeadTodosObject = createSelector(selectLeadTodosEntities, leadTodos => {
  return hydrateAll({ leadTodos });
});

const selectOneLeadTodoDictionary = (idLeadTodo: number) =>
  createSelector(selectLeadTodosEntities, leadTodos => {
    return { leadTodos: { [idLeadTodo]: leadTodos[idLeadTodo] } };
  });

const selectOneLeadTodoDictionaryWithoutChild = (idLeadTodo: number) =>
  createSelector(selectLeadTodosEntities, leadTodos => {
    return { leadTodo: leadTodos[idLeadTodo] };
  });

const selectActiveLeadTodosEntities = createSelector(
  selectIdLeadTodosActive,
  selectLeadTodosEntities,
  (actives: number[], leadTodos: Dictionary<LeadTodoEntityState>) => getLeadTodosFromActives(actives, leadTodos)
);

function getLeadTodosFromActives(
  actives: number[],
  leadTodos: Dictionary<LeadTodoEntityState>
): Dictionary<LeadTodoEntityState> {
  return actives.reduce((acc, idActive) => {
    if (leadTodos[idActive]) {
      acc[idActive] = leadTodos[idActive];
    }
    return acc;
  }, {} as Dictionary<LeadTodoEntityState>);
}

const selectAllLeadTodosSelectors: Dictionary<Selector> = {};
export function selectAllLeadTodos(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<LeadTodo>(
      schema,
      selectAllLeadTodosSelectors,
      selectLeadTodosEntitiesDictionary,
      getRelationSelectors,
      leadTodoRelations,
      hydrateAll,
      'leadTodo'
    );
  } else {
    return selectAllLeadTodosObject;
  }
}

export function selectAllLeadTodosDictionary(schema: SelectSchema = {}, customKey: string = 'leadTodos'): Selector {
  return createSelector(selectAllLeadTodos(schema), result => {
    const res = { [customKey]: {} as Dictionary<LeadTodoEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.leadTodos.length; i++) {
      res[customKey][result.leadTodos[i].idLeadTodo] = result.leadTodos[i];
    }
    return res;
  });
}

export function selectOneLeadTodo(schema: SelectSchema = {}, idLeadTodo: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneLeadTodoDictionary(idLeadTodo)];
    selectors.push(...getRelationSelectors(schema, leadTodoRelations, 'leadTodo'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneLeadTodoDictionaryWithoutChild(idLeadTodo);
  }
}

export function selectActiveLeadTodos(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [createSelector(selectActiveLeadTodosEntities, leadTodos => ({ leadTodos }))];
  selectors.push(...getRelationSelectors(schema, leadTodoRelations, 'leadTodo'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  leadTodos: Dictionary<LeadTodoEntityState>;
  organizationLeadTodos?: Dictionary<OrganizationLeadTodoEntityState>;
  leads?: Dictionary<LeadEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { leadTodos: (LeadTodo | null)[] } {
  const { leadTodos, organizationLeadTodos, leads } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    leadTodos: Object.keys(leadTodos).map(idLeadTodo =>
      hydrate(leadTodos[idLeadTodo] as LeadTodoEntityState, organizationLeadTodos, leads)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { leadTodo: LeadTodoEntityState | null } {
  const { leadTodos, organizationLeadTodos, leads } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const leadTodo = Object.values(leadTodos)[0];
  return { leadTodo: hydrate(leadTodo as LeadTodoEntityState, organizationLeadTodos, leads) };
}

function hydrate(
  leadTodo: LeadTodoEntityState,
  organizationLeadTodoEntities?: Dictionary<OrganizationLeadTodoEntityState>,
  leadEntities?: Dictionary<LeadEntityState>
): LeadTodo | null {
  if (!leadTodo) {
    return null;
  }

  const leadTodoHydrated: LeadTodoEntityState = { ...leadTodo };
  if (organizationLeadTodoEntities) {
    leadTodoHydrated.organizationLeadTodo = organizationLeadTodoEntities[
      leadTodo.organizationLeadTodo as number
    ] as OrganizationLeadTodo;
  } else {
    delete leadTodoHydrated.organizationLeadTodo;
  }
  if (leadEntities) {
    leadTodoHydrated.lead = leadEntities[leadTodo.lead as number] as Lead;
  } else {
    delete leadTodoHydrated.lead;
  }

  return leadTodoHydrated as LeadTodo;
}
