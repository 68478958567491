import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationStratalotTodoProfilApiService } from '@_services/api/organization-stratalot-todo-profil-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedOrganizationStratalotTodoProfilEffects } from './organization-stratalot-todo-profil-generated.effects';

@Injectable()
export class OrganizationStratalotTodoProfilEffects extends GeneratedOrganizationStratalotTodoProfilEffects {
  constructor(
    actions$: Actions,
    organizationStratalotTodoProfilApiService: OrganizationStratalotTodoProfilApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationStratalotTodoProfilApiService, store$);
  }
}
