import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceStudyLot } from '@_model/interfaces/residence-study-lot.model';
import { Observable } from 'rxjs';

export class GeneratedResidenceStudyLotApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidenceStudyLots(params?: any): Observable<ResidenceStudyLot[]> {
    return this.repo.getData<ResidenceStudyLot[]>('residence-study-lot', params);
  }

  public getResidenceStudyLot(params: { idResidenceStudyLot: number; params?: any }): Observable<ResidenceStudyLot> {
    return this.repo.getData<ResidenceStudyLot>('residence-study-lot/' + params.idResidenceStudyLot, params.params);
  }

  public addResidenceStudyLot(residenceStudyLot: Partial<ResidenceStudyLot>): Observable<ResidenceStudyLot> {
    return this.repo.create<ResidenceStudyLot>('residence-study-lot', residenceStudyLot);
  }

  public updateResidenceStudyLot(residenceStudyLot: Partial<ResidenceStudyLot>): Observable<ResidenceStudyLot> {
    return this.repo.update('residence-study-lot', residenceStudyLot);
  }

  public deleteResidenceStudyLot(idResidenceStudyLot: number): Observable<number> {
    return this.repo.delete('residence-study-lot/' + +idResidenceStudyLot);
  }
}
