import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MetierFamilieApiService } from '@_services/api/metier-familie-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedMetierFamilieEffects } from './metier-familie-generated.effects';

@Injectable()
export class MetierFamilieEffects extends GeneratedMetierFamilieEffects {
  constructor(actions$: Actions, metierFamilieApiService: MetierFamilieApiService, store$: Store<AppState>) {
    super(actions$, metierFamilieApiService, store$);
  }
}
