import * as AssociationReducer from '@_store/association/association.reducer';
import { AssociationState } from '@_store/association/association.state';
import * as StratalotAssociationReducer from '@_store/stratalot-association/stratalot-association.reducer';
import { StratalotAssociationState } from '@_store/stratalot-association/stratalot-association.state';
import * as BoardStateReducer from '@_store/board-state/board-state.reducer';
import { BoardStateState } from '@_store/board-state/board-state.state';
import * as CampaignMediaReducer from '@_store/campaign-media/campaign-media.reducer';
import { CampaignMediaState } from '@_store/campaign-media/campaign-media.state';
import * as CityReducer from '@_store/city/city.reducer';
import { CityState } from '@_store/city/city.state';
import * as CompanyReducer from '@_store/company/company.reducer';
import { CompanyState } from '@_store/company/company.state';
import * as CompanyUbiflowReducer from '@_store/company-ubiflow/company-ubiflow.reducer';
import { CompanyUbiflowState } from '@_store/company-ubiflow/company-ubiflow.state';
import * as CompanyMyNotaryReducer from '@_store/company-my-notary/company-my-notary.reducer';
import { CompanyMyNotaryState } from '@_store/company-my-notary/company-my-notary.state';
import * as CompanyPriceLabelReducer from '@_store/company-price-label/company-price-label.reducer';
import { CompanyPriceLabelState } from '@_store/company-price-label/company-price-label.state';
import * as CompanyStratalotVacantReducer from '@_store/company-stratalot-vacant/company-stratalot-vacant.reducer';
import { CompanyStratalotVacantState } from '@_store/company-stratalot-vacant/company-stratalot-vacant.state';
import * as CompanyStudyNextActionReducer from '@_store/company-study-next-action/company-study-next-action.reducer';
import { CompanyStudyNextActionState } from '@_store/company-study-next-action/company-study-next-action.state';
import * as CompanyStudyConclusionReducer from '@_store/company-study-conclusion/company-study-conclusion.reducer';
import { CompanyStudyConclusionState } from '@_store/company-study-conclusion/company-study-conclusion.state';
import * as CompanyStudyReasonReducer from '@_store/company-study-reason/company-study-reason.reducer';
import { CompanyStudyReasonState } from '@_store/company-study-reason/company-study-reason.state';
import * as CompanyStudyCriteriaReducer from '@_store/company-study-criteria/company-study-criteria.reducer';
import { CompanyStudyCriteriaState } from '@_store/company-study-criteria/company-study-criteria.state';
import * as CompanyTerritoireReducer from '@_store/company-territoire/company-territoire.reducer';
import { CompanyTerritoireState } from '@_store/company-territoire/company-territoire.state';
import * as CompanyTerritoireUserReducer from '@_store/company-territoire-user/company-territoire-user.reducer';
import { CompanyTerritoireUserState } from '@_store/company-territoire-user/company-territoire-user.state';
import * as DiagnosticReducer from '@_store/diagnostic/diagnostic.reducer';
import { DiagnosticState } from '@_store/diagnostic/diagnostic.state';
import * as DiffusionVisualReducer from '@_store/diffusion-visual/diffusion-visual.reducer';
import { DiffusionVisualState } from '@_store/diffusion-visual/diffusion-visual.state';
import * as DroitReducer from '@_store/droit/droit.reducer';
import { DroitState } from '@_store/droit/droit.state';
import * as GeneratedDocumentReducer from '@_store/generated-document/generated-document.reducer';
import { GeneratedDocumentState } from '@_store/generated-document/generated-document.state';
import * as GeneratedDocumentsCompanyReducer from '@_store/generated-documents-company/generated-documents-company.reducer';
import { GeneratedDocumentsCompanyState } from '@_store/generated-documents-company/generated-documents-company.state';
import * as GeneratedDocumentsResidenceReducer from '@_store/generated-documents-residence/generated-documents-residence.reducer';
import { GeneratedDocumentsResidenceState } from '@_store/generated-documents-residence/generated-documents-residence.state';
import * as LeadReducer from '@_store/lead/lead.reducer';
import { LeadState } from '@_store/lead/lead.state';
import * as LeadStratalotReducer from '@_store/lead-stratalot/lead-stratalot.reducer';
import { LeadStratalotState } from '@_store/lead-stratalot/lead-stratalot.state';
import * as LeadTodoReducer from '@_store/lead-todo/lead-todo.reducer';
import { LeadTodoState } from '@_store/lead-todo/lead-todo.state';
import * as OccupantReducer from '@_store/occupant/occupant.reducer';
import { OccupantState } from '@_store/occupant/occupant.state';
import * as OrganizationReducer from '@_store/organization/organization.reducer';
import { OrganizationState } from '@_store/organization/organization.state';
import * as OrganizationMetierReducer from '@_store/organization-metier/organization-metier.reducer';
import { OrganizationMetierState } from '@_store/organization-metier/organization-metier.state';
import * as OrganizationBuyingWishReducer from '@_store/organization-buying-wish/organization-buying-wish.reducer';
import { OrganizationBuyingWishState } from '@_store/organization-buying-wish/organization-buying-wish.state';
import * as OrganizationEnergieReducer from '@_store/organization-energie/organization-energie.reducer';
import { OrganizationEnergieState } from '@_store/organization-energie/organization-energie.state';
import * as OrganizationLeadAvancementReducer from '@_store/organization-lead-avancement/organization-lead-avancement.reducer';
import { OrganizationLeadAvancementState } from '@_store/organization-lead-avancement/organization-lead-avancement.state';
import * as OrganizationLeadTodoReducer from '@_store/organization-lead-todo/organization-lead-todo.reducer';
import { OrganizationLeadTodoState } from '@_store/organization-lead-todo/organization-lead-todo.state';
import * as OrganizationLeadTodoProfilReducer from '@_store/organization-lead-todo-profil/organization-lead-todo-profil.reducer';
import { OrganizationLeadTodoProfilState } from '@_store/organization-lead-todo-profil/organization-lead-todo-profil.state';
import * as OrganizationResidenceTodoProfilReducer from '@_store/organization-residence-todo-profil/organization-residence-todo-profil.reducer';
import { OrganizationResidenceTodoProfilState } from '@_store/organization-residence-todo-profil/organization-residence-todo-profil.state';
import * as OrganizationStratalotTodoProfilReducer from '@_store/organization-stratalot-todo-profil/organization-stratalot-todo-profil.reducer';
import { OrganizationStratalotTodoProfilState } from '@_store/organization-stratalot-todo-profil/organization-stratalot-todo-profil.state';
import * as OrganizationLeadTodoRuleReducer from '@_store/organization-lead-todo-rule/organization-lead-todo-rule.reducer';
import { OrganizationLeadTodoRuleState } from '@_store/organization-lead-todo-rule/organization-lead-todo-rule.state';
import * as OrganizationLeadTodoAppliedRuleReducer from '@_store/organization-lead-todo-applied-rule/organization-lead-todo-applied-rule.reducer';
import { OrganizationLeadTodoAppliedRuleState } from '@_store/organization-lead-todo-applied-rule/organization-lead-todo-applied-rule.state';
import * as CompanyMediaReducer from '@_store/company-media/company-media.reducer';
import { CompanyMediaState } from '@_store/company-media/company-media.state';
import * as OrganizationProfilReducer from '@_store/organization-profil/organization-profil.reducer';
import { OrganizationProfilState } from '@_store/organization-profil/organization-profil.state';
import * as CompanyCommunicationReducer from '@_store/company-communication/company-communication.reducer';
import { CompanyCommunicationState } from '@_store/company-communication/company-communication.state';
import * as CompanyCommunicationResidenceTodoReducer from '@_store/company-communication-residence-todo/company-communication-residence-todo.reducer';
import { CompanyCommunicationResidenceTodoState } from '@_store/company-communication-residence-todo/company-communication-residence-todo.state';
import * as CompanyCommunicationStratalotTodoReducer from '@_store/company-communication-stratalot-todo/company-communication-stratalot-todo.reducer';
import { CompanyCommunicationStratalotTodoState } from '@_store/company-communication-stratalot-todo/company-communication-stratalot-todo.state';
import * as CompanyCommunicationLeadTodoReducer from '@_store/company-communication-lead-todo/company-communication-lead-todo.reducer';
import { CompanyCommunicationLeadTodoState } from '@_store/company-communication-lead-todo/company-communication-lead-todo.state';
import * as CompanyCommunicationFileReducer from '@_store/company-communication-file/company-communication-file.reducer';
import { CompanyCommunicationFileState } from '@_store/company-communication-file/company-communication-file.state';
import * as CompanyCommunicationMediaReducer from '@_store/company-communication-media/company-communication-media.reducer';
import { CompanyCommunicationMediaState } from '@_store/company-communication-media/company-communication-media.state';
import * as CompanyCommunicationRecipientReducer from '@_store/company-communication-recipient/company-communication-recipient.reducer';
import { CompanyCommunicationRecipientState } from '@_store/company-communication-recipient/company-communication-recipient.state';
import * as CompanyCommunicationRecipientMetierReducer from '@_store/company-communication-recipient-metier/company-communication-recipient-metier.reducer';
import { CompanyCommunicationRecipientMetierState } from '@_store/company-communication-recipient-metier/company-communication-recipient-metier.state';
import * as CompanyCommunicationRecipientUserReducer from '@_store/company-communication-recipient-user/company-communication-recipient-user.reducer';
import { CompanyCommunicationRecipientUserState } from '@_store/company-communication-recipient-user/company-communication-recipient-user.state';
import * as CompanyCommunicationGeneratedDocumentReducer from '@_store/company-communication-generated-document/company-communication-generated-document.reducer';
import { CompanyCommunicationGeneratedDocumentState } from '@_store/company-communication-generated-document/company-communication-generated-document.state';
import * as OrganizationResidenceTodoReducer from '@_store/organization-residence-todo/organization-residence-todo.reducer';
import { OrganizationResidenceTodoState } from '@_store/organization-residence-todo/organization-residence-todo.state';
import * as OrganizationResidenceTodoRuleReducer from '@_store/organization-residence-todo-rule/organization-residence-todo-rule.reducer';
import { OrganizationResidenceTodoRuleState } from '@_store/organization-residence-todo-rule/organization-residence-todo-rule.state';
import * as OrganizationResidenceTodoAppliedRuleReducer from '@_store/organization-residence-todo-applied-rule/organization-residence-todo-applied-rule.reducer';
import { OrganizationResidenceTodoAppliedRuleState } from '@_store/organization-residence-todo-applied-rule/organization-residence-todo-applied-rule.state';
import * as OrganizationSaleCategoryReducer from '@_store/organization-sale-category/organization-sale-category.reducer';
import { OrganizationSaleCategoryState } from '@_store/organization-sale-category/organization-sale-category.state';
import * as OrganizationStratalotAvancementReducer from '@_store/organization-stratalot-avancement/organization-stratalot-avancement.reducer';
import { OrganizationStratalotAvancementState } from '@_store/organization-stratalot-avancement/organization-stratalot-avancement.state';
import * as OrganizationStratalotTodoReducer from '@_store/organization-stratalot-todo/organization-stratalot-todo.reducer';
import { OrganizationStratalotTodoState } from '@_store/organization-stratalot-todo/organization-stratalot-todo.state';
import * as OrganizationResidenceStudyTodoReducer from '@_store/organization-residence-study-todo/organization-residence-study-todo.reducer';
import { OrganizationResidenceStudyTodoState } from '@_store/organization-residence-study-todo/organization-residence-study-todo.state';
import * as OrganizationStratalotTodoRuleReducer from '@_store/organization-stratalot-todo-rule/organization-stratalot-todo-rule.reducer';
import { OrganizationStratalotTodoRuleState } from '@_store/organization-stratalot-todo-rule/organization-stratalot-todo-rule.state';
import * as OrganizationStratalotTodoAppliedRuleReducer from '@_store/organization-stratalot-todo-applied-rule/organization-stratalot-todo-applied-rule.reducer';
import { OrganizationStratalotTodoAppliedRuleState } from '@_store/organization-stratalot-todo-applied-rule/organization-stratalot-todo-applied-rule.state';
import * as OrganizationSocieteReducer from '@_store/organization-societe/organization-societe.reducer';
import { OrganizationSocieteState } from '@_store/organization-societe/organization-societe.state';
import * as CompanyStratalotTypeReducer from '@_store/company-stratalot-type/company-stratalot-type.reducer';
import { CompanyStratalotTypeState } from '@_store/company-stratalot-type/company-stratalot-type.state';
import * as OrganizationThirdPartyReducer from '@_store/organization-third-party/organization-third-party.reducer';
import { OrganizationThirdPartyState } from '@_store/organization-third-party/organization-third-party.state';
import * as OrganizationProspectOriginReducer from '@_store/organization-prospect-origin/organization-prospect-origin.reducer';
import { OrganizationProspectOriginState } from '@_store/organization-prospect-origin/organization-prospect-origin.state';
import * as ProspectReducer from '@_store/prospect/prospect.reducer';
import { ProspectState } from '@_store/prospect/prospect.state';
import * as ProspectBuyingWishReducer from '@_store/prospect-buying-wish/prospect-buying-wish.reducer';
import { ProspectBuyingWishState } from '@_store/prospect-buying-wish/prospect-buying-wish.state';
import * as ProspectEventReducer from '@_store/prospect-event/prospect-event.reducer';
import { ProspectEventState } from '@_store/prospect-event/prospect-event.state';
import * as ProfilReducer from '@_store/profil/profil.reducer';
import { ProfilState } from '@_store/profil/profil.state';
import * as ResidenceReducer from '@_store/residence/residence.reducer';
import { ResidenceState } from '@_store/residence/residence.state';
import * as ResidenceContactReducer from '@_store/residence-contact/residence-contact.reducer';
import { ResidenceContactState } from '@_store/residence-contact/residence-contact.state';
import * as ResidenceShareReducer from '@_store/residence-share/residence-share.reducer';
import { ResidenceShareState } from '@_store/residence-share/residence-share.state';
import * as ResidenceShareRcpReducer from '@_store/residence-share-rcp/residence-share-rcp.reducer';
import { ResidenceShareRcpState } from '@_store/residence-share-rcp/residence-share-rcp.state';
import * as ResidenceStudyReducer from '@_store/residence-study/residence-study.reducer';
import { ResidenceStudyState } from '@_store/residence-study/residence-study.state';
import * as ResidenceStudyCriteriaReducer from '@_store/residence-study-criteria/residence-study-criteria.reducer';
import { ResidenceStudyCriteriaState } from '@_store/residence-study-criteria/residence-study-criteria.state';
import * as ResidenceStudyWorkReducer from '@_store/residence-study-work/residence-study-work.reducer';
import { ResidenceStudyWorkState } from '@_store/residence-study-work/residence-study-work.state';
import * as ResidenceStudyLotReducer from '@_store/residence-study-lot/residence-study-lot.reducer';
import { ResidenceStudyLotState } from '@_store/residence-study-lot/residence-study-lot.state';
import * as ResidenceStudyFoundingReducer from '@_store/residence-study-founding/residence-study-founding.reducer';
import { ResidenceStudyFoundingState } from '@_store/residence-study-founding/residence-study-founding.state';
import * as ResidenceStudyReasonReducer from '@_store/residence-study-reason/residence-study-reason.reducer';
import { ResidenceStudyReasonState } from '@_store/residence-study-reason/residence-study-reason.state';
import * as ResidenceTodoReducer from '@_store/residence-todo/residence-todo.reducer';
import { ResidenceTodoState } from '@_store/residence-todo/residence-todo.state';
import * as ResidenceWorkReducer from '@_store/residence-work/residence-work.reducer';
import { ResidenceWorkState } from '@_store/residence-work/residence-work.state';
import * as ResidenceSaleCategoryFamilyReducer from '@_store/residence-sale-category-family/residence-sale-category-family.reducer';
import { ResidenceSaleCategoryFamilyState } from '@_store/residence-sale-category-family/residence-sale-category-family.state';
import * as SaleCategoryFamilyReducer from '@_store/sale-category-family/sale-category-family.reducer';
import { SaleCategoryFamilyState } from '@_store/sale-category-family/sale-category-family.state';
import * as StepReducer from '@_store/step/step.reducer';
import { StepState } from '@_store/step/step.state';
import * as StepProgressReducer from '@_store/step-progress/step-progress.reducer';
import { StepProgressState } from '@_store/step-progress/step-progress.state';
import * as StepProgressFamilyReducer from '@_store/step-progress-family/step-progress-family.reducer';
import { StepProgressFamilyState } from '@_store/step-progress-family/step-progress-family.state';
import * as StratalotFeeReducer from '@_store/stratalot-fee/stratalot-fee.reducer';
import { StratalotFeeState } from '@_store/stratalot-fee/stratalot-fee.state';
import * as StratalotReducer from '@_store/stratalot/stratalot.reducer';
import { StratalotState } from '@_store/stratalot/stratalot.state';
import * as StratalotCampaignReducer from '@_store/stratalot-campaign/stratalot-campaign.reducer';
import { StratalotCampaignState } from '@_store/stratalot-campaign/stratalot-campaign.state';
import * as StratalotPriceReducer from '@_store/stratalot-price/stratalot-price.reducer';
import { StratalotPriceState } from '@_store/stratalot-price/stratalot-price.state';
import * as ResidencePriceGridReducer from '@_store/residence-price-grid/residence-price-grid.reducer';
import { ResidencePriceGridState } from '@_store/residence-price-grid/residence-price-grid.state';
import * as StratalotPriceListReducer from '@_store/stratalot-price-list/stratalot-price-list.reducer';
import { StratalotPriceListState } from '@_store/stratalot-price-list/stratalot-price-list.state';
import * as ResidencePriceGridModifierReducer from '@_store/residence-price-grid-modifier/residence-price-grid-modifier.reducer';
import { ResidencePriceGridModifierState } from '@_store/residence-price-grid-modifier/residence-price-grid-modifier.state';
import * as ResidencePriceGridHistoryReducer from '@_store/residence-price-grid-history/residence-price-grid-history.reducer';
import { ResidencePriceGridHistoryState } from '@_store/residence-price-grid-history/residence-price-grid-history.state';
import * as StratalotPriceValueReducer from '@_store/stratalot-price-value/stratalot-price-value.reducer';
import { StratalotPriceValueState } from '@_store/stratalot-price-value/stratalot-price-value.state';
import * as StratalotRcpReducer from '@_store/stratalot-rcp/stratalot-rcp.reducer';
import { StratalotRcpState } from '@_store/stratalot-rcp/stratalot-rcp.state';
import * as StratalotTodoReducer from '@_store/stratalot-todo/stratalot-todo.reducer';
import { StratalotTodoState } from '@_store/stratalot-todo/stratalot-todo.state';
import * as ResidenceStudyTodoReducer from '@_store/residence-study-todo/residence-study-todo.reducer';
import { ResidenceStudyTodoState } from '@_store/residence-study-todo/residence-study-todo.state';
import * as StratalotTypeReducer from '@_store/stratalot-type/stratalot-type.reducer';
import { StratalotTypeState } from '@_store/stratalot-type/stratalot-type.state';
import * as UserReducer from '@_store/user/user.reducer';
import { UserState } from '@_store/user/user.state';
import * as ForecastPeriodReducer from '@_store/forecast-period/forecast-period.reducer';
import { ForecastPeriodState } from '@_store/forecast-period/forecast-period.state';
import * as OrganizationForecastRateReducer from '@_store/organization-forecast-rate/organization-forecast-rate.reducer';
import { OrganizationForecastRateState } from '@_store/organization-forecast-rate/organization-forecast-rate.state';
import * as ResidenceForecastReducer from '@_store/residence-forecast/residence-forecast.reducer';
import { ResidenceForecastState } from '@_store/residence-forecast/residence-forecast.state';
import * as ResidenceForecastValueReducer from '@_store/residence-forecast-value/residence-forecast-value.reducer';
import { ResidenceForecastValueState } from '@_store/residence-forecast-value/residence-forecast-value.state';
import * as MetierFamilieReducer from '@_store/metier-familie/metier-familie.reducer';
import { MetierFamilieState } from '@_store/metier-familie/metier-familie.state';

export interface GeneratedAppState {
  association: AssociationState;
  stratalotAssociation: StratalotAssociationState;
  boardState: BoardStateState;
  campaignMedia: CampaignMediaState;
  city: CityState;
  company: CompanyState;
  companyUbiflow: CompanyUbiflowState;
  companyMyNotary: CompanyMyNotaryState;
  companyPriceLabel: CompanyPriceLabelState;
  companyStratalotVacant: CompanyStratalotVacantState;
  companyStudyNextAction: CompanyStudyNextActionState;
  companyStudyConclusion: CompanyStudyConclusionState;
  companyStudyReason: CompanyStudyReasonState;
  companyStudyCriteria: CompanyStudyCriteriaState;
  companyTerritoire: CompanyTerritoireState;
  companyTerritoireUser: CompanyTerritoireUserState;
  diagnostic: DiagnosticState;
  diffusionVisual: DiffusionVisualState;
  droit: DroitState;
  generatedDocument: GeneratedDocumentState;
  generatedDocumentsCompany: GeneratedDocumentsCompanyState;
  generatedDocumentsResidence: GeneratedDocumentsResidenceState;
  lead: LeadState;
  leadStratalot: LeadStratalotState;
  leadTodo: LeadTodoState;
  occupant: OccupantState;
  organization: OrganizationState;
  organizationMetier: OrganizationMetierState;
  organizationBuyingWish: OrganizationBuyingWishState;
  organizationEnergie: OrganizationEnergieState;
  organizationLeadAvancement: OrganizationLeadAvancementState;
  organizationLeadTodo: OrganizationLeadTodoState;
  organizationLeadTodoProfil: OrganizationLeadTodoProfilState;
  organizationResidenceTodoProfil: OrganizationResidenceTodoProfilState;
  organizationStratalotTodoProfil: OrganizationStratalotTodoProfilState;
  organizationLeadTodoRule: OrganizationLeadTodoRuleState;
  organizationLeadTodoAppliedRule: OrganizationLeadTodoAppliedRuleState;
  companyMedia: CompanyMediaState;
  organizationProfil: OrganizationProfilState;
  companyCommunication: CompanyCommunicationState;
  companyCommunicationResidenceTodo: CompanyCommunicationResidenceTodoState;
  companyCommunicationStratalotTodo: CompanyCommunicationStratalotTodoState;
  companyCommunicationLeadTodo: CompanyCommunicationLeadTodoState;
  companyCommunicationFile: CompanyCommunicationFileState;
  companyCommunicationMedia: CompanyCommunicationMediaState;
  companyCommunicationRecipient: CompanyCommunicationRecipientState;
  companyCommunicationRecipientMetier: CompanyCommunicationRecipientMetierState;
  companyCommunicationRecipientUser: CompanyCommunicationRecipientUserState;
  companyCommunicationGeneratedDocument: CompanyCommunicationGeneratedDocumentState;
  organizationResidenceTodo: OrganizationResidenceTodoState;
  organizationResidenceTodoRule: OrganizationResidenceTodoRuleState;
  organizationResidenceTodoAppliedRule: OrganizationResidenceTodoAppliedRuleState;
  organizationSaleCategory: OrganizationSaleCategoryState;
  organizationStratalotAvancement: OrganizationStratalotAvancementState;
  organizationStratalotTodo: OrganizationStratalotTodoState;
  organizationResidenceStudyTodo: OrganizationResidenceStudyTodoState;
  organizationStratalotTodoRule: OrganizationStratalotTodoRuleState;
  organizationStratalotTodoAppliedRule: OrganizationStratalotTodoAppliedRuleState;
  organizationSociete: OrganizationSocieteState;
  companyStratalotType: CompanyStratalotTypeState;
  organizationThirdParty: OrganizationThirdPartyState;
  organizationProspectOrigin: OrganizationProspectOriginState;
  prospect: ProspectState;
  prospectBuyingWish: ProspectBuyingWishState;
  prospectEvent: ProspectEventState;
  profil: ProfilState;
  residence: ResidenceState;
  residenceContact: ResidenceContactState;
  residenceShare: ResidenceShareState;
  residenceShareRcp: ResidenceShareRcpState;
  residenceStudy: ResidenceStudyState;
  residenceStudyCriteria: ResidenceStudyCriteriaState;
  residenceStudyWork: ResidenceStudyWorkState;
  residenceStudyLot: ResidenceStudyLotState;
  residenceStudyFounding: ResidenceStudyFoundingState;
  residenceStudyReason: ResidenceStudyReasonState;
  residenceTodo: ResidenceTodoState;
  residenceWork: ResidenceWorkState;
  residenceSaleCategoryFamily: ResidenceSaleCategoryFamilyState;
  saleCategoryFamily: SaleCategoryFamilyState;
  step: StepState;
  stepProgress: StepProgressState;
  stepProgressFamily: StepProgressFamilyState;
  stratalotFee: StratalotFeeState;
  stratalot: StratalotState;
  stratalotCampaign: StratalotCampaignState;
  stratalotPrice: StratalotPriceState;
  residencePriceGrid: ResidencePriceGridState;
  stratalotPriceList: StratalotPriceListState;
  residencePriceGridModifier: ResidencePriceGridModifierState;
  residencePriceGridHistory: ResidencePriceGridHistoryState;
  stratalotPriceValue: StratalotPriceValueState;
  stratalotRcp: StratalotRcpState;
  stratalotTodo: StratalotTodoState;
  residenceStudyTodo: ResidenceStudyTodoState;
  stratalotType: StratalotTypeState;
  user: UserState;
  forecastPeriod: ForecastPeriodState;
  organizationForecastRate: OrganizationForecastRateState;
  residenceForecast: ResidenceForecastState;
  residenceForecastValue: ResidenceForecastValueState;
  metierFamilie: MetierFamilieState;
}

export const generatedAppState = {
  association: AssociationReducer.reducer,
  stratalotAssociation: StratalotAssociationReducer.reducer,
  boardState: BoardStateReducer.reducer,
  campaignMedia: CampaignMediaReducer.reducer,
  city: CityReducer.reducer,
  company: CompanyReducer.reducer,
  companyUbiflow: CompanyUbiflowReducer.reducer,
  companyMyNotary: CompanyMyNotaryReducer.reducer,
  companyPriceLabel: CompanyPriceLabelReducer.reducer,
  companyStratalotVacant: CompanyStratalotVacantReducer.reducer,
  companyStudyNextAction: CompanyStudyNextActionReducer.reducer,
  companyStudyConclusion: CompanyStudyConclusionReducer.reducer,
  companyStudyReason: CompanyStudyReasonReducer.reducer,
  companyStudyCriteria: CompanyStudyCriteriaReducer.reducer,
  companyTerritoire: CompanyTerritoireReducer.reducer,
  companyTerritoireUser: CompanyTerritoireUserReducer.reducer,
  diagnostic: DiagnosticReducer.reducer,
  diffusionVisual: DiffusionVisualReducer.reducer,
  droit: DroitReducer.reducer,
  generatedDocument: GeneratedDocumentReducer.reducer,
  generatedDocumentsCompany: GeneratedDocumentsCompanyReducer.reducer,
  generatedDocumentsResidence: GeneratedDocumentsResidenceReducer.reducer,
  lead: LeadReducer.reducer,
  leadStratalot: LeadStratalotReducer.reducer,
  leadTodo: LeadTodoReducer.reducer,
  occupant: OccupantReducer.reducer,
  organization: OrganizationReducer.reducer,
  organizationMetier: OrganizationMetierReducer.reducer,
  organizationBuyingWish: OrganizationBuyingWishReducer.reducer,
  organizationEnergie: OrganizationEnergieReducer.reducer,
  organizationLeadAvancement: OrganizationLeadAvancementReducer.reducer,
  organizationLeadTodo: OrganizationLeadTodoReducer.reducer,
  organizationLeadTodoProfil: OrganizationLeadTodoProfilReducer.reducer,
  organizationResidenceTodoProfil: OrganizationResidenceTodoProfilReducer.reducer,
  organizationStratalotTodoProfil: OrganizationStratalotTodoProfilReducer.reducer,
  organizationLeadTodoRule: OrganizationLeadTodoRuleReducer.reducer,
  organizationLeadTodoAppliedRule: OrganizationLeadTodoAppliedRuleReducer.reducer,
  companyMedia: CompanyMediaReducer.reducer,
  organizationProfil: OrganizationProfilReducer.reducer,
  companyCommunication: CompanyCommunicationReducer.reducer,
  companyCommunicationResidenceTodo: CompanyCommunicationResidenceTodoReducer.reducer,
  companyCommunicationStratalotTodo: CompanyCommunicationStratalotTodoReducer.reducer,
  companyCommunicationLeadTodo: CompanyCommunicationLeadTodoReducer.reducer,
  companyCommunicationFile: CompanyCommunicationFileReducer.reducer,
  companyCommunicationMedia: CompanyCommunicationMediaReducer.reducer,
  companyCommunicationRecipient: CompanyCommunicationRecipientReducer.reducer,
  companyCommunicationRecipientMetier: CompanyCommunicationRecipientMetierReducer.reducer,
  companyCommunicationRecipientUser: CompanyCommunicationRecipientUserReducer.reducer,
  companyCommunicationGeneratedDocument: CompanyCommunicationGeneratedDocumentReducer.reducer,
  organizationResidenceTodo: OrganizationResidenceTodoReducer.reducer,
  organizationResidenceTodoRule: OrganizationResidenceTodoRuleReducer.reducer,
  organizationResidenceTodoAppliedRule: OrganizationResidenceTodoAppliedRuleReducer.reducer,
  organizationSaleCategory: OrganizationSaleCategoryReducer.reducer,
  organizationStratalotAvancement: OrganizationStratalotAvancementReducer.reducer,
  organizationStratalotTodo: OrganizationStratalotTodoReducer.reducer,
  organizationResidenceStudyTodo: OrganizationResidenceStudyTodoReducer.reducer,
  organizationStratalotTodoRule: OrganizationStratalotTodoRuleReducer.reducer,
  organizationStratalotTodoAppliedRule: OrganizationStratalotTodoAppliedRuleReducer.reducer,
  organizationSociete: OrganizationSocieteReducer.reducer,
  companyStratalotType: CompanyStratalotTypeReducer.reducer,
  organizationThirdParty: OrganizationThirdPartyReducer.reducer,
  organizationProspectOrigin: OrganizationProspectOriginReducer.reducer,
  prospect: ProspectReducer.reducer,
  prospectBuyingWish: ProspectBuyingWishReducer.reducer,
  prospectEvent: ProspectEventReducer.reducer,
  profil: ProfilReducer.reducer,
  residence: ResidenceReducer.reducer,
  residenceContact: ResidenceContactReducer.reducer,
  residenceShare: ResidenceShareReducer.reducer,
  residenceShareRcp: ResidenceShareRcpReducer.reducer,
  residenceStudy: ResidenceStudyReducer.reducer,
  residenceStudyCriteria: ResidenceStudyCriteriaReducer.reducer,
  residenceStudyWork: ResidenceStudyWorkReducer.reducer,
  residenceStudyLot: ResidenceStudyLotReducer.reducer,
  residenceStudyFounding: ResidenceStudyFoundingReducer.reducer,
  residenceStudyReason: ResidenceStudyReasonReducer.reducer,
  residenceTodo: ResidenceTodoReducer.reducer,
  residenceWork: ResidenceWorkReducer.reducer,
  residenceSaleCategoryFamily: ResidenceSaleCategoryFamilyReducer.reducer,
  saleCategoryFamily: SaleCategoryFamilyReducer.reducer,
  step: StepReducer.reducer,
  stepProgress: StepProgressReducer.reducer,
  stepProgressFamily: StepProgressFamilyReducer.reducer,
  stratalotFee: StratalotFeeReducer.reducer,
  stratalot: StratalotReducer.reducer,
  stratalotCampaign: StratalotCampaignReducer.reducer,
  stratalotPrice: StratalotPriceReducer.reducer,
  residencePriceGrid: ResidencePriceGridReducer.reducer,
  stratalotPriceList: StratalotPriceListReducer.reducer,
  residencePriceGridModifier: ResidencePriceGridModifierReducer.reducer,
  residencePriceGridHistory: ResidencePriceGridHistoryReducer.reducer,
  stratalotPriceValue: StratalotPriceValueReducer.reducer,
  stratalotRcp: StratalotRcpReducer.reducer,
  stratalotTodo: StratalotTodoReducer.reducer,
  residenceStudyTodo: ResidenceStudyTodoReducer.reducer,
  stratalotType: StratalotTypeReducer.reducer,
  user: UserReducer.reducer,
  forecastPeriod: ForecastPeriodReducer.reducer,
  organizationForecastRate: OrganizationForecastRateReducer.reducer,
  residenceForecast: ResidenceForecastReducer.reducer,
  residenceForecastValue: ResidenceForecastValueReducer.reducer,
  metierFamilie: MetierFamilieReducer.reducer
};
