import { LeadTodoState, initialState } from './lead-todo.state';
import { Action, createReducer } from '@ngrx/store';
import { leadTodoReducersGeneratedFunctions } from './lead-todo-generated.reducer';

const leadTodoReducersFunctions = [...leadTodoReducersGeneratedFunctions];

const leadTodoReducer = createReducer(initialState, ...leadTodoReducersFunctions);

export function reducer(state: LeadTodoState | undefined, action: Action) {
  return leadTodoReducer(state, action);
}
