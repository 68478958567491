import { RepositoryService } from '@_services/api/repository.service';
import { StratalotFee } from '@_model/interfaces/stratalot-fee.model';
import { Observable } from 'rxjs';

export class GeneratedStratalotFeeApiService {
  constructor(protected repo: RepositoryService) {}

  public getStratalotFees(params?: any): Observable<StratalotFee[]> {
    return this.repo.getData<StratalotFee[]>('stratalot-fee', params);
  }

  public getStratalotFee(params: { idStratalotFee: number; params?: any }): Observable<StratalotFee> {
    return this.repo.getData<StratalotFee>('stratalot-fee/' + params.idStratalotFee, params.params);
  }

  public addStratalotFee(stratalotFee: Partial<StratalotFee>): Observable<StratalotFee> {
    return this.repo.create<StratalotFee>('stratalot-fee', stratalotFee);
  }

  public updateStratalotFee(stratalotFee: Partial<StratalotFee>): Observable<StratalotFee> {
    return this.repo.update('stratalot-fee', stratalotFee);
  }

  public deleteStratalotFee(idStratalotFee: number): Observable<number> {
    return this.repo.delete('stratalot-fee/' + +idStratalotFee);
  }
}
