import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyStudyCriteriaEntityState } from '@_model/interfaces/company-study-criteria.model';

export interface CompanyStudyCriteriaState extends EntityState<CompanyStudyCriteriaEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyStudyCriteriaEntityState> =
  createEntityAdapter<CompanyStudyCriteriaEntityState>({
    selectId: o => o.idCompanyStudyCriteria
  });
export const initialState: CompanyStudyCriteriaState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyStudyCriteriaFeatureKey = 'companyStudyCriteria';
