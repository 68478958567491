import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedCompanyCommunicationGeneratedDocumentApiService } from './company-communication-generated-document-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyCommunicationGeneratedDocumentApiService extends GeneratedCompanyCommunicationGeneratedDocumentApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
