import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationBuyingWishApiService } from '@_services/api/organization-buying-wish-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedOrganizationBuyingWishEffects } from './organization-buying-wish-generated.effects';

@Injectable()
export class OrganizationBuyingWishEffects extends GeneratedOrganizationBuyingWishEffects {
  constructor(
    actions$: Actions,
    organizationBuyingWishApiService: OrganizationBuyingWishApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationBuyingWishApiService, store$);
  }
}
