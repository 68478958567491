import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedResidenceStudyWorkApiService } from './residence-study-work-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ResidenceStudyWorkApiService extends GeneratedResidenceStudyWorkApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
