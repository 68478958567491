import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationSociete } from '@_model/interfaces/organization-societe.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationSocieteApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationSocietes(params?: any): Observable<OrganizationSociete[]> {
    return this.repo.getData<OrganizationSociete[]>('organization-societe', params);
  }

  public getOrganizationSociete(params: {
    idOrganizationSociete: number;
    params?: any;
  }): Observable<OrganizationSociete> {
    return this.repo.getData<OrganizationSociete>(
      'organization-societe/' + params.idOrganizationSociete,
      params.params
    );
  }

  public addOrganizationSociete(organizationSociete: Partial<OrganizationSociete>): Observable<OrganizationSociete> {
    return this.repo.create<OrganizationSociete>('organization-societe', organizationSociete);
  }

  public updateOrganizationSociete(organizationSociete: Partial<OrganizationSociete>): Observable<OrganizationSociete> {
    return this.repo.update('organization-societe', organizationSociete);
  }

  public deleteOrganizationSociete(idOrganizationSociete: number): Observable<number> {
    return this.repo.delete('organization-societe/' + +idOrganizationSociete);
  }
}
