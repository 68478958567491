import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { BoardState } from '@_model/interfaces/board-state.model';
import * as AppState from '@_store/index.reducers';
import { BoardStateRelationsIds } from '@_store/board-state/board-state-generated.effects';
import * as BoardStateAction from '@_store/board-state/board-state.actions';
import {
  selectActiveBoardStates,
  selectAllBoardStates,
  selectIdBoardStatesActive,
  selectIsLoadedBoardState,
  selectIsLoadingBoardState,
  selectIsReadyAndLoadedBoardState,
  selectOneBoardState
} from '@_store/board-state/board-state.selectors';
import { catchApiActions } from '@_utils/http.util';
import { getIsReadySelectors, Selector, SelectSchema } from '@_utils/selector.util';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedBoardStateService {
  constructor(protected store$: Store<AppState.AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadedBoardState));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadingBoardState));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [selectIsReadyAndLoadedBoardState as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllBoardStates(schema: SelectSchema = {}): Observable<BoardState[]> {
    return this.store$.pipe(select(selectAllBoardStates(schema))).pipe(
      switchMap(({ boardStates }: { boardStates: BoardState[] }) => {
        return this.getReady(schema).pipe(mapTo(boardStates));
      })
    );
  }

  public selectOneBoardState(idBoardState: number, schema: SelectSchema = {}): Observable<BoardState> {
    return this.store$.pipe(select(selectOneBoardState(schema, idBoardState))).pipe(
      switchMap(({ boardState }: { boardState: BoardState }) => {
        return this.getReady(schema).pipe(mapTo(boardState));
      })
    );
  }

  public selectAllActiveBoardStates(schema: SelectSchema = {}): Observable<BoardState[]> {
    return this.store$.pipe(select(selectActiveBoardStates(schema))).pipe(
      switchMap(({ boardStates }: { boardStates: BoardState[] }) => {
        return this.getReady(schema).pipe(mapTo(boardStates));
      })
    );
  }

  public selectIdBoardStatesActive(): Observable<number[]> {
    return this.store$.pipe(select(selectIdBoardStatesActive)).pipe(
      switchMap((idBoardStates: number[]) => {
        return this.getReady().pipe(mapTo(idBoardStates));
      })
    );
  }

  public getOneBoardState(idBoardState: number, params: any = {}, getResult?: boolean): void | Observable<BoardState> {
    this.store$.dispatch(BoardStateAction.getOneBoardState({ idBoardState, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        BoardStateAction.normalizeManyBoardStatesAfterUpsert,
        BoardStateAction.boardStatesFailure,
        true
      );
    }
  }

  public getManyBoardStates(params: any = {}, getResult?: boolean): void | Observable<BoardState[]> {
    this.store$.dispatch(BoardStateAction.getManyBoardStates({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        BoardStateAction.normalizeManyBoardStatesAfterUpsert,
        BoardStateAction.boardStatesFailure
      );
    }
  }

  public upsertOneBoardState(
    boardState: Partial<BoardState>,
    ids: BoardStateRelationsIds = {},
    getResult?: boolean
  ): void | Observable<BoardState> {
    this.store$.dispatch(BoardStateAction.upsertOneBoardState({ boardState, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        BoardStateAction.normalizeManyBoardStatesAfterUpsert,
        BoardStateAction.boardStatesFailure,
        true
      );
    }
  }

  public deleteOneBoardState(idBoardState: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(BoardStateAction.deleteOneBoardState({ idBoardState }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        BoardStateAction.deleteOneBoardStateSuccess,
        BoardStateAction.boardStatesFailure
      );
    }
  }

  public setActiveBoardStates(idBoardStates: number[]): void {
    this.store$.dispatch(BoardStateAction.clearActive());
    this.store$.dispatch(BoardStateAction.addManyActives({ idBoardStates }));
  }
}
