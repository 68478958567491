import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationSaleCategory,
  OrganizationSaleCategoryEntityState
} from '@_model/interfaces/organization-sale-category.model';
import { OrganizationSaleCategoryApiService } from '@_services/api/organization-sale-category-api.service';
import * as organizationSaleCategoryActions from '@_store/organization-sale-category/organization-sale-category.actions';
import { getActionsToNormalizeOrganizationSaleCategory } from '@_config/store/normalization.generated';
import { selectOrganizationSaleCategoryState } from './organization-sale-category-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as saleCategoryFamilyActions from '@_store/sale-category-family/sale-category-family.actions';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import { Stratalot } from '@_model/interfaces/stratalot.model';
import * as organizationLeadTodoRuleActions from '@_store/organization-lead-todo-rule/organization-lead-todo-rule.actions';
import { OrganizationLeadTodoRule } from '@_model/interfaces/organization-lead-todo-rule.model';
import * as organizationStratalotTodoRuleActions from '@_store/organization-stratalot-todo-rule/organization-stratalot-todo-rule.actions';
import { OrganizationStratalotTodoRule } from '@_model/interfaces/organization-stratalot-todo-rule.model';
import * as organizationResidenceTodoRuleActions from '@_store/organization-residence-todo-rule/organization-residence-todo-rule.actions';
import { OrganizationResidenceTodoRule } from '@_model/interfaces/organization-residence-todo-rule.model';

export interface OrganizationSaleCategoryRelationsIds {
  stratalots?: number | number[];
  organizationLeadTodoRules?: number | number[];
  organizationStratalotTodoRules?: number | number[];
  organizationResidenceTodoRules?: number | number[];
  organization?: number;
  saleCategoryFamily?: number;
}

export function getDefaultAddOrganizationSaleCategoryActions(
  organizationSaleCategory: OrganizationSaleCategoryEntityState,
  ids?: OrganizationSaleCategoryRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationSaleCategoryActions.normalizeManyOrganizationSaleCategoriesAfterUpsert({
      organizationSaleCategories: [organizationSaleCategory]
    })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationSaleCategorySuccess({
        idOrganization: ids.organization,
        idOrganizationSaleCategories: [organizationSaleCategory.idOrganizationSaleCategory]
      })
    );
    actions.push(
      organizationSaleCategoryActions.addOrganizationSuccess({
        idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.saleCategoryFamily) {
    actions.push(
      saleCategoryFamilyActions.addManyOrganizationSaleCategorySuccess({
        idSaleCategoryFamily: ids.saleCategoryFamily,
        idOrganizationSaleCategories: [organizationSaleCategory.idOrganizationSaleCategory]
      })
    );
    actions.push(
      organizationSaleCategoryActions.addSaleCategoryFamilySuccess({
        idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
        idSaleCategoryFamily: ids.saleCategoryFamily
      })
    );
  }

  if (ids?.stratalots) {
    if (!Array.isArray(ids.stratalots)) {
      actions.push(
        stratalotActions.upsertOneStratalot({
          stratalot: {
            idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
            idStratalot: ids.stratalots as number
          } as Stratalot
        })
      );
      actions.push(
        organizationSaleCategoryActions.addManyStratalotSuccess({
          idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
          idStratalots: [ids.stratalots as number]
        })
      );
    } else {
      actions.push(
        stratalotActions.upsertManyStratalots({
          stratalots: (ids.stratalots as number[]).map((idStratalot: number) => ({
            idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
            idStratalot
          })) as Stratalot[]
        })
      );
      actions.push(
        organizationSaleCategoryActions.addManyStratalotSuccess({
          idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
          idStratalots: ids.stratalots as number[]
        })
      );
    }
  }

  if (ids?.organizationLeadTodoRules) {
    if (!Array.isArray(ids.organizationLeadTodoRules)) {
      actions.push(
        organizationLeadTodoRuleActions.upsertOneOrganizationLeadTodoRule({
          organizationLeadTodoRule: {
            idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
            idOrganizationLeadTodoRule: ids.organizationLeadTodoRules as number
          } as OrganizationLeadTodoRule
        })
      );
      actions.push(
        organizationSaleCategoryActions.addManyOrganizationLeadTodoRuleSuccess({
          idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
          idOrganizationLeadTodoRules: [ids.organizationLeadTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationLeadTodoRuleActions.upsertManyOrganizationLeadTodoRules({
          organizationLeadTodoRules: (ids.organizationLeadTodoRules as number[]).map(
            (idOrganizationLeadTodoRule: number) => ({
              idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
              idOrganizationLeadTodoRule
            })
          ) as OrganizationLeadTodoRule[]
        })
      );
      actions.push(
        organizationSaleCategoryActions.addManyOrganizationLeadTodoRuleSuccess({
          idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
          idOrganizationLeadTodoRules: ids.organizationLeadTodoRules as number[]
        })
      );
    }
  }

  if (ids?.organizationStratalotTodoRules) {
    if (!Array.isArray(ids.organizationStratalotTodoRules)) {
      actions.push(
        organizationStratalotTodoRuleActions.upsertOneOrganizationStratalotTodoRule({
          organizationStratalotTodoRule: {
            idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
            idOrganizationStratalotTodoRule: ids.organizationStratalotTodoRules as number
          } as OrganizationStratalotTodoRule
        })
      );
      actions.push(
        organizationSaleCategoryActions.addManyOrganizationStratalotTodoRuleSuccess({
          idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
          idOrganizationStratalotTodoRules: [ids.organizationStratalotTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotTodoRuleActions.upsertManyOrganizationStratalotTodoRules({
          organizationStratalotTodoRules: (ids.organizationStratalotTodoRules as number[]).map(
            (idOrganizationStratalotTodoRule: number) => ({
              idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
              idOrganizationStratalotTodoRule
            })
          ) as OrganizationStratalotTodoRule[]
        })
      );
      actions.push(
        organizationSaleCategoryActions.addManyOrganizationStratalotTodoRuleSuccess({
          idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
          idOrganizationStratalotTodoRules: ids.organizationStratalotTodoRules as number[]
        })
      );
    }
  }

  if (ids?.organizationResidenceTodoRules) {
    if (!Array.isArray(ids.organizationResidenceTodoRules)) {
      actions.push(
        organizationResidenceTodoRuleActions.upsertOneOrganizationResidenceTodoRule({
          organizationResidenceTodoRule: {
            idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
            idOrganizationResidenceTodoRule: ids.organizationResidenceTodoRules as number
          } as OrganizationResidenceTodoRule
        })
      );
      actions.push(
        organizationSaleCategoryActions.addManyOrganizationResidenceTodoRuleSuccess({
          idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
          idOrganizationResidenceTodoRules: [ids.organizationResidenceTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationResidenceTodoRuleActions.upsertManyOrganizationResidenceTodoRules({
          organizationResidenceTodoRules: (ids.organizationResidenceTodoRules as number[]).map(
            (idOrganizationResidenceTodoRule: number) => ({
              idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
              idOrganizationResidenceTodoRule
            })
          ) as OrganizationResidenceTodoRule[]
        })
      );
      actions.push(
        organizationSaleCategoryActions.addManyOrganizationResidenceTodoRuleSuccess({
          idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory,
          idOrganizationResidenceTodoRules: ids.organizationResidenceTodoRules as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationSaleCategoryActions(
  organizationSaleCategory: OrganizationSaleCategoryEntityState
): Action[] {
  const actions: Action[] = [
    organizationSaleCategoryActions.deleteOneOrganizationSaleCategorySuccess({
      idOrganizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory
    })
  ];

  if (organizationSaleCategory.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationSaleCategorySuccess({
        idOrganizationSaleCategories: [organizationSaleCategory.idOrganizationSaleCategory],
        idOrganizations: [organizationSaleCategory.organization as number]
      })
    );
  }

  if (organizationSaleCategory.saleCategoryFamily) {
    actions.push(
      saleCategoryFamilyActions.deleteManyOrganizationSaleCategorySuccess({
        idOrganizationSaleCategories: [organizationSaleCategory.idOrganizationSaleCategory],
        idFamilySaleCategories: [organizationSaleCategory.saleCategoryFamily as number]
      })
    );
  }

  if (organizationSaleCategory.stratalots) {
    actions.push(
      stratalotActions.deleteManyOrganizationSaleCategorySuccess({
        idOrganizationSaleCategories: [organizationSaleCategory.idOrganizationSaleCategory],
        idStratalots: organizationSaleCategory.stratalots as number[]
      })
    );
  }

  if (organizationSaleCategory.organizationLeadTodoRules) {
    actions.push(
      organizationLeadTodoRuleActions.deleteManyOrganizationSaleCategorySuccess({
        idOrganizationSaleCategories: [organizationSaleCategory.idOrganizationSaleCategory],
        idOrganizationLeadTodoRules: organizationSaleCategory.organizationLeadTodoRules as number[]
      })
    );
  }

  if (organizationSaleCategory.organizationStratalotTodoRules) {
    actions.push(
      organizationStratalotTodoRuleActions.deleteManyOrganizationSaleCategorySuccess({
        idOrganizationSaleCategories: [organizationSaleCategory.idOrganizationSaleCategory],
        idOrganizationStratalotTodoRules: organizationSaleCategory.organizationStratalotTodoRules as number[]
      })
    );
  }

  if (organizationSaleCategory.organizationResidenceTodoRules) {
    actions.push(
      organizationResidenceTodoRuleActions.deleteManyOrganizationSaleCategorySuccess({
        idOrganizationSaleCategories: [organizationSaleCategory.idOrganizationSaleCategory],
        idOrganizationResidenceTodoRules: organizationSaleCategory.organizationResidenceTodoRules as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationSaleCategoryEffects {
  constructor(
    protected actions$: Actions,
    protected organizationSaleCategoryApiService: OrganizationSaleCategoryApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationSaleCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationSaleCategoryActions.getManyOrganizationSaleCategories),
      switchMap(({ params }) =>
        this.organizationSaleCategoryApiService.getOrganizationSaleCategories(params).pipe(
          map((organizationSaleCategories: OrganizationSaleCategory[]) => {
            return organizationSaleCategoryActions.normalizeManyOrganizationSaleCategoriesAfterUpsert({
              organizationSaleCategories
            });
          }),
          catchError(error => of(organizationSaleCategoryActions.organizationSaleCategoriesFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationSaleCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationSaleCategoryActions.getOneOrganizationSaleCategory),
      switchMap(idOrganizationSaleCategory =>
        this.organizationSaleCategoryApiService.getOrganizationSaleCategory(idOrganizationSaleCategory).pipe(
          map((organizationSaleCategory: OrganizationSaleCategory) => {
            return organizationSaleCategoryActions.normalizeManyOrganizationSaleCategoriesAfterUpsert({
              organizationSaleCategories: [organizationSaleCategory]
            });
          }),
          catchError(error => of(organizationSaleCategoryActions.organizationSaleCategoriesFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationSaleCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationSaleCategoryActions.upsertOneOrganizationSaleCategory),
      concatMap(
        ({
          organizationSaleCategory,
          ids
        }: {
          organizationSaleCategory: Partial<OrganizationSaleCategory>;
          ids?: OrganizationSaleCategoryRelationsIds;
        }) => {
          if (organizationSaleCategory.idOrganizationSaleCategory) {
            return this.organizationSaleCategoryApiService
              .updateOrganizationSaleCategory(organizationSaleCategory)
              .pipe(
                map((organizationSaleCategoryReturned: OrganizationSaleCategory) => {
                  return organizationSaleCategoryActions.normalizeManyOrganizationSaleCategoriesAfterUpsert({
                    organizationSaleCategories: [organizationSaleCategoryReturned]
                  });
                }),
                catchError(error => of(organizationSaleCategoryActions.organizationSaleCategoriesFailure({ error })))
              );
          } else {
            return this.organizationSaleCategoryApiService.addOrganizationSaleCategory(organizationSaleCategory).pipe(
              mergeMap((organizationSaleCategoryReturned: OrganizationSaleCategory) =>
                getDefaultAddOrganizationSaleCategoryActions(organizationSaleCategoryReturned, ids)
              ),
              catchError(error => of(organizationSaleCategoryActions.organizationSaleCategoriesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationSaleCategory$ = createEffect(() => {
    const selectOrganizationSaleCategoryState$ = this.store$.select(selectOrganizationSaleCategoryState);
    return this.actions$.pipe(
      ofType(organizationSaleCategoryActions.deleteOneOrganizationSaleCategory),
      withLatestFrom(selectOrganizationSaleCategoryState$),
      concatMap(([{ idOrganizationSaleCategory }, state]) =>
        this.organizationSaleCategoryApiService.deleteOrganizationSaleCategory(idOrganizationSaleCategory).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOrganizationSaleCategoryActions(
                state.entities[idOrganizationSaleCategory] as OrganizationSaleCategoryEntityState
              ),
              organizationSaleCategoryActions.deleteOneOrganizationSaleCategory.type
            )
          ]),
          catchError(error => of(organizationSaleCategoryActions.organizationSaleCategoriesFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationSaleCategoriesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationSaleCategoryActions.normalizeManyOrganizationSaleCategoriesAfterUpsert),
      concatMap(({ organizationSaleCategories }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationSaleCategory(
          organizationSaleCategories,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationSaleCategory] Normalization After Upsert Success')];
      })
    );
  });
}
