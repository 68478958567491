import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyCommunicationResidenceTodoActions from './company-communication-residence-todo.actions';
import {
  adapter,
  initialState,
  CompanyCommunicationResidenceTodoState
} from './company-communication-residence-todo.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyCommunicationResidenceTodoEntityState } from '@_model/interfaces/company-communication-residence-todo.model';

export const companyCommunicationResidenceTodoReducersGeneratedFunctions: ReducerTypes<
  CompanyCommunicationResidenceTodoState,
  readonly ActionCreator[]
>[] = [
  on(
    CompanyCommunicationResidenceTodoActions.getOneCompanyCommunicationResidenceTodo,
    (state: CompanyCommunicationResidenceTodoState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationResidenceTodoActions.getManyCompanyCommunicationResidenceTodos,
    (state: CompanyCommunicationResidenceTodoState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationResidenceTodoActions.upsertOneCompanyCommunicationResidenceTodo,
    (state: CompanyCommunicationResidenceTodoState) => setLoadingsState(state, true)
  ),

  on(
    CompanyCommunicationResidenceTodoActions.upsertManyCompanyCommunicationResidenceTodosSuccess,
    (state: CompanyCommunicationResidenceTodoState, { companyCommunicationResidenceTodos }) =>
      adapter.upsertMany(companyCommunicationResidenceTodos, setLoadingsState(state, false))
  ),
  on(
    CompanyCommunicationResidenceTodoActions.deleteOneCompanyCommunicationResidenceTodo,
    (state: CompanyCommunicationResidenceTodoState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationResidenceTodoActions.deleteOneCompanyCommunicationResidenceTodoSuccess,
    (state: CompanyCommunicationResidenceTodoState, { idCompanyCommunicationResidenceTodo }) =>
      adapter.removeOne(idCompanyCommunicationResidenceTodo, setLoadingsState(state, false))
  ),
  on(CompanyCommunicationResidenceTodoActions.clearActive, (state: CompanyCommunicationResidenceTodoState) => ({
    ...state,
    actives: []
  })),
  on(
    CompanyCommunicationResidenceTodoActions.addManyActives,
    (state: CompanyCommunicationResidenceTodoState, { idCompanyCommunicationResidenceTodos }) => ({
      ...state,
      actives: state.actives.concat(idCompanyCommunicationResidenceTodos)
    })
  ),
  on(
    CompanyCommunicationResidenceTodoActions.deleteOneActive,
    (state: CompanyCommunicationResidenceTodoState, { idCompanyCommunicationResidenceTodo }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCompanyCommunicationResidenceTodo)
    })
  ),

  on(CompanyCommunicationResidenceTodoActions.clearStore, () => initialState),

  on(
    CompanyCommunicationResidenceTodoActions.addCompanyCommunicationSuccess,
    (
      state: CompanyCommunicationResidenceTodoState,
      { idCompanyCommunicationResidenceTodo, idCompanyCommunication }
    ) => {
      if (!state.entities[idCompanyCommunicationResidenceTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationResidenceTodo]: {
            ...state.entities[idCompanyCommunicationResidenceTodo],
            companyCommunication: idCompanyCommunication
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationResidenceTodoActions.deleteManyCompanyCommunicationSuccess,
    (
      state: CompanyCommunicationResidenceTodoState,
      { idCompanyCommunications, idCompanyCommunicationResidenceTodos }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationResidenceTodos.reduce((entities, idCompanyCommunicationResidenceTodo) => {
            if (!state.entities[idCompanyCommunicationResidenceTodo]?.companyCommunication) {
              return entities;
            }
            entities[idCompanyCommunicationResidenceTodo] = {
              ...state.entities[idCompanyCommunicationResidenceTodo],
              companyCommunication: idCompanyCommunications.some(
                (idCompanyCommunication: number) =>
                  idCompanyCommunication === state.entities[idCompanyCommunicationResidenceTodo]?.companyCommunication
              )
                ? undefined
                : state.entities[idCompanyCommunicationResidenceTodo]?.companyCommunication
            } as CompanyCommunicationResidenceTodoEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationResidenceTodoEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationResidenceTodoActions.addOrganizationResidenceTodoSuccess,
    (
      state: CompanyCommunicationResidenceTodoState,
      { idCompanyCommunicationResidenceTodo, idOrganizationResidenceTodo }
    ) => {
      if (!state.entities[idCompanyCommunicationResidenceTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationResidenceTodo]: {
            ...state.entities[idCompanyCommunicationResidenceTodo],
            organizationResidenceTodo: idOrganizationResidenceTodo
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationResidenceTodoActions.deleteManyOrganizationResidenceTodoSuccess,
    (
      state: CompanyCommunicationResidenceTodoState,
      { idOrganizationResidenceTodos, idCompanyCommunicationResidenceTodos }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationResidenceTodos.reduce((entities, idCompanyCommunicationResidenceTodo) => {
            if (!state.entities[idCompanyCommunicationResidenceTodo]?.organizationResidenceTodo) {
              return entities;
            }
            entities[idCompanyCommunicationResidenceTodo] = {
              ...state.entities[idCompanyCommunicationResidenceTodo],
              organizationResidenceTodo: idOrganizationResidenceTodos.some(
                (idOrganizationResidenceTodo: number) =>
                  idOrganizationResidenceTodo ===
                  state.entities[idCompanyCommunicationResidenceTodo]?.organizationResidenceTodo
              )
                ? undefined
                : state.entities[idCompanyCommunicationResidenceTodo]?.organizationResidenceTodo
            } as CompanyCommunicationResidenceTodoEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationResidenceTodoEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyCommunicationResidenceTodoState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyCommunicationResidenceTodoState {
  return { ...state, isLoaded, isLoading };
}
