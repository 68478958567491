import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyStudyNextActionApiService } from '@_services/api/company-study-next-action-api.service';
import { GeneratedCompanyStudyNextActionEffects } from './company-study-next-action-generated.effects';

@Injectable()
export class CompanyStudyNextActionEffects extends GeneratedCompanyStudyNextActionEffects {
  constructor(
    actions$: Actions,
    companyStudyNextActionApiService: CompanyStudyNextActionApiService,
    store$: Store<AppState>
  ) {
    super(actions$, companyStudyNextActionApiService, store$);
  }
}
