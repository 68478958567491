import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResidenceStudyTodoApiService } from '@_services/api/residence-study-todo-api.service';
import { ResidenceStudyTodo } from '@_shared/models/interfaces/residence-study-todo.model';
import { AppState } from '@_store/index.reducers';
import * as residenceStudyTodoActions from '@_store/residence-study-todo/residence-study-todo.actions';
import { catchError, concatMap, map, of } from 'rxjs';
import { GeneratedResidenceStudyTodoEffects } from './residence-study-todo-generated.effects';

@Injectable()
export class ResidenceStudyTodoEffects extends GeneratedResidenceStudyTodoEffects {
  constructor(actions$: Actions, residenceStudyTodoApiService: ResidenceStudyTodoApiService, store$: Store<AppState>) {
    super(actions$, residenceStudyTodoApiService, store$);
  }

  upsertManyResidenceStudyTodosForFillHandle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyTodoActions.upsertManyResidenceStudyTodosForFillHandle),
      concatMap(({ residenceStudyTodos }: { residenceStudyTodos: Partial<ResidenceStudyTodo[]> }) => {
        return this.residenceStudyTodoApiService.updateManyResidenceStudyTodos(residenceStudyTodos).pipe(
          map((residenceStudyTodoReturned: ResidenceStudyTodo[]) => {
            return residenceStudyTodoActions.normalizeManyResidenceStudyTodosAfterUpsert({
              residenceStudyTodos: residenceStudyTodoReturned
            });
          }),
          catchError(error => of(residenceStudyTodoActions.residenceStudyTodosFailure({ error })))
        );
      })
    );
  });
}
