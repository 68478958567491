import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationLeadAvancementApiService } from '@_services/api/organization-lead-avancement-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedOrganizationLeadAvancementEffects } from './organization-lead-avancement-generated.effects';

@Injectable()
export class OrganizationLeadAvancementEffects extends GeneratedOrganizationLeadAvancementEffects {
  constructor(
    actions$: Actions,
    organizationLeadAvancementApiService: OrganizationLeadAvancementApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationLeadAvancementApiService, store$);
  }
}
