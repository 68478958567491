import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyTerritoireActions from './company-territoire.actions';
import { adapter, initialState, CompanyTerritoireState } from './company-territoire.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyTerritoireEntityState } from '@_model/interfaces/company-territoire.model';

export const companyTerritoireReducersGeneratedFunctions: ReducerTypes<
  CompanyTerritoireState,
  readonly ActionCreator[]
>[] = [
  on(CompanyTerritoireActions.getOneCompanyTerritoire, (state: CompanyTerritoireState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyTerritoireActions.getManyCompanyTerritoires, (state: CompanyTerritoireState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyTerritoireActions.upsertOneCompanyTerritoire, (state: CompanyTerritoireState) =>
    setLoadingsState(state, true)
  ),

  on(
    CompanyTerritoireActions.upsertManyCompanyTerritoiresSuccess,
    (state: CompanyTerritoireState, { companyTerritoires }) =>
      adapter.upsertMany(companyTerritoires, setLoadingsState(state, false))
  ),
  on(CompanyTerritoireActions.deleteOneCompanyTerritoire, (state: CompanyTerritoireState) =>
    setLoadingsState(state, true)
  ),
  on(
    CompanyTerritoireActions.deleteOneCompanyTerritoireSuccess,
    (state: CompanyTerritoireState, { idCompanyTerritoire }) =>
      adapter.removeOne(idCompanyTerritoire, setLoadingsState(state, false))
  ),
  on(CompanyTerritoireActions.clearActive, (state: CompanyTerritoireState) => ({ ...state, actives: [] })),
  on(CompanyTerritoireActions.addManyActives, (state: CompanyTerritoireState, { idCompanyTerritoires }) => ({
    ...state,
    actives: state.actives.concat(idCompanyTerritoires)
  })),
  on(CompanyTerritoireActions.deleteOneActive, (state: CompanyTerritoireState, { idCompanyTerritoire }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCompanyTerritoire)
  })),

  on(CompanyTerritoireActions.clearStore, () => initialState),

  on(
    CompanyTerritoireActions.addManyCompanyTerritoireUserSuccess,
    (state: CompanyTerritoireState, { idCompanyTerritoire, idCompanyTerritoireUsers }) => {
      if (!state.entities[idCompanyTerritoire]) {
        return state;
      }
      const companyTerritoireUsers = (state.entities[idCompanyTerritoire]?.companyTerritoireUsers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyTerritoire]: {
            ...state.entities[idCompanyTerritoire],
            companyTerritoireUsers: companyTerritoireUsers.concat(
              idCompanyTerritoireUsers.filter(id => companyTerritoireUsers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyTerritoireActions.deleteManyCompanyTerritoireUserSuccess,
    (state: CompanyTerritoireState, { idCompanyTerritoireUsers, idCompanyTerritoires }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyTerritoires.reduce((entities, idCompanyTerritoire) => {
            if (!state.entities[idCompanyTerritoire]?.companyTerritoireUsers) {
              return entities;
            }
            entities[idCompanyTerritoire] = {
              ...state.entities[idCompanyTerritoire],
              companyTerritoireUsers: (state.entities[idCompanyTerritoire]?.companyTerritoireUsers as number[])?.filter(
                (idCompanyTerritoireUser: number) =>
                  !idCompanyTerritoireUsers.some((id: number) => id === idCompanyTerritoireUser)
              )
            } as CompanyTerritoireEntityState;
            return entities;
          }, {} as Dictionary<CompanyTerritoireEntityState>)
        }
      };
    }
  ),

  on(
    CompanyTerritoireActions.addManyResidenceSuccess,
    (state: CompanyTerritoireState, { idCompanyTerritoire, idResidences }) => {
      if (!state.entities[idCompanyTerritoire]) {
        return state;
      }
      const residences = (state.entities[idCompanyTerritoire]?.residences as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyTerritoire]: {
            ...state.entities[idCompanyTerritoire],
            residences: residences.concat(idResidences.filter(id => residences.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CompanyTerritoireActions.deleteManyResidenceSuccess,
    (state: CompanyTerritoireState, { idResidences, idCompanyTerritoires }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyTerritoires.reduce((entities, idCompanyTerritoire) => {
            if (!state.entities[idCompanyTerritoire]?.residences) {
              return entities;
            }
            entities[idCompanyTerritoire] = {
              ...state.entities[idCompanyTerritoire],
              residences: (state.entities[idCompanyTerritoire]?.residences as number[])?.filter(
                (idResidence: number) => !idResidences.some((id: number) => id === idResidence)
              )
            } as CompanyTerritoireEntityState;
            return entities;
          }, {} as Dictionary<CompanyTerritoireEntityState>)
        }
      };
    }
  ),

  on(
    CompanyTerritoireActions.addCompanySuccess,
    (state: CompanyTerritoireState, { idCompanyTerritoire, idCompany }) => {
      if (!state.entities[idCompanyTerritoire]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyTerritoire]: {
            ...state.entities[idCompanyTerritoire],
            company: idCompany
          }
        }
      };
    }
  ),

  on(
    CompanyTerritoireActions.deleteManyCompanySuccess,
    (state: CompanyTerritoireState, { idCompanies, idCompanyTerritoires }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyTerritoires.reduce((entities, idCompanyTerritoire) => {
            if (!state.entities[idCompanyTerritoire]?.company) {
              return entities;
            }
            entities[idCompanyTerritoire] = {
              ...state.entities[idCompanyTerritoire],
              company: idCompanies.some(
                (idCompany: number) => idCompany === state.entities[idCompanyTerritoire]?.company
              )
                ? undefined
                : state.entities[idCompanyTerritoire]?.company
            } as CompanyTerritoireEntityState;
            return entities;
          }, {} as Dictionary<CompanyTerritoireEntityState>)
        }
      };
    }
  ),

  on(
    CompanyTerritoireActions.addResponsableSuccess,
    (state: CompanyTerritoireState, { idCompanyTerritoire, idResponsable }) => {
      if (!state.entities[idCompanyTerritoire]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyTerritoire]: {
            ...state.entities[idCompanyTerritoire],
            responsable: idResponsable
          }
        }
      };
    }
  ),

  on(
    CompanyTerritoireActions.deleteManyResponsableSuccess,
    (state: CompanyTerritoireState, { idResponsable, idCompanyTerritoires }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyTerritoires.reduce((entities, idCompanyTerritoire) => {
            if (!state.entities[idCompanyTerritoire]?.responsable) {
              return entities;
            }
            entities[idCompanyTerritoire] = {
              ...state.entities[idCompanyTerritoire],
              user: idResponsable.some((idUser: number) => idUser === state.entities[idCompanyTerritoire]?.responsable)
                ? undefined
                : state.entities[idCompanyTerritoire]?.responsable
            } as CompanyTerritoireEntityState;
            return entities;
          }, {} as Dictionary<CompanyTerritoireEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyTerritoireState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyTerritoireState {
  return { ...state, isLoaded, isLoading };
}
