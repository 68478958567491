import { OrganizationStratalotTodoRuleState, initialState } from './organization-stratalot-todo-rule.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationStratalotTodoRuleReducersGeneratedFunctions } from './organization-stratalot-todo-rule-generated.reducer';

const organizationStratalotTodoRuleReducersFunctions = [...organizationStratalotTodoRuleReducersGeneratedFunctions];

const organizationStratalotTodoRuleReducer = createReducer(
  initialState,
  ...organizationStratalotTodoRuleReducersFunctions
);

export function reducer(state: OrganizationStratalotTodoRuleState | undefined, action: Action) {
  return organizationStratalotTodoRuleReducer(state, action);
}
