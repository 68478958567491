import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedDiagnosticApiService } from './diagnostic-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticApiService extends GeneratedDiagnosticApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
