import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { ResidenceRelationsIds } from './residence-generated.effects';

export const getOneResidence = createAction(
  '[Residence] Get One Residence',
  props<{ idResidence: number; params?: any }>()
);

export const getManyResidences = createAction('[Residence] Get Many Residences', props<{ params: any }>());

export const addManyActives = createAction(
  '[Residence] Add Many Actives Residence',
  props<{ idResidences: number[] }>()
);

export const deleteOneActive = createAction(
  '[Residence] Delete One Active Residence',
  props<{ idResidence: number }>()
);

export const clearActive = createAction('[Residence] Clear Active Residence');

export const upsertOneResidence = createAction(
  '[Residence] Upsert One Residence',
  props<{
    residence: Partial<Residence>;
    ids?: ResidenceRelationsIds;
  }>()
);

export const upsertManyResidences = createAction(
  '[Residence] Upsert Many Residences',
  props<{
    residences: Partial<Residence>[];
    ids?: ResidenceRelationsIds;
  }>()
);

export const upsertManyResidencesSuccess = createAction(
  '[Residence] Create Many Residences Success',
  props<{ residences: ResidenceEntityState[] }>()
);

export const deleteOneResidence = createAction('[Residence] Delete One Residence', props<{ idResidence: number }>());

export const deleteOneResidenceSuccess = createAction(
  '[Residence] Delete One Residence Success',
  props<{ idResidence: number }>()
);

export const normalizeManyResidencesAfterUpsert = createAction(
  '[Residence] Normalize Many Residences After Upsert',
  props<{ residences: ResidenceEntityState[] }>()
);

export const residencesFailure = createAction('[Residence] Residences Failure', props<{ error: HttpErrorResponse }>());

export const clearStore = createAction('[Residence] Clear Residences');

export const addManyDiffusionVisualSuccess = createAction(
  '[Residence] Add Many diffusion-visual',
  props<{ idResidence: number; idDiffusionVisuals: number[] }>()
);

export const deleteManyDiffusionVisualSuccess = createAction(
  '[Residence] Delete Many DiffusionVisuals',
  props<{ idDiffusionVisuals: number[]; idResidences: number[] }>()
);

export const addManyResidenceTodoSuccess = createAction(
  '[Residence] Add Many residence-todo',
  props<{ idResidence: number; idResidenceTodos: number[] }>()
);

export const deleteManyResidenceTodoSuccess = createAction(
  '[Residence] Delete Many ResidenceTodos',
  props<{ idResidenceTodos: number[]; idResidences: number[] }>()
);

export const addManyResidenceWorkSuccess = createAction(
  '[Residence] Add Many residence-work',
  props<{ idResidence: number; idResidenceWorks: number[] }>()
);

export const deleteManyResidenceWorkSuccess = createAction(
  '[Residence] Delete Many ResidenceWorks',
  props<{ idResidenceWorks: number[]; idResidences: number[] }>()
);

export const addManyResidenceSaleCategoryFamilySuccess = createAction(
  '[Residence] Add Many residence-sale-category-family',
  props<{ idResidence: number; idResidenceSaleCategoryFamilies: number[] }>()
);

export const deleteManyResidenceSaleCategoryFamilySuccess = createAction(
  '[Residence] Delete Many ResidenceSaleCategoryFamilies',
  props<{ idResidenceSaleCategoryFamilies: number[]; idResidences: number[] }>()
);

export const addManyResidenceContactSuccess = createAction(
  '[Residence] Add Many residence-contact',
  props<{ idResidence: number; idResidenceContacts: number[] }>()
);

export const deleteManyResidenceContactSuccess = createAction(
  '[Residence] Delete Many ResidenceContacts',
  props<{ idResidenceContacts: number[]; idResidences: number[] }>()
);

export const addManyStratalotSuccess = createAction(
  '[Residence] Add Many stratalot',
  props<{ idResidence: number; idStratalots: number[] }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[Residence] Delete Many Stratalots',
  props<{ idStratalots: number[]; idResidences: number[] }>()
);

export const addManyResidencePriceGridSuccess = createAction(
  '[Residence] Add Many residence-price-grid',
  props<{ idResidence: number; idResidencePriceGrids: number[] }>()
);

export const deleteManyResidencePriceGridSuccess = createAction(
  '[Residence] Delete Many ResidencePriceGrids',
  props<{ idResidencePriceGrids: number[]; idResidences: number[] }>()
);

export const addManyResidenceShareSuccess = createAction(
  '[Residence] Add Many residence-share',
  props<{ idResidence: number; idResidenceShares: number[] }>()
);

export const deleteManyResidenceShareSuccess = createAction(
  '[Residence] Delete Many ResidenceShares',
  props<{ idResidenceShares: number[]; idResidences: number[] }>()
);

export const addManyStratalotRcpSuccess = createAction(
  '[Residence] Add Many stratalot-rcp',
  props<{ idResidence: number; idStratalotRcps: number[] }>()
);

export const deleteManyStratalotRcpSuccess = createAction(
  '[Residence] Delete Many StratalotRcps',
  props<{ idStratalotRcps: number[]; idResidences: number[] }>()
);

export const addManySaleCategoryFamilySuccess = createAction(
  '[Residence] Add Many sale-category-family',
  props<{ idResidence: number; idFamilySaleCategories: number[] }>()
);

export const deleteManySaleCategoryFamilySuccess = createAction(
  '[Residence] Delete Many FamilySaleCategories',
  props<{ idFamilySaleCategories: number[]; idResidences: number[] }>()
);

export const addManyProspectBuyingWishSuccess = createAction(
  '[Residence] Add Many prospect-buying-wish',
  props<{ idResidence: number; idProspectBuyingWishs: number[] }>()
);

export const deleteManyProspectBuyingWishSuccess = createAction(
  '[Residence] Delete Many ProspectBuyingWishs',
  props<{ idProspectBuyingWishs: number[]; idResidences: number[] }>()
);

export const addManyCompanyTerritoireSuccess = createAction(
  '[Residence] Add Many company-territoire',
  props<{ idResidence: number; idCompanyTerritoires: number[] }>()
);

export const deleteManyCompanyTerritoireSuccess = createAction(
  '[Residence] Delete Many CompanyTerritoires',
  props<{ idCompanyTerritoires: number[]; idResidences: number[] }>()
);

export const addManyResidenceForecastSuccess = createAction(
  '[Residence] Add Many residence-forecast',
  props<{ idResidence: number; idResidenceForecasts: number[] }>()
);

export const deleteManyResidenceForecastSuccess = createAction(
  '[Residence] Delete Many ResidenceForecasts',
  props<{ idResidenceForecasts: number[]; idResidences: number[] }>()
);

export const addManyGeneratedDocumentsResidenceSuccess = createAction(
  '[Residence] Add Many generated-documents-residence',
  props<{ idResidence: number; idGeneratedDocumentsResidences: number[] }>()
);

export const deleteManyGeneratedDocumentsResidenceSuccess = createAction(
  '[Residence] Delete Many GeneratedDocumentsResidences',
  props<{ idGeneratedDocumentsResidences: number[]; idResidences: number[] }>()
);

export const addManyGeneratedDocumentSuccess = createAction(
  '[Residence] Add Many generated-document',
  props<{ idResidence: number; idGeneratedDocuments: number[] }>()
);

export const deleteManyGeneratedDocumentSuccess = createAction(
  '[Residence] Delete Many GeneratedDocuments',
  props<{ idGeneratedDocuments: number[]; idResidences: number[] }>()
);

export const addCompanySuccess = createAction(
  '[Residence] Add Company',
  props<{ idResidence: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[Residence] Delete Many Company',
  props<{ idCompanies: number[]; idResidences: number[] }>()
);

export const addResponsableSuccess = createAction(
  '[Residence] Add Responsable',
  props<{ idResidence: number; idResponsable: number }>()
);

export const deleteManyResponsableSuccess = createAction(
  '[Residence] Delete Many Responsable',
  props<{ idResponsable: number[]; idResidences: number[] }>()
);

export const addOrganizationForecastRateSuccess = createAction(
  '[Residence] Add OrganizationForecastRate',
  props<{ idResidence: number; idOrganizationForecastRate: number }>()
);

export const deleteManyOrganizationForecastRateSuccess = createAction(
  '[Residence] Delete Many OrganizationForecastRate',
  props<{ idOrganizationForecastRates: number[]; idResidences: number[] }>()
);

export const addCitySuccess = createAction('[Residence] Add City', props<{ idResidence: number; idCity: number }>());

export const deleteManyCitySuccess = createAction(
  '[Residence] Delete Many City',
  props<{ idCities: number[]; idResidences: number[] }>()
);
