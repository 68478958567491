import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedSaleCategoryFamilyApiService } from './sale-category-family-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class SaleCategoryFamilyApiService extends GeneratedSaleCategoryFamilyApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
