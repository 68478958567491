import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@_environments/environment';
import { RepositoryService } from '@_services/api/repository.service';
import { idsForPublispostage } from '@_shared/interfaces/idsForPublispostage.interface';
import { GeneratedDocument } from '@_shared/models/interfaces/generated-document.model';
import { Observable } from 'rxjs';
import { GeneratedGeneratedDocumentApiService } from './generated-document-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class GeneratedDocumentApiService extends GeneratedGeneratedDocumentApiService {
  constructor(repo: RepositoryService, private http: HttpClient) {
    super(repo);
  }

  public getList(params: { idOrganization: number; page: string }): Observable<GeneratedDocument[]> {
    return this.repo.getData<GeneratedDocument[]>('generated-document/list', params);
  }
  public displayPreview(params: { idGeneratedDocument: number; idsObj: idsForPublispostage }): Observable<any[]> {
    return this.http.post<any[]>(environment.apiUrl + '/generated-document/preview', params);
  }
  public displayDictionary(params: { idsObj: idsForPublispostage; showAll: boolean }): Observable<any[]> {
    return this.http.post<any[]>(environment.apiUrl + '/generated-document/dictionary', params);
  }

  public getListByCompany(params: { idsCompanies: number[]; page?: string }): Observable<GeneratedDocument[]> {
    return this.repo.getData<GeneratedDocument[]>('generated-document/listByCompany', params);
  }
  public getListByResidence(params: { idsResidences: number[] }): Observable<GeneratedDocument[]> {
    return this.repo.getData<GeneratedDocument[]>('generated-document/listByResidence', params);
  }
  public addGeneratedDocumentWithCompanyOrResidence(
    generatedDocument: Partial<GeneratedDocument>,
    idCompany: number[],
    idResidence: number
  ): Observable<GeneratedDocument> {
    return this.repo.create<GeneratedDocument>('generated-document', { generatedDocument, idCompany, idResidence });
  }

  public downloadTemplate(body: {
    idGeneratedDocument: number;
    idsObj: idsForPublispostage;
    format?: string;
    prospectEvent: boolean;
  }): Observable<any> {
    return this.repo.postForFile(environment.apiUrl + '/generated-document/generate', body);
  }

  public downloadRawTemplate(params: { idGeneratedDocument: number; format?: string }): Observable<Blob> {
    return this.http.get(environment.apiUrl + '/generated-document/raw', {
      params: this.setParams(params),
      responseType: 'blob',
      headers: new HttpHeaders().append(
        'Content-Type',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      )
    });
  }

  private setParams(object: any): HttpParams {
    let params = new HttpParams();

    const formatedObject = Object.keys(object).every(key => key === 'params') ? object.params : object;

    for (const key in formatedObject) {
      if (formatedObject[key] !== null && formatedObject[key] !== undefined) {
        if (Array.isArray(formatedObject[key])) {
          formatedObject[key].forEach(element => {
            params = params.append(`${key}[]`, element);
          });
        } else {
          params = params.append(key, formatedObject[key]);
        }
      }
    }
    return params;
  }
}
