import { ResidenceState, initialState, adapter } from './residence.state';
import { Action, createReducer } from '@ngrx/store';
import { residenceReducersGeneratedFunctions, setLoadingsState } from './residence-generated.reducer';
import { on } from '@ngrx/store';
import * as ResidenceActions from '@_store/residence/residence.actions';

const residenceReducersFunctions = [
  ...residenceReducersGeneratedFunctions,
  // TODO A supprimer lorsque seront MAJ pour gérer le deleteMany
  on(ResidenceActions.deleteManyResidencesSuccess, (state: ResidenceState, { idResidences }) =>
    adapter.removeMany(idResidences, setLoadingsState(state, false))
  )
];

const residenceReducer = createReducer(initialState, ...residenceReducersFunctions);

export function reducer(state: ResidenceState | undefined, action: Action) {
  return residenceReducer(state, action);
}
