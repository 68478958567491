import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidencePriceGridEntityState } from '@_model/interfaces/residence-price-grid.model';

export interface ResidencePriceGridState extends EntityState<ResidencePriceGridEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidencePriceGridEntityState> = createEntityAdapter<ResidencePriceGridEntityState>(
  {
    selectId: o => o.idResidencePriceGrid
  }
);
export const initialState: ResidencePriceGridState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residencePriceGridFeatureKey = 'residencePriceGrid';
