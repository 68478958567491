import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyTerritoire, CompanyTerritoireEntityState } from '@_model/interfaces/company-territoire.model';
import {
  CompanyTerritoireUser,
  CompanyTerritoireUserEntityState
} from '@_model/interfaces/company-territoire-user.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { User, UserEntityState } from '@_model/interfaces/user.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, companyTerritoireFeatureKey, CompanyTerritoireState } from './company-territoire.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyTerritoireRelations: string[] = [
  'companyTerritoireUsers',
  'residences',
  'companies',
  'responsable'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyTerritoireState = createFeatureSelector<CompanyTerritoireState>(companyTerritoireFeatureKey);

export const selectIsLoadedCompanyTerritoire = createSelector(
  selectCompanyTerritoireState,
  (state: CompanyTerritoireState) => state.isLoaded
);

export const selectIsLoadingCompanyTerritoire = createSelector(
  selectCompanyTerritoireState,
  (state: CompanyTerritoireState) => state.isLoading
);

export const selectIsReadyCompanyTerritoire = createSelector(
  selectCompanyTerritoireState,
  (state: CompanyTerritoireState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyTerritoire = createSelector(
  selectCompanyTerritoireState,
  (state: CompanyTerritoireState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyTerritoireModel: SelectorModel = {
  name: 'companyTerritoires',
  getSelector: selectAllCompanyTerritoiresDictionary,
  isReady: selectIsReadyCompanyTerritoire
};

export const selectCompanyTerritoiresEntities = createSelector(selectCompanyTerritoireState, selectEntities);

export const selectCompanyTerritoiresArray = createSelector(selectCompanyTerritoireState, selectAll);

export const selectIdCompanyTerritoiresActive = createSelector(
  selectCompanyTerritoireState,
  (state: CompanyTerritoireState) => state.actives
);

const companyTerritoiresInObject = (companyTerritoires: Dictionary<CompanyTerritoireEntityState>) => ({
  companyTerritoires
});

const selectCompanyTerritoiresEntitiesDictionary = createSelector(
  selectCompanyTerritoiresEntities,
  companyTerritoiresInObject
);

const selectAllCompanyTerritoiresObject = createSelector(selectCompanyTerritoiresEntities, companyTerritoires => {
  return hydrateAll({ companyTerritoires });
});

const selectOneCompanyTerritoireDictionary = (idCompanyTerritoire: number) =>
  createSelector(selectCompanyTerritoiresEntities, companyTerritoires => {
    return { companyTerritoires: { [idCompanyTerritoire]: companyTerritoires[idCompanyTerritoire] } };
  });

const selectOneCompanyTerritoireDictionaryWithoutChild = (idCompanyTerritoire: number) =>
  createSelector(selectCompanyTerritoiresEntities, companyTerritoires => {
    return { companyTerritoire: companyTerritoires[idCompanyTerritoire] };
  });

const selectActiveCompanyTerritoiresEntities = createSelector(
  selectIdCompanyTerritoiresActive,
  selectCompanyTerritoiresEntities,
  (actives: number[], companyTerritoires: Dictionary<CompanyTerritoireEntityState>) =>
    getCompanyTerritoiresFromActives(actives, companyTerritoires)
);

function getCompanyTerritoiresFromActives(
  actives: number[],
  companyTerritoires: Dictionary<CompanyTerritoireEntityState>
): Dictionary<CompanyTerritoireEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyTerritoires[idActive]) {
      acc[idActive] = companyTerritoires[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyTerritoireEntityState>);
}

const selectAllCompanyTerritoiresSelectors: Dictionary<Selector> = {};
export function selectAllCompanyTerritoires(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyTerritoire>(
      schema,
      selectAllCompanyTerritoiresSelectors,
      selectCompanyTerritoiresEntitiesDictionary,
      getRelationSelectors,
      companyTerritoireRelations,
      hydrateAll,
      'companyTerritoire'
    );
  } else {
    return selectAllCompanyTerritoiresObject;
  }
}

export function selectAllCompanyTerritoiresDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyTerritoires'
): Selector {
  return createSelector(selectAllCompanyTerritoires(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyTerritoireEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyTerritoires.length; i++) {
      res[customKey][result.companyTerritoires[i].idCompanyTerritoire] = result.companyTerritoires[i];
    }
    return res;
  });
}

export function selectOneCompanyTerritoire(schema: SelectSchema = {}, idCompanyTerritoire: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyTerritoireDictionary(idCompanyTerritoire)];
    selectors.push(...getRelationSelectors(schema, companyTerritoireRelations, 'companyTerritoire'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyTerritoireDictionaryWithoutChild(idCompanyTerritoire);
  }
}

export function selectActiveCompanyTerritoires(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyTerritoiresEntities, companyTerritoires => ({ companyTerritoires }))
  ];
  selectors.push(...getRelationSelectors(schema, companyTerritoireRelations, 'companyTerritoire'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyTerritoires: Dictionary<CompanyTerritoireEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  responsable?: Dictionary<UserEntityState>;
  companyTerritoireUsers?: Dictionary<CompanyTerritoireUserEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { companyTerritoires: (CompanyTerritoire | null)[] } {
  const { companyTerritoires, companies, responsable, companyTerritoireUsers, residences } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    companyTerritoires: Object.keys(companyTerritoires).map(idCompanyTerritoire =>
      hydrate(
        companyTerritoires[idCompanyTerritoire] as CompanyTerritoireEntityState,
        companies,
        responsable,
        companyTerritoireUsers,
        residences
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { companyTerritoire: CompanyTerritoireEntityState | null } {
  const { companyTerritoires, companies, responsable, companyTerritoireUsers, residences } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const companyTerritoire = Object.values(companyTerritoires)[0];
  return {
    companyTerritoire: hydrate(
      companyTerritoire as CompanyTerritoireEntityState,
      companies,
      responsable,
      companyTerritoireUsers,
      residences
    )
  };
}

function hydrate(
  companyTerritoire: CompanyTerritoireEntityState,
  companyEntities?: Dictionary<CompanyEntityState>,
  responsableEntities?: Dictionary<UserEntityState>,
  companyTerritoireUserEntities?: Dictionary<CompanyTerritoireUserEntityState>,
  residenceEntities?: Dictionary<ResidenceEntityState>
): CompanyTerritoire | null {
  if (!companyTerritoire) {
    return null;
  }

  const companyTerritoireHydrated: CompanyTerritoireEntityState = { ...companyTerritoire };
  if (companyEntities) {
    companyTerritoireHydrated.company = companyEntities[companyTerritoire.company as number] as Company;
  } else {
    delete companyTerritoireHydrated.company;
  }
  if (responsableEntities) {
    companyTerritoireHydrated.responsable = responsableEntities[companyTerritoire.responsable as number] as User;
  } else {
    delete companyTerritoireHydrated.responsable;
  }

  if (companyTerritoireUserEntities) {
    companyTerritoireHydrated.companyTerritoireUsers = (
      (companyTerritoireHydrated.companyTerritoireUsers as number[]) || []
    ).map(id => companyTerritoireUserEntities[id]) as CompanyTerritoireUser[];
  } else {
    delete companyTerritoireHydrated.companyTerritoireUsers;
  }

  if (residenceEntities) {
    companyTerritoireHydrated.residences = ((companyTerritoireHydrated.residences as number[]) || []).map(
      id => residenceEntities[id]
    ) as Residence[];
  } else {
    delete companyTerritoireHydrated.residences;
  }

  return companyTerritoireHydrated as CompanyTerritoire;
}
