import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ForecastPeriod, ForecastPeriodEntityState } from '@_model/interfaces/forecast-period.model';
import { ResidenceForecast, ResidenceForecastEntityState } from '@_model/interfaces/residence-forecast.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, forecastPeriodFeatureKey, ForecastPeriodState } from './forecast-period.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const forecastPeriodRelations: string[] = ['residenceForecasts'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectForecastPeriodState = createFeatureSelector<ForecastPeriodState>(forecastPeriodFeatureKey);

export const selectIsLoadedForecastPeriod = createSelector(
  selectForecastPeriodState,
  (state: ForecastPeriodState) => state.isLoaded
);

export const selectIsLoadingForecastPeriod = createSelector(
  selectForecastPeriodState,
  (state: ForecastPeriodState) => state.isLoading
);

export const selectIsReadyForecastPeriod = createSelector(
  selectForecastPeriodState,
  (state: ForecastPeriodState) => !state.isLoading
);

export const selectIsReadyAndLoadedForecastPeriod = createSelector(
  selectForecastPeriodState,
  (state: ForecastPeriodState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ForecastPeriodModel: SelectorModel = {
  name: 'forecastPeriods',
  getSelector: selectAllForecastPeriodsDictionary,
  isReady: selectIsReadyForecastPeriod
};

export const selectForecastPeriodsEntities = createSelector(selectForecastPeriodState, selectEntities);

export const selectForecastPeriodsArray = createSelector(selectForecastPeriodState, selectAll);

export const selectIdForecastPeriodsActive = createSelector(
  selectForecastPeriodState,
  (state: ForecastPeriodState) => state.actives
);

const forecastPeriodsInObject = (forecastPeriods: Dictionary<ForecastPeriodEntityState>) => ({ forecastPeriods });

const selectForecastPeriodsEntitiesDictionary = createSelector(selectForecastPeriodsEntities, forecastPeriodsInObject);

const selectAllForecastPeriodsObject = createSelector(selectForecastPeriodsEntities, forecastPeriods => {
  return hydrateAll({ forecastPeriods });
});

const selectOneForecastPeriodDictionary = (idForecastPeriod: number) =>
  createSelector(selectForecastPeriodsEntities, forecastPeriods => {
    return { forecastPeriods: { [idForecastPeriod]: forecastPeriods[idForecastPeriod] } };
  });

const selectOneForecastPeriodDictionaryWithoutChild = (idForecastPeriod: number) =>
  createSelector(selectForecastPeriodsEntities, forecastPeriods => {
    return { forecastPeriod: forecastPeriods[idForecastPeriod] };
  });

const selectActiveForecastPeriodsEntities = createSelector(
  selectIdForecastPeriodsActive,
  selectForecastPeriodsEntities,
  (actives: number[], forecastPeriods: Dictionary<ForecastPeriodEntityState>) =>
    getForecastPeriodsFromActives(actives, forecastPeriods)
);

function getForecastPeriodsFromActives(
  actives: number[],
  forecastPeriods: Dictionary<ForecastPeriodEntityState>
): Dictionary<ForecastPeriodEntityState> {
  return actives.reduce((acc, idActive) => {
    if (forecastPeriods[idActive]) {
      acc[idActive] = forecastPeriods[idActive];
    }
    return acc;
  }, {} as Dictionary<ForecastPeriodEntityState>);
}

const selectAllForecastPeriodsSelectors: Dictionary<Selector> = {};
export function selectAllForecastPeriods(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ForecastPeriod>(
      schema,
      selectAllForecastPeriodsSelectors,
      selectForecastPeriodsEntitiesDictionary,
      getRelationSelectors,
      forecastPeriodRelations,
      hydrateAll,
      'forecastPeriod'
    );
  } else {
    return selectAllForecastPeriodsObject;
  }
}

export function selectAllForecastPeriodsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'forecastPeriods'
): Selector {
  return createSelector(selectAllForecastPeriods(schema), result => {
    const res = { [customKey]: {} as Dictionary<ForecastPeriodEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.forecastPeriods.length; i++) {
      res[customKey][result.forecastPeriods[i].idForecastPeriod] = result.forecastPeriods[i];
    }
    return res;
  });
}

export function selectOneForecastPeriod(schema: SelectSchema = {}, idForecastPeriod: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneForecastPeriodDictionary(idForecastPeriod)];
    selectors.push(...getRelationSelectors(schema, forecastPeriodRelations, 'forecastPeriod'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneForecastPeriodDictionaryWithoutChild(idForecastPeriod);
  }
}

export function selectActiveForecastPeriods(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveForecastPeriodsEntities, forecastPeriods => ({ forecastPeriods }))
  ];
  selectors.push(...getRelationSelectors(schema, forecastPeriodRelations, 'forecastPeriod'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  forecastPeriods: Dictionary<ForecastPeriodEntityState>;
  residenceForecasts?: Dictionary<ResidenceForecastEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { forecastPeriods: (ForecastPeriod | null)[] } {
  const { forecastPeriods, residenceForecasts } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    forecastPeriods: Object.keys(forecastPeriods).map(idForecastPeriod =>
      hydrate(forecastPeriods[idForecastPeriod] as ForecastPeriodEntityState, residenceForecasts)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { forecastPeriod: ForecastPeriodEntityState | null } {
  const { forecastPeriods, residenceForecasts } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const forecastPeriod = Object.values(forecastPeriods)[0];
  return { forecastPeriod: hydrate(forecastPeriod as ForecastPeriodEntityState, residenceForecasts) };
}

function hydrate(
  forecastPeriod: ForecastPeriodEntityState,
  residenceForecastEntities?: Dictionary<ResidenceForecastEntityState>
): ForecastPeriod | null {
  if (!forecastPeriod) {
    return null;
  }

  const forecastPeriodHydrated: ForecastPeriodEntityState = { ...forecastPeriod };

  if (residenceForecastEntities) {
    forecastPeriodHydrated.residenceForecasts = ((forecastPeriodHydrated.residenceForecasts as number[]) || []).map(
      id => residenceForecastEntities[id]
    ) as ResidenceForecast[];
  } else {
    delete forecastPeriodHydrated.residenceForecasts;
  }

  return forecastPeriodHydrated as ForecastPeriod;
}
