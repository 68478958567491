import { ResidencePriceGridState, initialState } from './residence-price-grid.state';
import { Action, createReducer } from '@ngrx/store';
import { residencePriceGridReducersGeneratedFunctions } from './residence-price-grid-generated.reducer';

const residencePriceGridReducersFunctions = [...residencePriceGridReducersGeneratedFunctions];

const residencePriceGridReducer = createReducer(initialState, ...residencePriceGridReducersFunctions);

export function reducer(state: ResidencePriceGridState | undefined, action: Action) {
  return residencePriceGridReducer(state, action);
}
