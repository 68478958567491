import { AssociationEffects } from '@_store/association/association.effects';
import { StratalotAssociationEffects } from '@_store/stratalot-association/stratalot-association.effects';
import { BoardStateEffects } from '@_store/board-state/board-state.effects';
import { CampaignMediaEffects } from '@_store/campaign-media/campaign-media.effects';
import { CityEffects } from '@_store/city/city.effects';
import { CompanyEffects } from '@_store/company/company.effects';
import { CompanyUbiflowEffects } from '@_store/company-ubiflow/company-ubiflow.effects';
import { CompanyMyNotaryEffects } from '@_store/company-my-notary/company-my-notary.effects';
import { CompanyPriceLabelEffects } from '@_store/company-price-label/company-price-label.effects';
import { CompanyStratalotVacantEffects } from '@_store/company-stratalot-vacant/company-stratalot-vacant.effects';
import { CompanyStudyNextActionEffects } from '@_store/company-study-next-action/company-study-next-action.effects';
import { CompanyStudyConclusionEffects } from '@_store/company-study-conclusion/company-study-conclusion.effects';
import { CompanyStudyReasonEffects } from '@_store/company-study-reason/company-study-reason.effects';
import { CompanyStudyCriteriaEffects } from '@_store/company-study-criteria/company-study-criteria.effects';
import { CompanyTerritoireEffects } from '@_store/company-territoire/company-territoire.effects';
import { CompanyTerritoireUserEffects } from '@_store/company-territoire-user/company-territoire-user.effects';
import { DiagnosticEffects } from '@_store/diagnostic/diagnostic.effects';
import { DiffusionVisualEffects } from '@_store/diffusion-visual/diffusion-visual.effects';
import { DroitEffects } from '@_store/droit/droit.effects';
import { GeneratedDocumentEffects } from '@_store/generated-document/generated-document.effects';
import { GeneratedDocumentsCompanyEffects } from '@_store/generated-documents-company/generated-documents-company.effects';
import { GeneratedDocumentsResidenceEffects } from '@_store/generated-documents-residence/generated-documents-residence.effects';
import { LeadEffects } from '@_store/lead/lead.effects';
import { LeadStratalotEffects } from '@_store/lead-stratalot/lead-stratalot.effects';
import { LeadTodoEffects } from '@_store/lead-todo/lead-todo.effects';
import { OccupantEffects } from '@_store/occupant/occupant.effects';
import { OrganizationEffects } from '@_store/organization/organization.effects';
import { OrganizationMetierEffects } from '@_store/organization-metier/organization-metier.effects';
import { OrganizationBuyingWishEffects } from '@_store/organization-buying-wish/organization-buying-wish.effects';
import { OrganizationEnergieEffects } from '@_store/organization-energie/organization-energie.effects';
import { OrganizationLeadAvancementEffects } from '@_store/organization-lead-avancement/organization-lead-avancement.effects';
import { OrganizationLeadTodoEffects } from '@_store/organization-lead-todo/organization-lead-todo.effects';
import { OrganizationLeadTodoProfilEffects } from '@_store/organization-lead-todo-profil/organization-lead-todo-profil.effects';
import { OrganizationResidenceTodoProfilEffects } from '@_store/organization-residence-todo-profil/organization-residence-todo-profil.effects';
import { OrganizationStratalotTodoProfilEffects } from '@_store/organization-stratalot-todo-profil/organization-stratalot-todo-profil.effects';
import { OrganizationLeadTodoRuleEffects } from '@_store/organization-lead-todo-rule/organization-lead-todo-rule.effects';
import { OrganizationLeadTodoAppliedRuleEffects } from '@_store/organization-lead-todo-applied-rule/organization-lead-todo-applied-rule.effects';
import { CompanyMediaEffects } from '@_store/company-media/company-media.effects';
import { OrganizationProfilEffects } from '@_store/organization-profil/organization-profil.effects';
import { CompanyCommunicationEffects } from '@_store/company-communication/company-communication.effects';
import { CompanyCommunicationResidenceTodoEffects } from '@_store/company-communication-residence-todo/company-communication-residence-todo.effects';
import { CompanyCommunicationStratalotTodoEffects } from '@_store/company-communication-stratalot-todo/company-communication-stratalot-todo.effects';
import { CompanyCommunicationLeadTodoEffects } from '@_store/company-communication-lead-todo/company-communication-lead-todo.effects';
import { CompanyCommunicationFileEffects } from '@_store/company-communication-file/company-communication-file.effects';
import { CompanyCommunicationMediaEffects } from '@_store/company-communication-media/company-communication-media.effects';
import { CompanyCommunicationRecipientEffects } from '@_store/company-communication-recipient/company-communication-recipient.effects';
import { CompanyCommunicationRecipientMetierEffects } from '@_store/company-communication-recipient-metier/company-communication-recipient-metier.effects';
import { CompanyCommunicationRecipientUserEffects } from '@_store/company-communication-recipient-user/company-communication-recipient-user.effects';
import { CompanyCommunicationGeneratedDocumentEffects } from '@_store/company-communication-generated-document/company-communication-generated-document.effects';
import { OrganizationResidenceTodoEffects } from '@_store/organization-residence-todo/organization-residence-todo.effects';
import { OrganizationResidenceTodoRuleEffects } from '@_store/organization-residence-todo-rule/organization-residence-todo-rule.effects';
import { OrganizationResidenceTodoAppliedRuleEffects } from '@_store/organization-residence-todo-applied-rule/organization-residence-todo-applied-rule.effects';
import { OrganizationSaleCategoryEffects } from '@_store/organization-sale-category/organization-sale-category.effects';
import { OrganizationStratalotAvancementEffects } from '@_store/organization-stratalot-avancement/organization-stratalot-avancement.effects';
import { OrganizationStratalotTodoEffects } from '@_store/organization-stratalot-todo/organization-stratalot-todo.effects';
import { OrganizationResidenceStudyTodoEffects } from '@_store/organization-residence-study-todo/organization-residence-study-todo.effects';
import { OrganizationStratalotTodoRuleEffects } from '@_store/organization-stratalot-todo-rule/organization-stratalot-todo-rule.effects';
import { OrganizationStratalotTodoAppliedRuleEffects } from '@_store/organization-stratalot-todo-applied-rule/organization-stratalot-todo-applied-rule.effects';
import { OrganizationSocieteEffects } from '@_store/organization-societe/organization-societe.effects';
import { CompanyStratalotTypeEffects } from '@_store/company-stratalot-type/company-stratalot-type.effects';
import { OrganizationThirdPartyEffects } from '@_store/organization-third-party/organization-third-party.effects';
import { OrganizationProspectOriginEffects } from '@_store/organization-prospect-origin/organization-prospect-origin.effects';
import { ProspectEffects } from '@_store/prospect/prospect.effects';
import { ProspectBuyingWishEffects } from '@_store/prospect-buying-wish/prospect-buying-wish.effects';
import { ProspectEventEffects } from '@_store/prospect-event/prospect-event.effects';
import { ProfilEffects } from '@_store/profil/profil.effects';
import { ResidenceEffects } from '@_store/residence/residence.effects';
import { ResidenceContactEffects } from '@_store/residence-contact/residence-contact.effects';
import { ResidenceShareEffects } from '@_store/residence-share/residence-share.effects';
import { ResidenceShareRcpEffects } from '@_store/residence-share-rcp/residence-share-rcp.effects';
import { ResidenceStudyEffects } from '@_store/residence-study/residence-study.effects';
import { ResidenceStudyCriteriaEffects } from '@_store/residence-study-criteria/residence-study-criteria.effects';
import { ResidenceStudyWorkEffects } from '@_store/residence-study-work/residence-study-work.effects';
import { ResidenceStudyLotEffects } from '@_store/residence-study-lot/residence-study-lot.effects';
import { ResidenceStudyFoundingEffects } from '@_store/residence-study-founding/residence-study-founding.effects';
import { ResidenceStudyReasonEffects } from '@_store/residence-study-reason/residence-study-reason.effects';
import { ResidenceTodoEffects } from '@_store/residence-todo/residence-todo.effects';
import { ResidenceWorkEffects } from '@_store/residence-work/residence-work.effects';
import { ResidenceSaleCategoryFamilyEffects } from '@_store/residence-sale-category-family/residence-sale-category-family.effects';
import { SaleCategoryFamilyEffects } from '@_store/sale-category-family/sale-category-family.effects';
import { StepEffects } from '@_store/step/step.effects';
import { StepProgressEffects } from '@_store/step-progress/step-progress.effects';
import { StepProgressFamilyEffects } from '@_store/step-progress-family/step-progress-family.effects';
import { StratalotFeeEffects } from '@_store/stratalot-fee/stratalot-fee.effects';
import { StratalotEffects } from '@_store/stratalot/stratalot.effects';
import { StratalotCampaignEffects } from '@_store/stratalot-campaign/stratalot-campaign.effects';
import { StratalotPriceEffects } from '@_store/stratalot-price/stratalot-price.effects';
import { ResidencePriceGridEffects } from '@_store/residence-price-grid/residence-price-grid.effects';
import { StratalotPriceListEffects } from '@_store/stratalot-price-list/stratalot-price-list.effects';
import { ResidencePriceGridModifierEffects } from '@_store/residence-price-grid-modifier/residence-price-grid-modifier.effects';
import { ResidencePriceGridHistoryEffects } from '@_store/residence-price-grid-history/residence-price-grid-history.effects';
import { StratalotPriceValueEffects } from '@_store/stratalot-price-value/stratalot-price-value.effects';
import { StratalotRcpEffects } from '@_store/stratalot-rcp/stratalot-rcp.effects';
import { StratalotTodoEffects } from '@_store/stratalot-todo/stratalot-todo.effects';
import { ResidenceStudyTodoEffects } from '@_store/residence-study-todo/residence-study-todo.effects';
import { StratalotTypeEffects } from '@_store/stratalot-type/stratalot-type.effects';
import { UserEffects } from '@_store/user/user.effects';
import { ForecastPeriodEffects } from '@_store/forecast-period/forecast-period.effects';
import { OrganizationForecastRateEffects } from '@_store/organization-forecast-rate/organization-forecast-rate.effects';
import { ResidenceForecastEffects } from '@_store/residence-forecast/residence-forecast.effects';
import { ResidenceForecastValueEffects } from '@_store/residence-forecast-value/residence-forecast-value.effects';
import { MetierFamilieEffects } from '@_store/metier-familie/metier-familie.effects';

export const generatedEffects = [
  AssociationEffects,
  StratalotAssociationEffects,
  BoardStateEffects,
  CampaignMediaEffects,
  CityEffects,
  CompanyEffects,
  CompanyUbiflowEffects,
  CompanyMyNotaryEffects,
  CompanyPriceLabelEffects,
  CompanyStratalotVacantEffects,
  CompanyStudyNextActionEffects,
  CompanyStudyConclusionEffects,
  CompanyStudyReasonEffects,
  CompanyStudyCriteriaEffects,
  CompanyTerritoireEffects,
  CompanyTerritoireUserEffects,
  DiagnosticEffects,
  DiffusionVisualEffects,
  DroitEffects,
  GeneratedDocumentEffects,
  GeneratedDocumentsCompanyEffects,
  GeneratedDocumentsResidenceEffects,
  LeadEffects,
  LeadStratalotEffects,
  LeadTodoEffects,
  OccupantEffects,
  OrganizationEffects,
  OrganizationMetierEffects,
  OrganizationBuyingWishEffects,
  OrganizationEnergieEffects,
  OrganizationLeadAvancementEffects,
  OrganizationLeadTodoEffects,
  OrganizationLeadTodoProfilEffects,
  OrganizationResidenceTodoProfilEffects,
  OrganizationStratalotTodoProfilEffects,
  OrganizationLeadTodoRuleEffects,
  OrganizationLeadTodoAppliedRuleEffects,
  CompanyMediaEffects,
  OrganizationProfilEffects,
  CompanyCommunicationEffects,
  CompanyCommunicationResidenceTodoEffects,
  CompanyCommunicationStratalotTodoEffects,
  CompanyCommunicationLeadTodoEffects,
  CompanyCommunicationFileEffects,
  CompanyCommunicationMediaEffects,
  CompanyCommunicationRecipientEffects,
  CompanyCommunicationRecipientMetierEffects,
  CompanyCommunicationRecipientUserEffects,
  CompanyCommunicationGeneratedDocumentEffects,
  OrganizationResidenceTodoEffects,
  OrganizationResidenceTodoRuleEffects,
  OrganizationResidenceTodoAppliedRuleEffects,
  OrganizationSaleCategoryEffects,
  OrganizationStratalotAvancementEffects,
  OrganizationStratalotTodoEffects,
  OrganizationResidenceStudyTodoEffects,
  OrganizationStratalotTodoRuleEffects,
  OrganizationStratalotTodoAppliedRuleEffects,
  OrganizationSocieteEffects,
  CompanyStratalotTypeEffects,
  OrganizationThirdPartyEffects,
  OrganizationProspectOriginEffects,
  ProspectEffects,
  ProspectBuyingWishEffects,
  ProspectEventEffects,
  ProfilEffects,
  ResidenceEffects,
  ResidenceContactEffects,
  ResidenceShareEffects,
  ResidenceShareRcpEffects,
  ResidenceStudyEffects,
  ResidenceStudyCriteriaEffects,
  ResidenceStudyWorkEffects,
  ResidenceStudyLotEffects,
  ResidenceStudyFoundingEffects,
  ResidenceStudyReasonEffects,
  ResidenceTodoEffects,
  ResidenceWorkEffects,
  ResidenceSaleCategoryFamilyEffects,
  SaleCategoryFamilyEffects,
  StepEffects,
  StepProgressEffects,
  StepProgressFamilyEffects,
  StratalotFeeEffects,
  StratalotEffects,
  StratalotCampaignEffects,
  StratalotPriceEffects,
  ResidencePriceGridEffects,
  StratalotPriceListEffects,
  ResidencePriceGridModifierEffects,
  ResidencePriceGridHistoryEffects,
  StratalotPriceValueEffects,
  StratalotRcpEffects,
  StratalotTodoEffects,
  ResidenceStudyTodoEffects,
  StratalotTypeEffects,
  UserEffects,
  ForecastPeriodEffects,
  OrganizationForecastRateEffects,
  ResidenceForecastEffects,
  ResidenceForecastValueEffects,
  MetierFamilieEffects
];
