import { ResidenceStudyState, initialState } from './residence-study.state';
import { Action, createReducer } from '@ngrx/store';
import { residenceStudyReducersGeneratedFunctions } from './residence-study-generated.reducer';

const residenceStudyReducersFunctions = [...residenceStudyReducersGeneratedFunctions];

const residenceStudyReducer = createReducer(initialState, ...residenceStudyReducersFunctions);

export function reducer(state: ResidenceStudyState | undefined, action: Action) {
  return residenceStudyReducer(state, action);
}
