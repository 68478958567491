import {
  OrganizationStratalotTodoAppliedRuleState,
  initialState
} from './organization-stratalot-todo-applied-rule.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationStratalotTodoAppliedRuleReducersGeneratedFunctions } from './organization-stratalot-todo-applied-rule-generated.reducer';

const organizationStratalotTodoAppliedRuleReducersFunctions = [
  ...organizationStratalotTodoAppliedRuleReducersGeneratedFunctions
];

const organizationStratalotTodoAppliedRuleReducer = createReducer(
  initialState,
  ...organizationStratalotTodoAppliedRuleReducersFunctions
);

export function reducer(state: OrganizationStratalotTodoAppliedRuleState | undefined, action: Action) {
  return organizationStratalotTodoAppliedRuleReducer(state, action);
}
