import { Injectable } from '@angular/core';
import { InformationComponent } from '@_shared/dumb/modal/information-component/information.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private matDialog: MatDialog) {}

  public showError(message: string): void {
    this.matDialog.open(InformationComponent, {
      width: '250px',
      data: {
        title: 'Attention',
        question: message,
        theme: 'danger'
      }
    });
  }
}
