import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidencePriceGridModifierActions from './residence-price-grid-modifier.actions';
import { adapter, initialState, ResidencePriceGridModifierState } from './residence-price-grid-modifier.state';
import { Dictionary } from '@ngrx/entity';
import { ResidencePriceGridModifierEntityState } from '@_model/interfaces/residence-price-grid-modifier.model';

export const residencePriceGridModifierReducersGeneratedFunctions: ReducerTypes<
  ResidencePriceGridModifierState,
  readonly ActionCreator[]
>[] = [
  on(ResidencePriceGridModifierActions.getOneResidencePriceGridModifier, (state: ResidencePriceGridModifierState) =>
    setLoadingsState(state, true)
  ),
  on(ResidencePriceGridModifierActions.getManyResidencePriceGridModifiers, (state: ResidencePriceGridModifierState) =>
    setLoadingsState(state, true)
  ),
  on(ResidencePriceGridModifierActions.upsertOneResidencePriceGridModifier, (state: ResidencePriceGridModifierState) =>
    setLoadingsState(state, true)
  ),

  on(
    ResidencePriceGridModifierActions.upsertManyResidencePriceGridModifiersSuccess,
    (state: ResidencePriceGridModifierState, { residencePriceGridModifiers }) =>
      adapter.upsertMany(residencePriceGridModifiers, setLoadingsState(state, false))
  ),
  on(ResidencePriceGridModifierActions.deleteOneResidencePriceGridModifier, (state: ResidencePriceGridModifierState) =>
    setLoadingsState(state, true)
  ),
  on(
    ResidencePriceGridModifierActions.deleteOneResidencePriceGridModifierSuccess,
    (state: ResidencePriceGridModifierState, { idResidencePriceGridModifier }) =>
      adapter.removeOne(idResidencePriceGridModifier, setLoadingsState(state, false))
  ),
  on(ResidencePriceGridModifierActions.clearActive, (state: ResidencePriceGridModifierState) => ({
    ...state,
    actives: []
  })),
  on(
    ResidencePriceGridModifierActions.addManyActives,
    (state: ResidencePriceGridModifierState, { idResidencePriceGridModifiers }) => ({
      ...state,
      actives: state.actives.concat(idResidencePriceGridModifiers)
    })
  ),
  on(
    ResidencePriceGridModifierActions.deleteOneActive,
    (state: ResidencePriceGridModifierState, { idResidencePriceGridModifier }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idResidencePriceGridModifier)
    })
  ),

  on(ResidencePriceGridModifierActions.clearStore, () => initialState),

  on(
    ResidencePriceGridModifierActions.addResidencePriceGridSuccess,
    (state: ResidencePriceGridModifierState, { idResidencePriceGridModifier, idResidencePriceGrid }) => {
      if (!state.entities[idResidencePriceGridModifier]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidencePriceGridModifier]: {
            ...state.entities[idResidencePriceGridModifier],
            residencePriceGrid: idResidencePriceGrid
          }
        }
      };
    }
  ),

  on(
    ResidencePriceGridModifierActions.deleteManyResidencePriceGridSuccess,
    (state: ResidencePriceGridModifierState, { idResidencePriceGrids, idResidencePriceGridModifiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidencePriceGridModifiers.reduce((entities, idResidencePriceGridModifier) => {
            if (!state.entities[idResidencePriceGridModifier]?.residencePriceGrid) {
              return entities;
            }
            entities[idResidencePriceGridModifier] = {
              ...state.entities[idResidencePriceGridModifier],
              residencePriceGrid: idResidencePriceGrids.some(
                (idResidencePriceGrid: number) =>
                  idResidencePriceGrid === state.entities[idResidencePriceGridModifier]?.residencePriceGrid
              )
                ? undefined
                : state.entities[idResidencePriceGridModifier]?.residencePriceGrid
            } as ResidencePriceGridModifierEntityState;
            return entities;
          }, {} as Dictionary<ResidencePriceGridModifierEntityState>)
        }
      };
    }
  ),

  on(
    ResidencePriceGridModifierActions.addCompanyPriceLabelSuccess,
    (state: ResidencePriceGridModifierState, { idResidencePriceGridModifier, idCompanyPriceLabel }) => {
      if (!state.entities[idResidencePriceGridModifier]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidencePriceGridModifier]: {
            ...state.entities[idResidencePriceGridModifier],
            companyPriceLabel: idCompanyPriceLabel
          }
        }
      };
    }
  ),

  on(
    ResidencePriceGridModifierActions.deleteManyCompanyPriceLabelSuccess,
    (state: ResidencePriceGridModifierState, { idCompanyPriceLabels, idResidencePriceGridModifiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidencePriceGridModifiers.reduce((entities, idResidencePriceGridModifier) => {
            if (!state.entities[idResidencePriceGridModifier]?.companyPriceLabel) {
              return entities;
            }
            entities[idResidencePriceGridModifier] = {
              ...state.entities[idResidencePriceGridModifier],
              companyPriceLabel: idCompanyPriceLabels.some(
                (idCompanyPriceLabel: number) =>
                  idCompanyPriceLabel === state.entities[idResidencePriceGridModifier]?.companyPriceLabel
              )
                ? undefined
                : state.entities[idResidencePriceGridModifier]?.companyPriceLabel
            } as ResidencePriceGridModifierEntityState;
            return entities;
          }, {} as Dictionary<ResidencePriceGridModifierEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidencePriceGridModifierState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidencePriceGridModifierState {
  return { ...state, isLoaded, isLoading };
}
