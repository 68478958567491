import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyCommunicationStratalotTodoApiService } from '@_services/api/company-communication-stratalot-todo-api.service';
import { GeneratedCompanyCommunicationStratalotTodoEffects } from './company-communication-stratalot-todo-generated.effects';

@Injectable()
export class CompanyCommunicationStratalotTodoEffects extends GeneratedCompanyCommunicationStratalotTodoEffects {
  constructor(
    actions$: Actions,
    companyCommunicationStratalotTodoApiService: CompanyCommunicationStratalotTodoApiService,
    store$: Store<AppState>
  ) {
    super(actions$, companyCommunicationStratalotTodoApiService, store$);
  }
}
