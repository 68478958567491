import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResidenceStudyApiService } from '@_services/api/residence-study-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedResidenceStudyEffects } from './residence-study-generated.effects';

@Injectable()
export class ResidenceStudyEffects extends GeneratedResidenceStudyEffects {
  constructor(actions$: Actions, residenceStudyApiService: ResidenceStudyApiService, store$: Store<AppState>) {
    super(actions$, residenceStudyApiService, store$);
  }
}
