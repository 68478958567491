import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { ResidenceStudyLot, ResidenceStudyLotEntityState } from '@_model/interfaces/residence-study-lot.model';
import { ResidenceStudyLotApiService } from '@_services/api/residence-study-lot-api.service';
import * as residenceStudyLotActions from '@_store/residence-study-lot/residence-study-lot.actions';
import { getActionsToNormalizeResidenceStudyLot } from '@_config/store/normalization.generated';
import { selectResidenceStudyLotState } from './residence-study-lot-generated.selectors';
import * as residenceStudyActions from '@_store/residence-study/residence-study.actions';

export interface ResidenceStudyLotRelationsIds {
  residenceStudy?: number;
}

export function getDefaultAddResidenceStudyLotActions(
  residenceStudyLot: ResidenceStudyLotEntityState,
  ids?: ResidenceStudyLotRelationsIds
): Action[] {
  const actions: Action[] = [
    residenceStudyLotActions.normalizeManyResidenceStudyLotsAfterUpsert({ residenceStudyLots: [residenceStudyLot] })
  ];

  if (ids?.residenceStudy) {
    actions.push(
      residenceStudyActions.addManyResidenceStudyLotSuccess({
        idResidenceStudy: ids.residenceStudy,
        idResidenceStudyLots: [residenceStudyLot.idResidenceStudyLot]
      })
    );
    actions.push(
      residenceStudyLotActions.addResidenceStudySuccess({
        idResidenceStudyLot: residenceStudyLot.idResidenceStudyLot,
        idResidenceStudy: ids.residenceStudy
      })
    );
  }

  return actions;
}

export function getDefaultDeleteResidenceStudyLotActions(residenceStudyLot: ResidenceStudyLotEntityState): Action[] {
  const actions: Action[] = [
    residenceStudyLotActions.deleteOneResidenceStudyLotSuccess({
      idResidenceStudyLot: residenceStudyLot.idResidenceStudyLot
    })
  ];

  if (residenceStudyLot.residenceStudy) {
    actions.push(
      residenceStudyActions.deleteManyResidenceStudyLotSuccess({
        idResidenceStudyLots: [residenceStudyLot.idResidenceStudyLot],
        idResidenceStudies: [residenceStudyLot.residenceStudy as number]
      })
    );
  }

  return actions;
}

export class GeneratedResidenceStudyLotEffects {
  constructor(
    protected actions$: Actions,
    protected residenceStudyLotApiService: ResidenceStudyLotApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidenceStudyLots$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyLotActions.getManyResidenceStudyLots),
      switchMap(({ params }) =>
        this.residenceStudyLotApiService.getResidenceStudyLots(params).pipe(
          map((residenceStudyLots: ResidenceStudyLot[]) => {
            return residenceStudyLotActions.normalizeManyResidenceStudyLotsAfterUpsert({ residenceStudyLots });
          }),
          catchError(error => of(residenceStudyLotActions.residenceStudyLotsFailure({ error })))
        )
      )
    );
  });

  getOneResidenceStudyLot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyLotActions.getOneResidenceStudyLot),
      switchMap(idResidenceStudyLot =>
        this.residenceStudyLotApiService.getResidenceStudyLot(idResidenceStudyLot).pipe(
          map((residenceStudyLot: ResidenceStudyLot) => {
            return residenceStudyLotActions.normalizeManyResidenceStudyLotsAfterUpsert({
              residenceStudyLots: [residenceStudyLot]
            });
          }),
          catchError(error => of(residenceStudyLotActions.residenceStudyLotsFailure({ error })))
        )
      )
    );
  });

  upsertOneResidenceStudyLot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyLotActions.upsertOneResidenceStudyLot),
      concatMap(
        ({
          residenceStudyLot,
          ids
        }: {
          residenceStudyLot: Partial<ResidenceStudyLot>;
          ids?: ResidenceStudyLotRelationsIds;
        }) => {
          if (residenceStudyLot.idResidenceStudyLot) {
            return this.residenceStudyLotApiService.updateResidenceStudyLot(residenceStudyLot).pipe(
              map((residenceStudyLotReturned: ResidenceStudyLot) => {
                return residenceStudyLotActions.normalizeManyResidenceStudyLotsAfterUpsert({
                  residenceStudyLots: [residenceStudyLotReturned]
                });
              }),
              catchError(error => of(residenceStudyLotActions.residenceStudyLotsFailure({ error })))
            );
          } else {
            return this.residenceStudyLotApiService.addResidenceStudyLot(residenceStudyLot).pipe(
              mergeMap((residenceStudyLotReturned: ResidenceStudyLot) =>
                getDefaultAddResidenceStudyLotActions(residenceStudyLotReturned, ids)
              ),
              catchError(error => of(residenceStudyLotActions.residenceStudyLotsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneResidenceStudyLot$ = createEffect(() => {
    const selectResidenceStudyLotState$ = this.store$.select(selectResidenceStudyLotState);
    return this.actions$.pipe(
      ofType(residenceStudyLotActions.deleteOneResidenceStudyLot),
      withLatestFrom(selectResidenceStudyLotState$),
      concatMap(([{ idResidenceStudyLot }, state]) =>
        this.residenceStudyLotApiService.deleteResidenceStudyLot(idResidenceStudyLot).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidenceStudyLotActions(
                state.entities[idResidenceStudyLot] as ResidenceStudyLotEntityState
              ),
              residenceStudyLotActions.deleteOneResidenceStudyLot.type
            )
          ]),
          catchError(error => of(residenceStudyLotActions.residenceStudyLotsFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidenceStudyLotsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyLotActions.normalizeManyResidenceStudyLotsAfterUpsert),
      concatMap(({ residenceStudyLots }) => {
        const actions: Action[] = getActionsToNormalizeResidenceStudyLot(residenceStudyLots, StoreActionType.upsert);
        return [getMultiAction(actions, '[ResidenceStudyLot] Normalization After Upsert Success')];
      })
    );
  });
}
