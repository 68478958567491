import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { GeneratedAppState } from '@_config/store/reducers.generated';
import { CompanyApiService } from '@_services/api/company-api.service';
import { Company } from '@_shared/models/interfaces/company.model';
import * as CompanyAction from '@_store/company/company.actions';
import { selectActiveCompanies } from '@_store/company/company.selectors';
import * as AppState from '@_store/index.reducers';
import { arraysAreEqual } from '@_utils/common-utils/arrays';
import ActiveCompanyUtils from '@_utils/router/active-company.utils';
import { SelectSchema } from '@_utils/selector.util';
import ClearStateUtils from '@_utils/up-utils/clear-state.utils';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { GeneratedCompanyService } from './company-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends GeneratedCompanyService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions, private companyApiService: CompanyApiService) {
    super(store$, actions$);
  }

  public currentCompany: number;

  public selectAllActiveCompaniesIds(schema: SelectSchema = {}): Observable<number[]> {
    return this.store$.pipe(select(selectActiveCompanies(schema))).pipe(
      switchMap(({ companies }: { companies: Company[] }) => {
        return this.getReady(schema).pipe(map(() => companies));
      }),
      map((companies: Company[]) => ActiveCompanyUtils.mapToCompaniesIds(companies)),
      filter(ids => !!ids?.length),
      distinctUntilChanged(this.isArrayChange())
    );
  }

  public switchCompany(companies: Company[]): void {
    const states: (keyof GeneratedAppState)[] = ClearStateUtils.findAllStateNameToClear();
    this.store$.dispatch(AppState.clearState({ states }));
    this.setActiveCompanies(ActiveCompanyUtils.mapToCompaniesIds(companies));
  }

  public clearState(): void {
    const storeToclear: (keyof GeneratedAppState)[] = ClearStateUtils.findAllStateNameToClear();
    this.store$.dispatch(AppState.clearState({ states: storeToclear }));
  }

  public uploadLogo(idCompany: number, files: File[], logoNum: number): any {
    this.store$.dispatch(CompanyAction.uploadLogo({ idCompany, files, logoNum }));
  }
  public downloadLogo(idCompany: number, idLogo: number): any {
    return this.companyApiService.downloadLogo({
      idCompany,
      idLogo
    });
  }
  private isArrayChange(): (previous: number[], current: number[]) => boolean {
    return (a, b) => {
      const idsA = [...a].sort();
      const idsB = [...b].sort();
      return arraysAreEqual(idsA, idsB);
    };
  }
}
