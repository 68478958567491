import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { Lead, LeadEntityState } from '@_model/interfaces/lead.model';
import { LeadApiService } from '@_services/api/lead-api.service';
import * as leadActions from '@_store/lead/lead.actions';
import { getActionsToNormalizeLead } from '@_config/store/normalization.generated';
import { selectLeadState } from './lead-generated.selectors';
import * as prospectActions from '@_store/prospect/prospect.actions';
import * as organizationLeadAvancementActions from '@_store/organization-lead-avancement/organization-lead-avancement.actions';
import * as prospectEventActions from '@_store/prospect-event/prospect-event.actions';
import { ProspectEvent } from '@_model/interfaces/prospect-event.model';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import { Stratalot } from '@_model/interfaces/stratalot.model';
import * as leadStratalotActions from '@_store/lead-stratalot/lead-stratalot.actions';
import { LeadStratalot } from '@_model/interfaces/lead-stratalot.model';
import * as leadTodoActions from '@_store/lead-todo/lead-todo.actions';
import { LeadTodo } from '@_model/interfaces/lead-todo.model';

export interface LeadRelationsIds {
  prospectEvents?: number | number[];
  stratalots?: number | number[];
  leadStratalots?: number | number[];
  leadTodos?: number | number[];
  prospect?: number;
  organizationLeadAvancement?: number;
}

export function getDefaultAddLeadActions(lead: LeadEntityState, ids?: LeadRelationsIds): Action[] {
  const actions: Action[] = [leadActions.normalizeManyLeadsAfterUpsert({ leads: [lead] })];

  if (ids?.prospect) {
    actions.push(
      prospectActions.addManyLeadSuccess({
        idProspect: ids.prospect,
        idLeads: [lead.idLead]
      })
    );
    actions.push(
      leadActions.addProspectSuccess({
        idLead: lead.idLead,
        idProspect: ids.prospect
      })
    );
  }

  if (ids?.organizationLeadAvancement) {
    actions.push(
      organizationLeadAvancementActions.addManyLeadSuccess({
        idOrganizationLeadAvancement: ids.organizationLeadAvancement,
        idLeads: [lead.idLead]
      })
    );
    actions.push(
      leadActions.addOrganizationLeadAvancementSuccess({
        idLead: lead.idLead,
        idOrganizationLeadAvancement: ids.organizationLeadAvancement
      })
    );
  }

  if (ids?.prospectEvents) {
    if (!Array.isArray(ids.prospectEvents)) {
      actions.push(
        prospectEventActions.upsertOneProspectEvent({
          prospectEvent: {
            idLead: lead.idLead,
            idProspectEvent: ids.prospectEvents as number
          } as ProspectEvent
        })
      );
      actions.push(
        leadActions.addManyProspectEventSuccess({
          idLead: lead.idLead,
          idProspectEvents: [ids.prospectEvents as number]
        })
      );
    } else {
      actions.push(
        prospectEventActions.upsertManyProspectEvents({
          prospectEvents: (ids.prospectEvents as number[]).map((idProspectEvent: number) => ({
            idLead: lead.idLead,
            idProspectEvent
          })) as ProspectEvent[]
        })
      );
      actions.push(
        leadActions.addManyProspectEventSuccess({
          idLead: lead.idLead,
          idProspectEvents: ids.prospectEvents as number[]
        })
      );
    }
  }

  if (ids?.stratalots) {
    if (!Array.isArray(ids.stratalots)) {
      actions.push(
        stratalotActions.upsertOneStratalot({
          stratalot: {
            idLead: lead.idLead,
            idStratalot: ids.stratalots as number
          } as Stratalot & any
        })
      );
      actions.push(
        leadActions.addManyStratalotSuccess({
          idLead: lead.idLead,
          idStratalots: [ids.stratalots as number]
        })
      );
    } else {
      actions.push(
        stratalotActions.upsertManyStratalots({
          stratalots: (ids.stratalots as number[]).map((idStratalot: number) => ({
            idLead: lead.idLead,
            idStratalot
          })) as Stratalot[] & any[]
        })
      );
      actions.push(
        leadActions.addManyStratalotSuccess({
          idLead: lead.idLead,
          idStratalots: ids.stratalots as number[]
        })
      );
    }
  }

  if (ids?.leadStratalots) {
    if (!Array.isArray(ids.leadStratalots)) {
      actions.push(
        leadStratalotActions.upsertOneLeadStratalot({
          leadStratalot: {
            idLead: lead.idLead,
            idLeadStratalot: ids.leadStratalots as number
          } as LeadStratalot
        })
      );
      actions.push(
        leadActions.addManyLeadStratalotSuccess({
          idLead: lead.idLead,
          idLeadStratalots: [ids.leadStratalots as number]
        })
      );
    } else {
      actions.push(
        leadStratalotActions.upsertManyLeadStratalots({
          leadStratalots: (ids.leadStratalots as number[]).map((idLeadStratalot: number) => ({
            idLead: lead.idLead,
            idLeadStratalot
          })) as LeadStratalot[]
        })
      );
      actions.push(
        leadActions.addManyLeadStratalotSuccess({
          idLead: lead.idLead,
          idLeadStratalots: ids.leadStratalots as number[]
        })
      );
    }
  }

  if (ids?.leadTodos) {
    if (!Array.isArray(ids.leadTodos)) {
      actions.push(
        leadTodoActions.upsertOneLeadTodo({
          leadTodo: {
            idLead: lead.idLead,
            idLeadTodo: ids.leadTodos as number
          } as LeadTodo
        })
      );
      actions.push(
        leadActions.addManyLeadTodoSuccess({
          idLead: lead.idLead,
          idLeadTodos: [ids.leadTodos as number]
        })
      );
    } else {
      actions.push(
        leadTodoActions.upsertManyLeadTodos({
          leadTodos: (ids.leadTodos as number[]).map((idLeadTodo: number) => ({
            idLead: lead.idLead,
            idLeadTodo
          })) as LeadTodo[]
        })
      );
      actions.push(
        leadActions.addManyLeadTodoSuccess({
          idLead: lead.idLead,
          idLeadTodos: ids.leadTodos as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteLeadActions(lead: LeadEntityState): Action[] {
  const actions: Action[] = [leadActions.deleteOneLeadSuccess({ idLead: lead.idLead })];

  if (lead.prospect) {
    actions.push(
      prospectActions.deleteManyLeadSuccess({
        idLeads: [lead.idLead],
        idProspects: [lead.prospect as number]
      })
    );
  }

  if (lead.organizationLeadAvancement) {
    actions.push(
      organizationLeadAvancementActions.deleteManyLeadSuccess({
        idLeads: [lead.idLead],
        idOrganizationLeadAvancements: [lead.organizationLeadAvancement as number]
      })
    );
  }

  if (lead.prospectEvents) {
    actions.push(
      prospectEventActions.deleteManyLeadSuccess({
        idLeads: [lead.idLead],
        idProspectEvents: lead.prospectEvents as number[]
      })
    );
  }

  if (lead.stratalots) {
    actions.push(
      stratalotActions.deleteManyLeadSuccess({
        idLeads: [lead.idLead],
        idStratalots: lead.stratalots as number[]
      })
    );
  }

  if (lead.leadStratalots) {
    actions.push(
      leadStratalotActions.deleteManyLeadSuccess({
        idLeads: [lead.idLead],
        idLeadStratalots: lead.leadStratalots as number[]
      })
    );
  }

  if (lead.leadTodos) {
    actions.push(
      leadTodoActions.deleteManyLeadSuccess({
        idLeads: [lead.idLead],
        idLeadTodos: lead.leadTodos as number[]
      })
    );
  }

  return actions;
}

export class GeneratedLeadEffects {
  constructor(
    protected actions$: Actions,
    protected leadApiService: LeadApiService,
    protected store$: Store<AppState>
  ) {}

  getManyLeads$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadActions.getManyLeads),
      switchMap(({ params }) =>
        this.leadApiService.getLeads(params).pipe(
          map((leads: Lead[]) => {
            return leadActions.normalizeManyLeadsAfterUpsert({ leads });
          }),
          catchError(error => of(leadActions.leadsFailure({ error })))
        )
      )
    );
  });

  getOneLead$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadActions.getOneLead),
      switchMap(idLead =>
        this.leadApiService.getLead(idLead).pipe(
          map((lead: Lead) => {
            return leadActions.normalizeManyLeadsAfterUpsert({ leads: [lead] });
          }),
          catchError(error => of(leadActions.leadsFailure({ error })))
        )
      )
    );
  });

  upsertOneLead$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadActions.upsertOneLead),
      concatMap(({ lead, ids }: { lead: Partial<Lead>; ids?: LeadRelationsIds }) => {
        if (lead.idLead) {
          return this.leadApiService.updateLead(lead).pipe(
            map((leadReturned: Lead) => {
              return leadActions.normalizeManyLeadsAfterUpsert({ leads: [leadReturned] });
            }),
            catchError(error => of(leadActions.leadsFailure({ error })))
          );
        } else {
          return this.leadApiService.addLead(lead).pipe(
            mergeMap((leadReturned: Lead) => getDefaultAddLeadActions(leadReturned, ids)),
            catchError(error => of(leadActions.leadsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneLead$ = createEffect(() => {
    const selectLeadState$ = this.store$.select(selectLeadState);
    return this.actions$.pipe(
      ofType(leadActions.deleteOneLead),
      withLatestFrom(selectLeadState$),
      concatMap(([{ idLead }, state]) =>
        this.leadApiService.deleteLead(idLead).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteLeadActions(state.entities[idLead] as LeadEntityState),
              leadActions.deleteOneLead.type
            )
          ]),
          catchError(error => of(leadActions.leadsFailure({ error })))
        )
      )
    );
  });

  normalizeManyLeadsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadActions.normalizeManyLeadsAfterUpsert),
      concatMap(({ leads }) => {
        const actions: Action[] = getActionsToNormalizeLead(leads, StoreActionType.upsert);
        return [getMultiAction(actions, '[Lead] Normalization After Upsert Success')];
      })
    );
  });
}
