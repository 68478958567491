import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { StratalotAssociation, StratalotAssociationEntityState } from '@_model/interfaces/stratalot-association.model';
import { StratalotAssociationRelationsIds } from './stratalot-association-generated.effects';

export const getOneStratalotAssociation = createAction(
  '[StratalotAssociation] Get One StratalotAssociation',
  props<{ idStratalotAssociation: number; params?: any }>()
);

export const getManyStratalotAssociations = createAction(
  '[StratalotAssociation] Get Many StratalotAssociations',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[StratalotAssociation] Add Many Actives StratalotAssociation',
  props<{ idStratalotAssociations: number[] }>()
);

export const deleteOneActive = createAction(
  '[StratalotAssociation] Delete One Active StratalotAssociation',
  props<{ idStratalotAssociation: number }>()
);

export const clearActive = createAction('[StratalotAssociation] Clear Active StratalotAssociation');

export const upsertOneStratalotAssociation = createAction(
  '[StratalotAssociation] Upsert One StratalotAssociation',
  props<{
    stratalotAssociation: Partial<StratalotAssociation>;
    ids?: StratalotAssociationRelationsIds;
  }>()
);

export const upsertManyStratalotAssociations = createAction(
  '[StratalotAssociation] Upsert Many StratalotAssociations',
  props<{
    stratalotAssociations: Partial<StratalotAssociation>[];
    ids?: StratalotAssociationRelationsIds;
  }>()
);

export const upsertManyStratalotAssociationsSuccess = createAction(
  '[StratalotAssociation] Create Many StratalotAssociations Success',
  props<{ stratalotAssociations: StratalotAssociationEntityState[] }>()
);

export const deleteOneStratalotAssociation = createAction(
  '[StratalotAssociation] Delete One StratalotAssociation',
  props<{ idStratalotAssociation: number }>()
);

export const deleteOneStratalotAssociationSuccess = createAction(
  '[StratalotAssociation] Delete One StratalotAssociation Success',
  props<{ idStratalotAssociation: number }>()
);

export const normalizeManyStratalotAssociationsAfterUpsert = createAction(
  '[StratalotAssociation] Normalize Many StratalotAssociations After Upsert',
  props<{ stratalotAssociations: StratalotAssociationEntityState[] }>()
);

export const stratalotAssociationsFailure = createAction(
  '[StratalotAssociation] StratalotAssociations Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[StratalotAssociation] Clear StratalotAssociations');

export const addStratalotSuccess = createAction(
  '[StratalotAssociation] Add Stratalot',
  props<{ idStratalotAssociation: number; idStratalot: number }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[StratalotAssociation] Delete Many Stratalot',
  props<{ idStratalots: number[]; idStratalotAssociations: number[] }>()
);

export const addAssociationSuccess = createAction(
  '[StratalotAssociation] Add Association',
  props<{ idStratalotAssociation: number; idAssociation: number }>()
);

export const deleteManyAssociationSuccess = createAction(
  '[StratalotAssociation] Delete Many Association',
  props<{ idAssociations: number[]; idStratalotAssociations: number[] }>()
);
