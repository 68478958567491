import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyCommunicationMediaActions from './company-communication-media.actions';
import { adapter, initialState, CompanyCommunicationMediaState } from './company-communication-media.state';

export const companyCommunicationMediaReducersGeneratedFunctions: ReducerTypes<
  CompanyCommunicationMediaState,
  readonly ActionCreator[]
>[] = [
  on(CompanyCommunicationMediaActions.getOneCompanyCommunicationMedia, (state: CompanyCommunicationMediaState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyCommunicationMediaActions.getManyCompanyCommunicationMedias, (state: CompanyCommunicationMediaState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyCommunicationMediaActions.upsertOneCompanyCommunicationMedia, (state: CompanyCommunicationMediaState) =>
    setLoadingsState(state, true)
  ),

  on(
    CompanyCommunicationMediaActions.upsertManyCompanyCommunicationMediasSuccess,
    (state: CompanyCommunicationMediaState, { companyCommunicationMedias }) =>
      adapter.upsertMany(companyCommunicationMedias, setLoadingsState(state, false))
  ),
  on(CompanyCommunicationMediaActions.deleteOneCompanyCommunicationMedia, (state: CompanyCommunicationMediaState) =>
    setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationMediaActions.deleteOneCompanyCommunicationMediaSuccess,
    (state: CompanyCommunicationMediaState, { idCompanyCommunicationMedia }) =>
      adapter.removeOne(idCompanyCommunicationMedia, setLoadingsState(state, false))
  ),
  on(CompanyCommunicationMediaActions.clearActive, (state: CompanyCommunicationMediaState) => ({
    ...state,
    actives: []
  })),
  on(
    CompanyCommunicationMediaActions.addManyActives,
    (state: CompanyCommunicationMediaState, { idCompanyCommunicationMedias }) => ({
      ...state,
      actives: state.actives.concat(idCompanyCommunicationMedias)
    })
  ),
  on(
    CompanyCommunicationMediaActions.deleteOneActive,
    (state: CompanyCommunicationMediaState, { idCompanyCommunicationMedia }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCompanyCommunicationMedia)
    })
  ),

  on(CompanyCommunicationMediaActions.clearStore, () => initialState),

  on(
    CompanyCommunicationMediaActions.addCompanyCommunicationSuccess,
    (state: CompanyCommunicationMediaState, { idCompanyCommunicationMedia, idCompanyCommunication }) => {
      if (!state.entities[idCompanyCommunicationMedia]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationMedia]: {
            ...state.entities[idCompanyCommunicationMedia],
            companyCommunication: idCompanyCommunication
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationMediaActions.deleteCompanyCommunicationSuccess,
    (state: CompanyCommunicationMediaState, { idCompanyCommunications, idCompanyCommunicationMedias }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationMedias.reduce((entities, idCompanyCommunicationMedia) => {
            if (!state.entities[idCompanyCommunicationMedia]?.companyCommunication) {
              return entities;
            }
            entities[idCompanyCommunicationMedia] = {
              ...state.entities[idCompanyCommunicationMedia],
              companyCommunication: idCompanyCommunications.some(
                (idCompanyCommunication: number) =>
                  idCompanyCommunication === state.entities[idCompanyCommunicationMedia].companyCommunication
              )
                ? undefined
                : state.entities[idCompanyCommunicationMedia].companyCommunication
            };
            return entities;
          }, {})
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyCommunicationMediaState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyCommunicationMediaState {
  return { ...state, isLoaded, isLoading };
}
