import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyEntityState } from '@_model/interfaces/company.model';

export interface CompanyState extends EntityState<CompanyEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyEntityState> = createEntityAdapter<CompanyEntityState>({
  selectId: o => o.idCompany
});
export const initialState: CompanyState = adapter.getInitialState({ isLoaded: false, isLoading: false, actives: [] });

export const companyFeatureKey = 'company';
