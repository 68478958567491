import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationSaleCategory } from '@_model/interfaces/organization-sale-category.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationSaleCategoryApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationSaleCategories(params?: any): Observable<OrganizationSaleCategory[]> {
    return this.repo.getData<OrganizationSaleCategory[]>('organization-sale-category', params);
  }

  public getOrganizationSaleCategory(params: {
    idOrganizationSaleCategory: number;
    params?: any;
  }): Observable<OrganizationSaleCategory> {
    return this.repo.getData<OrganizationSaleCategory>(
      'organization-sale-category/' + params.idOrganizationSaleCategory,
      params.params
    );
  }

  public addOrganizationSaleCategory(
    organizationSaleCategory: Partial<OrganizationSaleCategory>
  ): Observable<OrganizationSaleCategory> {
    return this.repo.create<OrganizationSaleCategory>('organization-sale-category', organizationSaleCategory);
  }

  public updateOrganizationSaleCategory(
    organizationSaleCategory: Partial<OrganizationSaleCategory>
  ): Observable<OrganizationSaleCategory> {
    return this.repo.update('organization-sale-category', organizationSaleCategory);
  }

  public deleteOrganizationSaleCategory(idOrganizationSaleCategory: number): Observable<number> {
    return this.repo.delete('organization-sale-category/' + +idOrganizationSaleCategory);
  }
}
