import { RepositoryService } from '@_services/api/repository.service';
import { ResidencePriceGridModifier } from '@_model/interfaces/residence-price-grid-modifier.model';
import { Observable } from 'rxjs';

export class GeneratedResidencePriceGridModifierApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidencePriceGridModifiers(params?: any): Observable<ResidencePriceGridModifier[]> {
    return this.repo.getData<ResidencePriceGridModifier[]>('residence-price-grid-modifier', params);
  }

  public getResidencePriceGridModifier(params: {
    idResidencePriceGridModifier: number;
    params?: any;
  }): Observable<ResidencePriceGridModifier> {
    return this.repo.getData<ResidencePriceGridModifier>(
      'residence-price-grid-modifier/' + params.idResidencePriceGridModifier,
      params.params
    );
  }

  public addResidencePriceGridModifier(
    residencePriceGridModifier: Partial<ResidencePriceGridModifier>
  ): Observable<ResidencePriceGridModifier> {
    return this.repo.create<ResidencePriceGridModifier>('residence-price-grid-modifier', residencePriceGridModifier);
  }

  public updateResidencePriceGridModifier(
    residencePriceGridModifier: Partial<ResidencePriceGridModifier>
  ): Observable<ResidencePriceGridModifier> {
    return this.repo.update('residence-price-grid-modifier', residencePriceGridModifier);
  }

  public deleteResidencePriceGridModifier(idResidencePriceGridModifier: number): Observable<number> {
    return this.repo.delete('residence-price-grid-modifier/' + +idResidencePriceGridModifier);
  }
}
