import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyTerritoireEntityState } from '@_model/interfaces/company-territoire.model';

export interface CompanyTerritoireState extends EntityState<CompanyTerritoireEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyTerritoireEntityState> = createEntityAdapter<CompanyTerritoireEntityState>({
  selectId: o => o.idCompanyTerritoire
});
export const initialState: CompanyTerritoireState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyTerritoireFeatureKey = 'companyTerritoire';
