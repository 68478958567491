import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidencePriceGridModifierEntityState } from '@_model/interfaces/residence-price-grid-modifier.model';

export interface ResidencePriceGridModifierState extends EntityState<ResidencePriceGridModifierEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidencePriceGridModifierEntityState> =
  createEntityAdapter<ResidencePriceGridModifierEntityState>({
    selectId: o => o.idResidencePriceGridModifier
  });
export const initialState: ResidencePriceGridModifierState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residencePriceGridModifierFeatureKey = 'residencePriceGridModifier';
