import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationForecastRate,
  OrganizationForecastRateEntityState
} from '@_model/interfaces/organization-forecast-rate.model';
import { OrganizationForecastRateRelationsIds } from './organization-forecast-rate-generated.effects';

export const getOneOrganizationForecastRate = createAction(
  '[OrganizationForecastRate] Get One OrganizationForecastRate',
  props<{ idOrganizationForecastRate: number; params?: any }>()
);

export const getManyOrganizationForecastRates = createAction(
  '[OrganizationForecastRate] Get Many OrganizationForecastRates',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationForecastRate] Add Many Actives OrganizationForecastRate',
  props<{ idOrganizationForecastRates: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationForecastRate] Delete One Active OrganizationForecastRate',
  props<{ idOrganizationForecastRate: number }>()
);

export const clearActive = createAction('[OrganizationForecastRate] Clear Active OrganizationForecastRate');

export const upsertOneOrganizationForecastRate = createAction(
  '[OrganizationForecastRate] Upsert One OrganizationForecastRate',
  props<{
    organizationForecastRate: Partial<OrganizationForecastRate>;
    ids?: OrganizationForecastRateRelationsIds;
  }>()
);

export const upsertManyOrganizationForecastRates = createAction(
  '[OrganizationForecastRate] Upsert Many OrganizationForecastRates',
  props<{
    organizationForecastRates: Partial<OrganizationForecastRate>[];
    ids?: OrganizationForecastRateRelationsIds;
  }>()
);

export const upsertManyOrganizationForecastRatesSuccess = createAction(
  '[OrganizationForecastRate] Create Many OrganizationForecastRates Success',
  props<{ organizationForecastRates: OrganizationForecastRateEntityState[] }>()
);

export const deleteOneOrganizationForecastRate = createAction(
  '[OrganizationForecastRate] Delete One OrganizationForecastRate',
  props<{ idOrganizationForecastRate: number }>()
);

export const deleteOneOrganizationForecastRateSuccess = createAction(
  '[OrganizationForecastRate] Delete One OrganizationForecastRate Success',
  props<{ idOrganizationForecastRate: number }>()
);

export const normalizeManyOrganizationForecastRatesAfterUpsert = createAction(
  '[OrganizationForecastRate] Normalize Many OrganizationForecastRates After Upsert',
  props<{ organizationForecastRates: OrganizationForecastRateEntityState[] }>()
);

export const organizationForecastRatesFailure = createAction(
  '[OrganizationForecastRate] OrganizationForecastRates Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationForecastRate] Clear OrganizationForecastRates');

export const addManyResidenceSuccess = createAction(
  '[OrganizationForecastRate] Add Many residence',
  props<{ idOrganizationForecastRate: number; idResidences: number[] }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[OrganizationForecastRate] Delete Many Residences',
  props<{ idResidences: number[]; idOrganizationForecastRates: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[OrganizationForecastRate] Add Organization',
  props<{ idOrganizationForecastRate: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationForecastRate] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationForecastRates: number[] }>()
);
