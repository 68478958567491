import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ProspectEventApiService } from '@_services/api/prospect-event-api.service';
import { ProspectEvent } from '@_shared/models/interfaces/prospect-event.model';
import { AppState } from '@_store/index.reducers';
import * as leadActions from '@_store/lead/lead.actions';
import * as ProspectEventActions from '@_store/prospect-event/prospect-event.actions';
import * as prospectActions from '@_store/prospect/prospect.actions';
import * as userActions from '@_store/user/user.actions';
import { of } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { GeneratedProspectEventEffects } from './prospect-event-generated.effects';
@Injectable()
export class ProspectEventEffects extends GeneratedProspectEventEffects {
  constructor(actions$: Actions, prospectEventApiService: ProspectEventApiService, store$: Store<AppState>) {
    super(actions$, prospectEventApiService, store$);
  }
  addOnePropectEventForPublipostage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProspectEventActions.addOnePropectEventForPublipostage),
      concatMap(({ idLead, idGeneratedDocument }) =>
        this.prospectEventApiService.addProspectForPublipostageEventByLead(idLead, idGeneratedDocument).pipe(
          concatMap((prospectEvent: ProspectEvent) => {
            const actions: Action[] = [
              ProspectEventActions.normalizeManyProspectEventsAfterUpsert({ prospectEvents: [prospectEvent] })
            ];
            if (prospectEvent.idProspect) {
              const action = prospectActions.addManyProspectEventSuccess({
                idProspect: prospectEvent.idProspect,
                idProspectEvents: [prospectEvent.idProspectEvent]
              });
              actions.push(action);
            }

            if (prospectEvent.idLead) {
              const action = leadActions.addManyProspectEventSuccess({
                idLead: prospectEvent.idLead,
                idProspectEvents: [prospectEvent.idProspectEvent]
              });
              actions.push(action);
            }

            if (prospectEvent.idUser) {
              const action = userActions.addManyProspectEventSuccess({
                idUser: prospectEvent.idUser,
                idProspectEvents: [prospectEvent.idProspectEvent]
              });
              actions.push(action);
            }
            return actions;
          }),
          catchError(error => of(ProspectEventActions.prospectEventsFailure({ error })))
        )
      )
    );
  });
}
