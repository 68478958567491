import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ResidenceStudyLotApiService } from '@_services/api/residence-study-lot-api.service';
import { GeneratedResidenceStudyLotEffects } from './residence-study-lot-generated.effects';

@Injectable()
export class ResidenceStudyLotEffects extends GeneratedResidenceStudyLotEffects {
  constructor(actions$: Actions, residenceStudyLotApiService: ResidenceStudyLotApiService, store$: Store<AppState>) {
    super(actions$, residenceStudyLotApiService, store$);
  }
}
