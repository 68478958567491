import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { GeneratedDocumentsCompanyEntityState } from '@_model/interfaces/generated-documents-company.model';

export interface GeneratedDocumentsCompanyState extends EntityState<GeneratedDocumentsCompanyEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<GeneratedDocumentsCompanyEntityState> =
  createEntityAdapter<GeneratedDocumentsCompanyEntityState>({
    selectId: o => o.idGeneratedDocumentsCompany
  });
export const initialState: GeneratedDocumentsCompanyState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const generatedDocumentsCompanyFeatureKey = 'generatedDocumentsCompany';
