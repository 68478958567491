import { StratalotState, initialState } from './stratalot.state';
import { Action, createReducer, on } from '@ngrx/store';
import { stratalotReducersGeneratedFunctions } from './stratalot-generated.reducer';
import * as StratalotActions from '@_store/stratalot/stratalot.actions';

const stratalotReducersFunctions = [
  on(StratalotActions.deleteManyOccupantSuccess, (state: StratalotState, { idOccupants }) => {
    return {
      ...state,
      entities: Object.keys(state.entities)
        .map((idStratalot: string) => ({
          ...state.entities[idStratalot],
          occupant: idOccupants.some((idOccupant: number) => idOccupant === state.entities[idStratalot].occupant)
            ? undefined
            : state.entities[idStratalot].occupant,
          idOccupant: idOccupants.some((idOccupant: number) => idOccupant === state.entities[idStratalot].occupant)
            ? undefined
            : state.entities[idStratalot].idOccupant
        }))
        .reduce((acc, curr) => {
          acc[curr.idStratalot] = curr;
          return acc;
        }, {})
    };
  }),
  ...stratalotReducersGeneratedFunctions
];

const stratalotReducer = createReducer(initialState, ...stratalotReducersFunctions);

export function reducer(state: StratalotState | undefined, action: Action) {
  return stratalotReducer(state, action);
}
