import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationStratalotTodoProfil } from '@_model/interfaces/organization-stratalot-todo-profil.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationStratalotTodoProfilApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationStratalotTodoProfils(params?: any): Observable<OrganizationStratalotTodoProfil[]> {
    return this.repo.getData<OrganizationStratalotTodoProfil[]>('organization-stratalot-todo-profil', params);
  }

  public getOrganizationStratalotTodoProfil(params: {
    idOrganizationStratalotTodoProfil: number;
    params?: any;
  }): Observable<OrganizationStratalotTodoProfil> {
    return this.repo.getData<OrganizationStratalotTodoProfil>(
      'organization-stratalot-todo-profil/' + params.idOrganizationStratalotTodoProfil,
      params.params
    );
  }

  public addOrganizationStratalotTodoProfil(
    organizationStratalotTodoProfil: Partial<OrganizationStratalotTodoProfil>
  ): Observable<OrganizationStratalotTodoProfil> {
    return this.repo.create<OrganizationStratalotTodoProfil>(
      'organization-stratalot-todo-profil',
      organizationStratalotTodoProfil
    );
  }

  public updateOrganizationStratalotTodoProfil(
    organizationStratalotTodoProfil: Partial<OrganizationStratalotTodoProfil>
  ): Observable<OrganizationStratalotTodoProfil> {
    return this.repo.update('organization-stratalot-todo-profil', organizationStratalotTodoProfil);
  }

  public deleteOrganizationStratalotTodoProfil(idOrganizationStratalotTodoProfil: number): Observable<number> {
    return this.repo.delete('organization-stratalot-todo-profil/' + +idOrganizationStratalotTodoProfil);
  }
}
