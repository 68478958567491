import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidenceStudyEntityState } from '@_model/interfaces/residence-study.model';

export interface ResidenceStudyState extends EntityState<ResidenceStudyEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidenceStudyEntityState> = createEntityAdapter<ResidenceStudyEntityState>({
  selectId: o => o.idResidenceStudy
});
export const initialState: ResidenceStudyState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residenceStudyFeatureKey = 'residenceStudy';
