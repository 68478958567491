import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { StratalotPriceList, StratalotPriceListEntityState } from '@_model/interfaces/stratalot-price-list.model';
import { StratalotPriceListRelationsIds } from './stratalot-price-list-generated.effects';

export const getOneStratalotPriceList = createAction(
  '[StratalotPriceList] Get One StratalotPriceList',
  props<{ idStratalotPriceList: number; params?: any }>()
);

export const getManyStratalotPriceLists = createAction(
  '[StratalotPriceList] Get Many StratalotPriceLists',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[StratalotPriceList] Add Many Actives StratalotPriceList',
  props<{ idStratalotPriceLists: number[] }>()
);

export const deleteOneActive = createAction(
  '[StratalotPriceList] Delete One Active StratalotPriceList',
  props<{ idStratalotPriceList: number }>()
);

export const clearActive = createAction('[StratalotPriceList] Clear Active StratalotPriceList');

export const upsertOneStratalotPriceList = createAction(
  '[StratalotPriceList] Upsert One StratalotPriceList',
  props<{
    stratalotPriceList: Partial<StratalotPriceList>;
    ids?: StratalotPriceListRelationsIds;
  }>()
);

export const upsertManyStratalotPriceLists = createAction(
  '[StratalotPriceList] Upsert Many StratalotPriceLists',
  props<{
    stratalotPriceLists: Partial<StratalotPriceList>[];
    ids?: StratalotPriceListRelationsIds;
  }>()
);

export const upsertManyStratalotPriceListsSuccess = createAction(
  '[StratalotPriceList] Create Many StratalotPriceLists Success',
  props<{ stratalotPriceLists: StratalotPriceListEntityState[] }>()
);

export const deleteOneStratalotPriceList = createAction(
  '[StratalotPriceList] Delete One StratalotPriceList',
  props<{ idStratalotPriceList: number }>()
);

export const deleteOneStratalotPriceListSuccess = createAction(
  '[StratalotPriceList] Delete One StratalotPriceList Success',
  props<{ idStratalotPriceList: number }>()
);

export const normalizeManyStratalotPriceListsAfterUpsert = createAction(
  '[StratalotPriceList] Normalize Many StratalotPriceLists After Upsert',
  props<{ stratalotPriceLists: StratalotPriceListEntityState[] }>()
);

export const stratalotPriceListsFailure = createAction(
  '[StratalotPriceList] StratalotPriceLists Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[StratalotPriceList] Clear StratalotPriceLists');

export const addResidencePriceGridSuccess = createAction(
  '[StratalotPriceList] Add ResidencePriceGrid',
  props<{ idStratalotPriceList: number; idResidencePriceGrid: number }>()
);

export const deleteManyResidencePriceGridSuccess = createAction(
  '[StratalotPriceList] Delete Many ResidencePriceGrid',
  props<{ idResidencePriceGrids: number[]; idStratalotPriceLists: number[] }>()
);

export const addCompanyStratalotTypeSuccess = createAction(
  '[StratalotPriceList] Add CompanyStratalotType',
  props<{ idStratalotPriceList: number; idCompanyStratalotType: number }>()
);

export const deleteManyCompanyStratalotTypeSuccess = createAction(
  '[StratalotPriceList] Delete Many CompanyStratalotType',
  props<{ idCompanyStratalotTypes: number[]; idStratalotPriceLists: number[] }>()
);
