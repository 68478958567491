import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResidenceStudy, ResidenceStudyEntityState } from '@_model/interfaces/residence-study.model';
import { ResidenceStudyReason, ResidenceStudyReasonEntityState } from '@_model/interfaces/residence-study-reason.model';
import {
  ResidenceStudyCriteria,
  ResidenceStudyCriteriaEntityState
} from '@_model/interfaces/residence-study-criteria.model';
import { ResidenceStudyWork, ResidenceStudyWorkEntityState } from '@_model/interfaces/residence-study-work.model';
import { ResidenceStudyTodo, ResidenceStudyTodoEntityState } from '@_model/interfaces/residence-study-todo.model';
import { ResidenceStudyLot, ResidenceStudyLotEntityState } from '@_model/interfaces/residence-study-lot.model';
import {
  ResidenceStudyFounding,
  ResidenceStudyFoundingEntityState
} from '@_model/interfaces/residence-study-founding.model';
import { CompanyStudyReason, CompanyStudyReasonEntityState } from '@_model/interfaces/company-study-reason.model';
import {
  CompanyStudyNextAction,
  CompanyStudyNextActionEntityState
} from '@_model/interfaces/company-study-next-action.model';
import {
  CompanyStudyConclusion,
  CompanyStudyConclusionEntityState
} from '@_model/interfaces/company-study-conclusion.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, residenceStudyFeatureKey, ResidenceStudyState } from './residence-study.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residenceStudyRelations: string[] = [
  'residenceStudyReasons',
  'residenceStudyCriterias',
  'residenceStudyWorks',
  'residenceStudyTodos',
  'residenceStudyLots',
  'residenceStudyFoundings',
  'companyStudyReasons',
  'companyStudyNextActions',
  'companyStudyConclusions',
  'companies'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidenceStudyState = createFeatureSelector<ResidenceStudyState>(residenceStudyFeatureKey);

export const selectIsLoadedResidenceStudy = createSelector(
  selectResidenceStudyState,
  (state: ResidenceStudyState) => state.isLoaded
);

export const selectIsLoadingResidenceStudy = createSelector(
  selectResidenceStudyState,
  (state: ResidenceStudyState) => state.isLoading
);

export const selectIsReadyResidenceStudy = createSelector(
  selectResidenceStudyState,
  (state: ResidenceStudyState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidenceStudy = createSelector(
  selectResidenceStudyState,
  (state: ResidenceStudyState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidenceStudyModel: SelectorModel = {
  name: 'residenceStudies',
  getSelector: selectAllResidenceStudiesDictionary,
  isReady: selectIsReadyResidenceStudy
};

export const selectResidenceStudiesEntities = createSelector(selectResidenceStudyState, selectEntities);

export const selectResidenceStudiesArray = createSelector(selectResidenceStudyState, selectAll);

export const selectIdResidenceStudiesActive = createSelector(
  selectResidenceStudyState,
  (state: ResidenceStudyState) => state.actives
);

const residenceStudiesInObject = (residenceStudies: Dictionary<ResidenceStudyEntityState>) => ({ residenceStudies });

const selectResidenceStudiesEntitiesDictionary = createSelector(
  selectResidenceStudiesEntities,
  residenceStudiesInObject
);

const selectAllResidenceStudiesObject = createSelector(selectResidenceStudiesEntities, residenceStudies => {
  return hydrateAll({ residenceStudies });
});

const selectOneResidenceStudyDictionary = (idResidenceStudy: number) =>
  createSelector(selectResidenceStudiesEntities, residenceStudies => {
    return { residenceStudies: { [idResidenceStudy]: residenceStudies[idResidenceStudy] } };
  });

const selectOneResidenceStudyDictionaryWithoutChild = (idResidenceStudy: number) =>
  createSelector(selectResidenceStudiesEntities, residenceStudies => {
    return { residenceStudy: residenceStudies[idResidenceStudy] };
  });

const selectActiveResidenceStudiesEntities = createSelector(
  selectIdResidenceStudiesActive,
  selectResidenceStudiesEntities,
  (actives: number[], residenceStudies: Dictionary<ResidenceStudyEntityState>) =>
    getResidenceStudiesFromActives(actives, residenceStudies)
);

function getResidenceStudiesFromActives(
  actives: number[],
  residenceStudies: Dictionary<ResidenceStudyEntityState>
): Dictionary<ResidenceStudyEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residenceStudies[idActive]) {
      acc[idActive] = residenceStudies[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidenceStudyEntityState>);
}

const selectAllResidenceStudiesSelectors: Dictionary<Selector> = {};
export function selectAllResidenceStudies(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidenceStudy>(
      schema,
      selectAllResidenceStudiesSelectors,
      selectResidenceStudiesEntitiesDictionary,
      getRelationSelectors,
      residenceStudyRelations,
      hydrateAll,
      'residenceStudy'
    );
  } else {
    return selectAllResidenceStudiesObject;
  }
}

export function selectAllResidenceStudiesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residenceStudies'
): Selector {
  return createSelector(selectAllResidenceStudies(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidenceStudyEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residenceStudies.length; i++) {
      res[customKey][result.residenceStudies[i].idResidenceStudy] = result.residenceStudies[i];
    }
    return res;
  });
}

export function selectOneResidenceStudy(schema: SelectSchema = {}, idResidenceStudy: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidenceStudyDictionary(idResidenceStudy)];
    selectors.push(...getRelationSelectors(schema, residenceStudyRelations, 'residenceStudy'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidenceStudyDictionaryWithoutChild(idResidenceStudy);
  }
}

export function selectActiveResidenceStudies(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidenceStudiesEntities, residenceStudies => ({ residenceStudies }))
  ];
  selectors.push(...getRelationSelectors(schema, residenceStudyRelations, 'residenceStudy'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residenceStudies: Dictionary<ResidenceStudyEntityState>;
  companyStudyNextActions?: Dictionary<CompanyStudyNextActionEntityState>;
  companyStudyConclusions?: Dictionary<CompanyStudyConclusionEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  residenceStudyReasons?: Dictionary<ResidenceStudyReasonEntityState>;
  residenceStudyCriterias?: Dictionary<ResidenceStudyCriteriaEntityState>;
  residenceStudyWorks?: Dictionary<ResidenceStudyWorkEntityState>;
  residenceStudyTodos?: Dictionary<ResidenceStudyTodoEntityState>;
  residenceStudyLots?: Dictionary<ResidenceStudyLotEntityState>;
  residenceStudyFoundings?: Dictionary<ResidenceStudyFoundingEntityState>;
  companyStudyReasons?: Dictionary<CompanyStudyReasonEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { residenceStudies: (ResidenceStudy | null)[] } {
  const {
    residenceStudies,
    companyStudyNextActions,
    companyStudyConclusions,
    companies,
    residenceStudyReasons,
    residenceStudyCriterias,
    residenceStudyWorks,
    residenceStudyTodos,
    residenceStudyLots,
    residenceStudyFoundings,
    companyStudyReasons
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    residenceStudies: Object.keys(residenceStudies).map(idResidenceStudy =>
      hydrate(
        residenceStudies[idResidenceStudy] as ResidenceStudyEntityState,
        companyStudyNextActions,
        companyStudyConclusions,
        companies,
        residenceStudyReasons,
        residenceStudyCriterias,
        residenceStudyWorks,
        residenceStudyTodos,
        residenceStudyLots,
        residenceStudyFoundings,
        companyStudyReasons
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { residenceStudy: ResidenceStudyEntityState | null } {
  const {
    residenceStudies,
    companyStudyNextActions,
    companyStudyConclusions,
    companies,
    residenceStudyReasons,
    residenceStudyCriterias,
    residenceStudyWorks,
    residenceStudyTodos,
    residenceStudyLots,
    residenceStudyFoundings,
    companyStudyReasons
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const residenceStudy = Object.values(residenceStudies)[0];
  return {
    residenceStudy: hydrate(
      residenceStudy as ResidenceStudyEntityState,
      companyStudyNextActions,
      companyStudyConclusions,
      companies,
      residenceStudyReasons,
      residenceStudyCriterias,
      residenceStudyWorks,
      residenceStudyTodos,
      residenceStudyLots,
      residenceStudyFoundings,
      companyStudyReasons
    )
  };
}

function hydrate(
  residenceStudy: ResidenceStudyEntityState,
  companyStudyNextActionEntities?: Dictionary<CompanyStudyNextActionEntityState>,
  companyStudyConclusionEntities?: Dictionary<CompanyStudyConclusionEntityState>,
  companyEntities?: Dictionary<CompanyEntityState>,
  residenceStudyReasonEntities?: Dictionary<ResidenceStudyReasonEntityState>,
  residenceStudyCriteriaEntities?: Dictionary<ResidenceStudyCriteriaEntityState>,
  residenceStudyWorkEntities?: Dictionary<ResidenceStudyWorkEntityState>,
  residenceStudyTodoEntities?: Dictionary<ResidenceStudyTodoEntityState>,
  residenceStudyLotEntities?: Dictionary<ResidenceStudyLotEntityState>,
  residenceStudyFoundingEntities?: Dictionary<ResidenceStudyFoundingEntityState>,
  companyStudyReasonEntities?: Dictionary<CompanyStudyReasonEntityState>
): ResidenceStudy | null {
  if (!residenceStudy) {
    return null;
  }

  const residenceStudyHydrated: ResidenceStudyEntityState = { ...residenceStudy };
  if (companyStudyNextActionEntities) {
    residenceStudyHydrated.companyStudyNextAction = companyStudyNextActionEntities[
      residenceStudy.companyStudyNextAction as number
    ] as CompanyStudyNextAction;
  } else {
    delete residenceStudyHydrated.companyStudyNextAction;
  }
  if (companyStudyConclusionEntities) {
    residenceStudyHydrated.companyStudyConclusion = companyStudyConclusionEntities[
      residenceStudy.companyStudyConclusion as number
    ] as CompanyStudyConclusion;
  } else {
    delete residenceStudyHydrated.companyStudyConclusion;
  }
  if (companyEntities) {
    residenceStudyHydrated.company = companyEntities[residenceStudy.company as number] as Company;
  } else {
    delete residenceStudyHydrated.company;
  }

  if (residenceStudyReasonEntities) {
    residenceStudyHydrated.residenceStudyReasons = (
      (residenceStudyHydrated.residenceStudyReasons as number[]) || []
    ).map(id => residenceStudyReasonEntities[id]) as ResidenceStudyReason[];
  } else {
    delete residenceStudyHydrated.residenceStudyReasons;
  }

  if (residenceStudyCriteriaEntities) {
    residenceStudyHydrated.residenceStudyCriterias = (
      (residenceStudyHydrated.residenceStudyCriterias as number[]) || []
    ).map(id => residenceStudyCriteriaEntities[id]) as ResidenceStudyCriteria[];
  } else {
    delete residenceStudyHydrated.residenceStudyCriterias;
  }

  if (residenceStudyWorkEntities) {
    residenceStudyHydrated.residenceStudyWorks = ((residenceStudyHydrated.residenceStudyWorks as number[]) || []).map(
      id => residenceStudyWorkEntities[id]
    ) as ResidenceStudyWork[];
  } else {
    delete residenceStudyHydrated.residenceStudyWorks;
  }

  if (residenceStudyTodoEntities) {
    residenceStudyHydrated.residenceStudyTodos = ((residenceStudyHydrated.residenceStudyTodos as number[]) || []).map(
      id => residenceStudyTodoEntities[id]
    ) as ResidenceStudyTodo[];
  } else {
    delete residenceStudyHydrated.residenceStudyTodos;
  }

  if (residenceStudyLotEntities) {
    residenceStudyHydrated.residenceStudyLots = ((residenceStudyHydrated.residenceStudyLots as number[]) || []).map(
      id => residenceStudyLotEntities[id]
    ) as ResidenceStudyLot[];
  } else {
    delete residenceStudyHydrated.residenceStudyLots;
  }

  if (residenceStudyFoundingEntities) {
    residenceStudyHydrated.residenceStudyFoundings = (
      (residenceStudyHydrated.residenceStudyFoundings as number[]) || []
    ).map(id => residenceStudyFoundingEntities[id]) as ResidenceStudyFounding[];
  } else {
    delete residenceStudyHydrated.residenceStudyFoundings;
  }

  if (companyStudyReasonEntities) {
    residenceStudyHydrated.companyStudyReasons = ((residenceStudyHydrated.companyStudyReasons as number[]) || []).map(
      id => companyStudyReasonEntities[id]
    ) as CompanyStudyReason[];
  } else {
    delete residenceStudyHydrated.companyStudyReasons;
  }

  return residenceStudyHydrated as ResidenceStudy;
}
