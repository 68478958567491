import { RepositoryService } from '@_services/api/repository.service';
import { CompanyStudyCriteria } from '@_model/interfaces/company-study-criteria.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyStudyCriteriaApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyStudyCriterias(params?: any): Observable<CompanyStudyCriteria[]> {
    return this.repo.getData<CompanyStudyCriteria[]>('company-study-criteria', params);
  }

  public getCompanyStudyCriteria(params: {
    idCompanyStudyCriteria: number;
    params?: any;
  }): Observable<CompanyStudyCriteria> {
    return this.repo.getData<CompanyStudyCriteria>(
      'company-study-criteria/' + params.idCompanyStudyCriteria,
      params.params
    );
  }

  public addCompanyStudyCriteria(
    companyStudyCriteria: Partial<CompanyStudyCriteria>
  ): Observable<CompanyStudyCriteria> {
    return this.repo.create<CompanyStudyCriteria>('company-study-criteria', companyStudyCriteria);
  }

  public updateCompanyStudyCriteria(
    companyStudyCriteria: Partial<CompanyStudyCriteria>
  ): Observable<CompanyStudyCriteria> {
    return this.repo.update('company-study-criteria', companyStudyCriteria);
  }

  public deleteCompanyStudyCriteria(idCompanyStudyCriteria: number): Observable<number> {
    return this.repo.delete('company-study-criteria/' + +idCompanyStudyCriteria);
  }
}
