import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationResidenceStudyTodo,
  OrganizationResidenceStudyTodoEntityState
} from '@_model/interfaces/organization-residence-study-todo.model';
import { OrganizationResidenceStudyTodoRelationsIds } from './organization-residence-study-todo-generated.effects';

export const getOneOrganizationResidenceStudyTodo = createAction(
  '[OrganizationResidenceStudyTodo] Get One OrganizationResidenceStudyTodo',
  props<{ idOrganizationResidenceStudyTodo: number; params?: any }>()
);

export const getManyOrganizationResidenceStudyTodos = createAction(
  '[OrganizationResidenceStudyTodo] Get Many OrganizationResidenceStudyTodos',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationResidenceStudyTodo] Add Many Actives OrganizationResidenceStudyTodo',
  props<{ idOrganizationResidenceStudyTodos: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationResidenceStudyTodo] Delete One Active OrganizationResidenceStudyTodo',
  props<{ idOrganizationResidenceStudyTodo: number }>()
);

export const clearActive = createAction('[OrganizationResidenceStudyTodo] Clear Active OrganizationResidenceStudyTodo');

export const upsertOneOrganizationResidenceStudyTodo = createAction(
  '[OrganizationResidenceStudyTodo] Upsert One OrganizationResidenceStudyTodo',
  props<{
    organizationResidenceStudyTodo: Partial<OrganizationResidenceStudyTodo>;
    ids?: OrganizationResidenceStudyTodoRelationsIds;
  }>()
);

export const upsertManyOrganizationResidenceStudyTodos = createAction(
  '[OrganizationResidenceStudyTodo] Upsert Many OrganizationResidenceStudyTodos',
  props<{
    organizationResidenceStudyTodos: Partial<OrganizationResidenceStudyTodo>[];
    ids?: OrganizationResidenceStudyTodoRelationsIds;
  }>()
);

export const upsertManyOrganizationResidenceStudyTodosSuccess = createAction(
  '[OrganizationResidenceStudyTodo] Create Many OrganizationResidenceStudyTodos Success',
  props<{ organizationResidenceStudyTodos: OrganizationResidenceStudyTodoEntityState[] }>()
);

export const deleteOneOrganizationResidenceStudyTodo = createAction(
  '[OrganizationResidenceStudyTodo] Delete One OrganizationResidenceStudyTodo',
  props<{ idOrganizationResidenceStudyTodo: number }>()
);

export const deleteOneOrganizationResidenceStudyTodoSuccess = createAction(
  '[OrganizationResidenceStudyTodo] Delete One OrganizationResidenceStudyTodo Success',
  props<{ idOrganizationResidenceStudyTodo: number }>()
);

export const normalizeManyOrganizationResidenceStudyTodosAfterUpsert = createAction(
  '[OrganizationResidenceStudyTodo] Normalize Many OrganizationResidenceStudyTodos After Upsert',
  props<{ organizationResidenceStudyTodos: OrganizationResidenceStudyTodoEntityState[] }>()
);

export const organizationResidenceStudyTodosFailure = createAction(
  '[OrganizationResidenceStudyTodo] OrganizationResidenceStudyTodos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationResidenceStudyTodo] Clear OrganizationResidenceStudyTodos');

export const addManyResidenceStudyTodoSuccess = createAction(
  '[OrganizationResidenceStudyTodo] Add Many residence-study-todo',
  props<{ idOrganizationResidenceStudyTodo: number; idResidenceStudyTodos: number[] }>()
);

export const deleteManyResidenceStudyTodoSuccess = createAction(
  '[OrganizationResidenceStudyTodo] Delete Many ResidenceStudyTodos',
  props<{ idResidenceStudyTodos: number[]; idOrganizationResidenceStudyTodos: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[OrganizationResidenceStudyTodo] Add Organization',
  props<{ idOrganizationResidenceStudyTodo: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationResidenceStudyTodo] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationResidenceStudyTodos: number[] }>()
);
