import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyCommunicationMediaEntityState } from '@_model/interfaces/company-communication-media.model';

export interface CompanyCommunicationMediaState extends EntityState<CompanyCommunicationMediaEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyCommunicationMediaEntityState> =
  createEntityAdapter<CompanyCommunicationMediaEntityState>({
    selectId: o => o.idCompanyCommunicationMedia
  });
export const initialState: CompanyCommunicationMediaState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyCommunicationMediaFeatureKey = 'companyCommunicationMedia';
