import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResidenceStudyReason, ResidenceStudyReasonEntityState } from '@_model/interfaces/residence-study-reason.model';
import { ResidenceStudy, ResidenceStudyEntityState } from '@_model/interfaces/residence-study.model';
import { CompanyStudyReason, CompanyStudyReasonEntityState } from '@_model/interfaces/company-study-reason.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, residenceStudyReasonFeatureKey, ResidenceStudyReasonState } from './residence-study-reason.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residenceStudyReasonRelations: string[] = ['residenceStudies', 'companyStudyReasons'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidenceStudyReasonState =
  createFeatureSelector<ResidenceStudyReasonState>(residenceStudyReasonFeatureKey);

export const selectIsLoadedResidenceStudyReason = createSelector(
  selectResidenceStudyReasonState,
  (state: ResidenceStudyReasonState) => state.isLoaded
);

export const selectIsLoadingResidenceStudyReason = createSelector(
  selectResidenceStudyReasonState,
  (state: ResidenceStudyReasonState) => state.isLoading
);

export const selectIsReadyResidenceStudyReason = createSelector(
  selectResidenceStudyReasonState,
  (state: ResidenceStudyReasonState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidenceStudyReason = createSelector(
  selectResidenceStudyReasonState,
  (state: ResidenceStudyReasonState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidenceStudyReasonModel: SelectorModel = {
  name: 'residenceStudyReasons',
  getSelector: selectAllResidenceStudyReasonsDictionary,
  isReady: selectIsReadyResidenceStudyReason
};

export const selectResidenceStudyReasonsEntities = createSelector(selectResidenceStudyReasonState, selectEntities);

export const selectResidenceStudyReasonsArray = createSelector(selectResidenceStudyReasonState, selectAll);

export const selectIdResidenceStudyReasonsActive = createSelector(
  selectResidenceStudyReasonState,
  (state: ResidenceStudyReasonState) => state.actives
);

const residenceStudyReasonsInObject = (residenceStudyReasons: Dictionary<ResidenceStudyReasonEntityState>) => ({
  residenceStudyReasons
});

const selectResidenceStudyReasonsEntitiesDictionary = createSelector(
  selectResidenceStudyReasonsEntities,
  residenceStudyReasonsInObject
);

const selectAllResidenceStudyReasonsObject = createSelector(
  selectResidenceStudyReasonsEntities,
  residenceStudyReasons => {
    return hydrateAll({ residenceStudyReasons });
  }
);

const selectOneResidenceStudyReasonDictionary = (idResidenceStudyReason: number) =>
  createSelector(selectResidenceStudyReasonsEntities, residenceStudyReasons => {
    return { residenceStudyReasons: { [idResidenceStudyReason]: residenceStudyReasons[idResidenceStudyReason] } };
  });

const selectOneResidenceStudyReasonDictionaryWithoutChild = (idResidenceStudyReason: number) =>
  createSelector(selectResidenceStudyReasonsEntities, residenceStudyReasons => {
    return { residenceStudyReason: residenceStudyReasons[idResidenceStudyReason] };
  });

const selectActiveResidenceStudyReasonsEntities = createSelector(
  selectIdResidenceStudyReasonsActive,
  selectResidenceStudyReasonsEntities,
  (actives: number[], residenceStudyReasons: Dictionary<ResidenceStudyReasonEntityState>) =>
    getResidenceStudyReasonsFromActives(actives, residenceStudyReasons)
);

function getResidenceStudyReasonsFromActives(
  actives: number[],
  residenceStudyReasons: Dictionary<ResidenceStudyReasonEntityState>
): Dictionary<ResidenceStudyReasonEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residenceStudyReasons[idActive]) {
      acc[idActive] = residenceStudyReasons[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidenceStudyReasonEntityState>);
}

const selectAllResidenceStudyReasonsSelectors: Dictionary<Selector> = {};
export function selectAllResidenceStudyReasons(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidenceStudyReason>(
      schema,
      selectAllResidenceStudyReasonsSelectors,
      selectResidenceStudyReasonsEntitiesDictionary,
      getRelationSelectors,
      residenceStudyReasonRelations,
      hydrateAll,
      'residenceStudyReason'
    );
  } else {
    return selectAllResidenceStudyReasonsObject;
  }
}

export function selectAllResidenceStudyReasonsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residenceStudyReasons'
): Selector {
  return createSelector(selectAllResidenceStudyReasons(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidenceStudyReasonEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residenceStudyReasons.length; i++) {
      res[customKey][result.residenceStudyReasons[i].idResidenceStudyReason] = result.residenceStudyReasons[i];
    }
    return res;
  });
}

export function selectOneResidenceStudyReason(schema: SelectSchema = {}, idResidenceStudyReason: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidenceStudyReasonDictionary(idResidenceStudyReason)];
    selectors.push(...getRelationSelectors(schema, residenceStudyReasonRelations, 'residenceStudyReason'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidenceStudyReasonDictionaryWithoutChild(idResidenceStudyReason);
  }
}

export function selectActiveResidenceStudyReasons(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidenceStudyReasonsEntities, residenceStudyReasons => ({ residenceStudyReasons }))
  ];
  selectors.push(...getRelationSelectors(schema, residenceStudyReasonRelations, 'residenceStudyReason'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residenceStudyReasons: Dictionary<ResidenceStudyReasonEntityState>;
  residenceStudies?: Dictionary<ResidenceStudyEntityState>;
  companyStudyReasons?: Dictionary<CompanyStudyReasonEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { residenceStudyReasons: (ResidenceStudyReason | null)[] } {
  const { residenceStudyReasons, residenceStudies, companyStudyReasons } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    residenceStudyReasons: Object.keys(residenceStudyReasons).map(idResidenceStudyReason =>
      hydrate(
        residenceStudyReasons[idResidenceStudyReason] as ResidenceStudyReasonEntityState,
        residenceStudies,
        companyStudyReasons
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { residenceStudyReason: ResidenceStudyReasonEntityState | null } {
  const { residenceStudyReasons, residenceStudies, companyStudyReasons } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const residenceStudyReason = Object.values(residenceStudyReasons)[0];
  return {
    residenceStudyReason: hydrate(
      residenceStudyReason as ResidenceStudyReasonEntityState,
      residenceStudies,
      companyStudyReasons
    )
  };
}

function hydrate(
  residenceStudyReason: ResidenceStudyReasonEntityState,
  residenceStudyEntities?: Dictionary<ResidenceStudyEntityState>,
  companyStudyReasonEntities?: Dictionary<CompanyStudyReasonEntityState>
): ResidenceStudyReason | null {
  if (!residenceStudyReason) {
    return null;
  }

  const residenceStudyReasonHydrated: ResidenceStudyReasonEntityState = { ...residenceStudyReason };
  if (residenceStudyEntities) {
    residenceStudyReasonHydrated.residenceStudy = residenceStudyEntities[
      residenceStudyReason.residenceStudy as number
    ] as ResidenceStudy;
  } else {
    delete residenceStudyReasonHydrated.residenceStudy;
  }
  if (companyStudyReasonEntities) {
    residenceStudyReasonHydrated.companyStudyReason = companyStudyReasonEntities[
      residenceStudyReason.companyStudyReason as number
    ] as CompanyStudyReason;
  } else {
    delete residenceStudyReasonHydrated.companyStudyReason;
  }

  return residenceStudyReasonHydrated as ResidenceStudyReason;
}
