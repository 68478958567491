import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  CompanyCommunicationMedia,
  CompanyCommunicationMediaEntityState
} from '@_model/interfaces/company-communication-media.model';
import { CompanyCommunicationMediaRelationsIds } from './company-communication-media-generated.effects';

export const getOneCompanyCommunicationMedia = createAction(
  '[CompanyCommunicationMedia] Get One CompanyCommunicationMedia',
  props<{ idCompanyCommunicationMedia: number; params?: any }>()
);

export const getManyCompanyCommunicationMedias = createAction(
  '[CompanyCommunicationMedia] Get Many CompanyCommunicationMedias',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyCommunicationMedia] Add Many Actives CompanyCommunicationMedia',
  props<{ idCompanyCommunicationMedias: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyCommunicationMedia] Delete One Active CompanyCommunicationMedia',
  props<{ idCompanyCommunicationMedia: number }>()
);

export const clearActive = createAction('[CompanyCommunicationMedia] Clear Active CompanyCommunicationMedia');

export const upsertOneCompanyCommunicationMedia = createAction(
  '[CompanyCommunicationMedia] Upsert One CompanyCommunicationMedia',
  props<{
    companyCommunicationMedia: Partial<CompanyCommunicationMedia>;
    ids?: CompanyCommunicationMediaRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationMedias = createAction(
  '[CompanyCommunicationMedia] Upsert Many CompanyCommunicationMedias',
  props<{
    companyCommunicationMedias: Partial<CompanyCommunicationMedia>[];
    ids?: CompanyCommunicationMediaRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationMediasSuccess = createAction(
  '[CompanyCommunicationMedia] Create Many CompanyCommunicationMedias Success',
  props<{ companyCommunicationMedias: CompanyCommunicationMediaEntityState[] }>()
);

export const deleteOneCompanyCommunicationMedia = createAction(
  '[CompanyCommunicationMedia] Delete One CompanyCommunicationMedia',
  props<{ idCompanyCommunicationMedia: number }>()
);

export const deleteOneCompanyCommunicationMediaSuccess = createAction(
  '[CompanyCommunicationMedia] Delete One CompanyCommunicationMedia Success',
  props<{ idCompanyCommunicationMedia: number }>()
);

export const normalizeManyCompanyCommunicationMediasAfterUpsert = createAction(
  '[CompanyCommunicationMedia] Normalize Many CompanyCommunicationMedias After Upsert',
  props<{ companyCommunicationMedias: CompanyCommunicationMediaEntityState[] }>()
);

export const companyCommunicationMediasFailure = createAction(
  '[CompanyCommunicationMedia] CompanyCommunicationMedias Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyCommunicationMedia] Clear CompanyCommunicationMedias');

export const addCompanyCommunicationSuccess = createAction(
  '[CompanyCommunicationMedia] Add CompanyCommunication',
  props<{ idCompanyCommunicationMedia: number; idCompanyCommunication: number }>()
);

export const deleteCompanyCommunicationSuccess = createAction(
  '[CompanyCommunicationMedia] Delete CompanyCommunications',
  props<{ idCompanyCommunications: number[]; idCompanyCommunicationMedias: number[] }>()
);
