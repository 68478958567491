import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceStudyCriteria } from '@_model/interfaces/residence-study-criteria.model';
import { Observable } from 'rxjs';

export class GeneratedResidenceStudyCriteriaApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidenceStudyCriterias(params?: any): Observable<ResidenceStudyCriteria[]> {
    return this.repo.getData<ResidenceStudyCriteria[]>('residence-study-criteria', params);
  }

  public getResidenceStudyCriteria(params: {
    idResidenceStudyCriteria: number;
    params?: any;
  }): Observable<ResidenceStudyCriteria> {
    return this.repo.getData<ResidenceStudyCriteria>(
      'residence-study-criteria/' + params.idResidenceStudyCriteria,
      params.params
    );
  }

  public addResidenceStudyCriteria(
    residenceStudyCriteria: Partial<ResidenceStudyCriteria>
  ): Observable<ResidenceStudyCriteria> {
    return this.repo.create<ResidenceStudyCriteria>('residence-study-criteria', residenceStudyCriteria);
  }

  public updateResidenceStudyCriteria(
    residenceStudyCriteria: Partial<ResidenceStudyCriteria>
  ): Observable<ResidenceStudyCriteria> {
    return this.repo.update('residence-study-criteria', residenceStudyCriteria);
  }

  public deleteResidenceStudyCriteria(idResidenceStudyCriteria: number): Observable<number> {
    return this.repo.delete('residence-study-criteria/' + +idResidenceStudyCriteria);
  }
}
