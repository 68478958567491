import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { StratalotType } from '@_model/interfaces/stratalot-type.model';
import * as AppState from '@_store/index.reducers';
import { StratalotTypeRelationsIds } from '@_store/stratalot-type/stratalot-type-generated.effects';
import * as StratalotTypeAction from '@_store/stratalot-type/stratalot-type.actions';
import {
  selectActiveStratalotTypes,
  selectAllStratalotTypes,
  selectIdStratalotTypesActive,
  selectIsLoadedStratalotType,
  selectIsLoadingStratalotType,
  selectIsReadyAndLoadedStratalotType,
  selectOneStratalotType
} from '@_store/stratalot-type/stratalot-type.selectors';
import { catchApiActions } from '@_utils/http.util';
import { getIsReadySelectors, Selector, SelectSchema } from '@_utils/selector.util';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedStratalotTypeService {
  constructor(protected store$: Store<AppState.AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadedStratalotType));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadingStratalotType));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [selectIsReadyAndLoadedStratalotType as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllStratalotTypes(schema: SelectSchema = {}): Observable<StratalotType[]> {
    return this.store$.pipe(select(selectAllStratalotTypes(schema))).pipe(
      switchMap(({ stratalotTypes }: { stratalotTypes: StratalotType[] }) => {
        return this.getReady(schema).pipe(mapTo(stratalotTypes));
      })
    );
  }

  public selectOneStratalotType(idStratalotType: number, schema: SelectSchema = {}): Observable<StratalotType> {
    return this.store$.pipe(select(selectOneStratalotType(schema, idStratalotType))).pipe(
      switchMap(({ stratalotType }: { stratalotType: StratalotType }) => {
        return this.getReady(schema).pipe(mapTo(stratalotType));
      })
    );
  }

  public selectAllActiveStratalotTypes(schema: SelectSchema = {}): Observable<StratalotType[]> {
    return this.store$.pipe(select(selectActiveStratalotTypes(schema))).pipe(
      switchMap(({ stratalotTypes }: { stratalotTypes: StratalotType[] }) => {
        return this.getReady(schema).pipe(mapTo(stratalotTypes));
      })
    );
  }

  public selectIdStratalotTypesActive(): Observable<number[]> {
    return this.store$.pipe(select(selectIdStratalotTypesActive)).pipe(
      switchMap((idStratalotTypes: number[]) => {
        return this.getReady().pipe(mapTo(idStratalotTypes));
      })
    );
  }

  public getOneStratalotType(
    idStratalotType: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<StratalotType> {
    this.store$.dispatch(StratalotTypeAction.getOneStratalotType({ idStratalotType, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        StratalotTypeAction.normalizeManyStratalotTypesAfterUpsert,
        StratalotTypeAction.stratalotTypesFailure,
        true
      );
    }
  }

  public getManyStratalotTypes(params: any = {}, getResult?: boolean): void | Observable<StratalotType[]> {
    this.store$.dispatch(StratalotTypeAction.getManyStratalotTypes({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        StratalotTypeAction.normalizeManyStratalotTypesAfterUpsert,
        StratalotTypeAction.stratalotTypesFailure
      );
    }
  }

  public upsertOneStratalotType(
    stratalotType: Partial<StratalotType>,
    ids: StratalotTypeRelationsIds = {},
    getResult?: boolean
  ): void | Observable<StratalotType> {
    this.store$.dispatch(StratalotTypeAction.upsertOneStratalotType({ stratalotType, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        StratalotTypeAction.normalizeManyStratalotTypesAfterUpsert,
        StratalotTypeAction.stratalotTypesFailure,
        true
      );
    }
  }

  public deleteOneStratalotType(idStratalotType: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(StratalotTypeAction.deleteOneStratalotType({ idStratalotType }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        StratalotTypeAction.deleteOneStratalotTypeSuccess,
        StratalotTypeAction.stratalotTypesFailure
      );
    }
  }

  public setActiveStratalotTypes(idStratalotTypes: number[]): void {
    this.store$.dispatch(StratalotTypeAction.clearActive());
    this.store$.dispatch(StratalotTypeAction.addManyActives({ idStratalotTypes }));
  }
}
