import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ResidenceStudyFoundingApiService } from '@_services/api/residence-study-founding-api.service';
import { GeneratedResidenceStudyFoundingEffects } from './residence-study-founding-generated.effects';

@Injectable()
export class ResidenceStudyFoundingEffects extends GeneratedResidenceStudyFoundingEffects {
  constructor(
    actions$: Actions,
    residenceStudyFoundingApiService: ResidenceStudyFoundingApiService,
    store$: Store<AppState>
  ) {
    super(actions$, residenceStudyFoundingApiService, store$);
  }
}
