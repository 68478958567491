import { RepositoryService } from '@_services/api/repository.service';
import { CompanyCommunicationStratalotTodo } from '@_model/interfaces/company-communication-stratalot-todo.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyCommunicationStratalotTodoApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyCommunicationStratalotTodos(params?: any): Observable<CompanyCommunicationStratalotTodo[]> {
    return this.repo.getData<CompanyCommunicationStratalotTodo[]>('company-communication-stratalot-todo', params);
  }

  public getCompanyCommunicationStratalotTodo(params: {
    idCompanyCommunicationStratalotTodo: number;
    params?: any;
  }): Observable<CompanyCommunicationStratalotTodo> {
    return this.repo.getData<CompanyCommunicationStratalotTodo>(
      'company-communication-stratalot-todo/' + params.idCompanyCommunicationStratalotTodo,
      params.params
    );
  }

  public addCompanyCommunicationStratalotTodo(
    companyCommunicationStratalotTodo: Partial<CompanyCommunicationStratalotTodo>
  ): Observable<CompanyCommunicationStratalotTodo> {
    return this.repo.create<CompanyCommunicationStratalotTodo>(
      'company-communication-stratalot-todo',
      companyCommunicationStratalotTodo
    );
  }

  public updateCompanyCommunicationStratalotTodo(
    companyCommunicationStratalotTodo: Partial<CompanyCommunicationStratalotTodo>
  ): Observable<CompanyCommunicationStratalotTodo> {
    return this.repo.update('company-communication-stratalot-todo', companyCommunicationStratalotTodo);
  }

  public deleteCompanyCommunicationStratalotTodo(idCompanyCommunicationStratalotTodo: number): Observable<number> {
    return this.repo.delete('company-communication-stratalot-todo/' + +idCompanyCommunicationStratalotTodo);
  }
}
