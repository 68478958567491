import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationProfilActions from './organization-profil.actions';
import { adapter, initialState, OrganizationProfilState } from './organization-profil.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationProfilEntityState } from '@_model/interfaces/organization-profil.model';

export const organizationProfilReducersGeneratedFunctions: ReducerTypes<
  OrganizationProfilState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationProfilActions.getOneOrganizationProfil, (state: OrganizationProfilState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationProfilActions.getManyOrganizationProfils, (state: OrganizationProfilState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationProfilActions.upsertOneOrganizationProfil, (state: OrganizationProfilState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationProfilActions.upsertManyOrganizationProfilsSuccess,
    (state: OrganizationProfilState, { organizationProfils }) =>
      adapter.upsertMany(organizationProfils, setLoadingsState(state, false))
  ),
  on(OrganizationProfilActions.deleteOneOrganizationProfil, (state: OrganizationProfilState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationProfilActions.deleteOneOrganizationProfilSuccess,
    (state: OrganizationProfilState, { idOrganizationProfil }) =>
      adapter.removeOne(idOrganizationProfil, setLoadingsState(state, false))
  ),
  on(OrganizationProfilActions.clearActive, (state: OrganizationProfilState) => ({ ...state, actives: [] })),
  on(OrganizationProfilActions.addManyActives, (state: OrganizationProfilState, { idOrganizationProfils }) => ({
    ...state,
    actives: state.actives.concat(idOrganizationProfils)
  })),
  on(OrganizationProfilActions.deleteOneActive, (state: OrganizationProfilState, { idOrganizationProfil }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idOrganizationProfil)
  })),

  on(OrganizationProfilActions.clearStore, () => initialState),

  on(
    OrganizationProfilActions.addOrganizationSuccess,
    (state: OrganizationProfilState, { idOrganizationProfil, idOrganization }) => {
      if (!state.entities[idOrganizationProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationProfil]: {
            ...state.entities[idOrganizationProfil],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationProfilActions.deleteManyOrganizationSuccess,
    (state: OrganizationProfilState, { idOrganizations, idOrganizationProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationProfils.reduce((entities, idOrganizationProfil) => {
            if (!state.entities[idOrganizationProfil]?.organization) {
              return entities;
            }
            entities[idOrganizationProfil] = {
              ...state.entities[idOrganizationProfil],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationProfil]?.organization
              )
                ? undefined
                : state.entities[idOrganizationProfil]?.organization
            } as OrganizationProfilEntityState;
            return entities;
          }, {} as Dictionary<OrganizationProfilEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationProfilActions.addCompanyPriceLabelSuccess,
    (state: OrganizationProfilState, { idOrganizationProfil, idCompanyPriceLabel }) => {
      if (!state.entities[idOrganizationProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationProfil]: {
            ...state.entities[idOrganizationProfil],
            companyPriceLabel: idCompanyPriceLabel
          }
        }
      };
    }
  ),

  on(
    OrganizationProfilActions.deleteManyCompanyPriceLabelSuccess,
    (state: OrganizationProfilState, { idCompanyPriceLabels, idOrganizationProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationProfils.reduce((entities, idOrganizationProfil) => {
            if (!state.entities[idOrganizationProfil]?.companyPriceLabel) {
              return entities;
            }
            entities[idOrganizationProfil] = {
              ...state.entities[idOrganizationProfil],
              companyPriceLabel: idCompanyPriceLabels.some(
                (idCompanyPriceLabel: number) =>
                  idCompanyPriceLabel === state.entities[idOrganizationProfil]?.companyPriceLabel
              )
                ? undefined
                : state.entities[idOrganizationProfil]?.companyPriceLabel
            } as OrganizationProfilEntityState;
            return entities;
          }, {} as Dictionary<OrganizationProfilEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationProfilState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationProfilState {
  return { ...state, isLoaded, isLoading };
}
