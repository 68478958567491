import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationProfilEntityState } from '@_model/interfaces/organization-profil.model';

export interface OrganizationProfilState extends EntityState<OrganizationProfilEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationProfilEntityState> = createEntityAdapter<OrganizationProfilEntityState>(
  {
    selectId: o => o.idOrganizationProfil
  }
);
export const initialState: OrganizationProfilState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationProfilFeatureKey = 'organizationProfil';
