import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResidenceShareApiService } from '@_services/api/residence-share-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedResidenceShareEffects } from './residence-share-generated.effects';

@Injectable()
export class ResidenceShareEffects extends GeneratedResidenceShareEffects {
  constructor(actions$: Actions, residenceShareApiService: ResidenceShareApiService, store$: Store<AppState>) {
    super(actions$, residenceShareApiService, store$);
  }
}
