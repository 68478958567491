<div class="menu" [ngClass]="{ 'menu-neuf': isUnbuilt, 'menu-ancien': !isUnbuilt }" fxLayout="column">
  <div fxLayout="column" fxLayoutAlign="start center" style="width: 100%; margin: 16px 0">
    <img src="/assets/logo_white.svg" style="width: 100px" />
  </div>

  <div fxLayout="column" fxLayoutAlign="space-between" class="menu-section-container">
    <ng-template #sectionTemplate let-section="section">
      <div
        (click)="onClick(section)"
        class="menu-section"
        [ngClass]="{ active: activeSection ? section.id === activeSection.id : false, opened: section.opened }"
        fxLayout="row"
        fxLayoutAlign="flex-start center"
        fxLayoutGap="1rem"
        [attr.data-cy]="section.dataCy + '-side-menu-section'"
      >
        <mat-icon *ngIf="!section.materialIcon" svgIcon="{{ section.icon }}"></mat-icon>
        <span>{{ section.label }}</span>
      </div>

      <div class="menu-section-children" #childrenSection *ngIf="section?.children?.length > 0 && section.opened">
        <div
          *ngFor="let item of section.children"
          class="menu-item"
          [ngClass]="{ active: item.id === activeSection?.id }"
          (click)="onClick(item)"
          [attr.data-cy]="item.dataCy + '-side-menu-section'"
        >
          {{ item.label }}
        </div>
      </div>
    </ng-template>

    <div>
      <div class="company-choice">
        <mat-select *ngIf="companies?.length >= 2" [formControl]="companiesFilters" [compareWith]="compareWith">
          <mat-option [value]="companies">Toutes</mat-option>
          <mat-option *ngFor="let company of companies" [value]="company">
            {{ company.nom }}
          </mat-option>
        </mat-select>
      </div>
      <div *ngIf="hasAccessToUnbuilt" class="company-choice">
        <mat-select [formControl]="categorieVenteFilter" [compareWith]="compare">
          <mat-option *ngFor="let categorieVente of categorieVentes" [value]="categorieVente">
            {{ categorieVente }}
          </mat-option>
        </mat-select>
      </div>

      <div *ngFor="let section of defaultSections" fxLayout="column">
        <ng-container *ngTemplateOutlet="sectionTemplate; context: { section: section }"></ng-container>
      </div>
    </div>

    <div>
      <div *ngFor="let section of fixedOnBottomSections" fxLayout="column">
        <div *ngIf="!section.admin || isUserAdmin">
          <ng-container *ngTemplateOutlet="sectionTemplate; context: { section: section }"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
