import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CompanyUbiflow, CompanyUbiflowEntityState } from '@_model/interfaces/company-ubiflow.model';
import { CompanyUbiflowRelationsIds } from './company-ubiflow-generated.effects';

export const getOneCompanyUbiflow = createAction(
  '[CompanyUbiflow] Get One CompanyUbiflow',
  props<{ idCompanyUbiflow: number; params?: any }>()
);

export const getManyCompanyUbiflows = createAction(
  '[CompanyUbiflow] Get Many CompanyUbiflows',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyUbiflow] Add Many Actives CompanyUbiflow',
  props<{ idCompanyUbiflows: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyUbiflow] Delete One Active CompanyUbiflow',
  props<{ idCompanyUbiflow: number }>()
);

export const clearActive = createAction('[CompanyUbiflow] Clear Active CompanyUbiflow');

export const upsertOneCompanyUbiflow = createAction(
  '[CompanyUbiflow] Upsert One CompanyUbiflow',
  props<{
    companyUbiflow: Partial<CompanyUbiflow>;
    ids?: CompanyUbiflowRelationsIds;
  }>()
);

export const upsertManyCompanyUbiflows = createAction(
  '[CompanyUbiflow] Upsert Many CompanyUbiflows',
  props<{
    companyUbiflows: Partial<CompanyUbiflow>[];
    ids?: CompanyUbiflowRelationsIds;
  }>()
);

export const upsertManyCompanyUbiflowsSuccess = createAction(
  '[CompanyUbiflow] Create Many CompanyUbiflows Success',
  props<{ companyUbiflows: CompanyUbiflowEntityState[] }>()
);

export const deleteOneCompanyUbiflow = createAction(
  '[CompanyUbiflow] Delete One CompanyUbiflow',
  props<{ idCompanyUbiflow: number }>()
);

export const deleteOneCompanyUbiflowSuccess = createAction(
  '[CompanyUbiflow] Delete One CompanyUbiflow Success',
  props<{ idCompanyUbiflow: number }>()
);

export const normalizeManyCompanyUbiflowsAfterUpsert = createAction(
  '[CompanyUbiflow] Normalize Many CompanyUbiflows After Upsert',
  props<{ companyUbiflows: CompanyUbiflowEntityState[] }>()
);

export const companyUbiflowsFailure = createAction(
  '[CompanyUbiflow] CompanyUbiflows Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyUbiflow] Clear CompanyUbiflows');

export const addCompanySuccess = createAction(
  '[CompanyUbiflow] Add Company',
  props<{ idCompanyUbiflow: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[CompanyUbiflow] Delete Many Company',
  props<{ idCompanies: number[]; idCompanyUbiflows: number[] }>()
);
