import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { GeneratedDocumentsResidenceEntityState } from '@_model/interfaces/generated-documents-residence.model';

export interface GeneratedDocumentsResidenceState extends EntityState<GeneratedDocumentsResidenceEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<GeneratedDocumentsResidenceEntityState> =
  createEntityAdapter<GeneratedDocumentsResidenceEntityState>({
    selectId: o => o.idGeneratedDocumentsResidence
  });
export const initialState: GeneratedDocumentsResidenceState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const generatedDocumentsResidenceFeatureKey = 'generatedDocumentsResidence';
