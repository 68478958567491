import { RepositoryService } from '@_services/api/repository.service';
import { CompanyCommunicationRecipient } from '@_model/interfaces/company-communication-recipient.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyCommunicationRecipientApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyCommunicationRecipients(params?: any): Observable<CompanyCommunicationRecipient[]> {
    return this.repo.getData<CompanyCommunicationRecipient[]>('company-communication-recipient', params);
  }

  public getCompanyCommunicationRecipient(params: {
    idCompanyCommunicationRecipient: number;
    params?: any;
  }): Observable<CompanyCommunicationRecipient> {
    return this.repo.getData<CompanyCommunicationRecipient>(
      'company-communication-recipient/' + params.idCompanyCommunicationRecipient,
      params.params
    );
  }

  public addCompanyCommunicationRecipient(
    companyCommunicationRecipient: Partial<CompanyCommunicationRecipient>
  ): Observable<CompanyCommunicationRecipient> {
    return this.repo.create<CompanyCommunicationRecipient>(
      'company-communication-recipient',
      companyCommunicationRecipient
    );
  }

  public updateCompanyCommunicationRecipient(
    companyCommunicationRecipient: Partial<CompanyCommunicationRecipient>
  ): Observable<CompanyCommunicationRecipient> {
    return this.repo.update('company-communication-recipient', companyCommunicationRecipient);
  }

  public deleteCompanyCommunicationRecipient(idCompanyCommunicationRecipient: number): Observable<number> {
    return this.repo.delete('company-communication-recipient/' + +idCompanyCommunicationRecipient);
  }
}
