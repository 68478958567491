import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyStratalotTypeEntityState } from '@_model/interfaces/company-stratalot-type.model';

export interface CompanyStratalotTypeState extends EntityState<CompanyStratalotTypeEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyStratalotTypeEntityState> =
  createEntityAdapter<CompanyStratalotTypeEntityState>({
    selectId: o => o.idCompanyStratalotType
  });
export const initialState: CompanyStratalotTypeState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyStratalotTypeFeatureKey = 'companyStratalotType';
