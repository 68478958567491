import { CompanyStudyNextActionState, initialState } from './company-study-next-action.state';
import { Action, createReducer } from '@ngrx/store';
import { companyStudyNextActionReducersGeneratedFunctions } from './company-study-next-action-generated.reducer';

const companyStudyNextActionReducersFunctions = [...companyStudyNextActionReducersGeneratedFunctions];

const companyStudyNextActionReducer = createReducer(initialState, ...companyStudyNextActionReducersFunctions);

export function reducer(state: CompanyStudyNextActionState | undefined, action: Action) {
  return companyStudyNextActionReducer(state, action);
}
