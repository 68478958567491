import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CompanyStudyConclusion,
  CompanyStudyConclusionEntityState
} from '@_model/interfaces/company-study-conclusion.model';
import { ResidenceStudy, ResidenceStudyEntityState } from '@_model/interfaces/residence-study.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  companyStudyConclusionFeatureKey,
  CompanyStudyConclusionState
} from './company-study-conclusion.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyStudyConclusionRelations: string[] = ['residenceStudies', 'companies'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyStudyConclusionState = createFeatureSelector<CompanyStudyConclusionState>(
  companyStudyConclusionFeatureKey
);

export const selectIsLoadedCompanyStudyConclusion = createSelector(
  selectCompanyStudyConclusionState,
  (state: CompanyStudyConclusionState) => state.isLoaded
);

export const selectIsLoadingCompanyStudyConclusion = createSelector(
  selectCompanyStudyConclusionState,
  (state: CompanyStudyConclusionState) => state.isLoading
);

export const selectIsReadyCompanyStudyConclusion = createSelector(
  selectCompanyStudyConclusionState,
  (state: CompanyStudyConclusionState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyStudyConclusion = createSelector(
  selectCompanyStudyConclusionState,
  (state: CompanyStudyConclusionState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyStudyConclusionModel: SelectorModel = {
  name: 'companyStudyConclusions',
  getSelector: selectAllCompanyStudyConclusionsDictionary,
  isReady: selectIsReadyCompanyStudyConclusion
};

export const selectCompanyStudyConclusionsEntities = createSelector(selectCompanyStudyConclusionState, selectEntities);

export const selectCompanyStudyConclusionsArray = createSelector(selectCompanyStudyConclusionState, selectAll);

export const selectIdCompanyStudyConclusionsActive = createSelector(
  selectCompanyStudyConclusionState,
  (state: CompanyStudyConclusionState) => state.actives
);

const companyStudyConclusionsInObject = (companyStudyConclusions: Dictionary<CompanyStudyConclusionEntityState>) => ({
  companyStudyConclusions
});

const selectCompanyStudyConclusionsEntitiesDictionary = createSelector(
  selectCompanyStudyConclusionsEntities,
  companyStudyConclusionsInObject
);

const selectAllCompanyStudyConclusionsObject = createSelector(
  selectCompanyStudyConclusionsEntities,
  companyStudyConclusions => {
    return hydrateAll({ companyStudyConclusions });
  }
);

const selectOneCompanyStudyConclusionDictionary = (idCompanyStudyConclusion: number) =>
  createSelector(selectCompanyStudyConclusionsEntities, companyStudyConclusions => {
    return {
      companyStudyConclusions: { [idCompanyStudyConclusion]: companyStudyConclusions[idCompanyStudyConclusion] }
    };
  });

const selectOneCompanyStudyConclusionDictionaryWithoutChild = (idCompanyStudyConclusion: number) =>
  createSelector(selectCompanyStudyConclusionsEntities, companyStudyConclusions => {
    return { companyStudyConclusion: companyStudyConclusions[idCompanyStudyConclusion] };
  });

const selectActiveCompanyStudyConclusionsEntities = createSelector(
  selectIdCompanyStudyConclusionsActive,
  selectCompanyStudyConclusionsEntities,
  (actives: number[], companyStudyConclusions: Dictionary<CompanyStudyConclusionEntityState>) =>
    getCompanyStudyConclusionsFromActives(actives, companyStudyConclusions)
);

function getCompanyStudyConclusionsFromActives(
  actives: number[],
  companyStudyConclusions: Dictionary<CompanyStudyConclusionEntityState>
): Dictionary<CompanyStudyConclusionEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyStudyConclusions[idActive]) {
      acc[idActive] = companyStudyConclusions[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyStudyConclusionEntityState>);
}

const selectAllCompanyStudyConclusionsSelectors: Dictionary<Selector> = {};
export function selectAllCompanyStudyConclusions(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyStudyConclusion>(
      schema,
      selectAllCompanyStudyConclusionsSelectors,
      selectCompanyStudyConclusionsEntitiesDictionary,
      getRelationSelectors,
      companyStudyConclusionRelations,
      hydrateAll,
      'companyStudyConclusion'
    );
  } else {
    return selectAllCompanyStudyConclusionsObject;
  }
}

export function selectAllCompanyStudyConclusionsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyStudyConclusions'
): Selector {
  return createSelector(selectAllCompanyStudyConclusions(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyStudyConclusionEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyStudyConclusions.length; i++) {
      res[customKey][result.companyStudyConclusions[i].idCompanyStudyConclusion] = result.companyStudyConclusions[i];
    }
    return res;
  });
}

export function selectOneCompanyStudyConclusion(schema: SelectSchema = {}, idCompanyStudyConclusion: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyStudyConclusionDictionary(idCompanyStudyConclusion)];
    selectors.push(...getRelationSelectors(schema, companyStudyConclusionRelations, 'companyStudyConclusion'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyStudyConclusionDictionaryWithoutChild(idCompanyStudyConclusion);
  }
}

export function selectActiveCompanyStudyConclusions(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyStudyConclusionsEntities, companyStudyConclusions => ({
      companyStudyConclusions
    }))
  ];
  selectors.push(...getRelationSelectors(schema, companyStudyConclusionRelations, 'companyStudyConclusion'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyStudyConclusions: Dictionary<CompanyStudyConclusionEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  residenceStudies?: Dictionary<ResidenceStudyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { companyStudyConclusions: (CompanyStudyConclusion | null)[] } {
  const { companyStudyConclusions, companies, residenceStudies } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    companyStudyConclusions: Object.keys(companyStudyConclusions).map(idCompanyStudyConclusion =>
      hydrate(
        companyStudyConclusions[idCompanyStudyConclusion] as CompanyStudyConclusionEntityState,
        companies,
        residenceStudies
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { companyStudyConclusion: CompanyStudyConclusionEntityState | null } {
  const { companyStudyConclusions, companies, residenceStudies } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const companyStudyConclusion = Object.values(companyStudyConclusions)[0];
  return {
    companyStudyConclusion: hydrate(
      companyStudyConclusion as CompanyStudyConclusionEntityState,
      companies,
      residenceStudies
    )
  };
}

function hydrate(
  companyStudyConclusion: CompanyStudyConclusionEntityState,
  companyEntities?: Dictionary<CompanyEntityState>,
  residenceStudyEntities?: Dictionary<ResidenceStudyEntityState>
): CompanyStudyConclusion | null {
  if (!companyStudyConclusion) {
    return null;
  }

  const companyStudyConclusionHydrated: CompanyStudyConclusionEntityState = { ...companyStudyConclusion };
  if (companyEntities) {
    companyStudyConclusionHydrated.company = companyEntities[companyStudyConclusion.company as number] as Company;
  } else {
    delete companyStudyConclusionHydrated.company;
  }

  if (residenceStudyEntities) {
    companyStudyConclusionHydrated.residenceStudies = (
      (companyStudyConclusionHydrated.residenceStudies as number[]) || []
    ).map(id => residenceStudyEntities[id]) as ResidenceStudy[];
  } else {
    delete companyStudyConclusionHydrated.residenceStudies;
  }

  return companyStudyConclusionHydrated as CompanyStudyConclusion;
}
