import { UserState, initialState } from './user.state';
import { Action, createReducer } from '@ngrx/store';
import { userReducersGeneratedFunctions } from './user-generated.reducer';

const userReducersFunctions = [...userReducersGeneratedFunctions];

const userReducer = createReducer(initialState, ...userReducersFunctions);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}
