import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyUbiflowApiService } from '@_services/api/company-ubiflow-api.service';
import { GeneratedCompanyUbiflowEffects } from './company-ubiflow-generated.effects';

@Injectable()
export class CompanyUbiflowEffects extends GeneratedCompanyUbiflowEffects {
  constructor(actions$: Actions, companyUbiflowApiService: CompanyUbiflowApiService, store$: Store<AppState>) {
    super(actions$, companyUbiflowApiService, store$);
  }
}
