import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyCommunicationFileActions from './company-communication-file.actions';
import { adapter, initialState, CompanyCommunicationFileState } from './company-communication-file.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyCommunicationFileEntityState } from '@_model/interfaces/company-communication-file.model';

export const companyCommunicationFileReducersGeneratedFunctions: ReducerTypes<
  CompanyCommunicationFileState,
  readonly ActionCreator[]
>[] = [
  on(CompanyCommunicationFileActions.getOneCompanyCommunicationFile, (state: CompanyCommunicationFileState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyCommunicationFileActions.getManyCompanyCommunicationFiles, (state: CompanyCommunicationFileState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyCommunicationFileActions.upsertOneCompanyCommunicationFile, (state: CompanyCommunicationFileState) =>
    setLoadingsState(state, true)
  ),

  on(
    CompanyCommunicationFileActions.upsertManyCompanyCommunicationFilesSuccess,
    (state: CompanyCommunicationFileState, { companyCommunicationFiles }) =>
      adapter.upsertMany(companyCommunicationFiles, setLoadingsState(state, false))
  ),
  on(CompanyCommunicationFileActions.deleteOneCompanyCommunicationFile, (state: CompanyCommunicationFileState) =>
    setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationFileActions.deleteOneCompanyCommunicationFileSuccess,
    (state: CompanyCommunicationFileState, { idCompanyCommunicationFile }) =>
      adapter.removeOne(idCompanyCommunicationFile, setLoadingsState(state, false))
  ),
  on(CompanyCommunicationFileActions.clearActive, (state: CompanyCommunicationFileState) => ({
    ...state,
    actives: []
  })),
  on(
    CompanyCommunicationFileActions.addManyActives,
    (state: CompanyCommunicationFileState, { idCompanyCommunicationFiles }) => ({
      ...state,
      actives: state.actives.concat(idCompanyCommunicationFiles)
    })
  ),
  on(
    CompanyCommunicationFileActions.deleteOneActive,
    (state: CompanyCommunicationFileState, { idCompanyCommunicationFile }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCompanyCommunicationFile)
    })
  ),

  on(CompanyCommunicationFileActions.clearStore, () => initialState),

  on(
    CompanyCommunicationFileActions.addCompanyCommunicationSuccess,
    (state: CompanyCommunicationFileState, { idCompanyCommunicationFile, idCompanyCommunication }) => {
      if (!state.entities[idCompanyCommunicationFile]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationFile]: {
            ...state.entities[idCompanyCommunicationFile],
            companyCommunication: idCompanyCommunication
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationFileActions.deleteManyCompanyCommunicationSuccess,
    (state: CompanyCommunicationFileState, { idCompanyCommunications, idCompanyCommunicationFiles }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationFiles.reduce((entities, idCompanyCommunicationFile) => {
            if (!state.entities[idCompanyCommunicationFile]?.companyCommunication) {
              return entities;
            }
            entities[idCompanyCommunicationFile] = {
              ...state.entities[idCompanyCommunicationFile],
              companyCommunication: idCompanyCommunications.some(
                (idCompanyCommunication: number) =>
                  idCompanyCommunication === state.entities[idCompanyCommunicationFile]?.companyCommunication
              )
                ? undefined
                : state.entities[idCompanyCommunicationFile]?.companyCommunication
            } as CompanyCommunicationFileEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationFileEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyCommunicationFileState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyCommunicationFileState {
  return { ...state, isLoaded, isLoading };
}
