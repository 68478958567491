import { ResidenceStudyFoundingState, initialState } from './residence-study-founding.state';
import { Action, createReducer } from '@ngrx/store';
import { residenceStudyFoundingReducersGeneratedFunctions } from './residence-study-founding-generated.reducer';

const residenceStudyFoundingReducersFunctions = [...residenceStudyFoundingReducersGeneratedFunctions];

const residenceStudyFoundingReducer = createReducer(initialState, ...residenceStudyFoundingReducersFunctions);

export function reducer(state: ResidenceStudyFoundingState | undefined, action: Action) {
  return residenceStudyFoundingReducer(state, action);
}
