import { OrganizationLeadTodoState, initialState } from './organization-lead-todo.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationLeadTodoReducersGeneratedFunctions } from './organization-lead-todo-generated.reducer';

const organizationLeadTodoReducersFunctions = [...organizationLeadTodoReducersGeneratedFunctions];

const organizationLeadTodoReducer = createReducer(initialState, ...organizationLeadTodoReducersFunctions);

export function reducer(state: OrganizationLeadTodoState | undefined, action: Action) {
  return organizationLeadTodoReducer(state, action);
}
