import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as StratalotRcpActions from './stratalot-rcp.actions';
import { adapter, initialState, StratalotRcpState } from './stratalot-rcp.state';
import { Dictionary } from '@ngrx/entity';
import { StratalotRcpEntityState } from '@_model/interfaces/stratalot-rcp.model';

export const stratalotRcpReducersGeneratedFunctions: ReducerTypes<StratalotRcpState, readonly ActionCreator[]>[] = [
  on(StratalotRcpActions.getOneStratalotRcp, (state: StratalotRcpState) => setLoadingsState(state, true)),
  on(StratalotRcpActions.getManyStratalotRcps, (state: StratalotRcpState) => setLoadingsState(state, true)),
  on(StratalotRcpActions.upsertOneStratalotRcp, (state: StratalotRcpState) => setLoadingsState(state, true)),

  on(StratalotRcpActions.upsertManyStratalotRcpsSuccess, (state: StratalotRcpState, { stratalotRcps }) =>
    adapter.upsertMany(stratalotRcps, setLoadingsState(state, false))
  ),
  on(StratalotRcpActions.deleteOneStratalotRcp, (state: StratalotRcpState) => setLoadingsState(state, true)),
  on(StratalotRcpActions.deleteOneStratalotRcpSuccess, (state: StratalotRcpState, { idStratalotRcp }) =>
    adapter.removeOne(idStratalotRcp, setLoadingsState(state, false))
  ),
  on(StratalotRcpActions.clearActive, (state: StratalotRcpState) => ({ ...state, actives: [] })),
  on(StratalotRcpActions.addManyActives, (state: StratalotRcpState, { idStratalotRcps }) => ({
    ...state,
    actives: state.actives.concat(idStratalotRcps)
  })),
  on(StratalotRcpActions.deleteOneActive, (state: StratalotRcpState, { idStratalotRcp }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idStratalotRcp)
  })),

  on(StratalotRcpActions.clearStore, () => initialState),

  on(
    StratalotRcpActions.addManyResidenceShareSuccess,
    (state: StratalotRcpState, { idStratalotRcp, idResidenceShares }) => {
      if (!state.entities[idStratalotRcp]) {
        return state;
      }
      const residenceShares = (state.entities[idStratalotRcp]?.residenceShares as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotRcp]: {
            ...state.entities[idStratalotRcp],
            residenceShares: residenceShares.concat(idResidenceShares.filter(id => residenceShares.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    StratalotRcpActions.deleteManyResidenceShareSuccess,
    (state: StratalotRcpState, { idResidenceShares, idStratalotRcps }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotRcps.reduce((entities, idStratalotRcp) => {
            if (!state.entities[idStratalotRcp]?.residenceShares) {
              return entities;
            }
            entities[idStratalotRcp] = {
              ...state.entities[idStratalotRcp],
              residenceShares: (state.entities[idStratalotRcp]?.residenceShares as number[])?.filter(
                (idResidenceShare: number) => !idResidenceShares.some((id: number) => id === idResidenceShare)
              )
            } as StratalotRcpEntityState;
            return entities;
          }, {} as Dictionary<StratalotRcpEntityState>)
        }
      };
    }
  ),

  on(
    StratalotRcpActions.addManyResidenceShareRcpSuccess,
    (state: StratalotRcpState, { idStratalotRcp, idResidenceShareRcps }) => {
      if (!state.entities[idStratalotRcp]) {
        return state;
      }
      const residenceShareRcps = (state.entities[idStratalotRcp]?.residenceShareRcps as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotRcp]: {
            ...state.entities[idStratalotRcp],
            residenceShareRcps: residenceShareRcps.concat(
              idResidenceShareRcps.filter(id => residenceShareRcps.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    StratalotRcpActions.deleteManyResidenceShareRcpSuccess,
    (state: StratalotRcpState, { idResidenceShareRcps, idStratalotRcps }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotRcps.reduce((entities, idStratalotRcp) => {
            if (!state.entities[idStratalotRcp]?.residenceShareRcps) {
              return entities;
            }
            entities[idStratalotRcp] = {
              ...state.entities[idStratalotRcp],
              residenceShareRcps: (state.entities[idStratalotRcp]?.residenceShareRcps as number[])?.filter(
                (idResidenceShareRcp: number) => !idResidenceShareRcps.some((id: number) => id === idResidenceShareRcp)
              )
            } as StratalotRcpEntityState;
            return entities;
          }, {} as Dictionary<StratalotRcpEntityState>)
        }
      };
    }
  ),

  on(StratalotRcpActions.addResidenceSuccess, (state: StratalotRcpState, { idStratalotRcp, idResidence }) => {
    if (!state.entities[idStratalotRcp]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalotRcp]: {
          ...state.entities[idStratalotRcp],
          residence: idResidence
        }
      }
    };
  }),

  on(StratalotRcpActions.deleteManyResidenceSuccess, (state: StratalotRcpState, { idResidences, idStratalotRcps }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idStratalotRcps.reduce((entities, idStratalotRcp) => {
          if (!state.entities[idStratalotRcp]?.residence) {
            return entities;
          }
          entities[idStratalotRcp] = {
            ...state.entities[idStratalotRcp],
            residence: idResidences.some(
              (idResidence: number) => idResidence === state.entities[idStratalotRcp]?.residence
            )
              ? undefined
              : state.entities[idStratalotRcp]?.residence
          } as StratalotRcpEntityState;
          return entities;
        }, {} as Dictionary<StratalotRcpEntityState>)
      }
    };
  }),

  on(StratalotRcpActions.addStratalotSuccess, (state: StratalotRcpState, { idStratalotRcp, idStratalot }) => {
    if (!state.entities[idStratalotRcp]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalotRcp]: {
          ...state.entities[idStratalotRcp],
          stratalot: idStratalot
        }
      }
    };
  }),

  on(StratalotRcpActions.deleteManyStratalotSuccess, (state: StratalotRcpState, { idStratalots, idStratalotRcps }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idStratalotRcps.reduce((entities, idStratalotRcp) => {
          if (!state.entities[idStratalotRcp]?.stratalot) {
            return entities;
          }
          entities[idStratalotRcp] = {
            ...state.entities[idStratalotRcp],
            stratalot: idStratalots.some(
              (idStratalot: number) => idStratalot === state.entities[idStratalotRcp]?.stratalot
            )
              ? undefined
              : state.entities[idStratalotRcp]?.stratalot
          } as StratalotRcpEntityState;
          return entities;
        }, {} as Dictionary<StratalotRcpEntityState>)
      }
    };
  })
];

export function setLoadingsState(
  state: StratalotRcpState,
  isLoading: boolean,
  isLoaded: boolean = true
): StratalotRcpState {
  return { ...state, isLoaded, isLoading };
}
