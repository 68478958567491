import { ResidencePriceGridModifierState, initialState } from './residence-price-grid-modifier.state';
import { Action, createReducer } from '@ngrx/store';
import { residencePriceGridModifierReducersGeneratedFunctions } from './residence-price-grid-modifier-generated.reducer';

const residencePriceGridModifierReducersFunctions = [...residencePriceGridModifierReducersGeneratedFunctions];

const residencePriceGridModifierReducer = createReducer(initialState, ...residencePriceGridModifierReducersFunctions);

export function reducer(state: ResidencePriceGridModifierState | undefined, action: Action) {
  return residencePriceGridModifierReducer(state, action);
}
