import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  ResidenceStudyFounding,
  ResidenceStudyFoundingEntityState
} from '@_model/interfaces/residence-study-founding.model';
import { ResidenceStudyFoundingRelationsIds } from './residence-study-founding-generated.effects';

export const getOneResidenceStudyFounding = createAction(
  '[ResidenceStudyFounding] Get One ResidenceStudyFounding',
  props<{ idResidenceStudyFounding: number; params?: any }>()
);

export const getManyResidenceStudyFoundings = createAction(
  '[ResidenceStudyFounding] Get Many ResidenceStudyFoundings',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ResidenceStudyFounding] Add Many Actives ResidenceStudyFounding',
  props<{ idResidenceStudyFoundings: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidenceStudyFounding] Delete One Active ResidenceStudyFounding',
  props<{ idResidenceStudyFounding: number }>()
);

export const clearActive = createAction('[ResidenceStudyFounding] Clear Active ResidenceStudyFounding');

export const upsertOneResidenceStudyFounding = createAction(
  '[ResidenceStudyFounding] Upsert One ResidenceStudyFounding',
  props<{
    residenceStudyFounding: Partial<ResidenceStudyFounding>;
    ids?: ResidenceStudyFoundingRelationsIds;
  }>()
);

export const upsertManyResidenceStudyFoundings = createAction(
  '[ResidenceStudyFounding] Upsert Many ResidenceStudyFoundings',
  props<{
    residenceStudyFoundings: Partial<ResidenceStudyFounding>[];
    ids?: ResidenceStudyFoundingRelationsIds;
  }>()
);

export const upsertManyResidenceStudyFoundingsSuccess = createAction(
  '[ResidenceStudyFounding] Create Many ResidenceStudyFoundings Success',
  props<{ residenceStudyFoundings: ResidenceStudyFoundingEntityState[] }>()
);

export const deleteOneResidenceStudyFounding = createAction(
  '[ResidenceStudyFounding] Delete One ResidenceStudyFounding',
  props<{ idResidenceStudyFounding: number }>()
);

export const deleteOneResidenceStudyFoundingSuccess = createAction(
  '[ResidenceStudyFounding] Delete One ResidenceStudyFounding Success',
  props<{ idResidenceStudyFounding: number }>()
);

export const normalizeManyResidenceStudyFoundingsAfterUpsert = createAction(
  '[ResidenceStudyFounding] Normalize Many ResidenceStudyFoundings After Upsert',
  props<{ residenceStudyFoundings: ResidenceStudyFoundingEntityState[] }>()
);

export const residenceStudyFoundingsFailure = createAction(
  '[ResidenceStudyFounding] ResidenceStudyFoundings Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidenceStudyFounding] Clear ResidenceStudyFoundings');

export const addResidenceStudySuccess = createAction(
  '[ResidenceStudyFounding] Add ResidenceStudy',
  props<{ idResidenceStudyFounding: number; idResidenceStudy: number }>()
);

export const deleteManyResidenceStudySuccess = createAction(
  '[ResidenceStudyFounding] Delete Many ResidenceStudy',
  props<{ idResidenceStudies: number[]; idResidenceStudyFoundings: number[] }>()
);
