import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedDocument } from '@_model/interfaces/generated-document.model';
import { Observable } from 'rxjs';

export class GeneratedGeneratedDocumentApiService {
  constructor(protected repo: RepositoryService) {}

  public getGeneratedDocuments(params?: any): Observable<GeneratedDocument[]> {
    return this.repo.getData<GeneratedDocument[]>('generated-document', params);
  }

  public getGeneratedDocument(params: { idGeneratedDocument: number; params?: any }): Observable<GeneratedDocument> {
    return this.repo.getData<GeneratedDocument>('generated-document/' + params.idGeneratedDocument, params.params);
  }

  public addGeneratedDocument(generatedDocument: Partial<GeneratedDocument>): Observable<GeneratedDocument> {
    return this.repo.create<GeneratedDocument>('generated-document', generatedDocument);
  }

  public updateGeneratedDocument(generatedDocument: Partial<GeneratedDocument>): Observable<GeneratedDocument> {
    return this.repo.update('generated-document', generatedDocument);
  }

  public deleteGeneratedDocument(idGeneratedDocument: number): Observable<number> {
    return this.repo.delete('generated-document/' + +idGeneratedDocument);
  }
}
