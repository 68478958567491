import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CompanyPriceLabel, CompanyPriceLabelEntityState } from '@_model/interfaces/company-price-label.model';
import { CompanyPriceLabelRelationsIds } from './company-price-label-generated.effects';

export const getOneCompanyPriceLabel = createAction(
  '[CompanyPriceLabel] Get One CompanyPriceLabel',
  props<{ idCompanyPriceLabel: number; params?: any }>()
);

export const getManyCompanyPriceLabels = createAction(
  '[CompanyPriceLabel] Get Many CompanyPriceLabels',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyPriceLabel] Add Many Actives CompanyPriceLabel',
  props<{ idCompanyPriceLabels: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyPriceLabel] Delete One Active CompanyPriceLabel',
  props<{ idCompanyPriceLabel: number }>()
);

export const clearActive = createAction('[CompanyPriceLabel] Clear Active CompanyPriceLabel');

export const upsertOneCompanyPriceLabel = createAction(
  '[CompanyPriceLabel] Upsert One CompanyPriceLabel',
  props<{
    companyPriceLabel: Partial<CompanyPriceLabel>;
    ids?: CompanyPriceLabelRelationsIds;
  }>()
);

export const upsertManyCompanyPriceLabels = createAction(
  '[CompanyPriceLabel] Upsert Many CompanyPriceLabels',
  props<{
    companyPriceLabels: Partial<CompanyPriceLabel>[];
    ids?: CompanyPriceLabelRelationsIds;
  }>()
);

export const upsertManyCompanyPriceLabelsSuccess = createAction(
  '[CompanyPriceLabel] Create Many CompanyPriceLabels Success',
  props<{ companyPriceLabels: CompanyPriceLabelEntityState[] }>()
);

export const deleteOneCompanyPriceLabel = createAction(
  '[CompanyPriceLabel] Delete One CompanyPriceLabel',
  props<{ idCompanyPriceLabel: number }>()
);

export const deleteOneCompanyPriceLabelSuccess = createAction(
  '[CompanyPriceLabel] Delete One CompanyPriceLabel Success',
  props<{ idCompanyPriceLabel: number }>()
);

export const normalizeManyCompanyPriceLabelsAfterUpsert = createAction(
  '[CompanyPriceLabel] Normalize Many CompanyPriceLabels After Upsert',
  props<{ companyPriceLabels: CompanyPriceLabelEntityState[] }>()
);

export const companyPriceLabelsFailure = createAction(
  '[CompanyPriceLabel] CompanyPriceLabels Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyPriceLabel] Clear CompanyPriceLabels');

export const addManyStratalotPriceSuccess = createAction(
  '[CompanyPriceLabel] Add Many stratalot-price',
  props<{ idCompanyPriceLabel: number; idStratalotPrices: number[] }>()
);

export const deleteManyStratalotPriceSuccess = createAction(
  '[CompanyPriceLabel] Delete Many StratalotPrices',
  props<{ idStratalotPrices: number[]; idCompanyPriceLabels: number[] }>()
);

export const addManyStratalotPriceValueSuccess = createAction(
  '[CompanyPriceLabel] Add Many stratalot-price-value',
  props<{ idCompanyPriceLabel: number; idStratalotPriceValues: number[] }>()
);

export const deleteManyStratalotPriceValueSuccess = createAction(
  '[CompanyPriceLabel] Delete Many StratalotPriceValues',
  props<{ idStratalotPriceValues: number[]; idCompanyPriceLabels: number[] }>()
);

export const addManyResidencePriceGridModifierSuccess = createAction(
  '[CompanyPriceLabel] Add Many residence-price-grid-modifier',
  props<{ idCompanyPriceLabel: number; idResidencePriceGridModifiers: number[] }>()
);

export const deleteManyResidencePriceGridModifierSuccess = createAction(
  '[CompanyPriceLabel] Delete Many ResidencePriceGridModifiers',
  props<{ idResidencePriceGridModifiers: number[]; idCompanyPriceLabels: number[] }>()
);

export const addManyOrganizationProfilSuccess = createAction(
  '[CompanyPriceLabel] Add Many organization-profil',
  props<{ idCompanyPriceLabel: number; idOrganizationProfils: number[] }>()
);

export const deleteManyOrganizationProfilSuccess = createAction(
  '[CompanyPriceLabel] Delete Many OrganizationProfils',
  props<{ idOrganizationProfils: number[]; idCompanyPriceLabels: number[] }>()
);

export const addCompanySuccess = createAction(
  '[CompanyPriceLabel] Add Company',
  props<{ idCompanyPriceLabel: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[CompanyPriceLabel] Delete Many Company',
  props<{ idCompanies: number[]; idCompanyPriceLabels: number[] }>()
);

export const addSaleCategoryFamilySuccess = createAction(
  '[CompanyPriceLabel] Add SaleCategoryFamily',
  props<{ idCompanyPriceLabel: number; idSaleCategoryFamily: number }>()
);

export const deleteManySaleCategoryFamilySuccess = createAction(
  '[CompanyPriceLabel] Delete Many SaleCategoryFamily',
  props<{ idFamilySaleCategories: number[]; idCompanyPriceLabels: number[] }>()
);
