import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationProfil } from '@_model/interfaces/organization-profil.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationProfilApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationProfils(params?: any): Observable<OrganizationProfil[]> {
    return this.repo.getData<OrganizationProfil[]>('organization-profil', params);
  }

  public getOrganizationProfil(params: { idOrganizationProfil: number; params?: any }): Observable<OrganizationProfil> {
    return this.repo.getData<OrganizationProfil>('organization-profil/' + params.idOrganizationProfil, params.params);
  }

  public addOrganizationProfil(organizationProfil: Partial<OrganizationProfil>): Observable<OrganizationProfil> {
    return this.repo.create<OrganizationProfil>('organization-profil', organizationProfil);
  }

  public updateOrganizationProfil(organizationProfil: Partial<OrganizationProfil>): Observable<OrganizationProfil> {
    return this.repo.update('organization-profil', organizationProfil);
  }

  public deleteOrganizationProfil(idOrganizationProfil: number): Observable<number> {
    return this.repo.delete('organization-profil/' + +idOrganizationProfil);
  }
}
