import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ProspectBuyingWishEntityState } from '@_model/interfaces/prospect-buying-wish.model';

export interface ProspectBuyingWishState extends EntityState<ProspectBuyingWishEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ProspectBuyingWishEntityState> = createEntityAdapter<ProspectBuyingWishEntityState>(
  {
    selectId: o => o.idProspectBuyingWish
  }
);
export const initialState: ProspectBuyingWishState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const prospectBuyingWishFeatureKey = 'prospectBuyingWish';
