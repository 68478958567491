import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationForecastRate,
  OrganizationForecastRateEntityState
} from '@_model/interfaces/organization-forecast-rate.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationForecastRateFeatureKey,
  OrganizationForecastRateState
} from './organization-forecast-rate.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationForecastRateRelations: string[] = ['residences', 'organizations'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationForecastRateState = createFeatureSelector<OrganizationForecastRateState>(
  organizationForecastRateFeatureKey
);

export const selectIsLoadedOrganizationForecastRate = createSelector(
  selectOrganizationForecastRateState,
  (state: OrganizationForecastRateState) => state.isLoaded
);

export const selectIsLoadingOrganizationForecastRate = createSelector(
  selectOrganizationForecastRateState,
  (state: OrganizationForecastRateState) => state.isLoading
);

export const selectIsReadyOrganizationForecastRate = createSelector(
  selectOrganizationForecastRateState,
  (state: OrganizationForecastRateState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationForecastRate = createSelector(
  selectOrganizationForecastRateState,
  (state: OrganizationForecastRateState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationForecastRateModel: SelectorModel = {
  name: 'organizationForecastRates',
  getSelector: selectAllOrganizationForecastRatesDictionary,
  isReady: selectIsReadyOrganizationForecastRate
};

export const selectOrganizationForecastRatesEntities = createSelector(
  selectOrganizationForecastRateState,
  selectEntities
);

export const selectOrganizationForecastRatesArray = createSelector(selectOrganizationForecastRateState, selectAll);

export const selectIdOrganizationForecastRatesActive = createSelector(
  selectOrganizationForecastRateState,
  (state: OrganizationForecastRateState) => state.actives
);

const organizationForecastRatesInObject = (
  organizationForecastRates: Dictionary<OrganizationForecastRateEntityState>
) => ({ organizationForecastRates });

const selectOrganizationForecastRatesEntitiesDictionary = createSelector(
  selectOrganizationForecastRatesEntities,
  organizationForecastRatesInObject
);

const selectAllOrganizationForecastRatesObject = createSelector(
  selectOrganizationForecastRatesEntities,
  organizationForecastRates => {
    return hydrateAll({ organizationForecastRates });
  }
);

const selectOneOrganizationForecastRateDictionary = (idOrganizationForecastRate: number) =>
  createSelector(selectOrganizationForecastRatesEntities, organizationForecastRates => {
    return {
      organizationForecastRates: { [idOrganizationForecastRate]: organizationForecastRates[idOrganizationForecastRate] }
    };
  });

const selectOneOrganizationForecastRateDictionaryWithoutChild = (idOrganizationForecastRate: number) =>
  createSelector(selectOrganizationForecastRatesEntities, organizationForecastRates => {
    return { organizationForecastRate: organizationForecastRates[idOrganizationForecastRate] };
  });

const selectActiveOrganizationForecastRatesEntities = createSelector(
  selectIdOrganizationForecastRatesActive,
  selectOrganizationForecastRatesEntities,
  (actives: number[], organizationForecastRates: Dictionary<OrganizationForecastRateEntityState>) =>
    getOrganizationForecastRatesFromActives(actives, organizationForecastRates)
);

function getOrganizationForecastRatesFromActives(
  actives: number[],
  organizationForecastRates: Dictionary<OrganizationForecastRateEntityState>
): Dictionary<OrganizationForecastRateEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationForecastRates[idActive]) {
      acc[idActive] = organizationForecastRates[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationForecastRateEntityState>);
}

const selectAllOrganizationForecastRatesSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationForecastRates(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationForecastRate>(
      schema,
      selectAllOrganizationForecastRatesSelectors,
      selectOrganizationForecastRatesEntitiesDictionary,
      getRelationSelectors,
      organizationForecastRateRelations,
      hydrateAll,
      'organizationForecastRate'
    );
  } else {
    return selectAllOrganizationForecastRatesObject;
  }
}

export function selectAllOrganizationForecastRatesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationForecastRates'
): Selector {
  return createSelector(selectAllOrganizationForecastRates(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationForecastRateEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationForecastRates.length; i++) {
      res[customKey][result.organizationForecastRates[i].idOrganizationForecastRate] =
        result.organizationForecastRates[i];
    }
    return res;
  });
}

export function selectOneOrganizationForecastRate(
  schema: SelectSchema = {},
  idOrganizationForecastRate: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationForecastRateDictionary(idOrganizationForecastRate)];
    selectors.push(...getRelationSelectors(schema, organizationForecastRateRelations, 'organizationForecastRate'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationForecastRateDictionaryWithoutChild(idOrganizationForecastRate);
  }
}

export function selectActiveOrganizationForecastRates(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationForecastRatesEntities, organizationForecastRates => ({
      organizationForecastRates
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationForecastRateRelations, 'organizationForecastRate'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationForecastRates: Dictionary<OrganizationForecastRateEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationForecastRates: (OrganizationForecastRate | null)[] } {
  const { organizationForecastRates, organizations, residences } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationForecastRates: Object.keys(organizationForecastRates).map(idOrganizationForecastRate =>
      hydrate(
        organizationForecastRates[idOrganizationForecastRate] as OrganizationForecastRateEntityState,
        organizations,
        residences
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationForecastRate: OrganizationForecastRateEntityState | null } {
  const { organizationForecastRates, organizations, residences } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationForecastRate = Object.values(organizationForecastRates)[0];
  return {
    organizationForecastRate: hydrate(
      organizationForecastRate as OrganizationForecastRateEntityState,
      organizations,
      residences
    )
  };
}

function hydrate(
  organizationForecastRate: OrganizationForecastRateEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  residenceEntities?: Dictionary<ResidenceEntityState>
): OrganizationForecastRate | null {
  if (!organizationForecastRate) {
    return null;
  }

  const organizationForecastRateHydrated: OrganizationForecastRateEntityState = { ...organizationForecastRate };
  if (organizationEntities) {
    organizationForecastRateHydrated.organization = organizationEntities[
      organizationForecastRate.organization as number
    ] as Organization;
  } else {
    delete organizationForecastRateHydrated.organization;
  }

  if (residenceEntities) {
    organizationForecastRateHydrated.residences = ((organizationForecastRateHydrated.residences as number[]) || []).map(
      id => residenceEntities[id]
    ) as Residence[];
  } else {
    delete organizationForecastRateHydrated.residences;
  }

  return organizationForecastRateHydrated as OrganizationForecastRate;
}
