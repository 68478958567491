import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { ResidencePriceGrid } from '@_shared/models/interfaces/residence-price-grid.model';
import { Observable } from 'rxjs';
import { GeneratedResidencePriceGridApiService } from './residence-price-grid-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ResidencePriceGridApiService extends GeneratedResidencePriceGridApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public generatePriceList(residencePriceGrid: Partial<ResidencePriceGrid>): Observable<ResidencePriceGrid> {
    return this.repo.create<ResidencePriceGrid>('residence-price-grid/generate-list', residencePriceGrid);
  }

  public duplicatePriceGrid(residencePriceGrid: Partial<ResidencePriceGrid>): Observable<ResidencePriceGrid> {
    return this.repo.create<ResidencePriceGrid>('residence-price-grid/duplicate', residencePriceGrid);
  }

  public updateResidencePriceGridWithArrayReturned(
    residencePriceGrid: Partial<ResidencePriceGrid>
  ): Observable<ResidencePriceGrid[]> {
    return this.repo.update('residence-price-grid', residencePriceGrid);
  }

  public updateResidencePriceGridLockWithArrayReturned(
    residencePriceGrid: Partial<ResidencePriceGrid>
  ): Observable<ResidencePriceGrid[]> {
    return this.repo.update('residence-price-grid/bloqued', residencePriceGrid);
  }

  public updateResidencePriceGridActiveWithArrayReturned(
    residencePriceGrid: Partial<ResidencePriceGrid>
  ): Observable<ResidencePriceGrid[]> {
    return this.repo.update('residence-price-grid/activate', residencePriceGrid);
  }
}
