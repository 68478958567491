import { ResidenceForecastValueState, initialState } from './residence-forecast-value.state';
import { Action, createReducer } from '@ngrx/store';
import { residenceForecastValueReducersGeneratedFunctions } from './residence-forecast-value-generated.reducer';

const residenceForecastValueReducersFunctions = [...residenceForecastValueReducersGeneratedFunctions];

const residenceForecastValueReducer = createReducer(initialState, ...residenceForecastValueReducersFunctions);

export function reducer(state: ResidenceForecastValueState | undefined, action: Action) {
  return residenceForecastValueReducer(state, action);
}
