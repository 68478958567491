import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyStudyReason, CompanyStudyReasonEntityState } from '@_model/interfaces/company-study-reason.model';
import { ResidenceStudyReason, ResidenceStudyReasonEntityState } from '@_model/interfaces/residence-study-reason.model';
import { ResidenceStudy, ResidenceStudyEntityState } from '@_model/interfaces/residence-study.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, companyStudyReasonFeatureKey, CompanyStudyReasonState } from './company-study-reason.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyStudyReasonRelations: string[] = ['residenceStudyReasons', 'residenceStudies', 'companies'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyStudyReasonState =
  createFeatureSelector<CompanyStudyReasonState>(companyStudyReasonFeatureKey);

export const selectIsLoadedCompanyStudyReason = createSelector(
  selectCompanyStudyReasonState,
  (state: CompanyStudyReasonState) => state.isLoaded
);

export const selectIsLoadingCompanyStudyReason = createSelector(
  selectCompanyStudyReasonState,
  (state: CompanyStudyReasonState) => state.isLoading
);

export const selectIsReadyCompanyStudyReason = createSelector(
  selectCompanyStudyReasonState,
  (state: CompanyStudyReasonState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyStudyReason = createSelector(
  selectCompanyStudyReasonState,
  (state: CompanyStudyReasonState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyStudyReasonModel: SelectorModel = {
  name: 'companyStudyReasons',
  getSelector: selectAllCompanyStudyReasonsDictionary,
  isReady: selectIsReadyCompanyStudyReason
};

export const selectCompanyStudyReasonsEntities = createSelector(selectCompanyStudyReasonState, selectEntities);

export const selectCompanyStudyReasonsArray = createSelector(selectCompanyStudyReasonState, selectAll);

export const selectIdCompanyStudyReasonsActive = createSelector(
  selectCompanyStudyReasonState,
  (state: CompanyStudyReasonState) => state.actives
);

const companyStudyReasonsInObject = (companyStudyReasons: Dictionary<CompanyStudyReasonEntityState>) => ({
  companyStudyReasons
});

const selectCompanyStudyReasonsEntitiesDictionary = createSelector(
  selectCompanyStudyReasonsEntities,
  companyStudyReasonsInObject
);

const selectAllCompanyStudyReasonsObject = createSelector(selectCompanyStudyReasonsEntities, companyStudyReasons => {
  return hydrateAll({ companyStudyReasons });
});

const selectOneCompanyStudyReasonDictionary = (idCompanyStudyReason: number) =>
  createSelector(selectCompanyStudyReasonsEntities, companyStudyReasons => {
    return { companyStudyReasons: { [idCompanyStudyReason]: companyStudyReasons[idCompanyStudyReason] } };
  });

const selectOneCompanyStudyReasonDictionaryWithoutChild = (idCompanyStudyReason: number) =>
  createSelector(selectCompanyStudyReasonsEntities, companyStudyReasons => {
    return { companyStudyReason: companyStudyReasons[idCompanyStudyReason] };
  });

const selectActiveCompanyStudyReasonsEntities = createSelector(
  selectIdCompanyStudyReasonsActive,
  selectCompanyStudyReasonsEntities,
  (actives: number[], companyStudyReasons: Dictionary<CompanyStudyReasonEntityState>) =>
    getCompanyStudyReasonsFromActives(actives, companyStudyReasons)
);

function getCompanyStudyReasonsFromActives(
  actives: number[],
  companyStudyReasons: Dictionary<CompanyStudyReasonEntityState>
): Dictionary<CompanyStudyReasonEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyStudyReasons[idActive]) {
      acc[idActive] = companyStudyReasons[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyStudyReasonEntityState>);
}

const selectAllCompanyStudyReasonsSelectors: Dictionary<Selector> = {};
export function selectAllCompanyStudyReasons(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyStudyReason>(
      schema,
      selectAllCompanyStudyReasonsSelectors,
      selectCompanyStudyReasonsEntitiesDictionary,
      getRelationSelectors,
      companyStudyReasonRelations,
      hydrateAll,
      'companyStudyReason'
    );
  } else {
    return selectAllCompanyStudyReasonsObject;
  }
}

export function selectAllCompanyStudyReasonsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyStudyReasons'
): Selector {
  return createSelector(selectAllCompanyStudyReasons(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyStudyReasonEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyStudyReasons.length; i++) {
      res[customKey][result.companyStudyReasons[i].idCompanyStudyReason] = result.companyStudyReasons[i];
    }
    return res;
  });
}

export function selectOneCompanyStudyReason(schema: SelectSchema = {}, idCompanyStudyReason: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyStudyReasonDictionary(idCompanyStudyReason)];
    selectors.push(...getRelationSelectors(schema, companyStudyReasonRelations, 'companyStudyReason'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyStudyReasonDictionaryWithoutChild(idCompanyStudyReason);
  }
}

export function selectActiveCompanyStudyReasons(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyStudyReasonsEntities, companyStudyReasons => ({ companyStudyReasons }))
  ];
  selectors.push(...getRelationSelectors(schema, companyStudyReasonRelations, 'companyStudyReason'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyStudyReasons: Dictionary<CompanyStudyReasonEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  residenceStudyReasons?: Dictionary<ResidenceStudyReasonEntityState>;
  residenceStudies?: Dictionary<ResidenceStudyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { companyStudyReasons: (CompanyStudyReason | null)[] } {
  const { companyStudyReasons, companies, residenceStudyReasons, residenceStudies } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    companyStudyReasons: Object.keys(companyStudyReasons).map(idCompanyStudyReason =>
      hydrate(
        companyStudyReasons[idCompanyStudyReason] as CompanyStudyReasonEntityState,
        companies,
        residenceStudyReasons,
        residenceStudies
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { companyStudyReason: CompanyStudyReasonEntityState | null } {
  const { companyStudyReasons, companies, residenceStudyReasons, residenceStudies } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const companyStudyReason = Object.values(companyStudyReasons)[0];
  return {
    companyStudyReason: hydrate(
      companyStudyReason as CompanyStudyReasonEntityState,
      companies,
      residenceStudyReasons,
      residenceStudies
    )
  };
}

function hydrate(
  companyStudyReason: CompanyStudyReasonEntityState,
  companyEntities?: Dictionary<CompanyEntityState>,
  residenceStudyReasonEntities?: Dictionary<ResidenceStudyReasonEntityState>,
  residenceStudyEntities?: Dictionary<ResidenceStudyEntityState>
): CompanyStudyReason | null {
  if (!companyStudyReason) {
    return null;
  }

  const companyStudyReasonHydrated: CompanyStudyReasonEntityState = { ...companyStudyReason };
  if (companyEntities) {
    companyStudyReasonHydrated.company = companyEntities[companyStudyReason.company as number] as Company;
  } else {
    delete companyStudyReasonHydrated.company;
  }

  if (residenceStudyReasonEntities) {
    companyStudyReasonHydrated.residenceStudyReasons = (
      (companyStudyReasonHydrated.residenceStudyReasons as number[]) || []
    ).map(id => residenceStudyReasonEntities[id]) as ResidenceStudyReason[];
  } else {
    delete companyStudyReasonHydrated.residenceStudyReasons;
  }

  if (residenceStudyEntities) {
    companyStudyReasonHydrated.residenceStudies = ((companyStudyReasonHydrated.residenceStudies as number[]) || []).map(
      id => residenceStudyEntities[id]
    ) as ResidenceStudy[];
  } else {
    delete companyStudyReasonHydrated.residenceStudies;
  }

  return companyStudyReasonHydrated as CompanyStudyReason;
}
