import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationMetierEntityState } from '@_model/interfaces/organization-metier.model';

export interface OrganizationMetierState extends EntityState<OrganizationMetierEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationMetierEntityState> = createEntityAdapter<OrganizationMetierEntityState>(
  {
    selectId: o => o.idOrganizationMetier
  }
);
export const initialState: OrganizationMetierState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationMetierFeatureKey = 'organizationMetier';
