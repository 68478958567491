import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { LeadEntityState } from '@_model/interfaces/lead.model';

export interface LeadState extends EntityState<LeadEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<LeadEntityState> = createEntityAdapter<LeadEntityState>({
  selectId: o => o.idLead
});
export const initialState: LeadState = adapter.getInitialState({ isLoaded: false, isLoading: false, actives: [] });

export const leadFeatureKey = 'lead';
