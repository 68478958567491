import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationLeadTodoRule,
  OrganizationLeadTodoRuleEntityState
} from '@_model/interfaces/organization-lead-todo-rule.model';
import { OrganizationLeadTodo, OrganizationLeadTodoEntityState } from '@_model/interfaces/organization-lead-todo.model';
import {
  OrganizationLeadTodoAppliedRule,
  OrganizationLeadTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-lead-todo-applied-rule.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import {
  OrganizationSaleCategory,
  OrganizationSaleCategoryEntityState
} from '@_model/interfaces/organization-sale-category.model';
import { SaleCategoryFamily, SaleCategoryFamilyEntityState } from '@_model/interfaces/sale-category-family.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { CompanyStratalotType, CompanyStratalotTypeEntityState } from '@_model/interfaces/company-stratalot-type.model';
import { StratalotType, StratalotTypeEntityState } from '@_model/interfaces/stratalot-type.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationLeadTodoRuleFeatureKey,
  OrganizationLeadTodoRuleState
} from './organization-lead-todo-rule.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationLeadTodoRuleRelations: string[] = [
  'organizationLeadTodos',
  'organizationLeadTodoAppliedRules',
  'organizations',
  'organizationSaleCategories',
  'familySaleCategories',
  'companies',
  'companyStratalotTypes',
  'stratalotTypes'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationLeadTodoRuleState = createFeatureSelector<OrganizationLeadTodoRuleState>(
  organizationLeadTodoRuleFeatureKey
);

export const selectIsLoadedOrganizationLeadTodoRule = createSelector(
  selectOrganizationLeadTodoRuleState,
  (state: OrganizationLeadTodoRuleState) => state.isLoaded
);

export const selectIsLoadingOrganizationLeadTodoRule = createSelector(
  selectOrganizationLeadTodoRuleState,
  (state: OrganizationLeadTodoRuleState) => state.isLoading
);

export const selectIsReadyOrganizationLeadTodoRule = createSelector(
  selectOrganizationLeadTodoRuleState,
  (state: OrganizationLeadTodoRuleState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationLeadTodoRule = createSelector(
  selectOrganizationLeadTodoRuleState,
  (state: OrganizationLeadTodoRuleState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationLeadTodoRuleModel: SelectorModel = {
  name: 'organizationLeadTodoRules',
  getSelector: selectAllOrganizationLeadTodoRulesDictionary,
  isReady: selectIsReadyOrganizationLeadTodoRule
};

export const selectOrganizationLeadTodoRulesEntities = createSelector(
  selectOrganizationLeadTodoRuleState,
  selectEntities
);

export const selectOrganizationLeadTodoRulesArray = createSelector(selectOrganizationLeadTodoRuleState, selectAll);

export const selectIdOrganizationLeadTodoRulesActive = createSelector(
  selectOrganizationLeadTodoRuleState,
  (state: OrganizationLeadTodoRuleState) => state.actives
);

const organizationLeadTodoRulesInObject = (
  organizationLeadTodoRules: Dictionary<OrganizationLeadTodoRuleEntityState>
) => ({ organizationLeadTodoRules });

const selectOrganizationLeadTodoRulesEntitiesDictionary = createSelector(
  selectOrganizationLeadTodoRulesEntities,
  organizationLeadTodoRulesInObject
);

const selectAllOrganizationLeadTodoRulesObject = createSelector(
  selectOrganizationLeadTodoRulesEntities,
  organizationLeadTodoRules => {
    return hydrateAll({ organizationLeadTodoRules });
  }
);

const selectOneOrganizationLeadTodoRuleDictionary = (idOrganizationLeadTodoRule: number) =>
  createSelector(selectOrganizationLeadTodoRulesEntities, organizationLeadTodoRules => {
    return {
      organizationLeadTodoRules: { [idOrganizationLeadTodoRule]: organizationLeadTodoRules[idOrganizationLeadTodoRule] }
    };
  });

const selectOneOrganizationLeadTodoRuleDictionaryWithoutChild = (idOrganizationLeadTodoRule: number) =>
  createSelector(selectOrganizationLeadTodoRulesEntities, organizationLeadTodoRules => {
    return { organizationLeadTodoRule: organizationLeadTodoRules[idOrganizationLeadTodoRule] };
  });

const selectActiveOrganizationLeadTodoRulesEntities = createSelector(
  selectIdOrganizationLeadTodoRulesActive,
  selectOrganizationLeadTodoRulesEntities,
  (actives: number[], organizationLeadTodoRules: Dictionary<OrganizationLeadTodoRuleEntityState>) =>
    getOrganizationLeadTodoRulesFromActives(actives, organizationLeadTodoRules)
);

function getOrganizationLeadTodoRulesFromActives(
  actives: number[],
  organizationLeadTodoRules: Dictionary<OrganizationLeadTodoRuleEntityState>
): Dictionary<OrganizationLeadTodoRuleEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationLeadTodoRules[idActive]) {
      acc[idActive] = organizationLeadTodoRules[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationLeadTodoRuleEntityState>);
}

const selectAllOrganizationLeadTodoRulesSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationLeadTodoRules(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationLeadTodoRule>(
      schema,
      selectAllOrganizationLeadTodoRulesSelectors,
      selectOrganizationLeadTodoRulesEntitiesDictionary,
      getRelationSelectors,
      organizationLeadTodoRuleRelations,
      hydrateAll,
      'organizationLeadTodoRule'
    );
  } else {
    return selectAllOrganizationLeadTodoRulesObject;
  }
}

export function selectAllOrganizationLeadTodoRulesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationLeadTodoRules'
): Selector {
  return createSelector(selectAllOrganizationLeadTodoRules(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationLeadTodoRuleEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationLeadTodoRules.length; i++) {
      res[customKey][result.organizationLeadTodoRules[i].idOrganizationLeadTodoRule] =
        result.organizationLeadTodoRules[i];
    }
    return res;
  });
}

export function selectOneOrganizationLeadTodoRule(
  schema: SelectSchema = {},
  idOrganizationLeadTodoRule: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationLeadTodoRuleDictionary(idOrganizationLeadTodoRule)];
    selectors.push(...getRelationSelectors(schema, organizationLeadTodoRuleRelations, 'organizationLeadTodoRule'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationLeadTodoRuleDictionaryWithoutChild(idOrganizationLeadTodoRule);
  }
}

export function selectActiveOrganizationLeadTodoRules(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationLeadTodoRulesEntities, organizationLeadTodoRules => ({
      organizationLeadTodoRules
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationLeadTodoRuleRelations, 'organizationLeadTodoRule'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationLeadTodoRules: Dictionary<OrganizationLeadTodoRuleEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  organizationSaleCategories?: Dictionary<OrganizationSaleCategoryEntityState>;
  familySaleCategories?: Dictionary<SaleCategoryFamilyEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  companyStratalotTypes?: Dictionary<CompanyStratalotTypeEntityState>;
  stratalotTypes?: Dictionary<StratalotTypeEntityState>;
  organizationLeadTodos?: Dictionary<OrganizationLeadTodoEntityState>;
  organizationLeadTodoAppliedRules?: Dictionary<OrganizationLeadTodoAppliedRuleEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationLeadTodoRules: (OrganizationLeadTodoRule | null)[] } {
  const {
    organizationLeadTodoRules,
    organizations,
    organizationSaleCategories,
    familySaleCategories,
    companies,
    companyStratalotTypes,
    stratalotTypes,
    organizationLeadTodos,
    organizationLeadTodoAppliedRules
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationLeadTodoRules: Object.keys(organizationLeadTodoRules).map(idOrganizationLeadTodoRule =>
      hydrate(
        organizationLeadTodoRules[idOrganizationLeadTodoRule] as OrganizationLeadTodoRuleEntityState,
        organizations,
        organizationSaleCategories,
        familySaleCategories,
        companies,
        companyStratalotTypes,
        stratalotTypes,
        organizationLeadTodos,
        organizationLeadTodoAppliedRules
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationLeadTodoRule: OrganizationLeadTodoRuleEntityState | null } {
  const {
    organizationLeadTodoRules,
    organizations,
    organizationSaleCategories,
    familySaleCategories,
    companies,
    companyStratalotTypes,
    stratalotTypes,
    organizationLeadTodos,
    organizationLeadTodoAppliedRules
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationLeadTodoRule = Object.values(organizationLeadTodoRules)[0];
  return {
    organizationLeadTodoRule: hydrate(
      organizationLeadTodoRule as OrganizationLeadTodoRuleEntityState,
      organizations,
      organizationSaleCategories,
      familySaleCategories,
      companies,
      companyStratalotTypes,
      stratalotTypes,
      organizationLeadTodos,
      organizationLeadTodoAppliedRules
    )
  };
}

function hydrate(
  organizationLeadTodoRule: OrganizationLeadTodoRuleEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  organizationSaleCategoryEntities?: Dictionary<OrganizationSaleCategoryEntityState>,
  saleCategoryFamilyEntities?: Dictionary<SaleCategoryFamilyEntityState>,
  companyEntities?: Dictionary<CompanyEntityState>,
  companyStratalotTypeEntities?: Dictionary<CompanyStratalotTypeEntityState>,
  stratalotTypeEntities?: Dictionary<StratalotTypeEntityState>,
  organizationLeadTodoEntities?: Dictionary<OrganizationLeadTodoEntityState>,
  organizationLeadTodoAppliedRuleEntities?: Dictionary<OrganizationLeadTodoAppliedRuleEntityState>
): OrganizationLeadTodoRule | null {
  if (!organizationLeadTodoRule) {
    return null;
  }

  const organizationLeadTodoRuleHydrated: OrganizationLeadTodoRuleEntityState = { ...organizationLeadTodoRule };
  if (organizationEntities) {
    organizationLeadTodoRuleHydrated.organization = organizationEntities[
      organizationLeadTodoRule.organization as number
    ] as Organization;
  } else {
    delete organizationLeadTodoRuleHydrated.organization;
  }
  if (organizationSaleCategoryEntities) {
    organizationLeadTodoRuleHydrated.organizationSaleCategory = organizationSaleCategoryEntities[
      organizationLeadTodoRule.organizationSaleCategory as number
    ] as OrganizationSaleCategory;
  } else {
    delete organizationLeadTodoRuleHydrated.organizationSaleCategory;
  }
  if (saleCategoryFamilyEntities) {
    organizationLeadTodoRuleHydrated.saleCategoryFamily = saleCategoryFamilyEntities[
      organizationLeadTodoRule.saleCategoryFamily as number
    ] as SaleCategoryFamily;
  } else {
    delete organizationLeadTodoRuleHydrated.saleCategoryFamily;
  }
  if (companyEntities) {
    organizationLeadTodoRuleHydrated.company = companyEntities[organizationLeadTodoRule.company as number] as Company;
  } else {
    delete organizationLeadTodoRuleHydrated.company;
  }
  if (companyStratalotTypeEntities) {
    organizationLeadTodoRuleHydrated.companyStratalotType = companyStratalotTypeEntities[
      organizationLeadTodoRule.companyStratalotType as number
    ] as CompanyStratalotType;
  } else {
    delete organizationLeadTodoRuleHydrated.companyStratalotType;
  }
  if (stratalotTypeEntities) {
    organizationLeadTodoRuleHydrated.stratalotType = stratalotTypeEntities[
      organizationLeadTodoRule.stratalotType as number
    ] as StratalotType;
  } else {
    delete organizationLeadTodoRuleHydrated.stratalotType;
  }

  if (organizationLeadTodoEntities) {
    organizationLeadTodoRuleHydrated.organizationLeadTodos = (
      (organizationLeadTodoRuleHydrated.organizationLeadTodos as number[]) || []
    ).map(id => organizationLeadTodoEntities[id]) as OrganizationLeadTodo[];
  } else {
    delete organizationLeadTodoRuleHydrated.organizationLeadTodos;
  }

  if (organizationLeadTodoAppliedRuleEntities) {
    organizationLeadTodoRuleHydrated.organizationLeadTodoAppliedRules = (
      (organizationLeadTodoRuleHydrated.organizationLeadTodoAppliedRules as number[]) || []
    ).map(id => organizationLeadTodoAppliedRuleEntities[id]) as OrganizationLeadTodoAppliedRule[];
  } else {
    delete organizationLeadTodoRuleHydrated.organizationLeadTodoAppliedRules;
  }

  return organizationLeadTodoRuleHydrated as OrganizationLeadTodoRule;
}
