import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StratalotTodoEntityState } from '@_model/interfaces/stratalot-todo.model';

export interface StratalotTodoState extends EntityState<StratalotTodoEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<StratalotTodoEntityState> = createEntityAdapter<StratalotTodoEntityState>({
  selectId: o => o.idStratalotTodo
});
export const initialState: StratalotTodoState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const stratalotTodoFeatureKey = 'stratalotTodo';
