import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationLeadTodoAppliedRule,
  OrganizationLeadTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-lead-todo-applied-rule.model';
import { OrganizationLeadTodoAppliedRuleApiService } from '@_services/api/organization-lead-todo-applied-rule-api.service';
import * as organizationLeadTodoAppliedRuleActions from '@_store/organization-lead-todo-applied-rule/organization-lead-todo-applied-rule.actions';
import { getActionsToNormalizeOrganizationLeadTodoAppliedRule } from '@_config/store/normalization.generated';
import { selectOrganizationLeadTodoAppliedRuleState } from './organization-lead-todo-applied-rule-generated.selectors';
import * as organizationLeadTodoActions from '@_store/organization-lead-todo/organization-lead-todo.actions';
import * as organizationLeadTodoRuleActions from '@_store/organization-lead-todo-rule/organization-lead-todo-rule.actions';

export interface OrganizationLeadTodoAppliedRuleRelationsIds {
  organizationLeadTodo?: number;
  organizationLeadTodoRule?: number;
}

export function getDefaultAddOrganizationLeadTodoAppliedRuleActions(
  organizationLeadTodoAppliedRule: OrganizationLeadTodoAppliedRuleEntityState,
  ids?: OrganizationLeadTodoAppliedRuleRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationLeadTodoAppliedRuleActions.normalizeManyOrganizationLeadTodoAppliedRulesAfterUpsert({
      organizationLeadTodoAppliedRules: [organizationLeadTodoAppliedRule]
    })
  ];

  if (ids?.organizationLeadTodo) {
    actions.push(
      organizationLeadTodoActions.addManyOrganizationLeadTodoAppliedRuleSuccess({
        idOrganizationLeadTodo: ids.organizationLeadTodo,
        idOrganizationLeadTodoAppliedRules: [organizationLeadTodoAppliedRule.idOrganizationLeadTodoAppliedRule]
      })
    );
    actions.push(
      organizationLeadTodoAppliedRuleActions.addOrganizationLeadTodoSuccess({
        idOrganizationLeadTodoAppliedRule: organizationLeadTodoAppliedRule.idOrganizationLeadTodoAppliedRule,
        idOrganizationLeadTodo: ids.organizationLeadTodo
      })
    );
  }

  if (ids?.organizationLeadTodoRule) {
    actions.push(
      organizationLeadTodoRuleActions.addManyOrganizationLeadTodoAppliedRuleSuccess({
        idOrganizationLeadTodoRule: ids.organizationLeadTodoRule,
        idOrganizationLeadTodoAppliedRules: [organizationLeadTodoAppliedRule.idOrganizationLeadTodoAppliedRule]
      })
    );
    actions.push(
      organizationLeadTodoAppliedRuleActions.addOrganizationLeadTodoRuleSuccess({
        idOrganizationLeadTodoAppliedRule: organizationLeadTodoAppliedRule.idOrganizationLeadTodoAppliedRule,
        idOrganizationLeadTodoRule: ids.organizationLeadTodoRule
      })
    );
  }

  return actions;
}

export function getDefaultDeleteOrganizationLeadTodoAppliedRuleActions(
  organizationLeadTodoAppliedRule: OrganizationLeadTodoAppliedRuleEntityState
): Action[] {
  const actions: Action[] = [
    organizationLeadTodoAppliedRuleActions.deleteOneOrganizationLeadTodoAppliedRuleSuccess({
      idOrganizationLeadTodoAppliedRule: organizationLeadTodoAppliedRule.idOrganizationLeadTodoAppliedRule
    })
  ];

  if (organizationLeadTodoAppliedRule.organizationLeadTodo) {
    actions.push(
      organizationLeadTodoActions.deleteManyOrganizationLeadTodoAppliedRuleSuccess({
        idOrganizationLeadTodoAppliedRules: [organizationLeadTodoAppliedRule.idOrganizationLeadTodoAppliedRule],
        idOrganizationLeadTodos: [organizationLeadTodoAppliedRule.organizationLeadTodo as number]
      })
    );
  }

  if (organizationLeadTodoAppliedRule.organizationLeadTodoRule) {
    actions.push(
      organizationLeadTodoRuleActions.deleteManyOrganizationLeadTodoAppliedRuleSuccess({
        idOrganizationLeadTodoAppliedRules: [organizationLeadTodoAppliedRule.idOrganizationLeadTodoAppliedRule],
        idOrganizationLeadTodoRules: [organizationLeadTodoAppliedRule.organizationLeadTodoRule as number]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationLeadTodoAppliedRuleEffects {
  constructor(
    protected actions$: Actions,
    protected organizationLeadTodoAppliedRuleApiService: OrganizationLeadTodoAppliedRuleApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationLeadTodoAppliedRules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadTodoAppliedRuleActions.getManyOrganizationLeadTodoAppliedRules),
      switchMap(({ params }) =>
        this.organizationLeadTodoAppliedRuleApiService.getOrganizationLeadTodoAppliedRules(params).pipe(
          map((organizationLeadTodoAppliedRules: OrganizationLeadTodoAppliedRule[]) => {
            return organizationLeadTodoAppliedRuleActions.normalizeManyOrganizationLeadTodoAppliedRulesAfterUpsert({
              organizationLeadTodoAppliedRules
            });
          }),
          catchError(error =>
            of(organizationLeadTodoAppliedRuleActions.organizationLeadTodoAppliedRulesFailure({ error }))
          )
        )
      )
    );
  });

  getOneOrganizationLeadTodoAppliedRule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadTodoAppliedRuleActions.getOneOrganizationLeadTodoAppliedRule),
      switchMap(idOrganizationLeadTodoAppliedRule =>
        this.organizationLeadTodoAppliedRuleApiService
          .getOrganizationLeadTodoAppliedRule(idOrganizationLeadTodoAppliedRule)
          .pipe(
            map((organizationLeadTodoAppliedRule: OrganizationLeadTodoAppliedRule) => {
              return organizationLeadTodoAppliedRuleActions.normalizeManyOrganizationLeadTodoAppliedRulesAfterUpsert({
                organizationLeadTodoAppliedRules: [organizationLeadTodoAppliedRule]
              });
            }),
            catchError(error =>
              of(organizationLeadTodoAppliedRuleActions.organizationLeadTodoAppliedRulesFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneOrganizationLeadTodoAppliedRule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadTodoAppliedRuleActions.upsertOneOrganizationLeadTodoAppliedRule),
      concatMap(
        ({
          organizationLeadTodoAppliedRule,
          ids
        }: {
          organizationLeadTodoAppliedRule: Partial<OrganizationLeadTodoAppliedRule>;
          ids?: OrganizationLeadTodoAppliedRuleRelationsIds;
        }) => {
          if (organizationLeadTodoAppliedRule.idOrganizationLeadTodoAppliedRule) {
            return this.organizationLeadTodoAppliedRuleApiService
              .updateOrganizationLeadTodoAppliedRule(organizationLeadTodoAppliedRule)
              .pipe(
                map((organizationLeadTodoAppliedRuleReturned: OrganizationLeadTodoAppliedRule) => {
                  return organizationLeadTodoAppliedRuleActions.normalizeManyOrganizationLeadTodoAppliedRulesAfterUpsert(
                    { organizationLeadTodoAppliedRules: [organizationLeadTodoAppliedRuleReturned] }
                  );
                }),
                catchError(error =>
                  of(organizationLeadTodoAppliedRuleActions.organizationLeadTodoAppliedRulesFailure({ error }))
                )
              );
          } else {
            return this.organizationLeadTodoAppliedRuleApiService
              .addOrganizationLeadTodoAppliedRule(organizationLeadTodoAppliedRule)
              .pipe(
                mergeMap((organizationLeadTodoAppliedRuleReturned: OrganizationLeadTodoAppliedRule) =>
                  getDefaultAddOrganizationLeadTodoAppliedRuleActions(organizationLeadTodoAppliedRuleReturned, ids)
                ),
                catchError(error =>
                  of(organizationLeadTodoAppliedRuleActions.organizationLeadTodoAppliedRulesFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationLeadTodoAppliedRule$ = createEffect(() => {
    const selectOrganizationLeadTodoAppliedRuleState$ = this.store$.select(selectOrganizationLeadTodoAppliedRuleState);
    return this.actions$.pipe(
      ofType(organizationLeadTodoAppliedRuleActions.deleteOneOrganizationLeadTodoAppliedRule),
      withLatestFrom(selectOrganizationLeadTodoAppliedRuleState$),
      concatMap(([{ idOrganizationLeadTodoAppliedRule }, state]) =>
        this.organizationLeadTodoAppliedRuleApiService
          .deleteOrganizationLeadTodoAppliedRule(idOrganizationLeadTodoAppliedRule)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteOrganizationLeadTodoAppliedRuleActions(
                  state.entities[idOrganizationLeadTodoAppliedRule] as OrganizationLeadTodoAppliedRuleEntityState
                ),
                organizationLeadTodoAppliedRuleActions.deleteOneOrganizationLeadTodoAppliedRule.type
              )
            ]),
            catchError(error =>
              of(organizationLeadTodoAppliedRuleActions.organizationLeadTodoAppliedRulesFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyOrganizationLeadTodoAppliedRulesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadTodoAppliedRuleActions.normalizeManyOrganizationLeadTodoAppliedRulesAfterUpsert),
      concatMap(({ organizationLeadTodoAppliedRules }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationLeadTodoAppliedRule(
          organizationLeadTodoAppliedRules,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationLeadTodoAppliedRule] Normalization After Upsert Success')];
      })
    );
  });
}
