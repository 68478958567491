import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationSaleCategory,
  OrganizationSaleCategoryEntityState
} from '@_model/interfaces/organization-sale-category.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import {
  OrganizationLeadTodoRule,
  OrganizationLeadTodoRuleEntityState
} from '@_model/interfaces/organization-lead-todo-rule.model';
import {
  OrganizationStratalotTodoRule,
  OrganizationStratalotTodoRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-rule.model';
import {
  OrganizationResidenceTodoRule,
  OrganizationResidenceTodoRuleEntityState
} from '@_model/interfaces/organization-residence-todo-rule.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { SaleCategoryFamily, SaleCategoryFamilyEntityState } from '@_model/interfaces/sale-category-family.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationSaleCategoryFeatureKey,
  OrganizationSaleCategoryState
} from './organization-sale-category.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationSaleCategoryRelations: string[] = [
  'stratalots',
  'organizationLeadTodoRules',
  'organizationStratalotTodoRules',
  'organizationResidenceTodoRules',
  'organizations',
  'familySaleCategories'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationSaleCategoryState = createFeatureSelector<OrganizationSaleCategoryState>(
  organizationSaleCategoryFeatureKey
);

export const selectIsLoadedOrganizationSaleCategory = createSelector(
  selectOrganizationSaleCategoryState,
  (state: OrganizationSaleCategoryState) => state.isLoaded
);

export const selectIsLoadingOrganizationSaleCategory = createSelector(
  selectOrganizationSaleCategoryState,
  (state: OrganizationSaleCategoryState) => state.isLoading
);

export const selectIsReadyOrganizationSaleCategory = createSelector(
  selectOrganizationSaleCategoryState,
  (state: OrganizationSaleCategoryState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationSaleCategory = createSelector(
  selectOrganizationSaleCategoryState,
  (state: OrganizationSaleCategoryState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationSaleCategoryModel: SelectorModel = {
  name: 'organizationSaleCategories',
  getSelector: selectAllOrganizationSaleCategoriesDictionary,
  isReady: selectIsReadyOrganizationSaleCategory
};

export const selectOrganizationSaleCategoriesEntities = createSelector(
  selectOrganizationSaleCategoryState,
  selectEntities
);

export const selectOrganizationSaleCategoriesArray = createSelector(selectOrganizationSaleCategoryState, selectAll);

export const selectIdOrganizationSaleCategoriesActive = createSelector(
  selectOrganizationSaleCategoryState,
  (state: OrganizationSaleCategoryState) => state.actives
);

const organizationSaleCategoriesInObject = (
  organizationSaleCategories: Dictionary<OrganizationSaleCategoryEntityState>
) => ({ organizationSaleCategories });

const selectOrganizationSaleCategoriesEntitiesDictionary = createSelector(
  selectOrganizationSaleCategoriesEntities,
  organizationSaleCategoriesInObject
);

const selectAllOrganizationSaleCategoriesObject = createSelector(
  selectOrganizationSaleCategoriesEntities,
  organizationSaleCategories => {
    return hydrateAll({ organizationSaleCategories });
  }
);

const selectOneOrganizationSaleCategoryDictionary = (idOrganizationSaleCategory: number) =>
  createSelector(selectOrganizationSaleCategoriesEntities, organizationSaleCategories => {
    return {
      organizationSaleCategories: {
        [idOrganizationSaleCategory]: organizationSaleCategories[idOrganizationSaleCategory]
      }
    };
  });

const selectOneOrganizationSaleCategoryDictionaryWithoutChild = (idOrganizationSaleCategory: number) =>
  createSelector(selectOrganizationSaleCategoriesEntities, organizationSaleCategories => {
    return { organizationSaleCategory: organizationSaleCategories[idOrganizationSaleCategory] };
  });

const selectActiveOrganizationSaleCategoriesEntities = createSelector(
  selectIdOrganizationSaleCategoriesActive,
  selectOrganizationSaleCategoriesEntities,
  (actives: number[], organizationSaleCategories: Dictionary<OrganizationSaleCategoryEntityState>) =>
    getOrganizationSaleCategoriesFromActives(actives, organizationSaleCategories)
);

function getOrganizationSaleCategoriesFromActives(
  actives: number[],
  organizationSaleCategories: Dictionary<OrganizationSaleCategoryEntityState>
): Dictionary<OrganizationSaleCategoryEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationSaleCategories[idActive]) {
      acc[idActive] = organizationSaleCategories[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationSaleCategoryEntityState>);
}

const selectAllOrganizationSaleCategoriesSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationSaleCategories(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationSaleCategory>(
      schema,
      selectAllOrganizationSaleCategoriesSelectors,
      selectOrganizationSaleCategoriesEntitiesDictionary,
      getRelationSelectors,
      organizationSaleCategoryRelations,
      hydrateAll,
      'organizationSaleCategory'
    );
  } else {
    return selectAllOrganizationSaleCategoriesObject;
  }
}

export function selectAllOrganizationSaleCategoriesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationSaleCategories'
): Selector {
  return createSelector(selectAllOrganizationSaleCategories(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationSaleCategoryEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationSaleCategories.length; i++) {
      res[customKey][result.organizationSaleCategories[i].idOrganizationSaleCategory] =
        result.organizationSaleCategories[i];
    }
    return res;
  });
}

export function selectOneOrganizationSaleCategory(
  schema: SelectSchema = {},
  idOrganizationSaleCategory: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationSaleCategoryDictionary(idOrganizationSaleCategory)];
    selectors.push(...getRelationSelectors(schema, organizationSaleCategoryRelations, 'organizationSaleCategory'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationSaleCategoryDictionaryWithoutChild(idOrganizationSaleCategory);
  }
}

export function selectActiveOrganizationSaleCategories(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationSaleCategoriesEntities, organizationSaleCategories => ({
      organizationSaleCategories
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationSaleCategoryRelations, 'organizationSaleCategory'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationSaleCategories: Dictionary<OrganizationSaleCategoryEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  familySaleCategories?: Dictionary<SaleCategoryFamilyEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
  organizationLeadTodoRules?: Dictionary<OrganizationLeadTodoRuleEntityState>;
  organizationStratalotTodoRules?: Dictionary<OrganizationStratalotTodoRuleEntityState>;
  organizationResidenceTodoRules?: Dictionary<OrganizationResidenceTodoRuleEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationSaleCategories: (OrganizationSaleCategory | null)[];
} {
  const {
    organizationSaleCategories,
    organizations,
    familySaleCategories,
    stratalots,
    organizationLeadTodoRules,
    organizationStratalotTodoRules,
    organizationResidenceTodoRules
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationSaleCategories: Object.keys(organizationSaleCategories).map(idOrganizationSaleCategory =>
      hydrate(
        organizationSaleCategories[idOrganizationSaleCategory] as OrganizationSaleCategoryEntityState,
        organizations,
        familySaleCategories,
        stratalots,
        organizationLeadTodoRules,
        organizationStratalotTodoRules,
        organizationResidenceTodoRules
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationSaleCategory: OrganizationSaleCategoryEntityState | null } {
  const {
    organizationSaleCategories,
    organizations,
    familySaleCategories,
    stratalots,
    organizationLeadTodoRules,
    organizationStratalotTodoRules,
    organizationResidenceTodoRules
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationSaleCategory = Object.values(organizationSaleCategories)[0];
  return {
    organizationSaleCategory: hydrate(
      organizationSaleCategory as OrganizationSaleCategoryEntityState,
      organizations,
      familySaleCategories,
      stratalots,
      organizationLeadTodoRules,
      organizationStratalotTodoRules,
      organizationResidenceTodoRules
    )
  };
}

function hydrate(
  organizationSaleCategory: OrganizationSaleCategoryEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  saleCategoryFamilyEntities?: Dictionary<SaleCategoryFamilyEntityState>,
  stratalotEntities?: Dictionary<StratalotEntityState>,
  organizationLeadTodoRuleEntities?: Dictionary<OrganizationLeadTodoRuleEntityState>,
  organizationStratalotTodoRuleEntities?: Dictionary<OrganizationStratalotTodoRuleEntityState>,
  organizationResidenceTodoRuleEntities?: Dictionary<OrganizationResidenceTodoRuleEntityState>
): OrganizationSaleCategory | null {
  if (!organizationSaleCategory) {
    return null;
  }

  const organizationSaleCategoryHydrated: OrganizationSaleCategoryEntityState = { ...organizationSaleCategory };
  if (organizationEntities) {
    organizationSaleCategoryHydrated.organization = organizationEntities[
      organizationSaleCategory.organization as number
    ] as Organization;
  } else {
    delete organizationSaleCategoryHydrated.organization;
  }
  if (saleCategoryFamilyEntities) {
    organizationSaleCategoryHydrated.saleCategoryFamily = saleCategoryFamilyEntities[
      organizationSaleCategory.saleCategoryFamily as number
    ] as SaleCategoryFamily;
  } else {
    delete organizationSaleCategoryHydrated.saleCategoryFamily;
  }

  if (stratalotEntities) {
    organizationSaleCategoryHydrated.stratalots = ((organizationSaleCategoryHydrated.stratalots as number[]) || []).map(
      id => stratalotEntities[id]
    ) as Stratalot[];
  } else {
    delete organizationSaleCategoryHydrated.stratalots;
  }

  if (organizationLeadTodoRuleEntities) {
    organizationSaleCategoryHydrated.organizationLeadTodoRules = (
      (organizationSaleCategoryHydrated.organizationLeadTodoRules as number[]) || []
    ).map(id => organizationLeadTodoRuleEntities[id]) as OrganizationLeadTodoRule[];
  } else {
    delete organizationSaleCategoryHydrated.organizationLeadTodoRules;
  }

  if (organizationStratalotTodoRuleEntities) {
    organizationSaleCategoryHydrated.organizationStratalotTodoRules = (
      (organizationSaleCategoryHydrated.organizationStratalotTodoRules as number[]) || []
    ).map(id => organizationStratalotTodoRuleEntities[id]) as OrganizationStratalotTodoRule[];
  } else {
    delete organizationSaleCategoryHydrated.organizationStratalotTodoRules;
  }

  if (organizationResidenceTodoRuleEntities) {
    organizationSaleCategoryHydrated.organizationResidenceTodoRules = (
      (organizationSaleCategoryHydrated.organizationResidenceTodoRules as number[]) || []
    ).map(id => organizationResidenceTodoRuleEntities[id]) as OrganizationResidenceTodoRule[];
  } else {
    delete organizationSaleCategoryHydrated.organizationResidenceTodoRules;
  }

  return organizationSaleCategoryHydrated as OrganizationSaleCategory;
}
