import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Prospect, ProspectEntityState } from '@_model/interfaces/prospect.model';
import { ProspectEvent, ProspectEventEntityState } from '@_model/interfaces/prospect-event.model';
import { ProspectBuyingWish, ProspectBuyingWishEntityState } from '@_model/interfaces/prospect-buying-wish.model';
import { Lead, LeadEntityState } from '@_model/interfaces/lead.model';
import { Occupant, OccupantEntityState } from '@_model/interfaces/occupant.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import {
  OrganizationProspectOrigin,
  OrganizationProspectOriginEntityState
} from '@_model/interfaces/organization-prospect-origin.model';
import { User, UserEntityState } from '@_model/interfaces/user.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, prospectFeatureKey, ProspectState } from './prospect.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const prospectRelations: string[] = [
  'prospectEvents',
  'prospectBuyingWishs',
  'leads',
  'occupants',
  'organizations',
  'organizationProspectOrigins',
  'responsable',
  'updatedUser',
  'createdUser'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectProspectState = createFeatureSelector<ProspectState>(prospectFeatureKey);

export const selectIsLoadedProspect = createSelector(selectProspectState, (state: ProspectState) => state.isLoaded);

export const selectIsLoadingProspect = createSelector(selectProspectState, (state: ProspectState) => state.isLoading);

export const selectIsReadyProspect = createSelector(selectProspectState, (state: ProspectState) => !state.isLoading);

export const selectIsReadyAndLoadedProspect = createSelector(
  selectProspectState,
  (state: ProspectState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ProspectModel: SelectorModel = {
  name: 'prospects',
  getSelector: selectAllProspectsDictionary,
  isReady: selectIsReadyProspect
};

export const selectProspectsEntities = createSelector(selectProspectState, selectEntities);

export const selectProspectsArray = createSelector(selectProspectState, selectAll);

export const selectIdProspectsActive = createSelector(selectProspectState, (state: ProspectState) => state.actives);

const prospectsInObject = (prospects: Dictionary<ProspectEntityState>) => ({ prospects });

const selectProspectsEntitiesDictionary = createSelector(selectProspectsEntities, prospectsInObject);

const selectAllProspectsObject = createSelector(selectProspectsEntities, prospects => {
  return hydrateAll({ prospects });
});

const selectOneProspectDictionary = (idProspect: number) =>
  createSelector(selectProspectsEntities, prospects => {
    return { prospects: { [idProspect]: prospects[idProspect] } };
  });

const selectOneProspectDictionaryWithoutChild = (idProspect: number) =>
  createSelector(selectProspectsEntities, prospects => {
    return { prospect: prospects[idProspect] };
  });

const selectActiveProspectsEntities = createSelector(
  selectIdProspectsActive,
  selectProspectsEntities,
  (actives: number[], prospects: Dictionary<ProspectEntityState>) => getProspectsFromActives(actives, prospects)
);

function getProspectsFromActives(
  actives: number[],
  prospects: Dictionary<ProspectEntityState>
): Dictionary<ProspectEntityState> {
  return actives.reduce((acc, idActive) => {
    if (prospects[idActive]) {
      acc[idActive] = prospects[idActive];
    }
    return acc;
  }, {} as Dictionary<ProspectEntityState>);
}

const selectAllProspectsSelectors: Dictionary<Selector> = {};
export function selectAllProspects(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Prospect>(
      schema,
      selectAllProspectsSelectors,
      selectProspectsEntitiesDictionary,
      getRelationSelectors,
      prospectRelations,
      hydrateAll,
      'prospect'
    );
  } else {
    return selectAllProspectsObject;
  }
}

export function selectAllProspectsDictionary(schema: SelectSchema = {}, customKey: string = 'prospects'): Selector {
  return createSelector(selectAllProspects(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProspectEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.prospects.length; i++) {
      res[customKey][result.prospects[i].idProspect] = result.prospects[i];
    }
    return res;
  });
}

export function selectOneProspect(schema: SelectSchema = {}, idProspect: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneProspectDictionary(idProspect)];
    selectors.push(...getRelationSelectors(schema, prospectRelations, 'prospect'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProspectDictionaryWithoutChild(idProspect);
  }
}

export function selectActiveProspects(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [createSelector(selectActiveProspectsEntities, prospects => ({ prospects }))];
  selectors.push(...getRelationSelectors(schema, prospectRelations, 'prospect'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  prospects: Dictionary<ProspectEntityState>;
  occupants?: Dictionary<OccupantEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  organizationProspectOrigins?: Dictionary<OrganizationProspectOriginEntityState>;
  responsable?: Dictionary<UserEntityState>;
  updatedUser?: Dictionary<UserEntityState>;
  createdUser?: Dictionary<UserEntityState>;
  prospectEvents?: Dictionary<ProspectEventEntityState>;
  prospectBuyingWishs?: Dictionary<ProspectBuyingWishEntityState>;
  leads?: Dictionary<LeadEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { prospects: (Prospect | null)[] } {
  const {
    prospects,
    occupants,
    organizations,
    organizationProspectOrigins,
    responsable,
    updatedUser,
    createdUser,
    prospectEvents,
    prospectBuyingWishs,
    leads
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    prospects: Object.keys(prospects).map(idProspect =>
      hydrate(
        prospects[idProspect] as ProspectEntityState,
        occupants,
        organizations,
        organizationProspectOrigins,
        responsable,
        updatedUser,
        createdUser,
        prospectEvents,
        prospectBuyingWishs,
        leads
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { prospect: ProspectEntityState | null } {
  const {
    prospects,
    occupants,
    organizations,
    organizationProspectOrigins,
    responsable,
    updatedUser,
    createdUser,
    prospectEvents,
    prospectBuyingWishs,
    leads
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const prospect = Object.values(prospects)[0];
  return {
    prospect: hydrate(
      prospect as ProspectEntityState,
      occupants,
      organizations,
      organizationProspectOrigins,
      responsable,
      updatedUser,
      createdUser,
      prospectEvents,
      prospectBuyingWishs,
      leads
    )
  };
}

function hydrate(
  prospect: ProspectEntityState,
  occupantEntities?: Dictionary<OccupantEntityState>,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  organizationProspectOriginEntities?: Dictionary<OrganizationProspectOriginEntityState>,
  responsableEntities?: Dictionary<UserEntityState>,
  updatedUserEntities?: Dictionary<UserEntityState>,
  createdUserEntities?: Dictionary<UserEntityState>,
  prospectEventEntities?: Dictionary<ProspectEventEntityState>,
  prospectBuyingWishEntities?: Dictionary<ProspectBuyingWishEntityState>,
  leadEntities?: Dictionary<LeadEntityState>
): Prospect | null {
  if (!prospect) {
    return null;
  }

  const prospectHydrated: ProspectEntityState = { ...prospect };
  if (occupantEntities) {
    prospectHydrated.occupant = occupantEntities[prospect.occupant as number] as Occupant;
  } else {
    delete prospectHydrated.occupant;
  }
  if (organizationEntities) {
    prospectHydrated.organization = organizationEntities[prospect.organization as number] as Organization;
  } else {
    delete prospectHydrated.organization;
  }
  if (organizationProspectOriginEntities) {
    prospectHydrated.organizationProspectOrigin = organizationProspectOriginEntities[
      prospect.organizationProspectOrigin as number
    ] as OrganizationProspectOrigin;
  } else {
    delete prospectHydrated.organizationProspectOrigin;
  }
  if (responsableEntities) {
    prospectHydrated.responsable = responsableEntities[prospect.responsable as number] as User;
  } else {
    delete prospectHydrated.responsable;
  }
  if (updatedUserEntities) {
    prospectHydrated.updatedUser = updatedUserEntities[prospect.updatedUser as number] as User;
  } else {
    delete prospectHydrated.updatedUser;
  }
  if (createdUserEntities) {
    prospectHydrated.createdUser = createdUserEntities[prospect.createdUser as number] as User;
  } else {
    delete prospectHydrated.createdUser;
  }

  if (prospectEventEntities) {
    prospectHydrated.prospectEvents = ((prospectHydrated.prospectEvents as number[]) || []).map(
      id => prospectEventEntities[id]
    ) as ProspectEvent[];
  } else {
    delete prospectHydrated.prospectEvents;
  }

  if (prospectBuyingWishEntities) {
    prospectHydrated.prospectBuyingWishs = ((prospectHydrated.prospectBuyingWishs as number[]) || []).map(
      id => prospectBuyingWishEntities[id]
    ) as ProspectBuyingWish[];
  } else {
    delete prospectHydrated.prospectBuyingWishs;
  }

  if (leadEntities) {
    prospectHydrated.leads = ((prospectHydrated.leads as number[]) || []).map(id => leadEntities[id]) as Lead[];
  } else {
    delete prospectHydrated.leads;
  }

  return prospectHydrated as Prospect;
}
