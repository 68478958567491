import { ProspectEventState, initialState } from './prospect-event.state';
import { Action, createReducer, on } from '@ngrx/store';
import { prospectEventReducersGeneratedFunctions } from './prospect-event-generated.reducer';
import * as prospectEventActions from '@_store/prospect-event/prospect-event.actions';

const prospectEventReducersFunctions = [
  ...prospectEventReducersGeneratedFunctions,
  on(prospectEventActions.deleteManyProspectEventByProspectSuccess, (state: ProspectEventState, { idProspect }) => {
    return {
      ...state,
      entities: Object.keys(state.entities)
        .filter((idProspectEvent: string) => state.entities[idProspectEvent].prospect !== idProspect)
        .map((idProspectEvent: string) => {
          return { ...state.entities[idProspectEvent] };
        })
        .reduce((acc, curr) => {
          acc[curr.idProspectEvent] = curr;
          return acc;
        }, {})
    };
  })
];

const prospectEventReducer = createReducer(initialState, ...prospectEventReducersFunctions);

export function reducer(state: ProspectEventState | undefined, action: Action) {
  return prospectEventReducer(state, action);
}
