import { RepositoryService } from '@_services/api/repository.service';
import { ProspectBuyingWish } from '@_model/interfaces/prospect-buying-wish.model';
import { Observable } from 'rxjs';

export class GeneratedProspectBuyingWishApiService {
  constructor(protected repo: RepositoryService) {}

  public getProspectBuyingWishs(params?: any): Observable<ProspectBuyingWish[]> {
    return this.repo.getData<ProspectBuyingWish[]>('prospect-buying-wish', params);
  }

  public getProspectBuyingWish(params: { idProspectBuyingWish: number; params?: any }): Observable<ProspectBuyingWish> {
    return this.repo.getData<ProspectBuyingWish>('prospect-buying-wish/' + params.idProspectBuyingWish, params.params);
  }

  public addProspectBuyingWish(prospectBuyingWish: Partial<ProspectBuyingWish>): Observable<ProspectBuyingWish> {
    return this.repo.create<ProspectBuyingWish>('prospect-buying-wish', prospectBuyingWish);
  }

  public updateProspectBuyingWish(prospectBuyingWish: Partial<ProspectBuyingWish>): Observable<ProspectBuyingWish> {
    return this.repo.update('prospect-buying-wish', prospectBuyingWish);
  }

  public deleteProspectBuyingWish(idProspectBuyingWish: number): Observable<number> {
    return this.repo.delete('prospect-buying-wish/' + +idProspectBuyingWish);
  }
}
