import { OrganizationResidenceTodoState, initialState } from './organization-residence-todo.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationResidenceTodoReducersGeneratedFunctions } from './organization-residence-todo-generated.reducer';

const organizationResidenceTodoReducersFunctions = [...organizationResidenceTodoReducersGeneratedFunctions];

const organizationResidenceTodoReducer = createReducer(initialState, ...organizationResidenceTodoReducersFunctions);

export function reducer(state: OrganizationResidenceTodoState | undefined, action: Action) {
  return organizationResidenceTodoReducer(state, action);
}
