import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ResidenceStudyFounding,
  ResidenceStudyFoundingEntityState
} from '@_model/interfaces/residence-study-founding.model';
import { ResidenceStudy, ResidenceStudyEntityState } from '@_model/interfaces/residence-study.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  residenceStudyFoundingFeatureKey,
  ResidenceStudyFoundingState
} from './residence-study-founding.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residenceStudyFoundingRelations: string[] = ['residenceStudies'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidenceStudyFoundingState = createFeatureSelector<ResidenceStudyFoundingState>(
  residenceStudyFoundingFeatureKey
);

export const selectIsLoadedResidenceStudyFounding = createSelector(
  selectResidenceStudyFoundingState,
  (state: ResidenceStudyFoundingState) => state.isLoaded
);

export const selectIsLoadingResidenceStudyFounding = createSelector(
  selectResidenceStudyFoundingState,
  (state: ResidenceStudyFoundingState) => state.isLoading
);

export const selectIsReadyResidenceStudyFounding = createSelector(
  selectResidenceStudyFoundingState,
  (state: ResidenceStudyFoundingState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidenceStudyFounding = createSelector(
  selectResidenceStudyFoundingState,
  (state: ResidenceStudyFoundingState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidenceStudyFoundingModel: SelectorModel = {
  name: 'residenceStudyFoundings',
  getSelector: selectAllResidenceStudyFoundingsDictionary,
  isReady: selectIsReadyResidenceStudyFounding
};

export const selectResidenceStudyFoundingsEntities = createSelector(selectResidenceStudyFoundingState, selectEntities);

export const selectResidenceStudyFoundingsArray = createSelector(selectResidenceStudyFoundingState, selectAll);

export const selectIdResidenceStudyFoundingsActive = createSelector(
  selectResidenceStudyFoundingState,
  (state: ResidenceStudyFoundingState) => state.actives
);

const residenceStudyFoundingsInObject = (residenceStudyFoundings: Dictionary<ResidenceStudyFoundingEntityState>) => ({
  residenceStudyFoundings
});

const selectResidenceStudyFoundingsEntitiesDictionary = createSelector(
  selectResidenceStudyFoundingsEntities,
  residenceStudyFoundingsInObject
);

const selectAllResidenceStudyFoundingsObject = createSelector(
  selectResidenceStudyFoundingsEntities,
  residenceStudyFoundings => {
    return hydrateAll({ residenceStudyFoundings });
  }
);

const selectOneResidenceStudyFoundingDictionary = (idResidenceStudyFounding: number) =>
  createSelector(selectResidenceStudyFoundingsEntities, residenceStudyFoundings => {
    return {
      residenceStudyFoundings: { [idResidenceStudyFounding]: residenceStudyFoundings[idResidenceStudyFounding] }
    };
  });

const selectOneResidenceStudyFoundingDictionaryWithoutChild = (idResidenceStudyFounding: number) =>
  createSelector(selectResidenceStudyFoundingsEntities, residenceStudyFoundings => {
    return { residenceStudyFounding: residenceStudyFoundings[idResidenceStudyFounding] };
  });

const selectActiveResidenceStudyFoundingsEntities = createSelector(
  selectIdResidenceStudyFoundingsActive,
  selectResidenceStudyFoundingsEntities,
  (actives: number[], residenceStudyFoundings: Dictionary<ResidenceStudyFoundingEntityState>) =>
    getResidenceStudyFoundingsFromActives(actives, residenceStudyFoundings)
);

function getResidenceStudyFoundingsFromActives(
  actives: number[],
  residenceStudyFoundings: Dictionary<ResidenceStudyFoundingEntityState>
): Dictionary<ResidenceStudyFoundingEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residenceStudyFoundings[idActive]) {
      acc[idActive] = residenceStudyFoundings[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidenceStudyFoundingEntityState>);
}

const selectAllResidenceStudyFoundingsSelectors: Dictionary<Selector> = {};
export function selectAllResidenceStudyFoundings(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidenceStudyFounding>(
      schema,
      selectAllResidenceStudyFoundingsSelectors,
      selectResidenceStudyFoundingsEntitiesDictionary,
      getRelationSelectors,
      residenceStudyFoundingRelations,
      hydrateAll,
      'residenceStudyFounding'
    );
  } else {
    return selectAllResidenceStudyFoundingsObject;
  }
}

export function selectAllResidenceStudyFoundingsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residenceStudyFoundings'
): Selector {
  return createSelector(selectAllResidenceStudyFoundings(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidenceStudyFoundingEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residenceStudyFoundings.length; i++) {
      res[customKey][result.residenceStudyFoundings[i].idResidenceStudyFounding] = result.residenceStudyFoundings[i];
    }
    return res;
  });
}

export function selectOneResidenceStudyFounding(schema: SelectSchema = {}, idResidenceStudyFounding: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidenceStudyFoundingDictionary(idResidenceStudyFounding)];
    selectors.push(...getRelationSelectors(schema, residenceStudyFoundingRelations, 'residenceStudyFounding'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidenceStudyFoundingDictionaryWithoutChild(idResidenceStudyFounding);
  }
}

export function selectActiveResidenceStudyFoundings(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidenceStudyFoundingsEntities, residenceStudyFoundings => ({
      residenceStudyFoundings
    }))
  ];
  selectors.push(...getRelationSelectors(schema, residenceStudyFoundingRelations, 'residenceStudyFounding'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residenceStudyFoundings: Dictionary<ResidenceStudyFoundingEntityState>;
  residenceStudies?: Dictionary<ResidenceStudyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { residenceStudyFoundings: (ResidenceStudyFounding | null)[] } {
  const { residenceStudyFoundings, residenceStudies } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    residenceStudyFoundings: Object.keys(residenceStudyFoundings).map(idResidenceStudyFounding =>
      hydrate(residenceStudyFoundings[idResidenceStudyFounding] as ResidenceStudyFoundingEntityState, residenceStudies)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { residenceStudyFounding: ResidenceStudyFoundingEntityState | null } {
  const { residenceStudyFoundings, residenceStudies } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const residenceStudyFounding = Object.values(residenceStudyFoundings)[0];
  return {
    residenceStudyFounding: hydrate(residenceStudyFounding as ResidenceStudyFoundingEntityState, residenceStudies)
  };
}

function hydrate(
  residenceStudyFounding: ResidenceStudyFoundingEntityState,
  residenceStudyEntities?: Dictionary<ResidenceStudyEntityState>
): ResidenceStudyFounding | null {
  if (!residenceStudyFounding) {
    return null;
  }

  const residenceStudyFoundingHydrated: ResidenceStudyFoundingEntityState = { ...residenceStudyFounding };
  if (residenceStudyEntities) {
    residenceStudyFoundingHydrated.residenceStudy = residenceStudyEntities[
      residenceStudyFounding.residenceStudy as number
    ] as ResidenceStudy;
  } else {
    delete residenceStudyFoundingHydrated.residenceStudy;
  }

  return residenceStudyFoundingHydrated as ResidenceStudyFounding;
}
