import { CompanyMediaState, initialState } from './company-media.state';
import { Action, createReducer } from '@ngrx/store';
import { companyMediaReducersGeneratedFunctions } from './company-media-generated.reducer';

const companyMediaReducersFunctions = [...companyMediaReducersGeneratedFunctions];

const companyMediaReducer = createReducer(initialState, ...companyMediaReducersFunctions);

export function reducer(state: CompanyMediaState | undefined, action: Action) {
  return companyMediaReducer(state, action);
}
