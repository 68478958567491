import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Step, StepEntityState } from '@_model/interfaces/step.model';
import { StepProgress, StepProgressEntityState } from '@_model/interfaces/step-progress.model';
import { OrganizationLeadTodo, OrganizationLeadTodoEntityState } from '@_model/interfaces/organization-lead-todo.model';
import {
  OrganizationStratalotAvancement,
  OrganizationStratalotAvancementEntityState
} from '@_model/interfaces/organization-stratalot-avancement.model';
import {
  OrganizationLeadAvancement,
  OrganizationLeadAvancementEntityState
} from '@_model/interfaces/organization-lead-avancement.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, stepFeatureKey, StepState } from './step.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const stepRelations: string[] = [
  'stepProgresses',
  'organizationLeadTodos',
  'organizationStratalotAvancements',
  'organizationLeadAvancements'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectStepState = createFeatureSelector<StepState>(stepFeatureKey);

export const selectIsLoadedStep = createSelector(selectStepState, (state: StepState) => state.isLoaded);

export const selectIsLoadingStep = createSelector(selectStepState, (state: StepState) => state.isLoading);

export const selectIsReadyStep = createSelector(selectStepState, (state: StepState) => !state.isLoading);

export const selectIsReadyAndLoadedStep = createSelector(
  selectStepState,
  (state: StepState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const StepModel: SelectorModel = {
  name: 'steps',
  getSelector: selectAllStepsDictionary,
  isReady: selectIsReadyStep
};

export const selectStepsEntities = createSelector(selectStepState, selectEntities);

export const selectStepsArray = createSelector(selectStepState, selectAll);

export const selectIdStepsActive = createSelector(selectStepState, (state: StepState) => state.actives);

const stepsInObject = (steps: Dictionary<StepEntityState>) => ({ steps });

const selectStepsEntitiesDictionary = createSelector(selectStepsEntities, stepsInObject);

const selectAllStepsObject = createSelector(selectStepsEntities, steps => {
  return hydrateAll({ steps });
});

const selectOneStepDictionary = (idStep: number) =>
  createSelector(selectStepsEntities, steps => {
    return { steps: { [idStep]: steps[idStep] } };
  });

const selectOneStepDictionaryWithoutChild = (idStep: number) =>
  createSelector(selectStepsEntities, steps => {
    return { step: steps[idStep] };
  });

const selectActiveStepsEntities = createSelector(
  selectIdStepsActive,
  selectStepsEntities,
  (actives: number[], steps: Dictionary<StepEntityState>) => getStepsFromActives(actives, steps)
);

function getStepsFromActives(actives: number[], steps: Dictionary<StepEntityState>): Dictionary<StepEntityState> {
  return actives.reduce((acc, idActive) => {
    if (steps[idActive]) {
      acc[idActive] = steps[idActive];
    }
    return acc;
  }, {} as Dictionary<StepEntityState>);
}

const selectAllStepsSelectors: Dictionary<Selector> = {};
export function selectAllSteps(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Step>(
      schema,
      selectAllStepsSelectors,
      selectStepsEntitiesDictionary,
      getRelationSelectors,
      stepRelations,
      hydrateAll,
      'step'
    );
  } else {
    return selectAllStepsObject;
  }
}

export function selectAllStepsDictionary(schema: SelectSchema = {}, customKey: string = 'steps'): Selector {
  return createSelector(selectAllSteps(schema), result => {
    const res = { [customKey]: {} as Dictionary<StepEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.steps.length; i++) {
      res[customKey][result.steps[i].idStep] = result.steps[i];
    }
    return res;
  });
}

export function selectOneStep(schema: SelectSchema = {}, idStep: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneStepDictionary(idStep)];
    selectors.push(...getRelationSelectors(schema, stepRelations, 'step'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneStepDictionaryWithoutChild(idStep);
  }
}

export function selectActiveSteps(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [createSelector(selectActiveStepsEntities, steps => ({ steps }))];
  selectors.push(...getRelationSelectors(schema, stepRelations, 'step'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  steps: Dictionary<StepEntityState>;
  stepProgresses?: Dictionary<StepProgressEntityState>;
  organizationLeadTodos?: Dictionary<OrganizationLeadTodoEntityState>;
  organizationStratalotAvancements?: Dictionary<OrganizationStratalotAvancementEntityState>;
  organizationLeadAvancements?: Dictionary<OrganizationLeadAvancementEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { steps: (Step | null)[] } {
  const {
    steps,
    stepProgresses,
    organizationLeadTodos,
    organizationStratalotAvancements,
    organizationLeadAvancements
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    steps: Object.keys(steps).map(idStep =>
      hydrate(
        steps[idStep] as StepEntityState,
        stepProgresses,
        organizationLeadTodos,
        organizationStratalotAvancements,
        organizationLeadAvancements
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { step: StepEntityState | null } {
  const {
    steps,
    stepProgresses,
    organizationLeadTodos,
    organizationStratalotAvancements,
    organizationLeadAvancements
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const step = Object.values(steps)[0];
  return {
    step: hydrate(
      step as StepEntityState,
      stepProgresses,
      organizationLeadTodos,
      organizationStratalotAvancements,
      organizationLeadAvancements
    )
  };
}

function hydrate(
  step: StepEntityState,
  stepProgressEntities?: Dictionary<StepProgressEntityState>,
  organizationLeadTodoEntities?: Dictionary<OrganizationLeadTodoEntityState>,
  organizationStratalotAvancementEntities?: Dictionary<OrganizationStratalotAvancementEntityState>,
  organizationLeadAvancementEntities?: Dictionary<OrganizationLeadAvancementEntityState>
): Step | null {
  if (!step) {
    return null;
  }

  const stepHydrated: StepEntityState = { ...step };

  if (stepProgressEntities) {
    stepHydrated.stepProgresses = ((stepHydrated.stepProgresses as number[]) || []).map(
      id => stepProgressEntities[id]
    ) as StepProgress[];
  } else {
    delete stepHydrated.stepProgresses;
  }

  if (organizationLeadTodoEntities) {
    stepHydrated.organizationLeadTodos = ((stepHydrated.organizationLeadTodos as number[]) || []).map(
      id => organizationLeadTodoEntities[id]
    ) as OrganizationLeadTodo[];
  } else {
    delete stepHydrated.organizationLeadTodos;
  }

  if (organizationStratalotAvancementEntities) {
    stepHydrated.organizationStratalotAvancements = (
      (stepHydrated.organizationStratalotAvancements as number[]) || []
    ).map(id => organizationStratalotAvancementEntities[id]) as OrganizationStratalotAvancement[];
  } else {
    delete stepHydrated.organizationStratalotAvancements;
  }

  if (organizationLeadAvancementEntities) {
    stepHydrated.organizationLeadAvancements = ((stepHydrated.organizationLeadAvancements as number[]) || []).map(
      id => organizationLeadAvancementEntities[id]
    ) as OrganizationLeadAvancement[];
  } else {
    delete stepHydrated.organizationLeadAvancements;
  }

  return stepHydrated as Step;
}
