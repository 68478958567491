import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { Profil, ProfilEntityState } from '@_model/interfaces/profil.model';
import { ProfilApiService } from '@_services/api/profil-api.service';
import * as profilActions from '@_store/profil/profil.actions';
import { getActionsToNormalizeProfil } from '@_config/store/normalization.generated';
import { selectProfilState } from './profil-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as userActions from '@_store/user/user.actions';
import { User } from '@_model/interfaces/user.model';
import * as droitActions from '@_store/droit/droit.actions';
import { Droit } from '@_model/interfaces/droit.model';
import * as organizationLeadTodoProfilActions from '@_store/organization-lead-todo-profil/organization-lead-todo-profil.actions';
import { OrganizationLeadTodoProfil } from '@_model/interfaces/organization-lead-todo-profil.model';
import * as organizationResidenceTodoProfilActions from '@_store/organization-residence-todo-profil/organization-residence-todo-profil.actions';
import { OrganizationResidenceTodoProfil } from '@_model/interfaces/organization-residence-todo-profil.model';
import * as organizationStratalotTodoProfilActions from '@_store/organization-stratalot-todo-profil/organization-stratalot-todo-profil.actions';
import { OrganizationStratalotTodoProfil } from '@_model/interfaces/organization-stratalot-todo-profil.model';

export interface ProfilRelationsIds {
  users?: number | number[];
  droits?: number | number[];
  organizationLeadTodoProfils?: number | number[];
  organizationResidenceTodoProfils?: number | number[];
  organizationStratalotTodoProfils?: number | number[];
  organization?: number;
}

export function getDefaultAddProfilActions(profil: ProfilEntityState, ids?: ProfilRelationsIds): Action[] {
  const actions: Action[] = [profilActions.normalizeManyProfilsAfterUpsert({ profils: [profil] })];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyProfilSuccess({
        idOrganization: ids.organization,
        idProfils: [profil.idProfil]
      })
    );
    actions.push(
      profilActions.addOrganizationSuccess({
        idProfil: profil.idProfil,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.users) {
    if (!Array.isArray(ids.users)) {
      actions.push(
        userActions.upsertOneUser({
          user: {
            idProfil: profil.idProfil,
            idUser: ids.users as number
          } as User & any
        })
      );
      actions.push(
        profilActions.addManyUserSuccess({
          idProfil: profil.idProfil,
          idUsers: [ids.users as number]
        })
      );
    } else {
      actions.push(
        userActions.upsertManyUsers({
          users: (ids.users as number[]).map((idUser: number) => ({
            idProfil: profil.idProfil,
            idUser
          })) as User[] & any[]
        })
      );
      actions.push(
        profilActions.addManyUserSuccess({
          idProfil: profil.idProfil,
          idUsers: ids.users as number[]
        })
      );
    }
  }

  if (ids?.droits) {
    if (!Array.isArray(ids.droits)) {
      actions.push(
        droitActions.upsertOneDroit({
          droit: {
            idProfil: profil.idProfil,
            idDroit: ids.droits as number
          } as Droit & any
        })
      );
      actions.push(
        profilActions.addManyDroitSuccess({
          idProfil: profil.idProfil,
          idDroits: [ids.droits as number]
        })
      );
    } else {
      actions.push(
        droitActions.upsertManyDroits({
          droits: (ids.droits as number[]).map((idDroit: number) => ({
            idProfil: profil.idProfil,
            idDroit
          })) as Droit[] & any[]
        })
      );
      actions.push(
        profilActions.addManyDroitSuccess({
          idProfil: profil.idProfil,
          idDroits: ids.droits as number[]
        })
      );
    }
  }

  if (ids?.organizationLeadTodoProfils) {
    if (!Array.isArray(ids.organizationLeadTodoProfils)) {
      actions.push(
        organizationLeadTodoProfilActions.upsertOneOrganizationLeadTodoProfil({
          organizationLeadTodoProfil: {
            idProfil: profil.idProfil,
            idOrganizationLeadTodoProfil: ids.organizationLeadTodoProfils as number
          } as OrganizationLeadTodoProfil
        })
      );
      actions.push(
        profilActions.addManyOrganizationLeadTodoProfilSuccess({
          idProfil: profil.idProfil,
          idOrganizationLeadTodoProfils: [ids.organizationLeadTodoProfils as number]
        })
      );
    } else {
      actions.push(
        organizationLeadTodoProfilActions.upsertManyOrganizationLeadTodoProfils({
          organizationLeadTodoProfils: (ids.organizationLeadTodoProfils as number[]).map(
            (idOrganizationLeadTodoProfil: number) => ({
              idProfil: profil.idProfil,
              idOrganizationLeadTodoProfil
            })
          ) as OrganizationLeadTodoProfil[]
        })
      );
      actions.push(
        profilActions.addManyOrganizationLeadTodoProfilSuccess({
          idProfil: profil.idProfil,
          idOrganizationLeadTodoProfils: ids.organizationLeadTodoProfils as number[]
        })
      );
    }
  }

  if (ids?.organizationResidenceTodoProfils) {
    if (!Array.isArray(ids.organizationResidenceTodoProfils)) {
      actions.push(
        organizationResidenceTodoProfilActions.upsertOneOrganizationResidenceTodoProfil({
          organizationResidenceTodoProfil: {
            idProfil: profil.idProfil,
            idOrganizationResidenceTodoProfil: ids.organizationResidenceTodoProfils as number
          } as OrganizationResidenceTodoProfil
        })
      );
      actions.push(
        profilActions.addManyOrganizationResidenceTodoProfilSuccess({
          idProfil: profil.idProfil,
          idOrganizationResidenceTodoProfils: [ids.organizationResidenceTodoProfils as number]
        })
      );
    } else {
      actions.push(
        organizationResidenceTodoProfilActions.upsertManyOrganizationResidenceTodoProfils({
          organizationResidenceTodoProfils: (ids.organizationResidenceTodoProfils as number[]).map(
            (idOrganizationResidenceTodoProfil: number) => ({
              idProfil: profil.idProfil,
              idOrganizationResidenceTodoProfil
            })
          ) as OrganizationResidenceTodoProfil[]
        })
      );
      actions.push(
        profilActions.addManyOrganizationResidenceTodoProfilSuccess({
          idProfil: profil.idProfil,
          idOrganizationResidenceTodoProfils: ids.organizationResidenceTodoProfils as number[]
        })
      );
    }
  }

  if (ids?.organizationStratalotTodoProfils) {
    if (!Array.isArray(ids.organizationStratalotTodoProfils)) {
      actions.push(
        organizationStratalotTodoProfilActions.upsertOneOrganizationStratalotTodoProfil({
          organizationStratalotTodoProfil: {
            idProfil: profil.idProfil,
            idOrganizationStratalotTodoProfil: ids.organizationStratalotTodoProfils as number
          } as OrganizationStratalotTodoProfil
        })
      );
      actions.push(
        profilActions.addManyOrganizationStratalotTodoProfilSuccess({
          idProfil: profil.idProfil,
          idOrganizationStratalotTodoProfils: [ids.organizationStratalotTodoProfils as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotTodoProfilActions.upsertManyOrganizationStratalotTodoProfils({
          organizationStratalotTodoProfils: (ids.organizationStratalotTodoProfils as number[]).map(
            (idOrganizationStratalotTodoProfil: number) => ({
              idProfil: profil.idProfil,
              idOrganizationStratalotTodoProfil
            })
          ) as OrganizationStratalotTodoProfil[]
        })
      );
      actions.push(
        profilActions.addManyOrganizationStratalotTodoProfilSuccess({
          idProfil: profil.idProfil,
          idOrganizationStratalotTodoProfils: ids.organizationStratalotTodoProfils as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteProfilActions(profil: ProfilEntityState): Action[] {
  const actions: Action[] = [profilActions.deleteOneProfilSuccess({ idProfil: profil.idProfil })];

  if (profil.organization) {
    actions.push(
      organizationActions.deleteManyProfilSuccess({
        idProfils: [profil.idProfil],
        idOrganizations: [profil.organization as number]
      })
    );
  }

  if (profil.users) {
    actions.push(
      userActions.deleteManyProfilSuccess({
        idProfils: [profil.idProfil],
        idUsers: profil.users as number[]
      })
    );
  }

  if (profil.droits) {
    actions.push(
      droitActions.deleteManyProfilSuccess({
        idProfils: [profil.idProfil],
        idDroits: profil.droits as number[]
      })
    );
  }

  if (profil.organizationLeadTodoProfils) {
    actions.push(
      organizationLeadTodoProfilActions.deleteManyProfilSuccess({
        idProfils: [profil.idProfil],
        idOrganizationLeadTodoProfils: profil.organizationLeadTodoProfils as number[]
      })
    );
  }

  if (profil.organizationResidenceTodoProfils) {
    actions.push(
      organizationResidenceTodoProfilActions.deleteManyProfilSuccess({
        idProfils: [profil.idProfil],
        idOrganizationResidenceTodoProfils: profil.organizationResidenceTodoProfils as number[]
      })
    );
  }

  if (profil.organizationStratalotTodoProfils) {
    actions.push(
      organizationStratalotTodoProfilActions.deleteManyProfilSuccess({
        idProfils: [profil.idProfil],
        idOrganizationStratalotTodoProfils: profil.organizationStratalotTodoProfils as number[]
      })
    );
  }

  return actions;
}

export class GeneratedProfilEffects {
  constructor(
    protected actions$: Actions,
    protected profilApiService: ProfilApiService,
    protected store$: Store<AppState>
  ) {}

  getManyProfils$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profilActions.getManyProfils),
      switchMap(({ params }) =>
        this.profilApiService.getProfils(params).pipe(
          map((profils: Profil[]) => {
            return profilActions.normalizeManyProfilsAfterUpsert({ profils });
          }),
          catchError(error => of(profilActions.profilsFailure({ error })))
        )
      )
    );
  });

  getOneProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profilActions.getOneProfil),
      switchMap(idProfil =>
        this.profilApiService.getProfil(idProfil).pipe(
          map((profil: Profil) => {
            return profilActions.normalizeManyProfilsAfterUpsert({ profils: [profil] });
          }),
          catchError(error => of(profilActions.profilsFailure({ error })))
        )
      )
    );
  });

  upsertOneProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profilActions.upsertOneProfil),
      concatMap(({ profil, ids }: { profil: Partial<Profil>; ids?: ProfilRelationsIds }) => {
        if (profil.idProfil) {
          return this.profilApiService.updateProfil(profil).pipe(
            map((profilReturned: Profil) => {
              return profilActions.normalizeManyProfilsAfterUpsert({ profils: [profilReturned] });
            }),
            catchError(error => of(profilActions.profilsFailure({ error })))
          );
        } else {
          return this.profilApiService.addProfil(profil).pipe(
            mergeMap((profilReturned: Profil) => getDefaultAddProfilActions(profilReturned, ids)),
            catchError(error => of(profilActions.profilsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneProfil$ = createEffect(() => {
    const selectProfilState$ = this.store$.select(selectProfilState);
    return this.actions$.pipe(
      ofType(profilActions.deleteOneProfil),
      withLatestFrom(selectProfilState$),
      concatMap(([{ idProfil }, state]) =>
        this.profilApiService.deleteProfil(idProfil).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteProfilActions(state.entities[idProfil] as ProfilEntityState),
              profilActions.deleteOneProfil.type
            )
          ]),
          catchError(error => of(profilActions.profilsFailure({ error })))
        )
      )
    );
  });

  normalizeManyProfilsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(profilActions.normalizeManyProfilsAfterUpsert),
      concatMap(({ profils }) => {
        const actions: Action[] = getActionsToNormalizeProfil(profils, StoreActionType.upsert);
        return [getMultiAction(actions, '[Profil] Normalization After Upsert Success')];
      })
    );
  });
}
