import { OrganizationThirdPartyState, initialState } from './organization-third-party.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationThirdPartyReducersGeneratedFunctions } from './organization-third-party-generated.reducer';

const organizationThirdPartyReducersFunctions = [...organizationThirdPartyReducersGeneratedFunctions];

const organizationThirdPartyReducer = createReducer(initialState, ...organizationThirdPartyReducersFunctions);

export function reducer(state: OrganizationThirdPartyState | undefined, action: Action) {
  return organizationThirdPartyReducer(state, action);
}
