import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Profil, ProfilEntityState } from '@_model/interfaces/profil.model';
import { ProfilRelationsIds } from './profil-generated.effects';

export const getOneProfil = createAction('[Profil] Get One Profil', props<{ idProfil: number; params?: any }>());

export const getManyProfils = createAction('[Profil] Get Many Profils', props<{ params: any }>());

export const addManyActives = createAction('[Profil] Add Many Actives Profil', props<{ idProfils: number[] }>());

export const deleteOneActive = createAction('[Profil] Delete One Active Profil', props<{ idProfil: number }>());

export const clearActive = createAction('[Profil] Clear Active Profil');

export const upsertOneProfil = createAction(
  '[Profil] Upsert One Profil',
  props<{
    profil: Partial<Profil>;
    ids?: ProfilRelationsIds;
  }>()
);

export const upsertManyProfils = createAction(
  '[Profil] Upsert Many Profils',
  props<{
    profils: Partial<Profil>[];
    ids?: ProfilRelationsIds;
  }>()
);

export const upsertManyProfilsSuccess = createAction(
  '[Profil] Create Many Profils Success',
  props<{ profils: ProfilEntityState[] }>()
);

export const deleteOneProfil = createAction('[Profil] Delete One Profil', props<{ idProfil: number }>());

export const deleteOneProfilSuccess = createAction('[Profil] Delete One Profil Success', props<{ idProfil: number }>());

export const normalizeManyProfilsAfterUpsert = createAction(
  '[Profil] Normalize Many Profils After Upsert',
  props<{ profils: ProfilEntityState[] }>()
);

export const profilsFailure = createAction('[Profil] Profils Failure', props<{ error: HttpErrorResponse }>());

export const clearStore = createAction('[Profil] Clear Profils');

export const addManyUserSuccess = createAction(
  '[Profil] Add Many user',
  props<{ idProfil: number; idUsers: number[] }>()
);

export const deleteManyUserSuccess = createAction(
  '[Profil] Delete Many Users',
  props<{ idUsers: number[]; idProfils: number[] }>()
);

export const addManyDroitSuccess = createAction(
  '[Profil] Add Many droit',
  props<{ idProfil: number; idDroits: number[] }>()
);

export const deleteManyDroitSuccess = createAction(
  '[Profil] Delete Many Droits',
  props<{ idDroits: number[]; idProfils: number[] }>()
);

export const addManyOrganizationLeadTodoProfilSuccess = createAction(
  '[Profil] Add Many organization-lead-todo-profil',
  props<{ idProfil: number; idOrganizationLeadTodoProfils: number[] }>()
);

export const deleteManyOrganizationLeadTodoProfilSuccess = createAction(
  '[Profil] Delete Many OrganizationLeadTodoProfils',
  props<{ idOrganizationLeadTodoProfils: number[]; idProfils: number[] }>()
);

export const addManyOrganizationResidenceTodoProfilSuccess = createAction(
  '[Profil] Add Many organization-residence-todo-profil',
  props<{ idProfil: number; idOrganizationResidenceTodoProfils: number[] }>()
);

export const deleteManyOrganizationResidenceTodoProfilSuccess = createAction(
  '[Profil] Delete Many OrganizationResidenceTodoProfils',
  props<{ idOrganizationResidenceTodoProfils: number[]; idProfils: number[] }>()
);

export const addManyOrganizationStratalotTodoProfilSuccess = createAction(
  '[Profil] Add Many organization-stratalot-todo-profil',
  props<{ idProfil: number; idOrganizationStratalotTodoProfils: number[] }>()
);

export const deleteManyOrganizationStratalotTodoProfilSuccess = createAction(
  '[Profil] Delete Many OrganizationStratalotTodoProfils',
  props<{ idOrganizationStratalotTodoProfils: number[]; idProfils: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[Profil] Add Organization',
  props<{ idProfil: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[Profil] Delete Many Organization',
  props<{ idOrganizations: number[]; idProfils: number[] }>()
);
