export enum CategorieCSPEnum {
  aggriculteurs = 'Agriculteurs',
  artisants = 'Artisans, commerçants et chefs d’entreprise',
  cadres = 'Cadres et professions intellectuelles supérieures',
  professionsIntermediaires = 'Professions intermédiaires',
  employes = 'Employés',
  ouvriers = 'Ouvriers',
  retraites = 'Retraités',
  autres = 'Autres personnes sans activité professionnelle'
}
