import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationEnergieActions from './organization-energie.actions';
import { adapter, initialState, OrganizationEnergieState } from './organization-energie.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationEnergieEntityState } from '@_model/interfaces/organization-energie.model';

export const organizationEnergieReducersGeneratedFunctions: ReducerTypes<
  OrganizationEnergieState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationEnergieActions.getOneOrganizationEnergie, (state: OrganizationEnergieState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationEnergieActions.getManyOrganizationEnergies, (state: OrganizationEnergieState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationEnergieActions.upsertOneOrganizationEnergie, (state: OrganizationEnergieState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationEnergieActions.upsertManyOrganizationEnergiesSuccess,
    (state: OrganizationEnergieState, { organizationEnergies }) =>
      adapter.upsertMany(organizationEnergies, setLoadingsState(state, false))
  ),
  on(OrganizationEnergieActions.deleteOneOrganizationEnergie, (state: OrganizationEnergieState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationEnergieActions.deleteOneOrganizationEnergieSuccess,
    (state: OrganizationEnergieState, { idOrganizationEnergie }) =>
      adapter.removeOne(idOrganizationEnergie, setLoadingsState(state, false))
  ),
  on(OrganizationEnergieActions.clearActive, (state: OrganizationEnergieState) => ({ ...state, actives: [] })),
  on(OrganizationEnergieActions.addManyActives, (state: OrganizationEnergieState, { idOrganizationEnergies }) => ({
    ...state,
    actives: state.actives.concat(idOrganizationEnergies)
  })),
  on(OrganizationEnergieActions.deleteOneActive, (state: OrganizationEnergieState, { idOrganizationEnergie }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idOrganizationEnergie)
  })),

  on(OrganizationEnergieActions.clearStore, () => initialState),

  on(
    OrganizationEnergieActions.addOrganizationSuccess,
    (state: OrganizationEnergieState, { idOrganizationEnergie, idOrganization }) => {
      if (!state.entities[idOrganizationEnergie]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationEnergie]: {
            ...state.entities[idOrganizationEnergie],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationEnergieActions.deleteManyOrganizationSuccess,
    (state: OrganizationEnergieState, { idOrganizations, idOrganizationEnergies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationEnergies.reduce((entities, idOrganizationEnergie) => {
            if (!state.entities[idOrganizationEnergie]?.organization) {
              return entities;
            }
            entities[idOrganizationEnergie] = {
              ...state.entities[idOrganizationEnergie],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationEnergie]?.organization
              )
                ? undefined
                : state.entities[idOrganizationEnergie]?.organization
            } as OrganizationEnergieEntityState;
            return entities;
          }, {} as Dictionary<OrganizationEnergieEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationEnergieState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationEnergieState {
  return { ...state, isLoaded, isLoading };
}
