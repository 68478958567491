import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationForecastRate,
  OrganizationForecastRateEntityState
} from '@_model/interfaces/organization-forecast-rate.model';
import { OrganizationForecastRateApiService } from '@_services/api/organization-forecast-rate-api.service';
import * as organizationForecastRateActions from '@_store/organization-forecast-rate/organization-forecast-rate.actions';
import { getActionsToNormalizeOrganizationForecastRate } from '@_config/store/normalization.generated';
import { selectOrganizationForecastRateState } from './organization-forecast-rate-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as residenceActions from '@_store/residence/residence.actions';
import { Residence } from '@_model/interfaces/residence.model';

export interface OrganizationForecastRateRelationsIds {
  residences?: number | number[];
  organization?: number;
}

export function getDefaultAddOrganizationForecastRateActions(
  organizationForecastRate: OrganizationForecastRateEntityState,
  ids?: OrganizationForecastRateRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationForecastRateActions.normalizeManyOrganizationForecastRatesAfterUpsert({
      organizationForecastRates: [organizationForecastRate]
    })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationForecastRateSuccess({
        idOrganization: ids.organization,
        idOrganizationForecastRates: [organizationForecastRate.idOrganizationForecastRate]
      })
    );
    actions.push(
      organizationForecastRateActions.addOrganizationSuccess({
        idOrganizationForecastRate: organizationForecastRate.idOrganizationForecastRate,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.residences) {
    if (!Array.isArray(ids.residences)) {
      actions.push(
        residenceActions.upsertOneResidence({
          residence: {
            idOrganizationForecastRate: organizationForecastRate.idOrganizationForecastRate,
            idResidence: ids.residences as number
          } as Residence
        })
      );
      actions.push(
        organizationForecastRateActions.addManyResidenceSuccess({
          idOrganizationForecastRate: organizationForecastRate.idOrganizationForecastRate,
          idResidences: [ids.residences as number]
        })
      );
    } else {
      actions.push(
        residenceActions.upsertManyResidences({
          residences: (ids.residences as number[]).map((idResidence: number) => ({
            idOrganizationForecastRate: organizationForecastRate.idOrganizationForecastRate,
            idResidence
          })) as Residence[]
        })
      );
      actions.push(
        organizationForecastRateActions.addManyResidenceSuccess({
          idOrganizationForecastRate: organizationForecastRate.idOrganizationForecastRate,
          idResidences: ids.residences as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationForecastRateActions(
  organizationForecastRate: OrganizationForecastRateEntityState
): Action[] {
  const actions: Action[] = [
    organizationForecastRateActions.deleteOneOrganizationForecastRateSuccess({
      idOrganizationForecastRate: organizationForecastRate.idOrganizationForecastRate
    })
  ];

  if (organizationForecastRate.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationForecastRateSuccess({
        idOrganizationForecastRates: [organizationForecastRate.idOrganizationForecastRate],
        idOrganizations: [organizationForecastRate.organization as number]
      })
    );
  }

  if (organizationForecastRate.residences) {
    actions.push(
      residenceActions.deleteManyOrganizationForecastRateSuccess({
        idOrganizationForecastRates: [organizationForecastRate.idOrganizationForecastRate],
        idResidences: organizationForecastRate.residences as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationForecastRateEffects {
  constructor(
    protected actions$: Actions,
    protected organizationForecastRateApiService: OrganizationForecastRateApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationForecastRates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationForecastRateActions.getManyOrganizationForecastRates),
      switchMap(({ params }) =>
        this.organizationForecastRateApiService.getOrganizationForecastRates(params).pipe(
          map((organizationForecastRates: OrganizationForecastRate[]) => {
            return organizationForecastRateActions.normalizeManyOrganizationForecastRatesAfterUpsert({
              organizationForecastRates
            });
          }),
          catchError(error => of(organizationForecastRateActions.organizationForecastRatesFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationForecastRate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationForecastRateActions.getOneOrganizationForecastRate),
      switchMap(idOrganizationForecastRate =>
        this.organizationForecastRateApiService.getOrganizationForecastRate(idOrganizationForecastRate).pipe(
          map((organizationForecastRate: OrganizationForecastRate) => {
            return organizationForecastRateActions.normalizeManyOrganizationForecastRatesAfterUpsert({
              organizationForecastRates: [organizationForecastRate]
            });
          }),
          catchError(error => of(organizationForecastRateActions.organizationForecastRatesFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationForecastRate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationForecastRateActions.upsertOneOrganizationForecastRate),
      concatMap(
        ({
          organizationForecastRate,
          ids
        }: {
          organizationForecastRate: Partial<OrganizationForecastRate>;
          ids?: OrganizationForecastRateRelationsIds;
        }) => {
          if (organizationForecastRate.idOrganizationForecastRate) {
            return this.organizationForecastRateApiService
              .updateOrganizationForecastRate(organizationForecastRate)
              .pipe(
                map((organizationForecastRateReturned: OrganizationForecastRate) => {
                  return organizationForecastRateActions.normalizeManyOrganizationForecastRatesAfterUpsert({
                    organizationForecastRates: [organizationForecastRateReturned]
                  });
                }),
                catchError(error => of(organizationForecastRateActions.organizationForecastRatesFailure({ error })))
              );
          } else {
            return this.organizationForecastRateApiService.addOrganizationForecastRate(organizationForecastRate).pipe(
              mergeMap((organizationForecastRateReturned: OrganizationForecastRate) =>
                getDefaultAddOrganizationForecastRateActions(organizationForecastRateReturned, ids)
              ),
              catchError(error => of(organizationForecastRateActions.organizationForecastRatesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationForecastRate$ = createEffect(() => {
    const selectOrganizationForecastRateState$ = this.store$.select(selectOrganizationForecastRateState);
    return this.actions$.pipe(
      ofType(organizationForecastRateActions.deleteOneOrganizationForecastRate),
      withLatestFrom(selectOrganizationForecastRateState$),
      concatMap(([{ idOrganizationForecastRate }, state]) =>
        this.organizationForecastRateApiService.deleteOrganizationForecastRate(idOrganizationForecastRate).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOrganizationForecastRateActions(
                state.entities[idOrganizationForecastRate] as OrganizationForecastRateEntityState
              ),
              organizationForecastRateActions.deleteOneOrganizationForecastRate.type
            )
          ]),
          catchError(error => of(organizationForecastRateActions.organizationForecastRatesFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationForecastRatesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationForecastRateActions.normalizeManyOrganizationForecastRatesAfterUpsert),
      concatMap(({ organizationForecastRates }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationForecastRate(
          organizationForecastRates,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationForecastRate] Normalization After Upsert Success')];
      })
    );
  });
}
