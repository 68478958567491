import { createAction, props } from '@ngrx/store';
import { ResidenceStudyTodo } from '@_shared/models/interfaces/residence-study-todo.model';

export * from './residence-study-todo-generated.actions';

export const upsertManyResidenceStudyTodosForFillHandle = createAction(
  '[ResidenceStudyTodo] Upsert Many ResidenceStudyTodos For Fill Handle',
  props<{
    residenceStudyTodos: Partial<ResidenceStudyTodo[]>;
  }>()
);
