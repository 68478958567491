import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StratalotPriceListEntityState } from '@_model/interfaces/stratalot-price-list.model';

export interface StratalotPriceListState extends EntityState<StratalotPriceListEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<StratalotPriceListEntityState> = createEntityAdapter<StratalotPriceListEntityState>(
  {
    selectId: o => o.idStratalotPriceList
  }
);
export const initialState: StratalotPriceListState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const stratalotPriceListFeatureKey = 'stratalotPriceList';
