import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResidenceStatutEnum } from '@_shared/models/enums/residence-statut.enum';
import { Residence, ResidenceWithSoldStratalots } from '@_shared/models/interfaces/residence.model';
import * as AppState from '@_store/index.reducers';
import * as ResidenceAction from '@_store/residence/residence.actions';
import { catchApiActions } from '@_utils/http.util';
import { SelectSchema } from '@_utils/selector.util';
import { combineLatest, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { CompanyService } from './company.service';
import { GeneratedResidenceService } from './residence-generated.service';
import { SaleCategoryFamilyService } from './sale-category-family.service';

@Injectable({
  providedIn: 'root'
})
export class ResidenceService extends GeneratedResidenceService {
  constructor(
    store$: Store<AppState.AppState>,
    actions$: Actions,
    private companyService: CompanyService,
    private saleCategoryFamilyService: SaleCategoryFamilyService
  ) {
    super(store$, actions$);
  }

  public getResidencesByCompanies(params: any = {}, getResult?: boolean): void | Observable<Residence[]> {
    this.store$.dispatch(ResidenceAction.getResidencesByCompanies({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ResidenceAction.normalizeManyResidencesAfterUpsert,
        ResidenceAction.residencesFailure
      );
    }
  }

  public getResidencesByActivesCompanies(params: any = {}, getResult?: boolean): void | Observable<Residence[]> {
    this.store$.dispatch(ResidenceAction.getResidencesByCompanies({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ResidenceAction.normalizeManyResidencesAfterUpsert,
        ResidenceAction.residencesFailure
      );
    }
  }

  public getTinyResidenceByCompanies(params: any = {}, getResult?: boolean): void | Observable<Residence[]> {
    this.store$.dispatch(ResidenceAction.getTinyResidenceByCompanies({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ResidenceAction.normalizeManyResidencesAfterUpsert,
        ResidenceAction.residencesFailure
      );
    }
  }

  public getForecastByResidence(
    idResidence: number,
    getResult?: boolean
  ): void | Observable<ResidenceWithSoldStratalots[]> {
    this.store$.dispatch(ResidenceAction.getForecastByResidence({ idResidence }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ResidenceAction.normalizeManyResidencesAfterUpsert,
        ResidenceAction.residencesFailure
      );
    }
  }

  public getDashboardResidenceForecast(
    params: any,
    getResult?: boolean
  ): void | Observable<ResidenceWithSoldStratalots[]> {
    this.store$.dispatch(ResidenceAction.getDashboardResidenceForecast(params));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ResidenceAction.normalizeManyResidencesAfterUpsert,
        ResidenceAction.residencesFailure
      );
    }
  }

  public upsertOneResidenceForecast(residence: Partial<Residence>, getResult?: boolean): void | Observable<Residence> {
    this.store$.dispatch(ResidenceAction.upsertOneResidenceForecast({ residence }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ResidenceAction.normalizeManyResidencesAfterUpsert,
        ResidenceAction.residencesFailure,
        true
      );
    }
  }

  public deleteManyResidencesByCompany(idCompany: number, getResult?: boolean): void | Observable<Residence[]> {
    this.store$.dispatch(ResidenceAction.deleteManyResidencesByCompany({ idCompany }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ResidenceAction.deleteManyResidencesSuccess,
        ResidenceAction.residencesFailure
      );
    }
  }

  public getResidencesForSales(): void {
    this.companyService
      .selectAllActiveCompaniesIds()
      .pipe(
        take(1),
        tap(idsCompanies =>
          this.store$.dispatch(
            ResidenceAction.getResidencesForSales({
              params: { idsCompanies, statuts: [ResidenceStatutEnum.enVente] }
            })
          )
        )
      )
      .subscribe();
  }

  public getResidencesOccupants(params: any = {}): void | Observable<Residence[]> {
    this.companyService
      .selectAllActiveCompaniesIds()
      .pipe(
        take(1),
        map(idsCompanies => {
          this.store$.dispatch(
            ResidenceAction.getResidencesOccupants({
              params: { ...params, idsCompanies }
            })
          );
        })
      )
      .subscribe();
  }

  public getResidencesMontage(params: any = {}): void | Observable<Residence[]> {
    this.companyService
      .selectAllActiveCompaniesIds()
      .pipe(
        take(1),
        map(idsCompanies => {
          this.store$.dispatch(ResidenceAction.getResidencesMontage({ params: { ...params, idsCompanies } }));
        })
      )
      .subscribe();
  }

  public selectAllResidencesBySaleCategoryAndActiveCompany(schema: SelectSchema = {}): Observable<Residence[]> {
    return combineLatest([
      this.selectAllResidences(schema),
      this.companyService.selectAllActiveCompaniesIds(),
      this.saleCategoryFamilyService.selectIdFamilySaleCategoriesActive()
    ]).pipe(
      map(([residences, activeCompaniesIds, activeSaleCategoriesIds]: [Residence[], number[], number[]]) => {
        return residences.filter(r => {
          return (
            activeCompaniesIds.some(id => id === r.idCompany) &&
            activeSaleCategoriesIds.some(id =>
              r.familySaleCategories.some(saleCategoryFamily => saleCategoryFamily.idSaleCategoryFamily === id)
            )
          );
        });
      })
    );
  }

  public activateResidenceFromCampaign(
    idCompany: number,
    envoiUbiflowDateDebut: Date,
    envoiUbiflowDateFin: Date,
    // envoiUbiflowIdPriceLabel: number,
    idResidence: number,
    getResult?: boolean
  ): void | Observable<Residence> {
    this.store$.dispatch(
      ResidenceAction.activateResidenceFromCampaign({
        idCompany,
        envoiUbiflowDateDebut,
        envoiUbiflowDateFin,
        // envoiUbiflowIdPriceLabel,
        idResidence
      })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ResidenceAction.normalizeManyResidencesAfterUpsert,
        ResidenceAction.residencesFailure,
        true
      );
    }
  }
}
