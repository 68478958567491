import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResidenceForecastValueApiService } from '@_services/api/residence-forecast-value-api.service';
import { ResidenceForecastValue } from '@_shared/models/interfaces/residence-forecast-value.model';
import { AppState } from '@_store/index.reducers';
import * as residenceForecastValueActions from '@_store/residence-forecast-value/residence-forecast-value.actions';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { GeneratedResidenceForecastValueEffects } from './residence-forecast-value-generated.effects';

@Injectable()
export class ResidenceForecastValueEffects extends GeneratedResidenceForecastValueEffects {
  constructor(
    actions$: Actions,
    residenceForecastValueApiService: ResidenceForecastValueApiService,
    store$: Store<AppState>
  ) {
    super(actions$, residenceForecastValueApiService, store$);
  }

  upsertManyResidenceForecastValues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceForecastValueActions.upsertManyResidenceForecastValues),
      concatMap(({ residenceForecastValues }: { residenceForecastValues: Partial<ResidenceForecastValue>[] }) => {
        return this.residenceForecastValueApiService.updateResidenceForecastValues(residenceForecastValues).pipe(
          map((residenceForecastValuesReturned: ResidenceForecastValue[]) => {
            return residenceForecastValueActions.normalizeManyResidenceForecastValuesAfterUpsert({
              residenceForecastValues: residenceForecastValuesReturned
            });
          }),
          catchError(error => of(residenceForecastValueActions.residenceForecastValuesFailure({ error })))
        );
      })
    );
  });
}
