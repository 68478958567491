import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  GeneratedDocumentsCompany,
  GeneratedDocumentsCompanyEntityState
} from '@_model/interfaces/generated-documents-company.model';
import { GeneratedDocumentsCompanyRelationsIds } from './generated-documents-company-generated.effects';

export const getOneGeneratedDocumentsCompany = createAction(
  '[GeneratedDocumentsCompany] Get One GeneratedDocumentsCompany',
  props<{ idGeneratedDocumentsCompany: number; params?: any }>()
);

export const getManyGeneratedDocumentsCompanies = createAction(
  '[GeneratedDocumentsCompany] Get Many GeneratedDocumentsCompanies',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[GeneratedDocumentsCompany] Add Many Actives GeneratedDocumentsCompany',
  props<{ idGeneratedDocumentsCompanies: number[] }>()
);

export const deleteOneActive = createAction(
  '[GeneratedDocumentsCompany] Delete One Active GeneratedDocumentsCompany',
  props<{ idGeneratedDocumentsCompany: number }>()
);

export const clearActive = createAction('[GeneratedDocumentsCompany] Clear Active GeneratedDocumentsCompany');

export const upsertOneGeneratedDocumentsCompany = createAction(
  '[GeneratedDocumentsCompany] Upsert One GeneratedDocumentsCompany',
  props<{
    generatedDocumentsCompany: Partial<GeneratedDocumentsCompany>;
    ids?: GeneratedDocumentsCompanyRelationsIds;
  }>()
);

export const upsertManyGeneratedDocumentsCompanies = createAction(
  '[GeneratedDocumentsCompany] Upsert Many GeneratedDocumentsCompanies',
  props<{
    generatedDocumentsCompanies: Partial<GeneratedDocumentsCompany>[];
    ids?: GeneratedDocumentsCompanyRelationsIds;
  }>()
);

export const upsertManyGeneratedDocumentsCompaniesSuccess = createAction(
  '[GeneratedDocumentsCompany] Create Many GeneratedDocumentsCompanies Success',
  props<{ generatedDocumentsCompanies: GeneratedDocumentsCompanyEntityState[] }>()
);

export const deleteOneGeneratedDocumentsCompany = createAction(
  '[GeneratedDocumentsCompany] Delete One GeneratedDocumentsCompany',
  props<{ idGeneratedDocumentsCompany: number }>()
);

export const deleteOneGeneratedDocumentsCompanySuccess = createAction(
  '[GeneratedDocumentsCompany] Delete One GeneratedDocumentsCompany Success',
  props<{ idGeneratedDocumentsCompany: number }>()
);

export const normalizeManyGeneratedDocumentsCompaniesAfterUpsert = createAction(
  '[GeneratedDocumentsCompany] Normalize Many GeneratedDocumentsCompanies After Upsert',
  props<{ generatedDocumentsCompanies: GeneratedDocumentsCompanyEntityState[] }>()
);

export const generatedDocumentsCompaniesFailure = createAction(
  '[GeneratedDocumentsCompany] GeneratedDocumentsCompanies Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[GeneratedDocumentsCompany] Clear GeneratedDocumentsCompanies');

export const addCompanySuccess = createAction(
  '[GeneratedDocumentsCompany] Add Company',
  props<{ idGeneratedDocumentsCompany: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[GeneratedDocumentsCompany] Delete Many Company',
  props<{ idCompanies: number[]; idGeneratedDocumentsCompanies: number[] }>()
);

export const addGeneratedDocumentSuccess = createAction(
  '[GeneratedDocumentsCompany] Add GeneratedDocument',
  props<{ idGeneratedDocumentsCompany: number; idGeneratedDocument: number }>()
);

export const deleteManyGeneratedDocumentSuccess = createAction(
  '[GeneratedDocumentsCompany] Delete Many GeneratedDocument',
  props<{ idGeneratedDocuments: number[]; idGeneratedDocumentsCompanies: number[] }>()
);
