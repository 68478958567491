import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { CampaignMedia, CampaignMediaEntityState } from '@_model/interfaces/campaign-media.model';
import { CampaignMediaApiService } from '@_services/api/campaign-media-api.service';
import * as campaignMediaActions from '@_store/campaign-media/campaign-media.actions';
import { getActionsToNormalizeCampaignMedia } from '@_config/store/normalization.generated';
import { selectCampaignMediaState } from './campaign-media-generated.selectors';
import * as companyMediaActions from '@_store/company-media/company-media.actions';
import * as stratalotCampaignActions from '@_store/stratalot-campaign/stratalot-campaign.actions';

export interface CampaignMediaRelationsIds {
  companyMedia?: number;
  stratalotCampaign?: number;
}

export function getDefaultAddCampaignMediaActions(
  campaignMedia: CampaignMediaEntityState,
  ids?: CampaignMediaRelationsIds
): Action[] {
  const actions: Action[] = [
    campaignMediaActions.normalizeManyCampaignMediasAfterUpsert({ campaignMedias: [campaignMedia] })
  ];

  if (ids?.companyMedia) {
    actions.push(
      companyMediaActions.addManyCampaignMediaSuccess({
        idCompanyMedia: ids.companyMedia,
        idCampaignMedias: [campaignMedia.idCampaignMedia]
      })
    );
    actions.push(
      campaignMediaActions.addCompanyMediaSuccess({
        idCampaignMedia: campaignMedia.idCampaignMedia,
        idCompanyMedia: ids.companyMedia
      })
    );
  }

  if (ids?.stratalotCampaign) {
    actions.push(
      stratalotCampaignActions.addManyCampaignMediaSuccess({
        idStratalotCampaign: ids.stratalotCampaign,
        idCampaignMedias: [campaignMedia.idCampaignMedia]
      })
    );
    actions.push(
      campaignMediaActions.addStratalotCampaignSuccess({
        idCampaignMedia: campaignMedia.idCampaignMedia,
        idStratalotCampaign: ids.stratalotCampaign
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCampaignMediaActions(campaignMedia: CampaignMediaEntityState): Action[] {
  const actions: Action[] = [
    campaignMediaActions.deleteOneCampaignMediaSuccess({ idCampaignMedia: campaignMedia.idCampaignMedia })
  ];

  if (campaignMedia.companyMedia) {
    actions.push(
      companyMediaActions.deleteManyCampaignMediaSuccess({
        idCampaignMedias: [campaignMedia.idCampaignMedia],
        idCompanyMedias: [campaignMedia.companyMedia as number]
      })
    );
  }

  if (campaignMedia.stratalotCampaign) {
    actions.push(
      stratalotCampaignActions.deleteManyCampaignMediaSuccess({
        idCampaignMedias: [campaignMedia.idCampaignMedia],
        idStratalotCampaigns: [campaignMedia.stratalotCampaign as number]
      })
    );
  }

  return actions;
}

export class GeneratedCampaignMediaEffects {
  constructor(
    protected actions$: Actions,
    protected campaignMediaApiService: CampaignMediaApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCampaignMedias$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(campaignMediaActions.getManyCampaignMedias),
      switchMap(({ params }) =>
        this.campaignMediaApiService.getCampaignMedias(params).pipe(
          map((campaignMedias: CampaignMedia[]) => {
            return campaignMediaActions.normalizeManyCampaignMediasAfterUpsert({ campaignMedias });
          }),
          catchError(error => of(campaignMediaActions.campaignMediasFailure({ error })))
        )
      )
    );
  });

  getOneCampaignMedia$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(campaignMediaActions.getOneCampaignMedia),
      switchMap(idCampaignMedia =>
        this.campaignMediaApiService.getCampaignMedia(idCampaignMedia).pipe(
          map((campaignMedia: CampaignMedia) => {
            return campaignMediaActions.normalizeManyCampaignMediasAfterUpsert({ campaignMedias: [campaignMedia] });
          }),
          catchError(error => of(campaignMediaActions.campaignMediasFailure({ error })))
        )
      )
    );
  });

  upsertOneCampaignMedia$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(campaignMediaActions.upsertOneCampaignMedia),
      concatMap(
        ({ campaignMedia, ids }: { campaignMedia: Partial<CampaignMedia>; ids?: CampaignMediaRelationsIds }) => {
          if (campaignMedia.idCampaignMedia) {
            return this.campaignMediaApiService.updateCampaignMedia(campaignMedia).pipe(
              map((campaignMediaReturned: CampaignMedia) => {
                return campaignMediaActions.normalizeManyCampaignMediasAfterUpsert({
                  campaignMedias: [campaignMediaReturned]
                });
              }),
              catchError(error => of(campaignMediaActions.campaignMediasFailure({ error })))
            );
          } else {
            return this.campaignMediaApiService.addCampaignMedia(campaignMedia).pipe(
              mergeMap((campaignMediaReturned: CampaignMedia) =>
                getDefaultAddCampaignMediaActions(campaignMediaReturned, ids)
              ),
              catchError(error => of(campaignMediaActions.campaignMediasFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCampaignMedia$ = createEffect(() => {
    const selectCampaignMediaState$ = this.store$.select(selectCampaignMediaState);
    return this.actions$.pipe(
      ofType(campaignMediaActions.deleteOneCampaignMedia),
      withLatestFrom(selectCampaignMediaState$),
      concatMap(([{ idCampaignMedia }, state]) =>
        this.campaignMediaApiService.deleteCampaignMedia(idCampaignMedia).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCampaignMediaActions(state.entities[idCampaignMedia] as CampaignMediaEntityState),
              campaignMediaActions.deleteOneCampaignMedia.type
            )
          ]),
          catchError(error => of(campaignMediaActions.campaignMediasFailure({ error })))
        )
      )
    );
  });

  normalizeManyCampaignMediasAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(campaignMediaActions.normalizeManyCampaignMediasAfterUpsert),
      concatMap(({ campaignMedias }) => {
        const actions: Action[] = getActionsToNormalizeCampaignMedia(campaignMedias, StoreActionType.upsert);
        return [getMultiAction(actions, '[CampaignMedia] Normalization After Upsert Success')];
      })
    );
  });
}
