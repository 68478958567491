import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconRegistrationService {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  public init(): void {
    this.iconRegistry.addSvgIcon(
      'lot-occupe',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/AppliVenteIcon_HouseKey.svg')
    );
    this.iconRegistry.addSvgIcon(
      'planDeVente',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/planDeVente.svg')
    );
    this.iconRegistry.addSvgIcon(
      'help',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/ic_fluent_question_circle_24_regular.svg')
    );
    this.iconRegistry.addSvgIcon(
      'prospect',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/AppliVenteIcon_Occupant.svg')
    );
    this.iconRegistry.addSvgIcon(
      'prospect-black',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/AppliVenteIcon_Occupant_black.svg')
    );
    this.iconRegistry.addSvgIcon(
      'dossierEnCours',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/dossierEnCours.svg')
    );
    this.iconRegistry.addSvgIcon(
      'commercialisation',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/commercialisation.svg')
    );
    this.iconRegistry.addSvgIcon(
      'offresAValider',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/offresAValider.svg')
    );
    this.iconRegistry.addSvgIcon(
      'dashboard',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/dashboard.svg')
    );
    this.iconRegistry.addSvgIcon(
      'settings',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/settings.svg')
    );
    this.iconRegistry.addSvgIcon(
      'profil',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/profil.svg')
    );
    this.iconRegistry.addSvgIcon(
      'montage',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/ic_fluent_tasks_app_24_regular.svg')
    );
    this.iconRegistry.addSvgIcon(
      'etude',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/svg/ic_fluent_compass_northwest_24_regular.svg')
    );
  }
}
