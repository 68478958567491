import { RepositoryService } from '@_services/api/repository.service';
import { CompanyMedia } from '@_model/interfaces/company-media.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyMediaApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyMedias(params?: any): Observable<CompanyMedia[]> {
    return this.repo.getData<CompanyMedia[]>('company-media', params);
  }

  public getCompanyMedia(params: { idCompanyMedia: number; params?: any }): Observable<CompanyMedia> {
    return this.repo.getData<CompanyMedia>('company-media/' + params.idCompanyMedia, params.params);
  }

  public addCompanyMedia(companyMedia: Partial<CompanyMedia>): Observable<CompanyMedia> {
    return this.repo.create<CompanyMedia>('company-media', companyMedia);
  }

  public updateCompanyMedia(companyMedia: Partial<CompanyMedia>): Observable<CompanyMedia> {
    return this.repo.update('company-media', companyMedia);
  }

  public deleteCompanyMedia(idCompanyMedia: number): Observable<number> {
    return this.repo.delete('company-media/' + +idCompanyMedia);
  }
}
