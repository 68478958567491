import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { MetierFamilie, MetierFamilieEntityState } from '@_model/interfaces/metier-familie.model';
import { MetierFamilieRelationsIds } from './metier-familie-generated.effects';

export const getOneMetierFamilie = createAction(
  '[MetierFamilie] Get One MetierFamilie',
  props<{ idMetierFamilie: number; params?: any }>()
);

export const getManyMetierFamilies = createAction('[MetierFamilie] Get Many MetierFamilies', props<{ params: any }>());

export const addManyActives = createAction(
  '[MetierFamilie] Add Many Actives MetierFamilie',
  props<{ idMetierFamilies: number[] }>()
);

export const deleteOneActive = createAction(
  '[MetierFamilie] Delete One Active MetierFamilie',
  props<{ idMetierFamilie: number }>()
);

export const clearActive = createAction('[MetierFamilie] Clear Active MetierFamilie');

export const upsertOneMetierFamilie = createAction(
  '[MetierFamilie] Upsert One MetierFamilie',
  props<{
    metierFamilie: Partial<MetierFamilie>;
    ids?: MetierFamilieRelationsIds;
  }>()
);

export const upsertManyMetierFamilies = createAction(
  '[MetierFamilie] Upsert Many MetierFamilies',
  props<{
    metierFamilies: Partial<MetierFamilie>[];
    ids?: MetierFamilieRelationsIds;
  }>()
);

export const upsertManyMetierFamiliesSuccess = createAction(
  '[MetierFamilie] Create Many MetierFamilies Success',
  props<{ metierFamilies: MetierFamilieEntityState[] }>()
);

export const deleteOneMetierFamilie = createAction(
  '[MetierFamilie] Delete One MetierFamilie',
  props<{ idMetierFamilie: number }>()
);

export const deleteOneMetierFamilieSuccess = createAction(
  '[MetierFamilie] Delete One MetierFamilie Success',
  props<{ idMetierFamilie: number }>()
);

export const normalizeManyMetierFamiliesAfterUpsert = createAction(
  '[MetierFamilie] Normalize Many MetierFamilies After Upsert',
  props<{ metierFamilies: MetierFamilieEntityState[] }>()
);

export const metierFamiliesFailure = createAction(
  '[MetierFamilie] MetierFamilies Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[MetierFamilie] Clear MetierFamilies');

export const addManyOrganizationMetierSuccess = createAction(
  '[MetierFamilie] Add Many organization-metier',
  props<{ idMetierFamilie: number; idOrganizationMetiers: number[] }>()
);

export const deleteManyOrganizationMetierSuccess = createAction(
  '[MetierFamilie] Delete Many OrganizationMetiers',
  props<{ idOrganizationMetiers: number[]; idMetierFamilies: number[] }>()
);
