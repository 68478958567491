import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ResidenceStudy, ResidenceStudyEntityState } from '@_model/interfaces/residence-study.model';
import { ResidenceStudyRelationsIds } from './residence-study-generated.effects';

export const getOneResidenceStudy = createAction(
  '[ResidenceStudy] Get One ResidenceStudy',
  props<{ idResidenceStudy: number; params?: any }>()
);

export const getManyResidenceStudies = createAction(
  '[ResidenceStudy] Get Many ResidenceStudies',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ResidenceStudy] Add Many Actives ResidenceStudy',
  props<{ idResidenceStudies: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidenceStudy] Delete One Active ResidenceStudy',
  props<{ idResidenceStudy: number }>()
);

export const clearActive = createAction('[ResidenceStudy] Clear Active ResidenceStudy');

export const upsertOneResidenceStudy = createAction(
  '[ResidenceStudy] Upsert One ResidenceStudy',
  props<{
    residenceStudy: Partial<ResidenceStudy>;
    ids?: ResidenceStudyRelationsIds;
  }>()
);

export const upsertManyResidenceStudies = createAction(
  '[ResidenceStudy] Upsert Many ResidenceStudies',
  props<{
    residenceStudies: Partial<ResidenceStudy>[];
    ids?: ResidenceStudyRelationsIds;
  }>()
);

export const upsertManyResidenceStudiesSuccess = createAction(
  '[ResidenceStudy] Create Many ResidenceStudies Success',
  props<{ residenceStudies: ResidenceStudyEntityState[] }>()
);

export const deleteOneResidenceStudy = createAction(
  '[ResidenceStudy] Delete One ResidenceStudy',
  props<{ idResidenceStudy: number }>()
);

export const deleteOneResidenceStudySuccess = createAction(
  '[ResidenceStudy] Delete One ResidenceStudy Success',
  props<{ idResidenceStudy: number }>()
);

export const normalizeManyResidenceStudiesAfterUpsert = createAction(
  '[ResidenceStudy] Normalize Many ResidenceStudies After Upsert',
  props<{ residenceStudies: ResidenceStudyEntityState[] }>()
);

export const residenceStudiesFailure = createAction(
  '[ResidenceStudy] ResidenceStudies Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidenceStudy] Clear ResidenceStudies');

export const addManyResidenceStudyReasonSuccess = createAction(
  '[ResidenceStudy] Add Many residence-study-reason',
  props<{ idResidenceStudy: number; idResidenceStudyReasons: number[] }>()
);

export const deleteManyResidenceStudyReasonSuccess = createAction(
  '[ResidenceStudy] Delete Many ResidenceStudyReasons',
  props<{ idResidenceStudyReasons: number[]; idResidenceStudies: number[] }>()
);

export const addManyResidenceStudyCriteriaSuccess = createAction(
  '[ResidenceStudy] Add Many residence-study-criteria',
  props<{ idResidenceStudy: number; idResidenceStudyCriterias: number[] }>()
);

export const deleteManyResidenceStudyCriteriaSuccess = createAction(
  '[ResidenceStudy] Delete Many ResidenceStudyCriterias',
  props<{ idResidenceStudyCriterias: number[]; idResidenceStudies: number[] }>()
);

export const addManyResidenceStudyWorkSuccess = createAction(
  '[ResidenceStudy] Add Many residence-study-work',
  props<{ idResidenceStudy: number; idResidenceStudyWorks: number[] }>()
);

export const deleteManyResidenceStudyWorkSuccess = createAction(
  '[ResidenceStudy] Delete Many ResidenceStudyWorks',
  props<{ idResidenceStudyWorks: number[]; idResidenceStudies: number[] }>()
);

export const addManyResidenceStudyTodoSuccess = createAction(
  '[ResidenceStudy] Add Many residence-study-todo',
  props<{ idResidenceStudy: number; idResidenceStudyTodos: number[] }>()
);

export const deleteManyResidenceStudyTodoSuccess = createAction(
  '[ResidenceStudy] Delete Many ResidenceStudyTodos',
  props<{ idResidenceStudyTodos: number[]; idResidenceStudies: number[] }>()
);

export const addManyResidenceStudyLotSuccess = createAction(
  '[ResidenceStudy] Add Many residence-study-lot',
  props<{ idResidenceStudy: number; idResidenceStudyLots: number[] }>()
);

export const deleteManyResidenceStudyLotSuccess = createAction(
  '[ResidenceStudy] Delete Many ResidenceStudyLots',
  props<{ idResidenceStudyLots: number[]; idResidenceStudies: number[] }>()
);

export const addManyResidenceStudyFoundingSuccess = createAction(
  '[ResidenceStudy] Add Many residence-study-founding',
  props<{ idResidenceStudy: number; idResidenceStudyFoundings: number[] }>()
);

export const deleteManyResidenceStudyFoundingSuccess = createAction(
  '[ResidenceStudy] Delete Many ResidenceStudyFoundings',
  props<{ idResidenceStudyFoundings: number[]; idResidenceStudies: number[] }>()
);

export const addManyCompanyStudyReasonSuccess = createAction(
  '[ResidenceStudy] Add Many company-study-reason',
  props<{ idResidenceStudy: number; idCompanyStudyReasons: number[] }>()
);

export const deleteManyCompanyStudyReasonSuccess = createAction(
  '[ResidenceStudy] Delete Many CompanyStudyReasons',
  props<{ idCompanyStudyReasons: number[]; idResidenceStudies: number[] }>()
);

export const addCompanyStudyNextActionSuccess = createAction(
  '[ResidenceStudy] Add CompanyStudyNextAction',
  props<{ idResidenceStudy: number; idCompanyStudyNextAction: number }>()
);

export const deleteManyCompanyStudyNextActionSuccess = createAction(
  '[ResidenceStudy] Delete Many CompanyStudyNextAction',
  props<{ idCompanyStudyNextActions: number[]; idResidenceStudies: number[] }>()
);

export const addCompanyStudyConclusionSuccess = createAction(
  '[ResidenceStudy] Add CompanyStudyConclusion',
  props<{ idResidenceStudy: number; idCompanyStudyConclusion: number }>()
);

export const deleteManyCompanyStudyConclusionSuccess = createAction(
  '[ResidenceStudy] Delete Many CompanyStudyConclusion',
  props<{ idCompanyStudyConclusions: number[]; idResidenceStudies: number[] }>()
);

export const addCompanySuccess = createAction(
  '[ResidenceStudy] Add Company',
  props<{ idResidenceStudy: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[ResidenceStudy] Delete Many Company',
  props<{ idCompanies: number[]; idResidenceStudies: number[] }>()
);
