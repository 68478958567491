import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationResidenceStudyTodoActions from './organization-residence-study-todo.actions';
import { adapter, initialState, OrganizationResidenceStudyTodoState } from './organization-residence-study-todo.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationResidenceStudyTodoEntityState } from '@_model/interfaces/organization-residence-study-todo.model';

export const organizationResidenceStudyTodoReducersGeneratedFunctions: ReducerTypes<
  OrganizationResidenceStudyTodoState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationResidenceStudyTodoActions.getOneOrganizationResidenceStudyTodo,
    (state: OrganizationResidenceStudyTodoState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationResidenceStudyTodoActions.getManyOrganizationResidenceStudyTodos,
    (state: OrganizationResidenceStudyTodoState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationResidenceStudyTodoActions.upsertOneOrganizationResidenceStudyTodo,
    (state: OrganizationResidenceStudyTodoState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationResidenceStudyTodoActions.upsertManyOrganizationResidenceStudyTodosSuccess,
    (state: OrganizationResidenceStudyTodoState, { organizationResidenceStudyTodos }) =>
      adapter.upsertMany(organizationResidenceStudyTodos, setLoadingsState(state, false))
  ),
  on(
    OrganizationResidenceStudyTodoActions.deleteOneOrganizationResidenceStudyTodo,
    (state: OrganizationResidenceStudyTodoState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationResidenceStudyTodoActions.deleteOneOrganizationResidenceStudyTodoSuccess,
    (state: OrganizationResidenceStudyTodoState, { idOrganizationResidenceStudyTodo }) =>
      adapter.removeOne(idOrganizationResidenceStudyTodo, setLoadingsState(state, false))
  ),
  on(OrganizationResidenceStudyTodoActions.clearActive, (state: OrganizationResidenceStudyTodoState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationResidenceStudyTodoActions.addManyActives,
    (state: OrganizationResidenceStudyTodoState, { idOrganizationResidenceStudyTodos }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationResidenceStudyTodos)
    })
  ),
  on(
    OrganizationResidenceStudyTodoActions.deleteOneActive,
    (state: OrganizationResidenceStudyTodoState, { idOrganizationResidenceStudyTodo }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationResidenceStudyTodo)
    })
  ),

  on(OrganizationResidenceStudyTodoActions.clearStore, () => initialState),

  on(
    OrganizationResidenceStudyTodoActions.addManyResidenceStudyTodoSuccess,
    (state: OrganizationResidenceStudyTodoState, { idOrganizationResidenceStudyTodo, idResidenceStudyTodos }) => {
      if (!state.entities[idOrganizationResidenceStudyTodo]) {
        return state;
      }
      const residenceStudyTodos =
        (state.entities[idOrganizationResidenceStudyTodo]?.residenceStudyTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceStudyTodo]: {
            ...state.entities[idOrganizationResidenceStudyTodo],
            residenceStudyTodos: residenceStudyTodos.concat(
              idResidenceStudyTodos.filter(id => residenceStudyTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceStudyTodoActions.deleteManyResidenceStudyTodoSuccess,
    (state: OrganizationResidenceStudyTodoState, { idResidenceStudyTodos, idOrganizationResidenceStudyTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceStudyTodos.reduce((entities, idOrganizationResidenceStudyTodo) => {
            if (!state.entities[idOrganizationResidenceStudyTodo]?.residenceStudyTodos) {
              return entities;
            }
            entities[idOrganizationResidenceStudyTodo] = {
              ...state.entities[idOrganizationResidenceStudyTodo],
              residenceStudyTodos: (
                state.entities[idOrganizationResidenceStudyTodo]?.residenceStudyTodos as number[]
              )?.filter(
                (idResidenceStudyTodo: number) =>
                  !idResidenceStudyTodos.some((id: number) => id === idResidenceStudyTodo)
              )
            } as OrganizationResidenceStudyTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceStudyTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationResidenceStudyTodoActions.addOrganizationSuccess,
    (state: OrganizationResidenceStudyTodoState, { idOrganizationResidenceStudyTodo, idOrganization }) => {
      if (!state.entities[idOrganizationResidenceStudyTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceStudyTodo]: {
            ...state.entities[idOrganizationResidenceStudyTodo],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceStudyTodoActions.deleteManyOrganizationSuccess,
    (state: OrganizationResidenceStudyTodoState, { idOrganizations, idOrganizationResidenceStudyTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceStudyTodos.reduce((entities, idOrganizationResidenceStudyTodo) => {
            if (!state.entities[idOrganizationResidenceStudyTodo]?.organization) {
              return entities;
            }
            entities[idOrganizationResidenceStudyTodo] = {
              ...state.entities[idOrganizationResidenceStudyTodo],
              organization: idOrganizations.some(
                (idOrganization: number) =>
                  idOrganization === state.entities[idOrganizationResidenceStudyTodo]?.organization
              )
                ? undefined
                : state.entities[idOrganizationResidenceStudyTodo]?.organization
            } as OrganizationResidenceStudyTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceStudyTodoEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationResidenceStudyTodoState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationResidenceStudyTodoState {
  return { ...state, isLoaded, isLoading };
}
