import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResidenceForecast, ResidenceForecastEntityState } from '@_model/interfaces/residence-forecast.model';
import {
  ResidenceForecastValue,
  ResidenceForecastValueEntityState
} from '@_model/interfaces/residence-forecast-value.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { ForecastPeriod, ForecastPeriodEntityState } from '@_model/interfaces/forecast-period.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, residenceForecastFeatureKey, ResidenceForecastState } from './residence-forecast.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residenceForecastRelations: string[] = ['residenceForecastValues', 'residences', 'forecastPeriods'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidenceForecastState = createFeatureSelector<ResidenceForecastState>(residenceForecastFeatureKey);

export const selectIsLoadedResidenceForecast = createSelector(
  selectResidenceForecastState,
  (state: ResidenceForecastState) => state.isLoaded
);

export const selectIsLoadingResidenceForecast = createSelector(
  selectResidenceForecastState,
  (state: ResidenceForecastState) => state.isLoading
);

export const selectIsReadyResidenceForecast = createSelector(
  selectResidenceForecastState,
  (state: ResidenceForecastState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidenceForecast = createSelector(
  selectResidenceForecastState,
  (state: ResidenceForecastState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidenceForecastModel: SelectorModel = {
  name: 'residenceForecasts',
  getSelector: selectAllResidenceForecastsDictionary,
  isReady: selectIsReadyResidenceForecast
};

export const selectResidenceForecastsEntities = createSelector(selectResidenceForecastState, selectEntities);

export const selectResidenceForecastsArray = createSelector(selectResidenceForecastState, selectAll);

export const selectIdResidenceForecastsActive = createSelector(
  selectResidenceForecastState,
  (state: ResidenceForecastState) => state.actives
);

const residenceForecastsInObject = (residenceForecasts: Dictionary<ResidenceForecastEntityState>) => ({
  residenceForecasts
});

const selectResidenceForecastsEntitiesDictionary = createSelector(
  selectResidenceForecastsEntities,
  residenceForecastsInObject
);

const selectAllResidenceForecastsObject = createSelector(selectResidenceForecastsEntities, residenceForecasts => {
  return hydrateAll({ residenceForecasts });
});

const selectOneResidenceForecastDictionary = (idResidenceForecast: number) =>
  createSelector(selectResidenceForecastsEntities, residenceForecasts => {
    return { residenceForecasts: { [idResidenceForecast]: residenceForecasts[idResidenceForecast] } };
  });

const selectOneResidenceForecastDictionaryWithoutChild = (idResidenceForecast: number) =>
  createSelector(selectResidenceForecastsEntities, residenceForecasts => {
    return { residenceForecast: residenceForecasts[idResidenceForecast] };
  });

const selectActiveResidenceForecastsEntities = createSelector(
  selectIdResidenceForecastsActive,
  selectResidenceForecastsEntities,
  (actives: number[], residenceForecasts: Dictionary<ResidenceForecastEntityState>) =>
    getResidenceForecastsFromActives(actives, residenceForecasts)
);

function getResidenceForecastsFromActives(
  actives: number[],
  residenceForecasts: Dictionary<ResidenceForecastEntityState>
): Dictionary<ResidenceForecastEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residenceForecasts[idActive]) {
      acc[idActive] = residenceForecasts[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidenceForecastEntityState>);
}

const selectAllResidenceForecastsSelectors: Dictionary<Selector> = {};
export function selectAllResidenceForecasts(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidenceForecast>(
      schema,
      selectAllResidenceForecastsSelectors,
      selectResidenceForecastsEntitiesDictionary,
      getRelationSelectors,
      residenceForecastRelations,
      hydrateAll,
      'residenceForecast'
    );
  } else {
    return selectAllResidenceForecastsObject;
  }
}

export function selectAllResidenceForecastsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residenceForecasts'
): Selector {
  return createSelector(selectAllResidenceForecasts(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidenceForecastEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residenceForecasts.length; i++) {
      res[customKey][result.residenceForecasts[i].idResidenceForecast] = result.residenceForecasts[i];
    }
    return res;
  });
}

export function selectOneResidenceForecast(schema: SelectSchema = {}, idResidenceForecast: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidenceForecastDictionary(idResidenceForecast)];
    selectors.push(...getRelationSelectors(schema, residenceForecastRelations, 'residenceForecast'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidenceForecastDictionaryWithoutChild(idResidenceForecast);
  }
}

export function selectActiveResidenceForecasts(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidenceForecastsEntities, residenceForecasts => ({ residenceForecasts }))
  ];
  selectors.push(...getRelationSelectors(schema, residenceForecastRelations, 'residenceForecast'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residenceForecasts: Dictionary<ResidenceForecastEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
  forecastPeriods?: Dictionary<ForecastPeriodEntityState>;
  residenceForecastValues?: Dictionary<ResidenceForecastValueEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { residenceForecasts: (ResidenceForecast | null)[] } {
  const { residenceForecasts, residences, forecastPeriods, residenceForecastValues } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    residenceForecasts: Object.keys(residenceForecasts).map(idResidenceForecast =>
      hydrate(
        residenceForecasts[idResidenceForecast] as ResidenceForecastEntityState,
        residences,
        forecastPeriods,
        residenceForecastValues
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { residenceForecast: ResidenceForecastEntityState | null } {
  const { residenceForecasts, residences, forecastPeriods, residenceForecastValues } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const residenceForecast = Object.values(residenceForecasts)[0];
  return {
    residenceForecast: hydrate(
      residenceForecast as ResidenceForecastEntityState,
      residences,
      forecastPeriods,
      residenceForecastValues
    )
  };
}

function hydrate(
  residenceForecast: ResidenceForecastEntityState,
  residenceEntities?: Dictionary<ResidenceEntityState>,
  forecastPeriodEntities?: Dictionary<ForecastPeriodEntityState>,
  residenceForecastValueEntities?: Dictionary<ResidenceForecastValueEntityState>
): ResidenceForecast | null {
  if (!residenceForecast) {
    return null;
  }

  const residenceForecastHydrated: ResidenceForecastEntityState = { ...residenceForecast };
  if (residenceEntities) {
    residenceForecastHydrated.residence = residenceEntities[residenceForecast.residence as number] as Residence;
  } else {
    delete residenceForecastHydrated.residence;
  }
  if (forecastPeriodEntities) {
    residenceForecastHydrated.forecastPeriod = forecastPeriodEntities[
      residenceForecast.forecastPeriod as number
    ] as ForecastPeriod;
  } else {
    delete residenceForecastHydrated.forecastPeriod;
  }

  if (residenceForecastValueEntities) {
    residenceForecastHydrated.residenceForecastValues = (
      (residenceForecastHydrated.residenceForecastValues as number[]) || []
    ).map(id => residenceForecastValueEntities[id]) as ResidenceForecastValue[];
  } else {
    delete residenceForecastHydrated.residenceForecastValues;
  }

  return residenceForecastHydrated as ResidenceForecast;
}
