import { StratalotRcpState, initialState } from './stratalot-rcp.state';
import { Action, createReducer } from '@ngrx/store';
import { stratalotRcpReducersGeneratedFunctions } from './stratalot-rcp-generated.reducer';

const stratalotRcpReducersFunctions = [...stratalotRcpReducersGeneratedFunctions];

const stratalotRcpReducer = createReducer(initialState, ...stratalotRcpReducersFunctions);

export function reducer(state: StratalotRcpState | undefined, action: Action) {
  return stratalotRcpReducer(state, action);
}
