import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { StepProgressFamily, StepProgressFamilyEntityState } from '@_model/interfaces/step-progress-family.model';
import { StepProgressFamilyRelationsIds } from './step-progress-family-generated.effects';

export const getOneStepProgressFamily = createAction(
  '[StepProgressFamily] Get One StepProgressFamily',
  props<{ idStepProgressFamily: number; params?: any }>()
);

export const getManyStepProgressesFamilies = createAction(
  '[StepProgressFamily] Get Many StepProgressesFamilies',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[StepProgressFamily] Add Many Actives StepProgressFamily',
  props<{ idStepProgressesFamilies: number[] }>()
);

export const deleteOneActive = createAction(
  '[StepProgressFamily] Delete One Active StepProgressFamily',
  props<{ idStepProgressFamily: number }>()
);

export const clearActive = createAction('[StepProgressFamily] Clear Active StepProgressFamily');

export const upsertOneStepProgressFamily = createAction(
  '[StepProgressFamily] Upsert One StepProgressFamily',
  props<{
    stepProgressFamily: Partial<StepProgressFamily>;
    ids?: StepProgressFamilyRelationsIds;
  }>()
);

export const upsertManyStepProgressesFamilies = createAction(
  '[StepProgressFamily] Upsert Many StepProgressesFamilies',
  props<{
    stepProgressesFamilies: Partial<StepProgressFamily>[];
    ids?: StepProgressFamilyRelationsIds;
  }>()
);

export const upsertManyStepProgressesFamiliesSuccess = createAction(
  '[StepProgressFamily] Create Many StepProgressesFamilies Success',
  props<{ stepProgressesFamilies: StepProgressFamilyEntityState[] }>()
);

export const deleteOneStepProgressFamily = createAction(
  '[StepProgressFamily] Delete One StepProgressFamily',
  props<{ idStepProgressFamily: number }>()
);

export const deleteOneStepProgressFamilySuccess = createAction(
  '[StepProgressFamily] Delete One StepProgressFamily Success',
  props<{ idStepProgressFamily: number }>()
);

export const normalizeManyStepProgressesFamiliesAfterUpsert = createAction(
  '[StepProgressFamily] Normalize Many StepProgressesFamilies After Upsert',
  props<{ stepProgressesFamilies: StepProgressFamilyEntityState[] }>()
);

export const stepProgressesFamiliesFailure = createAction(
  '[StepProgressFamily] StepProgressesFamilies Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[StepProgressFamily] Clear StepProgressesFamilies');

export const addManyStepProgressSuccess = createAction(
  '[StepProgressFamily] Add Many step-progress',
  props<{ idStepProgressFamily: number; idStepProgresses: number[] }>()
);

export const deleteManyStepProgressSuccess = createAction(
  '[StepProgressFamily] Delete Many StepProgresses',
  props<{ idStepProgresses: number[]; idStepProgressesFamilies: number[] }>()
);
