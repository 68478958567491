import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationResidenceTodoProfilActions from './organization-residence-todo-profil.actions';
import {
  adapter,
  initialState,
  OrganizationResidenceTodoProfilState
} from './organization-residence-todo-profil.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationResidenceTodoProfilEntityState } from '@_model/interfaces/organization-residence-todo-profil.model';

export const organizationResidenceTodoProfilReducersGeneratedFunctions: ReducerTypes<
  OrganizationResidenceTodoProfilState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationResidenceTodoProfilActions.getOneOrganizationResidenceTodoProfil,
    (state: OrganizationResidenceTodoProfilState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationResidenceTodoProfilActions.getManyOrganizationResidenceTodoProfils,
    (state: OrganizationResidenceTodoProfilState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationResidenceTodoProfilActions.upsertOneOrganizationResidenceTodoProfil,
    (state: OrganizationResidenceTodoProfilState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationResidenceTodoProfilActions.upsertManyOrganizationResidenceTodoProfilsSuccess,
    (state: OrganizationResidenceTodoProfilState, { organizationResidenceTodoProfils }) =>
      adapter.upsertMany(organizationResidenceTodoProfils, setLoadingsState(state, false))
  ),
  on(
    OrganizationResidenceTodoProfilActions.deleteOneOrganizationResidenceTodoProfil,
    (state: OrganizationResidenceTodoProfilState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationResidenceTodoProfilActions.deleteOneOrganizationResidenceTodoProfilSuccess,
    (state: OrganizationResidenceTodoProfilState, { idOrganizationResidenceTodoProfil }) =>
      adapter.removeOne(idOrganizationResidenceTodoProfil, setLoadingsState(state, false))
  ),
  on(OrganizationResidenceTodoProfilActions.clearActive, (state: OrganizationResidenceTodoProfilState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationResidenceTodoProfilActions.addManyActives,
    (state: OrganizationResidenceTodoProfilState, { idOrganizationResidenceTodoProfils }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationResidenceTodoProfils)
    })
  ),
  on(
    OrganizationResidenceTodoProfilActions.deleteOneActive,
    (state: OrganizationResidenceTodoProfilState, { idOrganizationResidenceTodoProfil }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationResidenceTodoProfil)
    })
  ),

  on(OrganizationResidenceTodoProfilActions.clearStore, () => initialState),

  on(
    OrganizationResidenceTodoProfilActions.addProfilSuccess,
    (state: OrganizationResidenceTodoProfilState, { idOrganizationResidenceTodoProfil, idProfil }) => {
      if (!state.entities[idOrganizationResidenceTodoProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodoProfil]: {
            ...state.entities[idOrganizationResidenceTodoProfil],
            profil: idProfil
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoProfilActions.deleteManyProfilSuccess,
    (state: OrganizationResidenceTodoProfilState, { idProfils, idOrganizationResidenceTodoProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodoProfils.reduce((entities, idOrganizationResidenceTodoProfil) => {
            if (!state.entities[idOrganizationResidenceTodoProfil]?.profil) {
              return entities;
            }
            entities[idOrganizationResidenceTodoProfil] = {
              ...state.entities[idOrganizationResidenceTodoProfil],
              profil: idProfils.some(
                (idProfil: number) => idProfil === state.entities[idOrganizationResidenceTodoProfil]?.profil
              )
                ? undefined
                : state.entities[idOrganizationResidenceTodoProfil]?.profil
            } as OrganizationResidenceTodoProfilEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoProfilEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoProfilActions.addOrganizationResidenceTodoSuccess,
    (
      state: OrganizationResidenceTodoProfilState,
      { idOrganizationResidenceTodoProfil, idOrganizationResidenceTodo }
    ) => {
      if (!state.entities[idOrganizationResidenceTodoProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodoProfil]: {
            ...state.entities[idOrganizationResidenceTodoProfil],
            organizationResidenceTodo: idOrganizationResidenceTodo
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoProfilActions.deleteManyOrganizationResidenceTodoSuccess,
    (
      state: OrganizationResidenceTodoProfilState,
      { idOrganizationResidenceTodos, idOrganizationResidenceTodoProfils }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodoProfils.reduce((entities, idOrganizationResidenceTodoProfil) => {
            if (!state.entities[idOrganizationResidenceTodoProfil]?.organizationResidenceTodo) {
              return entities;
            }
            entities[idOrganizationResidenceTodoProfil] = {
              ...state.entities[idOrganizationResidenceTodoProfil],
              organizationResidenceTodo: idOrganizationResidenceTodos.some(
                (idOrganizationResidenceTodo: number) =>
                  idOrganizationResidenceTodo ===
                  state.entities[idOrganizationResidenceTodoProfil]?.organizationResidenceTodo
              )
                ? undefined
                : state.entities[idOrganizationResidenceTodoProfil]?.organizationResidenceTodo
            } as OrganizationResidenceTodoProfilEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoProfilEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationResidenceTodoProfilState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationResidenceTodoProfilState {
  return { ...state, isLoaded, isLoading };
}
