import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationBuyingWish,
  OrganizationBuyingWishEntityState
} from '@_model/interfaces/organization-buying-wish.model';
import { OrganizationBuyingWishRelationsIds } from './organization-buying-wish-generated.effects';

export const getOneOrganizationBuyingWish = createAction(
  '[OrganizationBuyingWish] Get One OrganizationBuyingWish',
  props<{ idOrganizationBuyingWish: number; params?: any }>()
);

export const getManyOrganizationBuyingWishes = createAction(
  '[OrganizationBuyingWish] Get Many OrganizationBuyingWishes',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationBuyingWish] Add Many Actives OrganizationBuyingWish',
  props<{ idOrganizationBuyingWishes: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationBuyingWish] Delete One Active OrganizationBuyingWish',
  props<{ idOrganizationBuyingWish: number }>()
);

export const clearActive = createAction('[OrganizationBuyingWish] Clear Active OrganizationBuyingWish');

export const upsertOneOrganizationBuyingWish = createAction(
  '[OrganizationBuyingWish] Upsert One OrganizationBuyingWish',
  props<{
    organizationBuyingWish: Partial<OrganizationBuyingWish>;
    ids?: OrganizationBuyingWishRelationsIds;
  }>()
);

export const upsertManyOrganizationBuyingWishes = createAction(
  '[OrganizationBuyingWish] Upsert Many OrganizationBuyingWishes',
  props<{
    organizationBuyingWishes: Partial<OrganizationBuyingWish>[];
    ids?: OrganizationBuyingWishRelationsIds;
  }>()
);

export const upsertManyOrganizationBuyingWishesSuccess = createAction(
  '[OrganizationBuyingWish] Create Many OrganizationBuyingWishes Success',
  props<{ organizationBuyingWishes: OrganizationBuyingWishEntityState[] }>()
);

export const deleteOneOrganizationBuyingWish = createAction(
  '[OrganizationBuyingWish] Delete One OrganizationBuyingWish',
  props<{ idOrganizationBuyingWish: number }>()
);

export const deleteOneOrganizationBuyingWishSuccess = createAction(
  '[OrganizationBuyingWish] Delete One OrganizationBuyingWish Success',
  props<{ idOrganizationBuyingWish: number }>()
);

export const normalizeManyOrganizationBuyingWishesAfterUpsert = createAction(
  '[OrganizationBuyingWish] Normalize Many OrganizationBuyingWishes After Upsert',
  props<{ organizationBuyingWishes: OrganizationBuyingWishEntityState[] }>()
);

export const organizationBuyingWishesFailure = createAction(
  '[OrganizationBuyingWish] OrganizationBuyingWishes Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationBuyingWish] Clear OrganizationBuyingWishes');

export const addManyProspectBuyingWishSuccess = createAction(
  '[OrganizationBuyingWish] Add Many prospect-buying-wish',
  props<{ idOrganizationBuyingWish: number; idProspectBuyingWishs: number[] }>()
);

export const deleteManyProspectBuyingWishSuccess = createAction(
  '[OrganizationBuyingWish] Delete Many ProspectBuyingWishs',
  props<{ idProspectBuyingWishs: number[]; idOrganizationBuyingWishes: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[OrganizationBuyingWish] Add Organization',
  props<{ idOrganizationBuyingWish: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationBuyingWish] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationBuyingWishes: number[] }>()
);
