import { OrganizationBuyingWishState, initialState } from './organization-buying-wish.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationBuyingWishReducersGeneratedFunctions } from './organization-buying-wish-generated.reducer';

const organizationBuyingWishReducersFunctions = [...organizationBuyingWishReducersGeneratedFunctions];

const organizationBuyingWishReducer = createReducer(initialState, ...organizationBuyingWishReducersFunctions);

export function reducer(state: OrganizationBuyingWishState | undefined, action: Action) {
  return organizationBuyingWishReducer(state, action);
}
