import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationLeadTodoProfil,
  OrganizationLeadTodoProfilEntityState
} from '@_model/interfaces/organization-lead-todo-profil.model';
import { Profil, ProfilEntityState } from '@_model/interfaces/profil.model';
import { OrganizationLeadTodo, OrganizationLeadTodoEntityState } from '@_model/interfaces/organization-lead-todo.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationLeadTodoProfilFeatureKey,
  OrganizationLeadTodoProfilState
} from './organization-lead-todo-profil.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationLeadTodoProfilRelations: string[] = ['profils', 'organizationLeadTodos'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationLeadTodoProfilState = createFeatureSelector<OrganizationLeadTodoProfilState>(
  organizationLeadTodoProfilFeatureKey
);

export const selectIsLoadedOrganizationLeadTodoProfil = createSelector(
  selectOrganizationLeadTodoProfilState,
  (state: OrganizationLeadTodoProfilState) => state.isLoaded
);

export const selectIsLoadingOrganizationLeadTodoProfil = createSelector(
  selectOrganizationLeadTodoProfilState,
  (state: OrganizationLeadTodoProfilState) => state.isLoading
);

export const selectIsReadyOrganizationLeadTodoProfil = createSelector(
  selectOrganizationLeadTodoProfilState,
  (state: OrganizationLeadTodoProfilState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationLeadTodoProfil = createSelector(
  selectOrganizationLeadTodoProfilState,
  (state: OrganizationLeadTodoProfilState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationLeadTodoProfilModel: SelectorModel = {
  name: 'organizationLeadTodoProfils',
  getSelector: selectAllOrganizationLeadTodoProfilsDictionary,
  isReady: selectIsReadyOrganizationLeadTodoProfil
};

export const selectOrganizationLeadTodoProfilsEntities = createSelector(
  selectOrganizationLeadTodoProfilState,
  selectEntities
);

export const selectOrganizationLeadTodoProfilsArray = createSelector(selectOrganizationLeadTodoProfilState, selectAll);

export const selectIdOrganizationLeadTodoProfilsActive = createSelector(
  selectOrganizationLeadTodoProfilState,
  (state: OrganizationLeadTodoProfilState) => state.actives
);

const organizationLeadTodoProfilsInObject = (
  organizationLeadTodoProfils: Dictionary<OrganizationLeadTodoProfilEntityState>
) => ({ organizationLeadTodoProfils });

const selectOrganizationLeadTodoProfilsEntitiesDictionary = createSelector(
  selectOrganizationLeadTodoProfilsEntities,
  organizationLeadTodoProfilsInObject
);

const selectAllOrganizationLeadTodoProfilsObject = createSelector(
  selectOrganizationLeadTodoProfilsEntities,
  organizationLeadTodoProfils => {
    return hydrateAll({ organizationLeadTodoProfils });
  }
);

const selectOneOrganizationLeadTodoProfilDictionary = (idOrganizationLeadTodoProfil: number) =>
  createSelector(selectOrganizationLeadTodoProfilsEntities, organizationLeadTodoProfils => {
    return {
      organizationLeadTodoProfils: {
        [idOrganizationLeadTodoProfil]: organizationLeadTodoProfils[idOrganizationLeadTodoProfil]
      }
    };
  });

const selectOneOrganizationLeadTodoProfilDictionaryWithoutChild = (idOrganizationLeadTodoProfil: number) =>
  createSelector(selectOrganizationLeadTodoProfilsEntities, organizationLeadTodoProfils => {
    return { organizationLeadTodoProfil: organizationLeadTodoProfils[idOrganizationLeadTodoProfil] };
  });

const selectActiveOrganizationLeadTodoProfilsEntities = createSelector(
  selectIdOrganizationLeadTodoProfilsActive,
  selectOrganizationLeadTodoProfilsEntities,
  (actives: number[], organizationLeadTodoProfils: Dictionary<OrganizationLeadTodoProfilEntityState>) =>
    getOrganizationLeadTodoProfilsFromActives(actives, organizationLeadTodoProfils)
);

function getOrganizationLeadTodoProfilsFromActives(
  actives: number[],
  organizationLeadTodoProfils: Dictionary<OrganizationLeadTodoProfilEntityState>
): Dictionary<OrganizationLeadTodoProfilEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationLeadTodoProfils[idActive]) {
      acc[idActive] = organizationLeadTodoProfils[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationLeadTodoProfilEntityState>);
}

const selectAllOrganizationLeadTodoProfilsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationLeadTodoProfils(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationLeadTodoProfil>(
      schema,
      selectAllOrganizationLeadTodoProfilsSelectors,
      selectOrganizationLeadTodoProfilsEntitiesDictionary,
      getRelationSelectors,
      organizationLeadTodoProfilRelations,
      hydrateAll,
      'organizationLeadTodoProfil'
    );
  } else {
    return selectAllOrganizationLeadTodoProfilsObject;
  }
}

export function selectAllOrganizationLeadTodoProfilsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationLeadTodoProfils'
): Selector {
  return createSelector(selectAllOrganizationLeadTodoProfils(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationLeadTodoProfilEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationLeadTodoProfils.length; i++) {
      res[customKey][result.organizationLeadTodoProfils[i].idOrganizationLeadTodoProfil] =
        result.organizationLeadTodoProfils[i];
    }
    return res;
  });
}

export function selectOneOrganizationLeadTodoProfil(
  schema: SelectSchema = {},
  idOrganizationLeadTodoProfil: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationLeadTodoProfilDictionary(idOrganizationLeadTodoProfil)];
    selectors.push(...getRelationSelectors(schema, organizationLeadTodoProfilRelations, 'organizationLeadTodoProfil'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationLeadTodoProfilDictionaryWithoutChild(idOrganizationLeadTodoProfil);
  }
}

export function selectActiveOrganizationLeadTodoProfils(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationLeadTodoProfilsEntities, organizationLeadTodoProfils => ({
      organizationLeadTodoProfils
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationLeadTodoProfilRelations, 'organizationLeadTodoProfil'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationLeadTodoProfils: Dictionary<OrganizationLeadTodoProfilEntityState>;
  profils?: Dictionary<ProfilEntityState>;
  organizationLeadTodos?: Dictionary<OrganizationLeadTodoEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationLeadTodoProfils: (OrganizationLeadTodoProfil | null)[];
} {
  const { organizationLeadTodoProfils, profils, organizationLeadTodos } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationLeadTodoProfils: Object.keys(organizationLeadTodoProfils).map(idOrganizationLeadTodoProfil =>
      hydrate(
        organizationLeadTodoProfils[idOrganizationLeadTodoProfil] as OrganizationLeadTodoProfilEntityState,
        profils,
        organizationLeadTodos
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationLeadTodoProfil: OrganizationLeadTodoProfilEntityState | null;
} {
  const { organizationLeadTodoProfils, profils, organizationLeadTodos } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationLeadTodoProfil = Object.values(organizationLeadTodoProfils)[0];
  return {
    organizationLeadTodoProfil: hydrate(
      organizationLeadTodoProfil as OrganizationLeadTodoProfilEntityState,
      profils,
      organizationLeadTodos
    )
  };
}

function hydrate(
  organizationLeadTodoProfil: OrganizationLeadTodoProfilEntityState,
  profilEntities?: Dictionary<ProfilEntityState>,
  organizationLeadTodoEntities?: Dictionary<OrganizationLeadTodoEntityState>
): OrganizationLeadTodoProfil | null {
  if (!organizationLeadTodoProfil) {
    return null;
  }

  const organizationLeadTodoProfilHydrated: OrganizationLeadTodoProfilEntityState = { ...organizationLeadTodoProfil };
  if (profilEntities) {
    organizationLeadTodoProfilHydrated.profil = profilEntities[organizationLeadTodoProfil.profil as number] as Profil;
  } else {
    delete organizationLeadTodoProfilHydrated.profil;
  }
  if (organizationLeadTodoEntities) {
    organizationLeadTodoProfilHydrated.organizationLeadTodo = organizationLeadTodoEntities[
      organizationLeadTodoProfil.organizationLeadTodo as number
    ] as OrganizationLeadTodo;
  } else {
    delete organizationLeadTodoProfilHydrated.organizationLeadTodo;
  }

  return organizationLeadTodoProfilHydrated as OrganizationLeadTodoProfil;
}
