import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from '@_services/store/user.service';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class IsPageAvailableForUserGuard implements CanActivate {
  constructor(private readonly userService: UserService, private readonly snackBar: MatSnackBar) {}

  public canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    if (!this.userService?.currentUser?.idUser) {
      return true;
    }
    if (route.data.right) {
      if (this.userService.userHaveRight(route.data.right)) {
        return true;
      } else {
        this.snackBar.open("Vous n'avez pas les droits pour accéder à cette page", 'Fermer', {
          duration: 6000
        });
        return false;
      }
    }
    return true;
  }
}
