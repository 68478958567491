import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationApiService } from '@_services/api/organization-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedOrganizationEffects } from './organization-generated.effects';

@Injectable()
export class OrganizationEffects extends GeneratedOrganizationEffects {
  constructor(actions$: Actions, organizationApiService: OrganizationApiService, store$: Store<AppState>) {
    super(actions$, organizationApiService, store$);
  }
}
