import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DiffusionVisual, DiffusionVisualEntityState } from '@_model/interfaces/diffusion-visual.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, diffusionVisualFeatureKey, DiffusionVisualState } from './diffusion-visual.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const diffusionVisualRelations: string[] = ['residences', 'stratalots'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectDiffusionVisualState = createFeatureSelector<DiffusionVisualState>(diffusionVisualFeatureKey);

export const selectIsLoadedDiffusionVisual = createSelector(
  selectDiffusionVisualState,
  (state: DiffusionVisualState) => state.isLoaded
);

export const selectIsLoadingDiffusionVisual = createSelector(
  selectDiffusionVisualState,
  (state: DiffusionVisualState) => state.isLoading
);

export const selectIsReadyDiffusionVisual = createSelector(
  selectDiffusionVisualState,
  (state: DiffusionVisualState) => !state.isLoading
);

export const selectIsReadyAndLoadedDiffusionVisual = createSelector(
  selectDiffusionVisualState,
  (state: DiffusionVisualState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const DiffusionVisualModel: SelectorModel = {
  name: 'diffusionVisuals',
  getSelector: selectAllDiffusionVisualsDictionary,
  isReady: selectIsReadyDiffusionVisual
};

export const selectDiffusionVisualsEntities = createSelector(selectDiffusionVisualState, selectEntities);

export const selectDiffusionVisualsArray = createSelector(selectDiffusionVisualState, selectAll);

export const selectIdDiffusionVisualsActive = createSelector(
  selectDiffusionVisualState,
  (state: DiffusionVisualState) => state.actives
);

const diffusionVisualsInObject = (diffusionVisuals: Dictionary<DiffusionVisualEntityState>) => ({ diffusionVisuals });

const selectDiffusionVisualsEntitiesDictionary = createSelector(
  selectDiffusionVisualsEntities,
  diffusionVisualsInObject
);

const selectAllDiffusionVisualsObject = createSelector(selectDiffusionVisualsEntities, diffusionVisuals => {
  return hydrateAll({ diffusionVisuals });
});

const selectOneDiffusionVisualDictionary = (idDiffusionVisual: number) =>
  createSelector(selectDiffusionVisualsEntities, diffusionVisuals => {
    return { diffusionVisuals: { [idDiffusionVisual]: diffusionVisuals[idDiffusionVisual] } };
  });

const selectOneDiffusionVisualDictionaryWithoutChild = (idDiffusionVisual: number) =>
  createSelector(selectDiffusionVisualsEntities, diffusionVisuals => {
    return { diffusionVisual: diffusionVisuals[idDiffusionVisual] };
  });

const selectActiveDiffusionVisualsEntities = createSelector(
  selectIdDiffusionVisualsActive,
  selectDiffusionVisualsEntities,
  (actives: number[], diffusionVisuals: Dictionary<DiffusionVisualEntityState>) =>
    getDiffusionVisualsFromActives(actives, diffusionVisuals)
);

function getDiffusionVisualsFromActives(
  actives: number[],
  diffusionVisuals: Dictionary<DiffusionVisualEntityState>
): Dictionary<DiffusionVisualEntityState> {
  return actives.reduce((acc, idActive) => {
    if (diffusionVisuals[idActive]) {
      acc[idActive] = diffusionVisuals[idActive];
    }
    return acc;
  }, {} as Dictionary<DiffusionVisualEntityState>);
}

const selectAllDiffusionVisualsSelectors: Dictionary<Selector> = {};
export function selectAllDiffusionVisuals(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<DiffusionVisual>(
      schema,
      selectAllDiffusionVisualsSelectors,
      selectDiffusionVisualsEntitiesDictionary,
      getRelationSelectors,
      diffusionVisualRelations,
      hydrateAll,
      'diffusionVisual'
    );
  } else {
    return selectAllDiffusionVisualsObject;
  }
}

export function selectAllDiffusionVisualsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'diffusionVisuals'
): Selector {
  return createSelector(selectAllDiffusionVisuals(schema), result => {
    const res = { [customKey]: {} as Dictionary<DiffusionVisualEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.diffusionVisuals.length; i++) {
      res[customKey][result.diffusionVisuals[i].idDiffusionVisual] = result.diffusionVisuals[i];
    }
    return res;
  });
}

export function selectOneDiffusionVisual(schema: SelectSchema = {}, idDiffusionVisual: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneDiffusionVisualDictionary(idDiffusionVisual)];
    selectors.push(...getRelationSelectors(schema, diffusionVisualRelations, 'diffusionVisual'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneDiffusionVisualDictionaryWithoutChild(idDiffusionVisual);
  }
}

export function selectActiveDiffusionVisuals(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveDiffusionVisualsEntities, diffusionVisuals => ({ diffusionVisuals }))
  ];
  selectors.push(...getRelationSelectors(schema, diffusionVisualRelations, 'diffusionVisual'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  diffusionVisuals: Dictionary<DiffusionVisualEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { diffusionVisuals: (DiffusionVisual | null)[] } {
  const { diffusionVisuals, residences, stratalots } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    diffusionVisuals: Object.keys(diffusionVisuals).map(idDiffusionVisual =>
      hydrate(diffusionVisuals[idDiffusionVisual] as DiffusionVisualEntityState, residences, stratalots)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { diffusionVisual: DiffusionVisualEntityState | null } {
  const { diffusionVisuals, residences, stratalots } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const diffusionVisual = Object.values(diffusionVisuals)[0];
  return { diffusionVisual: hydrate(diffusionVisual as DiffusionVisualEntityState, residences, stratalots) };
}

function hydrate(
  diffusionVisual: DiffusionVisualEntityState,
  residenceEntities?: Dictionary<ResidenceEntityState>,
  stratalotEntities?: Dictionary<StratalotEntityState>
): DiffusionVisual | null {
  if (!diffusionVisual) {
    return null;
  }

  const diffusionVisualHydrated: DiffusionVisualEntityState = { ...diffusionVisual };
  if (residenceEntities) {
    diffusionVisualHydrated.residence = residenceEntities[diffusionVisual.residence as number] as Residence;
  } else {
    delete diffusionVisualHydrated.residence;
  }
  if (stratalotEntities) {
    diffusionVisualHydrated.stratalot = stratalotEntities[diffusionVisual.stratalot as number] as Stratalot;
  } else {
    delete diffusionVisualHydrated.stratalot;
  }

  return diffusionVisualHydrated as DiffusionVisual;
}
