import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResidencePriceGridModifierApiService } from '@_services/api/residence-price-grid-modifier-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedResidencePriceGridModifierEffects } from './residence-price-grid-modifier-generated.effects';

@Injectable()
export class ResidencePriceGridModifierEffects extends GeneratedResidencePriceGridModifierEffects {
  constructor(
    actions$: Actions,
    residencePriceGridModifierApiService: ResidencePriceGridModifierApiService,
    store$: Store<AppState>
  ) {
    super(actions$, residencePriceGridModifierApiService, store$);
  }
}
