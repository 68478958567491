import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ForecastPeriodEntityState } from '@_model/interfaces/forecast-period.model';

export interface ForecastPeriodState extends EntityState<ForecastPeriodEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ForecastPeriodEntityState> = createEntityAdapter<ForecastPeriodEntityState>({
  selectId: o => o.idForecastPeriod
});
export const initialState: ForecastPeriodState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const forecastPeriodFeatureKey = 'forecastPeriod';
