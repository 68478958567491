import { createAction, props } from '@ngrx/store';
import { Prospect } from '@_shared/models/interfaces/prospect.model';

export * from './occupant-generated.actions';

export const upsertOneOccupantWithProspectOnStratalot = createAction(
  '[Occupant] Upsert One Occupant With Prospect on Stratalot',
  props<{
    idStratalot: number;
    prospect: Partial<Prospect>;
  }>()
);
