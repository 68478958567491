import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationForecastRateEntityState } from '@_model/interfaces/organization-forecast-rate.model';

export interface OrganizationForecastRateState extends EntityState<OrganizationForecastRateEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationForecastRateEntityState> =
  createEntityAdapter<OrganizationForecastRateEntityState>({
    selectId: o => o.idOrganizationForecastRate
  });
export const initialState: OrganizationForecastRateState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationForecastRateFeatureKey = 'organizationForecastRate';
