import { RepositoryService } from '@_services/api/repository.service';
import { CompanyPriceLabel } from '@_model/interfaces/company-price-label.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyPriceLabelApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyPriceLabels(params?: any): Observable<CompanyPriceLabel[]> {
    return this.repo.getData<CompanyPriceLabel[]>('company-price-label', params);
  }

  public getCompanyPriceLabel(params: { idCompanyPriceLabel: number; params?: any }): Observable<CompanyPriceLabel> {
    return this.repo.getData<CompanyPriceLabel>('company-price-label/' + params.idCompanyPriceLabel, params.params);
  }

  public addCompanyPriceLabel(companyPriceLabel: Partial<CompanyPriceLabel>): Observable<CompanyPriceLabel> {
    return this.repo.create<CompanyPriceLabel>('company-price-label', companyPriceLabel);
  }

  public updateCompanyPriceLabel(companyPriceLabel: Partial<CompanyPriceLabel>): Observable<CompanyPriceLabel> {
    return this.repo.update('company-price-label', companyPriceLabel);
  }

  public deleteCompanyPriceLabel(idCompanyPriceLabel: number): Observable<number> {
    return this.repo.delete('company-price-label/' + +idCompanyPriceLabel);
  }
}
