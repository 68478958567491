import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { Stratalot } from '@_shared/models/interfaces/stratalot.model';
import { Observable } from 'rxjs';
import { GeneratedAssociationApiService } from './association-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class AssociationApiService extends GeneratedAssociationApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public getAssociatedStratalot(params?: { idStratalot: number; params?: any }): Observable<Stratalot[]> {
    return this.repo.getData<Stratalot[]>('association/associatedStratalots/' + params.idStratalot, params.params);
  }
}
