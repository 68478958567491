import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { StratalotPriceList, StratalotPriceListEntityState } from '@_model/interfaces/stratalot-price-list.model';
import { StratalotPriceListApiService } from '@_services/api/stratalot-price-list-api.service';
import * as stratalotPriceListActions from '@_store/stratalot-price-list/stratalot-price-list.actions';
import { getActionsToNormalizeStratalotPriceList } from '@_config/store/normalization.generated';
import { selectStratalotPriceListState } from './stratalot-price-list-generated.selectors';
import * as residencePriceGridActions from '@_store/residence-price-grid/residence-price-grid.actions';
import * as companyStratalotTypeActions from '@_store/company-stratalot-type/company-stratalot-type.actions';

export interface StratalotPriceListRelationsIds {
  residencePriceGrid?: number;
  companyStratalotType?: number;
}

export function getDefaultAddStratalotPriceListActions(
  stratalotPriceList: StratalotPriceListEntityState,
  ids?: StratalotPriceListRelationsIds
): Action[] {
  const actions: Action[] = [
    stratalotPriceListActions.normalizeManyStratalotPriceListsAfterUpsert({ stratalotPriceLists: [stratalotPriceList] })
  ];

  if (ids?.residencePriceGrid) {
    actions.push(
      residencePriceGridActions.addManyStratalotPriceListSuccess({
        idResidencePriceGrid: ids.residencePriceGrid,
        idStratalotPriceLists: [stratalotPriceList.idStratalotPriceList]
      })
    );
    actions.push(
      stratalotPriceListActions.addResidencePriceGridSuccess({
        idStratalotPriceList: stratalotPriceList.idStratalotPriceList,
        idResidencePriceGrid: ids.residencePriceGrid
      })
    );
  }

  if (ids?.companyStratalotType) {
    actions.push(
      companyStratalotTypeActions.addManyStratalotPriceListSuccess({
        idCompanyStratalotType: ids.companyStratalotType,
        idStratalotPriceLists: [stratalotPriceList.idStratalotPriceList]
      })
    );
    actions.push(
      stratalotPriceListActions.addCompanyStratalotTypeSuccess({
        idStratalotPriceList: stratalotPriceList.idStratalotPriceList,
        idCompanyStratalotType: ids.companyStratalotType
      })
    );
  }

  return actions;
}

export function getDefaultDeleteStratalotPriceListActions(stratalotPriceList: StratalotPriceListEntityState): Action[] {
  const actions: Action[] = [
    stratalotPriceListActions.deleteOneStratalotPriceListSuccess({
      idStratalotPriceList: stratalotPriceList.idStratalotPriceList
    })
  ];

  if (stratalotPriceList.residencePriceGrid) {
    actions.push(
      residencePriceGridActions.deleteManyStratalotPriceListSuccess({
        idStratalotPriceLists: [stratalotPriceList.idStratalotPriceList],
        idResidencePriceGrids: [stratalotPriceList.residencePriceGrid as number]
      })
    );
  }

  if (stratalotPriceList.companyStratalotType) {
    actions.push(
      companyStratalotTypeActions.deleteManyStratalotPriceListSuccess({
        idStratalotPriceLists: [stratalotPriceList.idStratalotPriceList],
        idCompanyStratalotTypes: [stratalotPriceList.companyStratalotType as number]
      })
    );
  }

  return actions;
}

export class GeneratedStratalotPriceListEffects {
  constructor(
    protected actions$: Actions,
    protected stratalotPriceListApiService: StratalotPriceListApiService,
    protected store$: Store<AppState>
  ) {}

  getManyStratalotPriceLists$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotPriceListActions.getManyStratalotPriceLists),
      switchMap(({ params }) =>
        this.stratalotPriceListApiService.getStratalotPriceLists(params).pipe(
          map((stratalotPriceLists: StratalotPriceList[]) => {
            return stratalotPriceListActions.normalizeManyStratalotPriceListsAfterUpsert({ stratalotPriceLists });
          }),
          catchError(error => of(stratalotPriceListActions.stratalotPriceListsFailure({ error })))
        )
      )
    );
  });

  getOneStratalotPriceList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotPriceListActions.getOneStratalotPriceList),
      switchMap(idStratalotPriceList =>
        this.stratalotPriceListApiService.getStratalotPriceList(idStratalotPriceList).pipe(
          map((stratalotPriceList: StratalotPriceList) => {
            return stratalotPriceListActions.normalizeManyStratalotPriceListsAfterUpsert({
              stratalotPriceLists: [stratalotPriceList]
            });
          }),
          catchError(error => of(stratalotPriceListActions.stratalotPriceListsFailure({ error })))
        )
      )
    );
  });

  upsertOneStratalotPriceList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotPriceListActions.upsertOneStratalotPriceList),
      concatMap(
        ({
          stratalotPriceList,
          ids
        }: {
          stratalotPriceList: Partial<StratalotPriceList>;
          ids?: StratalotPriceListRelationsIds;
        }) => {
          if (stratalotPriceList.idStratalotPriceList) {
            return this.stratalotPriceListApiService.updateStratalotPriceList(stratalotPriceList).pipe(
              map((stratalotPriceListReturned: StratalotPriceList) => {
                return stratalotPriceListActions.normalizeManyStratalotPriceListsAfterUpsert({
                  stratalotPriceLists: [stratalotPriceListReturned]
                });
              }),
              catchError(error => of(stratalotPriceListActions.stratalotPriceListsFailure({ error })))
            );
          } else {
            return this.stratalotPriceListApiService.addStratalotPriceList(stratalotPriceList).pipe(
              mergeMap((stratalotPriceListReturned: StratalotPriceList) =>
                getDefaultAddStratalotPriceListActions(stratalotPriceListReturned, ids)
              ),
              catchError(error => of(stratalotPriceListActions.stratalotPriceListsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneStratalotPriceList$ = createEffect(() => {
    const selectStratalotPriceListState$ = this.store$.select(selectStratalotPriceListState);
    return this.actions$.pipe(
      ofType(stratalotPriceListActions.deleteOneStratalotPriceList),
      withLatestFrom(selectStratalotPriceListState$),
      concatMap(([{ idStratalotPriceList }, state]) =>
        this.stratalotPriceListApiService.deleteStratalotPriceList(idStratalotPriceList).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteStratalotPriceListActions(
                state.entities[idStratalotPriceList] as StratalotPriceListEntityState
              ),
              stratalotPriceListActions.deleteOneStratalotPriceList.type
            )
          ]),
          catchError(error => of(stratalotPriceListActions.stratalotPriceListsFailure({ error })))
        )
      )
    );
  });

  normalizeManyStratalotPriceListsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotPriceListActions.normalizeManyStratalotPriceListsAfterUpsert),
      concatMap(({ stratalotPriceLists }) => {
        const actions: Action[] = getActionsToNormalizeStratalotPriceList(stratalotPriceLists, StoreActionType.upsert);
        return [getMultiAction(actions, '[StratalotPriceList] Normalization After Upsert Success')];
      })
    );
  });
}
