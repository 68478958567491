import { OrganizationResidenceTodoProfilState, initialState } from './organization-residence-todo-profil.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationResidenceTodoProfilReducersGeneratedFunctions } from './organization-residence-todo-profil-generated.reducer';

const organizationResidenceTodoProfilReducersFunctions = [...organizationResidenceTodoProfilReducersGeneratedFunctions];

const organizationResidenceTodoProfilReducer = createReducer(
  initialState,
  ...organizationResidenceTodoProfilReducersFunctions
);

export function reducer(state: OrganizationResidenceTodoProfilState | undefined, action: Action) {
  return organizationResidenceTodoProfilReducer(state, action);
}
