import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { CompanyCommunication, CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';
import { CompanyCommunicationApiService } from '@_services/api/company-communication-api.service';
import * as companyCommunicationActions from '@_store/company-communication/company-communication.actions';
import { getActionsToNormalizeCompanyCommunication } from '@_config/store/normalization.generated';
import { selectCompanyCommunicationState } from './company-communication-generated.selectors';
import * as companyActions from '@_store/company/company.actions';
import * as companyCommunicationRecipientActions from '@_store/company-communication-recipient/company-communication-recipient.actions';
import * as companyCommunicationMediaActions from '@_store/company-communication-media/company-communication-media.actions';
import * as organizationResidenceTodoActions from '@_store/organization-residence-todo/organization-residence-todo.actions';
import { OrganizationResidenceTodo } from '@_model/interfaces/organization-residence-todo.model';
import * as companyCommunicationResidenceTodoActions from '@_store/company-communication-residence-todo/company-communication-residence-todo.actions';
import { CompanyCommunicationResidenceTodo } from '@_model/interfaces/company-communication-residence-todo.model';
import * as organizationStratalotTodoActions from '@_store/organization-stratalot-todo/organization-stratalot-todo.actions';
import { OrganizationStratalotTodo } from '@_model/interfaces/organization-stratalot-todo.model';
import * as companyCommunicationStratalotTodoActions from '@_store/company-communication-stratalot-todo/company-communication-stratalot-todo.actions';
import { CompanyCommunicationStratalotTodo } from '@_model/interfaces/company-communication-stratalot-todo.model';
import * as organizationLeadTodoActions from '@_store/organization-lead-todo/organization-lead-todo.actions';
import { OrganizationLeadTodo } from '@_model/interfaces/organization-lead-todo.model';
import * as companyCommunicationLeadTodoActions from '@_store/company-communication-lead-todo/company-communication-lead-todo.actions';
import { CompanyCommunicationLeadTodo } from '@_model/interfaces/company-communication-lead-todo.model';
import * as generatedDocumentActions from '@_store/generated-document/generated-document.actions';
import { GeneratedDocument } from '@_model/interfaces/generated-document.model';
import * as companyCommunicationGeneratedDocumentActions from '@_store/company-communication-generated-document/company-communication-generated-document.actions';
import { CompanyCommunicationGeneratedDocument } from '@_model/interfaces/company-communication-generated-document.model';
import * as companyCommunicationFileActions from '@_store/company-communication-file/company-communication-file.actions';
import { CompanyCommunicationFile } from '@_model/interfaces/company-communication-file.model';

export interface CompanyCommunicationRelationsIds {
  organizationResidenceTodos?: number | number[];
  companyCommunicationResidenceTodos?: number | number[];
  organizationStratalotTodos?: number | number[];
  companyCommunicationStratalotTodos?: number | number[];
  organizationLeadTodos?: number | number[];
  companyCommunicationLeadTodos?: number | number[];
  generatedDocuments?: number | number[];
  companyCommunicationGeneratedDocuments?: number | number[];
  companyCommunicationFiles?: number | number[];
  company?: number;
  companyCommunicationRecipient?: number;
  companyCommunicationMedia?: number;
}

export function getDefaultAddCompanyCommunicationActions(
  companyCommunication: CompanyCommunicationEntityState,
  ids?: CompanyCommunicationRelationsIds
): Action[] {
  const actions: Action[] = [
    companyCommunicationActions.normalizeManyCompanyCommunicationsAfterUpsert({
      companyCommunications: [companyCommunication]
    })
  ];

  if (ids?.company) {
    actions.push(
      companyActions.addManyCompanyCommunicationSuccess({
        idCompany: ids.company,
        idCompanyCommunications: [companyCommunication.idCompanyCommunication]
      })
    );
    actions.push(
      companyCommunicationActions.addCompanySuccess({
        idCompanyCommunication: companyCommunication.idCompanyCommunication,
        idCompany: ids.company
      })
    );
  }

  if (ids?.companyCommunicationRecipient) {
    actions.push(
      companyCommunicationRecipientActions.addCompanyCommunicationSuccess({
        idCompanyCommunicationRecipient: ids.companyCommunicationRecipient,
        idCompanyCommunication: companyCommunication.idCompanyCommunication
      })
    );
    actions.push(
      companyCommunicationActions.addCompanyCommunicationRecipientSuccess({
        idCompanyCommunication: companyCommunication.idCompanyCommunication,
        idCompanyCommunicationRecipient: ids.companyCommunicationRecipient
      })
    );
  }

  if (ids?.companyCommunicationMedia) {
    actions.push(
      companyCommunicationMediaActions.addCompanyCommunicationSuccess({
        idCompanyCommunicationMedia: ids.companyCommunicationMedia,
        idCompanyCommunication: companyCommunication.idCompanyCommunication
      })
    );
    actions.push(
      companyCommunicationActions.addCompanyCommunicationMediaSuccess({
        idCompanyCommunication: companyCommunication.idCompanyCommunication,
        idCompanyCommunicationMedia: ids.companyCommunicationMedia
      })
    );
  }

  if (ids?.organizationResidenceTodos) {
    if (!Array.isArray(ids.organizationResidenceTodos)) {
      actions.push(
        organizationResidenceTodoActions.upsertOneOrganizationResidenceTodo({
          organizationResidenceTodo: {
            idCompanyCommunication: companyCommunication.idCompanyCommunication,
            idOrganizationResidenceTodo: ids.organizationResidenceTodos as number
          } as OrganizationResidenceTodo & any
        })
      );
      actions.push(
        companyCommunicationActions.addManyOrganizationResidenceTodoSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idOrganizationResidenceTodos: [ids.organizationResidenceTodos as number]
        })
      );
    } else {
      actions.push(
        organizationResidenceTodoActions.upsertManyOrganizationResidenceTodos({
          organizationResidenceTodos: (ids.organizationResidenceTodos as number[]).map(
            (idOrganizationResidenceTodo: number) => ({
              idCompanyCommunication: companyCommunication.idCompanyCommunication,
              idOrganizationResidenceTodo
            })
          ) as OrganizationResidenceTodo[] & any[]
        })
      );
      actions.push(
        companyCommunicationActions.addManyOrganizationResidenceTodoSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idOrganizationResidenceTodos: ids.organizationResidenceTodos as number[]
        })
      );
    }
  }

  if (ids?.companyCommunicationResidenceTodos) {
    if (!Array.isArray(ids.companyCommunicationResidenceTodos)) {
      actions.push(
        companyCommunicationResidenceTodoActions.upsertOneCompanyCommunicationResidenceTodo({
          companyCommunicationResidenceTodo: {
            idCompanyCommunication: companyCommunication.idCompanyCommunication,
            idCompanyCommunicationResidenceTodo: ids.companyCommunicationResidenceTodos as number
          } as CompanyCommunicationResidenceTodo
        })
      );
      actions.push(
        companyCommunicationActions.addManyCompanyCommunicationResidenceTodoSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idCompanyCommunicationResidenceTodos: [ids.companyCommunicationResidenceTodos as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationResidenceTodoActions.upsertManyCompanyCommunicationResidenceTodos({
          companyCommunicationResidenceTodos: (ids.companyCommunicationResidenceTodos as number[]).map(
            (idCompanyCommunicationResidenceTodo: number) => ({
              idCompanyCommunication: companyCommunication.idCompanyCommunication,
              idCompanyCommunicationResidenceTodo
            })
          ) as CompanyCommunicationResidenceTodo[]
        })
      );
      actions.push(
        companyCommunicationActions.addManyCompanyCommunicationResidenceTodoSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idCompanyCommunicationResidenceTodos: ids.companyCommunicationResidenceTodos as number[]
        })
      );
    }
  }

  if (ids?.organizationStratalotTodos) {
    if (!Array.isArray(ids.organizationStratalotTodos)) {
      actions.push(
        organizationStratalotTodoActions.upsertOneOrganizationStratalotTodo({
          organizationStratalotTodo: {
            idCompanyCommunication: companyCommunication.idCompanyCommunication,
            idOrganizationStratalotTodo: ids.organizationStratalotTodos as number
          } as OrganizationStratalotTodo & any
        })
      );
      actions.push(
        companyCommunicationActions.addManyOrganizationStratalotTodoSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idOrganizationStratalotTodos: [ids.organizationStratalotTodos as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotTodoActions.upsertManyOrganizationStratalotTodos({
          organizationStratalotTodos: (ids.organizationStratalotTodos as number[]).map(
            (idOrganizationStratalotTodo: number) => ({
              idCompanyCommunication: companyCommunication.idCompanyCommunication,
              idOrganizationStratalotTodo
            })
          ) as OrganizationStratalotTodo[] & any[]
        })
      );
      actions.push(
        companyCommunicationActions.addManyOrganizationStratalotTodoSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idOrganizationStratalotTodos: ids.organizationStratalotTodos as number[]
        })
      );
    }
  }

  if (ids?.companyCommunicationStratalotTodos) {
    if (!Array.isArray(ids.companyCommunicationStratalotTodos)) {
      actions.push(
        companyCommunicationStratalotTodoActions.upsertOneCompanyCommunicationStratalotTodo({
          companyCommunicationStratalotTodo: {
            idCompanyCommunication: companyCommunication.idCompanyCommunication,
            idCompanyCommunicationStratalotTodo: ids.companyCommunicationStratalotTodos as number
          } as CompanyCommunicationStratalotTodo
        })
      );
      actions.push(
        companyCommunicationActions.addManyCompanyCommunicationStratalotTodoSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idCompanyCommunicationStratalotTodos: [ids.companyCommunicationStratalotTodos as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationStratalotTodoActions.upsertManyCompanyCommunicationStratalotTodos({
          companyCommunicationStratalotTodos: (ids.companyCommunicationStratalotTodos as number[]).map(
            (idCompanyCommunicationStratalotTodo: number) => ({
              idCompanyCommunication: companyCommunication.idCompanyCommunication,
              idCompanyCommunicationStratalotTodo
            })
          ) as CompanyCommunicationStratalotTodo[]
        })
      );
      actions.push(
        companyCommunicationActions.addManyCompanyCommunicationStratalotTodoSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idCompanyCommunicationStratalotTodos: ids.companyCommunicationStratalotTodos as number[]
        })
      );
    }
  }

  if (ids?.organizationLeadTodos) {
    if (!Array.isArray(ids.organizationLeadTodos)) {
      actions.push(
        organizationLeadTodoActions.upsertOneOrganizationLeadTodo({
          organizationLeadTodo: {
            idCompanyCommunication: companyCommunication.idCompanyCommunication,
            idOrganizationLeadTodo: ids.organizationLeadTodos as number
          } as OrganizationLeadTodo & any
        })
      );
      actions.push(
        companyCommunicationActions.addManyOrganizationLeadTodoSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idOrganizationLeadTodos: [ids.organizationLeadTodos as number]
        })
      );
    } else {
      actions.push(
        organizationLeadTodoActions.upsertManyOrganizationLeadTodos({
          organizationLeadTodos: (ids.organizationLeadTodos as number[]).map((idOrganizationLeadTodo: number) => ({
            idCompanyCommunication: companyCommunication.idCompanyCommunication,
            idOrganizationLeadTodo
          })) as OrganizationLeadTodo[] & any[]
        })
      );
      actions.push(
        companyCommunicationActions.addManyOrganizationLeadTodoSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idOrganizationLeadTodos: ids.organizationLeadTodos as number[]
        })
      );
    }
  }

  if (ids?.companyCommunicationLeadTodos) {
    if (!Array.isArray(ids.companyCommunicationLeadTodos)) {
      actions.push(
        companyCommunicationLeadTodoActions.upsertOneCompanyCommunicationLeadTodo({
          companyCommunicationLeadTodo: {
            idCompanyCommunication: companyCommunication.idCompanyCommunication,
            idCompanyCommunicationLeadTodo: ids.companyCommunicationLeadTodos as number
          } as CompanyCommunicationLeadTodo
        })
      );
      actions.push(
        companyCommunicationActions.addManyCompanyCommunicationLeadTodoSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idCompanyCommunicationLeadTodos: [ids.companyCommunicationLeadTodos as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationLeadTodoActions.upsertManyCompanyCommunicationLeadTodos({
          companyCommunicationLeadTodos: (ids.companyCommunicationLeadTodos as number[]).map(
            (idCompanyCommunicationLeadTodo: number) => ({
              idCompanyCommunication: companyCommunication.idCompanyCommunication,
              idCompanyCommunicationLeadTodo
            })
          ) as CompanyCommunicationLeadTodo[]
        })
      );
      actions.push(
        companyCommunicationActions.addManyCompanyCommunicationLeadTodoSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idCompanyCommunicationLeadTodos: ids.companyCommunicationLeadTodos as number[]
        })
      );
    }
  }

  if (ids?.generatedDocuments) {
    if (!Array.isArray(ids.generatedDocuments)) {
      actions.push(
        generatedDocumentActions.upsertOneGeneratedDocument({
          generatedDocument: {
            idCompanyCommunication: companyCommunication.idCompanyCommunication,
            idGeneratedDocument: ids.generatedDocuments as number
          } as GeneratedDocument & any
        })
      );
      actions.push(
        companyCommunicationActions.addManyGeneratedDocumentSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idGeneratedDocuments: [ids.generatedDocuments as number]
        })
      );
    } else {
      actions.push(
        generatedDocumentActions.upsertManyGeneratedDocuments({
          generatedDocuments: (ids.generatedDocuments as number[]).map((idGeneratedDocument: number) => ({
            idCompanyCommunication: companyCommunication.idCompanyCommunication,
            idGeneratedDocument
          })) as GeneratedDocument[] & any[]
        })
      );
      actions.push(
        companyCommunicationActions.addManyGeneratedDocumentSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idGeneratedDocuments: ids.generatedDocuments as number[]
        })
      );
    }
  }

  if (ids?.companyCommunicationGeneratedDocuments) {
    if (!Array.isArray(ids.companyCommunicationGeneratedDocuments)) {
      actions.push(
        companyCommunicationGeneratedDocumentActions.upsertOneCompanyCommunicationGeneratedDocument({
          companyCommunicationGeneratedDocument: {
            idCompanyCommunication: companyCommunication.idCompanyCommunication,
            idCompanyCommunicationGeneratedDocument: ids.companyCommunicationGeneratedDocuments as number
          } as CompanyCommunicationGeneratedDocument
        })
      );
      actions.push(
        companyCommunicationActions.addManyCompanyCommunicationGeneratedDocumentSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idCompanyCommunicationGeneratedDocuments: [ids.companyCommunicationGeneratedDocuments as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationGeneratedDocumentActions.upsertManyCompanyCommunicationGeneratedDocuments({
          companyCommunicationGeneratedDocuments: (ids.companyCommunicationGeneratedDocuments as number[]).map(
            (idCompanyCommunicationGeneratedDocument: number) => ({
              idCompanyCommunication: companyCommunication.idCompanyCommunication,
              idCompanyCommunicationGeneratedDocument
            })
          ) as CompanyCommunicationGeneratedDocument[]
        })
      );
      actions.push(
        companyCommunicationActions.addManyCompanyCommunicationGeneratedDocumentSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idCompanyCommunicationGeneratedDocuments: ids.companyCommunicationGeneratedDocuments as number[]
        })
      );
    }
  }

  if (ids?.companyCommunicationFiles) {
    if (!Array.isArray(ids.companyCommunicationFiles)) {
      actions.push(
        companyCommunicationFileActions.upsertOneCompanyCommunicationFile({
          companyCommunicationFile: {
            idCompanyCommunication: companyCommunication.idCompanyCommunication,
            idCompanyCommunicationFile: ids.companyCommunicationFiles as number
          } as CompanyCommunicationFile
        })
      );
      actions.push(
        companyCommunicationActions.addManyCompanyCommunicationFileSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idCompanyCommunicationFiles: [ids.companyCommunicationFiles as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationFileActions.upsertManyCompanyCommunicationFiles({
          companyCommunicationFiles: (ids.companyCommunicationFiles as number[]).map(
            (idCompanyCommunicationFile: number) => ({
              idCompanyCommunication: companyCommunication.idCompanyCommunication,
              idCompanyCommunicationFile
            })
          ) as CompanyCommunicationFile[]
        })
      );
      actions.push(
        companyCommunicationActions.addManyCompanyCommunicationFileSuccess({
          idCompanyCommunication: companyCommunication.idCompanyCommunication,
          idCompanyCommunicationFiles: ids.companyCommunicationFiles as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCompanyCommunicationActions(
  companyCommunication: CompanyCommunicationEntityState
): Action[] {
  const actions: Action[] = [
    companyCommunicationActions.deleteOneCompanyCommunicationSuccess({
      idCompanyCommunication: companyCommunication.idCompanyCommunication
    })
  ];

  if (companyCommunication.company) {
    actions.push(
      companyActions.deleteManyCompanyCommunicationSuccess({
        idCompanyCommunications: [companyCommunication.idCompanyCommunication],
        idCompanies: [companyCommunication.company as number]
      })
    );
  }

  if (companyCommunication.companyCommunicationRecipient) {
    actions.push(
      companyCommunicationRecipientActions.deleteCompanyCommunicationSuccess({
        idCompanyCommunications: [companyCommunication.idCompanyCommunication],
        idCompanyCommunicationRecipients: [companyCommunication.companyCommunicationRecipient as number]
      })
    );
  }

  if (companyCommunication.companyCommunicationMedia) {
    actions.push(
      companyCommunicationMediaActions.deleteCompanyCommunicationSuccess({
        idCompanyCommunications: [companyCommunication.idCompanyCommunication],
        idCompanyCommunicationMedias: [companyCommunication.companyCommunicationMedia as number]
      })
    );
  }

  if (companyCommunication.organizationResidenceTodos) {
    actions.push(
      organizationResidenceTodoActions.deleteManyCompanyCommunicationSuccess({
        idCompanyCommunications: [companyCommunication.idCompanyCommunication],
        idOrganizationResidenceTodos: companyCommunication.organizationResidenceTodos as number[]
      })
    );
  }

  if (companyCommunication.companyCommunicationResidenceTodos) {
    actions.push(
      companyCommunicationResidenceTodoActions.deleteManyCompanyCommunicationSuccess({
        idCompanyCommunications: [companyCommunication.idCompanyCommunication],
        idCompanyCommunicationResidenceTodos: companyCommunication.companyCommunicationResidenceTodos as number[]
      })
    );
  }

  if (companyCommunication.organizationStratalotTodos) {
    actions.push(
      organizationStratalotTodoActions.deleteManyCompanyCommunicationSuccess({
        idCompanyCommunications: [companyCommunication.idCompanyCommunication],
        idOrganizationStratalotTodos: companyCommunication.organizationStratalotTodos as number[]
      })
    );
  }

  if (companyCommunication.companyCommunicationStratalotTodos) {
    actions.push(
      companyCommunicationStratalotTodoActions.deleteManyCompanyCommunicationSuccess({
        idCompanyCommunications: [companyCommunication.idCompanyCommunication],
        idCompanyCommunicationStratalotTodos: companyCommunication.companyCommunicationStratalotTodos as number[]
      })
    );
  }

  if (companyCommunication.organizationLeadTodos) {
    actions.push(
      organizationLeadTodoActions.deleteManyCompanyCommunicationSuccess({
        idCompanyCommunications: [companyCommunication.idCompanyCommunication],
        idOrganizationLeadTodos: companyCommunication.organizationLeadTodos as number[]
      })
    );
  }

  if (companyCommunication.companyCommunicationLeadTodos) {
    actions.push(
      companyCommunicationLeadTodoActions.deleteManyCompanyCommunicationSuccess({
        idCompanyCommunications: [companyCommunication.idCompanyCommunication],
        idCompanyCommunicationLeadTodos: companyCommunication.companyCommunicationLeadTodos as number[]
      })
    );
  }

  if (companyCommunication.generatedDocuments) {
    actions.push(
      generatedDocumentActions.deleteManyCompanyCommunicationSuccess({
        idCompanyCommunications: [companyCommunication.idCompanyCommunication],
        idGeneratedDocuments: companyCommunication.generatedDocuments as number[]
      })
    );
  }

  if (companyCommunication.companyCommunicationGeneratedDocuments) {
    actions.push(
      companyCommunicationGeneratedDocumentActions.deleteManyCompanyCommunicationSuccess({
        idCompanyCommunications: [companyCommunication.idCompanyCommunication],
        idCompanyCommunicationGeneratedDocuments:
          companyCommunication.companyCommunicationGeneratedDocuments as number[]
      })
    );
  }

  if (companyCommunication.companyCommunicationFiles) {
    actions.push(
      companyCommunicationFileActions.deleteManyCompanyCommunicationSuccess({
        idCompanyCommunications: [companyCommunication.idCompanyCommunication],
        idCompanyCommunicationFiles: companyCommunication.companyCommunicationFiles as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyCommunicationEffects {
  constructor(
    protected actions$: Actions,
    protected companyCommunicationApiService: CompanyCommunicationApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyCommunications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationActions.getManyCompanyCommunications),
      switchMap(({ params }) =>
        this.companyCommunicationApiService.getCompanyCommunications(params).pipe(
          map((companyCommunications: CompanyCommunication[]) => {
            return companyCommunicationActions.normalizeManyCompanyCommunicationsAfterUpsert({ companyCommunications });
          }),
          catchError(error => of(companyCommunicationActions.companyCommunicationsFailure({ error })))
        )
      )
    );
  });

  getOneCompanyCommunication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationActions.getOneCompanyCommunication),
      switchMap(idCompanyCommunication =>
        this.companyCommunicationApiService.getCompanyCommunication(idCompanyCommunication).pipe(
          map((companyCommunication: CompanyCommunication) => {
            return companyCommunicationActions.normalizeManyCompanyCommunicationsAfterUpsert({
              companyCommunications: [companyCommunication]
            });
          }),
          catchError(error => of(companyCommunicationActions.companyCommunicationsFailure({ error })))
        )
      )
    );
  });

  upsertOneCompanyCommunication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationActions.upsertOneCompanyCommunication),
      concatMap(
        ({
          companyCommunication,
          ids
        }: {
          companyCommunication: Partial<CompanyCommunication>;
          ids?: CompanyCommunicationRelationsIds;
        }) => {
          if (companyCommunication.idCompanyCommunication) {
            return this.companyCommunicationApiService.updateCompanyCommunication(companyCommunication).pipe(
              map((companyCommunicationReturned: CompanyCommunication) => {
                return companyCommunicationActions.normalizeManyCompanyCommunicationsAfterUpsert({
                  companyCommunications: [companyCommunicationReturned]
                });
              }),
              catchError(error => of(companyCommunicationActions.companyCommunicationsFailure({ error })))
            );
          } else {
            return this.companyCommunicationApiService.addCompanyCommunication(companyCommunication).pipe(
              mergeMap((companyCommunicationReturned: CompanyCommunication) =>
                getDefaultAddCompanyCommunicationActions(companyCommunicationReturned, ids)
              ),
              catchError(error => of(companyCommunicationActions.companyCommunicationsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCompanyCommunication$ = createEffect(() => {
    const selectCompanyCommunicationState$ = this.store$.select(selectCompanyCommunicationState);
    return this.actions$.pipe(
      ofType(companyCommunicationActions.deleteOneCompanyCommunication),
      withLatestFrom(selectCompanyCommunicationState$),
      concatMap(([{ idCompanyCommunication }, state]) =>
        this.companyCommunicationApiService.deleteCompanyCommunication(idCompanyCommunication).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCompanyCommunicationActions(
                state.entities[idCompanyCommunication] as CompanyCommunicationEntityState
              ),
              companyCommunicationActions.deleteOneCompanyCommunication.type
            )
          ]),
          catchError(error => of(companyCommunicationActions.companyCommunicationsFailure({ error })))
        )
      )
    );
  });

  normalizeManyCompanyCommunicationsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationActions.normalizeManyCompanyCommunicationsAfterUpsert),
      concatMap(({ companyCommunications }) => {
        const actions: Action[] = getActionsToNormalizeCompanyCommunication(
          companyCommunications,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CompanyCommunication] Normalization After Upsert Success')];
      })
    );
  });
}
