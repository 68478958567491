import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { DroitFamilleEnum, DroitInterneEnum } from '@_model/enums/droit.enum';
import { UserApiService } from '@_services/api/user-api.service';
import { User } from '@_shared/models/interfaces/user.model';
import { CompanyTerritoireModel } from '@_store/company-territoire/company-territoire-generated.selectors';
import { CompanyModel } from '@_store/company/company-generated.selectors';
import { DroitModel } from '@_store/droit/droit-generated.selectors';
import * as AppState from '@_store/index.reducers';
import { OrganizationModel } from '@_store/organization/organization-generated.selectors';
import { ProfilModel } from '@_store/profil/profil-generated.selectors';
import { UserRelationsIds } from '@_store/user/user-generated.effects';
import { selectAllUsers } from '@_store/user/user.selectors';
import { SelectSchema } from '@_utils/selector.util';
import OrganizationParamsUtils from '@_utils/up-utils/organization-params.utils';
import { Observable, Subject } from 'rxjs';
import { map, mapTo, switchMap, tap } from 'rxjs/operators';
import { GeneratedUserService } from './user-generated.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends GeneratedUserService {
  public currentUser: User = {} as User;

  public isUserHasUpdate$ = new Subject<void>();

  constructor(store$: Store<AppState.AppState>, actions$: Actions, private userApiService: UserApiService) {
    super(store$, actions$);
  }

  public userHasUpdate(): Observable<void> {
    return this.isUserHasUpdate$.asObservable();
  }

  public updateUserPassword(user: User): Observable<any> {
    return this.userApiService.updateUserPassword(user);
  }

  public selectAllUsers(schema: SelectSchema = {}): Observable<User[]> {
    return this.store$.pipe(select(selectAllUsers(schema))).pipe(
      switchMap(({ users }: { users: User[] }) => {
        return this.getReady(schema).pipe(mapTo(users.filter(user => !user.disable)));
      })
    );
  }

  public selectAllUsersWithDisable(schema: SelectSchema = {}): Observable<User[]> {
    return this.store$.pipe(select(selectAllUsers(schema))).pipe(
      switchMap(({ users }: { users: User[] }) => {
        return this.getReady(schema).pipe(mapTo(users));
      })
    );
  }

  public selectAllUsersByCurrentOrganisation(): Observable<User[]> {
    return this.selectAllUsers({ include: [OrganizationModel] }).pipe(
      map((users: User[]) =>
        users.filter((user: User) => user.idOrganization === OrganizationParamsUtils.getCurrentOrganisationId())
      )
    );
  }

  public updateCurrentUser(user?: User, idUser?: number): Observable<User> {
    if (user) {
      this.currentUser = user;
      this.isUserHasUpdate$.next();
    }
    return this.selectOneUser(idUser || this.currentUser?.idUser, {
      include: [
        { model: ProfilModel, include: [DroitModel] },
        { model: CompanyTerritoireModel, include: [CompanyModel] }
      ]
    }).pipe(
      tap(resultedUser => {
        this.currentUser = resultedUser;
        this.isUserHasUpdate$.next();
      })
    );
  }

  public upsertOneUser(user: Partial<User>, ids: UserRelationsIds = {}, getResult?: boolean): void | Observable<User> {
    const ret = super.upsertOneUser(user, ids, getResult);
    if (user.idUser && this?.currentUser?.idUser === user.idUser) {
      this.updateCurrentUser();
    }
    return ret;
  }

  public getUserRight(user: Partial<User> = this.currentUser): DroitInterneEnum[] {
    const rights: DroitInterneEnum[][] = user?.profils?.map(profil => profil.droits.map(droit => droit.labelIntern));
    return Array.prototype.concat.apply([], rights);
  }

  public getUserAccessRight(user: Partial<User> = this.currentUser): DroitInterneEnum[] {
    const rights: DroitInterneEnum[][] = user?.profils?.map(profil =>
      profil.droits
        .filter(d => d.famille == DroitFamilleEnum.page)
        .map(droit => {
          return droit.labelIntern;
        })
    );
    return Array.prototype.concat.apply([], rights);
  }

  public isUserAdmin(user = this.currentUser): boolean {
    return this.getUserRight(user).includes(DroitInterneEnum.manageAdmin);
  }

  public userHaveRight(right: DroitInterneEnum | DroitInterneEnum[], user: Partial<User> = this.currentUser): boolean {
    if (!right) {
      return false;
    } else if (typeof right === 'string') {
      return this.getUserRight(user).includes(right);
    } else if (Array.isArray(right)) {
      return !right.map(it => this.getUserRight(user).includes(it)).includes(false);
    } else {
      return false;
    }
  }

  public userHaveNoAccessPage(): boolean {
    return this.getUserAccessRight().length === 0;
  }

  public isUserSuperAdmin(user = this.currentUser): boolean {
    return !!user.superAdmin;
  }
}
