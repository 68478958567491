import { CompanyCommunicationLeadTodoState, initialState } from './company-communication-lead-todo.state';
import { Action, createReducer } from '@ngrx/store';
import { companyCommunicationLeadTodoReducersGeneratedFunctions } from './company-communication-lead-todo-generated.reducer';

const companyCommunicationLeadTodoReducersFunctions = [...companyCommunicationLeadTodoReducersGeneratedFunctions];

const companyCommunicationLeadTodoReducer = createReducer(
  initialState,
  ...companyCommunicationLeadTodoReducersFunctions
);

export function reducer(state: CompanyCommunicationLeadTodoState | undefined, action: Action) {
  return companyCommunicationLeadTodoReducer(state, action);
}
