import { RepositoryService } from '@_services/api/repository.service';
import { StratalotRcp } from '@_model/interfaces/stratalot-rcp.model';
import { Observable } from 'rxjs';

export class GeneratedStratalotRcpApiService {
  constructor(protected repo: RepositoryService) {}

  public getStratalotRcps(params?: any): Observable<StratalotRcp[]> {
    return this.repo.getData<StratalotRcp[]>('stratalot-rcp', params);
  }

  public getStratalotRcp(params: { idStratalotRcp: number; params?: any }): Observable<StratalotRcp> {
    return this.repo.getData<StratalotRcp>('stratalot-rcp/' + params.idStratalotRcp, params.params);
  }

  public addStratalotRcp(stratalotRcp: Partial<StratalotRcp>): Observable<StratalotRcp> {
    return this.repo.create<StratalotRcp>('stratalot-rcp', stratalotRcp);
  }

  public updateStratalotRcp(stratalotRcp: Partial<StratalotRcp>): Observable<StratalotRcp> {
    return this.repo.update('stratalot-rcp', stratalotRcp);
  }

  public deleteStratalotRcp(idStratalotRcp: number): Observable<number> {
    return this.repo.delete('stratalot-rcp/' + +idStratalotRcp);
  }
}
