import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StratalotPriceValueApiService } from '@_services/api/stratalot-price-value-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedStratalotPriceValueEffects } from './stratalot-price-value-generated.effects';

@Injectable()
export class StratalotPriceValueEffects extends GeneratedStratalotPriceValueEffects {
  constructor(
    actions$: Actions,
    stratalotPriceValueApiService: StratalotPriceValueApiService,
    store$: Store<AppState>
  ) {
    super(actions$, stratalotPriceValueApiService, store$);
  }
}
