import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationStratalotTodoAppliedRule } from '@_model/interfaces/organization-stratalot-todo-applied-rule.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationStratalotTodoAppliedRuleApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationStratalotTodoAppliedRules(params?: any): Observable<OrganizationStratalotTodoAppliedRule[]> {
    return this.repo.getData<OrganizationStratalotTodoAppliedRule[]>(
      'organization-stratalot-todo-applied-rule',
      params
    );
  }

  public getOrganizationStratalotTodoAppliedRule(params: {
    idOrganizationStratalotTodoAppliedRule: number;
    params?: any;
  }): Observable<OrganizationStratalotTodoAppliedRule> {
    return this.repo.getData<OrganizationStratalotTodoAppliedRule>(
      'organization-stratalot-todo-applied-rule/' + params.idOrganizationStratalotTodoAppliedRule,
      params.params
    );
  }

  public addOrganizationStratalotTodoAppliedRule(
    organizationStratalotTodoAppliedRule: Partial<OrganizationStratalotTodoAppliedRule>
  ): Observable<OrganizationStratalotTodoAppliedRule> {
    return this.repo.create<OrganizationStratalotTodoAppliedRule>(
      'organization-stratalot-todo-applied-rule',
      organizationStratalotTodoAppliedRule
    );
  }

  public updateOrganizationStratalotTodoAppliedRule(
    organizationStratalotTodoAppliedRule: Partial<OrganizationStratalotTodoAppliedRule>
  ): Observable<OrganizationStratalotTodoAppliedRule> {
    return this.repo.update('organization-stratalot-todo-applied-rule', organizationStratalotTodoAppliedRule);
  }

  public deleteOrganizationStratalotTodoAppliedRule(
    idOrganizationStratalotTodoAppliedRule: number
  ): Observable<number> {
    return this.repo.delete('organization-stratalot-todo-applied-rule/' + +idOrganizationStratalotTodoAppliedRule);
  }
}
