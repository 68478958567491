import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyStudyNextActionActions from './company-study-next-action.actions';
import { adapter, initialState, CompanyStudyNextActionState } from './company-study-next-action.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyStudyNextActionEntityState } from '@_model/interfaces/company-study-next-action.model';

export const companyStudyNextActionReducersGeneratedFunctions: ReducerTypes<
  CompanyStudyNextActionState,
  readonly ActionCreator[]
>[] = [
  on(CompanyStudyNextActionActions.getOneCompanyStudyNextAction, (state: CompanyStudyNextActionState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyStudyNextActionActions.getManyCompanyStudyNextActions, (state: CompanyStudyNextActionState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyStudyNextActionActions.upsertOneCompanyStudyNextAction, (state: CompanyStudyNextActionState) =>
    setLoadingsState(state, true)
  ),

  on(
    CompanyStudyNextActionActions.upsertManyCompanyStudyNextActionsSuccess,
    (state: CompanyStudyNextActionState, { companyStudyNextActions }) =>
      adapter.upsertMany(companyStudyNextActions, setLoadingsState(state, false))
  ),
  on(CompanyStudyNextActionActions.deleteOneCompanyStudyNextAction, (state: CompanyStudyNextActionState) =>
    setLoadingsState(state, true)
  ),
  on(
    CompanyStudyNextActionActions.deleteOneCompanyStudyNextActionSuccess,
    (state: CompanyStudyNextActionState, { idCompanyStudyNextAction }) =>
      adapter.removeOne(idCompanyStudyNextAction, setLoadingsState(state, false))
  ),
  on(CompanyStudyNextActionActions.clearActive, (state: CompanyStudyNextActionState) => ({ ...state, actives: [] })),
  on(
    CompanyStudyNextActionActions.addManyActives,
    (state: CompanyStudyNextActionState, { idCompanyStudyNextActions }) => ({
      ...state,
      actives: state.actives.concat(idCompanyStudyNextActions)
    })
  ),
  on(
    CompanyStudyNextActionActions.deleteOneActive,
    (state: CompanyStudyNextActionState, { idCompanyStudyNextAction }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCompanyStudyNextAction)
    })
  ),

  on(CompanyStudyNextActionActions.clearStore, () => initialState),

  on(
    CompanyStudyNextActionActions.addManyResidenceStudySuccess,
    (state: CompanyStudyNextActionState, { idCompanyStudyNextAction, idResidenceStudies }) => {
      if (!state.entities[idCompanyStudyNextAction]) {
        return state;
      }
      const residenceStudies = (state.entities[idCompanyStudyNextAction]?.residenceStudies as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStudyNextAction]: {
            ...state.entities[idCompanyStudyNextAction],
            residenceStudies: residenceStudies.concat(idResidenceStudies.filter(id => residenceStudies.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CompanyStudyNextActionActions.deleteManyResidenceStudySuccess,
    (state: CompanyStudyNextActionState, { idResidenceStudies, idCompanyStudyNextActions }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStudyNextActions.reduce((entities, idCompanyStudyNextAction) => {
            if (!state.entities[idCompanyStudyNextAction]?.residenceStudies) {
              return entities;
            }
            entities[idCompanyStudyNextAction] = {
              ...state.entities[idCompanyStudyNextAction],
              residenceStudies: (state.entities[idCompanyStudyNextAction]?.residenceStudies as number[])?.filter(
                (idResidenceStudy: number) => !idResidenceStudies.some((id: number) => id === idResidenceStudy)
              )
            } as CompanyStudyNextActionEntityState;
            return entities;
          }, {} as Dictionary<CompanyStudyNextActionEntityState>)
        }
      };
    }
  ),

  on(
    CompanyStudyNextActionActions.addCompanySuccess,
    (state: CompanyStudyNextActionState, { idCompanyStudyNextAction, idCompany }) => {
      if (!state.entities[idCompanyStudyNextAction]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStudyNextAction]: {
            ...state.entities[idCompanyStudyNextAction],
            company: idCompany
          }
        }
      };
    }
  ),

  on(
    CompanyStudyNextActionActions.deleteManyCompanySuccess,
    (state: CompanyStudyNextActionState, { idCompanies, idCompanyStudyNextActions }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStudyNextActions.reduce((entities, idCompanyStudyNextAction) => {
            if (!state.entities[idCompanyStudyNextAction]?.company) {
              return entities;
            }
            entities[idCompanyStudyNextAction] = {
              ...state.entities[idCompanyStudyNextAction],
              company: idCompanies.some(
                (idCompany: number) => idCompany === state.entities[idCompanyStudyNextAction]?.company
              )
                ? undefined
                : state.entities[idCompanyStudyNextAction]?.company
            } as CompanyStudyNextActionEntityState;
            return entities;
          }, {} as Dictionary<CompanyStudyNextActionEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyStudyNextActionState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyStudyNextActionState {
  return { ...state, isLoaded, isLoading };
}
