import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SaleCategoryFamily, SaleCategoryFamilyEntityState } from '@_model/interfaces/sale-category-family.model';
import {
  OrganizationSaleCategory,
  OrganizationSaleCategoryEntityState
} from '@_model/interfaces/organization-sale-category.model';
import { BoardState, BoardStateEntityState } from '@_model/interfaces/board-state.model';
import { CompanyPriceLabel, CompanyPriceLabelEntityState } from '@_model/interfaces/company-price-label.model';
import {
  OrganizationLeadTodoRule,
  OrganizationLeadTodoRuleEntityState
} from '@_model/interfaces/organization-lead-todo-rule.model';
import {
  ResidenceSaleCategoryFamily,
  ResidenceSaleCategoryFamilyEntityState
} from '@_model/interfaces/residence-sale-category-family.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import {
  OrganizationStratalotTodoRule,
  OrganizationStratalotTodoRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-rule.model';
import {
  OrganizationResidenceTodoRule,
  OrganizationResidenceTodoRuleEntityState
} from '@_model/interfaces/organization-residence-todo-rule.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, saleCategoryFamilyFeatureKey, SaleCategoryFamilyState } from './sale-category-family.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const saleCategoryFamilyRelations: string[] = [
  'organizationSaleCategories',
  'boardStates',
  'companyPriceLabels',
  'organizationLeadTodoRules',
  'residenceSaleCategoryFamilies',
  'residences',
  'organizationStratalotTodoRules',
  'organizationResidenceTodoRules'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectSaleCategoryFamilyState =
  createFeatureSelector<SaleCategoryFamilyState>(saleCategoryFamilyFeatureKey);

export const selectIsLoadedSaleCategoryFamily = createSelector(
  selectSaleCategoryFamilyState,
  (state: SaleCategoryFamilyState) => state.isLoaded
);

export const selectIsLoadingSaleCategoryFamily = createSelector(
  selectSaleCategoryFamilyState,
  (state: SaleCategoryFamilyState) => state.isLoading
);

export const selectIsReadySaleCategoryFamily = createSelector(
  selectSaleCategoryFamilyState,
  (state: SaleCategoryFamilyState) => !state.isLoading
);

export const selectIsReadyAndLoadedSaleCategoryFamily = createSelector(
  selectSaleCategoryFamilyState,
  (state: SaleCategoryFamilyState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const SaleCategoryFamilyModel: SelectorModel = {
  name: 'familySaleCategories',
  getSelector: selectAllFamilySaleCategoriesDictionary,
  isReady: selectIsReadySaleCategoryFamily
};

export const selectFamilySaleCategoriesEntities = createSelector(selectSaleCategoryFamilyState, selectEntities);

export const selectFamilySaleCategoriesArray = createSelector(selectSaleCategoryFamilyState, selectAll);

export const selectIdFamilySaleCategoriesActive = createSelector(
  selectSaleCategoryFamilyState,
  (state: SaleCategoryFamilyState) => state.actives
);

const familySaleCategoriesInObject = (familySaleCategories: Dictionary<SaleCategoryFamilyEntityState>) => ({
  familySaleCategories
});

const selectFamilySaleCategoriesEntitiesDictionary = createSelector(
  selectFamilySaleCategoriesEntities,
  familySaleCategoriesInObject
);

const selectAllFamilySaleCategoriesObject = createSelector(selectFamilySaleCategoriesEntities, familySaleCategories => {
  return hydrateAll({ familySaleCategories });
});

const selectOneSaleCategoryFamilyDictionary = (idSaleCategoryFamily: number) =>
  createSelector(selectFamilySaleCategoriesEntities, familySaleCategories => {
    return { familySaleCategories: { [idSaleCategoryFamily]: familySaleCategories[idSaleCategoryFamily] } };
  });

const selectOneSaleCategoryFamilyDictionaryWithoutChild = (idSaleCategoryFamily: number) =>
  createSelector(selectFamilySaleCategoriesEntities, familySaleCategories => {
    return { saleCategoryFamily: familySaleCategories[idSaleCategoryFamily] };
  });

const selectActiveFamilySaleCategoriesEntities = createSelector(
  selectIdFamilySaleCategoriesActive,
  selectFamilySaleCategoriesEntities,
  (actives: number[], familySaleCategories: Dictionary<SaleCategoryFamilyEntityState>) =>
    getFamilySaleCategoriesFromActives(actives, familySaleCategories)
);

function getFamilySaleCategoriesFromActives(
  actives: number[],
  familySaleCategories: Dictionary<SaleCategoryFamilyEntityState>
): Dictionary<SaleCategoryFamilyEntityState> {
  return actives.reduce((acc, idActive) => {
    if (familySaleCategories[idActive]) {
      acc[idActive] = familySaleCategories[idActive];
    }
    return acc;
  }, {} as Dictionary<SaleCategoryFamilyEntityState>);
}

const selectAllFamilySaleCategoriesSelectors: Dictionary<Selector> = {};
export function selectAllFamilySaleCategories(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<SaleCategoryFamily>(
      schema,
      selectAllFamilySaleCategoriesSelectors,
      selectFamilySaleCategoriesEntitiesDictionary,
      getRelationSelectors,
      saleCategoryFamilyRelations,
      hydrateAll,
      'saleCategoryFamily'
    );
  } else {
    return selectAllFamilySaleCategoriesObject;
  }
}

export function selectAllFamilySaleCategoriesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'familySaleCategories'
): Selector {
  return createSelector(selectAllFamilySaleCategories(schema), result => {
    const res = { [customKey]: {} as Dictionary<SaleCategoryFamilyEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.familySaleCategories.length; i++) {
      res[customKey][result.familySaleCategories[i].idSaleCategoryFamily] = result.familySaleCategories[i];
    }
    return res;
  });
}

export function selectOneSaleCategoryFamily(schema: SelectSchema = {}, idSaleCategoryFamily: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneSaleCategoryFamilyDictionary(idSaleCategoryFamily)];
    selectors.push(...getRelationSelectors(schema, saleCategoryFamilyRelations, 'saleCategoryFamily'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSaleCategoryFamilyDictionaryWithoutChild(idSaleCategoryFamily);
  }
}

export function selectActiveFamilySaleCategories(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveFamilySaleCategoriesEntities, familySaleCategories => ({ familySaleCategories }))
  ];
  selectors.push(...getRelationSelectors(schema, saleCategoryFamilyRelations, 'saleCategoryFamily'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  familySaleCategories: Dictionary<SaleCategoryFamilyEntityState>;
  organizationSaleCategories?: Dictionary<OrganizationSaleCategoryEntityState>;
  boardStates?: Dictionary<BoardStateEntityState>;
  companyPriceLabels?: Dictionary<CompanyPriceLabelEntityState>;
  organizationLeadTodoRules?: Dictionary<OrganizationLeadTodoRuleEntityState>;
  residenceSaleCategoryFamilies?: Dictionary<ResidenceSaleCategoryFamilyEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
  organizationStratalotTodoRules?: Dictionary<OrganizationStratalotTodoRuleEntityState>;
  organizationResidenceTodoRules?: Dictionary<OrganizationResidenceTodoRuleEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { familySaleCategories: (SaleCategoryFamily | null)[] } {
  const {
    familySaleCategories,
    organizationSaleCategories,
    boardStates,
    companyPriceLabels,
    organizationLeadTodoRules,
    residenceSaleCategoryFamilies,
    residences,
    organizationStratalotTodoRules,
    organizationResidenceTodoRules
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    familySaleCategories: Object.keys(familySaleCategories).map(idSaleCategoryFamily =>
      hydrate(
        familySaleCategories[idSaleCategoryFamily] as SaleCategoryFamilyEntityState,
        organizationSaleCategories,
        boardStates,
        companyPriceLabels,
        organizationLeadTodoRules,
        residenceSaleCategoryFamilies,
        residences,
        organizationStratalotTodoRules,
        organizationResidenceTodoRules
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { saleCategoryFamily: SaleCategoryFamilyEntityState | null } {
  const {
    familySaleCategories,
    organizationSaleCategories,
    boardStates,
    companyPriceLabels,
    organizationLeadTodoRules,
    residenceSaleCategoryFamilies,
    residences,
    organizationStratalotTodoRules,
    organizationResidenceTodoRules
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const saleCategoryFamily = Object.values(familySaleCategories)[0];
  return {
    saleCategoryFamily: hydrate(
      saleCategoryFamily as SaleCategoryFamilyEntityState,
      organizationSaleCategories,
      boardStates,
      companyPriceLabels,
      organizationLeadTodoRules,
      residenceSaleCategoryFamilies,
      residences,
      organizationStratalotTodoRules,
      organizationResidenceTodoRules
    )
  };
}

function hydrate(
  saleCategoryFamily: SaleCategoryFamilyEntityState,
  organizationSaleCategoryEntities?: Dictionary<OrganizationSaleCategoryEntityState>,
  boardStateEntities?: Dictionary<BoardStateEntityState>,
  companyPriceLabelEntities?: Dictionary<CompanyPriceLabelEntityState>,
  organizationLeadTodoRuleEntities?: Dictionary<OrganizationLeadTodoRuleEntityState>,
  residenceSaleCategoryFamilyEntities?: Dictionary<ResidenceSaleCategoryFamilyEntityState>,
  residenceEntities?: Dictionary<ResidenceEntityState>,
  organizationStratalotTodoRuleEntities?: Dictionary<OrganizationStratalotTodoRuleEntityState>,
  organizationResidenceTodoRuleEntities?: Dictionary<OrganizationResidenceTodoRuleEntityState>
): SaleCategoryFamily | null {
  if (!saleCategoryFamily) {
    return null;
  }

  const saleCategoryFamilyHydrated: SaleCategoryFamilyEntityState = { ...saleCategoryFamily };

  if (organizationSaleCategoryEntities) {
    saleCategoryFamilyHydrated.organizationSaleCategories = (
      (saleCategoryFamilyHydrated.organizationSaleCategories as number[]) || []
    ).map(id => organizationSaleCategoryEntities[id]) as OrganizationSaleCategory[];
  } else {
    delete saleCategoryFamilyHydrated.organizationSaleCategories;
  }

  if (boardStateEntities) {
    saleCategoryFamilyHydrated.boardStates = ((saleCategoryFamilyHydrated.boardStates as number[]) || []).map(
      id => boardStateEntities[id]
    ) as BoardState[];
  } else {
    delete saleCategoryFamilyHydrated.boardStates;
  }

  if (companyPriceLabelEntities) {
    saleCategoryFamilyHydrated.companyPriceLabels = (
      (saleCategoryFamilyHydrated.companyPriceLabels as number[]) || []
    ).map(id => companyPriceLabelEntities[id]) as CompanyPriceLabel[];
  } else {
    delete saleCategoryFamilyHydrated.companyPriceLabels;
  }

  if (organizationLeadTodoRuleEntities) {
    saleCategoryFamilyHydrated.organizationLeadTodoRules = (
      (saleCategoryFamilyHydrated.organizationLeadTodoRules as number[]) || []
    ).map(id => organizationLeadTodoRuleEntities[id]) as OrganizationLeadTodoRule[];
  } else {
    delete saleCategoryFamilyHydrated.organizationLeadTodoRules;
  }

  if (residenceSaleCategoryFamilyEntities) {
    saleCategoryFamilyHydrated.residenceSaleCategoryFamilies = (
      (saleCategoryFamilyHydrated.residenceSaleCategoryFamilies as number[]) || []
    ).map(id => residenceSaleCategoryFamilyEntities[id]) as ResidenceSaleCategoryFamily[];
  } else {
    delete saleCategoryFamilyHydrated.residenceSaleCategoryFamilies;
  }

  if (residenceEntities) {
    saleCategoryFamilyHydrated.residences = ((saleCategoryFamilyHydrated.residences as number[]) || []).map(
      id => residenceEntities[id]
    ) as Residence[];
  } else {
    delete saleCategoryFamilyHydrated.residences;
  }

  if (organizationStratalotTodoRuleEntities) {
    saleCategoryFamilyHydrated.organizationStratalotTodoRules = (
      (saleCategoryFamilyHydrated.organizationStratalotTodoRules as number[]) || []
    ).map(id => organizationStratalotTodoRuleEntities[id]) as OrganizationStratalotTodoRule[];
  } else {
    delete saleCategoryFamilyHydrated.organizationStratalotTodoRules;
  }

  if (organizationResidenceTodoRuleEntities) {
    saleCategoryFamilyHydrated.organizationResidenceTodoRules = (
      (saleCategoryFamilyHydrated.organizationResidenceTodoRules as number[]) || []
    ).map(id => organizationResidenceTodoRuleEntities[id]) as OrganizationResidenceTodoRule[];
  } else {
    delete saleCategoryFamilyHydrated.organizationResidenceTodoRules;
  }

  return saleCategoryFamilyHydrated as SaleCategoryFamily;
}
