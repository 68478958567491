import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationLeadTodoRule,
  OrganizationLeadTodoRuleEntityState
} from '@_model/interfaces/organization-lead-todo-rule.model';
import { OrganizationLeadTodoRuleApiService } from '@_services/api/organization-lead-todo-rule-api.service';
import * as organizationLeadTodoRuleActions from '@_store/organization-lead-todo-rule/organization-lead-todo-rule.actions';
import { getActionsToNormalizeOrganizationLeadTodoRule } from '@_config/store/normalization.generated';
import { selectOrganizationLeadTodoRuleState } from './organization-lead-todo-rule-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as organizationSaleCategoryActions from '@_store/organization-sale-category/organization-sale-category.actions';
import * as saleCategoryFamilyActions from '@_store/sale-category-family/sale-category-family.actions';
import * as companyActions from '@_store/company/company.actions';
import * as companyStratalotTypeActions from '@_store/company-stratalot-type/company-stratalot-type.actions';
import * as stratalotTypeActions from '@_store/stratalot-type/stratalot-type.actions';
import * as organizationLeadTodoActions from '@_store/organization-lead-todo/organization-lead-todo.actions';
import { OrganizationLeadTodo } from '@_model/interfaces/organization-lead-todo.model';
import * as organizationLeadTodoAppliedRuleActions from '@_store/organization-lead-todo-applied-rule/organization-lead-todo-applied-rule.actions';
import { OrganizationLeadTodoAppliedRule } from '@_model/interfaces/organization-lead-todo-applied-rule.model';

export interface OrganizationLeadTodoRuleRelationsIds {
  organizationLeadTodos?: number | number[];
  organizationLeadTodoAppliedRules?: number | number[];
  organization?: number;
  organizationSaleCategory?: number;
  saleCategoryFamily?: number;
  company?: number;
  companyStratalotType?: number;
  stratalotType?: number;
}

export function getDefaultAddOrganizationLeadTodoRuleActions(
  organizationLeadTodoRule: OrganizationLeadTodoRuleEntityState,
  ids?: OrganizationLeadTodoRuleRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationLeadTodoRuleActions.normalizeManyOrganizationLeadTodoRulesAfterUpsert({
      organizationLeadTodoRules: [organizationLeadTodoRule]
    })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationLeadTodoRuleSuccess({
        idOrganization: ids.organization,
        idOrganizationLeadTodoRules: [organizationLeadTodoRule.idOrganizationLeadTodoRule]
      })
    );
    actions.push(
      organizationLeadTodoRuleActions.addOrganizationSuccess({
        idOrganizationLeadTodoRule: organizationLeadTodoRule.idOrganizationLeadTodoRule,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.organizationSaleCategory) {
    actions.push(
      organizationSaleCategoryActions.addManyOrganizationLeadTodoRuleSuccess({
        idOrganizationSaleCategory: ids.organizationSaleCategory,
        idOrganizationLeadTodoRules: [organizationLeadTodoRule.idOrganizationLeadTodoRule]
      })
    );
    actions.push(
      organizationLeadTodoRuleActions.addOrganizationSaleCategorySuccess({
        idOrganizationLeadTodoRule: organizationLeadTodoRule.idOrganizationLeadTodoRule,
        idOrganizationSaleCategory: ids.organizationSaleCategory
      })
    );
  }

  if (ids?.saleCategoryFamily) {
    actions.push(
      saleCategoryFamilyActions.addManyOrganizationLeadTodoRuleSuccess({
        idSaleCategoryFamily: ids.saleCategoryFamily,
        idOrganizationLeadTodoRules: [organizationLeadTodoRule.idOrganizationLeadTodoRule]
      })
    );
    actions.push(
      organizationLeadTodoRuleActions.addSaleCategoryFamilySuccess({
        idOrganizationLeadTodoRule: organizationLeadTodoRule.idOrganizationLeadTodoRule,
        idSaleCategoryFamily: ids.saleCategoryFamily
      })
    );
  }

  if (ids?.company) {
    actions.push(
      companyActions.addManyOrganizationLeadTodoRuleSuccess({
        idCompany: ids.company,
        idOrganizationLeadTodoRules: [organizationLeadTodoRule.idOrganizationLeadTodoRule]
      })
    );
    actions.push(
      organizationLeadTodoRuleActions.addCompanySuccess({
        idOrganizationLeadTodoRule: organizationLeadTodoRule.idOrganizationLeadTodoRule,
        idCompany: ids.company
      })
    );
  }

  if (ids?.companyStratalotType) {
    actions.push(
      companyStratalotTypeActions.addManyOrganizationLeadTodoRuleSuccess({
        idCompanyStratalotType: ids.companyStratalotType,
        idOrganizationLeadTodoRules: [organizationLeadTodoRule.idOrganizationLeadTodoRule]
      })
    );
    actions.push(
      organizationLeadTodoRuleActions.addCompanyStratalotTypeSuccess({
        idOrganizationLeadTodoRule: organizationLeadTodoRule.idOrganizationLeadTodoRule,
        idCompanyStratalotType: ids.companyStratalotType
      })
    );
  }

  if (ids?.stratalotType) {
    actions.push(
      stratalotTypeActions.addManyOrganizationLeadTodoRuleSuccess({
        idStratalotType: ids.stratalotType,
        idOrganizationLeadTodoRules: [organizationLeadTodoRule.idOrganizationLeadTodoRule]
      })
    );
    actions.push(
      organizationLeadTodoRuleActions.addStratalotTypeSuccess({
        idOrganizationLeadTodoRule: organizationLeadTodoRule.idOrganizationLeadTodoRule,
        idStratalotType: ids.stratalotType
      })
    );
  }

  if (ids?.organizationLeadTodos) {
    if (!Array.isArray(ids.organizationLeadTodos)) {
      actions.push(
        organizationLeadTodoActions.upsertOneOrganizationLeadTodo({
          organizationLeadTodo: {
            idOrganizationLeadTodoRule: organizationLeadTodoRule.idOrganizationLeadTodoRule,
            idOrganizationLeadTodo: ids.organizationLeadTodos as number
          } as OrganizationLeadTodo & any
        })
      );
      actions.push(
        organizationLeadTodoRuleActions.addManyOrganizationLeadTodoSuccess({
          idOrganizationLeadTodoRule: organizationLeadTodoRule.idOrganizationLeadTodoRule,
          idOrganizationLeadTodos: [ids.organizationLeadTodos as number]
        })
      );
    } else {
      actions.push(
        organizationLeadTodoActions.upsertManyOrganizationLeadTodos({
          organizationLeadTodos: (ids.organizationLeadTodos as number[]).map((idOrganizationLeadTodo: number) => ({
            idOrganizationLeadTodoRule: organizationLeadTodoRule.idOrganizationLeadTodoRule,
            idOrganizationLeadTodo
          })) as OrganizationLeadTodo[] & any[]
        })
      );
      actions.push(
        organizationLeadTodoRuleActions.addManyOrganizationLeadTodoSuccess({
          idOrganizationLeadTodoRule: organizationLeadTodoRule.idOrganizationLeadTodoRule,
          idOrganizationLeadTodos: ids.organizationLeadTodos as number[]
        })
      );
    }
  }

  if (ids?.organizationLeadTodoAppliedRules) {
    if (!Array.isArray(ids.organizationLeadTodoAppliedRules)) {
      actions.push(
        organizationLeadTodoAppliedRuleActions.upsertOneOrganizationLeadTodoAppliedRule({
          organizationLeadTodoAppliedRule: {
            idOrganizationLeadTodoRule: organizationLeadTodoRule.idOrganizationLeadTodoRule,
            idOrganizationLeadTodoAppliedRule: ids.organizationLeadTodoAppliedRules as number
          } as OrganizationLeadTodoAppliedRule
        })
      );
      actions.push(
        organizationLeadTodoRuleActions.addManyOrganizationLeadTodoAppliedRuleSuccess({
          idOrganizationLeadTodoRule: organizationLeadTodoRule.idOrganizationLeadTodoRule,
          idOrganizationLeadTodoAppliedRules: [ids.organizationLeadTodoAppliedRules as number]
        })
      );
    } else {
      actions.push(
        organizationLeadTodoAppliedRuleActions.upsertManyOrganizationLeadTodoAppliedRules({
          organizationLeadTodoAppliedRules: (ids.organizationLeadTodoAppliedRules as number[]).map(
            (idOrganizationLeadTodoAppliedRule: number) => ({
              idOrganizationLeadTodoRule: organizationLeadTodoRule.idOrganizationLeadTodoRule,
              idOrganizationLeadTodoAppliedRule
            })
          ) as OrganizationLeadTodoAppliedRule[]
        })
      );
      actions.push(
        organizationLeadTodoRuleActions.addManyOrganizationLeadTodoAppliedRuleSuccess({
          idOrganizationLeadTodoRule: organizationLeadTodoRule.idOrganizationLeadTodoRule,
          idOrganizationLeadTodoAppliedRules: ids.organizationLeadTodoAppliedRules as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationLeadTodoRuleActions(
  organizationLeadTodoRule: OrganizationLeadTodoRuleEntityState
): Action[] {
  const actions: Action[] = [
    organizationLeadTodoRuleActions.deleteOneOrganizationLeadTodoRuleSuccess({
      idOrganizationLeadTodoRule: organizationLeadTodoRule.idOrganizationLeadTodoRule
    })
  ];

  if (organizationLeadTodoRule.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationLeadTodoRuleSuccess({
        idOrganizationLeadTodoRules: [organizationLeadTodoRule.idOrganizationLeadTodoRule],
        idOrganizations: [organizationLeadTodoRule.organization as number]
      })
    );
  }

  if (organizationLeadTodoRule.organizationSaleCategory) {
    actions.push(
      organizationSaleCategoryActions.deleteManyOrganizationLeadTodoRuleSuccess({
        idOrganizationLeadTodoRules: [organizationLeadTodoRule.idOrganizationLeadTodoRule],
        idOrganizationSaleCategories: [organizationLeadTodoRule.organizationSaleCategory as number]
      })
    );
  }

  if (organizationLeadTodoRule.saleCategoryFamily) {
    actions.push(
      saleCategoryFamilyActions.deleteManyOrganizationLeadTodoRuleSuccess({
        idOrganizationLeadTodoRules: [organizationLeadTodoRule.idOrganizationLeadTodoRule],
        idFamilySaleCategories: [organizationLeadTodoRule.saleCategoryFamily as number]
      })
    );
  }

  if (organizationLeadTodoRule.company) {
    actions.push(
      companyActions.deleteManyOrganizationLeadTodoRuleSuccess({
        idOrganizationLeadTodoRules: [organizationLeadTodoRule.idOrganizationLeadTodoRule],
        idCompanies: [organizationLeadTodoRule.company as number]
      })
    );
  }

  if (organizationLeadTodoRule.companyStratalotType) {
    actions.push(
      companyStratalotTypeActions.deleteManyOrganizationLeadTodoRuleSuccess({
        idOrganizationLeadTodoRules: [organizationLeadTodoRule.idOrganizationLeadTodoRule],
        idCompanyStratalotTypes: [organizationLeadTodoRule.companyStratalotType as number]
      })
    );
  }

  if (organizationLeadTodoRule.stratalotType) {
    actions.push(
      stratalotTypeActions.deleteManyOrganizationLeadTodoRuleSuccess({
        idOrganizationLeadTodoRules: [organizationLeadTodoRule.idOrganizationLeadTodoRule],
        idStratalotTypes: [organizationLeadTodoRule.stratalotType as number]
      })
    );
  }

  if (organizationLeadTodoRule.organizationLeadTodos) {
    actions.push(
      organizationLeadTodoActions.deleteManyOrganizationLeadTodoRuleSuccess({
        idOrganizationLeadTodoRules: [organizationLeadTodoRule.idOrganizationLeadTodoRule],
        idOrganizationLeadTodos: organizationLeadTodoRule.organizationLeadTodos as number[]
      })
    );
  }

  if (organizationLeadTodoRule.organizationLeadTodoAppliedRules) {
    actions.push(
      organizationLeadTodoAppliedRuleActions.deleteManyOrganizationLeadTodoRuleSuccess({
        idOrganizationLeadTodoRules: [organizationLeadTodoRule.idOrganizationLeadTodoRule],
        idOrganizationLeadTodoAppliedRules: organizationLeadTodoRule.organizationLeadTodoAppliedRules as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationLeadTodoRuleEffects {
  constructor(
    protected actions$: Actions,
    protected organizationLeadTodoRuleApiService: OrganizationLeadTodoRuleApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationLeadTodoRules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadTodoRuleActions.getManyOrganizationLeadTodoRules),
      switchMap(({ params }) =>
        this.organizationLeadTodoRuleApiService.getOrganizationLeadTodoRules(params).pipe(
          map((organizationLeadTodoRules: OrganizationLeadTodoRule[]) => {
            return organizationLeadTodoRuleActions.normalizeManyOrganizationLeadTodoRulesAfterUpsert({
              organizationLeadTodoRules
            });
          }),
          catchError(error => of(organizationLeadTodoRuleActions.organizationLeadTodoRulesFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationLeadTodoRule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadTodoRuleActions.getOneOrganizationLeadTodoRule),
      switchMap(idOrganizationLeadTodoRule =>
        this.organizationLeadTodoRuleApiService.getOrganizationLeadTodoRule(idOrganizationLeadTodoRule).pipe(
          map((organizationLeadTodoRule: OrganizationLeadTodoRule) => {
            return organizationLeadTodoRuleActions.normalizeManyOrganizationLeadTodoRulesAfterUpsert({
              organizationLeadTodoRules: [organizationLeadTodoRule]
            });
          }),
          catchError(error => of(organizationLeadTodoRuleActions.organizationLeadTodoRulesFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationLeadTodoRule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadTodoRuleActions.upsertOneOrganizationLeadTodoRule),
      concatMap(
        ({
          organizationLeadTodoRule,
          ids
        }: {
          organizationLeadTodoRule: Partial<OrganizationLeadTodoRule>;
          ids?: OrganizationLeadTodoRuleRelationsIds;
        }) => {
          if (organizationLeadTodoRule.idOrganizationLeadTodoRule) {
            return this.organizationLeadTodoRuleApiService
              .updateOrganizationLeadTodoRule(organizationLeadTodoRule)
              .pipe(
                map((organizationLeadTodoRuleReturned: OrganizationLeadTodoRule) => {
                  return organizationLeadTodoRuleActions.normalizeManyOrganizationLeadTodoRulesAfterUpsert({
                    organizationLeadTodoRules: [organizationLeadTodoRuleReturned]
                  });
                }),
                catchError(error => of(organizationLeadTodoRuleActions.organizationLeadTodoRulesFailure({ error })))
              );
          } else {
            return this.organizationLeadTodoRuleApiService.addOrganizationLeadTodoRule(organizationLeadTodoRule).pipe(
              mergeMap((organizationLeadTodoRuleReturned: OrganizationLeadTodoRule) =>
                getDefaultAddOrganizationLeadTodoRuleActions(organizationLeadTodoRuleReturned, ids)
              ),
              catchError(error => of(organizationLeadTodoRuleActions.organizationLeadTodoRulesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationLeadTodoRule$ = createEffect(() => {
    const selectOrganizationLeadTodoRuleState$ = this.store$.select(selectOrganizationLeadTodoRuleState);
    return this.actions$.pipe(
      ofType(organizationLeadTodoRuleActions.deleteOneOrganizationLeadTodoRule),
      withLatestFrom(selectOrganizationLeadTodoRuleState$),
      concatMap(([{ idOrganizationLeadTodoRule }, state]) =>
        this.organizationLeadTodoRuleApiService.deleteOrganizationLeadTodoRule(idOrganizationLeadTodoRule).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOrganizationLeadTodoRuleActions(
                state.entities[idOrganizationLeadTodoRule] as OrganizationLeadTodoRuleEntityState
              ),
              organizationLeadTodoRuleActions.deleteOneOrganizationLeadTodoRule.type
            )
          ]),
          catchError(error => of(organizationLeadTodoRuleActions.organizationLeadTodoRulesFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationLeadTodoRulesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadTodoRuleActions.normalizeManyOrganizationLeadTodoRulesAfterUpsert),
      concatMap(({ organizationLeadTodoRules }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationLeadTodoRule(
          organizationLeadTodoRules,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationLeadTodoRule] Normalization After Upsert Success')];
      })
    );
  });
}
