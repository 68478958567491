import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { StratalotPrice } from '@_shared/models/interfaces/stratalot-price.model';
import { Observable } from 'rxjs';
import { GeneratedStratalotPriceApiService } from './stratalot-price-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class StratalotPriceApiService extends GeneratedStratalotPriceApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public generatePriceValues(stratalotPrice: any): Observable<StratalotPrice[]> {
    return this.repo.create<StratalotPrice[]>('stratalot-price/generate-prices', stratalotPrice);
  }
}
