import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyCommunicationStratalotTodoEntityState } from '@_model/interfaces/company-communication-stratalot-todo.model';

export interface CompanyCommunicationStratalotTodoState
  extends EntityState<CompanyCommunicationStratalotTodoEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyCommunicationStratalotTodoEntityState> =
  createEntityAdapter<CompanyCommunicationStratalotTodoEntityState>({
    selectId: o => o.idCompanyCommunicationStratalotTodo
  });
export const initialState: CompanyCommunicationStratalotTodoState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyCommunicationStratalotTodoFeatureKey = 'companyCommunicationStratalotTodo';
