import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationLeadTodoActions from './organization-lead-todo.actions';
import { adapter, initialState, OrganizationLeadTodoState } from './organization-lead-todo.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationLeadTodoEntityState } from '@_model/interfaces/organization-lead-todo.model';

export const organizationLeadTodoReducersGeneratedFunctions: ReducerTypes<
  OrganizationLeadTodoState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationLeadTodoActions.getOneOrganizationLeadTodo, (state: OrganizationLeadTodoState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationLeadTodoActions.getManyOrganizationLeadTodos, (state: OrganizationLeadTodoState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationLeadTodoActions.upsertOneOrganizationLeadTodo, (state: OrganizationLeadTodoState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationLeadTodoActions.upsertManyOrganizationLeadTodosSuccess,
    (state: OrganizationLeadTodoState, { organizationLeadTodos }) =>
      adapter.upsertMany(organizationLeadTodos, setLoadingsState(state, false))
  ),
  on(OrganizationLeadTodoActions.deleteOneOrganizationLeadTodo, (state: OrganizationLeadTodoState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationLeadTodoActions.deleteOneOrganizationLeadTodoSuccess,
    (state: OrganizationLeadTodoState, { idOrganizationLeadTodo }) =>
      adapter.removeOne(idOrganizationLeadTodo, setLoadingsState(state, false))
  ),
  on(OrganizationLeadTodoActions.clearActive, (state: OrganizationLeadTodoState) => ({ ...state, actives: [] })),
  on(OrganizationLeadTodoActions.addManyActives, (state: OrganizationLeadTodoState, { idOrganizationLeadTodos }) => ({
    ...state,
    actives: state.actives.concat(idOrganizationLeadTodos)
  })),
  on(OrganizationLeadTodoActions.deleteOneActive, (state: OrganizationLeadTodoState, { idOrganizationLeadTodo }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idOrganizationLeadTodo)
  })),

  on(OrganizationLeadTodoActions.clearStore, () => initialState),

  on(
    OrganizationLeadTodoActions.addManyLeadTodoSuccess,
    (state: OrganizationLeadTodoState, { idOrganizationLeadTodo, idLeadTodos }) => {
      if (!state.entities[idOrganizationLeadTodo]) {
        return state;
      }
      const leadTodos = (state.entities[idOrganizationLeadTodo]?.leadTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodo]: {
            ...state.entities[idOrganizationLeadTodo],
            leadTodos: leadTodos.concat(idLeadTodos.filter(id => leadTodos.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoActions.deleteManyLeadTodoSuccess,
    (state: OrganizationLeadTodoState, { idLeadTodos, idOrganizationLeadTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodos.reduce((entities, idOrganizationLeadTodo) => {
            if (!state.entities[idOrganizationLeadTodo]?.leadTodos) {
              return entities;
            }
            entities[idOrganizationLeadTodo] = {
              ...state.entities[idOrganizationLeadTodo],
              leadTodos: (state.entities[idOrganizationLeadTodo]?.leadTodos as number[])?.filter(
                (idLeadTodo: number) => !idLeadTodos.some((id: number) => id === idLeadTodo)
              )
            } as OrganizationLeadTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoActions.addManyOrganizationLeadTodoRuleSuccess,
    (state: OrganizationLeadTodoState, { idOrganizationLeadTodo, idOrganizationLeadTodoRules }) => {
      if (!state.entities[idOrganizationLeadTodo]) {
        return state;
      }
      const organizationLeadTodoRules =
        (state.entities[idOrganizationLeadTodo]?.organizationLeadTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodo]: {
            ...state.entities[idOrganizationLeadTodo],
            organizationLeadTodoRules: organizationLeadTodoRules.concat(
              idOrganizationLeadTodoRules.filter(id => organizationLeadTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoActions.deleteManyOrganizationLeadTodoRuleSuccess,
    (state: OrganizationLeadTodoState, { idOrganizationLeadTodoRules, idOrganizationLeadTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodos.reduce((entities, idOrganizationLeadTodo) => {
            if (!state.entities[idOrganizationLeadTodo]?.organizationLeadTodoRules) {
              return entities;
            }
            entities[idOrganizationLeadTodo] = {
              ...state.entities[idOrganizationLeadTodo],
              organizationLeadTodoRules: (
                state.entities[idOrganizationLeadTodo]?.organizationLeadTodoRules as number[]
              )?.filter(
                (idOrganizationLeadTodoRule: number) =>
                  !idOrganizationLeadTodoRules.some((id: number) => id === idOrganizationLeadTodoRule)
              )
            } as OrganizationLeadTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoActions.addManyOrganizationLeadTodoAppliedRuleSuccess,
    (state: OrganizationLeadTodoState, { idOrganizationLeadTodo, idOrganizationLeadTodoAppliedRules }) => {
      if (!state.entities[idOrganizationLeadTodo]) {
        return state;
      }
      const organizationLeadTodoAppliedRules =
        (state.entities[idOrganizationLeadTodo]?.organizationLeadTodoAppliedRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodo]: {
            ...state.entities[idOrganizationLeadTodo],
            organizationLeadTodoAppliedRules: organizationLeadTodoAppliedRules.concat(
              idOrganizationLeadTodoAppliedRules.filter(id => organizationLeadTodoAppliedRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoActions.deleteManyOrganizationLeadTodoAppliedRuleSuccess,
    (state: OrganizationLeadTodoState, { idOrganizationLeadTodoAppliedRules, idOrganizationLeadTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodos.reduce((entities, idOrganizationLeadTodo) => {
            if (!state.entities[idOrganizationLeadTodo]?.organizationLeadTodoAppliedRules) {
              return entities;
            }
            entities[idOrganizationLeadTodo] = {
              ...state.entities[idOrganizationLeadTodo],
              organizationLeadTodoAppliedRules: (
                state.entities[idOrganizationLeadTodo]?.organizationLeadTodoAppliedRules as number[]
              )?.filter(
                (idOrganizationLeadTodoAppliedRule: number) =>
                  !idOrganizationLeadTodoAppliedRules.some((id: number) => id === idOrganizationLeadTodoAppliedRule)
              )
            } as OrganizationLeadTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoActions.addManyOrganizationLeadTodoProfilSuccess,
    (state: OrganizationLeadTodoState, { idOrganizationLeadTodo, idOrganizationLeadTodoProfils }) => {
      if (!state.entities[idOrganizationLeadTodo]) {
        return state;
      }
      const organizationLeadTodoProfils =
        (state.entities[idOrganizationLeadTodo]?.organizationLeadTodoProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodo]: {
            ...state.entities[idOrganizationLeadTodo],
            organizationLeadTodoProfils: organizationLeadTodoProfils.concat(
              idOrganizationLeadTodoProfils.filter(id => organizationLeadTodoProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoActions.deleteManyOrganizationLeadTodoProfilSuccess,
    (state: OrganizationLeadTodoState, { idOrganizationLeadTodoProfils, idOrganizationLeadTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodos.reduce((entities, idOrganizationLeadTodo) => {
            if (!state.entities[idOrganizationLeadTodo]?.organizationLeadTodoProfils) {
              return entities;
            }
            entities[idOrganizationLeadTodo] = {
              ...state.entities[idOrganizationLeadTodo],
              organizationLeadTodoProfils: (
                state.entities[idOrganizationLeadTodo]?.organizationLeadTodoProfils as number[]
              )?.filter(
                (idOrganizationLeadTodoProfil: number) =>
                  !idOrganizationLeadTodoProfils.some((id: number) => id === idOrganizationLeadTodoProfil)
              )
            } as OrganizationLeadTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoActions.addManyCompanyCommunicationSuccess,
    (state: OrganizationLeadTodoState, { idOrganizationLeadTodo, idCompanyCommunications }) => {
      if (!state.entities[idOrganizationLeadTodo]) {
        return state;
      }
      const companyCommunications = (state.entities[idOrganizationLeadTodo]?.companyCommunications as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodo]: {
            ...state.entities[idOrganizationLeadTodo],
            companyCommunications: companyCommunications.concat(
              idCompanyCommunications.filter(id => companyCommunications.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoActions.deleteManyCompanyCommunicationSuccess,
    (state: OrganizationLeadTodoState, { idCompanyCommunications, idOrganizationLeadTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodos.reduce((entities, idOrganizationLeadTodo) => {
            if (!state.entities[idOrganizationLeadTodo]?.companyCommunications) {
              return entities;
            }
            entities[idOrganizationLeadTodo] = {
              ...state.entities[idOrganizationLeadTodo],
              companyCommunications: (
                state.entities[idOrganizationLeadTodo]?.companyCommunications as number[]
              )?.filter(
                (idCompanyCommunication: number) =>
                  !idCompanyCommunications.some((id: number) => id === idCompanyCommunication)
              )
            } as OrganizationLeadTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoActions.addManyCompanyCommunicationLeadTodoSuccess,
    (state: OrganizationLeadTodoState, { idOrganizationLeadTodo, idCompanyCommunicationLeadTodos }) => {
      if (!state.entities[idOrganizationLeadTodo]) {
        return state;
      }
      const companyCommunicationLeadTodos =
        (state.entities[idOrganizationLeadTodo]?.companyCommunicationLeadTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodo]: {
            ...state.entities[idOrganizationLeadTodo],
            companyCommunicationLeadTodos: companyCommunicationLeadTodos.concat(
              idCompanyCommunicationLeadTodos.filter(id => companyCommunicationLeadTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoActions.deleteManyCompanyCommunicationLeadTodoSuccess,
    (state: OrganizationLeadTodoState, { idCompanyCommunicationLeadTodos, idOrganizationLeadTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodos.reduce((entities, idOrganizationLeadTodo) => {
            if (!state.entities[idOrganizationLeadTodo]?.companyCommunicationLeadTodos) {
              return entities;
            }
            entities[idOrganizationLeadTodo] = {
              ...state.entities[idOrganizationLeadTodo],
              companyCommunicationLeadTodos: (
                state.entities[idOrganizationLeadTodo]?.companyCommunicationLeadTodos as number[]
              )?.filter(
                (idCompanyCommunicationLeadTodo: number) =>
                  !idCompanyCommunicationLeadTodos.some((id: number) => id === idCompanyCommunicationLeadTodo)
              )
            } as OrganizationLeadTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoActions.addOrganizationSuccess,
    (state: OrganizationLeadTodoState, { idOrganizationLeadTodo, idOrganization }) => {
      if (!state.entities[idOrganizationLeadTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodo]: {
            ...state.entities[idOrganizationLeadTodo],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoActions.deleteManyOrganizationSuccess,
    (state: OrganizationLeadTodoState, { idOrganizations, idOrganizationLeadTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodos.reduce((entities, idOrganizationLeadTodo) => {
            if (!state.entities[idOrganizationLeadTodo]?.organization) {
              return entities;
            }
            entities[idOrganizationLeadTodo] = {
              ...state.entities[idOrganizationLeadTodo],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationLeadTodo]?.organization
              )
                ? undefined
                : state.entities[idOrganizationLeadTodo]?.organization
            } as OrganizationLeadTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoActions.addStepSuccess,
    (state: OrganizationLeadTodoState, { idOrganizationLeadTodo, idStep }) => {
      if (!state.entities[idOrganizationLeadTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodo]: {
            ...state.entities[idOrganizationLeadTodo],
            step: idStep
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoActions.deleteManyStepSuccess,
    (state: OrganizationLeadTodoState, { idSteps, idOrganizationLeadTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodos.reduce((entities, idOrganizationLeadTodo) => {
            if (!state.entities[idOrganizationLeadTodo]?.step) {
              return entities;
            }
            entities[idOrganizationLeadTodo] = {
              ...state.entities[idOrganizationLeadTodo],
              step: idSteps.some((idStep: number) => idStep === state.entities[idOrganizationLeadTodo]?.step)
                ? undefined
                : state.entities[idOrganizationLeadTodo]?.step
            } as OrganizationLeadTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationLeadTodoState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationLeadTodoState {
  return { ...state, isLoaded, isLoading };
}
