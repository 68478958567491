import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationResidenceTodoProfil } from '@_model/interfaces/organization-residence-todo-profil.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationResidenceTodoProfilApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationResidenceTodoProfils(params?: any): Observable<OrganizationResidenceTodoProfil[]> {
    return this.repo.getData<OrganizationResidenceTodoProfil[]>('organization-residence-todo-profil', params);
  }

  public getOrganizationResidenceTodoProfil(params: {
    idOrganizationResidenceTodoProfil: number;
    params?: any;
  }): Observable<OrganizationResidenceTodoProfil> {
    return this.repo.getData<OrganizationResidenceTodoProfil>(
      'organization-residence-todo-profil/' + params.idOrganizationResidenceTodoProfil,
      params.params
    );
  }

  public addOrganizationResidenceTodoProfil(
    organizationResidenceTodoProfil: Partial<OrganizationResidenceTodoProfil>
  ): Observable<OrganizationResidenceTodoProfil> {
    return this.repo.create<OrganizationResidenceTodoProfil>(
      'organization-residence-todo-profil',
      organizationResidenceTodoProfil
    );
  }

  public updateOrganizationResidenceTodoProfil(
    organizationResidenceTodoProfil: Partial<OrganizationResidenceTodoProfil>
  ): Observable<OrganizationResidenceTodoProfil> {
    return this.repo.update('organization-residence-todo-profil', organizationResidenceTodoProfil);
  }

  public deleteOrganizationResidenceTodoProfil(idOrganizationResidenceTodoProfil: number): Observable<number> {
    return this.repo.delete('organization-residence-todo-profil/' + +idOrganizationResidenceTodoProfil);
  }
}
