import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  ResidencePriceGridModifier,
  ResidencePriceGridModifierEntityState
} from '@_model/interfaces/residence-price-grid-modifier.model';
import { ResidencePriceGridModifierApiService } from '@_services/api/residence-price-grid-modifier-api.service';
import * as residencePriceGridModifierActions from '@_store/residence-price-grid-modifier/residence-price-grid-modifier.actions';
import { getActionsToNormalizeResidencePriceGridModifier } from '@_config/store/normalization.generated';
import { selectResidencePriceGridModifierState } from './residence-price-grid-modifier-generated.selectors';
import * as residencePriceGridActions from '@_store/residence-price-grid/residence-price-grid.actions';
import * as companyPriceLabelActions from '@_store/company-price-label/company-price-label.actions';

export interface ResidencePriceGridModifierRelationsIds {
  residencePriceGrid?: number;
  companyPriceLabel?: number;
}

export function getDefaultAddResidencePriceGridModifierActions(
  residencePriceGridModifier: ResidencePriceGridModifierEntityState,
  ids?: ResidencePriceGridModifierRelationsIds
): Action[] {
  const actions: Action[] = [
    residencePriceGridModifierActions.normalizeManyResidencePriceGridModifiersAfterUpsert({
      residencePriceGridModifiers: [residencePriceGridModifier]
    })
  ];

  if (ids?.residencePriceGrid) {
    actions.push(
      residencePriceGridActions.addManyResidencePriceGridModifierSuccess({
        idResidencePriceGrid: ids.residencePriceGrid,
        idResidencePriceGridModifiers: [residencePriceGridModifier.idResidencePriceGridModifier]
      })
    );
    actions.push(
      residencePriceGridModifierActions.addResidencePriceGridSuccess({
        idResidencePriceGridModifier: residencePriceGridModifier.idResidencePriceGridModifier,
        idResidencePriceGrid: ids.residencePriceGrid
      })
    );
  }

  if (ids?.companyPriceLabel) {
    actions.push(
      companyPriceLabelActions.addManyResidencePriceGridModifierSuccess({
        idCompanyPriceLabel: ids.companyPriceLabel,
        idResidencePriceGridModifiers: [residencePriceGridModifier.idResidencePriceGridModifier]
      })
    );
    actions.push(
      residencePriceGridModifierActions.addCompanyPriceLabelSuccess({
        idResidencePriceGridModifier: residencePriceGridModifier.idResidencePriceGridModifier,
        idCompanyPriceLabel: ids.companyPriceLabel
      })
    );
  }

  return actions;
}

export function getDefaultDeleteResidencePriceGridModifierActions(
  residencePriceGridModifier: ResidencePriceGridModifierEntityState
): Action[] {
  const actions: Action[] = [
    residencePriceGridModifierActions.deleteOneResidencePriceGridModifierSuccess({
      idResidencePriceGridModifier: residencePriceGridModifier.idResidencePriceGridModifier
    })
  ];

  if (residencePriceGridModifier.residencePriceGrid) {
    actions.push(
      residencePriceGridActions.deleteManyResidencePriceGridModifierSuccess({
        idResidencePriceGridModifiers: [residencePriceGridModifier.idResidencePriceGridModifier],
        idResidencePriceGrids: [residencePriceGridModifier.residencePriceGrid as number]
      })
    );
  }

  if (residencePriceGridModifier.companyPriceLabel) {
    actions.push(
      companyPriceLabelActions.deleteManyResidencePriceGridModifierSuccess({
        idResidencePriceGridModifiers: [residencePriceGridModifier.idResidencePriceGridModifier],
        idCompanyPriceLabels: [residencePriceGridModifier.companyPriceLabel as number]
      })
    );
  }

  return actions;
}

export class GeneratedResidencePriceGridModifierEffects {
  constructor(
    protected actions$: Actions,
    protected residencePriceGridModifierApiService: ResidencePriceGridModifierApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidencePriceGridModifiers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridModifierActions.getManyResidencePriceGridModifiers),
      switchMap(({ params }) =>
        this.residencePriceGridModifierApiService.getResidencePriceGridModifiers(params).pipe(
          map((residencePriceGridModifiers: ResidencePriceGridModifier[]) => {
            return residencePriceGridModifierActions.normalizeManyResidencePriceGridModifiersAfterUpsert({
              residencePriceGridModifiers
            });
          }),
          catchError(error => of(residencePriceGridModifierActions.residencePriceGridModifiersFailure({ error })))
        )
      )
    );
  });

  getOneResidencePriceGridModifier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridModifierActions.getOneResidencePriceGridModifier),
      switchMap(idResidencePriceGridModifier =>
        this.residencePriceGridModifierApiService.getResidencePriceGridModifier(idResidencePriceGridModifier).pipe(
          map((residencePriceGridModifier: ResidencePriceGridModifier) => {
            return residencePriceGridModifierActions.normalizeManyResidencePriceGridModifiersAfterUpsert({
              residencePriceGridModifiers: [residencePriceGridModifier]
            });
          }),
          catchError(error => of(residencePriceGridModifierActions.residencePriceGridModifiersFailure({ error })))
        )
      )
    );
  });

  upsertOneResidencePriceGridModifier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridModifierActions.upsertOneResidencePriceGridModifier),
      concatMap(
        ({
          residencePriceGridModifier,
          ids
        }: {
          residencePriceGridModifier: Partial<ResidencePriceGridModifier>;
          ids?: ResidencePriceGridModifierRelationsIds;
        }) => {
          if (residencePriceGridModifier.idResidencePriceGridModifier) {
            return this.residencePriceGridModifierApiService
              .updateResidencePriceGridModifier(residencePriceGridModifier)
              .pipe(
                map((residencePriceGridModifierReturned: ResidencePriceGridModifier) => {
                  return residencePriceGridModifierActions.normalizeManyResidencePriceGridModifiersAfterUpsert({
                    residencePriceGridModifiers: [residencePriceGridModifierReturned]
                  });
                }),
                catchError(error => of(residencePriceGridModifierActions.residencePriceGridModifiersFailure({ error })))
              );
          } else {
            return this.residencePriceGridModifierApiService
              .addResidencePriceGridModifier(residencePriceGridModifier)
              .pipe(
                mergeMap((residencePriceGridModifierReturned: ResidencePriceGridModifier) =>
                  getDefaultAddResidencePriceGridModifierActions(residencePriceGridModifierReturned, ids)
                ),
                catchError(error => of(residencePriceGridModifierActions.residencePriceGridModifiersFailure({ error })))
              );
          }
        }
      )
    );
  });

  deleteOneResidencePriceGridModifier$ = createEffect(() => {
    const selectResidencePriceGridModifierState$ = this.store$.select(selectResidencePriceGridModifierState);
    return this.actions$.pipe(
      ofType(residencePriceGridModifierActions.deleteOneResidencePriceGridModifier),
      withLatestFrom(selectResidencePriceGridModifierState$),
      concatMap(([{ idResidencePriceGridModifier }, state]) =>
        this.residencePriceGridModifierApiService.deleteResidencePriceGridModifier(idResidencePriceGridModifier).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidencePriceGridModifierActions(
                state.entities[idResidencePriceGridModifier] as ResidencePriceGridModifierEntityState
              ),
              residencePriceGridModifierActions.deleteOneResidencePriceGridModifier.type
            )
          ]),
          catchError(error => of(residencePriceGridModifierActions.residencePriceGridModifiersFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidencePriceGridModifiersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridModifierActions.normalizeManyResidencePriceGridModifiersAfterUpsert),
      concatMap(({ residencePriceGridModifiers }) => {
        const actions: Action[] = getActionsToNormalizeResidencePriceGridModifier(
          residencePriceGridModifiers,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[ResidencePriceGridModifier] Normalization After Upsert Success')];
      })
    );
  });
}
