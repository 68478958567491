import { ResidenceStudyWorkState, initialState } from './residence-study-work.state';
import { Action, createReducer } from '@ngrx/store';
import { residenceStudyWorkReducersGeneratedFunctions } from './residence-study-work-generated.reducer';

const residenceStudyWorkReducersFunctions = [...residenceStudyWorkReducersGeneratedFunctions];

const residenceStudyWorkReducer = createReducer(initialState, ...residenceStudyWorkReducersFunctions);

export function reducer(state: ResidenceStudyWorkState | undefined, action: Action) {
  return residenceStudyWorkReducer(state, action);
}
