import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyMyNotaryApiService } from '@_services/api/company-my-notary-api.service';
import { GeneratedCompanyMyNotaryEffects } from './company-my-notary-generated.effects';

@Injectable()
export class CompanyMyNotaryEffects extends GeneratedCompanyMyNotaryEffects {
  constructor(actions$: Actions, companyMyNotaryApiService: CompanyMyNotaryApiService, store$: Store<AppState>) {
    super(actions$, companyMyNotaryApiService, store$);
  }
}
