import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { StratalotCampaign } from '@_shared/models/interfaces/stratalot-campaign.model';
import { Observable } from 'rxjs';
import { GeneratedStratalotCampaignApiService } from './stratalot-campaign-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class StratalotCampaignApiService extends GeneratedStratalotCampaignApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public updateOneStratalotCampaign(stratalotCampaign: Partial<StratalotCampaign>): Observable<StratalotCampaign[]> {
    return this.repo.update('stratalot-campaign', stratalotCampaign);
  }

  public addOneStratalotCampaign(stratalotCampaign: Partial<StratalotCampaign>): Observable<StratalotCampaign[]> {
    return this.repo.create<StratalotCampaign[]>('stratalot-campaign', stratalotCampaign);
  }
}
