import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  CompanyStudyNextAction,
  CompanyStudyNextActionEntityState
} from '@_model/interfaces/company-study-next-action.model';
import { CompanyStudyNextActionApiService } from '@_services/api/company-study-next-action-api.service';
import * as companyStudyNextActionActions from '@_store/company-study-next-action/company-study-next-action.actions';
import { getActionsToNormalizeCompanyStudyNextAction } from '@_config/store/normalization.generated';
import { selectCompanyStudyNextActionState } from './company-study-next-action-generated.selectors';
import * as companyActions from '@_store/company/company.actions';
import * as residenceStudyActions from '@_store/residence-study/residence-study.actions';
import { ResidenceStudy } from '@_model/interfaces/residence-study.model';

export interface CompanyStudyNextActionRelationsIds {
  residenceStudies?: number | number[];
  company?: number;
}

export function getDefaultAddCompanyStudyNextActionActions(
  companyStudyNextAction: CompanyStudyNextActionEntityState,
  ids?: CompanyStudyNextActionRelationsIds
): Action[] {
  const actions: Action[] = [
    companyStudyNextActionActions.normalizeManyCompanyStudyNextActionsAfterUpsert({
      companyStudyNextActions: [companyStudyNextAction]
    })
  ];

  if (ids?.company) {
    actions.push(
      companyActions.addManyCompanyStudyNextActionSuccess({
        idCompany: ids.company,
        idCompanyStudyNextActions: [companyStudyNextAction.idCompanyStudyNextAction]
      })
    );
    actions.push(
      companyStudyNextActionActions.addCompanySuccess({
        idCompanyStudyNextAction: companyStudyNextAction.idCompanyStudyNextAction,
        idCompany: ids.company
      })
    );
  }

  if (ids?.residenceStudies) {
    if (!Array.isArray(ids.residenceStudies)) {
      actions.push(
        residenceStudyActions.upsertOneResidenceStudy({
          residenceStudy: {
            idCompanyStudyNextAction: companyStudyNextAction.idCompanyStudyNextAction,
            idResidenceStudy: ids.residenceStudies as number
          } as ResidenceStudy
        })
      );
      actions.push(
        companyStudyNextActionActions.addManyResidenceStudySuccess({
          idCompanyStudyNextAction: companyStudyNextAction.idCompanyStudyNextAction,
          idResidenceStudies: [ids.residenceStudies as number]
        })
      );
    } else {
      actions.push(
        residenceStudyActions.upsertManyResidenceStudies({
          residenceStudies: (ids.residenceStudies as number[]).map((idResidenceStudy: number) => ({
            idCompanyStudyNextAction: companyStudyNextAction.idCompanyStudyNextAction,
            idResidenceStudy
          })) as ResidenceStudy[]
        })
      );
      actions.push(
        companyStudyNextActionActions.addManyResidenceStudySuccess({
          idCompanyStudyNextAction: companyStudyNextAction.idCompanyStudyNextAction,
          idResidenceStudies: ids.residenceStudies as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCompanyStudyNextActionActions(
  companyStudyNextAction: CompanyStudyNextActionEntityState
): Action[] {
  const actions: Action[] = [
    companyStudyNextActionActions.deleteOneCompanyStudyNextActionSuccess({
      idCompanyStudyNextAction: companyStudyNextAction.idCompanyStudyNextAction
    })
  ];

  if (companyStudyNextAction.company) {
    actions.push(
      companyActions.deleteManyCompanyStudyNextActionSuccess({
        idCompanyStudyNextActions: [companyStudyNextAction.idCompanyStudyNextAction],
        idCompanies: [companyStudyNextAction.company as number]
      })
    );
  }

  if (companyStudyNextAction.residenceStudies) {
    actions.push(
      residenceStudyActions.deleteManyCompanyStudyNextActionSuccess({
        idCompanyStudyNextActions: [companyStudyNextAction.idCompanyStudyNextAction],
        idResidenceStudies: companyStudyNextAction.residenceStudies as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyStudyNextActionEffects {
  constructor(
    protected actions$: Actions,
    protected companyStudyNextActionApiService: CompanyStudyNextActionApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyStudyNextActions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStudyNextActionActions.getManyCompanyStudyNextActions),
      switchMap(({ params }) =>
        this.companyStudyNextActionApiService.getCompanyStudyNextActions(params).pipe(
          map((companyStudyNextActions: CompanyStudyNextAction[]) => {
            return companyStudyNextActionActions.normalizeManyCompanyStudyNextActionsAfterUpsert({
              companyStudyNextActions
            });
          }),
          catchError(error => of(companyStudyNextActionActions.companyStudyNextActionsFailure({ error })))
        )
      )
    );
  });

  getOneCompanyStudyNextAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStudyNextActionActions.getOneCompanyStudyNextAction),
      switchMap(idCompanyStudyNextAction =>
        this.companyStudyNextActionApiService.getCompanyStudyNextAction(idCompanyStudyNextAction).pipe(
          map((companyStudyNextAction: CompanyStudyNextAction) => {
            return companyStudyNextActionActions.normalizeManyCompanyStudyNextActionsAfterUpsert({
              companyStudyNextActions: [companyStudyNextAction]
            });
          }),
          catchError(error => of(companyStudyNextActionActions.companyStudyNextActionsFailure({ error })))
        )
      )
    );
  });

  upsertOneCompanyStudyNextAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStudyNextActionActions.upsertOneCompanyStudyNextAction),
      concatMap(
        ({
          companyStudyNextAction,
          ids
        }: {
          companyStudyNextAction: Partial<CompanyStudyNextAction>;
          ids?: CompanyStudyNextActionRelationsIds;
        }) => {
          if (companyStudyNextAction.idCompanyStudyNextAction) {
            return this.companyStudyNextActionApiService.updateCompanyStudyNextAction(companyStudyNextAction).pipe(
              map((companyStudyNextActionReturned: CompanyStudyNextAction) => {
                return companyStudyNextActionActions.normalizeManyCompanyStudyNextActionsAfterUpsert({
                  companyStudyNextActions: [companyStudyNextActionReturned]
                });
              }),
              catchError(error => of(companyStudyNextActionActions.companyStudyNextActionsFailure({ error })))
            );
          } else {
            return this.companyStudyNextActionApiService.addCompanyStudyNextAction(companyStudyNextAction).pipe(
              mergeMap((companyStudyNextActionReturned: CompanyStudyNextAction) =>
                getDefaultAddCompanyStudyNextActionActions(companyStudyNextActionReturned, ids)
              ),
              catchError(error => of(companyStudyNextActionActions.companyStudyNextActionsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCompanyStudyNextAction$ = createEffect(() => {
    const selectCompanyStudyNextActionState$ = this.store$.select(selectCompanyStudyNextActionState);
    return this.actions$.pipe(
      ofType(companyStudyNextActionActions.deleteOneCompanyStudyNextAction),
      withLatestFrom(selectCompanyStudyNextActionState$),
      concatMap(([{ idCompanyStudyNextAction }, state]) =>
        this.companyStudyNextActionApiService.deleteCompanyStudyNextAction(idCompanyStudyNextAction).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCompanyStudyNextActionActions(
                state.entities[idCompanyStudyNextAction] as CompanyStudyNextActionEntityState
              ),
              companyStudyNextActionActions.deleteOneCompanyStudyNextAction.type
            )
          ]),
          catchError(error => of(companyStudyNextActionActions.companyStudyNextActionsFailure({ error })))
        )
      )
    );
  });

  normalizeManyCompanyStudyNextActionsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStudyNextActionActions.normalizeManyCompanyStudyNextActionsAfterUpsert),
      concatMap(({ companyStudyNextActions }) => {
        const actions: Action[] = getActionsToNormalizeCompanyStudyNextAction(
          companyStudyNextActions,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CompanyStudyNextAction] Normalization After Upsert Success')];
      })
    );
  });
}
