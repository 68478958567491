import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidenceEntityState } from '@_model/interfaces/residence.model';

export interface ResidenceState extends EntityState<ResidenceEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidenceEntityState> = createEntityAdapter<ResidenceEntityState>({
  selectId: o => o.idResidence
});
export const initialState: ResidenceState = adapter.getInitialState({ isLoaded: false, isLoading: false, actives: [] });

export const residenceFeatureKey = 'residence';
