import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { User, UserEntityState } from '@_model/interfaces/user.model';
import { OrganizationMetier, OrganizationMetierEntityState } from '@_model/interfaces/organization-metier.model';
import { OrganizationSociete, OrganizationSocieteEntityState } from '@_model/interfaces/organization-societe.model';
import { OrganizationLeadTodo, OrganizationLeadTodoEntityState } from '@_model/interfaces/organization-lead-todo.model';
import {
  OrganizationLeadTodoRule,
  OrganizationLeadTodoRuleEntityState
} from '@_model/interfaces/organization-lead-todo-rule.model';
import {
  OrganizationStratalotTodoRule,
  OrganizationStratalotTodoRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-rule.model';
import {
  OrganizationResidenceTodoRule,
  OrganizationResidenceTodoRuleEntityState
} from '@_model/interfaces/organization-residence-todo-rule.model';
import {
  OrganizationResidenceTodo,
  OrganizationResidenceTodoEntityState
} from '@_model/interfaces/organization-residence-todo.model';
import {
  OrganizationStratalotTodo,
  OrganizationStratalotTodoEntityState
} from '@_model/interfaces/organization-stratalot-todo.model';
import {
  OrganizationResidenceStudyTodo,
  OrganizationResidenceStudyTodoEntityState
} from '@_model/interfaces/organization-residence-study-todo.model';
import { OrganizationProfil, OrganizationProfilEntityState } from '@_model/interfaces/organization-profil.model';
import {
  OrganizationProspectOrigin,
  OrganizationProspectOriginEntityState
} from '@_model/interfaces/organization-prospect-origin.model';
import { Prospect, ProspectEntityState } from '@_model/interfaces/prospect.model';
import { Profil, ProfilEntityState } from '@_model/interfaces/profil.model';
import { GeneratedDocument, GeneratedDocumentEntityState } from '@_model/interfaces/generated-document.model';
import {
  OrganizationThirdParty,
  OrganizationThirdPartyEntityState
} from '@_model/interfaces/organization-third-party.model';
import {
  OrganizationStratalotAvancement,
  OrganizationStratalotAvancementEntityState
} from '@_model/interfaces/organization-stratalot-avancement.model';
import {
  OrganizationLeadAvancement,
  OrganizationLeadAvancementEntityState
} from '@_model/interfaces/organization-lead-avancement.model';
import {
  OrganizationSaleCategory,
  OrganizationSaleCategoryEntityState
} from '@_model/interfaces/organization-sale-category.model';
import {
  OrganizationBuyingWish,
  OrganizationBuyingWishEntityState
} from '@_model/interfaces/organization-buying-wish.model';
import { OrganizationEnergie, OrganizationEnergieEntityState } from '@_model/interfaces/organization-energie.model';
import {
  OrganizationForecastRate,
  OrganizationForecastRateEntityState
} from '@_model/interfaces/organization-forecast-rate.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, organizationFeatureKey, OrganizationState } from './organization.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationRelations: string[] = [
  'companies',
  'users',
  'organizationMetiers',
  'organizationSocietes',
  'organizationLeadTodos',
  'organizationLeadTodoRules',
  'organizationStratalotTodoRules',
  'organizationResidenceTodoRules',
  'organizationResidenceTodos',
  'organizationStratalotTodos',
  'organizationResidenceStudyTodos',
  'organizationProfils',
  'organizationProspectOrigins',
  'prospects',
  'profils',
  'generatedDocuments',
  'organizationThirdParties',
  'organizationStratalotAvancements',
  'organizationLeadAvancements',
  'organizationSaleCategories',
  'organizationBuyingWishes',
  'organizationEnergies',
  'organizationForecastRates'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationState = createFeatureSelector<OrganizationState>(organizationFeatureKey);

export const selectIsLoadedOrganization = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isLoaded
);

export const selectIsLoadingOrganization = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isLoading
);

export const selectIsReadyOrganization = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganization = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationModel: SelectorModel = {
  name: 'organizations',
  getSelector: selectAllOrganizationsDictionary,
  isReady: selectIsReadyOrganization
};

export const selectOrganizationsEntities = createSelector(selectOrganizationState, selectEntities);

export const selectOrganizationsArray = createSelector(selectOrganizationState, selectAll);

export const selectIdOrganizationsActive = createSelector(
  selectOrganizationState,
  (state: OrganizationState) => state.actives
);

const organizationsInObject = (organizations: Dictionary<OrganizationEntityState>) => ({ organizations });

const selectOrganizationsEntitiesDictionary = createSelector(selectOrganizationsEntities, organizationsInObject);

const selectAllOrganizationsObject = createSelector(selectOrganizationsEntities, organizations => {
  return hydrateAll({ organizations });
});

const selectOneOrganizationDictionary = (idOrganization: number) =>
  createSelector(selectOrganizationsEntities, organizations => {
    return { organizations: { [idOrganization]: organizations[idOrganization] } };
  });

const selectOneOrganizationDictionaryWithoutChild = (idOrganization: number) =>
  createSelector(selectOrganizationsEntities, organizations => {
    return { organization: organizations[idOrganization] };
  });

const selectActiveOrganizationsEntities = createSelector(
  selectIdOrganizationsActive,
  selectOrganizationsEntities,
  (actives: number[], organizations: Dictionary<OrganizationEntityState>) =>
    getOrganizationsFromActives(actives, organizations)
);

function getOrganizationsFromActives(
  actives: number[],
  organizations: Dictionary<OrganizationEntityState>
): Dictionary<OrganizationEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizations[idActive]) {
      acc[idActive] = organizations[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationEntityState>);
}

const selectAllOrganizationsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizations(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Organization>(
      schema,
      selectAllOrganizationsSelectors,
      selectOrganizationsEntitiesDictionary,
      getRelationSelectors,
      organizationRelations,
      hydrateAll,
      'organization'
    );
  } else {
    return selectAllOrganizationsObject;
  }
}

export function selectAllOrganizationsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizations'
): Selector {
  return createSelector(selectAllOrganizations(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizations.length; i++) {
      res[customKey][result.organizations[i].idOrganization] = result.organizations[i];
    }
    return res;
  });
}

export function selectOneOrganization(schema: SelectSchema = {}, idOrganization: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationDictionary(idOrganization)];
    selectors.push(...getRelationSelectors(schema, organizationRelations, 'organization'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationDictionaryWithoutChild(idOrganization);
  }
}

export function selectActiveOrganizations(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationsEntities, organizations => ({ organizations }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationRelations, 'organization'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizations: Dictionary<OrganizationEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  users?: Dictionary<UserEntityState>;
  organizationMetiers?: Dictionary<OrganizationMetierEntityState>;
  organizationSocietes?: Dictionary<OrganizationSocieteEntityState>;
  organizationLeadTodos?: Dictionary<OrganizationLeadTodoEntityState>;
  organizationLeadTodoRules?: Dictionary<OrganizationLeadTodoRuleEntityState>;
  organizationStratalotTodoRules?: Dictionary<OrganizationStratalotTodoRuleEntityState>;
  organizationResidenceTodoRules?: Dictionary<OrganizationResidenceTodoRuleEntityState>;
  organizationResidenceTodos?: Dictionary<OrganizationResidenceTodoEntityState>;
  organizationStratalotTodos?: Dictionary<OrganizationStratalotTodoEntityState>;
  organizationResidenceStudyTodos?: Dictionary<OrganizationResidenceStudyTodoEntityState>;
  organizationProfils?: Dictionary<OrganizationProfilEntityState>;
  organizationProspectOrigins?: Dictionary<OrganizationProspectOriginEntityState>;
  prospects?: Dictionary<ProspectEntityState>;
  profils?: Dictionary<ProfilEntityState>;
  generatedDocuments?: Dictionary<GeneratedDocumentEntityState>;
  organizationThirdParties?: Dictionary<OrganizationThirdPartyEntityState>;
  organizationStratalotAvancements?: Dictionary<OrganizationStratalotAvancementEntityState>;
  organizationLeadAvancements?: Dictionary<OrganizationLeadAvancementEntityState>;
  organizationSaleCategories?: Dictionary<OrganizationSaleCategoryEntityState>;
  organizationBuyingWishes?: Dictionary<OrganizationBuyingWishEntityState>;
  organizationEnergies?: Dictionary<OrganizationEnergieEntityState>;
  organizationForecastRates?: Dictionary<OrganizationForecastRateEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizations: (Organization | null)[] } {
  const {
    organizations,
    companies,
    users,
    organizationMetiers,
    organizationSocietes,
    organizationLeadTodos,
    organizationLeadTodoRules,
    organizationStratalotTodoRules,
    organizationResidenceTodoRules,
    organizationResidenceTodos,
    organizationStratalotTodos,
    organizationResidenceStudyTodos,
    organizationProfils,
    organizationProspectOrigins,
    prospects,
    profils,
    generatedDocuments,
    organizationThirdParties,
    organizationStratalotAvancements,
    organizationLeadAvancements,
    organizationSaleCategories,
    organizationBuyingWishes,
    organizationEnergies,
    organizationForecastRates
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizations: Object.keys(organizations).map(idOrganization =>
      hydrate(
        organizations[idOrganization] as OrganizationEntityState,
        companies,
        users,
        organizationMetiers,
        organizationSocietes,
        organizationLeadTodos,
        organizationLeadTodoRules,
        organizationStratalotTodoRules,
        organizationResidenceTodoRules,
        organizationResidenceTodos,
        organizationStratalotTodos,
        organizationResidenceStudyTodos,
        organizationProfils,
        organizationProspectOrigins,
        prospects,
        profils,
        generatedDocuments,
        organizationThirdParties,
        organizationStratalotAvancements,
        organizationLeadAvancements,
        organizationSaleCategories,
        organizationBuyingWishes,
        organizationEnergies,
        organizationForecastRates
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organization: OrganizationEntityState | null } {
  const {
    organizations,
    companies,
    users,
    organizationMetiers,
    organizationSocietes,
    organizationLeadTodos,
    organizationLeadTodoRules,
    organizationStratalotTodoRules,
    organizationResidenceTodoRules,
    organizationResidenceTodos,
    organizationStratalotTodos,
    organizationResidenceStudyTodos,
    organizationProfils,
    organizationProspectOrigins,
    prospects,
    profils,
    generatedDocuments,
    organizationThirdParties,
    organizationStratalotAvancements,
    organizationLeadAvancements,
    organizationSaleCategories,
    organizationBuyingWishes,
    organizationEnergies,
    organizationForecastRates
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organization = Object.values(organizations)[0];
  return {
    organization: hydrate(
      organization as OrganizationEntityState,
      companies,
      users,
      organizationMetiers,
      organizationSocietes,
      organizationLeadTodos,
      organizationLeadTodoRules,
      organizationStratalotTodoRules,
      organizationResidenceTodoRules,
      organizationResidenceTodos,
      organizationStratalotTodos,
      organizationResidenceStudyTodos,
      organizationProfils,
      organizationProspectOrigins,
      prospects,
      profils,
      generatedDocuments,
      organizationThirdParties,
      organizationStratalotAvancements,
      organizationLeadAvancements,
      organizationSaleCategories,
      organizationBuyingWishes,
      organizationEnergies,
      organizationForecastRates
    )
  };
}

function hydrate(
  organization: OrganizationEntityState,
  companyEntities?: Dictionary<CompanyEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  organizationMetierEntities?: Dictionary<OrganizationMetierEntityState>,
  organizationSocieteEntities?: Dictionary<OrganizationSocieteEntityState>,
  organizationLeadTodoEntities?: Dictionary<OrganizationLeadTodoEntityState>,
  organizationLeadTodoRuleEntities?: Dictionary<OrganizationLeadTodoRuleEntityState>,
  organizationStratalotTodoRuleEntities?: Dictionary<OrganizationStratalotTodoRuleEntityState>,
  organizationResidenceTodoRuleEntities?: Dictionary<OrganizationResidenceTodoRuleEntityState>,
  organizationResidenceTodoEntities?: Dictionary<OrganizationResidenceTodoEntityState>,
  organizationStratalotTodoEntities?: Dictionary<OrganizationStratalotTodoEntityState>,
  organizationResidenceStudyTodoEntities?: Dictionary<OrganizationResidenceStudyTodoEntityState>,
  organizationProfilEntities?: Dictionary<OrganizationProfilEntityState>,
  organizationProspectOriginEntities?: Dictionary<OrganizationProspectOriginEntityState>,
  prospectEntities?: Dictionary<ProspectEntityState>,
  profilEntities?: Dictionary<ProfilEntityState>,
  generatedDocumentEntities?: Dictionary<GeneratedDocumentEntityState>,
  organizationThirdPartyEntities?: Dictionary<OrganizationThirdPartyEntityState>,
  organizationStratalotAvancementEntities?: Dictionary<OrganizationStratalotAvancementEntityState>,
  organizationLeadAvancementEntities?: Dictionary<OrganizationLeadAvancementEntityState>,
  organizationSaleCategoryEntities?: Dictionary<OrganizationSaleCategoryEntityState>,
  organizationBuyingWishEntities?: Dictionary<OrganizationBuyingWishEntityState>,
  organizationEnergieEntities?: Dictionary<OrganizationEnergieEntityState>,
  organizationForecastRateEntities?: Dictionary<OrganizationForecastRateEntityState>
): Organization | null {
  if (!organization) {
    return null;
  }

  const organizationHydrated: OrganizationEntityState = { ...organization };

  if (companyEntities) {
    organizationHydrated.companies = ((organizationHydrated.companies as number[]) || []).map(
      id => companyEntities[id]
    ) as Company[];
  } else {
    delete organizationHydrated.companies;
  }

  if (userEntities) {
    organizationHydrated.users = ((organizationHydrated.users as number[]) || []).map(id => userEntities[id]) as User[];
  } else {
    delete organizationHydrated.users;
  }

  if (organizationMetierEntities) {
    organizationHydrated.organizationMetiers = ((organizationHydrated.organizationMetiers as number[]) || []).map(
      id => organizationMetierEntities[id]
    ) as OrganizationMetier[];
  } else {
    delete organizationHydrated.organizationMetiers;
  }

  if (organizationSocieteEntities) {
    organizationHydrated.organizationSocietes = ((organizationHydrated.organizationSocietes as number[]) || []).map(
      id => organizationSocieteEntities[id]
    ) as OrganizationSociete[];
  } else {
    delete organizationHydrated.organizationSocietes;
  }

  if (organizationLeadTodoEntities) {
    organizationHydrated.organizationLeadTodos = ((organizationHydrated.organizationLeadTodos as number[]) || []).map(
      id => organizationLeadTodoEntities[id]
    ) as OrganizationLeadTodo[];
  } else {
    delete organizationHydrated.organizationLeadTodos;
  }

  if (organizationLeadTodoRuleEntities) {
    organizationHydrated.organizationLeadTodoRules = (
      (organizationHydrated.organizationLeadTodoRules as number[]) || []
    ).map(id => organizationLeadTodoRuleEntities[id]) as OrganizationLeadTodoRule[];
  } else {
    delete organizationHydrated.organizationLeadTodoRules;
  }

  if (organizationStratalotTodoRuleEntities) {
    organizationHydrated.organizationStratalotTodoRules = (
      (organizationHydrated.organizationStratalotTodoRules as number[]) || []
    ).map(id => organizationStratalotTodoRuleEntities[id]) as OrganizationStratalotTodoRule[];
  } else {
    delete organizationHydrated.organizationStratalotTodoRules;
  }

  if (organizationResidenceTodoRuleEntities) {
    organizationHydrated.organizationResidenceTodoRules = (
      (organizationHydrated.organizationResidenceTodoRules as number[]) || []
    ).map(id => organizationResidenceTodoRuleEntities[id]) as OrganizationResidenceTodoRule[];
  } else {
    delete organizationHydrated.organizationResidenceTodoRules;
  }

  if (organizationResidenceTodoEntities) {
    organizationHydrated.organizationResidenceTodos = (
      (organizationHydrated.organizationResidenceTodos as number[]) || []
    ).map(id => organizationResidenceTodoEntities[id]) as OrganizationResidenceTodo[];
  } else {
    delete organizationHydrated.organizationResidenceTodos;
  }

  if (organizationStratalotTodoEntities) {
    organizationHydrated.organizationStratalotTodos = (
      (organizationHydrated.organizationStratalotTodos as number[]) || []
    ).map(id => organizationStratalotTodoEntities[id]) as OrganizationStratalotTodo[];
  } else {
    delete organizationHydrated.organizationStratalotTodos;
  }

  if (organizationResidenceStudyTodoEntities) {
    organizationHydrated.organizationResidenceStudyTodos = (
      (organizationHydrated.organizationResidenceStudyTodos as number[]) || []
    ).map(id => organizationResidenceStudyTodoEntities[id]) as OrganizationResidenceStudyTodo[];
  } else {
    delete organizationHydrated.organizationResidenceStudyTodos;
  }

  if (organizationProfilEntities) {
    organizationHydrated.organizationProfils = ((organizationHydrated.organizationProfils as number[]) || []).map(
      id => organizationProfilEntities[id]
    ) as OrganizationProfil[];
  } else {
    delete organizationHydrated.organizationProfils;
  }

  if (organizationProspectOriginEntities) {
    organizationHydrated.organizationProspectOrigins = (
      (organizationHydrated.organizationProspectOrigins as number[]) || []
    ).map(id => organizationProspectOriginEntities[id]) as OrganizationProspectOrigin[];
  } else {
    delete organizationHydrated.organizationProspectOrigins;
  }

  if (prospectEntities) {
    organizationHydrated.prospects = ((organizationHydrated.prospects as number[]) || []).map(
      id => prospectEntities[id]
    ) as Prospect[];
  } else {
    delete organizationHydrated.prospects;
  }

  if (profilEntities) {
    organizationHydrated.profils = ((organizationHydrated.profils as number[]) || []).map(
      id => profilEntities[id]
    ) as Profil[];
  } else {
    delete organizationHydrated.profils;
  }

  if (generatedDocumentEntities) {
    organizationHydrated.generatedDocuments = ((organizationHydrated.generatedDocuments as number[]) || []).map(
      id => generatedDocumentEntities[id]
    ) as GeneratedDocument[];
  } else {
    delete organizationHydrated.generatedDocuments;
  }

  if (organizationThirdPartyEntities) {
    organizationHydrated.organizationThirdParties = (
      (organizationHydrated.organizationThirdParties as number[]) || []
    ).map(id => organizationThirdPartyEntities[id]) as OrganizationThirdParty[];
  } else {
    delete organizationHydrated.organizationThirdParties;
  }

  if (organizationStratalotAvancementEntities) {
    organizationHydrated.organizationStratalotAvancements = (
      (organizationHydrated.organizationStratalotAvancements as number[]) || []
    ).map(id => organizationStratalotAvancementEntities[id]) as OrganizationStratalotAvancement[];
  } else {
    delete organizationHydrated.organizationStratalotAvancements;
  }

  if (organizationLeadAvancementEntities) {
    organizationHydrated.organizationLeadAvancements = (
      (organizationHydrated.organizationLeadAvancements as number[]) || []
    ).map(id => organizationLeadAvancementEntities[id]) as OrganizationLeadAvancement[];
  } else {
    delete organizationHydrated.organizationLeadAvancements;
  }

  if (organizationSaleCategoryEntities) {
    organizationHydrated.organizationSaleCategories = (
      (organizationHydrated.organizationSaleCategories as number[]) || []
    ).map(id => organizationSaleCategoryEntities[id]) as OrganizationSaleCategory[];
  } else {
    delete organizationHydrated.organizationSaleCategories;
  }

  if (organizationBuyingWishEntities) {
    organizationHydrated.organizationBuyingWishes = (
      (organizationHydrated.organizationBuyingWishes as number[]) || []
    ).map(id => organizationBuyingWishEntities[id]) as OrganizationBuyingWish[];
  } else {
    delete organizationHydrated.organizationBuyingWishes;
  }

  if (organizationEnergieEntities) {
    organizationHydrated.organizationEnergies = ((organizationHydrated.organizationEnergies as number[]) || []).map(
      id => organizationEnergieEntities[id]
    ) as OrganizationEnergie[];
  } else {
    delete organizationHydrated.organizationEnergies;
  }

  if (organizationForecastRateEntities) {
    organizationHydrated.organizationForecastRates = (
      (organizationHydrated.organizationForecastRates as number[]) || []
    ).map(id => organizationForecastRateEntities[id]) as OrganizationForecastRate[];
  } else {
    delete organizationHydrated.organizationForecastRates;
  }

  return organizationHydrated as Organization;
}
