import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { LeadStratalot, LeadStratalotEntityState } from '@_model/interfaces/lead-stratalot.model';
import { LeadStratalotRelationsIds } from './lead-stratalot-generated.effects';

export const getOneLeadStratalot = createAction(
  '[LeadStratalot] Get One LeadStratalot',
  props<{ idLeadStratalot: number; params?: any }>()
);

export const getManyLeadStratalots = createAction('[LeadStratalot] Get Many LeadStratalots', props<{ params: any }>());

export const addManyActives = createAction(
  '[LeadStratalot] Add Many Actives LeadStratalot',
  props<{ idLeadStratalots: number[] }>()
);

export const deleteOneActive = createAction(
  '[LeadStratalot] Delete One Active LeadStratalot',
  props<{ idLeadStratalot: number }>()
);

export const clearActive = createAction('[LeadStratalot] Clear Active LeadStratalot');

export const upsertOneLeadStratalot = createAction(
  '[LeadStratalot] Upsert One LeadStratalot',
  props<{
    leadStratalot: Partial<LeadStratalot>;
    ids?: LeadStratalotRelationsIds;
  }>()
);

export const upsertManyLeadStratalots = createAction(
  '[LeadStratalot] Upsert Many LeadStratalots',
  props<{
    leadStratalots: Partial<LeadStratalot>[];
    ids?: LeadStratalotRelationsIds;
  }>()
);

export const upsertManyLeadStratalotsSuccess = createAction(
  '[LeadStratalot] Create Many LeadStratalots Success',
  props<{ leadStratalots: LeadStratalotEntityState[] }>()
);

export const deleteOneLeadStratalot = createAction(
  '[LeadStratalot] Delete One LeadStratalot',
  props<{ idLeadStratalot: number }>()
);

export const deleteOneLeadStratalotSuccess = createAction(
  '[LeadStratalot] Delete One LeadStratalot Success',
  props<{ idLeadStratalot: number }>()
);

export const normalizeManyLeadStratalotsAfterUpsert = createAction(
  '[LeadStratalot] Normalize Many LeadStratalots After Upsert',
  props<{ leadStratalots: LeadStratalotEntityState[] }>()
);

export const leadStratalotsFailure = createAction(
  '[LeadStratalot] LeadStratalots Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[LeadStratalot] Clear LeadStratalots');

export const addLeadSuccess = createAction(
  '[LeadStratalot] Add Lead',
  props<{ idLeadStratalot: number; idLead: number }>()
);

export const deleteManyLeadSuccess = createAction(
  '[LeadStratalot] Delete Many Lead',
  props<{ idLeads: number[]; idLeadStratalots: number[] }>()
);

export const addStratalotSuccess = createAction(
  '[LeadStratalot] Add Stratalot',
  props<{ idLeadStratalot: number; idStratalot: number }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[LeadStratalot] Delete Many Stratalot',
  props<{ idStratalots: number[]; idLeadStratalots: number[] }>()
);
