import { CompanyCommunicationRecipientUserState, initialState } from './company-communication-recipient-user.state';
import { Action, createReducer } from '@ngrx/store';
import { companyCommunicationRecipientUserReducersGeneratedFunctions } from './company-communication-recipient-user-generated.reducer';

const companyCommunicationRecipientUserReducersFunctions = [
  ...companyCommunicationRecipientUserReducersGeneratedFunctions
];

const companyCommunicationRecipientUserReducer = createReducer(
  initialState,
  ...companyCommunicationRecipientUserReducersFunctions
);

export function reducer(state: CompanyCommunicationRecipientUserState | undefined, action: Action) {
  return companyCommunicationRecipientUserReducer(state, action);
}
