import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationResidenceTodo,
  OrganizationResidenceTodoEntityState
} from '@_model/interfaces/organization-residence-todo.model';
import { OrganizationResidenceTodoRelationsIds } from './organization-residence-todo-generated.effects';

export const getOneOrganizationResidenceTodo = createAction(
  '[OrganizationResidenceTodo] Get One OrganizationResidenceTodo',
  props<{ idOrganizationResidenceTodo: number; params?: any }>()
);

export const getManyOrganizationResidenceTodos = createAction(
  '[OrganizationResidenceTodo] Get Many OrganizationResidenceTodos',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationResidenceTodo] Add Many Actives OrganizationResidenceTodo',
  props<{ idOrganizationResidenceTodos: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationResidenceTodo] Delete One Active OrganizationResidenceTodo',
  props<{ idOrganizationResidenceTodo: number }>()
);

export const clearActive = createAction('[OrganizationResidenceTodo] Clear Active OrganizationResidenceTodo');

export const upsertOneOrganizationResidenceTodo = createAction(
  '[OrganizationResidenceTodo] Upsert One OrganizationResidenceTodo',
  props<{
    organizationResidenceTodo: Partial<OrganizationResidenceTodo>;
    ids?: OrganizationResidenceTodoRelationsIds;
  }>()
);

export const upsertManyOrganizationResidenceTodos = createAction(
  '[OrganizationResidenceTodo] Upsert Many OrganizationResidenceTodos',
  props<{
    organizationResidenceTodos: Partial<OrganizationResidenceTodo>[];
    ids?: OrganizationResidenceTodoRelationsIds;
  }>()
);

export const upsertManyOrganizationResidenceTodosSuccess = createAction(
  '[OrganizationResidenceTodo] Create Many OrganizationResidenceTodos Success',
  props<{ organizationResidenceTodos: OrganizationResidenceTodoEntityState[] }>()
);

export const deleteOneOrganizationResidenceTodo = createAction(
  '[OrganizationResidenceTodo] Delete One OrganizationResidenceTodo',
  props<{ idOrganizationResidenceTodo: number }>()
);

export const deleteOneOrganizationResidenceTodoSuccess = createAction(
  '[OrganizationResidenceTodo] Delete One OrganizationResidenceTodo Success',
  props<{ idOrganizationResidenceTodo: number }>()
);

export const normalizeManyOrganizationResidenceTodosAfterUpsert = createAction(
  '[OrganizationResidenceTodo] Normalize Many OrganizationResidenceTodos After Upsert',
  props<{ organizationResidenceTodos: OrganizationResidenceTodoEntityState[] }>()
);

export const organizationResidenceTodosFailure = createAction(
  '[OrganizationResidenceTodo] OrganizationResidenceTodos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationResidenceTodo] Clear OrganizationResidenceTodos');

export const addManyResidenceTodoSuccess = createAction(
  '[OrganizationResidenceTodo] Add Many residence-todo',
  props<{ idOrganizationResidenceTodo: number; idResidenceTodos: number[] }>()
);

export const deleteManyResidenceTodoSuccess = createAction(
  '[OrganizationResidenceTodo] Delete Many ResidenceTodos',
  props<{ idResidenceTodos: number[]; idOrganizationResidenceTodos: number[] }>()
);

export const addManyOrganizationResidenceTodoRuleSuccess = createAction(
  '[OrganizationResidenceTodo] Add Many organization-residence-todo-rule',
  props<{ idOrganizationResidenceTodo: number; idOrganizationResidenceTodoRules: number[] }>()
);

export const deleteManyOrganizationResidenceTodoRuleSuccess = createAction(
  '[OrganizationResidenceTodo] Delete Many OrganizationResidenceTodoRules',
  props<{ idOrganizationResidenceTodoRules: number[]; idOrganizationResidenceTodos: number[] }>()
);

export const addManyOrganizationResidenceTodoAppliedRuleSuccess = createAction(
  '[OrganizationResidenceTodo] Add Many organization-residence-todo-applied-rule',
  props<{ idOrganizationResidenceTodo: number; idOrganizationResidenceTodoAppliedRules: number[] }>()
);

export const deleteManyOrganizationResidenceTodoAppliedRuleSuccess = createAction(
  '[OrganizationResidenceTodo] Delete Many OrganizationResidenceTodoAppliedRules',
  props<{ idOrganizationResidenceTodoAppliedRules: number[]; idOrganizationResidenceTodos: number[] }>()
);

export const addManyOrganizationResidenceTodoProfilSuccess = createAction(
  '[OrganizationResidenceTodo] Add Many organization-residence-todo-profil',
  props<{ idOrganizationResidenceTodo: number; idOrganizationResidenceTodoProfils: number[] }>()
);

export const deleteManyOrganizationResidenceTodoProfilSuccess = createAction(
  '[OrganizationResidenceTodo] Delete Many OrganizationResidenceTodoProfils',
  props<{ idOrganizationResidenceTodoProfils: number[]; idOrganizationResidenceTodos: number[] }>()
);

export const addManyCompanyCommunicationSuccess = createAction(
  '[OrganizationResidenceTodo] Add Many company-communication',
  props<{ idOrganizationResidenceTodo: number; idCompanyCommunications: number[] }>()
);

export const deleteManyCompanyCommunicationSuccess = createAction(
  '[OrganizationResidenceTodo] Delete Many CompanyCommunications',
  props<{ idCompanyCommunications: number[]; idOrganizationResidenceTodos: number[] }>()
);

export const addManyCompanyCommunicationResidenceTodoSuccess = createAction(
  '[OrganizationResidenceTodo] Add Many company-communication-residence-todo',
  props<{ idOrganizationResidenceTodo: number; idCompanyCommunicationResidenceTodos: number[] }>()
);

export const deleteManyCompanyCommunicationResidenceTodoSuccess = createAction(
  '[OrganizationResidenceTodo] Delete Many CompanyCommunicationResidenceTodos',
  props<{ idCompanyCommunicationResidenceTodos: number[]; idOrganizationResidenceTodos: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[OrganizationResidenceTodo] Add Organization',
  props<{ idOrganizationResidenceTodo: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationResidenceTodo] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationResidenceTodos: number[] }>()
);

export const addDiagnosticSuccess = createAction(
  '[OrganizationResidenceTodo] Add Diagnostic',
  props<{ idOrganizationResidenceTodo: number; idDiagnostic: number }>()
);

export const deleteManyDiagnosticSuccess = createAction(
  '[OrganizationResidenceTodo] Delete Many Diagnostic',
  props<{ idDiagnostics: number[]; idOrganizationResidenceTodos: number[] }>()
);
