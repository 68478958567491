import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationResidenceStudyTodoEntityState } from '@_model/interfaces/organization-residence-study-todo.model';

export interface OrganizationResidenceStudyTodoState extends EntityState<OrganizationResidenceStudyTodoEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationResidenceStudyTodoEntityState> =
  createEntityAdapter<OrganizationResidenceStudyTodoEntityState>({
    selectId: o => o.idOrganizationResidenceStudyTodo
  });
export const initialState: OrganizationResidenceStudyTodoState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationResidenceStudyTodoFeatureKey = 'organizationResidenceStudyTodo';
