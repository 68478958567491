import { RepositoryService } from '@_services/api/repository.service';
import { MetierFamilie } from '@_model/interfaces/metier-familie.model';
import { Observable } from 'rxjs';

export class GeneratedMetierFamilieApiService {
  constructor(protected repo: RepositoryService) {}

  public getMetierFamilies(params?: any): Observable<MetierFamilie[]> {
    return this.repo.getData<MetierFamilie[]>('metier-familie', params);
  }

  public getMetierFamilie(params: { idMetierFamilie: number; params?: any }): Observable<MetierFamilie> {
    return this.repo.getData<MetierFamilie>('metier-familie/' + params.idMetierFamilie, params.params);
  }

  public addMetierFamilie(metierFamilie: Partial<MetierFamilie>): Observable<MetierFamilie> {
    return this.repo.create<MetierFamilie>('metier-familie', metierFamilie);
  }

  public updateMetierFamilie(metierFamilie: Partial<MetierFamilie>): Observable<MetierFamilie> {
    return this.repo.update('metier-familie', metierFamilie);
  }

  public deleteMetierFamilie(idMetierFamilie: number): Observable<number> {
    return this.repo.delete('metier-familie/' + +idMetierFamilie);
  }
}
