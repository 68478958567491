import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { StratalotRcp, StratalotRcpEntityState } from '@_model/interfaces/stratalot-rcp.model';
import { StratalotRcpRelationsIds } from './stratalot-rcp-generated.effects';

export const getOneStratalotRcp = createAction(
  '[StratalotRcp] Get One StratalotRcp',
  props<{ idStratalotRcp: number; params?: any }>()
);

export const getManyStratalotRcps = createAction('[StratalotRcp] Get Many StratalotRcps', props<{ params: any }>());

export const addManyActives = createAction(
  '[StratalotRcp] Add Many Actives StratalotRcp',
  props<{ idStratalotRcps: number[] }>()
);

export const deleteOneActive = createAction(
  '[StratalotRcp] Delete One Active StratalotRcp',
  props<{ idStratalotRcp: number }>()
);

export const clearActive = createAction('[StratalotRcp] Clear Active StratalotRcp');

export const upsertOneStratalotRcp = createAction(
  '[StratalotRcp] Upsert One StratalotRcp',
  props<{
    stratalotRcp: Partial<StratalotRcp>;
    ids?: StratalotRcpRelationsIds;
  }>()
);

export const upsertManyStratalotRcps = createAction(
  '[StratalotRcp] Upsert Many StratalotRcps',
  props<{
    stratalotRcps: Partial<StratalotRcp>[];
    ids?: StratalotRcpRelationsIds;
  }>()
);

export const upsertManyStratalotRcpsSuccess = createAction(
  '[StratalotRcp] Create Many StratalotRcps Success',
  props<{ stratalotRcps: StratalotRcpEntityState[] }>()
);

export const deleteOneStratalotRcp = createAction(
  '[StratalotRcp] Delete One StratalotRcp',
  props<{ idStratalotRcp: number }>()
);

export const deleteOneStratalotRcpSuccess = createAction(
  '[StratalotRcp] Delete One StratalotRcp Success',
  props<{ idStratalotRcp: number }>()
);

export const normalizeManyStratalotRcpsAfterUpsert = createAction(
  '[StratalotRcp] Normalize Many StratalotRcps After Upsert',
  props<{ stratalotRcps: StratalotRcpEntityState[] }>()
);

export const stratalotRcpsFailure = createAction(
  '[StratalotRcp] StratalotRcps Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[StratalotRcp] Clear StratalotRcps');

export const addManyResidenceShareSuccess = createAction(
  '[StratalotRcp] Add Many residence-share',
  props<{ idStratalotRcp: number; idResidenceShares: number[] }>()
);

export const deleteManyResidenceShareSuccess = createAction(
  '[StratalotRcp] Delete Many ResidenceShares',
  props<{ idResidenceShares: number[]; idStratalotRcps: number[] }>()
);

export const addManyResidenceShareRcpSuccess = createAction(
  '[StratalotRcp] Add Many residence-share-rcp',
  props<{ idStratalotRcp: number; idResidenceShareRcps: number[] }>()
);

export const deleteManyResidenceShareRcpSuccess = createAction(
  '[StratalotRcp] Delete Many ResidenceShareRcps',
  props<{ idResidenceShareRcps: number[]; idStratalotRcps: number[] }>()
);

export const addResidenceSuccess = createAction(
  '[StratalotRcp] Add Residence',
  props<{ idStratalotRcp: number; idResidence: number }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[StratalotRcp] Delete Many Residence',
  props<{ idResidences: number[]; idStratalotRcps: number[] }>()
);

export const addStratalotSuccess = createAction(
  '[StratalotRcp] Add Stratalot',
  props<{ idStratalotRcp: number; idStratalot: number }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[StratalotRcp] Delete Many Stratalot',
  props<{ idStratalots: number[]; idStratalotRcps: number[] }>()
);
