import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CompanyCommunicationRecipientUser,
  CompanyCommunicationRecipientUserEntityState
} from '@_model/interfaces/company-communication-recipient-user.model';
import {
  CompanyCommunicationRecipient,
  CompanyCommunicationRecipientEntityState
} from '@_model/interfaces/company-communication-recipient.model';
import { User, UserEntityState } from '@_model/interfaces/user.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  companyCommunicationRecipientUserFeatureKey,
  CompanyCommunicationRecipientUserState
} from './company-communication-recipient-user.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyCommunicationRecipientUserRelations: string[] = ['companyCommunicationRecipients', 'users'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyCommunicationRecipientUserState =
  createFeatureSelector<CompanyCommunicationRecipientUserState>(companyCommunicationRecipientUserFeatureKey);

export const selectIsLoadedCompanyCommunicationRecipientUser = createSelector(
  selectCompanyCommunicationRecipientUserState,
  (state: CompanyCommunicationRecipientUserState) => state.isLoaded
);

export const selectIsLoadingCompanyCommunicationRecipientUser = createSelector(
  selectCompanyCommunicationRecipientUserState,
  (state: CompanyCommunicationRecipientUserState) => state.isLoading
);

export const selectIsReadyCompanyCommunicationRecipientUser = createSelector(
  selectCompanyCommunicationRecipientUserState,
  (state: CompanyCommunicationRecipientUserState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyCommunicationRecipientUser = createSelector(
  selectCompanyCommunicationRecipientUserState,
  (state: CompanyCommunicationRecipientUserState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyCommunicationRecipientUserModel: SelectorModel = {
  name: 'companyCommunicationRecipientUsers',
  getSelector: selectAllCompanyCommunicationRecipientUsersDictionary,
  isReady: selectIsReadyCompanyCommunicationRecipientUser
};

export const selectCompanyCommunicationRecipientUsersEntities = createSelector(
  selectCompanyCommunicationRecipientUserState,
  selectEntities
);

export const selectCompanyCommunicationRecipientUsersArray = createSelector(
  selectCompanyCommunicationRecipientUserState,
  selectAll
);

export const selectIdCompanyCommunicationRecipientUsersActive = createSelector(
  selectCompanyCommunicationRecipientUserState,
  (state: CompanyCommunicationRecipientUserState) => state.actives
);

const companyCommunicationRecipientUsersInObject = (
  companyCommunicationRecipientUsers: Dictionary<CompanyCommunicationRecipientUserEntityState>
) => ({ companyCommunicationRecipientUsers });

const selectCompanyCommunicationRecipientUsersEntitiesDictionary = createSelector(
  selectCompanyCommunicationRecipientUsersEntities,
  companyCommunicationRecipientUsersInObject
);

const selectAllCompanyCommunicationRecipientUsersObject = createSelector(
  selectCompanyCommunicationRecipientUsersEntities,
  companyCommunicationRecipientUsers => {
    return hydrateAll({ companyCommunicationRecipientUsers });
  }
);

const selectOneCompanyCommunicationRecipientUserDictionary = (idCompanyCommunicationRecipientUser: number) =>
  createSelector(selectCompanyCommunicationRecipientUsersEntities, companyCommunicationRecipientUsers => {
    return {
      companyCommunicationRecipientUsers: {
        [idCompanyCommunicationRecipientUser]: companyCommunicationRecipientUsers[idCompanyCommunicationRecipientUser]
      }
    };
  });

const selectOneCompanyCommunicationRecipientUserDictionaryWithoutChild = (
  idCompanyCommunicationRecipientUser: number
) =>
  createSelector(selectCompanyCommunicationRecipientUsersEntities, companyCommunicationRecipientUsers => {
    return {
      companyCommunicationRecipientUser: companyCommunicationRecipientUsers[idCompanyCommunicationRecipientUser]
    };
  });

const selectActiveCompanyCommunicationRecipientUsersEntities = createSelector(
  selectIdCompanyCommunicationRecipientUsersActive,
  selectCompanyCommunicationRecipientUsersEntities,
  (actives: number[], companyCommunicationRecipientUsers: Dictionary<CompanyCommunicationRecipientUserEntityState>) =>
    getCompanyCommunicationRecipientUsersFromActives(actives, companyCommunicationRecipientUsers)
);

function getCompanyCommunicationRecipientUsersFromActives(
  actives: number[],
  companyCommunicationRecipientUsers: Dictionary<CompanyCommunicationRecipientUserEntityState>
): Dictionary<CompanyCommunicationRecipientUserEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyCommunicationRecipientUsers[idActive]) {
      acc[idActive] = companyCommunicationRecipientUsers[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyCommunicationRecipientUserEntityState>);
}

const selectAllCompanyCommunicationRecipientUsersSelectors: Dictionary<Selector> = {};
export function selectAllCompanyCommunicationRecipientUsers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyCommunicationRecipientUser>(
      schema,
      selectAllCompanyCommunicationRecipientUsersSelectors,
      selectCompanyCommunicationRecipientUsersEntitiesDictionary,
      getRelationSelectors,
      companyCommunicationRecipientUserRelations,
      hydrateAll,
      'companyCommunicationRecipientUser'
    );
  } else {
    return selectAllCompanyCommunicationRecipientUsersObject;
  }
}

export function selectAllCompanyCommunicationRecipientUsersDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyCommunicationRecipientUsers'
): Selector {
  return createSelector(selectAllCompanyCommunicationRecipientUsers(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyCommunicationRecipientUserEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyCommunicationRecipientUsers.length; i++) {
      res[customKey][result.companyCommunicationRecipientUsers[i].idCompanyCommunicationRecipientUser] =
        result.companyCommunicationRecipientUsers[i];
    }
    return res;
  });
}

export function selectOneCompanyCommunicationRecipientUser(
  schema: SelectSchema = {},
  idCompanyCommunicationRecipientUser: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [
      selectOneCompanyCommunicationRecipientUserDictionary(idCompanyCommunicationRecipientUser)
    ];
    selectors.push(
      ...getRelationSelectors(schema, companyCommunicationRecipientUserRelations, 'companyCommunicationRecipientUser')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyCommunicationRecipientUserDictionaryWithoutChild(idCompanyCommunicationRecipientUser);
  }
}

export function selectActiveCompanyCommunicationRecipientUsers(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyCommunicationRecipientUsersEntities, companyCommunicationRecipientUsers => ({
      companyCommunicationRecipientUsers
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, companyCommunicationRecipientUserRelations, 'companyCommunicationRecipientUser')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyCommunicationRecipientUsers: Dictionary<CompanyCommunicationRecipientUserEntityState>;
  companyCommunicationRecipients?: Dictionary<CompanyCommunicationRecipientEntityState>;
  users?: Dictionary<UserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  companyCommunicationRecipientUsers: (CompanyCommunicationRecipientUser | null)[];
} {
  const { companyCommunicationRecipientUsers, companyCommunicationRecipients, users } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    companyCommunicationRecipientUsers: Object.keys(companyCommunicationRecipientUsers).map(
      idCompanyCommunicationRecipientUser =>
        hydrate(
          companyCommunicationRecipientUsers[
            idCompanyCommunicationRecipientUser
          ] as CompanyCommunicationRecipientUserEntityState,
          companyCommunicationRecipients,
          users
        )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  companyCommunicationRecipientUser: CompanyCommunicationRecipientUserEntityState | null;
} {
  const { companyCommunicationRecipientUsers, companyCommunicationRecipients, users } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const companyCommunicationRecipientUser = Object.values(companyCommunicationRecipientUsers)[0];
  return {
    companyCommunicationRecipientUser: hydrate(
      companyCommunicationRecipientUser as CompanyCommunicationRecipientUserEntityState,
      companyCommunicationRecipients,
      users
    )
  };
}

function hydrate(
  companyCommunicationRecipientUser: CompanyCommunicationRecipientUserEntityState,
  companyCommunicationRecipientEntities?: Dictionary<CompanyCommunicationRecipientEntityState>,
  userEntities?: Dictionary<UserEntityState>
): CompanyCommunicationRecipientUser | null {
  if (!companyCommunicationRecipientUser) {
    return null;
  }

  const companyCommunicationRecipientUserHydrated: CompanyCommunicationRecipientUserEntityState = {
    ...companyCommunicationRecipientUser
  };
  if (companyCommunicationRecipientEntities) {
    companyCommunicationRecipientUserHydrated.companyCommunicationRecipient = companyCommunicationRecipientEntities[
      companyCommunicationRecipientUser.companyCommunicationRecipient as number
    ] as CompanyCommunicationRecipient;
  } else {
    delete companyCommunicationRecipientUserHydrated.companyCommunicationRecipient;
  }
  if (userEntities) {
    companyCommunicationRecipientUserHydrated.user = userEntities[
      companyCommunicationRecipientUser.user as number
    ] as User;
  } else {
    delete companyCommunicationRecipientUserHydrated.user;
  }

  return companyCommunicationRecipientUserHydrated as CompanyCommunicationRecipientUser;
}
