import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationBuyingWish,
  OrganizationBuyingWishEntityState
} from '@_model/interfaces/organization-buying-wish.model';
import { ProspectBuyingWish, ProspectBuyingWishEntityState } from '@_model/interfaces/prospect-buying-wish.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationBuyingWishFeatureKey,
  OrganizationBuyingWishState
} from './organization-buying-wish.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationBuyingWishRelations: string[] = ['prospectBuyingWishs', 'organizations'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationBuyingWishState = createFeatureSelector<OrganizationBuyingWishState>(
  organizationBuyingWishFeatureKey
);

export const selectIsLoadedOrganizationBuyingWish = createSelector(
  selectOrganizationBuyingWishState,
  (state: OrganizationBuyingWishState) => state.isLoaded
);

export const selectIsLoadingOrganizationBuyingWish = createSelector(
  selectOrganizationBuyingWishState,
  (state: OrganizationBuyingWishState) => state.isLoading
);

export const selectIsReadyOrganizationBuyingWish = createSelector(
  selectOrganizationBuyingWishState,
  (state: OrganizationBuyingWishState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationBuyingWish = createSelector(
  selectOrganizationBuyingWishState,
  (state: OrganizationBuyingWishState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationBuyingWishModel: SelectorModel = {
  name: 'organizationBuyingWishes',
  getSelector: selectAllOrganizationBuyingWishesDictionary,
  isReady: selectIsReadyOrganizationBuyingWish
};

export const selectOrganizationBuyingWishesEntities = createSelector(selectOrganizationBuyingWishState, selectEntities);

export const selectOrganizationBuyingWishesArray = createSelector(selectOrganizationBuyingWishState, selectAll);

export const selectIdOrganizationBuyingWishesActive = createSelector(
  selectOrganizationBuyingWishState,
  (state: OrganizationBuyingWishState) => state.actives
);

const organizationBuyingWishesInObject = (organizationBuyingWishes: Dictionary<OrganizationBuyingWishEntityState>) => ({
  organizationBuyingWishes
});

const selectOrganizationBuyingWishesEntitiesDictionary = createSelector(
  selectOrganizationBuyingWishesEntities,
  organizationBuyingWishesInObject
);

const selectAllOrganizationBuyingWishesObject = createSelector(
  selectOrganizationBuyingWishesEntities,
  organizationBuyingWishes => {
    return hydrateAll({ organizationBuyingWishes });
  }
);

const selectOneOrganizationBuyingWishDictionary = (idOrganizationBuyingWish: number) =>
  createSelector(selectOrganizationBuyingWishesEntities, organizationBuyingWishes => {
    return {
      organizationBuyingWishes: { [idOrganizationBuyingWish]: organizationBuyingWishes[idOrganizationBuyingWish] }
    };
  });

const selectOneOrganizationBuyingWishDictionaryWithoutChild = (idOrganizationBuyingWish: number) =>
  createSelector(selectOrganizationBuyingWishesEntities, organizationBuyingWishes => {
    return { organizationBuyingWish: organizationBuyingWishes[idOrganizationBuyingWish] };
  });

const selectActiveOrganizationBuyingWishesEntities = createSelector(
  selectIdOrganizationBuyingWishesActive,
  selectOrganizationBuyingWishesEntities,
  (actives: number[], organizationBuyingWishes: Dictionary<OrganizationBuyingWishEntityState>) =>
    getOrganizationBuyingWishesFromActives(actives, organizationBuyingWishes)
);

function getOrganizationBuyingWishesFromActives(
  actives: number[],
  organizationBuyingWishes: Dictionary<OrganizationBuyingWishEntityState>
): Dictionary<OrganizationBuyingWishEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationBuyingWishes[idActive]) {
      acc[idActive] = organizationBuyingWishes[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationBuyingWishEntityState>);
}

const selectAllOrganizationBuyingWishesSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationBuyingWishes(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationBuyingWish>(
      schema,
      selectAllOrganizationBuyingWishesSelectors,
      selectOrganizationBuyingWishesEntitiesDictionary,
      getRelationSelectors,
      organizationBuyingWishRelations,
      hydrateAll,
      'organizationBuyingWish'
    );
  } else {
    return selectAllOrganizationBuyingWishesObject;
  }
}

export function selectAllOrganizationBuyingWishesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationBuyingWishes'
): Selector {
  return createSelector(selectAllOrganizationBuyingWishes(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationBuyingWishEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationBuyingWishes.length; i++) {
      res[customKey][result.organizationBuyingWishes[i].idOrganizationBuyingWish] = result.organizationBuyingWishes[i];
    }
    return res;
  });
}

export function selectOneOrganizationBuyingWish(schema: SelectSchema = {}, idOrganizationBuyingWish: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationBuyingWishDictionary(idOrganizationBuyingWish)];
    selectors.push(...getRelationSelectors(schema, organizationBuyingWishRelations, 'organizationBuyingWish'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationBuyingWishDictionaryWithoutChild(idOrganizationBuyingWish);
  }
}

export function selectActiveOrganizationBuyingWishes(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationBuyingWishesEntities, organizationBuyingWishes => ({
      organizationBuyingWishes
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationBuyingWishRelations, 'organizationBuyingWish'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationBuyingWishes: Dictionary<OrganizationBuyingWishEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  prospectBuyingWishs?: Dictionary<ProspectBuyingWishEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationBuyingWishes: (OrganizationBuyingWish | null)[] } {
  const { organizationBuyingWishes, organizations, prospectBuyingWishs } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationBuyingWishes: Object.keys(organizationBuyingWishes).map(idOrganizationBuyingWish =>
      hydrate(
        organizationBuyingWishes[idOrganizationBuyingWish] as OrganizationBuyingWishEntityState,
        organizations,
        prospectBuyingWishs
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationBuyingWish: OrganizationBuyingWishEntityState | null } {
  const { organizationBuyingWishes, organizations, prospectBuyingWishs } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationBuyingWish = Object.values(organizationBuyingWishes)[0];
  return {
    organizationBuyingWish: hydrate(
      organizationBuyingWish as OrganizationBuyingWishEntityState,
      organizations,
      prospectBuyingWishs
    )
  };
}

function hydrate(
  organizationBuyingWish: OrganizationBuyingWishEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  prospectBuyingWishEntities?: Dictionary<ProspectBuyingWishEntityState>
): OrganizationBuyingWish | null {
  if (!organizationBuyingWish) {
    return null;
  }

  const organizationBuyingWishHydrated: OrganizationBuyingWishEntityState = { ...organizationBuyingWish };
  if (organizationEntities) {
    organizationBuyingWishHydrated.organization = organizationEntities[
      organizationBuyingWish.organization as number
    ] as Organization;
  } else {
    delete organizationBuyingWishHydrated.organization;
  }

  if (prospectBuyingWishEntities) {
    organizationBuyingWishHydrated.prospectBuyingWishs = (
      (organizationBuyingWishHydrated.prospectBuyingWishs as number[]) || []
    ).map(id => prospectBuyingWishEntities[id]) as ProspectBuyingWish[];
  } else {
    delete organizationBuyingWishHydrated.prospectBuyingWishs;
  }

  return organizationBuyingWishHydrated as OrganizationBuyingWish;
}
