import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationProspectOrigin } from '@_model/interfaces/organization-prospect-origin.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationProspectOriginApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationProspectOrigins(params?: any): Observable<OrganizationProspectOrigin[]> {
    return this.repo.getData<OrganizationProspectOrigin[]>('organization-prospect-origin', params);
  }

  public getOrganizationProspectOrigin(params: {
    idOrganizationProspectOrigin: number;
    params?: any;
  }): Observable<OrganizationProspectOrigin> {
    return this.repo.getData<OrganizationProspectOrigin>(
      'organization-prospect-origin/' + params.idOrganizationProspectOrigin,
      params.params
    );
  }

  public addOrganizationProspectOrigin(
    organizationProspectOrigin: Partial<OrganizationProspectOrigin>
  ): Observable<OrganizationProspectOrigin> {
    return this.repo.create<OrganizationProspectOrigin>('organization-prospect-origin', organizationProspectOrigin);
  }

  public updateOrganizationProspectOrigin(
    organizationProspectOrigin: Partial<OrganizationProspectOrigin>
  ): Observable<OrganizationProspectOrigin> {
    return this.repo.update('organization-prospect-origin', organizationProspectOrigin);
  }

  public deleteOrganizationProspectOrigin(idOrganizationProspectOrigin: number): Observable<number> {
    return this.repo.delete('organization-prospect-origin/' + +idOrganizationProspectOrigin);
  }
}
