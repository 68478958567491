import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { CompanyStratalotType, CompanyStratalotTypeEntityState } from '@_model/interfaces/company-stratalot-type.model';
import { CompanyStratalotTypeApiService } from '@_services/api/company-stratalot-type-api.service';
import * as companyStratalotTypeActions from '@_store/company-stratalot-type/company-stratalot-type.actions';
import { getActionsToNormalizeCompanyStratalotType } from '@_config/store/normalization.generated';
import { selectCompanyStratalotTypeState } from './company-stratalot-type-generated.selectors';
import * as companyActions from '@_store/company/company.actions';
import * as stratalotTypeActions from '@_store/stratalot-type/stratalot-type.actions';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import { Stratalot } from '@_model/interfaces/stratalot.model';
import * as stratalotPriceListActions from '@_store/stratalot-price-list/stratalot-price-list.actions';
import { StratalotPriceList } from '@_model/interfaces/stratalot-price-list.model';
import * as organizationLeadTodoRuleActions from '@_store/organization-lead-todo-rule/organization-lead-todo-rule.actions';
import { OrganizationLeadTodoRule } from '@_model/interfaces/organization-lead-todo-rule.model';
import * as organizationStratalotTodoRuleActions from '@_store/organization-stratalot-todo-rule/organization-stratalot-todo-rule.actions';
import { OrganizationStratalotTodoRule } from '@_model/interfaces/organization-stratalot-todo-rule.model';

export interface CompanyStratalotTypeRelationsIds {
  stratalots?: number | number[];
  stratalotPriceLists?: number | number[];
  organizationLeadTodoRules?: number | number[];
  organizationStratalotTodoRules?: number | number[];
  company?: number;
  stratalotType?: number;
}

export function getDefaultAddCompanyStratalotTypeActions(
  companyStratalotType: CompanyStratalotTypeEntityState,
  ids?: CompanyStratalotTypeRelationsIds
): Action[] {
  const actions: Action[] = [
    companyStratalotTypeActions.normalizeManyCompanyStratalotTypesAfterUpsert({
      companyStratalotTypes: [companyStratalotType]
    })
  ];

  if (ids?.company) {
    actions.push(
      companyActions.addManyCompanyStratalotTypeSuccess({
        idCompany: ids.company,
        idCompanyStratalotTypes: [companyStratalotType.idCompanyStratalotType]
      })
    );
    actions.push(
      companyStratalotTypeActions.addCompanySuccess({
        idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
        idCompany: ids.company
      })
    );
  }

  if (ids?.stratalotType) {
    actions.push(
      stratalotTypeActions.addManyCompanyStratalotTypeSuccess({
        idStratalotType: ids.stratalotType,
        idCompanyStratalotTypes: [companyStratalotType.idCompanyStratalotType]
      })
    );
    actions.push(
      companyStratalotTypeActions.addStratalotTypeSuccess({
        idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
        idStratalotType: ids.stratalotType
      })
    );
  }

  if (ids?.stratalots) {
    if (!Array.isArray(ids.stratalots)) {
      actions.push(
        stratalotActions.upsertOneStratalot({
          stratalot: {
            idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
            idStratalot: ids.stratalots as number
          } as Stratalot
        })
      );
      actions.push(
        companyStratalotTypeActions.addManyStratalotSuccess({
          idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
          idStratalots: [ids.stratalots as number]
        })
      );
    } else {
      actions.push(
        stratalotActions.upsertManyStratalots({
          stratalots: (ids.stratalots as number[]).map((idStratalot: number) => ({
            idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
            idStratalot
          })) as Stratalot[]
        })
      );
      actions.push(
        companyStratalotTypeActions.addManyStratalotSuccess({
          idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
          idStratalots: ids.stratalots as number[]
        })
      );
    }
  }

  if (ids?.stratalotPriceLists) {
    if (!Array.isArray(ids.stratalotPriceLists)) {
      actions.push(
        stratalotPriceListActions.upsertOneStratalotPriceList({
          stratalotPriceList: {
            idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
            idStratalotPriceList: ids.stratalotPriceLists as number
          } as StratalotPriceList
        })
      );
      actions.push(
        companyStratalotTypeActions.addManyStratalotPriceListSuccess({
          idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
          idStratalotPriceLists: [ids.stratalotPriceLists as number]
        })
      );
    } else {
      actions.push(
        stratalotPriceListActions.upsertManyStratalotPriceLists({
          stratalotPriceLists: (ids.stratalotPriceLists as number[]).map((idStratalotPriceList: number) => ({
            idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
            idStratalotPriceList
          })) as StratalotPriceList[]
        })
      );
      actions.push(
        companyStratalotTypeActions.addManyStratalotPriceListSuccess({
          idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
          idStratalotPriceLists: ids.stratalotPriceLists as number[]
        })
      );
    }
  }

  if (ids?.organizationLeadTodoRules) {
    if (!Array.isArray(ids.organizationLeadTodoRules)) {
      actions.push(
        organizationLeadTodoRuleActions.upsertOneOrganizationLeadTodoRule({
          organizationLeadTodoRule: {
            idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
            idOrganizationLeadTodoRule: ids.organizationLeadTodoRules as number
          } as OrganizationLeadTodoRule
        })
      );
      actions.push(
        companyStratalotTypeActions.addManyOrganizationLeadTodoRuleSuccess({
          idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
          idOrganizationLeadTodoRules: [ids.organizationLeadTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationLeadTodoRuleActions.upsertManyOrganizationLeadTodoRules({
          organizationLeadTodoRules: (ids.organizationLeadTodoRules as number[]).map(
            (idOrganizationLeadTodoRule: number) => ({
              idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
              idOrganizationLeadTodoRule
            })
          ) as OrganizationLeadTodoRule[]
        })
      );
      actions.push(
        companyStratalotTypeActions.addManyOrganizationLeadTodoRuleSuccess({
          idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
          idOrganizationLeadTodoRules: ids.organizationLeadTodoRules as number[]
        })
      );
    }
  }

  if (ids?.organizationStratalotTodoRules) {
    if (!Array.isArray(ids.organizationStratalotTodoRules)) {
      actions.push(
        organizationStratalotTodoRuleActions.upsertOneOrganizationStratalotTodoRule({
          organizationStratalotTodoRule: {
            idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
            idOrganizationStratalotTodoRule: ids.organizationStratalotTodoRules as number
          } as OrganizationStratalotTodoRule
        })
      );
      actions.push(
        companyStratalotTypeActions.addManyOrganizationStratalotTodoRuleSuccess({
          idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
          idOrganizationStratalotTodoRules: [ids.organizationStratalotTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotTodoRuleActions.upsertManyOrganizationStratalotTodoRules({
          organizationStratalotTodoRules: (ids.organizationStratalotTodoRules as number[]).map(
            (idOrganizationStratalotTodoRule: number) => ({
              idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
              idOrganizationStratalotTodoRule
            })
          ) as OrganizationStratalotTodoRule[]
        })
      );
      actions.push(
        companyStratalotTypeActions.addManyOrganizationStratalotTodoRuleSuccess({
          idCompanyStratalotType: companyStratalotType.idCompanyStratalotType,
          idOrganizationStratalotTodoRules: ids.organizationStratalotTodoRules as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCompanyStratalotTypeActions(
  companyStratalotType: CompanyStratalotTypeEntityState
): Action[] {
  const actions: Action[] = [
    companyStratalotTypeActions.deleteOneCompanyStratalotTypeSuccess({
      idCompanyStratalotType: companyStratalotType.idCompanyStratalotType
    })
  ];

  if (companyStratalotType.company) {
    actions.push(
      companyActions.deleteManyCompanyStratalotTypeSuccess({
        idCompanyStratalotTypes: [companyStratalotType.idCompanyStratalotType],
        idCompanies: [companyStratalotType.company as number]
      })
    );
  }

  if (companyStratalotType.stratalotType) {
    actions.push(
      stratalotTypeActions.deleteManyCompanyStratalotTypeSuccess({
        idCompanyStratalotTypes: [companyStratalotType.idCompanyStratalotType],
        idStratalotTypes: [companyStratalotType.stratalotType as number]
      })
    );
  }

  if (companyStratalotType.stratalots) {
    actions.push(
      stratalotActions.deleteManyCompanyStratalotTypeSuccess({
        idCompanyStratalotTypes: [companyStratalotType.idCompanyStratalotType],
        idStratalots: companyStratalotType.stratalots as number[]
      })
    );
  }

  if (companyStratalotType.stratalotPriceLists) {
    actions.push(
      stratalotPriceListActions.deleteManyCompanyStratalotTypeSuccess({
        idCompanyStratalotTypes: [companyStratalotType.idCompanyStratalotType],
        idStratalotPriceLists: companyStratalotType.stratalotPriceLists as number[]
      })
    );
  }

  if (companyStratalotType.organizationLeadTodoRules) {
    actions.push(
      organizationLeadTodoRuleActions.deleteManyCompanyStratalotTypeSuccess({
        idCompanyStratalotTypes: [companyStratalotType.idCompanyStratalotType],
        idOrganizationLeadTodoRules: companyStratalotType.organizationLeadTodoRules as number[]
      })
    );
  }

  if (companyStratalotType.organizationStratalotTodoRules) {
    actions.push(
      organizationStratalotTodoRuleActions.deleteManyCompanyStratalotTypeSuccess({
        idCompanyStratalotTypes: [companyStratalotType.idCompanyStratalotType],
        idOrganizationStratalotTodoRules: companyStratalotType.organizationStratalotTodoRules as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyStratalotTypeEffects {
  constructor(
    protected actions$: Actions,
    protected companyStratalotTypeApiService: CompanyStratalotTypeApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyStratalotTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStratalotTypeActions.getManyCompanyStratalotTypes),
      switchMap(({ params }) =>
        this.companyStratalotTypeApiService.getCompanyStratalotTypes(params).pipe(
          map((companyStratalotTypes: CompanyStratalotType[]) => {
            return companyStratalotTypeActions.normalizeManyCompanyStratalotTypesAfterUpsert({ companyStratalotTypes });
          }),
          catchError(error => of(companyStratalotTypeActions.companyStratalotTypesFailure({ error })))
        )
      )
    );
  });

  getOneCompanyStratalotType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStratalotTypeActions.getOneCompanyStratalotType),
      switchMap(idCompanyStratalotType =>
        this.companyStratalotTypeApiService.getCompanyStratalotType(idCompanyStratalotType).pipe(
          map((companyStratalotType: CompanyStratalotType) => {
            return companyStratalotTypeActions.normalizeManyCompanyStratalotTypesAfterUpsert({
              companyStratalotTypes: [companyStratalotType]
            });
          }),
          catchError(error => of(companyStratalotTypeActions.companyStratalotTypesFailure({ error })))
        )
      )
    );
  });

  upsertOneCompanyStratalotType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStratalotTypeActions.upsertOneCompanyStratalotType),
      concatMap(
        ({
          companyStratalotType,
          ids
        }: {
          companyStratalotType: Partial<CompanyStratalotType>;
          ids?: CompanyStratalotTypeRelationsIds;
        }) => {
          if (companyStratalotType.idCompanyStratalotType) {
            return this.companyStratalotTypeApiService.updateCompanyStratalotType(companyStratalotType).pipe(
              map((companyStratalotTypeReturned: CompanyStratalotType) => {
                return companyStratalotTypeActions.normalizeManyCompanyStratalotTypesAfterUpsert({
                  companyStratalotTypes: [companyStratalotTypeReturned]
                });
              }),
              catchError(error => of(companyStratalotTypeActions.companyStratalotTypesFailure({ error })))
            );
          } else {
            return this.companyStratalotTypeApiService.addCompanyStratalotType(companyStratalotType).pipe(
              mergeMap((companyStratalotTypeReturned: CompanyStratalotType) =>
                getDefaultAddCompanyStratalotTypeActions(companyStratalotTypeReturned, ids)
              ),
              catchError(error => of(companyStratalotTypeActions.companyStratalotTypesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCompanyStratalotType$ = createEffect(() => {
    const selectCompanyStratalotTypeState$ = this.store$.select(selectCompanyStratalotTypeState);
    return this.actions$.pipe(
      ofType(companyStratalotTypeActions.deleteOneCompanyStratalotType),
      withLatestFrom(selectCompanyStratalotTypeState$),
      concatMap(([{ idCompanyStratalotType }, state]) =>
        this.companyStratalotTypeApiService.deleteCompanyStratalotType(idCompanyStratalotType).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCompanyStratalotTypeActions(
                state.entities[idCompanyStratalotType] as CompanyStratalotTypeEntityState
              ),
              companyStratalotTypeActions.deleteOneCompanyStratalotType.type
            )
          ]),
          catchError(error => of(companyStratalotTypeActions.companyStratalotTypesFailure({ error })))
        )
      )
    );
  });

  normalizeManyCompanyStratalotTypesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStratalotTypeActions.normalizeManyCompanyStratalotTypesAfterUpsert),
      concatMap(({ companyStratalotTypes }) => {
        const actions: Action[] = getActionsToNormalizeCompanyStratalotType(
          companyStratalotTypes,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CompanyStratalotType] Normalization After Upsert Success')];
      })
    );
  });
}
