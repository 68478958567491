import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  GeneratedDocumentsCompany,
  GeneratedDocumentsCompanyEntityState
} from '@_model/interfaces/generated-documents-company.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { GeneratedDocument, GeneratedDocumentEntityState } from '@_model/interfaces/generated-document.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  generatedDocumentsCompanyFeatureKey,
  GeneratedDocumentsCompanyState
} from './generated-documents-company.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const generatedDocumentsCompanyRelations: string[] = ['companies', 'generatedDocuments'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectGeneratedDocumentsCompanyState = createFeatureSelector<GeneratedDocumentsCompanyState>(
  generatedDocumentsCompanyFeatureKey
);

export const selectIsLoadedGeneratedDocumentsCompany = createSelector(
  selectGeneratedDocumentsCompanyState,
  (state: GeneratedDocumentsCompanyState) => state.isLoaded
);

export const selectIsLoadingGeneratedDocumentsCompany = createSelector(
  selectGeneratedDocumentsCompanyState,
  (state: GeneratedDocumentsCompanyState) => state.isLoading
);

export const selectIsReadyGeneratedDocumentsCompany = createSelector(
  selectGeneratedDocumentsCompanyState,
  (state: GeneratedDocumentsCompanyState) => !state.isLoading
);

export const selectIsReadyAndLoadedGeneratedDocumentsCompany = createSelector(
  selectGeneratedDocumentsCompanyState,
  (state: GeneratedDocumentsCompanyState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const GeneratedDocumentsCompanyModel: SelectorModel = {
  name: 'generatedDocumentsCompanies',
  getSelector: selectAllGeneratedDocumentsCompaniesDictionary,
  isReady: selectIsReadyGeneratedDocumentsCompany
};

export const selectGeneratedDocumentsCompaniesEntities = createSelector(
  selectGeneratedDocumentsCompanyState,
  selectEntities
);

export const selectGeneratedDocumentsCompaniesArray = createSelector(selectGeneratedDocumentsCompanyState, selectAll);

export const selectIdGeneratedDocumentsCompaniesActive = createSelector(
  selectGeneratedDocumentsCompanyState,
  (state: GeneratedDocumentsCompanyState) => state.actives
);

const generatedDocumentsCompaniesInObject = (
  generatedDocumentsCompanies: Dictionary<GeneratedDocumentsCompanyEntityState>
) => ({ generatedDocumentsCompanies });

const selectGeneratedDocumentsCompaniesEntitiesDictionary = createSelector(
  selectGeneratedDocumentsCompaniesEntities,
  generatedDocumentsCompaniesInObject
);

const selectAllGeneratedDocumentsCompaniesObject = createSelector(
  selectGeneratedDocumentsCompaniesEntities,
  generatedDocumentsCompanies => {
    return hydrateAll({ generatedDocumentsCompanies });
  }
);

const selectOneGeneratedDocumentsCompanyDictionary = (idGeneratedDocumentsCompany: number) =>
  createSelector(selectGeneratedDocumentsCompaniesEntities, generatedDocumentsCompanies => {
    return {
      generatedDocumentsCompanies: {
        [idGeneratedDocumentsCompany]: generatedDocumentsCompanies[idGeneratedDocumentsCompany]
      }
    };
  });

const selectOneGeneratedDocumentsCompanyDictionaryWithoutChild = (idGeneratedDocumentsCompany: number) =>
  createSelector(selectGeneratedDocumentsCompaniesEntities, generatedDocumentsCompanies => {
    return { generatedDocumentsCompany: generatedDocumentsCompanies[idGeneratedDocumentsCompany] };
  });

const selectActiveGeneratedDocumentsCompaniesEntities = createSelector(
  selectIdGeneratedDocumentsCompaniesActive,
  selectGeneratedDocumentsCompaniesEntities,
  (actives: number[], generatedDocumentsCompanies: Dictionary<GeneratedDocumentsCompanyEntityState>) =>
    getGeneratedDocumentsCompaniesFromActives(actives, generatedDocumentsCompanies)
);

function getGeneratedDocumentsCompaniesFromActives(
  actives: number[],
  generatedDocumentsCompanies: Dictionary<GeneratedDocumentsCompanyEntityState>
): Dictionary<GeneratedDocumentsCompanyEntityState> {
  return actives.reduce((acc, idActive) => {
    if (generatedDocumentsCompanies[idActive]) {
      acc[idActive] = generatedDocumentsCompanies[idActive];
    }
    return acc;
  }, {} as Dictionary<GeneratedDocumentsCompanyEntityState>);
}

const selectAllGeneratedDocumentsCompaniesSelectors: Dictionary<Selector> = {};
export function selectAllGeneratedDocumentsCompanies(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<GeneratedDocumentsCompany>(
      schema,
      selectAllGeneratedDocumentsCompaniesSelectors,
      selectGeneratedDocumentsCompaniesEntitiesDictionary,
      getRelationSelectors,
      generatedDocumentsCompanyRelations,
      hydrateAll,
      'generatedDocumentsCompany'
    );
  } else {
    return selectAllGeneratedDocumentsCompaniesObject;
  }
}

export function selectAllGeneratedDocumentsCompaniesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'generatedDocumentsCompanies'
): Selector {
  return createSelector(selectAllGeneratedDocumentsCompanies(schema), result => {
    const res = { [customKey]: {} as Dictionary<GeneratedDocumentsCompanyEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.generatedDocumentsCompanies.length; i++) {
      res[customKey][result.generatedDocumentsCompanies[i].idGeneratedDocumentsCompany] =
        result.generatedDocumentsCompanies[i];
    }
    return res;
  });
}

export function selectOneGeneratedDocumentsCompany(
  schema: SelectSchema = {},
  idGeneratedDocumentsCompany: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneGeneratedDocumentsCompanyDictionary(idGeneratedDocumentsCompany)];
    selectors.push(...getRelationSelectors(schema, generatedDocumentsCompanyRelations, 'generatedDocumentsCompany'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneGeneratedDocumentsCompanyDictionaryWithoutChild(idGeneratedDocumentsCompany);
  }
}

export function selectActiveGeneratedDocumentsCompanies(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveGeneratedDocumentsCompaniesEntities, generatedDocumentsCompanies => ({
      generatedDocumentsCompanies
    }))
  ];
  selectors.push(...getRelationSelectors(schema, generatedDocumentsCompanyRelations, 'generatedDocumentsCompany'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  generatedDocumentsCompanies: Dictionary<GeneratedDocumentsCompanyEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  generatedDocuments?: Dictionary<GeneratedDocumentEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  generatedDocumentsCompanies: (GeneratedDocumentsCompany | null)[];
} {
  const { generatedDocumentsCompanies, companies, generatedDocuments } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    generatedDocumentsCompanies: Object.keys(generatedDocumentsCompanies).map(idGeneratedDocumentsCompany =>
      hydrate(
        generatedDocumentsCompanies[idGeneratedDocumentsCompany] as GeneratedDocumentsCompanyEntityState,
        companies,
        generatedDocuments
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  generatedDocumentsCompany: GeneratedDocumentsCompanyEntityState | null;
} {
  const { generatedDocumentsCompanies, companies, generatedDocuments } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const generatedDocumentsCompany = Object.values(generatedDocumentsCompanies)[0];
  return {
    generatedDocumentsCompany: hydrate(
      generatedDocumentsCompany as GeneratedDocumentsCompanyEntityState,
      companies,
      generatedDocuments
    )
  };
}

function hydrate(
  generatedDocumentsCompany: GeneratedDocumentsCompanyEntityState,
  companyEntities?: Dictionary<CompanyEntityState>,
  generatedDocumentEntities?: Dictionary<GeneratedDocumentEntityState>
): GeneratedDocumentsCompany | null {
  if (!generatedDocumentsCompany) {
    return null;
  }

  const generatedDocumentsCompanyHydrated: GeneratedDocumentsCompanyEntityState = { ...generatedDocumentsCompany };
  if (companyEntities) {
    generatedDocumentsCompanyHydrated.company = companyEntities[generatedDocumentsCompany.company as number] as Company;
  } else {
    delete generatedDocumentsCompanyHydrated.company;
  }
  if (generatedDocumentEntities) {
    generatedDocumentsCompanyHydrated.generatedDocument = generatedDocumentEntities[
      generatedDocumentsCompany.generatedDocument as number
    ] as GeneratedDocument;
  } else {
    delete generatedDocumentsCompanyHydrated.generatedDocument;
  }

  return generatedDocumentsCompanyHydrated as GeneratedDocumentsCompany;
}
