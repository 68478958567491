import { Injectable } from '@angular/core';
import { RepositoryService } from '@_services/api/repository.service';
import { map, Observable } from 'rxjs';
import { ActivityDashboardRow } from '@_shared/interfaces/activity-dashboard-row.interface';
import { ResidenceDashboardRow } from '@_shared/interfaces/residence-dashboard-row.interface';
import { StockDashboardRow } from '@_shared/interfaces/stock-dashboard-row.interface';
import { ResidenceWithSoldStratalots } from '@_shared/models/interfaces/residence.model';
import { ActivityAggridDataRow } from '@_shared/interfaces/activity-aggrid-data-row.interface';
import { GraphPoint } from '@_shared/models/interfaces/graph-point.interface';
import DatesUtils from '@_utils/common-utils/dates.utils';
import { sortByMultipleKey } from '@_utils/common-utils/arrays';
import SortUtils from '@_utils/common-utils/custom-sort';
import { KeyResults } from '@_shared/key-results';
import { DashboardPrevisonalAgGridRowType } from '@_shared/enum/dashboard-previsonal-aggrid-row-type.enum';

export interface SaleGroupByYearAndMonth {
  month: string;
  year: string;
  count: number;
}
export interface ActivityDashboard {
  activityAggridDataRows: ActivityAggridDataRow[];
  saleGroupByYearAndMonth: SaleGroupByYearAndMonth[];
}

@Injectable({
  providedIn: 'root'
})
export class DashBoardApiService {
  constructor(private repo: RepositoryService) {}
  public getDataFormPrevisionnalBoard(params?: any): Observable<ActivityDashboardRow> {
    return this.repo
      .getData<ActivityDashboard>('dashboard/activity', params)
      .pipe(map(data => this.mapToActivityDashboardRow(data)));
  }
  mapToActivityDashboardRow(data: ActivityDashboard): ActivityDashboardRow {
    return {
      ...data,
      graphPoints: this.mapToGraphPoint(data.saleGroupByYearAndMonth),
      keyResults: this.createKeyResult(data.activityAggridDataRows)
    };
  }
  createKeyResult(activityAggridDataRows: ActivityAggridDataRow[]): KeyResults {
    const acteSigne = activityAggridDataRows.find(r => r.statutLot === DashboardPrevisonalAgGridRowType.acteSigne);
    const vendu = activityAggridDataRows.find(r => r.statutLot === DashboardPrevisonalAgGridRowType.vendu);
    const sousCompromis = activityAggridDataRows.find(
      r => r.statutLot === DashboardPrevisonalAgGridRowType.sousComprisDansLaPeriode
    );
    return {
      acteSigne: { ca: acteSigne.ca, lotsPrincipaux: acteSigne.nbLotPrincipaux, mb: acteSigne.mb },
      vente: { ca: vendu.ca, lotsPrincipaux: vendu.nbLotPrincipaux, mb: vendu.mb },
      sousCompromisOuLeveeOption: {
        ca: sousCompromis.ca,
        lotsPrincipaux: sousCompromis.nbLotPrincipaux,
        mb: sousCompromis.mb
      }
    };
  }
  mapToGraphPoint(saleGroupByYearAndMonth: SaleGroupByYearAndMonth[]): GraphPoint[] {
    const test = saleGroupByYearAndMonth.reduce((acc, curr) => {
      if (!acc[curr.month]) {
        acc[curr.month] = { month: curr.month };
      }
      acc[curr.month] = { ...acc[curr.month], [this.isCurrentYear(+curr.year) ? 'current' : 'previous']: curr.count };
      return acc;
    }, {});
    return Object.keys(test)
      .map(k => test[k])
      .sort(sortByMultipleKey([{ name: 'month', cb: SortUtils.sortbyMonth }]));
  }
  isCurrentYear(year: number): boolean {
    return DatesUtils.currentYear() === year;
  }
  public getDataFormStockBoard(params?: any): Observable<StockDashboardRow[]> {
    return this.repo.getData<StockDashboardRow[]>('dashboard/stock', params);
  }
  public getSaleLeadActivityByStratalot(params?: any): Observable<StockDashboardRow[]> {
    return this.repo.getData<StockDashboardRow[]>('dashboard/saleLeadActivityByStratalot', params);
  }
  public getLeadActivityByStratalot(params?: any): Observable<StockDashboardRow[]> {
    return this.repo.getData<StockDashboardRow[]>('dashboard/leadActivityByStratalot', params);
  }
  public getDiscontinuedLeads(params?: any): Observable<any[]> {
    return this.repo.getData<any[]>('dashboard/discontinuedLeads', params);
  }
  public getActivityByResidence(params?: any): Observable<ResidenceDashboardRow[]> {
    return this.repo.getData<ResidenceDashboardRow[]>('dashboard/saleActivityByResidence', params);
  }
  public getDataResidenceForecast(params?: any): Observable<ResidenceWithSoldStratalots[]> {
    return this.repo.getData<ResidenceWithSoldStratalots[]>('dashboard/forecast', params);
  }
}
