import { ResidenceContactState, initialState } from './residence-contact.state';
import { Action, createReducer } from '@ngrx/store';
import { residenceContactReducersGeneratedFunctions } from './residence-contact-generated.reducer';

const residenceContactReducersFunctions = [...residenceContactReducersGeneratedFunctions];

const residenceContactReducer = createReducer(initialState, ...residenceContactReducersFunctions);

export function reducer(state: ResidenceContactState | undefined, action: Action) {
  return residenceContactReducer(state, action);
}
