import { Injectable } from '@angular/core';
import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedProspectApiService } from './prospect-api-generated.service';
import { Prospect } from '@_model/interfaces/prospect.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProspectApiService extends GeneratedProspectApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public getProspectsForProspectBase(params?: any): Observable<Prospect[]> {
    return this.repo.getData<Prospect[]>('prospect/forProspectBase', params);
  }

  public getProspectsByResidence(idResidence: number, params: any): Observable<Prospect[]> {
    return this.repo.getData<Prospect[]>('prospect/byResidence/' + +idResidence, params);
  }

  public updateProspect(prospect: Partial<Prospect>): Observable<Prospect> {
    const newProspect: Partial<Prospect> = { ...prospect };
    delete newProspect.idOrganization;
    return this.repo.update('prospect', newProspect);
  }

  public deleteProspectByOrganization(idOrganization: number): Observable<number[]> {
    return this.repo.deleteMany('prospect/deleteByOrganization/' + +idOrganization);
  }
}
