import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  CompanyStratalotVacant,
  CompanyStratalotVacantEntityState
} from '@_model/interfaces/company-stratalot-vacant.model';
import { CompanyStratalotVacantRelationsIds } from './company-stratalot-vacant-generated.effects';

export const getOneCompanyStratalotVacant = createAction(
  '[CompanyStratalotVacant] Get One CompanyStratalotVacant',
  props<{ idCompanyStratalotVacant: number; params?: any }>()
);

export const getManyCompanyStratalotVacants = createAction(
  '[CompanyStratalotVacant] Get Many CompanyStratalotVacants',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyStratalotVacant] Add Many Actives CompanyStratalotVacant',
  props<{ idCompanyStratalotVacants: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyStratalotVacant] Delete One Active CompanyStratalotVacant',
  props<{ idCompanyStratalotVacant: number }>()
);

export const clearActive = createAction('[CompanyStratalotVacant] Clear Active CompanyStratalotVacant');

export const upsertOneCompanyStratalotVacant = createAction(
  '[CompanyStratalotVacant] Upsert One CompanyStratalotVacant',
  props<{
    companyStratalotVacant: Partial<CompanyStratalotVacant>;
    ids?: CompanyStratalotVacantRelationsIds;
  }>()
);

export const upsertManyCompanyStratalotVacants = createAction(
  '[CompanyStratalotVacant] Upsert Many CompanyStratalotVacants',
  props<{
    companyStratalotVacants: Partial<CompanyStratalotVacant>[];
    ids?: CompanyStratalotVacantRelationsIds;
  }>()
);

export const upsertManyCompanyStratalotVacantsSuccess = createAction(
  '[CompanyStratalotVacant] Create Many CompanyStratalotVacants Success',
  props<{ companyStratalotVacants: CompanyStratalotVacantEntityState[] }>()
);

export const deleteOneCompanyStratalotVacant = createAction(
  '[CompanyStratalotVacant] Delete One CompanyStratalotVacant',
  props<{ idCompanyStratalotVacant: number }>()
);

export const deleteOneCompanyStratalotVacantSuccess = createAction(
  '[CompanyStratalotVacant] Delete One CompanyStratalotVacant Success',
  props<{ idCompanyStratalotVacant: number }>()
);

export const normalizeManyCompanyStratalotVacantsAfterUpsert = createAction(
  '[CompanyStratalotVacant] Normalize Many CompanyStratalotVacants After Upsert',
  props<{ companyStratalotVacants: CompanyStratalotVacantEntityState[] }>()
);

export const companyStratalotVacantsFailure = createAction(
  '[CompanyStratalotVacant] CompanyStratalotVacants Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyStratalotVacant] Clear CompanyStratalotVacants');

export const addManyStratalotSuccess = createAction(
  '[CompanyStratalotVacant] Add Many stratalot',
  props<{ idCompanyStratalotVacant: number; idStratalots: number[] }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[CompanyStratalotVacant] Delete Many Stratalots',
  props<{ idStratalots: number[]; idCompanyStratalotVacants: number[] }>()
);

export const addCompanySuccess = createAction(
  '[CompanyStratalotVacant] Add Company',
  props<{ idCompanyStratalotVacant: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[CompanyStratalotVacant] Delete Many Company',
  props<{ idCompanies: number[]; idCompanyStratalotVacants: number[] }>()
);
