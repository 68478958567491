import dayjs from 'dayjs';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isSameOrBefore);
namespace DatesUtils {
  export interface IDateDifference {
    milliseconds: number;
    secondes: number;
    minutes: number;
    hours: number;
    days: number;
    months: number;
    years: number;
  }
  export function isDateAfter(dateAComparer: Date): boolean {
    if (dateAComparer) {
      dateAComparer = new Date(dateAComparer);
      return !dayjs(dateAComparer).isBefore(dayjs(), 'day');
    } else {
      return false;
    }
  }

  export function myRelativeTime(date: Date): string {
    if (date) {
      const mydate = dayjs(date);
      if (mydate.isToday()) {
        return "aujourd'hui à " + mydate.format('HH:mm');
      } else if (mydate.isYesterday()) {
        return 'hier à ' + mydate.format('HH:mm');
      }
      return 'le ' + mydate.format('DD/MM/YY');
    }
  }
  export function today(): Date {
    return new Date();
  }

  export function currentYear(): number {
    return today().getUTCFullYear();
  }

  export function getDateAtCommonFormat(dateInput: string | Date): string {
    return dateInput ? dayjs(dateInput).format('DD/MM/YY') : null;
  }
  export function getDateAtGraphFormat(dateInput: string | Date): string {
    return dayjs(dateInput).format('MMM YY');
  }

  export function getDateTimeAtCommonFormat(dateInput: string | Date, wording: string): string {
    return dateInput ? dayjs(dateInput).format(`[${wording}] DD/MM/YY [à] HH[h]mm`) : null;
  }

  export function getDateAtHTMLInputFormat(dateInput: string | Date): string {
    return dateInput ? dayjs(dateInput).format('YYYY-MM-DD') : null;
  }

  export function getDateHoursMinutesInputFormat(dateInput: string | Date): string {
    return dateInput ? dayjs(dateInput).format('HH:mm') : null;
  }

  export function getYearAtDate(dateInput: Date | string): number {
    return dayjs(dateInput).year();
  }
  export function differenceBetweenTwoDates(
    dateA: Date,
    dateB: Date,
    options: { absoluteValue: boolean } = { absoluteValue: true }
  ): IDateDifference {
    if (!(dateA instanceof Date)) {
      dateA = new Date(dateA);
    }
    if (!(dateB instanceof Date)) {
      dateB = new Date(dateB);
    }
    let result: IDateDifference = {
      milliseconds: 0,
      secondes: 0,
      minutes: 0,
      hours: 0,
      days: 0,
      months: 0,
      years: 0
    };

    if (dateA && dateB) {
      const milliseconds = options.absoluteValue
        ? Math.abs(dateA.getTime() - dateB.getTime())
        : dateA.getTime() - dateB.getTime();
      const secondes = Math.floor(milliseconds / 1000);
      const minutes = Math.floor(secondes / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      /* TODO : upgrade this line to able use of months with more or less 30 days */
      const months = Math.floor(days / 30);
      /* */
      const years = Math.floor(months / 12);

      result = {
        milliseconds,
        secondes,
        minutes,
        hours,
        days,
        months,
        years
      };
    }

    return result;
  }

  export function isValid(date: Date): boolean {
    return dayjs(date).isValid();
  }
  export function concatDateAndHours(dateInput: Date | string, hours: string): Date {
    if (!dateInput || !hours) {
      return null;
    }

    const date: Date = new Date(dateInput);

    return hours ? new Date(dayjs(date).format('YYYY-MM-DD') + ' ' + hours) : date;
  }
  export function isDateInCurrentYear(dateInput: Date): boolean {
    const year: number = new Date().getFullYear();
    return (
      dateInput &&
      dateInput.getTime() > new Date(year, 0, 1).getTime() &&
      dateInput.getTime() < new Date(year, 11, 31).getTime()
    );
  }

  export function dateInRange(dateAComparer: Date, startPeriode: Date, endPeriode: Date): boolean {
    if (dateAComparer && startPeriode && endPeriode) {
      dateAComparer = new Date(dateAComparer);
      return dateAComparer?.getTime() >= new Date(startPeriode)?.getTime() &&
        dateAComparer.getTime() <= new Date(endPeriode)?.getTime()
        ? true
        : false;
    } else {
      return false;
    }
  }
  export function dateCompare(dateOne: Date, dateTwo: Date): number {
    if (dateOne < dateTwo) {
      return -1;
    }
    if (dateOne === dateTwo) {
      return 0;
    }
    return 1;
  }

  export function getFirstDateOfMonth(dateInput: Date): Date {
    const tmp = new Date(dateInput);
    tmp.setDate(1);
    tmp.setHours(0, 0, 0, 0);
    return tmp;
  }
  export function isEtapeFinsishBeforeEndDate(etapeEnCours: Date, etapeSuivante: Date, echeance: Date): boolean {
    const tempsMoyenEntre2Etape = 90;

    if (etapeSuivante) {
      return dayjs(etapeSuivante).isSameOrBefore(echeance, 'day');
    }
    return dayjs(etapeEnCours).isSameOrBefore(dayjs(echeance).add(tempsMoyenEntre2Etape, 'day'), 'day');
  }

  export function diff(
    date1: Date,
    date2: Date,
    arg: 'millisecond' | 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
  ): number {
    return dayjs(date1).diff(date2, arg);
  }

  export function daysInMonth(dateInput: string | Date): number {
    return dayjs(dateInput).daysInMonth();
  }

  export function add(
    dateInput: string | Date,
    value: number,
    unit: 'millisecond' | 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
  ): Date {
    return dayjs(dateInput).add(value, unit).toDate();
  }
  export function subtract(
    dateInput: string | Date,
    value: number,
    unit: 'millisecond' | 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
  ): Date {
    return dayjs(dateInput).subtract(value, unit).toDate();
  }

  export function daysOfMonth(dateInput: string | Date): number {
    return dayjs(dateInput).date();
  }
  export function goToNextMonthIfExpectedDayPast(dateInput: string | Date, expectedDay: number): Date {
    return dayjs(dateInput).date(expectedDay).toDate();
  }

  export function startOfWithParams(dateInput: Date, timeUnit: any): Date {
    return dayjs(dateInput).startOf(timeUnit).toDate();
  }

  export function dateToCurrentTimeMillisDays(date: Date): number {
    if (date) {
      return Math.floor(date.getTime() / (1000 * 3600 * 24));
    } else {
      return 0;
    }
  }

  export function timeMillisDaysToDate(days: number): Date {
    return new Date(days * (1000 * 3600 * 24));
  }
}

export default DatesUtils;
