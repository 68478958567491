import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { StratalotRelationsIds } from './stratalot-generated.effects';

export const getOneStratalot = createAction(
  '[Stratalot] Get One Stratalot',
  props<{ idStratalot: number; params?: any }>()
);

export const getManyStratalots = createAction('[Stratalot] Get Many Stratalots', props<{ params: any }>());

export const addManyActives = createAction(
  '[Stratalot] Add Many Actives Stratalot',
  props<{ idStratalots: number[] }>()
);

export const deleteOneActive = createAction(
  '[Stratalot] Delete One Active Stratalot',
  props<{ idStratalot: number }>()
);

export const clearActive = createAction('[Stratalot] Clear Active Stratalot');

export const upsertOneStratalot = createAction(
  '[Stratalot] Upsert One Stratalot',
  props<{
    stratalot: Partial<Stratalot>;
    ids?: StratalotRelationsIds;
  }>()
);

export const upsertManyStratalots = createAction(
  '[Stratalot] Upsert Many Stratalots',
  props<{
    stratalots: Partial<Stratalot>[];
    ids?: StratalotRelationsIds;
  }>()
);

export const upsertManyStratalotsSuccess = createAction(
  '[Stratalot] Create Many Stratalots Success',
  props<{ stratalots: StratalotEntityState[] }>()
);

export const deleteOneStratalot = createAction('[Stratalot] Delete One Stratalot', props<{ idStratalot: number }>());

export const deleteOneStratalotSuccess = createAction(
  '[Stratalot] Delete One Stratalot Success',
  props<{ idStratalot: number }>()
);

export const normalizeManyStratalotsAfterUpsert = createAction(
  '[Stratalot] Normalize Many Stratalots After Upsert',
  props<{ stratalots: StratalotEntityState[] }>()
);

export const stratalotsFailure = createAction('[Stratalot] Stratalots Failure', props<{ error: HttpErrorResponse }>());

export const clearStore = createAction('[Stratalot] Clear Stratalots');

export const addManyStratalotCampaignSuccess = createAction(
  '[Stratalot] Add Many stratalot-campaign',
  props<{ idStratalot: number; idStratalotCampaigns: number[] }>()
);

export const deleteManyStratalotCampaignSuccess = createAction(
  '[Stratalot] Delete Many StratalotCampaigns',
  props<{ idStratalotCampaigns: number[]; idStratalots: number[] }>()
);

export const addManyDiffusionVisualSuccess = createAction(
  '[Stratalot] Add Many diffusion-visual',
  props<{ idStratalot: number; idDiffusionVisuals: number[] }>()
);

export const deleteManyDiffusionVisualSuccess = createAction(
  '[Stratalot] Delete Many DiffusionVisuals',
  props<{ idDiffusionVisuals: number[]; idStratalots: number[] }>()
);

export const addManyStratalotAssociationSuccess = createAction(
  '[Stratalot] Add Many stratalot-association',
  props<{ idStratalot: number; idStratalotAssociations: number[] }>()
);

export const deleteManyStratalotAssociationSuccess = createAction(
  '[Stratalot] Delete Many StratalotAssociations',
  props<{ idStratalotAssociations: number[]; idStratalots: number[] }>()
);

export const addManyStratalotFeeSuccess = createAction(
  '[Stratalot] Add Many stratalot-fee',
  props<{ idStratalot: number; idStratalotFees: number[] }>()
);

export const deleteManyStratalotFeeSuccess = createAction(
  '[Stratalot] Delete Many StratalotFees',
  props<{ idStratalotFees: number[]; idStratalots: number[] }>()
);

export const addManyAssociationSuccess = createAction(
  '[Stratalot] Add Many association',
  props<{ idStratalot: number; idAssociations: number[] }>()
);

export const deleteManyAssociationSuccess = createAction(
  '[Stratalot] Delete Many Associations',
  props<{ idAssociations: number[]; idStratalots: number[] }>()
);

export const addManyLeadSuccess = createAction(
  '[Stratalot] Add Many lead',
  props<{ idStratalot: number; idLeads: number[] }>()
);

export const deleteManyLeadSuccess = createAction(
  '[Stratalot] Delete Many Leads',
  props<{ idLeads: number[]; idStratalots: number[] }>()
);

export const addManyLeadStratalotSuccess = createAction(
  '[Stratalot] Add Many lead-stratalot',
  props<{ idStratalot: number; idLeadStratalots: number[] }>()
);

export const deleteManyLeadStratalotSuccess = createAction(
  '[Stratalot] Delete Many LeadStratalots',
  props<{ idLeadStratalots: number[]; idStratalots: number[] }>()
);

export const addManyStratalotPriceSuccess = createAction(
  '[Stratalot] Add Many stratalot-price',
  props<{ idStratalot: number; idStratalotPrices: number[] }>()
);

export const deleteManyStratalotPriceSuccess = createAction(
  '[Stratalot] Delete Many StratalotPrices',
  props<{ idStratalotPrices: number[]; idStratalots: number[] }>()
);

export const addStratalotRcpSuccess = createAction(
  '[Stratalot] Add StratalotRcp',
  props<{ idStratalot: number; idStratalotRcp: number }>()
);

export const deleteStratalotRcpSuccess = createAction(
  '[Stratalot] Delete StratalotRcps',
  props<{ idStratalotRcps: number[]; idStratalots: number[] }>()
);

export const addManyStratalotTodoSuccess = createAction(
  '[Stratalot] Add Many stratalot-todo',
  props<{ idStratalot: number; idStratalotTodos: number[] }>()
);

export const deleteManyStratalotTodoSuccess = createAction(
  '[Stratalot] Delete Many StratalotTodos',
  props<{ idStratalotTodos: number[]; idStratalots: number[] }>()
);

export const addResidenceSuccess = createAction(
  '[Stratalot] Add Residence',
  props<{ idStratalot: number; idResidence: number }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[Stratalot] Delete Many Residence',
  props<{ idResidences: number[]; idStratalots: number[] }>()
);

export const addOccupantSuccess = createAction(
  '[Stratalot] Add Occupant',
  props<{ idStratalot: number; idOccupant: number }>()
);

export const deleteManyOccupantSuccess = createAction(
  '[Stratalot] Delete Many Occupant',
  props<{ idOccupants: number[]; idStratalots: number[] }>()
);

export const addCompanyStratalotTypeSuccess = createAction(
  '[Stratalot] Add CompanyStratalotType',
  props<{ idStratalot: number; idCompanyStratalotType: number }>()
);

export const deleteManyCompanyStratalotTypeSuccess = createAction(
  '[Stratalot] Delete Many CompanyStratalotType',
  props<{ idCompanyStratalotTypes: number[]; idStratalots: number[] }>()
);

export const addCompanyStratalotVacantSuccess = createAction(
  '[Stratalot] Add CompanyStratalotVacant',
  props<{ idStratalot: number; idCompanyStratalotVacant: number }>()
);

export const deleteManyCompanyStratalotVacantSuccess = createAction(
  '[Stratalot] Delete Many CompanyStratalotVacant',
  props<{ idCompanyStratalotVacants: number[]; idStratalots: number[] }>()
);

export const addOrganizationSaleCategorySuccess = createAction(
  '[Stratalot] Add OrganizationSaleCategory',
  props<{ idStratalot: number; idOrganizationSaleCategory: number }>()
);

export const deleteManyOrganizationSaleCategorySuccess = createAction(
  '[Stratalot] Delete Many OrganizationSaleCategory',
  props<{ idOrganizationSaleCategories: number[]; idStratalots: number[] }>()
);

export const addStepProgressSuccess = createAction(
  '[Stratalot] Add StepProgress',
  props<{ idStratalot: number; idStepProgress: number }>()
);

export const deleteManyStepProgressSuccess = createAction(
  '[Stratalot] Delete Many StepProgress',
  props<{ idStepProgresses: number[]; idStratalots: number[] }>()
);

export const addResponsableSuccess = createAction(
  '[Stratalot] Add Responsable',
  props<{ idStratalot: number; idResponsable: number }>()
);

export const deleteManyResponsableSuccess = createAction(
  '[Stratalot] Delete Many Responsable',
  props<{ idResponsable: number[]; idStratalots: number[] }>()
);

export const addOrganizationStratalotAvancementSuccess = createAction(
  '[Stratalot] Add OrganizationStratalotAvancement',
  props<{ idStratalot: number; idOrganizationStratalotAvancement: number }>()
);

export const deleteManyOrganizationStratalotAvancementSuccess = createAction(
  '[Stratalot] Delete Many OrganizationStratalotAvancement',
  props<{ idOrganizationStratalotAvancements: number[]; idStratalots: number[] }>()
);
