import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Diagnostic, DiagnosticEntityState } from '@_model/interfaces/diagnostic.model';
import {
  OrganizationStratalotTodo,
  OrganizationStratalotTodoEntityState
} from '@_model/interfaces/organization-stratalot-todo.model';
import {
  OrganizationResidenceTodo,
  OrganizationResidenceTodoEntityState
} from '@_model/interfaces/organization-residence-todo.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, diagnosticFeatureKey, DiagnosticState } from './diagnostic.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const diagnosticRelations: string[] = ['organizationStratalotTodos', 'organizationResidenceTodos'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectDiagnosticState = createFeatureSelector<DiagnosticState>(diagnosticFeatureKey);

export const selectIsLoadedDiagnostic = createSelector(
  selectDiagnosticState,
  (state: DiagnosticState) => state.isLoaded
);

export const selectIsLoadingDiagnostic = createSelector(
  selectDiagnosticState,
  (state: DiagnosticState) => state.isLoading
);

export const selectIsReadyDiagnostic = createSelector(
  selectDiagnosticState,
  (state: DiagnosticState) => !state.isLoading
);

export const selectIsReadyAndLoadedDiagnostic = createSelector(
  selectDiagnosticState,
  (state: DiagnosticState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const DiagnosticModel: SelectorModel = {
  name: 'diagnostics',
  getSelector: selectAllDiagnosticsDictionary,
  isReady: selectIsReadyDiagnostic
};

export const selectDiagnosticsEntities = createSelector(selectDiagnosticState, selectEntities);

export const selectDiagnosticsArray = createSelector(selectDiagnosticState, selectAll);

export const selectIdDiagnosticsActive = createSelector(
  selectDiagnosticState,
  (state: DiagnosticState) => state.actives
);

const diagnosticsInObject = (diagnostics: Dictionary<DiagnosticEntityState>) => ({ diagnostics });

const selectDiagnosticsEntitiesDictionary = createSelector(selectDiagnosticsEntities, diagnosticsInObject);

const selectAllDiagnosticsObject = createSelector(selectDiagnosticsEntities, diagnostics => {
  return hydrateAll({ diagnostics });
});

const selectOneDiagnosticDictionary = (idDiagnostic: number) =>
  createSelector(selectDiagnosticsEntities, diagnostics => {
    return { diagnostics: { [idDiagnostic]: diagnostics[idDiagnostic] } };
  });

const selectOneDiagnosticDictionaryWithoutChild = (idDiagnostic: number) =>
  createSelector(selectDiagnosticsEntities, diagnostics => {
    return { diagnostic: diagnostics[idDiagnostic] };
  });

const selectActiveDiagnosticsEntities = createSelector(
  selectIdDiagnosticsActive,
  selectDiagnosticsEntities,
  (actives: number[], diagnostics: Dictionary<DiagnosticEntityState>) => getDiagnosticsFromActives(actives, diagnostics)
);

function getDiagnosticsFromActives(
  actives: number[],
  diagnostics: Dictionary<DiagnosticEntityState>
): Dictionary<DiagnosticEntityState> {
  return actives.reduce((acc, idActive) => {
    if (diagnostics[idActive]) {
      acc[idActive] = diagnostics[idActive];
    }
    return acc;
  }, {} as Dictionary<DiagnosticEntityState>);
}

const selectAllDiagnosticsSelectors: Dictionary<Selector> = {};
export function selectAllDiagnostics(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Diagnostic>(
      schema,
      selectAllDiagnosticsSelectors,
      selectDiagnosticsEntitiesDictionary,
      getRelationSelectors,
      diagnosticRelations,
      hydrateAll,
      'diagnostic'
    );
  } else {
    return selectAllDiagnosticsObject;
  }
}

export function selectAllDiagnosticsDictionary(schema: SelectSchema = {}, customKey: string = 'diagnostics'): Selector {
  return createSelector(selectAllDiagnostics(schema), result => {
    const res = { [customKey]: {} as Dictionary<DiagnosticEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.diagnostics.length; i++) {
      res[customKey][result.diagnostics[i].idDiagnostic] = result.diagnostics[i];
    }
    return res;
  });
}

export function selectOneDiagnostic(schema: SelectSchema = {}, idDiagnostic: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneDiagnosticDictionary(idDiagnostic)];
    selectors.push(...getRelationSelectors(schema, diagnosticRelations, 'diagnostic'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneDiagnosticDictionaryWithoutChild(idDiagnostic);
  }
}

export function selectActiveDiagnostics(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [createSelector(selectActiveDiagnosticsEntities, diagnostics => ({ diagnostics }))];
  selectors.push(...getRelationSelectors(schema, diagnosticRelations, 'diagnostic'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  diagnostics: Dictionary<DiagnosticEntityState>;
  organizationStratalotTodos?: Dictionary<OrganizationStratalotTodoEntityState>;
  organizationResidenceTodos?: Dictionary<OrganizationResidenceTodoEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { diagnostics: (Diagnostic | null)[] } {
  const { diagnostics, organizationStratalotTodos, organizationResidenceTodos } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    diagnostics: Object.keys(diagnostics).map(idDiagnostic =>
      hydrate(
        diagnostics[idDiagnostic] as DiagnosticEntityState,
        organizationStratalotTodos,
        organizationResidenceTodos
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { diagnostic: DiagnosticEntityState | null } {
  const { diagnostics, organizationStratalotTodos, organizationResidenceTodos } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const diagnostic = Object.values(diagnostics)[0];
  return {
    diagnostic: hydrate(diagnostic as DiagnosticEntityState, organizationStratalotTodos, organizationResidenceTodos)
  };
}

function hydrate(
  diagnostic: DiagnosticEntityState,
  organizationStratalotTodoEntities?: Dictionary<OrganizationStratalotTodoEntityState>,
  organizationResidenceTodoEntities?: Dictionary<OrganizationResidenceTodoEntityState>
): Diagnostic | null {
  if (!diagnostic) {
    return null;
  }

  const diagnosticHydrated: DiagnosticEntityState = { ...diagnostic };

  if (organizationStratalotTodoEntities) {
    diagnosticHydrated.organizationStratalotTodos = (
      (diagnosticHydrated.organizationStratalotTodos as number[]) || []
    ).map(id => organizationStratalotTodoEntities[id]) as OrganizationStratalotTodo[];
  } else {
    delete diagnosticHydrated.organizationStratalotTodos;
  }

  if (organizationResidenceTodoEntities) {
    diagnosticHydrated.organizationResidenceTodos = (
      (diagnosticHydrated.organizationResidenceTodos as number[]) || []
    ).map(id => organizationResidenceTodoEntities[id]) as OrganizationResidenceTodo[];
  } else {
    delete diagnosticHydrated.organizationResidenceTodos;
  }

  return diagnosticHydrated as Diagnostic;
}
