import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CampaignMediaActions from './campaign-media.actions';
import { adapter, initialState, CampaignMediaState } from './campaign-media.state';
import { Dictionary } from '@ngrx/entity';
import { CampaignMediaEntityState } from '@_model/interfaces/campaign-media.model';

export const campaignMediaReducersGeneratedFunctions: ReducerTypes<CampaignMediaState, readonly ActionCreator[]>[] = [
  on(CampaignMediaActions.getOneCampaignMedia, (state: CampaignMediaState) => setLoadingsState(state, true)),
  on(CampaignMediaActions.getManyCampaignMedias, (state: CampaignMediaState) => setLoadingsState(state, true)),
  on(CampaignMediaActions.upsertOneCampaignMedia, (state: CampaignMediaState) => setLoadingsState(state, true)),

  on(CampaignMediaActions.upsertManyCampaignMediasSuccess, (state: CampaignMediaState, { campaignMedias }) =>
    adapter.upsertMany(campaignMedias, setLoadingsState(state, false))
  ),
  on(CampaignMediaActions.deleteOneCampaignMedia, (state: CampaignMediaState) => setLoadingsState(state, true)),
  on(CampaignMediaActions.deleteOneCampaignMediaSuccess, (state: CampaignMediaState, { idCampaignMedia }) =>
    adapter.removeOne(idCampaignMedia, setLoadingsState(state, false))
  ),
  on(CampaignMediaActions.clearActive, (state: CampaignMediaState) => ({ ...state, actives: [] })),
  on(CampaignMediaActions.addManyActives, (state: CampaignMediaState, { idCampaignMedias }) => ({
    ...state,
    actives: state.actives.concat(idCampaignMedias)
  })),
  on(CampaignMediaActions.deleteOneActive, (state: CampaignMediaState, { idCampaignMedia }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCampaignMedia)
  })),

  on(CampaignMediaActions.clearStore, () => initialState),

  on(CampaignMediaActions.addCompanyMediaSuccess, (state: CampaignMediaState, { idCampaignMedia, idCompanyMedia }) => {
    if (!state.entities[idCampaignMedia]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCampaignMedia]: {
          ...state.entities[idCampaignMedia],
          companyMedia: idCompanyMedia
        }
      }
    };
  }),

  on(
    CampaignMediaActions.deleteManyCompanyMediaSuccess,
    (state: CampaignMediaState, { idCompanyMedias, idCampaignMedias }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCampaignMedias.reduce((entities, idCampaignMedia) => {
            if (!state.entities[idCampaignMedia]?.companyMedia) {
              return entities;
            }
            entities[idCampaignMedia] = {
              ...state.entities[idCampaignMedia],
              companyMedia: idCompanyMedias.some(
                (idCompanyMedia: number) => idCompanyMedia === state.entities[idCampaignMedia]?.companyMedia
              )
                ? undefined
                : state.entities[idCampaignMedia]?.companyMedia
            } as CampaignMediaEntityState;
            return entities;
          }, {} as Dictionary<CampaignMediaEntityState>)
        }
      };
    }
  ),

  on(
    CampaignMediaActions.addStratalotCampaignSuccess,
    (state: CampaignMediaState, { idCampaignMedia, idStratalotCampaign }) => {
      if (!state.entities[idCampaignMedia]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCampaignMedia]: {
            ...state.entities[idCampaignMedia],
            stratalotCampaign: idStratalotCampaign
          }
        }
      };
    }
  ),

  on(
    CampaignMediaActions.deleteManyStratalotCampaignSuccess,
    (state: CampaignMediaState, { idStratalotCampaigns, idCampaignMedias }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCampaignMedias.reduce((entities, idCampaignMedia) => {
            if (!state.entities[idCampaignMedia]?.stratalotCampaign) {
              return entities;
            }
            entities[idCampaignMedia] = {
              ...state.entities[idCampaignMedia],
              stratalotCampaign: idStratalotCampaigns.some(
                (idStratalotCampaign: number) =>
                  idStratalotCampaign === state.entities[idCampaignMedia]?.stratalotCampaign
              )
                ? undefined
                : state.entities[idCampaignMedia]?.stratalotCampaign
            } as CampaignMediaEntityState;
            return entities;
          }, {} as Dictionary<CampaignMediaEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CampaignMediaState,
  isLoading: boolean,
  isLoaded: boolean = true
): CampaignMediaState {
  return { ...state, isLoaded, isLoading };
}
