import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationStratalotTodo,
  OrganizationStratalotTodoEntityState
} from '@_model/interfaces/organization-stratalot-todo.model';
import { OrganizationStratalotTodoRelationsIds } from './organization-stratalot-todo-generated.effects';

export const getOneOrganizationStratalotTodo = createAction(
  '[OrganizationStratalotTodo] Get One OrganizationStratalotTodo',
  props<{ idOrganizationStratalotTodo: number; params?: any }>()
);

export const getManyOrganizationStratalotTodos = createAction(
  '[OrganizationStratalotTodo] Get Many OrganizationStratalotTodos',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationStratalotTodo] Add Many Actives OrganizationStratalotTodo',
  props<{ idOrganizationStratalotTodos: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationStratalotTodo] Delete One Active OrganizationStratalotTodo',
  props<{ idOrganizationStratalotTodo: number }>()
);

export const clearActive = createAction('[OrganizationStratalotTodo] Clear Active OrganizationStratalotTodo');

export const upsertOneOrganizationStratalotTodo = createAction(
  '[OrganizationStratalotTodo] Upsert One OrganizationStratalotTodo',
  props<{
    organizationStratalotTodo: Partial<OrganizationStratalotTodo>;
    ids?: OrganizationStratalotTodoRelationsIds;
  }>()
);

export const upsertManyOrganizationStratalotTodos = createAction(
  '[OrganizationStratalotTodo] Upsert Many OrganizationStratalotTodos',
  props<{
    organizationStratalotTodos: Partial<OrganizationStratalotTodo>[];
    ids?: OrganizationStratalotTodoRelationsIds;
  }>()
);

export const upsertManyOrganizationStratalotTodosSuccess = createAction(
  '[OrganizationStratalotTodo] Create Many OrganizationStratalotTodos Success',
  props<{ organizationStratalotTodos: OrganizationStratalotTodoEntityState[] }>()
);

export const deleteOneOrganizationStratalotTodo = createAction(
  '[OrganizationStratalotTodo] Delete One OrganizationStratalotTodo',
  props<{ idOrganizationStratalotTodo: number }>()
);

export const deleteOneOrganizationStratalotTodoSuccess = createAction(
  '[OrganizationStratalotTodo] Delete One OrganizationStratalotTodo Success',
  props<{ idOrganizationStratalotTodo: number }>()
);

export const normalizeManyOrganizationStratalotTodosAfterUpsert = createAction(
  '[OrganizationStratalotTodo] Normalize Many OrganizationStratalotTodos After Upsert',
  props<{ organizationStratalotTodos: OrganizationStratalotTodoEntityState[] }>()
);

export const organizationStratalotTodosFailure = createAction(
  '[OrganizationStratalotTodo] OrganizationStratalotTodos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationStratalotTodo] Clear OrganizationStratalotTodos');

export const addManyStratalotTodoSuccess = createAction(
  '[OrganizationStratalotTodo] Add Many stratalot-todo',
  props<{ idOrganizationStratalotTodo: number; idStratalotTodos: number[] }>()
);

export const deleteManyStratalotTodoSuccess = createAction(
  '[OrganizationStratalotTodo] Delete Many StratalotTodos',
  props<{ idStratalotTodos: number[]; idOrganizationStratalotTodos: number[] }>()
);

export const addManyOrganizationStratalotTodoRuleSuccess = createAction(
  '[OrganizationStratalotTodo] Add Many organization-stratalot-todo-rule',
  props<{ idOrganizationStratalotTodo: number; idOrganizationStratalotTodoRules: number[] }>()
);

export const deleteManyOrganizationStratalotTodoRuleSuccess = createAction(
  '[OrganizationStratalotTodo] Delete Many OrganizationStratalotTodoRules',
  props<{ idOrganizationStratalotTodoRules: number[]; idOrganizationStratalotTodos: number[] }>()
);

export const addManyOrganizationStratalotTodoAppliedRuleSuccess = createAction(
  '[OrganizationStratalotTodo] Add Many organization-stratalot-todo-applied-rule',
  props<{ idOrganizationStratalotTodo: number; idOrganizationStratalotTodoAppliedRules: number[] }>()
);

export const deleteManyOrganizationStratalotTodoAppliedRuleSuccess = createAction(
  '[OrganizationStratalotTodo] Delete Many OrganizationStratalotTodoAppliedRules',
  props<{ idOrganizationStratalotTodoAppliedRules: number[]; idOrganizationStratalotTodos: number[] }>()
);

export const addManyOrganizationStratalotTodoProfilSuccess = createAction(
  '[OrganizationStratalotTodo] Add Many organization-stratalot-todo-profil',
  props<{ idOrganizationStratalotTodo: number; idOrganizationStratalotTodoProfils: number[] }>()
);

export const deleteManyOrganizationStratalotTodoProfilSuccess = createAction(
  '[OrganizationStratalotTodo] Delete Many OrganizationStratalotTodoProfils',
  props<{ idOrganizationStratalotTodoProfils: number[]; idOrganizationStratalotTodos: number[] }>()
);

export const addManyCompanyCommunicationSuccess = createAction(
  '[OrganizationStratalotTodo] Add Many company-communication',
  props<{ idOrganizationStratalotTodo: number; idCompanyCommunications: number[] }>()
);

export const deleteManyCompanyCommunicationSuccess = createAction(
  '[OrganizationStratalotTodo] Delete Many CompanyCommunications',
  props<{ idCompanyCommunications: number[]; idOrganizationStratalotTodos: number[] }>()
);

export const addManyCompanyCommunicationStratalotTodoSuccess = createAction(
  '[OrganizationStratalotTodo] Add Many company-communication-stratalot-todo',
  props<{ idOrganizationStratalotTodo: number; idCompanyCommunicationStratalotTodos: number[] }>()
);

export const deleteManyCompanyCommunicationStratalotTodoSuccess = createAction(
  '[OrganizationStratalotTodo] Delete Many CompanyCommunicationStratalotTodos',
  props<{ idCompanyCommunicationStratalotTodos: number[]; idOrganizationStratalotTodos: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[OrganizationStratalotTodo] Add Organization',
  props<{ idOrganizationStratalotTodo: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationStratalotTodo] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationStratalotTodos: number[] }>()
);

export const addDiagnosticSuccess = createAction(
  '[OrganizationStratalotTodo] Add Diagnostic',
  props<{ idOrganizationStratalotTodo: number; idDiagnostic: number }>()
);

export const deleteManyDiagnosticSuccess = createAction(
  '[OrganizationStratalotTodo] Delete Many Diagnostic',
  props<{ idDiagnostics: number[]; idOrganizationStratalotTodos: number[] }>()
);
