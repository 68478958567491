import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationMetier, OrganizationMetierEntityState } from '@_model/interfaces/organization-metier.model';
import {
  OrganizationThirdParty,
  OrganizationThirdPartyEntityState
} from '@_model/interfaces/organization-third-party.model';
import {
  CompanyCommunicationRecipient,
  CompanyCommunicationRecipientEntityState
} from '@_model/interfaces/company-communication-recipient.model';
import {
  CompanyCommunicationRecipientMetier,
  CompanyCommunicationRecipientMetierEntityState
} from '@_model/interfaces/company-communication-recipient-metier.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { MetierFamilie, MetierFamilieEntityState } from '@_model/interfaces/metier-familie.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, organizationMetierFeatureKey, OrganizationMetierState } from './organization-metier.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationMetierRelations: string[] = [
  'organizationThirdParties',
  'companyCommunicationRecipients',
  'companyCommunicationRecipientMetiers',
  'organizations',
  'metierFamilies'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationMetierState =
  createFeatureSelector<OrganizationMetierState>(organizationMetierFeatureKey);

export const selectIsLoadedOrganizationMetier = createSelector(
  selectOrganizationMetierState,
  (state: OrganizationMetierState) => state.isLoaded
);

export const selectIsLoadingOrganizationMetier = createSelector(
  selectOrganizationMetierState,
  (state: OrganizationMetierState) => state.isLoading
);

export const selectIsReadyOrganizationMetier = createSelector(
  selectOrganizationMetierState,
  (state: OrganizationMetierState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationMetier = createSelector(
  selectOrganizationMetierState,
  (state: OrganizationMetierState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationMetierModel: SelectorModel = {
  name: 'organizationMetiers',
  getSelector: selectAllOrganizationMetiersDictionary,
  isReady: selectIsReadyOrganizationMetier
};

export const selectOrganizationMetiersEntities = createSelector(selectOrganizationMetierState, selectEntities);

export const selectOrganizationMetiersArray = createSelector(selectOrganizationMetierState, selectAll);

export const selectIdOrganizationMetiersActive = createSelector(
  selectOrganizationMetierState,
  (state: OrganizationMetierState) => state.actives
);

const organizationMetiersInObject = (organizationMetiers: Dictionary<OrganizationMetierEntityState>) => ({
  organizationMetiers
});

const selectOrganizationMetiersEntitiesDictionary = createSelector(
  selectOrganizationMetiersEntities,
  organizationMetiersInObject
);

const selectAllOrganizationMetiersObject = createSelector(selectOrganizationMetiersEntities, organizationMetiers => {
  return hydrateAll({ organizationMetiers });
});

const selectOneOrganizationMetierDictionary = (idOrganizationMetier: number) =>
  createSelector(selectOrganizationMetiersEntities, organizationMetiers => {
    return { organizationMetiers: { [idOrganizationMetier]: organizationMetiers[idOrganizationMetier] } };
  });

const selectOneOrganizationMetierDictionaryWithoutChild = (idOrganizationMetier: number) =>
  createSelector(selectOrganizationMetiersEntities, organizationMetiers => {
    return { organizationMetier: organizationMetiers[idOrganizationMetier] };
  });

const selectActiveOrganizationMetiersEntities = createSelector(
  selectIdOrganizationMetiersActive,
  selectOrganizationMetiersEntities,
  (actives: number[], organizationMetiers: Dictionary<OrganizationMetierEntityState>) =>
    getOrganizationMetiersFromActives(actives, organizationMetiers)
);

function getOrganizationMetiersFromActives(
  actives: number[],
  organizationMetiers: Dictionary<OrganizationMetierEntityState>
): Dictionary<OrganizationMetierEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationMetiers[idActive]) {
      acc[idActive] = organizationMetiers[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationMetierEntityState>);
}

const selectAllOrganizationMetiersSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationMetiers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationMetier>(
      schema,
      selectAllOrganizationMetiersSelectors,
      selectOrganizationMetiersEntitiesDictionary,
      getRelationSelectors,
      organizationMetierRelations,
      hydrateAll,
      'organizationMetier'
    );
  } else {
    return selectAllOrganizationMetiersObject;
  }
}

export function selectAllOrganizationMetiersDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationMetiers'
): Selector {
  return createSelector(selectAllOrganizationMetiers(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationMetierEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationMetiers.length; i++) {
      res[customKey][result.organizationMetiers[i].idOrganizationMetier] = result.organizationMetiers[i];
    }
    return res;
  });
}

export function selectOneOrganizationMetier(schema: SelectSchema = {}, idOrganizationMetier: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationMetierDictionary(idOrganizationMetier)];
    selectors.push(...getRelationSelectors(schema, organizationMetierRelations, 'organizationMetier'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationMetierDictionaryWithoutChild(idOrganizationMetier);
  }
}

export function selectActiveOrganizationMetiers(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationMetiersEntities, organizationMetiers => ({ organizationMetiers }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationMetierRelations, 'organizationMetier'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationMetiers: Dictionary<OrganizationMetierEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  metierFamilies?: Dictionary<MetierFamilieEntityState>;
  organizationThirdParties?: Dictionary<OrganizationThirdPartyEntityState>;
  companyCommunicationRecipients?: Dictionary<CompanyCommunicationRecipientEntityState>;
  companyCommunicationRecipientMetiers?: Dictionary<CompanyCommunicationRecipientMetierEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationMetiers: (OrganizationMetier | null)[] } {
  const {
    organizationMetiers,
    organizations,
    metierFamilies,
    organizationThirdParties,
    companyCommunicationRecipients,
    companyCommunicationRecipientMetiers
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationMetiers: Object.keys(organizationMetiers).map(idOrganizationMetier =>
      hydrate(
        organizationMetiers[idOrganizationMetier] as OrganizationMetierEntityState,
        organizations,
        metierFamilies,
        organizationThirdParties,
        companyCommunicationRecipients,
        companyCommunicationRecipientMetiers
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationMetier: OrganizationMetierEntityState | null } {
  const {
    organizationMetiers,
    organizations,
    metierFamilies,
    organizationThirdParties,
    companyCommunicationRecipients,
    companyCommunicationRecipientMetiers
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationMetier = Object.values(organizationMetiers)[0];
  return {
    organizationMetier: hydrate(
      organizationMetier as OrganizationMetierEntityState,
      organizations,
      metierFamilies,
      organizationThirdParties,
      companyCommunicationRecipients,
      companyCommunicationRecipientMetiers
    )
  };
}

function hydrate(
  organizationMetier: OrganizationMetierEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  metierFamilieEntities?: Dictionary<MetierFamilieEntityState>,
  organizationThirdPartyEntities?: Dictionary<OrganizationThirdPartyEntityState>,
  companyCommunicationRecipientEntities?: Dictionary<CompanyCommunicationRecipientEntityState>,
  companyCommunicationRecipientMetierEntities?: Dictionary<CompanyCommunicationRecipientMetierEntityState>
): OrganizationMetier | null {
  if (!organizationMetier) {
    return null;
  }

  const organizationMetierHydrated: OrganizationMetierEntityState = { ...organizationMetier };
  if (organizationEntities) {
    organizationMetierHydrated.organization = organizationEntities[
      organizationMetier.organization as number
    ] as Organization;
  } else {
    delete organizationMetierHydrated.organization;
  }
  if (metierFamilieEntities) {
    organizationMetierHydrated.metierFamilie = metierFamilieEntities[
      organizationMetier.metierFamilie as number
    ] as MetierFamilie;
  } else {
    delete organizationMetierHydrated.metierFamilie;
  }

  if (organizationThirdPartyEntities) {
    organizationMetierHydrated.organizationThirdParties = (
      (organizationMetierHydrated.organizationThirdParties as number[]) || []
    ).map(id => organizationThirdPartyEntities[id]) as OrganizationThirdParty[];
  } else {
    delete organizationMetierHydrated.organizationThirdParties;
  }

  if (companyCommunicationRecipientEntities) {
    organizationMetierHydrated.companyCommunicationRecipients = (
      (organizationMetierHydrated.companyCommunicationRecipients as number[]) || []
    ).map(id => companyCommunicationRecipientEntities[id]) as CompanyCommunicationRecipient[];
  } else {
    delete organizationMetierHydrated.companyCommunicationRecipients;
  }

  if (companyCommunicationRecipientMetierEntities) {
    organizationMetierHydrated.companyCommunicationRecipientMetiers = (
      (organizationMetierHydrated.companyCommunicationRecipientMetiers as number[]) || []
    ).map(id => companyCommunicationRecipientMetierEntities[id]) as CompanyCommunicationRecipientMetier[];
  } else {
    delete organizationMetierHydrated.companyCommunicationRecipientMetiers;
  }

  return organizationMetierHydrated as OrganizationMetier;
}
