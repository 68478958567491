import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as AppState from '@_store/index.reducers';
import { GeneratedForecastPeriodService } from './forecast-period-generated.service';
import { Actions } from '@ngrx/effects';
import { ForecastPeriodApiService } from '@_services/api/forecast-period-api.service';
import { Observable } from 'rxjs';
import { ForecastPeriod } from '@_model/interfaces/forecast-period.model';

@Injectable({
  providedIn: 'root'
})
export class ForecastPeriodService extends GeneratedForecastPeriodService {
  constructor(
    store$: Store<AppState.AppState>,
    actions$: Actions,
    private readonly forecastPeriodApiService: ForecastPeriodApiService
  ) {
    super(store$, actions$);
  }

  fillAllResidenceForecast(): Observable<void> {
    return this.forecastPeriodApiService.fillAllResidenceForecast();
  }

  getLockedYearForOrganization(idOrganization: number): Observable<ForecastPeriod[]> {
    return this.forecastPeriodApiService.getLockedYearForOrganization(idOrganization);
  }

  lockForecastPeriod(idForecastPeriod: number, idOrganization: number): Observable<ForecastPeriod[]> {
    return this.forecastPeriodApiService.lockForecastPeriod({ idForecastPeriod, idOrganization });
  }

  unLockForecastPeriod(idForecastPeriod: number, idOrganization: number): Observable<ForecastPeriod[]> {
    return this.forecastPeriodApiService.unLockForecastPeriod({ idForecastPeriod, idOrganization });
  }
}
