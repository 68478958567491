import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationThirdParty } from '@_model/interfaces/organization-third-party.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationThirdPartyApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationThirdParties(params?: any): Observable<OrganizationThirdParty[]> {
    return this.repo.getData<OrganizationThirdParty[]>('organization-third-party', params);
  }

  public getOrganizationThirdParty(params: {
    idOrganizationThirdParty: number;
    params?: any;
  }): Observable<OrganizationThirdParty> {
    return this.repo.getData<OrganizationThirdParty>(
      'organization-third-party/' + params.idOrganizationThirdParty,
      params.params
    );
  }

  public addOrganizationThirdParty(
    organizationThirdParty: Partial<OrganizationThirdParty>
  ): Observable<OrganizationThirdParty> {
    return this.repo.create<OrganizationThirdParty>('organization-third-party', organizationThirdParty);
  }

  public updateOrganizationThirdParty(
    organizationThirdParty: Partial<OrganizationThirdParty>
  ): Observable<OrganizationThirdParty> {
    return this.repo.update('organization-third-party', organizationThirdParty);
  }

  public deleteOrganizationThirdParty(idOrganizationThirdParty: number): Observable<number> {
    return this.repo.delete('organization-third-party/' + +idOrganizationThirdParty);
  }
}
