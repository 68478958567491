import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LeadStratalotApiService } from '@_services/api/lead-stratalot-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedLeadStratalotEffects } from './lead-stratalot-generated.effects';

@Injectable()
export class LeadStratalotEffects extends GeneratedLeadStratalotEffects {
  constructor(actions$: Actions, leadStratalotApiService: LeadStratalotApiService, store$: Store<AppState>) {
    super(actions$, leadStratalotApiService, store$);
  }
}
