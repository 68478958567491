import { OrganizationLeadTodoRuleState, initialState } from './organization-lead-todo-rule.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationLeadTodoRuleReducersGeneratedFunctions } from './organization-lead-todo-rule-generated.reducer';

const organizationLeadTodoRuleReducersFunctions = [...organizationLeadTodoRuleReducersGeneratedFunctions];

const organizationLeadTodoRuleReducer = createReducer(initialState, ...organizationLeadTodoRuleReducersFunctions);

export function reducer(state: OrganizationLeadTodoRuleState | undefined, action: Action) {
  return organizationLeadTodoRuleReducer(state, action);
}
