import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Prospect, ProspectEntityState } from '@_model/interfaces/prospect.model';
import { ProspectRelationsIds } from './prospect-generated.effects';

export const getOneProspect = createAction(
  '[Prospect] Get One Prospect',
  props<{ idProspect: number; params?: any }>()
);

export const getManyProspects = createAction('[Prospect] Get Many Prospects', props<{ params: any }>());

export const addManyActives = createAction('[Prospect] Add Many Actives Prospect', props<{ idProspects: number[] }>());

export const deleteOneActive = createAction('[Prospect] Delete One Active Prospect', props<{ idProspect: number }>());

export const clearActive = createAction('[Prospect] Clear Active Prospect');

export const upsertOneProspect = createAction(
  '[Prospect] Upsert One Prospect',
  props<{
    prospect: Partial<Prospect>;
    ids?: ProspectRelationsIds;
  }>()
);

export const upsertManyProspects = createAction(
  '[Prospect] Upsert Many Prospects',
  props<{
    prospects: Partial<Prospect>[];
    ids?: ProspectRelationsIds;
  }>()
);

export const upsertManyProspectsSuccess = createAction(
  '[Prospect] Create Many Prospects Success',
  props<{ prospects: ProspectEntityState[] }>()
);

export const deleteOneProspect = createAction('[Prospect] Delete One Prospect', props<{ idProspect: number }>());

export const deleteOneProspectSuccess = createAction(
  '[Prospect] Delete One Prospect Success',
  props<{ idProspect: number }>()
);

export const normalizeManyProspectsAfterUpsert = createAction(
  '[Prospect] Normalize Many Prospects After Upsert',
  props<{ prospects: ProspectEntityState[] }>()
);

export const prospectsFailure = createAction('[Prospect] Prospects Failure', props<{ error: HttpErrorResponse }>());

export const clearStore = createAction('[Prospect] Clear Prospects');

export const addManyProspectEventSuccess = createAction(
  '[Prospect] Add Many prospect-event',
  props<{ idProspect: number; idProspectEvents: number[] }>()
);

export const deleteManyProspectEventSuccess = createAction(
  '[Prospect] Delete Many ProspectEvents',
  props<{ idProspectEvents: number[]; idProspects: number[] }>()
);

export const addManyProspectBuyingWishSuccess = createAction(
  '[Prospect] Add Many prospect-buying-wish',
  props<{ idProspect: number; idProspectBuyingWishs: number[] }>()
);

export const deleteManyProspectBuyingWishSuccess = createAction(
  '[Prospect] Delete Many ProspectBuyingWishs',
  props<{ idProspectBuyingWishs: number[]; idProspects: number[] }>()
);

export const addManyLeadSuccess = createAction(
  '[Prospect] Add Many lead',
  props<{ idProspect: number; idLeads: number[] }>()
);

export const deleteManyLeadSuccess = createAction(
  '[Prospect] Delete Many Leads',
  props<{ idLeads: number[]; idProspects: number[] }>()
);

export const addOccupantSuccess = createAction(
  '[Prospect] Add Occupant',
  props<{ idProspect: number; idOccupant: number }>()
);

export const deleteManyOccupantSuccess = createAction(
  '[Prospect] Delete Many Occupant',
  props<{ idOccupants: number[]; idProspects: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[Prospect] Add Organization',
  props<{ idProspect: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[Prospect] Delete Many Organization',
  props<{ idOrganizations: number[]; idProspects: number[] }>()
);

export const addOrganizationProspectOriginSuccess = createAction(
  '[Prospect] Add OrganizationProspectOrigin',
  props<{ idProspect: number; idOrganizationProspectOrigin: number }>()
);

export const deleteManyOrganizationProspectOriginSuccess = createAction(
  '[Prospect] Delete Many OrganizationProspectOrigin',
  props<{ idOrganizationProspectOrigins: number[]; idProspects: number[] }>()
);

export const addResponsableSuccess = createAction(
  '[Prospect] Add Responsable',
  props<{ idProspect: number; idResponsable: number }>()
);

export const deleteManyResponsableSuccess = createAction(
  '[Prospect] Delete Many Responsable',
  props<{ idResponsable: number[]; idProspects: number[] }>()
);

export const addUpdatedUserSuccess = createAction(
  '[Prospect] Add UpdatedUser',
  props<{ idProspect: number; idUpdatedUser: number }>()
);

export const deleteManyUpdatedUserSuccess = createAction(
  '[Prospect] Delete Many UpdatedUser',
  props<{ idUpdatedUser: number[]; idProspects: number[] }>()
);

export const addCreatedUserSuccess = createAction(
  '[Prospect] Add CreatedUser',
  props<{ idProspect: number; idCreatedUser: number }>()
);

export const deleteManyCreatedUserSuccess = createAction(
  '[Prospect] Delete Many CreatedUser',
  props<{ idCreatedUser: number[]; idProspects: number[] }>()
);
