import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationStratalotTodoRule,
  OrganizationStratalotTodoRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-rule.model';
import { OrganizationStratalotTodoRuleRelationsIds } from './organization-stratalot-todo-rule-generated.effects';

export const getOneOrganizationStratalotTodoRule = createAction(
  '[OrganizationStratalotTodoRule] Get One OrganizationStratalotTodoRule',
  props<{ idOrganizationStratalotTodoRule: number; params?: any }>()
);

export const getManyOrganizationStratalotTodoRules = createAction(
  '[OrganizationStratalotTodoRule] Get Many OrganizationStratalotTodoRules',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationStratalotTodoRule] Add Many Actives OrganizationStratalotTodoRule',
  props<{ idOrganizationStratalotTodoRules: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationStratalotTodoRule] Delete One Active OrganizationStratalotTodoRule',
  props<{ idOrganizationStratalotTodoRule: number }>()
);

export const clearActive = createAction('[OrganizationStratalotTodoRule] Clear Active OrganizationStratalotTodoRule');

export const upsertOneOrganizationStratalotTodoRule = createAction(
  '[OrganizationStratalotTodoRule] Upsert One OrganizationStratalotTodoRule',
  props<{
    organizationStratalotTodoRule: Partial<OrganizationStratalotTodoRule>;
    ids?: OrganizationStratalotTodoRuleRelationsIds;
  }>()
);

export const upsertManyOrganizationStratalotTodoRules = createAction(
  '[OrganizationStratalotTodoRule] Upsert Many OrganizationStratalotTodoRules',
  props<{
    organizationStratalotTodoRules: Partial<OrganizationStratalotTodoRule>[];
    ids?: OrganizationStratalotTodoRuleRelationsIds;
  }>()
);

export const upsertManyOrganizationStratalotTodoRulesSuccess = createAction(
  '[OrganizationStratalotTodoRule] Create Many OrganizationStratalotTodoRules Success',
  props<{ organizationStratalotTodoRules: OrganizationStratalotTodoRuleEntityState[] }>()
);

export const deleteOneOrganizationStratalotTodoRule = createAction(
  '[OrganizationStratalotTodoRule] Delete One OrganizationStratalotTodoRule',
  props<{ idOrganizationStratalotTodoRule: number }>()
);

export const deleteOneOrganizationStratalotTodoRuleSuccess = createAction(
  '[OrganizationStratalotTodoRule] Delete One OrganizationStratalotTodoRule Success',
  props<{ idOrganizationStratalotTodoRule: number }>()
);

export const normalizeManyOrganizationStratalotTodoRulesAfterUpsert = createAction(
  '[OrganizationStratalotTodoRule] Normalize Many OrganizationStratalotTodoRules After Upsert',
  props<{ organizationStratalotTodoRules: OrganizationStratalotTodoRuleEntityState[] }>()
);

export const organizationStratalotTodoRulesFailure = createAction(
  '[OrganizationStratalotTodoRule] OrganizationStratalotTodoRules Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationStratalotTodoRule] Clear OrganizationStratalotTodoRules');

export const addManyOrganizationStratalotTodoSuccess = createAction(
  '[OrganizationStratalotTodoRule] Add Many organization-stratalot-todo',
  props<{ idOrganizationStratalotTodoRule: number; idOrganizationStratalotTodos: number[] }>()
);

export const deleteManyOrganizationStratalotTodoSuccess = createAction(
  '[OrganizationStratalotTodoRule] Delete Many OrganizationStratalotTodos',
  props<{ idOrganizationStratalotTodos: number[]; idOrganizationStratalotTodoRules: number[] }>()
);

export const addManyOrganizationStratalotTodoAppliedRuleSuccess = createAction(
  '[OrganizationStratalotTodoRule] Add Many organization-stratalot-todo-applied-rule',
  props<{ idOrganizationStratalotTodoRule: number; idOrganizationStratalotTodoAppliedRules: number[] }>()
);

export const deleteManyOrganizationStratalotTodoAppliedRuleSuccess = createAction(
  '[OrganizationStratalotTodoRule] Delete Many OrganizationStratalotTodoAppliedRules',
  props<{ idOrganizationStratalotTodoAppliedRules: number[]; idOrganizationStratalotTodoRules: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[OrganizationStratalotTodoRule] Add Organization',
  props<{ idOrganizationStratalotTodoRule: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationStratalotTodoRule] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationStratalotTodoRules: number[] }>()
);

export const addOrganizationSaleCategorySuccess = createAction(
  '[OrganizationStratalotTodoRule] Add OrganizationSaleCategory',
  props<{ idOrganizationStratalotTodoRule: number; idOrganizationSaleCategory: number }>()
);

export const deleteManyOrganizationSaleCategorySuccess = createAction(
  '[OrganizationStratalotTodoRule] Delete Many OrganizationSaleCategory',
  props<{ idOrganizationSaleCategories: number[]; idOrganizationStratalotTodoRules: number[] }>()
);

export const addSaleCategoryFamilySuccess = createAction(
  '[OrganizationStratalotTodoRule] Add SaleCategoryFamily',
  props<{ idOrganizationStratalotTodoRule: number; idSaleCategoryFamily: number }>()
);

export const deleteManySaleCategoryFamilySuccess = createAction(
  '[OrganizationStratalotTodoRule] Delete Many SaleCategoryFamily',
  props<{ idFamilySaleCategories: number[]; idOrganizationStratalotTodoRules: number[] }>()
);

export const addCompanySuccess = createAction(
  '[OrganizationStratalotTodoRule] Add Company',
  props<{ idOrganizationStratalotTodoRule: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[OrganizationStratalotTodoRule] Delete Many Company',
  props<{ idCompanies: number[]; idOrganizationStratalotTodoRules: number[] }>()
);

export const addCompanyStratalotTypeSuccess = createAction(
  '[OrganizationStratalotTodoRule] Add CompanyStratalotType',
  props<{ idOrganizationStratalotTodoRule: number; idCompanyStratalotType: number }>()
);

export const deleteManyCompanyStratalotTypeSuccess = createAction(
  '[OrganizationStratalotTodoRule] Delete Many CompanyStratalotType',
  props<{ idCompanyStratalotTypes: number[]; idOrganizationStratalotTodoRules: number[] }>()
);

export const addStratalotTypeSuccess = createAction(
  '[OrganizationStratalotTodoRule] Add StratalotType',
  props<{ idOrganizationStratalotTodoRule: number; idStratalotType: number }>()
);

export const deleteManyStratalotTypeSuccess = createAction(
  '[OrganizationStratalotTodoRule] Delete Many StratalotType',
  props<{ idStratalotTypes: number[]; idOrganizationStratalotTodoRules: number[] }>()
);
