import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationStratalotTodo } from '@_model/interfaces/organization-stratalot-todo.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationStratalotTodoApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationStratalotTodos(params?: any): Observable<OrganizationStratalotTodo[]> {
    return this.repo.getData<OrganizationStratalotTodo[]>('organization-stratalot-todo', params);
  }

  public getOrganizationStratalotTodo(params: {
    idOrganizationStratalotTodo: number;
    params?: any;
  }): Observable<OrganizationStratalotTodo> {
    return this.repo.getData<OrganizationStratalotTodo>(
      'organization-stratalot-todo/' + params.idOrganizationStratalotTodo,
      params.params
    );
  }

  public addOrganizationStratalotTodo(
    organizationStratalotTodo: Partial<OrganizationStratalotTodo>
  ): Observable<OrganizationStratalotTodo> {
    return this.repo.create<OrganizationStratalotTodo>('organization-stratalot-todo', organizationStratalotTodo);
  }

  public updateOrganizationStratalotTodo(
    organizationStratalotTodo: Partial<OrganizationStratalotTodo>
  ): Observable<OrganizationStratalotTodo> {
    return this.repo.update('organization-stratalot-todo', organizationStratalotTodo);
  }

  public deleteOrganizationStratalotTodo(idOrganizationStratalotTodo: number): Observable<number> {
    return this.repo.delete('organization-stratalot-todo/' + +idOrganizationStratalotTodo);
  }
}
