import { AssociationState, initialState } from './association.state';
import { Action, createReducer } from '@ngrx/store';
import { associationReducersGeneratedFunctions } from './association-generated.reducer';

const associationReducersFunctions = [...associationReducersGeneratedFunctions];

const associationReducer = createReducer(initialState, ...associationReducersFunctions);

export function reducer(state: AssociationState | undefined, action: Action) {
  return associationReducer(state, action);
}
