import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  CompanyStratalotVacant,
  CompanyStratalotVacantEntityState
} from '@_model/interfaces/company-stratalot-vacant.model';
import { CompanyStratalotVacantApiService } from '@_services/api/company-stratalot-vacant-api.service';
import * as companyStratalotVacantActions from '@_store/company-stratalot-vacant/company-stratalot-vacant.actions';
import { getActionsToNormalizeCompanyStratalotVacant } from '@_config/store/normalization.generated';
import { selectCompanyStratalotVacantState } from './company-stratalot-vacant-generated.selectors';
import * as companyActions from '@_store/company/company.actions';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import { Stratalot } from '@_model/interfaces/stratalot.model';

export interface CompanyStratalotVacantRelationsIds {
  stratalots?: number | number[];
  company?: number;
}

export function getDefaultAddCompanyStratalotVacantActions(
  companyStratalotVacant: CompanyStratalotVacantEntityState,
  ids?: CompanyStratalotVacantRelationsIds
): Action[] {
  const actions: Action[] = [
    companyStratalotVacantActions.normalizeManyCompanyStratalotVacantsAfterUpsert({
      companyStratalotVacants: [companyStratalotVacant]
    })
  ];

  if (ids?.company) {
    actions.push(
      companyActions.addManyCompanyStratalotVacantSuccess({
        idCompany: ids.company,
        idCompanyStratalotVacants: [companyStratalotVacant.idCompanyStratalotVacant]
      })
    );
    actions.push(
      companyStratalotVacantActions.addCompanySuccess({
        idCompanyStratalotVacant: companyStratalotVacant.idCompanyStratalotVacant,
        idCompany: ids.company
      })
    );
  }

  if (ids?.stratalots) {
    if (!Array.isArray(ids.stratalots)) {
      actions.push(
        stratalotActions.upsertOneStratalot({
          stratalot: {
            idCompanyStratalotVacant: companyStratalotVacant.idCompanyStratalotVacant,
            idStratalot: ids.stratalots as number
          } as Stratalot
        })
      );
      actions.push(
        companyStratalotVacantActions.addManyStratalotSuccess({
          idCompanyStratalotVacant: companyStratalotVacant.idCompanyStratalotVacant,
          idStratalots: [ids.stratalots as number]
        })
      );
    } else {
      actions.push(
        stratalotActions.upsertManyStratalots({
          stratalots: (ids.stratalots as number[]).map((idStratalot: number) => ({
            idCompanyStratalotVacant: companyStratalotVacant.idCompanyStratalotVacant,
            idStratalot
          })) as Stratalot[]
        })
      );
      actions.push(
        companyStratalotVacantActions.addManyStratalotSuccess({
          idCompanyStratalotVacant: companyStratalotVacant.idCompanyStratalotVacant,
          idStratalots: ids.stratalots as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCompanyStratalotVacantActions(
  companyStratalotVacant: CompanyStratalotVacantEntityState
): Action[] {
  const actions: Action[] = [
    companyStratalotVacantActions.deleteOneCompanyStratalotVacantSuccess({
      idCompanyStratalotVacant: companyStratalotVacant.idCompanyStratalotVacant
    })
  ];

  if (companyStratalotVacant.company) {
    actions.push(
      companyActions.deleteManyCompanyStratalotVacantSuccess({
        idCompanyStratalotVacants: [companyStratalotVacant.idCompanyStratalotVacant],
        idCompanies: [companyStratalotVacant.company as number]
      })
    );
  }

  if (companyStratalotVacant.stratalots) {
    actions.push(
      stratalotActions.deleteManyCompanyStratalotVacantSuccess({
        idCompanyStratalotVacants: [companyStratalotVacant.idCompanyStratalotVacant],
        idStratalots: companyStratalotVacant.stratalots as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyStratalotVacantEffects {
  constructor(
    protected actions$: Actions,
    protected companyStratalotVacantApiService: CompanyStratalotVacantApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyStratalotVacants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStratalotVacantActions.getManyCompanyStratalotVacants),
      switchMap(({ params }) =>
        this.companyStratalotVacantApiService.getCompanyStratalotVacants(params).pipe(
          map((companyStratalotVacants: CompanyStratalotVacant[]) => {
            return companyStratalotVacantActions.normalizeManyCompanyStratalotVacantsAfterUpsert({
              companyStratalotVacants
            });
          }),
          catchError(error => of(companyStratalotVacantActions.companyStratalotVacantsFailure({ error })))
        )
      )
    );
  });

  getOneCompanyStratalotVacant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStratalotVacantActions.getOneCompanyStratalotVacant),
      switchMap(idCompanyStratalotVacant =>
        this.companyStratalotVacantApiService.getCompanyStratalotVacant(idCompanyStratalotVacant).pipe(
          map((companyStratalotVacant: CompanyStratalotVacant) => {
            return companyStratalotVacantActions.normalizeManyCompanyStratalotVacantsAfterUpsert({
              companyStratalotVacants: [companyStratalotVacant]
            });
          }),
          catchError(error => of(companyStratalotVacantActions.companyStratalotVacantsFailure({ error })))
        )
      )
    );
  });

  upsertOneCompanyStratalotVacant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStratalotVacantActions.upsertOneCompanyStratalotVacant),
      concatMap(
        ({
          companyStratalotVacant,
          ids
        }: {
          companyStratalotVacant: Partial<CompanyStratalotVacant>;
          ids?: CompanyStratalotVacantRelationsIds;
        }) => {
          if (companyStratalotVacant.idCompanyStratalotVacant) {
            return this.companyStratalotVacantApiService.updateCompanyStratalotVacant(companyStratalotVacant).pipe(
              map((companyStratalotVacantReturned: CompanyStratalotVacant) => {
                return companyStratalotVacantActions.normalizeManyCompanyStratalotVacantsAfterUpsert({
                  companyStratalotVacants: [companyStratalotVacantReturned]
                });
              }),
              catchError(error => of(companyStratalotVacantActions.companyStratalotVacantsFailure({ error })))
            );
          } else {
            return this.companyStratalotVacantApiService.addCompanyStratalotVacant(companyStratalotVacant).pipe(
              mergeMap((companyStratalotVacantReturned: CompanyStratalotVacant) =>
                getDefaultAddCompanyStratalotVacantActions(companyStratalotVacantReturned, ids)
              ),
              catchError(error => of(companyStratalotVacantActions.companyStratalotVacantsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCompanyStratalotVacant$ = createEffect(() => {
    const selectCompanyStratalotVacantState$ = this.store$.select(selectCompanyStratalotVacantState);
    return this.actions$.pipe(
      ofType(companyStratalotVacantActions.deleteOneCompanyStratalotVacant),
      withLatestFrom(selectCompanyStratalotVacantState$),
      concatMap(([{ idCompanyStratalotVacant }, state]) =>
        this.companyStratalotVacantApiService.deleteCompanyStratalotVacant(idCompanyStratalotVacant).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCompanyStratalotVacantActions(
                state.entities[idCompanyStratalotVacant] as CompanyStratalotVacantEntityState
              ),
              companyStratalotVacantActions.deleteOneCompanyStratalotVacant.type
            )
          ]),
          catchError(error => of(companyStratalotVacantActions.companyStratalotVacantsFailure({ error })))
        )
      )
    );
  });

  normalizeManyCompanyStratalotVacantsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStratalotVacantActions.normalizeManyCompanyStratalotVacantsAfterUpsert),
      concatMap(({ companyStratalotVacants }) => {
        const actions: Action[] = getActionsToNormalizeCompanyStratalotVacant(
          companyStratalotVacants,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CompanyStratalotVacant] Normalization After Upsert Success')];
      })
    );
  });
}
