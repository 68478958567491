import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceSaleCategoryFamily } from '@_model/interfaces/residence-sale-category-family.model';
import { Observable } from 'rxjs';

export class GeneratedResidenceSaleCategoryFamilyApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidenceSaleCategoryFamilies(params?: any): Observable<ResidenceSaleCategoryFamily[]> {
    return this.repo.getData<ResidenceSaleCategoryFamily[]>('residence-sale-category-family', params);
  }

  public getResidenceSaleCategoryFamily(params: {
    idResidenceSaleCategoryFamily: number;
    params?: any;
  }): Observable<ResidenceSaleCategoryFamily> {
    return this.repo.getData<ResidenceSaleCategoryFamily>(
      'residence-sale-category-family/' + params.idResidenceSaleCategoryFamily,
      params.params
    );
  }

  public addResidenceSaleCategoryFamily(
    residenceSaleCategoryFamily: Partial<ResidenceSaleCategoryFamily>
  ): Observable<ResidenceSaleCategoryFamily> {
    return this.repo.create<ResidenceSaleCategoryFamily>('residence-sale-category-family', residenceSaleCategoryFamily);
  }

  public updateResidenceSaleCategoryFamily(
    residenceSaleCategoryFamily: Partial<ResidenceSaleCategoryFamily>
  ): Observable<ResidenceSaleCategoryFamily> {
    return this.repo.update('residence-sale-category-family', residenceSaleCategoryFamily);
  }

  public deleteResidenceSaleCategoryFamily(idResidenceSaleCategoryFamily: number): Observable<number> {
    return this.repo.delete('residence-sale-category-family/' + +idResidenceSaleCategoryFamily);
  }
}
