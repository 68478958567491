import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationLeadTodoApiService } from '@_services/api/organization-lead-todo-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedOrganizationLeadTodoEffects } from './organization-lead-todo-generated.effects';

@Injectable()
export class OrganizationLeadTodoEffects extends GeneratedOrganizationLeadTodoEffects {
  constructor(
    actions$: Actions,
    organizationLeadTodoApiService: OrganizationLeadTodoApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationLeadTodoApiService, store$);
  }
}
