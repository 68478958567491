import { DroitState, initialState } from './droit.state';
import { Action, createReducer } from '@ngrx/store';
import { droitReducersGeneratedFunctions } from './droit-generated.reducer';

const droitReducersFunctions = [...droitReducersGeneratedFunctions];

const droitReducer = createReducer(initialState, ...droitReducersFunctions);

export function reducer(state: DroitState | undefined, action: Action) {
  return droitReducer(state, action);
}
