import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  getActionsToNormalizeStratalot,
  getActionsToNormalizeStratalotAssociation
} from '@_config/store/normalization.generated';
import { StoreActionType } from '@_model/enums/store-action-type.enum';
import { StratalotAssociation } from '@_model/interfaces/stratalot-association.model';
import { Stratalot } from '@_model/interfaces/stratalot.model';
import { StratalotApiService } from '@_services/api/stratalot-api.service';
import { getMultiAction } from '@_store/batched-actions';
import { AppState } from '@_store/index.reducers';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';
import { GeneratedStratalotEffects } from './stratalot-generated.effects';

@Injectable()
export class StratalotEffects extends GeneratedStratalotEffects {
  constructor(actions$: Actions, stratalotApiService: StratalotApiService, store$: Store<AppState>) {
    super(actions$, stratalotApiService, store$);
  }

  public getManyFreeStratalots$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.getManyFreeStratalots),
      concatMap(({ params }) =>
        this.stratalotApiService.getFreeStratalots(params).pipe(
          map((stratalots: Stratalot[]) => {
            return stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots });
          }),
          catchError(error => of(stratalotActions.stratalotsFailure({ error })))
        )
      )
    );
  });
  public getManyFreeStratalotsOfProgram$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.getManyFreeStratalotsOfProgram),
      concatMap(({ params }) =>
        this.stratalotApiService.getFreeStratalotsOfProgram(params).pipe(
          map((stratalots: Stratalot[]) => {
            return stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots });
          }),
          catchError(error => of(stratalotActions.stratalotsFailure({ error })))
        )
      )
    );
  });

  public getManyUbiflowMonitoringStratalots$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.getManyUbiflowMonitoringStratalots),
      concatMap(({ params }) =>
        this.stratalotApiService.getManyUbiflowMonitoringStratalots(params).pipe(
          map((stratalots: Stratalot[]) => {
            return stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots });
          }),
          catchError(error => of(stratalotActions.stratalotsFailure({ error })))
        )
      )
    );
  });

  public getManyOccupedStratalots$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.getManyOccupedStratalots),
      concatMap(({ params }) =>
        this.stratalotApiService.getOccupedStratalots(params).pipe(
          map((stratalots: Stratalot[]) => {
            return stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots });
          }),
          catchError(error => of(stratalotActions.stratalotsFailure({ error })))
        )
      )
    );
  });

  public getAllOccupedStratalots$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.getAllOccupedStratalots),
      concatMap(({ params }) =>
        this.stratalotApiService.getAllOccupedStratalots(params).pipe(
          map((stratalots: Stratalot[]) => {
            return stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots });
          }),
          catchError(error => of(stratalotActions.stratalotsFailure({ error })))
        )
      )
    );
  });

  public getManyStratalotsFromResidence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.getManyStratalotsFromResidence),
      concatMap(({ params }) =>
        this.stratalotApiService.getStratalotsFromResidence(params).pipe(
          map((stratalots: Stratalot[]) => {
            return stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots });
          }),
          catchError(error => of(stratalotActions.stratalotsFailure({ error })))
        )
      )
    );
  });

  public getManyLeadsForValidateOfferBoard$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.getManyStratalotsForValidateOfferBoard),
      switchMap(({ params }) =>
        this.stratalotApiService.getLeadsForValidateOfferBoard(params).pipe(
          map((stratalots: Stratalot[]) => {
            return stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots });
          }),
          catchError(error => of(stratalotActions.stratalotsFailure({ error })))
        )
      )
    );
  });

  public getManyStratalotsByStepProgresses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.getManyStratalotsByStepProgresses),
      switchMap(patch =>
        this.stratalotApiService.getStratalotsByStepProgresses(patch).pipe(
          map((stratalots: Stratalot[]) => {
            return stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots });
          }),
          catchError(error => of(stratalotActions.stratalotsFailure({ error })))
        )
      )
    );
  });

  public activateStratalotFromCampaign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.activateStratalotFromCampaign),
      concatMap(({ idCompany, envoiUbiflowDateDebut, envoiUbiflowDateFin, idStratalot }) => {
        return this.stratalotApiService
          .activateStratalotFromCampaign({ idCompany, envoiUbiflowDateDebut, envoiUbiflowDateFin, idStratalot })
          .pipe(
            map((stratalotReturned: Stratalot) => {
              return stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots: [stratalotReturned] });
            }),
            catchError(error => of(stratalotActions.stratalotsFailure({ error })))
          );
      })
    );
  });

  public updateStratalotAd$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.updateStratalotAd),
      concatMap(({ idStratalot, stratalotAnnonce }) => {
        return this.stratalotApiService.updateStratalotAd({ idStratalot, stratalotAnnonce }).pipe(
          map((stratalotReturned: Stratalot) => {
            return stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots: [stratalotReturned] });
          }),
          catchError(error => of(stratalotActions.stratalotsFailure({ error })))
        );
      })
    );
  });

  public updateVacance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.updateVacance),
      concatMap(({ idStratalot, saisieconge }) => {
        return this.stratalotApiService.updateVacance({ idStratalot, saisieconge }).pipe(
          map((stratalotReturned: Stratalot) => {
            return stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots: [stratalotReturned] });
          }),
          catchError(error => of(stratalotActions.stratalotsFailure({ error })))
        );
      })
    );
  });

  public duplicateDiffusionVisualsFromResidence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.duplicateDiffusionVisualsFromResidence),
      concatMap((payload: { idResidence: number; idStratalot: number }) => {
        return this.stratalotApiService.duplicateDiffusionVisualsFromResidence(payload).pipe(
          map((stratalotReturned: Stratalot) => {
            return stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots: [stratalotReturned] });
          }),
          catchError(error => of(stratalotActions.stratalotsFailure({ error })))
        );
      })
    );
  });

  public normalizeManyStratalotsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.normalizeManyStratalotsAfterUpsert),
      concatMap(({ stratalots }) => {
        let associatedStratalotAssociations: StratalotAssociation[] = [];
        stratalots.forEach(stratalot => {
          if (
            stratalot.stratalotAssociations &&
            (stratalot.stratalotAssociations[0] as StratalotAssociation)?.associatedStratalotAssociations
          ) {
            associatedStratalotAssociations = associatedStratalotAssociations.concat(
              (stratalot.stratalotAssociations[0] as StratalotAssociation).associatedStratalotAssociations
            );
          }
        });

        const actions: Action[] = getActionsToNormalizeStratalot(stratalots, StoreActionType.upsert).concat(
          getActionsToNormalizeStratalotAssociation(associatedStratalotAssociations, StoreActionType.upsert)
        );
        return [getMultiAction(actions, '[Stratalot] Normalization After Upsert Success')];
      })
    );
  });
}
