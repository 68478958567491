import { initialState, OrganizationSocieteState } from './organization-societe.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationSocieteReducersGeneratedFunctions } from './organization-societe-generated.reducer';

const organizationSocieteReducersFunctions = [...organizationSocieteReducersGeneratedFunctions];

const organizationSocieteReducer = createReducer(initialState, ...organizationSocieteReducersFunctions);

export function reducer(state: OrganizationSocieteState | undefined, action: Action) {
  return organizationSocieteReducer(state, action);
}
