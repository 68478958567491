import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { Prospect, ProspectEntityState } from '@_model/interfaces/prospect.model';
import { ProspectApiService } from '@_services/api/prospect-api.service';
import * as prospectActions from '@_store/prospect/prospect.actions';
import { getActionsToNormalizeProspect } from '@_config/store/normalization.generated';
import { selectProspectState } from './prospect-generated.selectors';
import * as occupantActions from '@_store/occupant/occupant.actions';
import * as organizationActions from '@_store/organization/organization.actions';
import * as organizationProspectOriginActions from '@_store/organization-prospect-origin/organization-prospect-origin.actions';
import * as userActions from '@_store/user/user.actions';
import * as prospectEventActions from '@_store/prospect-event/prospect-event.actions';
import { ProspectEvent } from '@_model/interfaces/prospect-event.model';
import * as prospectBuyingWishActions from '@_store/prospect-buying-wish/prospect-buying-wish.actions';
import { ProspectBuyingWish } from '@_model/interfaces/prospect-buying-wish.model';
import * as leadActions from '@_store/lead/lead.actions';
import { Lead } from '@_model/interfaces/lead.model';

export interface ProspectRelationsIds {
  prospectEvents?: number | number[];
  prospectBuyingWishs?: number | number[];
  leads?: number | number[];
  occupant?: number;
  organization?: number;
  organizationProspectOrigin?: number;
  responsable?: number;
  updatedUser?: number;
  createdUser?: number;
}

export function getDefaultAddProspectActions(prospect: ProspectEntityState, ids?: ProspectRelationsIds): Action[] {
  const actions: Action[] = [prospectActions.normalizeManyProspectsAfterUpsert({ prospects: [prospect] })];

  if (ids?.occupant) {
    actions.push(
      occupantActions.addManyProspectSuccess({
        idOccupant: ids.occupant,
        idProspects: [prospect.idProspect]
      })
    );
    actions.push(
      prospectActions.addOccupantSuccess({
        idProspect: prospect.idProspect,
        idOccupant: ids.occupant
      })
    );
  }

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyProspectSuccess({
        idOrganization: ids.organization,
        idProspects: [prospect.idProspect]
      })
    );
    actions.push(
      prospectActions.addOrganizationSuccess({
        idProspect: prospect.idProspect,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.organizationProspectOrigin) {
    actions.push(
      organizationProspectOriginActions.addManyProspectSuccess({
        idOrganizationProspectOrigin: ids.organizationProspectOrigin,
        idProspects: [prospect.idProspect]
      })
    );
    actions.push(
      prospectActions.addOrganizationProspectOriginSuccess({
        idProspect: prospect.idProspect,
        idOrganizationProspectOrigin: ids.organizationProspectOrigin
      })
    );
  }

  if (ids?.responsable) {
    actions.push(
      userActions.addManyProspectResponsibleOfSuccess({
        idUser: ids.responsable,
        idProspectResponsibleOf: [prospect.idProspect]
      })
    );
    actions.push(
      prospectActions.addResponsableSuccess({
        idProspect: prospect.idProspect,
        idResponsable: ids.responsable
      })
    );
  }

  if (ids?.updatedUser) {
    actions.push(
      userActions.addManyProspectUpdaterSuccess({
        idUser: ids.updatedUser,
        idProspectUpdater: [prospect.idProspect]
      })
    );
    actions.push(
      prospectActions.addUpdatedUserSuccess({
        idProspect: prospect.idProspect,
        idUpdatedUser: ids.updatedUser
      })
    );
  }

  if (ids?.createdUser) {
    actions.push(
      userActions.addManyProspectCreatorSuccess({
        idUser: ids.createdUser,
        idProspectCreator: [prospect.idProspect]
      })
    );
    actions.push(
      prospectActions.addCreatedUserSuccess({
        idProspect: prospect.idProspect,
        idCreatedUser: ids.createdUser
      })
    );
  }

  if (ids?.prospectEvents) {
    if (!Array.isArray(ids.prospectEvents)) {
      actions.push(
        prospectEventActions.upsertOneProspectEvent({
          prospectEvent: {
            idProspect: prospect.idProspect,
            idProspectEvent: ids.prospectEvents as number
          } as ProspectEvent
        })
      );
      actions.push(
        prospectActions.addManyProspectEventSuccess({
          idProspect: prospect.idProspect,
          idProspectEvents: [ids.prospectEvents as number]
        })
      );
    } else {
      actions.push(
        prospectEventActions.upsertManyProspectEvents({
          prospectEvents: (ids.prospectEvents as number[]).map((idProspectEvent: number) => ({
            idProspect: prospect.idProspect,
            idProspectEvent
          })) as ProspectEvent[]
        })
      );
      actions.push(
        prospectActions.addManyProspectEventSuccess({
          idProspect: prospect.idProspect,
          idProspectEvents: ids.prospectEvents as number[]
        })
      );
    }
  }

  if (ids?.prospectBuyingWishs) {
    if (!Array.isArray(ids.prospectBuyingWishs)) {
      actions.push(
        prospectBuyingWishActions.upsertOneProspectBuyingWish({
          prospectBuyingWish: {
            idProspect: prospect.idProspect,
            idProspectBuyingWish: ids.prospectBuyingWishs as number
          } as ProspectBuyingWish
        })
      );
      actions.push(
        prospectActions.addManyProspectBuyingWishSuccess({
          idProspect: prospect.idProspect,
          idProspectBuyingWishs: [ids.prospectBuyingWishs as number]
        })
      );
    } else {
      actions.push(
        prospectBuyingWishActions.upsertManyProspectBuyingWishs({
          prospectBuyingWishs: (ids.prospectBuyingWishs as number[]).map((idProspectBuyingWish: number) => ({
            idProspect: prospect.idProspect,
            idProspectBuyingWish
          })) as ProspectBuyingWish[]
        })
      );
      actions.push(
        prospectActions.addManyProspectBuyingWishSuccess({
          idProspect: prospect.idProspect,
          idProspectBuyingWishs: ids.prospectBuyingWishs as number[]
        })
      );
    }
  }

  if (ids?.leads) {
    if (!Array.isArray(ids.leads)) {
      actions.push(
        leadActions.upsertOneLead({
          lead: {
            idProspect: prospect.idProspect,
            idLead: ids.leads as number
          } as Lead
        })
      );
      actions.push(
        prospectActions.addManyLeadSuccess({
          idProspect: prospect.idProspect,
          idLeads: [ids.leads as number]
        })
      );
    } else {
      actions.push(
        leadActions.upsertManyLeads({
          leads: (ids.leads as number[]).map((idLead: number) => ({
            idProspect: prospect.idProspect,
            idLead
          })) as Lead[]
        })
      );
      actions.push(
        prospectActions.addManyLeadSuccess({
          idProspect: prospect.idProspect,
          idLeads: ids.leads as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteProspectActions(prospect: ProspectEntityState): Action[] {
  const actions: Action[] = [prospectActions.deleteOneProspectSuccess({ idProspect: prospect.idProspect })];

  if (prospect.occupant) {
    actions.push(
      occupantActions.deleteManyProspectSuccess({
        idProspects: [prospect.idProspect],
        idOccupants: [prospect.occupant as number]
      })
    );
  }

  if (prospect.organization) {
    actions.push(
      organizationActions.deleteManyProspectSuccess({
        idProspects: [prospect.idProspect],
        idOrganizations: [prospect.organization as number]
      })
    );
  }

  if (prospect.organizationProspectOrigin) {
    actions.push(
      organizationProspectOriginActions.deleteManyProspectSuccess({
        idProspects: [prospect.idProspect],
        idOrganizationProspectOrigins: [prospect.organizationProspectOrigin as number]
      })
    );
  }

  if (prospect.responsable) {
    actions.push(
      userActions.deleteManyProspectResponsibleOfSuccess({
        idProspectResponsibleOf: [prospect.idProspect],
        idUsers: [prospect.responsable as number]
      })
    );
  }

  if (prospect.updatedUser) {
    actions.push(
      userActions.deleteManyProspectUpdaterSuccess({
        idProspectUpdater: [prospect.idProspect],
        idUsers: [prospect.updatedUser as number]
      })
    );
  }

  if (prospect.createdUser) {
    actions.push(
      userActions.deleteManyProspectCreatorSuccess({
        idProspectCreator: [prospect.idProspect],
        idUsers: [prospect.createdUser as number]
      })
    );
  }

  if (prospect.prospectEvents) {
    actions.push(
      prospectEventActions.deleteManyProspectSuccess({
        idProspects: [prospect.idProspect],
        idProspectEvents: prospect.prospectEvents as number[]
      })
    );
  }

  if (prospect.prospectBuyingWishs) {
    actions.push(
      prospectBuyingWishActions.deleteManyProspectSuccess({
        idProspects: [prospect.idProspect],
        idProspectBuyingWishs: prospect.prospectBuyingWishs as number[]
      })
    );
  }

  if (prospect.leads) {
    actions.push(
      leadActions.deleteManyProspectSuccess({
        idProspects: [prospect.idProspect],
        idLeads: prospect.leads as number[]
      })
    );
  }

  return actions;
}

export class GeneratedProspectEffects {
  constructor(
    protected actions$: Actions,
    protected prospectApiService: ProspectApiService,
    protected store$: Store<AppState>
  ) {}

  getManyProspects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(prospectActions.getManyProspects),
      switchMap(({ params }) =>
        this.prospectApiService.getProspects(params).pipe(
          map((prospects: Prospect[]) => {
            return prospectActions.normalizeManyProspectsAfterUpsert({ prospects });
          }),
          catchError(error => of(prospectActions.prospectsFailure({ error })))
        )
      )
    );
  });

  getOneProspect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(prospectActions.getOneProspect),
      switchMap(idProspect =>
        this.prospectApiService.getProspect(idProspect).pipe(
          map((prospect: Prospect) => {
            return prospectActions.normalizeManyProspectsAfterUpsert({ prospects: [prospect] });
          }),
          catchError(error => of(prospectActions.prospectsFailure({ error })))
        )
      )
    );
  });

  upsertOneProspect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(prospectActions.upsertOneProspect),
      concatMap(({ prospect, ids }: { prospect: Partial<Prospect>; ids?: ProspectRelationsIds }) => {
        if (prospect.idProspect) {
          return this.prospectApiService.updateProspect(prospect).pipe(
            map((prospectReturned: Prospect) => {
              return prospectActions.normalizeManyProspectsAfterUpsert({ prospects: [prospectReturned] });
            }),
            catchError(error => of(prospectActions.prospectsFailure({ error })))
          );
        } else {
          return this.prospectApiService.addProspect(prospect).pipe(
            mergeMap((prospectReturned: Prospect) => getDefaultAddProspectActions(prospectReturned, ids)),
            catchError(error => of(prospectActions.prospectsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneProspect$ = createEffect(() => {
    const selectProspectState$ = this.store$.select(selectProspectState);
    return this.actions$.pipe(
      ofType(prospectActions.deleteOneProspect),
      withLatestFrom(selectProspectState$),
      concatMap(([{ idProspect }, state]) =>
        this.prospectApiService.deleteProspect(idProspect).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteProspectActions(state.entities[idProspect] as ProspectEntityState),
              prospectActions.deleteOneProspect.type
            )
          ]),
          catchError(error => of(prospectActions.prospectsFailure({ error })))
        )
      )
    );
  });

  normalizeManyProspectsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(prospectActions.normalizeManyProspectsAfterUpsert),
      concatMap(({ prospects }) => {
        const actions: Action[] = getActionsToNormalizeProspect(prospects, StoreActionType.upsert);
        return [getMultiAction(actions, '[Prospect] Normalization After Upsert Success')];
      })
    );
  });
}
