import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidenceShareActions from './residence-share.actions';
import { adapter, initialState, ResidenceShareState } from './residence-share.state';
import { Dictionary } from '@ngrx/entity';
import { ResidenceShareEntityState } from '@_model/interfaces/residence-share.model';

export const residenceShareReducersGeneratedFunctions: ReducerTypes<ResidenceShareState, readonly ActionCreator[]>[] = [
  on(ResidenceShareActions.getOneResidenceShare, (state: ResidenceShareState) => setLoadingsState(state, true)),
  on(ResidenceShareActions.getManyResidenceShares, (state: ResidenceShareState) => setLoadingsState(state, true)),
  on(ResidenceShareActions.upsertOneResidenceShare, (state: ResidenceShareState) => setLoadingsState(state, true)),

  on(ResidenceShareActions.upsertManyResidenceSharesSuccess, (state: ResidenceShareState, { residenceShares }) =>
    adapter.upsertMany(residenceShares, setLoadingsState(state, false))
  ),
  on(ResidenceShareActions.deleteOneResidenceShare, (state: ResidenceShareState) => setLoadingsState(state, true)),
  on(ResidenceShareActions.deleteOneResidenceShareSuccess, (state: ResidenceShareState, { idResidenceShare }) =>
    adapter.removeOne(idResidenceShare, setLoadingsState(state, false))
  ),
  on(ResidenceShareActions.clearActive, (state: ResidenceShareState) => ({ ...state, actives: [] })),
  on(ResidenceShareActions.addManyActives, (state: ResidenceShareState, { idResidenceShares }) => ({
    ...state,
    actives: state.actives.concat(idResidenceShares)
  })),
  on(ResidenceShareActions.deleteOneActive, (state: ResidenceShareState, { idResidenceShare }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idResidenceShare)
  })),

  on(ResidenceShareActions.clearStore, () => initialState),

  on(
    ResidenceShareActions.addManyStratalotRcpSuccess,
    (state: ResidenceShareState, { idResidenceShare, idStratalotRcps }) => {
      if (!state.entities[idResidenceShare]) {
        return state;
      }
      const stratalotRcps = (state.entities[idResidenceShare]?.stratalotRcps as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceShare]: {
            ...state.entities[idResidenceShare],
            stratalotRcps: stratalotRcps.concat(idStratalotRcps.filter(id => stratalotRcps.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    ResidenceShareActions.deleteManyStratalotRcpSuccess,
    (state: ResidenceShareState, { idStratalotRcps, idResidenceShares }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceShares.reduce((entities, idResidenceShare) => {
            if (!state.entities[idResidenceShare]?.stratalotRcps) {
              return entities;
            }
            entities[idResidenceShare] = {
              ...state.entities[idResidenceShare],
              stratalotRcps: (state.entities[idResidenceShare]?.stratalotRcps as number[])?.filter(
                (idStratalotRcp: number) => !idStratalotRcps.some((id: number) => id === idStratalotRcp)
              )
            } as ResidenceShareEntityState;
            return entities;
          }, {} as Dictionary<ResidenceShareEntityState>)
        }
      };
    }
  ),

  on(ResidenceShareActions.addResidenceSuccess, (state: ResidenceShareState, { idResidenceShare, idResidence }) => {
    if (!state.entities[idResidenceShare]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idResidenceShare]: {
          ...state.entities[idResidenceShare],
          residence: idResidence
        }
      }
    };
  }),

  on(
    ResidenceShareActions.deleteManyResidenceSuccess,
    (state: ResidenceShareState, { idResidences, idResidenceShares }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceShares.reduce((entities, idResidenceShare) => {
            if (!state.entities[idResidenceShare]?.residence) {
              return entities;
            }
            entities[idResidenceShare] = {
              ...state.entities[idResidenceShare],
              residence: idResidences.some(
                (idResidence: number) => idResidence === state.entities[idResidenceShare]?.residence
              )
                ? undefined
                : state.entities[idResidenceShare]?.residence
            } as ResidenceShareEntityState;
            return entities;
          }, {} as Dictionary<ResidenceShareEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidenceShareState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidenceShareState {
  return { ...state, isLoaded, isLoading };
}
