import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationThirdPartyEntityState } from '@_model/interfaces/organization-third-party.model';

export interface OrganizationThirdPartyState extends EntityState<OrganizationThirdPartyEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationThirdPartyEntityState> =
  createEntityAdapter<OrganizationThirdPartyEntityState>({
    selectId: o => o.idOrganizationThirdParty
  });
export const initialState: OrganizationThirdPartyState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationThirdPartyFeatureKey = 'organizationThirdParty';
