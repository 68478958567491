import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationStratalotTodoAppliedRule,
  OrganizationStratalotTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-applied-rule.model';
import { OrganizationStratalotTodoAppliedRuleRelationsIds } from './organization-stratalot-todo-applied-rule-generated.effects';

export const getOneOrganizationStratalotTodoAppliedRule = createAction(
  '[OrganizationStratalotTodoAppliedRule] Get One OrganizationStratalotTodoAppliedRule',
  props<{ idOrganizationStratalotTodoAppliedRule: number; params?: any }>()
);

export const getManyOrganizationStratalotTodoAppliedRules = createAction(
  '[OrganizationStratalotTodoAppliedRule] Get Many OrganizationStratalotTodoAppliedRules',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationStratalotTodoAppliedRule] Add Many Actives OrganizationStratalotTodoAppliedRule',
  props<{ idOrganizationStratalotTodoAppliedRules: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationStratalotTodoAppliedRule] Delete One Active OrganizationStratalotTodoAppliedRule',
  props<{ idOrganizationStratalotTodoAppliedRule: number }>()
);

export const clearActive = createAction(
  '[OrganizationStratalotTodoAppliedRule] Clear Active OrganizationStratalotTodoAppliedRule'
);

export const upsertOneOrganizationStratalotTodoAppliedRule = createAction(
  '[OrganizationStratalotTodoAppliedRule] Upsert One OrganizationStratalotTodoAppliedRule',
  props<{
    organizationStratalotTodoAppliedRule: Partial<OrganizationStratalotTodoAppliedRule>;
    ids?: OrganizationStratalotTodoAppliedRuleRelationsIds;
  }>()
);

export const upsertManyOrganizationStratalotTodoAppliedRules = createAction(
  '[OrganizationStratalotTodoAppliedRule] Upsert Many OrganizationStratalotTodoAppliedRules',
  props<{
    organizationStratalotTodoAppliedRules: Partial<OrganizationStratalotTodoAppliedRule>[];
    ids?: OrganizationStratalotTodoAppliedRuleRelationsIds;
  }>()
);

export const upsertManyOrganizationStratalotTodoAppliedRulesSuccess = createAction(
  '[OrganizationStratalotTodoAppliedRule] Create Many OrganizationStratalotTodoAppliedRules Success',
  props<{ organizationStratalotTodoAppliedRules: OrganizationStratalotTodoAppliedRuleEntityState[] }>()
);

export const deleteOneOrganizationStratalotTodoAppliedRule = createAction(
  '[OrganizationStratalotTodoAppliedRule] Delete One OrganizationStratalotTodoAppliedRule',
  props<{ idOrganizationStratalotTodoAppliedRule: number }>()
);

export const deleteOneOrganizationStratalotTodoAppliedRuleSuccess = createAction(
  '[OrganizationStratalotTodoAppliedRule] Delete One OrganizationStratalotTodoAppliedRule Success',
  props<{ idOrganizationStratalotTodoAppliedRule: number }>()
);

export const normalizeManyOrganizationStratalotTodoAppliedRulesAfterUpsert = createAction(
  '[OrganizationStratalotTodoAppliedRule] Normalize Many OrganizationStratalotTodoAppliedRules After Upsert',
  props<{ organizationStratalotTodoAppliedRules: OrganizationStratalotTodoAppliedRuleEntityState[] }>()
);

export const organizationStratalotTodoAppliedRulesFailure = createAction(
  '[OrganizationStratalotTodoAppliedRule] OrganizationStratalotTodoAppliedRules Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction(
  '[OrganizationStratalotTodoAppliedRule] Clear OrganizationStratalotTodoAppliedRules'
);

export const addOrganizationStratalotTodoSuccess = createAction(
  '[OrganizationStratalotTodoAppliedRule] Add OrganizationStratalotTodo',
  props<{ idOrganizationStratalotTodoAppliedRule: number; idOrganizationStratalotTodo: number }>()
);

export const deleteManyOrganizationStratalotTodoSuccess = createAction(
  '[OrganizationStratalotTodoAppliedRule] Delete Many OrganizationStratalotTodo',
  props<{ idOrganizationStratalotTodos: number[]; idOrganizationStratalotTodoAppliedRules: number[] }>()
);

export const addOrganizationStratalotTodoRuleSuccess = createAction(
  '[OrganizationStratalotTodoAppliedRule] Add OrganizationStratalotTodoRule',
  props<{ idOrganizationStratalotTodoAppliedRule: number; idOrganizationStratalotTodoRule: number }>()
);

export const deleteManyOrganizationStratalotTodoRuleSuccess = createAction(
  '[OrganizationStratalotTodoAppliedRule] Delete Many OrganizationStratalotTodoRule',
  props<{ idOrganizationStratalotTodoRules: number[]; idOrganizationStratalotTodoAppliedRules: number[] }>()
);
