import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationStratalotTodoRule } from '@_model/interfaces/organization-stratalot-todo-rule.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationStratalotTodoRuleApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationStratalotTodoRules(params?: any): Observable<OrganizationStratalotTodoRule[]> {
    return this.repo.getData<OrganizationStratalotTodoRule[]>('organization-stratalot-todo-rule', params);
  }

  public getOrganizationStratalotTodoRule(params: {
    idOrganizationStratalotTodoRule: number;
    params?: any;
  }): Observable<OrganizationStratalotTodoRule> {
    return this.repo.getData<OrganizationStratalotTodoRule>(
      'organization-stratalot-todo-rule/' + params.idOrganizationStratalotTodoRule,
      params.params
    );
  }

  public addOrganizationStratalotTodoRule(
    organizationStratalotTodoRule: Partial<OrganizationStratalotTodoRule>
  ): Observable<OrganizationStratalotTodoRule> {
    return this.repo.create<OrganizationStratalotTodoRule>(
      'organization-stratalot-todo-rule',
      organizationStratalotTodoRule
    );
  }

  public updateOrganizationStratalotTodoRule(
    organizationStratalotTodoRule: Partial<OrganizationStratalotTodoRule>
  ): Observable<OrganizationStratalotTodoRule> {
    return this.repo.update('organization-stratalot-todo-rule', organizationStratalotTodoRule);
  }

  public deleteOrganizationStratalotTodoRule(idOrganizationStratalotTodoRule: number): Observable<number> {
    return this.repo.delete('organization-stratalot-todo-rule/' + +idOrganizationStratalotTodoRule);
  }
}
