import { RepositoryService } from '@_services/api/repository.service';
import { LeadStratalot } from '@_model/interfaces/lead-stratalot.model';
import { Observable } from 'rxjs';

export class GeneratedLeadStratalotApiService {
  constructor(protected repo: RepositoryService) {}

  public getLeadStratalots(params?: any): Observable<LeadStratalot[]> {
    return this.repo.getData<LeadStratalot[]>('lead-stratalot', params);
  }

  public getLeadStratalot(params: { idLeadStratalot: number; params?: any }): Observable<LeadStratalot> {
    return this.repo.getData<LeadStratalot>('lead-stratalot/' + params.idLeadStratalot, params.params);
  }

  public addLeadStratalot(leadStratalot: Partial<LeadStratalot>): Observable<LeadStratalot> {
    return this.repo.create<LeadStratalot>('lead-stratalot', leadStratalot);
  }

  public updateLeadStratalot(leadStratalot: Partial<LeadStratalot>): Observable<LeadStratalot> {
    return this.repo.update('lead-stratalot', leadStratalot);
  }

  public deleteLeadStratalot(idLeadStratalot: number): Observable<number> {
    return this.repo.delete('lead-stratalot/' + +idLeadStratalot);
  }
}
