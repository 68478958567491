import { Action } from '@ngrx/store';

export class MultiAction implements Action {
  public type = 'Multi action processing';
  public isBatchAction = true;
  constructor(public payload: Action[], type?: string) {
    if (type) {
      this.type = '[Multi] ' + type;
    }
  }
}

export function getMultiAction(payload: Action[], actionName?: string): MultiAction {
  return new MultiAction(payload, actionName);
}
