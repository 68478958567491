import { OrganizationForecastRateState, initialState } from './organization-forecast-rate.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationForecastRateReducersGeneratedFunctions } from './organization-forecast-rate-generated.reducer';

const organizationForecastRateReducersFunctions = [...organizationForecastRateReducersGeneratedFunctions];

const organizationForecastRateReducer = createReducer(initialState, ...organizationForecastRateReducersFunctions);

export function reducer(state: OrganizationForecastRateState | undefined, action: Action) {
  return organizationForecastRateReducer(state, action);
}
