import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Association, AssociationEntityState } from '@_model/interfaces/association.model';
import { AssociationRelationsIds } from './association-generated.effects';

export const getOneAssociation = createAction(
  '[Association] Get One Association',
  props<{ idAssociation: number; params?: any }>()
);

export const getManyAssociations = createAction('[Association] Get Many Associations', props<{ params: any }>());

export const addManyActives = createAction(
  '[Association] Add Many Actives Association',
  props<{ idAssociations: number[] }>()
);

export const deleteOneActive = createAction(
  '[Association] Delete One Active Association',
  props<{ idAssociation: number }>()
);

export const clearActive = createAction('[Association] Clear Active Association');

export const upsertOneAssociation = createAction(
  '[Association] Upsert One Association',
  props<{
    association: Partial<Association>;
    ids?: AssociationRelationsIds;
  }>()
);

export const upsertManyAssociations = createAction(
  '[Association] Upsert Many Associations',
  props<{
    associations: Partial<Association>[];
    ids?: AssociationRelationsIds;
  }>()
);

export const upsertManyAssociationsSuccess = createAction(
  '[Association] Create Many Associations Success',
  props<{ associations: AssociationEntityState[] }>()
);

export const deleteOneAssociation = createAction(
  '[Association] Delete One Association',
  props<{ idAssociation: number }>()
);

export const deleteOneAssociationSuccess = createAction(
  '[Association] Delete One Association Success',
  props<{ idAssociation: number }>()
);

export const normalizeManyAssociationsAfterUpsert = createAction(
  '[Association] Normalize Many Associations After Upsert',
  props<{ associations: AssociationEntityState[] }>()
);

export const associationsFailure = createAction(
  '[Association] Associations Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[Association] Clear Associations');

export const addManyStratalotAssociationSuccess = createAction(
  '[Association] Add Many stratalot-association',
  props<{ idAssociation: number; idStratalotAssociations: number[] }>()
);

export const deleteManyStratalotAssociationSuccess = createAction(
  '[Association] Delete Many StratalotAssociations',
  props<{ idStratalotAssociations: number[]; idAssociations: number[] }>()
);

export const addManyStratalotSuccess = createAction(
  '[Association] Add Many stratalot',
  props<{ idAssociation: number; idStratalots: number[] }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[Association] Delete Many Stratalots',
  props<{ idStratalots: number[]; idAssociations: number[] }>()
);
