import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  ResidenceSaleCategoryFamily,
  ResidenceSaleCategoryFamilyEntityState
} from '@_model/interfaces/residence-sale-category-family.model';
import { ResidenceSaleCategoryFamilyRelationsIds } from './residence-sale-category-family-generated.effects';

export const getOneResidenceSaleCategoryFamily = createAction(
  '[ResidenceSaleCategoryFamily] Get One ResidenceSaleCategoryFamily',
  props<{ idResidenceSaleCategoryFamily: number; params?: any }>()
);

export const getManyResidenceSaleCategoryFamilies = createAction(
  '[ResidenceSaleCategoryFamily] Get Many ResidenceSaleCategoryFamilies',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ResidenceSaleCategoryFamily] Add Many Actives ResidenceSaleCategoryFamily',
  props<{ idResidenceSaleCategoryFamilies: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidenceSaleCategoryFamily] Delete One Active ResidenceSaleCategoryFamily',
  props<{ idResidenceSaleCategoryFamily: number }>()
);

export const clearActive = createAction('[ResidenceSaleCategoryFamily] Clear Active ResidenceSaleCategoryFamily');

export const upsertOneResidenceSaleCategoryFamily = createAction(
  '[ResidenceSaleCategoryFamily] Upsert One ResidenceSaleCategoryFamily',
  props<{
    residenceSaleCategoryFamily: Partial<ResidenceSaleCategoryFamily>;
    ids?: ResidenceSaleCategoryFamilyRelationsIds;
  }>()
);

export const upsertManyResidenceSaleCategoryFamilies = createAction(
  '[ResidenceSaleCategoryFamily] Upsert Many ResidenceSaleCategoryFamilies',
  props<{
    residenceSaleCategoryFamilies: Partial<ResidenceSaleCategoryFamily>[];
    ids?: ResidenceSaleCategoryFamilyRelationsIds;
  }>()
);

export const upsertManyResidenceSaleCategoryFamiliesSuccess = createAction(
  '[ResidenceSaleCategoryFamily] Create Many ResidenceSaleCategoryFamilies Success',
  props<{ residenceSaleCategoryFamilies: ResidenceSaleCategoryFamilyEntityState[] }>()
);

export const deleteOneResidenceSaleCategoryFamily = createAction(
  '[ResidenceSaleCategoryFamily] Delete One ResidenceSaleCategoryFamily',
  props<{ idResidenceSaleCategoryFamily: number }>()
);

export const deleteOneResidenceSaleCategoryFamilySuccess = createAction(
  '[ResidenceSaleCategoryFamily] Delete One ResidenceSaleCategoryFamily Success',
  props<{ idResidenceSaleCategoryFamily: number }>()
);

export const normalizeManyResidenceSaleCategoryFamiliesAfterUpsert = createAction(
  '[ResidenceSaleCategoryFamily] Normalize Many ResidenceSaleCategoryFamilies After Upsert',
  props<{ residenceSaleCategoryFamilies: ResidenceSaleCategoryFamilyEntityState[] }>()
);

export const residenceSaleCategoryFamiliesFailure = createAction(
  '[ResidenceSaleCategoryFamily] ResidenceSaleCategoryFamilies Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidenceSaleCategoryFamily] Clear ResidenceSaleCategoryFamilies');

export const addResidenceSuccess = createAction(
  '[ResidenceSaleCategoryFamily] Add Residence',
  props<{ idResidenceSaleCategoryFamily: number; idResidence: number }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[ResidenceSaleCategoryFamily] Delete Many Residence',
  props<{ idResidences: number[]; idResidenceSaleCategoryFamilies: number[] }>()
);

export const addSaleCategoryFamilySuccess = createAction(
  '[ResidenceSaleCategoryFamily] Add SaleCategoryFamily',
  props<{ idResidenceSaleCategoryFamily: number; idSaleCategoryFamily: number }>()
);

export const deleteManySaleCategoryFamilySuccess = createAction(
  '[ResidenceSaleCategoryFamily] Delete Many SaleCategoryFamily',
  props<{ idFamilySaleCategories: number[]; idResidenceSaleCategoryFamilies: number[] }>()
);
