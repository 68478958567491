import { RepositoryService } from '@_services/api/repository.service';
import { StepProgressFamily } from '@_model/interfaces/step-progress-family.model';
import { Observable } from 'rxjs';

export class GeneratedStepProgressFamilyApiService {
  constructor(protected repo: RepositoryService) {}

  public getStepProgressesFamilies(params?: any): Observable<StepProgressFamily[]> {
    return this.repo.getData<StepProgressFamily[]>('step-progress-family', params);
  }

  public getStepProgressFamily(params: { idStepProgressFamily: number; params?: any }): Observable<StepProgressFamily> {
    return this.repo.getData<StepProgressFamily>('step-progress-family/' + params.idStepProgressFamily, params.params);
  }

  public addStepProgressFamily(stepProgressFamily: Partial<StepProgressFamily>): Observable<StepProgressFamily> {
    return this.repo.create<StepProgressFamily>('step-progress-family', stepProgressFamily);
  }

  public updateStepProgressFamily(stepProgressFamily: Partial<StepProgressFamily>): Observable<StepProgressFamily> {
    return this.repo.update('step-progress-family', stepProgressFamily);
  }

  public deleteStepProgressFamily(idStepProgressFamily: number): Observable<number> {
    return this.repo.delete('step-progress-family/' + +idStepProgressFamily);
  }
}
