import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { LeadTodoApiService } from '@_services/api/lead-todo-api.service';
import { LeadTodo } from '@_shared/models/interfaces/lead-todo.model';
import { Lead } from '@_shared/models/interfaces/lead.model';
import { AppState } from '@_store/index.reducers';
import * as leadTodoActions from '@_store/lead-todo/lead-todo.actions';
import * as leadActions from '@_store/lead/lead.actions';
import * as organizationLeadTodoActions from '@_store/organization-lead-todo/organization-lead-todo.actions';
import { of } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { GeneratedLeadTodoEffects, LeadTodoRelationsIds } from './lead-todo-generated.effects';
@Injectable()
export class LeadTodoEffects extends GeneratedLeadTodoEffects {
  constructor(actions$: Actions, leadTodoApiService: LeadTodoApiService, store$: Store<AppState>) {
    super(actions$, leadTodoApiService, store$);
  }

  public upsertOneLeadTodo$: any = createEffect(() => {
    return this.actions$.pipe(
      ofType(leadTodoActions.upsertOneLeadTodo),
      concatMap(({ leadTodo, ids }: { leadTodo: Partial<LeadTodo>; ids?: LeadTodoRelationsIds }) => {
        if (leadTodo.idLeadTodo) {
          return this.leadTodoApiService.updateLeadTodoWithSideEffect(leadTodo).pipe(
            concatMap((leadTodoReturned: LeadTodo | Lead[]) => {
              if (Array.isArray(leadTodoReturned)) {
                return [
                  leadActions.normalizeManyLeadsAfterUpsert({ leads: leadTodoReturned as Lead[] }),
                  leadTodoActions.upsertOneLeadTodoSuccess()
                ];
              }
              return [
                leadTodoActions.normalizeManyLeadTodosAfterUpsert({ leadTodos: [leadTodoReturned as LeadTodo] }),
                leadTodoActions.upsertOneLeadTodoSuccess()
              ];
            }),
            catchError(error => of(leadTodoActions.leadTodosFailure({ error })))
          );
        } else {
          return this.leadTodoApiService.addLeadTodo(leadTodo).pipe(
            concatMap((leadTodoReturned: LeadTodo) => {
              const actions: Action[] = [
                leadTodoActions.normalizeManyLeadTodosAfterUpsert({ leadTodos: [leadTodoReturned] })
              ];

              if (ids.organizationLeadTodo) {
                const action = organizationLeadTodoActions.addManyLeadTodoSuccess({
                  idOrganizationLeadTodo: ids.organizationLeadTodo,
                  idLeadTodos: [leadTodoReturned.idLeadTodo]
                });
                actions.push(action);
              }

              if (ids.lead) {
                const action = leadActions.addManyLeadTodoSuccess({
                  idLead: ids.lead,
                  idLeadTodos: [leadTodoReturned.idLeadTodo]
                });
                actions.push(action);
              }

              return actions;
            }),
            catchError(error => of(leadTodoActions.leadTodosFailure({ error })))
          );
        }
      })
    );
  });
}
