import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { BoardState, BoardStateEntityState } from '@_model/interfaces/board-state.model';
import { BoardStateApiService } from '@_services/api/board-state-api.service';
import * as boardStateActions from '@_store/board-state/board-state.actions';
import { getActionsToNormalizeBoardState } from '@_config/store/normalization.generated';
import { selectBoardStateState } from './board-state-generated.selectors';
import * as userActions from '@_store/user/user.actions';
import * as saleCategoryFamilyActions from '@_store/sale-category-family/sale-category-family.actions';

export interface BoardStateRelationsIds {
  user?: number;
  saleCategoryFamily?: number;
}

export function getDefaultAddBoardStateActions(
  boardState: BoardStateEntityState,
  ids?: BoardStateRelationsIds
): Action[] {
  const actions: Action[] = [boardStateActions.normalizeManyBoardStatesAfterUpsert({ boardStates: [boardState] })];

  if (ids?.user) {
    actions.push(
      userActions.addManyBoardStateSuccess({
        idUser: ids.user,
        idBoardStates: [boardState.idBoardState]
      })
    );
    actions.push(
      boardStateActions.addUserSuccess({
        idBoardState: boardState.idBoardState,
        idUser: ids.user
      })
    );
  }

  if (ids?.saleCategoryFamily) {
    actions.push(
      saleCategoryFamilyActions.addManyBoardStateSuccess({
        idSaleCategoryFamily: ids.saleCategoryFamily,
        idBoardStates: [boardState.idBoardState]
      })
    );
    actions.push(
      boardStateActions.addSaleCategoryFamilySuccess({
        idBoardState: boardState.idBoardState,
        idSaleCategoryFamily: ids.saleCategoryFamily
      })
    );
  }

  return actions;
}

export function getDefaultDeleteBoardStateActions(boardState: BoardStateEntityState): Action[] {
  const actions: Action[] = [boardStateActions.deleteOneBoardStateSuccess({ idBoardState: boardState.idBoardState })];

  if (boardState.user) {
    actions.push(
      userActions.deleteManyBoardStateSuccess({
        idBoardStates: [boardState.idBoardState],
        idUsers: [boardState.user as number]
      })
    );
  }

  if (boardState.saleCategoryFamily) {
    actions.push(
      saleCategoryFamilyActions.deleteManyBoardStateSuccess({
        idBoardStates: [boardState.idBoardState],
        idFamilySaleCategories: [boardState.saleCategoryFamily as number]
      })
    );
  }

  return actions;
}

export class GeneratedBoardStateEffects {
  constructor(
    protected actions$: Actions,
    protected boardStateApiService: BoardStateApiService,
    protected store$: Store<AppState>
  ) {}

  getManyBoardStates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(boardStateActions.getManyBoardStates),
      switchMap(({ params }) =>
        this.boardStateApiService.getBoardStates(params).pipe(
          map((boardStates: BoardState[]) => {
            return boardStateActions.normalizeManyBoardStatesAfterUpsert({ boardStates });
          }),
          catchError(error => of(boardStateActions.boardStatesFailure({ error })))
        )
      )
    );
  });

  getOneBoardState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(boardStateActions.getOneBoardState),
      switchMap(idBoardState =>
        this.boardStateApiService.getBoardState(idBoardState).pipe(
          map((boardState: BoardState) => {
            return boardStateActions.normalizeManyBoardStatesAfterUpsert({ boardStates: [boardState] });
          }),
          catchError(error => of(boardStateActions.boardStatesFailure({ error })))
        )
      )
    );
  });

  upsertOneBoardState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(boardStateActions.upsertOneBoardState),
      concatMap(({ boardState, ids }: { boardState: Partial<BoardState>; ids?: BoardStateRelationsIds }) => {
        if (boardState.idBoardState) {
          return this.boardStateApiService.updateBoardState(boardState).pipe(
            map((boardStateReturned: BoardState) => {
              return boardStateActions.normalizeManyBoardStatesAfterUpsert({ boardStates: [boardStateReturned] });
            }),
            catchError(error => of(boardStateActions.boardStatesFailure({ error })))
          );
        } else {
          return this.boardStateApiService.addBoardState(boardState).pipe(
            mergeMap((boardStateReturned: BoardState) => getDefaultAddBoardStateActions(boardStateReturned, ids)),
            catchError(error => of(boardStateActions.boardStatesFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneBoardState$ = createEffect(() => {
    const selectBoardStateState$ = this.store$.select(selectBoardStateState);
    return this.actions$.pipe(
      ofType(boardStateActions.deleteOneBoardState),
      withLatestFrom(selectBoardStateState$),
      concatMap(([{ idBoardState }, state]) =>
        this.boardStateApiService.deleteBoardState(idBoardState).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteBoardStateActions(state.entities[idBoardState] as BoardStateEntityState),
              boardStateActions.deleteOneBoardState.type
            )
          ]),
          catchError(error => of(boardStateActions.boardStatesFailure({ error })))
        )
      )
    );
  });

  normalizeManyBoardStatesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(boardStateActions.normalizeManyBoardStatesAfterUpsert),
      concatMap(({ boardStates }) => {
        const actions: Action[] = getActionsToNormalizeBoardState(boardStates, StoreActionType.upsert);
        return [getMultiAction(actions, '[BoardState] Normalization After Upsert Success')];
      })
    );
  });
}
