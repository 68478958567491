import { RepositoryService } from '@_services/api/repository.service';
import { Step } from '@_model/interfaces/step.model';
import { Observable } from 'rxjs';

export class GeneratedStepApiService {
  constructor(protected repo: RepositoryService) {}

  public getSteps(params?: any): Observable<Step[]> {
    return this.repo.getData<Step[]>('step', params);
  }

  public getStep(params: { idStep: number; params?: any }): Observable<Step> {
    return this.repo.getData<Step>('step/' + params.idStep, params.params);
  }

  public addStep(step: Partial<Step>): Observable<Step> {
    return this.repo.create<Step>('step', step);
  }

  public updateStep(step: Partial<Step>): Observable<Step> {
    return this.repo.update('step', step);
  }

  public deleteStep(idStep: number): Observable<number> {
    return this.repo.delete('step/' + +idStep);
  }
}
