import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CampaignMedia, CampaignMediaEntityState } from '@_model/interfaces/campaign-media.model';
import { CompanyMedia, CompanyMediaEntityState } from '@_model/interfaces/company-media.model';
import { StratalotCampaign, StratalotCampaignEntityState } from '@_model/interfaces/stratalot-campaign.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, campaignMediaFeatureKey, CampaignMediaState } from './campaign-media.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const campaignMediaRelations: string[] = ['companyMedias', 'stratalotCampaigns'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCampaignMediaState = createFeatureSelector<CampaignMediaState>(campaignMediaFeatureKey);

export const selectIsLoadedCampaignMedia = createSelector(
  selectCampaignMediaState,
  (state: CampaignMediaState) => state.isLoaded
);

export const selectIsLoadingCampaignMedia = createSelector(
  selectCampaignMediaState,
  (state: CampaignMediaState) => state.isLoading
);

export const selectIsReadyCampaignMedia = createSelector(
  selectCampaignMediaState,
  (state: CampaignMediaState) => !state.isLoading
);

export const selectIsReadyAndLoadedCampaignMedia = createSelector(
  selectCampaignMediaState,
  (state: CampaignMediaState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CampaignMediaModel: SelectorModel = {
  name: 'campaignMedias',
  getSelector: selectAllCampaignMediasDictionary,
  isReady: selectIsReadyCampaignMedia
};

export const selectCampaignMediasEntities = createSelector(selectCampaignMediaState, selectEntities);

export const selectCampaignMediasArray = createSelector(selectCampaignMediaState, selectAll);

export const selectIdCampaignMediasActive = createSelector(
  selectCampaignMediaState,
  (state: CampaignMediaState) => state.actives
);

const campaignMediasInObject = (campaignMedias: Dictionary<CampaignMediaEntityState>) => ({ campaignMedias });

const selectCampaignMediasEntitiesDictionary = createSelector(selectCampaignMediasEntities, campaignMediasInObject);

const selectAllCampaignMediasObject = createSelector(selectCampaignMediasEntities, campaignMedias => {
  return hydrateAll({ campaignMedias });
});

const selectOneCampaignMediaDictionary = (idCampaignMedia: number) =>
  createSelector(selectCampaignMediasEntities, campaignMedias => {
    return { campaignMedias: { [idCampaignMedia]: campaignMedias[idCampaignMedia] } };
  });

const selectOneCampaignMediaDictionaryWithoutChild = (idCampaignMedia: number) =>
  createSelector(selectCampaignMediasEntities, campaignMedias => {
    return { campaignMedia: campaignMedias[idCampaignMedia] };
  });

const selectActiveCampaignMediasEntities = createSelector(
  selectIdCampaignMediasActive,
  selectCampaignMediasEntities,
  (actives: number[], campaignMedias: Dictionary<CampaignMediaEntityState>) =>
    getCampaignMediasFromActives(actives, campaignMedias)
);

function getCampaignMediasFromActives(
  actives: number[],
  campaignMedias: Dictionary<CampaignMediaEntityState>
): Dictionary<CampaignMediaEntityState> {
  return actives.reduce((acc, idActive) => {
    if (campaignMedias[idActive]) {
      acc[idActive] = campaignMedias[idActive];
    }
    return acc;
  }, {} as Dictionary<CampaignMediaEntityState>);
}

const selectAllCampaignMediasSelectors: Dictionary<Selector> = {};
export function selectAllCampaignMedias(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CampaignMedia>(
      schema,
      selectAllCampaignMediasSelectors,
      selectCampaignMediasEntitiesDictionary,
      getRelationSelectors,
      campaignMediaRelations,
      hydrateAll,
      'campaignMedia'
    );
  } else {
    return selectAllCampaignMediasObject;
  }
}

export function selectAllCampaignMediasDictionary(
  schema: SelectSchema = {},
  customKey: string = 'campaignMedias'
): Selector {
  return createSelector(selectAllCampaignMedias(schema), result => {
    const res = { [customKey]: {} as Dictionary<CampaignMediaEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.campaignMedias.length; i++) {
      res[customKey][result.campaignMedias[i].idCampaignMedia] = result.campaignMedias[i];
    }
    return res;
  });
}

export function selectOneCampaignMedia(schema: SelectSchema = {}, idCampaignMedia: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCampaignMediaDictionary(idCampaignMedia)];
    selectors.push(...getRelationSelectors(schema, campaignMediaRelations, 'campaignMedia'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCampaignMediaDictionaryWithoutChild(idCampaignMedia);
  }
}

export function selectActiveCampaignMedias(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCampaignMediasEntities, campaignMedias => ({ campaignMedias }))
  ];
  selectors.push(...getRelationSelectors(schema, campaignMediaRelations, 'campaignMedia'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  campaignMedias: Dictionary<CampaignMediaEntityState>;
  companyMedias?: Dictionary<CompanyMediaEntityState>;
  stratalotCampaigns?: Dictionary<StratalotCampaignEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { campaignMedias: (CampaignMedia | null)[] } {
  const { campaignMedias, companyMedias, stratalotCampaigns } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    campaignMedias: Object.keys(campaignMedias).map(idCampaignMedia =>
      hydrate(campaignMedias[idCampaignMedia] as CampaignMediaEntityState, companyMedias, stratalotCampaigns)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { campaignMedia: CampaignMediaEntityState | null } {
  const { campaignMedias, companyMedias, stratalotCampaigns } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const campaignMedia = Object.values(campaignMedias)[0];
  return { campaignMedia: hydrate(campaignMedia as CampaignMediaEntityState, companyMedias, stratalotCampaigns) };
}

function hydrate(
  campaignMedia: CampaignMediaEntityState,
  companyMediaEntities?: Dictionary<CompanyMediaEntityState>,
  stratalotCampaignEntities?: Dictionary<StratalotCampaignEntityState>
): CampaignMedia | null {
  if (!campaignMedia) {
    return null;
  }

  const campaignMediaHydrated: CampaignMediaEntityState = { ...campaignMedia };
  if (companyMediaEntities) {
    campaignMediaHydrated.companyMedia = companyMediaEntities[campaignMedia.companyMedia as number] as CompanyMedia;
  } else {
    delete campaignMediaHydrated.companyMedia;
  }
  if (stratalotCampaignEntities) {
    campaignMediaHydrated.stratalotCampaign = stratalotCampaignEntities[
      campaignMedia.stratalotCampaign as number
    ] as StratalotCampaign;
  } else {
    delete campaignMediaHydrated.stratalotCampaign;
  }

  return campaignMediaHydrated as CampaignMedia;
}
