import { RepositoryService } from '@_services/api/repository.service';
import { CompanyStudyReason } from '@_model/interfaces/company-study-reason.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyStudyReasonApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyStudyReasons(params?: any): Observable<CompanyStudyReason[]> {
    return this.repo.getData<CompanyStudyReason[]>('company-study-reason', params);
  }

  public getCompanyStudyReason(params: { idCompanyStudyReason: number; params?: any }): Observable<CompanyStudyReason> {
    return this.repo.getData<CompanyStudyReason>('company-study-reason/' + params.idCompanyStudyReason, params.params);
  }

  public addCompanyStudyReason(companyStudyReason: Partial<CompanyStudyReason>): Observable<CompanyStudyReason> {
    return this.repo.create<CompanyStudyReason>('company-study-reason', companyStudyReason);
  }

  public updateCompanyStudyReason(companyStudyReason: Partial<CompanyStudyReason>): Observable<CompanyStudyReason> {
    return this.repo.update('company-study-reason', companyStudyReason);
  }

  public deleteCompanyStudyReason(idCompanyStudyReason: number): Observable<number> {
    return this.repo.delete('company-study-reason/' + +idCompanyStudyReason);
  }
}
