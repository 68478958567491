import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationResidenceStudyTodo,
  OrganizationResidenceStudyTodoEntityState
} from '@_model/interfaces/organization-residence-study-todo.model';
import { OrganizationResidenceStudyTodoApiService } from '@_services/api/organization-residence-study-todo-api.service';
import * as organizationResidenceStudyTodoActions from '@_store/organization-residence-study-todo/organization-residence-study-todo.actions';
import { getActionsToNormalizeOrganizationResidenceStudyTodo } from '@_config/store/normalization.generated';
import { selectOrganizationResidenceStudyTodoState } from './organization-residence-study-todo-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as residenceStudyTodoActions from '@_store/residence-study-todo/residence-study-todo.actions';
import { ResidenceStudyTodo } from '@_model/interfaces/residence-study-todo.model';

export interface OrganizationResidenceStudyTodoRelationsIds {
  residenceStudyTodos?: number | number[];
  organization?: number;
}

export function getDefaultAddOrganizationResidenceStudyTodoActions(
  organizationResidenceStudyTodo: OrganizationResidenceStudyTodoEntityState,
  ids?: OrganizationResidenceStudyTodoRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationResidenceStudyTodoActions.normalizeManyOrganizationResidenceStudyTodosAfterUpsert({
      organizationResidenceStudyTodos: [organizationResidenceStudyTodo]
    })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationResidenceStudyTodoSuccess({
        idOrganization: ids.organization,
        idOrganizationResidenceStudyTodos: [organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo]
      })
    );
    actions.push(
      organizationResidenceStudyTodoActions.addOrganizationSuccess({
        idOrganizationResidenceStudyTodo: organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.residenceStudyTodos) {
    if (!Array.isArray(ids.residenceStudyTodos)) {
      actions.push(
        residenceStudyTodoActions.upsertOneResidenceStudyTodo({
          residenceStudyTodo: {
            idOrganizationResidenceStudyTodo: organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo,
            idResidenceStudyTodo: ids.residenceStudyTodos as number
          } as ResidenceStudyTodo
        })
      );
      actions.push(
        organizationResidenceStudyTodoActions.addManyResidenceStudyTodoSuccess({
          idOrganizationResidenceStudyTodo: organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo,
          idResidenceStudyTodos: [ids.residenceStudyTodos as number]
        })
      );
    } else {
      actions.push(
        residenceStudyTodoActions.upsertManyResidenceStudyTodos({
          residenceStudyTodos: (ids.residenceStudyTodos as number[]).map((idResidenceStudyTodo: number) => ({
            idOrganizationResidenceStudyTodo: organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo,
            idResidenceStudyTodo
          })) as ResidenceStudyTodo[]
        })
      );
      actions.push(
        organizationResidenceStudyTodoActions.addManyResidenceStudyTodoSuccess({
          idOrganizationResidenceStudyTodo: organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo,
          idResidenceStudyTodos: ids.residenceStudyTodos as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationResidenceStudyTodoActions(
  organizationResidenceStudyTodo: OrganizationResidenceStudyTodoEntityState
): Action[] {
  const actions: Action[] = [
    organizationResidenceStudyTodoActions.deleteOneOrganizationResidenceStudyTodoSuccess({
      idOrganizationResidenceStudyTodo: organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo
    })
  ];

  if (organizationResidenceStudyTodo.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationResidenceStudyTodoSuccess({
        idOrganizationResidenceStudyTodos: [organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo],
        idOrganizations: [organizationResidenceStudyTodo.organization as number]
      })
    );
  }

  if (organizationResidenceStudyTodo.residenceStudyTodos) {
    actions.push(
      residenceStudyTodoActions.deleteManyOrganizationResidenceStudyTodoSuccess({
        idOrganizationResidenceStudyTodos: [organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo],
        idResidenceStudyTodos: organizationResidenceStudyTodo.residenceStudyTodos as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationResidenceStudyTodoEffects {
  constructor(
    protected actions$: Actions,
    protected organizationResidenceStudyTodoApiService: OrganizationResidenceStudyTodoApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationResidenceStudyTodos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceStudyTodoActions.getManyOrganizationResidenceStudyTodos),
      switchMap(({ params }) =>
        this.organizationResidenceStudyTodoApiService.getOrganizationResidenceStudyTodos(params).pipe(
          map((organizationResidenceStudyTodos: OrganizationResidenceStudyTodo[]) => {
            return organizationResidenceStudyTodoActions.normalizeManyOrganizationResidenceStudyTodosAfterUpsert({
              organizationResidenceStudyTodos
            });
          }),
          catchError(error =>
            of(organizationResidenceStudyTodoActions.organizationResidenceStudyTodosFailure({ error }))
          )
        )
      )
    );
  });

  getOneOrganizationResidenceStudyTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceStudyTodoActions.getOneOrganizationResidenceStudyTodo),
      switchMap(idOrganizationResidenceStudyTodo =>
        this.organizationResidenceStudyTodoApiService
          .getOrganizationResidenceStudyTodo(idOrganizationResidenceStudyTodo)
          .pipe(
            map((organizationResidenceStudyTodo: OrganizationResidenceStudyTodo) => {
              return organizationResidenceStudyTodoActions.normalizeManyOrganizationResidenceStudyTodosAfterUpsert({
                organizationResidenceStudyTodos: [organizationResidenceStudyTodo]
              });
            }),
            catchError(error =>
              of(organizationResidenceStudyTodoActions.organizationResidenceStudyTodosFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneOrganizationResidenceStudyTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceStudyTodoActions.upsertOneOrganizationResidenceStudyTodo),
      concatMap(
        ({
          organizationResidenceStudyTodo,
          ids
        }: {
          organizationResidenceStudyTodo: Partial<OrganizationResidenceStudyTodo>;
          ids?: OrganizationResidenceStudyTodoRelationsIds;
        }) => {
          if (organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo) {
            return this.organizationResidenceStudyTodoApiService
              .updateOrganizationResidenceStudyTodo(organizationResidenceStudyTodo)
              .pipe(
                map((organizationResidenceStudyTodoReturned: OrganizationResidenceStudyTodo) => {
                  return organizationResidenceStudyTodoActions.normalizeManyOrganizationResidenceStudyTodosAfterUpsert({
                    organizationResidenceStudyTodos: [organizationResidenceStudyTodoReturned]
                  });
                }),
                catchError(error =>
                  of(organizationResidenceStudyTodoActions.organizationResidenceStudyTodosFailure({ error }))
                )
              );
          } else {
            return this.organizationResidenceStudyTodoApiService
              .addOrganizationResidenceStudyTodo(organizationResidenceStudyTodo)
              .pipe(
                mergeMap((organizationResidenceStudyTodoReturned: OrganizationResidenceStudyTodo) =>
                  getDefaultAddOrganizationResidenceStudyTodoActions(organizationResidenceStudyTodoReturned, ids)
                ),
                catchError(error =>
                  of(organizationResidenceStudyTodoActions.organizationResidenceStudyTodosFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationResidenceStudyTodo$ = createEffect(() => {
    const selectOrganizationResidenceStudyTodoState$ = this.store$.select(selectOrganizationResidenceStudyTodoState);
    return this.actions$.pipe(
      ofType(organizationResidenceStudyTodoActions.deleteOneOrganizationResidenceStudyTodo),
      withLatestFrom(selectOrganizationResidenceStudyTodoState$),
      concatMap(([{ idOrganizationResidenceStudyTodo }, state]) =>
        this.organizationResidenceStudyTodoApiService
          .deleteOrganizationResidenceStudyTodo(idOrganizationResidenceStudyTodo)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteOrganizationResidenceStudyTodoActions(
                  state.entities[idOrganizationResidenceStudyTodo] as OrganizationResidenceStudyTodoEntityState
                ),
                organizationResidenceStudyTodoActions.deleteOneOrganizationResidenceStudyTodo.type
              )
            ]),
            catchError(error =>
              of(organizationResidenceStudyTodoActions.organizationResidenceStudyTodosFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyOrganizationResidenceStudyTodosAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceStudyTodoActions.normalizeManyOrganizationResidenceStudyTodosAfterUpsert),
      concatMap(({ organizationResidenceStudyTodos }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationResidenceStudyTodo(
          organizationResidenceStudyTodos,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationResidenceStudyTodo] Normalization After Upsert Success')];
      })
    );
  });
}
