import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidenceForecastValueActions from './residence-forecast-value.actions';
import { adapter, initialState, ResidenceForecastValueState } from './residence-forecast-value.state';
import { Dictionary } from '@ngrx/entity';
import { ResidenceForecastValueEntityState } from '@_model/interfaces/residence-forecast-value.model';

export const residenceForecastValueReducersGeneratedFunctions: ReducerTypes<
  ResidenceForecastValueState,
  readonly ActionCreator[]
>[] = [
  on(ResidenceForecastValueActions.getOneResidenceForecastValue, (state: ResidenceForecastValueState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceForecastValueActions.getManyResidenceForecastValues, (state: ResidenceForecastValueState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceForecastValueActions.upsertOneResidenceForecastValue, (state: ResidenceForecastValueState) =>
    setLoadingsState(state, true)
  ),

  on(
    ResidenceForecastValueActions.upsertManyResidenceForecastValuesSuccess,
    (state: ResidenceForecastValueState, { residenceForecastValues }) =>
      adapter.upsertMany(residenceForecastValues, setLoadingsState(state, false))
  ),
  on(ResidenceForecastValueActions.deleteOneResidenceForecastValue, (state: ResidenceForecastValueState) =>
    setLoadingsState(state, true)
  ),
  on(
    ResidenceForecastValueActions.deleteOneResidenceForecastValueSuccess,
    (state: ResidenceForecastValueState, { idResidenceForecastValue }) =>
      adapter.removeOne(idResidenceForecastValue, setLoadingsState(state, false))
  ),
  on(ResidenceForecastValueActions.clearActive, (state: ResidenceForecastValueState) => ({ ...state, actives: [] })),
  on(
    ResidenceForecastValueActions.addManyActives,
    (state: ResidenceForecastValueState, { idResidenceForecastValues }) => ({
      ...state,
      actives: state.actives.concat(idResidenceForecastValues)
    })
  ),
  on(
    ResidenceForecastValueActions.deleteOneActive,
    (state: ResidenceForecastValueState, { idResidenceForecastValue }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idResidenceForecastValue)
    })
  ),

  on(ResidenceForecastValueActions.clearStore, () => initialState),

  on(
    ResidenceForecastValueActions.addResidenceForecastSuccess,
    (state: ResidenceForecastValueState, { idResidenceForecastValue, idResidenceForecast }) => {
      if (!state.entities[idResidenceForecastValue]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceForecastValue]: {
            ...state.entities[idResidenceForecastValue],
            residenceForecast: idResidenceForecast
          }
        }
      };
    }
  ),

  on(
    ResidenceForecastValueActions.deleteManyResidenceForecastSuccess,
    (state: ResidenceForecastValueState, { idResidenceForecasts, idResidenceForecastValues }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceForecastValues.reduce((entities, idResidenceForecastValue) => {
            if (!state.entities[idResidenceForecastValue]?.residenceForecast) {
              return entities;
            }
            entities[idResidenceForecastValue] = {
              ...state.entities[idResidenceForecastValue],
              residenceForecast: idResidenceForecasts.some(
                (idResidenceForecast: number) =>
                  idResidenceForecast === state.entities[idResidenceForecastValue]?.residenceForecast
              )
                ? undefined
                : state.entities[idResidenceForecastValue]?.residenceForecast
            } as ResidenceForecastValueEntityState;
            return entities;
          }, {} as Dictionary<ResidenceForecastValueEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidenceForecastValueState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidenceForecastValueState {
  return { ...state, isLoaded, isLoading };
}
