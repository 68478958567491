import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ResidencePriceGrid, ResidencePriceGridEntityState } from '@_model/interfaces/residence-price-grid.model';
import { ResidencePriceGridRelationsIds } from './residence-price-grid-generated.effects';

export const getOneResidencePriceGrid = createAction(
  '[ResidencePriceGrid] Get One ResidencePriceGrid',
  props<{ idResidencePriceGrid: number; params?: any }>()
);

export const getManyResidencePriceGrids = createAction(
  '[ResidencePriceGrid] Get Many ResidencePriceGrids',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ResidencePriceGrid] Add Many Actives ResidencePriceGrid',
  props<{ idResidencePriceGrids: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidencePriceGrid] Delete One Active ResidencePriceGrid',
  props<{ idResidencePriceGrid: number }>()
);

export const clearActive = createAction('[ResidencePriceGrid] Clear Active ResidencePriceGrid');

export const upsertOneResidencePriceGrid = createAction(
  '[ResidencePriceGrid] Upsert One ResidencePriceGrid',
  props<{
    residencePriceGrid: Partial<ResidencePriceGrid>;
    ids?: ResidencePriceGridRelationsIds;
  }>()
);

export const upsertManyResidencePriceGrids = createAction(
  '[ResidencePriceGrid] Upsert Many ResidencePriceGrids',
  props<{
    residencePriceGrids: Partial<ResidencePriceGrid>[];
    ids?: ResidencePriceGridRelationsIds;
  }>()
);

export const upsertManyResidencePriceGridsSuccess = createAction(
  '[ResidencePriceGrid] Create Many ResidencePriceGrids Success',
  props<{ residencePriceGrids: ResidencePriceGridEntityState[] }>()
);

export const deleteOneResidencePriceGrid = createAction(
  '[ResidencePriceGrid] Delete One ResidencePriceGrid',
  props<{ idResidencePriceGrid: number }>()
);

export const deleteOneResidencePriceGridSuccess = createAction(
  '[ResidencePriceGrid] Delete One ResidencePriceGrid Success',
  props<{ idResidencePriceGrid: number }>()
);

export const normalizeManyResidencePriceGridsAfterUpsert = createAction(
  '[ResidencePriceGrid] Normalize Many ResidencePriceGrids After Upsert',
  props<{ residencePriceGrids: ResidencePriceGridEntityState[] }>()
);

export const residencePriceGridsFailure = createAction(
  '[ResidencePriceGrid] ResidencePriceGrids Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidencePriceGrid] Clear ResidencePriceGrids');

export const addManyStratalotPriceListSuccess = createAction(
  '[ResidencePriceGrid] Add Many stratalot-price-list',
  props<{ idResidencePriceGrid: number; idStratalotPriceLists: number[] }>()
);

export const deleteManyStratalotPriceListSuccess = createAction(
  '[ResidencePriceGrid] Delete Many StratalotPriceLists',
  props<{ idStratalotPriceLists: number[]; idResidencePriceGrids: number[] }>()
);

export const addManyStratalotPriceSuccess = createAction(
  '[ResidencePriceGrid] Add Many stratalot-price',
  props<{ idResidencePriceGrid: number; idStratalotPrices: number[] }>()
);

export const deleteManyStratalotPriceSuccess = createAction(
  '[ResidencePriceGrid] Delete Many StratalotPrices',
  props<{ idStratalotPrices: number[]; idResidencePriceGrids: number[] }>()
);

export const addManyResidencePriceGridModifierSuccess = createAction(
  '[ResidencePriceGrid] Add Many residence-price-grid-modifier',
  props<{ idResidencePriceGrid: number; idResidencePriceGridModifiers: number[] }>()
);

export const deleteManyResidencePriceGridModifierSuccess = createAction(
  '[ResidencePriceGrid] Delete Many ResidencePriceGridModifiers',
  props<{ idResidencePriceGridModifiers: number[]; idResidencePriceGrids: number[] }>()
);

export const addManyResidencePriceGridHistorySuccess = createAction(
  '[ResidencePriceGrid] Add Many residence-price-grid-history',
  props<{ idResidencePriceGrid: number; idResidencePriceGridHistories: number[] }>()
);

export const deleteManyResidencePriceGridHistorySuccess = createAction(
  '[ResidencePriceGrid] Delete Many ResidencePriceGridHistories',
  props<{ idResidencePriceGridHistories: number[]; idResidencePriceGrids: number[] }>()
);

export const addResidenceSuccess = createAction(
  '[ResidencePriceGrid] Add Residence',
  props<{ idResidencePriceGrid: number; idResidence: number }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[ResidencePriceGrid] Delete Many Residence',
  props<{ idResidences: number[]; idResidencePriceGrids: number[] }>()
);
