import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedGeneratedDocumentsCompanyApiService } from './generated-documents-company-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class GeneratedDocumentsCompanyApiService extends GeneratedGeneratedDocumentsCompanyApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
