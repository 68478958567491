import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ResidenceTodo, ResidenceTodoEntityState } from '@_model/interfaces/residence-todo.model';
import { ResidenceTodoRelationsIds } from './residence-todo-generated.effects';

export const getOneResidenceTodo = createAction(
  '[ResidenceTodo] Get One ResidenceTodo',
  props<{ idResidenceTodo: number; params?: any }>()
);

export const getManyResidenceTodos = createAction('[ResidenceTodo] Get Many ResidenceTodos', props<{ params: any }>());

export const addManyActives = createAction(
  '[ResidenceTodo] Add Many Actives ResidenceTodo',
  props<{ idResidenceTodos: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidenceTodo] Delete One Active ResidenceTodo',
  props<{ idResidenceTodo: number }>()
);

export const clearActive = createAction('[ResidenceTodo] Clear Active ResidenceTodo');

export const upsertOneResidenceTodo = createAction(
  '[ResidenceTodo] Upsert One ResidenceTodo',
  props<{
    residenceTodo: Partial<ResidenceTodo>;
    ids?: ResidenceTodoRelationsIds;
  }>()
);

export const upsertManyResidenceTodos = createAction(
  '[ResidenceTodo] Upsert Many ResidenceTodos',
  props<{
    residenceTodos: Partial<ResidenceTodo>[];
    ids?: ResidenceTodoRelationsIds;
  }>()
);

export const upsertManyResidenceTodosSuccess = createAction(
  '[ResidenceTodo] Create Many ResidenceTodos Success',
  props<{ residenceTodos: ResidenceTodoEntityState[] }>()
);

export const deleteOneResidenceTodo = createAction(
  '[ResidenceTodo] Delete One ResidenceTodo',
  props<{ idResidenceTodo: number }>()
);

export const deleteOneResidenceTodoSuccess = createAction(
  '[ResidenceTodo] Delete One ResidenceTodo Success',
  props<{ idResidenceTodo: number }>()
);

export const normalizeManyResidenceTodosAfterUpsert = createAction(
  '[ResidenceTodo] Normalize Many ResidenceTodos After Upsert',
  props<{ residenceTodos: ResidenceTodoEntityState[] }>()
);

export const residenceTodosFailure = createAction(
  '[ResidenceTodo] ResidenceTodos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidenceTodo] Clear ResidenceTodos');

export const addOrganizationResidenceTodoSuccess = createAction(
  '[ResidenceTodo] Add OrganizationResidenceTodo',
  props<{ idResidenceTodo: number; idOrganizationResidenceTodo: number }>()
);

export const deleteManyOrganizationResidenceTodoSuccess = createAction(
  '[ResidenceTodo] Delete Many OrganizationResidenceTodo',
  props<{ idOrganizationResidenceTodos: number[]; idResidenceTodos: number[] }>()
);

export const addResidenceSuccess = createAction(
  '[ResidenceTodo] Add Residence',
  props<{ idResidenceTodo: number; idResidence: number }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[ResidenceTodo] Delete Many Residence',
  props<{ idResidences: number[]; idResidenceTodos: number[] }>()
);
