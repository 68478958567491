import { CompanyMyNotaryState, initialState } from './company-my-notary.state';
import { Action, createReducer } from '@ngrx/store';
import { companyMyNotaryReducersGeneratedFunctions } from './company-my-notary-generated.reducer';

const companyMyNotaryReducersFunctions = [...companyMyNotaryReducersGeneratedFunctions];

const companyMyNotaryReducer = createReducer(initialState, ...companyMyNotaryReducersFunctions);

export function reducer(state: CompanyMyNotaryState | undefined, action: Action) {
  return companyMyNotaryReducer(state, action);
}
