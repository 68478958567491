import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { DiffusionVisual, DiffusionVisualEntityState } from '@_model/interfaces/diffusion-visual.model';
import { DiffusionVisualRelationsIds } from './diffusion-visual-generated.effects';

export const getOneDiffusionVisual = createAction(
  '[DiffusionVisual] Get One DiffusionVisual',
  props<{ idDiffusionVisual: number; params?: any }>()
);

export const getManyDiffusionVisuals = createAction(
  '[DiffusionVisual] Get Many DiffusionVisuals',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[DiffusionVisual] Add Many Actives DiffusionVisual',
  props<{ idDiffusionVisuals: number[] }>()
);

export const deleteOneActive = createAction(
  '[DiffusionVisual] Delete One Active DiffusionVisual',
  props<{ idDiffusionVisual: number }>()
);

export const clearActive = createAction('[DiffusionVisual] Clear Active DiffusionVisual');

export const upsertOneDiffusionVisual = createAction(
  '[DiffusionVisual] Upsert One DiffusionVisual',
  props<{
    diffusionVisual: Partial<DiffusionVisual>;
    ids?: DiffusionVisualRelationsIds;
  }>()
);

export const upsertManyDiffusionVisuals = createAction(
  '[DiffusionVisual] Upsert Many DiffusionVisuals',
  props<{
    diffusionVisuals: Partial<DiffusionVisual>[];
    ids?: DiffusionVisualRelationsIds;
  }>()
);

export const upsertManyDiffusionVisualsSuccess = createAction(
  '[DiffusionVisual] Create Many DiffusionVisuals Success',
  props<{ diffusionVisuals: DiffusionVisualEntityState[] }>()
);

export const deleteOneDiffusionVisual = createAction(
  '[DiffusionVisual] Delete One DiffusionVisual',
  props<{ idDiffusionVisual: number }>()
);

export const deleteOneDiffusionVisualSuccess = createAction(
  '[DiffusionVisual] Delete One DiffusionVisual Success',
  props<{ idDiffusionVisual: number }>()
);

export const normalizeManyDiffusionVisualsAfterUpsert = createAction(
  '[DiffusionVisual] Normalize Many DiffusionVisuals After Upsert',
  props<{ diffusionVisuals: DiffusionVisualEntityState[] }>()
);

export const diffusionVisualsFailure = createAction(
  '[DiffusionVisual] DiffusionVisuals Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[DiffusionVisual] Clear DiffusionVisuals');

export const addResidenceSuccess = createAction(
  '[DiffusionVisual] Add Residence',
  props<{ idDiffusionVisual: number; idResidence: number }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[DiffusionVisual] Delete Many Residence',
  props<{ idResidences: number[]; idDiffusionVisuals: number[] }>()
);

export const addStratalotSuccess = createAction(
  '[DiffusionVisual] Add Stratalot',
  props<{ idDiffusionVisual: number; idStratalot: number }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[DiffusionVisual] Delete Many Stratalot',
  props<{ idStratalots: number[]; idDiffusionVisuals: number[] }>()
);
