import { CompanyCommunicationState, initialState } from './company-communication.state';
import { Action, createReducer } from '@ngrx/store';
import { companyCommunicationReducersGeneratedFunctions } from './company-communication-generated.reducer';

const companyCommunicationReducersFunctions = [...companyCommunicationReducersGeneratedFunctions];

const companyCommunicationReducer = createReducer(initialState, ...companyCommunicationReducersFunctions);

export function reducer(state: CompanyCommunicationState | undefined, action: Action) {
  return companyCommunicationReducer(state, action);
}
