import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StepProgressFamilyEntityState } from '@_model/interfaces/step-progress-family.model';

export interface StepProgressFamilyState extends EntityState<StepProgressFamilyEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<StepProgressFamilyEntityState> = createEntityAdapter<StepProgressFamilyEntityState>(
  {
    selectId: o => o.idStepProgressFamily
  }
);
export const initialState: StepProgressFamilyState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const stepProgressFamilyFeatureKey = 'stepProgressFamily';
