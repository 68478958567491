import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CompanyStratalotTypeApiService } from '@_services/api/company-stratalot-type-api.service';
import { getMultiAction } from '@_store/batched-actions';
import { AppState } from '@_store/index.reducers';
import { of } from 'rxjs';
import { withLatestFrom, map, concatMap, mergeMap, catchError } from 'rxjs/operators';
import {
  GeneratedCompanyStratalotTypeEffects,
  getDefaultDeleteCompanyStratalotTypeActions
} from './company-stratalot-type-generated.effects';
import { selectCompanyStratalotTypeState } from './company-stratalot-type-generated.selectors';
import * as companyStratalotTypeActions from '@_store/company-stratalot-type/company-stratalot-type.actions';

@Injectable()
export class CompanyStratalotTypeEffects extends GeneratedCompanyStratalotTypeEffects {
  constructor(
    actions$: Actions,
    companyStratalotTypeApiService: CompanyStratalotTypeApiService,
    store$: Store<AppState>
  ) {
    super(actions$, companyStratalotTypeApiService, store$);
  }

  deleteOneCompanyStratalotType$ = createEffect(() => {
    const selectCompanyStratalotTypeState$ = this.store$.select(selectCompanyStratalotTypeState);
    return this.actions$.pipe(
      ofType(companyStratalotTypeActions.deleteOneCompanyStratalotTypeWithParams),
      withLatestFrom(selectCompanyStratalotTypeState$),
      map(([{ idCompanyStratalotType, newIdCompanyStratalotType }, state]) => [
        { idCompanyStratalotType, newIdCompanyStratalotType },
        { companyStratalotType: state.entities[idCompanyStratalotType] }
      ]),
      concatMap(([{ idCompanyStratalotType, newIdCompanyStratalotType }, { companyStratalotType }]) =>
        this.companyStratalotTypeApiService
          .deleteAndReplaceCompanyStratalotType(idCompanyStratalotType, { newIdCompanyStratalotType })
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteCompanyStratalotTypeActions(companyStratalotType),
                companyStratalotTypeActions.deleteOneCompanyStratalotType.type
              )
            ]),
            catchError(error => of(companyStratalotTypeActions.companyStratalotTypesFailure({ error })))
          )
      )
    );
  });
}
