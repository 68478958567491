import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StratalotTypeApiService } from '@_services/api/stratalot-type-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedStratalotTypeEffects } from './stratalot-type-generated.effects';

@Injectable()
export class StratalotTypeEffects extends GeneratedStratalotTypeEffects {
  constructor(actions$: Actions, stratalotTypeApiService: StratalotTypeApiService, store$: Store<AppState>) {
    super(actions$, stratalotTypeApiService, store$);
  }
}
