import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyTerritoireUserActions from './company-territoire-user.actions';
import { adapter, initialState, CompanyTerritoireUserState } from './company-territoire-user.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyTerritoireUserEntityState } from '@_model/interfaces/company-territoire-user.model';

export const companyTerritoireUserReducersGeneratedFunctions: ReducerTypes<
  CompanyTerritoireUserState,
  readonly ActionCreator[]
>[] = [
  on(CompanyTerritoireUserActions.getOneCompanyTerritoireUser, (state: CompanyTerritoireUserState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyTerritoireUserActions.getManyCompanyTerritoireUsers, (state: CompanyTerritoireUserState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyTerritoireUserActions.upsertOneCompanyTerritoireUser, (state: CompanyTerritoireUserState) =>
    setLoadingsState(state, true)
  ),

  on(
    CompanyTerritoireUserActions.upsertManyCompanyTerritoireUsersSuccess,
    (state: CompanyTerritoireUserState, { companyTerritoireUsers }) =>
      adapter.upsertMany(companyTerritoireUsers, setLoadingsState(state, false))
  ),
  on(CompanyTerritoireUserActions.deleteOneCompanyTerritoireUser, (state: CompanyTerritoireUserState) =>
    setLoadingsState(state, true)
  ),
  on(
    CompanyTerritoireUserActions.deleteOneCompanyTerritoireUserSuccess,
    (state: CompanyTerritoireUserState, { idCompanyTerritoireUser }) =>
      adapter.removeOne(idCompanyTerritoireUser, setLoadingsState(state, false))
  ),
  on(CompanyTerritoireUserActions.clearActive, (state: CompanyTerritoireUserState) => ({ ...state, actives: [] })),
  on(
    CompanyTerritoireUserActions.addManyActives,
    (state: CompanyTerritoireUserState, { idCompanyTerritoireUsers }) => ({
      ...state,
      actives: state.actives.concat(idCompanyTerritoireUsers)
    })
  ),
  on(
    CompanyTerritoireUserActions.deleteOneActive,
    (state: CompanyTerritoireUserState, { idCompanyTerritoireUser }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCompanyTerritoireUser)
    })
  ),

  on(CompanyTerritoireUserActions.clearStore, () => initialState),

  on(
    CompanyTerritoireUserActions.addCompanyTerritoireSuccess,
    (state: CompanyTerritoireUserState, { idCompanyTerritoireUser, idCompanyTerritoire }) => {
      if (!state.entities[idCompanyTerritoireUser]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyTerritoireUser]: {
            ...state.entities[idCompanyTerritoireUser],
            companyTerritoire: idCompanyTerritoire
          }
        }
      };
    }
  ),

  on(
    CompanyTerritoireUserActions.deleteManyCompanyTerritoireSuccess,
    (state: CompanyTerritoireUserState, { idCompanyTerritoires, idCompanyTerritoireUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyTerritoireUsers.reduce((entities, idCompanyTerritoireUser) => {
            if (!state.entities[idCompanyTerritoireUser]?.companyTerritoire) {
              return entities;
            }
            entities[idCompanyTerritoireUser] = {
              ...state.entities[idCompanyTerritoireUser],
              companyTerritoire: idCompanyTerritoires.some(
                (idCompanyTerritoire: number) =>
                  idCompanyTerritoire === state.entities[idCompanyTerritoireUser]?.companyTerritoire
              )
                ? undefined
                : state.entities[idCompanyTerritoireUser]?.companyTerritoire
            } as CompanyTerritoireUserEntityState;
            return entities;
          }, {} as Dictionary<CompanyTerritoireUserEntityState>)
        }
      };
    }
  ),

  on(
    CompanyTerritoireUserActions.addUserSuccess,
    (state: CompanyTerritoireUserState, { idCompanyTerritoireUser, idUser }) => {
      if (!state.entities[idCompanyTerritoireUser]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyTerritoireUser]: {
            ...state.entities[idCompanyTerritoireUser],
            user: idUser
          }
        }
      };
    }
  ),

  on(
    CompanyTerritoireUserActions.deleteManyUserSuccess,
    (state: CompanyTerritoireUserState, { idUsers, idCompanyTerritoireUsers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyTerritoireUsers.reduce((entities, idCompanyTerritoireUser) => {
            if (!state.entities[idCompanyTerritoireUser]?.user) {
              return entities;
            }
            entities[idCompanyTerritoireUser] = {
              ...state.entities[idCompanyTerritoireUser],
              user: idUsers.some((idUser: number) => idUser === state.entities[idCompanyTerritoireUser]?.user)
                ? undefined
                : state.entities[idCompanyTerritoireUser]?.user
            } as CompanyTerritoireUserEntityState;
            return entities;
          }, {} as Dictionary<CompanyTerritoireUserEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyTerritoireUserState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyTerritoireUserState {
  return { ...state, isLoaded, isLoading };
}
