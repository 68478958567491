import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyCommunicationRecipientMetierActions from './company-communication-recipient-metier.actions';
import {
  adapter,
  initialState,
  CompanyCommunicationRecipientMetierState
} from './company-communication-recipient-metier.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyCommunicationRecipientMetierEntityState } from '@_model/interfaces/company-communication-recipient-metier.model';

export const companyCommunicationRecipientMetierReducersGeneratedFunctions: ReducerTypes<
  CompanyCommunicationRecipientMetierState,
  readonly ActionCreator[]
>[] = [
  on(
    CompanyCommunicationRecipientMetierActions.getOneCompanyCommunicationRecipientMetier,
    (state: CompanyCommunicationRecipientMetierState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationRecipientMetierActions.getManyCompanyCommunicationRecipientMetiers,
    (state: CompanyCommunicationRecipientMetierState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationRecipientMetierActions.upsertOneCompanyCommunicationRecipientMetier,
    (state: CompanyCommunicationRecipientMetierState) => setLoadingsState(state, true)
  ),

  on(
    CompanyCommunicationRecipientMetierActions.upsertManyCompanyCommunicationRecipientMetiersSuccess,
    (state: CompanyCommunicationRecipientMetierState, { companyCommunicationRecipientMetiers }) =>
      adapter.upsertMany(companyCommunicationRecipientMetiers, setLoadingsState(state, false))
  ),
  on(
    CompanyCommunicationRecipientMetierActions.deleteOneCompanyCommunicationRecipientMetier,
    (state: CompanyCommunicationRecipientMetierState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationRecipientMetierActions.deleteOneCompanyCommunicationRecipientMetierSuccess,
    (state: CompanyCommunicationRecipientMetierState, { idCompanyCommunicationRecipientMetier }) =>
      adapter.removeOne(idCompanyCommunicationRecipientMetier, setLoadingsState(state, false))
  ),
  on(CompanyCommunicationRecipientMetierActions.clearActive, (state: CompanyCommunicationRecipientMetierState) => ({
    ...state,
    actives: []
  })),
  on(
    CompanyCommunicationRecipientMetierActions.addManyActives,
    (state: CompanyCommunicationRecipientMetierState, { idCompanyCommunicationRecipientMetiers }) => ({
      ...state,
      actives: state.actives.concat(idCompanyCommunicationRecipientMetiers)
    })
  ),
  on(
    CompanyCommunicationRecipientMetierActions.deleteOneActive,
    (state: CompanyCommunicationRecipientMetierState, { idCompanyCommunicationRecipientMetier }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCompanyCommunicationRecipientMetier)
    })
  ),

  on(CompanyCommunicationRecipientMetierActions.clearStore, () => initialState),

  on(
    CompanyCommunicationRecipientMetierActions.addCompanyCommunicationRecipientSuccess,
    (
      state: CompanyCommunicationRecipientMetierState,
      { idCompanyCommunicationRecipientMetier, idCompanyCommunicationRecipient }
    ) => {
      if (!state.entities[idCompanyCommunicationRecipientMetier]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationRecipientMetier]: {
            ...state.entities[idCompanyCommunicationRecipientMetier],
            companyCommunicationRecipient: idCompanyCommunicationRecipient
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationRecipientMetierActions.deleteManyCompanyCommunicationRecipientSuccess,
    (
      state: CompanyCommunicationRecipientMetierState,
      { idCompanyCommunicationRecipients, idCompanyCommunicationRecipientMetiers }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationRecipientMetiers.reduce((entities, idCompanyCommunicationRecipientMetier) => {
            if (!state.entities[idCompanyCommunicationRecipientMetier]?.companyCommunicationRecipient) {
              return entities;
            }
            entities[idCompanyCommunicationRecipientMetier] = {
              ...state.entities[idCompanyCommunicationRecipientMetier],
              companyCommunicationRecipient: idCompanyCommunicationRecipients.some(
                (idCompanyCommunicationRecipient: number) =>
                  idCompanyCommunicationRecipient ===
                  state.entities[idCompanyCommunicationRecipientMetier]?.companyCommunicationRecipient
              )
                ? undefined
                : state.entities[idCompanyCommunicationRecipientMetier]?.companyCommunicationRecipient
            } as CompanyCommunicationRecipientMetierEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationRecipientMetierEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationRecipientMetierActions.addOrganizationMetierSuccess,
    (
      state: CompanyCommunicationRecipientMetierState,
      { idCompanyCommunicationRecipientMetier, idOrganizationMetier }
    ) => {
      if (!state.entities[idCompanyCommunicationRecipientMetier]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationRecipientMetier]: {
            ...state.entities[idCompanyCommunicationRecipientMetier],
            organizationMetier: idOrganizationMetier
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationRecipientMetierActions.deleteManyOrganizationMetierSuccess,
    (
      state: CompanyCommunicationRecipientMetierState,
      { idOrganizationMetiers, idCompanyCommunicationRecipientMetiers }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationRecipientMetiers.reduce((entities, idCompanyCommunicationRecipientMetier) => {
            if (!state.entities[idCompanyCommunicationRecipientMetier]?.organizationMetier) {
              return entities;
            }
            entities[idCompanyCommunicationRecipientMetier] = {
              ...state.entities[idCompanyCommunicationRecipientMetier],
              organizationMetier: idOrganizationMetiers.some(
                (idOrganizationMetier: number) =>
                  idOrganizationMetier === state.entities[idCompanyCommunicationRecipientMetier]?.organizationMetier
              )
                ? undefined
                : state.entities[idCompanyCommunicationRecipientMetier]?.organizationMetier
            } as CompanyCommunicationRecipientMetierEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationRecipientMetierEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyCommunicationRecipientMetierState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyCommunicationRecipientMetierState {
  return { ...state, isLoaded, isLoading };
}
