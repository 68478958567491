import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResidenceStudyWork, ResidenceStudyWorkEntityState } from '@_model/interfaces/residence-study-work.model';
import { ResidenceStudy, ResidenceStudyEntityState } from '@_model/interfaces/residence-study.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, residenceStudyWorkFeatureKey, ResidenceStudyWorkState } from './residence-study-work.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residenceStudyWorkRelations: string[] = ['residenceStudies'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidenceStudyWorkState =
  createFeatureSelector<ResidenceStudyWorkState>(residenceStudyWorkFeatureKey);

export const selectIsLoadedResidenceStudyWork = createSelector(
  selectResidenceStudyWorkState,
  (state: ResidenceStudyWorkState) => state.isLoaded
);

export const selectIsLoadingResidenceStudyWork = createSelector(
  selectResidenceStudyWorkState,
  (state: ResidenceStudyWorkState) => state.isLoading
);

export const selectIsReadyResidenceStudyWork = createSelector(
  selectResidenceStudyWorkState,
  (state: ResidenceStudyWorkState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidenceStudyWork = createSelector(
  selectResidenceStudyWorkState,
  (state: ResidenceStudyWorkState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidenceStudyWorkModel: SelectorModel = {
  name: 'residenceStudyWorks',
  getSelector: selectAllResidenceStudyWorksDictionary,
  isReady: selectIsReadyResidenceStudyWork
};

export const selectResidenceStudyWorksEntities = createSelector(selectResidenceStudyWorkState, selectEntities);

export const selectResidenceStudyWorksArray = createSelector(selectResidenceStudyWorkState, selectAll);

export const selectIdResidenceStudyWorksActive = createSelector(
  selectResidenceStudyWorkState,
  (state: ResidenceStudyWorkState) => state.actives
);

const residenceStudyWorksInObject = (residenceStudyWorks: Dictionary<ResidenceStudyWorkEntityState>) => ({
  residenceStudyWorks
});

const selectResidenceStudyWorksEntitiesDictionary = createSelector(
  selectResidenceStudyWorksEntities,
  residenceStudyWorksInObject
);

const selectAllResidenceStudyWorksObject = createSelector(selectResidenceStudyWorksEntities, residenceStudyWorks => {
  return hydrateAll({ residenceStudyWorks });
});

const selectOneResidenceStudyWorkDictionary = (idResidenceStudyWork: number) =>
  createSelector(selectResidenceStudyWorksEntities, residenceStudyWorks => {
    return { residenceStudyWorks: { [idResidenceStudyWork]: residenceStudyWorks[idResidenceStudyWork] } };
  });

const selectOneResidenceStudyWorkDictionaryWithoutChild = (idResidenceStudyWork: number) =>
  createSelector(selectResidenceStudyWorksEntities, residenceStudyWorks => {
    return { residenceStudyWork: residenceStudyWorks[idResidenceStudyWork] };
  });

const selectActiveResidenceStudyWorksEntities = createSelector(
  selectIdResidenceStudyWorksActive,
  selectResidenceStudyWorksEntities,
  (actives: number[], residenceStudyWorks: Dictionary<ResidenceStudyWorkEntityState>) =>
    getResidenceStudyWorksFromActives(actives, residenceStudyWorks)
);

function getResidenceStudyWorksFromActives(
  actives: number[],
  residenceStudyWorks: Dictionary<ResidenceStudyWorkEntityState>
): Dictionary<ResidenceStudyWorkEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residenceStudyWorks[idActive]) {
      acc[idActive] = residenceStudyWorks[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidenceStudyWorkEntityState>);
}

const selectAllResidenceStudyWorksSelectors: Dictionary<Selector> = {};
export function selectAllResidenceStudyWorks(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidenceStudyWork>(
      schema,
      selectAllResidenceStudyWorksSelectors,
      selectResidenceStudyWorksEntitiesDictionary,
      getRelationSelectors,
      residenceStudyWorkRelations,
      hydrateAll,
      'residenceStudyWork'
    );
  } else {
    return selectAllResidenceStudyWorksObject;
  }
}

export function selectAllResidenceStudyWorksDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residenceStudyWorks'
): Selector {
  return createSelector(selectAllResidenceStudyWorks(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidenceStudyWorkEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residenceStudyWorks.length; i++) {
      res[customKey][result.residenceStudyWorks[i].idResidenceStudyWork] = result.residenceStudyWorks[i];
    }
    return res;
  });
}

export function selectOneResidenceStudyWork(schema: SelectSchema = {}, idResidenceStudyWork: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidenceStudyWorkDictionary(idResidenceStudyWork)];
    selectors.push(...getRelationSelectors(schema, residenceStudyWorkRelations, 'residenceStudyWork'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidenceStudyWorkDictionaryWithoutChild(idResidenceStudyWork);
  }
}

export function selectActiveResidenceStudyWorks(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidenceStudyWorksEntities, residenceStudyWorks => ({ residenceStudyWorks }))
  ];
  selectors.push(...getRelationSelectors(schema, residenceStudyWorkRelations, 'residenceStudyWork'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residenceStudyWorks: Dictionary<ResidenceStudyWorkEntityState>;
  residenceStudies?: Dictionary<ResidenceStudyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { residenceStudyWorks: (ResidenceStudyWork | null)[] } {
  const { residenceStudyWorks, residenceStudies } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    residenceStudyWorks: Object.keys(residenceStudyWorks).map(idResidenceStudyWork =>
      hydrate(residenceStudyWorks[idResidenceStudyWork] as ResidenceStudyWorkEntityState, residenceStudies)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { residenceStudyWork: ResidenceStudyWorkEntityState | null } {
  const { residenceStudyWorks, residenceStudies } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const residenceStudyWork = Object.values(residenceStudyWorks)[0];
  return { residenceStudyWork: hydrate(residenceStudyWork as ResidenceStudyWorkEntityState, residenceStudies) };
}

function hydrate(
  residenceStudyWork: ResidenceStudyWorkEntityState,
  residenceStudyEntities?: Dictionary<ResidenceStudyEntityState>
): ResidenceStudyWork | null {
  if (!residenceStudyWork) {
    return null;
  }

  const residenceStudyWorkHydrated: ResidenceStudyWorkEntityState = { ...residenceStudyWork };
  if (residenceStudyEntities) {
    residenceStudyWorkHydrated.residenceStudy = residenceStudyEntities[
      residenceStudyWork.residenceStudy as number
    ] as ResidenceStudy;
  } else {
    delete residenceStudyWorkHydrated.residenceStudy;
  }

  return residenceStudyWorkHydrated as ResidenceStudyWork;
}
