export interface ConfirmationTheme {
  backgroundColor: string;
  color: string;
}

export enum ConfirmationThemeEnum {
  danger = 'danger',
  default = 'default'
}

export type ConfirmationThemes = {
  [key in ConfirmationThemeEnum]: ConfirmationTheme;
};

export const THEMES: ConfirmationThemes = {
  danger: {
    backgroundColor: '#F44336',
    color: 'white'
  },
  default: {
    backgroundColor: '#579EA0',
    color: 'white'
  }
};
