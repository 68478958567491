import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyStudyConclusionEntityState } from '@_model/interfaces/company-study-conclusion.model';

export interface CompanyStudyConclusionState extends EntityState<CompanyStudyConclusionEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyStudyConclusionEntityState> =
  createEntityAdapter<CompanyStudyConclusionEntityState>({
    selectId: o => o.idCompanyStudyConclusion
  });
export const initialState: CompanyStudyConclusionState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyStudyConclusionFeatureKey = 'companyStudyConclusion';
