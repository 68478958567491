import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  CompanyStudyConclusion,
  CompanyStudyConclusionEntityState
} from '@_model/interfaces/company-study-conclusion.model';
import { CompanyStudyConclusionRelationsIds } from './company-study-conclusion-generated.effects';

export const getOneCompanyStudyConclusion = createAction(
  '[CompanyStudyConclusion] Get One CompanyStudyConclusion',
  props<{ idCompanyStudyConclusion: number; params?: any }>()
);

export const getManyCompanyStudyConclusions = createAction(
  '[CompanyStudyConclusion] Get Many CompanyStudyConclusions',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyStudyConclusion] Add Many Actives CompanyStudyConclusion',
  props<{ idCompanyStudyConclusions: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyStudyConclusion] Delete One Active CompanyStudyConclusion',
  props<{ idCompanyStudyConclusion: number }>()
);

export const clearActive = createAction('[CompanyStudyConclusion] Clear Active CompanyStudyConclusion');

export const upsertOneCompanyStudyConclusion = createAction(
  '[CompanyStudyConclusion] Upsert One CompanyStudyConclusion',
  props<{
    companyStudyConclusion: Partial<CompanyStudyConclusion>;
    ids?: CompanyStudyConclusionRelationsIds;
  }>()
);

export const upsertManyCompanyStudyConclusions = createAction(
  '[CompanyStudyConclusion] Upsert Many CompanyStudyConclusions',
  props<{
    companyStudyConclusions: Partial<CompanyStudyConclusion>[];
    ids?: CompanyStudyConclusionRelationsIds;
  }>()
);

export const upsertManyCompanyStudyConclusionsSuccess = createAction(
  '[CompanyStudyConclusion] Create Many CompanyStudyConclusions Success',
  props<{ companyStudyConclusions: CompanyStudyConclusionEntityState[] }>()
);

export const deleteOneCompanyStudyConclusion = createAction(
  '[CompanyStudyConclusion] Delete One CompanyStudyConclusion',
  props<{ idCompanyStudyConclusion: number }>()
);

export const deleteOneCompanyStudyConclusionSuccess = createAction(
  '[CompanyStudyConclusion] Delete One CompanyStudyConclusion Success',
  props<{ idCompanyStudyConclusion: number }>()
);

export const normalizeManyCompanyStudyConclusionsAfterUpsert = createAction(
  '[CompanyStudyConclusion] Normalize Many CompanyStudyConclusions After Upsert',
  props<{ companyStudyConclusions: CompanyStudyConclusionEntityState[] }>()
);

export const companyStudyConclusionsFailure = createAction(
  '[CompanyStudyConclusion] CompanyStudyConclusions Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyStudyConclusion] Clear CompanyStudyConclusions');

export const addManyResidenceStudySuccess = createAction(
  '[CompanyStudyConclusion] Add Many residence-study',
  props<{ idCompanyStudyConclusion: number; idResidenceStudies: number[] }>()
);

export const deleteManyResidenceStudySuccess = createAction(
  '[CompanyStudyConclusion] Delete Many ResidenceStudies',
  props<{ idResidenceStudies: number[]; idCompanyStudyConclusions: number[] }>()
);

export const addCompanySuccess = createAction(
  '[CompanyStudyConclusion] Add Company',
  props<{ idCompanyStudyConclusion: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[CompanyStudyConclusion] Delete Many Company',
  props<{ idCompanies: number[]; idCompanyStudyConclusions: number[] }>()
);
