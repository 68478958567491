import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationResidenceTodoProfil,
  OrganizationResidenceTodoProfilEntityState
} from '@_model/interfaces/organization-residence-todo-profil.model';
import { OrganizationResidenceTodoProfilRelationsIds } from './organization-residence-todo-profil-generated.effects';

export const getOneOrganizationResidenceTodoProfil = createAction(
  '[OrganizationResidenceTodoProfil] Get One OrganizationResidenceTodoProfil',
  props<{ idOrganizationResidenceTodoProfil: number; params?: any }>()
);

export const getManyOrganizationResidenceTodoProfils = createAction(
  '[OrganizationResidenceTodoProfil] Get Many OrganizationResidenceTodoProfils',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationResidenceTodoProfil] Add Many Actives OrganizationResidenceTodoProfil',
  props<{ idOrganizationResidenceTodoProfils: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationResidenceTodoProfil] Delete One Active OrganizationResidenceTodoProfil',
  props<{ idOrganizationResidenceTodoProfil: number }>()
);

export const clearActive = createAction(
  '[OrganizationResidenceTodoProfil] Clear Active OrganizationResidenceTodoProfil'
);

export const upsertOneOrganizationResidenceTodoProfil = createAction(
  '[OrganizationResidenceTodoProfil] Upsert One OrganizationResidenceTodoProfil',
  props<{
    organizationResidenceTodoProfil: Partial<OrganizationResidenceTodoProfil>;
    ids?: OrganizationResidenceTodoProfilRelationsIds;
  }>()
);

export const upsertManyOrganizationResidenceTodoProfils = createAction(
  '[OrganizationResidenceTodoProfil] Upsert Many OrganizationResidenceTodoProfils',
  props<{
    organizationResidenceTodoProfils: Partial<OrganizationResidenceTodoProfil>[];
    ids?: OrganizationResidenceTodoProfilRelationsIds;
  }>()
);

export const upsertManyOrganizationResidenceTodoProfilsSuccess = createAction(
  '[OrganizationResidenceTodoProfil] Create Many OrganizationResidenceTodoProfils Success',
  props<{ organizationResidenceTodoProfils: OrganizationResidenceTodoProfilEntityState[] }>()
);

export const deleteOneOrganizationResidenceTodoProfil = createAction(
  '[OrganizationResidenceTodoProfil] Delete One OrganizationResidenceTodoProfil',
  props<{ idOrganizationResidenceTodoProfil: number }>()
);

export const deleteOneOrganizationResidenceTodoProfilSuccess = createAction(
  '[OrganizationResidenceTodoProfil] Delete One OrganizationResidenceTodoProfil Success',
  props<{ idOrganizationResidenceTodoProfil: number }>()
);

export const normalizeManyOrganizationResidenceTodoProfilsAfterUpsert = createAction(
  '[OrganizationResidenceTodoProfil] Normalize Many OrganizationResidenceTodoProfils After Upsert',
  props<{ organizationResidenceTodoProfils: OrganizationResidenceTodoProfilEntityState[] }>()
);

export const organizationResidenceTodoProfilsFailure = createAction(
  '[OrganizationResidenceTodoProfil] OrganizationResidenceTodoProfils Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationResidenceTodoProfil] Clear OrganizationResidenceTodoProfils');

export const addProfilSuccess = createAction(
  '[OrganizationResidenceTodoProfil] Add Profil',
  props<{ idOrganizationResidenceTodoProfil: number; idProfil: number }>()
);

export const deleteManyProfilSuccess = createAction(
  '[OrganizationResidenceTodoProfil] Delete Many Profil',
  props<{ idProfils: number[]; idOrganizationResidenceTodoProfils: number[] }>()
);

export const addOrganizationResidenceTodoSuccess = createAction(
  '[OrganizationResidenceTodoProfil] Add OrganizationResidenceTodo',
  props<{ idOrganizationResidenceTodoProfil: number; idOrganizationResidenceTodo: number }>()
);

export const deleteManyOrganizationResidenceTodoSuccess = createAction(
  '[OrganizationResidenceTodoProfil] Delete Many OrganizationResidenceTodo',
  props<{ idOrganizationResidenceTodos: number[]; idOrganizationResidenceTodoProfils: number[] }>()
);
