import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CompanyCommunicationStratalotTodo,
  CompanyCommunicationStratalotTodoEntityState
} from '@_model/interfaces/company-communication-stratalot-todo.model';
import { CompanyCommunication, CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';
import {
  OrganizationStratalotTodo,
  OrganizationStratalotTodoEntityState
} from '@_model/interfaces/organization-stratalot-todo.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  companyCommunicationStratalotTodoFeatureKey,
  CompanyCommunicationStratalotTodoState
} from './company-communication-stratalot-todo.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyCommunicationStratalotTodoRelations: string[] = [
  'companyCommunications',
  'organizationStratalotTodos'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyCommunicationStratalotTodoState =
  createFeatureSelector<CompanyCommunicationStratalotTodoState>(companyCommunicationStratalotTodoFeatureKey);

export const selectIsLoadedCompanyCommunicationStratalotTodo = createSelector(
  selectCompanyCommunicationStratalotTodoState,
  (state: CompanyCommunicationStratalotTodoState) => state.isLoaded
);

export const selectIsLoadingCompanyCommunicationStratalotTodo = createSelector(
  selectCompanyCommunicationStratalotTodoState,
  (state: CompanyCommunicationStratalotTodoState) => state.isLoading
);

export const selectIsReadyCompanyCommunicationStratalotTodo = createSelector(
  selectCompanyCommunicationStratalotTodoState,
  (state: CompanyCommunicationStratalotTodoState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyCommunicationStratalotTodo = createSelector(
  selectCompanyCommunicationStratalotTodoState,
  (state: CompanyCommunicationStratalotTodoState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyCommunicationStratalotTodoModel: SelectorModel = {
  name: 'companyCommunicationStratalotTodos',
  getSelector: selectAllCompanyCommunicationStratalotTodosDictionary,
  isReady: selectIsReadyCompanyCommunicationStratalotTodo
};

export const selectCompanyCommunicationStratalotTodosEntities = createSelector(
  selectCompanyCommunicationStratalotTodoState,
  selectEntities
);

export const selectCompanyCommunicationStratalotTodosArray = createSelector(
  selectCompanyCommunicationStratalotTodoState,
  selectAll
);

export const selectIdCompanyCommunicationStratalotTodosActive = createSelector(
  selectCompanyCommunicationStratalotTodoState,
  (state: CompanyCommunicationStratalotTodoState) => state.actives
);

const companyCommunicationStratalotTodosInObject = (
  companyCommunicationStratalotTodos: Dictionary<CompanyCommunicationStratalotTodoEntityState>
) => ({ companyCommunicationStratalotTodos });

const selectCompanyCommunicationStratalotTodosEntitiesDictionary = createSelector(
  selectCompanyCommunicationStratalotTodosEntities,
  companyCommunicationStratalotTodosInObject
);

const selectAllCompanyCommunicationStratalotTodosObject = createSelector(
  selectCompanyCommunicationStratalotTodosEntities,
  companyCommunicationStratalotTodos => {
    return hydrateAll({ companyCommunicationStratalotTodos });
  }
);

const selectOneCompanyCommunicationStratalotTodoDictionary = (idCompanyCommunicationStratalotTodo: number) =>
  createSelector(selectCompanyCommunicationStratalotTodosEntities, companyCommunicationStratalotTodos => {
    return {
      companyCommunicationStratalotTodos: {
        [idCompanyCommunicationStratalotTodo]: companyCommunicationStratalotTodos[idCompanyCommunicationStratalotTodo]
      }
    };
  });

const selectOneCompanyCommunicationStratalotTodoDictionaryWithoutChild = (
  idCompanyCommunicationStratalotTodo: number
) =>
  createSelector(selectCompanyCommunicationStratalotTodosEntities, companyCommunicationStratalotTodos => {
    return {
      companyCommunicationStratalotTodo: companyCommunicationStratalotTodos[idCompanyCommunicationStratalotTodo]
    };
  });

const selectActiveCompanyCommunicationStratalotTodosEntities = createSelector(
  selectIdCompanyCommunicationStratalotTodosActive,
  selectCompanyCommunicationStratalotTodosEntities,
  (actives: number[], companyCommunicationStratalotTodos: Dictionary<CompanyCommunicationStratalotTodoEntityState>) =>
    getCompanyCommunicationStratalotTodosFromActives(actives, companyCommunicationStratalotTodos)
);

function getCompanyCommunicationStratalotTodosFromActives(
  actives: number[],
  companyCommunicationStratalotTodos: Dictionary<CompanyCommunicationStratalotTodoEntityState>
): Dictionary<CompanyCommunicationStratalotTodoEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyCommunicationStratalotTodos[idActive]) {
      acc[idActive] = companyCommunicationStratalotTodos[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyCommunicationStratalotTodoEntityState>);
}

const selectAllCompanyCommunicationStratalotTodosSelectors: Dictionary<Selector> = {};
export function selectAllCompanyCommunicationStratalotTodos(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyCommunicationStratalotTodo>(
      schema,
      selectAllCompanyCommunicationStratalotTodosSelectors,
      selectCompanyCommunicationStratalotTodosEntitiesDictionary,
      getRelationSelectors,
      companyCommunicationStratalotTodoRelations,
      hydrateAll,
      'companyCommunicationStratalotTodo'
    );
  } else {
    return selectAllCompanyCommunicationStratalotTodosObject;
  }
}

export function selectAllCompanyCommunicationStratalotTodosDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyCommunicationStratalotTodos'
): Selector {
  return createSelector(selectAllCompanyCommunicationStratalotTodos(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyCommunicationStratalotTodoEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyCommunicationStratalotTodos.length; i++) {
      res[customKey][result.companyCommunicationStratalotTodos[i].idCompanyCommunicationStratalotTodo] =
        result.companyCommunicationStratalotTodos[i];
    }
    return res;
  });
}

export function selectOneCompanyCommunicationStratalotTodo(
  schema: SelectSchema = {},
  idCompanyCommunicationStratalotTodo: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [
      selectOneCompanyCommunicationStratalotTodoDictionary(idCompanyCommunicationStratalotTodo)
    ];
    selectors.push(
      ...getRelationSelectors(schema, companyCommunicationStratalotTodoRelations, 'companyCommunicationStratalotTodo')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyCommunicationStratalotTodoDictionaryWithoutChild(idCompanyCommunicationStratalotTodo);
  }
}

export function selectActiveCompanyCommunicationStratalotTodos(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyCommunicationStratalotTodosEntities, companyCommunicationStratalotTodos => ({
      companyCommunicationStratalotTodos
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, companyCommunicationStratalotTodoRelations, 'companyCommunicationStratalotTodo')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyCommunicationStratalotTodos: Dictionary<CompanyCommunicationStratalotTodoEntityState>;
  companyCommunications?: Dictionary<CompanyCommunicationEntityState>;
  organizationStratalotTodos?: Dictionary<OrganizationStratalotTodoEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  companyCommunicationStratalotTodos: (CompanyCommunicationStratalotTodo | null)[];
} {
  const { companyCommunicationStratalotTodos, companyCommunications, organizationStratalotTodos } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    companyCommunicationStratalotTodos: Object.keys(companyCommunicationStratalotTodos).map(
      idCompanyCommunicationStratalotTodo =>
        hydrate(
          companyCommunicationStratalotTodos[
            idCompanyCommunicationStratalotTodo
          ] as CompanyCommunicationStratalotTodoEntityState,
          companyCommunications,
          organizationStratalotTodos
        )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  companyCommunicationStratalotTodo: CompanyCommunicationStratalotTodoEntityState | null;
} {
  const { companyCommunicationStratalotTodos, companyCommunications, organizationStratalotTodos } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const companyCommunicationStratalotTodo = Object.values(companyCommunicationStratalotTodos)[0];
  return {
    companyCommunicationStratalotTodo: hydrate(
      companyCommunicationStratalotTodo as CompanyCommunicationStratalotTodoEntityState,
      companyCommunications,
      organizationStratalotTodos
    )
  };
}

function hydrate(
  companyCommunicationStratalotTodo: CompanyCommunicationStratalotTodoEntityState,
  companyCommunicationEntities?: Dictionary<CompanyCommunicationEntityState>,
  organizationStratalotTodoEntities?: Dictionary<OrganizationStratalotTodoEntityState>
): CompanyCommunicationStratalotTodo | null {
  if (!companyCommunicationStratalotTodo) {
    return null;
  }

  const companyCommunicationStratalotTodoHydrated: CompanyCommunicationStratalotTodoEntityState = {
    ...companyCommunicationStratalotTodo
  };
  if (companyCommunicationEntities) {
    companyCommunicationStratalotTodoHydrated.companyCommunication = companyCommunicationEntities[
      companyCommunicationStratalotTodo.companyCommunication as number
    ] as CompanyCommunication;
  } else {
    delete companyCommunicationStratalotTodoHydrated.companyCommunication;
  }
  if (organizationStratalotTodoEntities) {
    companyCommunicationStratalotTodoHydrated.organizationStratalotTodo = organizationStratalotTodoEntities[
      companyCommunicationStratalotTodo.organizationStratalotTodo as number
    ] as OrganizationStratalotTodo;
  } else {
    delete companyCommunicationStratalotTodoHydrated.organizationStratalotTodo;
  }

  return companyCommunicationStratalotTodoHydrated as CompanyCommunicationStratalotTodo;
}
