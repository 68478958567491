import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as StepProgressFamilyActions from './step-progress-family.actions';
import { adapter, initialState, StepProgressFamilyState } from './step-progress-family.state';
import { Dictionary } from '@ngrx/entity';
import { StepProgressFamilyEntityState } from '@_model/interfaces/step-progress-family.model';

export const stepProgressFamilyReducersGeneratedFunctions: ReducerTypes<
  StepProgressFamilyState,
  readonly ActionCreator[]
>[] = [
  on(StepProgressFamilyActions.getOneStepProgressFamily, (state: StepProgressFamilyState) =>
    setLoadingsState(state, true)
  ),
  on(StepProgressFamilyActions.getManyStepProgressesFamilies, (state: StepProgressFamilyState) =>
    setLoadingsState(state, true)
  ),
  on(StepProgressFamilyActions.upsertOneStepProgressFamily, (state: StepProgressFamilyState) =>
    setLoadingsState(state, true)
  ),

  on(
    StepProgressFamilyActions.upsertManyStepProgressesFamiliesSuccess,
    (state: StepProgressFamilyState, { stepProgressesFamilies }) =>
      adapter.upsertMany(stepProgressesFamilies, setLoadingsState(state, false))
  ),
  on(StepProgressFamilyActions.deleteOneStepProgressFamily, (state: StepProgressFamilyState) =>
    setLoadingsState(state, true)
  ),
  on(
    StepProgressFamilyActions.deleteOneStepProgressFamilySuccess,
    (state: StepProgressFamilyState, { idStepProgressFamily }) =>
      adapter.removeOne(idStepProgressFamily, setLoadingsState(state, false))
  ),
  on(StepProgressFamilyActions.clearActive, (state: StepProgressFamilyState) => ({ ...state, actives: [] })),
  on(StepProgressFamilyActions.addManyActives, (state: StepProgressFamilyState, { idStepProgressesFamilies }) => ({
    ...state,
    actives: state.actives.concat(idStepProgressesFamilies)
  })),
  on(StepProgressFamilyActions.deleteOneActive, (state: StepProgressFamilyState, { idStepProgressFamily }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idStepProgressFamily)
  })),

  on(StepProgressFamilyActions.clearStore, () => initialState),

  on(
    StepProgressFamilyActions.addManyStepProgressSuccess,
    (state: StepProgressFamilyState, { idStepProgressFamily, idStepProgresses }) => {
      if (!state.entities[idStepProgressFamily]) {
        return state;
      }
      const stepProgresses = (state.entities[idStepProgressFamily]?.stepProgresses as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStepProgressFamily]: {
            ...state.entities[idStepProgressFamily],
            stepProgresses: stepProgresses.concat(idStepProgresses.filter(id => stepProgresses.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    StepProgressFamilyActions.deleteManyStepProgressSuccess,
    (state: StepProgressFamilyState, { idStepProgresses, idStepProgressesFamilies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStepProgressesFamilies.reduce((entities, idStepProgressFamily) => {
            if (!state.entities[idStepProgressFamily]?.stepProgresses) {
              return entities;
            }
            entities[idStepProgressFamily] = {
              ...state.entities[idStepProgressFamily],
              stepProgresses: (state.entities[idStepProgressFamily]?.stepProgresses as number[])?.filter(
                (idStepProgress: number) => !idStepProgresses.some((id: number) => id === idStepProgress)
              )
            } as StepProgressFamilyEntityState;
            return entities;
          }, {} as Dictionary<StepProgressFamilyEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: StepProgressFamilyState,
  isLoading: boolean,
  isLoaded: boolean = true
): StepProgressFamilyState {
  return { ...state, isLoaded, isLoading };
}
