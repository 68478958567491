import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyStratalotTypeActions from './company-stratalot-type.actions';
import { adapter, initialState, CompanyStratalotTypeState } from './company-stratalot-type.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyStratalotTypeEntityState } from '@_model/interfaces/company-stratalot-type.model';

export const companyStratalotTypeReducersGeneratedFunctions: ReducerTypes<
  CompanyStratalotTypeState,
  readonly ActionCreator[]
>[] = [
  on(CompanyStratalotTypeActions.getOneCompanyStratalotType, (state: CompanyStratalotTypeState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyStratalotTypeActions.getManyCompanyStratalotTypes, (state: CompanyStratalotTypeState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyStratalotTypeActions.upsertOneCompanyStratalotType, (state: CompanyStratalotTypeState) =>
    setLoadingsState(state, true)
  ),

  on(
    CompanyStratalotTypeActions.upsertManyCompanyStratalotTypesSuccess,
    (state: CompanyStratalotTypeState, { companyStratalotTypes }) =>
      adapter.upsertMany(companyStratalotTypes, setLoadingsState(state, false))
  ),
  on(CompanyStratalotTypeActions.deleteOneCompanyStratalotType, (state: CompanyStratalotTypeState) =>
    setLoadingsState(state, true)
  ),
  on(
    CompanyStratalotTypeActions.deleteOneCompanyStratalotTypeSuccess,
    (state: CompanyStratalotTypeState, { idCompanyStratalotType }) =>
      adapter.removeOne(idCompanyStratalotType, setLoadingsState(state, false))
  ),
  on(CompanyStratalotTypeActions.clearActive, (state: CompanyStratalotTypeState) => ({ ...state, actives: [] })),
  on(CompanyStratalotTypeActions.addManyActives, (state: CompanyStratalotTypeState, { idCompanyStratalotTypes }) => ({
    ...state,
    actives: state.actives.concat(idCompanyStratalotTypes)
  })),
  on(CompanyStratalotTypeActions.deleteOneActive, (state: CompanyStratalotTypeState, { idCompanyStratalotType }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCompanyStratalotType)
  })),

  on(CompanyStratalotTypeActions.clearStore, () => initialState),

  on(
    CompanyStratalotTypeActions.addManyStratalotSuccess,
    (state: CompanyStratalotTypeState, { idCompanyStratalotType, idStratalots }) => {
      if (!state.entities[idCompanyStratalotType]) {
        return state;
      }
      const stratalots = (state.entities[idCompanyStratalotType]?.stratalots as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStratalotType]: {
            ...state.entities[idCompanyStratalotType],
            stratalots: stratalots.concat(idStratalots.filter(id => stratalots.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CompanyStratalotTypeActions.deleteManyStratalotSuccess,
    (state: CompanyStratalotTypeState, { idStratalots, idCompanyStratalotTypes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStratalotTypes.reduce((entities, idCompanyStratalotType) => {
            if (!state.entities[idCompanyStratalotType]?.stratalots) {
              return entities;
            }
            entities[idCompanyStratalotType] = {
              ...state.entities[idCompanyStratalotType],
              stratalots: (state.entities[idCompanyStratalotType]?.stratalots as number[])?.filter(
                (idStratalot: number) => !idStratalots.some((id: number) => id === idStratalot)
              )
            } as CompanyStratalotTypeEntityState;
            return entities;
          }, {} as Dictionary<CompanyStratalotTypeEntityState>)
        }
      };
    }
  ),

  on(
    CompanyStratalotTypeActions.addManyStratalotPriceListSuccess,
    (state: CompanyStratalotTypeState, { idCompanyStratalotType, idStratalotPriceLists }) => {
      if (!state.entities[idCompanyStratalotType]) {
        return state;
      }
      const stratalotPriceLists = (state.entities[idCompanyStratalotType]?.stratalotPriceLists as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStratalotType]: {
            ...state.entities[idCompanyStratalotType],
            stratalotPriceLists: stratalotPriceLists.concat(
              idStratalotPriceLists.filter(id => stratalotPriceLists.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyStratalotTypeActions.deleteManyStratalotPriceListSuccess,
    (state: CompanyStratalotTypeState, { idStratalotPriceLists, idCompanyStratalotTypes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStratalotTypes.reduce((entities, idCompanyStratalotType) => {
            if (!state.entities[idCompanyStratalotType]?.stratalotPriceLists) {
              return entities;
            }
            entities[idCompanyStratalotType] = {
              ...state.entities[idCompanyStratalotType],
              stratalotPriceLists: (state.entities[idCompanyStratalotType]?.stratalotPriceLists as number[])?.filter(
                (idStratalotPriceList: number) =>
                  !idStratalotPriceLists.some((id: number) => id === idStratalotPriceList)
              )
            } as CompanyStratalotTypeEntityState;
            return entities;
          }, {} as Dictionary<CompanyStratalotTypeEntityState>)
        }
      };
    }
  ),

  on(
    CompanyStratalotTypeActions.addManyOrganizationLeadTodoRuleSuccess,
    (state: CompanyStratalotTypeState, { idCompanyStratalotType, idOrganizationLeadTodoRules }) => {
      if (!state.entities[idCompanyStratalotType]) {
        return state;
      }
      const organizationLeadTodoRules =
        (state.entities[idCompanyStratalotType]?.organizationLeadTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStratalotType]: {
            ...state.entities[idCompanyStratalotType],
            organizationLeadTodoRules: organizationLeadTodoRules.concat(
              idOrganizationLeadTodoRules.filter(id => organizationLeadTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyStratalotTypeActions.deleteManyOrganizationLeadTodoRuleSuccess,
    (state: CompanyStratalotTypeState, { idOrganizationLeadTodoRules, idCompanyStratalotTypes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStratalotTypes.reduce((entities, idCompanyStratalotType) => {
            if (!state.entities[idCompanyStratalotType]?.organizationLeadTodoRules) {
              return entities;
            }
            entities[idCompanyStratalotType] = {
              ...state.entities[idCompanyStratalotType],
              organizationLeadTodoRules: (
                state.entities[idCompanyStratalotType]?.organizationLeadTodoRules as number[]
              )?.filter(
                (idOrganizationLeadTodoRule: number) =>
                  !idOrganizationLeadTodoRules.some((id: number) => id === idOrganizationLeadTodoRule)
              )
            } as CompanyStratalotTypeEntityState;
            return entities;
          }, {} as Dictionary<CompanyStratalotTypeEntityState>)
        }
      };
    }
  ),

  on(
    CompanyStratalotTypeActions.addManyOrganizationStratalotTodoRuleSuccess,
    (state: CompanyStratalotTypeState, { idCompanyStratalotType, idOrganizationStratalotTodoRules }) => {
      if (!state.entities[idCompanyStratalotType]) {
        return state;
      }
      const organizationStratalotTodoRules =
        (state.entities[idCompanyStratalotType]?.organizationStratalotTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStratalotType]: {
            ...state.entities[idCompanyStratalotType],
            organizationStratalotTodoRules: organizationStratalotTodoRules.concat(
              idOrganizationStratalotTodoRules.filter(id => organizationStratalotTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyStratalotTypeActions.deleteManyOrganizationStratalotTodoRuleSuccess,
    (state: CompanyStratalotTypeState, { idOrganizationStratalotTodoRules, idCompanyStratalotTypes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStratalotTypes.reduce((entities, idCompanyStratalotType) => {
            if (!state.entities[idCompanyStratalotType]?.organizationStratalotTodoRules) {
              return entities;
            }
            entities[idCompanyStratalotType] = {
              ...state.entities[idCompanyStratalotType],
              organizationStratalotTodoRules: (
                state.entities[idCompanyStratalotType]?.organizationStratalotTodoRules as number[]
              )?.filter(
                (idOrganizationStratalotTodoRule: number) =>
                  !idOrganizationStratalotTodoRules.some((id: number) => id === idOrganizationStratalotTodoRule)
              )
            } as CompanyStratalotTypeEntityState;
            return entities;
          }, {} as Dictionary<CompanyStratalotTypeEntityState>)
        }
      };
    }
  ),

  on(
    CompanyStratalotTypeActions.addCompanySuccess,
    (state: CompanyStratalotTypeState, { idCompanyStratalotType, idCompany }) => {
      if (!state.entities[idCompanyStratalotType]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStratalotType]: {
            ...state.entities[idCompanyStratalotType],
            company: idCompany
          }
        }
      };
    }
  ),

  on(
    CompanyStratalotTypeActions.deleteManyCompanySuccess,
    (state: CompanyStratalotTypeState, { idCompanies, idCompanyStratalotTypes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStratalotTypes.reduce((entities, idCompanyStratalotType) => {
            if (!state.entities[idCompanyStratalotType]?.company) {
              return entities;
            }
            entities[idCompanyStratalotType] = {
              ...state.entities[idCompanyStratalotType],
              company: idCompanies.some(
                (idCompany: number) => idCompany === state.entities[idCompanyStratalotType]?.company
              )
                ? undefined
                : state.entities[idCompanyStratalotType]?.company
            } as CompanyStratalotTypeEntityState;
            return entities;
          }, {} as Dictionary<CompanyStratalotTypeEntityState>)
        }
      };
    }
  ),

  on(
    CompanyStratalotTypeActions.addStratalotTypeSuccess,
    (state: CompanyStratalotTypeState, { idCompanyStratalotType, idStratalotType }) => {
      if (!state.entities[idCompanyStratalotType]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStratalotType]: {
            ...state.entities[idCompanyStratalotType],
            stratalotType: idStratalotType
          }
        }
      };
    }
  ),

  on(
    CompanyStratalotTypeActions.deleteManyStratalotTypeSuccess,
    (state: CompanyStratalotTypeState, { idStratalotTypes, idCompanyStratalotTypes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStratalotTypes.reduce((entities, idCompanyStratalotType) => {
            if (!state.entities[idCompanyStratalotType]?.stratalotType) {
              return entities;
            }
            entities[idCompanyStratalotType] = {
              ...state.entities[idCompanyStratalotType],
              stratalotType: idStratalotTypes.some(
                (idStratalotType: number) => idStratalotType === state.entities[idCompanyStratalotType]?.stratalotType
              )
                ? undefined
                : state.entities[idCompanyStratalotType]?.stratalotType
            } as CompanyStratalotTypeEntityState;
            return entities;
          }, {} as Dictionary<CompanyStratalotTypeEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyStratalotTypeState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyStratalotTypeState {
  return { ...state, isLoaded, isLoading };
}
