import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResidenceShareRcp, ResidenceShareRcpEntityState } from '@_model/interfaces/residence-share-rcp.model';
import { StratalotRcp, StratalotRcpEntityState } from '@_model/interfaces/stratalot-rcp.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, residenceShareRcpFeatureKey, ResidenceShareRcpState } from './residence-share-rcp.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residenceShareRcpRelations: string[] = ['stratalotRcps'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidenceShareRcpState = createFeatureSelector<ResidenceShareRcpState>(residenceShareRcpFeatureKey);

export const selectIsLoadedResidenceShareRcp = createSelector(
  selectResidenceShareRcpState,
  (state: ResidenceShareRcpState) => state.isLoaded
);

export const selectIsLoadingResidenceShareRcp = createSelector(
  selectResidenceShareRcpState,
  (state: ResidenceShareRcpState) => state.isLoading
);

export const selectIsReadyResidenceShareRcp = createSelector(
  selectResidenceShareRcpState,
  (state: ResidenceShareRcpState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidenceShareRcp = createSelector(
  selectResidenceShareRcpState,
  (state: ResidenceShareRcpState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidenceShareRcpModel: SelectorModel = {
  name: 'residenceShareRcps',
  getSelector: selectAllResidenceShareRcpsDictionary,
  isReady: selectIsReadyResidenceShareRcp
};

export const selectResidenceShareRcpsEntities = createSelector(selectResidenceShareRcpState, selectEntities);

export const selectResidenceShareRcpsArray = createSelector(selectResidenceShareRcpState, selectAll);

export const selectIdResidenceShareRcpsActive = createSelector(
  selectResidenceShareRcpState,
  (state: ResidenceShareRcpState) => state.actives
);

const residenceShareRcpsInObject = (residenceShareRcps: Dictionary<ResidenceShareRcpEntityState>) => ({
  residenceShareRcps
});

const selectResidenceShareRcpsEntitiesDictionary = createSelector(
  selectResidenceShareRcpsEntities,
  residenceShareRcpsInObject
);

const selectAllResidenceShareRcpsObject = createSelector(selectResidenceShareRcpsEntities, residenceShareRcps => {
  return hydrateAll({ residenceShareRcps });
});

const selectOneResidenceShareRcpDictionary = (idResidenceShareRcp: number) =>
  createSelector(selectResidenceShareRcpsEntities, residenceShareRcps => {
    return { residenceShareRcps: { [idResidenceShareRcp]: residenceShareRcps[idResidenceShareRcp] } };
  });

const selectOneResidenceShareRcpDictionaryWithoutChild = (idResidenceShareRcp: number) =>
  createSelector(selectResidenceShareRcpsEntities, residenceShareRcps => {
    return { residenceShareRcp: residenceShareRcps[idResidenceShareRcp] };
  });

const selectActiveResidenceShareRcpsEntities = createSelector(
  selectIdResidenceShareRcpsActive,
  selectResidenceShareRcpsEntities,
  (actives: number[], residenceShareRcps: Dictionary<ResidenceShareRcpEntityState>) =>
    getResidenceShareRcpsFromActives(actives, residenceShareRcps)
);

function getResidenceShareRcpsFromActives(
  actives: number[],
  residenceShareRcps: Dictionary<ResidenceShareRcpEntityState>
): Dictionary<ResidenceShareRcpEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residenceShareRcps[idActive]) {
      acc[idActive] = residenceShareRcps[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidenceShareRcpEntityState>);
}

const selectAllResidenceShareRcpsSelectors: Dictionary<Selector> = {};
export function selectAllResidenceShareRcps(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidenceShareRcp>(
      schema,
      selectAllResidenceShareRcpsSelectors,
      selectResidenceShareRcpsEntitiesDictionary,
      getRelationSelectors,
      residenceShareRcpRelations,
      hydrateAll,
      'residenceShareRcp'
    );
  } else {
    return selectAllResidenceShareRcpsObject;
  }
}

export function selectAllResidenceShareRcpsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residenceShareRcps'
): Selector {
  return createSelector(selectAllResidenceShareRcps(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidenceShareRcpEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residenceShareRcps.length; i++) {
      res[customKey][result.residenceShareRcps[i].idResidenceShareRcp] = result.residenceShareRcps[i];
    }
    return res;
  });
}

export function selectOneResidenceShareRcp(schema: SelectSchema = {}, idResidenceShareRcp: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidenceShareRcpDictionary(idResidenceShareRcp)];
    selectors.push(...getRelationSelectors(schema, residenceShareRcpRelations, 'residenceShareRcp'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidenceShareRcpDictionaryWithoutChild(idResidenceShareRcp);
  }
}

export function selectActiveResidenceShareRcps(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidenceShareRcpsEntities, residenceShareRcps => ({ residenceShareRcps }))
  ];
  selectors.push(...getRelationSelectors(schema, residenceShareRcpRelations, 'residenceShareRcp'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residenceShareRcps: Dictionary<ResidenceShareRcpEntityState>;
  stratalotRcps?: Dictionary<StratalotRcpEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { residenceShareRcps: (ResidenceShareRcp | null)[] } {
  const { residenceShareRcps, stratalotRcps } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    residenceShareRcps: Object.keys(residenceShareRcps).map(idResidenceShareRcp =>
      hydrate(residenceShareRcps[idResidenceShareRcp] as ResidenceShareRcpEntityState, stratalotRcps)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { residenceShareRcp: ResidenceShareRcpEntityState | null } {
  const { residenceShareRcps, stratalotRcps } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const residenceShareRcp = Object.values(residenceShareRcps)[0];
  return { residenceShareRcp: hydrate(residenceShareRcp as ResidenceShareRcpEntityState, stratalotRcps) };
}

function hydrate(
  residenceShareRcp: ResidenceShareRcpEntityState,
  stratalotRcpEntities?: Dictionary<StratalotRcpEntityState>
): ResidenceShareRcp | null {
  if (!residenceShareRcp) {
    return null;
  }

  const residenceShareRcpHydrated: ResidenceShareRcpEntityState = { ...residenceShareRcp };
  if (stratalotRcpEntities) {
    residenceShareRcpHydrated.stratalotRcp = stratalotRcpEntities[
      residenceShareRcp.stratalotRcp as number
    ] as StratalotRcp;
  } else {
    delete residenceShareRcpHydrated.stratalotRcp;
  }

  return residenceShareRcpHydrated as ResidenceShareRcp;
}
