import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CompanyStudyCriteria, CompanyStudyCriteriaEntityState } from '@_model/interfaces/company-study-criteria.model';
import { CompanyStudyCriteriaRelationsIds } from './company-study-criteria-generated.effects';

export const getOneCompanyStudyCriteria = createAction(
  '[CompanyStudyCriteria] Get One CompanyStudyCriteria',
  props<{ idCompanyStudyCriteria: number; params?: any }>()
);

export const getManyCompanyStudyCriterias = createAction(
  '[CompanyStudyCriteria] Get Many CompanyStudyCriterias',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyStudyCriteria] Add Many Actives CompanyStudyCriteria',
  props<{ idCompanyStudyCriterias: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyStudyCriteria] Delete One Active CompanyStudyCriteria',
  props<{ idCompanyStudyCriteria: number }>()
);

export const clearActive = createAction('[CompanyStudyCriteria] Clear Active CompanyStudyCriteria');

export const upsertOneCompanyStudyCriteria = createAction(
  '[CompanyStudyCriteria] Upsert One CompanyStudyCriteria',
  props<{
    companyStudyCriteria: Partial<CompanyStudyCriteria>;
    ids?: CompanyStudyCriteriaRelationsIds;
  }>()
);

export const upsertManyCompanyStudyCriterias = createAction(
  '[CompanyStudyCriteria] Upsert Many CompanyStudyCriterias',
  props<{
    companyStudyCriterias: Partial<CompanyStudyCriteria>[];
    ids?: CompanyStudyCriteriaRelationsIds;
  }>()
);

export const upsertManyCompanyStudyCriteriasSuccess = createAction(
  '[CompanyStudyCriteria] Create Many CompanyStudyCriterias Success',
  props<{ companyStudyCriterias: CompanyStudyCriteriaEntityState[] }>()
);

export const deleteOneCompanyStudyCriteria = createAction(
  '[CompanyStudyCriteria] Delete One CompanyStudyCriteria',
  props<{ idCompanyStudyCriteria: number }>()
);

export const deleteOneCompanyStudyCriteriaSuccess = createAction(
  '[CompanyStudyCriteria] Delete One CompanyStudyCriteria Success',
  props<{ idCompanyStudyCriteria: number }>()
);

export const normalizeManyCompanyStudyCriteriasAfterUpsert = createAction(
  '[CompanyStudyCriteria] Normalize Many CompanyStudyCriterias After Upsert',
  props<{ companyStudyCriterias: CompanyStudyCriteriaEntityState[] }>()
);

export const companyStudyCriteriasFailure = createAction(
  '[CompanyStudyCriteria] CompanyStudyCriterias Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyStudyCriteria] Clear CompanyStudyCriterias');

export const addManyResidenceStudyCriteriaSuccess = createAction(
  '[CompanyStudyCriteria] Add Many residence-study-criteria',
  props<{ idCompanyStudyCriteria: number; idResidenceStudyCriterias: number[] }>()
);

export const deleteManyResidenceStudyCriteriaSuccess = createAction(
  '[CompanyStudyCriteria] Delete Many ResidenceStudyCriterias',
  props<{ idResidenceStudyCriterias: number[]; idCompanyStudyCriterias: number[] }>()
);

export const addCompanySuccess = createAction(
  '[CompanyStudyCriteria] Add Company',
  props<{ idCompanyStudyCriteria: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[CompanyStudyCriteria] Delete Many Company',
  props<{ idCompanies: number[]; idCompanyStudyCriterias: number[] }>()
);
