import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidenceStudyLotActions from './residence-study-lot.actions';
import { adapter, initialState, ResidenceStudyLotState } from './residence-study-lot.state';
import { Dictionary } from '@ngrx/entity';
import { ResidenceStudyLotEntityState } from '@_model/interfaces/residence-study-lot.model';

export const residenceStudyLotReducersGeneratedFunctions: ReducerTypes<
  ResidenceStudyLotState,
  readonly ActionCreator[]
>[] = [
  on(ResidenceStudyLotActions.getOneResidenceStudyLot, (state: ResidenceStudyLotState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceStudyLotActions.getManyResidenceStudyLots, (state: ResidenceStudyLotState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceStudyLotActions.upsertOneResidenceStudyLot, (state: ResidenceStudyLotState) =>
    setLoadingsState(state, true)
  ),

  on(
    ResidenceStudyLotActions.upsertManyResidenceStudyLotsSuccess,
    (state: ResidenceStudyLotState, { residenceStudyLots }) =>
      adapter.upsertMany(residenceStudyLots, setLoadingsState(state, false))
  ),
  on(ResidenceStudyLotActions.deleteOneResidenceStudyLot, (state: ResidenceStudyLotState) =>
    setLoadingsState(state, true)
  ),
  on(
    ResidenceStudyLotActions.deleteOneResidenceStudyLotSuccess,
    (state: ResidenceStudyLotState, { idResidenceStudyLot }) =>
      adapter.removeOne(idResidenceStudyLot, setLoadingsState(state, false))
  ),
  on(ResidenceStudyLotActions.clearActive, (state: ResidenceStudyLotState) => ({ ...state, actives: [] })),
  on(ResidenceStudyLotActions.addManyActives, (state: ResidenceStudyLotState, { idResidenceStudyLots }) => ({
    ...state,
    actives: state.actives.concat(idResidenceStudyLots)
  })),
  on(ResidenceStudyLotActions.deleteOneActive, (state: ResidenceStudyLotState, { idResidenceStudyLot }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idResidenceStudyLot)
  })),

  on(ResidenceStudyLotActions.clearStore, () => initialState),

  on(
    ResidenceStudyLotActions.addResidenceStudySuccess,
    (state: ResidenceStudyLotState, { idResidenceStudyLot, idResidenceStudy }) => {
      if (!state.entities[idResidenceStudyLot]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudyLot]: {
            ...state.entities[idResidenceStudyLot],
            residenceStudy: idResidenceStudy
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyLotActions.deleteManyResidenceStudySuccess,
    (state: ResidenceStudyLotState, { idResidenceStudies, idResidenceStudyLots }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudyLots.reduce((entities, idResidenceStudyLot) => {
            if (!state.entities[idResidenceStudyLot]?.residenceStudy) {
              return entities;
            }
            entities[idResidenceStudyLot] = {
              ...state.entities[idResidenceStudyLot],
              residenceStudy: idResidenceStudies.some(
                (idResidenceStudy: number) => idResidenceStudy === state.entities[idResidenceStudyLot]?.residenceStudy
              )
                ? undefined
                : state.entities[idResidenceStudyLot]?.residenceStudy
            } as ResidenceStudyLotEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyLotEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidenceStudyLotState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidenceStudyLotState {
  return { ...state, isLoaded, isLoading };
}
