import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { LeadStratalotEntityState } from '@_model/interfaces/lead-stratalot.model';

export interface LeadStratalotState extends EntityState<LeadStratalotEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<LeadStratalotEntityState> = createEntityAdapter<LeadStratalotEntityState>({
  selectId: o => o.idLeadStratalot
});
export const initialState: LeadStratalotState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const leadStratalotFeatureKey = 'leadStratalot';
