import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  CompanyCommunicationFile,
  CompanyCommunicationFileEntityState
} from '@_model/interfaces/company-communication-file.model';
import { CompanyCommunicationFileRelationsIds } from './company-communication-file-generated.effects';

export const getOneCompanyCommunicationFile = createAction(
  '[CompanyCommunicationFile] Get One CompanyCommunicationFile',
  props<{ idCompanyCommunicationFile: number; params?: any }>()
);

export const getManyCompanyCommunicationFiles = createAction(
  '[CompanyCommunicationFile] Get Many CompanyCommunicationFiles',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyCommunicationFile] Add Many Actives CompanyCommunicationFile',
  props<{ idCompanyCommunicationFiles: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyCommunicationFile] Delete One Active CompanyCommunicationFile',
  props<{ idCompanyCommunicationFile: number }>()
);

export const clearActive = createAction('[CompanyCommunicationFile] Clear Active CompanyCommunicationFile');

export const upsertOneCompanyCommunicationFile = createAction(
  '[CompanyCommunicationFile] Upsert One CompanyCommunicationFile',
  props<{
    companyCommunicationFile: Partial<CompanyCommunicationFile>;
    ids?: CompanyCommunicationFileRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationFiles = createAction(
  '[CompanyCommunicationFile] Upsert Many CompanyCommunicationFiles',
  props<{
    companyCommunicationFiles: Partial<CompanyCommunicationFile>[];
    ids?: CompanyCommunicationFileRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationFilesSuccess = createAction(
  '[CompanyCommunicationFile] Create Many CompanyCommunicationFiles Success',
  props<{ companyCommunicationFiles: CompanyCommunicationFileEntityState[] }>()
);

export const deleteOneCompanyCommunicationFile = createAction(
  '[CompanyCommunicationFile] Delete One CompanyCommunicationFile',
  props<{ idCompanyCommunicationFile: number }>()
);

export const deleteOneCompanyCommunicationFileSuccess = createAction(
  '[CompanyCommunicationFile] Delete One CompanyCommunicationFile Success',
  props<{ idCompanyCommunicationFile: number }>()
);

export const normalizeManyCompanyCommunicationFilesAfterUpsert = createAction(
  '[CompanyCommunicationFile] Normalize Many CompanyCommunicationFiles After Upsert',
  props<{ companyCommunicationFiles: CompanyCommunicationFileEntityState[] }>()
);

export const companyCommunicationFilesFailure = createAction(
  '[CompanyCommunicationFile] CompanyCommunicationFiles Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyCommunicationFile] Clear CompanyCommunicationFiles');

export const addCompanyCommunicationSuccess = createAction(
  '[CompanyCommunicationFile] Add CompanyCommunication',
  props<{ idCompanyCommunicationFile: number; idCompanyCommunication: number }>()
);

export const deleteManyCompanyCommunicationSuccess = createAction(
  '[CompanyCommunicationFile] Delete Many CompanyCommunication',
  props<{ idCompanyCommunications: number[]; idCompanyCommunicationFiles: number[] }>()
);
