import { RepositoryService } from '@_services/api/repository.service';
import { CompanyMyNotary } from '@_model/interfaces/company-my-notary.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyMyNotaryApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyMyNotaries(params?: any): Observable<CompanyMyNotary[]> {
    return this.repo.getData<CompanyMyNotary[]>('company-my-notary', params);
  }

  public getCompanyMyNotary(params: { idCompanyMyNotary: number; params?: any }): Observable<CompanyMyNotary> {
    return this.repo.getData<CompanyMyNotary>('company-my-notary/' + params.idCompanyMyNotary, params.params);
  }

  public addCompanyMyNotary(companyMyNotary: Partial<CompanyMyNotary>): Observable<CompanyMyNotary> {
    return this.repo.create<CompanyMyNotary>('company-my-notary', companyMyNotary);
  }

  public updateCompanyMyNotary(companyMyNotary: Partial<CompanyMyNotary>): Observable<CompanyMyNotary> {
    return this.repo.update('company-my-notary', companyMyNotary);
  }

  public deleteCompanyMyNotary(idCompanyMyNotary: number): Observable<number> {
    return this.repo.delete('company-my-notary/' + +idCompanyMyNotary);
  }
}
