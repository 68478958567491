import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationLeadTodoAppliedRuleActions from './organization-lead-todo-applied-rule.actions';
import {
  adapter,
  initialState,
  OrganizationLeadTodoAppliedRuleState
} from './organization-lead-todo-applied-rule.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationLeadTodoAppliedRuleEntityState } from '@_model/interfaces/organization-lead-todo-applied-rule.model';

export const organizationLeadTodoAppliedRuleReducersGeneratedFunctions: ReducerTypes<
  OrganizationLeadTodoAppliedRuleState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationLeadTodoAppliedRuleActions.getOneOrganizationLeadTodoAppliedRule,
    (state: OrganizationLeadTodoAppliedRuleState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationLeadTodoAppliedRuleActions.getManyOrganizationLeadTodoAppliedRules,
    (state: OrganizationLeadTodoAppliedRuleState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationLeadTodoAppliedRuleActions.upsertOneOrganizationLeadTodoAppliedRule,
    (state: OrganizationLeadTodoAppliedRuleState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationLeadTodoAppliedRuleActions.upsertManyOrganizationLeadTodoAppliedRulesSuccess,
    (state: OrganizationLeadTodoAppliedRuleState, { organizationLeadTodoAppliedRules }) =>
      adapter.upsertMany(organizationLeadTodoAppliedRules, setLoadingsState(state, false))
  ),
  on(
    OrganizationLeadTodoAppliedRuleActions.deleteOneOrganizationLeadTodoAppliedRule,
    (state: OrganizationLeadTodoAppliedRuleState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationLeadTodoAppliedRuleActions.deleteOneOrganizationLeadTodoAppliedRuleSuccess,
    (state: OrganizationLeadTodoAppliedRuleState, { idOrganizationLeadTodoAppliedRule }) =>
      adapter.removeOne(idOrganizationLeadTodoAppliedRule, setLoadingsState(state, false))
  ),
  on(OrganizationLeadTodoAppliedRuleActions.clearActive, (state: OrganizationLeadTodoAppliedRuleState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationLeadTodoAppliedRuleActions.addManyActives,
    (state: OrganizationLeadTodoAppliedRuleState, { idOrganizationLeadTodoAppliedRules }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationLeadTodoAppliedRules)
    })
  ),
  on(
    OrganizationLeadTodoAppliedRuleActions.deleteOneActive,
    (state: OrganizationLeadTodoAppliedRuleState, { idOrganizationLeadTodoAppliedRule }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationLeadTodoAppliedRule)
    })
  ),

  on(OrganizationLeadTodoAppliedRuleActions.clearStore, () => initialState),

  on(
    OrganizationLeadTodoAppliedRuleActions.addOrganizationLeadTodoSuccess,
    (state: OrganizationLeadTodoAppliedRuleState, { idOrganizationLeadTodoAppliedRule, idOrganizationLeadTodo }) => {
      if (!state.entities[idOrganizationLeadTodoAppliedRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodoAppliedRule]: {
            ...state.entities[idOrganizationLeadTodoAppliedRule],
            organizationLeadTodo: idOrganizationLeadTodo
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoAppliedRuleActions.deleteManyOrganizationLeadTodoSuccess,
    (state: OrganizationLeadTodoAppliedRuleState, { idOrganizationLeadTodos, idOrganizationLeadTodoAppliedRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodoAppliedRules.reduce((entities, idOrganizationLeadTodoAppliedRule) => {
            if (!state.entities[idOrganizationLeadTodoAppliedRule]?.organizationLeadTodo) {
              return entities;
            }
            entities[idOrganizationLeadTodoAppliedRule] = {
              ...state.entities[idOrganizationLeadTodoAppliedRule],
              organizationLeadTodo: idOrganizationLeadTodos.some(
                (idOrganizationLeadTodo: number) =>
                  idOrganizationLeadTodo === state.entities[idOrganizationLeadTodoAppliedRule]?.organizationLeadTodo
              )
                ? undefined
                : state.entities[idOrganizationLeadTodoAppliedRule]?.organizationLeadTodo
            } as OrganizationLeadTodoAppliedRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoAppliedRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoAppliedRuleActions.addOrganizationLeadTodoRuleSuccess,
    (
      state: OrganizationLeadTodoAppliedRuleState,
      { idOrganizationLeadTodoAppliedRule, idOrganizationLeadTodoRule }
    ) => {
      if (!state.entities[idOrganizationLeadTodoAppliedRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodoAppliedRule]: {
            ...state.entities[idOrganizationLeadTodoAppliedRule],
            organizationLeadTodoRule: idOrganizationLeadTodoRule
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoAppliedRuleActions.deleteManyOrganizationLeadTodoRuleSuccess,
    (
      state: OrganizationLeadTodoAppliedRuleState,
      { idOrganizationLeadTodoRules, idOrganizationLeadTodoAppliedRules }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodoAppliedRules.reduce((entities, idOrganizationLeadTodoAppliedRule) => {
            if (!state.entities[idOrganizationLeadTodoAppliedRule]?.organizationLeadTodoRule) {
              return entities;
            }
            entities[idOrganizationLeadTodoAppliedRule] = {
              ...state.entities[idOrganizationLeadTodoAppliedRule],
              organizationLeadTodoRule: idOrganizationLeadTodoRules.some(
                (idOrganizationLeadTodoRule: number) =>
                  idOrganizationLeadTodoRule ===
                  state.entities[idOrganizationLeadTodoAppliedRule]?.organizationLeadTodoRule
              )
                ? undefined
                : state.entities[idOrganizationLeadTodoAppliedRule]?.organizationLeadTodoRule
            } as OrganizationLeadTodoAppliedRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoAppliedRuleEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationLeadTodoAppliedRuleState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationLeadTodoAppliedRuleState {
  return { ...state, isLoaded, isLoading };
}
