import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { SaleCategoryFamily, SaleCategoryFamilyEntityState } from '@_model/interfaces/sale-category-family.model';
import { SaleCategoryFamilyRelationsIds } from './sale-category-family-generated.effects';

export const getOneSaleCategoryFamily = createAction(
  '[SaleCategoryFamily] Get One SaleCategoryFamily',
  props<{ idSaleCategoryFamily: number; params?: any }>()
);

export const getManyFamilySaleCategories = createAction(
  '[SaleCategoryFamily] Get Many FamilySaleCategories',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[SaleCategoryFamily] Add Many Actives SaleCategoryFamily',
  props<{ idFamilySaleCategories: number[] }>()
);

export const deleteOneActive = createAction(
  '[SaleCategoryFamily] Delete One Active SaleCategoryFamily',
  props<{ idSaleCategoryFamily: number }>()
);

export const clearActive = createAction('[SaleCategoryFamily] Clear Active SaleCategoryFamily');

export const upsertOneSaleCategoryFamily = createAction(
  '[SaleCategoryFamily] Upsert One SaleCategoryFamily',
  props<{
    saleCategoryFamily: Partial<SaleCategoryFamily>;
    ids?: SaleCategoryFamilyRelationsIds;
  }>()
);

export const upsertManyFamilySaleCategories = createAction(
  '[SaleCategoryFamily] Upsert Many FamilySaleCategories',
  props<{
    familySaleCategories: Partial<SaleCategoryFamily>[];
    ids?: SaleCategoryFamilyRelationsIds;
  }>()
);

export const upsertManyFamilySaleCategoriesSuccess = createAction(
  '[SaleCategoryFamily] Create Many FamilySaleCategories Success',
  props<{ familySaleCategories: SaleCategoryFamilyEntityState[] }>()
);

export const deleteOneSaleCategoryFamily = createAction(
  '[SaleCategoryFamily] Delete One SaleCategoryFamily',
  props<{ idSaleCategoryFamily: number }>()
);

export const deleteOneSaleCategoryFamilySuccess = createAction(
  '[SaleCategoryFamily] Delete One SaleCategoryFamily Success',
  props<{ idSaleCategoryFamily: number }>()
);

export const normalizeManyFamilySaleCategoriesAfterUpsert = createAction(
  '[SaleCategoryFamily] Normalize Many FamilySaleCategories After Upsert',
  props<{ familySaleCategories: SaleCategoryFamilyEntityState[] }>()
);

export const familySaleCategoriesFailure = createAction(
  '[SaleCategoryFamily] FamilySaleCategories Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[SaleCategoryFamily] Clear FamilySaleCategories');

export const addManyOrganizationSaleCategorySuccess = createAction(
  '[SaleCategoryFamily] Add Many organization-sale-category',
  props<{ idSaleCategoryFamily: number; idOrganizationSaleCategories: number[] }>()
);

export const deleteManyOrganizationSaleCategorySuccess = createAction(
  '[SaleCategoryFamily] Delete Many OrganizationSaleCategories',
  props<{ idOrganizationSaleCategories: number[]; idFamilySaleCategories: number[] }>()
);

export const addManyBoardStateSuccess = createAction(
  '[SaleCategoryFamily] Add Many board-state',
  props<{ idSaleCategoryFamily: number; idBoardStates: number[] }>()
);

export const deleteManyBoardStateSuccess = createAction(
  '[SaleCategoryFamily] Delete Many BoardStates',
  props<{ idBoardStates: number[]; idFamilySaleCategories: number[] }>()
);

export const addManyCompanyPriceLabelSuccess = createAction(
  '[SaleCategoryFamily] Add Many company-price-label',
  props<{ idSaleCategoryFamily: number; idCompanyPriceLabels: number[] }>()
);

export const deleteManyCompanyPriceLabelSuccess = createAction(
  '[SaleCategoryFamily] Delete Many CompanyPriceLabels',
  props<{ idCompanyPriceLabels: number[]; idFamilySaleCategories: number[] }>()
);

export const addManyOrganizationLeadTodoRuleSuccess = createAction(
  '[SaleCategoryFamily] Add Many organization-lead-todo-rule',
  props<{ idSaleCategoryFamily: number; idOrganizationLeadTodoRules: number[] }>()
);

export const deleteManyOrganizationLeadTodoRuleSuccess = createAction(
  '[SaleCategoryFamily] Delete Many OrganizationLeadTodoRules',
  props<{ idOrganizationLeadTodoRules: number[]; idFamilySaleCategories: number[] }>()
);

export const addManyResidenceSaleCategoryFamilySuccess = createAction(
  '[SaleCategoryFamily] Add Many residence-sale-category-family',
  props<{ idSaleCategoryFamily: number; idResidenceSaleCategoryFamilies: number[] }>()
);

export const deleteManyResidenceSaleCategoryFamilySuccess = createAction(
  '[SaleCategoryFamily] Delete Many ResidenceSaleCategoryFamilies',
  props<{ idResidenceSaleCategoryFamilies: number[]; idFamilySaleCategories: number[] }>()
);

export const addManyResidenceSuccess = createAction(
  '[SaleCategoryFamily] Add Many residence',
  props<{ idSaleCategoryFamily: number; idResidences: number[] }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[SaleCategoryFamily] Delete Many Residences',
  props<{ idResidences: number[]; idFamilySaleCategories: number[] }>()
);

export const addManyOrganizationStratalotTodoRuleSuccess = createAction(
  '[SaleCategoryFamily] Add Many organization-stratalot-todo-rule',
  props<{ idSaleCategoryFamily: number; idOrganizationStratalotTodoRules: number[] }>()
);

export const deleteManyOrganizationStratalotTodoRuleSuccess = createAction(
  '[SaleCategoryFamily] Delete Many OrganizationStratalotTodoRules',
  props<{ idOrganizationStratalotTodoRules: number[]; idFamilySaleCategories: number[] }>()
);

export const addManyOrganizationResidenceTodoRuleSuccess = createAction(
  '[SaleCategoryFamily] Add Many organization-residence-todo-rule',
  props<{ idSaleCategoryFamily: number; idOrganizationResidenceTodoRules: number[] }>()
);

export const deleteManyOrganizationResidenceTodoRuleSuccess = createAction(
  '[SaleCategoryFamily] Delete Many OrganizationResidenceTodoRules',
  props<{ idOrganizationResidenceTodoRules: number[]; idFamilySaleCategories: number[] }>()
);
