import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Lead, LeadEntityState } from '@_model/interfaces/lead.model';
import { ProspectEvent, ProspectEventEntityState } from '@_model/interfaces/prospect-event.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { LeadStratalot, LeadStratalotEntityState } from '@_model/interfaces/lead-stratalot.model';
import { LeadTodo, LeadTodoEntityState } from '@_model/interfaces/lead-todo.model';
import { Prospect, ProspectEntityState } from '@_model/interfaces/prospect.model';
import {
  OrganizationLeadAvancement,
  OrganizationLeadAvancementEntityState
} from '@_model/interfaces/organization-lead-avancement.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, leadFeatureKey, LeadState } from './lead.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const leadRelations: string[] = [
  'prospectEvents',
  'stratalots',
  'leadStratalots',
  'leadTodos',
  'prospects',
  'organizationLeadAvancements'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectLeadState = createFeatureSelector<LeadState>(leadFeatureKey);

export const selectIsLoadedLead = createSelector(selectLeadState, (state: LeadState) => state.isLoaded);

export const selectIsLoadingLead = createSelector(selectLeadState, (state: LeadState) => state.isLoading);

export const selectIsReadyLead = createSelector(selectLeadState, (state: LeadState) => !state.isLoading);

export const selectIsReadyAndLoadedLead = createSelector(
  selectLeadState,
  (state: LeadState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const LeadModel: SelectorModel = {
  name: 'leads',
  getSelector: selectAllLeadsDictionary,
  isReady: selectIsReadyLead
};

export const selectLeadsEntities = createSelector(selectLeadState, selectEntities);

export const selectLeadsArray = createSelector(selectLeadState, selectAll);

export const selectIdLeadsActive = createSelector(selectLeadState, (state: LeadState) => state.actives);

const leadsInObject = (leads: Dictionary<LeadEntityState>) => ({ leads });

const selectLeadsEntitiesDictionary = createSelector(selectLeadsEntities, leadsInObject);

const selectAllLeadsObject = createSelector(selectLeadsEntities, leads => {
  return hydrateAll({ leads });
});

const selectOneLeadDictionary = (idLead: number) =>
  createSelector(selectLeadsEntities, leads => {
    return { leads: { [idLead]: leads[idLead] } };
  });

const selectOneLeadDictionaryWithoutChild = (idLead: number) =>
  createSelector(selectLeadsEntities, leads => {
    return { lead: leads[idLead] };
  });

const selectActiveLeadsEntities = createSelector(
  selectIdLeadsActive,
  selectLeadsEntities,
  (actives: number[], leads: Dictionary<LeadEntityState>) => getLeadsFromActives(actives, leads)
);

function getLeadsFromActives(actives: number[], leads: Dictionary<LeadEntityState>): Dictionary<LeadEntityState> {
  return actives.reduce((acc, idActive) => {
    if (leads[idActive]) {
      acc[idActive] = leads[idActive];
    }
    return acc;
  }, {} as Dictionary<LeadEntityState>);
}

const selectAllLeadsSelectors: Dictionary<Selector> = {};
export function selectAllLeads(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Lead>(
      schema,
      selectAllLeadsSelectors,
      selectLeadsEntitiesDictionary,
      getRelationSelectors,
      leadRelations,
      hydrateAll,
      'lead'
    );
  } else {
    return selectAllLeadsObject;
  }
}

export function selectAllLeadsDictionary(schema: SelectSchema = {}, customKey: string = 'leads'): Selector {
  return createSelector(selectAllLeads(schema), result => {
    const res = { [customKey]: {} as Dictionary<LeadEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.leads.length; i++) {
      res[customKey][result.leads[i].idLead] = result.leads[i];
    }
    return res;
  });
}

export function selectOneLead(schema: SelectSchema = {}, idLead: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneLeadDictionary(idLead)];
    selectors.push(...getRelationSelectors(schema, leadRelations, 'lead'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneLeadDictionaryWithoutChild(idLead);
  }
}

export function selectActiveLeads(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [createSelector(selectActiveLeadsEntities, leads => ({ leads }))];
  selectors.push(...getRelationSelectors(schema, leadRelations, 'lead'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  leads: Dictionary<LeadEntityState>;
  prospects?: Dictionary<ProspectEntityState>;
  organizationLeadAvancements?: Dictionary<OrganizationLeadAvancementEntityState>;
  prospectEvents?: Dictionary<ProspectEventEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
  leadStratalots?: Dictionary<LeadStratalotEntityState>;
  leadTodos?: Dictionary<LeadTodoEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { leads: (Lead | null)[] } {
  const { leads, prospects, organizationLeadAvancements, prospectEvents, stratalots, leadStratalots, leadTodos } =
    args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    leads: Object.keys(leads).map(idLead =>
      hydrate(
        leads[idLead] as LeadEntityState,
        prospects,
        organizationLeadAvancements,
        prospectEvents,
        stratalots,
        leadStratalots,
        leadTodos
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { lead: LeadEntityState | null } {
  const { leads, prospects, organizationLeadAvancements, prospectEvents, stratalots, leadStratalots, leadTodos } =
    args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const lead = Object.values(leads)[0];
  return {
    lead: hydrate(
      lead as LeadEntityState,
      prospects,
      organizationLeadAvancements,
      prospectEvents,
      stratalots,
      leadStratalots,
      leadTodos
    )
  };
}

function hydrate(
  lead: LeadEntityState,
  prospectEntities?: Dictionary<ProspectEntityState>,
  organizationLeadAvancementEntities?: Dictionary<OrganizationLeadAvancementEntityState>,
  prospectEventEntities?: Dictionary<ProspectEventEntityState>,
  stratalotEntities?: Dictionary<StratalotEntityState>,
  leadStratalotEntities?: Dictionary<LeadStratalotEntityState>,
  leadTodoEntities?: Dictionary<LeadTodoEntityState>
): Lead | null {
  if (!lead) {
    return null;
  }

  const leadHydrated: LeadEntityState = { ...lead };
  if (prospectEntities) {
    leadHydrated.prospect = prospectEntities[lead.prospect as number] as Prospect;
  } else {
    delete leadHydrated.prospect;
  }
  if (organizationLeadAvancementEntities) {
    leadHydrated.organizationLeadAvancement = organizationLeadAvancementEntities[
      lead.organizationLeadAvancement as number
    ] as OrganizationLeadAvancement;
  } else {
    delete leadHydrated.organizationLeadAvancement;
  }

  if (prospectEventEntities) {
    leadHydrated.prospectEvents = ((leadHydrated.prospectEvents as number[]) || []).map(
      id => prospectEventEntities[id]
    ) as ProspectEvent[];
  } else {
    delete leadHydrated.prospectEvents;
  }

  if (stratalotEntities) {
    leadHydrated.stratalots = ((leadHydrated.stratalots as number[]) || []).map(
      id => stratalotEntities[id]
    ) as Stratalot[];
  } else {
    delete leadHydrated.stratalots;
  }

  if (leadStratalotEntities) {
    leadHydrated.leadStratalots = ((leadHydrated.leadStratalots as number[]) || []).map(
      id => leadStratalotEntities[id]
    ) as LeadStratalot[];
  } else {
    delete leadHydrated.leadStratalots;
  }

  if (leadTodoEntities) {
    leadHydrated.leadTodos = ((leadHydrated.leadTodos as number[]) || []).map(id => leadTodoEntities[id]) as LeadTodo[];
  } else {
    delete leadHydrated.leadTodos;
  }

  return leadHydrated as Lead;
}
