import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { Occupant, OccupantEntityState } from '@_model/interfaces/occupant.model';
import { OccupantApiService } from '@_services/api/occupant-api.service';
import * as occupantActions from '@_store/occupant/occupant.actions';
import { getActionsToNormalizeOccupant } from '@_config/store/normalization.generated';
import { selectOccupantState } from './occupant-generated.selectors';
import * as companyActions from '@_store/company/company.actions';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import { Stratalot } from '@_model/interfaces/stratalot.model';
import * as prospectActions from '@_store/prospect/prospect.actions';
import { Prospect } from '@_model/interfaces/prospect.model';

export interface OccupantRelationsIds {
  stratalots?: number | number[];
  prospects?: number | number[];
  company?: number;
}

export function getDefaultAddOccupantActions(occupant: OccupantEntityState, ids?: OccupantRelationsIds): Action[] {
  const actions: Action[] = [occupantActions.normalizeManyOccupantsAfterUpsert({ occupants: [occupant] })];

  if (ids?.company) {
    actions.push(
      companyActions.addManyOccupantSuccess({
        idCompany: ids.company,
        idOccupants: [occupant.idOccupant]
      })
    );
    actions.push(
      occupantActions.addCompanySuccess({
        idOccupant: occupant.idOccupant,
        idCompany: ids.company
      })
    );
  }

  if (ids?.stratalots) {
    if (!Array.isArray(ids.stratalots)) {
      actions.push(
        stratalotActions.upsertOneStratalot({
          stratalot: {
            idOccupant: occupant.idOccupant,
            idStratalot: ids.stratalots as number
          } as Stratalot
        })
      );
      actions.push(
        occupantActions.addManyStratalotSuccess({
          idOccupant: occupant.idOccupant,
          idStratalots: [ids.stratalots as number]
        })
      );
    } else {
      actions.push(
        stratalotActions.upsertManyStratalots({
          stratalots: (ids.stratalots as number[]).map((idStratalot: number) => ({
            idOccupant: occupant.idOccupant,
            idStratalot
          })) as Stratalot[]
        })
      );
      actions.push(
        occupantActions.addManyStratalotSuccess({
          idOccupant: occupant.idOccupant,
          idStratalots: ids.stratalots as number[]
        })
      );
    }
  }

  if (ids?.prospects) {
    if (!Array.isArray(ids.prospects)) {
      actions.push(
        prospectActions.upsertOneProspect({
          prospect: {
            idOccupant: occupant.idOccupant,
            idProspect: ids.prospects as number
          } as Prospect
        })
      );
      actions.push(
        occupantActions.addManyProspectSuccess({
          idOccupant: occupant.idOccupant,
          idProspects: [ids.prospects as number]
        })
      );
    } else {
      actions.push(
        prospectActions.upsertManyProspects({
          prospects: (ids.prospects as number[]).map((idProspect: number) => ({
            idOccupant: occupant.idOccupant,
            idProspect
          })) as Prospect[]
        })
      );
      actions.push(
        occupantActions.addManyProspectSuccess({
          idOccupant: occupant.idOccupant,
          idProspects: ids.prospects as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOccupantActions(occupant: OccupantEntityState): Action[] {
  const actions: Action[] = [occupantActions.deleteOneOccupantSuccess({ idOccupant: occupant.idOccupant })];

  if (occupant.company) {
    actions.push(
      companyActions.deleteManyOccupantSuccess({
        idOccupants: [occupant.idOccupant],
        idCompanies: [occupant.company as number]
      })
    );
  }

  if (occupant.stratalots) {
    actions.push(
      stratalotActions.deleteManyOccupantSuccess({
        idOccupants: [occupant.idOccupant],
        idStratalots: occupant.stratalots as number[]
      })
    );
  }

  if (occupant.prospects) {
    actions.push(
      prospectActions.deleteManyOccupantSuccess({
        idOccupants: [occupant.idOccupant],
        idProspects: occupant.prospects as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOccupantEffects {
  constructor(
    protected actions$: Actions,
    protected occupantApiService: OccupantApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOccupants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(occupantActions.getManyOccupants),
      switchMap(({ params }) =>
        this.occupantApiService.getOccupants(params).pipe(
          map((occupants: Occupant[]) => {
            return occupantActions.normalizeManyOccupantsAfterUpsert({ occupants });
          }),
          catchError(error => of(occupantActions.occupantsFailure({ error })))
        )
      )
    );
  });

  getOneOccupant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(occupantActions.getOneOccupant),
      switchMap(idOccupant =>
        this.occupantApiService.getOccupant(idOccupant).pipe(
          map((occupant: Occupant) => {
            return occupantActions.normalizeManyOccupantsAfterUpsert({ occupants: [occupant] });
          }),
          catchError(error => of(occupantActions.occupantsFailure({ error })))
        )
      )
    );
  });

  upsertOneOccupant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(occupantActions.upsertOneOccupant),
      concatMap(({ occupant, ids }: { occupant: Partial<Occupant>; ids?: OccupantRelationsIds }) => {
        if (occupant.idOccupant) {
          return this.occupantApiService.updateOccupant(occupant).pipe(
            map((occupantReturned: Occupant) => {
              return occupantActions.normalizeManyOccupantsAfterUpsert({ occupants: [occupantReturned] });
            }),
            catchError(error => of(occupantActions.occupantsFailure({ error })))
          );
        } else {
          return this.occupantApiService.addOccupant(occupant).pipe(
            mergeMap((occupantReturned: Occupant) => getDefaultAddOccupantActions(occupantReturned, ids)),
            catchError(error => of(occupantActions.occupantsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneOccupant$ = createEffect(() => {
    const selectOccupantState$ = this.store$.select(selectOccupantState);
    return this.actions$.pipe(
      ofType(occupantActions.deleteOneOccupant),
      withLatestFrom(selectOccupantState$),
      concatMap(([{ idOccupant }, state]) =>
        this.occupantApiService.deleteOccupant(idOccupant).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOccupantActions(state.entities[idOccupant] as OccupantEntityState),
              occupantActions.deleteOneOccupant.type
            )
          ]),
          catchError(error => of(occupantActions.occupantsFailure({ error })))
        )
      )
    );
  });

  normalizeManyOccupantsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(occupantActions.normalizeManyOccupantsAfterUpsert),
      concatMap(({ occupants }) => {
        const actions: Action[] = getActionsToNormalizeOccupant(occupants, StoreActionType.upsert);
        return [getMultiAction(actions, '[Occupant] Normalization After Upsert Success')];
      })
    );
  });
}
