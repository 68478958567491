import { CompanyStudyCriteriaState, initialState } from './company-study-criteria.state';
import { Action, createReducer } from '@ngrx/store';
import { companyStudyCriteriaReducersGeneratedFunctions } from './company-study-criteria-generated.reducer';

const companyStudyCriteriaReducersFunctions = [...companyStudyCriteriaReducersGeneratedFunctions];

const companyStudyCriteriaReducer = createReducer(initialState, ...companyStudyCriteriaReducersFunctions);

export function reducer(state: CompanyStudyCriteriaState | undefined, action: Action) {
  return companyStudyCriteriaReducer(state, action);
}
