import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SaleCategoryFamily } from '@_model/interfaces/sale-category-family.model';
import * as AppState from '@_store/index.reducers';
import { SaleCategoryFamilyRelationsIds } from '@_store/sale-category-family/sale-category-family-generated.effects';
import * as SaleCategoryFamilyAction from '@_store/sale-category-family/sale-category-family.actions';
import {
  selectActiveFamilySaleCategories,
  selectAllFamilySaleCategories,
  selectIdFamilySaleCategoriesActive,
  selectIsLoadedSaleCategoryFamily,
  selectIsLoadingSaleCategoryFamily,
  selectIsReadyAndLoadedSaleCategoryFamily,
  selectOneSaleCategoryFamily
} from '@_store/sale-category-family/sale-category-family.selectors';
import { catchApiActions } from '@_utils/http.util';
import { getIsReadySelectors, Selector, SelectSchema } from '@_utils/selector.util';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedSaleCategoryFamilyService {
  constructor(protected store$: Store<AppState.AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadedSaleCategoryFamily));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadingSaleCategoryFamily));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [selectIsReadyAndLoadedSaleCategoryFamily as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllFamilySaleCategories(schema: SelectSchema = {}): Observable<SaleCategoryFamily[]> {
    return this.store$.pipe(select(selectAllFamilySaleCategories(schema))).pipe(
      switchMap(({ familySaleCategories }: { familySaleCategories: SaleCategoryFamily[] }) => {
        return this.getReady(schema).pipe(mapTo(familySaleCategories));
      })
    );
  }

  public selectOneSaleCategoryFamily(
    idSaleCategoryFamily: number,
    schema: SelectSchema = {}
  ): Observable<SaleCategoryFamily> {
    return this.store$.pipe(select(selectOneSaleCategoryFamily(schema, idSaleCategoryFamily))).pipe(
      switchMap(({ saleCategoryFamily }: { saleCategoryFamily: SaleCategoryFamily }) => {
        return this.getReady(schema).pipe(mapTo(saleCategoryFamily));
      })
    );
  }

  public selectAllActiveFamilySaleCategories(schema: SelectSchema = {}): Observable<SaleCategoryFamily[]> {
    return this.store$.pipe(select(selectActiveFamilySaleCategories(schema))).pipe(
      switchMap(({ familySaleCategories }: { familySaleCategories: SaleCategoryFamily[] }) => {
        return this.getReady(schema).pipe(mapTo(familySaleCategories));
      })
    );
  }

  public selectIdFamilySaleCategoriesActive(): Observable<number[]> {
    return this.store$.pipe(select(selectIdFamilySaleCategoriesActive)).pipe(
      switchMap((idFamilySaleCategories: number[]) => {
        return this.getReady().pipe(mapTo(idFamilySaleCategories));
      })
    );
  }

  public getOneSaleCategoryFamily(
    idSaleCategoryFamily: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<SaleCategoryFamily> {
    this.store$.dispatch(SaleCategoryFamilyAction.getOneSaleCategoryFamily({ idSaleCategoryFamily, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SaleCategoryFamilyAction.normalizeManyFamilySaleCategoriesAfterUpsert,
        SaleCategoryFamilyAction.familySaleCategoriesFailure,
        true
      );
    }
  }

  public getManyFamilySaleCategories(params: any = {}, getResult?: boolean): void | Observable<SaleCategoryFamily[]> {
    this.store$.dispatch(SaleCategoryFamilyAction.getManyFamilySaleCategories({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SaleCategoryFamilyAction.normalizeManyFamilySaleCategoriesAfterUpsert,
        SaleCategoryFamilyAction.familySaleCategoriesFailure
      );
    }
  }

  public upsertOneSaleCategoryFamily(
    saleCategoryFamily: Partial<SaleCategoryFamily>,
    ids: SaleCategoryFamilyRelationsIds = {},
    getResult?: boolean
  ): void | Observable<SaleCategoryFamily> {
    this.store$.dispatch(SaleCategoryFamilyAction.upsertOneSaleCategoryFamily({ saleCategoryFamily, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SaleCategoryFamilyAction.normalizeManyFamilySaleCategoriesAfterUpsert,
        SaleCategoryFamilyAction.familySaleCategoriesFailure,
        true
      );
    }
  }

  public deleteOneSaleCategoryFamily(idSaleCategoryFamily: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(SaleCategoryFamilyAction.deleteOneSaleCategoryFamily({ idSaleCategoryFamily }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        SaleCategoryFamilyAction.deleteOneSaleCategoryFamilySuccess,
        SaleCategoryFamilyAction.familySaleCategoriesFailure
      );
    }
  }

  public setActiveFamilySaleCategories(idFamilySaleCategories: number[]): void {
    this.store$.dispatch(SaleCategoryFamilyAction.clearActive());
    this.store$.dispatch(SaleCategoryFamilyAction.addManyActives({ idFamilySaleCategories }));
  }
}
