import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppResolver } from './app.resolver';
import { IsUserLogged } from './core/guards/is-user-logged.guard';
import { IsUserNotLogged } from './core/guards/is-user-not-logged.guard';

const routerOptions: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  relativeLinkResolution: 'corrected',
  paramsInheritanceStrategy: 'always'
};

const routes: Routes = [
  {
    path: 'app',
    canActivate: [IsUserLogged],
    resolve: { AppResolver },
    loadChildren: () => import('./modules/layout/layout.module').then(m => m.LayoutModule),
    data: {
      breadcrumb: 'Accueil'
    }
  },
  {
    path: 'auth',
    canActivate: [IsUserNotLogged],
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  { path: '**', redirectTo: 'app' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
