import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationStratalotTodoAppliedRule,
  OrganizationStratalotTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-applied-rule.model';
import {
  OrganizationStratalotTodo,
  OrganizationStratalotTodoEntityState
} from '@_model/interfaces/organization-stratalot-todo.model';
import {
  OrganizationStratalotTodoRule,
  OrganizationStratalotTodoRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-rule.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationStratalotTodoAppliedRuleFeatureKey,
  OrganizationStratalotTodoAppliedRuleState
} from './organization-stratalot-todo-applied-rule.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationStratalotTodoAppliedRuleRelations: string[] = [
  'organizationStratalotTodos',
  'organizationStratalotTodoRules'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationStratalotTodoAppliedRuleState =
  createFeatureSelector<OrganizationStratalotTodoAppliedRuleState>(organizationStratalotTodoAppliedRuleFeatureKey);

export const selectIsLoadedOrganizationStratalotTodoAppliedRule = createSelector(
  selectOrganizationStratalotTodoAppliedRuleState,
  (state: OrganizationStratalotTodoAppliedRuleState) => state.isLoaded
);

export const selectIsLoadingOrganizationStratalotTodoAppliedRule = createSelector(
  selectOrganizationStratalotTodoAppliedRuleState,
  (state: OrganizationStratalotTodoAppliedRuleState) => state.isLoading
);

export const selectIsReadyOrganizationStratalotTodoAppliedRule = createSelector(
  selectOrganizationStratalotTodoAppliedRuleState,
  (state: OrganizationStratalotTodoAppliedRuleState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationStratalotTodoAppliedRule = createSelector(
  selectOrganizationStratalotTodoAppliedRuleState,
  (state: OrganizationStratalotTodoAppliedRuleState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationStratalotTodoAppliedRuleModel: SelectorModel = {
  name: 'organizationStratalotTodoAppliedRules',
  getSelector: selectAllOrganizationStratalotTodoAppliedRulesDictionary,
  isReady: selectIsReadyOrganizationStratalotTodoAppliedRule
};

export const selectOrganizationStratalotTodoAppliedRulesEntities = createSelector(
  selectOrganizationStratalotTodoAppliedRuleState,
  selectEntities
);

export const selectOrganizationStratalotTodoAppliedRulesArray = createSelector(
  selectOrganizationStratalotTodoAppliedRuleState,
  selectAll
);

export const selectIdOrganizationStratalotTodoAppliedRulesActive = createSelector(
  selectOrganizationStratalotTodoAppliedRuleState,
  (state: OrganizationStratalotTodoAppliedRuleState) => state.actives
);

const organizationStratalotTodoAppliedRulesInObject = (
  organizationStratalotTodoAppliedRules: Dictionary<OrganizationStratalotTodoAppliedRuleEntityState>
) => ({ organizationStratalotTodoAppliedRules });

const selectOrganizationStratalotTodoAppliedRulesEntitiesDictionary = createSelector(
  selectOrganizationStratalotTodoAppliedRulesEntities,
  organizationStratalotTodoAppliedRulesInObject
);

const selectAllOrganizationStratalotTodoAppliedRulesObject = createSelector(
  selectOrganizationStratalotTodoAppliedRulesEntities,
  organizationStratalotTodoAppliedRules => {
    return hydrateAll({ organizationStratalotTodoAppliedRules });
  }
);

const selectOneOrganizationStratalotTodoAppliedRuleDictionary = (idOrganizationStratalotTodoAppliedRule: number) =>
  createSelector(selectOrganizationStratalotTodoAppliedRulesEntities, organizationStratalotTodoAppliedRules => {
    return {
      organizationStratalotTodoAppliedRules: {
        [idOrganizationStratalotTodoAppliedRule]:
          organizationStratalotTodoAppliedRules[idOrganizationStratalotTodoAppliedRule]
      }
    };
  });

const selectOneOrganizationStratalotTodoAppliedRuleDictionaryWithoutChild = (
  idOrganizationStratalotTodoAppliedRule: number
) =>
  createSelector(selectOrganizationStratalotTodoAppliedRulesEntities, organizationStratalotTodoAppliedRules => {
    return {
      organizationStratalotTodoAppliedRule:
        organizationStratalotTodoAppliedRules[idOrganizationStratalotTodoAppliedRule]
    };
  });

const selectActiveOrganizationStratalotTodoAppliedRulesEntities = createSelector(
  selectIdOrganizationStratalotTodoAppliedRulesActive,
  selectOrganizationStratalotTodoAppliedRulesEntities,
  (
    actives: number[],
    organizationStratalotTodoAppliedRules: Dictionary<OrganizationStratalotTodoAppliedRuleEntityState>
  ) => getOrganizationStratalotTodoAppliedRulesFromActives(actives, organizationStratalotTodoAppliedRules)
);

function getOrganizationStratalotTodoAppliedRulesFromActives(
  actives: number[],
  organizationStratalotTodoAppliedRules: Dictionary<OrganizationStratalotTodoAppliedRuleEntityState>
): Dictionary<OrganizationStratalotTodoAppliedRuleEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationStratalotTodoAppliedRules[idActive]) {
      acc[idActive] = organizationStratalotTodoAppliedRules[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationStratalotTodoAppliedRuleEntityState>);
}

const selectAllOrganizationStratalotTodoAppliedRulesSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationStratalotTodoAppliedRules(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationStratalotTodoAppliedRule>(
      schema,
      selectAllOrganizationStratalotTodoAppliedRulesSelectors,
      selectOrganizationStratalotTodoAppliedRulesEntitiesDictionary,
      getRelationSelectors,
      organizationStratalotTodoAppliedRuleRelations,
      hydrateAll,
      'organizationStratalotTodoAppliedRule'
    );
  } else {
    return selectAllOrganizationStratalotTodoAppliedRulesObject;
  }
}

export function selectAllOrganizationStratalotTodoAppliedRulesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationStratalotTodoAppliedRules'
): Selector {
  return createSelector(selectAllOrganizationStratalotTodoAppliedRules(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationStratalotTodoAppliedRuleEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationStratalotTodoAppliedRules.length; i++) {
      res[customKey][result.organizationStratalotTodoAppliedRules[i].idOrganizationStratalotTodoAppliedRule] =
        result.organizationStratalotTodoAppliedRules[i];
    }
    return res;
  });
}

export function selectOneOrganizationStratalotTodoAppliedRule(
  schema: SelectSchema = {},
  idOrganizationStratalotTodoAppliedRule: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [
      selectOneOrganizationStratalotTodoAppliedRuleDictionary(idOrganizationStratalotTodoAppliedRule)
    ];
    selectors.push(
      ...getRelationSelectors(
        schema,
        organizationStratalotTodoAppliedRuleRelations,
        'organizationStratalotTodoAppliedRule'
      )
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationStratalotTodoAppliedRuleDictionaryWithoutChild(idOrganizationStratalotTodoAppliedRule);
  }
}

export function selectActiveOrganizationStratalotTodoAppliedRules(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(
      selectActiveOrganizationStratalotTodoAppliedRulesEntities,
      organizationStratalotTodoAppliedRules => ({ organizationStratalotTodoAppliedRules })
    )
  ];
  selectors.push(
    ...getRelationSelectors(
      schema,
      organizationStratalotTodoAppliedRuleRelations,
      'organizationStratalotTodoAppliedRule'
    )
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationStratalotTodoAppliedRules: Dictionary<OrganizationStratalotTodoAppliedRuleEntityState>;
  organizationStratalotTodos?: Dictionary<OrganizationStratalotTodoEntityState>;
  organizationStratalotTodoRules?: Dictionary<OrganizationStratalotTodoRuleEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationStratalotTodoAppliedRules: (OrganizationStratalotTodoAppliedRule | null)[];
} {
  const { organizationStratalotTodoAppliedRules, organizationStratalotTodos, organizationStratalotTodoRules } =
    args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationStratalotTodoAppliedRules: Object.keys(organizationStratalotTodoAppliedRules).map(
      idOrganizationStratalotTodoAppliedRule =>
        hydrate(
          organizationStratalotTodoAppliedRules[
            idOrganizationStratalotTodoAppliedRule
          ] as OrganizationStratalotTodoAppliedRuleEntityState,
          organizationStratalotTodos,
          organizationStratalotTodoRules
        )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationStratalotTodoAppliedRule: OrganizationStratalotTodoAppliedRuleEntityState | null;
} {
  const { organizationStratalotTodoAppliedRules, organizationStratalotTodos, organizationStratalotTodoRules } =
    args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationStratalotTodoAppliedRule = Object.values(organizationStratalotTodoAppliedRules)[0];
  return {
    organizationStratalotTodoAppliedRule: hydrate(
      organizationStratalotTodoAppliedRule as OrganizationStratalotTodoAppliedRuleEntityState,
      organizationStratalotTodos,
      organizationStratalotTodoRules
    )
  };
}

function hydrate(
  organizationStratalotTodoAppliedRule: OrganizationStratalotTodoAppliedRuleEntityState,
  organizationStratalotTodoEntities?: Dictionary<OrganizationStratalotTodoEntityState>,
  organizationStratalotTodoRuleEntities?: Dictionary<OrganizationStratalotTodoRuleEntityState>
): OrganizationStratalotTodoAppliedRule | null {
  if (!organizationStratalotTodoAppliedRule) {
    return null;
  }

  const organizationStratalotTodoAppliedRuleHydrated: OrganizationStratalotTodoAppliedRuleEntityState = {
    ...organizationStratalotTodoAppliedRule
  };
  if (organizationStratalotTodoEntities) {
    organizationStratalotTodoAppliedRuleHydrated.organizationStratalotTodo = organizationStratalotTodoEntities[
      organizationStratalotTodoAppliedRule.organizationStratalotTodo as number
    ] as OrganizationStratalotTodo;
  } else {
    delete organizationStratalotTodoAppliedRuleHydrated.organizationStratalotTodo;
  }
  if (organizationStratalotTodoRuleEntities) {
    organizationStratalotTodoAppliedRuleHydrated.organizationStratalotTodoRule = organizationStratalotTodoRuleEntities[
      organizationStratalotTodoAppliedRule.organizationStratalotTodoRule as number
    ] as OrganizationStratalotTodoRule;
  } else {
    delete organizationStratalotTodoAppliedRuleHydrated.organizationStratalotTodoRule;
  }

  return organizationStratalotTodoAppliedRuleHydrated as OrganizationStratalotTodoAppliedRule;
}
