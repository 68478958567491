import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyStudyReasonEntityState } from '@_model/interfaces/company-study-reason.model';

export interface CompanyStudyReasonState extends EntityState<CompanyStudyReasonEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyStudyReasonEntityState> = createEntityAdapter<CompanyStudyReasonEntityState>(
  {
    selectId: o => o.idCompanyStudyReason
  }
);
export const initialState: CompanyStudyReasonState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyStudyReasonFeatureKey = 'companyStudyReason';
