import { CompanyPriceLabelState, initialState } from './company-price-label.state';
import { Action, createReducer } from '@ngrx/store';
import { companyPriceLabelReducersGeneratedFunctions } from './company-price-label-generated.reducer';

const companyPriceLabelReducersFunctions = [...companyPriceLabelReducersGeneratedFunctions];

const companyPriceLabelReducer = createReducer(initialState, ...companyPriceLabelReducersFunctions);

export function reducer(state: CompanyPriceLabelState | undefined, action: Action) {
  return companyPriceLabelReducer(state, action);
}
