import { Injectable } from '@angular/core';
import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedLeadTodoApiService } from './lead-todo-api-generated.service';
import { LeadTodo } from '@_model/interfaces/lead-todo.model';
import { Observable } from 'rxjs';
import { Lead } from '@_shared/models/interfaces/lead.model';

@Injectable({
  providedIn: 'root'
})
export class LeadTodoApiService extends GeneratedLeadTodoApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public updateLeadTodoWithSideEffect(leadTodo: Partial<LeadTodo>): Observable<LeadTodo | Lead[]> {
    const newLeadTodo: Partial<LeadTodo> = { ...leadTodo };
    delete newLeadTodo.idLead;
    delete newLeadTodo.idOrganizationLeadTodo;
    newLeadTodo['verbose'] = true;
    return this.repo.updateWithSideEffect<LeadTodo, Lead[]>('lead-todo', newLeadTodo);
  }
}
