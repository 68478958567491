import { Action, ActionReducer, createAction, MetaReducer, props } from '@ngrx/store';
import { GeneratedAppState, generatedAppState } from '@_config/store/reducers.generated';
import * as FilterReducer from './filter/filter.reducer';
import { FilterState } from './filter/filter.state';

interface CustomAppState {
  filter: FilterState;
}

export type AppState = GeneratedAppState & CustomAppState;

export const appState = {
  filter: FilterReducer.reducer,
  ...generatedAppState
};

// Lib not supported with last update of ngrx
// export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
//   return localStorageSync({
//     keys: [
//       {
//         [stratalotFeatureKey]: ['activeOccupedStratalotFilter', 'actives']
//       },
//       {
//         [prospectFeatureKey]: ['actives']
//       }
//     ],
//     rehydrate: true
//   })(reducer);
// }

export const clearState = createAction('[Global] Clear state', props<{ states: (keyof GeneratedAppState)[] }>());

export function globalReducer(reducer: ActionReducer<AppState, BatchActionType>) {
  return function (state: AppState, action: BatchActionType & { [k: string]: any }): AppState {
    if (action.type === clearState.type) {
      return reducer(resetState(action, state), action);
    }
    return reducer(state, action);
  };
}

interface BatchActionType extends Action {
  isBatchAction: boolean;
  payload: Array<any>;
}

function enableBatchReducer<S>(reduce: ActionReducer<S, BatchActionType>): ActionReducer<S, BatchActionType> {
  return function batchReducer(state: S | undefined, action: BatchActionType): S {
    if (action['isBatchAction']) {
      const batchActions = action['payload'];
      return batchActions.reduce(batchReducer, state);
    } else {
      return reduce(state, action);
    }
  };
}

export const metaReducers: Array<MetaReducer<any, BatchActionType>> = [enableBatchReducer, globalReducer];

/**
 * Si states est un tableau vide alors on veut réinitialiser tout le store
 */
function resetState(action: Action & { [k: string]: any }, state: AppState): AppState {
  return action.states.length
    ? (action.states as (keyof GeneratedAppState)[]).reduce(
        (acc, curr) => {
          return { ...acc, [curr]: undefined };
        },
        { ...state }
      )
    : undefined;
}
