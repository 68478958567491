import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationBuyingWishEntityState } from '@_model/interfaces/organization-buying-wish.model';

export interface OrganizationBuyingWishState extends EntityState<OrganizationBuyingWishEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationBuyingWishEntityState> =
  createEntityAdapter<OrganizationBuyingWishEntityState>({
    selectId: o => o.idOrganizationBuyingWish
  });
export const initialState: OrganizationBuyingWishState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationBuyingWishFeatureKey = 'organizationBuyingWish';
