import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { Diagnostic, DiagnosticEntityState } from '@_model/interfaces/diagnostic.model';
import { DiagnosticApiService } from '@_services/api/diagnostic-api.service';
import * as diagnosticActions from '@_store/diagnostic/diagnostic.actions';
import { getActionsToNormalizeDiagnostic } from '@_config/store/normalization.generated';
import { selectDiagnosticState } from './diagnostic-generated.selectors';
import * as organizationStratalotTodoActions from '@_store/organization-stratalot-todo/organization-stratalot-todo.actions';
import { OrganizationStratalotTodo } from '@_model/interfaces/organization-stratalot-todo.model';
import * as organizationResidenceTodoActions from '@_store/organization-residence-todo/organization-residence-todo.actions';
import { OrganizationResidenceTodo } from '@_model/interfaces/organization-residence-todo.model';

export interface DiagnosticRelationsIds {
  organizationStratalotTodos?: number | number[];
  organizationResidenceTodos?: number | number[];
}

export function getDefaultAddDiagnosticActions(
  diagnostic: DiagnosticEntityState,
  ids?: DiagnosticRelationsIds
): Action[] {
  const actions: Action[] = [diagnosticActions.normalizeManyDiagnosticsAfterUpsert({ diagnostics: [diagnostic] })];

  if (ids?.organizationStratalotTodos) {
    if (!Array.isArray(ids.organizationStratalotTodos)) {
      actions.push(
        organizationStratalotTodoActions.upsertOneOrganizationStratalotTodo({
          organizationStratalotTodo: {
            idDiagnostic: diagnostic.idDiagnostic,
            idOrganizationStratalotTodo: ids.organizationStratalotTodos as number
          } as OrganizationStratalotTodo
        })
      );
      actions.push(
        diagnosticActions.addManyOrganizationStratalotTodoSuccess({
          idDiagnostic: diagnostic.idDiagnostic,
          idOrganizationStratalotTodos: [ids.organizationStratalotTodos as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotTodoActions.upsertManyOrganizationStratalotTodos({
          organizationStratalotTodos: (ids.organizationStratalotTodos as number[]).map(
            (idOrganizationStratalotTodo: number) => ({
              idDiagnostic: diagnostic.idDiagnostic,
              idOrganizationStratalotTodo
            })
          ) as OrganizationStratalotTodo[]
        })
      );
      actions.push(
        diagnosticActions.addManyOrganizationStratalotTodoSuccess({
          idDiagnostic: diagnostic.idDiagnostic,
          idOrganizationStratalotTodos: ids.organizationStratalotTodos as number[]
        })
      );
    }
  }

  if (ids?.organizationResidenceTodos) {
    if (!Array.isArray(ids.organizationResidenceTodos)) {
      actions.push(
        organizationResidenceTodoActions.upsertOneOrganizationResidenceTodo({
          organizationResidenceTodo: {
            idDiagnostic: diagnostic.idDiagnostic,
            idOrganizationResidenceTodo: ids.organizationResidenceTodos as number
          } as OrganizationResidenceTodo
        })
      );
      actions.push(
        diagnosticActions.addManyOrganizationResidenceTodoSuccess({
          idDiagnostic: diagnostic.idDiagnostic,
          idOrganizationResidenceTodos: [ids.organizationResidenceTodos as number]
        })
      );
    } else {
      actions.push(
        organizationResidenceTodoActions.upsertManyOrganizationResidenceTodos({
          organizationResidenceTodos: (ids.organizationResidenceTodos as number[]).map(
            (idOrganizationResidenceTodo: number) => ({
              idDiagnostic: diagnostic.idDiagnostic,
              idOrganizationResidenceTodo
            })
          ) as OrganizationResidenceTodo[]
        })
      );
      actions.push(
        diagnosticActions.addManyOrganizationResidenceTodoSuccess({
          idDiagnostic: diagnostic.idDiagnostic,
          idOrganizationResidenceTodos: ids.organizationResidenceTodos as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteDiagnosticActions(diagnostic: DiagnosticEntityState): Action[] {
  const actions: Action[] = [diagnosticActions.deleteOneDiagnosticSuccess({ idDiagnostic: diagnostic.idDiagnostic })];

  if (diagnostic.organizationStratalotTodos) {
    actions.push(
      organizationStratalotTodoActions.deleteManyDiagnosticSuccess({
        idDiagnostics: [diagnostic.idDiagnostic],
        idOrganizationStratalotTodos: diagnostic.organizationStratalotTodos as number[]
      })
    );
  }

  if (diagnostic.organizationResidenceTodos) {
    actions.push(
      organizationResidenceTodoActions.deleteManyDiagnosticSuccess({
        idDiagnostics: [diagnostic.idDiagnostic],
        idOrganizationResidenceTodos: diagnostic.organizationResidenceTodos as number[]
      })
    );
  }

  return actions;
}

export class GeneratedDiagnosticEffects {
  constructor(
    protected actions$: Actions,
    protected diagnosticApiService: DiagnosticApiService,
    protected store$: Store<AppState>
  ) {}

  getManyDiagnostics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(diagnosticActions.getManyDiagnostics),
      switchMap(({ params }) =>
        this.diagnosticApiService.getDiagnostics(params).pipe(
          map((diagnostics: Diagnostic[]) => {
            return diagnosticActions.normalizeManyDiagnosticsAfterUpsert({ diagnostics });
          }),
          catchError(error => of(diagnosticActions.diagnosticsFailure({ error })))
        )
      )
    );
  });

  getOneDiagnostic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(diagnosticActions.getOneDiagnostic),
      switchMap(idDiagnostic =>
        this.diagnosticApiService.getDiagnostic(idDiagnostic).pipe(
          map((diagnostic: Diagnostic) => {
            return diagnosticActions.normalizeManyDiagnosticsAfterUpsert({ diagnostics: [diagnostic] });
          }),
          catchError(error => of(diagnosticActions.diagnosticsFailure({ error })))
        )
      )
    );
  });

  upsertOneDiagnostic$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(diagnosticActions.upsertOneDiagnostic),
      concatMap(({ diagnostic, ids }: { diagnostic: Partial<Diagnostic>; ids?: DiagnosticRelationsIds }) => {
        if (diagnostic.idDiagnostic) {
          return this.diagnosticApiService.updateDiagnostic(diagnostic).pipe(
            map((diagnosticReturned: Diagnostic) => {
              return diagnosticActions.normalizeManyDiagnosticsAfterUpsert({ diagnostics: [diagnosticReturned] });
            }),
            catchError(error => of(diagnosticActions.diagnosticsFailure({ error })))
          );
        } else {
          return this.diagnosticApiService.addDiagnostic(diagnostic).pipe(
            mergeMap((diagnosticReturned: Diagnostic) => getDefaultAddDiagnosticActions(diagnosticReturned, ids)),
            catchError(error => of(diagnosticActions.diagnosticsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneDiagnostic$ = createEffect(() => {
    const selectDiagnosticState$ = this.store$.select(selectDiagnosticState);
    return this.actions$.pipe(
      ofType(diagnosticActions.deleteOneDiagnostic),
      withLatestFrom(selectDiagnosticState$),
      concatMap(([{ idDiagnostic }, state]) =>
        this.diagnosticApiService.deleteDiagnostic(idDiagnostic).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteDiagnosticActions(state.entities[idDiagnostic] as DiagnosticEntityState),
              diagnosticActions.deleteOneDiagnostic.type
            )
          ]),
          catchError(error => of(diagnosticActions.diagnosticsFailure({ error })))
        )
      )
    );
  });

  normalizeManyDiagnosticsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(diagnosticActions.normalizeManyDiagnosticsAfterUpsert),
      concatMap(({ diagnostics }) => {
        const actions: Action[] = getActionsToNormalizeDiagnostic(diagnostics, StoreActionType.upsert);
        return [getMultiAction(actions, '[Diagnostic] Normalization After Upsert Success')];
      })
    );
  });
}
