import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  CompanyCommunicationRecipientUser,
  CompanyCommunicationRecipientUserEntityState
} from '@_model/interfaces/company-communication-recipient-user.model';
import { CompanyCommunicationRecipientUserRelationsIds } from './company-communication-recipient-user-generated.effects';

export const getOneCompanyCommunicationRecipientUser = createAction(
  '[CompanyCommunicationRecipientUser] Get One CompanyCommunicationRecipientUser',
  props<{ idCompanyCommunicationRecipientUser: number; params?: any }>()
);

export const getManyCompanyCommunicationRecipientUsers = createAction(
  '[CompanyCommunicationRecipientUser] Get Many CompanyCommunicationRecipientUsers',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyCommunicationRecipientUser] Add Many Actives CompanyCommunicationRecipientUser',
  props<{ idCompanyCommunicationRecipientUsers: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyCommunicationRecipientUser] Delete One Active CompanyCommunicationRecipientUser',
  props<{ idCompanyCommunicationRecipientUser: number }>()
);

export const clearActive = createAction(
  '[CompanyCommunicationRecipientUser] Clear Active CompanyCommunicationRecipientUser'
);

export const upsertOneCompanyCommunicationRecipientUser = createAction(
  '[CompanyCommunicationRecipientUser] Upsert One CompanyCommunicationRecipientUser',
  props<{
    companyCommunicationRecipientUser: Partial<CompanyCommunicationRecipientUser>;
    ids?: CompanyCommunicationRecipientUserRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationRecipientUsers = createAction(
  '[CompanyCommunicationRecipientUser] Upsert Many CompanyCommunicationRecipientUsers',
  props<{
    companyCommunicationRecipientUsers: Partial<CompanyCommunicationRecipientUser>[];
    ids?: CompanyCommunicationRecipientUserRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationRecipientUsersSuccess = createAction(
  '[CompanyCommunicationRecipientUser] Create Many CompanyCommunicationRecipientUsers Success',
  props<{ companyCommunicationRecipientUsers: CompanyCommunicationRecipientUserEntityState[] }>()
);

export const deleteOneCompanyCommunicationRecipientUser = createAction(
  '[CompanyCommunicationRecipientUser] Delete One CompanyCommunicationRecipientUser',
  props<{ idCompanyCommunicationRecipientUser: number }>()
);

export const deleteOneCompanyCommunicationRecipientUserSuccess = createAction(
  '[CompanyCommunicationRecipientUser] Delete One CompanyCommunicationRecipientUser Success',
  props<{ idCompanyCommunicationRecipientUser: number }>()
);

export const normalizeManyCompanyCommunicationRecipientUsersAfterUpsert = createAction(
  '[CompanyCommunicationRecipientUser] Normalize Many CompanyCommunicationRecipientUsers After Upsert',
  props<{ companyCommunicationRecipientUsers: CompanyCommunicationRecipientUserEntityState[] }>()
);

export const companyCommunicationRecipientUsersFailure = createAction(
  '[CompanyCommunicationRecipientUser] CompanyCommunicationRecipientUsers Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyCommunicationRecipientUser] Clear CompanyCommunicationRecipientUsers');

export const addCompanyCommunicationRecipientSuccess = createAction(
  '[CompanyCommunicationRecipientUser] Add CompanyCommunicationRecipient',
  props<{ idCompanyCommunicationRecipientUser: number; idCompanyCommunicationRecipient: number }>()
);

export const deleteManyCompanyCommunicationRecipientSuccess = createAction(
  '[CompanyCommunicationRecipientUser] Delete Many CompanyCommunicationRecipient',
  props<{ idCompanyCommunicationRecipients: number[]; idCompanyCommunicationRecipientUsers: number[] }>()
);

export const addUserSuccess = createAction(
  '[CompanyCommunicationRecipientUser] Add User',
  props<{ idCompanyCommunicationRecipientUser: number; idUser: number }>()
);

export const deleteManyUserSuccess = createAction(
  '[CompanyCommunicationRecipientUser] Delete Many User',
  props<{ idUsers: number[]; idCompanyCommunicationRecipientUsers: number[] }>()
);
