import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationResidenceTodoProfil,
  OrganizationResidenceTodoProfilEntityState
} from '@_model/interfaces/organization-residence-todo-profil.model';
import { Profil, ProfilEntityState } from '@_model/interfaces/profil.model';
import {
  OrganizationResidenceTodo,
  OrganizationResidenceTodoEntityState
} from '@_model/interfaces/organization-residence-todo.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationResidenceTodoProfilFeatureKey,
  OrganizationResidenceTodoProfilState
} from './organization-residence-todo-profil.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationResidenceTodoProfilRelations: string[] = ['profils', 'organizationResidenceTodos'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationResidenceTodoProfilState = createFeatureSelector<OrganizationResidenceTodoProfilState>(
  organizationResidenceTodoProfilFeatureKey
);

export const selectIsLoadedOrganizationResidenceTodoProfil = createSelector(
  selectOrganizationResidenceTodoProfilState,
  (state: OrganizationResidenceTodoProfilState) => state.isLoaded
);

export const selectIsLoadingOrganizationResidenceTodoProfil = createSelector(
  selectOrganizationResidenceTodoProfilState,
  (state: OrganizationResidenceTodoProfilState) => state.isLoading
);

export const selectIsReadyOrganizationResidenceTodoProfil = createSelector(
  selectOrganizationResidenceTodoProfilState,
  (state: OrganizationResidenceTodoProfilState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationResidenceTodoProfil = createSelector(
  selectOrganizationResidenceTodoProfilState,
  (state: OrganizationResidenceTodoProfilState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationResidenceTodoProfilModel: SelectorModel = {
  name: 'organizationResidenceTodoProfils',
  getSelector: selectAllOrganizationResidenceTodoProfilsDictionary,
  isReady: selectIsReadyOrganizationResidenceTodoProfil
};

export const selectOrganizationResidenceTodoProfilsEntities = createSelector(
  selectOrganizationResidenceTodoProfilState,
  selectEntities
);

export const selectOrganizationResidenceTodoProfilsArray = createSelector(
  selectOrganizationResidenceTodoProfilState,
  selectAll
);

export const selectIdOrganizationResidenceTodoProfilsActive = createSelector(
  selectOrganizationResidenceTodoProfilState,
  (state: OrganizationResidenceTodoProfilState) => state.actives
);

const organizationResidenceTodoProfilsInObject = (
  organizationResidenceTodoProfils: Dictionary<OrganizationResidenceTodoProfilEntityState>
) => ({ organizationResidenceTodoProfils });

const selectOrganizationResidenceTodoProfilsEntitiesDictionary = createSelector(
  selectOrganizationResidenceTodoProfilsEntities,
  organizationResidenceTodoProfilsInObject
);

const selectAllOrganizationResidenceTodoProfilsObject = createSelector(
  selectOrganizationResidenceTodoProfilsEntities,
  organizationResidenceTodoProfils => {
    return hydrateAll({ organizationResidenceTodoProfils });
  }
);

const selectOneOrganizationResidenceTodoProfilDictionary = (idOrganizationResidenceTodoProfil: number) =>
  createSelector(selectOrganizationResidenceTodoProfilsEntities, organizationResidenceTodoProfils => {
    return {
      organizationResidenceTodoProfils: {
        [idOrganizationResidenceTodoProfil]: organizationResidenceTodoProfils[idOrganizationResidenceTodoProfil]
      }
    };
  });

const selectOneOrganizationResidenceTodoProfilDictionaryWithoutChild = (idOrganizationResidenceTodoProfil: number) =>
  createSelector(selectOrganizationResidenceTodoProfilsEntities, organizationResidenceTodoProfils => {
    return { organizationResidenceTodoProfil: organizationResidenceTodoProfils[idOrganizationResidenceTodoProfil] };
  });

const selectActiveOrganizationResidenceTodoProfilsEntities = createSelector(
  selectIdOrganizationResidenceTodoProfilsActive,
  selectOrganizationResidenceTodoProfilsEntities,
  (actives: number[], organizationResidenceTodoProfils: Dictionary<OrganizationResidenceTodoProfilEntityState>) =>
    getOrganizationResidenceTodoProfilsFromActives(actives, organizationResidenceTodoProfils)
);

function getOrganizationResidenceTodoProfilsFromActives(
  actives: number[],
  organizationResidenceTodoProfils: Dictionary<OrganizationResidenceTodoProfilEntityState>
): Dictionary<OrganizationResidenceTodoProfilEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationResidenceTodoProfils[idActive]) {
      acc[idActive] = organizationResidenceTodoProfils[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationResidenceTodoProfilEntityState>);
}

const selectAllOrganizationResidenceTodoProfilsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationResidenceTodoProfils(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationResidenceTodoProfil>(
      schema,
      selectAllOrganizationResidenceTodoProfilsSelectors,
      selectOrganizationResidenceTodoProfilsEntitiesDictionary,
      getRelationSelectors,
      organizationResidenceTodoProfilRelations,
      hydrateAll,
      'organizationResidenceTodoProfil'
    );
  } else {
    return selectAllOrganizationResidenceTodoProfilsObject;
  }
}

export function selectAllOrganizationResidenceTodoProfilsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationResidenceTodoProfils'
): Selector {
  return createSelector(selectAllOrganizationResidenceTodoProfils(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationResidenceTodoProfilEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationResidenceTodoProfils.length; i++) {
      res[customKey][result.organizationResidenceTodoProfils[i].idOrganizationResidenceTodoProfil] =
        result.organizationResidenceTodoProfils[i];
    }
    return res;
  });
}

export function selectOneOrganizationResidenceTodoProfil(
  schema: SelectSchema = {},
  idOrganizationResidenceTodoProfil: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [
      selectOneOrganizationResidenceTodoProfilDictionary(idOrganizationResidenceTodoProfil)
    ];
    selectors.push(
      ...getRelationSelectors(schema, organizationResidenceTodoProfilRelations, 'organizationResidenceTodoProfil')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationResidenceTodoProfilDictionaryWithoutChild(idOrganizationResidenceTodoProfil);
  }
}

export function selectActiveOrganizationResidenceTodoProfils(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationResidenceTodoProfilsEntities, organizationResidenceTodoProfils => ({
      organizationResidenceTodoProfils
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, organizationResidenceTodoProfilRelations, 'organizationResidenceTodoProfil')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationResidenceTodoProfils: Dictionary<OrganizationResidenceTodoProfilEntityState>;
  profils?: Dictionary<ProfilEntityState>;
  organizationResidenceTodos?: Dictionary<OrganizationResidenceTodoEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationResidenceTodoProfils: (OrganizationResidenceTodoProfil | null)[];
} {
  const { organizationResidenceTodoProfils, profils, organizationResidenceTodos } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationResidenceTodoProfils: Object.keys(organizationResidenceTodoProfils).map(
      idOrganizationResidenceTodoProfil =>
        hydrate(
          organizationResidenceTodoProfils[
            idOrganizationResidenceTodoProfil
          ] as OrganizationResidenceTodoProfilEntityState,
          profils,
          organizationResidenceTodos
        )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationResidenceTodoProfil: OrganizationResidenceTodoProfilEntityState | null;
} {
  const { organizationResidenceTodoProfils, profils, organizationResidenceTodos } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationResidenceTodoProfil = Object.values(organizationResidenceTodoProfils)[0];
  return {
    organizationResidenceTodoProfil: hydrate(
      organizationResidenceTodoProfil as OrganizationResidenceTodoProfilEntityState,
      profils,
      organizationResidenceTodos
    )
  };
}

function hydrate(
  organizationResidenceTodoProfil: OrganizationResidenceTodoProfilEntityState,
  profilEntities?: Dictionary<ProfilEntityState>,
  organizationResidenceTodoEntities?: Dictionary<OrganizationResidenceTodoEntityState>
): OrganizationResidenceTodoProfil | null {
  if (!organizationResidenceTodoProfil) {
    return null;
  }

  const organizationResidenceTodoProfilHydrated: OrganizationResidenceTodoProfilEntityState = {
    ...organizationResidenceTodoProfil
  };
  if (profilEntities) {
    organizationResidenceTodoProfilHydrated.profil = profilEntities[
      organizationResidenceTodoProfil.profil as number
    ] as Profil;
  } else {
    delete organizationResidenceTodoProfilHydrated.profil;
  }
  if (organizationResidenceTodoEntities) {
    organizationResidenceTodoProfilHydrated.organizationResidenceTodo = organizationResidenceTodoEntities[
      organizationResidenceTodoProfil.organizationResidenceTodo as number
    ] as OrganizationResidenceTodo;
  } else {
    delete organizationResidenceTodoProfilHydrated.organizationResidenceTodo;
  }

  return organizationResidenceTodoProfilHydrated as OrganizationResidenceTodoProfil;
}
