import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedResidencePriceGridModifierApiService } from './residence-price-grid-modifier-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ResidencePriceGridModifierApiService extends GeneratedResidencePriceGridModifierApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
