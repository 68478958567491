import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { CompanyMedia, CompanyMediaEntityState } from '@_model/interfaces/company-media.model';
import { CompanyMediaApiService } from '@_services/api/company-media-api.service';
import * as companyMediaActions from '@_store/company-media/company-media.actions';
import { getActionsToNormalizeCompanyMedia } from '@_config/store/normalization.generated';
import { selectCompanyMediaState } from './company-media-generated.selectors';
import * as companyActions from '@_store/company/company.actions';
import * as campaignMediaActions from '@_store/campaign-media/campaign-media.actions';
import { CampaignMedia } from '@_model/interfaces/campaign-media.model';

export interface CompanyMediaRelationsIds {
  campaignMedias?: number | number[];
  company?: number;
}

export function getDefaultAddCompanyMediaActions(
  companyMedia: CompanyMediaEntityState,
  ids?: CompanyMediaRelationsIds
): Action[] {
  const actions: Action[] = [
    companyMediaActions.normalizeManyCompanyMediasAfterUpsert({ companyMedias: [companyMedia] })
  ];

  if (ids?.company) {
    actions.push(
      companyActions.addManyCompanyMediaSuccess({
        idCompany: ids.company,
        idCompanyMedias: [companyMedia.idCompanyMedia]
      })
    );
    actions.push(
      companyMediaActions.addCompanySuccess({
        idCompanyMedia: companyMedia.idCompanyMedia,
        idCompany: ids.company
      })
    );
  }

  if (ids?.campaignMedias) {
    if (!Array.isArray(ids.campaignMedias)) {
      actions.push(
        campaignMediaActions.upsertOneCampaignMedia({
          campaignMedia: {
            idCompanyMedia: companyMedia.idCompanyMedia,
            idCampaignMedia: ids.campaignMedias as number
          } as CampaignMedia
        })
      );
      actions.push(
        companyMediaActions.addManyCampaignMediaSuccess({
          idCompanyMedia: companyMedia.idCompanyMedia,
          idCampaignMedias: [ids.campaignMedias as number]
        })
      );
    } else {
      actions.push(
        campaignMediaActions.upsertManyCampaignMedias({
          campaignMedias: (ids.campaignMedias as number[]).map((idCampaignMedia: number) => ({
            idCompanyMedia: companyMedia.idCompanyMedia,
            idCampaignMedia
          })) as CampaignMedia[]
        })
      );
      actions.push(
        companyMediaActions.addManyCampaignMediaSuccess({
          idCompanyMedia: companyMedia.idCompanyMedia,
          idCampaignMedias: ids.campaignMedias as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCompanyMediaActions(companyMedia: CompanyMediaEntityState): Action[] {
  const actions: Action[] = [
    companyMediaActions.deleteOneCompanyMediaSuccess({ idCompanyMedia: companyMedia.idCompanyMedia })
  ];

  if (companyMedia.company) {
    actions.push(
      companyActions.deleteManyCompanyMediaSuccess({
        idCompanyMedias: [companyMedia.idCompanyMedia],
        idCompanies: [companyMedia.company as number]
      })
    );
  }

  if (companyMedia.campaignMedias) {
    actions.push(
      campaignMediaActions.deleteManyCompanyMediaSuccess({
        idCompanyMedias: [companyMedia.idCompanyMedia],
        idCampaignMedias: companyMedia.campaignMedias as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyMediaEffects {
  constructor(
    protected actions$: Actions,
    protected companyMediaApiService: CompanyMediaApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyMedias$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyMediaActions.getManyCompanyMedias),
      switchMap(({ params }) =>
        this.companyMediaApiService.getCompanyMedias(params).pipe(
          map((companyMedias: CompanyMedia[]) => {
            return companyMediaActions.normalizeManyCompanyMediasAfterUpsert({ companyMedias });
          }),
          catchError(error => of(companyMediaActions.companyMediasFailure({ error })))
        )
      )
    );
  });

  getOneCompanyMedia$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyMediaActions.getOneCompanyMedia),
      switchMap(idCompanyMedia =>
        this.companyMediaApiService.getCompanyMedia(idCompanyMedia).pipe(
          map((companyMedia: CompanyMedia) => {
            return companyMediaActions.normalizeManyCompanyMediasAfterUpsert({ companyMedias: [companyMedia] });
          }),
          catchError(error => of(companyMediaActions.companyMediasFailure({ error })))
        )
      )
    );
  });

  upsertOneCompanyMedia$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyMediaActions.upsertOneCompanyMedia),
      concatMap(({ companyMedia, ids }: { companyMedia: Partial<CompanyMedia>; ids?: CompanyMediaRelationsIds }) => {
        if (companyMedia.idCompanyMedia) {
          return this.companyMediaApiService.updateCompanyMedia(companyMedia).pipe(
            map((companyMediaReturned: CompanyMedia) => {
              return companyMediaActions.normalizeManyCompanyMediasAfterUpsert({
                companyMedias: [companyMediaReturned]
              });
            }),
            catchError(error => of(companyMediaActions.companyMediasFailure({ error })))
          );
        } else {
          return this.companyMediaApiService.addCompanyMedia(companyMedia).pipe(
            mergeMap((companyMediaReturned: CompanyMedia) =>
              getDefaultAddCompanyMediaActions(companyMediaReturned, ids)
            ),
            catchError(error => of(companyMediaActions.companyMediasFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneCompanyMedia$ = createEffect(() => {
    const selectCompanyMediaState$ = this.store$.select(selectCompanyMediaState);
    return this.actions$.pipe(
      ofType(companyMediaActions.deleteOneCompanyMedia),
      withLatestFrom(selectCompanyMediaState$),
      concatMap(([{ idCompanyMedia }, state]) =>
        this.companyMediaApiService.deleteCompanyMedia(idCompanyMedia).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCompanyMediaActions(state.entities[idCompanyMedia] as CompanyMediaEntityState),
              companyMediaActions.deleteOneCompanyMedia.type
            )
          ]),
          catchError(error => of(companyMediaActions.companyMediasFailure({ error })))
        )
      )
    );
  });

  normalizeManyCompanyMediasAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyMediaActions.normalizeManyCompanyMediasAfterUpsert),
      concatMap(({ companyMedias }) => {
        const actions: Action[] = getActionsToNormalizeCompanyMedia(companyMedias, StoreActionType.upsert);
        return [getMultiAction(actions, '[CompanyMedia] Normalization After Upsert Success')];
      })
    );
  });
}
