import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceTodo } from '@_model/interfaces/residence-todo.model';
import { Observable } from 'rxjs';

export class GeneratedResidenceTodoApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidenceTodos(params?: any): Observable<ResidenceTodo[]> {
    return this.repo.getData<ResidenceTodo[]>('residence-todo', params);
  }

  public getResidenceTodo(params: { idResidenceTodo: number; params?: any }): Observable<ResidenceTodo> {
    return this.repo.getData<ResidenceTodo>('residence-todo/' + params.idResidenceTodo, params.params);
  }

  public addResidenceTodo(residenceTodo: Partial<ResidenceTodo>): Observable<ResidenceTodo> {
    return this.repo.create<ResidenceTodo>('residence-todo', residenceTodo);
  }

  public updateResidenceTodo(residenceTodo: Partial<ResidenceTodo>): Observable<ResidenceTodo> {
    return this.repo.update('residence-todo', residenceTodo);
  }

  public deleteResidenceTodo(idResidenceTodo: number): Observable<number> {
    return this.repo.delete('residence-todo/' + +idResidenceTodo);
  }
}
