import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as StratalotPriceActions from './stratalot-price.actions';
import { adapter, initialState, StratalotPriceState } from './stratalot-price.state';
import { Dictionary } from '@ngrx/entity';
import { StratalotPriceEntityState } from '@_model/interfaces/stratalot-price.model';

export const stratalotPriceReducersGeneratedFunctions: ReducerTypes<StratalotPriceState, readonly ActionCreator[]>[] = [
  on(StratalotPriceActions.getOneStratalotPrice, (state: StratalotPriceState) => setLoadingsState(state, true)),
  on(StratalotPriceActions.getManyStratalotPrices, (state: StratalotPriceState) => setLoadingsState(state, true)),
  on(StratalotPriceActions.upsertOneStratalotPrice, (state: StratalotPriceState) => setLoadingsState(state, true)),

  on(StratalotPriceActions.upsertManyStratalotPricesSuccess, (state: StratalotPriceState, { stratalotPrices }) =>
    adapter.upsertMany(stratalotPrices, setLoadingsState(state, false))
  ),
  on(StratalotPriceActions.deleteOneStratalotPrice, (state: StratalotPriceState) => setLoadingsState(state, true)),
  on(StratalotPriceActions.deleteOneStratalotPriceSuccess, (state: StratalotPriceState, { idStratalotPrice }) =>
    adapter.removeOne(idStratalotPrice, setLoadingsState(state, false))
  ),
  on(StratalotPriceActions.clearActive, (state: StratalotPriceState) => ({ ...state, actives: [] })),
  on(StratalotPriceActions.addManyActives, (state: StratalotPriceState, { idStratalotPrices }) => ({
    ...state,
    actives: state.actives.concat(idStratalotPrices)
  })),
  on(StratalotPriceActions.deleteOneActive, (state: StratalotPriceState, { idStratalotPrice }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idStratalotPrice)
  })),

  on(StratalotPriceActions.clearStore, () => initialState),

  on(
    StratalotPriceActions.addManyCompanyPriceLabelSuccess,
    (state: StratalotPriceState, { idStratalotPrice, idCompanyPriceLabels }) => {
      if (!state.entities[idStratalotPrice]) {
        return state;
      }
      const companyPriceLabels = (state.entities[idStratalotPrice]?.companyPriceLabels as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotPrice]: {
            ...state.entities[idStratalotPrice],
            companyPriceLabels: companyPriceLabels.concat(
              idCompanyPriceLabels.filter(id => companyPriceLabels.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    StratalotPriceActions.deleteManyCompanyPriceLabelSuccess,
    (state: StratalotPriceState, { idCompanyPriceLabels, idStratalotPrices }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotPrices.reduce((entities, idStratalotPrice) => {
            if (!state.entities[idStratalotPrice]?.companyPriceLabels) {
              return entities;
            }
            entities[idStratalotPrice] = {
              ...state.entities[idStratalotPrice],
              companyPriceLabels: (state.entities[idStratalotPrice]?.companyPriceLabels as number[])?.filter(
                (idCompanyPriceLabel: number) => !idCompanyPriceLabels.some((id: number) => id === idCompanyPriceLabel)
              )
            } as StratalotPriceEntityState;
            return entities;
          }, {} as Dictionary<StratalotPriceEntityState>)
        }
      };
    }
  ),

  on(
    StratalotPriceActions.addManyStratalotPriceValueSuccess,
    (state: StratalotPriceState, { idStratalotPrice, idStratalotPriceValues }) => {
      if (!state.entities[idStratalotPrice]) {
        return state;
      }
      const stratalotPriceValues = (state.entities[idStratalotPrice]?.stratalotPriceValues as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotPrice]: {
            ...state.entities[idStratalotPrice],
            stratalotPriceValues: stratalotPriceValues.concat(
              idStratalotPriceValues.filter(id => stratalotPriceValues.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    StratalotPriceActions.deleteManyStratalotPriceValueSuccess,
    (state: StratalotPriceState, { idStratalotPriceValues, idStratalotPrices }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotPrices.reduce((entities, idStratalotPrice) => {
            if (!state.entities[idStratalotPrice]?.stratalotPriceValues) {
              return entities;
            }
            entities[idStratalotPrice] = {
              ...state.entities[idStratalotPrice],
              stratalotPriceValues: (state.entities[idStratalotPrice]?.stratalotPriceValues as number[])?.filter(
                (idStratalotPriceValue: number) =>
                  !idStratalotPriceValues.some((id: number) => id === idStratalotPriceValue)
              )
            } as StratalotPriceEntityState;
            return entities;
          }, {} as Dictionary<StratalotPriceEntityState>)
        }
      };
    }
  ),

  on(StratalotPriceActions.addStratalotSuccess, (state: StratalotPriceState, { idStratalotPrice, idStratalot }) => {
    if (!state.entities[idStratalotPrice]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalotPrice]: {
          ...state.entities[idStratalotPrice],
          stratalot: idStratalot
        }
      }
    };
  }),

  on(
    StratalotPriceActions.deleteManyStratalotSuccess,
    (state: StratalotPriceState, { idStratalots, idStratalotPrices }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotPrices.reduce((entities, idStratalotPrice) => {
            if (!state.entities[idStratalotPrice]?.stratalot) {
              return entities;
            }
            entities[idStratalotPrice] = {
              ...state.entities[idStratalotPrice],
              stratalot: idStratalots.some(
                (idStratalot: number) => idStratalot === state.entities[idStratalotPrice]?.stratalot
              )
                ? undefined
                : state.entities[idStratalotPrice]?.stratalot
            } as StratalotPriceEntityState;
            return entities;
          }, {} as Dictionary<StratalotPriceEntityState>)
        }
      };
    }
  ),

  on(
    StratalotPriceActions.addResidencePriceGridSuccess,
    (state: StratalotPriceState, { idStratalotPrice, idResidencePriceGrid }) => {
      if (!state.entities[idStratalotPrice]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotPrice]: {
            ...state.entities[idStratalotPrice],
            residencePriceGrid: idResidencePriceGrid
          }
        }
      };
    }
  ),

  on(
    StratalotPriceActions.deleteManyResidencePriceGridSuccess,
    (state: StratalotPriceState, { idResidencePriceGrids, idStratalotPrices }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotPrices.reduce((entities, idStratalotPrice) => {
            if (!state.entities[idStratalotPrice]?.residencePriceGrid) {
              return entities;
            }
            entities[idStratalotPrice] = {
              ...state.entities[idStratalotPrice],
              residencePriceGrid: idResidencePriceGrids.some(
                (idResidencePriceGrid: number) =>
                  idResidencePriceGrid === state.entities[idStratalotPrice]?.residencePriceGrid
              )
                ? undefined
                : state.entities[idStratalotPrice]?.residencePriceGrid
            } as StratalotPriceEntityState;
            return entities;
          }, {} as Dictionary<StratalotPriceEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: StratalotPriceState,
  isLoading: boolean,
  isLoaded: boolean = true
): StratalotPriceState {
  return { ...state, isLoaded, isLoading };
}
