import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BoardStateApiService } from '@_services/api/board-state-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedBoardStateEffects } from './board-state-generated.effects';

@Injectable()
export class BoardStateEffects extends GeneratedBoardStateEffects {
  constructor(actions$: Actions, boardStateApiService: BoardStateApiService, store$: Store<AppState>) {
    super(actions$, boardStateApiService, store$);
  }
}
