import { RepositoryService } from '@_services/api/repository.service';
import { CompanyTerritoireUser } from '@_model/interfaces/company-territoire-user.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyTerritoireUserApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyTerritoireUsers(params?: any): Observable<CompanyTerritoireUser[]> {
    return this.repo.getData<CompanyTerritoireUser[]>('company-territoire-user', params);
  }

  public getCompanyTerritoireUser(params: {
    idCompanyTerritoireUser: number;
    params?: any;
  }): Observable<CompanyTerritoireUser> {
    return this.repo.getData<CompanyTerritoireUser>(
      'company-territoire-user/' + params.idCompanyTerritoireUser,
      params.params
    );
  }

  public addCompanyTerritoireUser(
    companyTerritoireUser: Partial<CompanyTerritoireUser>
  ): Observable<CompanyTerritoireUser> {
    return this.repo.create<CompanyTerritoireUser>('company-territoire-user', companyTerritoireUser);
  }

  public updateCompanyTerritoireUser(
    companyTerritoireUser: Partial<CompanyTerritoireUser>
  ): Observable<CompanyTerritoireUser> {
    return this.repo.update('company-territoire-user', companyTerritoireUser);
  }

  public deleteCompanyTerritoireUser(idCompanyTerritoireUser: number): Observable<number> {
    return this.repo.delete('company-territoire-user/' + +idCompanyTerritoireUser);
  }
}
