import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedUserApiService } from './user-api-generated.service';
import { User } from '@_model/interfaces/user.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserApiService extends GeneratedUserApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public updateUserPassword(user: User): Observable<any> {
    return this.repo.update('user/update-password', user);
  }
}
