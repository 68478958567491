import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationResidenceTodoRule,
  OrganizationResidenceTodoRuleEntityState
} from '@_model/interfaces/organization-residence-todo-rule.model';
import { OrganizationResidenceTodoRuleApiService } from '@_services/api/organization-residence-todo-rule-api.service';
import * as organizationResidenceTodoRuleActions from '@_store/organization-residence-todo-rule/organization-residence-todo-rule.actions';
import { getActionsToNormalizeOrganizationResidenceTodoRule } from '@_config/store/normalization.generated';
import { selectOrganizationResidenceTodoRuleState } from './organization-residence-todo-rule-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as organizationSaleCategoryActions from '@_store/organization-sale-category/organization-sale-category.actions';
import * as saleCategoryFamilyActions from '@_store/sale-category-family/sale-category-family.actions';
import * as companyActions from '@_store/company/company.actions';
import * as organizationResidenceTodoActions from '@_store/organization-residence-todo/organization-residence-todo.actions';
import { OrganizationResidenceTodo } from '@_model/interfaces/organization-residence-todo.model';
import * as organizationResidenceTodoAppliedRuleActions from '@_store/organization-residence-todo-applied-rule/organization-residence-todo-applied-rule.actions';
import { OrganizationResidenceTodoAppliedRule } from '@_model/interfaces/organization-residence-todo-applied-rule.model';

export interface OrganizationResidenceTodoRuleRelationsIds {
  organizationResidenceTodos?: number | number[];
  organizationResidenceTodoAppliedRules?: number | number[];
  organization?: number;
  organizationSaleCategory?: number;
  saleCategoryFamily?: number;
  company?: number;
}

export function getDefaultAddOrganizationResidenceTodoRuleActions(
  organizationResidenceTodoRule: OrganizationResidenceTodoRuleEntityState,
  ids?: OrganizationResidenceTodoRuleRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationResidenceTodoRuleActions.normalizeManyOrganizationResidenceTodoRulesAfterUpsert({
      organizationResidenceTodoRules: [organizationResidenceTodoRule]
    })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationResidenceTodoRuleSuccess({
        idOrganization: ids.organization,
        idOrganizationResidenceTodoRules: [organizationResidenceTodoRule.idOrganizationResidenceTodoRule]
      })
    );
    actions.push(
      organizationResidenceTodoRuleActions.addOrganizationSuccess({
        idOrganizationResidenceTodoRule: organizationResidenceTodoRule.idOrganizationResidenceTodoRule,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.organizationSaleCategory) {
    actions.push(
      organizationSaleCategoryActions.addManyOrganizationResidenceTodoRuleSuccess({
        idOrganizationSaleCategory: ids.organizationSaleCategory,
        idOrganizationResidenceTodoRules: [organizationResidenceTodoRule.idOrganizationResidenceTodoRule]
      })
    );
    actions.push(
      organizationResidenceTodoRuleActions.addOrganizationSaleCategorySuccess({
        idOrganizationResidenceTodoRule: organizationResidenceTodoRule.idOrganizationResidenceTodoRule,
        idOrganizationSaleCategory: ids.organizationSaleCategory
      })
    );
  }

  if (ids?.saleCategoryFamily) {
    actions.push(
      saleCategoryFamilyActions.addManyOrganizationResidenceTodoRuleSuccess({
        idSaleCategoryFamily: ids.saleCategoryFamily,
        idOrganizationResidenceTodoRules: [organizationResidenceTodoRule.idOrganizationResidenceTodoRule]
      })
    );
    actions.push(
      organizationResidenceTodoRuleActions.addSaleCategoryFamilySuccess({
        idOrganizationResidenceTodoRule: organizationResidenceTodoRule.idOrganizationResidenceTodoRule,
        idSaleCategoryFamily: ids.saleCategoryFamily
      })
    );
  }

  if (ids?.company) {
    actions.push(
      companyActions.addManyOrganizationResidenceTodoRuleSuccess({
        idCompany: ids.company,
        idOrganizationResidenceTodoRules: [organizationResidenceTodoRule.idOrganizationResidenceTodoRule]
      })
    );
    actions.push(
      organizationResidenceTodoRuleActions.addCompanySuccess({
        idOrganizationResidenceTodoRule: organizationResidenceTodoRule.idOrganizationResidenceTodoRule,
        idCompany: ids.company
      })
    );
  }

  if (ids?.organizationResidenceTodos) {
    if (!Array.isArray(ids.organizationResidenceTodos)) {
      actions.push(
        organizationResidenceTodoActions.upsertOneOrganizationResidenceTodo({
          organizationResidenceTodo: {
            idOrganizationResidenceTodoRule: organizationResidenceTodoRule.idOrganizationResidenceTodoRule,
            idOrganizationResidenceTodo: ids.organizationResidenceTodos as number
          } as OrganizationResidenceTodo & any
        })
      );
      actions.push(
        organizationResidenceTodoRuleActions.addManyOrganizationResidenceTodoSuccess({
          idOrganizationResidenceTodoRule: organizationResidenceTodoRule.idOrganizationResidenceTodoRule,
          idOrganizationResidenceTodos: [ids.organizationResidenceTodos as number]
        })
      );
    } else {
      actions.push(
        organizationResidenceTodoActions.upsertManyOrganizationResidenceTodos({
          organizationResidenceTodos: (ids.organizationResidenceTodos as number[]).map(
            (idOrganizationResidenceTodo: number) => ({
              idOrganizationResidenceTodoRule: organizationResidenceTodoRule.idOrganizationResidenceTodoRule,
              idOrganizationResidenceTodo
            })
          ) as OrganizationResidenceTodo[] & any[]
        })
      );
      actions.push(
        organizationResidenceTodoRuleActions.addManyOrganizationResidenceTodoSuccess({
          idOrganizationResidenceTodoRule: organizationResidenceTodoRule.idOrganizationResidenceTodoRule,
          idOrganizationResidenceTodos: ids.organizationResidenceTodos as number[]
        })
      );
    }
  }

  if (ids?.organizationResidenceTodoAppliedRules) {
    if (!Array.isArray(ids.organizationResidenceTodoAppliedRules)) {
      actions.push(
        organizationResidenceTodoAppliedRuleActions.upsertOneOrganizationResidenceTodoAppliedRule({
          organizationResidenceTodoAppliedRule: {
            idOrganizationResidenceTodoRule: organizationResidenceTodoRule.idOrganizationResidenceTodoRule,
            idOrganizationResidenceTodoAppliedRule: ids.organizationResidenceTodoAppliedRules as number
          } as OrganizationResidenceTodoAppliedRule
        })
      );
      actions.push(
        organizationResidenceTodoRuleActions.addManyOrganizationResidenceTodoAppliedRuleSuccess({
          idOrganizationResidenceTodoRule: organizationResidenceTodoRule.idOrganizationResidenceTodoRule,
          idOrganizationResidenceTodoAppliedRules: [ids.organizationResidenceTodoAppliedRules as number]
        })
      );
    } else {
      actions.push(
        organizationResidenceTodoAppliedRuleActions.upsertManyOrganizationResidenceTodoAppliedRules({
          organizationResidenceTodoAppliedRules: (ids.organizationResidenceTodoAppliedRules as number[]).map(
            (idOrganizationResidenceTodoAppliedRule: number) => ({
              idOrganizationResidenceTodoRule: organizationResidenceTodoRule.idOrganizationResidenceTodoRule,
              idOrganizationResidenceTodoAppliedRule
            })
          ) as OrganizationResidenceTodoAppliedRule[]
        })
      );
      actions.push(
        organizationResidenceTodoRuleActions.addManyOrganizationResidenceTodoAppliedRuleSuccess({
          idOrganizationResidenceTodoRule: organizationResidenceTodoRule.idOrganizationResidenceTodoRule,
          idOrganizationResidenceTodoAppliedRules: ids.organizationResidenceTodoAppliedRules as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationResidenceTodoRuleActions(
  organizationResidenceTodoRule: OrganizationResidenceTodoRuleEntityState
): Action[] {
  const actions: Action[] = [
    organizationResidenceTodoRuleActions.deleteOneOrganizationResidenceTodoRuleSuccess({
      idOrganizationResidenceTodoRule: organizationResidenceTodoRule.idOrganizationResidenceTodoRule
    })
  ];

  if (organizationResidenceTodoRule.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationResidenceTodoRuleSuccess({
        idOrganizationResidenceTodoRules: [organizationResidenceTodoRule.idOrganizationResidenceTodoRule],
        idOrganizations: [organizationResidenceTodoRule.organization as number]
      })
    );
  }

  if (organizationResidenceTodoRule.organizationSaleCategory) {
    actions.push(
      organizationSaleCategoryActions.deleteManyOrganizationResidenceTodoRuleSuccess({
        idOrganizationResidenceTodoRules: [organizationResidenceTodoRule.idOrganizationResidenceTodoRule],
        idOrganizationSaleCategories: [organizationResidenceTodoRule.organizationSaleCategory as number]
      })
    );
  }

  if (organizationResidenceTodoRule.saleCategoryFamily) {
    actions.push(
      saleCategoryFamilyActions.deleteManyOrganizationResidenceTodoRuleSuccess({
        idOrganizationResidenceTodoRules: [organizationResidenceTodoRule.idOrganizationResidenceTodoRule],
        idFamilySaleCategories: [organizationResidenceTodoRule.saleCategoryFamily as number]
      })
    );
  }

  if (organizationResidenceTodoRule.company) {
    actions.push(
      companyActions.deleteManyOrganizationResidenceTodoRuleSuccess({
        idOrganizationResidenceTodoRules: [organizationResidenceTodoRule.idOrganizationResidenceTodoRule],
        idCompanies: [organizationResidenceTodoRule.company as number]
      })
    );
  }

  if (organizationResidenceTodoRule.organizationResidenceTodos) {
    actions.push(
      organizationResidenceTodoActions.deleteManyOrganizationResidenceTodoRuleSuccess({
        idOrganizationResidenceTodoRules: [organizationResidenceTodoRule.idOrganizationResidenceTodoRule],
        idOrganizationResidenceTodos: organizationResidenceTodoRule.organizationResidenceTodos as number[]
      })
    );
  }

  if (organizationResidenceTodoRule.organizationResidenceTodoAppliedRules) {
    actions.push(
      organizationResidenceTodoAppliedRuleActions.deleteManyOrganizationResidenceTodoRuleSuccess({
        idOrganizationResidenceTodoRules: [organizationResidenceTodoRule.idOrganizationResidenceTodoRule],
        idOrganizationResidenceTodoAppliedRules:
          organizationResidenceTodoRule.organizationResidenceTodoAppliedRules as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationResidenceTodoRuleEffects {
  constructor(
    protected actions$: Actions,
    protected organizationResidenceTodoRuleApiService: OrganizationResidenceTodoRuleApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationResidenceTodoRules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceTodoRuleActions.getManyOrganizationResidenceTodoRules),
      switchMap(({ params }) =>
        this.organizationResidenceTodoRuleApiService.getOrganizationResidenceTodoRules(params).pipe(
          map((organizationResidenceTodoRules: OrganizationResidenceTodoRule[]) => {
            return organizationResidenceTodoRuleActions.normalizeManyOrganizationResidenceTodoRulesAfterUpsert({
              organizationResidenceTodoRules
            });
          }),
          catchError(error => of(organizationResidenceTodoRuleActions.organizationResidenceTodoRulesFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationResidenceTodoRule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceTodoRuleActions.getOneOrganizationResidenceTodoRule),
      switchMap(idOrganizationResidenceTodoRule =>
        this.organizationResidenceTodoRuleApiService
          .getOrganizationResidenceTodoRule(idOrganizationResidenceTodoRule)
          .pipe(
            map((organizationResidenceTodoRule: OrganizationResidenceTodoRule) => {
              return organizationResidenceTodoRuleActions.normalizeManyOrganizationResidenceTodoRulesAfterUpsert({
                organizationResidenceTodoRules: [organizationResidenceTodoRule]
              });
            }),
            catchError(error =>
              of(organizationResidenceTodoRuleActions.organizationResidenceTodoRulesFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneOrganizationResidenceTodoRule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceTodoRuleActions.upsertOneOrganizationResidenceTodoRule),
      concatMap(
        ({
          organizationResidenceTodoRule,
          ids
        }: {
          organizationResidenceTodoRule: Partial<OrganizationResidenceTodoRule>;
          ids?: OrganizationResidenceTodoRuleRelationsIds;
        }) => {
          if (organizationResidenceTodoRule.idOrganizationResidenceTodoRule) {
            return this.organizationResidenceTodoRuleApiService
              .updateOrganizationResidenceTodoRule(organizationResidenceTodoRule)
              .pipe(
                map((organizationResidenceTodoRuleReturned: OrganizationResidenceTodoRule) => {
                  return organizationResidenceTodoRuleActions.normalizeManyOrganizationResidenceTodoRulesAfterUpsert({
                    organizationResidenceTodoRules: [organizationResidenceTodoRuleReturned]
                  });
                }),
                catchError(error =>
                  of(organizationResidenceTodoRuleActions.organizationResidenceTodoRulesFailure({ error }))
                )
              );
          } else {
            return this.organizationResidenceTodoRuleApiService
              .addOrganizationResidenceTodoRule(organizationResidenceTodoRule)
              .pipe(
                mergeMap((organizationResidenceTodoRuleReturned: OrganizationResidenceTodoRule) =>
                  getDefaultAddOrganizationResidenceTodoRuleActions(organizationResidenceTodoRuleReturned, ids)
                ),
                catchError(error =>
                  of(organizationResidenceTodoRuleActions.organizationResidenceTodoRulesFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationResidenceTodoRule$ = createEffect(() => {
    const selectOrganizationResidenceTodoRuleState$ = this.store$.select(selectOrganizationResidenceTodoRuleState);
    return this.actions$.pipe(
      ofType(organizationResidenceTodoRuleActions.deleteOneOrganizationResidenceTodoRule),
      withLatestFrom(selectOrganizationResidenceTodoRuleState$),
      concatMap(([{ idOrganizationResidenceTodoRule }, state]) =>
        this.organizationResidenceTodoRuleApiService
          .deleteOrganizationResidenceTodoRule(idOrganizationResidenceTodoRule)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteOrganizationResidenceTodoRuleActions(
                  state.entities[idOrganizationResidenceTodoRule] as OrganizationResidenceTodoRuleEntityState
                ),
                organizationResidenceTodoRuleActions.deleteOneOrganizationResidenceTodoRule.type
              )
            ]),
            catchError(error =>
              of(organizationResidenceTodoRuleActions.organizationResidenceTodoRulesFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyOrganizationResidenceTodoRulesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceTodoRuleActions.normalizeManyOrganizationResidenceTodoRulesAfterUpsert),
      concatMap(({ organizationResidenceTodoRules }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationResidenceTodoRule(
          organizationResidenceTodoRules,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationResidenceTodoRule] Normalization After Upsert Success')];
      })
    );
  });
}
