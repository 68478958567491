import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CompanyCommunicationGeneratedDocument,
  CompanyCommunicationGeneratedDocumentEntityState
} from '@_model/interfaces/company-communication-generated-document.model';
import { CompanyCommunication, CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';
import { GeneratedDocument, GeneratedDocumentEntityState } from '@_model/interfaces/generated-document.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  companyCommunicationGeneratedDocumentFeatureKey,
  CompanyCommunicationGeneratedDocumentState
} from './company-communication-generated-document.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyCommunicationGeneratedDocumentRelations: string[] = ['companyCommunications', 'generatedDocuments'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyCommunicationGeneratedDocumentState =
  createFeatureSelector<CompanyCommunicationGeneratedDocumentState>(companyCommunicationGeneratedDocumentFeatureKey);

export const selectIsLoadedCompanyCommunicationGeneratedDocument = createSelector(
  selectCompanyCommunicationGeneratedDocumentState,
  (state: CompanyCommunicationGeneratedDocumentState) => state.isLoaded
);

export const selectIsLoadingCompanyCommunicationGeneratedDocument = createSelector(
  selectCompanyCommunicationGeneratedDocumentState,
  (state: CompanyCommunicationGeneratedDocumentState) => state.isLoading
);

export const selectIsReadyCompanyCommunicationGeneratedDocument = createSelector(
  selectCompanyCommunicationGeneratedDocumentState,
  (state: CompanyCommunicationGeneratedDocumentState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyCommunicationGeneratedDocument = createSelector(
  selectCompanyCommunicationGeneratedDocumentState,
  (state: CompanyCommunicationGeneratedDocumentState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyCommunicationGeneratedDocumentModel: SelectorModel = {
  name: 'companyCommunicationGeneratedDocuments',
  getSelector: selectAllCompanyCommunicationGeneratedDocumentsDictionary,
  isReady: selectIsReadyCompanyCommunicationGeneratedDocument
};

export const selectCompanyCommunicationGeneratedDocumentsEntities = createSelector(
  selectCompanyCommunicationGeneratedDocumentState,
  selectEntities
);

export const selectCompanyCommunicationGeneratedDocumentsArray = createSelector(
  selectCompanyCommunicationGeneratedDocumentState,
  selectAll
);

export const selectIdCompanyCommunicationGeneratedDocumentsActive = createSelector(
  selectCompanyCommunicationGeneratedDocumentState,
  (state: CompanyCommunicationGeneratedDocumentState) => state.actives
);

const companyCommunicationGeneratedDocumentsInObject = (
  companyCommunicationGeneratedDocuments: Dictionary<CompanyCommunicationGeneratedDocumentEntityState>
) => ({ companyCommunicationGeneratedDocuments });

const selectCompanyCommunicationGeneratedDocumentsEntitiesDictionary = createSelector(
  selectCompanyCommunicationGeneratedDocumentsEntities,
  companyCommunicationGeneratedDocumentsInObject
);

const selectAllCompanyCommunicationGeneratedDocumentsObject = createSelector(
  selectCompanyCommunicationGeneratedDocumentsEntities,
  companyCommunicationGeneratedDocuments => {
    return hydrateAll({ companyCommunicationGeneratedDocuments });
  }
);

const selectOneCompanyCommunicationGeneratedDocumentDictionary = (idCompanyCommunicationGeneratedDocument: number) =>
  createSelector(selectCompanyCommunicationGeneratedDocumentsEntities, companyCommunicationGeneratedDocuments => {
    return {
      companyCommunicationGeneratedDocuments: {
        [idCompanyCommunicationGeneratedDocument]:
          companyCommunicationGeneratedDocuments[idCompanyCommunicationGeneratedDocument]
      }
    };
  });

const selectOneCompanyCommunicationGeneratedDocumentDictionaryWithoutChild = (
  idCompanyCommunicationGeneratedDocument: number
) =>
  createSelector(selectCompanyCommunicationGeneratedDocumentsEntities, companyCommunicationGeneratedDocuments => {
    return {
      companyCommunicationGeneratedDocument:
        companyCommunicationGeneratedDocuments[idCompanyCommunicationGeneratedDocument]
    };
  });

const selectActiveCompanyCommunicationGeneratedDocumentsEntities = createSelector(
  selectIdCompanyCommunicationGeneratedDocumentsActive,
  selectCompanyCommunicationGeneratedDocumentsEntities,
  (
    actives: number[],
    companyCommunicationGeneratedDocuments: Dictionary<CompanyCommunicationGeneratedDocumentEntityState>
  ) => getCompanyCommunicationGeneratedDocumentsFromActives(actives, companyCommunicationGeneratedDocuments)
);

function getCompanyCommunicationGeneratedDocumentsFromActives(
  actives: number[],
  companyCommunicationGeneratedDocuments: Dictionary<CompanyCommunicationGeneratedDocumentEntityState>
): Dictionary<CompanyCommunicationGeneratedDocumentEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyCommunicationGeneratedDocuments[idActive]) {
      acc[idActive] = companyCommunicationGeneratedDocuments[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyCommunicationGeneratedDocumentEntityState>);
}

const selectAllCompanyCommunicationGeneratedDocumentsSelectors: Dictionary<Selector> = {};
export function selectAllCompanyCommunicationGeneratedDocuments(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyCommunicationGeneratedDocument>(
      schema,
      selectAllCompanyCommunicationGeneratedDocumentsSelectors,
      selectCompanyCommunicationGeneratedDocumentsEntitiesDictionary,
      getRelationSelectors,
      companyCommunicationGeneratedDocumentRelations,
      hydrateAll,
      'companyCommunicationGeneratedDocument'
    );
  } else {
    return selectAllCompanyCommunicationGeneratedDocumentsObject;
  }
}

export function selectAllCompanyCommunicationGeneratedDocumentsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyCommunicationGeneratedDocuments'
): Selector {
  return createSelector(selectAllCompanyCommunicationGeneratedDocuments(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyCommunicationGeneratedDocumentEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyCommunicationGeneratedDocuments.length; i++) {
      res[customKey][result.companyCommunicationGeneratedDocuments[i].idCompanyCommunicationGeneratedDocument] =
        result.companyCommunicationGeneratedDocuments[i];
    }
    return res;
  });
}

export function selectOneCompanyCommunicationGeneratedDocument(
  schema: SelectSchema = {},
  idCompanyCommunicationGeneratedDocument: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [
      selectOneCompanyCommunicationGeneratedDocumentDictionary(idCompanyCommunicationGeneratedDocument)
    ];
    selectors.push(
      ...getRelationSelectors(
        schema,
        companyCommunicationGeneratedDocumentRelations,
        'companyCommunicationGeneratedDocument'
      )
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyCommunicationGeneratedDocumentDictionaryWithoutChild(
      idCompanyCommunicationGeneratedDocument
    );
  }
}

export function selectActiveCompanyCommunicationGeneratedDocuments(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(
      selectActiveCompanyCommunicationGeneratedDocumentsEntities,
      companyCommunicationGeneratedDocuments => ({ companyCommunicationGeneratedDocuments })
    )
  ];
  selectors.push(
    ...getRelationSelectors(
      schema,
      companyCommunicationGeneratedDocumentRelations,
      'companyCommunicationGeneratedDocument'
    )
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyCommunicationGeneratedDocuments: Dictionary<CompanyCommunicationGeneratedDocumentEntityState>;
  companyCommunications?: Dictionary<CompanyCommunicationEntityState>;
  generatedDocuments?: Dictionary<GeneratedDocumentEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  companyCommunicationGeneratedDocuments: (CompanyCommunicationGeneratedDocument | null)[];
} {
  const { companyCommunicationGeneratedDocuments, companyCommunications, generatedDocuments } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    companyCommunicationGeneratedDocuments: Object.keys(companyCommunicationGeneratedDocuments).map(
      idCompanyCommunicationGeneratedDocument =>
        hydrate(
          companyCommunicationGeneratedDocuments[
            idCompanyCommunicationGeneratedDocument
          ] as CompanyCommunicationGeneratedDocumentEntityState,
          companyCommunications,
          generatedDocuments
        )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  companyCommunicationGeneratedDocument: CompanyCommunicationGeneratedDocumentEntityState | null;
} {
  const { companyCommunicationGeneratedDocuments, companyCommunications, generatedDocuments } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const companyCommunicationGeneratedDocument = Object.values(companyCommunicationGeneratedDocuments)[0];
  return {
    companyCommunicationGeneratedDocument: hydrate(
      companyCommunicationGeneratedDocument as CompanyCommunicationGeneratedDocumentEntityState,
      companyCommunications,
      generatedDocuments
    )
  };
}

function hydrate(
  companyCommunicationGeneratedDocument: CompanyCommunicationGeneratedDocumentEntityState,
  companyCommunicationEntities?: Dictionary<CompanyCommunicationEntityState>,
  generatedDocumentEntities?: Dictionary<GeneratedDocumentEntityState>
): CompanyCommunicationGeneratedDocument | null {
  if (!companyCommunicationGeneratedDocument) {
    return null;
  }

  const companyCommunicationGeneratedDocumentHydrated: CompanyCommunicationGeneratedDocumentEntityState = {
    ...companyCommunicationGeneratedDocument
  };
  if (companyCommunicationEntities) {
    companyCommunicationGeneratedDocumentHydrated.companyCommunication = companyCommunicationEntities[
      companyCommunicationGeneratedDocument.companyCommunication as number
    ] as CompanyCommunication;
  } else {
    delete companyCommunicationGeneratedDocumentHydrated.companyCommunication;
  }
  if (generatedDocumentEntities) {
    companyCommunicationGeneratedDocumentHydrated.generatedDocument = generatedDocumentEntities[
      companyCommunicationGeneratedDocument.generatedDocument as number
    ] as GeneratedDocument;
  } else {
    delete companyCommunicationGeneratedDocumentHydrated.generatedDocument;
  }

  return companyCommunicationGeneratedDocumentHydrated as CompanyCommunicationGeneratedDocument;
}
