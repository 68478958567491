import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ResidenceContact, ResidenceContactEntityState } from '@_model/interfaces/residence-contact.model';
import { ResidenceContactRelationsIds } from './residence-contact-generated.effects';

export const getOneResidenceContact = createAction(
  '[ResidenceContact] Get One ResidenceContact',
  props<{ idResidenceContact: number; params?: any }>()
);

export const getManyResidenceContacts = createAction(
  '[ResidenceContact] Get Many ResidenceContacts',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ResidenceContact] Add Many Actives ResidenceContact',
  props<{ idResidenceContacts: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidenceContact] Delete One Active ResidenceContact',
  props<{ idResidenceContact: number }>()
);

export const clearActive = createAction('[ResidenceContact] Clear Active ResidenceContact');

export const upsertOneResidenceContact = createAction(
  '[ResidenceContact] Upsert One ResidenceContact',
  props<{
    residenceContact: Partial<ResidenceContact>;
    ids?: ResidenceContactRelationsIds;
  }>()
);

export const upsertManyResidenceContacts = createAction(
  '[ResidenceContact] Upsert Many ResidenceContacts',
  props<{
    residenceContacts: Partial<ResidenceContact>[];
    ids?: ResidenceContactRelationsIds;
  }>()
);

export const upsertManyResidenceContactsSuccess = createAction(
  '[ResidenceContact] Create Many ResidenceContacts Success',
  props<{ residenceContacts: ResidenceContactEntityState[] }>()
);

export const deleteOneResidenceContact = createAction(
  '[ResidenceContact] Delete One ResidenceContact',
  props<{ idResidenceContact: number }>()
);

export const deleteOneResidenceContactSuccess = createAction(
  '[ResidenceContact] Delete One ResidenceContact Success',
  props<{ idResidenceContact: number }>()
);

export const normalizeManyResidenceContactsAfterUpsert = createAction(
  '[ResidenceContact] Normalize Many ResidenceContacts After Upsert',
  props<{ residenceContacts: ResidenceContactEntityState[] }>()
);

export const residenceContactsFailure = createAction(
  '[ResidenceContact] ResidenceContacts Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidenceContact] Clear ResidenceContacts');

export const addResidenceSuccess = createAction(
  '[ResidenceContact] Add Residence',
  props<{ idResidenceContact: number; idResidence: number }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[ResidenceContact] Delete Many Residence',
  props<{ idResidences: number[]; idResidenceContacts: number[] }>()
);

export const addOrganizationThirdPartySuccess = createAction(
  '[ResidenceContact] Add OrganizationThirdParty',
  props<{ idResidenceContact: number; idOrganizationThirdParty: number }>()
);

export const deleteManyOrganizationThirdPartySuccess = createAction(
  '[ResidenceContact] Delete Many OrganizationThirdParty',
  props<{ idOrganizationThirdParties: number[]; idResidenceContacts: number[] }>()
);
