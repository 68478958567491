import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { StratalotFee, StratalotFeeEntityState } from '@_model/interfaces/stratalot-fee.model';
import { StratalotFeeApiService } from '@_services/api/stratalot-fee-api.service';
import * as stratalotFeeActions from '@_store/stratalot-fee/stratalot-fee.actions';
import { getActionsToNormalizeStratalotFee } from '@_config/store/normalization.generated';
import { selectStratalotFeeState } from './stratalot-fee-generated.selectors';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';

export interface StratalotFeeRelationsIds {
  stratalot?: number;
}

export function getDefaultAddStratalotFeeActions(
  stratalotFee: StratalotFeeEntityState,
  ids?: StratalotFeeRelationsIds
): Action[] {
  const actions: Action[] = [
    stratalotFeeActions.normalizeManyStratalotFeesAfterUpsert({ stratalotFees: [stratalotFee] })
  ];

  if (ids?.stratalot) {
    actions.push(
      stratalotActions.addManyStratalotFeeSuccess({
        idStratalot: ids.stratalot,
        idStratalotFees: [stratalotFee.idStratalotFee]
      })
    );
    actions.push(
      stratalotFeeActions.addStratalotSuccess({
        idStratalotFee: stratalotFee.idStratalotFee,
        idStratalot: ids.stratalot
      })
    );
  }

  return actions;
}

export function getDefaultDeleteStratalotFeeActions(stratalotFee: StratalotFeeEntityState): Action[] {
  const actions: Action[] = [
    stratalotFeeActions.deleteOneStratalotFeeSuccess({ idStratalotFee: stratalotFee.idStratalotFee })
  ];

  if (stratalotFee.stratalot) {
    actions.push(
      stratalotActions.deleteManyStratalotFeeSuccess({
        idStratalotFees: [stratalotFee.idStratalotFee],
        idStratalots: [stratalotFee.stratalot as number]
      })
    );
  }

  return actions;
}

export class GeneratedStratalotFeeEffects {
  constructor(
    protected actions$: Actions,
    protected stratalotFeeApiService: StratalotFeeApiService,
    protected store$: Store<AppState>
  ) {}

  getManyStratalotFees$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotFeeActions.getManyStratalotFees),
      switchMap(({ params }) =>
        this.stratalotFeeApiService.getStratalotFees(params).pipe(
          map((stratalotFees: StratalotFee[]) => {
            return stratalotFeeActions.normalizeManyStratalotFeesAfterUpsert({ stratalotFees });
          }),
          catchError(error => of(stratalotFeeActions.stratalotFeesFailure({ error })))
        )
      )
    );
  });

  getOneStratalotFee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotFeeActions.getOneStratalotFee),
      switchMap(idStratalotFee =>
        this.stratalotFeeApiService.getStratalotFee(idStratalotFee).pipe(
          map((stratalotFee: StratalotFee) => {
            return stratalotFeeActions.normalizeManyStratalotFeesAfterUpsert({ stratalotFees: [stratalotFee] });
          }),
          catchError(error => of(stratalotFeeActions.stratalotFeesFailure({ error })))
        )
      )
    );
  });

  upsertOneStratalotFee$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotFeeActions.upsertOneStratalotFee),
      concatMap(({ stratalotFee, ids }: { stratalotFee: Partial<StratalotFee>; ids?: StratalotFeeRelationsIds }) => {
        if (stratalotFee.idStratalotFee) {
          return this.stratalotFeeApiService.updateStratalotFee(stratalotFee).pipe(
            map((stratalotFeeReturned: StratalotFee) => {
              return stratalotFeeActions.normalizeManyStratalotFeesAfterUpsert({
                stratalotFees: [stratalotFeeReturned]
              });
            }),
            catchError(error => of(stratalotFeeActions.stratalotFeesFailure({ error })))
          );
        } else {
          return this.stratalotFeeApiService.addStratalotFee(stratalotFee).pipe(
            mergeMap((stratalotFeeReturned: StratalotFee) =>
              getDefaultAddStratalotFeeActions(stratalotFeeReturned, ids)
            ),
            catchError(error => of(stratalotFeeActions.stratalotFeesFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneStratalotFee$ = createEffect(() => {
    const selectStratalotFeeState$ = this.store$.select(selectStratalotFeeState);
    return this.actions$.pipe(
      ofType(stratalotFeeActions.deleteOneStratalotFee),
      withLatestFrom(selectStratalotFeeState$),
      concatMap(([{ idStratalotFee }, state]) =>
        this.stratalotFeeApiService.deleteStratalotFee(idStratalotFee).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteStratalotFeeActions(state.entities[idStratalotFee] as StratalotFeeEntityState),
              stratalotFeeActions.deleteOneStratalotFee.type
            )
          ]),
          catchError(error => of(stratalotFeeActions.stratalotFeesFailure({ error })))
        )
      )
    );
  });

  normalizeManyStratalotFeesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotFeeActions.normalizeManyStratalotFeesAfterUpsert),
      concatMap(({ stratalotFees }) => {
        const actions: Action[] = getActionsToNormalizeStratalotFee(stratalotFees, StoreActionType.upsert);
        return [getMultiAction(actions, '[StratalotFee] Normalization After Upsert Success')];
      })
    );
  });
}
