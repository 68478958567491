import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { StratalotTodo, StratalotTodoEntityState } from '@_model/interfaces/stratalot-todo.model';
import { StratalotTodoApiService } from '@_services/api/stratalot-todo-api.service';
import * as stratalotTodoActions from '@_store/stratalot-todo/stratalot-todo.actions';
import { getActionsToNormalizeStratalotTodo } from '@_config/store/normalization.generated';
import { selectStratalotTodoState } from './stratalot-todo-generated.selectors';
import * as organizationStratalotTodoActions from '@_store/organization-stratalot-todo/organization-stratalot-todo.actions';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';

export interface StratalotTodoRelationsIds {
  organizationStratalotTodo?: number;
  stratalot?: number;
}

export function getDefaultAddStratalotTodoActions(
  stratalotTodo: StratalotTodoEntityState,
  ids?: StratalotTodoRelationsIds
): Action[] {
  const actions: Action[] = [
    stratalotTodoActions.normalizeManyStratalotTodosAfterUpsert({ stratalotTodos: [stratalotTodo] })
  ];

  if (ids?.organizationStratalotTodo) {
    actions.push(
      organizationStratalotTodoActions.addManyStratalotTodoSuccess({
        idOrganizationStratalotTodo: ids.organizationStratalotTodo,
        idStratalotTodos: [stratalotTodo.idStratalotTodo]
      })
    );
    actions.push(
      stratalotTodoActions.addOrganizationStratalotTodoSuccess({
        idStratalotTodo: stratalotTodo.idStratalotTodo,
        idOrganizationStratalotTodo: ids.organizationStratalotTodo
      })
    );
  }

  if (ids?.stratalot) {
    actions.push(
      stratalotActions.addManyStratalotTodoSuccess({
        idStratalot: ids.stratalot,
        idStratalotTodos: [stratalotTodo.idStratalotTodo]
      })
    );
    actions.push(
      stratalotTodoActions.addStratalotSuccess({
        idStratalotTodo: stratalotTodo.idStratalotTodo,
        idStratalot: ids.stratalot
      })
    );
  }

  return actions;
}

export function getDefaultDeleteStratalotTodoActions(stratalotTodo: StratalotTodoEntityState): Action[] {
  const actions: Action[] = [
    stratalotTodoActions.deleteOneStratalotTodoSuccess({ idStratalotTodo: stratalotTodo.idStratalotTodo })
  ];

  if (stratalotTodo.organizationStratalotTodo) {
    actions.push(
      organizationStratalotTodoActions.deleteManyStratalotTodoSuccess({
        idStratalotTodos: [stratalotTodo.idStratalotTodo],
        idOrganizationStratalotTodos: [stratalotTodo.organizationStratalotTodo as number]
      })
    );
  }

  if (stratalotTodo.stratalot) {
    actions.push(
      stratalotActions.deleteManyStratalotTodoSuccess({
        idStratalotTodos: [stratalotTodo.idStratalotTodo],
        idStratalots: [stratalotTodo.stratalot as number]
      })
    );
  }

  return actions;
}

export class GeneratedStratalotTodoEffects {
  constructor(
    protected actions$: Actions,
    protected stratalotTodoApiService: StratalotTodoApiService,
    protected store$: Store<AppState>
  ) {}

  getManyStratalotTodos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotTodoActions.getManyStratalotTodos),
      switchMap(({ params }) =>
        this.stratalotTodoApiService.getStratalotTodos(params).pipe(
          map((stratalotTodos: StratalotTodo[]) => {
            return stratalotTodoActions.normalizeManyStratalotTodosAfterUpsert({ stratalotTodos });
          }),
          catchError(error => of(stratalotTodoActions.stratalotTodosFailure({ error })))
        )
      )
    );
  });

  getOneStratalotTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotTodoActions.getOneStratalotTodo),
      switchMap(idStratalotTodo =>
        this.stratalotTodoApiService.getStratalotTodo(idStratalotTodo).pipe(
          map((stratalotTodo: StratalotTodo) => {
            return stratalotTodoActions.normalizeManyStratalotTodosAfterUpsert({ stratalotTodos: [stratalotTodo] });
          }),
          catchError(error => of(stratalotTodoActions.stratalotTodosFailure({ error })))
        )
      )
    );
  });

  upsertOneStratalotTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotTodoActions.upsertOneStratalotTodo),
      concatMap(
        ({ stratalotTodo, ids }: { stratalotTodo: Partial<StratalotTodo>; ids?: StratalotTodoRelationsIds }) => {
          if (stratalotTodo.idStratalotTodo) {
            return this.stratalotTodoApiService.updateStratalotTodo(stratalotTodo).pipe(
              map((stratalotTodoReturned: StratalotTodo) => {
                return stratalotTodoActions.normalizeManyStratalotTodosAfterUpsert({
                  stratalotTodos: [stratalotTodoReturned]
                });
              }),
              catchError(error => of(stratalotTodoActions.stratalotTodosFailure({ error })))
            );
          } else {
            return this.stratalotTodoApiService.addStratalotTodo(stratalotTodo).pipe(
              mergeMap((stratalotTodoReturned: StratalotTodo) =>
                getDefaultAddStratalotTodoActions(stratalotTodoReturned, ids)
              ),
              catchError(error => of(stratalotTodoActions.stratalotTodosFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneStratalotTodo$ = createEffect(() => {
    const selectStratalotTodoState$ = this.store$.select(selectStratalotTodoState);
    return this.actions$.pipe(
      ofType(stratalotTodoActions.deleteOneStratalotTodo),
      withLatestFrom(selectStratalotTodoState$),
      concatMap(([{ idStratalotTodo }, state]) =>
        this.stratalotTodoApiService.deleteStratalotTodo(idStratalotTodo).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteStratalotTodoActions(state.entities[idStratalotTodo] as StratalotTodoEntityState),
              stratalotTodoActions.deleteOneStratalotTodo.type
            )
          ]),
          catchError(error => of(stratalotTodoActions.stratalotTodosFailure({ error })))
        )
      )
    );
  });

  normalizeManyStratalotTodosAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotTodoActions.normalizeManyStratalotTodosAfterUpsert),
      concatMap(({ stratalotTodos }) => {
        const actions: Action[] = getActionsToNormalizeStratalotTodo(stratalotTodos, StoreActionType.upsert);
        return [getMultiAction(actions, '[StratalotTodo] Normalization After Upsert Success')];
      })
    );
  });
}
