export enum AncienModulePathEnum {
  dashboard = 'dashboard',
  salePlan = 'sale-plan',
  residenceForSale = 'residence-for-sale',
  occupedStratalots = 'occuped-stratalots',
  freeStratalots = 'free-stratalots',
  prospects = 'prospects',
  validateOffer = 'validate-offer',
  salesInProgress = 'sales-in-progress',
  study = 'study'
}
