import { RepositoryService } from '@_services/api/repository.service';
import { CompanyCommunicationRecipientMetier } from '@_model/interfaces/company-communication-recipient-metier.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyCommunicationRecipientMetierApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyCommunicationRecipientMetiers(params?: any): Observable<CompanyCommunicationRecipientMetier[]> {
    return this.repo.getData<CompanyCommunicationRecipientMetier[]>('company-communication-recipient-metier', params);
  }

  public getCompanyCommunicationRecipientMetier(params: {
    idCompanyCommunicationRecipientMetier: number;
    params?: any;
  }): Observable<CompanyCommunicationRecipientMetier> {
    return this.repo.getData<CompanyCommunicationRecipientMetier>(
      'company-communication-recipient-metier/' + params.idCompanyCommunicationRecipientMetier,
      params.params
    );
  }

  public addCompanyCommunicationRecipientMetier(
    companyCommunicationRecipientMetier: Partial<CompanyCommunicationRecipientMetier>
  ): Observable<CompanyCommunicationRecipientMetier> {
    return this.repo.create<CompanyCommunicationRecipientMetier>(
      'company-communication-recipient-metier',
      companyCommunicationRecipientMetier
    );
  }

  public updateCompanyCommunicationRecipientMetier(
    companyCommunicationRecipientMetier: Partial<CompanyCommunicationRecipientMetier>
  ): Observable<CompanyCommunicationRecipientMetier> {
    return this.repo.update('company-communication-recipient-metier', companyCommunicationRecipientMetier);
  }

  public deleteCompanyCommunicationRecipientMetier(idCompanyCommunicationRecipientMetier: number): Observable<number> {
    return this.repo.delete('company-communication-recipient-metier/' + +idCompanyCommunicationRecipientMetier);
  }
}
