import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as DiagnosticActions from './diagnostic.actions';
import { adapter, initialState, DiagnosticState } from './diagnostic.state';
import { Dictionary } from '@ngrx/entity';
import { DiagnosticEntityState } from '@_model/interfaces/diagnostic.model';

export const diagnosticReducersGeneratedFunctions: ReducerTypes<DiagnosticState, readonly ActionCreator[]>[] = [
  on(DiagnosticActions.getOneDiagnostic, (state: DiagnosticState) => setLoadingsState(state, true)),
  on(DiagnosticActions.getManyDiagnostics, (state: DiagnosticState) => setLoadingsState(state, true)),
  on(DiagnosticActions.upsertOneDiagnostic, (state: DiagnosticState) => setLoadingsState(state, true)),

  on(DiagnosticActions.upsertManyDiagnosticsSuccess, (state: DiagnosticState, { diagnostics }) =>
    adapter.upsertMany(diagnostics, setLoadingsState(state, false))
  ),
  on(DiagnosticActions.deleteOneDiagnostic, (state: DiagnosticState) => setLoadingsState(state, true)),
  on(DiagnosticActions.deleteOneDiagnosticSuccess, (state: DiagnosticState, { idDiagnostic }) =>
    adapter.removeOne(idDiagnostic, setLoadingsState(state, false))
  ),
  on(DiagnosticActions.clearActive, (state: DiagnosticState) => ({ ...state, actives: [] })),
  on(DiagnosticActions.addManyActives, (state: DiagnosticState, { idDiagnostics }) => ({
    ...state,
    actives: state.actives.concat(idDiagnostics)
  })),
  on(DiagnosticActions.deleteOneActive, (state: DiagnosticState, { idDiagnostic }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idDiagnostic)
  })),

  on(DiagnosticActions.clearStore, () => initialState),

  on(
    DiagnosticActions.addManyOrganizationStratalotTodoSuccess,
    (state: DiagnosticState, { idDiagnostic, idOrganizationStratalotTodos }) => {
      if (!state.entities[idDiagnostic]) {
        return state;
      }
      const organizationStratalotTodos = (state.entities[idDiagnostic]?.organizationStratalotTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idDiagnostic]: {
            ...state.entities[idDiagnostic],
            organizationStratalotTodos: organizationStratalotTodos.concat(
              idOrganizationStratalotTodos.filter(id => organizationStratalotTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    DiagnosticActions.deleteManyOrganizationStratalotTodoSuccess,
    (state: DiagnosticState, { idOrganizationStratalotTodos, idDiagnostics }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idDiagnostics.reduce((entities, idDiagnostic) => {
            if (!state.entities[idDiagnostic]?.organizationStratalotTodos) {
              return entities;
            }
            entities[idDiagnostic] = {
              ...state.entities[idDiagnostic],
              organizationStratalotTodos: (
                state.entities[idDiagnostic]?.organizationStratalotTodos as number[]
              )?.filter(
                (idOrganizationStratalotTodo: number) =>
                  !idOrganizationStratalotTodos.some((id: number) => id === idOrganizationStratalotTodo)
              )
            } as DiagnosticEntityState;
            return entities;
          }, {} as Dictionary<DiagnosticEntityState>)
        }
      };
    }
  ),

  on(
    DiagnosticActions.addManyOrganizationResidenceTodoSuccess,
    (state: DiagnosticState, { idDiagnostic, idOrganizationResidenceTodos }) => {
      if (!state.entities[idDiagnostic]) {
        return state;
      }
      const organizationResidenceTodos = (state.entities[idDiagnostic]?.organizationResidenceTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idDiagnostic]: {
            ...state.entities[idDiagnostic],
            organizationResidenceTodos: organizationResidenceTodos.concat(
              idOrganizationResidenceTodos.filter(id => organizationResidenceTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    DiagnosticActions.deleteManyOrganizationResidenceTodoSuccess,
    (state: DiagnosticState, { idOrganizationResidenceTodos, idDiagnostics }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idDiagnostics.reduce((entities, idDiagnostic) => {
            if (!state.entities[idDiagnostic]?.organizationResidenceTodos) {
              return entities;
            }
            entities[idDiagnostic] = {
              ...state.entities[idDiagnostic],
              organizationResidenceTodos: (
                state.entities[idDiagnostic]?.organizationResidenceTodos as number[]
              )?.filter(
                (idOrganizationResidenceTodo: number) =>
                  !idOrganizationResidenceTodos.some((id: number) => id === idOrganizationResidenceTodo)
              )
            } as DiagnosticEntityState;
            return entities;
          }, {} as Dictionary<DiagnosticEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: DiagnosticState,
  isLoading: boolean,
  isLoaded: boolean = true
): DiagnosticState {
  return { ...state, isLoaded, isLoading };
}
