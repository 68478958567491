import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceForecastValue } from '@_shared/models/interfaces/residence-forecast-value.model';
import { Observable } from 'rxjs';
import { GeneratedResidenceForecastValueApiService } from './residence-forecast-value-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ResidenceForecastValueApiService extends GeneratedResidenceForecastValueApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public updateResidenceForecastValues(
    residenceForecastValues: Partial<ResidenceForecastValue>[]
  ): Observable<ResidenceForecastValue[]> {
    return this.repo.update('residence-forecast-value/many', residenceForecastValues);
  }
}
