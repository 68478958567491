import { GeneratedAppState, generatedAppState } from '@_config/store/reducers.generated';

namespace ClearStateUtils {
  export function findAllStateNameToClear(): (keyof GeneratedAppState)[] {
    const stateToKeep: (keyof GeneratedAppState)[] = [
      'residence',
      'residenceContact',
      'saleCategoryFamily',
      'stratalotType',
      'step',
      'profil',
      'droit',
      'user',
      'metierFamilie',
      'city',
      'generatedDocument',
      'generatedDocumentsCompany',
      'stepProgress',
      'stepProgressFamily',
      'forecastPeriod',
      'boardState',
      'diagnostic'
    ];

    const storeToclear: (keyof GeneratedAppState)[] = Object.keys(generatedAppState).filter(
      key => !filterByRegex(key) && !stateToKeep.some(k => k === key)
    ) as (keyof GeneratedAppState)[];
    return storeToclear;
  }

  function filterByRegex(stateKey): boolean {
    const regexRules = '^organization[a-zA-Z]*|^company[a-zA-Z]*';
    return new RegExp(regexRules).test(stateKey);
  }
}
export default ClearStateUtils;
