import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedOrganizationThirdPartyApiService } from './organization-third-party-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationThirdPartyApiService extends GeneratedOrganizationThirdPartyApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
