import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ResidencePriceGridHistory,
  ResidencePriceGridHistoryEntityState
} from '@_model/interfaces/residence-price-grid-history.model';
import { ResidencePriceGrid, ResidencePriceGridEntityState } from '@_model/interfaces/residence-price-grid.model';
import { User, UserEntityState } from '@_model/interfaces/user.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  residencePriceGridHistoryFeatureKey,
  ResidencePriceGridHistoryState
} from './residence-price-grid-history.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residencePriceGridHistoryRelations: string[] = ['residencePriceGrids', 'users'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidencePriceGridHistoryState = createFeatureSelector<ResidencePriceGridHistoryState>(
  residencePriceGridHistoryFeatureKey
);

export const selectIsLoadedResidencePriceGridHistory = createSelector(
  selectResidencePriceGridHistoryState,
  (state: ResidencePriceGridHistoryState) => state.isLoaded
);

export const selectIsLoadingResidencePriceGridHistory = createSelector(
  selectResidencePriceGridHistoryState,
  (state: ResidencePriceGridHistoryState) => state.isLoading
);

export const selectIsReadyResidencePriceGridHistory = createSelector(
  selectResidencePriceGridHistoryState,
  (state: ResidencePriceGridHistoryState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidencePriceGridHistory = createSelector(
  selectResidencePriceGridHistoryState,
  (state: ResidencePriceGridHistoryState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidencePriceGridHistoryModel: SelectorModel = {
  name: 'residencePriceGridHistories',
  getSelector: selectAllResidencePriceGridHistoriesDictionary,
  isReady: selectIsReadyResidencePriceGridHistory
};

export const selectResidencePriceGridHistoriesEntities = createSelector(
  selectResidencePriceGridHistoryState,
  selectEntities
);

export const selectResidencePriceGridHistoriesArray = createSelector(selectResidencePriceGridHistoryState, selectAll);

export const selectIdResidencePriceGridHistoriesActive = createSelector(
  selectResidencePriceGridHistoryState,
  (state: ResidencePriceGridHistoryState) => state.actives
);

const residencePriceGridHistoriesInObject = (
  residencePriceGridHistories: Dictionary<ResidencePriceGridHistoryEntityState>
) => ({ residencePriceGridHistories });

const selectResidencePriceGridHistoriesEntitiesDictionary = createSelector(
  selectResidencePriceGridHistoriesEntities,
  residencePriceGridHistoriesInObject
);

const selectAllResidencePriceGridHistoriesObject = createSelector(
  selectResidencePriceGridHistoriesEntities,
  residencePriceGridHistories => {
    return hydrateAll({ residencePriceGridHistories });
  }
);

const selectOneResidencePriceGridHistoryDictionary = (idResidencePriceGridHistory: number) =>
  createSelector(selectResidencePriceGridHistoriesEntities, residencePriceGridHistories => {
    return {
      residencePriceGridHistories: {
        [idResidencePriceGridHistory]: residencePriceGridHistories[idResidencePriceGridHistory]
      }
    };
  });

const selectOneResidencePriceGridHistoryDictionaryWithoutChild = (idResidencePriceGridHistory: number) =>
  createSelector(selectResidencePriceGridHistoriesEntities, residencePriceGridHistories => {
    return { residencePriceGridHistory: residencePriceGridHistories[idResidencePriceGridHistory] };
  });

const selectActiveResidencePriceGridHistoriesEntities = createSelector(
  selectIdResidencePriceGridHistoriesActive,
  selectResidencePriceGridHistoriesEntities,
  (actives: number[], residencePriceGridHistories: Dictionary<ResidencePriceGridHistoryEntityState>) =>
    getResidencePriceGridHistoriesFromActives(actives, residencePriceGridHistories)
);

function getResidencePriceGridHistoriesFromActives(
  actives: number[],
  residencePriceGridHistories: Dictionary<ResidencePriceGridHistoryEntityState>
): Dictionary<ResidencePriceGridHistoryEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residencePriceGridHistories[idActive]) {
      acc[idActive] = residencePriceGridHistories[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidencePriceGridHistoryEntityState>);
}

const selectAllResidencePriceGridHistoriesSelectors: Dictionary<Selector> = {};
export function selectAllResidencePriceGridHistories(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidencePriceGridHistory>(
      schema,
      selectAllResidencePriceGridHistoriesSelectors,
      selectResidencePriceGridHistoriesEntitiesDictionary,
      getRelationSelectors,
      residencePriceGridHistoryRelations,
      hydrateAll,
      'residencePriceGridHistory'
    );
  } else {
    return selectAllResidencePriceGridHistoriesObject;
  }
}

export function selectAllResidencePriceGridHistoriesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residencePriceGridHistories'
): Selector {
  return createSelector(selectAllResidencePriceGridHistories(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidencePriceGridHistoryEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residencePriceGridHistories.length; i++) {
      res[customKey][result.residencePriceGridHistories[i].idResidencePriceGridHistory] =
        result.residencePriceGridHistories[i];
    }
    return res;
  });
}

export function selectOneResidencePriceGridHistory(
  schema: SelectSchema = {},
  idResidencePriceGridHistory: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidencePriceGridHistoryDictionary(idResidencePriceGridHistory)];
    selectors.push(...getRelationSelectors(schema, residencePriceGridHistoryRelations, 'residencePriceGridHistory'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidencePriceGridHistoryDictionaryWithoutChild(idResidencePriceGridHistory);
  }
}

export function selectActiveResidencePriceGridHistories(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidencePriceGridHistoriesEntities, residencePriceGridHistories => ({
      residencePriceGridHistories
    }))
  ];
  selectors.push(...getRelationSelectors(schema, residencePriceGridHistoryRelations, 'residencePriceGridHistory'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residencePriceGridHistories: Dictionary<ResidencePriceGridHistoryEntityState>;
  residencePriceGrids?: Dictionary<ResidencePriceGridEntityState>;
  users?: Dictionary<UserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  residencePriceGridHistories: (ResidencePriceGridHistory | null)[];
} {
  const { residencePriceGridHistories, residencePriceGrids, users } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    residencePriceGridHistories: Object.keys(residencePriceGridHistories).map(idResidencePriceGridHistory =>
      hydrate(
        residencePriceGridHistories[idResidencePriceGridHistory] as ResidencePriceGridHistoryEntityState,
        residencePriceGrids,
        users
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  residencePriceGridHistory: ResidencePriceGridHistoryEntityState | null;
} {
  const { residencePriceGridHistories, residencePriceGrids, users } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const residencePriceGridHistory = Object.values(residencePriceGridHistories)[0];
  return {
    residencePriceGridHistory: hydrate(
      residencePriceGridHistory as ResidencePriceGridHistoryEntityState,
      residencePriceGrids,
      users
    )
  };
}

function hydrate(
  residencePriceGridHistory: ResidencePriceGridHistoryEntityState,
  residencePriceGridEntities?: Dictionary<ResidencePriceGridEntityState>,
  userEntities?: Dictionary<UserEntityState>
): ResidencePriceGridHistory | null {
  if (!residencePriceGridHistory) {
    return null;
  }

  const residencePriceGridHistoryHydrated: ResidencePriceGridHistoryEntityState = { ...residencePriceGridHistory };
  if (residencePriceGridEntities) {
    residencePriceGridHistoryHydrated.residencePriceGrid = residencePriceGridEntities[
      residencePriceGridHistory.residencePriceGrid as number
    ] as ResidencePriceGrid;
  } else {
    delete residencePriceGridHistoryHydrated.residencePriceGrid;
  }
  if (userEntities) {
    residencePriceGridHistoryHydrated.user = userEntities[residencePriceGridHistory.user as number] as User;
  } else {
    delete residencePriceGridHistoryHydrated.user;
  }

  return residencePriceGridHistoryHydrated as ResidencePriceGridHistory;
}
