import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StepProgressEntityState } from '@_model/interfaces/step-progress.model';

export interface StepProgressState extends EntityState<StepProgressEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<StepProgressEntityState> = createEntityAdapter<StepProgressEntityState>({
  selectId: o => o.idStepProgress
});
export const initialState: StepProgressState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const stepProgressFeatureKey = 'stepProgress';
