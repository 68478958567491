import { CompanyUbiflowState, initialState } from './company-ubiflow.state';
import { Action, createReducer } from '@ngrx/store';
import { companyUbiflowReducersGeneratedFunctions } from './company-ubiflow-generated.reducer';

const companyUbiflowReducersFunctions = [...companyUbiflowReducersGeneratedFunctions];

const companyUbiflowReducer = createReducer(initialState, ...companyUbiflowReducersFunctions);

export function reducer(state: CompanyUbiflowState | undefined, action: Action) {
  return companyUbiflowReducer(state, action);
}
