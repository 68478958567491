import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedOrganizationLeadTodoRuleApiService } from './organization-lead-todo-rule-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationLeadTodoRuleApiService extends GeneratedOrganizationLeadTodoRuleApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
