import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationResidenceTodoAppliedRuleApiService } from '@_services/api/organization-residence-todo-applied-rule-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedOrganizationResidenceTodoAppliedRuleEffects } from './organization-residence-todo-applied-rule-generated.effects';

@Injectable()
export class OrganizationResidenceTodoAppliedRuleEffects extends GeneratedOrganizationResidenceTodoAppliedRuleEffects {
  constructor(
    actions$: Actions,
    organizationResidenceTodoAppliedRuleApiService: OrganizationResidenceTodoAppliedRuleApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationResidenceTodoAppliedRuleApiService, store$);
  }
}
