import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GeneratedDocumentsResidenceApiService } from '@_services/api/generated-documents-residence-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedGeneratedDocumentsResidenceEffects } from './generated-documents-residence-generated.effects';

@Injectable()
export class GeneratedDocumentsResidenceEffects extends GeneratedGeneratedDocumentsResidenceEffects {
  constructor(
    actions$: Actions,
    generatedDocumentsResidenceApiService: GeneratedDocumentsResidenceApiService,
    store$: Store<AppState>
  ) {
    super(actions$, generatedDocumentsResidenceApiService, store$);
  }
}
