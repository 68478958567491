import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationLeadTodo, OrganizationLeadTodoEntityState } from '@_model/interfaces/organization-lead-todo.model';
import { LeadTodo, LeadTodoEntityState } from '@_model/interfaces/lead-todo.model';
import {
  OrganizationLeadTodoRule,
  OrganizationLeadTodoRuleEntityState
} from '@_model/interfaces/organization-lead-todo-rule.model';
import {
  OrganizationLeadTodoAppliedRule,
  OrganizationLeadTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-lead-todo-applied-rule.model';
import {
  OrganizationLeadTodoProfil,
  OrganizationLeadTodoProfilEntityState
} from '@_model/interfaces/organization-lead-todo-profil.model';
import { CompanyCommunication, CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';
import {
  CompanyCommunicationLeadTodo,
  CompanyCommunicationLeadTodoEntityState
} from '@_model/interfaces/company-communication-lead-todo.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { Step, StepEntityState } from '@_model/interfaces/step.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, organizationLeadTodoFeatureKey, OrganizationLeadTodoState } from './organization-lead-todo.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationLeadTodoRelations: string[] = [
  'leadTodos',
  'organizationLeadTodoRules',
  'organizationLeadTodoAppliedRules',
  'organizationLeadTodoProfils',
  'companyCommunications',
  'companyCommunicationLeadTodos',
  'organizations',
  'steps'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationLeadTodoState =
  createFeatureSelector<OrganizationLeadTodoState>(organizationLeadTodoFeatureKey);

export const selectIsLoadedOrganizationLeadTodo = createSelector(
  selectOrganizationLeadTodoState,
  (state: OrganizationLeadTodoState) => state.isLoaded
);

export const selectIsLoadingOrganizationLeadTodo = createSelector(
  selectOrganizationLeadTodoState,
  (state: OrganizationLeadTodoState) => state.isLoading
);

export const selectIsReadyOrganizationLeadTodo = createSelector(
  selectOrganizationLeadTodoState,
  (state: OrganizationLeadTodoState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationLeadTodo = createSelector(
  selectOrganizationLeadTodoState,
  (state: OrganizationLeadTodoState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationLeadTodoModel: SelectorModel = {
  name: 'organizationLeadTodos',
  getSelector: selectAllOrganizationLeadTodosDictionary,
  isReady: selectIsReadyOrganizationLeadTodo
};

export const selectOrganizationLeadTodosEntities = createSelector(selectOrganizationLeadTodoState, selectEntities);

export const selectOrganizationLeadTodosArray = createSelector(selectOrganizationLeadTodoState, selectAll);

export const selectIdOrganizationLeadTodosActive = createSelector(
  selectOrganizationLeadTodoState,
  (state: OrganizationLeadTodoState) => state.actives
);

const organizationLeadTodosInObject = (organizationLeadTodos: Dictionary<OrganizationLeadTodoEntityState>) => ({
  organizationLeadTodos
});

const selectOrganizationLeadTodosEntitiesDictionary = createSelector(
  selectOrganizationLeadTodosEntities,
  organizationLeadTodosInObject
);

const selectAllOrganizationLeadTodosObject = createSelector(
  selectOrganizationLeadTodosEntities,
  organizationLeadTodos => {
    return hydrateAll({ organizationLeadTodos });
  }
);

const selectOneOrganizationLeadTodoDictionary = (idOrganizationLeadTodo: number) =>
  createSelector(selectOrganizationLeadTodosEntities, organizationLeadTodos => {
    return { organizationLeadTodos: { [idOrganizationLeadTodo]: organizationLeadTodos[idOrganizationLeadTodo] } };
  });

const selectOneOrganizationLeadTodoDictionaryWithoutChild = (idOrganizationLeadTodo: number) =>
  createSelector(selectOrganizationLeadTodosEntities, organizationLeadTodos => {
    return { organizationLeadTodo: organizationLeadTodos[idOrganizationLeadTodo] };
  });

const selectActiveOrganizationLeadTodosEntities = createSelector(
  selectIdOrganizationLeadTodosActive,
  selectOrganizationLeadTodosEntities,
  (actives: number[], organizationLeadTodos: Dictionary<OrganizationLeadTodoEntityState>) =>
    getOrganizationLeadTodosFromActives(actives, organizationLeadTodos)
);

function getOrganizationLeadTodosFromActives(
  actives: number[],
  organizationLeadTodos: Dictionary<OrganizationLeadTodoEntityState>
): Dictionary<OrganizationLeadTodoEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationLeadTodos[idActive]) {
      acc[idActive] = organizationLeadTodos[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationLeadTodoEntityState>);
}

const selectAllOrganizationLeadTodosSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationLeadTodos(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationLeadTodo>(
      schema,
      selectAllOrganizationLeadTodosSelectors,
      selectOrganizationLeadTodosEntitiesDictionary,
      getRelationSelectors,
      organizationLeadTodoRelations,
      hydrateAll,
      'organizationLeadTodo'
    );
  } else {
    return selectAllOrganizationLeadTodosObject;
  }
}

export function selectAllOrganizationLeadTodosDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationLeadTodos'
): Selector {
  return createSelector(selectAllOrganizationLeadTodos(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationLeadTodoEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationLeadTodos.length; i++) {
      res[customKey][result.organizationLeadTodos[i].idOrganizationLeadTodo] = result.organizationLeadTodos[i];
    }
    return res;
  });
}

export function selectOneOrganizationLeadTodo(schema: SelectSchema = {}, idOrganizationLeadTodo: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationLeadTodoDictionary(idOrganizationLeadTodo)];
    selectors.push(...getRelationSelectors(schema, organizationLeadTodoRelations, 'organizationLeadTodo'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationLeadTodoDictionaryWithoutChild(idOrganizationLeadTodo);
  }
}

export function selectActiveOrganizationLeadTodos(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationLeadTodosEntities, organizationLeadTodos => ({ organizationLeadTodos }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationLeadTodoRelations, 'organizationLeadTodo'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationLeadTodos: Dictionary<OrganizationLeadTodoEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  steps?: Dictionary<StepEntityState>;
  leadTodos?: Dictionary<LeadTodoEntityState>;
  organizationLeadTodoRules?: Dictionary<OrganizationLeadTodoRuleEntityState>;
  organizationLeadTodoAppliedRules?: Dictionary<OrganizationLeadTodoAppliedRuleEntityState>;
  organizationLeadTodoProfils?: Dictionary<OrganizationLeadTodoProfilEntityState>;
  companyCommunications?: Dictionary<CompanyCommunicationEntityState>;
  companyCommunicationLeadTodos?: Dictionary<CompanyCommunicationLeadTodoEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationLeadTodos: (OrganizationLeadTodo | null)[] } {
  const {
    organizationLeadTodos,
    organizations,
    steps,
    leadTodos,
    organizationLeadTodoRules,
    organizationLeadTodoAppliedRules,
    organizationLeadTodoProfils,
    companyCommunications,
    companyCommunicationLeadTodos
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationLeadTodos: Object.keys(organizationLeadTodos).map(idOrganizationLeadTodo =>
      hydrate(
        organizationLeadTodos[idOrganizationLeadTodo] as OrganizationLeadTodoEntityState,
        organizations,
        steps,
        leadTodos,
        organizationLeadTodoRules,
        organizationLeadTodoAppliedRules,
        organizationLeadTodoProfils,
        companyCommunications,
        companyCommunicationLeadTodos
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationLeadTodo: OrganizationLeadTodoEntityState | null } {
  const {
    organizationLeadTodos,
    organizations,
    steps,
    leadTodos,
    organizationLeadTodoRules,
    organizationLeadTodoAppliedRules,
    organizationLeadTodoProfils,
    companyCommunications,
    companyCommunicationLeadTodos
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationLeadTodo = Object.values(organizationLeadTodos)[0];
  return {
    organizationLeadTodo: hydrate(
      organizationLeadTodo as OrganizationLeadTodoEntityState,
      organizations,
      steps,
      leadTodos,
      organizationLeadTodoRules,
      organizationLeadTodoAppliedRules,
      organizationLeadTodoProfils,
      companyCommunications,
      companyCommunicationLeadTodos
    )
  };
}

function hydrate(
  organizationLeadTodo: OrganizationLeadTodoEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  stepEntities?: Dictionary<StepEntityState>,
  leadTodoEntities?: Dictionary<LeadTodoEntityState>,
  organizationLeadTodoRuleEntities?: Dictionary<OrganizationLeadTodoRuleEntityState>,
  organizationLeadTodoAppliedRuleEntities?: Dictionary<OrganizationLeadTodoAppliedRuleEntityState>,
  organizationLeadTodoProfilEntities?: Dictionary<OrganizationLeadTodoProfilEntityState>,
  companyCommunicationEntities?: Dictionary<CompanyCommunicationEntityState>,
  companyCommunicationLeadTodoEntities?: Dictionary<CompanyCommunicationLeadTodoEntityState>
): OrganizationLeadTodo | null {
  if (!organizationLeadTodo) {
    return null;
  }

  const organizationLeadTodoHydrated: OrganizationLeadTodoEntityState = { ...organizationLeadTodo };
  if (organizationEntities) {
    organizationLeadTodoHydrated.organization = organizationEntities[
      organizationLeadTodo.organization as number
    ] as Organization;
  } else {
    delete organizationLeadTodoHydrated.organization;
  }
  if (stepEntities) {
    organizationLeadTodoHydrated.step = stepEntities[organizationLeadTodo.step as number] as Step;
  } else {
    delete organizationLeadTodoHydrated.step;
  }

  if (leadTodoEntities) {
    organizationLeadTodoHydrated.leadTodos = ((organizationLeadTodoHydrated.leadTodos as number[]) || []).map(
      id => leadTodoEntities[id]
    ) as LeadTodo[];
  } else {
    delete organizationLeadTodoHydrated.leadTodos;
  }

  if (organizationLeadTodoRuleEntities) {
    organizationLeadTodoHydrated.organizationLeadTodoRules = (
      (organizationLeadTodoHydrated.organizationLeadTodoRules as number[]) || []
    ).map(id => organizationLeadTodoRuleEntities[id]) as OrganizationLeadTodoRule[];
  } else {
    delete organizationLeadTodoHydrated.organizationLeadTodoRules;
  }

  if (organizationLeadTodoAppliedRuleEntities) {
    organizationLeadTodoHydrated.organizationLeadTodoAppliedRules = (
      (organizationLeadTodoHydrated.organizationLeadTodoAppliedRules as number[]) || []
    ).map(id => organizationLeadTodoAppliedRuleEntities[id]) as OrganizationLeadTodoAppliedRule[];
  } else {
    delete organizationLeadTodoHydrated.organizationLeadTodoAppliedRules;
  }

  if (organizationLeadTodoProfilEntities) {
    organizationLeadTodoHydrated.organizationLeadTodoProfils = (
      (organizationLeadTodoHydrated.organizationLeadTodoProfils as number[]) || []
    ).map(id => organizationLeadTodoProfilEntities[id]) as OrganizationLeadTodoProfil[];
  } else {
    delete organizationLeadTodoHydrated.organizationLeadTodoProfils;
  }

  if (companyCommunicationEntities) {
    organizationLeadTodoHydrated.companyCommunications = (
      (organizationLeadTodoHydrated.companyCommunications as number[]) || []
    ).map(id => companyCommunicationEntities[id]) as CompanyCommunication[];
  } else {
    delete organizationLeadTodoHydrated.companyCommunications;
  }

  if (companyCommunicationLeadTodoEntities) {
    organizationLeadTodoHydrated.companyCommunicationLeadTodos = (
      (organizationLeadTodoHydrated.companyCommunicationLeadTodos as number[]) || []
    ).map(id => companyCommunicationLeadTodoEntities[id]) as CompanyCommunicationLeadTodo[];
  } else {
    delete organizationLeadTodoHydrated.companyCommunicationLeadTodos;
  }

  return organizationLeadTodoHydrated as OrganizationLeadTodo;
}
