import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationLeadTodoProfilApiService } from '@_services/api/organization-lead-todo-profil-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedOrganizationLeadTodoProfilEffects } from './organization-lead-todo-profil-generated.effects';

@Injectable()
export class OrganizationLeadTodoProfilEffects extends GeneratedOrganizationLeadTodoProfilEffects {
  constructor(
    actions$: Actions,
    organizationLeadTodoProfilApiService: OrganizationLeadTodoProfilApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationLeadTodoProfilApiService, store$);
  }
}
