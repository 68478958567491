import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { OrganizationLeadTodo, OrganizationLeadTodoEntityState } from '@_model/interfaces/organization-lead-todo.model';
import { OrganizationLeadTodoRelationsIds } from './organization-lead-todo-generated.effects';

export const getOneOrganizationLeadTodo = createAction(
  '[OrganizationLeadTodo] Get One OrganizationLeadTodo',
  props<{ idOrganizationLeadTodo: number; params?: any }>()
);

export const getManyOrganizationLeadTodos = createAction(
  '[OrganizationLeadTodo] Get Many OrganizationLeadTodos',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationLeadTodo] Add Many Actives OrganizationLeadTodo',
  props<{ idOrganizationLeadTodos: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationLeadTodo] Delete One Active OrganizationLeadTodo',
  props<{ idOrganizationLeadTodo: number }>()
);

export const clearActive = createAction('[OrganizationLeadTodo] Clear Active OrganizationLeadTodo');

export const upsertOneOrganizationLeadTodo = createAction(
  '[OrganizationLeadTodo] Upsert One OrganizationLeadTodo',
  props<{
    organizationLeadTodo: Partial<OrganizationLeadTodo>;
    ids?: OrganizationLeadTodoRelationsIds;
  }>()
);

export const upsertManyOrganizationLeadTodos = createAction(
  '[OrganizationLeadTodo] Upsert Many OrganizationLeadTodos',
  props<{
    organizationLeadTodos: Partial<OrganizationLeadTodo>[];
    ids?: OrganizationLeadTodoRelationsIds;
  }>()
);

export const upsertManyOrganizationLeadTodosSuccess = createAction(
  '[OrganizationLeadTodo] Create Many OrganizationLeadTodos Success',
  props<{ organizationLeadTodos: OrganizationLeadTodoEntityState[] }>()
);

export const deleteOneOrganizationLeadTodo = createAction(
  '[OrganizationLeadTodo] Delete One OrganizationLeadTodo',
  props<{ idOrganizationLeadTodo: number }>()
);

export const deleteOneOrganizationLeadTodoSuccess = createAction(
  '[OrganizationLeadTodo] Delete One OrganizationLeadTodo Success',
  props<{ idOrganizationLeadTodo: number }>()
);

export const normalizeManyOrganizationLeadTodosAfterUpsert = createAction(
  '[OrganizationLeadTodo] Normalize Many OrganizationLeadTodos After Upsert',
  props<{ organizationLeadTodos: OrganizationLeadTodoEntityState[] }>()
);

export const organizationLeadTodosFailure = createAction(
  '[OrganizationLeadTodo] OrganizationLeadTodos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationLeadTodo] Clear OrganizationLeadTodos');

export const addManyLeadTodoSuccess = createAction(
  '[OrganizationLeadTodo] Add Many lead-todo',
  props<{ idOrganizationLeadTodo: number; idLeadTodos: number[] }>()
);

export const deleteManyLeadTodoSuccess = createAction(
  '[OrganizationLeadTodo] Delete Many LeadTodos',
  props<{ idLeadTodos: number[]; idOrganizationLeadTodos: number[] }>()
);

export const addManyOrganizationLeadTodoRuleSuccess = createAction(
  '[OrganizationLeadTodo] Add Many organization-lead-todo-rule',
  props<{ idOrganizationLeadTodo: number; idOrganizationLeadTodoRules: number[] }>()
);

export const deleteManyOrganizationLeadTodoRuleSuccess = createAction(
  '[OrganizationLeadTodo] Delete Many OrganizationLeadTodoRules',
  props<{ idOrganizationLeadTodoRules: number[]; idOrganizationLeadTodos: number[] }>()
);

export const addManyOrganizationLeadTodoAppliedRuleSuccess = createAction(
  '[OrganizationLeadTodo] Add Many organization-lead-todo-applied-rule',
  props<{ idOrganizationLeadTodo: number; idOrganizationLeadTodoAppliedRules: number[] }>()
);

export const deleteManyOrganizationLeadTodoAppliedRuleSuccess = createAction(
  '[OrganizationLeadTodo] Delete Many OrganizationLeadTodoAppliedRules',
  props<{ idOrganizationLeadTodoAppliedRules: number[]; idOrganizationLeadTodos: number[] }>()
);

export const addManyOrganizationLeadTodoProfilSuccess = createAction(
  '[OrganizationLeadTodo] Add Many organization-lead-todo-profil',
  props<{ idOrganizationLeadTodo: number; idOrganizationLeadTodoProfils: number[] }>()
);

export const deleteManyOrganizationLeadTodoProfilSuccess = createAction(
  '[OrganizationLeadTodo] Delete Many OrganizationLeadTodoProfils',
  props<{ idOrganizationLeadTodoProfils: number[]; idOrganizationLeadTodos: number[] }>()
);

export const addManyCompanyCommunicationSuccess = createAction(
  '[OrganizationLeadTodo] Add Many company-communication',
  props<{ idOrganizationLeadTodo: number; idCompanyCommunications: number[] }>()
);

export const deleteManyCompanyCommunicationSuccess = createAction(
  '[OrganizationLeadTodo] Delete Many CompanyCommunications',
  props<{ idCompanyCommunications: number[]; idOrganizationLeadTodos: number[] }>()
);

export const addManyCompanyCommunicationLeadTodoSuccess = createAction(
  '[OrganizationLeadTodo] Add Many company-communication-lead-todo',
  props<{ idOrganizationLeadTodo: number; idCompanyCommunicationLeadTodos: number[] }>()
);

export const deleteManyCompanyCommunicationLeadTodoSuccess = createAction(
  '[OrganizationLeadTodo] Delete Many CompanyCommunicationLeadTodos',
  props<{ idCompanyCommunicationLeadTodos: number[]; idOrganizationLeadTodos: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[OrganizationLeadTodo] Add Organization',
  props<{ idOrganizationLeadTodo: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationLeadTodo] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationLeadTodos: number[] }>()
);

export const addStepSuccess = createAction(
  '[OrganizationLeadTodo] Add Step',
  props<{ idOrganizationLeadTodo: number; idStep: number }>()
);

export const deleteManyStepSuccess = createAction(
  '[OrganizationLeadTodo] Delete Many Step',
  props<{ idSteps: number[]; idOrganizationLeadTodos: number[] }>()
);
