import {
  CompanyCommunicationGeneratedDocumentState,
  initialState
} from './company-communication-generated-document.state';
import { Action, createReducer } from '@ngrx/store';
import { companyCommunicationGeneratedDocumentReducersGeneratedFunctions } from './company-communication-generated-document-generated.reducer';

const companyCommunicationGeneratedDocumentReducersFunctions = [
  ...companyCommunicationGeneratedDocumentReducersGeneratedFunctions
];

const companyCommunicationGeneratedDocumentReducer = createReducer(
  initialState,
  ...companyCommunicationGeneratedDocumentReducersFunctions
);

export function reducer(state: CompanyCommunicationGeneratedDocumentState | undefined, action: Action) {
  return companyCommunicationGeneratedDocumentReducer(state, action);
}
