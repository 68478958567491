import { StratalotTypeState, initialState } from './stratalot-type.state';
import { Action, createReducer } from '@ngrx/store';
import { stratalotTypeReducersGeneratedFunctions } from './stratalot-type-generated.reducer';

const stratalotTypeReducersFunctions = [...stratalotTypeReducersGeneratedFunctions];

const stratalotTypeReducer = createReducer(initialState, ...stratalotTypeReducersFunctions);

export function reducer(state: StratalotTypeState | undefined, action: Action) {
  return stratalotTypeReducer(state, action);
}
