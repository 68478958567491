import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StratalotTodo, StratalotTodoEntityState } from '@_model/interfaces/stratalot-todo.model';
import {
  OrganizationStratalotTodo,
  OrganizationStratalotTodoEntityState
} from '@_model/interfaces/organization-stratalot-todo.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, stratalotTodoFeatureKey, StratalotTodoState } from './stratalot-todo.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const stratalotTodoRelations: string[] = ['organizationStratalotTodos', 'stratalots'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectStratalotTodoState = createFeatureSelector<StratalotTodoState>(stratalotTodoFeatureKey);

export const selectIsLoadedStratalotTodo = createSelector(
  selectStratalotTodoState,
  (state: StratalotTodoState) => state.isLoaded
);

export const selectIsLoadingStratalotTodo = createSelector(
  selectStratalotTodoState,
  (state: StratalotTodoState) => state.isLoading
);

export const selectIsReadyStratalotTodo = createSelector(
  selectStratalotTodoState,
  (state: StratalotTodoState) => !state.isLoading
);

export const selectIsReadyAndLoadedStratalotTodo = createSelector(
  selectStratalotTodoState,
  (state: StratalotTodoState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const StratalotTodoModel: SelectorModel = {
  name: 'stratalotTodos',
  getSelector: selectAllStratalotTodosDictionary,
  isReady: selectIsReadyStratalotTodo
};

export const selectStratalotTodosEntities = createSelector(selectStratalotTodoState, selectEntities);

export const selectStratalotTodosArray = createSelector(selectStratalotTodoState, selectAll);

export const selectIdStratalotTodosActive = createSelector(
  selectStratalotTodoState,
  (state: StratalotTodoState) => state.actives
);

const stratalotTodosInObject = (stratalotTodos: Dictionary<StratalotTodoEntityState>) => ({ stratalotTodos });

const selectStratalotTodosEntitiesDictionary = createSelector(selectStratalotTodosEntities, stratalotTodosInObject);

const selectAllStratalotTodosObject = createSelector(selectStratalotTodosEntities, stratalotTodos => {
  return hydrateAll({ stratalotTodos });
});

const selectOneStratalotTodoDictionary = (idStratalotTodo: number) =>
  createSelector(selectStratalotTodosEntities, stratalotTodos => {
    return { stratalotTodos: { [idStratalotTodo]: stratalotTodos[idStratalotTodo] } };
  });

const selectOneStratalotTodoDictionaryWithoutChild = (idStratalotTodo: number) =>
  createSelector(selectStratalotTodosEntities, stratalotTodos => {
    return { stratalotTodo: stratalotTodos[idStratalotTodo] };
  });

const selectActiveStratalotTodosEntities = createSelector(
  selectIdStratalotTodosActive,
  selectStratalotTodosEntities,
  (actives: number[], stratalotTodos: Dictionary<StratalotTodoEntityState>) =>
    getStratalotTodosFromActives(actives, stratalotTodos)
);

function getStratalotTodosFromActives(
  actives: number[],
  stratalotTodos: Dictionary<StratalotTodoEntityState>
): Dictionary<StratalotTodoEntityState> {
  return actives.reduce((acc, idActive) => {
    if (stratalotTodos[idActive]) {
      acc[idActive] = stratalotTodos[idActive];
    }
    return acc;
  }, {} as Dictionary<StratalotTodoEntityState>);
}

const selectAllStratalotTodosSelectors: Dictionary<Selector> = {};
export function selectAllStratalotTodos(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<StratalotTodo>(
      schema,
      selectAllStratalotTodosSelectors,
      selectStratalotTodosEntitiesDictionary,
      getRelationSelectors,
      stratalotTodoRelations,
      hydrateAll,
      'stratalotTodo'
    );
  } else {
    return selectAllStratalotTodosObject;
  }
}

export function selectAllStratalotTodosDictionary(
  schema: SelectSchema = {},
  customKey: string = 'stratalotTodos'
): Selector {
  return createSelector(selectAllStratalotTodos(schema), result => {
    const res = { [customKey]: {} as Dictionary<StratalotTodoEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.stratalotTodos.length; i++) {
      res[customKey][result.stratalotTodos[i].idStratalotTodo] = result.stratalotTodos[i];
    }
    return res;
  });
}

export function selectOneStratalotTodo(schema: SelectSchema = {}, idStratalotTodo: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneStratalotTodoDictionary(idStratalotTodo)];
    selectors.push(...getRelationSelectors(schema, stratalotTodoRelations, 'stratalotTodo'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneStratalotTodoDictionaryWithoutChild(idStratalotTodo);
  }
}

export function selectActiveStratalotTodos(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveStratalotTodosEntities, stratalotTodos => ({ stratalotTodos }))
  ];
  selectors.push(...getRelationSelectors(schema, stratalotTodoRelations, 'stratalotTodo'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  stratalotTodos: Dictionary<StratalotTodoEntityState>;
  organizationStratalotTodos?: Dictionary<OrganizationStratalotTodoEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { stratalotTodos: (StratalotTodo | null)[] } {
  const { stratalotTodos, organizationStratalotTodos, stratalots } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    stratalotTodos: Object.keys(stratalotTodos).map(idStratalotTodo =>
      hydrate(stratalotTodos[idStratalotTodo] as StratalotTodoEntityState, organizationStratalotTodos, stratalots)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { stratalotTodo: StratalotTodoEntityState | null } {
  const { stratalotTodos, organizationStratalotTodos, stratalots } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const stratalotTodo = Object.values(stratalotTodos)[0];
  return { stratalotTodo: hydrate(stratalotTodo as StratalotTodoEntityState, organizationStratalotTodos, stratalots) };
}

function hydrate(
  stratalotTodo: StratalotTodoEntityState,
  organizationStratalotTodoEntities?: Dictionary<OrganizationStratalotTodoEntityState>,
  stratalotEntities?: Dictionary<StratalotEntityState>
): StratalotTodo | null {
  if (!stratalotTodo) {
    return null;
  }

  const stratalotTodoHydrated: StratalotTodoEntityState = { ...stratalotTodo };
  if (organizationStratalotTodoEntities) {
    stratalotTodoHydrated.organizationStratalotTodo = organizationStratalotTodoEntities[
      stratalotTodo.organizationStratalotTodo as number
    ] as OrganizationStratalotTodo;
  } else {
    delete stratalotTodoHydrated.organizationStratalotTodo;
  }
  if (stratalotEntities) {
    stratalotTodoHydrated.stratalot = stratalotEntities[stratalotTodo.stratalot as number] as Stratalot;
  } else {
    delete stratalotTodoHydrated.stratalot;
  }

  return stratalotTodoHydrated as StratalotTodo;
}
