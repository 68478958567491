import { RepositoryService } from '@_services/api/repository.service';
import { CompanyStudyNextAction } from '@_model/interfaces/company-study-next-action.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyStudyNextActionApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyStudyNextActions(params?: any): Observable<CompanyStudyNextAction[]> {
    return this.repo.getData<CompanyStudyNextAction[]>('company-study-next-action', params);
  }

  public getCompanyStudyNextAction(params: {
    idCompanyStudyNextAction: number;
    params?: any;
  }): Observable<CompanyStudyNextAction> {
    return this.repo.getData<CompanyStudyNextAction>(
      'company-study-next-action/' + params.idCompanyStudyNextAction,
      params.params
    );
  }

  public addCompanyStudyNextAction(
    companyStudyNextAction: Partial<CompanyStudyNextAction>
  ): Observable<CompanyStudyNextAction> {
    return this.repo.create<CompanyStudyNextAction>('company-study-next-action', companyStudyNextAction);
  }

  public updateCompanyStudyNextAction(
    companyStudyNextAction: Partial<CompanyStudyNextAction>
  ): Observable<CompanyStudyNextAction> {
    return this.repo.update('company-study-next-action', companyStudyNextAction);
  }

  public deleteCompanyStudyNextAction(idCompanyStudyNextAction: number): Observable<number> {
    return this.repo.delete('company-study-next-action/' + +idCompanyStudyNextAction);
  }
}
