import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceStudyTodo } from '@_model/interfaces/residence-study-todo.model';
import { Observable } from 'rxjs';

export class GeneratedResidenceStudyTodoApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidenceStudyTodos(params?: any): Observable<ResidenceStudyTodo[]> {
    return this.repo.getData<ResidenceStudyTodo[]>('residence-study-todo', params);
  }

  public getResidenceStudyTodo(params: { idResidenceStudyTodo: number; params?: any }): Observable<ResidenceStudyTodo> {
    return this.repo.getData<ResidenceStudyTodo>('residence-study-todo/' + params.idResidenceStudyTodo, params.params);
  }

  public addResidenceStudyTodo(residenceStudyTodo: Partial<ResidenceStudyTodo>): Observable<ResidenceStudyTodo> {
    return this.repo.create<ResidenceStudyTodo>('residence-study-todo', residenceStudyTodo);
  }

  public updateResidenceStudyTodo(residenceStudyTodo: Partial<ResidenceStudyTodo>): Observable<ResidenceStudyTodo> {
    return this.repo.update('residence-study-todo', residenceStudyTodo);
  }

  public deleteResidenceStudyTodo(idResidenceStudyTodo: number): Observable<number> {
    return this.repo.delete('residence-study-todo/' + +idResidenceStudyTodo);
  }
}
