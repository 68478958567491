import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { StepProgressFamily, StepProgressFamilyEntityState } from '@_model/interfaces/step-progress-family.model';
import { StepProgressFamilyApiService } from '@_services/api/step-progress-family-api.service';
import * as stepProgressFamilyActions from '@_store/step-progress-family/step-progress-family.actions';
import { getActionsToNormalizeStepProgressFamily } from '@_config/store/normalization.generated';
import { selectStepProgressFamilyState } from './step-progress-family-generated.selectors';
import * as stepProgressActions from '@_store/step-progress/step-progress.actions';
import { StepProgress } from '@_model/interfaces/step-progress.model';

export interface StepProgressFamilyRelationsIds {
  stepProgresses?: number | number[];
}

export function getDefaultAddStepProgressFamilyActions(
  stepProgressFamily: StepProgressFamilyEntityState,
  ids?: StepProgressFamilyRelationsIds
): Action[] {
  const actions: Action[] = [
    stepProgressFamilyActions.normalizeManyStepProgressesFamiliesAfterUpsert({
      stepProgressesFamilies: [stepProgressFamily]
    })
  ];

  if (ids?.stepProgresses) {
    if (!Array.isArray(ids.stepProgresses)) {
      actions.push(
        stepProgressActions.upsertOneStepProgress({
          stepProgress: {
            idStepProgressFamily: stepProgressFamily.idStepProgressFamily,
            idStepProgress: ids.stepProgresses as number
          } as StepProgress
        })
      );
      actions.push(
        stepProgressFamilyActions.addManyStepProgressSuccess({
          idStepProgressFamily: stepProgressFamily.idStepProgressFamily,
          idStepProgresses: [ids.stepProgresses as number]
        })
      );
    } else {
      actions.push(
        stepProgressActions.upsertManyStepProgresses({
          stepProgresses: (ids.stepProgresses as number[]).map((idStepProgress: number) => ({
            idStepProgressFamily: stepProgressFamily.idStepProgressFamily,
            idStepProgress
          })) as StepProgress[]
        })
      );
      actions.push(
        stepProgressFamilyActions.addManyStepProgressSuccess({
          idStepProgressFamily: stepProgressFamily.idStepProgressFamily,
          idStepProgresses: ids.stepProgresses as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteStepProgressFamilyActions(stepProgressFamily: StepProgressFamilyEntityState): Action[] {
  const actions: Action[] = [
    stepProgressFamilyActions.deleteOneStepProgressFamilySuccess({
      idStepProgressFamily: stepProgressFamily.idStepProgressFamily
    })
  ];

  if (stepProgressFamily.stepProgresses) {
    actions.push(
      stepProgressActions.deleteManyStepProgressFamilySuccess({
        idStepProgressesFamilies: [stepProgressFamily.idStepProgressFamily],
        idStepProgresses: stepProgressFamily.stepProgresses as number[]
      })
    );
  }

  return actions;
}

export class GeneratedStepProgressFamilyEffects {
  constructor(
    protected actions$: Actions,
    protected stepProgressFamilyApiService: StepProgressFamilyApiService,
    protected store$: Store<AppState>
  ) {}

  getManyStepProgressesFamilies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stepProgressFamilyActions.getManyStepProgressesFamilies),
      switchMap(({ params }) =>
        this.stepProgressFamilyApiService.getStepProgressesFamilies(params).pipe(
          map((stepProgressesFamilies: StepProgressFamily[]) => {
            return stepProgressFamilyActions.normalizeManyStepProgressesFamiliesAfterUpsert({ stepProgressesFamilies });
          }),
          catchError(error => of(stepProgressFamilyActions.stepProgressesFamiliesFailure({ error })))
        )
      )
    );
  });

  getOneStepProgressFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stepProgressFamilyActions.getOneStepProgressFamily),
      switchMap(idStepProgressFamily =>
        this.stepProgressFamilyApiService.getStepProgressFamily(idStepProgressFamily).pipe(
          map((stepProgressFamily: StepProgressFamily) => {
            return stepProgressFamilyActions.normalizeManyStepProgressesFamiliesAfterUpsert({
              stepProgressesFamilies: [stepProgressFamily]
            });
          }),
          catchError(error => of(stepProgressFamilyActions.stepProgressesFamiliesFailure({ error })))
        )
      )
    );
  });

  upsertOneStepProgressFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stepProgressFamilyActions.upsertOneStepProgressFamily),
      concatMap(
        ({
          stepProgressFamily,
          ids
        }: {
          stepProgressFamily: Partial<StepProgressFamily>;
          ids?: StepProgressFamilyRelationsIds;
        }) => {
          if (stepProgressFamily.idStepProgressFamily) {
            return this.stepProgressFamilyApiService.updateStepProgressFamily(stepProgressFamily).pipe(
              map((stepProgressFamilyReturned: StepProgressFamily) => {
                return stepProgressFamilyActions.normalizeManyStepProgressesFamiliesAfterUpsert({
                  stepProgressesFamilies: [stepProgressFamilyReturned]
                });
              }),
              catchError(error => of(stepProgressFamilyActions.stepProgressesFamiliesFailure({ error })))
            );
          } else {
            return this.stepProgressFamilyApiService.addStepProgressFamily(stepProgressFamily).pipe(
              mergeMap((stepProgressFamilyReturned: StepProgressFamily) =>
                getDefaultAddStepProgressFamilyActions(stepProgressFamilyReturned, ids)
              ),
              catchError(error => of(stepProgressFamilyActions.stepProgressesFamiliesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneStepProgressFamily$ = createEffect(() => {
    const selectStepProgressFamilyState$ = this.store$.select(selectStepProgressFamilyState);
    return this.actions$.pipe(
      ofType(stepProgressFamilyActions.deleteOneStepProgressFamily),
      withLatestFrom(selectStepProgressFamilyState$),
      concatMap(([{ idStepProgressFamily }, state]) =>
        this.stepProgressFamilyApiService.deleteStepProgressFamily(idStepProgressFamily).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteStepProgressFamilyActions(
                state.entities[idStepProgressFamily] as StepProgressFamilyEntityState
              ),
              stepProgressFamilyActions.deleteOneStepProgressFamily.type
            )
          ]),
          catchError(error => of(stepProgressFamilyActions.stepProgressesFamiliesFailure({ error })))
        )
      )
    );
  });

  normalizeManyStepProgressesFamiliesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stepProgressFamilyActions.normalizeManyStepProgressesFamiliesAfterUpsert),
      concatMap(({ stepProgressesFamilies }) => {
        const actions: Action[] = getActionsToNormalizeStepProgressFamily(
          stepProgressesFamilies,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[StepProgressFamily] Normalization After Upsert Success')];
      })
    );
  });
}
