import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ProspectBuyingWish, ProspectBuyingWishEntityState } from '@_model/interfaces/prospect-buying-wish.model';
import { ProspectBuyingWishRelationsIds } from './prospect-buying-wish-generated.effects';

export const getOneProspectBuyingWish = createAction(
  '[ProspectBuyingWish] Get One ProspectBuyingWish',
  props<{ idProspectBuyingWish: number; params?: any }>()
);

export const getManyProspectBuyingWishs = createAction(
  '[ProspectBuyingWish] Get Many ProspectBuyingWishs',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ProspectBuyingWish] Add Many Actives ProspectBuyingWish',
  props<{ idProspectBuyingWishs: number[] }>()
);

export const deleteOneActive = createAction(
  '[ProspectBuyingWish] Delete One Active ProspectBuyingWish',
  props<{ idProspectBuyingWish: number }>()
);

export const clearActive = createAction('[ProspectBuyingWish] Clear Active ProspectBuyingWish');

export const upsertOneProspectBuyingWish = createAction(
  '[ProspectBuyingWish] Upsert One ProspectBuyingWish',
  props<{
    prospectBuyingWish: Partial<ProspectBuyingWish>;
    ids?: ProspectBuyingWishRelationsIds;
  }>()
);

export const upsertManyProspectBuyingWishs = createAction(
  '[ProspectBuyingWish] Upsert Many ProspectBuyingWishs',
  props<{
    prospectBuyingWishs: Partial<ProspectBuyingWish>[];
    ids?: ProspectBuyingWishRelationsIds;
  }>()
);

export const upsertManyProspectBuyingWishsSuccess = createAction(
  '[ProspectBuyingWish] Create Many ProspectBuyingWishs Success',
  props<{ prospectBuyingWishs: ProspectBuyingWishEntityState[] }>()
);

export const deleteOneProspectBuyingWish = createAction(
  '[ProspectBuyingWish] Delete One ProspectBuyingWish',
  props<{ idProspectBuyingWish: number }>()
);

export const deleteOneProspectBuyingWishSuccess = createAction(
  '[ProspectBuyingWish] Delete One ProspectBuyingWish Success',
  props<{ idProspectBuyingWish: number }>()
);

export const normalizeManyProspectBuyingWishsAfterUpsert = createAction(
  '[ProspectBuyingWish] Normalize Many ProspectBuyingWishs After Upsert',
  props<{ prospectBuyingWishs: ProspectBuyingWishEntityState[] }>()
);

export const prospectBuyingWishsFailure = createAction(
  '[ProspectBuyingWish] ProspectBuyingWishs Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ProspectBuyingWish] Clear ProspectBuyingWishs');

export const addManyCitySuccess = createAction(
  '[ProspectBuyingWish] Add Many city',
  props<{ idProspectBuyingWish: number; idCities: number[] }>()
);

export const deleteManyCitySuccess = createAction(
  '[ProspectBuyingWish] Delete Many Cities',
  props<{ idCities: number[]; idProspectBuyingWishs: number[] }>()
);

export const addManyResidenceSuccess = createAction(
  '[ProspectBuyingWish] Add Many residence',
  props<{ idProspectBuyingWish: number; idResidences: number[] }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[ProspectBuyingWish] Delete Many Residences',
  props<{ idResidences: number[]; idProspectBuyingWishs: number[] }>()
);

export const addManyStratalotTypeSuccess = createAction(
  '[ProspectBuyingWish] Add Many stratalot-type',
  props<{ idProspectBuyingWish: number; idStratalotTypes: number[] }>()
);

export const deleteManyStratalotTypeSuccess = createAction(
  '[ProspectBuyingWish] Delete Many StratalotTypes',
  props<{ idStratalotTypes: number[]; idProspectBuyingWishs: number[] }>()
);

export const addProspectSuccess = createAction(
  '[ProspectBuyingWish] Add Prospect',
  props<{ idProspectBuyingWish: number; idProspect: number }>()
);

export const deleteManyProspectSuccess = createAction(
  '[ProspectBuyingWish] Delete Many Prospect',
  props<{ idProspects: number[]; idProspectBuyingWishs: number[] }>()
);

export const addOrganizationBuyingWishSuccess = createAction(
  '[ProspectBuyingWish] Add OrganizationBuyingWish',
  props<{ idProspectBuyingWish: number; idOrganizationBuyingWish: number }>()
);

export const deleteManyOrganizationBuyingWishSuccess = createAction(
  '[ProspectBuyingWish] Delete Many OrganizationBuyingWish',
  props<{ idOrganizationBuyingWishes: number[]; idProspectBuyingWishs: number[] }>()
);
