import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { ResidenceStudyTodo, ResidenceStudyTodoEntityState } from '@_model/interfaces/residence-study-todo.model';
import { ResidenceStudyTodoApiService } from '@_services/api/residence-study-todo-api.service';
import * as residenceStudyTodoActions from '@_store/residence-study-todo/residence-study-todo.actions';
import { getActionsToNormalizeResidenceStudyTodo } from '@_config/store/normalization.generated';
import { selectResidenceStudyTodoState } from './residence-study-todo-generated.selectors';
import * as organizationResidenceStudyTodoActions from '@_store/organization-residence-study-todo/organization-residence-study-todo.actions';
import * as residenceStudyActions from '@_store/residence-study/residence-study.actions';

export interface ResidenceStudyTodoRelationsIds {
  organizationResidenceStudyTodo?: number;
  residenceStudy?: number;
}

export function getDefaultAddResidenceStudyTodoActions(
  residenceStudyTodo: ResidenceStudyTodoEntityState,
  ids?: ResidenceStudyTodoRelationsIds
): Action[] {
  const actions: Action[] = [
    residenceStudyTodoActions.normalizeManyResidenceStudyTodosAfterUpsert({ residenceStudyTodos: [residenceStudyTodo] })
  ];

  if (ids?.organizationResidenceStudyTodo) {
    actions.push(
      organizationResidenceStudyTodoActions.addManyResidenceStudyTodoSuccess({
        idOrganizationResidenceStudyTodo: ids.organizationResidenceStudyTodo,
        idResidenceStudyTodos: [residenceStudyTodo.idResidenceStudyTodo]
      })
    );
    actions.push(
      residenceStudyTodoActions.addOrganizationResidenceStudyTodoSuccess({
        idResidenceStudyTodo: residenceStudyTodo.idResidenceStudyTodo,
        idOrganizationResidenceStudyTodo: ids.organizationResidenceStudyTodo
      })
    );
  }

  if (ids?.residenceStudy) {
    actions.push(
      residenceStudyActions.addManyResidenceStudyTodoSuccess({
        idResidenceStudy: ids.residenceStudy,
        idResidenceStudyTodos: [residenceStudyTodo.idResidenceStudyTodo]
      })
    );
    actions.push(
      residenceStudyTodoActions.addResidenceStudySuccess({
        idResidenceStudyTodo: residenceStudyTodo.idResidenceStudyTodo,
        idResidenceStudy: ids.residenceStudy
      })
    );
  }

  return actions;
}

export function getDefaultDeleteResidenceStudyTodoActions(residenceStudyTodo: ResidenceStudyTodoEntityState): Action[] {
  const actions: Action[] = [
    residenceStudyTodoActions.deleteOneResidenceStudyTodoSuccess({
      idResidenceStudyTodo: residenceStudyTodo.idResidenceStudyTodo
    })
  ];

  if (residenceStudyTodo.organizationResidenceStudyTodo) {
    actions.push(
      organizationResidenceStudyTodoActions.deleteManyResidenceStudyTodoSuccess({
        idResidenceStudyTodos: [residenceStudyTodo.idResidenceStudyTodo],
        idOrganizationResidenceStudyTodos: [residenceStudyTodo.organizationResidenceStudyTodo as number]
      })
    );
  }

  if (residenceStudyTodo.residenceStudy) {
    actions.push(
      residenceStudyActions.deleteManyResidenceStudyTodoSuccess({
        idResidenceStudyTodos: [residenceStudyTodo.idResidenceStudyTodo],
        idResidenceStudies: [residenceStudyTodo.residenceStudy as number]
      })
    );
  }

  return actions;
}

export class GeneratedResidenceStudyTodoEffects {
  constructor(
    protected actions$: Actions,
    protected residenceStudyTodoApiService: ResidenceStudyTodoApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidenceStudyTodos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyTodoActions.getManyResidenceStudyTodos),
      switchMap(({ params }) =>
        this.residenceStudyTodoApiService.getResidenceStudyTodos(params).pipe(
          map((residenceStudyTodos: ResidenceStudyTodo[]) => {
            return residenceStudyTodoActions.normalizeManyResidenceStudyTodosAfterUpsert({ residenceStudyTodos });
          }),
          catchError(error => of(residenceStudyTodoActions.residenceStudyTodosFailure({ error })))
        )
      )
    );
  });

  getOneResidenceStudyTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyTodoActions.getOneResidenceStudyTodo),
      switchMap(idResidenceStudyTodo =>
        this.residenceStudyTodoApiService.getResidenceStudyTodo(idResidenceStudyTodo).pipe(
          map((residenceStudyTodo: ResidenceStudyTodo) => {
            return residenceStudyTodoActions.normalizeManyResidenceStudyTodosAfterUpsert({
              residenceStudyTodos: [residenceStudyTodo]
            });
          }),
          catchError(error => of(residenceStudyTodoActions.residenceStudyTodosFailure({ error })))
        )
      )
    );
  });

  upsertOneResidenceStudyTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyTodoActions.upsertOneResidenceStudyTodo),
      concatMap(
        ({
          residenceStudyTodo,
          ids
        }: {
          residenceStudyTodo: Partial<ResidenceStudyTodo>;
          ids?: ResidenceStudyTodoRelationsIds;
        }) => {
          if (residenceStudyTodo.idResidenceStudyTodo) {
            return this.residenceStudyTodoApiService.updateResidenceStudyTodo(residenceStudyTodo).pipe(
              map((residenceStudyTodoReturned: ResidenceStudyTodo) => {
                return residenceStudyTodoActions.normalizeManyResidenceStudyTodosAfterUpsert({
                  residenceStudyTodos: [residenceStudyTodoReturned]
                });
              }),
              catchError(error => of(residenceStudyTodoActions.residenceStudyTodosFailure({ error })))
            );
          } else {
            return this.residenceStudyTodoApiService.addResidenceStudyTodo(residenceStudyTodo).pipe(
              mergeMap((residenceStudyTodoReturned: ResidenceStudyTodo) =>
                getDefaultAddResidenceStudyTodoActions(residenceStudyTodoReturned, ids)
              ),
              catchError(error => of(residenceStudyTodoActions.residenceStudyTodosFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneResidenceStudyTodo$ = createEffect(() => {
    const selectResidenceStudyTodoState$ = this.store$.select(selectResidenceStudyTodoState);
    return this.actions$.pipe(
      ofType(residenceStudyTodoActions.deleteOneResidenceStudyTodo),
      withLatestFrom(selectResidenceStudyTodoState$),
      concatMap(([{ idResidenceStudyTodo }, state]) =>
        this.residenceStudyTodoApiService.deleteResidenceStudyTodo(idResidenceStudyTodo).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidenceStudyTodoActions(
                state.entities[idResidenceStudyTodo] as ResidenceStudyTodoEntityState
              ),
              residenceStudyTodoActions.deleteOneResidenceStudyTodo.type
            )
          ]),
          catchError(error => of(residenceStudyTodoActions.residenceStudyTodosFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidenceStudyTodosAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyTodoActions.normalizeManyResidenceStudyTodosAfterUpsert),
      concatMap(({ residenceStudyTodos }) => {
        const actions: Action[] = getActionsToNormalizeResidenceStudyTodo(residenceStudyTodos, StoreActionType.upsert);
        return [getMultiAction(actions, '[ResidenceStudyTodo] Normalization After Upsert Success')];
      })
    );
  });
}
