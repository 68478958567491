import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ForecastPeriod, ForecastPeriodEntityState } from '@_model/interfaces/forecast-period.model';
import { ForecastPeriodRelationsIds } from './forecast-period-generated.effects';

export const getOneForecastPeriod = createAction(
  '[ForecastPeriod] Get One ForecastPeriod',
  props<{ idForecastPeriod: number; params?: any }>()
);

export const getManyForecastPeriods = createAction(
  '[ForecastPeriod] Get Many ForecastPeriods',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ForecastPeriod] Add Many Actives ForecastPeriod',
  props<{ idForecastPeriods: number[] }>()
);

export const deleteOneActive = createAction(
  '[ForecastPeriod] Delete One Active ForecastPeriod',
  props<{ idForecastPeriod: number }>()
);

export const clearActive = createAction('[ForecastPeriod] Clear Active ForecastPeriod');

export const upsertOneForecastPeriod = createAction(
  '[ForecastPeriod] Upsert One ForecastPeriod',
  props<{
    forecastPeriod: Partial<ForecastPeriod>;
    ids?: ForecastPeriodRelationsIds;
  }>()
);

export const upsertManyForecastPeriods = createAction(
  '[ForecastPeriod] Upsert Many ForecastPeriods',
  props<{
    forecastPeriods: Partial<ForecastPeriod>[];
    ids?: ForecastPeriodRelationsIds;
  }>()
);

export const upsertManyForecastPeriodsSuccess = createAction(
  '[ForecastPeriod] Create Many ForecastPeriods Success',
  props<{ forecastPeriods: ForecastPeriodEntityState[] }>()
);

export const deleteOneForecastPeriod = createAction(
  '[ForecastPeriod] Delete One ForecastPeriod',
  props<{ idForecastPeriod: number }>()
);

export const deleteOneForecastPeriodSuccess = createAction(
  '[ForecastPeriod] Delete One ForecastPeriod Success',
  props<{ idForecastPeriod: number }>()
);

export const normalizeManyForecastPeriodsAfterUpsert = createAction(
  '[ForecastPeriod] Normalize Many ForecastPeriods After Upsert',
  props<{ forecastPeriods: ForecastPeriodEntityState[] }>()
);

export const forecastPeriodsFailure = createAction(
  '[ForecastPeriod] ForecastPeriods Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ForecastPeriod] Clear ForecastPeriods');

export const addManyResidenceForecastSuccess = createAction(
  '[ForecastPeriod] Add Many residence-forecast',
  props<{ idForecastPeriod: number; idResidenceForecasts: number[] }>()
);

export const deleteManyResidenceForecastSuccess = createAction(
  '[ForecastPeriod] Delete Many ResidenceForecasts',
  props<{ idResidenceForecasts: number[]; idForecastPeriods: number[] }>()
);
