import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { ProspectEvent, ProspectEventEntityState } from '@_model/interfaces/prospect-event.model';
import { ProspectEventApiService } from '@_services/api/prospect-event-api.service';
import * as prospectEventActions from '@_store/prospect-event/prospect-event.actions';
import { getActionsToNormalizeProspectEvent } from '@_config/store/normalization.generated';
import { selectProspectEventState } from './prospect-event-generated.selectors';
import * as prospectActions from '@_store/prospect/prospect.actions';
import * as leadActions from '@_store/lead/lead.actions';
import * as userActions from '@_store/user/user.actions';

export interface ProspectEventRelationsIds {
  prospect?: number;
  lead?: number;
  user?: number;
}

export function getDefaultAddProspectEventActions(
  prospectEvent: ProspectEventEntityState,
  ids?: ProspectEventRelationsIds
): Action[] {
  const actions: Action[] = [
    prospectEventActions.normalizeManyProspectEventsAfterUpsert({ prospectEvents: [prospectEvent] })
  ];

  if (ids?.prospect) {
    actions.push(
      prospectActions.addManyProspectEventSuccess({
        idProspect: ids.prospect,
        idProspectEvents: [prospectEvent.idProspectEvent]
      })
    );
    actions.push(
      prospectEventActions.addProspectSuccess({
        idProspectEvent: prospectEvent.idProspectEvent,
        idProspect: ids.prospect
      })
    );
  }

  if (ids?.lead) {
    actions.push(
      leadActions.addManyProspectEventSuccess({
        idLead: ids.lead,
        idProspectEvents: [prospectEvent.idProspectEvent]
      })
    );
    actions.push(
      prospectEventActions.addLeadSuccess({
        idProspectEvent: prospectEvent.idProspectEvent,
        idLead: ids.lead
      })
    );
  }

  if (ids?.user) {
    actions.push(
      userActions.addManyProspectEventSuccess({
        idUser: ids.user,
        idProspectEvents: [prospectEvent.idProspectEvent]
      })
    );
    actions.push(
      prospectEventActions.addUserSuccess({
        idProspectEvent: prospectEvent.idProspectEvent,
        idUser: ids.user
      })
    );
  }

  return actions;
}

export function getDefaultDeleteProspectEventActions(prospectEvent: ProspectEventEntityState): Action[] {
  const actions: Action[] = [
    prospectEventActions.deleteOneProspectEventSuccess({ idProspectEvent: prospectEvent.idProspectEvent })
  ];

  if (prospectEvent.prospect) {
    actions.push(
      prospectActions.deleteManyProspectEventSuccess({
        idProspectEvents: [prospectEvent.idProspectEvent],
        idProspects: [prospectEvent.prospect as number]
      })
    );
  }

  if (prospectEvent.lead) {
    actions.push(
      leadActions.deleteManyProspectEventSuccess({
        idProspectEvents: [prospectEvent.idProspectEvent],
        idLeads: [prospectEvent.lead as number]
      })
    );
  }

  if (prospectEvent.user) {
    actions.push(
      userActions.deleteManyProspectEventSuccess({
        idProspectEvents: [prospectEvent.idProspectEvent],
        idUsers: [prospectEvent.user as number]
      })
    );
  }

  return actions;
}

export class GeneratedProspectEventEffects {
  constructor(
    protected actions$: Actions,
    protected prospectEventApiService: ProspectEventApiService,
    protected store$: Store<AppState>
  ) {}

  getManyProspectEvents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(prospectEventActions.getManyProspectEvents),
      switchMap(({ params }) =>
        this.prospectEventApiService.getProspectEvents(params).pipe(
          map((prospectEvents: ProspectEvent[]) => {
            return prospectEventActions.normalizeManyProspectEventsAfterUpsert({ prospectEvents });
          }),
          catchError(error => of(prospectEventActions.prospectEventsFailure({ error })))
        )
      )
    );
  });

  getOneProspectEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(prospectEventActions.getOneProspectEvent),
      switchMap(idProspectEvent =>
        this.prospectEventApiService.getProspectEvent(idProspectEvent).pipe(
          map((prospectEvent: ProspectEvent) => {
            return prospectEventActions.normalizeManyProspectEventsAfterUpsert({ prospectEvents: [prospectEvent] });
          }),
          catchError(error => of(prospectEventActions.prospectEventsFailure({ error })))
        )
      )
    );
  });

  upsertOneProspectEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(prospectEventActions.upsertOneProspectEvent),
      concatMap(
        ({ prospectEvent, ids }: { prospectEvent: Partial<ProspectEvent>; ids?: ProspectEventRelationsIds }) => {
          if (prospectEvent.idProspectEvent) {
            return this.prospectEventApiService.updateProspectEvent(prospectEvent).pipe(
              map((prospectEventReturned: ProspectEvent) => {
                return prospectEventActions.normalizeManyProspectEventsAfterUpsert({
                  prospectEvents: [prospectEventReturned]
                });
              }),
              catchError(error => of(prospectEventActions.prospectEventsFailure({ error })))
            );
          } else {
            return this.prospectEventApiService.addProspectEvent(prospectEvent).pipe(
              mergeMap((prospectEventReturned: ProspectEvent) =>
                getDefaultAddProspectEventActions(prospectEventReturned, ids)
              ),
              catchError(error => of(prospectEventActions.prospectEventsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneProspectEvent$ = createEffect(() => {
    const selectProspectEventState$ = this.store$.select(selectProspectEventState);
    return this.actions$.pipe(
      ofType(prospectEventActions.deleteOneProspectEvent),
      withLatestFrom(selectProspectEventState$),
      concatMap(([{ idProspectEvent }, state]) =>
        this.prospectEventApiService.deleteProspectEvent(idProspectEvent).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteProspectEventActions(state.entities[idProspectEvent] as ProspectEventEntityState),
              prospectEventActions.deleteOneProspectEvent.type
            )
          ]),
          catchError(error => of(prospectEventActions.prospectEventsFailure({ error })))
        )
      )
    );
  });

  normalizeManyProspectEventsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(prospectEventActions.normalizeManyProspectEventsAfterUpsert),
      concatMap(({ prospectEvents }) => {
        const actions: Action[] = getActionsToNormalizeProspectEvent(prospectEvents, StoreActionType.upsert);
        return [getMultiAction(actions, '[ProspectEvent] Normalization After Upsert Success')];
      })
    );
  });
}
