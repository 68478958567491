import { RepositoryService } from '@_services/api/repository.service';
import { CompanyCommunicationResidenceTodo } from '@_model/interfaces/company-communication-residence-todo.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyCommunicationResidenceTodoApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyCommunicationResidenceTodos(params?: any): Observable<CompanyCommunicationResidenceTodo[]> {
    return this.repo.getData<CompanyCommunicationResidenceTodo[]>('company-communication-residence-todo', params);
  }

  public getCompanyCommunicationResidenceTodo(params: {
    idCompanyCommunicationResidenceTodo: number;
    params?: any;
  }): Observable<CompanyCommunicationResidenceTodo> {
    return this.repo.getData<CompanyCommunicationResidenceTodo>(
      'company-communication-residence-todo/' + params.idCompanyCommunicationResidenceTodo,
      params.params
    );
  }

  public addCompanyCommunicationResidenceTodo(
    companyCommunicationResidenceTodo: Partial<CompanyCommunicationResidenceTodo>
  ): Observable<CompanyCommunicationResidenceTodo> {
    return this.repo.create<CompanyCommunicationResidenceTodo>(
      'company-communication-residence-todo',
      companyCommunicationResidenceTodo
    );
  }

  public updateCompanyCommunicationResidenceTodo(
    companyCommunicationResidenceTodo: Partial<CompanyCommunicationResidenceTodo>
  ): Observable<CompanyCommunicationResidenceTodo> {
    return this.repo.update('company-communication-residence-todo', companyCommunicationResidenceTodo);
  }

  public deleteCompanyCommunicationResidenceTodo(idCompanyCommunicationResidenceTodo: number): Observable<number> {
    return this.repo.delete('company-communication-residence-todo/' + +idCompanyCommunicationResidenceTodo);
  }
}
