import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Droit, DroitEntityState } from '@_model/interfaces/droit.model';
import { Profil, ProfilEntityState } from '@_model/interfaces/profil.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, droitFeatureKey, DroitState } from './droit.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const droitRelations: string[] = ['profils'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectDroitState = createFeatureSelector<DroitState>(droitFeatureKey);

export const selectIsLoadedDroit = createSelector(selectDroitState, (state: DroitState) => state.isLoaded);

export const selectIsLoadingDroit = createSelector(selectDroitState, (state: DroitState) => state.isLoading);

export const selectIsReadyDroit = createSelector(selectDroitState, (state: DroitState) => !state.isLoading);

export const selectIsReadyAndLoadedDroit = createSelector(
  selectDroitState,
  (state: DroitState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const DroitModel: SelectorModel = {
  name: 'droits',
  getSelector: selectAllDroitsDictionary,
  isReady: selectIsReadyDroit
};

export const selectDroitsEntities = createSelector(selectDroitState, selectEntities);

export const selectDroitsArray = createSelector(selectDroitState, selectAll);

export const selectIdDroitsActive = createSelector(selectDroitState, (state: DroitState) => state.actives);

const droitsInObject = (droits: Dictionary<DroitEntityState>) => ({ droits });

const selectDroitsEntitiesDictionary = createSelector(selectDroitsEntities, droitsInObject);

const selectAllDroitsObject = createSelector(selectDroitsEntities, droits => {
  return hydrateAll({ droits });
});

const selectOneDroitDictionary = (idDroit: number) =>
  createSelector(selectDroitsEntities, droits => {
    return { droits: { [idDroit]: droits[idDroit] } };
  });

const selectOneDroitDictionaryWithoutChild = (idDroit: number) =>
  createSelector(selectDroitsEntities, droits => {
    return { droit: droits[idDroit] };
  });

const selectActiveDroitsEntities = createSelector(
  selectIdDroitsActive,
  selectDroitsEntities,
  (actives: number[], droits: Dictionary<DroitEntityState>) => getDroitsFromActives(actives, droits)
);

function getDroitsFromActives(actives: number[], droits: Dictionary<DroitEntityState>): Dictionary<DroitEntityState> {
  return actives.reduce((acc, idActive) => {
    if (droits[idActive]) {
      acc[idActive] = droits[idActive];
    }
    return acc;
  }, {} as Dictionary<DroitEntityState>);
}

const selectAllDroitsSelectors: Dictionary<Selector> = {};
export function selectAllDroits(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Droit>(
      schema,
      selectAllDroitsSelectors,
      selectDroitsEntitiesDictionary,
      getRelationSelectors,
      droitRelations,
      hydrateAll,
      'droit'
    );
  } else {
    return selectAllDroitsObject;
  }
}

export function selectAllDroitsDictionary(schema: SelectSchema = {}, customKey: string = 'droits'): Selector {
  return createSelector(selectAllDroits(schema), result => {
    const res = { [customKey]: {} as Dictionary<DroitEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.droits.length; i++) {
      res[customKey][result.droits[i].idDroit] = result.droits[i];
    }
    return res;
  });
}

export function selectOneDroit(schema: SelectSchema = {}, idDroit: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneDroitDictionary(idDroit)];
    selectors.push(...getRelationSelectors(schema, droitRelations, 'droit'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneDroitDictionaryWithoutChild(idDroit);
  }
}

export function selectActiveDroits(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [createSelector(selectActiveDroitsEntities, droits => ({ droits }))];
  selectors.push(...getRelationSelectors(schema, droitRelations, 'droit'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  droits: Dictionary<DroitEntityState>;
  profils?: Dictionary<ProfilEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { droits: (Droit | null)[] } {
  const { droits, profils } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    droits: Object.keys(droits).map(idDroit => hydrate(droits[idDroit] as DroitEntityState, profils))
  };
}

function hydrateOne(...args: hydrateArgs[]): { droit: DroitEntityState | null } {
  const { droits, profils } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const droit = Object.values(droits)[0];
  return { droit: hydrate(droit as DroitEntityState, profils) };
}

function hydrate(droit: DroitEntityState, profilEntities?: Dictionary<ProfilEntityState>): Droit | null {
  if (!droit) {
    return null;
  }

  const droitHydrated: DroitEntityState = { ...droit };

  if (profilEntities) {
    droitHydrated.profils = ((droitHydrated.profils as number[]) || []).map(id => profilEntities[id]) as Profil[];
  } else {
    delete droitHydrated.profils;
  }

  return droitHydrated as Droit;
}
