import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationStratalotTodoAppliedRuleApiService } from '@_services/api/organization-stratalot-todo-applied-rule-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedOrganizationStratalotTodoAppliedRuleEffects } from './organization-stratalot-todo-applied-rule-generated.effects';

@Injectable()
export class OrganizationStratalotTodoAppliedRuleEffects extends GeneratedOrganizationStratalotTodoAppliedRuleEffects {
  constructor(
    actions$: Actions,
    organizationStratalotTodoAppliedRuleApiService: OrganizationStratalotTodoAppliedRuleApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationStratalotTodoAppliedRuleApiService, store$);
  }
}
