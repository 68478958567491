import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { ResidenceShare, ResidenceShareEntityState } from '@_model/interfaces/residence-share.model';
import { ResidenceShareApiService } from '@_services/api/residence-share-api.service';
import * as residenceShareActions from '@_store/residence-share/residence-share.actions';
import { getActionsToNormalizeResidenceShare } from '@_config/store/normalization.generated';
import { selectResidenceShareState } from './residence-share-generated.selectors';
import * as residenceActions from '@_store/residence/residence.actions';
import * as stratalotRcpActions from '@_store/stratalot-rcp/stratalot-rcp.actions';
import { StratalotRcp } from '@_model/interfaces/stratalot-rcp.model';

export interface ResidenceShareRelationsIds {
  stratalotRcps?: number | number[];
  residence?: number;
}

export function getDefaultAddResidenceShareActions(
  residenceShare: ResidenceShareEntityState,
  ids?: ResidenceShareRelationsIds
): Action[] {
  const actions: Action[] = [
    residenceShareActions.normalizeManyResidenceSharesAfterUpsert({ residenceShares: [residenceShare] })
  ];

  if (ids?.residence) {
    actions.push(
      residenceActions.addManyResidenceShareSuccess({
        idResidence: ids.residence,
        idResidenceShares: [residenceShare.idResidenceShare]
      })
    );
    actions.push(
      residenceShareActions.addResidenceSuccess({
        idResidenceShare: residenceShare.idResidenceShare,
        idResidence: ids.residence
      })
    );
  }

  if (ids?.stratalotRcps) {
    if (!Array.isArray(ids.stratalotRcps)) {
      actions.push(
        stratalotRcpActions.upsertOneStratalotRcp({
          stratalotRcp: {
            idResidenceShare: residenceShare.idResidenceShare,
            idStratalotRcp: ids.stratalotRcps as number
          } as StratalotRcp & any
        })
      );
      actions.push(
        residenceShareActions.addManyStratalotRcpSuccess({
          idResidenceShare: residenceShare.idResidenceShare,
          idStratalotRcps: [ids.stratalotRcps as number]
        })
      );
    } else {
      actions.push(
        stratalotRcpActions.upsertManyStratalotRcps({
          stratalotRcps: (ids.stratalotRcps as number[]).map((idStratalotRcp: number) => ({
            idResidenceShare: residenceShare.idResidenceShare,
            idStratalotRcp
          })) as StratalotRcp[] & any[]
        })
      );
      actions.push(
        residenceShareActions.addManyStratalotRcpSuccess({
          idResidenceShare: residenceShare.idResidenceShare,
          idStratalotRcps: ids.stratalotRcps as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteResidenceShareActions(residenceShare: ResidenceShareEntityState): Action[] {
  const actions: Action[] = [
    residenceShareActions.deleteOneResidenceShareSuccess({ idResidenceShare: residenceShare.idResidenceShare })
  ];

  if (residenceShare.residence) {
    actions.push(
      residenceActions.deleteManyResidenceShareSuccess({
        idResidenceShares: [residenceShare.idResidenceShare],
        idResidences: [residenceShare.residence as number]
      })
    );
  }

  if (residenceShare.stratalotRcps) {
    actions.push(
      stratalotRcpActions.deleteManyResidenceShareSuccess({
        idResidenceShares: [residenceShare.idResidenceShare],
        idStratalotRcps: residenceShare.stratalotRcps as number[]
      })
    );
  }

  return actions;
}

export class GeneratedResidenceShareEffects {
  constructor(
    protected actions$: Actions,
    protected residenceShareApiService: ResidenceShareApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidenceShares$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceShareActions.getManyResidenceShares),
      switchMap(({ params }) =>
        this.residenceShareApiService.getResidenceShares(params).pipe(
          map((residenceShares: ResidenceShare[]) => {
            return residenceShareActions.normalizeManyResidenceSharesAfterUpsert({ residenceShares });
          }),
          catchError(error => of(residenceShareActions.residenceSharesFailure({ error })))
        )
      )
    );
  });

  getOneResidenceShare$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceShareActions.getOneResidenceShare),
      switchMap(idResidenceShare =>
        this.residenceShareApiService.getResidenceShare(idResidenceShare).pipe(
          map((residenceShare: ResidenceShare) => {
            return residenceShareActions.normalizeManyResidenceSharesAfterUpsert({ residenceShares: [residenceShare] });
          }),
          catchError(error => of(residenceShareActions.residenceSharesFailure({ error })))
        )
      )
    );
  });

  upsertOneResidenceShare$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceShareActions.upsertOneResidenceShare),
      concatMap(
        ({ residenceShare, ids }: { residenceShare: Partial<ResidenceShare>; ids?: ResidenceShareRelationsIds }) => {
          if (residenceShare.idResidenceShare) {
            return this.residenceShareApiService.updateResidenceShare(residenceShare).pipe(
              map((residenceShareReturned: ResidenceShare) => {
                return residenceShareActions.normalizeManyResidenceSharesAfterUpsert({
                  residenceShares: [residenceShareReturned]
                });
              }),
              catchError(error => of(residenceShareActions.residenceSharesFailure({ error })))
            );
          } else {
            return this.residenceShareApiService.addResidenceShare(residenceShare).pipe(
              mergeMap((residenceShareReturned: ResidenceShare) =>
                getDefaultAddResidenceShareActions(residenceShareReturned, ids)
              ),
              catchError(error => of(residenceShareActions.residenceSharesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneResidenceShare$ = createEffect(() => {
    const selectResidenceShareState$ = this.store$.select(selectResidenceShareState);
    return this.actions$.pipe(
      ofType(residenceShareActions.deleteOneResidenceShare),
      withLatestFrom(selectResidenceShareState$),
      concatMap(([{ idResidenceShare }, state]) =>
        this.residenceShareApiService.deleteResidenceShare(idResidenceShare).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidenceShareActions(state.entities[idResidenceShare] as ResidenceShareEntityState),
              residenceShareActions.deleteOneResidenceShare.type
            )
          ]),
          catchError(error => of(residenceShareActions.residenceSharesFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidenceSharesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceShareActions.normalizeManyResidenceSharesAfterUpsert),
      concatMap(({ residenceShares }) => {
        const actions: Action[] = getActionsToNormalizeResidenceShare(residenceShares, StoreActionType.upsert);
        return [getMultiAction(actions, '[ResidenceShare] Normalization After Upsert Success')];
      })
    );
  });
}
