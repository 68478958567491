import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CityActions from './city.actions';
import { adapter, initialState, CityState } from './city.state';
import { Dictionary } from '@ngrx/entity';
import { CityEntityState } from '@_model/interfaces/city.model';

export const cityReducersGeneratedFunctions: ReducerTypes<CityState, readonly ActionCreator[]>[] = [
  on(CityActions.getOneCity, (state: CityState) => setLoadingsState(state, true)),
  on(CityActions.getManyCities, (state: CityState) => setLoadingsState(state, true)),
  on(CityActions.upsertOneCity, (state: CityState) => setLoadingsState(state, true)),

  on(CityActions.upsertManyCitiesSuccess, (state: CityState, { cities }) =>
    adapter.upsertMany(cities, setLoadingsState(state, false))
  ),
  on(CityActions.deleteOneCity, (state: CityState) => setLoadingsState(state, true)),
  on(CityActions.deleteOneCitySuccess, (state: CityState, { idCity }) =>
    adapter.removeOne(idCity, setLoadingsState(state, false))
  ),
  on(CityActions.clearActive, (state: CityState) => ({ ...state, actives: [] })),
  on(CityActions.addManyActives, (state: CityState, { idCities }) => ({
    ...state,
    actives: state.actives.concat(idCities)
  })),
  on(CityActions.deleteOneActive, (state: CityState, { idCity }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCity)
  })),

  on(CityActions.clearStore, () => initialState),

  on(CityActions.addManyProspectBuyingWishSuccess, (state: CityState, { idCity, idProspectBuyingWishs }) => {
    if (!state.entities[idCity]) {
      return state;
    }
    const prospectBuyingWishs = (state.entities[idCity]?.prospectBuyingWishs as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCity]: {
          ...state.entities[idCity],
          prospectBuyingWishs: prospectBuyingWishs.concat(
            idProspectBuyingWishs.filter(id => prospectBuyingWishs.indexOf(id) < 0)
          )
        }
      }
    };
  }),

  on(CityActions.deleteManyProspectBuyingWishSuccess, (state: CityState, { idProspectBuyingWishs, idCities }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idCities.reduce((entities, idCity) => {
          if (!state.entities[idCity]?.prospectBuyingWishs) {
            return entities;
          }
          entities[idCity] = {
            ...state.entities[idCity],
            prospectBuyingWishs: (state.entities[idCity]?.prospectBuyingWishs as number[])?.filter(
              (idProspectBuyingWish: number) => !idProspectBuyingWishs.some((id: number) => id === idProspectBuyingWish)
            )
          } as CityEntityState;
          return entities;
        }, {} as Dictionary<CityEntityState>)
      }
    };
  }),

  on(CityActions.addManyResidenceSuccess, (state: CityState, { idCity, idResidences }) => {
    if (!state.entities[idCity]) {
      return state;
    }
    const residences = (state.entities[idCity]?.residences as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCity]: {
          ...state.entities[idCity],
          residences: residences.concat(idResidences.filter(id => residences.indexOf(id) < 0))
        }
      }
    };
  }),

  on(CityActions.deleteManyResidenceSuccess, (state: CityState, { idResidences, idCities }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idCities.reduce((entities, idCity) => {
          if (!state.entities[idCity]?.residences) {
            return entities;
          }
          entities[idCity] = {
            ...state.entities[idCity],
            residences: (state.entities[idCity]?.residences as number[])?.filter(
              (idResidence: number) => !idResidences.some((id: number) => id === idResidence)
            )
          } as CityEntityState;
          return entities;
        }, {} as Dictionary<CityEntityState>)
      }
    };
  }),

  on(CityActions.addCompanySuccess, (state: CityState, { idCity, idCompany }) => {
    if (!state.entities[idCity]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCity]: {
          ...state.entities[idCity],
          company: idCompany
        }
      }
    };
  }),

  on(CityActions.deleteManyCompanySuccess, (state: CityState, { idCompanies, idCities }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idCities.reduce((entities, idCity) => {
          if (!state.entities[idCity]?.company) {
            return entities;
          }
          entities[idCity] = {
            ...state.entities[idCity],
            company: idCompanies.some((idCompany: number) => idCompany === state.entities[idCity]?.company)
              ? undefined
              : state.entities[idCity]?.company
          } as CityEntityState;
          return entities;
        }, {} as Dictionary<CityEntityState>)
      }
    };
  })
];

export function setLoadingsState(state: CityState, isLoading: boolean, isLoaded: boolean = true): CityState {
  return { ...state, isLoaded, isLoading };
}
