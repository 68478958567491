import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CompanyStudyReason, CompanyStudyReasonEntityState } from '@_model/interfaces/company-study-reason.model';
import { CompanyStudyReasonRelationsIds } from './company-study-reason-generated.effects';

export const getOneCompanyStudyReason = createAction(
  '[CompanyStudyReason] Get One CompanyStudyReason',
  props<{ idCompanyStudyReason: number; params?: any }>()
);

export const getManyCompanyStudyReasons = createAction(
  '[CompanyStudyReason] Get Many CompanyStudyReasons',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyStudyReason] Add Many Actives CompanyStudyReason',
  props<{ idCompanyStudyReasons: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyStudyReason] Delete One Active CompanyStudyReason',
  props<{ idCompanyStudyReason: number }>()
);

export const clearActive = createAction('[CompanyStudyReason] Clear Active CompanyStudyReason');

export const upsertOneCompanyStudyReason = createAction(
  '[CompanyStudyReason] Upsert One CompanyStudyReason',
  props<{
    companyStudyReason: Partial<CompanyStudyReason>;
    ids?: CompanyStudyReasonRelationsIds;
  }>()
);

export const upsertManyCompanyStudyReasons = createAction(
  '[CompanyStudyReason] Upsert Many CompanyStudyReasons',
  props<{
    companyStudyReasons: Partial<CompanyStudyReason>[];
    ids?: CompanyStudyReasonRelationsIds;
  }>()
);

export const upsertManyCompanyStudyReasonsSuccess = createAction(
  '[CompanyStudyReason] Create Many CompanyStudyReasons Success',
  props<{ companyStudyReasons: CompanyStudyReasonEntityState[] }>()
);

export const deleteOneCompanyStudyReason = createAction(
  '[CompanyStudyReason] Delete One CompanyStudyReason',
  props<{ idCompanyStudyReason: number }>()
);

export const deleteOneCompanyStudyReasonSuccess = createAction(
  '[CompanyStudyReason] Delete One CompanyStudyReason Success',
  props<{ idCompanyStudyReason: number }>()
);

export const normalizeManyCompanyStudyReasonsAfterUpsert = createAction(
  '[CompanyStudyReason] Normalize Many CompanyStudyReasons After Upsert',
  props<{ companyStudyReasons: CompanyStudyReasonEntityState[] }>()
);

export const companyStudyReasonsFailure = createAction(
  '[CompanyStudyReason] CompanyStudyReasons Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyStudyReason] Clear CompanyStudyReasons');

export const addManyResidenceStudyReasonSuccess = createAction(
  '[CompanyStudyReason] Add Many residence-study-reason',
  props<{ idCompanyStudyReason: number; idResidenceStudyReasons: number[] }>()
);

export const deleteManyResidenceStudyReasonSuccess = createAction(
  '[CompanyStudyReason] Delete Many ResidenceStudyReasons',
  props<{ idResidenceStudyReasons: number[]; idCompanyStudyReasons: number[] }>()
);

export const addManyResidenceStudySuccess = createAction(
  '[CompanyStudyReason] Add Many residence-study',
  props<{ idCompanyStudyReason: number; idResidenceStudies: number[] }>()
);

export const deleteManyResidenceStudySuccess = createAction(
  '[CompanyStudyReason] Delete Many ResidenceStudies',
  props<{ idResidenceStudies: number[]; idCompanyStudyReasons: number[] }>()
);

export const addCompanySuccess = createAction(
  '[CompanyStudyReason] Add Company',
  props<{ idCompanyStudyReason: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[CompanyStudyReason] Delete Many Company',
  props<{ idCompanies: number[]; idCompanyStudyReasons: number[] }>()
);
