import { CompanyStratalotVacantState, initialState } from './company-stratalot-vacant.state';
import { Action, createReducer } from '@ngrx/store';
import { companyStratalotVacantReducersGeneratedFunctions } from './company-stratalot-vacant-generated.reducer';

const companyStratalotVacantReducersFunctions = [...companyStratalotVacantReducersGeneratedFunctions];

const companyStratalotVacantReducer = createReducer(initialState, ...companyStratalotVacantReducersFunctions);

export function reducer(state: CompanyStratalotVacantState | undefined, action: Action) {
  return companyStratalotVacantReducer(state, action);
}
