import { RepositoryService } from '@_services/api/repository.service';
import { ResidencePriceGridHistory } from '@_model/interfaces/residence-price-grid-history.model';
import { Observable } from 'rxjs';

export class GeneratedResidencePriceGridHistoryApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidencePriceGridHistories(params?: any): Observable<ResidencePriceGridHistory[]> {
    return this.repo.getData<ResidencePriceGridHistory[]>('residence-price-grid-history', params);
  }

  public getResidencePriceGridHistory(params: {
    idResidencePriceGridHistory: number;
    params?: any;
  }): Observable<ResidencePriceGridHistory> {
    return this.repo.getData<ResidencePriceGridHistory>(
      'residence-price-grid-history/' + params.idResidencePriceGridHistory,
      params.params
    );
  }

  public addResidencePriceGridHistory(
    residencePriceGridHistory: Partial<ResidencePriceGridHistory>
  ): Observable<ResidencePriceGridHistory> {
    return this.repo.create<ResidencePriceGridHistory>('residence-price-grid-history', residencePriceGridHistory);
  }

  public updateResidencePriceGridHistory(
    residencePriceGridHistory: Partial<ResidencePriceGridHistory>
  ): Observable<ResidencePriceGridHistory> {
    return this.repo.update('residence-price-grid-history', residencePriceGridHistory);
  }

  public deleteResidencePriceGridHistory(idResidencePriceGridHistory: number): Observable<number> {
    return this.repo.delete('residence-price-grid-history/' + +idResidencePriceGridHistory);
  }
}
