import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  CompanyCommunicationResidenceTodo,
  CompanyCommunicationResidenceTodoEntityState
} from '@_model/interfaces/company-communication-residence-todo.model';
import { CompanyCommunicationResidenceTodoApiService } from '@_services/api/company-communication-residence-todo-api.service';
import * as companyCommunicationResidenceTodoActions from '@_store/company-communication-residence-todo/company-communication-residence-todo.actions';
import { getActionsToNormalizeCompanyCommunicationResidenceTodo } from '@_config/store/normalization.generated';
import { selectCompanyCommunicationResidenceTodoState } from './company-communication-residence-todo-generated.selectors';
import * as companyCommunicationActions from '@_store/company-communication/company-communication.actions';
import * as organizationResidenceTodoActions from '@_store/organization-residence-todo/organization-residence-todo.actions';

export interface CompanyCommunicationResidenceTodoRelationsIds {
  companyCommunication?: number;
  organizationResidenceTodo?: number;
}

export function getDefaultAddCompanyCommunicationResidenceTodoActions(
  companyCommunicationResidenceTodo: CompanyCommunicationResidenceTodoEntityState,
  ids?: CompanyCommunicationResidenceTodoRelationsIds
): Action[] {
  const actions: Action[] = [
    companyCommunicationResidenceTodoActions.normalizeManyCompanyCommunicationResidenceTodosAfterUpsert({
      companyCommunicationResidenceTodos: [companyCommunicationResidenceTodo]
    })
  ];

  if (ids?.companyCommunication) {
    actions.push(
      companyCommunicationActions.addManyCompanyCommunicationResidenceTodoSuccess({
        idCompanyCommunication: ids.companyCommunication,
        idCompanyCommunicationResidenceTodos: [companyCommunicationResidenceTodo.idCompanyCommunicationResidenceTodo]
      })
    );
    actions.push(
      companyCommunicationResidenceTodoActions.addCompanyCommunicationSuccess({
        idCompanyCommunicationResidenceTodo: companyCommunicationResidenceTodo.idCompanyCommunicationResidenceTodo,
        idCompanyCommunication: ids.companyCommunication
      })
    );
  }

  if (ids?.organizationResidenceTodo) {
    actions.push(
      organizationResidenceTodoActions.addManyCompanyCommunicationResidenceTodoSuccess({
        idOrganizationResidenceTodo: ids.organizationResidenceTodo,
        idCompanyCommunicationResidenceTodos: [companyCommunicationResidenceTodo.idCompanyCommunicationResidenceTodo]
      })
    );
    actions.push(
      companyCommunicationResidenceTodoActions.addOrganizationResidenceTodoSuccess({
        idCompanyCommunicationResidenceTodo: companyCommunicationResidenceTodo.idCompanyCommunicationResidenceTodo,
        idOrganizationResidenceTodo: ids.organizationResidenceTodo
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCompanyCommunicationResidenceTodoActions(
  companyCommunicationResidenceTodo: CompanyCommunicationResidenceTodoEntityState
): Action[] {
  const actions: Action[] = [
    companyCommunicationResidenceTodoActions.deleteOneCompanyCommunicationResidenceTodoSuccess({
      idCompanyCommunicationResidenceTodo: companyCommunicationResidenceTodo.idCompanyCommunicationResidenceTodo
    })
  ];

  if (companyCommunicationResidenceTodo.companyCommunication) {
    actions.push(
      companyCommunicationActions.deleteManyCompanyCommunicationResidenceTodoSuccess({
        idCompanyCommunicationResidenceTodos: [companyCommunicationResidenceTodo.idCompanyCommunicationResidenceTodo],
        idCompanyCommunications: [companyCommunicationResidenceTodo.companyCommunication as number]
      })
    );
  }

  if (companyCommunicationResidenceTodo.organizationResidenceTodo) {
    actions.push(
      organizationResidenceTodoActions.deleteManyCompanyCommunicationResidenceTodoSuccess({
        idCompanyCommunicationResidenceTodos: [companyCommunicationResidenceTodo.idCompanyCommunicationResidenceTodo],
        idOrganizationResidenceTodos: [companyCommunicationResidenceTodo.organizationResidenceTodo as number]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyCommunicationResidenceTodoEffects {
  constructor(
    protected actions$: Actions,
    protected companyCommunicationResidenceTodoApiService: CompanyCommunicationResidenceTodoApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyCommunicationResidenceTodos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationResidenceTodoActions.getManyCompanyCommunicationResidenceTodos),
      switchMap(({ params }) =>
        this.companyCommunicationResidenceTodoApiService.getCompanyCommunicationResidenceTodos(params).pipe(
          map((companyCommunicationResidenceTodos: CompanyCommunicationResidenceTodo[]) => {
            return companyCommunicationResidenceTodoActions.normalizeManyCompanyCommunicationResidenceTodosAfterUpsert({
              companyCommunicationResidenceTodos
            });
          }),
          catchError(error =>
            of(companyCommunicationResidenceTodoActions.companyCommunicationResidenceTodosFailure({ error }))
          )
        )
      )
    );
  });

  getOneCompanyCommunicationResidenceTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationResidenceTodoActions.getOneCompanyCommunicationResidenceTodo),
      switchMap(idCompanyCommunicationResidenceTodo =>
        this.companyCommunicationResidenceTodoApiService
          .getCompanyCommunicationResidenceTodo(idCompanyCommunicationResidenceTodo)
          .pipe(
            map((companyCommunicationResidenceTodo: CompanyCommunicationResidenceTodo) => {
              return companyCommunicationResidenceTodoActions.normalizeManyCompanyCommunicationResidenceTodosAfterUpsert(
                { companyCommunicationResidenceTodos: [companyCommunicationResidenceTodo] }
              );
            }),
            catchError(error =>
              of(companyCommunicationResidenceTodoActions.companyCommunicationResidenceTodosFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneCompanyCommunicationResidenceTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationResidenceTodoActions.upsertOneCompanyCommunicationResidenceTodo),
      concatMap(
        ({
          companyCommunicationResidenceTodo,
          ids
        }: {
          companyCommunicationResidenceTodo: Partial<CompanyCommunicationResidenceTodo>;
          ids?: CompanyCommunicationResidenceTodoRelationsIds;
        }) => {
          if (companyCommunicationResidenceTodo.idCompanyCommunicationResidenceTodo) {
            return this.companyCommunicationResidenceTodoApiService
              .updateCompanyCommunicationResidenceTodo(companyCommunicationResidenceTodo)
              .pipe(
                map((companyCommunicationResidenceTodoReturned: CompanyCommunicationResidenceTodo) => {
                  return companyCommunicationResidenceTodoActions.normalizeManyCompanyCommunicationResidenceTodosAfterUpsert(
                    { companyCommunicationResidenceTodos: [companyCommunicationResidenceTodoReturned] }
                  );
                }),
                catchError(error =>
                  of(companyCommunicationResidenceTodoActions.companyCommunicationResidenceTodosFailure({ error }))
                )
              );
          } else {
            return this.companyCommunicationResidenceTodoApiService
              .addCompanyCommunicationResidenceTodo(companyCommunicationResidenceTodo)
              .pipe(
                mergeMap((companyCommunicationResidenceTodoReturned: CompanyCommunicationResidenceTodo) =>
                  getDefaultAddCompanyCommunicationResidenceTodoActions(companyCommunicationResidenceTodoReturned, ids)
                ),
                catchError(error =>
                  of(companyCommunicationResidenceTodoActions.companyCommunicationResidenceTodosFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneCompanyCommunicationResidenceTodo$ = createEffect(() => {
    const selectCompanyCommunicationResidenceTodoState$ = this.store$.select(
      selectCompanyCommunicationResidenceTodoState
    );
    return this.actions$.pipe(
      ofType(companyCommunicationResidenceTodoActions.deleteOneCompanyCommunicationResidenceTodo),
      withLatestFrom(selectCompanyCommunicationResidenceTodoState$),
      concatMap(([{ idCompanyCommunicationResidenceTodo }, state]) =>
        this.companyCommunicationResidenceTodoApiService
          .deleteCompanyCommunicationResidenceTodo(idCompanyCommunicationResidenceTodo)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteCompanyCommunicationResidenceTodoActions(
                  state.entities[idCompanyCommunicationResidenceTodo] as CompanyCommunicationResidenceTodoEntityState
                ),
                companyCommunicationResidenceTodoActions.deleteOneCompanyCommunicationResidenceTodo.type
              )
            ]),
            catchError(error =>
              of(companyCommunicationResidenceTodoActions.companyCommunicationResidenceTodosFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyCompanyCommunicationResidenceTodosAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationResidenceTodoActions.normalizeManyCompanyCommunicationResidenceTodosAfterUpsert),
      concatMap(({ companyCommunicationResidenceTodos }) => {
        const actions: Action[] = getActionsToNormalizeCompanyCommunicationResidenceTodo(
          companyCommunicationResidenceTodos,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CompanyCommunicationResidenceTodo] Normalization After Upsert Success')];
      })
    );
  });
}
