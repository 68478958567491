import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationResidenceTodoProfil,
  OrganizationResidenceTodoProfilEntityState
} from '@_model/interfaces/organization-residence-todo-profil.model';
import { OrganizationResidenceTodoProfilApiService } from '@_services/api/organization-residence-todo-profil-api.service';
import * as organizationResidenceTodoProfilActions from '@_store/organization-residence-todo-profil/organization-residence-todo-profil.actions';
import { getActionsToNormalizeOrganizationResidenceTodoProfil } from '@_config/store/normalization.generated';
import { selectOrganizationResidenceTodoProfilState } from './organization-residence-todo-profil-generated.selectors';
import * as profilActions from '@_store/profil/profil.actions';
import * as organizationResidenceTodoActions from '@_store/organization-residence-todo/organization-residence-todo.actions';

export interface OrganizationResidenceTodoProfilRelationsIds {
  profil?: number;
  organizationResidenceTodo?: number;
}

export function getDefaultAddOrganizationResidenceTodoProfilActions(
  organizationResidenceTodoProfil: OrganizationResidenceTodoProfilEntityState,
  ids?: OrganizationResidenceTodoProfilRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationResidenceTodoProfilActions.normalizeManyOrganizationResidenceTodoProfilsAfterUpsert({
      organizationResidenceTodoProfils: [organizationResidenceTodoProfil]
    })
  ];

  if (ids?.profil) {
    actions.push(
      profilActions.addManyOrganizationResidenceTodoProfilSuccess({
        idProfil: ids.profil,
        idOrganizationResidenceTodoProfils: [organizationResidenceTodoProfil.idOrganizationResidenceTodoProfil]
      })
    );
    actions.push(
      organizationResidenceTodoProfilActions.addProfilSuccess({
        idOrganizationResidenceTodoProfil: organizationResidenceTodoProfil.idOrganizationResidenceTodoProfil,
        idProfil: ids.profil
      })
    );
  }

  if (ids?.organizationResidenceTodo) {
    actions.push(
      organizationResidenceTodoActions.addManyOrganizationResidenceTodoProfilSuccess({
        idOrganizationResidenceTodo: ids.organizationResidenceTodo,
        idOrganizationResidenceTodoProfils: [organizationResidenceTodoProfil.idOrganizationResidenceTodoProfil]
      })
    );
    actions.push(
      organizationResidenceTodoProfilActions.addOrganizationResidenceTodoSuccess({
        idOrganizationResidenceTodoProfil: organizationResidenceTodoProfil.idOrganizationResidenceTodoProfil,
        idOrganizationResidenceTodo: ids.organizationResidenceTodo
      })
    );
  }

  return actions;
}

export function getDefaultDeleteOrganizationResidenceTodoProfilActions(
  organizationResidenceTodoProfil: OrganizationResidenceTodoProfilEntityState
): Action[] {
  const actions: Action[] = [
    organizationResidenceTodoProfilActions.deleteOneOrganizationResidenceTodoProfilSuccess({
      idOrganizationResidenceTodoProfil: organizationResidenceTodoProfil.idOrganizationResidenceTodoProfil
    })
  ];

  if (organizationResidenceTodoProfil.profil) {
    actions.push(
      profilActions.deleteManyOrganizationResidenceTodoProfilSuccess({
        idOrganizationResidenceTodoProfils: [organizationResidenceTodoProfil.idOrganizationResidenceTodoProfil],
        idProfils: [organizationResidenceTodoProfil.profil as number]
      })
    );
  }

  if (organizationResidenceTodoProfil.organizationResidenceTodo) {
    actions.push(
      organizationResidenceTodoActions.deleteManyOrganizationResidenceTodoProfilSuccess({
        idOrganizationResidenceTodoProfils: [organizationResidenceTodoProfil.idOrganizationResidenceTodoProfil],
        idOrganizationResidenceTodos: [organizationResidenceTodoProfil.organizationResidenceTodo as number]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationResidenceTodoProfilEffects {
  constructor(
    protected actions$: Actions,
    protected organizationResidenceTodoProfilApiService: OrganizationResidenceTodoProfilApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationResidenceTodoProfils$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceTodoProfilActions.getManyOrganizationResidenceTodoProfils),
      switchMap(({ params }) =>
        this.organizationResidenceTodoProfilApiService.getOrganizationResidenceTodoProfils(params).pipe(
          map((organizationResidenceTodoProfils: OrganizationResidenceTodoProfil[]) => {
            return organizationResidenceTodoProfilActions.normalizeManyOrganizationResidenceTodoProfilsAfterUpsert({
              organizationResidenceTodoProfils
            });
          }),
          catchError(error =>
            of(organizationResidenceTodoProfilActions.organizationResidenceTodoProfilsFailure({ error }))
          )
        )
      )
    );
  });

  getOneOrganizationResidenceTodoProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceTodoProfilActions.getOneOrganizationResidenceTodoProfil),
      switchMap(idOrganizationResidenceTodoProfil =>
        this.organizationResidenceTodoProfilApiService
          .getOrganizationResidenceTodoProfil(idOrganizationResidenceTodoProfil)
          .pipe(
            map((organizationResidenceTodoProfil: OrganizationResidenceTodoProfil) => {
              return organizationResidenceTodoProfilActions.normalizeManyOrganizationResidenceTodoProfilsAfterUpsert({
                organizationResidenceTodoProfils: [organizationResidenceTodoProfil]
              });
            }),
            catchError(error =>
              of(organizationResidenceTodoProfilActions.organizationResidenceTodoProfilsFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneOrganizationResidenceTodoProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceTodoProfilActions.upsertOneOrganizationResidenceTodoProfil),
      concatMap(
        ({
          organizationResidenceTodoProfil,
          ids
        }: {
          organizationResidenceTodoProfil: Partial<OrganizationResidenceTodoProfil>;
          ids?: OrganizationResidenceTodoProfilRelationsIds;
        }) => {
          if (organizationResidenceTodoProfil.idOrganizationResidenceTodoProfil) {
            return this.organizationResidenceTodoProfilApiService
              .updateOrganizationResidenceTodoProfil(organizationResidenceTodoProfil)
              .pipe(
                map((organizationResidenceTodoProfilReturned: OrganizationResidenceTodoProfil) => {
                  return organizationResidenceTodoProfilActions.normalizeManyOrganizationResidenceTodoProfilsAfterUpsert(
                    { organizationResidenceTodoProfils: [organizationResidenceTodoProfilReturned] }
                  );
                }),
                catchError(error =>
                  of(organizationResidenceTodoProfilActions.organizationResidenceTodoProfilsFailure({ error }))
                )
              );
          } else {
            return this.organizationResidenceTodoProfilApiService
              .addOrganizationResidenceTodoProfil(organizationResidenceTodoProfil)
              .pipe(
                mergeMap((organizationResidenceTodoProfilReturned: OrganizationResidenceTodoProfil) =>
                  getDefaultAddOrganizationResidenceTodoProfilActions(organizationResidenceTodoProfilReturned, ids)
                ),
                catchError(error =>
                  of(organizationResidenceTodoProfilActions.organizationResidenceTodoProfilsFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationResidenceTodoProfil$ = createEffect(() => {
    const selectOrganizationResidenceTodoProfilState$ = this.store$.select(selectOrganizationResidenceTodoProfilState);
    return this.actions$.pipe(
      ofType(organizationResidenceTodoProfilActions.deleteOneOrganizationResidenceTodoProfil),
      withLatestFrom(selectOrganizationResidenceTodoProfilState$),
      concatMap(([{ idOrganizationResidenceTodoProfil }, state]) =>
        this.organizationResidenceTodoProfilApiService
          .deleteOrganizationResidenceTodoProfil(idOrganizationResidenceTodoProfil)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteOrganizationResidenceTodoProfilActions(
                  state.entities[idOrganizationResidenceTodoProfil] as OrganizationResidenceTodoProfilEntityState
                ),
                organizationResidenceTodoProfilActions.deleteOneOrganizationResidenceTodoProfil.type
              )
            ]),
            catchError(error =>
              of(organizationResidenceTodoProfilActions.organizationResidenceTodoProfilsFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyOrganizationResidenceTodoProfilsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceTodoProfilActions.normalizeManyOrganizationResidenceTodoProfilsAfterUpsert),
      concatMap(({ organizationResidenceTodoProfils }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationResidenceTodoProfil(
          organizationResidenceTodoProfils,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationResidenceTodoProfil] Normalization After Upsert Success')];
      })
    );
  });
}
