import { CampaignMediaState, initialState } from './campaign-media.state';
import { Action, createReducer } from '@ngrx/store';
import { campaignMediaReducersGeneratedFunctions } from './campaign-media-generated.reducer';

const campaignMediaReducersFunctions = [...campaignMediaReducersGeneratedFunctions];

const campaignMediaReducer = createReducer(initialState, ...campaignMediaReducersFunctions);

export function reducer(state: CampaignMediaState | undefined, action: Action) {
  return campaignMediaReducer(state, action);
}
