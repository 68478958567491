import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationProspectOriginApiService } from '@_services/api/organization-prospect-origin-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedOrganizationProspectOriginEffects } from './organization-prospect-origin-generated.effects';

@Injectable()
export class OrganizationProspectOriginEffects extends GeneratedOrganizationProspectOriginEffects {
  constructor(
    actions$: Actions,
    organizationProspectOriginApiService: OrganizationProspectOriginApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationProspectOriginApiService, store$);
  }
}
