import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationLeadTodoProfilActions from './organization-lead-todo-profil.actions';
import { adapter, initialState, OrganizationLeadTodoProfilState } from './organization-lead-todo-profil.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationLeadTodoProfilEntityState } from '@_model/interfaces/organization-lead-todo-profil.model';

export const organizationLeadTodoProfilReducersGeneratedFunctions: ReducerTypes<
  OrganizationLeadTodoProfilState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationLeadTodoProfilActions.getOneOrganizationLeadTodoProfil, (state: OrganizationLeadTodoProfilState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationLeadTodoProfilActions.getManyOrganizationLeadTodoProfils, (state: OrganizationLeadTodoProfilState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationLeadTodoProfilActions.upsertOneOrganizationLeadTodoProfil, (state: OrganizationLeadTodoProfilState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationLeadTodoProfilActions.upsertManyOrganizationLeadTodoProfilsSuccess,
    (state: OrganizationLeadTodoProfilState, { organizationLeadTodoProfils }) =>
      adapter.upsertMany(organizationLeadTodoProfils, setLoadingsState(state, false))
  ),
  on(OrganizationLeadTodoProfilActions.deleteOneOrganizationLeadTodoProfil, (state: OrganizationLeadTodoProfilState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationLeadTodoProfilActions.deleteOneOrganizationLeadTodoProfilSuccess,
    (state: OrganizationLeadTodoProfilState, { idOrganizationLeadTodoProfil }) =>
      adapter.removeOne(idOrganizationLeadTodoProfil, setLoadingsState(state, false))
  ),
  on(OrganizationLeadTodoProfilActions.clearActive, (state: OrganizationLeadTodoProfilState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationLeadTodoProfilActions.addManyActives,
    (state: OrganizationLeadTodoProfilState, { idOrganizationLeadTodoProfils }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationLeadTodoProfils)
    })
  ),
  on(
    OrganizationLeadTodoProfilActions.deleteOneActive,
    (state: OrganizationLeadTodoProfilState, { idOrganizationLeadTodoProfil }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationLeadTodoProfil)
    })
  ),

  on(OrganizationLeadTodoProfilActions.clearStore, () => initialState),

  on(
    OrganizationLeadTodoProfilActions.addProfilSuccess,
    (state: OrganizationLeadTodoProfilState, { idOrganizationLeadTodoProfil, idProfil }) => {
      if (!state.entities[idOrganizationLeadTodoProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodoProfil]: {
            ...state.entities[idOrganizationLeadTodoProfil],
            profil: idProfil
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoProfilActions.deleteManyProfilSuccess,
    (state: OrganizationLeadTodoProfilState, { idProfils, idOrganizationLeadTodoProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodoProfils.reduce((entities, idOrganizationLeadTodoProfil) => {
            if (!state.entities[idOrganizationLeadTodoProfil]?.profil) {
              return entities;
            }
            entities[idOrganizationLeadTodoProfil] = {
              ...state.entities[idOrganizationLeadTodoProfil],
              profil: idProfils.some(
                (idProfil: number) => idProfil === state.entities[idOrganizationLeadTodoProfil]?.profil
              )
                ? undefined
                : state.entities[idOrganizationLeadTodoProfil]?.profil
            } as OrganizationLeadTodoProfilEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoProfilEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoProfilActions.addOrganizationLeadTodoSuccess,
    (state: OrganizationLeadTodoProfilState, { idOrganizationLeadTodoProfil, idOrganizationLeadTodo }) => {
      if (!state.entities[idOrganizationLeadTodoProfil]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodoProfil]: {
            ...state.entities[idOrganizationLeadTodoProfil],
            organizationLeadTodo: idOrganizationLeadTodo
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoProfilActions.deleteManyOrganizationLeadTodoSuccess,
    (state: OrganizationLeadTodoProfilState, { idOrganizationLeadTodos, idOrganizationLeadTodoProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodoProfils.reduce((entities, idOrganizationLeadTodoProfil) => {
            if (!state.entities[idOrganizationLeadTodoProfil]?.organizationLeadTodo) {
              return entities;
            }
            entities[idOrganizationLeadTodoProfil] = {
              ...state.entities[idOrganizationLeadTodoProfil],
              organizationLeadTodo: idOrganizationLeadTodos.some(
                (idOrganizationLeadTodo: number) =>
                  idOrganizationLeadTodo === state.entities[idOrganizationLeadTodoProfil]?.organizationLeadTodo
              )
                ? undefined
                : state.entities[idOrganizationLeadTodoProfil]?.organizationLeadTodo
            } as OrganizationLeadTodoProfilEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoProfilEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationLeadTodoProfilState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationLeadTodoProfilState {
  return { ...state, isLoaded, isLoading };
}
