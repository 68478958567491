import {
  OrganizationResidenceTodoAppliedRuleState,
  initialState
} from './organization-residence-todo-applied-rule.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationResidenceTodoAppliedRuleReducersGeneratedFunctions } from './organization-residence-todo-applied-rule-generated.reducer';

const organizationResidenceTodoAppliedRuleReducersFunctions = [
  ...organizationResidenceTodoAppliedRuleReducersGeneratedFunctions
];

const organizationResidenceTodoAppliedRuleReducer = createReducer(
  initialState,
  ...organizationResidenceTodoAppliedRuleReducersFunctions
);

export function reducer(state: OrganizationResidenceTodoAppliedRuleState | undefined, action: Action) {
  return organizationResidenceTodoAppliedRuleReducer(state, action);
}
