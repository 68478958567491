import { createAction, props } from '@ngrx/store';
import { Association } from '@_model/interfaces/association.model';
import { AssociationRelationsIds } from './association-generated.effects';

export * from './association-generated.actions';

export const getAssociatedStratalot = createAction(
  '[Association] Get Associated Stratalot',
  props<{ idStratalot: number; params? }>()
);

export const upsertOneAssociationAndDeleteStratalotAssociation = createAction(
  '[Association] Upsert One Association And Delete Stratalot Association',
  props<{
    association: Partial<Association>;
    ids?: AssociationRelationsIds;
    idsToDelete?: {
      stratalotAssociations?: number | number[];
    };
  }>()
);
