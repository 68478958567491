import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidenceSaleCategoryFamilyEntityState } from '@_model/interfaces/residence-sale-category-family.model';

export interface ResidenceSaleCategoryFamilyState extends EntityState<ResidenceSaleCategoryFamilyEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidenceSaleCategoryFamilyEntityState> =
  createEntityAdapter<ResidenceSaleCategoryFamilyEntityState>({
    selectId: o => o.idResidenceSaleCategoryFamily
  });
export const initialState: ResidenceSaleCategoryFamilyState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residenceSaleCategoryFamilyFeatureKey = 'residenceSaleCategoryFamily';
