import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { OrganizationEnergie, OrganizationEnergieEntityState } from '@_model/interfaces/organization-energie.model';
import { OrganizationEnergieRelationsIds } from './organization-energie-generated.effects';

export const getOneOrganizationEnergie = createAction(
  '[OrganizationEnergie] Get One OrganizationEnergie',
  props<{ idOrganizationEnergie: number; params?: any }>()
);

export const getManyOrganizationEnergies = createAction(
  '[OrganizationEnergie] Get Many OrganizationEnergies',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationEnergie] Add Many Actives OrganizationEnergie',
  props<{ idOrganizationEnergies: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationEnergie] Delete One Active OrganizationEnergie',
  props<{ idOrganizationEnergie: number }>()
);

export const clearActive = createAction('[OrganizationEnergie] Clear Active OrganizationEnergie');

export const upsertOneOrganizationEnergie = createAction(
  '[OrganizationEnergie] Upsert One OrganizationEnergie',
  props<{
    organizationEnergie: Partial<OrganizationEnergie>;
    ids?: OrganizationEnergieRelationsIds;
  }>()
);

export const upsertManyOrganizationEnergies = createAction(
  '[OrganizationEnergie] Upsert Many OrganizationEnergies',
  props<{
    organizationEnergies: Partial<OrganizationEnergie>[];
    ids?: OrganizationEnergieRelationsIds;
  }>()
);

export const upsertManyOrganizationEnergiesSuccess = createAction(
  '[OrganizationEnergie] Create Many OrganizationEnergies Success',
  props<{ organizationEnergies: OrganizationEnergieEntityState[] }>()
);

export const deleteOneOrganizationEnergie = createAction(
  '[OrganizationEnergie] Delete One OrganizationEnergie',
  props<{ idOrganizationEnergie: number }>()
);

export const deleteOneOrganizationEnergieSuccess = createAction(
  '[OrganizationEnergie] Delete One OrganizationEnergie Success',
  props<{ idOrganizationEnergie: number }>()
);

export const normalizeManyOrganizationEnergiesAfterUpsert = createAction(
  '[OrganizationEnergie] Normalize Many OrganizationEnergies After Upsert',
  props<{ organizationEnergies: OrganizationEnergieEntityState[] }>()
);

export const organizationEnergiesFailure = createAction(
  '[OrganizationEnergie] OrganizationEnergies Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationEnergie] Clear OrganizationEnergies');

export const addOrganizationSuccess = createAction(
  '[OrganizationEnergie] Add Organization',
  props<{ idOrganizationEnergie: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationEnergie] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationEnergies: number[] }>()
);
