import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationResidenceTodoApiService } from '@_services/api/organization-residence-todo-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedOrganizationResidenceTodoEffects } from './organization-residence-todo-generated.effects';

@Injectable()
export class OrganizationResidenceTodoEffects extends GeneratedOrganizationResidenceTodoEffects {
  constructor(
    actions$: Actions,
    organizationResidenceTodoApiService: OrganizationResidenceTodoApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationResidenceTodoApiService, store$);
  }
}
