import { OrganizationResidenceStudyTodoState, initialState } from './organization-residence-study-todo.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationResidenceStudyTodoReducersGeneratedFunctions } from './organization-residence-study-todo-generated.reducer';

const organizationResidenceStudyTodoReducersFunctions = [...organizationResidenceStudyTodoReducersGeneratedFunctions];

const organizationResidenceStudyTodoReducer = createReducer(
  initialState,
  ...organizationResidenceStudyTodoReducersFunctions
);

export function reducer(state: OrganizationResidenceStudyTodoState | undefined, action: Action) {
  return organizationResidenceStudyTodoReducer(state, action);
}
