import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { StepProgress, StepProgressEntityState } from '@_model/interfaces/step-progress.model';
import { StepProgressRelationsIds } from './step-progress-generated.effects';

export const getOneStepProgress = createAction(
  '[StepProgress] Get One StepProgress',
  props<{ idStepProgress: number; params?: any }>()
);

export const getManyStepProgresses = createAction('[StepProgress] Get Many StepProgresses', props<{ params: any }>());

export const addManyActives = createAction(
  '[StepProgress] Add Many Actives StepProgress',
  props<{ idStepProgresses: number[] }>()
);

export const deleteOneActive = createAction(
  '[StepProgress] Delete One Active StepProgress',
  props<{ idStepProgress: number }>()
);

export const clearActive = createAction('[StepProgress] Clear Active StepProgress');

export const upsertOneStepProgress = createAction(
  '[StepProgress] Upsert One StepProgress',
  props<{
    stepProgress: Partial<StepProgress>;
    ids?: StepProgressRelationsIds;
  }>()
);

export const upsertManyStepProgresses = createAction(
  '[StepProgress] Upsert Many StepProgresses',
  props<{
    stepProgresses: Partial<StepProgress>[];
    ids?: StepProgressRelationsIds;
  }>()
);

export const upsertManyStepProgressesSuccess = createAction(
  '[StepProgress] Create Many StepProgresses Success',
  props<{ stepProgresses: StepProgressEntityState[] }>()
);

export const deleteOneStepProgress = createAction(
  '[StepProgress] Delete One StepProgress',
  props<{ idStepProgress: number }>()
);

export const deleteOneStepProgressSuccess = createAction(
  '[StepProgress] Delete One StepProgress Success',
  props<{ idStepProgress: number }>()
);

export const normalizeManyStepProgressesAfterUpsert = createAction(
  '[StepProgress] Normalize Many StepProgresses After Upsert',
  props<{ stepProgresses: StepProgressEntityState[] }>()
);

export const stepProgressesFailure = createAction(
  '[StepProgress] StepProgresses Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[StepProgress] Clear StepProgresses');

export const addManyStratalotSuccess = createAction(
  '[StepProgress] Add Many stratalot',
  props<{ idStepProgress: number; idStratalots: number[] }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[StepProgress] Delete Many Stratalots',
  props<{ idStratalots: number[]; idStepProgresses: number[] }>()
);

export const addStepSuccess = createAction(
  '[StepProgress] Add Step',
  props<{ idStepProgress: number; idStep: number }>()
);

export const deleteManyStepSuccess = createAction(
  '[StepProgress] Delete Many Step',
  props<{ idSteps: number[]; idStepProgresses: number[] }>()
);

export const addStepProgressFamilySuccess = createAction(
  '[StepProgress] Add StepProgressFamily',
  props<{ idStepProgress: number; idStepProgressFamily: number }>()
);

export const deleteManyStepProgressFamilySuccess = createAction(
  '[StepProgress] Delete Many StepProgressFamily',
  props<{ idStepProgressesFamilies: number[]; idStepProgresses: number[] }>()
);
