import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { Action } from '@ngrx/store';
import * as associationActions from '@_store/association/association.actions';
import { Association, AssociationEntityState } from '@_model/interfaces/association.model';
import * as stratalotAssociationActions from '@_store/stratalot-association/stratalot-association.actions';
import { StratalotAssociation, StratalotAssociationEntityState } from '@_model/interfaces/stratalot-association.model';
import * as boardStateActions from '@_store/board-state/board-state.actions';
import { BoardState, BoardStateEntityState } from '@_model/interfaces/board-state.model';
import * as campaignMediaActions from '@_store/campaign-media/campaign-media.actions';
import { CampaignMedia, CampaignMediaEntityState } from '@_model/interfaces/campaign-media.model';
import * as cityActions from '@_store/city/city.actions';
import { City, CityEntityState } from '@_model/interfaces/city.model';
import * as companyActions from '@_store/company/company.actions';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import * as companyUbiflowActions from '@_store/company-ubiflow/company-ubiflow.actions';
import { CompanyUbiflow, CompanyUbiflowEntityState } from '@_model/interfaces/company-ubiflow.model';
import * as companyMyNotaryActions from '@_store/company-my-notary/company-my-notary.actions';
import { CompanyMyNotary, CompanyMyNotaryEntityState } from '@_model/interfaces/company-my-notary.model';
import * as companyPriceLabelActions from '@_store/company-price-label/company-price-label.actions';
import { CompanyPriceLabel, CompanyPriceLabelEntityState } from '@_model/interfaces/company-price-label.model';
import * as companyStratalotVacantActions from '@_store/company-stratalot-vacant/company-stratalot-vacant.actions';
import {
  CompanyStratalotVacant,
  CompanyStratalotVacantEntityState
} from '@_model/interfaces/company-stratalot-vacant.model';
import * as companyStudyNextActionActions from '@_store/company-study-next-action/company-study-next-action.actions';
import {
  CompanyStudyNextAction,
  CompanyStudyNextActionEntityState
} from '@_model/interfaces/company-study-next-action.model';
import * as companyStudyConclusionActions from '@_store/company-study-conclusion/company-study-conclusion.actions';
import {
  CompanyStudyConclusion,
  CompanyStudyConclusionEntityState
} from '@_model/interfaces/company-study-conclusion.model';
import * as companyStudyReasonActions from '@_store/company-study-reason/company-study-reason.actions';
import { CompanyStudyReason, CompanyStudyReasonEntityState } from '@_model/interfaces/company-study-reason.model';
import * as companyStudyCriteriaActions from '@_store/company-study-criteria/company-study-criteria.actions';
import { CompanyStudyCriteria, CompanyStudyCriteriaEntityState } from '@_model/interfaces/company-study-criteria.model';
import * as companyTerritoireActions from '@_store/company-territoire/company-territoire.actions';
import { CompanyTerritoire, CompanyTerritoireEntityState } from '@_model/interfaces/company-territoire.model';
import * as companyTerritoireUserActions from '@_store/company-territoire-user/company-territoire-user.actions';
import {
  CompanyTerritoireUser,
  CompanyTerritoireUserEntityState
} from '@_model/interfaces/company-territoire-user.model';
import * as diagnosticActions from '@_store/diagnostic/diagnostic.actions';
import { DiagnosticEntityState } from '@_model/interfaces/diagnostic.model';
import * as diffusionVisualActions from '@_store/diffusion-visual/diffusion-visual.actions';
import { DiffusionVisual, DiffusionVisualEntityState } from '@_model/interfaces/diffusion-visual.model';
import * as droitActions from '@_store/droit/droit.actions';
import { Droit, DroitEntityState } from '@_model/interfaces/droit.model';
import * as generatedDocumentActions from '@_store/generated-document/generated-document.actions';
import { GeneratedDocument, GeneratedDocumentEntityState } from '@_model/interfaces/generated-document.model';
import * as generatedDocumentsCompanyActions from '@_store/generated-documents-company/generated-documents-company.actions';
import {
  GeneratedDocumentsCompany,
  GeneratedDocumentsCompanyEntityState
} from '@_model/interfaces/generated-documents-company.model';
import * as generatedDocumentsResidenceActions from '@_store/generated-documents-residence/generated-documents-residence.actions';
import {
  GeneratedDocumentsResidence,
  GeneratedDocumentsResidenceEntityState
} from '@_model/interfaces/generated-documents-residence.model';
import * as leadActions from '@_store/lead/lead.actions';
import { Lead, LeadEntityState } from '@_model/interfaces/lead.model';
import * as leadStratalotActions from '@_store/lead-stratalot/lead-stratalot.actions';
import { LeadStratalot, LeadStratalotEntityState } from '@_model/interfaces/lead-stratalot.model';
import * as leadTodoActions from '@_store/lead-todo/lead-todo.actions';
import { LeadTodo, LeadTodoEntityState } from '@_model/interfaces/lead-todo.model';
import * as occupantActions from '@_store/occupant/occupant.actions';
import { Occupant, OccupantEntityState } from '@_model/interfaces/occupant.model';
import * as organizationActions from '@_store/organization/organization.actions';
import { OrganizationEntityState } from '@_model/interfaces/organization.model';
import * as organizationMetierActions from '@_store/organization-metier/organization-metier.actions';
import { OrganizationMetier, OrganizationMetierEntityState } from '@_model/interfaces/organization-metier.model';
import * as organizationBuyingWishActions from '@_store/organization-buying-wish/organization-buying-wish.actions';
import {
  OrganizationBuyingWish,
  OrganizationBuyingWishEntityState
} from '@_model/interfaces/organization-buying-wish.model';
import * as organizationEnergieActions from '@_store/organization-energie/organization-energie.actions';
import { OrganizationEnergie, OrganizationEnergieEntityState } from '@_model/interfaces/organization-energie.model';
import * as organizationLeadAvancementActions from '@_store/organization-lead-avancement/organization-lead-avancement.actions';
import {
  OrganizationLeadAvancement,
  OrganizationLeadAvancementEntityState
} from '@_model/interfaces/organization-lead-avancement.model';
import * as organizationLeadTodoActions from '@_store/organization-lead-todo/organization-lead-todo.actions';
import { OrganizationLeadTodo, OrganizationLeadTodoEntityState } from '@_model/interfaces/organization-lead-todo.model';
import * as organizationLeadTodoProfilActions from '@_store/organization-lead-todo-profil/organization-lead-todo-profil.actions';
import {
  OrganizationLeadTodoProfil,
  OrganizationLeadTodoProfilEntityState
} from '@_model/interfaces/organization-lead-todo-profil.model';
import * as organizationResidenceTodoProfilActions from '@_store/organization-residence-todo-profil/organization-residence-todo-profil.actions';
import {
  OrganizationResidenceTodoProfil,
  OrganizationResidenceTodoProfilEntityState
} from '@_model/interfaces/organization-residence-todo-profil.model';
import * as organizationStratalotTodoProfilActions from '@_store/organization-stratalot-todo-profil/organization-stratalot-todo-profil.actions';
import {
  OrganizationStratalotTodoProfil,
  OrganizationStratalotTodoProfilEntityState
} from '@_model/interfaces/organization-stratalot-todo-profil.model';
import * as organizationLeadTodoRuleActions from '@_store/organization-lead-todo-rule/organization-lead-todo-rule.actions';
import {
  OrganizationLeadTodoRule,
  OrganizationLeadTodoRuleEntityState
} from '@_model/interfaces/organization-lead-todo-rule.model';
import * as organizationLeadTodoAppliedRuleActions from '@_store/organization-lead-todo-applied-rule/organization-lead-todo-applied-rule.actions';
import {
  OrganizationLeadTodoAppliedRule,
  OrganizationLeadTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-lead-todo-applied-rule.model';
import * as companyMediaActions from '@_store/company-media/company-media.actions';
import { CompanyMedia, CompanyMediaEntityState } from '@_model/interfaces/company-media.model';
import * as organizationProfilActions from '@_store/organization-profil/organization-profil.actions';
import { OrganizationProfil, OrganizationProfilEntityState } from '@_model/interfaces/organization-profil.model';
import * as companyCommunicationActions from '@_store/company-communication/company-communication.actions';
import { CompanyCommunication, CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';
import * as companyCommunicationResidenceTodoActions from '@_store/company-communication-residence-todo/company-communication-residence-todo.actions';
import {
  CompanyCommunicationResidenceTodo,
  CompanyCommunicationResidenceTodoEntityState
} from '@_model/interfaces/company-communication-residence-todo.model';
import * as companyCommunicationStratalotTodoActions from '@_store/company-communication-stratalot-todo/company-communication-stratalot-todo.actions';
import {
  CompanyCommunicationStratalotTodo,
  CompanyCommunicationStratalotTodoEntityState
} from '@_model/interfaces/company-communication-stratalot-todo.model';
import * as companyCommunicationLeadTodoActions from '@_store/company-communication-lead-todo/company-communication-lead-todo.actions';
import {
  CompanyCommunicationLeadTodo,
  CompanyCommunicationLeadTodoEntityState
} from '@_model/interfaces/company-communication-lead-todo.model';
import * as companyCommunicationFileActions from '@_store/company-communication-file/company-communication-file.actions';
import {
  CompanyCommunicationFile,
  CompanyCommunicationFileEntityState
} from '@_model/interfaces/company-communication-file.model';
import * as companyCommunicationMediaActions from '@_store/company-communication-media/company-communication-media.actions';
import { CompanyCommunicationMediaEntityState } from '@_model/interfaces/company-communication-media.model';
import * as companyCommunicationRecipientActions from '@_store/company-communication-recipient/company-communication-recipient.actions';
import {
  CompanyCommunicationRecipient,
  CompanyCommunicationRecipientEntityState
} from '@_model/interfaces/company-communication-recipient.model';
import * as companyCommunicationRecipientMetierActions from '@_store/company-communication-recipient-metier/company-communication-recipient-metier.actions';
import {
  CompanyCommunicationRecipientMetier,
  CompanyCommunicationRecipientMetierEntityState
} from '@_model/interfaces/company-communication-recipient-metier.model';
import * as companyCommunicationRecipientUserActions from '@_store/company-communication-recipient-user/company-communication-recipient-user.actions';
import {
  CompanyCommunicationRecipientUser,
  CompanyCommunicationRecipientUserEntityState
} from '@_model/interfaces/company-communication-recipient-user.model';
import * as companyCommunicationGeneratedDocumentActions from '@_store/company-communication-generated-document/company-communication-generated-document.actions';
import {
  CompanyCommunicationGeneratedDocument,
  CompanyCommunicationGeneratedDocumentEntityState
} from '@_model/interfaces/company-communication-generated-document.model';
import * as organizationResidenceTodoActions from '@_store/organization-residence-todo/organization-residence-todo.actions';
import {
  OrganizationResidenceTodo,
  OrganizationResidenceTodoEntityState
} from '@_model/interfaces/organization-residence-todo.model';
import * as organizationResidenceTodoRuleActions from '@_store/organization-residence-todo-rule/organization-residence-todo-rule.actions';
import {
  OrganizationResidenceTodoRule,
  OrganizationResidenceTodoRuleEntityState
} from '@_model/interfaces/organization-residence-todo-rule.model';
import * as organizationResidenceTodoAppliedRuleActions from '@_store/organization-residence-todo-applied-rule/organization-residence-todo-applied-rule.actions';
import {
  OrganizationResidenceTodoAppliedRule,
  OrganizationResidenceTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-residence-todo-applied-rule.model';
import * as organizationSaleCategoryActions from '@_store/organization-sale-category/organization-sale-category.actions';
import {
  OrganizationSaleCategory,
  OrganizationSaleCategoryEntityState
} from '@_model/interfaces/organization-sale-category.model';
import * as organizationStratalotAvancementActions from '@_store/organization-stratalot-avancement/organization-stratalot-avancement.actions';
import {
  OrganizationStratalotAvancement,
  OrganizationStratalotAvancementEntityState
} from '@_model/interfaces/organization-stratalot-avancement.model';
import * as organizationStratalotTodoActions from '@_store/organization-stratalot-todo/organization-stratalot-todo.actions';
import {
  OrganizationStratalotTodo,
  OrganizationStratalotTodoEntityState
} from '@_model/interfaces/organization-stratalot-todo.model';
import * as organizationResidenceStudyTodoActions from '@_store/organization-residence-study-todo/organization-residence-study-todo.actions';
import {
  OrganizationResidenceStudyTodo,
  OrganizationResidenceStudyTodoEntityState
} from '@_model/interfaces/organization-residence-study-todo.model';
import * as organizationStratalotTodoRuleActions from '@_store/organization-stratalot-todo-rule/organization-stratalot-todo-rule.actions';
import {
  OrganizationStratalotTodoRule,
  OrganizationStratalotTodoRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-rule.model';
import * as organizationStratalotTodoAppliedRuleActions from '@_store/organization-stratalot-todo-applied-rule/organization-stratalot-todo-applied-rule.actions';
import {
  OrganizationStratalotTodoAppliedRule,
  OrganizationStratalotTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-applied-rule.model';
import * as organizationSocieteActions from '@_store/organization-societe/organization-societe.actions';
import { OrganizationSociete, OrganizationSocieteEntityState } from '@_model/interfaces/organization-societe.model';
import * as companyStratalotTypeActions from '@_store/company-stratalot-type/company-stratalot-type.actions';
import { CompanyStratalotType, CompanyStratalotTypeEntityState } from '@_model/interfaces/company-stratalot-type.model';
import * as organizationThirdPartyActions from '@_store/organization-third-party/organization-third-party.actions';
import {
  OrganizationThirdParty,
  OrganizationThirdPartyEntityState
} from '@_model/interfaces/organization-third-party.model';
import * as organizationProspectOriginActions from '@_store/organization-prospect-origin/organization-prospect-origin.actions';
import {
  OrganizationProspectOrigin,
  OrganizationProspectOriginEntityState
} from '@_model/interfaces/organization-prospect-origin.model';
import * as prospectActions from '@_store/prospect/prospect.actions';
import { Prospect, ProspectEntityState } from '@_model/interfaces/prospect.model';
import * as prospectBuyingWishActions from '@_store/prospect-buying-wish/prospect-buying-wish.actions';
import { ProspectBuyingWish, ProspectBuyingWishEntityState } from '@_model/interfaces/prospect-buying-wish.model';
import * as prospectEventActions from '@_store/prospect-event/prospect-event.actions';
import { ProspectEvent, ProspectEventEntityState } from '@_model/interfaces/prospect-event.model';
import * as profilActions from '@_store/profil/profil.actions';
import { Profil, ProfilEntityState } from '@_model/interfaces/profil.model';
import * as residenceActions from '@_store/residence/residence.actions';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import * as residenceContactActions from '@_store/residence-contact/residence-contact.actions';
import { ResidenceContact, ResidenceContactEntityState } from '@_model/interfaces/residence-contact.model';
import * as residenceShareActions from '@_store/residence-share/residence-share.actions';
import { ResidenceShare, ResidenceShareEntityState } from '@_model/interfaces/residence-share.model';
import * as residenceShareRcpActions from '@_store/residence-share-rcp/residence-share-rcp.actions';
import { ResidenceShareRcp, ResidenceShareRcpEntityState } from '@_model/interfaces/residence-share-rcp.model';
import * as residenceStudyActions from '@_store/residence-study/residence-study.actions';
import { ResidenceStudy, ResidenceStudyEntityState } from '@_model/interfaces/residence-study.model';
import * as residenceStudyCriteriaActions from '@_store/residence-study-criteria/residence-study-criteria.actions';
import {
  ResidenceStudyCriteria,
  ResidenceStudyCriteriaEntityState
} from '@_model/interfaces/residence-study-criteria.model';
import * as residenceStudyWorkActions from '@_store/residence-study-work/residence-study-work.actions';
import { ResidenceStudyWork, ResidenceStudyWorkEntityState } from '@_model/interfaces/residence-study-work.model';
import * as residenceStudyLotActions from '@_store/residence-study-lot/residence-study-lot.actions';
import { ResidenceStudyLot, ResidenceStudyLotEntityState } from '@_model/interfaces/residence-study-lot.model';
import * as residenceStudyFoundingActions from '@_store/residence-study-founding/residence-study-founding.actions';
import {
  ResidenceStudyFounding,
  ResidenceStudyFoundingEntityState
} from '@_model/interfaces/residence-study-founding.model';
import * as residenceStudyReasonActions from '@_store/residence-study-reason/residence-study-reason.actions';
import { ResidenceStudyReason, ResidenceStudyReasonEntityState } from '@_model/interfaces/residence-study-reason.model';
import * as residenceTodoActions from '@_store/residence-todo/residence-todo.actions';
import { ResidenceTodo, ResidenceTodoEntityState } from '@_model/interfaces/residence-todo.model';
import * as residenceWorkActions from '@_store/residence-work/residence-work.actions';
import { ResidenceWork, ResidenceWorkEntityState } from '@_model/interfaces/residence-work.model';
import * as residenceSaleCategoryFamilyActions from '@_store/residence-sale-category-family/residence-sale-category-family.actions';
import {
  ResidenceSaleCategoryFamily,
  ResidenceSaleCategoryFamilyEntityState
} from '@_model/interfaces/residence-sale-category-family.model';
import * as saleCategoryFamilyActions from '@_store/sale-category-family/sale-category-family.actions';
import { SaleCategoryFamily, SaleCategoryFamilyEntityState } from '@_model/interfaces/sale-category-family.model';
import * as stepActions from '@_store/step/step.actions';
import { StepEntityState } from '@_model/interfaces/step.model';
import * as stepProgressActions from '@_store/step-progress/step-progress.actions';
import { StepProgress, StepProgressEntityState } from '@_model/interfaces/step-progress.model';
import * as stepProgressFamilyActions from '@_store/step-progress-family/step-progress-family.actions';
import { StepProgressFamilyEntityState } from '@_model/interfaces/step-progress-family.model';
import * as stratalotFeeActions from '@_store/stratalot-fee/stratalot-fee.actions';
import { StratalotFee, StratalotFeeEntityState } from '@_model/interfaces/stratalot-fee.model';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import * as stratalotCampaignActions from '@_store/stratalot-campaign/stratalot-campaign.actions';
import { StratalotCampaign, StratalotCampaignEntityState } from '@_model/interfaces/stratalot-campaign.model';
import * as stratalotPriceActions from '@_store/stratalot-price/stratalot-price.actions';
import { StratalotPrice, StratalotPriceEntityState } from '@_model/interfaces/stratalot-price.model';
import * as residencePriceGridActions from '@_store/residence-price-grid/residence-price-grid.actions';
import { ResidencePriceGrid, ResidencePriceGridEntityState } from '@_model/interfaces/residence-price-grid.model';
import * as stratalotPriceListActions from '@_store/stratalot-price-list/stratalot-price-list.actions';
import { StratalotPriceList, StratalotPriceListEntityState } from '@_model/interfaces/stratalot-price-list.model';
import * as residencePriceGridModifierActions from '@_store/residence-price-grid-modifier/residence-price-grid-modifier.actions';
import {
  ResidencePriceGridModifier,
  ResidencePriceGridModifierEntityState
} from '@_model/interfaces/residence-price-grid-modifier.model';
import * as residencePriceGridHistoryActions from '@_store/residence-price-grid-history/residence-price-grid-history.actions';
import {
  ResidencePriceGridHistory,
  ResidencePriceGridHistoryEntityState
} from '@_model/interfaces/residence-price-grid-history.model';
import * as stratalotPriceValueActions from '@_store/stratalot-price-value/stratalot-price-value.actions';
import { StratalotPriceValue, StratalotPriceValueEntityState } from '@_model/interfaces/stratalot-price-value.model';
import * as stratalotRcpActions from '@_store/stratalot-rcp/stratalot-rcp.actions';
import { StratalotRcp, StratalotRcpEntityState } from '@_model/interfaces/stratalot-rcp.model';
import * as stratalotTodoActions from '@_store/stratalot-todo/stratalot-todo.actions';
import { StratalotTodo, StratalotTodoEntityState } from '@_model/interfaces/stratalot-todo.model';
import * as residenceStudyTodoActions from '@_store/residence-study-todo/residence-study-todo.actions';
import { ResidenceStudyTodo, ResidenceStudyTodoEntityState } from '@_model/interfaces/residence-study-todo.model';
import * as stratalotTypeActions from '@_store/stratalot-type/stratalot-type.actions';
import { StratalotType, StratalotTypeEntityState } from '@_model/interfaces/stratalot-type.model';
import * as userActions from '@_store/user/user.actions';
import { User, UserEntityState } from '@_model/interfaces/user.model';
import * as forecastPeriodActions from '@_store/forecast-period/forecast-period.actions';
import { ForecastPeriodEntityState } from '@_model/interfaces/forecast-period.model';
import * as organizationForecastRateActions from '@_store/organization-forecast-rate/organization-forecast-rate.actions';
import {
  OrganizationForecastRate,
  OrganizationForecastRateEntityState
} from '@_model/interfaces/organization-forecast-rate.model';
import * as residenceForecastActions from '@_store/residence-forecast/residence-forecast.actions';
import { ResidenceForecast, ResidenceForecastEntityState } from '@_model/interfaces/residence-forecast.model';
import * as residenceForecastValueActions from '@_store/residence-forecast-value/residence-forecast-value.actions';
import {
  ResidenceForecastValue,
  ResidenceForecastValueEntityState
} from '@_model/interfaces/residence-forecast-value.model';
import * as metierFamilieActions from '@_store/metier-familie/metier-familie.actions';
import { MetierFamilieEntityState } from '@_model/interfaces/metier-familie.model';

/**
 *  Association
 */
export function getActionsToNormalizeAssociation(
  associations: AssociationEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const stratalotAssociationsToNormalize: StratalotAssociationEntityState[] = [];
  const stratalotsToNormalize: { [id: number]: StratalotEntityState } = {};

  const normalizedAssociations = associations.map((association: AssociationEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    associationNormalizeStratalotAssociation(association, stratalotAssociationsToNormalize, relations);
    associationNormalizeStratalot(association, stratalotsToNormalize, relations);

    return {
      ...association,
      ...relations
    };
  });
  if (stratalotAssociationsToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalotAssociation(stratalotAssociationsToNormalize, actionType));
  }
  const stratalotsToNormalizeArray = Object.values(stratalotsToNormalize);
  if (stratalotsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalizeArray, actionType));
  }

  const associationActionCreator = associationActions.upsertManyAssociationsSuccess;
  actions.push(associationActionCreator({ associations: normalizedAssociations }));

  return actions;
}

function associationNormalizeStratalotAssociation(
  association: AssociationEntityState,
  stratalotAssociationsToNormalize: StratalotAssociationEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (association.stratalotAssociations?.length && typeof association.stratalotAssociations[0] !== 'number') {
    const idsStratalotAssociations: number[] = [];
    stratalotAssociationsToNormalize.push(
      ...(association.stratalotAssociations as StratalotAssociation[]).map(
        (stratalotAssociation: StratalotAssociation) => {
          idsStratalotAssociations.push(stratalotAssociation.idStratalotAssociation);
          return { ...stratalotAssociation, association: association.idAssociation };
        }
      )
    );
    relations['stratalotAssociations'] = idsStratalotAssociations;
  }
}

function associationNormalizeStratalot(
  association: AssociationEntityState,
  stratalotsToNormalize: { [id: number]: StratalotEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (association.stratalots?.length && typeof association.stratalots[0] !== 'number') {
    const idsStratalots: number[] = (association.stratalots as Stratalot[]).map((stratalot: Stratalot) => {
      if (!stratalotsToNormalize[stratalot.idStratalot]) {
        stratalotsToNormalize[stratalot.idStratalot] = { ...stratalot, associations: [] };
      }
      (stratalotsToNormalize[stratalot.idStratalot].associations as number[]).push(association.idAssociation);
      return stratalot.idStratalot;
    });
    relations['stratalots'] = idsStratalots;
  }
}

/**
 *  StratalotAssociation
 */
export function getActionsToNormalizeStratalotAssociation(
  stratalotAssociations: StratalotAssociationEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const stratalotsToNormalize: { [id: number]: StratalotEntityState } = {};
  const associationsToNormalize: { [id: number]: AssociationEntityState } = {};

  const normalizedStratalotAssociations = stratalotAssociations.map(
    (stratalotAssociation: StratalotAssociationEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      stratalotAssociationNormalizeStratalot(stratalotAssociation, stratalotsToNormalize, relations);
      stratalotAssociationNormalizeAssociation(stratalotAssociation, associationsToNormalize, relations);

      return {
        ...stratalotAssociation,
        ...relations
      };
    }
  );
  const stratalotsToNormalizeArray = Object.values(stratalotsToNormalize);
  if (stratalotsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalizeArray, actionType));
  }
  const associationsToNormalizeArray = Object.values(associationsToNormalize);
  if (associationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeAssociation(associationsToNormalizeArray, actionType));
  }

  const stratalotAssociationActionCreator = stratalotAssociationActions.upsertManyStratalotAssociationsSuccess;
  actions.push(stratalotAssociationActionCreator({ stratalotAssociations: normalizedStratalotAssociations }));

  return actions;
}

function stratalotAssociationNormalizeStratalot(
  stratalotAssociation: StratalotAssociationEntityState,
  stratalotsToNormalize: { [id: number]: StratalotEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotAssociation.stratalot && typeof stratalotAssociation.stratalot !== 'number') {
    if (!stratalotsToNormalize[stratalotAssociation.stratalot.idStratalot]) {
      stratalotsToNormalize[stratalotAssociation.stratalot.idStratalot] = {
        ...stratalotAssociation.stratalot,
        stratalotAssociations: []
      };
    }
    (stratalotsToNormalize[stratalotAssociation.stratalot.idStratalot].stratalotAssociations as number[]).push(
      stratalotAssociation.idStratalotAssociation
    );
    relations['stratalot'] = stratalotAssociation.stratalot.idStratalot;
  }
}

function stratalotAssociationNormalizeAssociation(
  stratalotAssociation: StratalotAssociationEntityState,
  associationsToNormalize: { [id: number]: AssociationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotAssociation.association && typeof stratalotAssociation.association !== 'number') {
    if (!associationsToNormalize[stratalotAssociation.association.idAssociation]) {
      associationsToNormalize[stratalotAssociation.association.idAssociation] = {
        ...stratalotAssociation.association,
        stratalotAssociations: []
      };
    }
    (associationsToNormalize[stratalotAssociation.association.idAssociation].stratalotAssociations as number[]).push(
      stratalotAssociation.idStratalotAssociation
    );
    relations['association'] = stratalotAssociation.association.idAssociation;
  }
}

/**
 *  BoardState
 */
export function getActionsToNormalizeBoardState(
  boardStates: BoardStateEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const familySaleCategoriesToNormalize: { [id: number]: SaleCategoryFamilyEntityState } = {};

  const normalizedBoardStates = boardStates.map((boardState: BoardStateEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    boardStateNormalizeUser(boardState, usersToNormalize, relations);
    boardStateNormalizeSaleCategoryFamily(boardState, familySaleCategoriesToNormalize, relations);

    return {
      ...boardState,
      ...relations
    };
  });
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const familySaleCategoriesToNormalizeArray = Object.values(familySaleCategoriesToNormalize);
  if (familySaleCategoriesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSaleCategoryFamily(familySaleCategoriesToNormalizeArray, actionType));
  }

  const boardStateActionCreator = boardStateActions.upsertManyBoardStatesSuccess;
  actions.push(boardStateActionCreator({ boardStates: normalizedBoardStates }));

  return actions;
}

function boardStateNormalizeUser(
  boardState: BoardStateEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (boardState.user && typeof boardState.user !== 'number') {
    if (!usersToNormalize[boardState.user.idUser]) {
      usersToNormalize[boardState.user.idUser] = { ...boardState.user, boardStates: [] };
    }
    (usersToNormalize[boardState.user.idUser].boardStates as number[]).push(boardState.idBoardState);
    relations['user'] = boardState.user.idUser;
  }
}

function boardStateNormalizeSaleCategoryFamily(
  boardState: BoardStateEntityState,
  familySaleCategoriesToNormalize: { [id: number]: SaleCategoryFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (boardState.saleCategoryFamily && typeof boardState.saleCategoryFamily !== 'number') {
    if (!familySaleCategoriesToNormalize[boardState.saleCategoryFamily.idSaleCategoryFamily]) {
      familySaleCategoriesToNormalize[boardState.saleCategoryFamily.idSaleCategoryFamily] = {
        ...boardState.saleCategoryFamily,
        boardStates: []
      };
    }
    (familySaleCategoriesToNormalize[boardState.saleCategoryFamily.idSaleCategoryFamily].boardStates as number[]).push(
      boardState.idBoardState
    );
    relations['saleCategoryFamily'] = boardState.saleCategoryFamily.idSaleCategoryFamily;
  }
}

/**
 *  CampaignMedia
 */
export function getActionsToNormalizeCampaignMedia(
  campaignMedias: CampaignMediaEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companyMediasToNormalize: { [id: number]: CompanyMediaEntityState } = {};
  const stratalotCampaignsToNormalize: { [id: number]: StratalotCampaignEntityState } = {};

  const normalizedCampaignMedias = campaignMedias.map((campaignMedia: CampaignMediaEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    campaignMediaNormalizeCompanyMedia(campaignMedia, companyMediasToNormalize, relations);
    campaignMediaNormalizeStratalotCampaign(campaignMedia, stratalotCampaignsToNormalize, relations);

    return {
      ...campaignMedia,
      ...relations
    };
  });
  const companyMediasToNormalizeArray = Object.values(companyMediasToNormalize);
  if (companyMediasToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyMedia(companyMediasToNormalizeArray, actionType));
  }
  const stratalotCampaignsToNormalizeArray = Object.values(stratalotCampaignsToNormalize);
  if (stratalotCampaignsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalotCampaign(stratalotCampaignsToNormalizeArray, actionType));
  }

  const campaignMediaActionCreator = campaignMediaActions.upsertManyCampaignMediasSuccess;
  actions.push(campaignMediaActionCreator({ campaignMedias: normalizedCampaignMedias }));

  return actions;
}

function campaignMediaNormalizeCompanyMedia(
  campaignMedia: CampaignMediaEntityState,
  companyMediasToNormalize: { [id: number]: CompanyMediaEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (campaignMedia.companyMedia && typeof campaignMedia.companyMedia !== 'number') {
    if (!companyMediasToNormalize[campaignMedia.companyMedia.idCompanyMedia]) {
      companyMediasToNormalize[campaignMedia.companyMedia.idCompanyMedia] = {
        ...campaignMedia.companyMedia,
        campaignMedias: []
      };
    }
    (companyMediasToNormalize[campaignMedia.companyMedia.idCompanyMedia].campaignMedias as number[]).push(
      campaignMedia.idCampaignMedia
    );
    relations['companyMedia'] = campaignMedia.companyMedia.idCompanyMedia;
  }
}

function campaignMediaNormalizeStratalotCampaign(
  campaignMedia: CampaignMediaEntityState,
  stratalotCampaignsToNormalize: { [id: number]: StratalotCampaignEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (campaignMedia.stratalotCampaign && typeof campaignMedia.stratalotCampaign !== 'number') {
    if (!stratalotCampaignsToNormalize[campaignMedia.stratalotCampaign.idStratalotCampaign]) {
      stratalotCampaignsToNormalize[campaignMedia.stratalotCampaign.idStratalotCampaign] = {
        ...campaignMedia.stratalotCampaign,
        campaignMedias: []
      };
    }
    (
      stratalotCampaignsToNormalize[campaignMedia.stratalotCampaign.idStratalotCampaign].campaignMedias as number[]
    ).push(campaignMedia.idCampaignMedia);
    relations['stratalotCampaign'] = campaignMedia.stratalotCampaign.idStratalotCampaign;
  }
}

/**
 *  City
 */
export function getActionsToNormalizeCity(cities: CityEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const prospectBuyingWishsToNormalize: { [id: number]: ProspectBuyingWishEntityState } = {};
  const residencesToNormalize: ResidenceEntityState[] = [];

  const normalizedCities = cities.map((city: CityEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    cityNormalizeProspectBuyingWish(city, prospectBuyingWishsToNormalize, relations);
    cityNormalizeResidence(city, residencesToNormalize, relations);
    cityNormalizeCompany(city, companiesToNormalize, relations);

    return {
      ...city,
      ...relations
    };
  });
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  const prospectBuyingWishsToNormalizeArray = Object.values(prospectBuyingWishsToNormalize);
  if (prospectBuyingWishsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProspectBuyingWish(prospectBuyingWishsToNormalizeArray, actionType));
  }
  if (residencesToNormalize.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalize, actionType));
  }

  const cityActionCreator = cityActions.upsertManyCitiesSuccess;
  actions.push(cityActionCreator({ cities: normalizedCities }));

  return actions;
}

function cityNormalizeProspectBuyingWish(
  city: CityEntityState,
  prospectBuyingWishsToNormalize: { [id: number]: ProspectBuyingWishEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (city.prospectBuyingWishs?.length && typeof city.prospectBuyingWishs[0] !== 'number') {
    const idsProspectBuyingWishs: number[] = (city.prospectBuyingWishs as ProspectBuyingWish[]).map(
      (prospectBuyingWish: ProspectBuyingWish) => {
        if (!prospectBuyingWishsToNormalize[prospectBuyingWish.idProspectBuyingWish]) {
          prospectBuyingWishsToNormalize[prospectBuyingWish.idProspectBuyingWish] = {
            ...prospectBuyingWish,
            cities: []
          };
        }
        (prospectBuyingWishsToNormalize[prospectBuyingWish.idProspectBuyingWish].cities as number[]).push(city.idCity);
        return prospectBuyingWish.idProspectBuyingWish;
      }
    );
    relations['prospectBuyingWishs'] = idsProspectBuyingWishs;
  }
}

function cityNormalizeResidence(
  city: CityEntityState,
  residencesToNormalize: ResidenceEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (city.residences?.length && typeof city.residences[0] !== 'number') {
    const idsResidences: number[] = [];
    residencesToNormalize.push(
      ...(city.residences as Residence[]).map((residence: Residence) => {
        idsResidences.push(residence.idResidence);
        return { ...residence, city: city.idCity };
      })
    );
    relations['residences'] = idsResidences;
  }
}

function cityNormalizeCompany(
  city: CityEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (city.company && typeof city.company !== 'number') {
    if (!companiesToNormalize[city.company.idCompany]) {
      companiesToNormalize[city.company.idCompany] = { ...city.company, cities: [] };
    }
    (companiesToNormalize[city.company.idCompany].cities as number[]).push(city.idCity);
    relations['company'] = city.company.idCompany;
  }
}

/**
 *  Company
 */
export function getActionsToNormalizeCompany(companies: CompanyEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const occupantsToNormalize: OccupantEntityState[] = [];
  const companyMediasToNormalize: CompanyMediaEntityState[] = [];
  const citiesToNormalize: CityEntityState[] = [];
  const companyCommunicationsToNormalize: CompanyCommunicationEntityState[] = [];
  const residencesToNormalize: ResidenceEntityState[] = [];
  const companyPriceLabelsToNormalize: CompanyPriceLabelEntityState[] = [];
  const companyTerritoiresToNormalize: CompanyTerritoireEntityState[] = [];
  const organizationLeadTodoRulesToNormalize: OrganizationLeadTodoRuleEntityState[] = [];
  const organizationStratalotTodoRulesToNormalize: OrganizationStratalotTodoRuleEntityState[] = [];
  const companyStratalotTypesToNormalize: CompanyStratalotTypeEntityState[] = [];
  const organizationResidenceTodoRulesToNormalize: OrganizationResidenceTodoRuleEntityState[] = [];
  const companyStratalotVacantsToNormalize: CompanyStratalotVacantEntityState[] = [];
  const generatedDocumentsCompaniesToNormalize: GeneratedDocumentsCompanyEntityState[] = [];
  const generatedDocumentsToNormalize: { [id: number]: GeneratedDocumentEntityState } = {};
  const companyUbiflowsToNormalize: CompanyUbiflowEntityState[] = [];
  const companyMyNotariesToNormalize: CompanyMyNotaryEntityState[] = [];
  const companyStudyNextActionsToNormalize: CompanyStudyNextActionEntityState[] = [];
  const companyStudyConclusionsToNormalize: CompanyStudyConclusionEntityState[] = [];
  const companyStudyReasonsToNormalize: CompanyStudyReasonEntityState[] = [];
  const companyStudyCriteriasToNormalize: CompanyStudyCriteriaEntityState[] = [];
  const residenceStudiesToNormalize: ResidenceStudyEntityState[] = [];

  const normalizedCompanies = companies.map((company: CompanyEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    companyNormalizeOccupant(company, occupantsToNormalize, relations);
    companyNormalizeCompanyMedia(company, companyMediasToNormalize, relations);
    companyNormalizeCity(company, citiesToNormalize, relations);
    companyNormalizeCompanyCommunication(company, companyCommunicationsToNormalize, relations);
    companyNormalizeResidence(company, residencesToNormalize, relations);
    companyNormalizeCompanyPriceLabel(company, companyPriceLabelsToNormalize, relations);
    companyNormalizeCompanyTerritoire(company, companyTerritoiresToNormalize, relations);
    companyNormalizeOrganizationLeadTodoRule(company, organizationLeadTodoRulesToNormalize, relations);
    companyNormalizeOrganizationStratalotTodoRule(company, organizationStratalotTodoRulesToNormalize, relations);
    companyNormalizeCompanyStratalotType(company, companyStratalotTypesToNormalize, relations);
    companyNormalizeOrganizationResidenceTodoRule(company, organizationResidenceTodoRulesToNormalize, relations);
    companyNormalizeCompanyStratalotVacant(company, companyStratalotVacantsToNormalize, relations);
    companyNormalizeGeneratedDocumentsCompany(company, generatedDocumentsCompaniesToNormalize, relations);
    companyNormalizeGeneratedDocument(company, generatedDocumentsToNormalize, relations);
    companyNormalizeCompanyUbiflow(company, companyUbiflowsToNormalize, relations);
    companyNormalizeCompanyMyNotary(company, companyMyNotariesToNormalize, relations);
    companyNormalizeCompanyStudyNextAction(company, companyStudyNextActionsToNormalize, relations);
    companyNormalizeCompanyStudyConclusion(company, companyStudyConclusionsToNormalize, relations);
    companyNormalizeCompanyStudyReason(company, companyStudyReasonsToNormalize, relations);
    companyNormalizeCompanyStudyCriteria(company, companyStudyCriteriasToNormalize, relations);
    companyNormalizeResidenceStudy(company, residenceStudiesToNormalize, relations);
    companyNormalizeOrganization(company, organizationsToNormalize, relations);

    return {
      ...company,
      ...relations
    };
  });
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  if (occupantsToNormalize.length) {
    actions.push(...getActionsToNormalizeOccupant(occupantsToNormalize, actionType));
  }
  if (companyMediasToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyMedia(companyMediasToNormalize, actionType));
  }
  if (citiesToNormalize.length) {
    actions.push(...getActionsToNormalizeCity(citiesToNormalize, actionType));
  }
  if (companyCommunicationsToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyCommunication(companyCommunicationsToNormalize, actionType));
  }
  if (residencesToNormalize.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalize, actionType));
  }
  if (companyPriceLabelsToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyPriceLabel(companyPriceLabelsToNormalize, actionType));
  }
  if (companyTerritoiresToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyTerritoire(companyTerritoiresToNormalize, actionType));
  }
  if (organizationLeadTodoRulesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationLeadTodoRule(organizationLeadTodoRulesToNormalize, actionType));
  }
  if (organizationStratalotTodoRulesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodoRule(organizationStratalotTodoRulesToNormalize, actionType)
    );
  }
  if (companyStratalotTypesToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyStratalotType(companyStratalotTypesToNormalize, actionType));
  }
  if (organizationResidenceTodoRulesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceTodoRule(organizationResidenceTodoRulesToNormalize, actionType)
    );
  }
  if (companyStratalotVacantsToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyStratalotVacant(companyStratalotVacantsToNormalize, actionType));
  }
  if (generatedDocumentsCompaniesToNormalize.length) {
    actions.push(...getActionsToNormalizeGeneratedDocumentsCompany(generatedDocumentsCompaniesToNormalize, actionType));
  }
  const generatedDocumentsToNormalizeArray = Object.values(generatedDocumentsToNormalize);
  if (generatedDocumentsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeGeneratedDocument(generatedDocumentsToNormalizeArray, actionType));
  }
  if (companyUbiflowsToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyUbiflow(companyUbiflowsToNormalize, actionType));
  }
  if (companyMyNotariesToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyMyNotary(companyMyNotariesToNormalize, actionType));
  }
  if (companyStudyNextActionsToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyStudyNextAction(companyStudyNextActionsToNormalize, actionType));
  }
  if (companyStudyConclusionsToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyStudyConclusion(companyStudyConclusionsToNormalize, actionType));
  }
  if (companyStudyReasonsToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyStudyReason(companyStudyReasonsToNormalize, actionType));
  }
  if (companyStudyCriteriasToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyStudyCriteria(companyStudyCriteriasToNormalize, actionType));
  }
  if (residenceStudiesToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceStudy(residenceStudiesToNormalize, actionType));
  }

  const companyActionCreator = companyActions.upsertManyCompaniesSuccess;
  actions.push(companyActionCreator({ companies: normalizedCompanies }));

  return actions;
}

function companyNormalizeOccupant(
  company: CompanyEntityState,
  occupantsToNormalize: OccupantEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.occupants?.length && typeof company.occupants[0] !== 'number') {
    const idsOccupants: number[] = [];
    occupantsToNormalize.push(
      ...(company.occupants as Occupant[]).map((occupant: Occupant) => {
        idsOccupants.push(occupant.idOccupant);
        return { ...occupant, company: company.idCompany };
      })
    );
    relations['occupants'] = idsOccupants;
  }
}

function companyNormalizeCompanyMedia(
  company: CompanyEntityState,
  companyMediasToNormalize: CompanyMediaEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.companyMedias?.length && typeof company.companyMedias[0] !== 'number') {
    const idsCompanyMedias: number[] = [];
    companyMediasToNormalize.push(
      ...(company.companyMedias as CompanyMedia[]).map((companyMedia: CompanyMedia) => {
        idsCompanyMedias.push(companyMedia.idCompanyMedia);
        return { ...companyMedia, company: company.idCompany };
      })
    );
    relations['companyMedias'] = idsCompanyMedias;
  }
}

function companyNormalizeCity(
  company: CompanyEntityState,
  citiesToNormalize: CityEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.cities?.length && typeof company.cities[0] !== 'number') {
    const idsCities: number[] = [];
    citiesToNormalize.push(
      ...(company.cities as City[]).map((city: City) => {
        idsCities.push(city.idCity);
        return { ...city, company: company.idCompany };
      })
    );
    relations['cities'] = idsCities;
  }
}

function companyNormalizeCompanyCommunication(
  company: CompanyEntityState,
  companyCommunicationsToNormalize: CompanyCommunicationEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.companyCommunications?.length && typeof company.companyCommunications[0] !== 'number') {
    const idsCompanyCommunications: number[] = [];
    companyCommunicationsToNormalize.push(
      ...(company.companyCommunications as CompanyCommunication[]).map((companyCommunication: CompanyCommunication) => {
        idsCompanyCommunications.push(companyCommunication.idCompanyCommunication);
        return { ...companyCommunication, company: company.idCompany };
      })
    );
    relations['companyCommunications'] = idsCompanyCommunications;
  }
}

function companyNormalizeResidence(
  company: CompanyEntityState,
  residencesToNormalize: ResidenceEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.residences?.length && typeof company.residences[0] !== 'number') {
    const idsResidences: number[] = [];
    residencesToNormalize.push(
      ...(company.residences as Residence[]).map((residence: Residence) => {
        idsResidences.push(residence.idResidence);
        return { ...residence, company: company.idCompany };
      })
    );
    relations['residences'] = idsResidences;
  }
}

function companyNormalizeCompanyPriceLabel(
  company: CompanyEntityState,
  companyPriceLabelsToNormalize: CompanyPriceLabelEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.companyPriceLabels?.length && typeof company.companyPriceLabels[0] !== 'number') {
    const idsCompanyPriceLabels: number[] = [];
    companyPriceLabelsToNormalize.push(
      ...(company.companyPriceLabels as CompanyPriceLabel[]).map((companyPriceLabel: CompanyPriceLabel) => {
        idsCompanyPriceLabels.push(companyPriceLabel.idCompanyPriceLabel);
        return { ...companyPriceLabel, company: company.idCompany };
      })
    );
    relations['companyPriceLabels'] = idsCompanyPriceLabels;
  }
}

function companyNormalizeCompanyTerritoire(
  company: CompanyEntityState,
  companyTerritoiresToNormalize: CompanyTerritoireEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.companyTerritoires?.length && typeof company.companyTerritoires[0] !== 'number') {
    const idsCompanyTerritoires: number[] = [];
    companyTerritoiresToNormalize.push(
      ...(company.companyTerritoires as CompanyTerritoire[]).map((companyTerritoire: CompanyTerritoire) => {
        idsCompanyTerritoires.push(companyTerritoire.idCompanyTerritoire);
        return { ...companyTerritoire, company: company.idCompany };
      })
    );
    relations['companyTerritoires'] = idsCompanyTerritoires;
  }
}

function companyNormalizeOrganizationLeadTodoRule(
  company: CompanyEntityState,
  organizationLeadTodoRulesToNormalize: OrganizationLeadTodoRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.organizationLeadTodoRules?.length && typeof company.organizationLeadTodoRules[0] !== 'number') {
    const idsOrganizationLeadTodoRules: number[] = [];
    organizationLeadTodoRulesToNormalize.push(
      ...(company.organizationLeadTodoRules as OrganizationLeadTodoRule[]).map(
        (organizationLeadTodoRule: OrganizationLeadTodoRule) => {
          idsOrganizationLeadTodoRules.push(organizationLeadTodoRule.idOrganizationLeadTodoRule);
          return { ...organizationLeadTodoRule, company: company.idCompany };
        }
      )
    );
    relations['organizationLeadTodoRules'] = idsOrganizationLeadTodoRules;
  }
}

function companyNormalizeOrganizationStratalotTodoRule(
  company: CompanyEntityState,
  organizationStratalotTodoRulesToNormalize: OrganizationStratalotTodoRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.organizationStratalotTodoRules?.length && typeof company.organizationStratalotTodoRules[0] !== 'number') {
    const idsOrganizationStratalotTodoRules: number[] = [];
    organizationStratalotTodoRulesToNormalize.push(
      ...(company.organizationStratalotTodoRules as OrganizationStratalotTodoRule[]).map(
        (organizationStratalotTodoRule: OrganizationStratalotTodoRule) => {
          idsOrganizationStratalotTodoRules.push(organizationStratalotTodoRule.idOrganizationStratalotTodoRule);
          return { ...organizationStratalotTodoRule, company: company.idCompany };
        }
      )
    );
    relations['organizationStratalotTodoRules'] = idsOrganizationStratalotTodoRules;
  }
}

function companyNormalizeCompanyStratalotType(
  company: CompanyEntityState,
  companyStratalotTypesToNormalize: CompanyStratalotTypeEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.companyStratalotTypes?.length && typeof company.companyStratalotTypes[0] !== 'number') {
    const idsCompanyStratalotTypes: number[] = [];
    companyStratalotTypesToNormalize.push(
      ...(company.companyStratalotTypes as CompanyStratalotType[]).map((companyStratalotType: CompanyStratalotType) => {
        idsCompanyStratalotTypes.push(companyStratalotType.idCompanyStratalotType);
        return { ...companyStratalotType, company: company.idCompany };
      })
    );
    relations['companyStratalotTypes'] = idsCompanyStratalotTypes;
  }
}

function companyNormalizeOrganizationResidenceTodoRule(
  company: CompanyEntityState,
  organizationResidenceTodoRulesToNormalize: OrganizationResidenceTodoRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.organizationResidenceTodoRules?.length && typeof company.organizationResidenceTodoRules[0] !== 'number') {
    const idsOrganizationResidenceTodoRules: number[] = [];
    organizationResidenceTodoRulesToNormalize.push(
      ...(company.organizationResidenceTodoRules as OrganizationResidenceTodoRule[]).map(
        (organizationResidenceTodoRule: OrganizationResidenceTodoRule) => {
          idsOrganizationResidenceTodoRules.push(organizationResidenceTodoRule.idOrganizationResidenceTodoRule);
          return { ...organizationResidenceTodoRule, company: company.idCompany };
        }
      )
    );
    relations['organizationResidenceTodoRules'] = idsOrganizationResidenceTodoRules;
  }
}

function companyNormalizeCompanyStratalotVacant(
  company: CompanyEntityState,
  companyStratalotVacantsToNormalize: CompanyStratalotVacantEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.companyStratalotVacants?.length && typeof company.companyStratalotVacants[0] !== 'number') {
    const idsCompanyStratalotVacants: number[] = [];
    companyStratalotVacantsToNormalize.push(
      ...(company.companyStratalotVacants as CompanyStratalotVacant[]).map(
        (companyStratalotVacant: CompanyStratalotVacant) => {
          idsCompanyStratalotVacants.push(companyStratalotVacant.idCompanyStratalotVacant);
          return { ...companyStratalotVacant, company: company.idCompany };
        }
      )
    );
    relations['companyStratalotVacants'] = idsCompanyStratalotVacants;
  }
}

function companyNormalizeGeneratedDocumentsCompany(
  company: CompanyEntityState,
  generatedDocumentsCompaniesToNormalize: GeneratedDocumentsCompanyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.generatedDocumentsCompanies?.length && typeof company.generatedDocumentsCompanies[0] !== 'number') {
    const idsGeneratedDocumentsCompanies: number[] = [];
    generatedDocumentsCompaniesToNormalize.push(
      ...(company.generatedDocumentsCompanies as GeneratedDocumentsCompany[]).map(
        (generatedDocumentsCompany: GeneratedDocumentsCompany) => {
          idsGeneratedDocumentsCompanies.push(generatedDocumentsCompany.idGeneratedDocumentsCompany);
          return { ...generatedDocumentsCompany, company: company.idCompany };
        }
      )
    );
    relations['generatedDocumentsCompanies'] = idsGeneratedDocumentsCompanies;
  }
}

function companyNormalizeGeneratedDocument(
  company: CompanyEntityState,
  generatedDocumentsToNormalize: { [id: number]: GeneratedDocumentEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (company.generatedDocuments?.length && typeof company.generatedDocuments[0] !== 'number') {
    const idsGeneratedDocuments: number[] = (company.generatedDocuments as GeneratedDocument[]).map(
      (generatedDocument: GeneratedDocument) => {
        if (!generatedDocumentsToNormalize[generatedDocument.idGeneratedDocument]) {
          generatedDocumentsToNormalize[generatedDocument.idGeneratedDocument] = {
            ...generatedDocument,
            companies: []
          };
        }
        (generatedDocumentsToNormalize[generatedDocument.idGeneratedDocument].companies as number[]).push(
          company.idCompany
        );
        return generatedDocument.idGeneratedDocument;
      }
    );
    relations['generatedDocuments'] = idsGeneratedDocuments;
  }
}

function companyNormalizeCompanyUbiflow(
  company: CompanyEntityState,
  companyUbiflowsToNormalize: CompanyUbiflowEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.companyUbiflows?.length && typeof company.companyUbiflows[0] !== 'number') {
    const idsCompanyUbiflows: number[] = [];
    companyUbiflowsToNormalize.push(
      ...(company.companyUbiflows as CompanyUbiflow[]).map((companyUbiflow: CompanyUbiflow) => {
        idsCompanyUbiflows.push(companyUbiflow.idCompanyUbiflow);
        return { ...companyUbiflow, company: company.idCompany };
      })
    );
    relations['companyUbiflows'] = idsCompanyUbiflows;
  }
}

function companyNormalizeCompanyMyNotary(
  company: CompanyEntityState,
  companyMyNotariesToNormalize: CompanyMyNotaryEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.companyMyNotaries?.length && typeof company.companyMyNotaries[0] !== 'number') {
    const idsCompanyMyNotaries: number[] = [];
    companyMyNotariesToNormalize.push(
      ...(company.companyMyNotaries as CompanyMyNotary[]).map((companyMyNotary: CompanyMyNotary) => {
        idsCompanyMyNotaries.push(companyMyNotary.idCompanyMyNotary);
        return { ...companyMyNotary, company: company.idCompany };
      })
    );
    relations['companyMyNotaries'] = idsCompanyMyNotaries;
  }
}

function companyNormalizeCompanyStudyNextAction(
  company: CompanyEntityState,
  companyStudyNextActionsToNormalize: CompanyStudyNextActionEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.companyStudyNextActions?.length && typeof company.companyStudyNextActions[0] !== 'number') {
    const idsCompanyStudyNextActions: number[] = [];
    companyStudyNextActionsToNormalize.push(
      ...(company.companyStudyNextActions as CompanyStudyNextAction[]).map(
        (companyStudyNextAction: CompanyStudyNextAction) => {
          idsCompanyStudyNextActions.push(companyStudyNextAction.idCompanyStudyNextAction);
          return { ...companyStudyNextAction, company: company.idCompany };
        }
      )
    );
    relations['companyStudyNextActions'] = idsCompanyStudyNextActions;
  }
}

function companyNormalizeCompanyStudyConclusion(
  company: CompanyEntityState,
  companyStudyConclusionsToNormalize: CompanyStudyConclusionEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.companyStudyConclusions?.length && typeof company.companyStudyConclusions[0] !== 'number') {
    const idsCompanyStudyConclusions: number[] = [];
    companyStudyConclusionsToNormalize.push(
      ...(company.companyStudyConclusions as CompanyStudyConclusion[]).map(
        (companyStudyConclusion: CompanyStudyConclusion) => {
          idsCompanyStudyConclusions.push(companyStudyConclusion.idCompanyStudyConclusion);
          return { ...companyStudyConclusion, company: company.idCompany };
        }
      )
    );
    relations['companyStudyConclusions'] = idsCompanyStudyConclusions;
  }
}

function companyNormalizeCompanyStudyReason(
  company: CompanyEntityState,
  companyStudyReasonsToNormalize: CompanyStudyReasonEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.companyStudyReasons?.length && typeof company.companyStudyReasons[0] !== 'number') {
    const idsCompanyStudyReasons: number[] = [];
    companyStudyReasonsToNormalize.push(
      ...(company.companyStudyReasons as CompanyStudyReason[]).map((companyStudyReason: CompanyStudyReason) => {
        idsCompanyStudyReasons.push(companyStudyReason.idCompanyStudyReason);
        return { ...companyStudyReason, company: company.idCompany };
      })
    );
    relations['companyStudyReasons'] = idsCompanyStudyReasons;
  }
}

function companyNormalizeCompanyStudyCriteria(
  company: CompanyEntityState,
  companyStudyCriteriasToNormalize: CompanyStudyCriteriaEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.companyStudyCriterias?.length && typeof company.companyStudyCriterias[0] !== 'number') {
    const idsCompanyStudyCriterias: number[] = [];
    companyStudyCriteriasToNormalize.push(
      ...(company.companyStudyCriterias as CompanyStudyCriteria[]).map((companyStudyCriteria: CompanyStudyCriteria) => {
        idsCompanyStudyCriterias.push(companyStudyCriteria.idCompanyStudyCriteria);
        return { ...companyStudyCriteria, company: company.idCompany };
      })
    );
    relations['companyStudyCriterias'] = idsCompanyStudyCriterias;
  }
}

function companyNormalizeResidenceStudy(
  company: CompanyEntityState,
  residenceStudiesToNormalize: ResidenceStudyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (company.residenceStudies?.length && typeof company.residenceStudies[0] !== 'number') {
    const idsResidenceStudies: number[] = [];
    residenceStudiesToNormalize.push(
      ...(company.residenceStudies as ResidenceStudy[]).map((residenceStudy: ResidenceStudy) => {
        idsResidenceStudies.push(residenceStudy.idResidenceStudy);
        return { ...residenceStudy, company: company.idCompany };
      })
    );
    relations['residenceStudies'] = idsResidenceStudies;
  }
}

function companyNormalizeOrganization(
  company: CompanyEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (company.organization && typeof company.organization !== 'number') {
    if (!organizationsToNormalize[company.organization.idOrganization]) {
      organizationsToNormalize[company.organization.idOrganization] = { ...company.organization, companies: [] };
    }
    (organizationsToNormalize[company.organization.idOrganization].companies as number[]).push(company.idCompany);
    relations['organization'] = company.organization.idOrganization;
  }
}

/**
 *  CompanyUbiflow
 */
export function getActionsToNormalizeCompanyUbiflow(
  companyUbiflows: CompanyUbiflowEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};

  const normalizedCompanyUbiflows = companyUbiflows.map((companyUbiflow: CompanyUbiflowEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    companyUbiflowNormalizeCompany(companyUbiflow, companiesToNormalize, relations);

    return {
      ...companyUbiflow,
      ...relations
    };
  });
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }

  const companyUbiflowActionCreator = companyUbiflowActions.upsertManyCompanyUbiflowsSuccess;
  actions.push(companyUbiflowActionCreator({ companyUbiflows: normalizedCompanyUbiflows }));

  return actions;
}

function companyUbiflowNormalizeCompany(
  companyUbiflow: CompanyUbiflowEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyUbiflow.company && typeof companyUbiflow.company !== 'number') {
    if (!companiesToNormalize[companyUbiflow.company.idCompany]) {
      companiesToNormalize[companyUbiflow.company.idCompany] = { ...companyUbiflow.company, companyUbiflows: [] };
    }
    (companiesToNormalize[companyUbiflow.company.idCompany].companyUbiflows as number[]).push(
      companyUbiflow.idCompanyUbiflow
    );
    relations['company'] = companyUbiflow.company.idCompany;
  }
}

/**
 *  CompanyMyNotary
 */
export function getActionsToNormalizeCompanyMyNotary(
  companyMyNotaries: CompanyMyNotaryEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};

  const normalizedCompanyMyNotaries = companyMyNotaries.map((companyMyNotary: CompanyMyNotaryEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    companyMyNotaryNormalizeCompany(companyMyNotary, companiesToNormalize, relations);

    return {
      ...companyMyNotary,
      ...relations
    };
  });
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }

  const companyMyNotaryActionCreator = companyMyNotaryActions.upsertManyCompanyMyNotariesSuccess;
  actions.push(companyMyNotaryActionCreator({ companyMyNotaries: normalizedCompanyMyNotaries }));

  return actions;
}

function companyMyNotaryNormalizeCompany(
  companyMyNotary: CompanyMyNotaryEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyMyNotary.company && typeof companyMyNotary.company !== 'number') {
    if (!companiesToNormalize[companyMyNotary.company.idCompany]) {
      companiesToNormalize[companyMyNotary.company.idCompany] = { ...companyMyNotary.company, companyMyNotaries: [] };
    }
    (companiesToNormalize[companyMyNotary.company.idCompany].companyMyNotaries as number[]).push(
      companyMyNotary.idCompanyMyNotary
    );
    relations['company'] = companyMyNotary.company.idCompany;
  }
}

/**
 *  CompanyPriceLabel
 */
export function getActionsToNormalizeCompanyPriceLabel(
  companyPriceLabels: CompanyPriceLabelEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const familySaleCategoriesToNormalize: { [id: number]: SaleCategoryFamilyEntityState } = {};
  const stratalotPricesToNormalize: { [id: number]: StratalotPriceEntityState } = {};
  const stratalotPriceValuesToNormalize: StratalotPriceValueEntityState[] = [];
  const residencePriceGridModifiersToNormalize: ResidencePriceGridModifierEntityState[] = [];
  const organizationProfilsToNormalize: OrganizationProfilEntityState[] = [];

  const normalizedCompanyPriceLabels = companyPriceLabels.map((companyPriceLabel: CompanyPriceLabelEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    companyPriceLabelNormalizeStratalotPrice(companyPriceLabel, stratalotPricesToNormalize, relations);
    companyPriceLabelNormalizeStratalotPriceValue(companyPriceLabel, stratalotPriceValuesToNormalize, relations);
    companyPriceLabelNormalizeResidencePriceGridModifier(
      companyPriceLabel,
      residencePriceGridModifiersToNormalize,
      relations
    );
    companyPriceLabelNormalizeOrganizationProfil(companyPriceLabel, organizationProfilsToNormalize, relations);
    companyPriceLabelNormalizeCompany(companyPriceLabel, companiesToNormalize, relations);
    companyPriceLabelNormalizeSaleCategoryFamily(companyPriceLabel, familySaleCategoriesToNormalize, relations);

    return {
      ...companyPriceLabel,
      ...relations
    };
  });
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  const familySaleCategoriesToNormalizeArray = Object.values(familySaleCategoriesToNormalize);
  if (familySaleCategoriesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSaleCategoryFamily(familySaleCategoriesToNormalizeArray, actionType));
  }
  const stratalotPricesToNormalizeArray = Object.values(stratalotPricesToNormalize);
  if (stratalotPricesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalotPrice(stratalotPricesToNormalizeArray, actionType));
  }
  if (stratalotPriceValuesToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalotPriceValue(stratalotPriceValuesToNormalize, actionType));
  }
  if (residencePriceGridModifiersToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeResidencePriceGridModifier(residencePriceGridModifiersToNormalize, actionType)
    );
  }
  if (organizationProfilsToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationProfil(organizationProfilsToNormalize, actionType));
  }

  const companyPriceLabelActionCreator = companyPriceLabelActions.upsertManyCompanyPriceLabelsSuccess;
  actions.push(companyPriceLabelActionCreator({ companyPriceLabels: normalizedCompanyPriceLabels }));

  return actions;
}

function companyPriceLabelNormalizeStratalotPrice(
  companyPriceLabel: CompanyPriceLabelEntityState,
  stratalotPricesToNormalize: { [id: number]: StratalotPriceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyPriceLabel.stratalotPrices?.length && typeof companyPriceLabel.stratalotPrices[0] !== 'number') {
    const idsStratalotPrices: number[] = (companyPriceLabel.stratalotPrices as StratalotPrice[]).map(
      (stratalotPrice: StratalotPrice) => {
        if (!stratalotPricesToNormalize[stratalotPrice.idStratalotPrice]) {
          stratalotPricesToNormalize[stratalotPrice.idStratalotPrice] = { ...stratalotPrice, companyPriceLabels: [] };
        }
        (stratalotPricesToNormalize[stratalotPrice.idStratalotPrice].companyPriceLabels as number[]).push(
          companyPriceLabel.idCompanyPriceLabel
        );
        return stratalotPrice.idStratalotPrice;
      }
    );
    relations['stratalotPrices'] = idsStratalotPrices;
  }
}

function companyPriceLabelNormalizeStratalotPriceValue(
  companyPriceLabel: CompanyPriceLabelEntityState,
  stratalotPriceValuesToNormalize: StratalotPriceValueEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (companyPriceLabel.stratalotPriceValues?.length && typeof companyPriceLabel.stratalotPriceValues[0] !== 'number') {
    const idsStratalotPriceValues: number[] = [];
    stratalotPriceValuesToNormalize.push(
      ...(companyPriceLabel.stratalotPriceValues as StratalotPriceValue[]).map(
        (stratalotPriceValue: StratalotPriceValue) => {
          idsStratalotPriceValues.push(stratalotPriceValue.idStratalotPriceValue);
          return { ...stratalotPriceValue, companyPriceLabel: companyPriceLabel.idCompanyPriceLabel };
        }
      )
    );
    relations['stratalotPriceValues'] = idsStratalotPriceValues;
  }
}

function companyPriceLabelNormalizeResidencePriceGridModifier(
  companyPriceLabel: CompanyPriceLabelEntityState,
  residencePriceGridModifiersToNormalize: ResidencePriceGridModifierEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyPriceLabel.residencePriceGridModifiers?.length &&
    typeof companyPriceLabel.residencePriceGridModifiers[0] !== 'number'
  ) {
    const idsResidencePriceGridModifiers: number[] = [];
    residencePriceGridModifiersToNormalize.push(
      ...(companyPriceLabel.residencePriceGridModifiers as ResidencePriceGridModifier[]).map(
        (residencePriceGridModifier: ResidencePriceGridModifier) => {
          idsResidencePriceGridModifiers.push(residencePriceGridModifier.idResidencePriceGridModifier);
          return { ...residencePriceGridModifier, companyPriceLabel: companyPriceLabel.idCompanyPriceLabel };
        }
      )
    );
    relations['residencePriceGridModifiers'] = idsResidencePriceGridModifiers;
  }
}

function companyPriceLabelNormalizeOrganizationProfil(
  companyPriceLabel: CompanyPriceLabelEntityState,
  organizationProfilsToNormalize: OrganizationProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (companyPriceLabel.organizationProfils?.length && typeof companyPriceLabel.organizationProfils[0] !== 'number') {
    const idsOrganizationProfils: number[] = [];
    organizationProfilsToNormalize.push(
      ...(companyPriceLabel.organizationProfils as OrganizationProfil[]).map(
        (organizationProfil: OrganizationProfil) => {
          idsOrganizationProfils.push(organizationProfil.idOrganizationProfil);
          return { ...organizationProfil, companyPriceLabel: companyPriceLabel.idCompanyPriceLabel };
        }
      )
    );
    relations['organizationProfils'] = idsOrganizationProfils;
  }
}

function companyPriceLabelNormalizeCompany(
  companyPriceLabel: CompanyPriceLabelEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyPriceLabel.company && typeof companyPriceLabel.company !== 'number') {
    if (!companiesToNormalize[companyPriceLabel.company.idCompany]) {
      companiesToNormalize[companyPriceLabel.company.idCompany] = {
        ...companyPriceLabel.company,
        companyPriceLabels: []
      };
    }
    (companiesToNormalize[companyPriceLabel.company.idCompany].companyPriceLabels as number[]).push(
      companyPriceLabel.idCompanyPriceLabel
    );
    relations['company'] = companyPriceLabel.company.idCompany;
  }
}

function companyPriceLabelNormalizeSaleCategoryFamily(
  companyPriceLabel: CompanyPriceLabelEntityState,
  familySaleCategoriesToNormalize: { [id: number]: SaleCategoryFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyPriceLabel.saleCategoryFamily && typeof companyPriceLabel.saleCategoryFamily !== 'number') {
    if (!familySaleCategoriesToNormalize[companyPriceLabel.saleCategoryFamily.idSaleCategoryFamily]) {
      familySaleCategoriesToNormalize[companyPriceLabel.saleCategoryFamily.idSaleCategoryFamily] = {
        ...companyPriceLabel.saleCategoryFamily,
        companyPriceLabels: []
      };
    }
    (
      familySaleCategoriesToNormalize[companyPriceLabel.saleCategoryFamily.idSaleCategoryFamily]
        .companyPriceLabels as number[]
    ).push(companyPriceLabel.idCompanyPriceLabel);
    relations['saleCategoryFamily'] = companyPriceLabel.saleCategoryFamily.idSaleCategoryFamily;
  }
}

/**
 *  CompanyStratalotVacant
 */
export function getActionsToNormalizeCompanyStratalotVacant(
  companyStratalotVacants: CompanyStratalotVacantEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const stratalotsToNormalize: StratalotEntityState[] = [];

  const normalizedCompanyStratalotVacants = companyStratalotVacants.map(
    (companyStratalotVacant: CompanyStratalotVacantEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      companyStratalotVacantNormalizeStratalot(companyStratalotVacant, stratalotsToNormalize, relations);
      companyStratalotVacantNormalizeCompany(companyStratalotVacant, companiesToNormalize, relations);

      return {
        ...companyStratalotVacant,
        ...relations
      };
    }
  );
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  if (stratalotsToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalize, actionType));
  }

  const companyStratalotVacantActionCreator = companyStratalotVacantActions.upsertManyCompanyStratalotVacantsSuccess;
  actions.push(companyStratalotVacantActionCreator({ companyStratalotVacants: normalizedCompanyStratalotVacants }));

  return actions;
}

function companyStratalotVacantNormalizeStratalot(
  companyStratalotVacant: CompanyStratalotVacantEntityState,
  stratalotsToNormalize: StratalotEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (companyStratalotVacant.stratalots?.length && typeof companyStratalotVacant.stratalots[0] !== 'number') {
    const idsStratalots: number[] = [];
    stratalotsToNormalize.push(
      ...(companyStratalotVacant.stratalots as Stratalot[]).map((stratalot: Stratalot) => {
        idsStratalots.push(stratalot.idStratalot);
        return { ...stratalot, companyStratalotVacant: companyStratalotVacant.idCompanyStratalotVacant };
      })
    );
    relations['stratalots'] = idsStratalots;
  }
}

function companyStratalotVacantNormalizeCompany(
  companyStratalotVacant: CompanyStratalotVacantEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyStratalotVacant.company && typeof companyStratalotVacant.company !== 'number') {
    if (!companiesToNormalize[companyStratalotVacant.company.idCompany]) {
      companiesToNormalize[companyStratalotVacant.company.idCompany] = {
        ...companyStratalotVacant.company,
        companyStratalotVacants: []
      };
    }
    (companiesToNormalize[companyStratalotVacant.company.idCompany].companyStratalotVacants as number[]).push(
      companyStratalotVacant.idCompanyStratalotVacant
    );
    relations['company'] = companyStratalotVacant.company.idCompany;
  }
}

/**
 *  CompanyStudyNextAction
 */
export function getActionsToNormalizeCompanyStudyNextAction(
  companyStudyNextActions: CompanyStudyNextActionEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const residenceStudiesToNormalize: ResidenceStudyEntityState[] = [];

  const normalizedCompanyStudyNextActions = companyStudyNextActions.map(
    (companyStudyNextAction: CompanyStudyNextActionEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      companyStudyNextActionNormalizeResidenceStudy(companyStudyNextAction, residenceStudiesToNormalize, relations);
      companyStudyNextActionNormalizeCompany(companyStudyNextAction, companiesToNormalize, relations);

      return {
        ...companyStudyNextAction,
        ...relations
      };
    }
  );
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  if (residenceStudiesToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceStudy(residenceStudiesToNormalize, actionType));
  }

  const companyStudyNextActionActionCreator = companyStudyNextActionActions.upsertManyCompanyStudyNextActionsSuccess;
  actions.push(companyStudyNextActionActionCreator({ companyStudyNextActions: normalizedCompanyStudyNextActions }));

  return actions;
}

function companyStudyNextActionNormalizeResidenceStudy(
  companyStudyNextAction: CompanyStudyNextActionEntityState,
  residenceStudiesToNormalize: ResidenceStudyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyStudyNextAction.residenceStudies?.length &&
    typeof companyStudyNextAction.residenceStudies[0] !== 'number'
  ) {
    const idsResidenceStudies: number[] = [];
    residenceStudiesToNormalize.push(
      ...(companyStudyNextAction.residenceStudies as ResidenceStudy[]).map((residenceStudy: ResidenceStudy) => {
        idsResidenceStudies.push(residenceStudy.idResidenceStudy);
        return { ...residenceStudy, companyStudyNextAction: companyStudyNextAction.idCompanyStudyNextAction };
      })
    );
    relations['residenceStudies'] = idsResidenceStudies;
  }
}

function companyStudyNextActionNormalizeCompany(
  companyStudyNextAction: CompanyStudyNextActionEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyStudyNextAction.company && typeof companyStudyNextAction.company !== 'number') {
    if (!companiesToNormalize[companyStudyNextAction.company.idCompany]) {
      companiesToNormalize[companyStudyNextAction.company.idCompany] = {
        ...companyStudyNextAction.company,
        companyStudyNextActions: []
      };
    }
    (companiesToNormalize[companyStudyNextAction.company.idCompany].companyStudyNextActions as number[]).push(
      companyStudyNextAction.idCompanyStudyNextAction
    );
    relations['company'] = companyStudyNextAction.company.idCompany;
  }
}

/**
 *  CompanyStudyConclusion
 */
export function getActionsToNormalizeCompanyStudyConclusion(
  companyStudyConclusions: CompanyStudyConclusionEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const residenceStudiesToNormalize: ResidenceStudyEntityState[] = [];

  const normalizedCompanyStudyConclusions = companyStudyConclusions.map(
    (companyStudyConclusion: CompanyStudyConclusionEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      companyStudyConclusionNormalizeResidenceStudy(companyStudyConclusion, residenceStudiesToNormalize, relations);
      companyStudyConclusionNormalizeCompany(companyStudyConclusion, companiesToNormalize, relations);

      return {
        ...companyStudyConclusion,
        ...relations
      };
    }
  );
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  if (residenceStudiesToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceStudy(residenceStudiesToNormalize, actionType));
  }

  const companyStudyConclusionActionCreator = companyStudyConclusionActions.upsertManyCompanyStudyConclusionsSuccess;
  actions.push(companyStudyConclusionActionCreator({ companyStudyConclusions: normalizedCompanyStudyConclusions }));

  return actions;
}

function companyStudyConclusionNormalizeResidenceStudy(
  companyStudyConclusion: CompanyStudyConclusionEntityState,
  residenceStudiesToNormalize: ResidenceStudyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyStudyConclusion.residenceStudies?.length &&
    typeof companyStudyConclusion.residenceStudies[0] !== 'number'
  ) {
    const idsResidenceStudies: number[] = [];
    residenceStudiesToNormalize.push(
      ...(companyStudyConclusion.residenceStudies as ResidenceStudy[]).map((residenceStudy: ResidenceStudy) => {
        idsResidenceStudies.push(residenceStudy.idResidenceStudy);
        return { ...residenceStudy, companyStudyConclusion: companyStudyConclusion.idCompanyStudyConclusion };
      })
    );
    relations['residenceStudies'] = idsResidenceStudies;
  }
}

function companyStudyConclusionNormalizeCompany(
  companyStudyConclusion: CompanyStudyConclusionEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyStudyConclusion.company && typeof companyStudyConclusion.company !== 'number') {
    if (!companiesToNormalize[companyStudyConclusion.company.idCompany]) {
      companiesToNormalize[companyStudyConclusion.company.idCompany] = {
        ...companyStudyConclusion.company,
        companyStudyConclusions: []
      };
    }
    (companiesToNormalize[companyStudyConclusion.company.idCompany].companyStudyConclusions as number[]).push(
      companyStudyConclusion.idCompanyStudyConclusion
    );
    relations['company'] = companyStudyConclusion.company.idCompany;
  }
}

/**
 *  CompanyStudyReason
 */
export function getActionsToNormalizeCompanyStudyReason(
  companyStudyReasons: CompanyStudyReasonEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const residenceStudyReasonsToNormalize: ResidenceStudyReasonEntityState[] = [];
  const residenceStudiesToNormalize: { [id: number]: ResidenceStudyEntityState } = {};

  const normalizedCompanyStudyReasons = companyStudyReasons.map((companyStudyReason: CompanyStudyReasonEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    companyStudyReasonNormalizeResidenceStudyReason(companyStudyReason, residenceStudyReasonsToNormalize, relations);
    companyStudyReasonNormalizeResidenceStudy(companyStudyReason, residenceStudiesToNormalize, relations);
    companyStudyReasonNormalizeCompany(companyStudyReason, companiesToNormalize, relations);

    return {
      ...companyStudyReason,
      ...relations
    };
  });
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  if (residenceStudyReasonsToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceStudyReason(residenceStudyReasonsToNormalize, actionType));
  }
  const residenceStudiesToNormalizeArray = Object.values(residenceStudiesToNormalize);
  if (residenceStudiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidenceStudy(residenceStudiesToNormalizeArray, actionType));
  }

  const companyStudyReasonActionCreator = companyStudyReasonActions.upsertManyCompanyStudyReasonsSuccess;
  actions.push(companyStudyReasonActionCreator({ companyStudyReasons: normalizedCompanyStudyReasons }));

  return actions;
}

function companyStudyReasonNormalizeResidenceStudyReason(
  companyStudyReason: CompanyStudyReasonEntityState,
  residenceStudyReasonsToNormalize: ResidenceStudyReasonEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyStudyReason.residenceStudyReasons?.length &&
    typeof companyStudyReason.residenceStudyReasons[0] !== 'number'
  ) {
    const idsResidenceStudyReasons: number[] = [];
    residenceStudyReasonsToNormalize.push(
      ...(companyStudyReason.residenceStudyReasons as ResidenceStudyReason[]).map(
        (residenceStudyReason: ResidenceStudyReason) => {
          idsResidenceStudyReasons.push(residenceStudyReason.idResidenceStudyReason);
          return { ...residenceStudyReason, companyStudyReason: companyStudyReason.idCompanyStudyReason };
        }
      )
    );
    relations['residenceStudyReasons'] = idsResidenceStudyReasons;
  }
}

function companyStudyReasonNormalizeResidenceStudy(
  companyStudyReason: CompanyStudyReasonEntityState,
  residenceStudiesToNormalize: { [id: number]: ResidenceStudyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyStudyReason.residenceStudies?.length && typeof companyStudyReason.residenceStudies[0] !== 'number') {
    const idsResidenceStudies: number[] = (companyStudyReason.residenceStudies as ResidenceStudy[]).map(
      (residenceStudy: ResidenceStudy) => {
        if (!residenceStudiesToNormalize[residenceStudy.idResidenceStudy]) {
          residenceStudiesToNormalize[residenceStudy.idResidenceStudy] = { ...residenceStudy, companyStudyReasons: [] };
        }
        (residenceStudiesToNormalize[residenceStudy.idResidenceStudy].companyStudyReasons as number[]).push(
          companyStudyReason.idCompanyStudyReason
        );
        return residenceStudy.idResidenceStudy;
      }
    );
    relations['residenceStudies'] = idsResidenceStudies;
  }
}

function companyStudyReasonNormalizeCompany(
  companyStudyReason: CompanyStudyReasonEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyStudyReason.company && typeof companyStudyReason.company !== 'number') {
    if (!companiesToNormalize[companyStudyReason.company.idCompany]) {
      companiesToNormalize[companyStudyReason.company.idCompany] = {
        ...companyStudyReason.company,
        companyStudyReasons: []
      };
    }
    (companiesToNormalize[companyStudyReason.company.idCompany].companyStudyReasons as number[]).push(
      companyStudyReason.idCompanyStudyReason
    );
    relations['company'] = companyStudyReason.company.idCompany;
  }
}

/**
 *  CompanyStudyCriteria
 */
export function getActionsToNormalizeCompanyStudyCriteria(
  companyStudyCriterias: CompanyStudyCriteriaEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const residenceStudyCriteriasToNormalize: ResidenceStudyCriteriaEntityState[] = [];

  const normalizedCompanyStudyCriterias = companyStudyCriterias.map(
    (companyStudyCriteria: CompanyStudyCriteriaEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      companyStudyCriteriaNormalizeResidenceStudyCriteria(
        companyStudyCriteria,
        residenceStudyCriteriasToNormalize,
        relations
      );
      companyStudyCriteriaNormalizeCompany(companyStudyCriteria, companiesToNormalize, relations);

      return {
        ...companyStudyCriteria,
        ...relations
      };
    }
  );
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  if (residenceStudyCriteriasToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceStudyCriteria(residenceStudyCriteriasToNormalize, actionType));
  }

  const companyStudyCriteriaActionCreator = companyStudyCriteriaActions.upsertManyCompanyStudyCriteriasSuccess;
  actions.push(companyStudyCriteriaActionCreator({ companyStudyCriterias: normalizedCompanyStudyCriterias }));

  return actions;
}

function companyStudyCriteriaNormalizeResidenceStudyCriteria(
  companyStudyCriteria: CompanyStudyCriteriaEntityState,
  residenceStudyCriteriasToNormalize: ResidenceStudyCriteriaEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyStudyCriteria.residenceStudyCriterias?.length &&
    typeof companyStudyCriteria.residenceStudyCriterias[0] !== 'number'
  ) {
    const idsResidenceStudyCriterias: number[] = [];
    residenceStudyCriteriasToNormalize.push(
      ...(companyStudyCriteria.residenceStudyCriterias as ResidenceStudyCriteria[]).map(
        (residenceStudyCriteria: ResidenceStudyCriteria) => {
          idsResidenceStudyCriterias.push(residenceStudyCriteria.idResidenceStudyCriteria);
          return { ...residenceStudyCriteria, companyStudyCriteria: companyStudyCriteria.idCompanyStudyCriteria };
        }
      )
    );
    relations['residenceStudyCriterias'] = idsResidenceStudyCriterias;
  }
}

function companyStudyCriteriaNormalizeCompany(
  companyStudyCriteria: CompanyStudyCriteriaEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyStudyCriteria.company && typeof companyStudyCriteria.company !== 'number') {
    if (!companiesToNormalize[companyStudyCriteria.company.idCompany]) {
      companiesToNormalize[companyStudyCriteria.company.idCompany] = {
        ...companyStudyCriteria.company,
        companyStudyCriterias: []
      };
    }
    (companiesToNormalize[companyStudyCriteria.company.idCompany].companyStudyCriterias as number[]).push(
      companyStudyCriteria.idCompanyStudyCriteria
    );
    relations['company'] = companyStudyCriteria.company.idCompany;
  }
}

/**
 *  CompanyTerritoire
 */
export function getActionsToNormalizeCompanyTerritoire(
  companyTerritoires: CompanyTerritoireEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const responsableToNormalize: { [id: number]: UserEntityState } = {};
  const companyTerritoireUsersToNormalize: CompanyTerritoireUserEntityState[] = [];
  const residencesToNormalize: { [id: number]: ResidenceEntityState } = {};

  const normalizedCompanyTerritoires = companyTerritoires.map((companyTerritoire: CompanyTerritoireEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    companyTerritoireNormalizeCompanyTerritoireUser(companyTerritoire, companyTerritoireUsersToNormalize, relations);
    companyTerritoireNormalizeResidence(companyTerritoire, residencesToNormalize, relations);
    companyTerritoireNormalizeCompany(companyTerritoire, companiesToNormalize, relations);
    companyTerritoireNormalizeResponsable(companyTerritoire, responsableToNormalize, relations);

    return {
      ...companyTerritoire,
      ...relations
    };
  });
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  const responsableToNormalizeArray = Object.values(responsableToNormalize);
  if (responsableToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(responsableToNormalizeArray, actionType));
  }
  if (companyTerritoireUsersToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyTerritoireUser(companyTerritoireUsersToNormalize, actionType));
  }
  const residencesToNormalizeArray = Object.values(residencesToNormalize);
  if (residencesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalizeArray, actionType));
  }

  const companyTerritoireActionCreator = companyTerritoireActions.upsertManyCompanyTerritoiresSuccess;
  actions.push(companyTerritoireActionCreator({ companyTerritoires: normalizedCompanyTerritoires }));

  return actions;
}

function companyTerritoireNormalizeCompanyTerritoireUser(
  companyTerritoire: CompanyTerritoireEntityState,
  companyTerritoireUsersToNormalize: CompanyTerritoireUserEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyTerritoire.companyTerritoireUsers?.length &&
    typeof companyTerritoire.companyTerritoireUsers[0] !== 'number'
  ) {
    const idsCompanyTerritoireUsers: number[] = [];
    companyTerritoireUsersToNormalize.push(
      ...(companyTerritoire.companyTerritoireUsers as CompanyTerritoireUser[]).map(
        (companyTerritoireUser: CompanyTerritoireUser) => {
          idsCompanyTerritoireUsers.push(companyTerritoireUser.idCompanyTerritoireUser);
          return { ...companyTerritoireUser, companyTerritoire: companyTerritoire.idCompanyTerritoire };
        }
      )
    );
    relations['companyTerritoireUsers'] = idsCompanyTerritoireUsers;
  }
}

function companyTerritoireNormalizeResidence(
  companyTerritoire: CompanyTerritoireEntityState,
  residencesToNormalize: { [id: number]: ResidenceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyTerritoire.residences?.length && typeof companyTerritoire.residences[0] !== 'number') {
    const idsResidences: number[] = (companyTerritoire.residences as Residence[]).map((residence: Residence) => {
      if (!residencesToNormalize[residence.idResidence]) {
        residencesToNormalize[residence.idResidence] = { ...residence, companyTerritoires: [] };
      }
      (residencesToNormalize[residence.idResidence].companyTerritoires as number[]).push(
        companyTerritoire.idCompanyTerritoire
      );
      return residence.idResidence;
    });
    relations['residences'] = idsResidences;
  }
}

function companyTerritoireNormalizeCompany(
  companyTerritoire: CompanyTerritoireEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyTerritoire.company && typeof companyTerritoire.company !== 'number') {
    if (!companiesToNormalize[companyTerritoire.company.idCompany]) {
      companiesToNormalize[companyTerritoire.company.idCompany] = {
        ...companyTerritoire.company,
        companyTerritoires: []
      };
    }
    (companiesToNormalize[companyTerritoire.company.idCompany].companyTerritoires as number[]).push(
      companyTerritoire.idCompanyTerritoire
    );
    relations['company'] = companyTerritoire.company.idCompany;
  }
}

function companyTerritoireNormalizeResponsable(
  companyTerritoire: CompanyTerritoireEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyTerritoire.responsable && typeof companyTerritoire.responsable !== 'number') {
    if (!usersToNormalize[companyTerritoire.responsable.idUser]) {
      usersToNormalize[companyTerritoire.responsable.idUser] = {
        ...companyTerritoire.responsable,
        companyTerritoires: []
      };
    }
    (usersToNormalize[companyTerritoire.responsable.idUser].companyTerritoires as number[]).push(
      companyTerritoire.idCompanyTerritoire
    );
    relations['responsable'] = companyTerritoire.responsable.idUser;
  }
}

/**
 *  CompanyTerritoireUser
 */
export function getActionsToNormalizeCompanyTerritoireUser(
  companyTerritoireUsers: CompanyTerritoireUserEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companyTerritoiresToNormalize: { [id: number]: CompanyTerritoireEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};

  const normalizedCompanyTerritoireUsers = companyTerritoireUsers.map(
    (companyTerritoireUser: CompanyTerritoireUserEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      companyTerritoireUserNormalizeCompanyTerritoire(companyTerritoireUser, companyTerritoiresToNormalize, relations);
      companyTerritoireUserNormalizeUser(companyTerritoireUser, usersToNormalize, relations);

      return {
        ...companyTerritoireUser,
        ...relations
      };
    }
  );
  const companyTerritoiresToNormalizeArray = Object.values(companyTerritoiresToNormalize);
  if (companyTerritoiresToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyTerritoire(companyTerritoiresToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }

  const companyTerritoireUserActionCreator = companyTerritoireUserActions.upsertManyCompanyTerritoireUsersSuccess;
  actions.push(companyTerritoireUserActionCreator({ companyTerritoireUsers: normalizedCompanyTerritoireUsers }));

  return actions;
}

function companyTerritoireUserNormalizeCompanyTerritoire(
  companyTerritoireUser: CompanyTerritoireUserEntityState,
  companyTerritoiresToNormalize: { [id: number]: CompanyTerritoireEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyTerritoireUser.companyTerritoire && typeof companyTerritoireUser.companyTerritoire !== 'number') {
    if (!companyTerritoiresToNormalize[companyTerritoireUser.companyTerritoire.idCompanyTerritoire]) {
      companyTerritoiresToNormalize[companyTerritoireUser.companyTerritoire.idCompanyTerritoire] = {
        ...companyTerritoireUser.companyTerritoire,
        companyTerritoireUsers: []
      };
    }
    (
      companyTerritoiresToNormalize[companyTerritoireUser.companyTerritoire.idCompanyTerritoire]
        .companyTerritoireUsers as number[]
    ).push(companyTerritoireUser.idCompanyTerritoireUser);
    relations['companyTerritoire'] = companyTerritoireUser.companyTerritoire.idCompanyTerritoire;
  }
}

function companyTerritoireUserNormalizeUser(
  companyTerritoireUser: CompanyTerritoireUserEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyTerritoireUser.user && typeof companyTerritoireUser.user !== 'number') {
    if (!usersToNormalize[companyTerritoireUser.user.idUser]) {
      usersToNormalize[companyTerritoireUser.user.idUser] = {
        ...companyTerritoireUser.user,
        companyTerritoireUsers: []
      };
    }
    (usersToNormalize[companyTerritoireUser.user.idUser].companyTerritoireUsers as number[]).push(
      companyTerritoireUser.idCompanyTerritoireUser
    );
    relations['user'] = companyTerritoireUser.user.idUser;
  }
}

/**
 *  Diagnostic
 */
export function getActionsToNormalizeDiagnostic(
  diagnostics: DiagnosticEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationStratalotTodosToNormalize: OrganizationStratalotTodoEntityState[] = [];
  const organizationResidenceTodosToNormalize: OrganizationResidenceTodoEntityState[] = [];

  const normalizedDiagnostics = diagnostics.map((diagnostic: DiagnosticEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    diagnosticNormalizeOrganizationStratalotTodo(diagnostic, organizationStratalotTodosToNormalize, relations);
    diagnosticNormalizeOrganizationResidenceTodo(diagnostic, organizationResidenceTodosToNormalize, relations);

    return {
      ...diagnostic,
      ...relations
    };
  });
  if (organizationStratalotTodosToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationStratalotTodo(organizationStratalotTodosToNormalize, actionType));
  }
  if (organizationResidenceTodosToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationResidenceTodo(organizationResidenceTodosToNormalize, actionType));
  }

  const diagnosticActionCreator = diagnosticActions.upsertManyDiagnosticsSuccess;
  actions.push(diagnosticActionCreator({ diagnostics: normalizedDiagnostics }));

  return actions;
}

function diagnosticNormalizeOrganizationStratalotTodo(
  diagnostic: DiagnosticEntityState,
  organizationStratalotTodosToNormalize: OrganizationStratalotTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (diagnostic.organizationStratalotTodos?.length && typeof diagnostic.organizationStratalotTodos[0] !== 'number') {
    const idsOrganizationStratalotTodos: number[] = [];
    organizationStratalotTodosToNormalize.push(
      ...(diagnostic.organizationStratalotTodos as OrganizationStratalotTodo[]).map(
        (organizationStratalotTodo: OrganizationStratalotTodo) => {
          idsOrganizationStratalotTodos.push(organizationStratalotTodo.idOrganizationStratalotTodo);
          return { ...organizationStratalotTodo, diagnostic: diagnostic.idDiagnostic };
        }
      )
    );
    relations['organizationStratalotTodos'] = idsOrganizationStratalotTodos;
  }
}

function diagnosticNormalizeOrganizationResidenceTodo(
  diagnostic: DiagnosticEntityState,
  organizationResidenceTodosToNormalize: OrganizationResidenceTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (diagnostic.organizationResidenceTodos?.length && typeof diagnostic.organizationResidenceTodos[0] !== 'number') {
    const idsOrganizationResidenceTodos: number[] = [];
    organizationResidenceTodosToNormalize.push(
      ...(diagnostic.organizationResidenceTodos as OrganizationResidenceTodo[]).map(
        (organizationResidenceTodo: OrganizationResidenceTodo) => {
          idsOrganizationResidenceTodos.push(organizationResidenceTodo.idOrganizationResidenceTodo);
          return { ...organizationResidenceTodo, diagnostic: diagnostic.idDiagnostic };
        }
      )
    );
    relations['organizationResidenceTodos'] = idsOrganizationResidenceTodos;
  }
}

/**
 *  DiffusionVisual
 */
export function getActionsToNormalizeDiffusionVisual(
  diffusionVisuals: DiffusionVisualEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residencesToNormalize: { [id: number]: ResidenceEntityState } = {};
  const stratalotsToNormalize: { [id: number]: StratalotEntityState } = {};

  const normalizedDiffusionVisuals = diffusionVisuals.map((diffusionVisual: DiffusionVisualEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    diffusionVisualNormalizeResidence(diffusionVisual, residencesToNormalize, relations);
    diffusionVisualNormalizeStratalot(diffusionVisual, stratalotsToNormalize, relations);

    return {
      ...diffusionVisual,
      ...relations
    };
  });
  const residencesToNormalizeArray = Object.values(residencesToNormalize);
  if (residencesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalizeArray, actionType));
  }
  const stratalotsToNormalizeArray = Object.values(stratalotsToNormalize);
  if (stratalotsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalizeArray, actionType));
  }

  const diffusionVisualActionCreator = diffusionVisualActions.upsertManyDiffusionVisualsSuccess;
  actions.push(diffusionVisualActionCreator({ diffusionVisuals: normalizedDiffusionVisuals }));

  return actions;
}

function diffusionVisualNormalizeResidence(
  diffusionVisual: DiffusionVisualEntityState,
  residencesToNormalize: { [id: number]: ResidenceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (diffusionVisual.residence && typeof diffusionVisual.residence !== 'number') {
    if (!residencesToNormalize[diffusionVisual.residence.idResidence]) {
      residencesToNormalize[diffusionVisual.residence.idResidence] = {
        ...diffusionVisual.residence,
        diffusionVisuals: []
      };
    }
    (residencesToNormalize[diffusionVisual.residence.idResidence].diffusionVisuals as number[]).push(
      diffusionVisual.idDiffusionVisual
    );
    relations['residence'] = diffusionVisual.residence.idResidence;
  }
}

function diffusionVisualNormalizeStratalot(
  diffusionVisual: DiffusionVisualEntityState,
  stratalotsToNormalize: { [id: number]: StratalotEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (diffusionVisual.stratalot && typeof diffusionVisual.stratalot !== 'number') {
    if (!stratalotsToNormalize[diffusionVisual.stratalot.idStratalot]) {
      stratalotsToNormalize[diffusionVisual.stratalot.idStratalot] = {
        ...diffusionVisual.stratalot,
        diffusionVisuals: []
      };
    }
    (stratalotsToNormalize[diffusionVisual.stratalot.idStratalot].diffusionVisuals as number[]).push(
      diffusionVisual.idDiffusionVisual
    );
    relations['stratalot'] = diffusionVisual.stratalot.idStratalot;
  }
}

/**
 *  Droit
 */
export function getActionsToNormalizeDroit(droits: DroitEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const profilsToNormalize: { [id: number]: ProfilEntityState } = {};

  const normalizedDroits = droits.map((droit: DroitEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    droitNormalizeProfil(droit, profilsToNormalize, relations);

    return {
      ...droit,
      ...relations
    };
  });
  const profilsToNormalizeArray = Object.values(profilsToNormalize);
  if (profilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProfil(profilsToNormalizeArray, actionType));
  }

  const droitActionCreator = droitActions.upsertManyDroitsSuccess;
  actions.push(droitActionCreator({ droits: normalizedDroits }));

  return actions;
}

function droitNormalizeProfil(
  droit: DroitEntityState,
  profilsToNormalize: { [id: number]: ProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (droit.profils?.length && typeof droit.profils[0] !== 'number') {
    const idsProfils: number[] = (droit.profils as Profil[]).map((profil: Profil) => {
      if (!profilsToNormalize[profil.idProfil]) {
        profilsToNormalize[profil.idProfil] = { ...profil, droits: [] };
      }
      (profilsToNormalize[profil.idProfil].droits as number[]).push(droit.idDroit);
      return profil.idProfil;
    });
    relations['profils'] = idsProfils;
  }
}

/**
 *  GeneratedDocument
 */
export function getActionsToNormalizeGeneratedDocument(
  generatedDocuments: GeneratedDocumentEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const generatedDocumentsCompaniesToNormalize: GeneratedDocumentsCompanyEntityState[] = [];
  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const residencesToNormalize: { [id: number]: ResidenceEntityState } = {};
  const generatedDocumentsResidencesToNormalize: GeneratedDocumentsResidenceEntityState[] = [];
  const companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState } = {};
  const companyCommunicationGeneratedDocumentsToNormalize: CompanyCommunicationGeneratedDocumentEntityState[] = [];

  const normalizedGeneratedDocuments = generatedDocuments.map((generatedDocument: GeneratedDocumentEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    generatedDocumentNormalizeGeneratedDocumentsCompany(
      generatedDocument,
      generatedDocumentsCompaniesToNormalize,
      relations
    );
    generatedDocumentNormalizeCompany(generatedDocument, companiesToNormalize, relations);
    generatedDocumentNormalizeResidence(generatedDocument, residencesToNormalize, relations);
    generatedDocumentNormalizeGeneratedDocumentsResidence(
      generatedDocument,
      generatedDocumentsResidencesToNormalize,
      relations
    );
    generatedDocumentNormalizeCompanyCommunication(generatedDocument, companyCommunicationsToNormalize, relations);
    generatedDocumentNormalizeCompanyCommunicationGeneratedDocument(
      generatedDocument,
      companyCommunicationGeneratedDocumentsToNormalize,
      relations
    );
    generatedDocumentNormalizeOrganization(generatedDocument, organizationsToNormalize, relations);

    return {
      ...generatedDocument,
      ...relations
    };
  });
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  if (generatedDocumentsCompaniesToNormalize.length) {
    actions.push(...getActionsToNormalizeGeneratedDocumentsCompany(generatedDocumentsCompaniesToNormalize, actionType));
  }
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  const residencesToNormalizeArray = Object.values(residencesToNormalize);
  if (residencesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalizeArray, actionType));
  }
  if (generatedDocumentsResidencesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeGeneratedDocumentsResidence(generatedDocumentsResidencesToNormalize, actionType)
    );
  }
  const companyCommunicationsToNormalizeArray = Object.values(companyCommunicationsToNormalize);
  if (companyCommunicationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyCommunication(companyCommunicationsToNormalizeArray, actionType));
  }
  if (companyCommunicationGeneratedDocumentsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationGeneratedDocument(
        companyCommunicationGeneratedDocumentsToNormalize,
        actionType
      )
    );
  }

  const generatedDocumentActionCreator = generatedDocumentActions.upsertManyGeneratedDocumentsSuccess;
  actions.push(generatedDocumentActionCreator({ generatedDocuments: normalizedGeneratedDocuments }));

  return actions;
}

function generatedDocumentNormalizeGeneratedDocumentsCompany(
  generatedDocument: GeneratedDocumentEntityState,
  generatedDocumentsCompaniesToNormalize: GeneratedDocumentsCompanyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    generatedDocument.generatedDocumentsCompanies?.length &&
    typeof generatedDocument.generatedDocumentsCompanies[0] !== 'number'
  ) {
    const idsGeneratedDocumentsCompanies: number[] = [];
    generatedDocumentsCompaniesToNormalize.push(
      ...(generatedDocument.generatedDocumentsCompanies as GeneratedDocumentsCompany[]).map(
        (generatedDocumentsCompany: GeneratedDocumentsCompany) => {
          idsGeneratedDocumentsCompanies.push(generatedDocumentsCompany.idGeneratedDocumentsCompany);
          return { ...generatedDocumentsCompany, generatedDocument: generatedDocument.idGeneratedDocument };
        }
      )
    );
    relations['generatedDocumentsCompanies'] = idsGeneratedDocumentsCompanies;
  }
}

function generatedDocumentNormalizeCompany(
  generatedDocument: GeneratedDocumentEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (generatedDocument.companies?.length && typeof generatedDocument.companies[0] !== 'number') {
    const idsCompanies: number[] = (generatedDocument.companies as Company[]).map((company: Company) => {
      if (!companiesToNormalize[company.idCompany]) {
        companiesToNormalize[company.idCompany] = { ...company, generatedDocuments: [] };
      }
      (companiesToNormalize[company.idCompany].generatedDocuments as number[]).push(
        generatedDocument.idGeneratedDocument
      );
      return company.idCompany;
    });
    relations['companies'] = idsCompanies;
  }
}

function generatedDocumentNormalizeResidence(
  generatedDocument: GeneratedDocumentEntityState,
  residencesToNormalize: { [id: number]: ResidenceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (generatedDocument.residences?.length && typeof generatedDocument.residences[0] !== 'number') {
    const idsResidences: number[] = (generatedDocument.residences as Residence[]).map((residence: Residence) => {
      if (!residencesToNormalize[residence.idResidence]) {
        residencesToNormalize[residence.idResidence] = { ...residence, generatedDocuments: [] };
      }
      (residencesToNormalize[residence.idResidence].generatedDocuments as number[]).push(
        generatedDocument.idGeneratedDocument
      );
      return residence.idResidence;
    });
    relations['residences'] = idsResidences;
  }
}

function generatedDocumentNormalizeGeneratedDocumentsResidence(
  generatedDocument: GeneratedDocumentEntityState,
  generatedDocumentsResidencesToNormalize: GeneratedDocumentsResidenceEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    generatedDocument.generatedDocumentsResidences?.length &&
    typeof generatedDocument.generatedDocumentsResidences[0] !== 'number'
  ) {
    const idsGeneratedDocumentsResidences: number[] = [];
    generatedDocumentsResidencesToNormalize.push(
      ...(generatedDocument.generatedDocumentsResidences as GeneratedDocumentsResidence[]).map(
        (generatedDocumentsResidence: GeneratedDocumentsResidence) => {
          idsGeneratedDocumentsResidences.push(generatedDocumentsResidence.idGeneratedDocumentsResidence);
          return { ...generatedDocumentsResidence, generatedDocument: generatedDocument.idGeneratedDocument };
        }
      )
    );
    relations['generatedDocumentsResidences'] = idsGeneratedDocumentsResidences;
  }
}

function generatedDocumentNormalizeCompanyCommunication(
  generatedDocument: GeneratedDocumentEntityState,
  companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    generatedDocument.companyCommunications?.length &&
    typeof generatedDocument.companyCommunications[0] !== 'number'
  ) {
    const idsCompanyCommunications: number[] = (generatedDocument.companyCommunications as CompanyCommunication[]).map(
      (companyCommunication: CompanyCommunication) => {
        if (!companyCommunicationsToNormalize[companyCommunication.idCompanyCommunication]) {
          companyCommunicationsToNormalize[companyCommunication.idCompanyCommunication] = {
            ...companyCommunication,
            generatedDocuments: []
          };
        }
        (
          companyCommunicationsToNormalize[companyCommunication.idCompanyCommunication].generatedDocuments as number[]
        ).push(generatedDocument.idGeneratedDocument);
        return companyCommunication.idCompanyCommunication;
      }
    );
    relations['companyCommunications'] = idsCompanyCommunications;
  }
}

function generatedDocumentNormalizeCompanyCommunicationGeneratedDocument(
  generatedDocument: GeneratedDocumentEntityState,
  companyCommunicationGeneratedDocumentsToNormalize: CompanyCommunicationGeneratedDocumentEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    generatedDocument.companyCommunicationGeneratedDocuments?.length &&
    typeof generatedDocument.companyCommunicationGeneratedDocuments[0] !== 'number'
  ) {
    const idsCompanyCommunicationGeneratedDocuments: number[] = [];
    companyCommunicationGeneratedDocumentsToNormalize.push(
      ...(generatedDocument.companyCommunicationGeneratedDocuments as CompanyCommunicationGeneratedDocument[]).map(
        (companyCommunicationGeneratedDocument: CompanyCommunicationGeneratedDocument) => {
          idsCompanyCommunicationGeneratedDocuments.push(
            companyCommunicationGeneratedDocument.idCompanyCommunicationGeneratedDocument
          );
          return { ...companyCommunicationGeneratedDocument, generatedDocument: generatedDocument.idGeneratedDocument };
        }
      )
    );
    relations['companyCommunicationGeneratedDocuments'] = idsCompanyCommunicationGeneratedDocuments;
  }
}

function generatedDocumentNormalizeOrganization(
  generatedDocument: GeneratedDocumentEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (generatedDocument.organization && typeof generatedDocument.organization !== 'number') {
    if (!organizationsToNormalize[generatedDocument.organization.idOrganization]) {
      organizationsToNormalize[generatedDocument.organization.idOrganization] = {
        ...generatedDocument.organization,
        generatedDocuments: []
      };
    }
    (organizationsToNormalize[generatedDocument.organization.idOrganization].generatedDocuments as number[]).push(
      generatedDocument.idGeneratedDocument
    );
    relations['organization'] = generatedDocument.organization.idOrganization;
  }
}

/**
 *  GeneratedDocumentsCompany
 */
export function getActionsToNormalizeGeneratedDocumentsCompany(
  generatedDocumentsCompanies: GeneratedDocumentsCompanyEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const generatedDocumentsToNormalize: { [id: number]: GeneratedDocumentEntityState } = {};

  const normalizedGeneratedDocumentsCompanies = generatedDocumentsCompanies.map(
    (generatedDocumentsCompany: GeneratedDocumentsCompanyEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      generatedDocumentsCompanyNormalizeCompany(generatedDocumentsCompany, companiesToNormalize, relations);
      generatedDocumentsCompanyNormalizeGeneratedDocument(
        generatedDocumentsCompany,
        generatedDocumentsToNormalize,
        relations
      );

      return {
        ...generatedDocumentsCompany,
        ...relations
      };
    }
  );
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  const generatedDocumentsToNormalizeArray = Object.values(generatedDocumentsToNormalize);
  if (generatedDocumentsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeGeneratedDocument(generatedDocumentsToNormalizeArray, actionType));
  }

  const generatedDocumentsCompanyActionCreator =
    generatedDocumentsCompanyActions.upsertManyGeneratedDocumentsCompaniesSuccess;
  actions.push(
    generatedDocumentsCompanyActionCreator({ generatedDocumentsCompanies: normalizedGeneratedDocumentsCompanies })
  );

  return actions;
}

function generatedDocumentsCompanyNormalizeCompany(
  generatedDocumentsCompany: GeneratedDocumentsCompanyEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (generatedDocumentsCompany.company && typeof generatedDocumentsCompany.company !== 'number') {
    if (!companiesToNormalize[generatedDocumentsCompany.company.idCompany]) {
      companiesToNormalize[generatedDocumentsCompany.company.idCompany] = {
        ...generatedDocumentsCompany.company,
        generatedDocumentsCompanies: []
      };
    }
    (companiesToNormalize[generatedDocumentsCompany.company.idCompany].generatedDocumentsCompanies as number[]).push(
      generatedDocumentsCompany.idGeneratedDocumentsCompany
    );
    relations['company'] = generatedDocumentsCompany.company.idCompany;
  }
}

function generatedDocumentsCompanyNormalizeGeneratedDocument(
  generatedDocumentsCompany: GeneratedDocumentsCompanyEntityState,
  generatedDocumentsToNormalize: { [id: number]: GeneratedDocumentEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (generatedDocumentsCompany.generatedDocument && typeof generatedDocumentsCompany.generatedDocument !== 'number') {
    if (!generatedDocumentsToNormalize[generatedDocumentsCompany.generatedDocument.idGeneratedDocument]) {
      generatedDocumentsToNormalize[generatedDocumentsCompany.generatedDocument.idGeneratedDocument] = {
        ...generatedDocumentsCompany.generatedDocument,
        generatedDocumentsCompanies: []
      };
    }
    (
      generatedDocumentsToNormalize[generatedDocumentsCompany.generatedDocument.idGeneratedDocument]
        .generatedDocumentsCompanies as number[]
    ).push(generatedDocumentsCompany.idGeneratedDocumentsCompany);
    relations['generatedDocument'] = generatedDocumentsCompany.generatedDocument.idGeneratedDocument;
  }
}

/**
 *  GeneratedDocumentsResidence
 */
export function getActionsToNormalizeGeneratedDocumentsResidence(
  generatedDocumentsResidences: GeneratedDocumentsResidenceEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residencesToNormalize: { [id: number]: ResidenceEntityState } = {};
  const generatedDocumentsToNormalize: { [id: number]: GeneratedDocumentEntityState } = {};

  const normalizedGeneratedDocumentsResidences = generatedDocumentsResidences.map(
    (generatedDocumentsResidence: GeneratedDocumentsResidenceEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      generatedDocumentsResidenceNormalizeResidence(generatedDocumentsResidence, residencesToNormalize, relations);
      generatedDocumentsResidenceNormalizeGeneratedDocument(
        generatedDocumentsResidence,
        generatedDocumentsToNormalize,
        relations
      );

      return {
        ...generatedDocumentsResidence,
        ...relations
      };
    }
  );
  const residencesToNormalizeArray = Object.values(residencesToNormalize);
  if (residencesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalizeArray, actionType));
  }
  const generatedDocumentsToNormalizeArray = Object.values(generatedDocumentsToNormalize);
  if (generatedDocumentsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeGeneratedDocument(generatedDocumentsToNormalizeArray, actionType));
  }

  const generatedDocumentsResidenceActionCreator =
    generatedDocumentsResidenceActions.upsertManyGeneratedDocumentsResidencesSuccess;
  actions.push(
    generatedDocumentsResidenceActionCreator({ generatedDocumentsResidences: normalizedGeneratedDocumentsResidences })
  );

  return actions;
}

function generatedDocumentsResidenceNormalizeResidence(
  generatedDocumentsResidence: GeneratedDocumentsResidenceEntityState,
  residencesToNormalize: { [id: number]: ResidenceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (generatedDocumentsResidence.residence && typeof generatedDocumentsResidence.residence !== 'number') {
    if (!residencesToNormalize[generatedDocumentsResidence.residence.idResidence]) {
      residencesToNormalize[generatedDocumentsResidence.residence.idResidence] = {
        ...generatedDocumentsResidence.residence,
        generatedDocumentsResidences: []
      };
    }
    (
      residencesToNormalize[generatedDocumentsResidence.residence.idResidence].generatedDocumentsResidences as number[]
    ).push(generatedDocumentsResidence.idGeneratedDocumentsResidence);
    relations['residence'] = generatedDocumentsResidence.residence.idResidence;
  }
}

function generatedDocumentsResidenceNormalizeGeneratedDocument(
  generatedDocumentsResidence: GeneratedDocumentsResidenceEntityState,
  generatedDocumentsToNormalize: { [id: number]: GeneratedDocumentEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    generatedDocumentsResidence.generatedDocument &&
    typeof generatedDocumentsResidence.generatedDocument !== 'number'
  ) {
    if (!generatedDocumentsToNormalize[generatedDocumentsResidence.generatedDocument.idGeneratedDocument]) {
      generatedDocumentsToNormalize[generatedDocumentsResidence.generatedDocument.idGeneratedDocument] = {
        ...generatedDocumentsResidence.generatedDocument,
        generatedDocumentsResidences: []
      };
    }
    (
      generatedDocumentsToNormalize[generatedDocumentsResidence.generatedDocument.idGeneratedDocument]
        .generatedDocumentsResidences as number[]
    ).push(generatedDocumentsResidence.idGeneratedDocumentsResidence);
    relations['generatedDocument'] = generatedDocumentsResidence.generatedDocument.idGeneratedDocument;
  }
}

/**
 *  Lead
 */
export function getActionsToNormalizeLead(leads: LeadEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const prospectsToNormalize: { [id: number]: ProspectEntityState } = {};
  const organizationLeadAvancementsToNormalize: { [id: number]: OrganizationLeadAvancementEntityState } = {};
  const prospectEventsToNormalize: ProspectEventEntityState[] = [];
  const stratalotsToNormalize: { [id: number]: StratalotEntityState } = {};
  const leadStratalotsToNormalize: LeadStratalotEntityState[] = [];
  const leadTodosToNormalize: LeadTodoEntityState[] = [];

  const normalizedLeads = leads.map((lead: LeadEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    leadNormalizeProspectEvent(lead, prospectEventsToNormalize, relations);
    leadNormalizeStratalot(lead, stratalotsToNormalize, relations);
    leadNormalizeLeadStratalot(lead, leadStratalotsToNormalize, relations);
    leadNormalizeLeadTodo(lead, leadTodosToNormalize, relations);
    leadNormalizeProspect(lead, prospectsToNormalize, relations);
    leadNormalizeOrganizationLeadAvancement(lead, organizationLeadAvancementsToNormalize, relations);

    return {
      ...lead,
      ...relations
    };
  });
  const prospectsToNormalizeArray = Object.values(prospectsToNormalize);
  if (prospectsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProspect(prospectsToNormalizeArray, actionType));
  }
  const organizationLeadAvancementsToNormalizeArray = Object.values(organizationLeadAvancementsToNormalize);
  if (organizationLeadAvancementsToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationLeadAvancement(organizationLeadAvancementsToNormalizeArray, actionType)
    );
  }
  if (prospectEventsToNormalize.length) {
    actions.push(...getActionsToNormalizeProspectEvent(prospectEventsToNormalize, actionType));
  }
  const stratalotsToNormalizeArray = Object.values(stratalotsToNormalize);
  if (stratalotsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalizeArray, actionType));
  }
  if (leadStratalotsToNormalize.length) {
    actions.push(...getActionsToNormalizeLeadStratalot(leadStratalotsToNormalize, actionType));
  }
  if (leadTodosToNormalize.length) {
    actions.push(...getActionsToNormalizeLeadTodo(leadTodosToNormalize, actionType));
  }

  const leadActionCreator = leadActions.upsertManyLeadsSuccess;
  actions.push(leadActionCreator({ leads: normalizedLeads }));

  return actions;
}

function leadNormalizeProspectEvent(
  lead: LeadEntityState,
  prospectEventsToNormalize: ProspectEventEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (lead.prospectEvents?.length && typeof lead.prospectEvents[0] !== 'number') {
    const idsProspectEvents: number[] = [];
    prospectEventsToNormalize.push(
      ...(lead.prospectEvents as ProspectEvent[]).map((prospectEvent: ProspectEvent) => {
        idsProspectEvents.push(prospectEvent.idProspectEvent);
        return { ...prospectEvent, lead: lead.idLead };
      })
    );
    relations['prospectEvents'] = idsProspectEvents;
  }
}

function leadNormalizeStratalot(
  lead: LeadEntityState,
  stratalotsToNormalize: { [id: number]: StratalotEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (lead.stratalots?.length && typeof lead.stratalots[0] !== 'number') {
    const idsStratalots: number[] = (lead.stratalots as Stratalot[]).map((stratalot: Stratalot) => {
      if (!stratalotsToNormalize[stratalot.idStratalot]) {
        stratalotsToNormalize[stratalot.idStratalot] = { ...stratalot, leads: [] };
      }
      (stratalotsToNormalize[stratalot.idStratalot].leads as number[]).push(lead.idLead);
      return stratalot.idStratalot;
    });
    relations['stratalots'] = idsStratalots;
  }
}

function leadNormalizeLeadStratalot(
  lead: LeadEntityState,
  leadStratalotsToNormalize: LeadStratalotEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (lead.leadStratalots?.length && typeof lead.leadStratalots[0] !== 'number') {
    const idsLeadStratalots: number[] = [];
    leadStratalotsToNormalize.push(
      ...(lead.leadStratalots as LeadStratalot[]).map((leadStratalot: LeadStratalot) => {
        idsLeadStratalots.push(leadStratalot.idLeadStratalot);
        return { ...leadStratalot, lead: lead.idLead };
      })
    );
    relations['leadStratalots'] = idsLeadStratalots;
  }
}

function leadNormalizeLeadTodo(
  lead: LeadEntityState,
  leadTodosToNormalize: LeadTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (lead.leadTodos?.length && typeof lead.leadTodos[0] !== 'number') {
    const idsLeadTodos: number[] = [];
    leadTodosToNormalize.push(
      ...(lead.leadTodos as LeadTodo[]).map((leadTodo: LeadTodo) => {
        idsLeadTodos.push(leadTodo.idLeadTodo);
        return { ...leadTodo, lead: lead.idLead };
      })
    );
    relations['leadTodos'] = idsLeadTodos;
  }
}

function leadNormalizeProspect(
  lead: LeadEntityState,
  prospectsToNormalize: { [id: number]: ProspectEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (lead.prospect && typeof lead.prospect !== 'number') {
    if (!prospectsToNormalize[lead.prospect.idProspect]) {
      prospectsToNormalize[lead.prospect.idProspect] = { ...lead.prospect, leads: [] };
    }
    (prospectsToNormalize[lead.prospect.idProspect].leads as number[]).push(lead.idLead);
    relations['prospect'] = lead.prospect.idProspect;
  }
}

function leadNormalizeOrganizationLeadAvancement(
  lead: LeadEntityState,
  organizationLeadAvancementsToNormalize: { [id: number]: OrganizationLeadAvancementEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (lead.organizationLeadAvancement && typeof lead.organizationLeadAvancement !== 'number') {
    if (!organizationLeadAvancementsToNormalize[lead.organizationLeadAvancement.idOrganizationLeadAvancement]) {
      organizationLeadAvancementsToNormalize[lead.organizationLeadAvancement.idOrganizationLeadAvancement] = {
        ...lead.organizationLeadAvancement,
        leads: []
      };
    }
    (
      organizationLeadAvancementsToNormalize[lead.organizationLeadAvancement.idOrganizationLeadAvancement]
        .leads as number[]
    ).push(lead.idLead);
    relations['organizationLeadAvancement'] = lead.organizationLeadAvancement.idOrganizationLeadAvancement;
  }
}

/**
 *  LeadStratalot
 */
export function getActionsToNormalizeLeadStratalot(
  leadStratalots: LeadStratalotEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const leadsToNormalize: { [id: number]: LeadEntityState } = {};
  const stratalotsToNormalize: { [id: number]: StratalotEntityState } = {};

  const normalizedLeadStratalots = leadStratalots.map((leadStratalot: LeadStratalotEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    leadStratalotNormalizeLead(leadStratalot, leadsToNormalize, relations);
    leadStratalotNormalizeStratalot(leadStratalot, stratalotsToNormalize, relations);

    return {
      ...leadStratalot,
      ...relations
    };
  });
  const leadsToNormalizeArray = Object.values(leadsToNormalize);
  if (leadsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeLead(leadsToNormalizeArray, actionType));
  }
  const stratalotsToNormalizeArray = Object.values(stratalotsToNormalize);
  if (stratalotsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalizeArray, actionType));
  }

  const leadStratalotActionCreator = leadStratalotActions.upsertManyLeadStratalotsSuccess;
  actions.push(leadStratalotActionCreator({ leadStratalots: normalizedLeadStratalots }));

  return actions;
}

function leadStratalotNormalizeLead(
  leadStratalot: LeadStratalotEntityState,
  leadsToNormalize: { [id: number]: LeadEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (leadStratalot.lead && typeof leadStratalot.lead !== 'number') {
    if (!leadsToNormalize[leadStratalot.lead.idLead]) {
      leadsToNormalize[leadStratalot.lead.idLead] = { ...leadStratalot.lead, leadStratalots: [] };
    }
    (leadsToNormalize[leadStratalot.lead.idLead].leadStratalots as number[]).push(leadStratalot.idLeadStratalot);
    relations['lead'] = leadStratalot.lead.idLead;
  }
}

function leadStratalotNormalizeStratalot(
  leadStratalot: LeadStratalotEntityState,
  stratalotsToNormalize: { [id: number]: StratalotEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (leadStratalot.stratalot && typeof leadStratalot.stratalot !== 'number') {
    if (!stratalotsToNormalize[leadStratalot.stratalot.idStratalot]) {
      stratalotsToNormalize[leadStratalot.stratalot.idStratalot] = { ...leadStratalot.stratalot, leadStratalots: [] };
    }
    (stratalotsToNormalize[leadStratalot.stratalot.idStratalot].leadStratalots as number[]).push(
      leadStratalot.idLeadStratalot
    );
    relations['stratalot'] = leadStratalot.stratalot.idStratalot;
  }
}

/**
 *  LeadTodo
 */
export function getActionsToNormalizeLeadTodo(leadTodos: LeadTodoEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const organizationLeadTodosToNormalize: { [id: number]: OrganizationLeadTodoEntityState } = {};
  const leadsToNormalize: { [id: number]: LeadEntityState } = {};

  const normalizedLeadTodos = leadTodos.map((leadTodo: LeadTodoEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    leadTodoNormalizeOrganizationLeadTodo(leadTodo, organizationLeadTodosToNormalize, relations);
    leadTodoNormalizeLead(leadTodo, leadsToNormalize, relations);

    return {
      ...leadTodo,
      ...relations
    };
  });
  const organizationLeadTodosToNormalizeArray = Object.values(organizationLeadTodosToNormalize);
  if (organizationLeadTodosToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationLeadTodo(organizationLeadTodosToNormalizeArray, actionType));
  }
  const leadsToNormalizeArray = Object.values(leadsToNormalize);
  if (leadsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeLead(leadsToNormalizeArray, actionType));
  }

  const leadTodoActionCreator = leadTodoActions.upsertManyLeadTodosSuccess;
  actions.push(leadTodoActionCreator({ leadTodos: normalizedLeadTodos }));

  return actions;
}

function leadTodoNormalizeOrganizationLeadTodo(
  leadTodo: LeadTodoEntityState,
  organizationLeadTodosToNormalize: { [id: number]: OrganizationLeadTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (leadTodo.organizationLeadTodo && typeof leadTodo.organizationLeadTodo !== 'number') {
    if (!organizationLeadTodosToNormalize[leadTodo.organizationLeadTodo.idOrganizationLeadTodo]) {
      organizationLeadTodosToNormalize[leadTodo.organizationLeadTodo.idOrganizationLeadTodo] = {
        ...leadTodo.organizationLeadTodo,
        leadTodos: []
      };
    }
    (organizationLeadTodosToNormalize[leadTodo.organizationLeadTodo.idOrganizationLeadTodo].leadTodos as number[]).push(
      leadTodo.idLeadTodo
    );
    relations['organizationLeadTodo'] = leadTodo.organizationLeadTodo.idOrganizationLeadTodo;
  }
}

function leadTodoNormalizeLead(
  leadTodo: LeadTodoEntityState,
  leadsToNormalize: { [id: number]: LeadEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (leadTodo.lead && typeof leadTodo.lead !== 'number') {
    if (!leadsToNormalize[leadTodo.lead.idLead]) {
      leadsToNormalize[leadTodo.lead.idLead] = { ...leadTodo.lead, leadTodos: [] };
    }
    (leadsToNormalize[leadTodo.lead.idLead].leadTodos as number[]).push(leadTodo.idLeadTodo);
    relations['lead'] = leadTodo.lead.idLead;
  }
}

/**
 *  Occupant
 */
export function getActionsToNormalizeOccupant(occupants: OccupantEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const stratalotsToNormalize: StratalotEntityState[] = [];
  const prospectsToNormalize: ProspectEntityState[] = [];

  const normalizedOccupants = occupants.map((occupant: OccupantEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    occupantNormalizeStratalot(occupant, stratalotsToNormalize, relations);
    occupantNormalizeProspect(occupant, prospectsToNormalize, relations);
    occupantNormalizeCompany(occupant, companiesToNormalize, relations);

    return {
      ...occupant,
      ...relations
    };
  });
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  if (stratalotsToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalize, actionType));
  }
  if (prospectsToNormalize.length) {
    actions.push(...getActionsToNormalizeProspect(prospectsToNormalize, actionType));
  }

  const occupantActionCreator = occupantActions.upsertManyOccupantsSuccess;
  actions.push(occupantActionCreator({ occupants: normalizedOccupants }));

  return actions;
}

function occupantNormalizeStratalot(
  occupant: OccupantEntityState,
  stratalotsToNormalize: StratalotEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (occupant.stratalots?.length && typeof occupant.stratalots[0] !== 'number') {
    const idsStratalots: number[] = [];
    stratalotsToNormalize.push(
      ...(occupant.stratalots as Stratalot[]).map((stratalot: Stratalot) => {
        idsStratalots.push(stratalot.idStratalot);
        return { ...stratalot, occupant: occupant.idOccupant };
      })
    );
    relations['stratalots'] = idsStratalots;
  }
}

function occupantNormalizeProspect(
  occupant: OccupantEntityState,
  prospectsToNormalize: ProspectEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (occupant.prospects?.length && typeof occupant.prospects[0] !== 'number') {
    const idsProspects: number[] = [];
    prospectsToNormalize.push(
      ...(occupant.prospects as Prospect[]).map((prospect: Prospect) => {
        idsProspects.push(prospect.idProspect);
        return { ...prospect, occupant: occupant.idOccupant };
      })
    );
    relations['prospects'] = idsProspects;
  }
}

function occupantNormalizeCompany(
  occupant: OccupantEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (occupant.company && typeof occupant.company !== 'number') {
    if (!companiesToNormalize[occupant.company.idCompany]) {
      companiesToNormalize[occupant.company.idCompany] = { ...occupant.company, occupants: [] };
    }
    (companiesToNormalize[occupant.company.idCompany].occupants as number[]).push(occupant.idOccupant);
    relations['company'] = occupant.company.idCompany;
  }
}

/**
 *  Organization
 */
export function getActionsToNormalizeOrganization(
  organizations: OrganizationEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: CompanyEntityState[] = [];
  const usersToNormalize: UserEntityState[] = [];
  const organizationMetiersToNormalize: OrganizationMetierEntityState[] = [];
  const organizationSocietesToNormalize: OrganizationSocieteEntityState[] = [];
  const organizationLeadTodosToNormalize: OrganizationLeadTodoEntityState[] = [];
  const organizationLeadTodoRulesToNormalize: OrganizationLeadTodoRuleEntityState[] = [];
  const organizationStratalotTodoRulesToNormalize: OrganizationStratalotTodoRuleEntityState[] = [];
  const organizationResidenceTodoRulesToNormalize: OrganizationResidenceTodoRuleEntityState[] = [];
  const organizationResidenceTodosToNormalize: OrganizationResidenceTodoEntityState[] = [];
  const organizationStratalotTodosToNormalize: OrganizationStratalotTodoEntityState[] = [];
  const organizationResidenceStudyTodosToNormalize: OrganizationResidenceStudyTodoEntityState[] = [];
  const organizationProfilsToNormalize: OrganizationProfilEntityState[] = [];
  const organizationProspectOriginsToNormalize: OrganizationProspectOriginEntityState[] = [];
  const prospectsToNormalize: ProspectEntityState[] = [];
  const profilsToNormalize: ProfilEntityState[] = [];
  const generatedDocumentsToNormalize: GeneratedDocumentEntityState[] = [];
  const organizationThirdPartiesToNormalize: OrganizationThirdPartyEntityState[] = [];
  const organizationStratalotAvancementsToNormalize: OrganizationStratalotAvancementEntityState[] = [];
  const organizationLeadAvancementsToNormalize: OrganizationLeadAvancementEntityState[] = [];
  const organizationSaleCategoriesToNormalize: OrganizationSaleCategoryEntityState[] = [];
  const organizationBuyingWishesToNormalize: OrganizationBuyingWishEntityState[] = [];
  const organizationEnergiesToNormalize: OrganizationEnergieEntityState[] = [];
  const organizationForecastRatesToNormalize: OrganizationForecastRateEntityState[] = [];

  const normalizedOrganizations = organizations.map((organization: OrganizationEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    organizationNormalizeCompany(organization, companiesToNormalize, relations);
    organizationNormalizeUser(organization, usersToNormalize, relations);
    organizationNormalizeOrganizationMetier(organization, organizationMetiersToNormalize, relations);
    organizationNormalizeOrganizationSociete(organization, organizationSocietesToNormalize, relations);
    organizationNormalizeOrganizationLeadTodo(organization, organizationLeadTodosToNormalize, relations);
    organizationNormalizeOrganizationLeadTodoRule(organization, organizationLeadTodoRulesToNormalize, relations);
    organizationNormalizeOrganizationStratalotTodoRule(
      organization,
      organizationStratalotTodoRulesToNormalize,
      relations
    );
    organizationNormalizeOrganizationResidenceTodoRule(
      organization,
      organizationResidenceTodoRulesToNormalize,
      relations
    );
    organizationNormalizeOrganizationResidenceTodo(organization, organizationResidenceTodosToNormalize, relations);
    organizationNormalizeOrganizationStratalotTodo(organization, organizationStratalotTodosToNormalize, relations);
    organizationNormalizeOrganizationResidenceStudyTodo(
      organization,
      organizationResidenceStudyTodosToNormalize,
      relations
    );
    organizationNormalizeOrganizationProfil(organization, organizationProfilsToNormalize, relations);
    organizationNormalizeOrganizationProspectOrigin(organization, organizationProspectOriginsToNormalize, relations);
    organizationNormalizeProspect(organization, prospectsToNormalize, relations);
    organizationNormalizeProfil(organization, profilsToNormalize, relations);
    organizationNormalizeGeneratedDocument(organization, generatedDocumentsToNormalize, relations);
    organizationNormalizeOrganizationThirdParty(organization, organizationThirdPartiesToNormalize, relations);
    organizationNormalizeOrganizationStratalotAvancement(
      organization,
      organizationStratalotAvancementsToNormalize,
      relations
    );
    organizationNormalizeOrganizationLeadAvancement(organization, organizationLeadAvancementsToNormalize, relations);
    organizationNormalizeOrganizationSaleCategory(organization, organizationSaleCategoriesToNormalize, relations);
    organizationNormalizeOrganizationBuyingWish(organization, organizationBuyingWishesToNormalize, relations);
    organizationNormalizeOrganizationEnergie(organization, organizationEnergiesToNormalize, relations);
    organizationNormalizeOrganizationForecastRate(organization, organizationForecastRatesToNormalize, relations);

    return {
      ...organization,
      ...relations
    };
  });
  if (companiesToNormalize.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalize, actionType));
  }
  if (usersToNormalize.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalize, actionType));
  }
  if (organizationMetiersToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationMetier(organizationMetiersToNormalize, actionType));
  }
  if (organizationSocietesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationSociete(organizationSocietesToNormalize, actionType));
  }
  if (organizationLeadTodosToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationLeadTodo(organizationLeadTodosToNormalize, actionType));
  }
  if (organizationLeadTodoRulesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationLeadTodoRule(organizationLeadTodoRulesToNormalize, actionType));
  }
  if (organizationStratalotTodoRulesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodoRule(organizationStratalotTodoRulesToNormalize, actionType)
    );
  }
  if (organizationResidenceTodoRulesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceTodoRule(organizationResidenceTodoRulesToNormalize, actionType)
    );
  }
  if (organizationResidenceTodosToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationResidenceTodo(organizationResidenceTodosToNormalize, actionType));
  }
  if (organizationStratalotTodosToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationStratalotTodo(organizationStratalotTodosToNormalize, actionType));
  }
  if (organizationResidenceStudyTodosToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceStudyTodo(organizationResidenceStudyTodosToNormalize, actionType)
    );
  }
  if (organizationProfilsToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationProfil(organizationProfilsToNormalize, actionType));
  }
  if (organizationProspectOriginsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationProspectOrigin(organizationProspectOriginsToNormalize, actionType)
    );
  }
  if (prospectsToNormalize.length) {
    actions.push(...getActionsToNormalizeProspect(prospectsToNormalize, actionType));
  }
  if (profilsToNormalize.length) {
    actions.push(...getActionsToNormalizeProfil(profilsToNormalize, actionType));
  }
  if (generatedDocumentsToNormalize.length) {
    actions.push(...getActionsToNormalizeGeneratedDocument(generatedDocumentsToNormalize, actionType));
  }
  if (organizationThirdPartiesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationThirdParty(organizationThirdPartiesToNormalize, actionType));
  }
  if (organizationStratalotAvancementsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotAvancement(organizationStratalotAvancementsToNormalize, actionType)
    );
  }
  if (organizationLeadAvancementsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationLeadAvancement(organizationLeadAvancementsToNormalize, actionType)
    );
  }
  if (organizationSaleCategoriesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationSaleCategory(organizationSaleCategoriesToNormalize, actionType));
  }
  if (organizationBuyingWishesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationBuyingWish(organizationBuyingWishesToNormalize, actionType));
  }
  if (organizationEnergiesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationEnergie(organizationEnergiesToNormalize, actionType));
  }
  if (organizationForecastRatesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationForecastRate(organizationForecastRatesToNormalize, actionType));
  }

  const organizationActionCreator = organizationActions.upsertManyOrganizationsSuccess;
  actions.push(organizationActionCreator({ organizations: normalizedOrganizations }));

  return actions;
}

function organizationNormalizeCompany(
  organization: OrganizationEntityState,
  companiesToNormalize: CompanyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.companies?.length && typeof organization.companies[0] !== 'number') {
    const idsCompanies: number[] = [];
    companiesToNormalize.push(
      ...(organization.companies as Company[]).map((company: Company) => {
        idsCompanies.push(company.idCompany);
        return { ...company, organization: organization.idOrganization };
      })
    );
    relations['companies'] = idsCompanies;
  }
}

function organizationNormalizeUser(
  organization: OrganizationEntityState,
  usersToNormalize: UserEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.users?.length && typeof organization.users[0] !== 'number') {
    const idsUsers: number[] = [];
    usersToNormalize.push(
      ...(organization.users as User[]).map((user: User) => {
        idsUsers.push(user.idUser);
        return { ...user, organization: organization.idOrganization };
      })
    );
    relations['users'] = idsUsers;
  }
}

function organizationNormalizeOrganizationMetier(
  organization: OrganizationEntityState,
  organizationMetiersToNormalize: OrganizationMetierEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationMetiers?.length && typeof organization.organizationMetiers[0] !== 'number') {
    const idsOrganizationMetiers: number[] = [];
    organizationMetiersToNormalize.push(
      ...(organization.organizationMetiers as OrganizationMetier[]).map((organizationMetier: OrganizationMetier) => {
        idsOrganizationMetiers.push(organizationMetier.idOrganizationMetier);
        return { ...organizationMetier, organization: organization.idOrganization };
      })
    );
    relations['organizationMetiers'] = idsOrganizationMetiers;
  }
}

function organizationNormalizeOrganizationSociete(
  organization: OrganizationEntityState,
  organizationSocietesToNormalize: OrganizationSocieteEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationSocietes?.length && typeof organization.organizationSocietes[0] !== 'number') {
    const idsOrganizationSocietes: number[] = [];
    organizationSocietesToNormalize.push(
      ...(organization.organizationSocietes as OrganizationSociete[]).map(
        (organizationSociete: OrganizationSociete) => {
          idsOrganizationSocietes.push(organizationSociete.idOrganizationSociete);
          return { ...organizationSociete, organization: organization.idOrganization };
        }
      )
    );
    relations['organizationSocietes'] = idsOrganizationSocietes;
  }
}

function organizationNormalizeOrganizationLeadTodo(
  organization: OrganizationEntityState,
  organizationLeadTodosToNormalize: OrganizationLeadTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationLeadTodos?.length && typeof organization.organizationLeadTodos[0] !== 'number') {
    const idsOrganizationLeadTodos: number[] = [];
    organizationLeadTodosToNormalize.push(
      ...(organization.organizationLeadTodos as OrganizationLeadTodo[]).map(
        (organizationLeadTodo: OrganizationLeadTodo) => {
          idsOrganizationLeadTodos.push(organizationLeadTodo.idOrganizationLeadTodo);
          return { ...organizationLeadTodo, organization: organization.idOrganization };
        }
      )
    );
    relations['organizationLeadTodos'] = idsOrganizationLeadTodos;
  }
}

function organizationNormalizeOrganizationLeadTodoRule(
  organization: OrganizationEntityState,
  organizationLeadTodoRulesToNormalize: OrganizationLeadTodoRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationLeadTodoRules?.length && typeof organization.organizationLeadTodoRules[0] !== 'number') {
    const idsOrganizationLeadTodoRules: number[] = [];
    organizationLeadTodoRulesToNormalize.push(
      ...(organization.organizationLeadTodoRules as OrganizationLeadTodoRule[]).map(
        (organizationLeadTodoRule: OrganizationLeadTodoRule) => {
          idsOrganizationLeadTodoRules.push(organizationLeadTodoRule.idOrganizationLeadTodoRule);
          return { ...organizationLeadTodoRule, organization: organization.idOrganization };
        }
      )
    );
    relations['organizationLeadTodoRules'] = idsOrganizationLeadTodoRules;
  }
}

function organizationNormalizeOrganizationStratalotTodoRule(
  organization: OrganizationEntityState,
  organizationStratalotTodoRulesToNormalize: OrganizationStratalotTodoRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organization.organizationStratalotTodoRules?.length &&
    typeof organization.organizationStratalotTodoRules[0] !== 'number'
  ) {
    const idsOrganizationStratalotTodoRules: number[] = [];
    organizationStratalotTodoRulesToNormalize.push(
      ...(organization.organizationStratalotTodoRules as OrganizationStratalotTodoRule[]).map(
        (organizationStratalotTodoRule: OrganizationStratalotTodoRule) => {
          idsOrganizationStratalotTodoRules.push(organizationStratalotTodoRule.idOrganizationStratalotTodoRule);
          return { ...organizationStratalotTodoRule, organization: organization.idOrganization };
        }
      )
    );
    relations['organizationStratalotTodoRules'] = idsOrganizationStratalotTodoRules;
  }
}

function organizationNormalizeOrganizationResidenceTodoRule(
  organization: OrganizationEntityState,
  organizationResidenceTodoRulesToNormalize: OrganizationResidenceTodoRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organization.organizationResidenceTodoRules?.length &&
    typeof organization.organizationResidenceTodoRules[0] !== 'number'
  ) {
    const idsOrganizationResidenceTodoRules: number[] = [];
    organizationResidenceTodoRulesToNormalize.push(
      ...(organization.organizationResidenceTodoRules as OrganizationResidenceTodoRule[]).map(
        (organizationResidenceTodoRule: OrganizationResidenceTodoRule) => {
          idsOrganizationResidenceTodoRules.push(organizationResidenceTodoRule.idOrganizationResidenceTodoRule);
          return { ...organizationResidenceTodoRule, organization: organization.idOrganization };
        }
      )
    );
    relations['organizationResidenceTodoRules'] = idsOrganizationResidenceTodoRules;
  }
}

function organizationNormalizeOrganizationResidenceTodo(
  organization: OrganizationEntityState,
  organizationResidenceTodosToNormalize: OrganizationResidenceTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organization.organizationResidenceTodos?.length &&
    typeof organization.organizationResidenceTodos[0] !== 'number'
  ) {
    const idsOrganizationResidenceTodos: number[] = [];
    organizationResidenceTodosToNormalize.push(
      ...(organization.organizationResidenceTodos as OrganizationResidenceTodo[]).map(
        (organizationResidenceTodo: OrganizationResidenceTodo) => {
          idsOrganizationResidenceTodos.push(organizationResidenceTodo.idOrganizationResidenceTodo);
          return { ...organizationResidenceTodo, organization: organization.idOrganization };
        }
      )
    );
    relations['organizationResidenceTodos'] = idsOrganizationResidenceTodos;
  }
}

function organizationNormalizeOrganizationStratalotTodo(
  organization: OrganizationEntityState,
  organizationStratalotTodosToNormalize: OrganizationStratalotTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organization.organizationStratalotTodos?.length &&
    typeof organization.organizationStratalotTodos[0] !== 'number'
  ) {
    const idsOrganizationStratalotTodos: number[] = [];
    organizationStratalotTodosToNormalize.push(
      ...(organization.organizationStratalotTodos as OrganizationStratalotTodo[]).map(
        (organizationStratalotTodo: OrganizationStratalotTodo) => {
          idsOrganizationStratalotTodos.push(organizationStratalotTodo.idOrganizationStratalotTodo);
          return { ...organizationStratalotTodo, organization: organization.idOrganization };
        }
      )
    );
    relations['organizationStratalotTodos'] = idsOrganizationStratalotTodos;
  }
}

function organizationNormalizeOrganizationResidenceStudyTodo(
  organization: OrganizationEntityState,
  organizationResidenceStudyTodosToNormalize: OrganizationResidenceStudyTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organization.organizationResidenceStudyTodos?.length &&
    typeof organization.organizationResidenceStudyTodos[0] !== 'number'
  ) {
    const idsOrganizationResidenceStudyTodos: number[] = [];
    organizationResidenceStudyTodosToNormalize.push(
      ...(organization.organizationResidenceStudyTodos as OrganizationResidenceStudyTodo[]).map(
        (organizationResidenceStudyTodo: OrganizationResidenceStudyTodo) => {
          idsOrganizationResidenceStudyTodos.push(organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo);
          return { ...organizationResidenceStudyTodo, organization: organization.idOrganization };
        }
      )
    );
    relations['organizationResidenceStudyTodos'] = idsOrganizationResidenceStudyTodos;
  }
}

function organizationNormalizeOrganizationProfil(
  organization: OrganizationEntityState,
  organizationProfilsToNormalize: OrganizationProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationProfils?.length && typeof organization.organizationProfils[0] !== 'number') {
    const idsOrganizationProfils: number[] = [];
    organizationProfilsToNormalize.push(
      ...(organization.organizationProfils as OrganizationProfil[]).map((organizationProfil: OrganizationProfil) => {
        idsOrganizationProfils.push(organizationProfil.idOrganizationProfil);
        return { ...organizationProfil, organization: organization.idOrganization };
      })
    );
    relations['organizationProfils'] = idsOrganizationProfils;
  }
}

function organizationNormalizeOrganizationProspectOrigin(
  organization: OrganizationEntityState,
  organizationProspectOriginsToNormalize: OrganizationProspectOriginEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organization.organizationProspectOrigins?.length &&
    typeof organization.organizationProspectOrigins[0] !== 'number'
  ) {
    const idsOrganizationProspectOrigins: number[] = [];
    organizationProspectOriginsToNormalize.push(
      ...(organization.organizationProspectOrigins as OrganizationProspectOrigin[]).map(
        (organizationProspectOrigin: OrganizationProspectOrigin) => {
          idsOrganizationProspectOrigins.push(organizationProspectOrigin.idOrganizationProspectOrigin);
          return { ...organizationProspectOrigin, organization: organization.idOrganization };
        }
      )
    );
    relations['organizationProspectOrigins'] = idsOrganizationProspectOrigins;
  }
}

function organizationNormalizeProspect(
  organization: OrganizationEntityState,
  prospectsToNormalize: ProspectEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.prospects?.length && typeof organization.prospects[0] !== 'number') {
    const idsProspects: number[] = [];
    prospectsToNormalize.push(
      ...(organization.prospects as Prospect[]).map((prospect: Prospect) => {
        idsProspects.push(prospect.idProspect);
        return { ...prospect, organization: organization.idOrganization };
      })
    );
    relations['prospects'] = idsProspects;
  }
}

function organizationNormalizeProfil(
  organization: OrganizationEntityState,
  profilsToNormalize: ProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.profils?.length && typeof organization.profils[0] !== 'number') {
    const idsProfils: number[] = [];
    profilsToNormalize.push(
      ...(organization.profils as Profil[]).map((profil: Profil) => {
        idsProfils.push(profil.idProfil);
        return { ...profil, organization: organization.idOrganization };
      })
    );
    relations['profils'] = idsProfils;
  }
}

function organizationNormalizeGeneratedDocument(
  organization: OrganizationEntityState,
  generatedDocumentsToNormalize: GeneratedDocumentEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.generatedDocuments?.length && typeof organization.generatedDocuments[0] !== 'number') {
    const idsGeneratedDocuments: number[] = [];
    generatedDocumentsToNormalize.push(
      ...(organization.generatedDocuments as GeneratedDocument[]).map((generatedDocument: GeneratedDocument) => {
        idsGeneratedDocuments.push(generatedDocument.idGeneratedDocument);
        return { ...generatedDocument, organization: organization.idOrganization };
      })
    );
    relations['generatedDocuments'] = idsGeneratedDocuments;
  }
}

function organizationNormalizeOrganizationThirdParty(
  organization: OrganizationEntityState,
  organizationThirdPartiesToNormalize: OrganizationThirdPartyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationThirdParties?.length && typeof organization.organizationThirdParties[0] !== 'number') {
    const idsOrganizationThirdParties: number[] = [];
    organizationThirdPartiesToNormalize.push(
      ...(organization.organizationThirdParties as OrganizationThirdParty[]).map(
        (organizationThirdParty: OrganizationThirdParty) => {
          idsOrganizationThirdParties.push(organizationThirdParty.idOrganizationThirdParty);
          return { ...organizationThirdParty, organization: organization.idOrganization };
        }
      )
    );
    relations['organizationThirdParties'] = idsOrganizationThirdParties;
  }
}

function organizationNormalizeOrganizationStratalotAvancement(
  organization: OrganizationEntityState,
  organizationStratalotAvancementsToNormalize: OrganizationStratalotAvancementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organization.organizationStratalotAvancements?.length &&
    typeof organization.organizationStratalotAvancements[0] !== 'number'
  ) {
    const idsOrganizationStratalotAvancements: number[] = [];
    organizationStratalotAvancementsToNormalize.push(
      ...(organization.organizationStratalotAvancements as OrganizationStratalotAvancement[]).map(
        (organizationStratalotAvancement: OrganizationStratalotAvancement) => {
          idsOrganizationStratalotAvancements.push(organizationStratalotAvancement.idOrganizationStratalotAvancement);
          return { ...organizationStratalotAvancement, organization: organization.idOrganization };
        }
      )
    );
    relations['organizationStratalotAvancements'] = idsOrganizationStratalotAvancements;
  }
}

function organizationNormalizeOrganizationLeadAvancement(
  organization: OrganizationEntityState,
  organizationLeadAvancementsToNormalize: OrganizationLeadAvancementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organization.organizationLeadAvancements?.length &&
    typeof organization.organizationLeadAvancements[0] !== 'number'
  ) {
    const idsOrganizationLeadAvancements: number[] = [];
    organizationLeadAvancementsToNormalize.push(
      ...(organization.organizationLeadAvancements as OrganizationLeadAvancement[]).map(
        (organizationLeadAvancement: OrganizationLeadAvancement) => {
          idsOrganizationLeadAvancements.push(organizationLeadAvancement.idOrganizationLeadAvancement);
          return { ...organizationLeadAvancement, organization: organization.idOrganization };
        }
      )
    );
    relations['organizationLeadAvancements'] = idsOrganizationLeadAvancements;
  }
}

function organizationNormalizeOrganizationSaleCategory(
  organization: OrganizationEntityState,
  organizationSaleCategoriesToNormalize: OrganizationSaleCategoryEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organization.organizationSaleCategories?.length &&
    typeof organization.organizationSaleCategories[0] !== 'number'
  ) {
    const idsOrganizationSaleCategories: number[] = [];
    organizationSaleCategoriesToNormalize.push(
      ...(organization.organizationSaleCategories as OrganizationSaleCategory[]).map(
        (organizationSaleCategory: OrganizationSaleCategory) => {
          idsOrganizationSaleCategories.push(organizationSaleCategory.idOrganizationSaleCategory);
          return { ...organizationSaleCategory, organization: organization.idOrganization };
        }
      )
    );
    relations['organizationSaleCategories'] = idsOrganizationSaleCategories;
  }
}

function organizationNormalizeOrganizationBuyingWish(
  organization: OrganizationEntityState,
  organizationBuyingWishesToNormalize: OrganizationBuyingWishEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationBuyingWishes?.length && typeof organization.organizationBuyingWishes[0] !== 'number') {
    const idsOrganizationBuyingWishes: number[] = [];
    organizationBuyingWishesToNormalize.push(
      ...(organization.organizationBuyingWishes as OrganizationBuyingWish[]).map(
        (organizationBuyingWish: OrganizationBuyingWish) => {
          idsOrganizationBuyingWishes.push(organizationBuyingWish.idOrganizationBuyingWish);
          return { ...organizationBuyingWish, organization: organization.idOrganization };
        }
      )
    );
    relations['organizationBuyingWishes'] = idsOrganizationBuyingWishes;
  }
}

function organizationNormalizeOrganizationEnergie(
  organization: OrganizationEntityState,
  organizationEnergiesToNormalize: OrganizationEnergieEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationEnergies?.length && typeof organization.organizationEnergies[0] !== 'number') {
    const idsOrganizationEnergies: number[] = [];
    organizationEnergiesToNormalize.push(
      ...(organization.organizationEnergies as OrganizationEnergie[]).map(
        (organizationEnergie: OrganizationEnergie) => {
          idsOrganizationEnergies.push(organizationEnergie.idOrganizationEnergie);
          return { ...organizationEnergie, organization: organization.idOrganization };
        }
      )
    );
    relations['organizationEnergies'] = idsOrganizationEnergies;
  }
}

function organizationNormalizeOrganizationForecastRate(
  organization: OrganizationEntityState,
  organizationForecastRatesToNormalize: OrganizationForecastRateEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organization.organizationForecastRates?.length && typeof organization.organizationForecastRates[0] !== 'number') {
    const idsOrganizationForecastRates: number[] = [];
    organizationForecastRatesToNormalize.push(
      ...(organization.organizationForecastRates as OrganizationForecastRate[]).map(
        (organizationForecastRate: OrganizationForecastRate) => {
          idsOrganizationForecastRates.push(organizationForecastRate.idOrganizationForecastRate);
          return { ...organizationForecastRate, organization: organization.idOrganization };
        }
      )
    );
    relations['organizationForecastRates'] = idsOrganizationForecastRates;
  }
}

/**
 *  OrganizationMetier
 */
export function getActionsToNormalizeOrganizationMetier(
  organizationMetiers: OrganizationMetierEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const metierFamiliesToNormalize: { [id: number]: MetierFamilieEntityState } = {};
  const organizationThirdPartiesToNormalize: OrganizationThirdPartyEntityState[] = [];
  const companyCommunicationRecipientsToNormalize: { [id: number]: CompanyCommunicationRecipientEntityState } = {};
  const companyCommunicationRecipientMetiersToNormalize: CompanyCommunicationRecipientMetierEntityState[] = [];

  const normalizedOrganizationMetiers = organizationMetiers.map((organizationMetier: OrganizationMetierEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    organizationMetierNormalizeOrganizationThirdParty(
      organizationMetier,
      organizationThirdPartiesToNormalize,
      relations
    );
    organizationMetierNormalizeCompanyCommunicationRecipient(
      organizationMetier,
      companyCommunicationRecipientsToNormalize,
      relations
    );
    organizationMetierNormalizeCompanyCommunicationRecipientMetier(
      organizationMetier,
      companyCommunicationRecipientMetiersToNormalize,
      relations
    );
    organizationMetierNormalizeOrganization(organizationMetier, organizationsToNormalize, relations);
    organizationMetierNormalizeMetierFamilie(organizationMetier, metierFamiliesToNormalize, relations);

    return {
      ...organizationMetier,
      ...relations
    };
  });
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const metierFamiliesToNormalizeArray = Object.values(metierFamiliesToNormalize);
  if (metierFamiliesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeMetierFamilie(metierFamiliesToNormalizeArray, actionType));
  }
  if (organizationThirdPartiesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationThirdParty(organizationThirdPartiesToNormalize, actionType));
  }
  const companyCommunicationRecipientsToNormalizeArray = Object.values(companyCommunicationRecipientsToNormalize);
  if (companyCommunicationRecipientsToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationRecipient(companyCommunicationRecipientsToNormalizeArray, actionType)
    );
  }
  if (companyCommunicationRecipientMetiersToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationRecipientMetier(
        companyCommunicationRecipientMetiersToNormalize,
        actionType
      )
    );
  }

  const organizationMetierActionCreator = organizationMetierActions.upsertManyOrganizationMetiersSuccess;
  actions.push(organizationMetierActionCreator({ organizationMetiers: normalizedOrganizationMetiers }));

  return actions;
}

function organizationMetierNormalizeOrganizationThirdParty(
  organizationMetier: OrganizationMetierEntityState,
  organizationThirdPartiesToNormalize: OrganizationThirdPartyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationMetier.organizationThirdParties?.length &&
    typeof organizationMetier.organizationThirdParties[0] !== 'number'
  ) {
    const idsOrganizationThirdParties: number[] = [];
    organizationThirdPartiesToNormalize.push(
      ...(organizationMetier.organizationThirdParties as OrganizationThirdParty[]).map(
        (organizationThirdParty: OrganizationThirdParty) => {
          idsOrganizationThirdParties.push(organizationThirdParty.idOrganizationThirdParty);
          return { ...organizationThirdParty, organizationMetier: organizationMetier.idOrganizationMetier };
        }
      )
    );
    relations['organizationThirdParties'] = idsOrganizationThirdParties;
  }
}

function organizationMetierNormalizeCompanyCommunicationRecipient(
  organizationMetier: OrganizationMetierEntityState,
  companyCommunicationRecipientsToNormalize: { [id: number]: CompanyCommunicationRecipientEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationMetier.companyCommunicationRecipients?.length &&
    typeof organizationMetier.companyCommunicationRecipients[0] !== 'number'
  ) {
    const idsCompanyCommunicationRecipients: number[] = (
      organizationMetier.companyCommunicationRecipients as CompanyCommunicationRecipient[]
    ).map((companyCommunicationRecipient: CompanyCommunicationRecipient) => {
      if (!companyCommunicationRecipientsToNormalize[companyCommunicationRecipient.idCompanyCommunicationRecipient]) {
        companyCommunicationRecipientsToNormalize[companyCommunicationRecipient.idCompanyCommunicationRecipient] = {
          ...companyCommunicationRecipient,
          organizationMetiers: []
        };
      }
      (
        companyCommunicationRecipientsToNormalize[companyCommunicationRecipient.idCompanyCommunicationRecipient]
          .organizationMetiers as number[]
      ).push(organizationMetier.idOrganizationMetier);
      return companyCommunicationRecipient.idCompanyCommunicationRecipient;
    });
    relations['companyCommunicationRecipients'] = idsCompanyCommunicationRecipients;
  }
}

function organizationMetierNormalizeCompanyCommunicationRecipientMetier(
  organizationMetier: OrganizationMetierEntityState,
  companyCommunicationRecipientMetiersToNormalize: CompanyCommunicationRecipientMetierEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationMetier.companyCommunicationRecipientMetiers?.length &&
    typeof organizationMetier.companyCommunicationRecipientMetiers[0] !== 'number'
  ) {
    const idsCompanyCommunicationRecipientMetiers: number[] = [];
    companyCommunicationRecipientMetiersToNormalize.push(
      ...(organizationMetier.companyCommunicationRecipientMetiers as CompanyCommunicationRecipientMetier[]).map(
        (companyCommunicationRecipientMetier: CompanyCommunicationRecipientMetier) => {
          idsCompanyCommunicationRecipientMetiers.push(
            companyCommunicationRecipientMetier.idCompanyCommunicationRecipientMetier
          );
          return {
            ...companyCommunicationRecipientMetier,
            organizationMetier: organizationMetier.idOrganizationMetier
          };
        }
      )
    );
    relations['companyCommunicationRecipientMetiers'] = idsCompanyCommunicationRecipientMetiers;
  }
}

function organizationMetierNormalizeOrganization(
  organizationMetier: OrganizationMetierEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationMetier.organization && typeof organizationMetier.organization !== 'number') {
    if (!organizationsToNormalize[organizationMetier.organization.idOrganization]) {
      organizationsToNormalize[organizationMetier.organization.idOrganization] = {
        ...organizationMetier.organization,
        organizationMetiers: []
      };
    }
    (organizationsToNormalize[organizationMetier.organization.idOrganization].organizationMetiers as number[]).push(
      organizationMetier.idOrganizationMetier
    );
    relations['organization'] = organizationMetier.organization.idOrganization;
  }
}

function organizationMetierNormalizeMetierFamilie(
  organizationMetier: OrganizationMetierEntityState,
  metierFamiliesToNormalize: { [id: number]: MetierFamilieEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationMetier.metierFamilie && typeof organizationMetier.metierFamilie !== 'number') {
    if (!metierFamiliesToNormalize[organizationMetier.metierFamilie.idMetierFamilie]) {
      metierFamiliesToNormalize[organizationMetier.metierFamilie.idMetierFamilie] = {
        ...organizationMetier.metierFamilie,
        organizationMetiers: []
      };
    }
    (metierFamiliesToNormalize[organizationMetier.metierFamilie.idMetierFamilie].organizationMetiers as number[]).push(
      organizationMetier.idOrganizationMetier
    );
    relations['metierFamilie'] = organizationMetier.metierFamilie.idMetierFamilie;
  }
}

/**
 *  OrganizationBuyingWish
 */
export function getActionsToNormalizeOrganizationBuyingWish(
  organizationBuyingWishes: OrganizationBuyingWishEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const prospectBuyingWishsToNormalize: ProspectBuyingWishEntityState[] = [];

  const normalizedOrganizationBuyingWishes = organizationBuyingWishes.map(
    (organizationBuyingWish: OrganizationBuyingWishEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationBuyingWishNormalizeProspectBuyingWish(
        organizationBuyingWish,
        prospectBuyingWishsToNormalize,
        relations
      );
      organizationBuyingWishNormalizeOrganization(organizationBuyingWish, organizationsToNormalize, relations);

      return {
        ...organizationBuyingWish,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  if (prospectBuyingWishsToNormalize.length) {
    actions.push(...getActionsToNormalizeProspectBuyingWish(prospectBuyingWishsToNormalize, actionType));
  }

  const organizationBuyingWishActionCreator = organizationBuyingWishActions.upsertManyOrganizationBuyingWishesSuccess;
  actions.push(organizationBuyingWishActionCreator({ organizationBuyingWishes: normalizedOrganizationBuyingWishes }));

  return actions;
}

function organizationBuyingWishNormalizeProspectBuyingWish(
  organizationBuyingWish: OrganizationBuyingWishEntityState,
  prospectBuyingWishsToNormalize: ProspectBuyingWishEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationBuyingWish.prospectBuyingWishs?.length &&
    typeof organizationBuyingWish.prospectBuyingWishs[0] !== 'number'
  ) {
    const idsProspectBuyingWishs: number[] = [];
    prospectBuyingWishsToNormalize.push(
      ...(organizationBuyingWish.prospectBuyingWishs as ProspectBuyingWish[]).map(
        (prospectBuyingWish: ProspectBuyingWish) => {
          idsProspectBuyingWishs.push(prospectBuyingWish.idProspectBuyingWish);
          return { ...prospectBuyingWish, organizationBuyingWish: organizationBuyingWish.idOrganizationBuyingWish };
        }
      )
    );
    relations['prospectBuyingWishs'] = idsProspectBuyingWishs;
  }
}

function organizationBuyingWishNormalizeOrganization(
  organizationBuyingWish: OrganizationBuyingWishEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationBuyingWish.organization && typeof organizationBuyingWish.organization !== 'number') {
    if (!organizationsToNormalize[organizationBuyingWish.organization.idOrganization]) {
      organizationsToNormalize[organizationBuyingWish.organization.idOrganization] = {
        ...organizationBuyingWish.organization,
        organizationBuyingWishes: []
      };
    }
    (
      organizationsToNormalize[organizationBuyingWish.organization.idOrganization].organizationBuyingWishes as number[]
    ).push(organizationBuyingWish.idOrganizationBuyingWish);
    relations['organization'] = organizationBuyingWish.organization.idOrganization;
  }
}

/**
 *  OrganizationEnergie
 */
export function getActionsToNormalizeOrganizationEnergie(
  organizationEnergies: OrganizationEnergieEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};

  const normalizedOrganizationEnergies = organizationEnergies.map(
    (organizationEnergie: OrganizationEnergieEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationEnergieNormalizeOrganization(organizationEnergie, organizationsToNormalize, relations);

      return {
        ...organizationEnergie,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }

  const organizationEnergieActionCreator = organizationEnergieActions.upsertManyOrganizationEnergiesSuccess;
  actions.push(organizationEnergieActionCreator({ organizationEnergies: normalizedOrganizationEnergies }));

  return actions;
}

function organizationEnergieNormalizeOrganization(
  organizationEnergie: OrganizationEnergieEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationEnergie.organization && typeof organizationEnergie.organization !== 'number') {
    if (!organizationsToNormalize[organizationEnergie.organization.idOrganization]) {
      organizationsToNormalize[organizationEnergie.organization.idOrganization] = {
        ...organizationEnergie.organization,
        organizationEnergies: []
      };
    }
    (organizationsToNormalize[organizationEnergie.organization.idOrganization].organizationEnergies as number[]).push(
      organizationEnergie.idOrganizationEnergie
    );
    relations['organization'] = organizationEnergie.organization.idOrganization;
  }
}

/**
 *  OrganizationLeadAvancement
 */
export function getActionsToNormalizeOrganizationLeadAvancement(
  organizationLeadAvancements: OrganizationLeadAvancementEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const stepsToNormalize: { [id: number]: StepEntityState } = {};
  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const leadsToNormalize: LeadEntityState[] = [];

  const normalizedOrganizationLeadAvancements = organizationLeadAvancements.map(
    (organizationLeadAvancement: OrganizationLeadAvancementEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationLeadAvancementNormalizeLead(organizationLeadAvancement, leadsToNormalize, relations);
      organizationLeadAvancementNormalizeStep(organizationLeadAvancement, stepsToNormalize, relations);
      organizationLeadAvancementNormalizeOrganization(organizationLeadAvancement, organizationsToNormalize, relations);

      return {
        ...organizationLeadAvancement,
        ...relations
      };
    }
  );
  const stepsToNormalizeArray = Object.values(stepsToNormalize);
  if (stepsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStep(stepsToNormalizeArray, actionType));
  }
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  if (leadsToNormalize.length) {
    actions.push(...getActionsToNormalizeLead(leadsToNormalize, actionType));
  }

  const organizationLeadAvancementActionCreator =
    organizationLeadAvancementActions.upsertManyOrganizationLeadAvancementsSuccess;
  actions.push(
    organizationLeadAvancementActionCreator({ organizationLeadAvancements: normalizedOrganizationLeadAvancements })
  );

  return actions;
}

function organizationLeadAvancementNormalizeLead(
  organizationLeadAvancement: OrganizationLeadAvancementEntityState,
  leadsToNormalize: LeadEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationLeadAvancement.leads?.length && typeof organizationLeadAvancement.leads[0] !== 'number') {
    const idsLeads: number[] = [];
    leadsToNormalize.push(
      ...(organizationLeadAvancement.leads as Lead[]).map((lead: Lead) => {
        idsLeads.push(lead.idLead);
        return { ...lead, organizationLeadAvancement: organizationLeadAvancement.idOrganizationLeadAvancement };
      })
    );
    relations['leads'] = idsLeads;
  }
}

function organizationLeadAvancementNormalizeStep(
  organizationLeadAvancement: OrganizationLeadAvancementEntityState,
  stepsToNormalize: { [id: number]: StepEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationLeadAvancement.step && typeof organizationLeadAvancement.step !== 'number') {
    if (!stepsToNormalize[organizationLeadAvancement.step.idStep]) {
      stepsToNormalize[organizationLeadAvancement.step.idStep] = {
        ...organizationLeadAvancement.step,
        organizationLeadAvancements: []
      };
    }
    (stepsToNormalize[organizationLeadAvancement.step.idStep].organizationLeadAvancements as number[]).push(
      organizationLeadAvancement.idOrganizationLeadAvancement
    );
    relations['step'] = organizationLeadAvancement.step.idStep;
  }
}

function organizationLeadAvancementNormalizeOrganization(
  organizationLeadAvancement: OrganizationLeadAvancementEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationLeadAvancement.organization && typeof organizationLeadAvancement.organization !== 'number') {
    if (!organizationsToNormalize[organizationLeadAvancement.organization.idOrganization]) {
      organizationsToNormalize[organizationLeadAvancement.organization.idOrganization] = {
        ...organizationLeadAvancement.organization,
        organizationLeadAvancements: []
      };
    }
    (
      organizationsToNormalize[organizationLeadAvancement.organization.idOrganization]
        .organizationLeadAvancements as number[]
    ).push(organizationLeadAvancement.idOrganizationLeadAvancement);
    relations['organization'] = organizationLeadAvancement.organization.idOrganization;
  }
}

/**
 *  OrganizationLeadTodo
 */
export function getActionsToNormalizeOrganizationLeadTodo(
  organizationLeadTodos: OrganizationLeadTodoEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const stepsToNormalize: { [id: number]: StepEntityState } = {};
  const leadTodosToNormalize: LeadTodoEntityState[] = [];
  const organizationLeadTodoRulesToNormalize: { [id: number]: OrganizationLeadTodoRuleEntityState } = {};
  const organizationLeadTodoAppliedRulesToNormalize: OrganizationLeadTodoAppliedRuleEntityState[] = [];
  const organizationLeadTodoProfilsToNormalize: OrganizationLeadTodoProfilEntityState[] = [];
  const companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState } = {};
  const companyCommunicationLeadTodosToNormalize: CompanyCommunicationLeadTodoEntityState[] = [];

  const normalizedOrganizationLeadTodos = organizationLeadTodos.map(
    (organizationLeadTodo: OrganizationLeadTodoEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationLeadTodoNormalizeLeadTodo(organizationLeadTodo, leadTodosToNormalize, relations);
      organizationLeadTodoNormalizeOrganizationLeadTodoRule(
        organizationLeadTodo,
        organizationLeadTodoRulesToNormalize,
        relations
      );
      organizationLeadTodoNormalizeOrganizationLeadTodoAppliedRule(
        organizationLeadTodo,
        organizationLeadTodoAppliedRulesToNormalize,
        relations
      );
      organizationLeadTodoNormalizeOrganizationLeadTodoProfil(
        organizationLeadTodo,
        organizationLeadTodoProfilsToNormalize,
        relations
      );
      organizationLeadTodoNormalizeCompanyCommunication(
        organizationLeadTodo,
        companyCommunicationsToNormalize,
        relations
      );
      organizationLeadTodoNormalizeCompanyCommunicationLeadTodo(
        organizationLeadTodo,
        companyCommunicationLeadTodosToNormalize,
        relations
      );
      organizationLeadTodoNormalizeOrganization(organizationLeadTodo, organizationsToNormalize, relations);
      organizationLeadTodoNormalizeStep(organizationLeadTodo, stepsToNormalize, relations);

      return {
        ...organizationLeadTodo,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const stepsToNormalizeArray = Object.values(stepsToNormalize);
  if (stepsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStep(stepsToNormalizeArray, actionType));
  }
  if (leadTodosToNormalize.length) {
    actions.push(...getActionsToNormalizeLeadTodo(leadTodosToNormalize, actionType));
  }
  const organizationLeadTodoRulesToNormalizeArray = Object.values(organizationLeadTodoRulesToNormalize);
  if (organizationLeadTodoRulesToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationLeadTodoRule(organizationLeadTodoRulesToNormalizeArray, actionType)
    );
  }
  if (organizationLeadTodoAppliedRulesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationLeadTodoAppliedRule(organizationLeadTodoAppliedRulesToNormalize, actionType)
    );
  }
  if (organizationLeadTodoProfilsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationLeadTodoProfil(organizationLeadTodoProfilsToNormalize, actionType)
    );
  }
  const companyCommunicationsToNormalizeArray = Object.values(companyCommunicationsToNormalize);
  if (companyCommunicationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyCommunication(companyCommunicationsToNormalizeArray, actionType));
  }
  if (companyCommunicationLeadTodosToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationLeadTodo(companyCommunicationLeadTodosToNormalize, actionType)
    );
  }

  const organizationLeadTodoActionCreator = organizationLeadTodoActions.upsertManyOrganizationLeadTodosSuccess;
  actions.push(organizationLeadTodoActionCreator({ organizationLeadTodos: normalizedOrganizationLeadTodos }));

  return actions;
}

function organizationLeadTodoNormalizeLeadTodo(
  organizationLeadTodo: OrganizationLeadTodoEntityState,
  leadTodosToNormalize: LeadTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationLeadTodo.leadTodos?.length && typeof organizationLeadTodo.leadTodos[0] !== 'number') {
    const idsLeadTodos: number[] = [];
    leadTodosToNormalize.push(
      ...(organizationLeadTodo.leadTodos as LeadTodo[]).map((leadTodo: LeadTodo) => {
        idsLeadTodos.push(leadTodo.idLeadTodo);
        return { ...leadTodo, organizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo };
      })
    );
    relations['leadTodos'] = idsLeadTodos;
  }
}

function organizationLeadTodoNormalizeOrganizationLeadTodoRule(
  organizationLeadTodo: OrganizationLeadTodoEntityState,
  organizationLeadTodoRulesToNormalize: { [id: number]: OrganizationLeadTodoRuleEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationLeadTodo.organizationLeadTodoRules?.length &&
    typeof organizationLeadTodo.organizationLeadTodoRules[0] !== 'number'
  ) {
    const idsOrganizationLeadTodoRules: number[] = (
      organizationLeadTodo.organizationLeadTodoRules as OrganizationLeadTodoRule[]
    ).map((organizationLeadTodoRule: OrganizationLeadTodoRule) => {
      if (!organizationLeadTodoRulesToNormalize[organizationLeadTodoRule.idOrganizationLeadTodoRule]) {
        organizationLeadTodoRulesToNormalize[organizationLeadTodoRule.idOrganizationLeadTodoRule] = {
          ...organizationLeadTodoRule,
          organizationLeadTodos: []
        };
      }
      (
        organizationLeadTodoRulesToNormalize[organizationLeadTodoRule.idOrganizationLeadTodoRule]
          .organizationLeadTodos as number[]
      ).push(organizationLeadTodo.idOrganizationLeadTodo);
      return organizationLeadTodoRule.idOrganizationLeadTodoRule;
    });
    relations['organizationLeadTodoRules'] = idsOrganizationLeadTodoRules;
  }
}

function organizationLeadTodoNormalizeOrganizationLeadTodoAppliedRule(
  organizationLeadTodo: OrganizationLeadTodoEntityState,
  organizationLeadTodoAppliedRulesToNormalize: OrganizationLeadTodoAppliedRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationLeadTodo.organizationLeadTodoAppliedRules?.length &&
    typeof organizationLeadTodo.organizationLeadTodoAppliedRules[0] !== 'number'
  ) {
    const idsOrganizationLeadTodoAppliedRules: number[] = [];
    organizationLeadTodoAppliedRulesToNormalize.push(
      ...(organizationLeadTodo.organizationLeadTodoAppliedRules as OrganizationLeadTodoAppliedRule[]).map(
        (organizationLeadTodoAppliedRule: OrganizationLeadTodoAppliedRule) => {
          idsOrganizationLeadTodoAppliedRules.push(organizationLeadTodoAppliedRule.idOrganizationLeadTodoAppliedRule);
          return {
            ...organizationLeadTodoAppliedRule,
            organizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo
          };
        }
      )
    );
    relations['organizationLeadTodoAppliedRules'] = idsOrganizationLeadTodoAppliedRules;
  }
}

function organizationLeadTodoNormalizeOrganizationLeadTodoProfil(
  organizationLeadTodo: OrganizationLeadTodoEntityState,
  organizationLeadTodoProfilsToNormalize: OrganizationLeadTodoProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationLeadTodo.organizationLeadTodoProfils?.length &&
    typeof organizationLeadTodo.organizationLeadTodoProfils[0] !== 'number'
  ) {
    const idsOrganizationLeadTodoProfils: number[] = [];
    organizationLeadTodoProfilsToNormalize.push(
      ...(organizationLeadTodo.organizationLeadTodoProfils as OrganizationLeadTodoProfil[]).map(
        (organizationLeadTodoProfil: OrganizationLeadTodoProfil) => {
          idsOrganizationLeadTodoProfils.push(organizationLeadTodoProfil.idOrganizationLeadTodoProfil);
          return { ...organizationLeadTodoProfil, organizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo };
        }
      )
    );
    relations['organizationLeadTodoProfils'] = idsOrganizationLeadTodoProfils;
  }
}

function organizationLeadTodoNormalizeCompanyCommunication(
  organizationLeadTodo: OrganizationLeadTodoEntityState,
  companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationLeadTodo.companyCommunications?.length &&
    typeof organizationLeadTodo.companyCommunications[0] !== 'number'
  ) {
    const idsCompanyCommunications: number[] = (
      organizationLeadTodo.companyCommunications as CompanyCommunication[]
    ).map((companyCommunication: CompanyCommunication) => {
      if (!companyCommunicationsToNormalize[companyCommunication.idCompanyCommunication]) {
        companyCommunicationsToNormalize[companyCommunication.idCompanyCommunication] = {
          ...companyCommunication,
          organizationLeadTodos: []
        };
      }
      (
        companyCommunicationsToNormalize[companyCommunication.idCompanyCommunication].organizationLeadTodos as number[]
      ).push(organizationLeadTodo.idOrganizationLeadTodo);
      return companyCommunication.idCompanyCommunication;
    });
    relations['companyCommunications'] = idsCompanyCommunications;
  }
}

function organizationLeadTodoNormalizeCompanyCommunicationLeadTodo(
  organizationLeadTodo: OrganizationLeadTodoEntityState,
  companyCommunicationLeadTodosToNormalize: CompanyCommunicationLeadTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationLeadTodo.companyCommunicationLeadTodos?.length &&
    typeof organizationLeadTodo.companyCommunicationLeadTodos[0] !== 'number'
  ) {
    const idsCompanyCommunicationLeadTodos: number[] = [];
    companyCommunicationLeadTodosToNormalize.push(
      ...(organizationLeadTodo.companyCommunicationLeadTodos as CompanyCommunicationLeadTodo[]).map(
        (companyCommunicationLeadTodo: CompanyCommunicationLeadTodo) => {
          idsCompanyCommunicationLeadTodos.push(companyCommunicationLeadTodo.idCompanyCommunicationLeadTodo);
          return { ...companyCommunicationLeadTodo, organizationLeadTodo: organizationLeadTodo.idOrganizationLeadTodo };
        }
      )
    );
    relations['companyCommunicationLeadTodos'] = idsCompanyCommunicationLeadTodos;
  }
}

function organizationLeadTodoNormalizeOrganization(
  organizationLeadTodo: OrganizationLeadTodoEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationLeadTodo.organization && typeof organizationLeadTodo.organization !== 'number') {
    if (!organizationsToNormalize[organizationLeadTodo.organization.idOrganization]) {
      organizationsToNormalize[organizationLeadTodo.organization.idOrganization] = {
        ...organizationLeadTodo.organization,
        organizationLeadTodos: []
      };
    }
    (organizationsToNormalize[organizationLeadTodo.organization.idOrganization].organizationLeadTodos as number[]).push(
      organizationLeadTodo.idOrganizationLeadTodo
    );
    relations['organization'] = organizationLeadTodo.organization.idOrganization;
  }
}

function organizationLeadTodoNormalizeStep(
  organizationLeadTodo: OrganizationLeadTodoEntityState,
  stepsToNormalize: { [id: number]: StepEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationLeadTodo.step && typeof organizationLeadTodo.step !== 'number') {
    if (!stepsToNormalize[organizationLeadTodo.step.idStep]) {
      stepsToNormalize[organizationLeadTodo.step.idStep] = { ...organizationLeadTodo.step, organizationLeadTodos: [] };
    }
    (stepsToNormalize[organizationLeadTodo.step.idStep].organizationLeadTodos as number[]).push(
      organizationLeadTodo.idOrganizationLeadTodo
    );
    relations['step'] = organizationLeadTodo.step.idStep;
  }
}

/**
 *  OrganizationLeadTodoProfil
 */
export function getActionsToNormalizeOrganizationLeadTodoProfil(
  organizationLeadTodoProfils: OrganizationLeadTodoProfilEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const profilsToNormalize: { [id: number]: ProfilEntityState } = {};
  const organizationLeadTodosToNormalize: { [id: number]: OrganizationLeadTodoEntityState } = {};

  const normalizedOrganizationLeadTodoProfils = organizationLeadTodoProfils.map(
    (organizationLeadTodoProfil: OrganizationLeadTodoProfilEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationLeadTodoProfilNormalizeProfil(organizationLeadTodoProfil, profilsToNormalize, relations);
      organizationLeadTodoProfilNormalizeOrganizationLeadTodo(
        organizationLeadTodoProfil,
        organizationLeadTodosToNormalize,
        relations
      );

      return {
        ...organizationLeadTodoProfil,
        ...relations
      };
    }
  );
  const profilsToNormalizeArray = Object.values(profilsToNormalize);
  if (profilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProfil(profilsToNormalizeArray, actionType));
  }
  const organizationLeadTodosToNormalizeArray = Object.values(organizationLeadTodosToNormalize);
  if (organizationLeadTodosToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationLeadTodo(organizationLeadTodosToNormalizeArray, actionType));
  }

  const organizationLeadTodoProfilActionCreator =
    organizationLeadTodoProfilActions.upsertManyOrganizationLeadTodoProfilsSuccess;
  actions.push(
    organizationLeadTodoProfilActionCreator({ organizationLeadTodoProfils: normalizedOrganizationLeadTodoProfils })
  );

  return actions;
}

function organizationLeadTodoProfilNormalizeProfil(
  organizationLeadTodoProfil: OrganizationLeadTodoProfilEntityState,
  profilsToNormalize: { [id: number]: ProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationLeadTodoProfil.profil && typeof organizationLeadTodoProfil.profil !== 'number') {
    if (!profilsToNormalize[organizationLeadTodoProfil.profil.idProfil]) {
      profilsToNormalize[organizationLeadTodoProfil.profil.idProfil] = {
        ...organizationLeadTodoProfil.profil,
        organizationLeadTodoProfils: []
      };
    }
    (profilsToNormalize[organizationLeadTodoProfil.profil.idProfil].organizationLeadTodoProfils as number[]).push(
      organizationLeadTodoProfil.idOrganizationLeadTodoProfil
    );
    relations['profil'] = organizationLeadTodoProfil.profil.idProfil;
  }
}

function organizationLeadTodoProfilNormalizeOrganizationLeadTodo(
  organizationLeadTodoProfil: OrganizationLeadTodoProfilEntityState,
  organizationLeadTodosToNormalize: { [id: number]: OrganizationLeadTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationLeadTodoProfil.organizationLeadTodo &&
    typeof organizationLeadTodoProfil.organizationLeadTodo !== 'number'
  ) {
    if (!organizationLeadTodosToNormalize[organizationLeadTodoProfil.organizationLeadTodo.idOrganizationLeadTodo]) {
      organizationLeadTodosToNormalize[organizationLeadTodoProfil.organizationLeadTodo.idOrganizationLeadTodo] = {
        ...organizationLeadTodoProfil.organizationLeadTodo,
        organizationLeadTodoProfils: []
      };
    }
    (
      organizationLeadTodosToNormalize[organizationLeadTodoProfil.organizationLeadTodo.idOrganizationLeadTodo]
        .organizationLeadTodoProfils as number[]
    ).push(organizationLeadTodoProfil.idOrganizationLeadTodoProfil);
    relations['organizationLeadTodo'] = organizationLeadTodoProfil.organizationLeadTodo.idOrganizationLeadTodo;
  }
}

/**
 *  OrganizationResidenceTodoProfil
 */
export function getActionsToNormalizeOrganizationResidenceTodoProfil(
  organizationResidenceTodoProfils: OrganizationResidenceTodoProfilEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const profilsToNormalize: { [id: number]: ProfilEntityState } = {};
  const organizationResidenceTodosToNormalize: { [id: number]: OrganizationResidenceTodoEntityState } = {};

  const normalizedOrganizationResidenceTodoProfils = organizationResidenceTodoProfils.map(
    (organizationResidenceTodoProfil: OrganizationResidenceTodoProfilEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationResidenceTodoProfilNormalizeProfil(organizationResidenceTodoProfil, profilsToNormalize, relations);
      organizationResidenceTodoProfilNormalizeOrganizationResidenceTodo(
        organizationResidenceTodoProfil,
        organizationResidenceTodosToNormalize,
        relations
      );

      return {
        ...organizationResidenceTodoProfil,
        ...relations
      };
    }
  );
  const profilsToNormalizeArray = Object.values(profilsToNormalize);
  if (profilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProfil(profilsToNormalizeArray, actionType));
  }
  const organizationResidenceTodosToNormalizeArray = Object.values(organizationResidenceTodosToNormalize);
  if (organizationResidenceTodosToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceTodo(organizationResidenceTodosToNormalizeArray, actionType)
    );
  }

  const organizationResidenceTodoProfilActionCreator =
    organizationResidenceTodoProfilActions.upsertManyOrganizationResidenceTodoProfilsSuccess;
  actions.push(
    organizationResidenceTodoProfilActionCreator({
      organizationResidenceTodoProfils: normalizedOrganizationResidenceTodoProfils
    })
  );

  return actions;
}

function organizationResidenceTodoProfilNormalizeProfil(
  organizationResidenceTodoProfil: OrganizationResidenceTodoProfilEntityState,
  profilsToNormalize: { [id: number]: ProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationResidenceTodoProfil.profil && typeof organizationResidenceTodoProfil.profil !== 'number') {
    if (!profilsToNormalize[organizationResidenceTodoProfil.profil.idProfil]) {
      profilsToNormalize[organizationResidenceTodoProfil.profil.idProfil] = {
        ...organizationResidenceTodoProfil.profil,
        organizationResidenceTodoProfils: []
      };
    }
    (
      profilsToNormalize[organizationResidenceTodoProfil.profil.idProfil].organizationResidenceTodoProfils as number[]
    ).push(organizationResidenceTodoProfil.idOrganizationResidenceTodoProfil);
    relations['profil'] = organizationResidenceTodoProfil.profil.idProfil;
  }
}

function organizationResidenceTodoProfilNormalizeOrganizationResidenceTodo(
  organizationResidenceTodoProfil: OrganizationResidenceTodoProfilEntityState,
  organizationResidenceTodosToNormalize: { [id: number]: OrganizationResidenceTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationResidenceTodoProfil.organizationResidenceTodo &&
    typeof organizationResidenceTodoProfil.organizationResidenceTodo !== 'number'
  ) {
    if (
      !organizationResidenceTodosToNormalize[
        organizationResidenceTodoProfil.organizationResidenceTodo.idOrganizationResidenceTodo
      ]
    ) {
      organizationResidenceTodosToNormalize[
        organizationResidenceTodoProfil.organizationResidenceTodo.idOrganizationResidenceTodo
      ] = { ...organizationResidenceTodoProfil.organizationResidenceTodo, organizationResidenceTodoProfils: [] };
    }
    (
      organizationResidenceTodosToNormalize[
        organizationResidenceTodoProfil.organizationResidenceTodo.idOrganizationResidenceTodo
      ].organizationResidenceTodoProfils as number[]
    ).push(organizationResidenceTodoProfil.idOrganizationResidenceTodoProfil);
    relations['organizationResidenceTodo'] =
      organizationResidenceTodoProfil.organizationResidenceTodo.idOrganizationResidenceTodo;
  }
}

/**
 *  OrganizationStratalotTodoProfil
 */
export function getActionsToNormalizeOrganizationStratalotTodoProfil(
  organizationStratalotTodoProfils: OrganizationStratalotTodoProfilEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const profilsToNormalize: { [id: number]: ProfilEntityState } = {};
  const organizationStratalotTodosToNormalize: { [id: number]: OrganizationStratalotTodoEntityState } = {};

  const normalizedOrganizationStratalotTodoProfils = organizationStratalotTodoProfils.map(
    (organizationStratalotTodoProfil: OrganizationStratalotTodoProfilEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationStratalotTodoProfilNormalizeProfil(organizationStratalotTodoProfil, profilsToNormalize, relations);
      organizationStratalotTodoProfilNormalizeOrganizationStratalotTodo(
        organizationStratalotTodoProfil,
        organizationStratalotTodosToNormalize,
        relations
      );

      return {
        ...organizationStratalotTodoProfil,
        ...relations
      };
    }
  );
  const profilsToNormalizeArray = Object.values(profilsToNormalize);
  if (profilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProfil(profilsToNormalizeArray, actionType));
  }
  const organizationStratalotTodosToNormalizeArray = Object.values(organizationStratalotTodosToNormalize);
  if (organizationStratalotTodosToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodo(organizationStratalotTodosToNormalizeArray, actionType)
    );
  }

  const organizationStratalotTodoProfilActionCreator =
    organizationStratalotTodoProfilActions.upsertManyOrganizationStratalotTodoProfilsSuccess;
  actions.push(
    organizationStratalotTodoProfilActionCreator({
      organizationStratalotTodoProfils: normalizedOrganizationStratalotTodoProfils
    })
  );

  return actions;
}

function organizationStratalotTodoProfilNormalizeProfil(
  organizationStratalotTodoProfil: OrganizationStratalotTodoProfilEntityState,
  profilsToNormalize: { [id: number]: ProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationStratalotTodoProfil.profil && typeof organizationStratalotTodoProfil.profil !== 'number') {
    if (!profilsToNormalize[organizationStratalotTodoProfil.profil.idProfil]) {
      profilsToNormalize[organizationStratalotTodoProfil.profil.idProfil] = {
        ...organizationStratalotTodoProfil.profil,
        organizationStratalotTodoProfils: []
      };
    }
    (
      profilsToNormalize[organizationStratalotTodoProfil.profil.idProfil].organizationStratalotTodoProfils as number[]
    ).push(organizationStratalotTodoProfil.idOrganizationStratalotTodoProfil);
    relations['profil'] = organizationStratalotTodoProfil.profil.idProfil;
  }
}

function organizationStratalotTodoProfilNormalizeOrganizationStratalotTodo(
  organizationStratalotTodoProfil: OrganizationStratalotTodoProfilEntityState,
  organizationStratalotTodosToNormalize: { [id: number]: OrganizationStratalotTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationStratalotTodoProfil.organizationStratalotTodo &&
    typeof organizationStratalotTodoProfil.organizationStratalotTodo !== 'number'
  ) {
    if (
      !organizationStratalotTodosToNormalize[
        organizationStratalotTodoProfil.organizationStratalotTodo.idOrganizationStratalotTodo
      ]
    ) {
      organizationStratalotTodosToNormalize[
        organizationStratalotTodoProfil.organizationStratalotTodo.idOrganizationStratalotTodo
      ] = { ...organizationStratalotTodoProfil.organizationStratalotTodo, organizationStratalotTodoProfils: [] };
    }
    (
      organizationStratalotTodosToNormalize[
        organizationStratalotTodoProfil.organizationStratalotTodo.idOrganizationStratalotTodo
      ].organizationStratalotTodoProfils as number[]
    ).push(organizationStratalotTodoProfil.idOrganizationStratalotTodoProfil);
    relations['organizationStratalotTodo'] =
      organizationStratalotTodoProfil.organizationStratalotTodo.idOrganizationStratalotTodo;
  }
}

/**
 *  OrganizationLeadTodoRule
 */
export function getActionsToNormalizeOrganizationLeadTodoRule(
  organizationLeadTodoRules: OrganizationLeadTodoRuleEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const organizationSaleCategoriesToNormalize: { [id: number]: OrganizationSaleCategoryEntityState } = {};
  const familySaleCategoriesToNormalize: { [id: number]: SaleCategoryFamilyEntityState } = {};
  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const companyStratalotTypesToNormalize: { [id: number]: CompanyStratalotTypeEntityState } = {};
  const stratalotTypesToNormalize: { [id: number]: StratalotTypeEntityState } = {};
  const organizationLeadTodosToNormalize: { [id: number]: OrganizationLeadTodoEntityState } = {};
  const organizationLeadTodoAppliedRulesToNormalize: OrganizationLeadTodoAppliedRuleEntityState[] = [];

  const normalizedOrganizationLeadTodoRules = organizationLeadTodoRules.map(
    (organizationLeadTodoRule: OrganizationLeadTodoRuleEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationLeadTodoRuleNormalizeOrganizationLeadTodo(
        organizationLeadTodoRule,
        organizationLeadTodosToNormalize,
        relations
      );
      organizationLeadTodoRuleNormalizeOrganizationLeadTodoAppliedRule(
        organizationLeadTodoRule,
        organizationLeadTodoAppliedRulesToNormalize,
        relations
      );
      organizationLeadTodoRuleNormalizeOrganization(organizationLeadTodoRule, organizationsToNormalize, relations);
      organizationLeadTodoRuleNormalizeOrganizationSaleCategory(
        organizationLeadTodoRule,
        organizationSaleCategoriesToNormalize,
        relations
      );
      organizationLeadTodoRuleNormalizeSaleCategoryFamily(
        organizationLeadTodoRule,
        familySaleCategoriesToNormalize,
        relations
      );
      organizationLeadTodoRuleNormalizeCompany(organizationLeadTodoRule, companiesToNormalize, relations);
      organizationLeadTodoRuleNormalizeCompanyStratalotType(
        organizationLeadTodoRule,
        companyStratalotTypesToNormalize,
        relations
      );
      organizationLeadTodoRuleNormalizeStratalotType(organizationLeadTodoRule, stratalotTypesToNormalize, relations);

      return {
        ...organizationLeadTodoRule,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const organizationSaleCategoriesToNormalizeArray = Object.values(organizationSaleCategoriesToNormalize);
  if (organizationSaleCategoriesToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationSaleCategory(organizationSaleCategoriesToNormalizeArray, actionType)
    );
  }
  const familySaleCategoriesToNormalizeArray = Object.values(familySaleCategoriesToNormalize);
  if (familySaleCategoriesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSaleCategoryFamily(familySaleCategoriesToNormalizeArray, actionType));
  }
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  const companyStratalotTypesToNormalizeArray = Object.values(companyStratalotTypesToNormalize);
  if (companyStratalotTypesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyStratalotType(companyStratalotTypesToNormalizeArray, actionType));
  }
  const stratalotTypesToNormalizeArray = Object.values(stratalotTypesToNormalize);
  if (stratalotTypesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalotType(stratalotTypesToNormalizeArray, actionType));
  }
  const organizationLeadTodosToNormalizeArray = Object.values(organizationLeadTodosToNormalize);
  if (organizationLeadTodosToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationLeadTodo(organizationLeadTodosToNormalizeArray, actionType));
  }
  if (organizationLeadTodoAppliedRulesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationLeadTodoAppliedRule(organizationLeadTodoAppliedRulesToNormalize, actionType)
    );
  }

  const organizationLeadTodoRuleActionCreator =
    organizationLeadTodoRuleActions.upsertManyOrganizationLeadTodoRulesSuccess;
  actions.push(
    organizationLeadTodoRuleActionCreator({ organizationLeadTodoRules: normalizedOrganizationLeadTodoRules })
  );

  return actions;
}

function organizationLeadTodoRuleNormalizeOrganizationLeadTodo(
  organizationLeadTodoRule: OrganizationLeadTodoRuleEntityState,
  organizationLeadTodosToNormalize: { [id: number]: OrganizationLeadTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationLeadTodoRule.organizationLeadTodos?.length &&
    typeof organizationLeadTodoRule.organizationLeadTodos[0] !== 'number'
  ) {
    const idsOrganizationLeadTodos: number[] = (
      organizationLeadTodoRule.organizationLeadTodos as OrganizationLeadTodo[]
    ).map((organizationLeadTodo: OrganizationLeadTodo) => {
      if (!organizationLeadTodosToNormalize[organizationLeadTodo.idOrganizationLeadTodo]) {
        organizationLeadTodosToNormalize[organizationLeadTodo.idOrganizationLeadTodo] = {
          ...organizationLeadTodo,
          organizationLeadTodoRules: []
        };
      }
      (
        organizationLeadTodosToNormalize[organizationLeadTodo.idOrganizationLeadTodo]
          .organizationLeadTodoRules as number[]
      ).push(organizationLeadTodoRule.idOrganizationLeadTodoRule);
      return organizationLeadTodo.idOrganizationLeadTodo;
    });
    relations['organizationLeadTodos'] = idsOrganizationLeadTodos;
  }
}

function organizationLeadTodoRuleNormalizeOrganizationLeadTodoAppliedRule(
  organizationLeadTodoRule: OrganizationLeadTodoRuleEntityState,
  organizationLeadTodoAppliedRulesToNormalize: OrganizationLeadTodoAppliedRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationLeadTodoRule.organizationLeadTodoAppliedRules?.length &&
    typeof organizationLeadTodoRule.organizationLeadTodoAppliedRules[0] !== 'number'
  ) {
    const idsOrganizationLeadTodoAppliedRules: number[] = [];
    organizationLeadTodoAppliedRulesToNormalize.push(
      ...(organizationLeadTodoRule.organizationLeadTodoAppliedRules as OrganizationLeadTodoAppliedRule[]).map(
        (organizationLeadTodoAppliedRule: OrganizationLeadTodoAppliedRule) => {
          idsOrganizationLeadTodoAppliedRules.push(organizationLeadTodoAppliedRule.idOrganizationLeadTodoAppliedRule);
          return {
            ...organizationLeadTodoAppliedRule,
            organizationLeadTodoRule: organizationLeadTodoRule.idOrganizationLeadTodoRule
          };
        }
      )
    );
    relations['organizationLeadTodoAppliedRules'] = idsOrganizationLeadTodoAppliedRules;
  }
}

function organizationLeadTodoRuleNormalizeOrganization(
  organizationLeadTodoRule: OrganizationLeadTodoRuleEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationLeadTodoRule.organization && typeof organizationLeadTodoRule.organization !== 'number') {
    if (!organizationsToNormalize[organizationLeadTodoRule.organization.idOrganization]) {
      organizationsToNormalize[organizationLeadTodoRule.organization.idOrganization] = {
        ...organizationLeadTodoRule.organization,
        organizationLeadTodoRules: []
      };
    }
    (
      organizationsToNormalize[organizationLeadTodoRule.organization.idOrganization]
        .organizationLeadTodoRules as number[]
    ).push(organizationLeadTodoRule.idOrganizationLeadTodoRule);
    relations['organization'] = organizationLeadTodoRule.organization.idOrganization;
  }
}

function organizationLeadTodoRuleNormalizeOrganizationSaleCategory(
  organizationLeadTodoRule: OrganizationLeadTodoRuleEntityState,
  organizationSaleCategoriesToNormalize: { [id: number]: OrganizationSaleCategoryEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationLeadTodoRule.organizationSaleCategory &&
    typeof organizationLeadTodoRule.organizationSaleCategory !== 'number'
  ) {
    if (
      !organizationSaleCategoriesToNormalize[
        organizationLeadTodoRule.organizationSaleCategory.idOrganizationSaleCategory
      ]
    ) {
      organizationSaleCategoriesToNormalize[
        organizationLeadTodoRule.organizationSaleCategory.idOrganizationSaleCategory
      ] = { ...organizationLeadTodoRule.organizationSaleCategory, organizationLeadTodoRules: [] };
    }
    (
      organizationSaleCategoriesToNormalize[
        organizationLeadTodoRule.organizationSaleCategory.idOrganizationSaleCategory
      ].organizationLeadTodoRules as number[]
    ).push(organizationLeadTodoRule.idOrganizationLeadTodoRule);
    relations['organizationSaleCategory'] =
      organizationLeadTodoRule.organizationSaleCategory.idOrganizationSaleCategory;
  }
}

function organizationLeadTodoRuleNormalizeSaleCategoryFamily(
  organizationLeadTodoRule: OrganizationLeadTodoRuleEntityState,
  familySaleCategoriesToNormalize: { [id: number]: SaleCategoryFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationLeadTodoRule.saleCategoryFamily && typeof organizationLeadTodoRule.saleCategoryFamily !== 'number') {
    if (!familySaleCategoriesToNormalize[organizationLeadTodoRule.saleCategoryFamily.idSaleCategoryFamily]) {
      familySaleCategoriesToNormalize[organizationLeadTodoRule.saleCategoryFamily.idSaleCategoryFamily] = {
        ...organizationLeadTodoRule.saleCategoryFamily,
        organizationLeadTodoRules: []
      };
    }
    (
      familySaleCategoriesToNormalize[organizationLeadTodoRule.saleCategoryFamily.idSaleCategoryFamily]
        .organizationLeadTodoRules as number[]
    ).push(organizationLeadTodoRule.idOrganizationLeadTodoRule);
    relations['saleCategoryFamily'] = organizationLeadTodoRule.saleCategoryFamily.idSaleCategoryFamily;
  }
}

function organizationLeadTodoRuleNormalizeCompany(
  organizationLeadTodoRule: OrganizationLeadTodoRuleEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationLeadTodoRule.company && typeof organizationLeadTodoRule.company !== 'number') {
    if (!companiesToNormalize[organizationLeadTodoRule.company.idCompany]) {
      companiesToNormalize[organizationLeadTodoRule.company.idCompany] = {
        ...organizationLeadTodoRule.company,
        organizationLeadTodoRules: []
      };
    }
    (companiesToNormalize[organizationLeadTodoRule.company.idCompany].organizationLeadTodoRules as number[]).push(
      organizationLeadTodoRule.idOrganizationLeadTodoRule
    );
    relations['company'] = organizationLeadTodoRule.company.idCompany;
  }
}

function organizationLeadTodoRuleNormalizeCompanyStratalotType(
  organizationLeadTodoRule: OrganizationLeadTodoRuleEntityState,
  companyStratalotTypesToNormalize: { [id: number]: CompanyStratalotTypeEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationLeadTodoRule.companyStratalotType &&
    typeof organizationLeadTodoRule.companyStratalotType !== 'number'
  ) {
    if (!companyStratalotTypesToNormalize[organizationLeadTodoRule.companyStratalotType.idCompanyStratalotType]) {
      companyStratalotTypesToNormalize[organizationLeadTodoRule.companyStratalotType.idCompanyStratalotType] = {
        ...organizationLeadTodoRule.companyStratalotType,
        organizationLeadTodoRules: []
      };
    }
    (
      companyStratalotTypesToNormalize[organizationLeadTodoRule.companyStratalotType.idCompanyStratalotType]
        .organizationLeadTodoRules as number[]
    ).push(organizationLeadTodoRule.idOrganizationLeadTodoRule);
    relations['companyStratalotType'] = organizationLeadTodoRule.companyStratalotType.idCompanyStratalotType;
  }
}

function organizationLeadTodoRuleNormalizeStratalotType(
  organizationLeadTodoRule: OrganizationLeadTodoRuleEntityState,
  stratalotTypesToNormalize: { [id: number]: StratalotTypeEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationLeadTodoRule.stratalotType && typeof organizationLeadTodoRule.stratalotType !== 'number') {
    if (!stratalotTypesToNormalize[organizationLeadTodoRule.stratalotType.idStratalotType]) {
      stratalotTypesToNormalize[organizationLeadTodoRule.stratalotType.idStratalotType] = {
        ...organizationLeadTodoRule.stratalotType,
        organizationLeadTodoRules: []
      };
    }
    (
      stratalotTypesToNormalize[organizationLeadTodoRule.stratalotType.idStratalotType]
        .organizationLeadTodoRules as number[]
    ).push(organizationLeadTodoRule.idOrganizationLeadTodoRule);
    relations['stratalotType'] = organizationLeadTodoRule.stratalotType.idStratalotType;
  }
}

/**
 *  OrganizationLeadTodoAppliedRule
 */
export function getActionsToNormalizeOrganizationLeadTodoAppliedRule(
  organizationLeadTodoAppliedRules: OrganizationLeadTodoAppliedRuleEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationLeadTodosToNormalize: { [id: number]: OrganizationLeadTodoEntityState } = {};
  const organizationLeadTodoRulesToNormalize: { [id: number]: OrganizationLeadTodoRuleEntityState } = {};

  const normalizedOrganizationLeadTodoAppliedRules = organizationLeadTodoAppliedRules.map(
    (organizationLeadTodoAppliedRule: OrganizationLeadTodoAppliedRuleEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationLeadTodoAppliedRuleNormalizeOrganizationLeadTodo(
        organizationLeadTodoAppliedRule,
        organizationLeadTodosToNormalize,
        relations
      );
      organizationLeadTodoAppliedRuleNormalizeOrganizationLeadTodoRule(
        organizationLeadTodoAppliedRule,
        organizationLeadTodoRulesToNormalize,
        relations
      );

      return {
        ...organizationLeadTodoAppliedRule,
        ...relations
      };
    }
  );
  const organizationLeadTodosToNormalizeArray = Object.values(organizationLeadTodosToNormalize);
  if (organizationLeadTodosToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationLeadTodo(organizationLeadTodosToNormalizeArray, actionType));
  }
  const organizationLeadTodoRulesToNormalizeArray = Object.values(organizationLeadTodoRulesToNormalize);
  if (organizationLeadTodoRulesToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationLeadTodoRule(organizationLeadTodoRulesToNormalizeArray, actionType)
    );
  }

  const organizationLeadTodoAppliedRuleActionCreator =
    organizationLeadTodoAppliedRuleActions.upsertManyOrganizationLeadTodoAppliedRulesSuccess;
  actions.push(
    organizationLeadTodoAppliedRuleActionCreator({
      organizationLeadTodoAppliedRules: normalizedOrganizationLeadTodoAppliedRules
    })
  );

  return actions;
}

function organizationLeadTodoAppliedRuleNormalizeOrganizationLeadTodo(
  organizationLeadTodoAppliedRule: OrganizationLeadTodoAppliedRuleEntityState,
  organizationLeadTodosToNormalize: { [id: number]: OrganizationLeadTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationLeadTodoAppliedRule.organizationLeadTodo &&
    typeof organizationLeadTodoAppliedRule.organizationLeadTodo !== 'number'
  ) {
    if (
      !organizationLeadTodosToNormalize[organizationLeadTodoAppliedRule.organizationLeadTodo.idOrganizationLeadTodo]
    ) {
      organizationLeadTodosToNormalize[organizationLeadTodoAppliedRule.organizationLeadTodo.idOrganizationLeadTodo] = {
        ...organizationLeadTodoAppliedRule.organizationLeadTodo,
        organizationLeadTodoAppliedRules: []
      };
    }
    (
      organizationLeadTodosToNormalize[organizationLeadTodoAppliedRule.organizationLeadTodo.idOrganizationLeadTodo]
        .organizationLeadTodoAppliedRules as number[]
    ).push(organizationLeadTodoAppliedRule.idOrganizationLeadTodoAppliedRule);
    relations['organizationLeadTodo'] = organizationLeadTodoAppliedRule.organizationLeadTodo.idOrganizationLeadTodo;
  }
}

function organizationLeadTodoAppliedRuleNormalizeOrganizationLeadTodoRule(
  organizationLeadTodoAppliedRule: OrganizationLeadTodoAppliedRuleEntityState,
  organizationLeadTodoRulesToNormalize: { [id: number]: OrganizationLeadTodoRuleEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationLeadTodoAppliedRule.organizationLeadTodoRule &&
    typeof organizationLeadTodoAppliedRule.organizationLeadTodoRule !== 'number'
  ) {
    if (
      !organizationLeadTodoRulesToNormalize[
        organizationLeadTodoAppliedRule.organizationLeadTodoRule.idOrganizationLeadTodoRule
      ]
    ) {
      organizationLeadTodoRulesToNormalize[
        organizationLeadTodoAppliedRule.organizationLeadTodoRule.idOrganizationLeadTodoRule
      ] = { ...organizationLeadTodoAppliedRule.organizationLeadTodoRule, organizationLeadTodoAppliedRules: [] };
    }
    (
      organizationLeadTodoRulesToNormalize[
        organizationLeadTodoAppliedRule.organizationLeadTodoRule.idOrganizationLeadTodoRule
      ].organizationLeadTodoAppliedRules as number[]
    ).push(organizationLeadTodoAppliedRule.idOrganizationLeadTodoAppliedRule);
    relations['organizationLeadTodoRule'] =
      organizationLeadTodoAppliedRule.organizationLeadTodoRule.idOrganizationLeadTodoRule;
  }
}

/**
 *  CompanyMedia
 */
export function getActionsToNormalizeCompanyMedia(
  companyMedias: CompanyMediaEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const campaignMediasToNormalize: CampaignMediaEntityState[] = [];

  const normalizedCompanyMedias = companyMedias.map((companyMedia: CompanyMediaEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    companyMediaNormalizeCampaignMedia(companyMedia, campaignMediasToNormalize, relations);
    companyMediaNormalizeCompany(companyMedia, companiesToNormalize, relations);

    return {
      ...companyMedia,
      ...relations
    };
  });
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  if (campaignMediasToNormalize.length) {
    actions.push(...getActionsToNormalizeCampaignMedia(campaignMediasToNormalize, actionType));
  }

  const companyMediaActionCreator = companyMediaActions.upsertManyCompanyMediasSuccess;
  actions.push(companyMediaActionCreator({ companyMedias: normalizedCompanyMedias }));

  return actions;
}

function companyMediaNormalizeCampaignMedia(
  companyMedia: CompanyMediaEntityState,
  campaignMediasToNormalize: CampaignMediaEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (companyMedia.campaignMedias?.length && typeof companyMedia.campaignMedias[0] !== 'number') {
    const idsCampaignMedias: number[] = [];
    campaignMediasToNormalize.push(
      ...(companyMedia.campaignMedias as CampaignMedia[]).map((campaignMedia: CampaignMedia) => {
        idsCampaignMedias.push(campaignMedia.idCampaignMedia);
        return { ...campaignMedia, companyMedia: companyMedia.idCompanyMedia };
      })
    );
    relations['campaignMedias'] = idsCampaignMedias;
  }
}

function companyMediaNormalizeCompany(
  companyMedia: CompanyMediaEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyMedia.company && typeof companyMedia.company !== 'number') {
    if (!companiesToNormalize[companyMedia.company.idCompany]) {
      companiesToNormalize[companyMedia.company.idCompany] = { ...companyMedia.company, companyMedias: [] };
    }
    (companiesToNormalize[companyMedia.company.idCompany].companyMedias as number[]).push(companyMedia.idCompanyMedia);
    relations['company'] = companyMedia.company.idCompany;
  }
}

/**
 *  OrganizationProfil
 */
export function getActionsToNormalizeOrganizationProfil(
  organizationProfils: OrganizationProfilEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const companyPriceLabelsToNormalize: { [id: number]: CompanyPriceLabelEntityState } = {};

  const normalizedOrganizationProfils = organizationProfils.map((organizationProfil: OrganizationProfilEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    organizationProfilNormalizeOrganization(organizationProfil, organizationsToNormalize, relations);
    organizationProfilNormalizeCompanyPriceLabel(organizationProfil, companyPriceLabelsToNormalize, relations);

    return {
      ...organizationProfil,
      ...relations
    };
  });
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const companyPriceLabelsToNormalizeArray = Object.values(companyPriceLabelsToNormalize);
  if (companyPriceLabelsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyPriceLabel(companyPriceLabelsToNormalizeArray, actionType));
  }

  const organizationProfilActionCreator = organizationProfilActions.upsertManyOrganizationProfilsSuccess;
  actions.push(organizationProfilActionCreator({ organizationProfils: normalizedOrganizationProfils }));

  return actions;
}

function organizationProfilNormalizeOrganization(
  organizationProfil: OrganizationProfilEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationProfil.organization && typeof organizationProfil.organization !== 'number') {
    if (!organizationsToNormalize[organizationProfil.organization.idOrganization]) {
      organizationsToNormalize[organizationProfil.organization.idOrganization] = {
        ...organizationProfil.organization,
        organizationProfils: []
      };
    }
    (organizationsToNormalize[organizationProfil.organization.idOrganization].organizationProfils as number[]).push(
      organizationProfil.idOrganizationProfil
    );
    relations['organization'] = organizationProfil.organization.idOrganization;
  }
}

function organizationProfilNormalizeCompanyPriceLabel(
  organizationProfil: OrganizationProfilEntityState,
  companyPriceLabelsToNormalize: { [id: number]: CompanyPriceLabelEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationProfil.companyPriceLabel && typeof organizationProfil.companyPriceLabel !== 'number') {
    if (!companyPriceLabelsToNormalize[organizationProfil.companyPriceLabel.idCompanyPriceLabel]) {
      companyPriceLabelsToNormalize[organizationProfil.companyPriceLabel.idCompanyPriceLabel] = {
        ...organizationProfil.companyPriceLabel,
        organizationProfils: []
      };
    }
    (
      companyPriceLabelsToNormalize[organizationProfil.companyPriceLabel.idCompanyPriceLabel]
        .organizationProfils as number[]
    ).push(organizationProfil.idOrganizationProfil);
    relations['companyPriceLabel'] = organizationProfil.companyPriceLabel.idCompanyPriceLabel;
  }
}

/**
 *  CompanyCommunication
 */
export function getActionsToNormalizeCompanyCommunication(
  companyCommunications: CompanyCommunicationEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const companyCommunicationRecipientsToNormalize: { [id: number]: CompanyCommunicationRecipientEntityState } = {};
  const companyCommunicationMediasToNormalize: { [id: number]: CompanyCommunicationMediaEntityState } = {};
  const organizationResidenceTodosToNormalize: { [id: number]: OrganizationResidenceTodoEntityState } = {};
  const companyCommunicationResidenceTodosToNormalize: CompanyCommunicationResidenceTodoEntityState[] = [];
  const organizationStratalotTodosToNormalize: { [id: number]: OrganizationStratalotTodoEntityState } = {};
  const companyCommunicationStratalotTodosToNormalize: CompanyCommunicationStratalotTodoEntityState[] = [];
  const organizationLeadTodosToNormalize: { [id: number]: OrganizationLeadTodoEntityState } = {};
  const companyCommunicationLeadTodosToNormalize: CompanyCommunicationLeadTodoEntityState[] = [];
  const generatedDocumentsToNormalize: { [id: number]: GeneratedDocumentEntityState } = {};
  const companyCommunicationGeneratedDocumentsToNormalize: CompanyCommunicationGeneratedDocumentEntityState[] = [];
  const companyCommunicationFilesToNormalize: CompanyCommunicationFileEntityState[] = [];

  const normalizedCompanyCommunications = companyCommunications.map(
    (companyCommunication: CompanyCommunicationEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      companyCommunicationNormalizeOrganizationResidenceTodo(
        companyCommunication,
        organizationResidenceTodosToNormalize,
        relations
      );
      companyCommunicationNormalizeCompanyCommunicationResidenceTodo(
        companyCommunication,
        companyCommunicationResidenceTodosToNormalize,
        relations
      );
      companyCommunicationNormalizeOrganizationStratalotTodo(
        companyCommunication,
        organizationStratalotTodosToNormalize,
        relations
      );
      companyCommunicationNormalizeCompanyCommunicationStratalotTodo(
        companyCommunication,
        companyCommunicationStratalotTodosToNormalize,
        relations
      );
      companyCommunicationNormalizeOrganizationLeadTodo(
        companyCommunication,
        organizationLeadTodosToNormalize,
        relations
      );
      companyCommunicationNormalizeCompanyCommunicationLeadTodo(
        companyCommunication,
        companyCommunicationLeadTodosToNormalize,
        relations
      );
      companyCommunicationNormalizeGeneratedDocument(companyCommunication, generatedDocumentsToNormalize, relations);
      companyCommunicationNormalizeCompanyCommunicationGeneratedDocument(
        companyCommunication,
        companyCommunicationGeneratedDocumentsToNormalize,
        relations
      );
      companyCommunicationNormalizeCompanyCommunicationFile(
        companyCommunication,
        companyCommunicationFilesToNormalize,
        relations
      );
      companyCommunicationNormalizeCompany(companyCommunication, companiesToNormalize, relations);
      companyCommunicationNormalizeCompanyCommunicationRecipient(
        companyCommunication,
        companyCommunicationRecipientsToNormalize,
        relations
      );
      companyCommunicationNormalizeCompanyCommunicationMedia(
        companyCommunication,
        companyCommunicationMediasToNormalize,
        relations
      );

      return {
        ...companyCommunication,
        ...relations
      };
    }
  );
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  const companyCommunicationRecipientsToNormalizeArray = Object.values(companyCommunicationRecipientsToNormalize);
  if (companyCommunicationRecipientsToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationRecipient(companyCommunicationRecipientsToNormalizeArray, actionType)
    );
  }
  const companyCommunicationMediasToNormalizeArray = Object.values(companyCommunicationMediasToNormalize);
  if (companyCommunicationMediasToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationMedia(companyCommunicationMediasToNormalizeArray, actionType)
    );
  }
  const organizationResidenceTodosToNormalizeArray = Object.values(organizationResidenceTodosToNormalize);
  if (organizationResidenceTodosToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceTodo(organizationResidenceTodosToNormalizeArray, actionType)
    );
  }
  if (companyCommunicationResidenceTodosToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationResidenceTodo(
        companyCommunicationResidenceTodosToNormalize,
        actionType
      )
    );
  }
  const organizationStratalotTodosToNormalizeArray = Object.values(organizationStratalotTodosToNormalize);
  if (organizationStratalotTodosToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodo(organizationStratalotTodosToNormalizeArray, actionType)
    );
  }
  if (companyCommunicationStratalotTodosToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationStratalotTodo(
        companyCommunicationStratalotTodosToNormalize,
        actionType
      )
    );
  }
  const organizationLeadTodosToNormalizeArray = Object.values(organizationLeadTodosToNormalize);
  if (organizationLeadTodosToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationLeadTodo(organizationLeadTodosToNormalizeArray, actionType));
  }
  if (companyCommunicationLeadTodosToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationLeadTodo(companyCommunicationLeadTodosToNormalize, actionType)
    );
  }
  const generatedDocumentsToNormalizeArray = Object.values(generatedDocumentsToNormalize);
  if (generatedDocumentsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeGeneratedDocument(generatedDocumentsToNormalizeArray, actionType));
  }
  if (companyCommunicationGeneratedDocumentsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationGeneratedDocument(
        companyCommunicationGeneratedDocumentsToNormalize,
        actionType
      )
    );
  }
  if (companyCommunicationFilesToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyCommunicationFile(companyCommunicationFilesToNormalize, actionType));
  }

  const companyCommunicationActionCreator = companyCommunicationActions.upsertManyCompanyCommunicationsSuccess;
  actions.push(companyCommunicationActionCreator({ companyCommunications: normalizedCompanyCommunications }));

  return actions;
}

function companyCommunicationNormalizeOrganizationResidenceTodo(
  companyCommunication: CompanyCommunicationEntityState,
  organizationResidenceTodosToNormalize: { [id: number]: OrganizationResidenceTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunication.organizationResidenceTodos?.length &&
    typeof companyCommunication.organizationResidenceTodos[0] !== 'number'
  ) {
    const idsOrganizationResidenceTodos: number[] = (
      companyCommunication.organizationResidenceTodos as OrganizationResidenceTodo[]
    ).map((organizationResidenceTodo: OrganizationResidenceTodo) => {
      if (!organizationResidenceTodosToNormalize[organizationResidenceTodo.idOrganizationResidenceTodo]) {
        organizationResidenceTodosToNormalize[organizationResidenceTodo.idOrganizationResidenceTodo] = {
          ...organizationResidenceTodo,
          companyCommunications: []
        };
      }
      (
        organizationResidenceTodosToNormalize[organizationResidenceTodo.idOrganizationResidenceTodo]
          .companyCommunications as number[]
      ).push(companyCommunication.idCompanyCommunication);
      return organizationResidenceTodo.idOrganizationResidenceTodo;
    });
    relations['organizationResidenceTodos'] = idsOrganizationResidenceTodos;
  }
}

function companyCommunicationNormalizeCompanyCommunicationResidenceTodo(
  companyCommunication: CompanyCommunicationEntityState,
  companyCommunicationResidenceTodosToNormalize: CompanyCommunicationResidenceTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunication.companyCommunicationResidenceTodos?.length &&
    typeof companyCommunication.companyCommunicationResidenceTodos[0] !== 'number'
  ) {
    const idsCompanyCommunicationResidenceTodos: number[] = [];
    companyCommunicationResidenceTodosToNormalize.push(
      ...(companyCommunication.companyCommunicationResidenceTodos as CompanyCommunicationResidenceTodo[]).map(
        (companyCommunicationResidenceTodo: CompanyCommunicationResidenceTodo) => {
          idsCompanyCommunicationResidenceTodos.push(
            companyCommunicationResidenceTodo.idCompanyCommunicationResidenceTodo
          );
          return {
            ...companyCommunicationResidenceTodo,
            companyCommunication: companyCommunication.idCompanyCommunication
          };
        }
      )
    );
    relations['companyCommunicationResidenceTodos'] = idsCompanyCommunicationResidenceTodos;
  }
}

function companyCommunicationNormalizeOrganizationStratalotTodo(
  companyCommunication: CompanyCommunicationEntityState,
  organizationStratalotTodosToNormalize: { [id: number]: OrganizationStratalotTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunication.organizationStratalotTodos?.length &&
    typeof companyCommunication.organizationStratalotTodos[0] !== 'number'
  ) {
    const idsOrganizationStratalotTodos: number[] = (
      companyCommunication.organizationStratalotTodos as OrganizationStratalotTodo[]
    ).map((organizationStratalotTodo: OrganizationStratalotTodo) => {
      if (!organizationStratalotTodosToNormalize[organizationStratalotTodo.idOrganizationStratalotTodo]) {
        organizationStratalotTodosToNormalize[organizationStratalotTodo.idOrganizationStratalotTodo] = {
          ...organizationStratalotTodo,
          companyCommunications: []
        };
      }
      (
        organizationStratalotTodosToNormalize[organizationStratalotTodo.idOrganizationStratalotTodo]
          .companyCommunications as number[]
      ).push(companyCommunication.idCompanyCommunication);
      return organizationStratalotTodo.idOrganizationStratalotTodo;
    });
    relations['organizationStratalotTodos'] = idsOrganizationStratalotTodos;
  }
}

function companyCommunicationNormalizeCompanyCommunicationStratalotTodo(
  companyCommunication: CompanyCommunicationEntityState,
  companyCommunicationStratalotTodosToNormalize: CompanyCommunicationStratalotTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunication.companyCommunicationStratalotTodos?.length &&
    typeof companyCommunication.companyCommunicationStratalotTodos[0] !== 'number'
  ) {
    const idsCompanyCommunicationStratalotTodos: number[] = [];
    companyCommunicationStratalotTodosToNormalize.push(
      ...(companyCommunication.companyCommunicationStratalotTodos as CompanyCommunicationStratalotTodo[]).map(
        (companyCommunicationStratalotTodo: CompanyCommunicationStratalotTodo) => {
          idsCompanyCommunicationStratalotTodos.push(
            companyCommunicationStratalotTodo.idCompanyCommunicationStratalotTodo
          );
          return {
            ...companyCommunicationStratalotTodo,
            companyCommunication: companyCommunication.idCompanyCommunication
          };
        }
      )
    );
    relations['companyCommunicationStratalotTodos'] = idsCompanyCommunicationStratalotTodos;
  }
}

function companyCommunicationNormalizeOrganizationLeadTodo(
  companyCommunication: CompanyCommunicationEntityState,
  organizationLeadTodosToNormalize: { [id: number]: OrganizationLeadTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunication.organizationLeadTodos?.length &&
    typeof companyCommunication.organizationLeadTodos[0] !== 'number'
  ) {
    const idsOrganizationLeadTodos: number[] = (
      companyCommunication.organizationLeadTodos as OrganizationLeadTodo[]
    ).map((organizationLeadTodo: OrganizationLeadTodo) => {
      if (!organizationLeadTodosToNormalize[organizationLeadTodo.idOrganizationLeadTodo]) {
        organizationLeadTodosToNormalize[organizationLeadTodo.idOrganizationLeadTodo] = {
          ...organizationLeadTodo,
          companyCommunications: []
        };
      }
      (
        organizationLeadTodosToNormalize[organizationLeadTodo.idOrganizationLeadTodo].companyCommunications as number[]
      ).push(companyCommunication.idCompanyCommunication);
      return organizationLeadTodo.idOrganizationLeadTodo;
    });
    relations['organizationLeadTodos'] = idsOrganizationLeadTodos;
  }
}

function companyCommunicationNormalizeCompanyCommunicationLeadTodo(
  companyCommunication: CompanyCommunicationEntityState,
  companyCommunicationLeadTodosToNormalize: CompanyCommunicationLeadTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunication.companyCommunicationLeadTodos?.length &&
    typeof companyCommunication.companyCommunicationLeadTodos[0] !== 'number'
  ) {
    const idsCompanyCommunicationLeadTodos: number[] = [];
    companyCommunicationLeadTodosToNormalize.push(
      ...(companyCommunication.companyCommunicationLeadTodos as CompanyCommunicationLeadTodo[]).map(
        (companyCommunicationLeadTodo: CompanyCommunicationLeadTodo) => {
          idsCompanyCommunicationLeadTodos.push(companyCommunicationLeadTodo.idCompanyCommunicationLeadTodo);
          return { ...companyCommunicationLeadTodo, companyCommunication: companyCommunication.idCompanyCommunication };
        }
      )
    );
    relations['companyCommunicationLeadTodos'] = idsCompanyCommunicationLeadTodos;
  }
}

function companyCommunicationNormalizeGeneratedDocument(
  companyCommunication: CompanyCommunicationEntityState,
  generatedDocumentsToNormalize: { [id: number]: GeneratedDocumentEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunication.generatedDocuments?.length &&
    typeof companyCommunication.generatedDocuments[0] !== 'number'
  ) {
    const idsGeneratedDocuments: number[] = (companyCommunication.generatedDocuments as GeneratedDocument[]).map(
      (generatedDocument: GeneratedDocument) => {
        if (!generatedDocumentsToNormalize[generatedDocument.idGeneratedDocument]) {
          generatedDocumentsToNormalize[generatedDocument.idGeneratedDocument] = {
            ...generatedDocument,
            companyCommunications: []
          };
        }
        (generatedDocumentsToNormalize[generatedDocument.idGeneratedDocument].companyCommunications as number[]).push(
          companyCommunication.idCompanyCommunication
        );
        return generatedDocument.idGeneratedDocument;
      }
    );
    relations['generatedDocuments'] = idsGeneratedDocuments;
  }
}

function companyCommunicationNormalizeCompanyCommunicationGeneratedDocument(
  companyCommunication: CompanyCommunicationEntityState,
  companyCommunicationGeneratedDocumentsToNormalize: CompanyCommunicationGeneratedDocumentEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunication.companyCommunicationGeneratedDocuments?.length &&
    typeof companyCommunication.companyCommunicationGeneratedDocuments[0] !== 'number'
  ) {
    const idsCompanyCommunicationGeneratedDocuments: number[] = [];
    companyCommunicationGeneratedDocumentsToNormalize.push(
      ...(companyCommunication.companyCommunicationGeneratedDocuments as CompanyCommunicationGeneratedDocument[]).map(
        (companyCommunicationGeneratedDocument: CompanyCommunicationGeneratedDocument) => {
          idsCompanyCommunicationGeneratedDocuments.push(
            companyCommunicationGeneratedDocument.idCompanyCommunicationGeneratedDocument
          );
          return {
            ...companyCommunicationGeneratedDocument,
            companyCommunication: companyCommunication.idCompanyCommunication
          };
        }
      )
    );
    relations['companyCommunicationGeneratedDocuments'] = idsCompanyCommunicationGeneratedDocuments;
  }
}

function companyCommunicationNormalizeCompanyCommunicationFile(
  companyCommunication: CompanyCommunicationEntityState,
  companyCommunicationFilesToNormalize: CompanyCommunicationFileEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunication.companyCommunicationFiles?.length &&
    typeof companyCommunication.companyCommunicationFiles[0] !== 'number'
  ) {
    const idsCompanyCommunicationFiles: number[] = [];
    companyCommunicationFilesToNormalize.push(
      ...(companyCommunication.companyCommunicationFiles as CompanyCommunicationFile[]).map(
        (companyCommunicationFile: CompanyCommunicationFile) => {
          idsCompanyCommunicationFiles.push(companyCommunicationFile.idCompanyCommunicationFile);
          return { ...companyCommunicationFile, companyCommunication: companyCommunication.idCompanyCommunication };
        }
      )
    );
    relations['companyCommunicationFiles'] = idsCompanyCommunicationFiles;
  }
}

function companyCommunicationNormalizeCompany(
  companyCommunication: CompanyCommunicationEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyCommunication.company && typeof companyCommunication.company !== 'number') {
    if (!companiesToNormalize[companyCommunication.company.idCompany]) {
      companiesToNormalize[companyCommunication.company.idCompany] = {
        ...companyCommunication.company,
        companyCommunications: []
      };
    }
    (companiesToNormalize[companyCommunication.company.idCompany].companyCommunications as number[]).push(
      companyCommunication.idCompanyCommunication
    );
    relations['company'] = companyCommunication.company.idCompany;
  }
}

function companyCommunicationNormalizeCompanyCommunicationRecipient(
  companyCommunication: CompanyCommunicationEntityState,
  companyCommunicationRecipientsToNormalize: { [id: number]: CompanyCommunicationRecipientEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunication.companyCommunicationRecipient &&
    typeof companyCommunication.companyCommunicationRecipient !== 'number'
  ) {
    if (
      !companyCommunicationRecipientsToNormalize[
        companyCommunication.companyCommunicationRecipient.idCompanyCommunicationRecipient
      ]
    ) {
      companyCommunicationRecipientsToNormalize[
        companyCommunication.companyCommunicationRecipient.idCompanyCommunicationRecipient
      ] = {
        ...companyCommunication.companyCommunicationRecipient,
        companyCommunication: companyCommunication.idCompanyCommunication
      };
    }
    companyCommunicationRecipientsToNormalize[
      companyCommunication.companyCommunicationRecipient.idCompanyCommunicationRecipient
    ].companyCommunication = companyCommunication.idCompanyCommunication;
    relations['companyCommunicationRecipient'] = companyCommunication.idCompanyCommunicationRecipient;
  }
}

function companyCommunicationNormalizeCompanyCommunicationMedia(
  companyCommunication: CompanyCommunicationEntityState,
  companyCommunicationMediasToNormalize: { [id: number]: CompanyCommunicationMediaEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunication.companyCommunicationMedia &&
    typeof companyCommunication.companyCommunicationMedia !== 'number'
  ) {
    if (
      !companyCommunicationMediasToNormalize[companyCommunication.companyCommunicationMedia.idCompanyCommunicationMedia]
    ) {
      companyCommunicationMediasToNormalize[
        companyCommunication.companyCommunicationMedia.idCompanyCommunicationMedia
      ] = {
        ...companyCommunication.companyCommunicationMedia,
        companyCommunication: companyCommunication.idCompanyCommunication
      };
    }
    companyCommunicationMediasToNormalize[
      companyCommunication.companyCommunicationMedia.idCompanyCommunicationMedia
    ].companyCommunication = companyCommunication.idCompanyCommunication;
    relations['companyCommunicationMedia'] = companyCommunication.idCompanyCommunicationMedia;
  }
}

/**
 *  CompanyCommunicationResidenceTodo
 */
export function getActionsToNormalizeCompanyCommunicationResidenceTodo(
  companyCommunicationResidenceTodos: CompanyCommunicationResidenceTodoEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState } = {};
  const organizationResidenceTodosToNormalize: { [id: number]: OrganizationResidenceTodoEntityState } = {};

  const normalizedCompanyCommunicationResidenceTodos = companyCommunicationResidenceTodos.map(
    (companyCommunicationResidenceTodo: CompanyCommunicationResidenceTodoEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      companyCommunicationResidenceTodoNormalizeCompanyCommunication(
        companyCommunicationResidenceTodo,
        companyCommunicationsToNormalize,
        relations
      );
      companyCommunicationResidenceTodoNormalizeOrganizationResidenceTodo(
        companyCommunicationResidenceTodo,
        organizationResidenceTodosToNormalize,
        relations
      );

      return {
        ...companyCommunicationResidenceTodo,
        ...relations
      };
    }
  );
  const companyCommunicationsToNormalizeArray = Object.values(companyCommunicationsToNormalize);
  if (companyCommunicationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyCommunication(companyCommunicationsToNormalizeArray, actionType));
  }
  const organizationResidenceTodosToNormalizeArray = Object.values(organizationResidenceTodosToNormalize);
  if (organizationResidenceTodosToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceTodo(organizationResidenceTodosToNormalizeArray, actionType)
    );
  }

  const companyCommunicationResidenceTodoActionCreator =
    companyCommunicationResidenceTodoActions.upsertManyCompanyCommunicationResidenceTodosSuccess;
  actions.push(
    companyCommunicationResidenceTodoActionCreator({
      companyCommunicationResidenceTodos: normalizedCompanyCommunicationResidenceTodos
    })
  );

  return actions;
}

function companyCommunicationResidenceTodoNormalizeCompanyCommunication(
  companyCommunicationResidenceTodo: CompanyCommunicationResidenceTodoEntityState,
  companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationResidenceTodo.companyCommunication &&
    typeof companyCommunicationResidenceTodo.companyCommunication !== 'number'
  ) {
    if (
      !companyCommunicationsToNormalize[companyCommunicationResidenceTodo.companyCommunication.idCompanyCommunication]
    ) {
      companyCommunicationsToNormalize[companyCommunicationResidenceTodo.companyCommunication.idCompanyCommunication] =
        { ...companyCommunicationResidenceTodo.companyCommunication, companyCommunicationResidenceTodos: [] };
    }
    (
      companyCommunicationsToNormalize[companyCommunicationResidenceTodo.companyCommunication.idCompanyCommunication]
        .companyCommunicationResidenceTodos as number[]
    ).push(companyCommunicationResidenceTodo.idCompanyCommunicationResidenceTodo);
    relations['companyCommunication'] = companyCommunicationResidenceTodo.companyCommunication.idCompanyCommunication;
  }
}

function companyCommunicationResidenceTodoNormalizeOrganizationResidenceTodo(
  companyCommunicationResidenceTodo: CompanyCommunicationResidenceTodoEntityState,
  organizationResidenceTodosToNormalize: { [id: number]: OrganizationResidenceTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationResidenceTodo.organizationResidenceTodo &&
    typeof companyCommunicationResidenceTodo.organizationResidenceTodo !== 'number'
  ) {
    if (
      !organizationResidenceTodosToNormalize[
        companyCommunicationResidenceTodo.organizationResidenceTodo.idOrganizationResidenceTodo
      ]
    ) {
      organizationResidenceTodosToNormalize[
        companyCommunicationResidenceTodo.organizationResidenceTodo.idOrganizationResidenceTodo
      ] = { ...companyCommunicationResidenceTodo.organizationResidenceTodo, companyCommunicationResidenceTodos: [] };
    }
    (
      organizationResidenceTodosToNormalize[
        companyCommunicationResidenceTodo.organizationResidenceTodo.idOrganizationResidenceTodo
      ].companyCommunicationResidenceTodos as number[]
    ).push(companyCommunicationResidenceTodo.idCompanyCommunicationResidenceTodo);
    relations['organizationResidenceTodo'] =
      companyCommunicationResidenceTodo.organizationResidenceTodo.idOrganizationResidenceTodo;
  }
}

/**
 *  CompanyCommunicationStratalotTodo
 */
export function getActionsToNormalizeCompanyCommunicationStratalotTodo(
  companyCommunicationStratalotTodos: CompanyCommunicationStratalotTodoEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState } = {};
  const organizationStratalotTodosToNormalize: { [id: number]: OrganizationStratalotTodoEntityState } = {};

  const normalizedCompanyCommunicationStratalotTodos = companyCommunicationStratalotTodos.map(
    (companyCommunicationStratalotTodo: CompanyCommunicationStratalotTodoEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      companyCommunicationStratalotTodoNormalizeCompanyCommunication(
        companyCommunicationStratalotTodo,
        companyCommunicationsToNormalize,
        relations
      );
      companyCommunicationStratalotTodoNormalizeOrganizationStratalotTodo(
        companyCommunicationStratalotTodo,
        organizationStratalotTodosToNormalize,
        relations
      );

      return {
        ...companyCommunicationStratalotTodo,
        ...relations
      };
    }
  );
  const companyCommunicationsToNormalizeArray = Object.values(companyCommunicationsToNormalize);
  if (companyCommunicationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyCommunication(companyCommunicationsToNormalizeArray, actionType));
  }
  const organizationStratalotTodosToNormalizeArray = Object.values(organizationStratalotTodosToNormalize);
  if (organizationStratalotTodosToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodo(organizationStratalotTodosToNormalizeArray, actionType)
    );
  }

  const companyCommunicationStratalotTodoActionCreator =
    companyCommunicationStratalotTodoActions.upsertManyCompanyCommunicationStratalotTodosSuccess;
  actions.push(
    companyCommunicationStratalotTodoActionCreator({
      companyCommunicationStratalotTodos: normalizedCompanyCommunicationStratalotTodos
    })
  );

  return actions;
}

function companyCommunicationStratalotTodoNormalizeCompanyCommunication(
  companyCommunicationStratalotTodo: CompanyCommunicationStratalotTodoEntityState,
  companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationStratalotTodo.companyCommunication &&
    typeof companyCommunicationStratalotTodo.companyCommunication !== 'number'
  ) {
    if (
      !companyCommunicationsToNormalize[companyCommunicationStratalotTodo.companyCommunication.idCompanyCommunication]
    ) {
      companyCommunicationsToNormalize[companyCommunicationStratalotTodo.companyCommunication.idCompanyCommunication] =
        { ...companyCommunicationStratalotTodo.companyCommunication, companyCommunicationStratalotTodos: [] };
    }
    (
      companyCommunicationsToNormalize[companyCommunicationStratalotTodo.companyCommunication.idCompanyCommunication]
        .companyCommunicationStratalotTodos as number[]
    ).push(companyCommunicationStratalotTodo.idCompanyCommunicationStratalotTodo);
    relations['companyCommunication'] = companyCommunicationStratalotTodo.companyCommunication.idCompanyCommunication;
  }
}

function companyCommunicationStratalotTodoNormalizeOrganizationStratalotTodo(
  companyCommunicationStratalotTodo: CompanyCommunicationStratalotTodoEntityState,
  organizationStratalotTodosToNormalize: { [id: number]: OrganizationStratalotTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationStratalotTodo.organizationStratalotTodo &&
    typeof companyCommunicationStratalotTodo.organizationStratalotTodo !== 'number'
  ) {
    if (
      !organizationStratalotTodosToNormalize[
        companyCommunicationStratalotTodo.organizationStratalotTodo.idOrganizationStratalotTodo
      ]
    ) {
      organizationStratalotTodosToNormalize[
        companyCommunicationStratalotTodo.organizationStratalotTodo.idOrganizationStratalotTodo
      ] = { ...companyCommunicationStratalotTodo.organizationStratalotTodo, companyCommunicationStratalotTodos: [] };
    }
    (
      organizationStratalotTodosToNormalize[
        companyCommunicationStratalotTodo.organizationStratalotTodo.idOrganizationStratalotTodo
      ].companyCommunicationStratalotTodos as number[]
    ).push(companyCommunicationStratalotTodo.idCompanyCommunicationStratalotTodo);
    relations['organizationStratalotTodo'] =
      companyCommunicationStratalotTodo.organizationStratalotTodo.idOrganizationStratalotTodo;
  }
}

/**
 *  CompanyCommunicationLeadTodo
 */
export function getActionsToNormalizeCompanyCommunicationLeadTodo(
  companyCommunicationLeadTodos: CompanyCommunicationLeadTodoEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState } = {};
  const organizationLeadTodosToNormalize: { [id: number]: OrganizationLeadTodoEntityState } = {};

  const normalizedCompanyCommunicationLeadTodos = companyCommunicationLeadTodos.map(
    (companyCommunicationLeadTodo: CompanyCommunicationLeadTodoEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      companyCommunicationLeadTodoNormalizeCompanyCommunication(
        companyCommunicationLeadTodo,
        companyCommunicationsToNormalize,
        relations
      );
      companyCommunicationLeadTodoNormalizeOrganizationLeadTodo(
        companyCommunicationLeadTodo,
        organizationLeadTodosToNormalize,
        relations
      );

      return {
        ...companyCommunicationLeadTodo,
        ...relations
      };
    }
  );
  const companyCommunicationsToNormalizeArray = Object.values(companyCommunicationsToNormalize);
  if (companyCommunicationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyCommunication(companyCommunicationsToNormalizeArray, actionType));
  }
  const organizationLeadTodosToNormalizeArray = Object.values(organizationLeadTodosToNormalize);
  if (organizationLeadTodosToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationLeadTodo(organizationLeadTodosToNormalizeArray, actionType));
  }

  const companyCommunicationLeadTodoActionCreator =
    companyCommunicationLeadTodoActions.upsertManyCompanyCommunicationLeadTodosSuccess;
  actions.push(
    companyCommunicationLeadTodoActionCreator({
      companyCommunicationLeadTodos: normalizedCompanyCommunicationLeadTodos
    })
  );

  return actions;
}

function companyCommunicationLeadTodoNormalizeCompanyCommunication(
  companyCommunicationLeadTodo: CompanyCommunicationLeadTodoEntityState,
  companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationLeadTodo.companyCommunication &&
    typeof companyCommunicationLeadTodo.companyCommunication !== 'number'
  ) {
    if (!companyCommunicationsToNormalize[companyCommunicationLeadTodo.companyCommunication.idCompanyCommunication]) {
      companyCommunicationsToNormalize[companyCommunicationLeadTodo.companyCommunication.idCompanyCommunication] = {
        ...companyCommunicationLeadTodo.companyCommunication,
        companyCommunicationLeadTodos: []
      };
    }
    (
      companyCommunicationsToNormalize[companyCommunicationLeadTodo.companyCommunication.idCompanyCommunication]
        .companyCommunicationLeadTodos as number[]
    ).push(companyCommunicationLeadTodo.idCompanyCommunicationLeadTodo);
    relations['companyCommunication'] = companyCommunicationLeadTodo.companyCommunication.idCompanyCommunication;
  }
}

function companyCommunicationLeadTodoNormalizeOrganizationLeadTodo(
  companyCommunicationLeadTodo: CompanyCommunicationLeadTodoEntityState,
  organizationLeadTodosToNormalize: { [id: number]: OrganizationLeadTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationLeadTodo.organizationLeadTodo &&
    typeof companyCommunicationLeadTodo.organizationLeadTodo !== 'number'
  ) {
    if (!organizationLeadTodosToNormalize[companyCommunicationLeadTodo.organizationLeadTodo.idOrganizationLeadTodo]) {
      organizationLeadTodosToNormalize[companyCommunicationLeadTodo.organizationLeadTodo.idOrganizationLeadTodo] = {
        ...companyCommunicationLeadTodo.organizationLeadTodo,
        companyCommunicationLeadTodos: []
      };
    }
    (
      organizationLeadTodosToNormalize[companyCommunicationLeadTodo.organizationLeadTodo.idOrganizationLeadTodo]
        .companyCommunicationLeadTodos as number[]
    ).push(companyCommunicationLeadTodo.idCompanyCommunicationLeadTodo);
    relations['organizationLeadTodo'] = companyCommunicationLeadTodo.organizationLeadTodo.idOrganizationLeadTodo;
  }
}

/**
 *  CompanyCommunicationFile
 */
export function getActionsToNormalizeCompanyCommunicationFile(
  companyCommunicationFiles: CompanyCommunicationFileEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState } = {};

  const normalizedCompanyCommunicationFiles = companyCommunicationFiles.map(
    (companyCommunicationFile: CompanyCommunicationFileEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      companyCommunicationFileNormalizeCompanyCommunication(
        companyCommunicationFile,
        companyCommunicationsToNormalize,
        relations
      );

      return {
        ...companyCommunicationFile,
        ...relations
      };
    }
  );
  const companyCommunicationsToNormalizeArray = Object.values(companyCommunicationsToNormalize);
  if (companyCommunicationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyCommunication(companyCommunicationsToNormalizeArray, actionType));
  }

  const companyCommunicationFileActionCreator =
    companyCommunicationFileActions.upsertManyCompanyCommunicationFilesSuccess;
  actions.push(
    companyCommunicationFileActionCreator({ companyCommunicationFiles: normalizedCompanyCommunicationFiles })
  );

  return actions;
}

function companyCommunicationFileNormalizeCompanyCommunication(
  companyCommunicationFile: CompanyCommunicationFileEntityState,
  companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationFile.companyCommunication &&
    typeof companyCommunicationFile.companyCommunication !== 'number'
  ) {
    if (!companyCommunicationsToNormalize[companyCommunicationFile.companyCommunication.idCompanyCommunication]) {
      companyCommunicationsToNormalize[companyCommunicationFile.companyCommunication.idCompanyCommunication] = {
        ...companyCommunicationFile.companyCommunication,
        companyCommunicationFiles: []
      };
    }
    (
      companyCommunicationsToNormalize[companyCommunicationFile.companyCommunication.idCompanyCommunication]
        .companyCommunicationFiles as number[]
    ).push(companyCommunicationFile.idCompanyCommunicationFile);
    relations['companyCommunication'] = companyCommunicationFile.companyCommunication.idCompanyCommunication;
  }
}

/**
 *  CompanyCommunicationMedia
 */
export function getActionsToNormalizeCompanyCommunicationMedia(
  companyCommunicationMedias: CompanyCommunicationMediaEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companyCommunicationsToNormalize: CompanyCommunicationEntityState[] = [];

  const normalizedCompanyCommunicationMedias = companyCommunicationMedias.map(
    (companyCommunicationMedia: CompanyCommunicationMediaEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      companyCommunicationMediaNormalizeCompanyCommunication(
        companyCommunicationMedia,
        companyCommunicationsToNormalize,
        relations
      );

      return {
        ...companyCommunicationMedia,
        ...relations
      };
    }
  );
  if (companyCommunicationsToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyCommunication(companyCommunicationsToNormalize, actionType));
  }

  const companyCommunicationMediaActionCreator =
    companyCommunicationMediaActions.upsertManyCompanyCommunicationMediasSuccess;
  actions.push(
    companyCommunicationMediaActionCreator({ companyCommunicationMedias: normalizedCompanyCommunicationMedias })
  );

  return actions;
}

function companyCommunicationMediaNormalizeCompanyCommunication(
  companyCommunicationMedia: CompanyCommunicationMediaEntityState,
  companyCommunicationsToNormalize: CompanyCommunicationEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationMedia.companyCommunication &&
    typeof companyCommunicationMedia.companyCommunication !== 'number'
  ) {
    companyCommunicationsToNormalize.push({
      ...companyCommunicationMedia.companyCommunication,
      companyCommunicationMedia: companyCommunicationMedia.idCompanyCommunicationMedia
    });
    relations['companyCommunication'] = companyCommunicationMedia.companyCommunication.idCompanyCommunication;
  }
}

/**
 *  CompanyCommunicationRecipient
 */
export function getActionsToNormalizeCompanyCommunicationRecipient(
  companyCommunicationRecipients: CompanyCommunicationRecipientEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companyCommunicationsToNormalize: CompanyCommunicationEntityState[] = [];
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const companyCommunicationRecipientUsersToNormalize: CompanyCommunicationRecipientUserEntityState[] = [];
  const organizationMetiersToNormalize: { [id: number]: OrganizationMetierEntityState } = {};
  const companyCommunicationRecipientMetiersToNormalize: CompanyCommunicationRecipientMetierEntityState[] = [];

  const normalizedCompanyCommunicationRecipients = companyCommunicationRecipients.map(
    (companyCommunicationRecipient: CompanyCommunicationRecipientEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      companyCommunicationRecipientNormalizeCompanyCommunication(
        companyCommunicationRecipient,
        companyCommunicationsToNormalize,
        relations
      );
      companyCommunicationRecipientNormalizeUser(companyCommunicationRecipient, usersToNormalize, relations);
      companyCommunicationRecipientNormalizeCompanyCommunicationRecipientUser(
        companyCommunicationRecipient,
        companyCommunicationRecipientUsersToNormalize,
        relations
      );
      companyCommunicationRecipientNormalizeOrganizationMetier(
        companyCommunicationRecipient,
        organizationMetiersToNormalize,
        relations
      );
      companyCommunicationRecipientNormalizeCompanyCommunicationRecipientMetier(
        companyCommunicationRecipient,
        companyCommunicationRecipientMetiersToNormalize,
        relations
      );

      return {
        ...companyCommunicationRecipient,
        ...relations
      };
    }
  );
  if (companyCommunicationsToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyCommunication(companyCommunicationsToNormalize, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  if (companyCommunicationRecipientUsersToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationRecipientUser(
        companyCommunicationRecipientUsersToNormalize,
        actionType
      )
    );
  }
  const organizationMetiersToNormalizeArray = Object.values(organizationMetiersToNormalize);
  if (organizationMetiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationMetier(organizationMetiersToNormalizeArray, actionType));
  }
  if (companyCommunicationRecipientMetiersToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationRecipientMetier(
        companyCommunicationRecipientMetiersToNormalize,
        actionType
      )
    );
  }

  const companyCommunicationRecipientActionCreator =
    companyCommunicationRecipientActions.upsertManyCompanyCommunicationRecipientsSuccess;
  actions.push(
    companyCommunicationRecipientActionCreator({
      companyCommunicationRecipients: normalizedCompanyCommunicationRecipients
    })
  );

  return actions;
}

function companyCommunicationRecipientNormalizeCompanyCommunication(
  companyCommunicationRecipient: CompanyCommunicationRecipientEntityState,
  companyCommunicationsToNormalize: CompanyCommunicationEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationRecipient.companyCommunication &&
    typeof companyCommunicationRecipient.companyCommunication !== 'number'
  ) {
    companyCommunicationsToNormalize.push({
      ...companyCommunicationRecipient.companyCommunication,
      companyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient
    });
    relations['companyCommunication'] = companyCommunicationRecipient.companyCommunication.idCompanyCommunication;
  }
}

function companyCommunicationRecipientNormalizeUser(
  companyCommunicationRecipient: CompanyCommunicationRecipientEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyCommunicationRecipient.users?.length && typeof companyCommunicationRecipient.users[0] !== 'number') {
    const idsUsers: number[] = (companyCommunicationRecipient.users as User[]).map((user: User) => {
      if (!usersToNormalize[user.idUser]) {
        usersToNormalize[user.idUser] = { ...user, companyCommunicationRecipients: [] };
      }
      (usersToNormalize[user.idUser].companyCommunicationRecipients as number[]).push(
        companyCommunicationRecipient.idCompanyCommunicationRecipient
      );
      return user.idUser;
    });
    relations['users'] = idsUsers;
  }
}

function companyCommunicationRecipientNormalizeCompanyCommunicationRecipientUser(
  companyCommunicationRecipient: CompanyCommunicationRecipientEntityState,
  companyCommunicationRecipientUsersToNormalize: CompanyCommunicationRecipientUserEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationRecipient.companyCommunicationRecipientUsers?.length &&
    typeof companyCommunicationRecipient.companyCommunicationRecipientUsers[0] !== 'number'
  ) {
    const idsCompanyCommunicationRecipientUsers: number[] = [];
    companyCommunicationRecipientUsersToNormalize.push(
      ...(companyCommunicationRecipient.companyCommunicationRecipientUsers as CompanyCommunicationRecipientUser[]).map(
        (companyCommunicationRecipientUser: CompanyCommunicationRecipientUser) => {
          idsCompanyCommunicationRecipientUsers.push(
            companyCommunicationRecipientUser.idCompanyCommunicationRecipientUser
          );
          return {
            ...companyCommunicationRecipientUser,
            companyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient
          };
        }
      )
    );
    relations['companyCommunicationRecipientUsers'] = idsCompanyCommunicationRecipientUsers;
  }
}

function companyCommunicationRecipientNormalizeOrganizationMetier(
  companyCommunicationRecipient: CompanyCommunicationRecipientEntityState,
  organizationMetiersToNormalize: { [id: number]: OrganizationMetierEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationRecipient.organizationMetiers?.length &&
    typeof companyCommunicationRecipient.organizationMetiers[0] !== 'number'
  ) {
    const idsOrganizationMetiers: number[] = (
      companyCommunicationRecipient.organizationMetiers as OrganizationMetier[]
    ).map((organizationMetier: OrganizationMetier) => {
      if (!organizationMetiersToNormalize[organizationMetier.idOrganizationMetier]) {
        organizationMetiersToNormalize[organizationMetier.idOrganizationMetier] = {
          ...organizationMetier,
          companyCommunicationRecipients: []
        };
      }
      (
        organizationMetiersToNormalize[organizationMetier.idOrganizationMetier]
          .companyCommunicationRecipients as number[]
      ).push(companyCommunicationRecipient.idCompanyCommunicationRecipient);
      return organizationMetier.idOrganizationMetier;
    });
    relations['organizationMetiers'] = idsOrganizationMetiers;
  }
}

function companyCommunicationRecipientNormalizeCompanyCommunicationRecipientMetier(
  companyCommunicationRecipient: CompanyCommunicationRecipientEntityState,
  companyCommunicationRecipientMetiersToNormalize: CompanyCommunicationRecipientMetierEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationRecipient.companyCommunicationRecipientMetiers?.length &&
    typeof companyCommunicationRecipient.companyCommunicationRecipientMetiers[0] !== 'number'
  ) {
    const idsCompanyCommunicationRecipientMetiers: number[] = [];
    companyCommunicationRecipientMetiersToNormalize.push(
      ...(
        companyCommunicationRecipient.companyCommunicationRecipientMetiers as CompanyCommunicationRecipientMetier[]
      ).map((companyCommunicationRecipientMetier: CompanyCommunicationRecipientMetier) => {
        idsCompanyCommunicationRecipientMetiers.push(
          companyCommunicationRecipientMetier.idCompanyCommunicationRecipientMetier
        );
        return {
          ...companyCommunicationRecipientMetier,
          companyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient
        };
      })
    );
    relations['companyCommunicationRecipientMetiers'] = idsCompanyCommunicationRecipientMetiers;
  }
}

/**
 *  CompanyCommunicationRecipientMetier
 */
export function getActionsToNormalizeCompanyCommunicationRecipientMetier(
  companyCommunicationRecipientMetiers: CompanyCommunicationRecipientMetierEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companyCommunicationRecipientsToNormalize: { [id: number]: CompanyCommunicationRecipientEntityState } = {};
  const organizationMetiersToNormalize: { [id: number]: OrganizationMetierEntityState } = {};

  const normalizedCompanyCommunicationRecipientMetiers = companyCommunicationRecipientMetiers.map(
    (companyCommunicationRecipientMetier: CompanyCommunicationRecipientMetierEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      companyCommunicationRecipientMetierNormalizeCompanyCommunicationRecipient(
        companyCommunicationRecipientMetier,
        companyCommunicationRecipientsToNormalize,
        relations
      );
      companyCommunicationRecipientMetierNormalizeOrganizationMetier(
        companyCommunicationRecipientMetier,
        organizationMetiersToNormalize,
        relations
      );

      return {
        ...companyCommunicationRecipientMetier,
        ...relations
      };
    }
  );
  const companyCommunicationRecipientsToNormalizeArray = Object.values(companyCommunicationRecipientsToNormalize);
  if (companyCommunicationRecipientsToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationRecipient(companyCommunicationRecipientsToNormalizeArray, actionType)
    );
  }
  const organizationMetiersToNormalizeArray = Object.values(organizationMetiersToNormalize);
  if (organizationMetiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationMetier(organizationMetiersToNormalizeArray, actionType));
  }

  const companyCommunicationRecipientMetierActionCreator =
    companyCommunicationRecipientMetierActions.upsertManyCompanyCommunicationRecipientMetiersSuccess;
  actions.push(
    companyCommunicationRecipientMetierActionCreator({
      companyCommunicationRecipientMetiers: normalizedCompanyCommunicationRecipientMetiers
    })
  );

  return actions;
}

function companyCommunicationRecipientMetierNormalizeCompanyCommunicationRecipient(
  companyCommunicationRecipientMetier: CompanyCommunicationRecipientMetierEntityState,
  companyCommunicationRecipientsToNormalize: { [id: number]: CompanyCommunicationRecipientEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationRecipientMetier.companyCommunicationRecipient &&
    typeof companyCommunicationRecipientMetier.companyCommunicationRecipient !== 'number'
  ) {
    if (
      !companyCommunicationRecipientsToNormalize[
        companyCommunicationRecipientMetier.companyCommunicationRecipient.idCompanyCommunicationRecipient
      ]
    ) {
      companyCommunicationRecipientsToNormalize[
        companyCommunicationRecipientMetier.companyCommunicationRecipient.idCompanyCommunicationRecipient
      ] = {
        ...companyCommunicationRecipientMetier.companyCommunicationRecipient,
        companyCommunicationRecipientMetiers: []
      };
    }
    (
      companyCommunicationRecipientsToNormalize[
        companyCommunicationRecipientMetier.companyCommunicationRecipient.idCompanyCommunicationRecipient
      ].companyCommunicationRecipientMetiers as number[]
    ).push(companyCommunicationRecipientMetier.idCompanyCommunicationRecipientMetier);
    relations['companyCommunicationRecipient'] =
      companyCommunicationRecipientMetier.companyCommunicationRecipient.idCompanyCommunicationRecipient;
  }
}

function companyCommunicationRecipientMetierNormalizeOrganizationMetier(
  companyCommunicationRecipientMetier: CompanyCommunicationRecipientMetierEntityState,
  organizationMetiersToNormalize: { [id: number]: OrganizationMetierEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationRecipientMetier.organizationMetier &&
    typeof companyCommunicationRecipientMetier.organizationMetier !== 'number'
  ) {
    if (!organizationMetiersToNormalize[companyCommunicationRecipientMetier.organizationMetier.idOrganizationMetier]) {
      organizationMetiersToNormalize[companyCommunicationRecipientMetier.organizationMetier.idOrganizationMetier] = {
        ...companyCommunicationRecipientMetier.organizationMetier,
        companyCommunicationRecipientMetiers: []
      };
    }
    (
      organizationMetiersToNormalize[companyCommunicationRecipientMetier.organizationMetier.idOrganizationMetier]
        .companyCommunicationRecipientMetiers as number[]
    ).push(companyCommunicationRecipientMetier.idCompanyCommunicationRecipientMetier);
    relations['organizationMetier'] = companyCommunicationRecipientMetier.organizationMetier.idOrganizationMetier;
  }
}

/**
 *  CompanyCommunicationRecipientUser
 */
export function getActionsToNormalizeCompanyCommunicationRecipientUser(
  companyCommunicationRecipientUsers: CompanyCommunicationRecipientUserEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companyCommunicationRecipientsToNormalize: { [id: number]: CompanyCommunicationRecipientEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};

  const normalizedCompanyCommunicationRecipientUsers = companyCommunicationRecipientUsers.map(
    (companyCommunicationRecipientUser: CompanyCommunicationRecipientUserEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      companyCommunicationRecipientUserNormalizeCompanyCommunicationRecipient(
        companyCommunicationRecipientUser,
        companyCommunicationRecipientsToNormalize,
        relations
      );
      companyCommunicationRecipientUserNormalizeUser(companyCommunicationRecipientUser, usersToNormalize, relations);

      return {
        ...companyCommunicationRecipientUser,
        ...relations
      };
    }
  );
  const companyCommunicationRecipientsToNormalizeArray = Object.values(companyCommunicationRecipientsToNormalize);
  if (companyCommunicationRecipientsToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationRecipient(companyCommunicationRecipientsToNormalizeArray, actionType)
    );
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }

  const companyCommunicationRecipientUserActionCreator =
    companyCommunicationRecipientUserActions.upsertManyCompanyCommunicationRecipientUsersSuccess;
  actions.push(
    companyCommunicationRecipientUserActionCreator({
      companyCommunicationRecipientUsers: normalizedCompanyCommunicationRecipientUsers
    })
  );

  return actions;
}

function companyCommunicationRecipientUserNormalizeCompanyCommunicationRecipient(
  companyCommunicationRecipientUser: CompanyCommunicationRecipientUserEntityState,
  companyCommunicationRecipientsToNormalize: { [id: number]: CompanyCommunicationRecipientEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationRecipientUser.companyCommunicationRecipient &&
    typeof companyCommunicationRecipientUser.companyCommunicationRecipient !== 'number'
  ) {
    if (
      !companyCommunicationRecipientsToNormalize[
        companyCommunicationRecipientUser.companyCommunicationRecipient.idCompanyCommunicationRecipient
      ]
    ) {
      companyCommunicationRecipientsToNormalize[
        companyCommunicationRecipientUser.companyCommunicationRecipient.idCompanyCommunicationRecipient
      ] = {
        ...companyCommunicationRecipientUser.companyCommunicationRecipient,
        companyCommunicationRecipientUsers: []
      };
    }
    (
      companyCommunicationRecipientsToNormalize[
        companyCommunicationRecipientUser.companyCommunicationRecipient.idCompanyCommunicationRecipient
      ].companyCommunicationRecipientUsers as number[]
    ).push(companyCommunicationRecipientUser.idCompanyCommunicationRecipientUser);
    relations['companyCommunicationRecipient'] =
      companyCommunicationRecipientUser.companyCommunicationRecipient.idCompanyCommunicationRecipient;
  }
}

function companyCommunicationRecipientUserNormalizeUser(
  companyCommunicationRecipientUser: CompanyCommunicationRecipientUserEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyCommunicationRecipientUser.user && typeof companyCommunicationRecipientUser.user !== 'number') {
    if (!usersToNormalize[companyCommunicationRecipientUser.user.idUser]) {
      usersToNormalize[companyCommunicationRecipientUser.user.idUser] = {
        ...companyCommunicationRecipientUser.user,
        companyCommunicationRecipientUsers: []
      };
    }
    (
      usersToNormalize[companyCommunicationRecipientUser.user.idUser].companyCommunicationRecipientUsers as number[]
    ).push(companyCommunicationRecipientUser.idCompanyCommunicationRecipientUser);
    relations['user'] = companyCommunicationRecipientUser.user.idUser;
  }
}

/**
 *  CompanyCommunicationGeneratedDocument
 */
export function getActionsToNormalizeCompanyCommunicationGeneratedDocument(
  companyCommunicationGeneratedDocuments: CompanyCommunicationGeneratedDocumentEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState } = {};
  const generatedDocumentsToNormalize: { [id: number]: GeneratedDocumentEntityState } = {};

  const normalizedCompanyCommunicationGeneratedDocuments = companyCommunicationGeneratedDocuments.map(
    (companyCommunicationGeneratedDocument: CompanyCommunicationGeneratedDocumentEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      companyCommunicationGeneratedDocumentNormalizeCompanyCommunication(
        companyCommunicationGeneratedDocument,
        companyCommunicationsToNormalize,
        relations
      );
      companyCommunicationGeneratedDocumentNormalizeGeneratedDocument(
        companyCommunicationGeneratedDocument,
        generatedDocumentsToNormalize,
        relations
      );

      return {
        ...companyCommunicationGeneratedDocument,
        ...relations
      };
    }
  );
  const companyCommunicationsToNormalizeArray = Object.values(companyCommunicationsToNormalize);
  if (companyCommunicationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyCommunication(companyCommunicationsToNormalizeArray, actionType));
  }
  const generatedDocumentsToNormalizeArray = Object.values(generatedDocumentsToNormalize);
  if (generatedDocumentsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeGeneratedDocument(generatedDocumentsToNormalizeArray, actionType));
  }

  const companyCommunicationGeneratedDocumentActionCreator =
    companyCommunicationGeneratedDocumentActions.upsertManyCompanyCommunicationGeneratedDocumentsSuccess;
  actions.push(
    companyCommunicationGeneratedDocumentActionCreator({
      companyCommunicationGeneratedDocuments: normalizedCompanyCommunicationGeneratedDocuments
    })
  );

  return actions;
}

function companyCommunicationGeneratedDocumentNormalizeCompanyCommunication(
  companyCommunicationGeneratedDocument: CompanyCommunicationGeneratedDocumentEntityState,
  companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationGeneratedDocument.companyCommunication &&
    typeof companyCommunicationGeneratedDocument.companyCommunication !== 'number'
  ) {
    if (
      !companyCommunicationsToNormalize[
        companyCommunicationGeneratedDocument.companyCommunication.idCompanyCommunication
      ]
    ) {
      companyCommunicationsToNormalize[
        companyCommunicationGeneratedDocument.companyCommunication.idCompanyCommunication
      ] = { ...companyCommunicationGeneratedDocument.companyCommunication, companyCommunicationGeneratedDocuments: [] };
    }
    (
      companyCommunicationsToNormalize[
        companyCommunicationGeneratedDocument.companyCommunication.idCompanyCommunication
      ].companyCommunicationGeneratedDocuments as number[]
    ).push(companyCommunicationGeneratedDocument.idCompanyCommunicationGeneratedDocument);
    relations['companyCommunication'] =
      companyCommunicationGeneratedDocument.companyCommunication.idCompanyCommunication;
  }
}

function companyCommunicationGeneratedDocumentNormalizeGeneratedDocument(
  companyCommunicationGeneratedDocument: CompanyCommunicationGeneratedDocumentEntityState,
  generatedDocumentsToNormalize: { [id: number]: GeneratedDocumentEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyCommunicationGeneratedDocument.generatedDocument &&
    typeof companyCommunicationGeneratedDocument.generatedDocument !== 'number'
  ) {
    if (!generatedDocumentsToNormalize[companyCommunicationGeneratedDocument.generatedDocument.idGeneratedDocument]) {
      generatedDocumentsToNormalize[companyCommunicationGeneratedDocument.generatedDocument.idGeneratedDocument] = {
        ...companyCommunicationGeneratedDocument.generatedDocument,
        companyCommunicationGeneratedDocuments: []
      };
    }
    (
      generatedDocumentsToNormalize[companyCommunicationGeneratedDocument.generatedDocument.idGeneratedDocument]
        .companyCommunicationGeneratedDocuments as number[]
    ).push(companyCommunicationGeneratedDocument.idCompanyCommunicationGeneratedDocument);
    relations['generatedDocument'] = companyCommunicationGeneratedDocument.generatedDocument.idGeneratedDocument;
  }
}

/**
 *  OrganizationResidenceTodo
 */
export function getActionsToNormalizeOrganizationResidenceTodo(
  organizationResidenceTodos: OrganizationResidenceTodoEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const diagnosticsToNormalize: { [id: number]: DiagnosticEntityState } = {};
  const residenceTodosToNormalize: ResidenceTodoEntityState[] = [];
  const organizationResidenceTodoRulesToNormalize: { [id: number]: OrganizationResidenceTodoRuleEntityState } = {};
  const organizationResidenceTodoAppliedRulesToNormalize: OrganizationResidenceTodoAppliedRuleEntityState[] = [];
  const organizationResidenceTodoProfilsToNormalize: OrganizationResidenceTodoProfilEntityState[] = [];
  const companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState } = {};
  const companyCommunicationResidenceTodosToNormalize: CompanyCommunicationResidenceTodoEntityState[] = [];

  const normalizedOrganizationResidenceTodos = organizationResidenceTodos.map(
    (organizationResidenceTodo: OrganizationResidenceTodoEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationResidenceTodoNormalizeResidenceTodo(organizationResidenceTodo, residenceTodosToNormalize, relations);
      organizationResidenceTodoNormalizeOrganizationResidenceTodoRule(
        organizationResidenceTodo,
        organizationResidenceTodoRulesToNormalize,
        relations
      );
      organizationResidenceTodoNormalizeOrganizationResidenceTodoAppliedRule(
        organizationResidenceTodo,
        organizationResidenceTodoAppliedRulesToNormalize,
        relations
      );
      organizationResidenceTodoNormalizeOrganizationResidenceTodoProfil(
        organizationResidenceTodo,
        organizationResidenceTodoProfilsToNormalize,
        relations
      );
      organizationResidenceTodoNormalizeCompanyCommunication(
        organizationResidenceTodo,
        companyCommunicationsToNormalize,
        relations
      );
      organizationResidenceTodoNormalizeCompanyCommunicationResidenceTodo(
        organizationResidenceTodo,
        companyCommunicationResidenceTodosToNormalize,
        relations
      );
      organizationResidenceTodoNormalizeOrganization(organizationResidenceTodo, organizationsToNormalize, relations);
      organizationResidenceTodoNormalizeDiagnostic(organizationResidenceTodo, diagnosticsToNormalize, relations);

      return {
        ...organizationResidenceTodo,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const diagnosticsToNormalizeArray = Object.values(diagnosticsToNormalize);
  if (diagnosticsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeDiagnostic(diagnosticsToNormalizeArray, actionType));
  }
  if (residenceTodosToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceTodo(residenceTodosToNormalize, actionType));
  }
  const organizationResidenceTodoRulesToNormalizeArray = Object.values(organizationResidenceTodoRulesToNormalize);
  if (organizationResidenceTodoRulesToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceTodoRule(organizationResidenceTodoRulesToNormalizeArray, actionType)
    );
  }
  if (organizationResidenceTodoAppliedRulesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceTodoAppliedRule(
        organizationResidenceTodoAppliedRulesToNormalize,
        actionType
      )
    );
  }
  if (organizationResidenceTodoProfilsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceTodoProfil(organizationResidenceTodoProfilsToNormalize, actionType)
    );
  }
  const companyCommunicationsToNormalizeArray = Object.values(companyCommunicationsToNormalize);
  if (companyCommunicationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyCommunication(companyCommunicationsToNormalizeArray, actionType));
  }
  if (companyCommunicationResidenceTodosToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationResidenceTodo(
        companyCommunicationResidenceTodosToNormalize,
        actionType
      )
    );
  }

  const organizationResidenceTodoActionCreator =
    organizationResidenceTodoActions.upsertManyOrganizationResidenceTodosSuccess;
  actions.push(
    organizationResidenceTodoActionCreator({ organizationResidenceTodos: normalizedOrganizationResidenceTodos })
  );

  return actions;
}

function organizationResidenceTodoNormalizeResidenceTodo(
  organizationResidenceTodo: OrganizationResidenceTodoEntityState,
  residenceTodosToNormalize: ResidenceTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationResidenceTodo.residenceTodos?.length &&
    typeof organizationResidenceTodo.residenceTodos[0] !== 'number'
  ) {
    const idsResidenceTodos: number[] = [];
    residenceTodosToNormalize.push(
      ...(organizationResidenceTodo.residenceTodos as ResidenceTodo[]).map((residenceTodo: ResidenceTodo) => {
        idsResidenceTodos.push(residenceTodo.idResidenceTodo);
        return { ...residenceTodo, organizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo };
      })
    );
    relations['residenceTodos'] = idsResidenceTodos;
  }
}

function organizationResidenceTodoNormalizeOrganizationResidenceTodoRule(
  organizationResidenceTodo: OrganizationResidenceTodoEntityState,
  organizationResidenceTodoRulesToNormalize: { [id: number]: OrganizationResidenceTodoRuleEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationResidenceTodo.organizationResidenceTodoRules?.length &&
    typeof organizationResidenceTodo.organizationResidenceTodoRules[0] !== 'number'
  ) {
    const idsOrganizationResidenceTodoRules: number[] = (
      organizationResidenceTodo.organizationResidenceTodoRules as OrganizationResidenceTodoRule[]
    ).map((organizationResidenceTodoRule: OrganizationResidenceTodoRule) => {
      if (!organizationResidenceTodoRulesToNormalize[organizationResidenceTodoRule.idOrganizationResidenceTodoRule]) {
        organizationResidenceTodoRulesToNormalize[organizationResidenceTodoRule.idOrganizationResidenceTodoRule] = {
          ...organizationResidenceTodoRule,
          organizationResidenceTodos: []
        };
      }
      (
        organizationResidenceTodoRulesToNormalize[organizationResidenceTodoRule.idOrganizationResidenceTodoRule]
          .organizationResidenceTodos as number[]
      ).push(organizationResidenceTodo.idOrganizationResidenceTodo);
      return organizationResidenceTodoRule.idOrganizationResidenceTodoRule;
    });
    relations['organizationResidenceTodoRules'] = idsOrganizationResidenceTodoRules;
  }
}

function organizationResidenceTodoNormalizeOrganizationResidenceTodoAppliedRule(
  organizationResidenceTodo: OrganizationResidenceTodoEntityState,
  organizationResidenceTodoAppliedRulesToNormalize: OrganizationResidenceTodoAppliedRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationResidenceTodo.organizationResidenceTodoAppliedRules?.length &&
    typeof organizationResidenceTodo.organizationResidenceTodoAppliedRules[0] !== 'number'
  ) {
    const idsOrganizationResidenceTodoAppliedRules: number[] = [];
    organizationResidenceTodoAppliedRulesToNormalize.push(
      ...(
        organizationResidenceTodo.organizationResidenceTodoAppliedRules as OrganizationResidenceTodoAppliedRule[]
      ).map((organizationResidenceTodoAppliedRule: OrganizationResidenceTodoAppliedRule) => {
        idsOrganizationResidenceTodoAppliedRules.push(
          organizationResidenceTodoAppliedRule.idOrganizationResidenceTodoAppliedRule
        );
        return {
          ...organizationResidenceTodoAppliedRule,
          organizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo
        };
      })
    );
    relations['organizationResidenceTodoAppliedRules'] = idsOrganizationResidenceTodoAppliedRules;
  }
}

function organizationResidenceTodoNormalizeOrganizationResidenceTodoProfil(
  organizationResidenceTodo: OrganizationResidenceTodoEntityState,
  organizationResidenceTodoProfilsToNormalize: OrganizationResidenceTodoProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationResidenceTodo.organizationResidenceTodoProfils?.length &&
    typeof organizationResidenceTodo.organizationResidenceTodoProfils[0] !== 'number'
  ) {
    const idsOrganizationResidenceTodoProfils: number[] = [];
    organizationResidenceTodoProfilsToNormalize.push(
      ...(organizationResidenceTodo.organizationResidenceTodoProfils as OrganizationResidenceTodoProfil[]).map(
        (organizationResidenceTodoProfil: OrganizationResidenceTodoProfil) => {
          idsOrganizationResidenceTodoProfils.push(organizationResidenceTodoProfil.idOrganizationResidenceTodoProfil);
          return {
            ...organizationResidenceTodoProfil,
            organizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo
          };
        }
      )
    );
    relations['organizationResidenceTodoProfils'] = idsOrganizationResidenceTodoProfils;
  }
}

function organizationResidenceTodoNormalizeCompanyCommunication(
  organizationResidenceTodo: OrganizationResidenceTodoEntityState,
  companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationResidenceTodo.companyCommunications?.length &&
    typeof organizationResidenceTodo.companyCommunications[0] !== 'number'
  ) {
    const idsCompanyCommunications: number[] = (
      organizationResidenceTodo.companyCommunications as CompanyCommunication[]
    ).map((companyCommunication: CompanyCommunication) => {
      if (!companyCommunicationsToNormalize[companyCommunication.idCompanyCommunication]) {
        companyCommunicationsToNormalize[companyCommunication.idCompanyCommunication] = {
          ...companyCommunication,
          organizationResidenceTodos: []
        };
      }
      (
        companyCommunicationsToNormalize[companyCommunication.idCompanyCommunication]
          .organizationResidenceTodos as number[]
      ).push(organizationResidenceTodo.idOrganizationResidenceTodo);
      return companyCommunication.idCompanyCommunication;
    });
    relations['companyCommunications'] = idsCompanyCommunications;
  }
}

function organizationResidenceTodoNormalizeCompanyCommunicationResidenceTodo(
  organizationResidenceTodo: OrganizationResidenceTodoEntityState,
  companyCommunicationResidenceTodosToNormalize: CompanyCommunicationResidenceTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationResidenceTodo.companyCommunicationResidenceTodos?.length &&
    typeof organizationResidenceTodo.companyCommunicationResidenceTodos[0] !== 'number'
  ) {
    const idsCompanyCommunicationResidenceTodos: number[] = [];
    companyCommunicationResidenceTodosToNormalize.push(
      ...(organizationResidenceTodo.companyCommunicationResidenceTodos as CompanyCommunicationResidenceTodo[]).map(
        (companyCommunicationResidenceTodo: CompanyCommunicationResidenceTodo) => {
          idsCompanyCommunicationResidenceTodos.push(
            companyCommunicationResidenceTodo.idCompanyCommunicationResidenceTodo
          );
          return {
            ...companyCommunicationResidenceTodo,
            organizationResidenceTodo: organizationResidenceTodo.idOrganizationResidenceTodo
          };
        }
      )
    );
    relations['companyCommunicationResidenceTodos'] = idsCompanyCommunicationResidenceTodos;
  }
}

function organizationResidenceTodoNormalizeOrganization(
  organizationResidenceTodo: OrganizationResidenceTodoEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationResidenceTodo.organization && typeof organizationResidenceTodo.organization !== 'number') {
    if (!organizationsToNormalize[organizationResidenceTodo.organization.idOrganization]) {
      organizationsToNormalize[organizationResidenceTodo.organization.idOrganization] = {
        ...organizationResidenceTodo.organization,
        organizationResidenceTodos: []
      };
    }
    (
      organizationsToNormalize[organizationResidenceTodo.organization.idOrganization]
        .organizationResidenceTodos as number[]
    ).push(organizationResidenceTodo.idOrganizationResidenceTodo);
    relations['organization'] = organizationResidenceTodo.organization.idOrganization;
  }
}

function organizationResidenceTodoNormalizeDiagnostic(
  organizationResidenceTodo: OrganizationResidenceTodoEntityState,
  diagnosticsToNormalize: { [id: number]: DiagnosticEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationResidenceTodo.diagnostic && typeof organizationResidenceTodo.diagnostic !== 'number') {
    if (!diagnosticsToNormalize[organizationResidenceTodo.diagnostic.idDiagnostic]) {
      diagnosticsToNormalize[organizationResidenceTodo.diagnostic.idDiagnostic] = {
        ...organizationResidenceTodo.diagnostic,
        organizationResidenceTodos: []
      };
    }
    (
      diagnosticsToNormalize[organizationResidenceTodo.diagnostic.idDiagnostic].organizationResidenceTodos as number[]
    ).push(organizationResidenceTodo.idOrganizationResidenceTodo);
    relations['diagnostic'] = organizationResidenceTodo.diagnostic.idDiagnostic;
  }
}

/**
 *  OrganizationResidenceTodoRule
 */
export function getActionsToNormalizeOrganizationResidenceTodoRule(
  organizationResidenceTodoRules: OrganizationResidenceTodoRuleEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const organizationSaleCategoriesToNormalize: { [id: number]: OrganizationSaleCategoryEntityState } = {};
  const familySaleCategoriesToNormalize: { [id: number]: SaleCategoryFamilyEntityState } = {};
  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const organizationResidenceTodosToNormalize: { [id: number]: OrganizationResidenceTodoEntityState } = {};
  const organizationResidenceTodoAppliedRulesToNormalize: OrganizationResidenceTodoAppliedRuleEntityState[] = [];

  const normalizedOrganizationResidenceTodoRules = organizationResidenceTodoRules.map(
    (organizationResidenceTodoRule: OrganizationResidenceTodoRuleEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationResidenceTodoRuleNormalizeOrganizationResidenceTodo(
        organizationResidenceTodoRule,
        organizationResidenceTodosToNormalize,
        relations
      );
      organizationResidenceTodoRuleNormalizeOrganizationResidenceTodoAppliedRule(
        organizationResidenceTodoRule,
        organizationResidenceTodoAppliedRulesToNormalize,
        relations
      );
      organizationResidenceTodoRuleNormalizeOrganization(
        organizationResidenceTodoRule,
        organizationsToNormalize,
        relations
      );
      organizationResidenceTodoRuleNormalizeOrganizationSaleCategory(
        organizationResidenceTodoRule,
        organizationSaleCategoriesToNormalize,
        relations
      );
      organizationResidenceTodoRuleNormalizeSaleCategoryFamily(
        organizationResidenceTodoRule,
        familySaleCategoriesToNormalize,
        relations
      );
      organizationResidenceTodoRuleNormalizeCompany(organizationResidenceTodoRule, companiesToNormalize, relations);

      return {
        ...organizationResidenceTodoRule,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const organizationSaleCategoriesToNormalizeArray = Object.values(organizationSaleCategoriesToNormalize);
  if (organizationSaleCategoriesToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationSaleCategory(organizationSaleCategoriesToNormalizeArray, actionType)
    );
  }
  const familySaleCategoriesToNormalizeArray = Object.values(familySaleCategoriesToNormalize);
  if (familySaleCategoriesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSaleCategoryFamily(familySaleCategoriesToNormalizeArray, actionType));
  }
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  const organizationResidenceTodosToNormalizeArray = Object.values(organizationResidenceTodosToNormalize);
  if (organizationResidenceTodosToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceTodo(organizationResidenceTodosToNormalizeArray, actionType)
    );
  }
  if (organizationResidenceTodoAppliedRulesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceTodoAppliedRule(
        organizationResidenceTodoAppliedRulesToNormalize,
        actionType
      )
    );
  }

  const organizationResidenceTodoRuleActionCreator =
    organizationResidenceTodoRuleActions.upsertManyOrganizationResidenceTodoRulesSuccess;
  actions.push(
    organizationResidenceTodoRuleActionCreator({
      organizationResidenceTodoRules: normalizedOrganizationResidenceTodoRules
    })
  );

  return actions;
}

function organizationResidenceTodoRuleNormalizeOrganizationResidenceTodo(
  organizationResidenceTodoRule: OrganizationResidenceTodoRuleEntityState,
  organizationResidenceTodosToNormalize: { [id: number]: OrganizationResidenceTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationResidenceTodoRule.organizationResidenceTodos?.length &&
    typeof organizationResidenceTodoRule.organizationResidenceTodos[0] !== 'number'
  ) {
    const idsOrganizationResidenceTodos: number[] = (
      organizationResidenceTodoRule.organizationResidenceTodos as OrganizationResidenceTodo[]
    ).map((organizationResidenceTodo: OrganizationResidenceTodo) => {
      if (!organizationResidenceTodosToNormalize[organizationResidenceTodo.idOrganizationResidenceTodo]) {
        organizationResidenceTodosToNormalize[organizationResidenceTodo.idOrganizationResidenceTodo] = {
          ...organizationResidenceTodo,
          organizationResidenceTodoRules: []
        };
      }
      (
        organizationResidenceTodosToNormalize[organizationResidenceTodo.idOrganizationResidenceTodo]
          .organizationResidenceTodoRules as number[]
      ).push(organizationResidenceTodoRule.idOrganizationResidenceTodoRule);
      return organizationResidenceTodo.idOrganizationResidenceTodo;
    });
    relations['organizationResidenceTodos'] = idsOrganizationResidenceTodos;
  }
}

function organizationResidenceTodoRuleNormalizeOrganizationResidenceTodoAppliedRule(
  organizationResidenceTodoRule: OrganizationResidenceTodoRuleEntityState,
  organizationResidenceTodoAppliedRulesToNormalize: OrganizationResidenceTodoAppliedRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationResidenceTodoRule.organizationResidenceTodoAppliedRules?.length &&
    typeof organizationResidenceTodoRule.organizationResidenceTodoAppliedRules[0] !== 'number'
  ) {
    const idsOrganizationResidenceTodoAppliedRules: number[] = [];
    organizationResidenceTodoAppliedRulesToNormalize.push(
      ...(
        organizationResidenceTodoRule.organizationResidenceTodoAppliedRules as OrganizationResidenceTodoAppliedRule[]
      ).map((organizationResidenceTodoAppliedRule: OrganizationResidenceTodoAppliedRule) => {
        idsOrganizationResidenceTodoAppliedRules.push(
          organizationResidenceTodoAppliedRule.idOrganizationResidenceTodoAppliedRule
        );
        return {
          ...organizationResidenceTodoAppliedRule,
          organizationResidenceTodoRule: organizationResidenceTodoRule.idOrganizationResidenceTodoRule
        };
      })
    );
    relations['organizationResidenceTodoAppliedRules'] = idsOrganizationResidenceTodoAppliedRules;
  }
}

function organizationResidenceTodoRuleNormalizeOrganization(
  organizationResidenceTodoRule: OrganizationResidenceTodoRuleEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationResidenceTodoRule.organization && typeof organizationResidenceTodoRule.organization !== 'number') {
    if (!organizationsToNormalize[organizationResidenceTodoRule.organization.idOrganization]) {
      organizationsToNormalize[organizationResidenceTodoRule.organization.idOrganization] = {
        ...organizationResidenceTodoRule.organization,
        organizationResidenceTodoRules: []
      };
    }
    (
      organizationsToNormalize[organizationResidenceTodoRule.organization.idOrganization]
        .organizationResidenceTodoRules as number[]
    ).push(organizationResidenceTodoRule.idOrganizationResidenceTodoRule);
    relations['organization'] = organizationResidenceTodoRule.organization.idOrganization;
  }
}

function organizationResidenceTodoRuleNormalizeOrganizationSaleCategory(
  organizationResidenceTodoRule: OrganizationResidenceTodoRuleEntityState,
  organizationSaleCategoriesToNormalize: { [id: number]: OrganizationSaleCategoryEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationResidenceTodoRule.organizationSaleCategory &&
    typeof organizationResidenceTodoRule.organizationSaleCategory !== 'number'
  ) {
    if (
      !organizationSaleCategoriesToNormalize[
        organizationResidenceTodoRule.organizationSaleCategory.idOrganizationSaleCategory
      ]
    ) {
      organizationSaleCategoriesToNormalize[
        organizationResidenceTodoRule.organizationSaleCategory.idOrganizationSaleCategory
      ] = { ...organizationResidenceTodoRule.organizationSaleCategory, organizationResidenceTodoRules: [] };
    }
    (
      organizationSaleCategoriesToNormalize[
        organizationResidenceTodoRule.organizationSaleCategory.idOrganizationSaleCategory
      ].organizationResidenceTodoRules as number[]
    ).push(organizationResidenceTodoRule.idOrganizationResidenceTodoRule);
    relations['organizationSaleCategory'] =
      organizationResidenceTodoRule.organizationSaleCategory.idOrganizationSaleCategory;
  }
}

function organizationResidenceTodoRuleNormalizeSaleCategoryFamily(
  organizationResidenceTodoRule: OrganizationResidenceTodoRuleEntityState,
  familySaleCategoriesToNormalize: { [id: number]: SaleCategoryFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationResidenceTodoRule.saleCategoryFamily &&
    typeof organizationResidenceTodoRule.saleCategoryFamily !== 'number'
  ) {
    if (!familySaleCategoriesToNormalize[organizationResidenceTodoRule.saleCategoryFamily.idSaleCategoryFamily]) {
      familySaleCategoriesToNormalize[organizationResidenceTodoRule.saleCategoryFamily.idSaleCategoryFamily] = {
        ...organizationResidenceTodoRule.saleCategoryFamily,
        organizationResidenceTodoRules: []
      };
    }
    (
      familySaleCategoriesToNormalize[organizationResidenceTodoRule.saleCategoryFamily.idSaleCategoryFamily]
        .organizationResidenceTodoRules as number[]
    ).push(organizationResidenceTodoRule.idOrganizationResidenceTodoRule);
    relations['saleCategoryFamily'] = organizationResidenceTodoRule.saleCategoryFamily.idSaleCategoryFamily;
  }
}

function organizationResidenceTodoRuleNormalizeCompany(
  organizationResidenceTodoRule: OrganizationResidenceTodoRuleEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationResidenceTodoRule.company && typeof organizationResidenceTodoRule.company !== 'number') {
    if (!companiesToNormalize[organizationResidenceTodoRule.company.idCompany]) {
      companiesToNormalize[organizationResidenceTodoRule.company.idCompany] = {
        ...organizationResidenceTodoRule.company,
        organizationResidenceTodoRules: []
      };
    }
    (
      companiesToNormalize[organizationResidenceTodoRule.company.idCompany].organizationResidenceTodoRules as number[]
    ).push(organizationResidenceTodoRule.idOrganizationResidenceTodoRule);
    relations['company'] = organizationResidenceTodoRule.company.idCompany;
  }
}

/**
 *  OrganizationResidenceTodoAppliedRule
 */
export function getActionsToNormalizeOrganizationResidenceTodoAppliedRule(
  organizationResidenceTodoAppliedRules: OrganizationResidenceTodoAppliedRuleEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationResidenceTodosToNormalize: { [id: number]: OrganizationResidenceTodoEntityState } = {};
  const organizationResidenceTodoRulesToNormalize: { [id: number]: OrganizationResidenceTodoRuleEntityState } = {};

  const normalizedOrganizationResidenceTodoAppliedRules = organizationResidenceTodoAppliedRules.map(
    (organizationResidenceTodoAppliedRule: OrganizationResidenceTodoAppliedRuleEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationResidenceTodoAppliedRuleNormalizeOrganizationResidenceTodo(
        organizationResidenceTodoAppliedRule,
        organizationResidenceTodosToNormalize,
        relations
      );
      organizationResidenceTodoAppliedRuleNormalizeOrganizationResidenceTodoRule(
        organizationResidenceTodoAppliedRule,
        organizationResidenceTodoRulesToNormalize,
        relations
      );

      return {
        ...organizationResidenceTodoAppliedRule,
        ...relations
      };
    }
  );
  const organizationResidenceTodosToNormalizeArray = Object.values(organizationResidenceTodosToNormalize);
  if (organizationResidenceTodosToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceTodo(organizationResidenceTodosToNormalizeArray, actionType)
    );
  }
  const organizationResidenceTodoRulesToNormalizeArray = Object.values(organizationResidenceTodoRulesToNormalize);
  if (organizationResidenceTodoRulesToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceTodoRule(organizationResidenceTodoRulesToNormalizeArray, actionType)
    );
  }

  const organizationResidenceTodoAppliedRuleActionCreator =
    organizationResidenceTodoAppliedRuleActions.upsertManyOrganizationResidenceTodoAppliedRulesSuccess;
  actions.push(
    organizationResidenceTodoAppliedRuleActionCreator({
      organizationResidenceTodoAppliedRules: normalizedOrganizationResidenceTodoAppliedRules
    })
  );

  return actions;
}

function organizationResidenceTodoAppliedRuleNormalizeOrganizationResidenceTodo(
  organizationResidenceTodoAppliedRule: OrganizationResidenceTodoAppliedRuleEntityState,
  organizationResidenceTodosToNormalize: { [id: number]: OrganizationResidenceTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationResidenceTodoAppliedRule.organizationResidenceTodo &&
    typeof organizationResidenceTodoAppliedRule.organizationResidenceTodo !== 'number'
  ) {
    if (
      !organizationResidenceTodosToNormalize[
        organizationResidenceTodoAppliedRule.organizationResidenceTodo.idOrganizationResidenceTodo
      ]
    ) {
      organizationResidenceTodosToNormalize[
        organizationResidenceTodoAppliedRule.organizationResidenceTodo.idOrganizationResidenceTodo
      ] = {
        ...organizationResidenceTodoAppliedRule.organizationResidenceTodo,
        organizationResidenceTodoAppliedRules: []
      };
    }
    (
      organizationResidenceTodosToNormalize[
        organizationResidenceTodoAppliedRule.organizationResidenceTodo.idOrganizationResidenceTodo
      ].organizationResidenceTodoAppliedRules as number[]
    ).push(organizationResidenceTodoAppliedRule.idOrganizationResidenceTodoAppliedRule);
    relations['organizationResidenceTodo'] =
      organizationResidenceTodoAppliedRule.organizationResidenceTodo.idOrganizationResidenceTodo;
  }
}

function organizationResidenceTodoAppliedRuleNormalizeOrganizationResidenceTodoRule(
  organizationResidenceTodoAppliedRule: OrganizationResidenceTodoAppliedRuleEntityState,
  organizationResidenceTodoRulesToNormalize: { [id: number]: OrganizationResidenceTodoRuleEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationResidenceTodoAppliedRule.organizationResidenceTodoRule &&
    typeof organizationResidenceTodoAppliedRule.organizationResidenceTodoRule !== 'number'
  ) {
    if (
      !organizationResidenceTodoRulesToNormalize[
        organizationResidenceTodoAppliedRule.organizationResidenceTodoRule.idOrganizationResidenceTodoRule
      ]
    ) {
      organizationResidenceTodoRulesToNormalize[
        organizationResidenceTodoAppliedRule.organizationResidenceTodoRule.idOrganizationResidenceTodoRule
      ] = {
        ...organizationResidenceTodoAppliedRule.organizationResidenceTodoRule,
        organizationResidenceTodoAppliedRules: []
      };
    }
    (
      organizationResidenceTodoRulesToNormalize[
        organizationResidenceTodoAppliedRule.organizationResidenceTodoRule.idOrganizationResidenceTodoRule
      ].organizationResidenceTodoAppliedRules as number[]
    ).push(organizationResidenceTodoAppliedRule.idOrganizationResidenceTodoAppliedRule);
    relations['organizationResidenceTodoRule'] =
      organizationResidenceTodoAppliedRule.organizationResidenceTodoRule.idOrganizationResidenceTodoRule;
  }
}

/**
 *  OrganizationSaleCategory
 */
export function getActionsToNormalizeOrganizationSaleCategory(
  organizationSaleCategories: OrganizationSaleCategoryEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const familySaleCategoriesToNormalize: { [id: number]: SaleCategoryFamilyEntityState } = {};
  const stratalotsToNormalize: StratalotEntityState[] = [];
  const organizationLeadTodoRulesToNormalize: OrganizationLeadTodoRuleEntityState[] = [];
  const organizationStratalotTodoRulesToNormalize: OrganizationStratalotTodoRuleEntityState[] = [];
  const organizationResidenceTodoRulesToNormalize: OrganizationResidenceTodoRuleEntityState[] = [];

  const normalizedOrganizationSaleCategories = organizationSaleCategories.map(
    (organizationSaleCategory: OrganizationSaleCategoryEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationSaleCategoryNormalizeStratalot(organizationSaleCategory, stratalotsToNormalize, relations);
      organizationSaleCategoryNormalizeOrganizationLeadTodoRule(
        organizationSaleCategory,
        organizationLeadTodoRulesToNormalize,
        relations
      );
      organizationSaleCategoryNormalizeOrganizationStratalotTodoRule(
        organizationSaleCategory,
        organizationStratalotTodoRulesToNormalize,
        relations
      );
      organizationSaleCategoryNormalizeOrganizationResidenceTodoRule(
        organizationSaleCategory,
        organizationResidenceTodoRulesToNormalize,
        relations
      );
      organizationSaleCategoryNormalizeOrganization(organizationSaleCategory, organizationsToNormalize, relations);
      organizationSaleCategoryNormalizeSaleCategoryFamily(
        organizationSaleCategory,
        familySaleCategoriesToNormalize,
        relations
      );

      return {
        ...organizationSaleCategory,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const familySaleCategoriesToNormalizeArray = Object.values(familySaleCategoriesToNormalize);
  if (familySaleCategoriesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSaleCategoryFamily(familySaleCategoriesToNormalizeArray, actionType));
  }
  if (stratalotsToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalize, actionType));
  }
  if (organizationLeadTodoRulesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationLeadTodoRule(organizationLeadTodoRulesToNormalize, actionType));
  }
  if (organizationStratalotTodoRulesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodoRule(organizationStratalotTodoRulesToNormalize, actionType)
    );
  }
  if (organizationResidenceTodoRulesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceTodoRule(organizationResidenceTodoRulesToNormalize, actionType)
    );
  }

  const organizationSaleCategoryActionCreator =
    organizationSaleCategoryActions.upsertManyOrganizationSaleCategoriesSuccess;
  actions.push(
    organizationSaleCategoryActionCreator({ organizationSaleCategories: normalizedOrganizationSaleCategories })
  );

  return actions;
}

function organizationSaleCategoryNormalizeStratalot(
  organizationSaleCategory: OrganizationSaleCategoryEntityState,
  stratalotsToNormalize: StratalotEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationSaleCategory.stratalots?.length && typeof organizationSaleCategory.stratalots[0] !== 'number') {
    const idsStratalots: number[] = [];
    stratalotsToNormalize.push(
      ...(organizationSaleCategory.stratalots as Stratalot[]).map((stratalot: Stratalot) => {
        idsStratalots.push(stratalot.idStratalot);
        return { ...stratalot, organizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory };
      })
    );
    relations['stratalots'] = idsStratalots;
  }
}

function organizationSaleCategoryNormalizeOrganizationLeadTodoRule(
  organizationSaleCategory: OrganizationSaleCategoryEntityState,
  organizationLeadTodoRulesToNormalize: OrganizationLeadTodoRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationSaleCategory.organizationLeadTodoRules?.length &&
    typeof organizationSaleCategory.organizationLeadTodoRules[0] !== 'number'
  ) {
    const idsOrganizationLeadTodoRules: number[] = [];
    organizationLeadTodoRulesToNormalize.push(
      ...(organizationSaleCategory.organizationLeadTodoRules as OrganizationLeadTodoRule[]).map(
        (organizationLeadTodoRule: OrganizationLeadTodoRule) => {
          idsOrganizationLeadTodoRules.push(organizationLeadTodoRule.idOrganizationLeadTodoRule);
          return {
            ...organizationLeadTodoRule,
            organizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory
          };
        }
      )
    );
    relations['organizationLeadTodoRules'] = idsOrganizationLeadTodoRules;
  }
}

function organizationSaleCategoryNormalizeOrganizationStratalotTodoRule(
  organizationSaleCategory: OrganizationSaleCategoryEntityState,
  organizationStratalotTodoRulesToNormalize: OrganizationStratalotTodoRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationSaleCategory.organizationStratalotTodoRules?.length &&
    typeof organizationSaleCategory.organizationStratalotTodoRules[0] !== 'number'
  ) {
    const idsOrganizationStratalotTodoRules: number[] = [];
    organizationStratalotTodoRulesToNormalize.push(
      ...(organizationSaleCategory.organizationStratalotTodoRules as OrganizationStratalotTodoRule[]).map(
        (organizationStratalotTodoRule: OrganizationStratalotTodoRule) => {
          idsOrganizationStratalotTodoRules.push(organizationStratalotTodoRule.idOrganizationStratalotTodoRule);
          return {
            ...organizationStratalotTodoRule,
            organizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory
          };
        }
      )
    );
    relations['organizationStratalotTodoRules'] = idsOrganizationStratalotTodoRules;
  }
}

function organizationSaleCategoryNormalizeOrganizationResidenceTodoRule(
  organizationSaleCategory: OrganizationSaleCategoryEntityState,
  organizationResidenceTodoRulesToNormalize: OrganizationResidenceTodoRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationSaleCategory.organizationResidenceTodoRules?.length &&
    typeof organizationSaleCategory.organizationResidenceTodoRules[0] !== 'number'
  ) {
    const idsOrganizationResidenceTodoRules: number[] = [];
    organizationResidenceTodoRulesToNormalize.push(
      ...(organizationSaleCategory.organizationResidenceTodoRules as OrganizationResidenceTodoRule[]).map(
        (organizationResidenceTodoRule: OrganizationResidenceTodoRule) => {
          idsOrganizationResidenceTodoRules.push(organizationResidenceTodoRule.idOrganizationResidenceTodoRule);
          return {
            ...organizationResidenceTodoRule,
            organizationSaleCategory: organizationSaleCategory.idOrganizationSaleCategory
          };
        }
      )
    );
    relations['organizationResidenceTodoRules'] = idsOrganizationResidenceTodoRules;
  }
}

function organizationSaleCategoryNormalizeOrganization(
  organizationSaleCategory: OrganizationSaleCategoryEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationSaleCategory.organization && typeof organizationSaleCategory.organization !== 'number') {
    if (!organizationsToNormalize[organizationSaleCategory.organization.idOrganization]) {
      organizationsToNormalize[organizationSaleCategory.organization.idOrganization] = {
        ...organizationSaleCategory.organization,
        organizationSaleCategories: []
      };
    }
    (
      organizationsToNormalize[organizationSaleCategory.organization.idOrganization]
        .organizationSaleCategories as number[]
    ).push(organizationSaleCategory.idOrganizationSaleCategory);
    relations['organization'] = organizationSaleCategory.organization.idOrganization;
  }
}

function organizationSaleCategoryNormalizeSaleCategoryFamily(
  organizationSaleCategory: OrganizationSaleCategoryEntityState,
  familySaleCategoriesToNormalize: { [id: number]: SaleCategoryFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationSaleCategory.saleCategoryFamily && typeof organizationSaleCategory.saleCategoryFamily !== 'number') {
    if (!familySaleCategoriesToNormalize[organizationSaleCategory.saleCategoryFamily.idSaleCategoryFamily]) {
      familySaleCategoriesToNormalize[organizationSaleCategory.saleCategoryFamily.idSaleCategoryFamily] = {
        ...organizationSaleCategory.saleCategoryFamily,
        organizationSaleCategories: []
      };
    }
    (
      familySaleCategoriesToNormalize[organizationSaleCategory.saleCategoryFamily.idSaleCategoryFamily]
        .organizationSaleCategories as number[]
    ).push(organizationSaleCategory.idOrganizationSaleCategory);
    relations['saleCategoryFamily'] = organizationSaleCategory.saleCategoryFamily.idSaleCategoryFamily;
  }
}

/**
 *  OrganizationStratalotAvancement
 */
export function getActionsToNormalizeOrganizationStratalotAvancement(
  organizationStratalotAvancements: OrganizationStratalotAvancementEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const stepsToNormalize: { [id: number]: StepEntityState } = {};
  const stratalotsToNormalize: StratalotEntityState[] = [];

  const normalizedOrganizationStratalotAvancements = organizationStratalotAvancements.map(
    (organizationStratalotAvancement: OrganizationStratalotAvancementEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationStratalotAvancementNormalizeStratalot(
        organizationStratalotAvancement,
        stratalotsToNormalize,
        relations
      );
      organizationStratalotAvancementNormalizeOrganization(
        organizationStratalotAvancement,
        organizationsToNormalize,
        relations
      );
      organizationStratalotAvancementNormalizeStep(organizationStratalotAvancement, stepsToNormalize, relations);

      return {
        ...organizationStratalotAvancement,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const stepsToNormalizeArray = Object.values(stepsToNormalize);
  if (stepsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStep(stepsToNormalizeArray, actionType));
  }
  if (stratalotsToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalize, actionType));
  }

  const organizationStratalotAvancementActionCreator =
    organizationStratalotAvancementActions.upsertManyOrganizationStratalotAvancementsSuccess;
  actions.push(
    organizationStratalotAvancementActionCreator({
      organizationStratalotAvancements: normalizedOrganizationStratalotAvancements
    })
  );

  return actions;
}

function organizationStratalotAvancementNormalizeStratalot(
  organizationStratalotAvancement: OrganizationStratalotAvancementEntityState,
  stratalotsToNormalize: StratalotEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationStratalotAvancement.stratalots?.length &&
    typeof organizationStratalotAvancement.stratalots[0] !== 'number'
  ) {
    const idsStratalots: number[] = [];
    stratalotsToNormalize.push(
      ...(organizationStratalotAvancement.stratalots as Stratalot[]).map((stratalot: Stratalot) => {
        idsStratalots.push(stratalot.idStratalot);
        return {
          ...stratalot,
          organizationStratalotAvancement: organizationStratalotAvancement.idOrganizationStratalotAvancement
        };
      })
    );
    relations['stratalots'] = idsStratalots;
  }
}

function organizationStratalotAvancementNormalizeOrganization(
  organizationStratalotAvancement: OrganizationStratalotAvancementEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationStratalotAvancement.organization &&
    typeof organizationStratalotAvancement.organization !== 'number'
  ) {
    if (!organizationsToNormalize[organizationStratalotAvancement.organization.idOrganization]) {
      organizationsToNormalize[organizationStratalotAvancement.organization.idOrganization] = {
        ...organizationStratalotAvancement.organization,
        organizationStratalotAvancements: []
      };
    }
    (
      organizationsToNormalize[organizationStratalotAvancement.organization.idOrganization]
        .organizationStratalotAvancements as number[]
    ).push(organizationStratalotAvancement.idOrganizationStratalotAvancement);
    relations['organization'] = organizationStratalotAvancement.organization.idOrganization;
  }
}

function organizationStratalotAvancementNormalizeStep(
  organizationStratalotAvancement: OrganizationStratalotAvancementEntityState,
  stepsToNormalize: { [id: number]: StepEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationStratalotAvancement.step && typeof organizationStratalotAvancement.step !== 'number') {
    if (!stepsToNormalize[organizationStratalotAvancement.step.idStep]) {
      stepsToNormalize[organizationStratalotAvancement.step.idStep] = {
        ...organizationStratalotAvancement.step,
        organizationStratalotAvancements: []
      };
    }
    (stepsToNormalize[organizationStratalotAvancement.step.idStep].organizationStratalotAvancements as number[]).push(
      organizationStratalotAvancement.idOrganizationStratalotAvancement
    );
    relations['step'] = organizationStratalotAvancement.step.idStep;
  }
}

/**
 *  OrganizationStratalotTodo
 */
export function getActionsToNormalizeOrganizationStratalotTodo(
  organizationStratalotTodos: OrganizationStratalotTodoEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const diagnosticsToNormalize: { [id: number]: DiagnosticEntityState } = {};
  const stratalotTodosToNormalize: StratalotTodoEntityState[] = [];
  const organizationStratalotTodoRulesToNormalize: { [id: number]: OrganizationStratalotTodoRuleEntityState } = {};
  const organizationStratalotTodoAppliedRulesToNormalize: OrganizationStratalotTodoAppliedRuleEntityState[] = [];
  const organizationStratalotTodoProfilsToNormalize: OrganizationStratalotTodoProfilEntityState[] = [];
  const companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState } = {};
  const companyCommunicationStratalotTodosToNormalize: CompanyCommunicationStratalotTodoEntityState[] = [];

  const normalizedOrganizationStratalotTodos = organizationStratalotTodos.map(
    (organizationStratalotTodo: OrganizationStratalotTodoEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationStratalotTodoNormalizeStratalotTodo(organizationStratalotTodo, stratalotTodosToNormalize, relations);
      organizationStratalotTodoNormalizeOrganizationStratalotTodoRule(
        organizationStratalotTodo,
        organizationStratalotTodoRulesToNormalize,
        relations
      );
      organizationStratalotTodoNormalizeOrganizationStratalotTodoAppliedRule(
        organizationStratalotTodo,
        organizationStratalotTodoAppliedRulesToNormalize,
        relations
      );
      organizationStratalotTodoNormalizeOrganizationStratalotTodoProfil(
        organizationStratalotTodo,
        organizationStratalotTodoProfilsToNormalize,
        relations
      );
      organizationStratalotTodoNormalizeCompanyCommunication(
        organizationStratalotTodo,
        companyCommunicationsToNormalize,
        relations
      );
      organizationStratalotTodoNormalizeCompanyCommunicationStratalotTodo(
        organizationStratalotTodo,
        companyCommunicationStratalotTodosToNormalize,
        relations
      );
      organizationStratalotTodoNormalizeOrganization(organizationStratalotTodo, organizationsToNormalize, relations);
      organizationStratalotTodoNormalizeDiagnostic(organizationStratalotTodo, diagnosticsToNormalize, relations);

      return {
        ...organizationStratalotTodo,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const diagnosticsToNormalizeArray = Object.values(diagnosticsToNormalize);
  if (diagnosticsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeDiagnostic(diagnosticsToNormalizeArray, actionType));
  }
  if (stratalotTodosToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalotTodo(stratalotTodosToNormalize, actionType));
  }
  const organizationStratalotTodoRulesToNormalizeArray = Object.values(organizationStratalotTodoRulesToNormalize);
  if (organizationStratalotTodoRulesToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodoRule(organizationStratalotTodoRulesToNormalizeArray, actionType)
    );
  }
  if (organizationStratalotTodoAppliedRulesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodoAppliedRule(
        organizationStratalotTodoAppliedRulesToNormalize,
        actionType
      )
    );
  }
  if (organizationStratalotTodoProfilsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodoProfil(organizationStratalotTodoProfilsToNormalize, actionType)
    );
  }
  const companyCommunicationsToNormalizeArray = Object.values(companyCommunicationsToNormalize);
  if (companyCommunicationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyCommunication(companyCommunicationsToNormalizeArray, actionType));
  }
  if (companyCommunicationStratalotTodosToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationStratalotTodo(
        companyCommunicationStratalotTodosToNormalize,
        actionType
      )
    );
  }

  const organizationStratalotTodoActionCreator =
    organizationStratalotTodoActions.upsertManyOrganizationStratalotTodosSuccess;
  actions.push(
    organizationStratalotTodoActionCreator({ organizationStratalotTodos: normalizedOrganizationStratalotTodos })
  );

  return actions;
}

function organizationStratalotTodoNormalizeStratalotTodo(
  organizationStratalotTodo: OrganizationStratalotTodoEntityState,
  stratalotTodosToNormalize: StratalotTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationStratalotTodo.stratalotTodos?.length &&
    typeof organizationStratalotTodo.stratalotTodos[0] !== 'number'
  ) {
    const idsStratalotTodos: number[] = [];
    stratalotTodosToNormalize.push(
      ...(organizationStratalotTodo.stratalotTodos as StratalotTodo[]).map((stratalotTodo: StratalotTodo) => {
        idsStratalotTodos.push(stratalotTodo.idStratalotTodo);
        return { ...stratalotTodo, organizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo };
      })
    );
    relations['stratalotTodos'] = idsStratalotTodos;
  }
}

function organizationStratalotTodoNormalizeOrganizationStratalotTodoRule(
  organizationStratalotTodo: OrganizationStratalotTodoEntityState,
  organizationStratalotTodoRulesToNormalize: { [id: number]: OrganizationStratalotTodoRuleEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationStratalotTodo.organizationStratalotTodoRules?.length &&
    typeof organizationStratalotTodo.organizationStratalotTodoRules[0] !== 'number'
  ) {
    const idsOrganizationStratalotTodoRules: number[] = (
      organizationStratalotTodo.organizationStratalotTodoRules as OrganizationStratalotTodoRule[]
    ).map((organizationStratalotTodoRule: OrganizationStratalotTodoRule) => {
      if (!organizationStratalotTodoRulesToNormalize[organizationStratalotTodoRule.idOrganizationStratalotTodoRule]) {
        organizationStratalotTodoRulesToNormalize[organizationStratalotTodoRule.idOrganizationStratalotTodoRule] = {
          ...organizationStratalotTodoRule,
          organizationStratalotTodos: []
        };
      }
      (
        organizationStratalotTodoRulesToNormalize[organizationStratalotTodoRule.idOrganizationStratalotTodoRule]
          .organizationStratalotTodos as number[]
      ).push(organizationStratalotTodo.idOrganizationStratalotTodo);
      return organizationStratalotTodoRule.idOrganizationStratalotTodoRule;
    });
    relations['organizationStratalotTodoRules'] = idsOrganizationStratalotTodoRules;
  }
}

function organizationStratalotTodoNormalizeOrganizationStratalotTodoAppliedRule(
  organizationStratalotTodo: OrganizationStratalotTodoEntityState,
  organizationStratalotTodoAppliedRulesToNormalize: OrganizationStratalotTodoAppliedRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationStratalotTodo.organizationStratalotTodoAppliedRules?.length &&
    typeof organizationStratalotTodo.organizationStratalotTodoAppliedRules[0] !== 'number'
  ) {
    const idsOrganizationStratalotTodoAppliedRules: number[] = [];
    organizationStratalotTodoAppliedRulesToNormalize.push(
      ...(
        organizationStratalotTodo.organizationStratalotTodoAppliedRules as OrganizationStratalotTodoAppliedRule[]
      ).map((organizationStratalotTodoAppliedRule: OrganizationStratalotTodoAppliedRule) => {
        idsOrganizationStratalotTodoAppliedRules.push(
          organizationStratalotTodoAppliedRule.idOrganizationStratalotTodoAppliedRule
        );
        return {
          ...organizationStratalotTodoAppliedRule,
          organizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo
        };
      })
    );
    relations['organizationStratalotTodoAppliedRules'] = idsOrganizationStratalotTodoAppliedRules;
  }
}

function organizationStratalotTodoNormalizeOrganizationStratalotTodoProfil(
  organizationStratalotTodo: OrganizationStratalotTodoEntityState,
  organizationStratalotTodoProfilsToNormalize: OrganizationStratalotTodoProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationStratalotTodo.organizationStratalotTodoProfils?.length &&
    typeof organizationStratalotTodo.organizationStratalotTodoProfils[0] !== 'number'
  ) {
    const idsOrganizationStratalotTodoProfils: number[] = [];
    organizationStratalotTodoProfilsToNormalize.push(
      ...(organizationStratalotTodo.organizationStratalotTodoProfils as OrganizationStratalotTodoProfil[]).map(
        (organizationStratalotTodoProfil: OrganizationStratalotTodoProfil) => {
          idsOrganizationStratalotTodoProfils.push(organizationStratalotTodoProfil.idOrganizationStratalotTodoProfil);
          return {
            ...organizationStratalotTodoProfil,
            organizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo
          };
        }
      )
    );
    relations['organizationStratalotTodoProfils'] = idsOrganizationStratalotTodoProfils;
  }
}

function organizationStratalotTodoNormalizeCompanyCommunication(
  organizationStratalotTodo: OrganizationStratalotTodoEntityState,
  companyCommunicationsToNormalize: { [id: number]: CompanyCommunicationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationStratalotTodo.companyCommunications?.length &&
    typeof organizationStratalotTodo.companyCommunications[0] !== 'number'
  ) {
    const idsCompanyCommunications: number[] = (
      organizationStratalotTodo.companyCommunications as CompanyCommunication[]
    ).map((companyCommunication: CompanyCommunication) => {
      if (!companyCommunicationsToNormalize[companyCommunication.idCompanyCommunication]) {
        companyCommunicationsToNormalize[companyCommunication.idCompanyCommunication] = {
          ...companyCommunication,
          organizationStratalotTodos: []
        };
      }
      (
        companyCommunicationsToNormalize[companyCommunication.idCompanyCommunication]
          .organizationStratalotTodos as number[]
      ).push(organizationStratalotTodo.idOrganizationStratalotTodo);
      return companyCommunication.idCompanyCommunication;
    });
    relations['companyCommunications'] = idsCompanyCommunications;
  }
}

function organizationStratalotTodoNormalizeCompanyCommunicationStratalotTodo(
  organizationStratalotTodo: OrganizationStratalotTodoEntityState,
  companyCommunicationStratalotTodosToNormalize: CompanyCommunicationStratalotTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationStratalotTodo.companyCommunicationStratalotTodos?.length &&
    typeof organizationStratalotTodo.companyCommunicationStratalotTodos[0] !== 'number'
  ) {
    const idsCompanyCommunicationStratalotTodos: number[] = [];
    companyCommunicationStratalotTodosToNormalize.push(
      ...(organizationStratalotTodo.companyCommunicationStratalotTodos as CompanyCommunicationStratalotTodo[]).map(
        (companyCommunicationStratalotTodo: CompanyCommunicationStratalotTodo) => {
          idsCompanyCommunicationStratalotTodos.push(
            companyCommunicationStratalotTodo.idCompanyCommunicationStratalotTodo
          );
          return {
            ...companyCommunicationStratalotTodo,
            organizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo
          };
        }
      )
    );
    relations['companyCommunicationStratalotTodos'] = idsCompanyCommunicationStratalotTodos;
  }
}

function organizationStratalotTodoNormalizeOrganization(
  organizationStratalotTodo: OrganizationStratalotTodoEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationStratalotTodo.organization && typeof organizationStratalotTodo.organization !== 'number') {
    if (!organizationsToNormalize[organizationStratalotTodo.organization.idOrganization]) {
      organizationsToNormalize[organizationStratalotTodo.organization.idOrganization] = {
        ...organizationStratalotTodo.organization,
        organizationStratalotTodos: []
      };
    }
    (
      organizationsToNormalize[organizationStratalotTodo.organization.idOrganization]
        .organizationStratalotTodos as number[]
    ).push(organizationStratalotTodo.idOrganizationStratalotTodo);
    relations['organization'] = organizationStratalotTodo.organization.idOrganization;
  }
}

function organizationStratalotTodoNormalizeDiagnostic(
  organizationStratalotTodo: OrganizationStratalotTodoEntityState,
  diagnosticsToNormalize: { [id: number]: DiagnosticEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationStratalotTodo.diagnostic && typeof organizationStratalotTodo.diagnostic !== 'number') {
    if (!diagnosticsToNormalize[organizationStratalotTodo.diagnostic.idDiagnostic]) {
      diagnosticsToNormalize[organizationStratalotTodo.diagnostic.idDiagnostic] = {
        ...organizationStratalotTodo.diagnostic,
        organizationStratalotTodos: []
      };
    }
    (
      diagnosticsToNormalize[organizationStratalotTodo.diagnostic.idDiagnostic].organizationStratalotTodos as number[]
    ).push(organizationStratalotTodo.idOrganizationStratalotTodo);
    relations['diagnostic'] = organizationStratalotTodo.diagnostic.idDiagnostic;
  }
}

/**
 *  OrganizationResidenceStudyTodo
 */
export function getActionsToNormalizeOrganizationResidenceStudyTodo(
  organizationResidenceStudyTodos: OrganizationResidenceStudyTodoEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const residenceStudyTodosToNormalize: ResidenceStudyTodoEntityState[] = [];

  const normalizedOrganizationResidenceStudyTodos = organizationResidenceStudyTodos.map(
    (organizationResidenceStudyTodo: OrganizationResidenceStudyTodoEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationResidenceStudyTodoNormalizeResidenceStudyTodo(
        organizationResidenceStudyTodo,
        residenceStudyTodosToNormalize,
        relations
      );
      organizationResidenceStudyTodoNormalizeOrganization(
        organizationResidenceStudyTodo,
        organizationsToNormalize,
        relations
      );

      return {
        ...organizationResidenceStudyTodo,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  if (residenceStudyTodosToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceStudyTodo(residenceStudyTodosToNormalize, actionType));
  }

  const organizationResidenceStudyTodoActionCreator =
    organizationResidenceStudyTodoActions.upsertManyOrganizationResidenceStudyTodosSuccess;
  actions.push(
    organizationResidenceStudyTodoActionCreator({
      organizationResidenceStudyTodos: normalizedOrganizationResidenceStudyTodos
    })
  );

  return actions;
}

function organizationResidenceStudyTodoNormalizeResidenceStudyTodo(
  organizationResidenceStudyTodo: OrganizationResidenceStudyTodoEntityState,
  residenceStudyTodosToNormalize: ResidenceStudyTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationResidenceStudyTodo.residenceStudyTodos?.length &&
    typeof organizationResidenceStudyTodo.residenceStudyTodos[0] !== 'number'
  ) {
    const idsResidenceStudyTodos: number[] = [];
    residenceStudyTodosToNormalize.push(
      ...(organizationResidenceStudyTodo.residenceStudyTodos as ResidenceStudyTodo[]).map(
        (residenceStudyTodo: ResidenceStudyTodo) => {
          idsResidenceStudyTodos.push(residenceStudyTodo.idResidenceStudyTodo);
          return {
            ...residenceStudyTodo,
            organizationResidenceStudyTodo: organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo
          };
        }
      )
    );
    relations['residenceStudyTodos'] = idsResidenceStudyTodos;
  }
}

function organizationResidenceStudyTodoNormalizeOrganization(
  organizationResidenceStudyTodo: OrganizationResidenceStudyTodoEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationResidenceStudyTodo.organization && typeof organizationResidenceStudyTodo.organization !== 'number') {
    if (!organizationsToNormalize[organizationResidenceStudyTodo.organization.idOrganization]) {
      organizationsToNormalize[organizationResidenceStudyTodo.organization.idOrganization] = {
        ...organizationResidenceStudyTodo.organization,
        organizationResidenceStudyTodos: []
      };
    }
    (
      organizationsToNormalize[organizationResidenceStudyTodo.organization.idOrganization]
        .organizationResidenceStudyTodos as number[]
    ).push(organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo);
    relations['organization'] = organizationResidenceStudyTodo.organization.idOrganization;
  }
}

/**
 *  OrganizationStratalotTodoRule
 */
export function getActionsToNormalizeOrganizationStratalotTodoRule(
  organizationStratalotTodoRules: OrganizationStratalotTodoRuleEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const organizationSaleCategoriesToNormalize: { [id: number]: OrganizationSaleCategoryEntityState } = {};
  const familySaleCategoriesToNormalize: { [id: number]: SaleCategoryFamilyEntityState } = {};
  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const companyStratalotTypesToNormalize: { [id: number]: CompanyStratalotTypeEntityState } = {};
  const stratalotTypesToNormalize: { [id: number]: StratalotTypeEntityState } = {};
  const organizationStratalotTodosToNormalize: { [id: number]: OrganizationStratalotTodoEntityState } = {};
  const organizationStratalotTodoAppliedRulesToNormalize: OrganizationStratalotTodoAppliedRuleEntityState[] = [];

  const normalizedOrganizationStratalotTodoRules = organizationStratalotTodoRules.map(
    (organizationStratalotTodoRule: OrganizationStratalotTodoRuleEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationStratalotTodoRuleNormalizeOrganizationStratalotTodo(
        organizationStratalotTodoRule,
        organizationStratalotTodosToNormalize,
        relations
      );
      organizationStratalotTodoRuleNormalizeOrganizationStratalotTodoAppliedRule(
        organizationStratalotTodoRule,
        organizationStratalotTodoAppliedRulesToNormalize,
        relations
      );
      organizationStratalotTodoRuleNormalizeOrganization(
        organizationStratalotTodoRule,
        organizationsToNormalize,
        relations
      );
      organizationStratalotTodoRuleNormalizeOrganizationSaleCategory(
        organizationStratalotTodoRule,
        organizationSaleCategoriesToNormalize,
        relations
      );
      organizationStratalotTodoRuleNormalizeSaleCategoryFamily(
        organizationStratalotTodoRule,
        familySaleCategoriesToNormalize,
        relations
      );
      organizationStratalotTodoRuleNormalizeCompany(organizationStratalotTodoRule, companiesToNormalize, relations);
      organizationStratalotTodoRuleNormalizeCompanyStratalotType(
        organizationStratalotTodoRule,
        companyStratalotTypesToNormalize,
        relations
      );
      organizationStratalotTodoRuleNormalizeStratalotType(
        organizationStratalotTodoRule,
        stratalotTypesToNormalize,
        relations
      );

      return {
        ...organizationStratalotTodoRule,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const organizationSaleCategoriesToNormalizeArray = Object.values(organizationSaleCategoriesToNormalize);
  if (organizationSaleCategoriesToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationSaleCategory(organizationSaleCategoriesToNormalizeArray, actionType)
    );
  }
  const familySaleCategoriesToNormalizeArray = Object.values(familySaleCategoriesToNormalize);
  if (familySaleCategoriesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSaleCategoryFamily(familySaleCategoriesToNormalizeArray, actionType));
  }
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  const companyStratalotTypesToNormalizeArray = Object.values(companyStratalotTypesToNormalize);
  if (companyStratalotTypesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyStratalotType(companyStratalotTypesToNormalizeArray, actionType));
  }
  const stratalotTypesToNormalizeArray = Object.values(stratalotTypesToNormalize);
  if (stratalotTypesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalotType(stratalotTypesToNormalizeArray, actionType));
  }
  const organizationStratalotTodosToNormalizeArray = Object.values(organizationStratalotTodosToNormalize);
  if (organizationStratalotTodosToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodo(organizationStratalotTodosToNormalizeArray, actionType)
    );
  }
  if (organizationStratalotTodoAppliedRulesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodoAppliedRule(
        organizationStratalotTodoAppliedRulesToNormalize,
        actionType
      )
    );
  }

  const organizationStratalotTodoRuleActionCreator =
    organizationStratalotTodoRuleActions.upsertManyOrganizationStratalotTodoRulesSuccess;
  actions.push(
    organizationStratalotTodoRuleActionCreator({
      organizationStratalotTodoRules: normalizedOrganizationStratalotTodoRules
    })
  );

  return actions;
}

function organizationStratalotTodoRuleNormalizeOrganizationStratalotTodo(
  organizationStratalotTodoRule: OrganizationStratalotTodoRuleEntityState,
  organizationStratalotTodosToNormalize: { [id: number]: OrganizationStratalotTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationStratalotTodoRule.organizationStratalotTodos?.length &&
    typeof organizationStratalotTodoRule.organizationStratalotTodos[0] !== 'number'
  ) {
    const idsOrganizationStratalotTodos: number[] = (
      organizationStratalotTodoRule.organizationStratalotTodos as OrganizationStratalotTodo[]
    ).map((organizationStratalotTodo: OrganizationStratalotTodo) => {
      if (!organizationStratalotTodosToNormalize[organizationStratalotTodo.idOrganizationStratalotTodo]) {
        organizationStratalotTodosToNormalize[organizationStratalotTodo.idOrganizationStratalotTodo] = {
          ...organizationStratalotTodo,
          organizationStratalotTodoRules: []
        };
      }
      (
        organizationStratalotTodosToNormalize[organizationStratalotTodo.idOrganizationStratalotTodo]
          .organizationStratalotTodoRules as number[]
      ).push(organizationStratalotTodoRule.idOrganizationStratalotTodoRule);
      return organizationStratalotTodo.idOrganizationStratalotTodo;
    });
    relations['organizationStratalotTodos'] = idsOrganizationStratalotTodos;
  }
}

function organizationStratalotTodoRuleNormalizeOrganizationStratalotTodoAppliedRule(
  organizationStratalotTodoRule: OrganizationStratalotTodoRuleEntityState,
  organizationStratalotTodoAppliedRulesToNormalize: OrganizationStratalotTodoAppliedRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationStratalotTodoRule.organizationStratalotTodoAppliedRules?.length &&
    typeof organizationStratalotTodoRule.organizationStratalotTodoAppliedRules[0] !== 'number'
  ) {
    const idsOrganizationStratalotTodoAppliedRules: number[] = [];
    organizationStratalotTodoAppliedRulesToNormalize.push(
      ...(
        organizationStratalotTodoRule.organizationStratalotTodoAppliedRules as OrganizationStratalotTodoAppliedRule[]
      ).map((organizationStratalotTodoAppliedRule: OrganizationStratalotTodoAppliedRule) => {
        idsOrganizationStratalotTodoAppliedRules.push(
          organizationStratalotTodoAppliedRule.idOrganizationStratalotTodoAppliedRule
        );
        return {
          ...organizationStratalotTodoAppliedRule,
          organizationStratalotTodoRule: organizationStratalotTodoRule.idOrganizationStratalotTodoRule
        };
      })
    );
    relations['organizationStratalotTodoAppliedRules'] = idsOrganizationStratalotTodoAppliedRules;
  }
}

function organizationStratalotTodoRuleNormalizeOrganization(
  organizationStratalotTodoRule: OrganizationStratalotTodoRuleEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationStratalotTodoRule.organization && typeof organizationStratalotTodoRule.organization !== 'number') {
    if (!organizationsToNormalize[organizationStratalotTodoRule.organization.idOrganization]) {
      organizationsToNormalize[organizationStratalotTodoRule.organization.idOrganization] = {
        ...organizationStratalotTodoRule.organization,
        organizationStratalotTodoRules: []
      };
    }
    (
      organizationsToNormalize[organizationStratalotTodoRule.organization.idOrganization]
        .organizationStratalotTodoRules as number[]
    ).push(organizationStratalotTodoRule.idOrganizationStratalotTodoRule);
    relations['organization'] = organizationStratalotTodoRule.organization.idOrganization;
  }
}

function organizationStratalotTodoRuleNormalizeOrganizationSaleCategory(
  organizationStratalotTodoRule: OrganizationStratalotTodoRuleEntityState,
  organizationSaleCategoriesToNormalize: { [id: number]: OrganizationSaleCategoryEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationStratalotTodoRule.organizationSaleCategory &&
    typeof organizationStratalotTodoRule.organizationSaleCategory !== 'number'
  ) {
    if (
      !organizationSaleCategoriesToNormalize[
        organizationStratalotTodoRule.organizationSaleCategory.idOrganizationSaleCategory
      ]
    ) {
      organizationSaleCategoriesToNormalize[
        organizationStratalotTodoRule.organizationSaleCategory.idOrganizationSaleCategory
      ] = { ...organizationStratalotTodoRule.organizationSaleCategory, organizationStratalotTodoRules: [] };
    }
    (
      organizationSaleCategoriesToNormalize[
        organizationStratalotTodoRule.organizationSaleCategory.idOrganizationSaleCategory
      ].organizationStratalotTodoRules as number[]
    ).push(organizationStratalotTodoRule.idOrganizationStratalotTodoRule);
    relations['organizationSaleCategory'] =
      organizationStratalotTodoRule.organizationSaleCategory.idOrganizationSaleCategory;
  }
}

function organizationStratalotTodoRuleNormalizeSaleCategoryFamily(
  organizationStratalotTodoRule: OrganizationStratalotTodoRuleEntityState,
  familySaleCategoriesToNormalize: { [id: number]: SaleCategoryFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationStratalotTodoRule.saleCategoryFamily &&
    typeof organizationStratalotTodoRule.saleCategoryFamily !== 'number'
  ) {
    if (!familySaleCategoriesToNormalize[organizationStratalotTodoRule.saleCategoryFamily.idSaleCategoryFamily]) {
      familySaleCategoriesToNormalize[organizationStratalotTodoRule.saleCategoryFamily.idSaleCategoryFamily] = {
        ...organizationStratalotTodoRule.saleCategoryFamily,
        organizationStratalotTodoRules: []
      };
    }
    (
      familySaleCategoriesToNormalize[organizationStratalotTodoRule.saleCategoryFamily.idSaleCategoryFamily]
        .organizationStratalotTodoRules as number[]
    ).push(organizationStratalotTodoRule.idOrganizationStratalotTodoRule);
    relations['saleCategoryFamily'] = organizationStratalotTodoRule.saleCategoryFamily.idSaleCategoryFamily;
  }
}

function organizationStratalotTodoRuleNormalizeCompany(
  organizationStratalotTodoRule: OrganizationStratalotTodoRuleEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationStratalotTodoRule.company && typeof organizationStratalotTodoRule.company !== 'number') {
    if (!companiesToNormalize[organizationStratalotTodoRule.company.idCompany]) {
      companiesToNormalize[organizationStratalotTodoRule.company.idCompany] = {
        ...organizationStratalotTodoRule.company,
        organizationStratalotTodoRules: []
      };
    }
    (
      companiesToNormalize[organizationStratalotTodoRule.company.idCompany].organizationStratalotTodoRules as number[]
    ).push(organizationStratalotTodoRule.idOrganizationStratalotTodoRule);
    relations['company'] = organizationStratalotTodoRule.company.idCompany;
  }
}

function organizationStratalotTodoRuleNormalizeCompanyStratalotType(
  organizationStratalotTodoRule: OrganizationStratalotTodoRuleEntityState,
  companyStratalotTypesToNormalize: { [id: number]: CompanyStratalotTypeEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationStratalotTodoRule.companyStratalotType &&
    typeof organizationStratalotTodoRule.companyStratalotType !== 'number'
  ) {
    if (!companyStratalotTypesToNormalize[organizationStratalotTodoRule.companyStratalotType.idCompanyStratalotType]) {
      companyStratalotTypesToNormalize[organizationStratalotTodoRule.companyStratalotType.idCompanyStratalotType] = {
        ...organizationStratalotTodoRule.companyStratalotType,
        organizationStratalotTodoRules: []
      };
    }
    (
      companyStratalotTypesToNormalize[organizationStratalotTodoRule.companyStratalotType.idCompanyStratalotType]
        .organizationStratalotTodoRules as number[]
    ).push(organizationStratalotTodoRule.idOrganizationStratalotTodoRule);
    relations['companyStratalotType'] = organizationStratalotTodoRule.companyStratalotType.idCompanyStratalotType;
  }
}

function organizationStratalotTodoRuleNormalizeStratalotType(
  organizationStratalotTodoRule: OrganizationStratalotTodoRuleEntityState,
  stratalotTypesToNormalize: { [id: number]: StratalotTypeEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationStratalotTodoRule.stratalotType && typeof organizationStratalotTodoRule.stratalotType !== 'number') {
    if (!stratalotTypesToNormalize[organizationStratalotTodoRule.stratalotType.idStratalotType]) {
      stratalotTypesToNormalize[organizationStratalotTodoRule.stratalotType.idStratalotType] = {
        ...organizationStratalotTodoRule.stratalotType,
        organizationStratalotTodoRules: []
      };
    }
    (
      stratalotTypesToNormalize[organizationStratalotTodoRule.stratalotType.idStratalotType]
        .organizationStratalotTodoRules as number[]
    ).push(organizationStratalotTodoRule.idOrganizationStratalotTodoRule);
    relations['stratalotType'] = organizationStratalotTodoRule.stratalotType.idStratalotType;
  }
}

/**
 *  OrganizationStratalotTodoAppliedRule
 */
export function getActionsToNormalizeOrganizationStratalotTodoAppliedRule(
  organizationStratalotTodoAppliedRules: OrganizationStratalotTodoAppliedRuleEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationStratalotTodosToNormalize: { [id: number]: OrganizationStratalotTodoEntityState } = {};
  const organizationStratalotTodoRulesToNormalize: { [id: number]: OrganizationStratalotTodoRuleEntityState } = {};

  const normalizedOrganizationStratalotTodoAppliedRules = organizationStratalotTodoAppliedRules.map(
    (organizationStratalotTodoAppliedRule: OrganizationStratalotTodoAppliedRuleEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationStratalotTodoAppliedRuleNormalizeOrganizationStratalotTodo(
        organizationStratalotTodoAppliedRule,
        organizationStratalotTodosToNormalize,
        relations
      );
      organizationStratalotTodoAppliedRuleNormalizeOrganizationStratalotTodoRule(
        organizationStratalotTodoAppliedRule,
        organizationStratalotTodoRulesToNormalize,
        relations
      );

      return {
        ...organizationStratalotTodoAppliedRule,
        ...relations
      };
    }
  );
  const organizationStratalotTodosToNormalizeArray = Object.values(organizationStratalotTodosToNormalize);
  if (organizationStratalotTodosToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodo(organizationStratalotTodosToNormalizeArray, actionType)
    );
  }
  const organizationStratalotTodoRulesToNormalizeArray = Object.values(organizationStratalotTodoRulesToNormalize);
  if (organizationStratalotTodoRulesToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodoRule(organizationStratalotTodoRulesToNormalizeArray, actionType)
    );
  }

  const organizationStratalotTodoAppliedRuleActionCreator =
    organizationStratalotTodoAppliedRuleActions.upsertManyOrganizationStratalotTodoAppliedRulesSuccess;
  actions.push(
    organizationStratalotTodoAppliedRuleActionCreator({
      organizationStratalotTodoAppliedRules: normalizedOrganizationStratalotTodoAppliedRules
    })
  );

  return actions;
}

function organizationStratalotTodoAppliedRuleNormalizeOrganizationStratalotTodo(
  organizationStratalotTodoAppliedRule: OrganizationStratalotTodoAppliedRuleEntityState,
  organizationStratalotTodosToNormalize: { [id: number]: OrganizationStratalotTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationStratalotTodoAppliedRule.organizationStratalotTodo &&
    typeof organizationStratalotTodoAppliedRule.organizationStratalotTodo !== 'number'
  ) {
    if (
      !organizationStratalotTodosToNormalize[
        organizationStratalotTodoAppliedRule.organizationStratalotTodo.idOrganizationStratalotTodo
      ]
    ) {
      organizationStratalotTodosToNormalize[
        organizationStratalotTodoAppliedRule.organizationStratalotTodo.idOrganizationStratalotTodo
      ] = {
        ...organizationStratalotTodoAppliedRule.organizationStratalotTodo,
        organizationStratalotTodoAppliedRules: []
      };
    }
    (
      organizationStratalotTodosToNormalize[
        organizationStratalotTodoAppliedRule.organizationStratalotTodo.idOrganizationStratalotTodo
      ].organizationStratalotTodoAppliedRules as number[]
    ).push(organizationStratalotTodoAppliedRule.idOrganizationStratalotTodoAppliedRule);
    relations['organizationStratalotTodo'] =
      organizationStratalotTodoAppliedRule.organizationStratalotTodo.idOrganizationStratalotTodo;
  }
}

function organizationStratalotTodoAppliedRuleNormalizeOrganizationStratalotTodoRule(
  organizationStratalotTodoAppliedRule: OrganizationStratalotTodoAppliedRuleEntityState,
  organizationStratalotTodoRulesToNormalize: { [id: number]: OrganizationStratalotTodoRuleEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationStratalotTodoAppliedRule.organizationStratalotTodoRule &&
    typeof organizationStratalotTodoAppliedRule.organizationStratalotTodoRule !== 'number'
  ) {
    if (
      !organizationStratalotTodoRulesToNormalize[
        organizationStratalotTodoAppliedRule.organizationStratalotTodoRule.idOrganizationStratalotTodoRule
      ]
    ) {
      organizationStratalotTodoRulesToNormalize[
        organizationStratalotTodoAppliedRule.organizationStratalotTodoRule.idOrganizationStratalotTodoRule
      ] = {
        ...organizationStratalotTodoAppliedRule.organizationStratalotTodoRule,
        organizationStratalotTodoAppliedRules: []
      };
    }
    (
      organizationStratalotTodoRulesToNormalize[
        organizationStratalotTodoAppliedRule.organizationStratalotTodoRule.idOrganizationStratalotTodoRule
      ].organizationStratalotTodoAppliedRules as number[]
    ).push(organizationStratalotTodoAppliedRule.idOrganizationStratalotTodoAppliedRule);
    relations['organizationStratalotTodoRule'] =
      organizationStratalotTodoAppliedRule.organizationStratalotTodoRule.idOrganizationStratalotTodoRule;
  }
}

/**
 *  OrganizationSociete
 */
export function getActionsToNormalizeOrganizationSociete(
  organizationSocietes: OrganizationSocieteEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const organizationThirdPartiesToNormalize: OrganizationThirdPartyEntityState[] = [];

  const normalizedOrganizationSocietes = organizationSocietes.map(
    (organizationSociete: OrganizationSocieteEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationSocieteNormalizeOrganizationThirdParty(
        organizationSociete,
        organizationThirdPartiesToNormalize,
        relations
      );
      organizationSocieteNormalizeOrganization(organizationSociete, organizationsToNormalize, relations);

      return {
        ...organizationSociete,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  if (organizationThirdPartiesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationThirdParty(organizationThirdPartiesToNormalize, actionType));
  }

  const organizationSocieteActionCreator = organizationSocieteActions.upsertManyOrganizationSocietesSuccess;
  actions.push(organizationSocieteActionCreator({ organizationSocietes: normalizedOrganizationSocietes }));

  return actions;
}

function organizationSocieteNormalizeOrganizationThirdParty(
  organizationSociete: OrganizationSocieteEntityState,
  organizationThirdPartiesToNormalize: OrganizationThirdPartyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationSociete.organizationThirdParties?.length &&
    typeof organizationSociete.organizationThirdParties[0] !== 'number'
  ) {
    const idsOrganizationThirdParties: number[] = [];
    organizationThirdPartiesToNormalize.push(
      ...(organizationSociete.organizationThirdParties as OrganizationThirdParty[]).map(
        (organizationThirdParty: OrganizationThirdParty) => {
          idsOrganizationThirdParties.push(organizationThirdParty.idOrganizationThirdParty);
          return { ...organizationThirdParty, organizationSociete: organizationSociete.idOrganizationSociete };
        }
      )
    );
    relations['organizationThirdParties'] = idsOrganizationThirdParties;
  }
}

function organizationSocieteNormalizeOrganization(
  organizationSociete: OrganizationSocieteEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationSociete.organization && typeof organizationSociete.organization !== 'number') {
    if (!organizationsToNormalize[organizationSociete.organization.idOrganization]) {
      organizationsToNormalize[organizationSociete.organization.idOrganization] = {
        ...organizationSociete.organization,
        organizationSocietes: []
      };
    }
    (organizationsToNormalize[organizationSociete.organization.idOrganization].organizationSocietes as number[]).push(
      organizationSociete.idOrganizationSociete
    );
    relations['organization'] = organizationSociete.organization.idOrganization;
  }
}

/**
 *  CompanyStratalotType
 */
export function getActionsToNormalizeCompanyStratalotType(
  companyStratalotTypes: CompanyStratalotTypeEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const stratalotTypesToNormalize: { [id: number]: StratalotTypeEntityState } = {};
  const stratalotsToNormalize: StratalotEntityState[] = [];
  const stratalotPriceListsToNormalize: StratalotPriceListEntityState[] = [];
  const organizationLeadTodoRulesToNormalize: OrganizationLeadTodoRuleEntityState[] = [];
  const organizationStratalotTodoRulesToNormalize: OrganizationStratalotTodoRuleEntityState[] = [];

  const normalizedCompanyStratalotTypes = companyStratalotTypes.map(
    (companyStratalotType: CompanyStratalotTypeEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      companyStratalotTypeNormalizeStratalot(companyStratalotType, stratalotsToNormalize, relations);
      companyStratalotTypeNormalizeStratalotPriceList(companyStratalotType, stratalotPriceListsToNormalize, relations);
      companyStratalotTypeNormalizeOrganizationLeadTodoRule(
        companyStratalotType,
        organizationLeadTodoRulesToNormalize,
        relations
      );
      companyStratalotTypeNormalizeOrganizationStratalotTodoRule(
        companyStratalotType,
        organizationStratalotTodoRulesToNormalize,
        relations
      );
      companyStratalotTypeNormalizeCompany(companyStratalotType, companiesToNormalize, relations);
      companyStratalotTypeNormalizeStratalotType(companyStratalotType, stratalotTypesToNormalize, relations);

      return {
        ...companyStratalotType,
        ...relations
      };
    }
  );
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  const stratalotTypesToNormalizeArray = Object.values(stratalotTypesToNormalize);
  if (stratalotTypesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalotType(stratalotTypesToNormalizeArray, actionType));
  }
  if (stratalotsToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalize, actionType));
  }
  if (stratalotPriceListsToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalotPriceList(stratalotPriceListsToNormalize, actionType));
  }
  if (organizationLeadTodoRulesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationLeadTodoRule(organizationLeadTodoRulesToNormalize, actionType));
  }
  if (organizationStratalotTodoRulesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodoRule(organizationStratalotTodoRulesToNormalize, actionType)
    );
  }

  const companyStratalotTypeActionCreator = companyStratalotTypeActions.upsertManyCompanyStratalotTypesSuccess;
  actions.push(companyStratalotTypeActionCreator({ companyStratalotTypes: normalizedCompanyStratalotTypes }));

  return actions;
}

function companyStratalotTypeNormalizeStratalot(
  companyStratalotType: CompanyStratalotTypeEntityState,
  stratalotsToNormalize: StratalotEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (companyStratalotType.stratalots?.length && typeof companyStratalotType.stratalots[0] !== 'number') {
    const idsStratalots: number[] = [];
    stratalotsToNormalize.push(
      ...(companyStratalotType.stratalots as Stratalot[]).map((stratalot: Stratalot) => {
        idsStratalots.push(stratalot.idStratalot);
        return { ...stratalot, companyStratalotType: companyStratalotType.idCompanyStratalotType };
      })
    );
    relations['stratalots'] = idsStratalots;
  }
}

function companyStratalotTypeNormalizeStratalotPriceList(
  companyStratalotType: CompanyStratalotTypeEntityState,
  stratalotPriceListsToNormalize: StratalotPriceListEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyStratalotType.stratalotPriceLists?.length &&
    typeof companyStratalotType.stratalotPriceLists[0] !== 'number'
  ) {
    const idsStratalotPriceLists: number[] = [];
    stratalotPriceListsToNormalize.push(
      ...(companyStratalotType.stratalotPriceLists as StratalotPriceList[]).map(
        (stratalotPriceList: StratalotPriceList) => {
          idsStratalotPriceLists.push(stratalotPriceList.idStratalotPriceList);
          return { ...stratalotPriceList, companyStratalotType: companyStratalotType.idCompanyStratalotType };
        }
      )
    );
    relations['stratalotPriceLists'] = idsStratalotPriceLists;
  }
}

function companyStratalotTypeNormalizeOrganizationLeadTodoRule(
  companyStratalotType: CompanyStratalotTypeEntityState,
  organizationLeadTodoRulesToNormalize: OrganizationLeadTodoRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyStratalotType.organizationLeadTodoRules?.length &&
    typeof companyStratalotType.organizationLeadTodoRules[0] !== 'number'
  ) {
    const idsOrganizationLeadTodoRules: number[] = [];
    organizationLeadTodoRulesToNormalize.push(
      ...(companyStratalotType.organizationLeadTodoRules as OrganizationLeadTodoRule[]).map(
        (organizationLeadTodoRule: OrganizationLeadTodoRule) => {
          idsOrganizationLeadTodoRules.push(organizationLeadTodoRule.idOrganizationLeadTodoRule);
          return { ...organizationLeadTodoRule, companyStratalotType: companyStratalotType.idCompanyStratalotType };
        }
      )
    );
    relations['organizationLeadTodoRules'] = idsOrganizationLeadTodoRules;
  }
}

function companyStratalotTypeNormalizeOrganizationStratalotTodoRule(
  companyStratalotType: CompanyStratalotTypeEntityState,
  organizationStratalotTodoRulesToNormalize: OrganizationStratalotTodoRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    companyStratalotType.organizationStratalotTodoRules?.length &&
    typeof companyStratalotType.organizationStratalotTodoRules[0] !== 'number'
  ) {
    const idsOrganizationStratalotTodoRules: number[] = [];
    organizationStratalotTodoRulesToNormalize.push(
      ...(companyStratalotType.organizationStratalotTodoRules as OrganizationStratalotTodoRule[]).map(
        (organizationStratalotTodoRule: OrganizationStratalotTodoRule) => {
          idsOrganizationStratalotTodoRules.push(organizationStratalotTodoRule.idOrganizationStratalotTodoRule);
          return {
            ...organizationStratalotTodoRule,
            companyStratalotType: companyStratalotType.idCompanyStratalotType
          };
        }
      )
    );
    relations['organizationStratalotTodoRules'] = idsOrganizationStratalotTodoRules;
  }
}

function companyStratalotTypeNormalizeCompany(
  companyStratalotType: CompanyStratalotTypeEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyStratalotType.company && typeof companyStratalotType.company !== 'number') {
    if (!companiesToNormalize[companyStratalotType.company.idCompany]) {
      companiesToNormalize[companyStratalotType.company.idCompany] = {
        ...companyStratalotType.company,
        companyStratalotTypes: []
      };
    }
    (companiesToNormalize[companyStratalotType.company.idCompany].companyStratalotTypes as number[]).push(
      companyStratalotType.idCompanyStratalotType
    );
    relations['company'] = companyStratalotType.company.idCompany;
  }
}

function companyStratalotTypeNormalizeStratalotType(
  companyStratalotType: CompanyStratalotTypeEntityState,
  stratalotTypesToNormalize: { [id: number]: StratalotTypeEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (companyStratalotType.stratalotType && typeof companyStratalotType.stratalotType !== 'number') {
    if (!stratalotTypesToNormalize[companyStratalotType.stratalotType.idStratalotType]) {
      stratalotTypesToNormalize[companyStratalotType.stratalotType.idStratalotType] = {
        ...companyStratalotType.stratalotType,
        companyStratalotTypes: []
      };
    }
    (
      stratalotTypesToNormalize[companyStratalotType.stratalotType.idStratalotType].companyStratalotTypes as number[]
    ).push(companyStratalotType.idCompanyStratalotType);
    relations['stratalotType'] = companyStratalotType.stratalotType.idStratalotType;
  }
}

/**
 *  OrganizationThirdParty
 */
export function getActionsToNormalizeOrganizationThirdParty(
  organizationThirdParties: OrganizationThirdPartyEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const organizationSocietesToNormalize: { [id: number]: OrganizationSocieteEntityState } = {};
  const organizationMetiersToNormalize: { [id: number]: OrganizationMetierEntityState } = {};
  const residenceContactsToNormalize: ResidenceContactEntityState[] = [];

  const normalizedOrganizationThirdParties = organizationThirdParties.map(
    (organizationThirdParty: OrganizationThirdPartyEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationThirdPartyNormalizeResidenceContact(organizationThirdParty, residenceContactsToNormalize, relations);
      organizationThirdPartyNormalizeOrganization(organizationThirdParty, organizationsToNormalize, relations);
      organizationThirdPartyNormalizeOrganizationSociete(
        organizationThirdParty,
        organizationSocietesToNormalize,
        relations
      );
      organizationThirdPartyNormalizeOrganizationMetier(
        organizationThirdParty,
        organizationMetiersToNormalize,
        relations
      );

      return {
        ...organizationThirdParty,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const organizationSocietesToNormalizeArray = Object.values(organizationSocietesToNormalize);
  if (organizationSocietesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationSociete(organizationSocietesToNormalizeArray, actionType));
  }
  const organizationMetiersToNormalizeArray = Object.values(organizationMetiersToNormalize);
  if (organizationMetiersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationMetier(organizationMetiersToNormalizeArray, actionType));
  }
  if (residenceContactsToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceContact(residenceContactsToNormalize, actionType));
  }

  const organizationThirdPartyActionCreator = organizationThirdPartyActions.upsertManyOrganizationThirdPartiesSuccess;
  actions.push(organizationThirdPartyActionCreator({ organizationThirdParties: normalizedOrganizationThirdParties }));

  return actions;
}

function organizationThirdPartyNormalizeResidenceContact(
  organizationThirdParty: OrganizationThirdPartyEntityState,
  residenceContactsToNormalize: ResidenceContactEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    organizationThirdParty.residenceContacts?.length &&
    typeof organizationThirdParty.residenceContacts[0] !== 'number'
  ) {
    const idsResidenceContacts: number[] = [];
    residenceContactsToNormalize.push(
      ...(organizationThirdParty.residenceContacts as ResidenceContact[]).map((residenceContact: ResidenceContact) => {
        idsResidenceContacts.push(residenceContact.idResidenceContact);
        return { ...residenceContact, organizationThirdParty: organizationThirdParty.idOrganizationThirdParty };
      })
    );
    relations['residenceContacts'] = idsResidenceContacts;
  }
}

function organizationThirdPartyNormalizeOrganization(
  organizationThirdParty: OrganizationThirdPartyEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationThirdParty.organization && typeof organizationThirdParty.organization !== 'number') {
    if (!organizationsToNormalize[organizationThirdParty.organization.idOrganization]) {
      organizationsToNormalize[organizationThirdParty.organization.idOrganization] = {
        ...organizationThirdParty.organization,
        organizationThirdParties: []
      };
    }
    (
      organizationsToNormalize[organizationThirdParty.organization.idOrganization].organizationThirdParties as number[]
    ).push(organizationThirdParty.idOrganizationThirdParty);
    relations['organization'] = organizationThirdParty.organization.idOrganization;
  }
}

function organizationThirdPartyNormalizeOrganizationSociete(
  organizationThirdParty: OrganizationThirdPartyEntityState,
  organizationSocietesToNormalize: { [id: number]: OrganizationSocieteEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationThirdParty.organizationSociete && typeof organizationThirdParty.organizationSociete !== 'number') {
    if (!organizationSocietesToNormalize[organizationThirdParty.organizationSociete.idOrganizationSociete]) {
      organizationSocietesToNormalize[organizationThirdParty.organizationSociete.idOrganizationSociete] = {
        ...organizationThirdParty.organizationSociete,
        organizationThirdParties: []
      };
    }
    (
      organizationSocietesToNormalize[organizationThirdParty.organizationSociete.idOrganizationSociete]
        .organizationThirdParties as number[]
    ).push(organizationThirdParty.idOrganizationThirdParty);
    relations['organizationSociete'] = organizationThirdParty.organizationSociete.idOrganizationSociete;
  }
}

function organizationThirdPartyNormalizeOrganizationMetier(
  organizationThirdParty: OrganizationThirdPartyEntityState,
  organizationMetiersToNormalize: { [id: number]: OrganizationMetierEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationThirdParty.organizationMetier && typeof organizationThirdParty.organizationMetier !== 'number') {
    if (!organizationMetiersToNormalize[organizationThirdParty.organizationMetier.idOrganizationMetier]) {
      organizationMetiersToNormalize[organizationThirdParty.organizationMetier.idOrganizationMetier] = {
        ...organizationThirdParty.organizationMetier,
        organizationThirdParties: []
      };
    }
    (
      organizationMetiersToNormalize[organizationThirdParty.organizationMetier.idOrganizationMetier]
        .organizationThirdParties as number[]
    ).push(organizationThirdParty.idOrganizationThirdParty);
    relations['organizationMetier'] = organizationThirdParty.organizationMetier.idOrganizationMetier;
  }
}

/**
 *  OrganizationProspectOrigin
 */
export function getActionsToNormalizeOrganizationProspectOrigin(
  organizationProspectOrigins: OrganizationProspectOriginEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const prospectsToNormalize: ProspectEntityState[] = [];

  const normalizedOrganizationProspectOrigins = organizationProspectOrigins.map(
    (organizationProspectOrigin: OrganizationProspectOriginEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationProspectOriginNormalizeProspect(organizationProspectOrigin, prospectsToNormalize, relations);
      organizationProspectOriginNormalizeOrganization(organizationProspectOrigin, organizationsToNormalize, relations);

      return {
        ...organizationProspectOrigin,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  if (prospectsToNormalize.length) {
    actions.push(...getActionsToNormalizeProspect(prospectsToNormalize, actionType));
  }

  const organizationProspectOriginActionCreator =
    organizationProspectOriginActions.upsertManyOrganizationProspectOriginsSuccess;
  actions.push(
    organizationProspectOriginActionCreator({ organizationProspectOrigins: normalizedOrganizationProspectOrigins })
  );

  return actions;
}

function organizationProspectOriginNormalizeProspect(
  organizationProspectOrigin: OrganizationProspectOriginEntityState,
  prospectsToNormalize: ProspectEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationProspectOrigin.prospects?.length && typeof organizationProspectOrigin.prospects[0] !== 'number') {
    const idsProspects: number[] = [];
    prospectsToNormalize.push(
      ...(organizationProspectOrigin.prospects as Prospect[]).map((prospect: Prospect) => {
        idsProspects.push(prospect.idProspect);
        return { ...prospect, organizationProspectOrigin: organizationProspectOrigin.idOrganizationProspectOrigin };
      })
    );
    relations['prospects'] = idsProspects;
  }
}

function organizationProspectOriginNormalizeOrganization(
  organizationProspectOrigin: OrganizationProspectOriginEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationProspectOrigin.organization && typeof organizationProspectOrigin.organization !== 'number') {
    if (!organizationsToNormalize[organizationProspectOrigin.organization.idOrganization]) {
      organizationsToNormalize[organizationProspectOrigin.organization.idOrganization] = {
        ...organizationProspectOrigin.organization,
        organizationProspectOrigins: []
      };
    }
    (
      organizationsToNormalize[organizationProspectOrigin.organization.idOrganization]
        .organizationProspectOrigins as number[]
    ).push(organizationProspectOrigin.idOrganizationProspectOrigin);
    relations['organization'] = organizationProspectOrigin.organization.idOrganization;
  }
}

/**
 *  Prospect
 */
export function getActionsToNormalizeProspect(prospects: ProspectEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const occupantsToNormalize: { [id: number]: OccupantEntityState } = {};
  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const organizationProspectOriginsToNormalize: { [id: number]: OrganizationProspectOriginEntityState } = {};
  const responsableToNormalize: { [id: number]: UserEntityState } = {};
  const updatedUserToNormalize: { [id: number]: UserEntityState } = {};
  const createdUserToNormalize: { [id: number]: UserEntityState } = {};
  const prospectEventsToNormalize: ProspectEventEntityState[] = [];
  const prospectBuyingWishsToNormalize: ProspectBuyingWishEntityState[] = [];
  const leadsToNormalize: LeadEntityState[] = [];

  const normalizedProspects = prospects.map((prospect: ProspectEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    prospectNormalizeProspectEvent(prospect, prospectEventsToNormalize, relations);
    prospectNormalizeProspectBuyingWish(prospect, prospectBuyingWishsToNormalize, relations);
    prospectNormalizeLead(prospect, leadsToNormalize, relations);
    prospectNormalizeOccupant(prospect, occupantsToNormalize, relations);
    prospectNormalizeOrganization(prospect, organizationsToNormalize, relations);
    prospectNormalizeOrganizationProspectOrigin(prospect, organizationProspectOriginsToNormalize, relations);
    prospectNormalizeResponsable(prospect, responsableToNormalize, relations);
    prospectNormalizeUpdatedUser(prospect, updatedUserToNormalize, relations);
    prospectNormalizeCreatedUser(prospect, createdUserToNormalize, relations);

    return {
      ...prospect,
      ...relations
    };
  });
  const occupantsToNormalizeArray = Object.values(occupantsToNormalize);
  if (occupantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOccupant(occupantsToNormalizeArray, actionType));
  }
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const organizationProspectOriginsToNormalizeArray = Object.values(organizationProspectOriginsToNormalize);
  if (organizationProspectOriginsToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationProspectOrigin(organizationProspectOriginsToNormalizeArray, actionType)
    );
  }
  const responsableToNormalizeArray = Object.values(responsableToNormalize);
  if (responsableToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(responsableToNormalizeArray, actionType));
  }
  const updatedUserToNormalizeArray = Object.values(updatedUserToNormalize);
  if (updatedUserToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(updatedUserToNormalizeArray, actionType));
  }
  const createdUserToNormalizeArray = Object.values(createdUserToNormalize);
  if (createdUserToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(createdUserToNormalizeArray, actionType));
  }
  if (prospectEventsToNormalize.length) {
    actions.push(...getActionsToNormalizeProspectEvent(prospectEventsToNormalize, actionType));
  }
  if (prospectBuyingWishsToNormalize.length) {
    actions.push(...getActionsToNormalizeProspectBuyingWish(prospectBuyingWishsToNormalize, actionType));
  }
  if (leadsToNormalize.length) {
    actions.push(...getActionsToNormalizeLead(leadsToNormalize, actionType));
  }

  const prospectActionCreator = prospectActions.upsertManyProspectsSuccess;
  actions.push(prospectActionCreator({ prospects: normalizedProspects }));

  return actions;
}

function prospectNormalizeProspectEvent(
  prospect: ProspectEntityState,
  prospectEventsToNormalize: ProspectEventEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (prospect.prospectEvents?.length && typeof prospect.prospectEvents[0] !== 'number') {
    const idsProspectEvents: number[] = [];
    prospectEventsToNormalize.push(
      ...(prospect.prospectEvents as ProspectEvent[]).map((prospectEvent: ProspectEvent) => {
        idsProspectEvents.push(prospectEvent.idProspectEvent);
        return { ...prospectEvent, prospect: prospect.idProspect };
      })
    );
    relations['prospectEvents'] = idsProspectEvents;
  }
}

function prospectNormalizeProspectBuyingWish(
  prospect: ProspectEntityState,
  prospectBuyingWishsToNormalize: ProspectBuyingWishEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (prospect.prospectBuyingWishs?.length && typeof prospect.prospectBuyingWishs[0] !== 'number') {
    const idsProspectBuyingWishs: number[] = [];
    prospectBuyingWishsToNormalize.push(
      ...(prospect.prospectBuyingWishs as ProspectBuyingWish[]).map((prospectBuyingWish: ProspectBuyingWish) => {
        idsProspectBuyingWishs.push(prospectBuyingWish.idProspectBuyingWish);
        return { ...prospectBuyingWish, prospect: prospect.idProspect };
      })
    );
    relations['prospectBuyingWishs'] = idsProspectBuyingWishs;
  }
}

function prospectNormalizeLead(
  prospect: ProspectEntityState,
  leadsToNormalize: LeadEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (prospect.leads?.length && typeof prospect.leads[0] !== 'number') {
    const idsLeads: number[] = [];
    leadsToNormalize.push(
      ...(prospect.leads as Lead[]).map((lead: Lead) => {
        idsLeads.push(lead.idLead);
        return { ...lead, prospect: prospect.idProspect };
      })
    );
    relations['leads'] = idsLeads;
  }
}

function prospectNormalizeOccupant(
  prospect: ProspectEntityState,
  occupantsToNormalize: { [id: number]: OccupantEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (prospect.occupant && typeof prospect.occupant !== 'number') {
    if (!occupantsToNormalize[prospect.occupant.idOccupant]) {
      occupantsToNormalize[prospect.occupant.idOccupant] = { ...prospect.occupant, prospects: [] };
    }
    (occupantsToNormalize[prospect.occupant.idOccupant].prospects as number[]).push(prospect.idProspect);
    relations['occupant'] = prospect.occupant.idOccupant;
  }
}

function prospectNormalizeOrganization(
  prospect: ProspectEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (prospect.organization && typeof prospect.organization !== 'number') {
    if (!organizationsToNormalize[prospect.organization.idOrganization]) {
      organizationsToNormalize[prospect.organization.idOrganization] = { ...prospect.organization, prospects: [] };
    }
    (organizationsToNormalize[prospect.organization.idOrganization].prospects as number[]).push(prospect.idProspect);
    relations['organization'] = prospect.organization.idOrganization;
  }
}

function prospectNormalizeOrganizationProspectOrigin(
  prospect: ProspectEntityState,
  organizationProspectOriginsToNormalize: { [id: number]: OrganizationProspectOriginEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (prospect.organizationProspectOrigin && typeof prospect.organizationProspectOrigin !== 'number') {
    if (!organizationProspectOriginsToNormalize[prospect.organizationProspectOrigin.idOrganizationProspectOrigin]) {
      organizationProspectOriginsToNormalize[prospect.organizationProspectOrigin.idOrganizationProspectOrigin] = {
        ...prospect.organizationProspectOrigin,
        prospects: []
      };
    }
    (
      organizationProspectOriginsToNormalize[prospect.organizationProspectOrigin.idOrganizationProspectOrigin]
        .prospects as number[]
    ).push(prospect.idProspect);
    relations['organizationProspectOrigin'] = prospect.organizationProspectOrigin.idOrganizationProspectOrigin;
  }
}

function prospectNormalizeResponsable(
  prospect: ProspectEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (prospect.responsable && typeof prospect.responsable !== 'number') {
    if (!usersToNormalize[prospect.responsable.idUser]) {
      usersToNormalize[prospect.responsable.idUser] = { ...prospect.responsable, prospectResponsibleOf: [] };
    }
    (usersToNormalize[prospect.responsable.idUser].prospectResponsibleOf as number[]).push(prospect.idProspect);
    relations['responsable'] = prospect.responsable.idUser;
  }
}

function prospectNormalizeUpdatedUser(
  prospect: ProspectEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (prospect.updatedUser && typeof prospect.updatedUser !== 'number') {
    if (!usersToNormalize[prospect.updatedUser.idUser]) {
      usersToNormalize[prospect.updatedUser.idUser] = { ...prospect.updatedUser, prospectUpdater: [] };
    }
    (usersToNormalize[prospect.updatedUser.idUser].prospectUpdater as number[]).push(prospect.idProspect);
    relations['updatedUser'] = prospect.updatedUser.idUser;
  }
}

function prospectNormalizeCreatedUser(
  prospect: ProspectEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (prospect.createdUser && typeof prospect.createdUser !== 'number') {
    if (!usersToNormalize[prospect.createdUser.idUser]) {
      usersToNormalize[prospect.createdUser.idUser] = { ...prospect.createdUser, prospectCreator: [] };
    }
    (usersToNormalize[prospect.createdUser.idUser].prospectCreator as number[]).push(prospect.idProspect);
    relations['createdUser'] = prospect.createdUser.idUser;
  }
}

/**
 *  ProspectBuyingWish
 */
export function getActionsToNormalizeProspectBuyingWish(
  prospectBuyingWishs: ProspectBuyingWishEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const prospectsToNormalize: { [id: number]: ProspectEntityState } = {};
  const organizationBuyingWishesToNormalize: { [id: number]: OrganizationBuyingWishEntityState } = {};
  const citiesToNormalize: { [id: number]: CityEntityState } = {};
  const residencesToNormalize: { [id: number]: ResidenceEntityState } = {};
  const stratalotTypesToNormalize: { [id: number]: StratalotTypeEntityState } = {};

  const normalizedProspectBuyingWishs = prospectBuyingWishs.map((prospectBuyingWish: ProspectBuyingWishEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    prospectBuyingWishNormalizeCity(prospectBuyingWish, citiesToNormalize, relations);
    prospectBuyingWishNormalizeResidence(prospectBuyingWish, residencesToNormalize, relations);
    prospectBuyingWishNormalizeStratalotType(prospectBuyingWish, stratalotTypesToNormalize, relations);
    prospectBuyingWishNormalizeProspect(prospectBuyingWish, prospectsToNormalize, relations);
    prospectBuyingWishNormalizeOrganizationBuyingWish(
      prospectBuyingWish,
      organizationBuyingWishesToNormalize,
      relations
    );

    return {
      ...prospectBuyingWish,
      ...relations
    };
  });
  const prospectsToNormalizeArray = Object.values(prospectsToNormalize);
  if (prospectsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProspect(prospectsToNormalizeArray, actionType));
  }
  const organizationBuyingWishesToNormalizeArray = Object.values(organizationBuyingWishesToNormalize);
  if (organizationBuyingWishesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationBuyingWish(organizationBuyingWishesToNormalizeArray, actionType));
  }
  const citiesToNormalizeArray = Object.values(citiesToNormalize);
  if (citiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCity(citiesToNormalizeArray, actionType));
  }
  const residencesToNormalizeArray = Object.values(residencesToNormalize);
  if (residencesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalizeArray, actionType));
  }
  const stratalotTypesToNormalizeArray = Object.values(stratalotTypesToNormalize);
  if (stratalotTypesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalotType(stratalotTypesToNormalizeArray, actionType));
  }

  const prospectBuyingWishActionCreator = prospectBuyingWishActions.upsertManyProspectBuyingWishsSuccess;
  actions.push(prospectBuyingWishActionCreator({ prospectBuyingWishs: normalizedProspectBuyingWishs }));

  return actions;
}

function prospectBuyingWishNormalizeCity(
  prospectBuyingWish: ProspectBuyingWishEntityState,
  citiesToNormalize: { [id: number]: CityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (prospectBuyingWish.cities?.length && typeof prospectBuyingWish.cities[0] !== 'number') {
    const idsCities: number[] = (prospectBuyingWish.cities as City[]).map((city: City) => {
      if (!citiesToNormalize[city.idCity]) {
        citiesToNormalize[city.idCity] = { ...city, prospectBuyingWishs: [] };
      }
      (citiesToNormalize[city.idCity].prospectBuyingWishs as number[]).push(prospectBuyingWish.idProspectBuyingWish);
      return city.idCity;
    });
    relations['cities'] = idsCities;
  }
}

function prospectBuyingWishNormalizeResidence(
  prospectBuyingWish: ProspectBuyingWishEntityState,
  residencesToNormalize: { [id: number]: ResidenceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (prospectBuyingWish.residences?.length && typeof prospectBuyingWish.residences[0] !== 'number') {
    const idsResidences: number[] = (prospectBuyingWish.residences as Residence[]).map((residence: Residence) => {
      if (!residencesToNormalize[residence.idResidence]) {
        residencesToNormalize[residence.idResidence] = { ...residence, prospectBuyingWishs: [] };
      }
      (residencesToNormalize[residence.idResidence].prospectBuyingWishs as number[]).push(
        prospectBuyingWish.idProspectBuyingWish
      );
      return residence.idResidence;
    });
    relations['residences'] = idsResidences;
  }
}

function prospectBuyingWishNormalizeStratalotType(
  prospectBuyingWish: ProspectBuyingWishEntityState,
  stratalotTypesToNormalize: { [id: number]: StratalotTypeEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (prospectBuyingWish.stratalotTypes?.length && typeof prospectBuyingWish.stratalotTypes[0] !== 'number') {
    const idsStratalotTypes: number[] = (prospectBuyingWish.stratalotTypes as StratalotType[]).map(
      (stratalotType: StratalotType) => {
        if (!stratalotTypesToNormalize[stratalotType.idStratalotType]) {
          stratalotTypesToNormalize[stratalotType.idStratalotType] = { ...stratalotType, prospectBuyingWishs: [] };
        }
        (stratalotTypesToNormalize[stratalotType.idStratalotType].prospectBuyingWishs as number[]).push(
          prospectBuyingWish.idProspectBuyingWish
        );
        return stratalotType.idStratalotType;
      }
    );
    relations['stratalotTypes'] = idsStratalotTypes;
  }
}

function prospectBuyingWishNormalizeProspect(
  prospectBuyingWish: ProspectBuyingWishEntityState,
  prospectsToNormalize: { [id: number]: ProspectEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (prospectBuyingWish.prospect && typeof prospectBuyingWish.prospect !== 'number') {
    if (!prospectsToNormalize[prospectBuyingWish.prospect.idProspect]) {
      prospectsToNormalize[prospectBuyingWish.prospect.idProspect] = {
        ...prospectBuyingWish.prospect,
        prospectBuyingWishs: []
      };
    }
    (prospectsToNormalize[prospectBuyingWish.prospect.idProspect].prospectBuyingWishs as number[]).push(
      prospectBuyingWish.idProspectBuyingWish
    );
    relations['prospect'] = prospectBuyingWish.prospect.idProspect;
  }
}

function prospectBuyingWishNormalizeOrganizationBuyingWish(
  prospectBuyingWish: ProspectBuyingWishEntityState,
  organizationBuyingWishesToNormalize: { [id: number]: OrganizationBuyingWishEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (prospectBuyingWish.organizationBuyingWish && typeof prospectBuyingWish.organizationBuyingWish !== 'number') {
    if (!organizationBuyingWishesToNormalize[prospectBuyingWish.organizationBuyingWish.idOrganizationBuyingWish]) {
      organizationBuyingWishesToNormalize[prospectBuyingWish.organizationBuyingWish.idOrganizationBuyingWish] = {
        ...prospectBuyingWish.organizationBuyingWish,
        prospectBuyingWishs: []
      };
    }
    (
      organizationBuyingWishesToNormalize[prospectBuyingWish.organizationBuyingWish.idOrganizationBuyingWish]
        .prospectBuyingWishs as number[]
    ).push(prospectBuyingWish.idProspectBuyingWish);
    relations['organizationBuyingWish'] = prospectBuyingWish.organizationBuyingWish.idOrganizationBuyingWish;
  }
}

/**
 *  ProspectEvent
 */
export function getActionsToNormalizeProspectEvent(
  prospectEvents: ProspectEventEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const prospectsToNormalize: { [id: number]: ProspectEntityState } = {};
  const leadsToNormalize: { [id: number]: LeadEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};

  const normalizedProspectEvents = prospectEvents.map((prospectEvent: ProspectEventEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    prospectEventNormalizeProspect(prospectEvent, prospectsToNormalize, relations);
    prospectEventNormalizeLead(prospectEvent, leadsToNormalize, relations);
    prospectEventNormalizeUser(prospectEvent, usersToNormalize, relations);

    return {
      ...prospectEvent,
      ...relations
    };
  });
  const prospectsToNormalizeArray = Object.values(prospectsToNormalize);
  if (prospectsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProspect(prospectsToNormalizeArray, actionType));
  }
  const leadsToNormalizeArray = Object.values(leadsToNormalize);
  if (leadsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeLead(leadsToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }

  const prospectEventActionCreator = prospectEventActions.upsertManyProspectEventsSuccess;
  actions.push(prospectEventActionCreator({ prospectEvents: normalizedProspectEvents }));

  return actions;
}

function prospectEventNormalizeProspect(
  prospectEvent: ProspectEventEntityState,
  prospectsToNormalize: { [id: number]: ProspectEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (prospectEvent.prospect && typeof prospectEvent.prospect !== 'number') {
    if (!prospectsToNormalize[prospectEvent.prospect.idProspect]) {
      prospectsToNormalize[prospectEvent.prospect.idProspect] = { ...prospectEvent.prospect, prospectEvents: [] };
    }
    (prospectsToNormalize[prospectEvent.prospect.idProspect].prospectEvents as number[]).push(
      prospectEvent.idProspectEvent
    );
    relations['prospect'] = prospectEvent.prospect.idProspect;
  }
}

function prospectEventNormalizeLead(
  prospectEvent: ProspectEventEntityState,
  leadsToNormalize: { [id: number]: LeadEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (prospectEvent.lead && typeof prospectEvent.lead !== 'number') {
    if (!leadsToNormalize[prospectEvent.lead.idLead]) {
      leadsToNormalize[prospectEvent.lead.idLead] = { ...prospectEvent.lead, prospectEvents: [] };
    }
    (leadsToNormalize[prospectEvent.lead.idLead].prospectEvents as number[]).push(prospectEvent.idProspectEvent);
    relations['lead'] = prospectEvent.lead.idLead;
  }
}

function prospectEventNormalizeUser(
  prospectEvent: ProspectEventEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (prospectEvent.user && typeof prospectEvent.user !== 'number') {
    if (!usersToNormalize[prospectEvent.user.idUser]) {
      usersToNormalize[prospectEvent.user.idUser] = { ...prospectEvent.user, prospectEvents: [] };
    }
    (usersToNormalize[prospectEvent.user.idUser].prospectEvents as number[]).push(prospectEvent.idProspectEvent);
    relations['user'] = prospectEvent.user.idUser;
  }
}

/**
 *  Profil
 */
export function getActionsToNormalizeProfil(profils: ProfilEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};
  const droitsToNormalize: { [id: number]: DroitEntityState } = {};
  const organizationLeadTodoProfilsToNormalize: OrganizationLeadTodoProfilEntityState[] = [];
  const organizationResidenceTodoProfilsToNormalize: OrganizationResidenceTodoProfilEntityState[] = [];
  const organizationStratalotTodoProfilsToNormalize: OrganizationStratalotTodoProfilEntityState[] = [];

  const normalizedProfils = profils.map((profil: ProfilEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    profilNormalizeUser(profil, usersToNormalize, relations);
    profilNormalizeDroit(profil, droitsToNormalize, relations);
    profilNormalizeOrganizationLeadTodoProfil(profil, organizationLeadTodoProfilsToNormalize, relations);
    profilNormalizeOrganizationResidenceTodoProfil(profil, organizationResidenceTodoProfilsToNormalize, relations);
    profilNormalizeOrganizationStratalotTodoProfil(profil, organizationStratalotTodoProfilsToNormalize, relations);
    profilNormalizeOrganization(profil, organizationsToNormalize, relations);

    return {
      ...profil,
      ...relations
    };
  });
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }
  const droitsToNormalizeArray = Object.values(droitsToNormalize);
  if (droitsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeDroit(droitsToNormalizeArray, actionType));
  }
  if (organizationLeadTodoProfilsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationLeadTodoProfil(organizationLeadTodoProfilsToNormalize, actionType)
    );
  }
  if (organizationResidenceTodoProfilsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceTodoProfil(organizationResidenceTodoProfilsToNormalize, actionType)
    );
  }
  if (organizationStratalotTodoProfilsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodoProfil(organizationStratalotTodoProfilsToNormalize, actionType)
    );
  }

  const profilActionCreator = profilActions.upsertManyProfilsSuccess;
  actions.push(profilActionCreator({ profils: normalizedProfils }));

  return actions;
}

function profilNormalizeUser(
  profil: ProfilEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (profil.users?.length && typeof profil.users[0] !== 'number') {
    const idsUsers: number[] = (profil.users as User[]).map((user: User) => {
      if (!usersToNormalize[user.idUser]) {
        usersToNormalize[user.idUser] = { ...user, profils: [] };
      }
      (usersToNormalize[user.idUser].profils as number[]).push(profil.idProfil);
      return user.idUser;
    });
    relations['users'] = idsUsers;
  }
}

function profilNormalizeDroit(
  profil: ProfilEntityState,
  droitsToNormalize: { [id: number]: DroitEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (profil.droits?.length && typeof profil.droits[0] !== 'number') {
    const idsDroits: number[] = (profil.droits as Droit[]).map((droit: Droit) => {
      if (!droitsToNormalize[droit.idDroit]) {
        droitsToNormalize[droit.idDroit] = { ...droit, profils: [] };
      }
      (droitsToNormalize[droit.idDroit].profils as number[]).push(profil.idProfil);
      return droit.idDroit;
    });
    relations['droits'] = idsDroits;
  }
}

function profilNormalizeOrganizationLeadTodoProfil(
  profil: ProfilEntityState,
  organizationLeadTodoProfilsToNormalize: OrganizationLeadTodoProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (profil.organizationLeadTodoProfils?.length && typeof profil.organizationLeadTodoProfils[0] !== 'number') {
    const idsOrganizationLeadTodoProfils: number[] = [];
    organizationLeadTodoProfilsToNormalize.push(
      ...(profil.organizationLeadTodoProfils as OrganizationLeadTodoProfil[]).map(
        (organizationLeadTodoProfil: OrganizationLeadTodoProfil) => {
          idsOrganizationLeadTodoProfils.push(organizationLeadTodoProfil.idOrganizationLeadTodoProfil);
          return { ...organizationLeadTodoProfil, profil: profil.idProfil };
        }
      )
    );
    relations['organizationLeadTodoProfils'] = idsOrganizationLeadTodoProfils;
  }
}

function profilNormalizeOrganizationResidenceTodoProfil(
  profil: ProfilEntityState,
  organizationResidenceTodoProfilsToNormalize: OrganizationResidenceTodoProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    profil.organizationResidenceTodoProfils?.length &&
    typeof profil.organizationResidenceTodoProfils[0] !== 'number'
  ) {
    const idsOrganizationResidenceTodoProfils: number[] = [];
    organizationResidenceTodoProfilsToNormalize.push(
      ...(profil.organizationResidenceTodoProfils as OrganizationResidenceTodoProfil[]).map(
        (organizationResidenceTodoProfil: OrganizationResidenceTodoProfil) => {
          idsOrganizationResidenceTodoProfils.push(organizationResidenceTodoProfil.idOrganizationResidenceTodoProfil);
          return { ...organizationResidenceTodoProfil, profil: profil.idProfil };
        }
      )
    );
    relations['organizationResidenceTodoProfils'] = idsOrganizationResidenceTodoProfils;
  }
}

function profilNormalizeOrganizationStratalotTodoProfil(
  profil: ProfilEntityState,
  organizationStratalotTodoProfilsToNormalize: OrganizationStratalotTodoProfilEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    profil.organizationStratalotTodoProfils?.length &&
    typeof profil.organizationStratalotTodoProfils[0] !== 'number'
  ) {
    const idsOrganizationStratalotTodoProfils: number[] = [];
    organizationStratalotTodoProfilsToNormalize.push(
      ...(profil.organizationStratalotTodoProfils as OrganizationStratalotTodoProfil[]).map(
        (organizationStratalotTodoProfil: OrganizationStratalotTodoProfil) => {
          idsOrganizationStratalotTodoProfils.push(organizationStratalotTodoProfil.idOrganizationStratalotTodoProfil);
          return { ...organizationStratalotTodoProfil, profil: profil.idProfil };
        }
      )
    );
    relations['organizationStratalotTodoProfils'] = idsOrganizationStratalotTodoProfils;
  }
}

function profilNormalizeOrganization(
  profil: ProfilEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (profil.organization && typeof profil.organization !== 'number') {
    if (!organizationsToNormalize[profil.organization.idOrganization]) {
      organizationsToNormalize[profil.organization.idOrganization] = { ...profil.organization, profils: [] };
    }
    (organizationsToNormalize[profil.organization.idOrganization].profils as number[]).push(profil.idProfil);
    relations['organization'] = profil.organization.idOrganization;
  }
}

/**
 *  Residence
 */
export function getActionsToNormalizeResidence(
  residences: ResidenceEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const responsableToNormalize: { [id: number]: UserEntityState } = {};
  const organizationForecastRatesToNormalize: { [id: number]: OrganizationForecastRateEntityState } = {};
  const citiesToNormalize: { [id: number]: CityEntityState } = {};
  const diffusionVisualsToNormalize: DiffusionVisualEntityState[] = [];
  const residenceTodosToNormalize: ResidenceTodoEntityState[] = [];
  const residenceWorksToNormalize: ResidenceWorkEntityState[] = [];
  const residenceSaleCategoryFamiliesToNormalize: ResidenceSaleCategoryFamilyEntityState[] = [];
  const residenceContactsToNormalize: ResidenceContactEntityState[] = [];
  const stratalotsToNormalize: StratalotEntityState[] = [];
  const residencePriceGridsToNormalize: ResidencePriceGridEntityState[] = [];
  const residenceSharesToNormalize: ResidenceShareEntityState[] = [];
  const stratalotRcpsToNormalize: StratalotRcpEntityState[] = [];
  const familySaleCategoriesToNormalize: { [id: number]: SaleCategoryFamilyEntityState } = {};
  const prospectBuyingWishsToNormalize: { [id: number]: ProspectBuyingWishEntityState } = {};
  const companyTerritoiresToNormalize: { [id: number]: CompanyTerritoireEntityState } = {};
  const residenceForecastsToNormalize: ResidenceForecastEntityState[] = [];
  const generatedDocumentsResidencesToNormalize: GeneratedDocumentsResidenceEntityState[] = [];
  const generatedDocumentsToNormalize: { [id: number]: GeneratedDocumentEntityState } = {};

  const normalizedResidences = residences.map((residence: ResidenceEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    residenceNormalizeDiffusionVisual(residence, diffusionVisualsToNormalize, relations);
    residenceNormalizeResidenceTodo(residence, residenceTodosToNormalize, relations);
    residenceNormalizeResidenceWork(residence, residenceWorksToNormalize, relations);
    residenceNormalizeResidenceSaleCategoryFamily(residence, residenceSaleCategoryFamiliesToNormalize, relations);
    residenceNormalizeResidenceContact(residence, residenceContactsToNormalize, relations);
    residenceNormalizeStratalot(residence, stratalotsToNormalize, relations);
    residenceNormalizeResidencePriceGrid(residence, residencePriceGridsToNormalize, relations);
    residenceNormalizeResidenceShare(residence, residenceSharesToNormalize, relations);
    residenceNormalizeStratalotRcp(residence, stratalotRcpsToNormalize, relations);
    residenceNormalizeSaleCategoryFamily(residence, familySaleCategoriesToNormalize, relations);
    residenceNormalizeProspectBuyingWish(residence, prospectBuyingWishsToNormalize, relations);
    residenceNormalizeCompanyTerritoire(residence, companyTerritoiresToNormalize, relations);
    residenceNormalizeResidenceForecast(residence, residenceForecastsToNormalize, relations);
    residenceNormalizeGeneratedDocumentsResidence(residence, generatedDocumentsResidencesToNormalize, relations);
    residenceNormalizeGeneratedDocument(residence, generatedDocumentsToNormalize, relations);
    residenceNormalizeCompany(residence, companiesToNormalize, relations);
    residenceNormalizeResponsable(residence, responsableToNormalize, relations);
    residenceNormalizeOrganizationForecastRate(residence, organizationForecastRatesToNormalize, relations);
    residenceNormalizeCity(residence, citiesToNormalize, relations);

    return {
      ...residence,
      ...relations
    };
  });
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  const responsableToNormalizeArray = Object.values(responsableToNormalize);
  if (responsableToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(responsableToNormalizeArray, actionType));
  }
  const organizationForecastRatesToNormalizeArray = Object.values(organizationForecastRatesToNormalize);
  if (organizationForecastRatesToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationForecastRate(organizationForecastRatesToNormalizeArray, actionType)
    );
  }
  const citiesToNormalizeArray = Object.values(citiesToNormalize);
  if (citiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCity(citiesToNormalizeArray, actionType));
  }
  if (diffusionVisualsToNormalize.length) {
    actions.push(...getActionsToNormalizeDiffusionVisual(diffusionVisualsToNormalize, actionType));
  }
  if (residenceTodosToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceTodo(residenceTodosToNormalize, actionType));
  }
  if (residenceWorksToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceWork(residenceWorksToNormalize, actionType));
  }
  if (residenceSaleCategoryFamiliesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeResidenceSaleCategoryFamily(residenceSaleCategoryFamiliesToNormalize, actionType)
    );
  }
  if (residenceContactsToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceContact(residenceContactsToNormalize, actionType));
  }
  if (stratalotsToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalize, actionType));
  }
  if (residencePriceGridsToNormalize.length) {
    actions.push(...getActionsToNormalizeResidencePriceGrid(residencePriceGridsToNormalize, actionType));
  }
  if (residenceSharesToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceShare(residenceSharesToNormalize, actionType));
  }
  if (stratalotRcpsToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalotRcp(stratalotRcpsToNormalize, actionType));
  }
  const familySaleCategoriesToNormalizeArray = Object.values(familySaleCategoriesToNormalize);
  if (familySaleCategoriesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSaleCategoryFamily(familySaleCategoriesToNormalizeArray, actionType));
  }
  const prospectBuyingWishsToNormalizeArray = Object.values(prospectBuyingWishsToNormalize);
  if (prospectBuyingWishsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProspectBuyingWish(prospectBuyingWishsToNormalizeArray, actionType));
  }
  const companyTerritoiresToNormalizeArray = Object.values(companyTerritoiresToNormalize);
  if (companyTerritoiresToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyTerritoire(companyTerritoiresToNormalizeArray, actionType));
  }
  if (residenceForecastsToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceForecast(residenceForecastsToNormalize, actionType));
  }
  if (generatedDocumentsResidencesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeGeneratedDocumentsResidence(generatedDocumentsResidencesToNormalize, actionType)
    );
  }
  const generatedDocumentsToNormalizeArray = Object.values(generatedDocumentsToNormalize);
  if (generatedDocumentsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeGeneratedDocument(generatedDocumentsToNormalizeArray, actionType));
  }

  const residenceActionCreator = residenceActions.upsertManyResidencesSuccess;
  actions.push(residenceActionCreator({ residences: normalizedResidences }));

  return actions;
}

function residenceNormalizeDiffusionVisual(
  residence: ResidenceEntityState,
  diffusionVisualsToNormalize: DiffusionVisualEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residence.diffusionVisuals?.length && typeof residence.diffusionVisuals[0] !== 'number') {
    const idsDiffusionVisuals: number[] = [];
    diffusionVisualsToNormalize.push(
      ...(residence.diffusionVisuals as DiffusionVisual[]).map((diffusionVisual: DiffusionVisual) => {
        idsDiffusionVisuals.push(diffusionVisual.idDiffusionVisual);
        return { ...diffusionVisual, residence: residence.idResidence };
      })
    );
    relations['diffusionVisuals'] = idsDiffusionVisuals;
  }
}

function residenceNormalizeResidenceTodo(
  residence: ResidenceEntityState,
  residenceTodosToNormalize: ResidenceTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residence.residenceTodos?.length && typeof residence.residenceTodos[0] !== 'number') {
    const idsResidenceTodos: number[] = [];
    residenceTodosToNormalize.push(
      ...(residence.residenceTodos as ResidenceTodo[]).map((residenceTodo: ResidenceTodo) => {
        idsResidenceTodos.push(residenceTodo.idResidenceTodo);
        return { ...residenceTodo, residence: residence.idResidence };
      })
    );
    relations['residenceTodos'] = idsResidenceTodos;
  }
}

function residenceNormalizeResidenceWork(
  residence: ResidenceEntityState,
  residenceWorksToNormalize: ResidenceWorkEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residence.residenceWorks?.length && typeof residence.residenceWorks[0] !== 'number') {
    const idsResidenceWorks: number[] = [];
    residenceWorksToNormalize.push(
      ...(residence.residenceWorks as ResidenceWork[]).map((residenceWork: ResidenceWork) => {
        idsResidenceWorks.push(residenceWork.idResidenceWork);
        return { ...residenceWork, residence: residence.idResidence };
      })
    );
    relations['residenceWorks'] = idsResidenceWorks;
  }
}

function residenceNormalizeResidenceSaleCategoryFamily(
  residence: ResidenceEntityState,
  residenceSaleCategoryFamiliesToNormalize: ResidenceSaleCategoryFamilyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    residence.residenceSaleCategoryFamilies?.length &&
    typeof residence.residenceSaleCategoryFamilies[0] !== 'number'
  ) {
    const idsResidenceSaleCategoryFamilies: number[] = [];
    residenceSaleCategoryFamiliesToNormalize.push(
      ...(residence.residenceSaleCategoryFamilies as ResidenceSaleCategoryFamily[]).map(
        (residenceSaleCategoryFamily: ResidenceSaleCategoryFamily) => {
          idsResidenceSaleCategoryFamilies.push(residenceSaleCategoryFamily.idResidenceSaleCategoryFamily);
          return { ...residenceSaleCategoryFamily, residence: residence.idResidence };
        }
      )
    );
    relations['residenceSaleCategoryFamilies'] = idsResidenceSaleCategoryFamilies;
  }
}

function residenceNormalizeResidenceContact(
  residence: ResidenceEntityState,
  residenceContactsToNormalize: ResidenceContactEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residence.residenceContacts?.length && typeof residence.residenceContacts[0] !== 'number') {
    const idsResidenceContacts: number[] = [];
    residenceContactsToNormalize.push(
      ...(residence.residenceContacts as ResidenceContact[]).map((residenceContact: ResidenceContact) => {
        idsResidenceContacts.push(residenceContact.idResidenceContact);
        return { ...residenceContact, residence: residence.idResidence };
      })
    );
    relations['residenceContacts'] = idsResidenceContacts;
  }
}

function residenceNormalizeStratalot(
  residence: ResidenceEntityState,
  stratalotsToNormalize: StratalotEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residence.stratalots?.length && typeof residence.stratalots[0] !== 'number') {
    const idsStratalots: number[] = [];
    stratalotsToNormalize.push(
      ...(residence.stratalots as Stratalot[]).map((stratalot: Stratalot) => {
        idsStratalots.push(stratalot.idStratalot);
        return { ...stratalot, residence: residence.idResidence };
      })
    );
    relations['stratalots'] = idsStratalots;
  }
}

function residenceNormalizeResidencePriceGrid(
  residence: ResidenceEntityState,
  residencePriceGridsToNormalize: ResidencePriceGridEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residence.residencePriceGrids?.length && typeof residence.residencePriceGrids[0] !== 'number') {
    const idsResidencePriceGrids: number[] = [];
    residencePriceGridsToNormalize.push(
      ...(residence.residencePriceGrids as ResidencePriceGrid[]).map((residencePriceGrid: ResidencePriceGrid) => {
        idsResidencePriceGrids.push(residencePriceGrid.idResidencePriceGrid);
        return { ...residencePriceGrid, residence: residence.idResidence };
      })
    );
    relations['residencePriceGrids'] = idsResidencePriceGrids;
  }
}

function residenceNormalizeResidenceShare(
  residence: ResidenceEntityState,
  residenceSharesToNormalize: ResidenceShareEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residence.residenceShares?.length && typeof residence.residenceShares[0] !== 'number') {
    const idsResidenceShares: number[] = [];
    residenceSharesToNormalize.push(
      ...(residence.residenceShares as ResidenceShare[]).map((residenceShare: ResidenceShare) => {
        idsResidenceShares.push(residenceShare.idResidenceShare);
        return { ...residenceShare, residence: residence.idResidence };
      })
    );
    relations['residenceShares'] = idsResidenceShares;
  }
}

function residenceNormalizeStratalotRcp(
  residence: ResidenceEntityState,
  stratalotRcpsToNormalize: StratalotRcpEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residence.stratalotRcps?.length && typeof residence.stratalotRcps[0] !== 'number') {
    const idsStratalotRcps: number[] = [];
    stratalotRcpsToNormalize.push(
      ...(residence.stratalotRcps as StratalotRcp[]).map((stratalotRcp: StratalotRcp) => {
        idsStratalotRcps.push(stratalotRcp.idStratalotRcp);
        return { ...stratalotRcp, residence: residence.idResidence };
      })
    );
    relations['stratalotRcps'] = idsStratalotRcps;
  }
}

function residenceNormalizeSaleCategoryFamily(
  residence: ResidenceEntityState,
  familySaleCategoriesToNormalize: { [id: number]: SaleCategoryFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residence.familySaleCategories?.length && typeof residence.familySaleCategories[0] !== 'number') {
    const idsFamilySaleCategories: number[] = (residence.familySaleCategories as SaleCategoryFamily[]).map(
      (saleCategoryFamily: SaleCategoryFamily) => {
        if (!familySaleCategoriesToNormalize[saleCategoryFamily.idSaleCategoryFamily]) {
          familySaleCategoriesToNormalize[saleCategoryFamily.idSaleCategoryFamily] = {
            ...saleCategoryFamily,
            residences: []
          };
        }
        (familySaleCategoriesToNormalize[saleCategoryFamily.idSaleCategoryFamily].residences as number[]).push(
          residence.idResidence
        );
        return saleCategoryFamily.idSaleCategoryFamily;
      }
    );
    relations['familySaleCategories'] = idsFamilySaleCategories;
  }
}

function residenceNormalizeProspectBuyingWish(
  residence: ResidenceEntityState,
  prospectBuyingWishsToNormalize: { [id: number]: ProspectBuyingWishEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residence.prospectBuyingWishs?.length && typeof residence.prospectBuyingWishs[0] !== 'number') {
    const idsProspectBuyingWishs: number[] = (residence.prospectBuyingWishs as ProspectBuyingWish[]).map(
      (prospectBuyingWish: ProspectBuyingWish) => {
        if (!prospectBuyingWishsToNormalize[prospectBuyingWish.idProspectBuyingWish]) {
          prospectBuyingWishsToNormalize[prospectBuyingWish.idProspectBuyingWish] = {
            ...prospectBuyingWish,
            residences: []
          };
        }
        (prospectBuyingWishsToNormalize[prospectBuyingWish.idProspectBuyingWish].residences as number[]).push(
          residence.idResidence
        );
        return prospectBuyingWish.idProspectBuyingWish;
      }
    );
    relations['prospectBuyingWishs'] = idsProspectBuyingWishs;
  }
}

function residenceNormalizeCompanyTerritoire(
  residence: ResidenceEntityState,
  companyTerritoiresToNormalize: { [id: number]: CompanyTerritoireEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residence.companyTerritoires?.length && typeof residence.companyTerritoires[0] !== 'number') {
    const idsCompanyTerritoires: number[] = (residence.companyTerritoires as CompanyTerritoire[]).map(
      (companyTerritoire: CompanyTerritoire) => {
        if (!companyTerritoiresToNormalize[companyTerritoire.idCompanyTerritoire]) {
          companyTerritoiresToNormalize[companyTerritoire.idCompanyTerritoire] = {
            ...companyTerritoire,
            residences: []
          };
        }
        (companyTerritoiresToNormalize[companyTerritoire.idCompanyTerritoire].residences as number[]).push(
          residence.idResidence
        );
        return companyTerritoire.idCompanyTerritoire;
      }
    );
    relations['companyTerritoires'] = idsCompanyTerritoires;
  }
}

function residenceNormalizeResidenceForecast(
  residence: ResidenceEntityState,
  residenceForecastsToNormalize: ResidenceForecastEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residence.residenceForecasts?.length && typeof residence.residenceForecasts[0] !== 'number') {
    const idsResidenceForecasts: number[] = [];
    residenceForecastsToNormalize.push(
      ...(residence.residenceForecasts as ResidenceForecast[]).map((residenceForecast: ResidenceForecast) => {
        idsResidenceForecasts.push(residenceForecast.idResidenceForecast);
        return { ...residenceForecast, residence: residence.idResidence };
      })
    );
    relations['residenceForecasts'] = idsResidenceForecasts;
  }
}

function residenceNormalizeGeneratedDocumentsResidence(
  residence: ResidenceEntityState,
  generatedDocumentsResidencesToNormalize: GeneratedDocumentsResidenceEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residence.generatedDocumentsResidences?.length && typeof residence.generatedDocumentsResidences[0] !== 'number') {
    const idsGeneratedDocumentsResidences: number[] = [];
    generatedDocumentsResidencesToNormalize.push(
      ...(residence.generatedDocumentsResidences as GeneratedDocumentsResidence[]).map(
        (generatedDocumentsResidence: GeneratedDocumentsResidence) => {
          idsGeneratedDocumentsResidences.push(generatedDocumentsResidence.idGeneratedDocumentsResidence);
          return { ...generatedDocumentsResidence, residence: residence.idResidence };
        }
      )
    );
    relations['generatedDocumentsResidences'] = idsGeneratedDocumentsResidences;
  }
}

function residenceNormalizeGeneratedDocument(
  residence: ResidenceEntityState,
  generatedDocumentsToNormalize: { [id: number]: GeneratedDocumentEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residence.generatedDocuments?.length && typeof residence.generatedDocuments[0] !== 'number') {
    const idsGeneratedDocuments: number[] = (residence.generatedDocuments as GeneratedDocument[]).map(
      (generatedDocument: GeneratedDocument) => {
        if (!generatedDocumentsToNormalize[generatedDocument.idGeneratedDocument]) {
          generatedDocumentsToNormalize[generatedDocument.idGeneratedDocument] = {
            ...generatedDocument,
            residences: []
          };
        }
        (generatedDocumentsToNormalize[generatedDocument.idGeneratedDocument].residences as number[]).push(
          residence.idResidence
        );
        return generatedDocument.idGeneratedDocument;
      }
    );
    relations['generatedDocuments'] = idsGeneratedDocuments;
  }
}

function residenceNormalizeCompany(
  residence: ResidenceEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residence.company && typeof residence.company !== 'number') {
    if (!companiesToNormalize[residence.company.idCompany]) {
      companiesToNormalize[residence.company.idCompany] = { ...residence.company, residences: [] };
    }
    (companiesToNormalize[residence.company.idCompany].residences as number[]).push(residence.idResidence);
    relations['company'] = residence.company.idCompany;
  }
}

function residenceNormalizeResponsable(
  residence: ResidenceEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residence.responsable && typeof residence.responsable !== 'number') {
    if (!usersToNormalize[residence.responsable.idUser]) {
      usersToNormalize[residence.responsable.idUser] = { ...residence.responsable, residences: [] };
    }
    (usersToNormalize[residence.responsable.idUser].residences as number[]).push(residence.idResidence);
    relations['responsable'] = residence.responsable.idUser;
  }
}

function residenceNormalizeOrganizationForecastRate(
  residence: ResidenceEntityState,
  organizationForecastRatesToNormalize: { [id: number]: OrganizationForecastRateEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residence.organizationForecastRate && typeof residence.organizationForecastRate !== 'number') {
    if (!organizationForecastRatesToNormalize[residence.organizationForecastRate.idOrganizationForecastRate]) {
      organizationForecastRatesToNormalize[residence.organizationForecastRate.idOrganizationForecastRate] = {
        ...residence.organizationForecastRate,
        residences: []
      };
    }
    (
      organizationForecastRatesToNormalize[residence.organizationForecastRate.idOrganizationForecastRate]
        .residences as number[]
    ).push(residence.idResidence);
    relations['organizationForecastRate'] = residence.organizationForecastRate.idOrganizationForecastRate;
  }
}

function residenceNormalizeCity(
  residence: ResidenceEntityState,
  citiesToNormalize: { [id: number]: CityEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residence.city && typeof residence.city !== 'number') {
    if (!citiesToNormalize[residence.city.idCity]) {
      citiesToNormalize[residence.city.idCity] = { ...residence.city, residences: [] };
    }
    (citiesToNormalize[residence.city.idCity].residences as number[]).push(residence.idResidence);
    relations['city'] = residence.city.idCity;
  }
}

/**
 *  ResidenceContact
 */
export function getActionsToNormalizeResidenceContact(
  residenceContacts: ResidenceContactEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residencesToNormalize: { [id: number]: ResidenceEntityState } = {};
  const organizationThirdPartiesToNormalize: { [id: number]: OrganizationThirdPartyEntityState } = {};

  const normalizedResidenceContacts = residenceContacts.map((residenceContact: ResidenceContactEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    residenceContactNormalizeResidence(residenceContact, residencesToNormalize, relations);
    residenceContactNormalizeOrganizationThirdParty(residenceContact, organizationThirdPartiesToNormalize, relations);

    return {
      ...residenceContact,
      ...relations
    };
  });
  const residencesToNormalizeArray = Object.values(residencesToNormalize);
  if (residencesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalizeArray, actionType));
  }
  const organizationThirdPartiesToNormalizeArray = Object.values(organizationThirdPartiesToNormalize);
  if (organizationThirdPartiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganizationThirdParty(organizationThirdPartiesToNormalizeArray, actionType));
  }

  const residenceContactActionCreator = residenceContactActions.upsertManyResidenceContactsSuccess;
  actions.push(residenceContactActionCreator({ residenceContacts: normalizedResidenceContacts }));

  return actions;
}

function residenceContactNormalizeResidence(
  residenceContact: ResidenceContactEntityState,
  residencesToNormalize: { [id: number]: ResidenceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceContact.residence && typeof residenceContact.residence !== 'number') {
    if (!residencesToNormalize[residenceContact.residence.idResidence]) {
      residencesToNormalize[residenceContact.residence.idResidence] = {
        ...residenceContact.residence,
        residenceContacts: []
      };
    }
    (residencesToNormalize[residenceContact.residence.idResidence].residenceContacts as number[]).push(
      residenceContact.idResidenceContact
    );
    relations['residence'] = residenceContact.residence.idResidence;
  }
}

function residenceContactNormalizeOrganizationThirdParty(
  residenceContact: ResidenceContactEntityState,
  organizationThirdPartiesToNormalize: { [id: number]: OrganizationThirdPartyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceContact.organizationThirdParty && typeof residenceContact.organizationThirdParty !== 'number') {
    if (!organizationThirdPartiesToNormalize[residenceContact.organizationThirdParty.idOrganizationThirdParty]) {
      organizationThirdPartiesToNormalize[residenceContact.organizationThirdParty.idOrganizationThirdParty] = {
        ...residenceContact.organizationThirdParty,
        residenceContacts: []
      };
    }
    (
      organizationThirdPartiesToNormalize[residenceContact.organizationThirdParty.idOrganizationThirdParty]
        .residenceContacts as number[]
    ).push(residenceContact.idResidenceContact);
    relations['organizationThirdParty'] = residenceContact.organizationThirdParty.idOrganizationThirdParty;
  }
}

/**
 *  ResidenceShare
 */
export function getActionsToNormalizeResidenceShare(
  residenceShares: ResidenceShareEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residencesToNormalize: { [id: number]: ResidenceEntityState } = {};
  const stratalotRcpsToNormalize: { [id: number]: StratalotRcpEntityState } = {};

  const normalizedResidenceShares = residenceShares.map((residenceShare: ResidenceShareEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    residenceShareNormalizeStratalotRcp(residenceShare, stratalotRcpsToNormalize, relations);
    residenceShareNormalizeResidence(residenceShare, residencesToNormalize, relations);

    return {
      ...residenceShare,
      ...relations
    };
  });
  const residencesToNormalizeArray = Object.values(residencesToNormalize);
  if (residencesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalizeArray, actionType));
  }
  const stratalotRcpsToNormalizeArray = Object.values(stratalotRcpsToNormalize);
  if (stratalotRcpsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalotRcp(stratalotRcpsToNormalizeArray, actionType));
  }

  const residenceShareActionCreator = residenceShareActions.upsertManyResidenceSharesSuccess;
  actions.push(residenceShareActionCreator({ residenceShares: normalizedResidenceShares }));

  return actions;
}

function residenceShareNormalizeStratalotRcp(
  residenceShare: ResidenceShareEntityState,
  stratalotRcpsToNormalize: { [id: number]: StratalotRcpEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceShare.stratalotRcps?.length && typeof residenceShare.stratalotRcps[0] !== 'number') {
    const idsStratalotRcps: number[] = (residenceShare.stratalotRcps as StratalotRcp[]).map(
      (stratalotRcp: StratalotRcp) => {
        if (!stratalotRcpsToNormalize[stratalotRcp.idStratalotRcp]) {
          stratalotRcpsToNormalize[stratalotRcp.idStratalotRcp] = { ...stratalotRcp, residenceShares: [] };
        }
        (stratalotRcpsToNormalize[stratalotRcp.idStratalotRcp].residenceShares as number[]).push(
          residenceShare.idResidenceShare
        );
        return stratalotRcp.idStratalotRcp;
      }
    );
    relations['stratalotRcps'] = idsStratalotRcps;
  }
}

function residenceShareNormalizeResidence(
  residenceShare: ResidenceShareEntityState,
  residencesToNormalize: { [id: number]: ResidenceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceShare.residence && typeof residenceShare.residence !== 'number') {
    if (!residencesToNormalize[residenceShare.residence.idResidence]) {
      residencesToNormalize[residenceShare.residence.idResidence] = {
        ...residenceShare.residence,
        residenceShares: []
      };
    }
    (residencesToNormalize[residenceShare.residence.idResidence].residenceShares as number[]).push(
      residenceShare.idResidenceShare
    );
    relations['residence'] = residenceShare.residence.idResidence;
  }
}

/**
 *  ResidenceShareRcp
 */
export function getActionsToNormalizeResidenceShareRcp(
  residenceShareRcps: ResidenceShareRcpEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const stratalotRcpsToNormalize: { [id: number]: StratalotRcpEntityState } = {};

  const normalizedResidenceShareRcps = residenceShareRcps.map((residenceShareRcp: ResidenceShareRcpEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    residenceShareRcpNormalizeStratalotRcp(residenceShareRcp, stratalotRcpsToNormalize, relations);

    return {
      ...residenceShareRcp,
      ...relations
    };
  });
  const stratalotRcpsToNormalizeArray = Object.values(stratalotRcpsToNormalize);
  if (stratalotRcpsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalotRcp(stratalotRcpsToNormalizeArray, actionType));
  }

  const residenceShareRcpActionCreator = residenceShareRcpActions.upsertManyResidenceShareRcpsSuccess;
  actions.push(residenceShareRcpActionCreator({ residenceShareRcps: normalizedResidenceShareRcps }));

  return actions;
}

function residenceShareRcpNormalizeStratalotRcp(
  residenceShareRcp: ResidenceShareRcpEntityState,
  stratalotRcpsToNormalize: { [id: number]: StratalotRcpEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceShareRcp.stratalotRcp && typeof residenceShareRcp.stratalotRcp !== 'number') {
    if (!stratalotRcpsToNormalize[residenceShareRcp.stratalotRcp.idStratalotRcp]) {
      stratalotRcpsToNormalize[residenceShareRcp.stratalotRcp.idStratalotRcp] = {
        ...residenceShareRcp.stratalotRcp,
        residenceShareRcps: []
      };
    }
    (stratalotRcpsToNormalize[residenceShareRcp.stratalotRcp.idStratalotRcp].residenceShareRcps as number[]).push(
      residenceShareRcp.idResidenceShareRcp
    );
    relations['stratalotRcp'] = residenceShareRcp.stratalotRcp.idStratalotRcp;
  }
}

/**
 *  ResidenceStudy
 */
export function getActionsToNormalizeResidenceStudy(
  residenceStudies: ResidenceStudyEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companyStudyNextActionsToNormalize: { [id: number]: CompanyStudyNextActionEntityState } = {};
  const companyStudyConclusionsToNormalize: { [id: number]: CompanyStudyConclusionEntityState } = {};
  const companiesToNormalize: { [id: number]: CompanyEntityState } = {};
  const residenceStudyReasonsToNormalize: ResidenceStudyReasonEntityState[] = [];
  const residenceStudyCriteriasToNormalize: ResidenceStudyCriteriaEntityState[] = [];
  const residenceStudyWorksToNormalize: ResidenceStudyWorkEntityState[] = [];
  const residenceStudyTodosToNormalize: ResidenceStudyTodoEntityState[] = [];
  const residenceStudyLotsToNormalize: ResidenceStudyLotEntityState[] = [];
  const residenceStudyFoundingsToNormalize: ResidenceStudyFoundingEntityState[] = [];
  const companyStudyReasonsToNormalize: { [id: number]: CompanyStudyReasonEntityState } = {};

  const normalizedResidenceStudies = residenceStudies.map((residenceStudy: ResidenceStudyEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    residenceStudyNormalizeResidenceStudyReason(residenceStudy, residenceStudyReasonsToNormalize, relations);
    residenceStudyNormalizeResidenceStudyCriteria(residenceStudy, residenceStudyCriteriasToNormalize, relations);
    residenceStudyNormalizeResidenceStudyWork(residenceStudy, residenceStudyWorksToNormalize, relations);
    residenceStudyNormalizeResidenceStudyTodo(residenceStudy, residenceStudyTodosToNormalize, relations);
    residenceStudyNormalizeResidenceStudyLot(residenceStudy, residenceStudyLotsToNormalize, relations);
    residenceStudyNormalizeResidenceStudyFounding(residenceStudy, residenceStudyFoundingsToNormalize, relations);
    residenceStudyNormalizeCompanyStudyReason(residenceStudy, companyStudyReasonsToNormalize, relations);
    residenceStudyNormalizeCompanyStudyNextAction(residenceStudy, companyStudyNextActionsToNormalize, relations);
    residenceStudyNormalizeCompanyStudyConclusion(residenceStudy, companyStudyConclusionsToNormalize, relations);
    residenceStudyNormalizeCompany(residenceStudy, companiesToNormalize, relations);

    return {
      ...residenceStudy,
      ...relations
    };
  });
  const companyStudyNextActionsToNormalizeArray = Object.values(companyStudyNextActionsToNormalize);
  if (companyStudyNextActionsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyStudyNextAction(companyStudyNextActionsToNormalizeArray, actionType));
  }
  const companyStudyConclusionsToNormalizeArray = Object.values(companyStudyConclusionsToNormalize);
  if (companyStudyConclusionsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyStudyConclusion(companyStudyConclusionsToNormalizeArray, actionType));
  }
  const companiesToNormalizeArray = Object.values(companiesToNormalize);
  if (companiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompany(companiesToNormalizeArray, actionType));
  }
  if (residenceStudyReasonsToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceStudyReason(residenceStudyReasonsToNormalize, actionType));
  }
  if (residenceStudyCriteriasToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceStudyCriteria(residenceStudyCriteriasToNormalize, actionType));
  }
  if (residenceStudyWorksToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceStudyWork(residenceStudyWorksToNormalize, actionType));
  }
  if (residenceStudyTodosToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceStudyTodo(residenceStudyTodosToNormalize, actionType));
  }
  if (residenceStudyLotsToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceStudyLot(residenceStudyLotsToNormalize, actionType));
  }
  if (residenceStudyFoundingsToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceStudyFounding(residenceStudyFoundingsToNormalize, actionType));
  }
  const companyStudyReasonsToNormalizeArray = Object.values(companyStudyReasonsToNormalize);
  if (companyStudyReasonsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyStudyReason(companyStudyReasonsToNormalizeArray, actionType));
  }

  const residenceStudyActionCreator = residenceStudyActions.upsertManyResidenceStudiesSuccess;
  actions.push(residenceStudyActionCreator({ residenceStudies: normalizedResidenceStudies }));

  return actions;
}

function residenceStudyNormalizeResidenceStudyReason(
  residenceStudy: ResidenceStudyEntityState,
  residenceStudyReasonsToNormalize: ResidenceStudyReasonEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudy.residenceStudyReasons?.length && typeof residenceStudy.residenceStudyReasons[0] !== 'number') {
    const idsResidenceStudyReasons: number[] = [];
    residenceStudyReasonsToNormalize.push(
      ...(residenceStudy.residenceStudyReasons as ResidenceStudyReason[]).map(
        (residenceStudyReason: ResidenceStudyReason) => {
          idsResidenceStudyReasons.push(residenceStudyReason.idResidenceStudyReason);
          return { ...residenceStudyReason, residenceStudy: residenceStudy.idResidenceStudy };
        }
      )
    );
    relations['residenceStudyReasons'] = idsResidenceStudyReasons;
  }
}

function residenceStudyNormalizeResidenceStudyCriteria(
  residenceStudy: ResidenceStudyEntityState,
  residenceStudyCriteriasToNormalize: ResidenceStudyCriteriaEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudy.residenceStudyCriterias?.length && typeof residenceStudy.residenceStudyCriterias[0] !== 'number') {
    const idsResidenceStudyCriterias: number[] = [];
    residenceStudyCriteriasToNormalize.push(
      ...(residenceStudy.residenceStudyCriterias as ResidenceStudyCriteria[]).map(
        (residenceStudyCriteria: ResidenceStudyCriteria) => {
          idsResidenceStudyCriterias.push(residenceStudyCriteria.idResidenceStudyCriteria);
          return { ...residenceStudyCriteria, residenceStudy: residenceStudy.idResidenceStudy };
        }
      )
    );
    relations['residenceStudyCriterias'] = idsResidenceStudyCriterias;
  }
}

function residenceStudyNormalizeResidenceStudyWork(
  residenceStudy: ResidenceStudyEntityState,
  residenceStudyWorksToNormalize: ResidenceStudyWorkEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudy.residenceStudyWorks?.length && typeof residenceStudy.residenceStudyWorks[0] !== 'number') {
    const idsResidenceStudyWorks: number[] = [];
    residenceStudyWorksToNormalize.push(
      ...(residenceStudy.residenceStudyWorks as ResidenceStudyWork[]).map((residenceStudyWork: ResidenceStudyWork) => {
        idsResidenceStudyWorks.push(residenceStudyWork.idResidenceStudyWork);
        return { ...residenceStudyWork, residenceStudy: residenceStudy.idResidenceStudy };
      })
    );
    relations['residenceStudyWorks'] = idsResidenceStudyWorks;
  }
}

function residenceStudyNormalizeResidenceStudyTodo(
  residenceStudy: ResidenceStudyEntityState,
  residenceStudyTodosToNormalize: ResidenceStudyTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudy.residenceStudyTodos?.length && typeof residenceStudy.residenceStudyTodos[0] !== 'number') {
    const idsResidenceStudyTodos: number[] = [];
    residenceStudyTodosToNormalize.push(
      ...(residenceStudy.residenceStudyTodos as ResidenceStudyTodo[]).map((residenceStudyTodo: ResidenceStudyTodo) => {
        idsResidenceStudyTodos.push(residenceStudyTodo.idResidenceStudyTodo);
        return { ...residenceStudyTodo, residenceStudy: residenceStudy.idResidenceStudy };
      })
    );
    relations['residenceStudyTodos'] = idsResidenceStudyTodos;
  }
}

function residenceStudyNormalizeResidenceStudyLot(
  residenceStudy: ResidenceStudyEntityState,
  residenceStudyLotsToNormalize: ResidenceStudyLotEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudy.residenceStudyLots?.length && typeof residenceStudy.residenceStudyLots[0] !== 'number') {
    const idsResidenceStudyLots: number[] = [];
    residenceStudyLotsToNormalize.push(
      ...(residenceStudy.residenceStudyLots as ResidenceStudyLot[]).map((residenceStudyLot: ResidenceStudyLot) => {
        idsResidenceStudyLots.push(residenceStudyLot.idResidenceStudyLot);
        return { ...residenceStudyLot, residenceStudy: residenceStudy.idResidenceStudy };
      })
    );
    relations['residenceStudyLots'] = idsResidenceStudyLots;
  }
}

function residenceStudyNormalizeResidenceStudyFounding(
  residenceStudy: ResidenceStudyEntityState,
  residenceStudyFoundingsToNormalize: ResidenceStudyFoundingEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudy.residenceStudyFoundings?.length && typeof residenceStudy.residenceStudyFoundings[0] !== 'number') {
    const idsResidenceStudyFoundings: number[] = [];
    residenceStudyFoundingsToNormalize.push(
      ...(residenceStudy.residenceStudyFoundings as ResidenceStudyFounding[]).map(
        (residenceStudyFounding: ResidenceStudyFounding) => {
          idsResidenceStudyFoundings.push(residenceStudyFounding.idResidenceStudyFounding);
          return { ...residenceStudyFounding, residenceStudy: residenceStudy.idResidenceStudy };
        }
      )
    );
    relations['residenceStudyFoundings'] = idsResidenceStudyFoundings;
  }
}

function residenceStudyNormalizeCompanyStudyReason(
  residenceStudy: ResidenceStudyEntityState,
  companyStudyReasonsToNormalize: { [id: number]: CompanyStudyReasonEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudy.companyStudyReasons?.length && typeof residenceStudy.companyStudyReasons[0] !== 'number') {
    const idsCompanyStudyReasons: number[] = (residenceStudy.companyStudyReasons as CompanyStudyReason[]).map(
      (companyStudyReason: CompanyStudyReason) => {
        if (!companyStudyReasonsToNormalize[companyStudyReason.idCompanyStudyReason]) {
          companyStudyReasonsToNormalize[companyStudyReason.idCompanyStudyReason] = {
            ...companyStudyReason,
            residenceStudies: []
          };
        }
        (companyStudyReasonsToNormalize[companyStudyReason.idCompanyStudyReason].residenceStudies as number[]).push(
          residenceStudy.idResidenceStudy
        );
        return companyStudyReason.idCompanyStudyReason;
      }
    );
    relations['companyStudyReasons'] = idsCompanyStudyReasons;
  }
}

function residenceStudyNormalizeCompanyStudyNextAction(
  residenceStudy: ResidenceStudyEntityState,
  companyStudyNextActionsToNormalize: { [id: number]: CompanyStudyNextActionEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudy.companyStudyNextAction && typeof residenceStudy.companyStudyNextAction !== 'number') {
    if (!companyStudyNextActionsToNormalize[residenceStudy.companyStudyNextAction.idCompanyStudyNextAction]) {
      companyStudyNextActionsToNormalize[residenceStudy.companyStudyNextAction.idCompanyStudyNextAction] = {
        ...residenceStudy.companyStudyNextAction,
        residenceStudies: []
      };
    }
    (
      companyStudyNextActionsToNormalize[residenceStudy.companyStudyNextAction.idCompanyStudyNextAction]
        .residenceStudies as number[]
    ).push(residenceStudy.idResidenceStudy);
    relations['companyStudyNextAction'] = residenceStudy.companyStudyNextAction.idCompanyStudyNextAction;
  }
}

function residenceStudyNormalizeCompanyStudyConclusion(
  residenceStudy: ResidenceStudyEntityState,
  companyStudyConclusionsToNormalize: { [id: number]: CompanyStudyConclusionEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudy.companyStudyConclusion && typeof residenceStudy.companyStudyConclusion !== 'number') {
    if (!companyStudyConclusionsToNormalize[residenceStudy.companyStudyConclusion.idCompanyStudyConclusion]) {
      companyStudyConclusionsToNormalize[residenceStudy.companyStudyConclusion.idCompanyStudyConclusion] = {
        ...residenceStudy.companyStudyConclusion,
        residenceStudies: []
      };
    }
    (
      companyStudyConclusionsToNormalize[residenceStudy.companyStudyConclusion.idCompanyStudyConclusion]
        .residenceStudies as number[]
    ).push(residenceStudy.idResidenceStudy);
    relations['companyStudyConclusion'] = residenceStudy.companyStudyConclusion.idCompanyStudyConclusion;
  }
}

function residenceStudyNormalizeCompany(
  residenceStudy: ResidenceStudyEntityState,
  companiesToNormalize: { [id: number]: CompanyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudy.company && typeof residenceStudy.company !== 'number') {
    if (!companiesToNormalize[residenceStudy.company.idCompany]) {
      companiesToNormalize[residenceStudy.company.idCompany] = { ...residenceStudy.company, residenceStudies: [] };
    }
    (companiesToNormalize[residenceStudy.company.idCompany].residenceStudies as number[]).push(
      residenceStudy.idResidenceStudy
    );
    relations['company'] = residenceStudy.company.idCompany;
  }
}

/**
 *  ResidenceStudyCriteria
 */
export function getActionsToNormalizeResidenceStudyCriteria(
  residenceStudyCriterias: ResidenceStudyCriteriaEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residenceStudiesToNormalize: { [id: number]: ResidenceStudyEntityState } = {};
  const companyStudyCriteriasToNormalize: { [id: number]: CompanyStudyCriteriaEntityState } = {};

  const normalizedResidenceStudyCriterias = residenceStudyCriterias.map(
    (residenceStudyCriteria: ResidenceStudyCriteriaEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      residenceStudyCriteriaNormalizeResidenceStudy(residenceStudyCriteria, residenceStudiesToNormalize, relations);
      residenceStudyCriteriaNormalizeCompanyStudyCriteria(
        residenceStudyCriteria,
        companyStudyCriteriasToNormalize,
        relations
      );

      return {
        ...residenceStudyCriteria,
        ...relations
      };
    }
  );
  const residenceStudiesToNormalizeArray = Object.values(residenceStudiesToNormalize);
  if (residenceStudiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidenceStudy(residenceStudiesToNormalizeArray, actionType));
  }
  const companyStudyCriteriasToNormalizeArray = Object.values(companyStudyCriteriasToNormalize);
  if (companyStudyCriteriasToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyStudyCriteria(companyStudyCriteriasToNormalizeArray, actionType));
  }

  const residenceStudyCriteriaActionCreator = residenceStudyCriteriaActions.upsertManyResidenceStudyCriteriasSuccess;
  actions.push(residenceStudyCriteriaActionCreator({ residenceStudyCriterias: normalizedResidenceStudyCriterias }));

  return actions;
}

function residenceStudyCriteriaNormalizeResidenceStudy(
  residenceStudyCriteria: ResidenceStudyCriteriaEntityState,
  residenceStudiesToNormalize: { [id: number]: ResidenceStudyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudyCriteria.residenceStudy && typeof residenceStudyCriteria.residenceStudy !== 'number') {
    if (!residenceStudiesToNormalize[residenceStudyCriteria.residenceStudy.idResidenceStudy]) {
      residenceStudiesToNormalize[residenceStudyCriteria.residenceStudy.idResidenceStudy] = {
        ...residenceStudyCriteria.residenceStudy,
        residenceStudyCriterias: []
      };
    }
    (
      residenceStudiesToNormalize[residenceStudyCriteria.residenceStudy.idResidenceStudy]
        .residenceStudyCriterias as number[]
    ).push(residenceStudyCriteria.idResidenceStudyCriteria);
    relations['residenceStudy'] = residenceStudyCriteria.residenceStudy.idResidenceStudy;
  }
}

function residenceStudyCriteriaNormalizeCompanyStudyCriteria(
  residenceStudyCriteria: ResidenceStudyCriteriaEntityState,
  companyStudyCriteriasToNormalize: { [id: number]: CompanyStudyCriteriaEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudyCriteria.companyStudyCriteria && typeof residenceStudyCriteria.companyStudyCriteria !== 'number') {
    if (!companyStudyCriteriasToNormalize[residenceStudyCriteria.companyStudyCriteria.idCompanyStudyCriteria]) {
      companyStudyCriteriasToNormalize[residenceStudyCriteria.companyStudyCriteria.idCompanyStudyCriteria] = {
        ...residenceStudyCriteria.companyStudyCriteria,
        residenceStudyCriterias: []
      };
    }
    (
      companyStudyCriteriasToNormalize[residenceStudyCriteria.companyStudyCriteria.idCompanyStudyCriteria]
        .residenceStudyCriterias as number[]
    ).push(residenceStudyCriteria.idResidenceStudyCriteria);
    relations['companyStudyCriteria'] = residenceStudyCriteria.companyStudyCriteria.idCompanyStudyCriteria;
  }
}

/**
 *  ResidenceStudyWork
 */
export function getActionsToNormalizeResidenceStudyWork(
  residenceStudyWorks: ResidenceStudyWorkEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residenceStudiesToNormalize: { [id: number]: ResidenceStudyEntityState } = {};

  const normalizedResidenceStudyWorks = residenceStudyWorks.map((residenceStudyWork: ResidenceStudyWorkEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    residenceStudyWorkNormalizeResidenceStudy(residenceStudyWork, residenceStudiesToNormalize, relations);

    return {
      ...residenceStudyWork,
      ...relations
    };
  });
  const residenceStudiesToNormalizeArray = Object.values(residenceStudiesToNormalize);
  if (residenceStudiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidenceStudy(residenceStudiesToNormalizeArray, actionType));
  }

  const residenceStudyWorkActionCreator = residenceStudyWorkActions.upsertManyResidenceStudyWorksSuccess;
  actions.push(residenceStudyWorkActionCreator({ residenceStudyWorks: normalizedResidenceStudyWorks }));

  return actions;
}

function residenceStudyWorkNormalizeResidenceStudy(
  residenceStudyWork: ResidenceStudyWorkEntityState,
  residenceStudiesToNormalize: { [id: number]: ResidenceStudyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudyWork.residenceStudy && typeof residenceStudyWork.residenceStudy !== 'number') {
    if (!residenceStudiesToNormalize[residenceStudyWork.residenceStudy.idResidenceStudy]) {
      residenceStudiesToNormalize[residenceStudyWork.residenceStudy.idResidenceStudy] = {
        ...residenceStudyWork.residenceStudy,
        residenceStudyWorks: []
      };
    }
    (
      residenceStudiesToNormalize[residenceStudyWork.residenceStudy.idResidenceStudy].residenceStudyWorks as number[]
    ).push(residenceStudyWork.idResidenceStudyWork);
    relations['residenceStudy'] = residenceStudyWork.residenceStudy.idResidenceStudy;
  }
}

/**
 *  ResidenceStudyLot
 */
export function getActionsToNormalizeResidenceStudyLot(
  residenceStudyLots: ResidenceStudyLotEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residenceStudiesToNormalize: { [id: number]: ResidenceStudyEntityState } = {};

  const normalizedResidenceStudyLots = residenceStudyLots.map((residenceStudyLot: ResidenceStudyLotEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    residenceStudyLotNormalizeResidenceStudy(residenceStudyLot, residenceStudiesToNormalize, relations);

    return {
      ...residenceStudyLot,
      ...relations
    };
  });
  const residenceStudiesToNormalizeArray = Object.values(residenceStudiesToNormalize);
  if (residenceStudiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidenceStudy(residenceStudiesToNormalizeArray, actionType));
  }

  const residenceStudyLotActionCreator = residenceStudyLotActions.upsertManyResidenceStudyLotsSuccess;
  actions.push(residenceStudyLotActionCreator({ residenceStudyLots: normalizedResidenceStudyLots }));

  return actions;
}

function residenceStudyLotNormalizeResidenceStudy(
  residenceStudyLot: ResidenceStudyLotEntityState,
  residenceStudiesToNormalize: { [id: number]: ResidenceStudyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudyLot.residenceStudy && typeof residenceStudyLot.residenceStudy !== 'number') {
    if (!residenceStudiesToNormalize[residenceStudyLot.residenceStudy.idResidenceStudy]) {
      residenceStudiesToNormalize[residenceStudyLot.residenceStudy.idResidenceStudy] = {
        ...residenceStudyLot.residenceStudy,
        residenceStudyLots: []
      };
    }
    (
      residenceStudiesToNormalize[residenceStudyLot.residenceStudy.idResidenceStudy].residenceStudyLots as number[]
    ).push(residenceStudyLot.idResidenceStudyLot);
    relations['residenceStudy'] = residenceStudyLot.residenceStudy.idResidenceStudy;
  }
}

/**
 *  ResidenceStudyFounding
 */
export function getActionsToNormalizeResidenceStudyFounding(
  residenceStudyFoundings: ResidenceStudyFoundingEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residenceStudiesToNormalize: { [id: number]: ResidenceStudyEntityState } = {};

  const normalizedResidenceStudyFoundings = residenceStudyFoundings.map(
    (residenceStudyFounding: ResidenceStudyFoundingEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      residenceStudyFoundingNormalizeResidenceStudy(residenceStudyFounding, residenceStudiesToNormalize, relations);

      return {
        ...residenceStudyFounding,
        ...relations
      };
    }
  );
  const residenceStudiesToNormalizeArray = Object.values(residenceStudiesToNormalize);
  if (residenceStudiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidenceStudy(residenceStudiesToNormalizeArray, actionType));
  }

  const residenceStudyFoundingActionCreator = residenceStudyFoundingActions.upsertManyResidenceStudyFoundingsSuccess;
  actions.push(residenceStudyFoundingActionCreator({ residenceStudyFoundings: normalizedResidenceStudyFoundings }));

  return actions;
}

function residenceStudyFoundingNormalizeResidenceStudy(
  residenceStudyFounding: ResidenceStudyFoundingEntityState,
  residenceStudiesToNormalize: { [id: number]: ResidenceStudyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudyFounding.residenceStudy && typeof residenceStudyFounding.residenceStudy !== 'number') {
    if (!residenceStudiesToNormalize[residenceStudyFounding.residenceStudy.idResidenceStudy]) {
      residenceStudiesToNormalize[residenceStudyFounding.residenceStudy.idResidenceStudy] = {
        ...residenceStudyFounding.residenceStudy,
        residenceStudyFoundings: []
      };
    }
    (
      residenceStudiesToNormalize[residenceStudyFounding.residenceStudy.idResidenceStudy]
        .residenceStudyFoundings as number[]
    ).push(residenceStudyFounding.idResidenceStudyFounding);
    relations['residenceStudy'] = residenceStudyFounding.residenceStudy.idResidenceStudy;
  }
}

/**
 *  ResidenceStudyReason
 */
export function getActionsToNormalizeResidenceStudyReason(
  residenceStudyReasons: ResidenceStudyReasonEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residenceStudiesToNormalize: { [id: number]: ResidenceStudyEntityState } = {};
  const companyStudyReasonsToNormalize: { [id: number]: CompanyStudyReasonEntityState } = {};

  const normalizedResidenceStudyReasons = residenceStudyReasons.map(
    (residenceStudyReason: ResidenceStudyReasonEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      residenceStudyReasonNormalizeResidenceStudy(residenceStudyReason, residenceStudiesToNormalize, relations);
      residenceStudyReasonNormalizeCompanyStudyReason(residenceStudyReason, companyStudyReasonsToNormalize, relations);

      return {
        ...residenceStudyReason,
        ...relations
      };
    }
  );
  const residenceStudiesToNormalizeArray = Object.values(residenceStudiesToNormalize);
  if (residenceStudiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidenceStudy(residenceStudiesToNormalizeArray, actionType));
  }
  const companyStudyReasonsToNormalizeArray = Object.values(companyStudyReasonsToNormalize);
  if (companyStudyReasonsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyStudyReason(companyStudyReasonsToNormalizeArray, actionType));
  }

  const residenceStudyReasonActionCreator = residenceStudyReasonActions.upsertManyResidenceStudyReasonsSuccess;
  actions.push(residenceStudyReasonActionCreator({ residenceStudyReasons: normalizedResidenceStudyReasons }));

  return actions;
}

function residenceStudyReasonNormalizeResidenceStudy(
  residenceStudyReason: ResidenceStudyReasonEntityState,
  residenceStudiesToNormalize: { [id: number]: ResidenceStudyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudyReason.residenceStudy && typeof residenceStudyReason.residenceStudy !== 'number') {
    if (!residenceStudiesToNormalize[residenceStudyReason.residenceStudy.idResidenceStudy]) {
      residenceStudiesToNormalize[residenceStudyReason.residenceStudy.idResidenceStudy] = {
        ...residenceStudyReason.residenceStudy,
        residenceStudyReasons: []
      };
    }
    (
      residenceStudiesToNormalize[residenceStudyReason.residenceStudy.idResidenceStudy]
        .residenceStudyReasons as number[]
    ).push(residenceStudyReason.idResidenceStudyReason);
    relations['residenceStudy'] = residenceStudyReason.residenceStudy.idResidenceStudy;
  }
}

function residenceStudyReasonNormalizeCompanyStudyReason(
  residenceStudyReason: ResidenceStudyReasonEntityState,
  companyStudyReasonsToNormalize: { [id: number]: CompanyStudyReasonEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudyReason.companyStudyReason && typeof residenceStudyReason.companyStudyReason !== 'number') {
    if (!companyStudyReasonsToNormalize[residenceStudyReason.companyStudyReason.idCompanyStudyReason]) {
      companyStudyReasonsToNormalize[residenceStudyReason.companyStudyReason.idCompanyStudyReason] = {
        ...residenceStudyReason.companyStudyReason,
        residenceStudyReasons: []
      };
    }
    (
      companyStudyReasonsToNormalize[residenceStudyReason.companyStudyReason.idCompanyStudyReason]
        .residenceStudyReasons as number[]
    ).push(residenceStudyReason.idResidenceStudyReason);
    relations['companyStudyReason'] = residenceStudyReason.companyStudyReason.idCompanyStudyReason;
  }
}

/**
 *  ResidenceTodo
 */
export function getActionsToNormalizeResidenceTodo(
  residenceTodos: ResidenceTodoEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationResidenceTodosToNormalize: { [id: number]: OrganizationResidenceTodoEntityState } = {};
  const residencesToNormalize: { [id: number]: ResidenceEntityState } = {};

  const normalizedResidenceTodos = residenceTodos.map((residenceTodo: ResidenceTodoEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    residenceTodoNormalizeOrganizationResidenceTodo(residenceTodo, organizationResidenceTodosToNormalize, relations);
    residenceTodoNormalizeResidence(residenceTodo, residencesToNormalize, relations);

    return {
      ...residenceTodo,
      ...relations
    };
  });
  const organizationResidenceTodosToNormalizeArray = Object.values(organizationResidenceTodosToNormalize);
  if (organizationResidenceTodosToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceTodo(organizationResidenceTodosToNormalizeArray, actionType)
    );
  }
  const residencesToNormalizeArray = Object.values(residencesToNormalize);
  if (residencesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalizeArray, actionType));
  }

  const residenceTodoActionCreator = residenceTodoActions.upsertManyResidenceTodosSuccess;
  actions.push(residenceTodoActionCreator({ residenceTodos: normalizedResidenceTodos }));

  return actions;
}

function residenceTodoNormalizeOrganizationResidenceTodo(
  residenceTodo: ResidenceTodoEntityState,
  organizationResidenceTodosToNormalize: { [id: number]: OrganizationResidenceTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceTodo.organizationResidenceTodo && typeof residenceTodo.organizationResidenceTodo !== 'number') {
    if (!organizationResidenceTodosToNormalize[residenceTodo.organizationResidenceTodo.idOrganizationResidenceTodo]) {
      organizationResidenceTodosToNormalize[residenceTodo.organizationResidenceTodo.idOrganizationResidenceTodo] = {
        ...residenceTodo.organizationResidenceTodo,
        residenceTodos: []
      };
    }
    (
      organizationResidenceTodosToNormalize[residenceTodo.organizationResidenceTodo.idOrganizationResidenceTodo]
        .residenceTodos as number[]
    ).push(residenceTodo.idResidenceTodo);
    relations['organizationResidenceTodo'] = residenceTodo.organizationResidenceTodo.idOrganizationResidenceTodo;
  }
}

function residenceTodoNormalizeResidence(
  residenceTodo: ResidenceTodoEntityState,
  residencesToNormalize: { [id: number]: ResidenceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceTodo.residence && typeof residenceTodo.residence !== 'number') {
    if (!residencesToNormalize[residenceTodo.residence.idResidence]) {
      residencesToNormalize[residenceTodo.residence.idResidence] = { ...residenceTodo.residence, residenceTodos: [] };
    }
    (residencesToNormalize[residenceTodo.residence.idResidence].residenceTodos as number[]).push(
      residenceTodo.idResidenceTodo
    );
    relations['residence'] = residenceTodo.residence.idResidence;
  }
}

/**
 *  ResidenceWork
 */
export function getActionsToNormalizeResidenceWork(
  residenceWorks: ResidenceWorkEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residencesToNormalize: { [id: number]: ResidenceEntityState } = {};

  const normalizedResidenceWorks = residenceWorks.map((residenceWork: ResidenceWorkEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    residenceWorkNormalizeResidence(residenceWork, residencesToNormalize, relations);

    return {
      ...residenceWork,
      ...relations
    };
  });
  const residencesToNormalizeArray = Object.values(residencesToNormalize);
  if (residencesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalizeArray, actionType));
  }

  const residenceWorkActionCreator = residenceWorkActions.upsertManyResidenceWorksSuccess;
  actions.push(residenceWorkActionCreator({ residenceWorks: normalizedResidenceWorks }));

  return actions;
}

function residenceWorkNormalizeResidence(
  residenceWork: ResidenceWorkEntityState,
  residencesToNormalize: { [id: number]: ResidenceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceWork.residence && typeof residenceWork.residence !== 'number') {
    if (!residencesToNormalize[residenceWork.residence.idResidence]) {
      residencesToNormalize[residenceWork.residence.idResidence] = { ...residenceWork.residence, residenceWorks: [] };
    }
    (residencesToNormalize[residenceWork.residence.idResidence].residenceWorks as number[]).push(
      residenceWork.idResidenceWork
    );
    relations['residence'] = residenceWork.residence.idResidence;
  }
}

/**
 *  ResidenceSaleCategoryFamily
 */
export function getActionsToNormalizeResidenceSaleCategoryFamily(
  residenceSaleCategoryFamilies: ResidenceSaleCategoryFamilyEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residencesToNormalize: { [id: number]: ResidenceEntityState } = {};
  const familySaleCategoriesToNormalize: { [id: number]: SaleCategoryFamilyEntityState } = {};

  const normalizedResidenceSaleCategoryFamilies = residenceSaleCategoryFamilies.map(
    (residenceSaleCategoryFamily: ResidenceSaleCategoryFamilyEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      residenceSaleCategoryFamilyNormalizeResidence(residenceSaleCategoryFamily, residencesToNormalize, relations);
      residenceSaleCategoryFamilyNormalizeSaleCategoryFamily(
        residenceSaleCategoryFamily,
        familySaleCategoriesToNormalize,
        relations
      );

      return {
        ...residenceSaleCategoryFamily,
        ...relations
      };
    }
  );
  const residencesToNormalizeArray = Object.values(residencesToNormalize);
  if (residencesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalizeArray, actionType));
  }
  const familySaleCategoriesToNormalizeArray = Object.values(familySaleCategoriesToNormalize);
  if (familySaleCategoriesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeSaleCategoryFamily(familySaleCategoriesToNormalizeArray, actionType));
  }

  const residenceSaleCategoryFamilyActionCreator =
    residenceSaleCategoryFamilyActions.upsertManyResidenceSaleCategoryFamiliesSuccess;
  actions.push(
    residenceSaleCategoryFamilyActionCreator({ residenceSaleCategoryFamilies: normalizedResidenceSaleCategoryFamilies })
  );

  return actions;
}

function residenceSaleCategoryFamilyNormalizeResidence(
  residenceSaleCategoryFamily: ResidenceSaleCategoryFamilyEntityState,
  residencesToNormalize: { [id: number]: ResidenceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceSaleCategoryFamily.residence && typeof residenceSaleCategoryFamily.residence !== 'number') {
    if (!residencesToNormalize[residenceSaleCategoryFamily.residence.idResidence]) {
      residencesToNormalize[residenceSaleCategoryFamily.residence.idResidence] = {
        ...residenceSaleCategoryFamily.residence,
        residenceSaleCategoryFamilies: []
      };
    }
    (
      residencesToNormalize[residenceSaleCategoryFamily.residence.idResidence].residenceSaleCategoryFamilies as number[]
    ).push(residenceSaleCategoryFamily.idResidenceSaleCategoryFamily);
    relations['residence'] = residenceSaleCategoryFamily.residence.idResidence;
  }
}

function residenceSaleCategoryFamilyNormalizeSaleCategoryFamily(
  residenceSaleCategoryFamily: ResidenceSaleCategoryFamilyEntityState,
  familySaleCategoriesToNormalize: { [id: number]: SaleCategoryFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    residenceSaleCategoryFamily.saleCategoryFamily &&
    typeof residenceSaleCategoryFamily.saleCategoryFamily !== 'number'
  ) {
    if (!familySaleCategoriesToNormalize[residenceSaleCategoryFamily.saleCategoryFamily.idSaleCategoryFamily]) {
      familySaleCategoriesToNormalize[residenceSaleCategoryFamily.saleCategoryFamily.idSaleCategoryFamily] = {
        ...residenceSaleCategoryFamily.saleCategoryFamily,
        residenceSaleCategoryFamilies: []
      };
    }
    (
      familySaleCategoriesToNormalize[residenceSaleCategoryFamily.saleCategoryFamily.idSaleCategoryFamily]
        .residenceSaleCategoryFamilies as number[]
    ).push(residenceSaleCategoryFamily.idResidenceSaleCategoryFamily);
    relations['saleCategoryFamily'] = residenceSaleCategoryFamily.saleCategoryFamily.idSaleCategoryFamily;
  }
}

/**
 *  SaleCategoryFamily
 */
export function getActionsToNormalizeSaleCategoryFamily(
  familySaleCategories: SaleCategoryFamilyEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationSaleCategoriesToNormalize: OrganizationSaleCategoryEntityState[] = [];
  const boardStatesToNormalize: BoardStateEntityState[] = [];
  const companyPriceLabelsToNormalize: CompanyPriceLabelEntityState[] = [];
  const organizationLeadTodoRulesToNormalize: OrganizationLeadTodoRuleEntityState[] = [];
  const residenceSaleCategoryFamiliesToNormalize: ResidenceSaleCategoryFamilyEntityState[] = [];
  const residencesToNormalize: { [id: number]: ResidenceEntityState } = {};
  const organizationStratalotTodoRulesToNormalize: OrganizationStratalotTodoRuleEntityState[] = [];
  const organizationResidenceTodoRulesToNormalize: OrganizationResidenceTodoRuleEntityState[] = [];

  const normalizedFamilySaleCategories = familySaleCategories.map(
    (saleCategoryFamily: SaleCategoryFamilyEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      saleCategoryFamilyNormalizeOrganizationSaleCategory(
        saleCategoryFamily,
        organizationSaleCategoriesToNormalize,
        relations
      );
      saleCategoryFamilyNormalizeBoardState(saleCategoryFamily, boardStatesToNormalize, relations);
      saleCategoryFamilyNormalizeCompanyPriceLabel(saleCategoryFamily, companyPriceLabelsToNormalize, relations);
      saleCategoryFamilyNormalizeOrganizationLeadTodoRule(
        saleCategoryFamily,
        organizationLeadTodoRulesToNormalize,
        relations
      );
      saleCategoryFamilyNormalizeResidenceSaleCategoryFamily(
        saleCategoryFamily,
        residenceSaleCategoryFamiliesToNormalize,
        relations
      );
      saleCategoryFamilyNormalizeResidence(saleCategoryFamily, residencesToNormalize, relations);
      saleCategoryFamilyNormalizeOrganizationStratalotTodoRule(
        saleCategoryFamily,
        organizationStratalotTodoRulesToNormalize,
        relations
      );
      saleCategoryFamilyNormalizeOrganizationResidenceTodoRule(
        saleCategoryFamily,
        organizationResidenceTodoRulesToNormalize,
        relations
      );

      return {
        ...saleCategoryFamily,
        ...relations
      };
    }
  );
  if (organizationSaleCategoriesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationSaleCategory(organizationSaleCategoriesToNormalize, actionType));
  }
  if (boardStatesToNormalize.length) {
    actions.push(...getActionsToNormalizeBoardState(boardStatesToNormalize, actionType));
  }
  if (companyPriceLabelsToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyPriceLabel(companyPriceLabelsToNormalize, actionType));
  }
  if (organizationLeadTodoRulesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationLeadTodoRule(organizationLeadTodoRulesToNormalize, actionType));
  }
  if (residenceSaleCategoryFamiliesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeResidenceSaleCategoryFamily(residenceSaleCategoryFamiliesToNormalize, actionType)
    );
  }
  const residencesToNormalizeArray = Object.values(residencesToNormalize);
  if (residencesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalizeArray, actionType));
  }
  if (organizationStratalotTodoRulesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodoRule(organizationStratalotTodoRulesToNormalize, actionType)
    );
  }
  if (organizationResidenceTodoRulesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceTodoRule(organizationResidenceTodoRulesToNormalize, actionType)
    );
  }

  const saleCategoryFamilyActionCreator = saleCategoryFamilyActions.upsertManyFamilySaleCategoriesSuccess;
  actions.push(saleCategoryFamilyActionCreator({ familySaleCategories: normalizedFamilySaleCategories }));

  return actions;
}

function saleCategoryFamilyNormalizeOrganizationSaleCategory(
  saleCategoryFamily: SaleCategoryFamilyEntityState,
  organizationSaleCategoriesToNormalize: OrganizationSaleCategoryEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    saleCategoryFamily.organizationSaleCategories?.length &&
    typeof saleCategoryFamily.organizationSaleCategories[0] !== 'number'
  ) {
    const idsOrganizationSaleCategories: number[] = [];
    organizationSaleCategoriesToNormalize.push(
      ...(saleCategoryFamily.organizationSaleCategories as OrganizationSaleCategory[]).map(
        (organizationSaleCategory: OrganizationSaleCategory) => {
          idsOrganizationSaleCategories.push(organizationSaleCategory.idOrganizationSaleCategory);
          return { ...organizationSaleCategory, saleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily };
        }
      )
    );
    relations['organizationSaleCategories'] = idsOrganizationSaleCategories;
  }
}

function saleCategoryFamilyNormalizeBoardState(
  saleCategoryFamily: SaleCategoryFamilyEntityState,
  boardStatesToNormalize: BoardStateEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (saleCategoryFamily.boardStates?.length && typeof saleCategoryFamily.boardStates[0] !== 'number') {
    const idsBoardStates: number[] = [];
    boardStatesToNormalize.push(
      ...(saleCategoryFamily.boardStates as BoardState[]).map((boardState: BoardState) => {
        idsBoardStates.push(boardState.idBoardState);
        return { ...boardState, saleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily };
      })
    );
    relations['boardStates'] = idsBoardStates;
  }
}

function saleCategoryFamilyNormalizeCompanyPriceLabel(
  saleCategoryFamily: SaleCategoryFamilyEntityState,
  companyPriceLabelsToNormalize: CompanyPriceLabelEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (saleCategoryFamily.companyPriceLabels?.length && typeof saleCategoryFamily.companyPriceLabels[0] !== 'number') {
    const idsCompanyPriceLabels: number[] = [];
    companyPriceLabelsToNormalize.push(
      ...(saleCategoryFamily.companyPriceLabels as CompanyPriceLabel[]).map((companyPriceLabel: CompanyPriceLabel) => {
        idsCompanyPriceLabels.push(companyPriceLabel.idCompanyPriceLabel);
        return { ...companyPriceLabel, saleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily };
      })
    );
    relations['companyPriceLabels'] = idsCompanyPriceLabels;
  }
}

function saleCategoryFamilyNormalizeOrganizationLeadTodoRule(
  saleCategoryFamily: SaleCategoryFamilyEntityState,
  organizationLeadTodoRulesToNormalize: OrganizationLeadTodoRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    saleCategoryFamily.organizationLeadTodoRules?.length &&
    typeof saleCategoryFamily.organizationLeadTodoRules[0] !== 'number'
  ) {
    const idsOrganizationLeadTodoRules: number[] = [];
    organizationLeadTodoRulesToNormalize.push(
      ...(saleCategoryFamily.organizationLeadTodoRules as OrganizationLeadTodoRule[]).map(
        (organizationLeadTodoRule: OrganizationLeadTodoRule) => {
          idsOrganizationLeadTodoRules.push(organizationLeadTodoRule.idOrganizationLeadTodoRule);
          return { ...organizationLeadTodoRule, saleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily };
        }
      )
    );
    relations['organizationLeadTodoRules'] = idsOrganizationLeadTodoRules;
  }
}

function saleCategoryFamilyNormalizeResidenceSaleCategoryFamily(
  saleCategoryFamily: SaleCategoryFamilyEntityState,
  residenceSaleCategoryFamiliesToNormalize: ResidenceSaleCategoryFamilyEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    saleCategoryFamily.residenceSaleCategoryFamilies?.length &&
    typeof saleCategoryFamily.residenceSaleCategoryFamilies[0] !== 'number'
  ) {
    const idsResidenceSaleCategoryFamilies: number[] = [];
    residenceSaleCategoryFamiliesToNormalize.push(
      ...(saleCategoryFamily.residenceSaleCategoryFamilies as ResidenceSaleCategoryFamily[]).map(
        (residenceSaleCategoryFamily: ResidenceSaleCategoryFamily) => {
          idsResidenceSaleCategoryFamilies.push(residenceSaleCategoryFamily.idResidenceSaleCategoryFamily);
          return { ...residenceSaleCategoryFamily, saleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily };
        }
      )
    );
    relations['residenceSaleCategoryFamilies'] = idsResidenceSaleCategoryFamilies;
  }
}

function saleCategoryFamilyNormalizeResidence(
  saleCategoryFamily: SaleCategoryFamilyEntityState,
  residencesToNormalize: { [id: number]: ResidenceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (saleCategoryFamily.residences?.length && typeof saleCategoryFamily.residences[0] !== 'number') {
    const idsResidences: number[] = (saleCategoryFamily.residences as Residence[]).map((residence: Residence) => {
      if (!residencesToNormalize[residence.idResidence]) {
        residencesToNormalize[residence.idResidence] = { ...residence, familySaleCategories: [] };
      }
      (residencesToNormalize[residence.idResidence].familySaleCategories as number[]).push(
        saleCategoryFamily.idSaleCategoryFamily
      );
      return residence.idResidence;
    });
    relations['residences'] = idsResidences;
  }
}

function saleCategoryFamilyNormalizeOrganizationStratalotTodoRule(
  saleCategoryFamily: SaleCategoryFamilyEntityState,
  organizationStratalotTodoRulesToNormalize: OrganizationStratalotTodoRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    saleCategoryFamily.organizationStratalotTodoRules?.length &&
    typeof saleCategoryFamily.organizationStratalotTodoRules[0] !== 'number'
  ) {
    const idsOrganizationStratalotTodoRules: number[] = [];
    organizationStratalotTodoRulesToNormalize.push(
      ...(saleCategoryFamily.organizationStratalotTodoRules as OrganizationStratalotTodoRule[]).map(
        (organizationStratalotTodoRule: OrganizationStratalotTodoRule) => {
          idsOrganizationStratalotTodoRules.push(organizationStratalotTodoRule.idOrganizationStratalotTodoRule);
          return { ...organizationStratalotTodoRule, saleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily };
        }
      )
    );
    relations['organizationStratalotTodoRules'] = idsOrganizationStratalotTodoRules;
  }
}

function saleCategoryFamilyNormalizeOrganizationResidenceTodoRule(
  saleCategoryFamily: SaleCategoryFamilyEntityState,
  organizationResidenceTodoRulesToNormalize: OrganizationResidenceTodoRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    saleCategoryFamily.organizationResidenceTodoRules?.length &&
    typeof saleCategoryFamily.organizationResidenceTodoRules[0] !== 'number'
  ) {
    const idsOrganizationResidenceTodoRules: number[] = [];
    organizationResidenceTodoRulesToNormalize.push(
      ...(saleCategoryFamily.organizationResidenceTodoRules as OrganizationResidenceTodoRule[]).map(
        (organizationResidenceTodoRule: OrganizationResidenceTodoRule) => {
          idsOrganizationResidenceTodoRules.push(organizationResidenceTodoRule.idOrganizationResidenceTodoRule);
          return { ...organizationResidenceTodoRule, saleCategoryFamily: saleCategoryFamily.idSaleCategoryFamily };
        }
      )
    );
    relations['organizationResidenceTodoRules'] = idsOrganizationResidenceTodoRules;
  }
}

/**
 *  Step
 */
export function getActionsToNormalizeStep(steps: StepEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const stepProgressesToNormalize: StepProgressEntityState[] = [];
  const organizationLeadTodosToNormalize: OrganizationLeadTodoEntityState[] = [];
  const organizationStratalotAvancementsToNormalize: OrganizationStratalotAvancementEntityState[] = [];
  const organizationLeadAvancementsToNormalize: OrganizationLeadAvancementEntityState[] = [];

  const normalizedSteps = steps.map((step: StepEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    stepNormalizeStepProgress(step, stepProgressesToNormalize, relations);
    stepNormalizeOrganizationLeadTodo(step, organizationLeadTodosToNormalize, relations);
    stepNormalizeOrganizationStratalotAvancement(step, organizationStratalotAvancementsToNormalize, relations);
    stepNormalizeOrganizationLeadAvancement(step, organizationLeadAvancementsToNormalize, relations);

    return {
      ...step,
      ...relations
    };
  });
  if (stepProgressesToNormalize.length) {
    actions.push(...getActionsToNormalizeStepProgress(stepProgressesToNormalize, actionType));
  }
  if (organizationLeadTodosToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationLeadTodo(organizationLeadTodosToNormalize, actionType));
  }
  if (organizationStratalotAvancementsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotAvancement(organizationStratalotAvancementsToNormalize, actionType)
    );
  }
  if (organizationLeadAvancementsToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationLeadAvancement(organizationLeadAvancementsToNormalize, actionType)
    );
  }

  const stepActionCreator = stepActions.upsertManyStepsSuccess;
  actions.push(stepActionCreator({ steps: normalizedSteps }));

  return actions;
}

function stepNormalizeStepProgress(
  step: StepEntityState,
  stepProgressesToNormalize: StepProgressEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (step.stepProgresses?.length && typeof step.stepProgresses[0] !== 'number') {
    const idsStepProgresses: number[] = [];
    stepProgressesToNormalize.push(
      ...(step.stepProgresses as StepProgress[]).map((stepProgress: StepProgress) => {
        idsStepProgresses.push(stepProgress.idStepProgress);
        return { ...stepProgress, step: step.idStep };
      })
    );
    relations['stepProgresses'] = idsStepProgresses;
  }
}

function stepNormalizeOrganizationLeadTodo(
  step: StepEntityState,
  organizationLeadTodosToNormalize: OrganizationLeadTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (step.organizationLeadTodos?.length && typeof step.organizationLeadTodos[0] !== 'number') {
    const idsOrganizationLeadTodos: number[] = [];
    organizationLeadTodosToNormalize.push(
      ...(step.organizationLeadTodos as OrganizationLeadTodo[]).map((organizationLeadTodo: OrganizationLeadTodo) => {
        idsOrganizationLeadTodos.push(organizationLeadTodo.idOrganizationLeadTodo);
        return { ...organizationLeadTodo, step: step.idStep };
      })
    );
    relations['organizationLeadTodos'] = idsOrganizationLeadTodos;
  }
}

function stepNormalizeOrganizationStratalotAvancement(
  step: StepEntityState,
  organizationStratalotAvancementsToNormalize: OrganizationStratalotAvancementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (step.organizationStratalotAvancements?.length && typeof step.organizationStratalotAvancements[0] !== 'number') {
    const idsOrganizationStratalotAvancements: number[] = [];
    organizationStratalotAvancementsToNormalize.push(
      ...(step.organizationStratalotAvancements as OrganizationStratalotAvancement[]).map(
        (organizationStratalotAvancement: OrganizationStratalotAvancement) => {
          idsOrganizationStratalotAvancements.push(organizationStratalotAvancement.idOrganizationStratalotAvancement);
          return { ...organizationStratalotAvancement, step: step.idStep };
        }
      )
    );
    relations['organizationStratalotAvancements'] = idsOrganizationStratalotAvancements;
  }
}

function stepNormalizeOrganizationLeadAvancement(
  step: StepEntityState,
  organizationLeadAvancementsToNormalize: OrganizationLeadAvancementEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (step.organizationLeadAvancements?.length && typeof step.organizationLeadAvancements[0] !== 'number') {
    const idsOrganizationLeadAvancements: number[] = [];
    organizationLeadAvancementsToNormalize.push(
      ...(step.organizationLeadAvancements as OrganizationLeadAvancement[]).map(
        (organizationLeadAvancement: OrganizationLeadAvancement) => {
          idsOrganizationLeadAvancements.push(organizationLeadAvancement.idOrganizationLeadAvancement);
          return { ...organizationLeadAvancement, step: step.idStep };
        }
      )
    );
    relations['organizationLeadAvancements'] = idsOrganizationLeadAvancements;
  }
}

/**
 *  StepProgress
 */
export function getActionsToNormalizeStepProgress(
  stepProgresses: StepProgressEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const stepsToNormalize: { [id: number]: StepEntityState } = {};
  const stepProgressesFamiliesToNormalize: { [id: number]: StepProgressFamilyEntityState } = {};
  const stratalotsToNormalize: StratalotEntityState[] = [];

  const normalizedStepProgresses = stepProgresses.map((stepProgress: StepProgressEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    stepProgressNormalizeStratalot(stepProgress, stratalotsToNormalize, relations);
    stepProgressNormalizeStep(stepProgress, stepsToNormalize, relations);
    stepProgressNormalizeStepProgressFamily(stepProgress, stepProgressesFamiliesToNormalize, relations);

    return {
      ...stepProgress,
      ...relations
    };
  });
  const stepsToNormalizeArray = Object.values(stepsToNormalize);
  if (stepsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStep(stepsToNormalizeArray, actionType));
  }
  const stepProgressesFamiliesToNormalizeArray = Object.values(stepProgressesFamiliesToNormalize);
  if (stepProgressesFamiliesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStepProgressFamily(stepProgressesFamiliesToNormalizeArray, actionType));
  }
  if (stratalotsToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalize, actionType));
  }

  const stepProgressActionCreator = stepProgressActions.upsertManyStepProgressesSuccess;
  actions.push(stepProgressActionCreator({ stepProgresses: normalizedStepProgresses }));

  return actions;
}

function stepProgressNormalizeStratalot(
  stepProgress: StepProgressEntityState,
  stratalotsToNormalize: StratalotEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (stepProgress.stratalots?.length && typeof stepProgress.stratalots[0] !== 'number') {
    const idsStratalots: number[] = [];
    stratalotsToNormalize.push(
      ...(stepProgress.stratalots as Stratalot[]).map((stratalot: Stratalot) => {
        idsStratalots.push(stratalot.idStratalot);
        return { ...stratalot, stepProgress: stepProgress.idStepProgress };
      })
    );
    relations['stratalots'] = idsStratalots;
  }
}

function stepProgressNormalizeStep(
  stepProgress: StepProgressEntityState,
  stepsToNormalize: { [id: number]: StepEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stepProgress.step && typeof stepProgress.step !== 'number') {
    if (!stepsToNormalize[stepProgress.step.idStep]) {
      stepsToNormalize[stepProgress.step.idStep] = { ...stepProgress.step, stepProgresses: [] };
    }
    (stepsToNormalize[stepProgress.step.idStep].stepProgresses as number[]).push(stepProgress.idStepProgress);
    relations['step'] = stepProgress.step.idStep;
  }
}

function stepProgressNormalizeStepProgressFamily(
  stepProgress: StepProgressEntityState,
  stepProgressesFamiliesToNormalize: { [id: number]: StepProgressFamilyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stepProgress.stepProgressFamily && typeof stepProgress.stepProgressFamily !== 'number') {
    if (!stepProgressesFamiliesToNormalize[stepProgress.stepProgressFamily.idStepProgressFamily]) {
      stepProgressesFamiliesToNormalize[stepProgress.stepProgressFamily.idStepProgressFamily] = {
        ...stepProgress.stepProgressFamily,
        stepProgresses: []
      };
    }
    (
      stepProgressesFamiliesToNormalize[stepProgress.stepProgressFamily.idStepProgressFamily].stepProgresses as number[]
    ).push(stepProgress.idStepProgress);
    relations['stepProgressFamily'] = stepProgress.stepProgressFamily.idStepProgressFamily;
  }
}

/**
 *  StepProgressFamily
 */
export function getActionsToNormalizeStepProgressFamily(
  stepProgressesFamilies: StepProgressFamilyEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const stepProgressesToNormalize: StepProgressEntityState[] = [];

  const normalizedStepProgressesFamilies = stepProgressesFamilies.map(
    (stepProgressFamily: StepProgressFamilyEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      stepProgressFamilyNormalizeStepProgress(stepProgressFamily, stepProgressesToNormalize, relations);

      return {
        ...stepProgressFamily,
        ...relations
      };
    }
  );
  if (stepProgressesToNormalize.length) {
    actions.push(...getActionsToNormalizeStepProgress(stepProgressesToNormalize, actionType));
  }

  const stepProgressFamilyActionCreator = stepProgressFamilyActions.upsertManyStepProgressesFamiliesSuccess;
  actions.push(stepProgressFamilyActionCreator({ stepProgressesFamilies: normalizedStepProgressesFamilies }));

  return actions;
}

function stepProgressFamilyNormalizeStepProgress(
  stepProgressFamily: StepProgressFamilyEntityState,
  stepProgressesToNormalize: StepProgressEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (stepProgressFamily.stepProgresses?.length && typeof stepProgressFamily.stepProgresses[0] !== 'number') {
    const idsStepProgresses: number[] = [];
    stepProgressesToNormalize.push(
      ...(stepProgressFamily.stepProgresses as StepProgress[]).map((stepProgress: StepProgress) => {
        idsStepProgresses.push(stepProgress.idStepProgress);
        return { ...stepProgress, stepProgressFamily: stepProgressFamily.idStepProgressFamily };
      })
    );
    relations['stepProgresses'] = idsStepProgresses;
  }
}

/**
 *  StratalotFee
 */
export function getActionsToNormalizeStratalotFee(
  stratalotFees: StratalotFeeEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const stratalotsToNormalize: { [id: number]: StratalotEntityState } = {};

  const normalizedStratalotFees = stratalotFees.map((stratalotFee: StratalotFeeEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    stratalotFeeNormalizeStratalot(stratalotFee, stratalotsToNormalize, relations);

    return {
      ...stratalotFee,
      ...relations
    };
  });
  const stratalotsToNormalizeArray = Object.values(stratalotsToNormalize);
  if (stratalotsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalizeArray, actionType));
  }

  const stratalotFeeActionCreator = stratalotFeeActions.upsertManyStratalotFeesSuccess;
  actions.push(stratalotFeeActionCreator({ stratalotFees: normalizedStratalotFees }));

  return actions;
}

function stratalotFeeNormalizeStratalot(
  stratalotFee: StratalotFeeEntityState,
  stratalotsToNormalize: { [id: number]: StratalotEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotFee.stratalot && typeof stratalotFee.stratalot !== 'number') {
    if (!stratalotsToNormalize[stratalotFee.stratalot.idStratalot]) {
      stratalotsToNormalize[stratalotFee.stratalot.idStratalot] = { ...stratalotFee.stratalot, stratalotFees: [] };
    }
    (stratalotsToNormalize[stratalotFee.stratalot.idStratalot].stratalotFees as number[]).push(
      stratalotFee.idStratalotFee
    );
    relations['stratalot'] = stratalotFee.stratalot.idStratalot;
  }
}

/**
 *  Stratalot
 */
export function getActionsToNormalizeStratalot(
  stratalots: StratalotEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residencesToNormalize: { [id: number]: ResidenceEntityState } = {};
  const occupantsToNormalize: { [id: number]: OccupantEntityState } = {};
  const companyStratalotTypesToNormalize: { [id: number]: CompanyStratalotTypeEntityState } = {};
  const companyStratalotVacantsToNormalize: { [id: number]: CompanyStratalotVacantEntityState } = {};
  const organizationSaleCategoriesToNormalize: { [id: number]: OrganizationSaleCategoryEntityState } = {};
  const stepProgressesToNormalize: { [id: number]: StepProgressEntityState } = {};
  const responsableToNormalize: { [id: number]: UserEntityState } = {};
  const organizationStratalotAvancementsToNormalize: { [id: number]: OrganizationStratalotAvancementEntityState } = {};
  const stratalotCampaignsToNormalize: StratalotCampaignEntityState[] = [];
  const diffusionVisualsToNormalize: DiffusionVisualEntityState[] = [];
  const stratalotAssociationsToNormalize: StratalotAssociationEntityState[] = [];
  const stratalotFeesToNormalize: StratalotFeeEntityState[] = [];
  const associationsToNormalize: { [id: number]: AssociationEntityState } = {};
  const leadsToNormalize: { [id: number]: LeadEntityState } = {};
  const leadStratalotsToNormalize: LeadStratalotEntityState[] = [];
  const stratalotPricesToNormalize: StratalotPriceEntityState[] = [];
  const stratalotRcpsToNormalize: StratalotRcpEntityState[] = [];
  const stratalotTodosToNormalize: StratalotTodoEntityState[] = [];

  const normalizedStratalots = stratalots.map((stratalot: StratalotEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    stratalotNormalizeStratalotCampaign(stratalot, stratalotCampaignsToNormalize, relations);
    stratalotNormalizeDiffusionVisual(stratalot, diffusionVisualsToNormalize, relations);
    stratalotNormalizeStratalotAssociation(stratalot, stratalotAssociationsToNormalize, relations);
    stratalotNormalizeStratalotFee(stratalot, stratalotFeesToNormalize, relations);
    stratalotNormalizeAssociation(stratalot, associationsToNormalize, relations);
    stratalotNormalizeLead(stratalot, leadsToNormalize, relations);
    stratalotNormalizeLeadStratalot(stratalot, leadStratalotsToNormalize, relations);
    stratalotNormalizeStratalotPrice(stratalot, stratalotPricesToNormalize, relations);
    stratalotNormalizeStratalotRcp(stratalot, stratalotRcpsToNormalize, relations);
    stratalotNormalizeStratalotTodo(stratalot, stratalotTodosToNormalize, relations);
    stratalotNormalizeResidence(stratalot, residencesToNormalize, relations);
    stratalotNormalizeOccupant(stratalot, occupantsToNormalize, relations);
    stratalotNormalizeCompanyStratalotType(stratalot, companyStratalotTypesToNormalize, relations);
    stratalotNormalizeCompanyStratalotVacant(stratalot, companyStratalotVacantsToNormalize, relations);
    stratalotNormalizeOrganizationSaleCategory(stratalot, organizationSaleCategoriesToNormalize, relations);
    stratalotNormalizeStepProgress(stratalot, stepProgressesToNormalize, relations);
    stratalotNormalizeResponsable(stratalot, responsableToNormalize, relations);
    stratalotNormalizeOrganizationStratalotAvancement(
      stratalot,
      organizationStratalotAvancementsToNormalize,
      relations
    );

    return {
      ...stratalot,
      ...relations
    };
  });
  const residencesToNormalizeArray = Object.values(residencesToNormalize);
  if (residencesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalizeArray, actionType));
  }
  const occupantsToNormalizeArray = Object.values(occupantsToNormalize);
  if (occupantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOccupant(occupantsToNormalizeArray, actionType));
  }
  const companyStratalotTypesToNormalizeArray = Object.values(companyStratalotTypesToNormalize);
  if (companyStratalotTypesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyStratalotType(companyStratalotTypesToNormalizeArray, actionType));
  }
  const companyStratalotVacantsToNormalizeArray = Object.values(companyStratalotVacantsToNormalize);
  if (companyStratalotVacantsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyStratalotVacant(companyStratalotVacantsToNormalizeArray, actionType));
  }
  const organizationSaleCategoriesToNormalizeArray = Object.values(organizationSaleCategoriesToNormalize);
  if (organizationSaleCategoriesToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationSaleCategory(organizationSaleCategoriesToNormalizeArray, actionType)
    );
  }
  const stepProgressesToNormalizeArray = Object.values(stepProgressesToNormalize);
  if (stepProgressesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStepProgress(stepProgressesToNormalizeArray, actionType));
  }
  const responsableToNormalizeArray = Object.values(responsableToNormalize);
  if (responsableToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(responsableToNormalizeArray, actionType));
  }
  const organizationStratalotAvancementsToNormalizeArray = Object.values(organizationStratalotAvancementsToNormalize);
  if (organizationStratalotAvancementsToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotAvancement(
        organizationStratalotAvancementsToNormalizeArray,
        actionType
      )
    );
  }
  if (stratalotCampaignsToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalotCampaign(stratalotCampaignsToNormalize, actionType));
  }
  if (diffusionVisualsToNormalize.length) {
    actions.push(...getActionsToNormalizeDiffusionVisual(diffusionVisualsToNormalize, actionType));
  }
  if (stratalotAssociationsToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalotAssociation(stratalotAssociationsToNormalize, actionType));
  }
  if (stratalotFeesToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalotFee(stratalotFeesToNormalize, actionType));
  }
  const associationsToNormalizeArray = Object.values(associationsToNormalize);
  if (associationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeAssociation(associationsToNormalizeArray, actionType));
  }
  const leadsToNormalizeArray = Object.values(leadsToNormalize);
  if (leadsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeLead(leadsToNormalizeArray, actionType));
  }
  if (leadStratalotsToNormalize.length) {
    actions.push(...getActionsToNormalizeLeadStratalot(leadStratalotsToNormalize, actionType));
  }
  if (stratalotPricesToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalotPrice(stratalotPricesToNormalize, actionType));
  }
  if (stratalotRcpsToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalotRcp(stratalotRcpsToNormalize, actionType));
  }
  if (stratalotTodosToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalotTodo(stratalotTodosToNormalize, actionType));
  }

  const stratalotActionCreator = stratalotActions.upsertManyStratalotsSuccess;
  actions.push(stratalotActionCreator({ stratalots: normalizedStratalots }));

  return actions;
}

function stratalotNormalizeStratalotCampaign(
  stratalot: StratalotEntityState,
  stratalotCampaignsToNormalize: StratalotCampaignEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.stratalotCampaigns?.length && typeof stratalot.stratalotCampaigns[0] !== 'number') {
    const idsStratalotCampaigns: number[] = [];
    stratalotCampaignsToNormalize.push(
      ...(stratalot.stratalotCampaigns as StratalotCampaign[]).map((stratalotCampaign: StratalotCampaign) => {
        idsStratalotCampaigns.push(stratalotCampaign.idStratalotCampaign);
        return { ...stratalotCampaign, stratalot: stratalot.idStratalot };
      })
    );
    relations['stratalotCampaigns'] = idsStratalotCampaigns;
  }
}

function stratalotNormalizeDiffusionVisual(
  stratalot: StratalotEntityState,
  diffusionVisualsToNormalize: DiffusionVisualEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.diffusionVisuals?.length && typeof stratalot.diffusionVisuals[0] !== 'number') {
    const idsDiffusionVisuals: number[] = [];
    diffusionVisualsToNormalize.push(
      ...(stratalot.diffusionVisuals as DiffusionVisual[]).map((diffusionVisual: DiffusionVisual) => {
        idsDiffusionVisuals.push(diffusionVisual.idDiffusionVisual);
        return { ...diffusionVisual, stratalot: stratalot.idStratalot };
      })
    );
    relations['diffusionVisuals'] = idsDiffusionVisuals;
  }
}

function stratalotNormalizeStratalotAssociation(
  stratalot: StratalotEntityState,
  stratalotAssociationsToNormalize: StratalotAssociationEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.stratalotAssociations?.length && typeof stratalot.stratalotAssociations[0] !== 'number') {
    const idsStratalotAssociations: number[] = [];
    stratalotAssociationsToNormalize.push(
      ...(stratalot.stratalotAssociations as StratalotAssociation[]).map(
        (stratalotAssociation: StratalotAssociation) => {
          idsStratalotAssociations.push(stratalotAssociation.idStratalotAssociation);
          return { ...stratalotAssociation, stratalot: stratalot.idStratalot };
        }
      )
    );
    relations['stratalotAssociations'] = idsStratalotAssociations;
  }
}

function stratalotNormalizeStratalotFee(
  stratalot: StratalotEntityState,
  stratalotFeesToNormalize: StratalotFeeEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.stratalotFees?.length && typeof stratalot.stratalotFees[0] !== 'number') {
    const idsStratalotFees: number[] = [];
    stratalotFeesToNormalize.push(
      ...(stratalot.stratalotFees as StratalotFee[]).map((stratalotFee: StratalotFee) => {
        idsStratalotFees.push(stratalotFee.idStratalotFee);
        return { ...stratalotFee, stratalot: stratalot.idStratalot };
      })
    );
    relations['stratalotFees'] = idsStratalotFees;
  }
}

function stratalotNormalizeAssociation(
  stratalot: StratalotEntityState,
  associationsToNormalize: { [id: number]: AssociationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.associations?.length && typeof stratalot.associations[0] !== 'number') {
    const idsAssociations: number[] = (stratalot.associations as Association[]).map((association: Association) => {
      if (!associationsToNormalize[association.idAssociation]) {
        associationsToNormalize[association.idAssociation] = { ...association, stratalots: [] };
      }
      (associationsToNormalize[association.idAssociation].stratalots as number[]).push(stratalot.idStratalot);
      return association.idAssociation;
    });
    relations['associations'] = idsAssociations;
  }
}

function stratalotNormalizeLead(
  stratalot: StratalotEntityState,
  leadsToNormalize: { [id: number]: LeadEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.leads?.length && typeof stratalot.leads[0] !== 'number') {
    const idsLeads: number[] = (stratalot.leads as Lead[]).map((lead: Lead) => {
      if (!leadsToNormalize[lead.idLead]) {
        leadsToNormalize[lead.idLead] = { ...lead, stratalots: [] };
      }
      (leadsToNormalize[lead.idLead].stratalots as number[]).push(stratalot.idStratalot);
      return lead.idLead;
    });
    relations['leads'] = idsLeads;
  }
}

function stratalotNormalizeLeadStratalot(
  stratalot: StratalotEntityState,
  leadStratalotsToNormalize: LeadStratalotEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.leadStratalots?.length && typeof stratalot.leadStratalots[0] !== 'number') {
    const idsLeadStratalots: number[] = [];
    leadStratalotsToNormalize.push(
      ...(stratalot.leadStratalots as LeadStratalot[]).map((leadStratalot: LeadStratalot) => {
        idsLeadStratalots.push(leadStratalot.idLeadStratalot);
        return { ...leadStratalot, stratalot: stratalot.idStratalot };
      })
    );
    relations['leadStratalots'] = idsLeadStratalots;
  }
}

function stratalotNormalizeStratalotPrice(
  stratalot: StratalotEntityState,
  stratalotPricesToNormalize: StratalotPriceEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.stratalotPrices?.length && typeof stratalot.stratalotPrices[0] !== 'number') {
    const idsStratalotPrices: number[] = [];
    stratalotPricesToNormalize.push(
      ...(stratalot.stratalotPrices as StratalotPrice[]).map((stratalotPrice: StratalotPrice) => {
        idsStratalotPrices.push(stratalotPrice.idStratalotPrice);
        return { ...stratalotPrice, stratalot: stratalot.idStratalot };
      })
    );
    relations['stratalotPrices'] = idsStratalotPrices;
  }
}

function stratalotNormalizeStratalotRcp(
  stratalot: StratalotEntityState,
  stratalotRcpsToNormalize: StratalotRcpEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.stratalotRcp && typeof stratalot.stratalotRcp !== 'number') {
    stratalotRcpsToNormalize.push({ ...stratalot.stratalotRcp, stratalot: stratalot.idStratalot });
    relations['stratalotRcp'] = stratalot.stratalotRcp.idStratalotRcp;
  }
}

function stratalotNormalizeStratalotTodo(
  stratalot: StratalotEntityState,
  stratalotTodosToNormalize: StratalotTodoEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.stratalotTodos?.length && typeof stratalot.stratalotTodos[0] !== 'number') {
    const idsStratalotTodos: number[] = [];
    stratalotTodosToNormalize.push(
      ...(stratalot.stratalotTodos as StratalotTodo[]).map((stratalotTodo: StratalotTodo) => {
        idsStratalotTodos.push(stratalotTodo.idStratalotTodo);
        return { ...stratalotTodo, stratalot: stratalot.idStratalot };
      })
    );
    relations['stratalotTodos'] = idsStratalotTodos;
  }
}

function stratalotNormalizeResidence(
  stratalot: StratalotEntityState,
  residencesToNormalize: { [id: number]: ResidenceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.residence && typeof stratalot.residence !== 'number') {
    if (!residencesToNormalize[stratalot.residence.idResidence]) {
      residencesToNormalize[stratalot.residence.idResidence] = { ...stratalot.residence, stratalots: [] };
    }
    (residencesToNormalize[stratalot.residence.idResidence].stratalots as number[]).push(stratalot.idStratalot);
    relations['residence'] = stratalot.residence.idResidence;
  }
}

function stratalotNormalizeOccupant(
  stratalot: StratalotEntityState,
  occupantsToNormalize: { [id: number]: OccupantEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.occupant && typeof stratalot.occupant !== 'number') {
    if (!occupantsToNormalize[stratalot.occupant.idOccupant]) {
      occupantsToNormalize[stratalot.occupant.idOccupant] = { ...stratalot.occupant, stratalots: [] };
    }
    (occupantsToNormalize[stratalot.occupant.idOccupant].stratalots as number[]).push(stratalot.idStratalot);
    relations['occupant'] = stratalot.occupant.idOccupant;
  }
}

function stratalotNormalizeCompanyStratalotType(
  stratalot: StratalotEntityState,
  companyStratalotTypesToNormalize: { [id: number]: CompanyStratalotTypeEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.companyStratalotType && typeof stratalot.companyStratalotType !== 'number') {
    if (!companyStratalotTypesToNormalize[stratalot.companyStratalotType.idCompanyStratalotType]) {
      companyStratalotTypesToNormalize[stratalot.companyStratalotType.idCompanyStratalotType] = {
        ...stratalot.companyStratalotType,
        stratalots: []
      };
    }
    (
      companyStratalotTypesToNormalize[stratalot.companyStratalotType.idCompanyStratalotType].stratalots as number[]
    ).push(stratalot.idStratalot);
    relations['companyStratalotType'] = stratalot.companyStratalotType.idCompanyStratalotType;
  }
}

function stratalotNormalizeCompanyStratalotVacant(
  stratalot: StratalotEntityState,
  companyStratalotVacantsToNormalize: { [id: number]: CompanyStratalotVacantEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.companyStratalotVacant && typeof stratalot.companyStratalotVacant !== 'number') {
    if (!companyStratalotVacantsToNormalize[stratalot.companyStratalotVacant.idCompanyStratalotVacant]) {
      companyStratalotVacantsToNormalize[stratalot.companyStratalotVacant.idCompanyStratalotVacant] = {
        ...stratalot.companyStratalotVacant,
        stratalots: []
      };
    }
    (
      companyStratalotVacantsToNormalize[stratalot.companyStratalotVacant.idCompanyStratalotVacant]
        .stratalots as number[]
    ).push(stratalot.idStratalot);
    relations['companyStratalotVacant'] = stratalot.companyStratalotVacant.idCompanyStratalotVacant;
  }
}

function stratalotNormalizeOrganizationSaleCategory(
  stratalot: StratalotEntityState,
  organizationSaleCategoriesToNormalize: { [id: number]: OrganizationSaleCategoryEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.organizationSaleCategory && typeof stratalot.organizationSaleCategory !== 'number') {
    if (!organizationSaleCategoriesToNormalize[stratalot.organizationSaleCategory.idOrganizationSaleCategory]) {
      organizationSaleCategoriesToNormalize[stratalot.organizationSaleCategory.idOrganizationSaleCategory] = {
        ...stratalot.organizationSaleCategory,
        stratalots: []
      };
    }
    (
      organizationSaleCategoriesToNormalize[stratalot.organizationSaleCategory.idOrganizationSaleCategory]
        .stratalots as number[]
    ).push(stratalot.idStratalot);
    relations['organizationSaleCategory'] = stratalot.organizationSaleCategory.idOrganizationSaleCategory;
  }
}

function stratalotNormalizeStepProgress(
  stratalot: StratalotEntityState,
  stepProgressesToNormalize: { [id: number]: StepProgressEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.stepProgress && typeof stratalot.stepProgress !== 'number') {
    if (!stepProgressesToNormalize[stratalot.stepProgress.idStepProgress]) {
      stepProgressesToNormalize[stratalot.stepProgress.idStepProgress] = { ...stratalot.stepProgress, stratalots: [] };
    }
    (stepProgressesToNormalize[stratalot.stepProgress.idStepProgress].stratalots as number[]).push(
      stratalot.idStratalot
    );
    relations['stepProgress'] = stratalot.stepProgress.idStepProgress;
  }
}

function stratalotNormalizeResponsable(
  stratalot: StratalotEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.responsable && typeof stratalot.responsable !== 'number') {
    if (!usersToNormalize[stratalot.responsable.idUser]) {
      usersToNormalize[stratalot.responsable.idUser] = { ...stratalot.responsable, stratalots: [] };
    }
    (usersToNormalize[stratalot.responsable.idUser].stratalots as number[]).push(stratalot.idStratalot);
    relations['responsable'] = stratalot.responsable.idUser;
  }
}

function stratalotNormalizeOrganizationStratalotAvancement(
  stratalot: StratalotEntityState,
  organizationStratalotAvancementsToNormalize: { [id: number]: OrganizationStratalotAvancementEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalot.organizationStratalotAvancement && typeof stratalot.organizationStratalotAvancement !== 'number') {
    if (
      !organizationStratalotAvancementsToNormalize[
        stratalot.organizationStratalotAvancement.idOrganizationStratalotAvancement
      ]
    ) {
      organizationStratalotAvancementsToNormalize[
        stratalot.organizationStratalotAvancement.idOrganizationStratalotAvancement
      ] = { ...stratalot.organizationStratalotAvancement, stratalots: [] };
    }
    (
      organizationStratalotAvancementsToNormalize[
        stratalot.organizationStratalotAvancement.idOrganizationStratalotAvancement
      ].stratalots as number[]
    ).push(stratalot.idStratalot);
    relations['organizationStratalotAvancement'] =
      stratalot.organizationStratalotAvancement.idOrganizationStratalotAvancement;
  }
}

/**
 *  StratalotCampaign
 */
export function getActionsToNormalizeStratalotCampaign(
  stratalotCampaigns: StratalotCampaignEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const stratalotsToNormalize: { [id: number]: StratalotEntityState } = {};
  const campaignMediasToNormalize: CampaignMediaEntityState[] = [];

  const normalizedStratalotCampaigns = stratalotCampaigns.map((stratalotCampaign: StratalotCampaignEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    stratalotCampaignNormalizeCampaignMedia(stratalotCampaign, campaignMediasToNormalize, relations);
    stratalotCampaignNormalizeStratalot(stratalotCampaign, stratalotsToNormalize, relations);

    return {
      ...stratalotCampaign,
      ...relations
    };
  });
  const stratalotsToNormalizeArray = Object.values(stratalotsToNormalize);
  if (stratalotsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalizeArray, actionType));
  }
  if (campaignMediasToNormalize.length) {
    actions.push(...getActionsToNormalizeCampaignMedia(campaignMediasToNormalize, actionType));
  }

  const stratalotCampaignActionCreator = stratalotCampaignActions.upsertManyStratalotCampaignsSuccess;
  actions.push(stratalotCampaignActionCreator({ stratalotCampaigns: normalizedStratalotCampaigns }));

  return actions;
}

function stratalotCampaignNormalizeCampaignMedia(
  stratalotCampaign: StratalotCampaignEntityState,
  campaignMediasToNormalize: CampaignMediaEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotCampaign.campaignMedias?.length && typeof stratalotCampaign.campaignMedias[0] !== 'number') {
    const idsCampaignMedias: number[] = [];
    campaignMediasToNormalize.push(
      ...(stratalotCampaign.campaignMedias as CampaignMedia[]).map((campaignMedia: CampaignMedia) => {
        idsCampaignMedias.push(campaignMedia.idCampaignMedia);
        return { ...campaignMedia, stratalotCampaign: stratalotCampaign.idStratalotCampaign };
      })
    );
    relations['campaignMedias'] = idsCampaignMedias;
  }
}

function stratalotCampaignNormalizeStratalot(
  stratalotCampaign: StratalotCampaignEntityState,
  stratalotsToNormalize: { [id: number]: StratalotEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotCampaign.stratalot && typeof stratalotCampaign.stratalot !== 'number') {
    if (!stratalotsToNormalize[stratalotCampaign.stratalot.idStratalot]) {
      stratalotsToNormalize[stratalotCampaign.stratalot.idStratalot] = {
        ...stratalotCampaign.stratalot,
        stratalotCampaigns: []
      };
    }
    (stratalotsToNormalize[stratalotCampaign.stratalot.idStratalot].stratalotCampaigns as number[]).push(
      stratalotCampaign.idStratalotCampaign
    );
    relations['stratalot'] = stratalotCampaign.stratalot.idStratalot;
  }
}

/**
 *  StratalotPrice
 */
export function getActionsToNormalizeStratalotPrice(
  stratalotPrices: StratalotPriceEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const stratalotsToNormalize: { [id: number]: StratalotEntityState } = {};
  const residencePriceGridsToNormalize: { [id: number]: ResidencePriceGridEntityState } = {};
  const companyPriceLabelsToNormalize: { [id: number]: CompanyPriceLabelEntityState } = {};
  const stratalotPriceValuesToNormalize: StratalotPriceValueEntityState[] = [];

  const normalizedStratalotPrices = stratalotPrices.map((stratalotPrice: StratalotPriceEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    stratalotPriceNormalizeCompanyPriceLabel(stratalotPrice, companyPriceLabelsToNormalize, relations);
    stratalotPriceNormalizeStratalotPriceValue(stratalotPrice, stratalotPriceValuesToNormalize, relations);
    stratalotPriceNormalizeStratalot(stratalotPrice, stratalotsToNormalize, relations);
    stratalotPriceNormalizeResidencePriceGrid(stratalotPrice, residencePriceGridsToNormalize, relations);

    return {
      ...stratalotPrice,
      ...relations
    };
  });
  const stratalotsToNormalizeArray = Object.values(stratalotsToNormalize);
  if (stratalotsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalizeArray, actionType));
  }
  const residencePriceGridsToNormalizeArray = Object.values(residencePriceGridsToNormalize);
  if (residencePriceGridsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidencePriceGrid(residencePriceGridsToNormalizeArray, actionType));
  }
  const companyPriceLabelsToNormalizeArray = Object.values(companyPriceLabelsToNormalize);
  if (companyPriceLabelsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyPriceLabel(companyPriceLabelsToNormalizeArray, actionType));
  }
  if (stratalotPriceValuesToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalotPriceValue(stratalotPriceValuesToNormalize, actionType));
  }

  const stratalotPriceActionCreator = stratalotPriceActions.upsertManyStratalotPricesSuccess;
  actions.push(stratalotPriceActionCreator({ stratalotPrices: normalizedStratalotPrices }));

  return actions;
}

function stratalotPriceNormalizeCompanyPriceLabel(
  stratalotPrice: StratalotPriceEntityState,
  companyPriceLabelsToNormalize: { [id: number]: CompanyPriceLabelEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotPrice.companyPriceLabels?.length && typeof stratalotPrice.companyPriceLabels[0] !== 'number') {
    const idsCompanyPriceLabels: number[] = (stratalotPrice.companyPriceLabels as CompanyPriceLabel[]).map(
      (companyPriceLabel: CompanyPriceLabel) => {
        if (!companyPriceLabelsToNormalize[companyPriceLabel.idCompanyPriceLabel]) {
          companyPriceLabelsToNormalize[companyPriceLabel.idCompanyPriceLabel] = {
            ...companyPriceLabel,
            stratalotPrices: []
          };
        }
        (companyPriceLabelsToNormalize[companyPriceLabel.idCompanyPriceLabel].stratalotPrices as number[]).push(
          stratalotPrice.idStratalotPrice
        );
        return companyPriceLabel.idCompanyPriceLabel;
      }
    );
    relations['companyPriceLabels'] = idsCompanyPriceLabels;
  }
}

function stratalotPriceNormalizeStratalotPriceValue(
  stratalotPrice: StratalotPriceEntityState,
  stratalotPriceValuesToNormalize: StratalotPriceValueEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotPrice.stratalotPriceValues?.length && typeof stratalotPrice.stratalotPriceValues[0] !== 'number') {
    const idsStratalotPriceValues: number[] = [];
    stratalotPriceValuesToNormalize.push(
      ...(stratalotPrice.stratalotPriceValues as StratalotPriceValue[]).map(
        (stratalotPriceValue: StratalotPriceValue) => {
          idsStratalotPriceValues.push(stratalotPriceValue.idStratalotPriceValue);
          return { ...stratalotPriceValue, stratalotPrice: stratalotPrice.idStratalotPrice };
        }
      )
    );
    relations['stratalotPriceValues'] = idsStratalotPriceValues;
  }
}

function stratalotPriceNormalizeStratalot(
  stratalotPrice: StratalotPriceEntityState,
  stratalotsToNormalize: { [id: number]: StratalotEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotPrice.stratalot && typeof stratalotPrice.stratalot !== 'number') {
    if (!stratalotsToNormalize[stratalotPrice.stratalot.idStratalot]) {
      stratalotsToNormalize[stratalotPrice.stratalot.idStratalot] = {
        ...stratalotPrice.stratalot,
        stratalotPrices: []
      };
    }
    (stratalotsToNormalize[stratalotPrice.stratalot.idStratalot].stratalotPrices as number[]).push(
      stratalotPrice.idStratalotPrice
    );
    relations['stratalot'] = stratalotPrice.stratalot.idStratalot;
  }
}

function stratalotPriceNormalizeResidencePriceGrid(
  stratalotPrice: StratalotPriceEntityState,
  residencePriceGridsToNormalize: { [id: number]: ResidencePriceGridEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotPrice.residencePriceGrid && typeof stratalotPrice.residencePriceGrid !== 'number') {
    if (!residencePriceGridsToNormalize[stratalotPrice.residencePriceGrid.idResidencePriceGrid]) {
      residencePriceGridsToNormalize[stratalotPrice.residencePriceGrid.idResidencePriceGrid] = {
        ...stratalotPrice.residencePriceGrid,
        stratalotPrices: []
      };
    }
    (
      residencePriceGridsToNormalize[stratalotPrice.residencePriceGrid.idResidencePriceGrid].stratalotPrices as number[]
    ).push(stratalotPrice.idStratalotPrice);
    relations['residencePriceGrid'] = stratalotPrice.residencePriceGrid.idResidencePriceGrid;
  }
}

/**
 *  ResidencePriceGrid
 */
export function getActionsToNormalizeResidencePriceGrid(
  residencePriceGrids: ResidencePriceGridEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residencesToNormalize: { [id: number]: ResidenceEntityState } = {};
  const stratalotPriceListsToNormalize: StratalotPriceListEntityState[] = [];
  const stratalotPricesToNormalize: StratalotPriceEntityState[] = [];
  const residencePriceGridModifiersToNormalize: ResidencePriceGridModifierEntityState[] = [];
  const residencePriceGridHistoriesToNormalize: ResidencePriceGridHistoryEntityState[] = [];

  const normalizedResidencePriceGrids = residencePriceGrids.map((residencePriceGrid: ResidencePriceGridEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    residencePriceGridNormalizeStratalotPriceList(residencePriceGrid, stratalotPriceListsToNormalize, relations);
    residencePriceGridNormalizeStratalotPrice(residencePriceGrid, stratalotPricesToNormalize, relations);
    residencePriceGridNormalizeResidencePriceGridModifier(
      residencePriceGrid,
      residencePriceGridModifiersToNormalize,
      relations
    );
    residencePriceGridNormalizeResidencePriceGridHistory(
      residencePriceGrid,
      residencePriceGridHistoriesToNormalize,
      relations
    );
    residencePriceGridNormalizeResidence(residencePriceGrid, residencesToNormalize, relations);

    return {
      ...residencePriceGrid,
      ...relations
    };
  });
  const residencesToNormalizeArray = Object.values(residencesToNormalize);
  if (residencesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalizeArray, actionType));
  }
  if (stratalotPriceListsToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalotPriceList(stratalotPriceListsToNormalize, actionType));
  }
  if (stratalotPricesToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalotPrice(stratalotPricesToNormalize, actionType));
  }
  if (residencePriceGridModifiersToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeResidencePriceGridModifier(residencePriceGridModifiersToNormalize, actionType)
    );
  }
  if (residencePriceGridHistoriesToNormalize.length) {
    actions.push(...getActionsToNormalizeResidencePriceGridHistory(residencePriceGridHistoriesToNormalize, actionType));
  }

  const residencePriceGridActionCreator = residencePriceGridActions.upsertManyResidencePriceGridsSuccess;
  actions.push(residencePriceGridActionCreator({ residencePriceGrids: normalizedResidencePriceGrids }));

  return actions;
}

function residencePriceGridNormalizeStratalotPriceList(
  residencePriceGrid: ResidencePriceGridEntityState,
  stratalotPriceListsToNormalize: StratalotPriceListEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residencePriceGrid.stratalotPriceLists?.length && typeof residencePriceGrid.stratalotPriceLists[0] !== 'number') {
    const idsStratalotPriceLists: number[] = [];
    stratalotPriceListsToNormalize.push(
      ...(residencePriceGrid.stratalotPriceLists as StratalotPriceList[]).map(
        (stratalotPriceList: StratalotPriceList) => {
          idsStratalotPriceLists.push(stratalotPriceList.idStratalotPriceList);
          return { ...stratalotPriceList, residencePriceGrid: residencePriceGrid.idResidencePriceGrid };
        }
      )
    );
    relations['stratalotPriceLists'] = idsStratalotPriceLists;
  }
}

function residencePriceGridNormalizeStratalotPrice(
  residencePriceGrid: ResidencePriceGridEntityState,
  stratalotPricesToNormalize: StratalotPriceEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (residencePriceGrid.stratalotPrices?.length && typeof residencePriceGrid.stratalotPrices[0] !== 'number') {
    const idsStratalotPrices: number[] = [];
    stratalotPricesToNormalize.push(
      ...(residencePriceGrid.stratalotPrices as StratalotPrice[]).map((stratalotPrice: StratalotPrice) => {
        idsStratalotPrices.push(stratalotPrice.idStratalotPrice);
        return { ...stratalotPrice, residencePriceGrid: residencePriceGrid.idResidencePriceGrid };
      })
    );
    relations['stratalotPrices'] = idsStratalotPrices;
  }
}

function residencePriceGridNormalizeResidencePriceGridModifier(
  residencePriceGrid: ResidencePriceGridEntityState,
  residencePriceGridModifiersToNormalize: ResidencePriceGridModifierEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    residencePriceGrid.residencePriceGridModifiers?.length &&
    typeof residencePriceGrid.residencePriceGridModifiers[0] !== 'number'
  ) {
    const idsResidencePriceGridModifiers: number[] = [];
    residencePriceGridModifiersToNormalize.push(
      ...(residencePriceGrid.residencePriceGridModifiers as ResidencePriceGridModifier[]).map(
        (residencePriceGridModifier: ResidencePriceGridModifier) => {
          idsResidencePriceGridModifiers.push(residencePriceGridModifier.idResidencePriceGridModifier);
          return { ...residencePriceGridModifier, residencePriceGrid: residencePriceGrid.idResidencePriceGrid };
        }
      )
    );
    relations['residencePriceGridModifiers'] = idsResidencePriceGridModifiers;
  }
}

function residencePriceGridNormalizeResidencePriceGridHistory(
  residencePriceGrid: ResidencePriceGridEntityState,
  residencePriceGridHistoriesToNormalize: ResidencePriceGridHistoryEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    residencePriceGrid.residencePriceGridHistories?.length &&
    typeof residencePriceGrid.residencePriceGridHistories[0] !== 'number'
  ) {
    const idsResidencePriceGridHistories: number[] = [];
    residencePriceGridHistoriesToNormalize.push(
      ...(residencePriceGrid.residencePriceGridHistories as ResidencePriceGridHistory[]).map(
        (residencePriceGridHistory: ResidencePriceGridHistory) => {
          idsResidencePriceGridHistories.push(residencePriceGridHistory.idResidencePriceGridHistory);
          return { ...residencePriceGridHistory, residencePriceGrid: residencePriceGrid.idResidencePriceGrid };
        }
      )
    );
    relations['residencePriceGridHistories'] = idsResidencePriceGridHistories;
  }
}

function residencePriceGridNormalizeResidence(
  residencePriceGrid: ResidencePriceGridEntityState,
  residencesToNormalize: { [id: number]: ResidenceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residencePriceGrid.residence && typeof residencePriceGrid.residence !== 'number') {
    if (!residencesToNormalize[residencePriceGrid.residence.idResidence]) {
      residencesToNormalize[residencePriceGrid.residence.idResidence] = {
        ...residencePriceGrid.residence,
        residencePriceGrids: []
      };
    }
    (residencesToNormalize[residencePriceGrid.residence.idResidence].residencePriceGrids as number[]).push(
      residencePriceGrid.idResidencePriceGrid
    );
    relations['residence'] = residencePriceGrid.residence.idResidence;
  }
}

/**
 *  StratalotPriceList
 */
export function getActionsToNormalizeStratalotPriceList(
  stratalotPriceLists: StratalotPriceListEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residencePriceGridsToNormalize: { [id: number]: ResidencePriceGridEntityState } = {};
  const companyStratalotTypesToNormalize: { [id: number]: CompanyStratalotTypeEntityState } = {};

  const normalizedStratalotPriceLists = stratalotPriceLists.map((stratalotPriceList: StratalotPriceListEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    stratalotPriceListNormalizeResidencePriceGrid(stratalotPriceList, residencePriceGridsToNormalize, relations);
    stratalotPriceListNormalizeCompanyStratalotType(stratalotPriceList, companyStratalotTypesToNormalize, relations);

    return {
      ...stratalotPriceList,
      ...relations
    };
  });
  const residencePriceGridsToNormalizeArray = Object.values(residencePriceGridsToNormalize);
  if (residencePriceGridsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidencePriceGrid(residencePriceGridsToNormalizeArray, actionType));
  }
  const companyStratalotTypesToNormalizeArray = Object.values(companyStratalotTypesToNormalize);
  if (companyStratalotTypesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyStratalotType(companyStratalotTypesToNormalizeArray, actionType));
  }

  const stratalotPriceListActionCreator = stratalotPriceListActions.upsertManyStratalotPriceListsSuccess;
  actions.push(stratalotPriceListActionCreator({ stratalotPriceLists: normalizedStratalotPriceLists }));

  return actions;
}

function stratalotPriceListNormalizeResidencePriceGrid(
  stratalotPriceList: StratalotPriceListEntityState,
  residencePriceGridsToNormalize: { [id: number]: ResidencePriceGridEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotPriceList.residencePriceGrid && typeof stratalotPriceList.residencePriceGrid !== 'number') {
    if (!residencePriceGridsToNormalize[stratalotPriceList.residencePriceGrid.idResidencePriceGrid]) {
      residencePriceGridsToNormalize[stratalotPriceList.residencePriceGrid.idResidencePriceGrid] = {
        ...stratalotPriceList.residencePriceGrid,
        stratalotPriceLists: []
      };
    }
    (
      residencePriceGridsToNormalize[stratalotPriceList.residencePriceGrid.idResidencePriceGrid]
        .stratalotPriceLists as number[]
    ).push(stratalotPriceList.idStratalotPriceList);
    relations['residencePriceGrid'] = stratalotPriceList.residencePriceGrid.idResidencePriceGrid;
  }
}

function stratalotPriceListNormalizeCompanyStratalotType(
  stratalotPriceList: StratalotPriceListEntityState,
  companyStratalotTypesToNormalize: { [id: number]: CompanyStratalotTypeEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotPriceList.companyStratalotType && typeof stratalotPriceList.companyStratalotType !== 'number') {
    if (!companyStratalotTypesToNormalize[stratalotPriceList.companyStratalotType.idCompanyStratalotType]) {
      companyStratalotTypesToNormalize[stratalotPriceList.companyStratalotType.idCompanyStratalotType] = {
        ...stratalotPriceList.companyStratalotType,
        stratalotPriceLists: []
      };
    }
    (
      companyStratalotTypesToNormalize[stratalotPriceList.companyStratalotType.idCompanyStratalotType]
        .stratalotPriceLists as number[]
    ).push(stratalotPriceList.idStratalotPriceList);
    relations['companyStratalotType'] = stratalotPriceList.companyStratalotType.idCompanyStratalotType;
  }
}

/**
 *  ResidencePriceGridModifier
 */
export function getActionsToNormalizeResidencePriceGridModifier(
  residencePriceGridModifiers: ResidencePriceGridModifierEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residencePriceGridsToNormalize: { [id: number]: ResidencePriceGridEntityState } = {};
  const companyPriceLabelsToNormalize: { [id: number]: CompanyPriceLabelEntityState } = {};

  const normalizedResidencePriceGridModifiers = residencePriceGridModifiers.map(
    (residencePriceGridModifier: ResidencePriceGridModifierEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      residencePriceGridModifierNormalizeResidencePriceGrid(
        residencePriceGridModifier,
        residencePriceGridsToNormalize,
        relations
      );
      residencePriceGridModifierNormalizeCompanyPriceLabel(
        residencePriceGridModifier,
        companyPriceLabelsToNormalize,
        relations
      );

      return {
        ...residencePriceGridModifier,
        ...relations
      };
    }
  );
  const residencePriceGridsToNormalizeArray = Object.values(residencePriceGridsToNormalize);
  if (residencePriceGridsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidencePriceGrid(residencePriceGridsToNormalizeArray, actionType));
  }
  const companyPriceLabelsToNormalizeArray = Object.values(companyPriceLabelsToNormalize);
  if (companyPriceLabelsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyPriceLabel(companyPriceLabelsToNormalizeArray, actionType));
  }

  const residencePriceGridModifierActionCreator =
    residencePriceGridModifierActions.upsertManyResidencePriceGridModifiersSuccess;
  actions.push(
    residencePriceGridModifierActionCreator({ residencePriceGridModifiers: normalizedResidencePriceGridModifiers })
  );

  return actions;
}

function residencePriceGridModifierNormalizeResidencePriceGrid(
  residencePriceGridModifier: ResidencePriceGridModifierEntityState,
  residencePriceGridsToNormalize: { [id: number]: ResidencePriceGridEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    residencePriceGridModifier.residencePriceGrid &&
    typeof residencePriceGridModifier.residencePriceGrid !== 'number'
  ) {
    if (!residencePriceGridsToNormalize[residencePriceGridModifier.residencePriceGrid.idResidencePriceGrid]) {
      residencePriceGridsToNormalize[residencePriceGridModifier.residencePriceGrid.idResidencePriceGrid] = {
        ...residencePriceGridModifier.residencePriceGrid,
        residencePriceGridModifiers: []
      };
    }
    (
      residencePriceGridsToNormalize[residencePriceGridModifier.residencePriceGrid.idResidencePriceGrid]
        .residencePriceGridModifiers as number[]
    ).push(residencePriceGridModifier.idResidencePriceGridModifier);
    relations['residencePriceGrid'] = residencePriceGridModifier.residencePriceGrid.idResidencePriceGrid;
  }
}

function residencePriceGridModifierNormalizeCompanyPriceLabel(
  residencePriceGridModifier: ResidencePriceGridModifierEntityState,
  companyPriceLabelsToNormalize: { [id: number]: CompanyPriceLabelEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    residencePriceGridModifier.companyPriceLabel &&
    typeof residencePriceGridModifier.companyPriceLabel !== 'number'
  ) {
    if (!companyPriceLabelsToNormalize[residencePriceGridModifier.companyPriceLabel.idCompanyPriceLabel]) {
      companyPriceLabelsToNormalize[residencePriceGridModifier.companyPriceLabel.idCompanyPriceLabel] = {
        ...residencePriceGridModifier.companyPriceLabel,
        residencePriceGridModifiers: []
      };
    }
    (
      companyPriceLabelsToNormalize[residencePriceGridModifier.companyPriceLabel.idCompanyPriceLabel]
        .residencePriceGridModifiers as number[]
    ).push(residencePriceGridModifier.idResidencePriceGridModifier);
    relations['companyPriceLabel'] = residencePriceGridModifier.companyPriceLabel.idCompanyPriceLabel;
  }
}

/**
 *  ResidencePriceGridHistory
 */
export function getActionsToNormalizeResidencePriceGridHistory(
  residencePriceGridHistories: ResidencePriceGridHistoryEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residencePriceGridsToNormalize: { [id: number]: ResidencePriceGridEntityState } = {};
  const usersToNormalize: { [id: number]: UserEntityState } = {};

  const normalizedResidencePriceGridHistories = residencePriceGridHistories.map(
    (residencePriceGridHistory: ResidencePriceGridHistoryEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      residencePriceGridHistoryNormalizeResidencePriceGrid(
        residencePriceGridHistory,
        residencePriceGridsToNormalize,
        relations
      );
      residencePriceGridHistoryNormalizeUser(residencePriceGridHistory, usersToNormalize, relations);

      return {
        ...residencePriceGridHistory,
        ...relations
      };
    }
  );
  const residencePriceGridsToNormalizeArray = Object.values(residencePriceGridsToNormalize);
  if (residencePriceGridsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidencePriceGrid(residencePriceGridsToNormalizeArray, actionType));
  }
  const usersToNormalizeArray = Object.values(usersToNormalize);
  if (usersToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeUser(usersToNormalizeArray, actionType));
  }

  const residencePriceGridHistoryActionCreator =
    residencePriceGridHistoryActions.upsertManyResidencePriceGridHistoriesSuccess;
  actions.push(
    residencePriceGridHistoryActionCreator({ residencePriceGridHistories: normalizedResidencePriceGridHistories })
  );

  return actions;
}

function residencePriceGridHistoryNormalizeResidencePriceGrid(
  residencePriceGridHistory: ResidencePriceGridHistoryEntityState,
  residencePriceGridsToNormalize: { [id: number]: ResidencePriceGridEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    residencePriceGridHistory.residencePriceGrid &&
    typeof residencePriceGridHistory.residencePriceGrid !== 'number'
  ) {
    if (!residencePriceGridsToNormalize[residencePriceGridHistory.residencePriceGrid.idResidencePriceGrid]) {
      residencePriceGridsToNormalize[residencePriceGridHistory.residencePriceGrid.idResidencePriceGrid] = {
        ...residencePriceGridHistory.residencePriceGrid,
        residencePriceGridHistories: []
      };
    }
    (
      residencePriceGridsToNormalize[residencePriceGridHistory.residencePriceGrid.idResidencePriceGrid]
        .residencePriceGridHistories as number[]
    ).push(residencePriceGridHistory.idResidencePriceGridHistory);
    relations['residencePriceGrid'] = residencePriceGridHistory.residencePriceGrid.idResidencePriceGrid;
  }
}

function residencePriceGridHistoryNormalizeUser(
  residencePriceGridHistory: ResidencePriceGridHistoryEntityState,
  usersToNormalize: { [id: number]: UserEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residencePriceGridHistory.user && typeof residencePriceGridHistory.user !== 'number') {
    if (!usersToNormalize[residencePriceGridHistory.user.idUser]) {
      usersToNormalize[residencePriceGridHistory.user.idUser] = {
        ...residencePriceGridHistory.user,
        residencePriceGridHistories: []
      };
    }
    (usersToNormalize[residencePriceGridHistory.user.idUser].residencePriceGridHistories as number[]).push(
      residencePriceGridHistory.idResidencePriceGridHistory
    );
    relations['user'] = residencePriceGridHistory.user.idUser;
  }
}

/**
 *  StratalotPriceValue
 */
export function getActionsToNormalizeStratalotPriceValue(
  stratalotPriceValues: StratalotPriceValueEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companyPriceLabelsToNormalize: { [id: number]: CompanyPriceLabelEntityState } = {};
  const stratalotPricesToNormalize: { [id: number]: StratalotPriceEntityState } = {};

  const normalizedStratalotPriceValues = stratalotPriceValues.map(
    (stratalotPriceValue: StratalotPriceValueEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      stratalotPriceValueNormalizeCompanyPriceLabel(stratalotPriceValue, companyPriceLabelsToNormalize, relations);
      stratalotPriceValueNormalizeStratalotPrice(stratalotPriceValue, stratalotPricesToNormalize, relations);

      return {
        ...stratalotPriceValue,
        ...relations
      };
    }
  );
  const companyPriceLabelsToNormalizeArray = Object.values(companyPriceLabelsToNormalize);
  if (companyPriceLabelsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeCompanyPriceLabel(companyPriceLabelsToNormalizeArray, actionType));
  }
  const stratalotPricesToNormalizeArray = Object.values(stratalotPricesToNormalize);
  if (stratalotPricesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalotPrice(stratalotPricesToNormalizeArray, actionType));
  }

  const stratalotPriceValueActionCreator = stratalotPriceValueActions.upsertManyStratalotPriceValuesSuccess;
  actions.push(stratalotPriceValueActionCreator({ stratalotPriceValues: normalizedStratalotPriceValues }));

  return actions;
}

function stratalotPriceValueNormalizeCompanyPriceLabel(
  stratalotPriceValue: StratalotPriceValueEntityState,
  companyPriceLabelsToNormalize: { [id: number]: CompanyPriceLabelEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotPriceValue.companyPriceLabel && typeof stratalotPriceValue.companyPriceLabel !== 'number') {
    if (!companyPriceLabelsToNormalize[stratalotPriceValue.companyPriceLabel.idCompanyPriceLabel]) {
      companyPriceLabelsToNormalize[stratalotPriceValue.companyPriceLabel.idCompanyPriceLabel] = {
        ...stratalotPriceValue.companyPriceLabel,
        stratalotPriceValues: []
      };
    }
    (
      companyPriceLabelsToNormalize[stratalotPriceValue.companyPriceLabel.idCompanyPriceLabel]
        .stratalotPriceValues as number[]
    ).push(stratalotPriceValue.idStratalotPriceValue);
    relations['companyPriceLabel'] = stratalotPriceValue.companyPriceLabel.idCompanyPriceLabel;
  }
}

function stratalotPriceValueNormalizeStratalotPrice(
  stratalotPriceValue: StratalotPriceValueEntityState,
  stratalotPricesToNormalize: { [id: number]: StratalotPriceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotPriceValue.stratalotPrice && typeof stratalotPriceValue.stratalotPrice !== 'number') {
    if (!stratalotPricesToNormalize[stratalotPriceValue.stratalotPrice.idStratalotPrice]) {
      stratalotPricesToNormalize[stratalotPriceValue.stratalotPrice.idStratalotPrice] = {
        ...stratalotPriceValue.stratalotPrice,
        stratalotPriceValues: []
      };
    }
    (
      stratalotPricesToNormalize[stratalotPriceValue.stratalotPrice.idStratalotPrice].stratalotPriceValues as number[]
    ).push(stratalotPriceValue.idStratalotPriceValue);
    relations['stratalotPrice'] = stratalotPriceValue.stratalotPrice.idStratalotPrice;
  }
}

/**
 *  StratalotRcp
 */
export function getActionsToNormalizeStratalotRcp(
  stratalotRcps: StratalotRcpEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residencesToNormalize: { [id: number]: ResidenceEntityState } = {};
  const stratalotsToNormalize: { [id: number]: StratalotEntityState } = {};
  const residenceSharesToNormalize: { [id: number]: ResidenceShareEntityState } = {};
  const residenceShareRcpsToNormalize: ResidenceShareRcpEntityState[] = [];

  const normalizedStratalotRcps = stratalotRcps.map((stratalotRcp: StratalotRcpEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    stratalotRcpNormalizeResidenceShare(stratalotRcp, residenceSharesToNormalize, relations);
    stratalotRcpNormalizeResidenceShareRcp(stratalotRcp, residenceShareRcpsToNormalize, relations);
    stratalotRcpNormalizeResidence(stratalotRcp, residencesToNormalize, relations);
    stratalotRcpNormalizeStratalot(stratalotRcp, stratalotsToNormalize, relations);

    return {
      ...stratalotRcp,
      ...relations
    };
  });
  const residencesToNormalizeArray = Object.values(residencesToNormalize);
  if (residencesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalizeArray, actionType));
  }
  const stratalotsToNormalizeArray = Object.values(stratalotsToNormalize);
  if (stratalotsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalizeArray, actionType));
  }
  const residenceSharesToNormalizeArray = Object.values(residenceSharesToNormalize);
  if (residenceSharesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidenceShare(residenceSharesToNormalizeArray, actionType));
  }
  if (residenceShareRcpsToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceShareRcp(residenceShareRcpsToNormalize, actionType));
  }

  const stratalotRcpActionCreator = stratalotRcpActions.upsertManyStratalotRcpsSuccess;
  actions.push(stratalotRcpActionCreator({ stratalotRcps: normalizedStratalotRcps }));

  return actions;
}

function stratalotRcpNormalizeResidenceShare(
  stratalotRcp: StratalotRcpEntityState,
  residenceSharesToNormalize: { [id: number]: ResidenceShareEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotRcp.residenceShares?.length && typeof stratalotRcp.residenceShares[0] !== 'number') {
    const idsResidenceShares: number[] = (stratalotRcp.residenceShares as ResidenceShare[]).map(
      (residenceShare: ResidenceShare) => {
        if (!residenceSharesToNormalize[residenceShare.idResidenceShare]) {
          residenceSharesToNormalize[residenceShare.idResidenceShare] = { ...residenceShare, stratalotRcps: [] };
        }
        (residenceSharesToNormalize[residenceShare.idResidenceShare].stratalotRcps as number[]).push(
          stratalotRcp.idStratalotRcp
        );
        return residenceShare.idResidenceShare;
      }
    );
    relations['residenceShares'] = idsResidenceShares;
  }
}

function stratalotRcpNormalizeResidenceShareRcp(
  stratalotRcp: StratalotRcpEntityState,
  residenceShareRcpsToNormalize: ResidenceShareRcpEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotRcp.residenceShareRcps?.length && typeof stratalotRcp.residenceShareRcps[0] !== 'number') {
    const idsResidenceShareRcps: number[] = [];
    residenceShareRcpsToNormalize.push(
      ...(stratalotRcp.residenceShareRcps as ResidenceShareRcp[]).map((residenceShareRcp: ResidenceShareRcp) => {
        idsResidenceShareRcps.push(residenceShareRcp.idResidenceShareRcp);
        return { ...residenceShareRcp, stratalotRcp: stratalotRcp.idStratalotRcp };
      })
    );
    relations['residenceShareRcps'] = idsResidenceShareRcps;
  }
}

function stratalotRcpNormalizeResidence(
  stratalotRcp: StratalotRcpEntityState,
  residencesToNormalize: { [id: number]: ResidenceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotRcp.residence && typeof stratalotRcp.residence !== 'number') {
    if (!residencesToNormalize[stratalotRcp.residence.idResidence]) {
      residencesToNormalize[stratalotRcp.residence.idResidence] = { ...stratalotRcp.residence, stratalotRcps: [] };
    }
    (residencesToNormalize[stratalotRcp.residence.idResidence].stratalotRcps as number[]).push(
      stratalotRcp.idStratalotRcp
    );
    relations['residence'] = stratalotRcp.residence.idResidence;
  }
}

function stratalotRcpNormalizeStratalot(
  stratalotRcp: StratalotRcpEntityState,
  stratalotsToNormalize: { [id: number]: StratalotEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotRcp.stratalot && typeof stratalotRcp.stratalot !== 'number') {
    if (!stratalotsToNormalize[stratalotRcp.stratalot.idStratalot]) {
      stratalotsToNormalize[stratalotRcp.stratalot.idStratalot] = {
        ...stratalotRcp.stratalot,
        stratalotRcp: stratalotRcp.idStratalotRcp
      };
    }
    stratalotsToNormalize[stratalotRcp.stratalot.idStratalot].stratalotRcp = stratalotRcp.idStratalotRcp;
    relations['stratalot'] = stratalotRcp.idStratalot;
  }
}

/**
 *  StratalotTodo
 */
export function getActionsToNormalizeStratalotTodo(
  stratalotTodos: StratalotTodoEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationStratalotTodosToNormalize: { [id: number]: OrganizationStratalotTodoEntityState } = {};
  const stratalotsToNormalize: { [id: number]: StratalotEntityState } = {};

  const normalizedStratalotTodos = stratalotTodos.map((stratalotTodo: StratalotTodoEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    stratalotTodoNormalizeOrganizationStratalotTodo(stratalotTodo, organizationStratalotTodosToNormalize, relations);
    stratalotTodoNormalizeStratalot(stratalotTodo, stratalotsToNormalize, relations);

    return {
      ...stratalotTodo,
      ...relations
    };
  });
  const organizationStratalotTodosToNormalizeArray = Object.values(organizationStratalotTodosToNormalize);
  if (organizationStratalotTodosToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodo(organizationStratalotTodosToNormalizeArray, actionType)
    );
  }
  const stratalotsToNormalizeArray = Object.values(stratalotsToNormalize);
  if (stratalotsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotsToNormalizeArray, actionType));
  }

  const stratalotTodoActionCreator = stratalotTodoActions.upsertManyStratalotTodosSuccess;
  actions.push(stratalotTodoActionCreator({ stratalotTodos: normalizedStratalotTodos }));

  return actions;
}

function stratalotTodoNormalizeOrganizationStratalotTodo(
  stratalotTodo: StratalotTodoEntityState,
  organizationStratalotTodosToNormalize: { [id: number]: OrganizationStratalotTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotTodo.organizationStratalotTodo && typeof stratalotTodo.organizationStratalotTodo !== 'number') {
    if (!organizationStratalotTodosToNormalize[stratalotTodo.organizationStratalotTodo.idOrganizationStratalotTodo]) {
      organizationStratalotTodosToNormalize[stratalotTodo.organizationStratalotTodo.idOrganizationStratalotTodo] = {
        ...stratalotTodo.organizationStratalotTodo,
        stratalotTodos: []
      };
    }
    (
      organizationStratalotTodosToNormalize[stratalotTodo.organizationStratalotTodo.idOrganizationStratalotTodo]
        .stratalotTodos as number[]
    ).push(stratalotTodo.idStratalotTodo);
    relations['organizationStratalotTodo'] = stratalotTodo.organizationStratalotTodo.idOrganizationStratalotTodo;
  }
}

function stratalotTodoNormalizeStratalot(
  stratalotTodo: StratalotTodoEntityState,
  stratalotsToNormalize: { [id: number]: StratalotEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotTodo.stratalot && typeof stratalotTodo.stratalot !== 'number') {
    if (!stratalotsToNormalize[stratalotTodo.stratalot.idStratalot]) {
      stratalotsToNormalize[stratalotTodo.stratalot.idStratalot] = { ...stratalotTodo.stratalot, stratalotTodos: [] };
    }
    (stratalotsToNormalize[stratalotTodo.stratalot.idStratalot].stratalotTodos as number[]).push(
      stratalotTodo.idStratalotTodo
    );
    relations['stratalot'] = stratalotTodo.stratalot.idStratalot;
  }
}

/**
 *  ResidenceStudyTodo
 */
export function getActionsToNormalizeResidenceStudyTodo(
  residenceStudyTodos: ResidenceStudyTodoEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationResidenceStudyTodosToNormalize: { [id: number]: OrganizationResidenceStudyTodoEntityState } = {};
  const residenceStudiesToNormalize: { [id: number]: ResidenceStudyEntityState } = {};

  const normalizedResidenceStudyTodos = residenceStudyTodos.map((residenceStudyTodo: ResidenceStudyTodoEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    residenceStudyTodoNormalizeOrganizationResidenceStudyTodo(
      residenceStudyTodo,
      organizationResidenceStudyTodosToNormalize,
      relations
    );
    residenceStudyTodoNormalizeResidenceStudy(residenceStudyTodo, residenceStudiesToNormalize, relations);

    return {
      ...residenceStudyTodo,
      ...relations
    };
  });
  const organizationResidenceStudyTodosToNormalizeArray = Object.values(organizationResidenceStudyTodosToNormalize);
  if (organizationResidenceStudyTodosToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationResidenceStudyTodo(
        organizationResidenceStudyTodosToNormalizeArray,
        actionType
      )
    );
  }
  const residenceStudiesToNormalizeArray = Object.values(residenceStudiesToNormalize);
  if (residenceStudiesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidenceStudy(residenceStudiesToNormalizeArray, actionType));
  }

  const residenceStudyTodoActionCreator = residenceStudyTodoActions.upsertManyResidenceStudyTodosSuccess;
  actions.push(residenceStudyTodoActionCreator({ residenceStudyTodos: normalizedResidenceStudyTodos }));

  return actions;
}

function residenceStudyTodoNormalizeOrganizationResidenceStudyTodo(
  residenceStudyTodo: ResidenceStudyTodoEntityState,
  organizationResidenceStudyTodosToNormalize: { [id: number]: OrganizationResidenceStudyTodoEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (
    residenceStudyTodo.organizationResidenceStudyTodo &&
    typeof residenceStudyTodo.organizationResidenceStudyTodo !== 'number'
  ) {
    if (
      !organizationResidenceStudyTodosToNormalize[
        residenceStudyTodo.organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo
      ]
    ) {
      organizationResidenceStudyTodosToNormalize[
        residenceStudyTodo.organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo
      ] = { ...residenceStudyTodo.organizationResidenceStudyTodo, residenceStudyTodos: [] };
    }
    (
      organizationResidenceStudyTodosToNormalize[
        residenceStudyTodo.organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo
      ].residenceStudyTodos as number[]
    ).push(residenceStudyTodo.idResidenceStudyTodo);
    relations['organizationResidenceStudyTodo'] =
      residenceStudyTodo.organizationResidenceStudyTodo.idOrganizationResidenceStudyTodo;
  }
}

function residenceStudyTodoNormalizeResidenceStudy(
  residenceStudyTodo: ResidenceStudyTodoEntityState,
  residenceStudiesToNormalize: { [id: number]: ResidenceStudyEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceStudyTodo.residenceStudy && typeof residenceStudyTodo.residenceStudy !== 'number') {
    if (!residenceStudiesToNormalize[residenceStudyTodo.residenceStudy.idResidenceStudy]) {
      residenceStudiesToNormalize[residenceStudyTodo.residenceStudy.idResidenceStudy] = {
        ...residenceStudyTodo.residenceStudy,
        residenceStudyTodos: []
      };
    }
    (
      residenceStudiesToNormalize[residenceStudyTodo.residenceStudy.idResidenceStudy].residenceStudyTodos as number[]
    ).push(residenceStudyTodo.idResidenceStudyTodo);
    relations['residenceStudy'] = residenceStudyTodo.residenceStudy.idResidenceStudy;
  }
}

/**
 *  StratalotType
 */
export function getActionsToNormalizeStratalotType(
  stratalotTypes: StratalotTypeEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const companyStratalotTypesToNormalize: CompanyStratalotTypeEntityState[] = [];
  const prospectBuyingWishsToNormalize: { [id: number]: ProspectBuyingWishEntityState } = {};
  const organizationLeadTodoRulesToNormalize: OrganizationLeadTodoRuleEntityState[] = [];
  const organizationStratalotTodoRulesToNormalize: OrganizationStratalotTodoRuleEntityState[] = [];

  const normalizedStratalotTypes = stratalotTypes.map((stratalotType: StratalotTypeEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    stratalotTypeNormalizeCompanyStratalotType(stratalotType, companyStratalotTypesToNormalize, relations);
    stratalotTypeNormalizeProspectBuyingWish(stratalotType, prospectBuyingWishsToNormalize, relations);
    stratalotTypeNormalizeOrganizationLeadTodoRule(stratalotType, organizationLeadTodoRulesToNormalize, relations);
    stratalotTypeNormalizeOrganizationStratalotTodoRule(
      stratalotType,
      organizationStratalotTodoRulesToNormalize,
      relations
    );

    return {
      ...stratalotType,
      ...relations
    };
  });
  if (companyStratalotTypesToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyStratalotType(companyStratalotTypesToNormalize, actionType));
  }
  const prospectBuyingWishsToNormalizeArray = Object.values(prospectBuyingWishsToNormalize);
  if (prospectBuyingWishsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProspectBuyingWish(prospectBuyingWishsToNormalizeArray, actionType));
  }
  if (organizationLeadTodoRulesToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationLeadTodoRule(organizationLeadTodoRulesToNormalize, actionType));
  }
  if (organizationStratalotTodoRulesToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeOrganizationStratalotTodoRule(organizationStratalotTodoRulesToNormalize, actionType)
    );
  }

  const stratalotTypeActionCreator = stratalotTypeActions.upsertManyStratalotTypesSuccess;
  actions.push(stratalotTypeActionCreator({ stratalotTypes: normalizedStratalotTypes }));

  return actions;
}

function stratalotTypeNormalizeCompanyStratalotType(
  stratalotType: StratalotTypeEntityState,
  companyStratalotTypesToNormalize: CompanyStratalotTypeEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotType.companyStratalotTypes?.length && typeof stratalotType.companyStratalotTypes[0] !== 'number') {
    const idsCompanyStratalotTypes: number[] = [];
    companyStratalotTypesToNormalize.push(
      ...(stratalotType.companyStratalotTypes as CompanyStratalotType[]).map(
        (companyStratalotType: CompanyStratalotType) => {
          idsCompanyStratalotTypes.push(companyStratalotType.idCompanyStratalotType);
          return { ...companyStratalotType, stratalotType: stratalotType.idStratalotType };
        }
      )
    );
    relations['companyStratalotTypes'] = idsCompanyStratalotTypes;
  }
}

function stratalotTypeNormalizeProspectBuyingWish(
  stratalotType: StratalotTypeEntityState,
  prospectBuyingWishsToNormalize: { [id: number]: ProspectBuyingWishEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (stratalotType.prospectBuyingWishs?.length && typeof stratalotType.prospectBuyingWishs[0] !== 'number') {
    const idsProspectBuyingWishs: number[] = (stratalotType.prospectBuyingWishs as ProspectBuyingWish[]).map(
      (prospectBuyingWish: ProspectBuyingWish) => {
        if (!prospectBuyingWishsToNormalize[prospectBuyingWish.idProspectBuyingWish]) {
          prospectBuyingWishsToNormalize[prospectBuyingWish.idProspectBuyingWish] = {
            ...prospectBuyingWish,
            stratalotTypes: []
          };
        }
        (prospectBuyingWishsToNormalize[prospectBuyingWish.idProspectBuyingWish].stratalotTypes as number[]).push(
          stratalotType.idStratalotType
        );
        return prospectBuyingWish.idProspectBuyingWish;
      }
    );
    relations['prospectBuyingWishs'] = idsProspectBuyingWishs;
  }
}

function stratalotTypeNormalizeOrganizationLeadTodoRule(
  stratalotType: StratalotTypeEntityState,
  organizationLeadTodoRulesToNormalize: OrganizationLeadTodoRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    stratalotType.organizationLeadTodoRules?.length &&
    typeof stratalotType.organizationLeadTodoRules[0] !== 'number'
  ) {
    const idsOrganizationLeadTodoRules: number[] = [];
    organizationLeadTodoRulesToNormalize.push(
      ...(stratalotType.organizationLeadTodoRules as OrganizationLeadTodoRule[]).map(
        (organizationLeadTodoRule: OrganizationLeadTodoRule) => {
          idsOrganizationLeadTodoRules.push(organizationLeadTodoRule.idOrganizationLeadTodoRule);
          return { ...organizationLeadTodoRule, stratalotType: stratalotType.idStratalotType };
        }
      )
    );
    relations['organizationLeadTodoRules'] = idsOrganizationLeadTodoRules;
  }
}

function stratalotTypeNormalizeOrganizationStratalotTodoRule(
  stratalotType: StratalotTypeEntityState,
  organizationStratalotTodoRulesToNormalize: OrganizationStratalotTodoRuleEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    stratalotType.organizationStratalotTodoRules?.length &&
    typeof stratalotType.organizationStratalotTodoRules[0] !== 'number'
  ) {
    const idsOrganizationStratalotTodoRules: number[] = [];
    organizationStratalotTodoRulesToNormalize.push(
      ...(stratalotType.organizationStratalotTodoRules as OrganizationStratalotTodoRule[]).map(
        (organizationStratalotTodoRule: OrganizationStratalotTodoRule) => {
          idsOrganizationStratalotTodoRules.push(organizationStratalotTodoRule.idOrganizationStratalotTodoRule);
          return { ...organizationStratalotTodoRule, stratalotType: stratalotType.idStratalotType };
        }
      )
    );
    relations['organizationStratalotTodoRules'] = idsOrganizationStratalotTodoRules;
  }
}

/**
 *  User
 */
export function getActionsToNormalizeUser(users: UserEntityState[], actionType: StoreActionType): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const boardStatesToNormalize: BoardStateEntityState[] = [];
  const companyTerritoireUsersToNormalize: CompanyTerritoireUserEntityState[] = [];
  const residencePriceGridHistoriesToNormalize: ResidencePriceGridHistoryEntityState[] = [];
  const companyTerritoireToNormalize: CompanyTerritoireEntityState[] = [];
  const prospectResponsibleOfToNormalize: ProspectEntityState[] = [];
  const prospectUpdaterToNormalize: ProspectEntityState[] = [];
  const prospectCreatorToNormalize: ProspectEntityState[] = [];
  const residenceToNormalize: ResidenceEntityState[] = [];
  const stratalotToNormalize: StratalotEntityState[] = [];
  const prospectEventsToNormalize: ProspectEventEntityState[] = [];
  const profilsToNormalize: { [id: number]: ProfilEntityState } = {};
  const companyCommunicationRecipientsToNormalize: { [id: number]: CompanyCommunicationRecipientEntityState } = {};
  const companyCommunicationRecipientUsersToNormalize: CompanyCommunicationRecipientUserEntityState[] = [];

  const normalizedUsers = users.map((user: UserEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    userNormalizeBoardState(user, boardStatesToNormalize, relations);
    userNormalizeCompanyTerritoireUser(user, companyTerritoireUsersToNormalize, relations);
    userNormalizeResidencePriceGridHistory(user, residencePriceGridHistoriesToNormalize, relations);
    userNormalizeCompanyTerritoire(user, companyTerritoireToNormalize, relations);
    userNormalizeProspectResponsibleOf(user, prospectResponsibleOfToNormalize, relations);
    userNormalizeProspectUpdater(user, prospectUpdaterToNormalize, relations);
    userNormalizeProspectCreator(user, prospectCreatorToNormalize, relations);
    userNormalizeResidence(user, residenceToNormalize, relations);
    userNormalizeStratalot(user, stratalotToNormalize, relations);
    userNormalizeProspectEvent(user, prospectEventsToNormalize, relations);
    userNormalizeProfil(user, profilsToNormalize, relations);
    userNormalizeCompanyCommunicationRecipient(user, companyCommunicationRecipientsToNormalize, relations);
    userNormalizeCompanyCommunicationRecipientUser(user, companyCommunicationRecipientUsersToNormalize, relations);
    userNormalizeOrganization(user, organizationsToNormalize, relations);

    return {
      ...user,
      ...relations
    };
  });
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  if (boardStatesToNormalize.length) {
    actions.push(...getActionsToNormalizeBoardState(boardStatesToNormalize, actionType));
  }
  if (companyTerritoireUsersToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyTerritoireUser(companyTerritoireUsersToNormalize, actionType));
  }
  if (residencePriceGridHistoriesToNormalize.length) {
    actions.push(...getActionsToNormalizeResidencePriceGridHistory(residencePriceGridHistoriesToNormalize, actionType));
  }
  if (companyTerritoireToNormalize.length) {
    actions.push(...getActionsToNormalizeCompanyTerritoire(companyTerritoireToNormalize, actionType));
  }
  if (prospectResponsibleOfToNormalize.length) {
    actions.push(...getActionsToNormalizeProspect(prospectResponsibleOfToNormalize, actionType));
  }
  if (prospectUpdaterToNormalize.length) {
    actions.push(...getActionsToNormalizeProspect(prospectUpdaterToNormalize, actionType));
  }
  if (prospectCreatorToNormalize.length) {
    actions.push(...getActionsToNormalizeProspect(prospectCreatorToNormalize, actionType));
  }
  if (residenceToNormalize.length) {
    actions.push(...getActionsToNormalizeResidence(residenceToNormalize, actionType));
  }
  if (stratalotToNormalize.length) {
    actions.push(...getActionsToNormalizeStratalot(stratalotToNormalize, actionType));
  }
  if (prospectEventsToNormalize.length) {
    actions.push(...getActionsToNormalizeProspectEvent(prospectEventsToNormalize, actionType));
  }
  const profilsToNormalizeArray = Object.values(profilsToNormalize);
  if (profilsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeProfil(profilsToNormalizeArray, actionType));
  }
  const companyCommunicationRecipientsToNormalizeArray = Object.values(companyCommunicationRecipientsToNormalize);
  if (companyCommunicationRecipientsToNormalizeArray.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationRecipient(companyCommunicationRecipientsToNormalizeArray, actionType)
    );
  }
  if (companyCommunicationRecipientUsersToNormalize.length) {
    actions.push(
      ...getActionsToNormalizeCompanyCommunicationRecipientUser(
        companyCommunicationRecipientUsersToNormalize,
        actionType
      )
    );
  }

  const userActionCreator = userActions.upsertManyUsersSuccess;
  actions.push(userActionCreator({ users: normalizedUsers }));

  return actions;
}

function userNormalizeBoardState(
  user: UserEntityState,
  boardStatesToNormalize: BoardStateEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.boardStates?.length && typeof user.boardStates[0] !== 'number') {
    const idsBoardStates: number[] = [];
    boardStatesToNormalize.push(
      ...(user.boardStates as BoardState[]).map((boardState: BoardState) => {
        idsBoardStates.push(boardState.idBoardState);
        return { ...boardState, user: user.idUser };
      })
    );
    relations['boardStates'] = idsBoardStates;
  }
}

function userNormalizeCompanyTerritoireUser(
  user: UserEntityState,
  companyTerritoireUsersToNormalize: CompanyTerritoireUserEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.companyTerritoireUsers?.length && typeof user.companyTerritoireUsers[0] !== 'number') {
    const idsCompanyTerritoireUsers: number[] = [];
    companyTerritoireUsersToNormalize.push(
      ...(user.companyTerritoireUsers as CompanyTerritoireUser[]).map(
        (companyTerritoireUser: CompanyTerritoireUser) => {
          idsCompanyTerritoireUsers.push(companyTerritoireUser.idCompanyTerritoireUser);
          return { ...companyTerritoireUser, user: user.idUser };
        }
      )
    );
    relations['companyTerritoireUsers'] = idsCompanyTerritoireUsers;
  }
}

function userNormalizeResidencePriceGridHistory(
  user: UserEntityState,
  residencePriceGridHistoriesToNormalize: ResidencePriceGridHistoryEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.residencePriceGridHistories?.length && typeof user.residencePriceGridHistories[0] !== 'number') {
    const idsResidencePriceGridHistories: number[] = [];
    residencePriceGridHistoriesToNormalize.push(
      ...(user.residencePriceGridHistories as ResidencePriceGridHistory[]).map(
        (residencePriceGridHistory: ResidencePriceGridHistory) => {
          idsResidencePriceGridHistories.push(residencePriceGridHistory.idResidencePriceGridHistory);
          return { ...residencePriceGridHistory, user: user.idUser };
        }
      )
    );
    relations['residencePriceGridHistories'] = idsResidencePriceGridHistories;
  }
}

function userNormalizeCompanyTerritoire(
  user: UserEntityState,
  companyTerritoiresToNormalize: CompanyTerritoireEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.companyTerritoires?.length && typeof user.companyTerritoires[0] !== 'number') {
    const idsCompanyTerritoires: number[] = [];
    companyTerritoiresToNormalize.push(
      ...(user.companyTerritoires as CompanyTerritoire[]).map((companyTerritoire: CompanyTerritoire) => {
        idsCompanyTerritoires.push(companyTerritoire.idCompanyTerritoire);
        return { ...companyTerritoire, user: user.idUser };
      })
    );
    relations['companyTerritoires'] = idsCompanyTerritoires;
  }
}

function userNormalizeProspectResponsibleOf(
  user: UserEntityState,
  prospectsToNormalize: ProspectEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.prospectResponsibleOf?.length && typeof user.prospectResponsibleOf[0] !== 'number') {
    const idsProspects: number[] = [];
    prospectsToNormalize.push(
      ...(user.prospectResponsibleOf as Prospect[]).map((prospect: Prospect) => {
        idsProspects.push(prospect.idProspect);
        return { ...prospect, user: user.idUser };
      })
    );
    relations['prospectResponsibleOf'] = idsProspects;
  }
}

function userNormalizeProspectUpdater(
  user: UserEntityState,
  prospectsToNormalize: ProspectEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.prospectUpdater?.length && typeof user.prospectUpdater[0] !== 'number') {
    const idsProspects: number[] = [];
    prospectsToNormalize.push(
      ...(user.prospectUpdater as Prospect[]).map((prospect: Prospect) => {
        idsProspects.push(prospect.idProspect);
        return { ...prospect, user: user.idUser };
      })
    );
    relations['prospectUpdater'] = idsProspects;
  }
}

function userNormalizeProspectCreator(
  user: UserEntityState,
  prospectsToNormalize: ProspectEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.prospectCreator?.length && typeof user.prospectCreator[0] !== 'number') {
    const idsProspects: number[] = [];
    prospectsToNormalize.push(
      ...(user.prospectCreator as Prospect[]).map((prospect: Prospect) => {
        idsProspects.push(prospect.idProspect);
        return { ...prospect, user: user.idUser };
      })
    );
    relations['prospectCreator'] = idsProspects;
  }
}

function userNormalizeResidence(
  user: UserEntityState,
  residencesToNormalize: ResidenceEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.residences?.length && typeof user.residences[0] !== 'number') {
    const idsResidences: number[] = [];
    residencesToNormalize.push(
      ...(user.residences as Residence[]).map((residence: Residence) => {
        idsResidences.push(residence.idResidence);
        return { ...residence, user: user.idUser };
      })
    );
    relations['residences'] = idsResidences;
  }
}

function userNormalizeStratalot(
  user: UserEntityState,
  stratalotsToNormalize: StratalotEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.stratalots?.length && typeof user.stratalots[0] !== 'number') {
    const idsStratalots: number[] = [];
    stratalotsToNormalize.push(
      ...(user.stratalots as Stratalot[]).map((stratalot: Stratalot) => {
        idsStratalots.push(stratalot.idStratalot);
        return { ...stratalot, user: user.idUser };
      })
    );
    relations['stratalots'] = idsStratalots;
  }
}

function userNormalizeProspectEvent(
  user: UserEntityState,
  prospectEventsToNormalize: ProspectEventEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (user.prospectEvents?.length && typeof user.prospectEvents[0] !== 'number') {
    const idsProspectEvents: number[] = [];
    prospectEventsToNormalize.push(
      ...(user.prospectEvents as ProspectEvent[]).map((prospectEvent: ProspectEvent) => {
        idsProspectEvents.push(prospectEvent.idProspectEvent);
        return { ...prospectEvent, user: user.idUser };
      })
    );
    relations['prospectEvents'] = idsProspectEvents;
  }
}

function userNormalizeProfil(
  user: UserEntityState,
  profilsToNormalize: { [id: number]: ProfilEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (user.profils?.length && typeof user.profils[0] !== 'number') {
    const idsProfils: number[] = (user.profils as Profil[]).map((profil: Profil) => {
      if (!profilsToNormalize[profil.idProfil]) {
        profilsToNormalize[profil.idProfil] = { ...profil, users: [] };
      }
      (profilsToNormalize[profil.idProfil].users as number[]).push(user.idUser);
      return profil.idProfil;
    });
    relations['profils'] = idsProfils;
  }
}

function userNormalizeCompanyCommunicationRecipient(
  user: UserEntityState,
  companyCommunicationRecipientsToNormalize: { [id: number]: CompanyCommunicationRecipientEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (user.companyCommunicationRecipients?.length && typeof user.companyCommunicationRecipients[0] !== 'number') {
    const idsCompanyCommunicationRecipients: number[] = (
      user.companyCommunicationRecipients as CompanyCommunicationRecipient[]
    ).map((companyCommunicationRecipient: CompanyCommunicationRecipient) => {
      if (!companyCommunicationRecipientsToNormalize[companyCommunicationRecipient.idCompanyCommunicationRecipient]) {
        companyCommunicationRecipientsToNormalize[companyCommunicationRecipient.idCompanyCommunicationRecipient] = {
          ...companyCommunicationRecipient,
          users: []
        };
      }
      (
        companyCommunicationRecipientsToNormalize[companyCommunicationRecipient.idCompanyCommunicationRecipient]
          .users as number[]
      ).push(user.idUser);
      return companyCommunicationRecipient.idCompanyCommunicationRecipient;
    });
    relations['companyCommunicationRecipients'] = idsCompanyCommunicationRecipients;
  }
}

function userNormalizeCompanyCommunicationRecipientUser(
  user: UserEntityState,
  companyCommunicationRecipientUsersToNormalize: CompanyCommunicationRecipientUserEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    user.companyCommunicationRecipientUsers?.length &&
    typeof user.companyCommunicationRecipientUsers[0] !== 'number'
  ) {
    const idsCompanyCommunicationRecipientUsers: number[] = [];
    companyCommunicationRecipientUsersToNormalize.push(
      ...(user.companyCommunicationRecipientUsers as CompanyCommunicationRecipientUser[]).map(
        (companyCommunicationRecipientUser: CompanyCommunicationRecipientUser) => {
          idsCompanyCommunicationRecipientUsers.push(
            companyCommunicationRecipientUser.idCompanyCommunicationRecipientUser
          );
          return { ...companyCommunicationRecipientUser, user: user.idUser };
        }
      )
    );
    relations['companyCommunicationRecipientUsers'] = idsCompanyCommunicationRecipientUsers;
  }
}

function userNormalizeOrganization(
  user: UserEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (user.organization && typeof user.organization !== 'number') {
    if (!organizationsToNormalize[user.organization.idOrganization]) {
      organizationsToNormalize[user.organization.idOrganization] = { ...user.organization, users: [] };
    }
    (organizationsToNormalize[user.organization.idOrganization].users as number[]).push(user.idUser);
    relations['organization'] = user.organization.idOrganization;
  }
}

/**
 *  ForecastPeriod
 */
export function getActionsToNormalizeForecastPeriod(
  forecastPeriods: ForecastPeriodEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residenceForecastsToNormalize: ResidenceForecastEntityState[] = [];

  const normalizedForecastPeriods = forecastPeriods.map((forecastPeriod: ForecastPeriodEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    forecastPeriodNormalizeResidenceForecast(forecastPeriod, residenceForecastsToNormalize, relations);

    return {
      ...forecastPeriod,
      ...relations
    };
  });
  if (residenceForecastsToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceForecast(residenceForecastsToNormalize, actionType));
  }

  const forecastPeriodActionCreator = forecastPeriodActions.upsertManyForecastPeriodsSuccess;
  actions.push(forecastPeriodActionCreator({ forecastPeriods: normalizedForecastPeriods }));

  return actions;
}

function forecastPeriodNormalizeResidenceForecast(
  forecastPeriod: ForecastPeriodEntityState,
  residenceForecastsToNormalize: ResidenceForecastEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (forecastPeriod.residenceForecasts?.length && typeof forecastPeriod.residenceForecasts[0] !== 'number') {
    const idsResidenceForecasts: number[] = [];
    residenceForecastsToNormalize.push(
      ...(forecastPeriod.residenceForecasts as ResidenceForecast[]).map((residenceForecast: ResidenceForecast) => {
        idsResidenceForecasts.push(residenceForecast.idResidenceForecast);
        return { ...residenceForecast, forecastPeriod: forecastPeriod.idForecastPeriod };
      })
    );
    relations['residenceForecasts'] = idsResidenceForecasts;
  }
}

/**
 *  OrganizationForecastRate
 */
export function getActionsToNormalizeOrganizationForecastRate(
  organizationForecastRates: OrganizationForecastRateEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationsToNormalize: { [id: number]: OrganizationEntityState } = {};
  const residencesToNormalize: ResidenceEntityState[] = [];

  const normalizedOrganizationForecastRates = organizationForecastRates.map(
    (organizationForecastRate: OrganizationForecastRateEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      organizationForecastRateNormalizeResidence(organizationForecastRate, residencesToNormalize, relations);
      organizationForecastRateNormalizeOrganization(organizationForecastRate, organizationsToNormalize, relations);

      return {
        ...organizationForecastRate,
        ...relations
      };
    }
  );
  const organizationsToNormalizeArray = Object.values(organizationsToNormalize);
  if (organizationsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeOrganization(organizationsToNormalizeArray, actionType));
  }
  if (residencesToNormalize.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalize, actionType));
  }

  const organizationForecastRateActionCreator =
    organizationForecastRateActions.upsertManyOrganizationForecastRatesSuccess;
  actions.push(
    organizationForecastRateActionCreator({ organizationForecastRates: normalizedOrganizationForecastRates })
  );

  return actions;
}

function organizationForecastRateNormalizeResidence(
  organizationForecastRate: OrganizationForecastRateEntityState,
  residencesToNormalize: ResidenceEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (organizationForecastRate.residences?.length && typeof organizationForecastRate.residences[0] !== 'number') {
    const idsResidences: number[] = [];
    residencesToNormalize.push(
      ...(organizationForecastRate.residences as Residence[]).map((residence: Residence) => {
        idsResidences.push(residence.idResidence);
        return { ...residence, organizationForecastRate: organizationForecastRate.idOrganizationForecastRate };
      })
    );
    relations['residences'] = idsResidences;
  }
}

function organizationForecastRateNormalizeOrganization(
  organizationForecastRate: OrganizationForecastRateEntityState,
  organizationsToNormalize: { [id: number]: OrganizationEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (organizationForecastRate.organization && typeof organizationForecastRate.organization !== 'number') {
    if (!organizationsToNormalize[organizationForecastRate.organization.idOrganization]) {
      organizationsToNormalize[organizationForecastRate.organization.idOrganization] = {
        ...organizationForecastRate.organization,
        organizationForecastRates: []
      };
    }
    (
      organizationsToNormalize[organizationForecastRate.organization.idOrganization]
        .organizationForecastRates as number[]
    ).push(organizationForecastRate.idOrganizationForecastRate);
    relations['organization'] = organizationForecastRate.organization.idOrganization;
  }
}

/**
 *  ResidenceForecast
 */
export function getActionsToNormalizeResidenceForecast(
  residenceForecasts: ResidenceForecastEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residencesToNormalize: { [id: number]: ResidenceEntityState } = {};
  const forecastPeriodsToNormalize: { [id: number]: ForecastPeriodEntityState } = {};
  const residenceForecastValuesToNormalize: ResidenceForecastValueEntityState[] = [];

  const normalizedResidenceForecasts = residenceForecasts.map((residenceForecast: ResidenceForecastEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    residenceForecastNormalizeResidenceForecastValue(residenceForecast, residenceForecastValuesToNormalize, relations);
    residenceForecastNormalizeResidence(residenceForecast, residencesToNormalize, relations);
    residenceForecastNormalizeForecastPeriod(residenceForecast, forecastPeriodsToNormalize, relations);

    return {
      ...residenceForecast,
      ...relations
    };
  });
  const residencesToNormalizeArray = Object.values(residencesToNormalize);
  if (residencesToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidence(residencesToNormalizeArray, actionType));
  }
  const forecastPeriodsToNormalizeArray = Object.values(forecastPeriodsToNormalize);
  if (forecastPeriodsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeForecastPeriod(forecastPeriodsToNormalizeArray, actionType));
  }
  if (residenceForecastValuesToNormalize.length) {
    actions.push(...getActionsToNormalizeResidenceForecastValue(residenceForecastValuesToNormalize, actionType));
  }

  const residenceForecastActionCreator = residenceForecastActions.upsertManyResidenceForecastsSuccess;
  actions.push(residenceForecastActionCreator({ residenceForecasts: normalizedResidenceForecasts }));

  return actions;
}

function residenceForecastNormalizeResidenceForecastValue(
  residenceForecast: ResidenceForecastEntityState,
  residenceForecastValuesToNormalize: ResidenceForecastValueEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (
    residenceForecast.residenceForecastValues?.length &&
    typeof residenceForecast.residenceForecastValues[0] !== 'number'
  ) {
    const idsResidenceForecastValues: number[] = [];
    residenceForecastValuesToNormalize.push(
      ...(residenceForecast.residenceForecastValues as ResidenceForecastValue[]).map(
        (residenceForecastValue: ResidenceForecastValue) => {
          idsResidenceForecastValues.push(residenceForecastValue.idResidenceForecastValue);
          return { ...residenceForecastValue, residenceForecast: residenceForecast.idResidenceForecast };
        }
      )
    );
    relations['residenceForecastValues'] = idsResidenceForecastValues;
  }
}

function residenceForecastNormalizeResidence(
  residenceForecast: ResidenceForecastEntityState,
  residencesToNormalize: { [id: number]: ResidenceEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceForecast.residence && typeof residenceForecast.residence !== 'number') {
    if (!residencesToNormalize[residenceForecast.residence.idResidence]) {
      residencesToNormalize[residenceForecast.residence.idResidence] = {
        ...residenceForecast.residence,
        residenceForecasts: []
      };
    }
    (residencesToNormalize[residenceForecast.residence.idResidence].residenceForecasts as number[]).push(
      residenceForecast.idResidenceForecast
    );
    relations['residence'] = residenceForecast.residence.idResidence;
  }
}

function residenceForecastNormalizeForecastPeriod(
  residenceForecast: ResidenceForecastEntityState,
  forecastPeriodsToNormalize: { [id: number]: ForecastPeriodEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceForecast.forecastPeriod && typeof residenceForecast.forecastPeriod !== 'number') {
    if (!forecastPeriodsToNormalize[residenceForecast.forecastPeriod.idForecastPeriod]) {
      forecastPeriodsToNormalize[residenceForecast.forecastPeriod.idForecastPeriod] = {
        ...residenceForecast.forecastPeriod,
        residenceForecasts: []
      };
    }
    (forecastPeriodsToNormalize[residenceForecast.forecastPeriod.idForecastPeriod].residenceForecasts as number[]).push(
      residenceForecast.idResidenceForecast
    );
    relations['forecastPeriod'] = residenceForecast.forecastPeriod.idForecastPeriod;
  }
}

/**
 *  ResidenceForecastValue
 */
export function getActionsToNormalizeResidenceForecastValue(
  residenceForecastValues: ResidenceForecastValueEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const residenceForecastsToNormalize: { [id: number]: ResidenceForecastEntityState } = {};

  const normalizedResidenceForecastValues = residenceForecastValues.map(
    (residenceForecastValue: ResidenceForecastValueEntityState) => {
      const relations: { [key: string]: number | number[] } = {};
      residenceForecastValueNormalizeResidenceForecast(
        residenceForecastValue,
        residenceForecastsToNormalize,
        relations
      );

      return {
        ...residenceForecastValue,
        ...relations
      };
    }
  );
  const residenceForecastsToNormalizeArray = Object.values(residenceForecastsToNormalize);
  if (residenceForecastsToNormalizeArray.length) {
    actions.push(...getActionsToNormalizeResidenceForecast(residenceForecastsToNormalizeArray, actionType));
  }

  const residenceForecastValueActionCreator = residenceForecastValueActions.upsertManyResidenceForecastValuesSuccess;
  actions.push(residenceForecastValueActionCreator({ residenceForecastValues: normalizedResidenceForecastValues }));

  return actions;
}

function residenceForecastValueNormalizeResidenceForecast(
  residenceForecastValue: ResidenceForecastValueEntityState,
  residenceForecastsToNormalize: { [id: number]: ResidenceForecastEntityState },
  relations: { [key: string]: number | number[] }
): void {
  if (residenceForecastValue.residenceForecast && typeof residenceForecastValue.residenceForecast !== 'number') {
    if (!residenceForecastsToNormalize[residenceForecastValue.residenceForecast.idResidenceForecast]) {
      residenceForecastsToNormalize[residenceForecastValue.residenceForecast.idResidenceForecast] = {
        ...residenceForecastValue.residenceForecast,
        residenceForecastValues: []
      };
    }
    (
      residenceForecastsToNormalize[residenceForecastValue.residenceForecast.idResidenceForecast]
        .residenceForecastValues as number[]
    ).push(residenceForecastValue.idResidenceForecastValue);
    relations['residenceForecast'] = residenceForecastValue.residenceForecast.idResidenceForecast;
  }
}

/**
 *  MetierFamilie
 */
export function getActionsToNormalizeMetierFamilie(
  metierFamilies: MetierFamilieEntityState[],
  actionType: StoreActionType
): Action[] {
  const actions: Action[] = [];

  const organizationMetiersToNormalize: OrganizationMetierEntityState[] = [];

  const normalizedMetierFamilies = metierFamilies.map((metierFamilie: MetierFamilieEntityState) => {
    const relations: { [key: string]: number | number[] } = {};
    metierFamilieNormalizeOrganizationMetier(metierFamilie, organizationMetiersToNormalize, relations);

    return {
      ...metierFamilie,
      ...relations
    };
  });
  if (organizationMetiersToNormalize.length) {
    actions.push(...getActionsToNormalizeOrganizationMetier(organizationMetiersToNormalize, actionType));
  }

  const metierFamilieActionCreator = metierFamilieActions.upsertManyMetierFamiliesSuccess;
  actions.push(metierFamilieActionCreator({ metierFamilies: normalizedMetierFamilies }));

  return actions;
}

function metierFamilieNormalizeOrganizationMetier(
  metierFamilie: MetierFamilieEntityState,
  organizationMetiersToNormalize: OrganizationMetierEntityState[],
  relations: { [key: string]: number | number[] }
): void {
  if (metierFamilie.organizationMetiers?.length && typeof metierFamilie.organizationMetiers[0] !== 'number') {
    const idsOrganizationMetiers: number[] = [];
    organizationMetiersToNormalize.push(
      ...(metierFamilie.organizationMetiers as OrganizationMetier[]).map((organizationMetier: OrganizationMetier) => {
        idsOrganizationMetiers.push(organizationMetier.idOrganizationMetier);
        return { ...organizationMetier, metierFamilie: metierFamilie.idMetierFamilie };
      })
    );
    relations['organizationMetiers'] = idsOrganizationMetiers;
  }
}
