import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedDocumentsResidence } from '@_model/interfaces/generated-documents-residence.model';
import { Observable } from 'rxjs';

export class GeneratedGeneratedDocumentsResidenceApiService {
  constructor(protected repo: RepositoryService) {}

  public getGeneratedDocumentsResidences(params?: any): Observable<GeneratedDocumentsResidence[]> {
    return this.repo.getData<GeneratedDocumentsResidence[]>('generated-documents-residence', params);
  }

  public getGeneratedDocumentsResidence(params: {
    idGeneratedDocumentsResidence: number;
    params?: any;
  }): Observable<GeneratedDocumentsResidence> {
    return this.repo.getData<GeneratedDocumentsResidence>(
      'generated-documents-residence/' + params.idGeneratedDocumentsResidence,
      params.params
    );
  }

  public addGeneratedDocumentsResidence(
    generatedDocumentsResidence: Partial<GeneratedDocumentsResidence>
  ): Observable<GeneratedDocumentsResidence> {
    return this.repo.create<GeneratedDocumentsResidence>('generated-documents-residence', generatedDocumentsResidence);
  }

  public updateGeneratedDocumentsResidence(
    generatedDocumentsResidence: Partial<GeneratedDocumentsResidence>
  ): Observable<GeneratedDocumentsResidence> {
    return this.repo.update('generated-documents-residence', generatedDocumentsResidence);
  }

  public deleteGeneratedDocumentsResidence(idGeneratedDocumentsResidence: number): Observable<number> {
    return this.repo.delete('generated-documents-residence/' + +idGeneratedDocumentsResidence);
  }
}
