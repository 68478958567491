import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { Occupant, OccupantEntityState } from '@_model/interfaces/occupant.model';
import { CompanyMedia, CompanyMediaEntityState } from '@_model/interfaces/company-media.model';
import { City, CityEntityState } from '@_model/interfaces/city.model';
import { CompanyCommunication, CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { CompanyPriceLabel, CompanyPriceLabelEntityState } from '@_model/interfaces/company-price-label.model';
import { CompanyTerritoire, CompanyTerritoireEntityState } from '@_model/interfaces/company-territoire.model';
import {
  OrganizationLeadTodoRule,
  OrganizationLeadTodoRuleEntityState
} from '@_model/interfaces/organization-lead-todo-rule.model';
import {
  OrganizationStratalotTodoRule,
  OrganizationStratalotTodoRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-rule.model';
import { CompanyStratalotType, CompanyStratalotTypeEntityState } from '@_model/interfaces/company-stratalot-type.model';
import {
  OrganizationResidenceTodoRule,
  OrganizationResidenceTodoRuleEntityState
} from '@_model/interfaces/organization-residence-todo-rule.model';
import {
  CompanyStratalotVacant,
  CompanyStratalotVacantEntityState
} from '@_model/interfaces/company-stratalot-vacant.model';
import {
  GeneratedDocumentsCompany,
  GeneratedDocumentsCompanyEntityState
} from '@_model/interfaces/generated-documents-company.model';
import { GeneratedDocument, GeneratedDocumentEntityState } from '@_model/interfaces/generated-document.model';
import { CompanyUbiflow, CompanyUbiflowEntityState } from '@_model/interfaces/company-ubiflow.model';
import { CompanyMyNotary, CompanyMyNotaryEntityState } from '@_model/interfaces/company-my-notary.model';
import {
  CompanyStudyNextAction,
  CompanyStudyNextActionEntityState
} from '@_model/interfaces/company-study-next-action.model';
import {
  CompanyStudyConclusion,
  CompanyStudyConclusionEntityState
} from '@_model/interfaces/company-study-conclusion.model';
import { CompanyStudyReason, CompanyStudyReasonEntityState } from '@_model/interfaces/company-study-reason.model';
import { CompanyStudyCriteria, CompanyStudyCriteriaEntityState } from '@_model/interfaces/company-study-criteria.model';
import { ResidenceStudy, ResidenceStudyEntityState } from '@_model/interfaces/residence-study.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, companyFeatureKey, CompanyState } from './company.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyRelations: string[] = [
  'occupants',
  'companyMedias',
  'cities',
  'companyCommunications',
  'residences',
  'companyPriceLabels',
  'companyTerritoires',
  'organizationLeadTodoRules',
  'organizationStratalotTodoRules',
  'companyStratalotTypes',
  'organizationResidenceTodoRules',
  'companyStratalotVacants',
  'generatedDocumentsCompanies',
  'generatedDocuments',
  'companyUbiflows',
  'companyMyNotaries',
  'companyStudyNextActions',
  'companyStudyConclusions',
  'companyStudyReasons',
  'companyStudyCriterias',
  'residenceStudies',
  'organizations'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyState = createFeatureSelector<CompanyState>(companyFeatureKey);

export const selectIsLoadedCompany = createSelector(selectCompanyState, (state: CompanyState) => state.isLoaded);

export const selectIsLoadingCompany = createSelector(selectCompanyState, (state: CompanyState) => state.isLoading);

export const selectIsReadyCompany = createSelector(selectCompanyState, (state: CompanyState) => !state.isLoading);

export const selectIsReadyAndLoadedCompany = createSelector(
  selectCompanyState,
  (state: CompanyState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyModel: SelectorModel = {
  name: 'companies',
  getSelector: selectAllCompaniesDictionary,
  isReady: selectIsReadyCompany
};

export const selectCompaniesEntities = createSelector(selectCompanyState, selectEntities);

export const selectCompaniesArray = createSelector(selectCompanyState, selectAll);

export const selectIdCompaniesActive = createSelector(selectCompanyState, (state: CompanyState) => state.actives);

const companiesInObject = (companies: Dictionary<CompanyEntityState>) => ({ companies });

const selectCompaniesEntitiesDictionary = createSelector(selectCompaniesEntities, companiesInObject);

const selectAllCompaniesObject = createSelector(selectCompaniesEntities, companies => {
  return hydrateAll({ companies });
});

const selectOneCompanyDictionary = (idCompany: number) =>
  createSelector(selectCompaniesEntities, companies => {
    return { companies: { [idCompany]: companies[idCompany] } };
  });

const selectOneCompanyDictionaryWithoutChild = (idCompany: number) =>
  createSelector(selectCompaniesEntities, companies => {
    return { company: companies[idCompany] };
  });

const selectActiveCompaniesEntities = createSelector(
  selectIdCompaniesActive,
  selectCompaniesEntities,
  (actives: number[], companies: Dictionary<CompanyEntityState>) => getCompaniesFromActives(actives, companies)
);

function getCompaniesFromActives(
  actives: number[],
  companies: Dictionary<CompanyEntityState>
): Dictionary<CompanyEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companies[idActive]) {
      acc[idActive] = companies[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyEntityState>);
}

const selectAllCompaniesSelectors: Dictionary<Selector> = {};
export function selectAllCompanies(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Company>(
      schema,
      selectAllCompaniesSelectors,
      selectCompaniesEntitiesDictionary,
      getRelationSelectors,
      companyRelations,
      hydrateAll,
      'company'
    );
  } else {
    return selectAllCompaniesObject;
  }
}

export function selectAllCompaniesDictionary(schema: SelectSchema = {}, customKey: string = 'companies'): Selector {
  return createSelector(selectAllCompanies(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companies.length; i++) {
      res[customKey][result.companies[i].idCompany] = result.companies[i];
    }
    return res;
  });
}

export function selectOneCompany(schema: SelectSchema = {}, idCompany: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyDictionary(idCompany)];
    selectors.push(...getRelationSelectors(schema, companyRelations, 'company'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyDictionaryWithoutChild(idCompany);
  }
}

export function selectActiveCompanies(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [createSelector(selectActiveCompaniesEntities, companies => ({ companies }))];
  selectors.push(...getRelationSelectors(schema, companyRelations, 'company'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companies: Dictionary<CompanyEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  occupants?: Dictionary<OccupantEntityState>;
  companyMedias?: Dictionary<CompanyMediaEntityState>;
  cities?: Dictionary<CityEntityState>;
  companyCommunications?: Dictionary<CompanyCommunicationEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
  companyPriceLabels?: Dictionary<CompanyPriceLabelEntityState>;
  companyTerritoires?: Dictionary<CompanyTerritoireEntityState>;
  organizationLeadTodoRules?: Dictionary<OrganizationLeadTodoRuleEntityState>;
  organizationStratalotTodoRules?: Dictionary<OrganizationStratalotTodoRuleEntityState>;
  companyStratalotTypes?: Dictionary<CompanyStratalotTypeEntityState>;
  organizationResidenceTodoRules?: Dictionary<OrganizationResidenceTodoRuleEntityState>;
  companyStratalotVacants?: Dictionary<CompanyStratalotVacantEntityState>;
  generatedDocumentsCompanies?: Dictionary<GeneratedDocumentsCompanyEntityState>;
  generatedDocuments?: Dictionary<GeneratedDocumentEntityState>;
  companyUbiflows?: Dictionary<CompanyUbiflowEntityState>;
  companyMyNotaries?: Dictionary<CompanyMyNotaryEntityState>;
  companyStudyNextActions?: Dictionary<CompanyStudyNextActionEntityState>;
  companyStudyConclusions?: Dictionary<CompanyStudyConclusionEntityState>;
  companyStudyReasons?: Dictionary<CompanyStudyReasonEntityState>;
  companyStudyCriterias?: Dictionary<CompanyStudyCriteriaEntityState>;
  residenceStudies?: Dictionary<ResidenceStudyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { companies: (Company | null)[] } {
  const {
    companies,
    organizations,
    occupants,
    companyMedias,
    cities,
    companyCommunications,
    residences,
    companyPriceLabels,
    companyTerritoires,
    organizationLeadTodoRules,
    organizationStratalotTodoRules,
    companyStratalotTypes,
    organizationResidenceTodoRules,
    companyStratalotVacants,
    generatedDocumentsCompanies,
    generatedDocuments,
    companyUbiflows,
    companyMyNotaries,
    companyStudyNextActions,
    companyStudyConclusions,
    companyStudyReasons,
    companyStudyCriterias,
    residenceStudies
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    companies: Object.keys(companies).map(idCompany =>
      hydrate(
        companies[idCompany] as CompanyEntityState,
        organizations,
        occupants,
        companyMedias,
        cities,
        companyCommunications,
        residences,
        companyPriceLabels,
        companyTerritoires,
        organizationLeadTodoRules,
        organizationStratalotTodoRules,
        companyStratalotTypes,
        organizationResidenceTodoRules,
        companyStratalotVacants,
        generatedDocumentsCompanies,
        generatedDocuments,
        companyUbiflows,
        companyMyNotaries,
        companyStudyNextActions,
        companyStudyConclusions,
        companyStudyReasons,
        companyStudyCriterias,
        residenceStudies
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { company: CompanyEntityState | null } {
  const {
    companies,
    organizations,
    occupants,
    companyMedias,
    cities,
    companyCommunications,
    residences,
    companyPriceLabels,
    companyTerritoires,
    organizationLeadTodoRules,
    organizationStratalotTodoRules,
    companyStratalotTypes,
    organizationResidenceTodoRules,
    companyStratalotVacants,
    generatedDocumentsCompanies,
    generatedDocuments,
    companyUbiflows,
    companyMyNotaries,
    companyStudyNextActions,
    companyStudyConclusions,
    companyStudyReasons,
    companyStudyCriterias,
    residenceStudies
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const company = Object.values(companies)[0];
  return {
    company: hydrate(
      company as CompanyEntityState,
      organizations,
      occupants,
      companyMedias,
      cities,
      companyCommunications,
      residences,
      companyPriceLabels,
      companyTerritoires,
      organizationLeadTodoRules,
      organizationStratalotTodoRules,
      companyStratalotTypes,
      organizationResidenceTodoRules,
      companyStratalotVacants,
      generatedDocumentsCompanies,
      generatedDocuments,
      companyUbiflows,
      companyMyNotaries,
      companyStudyNextActions,
      companyStudyConclusions,
      companyStudyReasons,
      companyStudyCriterias,
      residenceStudies
    )
  };
}

function hydrate(
  company: CompanyEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  occupantEntities?: Dictionary<OccupantEntityState>,
  companyMediaEntities?: Dictionary<CompanyMediaEntityState>,
  cityEntities?: Dictionary<CityEntityState>,
  companyCommunicationEntities?: Dictionary<CompanyCommunicationEntityState>,
  residenceEntities?: Dictionary<ResidenceEntityState>,
  companyPriceLabelEntities?: Dictionary<CompanyPriceLabelEntityState>,
  companyTerritoireEntities?: Dictionary<CompanyTerritoireEntityState>,
  organizationLeadTodoRuleEntities?: Dictionary<OrganizationLeadTodoRuleEntityState>,
  organizationStratalotTodoRuleEntities?: Dictionary<OrganizationStratalotTodoRuleEntityState>,
  companyStratalotTypeEntities?: Dictionary<CompanyStratalotTypeEntityState>,
  organizationResidenceTodoRuleEntities?: Dictionary<OrganizationResidenceTodoRuleEntityState>,
  companyStratalotVacantEntities?: Dictionary<CompanyStratalotVacantEntityState>,
  generatedDocumentsCompanyEntities?: Dictionary<GeneratedDocumentsCompanyEntityState>,
  generatedDocumentEntities?: Dictionary<GeneratedDocumentEntityState>,
  companyUbiflowEntities?: Dictionary<CompanyUbiflowEntityState>,
  companyMyNotaryEntities?: Dictionary<CompanyMyNotaryEntityState>,
  companyStudyNextActionEntities?: Dictionary<CompanyStudyNextActionEntityState>,
  companyStudyConclusionEntities?: Dictionary<CompanyStudyConclusionEntityState>,
  companyStudyReasonEntities?: Dictionary<CompanyStudyReasonEntityState>,
  companyStudyCriteriaEntities?: Dictionary<CompanyStudyCriteriaEntityState>,
  residenceStudyEntities?: Dictionary<ResidenceStudyEntityState>
): Company | null {
  if (!company) {
    return null;
  }

  const companyHydrated: CompanyEntityState = { ...company };
  if (organizationEntities) {
    companyHydrated.organization = organizationEntities[company.organization as number] as Organization;
  } else {
    delete companyHydrated.organization;
  }

  if (occupantEntities) {
    companyHydrated.occupants = ((companyHydrated.occupants as number[]) || []).map(
      id => occupantEntities[id]
    ) as Occupant[];
  } else {
    delete companyHydrated.occupants;
  }

  if (companyMediaEntities) {
    companyHydrated.companyMedias = ((companyHydrated.companyMedias as number[]) || []).map(
      id => companyMediaEntities[id]
    ) as CompanyMedia[];
  } else {
    delete companyHydrated.companyMedias;
  }

  if (cityEntities) {
    companyHydrated.cities = ((companyHydrated.cities as number[]) || []).map(id => cityEntities[id]) as City[];
  } else {
    delete companyHydrated.cities;
  }

  if (companyCommunicationEntities) {
    companyHydrated.companyCommunications = ((companyHydrated.companyCommunications as number[]) || []).map(
      id => companyCommunicationEntities[id]
    ) as CompanyCommunication[];
  } else {
    delete companyHydrated.companyCommunications;
  }

  if (residenceEntities) {
    companyHydrated.residences = ((companyHydrated.residences as number[]) || []).map(
      id => residenceEntities[id]
    ) as Residence[];
  } else {
    delete companyHydrated.residences;
  }

  if (companyPriceLabelEntities) {
    companyHydrated.companyPriceLabels = ((companyHydrated.companyPriceLabels as number[]) || []).map(
      id => companyPriceLabelEntities[id]
    ) as CompanyPriceLabel[];
  } else {
    delete companyHydrated.companyPriceLabels;
  }

  if (companyTerritoireEntities) {
    companyHydrated.companyTerritoires = ((companyHydrated.companyTerritoires as number[]) || []).map(
      id => companyTerritoireEntities[id]
    ) as CompanyTerritoire[];
  } else {
    delete companyHydrated.companyTerritoires;
  }

  if (organizationLeadTodoRuleEntities) {
    companyHydrated.organizationLeadTodoRules = ((companyHydrated.organizationLeadTodoRules as number[]) || []).map(
      id => organizationLeadTodoRuleEntities[id]
    ) as OrganizationLeadTodoRule[];
  } else {
    delete companyHydrated.organizationLeadTodoRules;
  }

  if (organizationStratalotTodoRuleEntities) {
    companyHydrated.organizationStratalotTodoRules = (
      (companyHydrated.organizationStratalotTodoRules as number[]) || []
    ).map(id => organizationStratalotTodoRuleEntities[id]) as OrganizationStratalotTodoRule[];
  } else {
    delete companyHydrated.organizationStratalotTodoRules;
  }

  if (companyStratalotTypeEntities) {
    companyHydrated.companyStratalotTypes = ((companyHydrated.companyStratalotTypes as number[]) || []).map(
      id => companyStratalotTypeEntities[id]
    ) as CompanyStratalotType[];
  } else {
    delete companyHydrated.companyStratalotTypes;
  }

  if (organizationResidenceTodoRuleEntities) {
    companyHydrated.organizationResidenceTodoRules = (
      (companyHydrated.organizationResidenceTodoRules as number[]) || []
    ).map(id => organizationResidenceTodoRuleEntities[id]) as OrganizationResidenceTodoRule[];
  } else {
    delete companyHydrated.organizationResidenceTodoRules;
  }

  if (companyStratalotVacantEntities) {
    companyHydrated.companyStratalotVacants = ((companyHydrated.companyStratalotVacants as number[]) || []).map(
      id => companyStratalotVacantEntities[id]
    ) as CompanyStratalotVacant[];
  } else {
    delete companyHydrated.companyStratalotVacants;
  }

  if (generatedDocumentsCompanyEntities) {
    companyHydrated.generatedDocumentsCompanies = ((companyHydrated.generatedDocumentsCompanies as number[]) || []).map(
      id => generatedDocumentsCompanyEntities[id]
    ) as GeneratedDocumentsCompany[];
  } else {
    delete companyHydrated.generatedDocumentsCompanies;
  }

  if (generatedDocumentEntities) {
    companyHydrated.generatedDocuments = ((companyHydrated.generatedDocuments as number[]) || []).map(
      id => generatedDocumentEntities[id]
    ) as GeneratedDocument[];
  } else {
    delete companyHydrated.generatedDocuments;
  }

  if (companyUbiflowEntities) {
    companyHydrated.companyUbiflows = ((companyHydrated.companyUbiflows as number[]) || []).map(
      id => companyUbiflowEntities[id]
    ) as CompanyUbiflow[];
  } else {
    delete companyHydrated.companyUbiflows;
  }

  if (companyMyNotaryEntities) {
    companyHydrated.companyMyNotaries = ((companyHydrated.companyMyNotaries as number[]) || []).map(
      id => companyMyNotaryEntities[id]
    ) as CompanyMyNotary[];
  } else {
    delete companyHydrated.companyMyNotaries;
  }

  if (companyStudyNextActionEntities) {
    companyHydrated.companyStudyNextActions = ((companyHydrated.companyStudyNextActions as number[]) || []).map(
      id => companyStudyNextActionEntities[id]
    ) as CompanyStudyNextAction[];
  } else {
    delete companyHydrated.companyStudyNextActions;
  }

  if (companyStudyConclusionEntities) {
    companyHydrated.companyStudyConclusions = ((companyHydrated.companyStudyConclusions as number[]) || []).map(
      id => companyStudyConclusionEntities[id]
    ) as CompanyStudyConclusion[];
  } else {
    delete companyHydrated.companyStudyConclusions;
  }

  if (companyStudyReasonEntities) {
    companyHydrated.companyStudyReasons = ((companyHydrated.companyStudyReasons as number[]) || []).map(
      id => companyStudyReasonEntities[id]
    ) as CompanyStudyReason[];
  } else {
    delete companyHydrated.companyStudyReasons;
  }

  if (companyStudyCriteriaEntities) {
    companyHydrated.companyStudyCriterias = ((companyHydrated.companyStudyCriterias as number[]) || []).map(
      id => companyStudyCriteriaEntities[id]
    ) as CompanyStudyCriteria[];
  } else {
    delete companyHydrated.companyStudyCriterias;
  }

  if (residenceStudyEntities) {
    companyHydrated.residenceStudies = ((companyHydrated.residenceStudies as number[]) || []).map(
      id => residenceStudyEntities[id]
    ) as ResidenceStudy[];
  } else {
    delete companyHydrated.residenceStudies;
  }

  return companyHydrated as Company;
}
