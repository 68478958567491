import { RepositoryService } from '@_services/api/repository.service';
import { StratalotTodo } from '@_model/interfaces/stratalot-todo.model';
import { Observable } from 'rxjs';

export class GeneratedStratalotTodoApiService {
  constructor(protected repo: RepositoryService) {}

  public getStratalotTodos(params?: any): Observable<StratalotTodo[]> {
    return this.repo.getData<StratalotTodo[]>('stratalot-todo', params);
  }

  public getStratalotTodo(params: { idStratalotTodo: number; params?: any }): Observable<StratalotTodo> {
    return this.repo.getData<StratalotTodo>('stratalot-todo/' + params.idStratalotTodo, params.params);
  }

  public addStratalotTodo(stratalotTodo: Partial<StratalotTodo>): Observable<StratalotTodo> {
    return this.repo.create<StratalotTodo>('stratalot-todo', stratalotTodo);
  }

  public updateStratalotTodo(stratalotTodo: Partial<StratalotTodo>): Observable<StratalotTodo> {
    return this.repo.update('stratalot-todo', stratalotTodo);
  }

  public deleteStratalotTodo(idStratalotTodo: number): Observable<number> {
    return this.repo.delete('stratalot-todo/' + +idStratalotTodo);
  }
}
