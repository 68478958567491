import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationLeadAvancement } from '@_model/interfaces/organization-lead-avancement.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationLeadAvancementApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationLeadAvancements(params?: any): Observable<OrganizationLeadAvancement[]> {
    return this.repo.getData<OrganizationLeadAvancement[]>('organization-lead-avancement', params);
  }

  public getOrganizationLeadAvancement(params: {
    idOrganizationLeadAvancement: number;
    params?: any;
  }): Observable<OrganizationLeadAvancement> {
    return this.repo.getData<OrganizationLeadAvancement>(
      'organization-lead-avancement/' + params.idOrganizationLeadAvancement,
      params.params
    );
  }

  public addOrganizationLeadAvancement(
    organizationLeadAvancement: Partial<OrganizationLeadAvancement>
  ): Observable<OrganizationLeadAvancement> {
    return this.repo.create<OrganizationLeadAvancement>('organization-lead-avancement', organizationLeadAvancement);
  }

  public updateOrganizationLeadAvancement(
    organizationLeadAvancement: Partial<OrganizationLeadAvancement>
  ): Observable<OrganizationLeadAvancement> {
    return this.repo.update('organization-lead-avancement', organizationLeadAvancement);
  }

  public deleteOrganizationLeadAvancement(idOrganizationLeadAvancement: number): Observable<number> {
    return this.repo.delete('organization-lead-avancement/' + +idOrganizationLeadAvancement);
  }
}
