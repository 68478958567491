import { RepositoryService } from '@_services/api/repository.service';
import { CampaignMedia } from '@_model/interfaces/campaign-media.model';
import { Observable } from 'rxjs';

export class GeneratedCampaignMediaApiService {
  constructor(protected repo: RepositoryService) {}

  public getCampaignMedias(params?: any): Observable<CampaignMedia[]> {
    return this.repo.getData<CampaignMedia[]>('campaign-media', params);
  }

  public getCampaignMedia(params: { idCampaignMedia: number; params?: any }): Observable<CampaignMedia> {
    return this.repo.getData<CampaignMedia>('campaign-media/' + params.idCampaignMedia, params.params);
  }

  public addCampaignMedia(campaignMedia: Partial<CampaignMedia>): Observable<CampaignMedia> {
    return this.repo.create<CampaignMedia>('campaign-media', campaignMedia);
  }

  public updateCampaignMedia(campaignMedia: Partial<CampaignMedia>): Observable<CampaignMedia> {
    return this.repo.update('campaign-media', campaignMedia);
  }

  public deleteCampaignMedia(idCampaignMedia: number): Observable<number> {
    return this.repo.delete('campaign-media/' + +idCampaignMedia);
  }
}
