import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Occupant, OccupantEntityState } from '@_model/interfaces/occupant.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { Prospect, ProspectEntityState } from '@_model/interfaces/prospect.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, occupantFeatureKey, OccupantState } from './occupant.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const occupantRelations: string[] = ['stratalots', 'prospects', 'companies'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOccupantState = createFeatureSelector<OccupantState>(occupantFeatureKey);

export const selectIsLoadedOccupant = createSelector(selectOccupantState, (state: OccupantState) => state.isLoaded);

export const selectIsLoadingOccupant = createSelector(selectOccupantState, (state: OccupantState) => state.isLoading);

export const selectIsReadyOccupant = createSelector(selectOccupantState, (state: OccupantState) => !state.isLoading);

export const selectIsReadyAndLoadedOccupant = createSelector(
  selectOccupantState,
  (state: OccupantState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OccupantModel: SelectorModel = {
  name: 'occupants',
  getSelector: selectAllOccupantsDictionary,
  isReady: selectIsReadyOccupant
};

export const selectOccupantsEntities = createSelector(selectOccupantState, selectEntities);

export const selectOccupantsArray = createSelector(selectOccupantState, selectAll);

export const selectIdOccupantsActive = createSelector(selectOccupantState, (state: OccupantState) => state.actives);

const occupantsInObject = (occupants: Dictionary<OccupantEntityState>) => ({ occupants });

const selectOccupantsEntitiesDictionary = createSelector(selectOccupantsEntities, occupantsInObject);

const selectAllOccupantsObject = createSelector(selectOccupantsEntities, occupants => {
  return hydrateAll({ occupants });
});

const selectOneOccupantDictionary = (idOccupant: number) =>
  createSelector(selectOccupantsEntities, occupants => {
    return { occupants: { [idOccupant]: occupants[idOccupant] } };
  });

const selectOneOccupantDictionaryWithoutChild = (idOccupant: number) =>
  createSelector(selectOccupantsEntities, occupants => {
    return { occupant: occupants[idOccupant] };
  });

const selectActiveOccupantsEntities = createSelector(
  selectIdOccupantsActive,
  selectOccupantsEntities,
  (actives: number[], occupants: Dictionary<OccupantEntityState>) => getOccupantsFromActives(actives, occupants)
);

function getOccupantsFromActives(
  actives: number[],
  occupants: Dictionary<OccupantEntityState>
): Dictionary<OccupantEntityState> {
  return actives.reduce((acc, idActive) => {
    if (occupants[idActive]) {
      acc[idActive] = occupants[idActive];
    }
    return acc;
  }, {} as Dictionary<OccupantEntityState>);
}

const selectAllOccupantsSelectors: Dictionary<Selector> = {};
export function selectAllOccupants(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Occupant>(
      schema,
      selectAllOccupantsSelectors,
      selectOccupantsEntitiesDictionary,
      getRelationSelectors,
      occupantRelations,
      hydrateAll,
      'occupant'
    );
  } else {
    return selectAllOccupantsObject;
  }
}

export function selectAllOccupantsDictionary(schema: SelectSchema = {}, customKey: string = 'occupants'): Selector {
  return createSelector(selectAllOccupants(schema), result => {
    const res = { [customKey]: {} as Dictionary<OccupantEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.occupants.length; i++) {
      res[customKey][result.occupants[i].idOccupant] = result.occupants[i];
    }
    return res;
  });
}

export function selectOneOccupant(schema: SelectSchema = {}, idOccupant: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOccupantDictionary(idOccupant)];
    selectors.push(...getRelationSelectors(schema, occupantRelations, 'occupant'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOccupantDictionaryWithoutChild(idOccupant);
  }
}

export function selectActiveOccupants(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [createSelector(selectActiveOccupantsEntities, occupants => ({ occupants }))];
  selectors.push(...getRelationSelectors(schema, occupantRelations, 'occupant'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  occupants: Dictionary<OccupantEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
  prospects?: Dictionary<ProspectEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { occupants: (Occupant | null)[] } {
  const { occupants, companies, stratalots, prospects } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    occupants: Object.keys(occupants).map(idOccupant =>
      hydrate(occupants[idOccupant] as OccupantEntityState, companies, stratalots, prospects)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { occupant: OccupantEntityState | null } {
  const { occupants, companies, stratalots, prospects } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const occupant = Object.values(occupants)[0];
  return { occupant: hydrate(occupant as OccupantEntityState, companies, stratalots, prospects) };
}

function hydrate(
  occupant: OccupantEntityState,
  companyEntities?: Dictionary<CompanyEntityState>,
  stratalotEntities?: Dictionary<StratalotEntityState>,
  prospectEntities?: Dictionary<ProspectEntityState>
): Occupant | null {
  if (!occupant) {
    return null;
  }

  const occupantHydrated: OccupantEntityState = { ...occupant };
  if (companyEntities) {
    occupantHydrated.company = companyEntities[occupant.company as number] as Company;
  } else {
    delete occupantHydrated.company;
  }

  if (stratalotEntities) {
    occupantHydrated.stratalots = ((occupantHydrated.stratalots as number[]) || []).map(
      id => stratalotEntities[id]
    ) as Stratalot[];
  } else {
    delete occupantHydrated.stratalots;
  }

  if (prospectEntities) {
    occupantHydrated.prospects = ((occupantHydrated.prospects as number[]) || []).map(
      id => prospectEntities[id]
    ) as Prospect[];
  } else {
    delete occupantHydrated.prospects;
  }

  return occupantHydrated as Occupant;
}
