import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { City } from '@_shared/models/interfaces/city.model';
import { Observable } from 'rxjs';
import { GeneratedCityApiService } from './city-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CityApiService extends GeneratedCityApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public getCitiesByCompany(params?: { idCompany: number; params?: any }): Observable<City[]> {
    return this.repo.getData<City[]>('city/company/' + params.idCompany, params.params);
  }
}
