import { OrganizationResidenceTodoRuleState, initialState } from './organization-residence-todo-rule.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationResidenceTodoRuleReducersGeneratedFunctions } from './organization-residence-todo-rule-generated.reducer';

const organizationResidenceTodoRuleReducersFunctions = [...organizationResidenceTodoRuleReducersGeneratedFunctions];

const organizationResidenceTodoRuleReducer = createReducer(
  initialState,
  ...organizationResidenceTodoRuleReducersFunctions
);

export function reducer(state: OrganizationResidenceTodoRuleState | undefined, action: Action) {
  return organizationResidenceTodoRuleReducer(state, action);
}
