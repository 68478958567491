import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyMedia, CompanyMediaEntityState } from '@_model/interfaces/company-media.model';
import { CampaignMedia, CampaignMediaEntityState } from '@_model/interfaces/campaign-media.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, companyMediaFeatureKey, CompanyMediaState } from './company-media.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyMediaRelations: string[] = ['campaignMedias', 'companies'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyMediaState = createFeatureSelector<CompanyMediaState>(companyMediaFeatureKey);

export const selectIsLoadedCompanyMedia = createSelector(
  selectCompanyMediaState,
  (state: CompanyMediaState) => state.isLoaded
);

export const selectIsLoadingCompanyMedia = createSelector(
  selectCompanyMediaState,
  (state: CompanyMediaState) => state.isLoading
);

export const selectIsReadyCompanyMedia = createSelector(
  selectCompanyMediaState,
  (state: CompanyMediaState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyMedia = createSelector(
  selectCompanyMediaState,
  (state: CompanyMediaState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyMediaModel: SelectorModel = {
  name: 'companyMedias',
  getSelector: selectAllCompanyMediasDictionary,
  isReady: selectIsReadyCompanyMedia
};

export const selectCompanyMediasEntities = createSelector(selectCompanyMediaState, selectEntities);

export const selectCompanyMediasArray = createSelector(selectCompanyMediaState, selectAll);

export const selectIdCompanyMediasActive = createSelector(
  selectCompanyMediaState,
  (state: CompanyMediaState) => state.actives
);

const companyMediasInObject = (companyMedias: Dictionary<CompanyMediaEntityState>) => ({ companyMedias });

const selectCompanyMediasEntitiesDictionary = createSelector(selectCompanyMediasEntities, companyMediasInObject);

const selectAllCompanyMediasObject = createSelector(selectCompanyMediasEntities, companyMedias => {
  return hydrateAll({ companyMedias });
});

const selectOneCompanyMediaDictionary = (idCompanyMedia: number) =>
  createSelector(selectCompanyMediasEntities, companyMedias => {
    return { companyMedias: { [idCompanyMedia]: companyMedias[idCompanyMedia] } };
  });

const selectOneCompanyMediaDictionaryWithoutChild = (idCompanyMedia: number) =>
  createSelector(selectCompanyMediasEntities, companyMedias => {
    return { companyMedia: companyMedias[idCompanyMedia] };
  });

const selectActiveCompanyMediasEntities = createSelector(
  selectIdCompanyMediasActive,
  selectCompanyMediasEntities,
  (actives: number[], companyMedias: Dictionary<CompanyMediaEntityState>) =>
    getCompanyMediasFromActives(actives, companyMedias)
);

function getCompanyMediasFromActives(
  actives: number[],
  companyMedias: Dictionary<CompanyMediaEntityState>
): Dictionary<CompanyMediaEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyMedias[idActive]) {
      acc[idActive] = companyMedias[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyMediaEntityState>);
}

const selectAllCompanyMediasSelectors: Dictionary<Selector> = {};
export function selectAllCompanyMedias(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyMedia>(
      schema,
      selectAllCompanyMediasSelectors,
      selectCompanyMediasEntitiesDictionary,
      getRelationSelectors,
      companyMediaRelations,
      hydrateAll,
      'companyMedia'
    );
  } else {
    return selectAllCompanyMediasObject;
  }
}

export function selectAllCompanyMediasDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyMedias'
): Selector {
  return createSelector(selectAllCompanyMedias(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyMediaEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyMedias.length; i++) {
      res[customKey][result.companyMedias[i].idCompanyMedia] = result.companyMedias[i];
    }
    return res;
  });
}

export function selectOneCompanyMedia(schema: SelectSchema = {}, idCompanyMedia: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyMediaDictionary(idCompanyMedia)];
    selectors.push(...getRelationSelectors(schema, companyMediaRelations, 'companyMedia'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyMediaDictionaryWithoutChild(idCompanyMedia);
  }
}

export function selectActiveCompanyMedias(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyMediasEntities, companyMedias => ({ companyMedias }))
  ];
  selectors.push(...getRelationSelectors(schema, companyMediaRelations, 'companyMedia'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyMedias: Dictionary<CompanyMediaEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  campaignMedias?: Dictionary<CampaignMediaEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { companyMedias: (CompanyMedia | null)[] } {
  const { companyMedias, companies, campaignMedias } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    companyMedias: Object.keys(companyMedias).map(idCompanyMedia =>
      hydrate(companyMedias[idCompanyMedia] as CompanyMediaEntityState, companies, campaignMedias)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { companyMedia: CompanyMediaEntityState | null } {
  const { companyMedias, companies, campaignMedias } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const companyMedia = Object.values(companyMedias)[0];
  return { companyMedia: hydrate(companyMedia as CompanyMediaEntityState, companies, campaignMedias) };
}

function hydrate(
  companyMedia: CompanyMediaEntityState,
  companyEntities?: Dictionary<CompanyEntityState>,
  campaignMediaEntities?: Dictionary<CampaignMediaEntityState>
): CompanyMedia | null {
  if (!companyMedia) {
    return null;
  }

  const companyMediaHydrated: CompanyMediaEntityState = { ...companyMedia };
  if (companyEntities) {
    companyMediaHydrated.company = companyEntities[companyMedia.company as number] as Company;
  } else {
    delete companyMediaHydrated.company;
  }

  if (campaignMediaEntities) {
    companyMediaHydrated.campaignMedias = ((companyMediaHydrated.campaignMedias as number[]) || []).map(
      id => campaignMediaEntities[id]
    ) as CampaignMedia[];
  } else {
    delete companyMediaHydrated.campaignMedias;
  }

  return companyMediaHydrated as CompanyMedia;
}
