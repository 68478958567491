import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StratalotCampaignEntityState } from '@_model/interfaces/stratalot-campaign.model';

export interface StratalotCampaignState extends EntityState<StratalotCampaignEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<StratalotCampaignEntityState> = createEntityAdapter<StratalotCampaignEntityState>({
  selectId: o => o.idStratalotCampaign
});
export const initialState: StratalotCampaignState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const stratalotCampaignFeatureKey = 'stratalotCampaign';
