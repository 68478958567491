import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { Observable } from 'rxjs';
import { GeneratedCompanyStratalotTypeApiService } from './company-stratalot-type-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyStratalotTypeApiService extends GeneratedCompanyStratalotTypeApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
  public deleteAndReplaceCompanyStratalotType(idCompanyStratalotType: number, params?: any): Observable<number> {
    return this.repo.delete('company-stratalot-type/' + +idCompanyStratalotType, params);
  }
}
