import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as AppState from '@_store/index.reducers';
import { GeneratedOrganizationService } from './organization-generated.service';
import { Actions } from '@ngrx/effects';
import { Organization } from '@_shared/models/interfaces/organization.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService extends GeneratedOrganizationService {
  public currentIdOrganization: number;
  public currentOrganization: Organization;

  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }
}
