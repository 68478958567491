import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StratalotRcpEntityState } from '@_model/interfaces/stratalot-rcp.model';

export interface StratalotRcpState extends EntityState<StratalotRcpEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<StratalotRcpEntityState> = createEntityAdapter<StratalotRcpEntityState>({
  selectId: o => o.idStratalotRcp
});
export const initialState: StratalotRcpState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const stratalotRcpFeatureKey = 'stratalotRcp';
