import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyCommunicationFileEntityState } from '@_model/interfaces/company-communication-file.model';

export interface CompanyCommunicationFileState extends EntityState<CompanyCommunicationFileEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyCommunicationFileEntityState> =
  createEntityAdapter<CompanyCommunicationFileEntityState>({
    selectId: o => o.idCompanyCommunicationFile
  });
export const initialState: CompanyCommunicationFileState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyCommunicationFileFeatureKey = 'companyCommunicationFile';
