import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationStratalotTodoProfilEntityState } from '@_model/interfaces/organization-stratalot-todo-profil.model';

export interface OrganizationStratalotTodoProfilState extends EntityState<OrganizationStratalotTodoProfilEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationStratalotTodoProfilEntityState> =
  createEntityAdapter<OrganizationStratalotTodoProfilEntityState>({
    selectId: o => o.idOrganizationStratalotTodoProfil
  });
export const initialState: OrganizationStratalotTodoProfilState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationStratalotTodoProfilFeatureKey = 'organizationStratalotTodoProfil';
