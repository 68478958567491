import { ResidenceShareState, initialState } from './residence-share.state';
import { Action, createReducer } from '@ngrx/store';
import { residenceShareReducersGeneratedFunctions } from './residence-share-generated.reducer';

const residenceShareReducersFunctions = [...residenceShareReducersGeneratedFunctions];

const residenceShareReducer = createReducer(initialState, ...residenceShareReducersFunctions);

export function reducer(state: ResidenceShareState | undefined, action: Action) {
  return residenceShareReducer(state, action);
}
