import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OccupantEntityState } from '@_model/interfaces/occupant.model';

export interface OccupantState extends EntityState<OccupantEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OccupantEntityState> = createEntityAdapter<OccupantEntityState>({
  selectId: o => o.idOccupant
});
export const initialState: OccupantState = adapter.getInitialState({ isLoaded: false, isLoading: false, actives: [] });

export const occupantFeatureKey = 'occupant';
