import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationThirdParty,
  OrganizationThirdPartyEntityState
} from '@_model/interfaces/organization-third-party.model';
import { OrganizationThirdPartyApiService } from '@_services/api/organization-third-party-api.service';
import * as organizationThirdPartyActions from '@_store/organization-third-party/organization-third-party.actions';
import { getActionsToNormalizeOrganizationThirdParty } from '@_config/store/normalization.generated';
import { selectOrganizationThirdPartyState } from './organization-third-party-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as organizationSocieteActions from '@_store/organization-societe/organization-societe.actions';
import * as organizationMetierActions from '@_store/organization-metier/organization-metier.actions';
import * as residenceContactActions from '@_store/residence-contact/residence-contact.actions';
import { ResidenceContact } from '@_model/interfaces/residence-contact.model';

export interface OrganizationThirdPartyRelationsIds {
  residenceContacts?: number | number[];
  organization?: number;
  organizationSociete?: number;
  organizationMetier?: number;
}

export function getDefaultAddOrganizationThirdPartyActions(
  organizationThirdParty: OrganizationThirdPartyEntityState,
  ids?: OrganizationThirdPartyRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationThirdPartyActions.normalizeManyOrganizationThirdPartiesAfterUpsert({
      organizationThirdParties: [organizationThirdParty]
    })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationThirdPartySuccess({
        idOrganization: ids.organization,
        idOrganizationThirdParties: [organizationThirdParty.idOrganizationThirdParty]
      })
    );
    actions.push(
      organizationThirdPartyActions.addOrganizationSuccess({
        idOrganizationThirdParty: organizationThirdParty.idOrganizationThirdParty,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.organizationSociete) {
    actions.push(
      organizationSocieteActions.addManyOrganizationThirdPartySuccess({
        idOrganizationSociete: ids.organizationSociete,
        idOrganizationThirdParties: [organizationThirdParty.idOrganizationThirdParty]
      })
    );
    actions.push(
      organizationThirdPartyActions.addOrganizationSocieteSuccess({
        idOrganizationThirdParty: organizationThirdParty.idOrganizationThirdParty,
        idOrganizationSociete: ids.organizationSociete
      })
    );
  }

  if (ids?.organizationMetier) {
    actions.push(
      organizationMetierActions.addManyOrganizationThirdPartySuccess({
        idOrganizationMetier: ids.organizationMetier,
        idOrganizationThirdParties: [organizationThirdParty.idOrganizationThirdParty]
      })
    );
    actions.push(
      organizationThirdPartyActions.addOrganizationMetierSuccess({
        idOrganizationThirdParty: organizationThirdParty.idOrganizationThirdParty,
        idOrganizationMetier: ids.organizationMetier
      })
    );
  }

  if (ids?.residenceContacts) {
    if (!Array.isArray(ids.residenceContacts)) {
      actions.push(
        residenceContactActions.upsertOneResidenceContact({
          residenceContact: {
            idOrganizationThirdParty: organizationThirdParty.idOrganizationThirdParty,
            idResidenceContact: ids.residenceContacts as number
          } as ResidenceContact
        })
      );
      actions.push(
        organizationThirdPartyActions.addManyResidenceContactSuccess({
          idOrganizationThirdParty: organizationThirdParty.idOrganizationThirdParty,
          idResidenceContacts: [ids.residenceContacts as number]
        })
      );
    } else {
      actions.push(
        residenceContactActions.upsertManyResidenceContacts({
          residenceContacts: (ids.residenceContacts as number[]).map((idResidenceContact: number) => ({
            idOrganizationThirdParty: organizationThirdParty.idOrganizationThirdParty,
            idResidenceContact
          })) as ResidenceContact[]
        })
      );
      actions.push(
        organizationThirdPartyActions.addManyResidenceContactSuccess({
          idOrganizationThirdParty: organizationThirdParty.idOrganizationThirdParty,
          idResidenceContacts: ids.residenceContacts as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationThirdPartyActions(
  organizationThirdParty: OrganizationThirdPartyEntityState
): Action[] {
  const actions: Action[] = [
    organizationThirdPartyActions.deleteOneOrganizationThirdPartySuccess({
      idOrganizationThirdParty: organizationThirdParty.idOrganizationThirdParty
    })
  ];

  if (organizationThirdParty.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationThirdPartySuccess({
        idOrganizationThirdParties: [organizationThirdParty.idOrganizationThirdParty],
        idOrganizations: [organizationThirdParty.organization as number]
      })
    );
  }

  if (organizationThirdParty.organizationSociete) {
    actions.push(
      organizationSocieteActions.deleteManyOrganizationThirdPartySuccess({
        idOrganizationThirdParties: [organizationThirdParty.idOrganizationThirdParty],
        idOrganizationSocietes: [organizationThirdParty.organizationSociete as number]
      })
    );
  }

  if (organizationThirdParty.organizationMetier) {
    actions.push(
      organizationMetierActions.deleteManyOrganizationThirdPartySuccess({
        idOrganizationThirdParties: [organizationThirdParty.idOrganizationThirdParty],
        idOrganizationMetiers: [organizationThirdParty.organizationMetier as number]
      })
    );
  }

  if (organizationThirdParty.residenceContacts) {
    actions.push(
      residenceContactActions.deleteManyOrganizationThirdPartySuccess({
        idOrganizationThirdParties: [organizationThirdParty.idOrganizationThirdParty],
        idResidenceContacts: organizationThirdParty.residenceContacts as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationThirdPartyEffects {
  constructor(
    protected actions$: Actions,
    protected organizationThirdPartyApiService: OrganizationThirdPartyApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationThirdParties$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationThirdPartyActions.getManyOrganizationThirdParties),
      switchMap(({ params }) =>
        this.organizationThirdPartyApiService.getOrganizationThirdParties(params).pipe(
          map((organizationThirdParties: OrganizationThirdParty[]) => {
            return organizationThirdPartyActions.normalizeManyOrganizationThirdPartiesAfterUpsert({
              organizationThirdParties
            });
          }),
          catchError(error => of(organizationThirdPartyActions.organizationThirdPartiesFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationThirdParty$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationThirdPartyActions.getOneOrganizationThirdParty),
      switchMap(idOrganizationThirdParty =>
        this.organizationThirdPartyApiService.getOrganizationThirdParty(idOrganizationThirdParty).pipe(
          map((organizationThirdParty: OrganizationThirdParty) => {
            return organizationThirdPartyActions.normalizeManyOrganizationThirdPartiesAfterUpsert({
              organizationThirdParties: [organizationThirdParty]
            });
          }),
          catchError(error => of(organizationThirdPartyActions.organizationThirdPartiesFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationThirdParty$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationThirdPartyActions.upsertOneOrganizationThirdParty),
      concatMap(
        ({
          organizationThirdParty,
          ids
        }: {
          organizationThirdParty: Partial<OrganizationThirdParty>;
          ids?: OrganizationThirdPartyRelationsIds;
        }) => {
          if (organizationThirdParty.idOrganizationThirdParty) {
            return this.organizationThirdPartyApiService.updateOrganizationThirdParty(organizationThirdParty).pipe(
              map((organizationThirdPartyReturned: OrganizationThirdParty) => {
                return organizationThirdPartyActions.normalizeManyOrganizationThirdPartiesAfterUpsert({
                  organizationThirdParties: [organizationThirdPartyReturned]
                });
              }),
              catchError(error => of(organizationThirdPartyActions.organizationThirdPartiesFailure({ error })))
            );
          } else {
            return this.organizationThirdPartyApiService.addOrganizationThirdParty(organizationThirdParty).pipe(
              mergeMap((organizationThirdPartyReturned: OrganizationThirdParty) =>
                getDefaultAddOrganizationThirdPartyActions(organizationThirdPartyReturned, ids)
              ),
              catchError(error => of(organizationThirdPartyActions.organizationThirdPartiesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationThirdParty$ = createEffect(() => {
    const selectOrganizationThirdPartyState$ = this.store$.select(selectOrganizationThirdPartyState);
    return this.actions$.pipe(
      ofType(organizationThirdPartyActions.deleteOneOrganizationThirdParty),
      withLatestFrom(selectOrganizationThirdPartyState$),
      concatMap(([{ idOrganizationThirdParty }, state]) =>
        this.organizationThirdPartyApiService.deleteOrganizationThirdParty(idOrganizationThirdParty).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOrganizationThirdPartyActions(
                state.entities[idOrganizationThirdParty] as OrganizationThirdPartyEntityState
              ),
              organizationThirdPartyActions.deleteOneOrganizationThirdParty.type
            )
          ]),
          catchError(error => of(organizationThirdPartyActions.organizationThirdPartiesFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationThirdPartiesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationThirdPartyActions.normalizeManyOrganizationThirdPartiesAfterUpsert),
      concatMap(({ organizationThirdParties }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationThirdParty(
          organizationThirdParties,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationThirdParty] Normalization After Upsert Success')];
      })
    );
  });
}
