import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StratalotType, StratalotTypeEntityState } from '@_model/interfaces/stratalot-type.model';
import { CompanyStratalotType, CompanyStratalotTypeEntityState } from '@_model/interfaces/company-stratalot-type.model';
import { ProspectBuyingWish, ProspectBuyingWishEntityState } from '@_model/interfaces/prospect-buying-wish.model';
import {
  OrganizationLeadTodoRule,
  OrganizationLeadTodoRuleEntityState
} from '@_model/interfaces/organization-lead-todo-rule.model';
import {
  OrganizationStratalotTodoRule,
  OrganizationStratalotTodoRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-rule.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, stratalotTypeFeatureKey, StratalotTypeState } from './stratalot-type.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const stratalotTypeRelations: string[] = [
  'companyStratalotTypes',
  'prospectBuyingWishs',
  'organizationLeadTodoRules',
  'organizationStratalotTodoRules'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectStratalotTypeState = createFeatureSelector<StratalotTypeState>(stratalotTypeFeatureKey);

export const selectIsLoadedStratalotType = createSelector(
  selectStratalotTypeState,
  (state: StratalotTypeState) => state.isLoaded
);

export const selectIsLoadingStratalotType = createSelector(
  selectStratalotTypeState,
  (state: StratalotTypeState) => state.isLoading
);

export const selectIsReadyStratalotType = createSelector(
  selectStratalotTypeState,
  (state: StratalotTypeState) => !state.isLoading
);

export const selectIsReadyAndLoadedStratalotType = createSelector(
  selectStratalotTypeState,
  (state: StratalotTypeState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const StratalotTypeModel: SelectorModel = {
  name: 'stratalotTypes',
  getSelector: selectAllStratalotTypesDictionary,
  isReady: selectIsReadyStratalotType
};

export const selectStratalotTypesEntities = createSelector(selectStratalotTypeState, selectEntities);

export const selectStratalotTypesArray = createSelector(selectStratalotTypeState, selectAll);

export const selectIdStratalotTypesActive = createSelector(
  selectStratalotTypeState,
  (state: StratalotTypeState) => state.actives
);

const stratalotTypesInObject = (stratalotTypes: Dictionary<StratalotTypeEntityState>) => ({ stratalotTypes });

const selectStratalotTypesEntitiesDictionary = createSelector(selectStratalotTypesEntities, stratalotTypesInObject);

const selectAllStratalotTypesObject = createSelector(selectStratalotTypesEntities, stratalotTypes => {
  return hydrateAll({ stratalotTypes });
});

const selectOneStratalotTypeDictionary = (idStratalotType: number) =>
  createSelector(selectStratalotTypesEntities, stratalotTypes => {
    return { stratalotTypes: { [idStratalotType]: stratalotTypes[idStratalotType] } };
  });

const selectOneStratalotTypeDictionaryWithoutChild = (idStratalotType: number) =>
  createSelector(selectStratalotTypesEntities, stratalotTypes => {
    return { stratalotType: stratalotTypes[idStratalotType] };
  });

const selectActiveStratalotTypesEntities = createSelector(
  selectIdStratalotTypesActive,
  selectStratalotTypesEntities,
  (actives: number[], stratalotTypes: Dictionary<StratalotTypeEntityState>) =>
    getStratalotTypesFromActives(actives, stratalotTypes)
);

function getStratalotTypesFromActives(
  actives: number[],
  stratalotTypes: Dictionary<StratalotTypeEntityState>
): Dictionary<StratalotTypeEntityState> {
  return actives.reduce((acc, idActive) => {
    if (stratalotTypes[idActive]) {
      acc[idActive] = stratalotTypes[idActive];
    }
    return acc;
  }, {} as Dictionary<StratalotTypeEntityState>);
}

const selectAllStratalotTypesSelectors: Dictionary<Selector> = {};
export function selectAllStratalotTypes(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<StratalotType>(
      schema,
      selectAllStratalotTypesSelectors,
      selectStratalotTypesEntitiesDictionary,
      getRelationSelectors,
      stratalotTypeRelations,
      hydrateAll,
      'stratalotType'
    );
  } else {
    return selectAllStratalotTypesObject;
  }
}

export function selectAllStratalotTypesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'stratalotTypes'
): Selector {
  return createSelector(selectAllStratalotTypes(schema), result => {
    const res = { [customKey]: {} as Dictionary<StratalotTypeEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.stratalotTypes.length; i++) {
      res[customKey][result.stratalotTypes[i].idStratalotType] = result.stratalotTypes[i];
    }
    return res;
  });
}

export function selectOneStratalotType(schema: SelectSchema = {}, idStratalotType: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneStratalotTypeDictionary(idStratalotType)];
    selectors.push(...getRelationSelectors(schema, stratalotTypeRelations, 'stratalotType'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneStratalotTypeDictionaryWithoutChild(idStratalotType);
  }
}

export function selectActiveStratalotTypes(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveStratalotTypesEntities, stratalotTypes => ({ stratalotTypes }))
  ];
  selectors.push(...getRelationSelectors(schema, stratalotTypeRelations, 'stratalotType'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  stratalotTypes: Dictionary<StratalotTypeEntityState>;
  companyStratalotTypes?: Dictionary<CompanyStratalotTypeEntityState>;
  prospectBuyingWishs?: Dictionary<ProspectBuyingWishEntityState>;
  organizationLeadTodoRules?: Dictionary<OrganizationLeadTodoRuleEntityState>;
  organizationStratalotTodoRules?: Dictionary<OrganizationStratalotTodoRuleEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { stratalotTypes: (StratalotType | null)[] } {
  const {
    stratalotTypes,
    companyStratalotTypes,
    prospectBuyingWishs,
    organizationLeadTodoRules,
    organizationStratalotTodoRules
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    stratalotTypes: Object.keys(stratalotTypes).map(idStratalotType =>
      hydrate(
        stratalotTypes[idStratalotType] as StratalotTypeEntityState,
        companyStratalotTypes,
        prospectBuyingWishs,
        organizationLeadTodoRules,
        organizationStratalotTodoRules
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { stratalotType: StratalotTypeEntityState | null } {
  const {
    stratalotTypes,
    companyStratalotTypes,
    prospectBuyingWishs,
    organizationLeadTodoRules,
    organizationStratalotTodoRules
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const stratalotType = Object.values(stratalotTypes)[0];
  return {
    stratalotType: hydrate(
      stratalotType as StratalotTypeEntityState,
      companyStratalotTypes,
      prospectBuyingWishs,
      organizationLeadTodoRules,
      organizationStratalotTodoRules
    )
  };
}

function hydrate(
  stratalotType: StratalotTypeEntityState,
  companyStratalotTypeEntities?: Dictionary<CompanyStratalotTypeEntityState>,
  prospectBuyingWishEntities?: Dictionary<ProspectBuyingWishEntityState>,
  organizationLeadTodoRuleEntities?: Dictionary<OrganizationLeadTodoRuleEntityState>,
  organizationStratalotTodoRuleEntities?: Dictionary<OrganizationStratalotTodoRuleEntityState>
): StratalotType | null {
  if (!stratalotType) {
    return null;
  }

  const stratalotTypeHydrated: StratalotTypeEntityState = { ...stratalotType };

  if (companyStratalotTypeEntities) {
    stratalotTypeHydrated.companyStratalotTypes = ((stratalotTypeHydrated.companyStratalotTypes as number[]) || []).map(
      id => companyStratalotTypeEntities[id]
    ) as CompanyStratalotType[];
  } else {
    delete stratalotTypeHydrated.companyStratalotTypes;
  }

  if (prospectBuyingWishEntities) {
    stratalotTypeHydrated.prospectBuyingWishs = ((stratalotTypeHydrated.prospectBuyingWishs as number[]) || []).map(
      id => prospectBuyingWishEntities[id]
    ) as ProspectBuyingWish[];
  } else {
    delete stratalotTypeHydrated.prospectBuyingWishs;
  }

  if (organizationLeadTodoRuleEntities) {
    stratalotTypeHydrated.organizationLeadTodoRules = (
      (stratalotTypeHydrated.organizationLeadTodoRules as number[]) || []
    ).map(id => organizationLeadTodoRuleEntities[id]) as OrganizationLeadTodoRule[];
  } else {
    delete stratalotTypeHydrated.organizationLeadTodoRules;
  }

  if (organizationStratalotTodoRuleEntities) {
    stratalotTypeHydrated.organizationStratalotTodoRules = (
      (stratalotTypeHydrated.organizationStratalotTodoRules as number[]) || []
    ).map(id => organizationStratalotTodoRuleEntities[id]) as OrganizationStratalotTodoRule[];
  } else {
    delete stratalotTypeHydrated.organizationStratalotTodoRules;
  }

  return stratalotTypeHydrated as StratalotType;
}
