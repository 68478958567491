import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { OrganizationProfil, OrganizationProfilEntityState } from '@_model/interfaces/organization-profil.model';
import { OrganizationProfilApiService } from '@_services/api/organization-profil-api.service';
import * as organizationProfilActions from '@_store/organization-profil/organization-profil.actions';
import { getActionsToNormalizeOrganizationProfil } from '@_config/store/normalization.generated';
import { selectOrganizationProfilState } from './organization-profil-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as companyPriceLabelActions from '@_store/company-price-label/company-price-label.actions';

export interface OrganizationProfilRelationsIds {
  organization?: number;
  companyPriceLabel?: number;
}

export function getDefaultAddOrganizationProfilActions(
  organizationProfil: OrganizationProfilEntityState,
  ids?: OrganizationProfilRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationProfilActions.normalizeManyOrganizationProfilsAfterUpsert({ organizationProfils: [organizationProfil] })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationProfilSuccess({
        idOrganization: ids.organization,
        idOrganizationProfils: [organizationProfil.idOrganizationProfil]
      })
    );
    actions.push(
      organizationProfilActions.addOrganizationSuccess({
        idOrganizationProfil: organizationProfil.idOrganizationProfil,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.companyPriceLabel) {
    actions.push(
      companyPriceLabelActions.addManyOrganizationProfilSuccess({
        idCompanyPriceLabel: ids.companyPriceLabel,
        idOrganizationProfils: [organizationProfil.idOrganizationProfil]
      })
    );
    actions.push(
      organizationProfilActions.addCompanyPriceLabelSuccess({
        idOrganizationProfil: organizationProfil.idOrganizationProfil,
        idCompanyPriceLabel: ids.companyPriceLabel
      })
    );
  }

  return actions;
}

export function getDefaultDeleteOrganizationProfilActions(organizationProfil: OrganizationProfilEntityState): Action[] {
  const actions: Action[] = [
    organizationProfilActions.deleteOneOrganizationProfilSuccess({
      idOrganizationProfil: organizationProfil.idOrganizationProfil
    })
  ];

  if (organizationProfil.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationProfilSuccess({
        idOrganizationProfils: [organizationProfil.idOrganizationProfil],
        idOrganizations: [organizationProfil.organization as number]
      })
    );
  }

  if (organizationProfil.companyPriceLabel) {
    actions.push(
      companyPriceLabelActions.deleteManyOrganizationProfilSuccess({
        idOrganizationProfils: [organizationProfil.idOrganizationProfil],
        idCompanyPriceLabels: [organizationProfil.companyPriceLabel as number]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationProfilEffects {
  constructor(
    protected actions$: Actions,
    protected organizationProfilApiService: OrganizationProfilApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationProfils$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationProfilActions.getManyOrganizationProfils),
      switchMap(({ params }) =>
        this.organizationProfilApiService.getOrganizationProfils(params).pipe(
          map((organizationProfils: OrganizationProfil[]) => {
            return organizationProfilActions.normalizeManyOrganizationProfilsAfterUpsert({ organizationProfils });
          }),
          catchError(error => of(organizationProfilActions.organizationProfilsFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationProfilActions.getOneOrganizationProfil),
      switchMap(idOrganizationProfil =>
        this.organizationProfilApiService.getOrganizationProfil(idOrganizationProfil).pipe(
          map((organizationProfil: OrganizationProfil) => {
            return organizationProfilActions.normalizeManyOrganizationProfilsAfterUpsert({
              organizationProfils: [organizationProfil]
            });
          }),
          catchError(error => of(organizationProfilActions.organizationProfilsFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationProfilActions.upsertOneOrganizationProfil),
      concatMap(
        ({
          organizationProfil,
          ids
        }: {
          organizationProfil: Partial<OrganizationProfil>;
          ids?: OrganizationProfilRelationsIds;
        }) => {
          if (organizationProfil.idOrganizationProfil) {
            return this.organizationProfilApiService.updateOrganizationProfil(organizationProfil).pipe(
              map((organizationProfilReturned: OrganizationProfil) => {
                return organizationProfilActions.normalizeManyOrganizationProfilsAfterUpsert({
                  organizationProfils: [organizationProfilReturned]
                });
              }),
              catchError(error => of(organizationProfilActions.organizationProfilsFailure({ error })))
            );
          } else {
            return this.organizationProfilApiService.addOrganizationProfil(organizationProfil).pipe(
              mergeMap((organizationProfilReturned: OrganizationProfil) =>
                getDefaultAddOrganizationProfilActions(organizationProfilReturned, ids)
              ),
              catchError(error => of(organizationProfilActions.organizationProfilsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationProfil$ = createEffect(() => {
    const selectOrganizationProfilState$ = this.store$.select(selectOrganizationProfilState);
    return this.actions$.pipe(
      ofType(organizationProfilActions.deleteOneOrganizationProfil),
      withLatestFrom(selectOrganizationProfilState$),
      concatMap(([{ idOrganizationProfil }, state]) =>
        this.organizationProfilApiService.deleteOrganizationProfil(idOrganizationProfil).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOrganizationProfilActions(
                state.entities[idOrganizationProfil] as OrganizationProfilEntityState
              ),
              organizationProfilActions.deleteOneOrganizationProfil.type
            )
          ]),
          catchError(error => of(organizationProfilActions.organizationProfilsFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationProfilsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationProfilActions.normalizeManyOrganizationProfilsAfterUpsert),
      concatMap(({ organizationProfils }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationProfil(organizationProfils, StoreActionType.upsert);
        return [getMultiAction(actions, '[OrganizationProfil] Normalization After Upsert Success')];
      })
    );
  });
}
