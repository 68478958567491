import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StratalotAssociationEntityState } from '@_model/interfaces/stratalot-association.model';

export interface StratalotAssociationState extends EntityState<StratalotAssociationEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<StratalotAssociationEntityState> =
  createEntityAdapter<StratalotAssociationEntityState>({
    selectId: o => o.idStratalotAssociation
  });
export const initialState: StratalotAssociationState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const stratalotAssociationFeatureKey = 'stratalotAssociation';
