import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as LeadTodoActions from './lead-todo.actions';
import { adapter, initialState, LeadTodoState } from './lead-todo.state';
import { Dictionary } from '@ngrx/entity';
import { LeadTodoEntityState } from '@_model/interfaces/lead-todo.model';

export const leadTodoReducersGeneratedFunctions: ReducerTypes<LeadTodoState, readonly ActionCreator[]>[] = [
  on(LeadTodoActions.getOneLeadTodo, (state: LeadTodoState) => setLoadingsState(state, true)),
  on(LeadTodoActions.getManyLeadTodos, (state: LeadTodoState) => setLoadingsState(state, true)),
  on(LeadTodoActions.upsertOneLeadTodo, (state: LeadTodoState) => setLoadingsState(state, true)),

  on(LeadTodoActions.upsertManyLeadTodosSuccess, (state: LeadTodoState, { leadTodos }) =>
    adapter.upsertMany(leadTodos, setLoadingsState(state, false))
  ),
  on(LeadTodoActions.deleteOneLeadTodo, (state: LeadTodoState) => setLoadingsState(state, true)),
  on(LeadTodoActions.deleteOneLeadTodoSuccess, (state: LeadTodoState, { idLeadTodo }) =>
    adapter.removeOne(idLeadTodo, setLoadingsState(state, false))
  ),
  on(LeadTodoActions.clearActive, (state: LeadTodoState) => ({ ...state, actives: [] })),
  on(LeadTodoActions.addManyActives, (state: LeadTodoState, { idLeadTodos }) => ({
    ...state,
    actives: state.actives.concat(idLeadTodos)
  })),
  on(LeadTodoActions.deleteOneActive, (state: LeadTodoState, { idLeadTodo }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idLeadTodo)
  })),

  on(LeadTodoActions.clearStore, () => initialState),

  on(LeadTodoActions.addOrganizationLeadTodoSuccess, (state: LeadTodoState, { idLeadTodo, idOrganizationLeadTodo }) => {
    if (!state.entities[idLeadTodo]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idLeadTodo]: {
          ...state.entities[idLeadTodo],
          organizationLeadTodo: idOrganizationLeadTodo
        }
      }
    };
  }),

  on(
    LeadTodoActions.deleteManyOrganizationLeadTodoSuccess,
    (state: LeadTodoState, { idOrganizationLeadTodos, idLeadTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idLeadTodos.reduce((entities, idLeadTodo) => {
            if (!state.entities[idLeadTodo]?.organizationLeadTodo) {
              return entities;
            }
            entities[idLeadTodo] = {
              ...state.entities[idLeadTodo],
              organizationLeadTodo: idOrganizationLeadTodos.some(
                (idOrganizationLeadTodo: number) =>
                  idOrganizationLeadTodo === state.entities[idLeadTodo]?.organizationLeadTodo
              )
                ? undefined
                : state.entities[idLeadTodo]?.organizationLeadTodo
            } as LeadTodoEntityState;
            return entities;
          }, {} as Dictionary<LeadTodoEntityState>)
        }
      };
    }
  ),

  on(LeadTodoActions.addLeadSuccess, (state: LeadTodoState, { idLeadTodo, idLead }) => {
    if (!state.entities[idLeadTodo]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idLeadTodo]: {
          ...state.entities[idLeadTodo],
          lead: idLead
        }
      }
    };
  }),

  on(LeadTodoActions.deleteManyLeadSuccess, (state: LeadTodoState, { idLeads, idLeadTodos }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idLeadTodos.reduce((entities, idLeadTodo) => {
          if (!state.entities[idLeadTodo]?.lead) {
            return entities;
          }
          entities[idLeadTodo] = {
            ...state.entities[idLeadTodo],
            lead: idLeads.some((idLead: number) => idLead === state.entities[idLeadTodo]?.lead)
              ? undefined
              : state.entities[idLeadTodo]?.lead
          } as LeadTodoEntityState;
          return entities;
        }, {} as Dictionary<LeadTodoEntityState>)
      }
    };
  })
];

export function setLoadingsState(state: LeadTodoState, isLoading: boolean, isLoaded: boolean = true): LeadTodoState {
  return { ...state, isLoaded, isLoading };
}
