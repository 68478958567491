import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ProfilActions from './profil.actions';
import { adapter, initialState, ProfilState } from './profil.state';
import { Dictionary } from '@ngrx/entity';
import { ProfilEntityState } from '@_model/interfaces/profil.model';

export const profilReducersGeneratedFunctions: ReducerTypes<ProfilState, readonly ActionCreator[]>[] = [
  on(ProfilActions.getOneProfil, (state: ProfilState) => setLoadingsState(state, true)),
  on(ProfilActions.getManyProfils, (state: ProfilState) => setLoadingsState(state, true)),
  on(ProfilActions.upsertOneProfil, (state: ProfilState) => setLoadingsState(state, true)),

  on(ProfilActions.upsertManyProfilsSuccess, (state: ProfilState, { profils }) =>
    adapter.upsertMany(profils, setLoadingsState(state, false))
  ),
  on(ProfilActions.deleteOneProfil, (state: ProfilState) => setLoadingsState(state, true)),
  on(ProfilActions.deleteOneProfilSuccess, (state: ProfilState, { idProfil }) =>
    adapter.removeOne(idProfil, setLoadingsState(state, false))
  ),
  on(ProfilActions.clearActive, (state: ProfilState) => ({ ...state, actives: [] })),
  on(ProfilActions.addManyActives, (state: ProfilState, { idProfils }) => ({
    ...state,
    actives: state.actives.concat(idProfils)
  })),
  on(ProfilActions.deleteOneActive, (state: ProfilState, { idProfil }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idProfil)
  })),

  on(ProfilActions.clearStore, () => initialState),

  on(ProfilActions.addManyUserSuccess, (state: ProfilState, { idProfil, idUsers }) => {
    if (!state.entities[idProfil]) {
      return state;
    }
    const users = (state.entities[idProfil]?.users as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProfil]: {
          ...state.entities[idProfil],
          users: users.concat(idUsers.filter(id => users.indexOf(id) < 0))
        }
      }
    };
  }),

  on(ProfilActions.deleteManyUserSuccess, (state: ProfilState, { idUsers, idProfils }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idProfils.reduce((entities, idProfil) => {
          if (!state.entities[idProfil]?.users) {
            return entities;
          }
          entities[idProfil] = {
            ...state.entities[idProfil],
            users: (state.entities[idProfil]?.users as number[])?.filter(
              (idUser: number) => !idUsers.some((id: number) => id === idUser)
            )
          } as ProfilEntityState;
          return entities;
        }, {} as Dictionary<ProfilEntityState>)
      }
    };
  }),

  on(ProfilActions.addManyDroitSuccess, (state: ProfilState, { idProfil, idDroits }) => {
    if (!state.entities[idProfil]) {
      return state;
    }
    const droits = (state.entities[idProfil]?.droits as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProfil]: {
          ...state.entities[idProfil],
          droits: droits.concat(idDroits.filter(id => droits.indexOf(id) < 0))
        }
      }
    };
  }),

  on(ProfilActions.deleteManyDroitSuccess, (state: ProfilState, { idDroits, idProfils }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idProfils.reduce((entities, idProfil) => {
          if (!state.entities[idProfil]?.droits) {
            return entities;
          }
          entities[idProfil] = {
            ...state.entities[idProfil],
            droits: (state.entities[idProfil]?.droits as number[])?.filter(
              (idDroit: number) => !idDroits.some((id: number) => id === idDroit)
            )
          } as ProfilEntityState;
          return entities;
        }, {} as Dictionary<ProfilEntityState>)
      }
    };
  }),

  on(
    ProfilActions.addManyOrganizationLeadTodoProfilSuccess,
    (state: ProfilState, { idProfil, idOrganizationLeadTodoProfils }) => {
      if (!state.entities[idProfil]) {
        return state;
      }
      const organizationLeadTodoProfils = (state.entities[idProfil]?.organizationLeadTodoProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProfil]: {
            ...state.entities[idProfil],
            organizationLeadTodoProfils: organizationLeadTodoProfils.concat(
              idOrganizationLeadTodoProfils.filter(id => organizationLeadTodoProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProfilActions.deleteManyOrganizationLeadTodoProfilSuccess,
    (state: ProfilState, { idOrganizationLeadTodoProfils, idProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProfils.reduce((entities, idProfil) => {
            if (!state.entities[idProfil]?.organizationLeadTodoProfils) {
              return entities;
            }
            entities[idProfil] = {
              ...state.entities[idProfil],
              organizationLeadTodoProfils: (state.entities[idProfil]?.organizationLeadTodoProfils as number[])?.filter(
                (idOrganizationLeadTodoProfil: number) =>
                  !idOrganizationLeadTodoProfils.some((id: number) => id === idOrganizationLeadTodoProfil)
              )
            } as ProfilEntityState;
            return entities;
          }, {} as Dictionary<ProfilEntityState>)
        }
      };
    }
  ),

  on(
    ProfilActions.addManyOrganizationResidenceTodoProfilSuccess,
    (state: ProfilState, { idProfil, idOrganizationResidenceTodoProfils }) => {
      if (!state.entities[idProfil]) {
        return state;
      }
      const organizationResidenceTodoProfils =
        (state.entities[idProfil]?.organizationResidenceTodoProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProfil]: {
            ...state.entities[idProfil],
            organizationResidenceTodoProfils: organizationResidenceTodoProfils.concat(
              idOrganizationResidenceTodoProfils.filter(id => organizationResidenceTodoProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProfilActions.deleteManyOrganizationResidenceTodoProfilSuccess,
    (state: ProfilState, { idOrganizationResidenceTodoProfils, idProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProfils.reduce((entities, idProfil) => {
            if (!state.entities[idProfil]?.organizationResidenceTodoProfils) {
              return entities;
            }
            entities[idProfil] = {
              ...state.entities[idProfil],
              organizationResidenceTodoProfils: (
                state.entities[idProfil]?.organizationResidenceTodoProfils as number[]
              )?.filter(
                (idOrganizationResidenceTodoProfil: number) =>
                  !idOrganizationResidenceTodoProfils.some((id: number) => id === idOrganizationResidenceTodoProfil)
              )
            } as ProfilEntityState;
            return entities;
          }, {} as Dictionary<ProfilEntityState>)
        }
      };
    }
  ),

  on(
    ProfilActions.addManyOrganizationStratalotTodoProfilSuccess,
    (state: ProfilState, { idProfil, idOrganizationStratalotTodoProfils }) => {
      if (!state.entities[idProfil]) {
        return state;
      }
      const organizationStratalotTodoProfils =
        (state.entities[idProfil]?.organizationStratalotTodoProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idProfil]: {
            ...state.entities[idProfil],
            organizationStratalotTodoProfils: organizationStratalotTodoProfils.concat(
              idOrganizationStratalotTodoProfils.filter(id => organizationStratalotTodoProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ProfilActions.deleteManyOrganizationStratalotTodoProfilSuccess,
    (state: ProfilState, { idOrganizationStratalotTodoProfils, idProfils }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idProfils.reduce((entities, idProfil) => {
            if (!state.entities[idProfil]?.organizationStratalotTodoProfils) {
              return entities;
            }
            entities[idProfil] = {
              ...state.entities[idProfil],
              organizationStratalotTodoProfils: (
                state.entities[idProfil]?.organizationStratalotTodoProfils as number[]
              )?.filter(
                (idOrganizationStratalotTodoProfil: number) =>
                  !idOrganizationStratalotTodoProfils.some((id: number) => id === idOrganizationStratalotTodoProfil)
              )
            } as ProfilEntityState;
            return entities;
          }, {} as Dictionary<ProfilEntityState>)
        }
      };
    }
  ),

  on(ProfilActions.addOrganizationSuccess, (state: ProfilState, { idProfil, idOrganization }) => {
    if (!state.entities[idProfil]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProfil]: {
          ...state.entities[idProfil],
          organization: idOrganization
        }
      }
    };
  }),

  on(ProfilActions.deleteManyOrganizationSuccess, (state: ProfilState, { idOrganizations, idProfils }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idProfils.reduce((entities, idProfil) => {
          if (!state.entities[idProfil]?.organization) {
            return entities;
          }
          entities[idProfil] = {
            ...state.entities[idProfil],
            organization: idOrganizations.some(
              (idOrganization: number) => idOrganization === state.entities[idProfil]?.organization
            )
              ? undefined
              : state.entities[idProfil]?.organization
          } as ProfilEntityState;
          return entities;
        }, {} as Dictionary<ProfilEntityState>)
      }
    };
  })
];

export function setLoadingsState(state: ProfilState, isLoading: boolean, isLoaded: boolean = true): ProfilState {
  return { ...state, isLoaded, isLoading };
}
