import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationStratalotTodo,
  OrganizationStratalotTodoEntityState
} from '@_model/interfaces/organization-stratalot-todo.model';
import { OrganizationStratalotTodoApiService } from '@_services/api/organization-stratalot-todo-api.service';
import * as organizationStratalotTodoActions from '@_store/organization-stratalot-todo/organization-stratalot-todo.actions';
import { getActionsToNormalizeOrganizationStratalotTodo } from '@_config/store/normalization.generated';
import { selectOrganizationStratalotTodoState } from './organization-stratalot-todo-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as diagnosticActions from '@_store/diagnostic/diagnostic.actions';
import * as stratalotTodoActions from '@_store/stratalot-todo/stratalot-todo.actions';
import { StratalotTodo } from '@_model/interfaces/stratalot-todo.model';
import * as organizationStratalotTodoRuleActions from '@_store/organization-stratalot-todo-rule/organization-stratalot-todo-rule.actions';
import { OrganizationStratalotTodoRule } from '@_model/interfaces/organization-stratalot-todo-rule.model';
import * as organizationStratalotTodoAppliedRuleActions from '@_store/organization-stratalot-todo-applied-rule/organization-stratalot-todo-applied-rule.actions';
import { OrganizationStratalotTodoAppliedRule } from '@_model/interfaces/organization-stratalot-todo-applied-rule.model';
import * as organizationStratalotTodoProfilActions from '@_store/organization-stratalot-todo-profil/organization-stratalot-todo-profil.actions';
import { OrganizationStratalotTodoProfil } from '@_model/interfaces/organization-stratalot-todo-profil.model';
import * as companyCommunicationActions from '@_store/company-communication/company-communication.actions';
import { CompanyCommunication } from '@_model/interfaces/company-communication.model';
import * as companyCommunicationStratalotTodoActions from '@_store/company-communication-stratalot-todo/company-communication-stratalot-todo.actions';
import { CompanyCommunicationStratalotTodo } from '@_model/interfaces/company-communication-stratalot-todo.model';

export interface OrganizationStratalotTodoRelationsIds {
  stratalotTodos?: number | number[];
  organizationStratalotTodoRules?: number | number[];
  organizationStratalotTodoAppliedRules?: number | number[];
  organizationStratalotTodoProfils?: number | number[];
  companyCommunications?: number | number[];
  companyCommunicationStratalotTodos?: number | number[];
  organization?: number;
  diagnostic?: number;
}

export function getDefaultAddOrganizationStratalotTodoActions(
  organizationStratalotTodo: OrganizationStratalotTodoEntityState,
  ids?: OrganizationStratalotTodoRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationStratalotTodoActions.normalizeManyOrganizationStratalotTodosAfterUpsert({
      organizationStratalotTodos: [organizationStratalotTodo]
    })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationStratalotTodoSuccess({
        idOrganization: ids.organization,
        idOrganizationStratalotTodos: [organizationStratalotTodo.idOrganizationStratalotTodo]
      })
    );
    actions.push(
      organizationStratalotTodoActions.addOrganizationSuccess({
        idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.diagnostic) {
    actions.push(
      diagnosticActions.addManyOrganizationStratalotTodoSuccess({
        idDiagnostic: ids.diagnostic,
        idOrganizationStratalotTodos: [organizationStratalotTodo.idOrganizationStratalotTodo]
      })
    );
    actions.push(
      organizationStratalotTodoActions.addDiagnosticSuccess({
        idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
        idDiagnostic: ids.diagnostic
      })
    );
  }

  if (ids?.stratalotTodos) {
    if (!Array.isArray(ids.stratalotTodos)) {
      actions.push(
        stratalotTodoActions.upsertOneStratalotTodo({
          stratalotTodo: {
            idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
            idStratalotTodo: ids.stratalotTodos as number
          } as StratalotTodo
        })
      );
      actions.push(
        organizationStratalotTodoActions.addManyStratalotTodoSuccess({
          idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
          idStratalotTodos: [ids.stratalotTodos as number]
        })
      );
    } else {
      actions.push(
        stratalotTodoActions.upsertManyStratalotTodos({
          stratalotTodos: (ids.stratalotTodos as number[]).map((idStratalotTodo: number) => ({
            idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
            idStratalotTodo
          })) as StratalotTodo[]
        })
      );
      actions.push(
        organizationStratalotTodoActions.addManyStratalotTodoSuccess({
          idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
          idStratalotTodos: ids.stratalotTodos as number[]
        })
      );
    }
  }

  if (ids?.organizationStratalotTodoRules) {
    if (!Array.isArray(ids.organizationStratalotTodoRules)) {
      actions.push(
        organizationStratalotTodoRuleActions.upsertOneOrganizationStratalotTodoRule({
          organizationStratalotTodoRule: {
            idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
            idOrganizationStratalotTodoRule: ids.organizationStratalotTodoRules as number
          } as OrganizationStratalotTodoRule & any
        })
      );
      actions.push(
        organizationStratalotTodoActions.addManyOrganizationStratalotTodoRuleSuccess({
          idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
          idOrganizationStratalotTodoRules: [ids.organizationStratalotTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotTodoRuleActions.upsertManyOrganizationStratalotTodoRules({
          organizationStratalotTodoRules: (ids.organizationStratalotTodoRules as number[]).map(
            (idOrganizationStratalotTodoRule: number) => ({
              idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
              idOrganizationStratalotTodoRule
            })
          ) as OrganizationStratalotTodoRule[] & any[]
        })
      );
      actions.push(
        organizationStratalotTodoActions.addManyOrganizationStratalotTodoRuleSuccess({
          idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
          idOrganizationStratalotTodoRules: ids.organizationStratalotTodoRules as number[]
        })
      );
    }
  }

  if (ids?.organizationStratalotTodoAppliedRules) {
    if (!Array.isArray(ids.organizationStratalotTodoAppliedRules)) {
      actions.push(
        organizationStratalotTodoAppliedRuleActions.upsertOneOrganizationStratalotTodoAppliedRule({
          organizationStratalotTodoAppliedRule: {
            idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
            idOrganizationStratalotTodoAppliedRule: ids.organizationStratalotTodoAppliedRules as number
          } as OrganizationStratalotTodoAppliedRule
        })
      );
      actions.push(
        organizationStratalotTodoActions.addManyOrganizationStratalotTodoAppliedRuleSuccess({
          idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
          idOrganizationStratalotTodoAppliedRules: [ids.organizationStratalotTodoAppliedRules as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotTodoAppliedRuleActions.upsertManyOrganizationStratalotTodoAppliedRules({
          organizationStratalotTodoAppliedRules: (ids.organizationStratalotTodoAppliedRules as number[]).map(
            (idOrganizationStratalotTodoAppliedRule: number) => ({
              idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
              idOrganizationStratalotTodoAppliedRule
            })
          ) as OrganizationStratalotTodoAppliedRule[]
        })
      );
      actions.push(
        organizationStratalotTodoActions.addManyOrganizationStratalotTodoAppliedRuleSuccess({
          idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
          idOrganizationStratalotTodoAppliedRules: ids.organizationStratalotTodoAppliedRules as number[]
        })
      );
    }
  }

  if (ids?.organizationStratalotTodoProfils) {
    if (!Array.isArray(ids.organizationStratalotTodoProfils)) {
      actions.push(
        organizationStratalotTodoProfilActions.upsertOneOrganizationStratalotTodoProfil({
          organizationStratalotTodoProfil: {
            idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
            idOrganizationStratalotTodoProfil: ids.organizationStratalotTodoProfils as number
          } as OrganizationStratalotTodoProfil
        })
      );
      actions.push(
        organizationStratalotTodoActions.addManyOrganizationStratalotTodoProfilSuccess({
          idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
          idOrganizationStratalotTodoProfils: [ids.organizationStratalotTodoProfils as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotTodoProfilActions.upsertManyOrganizationStratalotTodoProfils({
          organizationStratalotTodoProfils: (ids.organizationStratalotTodoProfils as number[]).map(
            (idOrganizationStratalotTodoProfil: number) => ({
              idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
              idOrganizationStratalotTodoProfil
            })
          ) as OrganizationStratalotTodoProfil[]
        })
      );
      actions.push(
        organizationStratalotTodoActions.addManyOrganizationStratalotTodoProfilSuccess({
          idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
          idOrganizationStratalotTodoProfils: ids.organizationStratalotTodoProfils as number[]
        })
      );
    }
  }

  if (ids?.companyCommunications) {
    if (!Array.isArray(ids.companyCommunications)) {
      actions.push(
        companyCommunicationActions.upsertOneCompanyCommunication({
          companyCommunication: {
            idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
            idCompanyCommunication: ids.companyCommunications as number
          } as CompanyCommunication & any
        })
      );
      actions.push(
        organizationStratalotTodoActions.addManyCompanyCommunicationSuccess({
          idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
          idCompanyCommunications: [ids.companyCommunications as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationActions.upsertManyCompanyCommunications({
          companyCommunications: (ids.companyCommunications as number[]).map((idCompanyCommunication: number) => ({
            idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
            idCompanyCommunication
          })) as CompanyCommunication[] & any[]
        })
      );
      actions.push(
        organizationStratalotTodoActions.addManyCompanyCommunicationSuccess({
          idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
          idCompanyCommunications: ids.companyCommunications as number[]
        })
      );
    }
  }

  if (ids?.companyCommunicationStratalotTodos) {
    if (!Array.isArray(ids.companyCommunicationStratalotTodos)) {
      actions.push(
        companyCommunicationStratalotTodoActions.upsertOneCompanyCommunicationStratalotTodo({
          companyCommunicationStratalotTodo: {
            idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
            idCompanyCommunicationStratalotTodo: ids.companyCommunicationStratalotTodos as number
          } as CompanyCommunicationStratalotTodo
        })
      );
      actions.push(
        organizationStratalotTodoActions.addManyCompanyCommunicationStratalotTodoSuccess({
          idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
          idCompanyCommunicationStratalotTodos: [ids.companyCommunicationStratalotTodos as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationStratalotTodoActions.upsertManyCompanyCommunicationStratalotTodos({
          companyCommunicationStratalotTodos: (ids.companyCommunicationStratalotTodos as number[]).map(
            (idCompanyCommunicationStratalotTodo: number) => ({
              idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
              idCompanyCommunicationStratalotTodo
            })
          ) as CompanyCommunicationStratalotTodo[]
        })
      );
      actions.push(
        organizationStratalotTodoActions.addManyCompanyCommunicationStratalotTodoSuccess({
          idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo,
          idCompanyCommunicationStratalotTodos: ids.companyCommunicationStratalotTodos as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationStratalotTodoActions(
  organizationStratalotTodo: OrganizationStratalotTodoEntityState
): Action[] {
  const actions: Action[] = [
    organizationStratalotTodoActions.deleteOneOrganizationStratalotTodoSuccess({
      idOrganizationStratalotTodo: organizationStratalotTodo.idOrganizationStratalotTodo
    })
  ];

  if (organizationStratalotTodo.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationStratalotTodoSuccess({
        idOrganizationStratalotTodos: [organizationStratalotTodo.idOrganizationStratalotTodo],
        idOrganizations: [organizationStratalotTodo.organization as number]
      })
    );
  }

  if (organizationStratalotTodo.diagnostic) {
    actions.push(
      diagnosticActions.deleteManyOrganizationStratalotTodoSuccess({
        idOrganizationStratalotTodos: [organizationStratalotTodo.idOrganizationStratalotTodo],
        idDiagnostics: [organizationStratalotTodo.diagnostic as number]
      })
    );
  }

  if (organizationStratalotTodo.stratalotTodos) {
    actions.push(
      stratalotTodoActions.deleteManyOrganizationStratalotTodoSuccess({
        idOrganizationStratalotTodos: [organizationStratalotTodo.idOrganizationStratalotTodo],
        idStratalotTodos: organizationStratalotTodo.stratalotTodos as number[]
      })
    );
  }

  if (organizationStratalotTodo.organizationStratalotTodoRules) {
    actions.push(
      organizationStratalotTodoRuleActions.deleteManyOrganizationStratalotTodoSuccess({
        idOrganizationStratalotTodos: [organizationStratalotTodo.idOrganizationStratalotTodo],
        idOrganizationStratalotTodoRules: organizationStratalotTodo.organizationStratalotTodoRules as number[]
      })
    );
  }

  if (organizationStratalotTodo.organizationStratalotTodoAppliedRules) {
    actions.push(
      organizationStratalotTodoAppliedRuleActions.deleteManyOrganizationStratalotTodoSuccess({
        idOrganizationStratalotTodos: [organizationStratalotTodo.idOrganizationStratalotTodo],
        idOrganizationStratalotTodoAppliedRules:
          organizationStratalotTodo.organizationStratalotTodoAppliedRules as number[]
      })
    );
  }

  if (organizationStratalotTodo.organizationStratalotTodoProfils) {
    actions.push(
      organizationStratalotTodoProfilActions.deleteManyOrganizationStratalotTodoSuccess({
        idOrganizationStratalotTodos: [organizationStratalotTodo.idOrganizationStratalotTodo],
        idOrganizationStratalotTodoProfils: organizationStratalotTodo.organizationStratalotTodoProfils as number[]
      })
    );
  }

  if (organizationStratalotTodo.companyCommunications) {
    actions.push(
      companyCommunicationActions.deleteManyOrganizationStratalotTodoSuccess({
        idOrganizationStratalotTodos: [organizationStratalotTodo.idOrganizationStratalotTodo],
        idCompanyCommunications: organizationStratalotTodo.companyCommunications as number[]
      })
    );
  }

  if (organizationStratalotTodo.companyCommunicationStratalotTodos) {
    actions.push(
      companyCommunicationStratalotTodoActions.deleteManyOrganizationStratalotTodoSuccess({
        idOrganizationStratalotTodos: [organizationStratalotTodo.idOrganizationStratalotTodo],
        idCompanyCommunicationStratalotTodos: organizationStratalotTodo.companyCommunicationStratalotTodos as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationStratalotTodoEffects {
  constructor(
    protected actions$: Actions,
    protected organizationStratalotTodoApiService: OrganizationStratalotTodoApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationStratalotTodos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotTodoActions.getManyOrganizationStratalotTodos),
      switchMap(({ params }) =>
        this.organizationStratalotTodoApiService.getOrganizationStratalotTodos(params).pipe(
          map((organizationStratalotTodos: OrganizationStratalotTodo[]) => {
            return organizationStratalotTodoActions.normalizeManyOrganizationStratalotTodosAfterUpsert({
              organizationStratalotTodos
            });
          }),
          catchError(error => of(organizationStratalotTodoActions.organizationStratalotTodosFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationStratalotTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotTodoActions.getOneOrganizationStratalotTodo),
      switchMap(idOrganizationStratalotTodo =>
        this.organizationStratalotTodoApiService.getOrganizationStratalotTodo(idOrganizationStratalotTodo).pipe(
          map((organizationStratalotTodo: OrganizationStratalotTodo) => {
            return organizationStratalotTodoActions.normalizeManyOrganizationStratalotTodosAfterUpsert({
              organizationStratalotTodos: [organizationStratalotTodo]
            });
          }),
          catchError(error => of(organizationStratalotTodoActions.organizationStratalotTodosFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationStratalotTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotTodoActions.upsertOneOrganizationStratalotTodo),
      concatMap(
        ({
          organizationStratalotTodo,
          ids
        }: {
          organizationStratalotTodo: Partial<OrganizationStratalotTodo>;
          ids?: OrganizationStratalotTodoRelationsIds;
        }) => {
          if (organizationStratalotTodo.idOrganizationStratalotTodo) {
            return this.organizationStratalotTodoApiService
              .updateOrganizationStratalotTodo(organizationStratalotTodo)
              .pipe(
                map((organizationStratalotTodoReturned: OrganizationStratalotTodo) => {
                  return organizationStratalotTodoActions.normalizeManyOrganizationStratalotTodosAfterUpsert({
                    organizationStratalotTodos: [organizationStratalotTodoReturned]
                  });
                }),
                catchError(error => of(organizationStratalotTodoActions.organizationStratalotTodosFailure({ error })))
              );
          } else {
            return this.organizationStratalotTodoApiService
              .addOrganizationStratalotTodo(organizationStratalotTodo)
              .pipe(
                mergeMap((organizationStratalotTodoReturned: OrganizationStratalotTodo) =>
                  getDefaultAddOrganizationStratalotTodoActions(organizationStratalotTodoReturned, ids)
                ),
                catchError(error => of(organizationStratalotTodoActions.organizationStratalotTodosFailure({ error })))
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationStratalotTodo$ = createEffect(() => {
    const selectOrganizationStratalotTodoState$ = this.store$.select(selectOrganizationStratalotTodoState);
    return this.actions$.pipe(
      ofType(organizationStratalotTodoActions.deleteOneOrganizationStratalotTodo),
      withLatestFrom(selectOrganizationStratalotTodoState$),
      concatMap(([{ idOrganizationStratalotTodo }, state]) =>
        this.organizationStratalotTodoApiService.deleteOrganizationStratalotTodo(idOrganizationStratalotTodo).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOrganizationStratalotTodoActions(
                state.entities[idOrganizationStratalotTodo] as OrganizationStratalotTodoEntityState
              ),
              organizationStratalotTodoActions.deleteOneOrganizationStratalotTodo.type
            )
          ]),
          catchError(error => of(organizationStratalotTodoActions.organizationStratalotTodosFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationStratalotTodosAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotTodoActions.normalizeManyOrganizationStratalotTodosAfterUpsert),
      concatMap(({ organizationStratalotTodos }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationStratalotTodo(
          organizationStratalotTodos,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationStratalotTodo] Normalization After Upsert Success')];
      })
    );
  });
}
