import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StepProgressFamily, StepProgressFamilyEntityState } from '@_model/interfaces/step-progress-family.model';
import { StepProgress, StepProgressEntityState } from '@_model/interfaces/step-progress.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, stepProgressFamilyFeatureKey, StepProgressFamilyState } from './step-progress-family.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const stepProgressFamilyRelations: string[] = ['stepProgresses'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectStepProgressFamilyState =
  createFeatureSelector<StepProgressFamilyState>(stepProgressFamilyFeatureKey);

export const selectIsLoadedStepProgressFamily = createSelector(
  selectStepProgressFamilyState,
  (state: StepProgressFamilyState) => state.isLoaded
);

export const selectIsLoadingStepProgressFamily = createSelector(
  selectStepProgressFamilyState,
  (state: StepProgressFamilyState) => state.isLoading
);

export const selectIsReadyStepProgressFamily = createSelector(
  selectStepProgressFamilyState,
  (state: StepProgressFamilyState) => !state.isLoading
);

export const selectIsReadyAndLoadedStepProgressFamily = createSelector(
  selectStepProgressFamilyState,
  (state: StepProgressFamilyState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const StepProgressFamilyModel: SelectorModel = {
  name: 'stepProgressesFamilies',
  getSelector: selectAllStepProgressesFamiliesDictionary,
  isReady: selectIsReadyStepProgressFamily
};

export const selectStepProgressesFamiliesEntities = createSelector(selectStepProgressFamilyState, selectEntities);

export const selectStepProgressesFamiliesArray = createSelector(selectStepProgressFamilyState, selectAll);

export const selectIdStepProgressesFamiliesActive = createSelector(
  selectStepProgressFamilyState,
  (state: StepProgressFamilyState) => state.actives
);

const stepProgressesFamiliesInObject = (stepProgressesFamilies: Dictionary<StepProgressFamilyEntityState>) => ({
  stepProgressesFamilies
});

const selectStepProgressesFamiliesEntitiesDictionary = createSelector(
  selectStepProgressesFamiliesEntities,
  stepProgressesFamiliesInObject
);

const selectAllStepProgressesFamiliesObject = createSelector(
  selectStepProgressesFamiliesEntities,
  stepProgressesFamilies => {
    return hydrateAll({ stepProgressesFamilies });
  }
);

const selectOneStepProgressFamilyDictionary = (idStepProgressFamily: number) =>
  createSelector(selectStepProgressesFamiliesEntities, stepProgressesFamilies => {
    return { stepProgressesFamilies: { [idStepProgressFamily]: stepProgressesFamilies[idStepProgressFamily] } };
  });

const selectOneStepProgressFamilyDictionaryWithoutChild = (idStepProgressFamily: number) =>
  createSelector(selectStepProgressesFamiliesEntities, stepProgressesFamilies => {
    return { stepProgressFamily: stepProgressesFamilies[idStepProgressFamily] };
  });

const selectActiveStepProgressesFamiliesEntities = createSelector(
  selectIdStepProgressesFamiliesActive,
  selectStepProgressesFamiliesEntities,
  (actives: number[], stepProgressesFamilies: Dictionary<StepProgressFamilyEntityState>) =>
    getStepProgressesFamiliesFromActives(actives, stepProgressesFamilies)
);

function getStepProgressesFamiliesFromActives(
  actives: number[],
  stepProgressesFamilies: Dictionary<StepProgressFamilyEntityState>
): Dictionary<StepProgressFamilyEntityState> {
  return actives.reduce((acc, idActive) => {
    if (stepProgressesFamilies[idActive]) {
      acc[idActive] = stepProgressesFamilies[idActive];
    }
    return acc;
  }, {} as Dictionary<StepProgressFamilyEntityState>);
}

const selectAllStepProgressesFamiliesSelectors: Dictionary<Selector> = {};
export function selectAllStepProgressesFamilies(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<StepProgressFamily>(
      schema,
      selectAllStepProgressesFamiliesSelectors,
      selectStepProgressesFamiliesEntitiesDictionary,
      getRelationSelectors,
      stepProgressFamilyRelations,
      hydrateAll,
      'stepProgressFamily'
    );
  } else {
    return selectAllStepProgressesFamiliesObject;
  }
}

export function selectAllStepProgressesFamiliesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'stepProgressesFamilies'
): Selector {
  return createSelector(selectAllStepProgressesFamilies(schema), result => {
    const res = { [customKey]: {} as Dictionary<StepProgressFamilyEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.stepProgressesFamilies.length; i++) {
      res[customKey][result.stepProgressesFamilies[i].idStepProgressFamily] = result.stepProgressesFamilies[i];
    }
    return res;
  });
}

export function selectOneStepProgressFamily(schema: SelectSchema = {}, idStepProgressFamily: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneStepProgressFamilyDictionary(idStepProgressFamily)];
    selectors.push(...getRelationSelectors(schema, stepProgressFamilyRelations, 'stepProgressFamily'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneStepProgressFamilyDictionaryWithoutChild(idStepProgressFamily);
  }
}

export function selectActiveStepProgressesFamilies(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveStepProgressesFamiliesEntities, stepProgressesFamilies => ({ stepProgressesFamilies }))
  ];
  selectors.push(...getRelationSelectors(schema, stepProgressFamilyRelations, 'stepProgressFamily'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  stepProgressesFamilies: Dictionary<StepProgressFamilyEntityState>;
  stepProgresses?: Dictionary<StepProgressEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { stepProgressesFamilies: (StepProgressFamily | null)[] } {
  const { stepProgressesFamilies, stepProgresses } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    stepProgressesFamilies: Object.keys(stepProgressesFamilies).map(idStepProgressFamily =>
      hydrate(stepProgressesFamilies[idStepProgressFamily] as StepProgressFamilyEntityState, stepProgresses)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { stepProgressFamily: StepProgressFamilyEntityState | null } {
  const { stepProgressesFamilies, stepProgresses } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const stepProgressFamily = Object.values(stepProgressesFamilies)[0];
  return { stepProgressFamily: hydrate(stepProgressFamily as StepProgressFamilyEntityState, stepProgresses) };
}

function hydrate(
  stepProgressFamily: StepProgressFamilyEntityState,
  stepProgressEntities?: Dictionary<StepProgressEntityState>
): StepProgressFamily | null {
  if (!stepProgressFamily) {
    return null;
  }

  const stepProgressFamilyHydrated: StepProgressFamilyEntityState = { ...stepProgressFamily };

  if (stepProgressEntities) {
    stepProgressFamilyHydrated.stepProgresses = ((stepProgressFamilyHydrated.stepProgresses as number[]) || []).map(
      id => stepProgressEntities[id]
    ) as StepProgress[];
  } else {
    delete stepProgressFamilyHydrated.stepProgresses;
  }

  return stepProgressFamilyHydrated as StepProgressFamily;
}
