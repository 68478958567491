import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { CompanyUbiflow, CompanyUbiflowEntityState } from '@_model/interfaces/company-ubiflow.model';
import { CompanyUbiflowApiService } from '@_services/api/company-ubiflow-api.service';
import * as companyUbiflowActions from '@_store/company-ubiflow/company-ubiflow.actions';
import { getActionsToNormalizeCompanyUbiflow } from '@_config/store/normalization.generated';
import { selectCompanyUbiflowState } from './company-ubiflow-generated.selectors';
import * as companyActions from '@_store/company/company.actions';

export interface CompanyUbiflowRelationsIds {
  company?: number;
}

export function getDefaultAddCompanyUbiflowActions(
  companyUbiflow: CompanyUbiflowEntityState,
  ids?: CompanyUbiflowRelationsIds
): Action[] {
  const actions: Action[] = [
    companyUbiflowActions.normalizeManyCompanyUbiflowsAfterUpsert({ companyUbiflows: [companyUbiflow] })
  ];

  if (ids?.company) {
    actions.push(
      companyActions.addManyCompanyUbiflowSuccess({
        idCompany: ids.company,
        idCompanyUbiflows: [companyUbiflow.idCompanyUbiflow]
      })
    );
    actions.push(
      companyUbiflowActions.addCompanySuccess({
        idCompanyUbiflow: companyUbiflow.idCompanyUbiflow,
        idCompany: ids.company
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCompanyUbiflowActions(companyUbiflow: CompanyUbiflowEntityState): Action[] {
  const actions: Action[] = [
    companyUbiflowActions.deleteOneCompanyUbiflowSuccess({ idCompanyUbiflow: companyUbiflow.idCompanyUbiflow })
  ];

  if (companyUbiflow.company) {
    actions.push(
      companyActions.deleteManyCompanyUbiflowSuccess({
        idCompanyUbiflows: [companyUbiflow.idCompanyUbiflow],
        idCompanies: [companyUbiflow.company as number]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyUbiflowEffects {
  constructor(
    protected actions$: Actions,
    protected companyUbiflowApiService: CompanyUbiflowApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyUbiflows$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyUbiflowActions.getManyCompanyUbiflows),
      switchMap(({ params }) =>
        this.companyUbiflowApiService.getCompanyUbiflows(params).pipe(
          map((companyUbiflows: CompanyUbiflow[]) => {
            return companyUbiflowActions.normalizeManyCompanyUbiflowsAfterUpsert({ companyUbiflows });
          }),
          catchError(error => of(companyUbiflowActions.companyUbiflowsFailure({ error })))
        )
      )
    );
  });

  getOneCompanyUbiflow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyUbiflowActions.getOneCompanyUbiflow),
      switchMap(idCompanyUbiflow =>
        this.companyUbiflowApiService.getCompanyUbiflow(idCompanyUbiflow).pipe(
          map((companyUbiflow: CompanyUbiflow) => {
            return companyUbiflowActions.normalizeManyCompanyUbiflowsAfterUpsert({ companyUbiflows: [companyUbiflow] });
          }),
          catchError(error => of(companyUbiflowActions.companyUbiflowsFailure({ error })))
        )
      )
    );
  });

  upsertOneCompanyUbiflow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyUbiflowActions.upsertOneCompanyUbiflow),
      concatMap(
        ({ companyUbiflow, ids }: { companyUbiflow: Partial<CompanyUbiflow>; ids?: CompanyUbiflowRelationsIds }) => {
          if (companyUbiflow.idCompanyUbiflow) {
            return this.companyUbiflowApiService.updateCompanyUbiflow(companyUbiflow).pipe(
              map((companyUbiflowReturned: CompanyUbiflow) => {
                return companyUbiflowActions.normalizeManyCompanyUbiflowsAfterUpsert({
                  companyUbiflows: [companyUbiflowReturned]
                });
              }),
              catchError(error => of(companyUbiflowActions.companyUbiflowsFailure({ error })))
            );
          } else {
            return this.companyUbiflowApiService.addCompanyUbiflow(companyUbiflow).pipe(
              mergeMap((companyUbiflowReturned: CompanyUbiflow) =>
                getDefaultAddCompanyUbiflowActions(companyUbiflowReturned, ids)
              ),
              catchError(error => of(companyUbiflowActions.companyUbiflowsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCompanyUbiflow$ = createEffect(() => {
    const selectCompanyUbiflowState$ = this.store$.select(selectCompanyUbiflowState);
    return this.actions$.pipe(
      ofType(companyUbiflowActions.deleteOneCompanyUbiflow),
      withLatestFrom(selectCompanyUbiflowState$),
      concatMap(([{ idCompanyUbiflow }, state]) =>
        this.companyUbiflowApiService.deleteCompanyUbiflow(idCompanyUbiflow).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCompanyUbiflowActions(state.entities[idCompanyUbiflow] as CompanyUbiflowEntityState),
              companyUbiflowActions.deleteOneCompanyUbiflow.type
            )
          ]),
          catchError(error => of(companyUbiflowActions.companyUbiflowsFailure({ error })))
        )
      )
    );
  });

  normalizeManyCompanyUbiflowsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyUbiflowActions.normalizeManyCompanyUbiflowsAfterUpsert),
      concatMap(({ companyUbiflows }) => {
        const actions: Action[] = getActionsToNormalizeCompanyUbiflow(companyUbiflows, StoreActionType.upsert);
        return [getMultiAction(actions, '[CompanyUbiflow] Normalization After Upsert Success')];
      })
    );
  });
}
