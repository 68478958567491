import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  GeneratedDocumentsResidence,
  GeneratedDocumentsResidenceEntityState
} from '@_model/interfaces/generated-documents-residence.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { GeneratedDocument, GeneratedDocumentEntityState } from '@_model/interfaces/generated-document.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  generatedDocumentsResidenceFeatureKey,
  GeneratedDocumentsResidenceState
} from './generated-documents-residence.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const generatedDocumentsResidenceRelations: string[] = ['residences', 'generatedDocuments'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectGeneratedDocumentsResidenceState = createFeatureSelector<GeneratedDocumentsResidenceState>(
  generatedDocumentsResidenceFeatureKey
);

export const selectIsLoadedGeneratedDocumentsResidence = createSelector(
  selectGeneratedDocumentsResidenceState,
  (state: GeneratedDocumentsResidenceState) => state.isLoaded
);

export const selectIsLoadingGeneratedDocumentsResidence = createSelector(
  selectGeneratedDocumentsResidenceState,
  (state: GeneratedDocumentsResidenceState) => state.isLoading
);

export const selectIsReadyGeneratedDocumentsResidence = createSelector(
  selectGeneratedDocumentsResidenceState,
  (state: GeneratedDocumentsResidenceState) => !state.isLoading
);

export const selectIsReadyAndLoadedGeneratedDocumentsResidence = createSelector(
  selectGeneratedDocumentsResidenceState,
  (state: GeneratedDocumentsResidenceState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const GeneratedDocumentsResidenceModel: SelectorModel = {
  name: 'generatedDocumentsResidences',
  getSelector: selectAllGeneratedDocumentsResidencesDictionary,
  isReady: selectIsReadyGeneratedDocumentsResidence
};

export const selectGeneratedDocumentsResidencesEntities = createSelector(
  selectGeneratedDocumentsResidenceState,
  selectEntities
);

export const selectGeneratedDocumentsResidencesArray = createSelector(
  selectGeneratedDocumentsResidenceState,
  selectAll
);

export const selectIdGeneratedDocumentsResidencesActive = createSelector(
  selectGeneratedDocumentsResidenceState,
  (state: GeneratedDocumentsResidenceState) => state.actives
);

const generatedDocumentsResidencesInObject = (
  generatedDocumentsResidences: Dictionary<GeneratedDocumentsResidenceEntityState>
) => ({ generatedDocumentsResidences });

const selectGeneratedDocumentsResidencesEntitiesDictionary = createSelector(
  selectGeneratedDocumentsResidencesEntities,
  generatedDocumentsResidencesInObject
);

const selectAllGeneratedDocumentsResidencesObject = createSelector(
  selectGeneratedDocumentsResidencesEntities,
  generatedDocumentsResidences => {
    return hydrateAll({ generatedDocumentsResidences });
  }
);

const selectOneGeneratedDocumentsResidenceDictionary = (idGeneratedDocumentsResidence: number) =>
  createSelector(selectGeneratedDocumentsResidencesEntities, generatedDocumentsResidences => {
    return {
      generatedDocumentsResidences: {
        [idGeneratedDocumentsResidence]: generatedDocumentsResidences[idGeneratedDocumentsResidence]
      }
    };
  });

const selectOneGeneratedDocumentsResidenceDictionaryWithoutChild = (idGeneratedDocumentsResidence: number) =>
  createSelector(selectGeneratedDocumentsResidencesEntities, generatedDocumentsResidences => {
    return { generatedDocumentsResidence: generatedDocumentsResidences[idGeneratedDocumentsResidence] };
  });

const selectActiveGeneratedDocumentsResidencesEntities = createSelector(
  selectIdGeneratedDocumentsResidencesActive,
  selectGeneratedDocumentsResidencesEntities,
  (actives: number[], generatedDocumentsResidences: Dictionary<GeneratedDocumentsResidenceEntityState>) =>
    getGeneratedDocumentsResidencesFromActives(actives, generatedDocumentsResidences)
);

function getGeneratedDocumentsResidencesFromActives(
  actives: number[],
  generatedDocumentsResidences: Dictionary<GeneratedDocumentsResidenceEntityState>
): Dictionary<GeneratedDocumentsResidenceEntityState> {
  return actives.reduce((acc, idActive) => {
    if (generatedDocumentsResidences[idActive]) {
      acc[idActive] = generatedDocumentsResidences[idActive];
    }
    return acc;
  }, {} as Dictionary<GeneratedDocumentsResidenceEntityState>);
}

const selectAllGeneratedDocumentsResidencesSelectors: Dictionary<Selector> = {};
export function selectAllGeneratedDocumentsResidences(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<GeneratedDocumentsResidence>(
      schema,
      selectAllGeneratedDocumentsResidencesSelectors,
      selectGeneratedDocumentsResidencesEntitiesDictionary,
      getRelationSelectors,
      generatedDocumentsResidenceRelations,
      hydrateAll,
      'generatedDocumentsResidence'
    );
  } else {
    return selectAllGeneratedDocumentsResidencesObject;
  }
}

export function selectAllGeneratedDocumentsResidencesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'generatedDocumentsResidences'
): Selector {
  return createSelector(selectAllGeneratedDocumentsResidences(schema), result => {
    const res = { [customKey]: {} as Dictionary<GeneratedDocumentsResidenceEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.generatedDocumentsResidences.length; i++) {
      res[customKey][result.generatedDocumentsResidences[i].idGeneratedDocumentsResidence] =
        result.generatedDocumentsResidences[i];
    }
    return res;
  });
}

export function selectOneGeneratedDocumentsResidence(
  schema: SelectSchema = {},
  idGeneratedDocumentsResidence: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneGeneratedDocumentsResidenceDictionary(idGeneratedDocumentsResidence)];
    selectors.push(
      ...getRelationSelectors(schema, generatedDocumentsResidenceRelations, 'generatedDocumentsResidence')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneGeneratedDocumentsResidenceDictionaryWithoutChild(idGeneratedDocumentsResidence);
  }
}

export function selectActiveGeneratedDocumentsResidences(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveGeneratedDocumentsResidencesEntities, generatedDocumentsResidences => ({
      generatedDocumentsResidences
    }))
  ];
  selectors.push(...getRelationSelectors(schema, generatedDocumentsResidenceRelations, 'generatedDocumentsResidence'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  generatedDocumentsResidences: Dictionary<GeneratedDocumentsResidenceEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
  generatedDocuments?: Dictionary<GeneratedDocumentEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  generatedDocumentsResidences: (GeneratedDocumentsResidence | null)[];
} {
  const { generatedDocumentsResidences, residences, generatedDocuments } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    generatedDocumentsResidences: Object.keys(generatedDocumentsResidences).map(idGeneratedDocumentsResidence =>
      hydrate(
        generatedDocumentsResidences[idGeneratedDocumentsResidence] as GeneratedDocumentsResidenceEntityState,
        residences,
        generatedDocuments
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  generatedDocumentsResidence: GeneratedDocumentsResidenceEntityState | null;
} {
  const { generatedDocumentsResidences, residences, generatedDocuments } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const generatedDocumentsResidence = Object.values(generatedDocumentsResidences)[0];
  return {
    generatedDocumentsResidence: hydrate(
      generatedDocumentsResidence as GeneratedDocumentsResidenceEntityState,
      residences,
      generatedDocuments
    )
  };
}

function hydrate(
  generatedDocumentsResidence: GeneratedDocumentsResidenceEntityState,
  residenceEntities?: Dictionary<ResidenceEntityState>,
  generatedDocumentEntities?: Dictionary<GeneratedDocumentEntityState>
): GeneratedDocumentsResidence | null {
  if (!generatedDocumentsResidence) {
    return null;
  }

  const generatedDocumentsResidenceHydrated: GeneratedDocumentsResidenceEntityState = {
    ...generatedDocumentsResidence
  };
  if (residenceEntities) {
    generatedDocumentsResidenceHydrated.residence = residenceEntities[
      generatedDocumentsResidence.residence as number
    ] as Residence;
  } else {
    delete generatedDocumentsResidenceHydrated.residence;
  }
  if (generatedDocumentEntities) {
    generatedDocumentsResidenceHydrated.generatedDocument = generatedDocumentEntities[
      generatedDocumentsResidence.generatedDocument as number
    ] as GeneratedDocument;
  } else {
    delete generatedDocumentsResidenceHydrated.generatedDocument;
  }

  return generatedDocumentsResidenceHydrated as GeneratedDocumentsResidence;
}
