import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyMediaActions from './company-media.actions';
import { adapter, initialState, CompanyMediaState } from './company-media.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyMediaEntityState } from '@_model/interfaces/company-media.model';

export const companyMediaReducersGeneratedFunctions: ReducerTypes<CompanyMediaState, readonly ActionCreator[]>[] = [
  on(CompanyMediaActions.getOneCompanyMedia, (state: CompanyMediaState) => setLoadingsState(state, true)),
  on(CompanyMediaActions.getManyCompanyMedias, (state: CompanyMediaState) => setLoadingsState(state, true)),
  on(CompanyMediaActions.upsertOneCompanyMedia, (state: CompanyMediaState) => setLoadingsState(state, true)),

  on(CompanyMediaActions.upsertManyCompanyMediasSuccess, (state: CompanyMediaState, { companyMedias }) =>
    adapter.upsertMany(companyMedias, setLoadingsState(state, false))
  ),
  on(CompanyMediaActions.deleteOneCompanyMedia, (state: CompanyMediaState) => setLoadingsState(state, true)),
  on(CompanyMediaActions.deleteOneCompanyMediaSuccess, (state: CompanyMediaState, { idCompanyMedia }) =>
    adapter.removeOne(idCompanyMedia, setLoadingsState(state, false))
  ),
  on(CompanyMediaActions.clearActive, (state: CompanyMediaState) => ({ ...state, actives: [] })),
  on(CompanyMediaActions.addManyActives, (state: CompanyMediaState, { idCompanyMedias }) => ({
    ...state,
    actives: state.actives.concat(idCompanyMedias)
  })),
  on(CompanyMediaActions.deleteOneActive, (state: CompanyMediaState, { idCompanyMedia }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCompanyMedia)
  })),

  on(CompanyMediaActions.clearStore, () => initialState),

  on(
    CompanyMediaActions.addManyCampaignMediaSuccess,
    (state: CompanyMediaState, { idCompanyMedia, idCampaignMedias }) => {
      if (!state.entities[idCompanyMedia]) {
        return state;
      }
      const campaignMedias = (state.entities[idCompanyMedia]?.campaignMedias as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyMedia]: {
            ...state.entities[idCompanyMedia],
            campaignMedias: campaignMedias.concat(idCampaignMedias.filter(id => campaignMedias.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CompanyMediaActions.deleteManyCampaignMediaSuccess,
    (state: CompanyMediaState, { idCampaignMedias, idCompanyMedias }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyMedias.reduce((entities, idCompanyMedia) => {
            if (!state.entities[idCompanyMedia]?.campaignMedias) {
              return entities;
            }
            entities[idCompanyMedia] = {
              ...state.entities[idCompanyMedia],
              campaignMedias: (state.entities[idCompanyMedia]?.campaignMedias as number[])?.filter(
                (idCampaignMedia: number) => !idCampaignMedias.some((id: number) => id === idCampaignMedia)
              )
            } as CompanyMediaEntityState;
            return entities;
          }, {} as Dictionary<CompanyMediaEntityState>)
        }
      };
    }
  ),

  on(CompanyMediaActions.addCompanySuccess, (state: CompanyMediaState, { idCompanyMedia, idCompany }) => {
    if (!state.entities[idCompanyMedia]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCompanyMedia]: {
          ...state.entities[idCompanyMedia],
          company: idCompany
        }
      }
    };
  }),

  on(CompanyMediaActions.deleteManyCompanySuccess, (state: CompanyMediaState, { idCompanies, idCompanyMedias }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idCompanyMedias.reduce((entities, idCompanyMedia) => {
          if (!state.entities[idCompanyMedia]?.company) {
            return entities;
          }
          entities[idCompanyMedia] = {
            ...state.entities[idCompanyMedia],
            company: idCompanies.some((idCompany: number) => idCompany === state.entities[idCompanyMedia]?.company)
              ? undefined
              : state.entities[idCompanyMedia]?.company
          } as CompanyMediaEntityState;
          return entities;
        }, {} as Dictionary<CompanyMediaEntityState>)
      }
    };
  })
];

export function setLoadingsState(
  state: CompanyMediaState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyMediaState {
  return { ...state, isLoaded, isLoading };
}
