import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { init as initSentry, routingInstrumentation } from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

if (environment.production) {
  enableProdMode();
}

function getCurrentEnv(): string {
  if (environment.test) {
    return 'test';
  } else if (environment.integration) {
    return 'integration';
  } else if (environment.staging) {
    return 'staging';
  } else if (environment.qualif) {
    return 'qualif';
  } else if (environment.production) {
    return 'production';
  } else {
    return 'development';
  }
}

initSentry({
  dsn: 'https://083e0b3dc8d64c7ba4ae060d1d8ef8c5@o1171300.ingest.sentry.io/6272982',
  environment: getCurrentEnv(),
  enabled: getCurrentEnv() !== 'development',
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation

    new BrowserTracing({
      tracingOrigins: ['localhost', environment.apiUrl],

      routingInstrumentation: routingInstrumentation
    })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
