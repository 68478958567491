import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationResidenceTodoActions from './organization-residence-todo.actions';
import { adapter, initialState, OrganizationResidenceTodoState } from './organization-residence-todo.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationResidenceTodoEntityState } from '@_model/interfaces/organization-residence-todo.model';

export const organizationResidenceTodoReducersGeneratedFunctions: ReducerTypes<
  OrganizationResidenceTodoState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationResidenceTodoActions.getOneOrganizationResidenceTodo, (state: OrganizationResidenceTodoState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationResidenceTodoActions.getManyOrganizationResidenceTodos, (state: OrganizationResidenceTodoState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationResidenceTodoActions.upsertOneOrganizationResidenceTodo, (state: OrganizationResidenceTodoState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationResidenceTodoActions.upsertManyOrganizationResidenceTodosSuccess,
    (state: OrganizationResidenceTodoState, { organizationResidenceTodos }) =>
      adapter.upsertMany(organizationResidenceTodos, setLoadingsState(state, false))
  ),
  on(OrganizationResidenceTodoActions.deleteOneOrganizationResidenceTodo, (state: OrganizationResidenceTodoState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationResidenceTodoActions.deleteOneOrganizationResidenceTodoSuccess,
    (state: OrganizationResidenceTodoState, { idOrganizationResidenceTodo }) =>
      adapter.removeOne(idOrganizationResidenceTodo, setLoadingsState(state, false))
  ),
  on(OrganizationResidenceTodoActions.clearActive, (state: OrganizationResidenceTodoState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationResidenceTodoActions.addManyActives,
    (state: OrganizationResidenceTodoState, { idOrganizationResidenceTodos }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationResidenceTodos)
    })
  ),
  on(
    OrganizationResidenceTodoActions.deleteOneActive,
    (state: OrganizationResidenceTodoState, { idOrganizationResidenceTodo }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationResidenceTodo)
    })
  ),

  on(OrganizationResidenceTodoActions.clearStore, () => initialState),

  on(
    OrganizationResidenceTodoActions.addManyResidenceTodoSuccess,
    (state: OrganizationResidenceTodoState, { idOrganizationResidenceTodo, idResidenceTodos }) => {
      if (!state.entities[idOrganizationResidenceTodo]) {
        return state;
      }
      const residenceTodos = (state.entities[idOrganizationResidenceTodo]?.residenceTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodo]: {
            ...state.entities[idOrganizationResidenceTodo],
            residenceTodos: residenceTodos.concat(idResidenceTodos.filter(id => residenceTodos.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoActions.deleteManyResidenceTodoSuccess,
    (state: OrganizationResidenceTodoState, { idResidenceTodos, idOrganizationResidenceTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodos.reduce((entities, idOrganizationResidenceTodo) => {
            if (!state.entities[idOrganizationResidenceTodo]?.residenceTodos) {
              return entities;
            }
            entities[idOrganizationResidenceTodo] = {
              ...state.entities[idOrganizationResidenceTodo],
              residenceTodos: (state.entities[idOrganizationResidenceTodo]?.residenceTodos as number[])?.filter(
                (idResidenceTodo: number) => !idResidenceTodos.some((id: number) => id === idResidenceTodo)
              )
            } as OrganizationResidenceTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoActions.addManyOrganizationResidenceTodoRuleSuccess,
    (state: OrganizationResidenceTodoState, { idOrganizationResidenceTodo, idOrganizationResidenceTodoRules }) => {
      if (!state.entities[idOrganizationResidenceTodo]) {
        return state;
      }
      const organizationResidenceTodoRules =
        (state.entities[idOrganizationResidenceTodo]?.organizationResidenceTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodo]: {
            ...state.entities[idOrganizationResidenceTodo],
            organizationResidenceTodoRules: organizationResidenceTodoRules.concat(
              idOrganizationResidenceTodoRules.filter(id => organizationResidenceTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoActions.deleteManyOrganizationResidenceTodoRuleSuccess,
    (state: OrganizationResidenceTodoState, { idOrganizationResidenceTodoRules, idOrganizationResidenceTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodos.reduce((entities, idOrganizationResidenceTodo) => {
            if (!state.entities[idOrganizationResidenceTodo]?.organizationResidenceTodoRules) {
              return entities;
            }
            entities[idOrganizationResidenceTodo] = {
              ...state.entities[idOrganizationResidenceTodo],
              organizationResidenceTodoRules: (
                state.entities[idOrganizationResidenceTodo]?.organizationResidenceTodoRules as number[]
              )?.filter(
                (idOrganizationResidenceTodoRule: number) =>
                  !idOrganizationResidenceTodoRules.some((id: number) => id === idOrganizationResidenceTodoRule)
              )
            } as OrganizationResidenceTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoActions.addManyOrganizationResidenceTodoAppliedRuleSuccess,
    (
      state: OrganizationResidenceTodoState,
      { idOrganizationResidenceTodo, idOrganizationResidenceTodoAppliedRules }
    ) => {
      if (!state.entities[idOrganizationResidenceTodo]) {
        return state;
      }
      const organizationResidenceTodoAppliedRules =
        (state.entities[idOrganizationResidenceTodo]?.organizationResidenceTodoAppliedRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodo]: {
            ...state.entities[idOrganizationResidenceTodo],
            organizationResidenceTodoAppliedRules: organizationResidenceTodoAppliedRules.concat(
              idOrganizationResidenceTodoAppliedRules.filter(
                id => organizationResidenceTodoAppliedRules.indexOf(id) < 0
              )
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoActions.deleteManyOrganizationResidenceTodoAppliedRuleSuccess,
    (
      state: OrganizationResidenceTodoState,
      { idOrganizationResidenceTodoAppliedRules, idOrganizationResidenceTodos }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodos.reduce((entities, idOrganizationResidenceTodo) => {
            if (!state.entities[idOrganizationResidenceTodo]?.organizationResidenceTodoAppliedRules) {
              return entities;
            }
            entities[idOrganizationResidenceTodo] = {
              ...state.entities[idOrganizationResidenceTodo],
              organizationResidenceTodoAppliedRules: (
                state.entities[idOrganizationResidenceTodo]?.organizationResidenceTodoAppliedRules as number[]
              )?.filter(
                (idOrganizationResidenceTodoAppliedRule: number) =>
                  !idOrganizationResidenceTodoAppliedRules.some(
                    (id: number) => id === idOrganizationResidenceTodoAppliedRule
                  )
              )
            } as OrganizationResidenceTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoActions.addManyOrganizationResidenceTodoProfilSuccess,
    (state: OrganizationResidenceTodoState, { idOrganizationResidenceTodo, idOrganizationResidenceTodoProfils }) => {
      if (!state.entities[idOrganizationResidenceTodo]) {
        return state;
      }
      const organizationResidenceTodoProfils =
        (state.entities[idOrganizationResidenceTodo]?.organizationResidenceTodoProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodo]: {
            ...state.entities[idOrganizationResidenceTodo],
            organizationResidenceTodoProfils: organizationResidenceTodoProfils.concat(
              idOrganizationResidenceTodoProfils.filter(id => organizationResidenceTodoProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoActions.deleteManyOrganizationResidenceTodoProfilSuccess,
    (state: OrganizationResidenceTodoState, { idOrganizationResidenceTodoProfils, idOrganizationResidenceTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodos.reduce((entities, idOrganizationResidenceTodo) => {
            if (!state.entities[idOrganizationResidenceTodo]?.organizationResidenceTodoProfils) {
              return entities;
            }
            entities[idOrganizationResidenceTodo] = {
              ...state.entities[idOrganizationResidenceTodo],
              organizationResidenceTodoProfils: (
                state.entities[idOrganizationResidenceTodo]?.organizationResidenceTodoProfils as number[]
              )?.filter(
                (idOrganizationResidenceTodoProfil: number) =>
                  !idOrganizationResidenceTodoProfils.some((id: number) => id === idOrganizationResidenceTodoProfil)
              )
            } as OrganizationResidenceTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoActions.addManyCompanyCommunicationSuccess,
    (state: OrganizationResidenceTodoState, { idOrganizationResidenceTodo, idCompanyCommunications }) => {
      if (!state.entities[idOrganizationResidenceTodo]) {
        return state;
      }
      const companyCommunications =
        (state.entities[idOrganizationResidenceTodo]?.companyCommunications as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodo]: {
            ...state.entities[idOrganizationResidenceTodo],
            companyCommunications: companyCommunications.concat(
              idCompanyCommunications.filter(id => companyCommunications.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoActions.deleteManyCompanyCommunicationSuccess,
    (state: OrganizationResidenceTodoState, { idCompanyCommunications, idOrganizationResidenceTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodos.reduce((entities, idOrganizationResidenceTodo) => {
            if (!state.entities[idOrganizationResidenceTodo]?.companyCommunications) {
              return entities;
            }
            entities[idOrganizationResidenceTodo] = {
              ...state.entities[idOrganizationResidenceTodo],
              companyCommunications: (
                state.entities[idOrganizationResidenceTodo]?.companyCommunications as number[]
              )?.filter(
                (idCompanyCommunication: number) =>
                  !idCompanyCommunications.some((id: number) => id === idCompanyCommunication)
              )
            } as OrganizationResidenceTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoActions.addManyCompanyCommunicationResidenceTodoSuccess,
    (state: OrganizationResidenceTodoState, { idOrganizationResidenceTodo, idCompanyCommunicationResidenceTodos }) => {
      if (!state.entities[idOrganizationResidenceTodo]) {
        return state;
      }
      const companyCommunicationResidenceTodos =
        (state.entities[idOrganizationResidenceTodo]?.companyCommunicationResidenceTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodo]: {
            ...state.entities[idOrganizationResidenceTodo],
            companyCommunicationResidenceTodos: companyCommunicationResidenceTodos.concat(
              idCompanyCommunicationResidenceTodos.filter(id => companyCommunicationResidenceTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoActions.deleteManyCompanyCommunicationResidenceTodoSuccess,
    (state: OrganizationResidenceTodoState, { idCompanyCommunicationResidenceTodos, idOrganizationResidenceTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodos.reduce((entities, idOrganizationResidenceTodo) => {
            if (!state.entities[idOrganizationResidenceTodo]?.companyCommunicationResidenceTodos) {
              return entities;
            }
            entities[idOrganizationResidenceTodo] = {
              ...state.entities[idOrganizationResidenceTodo],
              companyCommunicationResidenceTodos: (
                state.entities[idOrganizationResidenceTodo]?.companyCommunicationResidenceTodos as number[]
              )?.filter(
                (idCompanyCommunicationResidenceTodo: number) =>
                  !idCompanyCommunicationResidenceTodos.some((id: number) => id === idCompanyCommunicationResidenceTodo)
              )
            } as OrganizationResidenceTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoActions.addOrganizationSuccess,
    (state: OrganizationResidenceTodoState, { idOrganizationResidenceTodo, idOrganization }) => {
      if (!state.entities[idOrganizationResidenceTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodo]: {
            ...state.entities[idOrganizationResidenceTodo],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoActions.deleteManyOrganizationSuccess,
    (state: OrganizationResidenceTodoState, { idOrganizations, idOrganizationResidenceTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodos.reduce((entities, idOrganizationResidenceTodo) => {
            if (!state.entities[idOrganizationResidenceTodo]?.organization) {
              return entities;
            }
            entities[idOrganizationResidenceTodo] = {
              ...state.entities[idOrganizationResidenceTodo],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationResidenceTodo]?.organization
              )
                ? undefined
                : state.entities[idOrganizationResidenceTodo]?.organization
            } as OrganizationResidenceTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoActions.addDiagnosticSuccess,
    (state: OrganizationResidenceTodoState, { idOrganizationResidenceTodo, idDiagnostic }) => {
      if (!state.entities[idOrganizationResidenceTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodo]: {
            ...state.entities[idOrganizationResidenceTodo],
            diagnostic: idDiagnostic
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoActions.deleteManyDiagnosticSuccess,
    (state: OrganizationResidenceTodoState, { idDiagnostics, idOrganizationResidenceTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodos.reduce((entities, idOrganizationResidenceTodo) => {
            if (!state.entities[idOrganizationResidenceTodo]?.diagnostic) {
              return entities;
            }
            entities[idOrganizationResidenceTodo] = {
              ...state.entities[idOrganizationResidenceTodo],
              diagnostic: idDiagnostics.some(
                (idDiagnostic: number) => idDiagnostic === state.entities[idOrganizationResidenceTodo]?.diagnostic
              )
                ? undefined
                : state.entities[idOrganizationResidenceTodo]?.diagnostic
            } as OrganizationResidenceTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationResidenceTodoState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationResidenceTodoState {
  return { ...state, isLoaded, isLoading };
}
