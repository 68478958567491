import { OrganizationEnergieState, initialState } from './organization-energie.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationEnergieReducersGeneratedFunctions } from './organization-energie-generated.reducer';

const organizationEnergieReducersFunctions = [...organizationEnergieReducersGeneratedFunctions];

const organizationEnergieReducer = createReducer(initialState, ...organizationEnergieReducersFunctions);

export function reducer(state: OrganizationEnergieState | undefined, action: Action) {
  return organizationEnergieReducer(state, action);
}
