import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  ResidenceSaleCategoryFamily,
  ResidenceSaleCategoryFamilyEntityState
} from '@_model/interfaces/residence-sale-category-family.model';
import { ResidenceSaleCategoryFamilyApiService } from '@_services/api/residence-sale-category-family-api.service';
import * as residenceSaleCategoryFamilyActions from '@_store/residence-sale-category-family/residence-sale-category-family.actions';
import { getActionsToNormalizeResidenceSaleCategoryFamily } from '@_config/store/normalization.generated';
import { selectResidenceSaleCategoryFamilyState } from './residence-sale-category-family-generated.selectors';
import * as residenceActions from '@_store/residence/residence.actions';
import * as saleCategoryFamilyActions from '@_store/sale-category-family/sale-category-family.actions';

export interface ResidenceSaleCategoryFamilyRelationsIds {
  residence?: number;
  saleCategoryFamily?: number;
}

export function getDefaultAddResidenceSaleCategoryFamilyActions(
  residenceSaleCategoryFamily: ResidenceSaleCategoryFamilyEntityState,
  ids?: ResidenceSaleCategoryFamilyRelationsIds
): Action[] {
  const actions: Action[] = [
    residenceSaleCategoryFamilyActions.normalizeManyResidenceSaleCategoryFamiliesAfterUpsert({
      residenceSaleCategoryFamilies: [residenceSaleCategoryFamily]
    })
  ];

  if (ids?.residence) {
    actions.push(
      residenceActions.addManyResidenceSaleCategoryFamilySuccess({
        idResidence: ids.residence,
        idResidenceSaleCategoryFamilies: [residenceSaleCategoryFamily.idResidenceSaleCategoryFamily]
      })
    );
    actions.push(
      residenceSaleCategoryFamilyActions.addResidenceSuccess({
        idResidenceSaleCategoryFamily: residenceSaleCategoryFamily.idResidenceSaleCategoryFamily,
        idResidence: ids.residence
      })
    );
  }

  if (ids?.saleCategoryFamily) {
    actions.push(
      saleCategoryFamilyActions.addManyResidenceSaleCategoryFamilySuccess({
        idSaleCategoryFamily: ids.saleCategoryFamily,
        idResidenceSaleCategoryFamilies: [residenceSaleCategoryFamily.idResidenceSaleCategoryFamily]
      })
    );
    actions.push(
      residenceSaleCategoryFamilyActions.addSaleCategoryFamilySuccess({
        idResidenceSaleCategoryFamily: residenceSaleCategoryFamily.idResidenceSaleCategoryFamily,
        idSaleCategoryFamily: ids.saleCategoryFamily
      })
    );
  }

  return actions;
}

export function getDefaultDeleteResidenceSaleCategoryFamilyActions(
  residenceSaleCategoryFamily: ResidenceSaleCategoryFamilyEntityState
): Action[] {
  const actions: Action[] = [
    residenceSaleCategoryFamilyActions.deleteOneResidenceSaleCategoryFamilySuccess({
      idResidenceSaleCategoryFamily: residenceSaleCategoryFamily.idResidenceSaleCategoryFamily
    })
  ];

  if (residenceSaleCategoryFamily.residence) {
    actions.push(
      residenceActions.deleteManyResidenceSaleCategoryFamilySuccess({
        idResidenceSaleCategoryFamilies: [residenceSaleCategoryFamily.idResidenceSaleCategoryFamily],
        idResidences: [residenceSaleCategoryFamily.residence as number]
      })
    );
  }

  if (residenceSaleCategoryFamily.saleCategoryFamily) {
    actions.push(
      saleCategoryFamilyActions.deleteManyResidenceSaleCategoryFamilySuccess({
        idResidenceSaleCategoryFamilies: [residenceSaleCategoryFamily.idResidenceSaleCategoryFamily],
        idFamilySaleCategories: [residenceSaleCategoryFamily.saleCategoryFamily as number]
      })
    );
  }

  return actions;
}

export class GeneratedResidenceSaleCategoryFamilyEffects {
  constructor(
    protected actions$: Actions,
    protected residenceSaleCategoryFamilyApiService: ResidenceSaleCategoryFamilyApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidenceSaleCategoryFamilies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceSaleCategoryFamilyActions.getManyResidenceSaleCategoryFamilies),
      switchMap(({ params }) =>
        this.residenceSaleCategoryFamilyApiService.getResidenceSaleCategoryFamilies(params).pipe(
          map((residenceSaleCategoryFamilies: ResidenceSaleCategoryFamily[]) => {
            return residenceSaleCategoryFamilyActions.normalizeManyResidenceSaleCategoryFamiliesAfterUpsert({
              residenceSaleCategoryFamilies
            });
          }),
          catchError(error => of(residenceSaleCategoryFamilyActions.residenceSaleCategoryFamiliesFailure({ error })))
        )
      )
    );
  });

  getOneResidenceSaleCategoryFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceSaleCategoryFamilyActions.getOneResidenceSaleCategoryFamily),
      switchMap(idResidenceSaleCategoryFamily =>
        this.residenceSaleCategoryFamilyApiService.getResidenceSaleCategoryFamily(idResidenceSaleCategoryFamily).pipe(
          map((residenceSaleCategoryFamily: ResidenceSaleCategoryFamily) => {
            return residenceSaleCategoryFamilyActions.normalizeManyResidenceSaleCategoryFamiliesAfterUpsert({
              residenceSaleCategoryFamilies: [residenceSaleCategoryFamily]
            });
          }),
          catchError(error => of(residenceSaleCategoryFamilyActions.residenceSaleCategoryFamiliesFailure({ error })))
        )
      )
    );
  });

  upsertOneResidenceSaleCategoryFamily$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceSaleCategoryFamilyActions.upsertOneResidenceSaleCategoryFamily),
      concatMap(
        ({
          residenceSaleCategoryFamily,
          ids
        }: {
          residenceSaleCategoryFamily: Partial<ResidenceSaleCategoryFamily>;
          ids?: ResidenceSaleCategoryFamilyRelationsIds;
        }) => {
          if (residenceSaleCategoryFamily.idResidenceSaleCategoryFamily) {
            return this.residenceSaleCategoryFamilyApiService
              .updateResidenceSaleCategoryFamily(residenceSaleCategoryFamily)
              .pipe(
                map((residenceSaleCategoryFamilyReturned: ResidenceSaleCategoryFamily) => {
                  return residenceSaleCategoryFamilyActions.normalizeManyResidenceSaleCategoryFamiliesAfterUpsert({
                    residenceSaleCategoryFamilies: [residenceSaleCategoryFamilyReturned]
                  });
                }),
                catchError(error =>
                  of(residenceSaleCategoryFamilyActions.residenceSaleCategoryFamiliesFailure({ error }))
                )
              );
          } else {
            return this.residenceSaleCategoryFamilyApiService
              .addResidenceSaleCategoryFamily(residenceSaleCategoryFamily)
              .pipe(
                mergeMap((residenceSaleCategoryFamilyReturned: ResidenceSaleCategoryFamily) =>
                  getDefaultAddResidenceSaleCategoryFamilyActions(residenceSaleCategoryFamilyReturned, ids)
                ),
                catchError(error =>
                  of(residenceSaleCategoryFamilyActions.residenceSaleCategoryFamiliesFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneResidenceSaleCategoryFamily$ = createEffect(() => {
    const selectResidenceSaleCategoryFamilyState$ = this.store$.select(selectResidenceSaleCategoryFamilyState);
    return this.actions$.pipe(
      ofType(residenceSaleCategoryFamilyActions.deleteOneResidenceSaleCategoryFamily),
      withLatestFrom(selectResidenceSaleCategoryFamilyState$),
      concatMap(([{ idResidenceSaleCategoryFamily }, state]) =>
        this.residenceSaleCategoryFamilyApiService
          .deleteResidenceSaleCategoryFamily(idResidenceSaleCategoryFamily)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteResidenceSaleCategoryFamilyActions(
                  state.entities[idResidenceSaleCategoryFamily] as ResidenceSaleCategoryFamilyEntityState
                ),
                residenceSaleCategoryFamilyActions.deleteOneResidenceSaleCategoryFamily.type
              )
            ]),
            catchError(error => of(residenceSaleCategoryFamilyActions.residenceSaleCategoryFamiliesFailure({ error })))
          )
      )
    );
  });

  normalizeManyResidenceSaleCategoryFamiliesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceSaleCategoryFamilyActions.normalizeManyResidenceSaleCategoryFamiliesAfterUpsert),
      concatMap(({ residenceSaleCategoryFamilies }) => {
        const actions: Action[] = getActionsToNormalizeResidenceSaleCategoryFamily(
          residenceSaleCategoryFamilies,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[ResidenceSaleCategoryFamily] Normalization After Upsert Success')];
      })
    );
  });
}
