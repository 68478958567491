import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { ResidenceApiService } from '@_services/api/residence-api.service';
import * as residenceActions from '@_store/residence/residence.actions';
import { getActionsToNormalizeResidence } from '@_config/store/normalization.generated';
import { selectResidenceState } from './residence-generated.selectors';
import * as companyActions from '@_store/company/company.actions';
import * as userActions from '@_store/user/user.actions';
import * as organizationForecastRateActions from '@_store/organization-forecast-rate/organization-forecast-rate.actions';
import * as cityActions from '@_store/city/city.actions';
import * as diffusionVisualActions from '@_store/diffusion-visual/diffusion-visual.actions';
import { DiffusionVisual } from '@_model/interfaces/diffusion-visual.model';
import * as residenceTodoActions from '@_store/residence-todo/residence-todo.actions';
import { ResidenceTodo } from '@_model/interfaces/residence-todo.model';
import * as residenceWorkActions from '@_store/residence-work/residence-work.actions';
import { ResidenceWork } from '@_model/interfaces/residence-work.model';
import * as residenceSaleCategoryFamilyActions from '@_store/residence-sale-category-family/residence-sale-category-family.actions';
import { ResidenceSaleCategoryFamily } from '@_model/interfaces/residence-sale-category-family.model';
import * as residenceContactActions from '@_store/residence-contact/residence-contact.actions';
import { ResidenceContact } from '@_model/interfaces/residence-contact.model';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import { Stratalot } from '@_model/interfaces/stratalot.model';
import * as residencePriceGridActions from '@_store/residence-price-grid/residence-price-grid.actions';
import { ResidencePriceGrid } from '@_model/interfaces/residence-price-grid.model';
import * as residenceShareActions from '@_store/residence-share/residence-share.actions';
import { ResidenceShare } from '@_model/interfaces/residence-share.model';
import * as stratalotRcpActions from '@_store/stratalot-rcp/stratalot-rcp.actions';
import { StratalotRcp } from '@_model/interfaces/stratalot-rcp.model';
import * as saleCategoryFamilyActions from '@_store/sale-category-family/sale-category-family.actions';
import { SaleCategoryFamily } from '@_model/interfaces/sale-category-family.model';
import * as prospectBuyingWishActions from '@_store/prospect-buying-wish/prospect-buying-wish.actions';
import { ProspectBuyingWish } from '@_model/interfaces/prospect-buying-wish.model';
import * as companyTerritoireActions from '@_store/company-territoire/company-territoire.actions';
import { CompanyTerritoire } from '@_model/interfaces/company-territoire.model';
import * as residenceForecastActions from '@_store/residence-forecast/residence-forecast.actions';
import { ResidenceForecast } from '@_model/interfaces/residence-forecast.model';
import * as generatedDocumentsResidenceActions from '@_store/generated-documents-residence/generated-documents-residence.actions';
import { GeneratedDocumentsResidence } from '@_model/interfaces/generated-documents-residence.model';
import * as generatedDocumentActions from '@_store/generated-document/generated-document.actions';
import { GeneratedDocument } from '@_model/interfaces/generated-document.model';

export interface ResidenceRelationsIds {
  diffusionVisuals?: number | number[];
  residenceTodos?: number | number[];
  residenceWorks?: number | number[];
  residenceSaleCategoryFamilies?: number | number[];
  residenceContacts?: number | number[];
  stratalots?: number | number[];
  residencePriceGrids?: number | number[];
  residenceShares?: number | number[];
  stratalotRcps?: number | number[];
  familySaleCategories?: number | number[];
  prospectBuyingWishs?: number | number[];
  companyTerritoires?: number | number[];
  residenceForecasts?: number | number[];
  generatedDocumentsResidences?: number | number[];
  generatedDocuments?: number | number[];
  company?: number;
  responsable?: number;
  organizationForecastRate?: number;
  city?: number;
}

export function getDefaultAddResidenceActions(residence: ResidenceEntityState, ids?: ResidenceRelationsIds): Action[] {
  const actions: Action[] = [residenceActions.normalizeManyResidencesAfterUpsert({ residences: [residence] })];

  if (ids?.company) {
    actions.push(
      companyActions.addManyResidenceSuccess({
        idCompany: ids.company,
        idResidences: [residence.idResidence]
      })
    );
    actions.push(
      residenceActions.addCompanySuccess({
        idResidence: residence.idResidence,
        idCompany: ids.company
      })
    );
  }

  if (ids?.responsable) {
    actions.push(
      userActions.addManyResidenceSuccess({
        idUser: ids.responsable,
        idResidence: [residence.idResidence]
      })
    );
    actions.push(
      residenceActions.addResponsableSuccess({
        idResidence: residence.idResidence,
        idResponsable: ids.responsable
      })
    );
  }

  if (ids?.organizationForecastRate) {
    actions.push(
      organizationForecastRateActions.addManyResidenceSuccess({
        idOrganizationForecastRate: ids.organizationForecastRate,
        idResidences: [residence.idResidence]
      })
    );
    actions.push(
      residenceActions.addOrganizationForecastRateSuccess({
        idResidence: residence.idResidence,
        idOrganizationForecastRate: ids.organizationForecastRate
      })
    );
  }

  if (ids?.city) {
    actions.push(
      cityActions.addManyResidenceSuccess({
        idCity: ids.city,
        idResidences: [residence.idResidence]
      })
    );
    actions.push(
      residenceActions.addCitySuccess({
        idResidence: residence.idResidence,
        idCity: ids.city
      })
    );
  }

  if (ids?.diffusionVisuals) {
    if (!Array.isArray(ids.diffusionVisuals)) {
      actions.push(
        diffusionVisualActions.upsertOneDiffusionVisual({
          diffusionVisual: {
            idResidence: residence.idResidence,
            idDiffusionVisual: ids.diffusionVisuals as number
          } as DiffusionVisual
        })
      );
      actions.push(
        residenceActions.addManyDiffusionVisualSuccess({
          idResidence: residence.idResidence,
          idDiffusionVisuals: [ids.diffusionVisuals as number]
        })
      );
    } else {
      actions.push(
        diffusionVisualActions.upsertManyDiffusionVisuals({
          diffusionVisuals: (ids.diffusionVisuals as number[]).map((idDiffusionVisual: number) => ({
            idResidence: residence.idResidence,
            idDiffusionVisual
          })) as DiffusionVisual[]
        })
      );
      actions.push(
        residenceActions.addManyDiffusionVisualSuccess({
          idResidence: residence.idResidence,
          idDiffusionVisuals: ids.diffusionVisuals as number[]
        })
      );
    }
  }

  if (ids?.residenceTodos) {
    if (!Array.isArray(ids.residenceTodos)) {
      actions.push(
        residenceTodoActions.upsertOneResidenceTodo({
          residenceTodo: {
            idResidence: residence.idResidence,
            idResidenceTodo: ids.residenceTodos as number
          } as ResidenceTodo
        })
      );
      actions.push(
        residenceActions.addManyResidenceTodoSuccess({
          idResidence: residence.idResidence,
          idResidenceTodos: [ids.residenceTodos as number]
        })
      );
    } else {
      actions.push(
        residenceTodoActions.upsertManyResidenceTodos({
          residenceTodos: (ids.residenceTodos as number[]).map((idResidenceTodo: number) => ({
            idResidence: residence.idResidence,
            idResidenceTodo
          })) as ResidenceTodo[]
        })
      );
      actions.push(
        residenceActions.addManyResidenceTodoSuccess({
          idResidence: residence.idResidence,
          idResidenceTodos: ids.residenceTodos as number[]
        })
      );
    }
  }

  if (ids?.residenceWorks) {
    if (!Array.isArray(ids.residenceWorks)) {
      actions.push(
        residenceWorkActions.upsertOneResidenceWork({
          residenceWork: {
            idResidence: residence.idResidence,
            idResidenceWork: ids.residenceWorks as number
          } as ResidenceWork
        })
      );
      actions.push(
        residenceActions.addManyResidenceWorkSuccess({
          idResidence: residence.idResidence,
          idResidenceWorks: [ids.residenceWorks as number]
        })
      );
    } else {
      actions.push(
        residenceWorkActions.upsertManyResidenceWorks({
          residenceWorks: (ids.residenceWorks as number[]).map((idResidenceWork: number) => ({
            idResidence: residence.idResidence,
            idResidenceWork
          })) as ResidenceWork[]
        })
      );
      actions.push(
        residenceActions.addManyResidenceWorkSuccess({
          idResidence: residence.idResidence,
          idResidenceWorks: ids.residenceWorks as number[]
        })
      );
    }
  }

  if (ids?.residenceSaleCategoryFamilies) {
    if (!Array.isArray(ids.residenceSaleCategoryFamilies)) {
      actions.push(
        residenceSaleCategoryFamilyActions.upsertOneResidenceSaleCategoryFamily({
          residenceSaleCategoryFamily: {
            idResidence: residence.idResidence,
            idResidenceSaleCategoryFamily: ids.residenceSaleCategoryFamilies as number
          } as ResidenceSaleCategoryFamily
        })
      );
      actions.push(
        residenceActions.addManyResidenceSaleCategoryFamilySuccess({
          idResidence: residence.idResidence,
          idResidenceSaleCategoryFamilies: [ids.residenceSaleCategoryFamilies as number]
        })
      );
    } else {
      actions.push(
        residenceSaleCategoryFamilyActions.upsertManyResidenceSaleCategoryFamilies({
          residenceSaleCategoryFamilies: (ids.residenceSaleCategoryFamilies as number[]).map(
            (idResidenceSaleCategoryFamily: number) => ({
              idResidence: residence.idResidence,
              idResidenceSaleCategoryFamily
            })
          ) as ResidenceSaleCategoryFamily[]
        })
      );
      actions.push(
        residenceActions.addManyResidenceSaleCategoryFamilySuccess({
          idResidence: residence.idResidence,
          idResidenceSaleCategoryFamilies: ids.residenceSaleCategoryFamilies as number[]
        })
      );
    }
  }

  if (ids?.residenceContacts) {
    if (!Array.isArray(ids.residenceContacts)) {
      actions.push(
        residenceContactActions.upsertOneResidenceContact({
          residenceContact: {
            idResidence: residence.idResidence,
            idResidenceContact: ids.residenceContacts as number
          } as ResidenceContact
        })
      );
      actions.push(
        residenceActions.addManyResidenceContactSuccess({
          idResidence: residence.idResidence,
          idResidenceContacts: [ids.residenceContacts as number]
        })
      );
    } else {
      actions.push(
        residenceContactActions.upsertManyResidenceContacts({
          residenceContacts: (ids.residenceContacts as number[]).map((idResidenceContact: number) => ({
            idResidence: residence.idResidence,
            idResidenceContact
          })) as ResidenceContact[]
        })
      );
      actions.push(
        residenceActions.addManyResidenceContactSuccess({
          idResidence: residence.idResidence,
          idResidenceContacts: ids.residenceContacts as number[]
        })
      );
    }
  }

  if (ids?.stratalots) {
    if (!Array.isArray(ids.stratalots)) {
      actions.push(
        stratalotActions.upsertOneStratalot({
          stratalot: {
            idResidence: residence.idResidence,
            idStratalot: ids.stratalots as number
          } as Stratalot
        })
      );
      actions.push(
        residenceActions.addManyStratalotSuccess({
          idResidence: residence.idResidence,
          idStratalots: [ids.stratalots as number]
        })
      );
    } else {
      actions.push(
        stratalotActions.upsertManyStratalots({
          stratalots: (ids.stratalots as number[]).map((idStratalot: number) => ({
            idResidence: residence.idResidence,
            idStratalot
          })) as Stratalot[]
        })
      );
      actions.push(
        residenceActions.addManyStratalotSuccess({
          idResidence: residence.idResidence,
          idStratalots: ids.stratalots as number[]
        })
      );
    }
  }

  if (ids?.residencePriceGrids) {
    if (!Array.isArray(ids.residencePriceGrids)) {
      actions.push(
        residencePriceGridActions.upsertOneResidencePriceGrid({
          residencePriceGrid: {
            idResidence: residence.idResidence,
            idResidencePriceGrid: ids.residencePriceGrids as number
          } as ResidencePriceGrid
        })
      );
      actions.push(
        residenceActions.addManyResidencePriceGridSuccess({
          idResidence: residence.idResidence,
          idResidencePriceGrids: [ids.residencePriceGrids as number]
        })
      );
    } else {
      actions.push(
        residencePriceGridActions.upsertManyResidencePriceGrids({
          residencePriceGrids: (ids.residencePriceGrids as number[]).map((idResidencePriceGrid: number) => ({
            idResidence: residence.idResidence,
            idResidencePriceGrid
          })) as ResidencePriceGrid[]
        })
      );
      actions.push(
        residenceActions.addManyResidencePriceGridSuccess({
          idResidence: residence.idResidence,
          idResidencePriceGrids: ids.residencePriceGrids as number[]
        })
      );
    }
  }

  if (ids?.residenceShares) {
    if (!Array.isArray(ids.residenceShares)) {
      actions.push(
        residenceShareActions.upsertOneResidenceShare({
          residenceShare: {
            idResidence: residence.idResidence,
            idResidenceShare: ids.residenceShares as number
          } as ResidenceShare
        })
      );
      actions.push(
        residenceActions.addManyResidenceShareSuccess({
          idResidence: residence.idResidence,
          idResidenceShares: [ids.residenceShares as number]
        })
      );
    } else {
      actions.push(
        residenceShareActions.upsertManyResidenceShares({
          residenceShares: (ids.residenceShares as number[]).map((idResidenceShare: number) => ({
            idResidence: residence.idResidence,
            idResidenceShare
          })) as ResidenceShare[]
        })
      );
      actions.push(
        residenceActions.addManyResidenceShareSuccess({
          idResidence: residence.idResidence,
          idResidenceShares: ids.residenceShares as number[]
        })
      );
    }
  }

  if (ids?.stratalotRcps) {
    if (!Array.isArray(ids.stratalotRcps)) {
      actions.push(
        stratalotRcpActions.upsertOneStratalotRcp({
          stratalotRcp: {
            idResidence: residence.idResidence,
            idStratalotRcp: ids.stratalotRcps as number
          } as StratalotRcp
        })
      );
      actions.push(
        residenceActions.addManyStratalotRcpSuccess({
          idResidence: residence.idResidence,
          idStratalotRcps: [ids.stratalotRcps as number]
        })
      );
    } else {
      actions.push(
        stratalotRcpActions.upsertManyStratalotRcps({
          stratalotRcps: (ids.stratalotRcps as number[]).map((idStratalotRcp: number) => ({
            idResidence: residence.idResidence,
            idStratalotRcp
          })) as StratalotRcp[]
        })
      );
      actions.push(
        residenceActions.addManyStratalotRcpSuccess({
          idResidence: residence.idResidence,
          idStratalotRcps: ids.stratalotRcps as number[]
        })
      );
    }
  }

  if (ids?.familySaleCategories) {
    if (!Array.isArray(ids.familySaleCategories)) {
      actions.push(
        saleCategoryFamilyActions.upsertOneSaleCategoryFamily({
          saleCategoryFamily: {
            idResidence: residence.idResidence,
            idSaleCategoryFamily: ids.familySaleCategories as number
          } as SaleCategoryFamily & any
        })
      );
      actions.push(
        residenceActions.addManySaleCategoryFamilySuccess({
          idResidence: residence.idResidence,
          idFamilySaleCategories: [ids.familySaleCategories as number]
        })
      );
    } else {
      actions.push(
        saleCategoryFamilyActions.upsertManyFamilySaleCategories({
          familySaleCategories: (ids.familySaleCategories as number[]).map((idSaleCategoryFamily: number) => ({
            idResidence: residence.idResidence,
            idSaleCategoryFamily
          })) as SaleCategoryFamily[] & any[]
        })
      );
      actions.push(
        residenceActions.addManySaleCategoryFamilySuccess({
          idResidence: residence.idResidence,
          idFamilySaleCategories: ids.familySaleCategories as number[]
        })
      );
    }
  }

  if (ids?.prospectBuyingWishs) {
    if (!Array.isArray(ids.prospectBuyingWishs)) {
      actions.push(
        prospectBuyingWishActions.upsertOneProspectBuyingWish({
          prospectBuyingWish: {
            idResidence: residence.idResidence,
            idProspectBuyingWish: ids.prospectBuyingWishs as number
          } as ProspectBuyingWish & any
        })
      );
      actions.push(
        residenceActions.addManyProspectBuyingWishSuccess({
          idResidence: residence.idResidence,
          idProspectBuyingWishs: [ids.prospectBuyingWishs as number]
        })
      );
    } else {
      actions.push(
        prospectBuyingWishActions.upsertManyProspectBuyingWishs({
          prospectBuyingWishs: (ids.prospectBuyingWishs as number[]).map((idProspectBuyingWish: number) => ({
            idResidence: residence.idResidence,
            idProspectBuyingWish
          })) as ProspectBuyingWish[] & any[]
        })
      );
      actions.push(
        residenceActions.addManyProspectBuyingWishSuccess({
          idResidence: residence.idResidence,
          idProspectBuyingWishs: ids.prospectBuyingWishs as number[]
        })
      );
    }
  }

  if (ids?.companyTerritoires) {
    if (!Array.isArray(ids.companyTerritoires)) {
      actions.push(
        companyTerritoireActions.upsertOneCompanyTerritoire({
          companyTerritoire: {
            idResidence: residence.idResidence,
            idCompanyTerritoire: ids.companyTerritoires as number
          } as CompanyTerritoire & any
        })
      );
      actions.push(
        residenceActions.addManyCompanyTerritoireSuccess({
          idResidence: residence.idResidence,
          idCompanyTerritoires: [ids.companyTerritoires as number]
        })
      );
    } else {
      actions.push(
        companyTerritoireActions.upsertManyCompanyTerritoires({
          companyTerritoires: (ids.companyTerritoires as number[]).map((idCompanyTerritoire: number) => ({
            idResidence: residence.idResidence,
            idCompanyTerritoire
          })) as CompanyTerritoire[] & any[]
        })
      );
      actions.push(
        residenceActions.addManyCompanyTerritoireSuccess({
          idResidence: residence.idResidence,
          idCompanyTerritoires: ids.companyTerritoires as number[]
        })
      );
    }
  }

  if (ids?.residenceForecasts) {
    if (!Array.isArray(ids.residenceForecasts)) {
      actions.push(
        residenceForecastActions.upsertOneResidenceForecast({
          residenceForecast: {
            idResidence: residence.idResidence,
            idResidenceForecast: ids.residenceForecasts as number
          } as ResidenceForecast
        })
      );
      actions.push(
        residenceActions.addManyResidenceForecastSuccess({
          idResidence: residence.idResidence,
          idResidenceForecasts: [ids.residenceForecasts as number]
        })
      );
    } else {
      actions.push(
        residenceForecastActions.upsertManyResidenceForecasts({
          residenceForecasts: (ids.residenceForecasts as number[]).map((idResidenceForecast: number) => ({
            idResidence: residence.idResidence,
            idResidenceForecast
          })) as ResidenceForecast[]
        })
      );
      actions.push(
        residenceActions.addManyResidenceForecastSuccess({
          idResidence: residence.idResidence,
          idResidenceForecasts: ids.residenceForecasts as number[]
        })
      );
    }
  }

  if (ids?.generatedDocumentsResidences) {
    if (!Array.isArray(ids.generatedDocumentsResidences)) {
      actions.push(
        generatedDocumentsResidenceActions.upsertOneGeneratedDocumentsResidence({
          generatedDocumentsResidence: {
            idResidence: residence.idResidence,
            idGeneratedDocumentsResidence: ids.generatedDocumentsResidences as number
          } as GeneratedDocumentsResidence
        })
      );
      actions.push(
        residenceActions.addManyGeneratedDocumentsResidenceSuccess({
          idResidence: residence.idResidence,
          idGeneratedDocumentsResidences: [ids.generatedDocumentsResidences as number]
        })
      );
    } else {
      actions.push(
        generatedDocumentsResidenceActions.upsertManyGeneratedDocumentsResidences({
          generatedDocumentsResidences: (ids.generatedDocumentsResidences as number[]).map(
            (idGeneratedDocumentsResidence: number) => ({
              idResidence: residence.idResidence,
              idGeneratedDocumentsResidence
            })
          ) as GeneratedDocumentsResidence[]
        })
      );
      actions.push(
        residenceActions.addManyGeneratedDocumentsResidenceSuccess({
          idResidence: residence.idResidence,
          idGeneratedDocumentsResidences: ids.generatedDocumentsResidences as number[]
        })
      );
    }
  }

  if (ids?.generatedDocuments) {
    if (!Array.isArray(ids.generatedDocuments)) {
      actions.push(
        generatedDocumentActions.upsertOneGeneratedDocument({
          generatedDocument: {
            idResidence: residence.idResidence,
            idGeneratedDocument: ids.generatedDocuments as number
          } as GeneratedDocument & any
        })
      );
      actions.push(
        residenceActions.addManyGeneratedDocumentSuccess({
          idResidence: residence.idResidence,
          idGeneratedDocuments: [ids.generatedDocuments as number]
        })
      );
    } else {
      actions.push(
        generatedDocumentActions.upsertManyGeneratedDocuments({
          generatedDocuments: (ids.generatedDocuments as number[]).map((idGeneratedDocument: number) => ({
            idResidence: residence.idResidence,
            idGeneratedDocument
          })) as GeneratedDocument[] & any[]
        })
      );
      actions.push(
        residenceActions.addManyGeneratedDocumentSuccess({
          idResidence: residence.idResidence,
          idGeneratedDocuments: ids.generatedDocuments as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteResidenceActions(residence: ResidenceEntityState): Action[] {
  const actions: Action[] = [residenceActions.deleteOneResidenceSuccess({ idResidence: residence.idResidence })];

  if (residence.company) {
    actions.push(
      companyActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idCompanies: [residence.company as number]
      })
    );
  }

  if (residence.responsable) {
    actions.push(
      userActions.deleteManyResidenceSuccess({
        idResidence: [residence.idResidence],
        idUsers: [residence.responsable as number]
      })
    );
  }

  if (residence.organizationForecastRate) {
    actions.push(
      organizationForecastRateActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idOrganizationForecastRates: [residence.organizationForecastRate as number]
      })
    );
  }

  if (residence.city) {
    actions.push(
      cityActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idCities: [residence.city as number]
      })
    );
  }

  if (residence.diffusionVisuals) {
    actions.push(
      diffusionVisualActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idDiffusionVisuals: residence.diffusionVisuals as number[]
      })
    );
  }

  if (residence.residenceTodos) {
    actions.push(
      residenceTodoActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idResidenceTodos: residence.residenceTodos as number[]
      })
    );
  }

  if (residence.residenceWorks) {
    actions.push(
      residenceWorkActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idResidenceWorks: residence.residenceWorks as number[]
      })
    );
  }

  if (residence.residenceSaleCategoryFamilies) {
    actions.push(
      residenceSaleCategoryFamilyActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idResidenceSaleCategoryFamilies: residence.residenceSaleCategoryFamilies as number[]
      })
    );
  }

  if (residence.residenceContacts) {
    actions.push(
      residenceContactActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idResidenceContacts: residence.residenceContacts as number[]
      })
    );
  }

  if (residence.stratalots) {
    actions.push(
      stratalotActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idStratalots: residence.stratalots as number[]
      })
    );
  }

  if (residence.residencePriceGrids) {
    actions.push(
      residencePriceGridActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idResidencePriceGrids: residence.residencePriceGrids as number[]
      })
    );
  }

  if (residence.residenceShares) {
    actions.push(
      residenceShareActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idResidenceShares: residence.residenceShares as number[]
      })
    );
  }

  if (residence.stratalotRcps) {
    actions.push(
      stratalotRcpActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idStratalotRcps: residence.stratalotRcps as number[]
      })
    );
  }

  if (residence.familySaleCategories) {
    actions.push(
      saleCategoryFamilyActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idFamilySaleCategories: residence.familySaleCategories as number[]
      })
    );
  }

  if (residence.prospectBuyingWishs) {
    actions.push(
      prospectBuyingWishActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idProspectBuyingWishs: residence.prospectBuyingWishs as number[]
      })
    );
  }

  if (residence.companyTerritoires) {
    actions.push(
      companyTerritoireActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idCompanyTerritoires: residence.companyTerritoires as number[]
      })
    );
  }

  if (residence.residenceForecasts) {
    actions.push(
      residenceForecastActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idResidenceForecasts: residence.residenceForecasts as number[]
      })
    );
  }

  if (residence.generatedDocumentsResidences) {
    actions.push(
      generatedDocumentsResidenceActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idGeneratedDocumentsResidences: residence.generatedDocumentsResidences as number[]
      })
    );
  }

  if (residence.generatedDocuments) {
    actions.push(
      generatedDocumentActions.deleteManyResidenceSuccess({
        idResidences: [residence.idResidence],
        idGeneratedDocuments: residence.generatedDocuments as number[]
      })
    );
  }

  return actions;
}

export class GeneratedResidenceEffects {
  constructor(
    protected actions$: Actions,
    protected residenceApiService: ResidenceApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceActions.getManyResidences),
      switchMap(({ params }) =>
        this.residenceApiService.getResidences(params).pipe(
          map((residences: Residence[]) => {
            return residenceActions.normalizeManyResidencesAfterUpsert({ residences });
          }),
          catchError(error => of(residenceActions.residencesFailure({ error })))
        )
      )
    );
  });

  getOneResidence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceActions.getOneResidence),
      switchMap(idResidence =>
        this.residenceApiService.getResidence(idResidence).pipe(
          map((residence: Residence) => {
            return residenceActions.normalizeManyResidencesAfterUpsert({ residences: [residence] });
          }),
          catchError(error => of(residenceActions.residencesFailure({ error })))
        )
      )
    );
  });

  upsertOneResidence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceActions.upsertOneResidence),
      concatMap(({ residence, ids }: { residence: Partial<Residence>; ids?: ResidenceRelationsIds }) => {
        if (residence.idResidence) {
          return this.residenceApiService.updateResidence(residence).pipe(
            map((residenceReturned: Residence) => {
              return residenceActions.normalizeManyResidencesAfterUpsert({ residences: [residenceReturned] });
            }),
            catchError(error => of(residenceActions.residencesFailure({ error })))
          );
        } else {
          return this.residenceApiService.addResidence(residence).pipe(
            mergeMap((residenceReturned: Residence) => getDefaultAddResidenceActions(residenceReturned, ids)),
            catchError(error => of(residenceActions.residencesFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneResidence$ = createEffect(() => {
    const selectResidenceState$ = this.store$.select(selectResidenceState);
    return this.actions$.pipe(
      ofType(residenceActions.deleteOneResidence),
      withLatestFrom(selectResidenceState$),
      concatMap(([{ idResidence }, state]) =>
        this.residenceApiService.deleteResidence(idResidence).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidenceActions(state.entities[idResidence] as ResidenceEntityState),
              residenceActions.deleteOneResidence.type
            )
          ]),
          catchError(error => of(residenceActions.residencesFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidencesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceActions.normalizeManyResidencesAfterUpsert),
      concatMap(({ residences }) => {
        const actions: Action[] = getActionsToNormalizeResidence(residences, StoreActionType.upsert);
        return [getMultiAction(actions, '[Residence] Normalization After Upsert Success')];
      })
    );
  });
}
