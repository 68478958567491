import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationBuyingWish } from '@_model/interfaces/organization-buying-wish.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationBuyingWishApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationBuyingWishes(params?: any): Observable<OrganizationBuyingWish[]> {
    return this.repo.getData<OrganizationBuyingWish[]>('organization-buying-wish', params);
  }

  public getOrganizationBuyingWish(params: {
    idOrganizationBuyingWish: number;
    params?: any;
  }): Observable<OrganizationBuyingWish> {
    return this.repo.getData<OrganizationBuyingWish>(
      'organization-buying-wish/' + params.idOrganizationBuyingWish,
      params.params
    );
  }

  public addOrganizationBuyingWish(
    organizationBuyingWish: Partial<OrganizationBuyingWish>
  ): Observable<OrganizationBuyingWish> {
    return this.repo.create<OrganizationBuyingWish>('organization-buying-wish', organizationBuyingWish);
  }

  public updateOrganizationBuyingWish(
    organizationBuyingWish: Partial<OrganizationBuyingWish>
  ): Observable<OrganizationBuyingWish> {
    return this.repo.update('organization-buying-wish', organizationBuyingWish);
  }

  public deleteOrganizationBuyingWish(idOrganizationBuyingWish: number): Observable<number> {
    return this.repo.delete('organization-buying-wish/' + +idOrganizationBuyingWish);
  }
}
