import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyCommunicationRecipientApiService } from '@_services/api/company-communication-recipient-api.service';
import { GeneratedCompanyCommunicationRecipientEffects } from './company-communication-recipient-generated.effects';

@Injectable()
export class CompanyCommunicationRecipientEffects extends GeneratedCompanyCommunicationRecipientEffects {
  constructor(
    actions$: Actions,
    companyCommunicationRecipientApiService: CompanyCommunicationRecipientApiService,
    store$: Store<AppState>
  ) {
    super(actions$, companyCommunicationRecipientApiService, store$);
  }
}
