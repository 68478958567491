import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ResidencePriceGridModifier,
  ResidencePriceGridModifierEntityState
} from '@_model/interfaces/residence-price-grid-modifier.model';
import { ResidencePriceGrid, ResidencePriceGridEntityState } from '@_model/interfaces/residence-price-grid.model';
import { CompanyPriceLabel, CompanyPriceLabelEntityState } from '@_model/interfaces/company-price-label.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  residencePriceGridModifierFeatureKey,
  ResidencePriceGridModifierState
} from './residence-price-grid-modifier.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residencePriceGridModifierRelations: string[] = ['residencePriceGrids', 'companyPriceLabels'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidencePriceGridModifierState = createFeatureSelector<ResidencePriceGridModifierState>(
  residencePriceGridModifierFeatureKey
);

export const selectIsLoadedResidencePriceGridModifier = createSelector(
  selectResidencePriceGridModifierState,
  (state: ResidencePriceGridModifierState) => state.isLoaded
);

export const selectIsLoadingResidencePriceGridModifier = createSelector(
  selectResidencePriceGridModifierState,
  (state: ResidencePriceGridModifierState) => state.isLoading
);

export const selectIsReadyResidencePriceGridModifier = createSelector(
  selectResidencePriceGridModifierState,
  (state: ResidencePriceGridModifierState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidencePriceGridModifier = createSelector(
  selectResidencePriceGridModifierState,
  (state: ResidencePriceGridModifierState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidencePriceGridModifierModel: SelectorModel = {
  name: 'residencePriceGridModifiers',
  getSelector: selectAllResidencePriceGridModifiersDictionary,
  isReady: selectIsReadyResidencePriceGridModifier
};

export const selectResidencePriceGridModifiersEntities = createSelector(
  selectResidencePriceGridModifierState,
  selectEntities
);

export const selectResidencePriceGridModifiersArray = createSelector(selectResidencePriceGridModifierState, selectAll);

export const selectIdResidencePriceGridModifiersActive = createSelector(
  selectResidencePriceGridModifierState,
  (state: ResidencePriceGridModifierState) => state.actives
);

const residencePriceGridModifiersInObject = (
  residencePriceGridModifiers: Dictionary<ResidencePriceGridModifierEntityState>
) => ({ residencePriceGridModifiers });

const selectResidencePriceGridModifiersEntitiesDictionary = createSelector(
  selectResidencePriceGridModifiersEntities,
  residencePriceGridModifiersInObject
);

const selectAllResidencePriceGridModifiersObject = createSelector(
  selectResidencePriceGridModifiersEntities,
  residencePriceGridModifiers => {
    return hydrateAll({ residencePriceGridModifiers });
  }
);

const selectOneResidencePriceGridModifierDictionary = (idResidencePriceGridModifier: number) =>
  createSelector(selectResidencePriceGridModifiersEntities, residencePriceGridModifiers => {
    return {
      residencePriceGridModifiers: {
        [idResidencePriceGridModifier]: residencePriceGridModifiers[idResidencePriceGridModifier]
      }
    };
  });

const selectOneResidencePriceGridModifierDictionaryWithoutChild = (idResidencePriceGridModifier: number) =>
  createSelector(selectResidencePriceGridModifiersEntities, residencePriceGridModifiers => {
    return { residencePriceGridModifier: residencePriceGridModifiers[idResidencePriceGridModifier] };
  });

const selectActiveResidencePriceGridModifiersEntities = createSelector(
  selectIdResidencePriceGridModifiersActive,
  selectResidencePriceGridModifiersEntities,
  (actives: number[], residencePriceGridModifiers: Dictionary<ResidencePriceGridModifierEntityState>) =>
    getResidencePriceGridModifiersFromActives(actives, residencePriceGridModifiers)
);

function getResidencePriceGridModifiersFromActives(
  actives: number[],
  residencePriceGridModifiers: Dictionary<ResidencePriceGridModifierEntityState>
): Dictionary<ResidencePriceGridModifierEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residencePriceGridModifiers[idActive]) {
      acc[idActive] = residencePriceGridModifiers[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidencePriceGridModifierEntityState>);
}

const selectAllResidencePriceGridModifiersSelectors: Dictionary<Selector> = {};
export function selectAllResidencePriceGridModifiers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidencePriceGridModifier>(
      schema,
      selectAllResidencePriceGridModifiersSelectors,
      selectResidencePriceGridModifiersEntitiesDictionary,
      getRelationSelectors,
      residencePriceGridModifierRelations,
      hydrateAll,
      'residencePriceGridModifier'
    );
  } else {
    return selectAllResidencePriceGridModifiersObject;
  }
}

export function selectAllResidencePriceGridModifiersDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residencePriceGridModifiers'
): Selector {
  return createSelector(selectAllResidencePriceGridModifiers(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidencePriceGridModifierEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residencePriceGridModifiers.length; i++) {
      res[customKey][result.residencePriceGridModifiers[i].idResidencePriceGridModifier] =
        result.residencePriceGridModifiers[i];
    }
    return res;
  });
}

export function selectOneResidencePriceGridModifier(
  schema: SelectSchema = {},
  idResidencePriceGridModifier: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidencePriceGridModifierDictionary(idResidencePriceGridModifier)];
    selectors.push(...getRelationSelectors(schema, residencePriceGridModifierRelations, 'residencePriceGridModifier'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidencePriceGridModifierDictionaryWithoutChild(idResidencePriceGridModifier);
  }
}

export function selectActiveResidencePriceGridModifiers(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidencePriceGridModifiersEntities, residencePriceGridModifiers => ({
      residencePriceGridModifiers
    }))
  ];
  selectors.push(...getRelationSelectors(schema, residencePriceGridModifierRelations, 'residencePriceGridModifier'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residencePriceGridModifiers: Dictionary<ResidencePriceGridModifierEntityState>;
  residencePriceGrids?: Dictionary<ResidencePriceGridEntityState>;
  companyPriceLabels?: Dictionary<CompanyPriceLabelEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  residencePriceGridModifiers: (ResidencePriceGridModifier | null)[];
} {
  const { residencePriceGridModifiers, residencePriceGrids, companyPriceLabels } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    residencePriceGridModifiers: Object.keys(residencePriceGridModifiers).map(idResidencePriceGridModifier =>
      hydrate(
        residencePriceGridModifiers[idResidencePriceGridModifier] as ResidencePriceGridModifierEntityState,
        residencePriceGrids,
        companyPriceLabels
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  residencePriceGridModifier: ResidencePriceGridModifierEntityState | null;
} {
  const { residencePriceGridModifiers, residencePriceGrids, companyPriceLabels } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const residencePriceGridModifier = Object.values(residencePriceGridModifiers)[0];
  return {
    residencePriceGridModifier: hydrate(
      residencePriceGridModifier as ResidencePriceGridModifierEntityState,
      residencePriceGrids,
      companyPriceLabels
    )
  };
}

function hydrate(
  residencePriceGridModifier: ResidencePriceGridModifierEntityState,
  residencePriceGridEntities?: Dictionary<ResidencePriceGridEntityState>,
  companyPriceLabelEntities?: Dictionary<CompanyPriceLabelEntityState>
): ResidencePriceGridModifier | null {
  if (!residencePriceGridModifier) {
    return null;
  }

  const residencePriceGridModifierHydrated: ResidencePriceGridModifierEntityState = { ...residencePriceGridModifier };
  if (residencePriceGridEntities) {
    residencePriceGridModifierHydrated.residencePriceGrid = residencePriceGridEntities[
      residencePriceGridModifier.residencePriceGrid as number
    ] as ResidencePriceGrid;
  } else {
    delete residencePriceGridModifierHydrated.residencePriceGrid;
  }
  if (companyPriceLabelEntities) {
    residencePriceGridModifierHydrated.companyPriceLabel = companyPriceLabelEntities[
      residencePriceGridModifier.companyPriceLabel as number
    ] as CompanyPriceLabel;
  } else {
    delete residencePriceGridModifierHydrated.companyPriceLabel;
  }

  return residencePriceGridModifierHydrated as ResidencePriceGridModifier;
}
