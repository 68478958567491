import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationMetier } from '@_model/interfaces/organization-metier.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationMetierApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationMetiers(params?: any): Observable<OrganizationMetier[]> {
    return this.repo.getData<OrganizationMetier[]>('organization-metier', params);
  }

  public getOrganizationMetier(params: { idOrganizationMetier: number; params?: any }): Observable<OrganizationMetier> {
    return this.repo.getData<OrganizationMetier>('organization-metier/' + params.idOrganizationMetier, params.params);
  }

  public addOrganizationMetier(organizationMetier: Partial<OrganizationMetier>): Observable<OrganizationMetier> {
    return this.repo.create<OrganizationMetier>('organization-metier', organizationMetier);
  }

  public updateOrganizationMetier(organizationMetier: Partial<OrganizationMetier>): Observable<OrganizationMetier> {
    return this.repo.update('organization-metier', organizationMetier);
  }

  public deleteOrganizationMetier(idOrganizationMetier: number): Observable<number> {
    return this.repo.delete('organization-metier/' + +idOrganizationMetier);
  }
}
