import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationStratalotTodoRuleActions from './organization-stratalot-todo-rule.actions';
import { adapter, initialState, OrganizationStratalotTodoRuleState } from './organization-stratalot-todo-rule.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationStratalotTodoRuleEntityState } from '@_model/interfaces/organization-stratalot-todo-rule.model';

export const organizationStratalotTodoRuleReducersGeneratedFunctions: ReducerTypes<
  OrganizationStratalotTodoRuleState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationStratalotTodoRuleActions.getOneOrganizationStratalotTodoRule,
    (state: OrganizationStratalotTodoRuleState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationStratalotTodoRuleActions.getManyOrganizationStratalotTodoRules,
    (state: OrganizationStratalotTodoRuleState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationStratalotTodoRuleActions.upsertOneOrganizationStratalotTodoRule,
    (state: OrganizationStratalotTodoRuleState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationStratalotTodoRuleActions.upsertManyOrganizationStratalotTodoRulesSuccess,
    (state: OrganizationStratalotTodoRuleState, { organizationStratalotTodoRules }) =>
      adapter.upsertMany(organizationStratalotTodoRules, setLoadingsState(state, false))
  ),
  on(
    OrganizationStratalotTodoRuleActions.deleteOneOrganizationStratalotTodoRule,
    (state: OrganizationStratalotTodoRuleState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationStratalotTodoRuleActions.deleteOneOrganizationStratalotTodoRuleSuccess,
    (state: OrganizationStratalotTodoRuleState, { idOrganizationStratalotTodoRule }) =>
      adapter.removeOne(idOrganizationStratalotTodoRule, setLoadingsState(state, false))
  ),
  on(OrganizationStratalotTodoRuleActions.clearActive, (state: OrganizationStratalotTodoRuleState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationStratalotTodoRuleActions.addManyActives,
    (state: OrganizationStratalotTodoRuleState, { idOrganizationStratalotTodoRules }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationStratalotTodoRules)
    })
  ),
  on(
    OrganizationStratalotTodoRuleActions.deleteOneActive,
    (state: OrganizationStratalotTodoRuleState, { idOrganizationStratalotTodoRule }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationStratalotTodoRule)
    })
  ),

  on(OrganizationStratalotTodoRuleActions.clearStore, () => initialState),

  on(
    OrganizationStratalotTodoRuleActions.addManyOrganizationStratalotTodoSuccess,
    (state: OrganizationStratalotTodoRuleState, { idOrganizationStratalotTodoRule, idOrganizationStratalotTodos }) => {
      if (!state.entities[idOrganizationStratalotTodoRule]) {
        return state;
      }
      const organizationStratalotTodos =
        (state.entities[idOrganizationStratalotTodoRule]?.organizationStratalotTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodoRule]: {
            ...state.entities[idOrganizationStratalotTodoRule],
            organizationStratalotTodos: organizationStratalotTodos.concat(
              idOrganizationStratalotTodos.filter(id => organizationStratalotTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoRuleActions.deleteManyOrganizationStratalotTodoSuccess,
    (state: OrganizationStratalotTodoRuleState, { idOrganizationStratalotTodos, idOrganizationStratalotTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodoRules.reduce((entities, idOrganizationStratalotTodoRule) => {
            if (!state.entities[idOrganizationStratalotTodoRule]?.organizationStratalotTodos) {
              return entities;
            }
            entities[idOrganizationStratalotTodoRule] = {
              ...state.entities[idOrganizationStratalotTodoRule],
              organizationStratalotTodos: (
                state.entities[idOrganizationStratalotTodoRule]?.organizationStratalotTodos as number[]
              )?.filter(
                (idOrganizationStratalotTodo: number) =>
                  !idOrganizationStratalotTodos.some((id: number) => id === idOrganizationStratalotTodo)
              )
            } as OrganizationStratalotTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoRuleActions.addManyOrganizationStratalotTodoAppliedRuleSuccess,
    (
      state: OrganizationStratalotTodoRuleState,
      { idOrganizationStratalotTodoRule, idOrganizationStratalotTodoAppliedRules }
    ) => {
      if (!state.entities[idOrganizationStratalotTodoRule]) {
        return state;
      }
      const organizationStratalotTodoAppliedRules =
        (state.entities[idOrganizationStratalotTodoRule]?.organizationStratalotTodoAppliedRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodoRule]: {
            ...state.entities[idOrganizationStratalotTodoRule],
            organizationStratalotTodoAppliedRules: organizationStratalotTodoAppliedRules.concat(
              idOrganizationStratalotTodoAppliedRules.filter(
                id => organizationStratalotTodoAppliedRules.indexOf(id) < 0
              )
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoRuleActions.deleteManyOrganizationStratalotTodoAppliedRuleSuccess,
    (
      state: OrganizationStratalotTodoRuleState,
      { idOrganizationStratalotTodoAppliedRules, idOrganizationStratalotTodoRules }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodoRules.reduce((entities, idOrganizationStratalotTodoRule) => {
            if (!state.entities[idOrganizationStratalotTodoRule]?.organizationStratalotTodoAppliedRules) {
              return entities;
            }
            entities[idOrganizationStratalotTodoRule] = {
              ...state.entities[idOrganizationStratalotTodoRule],
              organizationStratalotTodoAppliedRules: (
                state.entities[idOrganizationStratalotTodoRule]?.organizationStratalotTodoAppliedRules as number[]
              )?.filter(
                (idOrganizationStratalotTodoAppliedRule: number) =>
                  !idOrganizationStratalotTodoAppliedRules.some(
                    (id: number) => id === idOrganizationStratalotTodoAppliedRule
                  )
              )
            } as OrganizationStratalotTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoRuleActions.addOrganizationSuccess,
    (state: OrganizationStratalotTodoRuleState, { idOrganizationStratalotTodoRule, idOrganization }) => {
      if (!state.entities[idOrganizationStratalotTodoRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodoRule]: {
            ...state.entities[idOrganizationStratalotTodoRule],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoRuleActions.deleteManyOrganizationSuccess,
    (state: OrganizationStratalotTodoRuleState, { idOrganizations, idOrganizationStratalotTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodoRules.reduce((entities, idOrganizationStratalotTodoRule) => {
            if (!state.entities[idOrganizationStratalotTodoRule]?.organization) {
              return entities;
            }
            entities[idOrganizationStratalotTodoRule] = {
              ...state.entities[idOrganizationStratalotTodoRule],
              organization: idOrganizations.some(
                (idOrganization: number) =>
                  idOrganization === state.entities[idOrganizationStratalotTodoRule]?.organization
              )
                ? undefined
                : state.entities[idOrganizationStratalotTodoRule]?.organization
            } as OrganizationStratalotTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoRuleActions.addOrganizationSaleCategorySuccess,
    (state: OrganizationStratalotTodoRuleState, { idOrganizationStratalotTodoRule, idOrganizationSaleCategory }) => {
      if (!state.entities[idOrganizationStratalotTodoRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodoRule]: {
            ...state.entities[idOrganizationStratalotTodoRule],
            organizationSaleCategory: idOrganizationSaleCategory
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoRuleActions.deleteManyOrganizationSaleCategorySuccess,
    (state: OrganizationStratalotTodoRuleState, { idOrganizationSaleCategories, idOrganizationStratalotTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodoRules.reduce((entities, idOrganizationStratalotTodoRule) => {
            if (!state.entities[idOrganizationStratalotTodoRule]?.organizationSaleCategory) {
              return entities;
            }
            entities[idOrganizationStratalotTodoRule] = {
              ...state.entities[idOrganizationStratalotTodoRule],
              organizationSaleCategory: idOrganizationSaleCategories.some(
                (idOrganizationSaleCategory: number) =>
                  idOrganizationSaleCategory ===
                  state.entities[idOrganizationStratalotTodoRule]?.organizationSaleCategory
              )
                ? undefined
                : state.entities[idOrganizationStratalotTodoRule]?.organizationSaleCategory
            } as OrganizationStratalotTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoRuleActions.addSaleCategoryFamilySuccess,
    (state: OrganizationStratalotTodoRuleState, { idOrganizationStratalotTodoRule, idSaleCategoryFamily }) => {
      if (!state.entities[idOrganizationStratalotTodoRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodoRule]: {
            ...state.entities[idOrganizationStratalotTodoRule],
            saleCategoryFamily: idSaleCategoryFamily
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoRuleActions.deleteManySaleCategoryFamilySuccess,
    (state: OrganizationStratalotTodoRuleState, { idFamilySaleCategories, idOrganizationStratalotTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodoRules.reduce((entities, idOrganizationStratalotTodoRule) => {
            if (!state.entities[idOrganizationStratalotTodoRule]?.saleCategoryFamily) {
              return entities;
            }
            entities[idOrganizationStratalotTodoRule] = {
              ...state.entities[idOrganizationStratalotTodoRule],
              saleCategoryFamily: idFamilySaleCategories.some(
                (idSaleCategoryFamily: number) =>
                  idSaleCategoryFamily === state.entities[idOrganizationStratalotTodoRule]?.saleCategoryFamily
              )
                ? undefined
                : state.entities[idOrganizationStratalotTodoRule]?.saleCategoryFamily
            } as OrganizationStratalotTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoRuleActions.addCompanySuccess,
    (state: OrganizationStratalotTodoRuleState, { idOrganizationStratalotTodoRule, idCompany }) => {
      if (!state.entities[idOrganizationStratalotTodoRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodoRule]: {
            ...state.entities[idOrganizationStratalotTodoRule],
            company: idCompany
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoRuleActions.deleteManyCompanySuccess,
    (state: OrganizationStratalotTodoRuleState, { idCompanies, idOrganizationStratalotTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodoRules.reduce((entities, idOrganizationStratalotTodoRule) => {
            if (!state.entities[idOrganizationStratalotTodoRule]?.company) {
              return entities;
            }
            entities[idOrganizationStratalotTodoRule] = {
              ...state.entities[idOrganizationStratalotTodoRule],
              company: idCompanies.some(
                (idCompany: number) => idCompany === state.entities[idOrganizationStratalotTodoRule]?.company
              )
                ? undefined
                : state.entities[idOrganizationStratalotTodoRule]?.company
            } as OrganizationStratalotTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoRuleActions.addCompanyStratalotTypeSuccess,
    (state: OrganizationStratalotTodoRuleState, { idOrganizationStratalotTodoRule, idCompanyStratalotType }) => {
      if (!state.entities[idOrganizationStratalotTodoRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodoRule]: {
            ...state.entities[idOrganizationStratalotTodoRule],
            companyStratalotType: idCompanyStratalotType
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoRuleActions.deleteManyCompanyStratalotTypeSuccess,
    (state: OrganizationStratalotTodoRuleState, { idCompanyStratalotTypes, idOrganizationStratalotTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodoRules.reduce((entities, idOrganizationStratalotTodoRule) => {
            if (!state.entities[idOrganizationStratalotTodoRule]?.companyStratalotType) {
              return entities;
            }
            entities[idOrganizationStratalotTodoRule] = {
              ...state.entities[idOrganizationStratalotTodoRule],
              companyStratalotType: idCompanyStratalotTypes.some(
                (idCompanyStratalotType: number) =>
                  idCompanyStratalotType === state.entities[idOrganizationStratalotTodoRule]?.companyStratalotType
              )
                ? undefined
                : state.entities[idOrganizationStratalotTodoRule]?.companyStratalotType
            } as OrganizationStratalotTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoRuleActions.addStratalotTypeSuccess,
    (state: OrganizationStratalotTodoRuleState, { idOrganizationStratalotTodoRule, idStratalotType }) => {
      if (!state.entities[idOrganizationStratalotTodoRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodoRule]: {
            ...state.entities[idOrganizationStratalotTodoRule],
            stratalotType: idStratalotType
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoRuleActions.deleteManyStratalotTypeSuccess,
    (state: OrganizationStratalotTodoRuleState, { idStratalotTypes, idOrganizationStratalotTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodoRules.reduce((entities, idOrganizationStratalotTodoRule) => {
            if (!state.entities[idOrganizationStratalotTodoRule]?.stratalotType) {
              return entities;
            }
            entities[idOrganizationStratalotTodoRule] = {
              ...state.entities[idOrganizationStratalotTodoRule],
              stratalotType: idStratalotTypes.some(
                (idStratalotType: number) =>
                  idStratalotType === state.entities[idOrganizationStratalotTodoRule]?.stratalotType
              )
                ? undefined
                : state.entities[idOrganizationStratalotTodoRule]?.stratalotType
            } as OrganizationStratalotTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoRuleEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationStratalotTodoRuleState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationStratalotTodoRuleState {
  return { ...state, isLoaded, isLoading };
}
