import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  ResidenceStudyFounding,
  ResidenceStudyFoundingEntityState
} from '@_model/interfaces/residence-study-founding.model';
import { ResidenceStudyFoundingApiService } from '@_services/api/residence-study-founding-api.service';
import * as residenceStudyFoundingActions from '@_store/residence-study-founding/residence-study-founding.actions';
import { getActionsToNormalizeResidenceStudyFounding } from '@_config/store/normalization.generated';
import { selectResidenceStudyFoundingState } from './residence-study-founding-generated.selectors';
import * as residenceStudyActions from '@_store/residence-study/residence-study.actions';

export interface ResidenceStudyFoundingRelationsIds {
  residenceStudy?: number;
}

export function getDefaultAddResidenceStudyFoundingActions(
  residenceStudyFounding: ResidenceStudyFoundingEntityState,
  ids?: ResidenceStudyFoundingRelationsIds
): Action[] {
  const actions: Action[] = [
    residenceStudyFoundingActions.normalizeManyResidenceStudyFoundingsAfterUpsert({
      residenceStudyFoundings: [residenceStudyFounding]
    })
  ];

  if (ids?.residenceStudy) {
    actions.push(
      residenceStudyActions.addManyResidenceStudyFoundingSuccess({
        idResidenceStudy: ids.residenceStudy,
        idResidenceStudyFoundings: [residenceStudyFounding.idResidenceStudyFounding]
      })
    );
    actions.push(
      residenceStudyFoundingActions.addResidenceStudySuccess({
        idResidenceStudyFounding: residenceStudyFounding.idResidenceStudyFounding,
        idResidenceStudy: ids.residenceStudy
      })
    );
  }

  return actions;
}

export function getDefaultDeleteResidenceStudyFoundingActions(
  residenceStudyFounding: ResidenceStudyFoundingEntityState
): Action[] {
  const actions: Action[] = [
    residenceStudyFoundingActions.deleteOneResidenceStudyFoundingSuccess({
      idResidenceStudyFounding: residenceStudyFounding.idResidenceStudyFounding
    })
  ];

  if (residenceStudyFounding.residenceStudy) {
    actions.push(
      residenceStudyActions.deleteManyResidenceStudyFoundingSuccess({
        idResidenceStudyFoundings: [residenceStudyFounding.idResidenceStudyFounding],
        idResidenceStudies: [residenceStudyFounding.residenceStudy as number]
      })
    );
  }

  return actions;
}

export class GeneratedResidenceStudyFoundingEffects {
  constructor(
    protected actions$: Actions,
    protected residenceStudyFoundingApiService: ResidenceStudyFoundingApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidenceStudyFoundings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyFoundingActions.getManyResidenceStudyFoundings),
      switchMap(({ params }) =>
        this.residenceStudyFoundingApiService.getResidenceStudyFoundings(params).pipe(
          map((residenceStudyFoundings: ResidenceStudyFounding[]) => {
            return residenceStudyFoundingActions.normalizeManyResidenceStudyFoundingsAfterUpsert({
              residenceStudyFoundings
            });
          }),
          catchError(error => of(residenceStudyFoundingActions.residenceStudyFoundingsFailure({ error })))
        )
      )
    );
  });

  getOneResidenceStudyFounding$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyFoundingActions.getOneResidenceStudyFounding),
      switchMap(idResidenceStudyFounding =>
        this.residenceStudyFoundingApiService.getResidenceStudyFounding(idResidenceStudyFounding).pipe(
          map((residenceStudyFounding: ResidenceStudyFounding) => {
            return residenceStudyFoundingActions.normalizeManyResidenceStudyFoundingsAfterUpsert({
              residenceStudyFoundings: [residenceStudyFounding]
            });
          }),
          catchError(error => of(residenceStudyFoundingActions.residenceStudyFoundingsFailure({ error })))
        )
      )
    );
  });

  upsertOneResidenceStudyFounding$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyFoundingActions.upsertOneResidenceStudyFounding),
      concatMap(
        ({
          residenceStudyFounding,
          ids
        }: {
          residenceStudyFounding: Partial<ResidenceStudyFounding>;
          ids?: ResidenceStudyFoundingRelationsIds;
        }) => {
          if (residenceStudyFounding.idResidenceStudyFounding) {
            return this.residenceStudyFoundingApiService.updateResidenceStudyFounding(residenceStudyFounding).pipe(
              map((residenceStudyFoundingReturned: ResidenceStudyFounding) => {
                return residenceStudyFoundingActions.normalizeManyResidenceStudyFoundingsAfterUpsert({
                  residenceStudyFoundings: [residenceStudyFoundingReturned]
                });
              }),
              catchError(error => of(residenceStudyFoundingActions.residenceStudyFoundingsFailure({ error })))
            );
          } else {
            return this.residenceStudyFoundingApiService.addResidenceStudyFounding(residenceStudyFounding).pipe(
              mergeMap((residenceStudyFoundingReturned: ResidenceStudyFounding) =>
                getDefaultAddResidenceStudyFoundingActions(residenceStudyFoundingReturned, ids)
              ),
              catchError(error => of(residenceStudyFoundingActions.residenceStudyFoundingsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneResidenceStudyFounding$ = createEffect(() => {
    const selectResidenceStudyFoundingState$ = this.store$.select(selectResidenceStudyFoundingState);
    return this.actions$.pipe(
      ofType(residenceStudyFoundingActions.deleteOneResidenceStudyFounding),
      withLatestFrom(selectResidenceStudyFoundingState$),
      concatMap(([{ idResidenceStudyFounding }, state]) =>
        this.residenceStudyFoundingApiService.deleteResidenceStudyFounding(idResidenceStudyFounding).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidenceStudyFoundingActions(
                state.entities[idResidenceStudyFounding] as ResidenceStudyFoundingEntityState
              ),
              residenceStudyFoundingActions.deleteOneResidenceStudyFounding.type
            )
          ]),
          catchError(error => of(residenceStudyFoundingActions.residenceStudyFoundingsFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidenceStudyFoundingsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyFoundingActions.normalizeManyResidenceStudyFoundingsAfterUpsert),
      concatMap(({ residenceStudyFoundings }) => {
        const actions: Action[] = getActionsToNormalizeResidenceStudyFounding(
          residenceStudyFoundings,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[ResidenceStudyFounding] Normalization After Upsert Success')];
      })
    );
  });
}
