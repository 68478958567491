import { ResidenceStatutEnum } from '@_shared/models/enums/residence-statut.enum';
import { StepProgressName } from '@_shared/models/enums/step-progress-name.enum';
import { StratalotTypeAggrege } from '@_shared/enum/stratalot-type-aggrege';
import { Residence } from '@_shared/models/interfaces/residence.model';
import { Stratalot } from '@_shared/models/interfaces/stratalot.model';
import { checkstringBelongto } from './arrays';
import { StockAggridDataRow } from '@_shared/interfaces/stock-aggrid-data-row.interface';
import { DashboardStockAgGridRowType } from '@_shared/enum/dashboard-stock-aggrid-row-type.enum';

import { LeadActivityDataRow } from '@_shared/dumb/lead-activity-board-display/data-row/lead-activity-display-board.data-row';
import { LeadAvancementGroup, LeadAvancementGroupEnum } from '@_shared/enum/LeadAvancementGroupEnum';
import { LeadAvancementEnum } from '@_shared/models/enums/lead-statut-avancement.enum';
import { Lead } from '@_shared/models/interfaces/lead.model';
import { FreeStratalotDataRow } from '@_shared/models/interfaces/agGrid/data-row/free-stratalot-data-row';
import { GraphPoint } from '@_shared/models/interfaces/graph-point.interface';
namespace SortUtils {
  const statutOrder: ResidenceStatutEnum[] = [
    ResidenceStatutEnum.enEtude,
    ResidenceStatutEnum.enProjet,
    ResidenceStatutEnum.enMontage,
    ResidenceStatutEnum.enVente,
    ResidenceStatutEnum.vendu,
    ResidenceStatutEnum.suspendu,
    ResidenceStatutEnum.abandonne,
    ResidenceStatutEnum.termine
  ];
  const leadAvancementGroup: LeadAvancementGroup[] = [
    LeadAvancementGroupEnum.vendu,
    LeadAvancementEnum.acteSigne,
    LeadAvancementEnum.acteAncienPlanifie,
    LeadAvancementEnum.compromisSigne,
    LeadAvancementEnum.compromisPlanifie,
    LeadAvancementEnum.offreAcceptee,
    LeadAvancementEnum.offreExamineeNonRetenue,
    LeadAvancementEnum.offreSoumiseValidation,
    LeadAvancementEnum.depotOffre,
    LeadAvancementEnum.premierContact,
    LeadAvancementEnum.pasDate,
    LeadAvancementGroupEnum.sansSuite,
    LeadAvancementGroupEnum.statutIndetermine
  ];
  const stepProgressNameForFreeStratalot: StepProgressName[] = [
    StepProgressName.disponible,
    StepProgressName.disponibleAvecProspects,
    StepProgressName.disponibleAvecOffres,
    StepProgressName.conge,
    StepProgressName.nonDisponible
  ];
  const stepProgressNameForStratalotInProgram: StepProgressName[] = [
    StepProgressName.nonDisponible,
    StepProgressName.disponible,
    StepProgressName.disponibleAvecProspects,
    StepProgressName.disponibleAvecOffres,
    StepProgressName.resilie,
    StepProgressName.conge
  ];
  const stockAggridDataRowType: DashboardStockAgGridRowType[] = [
    DashboardStockAgGridRowType.enVenteLibre,
    DashboardStockAgGridRowType.enVenteOccupe,
    DashboardStockAgGridRowType.enVenteBloque,
    DashboardStockAgGridRowType.vendu,
    DashboardStockAgGridRowType.enVente,
    DashboardStockAgGridRowType.termine,
    DashboardStockAgGridRowType.suspendu,
    DashboardStockAgGridRowType.abandonne,
    DashboardStockAgGridRowType.enMontage,
    DashboardStockAgGridRowType.enEtude,
    DashboardStockAgGridRowType.enProjet,
    DashboardStockAgGridRowType.total
  ];
  const leadStatutAvancementSortByAccending: LeadAvancementEnum[] = [
    LeadAvancementEnum.reservation,
    LeadAvancementEnum.pasDate,
    LeadAvancementEnum.premierContact,
    LeadAvancementEnum.depotOffre,
    LeadAvancementEnum.resiliation,
    LeadAvancementEnum.resiliationPlanifiee,
    LeadAvancementEnum.nonLeveeOption,
    LeadAvancementEnum.nonLeveeOptionPlanifiee,
    LeadAvancementEnum.offreSoumiseValidation,
    LeadAvancementEnum.offreExamineeNonRetenue,
    LeadAvancementEnum.offreAcceptee,
    LeadAvancementEnum.compromisPlanifie,
    LeadAvancementEnum.compromisSigne,
    LeadAvancementEnum.contratLocationAccession,
    LeadAvancementEnum.contratLocationAccessionPlanifie,
    LeadAvancementEnum.leveeOption,
    LeadAvancementEnum.leveeOptionPlanifiee,
    LeadAvancementEnum.acteAncienPlanifie,
    LeadAvancementEnum.acteSigne,
    LeadAvancementEnum.venteArchivee
  ];
  const stratalotTypelot: string[] = [StratalotTypeAggrege.lotsPrincipaux, StratalotTypeAggrege.lotsAnnexes];
  const monthOrder: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  export function sortbyMonth(a: GraphPoint, b: GraphPoint): 1 | 0 | -1 {
    if (!checkstringBelongto(a.month, monthOrder) || !checkstringBelongto(b.month, monthOrder)) {
      throw new Error(`${a} ou ${b} non présent dans tri  personnalisé`);
    }
    const result = monthOrder.findIndex(month => month === a.month) - monthOrder.findIndex(month => month === b.month);
    if (result > 0) {
      return 1;
    }
    if (result === 0) {
      return 0;
    }
    return -1;
  }
  export function sortbyResidenceStatus(a: Residence, b: Residence): 1 | 0 | -1 {
    if (!checkstringBelongto(a.statut, statutOrder) || !checkstringBelongto(b.statut, statutOrder)) {
      throw new Error(`${a.statut} ou ${b.statut} non présent dans tri  personnalisé`);
    }
    const result =
      statutOrder.findIndex(status => status === a.statut) - statutOrder.findIndex(status => status === b.statut);
    if (result > 0) {
      return 1;
    }
    if (result === 0) {
      return 0;
    }
    return -1;
  }

  export function sortbyCompanyTypelot(a: FreeStratalotDataRow, b: FreeStratalotDataRow): 1 | 0 | -1 {
    const aTypeLot = a.stratalotTypeAggrege;
    const bTypeLot = b.stratalotTypeAggrege;
    if (!checkstringBelongto(aTypeLot, stratalotTypelot) || !checkstringBelongto(bTypeLot, stratalotTypelot)) {
      throw new Error(`${aTypeLot} ou ${bTypeLot} non présent dans filtre personnalisé`);
    }
    const result =
      stratalotTypelot.findIndex(typeLot => typeLot === aTypeLot) -
      stratalotTypelot.findIndex(typelot => typelot === bTypeLot);
    if (result > 0) {
      return 1;
    }
    if (result === 0) {
      return 0;
    }
    return -1;
  }
  export function sortbyStepProgressNameForFreeStratalot(a: Stratalot, b: Stratalot): 1 | 0 | -1 {
    if (
      !checkstringBelongto(a.stepProgress?.libelle, stepProgressNameForFreeStratalot) ||
      !checkstringBelongto(b.stepProgress?.libelle, stepProgressNameForFreeStratalot)
    ) {
      throw new Error(`${a.stepProgress?.libelle} ou ${b.stepProgress?.libelle} non présent dans filtre personnalisé`);
    }
    const result =
      stepProgressNameForFreeStratalot.findIndex(typeLot => typeLot === a.stepProgress?.libelle) -
      stepProgressNameForFreeStratalot.findIndex(typelot => typelot === b.stepProgress?.libelle);
    if (result > 0) {
      return 1;
    }
    if (result === 0) {
      return 0;
    }
    return -1;
  }
  export function sortbyStepProgressNameForStratalotInProgram(a: Stratalot, b: Stratalot): 1 | 0 | -1 {
    if (
      !checkstringBelongto(a.stepProgress?.libelle, stepProgressNameForStratalotInProgram) ||
      !checkstringBelongto(b.stepProgress?.libelle, stepProgressNameForStratalotInProgram)
    ) {
      throw new Error(`${a.stepProgress?.libelle} ou ${b.stepProgress?.libelle} non présent dans filtre personnalisé`);
    }
    const result =
      stepProgressNameForStratalotInProgram.findIndex(typeLot => typeLot === a.stepProgress?.libelle) -
      stepProgressNameForStratalotInProgram.findIndex(typelot => typelot === b.stepProgress?.libelle);
    if (result > 0) {
      return 1;
    }
    if (result === 0) {
      return 0;
    }
    return -1;
  }

  export function sortByLeadAvancementGroup(a: LeadActivityDataRow, b: LeadActivityDataRow): 1 | 0 | -1 {
    if (
      !checkstringBelongto(a.regroupementNiveau1, leadAvancementGroup) ||
      !checkstringBelongto(b.regroupementNiveau1, leadAvancementGroup)
    ) {
      throw new Error(`${a.regroupementNiveau1} ou ${b.regroupementNiveau1} non présent dans filtre personnalisé`);
    }
    const result =
      leadAvancementGroup.findIndex(typeLot => typeLot === a.regroupementNiveau1) -
      leadAvancementGroup.findIndex(typelot => typelot === b.regroupementNiveau1);
    if (result > 0) {
      return 1;
    }
    if (result === 0) {
      return 0;
    }
    return -1;
  }

  export function sortbyRowType(a: StockAggridDataRow, b: StockAggridDataRow): 1 | 0 | -1 {
    if (!checkstringBelongto(a.type, stockAggridDataRowType) || !checkstringBelongto(b.type, stockAggridDataRowType)) {
      throw new Error(`${a.type} ou ${b.type} non présent dans tri personnalisé`);
    }
    const result =
      stockAggridDataRowType.findIndex(typeLot => typeLot === a.type) -
      stockAggridDataRowType.findIndex(typelot => typelot === b.type);
    if (result > 0) {
      return 1;
    }
    if (result === 0) {
      return 0;
    }
    return -1;
  }
  export function sortByLeadAvancement(a: Lead, b: Lead): 1 | 0 | -1 {
    if (
      !checkstringBelongto(a.statutAvancement, leadStatutAvancementSortByAccending) ||
      !checkstringBelongto(b.statutAvancement, leadStatutAvancementSortByAccending)
    ) {
      throw new Error(`${a.statutAvancement} ou ${b.statutAvancement} non présent dans filtre personnalisé`);
    }
    const result =
      leadStatutAvancementSortByAccending.findIndex(typeLot => typeLot === a.statutAvancement) -
      leadStatutAvancementSortByAccending.findIndex(typelot => typelot === b.statutAvancement);
    if (result > 0) {
      return 1;
    }
    if (result === 0) {
      return 0;
    }
    return -1;
  }
}
export default SortUtils;
