import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LeadStratalot, LeadStratalotEntityState } from '@_model/interfaces/lead-stratalot.model';
import { Lead, LeadEntityState } from '@_model/interfaces/lead.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, leadStratalotFeatureKey, LeadStratalotState } from './lead-stratalot.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const leadStratalotRelations: string[] = ['leads', 'stratalots'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectLeadStratalotState = createFeatureSelector<LeadStratalotState>(leadStratalotFeatureKey);

export const selectIsLoadedLeadStratalot = createSelector(
  selectLeadStratalotState,
  (state: LeadStratalotState) => state.isLoaded
);

export const selectIsLoadingLeadStratalot = createSelector(
  selectLeadStratalotState,
  (state: LeadStratalotState) => state.isLoading
);

export const selectIsReadyLeadStratalot = createSelector(
  selectLeadStratalotState,
  (state: LeadStratalotState) => !state.isLoading
);

export const selectIsReadyAndLoadedLeadStratalot = createSelector(
  selectLeadStratalotState,
  (state: LeadStratalotState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const LeadStratalotModel: SelectorModel = {
  name: 'leadStratalots',
  getSelector: selectAllLeadStratalotsDictionary,
  isReady: selectIsReadyLeadStratalot
};

export const selectLeadStratalotsEntities = createSelector(selectLeadStratalotState, selectEntities);

export const selectLeadStratalotsArray = createSelector(selectLeadStratalotState, selectAll);

export const selectIdLeadStratalotsActive = createSelector(
  selectLeadStratalotState,
  (state: LeadStratalotState) => state.actives
);

const leadStratalotsInObject = (leadStratalots: Dictionary<LeadStratalotEntityState>) => ({ leadStratalots });

const selectLeadStratalotsEntitiesDictionary = createSelector(selectLeadStratalotsEntities, leadStratalotsInObject);

const selectAllLeadStratalotsObject = createSelector(selectLeadStratalotsEntities, leadStratalots => {
  return hydrateAll({ leadStratalots });
});

const selectOneLeadStratalotDictionary = (idLeadStratalot: number) =>
  createSelector(selectLeadStratalotsEntities, leadStratalots => {
    return { leadStratalots: { [idLeadStratalot]: leadStratalots[idLeadStratalot] } };
  });

const selectOneLeadStratalotDictionaryWithoutChild = (idLeadStratalot: number) =>
  createSelector(selectLeadStratalotsEntities, leadStratalots => {
    return { leadStratalot: leadStratalots[idLeadStratalot] };
  });

const selectActiveLeadStratalotsEntities = createSelector(
  selectIdLeadStratalotsActive,
  selectLeadStratalotsEntities,
  (actives: number[], leadStratalots: Dictionary<LeadStratalotEntityState>) =>
    getLeadStratalotsFromActives(actives, leadStratalots)
);

function getLeadStratalotsFromActives(
  actives: number[],
  leadStratalots: Dictionary<LeadStratalotEntityState>
): Dictionary<LeadStratalotEntityState> {
  return actives.reduce((acc, idActive) => {
    if (leadStratalots[idActive]) {
      acc[idActive] = leadStratalots[idActive];
    }
    return acc;
  }, {} as Dictionary<LeadStratalotEntityState>);
}

const selectAllLeadStratalotsSelectors: Dictionary<Selector> = {};
export function selectAllLeadStratalots(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<LeadStratalot>(
      schema,
      selectAllLeadStratalotsSelectors,
      selectLeadStratalotsEntitiesDictionary,
      getRelationSelectors,
      leadStratalotRelations,
      hydrateAll,
      'leadStratalot'
    );
  } else {
    return selectAllLeadStratalotsObject;
  }
}

export function selectAllLeadStratalotsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'leadStratalots'
): Selector {
  return createSelector(selectAllLeadStratalots(schema), result => {
    const res = { [customKey]: {} as Dictionary<LeadStratalotEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.leadStratalots.length; i++) {
      res[customKey][result.leadStratalots[i].idLeadStratalot] = result.leadStratalots[i];
    }
    return res;
  });
}

export function selectOneLeadStratalot(schema: SelectSchema = {}, idLeadStratalot: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneLeadStratalotDictionary(idLeadStratalot)];
    selectors.push(...getRelationSelectors(schema, leadStratalotRelations, 'leadStratalot'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneLeadStratalotDictionaryWithoutChild(idLeadStratalot);
  }
}

export function selectActiveLeadStratalots(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveLeadStratalotsEntities, leadStratalots => ({ leadStratalots }))
  ];
  selectors.push(...getRelationSelectors(schema, leadStratalotRelations, 'leadStratalot'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  leadStratalots: Dictionary<LeadStratalotEntityState>;
  leads?: Dictionary<LeadEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { leadStratalots: (LeadStratalot | null)[] } {
  const { leadStratalots, leads, stratalots } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    leadStratalots: Object.keys(leadStratalots).map(idLeadStratalot =>
      hydrate(leadStratalots[idLeadStratalot] as LeadStratalotEntityState, leads, stratalots)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { leadStratalot: LeadStratalotEntityState | null } {
  const { leadStratalots, leads, stratalots } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const leadStratalot = Object.values(leadStratalots)[0];
  return { leadStratalot: hydrate(leadStratalot as LeadStratalotEntityState, leads, stratalots) };
}

function hydrate(
  leadStratalot: LeadStratalotEntityState,
  leadEntities?: Dictionary<LeadEntityState>,
  stratalotEntities?: Dictionary<StratalotEntityState>
): LeadStratalot | null {
  if (!leadStratalot) {
    return null;
  }

  const leadStratalotHydrated: LeadStratalotEntityState = { ...leadStratalot };
  if (leadEntities) {
    leadStratalotHydrated.lead = leadEntities[leadStratalot.lead as number] as Lead;
  } else {
    delete leadStratalotHydrated.lead;
  }
  if (stratalotEntities) {
    leadStratalotHydrated.stratalot = stratalotEntities[leadStratalot.stratalot as number] as Stratalot;
  } else {
    delete leadStratalotHydrated.stratalot;
  }

  return leadStratalotHydrated as LeadStratalot;
}
