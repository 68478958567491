import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationStratalotTodoAppliedRuleEntityState } from '@_model/interfaces/organization-stratalot-todo-applied-rule.model';

export interface OrganizationStratalotTodoAppliedRuleState
  extends EntityState<OrganizationStratalotTodoAppliedRuleEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationStratalotTodoAppliedRuleEntityState> =
  createEntityAdapter<OrganizationStratalotTodoAppliedRuleEntityState>({
    selectId: o => o.idOrganizationStratalotTodoAppliedRule
  });
export const initialState: OrganizationStratalotTodoAppliedRuleState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationStratalotTodoAppliedRuleFeatureKey = 'organizationStratalotTodoAppliedRule';
