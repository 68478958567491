import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CompanyPriceLabelApiService } from '@_services/api/company-price-label-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedCompanyPriceLabelEffects } from './company-price-label-generated.effects';

@Injectable()
export class CompanyPriceLabelEffects extends GeneratedCompanyPriceLabelEffects {
  constructor(actions$: Actions, companyPriceLabelApiService: CompanyPriceLabelApiService, store$: Store<AppState>) {
    super(actions$, companyPriceLabelApiService, store$);
  }
}
