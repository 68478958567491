import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ResidenceSaleCategoryFamily,
  ResidenceSaleCategoryFamilyEntityState
} from '@_model/interfaces/residence-sale-category-family.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { SaleCategoryFamily, SaleCategoryFamilyEntityState } from '@_model/interfaces/sale-category-family.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  residenceSaleCategoryFamilyFeatureKey,
  ResidenceSaleCategoryFamilyState
} from './residence-sale-category-family.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residenceSaleCategoryFamilyRelations: string[] = ['residences', 'familySaleCategories'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidenceSaleCategoryFamilyState = createFeatureSelector<ResidenceSaleCategoryFamilyState>(
  residenceSaleCategoryFamilyFeatureKey
);

export const selectIsLoadedResidenceSaleCategoryFamily = createSelector(
  selectResidenceSaleCategoryFamilyState,
  (state: ResidenceSaleCategoryFamilyState) => state.isLoaded
);

export const selectIsLoadingResidenceSaleCategoryFamily = createSelector(
  selectResidenceSaleCategoryFamilyState,
  (state: ResidenceSaleCategoryFamilyState) => state.isLoading
);

export const selectIsReadyResidenceSaleCategoryFamily = createSelector(
  selectResidenceSaleCategoryFamilyState,
  (state: ResidenceSaleCategoryFamilyState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidenceSaleCategoryFamily = createSelector(
  selectResidenceSaleCategoryFamilyState,
  (state: ResidenceSaleCategoryFamilyState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidenceSaleCategoryFamilyModel: SelectorModel = {
  name: 'residenceSaleCategoryFamilies',
  getSelector: selectAllResidenceSaleCategoryFamiliesDictionary,
  isReady: selectIsReadyResidenceSaleCategoryFamily
};

export const selectResidenceSaleCategoryFamiliesEntities = createSelector(
  selectResidenceSaleCategoryFamilyState,
  selectEntities
);

export const selectResidenceSaleCategoryFamiliesArray = createSelector(
  selectResidenceSaleCategoryFamilyState,
  selectAll
);

export const selectIdResidenceSaleCategoryFamiliesActive = createSelector(
  selectResidenceSaleCategoryFamilyState,
  (state: ResidenceSaleCategoryFamilyState) => state.actives
);

const residenceSaleCategoryFamiliesInObject = (
  residenceSaleCategoryFamilies: Dictionary<ResidenceSaleCategoryFamilyEntityState>
) => ({ residenceSaleCategoryFamilies });

const selectResidenceSaleCategoryFamiliesEntitiesDictionary = createSelector(
  selectResidenceSaleCategoryFamiliesEntities,
  residenceSaleCategoryFamiliesInObject
);

const selectAllResidenceSaleCategoryFamiliesObject = createSelector(
  selectResidenceSaleCategoryFamiliesEntities,
  residenceSaleCategoryFamilies => {
    return hydrateAll({ residenceSaleCategoryFamilies });
  }
);

const selectOneResidenceSaleCategoryFamilyDictionary = (idResidenceSaleCategoryFamily: number) =>
  createSelector(selectResidenceSaleCategoryFamiliesEntities, residenceSaleCategoryFamilies => {
    return {
      residenceSaleCategoryFamilies: {
        [idResidenceSaleCategoryFamily]: residenceSaleCategoryFamilies[idResidenceSaleCategoryFamily]
      }
    };
  });

const selectOneResidenceSaleCategoryFamilyDictionaryWithoutChild = (idResidenceSaleCategoryFamily: number) =>
  createSelector(selectResidenceSaleCategoryFamiliesEntities, residenceSaleCategoryFamilies => {
    return { residenceSaleCategoryFamily: residenceSaleCategoryFamilies[idResidenceSaleCategoryFamily] };
  });

const selectActiveResidenceSaleCategoryFamiliesEntities = createSelector(
  selectIdResidenceSaleCategoryFamiliesActive,
  selectResidenceSaleCategoryFamiliesEntities,
  (actives: number[], residenceSaleCategoryFamilies: Dictionary<ResidenceSaleCategoryFamilyEntityState>) =>
    getResidenceSaleCategoryFamiliesFromActives(actives, residenceSaleCategoryFamilies)
);

function getResidenceSaleCategoryFamiliesFromActives(
  actives: number[],
  residenceSaleCategoryFamilies: Dictionary<ResidenceSaleCategoryFamilyEntityState>
): Dictionary<ResidenceSaleCategoryFamilyEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residenceSaleCategoryFamilies[idActive]) {
      acc[idActive] = residenceSaleCategoryFamilies[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidenceSaleCategoryFamilyEntityState>);
}

const selectAllResidenceSaleCategoryFamiliesSelectors: Dictionary<Selector> = {};
export function selectAllResidenceSaleCategoryFamilies(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidenceSaleCategoryFamily>(
      schema,
      selectAllResidenceSaleCategoryFamiliesSelectors,
      selectResidenceSaleCategoryFamiliesEntitiesDictionary,
      getRelationSelectors,
      residenceSaleCategoryFamilyRelations,
      hydrateAll,
      'residenceSaleCategoryFamily'
    );
  } else {
    return selectAllResidenceSaleCategoryFamiliesObject;
  }
}

export function selectAllResidenceSaleCategoryFamiliesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residenceSaleCategoryFamilies'
): Selector {
  return createSelector(selectAllResidenceSaleCategoryFamilies(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidenceSaleCategoryFamilyEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residenceSaleCategoryFamilies.length; i++) {
      res[customKey][result.residenceSaleCategoryFamilies[i].idResidenceSaleCategoryFamily] =
        result.residenceSaleCategoryFamilies[i];
    }
    return res;
  });
}

export function selectOneResidenceSaleCategoryFamily(
  schema: SelectSchema = {},
  idResidenceSaleCategoryFamily: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidenceSaleCategoryFamilyDictionary(idResidenceSaleCategoryFamily)];
    selectors.push(
      ...getRelationSelectors(schema, residenceSaleCategoryFamilyRelations, 'residenceSaleCategoryFamily')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidenceSaleCategoryFamilyDictionaryWithoutChild(idResidenceSaleCategoryFamily);
  }
}

export function selectActiveResidenceSaleCategoryFamilies(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidenceSaleCategoryFamiliesEntities, residenceSaleCategoryFamilies => ({
      residenceSaleCategoryFamilies
    }))
  ];
  selectors.push(...getRelationSelectors(schema, residenceSaleCategoryFamilyRelations, 'residenceSaleCategoryFamily'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residenceSaleCategoryFamilies: Dictionary<ResidenceSaleCategoryFamilyEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
  familySaleCategories?: Dictionary<SaleCategoryFamilyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  residenceSaleCategoryFamilies: (ResidenceSaleCategoryFamily | null)[];
} {
  const { residenceSaleCategoryFamilies, residences, familySaleCategories } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    residenceSaleCategoryFamilies: Object.keys(residenceSaleCategoryFamilies).map(idResidenceSaleCategoryFamily =>
      hydrate(
        residenceSaleCategoryFamilies[idResidenceSaleCategoryFamily] as ResidenceSaleCategoryFamilyEntityState,
        residences,
        familySaleCategories
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  residenceSaleCategoryFamily: ResidenceSaleCategoryFamilyEntityState | null;
} {
  const { residenceSaleCategoryFamilies, residences, familySaleCategories } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const residenceSaleCategoryFamily = Object.values(residenceSaleCategoryFamilies)[0];
  return {
    residenceSaleCategoryFamily: hydrate(
      residenceSaleCategoryFamily as ResidenceSaleCategoryFamilyEntityState,
      residences,
      familySaleCategories
    )
  };
}

function hydrate(
  residenceSaleCategoryFamily: ResidenceSaleCategoryFamilyEntityState,
  residenceEntities?: Dictionary<ResidenceEntityState>,
  saleCategoryFamilyEntities?: Dictionary<SaleCategoryFamilyEntityState>
): ResidenceSaleCategoryFamily | null {
  if (!residenceSaleCategoryFamily) {
    return null;
  }

  const residenceSaleCategoryFamilyHydrated: ResidenceSaleCategoryFamilyEntityState = {
    ...residenceSaleCategoryFamily
  };
  if (residenceEntities) {
    residenceSaleCategoryFamilyHydrated.residence = residenceEntities[
      residenceSaleCategoryFamily.residence as number
    ] as Residence;
  } else {
    delete residenceSaleCategoryFamilyHydrated.residence;
  }
  if (saleCategoryFamilyEntities) {
    residenceSaleCategoryFamilyHydrated.saleCategoryFamily = saleCategoryFamilyEntities[
      residenceSaleCategoryFamily.saleCategoryFamily as number
    ] as SaleCategoryFamily;
  } else {
    delete residenceSaleCategoryFamilyHydrated.saleCategoryFamily;
  }

  return residenceSaleCategoryFamilyHydrated as ResidenceSaleCategoryFamily;
}
