import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResidenceWork, ResidenceWorkEntityState } from '@_model/interfaces/residence-work.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, residenceWorkFeatureKey, ResidenceWorkState } from './residence-work.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residenceWorkRelations: string[] = ['residences'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidenceWorkState = createFeatureSelector<ResidenceWorkState>(residenceWorkFeatureKey);

export const selectIsLoadedResidenceWork = createSelector(
  selectResidenceWorkState,
  (state: ResidenceWorkState) => state.isLoaded
);

export const selectIsLoadingResidenceWork = createSelector(
  selectResidenceWorkState,
  (state: ResidenceWorkState) => state.isLoading
);

export const selectIsReadyResidenceWork = createSelector(
  selectResidenceWorkState,
  (state: ResidenceWorkState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidenceWork = createSelector(
  selectResidenceWorkState,
  (state: ResidenceWorkState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidenceWorkModel: SelectorModel = {
  name: 'residenceWorks',
  getSelector: selectAllResidenceWorksDictionary,
  isReady: selectIsReadyResidenceWork
};

export const selectResidenceWorksEntities = createSelector(selectResidenceWorkState, selectEntities);

export const selectResidenceWorksArray = createSelector(selectResidenceWorkState, selectAll);

export const selectIdResidenceWorksActive = createSelector(
  selectResidenceWorkState,
  (state: ResidenceWorkState) => state.actives
);

const residenceWorksInObject = (residenceWorks: Dictionary<ResidenceWorkEntityState>) => ({ residenceWorks });

const selectResidenceWorksEntitiesDictionary = createSelector(selectResidenceWorksEntities, residenceWorksInObject);

const selectAllResidenceWorksObject = createSelector(selectResidenceWorksEntities, residenceWorks => {
  return hydrateAll({ residenceWorks });
});

const selectOneResidenceWorkDictionary = (idResidenceWork: number) =>
  createSelector(selectResidenceWorksEntities, residenceWorks => {
    return { residenceWorks: { [idResidenceWork]: residenceWorks[idResidenceWork] } };
  });

const selectOneResidenceWorkDictionaryWithoutChild = (idResidenceWork: number) =>
  createSelector(selectResidenceWorksEntities, residenceWorks => {
    return { residenceWork: residenceWorks[idResidenceWork] };
  });

const selectActiveResidenceWorksEntities = createSelector(
  selectIdResidenceWorksActive,
  selectResidenceWorksEntities,
  (actives: number[], residenceWorks: Dictionary<ResidenceWorkEntityState>) =>
    getResidenceWorksFromActives(actives, residenceWorks)
);

function getResidenceWorksFromActives(
  actives: number[],
  residenceWorks: Dictionary<ResidenceWorkEntityState>
): Dictionary<ResidenceWorkEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residenceWorks[idActive]) {
      acc[idActive] = residenceWorks[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidenceWorkEntityState>);
}

const selectAllResidenceWorksSelectors: Dictionary<Selector> = {};
export function selectAllResidenceWorks(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidenceWork>(
      schema,
      selectAllResidenceWorksSelectors,
      selectResidenceWorksEntitiesDictionary,
      getRelationSelectors,
      residenceWorkRelations,
      hydrateAll,
      'residenceWork'
    );
  } else {
    return selectAllResidenceWorksObject;
  }
}

export function selectAllResidenceWorksDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residenceWorks'
): Selector {
  return createSelector(selectAllResidenceWorks(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidenceWorkEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residenceWorks.length; i++) {
      res[customKey][result.residenceWorks[i].idResidenceWork] = result.residenceWorks[i];
    }
    return res;
  });
}

export function selectOneResidenceWork(schema: SelectSchema = {}, idResidenceWork: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidenceWorkDictionary(idResidenceWork)];
    selectors.push(...getRelationSelectors(schema, residenceWorkRelations, 'residenceWork'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidenceWorkDictionaryWithoutChild(idResidenceWork);
  }
}

export function selectActiveResidenceWorks(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidenceWorksEntities, residenceWorks => ({ residenceWorks }))
  ];
  selectors.push(...getRelationSelectors(schema, residenceWorkRelations, 'residenceWork'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residenceWorks: Dictionary<ResidenceWorkEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { residenceWorks: (ResidenceWork | null)[] } {
  const { residenceWorks, residences } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    residenceWorks: Object.keys(residenceWorks).map(idResidenceWork =>
      hydrate(residenceWorks[idResidenceWork] as ResidenceWorkEntityState, residences)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { residenceWork: ResidenceWorkEntityState | null } {
  const { residenceWorks, residences } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const residenceWork = Object.values(residenceWorks)[0];
  return { residenceWork: hydrate(residenceWork as ResidenceWorkEntityState, residences) };
}

function hydrate(
  residenceWork: ResidenceWorkEntityState,
  residenceEntities?: Dictionary<ResidenceEntityState>
): ResidenceWork | null {
  if (!residenceWork) {
    return null;
  }

  const residenceWorkHydrated: ResidenceWorkEntityState = { ...residenceWork };
  if (residenceEntities) {
    residenceWorkHydrated.residence = residenceEntities[residenceWork.residence as number] as Residence;
  } else {
    delete residenceWorkHydrated.residence;
  }

  return residenceWorkHydrated as ResidenceWork;
}
