import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { OccupantApiService } from '@_services/api/occupant-api.service';
import { Occupant } from '@_shared/models/interfaces/occupant.model';
import { Prospect } from '@_shared/models/interfaces/prospect.model';
import { AppState } from '@_store/index.reducers';
import * as occupantActions from '@_store/occupant/occupant.actions';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { GeneratedOccupantEffects } from './occupant-generated.effects';

@Injectable()
export class OccupantEffects extends GeneratedOccupantEffects {
  constructor(actions$: Actions, occupantApiService: OccupantApiService, store$: Store<AppState>) {
    super(actions$, occupantApiService, store$);
  }

  upsertOneOccupantWithProspect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(occupantActions.upsertOneOccupantWithProspectOnStratalot),
      concatMap(({ idStratalot, prospect }: { idStratalot: number; prospect: Partial<Prospect> }) => {
        if (prospect.idProspect) {
          return this.occupantApiService.updateOccupantWithProspect({ ...prospect } as Partial<Occupant>).pipe(
            map((occupantReturned: Occupant) => {
              return occupantActions.normalizeManyOccupantsAfterUpsert({ occupants: [occupantReturned] });
            }),
            catchError(error => of(occupantActions.occupantsFailure({ error })))
          );
        } else {
          return this.occupantApiService.addOccupantWithProspectOnStratalot(idStratalot, { ...prospect }).pipe(
            concatMap((occupantReturned: Occupant) => {
              const actions: Action[] = [
                occupantActions.normalizeManyOccupantsAfterUpsert({ occupants: [occupantReturned] })
              ];

              return actions;
            }),
            catchError(error => of(occupantActions.occupantsFailure({ error })))
          );
        }
      })
    );
  });
}
