import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ProspectEventActions from './prospect-event.actions';
import { adapter, initialState, ProspectEventState } from './prospect-event.state';
import { Dictionary } from '@ngrx/entity';
import { ProspectEventEntityState } from '@_model/interfaces/prospect-event.model';

export const prospectEventReducersGeneratedFunctions: ReducerTypes<ProspectEventState, readonly ActionCreator[]>[] = [
  on(ProspectEventActions.getOneProspectEvent, (state: ProspectEventState) => setLoadingsState(state, true)),
  on(ProspectEventActions.getManyProspectEvents, (state: ProspectEventState) => setLoadingsState(state, true)),
  on(ProspectEventActions.upsertOneProspectEvent, (state: ProspectEventState) => setLoadingsState(state, true)),

  on(ProspectEventActions.upsertManyProspectEventsSuccess, (state: ProspectEventState, { prospectEvents }) =>
    adapter.upsertMany(prospectEvents, setLoadingsState(state, false))
  ),
  on(ProspectEventActions.deleteOneProspectEvent, (state: ProspectEventState) => setLoadingsState(state, true)),
  on(ProspectEventActions.deleteOneProspectEventSuccess, (state: ProspectEventState, { idProspectEvent }) =>
    adapter.removeOne(idProspectEvent, setLoadingsState(state, false))
  ),
  on(ProspectEventActions.clearActive, (state: ProspectEventState) => ({ ...state, actives: [] })),
  on(ProspectEventActions.addManyActives, (state: ProspectEventState, { idProspectEvents }) => ({
    ...state,
    actives: state.actives.concat(idProspectEvents)
  })),
  on(ProspectEventActions.deleteOneActive, (state: ProspectEventState, { idProspectEvent }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idProspectEvent)
  })),

  on(ProspectEventActions.clearStore, () => initialState),

  on(ProspectEventActions.addProspectSuccess, (state: ProspectEventState, { idProspectEvent, idProspect }) => {
    if (!state.entities[idProspectEvent]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProspectEvent]: {
          ...state.entities[idProspectEvent],
          prospect: idProspect
        }
      }
    };
  }),

  on(ProspectEventActions.deleteManyProspectSuccess, (state: ProspectEventState, { idProspects, idProspectEvents }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idProspectEvents.reduce((entities, idProspectEvent) => {
          if (!state.entities[idProspectEvent]?.prospect) {
            return entities;
          }
          entities[idProspectEvent] = {
            ...state.entities[idProspectEvent],
            prospect: idProspects.some((idProspect: number) => idProspect === state.entities[idProspectEvent]?.prospect)
              ? undefined
              : state.entities[idProspectEvent]?.prospect
          } as ProspectEventEntityState;
          return entities;
        }, {} as Dictionary<ProspectEventEntityState>)
      }
    };
  }),

  on(ProspectEventActions.addLeadSuccess, (state: ProspectEventState, { idProspectEvent, idLead }) => {
    if (!state.entities[idProspectEvent]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProspectEvent]: {
          ...state.entities[idProspectEvent],
          lead: idLead
        }
      }
    };
  }),

  on(ProspectEventActions.deleteManyLeadSuccess, (state: ProspectEventState, { idLeads, idProspectEvents }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idProspectEvents.reduce((entities, idProspectEvent) => {
          if (!state.entities[idProspectEvent]?.lead) {
            return entities;
          }
          entities[idProspectEvent] = {
            ...state.entities[idProspectEvent],
            lead: idLeads.some((idLead: number) => idLead === state.entities[idProspectEvent]?.lead)
              ? undefined
              : state.entities[idProspectEvent]?.lead
          } as ProspectEventEntityState;
          return entities;
        }, {} as Dictionary<ProspectEventEntityState>)
      }
    };
  }),

  on(ProspectEventActions.addUserSuccess, (state: ProspectEventState, { idProspectEvent, idUser }) => {
    if (!state.entities[idProspectEvent]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idProspectEvent]: {
          ...state.entities[idProspectEvent],
          user: idUser
        }
      }
    };
  }),

  on(ProspectEventActions.deleteManyUserSuccess, (state: ProspectEventState, { idUsers, idProspectEvents }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idProspectEvents.reduce((entities, idProspectEvent) => {
          if (!state.entities[idProspectEvent]?.user) {
            return entities;
          }
          entities[idProspectEvent] = {
            ...state.entities[idProspectEvent],
            user: idUsers.some((idUser: number) => idUser === state.entities[idProspectEvent]?.user)
              ? undefined
              : state.entities[idProspectEvent]?.user
          } as ProspectEventEntityState;
          return entities;
        }, {} as Dictionary<ProspectEventEntityState>)
      }
    };
  })
];

export function setLoadingsState(
  state: ProspectEventState,
  isLoading: boolean,
  isLoaded: boolean = true
): ProspectEventState {
  return { ...state, isLoaded, isLoading };
}
