import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProspectBuyingWishApiService } from '@_services/api/prospect-buying-wish-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedProspectBuyingWishEffects } from './prospect-buying-wish-generated.effects';

@Injectable()
export class ProspectBuyingWishEffects extends GeneratedProspectBuyingWishEffects {
  constructor(actions$: Actions, prospectBuyingWishApiService: ProspectBuyingWishApiService, store$: Store<AppState>) {
    super(actions$, prospectBuyingWishApiService, store$);
  }
}
