import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as StratalotTypeActions from './stratalot-type.actions';
import { adapter, initialState, StratalotTypeState } from './stratalot-type.state';
import { Dictionary } from '@ngrx/entity';
import { StratalotTypeEntityState } from '@_model/interfaces/stratalot-type.model';

export const stratalotTypeReducersGeneratedFunctions: ReducerTypes<StratalotTypeState, readonly ActionCreator[]>[] = [
  on(StratalotTypeActions.getOneStratalotType, (state: StratalotTypeState) => setLoadingsState(state, true)),
  on(StratalotTypeActions.getManyStratalotTypes, (state: StratalotTypeState) => setLoadingsState(state, true)),
  on(StratalotTypeActions.upsertOneStratalotType, (state: StratalotTypeState) => setLoadingsState(state, true)),

  on(StratalotTypeActions.upsertManyStratalotTypesSuccess, (state: StratalotTypeState, { stratalotTypes }) =>
    adapter.upsertMany(stratalotTypes, setLoadingsState(state, false))
  ),
  on(StratalotTypeActions.deleteOneStratalotType, (state: StratalotTypeState) => setLoadingsState(state, true)),
  on(StratalotTypeActions.deleteOneStratalotTypeSuccess, (state: StratalotTypeState, { idStratalotType }) =>
    adapter.removeOne(idStratalotType, setLoadingsState(state, false))
  ),
  on(StratalotTypeActions.clearActive, (state: StratalotTypeState) => ({ ...state, actives: [] })),
  on(StratalotTypeActions.addManyActives, (state: StratalotTypeState, { idStratalotTypes }) => ({
    ...state,
    actives: state.actives.concat(idStratalotTypes)
  })),
  on(StratalotTypeActions.deleteOneActive, (state: StratalotTypeState, { idStratalotType }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idStratalotType)
  })),

  on(StratalotTypeActions.clearStore, () => initialState),

  on(
    StratalotTypeActions.addManyCompanyStratalotTypeSuccess,
    (state: StratalotTypeState, { idStratalotType, idCompanyStratalotTypes }) => {
      if (!state.entities[idStratalotType]) {
        return state;
      }
      const companyStratalotTypes = (state.entities[idStratalotType]?.companyStratalotTypes as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotType]: {
            ...state.entities[idStratalotType],
            companyStratalotTypes: companyStratalotTypes.concat(
              idCompanyStratalotTypes.filter(id => companyStratalotTypes.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    StratalotTypeActions.deleteManyCompanyStratalotTypeSuccess,
    (state: StratalotTypeState, { idCompanyStratalotTypes, idStratalotTypes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotTypes.reduce((entities, idStratalotType) => {
            if (!state.entities[idStratalotType]?.companyStratalotTypes) {
              return entities;
            }
            entities[idStratalotType] = {
              ...state.entities[idStratalotType],
              companyStratalotTypes: (state.entities[idStratalotType]?.companyStratalotTypes as number[])?.filter(
                (idCompanyStratalotType: number) =>
                  !idCompanyStratalotTypes.some((id: number) => id === idCompanyStratalotType)
              )
            } as StratalotTypeEntityState;
            return entities;
          }, {} as Dictionary<StratalotTypeEntityState>)
        }
      };
    }
  ),

  on(
    StratalotTypeActions.addManyProspectBuyingWishSuccess,
    (state: StratalotTypeState, { idStratalotType, idProspectBuyingWishs }) => {
      if (!state.entities[idStratalotType]) {
        return state;
      }
      const prospectBuyingWishs = (state.entities[idStratalotType]?.prospectBuyingWishs as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotType]: {
            ...state.entities[idStratalotType],
            prospectBuyingWishs: prospectBuyingWishs.concat(
              idProspectBuyingWishs.filter(id => prospectBuyingWishs.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    StratalotTypeActions.deleteManyProspectBuyingWishSuccess,
    (state: StratalotTypeState, { idProspectBuyingWishs, idStratalotTypes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotTypes.reduce((entities, idStratalotType) => {
            if (!state.entities[idStratalotType]?.prospectBuyingWishs) {
              return entities;
            }
            entities[idStratalotType] = {
              ...state.entities[idStratalotType],
              prospectBuyingWishs: (state.entities[idStratalotType]?.prospectBuyingWishs as number[])?.filter(
                (idProspectBuyingWish: number) =>
                  !idProspectBuyingWishs.some((id: number) => id === idProspectBuyingWish)
              )
            } as StratalotTypeEntityState;
            return entities;
          }, {} as Dictionary<StratalotTypeEntityState>)
        }
      };
    }
  ),

  on(
    StratalotTypeActions.addManyOrganizationLeadTodoRuleSuccess,
    (state: StratalotTypeState, { idStratalotType, idOrganizationLeadTodoRules }) => {
      if (!state.entities[idStratalotType]) {
        return state;
      }
      const organizationLeadTodoRules = (state.entities[idStratalotType]?.organizationLeadTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotType]: {
            ...state.entities[idStratalotType],
            organizationLeadTodoRules: organizationLeadTodoRules.concat(
              idOrganizationLeadTodoRules.filter(id => organizationLeadTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    StratalotTypeActions.deleteManyOrganizationLeadTodoRuleSuccess,
    (state: StratalotTypeState, { idOrganizationLeadTodoRules, idStratalotTypes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotTypes.reduce((entities, idStratalotType) => {
            if (!state.entities[idStratalotType]?.organizationLeadTodoRules) {
              return entities;
            }
            entities[idStratalotType] = {
              ...state.entities[idStratalotType],
              organizationLeadTodoRules: (
                state.entities[idStratalotType]?.organizationLeadTodoRules as number[]
              )?.filter(
                (idOrganizationLeadTodoRule: number) =>
                  !idOrganizationLeadTodoRules.some((id: number) => id === idOrganizationLeadTodoRule)
              )
            } as StratalotTypeEntityState;
            return entities;
          }, {} as Dictionary<StratalotTypeEntityState>)
        }
      };
    }
  ),

  on(
    StratalotTypeActions.addManyOrganizationStratalotTodoRuleSuccess,
    (state: StratalotTypeState, { idStratalotType, idOrganizationStratalotTodoRules }) => {
      if (!state.entities[idStratalotType]) {
        return state;
      }
      const organizationStratalotTodoRules =
        (state.entities[idStratalotType]?.organizationStratalotTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotType]: {
            ...state.entities[idStratalotType],
            organizationStratalotTodoRules: organizationStratalotTodoRules.concat(
              idOrganizationStratalotTodoRules.filter(id => organizationStratalotTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    StratalotTypeActions.deleteManyOrganizationStratalotTodoRuleSuccess,
    (state: StratalotTypeState, { idOrganizationStratalotTodoRules, idStratalotTypes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotTypes.reduce((entities, idStratalotType) => {
            if (!state.entities[idStratalotType]?.organizationStratalotTodoRules) {
              return entities;
            }
            entities[idStratalotType] = {
              ...state.entities[idStratalotType],
              organizationStratalotTodoRules: (
                state.entities[idStratalotType]?.organizationStratalotTodoRules as number[]
              )?.filter(
                (idOrganizationStratalotTodoRule: number) =>
                  !idOrganizationStratalotTodoRules.some((id: number) => id === idOrganizationStratalotTodoRule)
              )
            } as StratalotTypeEntityState;
            return entities;
          }, {} as Dictionary<StratalotTypeEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: StratalotTypeState,
  isLoading: boolean,
  isLoaded: boolean = true
): StratalotTypeState {
  return { ...state, isLoaded, isLoading };
}
