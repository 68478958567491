import { RepositoryService } from '@_services/api/repository.service';
import { CompanyStratalotVacant } from '@_model/interfaces/company-stratalot-vacant.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyStratalotVacantApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyStratalotVacants(params?: any): Observable<CompanyStratalotVacant[]> {
    return this.repo.getData<CompanyStratalotVacant[]>('company-stratalot-vacant', params);
  }

  public getCompanyStratalotVacant(params: {
    idCompanyStratalotVacant: number;
    params?: any;
  }): Observable<CompanyStratalotVacant> {
    return this.repo.getData<CompanyStratalotVacant>(
      'company-stratalot-vacant/' + params.idCompanyStratalotVacant,
      params.params
    );
  }

  public addCompanyStratalotVacant(
    companyStratalotVacant: Partial<CompanyStratalotVacant>
  ): Observable<CompanyStratalotVacant> {
    return this.repo.create<CompanyStratalotVacant>('company-stratalot-vacant', companyStratalotVacant);
  }

  public updateCompanyStratalotVacant(
    companyStratalotVacant: Partial<CompanyStratalotVacant>
  ): Observable<CompanyStratalotVacant> {
    return this.repo.update('company-stratalot-vacant', companyStratalotVacant);
  }

  public deleteCompanyStratalotVacant(idCompanyStratalotVacant: number): Observable<number> {
    return this.repo.delete('company-stratalot-vacant/' + +idCompanyStratalotVacant);
  }
}
