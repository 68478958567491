import { CompanyTerritoireUserState, initialState } from './company-territoire-user.state';
import { Action, createReducer } from '@ngrx/store';
import { companyTerritoireUserReducersGeneratedFunctions } from './company-territoire-user-generated.reducer';

const companyTerritoireUserReducersFunctions = [...companyTerritoireUserReducersGeneratedFunctions];

const companyTerritoireUserReducer = createReducer(initialState, ...companyTerritoireUserReducersFunctions);

export function reducer(state: CompanyTerritoireUserState | undefined, action: Action) {
  return companyTerritoireUserReducer(state, action);
}
