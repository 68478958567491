import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationMetierActions from './organization-metier.actions';
import { adapter, initialState, OrganizationMetierState } from './organization-metier.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationMetierEntityState } from '@_model/interfaces/organization-metier.model';

export const organizationMetierReducersGeneratedFunctions: ReducerTypes<
  OrganizationMetierState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationMetierActions.getOneOrganizationMetier, (state: OrganizationMetierState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationMetierActions.getManyOrganizationMetiers, (state: OrganizationMetierState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationMetierActions.upsertOneOrganizationMetier, (state: OrganizationMetierState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationMetierActions.upsertManyOrganizationMetiersSuccess,
    (state: OrganizationMetierState, { organizationMetiers }) =>
      adapter.upsertMany(organizationMetiers, setLoadingsState(state, false))
  ),
  on(OrganizationMetierActions.deleteOneOrganizationMetier, (state: OrganizationMetierState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationMetierActions.deleteOneOrganizationMetierSuccess,
    (state: OrganizationMetierState, { idOrganizationMetier }) =>
      adapter.removeOne(idOrganizationMetier, setLoadingsState(state, false))
  ),
  on(OrganizationMetierActions.clearActive, (state: OrganizationMetierState) => ({ ...state, actives: [] })),
  on(OrganizationMetierActions.addManyActives, (state: OrganizationMetierState, { idOrganizationMetiers }) => ({
    ...state,
    actives: state.actives.concat(idOrganizationMetiers)
  })),
  on(OrganizationMetierActions.deleteOneActive, (state: OrganizationMetierState, { idOrganizationMetier }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idOrganizationMetier)
  })),

  on(OrganizationMetierActions.clearStore, () => initialState),

  on(
    OrganizationMetierActions.addManyOrganizationThirdPartySuccess,
    (state: OrganizationMetierState, { idOrganizationMetier, idOrganizationThirdParties }) => {
      if (!state.entities[idOrganizationMetier]) {
        return state;
      }
      const organizationThirdParties =
        (state.entities[idOrganizationMetier]?.organizationThirdParties as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMetier]: {
            ...state.entities[idOrganizationMetier],
            organizationThirdParties: organizationThirdParties.concat(
              idOrganizationThirdParties.filter(id => organizationThirdParties.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationMetierActions.deleteManyOrganizationThirdPartySuccess,
    (state: OrganizationMetierState, { idOrganizationThirdParties, idOrganizationMetiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMetiers.reduce((entities, idOrganizationMetier) => {
            if (!state.entities[idOrganizationMetier]?.organizationThirdParties) {
              return entities;
            }
            entities[idOrganizationMetier] = {
              ...state.entities[idOrganizationMetier],
              organizationThirdParties: (
                state.entities[idOrganizationMetier]?.organizationThirdParties as number[]
              )?.filter(
                (idOrganizationThirdParty: number) =>
                  !idOrganizationThirdParties.some((id: number) => id === idOrganizationThirdParty)
              )
            } as OrganizationMetierEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMetierEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationMetierActions.addManyCompanyCommunicationRecipientSuccess,
    (state: OrganizationMetierState, { idOrganizationMetier, idCompanyCommunicationRecipients }) => {
      if (!state.entities[idOrganizationMetier]) {
        return state;
      }
      const companyCommunicationRecipients =
        (state.entities[idOrganizationMetier]?.companyCommunicationRecipients as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMetier]: {
            ...state.entities[idOrganizationMetier],
            companyCommunicationRecipients: companyCommunicationRecipients.concat(
              idCompanyCommunicationRecipients.filter(id => companyCommunicationRecipients.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationMetierActions.deleteManyCompanyCommunicationRecipientSuccess,
    (state: OrganizationMetierState, { idCompanyCommunicationRecipients, idOrganizationMetiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMetiers.reduce((entities, idOrganizationMetier) => {
            if (!state.entities[idOrganizationMetier]?.companyCommunicationRecipients) {
              return entities;
            }
            entities[idOrganizationMetier] = {
              ...state.entities[idOrganizationMetier],
              companyCommunicationRecipients: (
                state.entities[idOrganizationMetier]?.companyCommunicationRecipients as number[]
              )?.filter(
                (idCompanyCommunicationRecipient: number) =>
                  !idCompanyCommunicationRecipients.some((id: number) => id === idCompanyCommunicationRecipient)
              )
            } as OrganizationMetierEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMetierEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationMetierActions.addManyCompanyCommunicationRecipientMetierSuccess,
    (state: OrganizationMetierState, { idOrganizationMetier, idCompanyCommunicationRecipientMetiers }) => {
      if (!state.entities[idOrganizationMetier]) {
        return state;
      }
      const companyCommunicationRecipientMetiers =
        (state.entities[idOrganizationMetier]?.companyCommunicationRecipientMetiers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMetier]: {
            ...state.entities[idOrganizationMetier],
            companyCommunicationRecipientMetiers: companyCommunicationRecipientMetiers.concat(
              idCompanyCommunicationRecipientMetiers.filter(id => companyCommunicationRecipientMetiers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationMetierActions.deleteManyCompanyCommunicationRecipientMetierSuccess,
    (state: OrganizationMetierState, { idCompanyCommunicationRecipientMetiers, idOrganizationMetiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMetiers.reduce((entities, idOrganizationMetier) => {
            if (!state.entities[idOrganizationMetier]?.companyCommunicationRecipientMetiers) {
              return entities;
            }
            entities[idOrganizationMetier] = {
              ...state.entities[idOrganizationMetier],
              companyCommunicationRecipientMetiers: (
                state.entities[idOrganizationMetier]?.companyCommunicationRecipientMetiers as number[]
              )?.filter(
                (idCompanyCommunicationRecipientMetier: number) =>
                  !idCompanyCommunicationRecipientMetiers.some(
                    (id: number) => id === idCompanyCommunicationRecipientMetier
                  )
              )
            } as OrganizationMetierEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMetierEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationMetierActions.addOrganizationSuccess,
    (state: OrganizationMetierState, { idOrganizationMetier, idOrganization }) => {
      if (!state.entities[idOrganizationMetier]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMetier]: {
            ...state.entities[idOrganizationMetier],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationMetierActions.deleteManyOrganizationSuccess,
    (state: OrganizationMetierState, { idOrganizations, idOrganizationMetiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMetiers.reduce((entities, idOrganizationMetier) => {
            if (!state.entities[idOrganizationMetier]?.organization) {
              return entities;
            }
            entities[idOrganizationMetier] = {
              ...state.entities[idOrganizationMetier],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationMetier]?.organization
              )
                ? undefined
                : state.entities[idOrganizationMetier]?.organization
            } as OrganizationMetierEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMetierEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationMetierActions.addMetierFamilieSuccess,
    (state: OrganizationMetierState, { idOrganizationMetier, idMetierFamilie }) => {
      if (!state.entities[idOrganizationMetier]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationMetier]: {
            ...state.entities[idOrganizationMetier],
            metierFamilie: idMetierFamilie
          }
        }
      };
    }
  ),

  on(
    OrganizationMetierActions.deleteManyMetierFamilieSuccess,
    (state: OrganizationMetierState, { idMetierFamilies, idOrganizationMetiers }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationMetiers.reduce((entities, idOrganizationMetier) => {
            if (!state.entities[idOrganizationMetier]?.metierFamilie) {
              return entities;
            }
            entities[idOrganizationMetier] = {
              ...state.entities[idOrganizationMetier],
              metierFamilie: idMetierFamilies.some(
                (idMetierFamilie: number) => idMetierFamilie === state.entities[idOrganizationMetier]?.metierFamilie
              )
                ? undefined
                : state.entities[idOrganizationMetier]?.metierFamilie
            } as OrganizationMetierEntityState;
            return entities;
          }, {} as Dictionary<OrganizationMetierEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationMetierState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationMetierState {
  return { ...state, isLoaded, isLoading };
}
