import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationResidenceTodo,
  OrganizationResidenceTodoEntityState
} from '@_model/interfaces/organization-residence-todo.model';
import { ResidenceTodo, ResidenceTodoEntityState } from '@_model/interfaces/residence-todo.model';
import {
  OrganizationResidenceTodoRule,
  OrganizationResidenceTodoRuleEntityState
} from '@_model/interfaces/organization-residence-todo-rule.model';
import {
  OrganizationResidenceTodoAppliedRule,
  OrganizationResidenceTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-residence-todo-applied-rule.model';
import {
  OrganizationResidenceTodoProfil,
  OrganizationResidenceTodoProfilEntityState
} from '@_model/interfaces/organization-residence-todo-profil.model';
import { CompanyCommunication, CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';
import {
  CompanyCommunicationResidenceTodo,
  CompanyCommunicationResidenceTodoEntityState
} from '@_model/interfaces/company-communication-residence-todo.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { Diagnostic, DiagnosticEntityState } from '@_model/interfaces/diagnostic.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationResidenceTodoFeatureKey,
  OrganizationResidenceTodoState
} from './organization-residence-todo.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationResidenceTodoRelations: string[] = [
  'residenceTodos',
  'organizationResidenceTodoRules',
  'organizationResidenceTodoAppliedRules',
  'organizationResidenceTodoProfils',
  'companyCommunications',
  'companyCommunicationResidenceTodos',
  'organizations',
  'diagnostics'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationResidenceTodoState = createFeatureSelector<OrganizationResidenceTodoState>(
  organizationResidenceTodoFeatureKey
);

export const selectIsLoadedOrganizationResidenceTodo = createSelector(
  selectOrganizationResidenceTodoState,
  (state: OrganizationResidenceTodoState) => state.isLoaded
);

export const selectIsLoadingOrganizationResidenceTodo = createSelector(
  selectOrganizationResidenceTodoState,
  (state: OrganizationResidenceTodoState) => state.isLoading
);

export const selectIsReadyOrganizationResidenceTodo = createSelector(
  selectOrganizationResidenceTodoState,
  (state: OrganizationResidenceTodoState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationResidenceTodo = createSelector(
  selectOrganizationResidenceTodoState,
  (state: OrganizationResidenceTodoState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationResidenceTodoModel: SelectorModel = {
  name: 'organizationResidenceTodos',
  getSelector: selectAllOrganizationResidenceTodosDictionary,
  isReady: selectIsReadyOrganizationResidenceTodo
};

export const selectOrganizationResidenceTodosEntities = createSelector(
  selectOrganizationResidenceTodoState,
  selectEntities
);

export const selectOrganizationResidenceTodosArray = createSelector(selectOrganizationResidenceTodoState, selectAll);

export const selectIdOrganizationResidenceTodosActive = createSelector(
  selectOrganizationResidenceTodoState,
  (state: OrganizationResidenceTodoState) => state.actives
);

const organizationResidenceTodosInObject = (
  organizationResidenceTodos: Dictionary<OrganizationResidenceTodoEntityState>
) => ({ organizationResidenceTodos });

const selectOrganizationResidenceTodosEntitiesDictionary = createSelector(
  selectOrganizationResidenceTodosEntities,
  organizationResidenceTodosInObject
);

const selectAllOrganizationResidenceTodosObject = createSelector(
  selectOrganizationResidenceTodosEntities,
  organizationResidenceTodos => {
    return hydrateAll({ organizationResidenceTodos });
  }
);

const selectOneOrganizationResidenceTodoDictionary = (idOrganizationResidenceTodo: number) =>
  createSelector(selectOrganizationResidenceTodosEntities, organizationResidenceTodos => {
    return {
      organizationResidenceTodos: {
        [idOrganizationResidenceTodo]: organizationResidenceTodos[idOrganizationResidenceTodo]
      }
    };
  });

const selectOneOrganizationResidenceTodoDictionaryWithoutChild = (idOrganizationResidenceTodo: number) =>
  createSelector(selectOrganizationResidenceTodosEntities, organizationResidenceTodos => {
    return { organizationResidenceTodo: organizationResidenceTodos[idOrganizationResidenceTodo] };
  });

const selectActiveOrganizationResidenceTodosEntities = createSelector(
  selectIdOrganizationResidenceTodosActive,
  selectOrganizationResidenceTodosEntities,
  (actives: number[], organizationResidenceTodos: Dictionary<OrganizationResidenceTodoEntityState>) =>
    getOrganizationResidenceTodosFromActives(actives, organizationResidenceTodos)
);

function getOrganizationResidenceTodosFromActives(
  actives: number[],
  organizationResidenceTodos: Dictionary<OrganizationResidenceTodoEntityState>
): Dictionary<OrganizationResidenceTodoEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationResidenceTodos[idActive]) {
      acc[idActive] = organizationResidenceTodos[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationResidenceTodoEntityState>);
}

const selectAllOrganizationResidenceTodosSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationResidenceTodos(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationResidenceTodo>(
      schema,
      selectAllOrganizationResidenceTodosSelectors,
      selectOrganizationResidenceTodosEntitiesDictionary,
      getRelationSelectors,
      organizationResidenceTodoRelations,
      hydrateAll,
      'organizationResidenceTodo'
    );
  } else {
    return selectAllOrganizationResidenceTodosObject;
  }
}

export function selectAllOrganizationResidenceTodosDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationResidenceTodos'
): Selector {
  return createSelector(selectAllOrganizationResidenceTodos(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationResidenceTodoEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationResidenceTodos.length; i++) {
      res[customKey][result.organizationResidenceTodos[i].idOrganizationResidenceTodo] =
        result.organizationResidenceTodos[i];
    }
    return res;
  });
}

export function selectOneOrganizationResidenceTodo(
  schema: SelectSchema = {},
  idOrganizationResidenceTodo: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationResidenceTodoDictionary(idOrganizationResidenceTodo)];
    selectors.push(...getRelationSelectors(schema, organizationResidenceTodoRelations, 'organizationResidenceTodo'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationResidenceTodoDictionaryWithoutChild(idOrganizationResidenceTodo);
  }
}

export function selectActiveOrganizationResidenceTodos(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationResidenceTodosEntities, organizationResidenceTodos => ({
      organizationResidenceTodos
    }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationResidenceTodoRelations, 'organizationResidenceTodo'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationResidenceTodos: Dictionary<OrganizationResidenceTodoEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  diagnostics?: Dictionary<DiagnosticEntityState>;
  residenceTodos?: Dictionary<ResidenceTodoEntityState>;
  organizationResidenceTodoRules?: Dictionary<OrganizationResidenceTodoRuleEntityState>;
  organizationResidenceTodoAppliedRules?: Dictionary<OrganizationResidenceTodoAppliedRuleEntityState>;
  organizationResidenceTodoProfils?: Dictionary<OrganizationResidenceTodoProfilEntityState>;
  companyCommunications?: Dictionary<CompanyCommunicationEntityState>;
  companyCommunicationResidenceTodos?: Dictionary<CompanyCommunicationResidenceTodoEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationResidenceTodos: (OrganizationResidenceTodo | null)[];
} {
  const {
    organizationResidenceTodos,
    organizations,
    diagnostics,
    residenceTodos,
    organizationResidenceTodoRules,
    organizationResidenceTodoAppliedRules,
    organizationResidenceTodoProfils,
    companyCommunications,
    companyCommunicationResidenceTodos
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationResidenceTodos: Object.keys(organizationResidenceTodos).map(idOrganizationResidenceTodo =>
      hydrate(
        organizationResidenceTodos[idOrganizationResidenceTodo] as OrganizationResidenceTodoEntityState,
        organizations,
        diagnostics,
        residenceTodos,
        organizationResidenceTodoRules,
        organizationResidenceTodoAppliedRules,
        organizationResidenceTodoProfils,
        companyCommunications,
        companyCommunicationResidenceTodos
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationResidenceTodo: OrganizationResidenceTodoEntityState | null;
} {
  const {
    organizationResidenceTodos,
    organizations,
    diagnostics,
    residenceTodos,
    organizationResidenceTodoRules,
    organizationResidenceTodoAppliedRules,
    organizationResidenceTodoProfils,
    companyCommunications,
    companyCommunicationResidenceTodos
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationResidenceTodo = Object.values(organizationResidenceTodos)[0];
  return {
    organizationResidenceTodo: hydrate(
      organizationResidenceTodo as OrganizationResidenceTodoEntityState,
      organizations,
      diagnostics,
      residenceTodos,
      organizationResidenceTodoRules,
      organizationResidenceTodoAppliedRules,
      organizationResidenceTodoProfils,
      companyCommunications,
      companyCommunicationResidenceTodos
    )
  };
}

function hydrate(
  organizationResidenceTodo: OrganizationResidenceTodoEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  diagnosticEntities?: Dictionary<DiagnosticEntityState>,
  residenceTodoEntities?: Dictionary<ResidenceTodoEntityState>,
  organizationResidenceTodoRuleEntities?: Dictionary<OrganizationResidenceTodoRuleEntityState>,
  organizationResidenceTodoAppliedRuleEntities?: Dictionary<OrganizationResidenceTodoAppliedRuleEntityState>,
  organizationResidenceTodoProfilEntities?: Dictionary<OrganizationResidenceTodoProfilEntityState>,
  companyCommunicationEntities?: Dictionary<CompanyCommunicationEntityState>,
  companyCommunicationResidenceTodoEntities?: Dictionary<CompanyCommunicationResidenceTodoEntityState>
): OrganizationResidenceTodo | null {
  if (!organizationResidenceTodo) {
    return null;
  }

  const organizationResidenceTodoHydrated: OrganizationResidenceTodoEntityState = { ...organizationResidenceTodo };
  if (organizationEntities) {
    organizationResidenceTodoHydrated.organization = organizationEntities[
      organizationResidenceTodo.organization as number
    ] as Organization;
  } else {
    delete organizationResidenceTodoHydrated.organization;
  }
  if (diagnosticEntities) {
    organizationResidenceTodoHydrated.diagnostic = diagnosticEntities[
      organizationResidenceTodo.diagnostic as number
    ] as Diagnostic;
  } else {
    delete organizationResidenceTodoHydrated.diagnostic;
  }

  if (residenceTodoEntities) {
    organizationResidenceTodoHydrated.residenceTodos = (
      (organizationResidenceTodoHydrated.residenceTodos as number[]) || []
    ).map(id => residenceTodoEntities[id]) as ResidenceTodo[];
  } else {
    delete organizationResidenceTodoHydrated.residenceTodos;
  }

  if (organizationResidenceTodoRuleEntities) {
    organizationResidenceTodoHydrated.organizationResidenceTodoRules = (
      (organizationResidenceTodoHydrated.organizationResidenceTodoRules as number[]) || []
    ).map(id => organizationResidenceTodoRuleEntities[id]) as OrganizationResidenceTodoRule[];
  } else {
    delete organizationResidenceTodoHydrated.organizationResidenceTodoRules;
  }

  if (organizationResidenceTodoAppliedRuleEntities) {
    organizationResidenceTodoHydrated.organizationResidenceTodoAppliedRules = (
      (organizationResidenceTodoHydrated.organizationResidenceTodoAppliedRules as number[]) || []
    ).map(id => organizationResidenceTodoAppliedRuleEntities[id]) as OrganizationResidenceTodoAppliedRule[];
  } else {
    delete organizationResidenceTodoHydrated.organizationResidenceTodoAppliedRules;
  }

  if (organizationResidenceTodoProfilEntities) {
    organizationResidenceTodoHydrated.organizationResidenceTodoProfils = (
      (organizationResidenceTodoHydrated.organizationResidenceTodoProfils as number[]) || []
    ).map(id => organizationResidenceTodoProfilEntities[id]) as OrganizationResidenceTodoProfil[];
  } else {
    delete organizationResidenceTodoHydrated.organizationResidenceTodoProfils;
  }

  if (companyCommunicationEntities) {
    organizationResidenceTodoHydrated.companyCommunications = (
      (organizationResidenceTodoHydrated.companyCommunications as number[]) || []
    ).map(id => companyCommunicationEntities[id]) as CompanyCommunication[];
  } else {
    delete organizationResidenceTodoHydrated.companyCommunications;
  }

  if (companyCommunicationResidenceTodoEntities) {
    organizationResidenceTodoHydrated.companyCommunicationResidenceTodos = (
      (organizationResidenceTodoHydrated.companyCommunicationResidenceTodos as number[]) || []
    ).map(id => companyCommunicationResidenceTodoEntities[id]) as CompanyCommunicationResidenceTodo[];
  } else {
    delete organizationResidenceTodoHydrated.companyCommunicationResidenceTodos;
  }

  return organizationResidenceTodoHydrated as OrganizationResidenceTodo;
}
