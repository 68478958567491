import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationResidenceTodoAppliedRule } from '@_model/interfaces/organization-residence-todo-applied-rule.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationResidenceTodoAppliedRuleApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationResidenceTodoAppliedRules(params?: any): Observable<OrganizationResidenceTodoAppliedRule[]> {
    return this.repo.getData<OrganizationResidenceTodoAppliedRule[]>(
      'organization-residence-todo-applied-rule',
      params
    );
  }

  public getOrganizationResidenceTodoAppliedRule(params: {
    idOrganizationResidenceTodoAppliedRule: number;
    params?: any;
  }): Observable<OrganizationResidenceTodoAppliedRule> {
    return this.repo.getData<OrganizationResidenceTodoAppliedRule>(
      'organization-residence-todo-applied-rule/' + params.idOrganizationResidenceTodoAppliedRule,
      params.params
    );
  }

  public addOrganizationResidenceTodoAppliedRule(
    organizationResidenceTodoAppliedRule: Partial<OrganizationResidenceTodoAppliedRule>
  ): Observable<OrganizationResidenceTodoAppliedRule> {
    return this.repo.create<OrganizationResidenceTodoAppliedRule>(
      'organization-residence-todo-applied-rule',
      organizationResidenceTodoAppliedRule
    );
  }

  public updateOrganizationResidenceTodoAppliedRule(
    organizationResidenceTodoAppliedRule: Partial<OrganizationResidenceTodoAppliedRule>
  ): Observable<OrganizationResidenceTodoAppliedRule> {
    return this.repo.update('organization-residence-todo-applied-rule', organizationResidenceTodoAppliedRule);
  }

  public deleteOrganizationResidenceTodoAppliedRule(
    idOrganizationResidenceTodoAppliedRule: number
  ): Observable<number> {
    return this.repo.delete('organization-residence-todo-applied-rule/' + +idOrganizationResidenceTodoAppliedRule);
  }
}
