import { CompanyStudyConclusionState, initialState } from './company-study-conclusion.state';
import { Action, createReducer } from '@ngrx/store';
import { companyStudyConclusionReducersGeneratedFunctions } from './company-study-conclusion-generated.reducer';

const companyStudyConclusionReducersFunctions = [...companyStudyConclusionReducersGeneratedFunctions];

const companyStudyConclusionReducer = createReducer(initialState, ...companyStudyConclusionReducersFunctions);

export function reducer(state: CompanyStudyConclusionState | undefined, action: Action) {
  return companyStudyConclusionReducer(state, action);
}
