import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { StratalotCampaign, StratalotCampaignEntityState } from '@_model/interfaces/stratalot-campaign.model';
import { DiffusionVisual, DiffusionVisualEntityState } from '@_model/interfaces/diffusion-visual.model';
import { StratalotAssociation, StratalotAssociationEntityState } from '@_model/interfaces/stratalot-association.model';
import { StratalotFee, StratalotFeeEntityState } from '@_model/interfaces/stratalot-fee.model';
import { Association, AssociationEntityState } from '@_model/interfaces/association.model';
import { Lead, LeadEntityState } from '@_model/interfaces/lead.model';
import { LeadStratalot, LeadStratalotEntityState } from '@_model/interfaces/lead-stratalot.model';
import { StratalotPrice, StratalotPriceEntityState } from '@_model/interfaces/stratalot-price.model';
import { StratalotRcp, StratalotRcpEntityState } from '@_model/interfaces/stratalot-rcp.model';
import { StratalotTodo, StratalotTodoEntityState } from '@_model/interfaces/stratalot-todo.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { Occupant, OccupantEntityState } from '@_model/interfaces/occupant.model';
import { CompanyStratalotType, CompanyStratalotTypeEntityState } from '@_model/interfaces/company-stratalot-type.model';
import {
  CompanyStratalotVacant,
  CompanyStratalotVacantEntityState
} from '@_model/interfaces/company-stratalot-vacant.model';
import {
  OrganizationSaleCategory,
  OrganizationSaleCategoryEntityState
} from '@_model/interfaces/organization-sale-category.model';
import { StepProgress, StepProgressEntityState } from '@_model/interfaces/step-progress.model';
import { User, UserEntityState } from '@_model/interfaces/user.model';
import {
  OrganizationStratalotAvancement,
  OrganizationStratalotAvancementEntityState
} from '@_model/interfaces/organization-stratalot-avancement.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, stratalotFeatureKey, StratalotState } from './stratalot.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const stratalotRelations: string[] = [
  'stratalotCampaigns',
  'diffusionVisuals',
  'stratalotAssociations',
  'stratalotFees',
  'associations',
  'leads',
  'leadStratalots',
  'stratalotPrices',
  'stratalotRcps',
  'stratalotTodos',
  'residences',
  'occupants',
  'companyStratalotTypes',
  'companyStratalotVacants',
  'organizationSaleCategories',
  'stepProgresses',
  'responsable',
  'organizationStratalotAvancements'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectStratalotState = createFeatureSelector<StratalotState>(stratalotFeatureKey);

export const selectIsLoadedStratalot = createSelector(selectStratalotState, (state: StratalotState) => state.isLoaded);

export const selectIsLoadingStratalot = createSelector(
  selectStratalotState,
  (state: StratalotState) => state.isLoading
);

export const selectIsReadyStratalot = createSelector(selectStratalotState, (state: StratalotState) => !state.isLoading);

export const selectIsReadyAndLoadedStratalot = createSelector(
  selectStratalotState,
  (state: StratalotState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const StratalotModel: SelectorModel = {
  name: 'stratalots',
  getSelector: selectAllStratalotsDictionary,
  isReady: selectIsReadyStratalot
};

export const selectStratalotsEntities = createSelector(selectStratalotState, selectEntities);

export const selectStratalotsArray = createSelector(selectStratalotState, selectAll);

export const selectIdStratalotsActive = createSelector(selectStratalotState, (state: StratalotState) => state.actives);

const stratalotsInObject = (stratalots: Dictionary<StratalotEntityState>) => ({ stratalots });

const selectStratalotsEntitiesDictionary = createSelector(selectStratalotsEntities, stratalotsInObject);

const selectAllStratalotsObject = createSelector(selectStratalotsEntities, stratalots => {
  return hydrateAll({ stratalots });
});

const selectOneStratalotDictionary = (idStratalot: number) =>
  createSelector(selectStratalotsEntities, stratalots => {
    return { stratalots: { [idStratalot]: stratalots[idStratalot] } };
  });

const selectOneStratalotDictionaryWithoutChild = (idStratalot: number) =>
  createSelector(selectStratalotsEntities, stratalots => {
    return { stratalot: stratalots[idStratalot] };
  });

const selectActiveStratalotsEntities = createSelector(
  selectIdStratalotsActive,
  selectStratalotsEntities,
  (actives: number[], stratalots: Dictionary<StratalotEntityState>) => getStratalotsFromActives(actives, stratalots)
);

function getStratalotsFromActives(
  actives: number[],
  stratalots: Dictionary<StratalotEntityState>
): Dictionary<StratalotEntityState> {
  return actives.reduce((acc, idActive) => {
    if (stratalots[idActive]) {
      acc[idActive] = stratalots[idActive];
    }
    return acc;
  }, {} as Dictionary<StratalotEntityState>);
}

const selectAllStratalotsSelectors: Dictionary<Selector> = {};
export function selectAllStratalots(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Stratalot>(
      schema,
      selectAllStratalotsSelectors,
      selectStratalotsEntitiesDictionary,
      getRelationSelectors,
      stratalotRelations,
      hydrateAll,
      'stratalot'
    );
  } else {
    return selectAllStratalotsObject;
  }
}

export function selectAllStratalotsDictionary(schema: SelectSchema = {}, customKey: string = 'stratalots'): Selector {
  return createSelector(selectAllStratalots(schema), result => {
    const res = { [customKey]: {} as Dictionary<StratalotEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.stratalots.length; i++) {
      res[customKey][result.stratalots[i].idStratalot] = result.stratalots[i];
    }
    return res;
  });
}

export function selectOneStratalot(schema: SelectSchema = {}, idStratalot: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneStratalotDictionary(idStratalot)];
    selectors.push(...getRelationSelectors(schema, stratalotRelations, 'stratalot'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneStratalotDictionaryWithoutChild(idStratalot);
  }
}

export function selectActiveStratalots(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [createSelector(selectActiveStratalotsEntities, stratalots => ({ stratalots }))];
  selectors.push(...getRelationSelectors(schema, stratalotRelations, 'stratalot'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  stratalots: Dictionary<StratalotEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
  occupants?: Dictionary<OccupantEntityState>;
  companyStratalotTypes?: Dictionary<CompanyStratalotTypeEntityState>;
  companyStratalotVacants?: Dictionary<CompanyStratalotVacantEntityState>;
  organizationSaleCategories?: Dictionary<OrganizationSaleCategoryEntityState>;
  stepProgresses?: Dictionary<StepProgressEntityState>;
  responsable?: Dictionary<UserEntityState>;
  organizationStratalotAvancements?: Dictionary<OrganizationStratalotAvancementEntityState>;
  stratalotCampaigns?: Dictionary<StratalotCampaignEntityState>;
  diffusionVisuals?: Dictionary<DiffusionVisualEntityState>;
  stratalotAssociations?: Dictionary<StratalotAssociationEntityState>;
  stratalotFees?: Dictionary<StratalotFeeEntityState>;
  associations?: Dictionary<AssociationEntityState>;
  leads?: Dictionary<LeadEntityState>;
  leadStratalots?: Dictionary<LeadStratalotEntityState>;
  stratalotPrices?: Dictionary<StratalotPriceEntityState>;
  stratalotRcps?: Dictionary<StratalotRcpEntityState>;
  stratalotTodos?: Dictionary<StratalotTodoEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { stratalots: (Stratalot | null)[] } {
  const {
    stratalots,
    residences,
    occupants,
    companyStratalotTypes,
    companyStratalotVacants,
    organizationSaleCategories,
    stepProgresses,
    responsable,
    organizationStratalotAvancements,
    stratalotCampaigns,
    diffusionVisuals,
    stratalotAssociations,
    stratalotFees,
    associations,
    leads,
    leadStratalots,
    stratalotPrices,
    stratalotRcps,
    stratalotTodos
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    stratalots: Object.keys(stratalots).map(idStratalot =>
      hydrate(
        stratalots[idStratalot] as StratalotEntityState,
        residences,
        occupants,
        companyStratalotTypes,
        companyStratalotVacants,
        organizationSaleCategories,
        stepProgresses,
        responsable,
        organizationStratalotAvancements,
        stratalotCampaigns,
        diffusionVisuals,
        stratalotAssociations,
        stratalotFees,
        associations,
        leads,
        leadStratalots,
        stratalotPrices,
        stratalotRcps,
        stratalotTodos
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { stratalot: StratalotEntityState | null } {
  const {
    stratalots,
    residences,
    occupants,
    companyStratalotTypes,
    companyStratalotVacants,
    organizationSaleCategories,
    stepProgresses,
    responsable,
    organizationStratalotAvancements,
    stratalotCampaigns,
    diffusionVisuals,
    stratalotAssociations,
    stratalotFees,
    associations,
    leads,
    leadStratalots,
    stratalotPrices,
    stratalotRcps,
    stratalotTodos
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const stratalot = Object.values(stratalots)[0];
  return {
    stratalot: hydrate(
      stratalot as StratalotEntityState,
      residences,
      occupants,
      companyStratalotTypes,
      companyStratalotVacants,
      organizationSaleCategories,
      stepProgresses,
      responsable,
      organizationStratalotAvancements,
      stratalotCampaigns,
      diffusionVisuals,
      stratalotAssociations,
      stratalotFees,
      associations,
      leads,
      leadStratalots,
      stratalotPrices,
      stratalotRcps,
      stratalotTodos
    )
  };
}

function hydrate(
  stratalot: StratalotEntityState,
  residenceEntities?: Dictionary<ResidenceEntityState>,
  occupantEntities?: Dictionary<OccupantEntityState>,
  companyStratalotTypeEntities?: Dictionary<CompanyStratalotTypeEntityState>,
  companyStratalotVacantEntities?: Dictionary<CompanyStratalotVacantEntityState>,
  organizationSaleCategoryEntities?: Dictionary<OrganizationSaleCategoryEntityState>,
  stepProgressEntities?: Dictionary<StepProgressEntityState>,
  responsableEntities?: Dictionary<UserEntityState>,
  organizationStratalotAvancementEntities?: Dictionary<OrganizationStratalotAvancementEntityState>,
  stratalotCampaignEntities?: Dictionary<StratalotCampaignEntityState>,
  diffusionVisualEntities?: Dictionary<DiffusionVisualEntityState>,
  stratalotAssociationEntities?: Dictionary<StratalotAssociationEntityState>,
  stratalotFeeEntities?: Dictionary<StratalotFeeEntityState>,
  associationEntities?: Dictionary<AssociationEntityState>,
  leadEntities?: Dictionary<LeadEntityState>,
  leadStratalotEntities?: Dictionary<LeadStratalotEntityState>,
  stratalotPriceEntities?: Dictionary<StratalotPriceEntityState>,
  stratalotRcpEntities?: Dictionary<StratalotRcpEntityState>,
  stratalotTodoEntities?: Dictionary<StratalotTodoEntityState>
): Stratalot | null {
  if (!stratalot) {
    return null;
  }

  const stratalotHydrated: StratalotEntityState = { ...stratalot };
  if (residenceEntities) {
    stratalotHydrated.residence = residenceEntities[stratalot.residence as number] as Residence;
  } else {
    delete stratalotHydrated.residence;
  }
  if (occupantEntities) {
    stratalotHydrated.occupant = occupantEntities[stratalot.occupant as number] as Occupant;
  } else {
    delete stratalotHydrated.occupant;
  }
  if (companyStratalotTypeEntities) {
    stratalotHydrated.companyStratalotType = companyStratalotTypeEntities[
      stratalot.companyStratalotType as number
    ] as CompanyStratalotType;
  } else {
    delete stratalotHydrated.companyStratalotType;
  }
  if (companyStratalotVacantEntities) {
    stratalotHydrated.companyStratalotVacant = companyStratalotVacantEntities[
      stratalot.companyStratalotVacant as number
    ] as CompanyStratalotVacant;
  } else {
    delete stratalotHydrated.companyStratalotVacant;
  }
  if (organizationSaleCategoryEntities) {
    stratalotHydrated.organizationSaleCategory = organizationSaleCategoryEntities[
      stratalot.organizationSaleCategory as number
    ] as OrganizationSaleCategory;
  } else {
    delete stratalotHydrated.organizationSaleCategory;
  }
  if (stepProgressEntities) {
    stratalotHydrated.stepProgress = stepProgressEntities[stratalot.stepProgress as number] as StepProgress;
  } else {
    delete stratalotHydrated.stepProgress;
  }
  if (responsableEntities) {
    stratalotHydrated.responsable = responsableEntities[stratalot.responsable as number] as User;
  } else {
    delete stratalotHydrated.responsable;
  }
  if (organizationStratalotAvancementEntities) {
    stratalotHydrated.organizationStratalotAvancement = organizationStratalotAvancementEntities[
      stratalot.organizationStratalotAvancement as number
    ] as OrganizationStratalotAvancement;
  } else {
    delete stratalotHydrated.organizationStratalotAvancement;
  }

  if (stratalotCampaignEntities) {
    stratalotHydrated.stratalotCampaigns = ((stratalotHydrated.stratalotCampaigns as number[]) || []).map(
      id => stratalotCampaignEntities[id]
    ) as StratalotCampaign[];
  } else {
    delete stratalotHydrated.stratalotCampaigns;
  }

  if (diffusionVisualEntities) {
    stratalotHydrated.diffusionVisuals = ((stratalotHydrated.diffusionVisuals as number[]) || []).map(
      id => diffusionVisualEntities[id]
    ) as DiffusionVisual[];
  } else {
    delete stratalotHydrated.diffusionVisuals;
  }

  if (stratalotAssociationEntities) {
    stratalotHydrated.stratalotAssociations = ((stratalotHydrated.stratalotAssociations as number[]) || []).map(
      id => stratalotAssociationEntities[id]
    ) as StratalotAssociation[];
  } else {
    delete stratalotHydrated.stratalotAssociations;
  }

  if (stratalotFeeEntities) {
    stratalotHydrated.stratalotFees = ((stratalotHydrated.stratalotFees as number[]) || []).map(
      id => stratalotFeeEntities[id]
    ) as StratalotFee[];
  } else {
    delete stratalotHydrated.stratalotFees;
  }

  if (associationEntities) {
    stratalotHydrated.associations = ((stratalotHydrated.associations as number[]) || []).map(
      id => associationEntities[id]
    ) as Association[];
  } else {
    delete stratalotHydrated.associations;
  }

  if (leadEntities) {
    stratalotHydrated.leads = ((stratalotHydrated.leads as number[]) || []).map(id => leadEntities[id]) as Lead[];
  } else {
    delete stratalotHydrated.leads;
  }

  if (leadStratalotEntities) {
    stratalotHydrated.leadStratalots = ((stratalotHydrated.leadStratalots as number[]) || []).map(
      id => leadStratalotEntities[id]
    ) as LeadStratalot[];
  } else {
    delete stratalotHydrated.leadStratalots;
  }

  if (stratalotPriceEntities) {
    stratalotHydrated.stratalotPrices = ((stratalotHydrated.stratalotPrices as number[]) || []).map(
      id => stratalotPriceEntities[id]
    ) as StratalotPrice[];
  } else {
    delete stratalotHydrated.stratalotPrices;
  }

  if (stratalotRcpEntities) {
    stratalotHydrated.stratalotRcp = stratalotRcpEntities[stratalot.stratalotRcp as number] as StratalotRcp;
  } else {
    delete stratalotHydrated.stratalotRcp;
  }

  if (stratalotTodoEntities) {
    stratalotHydrated.stratalotTodos = ((stratalotHydrated.stratalotTodos as number[]) || []).map(
      id => stratalotTodoEntities[id]
    ) as StratalotTodo[];
  } else {
    delete stratalotHydrated.stratalotTodos;
  }

  return stratalotHydrated as Stratalot;
}
