import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { GeneratedDocumentEntityState } from '@_model/interfaces/generated-document.model';

export interface GeneratedDocumentState extends EntityState<GeneratedDocumentEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<GeneratedDocumentEntityState> = createEntityAdapter<GeneratedDocumentEntityState>({
  selectId: o => o.idGeneratedDocument
});
export const initialState: GeneratedDocumentState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const generatedDocumentFeatureKey = 'generatedDocument';
