import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ResidenceStudyReasonApiService } from '@_services/api/residence-study-reason-api.service';
import { GeneratedResidenceStudyReasonEffects } from './residence-study-reason-generated.effects';

@Injectable()
export class ResidenceStudyReasonEffects extends GeneratedResidenceStudyReasonEffects {
  constructor(
    actions$: Actions,
    residenceStudyReasonApiService: ResidenceStudyReasonApiService,
    store$: Store<AppState>
  ) {
    super(actions$, residenceStudyReasonApiService, store$);
  }
}
