import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { ResidenceContact, ResidenceContactEntityState } from '@_model/interfaces/residence-contact.model';
import { ResidenceContactApiService } from '@_services/api/residence-contact-api.service';
import * as residenceContactActions from '@_store/residence-contact/residence-contact.actions';
import { getActionsToNormalizeResidenceContact } from '@_config/store/normalization.generated';
import { selectResidenceContactState } from './residence-contact-generated.selectors';
import * as residenceActions from '@_store/residence/residence.actions';
import * as organizationThirdPartyActions from '@_store/organization-third-party/organization-third-party.actions';

export interface ResidenceContactRelationsIds {
  residence?: number;
  organizationThirdParty?: number;
}

export function getDefaultAddResidenceContactActions(
  residenceContact: ResidenceContactEntityState,
  ids?: ResidenceContactRelationsIds
): Action[] {
  const actions: Action[] = [
    residenceContactActions.normalizeManyResidenceContactsAfterUpsert({ residenceContacts: [residenceContact] })
  ];

  if (ids?.residence) {
    actions.push(
      residenceActions.addManyResidenceContactSuccess({
        idResidence: ids.residence,
        idResidenceContacts: [residenceContact.idResidenceContact]
      })
    );
    actions.push(
      residenceContactActions.addResidenceSuccess({
        idResidenceContact: residenceContact.idResidenceContact,
        idResidence: ids.residence
      })
    );
  }

  if (ids?.organizationThirdParty) {
    actions.push(
      organizationThirdPartyActions.addManyResidenceContactSuccess({
        idOrganizationThirdParty: ids.organizationThirdParty,
        idResidenceContacts: [residenceContact.idResidenceContact]
      })
    );
    actions.push(
      residenceContactActions.addOrganizationThirdPartySuccess({
        idResidenceContact: residenceContact.idResidenceContact,
        idOrganizationThirdParty: ids.organizationThirdParty
      })
    );
  }

  return actions;
}

export function getDefaultDeleteResidenceContactActions(residenceContact: ResidenceContactEntityState): Action[] {
  const actions: Action[] = [
    residenceContactActions.deleteOneResidenceContactSuccess({
      idResidenceContact: residenceContact.idResidenceContact
    })
  ];

  if (residenceContact.residence) {
    actions.push(
      residenceActions.deleteManyResidenceContactSuccess({
        idResidenceContacts: [residenceContact.idResidenceContact],
        idResidences: [residenceContact.residence as number]
      })
    );
  }

  if (residenceContact.organizationThirdParty) {
    actions.push(
      organizationThirdPartyActions.deleteManyResidenceContactSuccess({
        idResidenceContacts: [residenceContact.idResidenceContact],
        idOrganizationThirdParties: [residenceContact.organizationThirdParty as number]
      })
    );
  }

  return actions;
}

export class GeneratedResidenceContactEffects {
  constructor(
    protected actions$: Actions,
    protected residenceContactApiService: ResidenceContactApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidenceContacts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceContactActions.getManyResidenceContacts),
      switchMap(({ params }) =>
        this.residenceContactApiService.getResidenceContacts(params).pipe(
          map((residenceContacts: ResidenceContact[]) => {
            return residenceContactActions.normalizeManyResidenceContactsAfterUpsert({ residenceContacts });
          }),
          catchError(error => of(residenceContactActions.residenceContactsFailure({ error })))
        )
      )
    );
  });

  getOneResidenceContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceContactActions.getOneResidenceContact),
      switchMap(idResidenceContact =>
        this.residenceContactApiService.getResidenceContact(idResidenceContact).pipe(
          map((residenceContact: ResidenceContact) => {
            return residenceContactActions.normalizeManyResidenceContactsAfterUpsert({
              residenceContacts: [residenceContact]
            });
          }),
          catchError(error => of(residenceContactActions.residenceContactsFailure({ error })))
        )
      )
    );
  });

  upsertOneResidenceContact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceContactActions.upsertOneResidenceContact),
      concatMap(
        ({
          residenceContact,
          ids
        }: {
          residenceContact: Partial<ResidenceContact>;
          ids?: ResidenceContactRelationsIds;
        }) => {
          if (residenceContact.idResidenceContact) {
            return this.residenceContactApiService.updateResidenceContact(residenceContact).pipe(
              map((residenceContactReturned: ResidenceContact) => {
                return residenceContactActions.normalizeManyResidenceContactsAfterUpsert({
                  residenceContacts: [residenceContactReturned]
                });
              }),
              catchError(error => of(residenceContactActions.residenceContactsFailure({ error })))
            );
          } else {
            return this.residenceContactApiService.addResidenceContact(residenceContact).pipe(
              mergeMap((residenceContactReturned: ResidenceContact) =>
                getDefaultAddResidenceContactActions(residenceContactReturned, ids)
              ),
              catchError(error => of(residenceContactActions.residenceContactsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneResidenceContact$ = createEffect(() => {
    const selectResidenceContactState$ = this.store$.select(selectResidenceContactState);
    return this.actions$.pipe(
      ofType(residenceContactActions.deleteOneResidenceContact),
      withLatestFrom(selectResidenceContactState$),
      concatMap(([{ idResidenceContact }, state]) =>
        this.residenceContactApiService.deleteResidenceContact(idResidenceContact).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidenceContactActions(
                state.entities[idResidenceContact] as ResidenceContactEntityState
              ),
              residenceContactActions.deleteOneResidenceContact.type
            )
          ]),
          catchError(error => of(residenceContactActions.residenceContactsFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidenceContactsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceContactActions.normalizeManyResidenceContactsAfterUpsert),
      concatMap(({ residenceContacts }) => {
        const actions: Action[] = getActionsToNormalizeResidenceContact(residenceContacts, StoreActionType.upsert);
        return [getMultiAction(actions, '[ResidenceContact] Normalization After Upsert Success')];
      })
    );
  });
}
