import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  ResidencePriceGridHistory,
  ResidencePriceGridHistoryEntityState
} from '@_model/interfaces/residence-price-grid-history.model';
import { ResidencePriceGridHistoryApiService } from '@_services/api/residence-price-grid-history-api.service';
import * as residencePriceGridHistoryActions from '@_store/residence-price-grid-history/residence-price-grid-history.actions';
import { getActionsToNormalizeResidencePriceGridHistory } from '@_config/store/normalization.generated';
import { selectResidencePriceGridHistoryState } from './residence-price-grid-history-generated.selectors';
import * as residencePriceGridActions from '@_store/residence-price-grid/residence-price-grid.actions';
import * as userActions from '@_store/user/user.actions';

export interface ResidencePriceGridHistoryRelationsIds {
  residencePriceGrid?: number;
  user?: number;
}

export function getDefaultAddResidencePriceGridHistoryActions(
  residencePriceGridHistory: ResidencePriceGridHistoryEntityState,
  ids?: ResidencePriceGridHistoryRelationsIds
): Action[] {
  const actions: Action[] = [
    residencePriceGridHistoryActions.normalizeManyResidencePriceGridHistoriesAfterUpsert({
      residencePriceGridHistories: [residencePriceGridHistory]
    })
  ];

  if (ids?.residencePriceGrid) {
    actions.push(
      residencePriceGridActions.addManyResidencePriceGridHistorySuccess({
        idResidencePriceGrid: ids.residencePriceGrid,
        idResidencePriceGridHistories: [residencePriceGridHistory.idResidencePriceGridHistory]
      })
    );
    actions.push(
      residencePriceGridHistoryActions.addResidencePriceGridSuccess({
        idResidencePriceGridHistory: residencePriceGridHistory.idResidencePriceGridHistory,
        idResidencePriceGrid: ids.residencePriceGrid
      })
    );
  }

  if (ids?.user) {
    actions.push(
      userActions.addManyResidencePriceGridHistorySuccess({
        idUser: ids.user,
        idResidencePriceGridHistories: [residencePriceGridHistory.idResidencePriceGridHistory]
      })
    );
    actions.push(
      residencePriceGridHistoryActions.addUserSuccess({
        idResidencePriceGridHistory: residencePriceGridHistory.idResidencePriceGridHistory,
        idUser: ids.user
      })
    );
  }

  return actions;
}

export function getDefaultDeleteResidencePriceGridHistoryActions(
  residencePriceGridHistory: ResidencePriceGridHistoryEntityState
): Action[] {
  const actions: Action[] = [
    residencePriceGridHistoryActions.deleteOneResidencePriceGridHistorySuccess({
      idResidencePriceGridHistory: residencePriceGridHistory.idResidencePriceGridHistory
    })
  ];

  if (residencePriceGridHistory.residencePriceGrid) {
    actions.push(
      residencePriceGridActions.deleteManyResidencePriceGridHistorySuccess({
        idResidencePriceGridHistories: [residencePriceGridHistory.idResidencePriceGridHistory],
        idResidencePriceGrids: [residencePriceGridHistory.residencePriceGrid as number]
      })
    );
  }

  if (residencePriceGridHistory.user) {
    actions.push(
      userActions.deleteManyResidencePriceGridHistorySuccess({
        idResidencePriceGridHistories: [residencePriceGridHistory.idResidencePriceGridHistory],
        idUsers: [residencePriceGridHistory.user as number]
      })
    );
  }

  return actions;
}

export class GeneratedResidencePriceGridHistoryEffects {
  constructor(
    protected actions$: Actions,
    protected residencePriceGridHistoryApiService: ResidencePriceGridHistoryApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidencePriceGridHistories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridHistoryActions.getManyResidencePriceGridHistories),
      switchMap(({ params }) =>
        this.residencePriceGridHistoryApiService.getResidencePriceGridHistories(params).pipe(
          map((residencePriceGridHistories: ResidencePriceGridHistory[]) => {
            return residencePriceGridHistoryActions.normalizeManyResidencePriceGridHistoriesAfterUpsert({
              residencePriceGridHistories
            });
          }),
          catchError(error => of(residencePriceGridHistoryActions.residencePriceGridHistoriesFailure({ error })))
        )
      )
    );
  });

  getOneResidencePriceGridHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridHistoryActions.getOneResidencePriceGridHistory),
      switchMap(idResidencePriceGridHistory =>
        this.residencePriceGridHistoryApiService.getResidencePriceGridHistory(idResidencePriceGridHistory).pipe(
          map((residencePriceGridHistory: ResidencePriceGridHistory) => {
            return residencePriceGridHistoryActions.normalizeManyResidencePriceGridHistoriesAfterUpsert({
              residencePriceGridHistories: [residencePriceGridHistory]
            });
          }),
          catchError(error => of(residencePriceGridHistoryActions.residencePriceGridHistoriesFailure({ error })))
        )
      )
    );
  });

  upsertOneResidencePriceGridHistory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridHistoryActions.upsertOneResidencePriceGridHistory),
      concatMap(
        ({
          residencePriceGridHistory,
          ids
        }: {
          residencePriceGridHistory: Partial<ResidencePriceGridHistory>;
          ids?: ResidencePriceGridHistoryRelationsIds;
        }) => {
          if (residencePriceGridHistory.idResidencePriceGridHistory) {
            return this.residencePriceGridHistoryApiService
              .updateResidencePriceGridHistory(residencePriceGridHistory)
              .pipe(
                map((residencePriceGridHistoryReturned: ResidencePriceGridHistory) => {
                  return residencePriceGridHistoryActions.normalizeManyResidencePriceGridHistoriesAfterUpsert({
                    residencePriceGridHistories: [residencePriceGridHistoryReturned]
                  });
                }),
                catchError(error => of(residencePriceGridHistoryActions.residencePriceGridHistoriesFailure({ error })))
              );
          } else {
            return this.residencePriceGridHistoryApiService
              .addResidencePriceGridHistory(residencePriceGridHistory)
              .pipe(
                mergeMap((residencePriceGridHistoryReturned: ResidencePriceGridHistory) =>
                  getDefaultAddResidencePriceGridHistoryActions(residencePriceGridHistoryReturned, ids)
                ),
                catchError(error => of(residencePriceGridHistoryActions.residencePriceGridHistoriesFailure({ error })))
              );
          }
        }
      )
    );
  });

  deleteOneResidencePriceGridHistory$ = createEffect(() => {
    const selectResidencePriceGridHistoryState$ = this.store$.select(selectResidencePriceGridHistoryState);
    return this.actions$.pipe(
      ofType(residencePriceGridHistoryActions.deleteOneResidencePriceGridHistory),
      withLatestFrom(selectResidencePriceGridHistoryState$),
      concatMap(([{ idResidencePriceGridHistory }, state]) =>
        this.residencePriceGridHistoryApiService.deleteResidencePriceGridHistory(idResidencePriceGridHistory).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidencePriceGridHistoryActions(
                state.entities[idResidencePriceGridHistory] as ResidencePriceGridHistoryEntityState
              ),
              residencePriceGridHistoryActions.deleteOneResidencePriceGridHistory.type
            )
          ]),
          catchError(error => of(residencePriceGridHistoryActions.residencePriceGridHistoriesFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidencePriceGridHistoriesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridHistoryActions.normalizeManyResidencePriceGridHistoriesAfterUpsert),
      concatMap(({ residencePriceGridHistories }) => {
        const actions: Action[] = getActionsToNormalizeResidencePriceGridHistory(
          residencePriceGridHistories,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[ResidencePriceGridHistory] Normalization After Upsert Success')];
      })
    );
  });
}
