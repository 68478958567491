import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User, UserEntityState } from '@_model/interfaces/user.model';
import { BoardState, BoardStateEntityState } from '@_model/interfaces/board-state.model';
import {
  CompanyTerritoireUser,
  CompanyTerritoireUserEntityState
} from '@_model/interfaces/company-territoire-user.model';
import {
  ResidencePriceGridHistory,
  ResidencePriceGridHistoryEntityState
} from '@_model/interfaces/residence-price-grid-history.model';
import { CompanyTerritoire, CompanyTerritoireEntityState } from '@_model/interfaces/company-territoire.model';
import { Prospect, ProspectEntityState } from '@_model/interfaces/prospect.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { ProspectEvent, ProspectEventEntityState } from '@_model/interfaces/prospect-event.model';
import { Profil, ProfilEntityState } from '@_model/interfaces/profil.model';
import {
  CompanyCommunicationRecipient,
  CompanyCommunicationRecipientEntityState
} from '@_model/interfaces/company-communication-recipient.model';
import {
  CompanyCommunicationRecipientUser,
  CompanyCommunicationRecipientUserEntityState
} from '@_model/interfaces/company-communication-recipient-user.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, userFeatureKey, UserState } from './user.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const userRelations: string[] = [
  'boardStates',
  'companyTerritoireUsers',
  'residencePriceGridHistories',
  'companyTerritoires',
  'prospectResponsibleOfs',
  'prospectUpdaters',
  'prospectCreators',
  'residences',
  'stratalots',
  'prospectEvents',
  'profils',
  'companyCommunicationRecipients',
  'companyCommunicationRecipientUsers',
  'organizations'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectUserState = createFeatureSelector<UserState>(userFeatureKey);

export const selectIsLoadedUser = createSelector(selectUserState, (state: UserState) => state.isLoaded);

export const selectIsLoadingUser = createSelector(selectUserState, (state: UserState) => state.isLoading);

export const selectIsReadyUser = createSelector(selectUserState, (state: UserState) => !state.isLoading);

export const selectIsReadyAndLoadedUser = createSelector(
  selectUserState,
  (state: UserState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const UserModel: SelectorModel = {
  name: 'users',
  getSelector: selectAllUsersDictionary,
  isReady: selectIsReadyUser
};

export const selectUsersEntities = createSelector(selectUserState, selectEntities);

export const selectUsersArray = createSelector(selectUserState, selectAll);

export const selectIdUsersActive = createSelector(selectUserState, (state: UserState) => state.actives);

const usersInObject = (users: Dictionary<UserEntityState>) => ({ users });

const selectUsersEntitiesDictionary = createSelector(selectUsersEntities, usersInObject);

const selectAllUsersObject = createSelector(selectUsersEntities, users => {
  return hydrateAll({ users });
});

const selectOneUserDictionary = (idUser: number) =>
  createSelector(selectUsersEntities, users => {
    return { users: { [idUser]: users[idUser] } };
  });

const selectOneUserDictionaryWithoutChild = (idUser: number) =>
  createSelector(selectUsersEntities, users => {
    return { user: users[idUser] };
  });

const selectActiveUsersEntities = createSelector(
  selectIdUsersActive,
  selectUsersEntities,
  (actives: number[], users: Dictionary<UserEntityState>) => getUsersFromActives(actives, users)
);

function getUsersFromActives(actives: number[], users: Dictionary<UserEntityState>): Dictionary<UserEntityState> {
  return actives.reduce((acc, idActive) => {
    if (users[idActive]) {
      acc[idActive] = users[idActive];
    }
    return acc;
  }, {} as Dictionary<UserEntityState>);
}

const selectAllUsersSelectors: Dictionary<Selector> = {};
export function selectAllUsers(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<User>(
      schema,
      selectAllUsersSelectors,
      selectUsersEntitiesDictionary,
      getRelationSelectors,
      userRelations,
      hydrateAll,
      'user'
    );
  } else {
    return selectAllUsersObject;
  }
}

export function selectAllUsersDictionary(schema: SelectSchema = {}, customKey: string = 'users'): Selector {
  return createSelector(selectAllUsers(schema), result => {
    const res = { [customKey]: {} as Dictionary<UserEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.users.length; i++) {
      res[customKey][result.users[i].idUser] = result.users[i];
    }
    return res;
  });
}

export function selectOneUser(schema: SelectSchema = {}, idUser: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneUserDictionary(idUser)];
    selectors.push(...getRelationSelectors(schema, userRelations, 'user'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneUserDictionaryWithoutChild(idUser);
  }
}

export function selectActiveUsers(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [createSelector(selectActiveUsersEntities, users => ({ users }))];
  selectors.push(...getRelationSelectors(schema, userRelations, 'user'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  users: Dictionary<UserEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  boardStates?: Dictionary<BoardStateEntityState>;
  companyTerritoireUsers?: Dictionary<CompanyTerritoireUserEntityState>;
  residencePriceGridHistories?: Dictionary<ResidencePriceGridHistoryEntityState>;
  companyTerritoires?: Dictionary<CompanyTerritoireEntityState>;
  prospectResponsibleOf?: Dictionary<ProspectEntityState>;
  prospectUpdater?: Dictionary<ProspectEntityState>;
  prospectCreator?: Dictionary<ProspectEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
  prospectEvents?: Dictionary<ProspectEventEntityState>;
  profils?: Dictionary<ProfilEntityState>;
  companyCommunicationRecipients?: Dictionary<CompanyCommunicationRecipientEntityState>;
  companyCommunicationRecipientUsers?: Dictionary<CompanyCommunicationRecipientUserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { users: (User | null)[] } {
  const {
    users,
    organizations,
    boardStates,
    companyTerritoireUsers,
    residencePriceGridHistories,
    companyTerritoires,
    prospectResponsibleOf,
    prospectUpdater,
    prospectCreator,
    residences,
    stratalots,
    prospectEvents,
    profils,
    companyCommunicationRecipients,
    companyCommunicationRecipientUsers
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    users: Object.keys(users).map(idUser =>
      hydrate(
        users[idUser] as UserEntityState,
        organizations,
        boardStates,
        companyTerritoireUsers,
        residencePriceGridHistories,
        companyTerritoires,
        prospectResponsibleOf,
        prospectUpdater,
        prospectCreator,
        residences,
        stratalots,
        prospectEvents,
        profils,
        companyCommunicationRecipients,
        companyCommunicationRecipientUsers
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { user: UserEntityState | null } {
  const {
    users,
    organizations,
    boardStates,
    companyTerritoireUsers,
    residencePriceGridHistories,
    companyTerritoires,
    prospectResponsibleOf,
    prospectUpdater,
    prospectCreator,
    residences,
    stratalots,
    prospectEvents,
    profils,
    companyCommunicationRecipients,
    companyCommunicationRecipientUsers
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const user = Object.values(users)[0];
  return {
    user: hydrate(
      user as UserEntityState,
      organizations,
      boardStates,
      companyTerritoireUsers,
      residencePriceGridHistories,
      companyTerritoires,
      prospectResponsibleOf,
      prospectUpdater,
      prospectCreator,
      residences,
      stratalots,
      prospectEvents,
      profils,
      companyCommunicationRecipients,
      companyCommunicationRecipientUsers
    )
  };
}

function hydrate(
  user: UserEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  boardStateEntities?: Dictionary<BoardStateEntityState>,
  companyTerritoireUserEntities?: Dictionary<CompanyTerritoireUserEntityState>,
  residencePriceGridHistoryEntities?: Dictionary<ResidencePriceGridHistoryEntityState>,
  companyTerritoireEntities?: Dictionary<CompanyTerritoireEntityState>,
  prospectResponsibleOfEntities?: Dictionary<ProspectEntityState>,
  prospectUpdaterEntities?: Dictionary<ProspectEntityState>,
  prospectCreatorEntities?: Dictionary<ProspectEntityState>,
  residenceEntities?: Dictionary<ResidenceEntityState>,
  stratalotEntities?: Dictionary<StratalotEntityState>,
  prospectEventEntities?: Dictionary<ProspectEventEntityState>,
  profilEntities?: Dictionary<ProfilEntityState>,
  companyCommunicationRecipientEntities?: Dictionary<CompanyCommunicationRecipientEntityState>,
  companyCommunicationRecipientUserEntities?: Dictionary<CompanyCommunicationRecipientUserEntityState>
): User | null {
  if (!user) {
    return null;
  }

  const userHydrated: UserEntityState = { ...user };
  if (organizationEntities) {
    userHydrated.organization = organizationEntities[user.organization as number] as Organization;
  } else {
    delete userHydrated.organization;
  }

  if (boardStateEntities) {
    userHydrated.boardStates = ((userHydrated.boardStates as number[]) || []).map(
      id => boardStateEntities[id]
    ) as BoardState[];
  } else {
    delete userHydrated.boardStates;
  }

  if (companyTerritoireUserEntities) {
    userHydrated.companyTerritoireUsers = ((userHydrated.companyTerritoireUsers as number[]) || []).map(
      id => companyTerritoireUserEntities[id]
    ) as CompanyTerritoireUser[];
  } else {
    delete userHydrated.companyTerritoireUsers;
  }

  if (residencePriceGridHistoryEntities) {
    userHydrated.residencePriceGridHistories = ((userHydrated.residencePriceGridHistories as number[]) || []).map(
      id => residencePriceGridHistoryEntities[id]
    ) as ResidencePriceGridHistory[];
  } else {
    delete userHydrated.residencePriceGridHistories;
  }

  if (companyTerritoireEntities) {
    userHydrated.companyTerritoires = ((userHydrated.companyTerritoires as number[]) || []).map(
      id => companyTerritoireEntities[id]
    ) as CompanyTerritoire[];
  } else {
    delete userHydrated.companyTerritoires;
  }

  if (prospectResponsibleOfEntities) {
    userHydrated.prospectResponsibleOf = ((userHydrated.prospectResponsibleOf as number[]) || []).map(
      id => prospectResponsibleOfEntities[id]
    ) as Prospect[];
  } else {
    delete userHydrated.prospectResponsibleOf;
  }

  if (prospectUpdaterEntities) {
    userHydrated.prospectUpdater = ((userHydrated.prospectUpdater as number[]) || []).map(
      id => prospectUpdaterEntities[id]
    ) as Prospect[];
  } else {
    delete userHydrated.prospectUpdater;
  }

  if (prospectCreatorEntities) {
    userHydrated.prospectCreator = ((userHydrated.prospectCreator as number[]) || []).map(
      id => prospectCreatorEntities[id]
    ) as Prospect[];
  } else {
    delete userHydrated.prospectCreator;
  }

  if (residenceEntities) {
    userHydrated.residences = ((userHydrated.residences as number[]) || []).map(
      id => residenceEntities[id]
    ) as Residence[];
  } else {
    delete userHydrated.residences;
  }

  if (stratalotEntities) {
    userHydrated.stratalots = ((userHydrated.stratalots as number[]) || []).map(
      id => stratalotEntities[id]
    ) as Stratalot[];
  } else {
    delete userHydrated.stratalots;
  }

  if (prospectEventEntities) {
    userHydrated.prospectEvents = ((userHydrated.prospectEvents as number[]) || []).map(
      id => prospectEventEntities[id]
    ) as ProspectEvent[];
  } else {
    delete userHydrated.prospectEvents;
  }

  if (profilEntities) {
    userHydrated.profils = ((userHydrated.profils as number[]) || []).map(id => profilEntities[id]) as Profil[];
  } else {
    delete userHydrated.profils;
  }

  if (companyCommunicationRecipientEntities) {
    userHydrated.companyCommunicationRecipients = ((userHydrated.companyCommunicationRecipients as number[]) || []).map(
      id => companyCommunicationRecipientEntities[id]
    ) as CompanyCommunicationRecipient[];
  } else {
    delete userHydrated.companyCommunicationRecipients;
  }

  if (companyCommunicationRecipientUserEntities) {
    userHydrated.companyCommunicationRecipientUsers = (
      (userHydrated.companyCommunicationRecipientUsers as number[]) || []
    ).map(id => companyCommunicationRecipientUserEntities[id]) as CompanyCommunicationRecipientUser[];
  } else {
    delete userHydrated.companyCommunicationRecipientUsers;
  }

  return userHydrated as User;
}
