import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyStudyCriteriaApiService } from '@_services/api/company-study-criteria-api.service';
import { GeneratedCompanyStudyCriteriaEffects } from './company-study-criteria-generated.effects';

@Injectable()
export class CompanyStudyCriteriaEffects extends GeneratedCompanyStudyCriteriaEffects {
  constructor(
    actions$: Actions,
    companyStudyCriteriaApiService: CompanyStudyCriteriaApiService,
    store$: Store<AppState>
  ) {
    super(actions$, companyStudyCriteriaApiService, store$);
  }
}
