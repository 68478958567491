import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CompanyApiService } from '@_services/api/company-api.service';
import * as companyActions from '@_store/company/company.actions';
import { AppState } from '@_store/index.reducers';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { GeneratedCompanyEffects } from './company-generated.effects';

@Injectable()
export class CompanyEffects extends GeneratedCompanyEffects {
  constructor(actions$: Actions, companyApiService: CompanyApiService, store$: Store<AppState>) {
    super(actions$, companyApiService, store$);
  }

  uploadLogo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyActions.uploadLogo),
      concatMap(company =>
        this.companyApiService.uploadLogo(company.idCompany, company.files, company.logoNum).pipe(
          map(companyReturned => {
            return companyActions.normalizeManyCompaniesAfterUpsert({ companies: [companyReturned] });
          }),
          catchError(error => of(companyActions.companiesFailure({ error })))
        )
      )
    );
  });
}
