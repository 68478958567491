import { RepositoryService } from '@_services/api/repository.service';
import { SaleCategoryFamily } from '@_model/interfaces/sale-category-family.model';
import { Observable } from 'rxjs';

export class GeneratedSaleCategoryFamilyApiService {
  constructor(protected repo: RepositoryService) {}

  public getFamilySaleCategories(params?: any): Observable<SaleCategoryFamily[]> {
    return this.repo.getData<SaleCategoryFamily[]>('sale-category-family', params);
  }

  public getSaleCategoryFamily(params: { idSaleCategoryFamily: number; params?: any }): Observable<SaleCategoryFamily> {
    return this.repo.getData<SaleCategoryFamily>('sale-category-family/' + params.idSaleCategoryFamily, params.params);
  }

  public addSaleCategoryFamily(saleCategoryFamily: Partial<SaleCategoryFamily>): Observable<SaleCategoryFamily> {
    return this.repo.create<SaleCategoryFamily>('sale-category-family', saleCategoryFamily);
  }

  public updateSaleCategoryFamily(saleCategoryFamily: Partial<SaleCategoryFamily>): Observable<SaleCategoryFamily> {
    return this.repo.update('sale-category-family', saleCategoryFamily);
  }

  public deleteSaleCategoryFamily(idSaleCategoryFamily: number): Observable<number> {
    return this.repo.delete('sale-category-family/' + +idSaleCategoryFamily);
  }
}
