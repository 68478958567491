import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { DiagnosticEntityState } from '@_model/interfaces/diagnostic.model';

export interface DiagnosticState extends EntityState<DiagnosticEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<DiagnosticEntityState> = createEntityAdapter<DiagnosticEntityState>({
  selectId: o => o.idDiagnostic
});
export const initialState: DiagnosticState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const diagnosticFeatureKey = 'diagnostic';
