import { LeadStratalotState, initialState } from './lead-stratalot.state';
import { Action, createReducer } from '@ngrx/store';
import { leadStratalotReducersGeneratedFunctions } from './lead-stratalot-generated.reducer';

const leadStratalotReducersFunctions = [...leadStratalotReducersGeneratedFunctions];

const leadStratalotReducer = createReducer(initialState, ...leadStratalotReducersFunctions);

export function reducer(state: LeadStratalotState | undefined, action: Action) {
  return leadStratalotReducer(state, action);
}
