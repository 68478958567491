import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationSaleCategoryEntityState } from '@_model/interfaces/organization-sale-category.model';

export interface OrganizationSaleCategoryState extends EntityState<OrganizationSaleCategoryEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationSaleCategoryEntityState> =
  createEntityAdapter<OrganizationSaleCategoryEntityState>({
    selectId: o => o.idOrganizationSaleCategory
  });
export const initialState: OrganizationSaleCategoryState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationSaleCategoryFeatureKey = 'organizationSaleCategory';
