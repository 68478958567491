import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { OrganizationRelationsIds } from './organization-generated.effects';

export const getOneOrganization = createAction(
  '[Organization] Get One Organization',
  props<{ idOrganization: number; params?: any }>()
);

export const getManyOrganizations = createAction('[Organization] Get Many Organizations', props<{ params: any }>());

export const addManyActives = createAction(
  '[Organization] Add Many Actives Organization',
  props<{ idOrganizations: number[] }>()
);

export const deleteOneActive = createAction(
  '[Organization] Delete One Active Organization',
  props<{ idOrganization: number }>()
);

export const clearActive = createAction('[Organization] Clear Active Organization');

export const upsertOneOrganization = createAction(
  '[Organization] Upsert One Organization',
  props<{
    organization: Partial<Organization>;
    ids?: OrganizationRelationsIds;
  }>()
);

export const upsertManyOrganizations = createAction(
  '[Organization] Upsert Many Organizations',
  props<{
    organizations: Partial<Organization>[];
    ids?: OrganizationRelationsIds;
  }>()
);

export const upsertManyOrganizationsSuccess = createAction(
  '[Organization] Create Many Organizations Success',
  props<{ organizations: OrganizationEntityState[] }>()
);

export const deleteOneOrganization = createAction(
  '[Organization] Delete One Organization',
  props<{ idOrganization: number }>()
);

export const deleteOneOrganizationSuccess = createAction(
  '[Organization] Delete One Organization Success',
  props<{ idOrganization: number }>()
);

export const normalizeManyOrganizationsAfterUpsert = createAction(
  '[Organization] Normalize Many Organizations After Upsert',
  props<{ organizations: OrganizationEntityState[] }>()
);

export const organizationsFailure = createAction(
  '[Organization] Organizations Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[Organization] Clear Organizations');

export const addManyCompanySuccess = createAction(
  '[Organization] Add Many company',
  props<{ idOrganization: number; idCompanies: number[] }>()
);

export const deleteManyCompanySuccess = createAction(
  '[Organization] Delete Many Companies',
  props<{ idCompanies: number[]; idOrganizations: number[] }>()
);

export const addManyUserSuccess = createAction(
  '[Organization] Add Many user',
  props<{ idOrganization: number; idUsers: number[] }>()
);

export const deleteManyUserSuccess = createAction(
  '[Organization] Delete Many Users',
  props<{ idUsers: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationMetierSuccess = createAction(
  '[Organization] Add Many organization-metier',
  props<{ idOrganization: number; idOrganizationMetiers: number[] }>()
);

export const deleteManyOrganizationMetierSuccess = createAction(
  '[Organization] Delete Many OrganizationMetiers',
  props<{ idOrganizationMetiers: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationSocieteSuccess = createAction(
  '[Organization] Add Many organization-societe',
  props<{ idOrganization: number; idOrganizationSocietes: number[] }>()
);

export const deleteManyOrganizationSocieteSuccess = createAction(
  '[Organization] Delete Many OrganizationSocietes',
  props<{ idOrganizationSocietes: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationLeadTodoSuccess = createAction(
  '[Organization] Add Many organization-lead-todo',
  props<{ idOrganization: number; idOrganizationLeadTodos: number[] }>()
);

export const deleteManyOrganizationLeadTodoSuccess = createAction(
  '[Organization] Delete Many OrganizationLeadTodos',
  props<{ idOrganizationLeadTodos: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationLeadTodoRuleSuccess = createAction(
  '[Organization] Add Many organization-lead-todo-rule',
  props<{ idOrganization: number; idOrganizationLeadTodoRules: number[] }>()
);

export const deleteManyOrganizationLeadTodoRuleSuccess = createAction(
  '[Organization] Delete Many OrganizationLeadTodoRules',
  props<{ idOrganizationLeadTodoRules: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationStratalotTodoRuleSuccess = createAction(
  '[Organization] Add Many organization-stratalot-todo-rule',
  props<{ idOrganization: number; idOrganizationStratalotTodoRules: number[] }>()
);

export const deleteManyOrganizationStratalotTodoRuleSuccess = createAction(
  '[Organization] Delete Many OrganizationStratalotTodoRules',
  props<{ idOrganizationStratalotTodoRules: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationResidenceTodoRuleSuccess = createAction(
  '[Organization] Add Many organization-residence-todo-rule',
  props<{ idOrganization: number; idOrganizationResidenceTodoRules: number[] }>()
);

export const deleteManyOrganizationResidenceTodoRuleSuccess = createAction(
  '[Organization] Delete Many OrganizationResidenceTodoRules',
  props<{ idOrganizationResidenceTodoRules: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationResidenceTodoSuccess = createAction(
  '[Organization] Add Many organization-residence-todo',
  props<{ idOrganization: number; idOrganizationResidenceTodos: number[] }>()
);

export const deleteManyOrganizationResidenceTodoSuccess = createAction(
  '[Organization] Delete Many OrganizationResidenceTodos',
  props<{ idOrganizationResidenceTodos: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationStratalotTodoSuccess = createAction(
  '[Organization] Add Many organization-stratalot-todo',
  props<{ idOrganization: number; idOrganizationStratalotTodos: number[] }>()
);

export const deleteManyOrganizationStratalotTodoSuccess = createAction(
  '[Organization] Delete Many OrganizationStratalotTodos',
  props<{ idOrganizationStratalotTodos: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationResidenceStudyTodoSuccess = createAction(
  '[Organization] Add Many organization-residence-study-todo',
  props<{ idOrganization: number; idOrganizationResidenceStudyTodos: number[] }>()
);

export const deleteManyOrganizationResidenceStudyTodoSuccess = createAction(
  '[Organization] Delete Many OrganizationResidenceStudyTodos',
  props<{ idOrganizationResidenceStudyTodos: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationProfilSuccess = createAction(
  '[Organization] Add Many organization-profil',
  props<{ idOrganization: number; idOrganizationProfils: number[] }>()
);

export const deleteManyOrganizationProfilSuccess = createAction(
  '[Organization] Delete Many OrganizationProfils',
  props<{ idOrganizationProfils: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationProspectOriginSuccess = createAction(
  '[Organization] Add Many organization-prospect-origin',
  props<{ idOrganization: number; idOrganizationProspectOrigins: number[] }>()
);

export const deleteManyOrganizationProspectOriginSuccess = createAction(
  '[Organization] Delete Many OrganizationProspectOrigins',
  props<{ idOrganizationProspectOrigins: number[]; idOrganizations: number[] }>()
);

export const addManyProspectSuccess = createAction(
  '[Organization] Add Many prospect',
  props<{ idOrganization: number; idProspects: number[] }>()
);

export const deleteManyProspectSuccess = createAction(
  '[Organization] Delete Many Prospects',
  props<{ idProspects: number[]; idOrganizations: number[] }>()
);

export const addManyProfilSuccess = createAction(
  '[Organization] Add Many profil',
  props<{ idOrganization: number; idProfils: number[] }>()
);

export const deleteManyProfilSuccess = createAction(
  '[Organization] Delete Many Profils',
  props<{ idProfils: number[]; idOrganizations: number[] }>()
);

export const addManyGeneratedDocumentSuccess = createAction(
  '[Organization] Add Many generated-document',
  props<{ idOrganization: number; idGeneratedDocuments: number[] }>()
);

export const deleteManyGeneratedDocumentSuccess = createAction(
  '[Organization] Delete Many GeneratedDocuments',
  props<{ idGeneratedDocuments: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationThirdPartySuccess = createAction(
  '[Organization] Add Many organization-third-party',
  props<{ idOrganization: number; idOrganizationThirdParties: number[] }>()
);

export const deleteManyOrganizationThirdPartySuccess = createAction(
  '[Organization] Delete Many OrganizationThirdParties',
  props<{ idOrganizationThirdParties: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationStratalotAvancementSuccess = createAction(
  '[Organization] Add Many organization-stratalot-avancement',
  props<{ idOrganization: number; idOrganizationStratalotAvancements: number[] }>()
);

export const deleteManyOrganizationStratalotAvancementSuccess = createAction(
  '[Organization] Delete Many OrganizationStratalotAvancements',
  props<{ idOrganizationStratalotAvancements: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationLeadAvancementSuccess = createAction(
  '[Organization] Add Many organization-lead-avancement',
  props<{ idOrganization: number; idOrganizationLeadAvancements: number[] }>()
);

export const deleteManyOrganizationLeadAvancementSuccess = createAction(
  '[Organization] Delete Many OrganizationLeadAvancements',
  props<{ idOrganizationLeadAvancements: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationSaleCategorySuccess = createAction(
  '[Organization] Add Many organization-sale-category',
  props<{ idOrganization: number; idOrganizationSaleCategories: number[] }>()
);

export const deleteManyOrganizationSaleCategorySuccess = createAction(
  '[Organization] Delete Many OrganizationSaleCategories',
  props<{ idOrganizationSaleCategories: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationBuyingWishSuccess = createAction(
  '[Organization] Add Many organization-buying-wish',
  props<{ idOrganization: number; idOrganizationBuyingWishes: number[] }>()
);

export const deleteManyOrganizationBuyingWishSuccess = createAction(
  '[Organization] Delete Many OrganizationBuyingWishes',
  props<{ idOrganizationBuyingWishes: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationEnergieSuccess = createAction(
  '[Organization] Add Many organization-energie',
  props<{ idOrganization: number; idOrganizationEnergies: number[] }>()
);

export const deleteManyOrganizationEnergieSuccess = createAction(
  '[Organization] Delete Many OrganizationEnergies',
  props<{ idOrganizationEnergies: number[]; idOrganizations: number[] }>()
);

export const addManyOrganizationForecastRateSuccess = createAction(
  '[Organization] Add Many organization-forecast-rate',
  props<{ idOrganization: number; idOrganizationForecastRates: number[] }>()
);

export const deleteManyOrganizationForecastRateSuccess = createAction(
  '[Organization] Delete Many OrganizationForecastRates',
  props<{ idOrganizationForecastRates: number[]; idOrganizations: number[] }>()
);
