import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidenceShareRcpActions from './residence-share-rcp.actions';
import { adapter, initialState, ResidenceShareRcpState } from './residence-share-rcp.state';
import { Dictionary } from '@ngrx/entity';
import { ResidenceShareRcpEntityState } from '@_model/interfaces/residence-share-rcp.model';

export const residenceShareRcpReducersGeneratedFunctions: ReducerTypes<
  ResidenceShareRcpState,
  readonly ActionCreator[]
>[] = [
  on(ResidenceShareRcpActions.getOneResidenceShareRcp, (state: ResidenceShareRcpState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceShareRcpActions.getManyResidenceShareRcps, (state: ResidenceShareRcpState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceShareRcpActions.upsertOneResidenceShareRcp, (state: ResidenceShareRcpState) =>
    setLoadingsState(state, true)
  ),

  on(
    ResidenceShareRcpActions.upsertManyResidenceShareRcpsSuccess,
    (state: ResidenceShareRcpState, { residenceShareRcps }) =>
      adapter.upsertMany(residenceShareRcps, setLoadingsState(state, false))
  ),
  on(ResidenceShareRcpActions.deleteOneResidenceShareRcp, (state: ResidenceShareRcpState) =>
    setLoadingsState(state, true)
  ),
  on(
    ResidenceShareRcpActions.deleteOneResidenceShareRcpSuccess,
    (state: ResidenceShareRcpState, { idResidenceShareRcp }) =>
      adapter.removeOne(idResidenceShareRcp, setLoadingsState(state, false))
  ),
  on(ResidenceShareRcpActions.clearActive, (state: ResidenceShareRcpState) => ({ ...state, actives: [] })),
  on(ResidenceShareRcpActions.addManyActives, (state: ResidenceShareRcpState, { idResidenceShareRcps }) => ({
    ...state,
    actives: state.actives.concat(idResidenceShareRcps)
  })),
  on(ResidenceShareRcpActions.deleteOneActive, (state: ResidenceShareRcpState, { idResidenceShareRcp }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idResidenceShareRcp)
  })),

  on(ResidenceShareRcpActions.clearStore, () => initialState),

  on(
    ResidenceShareRcpActions.addStratalotRcpSuccess,
    (state: ResidenceShareRcpState, { idResidenceShareRcp, idStratalotRcp }) => {
      if (!state.entities[idResidenceShareRcp]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceShareRcp]: {
            ...state.entities[idResidenceShareRcp],
            stratalotRcp: idStratalotRcp
          }
        }
      };
    }
  ),

  on(
    ResidenceShareRcpActions.deleteManyStratalotRcpSuccess,
    (state: ResidenceShareRcpState, { idStratalotRcps, idResidenceShareRcps }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceShareRcps.reduce((entities, idResidenceShareRcp) => {
            if (!state.entities[idResidenceShareRcp]?.stratalotRcp) {
              return entities;
            }
            entities[idResidenceShareRcp] = {
              ...state.entities[idResidenceShareRcp],
              stratalotRcp: idStratalotRcps.some(
                (idStratalotRcp: number) => idStratalotRcp === state.entities[idResidenceShareRcp]?.stratalotRcp
              )
                ? undefined
                : state.entities[idResidenceShareRcp]?.stratalotRcp
            } as ResidenceShareRcpEntityState;
            return entities;
          }, {} as Dictionary<ResidenceShareRcpEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidenceShareRcpState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidenceShareRcpState {
  return { ...state, isLoaded, isLoading };
}
