import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StratalotAssociation, StratalotAssociationEntityState } from '@_model/interfaces/stratalot-association.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { Association, AssociationEntityState } from '@_model/interfaces/association.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, stratalotAssociationFeatureKey, StratalotAssociationState } from './stratalot-association.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const stratalotAssociationRelations: string[] = ['stratalots', 'associations'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectStratalotAssociationState =
  createFeatureSelector<StratalotAssociationState>(stratalotAssociationFeatureKey);

export const selectIsLoadedStratalotAssociation = createSelector(
  selectStratalotAssociationState,
  (state: StratalotAssociationState) => state.isLoaded
);

export const selectIsLoadingStratalotAssociation = createSelector(
  selectStratalotAssociationState,
  (state: StratalotAssociationState) => state.isLoading
);

export const selectIsReadyStratalotAssociation = createSelector(
  selectStratalotAssociationState,
  (state: StratalotAssociationState) => !state.isLoading
);

export const selectIsReadyAndLoadedStratalotAssociation = createSelector(
  selectStratalotAssociationState,
  (state: StratalotAssociationState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const StratalotAssociationModel: SelectorModel = {
  name: 'stratalotAssociations',
  getSelector: selectAllStratalotAssociationsDictionary,
  isReady: selectIsReadyStratalotAssociation
};

export const selectStratalotAssociationsEntities = createSelector(selectStratalotAssociationState, selectEntities);

export const selectStratalotAssociationsArray = createSelector(selectStratalotAssociationState, selectAll);

export const selectIdStratalotAssociationsActive = createSelector(
  selectStratalotAssociationState,
  (state: StratalotAssociationState) => state.actives
);

const stratalotAssociationsInObject = (stratalotAssociations: Dictionary<StratalotAssociationEntityState>) => ({
  stratalotAssociations
});

const selectStratalotAssociationsEntitiesDictionary = createSelector(
  selectStratalotAssociationsEntities,
  stratalotAssociationsInObject
);

const selectAllStratalotAssociationsObject = createSelector(
  selectStratalotAssociationsEntities,
  stratalotAssociations => {
    return hydrateAll({ stratalotAssociations });
  }
);

const selectOneStratalotAssociationDictionary = (idStratalotAssociation: number) =>
  createSelector(selectStratalotAssociationsEntities, stratalotAssociations => {
    return { stratalotAssociations: { [idStratalotAssociation]: stratalotAssociations[idStratalotAssociation] } };
  });

const selectOneStratalotAssociationDictionaryWithoutChild = (idStratalotAssociation: number) =>
  createSelector(selectStratalotAssociationsEntities, stratalotAssociations => {
    return { stratalotAssociation: stratalotAssociations[idStratalotAssociation] };
  });

const selectActiveStratalotAssociationsEntities = createSelector(
  selectIdStratalotAssociationsActive,
  selectStratalotAssociationsEntities,
  (actives: number[], stratalotAssociations: Dictionary<StratalotAssociationEntityState>) =>
    getStratalotAssociationsFromActives(actives, stratalotAssociations)
);

function getStratalotAssociationsFromActives(
  actives: number[],
  stratalotAssociations: Dictionary<StratalotAssociationEntityState>
): Dictionary<StratalotAssociationEntityState> {
  return actives.reduce((acc, idActive) => {
    if (stratalotAssociations[idActive]) {
      acc[idActive] = stratalotAssociations[idActive];
    }
    return acc;
  }, {} as Dictionary<StratalotAssociationEntityState>);
}

const selectAllStratalotAssociationsSelectors: Dictionary<Selector> = {};
export function selectAllStratalotAssociations(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<StratalotAssociation>(
      schema,
      selectAllStratalotAssociationsSelectors,
      selectStratalotAssociationsEntitiesDictionary,
      getRelationSelectors,
      stratalotAssociationRelations,
      hydrateAll,
      'stratalotAssociation'
    );
  } else {
    return selectAllStratalotAssociationsObject;
  }
}

export function selectAllStratalotAssociationsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'stratalotAssociations'
): Selector {
  return createSelector(selectAllStratalotAssociations(schema), result => {
    const res = { [customKey]: {} as Dictionary<StratalotAssociationEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.stratalotAssociations.length; i++) {
      res[customKey][result.stratalotAssociations[i].idStratalotAssociation] = result.stratalotAssociations[i];
    }
    return res;
  });
}

export function selectOneStratalotAssociation(schema: SelectSchema = {}, idStratalotAssociation: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneStratalotAssociationDictionary(idStratalotAssociation)];
    selectors.push(...getRelationSelectors(schema, stratalotAssociationRelations, 'stratalotAssociation'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneStratalotAssociationDictionaryWithoutChild(idStratalotAssociation);
  }
}

export function selectActiveStratalotAssociations(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveStratalotAssociationsEntities, stratalotAssociations => ({ stratalotAssociations }))
  ];
  selectors.push(...getRelationSelectors(schema, stratalotAssociationRelations, 'stratalotAssociation'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  stratalotAssociations: Dictionary<StratalotAssociationEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
  associations?: Dictionary<AssociationEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { stratalotAssociations: (StratalotAssociation | null)[] } {
  const { stratalotAssociations, stratalots, associations } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    stratalotAssociations: Object.keys(stratalotAssociations).map(idStratalotAssociation =>
      hydrate(
        stratalotAssociations[idStratalotAssociation] as StratalotAssociationEntityState,
        stratalots,
        associations
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { stratalotAssociation: StratalotAssociationEntityState | null } {
  const { stratalotAssociations, stratalots, associations } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const stratalotAssociation = Object.values(stratalotAssociations)[0];
  return {
    stratalotAssociation: hydrate(stratalotAssociation as StratalotAssociationEntityState, stratalots, associations)
  };
}

function hydrate(
  stratalotAssociation: StratalotAssociationEntityState,
  stratalotEntities?: Dictionary<StratalotEntityState>,
  associationEntities?: Dictionary<AssociationEntityState>
): StratalotAssociation | null {
  if (!stratalotAssociation) {
    return null;
  }

  const stratalotAssociationHydrated: StratalotAssociationEntityState = { ...stratalotAssociation };
  if (stratalotEntities) {
    stratalotAssociationHydrated.stratalot = stratalotEntities[stratalotAssociation.stratalot as number] as Stratalot;
  } else {
    delete stratalotAssociationHydrated.stratalot;
  }
  if (associationEntities) {
    stratalotAssociationHydrated.association = associationEntities[
      stratalotAssociation.association as number
    ] as Association;
  } else {
    delete stratalotAssociationHydrated.association;
  }

  return stratalotAssociationHydrated as StratalotAssociation;
}
