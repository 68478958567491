import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '@_environments/environment';
import { AppState, clearState } from '@_store/index.reducers';
import { Observable } from 'rxjs';
import { LoginData } from '@_shared/interfaces/login-data.interface';
import { StorageService } from './storage.service';
import { UserService } from '@_services/store/user.service';
import RouterUtils from '@_utils/router/router.utils';
import { routesLayout } from '../modules/layout/layout-routing.module';
import { User } from '@_model/interfaces/user.model';
import { Routes } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private storageService: StorageService,
    private http: HttpClient,
    private store: Store<AppState>,
    private userService: UserService
  ) {}

  public isLogged = false;
  public loginData: LoginData;

  public login(credentials: { email: string; password: string }): Observable<any> {
    return this.http.post(environment.apiUrl + '/auth/login', credentials);
  }

  public reset(credentials: { password: string; token: string }): Observable<any> {
    return this.http.put(environment.apiUrl + '/auth/reset', credentials);
  }

  public sendReset(credentials: { email: string; type: string }): Observable<any> {
    return this.http.post(environment.apiUrl + '/auth/send-reset-email', credentials);
  }

  public tokenUser(credentials: { token: string }): Observable<any> {
    return this.http.get(environment.apiUrl + '/auth/token-user?token=' + credentials.token);
  }

  public renew(): Observable<any> {
    return this.http.get(environment.apiUrl + '/auth/renew');
  }

  public getLoginStorage(): void {
    const loginData = this.storageService.isLogged();
    if (!loginData) {
      return;
    } else {
      this.loginData = loginData;
      this.isLogged = true;
    }
  }

  public findLandingPage(user: User): string {
    const routes: Routes = RouterUtils.flatRoutesWithCumulativePath(routesLayout);
    return routes.find(it => this.userService.userHaveRight(it.data?.right, user))?.path;
  }

  public logout(): void {
    this.storageService.clearAll();
    this.userService.currentUser = null;
    this.loginData = undefined;
    this.isLogged = false;
    this.store.dispatch(clearState({ states: [] }));
  }
}
