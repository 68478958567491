import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Organization } from '@_model/interfaces/organization.model';
import * as AppState from '@_store/index.reducers';
import { OrganizationRelationsIds } from '@_store/organization/organization-generated.effects';
import * as OrganizationAction from '@_store/organization/organization.actions';
import {
  selectActiveOrganizations,
  selectAllOrganizations,
  selectIdOrganizationsActive,
  selectIsLoadedOrganization,
  selectIsLoadingOrganization,
  selectIsReadyAndLoadedOrganization,
  selectOneOrganization
} from '@_store/organization/organization.selectors';
import { catchApiActions } from '@_utils/http.util';
import { getIsReadySelectors, Selector, SelectSchema } from '@_utils/selector.util';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedOrganizationService {
  constructor(protected store$: Store<AppState.AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadedOrganization));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadingOrganization));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [selectIsReadyAndLoadedOrganization as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllOrganizations(schema: SelectSchema = {}): Observable<Organization[]> {
    return this.store$.pipe(select(selectAllOrganizations(schema))).pipe(
      switchMap(({ organizations }: { organizations: Organization[] }) => {
        return this.getReady(schema).pipe(mapTo(organizations));
      })
    );
  }

  public selectOneOrganization(idOrganization: number, schema: SelectSchema = {}): Observable<Organization> {
    return this.store$.pipe(select(selectOneOrganization(schema, idOrganization))).pipe(
      switchMap(({ organization }: { organization: Organization }) => {
        return this.getReady(schema).pipe(mapTo(organization));
      })
    );
  }

  public selectAllActiveOrganizations(schema: SelectSchema = {}): Observable<Organization[]> {
    return this.store$.pipe(select(selectActiveOrganizations(schema))).pipe(
      switchMap(({ organizations }: { organizations: Organization[] }) => {
        return this.getReady(schema).pipe(mapTo(organizations));
      })
    );
  }

  public selectIdOrganizationsActive(): Observable<number[]> {
    return this.store$.pipe(select(selectIdOrganizationsActive)).pipe(
      switchMap((idOrganizations: number[]) => {
        return this.getReady().pipe(mapTo(idOrganizations));
      })
    );
  }

  public getOneOrganization(
    idOrganization: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<Organization> {
    this.store$.dispatch(OrganizationAction.getOneOrganization({ idOrganization, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationAction.normalizeManyOrganizationsAfterUpsert,
        OrganizationAction.organizationsFailure,
        true
      );
    }
  }

  public getManyOrganizations(params: any = {}, getResult?: boolean): void | Observable<Organization[]> {
    this.store$.dispatch(OrganizationAction.getManyOrganizations({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationAction.normalizeManyOrganizationsAfterUpsert,
        OrganizationAction.organizationsFailure
      );
    }
  }

  public upsertOneOrganization(
    organization: Partial<Organization>,
    ids: OrganizationRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Organization> {
    this.store$.dispatch(OrganizationAction.upsertOneOrganization({ organization, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationAction.normalizeManyOrganizationsAfterUpsert,
        OrganizationAction.organizationsFailure,
        true
      );
    }
  }

  public deleteOneOrganization(idOrganization: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(OrganizationAction.deleteOneOrganization({ idOrganization }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        OrganizationAction.deleteOneOrganizationSuccess,
        OrganizationAction.organizationsFailure
      );
    }
  }

  public setActiveOrganizations(idOrganizations: number[]): void {
    this.store$.dispatch(OrganizationAction.clearActive());
    this.store$.dispatch(OrganizationAction.addManyActives({ idOrganizations }));
  }
}
