import { Injectable } from '@angular/core';
import { RepositoryService } from '@_services/api/repository.service';
import { LeadStratalot } from '@_shared/models/interfaces/lead-stratalot.model';
import { Lead } from '@_shared/models/interfaces/lead.model';
import { Stratalot } from '@_shared/models/interfaces/stratalot.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeneratedLeadApiService } from './lead-api-generated.service';
import { CloseSaleDTO } from '@_shared/interfaces/close-sale.dto';

@Injectable({
  providedIn: 'root'
})
export class LeadApiService extends GeneratedLeadApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
  public getOneLeadWithLeadStratalots(params?: { idLead: number; params?: any }): Observable<Lead> {
    return this.repo.getData<Lead>('lead/' + params.idLead, params.params).pipe(
      map((lead: Lead) => {
        return this.includeStratalotLeadInStratalot(lead);
      })
    );
  }

  public getLeadsForSaleInProgressBoard(params?: any): Observable<Lead[]> {
    return this.repo.getData<Lead[]>('lead/forSalesInProgressBoard', { params }).pipe(
      map((leads: Lead[]) => {
        return leads.map((lead: Lead) => {
          return this.includeStratalotLeadInStratalot(lead);
        });
      })
    );
  }

  private includeStratalotLeadInStratalot(lead: Lead): Lead {
    const leadStratalots = new Array<LeadStratalot>();

    lead.stratalots.forEach((stratalot: Stratalot) => {
      // "leadStratalot" isn't a property of front-end's Stratalot model.
      const leadStratalot = stratalot['leadStratalot'] as LeadStratalot;

      leadStratalots.push(leadStratalot);

      // Clean the data structure.
      delete stratalot['leadStratalot'];
    });

    lead.leadStratalots = leadStratalots;
    return lead;
  }

  public setMainStratalot(idLead: number, idStratalot: number): Observable<LeadStratalot[]> {
    return this.repo.update(`lead/${idLead}/setMainStratalot`, {
      idLead,
      idStratalot
    });
  }

  public updateManyLeads(partialLead: Partial<Lead>, ids: number[]): Observable<Lead[]> {
    return this.repo.update<Lead[]>('lead/updateMany', {
      partialLead,
      ids
    });
  }

  public updateLead(lead: Partial<Lead>): Observable<Lead> {
    const newLead: Partial<Lead> = { ...lead };
    delete newLead.idProspect;
    return this.repo.update('lead', newLead);
  }
  public updateLeadWithSideEffect(lead: Partial<Lead>): Observable<Lead | Lead[]> {
    const newLead: Partial<Lead> = { ...lead };

    newLead['verbose'] = true;

    return this.repo.updateWithSideEffect<Lead, Lead[]>('lead', newLead);
  }
  public closeSale(patch: CloseSaleDTO): Observable<Partial<Lead>> {
    return this.repo.update(`lead/${patch.idLead}/closeSale`, {});
  }
  public deleteOneLeadWithUpdateStratalot(idLead: number): Observable<{ leads: Lead[]; stratalots: Stratalot[] }> {
    return this.repo.delete('lead/' + +idLead) as any;
  }

  public calculateTodo(): Observable<any> {
    return this.repo.create('lead/calculateTodosForAllLead', {});
  }

  public coherenceIsTheMain(): Observable<any> {
    return this.repo.create('lead/coherenceIsTheMain', {});
  }
}
