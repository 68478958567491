import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedResidenceTodoApiService } from './residence-todo-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ResidenceTodoApiService extends GeneratedResidenceTodoApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
