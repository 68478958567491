import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidencePriceGridHistoryEntityState } from '@_model/interfaces/residence-price-grid-history.model';

export interface ResidencePriceGridHistoryState extends EntityState<ResidencePriceGridHistoryEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidencePriceGridHistoryEntityState> =
  createEntityAdapter<ResidencePriceGridHistoryEntityState>({
    selectId: o => o.idResidencePriceGridHistory
  });
export const initialState: ResidencePriceGridHistoryState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residencePriceGridHistoryFeatureKey = 'residencePriceGridHistory';
