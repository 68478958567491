import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyCommunicationRecipientUserEntityState } from '@_model/interfaces/company-communication-recipient-user.model';

export interface CompanyCommunicationRecipientUserState
  extends EntityState<CompanyCommunicationRecipientUserEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyCommunicationRecipientUserEntityState> =
  createEntityAdapter<CompanyCommunicationRecipientUserEntityState>({
    selectId: o => o.idCompanyCommunicationRecipientUser
  });
export const initialState: CompanyCommunicationRecipientUserState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyCommunicationRecipientUserFeatureKey = 'companyCommunicationRecipientUser';
