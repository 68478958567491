export enum ResidenceStatutEnum {
  // prettier-ignore
  enEtude = 'A l\'étude',
  enProjet = 'En projet',
  enMontage = 'En montage',
  enVente = 'En vente',
  vendu = 'Vendu',
  suspendu = 'Suspendu',
  abandonne = 'Abandonné',
  termine = 'Terminé (fin mandat-marché)'
}
