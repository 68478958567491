import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceStudyTodo } from '@_shared/models/interfaces/residence-study-todo.model';
import { Observable } from 'rxjs';
import { GeneratedResidenceStudyTodoApiService } from './residence-study-todo-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ResidenceStudyTodoApiService extends GeneratedResidenceStudyTodoApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public updateManyResidenceStudyTodos(
    residenceStudyTodos: Partial<ResidenceStudyTodo[]>
  ): Observable<ResidenceStudyTodo[]> {
    return this.repo.update('residence-study-todo', residenceStudyTodos);
  }
}
