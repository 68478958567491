import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { StratalotCampaign, StratalotCampaignEntityState } from '@_model/interfaces/stratalot-campaign.model';
import { StratalotCampaignApiService } from '@_services/api/stratalot-campaign-api.service';
import * as stratalotCampaignActions from '@_store/stratalot-campaign/stratalot-campaign.actions';
import { getActionsToNormalizeStratalotCampaign } from '@_config/store/normalization.generated';
import { selectStratalotCampaignState } from './stratalot-campaign-generated.selectors';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import * as campaignMediaActions from '@_store/campaign-media/campaign-media.actions';
import { CampaignMedia } from '@_model/interfaces/campaign-media.model';

export interface StratalotCampaignRelationsIds {
  campaignMedias?: number | number[];
  stratalot?: number;
}

export function getDefaultAddStratalotCampaignActions(
  stratalotCampaign: StratalotCampaignEntityState,
  ids?: StratalotCampaignRelationsIds
): Action[] {
  const actions: Action[] = [
    stratalotCampaignActions.normalizeManyStratalotCampaignsAfterUpsert({ stratalotCampaigns: [stratalotCampaign] })
  ];

  if (ids?.stratalot) {
    actions.push(
      stratalotActions.addManyStratalotCampaignSuccess({
        idStratalot: ids.stratalot,
        idStratalotCampaigns: [stratalotCampaign.idStratalotCampaign]
      })
    );
    actions.push(
      stratalotCampaignActions.addStratalotSuccess({
        idStratalotCampaign: stratalotCampaign.idStratalotCampaign,
        idStratalot: ids.stratalot
      })
    );
  }

  if (ids?.campaignMedias) {
    if (!Array.isArray(ids.campaignMedias)) {
      actions.push(
        campaignMediaActions.upsertOneCampaignMedia({
          campaignMedia: {
            idStratalotCampaign: stratalotCampaign.idStratalotCampaign,
            idCampaignMedia: ids.campaignMedias as number
          } as CampaignMedia
        })
      );
      actions.push(
        stratalotCampaignActions.addManyCampaignMediaSuccess({
          idStratalotCampaign: stratalotCampaign.idStratalotCampaign,
          idCampaignMedias: [ids.campaignMedias as number]
        })
      );
    } else {
      actions.push(
        campaignMediaActions.upsertManyCampaignMedias({
          campaignMedias: (ids.campaignMedias as number[]).map((idCampaignMedia: number) => ({
            idStratalotCampaign: stratalotCampaign.idStratalotCampaign,
            idCampaignMedia
          })) as CampaignMedia[]
        })
      );
      actions.push(
        stratalotCampaignActions.addManyCampaignMediaSuccess({
          idStratalotCampaign: stratalotCampaign.idStratalotCampaign,
          idCampaignMedias: ids.campaignMedias as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteStratalotCampaignActions(stratalotCampaign: StratalotCampaignEntityState): Action[] {
  const actions: Action[] = [
    stratalotCampaignActions.deleteOneStratalotCampaignSuccess({
      idStratalotCampaign: stratalotCampaign.idStratalotCampaign
    })
  ];

  if (stratalotCampaign.stratalot) {
    actions.push(
      stratalotActions.deleteManyStratalotCampaignSuccess({
        idStratalotCampaigns: [stratalotCampaign.idStratalotCampaign],
        idStratalots: [stratalotCampaign.stratalot as number]
      })
    );
  }

  if (stratalotCampaign.campaignMedias) {
    actions.push(
      campaignMediaActions.deleteManyStratalotCampaignSuccess({
        idStratalotCampaigns: [stratalotCampaign.idStratalotCampaign],
        idCampaignMedias: stratalotCampaign.campaignMedias as number[]
      })
    );
  }

  return actions;
}

export class GeneratedStratalotCampaignEffects {
  constructor(
    protected actions$: Actions,
    protected stratalotCampaignApiService: StratalotCampaignApiService,
    protected store$: Store<AppState>
  ) {}

  getManyStratalotCampaigns$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotCampaignActions.getManyStratalotCampaigns),
      switchMap(({ params }) =>
        this.stratalotCampaignApiService.getStratalotCampaigns(params).pipe(
          map((stratalotCampaigns: StratalotCampaign[]) => {
            return stratalotCampaignActions.normalizeManyStratalotCampaignsAfterUpsert({ stratalotCampaigns });
          }),
          catchError(error => of(stratalotCampaignActions.stratalotCampaignsFailure({ error })))
        )
      )
    );
  });

  getOneStratalotCampaign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotCampaignActions.getOneStratalotCampaign),
      switchMap(idStratalotCampaign =>
        this.stratalotCampaignApiService.getStratalotCampaign(idStratalotCampaign).pipe(
          map((stratalotCampaign: StratalotCampaign) => {
            return stratalotCampaignActions.normalizeManyStratalotCampaignsAfterUpsert({
              stratalotCampaigns: [stratalotCampaign]
            });
          }),
          catchError(error => of(stratalotCampaignActions.stratalotCampaignsFailure({ error })))
        )
      )
    );
  });

  upsertOneStratalotCampaign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotCampaignActions.upsertOneStratalotCampaign),
      concatMap(
        ({
          stratalotCampaign,
          ids
        }: {
          stratalotCampaign: Partial<StratalotCampaign>;
          ids?: StratalotCampaignRelationsIds;
        }) => {
          if (stratalotCampaign.idStratalotCampaign) {
            return this.stratalotCampaignApiService.updateStratalotCampaign(stratalotCampaign).pipe(
              map((stratalotCampaignReturned: StratalotCampaign) => {
                return stratalotCampaignActions.normalizeManyStratalotCampaignsAfterUpsert({
                  stratalotCampaigns: [stratalotCampaignReturned]
                });
              }),
              catchError(error => of(stratalotCampaignActions.stratalotCampaignsFailure({ error })))
            );
          } else {
            return this.stratalotCampaignApiService.addStratalotCampaign(stratalotCampaign).pipe(
              mergeMap((stratalotCampaignReturned: StratalotCampaign) =>
                getDefaultAddStratalotCampaignActions(stratalotCampaignReturned, ids)
              ),
              catchError(error => of(stratalotCampaignActions.stratalotCampaignsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneStratalotCampaign$ = createEffect(() => {
    const selectStratalotCampaignState$ = this.store$.select(selectStratalotCampaignState);
    return this.actions$.pipe(
      ofType(stratalotCampaignActions.deleteOneStratalotCampaign),
      withLatestFrom(selectStratalotCampaignState$),
      concatMap(([{ idStratalotCampaign }, state]) =>
        this.stratalotCampaignApiService.deleteStratalotCampaign(idStratalotCampaign).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteStratalotCampaignActions(
                state.entities[idStratalotCampaign] as StratalotCampaignEntityState
              ),
              stratalotCampaignActions.deleteOneStratalotCampaign.type
            )
          ]),
          catchError(error => of(stratalotCampaignActions.stratalotCampaignsFailure({ error })))
        )
      )
    );
  });

  normalizeManyStratalotCampaignsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotCampaignActions.normalizeManyStratalotCampaignsAfterUpsert),
      concatMap(({ stratalotCampaigns }) => {
        const actions: Action[] = getActionsToNormalizeStratalotCampaign(stratalotCampaigns, StoreActionType.upsert);
        return [getMultiAction(actions, '[StratalotCampaign] Normalization After Upsert Success')];
      })
    );
  });
}
