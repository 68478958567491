import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  ResidenceStudyCriteria,
  ResidenceStudyCriteriaEntityState
} from '@_model/interfaces/residence-study-criteria.model';
import { ResidenceStudyCriteriaApiService } from '@_services/api/residence-study-criteria-api.service';
import * as residenceStudyCriteriaActions from '@_store/residence-study-criteria/residence-study-criteria.actions';
import { getActionsToNormalizeResidenceStudyCriteria } from '@_config/store/normalization.generated';
import { selectResidenceStudyCriteriaState } from './residence-study-criteria-generated.selectors';
import * as residenceStudyActions from '@_store/residence-study/residence-study.actions';
import * as companyStudyCriteriaActions from '@_store/company-study-criteria/company-study-criteria.actions';

export interface ResidenceStudyCriteriaRelationsIds {
  residenceStudy?: number;
  companyStudyCriteria?: number;
}

export function getDefaultAddResidenceStudyCriteriaActions(
  residenceStudyCriteria: ResidenceStudyCriteriaEntityState,
  ids?: ResidenceStudyCriteriaRelationsIds
): Action[] {
  const actions: Action[] = [
    residenceStudyCriteriaActions.normalizeManyResidenceStudyCriteriasAfterUpsert({
      residenceStudyCriterias: [residenceStudyCriteria]
    })
  ];

  if (ids?.residenceStudy) {
    actions.push(
      residenceStudyActions.addManyResidenceStudyCriteriaSuccess({
        idResidenceStudy: ids.residenceStudy,
        idResidenceStudyCriterias: [residenceStudyCriteria.idResidenceStudyCriteria]
      })
    );
    actions.push(
      residenceStudyCriteriaActions.addResidenceStudySuccess({
        idResidenceStudyCriteria: residenceStudyCriteria.idResidenceStudyCriteria,
        idResidenceStudy: ids.residenceStudy
      })
    );
  }

  if (ids?.companyStudyCriteria) {
    actions.push(
      companyStudyCriteriaActions.addManyResidenceStudyCriteriaSuccess({
        idCompanyStudyCriteria: ids.companyStudyCriteria,
        idResidenceStudyCriterias: [residenceStudyCriteria.idResidenceStudyCriteria]
      })
    );
    actions.push(
      residenceStudyCriteriaActions.addCompanyStudyCriteriaSuccess({
        idResidenceStudyCriteria: residenceStudyCriteria.idResidenceStudyCriteria,
        idCompanyStudyCriteria: ids.companyStudyCriteria
      })
    );
  }

  return actions;
}

export function getDefaultDeleteResidenceStudyCriteriaActions(
  residenceStudyCriteria: ResidenceStudyCriteriaEntityState
): Action[] {
  const actions: Action[] = [
    residenceStudyCriteriaActions.deleteOneResidenceStudyCriteriaSuccess({
      idResidenceStudyCriteria: residenceStudyCriteria.idResidenceStudyCriteria
    })
  ];

  if (residenceStudyCriteria.residenceStudy) {
    actions.push(
      residenceStudyActions.deleteManyResidenceStudyCriteriaSuccess({
        idResidenceStudyCriterias: [residenceStudyCriteria.idResidenceStudyCriteria],
        idResidenceStudies: [residenceStudyCriteria.residenceStudy as number]
      })
    );
  }

  if (residenceStudyCriteria.companyStudyCriteria) {
    actions.push(
      companyStudyCriteriaActions.deleteManyResidenceStudyCriteriaSuccess({
        idResidenceStudyCriterias: [residenceStudyCriteria.idResidenceStudyCriteria],
        idCompanyStudyCriterias: [residenceStudyCriteria.companyStudyCriteria as number]
      })
    );
  }

  return actions;
}

export class GeneratedResidenceStudyCriteriaEffects {
  constructor(
    protected actions$: Actions,
    protected residenceStudyCriteriaApiService: ResidenceStudyCriteriaApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidenceStudyCriterias$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyCriteriaActions.getManyResidenceStudyCriterias),
      switchMap(({ params }) =>
        this.residenceStudyCriteriaApiService.getResidenceStudyCriterias(params).pipe(
          map((residenceStudyCriterias: ResidenceStudyCriteria[]) => {
            return residenceStudyCriteriaActions.normalizeManyResidenceStudyCriteriasAfterUpsert({
              residenceStudyCriterias
            });
          }),
          catchError(error => of(residenceStudyCriteriaActions.residenceStudyCriteriasFailure({ error })))
        )
      )
    );
  });

  getOneResidenceStudyCriteria$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyCriteriaActions.getOneResidenceStudyCriteria),
      switchMap(idResidenceStudyCriteria =>
        this.residenceStudyCriteriaApiService.getResidenceStudyCriteria(idResidenceStudyCriteria).pipe(
          map((residenceStudyCriteria: ResidenceStudyCriteria) => {
            return residenceStudyCriteriaActions.normalizeManyResidenceStudyCriteriasAfterUpsert({
              residenceStudyCriterias: [residenceStudyCriteria]
            });
          }),
          catchError(error => of(residenceStudyCriteriaActions.residenceStudyCriteriasFailure({ error })))
        )
      )
    );
  });

  upsertOneResidenceStudyCriteria$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyCriteriaActions.upsertOneResidenceStudyCriteria),
      concatMap(
        ({
          residenceStudyCriteria,
          ids
        }: {
          residenceStudyCriteria: Partial<ResidenceStudyCriteria>;
          ids?: ResidenceStudyCriteriaRelationsIds;
        }) => {
          if (residenceStudyCriteria.idResidenceStudyCriteria) {
            return this.residenceStudyCriteriaApiService.updateResidenceStudyCriteria(residenceStudyCriteria).pipe(
              map((residenceStudyCriteriaReturned: ResidenceStudyCriteria) => {
                return residenceStudyCriteriaActions.normalizeManyResidenceStudyCriteriasAfterUpsert({
                  residenceStudyCriterias: [residenceStudyCriteriaReturned]
                });
              }),
              catchError(error => of(residenceStudyCriteriaActions.residenceStudyCriteriasFailure({ error })))
            );
          } else {
            return this.residenceStudyCriteriaApiService.addResidenceStudyCriteria(residenceStudyCriteria).pipe(
              mergeMap((residenceStudyCriteriaReturned: ResidenceStudyCriteria) =>
                getDefaultAddResidenceStudyCriteriaActions(residenceStudyCriteriaReturned, ids)
              ),
              catchError(error => of(residenceStudyCriteriaActions.residenceStudyCriteriasFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneResidenceStudyCriteria$ = createEffect(() => {
    const selectResidenceStudyCriteriaState$ = this.store$.select(selectResidenceStudyCriteriaState);
    return this.actions$.pipe(
      ofType(residenceStudyCriteriaActions.deleteOneResidenceStudyCriteria),
      withLatestFrom(selectResidenceStudyCriteriaState$),
      concatMap(([{ idResidenceStudyCriteria }, state]) =>
        this.residenceStudyCriteriaApiService.deleteResidenceStudyCriteria(idResidenceStudyCriteria).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidenceStudyCriteriaActions(
                state.entities[idResidenceStudyCriteria] as ResidenceStudyCriteriaEntityState
              ),
              residenceStudyCriteriaActions.deleteOneResidenceStudyCriteria.type
            )
          ]),
          catchError(error => of(residenceStudyCriteriaActions.residenceStudyCriteriasFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidenceStudyCriteriasAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyCriteriaActions.normalizeManyResidenceStudyCriteriasAfterUpsert),
      concatMap(({ residenceStudyCriterias }) => {
        const actions: Action[] = getActionsToNormalizeResidenceStudyCriteria(
          residenceStudyCriterias,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[ResidenceStudyCriteria] Normalization After Upsert Success')];
      })
    );
  });
}
