export * from './residence-price-grid-generated.actions';

import { createAction, props } from '@ngrx/store';
import { ResidencePriceGrid } from '@_shared/models/interfaces/residence-price-grid.model';
import { ResidencePriceGridRelationsIds } from './residence-price-grid-generated.effects';

export const generatePriceList = createAction(
  '[ResidencePriceGrid] Generate associated price list',
  props<{ params: Partial<ResidencePriceGrid> }>()
);

export const duplicatePriceGrid = createAction(
  '[ResidencePriceGrid] Duplicate price grid',
  props<{ params: Partial<ResidencePriceGrid> }>()
);

export const upsertOneResidencePriceGridLock = createAction(
  '[ResidencePriceGrid] Upsert One ResidencePriceGrid Lock',
  props<{
    residencePriceGrid: Partial<ResidencePriceGrid>;
    ids?: ResidencePriceGridRelationsIds;
  }>()
);

export const upsertOneResidencePriceGridActive = createAction(
  '[ResidencePriceGrid] Upsert One ResidencePriceGrid Active',
  props<{
    residencePriceGrid: Partial<ResidencePriceGrid>;
    ids?: ResidencePriceGridRelationsIds;
  }>()
);
