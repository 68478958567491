export enum DroitInterneEnum {
  updateProspect = 'prospect_U',
  createDeleteProspect = 'prospect_CD',

  updateResidence = 'residence_U',
  createDeleteResidence = 'residence_CD',
  updateResidenceTodo = 'residence_todo_U',

  updateLot = 'lot_U',
  createDeleteLot = 'lot_CD',
  ubiflowLot = 'lot_ubiflow',
  updateLotTodo = 'stratalot_todo_U',

  createDeleteGrilleDePrix = 'grid_CD',
  updateNonBloquedGrilleDePrix = 'grid_not_bloqued_U',
  deleteNonBloquedGrilleDePrix = 'grid_not_bloqued_D',
  lockUnlockGrilleDePrix = 'grid_lock',
  activateGrilleDePrix = 'grid_activate',
  dataVisualisationGrilleDePrix = 'data_visualisation_grillePrix',

  manageDossierVente = 'lead_CUD',
  deposerOffre = 'offre_deposit',
  validateOffre = 'offre_validate',
  reviewOffre = 'review_validate',
  validateVente = 'vente_validate',
  updateDossierVenteTodo = 'lead_todo_U',
  detailDossierVente = 'lead_detail',

  manageAdmin = 'admin_CUD',
  deleteGlobal = 'delete_all',
  publipostageDictionary = 'publipostage_dictionary_access',
  publicImport = 'public_import',
  publicExport = 'public_export',

  accessProspectPage = 'prospect_page_access',
  accessResidencePage = 'residence_page_access',
  accessResidenceMontagePage = 'residence_montage_page_access',
  accessResidenceVentePage = 'residence_vente_page_access',
  accessFreeStratalotPage = 'freeStratalot_page_access',
  accessToValidateOfferPage = 'toValidateOffer_page_access',
  accessSaleInProgressPage = 'saleInProgressPage_page_access',
  accessOccupiedStratalotPage = 'occupiedStratalot_page_access',
  accessDashboardPage = 'dashboard_page_access',
  accessStudy = 'study_page_access',

  updatePrevisionnel = 'forecast_U',
  accessPrevisionnel = 'forecast_page_access',
  accessDashboardPrevisionnel = 'dashbord_forecast_page_access',
  dataVisualisationLead = 'data_visualisation_lead'
}

export enum DroitFamilleEnum {
  api = 'Api',
  lot = 'Lots',
  prospect = 'Prospect',
  residence = 'Résidences',
  page = 'Accès menu navigation',
  grilleDePrix = 'Grilles de prix',
  administration = 'Administration',
  dossierDeVente = 'Dossiers de vente',
  previsionnel = 'Prévisionnel'
}
