import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationLeadTodoAppliedRule } from '@_model/interfaces/organization-lead-todo-applied-rule.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationLeadTodoAppliedRuleApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationLeadTodoAppliedRules(params?: any): Observable<OrganizationLeadTodoAppliedRule[]> {
    return this.repo.getData<OrganizationLeadTodoAppliedRule[]>('organization-lead-todo-applied-rule', params);
  }

  public getOrganizationLeadTodoAppliedRule(params: {
    idOrganizationLeadTodoAppliedRule: number;
    params?: any;
  }): Observable<OrganizationLeadTodoAppliedRule> {
    return this.repo.getData<OrganizationLeadTodoAppliedRule>(
      'organization-lead-todo-applied-rule/' + params.idOrganizationLeadTodoAppliedRule,
      params.params
    );
  }

  public addOrganizationLeadTodoAppliedRule(
    organizationLeadTodoAppliedRule: Partial<OrganizationLeadTodoAppliedRule>
  ): Observable<OrganizationLeadTodoAppliedRule> {
    return this.repo.create<OrganizationLeadTodoAppliedRule>(
      'organization-lead-todo-applied-rule',
      organizationLeadTodoAppliedRule
    );
  }

  public updateOrganizationLeadTodoAppliedRule(
    organizationLeadTodoAppliedRule: Partial<OrganizationLeadTodoAppliedRule>
  ): Observable<OrganizationLeadTodoAppliedRule> {
    return this.repo.update('organization-lead-todo-applied-rule', organizationLeadTodoAppliedRule);
  }

  public deleteOrganizationLeadTodoAppliedRule(idOrganizationLeadTodoAppliedRule: number): Observable<number> {
    return this.repo.delete('organization-lead-todo-applied-rule/' + +idOrganizationLeadTodoAppliedRule);
  }
}
