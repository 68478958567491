import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BoardStateEntityState } from '@_model/interfaces/board-state.model';

export interface BoardStateState extends EntityState<BoardStateEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<BoardStateEntityState> = createEntityAdapter<BoardStateEntityState>({
  selectId: o => o.idBoardState
});
export const initialState: BoardStateState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const boardStateFeatureKey = 'boardState';
