import { ResidenceTodoState, initialState } from './residence-todo.state';
import { Action, createReducer } from '@ngrx/store';
import { residenceTodoReducersGeneratedFunctions } from './residence-todo-generated.reducer';

const residenceTodoReducersFunctions = [...residenceTodoReducersGeneratedFunctions];

const residenceTodoReducer = createReducer(initialState, ...residenceTodoReducersFunctions);

export function reducer(state: ResidenceTodoState | undefined, action: Action) {
  return residenceTodoReducer(state, action);
}
