import { RepositoryService } from '@_services/api/repository.service';
import { StepProgress } from '@_model/interfaces/step-progress.model';
import { Observable } from 'rxjs';

export class GeneratedStepProgressApiService {
  constructor(protected repo: RepositoryService) {}

  public getStepProgresses(params?: any): Observable<StepProgress[]> {
    return this.repo.getData<StepProgress[]>('step-progress', params);
  }

  public getStepProgress(params: { idStepProgress: number; params?: any }): Observable<StepProgress> {
    return this.repo.getData<StepProgress>('step-progress/' + params.idStepProgress, params.params);
  }

  public addStepProgress(stepProgress: Partial<StepProgress>): Observable<StepProgress> {
    return this.repo.create<StepProgress>('step-progress', stepProgress);
  }

  public updateStepProgress(stepProgress: Partial<StepProgress>): Observable<StepProgress> {
    return this.repo.update('step-progress', stepProgress);
  }

  public deleteStepProgress(idStepProgress: number): Observable<number> {
    return this.repo.delete('step-progress/' + +idStepProgress);
  }
}
