import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyCommunicationGeneratedDocumentActions from './company-communication-generated-document.actions';
import {
  adapter,
  initialState,
  CompanyCommunicationGeneratedDocumentState
} from './company-communication-generated-document.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyCommunicationGeneratedDocumentEntityState } from '@_model/interfaces/company-communication-generated-document.model';

export const companyCommunicationGeneratedDocumentReducersGeneratedFunctions: ReducerTypes<
  CompanyCommunicationGeneratedDocumentState,
  readonly ActionCreator[]
>[] = [
  on(
    CompanyCommunicationGeneratedDocumentActions.getOneCompanyCommunicationGeneratedDocument,
    (state: CompanyCommunicationGeneratedDocumentState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationGeneratedDocumentActions.getManyCompanyCommunicationGeneratedDocuments,
    (state: CompanyCommunicationGeneratedDocumentState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationGeneratedDocumentActions.upsertOneCompanyCommunicationGeneratedDocument,
    (state: CompanyCommunicationGeneratedDocumentState) => setLoadingsState(state, true)
  ),

  on(
    CompanyCommunicationGeneratedDocumentActions.upsertManyCompanyCommunicationGeneratedDocumentsSuccess,
    (state: CompanyCommunicationGeneratedDocumentState, { companyCommunicationGeneratedDocuments }) =>
      adapter.upsertMany(companyCommunicationGeneratedDocuments, setLoadingsState(state, false))
  ),
  on(
    CompanyCommunicationGeneratedDocumentActions.deleteOneCompanyCommunicationGeneratedDocument,
    (state: CompanyCommunicationGeneratedDocumentState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationGeneratedDocumentActions.deleteOneCompanyCommunicationGeneratedDocumentSuccess,
    (state: CompanyCommunicationGeneratedDocumentState, { idCompanyCommunicationGeneratedDocument }) =>
      adapter.removeOne(idCompanyCommunicationGeneratedDocument, setLoadingsState(state, false))
  ),
  on(CompanyCommunicationGeneratedDocumentActions.clearActive, (state: CompanyCommunicationGeneratedDocumentState) => ({
    ...state,
    actives: []
  })),
  on(
    CompanyCommunicationGeneratedDocumentActions.addManyActives,
    (state: CompanyCommunicationGeneratedDocumentState, { idCompanyCommunicationGeneratedDocuments }) => ({
      ...state,
      actives: state.actives.concat(idCompanyCommunicationGeneratedDocuments)
    })
  ),
  on(
    CompanyCommunicationGeneratedDocumentActions.deleteOneActive,
    (state: CompanyCommunicationGeneratedDocumentState, { idCompanyCommunicationGeneratedDocument }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCompanyCommunicationGeneratedDocument)
    })
  ),

  on(CompanyCommunicationGeneratedDocumentActions.clearStore, () => initialState),

  on(
    CompanyCommunicationGeneratedDocumentActions.addCompanyCommunicationSuccess,
    (
      state: CompanyCommunicationGeneratedDocumentState,
      { idCompanyCommunicationGeneratedDocument, idCompanyCommunication }
    ) => {
      if (!state.entities[idCompanyCommunicationGeneratedDocument]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationGeneratedDocument]: {
            ...state.entities[idCompanyCommunicationGeneratedDocument],
            companyCommunication: idCompanyCommunication
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationGeneratedDocumentActions.deleteManyCompanyCommunicationSuccess,
    (
      state: CompanyCommunicationGeneratedDocumentState,
      { idCompanyCommunications, idCompanyCommunicationGeneratedDocuments }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationGeneratedDocuments.reduce((entities, idCompanyCommunicationGeneratedDocument) => {
            if (!state.entities[idCompanyCommunicationGeneratedDocument]?.companyCommunication) {
              return entities;
            }
            entities[idCompanyCommunicationGeneratedDocument] = {
              ...state.entities[idCompanyCommunicationGeneratedDocument],
              companyCommunication: idCompanyCommunications.some(
                (idCompanyCommunication: number) =>
                  idCompanyCommunication ===
                  state.entities[idCompanyCommunicationGeneratedDocument]?.companyCommunication
              )
                ? undefined
                : state.entities[idCompanyCommunicationGeneratedDocument]?.companyCommunication
            } as CompanyCommunicationGeneratedDocumentEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationGeneratedDocumentEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationGeneratedDocumentActions.addGeneratedDocumentSuccess,
    (
      state: CompanyCommunicationGeneratedDocumentState,
      { idCompanyCommunicationGeneratedDocument, idGeneratedDocument }
    ) => {
      if (!state.entities[idCompanyCommunicationGeneratedDocument]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationGeneratedDocument]: {
            ...state.entities[idCompanyCommunicationGeneratedDocument],
            generatedDocument: idGeneratedDocument
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationGeneratedDocumentActions.deleteManyGeneratedDocumentSuccess,
    (
      state: CompanyCommunicationGeneratedDocumentState,
      { idGeneratedDocuments, idCompanyCommunicationGeneratedDocuments }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationGeneratedDocuments.reduce((entities, idCompanyCommunicationGeneratedDocument) => {
            if (!state.entities[idCompanyCommunicationGeneratedDocument]?.generatedDocument) {
              return entities;
            }
            entities[idCompanyCommunicationGeneratedDocument] = {
              ...state.entities[idCompanyCommunicationGeneratedDocument],
              generatedDocument: idGeneratedDocuments.some(
                (idGeneratedDocument: number) =>
                  idGeneratedDocument === state.entities[idCompanyCommunicationGeneratedDocument]?.generatedDocument
              )
                ? undefined
                : state.entities[idCompanyCommunicationGeneratedDocument]?.generatedDocument
            } as CompanyCommunicationGeneratedDocumentEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationGeneratedDocumentEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyCommunicationGeneratedDocumentState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyCommunicationGeneratedDocumentState {
  return { ...state, isLoaded, isLoading };
}
