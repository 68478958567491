import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ResidenceStudyReason, ResidenceStudyReasonEntityState } from '@_model/interfaces/residence-study-reason.model';
import { ResidenceStudyReasonRelationsIds } from './residence-study-reason-generated.effects';

export const getOneResidenceStudyReason = createAction(
  '[ResidenceStudyReason] Get One ResidenceStudyReason',
  props<{ idResidenceStudyReason: number; params?: any }>()
);

export const getManyResidenceStudyReasons = createAction(
  '[ResidenceStudyReason] Get Many ResidenceStudyReasons',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ResidenceStudyReason] Add Many Actives ResidenceStudyReason',
  props<{ idResidenceStudyReasons: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidenceStudyReason] Delete One Active ResidenceStudyReason',
  props<{ idResidenceStudyReason: number }>()
);

export const clearActive = createAction('[ResidenceStudyReason] Clear Active ResidenceStudyReason');

export const upsertOneResidenceStudyReason = createAction(
  '[ResidenceStudyReason] Upsert One ResidenceStudyReason',
  props<{
    residenceStudyReason: Partial<ResidenceStudyReason>;
    ids?: ResidenceStudyReasonRelationsIds;
  }>()
);

export const upsertManyResidenceStudyReasons = createAction(
  '[ResidenceStudyReason] Upsert Many ResidenceStudyReasons',
  props<{
    residenceStudyReasons: Partial<ResidenceStudyReason>[];
    ids?: ResidenceStudyReasonRelationsIds;
  }>()
);

export const upsertManyResidenceStudyReasonsSuccess = createAction(
  '[ResidenceStudyReason] Create Many ResidenceStudyReasons Success',
  props<{ residenceStudyReasons: ResidenceStudyReasonEntityState[] }>()
);

export const deleteOneResidenceStudyReason = createAction(
  '[ResidenceStudyReason] Delete One ResidenceStudyReason',
  props<{ idResidenceStudyReason: number }>()
);

export const deleteOneResidenceStudyReasonSuccess = createAction(
  '[ResidenceStudyReason] Delete One ResidenceStudyReason Success',
  props<{ idResidenceStudyReason: number }>()
);

export const normalizeManyResidenceStudyReasonsAfterUpsert = createAction(
  '[ResidenceStudyReason] Normalize Many ResidenceStudyReasons After Upsert',
  props<{ residenceStudyReasons: ResidenceStudyReasonEntityState[] }>()
);

export const residenceStudyReasonsFailure = createAction(
  '[ResidenceStudyReason] ResidenceStudyReasons Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidenceStudyReason] Clear ResidenceStudyReasons');

export const addResidenceStudySuccess = createAction(
  '[ResidenceStudyReason] Add ResidenceStudy',
  props<{ idResidenceStudyReason: number; idResidenceStudy: number }>()
);

export const deleteManyResidenceStudySuccess = createAction(
  '[ResidenceStudyReason] Delete Many ResidenceStudy',
  props<{ idResidenceStudies: number[]; idResidenceStudyReasons: number[] }>()
);

export const addCompanyStudyReasonSuccess = createAction(
  '[ResidenceStudyReason] Add CompanyStudyReason',
  props<{ idResidenceStudyReason: number; idCompanyStudyReason: number }>()
);

export const deleteManyCompanyStudyReasonSuccess = createAction(
  '[ResidenceStudyReason] Delete Many CompanyStudyReason',
  props<{ idCompanyStudyReasons: number[]; idResidenceStudyReasons: number[] }>()
);
