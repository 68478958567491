import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  CompanyStudyConclusion,
  CompanyStudyConclusionEntityState
} from '@_model/interfaces/company-study-conclusion.model';
import { CompanyStudyConclusionApiService } from '@_services/api/company-study-conclusion-api.service';
import * as companyStudyConclusionActions from '@_store/company-study-conclusion/company-study-conclusion.actions';
import { getActionsToNormalizeCompanyStudyConclusion } from '@_config/store/normalization.generated';
import { selectCompanyStudyConclusionState } from './company-study-conclusion-generated.selectors';
import * as companyActions from '@_store/company/company.actions';
import * as residenceStudyActions from '@_store/residence-study/residence-study.actions';
import { ResidenceStudy } from '@_model/interfaces/residence-study.model';

export interface CompanyStudyConclusionRelationsIds {
  residenceStudies?: number | number[];
  company?: number;
}

export function getDefaultAddCompanyStudyConclusionActions(
  companyStudyConclusion: CompanyStudyConclusionEntityState,
  ids?: CompanyStudyConclusionRelationsIds
): Action[] {
  const actions: Action[] = [
    companyStudyConclusionActions.normalizeManyCompanyStudyConclusionsAfterUpsert({
      companyStudyConclusions: [companyStudyConclusion]
    })
  ];

  if (ids?.company) {
    actions.push(
      companyActions.addManyCompanyStudyConclusionSuccess({
        idCompany: ids.company,
        idCompanyStudyConclusions: [companyStudyConclusion.idCompanyStudyConclusion]
      })
    );
    actions.push(
      companyStudyConclusionActions.addCompanySuccess({
        idCompanyStudyConclusion: companyStudyConclusion.idCompanyStudyConclusion,
        idCompany: ids.company
      })
    );
  }

  if (ids?.residenceStudies) {
    if (!Array.isArray(ids.residenceStudies)) {
      actions.push(
        residenceStudyActions.upsertOneResidenceStudy({
          residenceStudy: {
            idCompanyStudyConclusion: companyStudyConclusion.idCompanyStudyConclusion,
            idResidenceStudy: ids.residenceStudies as number
          } as ResidenceStudy
        })
      );
      actions.push(
        companyStudyConclusionActions.addManyResidenceStudySuccess({
          idCompanyStudyConclusion: companyStudyConclusion.idCompanyStudyConclusion,
          idResidenceStudies: [ids.residenceStudies as number]
        })
      );
    } else {
      actions.push(
        residenceStudyActions.upsertManyResidenceStudies({
          residenceStudies: (ids.residenceStudies as number[]).map((idResidenceStudy: number) => ({
            idCompanyStudyConclusion: companyStudyConclusion.idCompanyStudyConclusion,
            idResidenceStudy
          })) as ResidenceStudy[]
        })
      );
      actions.push(
        companyStudyConclusionActions.addManyResidenceStudySuccess({
          idCompanyStudyConclusion: companyStudyConclusion.idCompanyStudyConclusion,
          idResidenceStudies: ids.residenceStudies as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCompanyStudyConclusionActions(
  companyStudyConclusion: CompanyStudyConclusionEntityState
): Action[] {
  const actions: Action[] = [
    companyStudyConclusionActions.deleteOneCompanyStudyConclusionSuccess({
      idCompanyStudyConclusion: companyStudyConclusion.idCompanyStudyConclusion
    })
  ];

  if (companyStudyConclusion.company) {
    actions.push(
      companyActions.deleteManyCompanyStudyConclusionSuccess({
        idCompanyStudyConclusions: [companyStudyConclusion.idCompanyStudyConclusion],
        idCompanies: [companyStudyConclusion.company as number]
      })
    );
  }

  if (companyStudyConclusion.residenceStudies) {
    actions.push(
      residenceStudyActions.deleteManyCompanyStudyConclusionSuccess({
        idCompanyStudyConclusions: [companyStudyConclusion.idCompanyStudyConclusion],
        idResidenceStudies: companyStudyConclusion.residenceStudies as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyStudyConclusionEffects {
  constructor(
    protected actions$: Actions,
    protected companyStudyConclusionApiService: CompanyStudyConclusionApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyStudyConclusions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStudyConclusionActions.getManyCompanyStudyConclusions),
      switchMap(({ params }) =>
        this.companyStudyConclusionApiService.getCompanyStudyConclusions(params).pipe(
          map((companyStudyConclusions: CompanyStudyConclusion[]) => {
            return companyStudyConclusionActions.normalizeManyCompanyStudyConclusionsAfterUpsert({
              companyStudyConclusions
            });
          }),
          catchError(error => of(companyStudyConclusionActions.companyStudyConclusionsFailure({ error })))
        )
      )
    );
  });

  getOneCompanyStudyConclusion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStudyConclusionActions.getOneCompanyStudyConclusion),
      switchMap(idCompanyStudyConclusion =>
        this.companyStudyConclusionApiService.getCompanyStudyConclusion(idCompanyStudyConclusion).pipe(
          map((companyStudyConclusion: CompanyStudyConclusion) => {
            return companyStudyConclusionActions.normalizeManyCompanyStudyConclusionsAfterUpsert({
              companyStudyConclusions: [companyStudyConclusion]
            });
          }),
          catchError(error => of(companyStudyConclusionActions.companyStudyConclusionsFailure({ error })))
        )
      )
    );
  });

  upsertOneCompanyStudyConclusion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStudyConclusionActions.upsertOneCompanyStudyConclusion),
      concatMap(
        ({
          companyStudyConclusion,
          ids
        }: {
          companyStudyConclusion: Partial<CompanyStudyConclusion>;
          ids?: CompanyStudyConclusionRelationsIds;
        }) => {
          if (companyStudyConclusion.idCompanyStudyConclusion) {
            return this.companyStudyConclusionApiService.updateCompanyStudyConclusion(companyStudyConclusion).pipe(
              map((companyStudyConclusionReturned: CompanyStudyConclusion) => {
                return companyStudyConclusionActions.normalizeManyCompanyStudyConclusionsAfterUpsert({
                  companyStudyConclusions: [companyStudyConclusionReturned]
                });
              }),
              catchError(error => of(companyStudyConclusionActions.companyStudyConclusionsFailure({ error })))
            );
          } else {
            return this.companyStudyConclusionApiService.addCompanyStudyConclusion(companyStudyConclusion).pipe(
              mergeMap((companyStudyConclusionReturned: CompanyStudyConclusion) =>
                getDefaultAddCompanyStudyConclusionActions(companyStudyConclusionReturned, ids)
              ),
              catchError(error => of(companyStudyConclusionActions.companyStudyConclusionsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCompanyStudyConclusion$ = createEffect(() => {
    const selectCompanyStudyConclusionState$ = this.store$.select(selectCompanyStudyConclusionState);
    return this.actions$.pipe(
      ofType(companyStudyConclusionActions.deleteOneCompanyStudyConclusion),
      withLatestFrom(selectCompanyStudyConclusionState$),
      concatMap(([{ idCompanyStudyConclusion }, state]) =>
        this.companyStudyConclusionApiService.deleteCompanyStudyConclusion(idCompanyStudyConclusion).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCompanyStudyConclusionActions(
                state.entities[idCompanyStudyConclusion] as CompanyStudyConclusionEntityState
              ),
              companyStudyConclusionActions.deleteOneCompanyStudyConclusion.type
            )
          ]),
          catchError(error => of(companyStudyConclusionActions.companyStudyConclusionsFailure({ error })))
        )
      )
    );
  });

  normalizeManyCompanyStudyConclusionsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStudyConclusionActions.normalizeManyCompanyStudyConclusionsAfterUpsert),
      concatMap(({ companyStudyConclusions }) => {
        const actions: Action[] = getActionsToNormalizeCompanyStudyConclusion(
          companyStudyConclusions,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CompanyStudyConclusion] Normalization After Upsert Success')];
      })
    );
  });
}
