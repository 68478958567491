import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidenceTodoEntityState } from '@_model/interfaces/residence-todo.model';

export interface ResidenceTodoState extends EntityState<ResidenceTodoEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidenceTodoEntityState> = createEntityAdapter<ResidenceTodoEntityState>({
  selectId: o => o.idResidenceTodo
});
export const initialState: ResidenceTodoState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residenceTodoFeatureKey = 'residenceTodo';
