import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyStratalotType, CompanyStratalotTypeEntityState } from '@_model/interfaces/company-stratalot-type.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { StratalotPriceList, StratalotPriceListEntityState } from '@_model/interfaces/stratalot-price-list.model';
import {
  OrganizationLeadTodoRule,
  OrganizationLeadTodoRuleEntityState
} from '@_model/interfaces/organization-lead-todo-rule.model';
import {
  OrganizationStratalotTodoRule,
  OrganizationStratalotTodoRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-rule.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { StratalotType, StratalotTypeEntityState } from '@_model/interfaces/stratalot-type.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, companyStratalotTypeFeatureKey, CompanyStratalotTypeState } from './company-stratalot-type.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyStratalotTypeRelations: string[] = [
  'stratalots',
  'stratalotPriceLists',
  'organizationLeadTodoRules',
  'organizationStratalotTodoRules',
  'companies',
  'stratalotTypes'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyStratalotTypeState =
  createFeatureSelector<CompanyStratalotTypeState>(companyStratalotTypeFeatureKey);

export const selectIsLoadedCompanyStratalotType = createSelector(
  selectCompanyStratalotTypeState,
  (state: CompanyStratalotTypeState) => state.isLoaded
);

export const selectIsLoadingCompanyStratalotType = createSelector(
  selectCompanyStratalotTypeState,
  (state: CompanyStratalotTypeState) => state.isLoading
);

export const selectIsReadyCompanyStratalotType = createSelector(
  selectCompanyStratalotTypeState,
  (state: CompanyStratalotTypeState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyStratalotType = createSelector(
  selectCompanyStratalotTypeState,
  (state: CompanyStratalotTypeState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyStratalotTypeModel: SelectorModel = {
  name: 'companyStratalotTypes',
  getSelector: selectAllCompanyStratalotTypesDictionary,
  isReady: selectIsReadyCompanyStratalotType
};

export const selectCompanyStratalotTypesEntities = createSelector(selectCompanyStratalotTypeState, selectEntities);

export const selectCompanyStratalotTypesArray = createSelector(selectCompanyStratalotTypeState, selectAll);

export const selectIdCompanyStratalotTypesActive = createSelector(
  selectCompanyStratalotTypeState,
  (state: CompanyStratalotTypeState) => state.actives
);

const companyStratalotTypesInObject = (companyStratalotTypes: Dictionary<CompanyStratalotTypeEntityState>) => ({
  companyStratalotTypes
});

const selectCompanyStratalotTypesEntitiesDictionary = createSelector(
  selectCompanyStratalotTypesEntities,
  companyStratalotTypesInObject
);

const selectAllCompanyStratalotTypesObject = createSelector(
  selectCompanyStratalotTypesEntities,
  companyStratalotTypes => {
    return hydrateAll({ companyStratalotTypes });
  }
);

const selectOneCompanyStratalotTypeDictionary = (idCompanyStratalotType: number) =>
  createSelector(selectCompanyStratalotTypesEntities, companyStratalotTypes => {
    return { companyStratalotTypes: { [idCompanyStratalotType]: companyStratalotTypes[idCompanyStratalotType] } };
  });

const selectOneCompanyStratalotTypeDictionaryWithoutChild = (idCompanyStratalotType: number) =>
  createSelector(selectCompanyStratalotTypesEntities, companyStratalotTypes => {
    return { companyStratalotType: companyStratalotTypes[idCompanyStratalotType] };
  });

const selectActiveCompanyStratalotTypesEntities = createSelector(
  selectIdCompanyStratalotTypesActive,
  selectCompanyStratalotTypesEntities,
  (actives: number[], companyStratalotTypes: Dictionary<CompanyStratalotTypeEntityState>) =>
    getCompanyStratalotTypesFromActives(actives, companyStratalotTypes)
);

function getCompanyStratalotTypesFromActives(
  actives: number[],
  companyStratalotTypes: Dictionary<CompanyStratalotTypeEntityState>
): Dictionary<CompanyStratalotTypeEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyStratalotTypes[idActive]) {
      acc[idActive] = companyStratalotTypes[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyStratalotTypeEntityState>);
}

const selectAllCompanyStratalotTypesSelectors: Dictionary<Selector> = {};
export function selectAllCompanyStratalotTypes(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyStratalotType>(
      schema,
      selectAllCompanyStratalotTypesSelectors,
      selectCompanyStratalotTypesEntitiesDictionary,
      getRelationSelectors,
      companyStratalotTypeRelations,
      hydrateAll,
      'companyStratalotType'
    );
  } else {
    return selectAllCompanyStratalotTypesObject;
  }
}

export function selectAllCompanyStratalotTypesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyStratalotTypes'
): Selector {
  return createSelector(selectAllCompanyStratalotTypes(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyStratalotTypeEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyStratalotTypes.length; i++) {
      res[customKey][result.companyStratalotTypes[i].idCompanyStratalotType] = result.companyStratalotTypes[i];
    }
    return res;
  });
}

export function selectOneCompanyStratalotType(schema: SelectSchema = {}, idCompanyStratalotType: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyStratalotTypeDictionary(idCompanyStratalotType)];
    selectors.push(...getRelationSelectors(schema, companyStratalotTypeRelations, 'companyStratalotType'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyStratalotTypeDictionaryWithoutChild(idCompanyStratalotType);
  }
}

export function selectActiveCompanyStratalotTypes(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyStratalotTypesEntities, companyStratalotTypes => ({ companyStratalotTypes }))
  ];
  selectors.push(...getRelationSelectors(schema, companyStratalotTypeRelations, 'companyStratalotType'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyStratalotTypes: Dictionary<CompanyStratalotTypeEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  stratalotTypes?: Dictionary<StratalotTypeEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
  stratalotPriceLists?: Dictionary<StratalotPriceListEntityState>;
  organizationLeadTodoRules?: Dictionary<OrganizationLeadTodoRuleEntityState>;
  organizationStratalotTodoRules?: Dictionary<OrganizationStratalotTodoRuleEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { companyStratalotTypes: (CompanyStratalotType | null)[] } {
  const {
    companyStratalotTypes,
    companies,
    stratalotTypes,
    stratalots,
    stratalotPriceLists,
    organizationLeadTodoRules,
    organizationStratalotTodoRules
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    companyStratalotTypes: Object.keys(companyStratalotTypes).map(idCompanyStratalotType =>
      hydrate(
        companyStratalotTypes[idCompanyStratalotType] as CompanyStratalotTypeEntityState,
        companies,
        stratalotTypes,
        stratalots,
        stratalotPriceLists,
        organizationLeadTodoRules,
        organizationStratalotTodoRules
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { companyStratalotType: CompanyStratalotTypeEntityState | null } {
  const {
    companyStratalotTypes,
    companies,
    stratalotTypes,
    stratalots,
    stratalotPriceLists,
    organizationLeadTodoRules,
    organizationStratalotTodoRules
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const companyStratalotType = Object.values(companyStratalotTypes)[0];
  return {
    companyStratalotType: hydrate(
      companyStratalotType as CompanyStratalotTypeEntityState,
      companies,
      stratalotTypes,
      stratalots,
      stratalotPriceLists,
      organizationLeadTodoRules,
      organizationStratalotTodoRules
    )
  };
}

function hydrate(
  companyStratalotType: CompanyStratalotTypeEntityState,
  companyEntities?: Dictionary<CompanyEntityState>,
  stratalotTypeEntities?: Dictionary<StratalotTypeEntityState>,
  stratalotEntities?: Dictionary<StratalotEntityState>,
  stratalotPriceListEntities?: Dictionary<StratalotPriceListEntityState>,
  organizationLeadTodoRuleEntities?: Dictionary<OrganizationLeadTodoRuleEntityState>,
  organizationStratalotTodoRuleEntities?: Dictionary<OrganizationStratalotTodoRuleEntityState>
): CompanyStratalotType | null {
  if (!companyStratalotType) {
    return null;
  }

  const companyStratalotTypeHydrated: CompanyStratalotTypeEntityState = { ...companyStratalotType };
  if (companyEntities) {
    companyStratalotTypeHydrated.company = companyEntities[companyStratalotType.company as number] as Company;
  } else {
    delete companyStratalotTypeHydrated.company;
  }
  if (stratalotTypeEntities) {
    companyStratalotTypeHydrated.stratalotType = stratalotTypeEntities[
      companyStratalotType.stratalotType as number
    ] as StratalotType;
  } else {
    delete companyStratalotTypeHydrated.stratalotType;
  }

  if (stratalotEntities) {
    companyStratalotTypeHydrated.stratalots = ((companyStratalotTypeHydrated.stratalots as number[]) || []).map(
      id => stratalotEntities[id]
    ) as Stratalot[];
  } else {
    delete companyStratalotTypeHydrated.stratalots;
  }

  if (stratalotPriceListEntities) {
    companyStratalotTypeHydrated.stratalotPriceLists = (
      (companyStratalotTypeHydrated.stratalotPriceLists as number[]) || []
    ).map(id => stratalotPriceListEntities[id]) as StratalotPriceList[];
  } else {
    delete companyStratalotTypeHydrated.stratalotPriceLists;
  }

  if (organizationLeadTodoRuleEntities) {
    companyStratalotTypeHydrated.organizationLeadTodoRules = (
      (companyStratalotTypeHydrated.organizationLeadTodoRules as number[]) || []
    ).map(id => organizationLeadTodoRuleEntities[id]) as OrganizationLeadTodoRule[];
  } else {
    delete companyStratalotTypeHydrated.organizationLeadTodoRules;
  }

  if (organizationStratalotTodoRuleEntities) {
    companyStratalotTypeHydrated.organizationStratalotTodoRules = (
      (companyStratalotTypeHydrated.organizationStratalotTodoRules as number[]) || []
    ).map(id => organizationStratalotTodoRuleEntities[id]) as OrganizationStratalotTodoRule[];
  } else {
    delete companyStratalotTypeHydrated.organizationStratalotTodoRules;
  }

  return companyStratalotTypeHydrated as CompanyStratalotType;
}
