import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationProspectOrigin,
  OrganizationProspectOriginEntityState
} from '@_model/interfaces/organization-prospect-origin.model';
import { OrganizationProspectOriginRelationsIds } from './organization-prospect-origin-generated.effects';

export const getOneOrganizationProspectOrigin = createAction(
  '[OrganizationProspectOrigin] Get One OrganizationProspectOrigin',
  props<{ idOrganizationProspectOrigin: number; params?: any }>()
);

export const getManyOrganizationProspectOrigins = createAction(
  '[OrganizationProspectOrigin] Get Many OrganizationProspectOrigins',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationProspectOrigin] Add Many Actives OrganizationProspectOrigin',
  props<{ idOrganizationProspectOrigins: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationProspectOrigin] Delete One Active OrganizationProspectOrigin',
  props<{ idOrganizationProspectOrigin: number }>()
);

export const clearActive = createAction('[OrganizationProspectOrigin] Clear Active OrganizationProspectOrigin');

export const upsertOneOrganizationProspectOrigin = createAction(
  '[OrganizationProspectOrigin] Upsert One OrganizationProspectOrigin',
  props<{
    organizationProspectOrigin: Partial<OrganizationProspectOrigin>;
    ids?: OrganizationProspectOriginRelationsIds;
  }>()
);

export const upsertManyOrganizationProspectOrigins = createAction(
  '[OrganizationProspectOrigin] Upsert Many OrganizationProspectOrigins',
  props<{
    organizationProspectOrigins: Partial<OrganizationProspectOrigin>[];
    ids?: OrganizationProspectOriginRelationsIds;
  }>()
);

export const upsertManyOrganizationProspectOriginsSuccess = createAction(
  '[OrganizationProspectOrigin] Create Many OrganizationProspectOrigins Success',
  props<{ organizationProspectOrigins: OrganizationProspectOriginEntityState[] }>()
);

export const deleteOneOrganizationProspectOrigin = createAction(
  '[OrganizationProspectOrigin] Delete One OrganizationProspectOrigin',
  props<{ idOrganizationProspectOrigin: number }>()
);

export const deleteOneOrganizationProspectOriginSuccess = createAction(
  '[OrganizationProspectOrigin] Delete One OrganizationProspectOrigin Success',
  props<{ idOrganizationProspectOrigin: number }>()
);

export const normalizeManyOrganizationProspectOriginsAfterUpsert = createAction(
  '[OrganizationProspectOrigin] Normalize Many OrganizationProspectOrigins After Upsert',
  props<{ organizationProspectOrigins: OrganizationProspectOriginEntityState[] }>()
);

export const organizationProspectOriginsFailure = createAction(
  '[OrganizationProspectOrigin] OrganizationProspectOrigins Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationProspectOrigin] Clear OrganizationProspectOrigins');

export const addManyProspectSuccess = createAction(
  '[OrganizationProspectOrigin] Add Many prospect',
  props<{ idOrganizationProspectOrigin: number; idProspects: number[] }>()
);

export const deleteManyProspectSuccess = createAction(
  '[OrganizationProspectOrigin] Delete Many Prospects',
  props<{ idProspects: number[]; idOrganizationProspectOrigins: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[OrganizationProspectOrigin] Add Organization',
  props<{ idOrganizationProspectOrigin: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationProspectOrigin] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationProspectOrigins: number[] }>()
);
