import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ResidenceStudyWorkApiService } from '@_services/api/residence-study-work-api.service';
import { GeneratedResidenceStudyWorkEffects } from './residence-study-work-generated.effects';

@Injectable()
export class ResidenceStudyWorkEffects extends GeneratedResidenceStudyWorkEffects {
  constructor(actions$: Actions, residenceStudyWorkApiService: ResidenceStudyWorkApiService, store$: Store<AppState>) {
    super(actions$, residenceStudyWorkApiService, store$);
  }
}
