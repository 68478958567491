import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as SaleCategoryFamilyActions from './sale-category-family.actions';
import { adapter, initialState, SaleCategoryFamilyState } from './sale-category-family.state';
import { Dictionary } from '@ngrx/entity';
import { SaleCategoryFamilyEntityState } from '@_model/interfaces/sale-category-family.model';

export const saleCategoryFamilyReducersGeneratedFunctions: ReducerTypes<
  SaleCategoryFamilyState,
  readonly ActionCreator[]
>[] = [
  on(SaleCategoryFamilyActions.getOneSaleCategoryFamily, (state: SaleCategoryFamilyState) =>
    setLoadingsState(state, true)
  ),
  on(SaleCategoryFamilyActions.getManyFamilySaleCategories, (state: SaleCategoryFamilyState) =>
    setLoadingsState(state, true)
  ),
  on(SaleCategoryFamilyActions.upsertOneSaleCategoryFamily, (state: SaleCategoryFamilyState) =>
    setLoadingsState(state, true)
  ),

  on(
    SaleCategoryFamilyActions.upsertManyFamilySaleCategoriesSuccess,
    (state: SaleCategoryFamilyState, { familySaleCategories }) =>
      adapter.upsertMany(familySaleCategories, setLoadingsState(state, false))
  ),
  on(SaleCategoryFamilyActions.deleteOneSaleCategoryFamily, (state: SaleCategoryFamilyState) =>
    setLoadingsState(state, true)
  ),
  on(
    SaleCategoryFamilyActions.deleteOneSaleCategoryFamilySuccess,
    (state: SaleCategoryFamilyState, { idSaleCategoryFamily }) =>
      adapter.removeOne(idSaleCategoryFamily, setLoadingsState(state, false))
  ),
  on(SaleCategoryFamilyActions.clearActive, (state: SaleCategoryFamilyState) => ({ ...state, actives: [] })),
  on(SaleCategoryFamilyActions.addManyActives, (state: SaleCategoryFamilyState, { idFamilySaleCategories }) => ({
    ...state,
    actives: state.actives.concat(idFamilySaleCategories)
  })),
  on(SaleCategoryFamilyActions.deleteOneActive, (state: SaleCategoryFamilyState, { idSaleCategoryFamily }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idSaleCategoryFamily)
  })),

  on(SaleCategoryFamilyActions.clearStore, () => initialState),

  on(
    SaleCategoryFamilyActions.addManyOrganizationSaleCategorySuccess,
    (state: SaleCategoryFamilyState, { idSaleCategoryFamily, idOrganizationSaleCategories }) => {
      if (!state.entities[idSaleCategoryFamily]) {
        return state;
      }
      const organizationSaleCategories =
        (state.entities[idSaleCategoryFamily]?.organizationSaleCategories as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSaleCategoryFamily]: {
            ...state.entities[idSaleCategoryFamily],
            organizationSaleCategories: organizationSaleCategories.concat(
              idOrganizationSaleCategories.filter(id => organizationSaleCategories.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SaleCategoryFamilyActions.deleteManyOrganizationSaleCategorySuccess,
    (state: SaleCategoryFamilyState, { idOrganizationSaleCategories, idFamilySaleCategories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFamilySaleCategories.reduce((entities, idSaleCategoryFamily) => {
            if (!state.entities[idSaleCategoryFamily]?.organizationSaleCategories) {
              return entities;
            }
            entities[idSaleCategoryFamily] = {
              ...state.entities[idSaleCategoryFamily],
              organizationSaleCategories: (
                state.entities[idSaleCategoryFamily]?.organizationSaleCategories as number[]
              )?.filter(
                (idOrganizationSaleCategory: number) =>
                  !idOrganizationSaleCategories.some((id: number) => id === idOrganizationSaleCategory)
              )
            } as SaleCategoryFamilyEntityState;
            return entities;
          }, {} as Dictionary<SaleCategoryFamilyEntityState>)
        }
      };
    }
  ),

  on(
    SaleCategoryFamilyActions.addManyBoardStateSuccess,
    (state: SaleCategoryFamilyState, { idSaleCategoryFamily, idBoardStates }) => {
      if (!state.entities[idSaleCategoryFamily]) {
        return state;
      }
      const boardStates = (state.entities[idSaleCategoryFamily]?.boardStates as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSaleCategoryFamily]: {
            ...state.entities[idSaleCategoryFamily],
            boardStates: boardStates.concat(idBoardStates.filter(id => boardStates.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    SaleCategoryFamilyActions.deleteManyBoardStateSuccess,
    (state: SaleCategoryFamilyState, { idBoardStates, idFamilySaleCategories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFamilySaleCategories.reduce((entities, idSaleCategoryFamily) => {
            if (!state.entities[idSaleCategoryFamily]?.boardStates) {
              return entities;
            }
            entities[idSaleCategoryFamily] = {
              ...state.entities[idSaleCategoryFamily],
              boardStates: (state.entities[idSaleCategoryFamily]?.boardStates as number[])?.filter(
                (idBoardState: number) => !idBoardStates.some((id: number) => id === idBoardState)
              )
            } as SaleCategoryFamilyEntityState;
            return entities;
          }, {} as Dictionary<SaleCategoryFamilyEntityState>)
        }
      };
    }
  ),

  on(
    SaleCategoryFamilyActions.addManyCompanyPriceLabelSuccess,
    (state: SaleCategoryFamilyState, { idSaleCategoryFamily, idCompanyPriceLabels }) => {
      if (!state.entities[idSaleCategoryFamily]) {
        return state;
      }
      const companyPriceLabels = (state.entities[idSaleCategoryFamily]?.companyPriceLabels as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSaleCategoryFamily]: {
            ...state.entities[idSaleCategoryFamily],
            companyPriceLabels: companyPriceLabels.concat(
              idCompanyPriceLabels.filter(id => companyPriceLabels.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SaleCategoryFamilyActions.deleteManyCompanyPriceLabelSuccess,
    (state: SaleCategoryFamilyState, { idCompanyPriceLabels, idFamilySaleCategories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFamilySaleCategories.reduce((entities, idSaleCategoryFamily) => {
            if (!state.entities[idSaleCategoryFamily]?.companyPriceLabels) {
              return entities;
            }
            entities[idSaleCategoryFamily] = {
              ...state.entities[idSaleCategoryFamily],
              companyPriceLabels: (state.entities[idSaleCategoryFamily]?.companyPriceLabels as number[])?.filter(
                (idCompanyPriceLabel: number) => !idCompanyPriceLabels.some((id: number) => id === idCompanyPriceLabel)
              )
            } as SaleCategoryFamilyEntityState;
            return entities;
          }, {} as Dictionary<SaleCategoryFamilyEntityState>)
        }
      };
    }
  ),

  on(
    SaleCategoryFamilyActions.addManyOrganizationLeadTodoRuleSuccess,
    (state: SaleCategoryFamilyState, { idSaleCategoryFamily, idOrganizationLeadTodoRules }) => {
      if (!state.entities[idSaleCategoryFamily]) {
        return state;
      }
      const organizationLeadTodoRules =
        (state.entities[idSaleCategoryFamily]?.organizationLeadTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSaleCategoryFamily]: {
            ...state.entities[idSaleCategoryFamily],
            organizationLeadTodoRules: organizationLeadTodoRules.concat(
              idOrganizationLeadTodoRules.filter(id => organizationLeadTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SaleCategoryFamilyActions.deleteManyOrganizationLeadTodoRuleSuccess,
    (state: SaleCategoryFamilyState, { idOrganizationLeadTodoRules, idFamilySaleCategories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFamilySaleCategories.reduce((entities, idSaleCategoryFamily) => {
            if (!state.entities[idSaleCategoryFamily]?.organizationLeadTodoRules) {
              return entities;
            }
            entities[idSaleCategoryFamily] = {
              ...state.entities[idSaleCategoryFamily],
              organizationLeadTodoRules: (
                state.entities[idSaleCategoryFamily]?.organizationLeadTodoRules as number[]
              )?.filter(
                (idOrganizationLeadTodoRule: number) =>
                  !idOrganizationLeadTodoRules.some((id: number) => id === idOrganizationLeadTodoRule)
              )
            } as SaleCategoryFamilyEntityState;
            return entities;
          }, {} as Dictionary<SaleCategoryFamilyEntityState>)
        }
      };
    }
  ),

  on(
    SaleCategoryFamilyActions.addManyResidenceSaleCategoryFamilySuccess,
    (state: SaleCategoryFamilyState, { idSaleCategoryFamily, idResidenceSaleCategoryFamilies }) => {
      if (!state.entities[idSaleCategoryFamily]) {
        return state;
      }
      const residenceSaleCategoryFamilies =
        (state.entities[idSaleCategoryFamily]?.residenceSaleCategoryFamilies as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSaleCategoryFamily]: {
            ...state.entities[idSaleCategoryFamily],
            residenceSaleCategoryFamilies: residenceSaleCategoryFamilies.concat(
              idResidenceSaleCategoryFamilies.filter(id => residenceSaleCategoryFamilies.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SaleCategoryFamilyActions.deleteManyResidenceSaleCategoryFamilySuccess,
    (state: SaleCategoryFamilyState, { idResidenceSaleCategoryFamilies, idFamilySaleCategories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFamilySaleCategories.reduce((entities, idSaleCategoryFamily) => {
            if (!state.entities[idSaleCategoryFamily]?.residenceSaleCategoryFamilies) {
              return entities;
            }
            entities[idSaleCategoryFamily] = {
              ...state.entities[idSaleCategoryFamily],
              residenceSaleCategoryFamilies: (
                state.entities[idSaleCategoryFamily]?.residenceSaleCategoryFamilies as number[]
              )?.filter(
                (idResidenceSaleCategoryFamily: number) =>
                  !idResidenceSaleCategoryFamilies.some((id: number) => id === idResidenceSaleCategoryFamily)
              )
            } as SaleCategoryFamilyEntityState;
            return entities;
          }, {} as Dictionary<SaleCategoryFamilyEntityState>)
        }
      };
    }
  ),

  on(
    SaleCategoryFamilyActions.addManyResidenceSuccess,
    (state: SaleCategoryFamilyState, { idSaleCategoryFamily, idResidences }) => {
      if (!state.entities[idSaleCategoryFamily]) {
        return state;
      }
      const residences = (state.entities[idSaleCategoryFamily]?.residences as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSaleCategoryFamily]: {
            ...state.entities[idSaleCategoryFamily],
            residences: residences.concat(idResidences.filter(id => residences.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    SaleCategoryFamilyActions.deleteManyResidenceSuccess,
    (state: SaleCategoryFamilyState, { idResidences, idFamilySaleCategories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFamilySaleCategories.reduce((entities, idSaleCategoryFamily) => {
            if (!state.entities[idSaleCategoryFamily]?.residences) {
              return entities;
            }
            entities[idSaleCategoryFamily] = {
              ...state.entities[idSaleCategoryFamily],
              residences: (state.entities[idSaleCategoryFamily]?.residences as number[])?.filter(
                (idResidence: number) => !idResidences.some((id: number) => id === idResidence)
              )
            } as SaleCategoryFamilyEntityState;
            return entities;
          }, {} as Dictionary<SaleCategoryFamilyEntityState>)
        }
      };
    }
  ),

  on(
    SaleCategoryFamilyActions.addManyOrganizationStratalotTodoRuleSuccess,
    (state: SaleCategoryFamilyState, { idSaleCategoryFamily, idOrganizationStratalotTodoRules }) => {
      if (!state.entities[idSaleCategoryFamily]) {
        return state;
      }
      const organizationStratalotTodoRules =
        (state.entities[idSaleCategoryFamily]?.organizationStratalotTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSaleCategoryFamily]: {
            ...state.entities[idSaleCategoryFamily],
            organizationStratalotTodoRules: organizationStratalotTodoRules.concat(
              idOrganizationStratalotTodoRules.filter(id => organizationStratalotTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SaleCategoryFamilyActions.deleteManyOrganizationStratalotTodoRuleSuccess,
    (state: SaleCategoryFamilyState, { idOrganizationStratalotTodoRules, idFamilySaleCategories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFamilySaleCategories.reduce((entities, idSaleCategoryFamily) => {
            if (!state.entities[idSaleCategoryFamily]?.organizationStratalotTodoRules) {
              return entities;
            }
            entities[idSaleCategoryFamily] = {
              ...state.entities[idSaleCategoryFamily],
              organizationStratalotTodoRules: (
                state.entities[idSaleCategoryFamily]?.organizationStratalotTodoRules as number[]
              )?.filter(
                (idOrganizationStratalotTodoRule: number) =>
                  !idOrganizationStratalotTodoRules.some((id: number) => id === idOrganizationStratalotTodoRule)
              )
            } as SaleCategoryFamilyEntityState;
            return entities;
          }, {} as Dictionary<SaleCategoryFamilyEntityState>)
        }
      };
    }
  ),

  on(
    SaleCategoryFamilyActions.addManyOrganizationResidenceTodoRuleSuccess,
    (state: SaleCategoryFamilyState, { idSaleCategoryFamily, idOrganizationResidenceTodoRules }) => {
      if (!state.entities[idSaleCategoryFamily]) {
        return state;
      }
      const organizationResidenceTodoRules =
        (state.entities[idSaleCategoryFamily]?.organizationResidenceTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSaleCategoryFamily]: {
            ...state.entities[idSaleCategoryFamily],
            organizationResidenceTodoRules: organizationResidenceTodoRules.concat(
              idOrganizationResidenceTodoRules.filter(id => organizationResidenceTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SaleCategoryFamilyActions.deleteManyOrganizationResidenceTodoRuleSuccess,
    (state: SaleCategoryFamilyState, { idOrganizationResidenceTodoRules, idFamilySaleCategories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idFamilySaleCategories.reduce((entities, idSaleCategoryFamily) => {
            if (!state.entities[idSaleCategoryFamily]?.organizationResidenceTodoRules) {
              return entities;
            }
            entities[idSaleCategoryFamily] = {
              ...state.entities[idSaleCategoryFamily],
              organizationResidenceTodoRules: (
                state.entities[idSaleCategoryFamily]?.organizationResidenceTodoRules as number[]
              )?.filter(
                (idOrganizationResidenceTodoRule: number) =>
                  !idOrganizationResidenceTodoRules.some((id: number) => id === idOrganizationResidenceTodoRule)
              )
            } as SaleCategoryFamilyEntityState;
            return entities;
          }, {} as Dictionary<SaleCategoryFamilyEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: SaleCategoryFamilyState,
  isLoading: boolean,
  isLoaded: boolean = true
): SaleCategoryFamilyState {
  return { ...state, isLoaded, isLoading };
}
