import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { StratalotFeeApiService } from '@_services/api/stratalot-fee-api.service';
import { GeneratedStratalotFeeEffects } from './stratalot-fee-generated.effects';

@Injectable()
export class StratalotFeeEffects extends GeneratedStratalotFeeEffects {
  constructor(actions$: Actions, stratalotFeeApiService: StratalotFeeApiService, store$: Store<AppState>) {
    super(actions$, stratalotFeeApiService, store$);
  }
}
