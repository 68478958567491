import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CompanyTerritoire, CompanyTerritoireEntityState } from '@_model/interfaces/company-territoire.model';
import { CompanyTerritoireRelationsIds } from './company-territoire-generated.effects';

export const getOneCompanyTerritoire = createAction(
  '[CompanyTerritoire] Get One CompanyTerritoire',
  props<{ idCompanyTerritoire: number; params?: any }>()
);

export const getManyCompanyTerritoires = createAction(
  '[CompanyTerritoire] Get Many CompanyTerritoires',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyTerritoire] Add Many Actives CompanyTerritoire',
  props<{ idCompanyTerritoires: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyTerritoire] Delete One Active CompanyTerritoire',
  props<{ idCompanyTerritoire: number }>()
);

export const clearActive = createAction('[CompanyTerritoire] Clear Active CompanyTerritoire');

export const upsertOneCompanyTerritoire = createAction(
  '[CompanyTerritoire] Upsert One CompanyTerritoire',
  props<{
    companyTerritoire: Partial<CompanyTerritoire>;
    ids?: CompanyTerritoireRelationsIds;
  }>()
);

export const upsertManyCompanyTerritoires = createAction(
  '[CompanyTerritoire] Upsert Many CompanyTerritoires',
  props<{
    companyTerritoires: Partial<CompanyTerritoire>[];
    ids?: CompanyTerritoireRelationsIds;
  }>()
);

export const upsertManyCompanyTerritoiresSuccess = createAction(
  '[CompanyTerritoire] Create Many CompanyTerritoires Success',
  props<{ companyTerritoires: CompanyTerritoireEntityState[] }>()
);

export const deleteOneCompanyTerritoire = createAction(
  '[CompanyTerritoire] Delete One CompanyTerritoire',
  props<{ idCompanyTerritoire: number }>()
);

export const deleteOneCompanyTerritoireSuccess = createAction(
  '[CompanyTerritoire] Delete One CompanyTerritoire Success',
  props<{ idCompanyTerritoire: number }>()
);

export const normalizeManyCompanyTerritoiresAfterUpsert = createAction(
  '[CompanyTerritoire] Normalize Many CompanyTerritoires After Upsert',
  props<{ companyTerritoires: CompanyTerritoireEntityState[] }>()
);

export const companyTerritoiresFailure = createAction(
  '[CompanyTerritoire] CompanyTerritoires Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyTerritoire] Clear CompanyTerritoires');

export const addManyCompanyTerritoireUserSuccess = createAction(
  '[CompanyTerritoire] Add Many company-territoire-user',
  props<{ idCompanyTerritoire: number; idCompanyTerritoireUsers: number[] }>()
);

export const deleteManyCompanyTerritoireUserSuccess = createAction(
  '[CompanyTerritoire] Delete Many CompanyTerritoireUsers',
  props<{ idCompanyTerritoireUsers: number[]; idCompanyTerritoires: number[] }>()
);

export const addManyResidenceSuccess = createAction(
  '[CompanyTerritoire] Add Many residence',
  props<{ idCompanyTerritoire: number; idResidences: number[] }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[CompanyTerritoire] Delete Many Residences',
  props<{ idResidences: number[]; idCompanyTerritoires: number[] }>()
);

export const addCompanySuccess = createAction(
  '[CompanyTerritoire] Add Company',
  props<{ idCompanyTerritoire: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[CompanyTerritoire] Delete Many Company',
  props<{ idCompanies: number[]; idCompanyTerritoires: number[] }>()
);

export const addResponsableSuccess = createAction(
  '[CompanyTerritoire] Add Responsable',
  props<{ idCompanyTerritoire: number; idResponsable: number }>()
);

export const deleteManyResponsableSuccess = createAction(
  '[CompanyTerritoire] Delete Many Responsable',
  props<{ idResponsable: number[]; idCompanyTerritoires: number[] }>()
);
