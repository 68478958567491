import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationStratalotAvancement,
  OrganizationStratalotAvancementEntityState
} from '@_model/interfaces/organization-stratalot-avancement.model';
import { OrganizationStratalotAvancementApiService } from '@_services/api/organization-stratalot-avancement-api.service';
import * as organizationStratalotAvancementActions from '@_store/organization-stratalot-avancement/organization-stratalot-avancement.actions';
import { getActionsToNormalizeOrganizationStratalotAvancement } from '@_config/store/normalization.generated';
import { selectOrganizationStratalotAvancementState } from './organization-stratalot-avancement-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as stepActions from '@_store/step/step.actions';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import { Stratalot } from '@_model/interfaces/stratalot.model';

export interface OrganizationStratalotAvancementRelationsIds {
  stratalots?: number | number[];
  organization?: number;
  step?: number;
}

export function getDefaultAddOrganizationStratalotAvancementActions(
  organizationStratalotAvancement: OrganizationStratalotAvancementEntityState,
  ids?: OrganizationStratalotAvancementRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationStratalotAvancementActions.normalizeManyOrganizationStratalotAvancementsAfterUpsert({
      organizationStratalotAvancements: [organizationStratalotAvancement]
    })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationStratalotAvancementSuccess({
        idOrganization: ids.organization,
        idOrganizationStratalotAvancements: [organizationStratalotAvancement.idOrganizationStratalotAvancement]
      })
    );
    actions.push(
      organizationStratalotAvancementActions.addOrganizationSuccess({
        idOrganizationStratalotAvancement: organizationStratalotAvancement.idOrganizationStratalotAvancement,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.step) {
    actions.push(
      stepActions.addManyOrganizationStratalotAvancementSuccess({
        idStep: ids.step,
        idOrganizationStratalotAvancements: [organizationStratalotAvancement.idOrganizationStratalotAvancement]
      })
    );
    actions.push(
      organizationStratalotAvancementActions.addStepSuccess({
        idOrganizationStratalotAvancement: organizationStratalotAvancement.idOrganizationStratalotAvancement,
        idStep: ids.step
      })
    );
  }

  if (ids?.stratalots) {
    if (!Array.isArray(ids.stratalots)) {
      actions.push(
        stratalotActions.upsertOneStratalot({
          stratalot: {
            idOrganizationStratalotAvancement: organizationStratalotAvancement.idOrganizationStratalotAvancement,
            idStratalot: ids.stratalots as number
          } as Stratalot
        })
      );
      actions.push(
        organizationStratalotAvancementActions.addManyStratalotSuccess({
          idOrganizationStratalotAvancement: organizationStratalotAvancement.idOrganizationStratalotAvancement,
          idStratalots: [ids.stratalots as number]
        })
      );
    } else {
      actions.push(
        stratalotActions.upsertManyStratalots({
          stratalots: (ids.stratalots as number[]).map((idStratalot: number) => ({
            idOrganizationStratalotAvancement: organizationStratalotAvancement.idOrganizationStratalotAvancement,
            idStratalot
          })) as Stratalot[]
        })
      );
      actions.push(
        organizationStratalotAvancementActions.addManyStratalotSuccess({
          idOrganizationStratalotAvancement: organizationStratalotAvancement.idOrganizationStratalotAvancement,
          idStratalots: ids.stratalots as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationStratalotAvancementActions(
  organizationStratalotAvancement: OrganizationStratalotAvancementEntityState
): Action[] {
  const actions: Action[] = [
    organizationStratalotAvancementActions.deleteOneOrganizationStratalotAvancementSuccess({
      idOrganizationStratalotAvancement: organizationStratalotAvancement.idOrganizationStratalotAvancement
    })
  ];

  if (organizationStratalotAvancement.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationStratalotAvancementSuccess({
        idOrganizationStratalotAvancements: [organizationStratalotAvancement.idOrganizationStratalotAvancement],
        idOrganizations: [organizationStratalotAvancement.organization as number]
      })
    );
  }

  if (organizationStratalotAvancement.step) {
    actions.push(
      stepActions.deleteManyOrganizationStratalotAvancementSuccess({
        idOrganizationStratalotAvancements: [organizationStratalotAvancement.idOrganizationStratalotAvancement],
        idSteps: [organizationStratalotAvancement.step as number]
      })
    );
  }

  if (organizationStratalotAvancement.stratalots) {
    actions.push(
      stratalotActions.deleteManyOrganizationStratalotAvancementSuccess({
        idOrganizationStratalotAvancements: [organizationStratalotAvancement.idOrganizationStratalotAvancement],
        idStratalots: organizationStratalotAvancement.stratalots as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationStratalotAvancementEffects {
  constructor(
    protected actions$: Actions,
    protected organizationStratalotAvancementApiService: OrganizationStratalotAvancementApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationStratalotAvancements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotAvancementActions.getManyOrganizationStratalotAvancements),
      switchMap(({ params }) =>
        this.organizationStratalotAvancementApiService.getOrganizationStratalotAvancements(params).pipe(
          map((organizationStratalotAvancements: OrganizationStratalotAvancement[]) => {
            return organizationStratalotAvancementActions.normalizeManyOrganizationStratalotAvancementsAfterUpsert({
              organizationStratalotAvancements
            });
          }),
          catchError(error =>
            of(organizationStratalotAvancementActions.organizationStratalotAvancementsFailure({ error }))
          )
        )
      )
    );
  });

  getOneOrganizationStratalotAvancement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotAvancementActions.getOneOrganizationStratalotAvancement),
      switchMap(idOrganizationStratalotAvancement =>
        this.organizationStratalotAvancementApiService
          .getOrganizationStratalotAvancement(idOrganizationStratalotAvancement)
          .pipe(
            map((organizationStratalotAvancement: OrganizationStratalotAvancement) => {
              return organizationStratalotAvancementActions.normalizeManyOrganizationStratalotAvancementsAfterUpsert({
                organizationStratalotAvancements: [organizationStratalotAvancement]
              });
            }),
            catchError(error =>
              of(organizationStratalotAvancementActions.organizationStratalotAvancementsFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneOrganizationStratalotAvancement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotAvancementActions.upsertOneOrganizationStratalotAvancement),
      concatMap(
        ({
          organizationStratalotAvancement,
          ids
        }: {
          organizationStratalotAvancement: Partial<OrganizationStratalotAvancement>;
          ids?: OrganizationStratalotAvancementRelationsIds;
        }) => {
          if (organizationStratalotAvancement.idOrganizationStratalotAvancement) {
            return this.organizationStratalotAvancementApiService
              .updateOrganizationStratalotAvancement(organizationStratalotAvancement)
              .pipe(
                map((organizationStratalotAvancementReturned: OrganizationStratalotAvancement) => {
                  return organizationStratalotAvancementActions.normalizeManyOrganizationStratalotAvancementsAfterUpsert(
                    { organizationStratalotAvancements: [organizationStratalotAvancementReturned] }
                  );
                }),
                catchError(error =>
                  of(organizationStratalotAvancementActions.organizationStratalotAvancementsFailure({ error }))
                )
              );
          } else {
            return this.organizationStratalotAvancementApiService
              .addOrganizationStratalotAvancement(organizationStratalotAvancement)
              .pipe(
                mergeMap((organizationStratalotAvancementReturned: OrganizationStratalotAvancement) =>
                  getDefaultAddOrganizationStratalotAvancementActions(organizationStratalotAvancementReturned, ids)
                ),
                catchError(error =>
                  of(organizationStratalotAvancementActions.organizationStratalotAvancementsFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationStratalotAvancement$ = createEffect(() => {
    const selectOrganizationStratalotAvancementState$ = this.store$.select(selectOrganizationStratalotAvancementState);
    return this.actions$.pipe(
      ofType(organizationStratalotAvancementActions.deleteOneOrganizationStratalotAvancement),
      withLatestFrom(selectOrganizationStratalotAvancementState$),
      concatMap(([{ idOrganizationStratalotAvancement }, state]) =>
        this.organizationStratalotAvancementApiService
          .deleteOrganizationStratalotAvancement(idOrganizationStratalotAvancement)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteOrganizationStratalotAvancementActions(
                  state.entities[idOrganizationStratalotAvancement] as OrganizationStratalotAvancementEntityState
                ),
                organizationStratalotAvancementActions.deleteOneOrganizationStratalotAvancement.type
              )
            ]),
            catchError(error =>
              of(organizationStratalotAvancementActions.organizationStratalotAvancementsFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyOrganizationStratalotAvancementsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotAvancementActions.normalizeManyOrganizationStratalotAvancementsAfterUpsert),
      concatMap(({ organizationStratalotAvancements }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationStratalotAvancement(
          organizationStratalotAvancements,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationStratalotAvancement] Normalization After Upsert Success')];
      })
    );
  });
}
