import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidenceStudyFoundingActions from './residence-study-founding.actions';
import { adapter, initialState, ResidenceStudyFoundingState } from './residence-study-founding.state';
import { Dictionary } from '@ngrx/entity';
import { ResidenceStudyFoundingEntityState } from '@_model/interfaces/residence-study-founding.model';

export const residenceStudyFoundingReducersGeneratedFunctions: ReducerTypes<
  ResidenceStudyFoundingState,
  readonly ActionCreator[]
>[] = [
  on(ResidenceStudyFoundingActions.getOneResidenceStudyFounding, (state: ResidenceStudyFoundingState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceStudyFoundingActions.getManyResidenceStudyFoundings, (state: ResidenceStudyFoundingState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceStudyFoundingActions.upsertOneResidenceStudyFounding, (state: ResidenceStudyFoundingState) =>
    setLoadingsState(state, true)
  ),

  on(
    ResidenceStudyFoundingActions.upsertManyResidenceStudyFoundingsSuccess,
    (state: ResidenceStudyFoundingState, { residenceStudyFoundings }) =>
      adapter.upsertMany(residenceStudyFoundings, setLoadingsState(state, false))
  ),
  on(ResidenceStudyFoundingActions.deleteOneResidenceStudyFounding, (state: ResidenceStudyFoundingState) =>
    setLoadingsState(state, true)
  ),
  on(
    ResidenceStudyFoundingActions.deleteOneResidenceStudyFoundingSuccess,
    (state: ResidenceStudyFoundingState, { idResidenceStudyFounding }) =>
      adapter.removeOne(idResidenceStudyFounding, setLoadingsState(state, false))
  ),
  on(ResidenceStudyFoundingActions.clearActive, (state: ResidenceStudyFoundingState) => ({ ...state, actives: [] })),
  on(
    ResidenceStudyFoundingActions.addManyActives,
    (state: ResidenceStudyFoundingState, { idResidenceStudyFoundings }) => ({
      ...state,
      actives: state.actives.concat(idResidenceStudyFoundings)
    })
  ),
  on(
    ResidenceStudyFoundingActions.deleteOneActive,
    (state: ResidenceStudyFoundingState, { idResidenceStudyFounding }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idResidenceStudyFounding)
    })
  ),

  on(ResidenceStudyFoundingActions.clearStore, () => initialState),

  on(
    ResidenceStudyFoundingActions.addResidenceStudySuccess,
    (state: ResidenceStudyFoundingState, { idResidenceStudyFounding, idResidenceStudy }) => {
      if (!state.entities[idResidenceStudyFounding]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudyFounding]: {
            ...state.entities[idResidenceStudyFounding],
            residenceStudy: idResidenceStudy
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyFoundingActions.deleteManyResidenceStudySuccess,
    (state: ResidenceStudyFoundingState, { idResidenceStudies, idResidenceStudyFoundings }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudyFoundings.reduce((entities, idResidenceStudyFounding) => {
            if (!state.entities[idResidenceStudyFounding]?.residenceStudy) {
              return entities;
            }
            entities[idResidenceStudyFounding] = {
              ...state.entities[idResidenceStudyFounding],
              residenceStudy: idResidenceStudies.some(
                (idResidenceStudy: number) =>
                  idResidenceStudy === state.entities[idResidenceStudyFounding]?.residenceStudy
              )
                ? undefined
                : state.entities[idResidenceStudyFounding]?.residenceStudy
            } as ResidenceStudyFoundingEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyFoundingEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidenceStudyFoundingState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidenceStudyFoundingState {
  return { ...state, isLoaded, isLoading };
}
