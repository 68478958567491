import { StratalotTypeAggrege } from '@_shared/enum/stratalot-type-aggrege';
import { TypeLotGroupEnum } from '@_shared/models/enums/type-lot-group.enum';
import { CompanyStratalotType } from '@_shared/models/interfaces/company-stratalot-type.model';
import { Company } from '@_shared/models/interfaces/company.model';
import { StratalotType } from '@_shared/models/interfaces/stratalot-type.model';
import StratalotUtils from './stratalot.utils';

export interface CompanyParams {
  idResidence?: number;
  idCompany?: number;
}

namespace CompanyParamsUtils {
  let _companyStratalotTypesByCompanies: {
    idCompany: number;
    idsResidences: number[];
    companyStratalotTypes: CompanyStratalotType[];
    stratalotTypes: StratalotType[];
  }[] = [];

  export function getStratalotTypesFromCompany(company: Company): StratalotType[] {
    const stratalotsTypes: StratalotType[] = [];
    company.companyStratalotTypes?.forEach(companyStratalotType => {
      if (
        !stratalotsTypes.find(type => type.idStratalotType === companyStratalotType?.stratalotType?.idStratalotType)
      ) {
        stratalotsTypes.push(companyStratalotType.stratalotType);
      }
    });
    return stratalotsTypes;
  }

  export function getStratalotTypesByStratalotTypeAggrege(
    data: CompanyParams,
    stratalotTypeAggrege: StratalotTypeAggrege
  ): StratalotType[] {
    return getStratalotTypes(data)?.filter(stratalotType => {
      return StratalotUtils.getTypeAggrege(stratalotType) === stratalotTypeAggrege;
    });
  }

  export function setCompanyStratalotTypesByCompanies(companies: Company[]): void {
    _companyStratalotTypesByCompanies = [];
    companies.forEach(company => {
      _companyStratalotTypesByCompanies.push({
        idCompany: company.idCompany,
        idsResidences: company.residences.map(residence => {
          return residence?.idResidence;
        }),
        companyStratalotTypes: company.companyStratalotTypes,
        stratalotTypes: getStratalotTypesFromCompany(company)
      });
    });
  }

  export function getStratalotTypes(data: CompanyParams): StratalotType[] {
    if (data.idCompany) {
      return _companyStratalotTypesByCompanies?.find(cstbc => cstbc.idCompany === data.idCompany)?.stratalotTypes;
    } else if (data.idResidence) {
      return _companyStratalotTypesByCompanies?.find(cstbc => cstbc.idsResidences.includes(data.idResidence))
        ?.stratalotTypes;
    }
  }

  export function getCompanyStratalotTypes(data: CompanyParams): CompanyStratalotType[] {
    if (data.idCompany) {
      return _companyStratalotTypesByCompanies?.find(cstbc => cstbc.idCompany === data.idCompany)
        ?.companyStratalotTypes;
    } else if (data.idResidence) {
      return _companyStratalotTypesByCompanies?.find(cstbc => cstbc.idsResidences.includes(data.idResidence))
        ?.companyStratalotTypes;
    }
  }

  export function getCompanyStratalotTypesByTypeLotGroupEnum(
    data: CompanyParams,
    typeLotGroupEnum: TypeLotGroupEnum[]
  ): CompanyStratalotType[] {
    return getCompanyStratalotTypes(data)?.filter(o => typeLotGroupEnum.some(t => t === o.stratalotType.libelle));
  }

  export function getCompanyStratalotTypesByStratalotTypeAggrege(
    data: CompanyParams,
    stratalotTypeAggrege: StratalotTypeAggrege
  ): CompanyStratalotType[] {
    return getCompanyStratalotTypes(data)?.filter(companyStratalotType => {
      return StratalotUtils.getTypeAggrege(companyStratalotType?.stratalotType) === stratalotTypeAggrege;
    });
  }

  export function getCompanyStratalotTypesGroupByStratalotTypeAggrege(data: CompanyParams): {
    value: StratalotTypeAggrege;
    children: CompanyStratalotType[];
  }[] {
    return Object.entries(
      getCompanyStratalotTypes(data)?.reduce((acc, curr) => {
        return acc[StratalotUtils.getTypeAggrege(curr.stratalotType)]
          ? {
              ...acc,
              [StratalotUtils.getTypeAggrege(curr.stratalotType)]: acc[
                StratalotUtils.getTypeAggrege(curr.stratalotType)
              ].concat([curr])
            }
          : { ...acc, [StratalotUtils.getTypeAggrege(curr.stratalotType)]: [curr] };
      }, {})
    )?.map(([key, value]: [StratalotTypeAggrege, CompanyStratalotType[]]) => ({ value: key, children: value }));
  }
}
export default CompanyParamsUtils;
