import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyCommunicationResidenceTodoApiService } from '@_services/api/company-communication-residence-todo-api.service';
import { GeneratedCompanyCommunicationResidenceTodoEffects } from './company-communication-residence-todo-generated.effects';

@Injectable()
export class CompanyCommunicationResidenceTodoEffects extends GeneratedCompanyCommunicationResidenceTodoEffects {
  constructor(
    actions$: Actions,
    companyCommunicationResidenceTodoApiService: CompanyCommunicationResidenceTodoApiService,
    store$: Store<AppState>
  ) {
    super(actions$, companyCommunicationResidenceTodoApiService, store$);
  }
}
