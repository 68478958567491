import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { City, CityEntityState } from '@_model/interfaces/city.model';
import { ProspectBuyingWish, ProspectBuyingWishEntityState } from '@_model/interfaces/prospect-buying-wish.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, cityFeatureKey, CityState } from './city.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const cityRelations: string[] = ['prospectBuyingWishs', 'residences', 'companies'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCityState = createFeatureSelector<CityState>(cityFeatureKey);

export const selectIsLoadedCity = createSelector(selectCityState, (state: CityState) => state.isLoaded);

export const selectIsLoadingCity = createSelector(selectCityState, (state: CityState) => state.isLoading);

export const selectIsReadyCity = createSelector(selectCityState, (state: CityState) => !state.isLoading);

export const selectIsReadyAndLoadedCity = createSelector(
  selectCityState,
  (state: CityState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CityModel: SelectorModel = {
  name: 'cities',
  getSelector: selectAllCitiesDictionary,
  isReady: selectIsReadyCity
};

export const selectCitiesEntities = createSelector(selectCityState, selectEntities);

export const selectCitiesArray = createSelector(selectCityState, selectAll);

export const selectIdCitiesActive = createSelector(selectCityState, (state: CityState) => state.actives);

const citiesInObject = (cities: Dictionary<CityEntityState>) => ({ cities });

const selectCitiesEntitiesDictionary = createSelector(selectCitiesEntities, citiesInObject);

const selectAllCitiesObject = createSelector(selectCitiesEntities, cities => {
  return hydrateAll({ cities });
});

const selectOneCityDictionary = (idCity: number) =>
  createSelector(selectCitiesEntities, cities => {
    return { cities: { [idCity]: cities[idCity] } };
  });

const selectOneCityDictionaryWithoutChild = (idCity: number) =>
  createSelector(selectCitiesEntities, cities => {
    return { city: cities[idCity] };
  });

const selectActiveCitiesEntities = createSelector(
  selectIdCitiesActive,
  selectCitiesEntities,
  (actives: number[], cities: Dictionary<CityEntityState>) => getCitiesFromActives(actives, cities)
);

function getCitiesFromActives(actives: number[], cities: Dictionary<CityEntityState>): Dictionary<CityEntityState> {
  return actives.reduce((acc, idActive) => {
    if (cities[idActive]) {
      acc[idActive] = cities[idActive];
    }
    return acc;
  }, {} as Dictionary<CityEntityState>);
}

const selectAllCitiesSelectors: Dictionary<Selector> = {};
export function selectAllCities(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<City>(
      schema,
      selectAllCitiesSelectors,
      selectCitiesEntitiesDictionary,
      getRelationSelectors,
      cityRelations,
      hydrateAll,
      'city'
    );
  } else {
    return selectAllCitiesObject;
  }
}

export function selectAllCitiesDictionary(schema: SelectSchema = {}, customKey: string = 'cities'): Selector {
  return createSelector(selectAllCities(schema), result => {
    const res = { [customKey]: {} as Dictionary<CityEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.cities.length; i++) {
      res[customKey][result.cities[i].idCity] = result.cities[i];
    }
    return res;
  });
}

export function selectOneCity(schema: SelectSchema = {}, idCity: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCityDictionary(idCity)];
    selectors.push(...getRelationSelectors(schema, cityRelations, 'city'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCityDictionaryWithoutChild(idCity);
  }
}

export function selectActiveCities(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [createSelector(selectActiveCitiesEntities, cities => ({ cities }))];
  selectors.push(...getRelationSelectors(schema, cityRelations, 'city'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  cities: Dictionary<CityEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  prospectBuyingWishs?: Dictionary<ProspectBuyingWishEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { cities: (City | null)[] } {
  const { cities, companies, prospectBuyingWishs, residences } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    cities: Object.keys(cities).map(idCity =>
      hydrate(cities[idCity] as CityEntityState, companies, prospectBuyingWishs, residences)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { city: CityEntityState | null } {
  const { cities, companies, prospectBuyingWishs, residences } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const city = Object.values(cities)[0];
  return { city: hydrate(city as CityEntityState, companies, prospectBuyingWishs, residences) };
}

function hydrate(
  city: CityEntityState,
  companyEntities?: Dictionary<CompanyEntityState>,
  prospectBuyingWishEntities?: Dictionary<ProspectBuyingWishEntityState>,
  residenceEntities?: Dictionary<ResidenceEntityState>
): City | null {
  if (!city) {
    return null;
  }

  const cityHydrated: CityEntityState = { ...city };
  if (companyEntities) {
    cityHydrated.company = companyEntities[city.company as number] as Company;
  } else {
    delete cityHydrated.company;
  }

  if (prospectBuyingWishEntities) {
    cityHydrated.prospectBuyingWishs = ((cityHydrated.prospectBuyingWishs as number[]) || []).map(
      id => prospectBuyingWishEntities[id]
    ) as ProspectBuyingWish[];
  } else {
    delete cityHydrated.prospectBuyingWishs;
  }

  if (residenceEntities) {
    cityHydrated.residences = ((cityHydrated.residences as number[]) || []).map(
      id => residenceEntities[id]
    ) as Residence[];
  } else {
    delete cityHydrated.residences;
  }

  return cityHydrated as City;
}
