import { CompanyCommunicationMediaState, initialState } from './company-communication-media.state';
import { Action, createReducer } from '@ngrx/store';
import { companyCommunicationMediaReducersGeneratedFunctions } from './company-communication-media-generated.reducer';

const companyCommunicationMediaReducersFunctions = [...companyCommunicationMediaReducersGeneratedFunctions];

const companyCommunicationMediaReducer = createReducer(initialState, ...companyCommunicationMediaReducersFunctions);

export function reducer(state: CompanyCommunicationMediaState | undefined, action: Action) {
  return companyCommunicationMediaReducer(state, action);
}
