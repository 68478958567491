import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Step, StepEntityState } from '@_model/interfaces/step.model';
import { StepRelationsIds } from './step-generated.effects';

export const getOneStep = createAction('[Step] Get One Step', props<{ idStep: number; params?: any }>());

export const getManySteps = createAction('[Step] Get Many Steps', props<{ params: any }>());

export const addManyActives = createAction('[Step] Add Many Actives Step', props<{ idSteps: number[] }>());

export const deleteOneActive = createAction('[Step] Delete One Active Step', props<{ idStep: number }>());

export const clearActive = createAction('[Step] Clear Active Step');

export const upsertOneStep = createAction(
  '[Step] Upsert One Step',
  props<{
    step: Partial<Step>;
    ids?: StepRelationsIds;
  }>()
);

export const upsertManySteps = createAction(
  '[Step] Upsert Many Steps',
  props<{
    steps: Partial<Step>[];
    ids?: StepRelationsIds;
  }>()
);

export const upsertManyStepsSuccess = createAction(
  '[Step] Create Many Steps Success',
  props<{ steps: StepEntityState[] }>()
);

export const deleteOneStep = createAction('[Step] Delete One Step', props<{ idStep: number }>());

export const deleteOneStepSuccess = createAction('[Step] Delete One Step Success', props<{ idStep: number }>());

export const normalizeManyStepsAfterUpsert = createAction(
  '[Step] Normalize Many Steps After Upsert',
  props<{ steps: StepEntityState[] }>()
);

export const stepsFailure = createAction('[Step] Steps Failure', props<{ error: HttpErrorResponse }>());

export const clearStore = createAction('[Step] Clear Steps');

export const addManyStepProgressSuccess = createAction(
  '[Step] Add Many step-progress',
  props<{ idStep: number; idStepProgresses: number[] }>()
);

export const deleteManyStepProgressSuccess = createAction(
  '[Step] Delete Many StepProgresses',
  props<{ idStepProgresses: number[]; idSteps: number[] }>()
);

export const addManyOrganizationLeadTodoSuccess = createAction(
  '[Step] Add Many organization-lead-todo',
  props<{ idStep: number; idOrganizationLeadTodos: number[] }>()
);

export const deleteManyOrganizationLeadTodoSuccess = createAction(
  '[Step] Delete Many OrganizationLeadTodos',
  props<{ idOrganizationLeadTodos: number[]; idSteps: number[] }>()
);

export const addManyOrganizationStratalotAvancementSuccess = createAction(
  '[Step] Add Many organization-stratalot-avancement',
  props<{ idStep: number; idOrganizationStratalotAvancements: number[] }>()
);

export const deleteManyOrganizationStratalotAvancementSuccess = createAction(
  '[Step] Delete Many OrganizationStratalotAvancements',
  props<{ idOrganizationStratalotAvancements: number[]; idSteps: number[] }>()
);

export const addManyOrganizationLeadAvancementSuccess = createAction(
  '[Step] Add Many organization-lead-avancement',
  props<{ idStep: number; idOrganizationLeadAvancements: number[] }>()
);

export const deleteManyOrganizationLeadAvancementSuccess = createAction(
  '[Step] Delete Many OrganizationLeadAvancements',
  props<{ idOrganizationLeadAvancements: number[]; idSteps: number[] }>()
);
