import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { ResidenceStudyWork, ResidenceStudyWorkEntityState } from '@_model/interfaces/residence-study-work.model';
import { ResidenceStudyWorkApiService } from '@_services/api/residence-study-work-api.service';
import * as residenceStudyWorkActions from '@_store/residence-study-work/residence-study-work.actions';
import { getActionsToNormalizeResidenceStudyWork } from '@_config/store/normalization.generated';
import { selectResidenceStudyWorkState } from './residence-study-work-generated.selectors';
import * as residenceStudyActions from '@_store/residence-study/residence-study.actions';

export interface ResidenceStudyWorkRelationsIds {
  residenceStudy?: number;
}

export function getDefaultAddResidenceStudyWorkActions(
  residenceStudyWork: ResidenceStudyWorkEntityState,
  ids?: ResidenceStudyWorkRelationsIds
): Action[] {
  const actions: Action[] = [
    residenceStudyWorkActions.normalizeManyResidenceStudyWorksAfterUpsert({ residenceStudyWorks: [residenceStudyWork] })
  ];

  if (ids?.residenceStudy) {
    actions.push(
      residenceStudyActions.addManyResidenceStudyWorkSuccess({
        idResidenceStudy: ids.residenceStudy,
        idResidenceStudyWorks: [residenceStudyWork.idResidenceStudyWork]
      })
    );
    actions.push(
      residenceStudyWorkActions.addResidenceStudySuccess({
        idResidenceStudyWork: residenceStudyWork.idResidenceStudyWork,
        idResidenceStudy: ids.residenceStudy
      })
    );
  }

  return actions;
}

export function getDefaultDeleteResidenceStudyWorkActions(residenceStudyWork: ResidenceStudyWorkEntityState): Action[] {
  const actions: Action[] = [
    residenceStudyWorkActions.deleteOneResidenceStudyWorkSuccess({
      idResidenceStudyWork: residenceStudyWork.idResidenceStudyWork
    })
  ];

  if (residenceStudyWork.residenceStudy) {
    actions.push(
      residenceStudyActions.deleteManyResidenceStudyWorkSuccess({
        idResidenceStudyWorks: [residenceStudyWork.idResidenceStudyWork],
        idResidenceStudies: [residenceStudyWork.residenceStudy as number]
      })
    );
  }

  return actions;
}

export class GeneratedResidenceStudyWorkEffects {
  constructor(
    protected actions$: Actions,
    protected residenceStudyWorkApiService: ResidenceStudyWorkApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidenceStudyWorks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyWorkActions.getManyResidenceStudyWorks),
      switchMap(({ params }) =>
        this.residenceStudyWorkApiService.getResidenceStudyWorks(params).pipe(
          map((residenceStudyWorks: ResidenceStudyWork[]) => {
            return residenceStudyWorkActions.normalizeManyResidenceStudyWorksAfterUpsert({ residenceStudyWorks });
          }),
          catchError(error => of(residenceStudyWorkActions.residenceStudyWorksFailure({ error })))
        )
      )
    );
  });

  getOneResidenceStudyWork$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyWorkActions.getOneResidenceStudyWork),
      switchMap(idResidenceStudyWork =>
        this.residenceStudyWorkApiService.getResidenceStudyWork(idResidenceStudyWork).pipe(
          map((residenceStudyWork: ResidenceStudyWork) => {
            return residenceStudyWorkActions.normalizeManyResidenceStudyWorksAfterUpsert({
              residenceStudyWorks: [residenceStudyWork]
            });
          }),
          catchError(error => of(residenceStudyWorkActions.residenceStudyWorksFailure({ error })))
        )
      )
    );
  });

  upsertOneResidenceStudyWork$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyWorkActions.upsertOneResidenceStudyWork),
      concatMap(
        ({
          residenceStudyWork,
          ids
        }: {
          residenceStudyWork: Partial<ResidenceStudyWork>;
          ids?: ResidenceStudyWorkRelationsIds;
        }) => {
          if (residenceStudyWork.idResidenceStudyWork) {
            return this.residenceStudyWorkApiService.updateResidenceStudyWork(residenceStudyWork).pipe(
              map((residenceStudyWorkReturned: ResidenceStudyWork) => {
                return residenceStudyWorkActions.normalizeManyResidenceStudyWorksAfterUpsert({
                  residenceStudyWorks: [residenceStudyWorkReturned]
                });
              }),
              catchError(error => of(residenceStudyWorkActions.residenceStudyWorksFailure({ error })))
            );
          } else {
            return this.residenceStudyWorkApiService.addResidenceStudyWork(residenceStudyWork).pipe(
              mergeMap((residenceStudyWorkReturned: ResidenceStudyWork) =>
                getDefaultAddResidenceStudyWorkActions(residenceStudyWorkReturned, ids)
              ),
              catchError(error => of(residenceStudyWorkActions.residenceStudyWorksFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneResidenceStudyWork$ = createEffect(() => {
    const selectResidenceStudyWorkState$ = this.store$.select(selectResidenceStudyWorkState);
    return this.actions$.pipe(
      ofType(residenceStudyWorkActions.deleteOneResidenceStudyWork),
      withLatestFrom(selectResidenceStudyWorkState$),
      concatMap(([{ idResidenceStudyWork }, state]) =>
        this.residenceStudyWorkApiService.deleteResidenceStudyWork(idResidenceStudyWork).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidenceStudyWorkActions(
                state.entities[idResidenceStudyWork] as ResidenceStudyWorkEntityState
              ),
              residenceStudyWorkActions.deleteOneResidenceStudyWork.type
            )
          ]),
          catchError(error => of(residenceStudyWorkActions.residenceStudyWorksFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidenceStudyWorksAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyWorkActions.normalizeManyResidenceStudyWorksAfterUpsert),
      concatMap(({ residenceStudyWorks }) => {
        const actions: Action[] = getActionsToNormalizeResidenceStudyWork(residenceStudyWorks, StoreActionType.upsert);
        return [getMultiAction(actions, '[ResidenceStudyWork] Normalization After Upsert Success')];
      })
    );
  });
}
