import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CompanyMedia, CompanyMediaEntityState } from '@_model/interfaces/company-media.model';
import { CompanyMediaRelationsIds } from './company-media-generated.effects';

export const getOneCompanyMedia = createAction(
  '[CompanyMedia] Get One CompanyMedia',
  props<{ idCompanyMedia: number; params?: any }>()
);

export const getManyCompanyMedias = createAction('[CompanyMedia] Get Many CompanyMedias', props<{ params: any }>());

export const addManyActives = createAction(
  '[CompanyMedia] Add Many Actives CompanyMedia',
  props<{ idCompanyMedias: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyMedia] Delete One Active CompanyMedia',
  props<{ idCompanyMedia: number }>()
);

export const clearActive = createAction('[CompanyMedia] Clear Active CompanyMedia');

export const upsertOneCompanyMedia = createAction(
  '[CompanyMedia] Upsert One CompanyMedia',
  props<{
    companyMedia: Partial<CompanyMedia>;
    ids?: CompanyMediaRelationsIds;
  }>()
);

export const upsertManyCompanyMedias = createAction(
  '[CompanyMedia] Upsert Many CompanyMedias',
  props<{
    companyMedias: Partial<CompanyMedia>[];
    ids?: CompanyMediaRelationsIds;
  }>()
);

export const upsertManyCompanyMediasSuccess = createAction(
  '[CompanyMedia] Create Many CompanyMedias Success',
  props<{ companyMedias: CompanyMediaEntityState[] }>()
);

export const deleteOneCompanyMedia = createAction(
  '[CompanyMedia] Delete One CompanyMedia',
  props<{ idCompanyMedia: number }>()
);

export const deleteOneCompanyMediaSuccess = createAction(
  '[CompanyMedia] Delete One CompanyMedia Success',
  props<{ idCompanyMedia: number }>()
);

export const normalizeManyCompanyMediasAfterUpsert = createAction(
  '[CompanyMedia] Normalize Many CompanyMedias After Upsert',
  props<{ companyMedias: CompanyMediaEntityState[] }>()
);

export const companyMediasFailure = createAction(
  '[CompanyMedia] CompanyMedias Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyMedia] Clear CompanyMedias');

export const addManyCampaignMediaSuccess = createAction(
  '[CompanyMedia] Add Many campaign-media',
  props<{ idCompanyMedia: number; idCampaignMedias: number[] }>()
);

export const deleteManyCampaignMediaSuccess = createAction(
  '[CompanyMedia] Delete Many CampaignMedias',
  props<{ idCampaignMedias: number[]; idCompanyMedias: number[] }>()
);

export const addCompanySuccess = createAction(
  '[CompanyMedia] Add Company',
  props<{ idCompanyMedia: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[CompanyMedia] Delete Many Company',
  props<{ idCompanies: number[]; idCompanyMedias: number[] }>()
);
