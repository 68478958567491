import { OrganizationSaleCategoryState, initialState } from './organization-sale-category.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationSaleCategoryReducersGeneratedFunctions } from './organization-sale-category-generated.reducer';

const organizationSaleCategoryReducersFunctions = [...organizationSaleCategoryReducersGeneratedFunctions];

const organizationSaleCategoryReducer = createReducer(initialState, ...organizationSaleCategoryReducersFunctions);

export function reducer(state: OrganizationSaleCategoryState | undefined, action: Action) {
  return organizationSaleCategoryReducer(state, action);
}
