import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StepEntityState } from '@_model/interfaces/step.model';

export interface StepState extends EntityState<StepEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<StepEntityState> = createEntityAdapter<StepEntityState>({
  selectId: o => o.idStep
});
export const initialState: StepState = adapter.getInitialState({ isLoaded: false, isLoading: false, actives: [] });

export const stepFeatureKey = 'step';
