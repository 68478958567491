import { RepositoryService } from '@_services/api/repository.service';
import { ResidencePriceGrid } from '@_model/interfaces/residence-price-grid.model';
import { Observable } from 'rxjs';

export class GeneratedResidencePriceGridApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidencePriceGrids(params?: any): Observable<ResidencePriceGrid[]> {
    return this.repo.getData<ResidencePriceGrid[]>('residence-price-grid', params);
  }

  public getResidencePriceGrid(params: { idResidencePriceGrid: number; params?: any }): Observable<ResidencePriceGrid> {
    return this.repo.getData<ResidencePriceGrid>('residence-price-grid/' + params.idResidencePriceGrid, params.params);
  }

  public addResidencePriceGrid(residencePriceGrid: Partial<ResidencePriceGrid>): Observable<ResidencePriceGrid> {
    return this.repo.create<ResidencePriceGrid>('residence-price-grid', residencePriceGrid);
  }

  public updateResidencePriceGrid(residencePriceGrid: Partial<ResidencePriceGrid>): Observable<ResidencePriceGrid> {
    return this.repo.update('residence-price-grid', residencePriceGrid);
  }

  public deleteResidencePriceGrid(idResidencePriceGrid: number): Observable<number> {
    return this.repo.delete('residence-price-grid/' + +idResidencePriceGrid);
  }
}
