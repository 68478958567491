import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Lead, LeadEntityState } from '@_model/interfaces/lead.model';
import { LeadRelationsIds } from './lead-generated.effects';

export const getOneLead = createAction('[Lead] Get One Lead', props<{ idLead: number; params?: any }>());

export const getManyLeads = createAction('[Lead] Get Many Leads', props<{ params: any }>());

export const addManyActives = createAction('[Lead] Add Many Actives Lead', props<{ idLeads: number[] }>());

export const deleteOneActive = createAction('[Lead] Delete One Active Lead', props<{ idLead: number }>());

export const clearActive = createAction('[Lead] Clear Active Lead');

export const upsertOneLead = createAction(
  '[Lead] Upsert One Lead',
  props<{
    lead: Partial<Lead>;
    ids?: LeadRelationsIds;
  }>()
);

export const upsertManyLeads = createAction(
  '[Lead] Upsert Many Leads',
  props<{
    leads: Partial<Lead>[];
    ids?: LeadRelationsIds;
  }>()
);

export const upsertManyLeadsSuccess = createAction(
  '[Lead] Create Many Leads Success',
  props<{ leads: LeadEntityState[] }>()
);

export const deleteOneLead = createAction('[Lead] Delete One Lead', props<{ idLead: number }>());

export const deleteOneLeadSuccess = createAction('[Lead] Delete One Lead Success', props<{ idLead: number }>());

export const normalizeManyLeadsAfterUpsert = createAction(
  '[Lead] Normalize Many Leads After Upsert',
  props<{ leads: LeadEntityState[] }>()
);

export const leadsFailure = createAction('[Lead] Leads Failure', props<{ error: HttpErrorResponse }>());

export const clearStore = createAction('[Lead] Clear Leads');

export const addManyProspectEventSuccess = createAction(
  '[Lead] Add Many prospect-event',
  props<{ idLead: number; idProspectEvents: number[] }>()
);

export const deleteManyProspectEventSuccess = createAction(
  '[Lead] Delete Many ProspectEvents',
  props<{ idProspectEvents: number[]; idLeads: number[] }>()
);

export const addManyStratalotSuccess = createAction(
  '[Lead] Add Many stratalot',
  props<{ idLead: number; idStratalots: number[] }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[Lead] Delete Many Stratalots',
  props<{ idStratalots: number[]; idLeads: number[] }>()
);

export const addManyLeadStratalotSuccess = createAction(
  '[Lead] Add Many lead-stratalot',
  props<{ idLead: number; idLeadStratalots: number[] }>()
);

export const deleteManyLeadStratalotSuccess = createAction(
  '[Lead] Delete Many LeadStratalots',
  props<{ idLeadStratalots: number[]; idLeads: number[] }>()
);

export const addManyLeadTodoSuccess = createAction(
  '[Lead] Add Many lead-todo',
  props<{ idLead: number; idLeadTodos: number[] }>()
);

export const deleteManyLeadTodoSuccess = createAction(
  '[Lead] Delete Many LeadTodos',
  props<{ idLeadTodos: number[]; idLeads: number[] }>()
);

export const addProspectSuccess = createAction('[Lead] Add Prospect', props<{ idLead: number; idProspect: number }>());

export const deleteManyProspectSuccess = createAction(
  '[Lead] Delete Many Prospect',
  props<{ idProspects: number[]; idLeads: number[] }>()
);

export const addOrganizationLeadAvancementSuccess = createAction(
  '[Lead] Add OrganizationLeadAvancement',
  props<{ idLead: number; idOrganizationLeadAvancement: number }>()
);

export const deleteManyOrganizationLeadAvancementSuccess = createAction(
  '[Lead] Delete Many OrganizationLeadAvancement',
  props<{ idOrganizationLeadAvancements: number[]; idLeads: number[] }>()
);
