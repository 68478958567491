import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { User, UserEntityState } from '@_model/interfaces/user.model';
import { UserRelationsIds } from './user-generated.effects';

export const getOneUser = createAction('[User] Get One User', props<{ idUser: number; params?: any }>());

export const getManyUsers = createAction('[User] Get Many Users', props<{ params: any }>());

export const addManyActives = createAction('[User] Add Many Actives User', props<{ idUsers: number[] }>());

export const deleteOneActive = createAction('[User] Delete One Active User', props<{ idUser: number }>());

export const clearActive = createAction('[User] Clear Active User');

export const upsertOneUser = createAction(
  '[User] Upsert One User',
  props<{
    user: Partial<User>;
    ids?: UserRelationsIds;
  }>()
);

export const upsertManyUsers = createAction(
  '[User] Upsert Many Users',
  props<{
    users: Partial<User>[];
    ids?: UserRelationsIds;
  }>()
);

export const upsertManyUsersSuccess = createAction(
  '[User] Create Many Users Success',
  props<{ users: UserEntityState[] }>()
);

export const deleteOneUser = createAction('[User] Delete One User', props<{ idUser: number }>());

export const deleteOneUserSuccess = createAction('[User] Delete One User Success', props<{ idUser: number }>());

export const normalizeManyUsersAfterUpsert = createAction(
  '[User] Normalize Many Users After Upsert',
  props<{ users: UserEntityState[] }>()
);

export const usersFailure = createAction('[User] Users Failure', props<{ error: HttpErrorResponse }>());

export const clearStore = createAction('[User] Clear Users');

export const addManyBoardStateSuccess = createAction(
  '[User] Add Many board-state',
  props<{ idUser: number; idBoardStates: number[] }>()
);

export const deleteManyBoardStateSuccess = createAction(
  '[User] Delete Many BoardStates',
  props<{ idBoardStates: number[]; idUsers: number[] }>()
);

export const addManyCompanyTerritoireUserSuccess = createAction(
  '[User] Add Many company-territoire-user',
  props<{ idUser: number; idCompanyTerritoireUsers: number[] }>()
);

export const deleteManyCompanyTerritoireUserSuccess = createAction(
  '[User] Delete Many CompanyTerritoireUsers',
  props<{ idCompanyTerritoireUsers: number[]; idUsers: number[] }>()
);

export const addManyResidencePriceGridHistorySuccess = createAction(
  '[User] Add Many residence-price-grid-history',
  props<{ idUser: number; idResidencePriceGridHistories: number[] }>()
);

export const deleteManyResidencePriceGridHistorySuccess = createAction(
  '[User] Delete Many ResidencePriceGridHistories',
  props<{ idResidencePriceGridHistories: number[]; idUsers: number[] }>()
);

export const addManyCompanyTerritoireSuccess = createAction(
  '[User] Add Many company-territoire',
  props<{ idUser: number; idCompanyTerritoire: number[] }>()
);

export const deleteManyCompanyTerritoireSuccess = createAction(
  '[User] Delete Many CompanyTerritoires',
  props<{ idCompanyTerritoire: number[]; idUsers: number[] }>()
);

export const addManyProspectResponsibleOfSuccess = createAction(
  '[User] Add Many prospectResponsibleOf',
  props<{ idUser: number; idProspectResponsibleOf: number[] }>()
);

export const deleteManyProspectResponsibleOfSuccess = createAction(
  '[User] Delete Many ProspectResponsibleOfs',
  props<{ idProspectResponsibleOf: number[]; idUsers: number[] }>()
);

export const addManyProspectUpdaterSuccess = createAction(
  '[User] Add Many prospectUpdater',
  props<{ idUser: number; idProspectUpdater: number[] }>()
);

export const deleteManyProspectUpdaterSuccess = createAction(
  '[User] Delete Many ProspectUpdaters',
  props<{ idProspectUpdater: number[]; idUsers: number[] }>()
);

export const addManyProspectCreatorSuccess = createAction(
  '[User] Add Many prospectCreator',
  props<{ idUser: number; idProspectCreator: number[] }>()
);

export const deleteManyProspectCreatorSuccess = createAction(
  '[User] Delete Many ProspectCreators',
  props<{ idProspectCreator: number[]; idUsers: number[] }>()
);

export const addManyResidenceSuccess = createAction(
  '[User] Add Many residence',
  props<{ idUser: number; idResidence: number[] }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[User] Delete Many Residences',
  props<{ idResidence: number[]; idUsers: number[] }>()
);

export const addManyStratalotSuccess = createAction(
  '[User] Add Many stratalot',
  props<{ idUser: number; idStratalot: number[] }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[User] Delete Many Stratalots',
  props<{ idStratalot: number[]; idUsers: number[] }>()
);

export const addManyProspectEventSuccess = createAction(
  '[User] Add Many prospect-event',
  props<{ idUser: number; idProspectEvents: number[] }>()
);

export const deleteManyProspectEventSuccess = createAction(
  '[User] Delete Many ProspectEvents',
  props<{ idProspectEvents: number[]; idUsers: number[] }>()
);

export const addManyProfilSuccess = createAction(
  '[User] Add Many profil',
  props<{ idUser: number; idProfils: number[] }>()
);

export const deleteManyProfilSuccess = createAction(
  '[User] Delete Many Profils',
  props<{ idProfils: number[]; idUsers: number[] }>()
);

export const addManyCompanyCommunicationRecipientSuccess = createAction(
  '[User] Add Many company-communication-recipient',
  props<{ idUser: number; idCompanyCommunicationRecipients: number[] }>()
);

export const deleteManyCompanyCommunicationRecipientSuccess = createAction(
  '[User] Delete Many CompanyCommunicationRecipients',
  props<{ idCompanyCommunicationRecipients: number[]; idUsers: number[] }>()
);

export const addManyCompanyCommunicationRecipientUserSuccess = createAction(
  '[User] Add Many company-communication-recipient-user',
  props<{ idUser: number; idCompanyCommunicationRecipientUsers: number[] }>()
);

export const deleteManyCompanyCommunicationRecipientUserSuccess = createAction(
  '[User] Delete Many CompanyCommunicationRecipientUsers',
  props<{ idCompanyCommunicationRecipientUsers: number[]; idUsers: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[User] Add Organization',
  props<{ idUser: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[User] Delete Many Organization',
  props<{ idOrganizations: number[]; idUsers: number[] }>()
);
