import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyStudyConclusionActions from './company-study-conclusion.actions';
import { adapter, initialState, CompanyStudyConclusionState } from './company-study-conclusion.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyStudyConclusionEntityState } from '@_model/interfaces/company-study-conclusion.model';

export const companyStudyConclusionReducersGeneratedFunctions: ReducerTypes<
  CompanyStudyConclusionState,
  readonly ActionCreator[]
>[] = [
  on(CompanyStudyConclusionActions.getOneCompanyStudyConclusion, (state: CompanyStudyConclusionState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyStudyConclusionActions.getManyCompanyStudyConclusions, (state: CompanyStudyConclusionState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyStudyConclusionActions.upsertOneCompanyStudyConclusion, (state: CompanyStudyConclusionState) =>
    setLoadingsState(state, true)
  ),

  on(
    CompanyStudyConclusionActions.upsertManyCompanyStudyConclusionsSuccess,
    (state: CompanyStudyConclusionState, { companyStudyConclusions }) =>
      adapter.upsertMany(companyStudyConclusions, setLoadingsState(state, false))
  ),
  on(CompanyStudyConclusionActions.deleteOneCompanyStudyConclusion, (state: CompanyStudyConclusionState) =>
    setLoadingsState(state, true)
  ),
  on(
    CompanyStudyConclusionActions.deleteOneCompanyStudyConclusionSuccess,
    (state: CompanyStudyConclusionState, { idCompanyStudyConclusion }) =>
      adapter.removeOne(idCompanyStudyConclusion, setLoadingsState(state, false))
  ),
  on(CompanyStudyConclusionActions.clearActive, (state: CompanyStudyConclusionState) => ({ ...state, actives: [] })),
  on(
    CompanyStudyConclusionActions.addManyActives,
    (state: CompanyStudyConclusionState, { idCompanyStudyConclusions }) => ({
      ...state,
      actives: state.actives.concat(idCompanyStudyConclusions)
    })
  ),
  on(
    CompanyStudyConclusionActions.deleteOneActive,
    (state: CompanyStudyConclusionState, { idCompanyStudyConclusion }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCompanyStudyConclusion)
    })
  ),

  on(CompanyStudyConclusionActions.clearStore, () => initialState),

  on(
    CompanyStudyConclusionActions.addManyResidenceStudySuccess,
    (state: CompanyStudyConclusionState, { idCompanyStudyConclusion, idResidenceStudies }) => {
      if (!state.entities[idCompanyStudyConclusion]) {
        return state;
      }
      const residenceStudies = (state.entities[idCompanyStudyConclusion]?.residenceStudies as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStudyConclusion]: {
            ...state.entities[idCompanyStudyConclusion],
            residenceStudies: residenceStudies.concat(idResidenceStudies.filter(id => residenceStudies.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CompanyStudyConclusionActions.deleteManyResidenceStudySuccess,
    (state: CompanyStudyConclusionState, { idResidenceStudies, idCompanyStudyConclusions }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStudyConclusions.reduce((entities, idCompanyStudyConclusion) => {
            if (!state.entities[idCompanyStudyConclusion]?.residenceStudies) {
              return entities;
            }
            entities[idCompanyStudyConclusion] = {
              ...state.entities[idCompanyStudyConclusion],
              residenceStudies: (state.entities[idCompanyStudyConclusion]?.residenceStudies as number[])?.filter(
                (idResidenceStudy: number) => !idResidenceStudies.some((id: number) => id === idResidenceStudy)
              )
            } as CompanyStudyConclusionEntityState;
            return entities;
          }, {} as Dictionary<CompanyStudyConclusionEntityState>)
        }
      };
    }
  ),

  on(
    CompanyStudyConclusionActions.addCompanySuccess,
    (state: CompanyStudyConclusionState, { idCompanyStudyConclusion, idCompany }) => {
      if (!state.entities[idCompanyStudyConclusion]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStudyConclusion]: {
            ...state.entities[idCompanyStudyConclusion],
            company: idCompany
          }
        }
      };
    }
  ),

  on(
    CompanyStudyConclusionActions.deleteManyCompanySuccess,
    (state: CompanyStudyConclusionState, { idCompanies, idCompanyStudyConclusions }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStudyConclusions.reduce((entities, idCompanyStudyConclusion) => {
            if (!state.entities[idCompanyStudyConclusion]?.company) {
              return entities;
            }
            entities[idCompanyStudyConclusion] = {
              ...state.entities[idCompanyStudyConclusion],
              company: idCompanies.some(
                (idCompany: number) => idCompany === state.entities[idCompanyStudyConclusion]?.company
              )
                ? undefined
                : state.entities[idCompanyStudyConclusion]?.company
            } as CompanyStudyConclusionEntityState;
            return entities;
          }, {} as Dictionary<CompanyStudyConclusionEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyStudyConclusionState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyStudyConclusionState {
  return { ...state, isLoaded, isLoading };
}
