import { createAction, props } from '@ngrx/store';
import { OccupedStratalotFilterState } from '@_shared/models/interfaces/filter/occuped-stratalot-filter';
import { ProspectFilterState } from '@_shared/models/interfaces/filter/prospect-filter';
import { FreeStratalotFilterState } from '@_shared/models/interfaces/filter/free-stratalot-filter';

export const setFilterOccupedStratalot = createAction(
  '[Stratalot] Set Filter OccupedStratalot',
  props<{ occupedStratalotFilter: OccupedStratalotFilterState }>()
);
export const setFilterFreeStratalot = createAction(
  '[Stratalot] Set Filter OccupedStratalot',
  props<{ freeStratalotFilter: FreeStratalotFilterState }>()
);
export const setFilterProspect = createAction(
  '[Stratalot] Set Filter OccupedStratalot',
  props<{ prospectFilter: ProspectFilterState }>()
);
