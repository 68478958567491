import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@_environments/environment';
import { RepositoryService } from '@_services/api/repository.service';
import { ErrorResponse } from '@_shared/models/interfaces/error-response';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeneratedDiffusionVisualApiService } from './diffusion-visual-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class DiffusionVisualApiService extends GeneratedDiffusionVisualApiService {
  constructor(repo: RepositoryService, private http: HttpClient) {
    super(repo);
  }

  public getDiffusionVisualFile(params?: { idDiffusionVisual: number; params?: any }): Observable<any> {
    return this.http
      .get(environment.apiUrl + '/diffusion-visual/file', {
        params: this.setParams(params),
        responseType: 'blob'
      })
      .pipe(
        map(
          (res: Blob) => res,
          (err: ErrorResponse) => err
        )
      );
  }

  private setParams(object: any): HttpParams {
    let params = new HttpParams();

    const formatedObject = Object.keys(object).every(key => key === 'params') ? object.params : object;

    for (const key in formatedObject) {
      if (formatedObject[key] !== null && formatedObject[key] !== undefined) {
        if (Array.isArray(formatedObject[key])) {
          formatedObject[key].forEach(element => {
            params = params.append(`${key}[]`, element);
          });
        } else {
          params = params.append(key, formatedObject[key]);
        }
      }
    }
    return params;
  }
}
