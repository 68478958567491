import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResidenceStudyLot, ResidenceStudyLotEntityState } from '@_model/interfaces/residence-study-lot.model';
import { ResidenceStudy, ResidenceStudyEntityState } from '@_model/interfaces/residence-study.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, residenceStudyLotFeatureKey, ResidenceStudyLotState } from './residence-study-lot.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residenceStudyLotRelations: string[] = ['residenceStudies'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidenceStudyLotState = createFeatureSelector<ResidenceStudyLotState>(residenceStudyLotFeatureKey);

export const selectIsLoadedResidenceStudyLot = createSelector(
  selectResidenceStudyLotState,
  (state: ResidenceStudyLotState) => state.isLoaded
);

export const selectIsLoadingResidenceStudyLot = createSelector(
  selectResidenceStudyLotState,
  (state: ResidenceStudyLotState) => state.isLoading
);

export const selectIsReadyResidenceStudyLot = createSelector(
  selectResidenceStudyLotState,
  (state: ResidenceStudyLotState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidenceStudyLot = createSelector(
  selectResidenceStudyLotState,
  (state: ResidenceStudyLotState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidenceStudyLotModel: SelectorModel = {
  name: 'residenceStudyLots',
  getSelector: selectAllResidenceStudyLotsDictionary,
  isReady: selectIsReadyResidenceStudyLot
};

export const selectResidenceStudyLotsEntities = createSelector(selectResidenceStudyLotState, selectEntities);

export const selectResidenceStudyLotsArray = createSelector(selectResidenceStudyLotState, selectAll);

export const selectIdResidenceStudyLotsActive = createSelector(
  selectResidenceStudyLotState,
  (state: ResidenceStudyLotState) => state.actives
);

const residenceStudyLotsInObject = (residenceStudyLots: Dictionary<ResidenceStudyLotEntityState>) => ({
  residenceStudyLots
});

const selectResidenceStudyLotsEntitiesDictionary = createSelector(
  selectResidenceStudyLotsEntities,
  residenceStudyLotsInObject
);

const selectAllResidenceStudyLotsObject = createSelector(selectResidenceStudyLotsEntities, residenceStudyLots => {
  return hydrateAll({ residenceStudyLots });
});

const selectOneResidenceStudyLotDictionary = (idResidenceStudyLot: number) =>
  createSelector(selectResidenceStudyLotsEntities, residenceStudyLots => {
    return { residenceStudyLots: { [idResidenceStudyLot]: residenceStudyLots[idResidenceStudyLot] } };
  });

const selectOneResidenceStudyLotDictionaryWithoutChild = (idResidenceStudyLot: number) =>
  createSelector(selectResidenceStudyLotsEntities, residenceStudyLots => {
    return { residenceStudyLot: residenceStudyLots[idResidenceStudyLot] };
  });

const selectActiveResidenceStudyLotsEntities = createSelector(
  selectIdResidenceStudyLotsActive,
  selectResidenceStudyLotsEntities,
  (actives: number[], residenceStudyLots: Dictionary<ResidenceStudyLotEntityState>) =>
    getResidenceStudyLotsFromActives(actives, residenceStudyLots)
);

function getResidenceStudyLotsFromActives(
  actives: number[],
  residenceStudyLots: Dictionary<ResidenceStudyLotEntityState>
): Dictionary<ResidenceStudyLotEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residenceStudyLots[idActive]) {
      acc[idActive] = residenceStudyLots[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidenceStudyLotEntityState>);
}

const selectAllResidenceStudyLotsSelectors: Dictionary<Selector> = {};
export function selectAllResidenceStudyLots(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidenceStudyLot>(
      schema,
      selectAllResidenceStudyLotsSelectors,
      selectResidenceStudyLotsEntitiesDictionary,
      getRelationSelectors,
      residenceStudyLotRelations,
      hydrateAll,
      'residenceStudyLot'
    );
  } else {
    return selectAllResidenceStudyLotsObject;
  }
}

export function selectAllResidenceStudyLotsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residenceStudyLots'
): Selector {
  return createSelector(selectAllResidenceStudyLots(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidenceStudyLotEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residenceStudyLots.length; i++) {
      res[customKey][result.residenceStudyLots[i].idResidenceStudyLot] = result.residenceStudyLots[i];
    }
    return res;
  });
}

export function selectOneResidenceStudyLot(schema: SelectSchema = {}, idResidenceStudyLot: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidenceStudyLotDictionary(idResidenceStudyLot)];
    selectors.push(...getRelationSelectors(schema, residenceStudyLotRelations, 'residenceStudyLot'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidenceStudyLotDictionaryWithoutChild(idResidenceStudyLot);
  }
}

export function selectActiveResidenceStudyLots(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidenceStudyLotsEntities, residenceStudyLots => ({ residenceStudyLots }))
  ];
  selectors.push(...getRelationSelectors(schema, residenceStudyLotRelations, 'residenceStudyLot'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residenceStudyLots: Dictionary<ResidenceStudyLotEntityState>;
  residenceStudies?: Dictionary<ResidenceStudyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { residenceStudyLots: (ResidenceStudyLot | null)[] } {
  const { residenceStudyLots, residenceStudies } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    residenceStudyLots: Object.keys(residenceStudyLots).map(idResidenceStudyLot =>
      hydrate(residenceStudyLots[idResidenceStudyLot] as ResidenceStudyLotEntityState, residenceStudies)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { residenceStudyLot: ResidenceStudyLotEntityState | null } {
  const { residenceStudyLots, residenceStudies } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const residenceStudyLot = Object.values(residenceStudyLots)[0];
  return { residenceStudyLot: hydrate(residenceStudyLot as ResidenceStudyLotEntityState, residenceStudies) };
}

function hydrate(
  residenceStudyLot: ResidenceStudyLotEntityState,
  residenceStudyEntities?: Dictionary<ResidenceStudyEntityState>
): ResidenceStudyLot | null {
  if (!residenceStudyLot) {
    return null;
  }

  const residenceStudyLotHydrated: ResidenceStudyLotEntityState = { ...residenceStudyLot };
  if (residenceStudyEntities) {
    residenceStudyLotHydrated.residenceStudy = residenceStudyEntities[
      residenceStudyLot.residenceStudy as number
    ] as ResidenceStudy;
  } else {
    delete residenceStudyLotHydrated.residenceStudy;
  }

  return residenceStudyLotHydrated as ResidenceStudyLot;
}
