import { RepositoryService } from '@_services/api/repository.service';
import { CompanyCommunicationFile } from '@_model/interfaces/company-communication-file.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyCommunicationFileApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyCommunicationFiles(params?: any): Observable<CompanyCommunicationFile[]> {
    return this.repo.getData<CompanyCommunicationFile[]>('company-communication-file', params);
  }

  public getCompanyCommunicationFile(params: {
    idCompanyCommunicationFile: number;
    params?: any;
  }): Observable<CompanyCommunicationFile> {
    return this.repo.getData<CompanyCommunicationFile>(
      'company-communication-file/' + params.idCompanyCommunicationFile,
      params.params
    );
  }

  public addCompanyCommunicationFile(
    companyCommunicationFile: Partial<CompanyCommunicationFile>
  ): Observable<CompanyCommunicationFile> {
    return this.repo.create<CompanyCommunicationFile>('company-communication-file', companyCommunicationFile);
  }

  public updateCompanyCommunicationFile(
    companyCommunicationFile: Partial<CompanyCommunicationFile>
  ): Observable<CompanyCommunicationFile> {
    return this.repo.update('company-communication-file', companyCommunicationFile);
  }

  public deleteCompanyCommunicationFile(idCompanyCommunicationFile: number): Observable<number> {
    return this.repo.delete('company-communication-file/' + +idCompanyCommunicationFile);
  }
}
