import { Component, Inject } from '@angular/core';
import { ConfirmationTheme, ConfirmationThemeEnum, THEMES } from '../confirmation/theme';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent {
  public title: string;
  public question: string | string[];
  public isQuestionArray: boolean;
  public theme: ConfirmationTheme;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    { title, question, theme }: { title: string; question: string | string[]; theme: ConfirmationThemeEnum }
  ) {
    this.title = title;
    this.isQuestionArray = Array.isArray(question);
    this.question = question;
    this.theme = THEMES[theme] ? THEMES[theme] : THEMES.default;
  }
}
