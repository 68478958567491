import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as GeneratedDocumentsResidenceActions from './generated-documents-residence.actions';
import { adapter, initialState, GeneratedDocumentsResidenceState } from './generated-documents-residence.state';
import { Dictionary } from '@ngrx/entity';
import { GeneratedDocumentsResidenceEntityState } from '@_model/interfaces/generated-documents-residence.model';

export const generatedDocumentsResidenceReducersGeneratedFunctions: ReducerTypes<
  GeneratedDocumentsResidenceState,
  readonly ActionCreator[]
>[] = [
  on(GeneratedDocumentsResidenceActions.getOneGeneratedDocumentsResidence, (state: GeneratedDocumentsResidenceState) =>
    setLoadingsState(state, true)
  ),
  on(
    GeneratedDocumentsResidenceActions.getManyGeneratedDocumentsResidences,
    (state: GeneratedDocumentsResidenceState) => setLoadingsState(state, true)
  ),
  on(
    GeneratedDocumentsResidenceActions.upsertOneGeneratedDocumentsResidence,
    (state: GeneratedDocumentsResidenceState) => setLoadingsState(state, true)
  ),

  on(
    GeneratedDocumentsResidenceActions.upsertManyGeneratedDocumentsResidencesSuccess,
    (state: GeneratedDocumentsResidenceState, { generatedDocumentsResidences }) =>
      adapter.upsertMany(generatedDocumentsResidences, setLoadingsState(state, false))
  ),
  on(
    GeneratedDocumentsResidenceActions.deleteOneGeneratedDocumentsResidence,
    (state: GeneratedDocumentsResidenceState) => setLoadingsState(state, true)
  ),
  on(
    GeneratedDocumentsResidenceActions.deleteOneGeneratedDocumentsResidenceSuccess,
    (state: GeneratedDocumentsResidenceState, { idGeneratedDocumentsResidence }) =>
      adapter.removeOne(idGeneratedDocumentsResidence, setLoadingsState(state, false))
  ),
  on(GeneratedDocumentsResidenceActions.clearActive, (state: GeneratedDocumentsResidenceState) => ({
    ...state,
    actives: []
  })),
  on(
    GeneratedDocumentsResidenceActions.addManyActives,
    (state: GeneratedDocumentsResidenceState, { idGeneratedDocumentsResidences }) => ({
      ...state,
      actives: state.actives.concat(idGeneratedDocumentsResidences)
    })
  ),
  on(
    GeneratedDocumentsResidenceActions.deleteOneActive,
    (state: GeneratedDocumentsResidenceState, { idGeneratedDocumentsResidence }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idGeneratedDocumentsResidence)
    })
  ),

  on(GeneratedDocumentsResidenceActions.clearStore, () => initialState),

  on(
    GeneratedDocumentsResidenceActions.addResidenceSuccess,
    (state: GeneratedDocumentsResidenceState, { idGeneratedDocumentsResidence, idResidence }) => {
      if (!state.entities[idGeneratedDocumentsResidence]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idGeneratedDocumentsResidence]: {
            ...state.entities[idGeneratedDocumentsResidence],
            residence: idResidence
          }
        }
      };
    }
  ),

  on(
    GeneratedDocumentsResidenceActions.deleteManyResidenceSuccess,
    (state: GeneratedDocumentsResidenceState, { idResidences, idGeneratedDocumentsResidences }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idGeneratedDocumentsResidences.reduce((entities, idGeneratedDocumentsResidence) => {
            if (!state.entities[idGeneratedDocumentsResidence]?.residence) {
              return entities;
            }
            entities[idGeneratedDocumentsResidence] = {
              ...state.entities[idGeneratedDocumentsResidence],
              residence: idResidences.some(
                (idResidence: number) => idResidence === state.entities[idGeneratedDocumentsResidence]?.residence
              )
                ? undefined
                : state.entities[idGeneratedDocumentsResidence]?.residence
            } as GeneratedDocumentsResidenceEntityState;
            return entities;
          }, {} as Dictionary<GeneratedDocumentsResidenceEntityState>)
        }
      };
    }
  ),

  on(
    GeneratedDocumentsResidenceActions.addGeneratedDocumentSuccess,
    (state: GeneratedDocumentsResidenceState, { idGeneratedDocumentsResidence, idGeneratedDocument }) => {
      if (!state.entities[idGeneratedDocumentsResidence]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idGeneratedDocumentsResidence]: {
            ...state.entities[idGeneratedDocumentsResidence],
            generatedDocument: idGeneratedDocument
          }
        }
      };
    }
  ),

  on(
    GeneratedDocumentsResidenceActions.deleteManyGeneratedDocumentSuccess,
    (state: GeneratedDocumentsResidenceState, { idGeneratedDocuments, idGeneratedDocumentsResidences }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idGeneratedDocumentsResidences.reduce((entities, idGeneratedDocumentsResidence) => {
            if (!state.entities[idGeneratedDocumentsResidence]?.generatedDocument) {
              return entities;
            }
            entities[idGeneratedDocumentsResidence] = {
              ...state.entities[idGeneratedDocumentsResidence],
              generatedDocument: idGeneratedDocuments.some(
                (idGeneratedDocument: number) =>
                  idGeneratedDocument === state.entities[idGeneratedDocumentsResidence]?.generatedDocument
              )
                ? undefined
                : state.entities[idGeneratedDocumentsResidence]?.generatedDocument
            } as GeneratedDocumentsResidenceEntityState;
            return entities;
          }, {} as Dictionary<GeneratedDocumentsResidenceEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: GeneratedDocumentsResidenceState,
  isLoading: boolean,
  isLoaded: boolean = true
): GeneratedDocumentsResidenceState {
  return { ...state, isLoaded, isLoading };
}
