import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BoardState, BoardStateEntityState } from '@_model/interfaces/board-state.model';
import { User, UserEntityState } from '@_model/interfaces/user.model';
import { SaleCategoryFamily, SaleCategoryFamilyEntityState } from '@_model/interfaces/sale-category-family.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, boardStateFeatureKey, BoardStateState } from './board-state.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const boardStateRelations: string[] = ['users', 'familySaleCategories'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectBoardStateState = createFeatureSelector<BoardStateState>(boardStateFeatureKey);

export const selectIsLoadedBoardState = createSelector(
  selectBoardStateState,
  (state: BoardStateState) => state.isLoaded
);

export const selectIsLoadingBoardState = createSelector(
  selectBoardStateState,
  (state: BoardStateState) => state.isLoading
);

export const selectIsReadyBoardState = createSelector(
  selectBoardStateState,
  (state: BoardStateState) => !state.isLoading
);

export const selectIsReadyAndLoadedBoardState = createSelector(
  selectBoardStateState,
  (state: BoardStateState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const BoardStateModel: SelectorModel = {
  name: 'boardStates',
  getSelector: selectAllBoardStatesDictionary,
  isReady: selectIsReadyBoardState
};

export const selectBoardStatesEntities = createSelector(selectBoardStateState, selectEntities);

export const selectBoardStatesArray = createSelector(selectBoardStateState, selectAll);

export const selectIdBoardStatesActive = createSelector(
  selectBoardStateState,
  (state: BoardStateState) => state.actives
);

const boardStatesInObject = (boardStates: Dictionary<BoardStateEntityState>) => ({ boardStates });

const selectBoardStatesEntitiesDictionary = createSelector(selectBoardStatesEntities, boardStatesInObject);

const selectAllBoardStatesObject = createSelector(selectBoardStatesEntities, boardStates => {
  return hydrateAll({ boardStates });
});

const selectOneBoardStateDictionary = (idBoardState: number) =>
  createSelector(selectBoardStatesEntities, boardStates => {
    return { boardStates: { [idBoardState]: boardStates[idBoardState] } };
  });

const selectOneBoardStateDictionaryWithoutChild = (idBoardState: number) =>
  createSelector(selectBoardStatesEntities, boardStates => {
    return { boardState: boardStates[idBoardState] };
  });

const selectActiveBoardStatesEntities = createSelector(
  selectIdBoardStatesActive,
  selectBoardStatesEntities,
  (actives: number[], boardStates: Dictionary<BoardStateEntityState>) => getBoardStatesFromActives(actives, boardStates)
);

function getBoardStatesFromActives(
  actives: number[],
  boardStates: Dictionary<BoardStateEntityState>
): Dictionary<BoardStateEntityState> {
  return actives.reduce((acc, idActive) => {
    if (boardStates[idActive]) {
      acc[idActive] = boardStates[idActive];
    }
    return acc;
  }, {} as Dictionary<BoardStateEntityState>);
}

const selectAllBoardStatesSelectors: Dictionary<Selector> = {};
export function selectAllBoardStates(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<BoardState>(
      schema,
      selectAllBoardStatesSelectors,
      selectBoardStatesEntitiesDictionary,
      getRelationSelectors,
      boardStateRelations,
      hydrateAll,
      'boardState'
    );
  } else {
    return selectAllBoardStatesObject;
  }
}

export function selectAllBoardStatesDictionary(schema: SelectSchema = {}, customKey: string = 'boardStates'): Selector {
  return createSelector(selectAllBoardStates(schema), result => {
    const res = { [customKey]: {} as Dictionary<BoardStateEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.boardStates.length; i++) {
      res[customKey][result.boardStates[i].idBoardState] = result.boardStates[i];
    }
    return res;
  });
}

export function selectOneBoardState(schema: SelectSchema = {}, idBoardState: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneBoardStateDictionary(idBoardState)];
    selectors.push(...getRelationSelectors(schema, boardStateRelations, 'boardState'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneBoardStateDictionaryWithoutChild(idBoardState);
  }
}

export function selectActiveBoardStates(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [createSelector(selectActiveBoardStatesEntities, boardStates => ({ boardStates }))];
  selectors.push(...getRelationSelectors(schema, boardStateRelations, 'boardState'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  boardStates: Dictionary<BoardStateEntityState>;
  users?: Dictionary<UserEntityState>;
  familySaleCategories?: Dictionary<SaleCategoryFamilyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { boardStates: (BoardState | null)[] } {
  const { boardStates, users, familySaleCategories } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    boardStates: Object.keys(boardStates).map(idBoardState =>
      hydrate(boardStates[idBoardState] as BoardStateEntityState, users, familySaleCategories)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { boardState: BoardStateEntityState | null } {
  const { boardStates, users, familySaleCategories } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const boardState = Object.values(boardStates)[0];
  return { boardState: hydrate(boardState as BoardStateEntityState, users, familySaleCategories) };
}

function hydrate(
  boardState: BoardStateEntityState,
  userEntities?: Dictionary<UserEntityState>,
  saleCategoryFamilyEntities?: Dictionary<SaleCategoryFamilyEntityState>
): BoardState | null {
  if (!boardState) {
    return null;
  }

  const boardStateHydrated: BoardStateEntityState = { ...boardState };
  if (userEntities) {
    boardStateHydrated.user = userEntities[boardState.user as number] as User;
  } else {
    delete boardStateHydrated.user;
  }
  if (saleCategoryFamilyEntities) {
    boardStateHydrated.saleCategoryFamily = saleCategoryFamilyEntities[
      boardState.saleCategoryFamily as number
    ] as SaleCategoryFamily;
  } else {
    delete boardStateHydrated.saleCategoryFamily;
  }

  return boardStateHydrated as BoardState;
}
