import { RepositoryService } from '@_services/api/repository.service';
import { Company } from '@_model/interfaces/company.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanies(params?: any): Observable<Company[]> {
    return this.repo.getData<Company[]>('company', params);
  }

  public getCompany(params: { idCompany: number; params?: any }): Observable<Company> {
    return this.repo.getData<Company>('company/' + params.idCompany, params.params);
  }

  public addCompany(company: Partial<Company>): Observable<Company> {
    return this.repo.create<Company>('company', company);
  }

  public updateCompany(company: Partial<Company>): Observable<Company> {
    return this.repo.update('company', company);
  }

  public deleteCompany(idCompany: number): Observable<number> {
    return this.repo.delete('company/' + +idCompany);
  }
}
