import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { User } from '@_model/interfaces/user.model';
import { StorageService } from '@_services/storage.service';
import { ForecastPeriodService } from '@_services/store/forecast-period.service';
import { OrganizationService } from '@_services/store/organization.service';
import { SaleCategoryFamilyService } from '@_services/store/sale-category-family.service';
import { StepProgressService } from '@_services/store/step-progress.service';
import { StepService } from '@_services/store/step.service';
import { StratalotTypeService } from '@_services/store/stratalot-type.service';
import { UserService } from '@_services/store/user.service';
import { Organization } from '@_shared/models/interfaces/organization.model';
import { StepProgress } from '@_shared/models/interfaces/step-progress.model';
import { CompanyTerritoireModel } from '@_store/company-territoire/company-territoire-generated.selectors';
import { CompanyModel } from '@_store/company/company.selectors';
import { DroitModel } from '@_store/droit/droit-generated.selectors';
import { ProfilModel } from '@_store/profil/profil-generated.selectors';
import { StepModel } from '@_store/step/step.selectors';
import parseJwt from '@_utils/common-utils/jwt-decode.utils';
import { combineLatest, Observable } from 'rxjs';
import { concatMap, first, tap } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';

@Injectable({ providedIn: 'root' })
export class AppResolver implements Resolve<void> {
  constructor(
    private organizationService: OrganizationService,
    private stepService: StepService,
    private stepProgressService: StepProgressService,
    private storageService: StorageService,
    private userService: UserService,
    private saleCategoryFamilyService: SaleCategoryFamilyService,
    private forecastPeriodService: ForecastPeriodService,
    private stratalotTypeService: StratalotTypeService
  ) {}

  public resolve(): Observable<any> {
    const decodedToken = parseJwt(this.storageService.getLocal('login')?.token);
    const idUser = decodedToken?.idUser;

    this.userService.setActiveUsers([idUser]);
    this.organizationService.getManyOrganizations();
    this.saleCategoryFamilyService.getManyFamilySaleCategories();
    this.stepService.getManySteps();
    this.forecastPeriodService.getManyForecastPeriods();
    this.stratalotTypeService.getManyStratalotTypes({});

    return combineLatest([this.organizationService.getLoaded(), this.stepProgressService.getLoaded()]).pipe(
      first(([isLoaded, load]) => isLoaded && load),
      concatMap(() =>
        this.organizationService.selectAllOrganizations({ include: [CompanyModel] }).pipe(
          tap((organizations: Organization[]) => {
            this.organizationService.currentOrganization = organizations[0];
            this.organizationService.currentIdOrganization = organizations[0]?.idOrganization;
            Sentry.setContext('cellance_organization', {
              idOrganization: organizations[0]?.idOrganization,
              nom: organizations[0]?.nom
            });
          }),
          first()
        )
      ),
      concatMap(() =>
        this.userService
          .selectOneUser(idUser, {
            include: [
              { model: ProfilModel, include: [DroitModel] },
              { model: CompanyTerritoireModel, include: [CompanyModel] }
            ]
          })
          .pipe(
            tap((user: User) => {
              this.userService.updateCurrentUser(user);
              Sentry.setContext('cellance_user', { idUser: user.idUser, email: user.email });
            }),
            first()
          )
      ),
      concatMap(() =>
        this.stepProgressService.selectAllStepProgresses({ include: [StepModel] }).pipe(
          tap((stepProgresses: StepProgress[]) => (this.stepProgressService.stepProgresses = stepProgresses)),
          first()
        )
      )
    );
  }
}
