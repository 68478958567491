import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  ResidenceForecastValue,
  ResidenceForecastValueEntityState
} from '@_model/interfaces/residence-forecast-value.model';
import { ResidenceForecastValueRelationsIds } from './residence-forecast-value-generated.effects';

export const getOneResidenceForecastValue = createAction(
  '[ResidenceForecastValue] Get One ResidenceForecastValue',
  props<{ idResidenceForecastValue: number; params?: any }>()
);

export const getManyResidenceForecastValues = createAction(
  '[ResidenceForecastValue] Get Many ResidenceForecastValues',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ResidenceForecastValue] Add Many Actives ResidenceForecastValue',
  props<{ idResidenceForecastValues: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidenceForecastValue] Delete One Active ResidenceForecastValue',
  props<{ idResidenceForecastValue: number }>()
);

export const clearActive = createAction('[ResidenceForecastValue] Clear Active ResidenceForecastValue');

export const upsertOneResidenceForecastValue = createAction(
  '[ResidenceForecastValue] Upsert One ResidenceForecastValue',
  props<{
    residenceForecastValue: Partial<ResidenceForecastValue>;
    ids?: ResidenceForecastValueRelationsIds;
  }>()
);

export const upsertManyResidenceForecastValues = createAction(
  '[ResidenceForecastValue] Upsert Many ResidenceForecastValues',
  props<{
    residenceForecastValues: Partial<ResidenceForecastValue>[];
    ids?: ResidenceForecastValueRelationsIds;
  }>()
);

export const upsertManyResidenceForecastValuesSuccess = createAction(
  '[ResidenceForecastValue] Create Many ResidenceForecastValues Success',
  props<{ residenceForecastValues: ResidenceForecastValueEntityState[] }>()
);

export const deleteOneResidenceForecastValue = createAction(
  '[ResidenceForecastValue] Delete One ResidenceForecastValue',
  props<{ idResidenceForecastValue: number }>()
);

export const deleteOneResidenceForecastValueSuccess = createAction(
  '[ResidenceForecastValue] Delete One ResidenceForecastValue Success',
  props<{ idResidenceForecastValue: number }>()
);

export const normalizeManyResidenceForecastValuesAfterUpsert = createAction(
  '[ResidenceForecastValue] Normalize Many ResidenceForecastValues After Upsert',
  props<{ residenceForecastValues: ResidenceForecastValueEntityState[] }>()
);

export const residenceForecastValuesFailure = createAction(
  '[ResidenceForecastValue] ResidenceForecastValues Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidenceForecastValue] Clear ResidenceForecastValues');

export const addResidenceForecastSuccess = createAction(
  '[ResidenceForecastValue] Add ResidenceForecast',
  props<{ idResidenceForecastValue: number; idResidenceForecast: number }>()
);

export const deleteManyResidenceForecastSuccess = createAction(
  '[ResidenceForecastValue] Delete Many ResidenceForecast',
  props<{ idResidenceForecasts: number[]; idResidenceForecastValues: number[] }>()
);
