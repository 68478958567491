import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { StratalotCampaignApiService } from '@_services/api/stratalot-campaign-api.service';
import { CampaignMedia } from '@_shared/models/interfaces/campaign-media.model';
import { StratalotCampaign } from '@_shared/models/interfaces/stratalot-campaign.model';
import * as campaignMediaActions from '@_store/campaign-media/campaign-media.actions';
import { AppState } from '@_store/index.reducers';
import * as stratalotCampaignActions from '@_store/stratalot-campaign/stratalot-campaign.actions';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import { catchError, concatMap, map, of } from 'rxjs';
import {
  GeneratedStratalotCampaignEffects,
  StratalotCampaignRelationsIds
} from './stratalot-campaign-generated.effects';

@Injectable()
export class StratalotCampaignEffects extends GeneratedStratalotCampaignEffects {
  constructor(actions$: Actions, stratalotCampaignApiService: StratalotCampaignApiService, store$: Store<AppState>) {
    super(actions$, stratalotCampaignApiService, store$);
  }

  upsertOneStratalotCampaign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotCampaignActions.upsertOneStratalotCampaign),
      concatMap(
        ({
          stratalotCampaign,
          ids
        }: {
          stratalotCampaign: Partial<StratalotCampaign>;
          ids?: StratalotCampaignRelationsIds;
        }) => {
          if (stratalotCampaign.idStratalotCampaign) {
            return this.stratalotCampaignApiService.updateOneStratalotCampaign(stratalotCampaign).pipe(
              map((stratalotCampaignReturned: StratalotCampaign[]) => {
                return stratalotCampaignActions.normalizeManyStratalotCampaignsAfterUpsert({
                  stratalotCampaigns: stratalotCampaignReturned
                });
              }),
              catchError(error => of(stratalotCampaignActions.stratalotCampaignsFailure({ error })))
            );
          } else {
            return this.stratalotCampaignApiService.addOneStratalotCampaign(stratalotCampaign).pipe(
              concatMap((stratalotCampaignsReturned: StratalotCampaign[]) => {
                const actions: Action[] = [
                  stratalotCampaignActions.normalizeManyStratalotCampaignsAfterUpsert({
                    stratalotCampaigns: stratalotCampaignsReturned
                  })
                ];
                // const actions = getActionsToNormalizeStratalotCampaign(
                //   stratalotCampaignsReturned,
                //   StoreActionType.upsert
                // );
                // return [getMultiAction(actions, '[StratalotCampaign] Normalization After Upsert Success')];

                stratalotCampaignsReturned.map(scr => {
                  if (ids?.stratalot) {
                    actions.push(
                      stratalotActions.addManyStratalotCampaignSuccess({
                        idStratalot: ids.stratalot,
                        idStratalotCampaigns: [scr.idStratalotCampaign]
                      })
                    );
                    actions.push(
                      stratalotCampaignActions.addStratalotSuccess({
                        idStratalotCampaign: scr.idStratalotCampaign,
                        idStratalot: ids.stratalot
                      })
                    );
                  }

                  if (ids?.campaignMedias) {
                    if (!Array.isArray(ids.campaignMedias)) {
                      actions.push(
                        campaignMediaActions.upsertOneCampaignMedia({
                          campaignMedia: {
                            idStratalotCampaign: scr.idStratalotCampaign,
                            idCampaignMedia: ids.campaignMedias as number
                          } as CampaignMedia
                        })
                      );
                      actions.push(
                        stratalotCampaignActions.addManyCampaignMediaSuccess({
                          idStratalotCampaign: scr.idStratalotCampaign,
                          idCampaignMedias: [ids.campaignMedias as number]
                        })
                      );
                    } else {
                      actions.push(
                        campaignMediaActions.upsertManyCampaignMedias({
                          campaignMedias: (ids.campaignMedias as number[]).map((idCampaignMedia: number) => ({
                            idStratalotCampaign: scr.idStratalotCampaign,
                            idCampaignMedia
                          })) as CampaignMedia[]
                        })
                      );
                      actions.push(
                        stratalotCampaignActions.addManyCampaignMediaSuccess({
                          idStratalotCampaign: scr.idStratalotCampaign,
                          idCampaignMedias: ids.campaignMedias as number[]
                        })
                      );
                    }
                  }
                });
                return actions;
              }),
              catchError(error => of(stratalotCampaignActions.stratalotCampaignsFailure({ error })))
            );
          }
        }
      )
    );
  });
}
