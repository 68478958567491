import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  CompanyCommunicationRecipient,
  CompanyCommunicationRecipientEntityState
} from '@_model/interfaces/company-communication-recipient.model';
import { CompanyCommunicationRecipientApiService } from '@_services/api/company-communication-recipient-api.service';
import * as companyCommunicationRecipientActions from '@_store/company-communication-recipient/company-communication-recipient.actions';
import { getActionsToNormalizeCompanyCommunicationRecipient } from '@_config/store/normalization.generated';
import { selectCompanyCommunicationRecipientState } from './company-communication-recipient-generated.selectors';
import * as companyCommunicationActions from '@_store/company-communication/company-communication.actions';
import { CompanyCommunication } from '@_model/interfaces/company-communication.model';
import * as userActions from '@_store/user/user.actions';
import { User } from '@_model/interfaces/user.model';
import * as companyCommunicationRecipientUserActions from '@_store/company-communication-recipient-user/company-communication-recipient-user.actions';
import { CompanyCommunicationRecipientUser } from '@_model/interfaces/company-communication-recipient-user.model';
import * as organizationMetierActions from '@_store/organization-metier/organization-metier.actions';
import { OrganizationMetier } from '@_model/interfaces/organization-metier.model';
import * as companyCommunicationRecipientMetierActions from '@_store/company-communication-recipient-metier/company-communication-recipient-metier.actions';
import { CompanyCommunicationRecipientMetier } from '@_model/interfaces/company-communication-recipient-metier.model';

export interface CompanyCommunicationRecipientRelationsIds {
  companyCommunication?: number;
  users?: number | number[];
  companyCommunicationRecipientUsers?: number | number[];
  organizationMetiers?: number | number[];
  companyCommunicationRecipientMetiers?: number | number[];
}

export function getDefaultAddCompanyCommunicationRecipientActions(
  companyCommunicationRecipient: CompanyCommunicationRecipientEntityState,
  ids?: CompanyCommunicationRecipientRelationsIds
): Action[] {
  const actions: Action[] = [
    companyCommunicationRecipientActions.normalizeManyCompanyCommunicationRecipientsAfterUpsert({
      companyCommunicationRecipients: [companyCommunicationRecipient]
    })
  ];

  if (ids?.companyCommunication) {
    actions.push(
      companyCommunicationActions.upsertOneCompanyCommunication({
        companyCommunication: {
          idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
          idCompanyCommunication: ids.companyCommunication as number
        } as CompanyCommunication
      })
    );
    actions.push(
      companyCommunicationRecipientActions.addCompanyCommunicationSuccess({
        idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
        idCompanyCommunication: ids.companyCommunication
      })
    );
  }

  if (ids?.users) {
    if (!Array.isArray(ids.users)) {
      actions.push(
        userActions.upsertOneUser({
          user: {
            idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
            idUser: ids.users as number
          } as User & any
        })
      );
      actions.push(
        companyCommunicationRecipientActions.addManyUserSuccess({
          idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
          idUsers: [ids.users as number]
        })
      );
    } else {
      actions.push(
        userActions.upsertManyUsers({
          users: (ids.users as number[]).map((idUser: number) => ({
            idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
            idUser
          })) as User[] & any[]
        })
      );
      actions.push(
        companyCommunicationRecipientActions.addManyUserSuccess({
          idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
          idUsers: ids.users as number[]
        })
      );
    }
  }

  if (ids?.companyCommunicationRecipientUsers) {
    if (!Array.isArray(ids.companyCommunicationRecipientUsers)) {
      actions.push(
        companyCommunicationRecipientUserActions.upsertOneCompanyCommunicationRecipientUser({
          companyCommunicationRecipientUser: {
            idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
            idCompanyCommunicationRecipientUser: ids.companyCommunicationRecipientUsers as number
          } as CompanyCommunicationRecipientUser
        })
      );
      actions.push(
        companyCommunicationRecipientActions.addManyCompanyCommunicationRecipientUserSuccess({
          idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
          idCompanyCommunicationRecipientUsers: [ids.companyCommunicationRecipientUsers as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationRecipientUserActions.upsertManyCompanyCommunicationRecipientUsers({
          companyCommunicationRecipientUsers: (ids.companyCommunicationRecipientUsers as number[]).map(
            (idCompanyCommunicationRecipientUser: number) => ({
              idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
              idCompanyCommunicationRecipientUser
            })
          ) as CompanyCommunicationRecipientUser[]
        })
      );
      actions.push(
        companyCommunicationRecipientActions.addManyCompanyCommunicationRecipientUserSuccess({
          idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
          idCompanyCommunicationRecipientUsers: ids.companyCommunicationRecipientUsers as number[]
        })
      );
    }
  }

  if (ids?.organizationMetiers) {
    if (!Array.isArray(ids.organizationMetiers)) {
      actions.push(
        organizationMetierActions.upsertOneOrganizationMetier({
          organizationMetier: {
            idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
            idOrganizationMetier: ids.organizationMetiers as number
          } as OrganizationMetier & any
        })
      );
      actions.push(
        companyCommunicationRecipientActions.addManyOrganizationMetierSuccess({
          idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
          idOrganizationMetiers: [ids.organizationMetiers as number]
        })
      );
    } else {
      actions.push(
        organizationMetierActions.upsertManyOrganizationMetiers({
          organizationMetiers: (ids.organizationMetiers as number[]).map((idOrganizationMetier: number) => ({
            idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
            idOrganizationMetier
          })) as OrganizationMetier[] & any[]
        })
      );
      actions.push(
        companyCommunicationRecipientActions.addManyOrganizationMetierSuccess({
          idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
          idOrganizationMetiers: ids.organizationMetiers as number[]
        })
      );
    }
  }

  if (ids?.companyCommunicationRecipientMetiers) {
    if (!Array.isArray(ids.companyCommunicationRecipientMetiers)) {
      actions.push(
        companyCommunicationRecipientMetierActions.upsertOneCompanyCommunicationRecipientMetier({
          companyCommunicationRecipientMetier: {
            idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
            idCompanyCommunicationRecipientMetier: ids.companyCommunicationRecipientMetiers as number
          } as CompanyCommunicationRecipientMetier
        })
      );
      actions.push(
        companyCommunicationRecipientActions.addManyCompanyCommunicationRecipientMetierSuccess({
          idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
          idCompanyCommunicationRecipientMetiers: [ids.companyCommunicationRecipientMetiers as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationRecipientMetierActions.upsertManyCompanyCommunicationRecipientMetiers({
          companyCommunicationRecipientMetiers: (ids.companyCommunicationRecipientMetiers as number[]).map(
            (idCompanyCommunicationRecipientMetier: number) => ({
              idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
              idCompanyCommunicationRecipientMetier
            })
          ) as CompanyCommunicationRecipientMetier[]
        })
      );
      actions.push(
        companyCommunicationRecipientActions.addManyCompanyCommunicationRecipientMetierSuccess({
          idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient,
          idCompanyCommunicationRecipientMetiers: ids.companyCommunicationRecipientMetiers as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCompanyCommunicationRecipientActions(
  companyCommunicationRecipient: CompanyCommunicationRecipientEntityState
): Action[] {
  const actions: Action[] = [
    companyCommunicationRecipientActions.deleteOneCompanyCommunicationRecipientSuccess({
      idCompanyCommunicationRecipient: companyCommunicationRecipient.idCompanyCommunicationRecipient
    })
  ];

  if (companyCommunicationRecipient.companyCommunication) {
    actions.push(
      companyCommunicationActions.deleteManyCompanyCommunicationRecipientSuccess({
        idCompanyCommunicationRecipients: [companyCommunicationRecipient.idCompanyCommunicationRecipient],
        idCompanyCommunications: [companyCommunicationRecipient.companyCommunication] as number[]
      })
    );
  }

  if (companyCommunicationRecipient.users) {
    actions.push(
      userActions.deleteManyCompanyCommunicationRecipientSuccess({
        idCompanyCommunicationRecipients: [companyCommunicationRecipient.idCompanyCommunicationRecipient],
        idUsers: companyCommunicationRecipient.users as number[]
      })
    );
  }

  if (companyCommunicationRecipient.companyCommunicationRecipientUsers) {
    actions.push(
      companyCommunicationRecipientUserActions.deleteManyCompanyCommunicationRecipientSuccess({
        idCompanyCommunicationRecipients: [companyCommunicationRecipient.idCompanyCommunicationRecipient],
        idCompanyCommunicationRecipientUsers:
          companyCommunicationRecipient.companyCommunicationRecipientUsers as number[]
      })
    );
  }

  if (companyCommunicationRecipient.organizationMetiers) {
    actions.push(
      organizationMetierActions.deleteManyCompanyCommunicationRecipientSuccess({
        idCompanyCommunicationRecipients: [companyCommunicationRecipient.idCompanyCommunicationRecipient],
        idOrganizationMetiers: companyCommunicationRecipient.organizationMetiers as number[]
      })
    );
  }

  if (companyCommunicationRecipient.companyCommunicationRecipientMetiers) {
    actions.push(
      companyCommunicationRecipientMetierActions.deleteManyCompanyCommunicationRecipientSuccess({
        idCompanyCommunicationRecipients: [companyCommunicationRecipient.idCompanyCommunicationRecipient],
        idCompanyCommunicationRecipientMetiers:
          companyCommunicationRecipient.companyCommunicationRecipientMetiers as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyCommunicationRecipientEffects {
  constructor(
    protected actions$: Actions,
    protected companyCommunicationRecipientApiService: CompanyCommunicationRecipientApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyCommunicationRecipients$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationRecipientActions.getManyCompanyCommunicationRecipients),
      switchMap(({ params }) =>
        this.companyCommunicationRecipientApiService.getCompanyCommunicationRecipients(params).pipe(
          map((companyCommunicationRecipients: CompanyCommunicationRecipient[]) => {
            return companyCommunicationRecipientActions.normalizeManyCompanyCommunicationRecipientsAfterUpsert({
              companyCommunicationRecipients
            });
          }),
          catchError(error => of(companyCommunicationRecipientActions.companyCommunicationRecipientsFailure({ error })))
        )
      )
    );
  });

  getOneCompanyCommunicationRecipient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationRecipientActions.getOneCompanyCommunicationRecipient),
      switchMap(idCompanyCommunicationRecipient =>
        this.companyCommunicationRecipientApiService
          .getCompanyCommunicationRecipient(idCompanyCommunicationRecipient)
          .pipe(
            map((companyCommunicationRecipient: CompanyCommunicationRecipient) => {
              return companyCommunicationRecipientActions.normalizeManyCompanyCommunicationRecipientsAfterUpsert({
                companyCommunicationRecipients: [companyCommunicationRecipient]
              });
            }),
            catchError(error =>
              of(companyCommunicationRecipientActions.companyCommunicationRecipientsFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneCompanyCommunicationRecipient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationRecipientActions.upsertOneCompanyCommunicationRecipient),
      concatMap(
        ({
          companyCommunicationRecipient,
          ids
        }: {
          companyCommunicationRecipient: Partial<CompanyCommunicationRecipient>;
          ids?: CompanyCommunicationRecipientRelationsIds;
        }) => {
          if (companyCommunicationRecipient.idCompanyCommunicationRecipient) {
            return this.companyCommunicationRecipientApiService
              .updateCompanyCommunicationRecipient(companyCommunicationRecipient)
              .pipe(
                map((companyCommunicationRecipientReturned: CompanyCommunicationRecipient) => {
                  return companyCommunicationRecipientActions.normalizeManyCompanyCommunicationRecipientsAfterUpsert({
                    companyCommunicationRecipients: [companyCommunicationRecipientReturned]
                  });
                }),
                catchError(error =>
                  of(companyCommunicationRecipientActions.companyCommunicationRecipientsFailure({ error }))
                )
              );
          } else {
            return this.companyCommunicationRecipientApiService
              .addCompanyCommunicationRecipient(companyCommunicationRecipient)
              .pipe(
                mergeMap((companyCommunicationRecipientReturned: CompanyCommunicationRecipient) =>
                  getDefaultAddCompanyCommunicationRecipientActions(companyCommunicationRecipientReturned, ids)
                ),
                catchError(error =>
                  of(companyCommunicationRecipientActions.companyCommunicationRecipientsFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneCompanyCommunicationRecipient$ = createEffect(() => {
    const selectCompanyCommunicationRecipientState$ = this.store$.select(selectCompanyCommunicationRecipientState);
    return this.actions$.pipe(
      ofType(companyCommunicationRecipientActions.deleteOneCompanyCommunicationRecipient),
      withLatestFrom(selectCompanyCommunicationRecipientState$),
      concatMap(([{ idCompanyCommunicationRecipient }, state]) =>
        this.companyCommunicationRecipientApiService
          .deleteCompanyCommunicationRecipient(idCompanyCommunicationRecipient)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteCompanyCommunicationRecipientActions(
                  state.entities[idCompanyCommunicationRecipient] as CompanyCommunicationRecipientEntityState
                ),
                companyCommunicationRecipientActions.deleteOneCompanyCommunicationRecipient.type
              )
            ]),
            catchError(error =>
              of(companyCommunicationRecipientActions.companyCommunicationRecipientsFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyCompanyCommunicationRecipientsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationRecipientActions.normalizeManyCompanyCommunicationRecipientsAfterUpsert),
      concatMap(({ companyCommunicationRecipients }) => {
        const actions: Action[] = getActionsToNormalizeCompanyCommunicationRecipient(
          companyCommunicationRecipients,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CompanyCommunicationRecipient] Normalization After Upsert Success')];
      })
    );
  });
}
