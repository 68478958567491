import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyCommunicationRecipientUserApiService } from '@_services/api/company-communication-recipient-user-api.service';
import { GeneratedCompanyCommunicationRecipientUserEffects } from './company-communication-recipient-user-generated.effects';

@Injectable()
export class CompanyCommunicationRecipientUserEffects extends GeneratedCompanyCommunicationRecipientUserEffects {
  constructor(
    actions$: Actions,
    companyCommunicationRecipientUserApiService: CompanyCommunicationRecipientUserApiService,
    store$: Store<AppState>
  ) {
    super(actions$, companyCommunicationRecipientUserApiService, store$);
  }
}
