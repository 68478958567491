import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { StratalotType, StratalotTypeEntityState } from '@_model/interfaces/stratalot-type.model';
import { StratalotTypeApiService } from '@_services/api/stratalot-type-api.service';
import * as stratalotTypeActions from '@_store/stratalot-type/stratalot-type.actions';
import { getActionsToNormalizeStratalotType } from '@_config/store/normalization.generated';
import { selectStratalotTypeState } from './stratalot-type-generated.selectors';
import * as companyStratalotTypeActions from '@_store/company-stratalot-type/company-stratalot-type.actions';
import { CompanyStratalotType } from '@_model/interfaces/company-stratalot-type.model';
import * as prospectBuyingWishActions from '@_store/prospect-buying-wish/prospect-buying-wish.actions';
import { ProspectBuyingWish } from '@_model/interfaces/prospect-buying-wish.model';
import * as organizationLeadTodoRuleActions from '@_store/organization-lead-todo-rule/organization-lead-todo-rule.actions';
import { OrganizationLeadTodoRule } from '@_model/interfaces/organization-lead-todo-rule.model';
import * as organizationStratalotTodoRuleActions from '@_store/organization-stratalot-todo-rule/organization-stratalot-todo-rule.actions';
import { OrganizationStratalotTodoRule } from '@_model/interfaces/organization-stratalot-todo-rule.model';

export interface StratalotTypeRelationsIds {
  companyStratalotTypes?: number | number[];
  prospectBuyingWishs?: number | number[];
  organizationLeadTodoRules?: number | number[];
  organizationStratalotTodoRules?: number | number[];
}

export function getDefaultAddStratalotTypeActions(
  stratalotType: StratalotTypeEntityState,
  ids?: StratalotTypeRelationsIds
): Action[] {
  const actions: Action[] = [
    stratalotTypeActions.normalizeManyStratalotTypesAfterUpsert({ stratalotTypes: [stratalotType] })
  ];

  if (ids?.companyStratalotTypes) {
    if (!Array.isArray(ids.companyStratalotTypes)) {
      actions.push(
        companyStratalotTypeActions.upsertOneCompanyStratalotType({
          companyStratalotType: {
            idStratalotType: stratalotType.idStratalotType,
            idCompanyStratalotType: ids.companyStratalotTypes as number
          } as CompanyStratalotType
        })
      );
      actions.push(
        stratalotTypeActions.addManyCompanyStratalotTypeSuccess({
          idStratalotType: stratalotType.idStratalotType,
          idCompanyStratalotTypes: [ids.companyStratalotTypes as number]
        })
      );
    } else {
      actions.push(
        companyStratalotTypeActions.upsertManyCompanyStratalotTypes({
          companyStratalotTypes: (ids.companyStratalotTypes as number[]).map((idCompanyStratalotType: number) => ({
            idStratalotType: stratalotType.idStratalotType,
            idCompanyStratalotType
          })) as CompanyStratalotType[]
        })
      );
      actions.push(
        stratalotTypeActions.addManyCompanyStratalotTypeSuccess({
          idStratalotType: stratalotType.idStratalotType,
          idCompanyStratalotTypes: ids.companyStratalotTypes as number[]
        })
      );
    }
  }

  if (ids?.prospectBuyingWishs) {
    if (!Array.isArray(ids.prospectBuyingWishs)) {
      actions.push(
        prospectBuyingWishActions.upsertOneProspectBuyingWish({
          prospectBuyingWish: {
            idStratalotType: stratalotType.idStratalotType,
            idProspectBuyingWish: ids.prospectBuyingWishs as number
          } as ProspectBuyingWish & any
        })
      );
      actions.push(
        stratalotTypeActions.addManyProspectBuyingWishSuccess({
          idStratalotType: stratalotType.idStratalotType,
          idProspectBuyingWishs: [ids.prospectBuyingWishs as number]
        })
      );
    } else {
      actions.push(
        prospectBuyingWishActions.upsertManyProspectBuyingWishs({
          prospectBuyingWishs: (ids.prospectBuyingWishs as number[]).map((idProspectBuyingWish: number) => ({
            idStratalotType: stratalotType.idStratalotType,
            idProspectBuyingWish
          })) as ProspectBuyingWish[] & any[]
        })
      );
      actions.push(
        stratalotTypeActions.addManyProspectBuyingWishSuccess({
          idStratalotType: stratalotType.idStratalotType,
          idProspectBuyingWishs: ids.prospectBuyingWishs as number[]
        })
      );
    }
  }

  if (ids?.organizationLeadTodoRules) {
    if (!Array.isArray(ids.organizationLeadTodoRules)) {
      actions.push(
        organizationLeadTodoRuleActions.upsertOneOrganizationLeadTodoRule({
          organizationLeadTodoRule: {
            idStratalotType: stratalotType.idStratalotType,
            idOrganizationLeadTodoRule: ids.organizationLeadTodoRules as number
          } as OrganizationLeadTodoRule
        })
      );
      actions.push(
        stratalotTypeActions.addManyOrganizationLeadTodoRuleSuccess({
          idStratalotType: stratalotType.idStratalotType,
          idOrganizationLeadTodoRules: [ids.organizationLeadTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationLeadTodoRuleActions.upsertManyOrganizationLeadTodoRules({
          organizationLeadTodoRules: (ids.organizationLeadTodoRules as number[]).map(
            (idOrganizationLeadTodoRule: number) => ({
              idStratalotType: stratalotType.idStratalotType,
              idOrganizationLeadTodoRule
            })
          ) as OrganizationLeadTodoRule[]
        })
      );
      actions.push(
        stratalotTypeActions.addManyOrganizationLeadTodoRuleSuccess({
          idStratalotType: stratalotType.idStratalotType,
          idOrganizationLeadTodoRules: ids.organizationLeadTodoRules as number[]
        })
      );
    }
  }

  if (ids?.organizationStratalotTodoRules) {
    if (!Array.isArray(ids.organizationStratalotTodoRules)) {
      actions.push(
        organizationStratalotTodoRuleActions.upsertOneOrganizationStratalotTodoRule({
          organizationStratalotTodoRule: {
            idStratalotType: stratalotType.idStratalotType,
            idOrganizationStratalotTodoRule: ids.organizationStratalotTodoRules as number
          } as OrganizationStratalotTodoRule
        })
      );
      actions.push(
        stratalotTypeActions.addManyOrganizationStratalotTodoRuleSuccess({
          idStratalotType: stratalotType.idStratalotType,
          idOrganizationStratalotTodoRules: [ids.organizationStratalotTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotTodoRuleActions.upsertManyOrganizationStratalotTodoRules({
          organizationStratalotTodoRules: (ids.organizationStratalotTodoRules as number[]).map(
            (idOrganizationStratalotTodoRule: number) => ({
              idStratalotType: stratalotType.idStratalotType,
              idOrganizationStratalotTodoRule
            })
          ) as OrganizationStratalotTodoRule[]
        })
      );
      actions.push(
        stratalotTypeActions.addManyOrganizationStratalotTodoRuleSuccess({
          idStratalotType: stratalotType.idStratalotType,
          idOrganizationStratalotTodoRules: ids.organizationStratalotTodoRules as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteStratalotTypeActions(stratalotType: StratalotTypeEntityState): Action[] {
  const actions: Action[] = [
    stratalotTypeActions.deleteOneStratalotTypeSuccess({ idStratalotType: stratalotType.idStratalotType })
  ];

  if (stratalotType.companyStratalotTypes) {
    actions.push(
      companyStratalotTypeActions.deleteManyStratalotTypeSuccess({
        idStratalotTypes: [stratalotType.idStratalotType],
        idCompanyStratalotTypes: stratalotType.companyStratalotTypes as number[]
      })
    );
  }

  if (stratalotType.prospectBuyingWishs) {
    actions.push(
      prospectBuyingWishActions.deleteManyStratalotTypeSuccess({
        idStratalotTypes: [stratalotType.idStratalotType],
        idProspectBuyingWishs: stratalotType.prospectBuyingWishs as number[]
      })
    );
  }

  if (stratalotType.organizationLeadTodoRules) {
    actions.push(
      organizationLeadTodoRuleActions.deleteManyStratalotTypeSuccess({
        idStratalotTypes: [stratalotType.idStratalotType],
        idOrganizationLeadTodoRules: stratalotType.organizationLeadTodoRules as number[]
      })
    );
  }

  if (stratalotType.organizationStratalotTodoRules) {
    actions.push(
      organizationStratalotTodoRuleActions.deleteManyStratalotTypeSuccess({
        idStratalotTypes: [stratalotType.idStratalotType],
        idOrganizationStratalotTodoRules: stratalotType.organizationStratalotTodoRules as number[]
      })
    );
  }

  return actions;
}

export class GeneratedStratalotTypeEffects {
  constructor(
    protected actions$: Actions,
    protected stratalotTypeApiService: StratalotTypeApiService,
    protected store$: Store<AppState>
  ) {}

  getManyStratalotTypes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotTypeActions.getManyStratalotTypes),
      switchMap(({ params }) =>
        this.stratalotTypeApiService.getStratalotTypes(params).pipe(
          map((stratalotTypes: StratalotType[]) => {
            return stratalotTypeActions.normalizeManyStratalotTypesAfterUpsert({ stratalotTypes });
          }),
          catchError(error => of(stratalotTypeActions.stratalotTypesFailure({ error })))
        )
      )
    );
  });

  getOneStratalotType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotTypeActions.getOneStratalotType),
      switchMap(idStratalotType =>
        this.stratalotTypeApiService.getStratalotType(idStratalotType).pipe(
          map((stratalotType: StratalotType) => {
            return stratalotTypeActions.normalizeManyStratalotTypesAfterUpsert({ stratalotTypes: [stratalotType] });
          }),
          catchError(error => of(stratalotTypeActions.stratalotTypesFailure({ error })))
        )
      )
    );
  });

  upsertOneStratalotType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotTypeActions.upsertOneStratalotType),
      concatMap(
        ({ stratalotType, ids }: { stratalotType: Partial<StratalotType>; ids?: StratalotTypeRelationsIds }) => {
          if (stratalotType.idStratalotType) {
            return this.stratalotTypeApiService.updateStratalotType(stratalotType).pipe(
              map((stratalotTypeReturned: StratalotType) => {
                return stratalotTypeActions.normalizeManyStratalotTypesAfterUpsert({
                  stratalotTypes: [stratalotTypeReturned]
                });
              }),
              catchError(error => of(stratalotTypeActions.stratalotTypesFailure({ error })))
            );
          } else {
            return this.stratalotTypeApiService.addStratalotType(stratalotType).pipe(
              mergeMap((stratalotTypeReturned: StratalotType) =>
                getDefaultAddStratalotTypeActions(stratalotTypeReturned, ids)
              ),
              catchError(error => of(stratalotTypeActions.stratalotTypesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneStratalotType$ = createEffect(() => {
    const selectStratalotTypeState$ = this.store$.select(selectStratalotTypeState);
    return this.actions$.pipe(
      ofType(stratalotTypeActions.deleteOneStratalotType),
      withLatestFrom(selectStratalotTypeState$),
      concatMap(([{ idStratalotType }, state]) =>
        this.stratalotTypeApiService.deleteStratalotType(idStratalotType).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteStratalotTypeActions(state.entities[idStratalotType] as StratalotTypeEntityState),
              stratalotTypeActions.deleteOneStratalotType.type
            )
          ]),
          catchError(error => of(stratalotTypeActions.stratalotTypesFailure({ error })))
        )
      )
    );
  });

  normalizeManyStratalotTypesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotTypeActions.normalizeManyStratalotTypesAfterUpsert),
      concatMap(({ stratalotTypes }) => {
        const actions: Action[] = getActionsToNormalizeStratalotType(stratalotTypes, StoreActionType.upsert);
        return [getMultiAction(actions, '[StratalotType] Normalization After Upsert Success')];
      })
    );
  });
}
