import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationProspectOriginActions from './organization-prospect-origin.actions';
import { adapter, initialState, OrganizationProspectOriginState } from './organization-prospect-origin.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationProspectOriginEntityState } from '@_model/interfaces/organization-prospect-origin.model';

export const organizationProspectOriginReducersGeneratedFunctions: ReducerTypes<
  OrganizationProspectOriginState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationProspectOriginActions.getOneOrganizationProspectOrigin, (state: OrganizationProspectOriginState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationProspectOriginActions.getManyOrganizationProspectOrigins, (state: OrganizationProspectOriginState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationProspectOriginActions.upsertOneOrganizationProspectOrigin, (state: OrganizationProspectOriginState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationProspectOriginActions.upsertManyOrganizationProspectOriginsSuccess,
    (state: OrganizationProspectOriginState, { organizationProspectOrigins }) =>
      adapter.upsertMany(organizationProspectOrigins, setLoadingsState(state, false))
  ),
  on(OrganizationProspectOriginActions.deleteOneOrganizationProspectOrigin, (state: OrganizationProspectOriginState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationProspectOriginActions.deleteOneOrganizationProspectOriginSuccess,
    (state: OrganizationProspectOriginState, { idOrganizationProspectOrigin }) =>
      adapter.removeOne(idOrganizationProspectOrigin, setLoadingsState(state, false))
  ),
  on(OrganizationProspectOriginActions.clearActive, (state: OrganizationProspectOriginState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationProspectOriginActions.addManyActives,
    (state: OrganizationProspectOriginState, { idOrganizationProspectOrigins }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationProspectOrigins)
    })
  ),
  on(
    OrganizationProspectOriginActions.deleteOneActive,
    (state: OrganizationProspectOriginState, { idOrganizationProspectOrigin }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationProspectOrigin)
    })
  ),

  on(OrganizationProspectOriginActions.clearStore, () => initialState),

  on(
    OrganizationProspectOriginActions.addManyProspectSuccess,
    (state: OrganizationProspectOriginState, { idOrganizationProspectOrigin, idProspects }) => {
      if (!state.entities[idOrganizationProspectOrigin]) {
        return state;
      }
      const prospects = (state.entities[idOrganizationProspectOrigin]?.prospects as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationProspectOrigin]: {
            ...state.entities[idOrganizationProspectOrigin],
            prospects: prospects.concat(idProspects.filter(id => prospects.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationProspectOriginActions.deleteManyProspectSuccess,
    (state: OrganizationProspectOriginState, { idProspects, idOrganizationProspectOrigins }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationProspectOrigins.reduce((entities, idOrganizationProspectOrigin) => {
            if (!state.entities[idOrganizationProspectOrigin]?.prospects) {
              return entities;
            }
            entities[idOrganizationProspectOrigin] = {
              ...state.entities[idOrganizationProspectOrigin],
              prospects: (state.entities[idOrganizationProspectOrigin]?.prospects as number[])?.filter(
                (idProspect: number) => !idProspects.some((id: number) => id === idProspect)
              )
            } as OrganizationProspectOriginEntityState;
            return entities;
          }, {} as Dictionary<OrganizationProspectOriginEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationProspectOriginActions.addOrganizationSuccess,
    (state: OrganizationProspectOriginState, { idOrganizationProspectOrigin, idOrganization }) => {
      if (!state.entities[idOrganizationProspectOrigin]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationProspectOrigin]: {
            ...state.entities[idOrganizationProspectOrigin],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationProspectOriginActions.deleteManyOrganizationSuccess,
    (state: OrganizationProspectOriginState, { idOrganizations, idOrganizationProspectOrigins }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationProspectOrigins.reduce((entities, idOrganizationProspectOrigin) => {
            if (!state.entities[idOrganizationProspectOrigin]?.organization) {
              return entities;
            }
            entities[idOrganizationProspectOrigin] = {
              ...state.entities[idOrganizationProspectOrigin],
              organization: idOrganizations.some(
                (idOrganization: number) =>
                  idOrganization === state.entities[idOrganizationProspectOrigin]?.organization
              )
                ? undefined
                : state.entities[idOrganizationProspectOrigin]?.organization
            } as OrganizationProspectOriginEntityState;
            return entities;
          }, {} as Dictionary<OrganizationProspectOriginEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationProspectOriginState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationProspectOriginState {
  return { ...state, isLoaded, isLoading };
}
