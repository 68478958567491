import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationStratalotAvancement,
  OrganizationStratalotAvancementEntityState
} from '@_model/interfaces/organization-stratalot-avancement.model';
import { OrganizationStratalotAvancementRelationsIds } from './organization-stratalot-avancement-generated.effects';

export const getOneOrganizationStratalotAvancement = createAction(
  '[OrganizationStratalotAvancement] Get One OrganizationStratalotAvancement',
  props<{ idOrganizationStratalotAvancement: number; params?: any }>()
);

export const getManyOrganizationStratalotAvancements = createAction(
  '[OrganizationStratalotAvancement] Get Many OrganizationStratalotAvancements',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationStratalotAvancement] Add Many Actives OrganizationStratalotAvancement',
  props<{ idOrganizationStratalotAvancements: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationStratalotAvancement] Delete One Active OrganizationStratalotAvancement',
  props<{ idOrganizationStratalotAvancement: number }>()
);

export const clearActive = createAction(
  '[OrganizationStratalotAvancement] Clear Active OrganizationStratalotAvancement'
);

export const upsertOneOrganizationStratalotAvancement = createAction(
  '[OrganizationStratalotAvancement] Upsert One OrganizationStratalotAvancement',
  props<{
    organizationStratalotAvancement: Partial<OrganizationStratalotAvancement>;
    ids?: OrganizationStratalotAvancementRelationsIds;
  }>()
);

export const upsertManyOrganizationStratalotAvancements = createAction(
  '[OrganizationStratalotAvancement] Upsert Many OrganizationStratalotAvancements',
  props<{
    organizationStratalotAvancements: Partial<OrganizationStratalotAvancement>[];
    ids?: OrganizationStratalotAvancementRelationsIds;
  }>()
);

export const upsertManyOrganizationStratalotAvancementsSuccess = createAction(
  '[OrganizationStratalotAvancement] Create Many OrganizationStratalotAvancements Success',
  props<{ organizationStratalotAvancements: OrganizationStratalotAvancementEntityState[] }>()
);

export const deleteOneOrganizationStratalotAvancement = createAction(
  '[OrganizationStratalotAvancement] Delete One OrganizationStratalotAvancement',
  props<{ idOrganizationStratalotAvancement: number }>()
);

export const deleteOneOrganizationStratalotAvancementSuccess = createAction(
  '[OrganizationStratalotAvancement] Delete One OrganizationStratalotAvancement Success',
  props<{ idOrganizationStratalotAvancement: number }>()
);

export const normalizeManyOrganizationStratalotAvancementsAfterUpsert = createAction(
  '[OrganizationStratalotAvancement] Normalize Many OrganizationStratalotAvancements After Upsert',
  props<{ organizationStratalotAvancements: OrganizationStratalotAvancementEntityState[] }>()
);

export const organizationStratalotAvancementsFailure = createAction(
  '[OrganizationStratalotAvancement] OrganizationStratalotAvancements Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationStratalotAvancement] Clear OrganizationStratalotAvancements');

export const addManyStratalotSuccess = createAction(
  '[OrganizationStratalotAvancement] Add Many stratalot',
  props<{ idOrganizationStratalotAvancement: number; idStratalots: number[] }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[OrganizationStratalotAvancement] Delete Many Stratalots',
  props<{ idStratalots: number[]; idOrganizationStratalotAvancements: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[OrganizationStratalotAvancement] Add Organization',
  props<{ idOrganizationStratalotAvancement: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationStratalotAvancement] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationStratalotAvancements: number[] }>()
);

export const addStepSuccess = createAction(
  '[OrganizationStratalotAvancement] Add Step',
  props<{ idOrganizationStratalotAvancement: number; idStep: number }>()
);

export const deleteManyStepSuccess = createAction(
  '[OrganizationStratalotAvancement] Delete Many Step',
  props<{ idSteps: number[]; idOrganizationStratalotAvancements: number[] }>()
);
