import { initialState, ResidenceForecastState } from './residence-forecast.state';
import { Action, createReducer } from '@ngrx/store';
import { residenceForecastReducersGeneratedFunctions } from './residence-forecast-generated.reducer';

const residenceForecastReducersFunctions = [...residenceForecastReducersGeneratedFunctions];

const residenceForecastReducer = createReducer(initialState, ...residenceForecastReducersFunctions);

export function reducer(state: ResidenceForecastState | undefined, action: Action) {
  return residenceForecastReducer(state, action);
}
