import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationResidenceTodoEntityState } from '@_model/interfaces/organization-residence-todo.model';

export interface OrganizationResidenceTodoState extends EntityState<OrganizationResidenceTodoEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationResidenceTodoEntityState> =
  createEntityAdapter<OrganizationResidenceTodoEntityState>({
    selectId: o => o.idOrganizationResidenceTodo
  });
export const initialState: OrganizationResidenceTodoState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationResidenceTodoFeatureKey = 'organizationResidenceTodo';
