import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Droit, DroitEntityState } from '@_model/interfaces/droit.model';
import { DroitRelationsIds } from './droit-generated.effects';

export const getOneDroit = createAction('[Droit] Get One Droit', props<{ idDroit: number; params?: any }>());

export const getManyDroits = createAction('[Droit] Get Many Droits', props<{ params: any }>());

export const addManyActives = createAction('[Droit] Add Many Actives Droit', props<{ idDroits: number[] }>());

export const deleteOneActive = createAction('[Droit] Delete One Active Droit', props<{ idDroit: number }>());

export const clearActive = createAction('[Droit] Clear Active Droit');

export const upsertOneDroit = createAction(
  '[Droit] Upsert One Droit',
  props<{
    droit: Partial<Droit>;
    ids?: DroitRelationsIds;
  }>()
);

export const upsertManyDroits = createAction(
  '[Droit] Upsert Many Droits',
  props<{
    droits: Partial<Droit>[];
    ids?: DroitRelationsIds;
  }>()
);

export const upsertManyDroitsSuccess = createAction(
  '[Droit] Create Many Droits Success',
  props<{ droits: DroitEntityState[] }>()
);

export const deleteOneDroit = createAction('[Droit] Delete One Droit', props<{ idDroit: number }>());

export const deleteOneDroitSuccess = createAction('[Droit] Delete One Droit Success', props<{ idDroit: number }>());

export const normalizeManyDroitsAfterUpsert = createAction(
  '[Droit] Normalize Many Droits After Upsert',
  props<{ droits: DroitEntityState[] }>()
);

export const droitsFailure = createAction('[Droit] Droits Failure', props<{ error: HttpErrorResponse }>());

export const clearStore = createAction('[Droit] Clear Droits');

export const addManyProfilSuccess = createAction(
  '[Droit] Add Many profil',
  props<{ idDroit: number; idProfils: number[] }>()
);

export const deleteManyProfilSuccess = createAction(
  '[Droit] Delete Many Profils',
  props<{ idProfils: number[]; idDroits: number[] }>()
);
