import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedStratalotTypeApiService } from './stratalot-type-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class StratalotTypeApiService extends GeneratedStratalotTypeApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
