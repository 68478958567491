import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidenceForecastActions from './residence-forecast.actions';
import { adapter, initialState, ResidenceForecastState } from './residence-forecast.state';
import { Dictionary } from '@ngrx/entity';
import { ResidenceForecastEntityState } from '@_model/interfaces/residence-forecast.model';

export const residenceForecastReducersGeneratedFunctions: ReducerTypes<
  ResidenceForecastState,
  readonly ActionCreator[]
>[] = [
  on(ResidenceForecastActions.getOneResidenceForecast, (state: ResidenceForecastState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceForecastActions.getManyResidenceForecasts, (state: ResidenceForecastState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceForecastActions.upsertOneResidenceForecast, (state: ResidenceForecastState) =>
    setLoadingsState(state, true)
  ),

  on(
    ResidenceForecastActions.upsertManyResidenceForecastsSuccess,
    (state: ResidenceForecastState, { residenceForecasts }) =>
      adapter.upsertMany(residenceForecasts, setLoadingsState(state, false))
  ),
  on(ResidenceForecastActions.deleteOneResidenceForecast, (state: ResidenceForecastState) =>
    setLoadingsState(state, true)
  ),
  on(
    ResidenceForecastActions.deleteOneResidenceForecastSuccess,
    (state: ResidenceForecastState, { idResidenceForecast }) =>
      adapter.removeOne(idResidenceForecast, setLoadingsState(state, false))
  ),
  on(ResidenceForecastActions.clearActive, (state: ResidenceForecastState) => ({ ...state, actives: [] })),
  on(ResidenceForecastActions.addManyActives, (state: ResidenceForecastState, { idResidenceForecasts }) => ({
    ...state,
    actives: state.actives.concat(idResidenceForecasts)
  })),
  on(ResidenceForecastActions.deleteOneActive, (state: ResidenceForecastState, { idResidenceForecast }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idResidenceForecast)
  })),

  on(ResidenceForecastActions.clearStore, () => initialState),

  on(
    ResidenceForecastActions.addManyResidenceForecastValueSuccess,
    (state: ResidenceForecastState, { idResidenceForecast, idResidenceForecastValues }) => {
      if (!state.entities[idResidenceForecast]) {
        return state;
      }
      const residenceForecastValues = (state.entities[idResidenceForecast]?.residenceForecastValues as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceForecast]: {
            ...state.entities[idResidenceForecast],
            residenceForecastValues: residenceForecastValues.concat(
              idResidenceForecastValues.filter(id => residenceForecastValues.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidenceForecastActions.deleteManyResidenceForecastValueSuccess,
    (state: ResidenceForecastState, { idResidenceForecastValues, idResidenceForecasts }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceForecasts.reduce((entities, idResidenceForecast) => {
            if (!state.entities[idResidenceForecast]?.residenceForecastValues) {
              return entities;
            }
            entities[idResidenceForecast] = {
              ...state.entities[idResidenceForecast],
              residenceForecastValues: (
                state.entities[idResidenceForecast]?.residenceForecastValues as number[]
              )?.filter(
                (idResidenceForecastValue: number) =>
                  !idResidenceForecastValues.some((id: number) => id === idResidenceForecastValue)
              )
            } as ResidenceForecastEntityState;
            return entities;
          }, {} as Dictionary<ResidenceForecastEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceForecastActions.addResidenceSuccess,
    (state: ResidenceForecastState, { idResidenceForecast, idResidence }) => {
      if (!state.entities[idResidenceForecast]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceForecast]: {
            ...state.entities[idResidenceForecast],
            residence: idResidence
          }
        }
      };
    }
  ),

  on(
    ResidenceForecastActions.deleteManyResidenceSuccess,
    (state: ResidenceForecastState, { idResidences, idResidenceForecasts }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceForecasts.reduce((entities, idResidenceForecast) => {
            if (!state.entities[idResidenceForecast]?.residence) {
              return entities;
            }
            entities[idResidenceForecast] = {
              ...state.entities[idResidenceForecast],
              residence: idResidences.some(
                (idResidence: number) => idResidence === state.entities[idResidenceForecast]?.residence
              )
                ? undefined
                : state.entities[idResidenceForecast]?.residence
            } as ResidenceForecastEntityState;
            return entities;
          }, {} as Dictionary<ResidenceForecastEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceForecastActions.addForecastPeriodSuccess,
    (state: ResidenceForecastState, { idResidenceForecast, idForecastPeriod }) => {
      if (!state.entities[idResidenceForecast]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceForecast]: {
            ...state.entities[idResidenceForecast],
            forecastPeriod: idForecastPeriod
          }
        }
      };
    }
  ),

  on(
    ResidenceForecastActions.deleteManyForecastPeriodSuccess,
    (state: ResidenceForecastState, { idForecastPeriods, idResidenceForecasts }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceForecasts.reduce((entities, idResidenceForecast) => {
            if (!state.entities[idResidenceForecast]?.forecastPeriod) {
              return entities;
            }
            entities[idResidenceForecast] = {
              ...state.entities[idResidenceForecast],
              forecastPeriod: idForecastPeriods.some(
                (idForecastPeriod: number) => idForecastPeriod === state.entities[idResidenceForecast]?.forecastPeriod
              )
                ? undefined
                : state.entities[idResidenceForecast]?.forecastPeriod
            } as ResidenceForecastEntityState;
            return entities;
          }, {} as Dictionary<ResidenceForecastEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidenceForecastState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidenceForecastState {
  return { ...state, isLoaded, isLoading };
}
