import { RepositoryService } from '@_services/api/repository.service';
import { CompanyCommunicationMedia } from '@_model/interfaces/company-communication-media.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyCommunicationMediaApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyCommunicationMedias(params?: any): Observable<CompanyCommunicationMedia[]> {
    return this.repo.getData<CompanyCommunicationMedia[]>('company-communication-media', params);
  }

  public getCompanyCommunicationMedia(params: {
    idCompanyCommunicationMedia: number;
    params?: any;
  }): Observable<CompanyCommunicationMedia> {
    return this.repo.getData<CompanyCommunicationMedia>(
      'company-communication-media/' + params.idCompanyCommunicationMedia,
      params.params
    );
  }

  public addCompanyCommunicationMedia(
    companyCommunicationMedia: Partial<CompanyCommunicationMedia>
  ): Observable<CompanyCommunicationMedia> {
    return this.repo.create<CompanyCommunicationMedia>('company-communication-media', companyCommunicationMedia);
  }

  public updateCompanyCommunicationMedia(
    companyCommunicationMedia: Partial<CompanyCommunicationMedia>
  ): Observable<CompanyCommunicationMedia> {
    return this.repo.update('company-communication-media', companyCommunicationMedia);
  }

  public deleteCompanyCommunicationMedia(idCompanyCommunicationMedia: number): Observable<number> {
    return this.repo.delete('company-communication-media/' + +idCompanyCommunicationMedia);
  }
}
