import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StratalotPriceValue, StratalotPriceValueEntityState } from '@_model/interfaces/stratalot-price-value.model';
import { CompanyPriceLabel, CompanyPriceLabelEntityState } from '@_model/interfaces/company-price-label.model';
import { StratalotPrice, StratalotPriceEntityState } from '@_model/interfaces/stratalot-price.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, stratalotPriceValueFeatureKey, StratalotPriceValueState } from './stratalot-price-value.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const stratalotPriceValueRelations: string[] = ['companyPriceLabels', 'stratalotPrices'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectStratalotPriceValueState =
  createFeatureSelector<StratalotPriceValueState>(stratalotPriceValueFeatureKey);

export const selectIsLoadedStratalotPriceValue = createSelector(
  selectStratalotPriceValueState,
  (state: StratalotPriceValueState) => state.isLoaded
);

export const selectIsLoadingStratalotPriceValue = createSelector(
  selectStratalotPriceValueState,
  (state: StratalotPriceValueState) => state.isLoading
);

export const selectIsReadyStratalotPriceValue = createSelector(
  selectStratalotPriceValueState,
  (state: StratalotPriceValueState) => !state.isLoading
);

export const selectIsReadyAndLoadedStratalotPriceValue = createSelector(
  selectStratalotPriceValueState,
  (state: StratalotPriceValueState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const StratalotPriceValueModel: SelectorModel = {
  name: 'stratalotPriceValues',
  getSelector: selectAllStratalotPriceValuesDictionary,
  isReady: selectIsReadyStratalotPriceValue
};

export const selectStratalotPriceValuesEntities = createSelector(selectStratalotPriceValueState, selectEntities);

export const selectStratalotPriceValuesArray = createSelector(selectStratalotPriceValueState, selectAll);

export const selectIdStratalotPriceValuesActive = createSelector(
  selectStratalotPriceValueState,
  (state: StratalotPriceValueState) => state.actives
);

const stratalotPriceValuesInObject = (stratalotPriceValues: Dictionary<StratalotPriceValueEntityState>) => ({
  stratalotPriceValues
});

const selectStratalotPriceValuesEntitiesDictionary = createSelector(
  selectStratalotPriceValuesEntities,
  stratalotPriceValuesInObject
);

const selectAllStratalotPriceValuesObject = createSelector(selectStratalotPriceValuesEntities, stratalotPriceValues => {
  return hydrateAll({ stratalotPriceValues });
});

const selectOneStratalotPriceValueDictionary = (idStratalotPriceValue: number) =>
  createSelector(selectStratalotPriceValuesEntities, stratalotPriceValues => {
    return { stratalotPriceValues: { [idStratalotPriceValue]: stratalotPriceValues[idStratalotPriceValue] } };
  });

const selectOneStratalotPriceValueDictionaryWithoutChild = (idStratalotPriceValue: number) =>
  createSelector(selectStratalotPriceValuesEntities, stratalotPriceValues => {
    return { stratalotPriceValue: stratalotPriceValues[idStratalotPriceValue] };
  });

const selectActiveStratalotPriceValuesEntities = createSelector(
  selectIdStratalotPriceValuesActive,
  selectStratalotPriceValuesEntities,
  (actives: number[], stratalotPriceValues: Dictionary<StratalotPriceValueEntityState>) =>
    getStratalotPriceValuesFromActives(actives, stratalotPriceValues)
);

function getStratalotPriceValuesFromActives(
  actives: number[],
  stratalotPriceValues: Dictionary<StratalotPriceValueEntityState>
): Dictionary<StratalotPriceValueEntityState> {
  return actives.reduce((acc, idActive) => {
    if (stratalotPriceValues[idActive]) {
      acc[idActive] = stratalotPriceValues[idActive];
    }
    return acc;
  }, {} as Dictionary<StratalotPriceValueEntityState>);
}

const selectAllStratalotPriceValuesSelectors: Dictionary<Selector> = {};
export function selectAllStratalotPriceValues(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<StratalotPriceValue>(
      schema,
      selectAllStratalotPriceValuesSelectors,
      selectStratalotPriceValuesEntitiesDictionary,
      getRelationSelectors,
      stratalotPriceValueRelations,
      hydrateAll,
      'stratalotPriceValue'
    );
  } else {
    return selectAllStratalotPriceValuesObject;
  }
}

export function selectAllStratalotPriceValuesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'stratalotPriceValues'
): Selector {
  return createSelector(selectAllStratalotPriceValues(schema), result => {
    const res = { [customKey]: {} as Dictionary<StratalotPriceValueEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.stratalotPriceValues.length; i++) {
      res[customKey][result.stratalotPriceValues[i].idStratalotPriceValue] = result.stratalotPriceValues[i];
    }
    return res;
  });
}

export function selectOneStratalotPriceValue(schema: SelectSchema = {}, idStratalotPriceValue: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneStratalotPriceValueDictionary(idStratalotPriceValue)];
    selectors.push(...getRelationSelectors(schema, stratalotPriceValueRelations, 'stratalotPriceValue'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneStratalotPriceValueDictionaryWithoutChild(idStratalotPriceValue);
  }
}

export function selectActiveStratalotPriceValues(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveStratalotPriceValuesEntities, stratalotPriceValues => ({ stratalotPriceValues }))
  ];
  selectors.push(...getRelationSelectors(schema, stratalotPriceValueRelations, 'stratalotPriceValue'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  stratalotPriceValues: Dictionary<StratalotPriceValueEntityState>;
  companyPriceLabels?: Dictionary<CompanyPriceLabelEntityState>;
  stratalotPrices?: Dictionary<StratalotPriceEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { stratalotPriceValues: (StratalotPriceValue | null)[] } {
  const { stratalotPriceValues, companyPriceLabels, stratalotPrices } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    stratalotPriceValues: Object.keys(stratalotPriceValues).map(idStratalotPriceValue =>
      hydrate(
        stratalotPriceValues[idStratalotPriceValue] as StratalotPriceValueEntityState,
        companyPriceLabels,
        stratalotPrices
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { stratalotPriceValue: StratalotPriceValueEntityState | null } {
  const { stratalotPriceValues, companyPriceLabels, stratalotPrices } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const stratalotPriceValue = Object.values(stratalotPriceValues)[0];
  return {
    stratalotPriceValue: hydrate(
      stratalotPriceValue as StratalotPriceValueEntityState,
      companyPriceLabels,
      stratalotPrices
    )
  };
}

function hydrate(
  stratalotPriceValue: StratalotPriceValueEntityState,
  companyPriceLabelEntities?: Dictionary<CompanyPriceLabelEntityState>,
  stratalotPriceEntities?: Dictionary<StratalotPriceEntityState>
): StratalotPriceValue | null {
  if (!stratalotPriceValue) {
    return null;
  }

  const stratalotPriceValueHydrated: StratalotPriceValueEntityState = { ...stratalotPriceValue };
  if (companyPriceLabelEntities) {
    stratalotPriceValueHydrated.companyPriceLabel = companyPriceLabelEntities[
      stratalotPriceValue.companyPriceLabel as number
    ] as CompanyPriceLabel;
  } else {
    delete stratalotPriceValueHydrated.companyPriceLabel;
  }
  if (stratalotPriceEntities) {
    stratalotPriceValueHydrated.stratalotPrice = stratalotPriceEntities[
      stratalotPriceValue.stratalotPrice as number
    ] as StratalotPrice;
  } else {
    delete stratalotPriceValueHydrated.stratalotPrice;
  }

  return stratalotPriceValueHydrated as StratalotPriceValue;
}
