import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StepProgressFamilyApiService } from '@_services/api/step-progress-family-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedStepProgressFamilyEffects } from './step-progress-family-generated.effects';

@Injectable()
export class StepProgressFamilyEffects extends GeneratedStepProgressFamilyEffects {
  constructor(actions$: Actions, stepProgressFamilyApiService: StepProgressFamilyApiService, store$: Store<AppState>) {
    super(actions$, stepProgressFamilyApiService, store$);
  }
}
