import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedResidenceStudyReasonApiService } from './residence-study-reason-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ResidenceStudyReasonApiService extends GeneratedResidenceStudyReasonApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
