import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationLeadAvancement,
  OrganizationLeadAvancementEntityState
} from '@_model/interfaces/organization-lead-avancement.model';
import { OrganizationLeadAvancementRelationsIds } from './organization-lead-avancement-generated.effects';

export const getOneOrganizationLeadAvancement = createAction(
  '[OrganizationLeadAvancement] Get One OrganizationLeadAvancement',
  props<{ idOrganizationLeadAvancement: number; params?: any }>()
);

export const getManyOrganizationLeadAvancements = createAction(
  '[OrganizationLeadAvancement] Get Many OrganizationLeadAvancements',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationLeadAvancement] Add Many Actives OrganizationLeadAvancement',
  props<{ idOrganizationLeadAvancements: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationLeadAvancement] Delete One Active OrganizationLeadAvancement',
  props<{ idOrganizationLeadAvancement: number }>()
);

export const clearActive = createAction('[OrganizationLeadAvancement] Clear Active OrganizationLeadAvancement');

export const upsertOneOrganizationLeadAvancement = createAction(
  '[OrganizationLeadAvancement] Upsert One OrganizationLeadAvancement',
  props<{
    organizationLeadAvancement: Partial<OrganizationLeadAvancement>;
    ids?: OrganizationLeadAvancementRelationsIds;
  }>()
);

export const upsertManyOrganizationLeadAvancements = createAction(
  '[OrganizationLeadAvancement] Upsert Many OrganizationLeadAvancements',
  props<{
    organizationLeadAvancements: Partial<OrganizationLeadAvancement>[];
    ids?: OrganizationLeadAvancementRelationsIds;
  }>()
);

export const upsertManyOrganizationLeadAvancementsSuccess = createAction(
  '[OrganizationLeadAvancement] Create Many OrganizationLeadAvancements Success',
  props<{ organizationLeadAvancements: OrganizationLeadAvancementEntityState[] }>()
);

export const deleteOneOrganizationLeadAvancement = createAction(
  '[OrganizationLeadAvancement] Delete One OrganizationLeadAvancement',
  props<{ idOrganizationLeadAvancement: number }>()
);

export const deleteOneOrganizationLeadAvancementSuccess = createAction(
  '[OrganizationLeadAvancement] Delete One OrganizationLeadAvancement Success',
  props<{ idOrganizationLeadAvancement: number }>()
);

export const normalizeManyOrganizationLeadAvancementsAfterUpsert = createAction(
  '[OrganizationLeadAvancement] Normalize Many OrganizationLeadAvancements After Upsert',
  props<{ organizationLeadAvancements: OrganizationLeadAvancementEntityState[] }>()
);

export const organizationLeadAvancementsFailure = createAction(
  '[OrganizationLeadAvancement] OrganizationLeadAvancements Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationLeadAvancement] Clear OrganizationLeadAvancements');

export const addManyLeadSuccess = createAction(
  '[OrganizationLeadAvancement] Add Many lead',
  props<{ idOrganizationLeadAvancement: number; idLeads: number[] }>()
);

export const deleteManyLeadSuccess = createAction(
  '[OrganizationLeadAvancement] Delete Many Leads',
  props<{ idLeads: number[]; idOrganizationLeadAvancements: number[] }>()
);

export const addStepSuccess = createAction(
  '[OrganizationLeadAvancement] Add Step',
  props<{ idOrganizationLeadAvancement: number; idStep: number }>()
);

export const deleteManyStepSuccess = createAction(
  '[OrganizationLeadAvancement] Delete Many Step',
  props<{ idSteps: number[]; idOrganizationLeadAvancements: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[OrganizationLeadAvancement] Add Organization',
  props<{ idOrganizationLeadAvancement: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationLeadAvancement] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationLeadAvancements: number[] }>()
);
