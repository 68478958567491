import { CompanyState, initialState } from './company.state';
import { Action, createReducer } from '@ngrx/store';
import { companyReducersGeneratedFunctions } from './company-generated.reducer';

const companyReducersFunctions = [...companyReducersGeneratedFunctions];

const companyReducer = createReducer(initialState, ...companyReducersFunctions);

export function reducer(state: CompanyState | undefined, action: Action) {
  return companyReducer(state, action);
}
