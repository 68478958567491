import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceShare } from '@_model/interfaces/residence-share.model';
import { Observable } from 'rxjs';

export class GeneratedResidenceShareApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidenceShares(params?: any): Observable<ResidenceShare[]> {
    return this.repo.getData<ResidenceShare[]>('residence-share', params);
  }

  public getResidenceShare(params: { idResidenceShare: number; params?: any }): Observable<ResidenceShare> {
    return this.repo.getData<ResidenceShare>('residence-share/' + params.idResidenceShare, params.params);
  }

  public addResidenceShare(residenceShare: Partial<ResidenceShare>): Observable<ResidenceShare> {
    return this.repo.create<ResidenceShare>('residence-share', residenceShare);
  }

  public updateResidenceShare(residenceShare: Partial<ResidenceShare>): Observable<ResidenceShare> {
    return this.repo.update('residence-share', residenceShare);
  }

  public deleteResidenceShare(idResidenceShare: number): Observable<number> {
    return this.repo.delete('residence-share/' + +idResidenceShare);
  }
}
