export enum ModulePathKeyEnum {
  admin = 'admin',
  dashboard = 'dashboard',
  salePlan = 'salePlan',
  residenceForSale = 'residenceForSale',
  occupedStratalots = 'occupedStratalots',
  freeStratalots = 'freeStratalots',
  prospects = 'prospects',
  validateOffer = 'validateOffer',
  salesInProgress = 'salesInProgress',
  user = 'user',
  programCommercialisation = 'programCommercialisation',
  study = 'study'
}
