import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { OrganizationSociete, OrganizationSocieteEntityState } from '@_model/interfaces/organization-societe.model';
import { OrganizationSocieteRelationsIds } from './organization-societe-generated.effects';

export const getOneOrganizationSociete = createAction(
  '[OrganizationSociete] Get One OrganizationSociete',
  props<{ idOrganizationSociete: number; params?: any }>()
);

export const getManyOrganizationSocietes = createAction(
  '[OrganizationSociete] Get Many OrganizationSocietes',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationSociete] Add Many Actives OrganizationSociete',
  props<{ idOrganizationSocietes: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationSociete] Delete One Active OrganizationSociete',
  props<{ idOrganizationSociete: number }>()
);

export const clearActive = createAction('[OrganizationSociete] Clear Active OrganizationSociete');

export const upsertOneOrganizationSociete = createAction(
  '[OrganizationSociete] Upsert One OrganizationSociete',
  props<{
    organizationSociete: Partial<OrganizationSociete>;
    ids?: OrganizationSocieteRelationsIds;
  }>()
);

export const upsertManyOrganizationSocietes = createAction(
  '[OrganizationSociete] Upsert Many OrganizationSocietes',
  props<{
    organizationSocietes: Partial<OrganizationSociete>[];
    ids?: OrganizationSocieteRelationsIds;
  }>()
);

export const upsertManyOrganizationSocietesSuccess = createAction(
  '[OrganizationSociete] Create Many OrganizationSocietes Success',
  props<{ organizationSocietes: OrganizationSocieteEntityState[] }>()
);

export const deleteOneOrganizationSociete = createAction(
  '[OrganizationSociete] Delete One OrganizationSociete',
  props<{ idOrganizationSociete: number }>()
);

export const deleteOneOrganizationSocieteSuccess = createAction(
  '[OrganizationSociete] Delete One OrganizationSociete Success',
  props<{ idOrganizationSociete: number }>()
);

export const normalizeManyOrganizationSocietesAfterUpsert = createAction(
  '[OrganizationSociete] Normalize Many OrganizationSocietes After Upsert',
  props<{ organizationSocietes: OrganizationSocieteEntityState[] }>()
);

export const organizationSocietesFailure = createAction(
  '[OrganizationSociete] OrganizationSocietes Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationSociete] Clear OrganizationSocietes');

export const addManyOrganizationThirdPartySuccess = createAction(
  '[OrganizationSociete] Add Many organization-third-party',
  props<{ idOrganizationSociete: number; idOrganizationThirdParties: number[] }>()
);

export const deleteManyOrganizationThirdPartySuccess = createAction(
  '[OrganizationSociete] Delete Many OrganizationThirdParties',
  props<{ idOrganizationThirdParties: number[]; idOrganizationSocietes: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[OrganizationSociete] Add Organization',
  props<{ idOrganizationSociete: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationSociete] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationSocietes: number[] }>()
);
