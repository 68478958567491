import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CityEntityState } from '@_model/interfaces/city.model';

export interface CityState extends EntityState<CityEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CityEntityState> = createEntityAdapter<CityEntityState>({
  selectId: o => o.idCity
});
export const initialState: CityState = adapter.getInitialState({ isLoaded: false, isLoading: false, actives: [] });

export const cityFeatureKey = 'city';
