import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationBuyingWish,
  OrganizationBuyingWishEntityState
} from '@_model/interfaces/organization-buying-wish.model';
import { OrganizationBuyingWishApiService } from '@_services/api/organization-buying-wish-api.service';
import * as organizationBuyingWishActions from '@_store/organization-buying-wish/organization-buying-wish.actions';
import { getActionsToNormalizeOrganizationBuyingWish } from '@_config/store/normalization.generated';
import { selectOrganizationBuyingWishState } from './organization-buying-wish-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as prospectBuyingWishActions from '@_store/prospect-buying-wish/prospect-buying-wish.actions';
import { ProspectBuyingWish } from '@_model/interfaces/prospect-buying-wish.model';

export interface OrganizationBuyingWishRelationsIds {
  prospectBuyingWishs?: number | number[];
  organization?: number;
}

export function getDefaultAddOrganizationBuyingWishActions(
  organizationBuyingWish: OrganizationBuyingWishEntityState,
  ids?: OrganizationBuyingWishRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationBuyingWishActions.normalizeManyOrganizationBuyingWishesAfterUpsert({
      organizationBuyingWishes: [organizationBuyingWish]
    })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationBuyingWishSuccess({
        idOrganization: ids.organization,
        idOrganizationBuyingWishes: [organizationBuyingWish.idOrganizationBuyingWish]
      })
    );
    actions.push(
      organizationBuyingWishActions.addOrganizationSuccess({
        idOrganizationBuyingWish: organizationBuyingWish.idOrganizationBuyingWish,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.prospectBuyingWishs) {
    if (!Array.isArray(ids.prospectBuyingWishs)) {
      actions.push(
        prospectBuyingWishActions.upsertOneProspectBuyingWish({
          prospectBuyingWish: {
            idOrganizationBuyingWish: organizationBuyingWish.idOrganizationBuyingWish,
            idProspectBuyingWish: ids.prospectBuyingWishs as number
          } as ProspectBuyingWish
        })
      );
      actions.push(
        organizationBuyingWishActions.addManyProspectBuyingWishSuccess({
          idOrganizationBuyingWish: organizationBuyingWish.idOrganizationBuyingWish,
          idProspectBuyingWishs: [ids.prospectBuyingWishs as number]
        })
      );
    } else {
      actions.push(
        prospectBuyingWishActions.upsertManyProspectBuyingWishs({
          prospectBuyingWishs: (ids.prospectBuyingWishs as number[]).map((idProspectBuyingWish: number) => ({
            idOrganizationBuyingWish: organizationBuyingWish.idOrganizationBuyingWish,
            idProspectBuyingWish
          })) as ProspectBuyingWish[]
        })
      );
      actions.push(
        organizationBuyingWishActions.addManyProspectBuyingWishSuccess({
          idOrganizationBuyingWish: organizationBuyingWish.idOrganizationBuyingWish,
          idProspectBuyingWishs: ids.prospectBuyingWishs as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationBuyingWishActions(
  organizationBuyingWish: OrganizationBuyingWishEntityState
): Action[] {
  const actions: Action[] = [
    organizationBuyingWishActions.deleteOneOrganizationBuyingWishSuccess({
      idOrganizationBuyingWish: organizationBuyingWish.idOrganizationBuyingWish
    })
  ];

  if (organizationBuyingWish.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationBuyingWishSuccess({
        idOrganizationBuyingWishes: [organizationBuyingWish.idOrganizationBuyingWish],
        idOrganizations: [organizationBuyingWish.organization as number]
      })
    );
  }

  if (organizationBuyingWish.prospectBuyingWishs) {
    actions.push(
      prospectBuyingWishActions.deleteManyOrganizationBuyingWishSuccess({
        idOrganizationBuyingWishes: [organizationBuyingWish.idOrganizationBuyingWish],
        idProspectBuyingWishs: organizationBuyingWish.prospectBuyingWishs as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationBuyingWishEffects {
  constructor(
    protected actions$: Actions,
    protected organizationBuyingWishApiService: OrganizationBuyingWishApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationBuyingWishes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationBuyingWishActions.getManyOrganizationBuyingWishes),
      switchMap(({ params }) =>
        this.organizationBuyingWishApiService.getOrganizationBuyingWishes(params).pipe(
          map((organizationBuyingWishes: OrganizationBuyingWish[]) => {
            return organizationBuyingWishActions.normalizeManyOrganizationBuyingWishesAfterUpsert({
              organizationBuyingWishes
            });
          }),
          catchError(error => of(organizationBuyingWishActions.organizationBuyingWishesFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationBuyingWish$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationBuyingWishActions.getOneOrganizationBuyingWish),
      switchMap(idOrganizationBuyingWish =>
        this.organizationBuyingWishApiService.getOrganizationBuyingWish(idOrganizationBuyingWish).pipe(
          map((organizationBuyingWish: OrganizationBuyingWish) => {
            return organizationBuyingWishActions.normalizeManyOrganizationBuyingWishesAfterUpsert({
              organizationBuyingWishes: [organizationBuyingWish]
            });
          }),
          catchError(error => of(organizationBuyingWishActions.organizationBuyingWishesFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationBuyingWish$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationBuyingWishActions.upsertOneOrganizationBuyingWish),
      concatMap(
        ({
          organizationBuyingWish,
          ids
        }: {
          organizationBuyingWish: Partial<OrganizationBuyingWish>;
          ids?: OrganizationBuyingWishRelationsIds;
        }) => {
          if (organizationBuyingWish.idOrganizationBuyingWish) {
            return this.organizationBuyingWishApiService.updateOrganizationBuyingWish(organizationBuyingWish).pipe(
              map((organizationBuyingWishReturned: OrganizationBuyingWish) => {
                return organizationBuyingWishActions.normalizeManyOrganizationBuyingWishesAfterUpsert({
                  organizationBuyingWishes: [organizationBuyingWishReturned]
                });
              }),
              catchError(error => of(organizationBuyingWishActions.organizationBuyingWishesFailure({ error })))
            );
          } else {
            return this.organizationBuyingWishApiService.addOrganizationBuyingWish(organizationBuyingWish).pipe(
              mergeMap((organizationBuyingWishReturned: OrganizationBuyingWish) =>
                getDefaultAddOrganizationBuyingWishActions(organizationBuyingWishReturned, ids)
              ),
              catchError(error => of(organizationBuyingWishActions.organizationBuyingWishesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationBuyingWish$ = createEffect(() => {
    const selectOrganizationBuyingWishState$ = this.store$.select(selectOrganizationBuyingWishState);
    return this.actions$.pipe(
      ofType(organizationBuyingWishActions.deleteOneOrganizationBuyingWish),
      withLatestFrom(selectOrganizationBuyingWishState$),
      concatMap(([{ idOrganizationBuyingWish }, state]) =>
        this.organizationBuyingWishApiService.deleteOrganizationBuyingWish(idOrganizationBuyingWish).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOrganizationBuyingWishActions(
                state.entities[idOrganizationBuyingWish] as OrganizationBuyingWishEntityState
              ),
              organizationBuyingWishActions.deleteOneOrganizationBuyingWish.type
            )
          ]),
          catchError(error => of(organizationBuyingWishActions.organizationBuyingWishesFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationBuyingWishesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationBuyingWishActions.normalizeManyOrganizationBuyingWishesAfterUpsert),
      concatMap(({ organizationBuyingWishes }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationBuyingWish(
          organizationBuyingWishes,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationBuyingWish] Normalization After Upsert Success')];
      })
    );
  });
}
