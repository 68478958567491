import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StratalotPriceEntityState } from '@_model/interfaces/stratalot-price.model';

export interface StratalotPriceState extends EntityState<StratalotPriceEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<StratalotPriceEntityState> = createEntityAdapter<StratalotPriceEntityState>({
  selectId: o => o.idStratalotPrice
});
export const initialState: StratalotPriceState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const stratalotPriceFeatureKey = 'stratalotPrice';
