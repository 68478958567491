import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyMediaApiService } from '@_services/api/company-media-api.service';
import { GeneratedCompanyMediaEffects } from './company-media-generated.effects';

@Injectable()
export class CompanyMediaEffects extends GeneratedCompanyMediaEffects {
  constructor(actions$: Actions, companyMediaApiService: CompanyMediaApiService, store$: Store<AppState>) {
    super(actions$, companyMediaApiService, store$);
  }
}
