import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationSocieteEntityState } from '@_model/interfaces/organization-societe.model';

export interface OrganizationSocieteState extends EntityState<OrganizationSocieteEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationSocieteEntityState> =
  createEntityAdapter<OrganizationSocieteEntityState>({
    selectId: o => o.idOrganizationSociete
  });
export const initialState: OrganizationSocieteState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationSocieteFeatureKey = 'organizationSociete';
