import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationStratalotTodoRuleEntityState } from '@_model/interfaces/organization-stratalot-todo-rule.model';

export interface OrganizationStratalotTodoRuleState extends EntityState<OrganizationStratalotTodoRuleEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationStratalotTodoRuleEntityState> =
  createEntityAdapter<OrganizationStratalotTodoRuleEntityState>({
    selectId: o => o.idOrganizationStratalotTodoRule
  });
export const initialState: OrganizationStratalotTodoRuleState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationStratalotTodoRuleFeatureKey = 'organizationStratalotTodoRule';
