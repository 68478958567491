import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedStratalotTodoApiService } from './stratalot-todo-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class StratalotTodoApiService extends GeneratedStratalotTodoApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
