import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StratalotAssociationApiService } from '@_services/api/stratalot-association-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedStratalotAssociationEffects } from './stratalot-association-generated.effects';

@Injectable()
export class StratalotAssociationEffects extends GeneratedStratalotAssociationEffects {
  constructor(
    actions$: Actions,
    stratalotAssociationApiService: StratalotAssociationApiService,
    store$: Store<AppState>
  ) {
    super(actions$, stratalotAssociationApiService, store$);
  }
}
