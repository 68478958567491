import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationResidenceTodoAppliedRule,
  OrganizationResidenceTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-residence-todo-applied-rule.model';
import {
  OrganizationResidenceTodo,
  OrganizationResidenceTodoEntityState
} from '@_model/interfaces/organization-residence-todo.model';
import {
  OrganizationResidenceTodoRule,
  OrganizationResidenceTodoRuleEntityState
} from '@_model/interfaces/organization-residence-todo-rule.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationResidenceTodoAppliedRuleFeatureKey,
  OrganizationResidenceTodoAppliedRuleState
} from './organization-residence-todo-applied-rule.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationResidenceTodoAppliedRuleRelations: string[] = [
  'organizationResidenceTodos',
  'organizationResidenceTodoRules'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationResidenceTodoAppliedRuleState =
  createFeatureSelector<OrganizationResidenceTodoAppliedRuleState>(organizationResidenceTodoAppliedRuleFeatureKey);

export const selectIsLoadedOrganizationResidenceTodoAppliedRule = createSelector(
  selectOrganizationResidenceTodoAppliedRuleState,
  (state: OrganizationResidenceTodoAppliedRuleState) => state.isLoaded
);

export const selectIsLoadingOrganizationResidenceTodoAppliedRule = createSelector(
  selectOrganizationResidenceTodoAppliedRuleState,
  (state: OrganizationResidenceTodoAppliedRuleState) => state.isLoading
);

export const selectIsReadyOrganizationResidenceTodoAppliedRule = createSelector(
  selectOrganizationResidenceTodoAppliedRuleState,
  (state: OrganizationResidenceTodoAppliedRuleState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationResidenceTodoAppliedRule = createSelector(
  selectOrganizationResidenceTodoAppliedRuleState,
  (state: OrganizationResidenceTodoAppliedRuleState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationResidenceTodoAppliedRuleModel: SelectorModel = {
  name: 'organizationResidenceTodoAppliedRules',
  getSelector: selectAllOrganizationResidenceTodoAppliedRulesDictionary,
  isReady: selectIsReadyOrganizationResidenceTodoAppliedRule
};

export const selectOrganizationResidenceTodoAppliedRulesEntities = createSelector(
  selectOrganizationResidenceTodoAppliedRuleState,
  selectEntities
);

export const selectOrganizationResidenceTodoAppliedRulesArray = createSelector(
  selectOrganizationResidenceTodoAppliedRuleState,
  selectAll
);

export const selectIdOrganizationResidenceTodoAppliedRulesActive = createSelector(
  selectOrganizationResidenceTodoAppliedRuleState,
  (state: OrganizationResidenceTodoAppliedRuleState) => state.actives
);

const organizationResidenceTodoAppliedRulesInObject = (
  organizationResidenceTodoAppliedRules: Dictionary<OrganizationResidenceTodoAppliedRuleEntityState>
) => ({ organizationResidenceTodoAppliedRules });

const selectOrganizationResidenceTodoAppliedRulesEntitiesDictionary = createSelector(
  selectOrganizationResidenceTodoAppliedRulesEntities,
  organizationResidenceTodoAppliedRulesInObject
);

const selectAllOrganizationResidenceTodoAppliedRulesObject = createSelector(
  selectOrganizationResidenceTodoAppliedRulesEntities,
  organizationResidenceTodoAppliedRules => {
    return hydrateAll({ organizationResidenceTodoAppliedRules });
  }
);

const selectOneOrganizationResidenceTodoAppliedRuleDictionary = (idOrganizationResidenceTodoAppliedRule: number) =>
  createSelector(selectOrganizationResidenceTodoAppliedRulesEntities, organizationResidenceTodoAppliedRules => {
    return {
      organizationResidenceTodoAppliedRules: {
        [idOrganizationResidenceTodoAppliedRule]:
          organizationResidenceTodoAppliedRules[idOrganizationResidenceTodoAppliedRule]
      }
    };
  });

const selectOneOrganizationResidenceTodoAppliedRuleDictionaryWithoutChild = (
  idOrganizationResidenceTodoAppliedRule: number
) =>
  createSelector(selectOrganizationResidenceTodoAppliedRulesEntities, organizationResidenceTodoAppliedRules => {
    return {
      organizationResidenceTodoAppliedRule:
        organizationResidenceTodoAppliedRules[idOrganizationResidenceTodoAppliedRule]
    };
  });

const selectActiveOrganizationResidenceTodoAppliedRulesEntities = createSelector(
  selectIdOrganizationResidenceTodoAppliedRulesActive,
  selectOrganizationResidenceTodoAppliedRulesEntities,
  (
    actives: number[],
    organizationResidenceTodoAppliedRules: Dictionary<OrganizationResidenceTodoAppliedRuleEntityState>
  ) => getOrganizationResidenceTodoAppliedRulesFromActives(actives, organizationResidenceTodoAppliedRules)
);

function getOrganizationResidenceTodoAppliedRulesFromActives(
  actives: number[],
  organizationResidenceTodoAppliedRules: Dictionary<OrganizationResidenceTodoAppliedRuleEntityState>
): Dictionary<OrganizationResidenceTodoAppliedRuleEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationResidenceTodoAppliedRules[idActive]) {
      acc[idActive] = organizationResidenceTodoAppliedRules[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationResidenceTodoAppliedRuleEntityState>);
}

const selectAllOrganizationResidenceTodoAppliedRulesSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationResidenceTodoAppliedRules(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationResidenceTodoAppliedRule>(
      schema,
      selectAllOrganizationResidenceTodoAppliedRulesSelectors,
      selectOrganizationResidenceTodoAppliedRulesEntitiesDictionary,
      getRelationSelectors,
      organizationResidenceTodoAppliedRuleRelations,
      hydrateAll,
      'organizationResidenceTodoAppliedRule'
    );
  } else {
    return selectAllOrganizationResidenceTodoAppliedRulesObject;
  }
}

export function selectAllOrganizationResidenceTodoAppliedRulesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationResidenceTodoAppliedRules'
): Selector {
  return createSelector(selectAllOrganizationResidenceTodoAppliedRules(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationResidenceTodoAppliedRuleEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationResidenceTodoAppliedRules.length; i++) {
      res[customKey][result.organizationResidenceTodoAppliedRules[i].idOrganizationResidenceTodoAppliedRule] =
        result.organizationResidenceTodoAppliedRules[i];
    }
    return res;
  });
}

export function selectOneOrganizationResidenceTodoAppliedRule(
  schema: SelectSchema = {},
  idOrganizationResidenceTodoAppliedRule: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [
      selectOneOrganizationResidenceTodoAppliedRuleDictionary(idOrganizationResidenceTodoAppliedRule)
    ];
    selectors.push(
      ...getRelationSelectors(
        schema,
        organizationResidenceTodoAppliedRuleRelations,
        'organizationResidenceTodoAppliedRule'
      )
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationResidenceTodoAppliedRuleDictionaryWithoutChild(idOrganizationResidenceTodoAppliedRule);
  }
}

export function selectActiveOrganizationResidenceTodoAppliedRules(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(
      selectActiveOrganizationResidenceTodoAppliedRulesEntities,
      organizationResidenceTodoAppliedRules => ({ organizationResidenceTodoAppliedRules })
    )
  ];
  selectors.push(
    ...getRelationSelectors(
      schema,
      organizationResidenceTodoAppliedRuleRelations,
      'organizationResidenceTodoAppliedRule'
    )
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationResidenceTodoAppliedRules: Dictionary<OrganizationResidenceTodoAppliedRuleEntityState>;
  organizationResidenceTodos?: Dictionary<OrganizationResidenceTodoEntityState>;
  organizationResidenceTodoRules?: Dictionary<OrganizationResidenceTodoRuleEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationResidenceTodoAppliedRules: (OrganizationResidenceTodoAppliedRule | null)[];
} {
  const { organizationResidenceTodoAppliedRules, organizationResidenceTodos, organizationResidenceTodoRules } =
    args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationResidenceTodoAppliedRules: Object.keys(organizationResidenceTodoAppliedRules).map(
      idOrganizationResidenceTodoAppliedRule =>
        hydrate(
          organizationResidenceTodoAppliedRules[
            idOrganizationResidenceTodoAppliedRule
          ] as OrganizationResidenceTodoAppliedRuleEntityState,
          organizationResidenceTodos,
          organizationResidenceTodoRules
        )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationResidenceTodoAppliedRule: OrganizationResidenceTodoAppliedRuleEntityState | null;
} {
  const { organizationResidenceTodoAppliedRules, organizationResidenceTodos, organizationResidenceTodoRules } =
    args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationResidenceTodoAppliedRule = Object.values(organizationResidenceTodoAppliedRules)[0];
  return {
    organizationResidenceTodoAppliedRule: hydrate(
      organizationResidenceTodoAppliedRule as OrganizationResidenceTodoAppliedRuleEntityState,
      organizationResidenceTodos,
      organizationResidenceTodoRules
    )
  };
}

function hydrate(
  organizationResidenceTodoAppliedRule: OrganizationResidenceTodoAppliedRuleEntityState,
  organizationResidenceTodoEntities?: Dictionary<OrganizationResidenceTodoEntityState>,
  organizationResidenceTodoRuleEntities?: Dictionary<OrganizationResidenceTodoRuleEntityState>
): OrganizationResidenceTodoAppliedRule | null {
  if (!organizationResidenceTodoAppliedRule) {
    return null;
  }

  const organizationResidenceTodoAppliedRuleHydrated: OrganizationResidenceTodoAppliedRuleEntityState = {
    ...organizationResidenceTodoAppliedRule
  };
  if (organizationResidenceTodoEntities) {
    organizationResidenceTodoAppliedRuleHydrated.organizationResidenceTodo = organizationResidenceTodoEntities[
      organizationResidenceTodoAppliedRule.organizationResidenceTodo as number
    ] as OrganizationResidenceTodo;
  } else {
    delete organizationResidenceTodoAppliedRuleHydrated.organizationResidenceTodo;
  }
  if (organizationResidenceTodoRuleEntities) {
    organizationResidenceTodoAppliedRuleHydrated.organizationResidenceTodoRule = organizationResidenceTodoRuleEntities[
      organizationResidenceTodoAppliedRule.organizationResidenceTodoRule as number
    ] as OrganizationResidenceTodoRule;
  } else {
    delete organizationResidenceTodoAppliedRuleHydrated.organizationResidenceTodoRule;
  }

  return organizationResidenceTodoAppliedRuleHydrated as OrganizationResidenceTodoAppliedRule;
}
