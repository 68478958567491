import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ResidencePriceGridApiService } from '@_services/api/residence-price-grid-api.service';
import { ResidencePriceGrid } from '@_shared/models/interfaces/residence-price-grid.model';
import { getMultiAction } from '@_store/batched-actions';
import { AppState } from '@_store/index.reducers';
import * as residencePriceGridActions from '@_store/residence-price-grid/residence-price-grid.actions';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import {
  GeneratedResidencePriceGridEffects,
  getDefaultAddResidencePriceGridActions,
  getDefaultDeleteResidencePriceGridActions,
  ResidencePriceGridRelationsIds
} from './residence-price-grid-generated.effects';
import { selectResidencePriceGridState } from './residence-price-grid-generated.selectors';

@Injectable()
export class ResidencePriceGridEffects extends GeneratedResidencePriceGridEffects {
  constructor(actions$: Actions, residencePriceGridApiService: ResidencePriceGridApiService, store$: Store<AppState>) {
    super(actions$, residencePriceGridApiService, store$);
  }

  generatePriceList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridActions.generatePriceList),
      concatMap(({ params }) =>
        this.residencePriceGridApiService.generatePriceList(params).pipe(
          map((residencePriceGrids: ResidencePriceGrid) => {
            return residencePriceGridActions.normalizeManyResidencePriceGridsAfterUpsert({
              residencePriceGrids: [residencePriceGrids]
            });
          }),
          catchError(error => of(residencePriceGridActions.residencePriceGridsFailure({ error })))
        )
      )
    );
  });

  duplicatePriceGrid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridActions.duplicatePriceGrid),
      concatMap(({ params }) =>
        this.residencePriceGridApiService.duplicatePriceGrid(params).pipe(
          map((residencePriceGrids: ResidencePriceGrid) => {
            return residencePriceGridActions.normalizeManyResidencePriceGridsAfterUpsert({
              residencePriceGrids: [residencePriceGrids]
            });
          }),
          catchError(error => of(residencePriceGridActions.residencePriceGridsFailure({ error })))
        )
      )
    );
  });

  deleteOneResidencePriceGrid$ = createEffect(() => {
    const selectResidencePriceGridState$ = this.store$.select(selectResidencePriceGridState);
    return this.actions$.pipe(
      ofType(residencePriceGridActions.deleteOneResidencePriceGrid),
      withLatestFrom(selectResidencePriceGridState$),
      map(([{ idResidencePriceGrid }, state]) => [
        { idResidencePriceGrid },
        { residencePriceGrid: state.entities[idResidencePriceGrid] }
      ]),
      concatMap(([{ idResidencePriceGrid }, { residencePriceGrid }]) =>
        this.residencePriceGridApiService.deleteResidencePriceGrid(idResidencePriceGrid).pipe(
          mergeMap((success: any) => {
            const actions: Action[] = getDefaultDeleteResidencePriceGridActions(residencePriceGrid);
            if (success.updatedPriceGrid) {
              actions.push(
                residencePriceGridActions.normalizeManyResidencePriceGridsAfterUpsert({
                  residencePriceGrids: [success.updatedPriceGrid]
                })
              );
            }
            return [getMultiAction(actions, residencePriceGridActions.deleteOneResidencePriceGrid.type)];
          }),
          catchError(error => of(residencePriceGridActions.residencePriceGridsFailure({ error })))
        )
      )
    );
  }) as any;

  upsertOneResidencePriceGrid$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridActions.upsertOneResidencePriceGrid),
      concatMap(
        ({
          residencePriceGrid,
          ids
        }: {
          residencePriceGrid: Partial<ResidencePriceGrid>;
          ids?: ResidencePriceGridRelationsIds;
        }) => {
          if (residencePriceGrid.idResidencePriceGrid) {
            return this.residencePriceGridApiService.updateResidencePriceGridWithArrayReturned(residencePriceGrid).pipe(
              map((residencePriceGridReturned: ResidencePriceGrid[]) => {
                return residencePriceGridActions.normalizeManyResidencePriceGridsAfterUpsert({
                  residencePriceGrids: residencePriceGridReturned
                });
              }),
              catchError(error => of(residencePriceGridActions.residencePriceGridsFailure({ error })))
            );
          } else {
            return this.residencePriceGridApiService.addResidencePriceGrid(residencePriceGrid).pipe(
              concatMap((residencePriceGridReturned: ResidencePriceGrid) =>
                getDefaultAddResidencePriceGridActions(residencePriceGridReturned, ids)
              ),
              catchError(error => of(residencePriceGridActions.residencePriceGridsFailure({ error })))
            );
          }
        }
      )
    );
  }) as any;

  upsertOneResidencePriceGridLock$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridActions.upsertOneResidencePriceGridLock),
      concatMap(
        ({
          residencePriceGrid,
          ids
        }: {
          residencePriceGrid: Partial<ResidencePriceGrid>;
          ids?: ResidencePriceGridRelationsIds;
        }) => {
          if (residencePriceGrid.idResidencePriceGrid) {
            return this.residencePriceGridApiService
              .updateResidencePriceGridLockWithArrayReturned(residencePriceGrid)
              .pipe(
                map((residencePriceGridReturned: ResidencePriceGrid[]) => {
                  return residencePriceGridActions.normalizeManyResidencePriceGridsAfterUpsert({
                    residencePriceGrids: residencePriceGridReturned
                  });
                }),
                catchError(error => of(residencePriceGridActions.residencePriceGridsFailure({ error })))
              );
          } else {
            return this.residencePriceGridApiService.addResidencePriceGrid(residencePriceGrid).pipe(
              concatMap((residencePriceGridReturned: ResidencePriceGrid) =>
                getDefaultAddResidencePriceGridActions(residencePriceGridReturned, ids)
              ),
              catchError(error => of(residencePriceGridActions.residencePriceGridsFailure({ error })))
            );
          }
        }
      )
    );
  }) as any;

  upsertOneResidencePriceGridActive$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridActions.upsertOneResidencePriceGridActive),
      concatMap(
        ({
          residencePriceGrid,
          ids
        }: {
          residencePriceGrid: Partial<ResidencePriceGrid>;
          ids?: ResidencePriceGridRelationsIds;
        }) => {
          if (residencePriceGrid.idResidencePriceGrid) {
            return this.residencePriceGridApiService
              .updateResidencePriceGridActiveWithArrayReturned(residencePriceGrid)
              .pipe(
                map((residencePriceGridReturned: ResidencePriceGrid[]) => {
                  return residencePriceGridActions.normalizeManyResidencePriceGridsAfterUpsert({
                    residencePriceGrids: residencePriceGridReturned
                  });
                }),
                catchError(error => of(residencePriceGridActions.residencePriceGridsFailure({ error })))
              );
          } else {
            return this.residencePriceGridApiService.addResidencePriceGrid(residencePriceGrid).pipe(
              concatMap((residencePriceGridReturned: ResidencePriceGrid) =>
                getDefaultAddResidencePriceGridActions(residencePriceGridReturned, ids)
              ),
              catchError(error => of(residencePriceGridActions.residencePriceGridsFailure({ error })))
            );
          }
        }
      )
    );
  }) as any;
}
