import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationEnergie } from '@_model/interfaces/organization-energie.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationEnergieApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationEnergies(params?: any): Observable<OrganizationEnergie[]> {
    return this.repo.getData<OrganizationEnergie[]>('organization-energie', params);
  }

  public getOrganizationEnergie(params: {
    idOrganizationEnergie: number;
    params?: any;
  }): Observable<OrganizationEnergie> {
    return this.repo.getData<OrganizationEnergie>(
      'organization-energie/' + params.idOrganizationEnergie,
      params.params
    );
  }

  public addOrganizationEnergie(organizationEnergie: Partial<OrganizationEnergie>): Observable<OrganizationEnergie> {
    return this.repo.create<OrganizationEnergie>('organization-energie', organizationEnergie);
  }

  public updateOrganizationEnergie(organizationEnergie: Partial<OrganizationEnergie>): Observable<OrganizationEnergie> {
    return this.repo.update('organization-energie', organizationEnergie);
  }

  public deleteOrganizationEnergie(idOrganizationEnergie: number): Observable<number> {
    return this.repo.delete('organization-energie/' + +idOrganizationEnergie);
  }
}
