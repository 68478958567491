import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidenceStudyReasonEntityState } from '@_model/interfaces/residence-study-reason.model';

export interface ResidenceStudyReasonState extends EntityState<ResidenceStudyReasonEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidenceStudyReasonEntityState> =
  createEntityAdapter<ResidenceStudyReasonEntityState>({
    selectId: o => o.idResidenceStudyReason
  });
export const initialState: ResidenceStudyReasonState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residenceStudyReasonFeatureKey = 'residenceStudyReason';
