import { ResidenceStudyReasonState, initialState } from './residence-study-reason.state';
import { Action, createReducer } from '@ngrx/store';
import { residenceStudyReasonReducersGeneratedFunctions } from './residence-study-reason-generated.reducer';

const residenceStudyReasonReducersFunctions = [...residenceStudyReasonReducersGeneratedFunctions];

const residenceStudyReasonReducer = createReducer(initialState, ...residenceStudyReasonReducersFunctions);

export function reducer(state: ResidenceStudyReasonState | undefined, action: Action) {
  return residenceStudyReasonReducer(state, action);
}
