import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceContact } from '@_model/interfaces/residence-contact.model';
import { Observable } from 'rxjs';

export class GeneratedResidenceContactApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidenceContacts(params?: any): Observable<ResidenceContact[]> {
    return this.repo.getData<ResidenceContact[]>('residence-contact', params);
  }

  public getResidenceContact(params: { idResidenceContact: number; params?: any }): Observable<ResidenceContact> {
    return this.repo.getData<ResidenceContact>('residence-contact/' + params.idResidenceContact, params.params);
  }

  public addResidenceContact(residenceContact: Partial<ResidenceContact>): Observable<ResidenceContact> {
    return this.repo.create<ResidenceContact>('residence-contact', residenceContact);
  }

  public updateResidenceContact(residenceContact: Partial<ResidenceContact>): Observable<ResidenceContact> {
    return this.repo.update('residence-contact', residenceContact);
  }

  public deleteResidenceContact(idResidenceContact: number): Observable<number> {
    return this.repo.delete('residence-contact/' + +idResidenceContact);
  }
}
