import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as StratalotAssociationActions from './stratalot-association.actions';
import { adapter, initialState, StratalotAssociationState } from './stratalot-association.state';
import { Dictionary } from '@ngrx/entity';
import { StratalotAssociationEntityState } from '@_model/interfaces/stratalot-association.model';

export const stratalotAssociationReducersGeneratedFunctions: ReducerTypes<
  StratalotAssociationState,
  readonly ActionCreator[]
>[] = [
  on(StratalotAssociationActions.getOneStratalotAssociation, (state: StratalotAssociationState) =>
    setLoadingsState(state, true)
  ),
  on(StratalotAssociationActions.getManyStratalotAssociations, (state: StratalotAssociationState) =>
    setLoadingsState(state, true)
  ),
  on(StratalotAssociationActions.upsertOneStratalotAssociation, (state: StratalotAssociationState) =>
    setLoadingsState(state, true)
  ),

  on(
    StratalotAssociationActions.upsertManyStratalotAssociationsSuccess,
    (state: StratalotAssociationState, { stratalotAssociations }) =>
      adapter.upsertMany(stratalotAssociations, setLoadingsState(state, false))
  ),
  on(StratalotAssociationActions.deleteOneStratalotAssociation, (state: StratalotAssociationState) =>
    setLoadingsState(state, true)
  ),
  on(
    StratalotAssociationActions.deleteOneStratalotAssociationSuccess,
    (state: StratalotAssociationState, { idStratalotAssociation }) =>
      adapter.removeOne(idStratalotAssociation, setLoadingsState(state, false))
  ),
  on(StratalotAssociationActions.clearActive, (state: StratalotAssociationState) => ({ ...state, actives: [] })),
  on(StratalotAssociationActions.addManyActives, (state: StratalotAssociationState, { idStratalotAssociations }) => ({
    ...state,
    actives: state.actives.concat(idStratalotAssociations)
  })),
  on(StratalotAssociationActions.deleteOneActive, (state: StratalotAssociationState, { idStratalotAssociation }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idStratalotAssociation)
  })),

  on(StratalotAssociationActions.clearStore, () => initialState),

  on(
    StratalotAssociationActions.addStratalotSuccess,
    (state: StratalotAssociationState, { idStratalotAssociation, idStratalot }) => {
      if (!state.entities[idStratalotAssociation]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotAssociation]: {
            ...state.entities[idStratalotAssociation],
            stratalot: idStratalot
          }
        }
      };
    }
  ),

  on(
    StratalotAssociationActions.deleteManyStratalotSuccess,
    (state: StratalotAssociationState, { idStratalots, idStratalotAssociations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotAssociations.reduce((entities, idStratalotAssociation) => {
            if (!state.entities[idStratalotAssociation]?.stratalot) {
              return entities;
            }
            entities[idStratalotAssociation] = {
              ...state.entities[idStratalotAssociation],
              stratalot: idStratalots.some(
                (idStratalot: number) => idStratalot === state.entities[idStratalotAssociation]?.stratalot
              )
                ? undefined
                : state.entities[idStratalotAssociation]?.stratalot
            } as StratalotAssociationEntityState;
            return entities;
          }, {} as Dictionary<StratalotAssociationEntityState>)
        }
      };
    }
  ),

  on(
    StratalotAssociationActions.addAssociationSuccess,
    (state: StratalotAssociationState, { idStratalotAssociation, idAssociation }) => {
      if (!state.entities[idStratalotAssociation]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotAssociation]: {
            ...state.entities[idStratalotAssociation],
            association: idAssociation
          }
        }
      };
    }
  ),

  on(
    StratalotAssociationActions.deleteManyAssociationSuccess,
    (state: StratalotAssociationState, { idAssociations, idStratalotAssociations }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotAssociations.reduce((entities, idStratalotAssociation) => {
            if (!state.entities[idStratalotAssociation]?.association) {
              return entities;
            }
            entities[idStratalotAssociation] = {
              ...state.entities[idStratalotAssociation],
              association: idAssociations.some(
                (idAssociation: number) => idAssociation === state.entities[idStratalotAssociation]?.association
              )
                ? undefined
                : state.entities[idStratalotAssociation]?.association
            } as StratalotAssociationEntityState;
            return entities;
          }, {} as Dictionary<StratalotAssociationEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: StratalotAssociationState,
  isLoading: boolean,
  isLoaded: boolean = true
): StratalotAssociationState {
  return { ...state, isLoaded, isLoading };
}
