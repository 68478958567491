import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StratalotPriceListApiService } from '@_services/api/stratalot-price-list-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedStratalotPriceListEffects } from './stratalot-price-list-generated.effects';

@Injectable()
export class StratalotPriceListEffects extends GeneratedStratalotPriceListEffects {
  constructor(actions$: Actions, stratalotPriceListApiService: StratalotPriceListApiService, store$: Store<AppState>) {
    super(actions$, stratalotPriceListApiService, store$);
  }
}
