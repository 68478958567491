import { RepositoryService } from '@_services/api/repository.service';
import { StratalotPrice } from '@_model/interfaces/stratalot-price.model';
import { Observable } from 'rxjs';

export class GeneratedStratalotPriceApiService {
  constructor(protected repo: RepositoryService) {}

  public getStratalotPrices(params?: any): Observable<StratalotPrice[]> {
    return this.repo.getData<StratalotPrice[]>('stratalot-price', params);
  }

  public getStratalotPrice(params: { idStratalotPrice: number; params?: any }): Observable<StratalotPrice> {
    return this.repo.getData<StratalotPrice>('stratalot-price/' + params.idStratalotPrice, params.params);
  }

  public addStratalotPrice(stratalotPrice: Partial<StratalotPrice>): Observable<StratalotPrice> {
    return this.repo.create<StratalotPrice>('stratalot-price', stratalotPrice);
  }

  public updateStratalotPrice(stratalotPrice: Partial<StratalotPrice>): Observable<StratalotPrice> {
    return this.repo.update('stratalot-price', stratalotPrice);
  }

  public deleteStratalotPrice(idStratalotPrice: number): Observable<number> {
    return this.repo.delete('stratalot-price/' + +idStratalotPrice);
  }
}
