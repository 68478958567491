export enum DashBoardPathEnum {
  resultsAtDate = 'results-at-date',
  activity = 'activity',
  reportSoldStratalot = 'report-sold-stratalot',
  reportAllStratalot = 'report-all-stratalot',
  resultsResidence = 'results-residence',
  stockPatrimoine = 'stock-patrimoine',
  previsionnel = 'previsionnel',
  desistements = 'desistements'
}
