export enum SalePlanPathEnum {
  detail = 'detail',
  detailList = 'detail-list',
  process = 'process',
  documents = 'documents',
  diffusion = 'diffusion',
  diffusionProgramme = 'diffusion-programme',
  etatVente = 'etat-vente',
  lotsLocatifs = 'lots-locatifs',
  lotsCoproprietes = 'lots-coproprietes',
  grillePrix = 'grille-prix',
  residence = 'residence',
  previsionnel = 'previsionnel',
  programme = 'programmes',
  programCommercialisation = 'programmes-commercialisation',
  prospects = 'prospects',
  publipostage = 'publipostage'
}
