import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as AppState from '@_store/index.reducers';
import { GeneratedSaleCategoryFamilyService } from './sale-category-family-generated.service';
import { Actions } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class SaleCategoryFamilyService extends GeneratedSaleCategoryFamilyService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }
}
