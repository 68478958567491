import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationResidenceTodoAppliedRuleActions from './organization-residence-todo-applied-rule.actions';
import {
  adapter,
  initialState,
  OrganizationResidenceTodoAppliedRuleState
} from './organization-residence-todo-applied-rule.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationResidenceTodoAppliedRuleEntityState } from '@_model/interfaces/organization-residence-todo-applied-rule.model';

export const organizationResidenceTodoAppliedRuleReducersGeneratedFunctions: ReducerTypes<
  OrganizationResidenceTodoAppliedRuleState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationResidenceTodoAppliedRuleActions.getOneOrganizationResidenceTodoAppliedRule,
    (state: OrganizationResidenceTodoAppliedRuleState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationResidenceTodoAppliedRuleActions.getManyOrganizationResidenceTodoAppliedRules,
    (state: OrganizationResidenceTodoAppliedRuleState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationResidenceTodoAppliedRuleActions.upsertOneOrganizationResidenceTodoAppliedRule,
    (state: OrganizationResidenceTodoAppliedRuleState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationResidenceTodoAppliedRuleActions.upsertManyOrganizationResidenceTodoAppliedRulesSuccess,
    (state: OrganizationResidenceTodoAppliedRuleState, { organizationResidenceTodoAppliedRules }) =>
      adapter.upsertMany(organizationResidenceTodoAppliedRules, setLoadingsState(state, false))
  ),
  on(
    OrganizationResidenceTodoAppliedRuleActions.deleteOneOrganizationResidenceTodoAppliedRule,
    (state: OrganizationResidenceTodoAppliedRuleState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationResidenceTodoAppliedRuleActions.deleteOneOrganizationResidenceTodoAppliedRuleSuccess,
    (state: OrganizationResidenceTodoAppliedRuleState, { idOrganizationResidenceTodoAppliedRule }) =>
      adapter.removeOne(idOrganizationResidenceTodoAppliedRule, setLoadingsState(state, false))
  ),
  on(OrganizationResidenceTodoAppliedRuleActions.clearActive, (state: OrganizationResidenceTodoAppliedRuleState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationResidenceTodoAppliedRuleActions.addManyActives,
    (state: OrganizationResidenceTodoAppliedRuleState, { idOrganizationResidenceTodoAppliedRules }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationResidenceTodoAppliedRules)
    })
  ),
  on(
    OrganizationResidenceTodoAppliedRuleActions.deleteOneActive,
    (state: OrganizationResidenceTodoAppliedRuleState, { idOrganizationResidenceTodoAppliedRule }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationResidenceTodoAppliedRule)
    })
  ),

  on(OrganizationResidenceTodoAppliedRuleActions.clearStore, () => initialState),

  on(
    OrganizationResidenceTodoAppliedRuleActions.addOrganizationResidenceTodoSuccess,
    (
      state: OrganizationResidenceTodoAppliedRuleState,
      { idOrganizationResidenceTodoAppliedRule, idOrganizationResidenceTodo }
    ) => {
      if (!state.entities[idOrganizationResidenceTodoAppliedRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodoAppliedRule]: {
            ...state.entities[idOrganizationResidenceTodoAppliedRule],
            organizationResidenceTodo: idOrganizationResidenceTodo
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoAppliedRuleActions.deleteManyOrganizationResidenceTodoSuccess,
    (
      state: OrganizationResidenceTodoAppliedRuleState,
      { idOrganizationResidenceTodos, idOrganizationResidenceTodoAppliedRules }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodoAppliedRules.reduce((entities, idOrganizationResidenceTodoAppliedRule) => {
            if (!state.entities[idOrganizationResidenceTodoAppliedRule]?.organizationResidenceTodo) {
              return entities;
            }
            entities[idOrganizationResidenceTodoAppliedRule] = {
              ...state.entities[idOrganizationResidenceTodoAppliedRule],
              organizationResidenceTodo: idOrganizationResidenceTodos.some(
                (idOrganizationResidenceTodo: number) =>
                  idOrganizationResidenceTodo ===
                  state.entities[idOrganizationResidenceTodoAppliedRule]?.organizationResidenceTodo
              )
                ? undefined
                : state.entities[idOrganizationResidenceTodoAppliedRule]?.organizationResidenceTodo
            } as OrganizationResidenceTodoAppliedRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoAppliedRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoAppliedRuleActions.addOrganizationResidenceTodoRuleSuccess,
    (
      state: OrganizationResidenceTodoAppliedRuleState,
      { idOrganizationResidenceTodoAppliedRule, idOrganizationResidenceTodoRule }
    ) => {
      if (!state.entities[idOrganizationResidenceTodoAppliedRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationResidenceTodoAppliedRule]: {
            ...state.entities[idOrganizationResidenceTodoAppliedRule],
            organizationResidenceTodoRule: idOrganizationResidenceTodoRule
          }
        }
      };
    }
  ),

  on(
    OrganizationResidenceTodoAppliedRuleActions.deleteManyOrganizationResidenceTodoRuleSuccess,
    (
      state: OrganizationResidenceTodoAppliedRuleState,
      { idOrganizationResidenceTodoRules, idOrganizationResidenceTodoAppliedRules }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationResidenceTodoAppliedRules.reduce((entities, idOrganizationResidenceTodoAppliedRule) => {
            if (!state.entities[idOrganizationResidenceTodoAppliedRule]?.organizationResidenceTodoRule) {
              return entities;
            }
            entities[idOrganizationResidenceTodoAppliedRule] = {
              ...state.entities[idOrganizationResidenceTodoAppliedRule],
              organizationResidenceTodoRule: idOrganizationResidenceTodoRules.some(
                (idOrganizationResidenceTodoRule: number) =>
                  idOrganizationResidenceTodoRule ===
                  state.entities[idOrganizationResidenceTodoAppliedRule]?.organizationResidenceTodoRule
              )
                ? undefined
                : state.entities[idOrganizationResidenceTodoAppliedRule]?.organizationResidenceTodoRule
            } as OrganizationResidenceTodoAppliedRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationResidenceTodoAppliedRuleEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationResidenceTodoAppliedRuleState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationResidenceTodoAppliedRuleState {
  return { ...state, isLoaded, isLoading };
}
