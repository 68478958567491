import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { OrganizationEnergie, OrganizationEnergieEntityState } from '@_model/interfaces/organization-energie.model';
import { OrganizationEnergieApiService } from '@_services/api/organization-energie-api.service';
import * as organizationEnergieActions from '@_store/organization-energie/organization-energie.actions';
import { getActionsToNormalizeOrganizationEnergie } from '@_config/store/normalization.generated';
import { selectOrganizationEnergieState } from './organization-energie-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';

export interface OrganizationEnergieRelationsIds {
  organization?: number;
}

export function getDefaultAddOrganizationEnergieActions(
  organizationEnergie: OrganizationEnergieEntityState,
  ids?: OrganizationEnergieRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationEnergieActions.normalizeManyOrganizationEnergiesAfterUpsert({
      organizationEnergies: [organizationEnergie]
    })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationEnergieSuccess({
        idOrganization: ids.organization,
        idOrganizationEnergies: [organizationEnergie.idOrganizationEnergie]
      })
    );
    actions.push(
      organizationEnergieActions.addOrganizationSuccess({
        idOrganizationEnergie: organizationEnergie.idOrganizationEnergie,
        idOrganization: ids.organization
      })
    );
  }

  return actions;
}

export function getDefaultDeleteOrganizationEnergieActions(
  organizationEnergie: OrganizationEnergieEntityState
): Action[] {
  const actions: Action[] = [
    organizationEnergieActions.deleteOneOrganizationEnergieSuccess({
      idOrganizationEnergie: organizationEnergie.idOrganizationEnergie
    })
  ];

  if (organizationEnergie.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationEnergieSuccess({
        idOrganizationEnergies: [organizationEnergie.idOrganizationEnergie],
        idOrganizations: [organizationEnergie.organization as number]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationEnergieEffects {
  constructor(
    protected actions$: Actions,
    protected organizationEnergieApiService: OrganizationEnergieApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationEnergies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationEnergieActions.getManyOrganizationEnergies),
      switchMap(({ params }) =>
        this.organizationEnergieApiService.getOrganizationEnergies(params).pipe(
          map((organizationEnergies: OrganizationEnergie[]) => {
            return organizationEnergieActions.normalizeManyOrganizationEnergiesAfterUpsert({ organizationEnergies });
          }),
          catchError(error => of(organizationEnergieActions.organizationEnergiesFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationEnergie$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationEnergieActions.getOneOrganizationEnergie),
      switchMap(idOrganizationEnergie =>
        this.organizationEnergieApiService.getOrganizationEnergie(idOrganizationEnergie).pipe(
          map((organizationEnergie: OrganizationEnergie) => {
            return organizationEnergieActions.normalizeManyOrganizationEnergiesAfterUpsert({
              organizationEnergies: [organizationEnergie]
            });
          }),
          catchError(error => of(organizationEnergieActions.organizationEnergiesFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationEnergie$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationEnergieActions.upsertOneOrganizationEnergie),
      concatMap(
        ({
          organizationEnergie,
          ids
        }: {
          organizationEnergie: Partial<OrganizationEnergie>;
          ids?: OrganizationEnergieRelationsIds;
        }) => {
          if (organizationEnergie.idOrganizationEnergie) {
            return this.organizationEnergieApiService.updateOrganizationEnergie(organizationEnergie).pipe(
              map((organizationEnergieReturned: OrganizationEnergie) => {
                return organizationEnergieActions.normalizeManyOrganizationEnergiesAfterUpsert({
                  organizationEnergies: [organizationEnergieReturned]
                });
              }),
              catchError(error => of(organizationEnergieActions.organizationEnergiesFailure({ error })))
            );
          } else {
            return this.organizationEnergieApiService.addOrganizationEnergie(organizationEnergie).pipe(
              mergeMap((organizationEnergieReturned: OrganizationEnergie) =>
                getDefaultAddOrganizationEnergieActions(organizationEnergieReturned, ids)
              ),
              catchError(error => of(organizationEnergieActions.organizationEnergiesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationEnergie$ = createEffect(() => {
    const selectOrganizationEnergieState$ = this.store$.select(selectOrganizationEnergieState);
    return this.actions$.pipe(
      ofType(organizationEnergieActions.deleteOneOrganizationEnergie),
      withLatestFrom(selectOrganizationEnergieState$),
      concatMap(([{ idOrganizationEnergie }, state]) =>
        this.organizationEnergieApiService.deleteOrganizationEnergie(idOrganizationEnergie).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOrganizationEnergieActions(
                state.entities[idOrganizationEnergie] as OrganizationEnergieEntityState
              ),
              organizationEnergieActions.deleteOneOrganizationEnergie.type
            )
          ]),
          catchError(error => of(organizationEnergieActions.organizationEnergiesFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationEnergiesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationEnergieActions.normalizeManyOrganizationEnergiesAfterUpsert),
      concatMap(({ organizationEnergies }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationEnergie(
          organizationEnergies,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationEnergie] Normalization After Upsert Success')];
      })
    );
  });
}
