import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidenceActions from './residence.actions';
import { adapter, initialState, ResidenceState } from './residence.state';
import { Dictionary } from '@ngrx/entity';
import { ResidenceEntityState } from '@_model/interfaces/residence.model';

export const residenceReducersGeneratedFunctions: ReducerTypes<ResidenceState, readonly ActionCreator[]>[] = [
  on(ResidenceActions.getOneResidence, (state: ResidenceState) => setLoadingsState(state, true)),
  on(ResidenceActions.getManyResidences, (state: ResidenceState) => setLoadingsState(state, true)),
  on(ResidenceActions.upsertOneResidence, (state: ResidenceState) => setLoadingsState(state, true)),

  on(ResidenceActions.upsertManyResidencesSuccess, (state: ResidenceState, { residences }) =>
    adapter.upsertMany(residences, setLoadingsState(state, false))
  ),
  on(ResidenceActions.deleteOneResidence, (state: ResidenceState) => setLoadingsState(state, true)),
  on(ResidenceActions.deleteOneResidenceSuccess, (state: ResidenceState, { idResidence }) =>
    adapter.removeOne(idResidence, setLoadingsState(state, false))
  ),
  on(ResidenceActions.clearActive, (state: ResidenceState) => ({ ...state, actives: [] })),
  on(ResidenceActions.addManyActives, (state: ResidenceState, { idResidences }) => ({
    ...state,
    actives: state.actives.concat(idResidences)
  })),
  on(ResidenceActions.deleteOneActive, (state: ResidenceState, { idResidence }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idResidence)
  })),

  on(ResidenceActions.clearStore, () => initialState),

  on(ResidenceActions.addManyDiffusionVisualSuccess, (state: ResidenceState, { idResidence, idDiffusionVisuals }) => {
    if (!state.entities[idResidence]) {
      return state;
    }
    const diffusionVisuals = (state.entities[idResidence]?.diffusionVisuals as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idResidence]: {
          ...state.entities[idResidence],
          diffusionVisuals: diffusionVisuals.concat(idDiffusionVisuals.filter(id => diffusionVisuals.indexOf(id) < 0))
        }
      }
    };
  }),

  on(
    ResidenceActions.deleteManyDiffusionVisualSuccess,
    (state: ResidenceState, { idDiffusionVisuals, idResidences }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidences.reduce((entities, idResidence) => {
            if (!state.entities[idResidence]?.diffusionVisuals) {
              return entities;
            }
            entities[idResidence] = {
              ...state.entities[idResidence],
              diffusionVisuals: (state.entities[idResidence]?.diffusionVisuals as number[])?.filter(
                (idDiffusionVisual: number) => !idDiffusionVisuals.some((id: number) => id === idDiffusionVisual)
              )
            } as ResidenceEntityState;
            return entities;
          }, {} as Dictionary<ResidenceEntityState>)
        }
      };
    }
  ),

  on(ResidenceActions.addManyResidenceTodoSuccess, (state: ResidenceState, { idResidence, idResidenceTodos }) => {
    if (!state.entities[idResidence]) {
      return state;
    }
    const residenceTodos = (state.entities[idResidence]?.residenceTodos as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idResidence]: {
          ...state.entities[idResidence],
          residenceTodos: residenceTodos.concat(idResidenceTodos.filter(id => residenceTodos.indexOf(id) < 0))
        }
      }
    };
  }),

  on(ResidenceActions.deleteManyResidenceTodoSuccess, (state: ResidenceState, { idResidenceTodos, idResidences }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idResidences.reduce((entities, idResidence) => {
          if (!state.entities[idResidence]?.residenceTodos) {
            return entities;
          }
          entities[idResidence] = {
            ...state.entities[idResidence],
            residenceTodos: (state.entities[idResidence]?.residenceTodos as number[])?.filter(
              (idResidenceTodo: number) => !idResidenceTodos.some((id: number) => id === idResidenceTodo)
            )
          } as ResidenceEntityState;
          return entities;
        }, {} as Dictionary<ResidenceEntityState>)
      }
    };
  }),

  on(ResidenceActions.addManyResidenceWorkSuccess, (state: ResidenceState, { idResidence, idResidenceWorks }) => {
    if (!state.entities[idResidence]) {
      return state;
    }
    const residenceWorks = (state.entities[idResidence]?.residenceWorks as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idResidence]: {
          ...state.entities[idResidence],
          residenceWorks: residenceWorks.concat(idResidenceWorks.filter(id => residenceWorks.indexOf(id) < 0))
        }
      }
    };
  }),

  on(ResidenceActions.deleteManyResidenceWorkSuccess, (state: ResidenceState, { idResidenceWorks, idResidences }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idResidences.reduce((entities, idResidence) => {
          if (!state.entities[idResidence]?.residenceWorks) {
            return entities;
          }
          entities[idResidence] = {
            ...state.entities[idResidence],
            residenceWorks: (state.entities[idResidence]?.residenceWorks as number[])?.filter(
              (idResidenceWork: number) => !idResidenceWorks.some((id: number) => id === idResidenceWork)
            )
          } as ResidenceEntityState;
          return entities;
        }, {} as Dictionary<ResidenceEntityState>)
      }
    };
  }),

  on(
    ResidenceActions.addManyResidenceSaleCategoryFamilySuccess,
    (state: ResidenceState, { idResidence, idResidenceSaleCategoryFamilies }) => {
      if (!state.entities[idResidence]) {
        return state;
      }
      const residenceSaleCategoryFamilies =
        (state.entities[idResidence]?.residenceSaleCategoryFamilies as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidence]: {
            ...state.entities[idResidence],
            residenceSaleCategoryFamilies: residenceSaleCategoryFamilies.concat(
              idResidenceSaleCategoryFamilies.filter(id => residenceSaleCategoryFamilies.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidenceActions.deleteManyResidenceSaleCategoryFamilySuccess,
    (state: ResidenceState, { idResidenceSaleCategoryFamilies, idResidences }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidences.reduce((entities, idResidence) => {
            if (!state.entities[idResidence]?.residenceSaleCategoryFamilies) {
              return entities;
            }
            entities[idResidence] = {
              ...state.entities[idResidence],
              residenceSaleCategoryFamilies: (
                state.entities[idResidence]?.residenceSaleCategoryFamilies as number[]
              )?.filter(
                (idResidenceSaleCategoryFamily: number) =>
                  !idResidenceSaleCategoryFamilies.some((id: number) => id === idResidenceSaleCategoryFamily)
              )
            } as ResidenceEntityState;
            return entities;
          }, {} as Dictionary<ResidenceEntityState>)
        }
      };
    }
  ),

  on(ResidenceActions.addManyResidenceContactSuccess, (state: ResidenceState, { idResidence, idResidenceContacts }) => {
    if (!state.entities[idResidence]) {
      return state;
    }
    const residenceContacts = (state.entities[idResidence]?.residenceContacts as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idResidence]: {
          ...state.entities[idResidence],
          residenceContacts: residenceContacts.concat(
            idResidenceContacts.filter(id => residenceContacts.indexOf(id) < 0)
          )
        }
      }
    };
  }),

  on(
    ResidenceActions.deleteManyResidenceContactSuccess,
    (state: ResidenceState, { idResidenceContacts, idResidences }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidences.reduce((entities, idResidence) => {
            if (!state.entities[idResidence]?.residenceContacts) {
              return entities;
            }
            entities[idResidence] = {
              ...state.entities[idResidence],
              residenceContacts: (state.entities[idResidence]?.residenceContacts as number[])?.filter(
                (idResidenceContact: number) => !idResidenceContacts.some((id: number) => id === idResidenceContact)
              )
            } as ResidenceEntityState;
            return entities;
          }, {} as Dictionary<ResidenceEntityState>)
        }
      };
    }
  ),

  on(ResidenceActions.addManyStratalotSuccess, (state: ResidenceState, { idResidence, idStratalots }) => {
    if (!state.entities[idResidence]) {
      return state;
    }
    const stratalots = (state.entities[idResidence]?.stratalots as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idResidence]: {
          ...state.entities[idResidence],
          stratalots: stratalots.concat(idStratalots.filter(id => stratalots.indexOf(id) < 0))
        }
      }
    };
  }),

  on(ResidenceActions.deleteManyStratalotSuccess, (state: ResidenceState, { idStratalots, idResidences }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idResidences.reduce((entities, idResidence) => {
          if (!state.entities[idResidence]?.stratalots) {
            return entities;
          }
          entities[idResidence] = {
            ...state.entities[idResidence],
            stratalots: (state.entities[idResidence]?.stratalots as number[])?.filter(
              (idStratalot: number) => !idStratalots.some((id: number) => id === idStratalot)
            )
          } as ResidenceEntityState;
          return entities;
        }, {} as Dictionary<ResidenceEntityState>)
      }
    };
  }),

  on(
    ResidenceActions.addManyResidencePriceGridSuccess,
    (state: ResidenceState, { idResidence, idResidencePriceGrids }) => {
      if (!state.entities[idResidence]) {
        return state;
      }
      const residencePriceGrids = (state.entities[idResidence]?.residencePriceGrids as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidence]: {
            ...state.entities[idResidence],
            residencePriceGrids: residencePriceGrids.concat(
              idResidencePriceGrids.filter(id => residencePriceGrids.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidenceActions.deleteManyResidencePriceGridSuccess,
    (state: ResidenceState, { idResidencePriceGrids, idResidences }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidences.reduce((entities, idResidence) => {
            if (!state.entities[idResidence]?.residencePriceGrids) {
              return entities;
            }
            entities[idResidence] = {
              ...state.entities[idResidence],
              residencePriceGrids: (state.entities[idResidence]?.residencePriceGrids as number[])?.filter(
                (idResidencePriceGrid: number) =>
                  !idResidencePriceGrids.some((id: number) => id === idResidencePriceGrid)
              )
            } as ResidenceEntityState;
            return entities;
          }, {} as Dictionary<ResidenceEntityState>)
        }
      };
    }
  ),

  on(ResidenceActions.addManyResidenceShareSuccess, (state: ResidenceState, { idResidence, idResidenceShares }) => {
    if (!state.entities[idResidence]) {
      return state;
    }
    const residenceShares = (state.entities[idResidence]?.residenceShares as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idResidence]: {
          ...state.entities[idResidence],
          residenceShares: residenceShares.concat(idResidenceShares.filter(id => residenceShares.indexOf(id) < 0))
        }
      }
    };
  }),

  on(ResidenceActions.deleteManyResidenceShareSuccess, (state: ResidenceState, { idResidenceShares, idResidences }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idResidences.reduce((entities, idResidence) => {
          if (!state.entities[idResidence]?.residenceShares) {
            return entities;
          }
          entities[idResidence] = {
            ...state.entities[idResidence],
            residenceShares: (state.entities[idResidence]?.residenceShares as number[])?.filter(
              (idResidenceShare: number) => !idResidenceShares.some((id: number) => id === idResidenceShare)
            )
          } as ResidenceEntityState;
          return entities;
        }, {} as Dictionary<ResidenceEntityState>)
      }
    };
  }),

  on(ResidenceActions.addManyStratalotRcpSuccess, (state: ResidenceState, { idResidence, idStratalotRcps }) => {
    if (!state.entities[idResidence]) {
      return state;
    }
    const stratalotRcps = (state.entities[idResidence]?.stratalotRcps as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idResidence]: {
          ...state.entities[idResidence],
          stratalotRcps: stratalotRcps.concat(idStratalotRcps.filter(id => stratalotRcps.indexOf(id) < 0))
        }
      }
    };
  }),

  on(ResidenceActions.deleteManyStratalotRcpSuccess, (state: ResidenceState, { idStratalotRcps, idResidences }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idResidences.reduce((entities, idResidence) => {
          if (!state.entities[idResidence]?.stratalotRcps) {
            return entities;
          }
          entities[idResidence] = {
            ...state.entities[idResidence],
            stratalotRcps: (state.entities[idResidence]?.stratalotRcps as number[])?.filter(
              (idStratalotRcp: number) => !idStratalotRcps.some((id: number) => id === idStratalotRcp)
            )
          } as ResidenceEntityState;
          return entities;
        }, {} as Dictionary<ResidenceEntityState>)
      }
    };
  }),

  on(
    ResidenceActions.addManySaleCategoryFamilySuccess,
    (state: ResidenceState, { idResidence, idFamilySaleCategories }) => {
      if (!state.entities[idResidence]) {
        return state;
      }
      const familySaleCategories = (state.entities[idResidence]?.familySaleCategories as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidence]: {
            ...state.entities[idResidence],
            familySaleCategories: familySaleCategories.concat(
              idFamilySaleCategories.filter(id => familySaleCategories.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidenceActions.deleteManySaleCategoryFamilySuccess,
    (state: ResidenceState, { idFamilySaleCategories, idResidences }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidences.reduce((entities, idResidence) => {
            if (!state.entities[idResidence]?.familySaleCategories) {
              return entities;
            }
            entities[idResidence] = {
              ...state.entities[idResidence],
              familySaleCategories: (state.entities[idResidence]?.familySaleCategories as number[])?.filter(
                (idSaleCategoryFamily: number) =>
                  !idFamilySaleCategories.some((id: number) => id === idSaleCategoryFamily)
              )
            } as ResidenceEntityState;
            return entities;
          }, {} as Dictionary<ResidenceEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceActions.addManyProspectBuyingWishSuccess,
    (state: ResidenceState, { idResidence, idProspectBuyingWishs }) => {
      if (!state.entities[idResidence]) {
        return state;
      }
      const prospectBuyingWishs = (state.entities[idResidence]?.prospectBuyingWishs as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidence]: {
            ...state.entities[idResidence],
            prospectBuyingWishs: prospectBuyingWishs.concat(
              idProspectBuyingWishs.filter(id => prospectBuyingWishs.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidenceActions.deleteManyProspectBuyingWishSuccess,
    (state: ResidenceState, { idProspectBuyingWishs, idResidences }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidences.reduce((entities, idResidence) => {
            if (!state.entities[idResidence]?.prospectBuyingWishs) {
              return entities;
            }
            entities[idResidence] = {
              ...state.entities[idResidence],
              prospectBuyingWishs: (state.entities[idResidence]?.prospectBuyingWishs as number[])?.filter(
                (idProspectBuyingWish: number) =>
                  !idProspectBuyingWishs.some((id: number) => id === idProspectBuyingWish)
              )
            } as ResidenceEntityState;
            return entities;
          }, {} as Dictionary<ResidenceEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceActions.addManyCompanyTerritoireSuccess,
    (state: ResidenceState, { idResidence, idCompanyTerritoires }) => {
      if (!state.entities[idResidence]) {
        return state;
      }
      const companyTerritoires = (state.entities[idResidence]?.companyTerritoires as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidence]: {
            ...state.entities[idResidence],
            companyTerritoires: companyTerritoires.concat(
              idCompanyTerritoires.filter(id => companyTerritoires.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidenceActions.deleteManyCompanyTerritoireSuccess,
    (state: ResidenceState, { idCompanyTerritoires, idResidences }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidences.reduce((entities, idResidence) => {
            if (!state.entities[idResidence]?.companyTerritoires) {
              return entities;
            }
            entities[idResidence] = {
              ...state.entities[idResidence],
              companyTerritoires: (state.entities[idResidence]?.companyTerritoires as number[])?.filter(
                (idCompanyTerritoire: number) => !idCompanyTerritoires.some((id: number) => id === idCompanyTerritoire)
              )
            } as ResidenceEntityState;
            return entities;
          }, {} as Dictionary<ResidenceEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceActions.addManyResidenceForecastSuccess,
    (state: ResidenceState, { idResidence, idResidenceForecasts }) => {
      if (!state.entities[idResidence]) {
        return state;
      }
      const residenceForecasts = (state.entities[idResidence]?.residenceForecasts as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidence]: {
            ...state.entities[idResidence],
            residenceForecasts: residenceForecasts.concat(
              idResidenceForecasts.filter(id => residenceForecasts.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidenceActions.deleteManyResidenceForecastSuccess,
    (state: ResidenceState, { idResidenceForecasts, idResidences }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidences.reduce((entities, idResidence) => {
            if (!state.entities[idResidence]?.residenceForecasts) {
              return entities;
            }
            entities[idResidence] = {
              ...state.entities[idResidence],
              residenceForecasts: (state.entities[idResidence]?.residenceForecasts as number[])?.filter(
                (idResidenceForecast: number) => !idResidenceForecasts.some((id: number) => id === idResidenceForecast)
              )
            } as ResidenceEntityState;
            return entities;
          }, {} as Dictionary<ResidenceEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceActions.addManyGeneratedDocumentsResidenceSuccess,
    (state: ResidenceState, { idResidence, idGeneratedDocumentsResidences }) => {
      if (!state.entities[idResidence]) {
        return state;
      }
      const generatedDocumentsResidences =
        (state.entities[idResidence]?.generatedDocumentsResidences as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidence]: {
            ...state.entities[idResidence],
            generatedDocumentsResidences: generatedDocumentsResidences.concat(
              idGeneratedDocumentsResidences.filter(id => generatedDocumentsResidences.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidenceActions.deleteManyGeneratedDocumentsResidenceSuccess,
    (state: ResidenceState, { idGeneratedDocumentsResidences, idResidences }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidences.reduce((entities, idResidence) => {
            if (!state.entities[idResidence]?.generatedDocumentsResidences) {
              return entities;
            }
            entities[idResidence] = {
              ...state.entities[idResidence],
              generatedDocumentsResidences: (
                state.entities[idResidence]?.generatedDocumentsResidences as number[]
              )?.filter(
                (idGeneratedDocumentsResidence: number) =>
                  !idGeneratedDocumentsResidences.some((id: number) => id === idGeneratedDocumentsResidence)
              )
            } as ResidenceEntityState;
            return entities;
          }, {} as Dictionary<ResidenceEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceActions.addManyGeneratedDocumentSuccess,
    (state: ResidenceState, { idResidence, idGeneratedDocuments }) => {
      if (!state.entities[idResidence]) {
        return state;
      }
      const generatedDocuments = (state.entities[idResidence]?.generatedDocuments as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidence]: {
            ...state.entities[idResidence],
            generatedDocuments: generatedDocuments.concat(
              idGeneratedDocuments.filter(id => generatedDocuments.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidenceActions.deleteManyGeneratedDocumentSuccess,
    (state: ResidenceState, { idGeneratedDocuments, idResidences }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidences.reduce((entities, idResidence) => {
            if (!state.entities[idResidence]?.generatedDocuments) {
              return entities;
            }
            entities[idResidence] = {
              ...state.entities[idResidence],
              generatedDocuments: (state.entities[idResidence]?.generatedDocuments as number[])?.filter(
                (idGeneratedDocument: number) => !idGeneratedDocuments.some((id: number) => id === idGeneratedDocument)
              )
            } as ResidenceEntityState;
            return entities;
          }, {} as Dictionary<ResidenceEntityState>)
        }
      };
    }
  ),

  on(ResidenceActions.addCompanySuccess, (state: ResidenceState, { idResidence, idCompany }) => {
    if (!state.entities[idResidence]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idResidence]: {
          ...state.entities[idResidence],
          company: idCompany
        }
      }
    };
  }),

  on(ResidenceActions.deleteManyCompanySuccess, (state: ResidenceState, { idCompanies, idResidences }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idResidences.reduce((entities, idResidence) => {
          if (!state.entities[idResidence]?.company) {
            return entities;
          }
          entities[idResidence] = {
            ...state.entities[idResidence],
            company: idCompanies.some((idCompany: number) => idCompany === state.entities[idResidence]?.company)
              ? undefined
              : state.entities[idResidence]?.company
          } as ResidenceEntityState;
          return entities;
        }, {} as Dictionary<ResidenceEntityState>)
      }
    };
  }),

  on(ResidenceActions.addResponsableSuccess, (state: ResidenceState, { idResidence, idResponsable }) => {
    if (!state.entities[idResidence]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idResidence]: {
          ...state.entities[idResidence],
          responsable: idResponsable
        }
      }
    };
  }),

  on(ResidenceActions.deleteManyResponsableSuccess, (state: ResidenceState, { idResponsable, idResidences }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idResidences.reduce((entities, idResidence) => {
          if (!state.entities[idResidence]?.responsable) {
            return entities;
          }
          entities[idResidence] = {
            ...state.entities[idResidence],
            user: idResponsable.some((idUser: number) => idUser === state.entities[idResidence]?.responsable)
              ? undefined
              : state.entities[idResidence]?.responsable
          } as ResidenceEntityState;
          return entities;
        }, {} as Dictionary<ResidenceEntityState>)
      }
    };
  }),

  on(
    ResidenceActions.addOrganizationForecastRateSuccess,
    (state: ResidenceState, { idResidence, idOrganizationForecastRate }) => {
      if (!state.entities[idResidence]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidence]: {
            ...state.entities[idResidence],
            organizationForecastRate: idOrganizationForecastRate
          }
        }
      };
    }
  ),

  on(
    ResidenceActions.deleteManyOrganizationForecastRateSuccess,
    (state: ResidenceState, { idOrganizationForecastRates, idResidences }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidences.reduce((entities, idResidence) => {
            if (!state.entities[idResidence]?.organizationForecastRate) {
              return entities;
            }
            entities[idResidence] = {
              ...state.entities[idResidence],
              organizationForecastRate: idOrganizationForecastRates.some(
                (idOrganizationForecastRate: number) =>
                  idOrganizationForecastRate === state.entities[idResidence]?.organizationForecastRate
              )
                ? undefined
                : state.entities[idResidence]?.organizationForecastRate
            } as ResidenceEntityState;
            return entities;
          }, {} as Dictionary<ResidenceEntityState>)
        }
      };
    }
  ),

  on(ResidenceActions.addCitySuccess, (state: ResidenceState, { idResidence, idCity }) => {
    if (!state.entities[idResidence]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idResidence]: {
          ...state.entities[idResidence],
          city: idCity
        }
      }
    };
  }),

  on(ResidenceActions.deleteManyCitySuccess, (state: ResidenceState, { idCities, idResidences }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idResidences.reduce((entities, idResidence) => {
          if (!state.entities[idResidence]?.city) {
            return entities;
          }
          entities[idResidence] = {
            ...state.entities[idResidence],
            city: idCities.some((idCity: number) => idCity === state.entities[idResidence]?.city)
              ? undefined
              : state.entities[idResidence]?.city
          } as ResidenceEntityState;
          return entities;
        }, {} as Dictionary<ResidenceEntityState>)
      }
    };
  })
];

export function setLoadingsState(state: ResidenceState, isLoading: boolean, isLoaded: boolean = true): ResidenceState {
  return { ...state, isLoaded, isLoading };
}
