import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyStudyCriteria, CompanyStudyCriteriaEntityState } from '@_model/interfaces/company-study-criteria.model';
import {
  ResidenceStudyCriteria,
  ResidenceStudyCriteriaEntityState
} from '@_model/interfaces/residence-study-criteria.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, companyStudyCriteriaFeatureKey, CompanyStudyCriteriaState } from './company-study-criteria.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyStudyCriteriaRelations: string[] = ['residenceStudyCriterias', 'companies'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyStudyCriteriaState =
  createFeatureSelector<CompanyStudyCriteriaState>(companyStudyCriteriaFeatureKey);

export const selectIsLoadedCompanyStudyCriteria = createSelector(
  selectCompanyStudyCriteriaState,
  (state: CompanyStudyCriteriaState) => state.isLoaded
);

export const selectIsLoadingCompanyStudyCriteria = createSelector(
  selectCompanyStudyCriteriaState,
  (state: CompanyStudyCriteriaState) => state.isLoading
);

export const selectIsReadyCompanyStudyCriteria = createSelector(
  selectCompanyStudyCriteriaState,
  (state: CompanyStudyCriteriaState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyStudyCriteria = createSelector(
  selectCompanyStudyCriteriaState,
  (state: CompanyStudyCriteriaState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyStudyCriteriaModel: SelectorModel = {
  name: 'companyStudyCriterias',
  getSelector: selectAllCompanyStudyCriteriasDictionary,
  isReady: selectIsReadyCompanyStudyCriteria
};

export const selectCompanyStudyCriteriasEntities = createSelector(selectCompanyStudyCriteriaState, selectEntities);

export const selectCompanyStudyCriteriasArray = createSelector(selectCompanyStudyCriteriaState, selectAll);

export const selectIdCompanyStudyCriteriasActive = createSelector(
  selectCompanyStudyCriteriaState,
  (state: CompanyStudyCriteriaState) => state.actives
);

const companyStudyCriteriasInObject = (companyStudyCriterias: Dictionary<CompanyStudyCriteriaEntityState>) => ({
  companyStudyCriterias
});

const selectCompanyStudyCriteriasEntitiesDictionary = createSelector(
  selectCompanyStudyCriteriasEntities,
  companyStudyCriteriasInObject
);

const selectAllCompanyStudyCriteriasObject = createSelector(
  selectCompanyStudyCriteriasEntities,
  companyStudyCriterias => {
    return hydrateAll({ companyStudyCriterias });
  }
);

const selectOneCompanyStudyCriteriaDictionary = (idCompanyStudyCriteria: number) =>
  createSelector(selectCompanyStudyCriteriasEntities, companyStudyCriterias => {
    return { companyStudyCriterias: { [idCompanyStudyCriteria]: companyStudyCriterias[idCompanyStudyCriteria] } };
  });

const selectOneCompanyStudyCriteriaDictionaryWithoutChild = (idCompanyStudyCriteria: number) =>
  createSelector(selectCompanyStudyCriteriasEntities, companyStudyCriterias => {
    return { companyStudyCriteria: companyStudyCriterias[idCompanyStudyCriteria] };
  });

const selectActiveCompanyStudyCriteriasEntities = createSelector(
  selectIdCompanyStudyCriteriasActive,
  selectCompanyStudyCriteriasEntities,
  (actives: number[], companyStudyCriterias: Dictionary<CompanyStudyCriteriaEntityState>) =>
    getCompanyStudyCriteriasFromActives(actives, companyStudyCriterias)
);

function getCompanyStudyCriteriasFromActives(
  actives: number[],
  companyStudyCriterias: Dictionary<CompanyStudyCriteriaEntityState>
): Dictionary<CompanyStudyCriteriaEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyStudyCriterias[idActive]) {
      acc[idActive] = companyStudyCriterias[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyStudyCriteriaEntityState>);
}

const selectAllCompanyStudyCriteriasSelectors: Dictionary<Selector> = {};
export function selectAllCompanyStudyCriterias(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyStudyCriteria>(
      schema,
      selectAllCompanyStudyCriteriasSelectors,
      selectCompanyStudyCriteriasEntitiesDictionary,
      getRelationSelectors,
      companyStudyCriteriaRelations,
      hydrateAll,
      'companyStudyCriteria'
    );
  } else {
    return selectAllCompanyStudyCriteriasObject;
  }
}

export function selectAllCompanyStudyCriteriasDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyStudyCriterias'
): Selector {
  return createSelector(selectAllCompanyStudyCriterias(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyStudyCriteriaEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyStudyCriterias.length; i++) {
      res[customKey][result.companyStudyCriterias[i].idCompanyStudyCriteria] = result.companyStudyCriterias[i];
    }
    return res;
  });
}

export function selectOneCompanyStudyCriteria(schema: SelectSchema = {}, idCompanyStudyCriteria: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyStudyCriteriaDictionary(idCompanyStudyCriteria)];
    selectors.push(...getRelationSelectors(schema, companyStudyCriteriaRelations, 'companyStudyCriteria'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyStudyCriteriaDictionaryWithoutChild(idCompanyStudyCriteria);
  }
}

export function selectActiveCompanyStudyCriterias(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyStudyCriteriasEntities, companyStudyCriterias => ({ companyStudyCriterias }))
  ];
  selectors.push(...getRelationSelectors(schema, companyStudyCriteriaRelations, 'companyStudyCriteria'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyStudyCriterias: Dictionary<CompanyStudyCriteriaEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  residenceStudyCriterias?: Dictionary<ResidenceStudyCriteriaEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { companyStudyCriterias: (CompanyStudyCriteria | null)[] } {
  const { companyStudyCriterias, companies, residenceStudyCriterias } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    companyStudyCriterias: Object.keys(companyStudyCriterias).map(idCompanyStudyCriteria =>
      hydrate(
        companyStudyCriterias[idCompanyStudyCriteria] as CompanyStudyCriteriaEntityState,
        companies,
        residenceStudyCriterias
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { companyStudyCriteria: CompanyStudyCriteriaEntityState | null } {
  const { companyStudyCriterias, companies, residenceStudyCriterias } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const companyStudyCriteria = Object.values(companyStudyCriterias)[0];
  return {
    companyStudyCriteria: hydrate(
      companyStudyCriteria as CompanyStudyCriteriaEntityState,
      companies,
      residenceStudyCriterias
    )
  };
}

function hydrate(
  companyStudyCriteria: CompanyStudyCriteriaEntityState,
  companyEntities?: Dictionary<CompanyEntityState>,
  residenceStudyCriteriaEntities?: Dictionary<ResidenceStudyCriteriaEntityState>
): CompanyStudyCriteria | null {
  if (!companyStudyCriteria) {
    return null;
  }

  const companyStudyCriteriaHydrated: CompanyStudyCriteriaEntityState = { ...companyStudyCriteria };
  if (companyEntities) {
    companyStudyCriteriaHydrated.company = companyEntities[companyStudyCriteria.company as number] as Company;
  } else {
    delete companyStudyCriteriaHydrated.company;
  }

  if (residenceStudyCriteriaEntities) {
    companyStudyCriteriaHydrated.residenceStudyCriterias = (
      (companyStudyCriteriaHydrated.residenceStudyCriterias as number[]) || []
    ).map(id => residenceStudyCriteriaEntities[id]) as ResidenceStudyCriteria[];
  } else {
    delete companyStudyCriteriaHydrated.residenceStudyCriterias;
  }

  return companyStudyCriteriaHydrated as CompanyStudyCriteria;
}
