export enum StudyPathEnum {
  // etude
  funnel = 'funnel',
  residenceStudy = 'residence-studies',
  studySynthesis = 'synthese-study',
  // residence detail
  studyDetail = 'detail-study',
  details = 'details',
  todosStudy = 'todos-study',
  documentResidenceStudy = 'document-residence-study',
  residenceStudyWorks = 'residence-work-study'
}
