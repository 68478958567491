import { OrganizationStratalotTodoProfilState, initialState } from './organization-stratalot-todo-profil.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationStratalotTodoProfilReducersGeneratedFunctions } from './organization-stratalot-todo-profil-generated.reducer';

const organizationStratalotTodoProfilReducersFunctions = [...organizationStratalotTodoProfilReducersGeneratedFunctions];

const organizationStratalotTodoProfilReducer = createReducer(
  initialState,
  ...organizationStratalotTodoProfilReducersFunctions
);

export function reducer(state: OrganizationStratalotTodoProfilState | undefined, action: Action) {
  return organizationStratalotTodoProfilReducer(state, action);
}
