import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResidencePriceGrid, ResidencePriceGridEntityState } from '@_model/interfaces/residence-price-grid.model';
import { StratalotPriceList, StratalotPriceListEntityState } from '@_model/interfaces/stratalot-price-list.model';
import { StratalotPrice, StratalotPriceEntityState } from '@_model/interfaces/stratalot-price.model';
import {
  ResidencePriceGridModifier,
  ResidencePriceGridModifierEntityState
} from '@_model/interfaces/residence-price-grid-modifier.model';
import {
  ResidencePriceGridHistory,
  ResidencePriceGridHistoryEntityState
} from '@_model/interfaces/residence-price-grid-history.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, residencePriceGridFeatureKey, ResidencePriceGridState } from './residence-price-grid.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residencePriceGridRelations: string[] = [
  'stratalotPriceLists',
  'stratalotPrices',
  'residencePriceGridModifiers',
  'residencePriceGridHistories',
  'residences'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidencePriceGridState =
  createFeatureSelector<ResidencePriceGridState>(residencePriceGridFeatureKey);

export const selectIsLoadedResidencePriceGrid = createSelector(
  selectResidencePriceGridState,
  (state: ResidencePriceGridState) => state.isLoaded
);

export const selectIsLoadingResidencePriceGrid = createSelector(
  selectResidencePriceGridState,
  (state: ResidencePriceGridState) => state.isLoading
);

export const selectIsReadyResidencePriceGrid = createSelector(
  selectResidencePriceGridState,
  (state: ResidencePriceGridState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidencePriceGrid = createSelector(
  selectResidencePriceGridState,
  (state: ResidencePriceGridState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidencePriceGridModel: SelectorModel = {
  name: 'residencePriceGrids',
  getSelector: selectAllResidencePriceGridsDictionary,
  isReady: selectIsReadyResidencePriceGrid
};

export const selectResidencePriceGridsEntities = createSelector(selectResidencePriceGridState, selectEntities);

export const selectResidencePriceGridsArray = createSelector(selectResidencePriceGridState, selectAll);

export const selectIdResidencePriceGridsActive = createSelector(
  selectResidencePriceGridState,
  (state: ResidencePriceGridState) => state.actives
);

const residencePriceGridsInObject = (residencePriceGrids: Dictionary<ResidencePriceGridEntityState>) => ({
  residencePriceGrids
});

const selectResidencePriceGridsEntitiesDictionary = createSelector(
  selectResidencePriceGridsEntities,
  residencePriceGridsInObject
);

const selectAllResidencePriceGridsObject = createSelector(selectResidencePriceGridsEntities, residencePriceGrids => {
  return hydrateAll({ residencePriceGrids });
});

const selectOneResidencePriceGridDictionary = (idResidencePriceGrid: number) =>
  createSelector(selectResidencePriceGridsEntities, residencePriceGrids => {
    return { residencePriceGrids: { [idResidencePriceGrid]: residencePriceGrids[idResidencePriceGrid] } };
  });

const selectOneResidencePriceGridDictionaryWithoutChild = (idResidencePriceGrid: number) =>
  createSelector(selectResidencePriceGridsEntities, residencePriceGrids => {
    return { residencePriceGrid: residencePriceGrids[idResidencePriceGrid] };
  });

const selectActiveResidencePriceGridsEntities = createSelector(
  selectIdResidencePriceGridsActive,
  selectResidencePriceGridsEntities,
  (actives: number[], residencePriceGrids: Dictionary<ResidencePriceGridEntityState>) =>
    getResidencePriceGridsFromActives(actives, residencePriceGrids)
);

function getResidencePriceGridsFromActives(
  actives: number[],
  residencePriceGrids: Dictionary<ResidencePriceGridEntityState>
): Dictionary<ResidencePriceGridEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residencePriceGrids[idActive]) {
      acc[idActive] = residencePriceGrids[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidencePriceGridEntityState>);
}

const selectAllResidencePriceGridsSelectors: Dictionary<Selector> = {};
export function selectAllResidencePriceGrids(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidencePriceGrid>(
      schema,
      selectAllResidencePriceGridsSelectors,
      selectResidencePriceGridsEntitiesDictionary,
      getRelationSelectors,
      residencePriceGridRelations,
      hydrateAll,
      'residencePriceGrid'
    );
  } else {
    return selectAllResidencePriceGridsObject;
  }
}

export function selectAllResidencePriceGridsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residencePriceGrids'
): Selector {
  return createSelector(selectAllResidencePriceGrids(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidencePriceGridEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residencePriceGrids.length; i++) {
      res[customKey][result.residencePriceGrids[i].idResidencePriceGrid] = result.residencePriceGrids[i];
    }
    return res;
  });
}

export function selectOneResidencePriceGrid(schema: SelectSchema = {}, idResidencePriceGrid: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidencePriceGridDictionary(idResidencePriceGrid)];
    selectors.push(...getRelationSelectors(schema, residencePriceGridRelations, 'residencePriceGrid'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidencePriceGridDictionaryWithoutChild(idResidencePriceGrid);
  }
}

export function selectActiveResidencePriceGrids(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidencePriceGridsEntities, residencePriceGrids => ({ residencePriceGrids }))
  ];
  selectors.push(...getRelationSelectors(schema, residencePriceGridRelations, 'residencePriceGrid'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residencePriceGrids: Dictionary<ResidencePriceGridEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
  stratalotPriceLists?: Dictionary<StratalotPriceListEntityState>;
  stratalotPrices?: Dictionary<StratalotPriceEntityState>;
  residencePriceGridModifiers?: Dictionary<ResidencePriceGridModifierEntityState>;
  residencePriceGridHistories?: Dictionary<ResidencePriceGridHistoryEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { residencePriceGrids: (ResidencePriceGrid | null)[] } {
  const {
    residencePriceGrids,
    residences,
    stratalotPriceLists,
    stratalotPrices,
    residencePriceGridModifiers,
    residencePriceGridHistories
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    residencePriceGrids: Object.keys(residencePriceGrids).map(idResidencePriceGrid =>
      hydrate(
        residencePriceGrids[idResidencePriceGrid] as ResidencePriceGridEntityState,
        residences,
        stratalotPriceLists,
        stratalotPrices,
        residencePriceGridModifiers,
        residencePriceGridHistories
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { residencePriceGrid: ResidencePriceGridEntityState | null } {
  const {
    residencePriceGrids,
    residences,
    stratalotPriceLists,
    stratalotPrices,
    residencePriceGridModifiers,
    residencePriceGridHistories
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const residencePriceGrid = Object.values(residencePriceGrids)[0];
  return {
    residencePriceGrid: hydrate(
      residencePriceGrid as ResidencePriceGridEntityState,
      residences,
      stratalotPriceLists,
      stratalotPrices,
      residencePriceGridModifiers,
      residencePriceGridHistories
    )
  };
}

function hydrate(
  residencePriceGrid: ResidencePriceGridEntityState,
  residenceEntities?: Dictionary<ResidenceEntityState>,
  stratalotPriceListEntities?: Dictionary<StratalotPriceListEntityState>,
  stratalotPriceEntities?: Dictionary<StratalotPriceEntityState>,
  residencePriceGridModifierEntities?: Dictionary<ResidencePriceGridModifierEntityState>,
  residencePriceGridHistoryEntities?: Dictionary<ResidencePriceGridHistoryEntityState>
): ResidencePriceGrid | null {
  if (!residencePriceGrid) {
    return null;
  }

  const residencePriceGridHydrated: ResidencePriceGridEntityState = { ...residencePriceGrid };
  if (residenceEntities) {
    residencePriceGridHydrated.residence = residenceEntities[residencePriceGrid.residence as number] as Residence;
  } else {
    delete residencePriceGridHydrated.residence;
  }

  if (stratalotPriceListEntities) {
    residencePriceGridHydrated.stratalotPriceLists = (
      (residencePriceGridHydrated.stratalotPriceLists as number[]) || []
    ).map(id => stratalotPriceListEntities[id]) as StratalotPriceList[];
  } else {
    delete residencePriceGridHydrated.stratalotPriceLists;
  }

  if (stratalotPriceEntities) {
    residencePriceGridHydrated.stratalotPrices = ((residencePriceGridHydrated.stratalotPrices as number[]) || []).map(
      id => stratalotPriceEntities[id]
    ) as StratalotPrice[];
  } else {
    delete residencePriceGridHydrated.stratalotPrices;
  }

  if (residencePriceGridModifierEntities) {
    residencePriceGridHydrated.residencePriceGridModifiers = (
      (residencePriceGridHydrated.residencePriceGridModifiers as number[]) || []
    ).map(id => residencePriceGridModifierEntities[id]) as ResidencePriceGridModifier[];
  } else {
    delete residencePriceGridHydrated.residencePriceGridModifiers;
  }

  if (residencePriceGridHistoryEntities) {
    residencePriceGridHydrated.residencePriceGridHistories = (
      (residencePriceGridHydrated.residencePriceGridHistories as number[]) || []
    ).map(id => residencePriceGridHistoryEntities[id]) as ResidencePriceGridHistory[];
  } else {
    delete residencePriceGridHydrated.residencePriceGridHistories;
  }

  return residencePriceGridHydrated as ResidencePriceGrid;
}
