import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyPriceLabel, CompanyPriceLabelEntityState } from '@_model/interfaces/company-price-label.model';
import { StratalotPrice, StratalotPriceEntityState } from '@_model/interfaces/stratalot-price.model';
import { StratalotPriceValue, StratalotPriceValueEntityState } from '@_model/interfaces/stratalot-price-value.model';
import {
  ResidencePriceGridModifier,
  ResidencePriceGridModifierEntityState
} from '@_model/interfaces/residence-price-grid-modifier.model';
import { OrganizationProfil, OrganizationProfilEntityState } from '@_model/interfaces/organization-profil.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { SaleCategoryFamily, SaleCategoryFamilyEntityState } from '@_model/interfaces/sale-category-family.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, companyPriceLabelFeatureKey, CompanyPriceLabelState } from './company-price-label.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyPriceLabelRelations: string[] = [
  'stratalotPrices',
  'stratalotPriceValues',
  'residencePriceGridModifiers',
  'organizationProfils',
  'companies',
  'familySaleCategories'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyPriceLabelState = createFeatureSelector<CompanyPriceLabelState>(companyPriceLabelFeatureKey);

export const selectIsLoadedCompanyPriceLabel = createSelector(
  selectCompanyPriceLabelState,
  (state: CompanyPriceLabelState) => state.isLoaded
);

export const selectIsLoadingCompanyPriceLabel = createSelector(
  selectCompanyPriceLabelState,
  (state: CompanyPriceLabelState) => state.isLoading
);

export const selectIsReadyCompanyPriceLabel = createSelector(
  selectCompanyPriceLabelState,
  (state: CompanyPriceLabelState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyPriceLabel = createSelector(
  selectCompanyPriceLabelState,
  (state: CompanyPriceLabelState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyPriceLabelModel: SelectorModel = {
  name: 'companyPriceLabels',
  getSelector: selectAllCompanyPriceLabelsDictionary,
  isReady: selectIsReadyCompanyPriceLabel
};

export const selectCompanyPriceLabelsEntities = createSelector(selectCompanyPriceLabelState, selectEntities);

export const selectCompanyPriceLabelsArray = createSelector(selectCompanyPriceLabelState, selectAll);

export const selectIdCompanyPriceLabelsActive = createSelector(
  selectCompanyPriceLabelState,
  (state: CompanyPriceLabelState) => state.actives
);

const companyPriceLabelsInObject = (companyPriceLabels: Dictionary<CompanyPriceLabelEntityState>) => ({
  companyPriceLabels
});

const selectCompanyPriceLabelsEntitiesDictionary = createSelector(
  selectCompanyPriceLabelsEntities,
  companyPriceLabelsInObject
);

const selectAllCompanyPriceLabelsObject = createSelector(selectCompanyPriceLabelsEntities, companyPriceLabels => {
  return hydrateAll({ companyPriceLabels });
});

const selectOneCompanyPriceLabelDictionary = (idCompanyPriceLabel: number) =>
  createSelector(selectCompanyPriceLabelsEntities, companyPriceLabels => {
    return { companyPriceLabels: { [idCompanyPriceLabel]: companyPriceLabels[idCompanyPriceLabel] } };
  });

const selectOneCompanyPriceLabelDictionaryWithoutChild = (idCompanyPriceLabel: number) =>
  createSelector(selectCompanyPriceLabelsEntities, companyPriceLabels => {
    return { companyPriceLabel: companyPriceLabels[idCompanyPriceLabel] };
  });

const selectActiveCompanyPriceLabelsEntities = createSelector(
  selectIdCompanyPriceLabelsActive,
  selectCompanyPriceLabelsEntities,
  (actives: number[], companyPriceLabels: Dictionary<CompanyPriceLabelEntityState>) =>
    getCompanyPriceLabelsFromActives(actives, companyPriceLabels)
);

function getCompanyPriceLabelsFromActives(
  actives: number[],
  companyPriceLabels: Dictionary<CompanyPriceLabelEntityState>
): Dictionary<CompanyPriceLabelEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyPriceLabels[idActive]) {
      acc[idActive] = companyPriceLabels[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyPriceLabelEntityState>);
}

const selectAllCompanyPriceLabelsSelectors: Dictionary<Selector> = {};
export function selectAllCompanyPriceLabels(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyPriceLabel>(
      schema,
      selectAllCompanyPriceLabelsSelectors,
      selectCompanyPriceLabelsEntitiesDictionary,
      getRelationSelectors,
      companyPriceLabelRelations,
      hydrateAll,
      'companyPriceLabel'
    );
  } else {
    return selectAllCompanyPriceLabelsObject;
  }
}

export function selectAllCompanyPriceLabelsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyPriceLabels'
): Selector {
  return createSelector(selectAllCompanyPriceLabels(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyPriceLabelEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyPriceLabels.length; i++) {
      res[customKey][result.companyPriceLabels[i].idCompanyPriceLabel] = result.companyPriceLabels[i];
    }
    return res;
  });
}

export function selectOneCompanyPriceLabel(schema: SelectSchema = {}, idCompanyPriceLabel: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyPriceLabelDictionary(idCompanyPriceLabel)];
    selectors.push(...getRelationSelectors(schema, companyPriceLabelRelations, 'companyPriceLabel'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyPriceLabelDictionaryWithoutChild(idCompanyPriceLabel);
  }
}

export function selectActiveCompanyPriceLabels(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyPriceLabelsEntities, companyPriceLabels => ({ companyPriceLabels }))
  ];
  selectors.push(...getRelationSelectors(schema, companyPriceLabelRelations, 'companyPriceLabel'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyPriceLabels: Dictionary<CompanyPriceLabelEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  familySaleCategories?: Dictionary<SaleCategoryFamilyEntityState>;
  stratalotPrices?: Dictionary<StratalotPriceEntityState>;
  stratalotPriceValues?: Dictionary<StratalotPriceValueEntityState>;
  residencePriceGridModifiers?: Dictionary<ResidencePriceGridModifierEntityState>;
  organizationProfils?: Dictionary<OrganizationProfilEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { companyPriceLabels: (CompanyPriceLabel | null)[] } {
  const {
    companyPriceLabels,
    companies,
    familySaleCategories,
    stratalotPrices,
    stratalotPriceValues,
    residencePriceGridModifiers,
    organizationProfils
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    companyPriceLabels: Object.keys(companyPriceLabels).map(idCompanyPriceLabel =>
      hydrate(
        companyPriceLabels[idCompanyPriceLabel] as CompanyPriceLabelEntityState,
        companies,
        familySaleCategories,
        stratalotPrices,
        stratalotPriceValues,
        residencePriceGridModifiers,
        organizationProfils
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { companyPriceLabel: CompanyPriceLabelEntityState | null } {
  const {
    companyPriceLabels,
    companies,
    familySaleCategories,
    stratalotPrices,
    stratalotPriceValues,
    residencePriceGridModifiers,
    organizationProfils
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const companyPriceLabel = Object.values(companyPriceLabels)[0];
  return {
    companyPriceLabel: hydrate(
      companyPriceLabel as CompanyPriceLabelEntityState,
      companies,
      familySaleCategories,
      stratalotPrices,
      stratalotPriceValues,
      residencePriceGridModifiers,
      organizationProfils
    )
  };
}

function hydrate(
  companyPriceLabel: CompanyPriceLabelEntityState,
  companyEntities?: Dictionary<CompanyEntityState>,
  saleCategoryFamilyEntities?: Dictionary<SaleCategoryFamilyEntityState>,
  stratalotPriceEntities?: Dictionary<StratalotPriceEntityState>,
  stratalotPriceValueEntities?: Dictionary<StratalotPriceValueEntityState>,
  residencePriceGridModifierEntities?: Dictionary<ResidencePriceGridModifierEntityState>,
  organizationProfilEntities?: Dictionary<OrganizationProfilEntityState>
): CompanyPriceLabel | null {
  if (!companyPriceLabel) {
    return null;
  }

  const companyPriceLabelHydrated: CompanyPriceLabelEntityState = { ...companyPriceLabel };
  if (companyEntities) {
    companyPriceLabelHydrated.company = companyEntities[companyPriceLabel.company as number] as Company;
  } else {
    delete companyPriceLabelHydrated.company;
  }
  if (saleCategoryFamilyEntities) {
    companyPriceLabelHydrated.saleCategoryFamily = saleCategoryFamilyEntities[
      companyPriceLabel.saleCategoryFamily as number
    ] as SaleCategoryFamily;
  } else {
    delete companyPriceLabelHydrated.saleCategoryFamily;
  }

  if (stratalotPriceEntities) {
    companyPriceLabelHydrated.stratalotPrices = ((companyPriceLabelHydrated.stratalotPrices as number[]) || []).map(
      id => stratalotPriceEntities[id]
    ) as StratalotPrice[];
  } else {
    delete companyPriceLabelHydrated.stratalotPrices;
  }

  if (stratalotPriceValueEntities) {
    companyPriceLabelHydrated.stratalotPriceValues = (
      (companyPriceLabelHydrated.stratalotPriceValues as number[]) || []
    ).map(id => stratalotPriceValueEntities[id]) as StratalotPriceValue[];
  } else {
    delete companyPriceLabelHydrated.stratalotPriceValues;
  }

  if (residencePriceGridModifierEntities) {
    companyPriceLabelHydrated.residencePriceGridModifiers = (
      (companyPriceLabelHydrated.residencePriceGridModifiers as number[]) || []
    ).map(id => residencePriceGridModifierEntities[id]) as ResidencePriceGridModifier[];
  } else {
    delete companyPriceLabelHydrated.residencePriceGridModifiers;
  }

  if (organizationProfilEntities) {
    companyPriceLabelHydrated.organizationProfils = (
      (companyPriceLabelHydrated.organizationProfils as number[]) || []
    ).map(id => organizationProfilEntities[id]) as OrganizationProfil[];
  } else {
    delete companyPriceLabelHydrated.organizationProfils;
  }

  return companyPriceLabelHydrated as CompanyPriceLabel;
}
