import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CompanyCommunicationFile,
  CompanyCommunicationFileEntityState
} from '@_model/interfaces/company-communication-file.model';
import { CompanyCommunication, CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  companyCommunicationFileFeatureKey,
  CompanyCommunicationFileState
} from './company-communication-file.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyCommunicationFileRelations: string[] = ['companyCommunications'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyCommunicationFileState = createFeatureSelector<CompanyCommunicationFileState>(
  companyCommunicationFileFeatureKey
);

export const selectIsLoadedCompanyCommunicationFile = createSelector(
  selectCompanyCommunicationFileState,
  (state: CompanyCommunicationFileState) => state.isLoaded
);

export const selectIsLoadingCompanyCommunicationFile = createSelector(
  selectCompanyCommunicationFileState,
  (state: CompanyCommunicationFileState) => state.isLoading
);

export const selectIsReadyCompanyCommunicationFile = createSelector(
  selectCompanyCommunicationFileState,
  (state: CompanyCommunicationFileState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyCommunicationFile = createSelector(
  selectCompanyCommunicationFileState,
  (state: CompanyCommunicationFileState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyCommunicationFileModel: SelectorModel = {
  name: 'companyCommunicationFiles',
  getSelector: selectAllCompanyCommunicationFilesDictionary,
  isReady: selectIsReadyCompanyCommunicationFile
};

export const selectCompanyCommunicationFilesEntities = createSelector(
  selectCompanyCommunicationFileState,
  selectEntities
);

export const selectCompanyCommunicationFilesArray = createSelector(selectCompanyCommunicationFileState, selectAll);

export const selectIdCompanyCommunicationFilesActive = createSelector(
  selectCompanyCommunicationFileState,
  (state: CompanyCommunicationFileState) => state.actives
);

const companyCommunicationFilesInObject = (
  companyCommunicationFiles: Dictionary<CompanyCommunicationFileEntityState>
) => ({ companyCommunicationFiles });

const selectCompanyCommunicationFilesEntitiesDictionary = createSelector(
  selectCompanyCommunicationFilesEntities,
  companyCommunicationFilesInObject
);

const selectAllCompanyCommunicationFilesObject = createSelector(
  selectCompanyCommunicationFilesEntities,
  companyCommunicationFiles => {
    return hydrateAll({ companyCommunicationFiles });
  }
);

const selectOneCompanyCommunicationFileDictionary = (idCompanyCommunicationFile: number) =>
  createSelector(selectCompanyCommunicationFilesEntities, companyCommunicationFiles => {
    return {
      companyCommunicationFiles: { [idCompanyCommunicationFile]: companyCommunicationFiles[idCompanyCommunicationFile] }
    };
  });

const selectOneCompanyCommunicationFileDictionaryWithoutChild = (idCompanyCommunicationFile: number) =>
  createSelector(selectCompanyCommunicationFilesEntities, companyCommunicationFiles => {
    return { companyCommunicationFile: companyCommunicationFiles[idCompanyCommunicationFile] };
  });

const selectActiveCompanyCommunicationFilesEntities = createSelector(
  selectIdCompanyCommunicationFilesActive,
  selectCompanyCommunicationFilesEntities,
  (actives: number[], companyCommunicationFiles: Dictionary<CompanyCommunicationFileEntityState>) =>
    getCompanyCommunicationFilesFromActives(actives, companyCommunicationFiles)
);

function getCompanyCommunicationFilesFromActives(
  actives: number[],
  companyCommunicationFiles: Dictionary<CompanyCommunicationFileEntityState>
): Dictionary<CompanyCommunicationFileEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyCommunicationFiles[idActive]) {
      acc[idActive] = companyCommunicationFiles[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyCommunicationFileEntityState>);
}

const selectAllCompanyCommunicationFilesSelectors: Dictionary<Selector> = {};
export function selectAllCompanyCommunicationFiles(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyCommunicationFile>(
      schema,
      selectAllCompanyCommunicationFilesSelectors,
      selectCompanyCommunicationFilesEntitiesDictionary,
      getRelationSelectors,
      companyCommunicationFileRelations,
      hydrateAll,
      'companyCommunicationFile'
    );
  } else {
    return selectAllCompanyCommunicationFilesObject;
  }
}

export function selectAllCompanyCommunicationFilesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyCommunicationFiles'
): Selector {
  return createSelector(selectAllCompanyCommunicationFiles(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyCommunicationFileEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyCommunicationFiles.length; i++) {
      res[customKey][result.companyCommunicationFiles[i].idCompanyCommunicationFile] =
        result.companyCommunicationFiles[i];
    }
    return res;
  });
}

export function selectOneCompanyCommunicationFile(
  schema: SelectSchema = {},
  idCompanyCommunicationFile: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyCommunicationFileDictionary(idCompanyCommunicationFile)];
    selectors.push(...getRelationSelectors(schema, companyCommunicationFileRelations, 'companyCommunicationFile'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyCommunicationFileDictionaryWithoutChild(idCompanyCommunicationFile);
  }
}

export function selectActiveCompanyCommunicationFiles(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyCommunicationFilesEntities, companyCommunicationFiles => ({
      companyCommunicationFiles
    }))
  ];
  selectors.push(...getRelationSelectors(schema, companyCommunicationFileRelations, 'companyCommunicationFile'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyCommunicationFiles: Dictionary<CompanyCommunicationFileEntityState>;
  companyCommunications?: Dictionary<CompanyCommunicationEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { companyCommunicationFiles: (CompanyCommunicationFile | null)[] } {
  const { companyCommunicationFiles, companyCommunications } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    companyCommunicationFiles: Object.keys(companyCommunicationFiles).map(idCompanyCommunicationFile =>
      hydrate(
        companyCommunicationFiles[idCompanyCommunicationFile] as CompanyCommunicationFileEntityState,
        companyCommunications
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { companyCommunicationFile: CompanyCommunicationFileEntityState | null } {
  const { companyCommunicationFiles, companyCommunications } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const companyCommunicationFile = Object.values(companyCommunicationFiles)[0];
  return {
    companyCommunicationFile: hydrate(
      companyCommunicationFile as CompanyCommunicationFileEntityState,
      companyCommunications
    )
  };
}

function hydrate(
  companyCommunicationFile: CompanyCommunicationFileEntityState,
  companyCommunicationEntities?: Dictionary<CompanyCommunicationEntityState>
): CompanyCommunicationFile | null {
  if (!companyCommunicationFile) {
    return null;
  }

  const companyCommunicationFileHydrated: CompanyCommunicationFileEntityState = { ...companyCommunicationFile };
  if (companyCommunicationEntities) {
    companyCommunicationFileHydrated.companyCommunication = companyCommunicationEntities[
      companyCommunicationFile.companyCommunication as number
    ] as CompanyCommunication;
  } else {
    delete companyCommunicationFileHydrated.companyCommunication;
  }

  return companyCommunicationFileHydrated as CompanyCommunicationFile;
}
