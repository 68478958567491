import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedResidenceSaleCategoryFamilyApiService } from './residence-sale-category-family-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ResidenceSaleCategoryFamilyApiService extends GeneratedResidenceSaleCategoryFamilyApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
