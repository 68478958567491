import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationStratalotTodoActions from './organization-stratalot-todo.actions';
import { adapter, initialState, OrganizationStratalotTodoState } from './organization-stratalot-todo.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationStratalotTodoEntityState } from '@_model/interfaces/organization-stratalot-todo.model';

export const organizationStratalotTodoReducersGeneratedFunctions: ReducerTypes<
  OrganizationStratalotTodoState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationStratalotTodoActions.getOneOrganizationStratalotTodo, (state: OrganizationStratalotTodoState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationStratalotTodoActions.getManyOrganizationStratalotTodos, (state: OrganizationStratalotTodoState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationStratalotTodoActions.upsertOneOrganizationStratalotTodo, (state: OrganizationStratalotTodoState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationStratalotTodoActions.upsertManyOrganizationStratalotTodosSuccess,
    (state: OrganizationStratalotTodoState, { organizationStratalotTodos }) =>
      adapter.upsertMany(organizationStratalotTodos, setLoadingsState(state, false))
  ),
  on(OrganizationStratalotTodoActions.deleteOneOrganizationStratalotTodo, (state: OrganizationStratalotTodoState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationStratalotTodoActions.deleteOneOrganizationStratalotTodoSuccess,
    (state: OrganizationStratalotTodoState, { idOrganizationStratalotTodo }) =>
      adapter.removeOne(idOrganizationStratalotTodo, setLoadingsState(state, false))
  ),
  on(OrganizationStratalotTodoActions.clearActive, (state: OrganizationStratalotTodoState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationStratalotTodoActions.addManyActives,
    (state: OrganizationStratalotTodoState, { idOrganizationStratalotTodos }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationStratalotTodos)
    })
  ),
  on(
    OrganizationStratalotTodoActions.deleteOneActive,
    (state: OrganizationStratalotTodoState, { idOrganizationStratalotTodo }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationStratalotTodo)
    })
  ),

  on(OrganizationStratalotTodoActions.clearStore, () => initialState),

  on(
    OrganizationStratalotTodoActions.addManyStratalotTodoSuccess,
    (state: OrganizationStratalotTodoState, { idOrganizationStratalotTodo, idStratalotTodos }) => {
      if (!state.entities[idOrganizationStratalotTodo]) {
        return state;
      }
      const stratalotTodos = (state.entities[idOrganizationStratalotTodo]?.stratalotTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodo]: {
            ...state.entities[idOrganizationStratalotTodo],
            stratalotTodos: stratalotTodos.concat(idStratalotTodos.filter(id => stratalotTodos.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoActions.deleteManyStratalotTodoSuccess,
    (state: OrganizationStratalotTodoState, { idStratalotTodos, idOrganizationStratalotTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodos.reduce((entities, idOrganizationStratalotTodo) => {
            if (!state.entities[idOrganizationStratalotTodo]?.stratalotTodos) {
              return entities;
            }
            entities[idOrganizationStratalotTodo] = {
              ...state.entities[idOrganizationStratalotTodo],
              stratalotTodos: (state.entities[idOrganizationStratalotTodo]?.stratalotTodos as number[])?.filter(
                (idStratalotTodo: number) => !idStratalotTodos.some((id: number) => id === idStratalotTodo)
              )
            } as OrganizationStratalotTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoActions.addManyOrganizationStratalotTodoRuleSuccess,
    (state: OrganizationStratalotTodoState, { idOrganizationStratalotTodo, idOrganizationStratalotTodoRules }) => {
      if (!state.entities[idOrganizationStratalotTodo]) {
        return state;
      }
      const organizationStratalotTodoRules =
        (state.entities[idOrganizationStratalotTodo]?.organizationStratalotTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodo]: {
            ...state.entities[idOrganizationStratalotTodo],
            organizationStratalotTodoRules: organizationStratalotTodoRules.concat(
              idOrganizationStratalotTodoRules.filter(id => organizationStratalotTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoActions.deleteManyOrganizationStratalotTodoRuleSuccess,
    (state: OrganizationStratalotTodoState, { idOrganizationStratalotTodoRules, idOrganizationStratalotTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodos.reduce((entities, idOrganizationStratalotTodo) => {
            if (!state.entities[idOrganizationStratalotTodo]?.organizationStratalotTodoRules) {
              return entities;
            }
            entities[idOrganizationStratalotTodo] = {
              ...state.entities[idOrganizationStratalotTodo],
              organizationStratalotTodoRules: (
                state.entities[idOrganizationStratalotTodo]?.organizationStratalotTodoRules as number[]
              )?.filter(
                (idOrganizationStratalotTodoRule: number) =>
                  !idOrganizationStratalotTodoRules.some((id: number) => id === idOrganizationStratalotTodoRule)
              )
            } as OrganizationStratalotTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoActions.addManyOrganizationStratalotTodoAppliedRuleSuccess,
    (
      state: OrganizationStratalotTodoState,
      { idOrganizationStratalotTodo, idOrganizationStratalotTodoAppliedRules }
    ) => {
      if (!state.entities[idOrganizationStratalotTodo]) {
        return state;
      }
      const organizationStratalotTodoAppliedRules =
        (state.entities[idOrganizationStratalotTodo]?.organizationStratalotTodoAppliedRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodo]: {
            ...state.entities[idOrganizationStratalotTodo],
            organizationStratalotTodoAppliedRules: organizationStratalotTodoAppliedRules.concat(
              idOrganizationStratalotTodoAppliedRules.filter(
                id => organizationStratalotTodoAppliedRules.indexOf(id) < 0
              )
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoActions.deleteManyOrganizationStratalotTodoAppliedRuleSuccess,
    (
      state: OrganizationStratalotTodoState,
      { idOrganizationStratalotTodoAppliedRules, idOrganizationStratalotTodos }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodos.reduce((entities, idOrganizationStratalotTodo) => {
            if (!state.entities[idOrganizationStratalotTodo]?.organizationStratalotTodoAppliedRules) {
              return entities;
            }
            entities[idOrganizationStratalotTodo] = {
              ...state.entities[idOrganizationStratalotTodo],
              organizationStratalotTodoAppliedRules: (
                state.entities[idOrganizationStratalotTodo]?.organizationStratalotTodoAppliedRules as number[]
              )?.filter(
                (idOrganizationStratalotTodoAppliedRule: number) =>
                  !idOrganizationStratalotTodoAppliedRules.some(
                    (id: number) => id === idOrganizationStratalotTodoAppliedRule
                  )
              )
            } as OrganizationStratalotTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoActions.addManyOrganizationStratalotTodoProfilSuccess,
    (state: OrganizationStratalotTodoState, { idOrganizationStratalotTodo, idOrganizationStratalotTodoProfils }) => {
      if (!state.entities[idOrganizationStratalotTodo]) {
        return state;
      }
      const organizationStratalotTodoProfils =
        (state.entities[idOrganizationStratalotTodo]?.organizationStratalotTodoProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodo]: {
            ...state.entities[idOrganizationStratalotTodo],
            organizationStratalotTodoProfils: organizationStratalotTodoProfils.concat(
              idOrganizationStratalotTodoProfils.filter(id => organizationStratalotTodoProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoActions.deleteManyOrganizationStratalotTodoProfilSuccess,
    (state: OrganizationStratalotTodoState, { idOrganizationStratalotTodoProfils, idOrganizationStratalotTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodos.reduce((entities, idOrganizationStratalotTodo) => {
            if (!state.entities[idOrganizationStratalotTodo]?.organizationStratalotTodoProfils) {
              return entities;
            }
            entities[idOrganizationStratalotTodo] = {
              ...state.entities[idOrganizationStratalotTodo],
              organizationStratalotTodoProfils: (
                state.entities[idOrganizationStratalotTodo]?.organizationStratalotTodoProfils as number[]
              )?.filter(
                (idOrganizationStratalotTodoProfil: number) =>
                  !idOrganizationStratalotTodoProfils.some((id: number) => id === idOrganizationStratalotTodoProfil)
              )
            } as OrganizationStratalotTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoActions.addManyCompanyCommunicationSuccess,
    (state: OrganizationStratalotTodoState, { idOrganizationStratalotTodo, idCompanyCommunications }) => {
      if (!state.entities[idOrganizationStratalotTodo]) {
        return state;
      }
      const companyCommunications =
        (state.entities[idOrganizationStratalotTodo]?.companyCommunications as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodo]: {
            ...state.entities[idOrganizationStratalotTodo],
            companyCommunications: companyCommunications.concat(
              idCompanyCommunications.filter(id => companyCommunications.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoActions.deleteManyCompanyCommunicationSuccess,
    (state: OrganizationStratalotTodoState, { idCompanyCommunications, idOrganizationStratalotTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodos.reduce((entities, idOrganizationStratalotTodo) => {
            if (!state.entities[idOrganizationStratalotTodo]?.companyCommunications) {
              return entities;
            }
            entities[idOrganizationStratalotTodo] = {
              ...state.entities[idOrganizationStratalotTodo],
              companyCommunications: (
                state.entities[idOrganizationStratalotTodo]?.companyCommunications as number[]
              )?.filter(
                (idCompanyCommunication: number) =>
                  !idCompanyCommunications.some((id: number) => id === idCompanyCommunication)
              )
            } as OrganizationStratalotTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoActions.addManyCompanyCommunicationStratalotTodoSuccess,
    (state: OrganizationStratalotTodoState, { idOrganizationStratalotTodo, idCompanyCommunicationStratalotTodos }) => {
      if (!state.entities[idOrganizationStratalotTodo]) {
        return state;
      }
      const companyCommunicationStratalotTodos =
        (state.entities[idOrganizationStratalotTodo]?.companyCommunicationStratalotTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodo]: {
            ...state.entities[idOrganizationStratalotTodo],
            companyCommunicationStratalotTodos: companyCommunicationStratalotTodos.concat(
              idCompanyCommunicationStratalotTodos.filter(id => companyCommunicationStratalotTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoActions.deleteManyCompanyCommunicationStratalotTodoSuccess,
    (state: OrganizationStratalotTodoState, { idCompanyCommunicationStratalotTodos, idOrganizationStratalotTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodos.reduce((entities, idOrganizationStratalotTodo) => {
            if (!state.entities[idOrganizationStratalotTodo]?.companyCommunicationStratalotTodos) {
              return entities;
            }
            entities[idOrganizationStratalotTodo] = {
              ...state.entities[idOrganizationStratalotTodo],
              companyCommunicationStratalotTodos: (
                state.entities[idOrganizationStratalotTodo]?.companyCommunicationStratalotTodos as number[]
              )?.filter(
                (idCompanyCommunicationStratalotTodo: number) =>
                  !idCompanyCommunicationStratalotTodos.some((id: number) => id === idCompanyCommunicationStratalotTodo)
              )
            } as OrganizationStratalotTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoActions.addOrganizationSuccess,
    (state: OrganizationStratalotTodoState, { idOrganizationStratalotTodo, idOrganization }) => {
      if (!state.entities[idOrganizationStratalotTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodo]: {
            ...state.entities[idOrganizationStratalotTodo],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoActions.deleteManyOrganizationSuccess,
    (state: OrganizationStratalotTodoState, { idOrganizations, idOrganizationStratalotTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodos.reduce((entities, idOrganizationStratalotTodo) => {
            if (!state.entities[idOrganizationStratalotTodo]?.organization) {
              return entities;
            }
            entities[idOrganizationStratalotTodo] = {
              ...state.entities[idOrganizationStratalotTodo],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationStratalotTodo]?.organization
              )
                ? undefined
                : state.entities[idOrganizationStratalotTodo]?.organization
            } as OrganizationStratalotTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoActions.addDiagnosticSuccess,
    (state: OrganizationStratalotTodoState, { idOrganizationStratalotTodo, idDiagnostic }) => {
      if (!state.entities[idOrganizationStratalotTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodo]: {
            ...state.entities[idOrganizationStratalotTodo],
            diagnostic: idDiagnostic
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoActions.deleteManyDiagnosticSuccess,
    (state: OrganizationStratalotTodoState, { idDiagnostics, idOrganizationStratalotTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodos.reduce((entities, idOrganizationStratalotTodo) => {
            if (!state.entities[idOrganizationStratalotTodo]?.diagnostic) {
              return entities;
            }
            entities[idOrganizationStratalotTodo] = {
              ...state.entities[idOrganizationStratalotTodo],
              diagnostic: idDiagnostics.some(
                (idDiagnostic: number) => idDiagnostic === state.entities[idOrganizationStratalotTodo]?.diagnostic
              )
                ? undefined
                : state.entities[idOrganizationStratalotTodo]?.diagnostic
            } as OrganizationStratalotTodoEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationStratalotTodoState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationStratalotTodoState {
  return { ...state, isLoaded, isLoading };
}
