import { BuyingProbability } from '@_model/enums/buying-probability.enum';
import { BuyingWish } from '@_model/enums/buying-wish.enum';
import { DisplayIconHelper } from '@_model/interfaces/displayIconHelper';
import { MediaProspectEvent } from '@_shared/models/enums/media-prospect-event.enum';
import { Statut } from '@_shared/models/enums/statut.enum';

export const buyingProbabilityList: DisplayIconHelper[] = [
  { value: BuyingProbability.one, text: 'Faible', icon: 'icon-ic_fluent_star_24_regular' },
  { value: BuyingProbability.two, text: 'Moyen', icon: 'icon-ic_fluent_star_half_24_filled', class: 'app-icon-star' },
  { value: BuyingProbability.three, text: 'Fort', icon: 'icon-ic_fluent_star_24_filled', class: 'app-icon-star' }
];

export const trueFalseArchivedList: DisplayIconHelper[] = [
  { value: 'true', text: 'true', icon: 'icon-ic_fluent_checkbox_checked_24_regular', class: 'app-icon-green' },
  { value: 'false', text: 'false', icon: '' }
];

export const lockUnlockList: DisplayIconHelper[] = [
  { value: 'lock', text: 'Verouillé', icon: 'icon-ic_fluent_lock_open_24_regular', class: 'app-icon-green' },
  { value: 'unlock', text: 'Déverouillé', icon: 'icon-ic_fluent_lock_closed_24_regular', class: 'app-icon-gray' }
];

export const buyingProbabilityDefault: DisplayIconHelper = {
  value: BuyingProbability.one,
  text: 'Faible',
  icon: 'icon-ic_fluent_star_24_regular'
};

export const defaultBuyingWishIconList: DisplayIconHelper[] = [
  {
    value: BuyingWish.interestedIn,
    text: 'Intérêt',
    icon: 'icon-ic_fluent_my_location_24_regular',
    class: 'app-icon-green'
  },
  {
    value: BuyingWish.reflexion,
    text: 'A relancer',
    icon: 'icon-ic_fluent_arrow_bounce_24_regular',
    class: 'app-icon-green'
  },
  {
    value: BuyingWish.toQualify,
    text: 'A qualifier',
    icon: 'icon-ic_fluent_warning_24_regular',
    class: 'app-icon-grey'
  },
  { value: BuyingWish.refusal, text: 'Refus', icon: 'icon-ic_fluent_prohibited_24_regular', class: 'app-icon-red' }
];
export const buyingWishDefault: DisplayIconHelper = {
  value: BuyingWish.toQualify,
  text: 'A qualifier',
  icon: 'icon-ic_fluent_warning_24_regular',
  class: 'app-icon-grey'
};

export const eventListGroup: DisplayIconHelper[] = [
  {
    value: 'Commentaire',
    text: 'Commentaire',
    icon: 'icon-ic_fluent_chat_24_regular',
    children: [
      {
        value: MediaProspectEvent.suiviAvancement,
        text: "Suivi d'avancement",
        icon: 'icon-ic_fluent_chat_24_regular',
        class: 'app-icon-blue',
        label: "Suivi d'avancement du dossier"
      }
    ]
  },
  {
    value: 'Appels',
    text: 'Appels',
    icon: 'icon-ic_fluent_call_24_regular',
    children: [
      {
        value: MediaProspectEvent.phoneAnswered,
        text: ' Appel abouti',
        icon: 'icon-ic_fluent_call_24_regular',
        class: 'app-icon-green',
        label: 'Compte-rendu échange'
      },
      {
        value: MediaProspectEvent.phoneUnansered,
        text: 'Appel non abouti',
        icon: 'icon-ic_fluent_call_missed_24_regular',
        class: 'app-icon-red',
        label: 'Commentaire'
      },
      {
        value: MediaProspectEvent.phoneMessage,
        text: 'Appel répondeur',
        icon: 'icon-ic_fluent_voicemail_24_regular',
        label: 'Contenu message',
        class: 'app-icon-blue'
      }
    ]
  },
  {
    value: 'Envoi',
    text: 'Envoi',
    icon: 'icon-ic_fluent_mail_24_regular',
    children: [
      {
        value: MediaProspectEvent.email,
        text: 'Email',
        icon: 'icon-ic_fluent_mention_24_regular',
        label: 'Contenu mail',
        class: 'app-icon-blue'
      },
      {
        value: MediaProspectEvent.mail,
        text: 'Courrier',
        icon: 'icon-ic_fluent_mail_24_regular',
        label: 'Nature et contenu courrier',
        class: 'app-icon-blue'
      }
    ]
  },
  {
    value: 'Visite',
    text: 'Visite',
    icon: 'icon-ic_fluent_calendar_add_24_regular',
    children: [
      {
        value: MediaProspectEvent.visitPlaned,
        text: 'Planification de visite',
        icon: 'icon-ic_fluent_calendar_add_24_regular',
        label: 'Planifiée',
        class: 'app-icon-blue'
      },
      {
        value: MediaProspectEvent.visitDone,
        text: 'Compte rendu de visite',
        icon: 'icon-ic_fluent_channel_add_24_regular',
        label: 'Faite',
        class: 'app-icon-blue'
      }
    ]
  },
  {
    value: 'Rendez-vous',
    text: 'Rendez-vous',
    icon: 'icon-ic_fluent_calendar_add_24_regular',
    children: [
      {
        value: MediaProspectEvent.meetingPlaned,
        text: 'Planification de rendez-vous',
        icon: 'icon-ic_fluent_calendar_add_24_regular',
        label: 'Detail rendez-vous',
        class: 'app-icon-blue'
      },
      {
        value: MediaProspectEvent.meetingReport,
        text: 'Compte rendu visite',
        icon: 'icon-ic_fluent_channel_add_24_regular',
        label: 'Compte rendu visite',
        class: 'app-icon-blue'
      }
    ]
  },
  {
    value: 'Information',
    text: 'Information',
    icon: 'icon-ic_fluent_info_24_regular',
    children: [
      {
        value: MediaProspectEvent.information,
        text: 'Information',
        icon: 'icon-ic_fluent_info_24_regular',
        class: 'app-icon-gray',
        label: 'info'
      }
    ]
  }
];

export const stratalotTodoAllDone: DisplayIconHelper = {
  value: 'Terminé',
  text: 'Terminé',
  icon: 'icon-ic_fluent_checkmark_24_regular',
  class: 'app-icon-green'
};

const greyBg = { backgroundColor: 'rgb(102, 102, 102, 0.2)' };
const whiteBg = { backgroundColor: 'rgba(255, 255, 255, 0.2)' };
const blueBg = { backgroundColor: 'rgba(2, 147, 191, 0.2)' };
const greenBg = { backgroundColor: 'rgba(22, 145, 133, 0.2)' };

export const stratalotTodoStatusList: DisplayIconHelper[] = [
  {
    value: Statut.nonApplicable,
    text: 'Non applicable',
    icon: 'icon-ic_fluent_dismiss_circle_24_regular',
    class: 'app-icon-gray',
    bgColor: greyBg
  },
  {
    value: Statut.open,
    text: 'A faire',
    icon: 'icon-ic_fluent_circle_24_regular',
    class: 'app-icon-gray',
    bgColor: whiteBg
  },
  {
    value: Statut.inProgress,
    text: 'En cours',
    icon: 'icon-ic_fluent_play_circle_24_regular',
    class: 'app-icon-blue',
    bgColor: blueBg
  },
  {
    value: Statut.done,
    text: 'Terminé',
    icon: 'icon-ic_fluent_checkmark_circle_24_regular',
    class: 'app-icon-green',
    bgColor: greenBg
  }
];
export const residenceTodoStatusList: DisplayIconHelper[] = [
  {
    value: Statut.nonApplicable,
    text: 'Non applicable',
    icon: 'icon-ic_fluent_dismiss_circle_24_regular',
    class: 'app-icon-gray',
    bgColor: greyBg
  },
  {
    value: Statut.open,
    text: 'A faire',
    icon: 'icon-ic_fluent_circle_24_regular',
    class: 'app-icon-gray',
    bgColor: whiteBg
  },
  {
    value: Statut.inProgress,
    text: 'En cours',
    icon: 'icon-ic_fluent_play_circle_24_regular',
    class: 'app-icon-blue',
    bgColor: blueBg
  },
  {
    value: Statut.done,
    text: 'Terminé',
    icon: 'icon-ic_fluent_checkmark_circle_24_regular',
    class: 'app-icon-green',
    bgColor: greenBg
  }
];
export const leadStatusList: DisplayIconHelper[] = [
  {
    value: Statut.nonApplicable,
    text: 'Non applicable',
    icon: 'icon-ic_fluent_dismiss_circle_24_regular',
    class: 'app-icon-gray',
    bgColor: greyBg
  },
  {
    value: Statut.open,
    text: 'A faire',
    icon: 'icon-ic_fluent_circle_24_regular',
    class: 'app-icon-gray',
    bgColor: whiteBg
  },
  {
    value: Statut.inProgress,
    text: 'En cours',
    icon: 'icon-ic_fluent_play_circle_24_regular',
    class: 'app-icon-blue',
    bgColor: blueBg
  },
  {
    value: Statut.done,
    text: 'Terminé',
    icon: 'icon-ic_fluent_checkmark_circle_24_regular',
    class: 'app-icon-green',
    bgColor: greenBg
  }
];

export const residenceStudyWorkStatusList: DisplayIconHelper[] = [
  {
    value: Statut.open,
    text: 'A faire',
    icon: 'icon-ic_fluent_circle_24_regular',
    class: 'app-icon-gray',
    bgColor: whiteBg
  },
  {
    value: Statut.inProgress,
    text: 'En cours',
    icon: 'icon-ic_fluent_play_circle_24_regular',
    class: 'app-icon-blue',
    bgColor: blueBg
  },
  {
    value: Statut.done,
    text: 'Terminé',
    icon: 'icon-ic_fluent_checkmark_circle_24_regular',
    class: 'app-icon-green',
    bgColor: greenBg
  }
];
