import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DroitApiService } from '@_services/api/droit-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedDroitEffects } from './droit-generated.effects';

@Injectable()
export class DroitEffects extends GeneratedDroitEffects {
  constructor(actions$: Actions, droitApiService: DroitApiService, store$: Store<AppState>) {
    super(actions$, droitApiService, store$);
  }
}
