import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationStratalotTodoRule,
  OrganizationStratalotTodoRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-rule.model';
import { OrganizationStratalotTodoRuleApiService } from '@_services/api/organization-stratalot-todo-rule-api.service';
import * as organizationStratalotTodoRuleActions from '@_store/organization-stratalot-todo-rule/organization-stratalot-todo-rule.actions';
import { getActionsToNormalizeOrganizationStratalotTodoRule } from '@_config/store/normalization.generated';
import { selectOrganizationStratalotTodoRuleState } from './organization-stratalot-todo-rule-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as organizationSaleCategoryActions from '@_store/organization-sale-category/organization-sale-category.actions';
import * as saleCategoryFamilyActions from '@_store/sale-category-family/sale-category-family.actions';
import * as companyActions from '@_store/company/company.actions';
import * as companyStratalotTypeActions from '@_store/company-stratalot-type/company-stratalot-type.actions';
import * as stratalotTypeActions from '@_store/stratalot-type/stratalot-type.actions';
import * as organizationStratalotTodoActions from '@_store/organization-stratalot-todo/organization-stratalot-todo.actions';
import { OrganizationStratalotTodo } from '@_model/interfaces/organization-stratalot-todo.model';
import * as organizationStratalotTodoAppliedRuleActions from '@_store/organization-stratalot-todo-applied-rule/organization-stratalot-todo-applied-rule.actions';
import { OrganizationStratalotTodoAppliedRule } from '@_model/interfaces/organization-stratalot-todo-applied-rule.model';

export interface OrganizationStratalotTodoRuleRelationsIds {
  organizationStratalotTodos?: number | number[];
  organizationStratalotTodoAppliedRules?: number | number[];
  organization?: number;
  organizationSaleCategory?: number;
  saleCategoryFamily?: number;
  company?: number;
  companyStratalotType?: number;
  stratalotType?: number;
}

export function getDefaultAddOrganizationStratalotTodoRuleActions(
  organizationStratalotTodoRule: OrganizationStratalotTodoRuleEntityState,
  ids?: OrganizationStratalotTodoRuleRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationStratalotTodoRuleActions.normalizeManyOrganizationStratalotTodoRulesAfterUpsert({
      organizationStratalotTodoRules: [organizationStratalotTodoRule]
    })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationStratalotTodoRuleSuccess({
        idOrganization: ids.organization,
        idOrganizationStratalotTodoRules: [organizationStratalotTodoRule.idOrganizationStratalotTodoRule]
      })
    );
    actions.push(
      organizationStratalotTodoRuleActions.addOrganizationSuccess({
        idOrganizationStratalotTodoRule: organizationStratalotTodoRule.idOrganizationStratalotTodoRule,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.organizationSaleCategory) {
    actions.push(
      organizationSaleCategoryActions.addManyOrganizationStratalotTodoRuleSuccess({
        idOrganizationSaleCategory: ids.organizationSaleCategory,
        idOrganizationStratalotTodoRules: [organizationStratalotTodoRule.idOrganizationStratalotTodoRule]
      })
    );
    actions.push(
      organizationStratalotTodoRuleActions.addOrganizationSaleCategorySuccess({
        idOrganizationStratalotTodoRule: organizationStratalotTodoRule.idOrganizationStratalotTodoRule,
        idOrganizationSaleCategory: ids.organizationSaleCategory
      })
    );
  }

  if (ids?.saleCategoryFamily) {
    actions.push(
      saleCategoryFamilyActions.addManyOrganizationStratalotTodoRuleSuccess({
        idSaleCategoryFamily: ids.saleCategoryFamily,
        idOrganizationStratalotTodoRules: [organizationStratalotTodoRule.idOrganizationStratalotTodoRule]
      })
    );
    actions.push(
      organizationStratalotTodoRuleActions.addSaleCategoryFamilySuccess({
        idOrganizationStratalotTodoRule: organizationStratalotTodoRule.idOrganizationStratalotTodoRule,
        idSaleCategoryFamily: ids.saleCategoryFamily
      })
    );
  }

  if (ids?.company) {
    actions.push(
      companyActions.addManyOrganizationStratalotTodoRuleSuccess({
        idCompany: ids.company,
        idOrganizationStratalotTodoRules: [organizationStratalotTodoRule.idOrganizationStratalotTodoRule]
      })
    );
    actions.push(
      organizationStratalotTodoRuleActions.addCompanySuccess({
        idOrganizationStratalotTodoRule: organizationStratalotTodoRule.idOrganizationStratalotTodoRule,
        idCompany: ids.company
      })
    );
  }

  if (ids?.companyStratalotType) {
    actions.push(
      companyStratalotTypeActions.addManyOrganizationStratalotTodoRuleSuccess({
        idCompanyStratalotType: ids.companyStratalotType,
        idOrganizationStratalotTodoRules: [organizationStratalotTodoRule.idOrganizationStratalotTodoRule]
      })
    );
    actions.push(
      organizationStratalotTodoRuleActions.addCompanyStratalotTypeSuccess({
        idOrganizationStratalotTodoRule: organizationStratalotTodoRule.idOrganizationStratalotTodoRule,
        idCompanyStratalotType: ids.companyStratalotType
      })
    );
  }

  if (ids?.stratalotType) {
    actions.push(
      stratalotTypeActions.addManyOrganizationStratalotTodoRuleSuccess({
        idStratalotType: ids.stratalotType,
        idOrganizationStratalotTodoRules: [organizationStratalotTodoRule.idOrganizationStratalotTodoRule]
      })
    );
    actions.push(
      organizationStratalotTodoRuleActions.addStratalotTypeSuccess({
        idOrganizationStratalotTodoRule: organizationStratalotTodoRule.idOrganizationStratalotTodoRule,
        idStratalotType: ids.stratalotType
      })
    );
  }

  if (ids?.organizationStratalotTodos) {
    if (!Array.isArray(ids.organizationStratalotTodos)) {
      actions.push(
        organizationStratalotTodoActions.upsertOneOrganizationStratalotTodo({
          organizationStratalotTodo: {
            idOrganizationStratalotTodoRule: organizationStratalotTodoRule.idOrganizationStratalotTodoRule,
            idOrganizationStratalotTodo: ids.organizationStratalotTodos as number
          } as OrganizationStratalotTodo & any
        })
      );
      actions.push(
        organizationStratalotTodoRuleActions.addManyOrganizationStratalotTodoSuccess({
          idOrganizationStratalotTodoRule: organizationStratalotTodoRule.idOrganizationStratalotTodoRule,
          idOrganizationStratalotTodos: [ids.organizationStratalotTodos as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotTodoActions.upsertManyOrganizationStratalotTodos({
          organizationStratalotTodos: (ids.organizationStratalotTodos as number[]).map(
            (idOrganizationStratalotTodo: number) => ({
              idOrganizationStratalotTodoRule: organizationStratalotTodoRule.idOrganizationStratalotTodoRule,
              idOrganizationStratalotTodo
            })
          ) as OrganizationStratalotTodo[] & any[]
        })
      );
      actions.push(
        organizationStratalotTodoRuleActions.addManyOrganizationStratalotTodoSuccess({
          idOrganizationStratalotTodoRule: organizationStratalotTodoRule.idOrganizationStratalotTodoRule,
          idOrganizationStratalotTodos: ids.organizationStratalotTodos as number[]
        })
      );
    }
  }

  if (ids?.organizationStratalotTodoAppliedRules) {
    if (!Array.isArray(ids.organizationStratalotTodoAppliedRules)) {
      actions.push(
        organizationStratalotTodoAppliedRuleActions.upsertOneOrganizationStratalotTodoAppliedRule({
          organizationStratalotTodoAppliedRule: {
            idOrganizationStratalotTodoRule: organizationStratalotTodoRule.idOrganizationStratalotTodoRule,
            idOrganizationStratalotTodoAppliedRule: ids.organizationStratalotTodoAppliedRules as number
          } as OrganizationStratalotTodoAppliedRule
        })
      );
      actions.push(
        organizationStratalotTodoRuleActions.addManyOrganizationStratalotTodoAppliedRuleSuccess({
          idOrganizationStratalotTodoRule: organizationStratalotTodoRule.idOrganizationStratalotTodoRule,
          idOrganizationStratalotTodoAppliedRules: [ids.organizationStratalotTodoAppliedRules as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotTodoAppliedRuleActions.upsertManyOrganizationStratalotTodoAppliedRules({
          organizationStratalotTodoAppliedRules: (ids.organizationStratalotTodoAppliedRules as number[]).map(
            (idOrganizationStratalotTodoAppliedRule: number) => ({
              idOrganizationStratalotTodoRule: organizationStratalotTodoRule.idOrganizationStratalotTodoRule,
              idOrganizationStratalotTodoAppliedRule
            })
          ) as OrganizationStratalotTodoAppliedRule[]
        })
      );
      actions.push(
        organizationStratalotTodoRuleActions.addManyOrganizationStratalotTodoAppliedRuleSuccess({
          idOrganizationStratalotTodoRule: organizationStratalotTodoRule.idOrganizationStratalotTodoRule,
          idOrganizationStratalotTodoAppliedRules: ids.organizationStratalotTodoAppliedRules as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationStratalotTodoRuleActions(
  organizationStratalotTodoRule: OrganizationStratalotTodoRuleEntityState
): Action[] {
  const actions: Action[] = [
    organizationStratalotTodoRuleActions.deleteOneOrganizationStratalotTodoRuleSuccess({
      idOrganizationStratalotTodoRule: organizationStratalotTodoRule.idOrganizationStratalotTodoRule
    })
  ];

  if (organizationStratalotTodoRule.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationStratalotTodoRuleSuccess({
        idOrganizationStratalotTodoRules: [organizationStratalotTodoRule.idOrganizationStratalotTodoRule],
        idOrganizations: [organizationStratalotTodoRule.organization as number]
      })
    );
  }

  if (organizationStratalotTodoRule.organizationSaleCategory) {
    actions.push(
      organizationSaleCategoryActions.deleteManyOrganizationStratalotTodoRuleSuccess({
        idOrganizationStratalotTodoRules: [organizationStratalotTodoRule.idOrganizationStratalotTodoRule],
        idOrganizationSaleCategories: [organizationStratalotTodoRule.organizationSaleCategory as number]
      })
    );
  }

  if (organizationStratalotTodoRule.saleCategoryFamily) {
    actions.push(
      saleCategoryFamilyActions.deleteManyOrganizationStratalotTodoRuleSuccess({
        idOrganizationStratalotTodoRules: [organizationStratalotTodoRule.idOrganizationStratalotTodoRule],
        idFamilySaleCategories: [organizationStratalotTodoRule.saleCategoryFamily as number]
      })
    );
  }

  if (organizationStratalotTodoRule.company) {
    actions.push(
      companyActions.deleteManyOrganizationStratalotTodoRuleSuccess({
        idOrganizationStratalotTodoRules: [organizationStratalotTodoRule.idOrganizationStratalotTodoRule],
        idCompanies: [organizationStratalotTodoRule.company as number]
      })
    );
  }

  if (organizationStratalotTodoRule.companyStratalotType) {
    actions.push(
      companyStratalotTypeActions.deleteManyOrganizationStratalotTodoRuleSuccess({
        idOrganizationStratalotTodoRules: [organizationStratalotTodoRule.idOrganizationStratalotTodoRule],
        idCompanyStratalotTypes: [organizationStratalotTodoRule.companyStratalotType as number]
      })
    );
  }

  if (organizationStratalotTodoRule.stratalotType) {
    actions.push(
      stratalotTypeActions.deleteManyOrganizationStratalotTodoRuleSuccess({
        idOrganizationStratalotTodoRules: [organizationStratalotTodoRule.idOrganizationStratalotTodoRule],
        idStratalotTypes: [organizationStratalotTodoRule.stratalotType as number]
      })
    );
  }

  if (organizationStratalotTodoRule.organizationStratalotTodos) {
    actions.push(
      organizationStratalotTodoActions.deleteManyOrganizationStratalotTodoRuleSuccess({
        idOrganizationStratalotTodoRules: [organizationStratalotTodoRule.idOrganizationStratalotTodoRule],
        idOrganizationStratalotTodos: organizationStratalotTodoRule.organizationStratalotTodos as number[]
      })
    );
  }

  if (organizationStratalotTodoRule.organizationStratalotTodoAppliedRules) {
    actions.push(
      organizationStratalotTodoAppliedRuleActions.deleteManyOrganizationStratalotTodoRuleSuccess({
        idOrganizationStratalotTodoRules: [organizationStratalotTodoRule.idOrganizationStratalotTodoRule],
        idOrganizationStratalotTodoAppliedRules:
          organizationStratalotTodoRule.organizationStratalotTodoAppliedRules as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationStratalotTodoRuleEffects {
  constructor(
    protected actions$: Actions,
    protected organizationStratalotTodoRuleApiService: OrganizationStratalotTodoRuleApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationStratalotTodoRules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotTodoRuleActions.getManyOrganizationStratalotTodoRules),
      switchMap(({ params }) =>
        this.organizationStratalotTodoRuleApiService.getOrganizationStratalotTodoRules(params).pipe(
          map((organizationStratalotTodoRules: OrganizationStratalotTodoRule[]) => {
            return organizationStratalotTodoRuleActions.normalizeManyOrganizationStratalotTodoRulesAfterUpsert({
              organizationStratalotTodoRules
            });
          }),
          catchError(error => of(organizationStratalotTodoRuleActions.organizationStratalotTodoRulesFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationStratalotTodoRule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotTodoRuleActions.getOneOrganizationStratalotTodoRule),
      switchMap(idOrganizationStratalotTodoRule =>
        this.organizationStratalotTodoRuleApiService
          .getOrganizationStratalotTodoRule(idOrganizationStratalotTodoRule)
          .pipe(
            map((organizationStratalotTodoRule: OrganizationStratalotTodoRule) => {
              return organizationStratalotTodoRuleActions.normalizeManyOrganizationStratalotTodoRulesAfterUpsert({
                organizationStratalotTodoRules: [organizationStratalotTodoRule]
              });
            }),
            catchError(error =>
              of(organizationStratalotTodoRuleActions.organizationStratalotTodoRulesFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneOrganizationStratalotTodoRule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotTodoRuleActions.upsertOneOrganizationStratalotTodoRule),
      concatMap(
        ({
          organizationStratalotTodoRule,
          ids
        }: {
          organizationStratalotTodoRule: Partial<OrganizationStratalotTodoRule>;
          ids?: OrganizationStratalotTodoRuleRelationsIds;
        }) => {
          if (organizationStratalotTodoRule.idOrganizationStratalotTodoRule) {
            return this.organizationStratalotTodoRuleApiService
              .updateOrganizationStratalotTodoRule(organizationStratalotTodoRule)
              .pipe(
                map((organizationStratalotTodoRuleReturned: OrganizationStratalotTodoRule) => {
                  return organizationStratalotTodoRuleActions.normalizeManyOrganizationStratalotTodoRulesAfterUpsert({
                    organizationStratalotTodoRules: [organizationStratalotTodoRuleReturned]
                  });
                }),
                catchError(error =>
                  of(organizationStratalotTodoRuleActions.organizationStratalotTodoRulesFailure({ error }))
                )
              );
          } else {
            return this.organizationStratalotTodoRuleApiService
              .addOrganizationStratalotTodoRule(organizationStratalotTodoRule)
              .pipe(
                mergeMap((organizationStratalotTodoRuleReturned: OrganizationStratalotTodoRule) =>
                  getDefaultAddOrganizationStratalotTodoRuleActions(organizationStratalotTodoRuleReturned, ids)
                ),
                catchError(error =>
                  of(organizationStratalotTodoRuleActions.organizationStratalotTodoRulesFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationStratalotTodoRule$ = createEffect(() => {
    const selectOrganizationStratalotTodoRuleState$ = this.store$.select(selectOrganizationStratalotTodoRuleState);
    return this.actions$.pipe(
      ofType(organizationStratalotTodoRuleActions.deleteOneOrganizationStratalotTodoRule),
      withLatestFrom(selectOrganizationStratalotTodoRuleState$),
      concatMap(([{ idOrganizationStratalotTodoRule }, state]) =>
        this.organizationStratalotTodoRuleApiService
          .deleteOrganizationStratalotTodoRule(idOrganizationStratalotTodoRule)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteOrganizationStratalotTodoRuleActions(
                  state.entities[idOrganizationStratalotTodoRule] as OrganizationStratalotTodoRuleEntityState
                ),
                organizationStratalotTodoRuleActions.deleteOneOrganizationStratalotTodoRule.type
              )
            ]),
            catchError(error =>
              of(organizationStratalotTodoRuleActions.organizationStratalotTodoRulesFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyOrganizationStratalotTodoRulesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotTodoRuleActions.normalizeManyOrganizationStratalotTodoRulesAfterUpsert),
      concatMap(({ organizationStratalotTodoRules }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationStratalotTodoRule(
          organizationStratalotTodoRules,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationStratalotTodoRule] Normalization After Upsert Success')];
      })
    );
  });
}
