import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { DiffusionVisualEntityState } from '@_model/interfaces/diffusion-visual.model';

export interface DiffusionVisualState extends EntityState<DiffusionVisualEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<DiffusionVisualEntityState> = createEntityAdapter<DiffusionVisualEntityState>({
  selectId: o => o.idDiffusionVisual
});
export const initialState: DiffusionVisualState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const diffusionVisualFeatureKey = 'diffusionVisual';
