import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  GeneratedDocumentsResidence,
  GeneratedDocumentsResidenceEntityState
} from '@_model/interfaces/generated-documents-residence.model';
import { GeneratedDocumentsResidenceRelationsIds } from './generated-documents-residence-generated.effects';

export const getOneGeneratedDocumentsResidence = createAction(
  '[GeneratedDocumentsResidence] Get One GeneratedDocumentsResidence',
  props<{ idGeneratedDocumentsResidence: number; params?: any }>()
);

export const getManyGeneratedDocumentsResidences = createAction(
  '[GeneratedDocumentsResidence] Get Many GeneratedDocumentsResidences',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[GeneratedDocumentsResidence] Add Many Actives GeneratedDocumentsResidence',
  props<{ idGeneratedDocumentsResidences: number[] }>()
);

export const deleteOneActive = createAction(
  '[GeneratedDocumentsResidence] Delete One Active GeneratedDocumentsResidence',
  props<{ idGeneratedDocumentsResidence: number }>()
);

export const clearActive = createAction('[GeneratedDocumentsResidence] Clear Active GeneratedDocumentsResidence');

export const upsertOneGeneratedDocumentsResidence = createAction(
  '[GeneratedDocumentsResidence] Upsert One GeneratedDocumentsResidence',
  props<{
    generatedDocumentsResidence: Partial<GeneratedDocumentsResidence>;
    ids?: GeneratedDocumentsResidenceRelationsIds;
  }>()
);

export const upsertManyGeneratedDocumentsResidences = createAction(
  '[GeneratedDocumentsResidence] Upsert Many GeneratedDocumentsResidences',
  props<{
    generatedDocumentsResidences: Partial<GeneratedDocumentsResidence>[];
    ids?: GeneratedDocumentsResidenceRelationsIds;
  }>()
);

export const upsertManyGeneratedDocumentsResidencesSuccess = createAction(
  '[GeneratedDocumentsResidence] Create Many GeneratedDocumentsResidences Success',
  props<{ generatedDocumentsResidences: GeneratedDocumentsResidenceEntityState[] }>()
);

export const deleteOneGeneratedDocumentsResidence = createAction(
  '[GeneratedDocumentsResidence] Delete One GeneratedDocumentsResidence',
  props<{ idGeneratedDocumentsResidence: number }>()
);

export const deleteOneGeneratedDocumentsResidenceSuccess = createAction(
  '[GeneratedDocumentsResidence] Delete One GeneratedDocumentsResidence Success',
  props<{ idGeneratedDocumentsResidence: number }>()
);

export const normalizeManyGeneratedDocumentsResidencesAfterUpsert = createAction(
  '[GeneratedDocumentsResidence] Normalize Many GeneratedDocumentsResidences After Upsert',
  props<{ generatedDocumentsResidences: GeneratedDocumentsResidenceEntityState[] }>()
);

export const generatedDocumentsResidencesFailure = createAction(
  '[GeneratedDocumentsResidence] GeneratedDocumentsResidences Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[GeneratedDocumentsResidence] Clear GeneratedDocumentsResidences');

export const addResidenceSuccess = createAction(
  '[GeneratedDocumentsResidence] Add Residence',
  props<{ idGeneratedDocumentsResidence: number; idResidence: number }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[GeneratedDocumentsResidence] Delete Many Residence',
  props<{ idResidences: number[]; idGeneratedDocumentsResidences: number[] }>()
);

export const addGeneratedDocumentSuccess = createAction(
  '[GeneratedDocumentsResidence] Add GeneratedDocument',
  props<{ idGeneratedDocumentsResidence: number; idGeneratedDocument: number }>()
);

export const deleteManyGeneratedDocumentSuccess = createAction(
  '[GeneratedDocumentsResidence] Delete Many GeneratedDocument',
  props<{ idGeneratedDocuments: number[]; idGeneratedDocumentsResidences: number[] }>()
);
