import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationBuyingWishActions from './organization-buying-wish.actions';
import { adapter, initialState, OrganizationBuyingWishState } from './organization-buying-wish.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationBuyingWishEntityState } from '@_model/interfaces/organization-buying-wish.model';

export const organizationBuyingWishReducersGeneratedFunctions: ReducerTypes<
  OrganizationBuyingWishState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationBuyingWishActions.getOneOrganizationBuyingWish, (state: OrganizationBuyingWishState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationBuyingWishActions.getManyOrganizationBuyingWishes, (state: OrganizationBuyingWishState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationBuyingWishActions.upsertOneOrganizationBuyingWish, (state: OrganizationBuyingWishState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationBuyingWishActions.upsertManyOrganizationBuyingWishesSuccess,
    (state: OrganizationBuyingWishState, { organizationBuyingWishes }) =>
      adapter.upsertMany(organizationBuyingWishes, setLoadingsState(state, false))
  ),
  on(OrganizationBuyingWishActions.deleteOneOrganizationBuyingWish, (state: OrganizationBuyingWishState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationBuyingWishActions.deleteOneOrganizationBuyingWishSuccess,
    (state: OrganizationBuyingWishState, { idOrganizationBuyingWish }) =>
      adapter.removeOne(idOrganizationBuyingWish, setLoadingsState(state, false))
  ),
  on(OrganizationBuyingWishActions.clearActive, (state: OrganizationBuyingWishState) => ({ ...state, actives: [] })),
  on(
    OrganizationBuyingWishActions.addManyActives,
    (state: OrganizationBuyingWishState, { idOrganizationBuyingWishes }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationBuyingWishes)
    })
  ),
  on(
    OrganizationBuyingWishActions.deleteOneActive,
    (state: OrganizationBuyingWishState, { idOrganizationBuyingWish }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationBuyingWish)
    })
  ),

  on(OrganizationBuyingWishActions.clearStore, () => initialState),

  on(
    OrganizationBuyingWishActions.addManyProspectBuyingWishSuccess,
    (state: OrganizationBuyingWishState, { idOrganizationBuyingWish, idProspectBuyingWishs }) => {
      if (!state.entities[idOrganizationBuyingWish]) {
        return state;
      }
      const prospectBuyingWishs = (state.entities[idOrganizationBuyingWish]?.prospectBuyingWishs as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationBuyingWish]: {
            ...state.entities[idOrganizationBuyingWish],
            prospectBuyingWishs: prospectBuyingWishs.concat(
              idProspectBuyingWishs.filter(id => prospectBuyingWishs.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationBuyingWishActions.deleteManyProspectBuyingWishSuccess,
    (state: OrganizationBuyingWishState, { idProspectBuyingWishs, idOrganizationBuyingWishes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationBuyingWishes.reduce((entities, idOrganizationBuyingWish) => {
            if (!state.entities[idOrganizationBuyingWish]?.prospectBuyingWishs) {
              return entities;
            }
            entities[idOrganizationBuyingWish] = {
              ...state.entities[idOrganizationBuyingWish],
              prospectBuyingWishs: (state.entities[idOrganizationBuyingWish]?.prospectBuyingWishs as number[])?.filter(
                (idProspectBuyingWish: number) =>
                  !idProspectBuyingWishs.some((id: number) => id === idProspectBuyingWish)
              )
            } as OrganizationBuyingWishEntityState;
            return entities;
          }, {} as Dictionary<OrganizationBuyingWishEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationBuyingWishActions.addOrganizationSuccess,
    (state: OrganizationBuyingWishState, { idOrganizationBuyingWish, idOrganization }) => {
      if (!state.entities[idOrganizationBuyingWish]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationBuyingWish]: {
            ...state.entities[idOrganizationBuyingWish],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationBuyingWishActions.deleteManyOrganizationSuccess,
    (state: OrganizationBuyingWishState, { idOrganizations, idOrganizationBuyingWishes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationBuyingWishes.reduce((entities, idOrganizationBuyingWish) => {
            if (!state.entities[idOrganizationBuyingWish]?.organization) {
              return entities;
            }
            entities[idOrganizationBuyingWish] = {
              ...state.entities[idOrganizationBuyingWish],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationBuyingWish]?.organization
              )
                ? undefined
                : state.entities[idOrganizationBuyingWish]?.organization
            } as OrganizationBuyingWishEntityState;
            return entities;
          }, {} as Dictionary<OrganizationBuyingWishEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationBuyingWishState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationBuyingWishState {
  return { ...state, isLoaded, isLoading };
}
