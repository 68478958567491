import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Association } from '@_model/interfaces/association.model';
import { AssociationApiService } from '@_services/api/association-api.service';
import { Stratalot } from '@_shared/models/interfaces/stratalot.model';
import * as associationActions from '@_store/association/association.actions';
import { AppState } from '@_store/index.reducers';
import { selectStratalotAssociationState } from '@_store/stratalot-association/stratalot-association-generated.selectors';
import * as stratalotAssociationActions from '@_store/stratalot-association/stratalot-association.actions';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { GeneratedAssociationEffects, getDefaultAddAssociationActions } from './association-generated.effects';

@Injectable()
export class AssociationEffects extends GeneratedAssociationEffects {
  constructor(actions$: Actions, associationApiService: AssociationApiService, store$: Store<AppState>) {
    super(actions$, associationApiService, store$);
  }

  public getAssociatedStratalot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(associationActions.getAssociatedStratalot),
      switchMap(idStratalot =>
        this.associationApiService.getAssociatedStratalot(idStratalot).pipe(
          map((stratalots: Stratalot[]) => {
            return stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots });
          }),
          catchError(error => of(associationActions.associationsFailure({ error })))
        )
      )
    );
  });

  upsertOneAssociationAndDeleteStratalotAssociation$ = createEffect(() => {
    const selectStratalotAssociationState$ = this.store$.select(selectStratalotAssociationState);
    return this.actions$.pipe(
      ofType(associationActions.upsertOneAssociationAndDeleteStratalotAssociation),
      withLatestFrom(selectStratalotAssociationState$),
      map(([{ association, ids, idsToDelete }, state]) => [
        {
          association,
          ids,
          idsToDelete
        },
        { entities: state.entities }
      ]),
      concatMap(([{ association, ids, idsToDelete }, { entities }]) => {
        if (association.idAssociation) {
          return this.associationApiService.updateAssociation(association).pipe(
            mergeMap((associationReturned: Association) => {
              if (idsToDelete?.stratalotAssociations) {
                if (!Array.isArray(idsToDelete.stratalotAssociations)) {
                  const stratalotAssociation = entities[idsToDelete.stratalotAssociations];
                  return [
                    stratalotAssociationActions.deleteOneStratalotAssociationSuccess({
                      idStratalotAssociation: idsToDelete.stratalotAssociations
                    }),
                    stratalotActions.deleteManyStratalotAssociationSuccess({
                      idStratalotAssociations: [idsToDelete.stratalotAssociations],
                      idStratalots: [stratalotAssociation.stratalot as number]
                    }),
                    associationActions.deleteManyStratalotAssociationSuccess({
                      idStratalotAssociations: [idsToDelete.stratalotAssociations],
                      idAssociations: [stratalotAssociation.association as number]
                    }),
                    associationActions.normalizeManyAssociationsAfterUpsert({ associations: [associationReturned] })
                  ];
                } else {
                  const stratalotAssociations = idsToDelete.stratalotAssociations.map(
                    stratalotAssociation => entities[stratalotAssociation]
                  );
                  const idStratalots = [
                    ...new Set(
                      [stratalotAssociations.map(stratalotAssociation => stratalotAssociation.stratalot)].flat(1)
                    )
                  ] as number[];
                  const idAssociations = [
                    ...new Set(
                      [stratalotAssociations.map(stratalotAssociation => stratalotAssociation.association)].flat(1)
                    )
                  ] as number[];
                  return [
                    stratalotAssociationActions.deleteManyStratalotAssociationSuccess({
                      idStratalotAssociations: idsToDelete.stratalotAssociations
                    }),
                    stratalotActions.deleteManyStratalotAssociationSuccess({
                      idStratalotAssociations: idsToDelete.stratalotAssociations,
                      idStratalots
                    }),
                    associationActions.deleteManyStratalotAssociationSuccess({
                      idStratalotAssociations: idsToDelete.stratalotAssociations,
                      idAssociations
                    }),
                    associationActions.normalizeManyAssociationsAfterUpsert({ associations: [associationReturned] })
                  ];
                }
              }

              return [associationActions.normalizeManyAssociationsAfterUpsert({ associations: [associationReturned] })];
            }),
            catchError(error => of(associationActions.associationsFailure({ error })))
          );
        } else {
          return this.associationApiService.addAssociation(association).pipe(
            concatMap((associationReturned: Association) => getDefaultAddAssociationActions(associationReturned, ids)),
            catchError(error => of(associationActions.associationsFailure({ error })))
          );
        }
      })
    );
  });
}
