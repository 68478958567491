import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedResidenceStudyFoundingApiService } from './residence-study-founding-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ResidenceStudyFoundingApiService extends GeneratedResidenceStudyFoundingApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
