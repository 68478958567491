import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResidenceShare, ResidenceShareEntityState } from '@_model/interfaces/residence-share.model';
import { StratalotRcp, StratalotRcpEntityState } from '@_model/interfaces/stratalot-rcp.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, residenceShareFeatureKey, ResidenceShareState } from './residence-share.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residenceShareRelations: string[] = ['stratalotRcps', 'residences'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidenceShareState = createFeatureSelector<ResidenceShareState>(residenceShareFeatureKey);

export const selectIsLoadedResidenceShare = createSelector(
  selectResidenceShareState,
  (state: ResidenceShareState) => state.isLoaded
);

export const selectIsLoadingResidenceShare = createSelector(
  selectResidenceShareState,
  (state: ResidenceShareState) => state.isLoading
);

export const selectIsReadyResidenceShare = createSelector(
  selectResidenceShareState,
  (state: ResidenceShareState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidenceShare = createSelector(
  selectResidenceShareState,
  (state: ResidenceShareState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidenceShareModel: SelectorModel = {
  name: 'residenceShares',
  getSelector: selectAllResidenceSharesDictionary,
  isReady: selectIsReadyResidenceShare
};

export const selectResidenceSharesEntities = createSelector(selectResidenceShareState, selectEntities);

export const selectResidenceSharesArray = createSelector(selectResidenceShareState, selectAll);

export const selectIdResidenceSharesActive = createSelector(
  selectResidenceShareState,
  (state: ResidenceShareState) => state.actives
);

const residenceSharesInObject = (residenceShares: Dictionary<ResidenceShareEntityState>) => ({ residenceShares });

const selectResidenceSharesEntitiesDictionary = createSelector(selectResidenceSharesEntities, residenceSharesInObject);

const selectAllResidenceSharesObject = createSelector(selectResidenceSharesEntities, residenceShares => {
  return hydrateAll({ residenceShares });
});

const selectOneResidenceShareDictionary = (idResidenceShare: number) =>
  createSelector(selectResidenceSharesEntities, residenceShares => {
    return { residenceShares: { [idResidenceShare]: residenceShares[idResidenceShare] } };
  });

const selectOneResidenceShareDictionaryWithoutChild = (idResidenceShare: number) =>
  createSelector(selectResidenceSharesEntities, residenceShares => {
    return { residenceShare: residenceShares[idResidenceShare] };
  });

const selectActiveResidenceSharesEntities = createSelector(
  selectIdResidenceSharesActive,
  selectResidenceSharesEntities,
  (actives: number[], residenceShares: Dictionary<ResidenceShareEntityState>) =>
    getResidenceSharesFromActives(actives, residenceShares)
);

function getResidenceSharesFromActives(
  actives: number[],
  residenceShares: Dictionary<ResidenceShareEntityState>
): Dictionary<ResidenceShareEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residenceShares[idActive]) {
      acc[idActive] = residenceShares[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidenceShareEntityState>);
}

const selectAllResidenceSharesSelectors: Dictionary<Selector> = {};
export function selectAllResidenceShares(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidenceShare>(
      schema,
      selectAllResidenceSharesSelectors,
      selectResidenceSharesEntitiesDictionary,
      getRelationSelectors,
      residenceShareRelations,
      hydrateAll,
      'residenceShare'
    );
  } else {
    return selectAllResidenceSharesObject;
  }
}

export function selectAllResidenceSharesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residenceShares'
): Selector {
  return createSelector(selectAllResidenceShares(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidenceShareEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residenceShares.length; i++) {
      res[customKey][result.residenceShares[i].idResidenceShare] = result.residenceShares[i];
    }
    return res;
  });
}

export function selectOneResidenceShare(schema: SelectSchema = {}, idResidenceShare: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidenceShareDictionary(idResidenceShare)];
    selectors.push(...getRelationSelectors(schema, residenceShareRelations, 'residenceShare'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidenceShareDictionaryWithoutChild(idResidenceShare);
  }
}

export function selectActiveResidenceShares(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidenceSharesEntities, residenceShares => ({ residenceShares }))
  ];
  selectors.push(...getRelationSelectors(schema, residenceShareRelations, 'residenceShare'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residenceShares: Dictionary<ResidenceShareEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
  stratalotRcps?: Dictionary<StratalotRcpEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { residenceShares: (ResidenceShare | null)[] } {
  const { residenceShares, residences, stratalotRcps } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    residenceShares: Object.keys(residenceShares).map(idResidenceShare =>
      hydrate(residenceShares[idResidenceShare] as ResidenceShareEntityState, residences, stratalotRcps)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { residenceShare: ResidenceShareEntityState | null } {
  const { residenceShares, residences, stratalotRcps } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const residenceShare = Object.values(residenceShares)[0];
  return { residenceShare: hydrate(residenceShare as ResidenceShareEntityState, residences, stratalotRcps) };
}

function hydrate(
  residenceShare: ResidenceShareEntityState,
  residenceEntities?: Dictionary<ResidenceEntityState>,
  stratalotRcpEntities?: Dictionary<StratalotRcpEntityState>
): ResidenceShare | null {
  if (!residenceShare) {
    return null;
  }

  const residenceShareHydrated: ResidenceShareEntityState = { ...residenceShare };
  if (residenceEntities) {
    residenceShareHydrated.residence = residenceEntities[residenceShare.residence as number] as Residence;
  } else {
    delete residenceShareHydrated.residence;
  }

  if (stratalotRcpEntities) {
    residenceShareHydrated.stratalotRcps = ((residenceShareHydrated.stratalotRcps as number[]) || []).map(
      id => stratalotRcpEntities[id]
    ) as StratalotRcp[];
  } else {
    delete residenceShareHydrated.stratalotRcps;
  }

  return residenceShareHydrated as ResidenceShare;
}
