import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Residence } from '@_model/interfaces/residence.model';
import * as AppState from '@_store/index.reducers';
import { ResidenceRelationsIds } from '@_store/residence/residence-generated.effects';
import * as ResidenceAction from '@_store/residence/residence.actions';
import {
  selectActiveResidences,
  selectAllResidences,
  selectIdResidencesActive,
  selectIsLoadedResidence,
  selectIsLoadingResidence,
  selectIsReadyAndLoadedResidence,
  selectOneResidence
} from '@_store/residence/residence.selectors';
import { catchApiActions } from '@_utils/http.util';
import { getIsReadySelectors, Selector, SelectSchema } from '@_utils/selector.util';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedResidenceService {
  constructor(protected store$: Store<AppState.AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadedResidence));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadingResidence));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [selectIsReadyAndLoadedResidence as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllResidences(schema: SelectSchema = {}): Observable<Residence[]> {
    return this.store$.pipe(select(selectAllResidences(schema))).pipe(
      switchMap(({ residences }: { residences: Residence[] }) => {
        return this.getReady(schema).pipe(mapTo(residences));
      })
    );
  }

  public selectOneResidence(idResidence: number, schema: SelectSchema = {}): Observable<Residence> {
    return this.store$.pipe(select(selectOneResidence(schema, idResidence))).pipe(
      switchMap(({ residence }: { residence: Residence }) => {
        return this.getReady(schema).pipe(mapTo(residence));
      })
    );
  }

  public selectAllActiveResidences(schema: SelectSchema = {}): Observable<Residence[]> {
    return this.store$.pipe(select(selectActiveResidences(schema))).pipe(
      switchMap(({ residences }: { residences: Residence[] }) => {
        return this.getReady(schema).pipe(mapTo(residences));
      })
    );
  }

  public selectIdResidencesActive(): Observable<number[]> {
    return this.store$.pipe(select(selectIdResidencesActive)).pipe(
      switchMap((idResidences: number[]) => {
        return this.getReady().pipe(mapTo(idResidences));
      })
    );
  }

  public getOneResidence(idResidence: number, params: any = {}, getResult?: boolean): void | Observable<Residence> {
    this.store$.dispatch(ResidenceAction.getOneResidence({ idResidence, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ResidenceAction.normalizeManyResidencesAfterUpsert,
        ResidenceAction.residencesFailure,
        true
      );
    }
  }

  public getManyResidences(params: any = {}, getResult?: boolean): void | Observable<Residence[]> {
    this.store$.dispatch(ResidenceAction.getManyResidences({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ResidenceAction.normalizeManyResidencesAfterUpsert,
        ResidenceAction.residencesFailure
      );
    }
  }

  public upsertOneResidence(
    residence: Partial<Residence>,
    ids: ResidenceRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Residence> {
    this.store$.dispatch(ResidenceAction.upsertOneResidence({ residence, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ResidenceAction.normalizeManyResidencesAfterUpsert,
        ResidenceAction.residencesFailure,
        true
      );
    }
  }

  public deleteOneResidence(idResidence: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(ResidenceAction.deleteOneResidence({ idResidence }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ResidenceAction.deleteOneResidenceSuccess,
        ResidenceAction.residencesFailure
      );
    }
  }

  public setActiveResidences(idResidences: number[]): void {
    this.store$.dispatch(ResidenceAction.clearActive());
    this.store$.dispatch(ResidenceAction.addManyActives({ idResidences }));
  }
}
