import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyStratalotVacantActions from './company-stratalot-vacant.actions';
import { adapter, initialState, CompanyStratalotVacantState } from './company-stratalot-vacant.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyStratalotVacantEntityState } from '@_model/interfaces/company-stratalot-vacant.model';

export const companyStratalotVacantReducersGeneratedFunctions: ReducerTypes<
  CompanyStratalotVacantState,
  readonly ActionCreator[]
>[] = [
  on(CompanyStratalotVacantActions.getOneCompanyStratalotVacant, (state: CompanyStratalotVacantState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyStratalotVacantActions.getManyCompanyStratalotVacants, (state: CompanyStratalotVacantState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyStratalotVacantActions.upsertOneCompanyStratalotVacant, (state: CompanyStratalotVacantState) =>
    setLoadingsState(state, true)
  ),

  on(
    CompanyStratalotVacantActions.upsertManyCompanyStratalotVacantsSuccess,
    (state: CompanyStratalotVacantState, { companyStratalotVacants }) =>
      adapter.upsertMany(companyStratalotVacants, setLoadingsState(state, false))
  ),
  on(CompanyStratalotVacantActions.deleteOneCompanyStratalotVacant, (state: CompanyStratalotVacantState) =>
    setLoadingsState(state, true)
  ),
  on(
    CompanyStratalotVacantActions.deleteOneCompanyStratalotVacantSuccess,
    (state: CompanyStratalotVacantState, { idCompanyStratalotVacant }) =>
      adapter.removeOne(idCompanyStratalotVacant, setLoadingsState(state, false))
  ),
  on(CompanyStratalotVacantActions.clearActive, (state: CompanyStratalotVacantState) => ({ ...state, actives: [] })),
  on(
    CompanyStratalotVacantActions.addManyActives,
    (state: CompanyStratalotVacantState, { idCompanyStratalotVacants }) => ({
      ...state,
      actives: state.actives.concat(idCompanyStratalotVacants)
    })
  ),
  on(
    CompanyStratalotVacantActions.deleteOneActive,
    (state: CompanyStratalotVacantState, { idCompanyStratalotVacant }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCompanyStratalotVacant)
    })
  ),

  on(CompanyStratalotVacantActions.clearStore, () => initialState),

  on(
    CompanyStratalotVacantActions.addManyStratalotSuccess,
    (state: CompanyStratalotVacantState, { idCompanyStratalotVacant, idStratalots }) => {
      if (!state.entities[idCompanyStratalotVacant]) {
        return state;
      }
      const stratalots = (state.entities[idCompanyStratalotVacant]?.stratalots as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStratalotVacant]: {
            ...state.entities[idCompanyStratalotVacant],
            stratalots: stratalots.concat(idStratalots.filter(id => stratalots.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CompanyStratalotVacantActions.deleteManyStratalotSuccess,
    (state: CompanyStratalotVacantState, { idStratalots, idCompanyStratalotVacants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStratalotVacants.reduce((entities, idCompanyStratalotVacant) => {
            if (!state.entities[idCompanyStratalotVacant]?.stratalots) {
              return entities;
            }
            entities[idCompanyStratalotVacant] = {
              ...state.entities[idCompanyStratalotVacant],
              stratalots: (state.entities[idCompanyStratalotVacant]?.stratalots as number[])?.filter(
                (idStratalot: number) => !idStratalots.some((id: number) => id === idStratalot)
              )
            } as CompanyStratalotVacantEntityState;
            return entities;
          }, {} as Dictionary<CompanyStratalotVacantEntityState>)
        }
      };
    }
  ),

  on(
    CompanyStratalotVacantActions.addCompanySuccess,
    (state: CompanyStratalotVacantState, { idCompanyStratalotVacant, idCompany }) => {
      if (!state.entities[idCompanyStratalotVacant]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStratalotVacant]: {
            ...state.entities[idCompanyStratalotVacant],
            company: idCompany
          }
        }
      };
    }
  ),

  on(
    CompanyStratalotVacantActions.deleteManyCompanySuccess,
    (state: CompanyStratalotVacantState, { idCompanies, idCompanyStratalotVacants }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStratalotVacants.reduce((entities, idCompanyStratalotVacant) => {
            if (!state.entities[idCompanyStratalotVacant]?.company) {
              return entities;
            }
            entities[idCompanyStratalotVacant] = {
              ...state.entities[idCompanyStratalotVacant],
              company: idCompanies.some(
                (idCompany: number) => idCompany === state.entities[idCompanyStratalotVacant]?.company
              )
                ? undefined
                : state.entities[idCompanyStratalotVacant]?.company
            } as CompanyStratalotVacantEntityState;
            return entities;
          }, {} as Dictionary<CompanyStratalotVacantEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyStratalotVacantState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyStratalotVacantState {
  return { ...state, isLoaded, isLoading };
}
