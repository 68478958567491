import { Injectable } from '@angular/core';
import { RepositoryService } from '@_services/api/repository.service';
import { Observable } from 'rxjs';
import { GeneratedCompanyApiService } from './company-api-generated.service';
import { environment } from '@_environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FileModel } from '@_shared/models/interfaces/file.model';
import { first, map } from 'rxjs/operators';
import { ErrorResponse } from '@_shared/models/interfaces/error-response';

@Injectable({
  providedIn: 'root'
})
export class CompanyApiService extends GeneratedCompanyApiService {
  constructor(repo: RepositoryService, private readonly http: HttpClient) {
    super(repo);
  }

  public uploadLogo(idCompany: number, files: File[], logoNum: number): Observable<any> {
    const formData = new FormData();
    const route = new URL(environment.apiUrl + '/company/uploadLogo').href;
    files.forEach(media => {
      formData.append('files', media);
    });
    formData.append('idCompany', idCompany.toString());
    formData.append('logoNum', logoNum.toString());
    return this.http
      .post(route, formData, { params: { idCompany: idCompany.toString(), logoNum: logoNum.toString() } })
      .pipe(
        first(),
        map(
          (res: { data: Array<FileModel> }) => res.data,
          (err: ErrorResponse) => err
        )
      );
  }

  public downloadLogo(params: { idCompany: number; idLogo: number }): Observable<Blob> {
    return this.http.get(environment.apiUrl + '/company/downloadLogo', {
      params: this.setParams(params),
      responseType: 'blob'
      // headers: new HttpHeaders().append('Content-Type')
    });
  }

  private setParams(object: any): HttpParams {
    let params = new HttpParams();

    const formatedObject = Object.keys(object).every(key => key === 'params') ? object.params : object;

    for (const key in formatedObject) {
      if (formatedObject[key] !== null && formatedObject[key] !== undefined) {
        if (Array.isArray(formatedObject[key])) {
          formatedObject[key].forEach(element => {
            params = params.append(`${key}[]`, element);
          });
        } else {
          params = params.append(key, formatedObject[key]);
        }
      }
    }
    return params;
  }
}
