import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceShareRcp } from '@_model/interfaces/residence-share-rcp.model';
import { Observable } from 'rxjs';

export class GeneratedResidenceShareRcpApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidenceShareRcps(params?: any): Observable<ResidenceShareRcp[]> {
    return this.repo.getData<ResidenceShareRcp[]>('residence-share-rcp', params);
  }

  public getResidenceShareRcp(params: { idResidenceShareRcp: number; params?: any }): Observable<ResidenceShareRcp> {
    return this.repo.getData<ResidenceShareRcp>('residence-share-rcp/' + params.idResidenceShareRcp, params.params);
  }

  public addResidenceShareRcp(residenceShareRcp: Partial<ResidenceShareRcp>): Observable<ResidenceShareRcp> {
    return this.repo.create<ResidenceShareRcp>('residence-share-rcp', residenceShareRcp);
  }

  public updateResidenceShareRcp(residenceShareRcp: Partial<ResidenceShareRcp>): Observable<ResidenceShareRcp> {
    return this.repo.update('residence-share-rcp', residenceShareRcp);
  }

  public deleteResidenceShareRcp(idResidenceShareRcp: number): Observable<number> {
    return this.repo.delete('residence-share-rcp/' + +idResidenceShareRcp);
  }
}
