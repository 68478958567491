import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StratalotFee, StratalotFeeEntityState } from '@_model/interfaces/stratalot-fee.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, stratalotFeeFeatureKey, StratalotFeeState } from './stratalot-fee.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const stratalotFeeRelations: string[] = ['stratalots'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectStratalotFeeState = createFeatureSelector<StratalotFeeState>(stratalotFeeFeatureKey);

export const selectIsLoadedStratalotFee = createSelector(
  selectStratalotFeeState,
  (state: StratalotFeeState) => state.isLoaded
);

export const selectIsLoadingStratalotFee = createSelector(
  selectStratalotFeeState,
  (state: StratalotFeeState) => state.isLoading
);

export const selectIsReadyStratalotFee = createSelector(
  selectStratalotFeeState,
  (state: StratalotFeeState) => !state.isLoading
);

export const selectIsReadyAndLoadedStratalotFee = createSelector(
  selectStratalotFeeState,
  (state: StratalotFeeState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const StratalotFeeModel: SelectorModel = {
  name: 'stratalotFees',
  getSelector: selectAllStratalotFeesDictionary,
  isReady: selectIsReadyStratalotFee
};

export const selectStratalotFeesEntities = createSelector(selectStratalotFeeState, selectEntities);

export const selectStratalotFeesArray = createSelector(selectStratalotFeeState, selectAll);

export const selectIdStratalotFeesActive = createSelector(
  selectStratalotFeeState,
  (state: StratalotFeeState) => state.actives
);

const stratalotFeesInObject = (stratalotFees: Dictionary<StratalotFeeEntityState>) => ({ stratalotFees });

const selectStratalotFeesEntitiesDictionary = createSelector(selectStratalotFeesEntities, stratalotFeesInObject);

const selectAllStratalotFeesObject = createSelector(selectStratalotFeesEntities, stratalotFees => {
  return hydrateAll({ stratalotFees });
});

const selectOneStratalotFeeDictionary = (idStratalotFee: number) =>
  createSelector(selectStratalotFeesEntities, stratalotFees => {
    return { stratalotFees: { [idStratalotFee]: stratalotFees[idStratalotFee] } };
  });

const selectOneStratalotFeeDictionaryWithoutChild = (idStratalotFee: number) =>
  createSelector(selectStratalotFeesEntities, stratalotFees => {
    return { stratalotFee: stratalotFees[idStratalotFee] };
  });

const selectActiveStratalotFeesEntities = createSelector(
  selectIdStratalotFeesActive,
  selectStratalotFeesEntities,
  (actives: number[], stratalotFees: Dictionary<StratalotFeeEntityState>) =>
    getStratalotFeesFromActives(actives, stratalotFees)
);

function getStratalotFeesFromActives(
  actives: number[],
  stratalotFees: Dictionary<StratalotFeeEntityState>
): Dictionary<StratalotFeeEntityState> {
  return actives.reduce((acc, idActive) => {
    if (stratalotFees[idActive]) {
      acc[idActive] = stratalotFees[idActive];
    }
    return acc;
  }, {} as Dictionary<StratalotFeeEntityState>);
}

const selectAllStratalotFeesSelectors: Dictionary<Selector> = {};
export function selectAllStratalotFees(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<StratalotFee>(
      schema,
      selectAllStratalotFeesSelectors,
      selectStratalotFeesEntitiesDictionary,
      getRelationSelectors,
      stratalotFeeRelations,
      hydrateAll,
      'stratalotFee'
    );
  } else {
    return selectAllStratalotFeesObject;
  }
}

export function selectAllStratalotFeesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'stratalotFees'
): Selector {
  return createSelector(selectAllStratalotFees(schema), result => {
    const res = { [customKey]: {} as Dictionary<StratalotFeeEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.stratalotFees.length; i++) {
      res[customKey][result.stratalotFees[i].idStratalotFee] = result.stratalotFees[i];
    }
    return res;
  });
}

export function selectOneStratalotFee(schema: SelectSchema = {}, idStratalotFee: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneStratalotFeeDictionary(idStratalotFee)];
    selectors.push(...getRelationSelectors(schema, stratalotFeeRelations, 'stratalotFee'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneStratalotFeeDictionaryWithoutChild(idStratalotFee);
  }
}

export function selectActiveStratalotFees(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveStratalotFeesEntities, stratalotFees => ({ stratalotFees }))
  ];
  selectors.push(...getRelationSelectors(schema, stratalotFeeRelations, 'stratalotFee'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  stratalotFees: Dictionary<StratalotFeeEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { stratalotFees: (StratalotFee | null)[] } {
  const { stratalotFees, stratalots } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    stratalotFees: Object.keys(stratalotFees).map(idStratalotFee =>
      hydrate(stratalotFees[idStratalotFee] as StratalotFeeEntityState, stratalots)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { stratalotFee: StratalotFeeEntityState | null } {
  const { stratalotFees, stratalots } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const stratalotFee = Object.values(stratalotFees)[0];
  return { stratalotFee: hydrate(stratalotFee as StratalotFeeEntityState, stratalots) };
}

function hydrate(
  stratalotFee: StratalotFeeEntityState,
  stratalotEntities?: Dictionary<StratalotEntityState>
): StratalotFee | null {
  if (!stratalotFee) {
    return null;
  }

  const stratalotFeeHydrated: StratalotFeeEntityState = { ...stratalotFee };
  if (stratalotEntities) {
    stratalotFeeHydrated.stratalot = stratalotEntities[stratalotFee.stratalot as number] as Stratalot;
  } else {
    delete stratalotFeeHydrated.stratalot;
  }

  return stratalotFeeHydrated as StratalotFee;
}
