import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StratalotFeeEntityState } from '@_model/interfaces/stratalot-fee.model';

export interface StratalotFeeState extends EntityState<StratalotFeeEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<StratalotFeeEntityState> = createEntityAdapter<StratalotFeeEntityState>({
  selectId: o => o.idStratalotFee
});
export const initialState: StratalotFeeState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const stratalotFeeFeatureKey = 'stratalotFee';
