import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Association, AssociationEntityState } from '@_model/interfaces/association.model';
import { StratalotAssociation, StratalotAssociationEntityState } from '@_model/interfaces/stratalot-association.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, associationFeatureKey, AssociationState } from './association.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const associationRelations: string[] = ['stratalotAssociations', 'stratalots'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectAssociationState = createFeatureSelector<AssociationState>(associationFeatureKey);

export const selectIsLoadedAssociation = createSelector(
  selectAssociationState,
  (state: AssociationState) => state.isLoaded
);

export const selectIsLoadingAssociation = createSelector(
  selectAssociationState,
  (state: AssociationState) => state.isLoading
);

export const selectIsReadyAssociation = createSelector(
  selectAssociationState,
  (state: AssociationState) => !state.isLoading
);

export const selectIsReadyAndLoadedAssociation = createSelector(
  selectAssociationState,
  (state: AssociationState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const AssociationModel: SelectorModel = {
  name: 'associations',
  getSelector: selectAllAssociationsDictionary,
  isReady: selectIsReadyAssociation
};

export const selectAssociationsEntities = createSelector(selectAssociationState, selectEntities);

export const selectAssociationsArray = createSelector(selectAssociationState, selectAll);

export const selectIdAssociationsActive = createSelector(
  selectAssociationState,
  (state: AssociationState) => state.actives
);

const associationsInObject = (associations: Dictionary<AssociationEntityState>) => ({ associations });

const selectAssociationsEntitiesDictionary = createSelector(selectAssociationsEntities, associationsInObject);

const selectAllAssociationsObject = createSelector(selectAssociationsEntities, associations => {
  return hydrateAll({ associations });
});

const selectOneAssociationDictionary = (idAssociation: number) =>
  createSelector(selectAssociationsEntities, associations => {
    return { associations: { [idAssociation]: associations[idAssociation] } };
  });

const selectOneAssociationDictionaryWithoutChild = (idAssociation: number) =>
  createSelector(selectAssociationsEntities, associations => {
    return { association: associations[idAssociation] };
  });

const selectActiveAssociationsEntities = createSelector(
  selectIdAssociationsActive,
  selectAssociationsEntities,
  (actives: number[], associations: Dictionary<AssociationEntityState>) =>
    getAssociationsFromActives(actives, associations)
);

function getAssociationsFromActives(
  actives: number[],
  associations: Dictionary<AssociationEntityState>
): Dictionary<AssociationEntityState> {
  return actives.reduce((acc, idActive) => {
    if (associations[idActive]) {
      acc[idActive] = associations[idActive];
    }
    return acc;
  }, {} as Dictionary<AssociationEntityState>);
}

const selectAllAssociationsSelectors: Dictionary<Selector> = {};
export function selectAllAssociations(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Association>(
      schema,
      selectAllAssociationsSelectors,
      selectAssociationsEntitiesDictionary,
      getRelationSelectors,
      associationRelations,
      hydrateAll,
      'association'
    );
  } else {
    return selectAllAssociationsObject;
  }
}

export function selectAllAssociationsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'associations'
): Selector {
  return createSelector(selectAllAssociations(schema), result => {
    const res = { [customKey]: {} as Dictionary<AssociationEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.associations.length; i++) {
      res[customKey][result.associations[i].idAssociation] = result.associations[i];
    }
    return res;
  });
}

export function selectOneAssociation(schema: SelectSchema = {}, idAssociation: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneAssociationDictionary(idAssociation)];
    selectors.push(...getRelationSelectors(schema, associationRelations, 'association'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneAssociationDictionaryWithoutChild(idAssociation);
  }
}

export function selectActiveAssociations(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [createSelector(selectActiveAssociationsEntities, associations => ({ associations }))];
  selectors.push(...getRelationSelectors(schema, associationRelations, 'association'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  associations: Dictionary<AssociationEntityState>;
  stratalotAssociations?: Dictionary<StratalotAssociationEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { associations: (Association | null)[] } {
  const { associations, stratalotAssociations, stratalots } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    associations: Object.keys(associations).map(idAssociation =>
      hydrate(associations[idAssociation] as AssociationEntityState, stratalotAssociations, stratalots)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { association: AssociationEntityState | null } {
  const { associations, stratalotAssociations, stratalots } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const association = Object.values(associations)[0];
  return { association: hydrate(association as AssociationEntityState, stratalotAssociations, stratalots) };
}

function hydrate(
  association: AssociationEntityState,
  stratalotAssociationEntities?: Dictionary<StratalotAssociationEntityState>,
  stratalotEntities?: Dictionary<StratalotEntityState>
): Association | null {
  if (!association) {
    return null;
  }

  const associationHydrated: AssociationEntityState = { ...association };

  if (stratalotAssociationEntities) {
    associationHydrated.stratalotAssociations = ((associationHydrated.stratalotAssociations as number[]) || []).map(
      id => stratalotAssociationEntities[id]
    ) as StratalotAssociation[];
  } else {
    delete associationHydrated.stratalotAssociations;
  }

  if (stratalotEntities) {
    associationHydrated.stratalots = ((associationHydrated.stratalots as number[]) || []).map(
      id => stratalotEntities[id]
    ) as Stratalot[];
  } else {
    delete associationHydrated.stratalots;
  }

  return associationHydrated as Association;
}
