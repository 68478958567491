export enum MediaProspectEvent {
  information = 'information',
  suiviAvancement = 'suivi avancement',
  phoneAnswered = 'appel abouti',
  phoneUnansered = 'appel non abouti',
  phoneMessage = 'appel répondeur',
  email = 'email',
  mail = 'courrier',
  meetingPlaned = 'rendez-vous planifié',
  meetingReport = 'compte-rendu visite',
  buyingwishEvolution = 'buyingwishEvolution',
  visitPlaned = 'visite planifiée',
  visitDone = 'visite faite'
}
