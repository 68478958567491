import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { StratalotFee, StratalotFeeEntityState } from '@_model/interfaces/stratalot-fee.model';
import { StratalotFeeRelationsIds } from './stratalot-fee-generated.effects';

export const getOneStratalotFee = createAction(
  '[StratalotFee] Get One StratalotFee',
  props<{ idStratalotFee: number; params?: any }>()
);

export const getManyStratalotFees = createAction('[StratalotFee] Get Many StratalotFees', props<{ params: any }>());

export const addManyActives = createAction(
  '[StratalotFee] Add Many Actives StratalotFee',
  props<{ idStratalotFees: number[] }>()
);

export const deleteOneActive = createAction(
  '[StratalotFee] Delete One Active StratalotFee',
  props<{ idStratalotFee: number }>()
);

export const clearActive = createAction('[StratalotFee] Clear Active StratalotFee');

export const upsertOneStratalotFee = createAction(
  '[StratalotFee] Upsert One StratalotFee',
  props<{
    stratalotFee: Partial<StratalotFee>;
    ids?: StratalotFeeRelationsIds;
  }>()
);

export const upsertManyStratalotFees = createAction(
  '[StratalotFee] Upsert Many StratalotFees',
  props<{
    stratalotFees: Partial<StratalotFee>[];
    ids?: StratalotFeeRelationsIds;
  }>()
);

export const upsertManyStratalotFeesSuccess = createAction(
  '[StratalotFee] Create Many StratalotFees Success',
  props<{ stratalotFees: StratalotFeeEntityState[] }>()
);

export const deleteOneStratalotFee = createAction(
  '[StratalotFee] Delete One StratalotFee',
  props<{ idStratalotFee: number }>()
);

export const deleteOneStratalotFeeSuccess = createAction(
  '[StratalotFee] Delete One StratalotFee Success',
  props<{ idStratalotFee: number }>()
);

export const normalizeManyStratalotFeesAfterUpsert = createAction(
  '[StratalotFee] Normalize Many StratalotFees After Upsert',
  props<{ stratalotFees: StratalotFeeEntityState[] }>()
);

export const stratalotFeesFailure = createAction(
  '[StratalotFee] StratalotFees Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[StratalotFee] Clear StratalotFees');

export const addStratalotSuccess = createAction(
  '[StratalotFee] Add Stratalot',
  props<{ idStratalotFee: number; idStratalot: number }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[StratalotFee] Delete Many Stratalot',
  props<{ idStratalots: number[]; idStratalotFees: number[] }>()
);
