import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '@_services/auth.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageService } from '@_services/storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router, private storageService: StorageService) {}

  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (!this.authService.isLogged) {
      return next.handle(request);
    }
    const bearerToken = this.storageService.getLocal('login')?.token;
    const newRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${bearerToken}`
      }
    });
    return next.handle(newRequest).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 && err.error.subCode === 'E_INVALID_TOKEN') {
            this.authService.logout();
            const informData = {
              title: 'Session expiré',
              content: 'Votre session a expiré. Veuillez vous reconnecter.'
            };
            this.router.navigate(['/auth/information'], { state: informData });
          }
        }
        return throwError(err);
      })
    );
  }
}
