import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { CompanyMyNotary, CompanyMyNotaryEntityState } from '@_model/interfaces/company-my-notary.model';
import { CompanyMyNotaryApiService } from '@_services/api/company-my-notary-api.service';
import * as companyMyNotaryActions from '@_store/company-my-notary/company-my-notary.actions';
import { getActionsToNormalizeCompanyMyNotary } from '@_config/store/normalization.generated';
import { selectCompanyMyNotaryState } from './company-my-notary-generated.selectors';
import * as companyActions from '@_store/company/company.actions';

export interface CompanyMyNotaryRelationsIds {
  company?: number;
}

export function getDefaultAddCompanyMyNotaryActions(
  companyMyNotary: CompanyMyNotaryEntityState,
  ids?: CompanyMyNotaryRelationsIds
): Action[] {
  const actions: Action[] = [
    companyMyNotaryActions.normalizeManyCompanyMyNotariesAfterUpsert({ companyMyNotaries: [companyMyNotary] })
  ];

  if (ids?.company) {
    actions.push(
      companyActions.addManyCompanyMyNotarySuccess({
        idCompany: ids.company,
        idCompanyMyNotaries: [companyMyNotary.idCompanyMyNotary]
      })
    );
    actions.push(
      companyMyNotaryActions.addCompanySuccess({
        idCompanyMyNotary: companyMyNotary.idCompanyMyNotary,
        idCompany: ids.company
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCompanyMyNotaryActions(companyMyNotary: CompanyMyNotaryEntityState): Action[] {
  const actions: Action[] = [
    companyMyNotaryActions.deleteOneCompanyMyNotarySuccess({ idCompanyMyNotary: companyMyNotary.idCompanyMyNotary })
  ];

  if (companyMyNotary.company) {
    actions.push(
      companyActions.deleteManyCompanyMyNotarySuccess({
        idCompanyMyNotaries: [companyMyNotary.idCompanyMyNotary],
        idCompanies: [companyMyNotary.company as number]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyMyNotaryEffects {
  constructor(
    protected actions$: Actions,
    protected companyMyNotaryApiService: CompanyMyNotaryApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyMyNotaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyMyNotaryActions.getManyCompanyMyNotaries),
      switchMap(({ params }) =>
        this.companyMyNotaryApiService.getCompanyMyNotaries(params).pipe(
          map((companyMyNotaries: CompanyMyNotary[]) => {
            return companyMyNotaryActions.normalizeManyCompanyMyNotariesAfterUpsert({ companyMyNotaries });
          }),
          catchError(error => of(companyMyNotaryActions.companyMyNotariesFailure({ error })))
        )
      )
    );
  });

  getOneCompanyMyNotary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyMyNotaryActions.getOneCompanyMyNotary),
      switchMap(idCompanyMyNotary =>
        this.companyMyNotaryApiService.getCompanyMyNotary(idCompanyMyNotary).pipe(
          map((companyMyNotary: CompanyMyNotary) => {
            return companyMyNotaryActions.normalizeManyCompanyMyNotariesAfterUpsert({
              companyMyNotaries: [companyMyNotary]
            });
          }),
          catchError(error => of(companyMyNotaryActions.companyMyNotariesFailure({ error })))
        )
      )
    );
  });

  upsertOneCompanyMyNotary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyMyNotaryActions.upsertOneCompanyMyNotary),
      concatMap(
        ({
          companyMyNotary,
          ids
        }: {
          companyMyNotary: Partial<CompanyMyNotary>;
          ids?: CompanyMyNotaryRelationsIds;
        }) => {
          if (companyMyNotary.idCompanyMyNotary) {
            return this.companyMyNotaryApiService.updateCompanyMyNotary(companyMyNotary).pipe(
              map((companyMyNotaryReturned: CompanyMyNotary) => {
                return companyMyNotaryActions.normalizeManyCompanyMyNotariesAfterUpsert({
                  companyMyNotaries: [companyMyNotaryReturned]
                });
              }),
              catchError(error => of(companyMyNotaryActions.companyMyNotariesFailure({ error })))
            );
          } else {
            return this.companyMyNotaryApiService.addCompanyMyNotary(companyMyNotary).pipe(
              mergeMap((companyMyNotaryReturned: CompanyMyNotary) =>
                getDefaultAddCompanyMyNotaryActions(companyMyNotaryReturned, ids)
              ),
              catchError(error => of(companyMyNotaryActions.companyMyNotariesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCompanyMyNotary$ = createEffect(() => {
    const selectCompanyMyNotaryState$ = this.store$.select(selectCompanyMyNotaryState);
    return this.actions$.pipe(
      ofType(companyMyNotaryActions.deleteOneCompanyMyNotary),
      withLatestFrom(selectCompanyMyNotaryState$),
      concatMap(([{ idCompanyMyNotary }, state]) =>
        this.companyMyNotaryApiService.deleteCompanyMyNotary(idCompanyMyNotary).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCompanyMyNotaryActions(state.entities[idCompanyMyNotary] as CompanyMyNotaryEntityState),
              companyMyNotaryActions.deleteOneCompanyMyNotary.type
            )
          ]),
          catchError(error => of(companyMyNotaryActions.companyMyNotariesFailure({ error })))
        )
      )
    );
  });

  normalizeManyCompanyMyNotariesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyMyNotaryActions.normalizeManyCompanyMyNotariesAfterUpsert),
      concatMap(({ companyMyNotaries }) => {
        const actions: Action[] = getActionsToNormalizeCompanyMyNotary(companyMyNotaries, StoreActionType.upsert);
        return [getMultiAction(actions, '[CompanyMyNotary] Normalization After Upsert Success')];
      })
    );
  });
}
