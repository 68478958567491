import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidenceWorkEntityState } from '@_model/interfaces/residence-work.model';

export interface ResidenceWorkState extends EntityState<ResidenceWorkEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidenceWorkEntityState> = createEntityAdapter<ResidenceWorkEntityState>({
  selectId: o => o.idResidenceWork
});
export const initialState: ResidenceWorkState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residenceWorkFeatureKey = 'residenceWork';
