import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as StratalotFeeActions from './stratalot-fee.actions';
import { adapter, initialState, StratalotFeeState } from './stratalot-fee.state';
import { Dictionary } from '@ngrx/entity';
import { StratalotFeeEntityState } from '@_model/interfaces/stratalot-fee.model';

export const stratalotFeeReducersGeneratedFunctions: ReducerTypes<StratalotFeeState, readonly ActionCreator[]>[] = [
  on(StratalotFeeActions.getOneStratalotFee, (state: StratalotFeeState) => setLoadingsState(state, true)),
  on(StratalotFeeActions.getManyStratalotFees, (state: StratalotFeeState) => setLoadingsState(state, true)),
  on(StratalotFeeActions.upsertOneStratalotFee, (state: StratalotFeeState) => setLoadingsState(state, true)),

  on(StratalotFeeActions.upsertManyStratalotFeesSuccess, (state: StratalotFeeState, { stratalotFees }) =>
    adapter.upsertMany(stratalotFees, setLoadingsState(state, false))
  ),
  on(StratalotFeeActions.deleteOneStratalotFee, (state: StratalotFeeState) => setLoadingsState(state, true)),
  on(StratalotFeeActions.deleteOneStratalotFeeSuccess, (state: StratalotFeeState, { idStratalotFee }) =>
    adapter.removeOne(idStratalotFee, setLoadingsState(state, false))
  ),
  on(StratalotFeeActions.clearActive, (state: StratalotFeeState) => ({ ...state, actives: [] })),
  on(StratalotFeeActions.addManyActives, (state: StratalotFeeState, { idStratalotFees }) => ({
    ...state,
    actives: state.actives.concat(idStratalotFees)
  })),
  on(StratalotFeeActions.deleteOneActive, (state: StratalotFeeState, { idStratalotFee }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idStratalotFee)
  })),

  on(StratalotFeeActions.clearStore, () => initialState),

  on(StratalotFeeActions.addStratalotSuccess, (state: StratalotFeeState, { idStratalotFee, idStratalot }) => {
    if (!state.entities[idStratalotFee]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalotFee]: {
          ...state.entities[idStratalotFee],
          stratalot: idStratalot
        }
      }
    };
  }),

  on(StratalotFeeActions.deleteManyStratalotSuccess, (state: StratalotFeeState, { idStratalots, idStratalotFees }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idStratalotFees.reduce((entities, idStratalotFee) => {
          if (!state.entities[idStratalotFee]?.stratalot) {
            return entities;
          }
          entities[idStratalotFee] = {
            ...state.entities[idStratalotFee],
            stratalot: idStratalots.some(
              (idStratalot: number) => idStratalot === state.entities[idStratalotFee]?.stratalot
            )
              ? undefined
              : state.entities[idStratalotFee]?.stratalot
          } as StratalotFeeEntityState;
          return entities;
        }, {} as Dictionary<StratalotFeeEntityState>)
      }
    };
  })
];

export function setLoadingsState(
  state: StratalotFeeState,
  isLoading: boolean,
  isLoaded: boolean = true
): StratalotFeeState {
  return { ...state, isLoaded, isLoading };
}
