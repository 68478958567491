import { OrganizationLeadTodoAppliedRuleState, initialState } from './organization-lead-todo-applied-rule.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationLeadTodoAppliedRuleReducersGeneratedFunctions } from './organization-lead-todo-applied-rule-generated.reducer';

const organizationLeadTodoAppliedRuleReducersFunctions = [...organizationLeadTodoAppliedRuleReducersGeneratedFunctions];

const organizationLeadTodoAppliedRuleReducer = createReducer(
  initialState,
  ...organizationLeadTodoAppliedRuleReducersFunctions
);

export function reducer(state: OrganizationLeadTodoAppliedRuleState | undefined, action: Action) {
  return organizationLeadTodoAppliedRuleReducer(state, action);
}
