import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationStratalotTodoProfil,
  OrganizationStratalotTodoProfilEntityState
} from '@_model/interfaces/organization-stratalot-todo-profil.model';
import { Profil, ProfilEntityState } from '@_model/interfaces/profil.model';
import {
  OrganizationStratalotTodo,
  OrganizationStratalotTodoEntityState
} from '@_model/interfaces/organization-stratalot-todo.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationStratalotTodoProfilFeatureKey,
  OrganizationStratalotTodoProfilState
} from './organization-stratalot-todo-profil.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationStratalotTodoProfilRelations: string[] = ['profils', 'organizationStratalotTodos'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationStratalotTodoProfilState = createFeatureSelector<OrganizationStratalotTodoProfilState>(
  organizationStratalotTodoProfilFeatureKey
);

export const selectIsLoadedOrganizationStratalotTodoProfil = createSelector(
  selectOrganizationStratalotTodoProfilState,
  (state: OrganizationStratalotTodoProfilState) => state.isLoaded
);

export const selectIsLoadingOrganizationStratalotTodoProfil = createSelector(
  selectOrganizationStratalotTodoProfilState,
  (state: OrganizationStratalotTodoProfilState) => state.isLoading
);

export const selectIsReadyOrganizationStratalotTodoProfil = createSelector(
  selectOrganizationStratalotTodoProfilState,
  (state: OrganizationStratalotTodoProfilState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationStratalotTodoProfil = createSelector(
  selectOrganizationStratalotTodoProfilState,
  (state: OrganizationStratalotTodoProfilState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationStratalotTodoProfilModel: SelectorModel = {
  name: 'organizationStratalotTodoProfils',
  getSelector: selectAllOrganizationStratalotTodoProfilsDictionary,
  isReady: selectIsReadyOrganizationStratalotTodoProfil
};

export const selectOrganizationStratalotTodoProfilsEntities = createSelector(
  selectOrganizationStratalotTodoProfilState,
  selectEntities
);

export const selectOrganizationStratalotTodoProfilsArray = createSelector(
  selectOrganizationStratalotTodoProfilState,
  selectAll
);

export const selectIdOrganizationStratalotTodoProfilsActive = createSelector(
  selectOrganizationStratalotTodoProfilState,
  (state: OrganizationStratalotTodoProfilState) => state.actives
);

const organizationStratalotTodoProfilsInObject = (
  organizationStratalotTodoProfils: Dictionary<OrganizationStratalotTodoProfilEntityState>
) => ({ organizationStratalotTodoProfils });

const selectOrganizationStratalotTodoProfilsEntitiesDictionary = createSelector(
  selectOrganizationStratalotTodoProfilsEntities,
  organizationStratalotTodoProfilsInObject
);

const selectAllOrganizationStratalotTodoProfilsObject = createSelector(
  selectOrganizationStratalotTodoProfilsEntities,
  organizationStratalotTodoProfils => {
    return hydrateAll({ organizationStratalotTodoProfils });
  }
);

const selectOneOrganizationStratalotTodoProfilDictionary = (idOrganizationStratalotTodoProfil: number) =>
  createSelector(selectOrganizationStratalotTodoProfilsEntities, organizationStratalotTodoProfils => {
    return {
      organizationStratalotTodoProfils: {
        [idOrganizationStratalotTodoProfil]: organizationStratalotTodoProfils[idOrganizationStratalotTodoProfil]
      }
    };
  });

const selectOneOrganizationStratalotTodoProfilDictionaryWithoutChild = (idOrganizationStratalotTodoProfil: number) =>
  createSelector(selectOrganizationStratalotTodoProfilsEntities, organizationStratalotTodoProfils => {
    return { organizationStratalotTodoProfil: organizationStratalotTodoProfils[idOrganizationStratalotTodoProfil] };
  });

const selectActiveOrganizationStratalotTodoProfilsEntities = createSelector(
  selectIdOrganizationStratalotTodoProfilsActive,
  selectOrganizationStratalotTodoProfilsEntities,
  (actives: number[], organizationStratalotTodoProfils: Dictionary<OrganizationStratalotTodoProfilEntityState>) =>
    getOrganizationStratalotTodoProfilsFromActives(actives, organizationStratalotTodoProfils)
);

function getOrganizationStratalotTodoProfilsFromActives(
  actives: number[],
  organizationStratalotTodoProfils: Dictionary<OrganizationStratalotTodoProfilEntityState>
): Dictionary<OrganizationStratalotTodoProfilEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationStratalotTodoProfils[idActive]) {
      acc[idActive] = organizationStratalotTodoProfils[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationStratalotTodoProfilEntityState>);
}

const selectAllOrganizationStratalotTodoProfilsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationStratalotTodoProfils(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationStratalotTodoProfil>(
      schema,
      selectAllOrganizationStratalotTodoProfilsSelectors,
      selectOrganizationStratalotTodoProfilsEntitiesDictionary,
      getRelationSelectors,
      organizationStratalotTodoProfilRelations,
      hydrateAll,
      'organizationStratalotTodoProfil'
    );
  } else {
    return selectAllOrganizationStratalotTodoProfilsObject;
  }
}

export function selectAllOrganizationStratalotTodoProfilsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationStratalotTodoProfils'
): Selector {
  return createSelector(selectAllOrganizationStratalotTodoProfils(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationStratalotTodoProfilEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationStratalotTodoProfils.length; i++) {
      res[customKey][result.organizationStratalotTodoProfils[i].idOrganizationStratalotTodoProfil] =
        result.organizationStratalotTodoProfils[i];
    }
    return res;
  });
}

export function selectOneOrganizationStratalotTodoProfil(
  schema: SelectSchema = {},
  idOrganizationStratalotTodoProfil: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [
      selectOneOrganizationStratalotTodoProfilDictionary(idOrganizationStratalotTodoProfil)
    ];
    selectors.push(
      ...getRelationSelectors(schema, organizationStratalotTodoProfilRelations, 'organizationStratalotTodoProfil')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationStratalotTodoProfilDictionaryWithoutChild(idOrganizationStratalotTodoProfil);
  }
}

export function selectActiveOrganizationStratalotTodoProfils(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationStratalotTodoProfilsEntities, organizationStratalotTodoProfils => ({
      organizationStratalotTodoProfils
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, organizationStratalotTodoProfilRelations, 'organizationStratalotTodoProfil')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationStratalotTodoProfils: Dictionary<OrganizationStratalotTodoProfilEntityState>;
  profils?: Dictionary<ProfilEntityState>;
  organizationStratalotTodos?: Dictionary<OrganizationStratalotTodoEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationStratalotTodoProfils: (OrganizationStratalotTodoProfil | null)[];
} {
  const { organizationStratalotTodoProfils, profils, organizationStratalotTodos } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationStratalotTodoProfils: Object.keys(organizationStratalotTodoProfils).map(
      idOrganizationStratalotTodoProfil =>
        hydrate(
          organizationStratalotTodoProfils[
            idOrganizationStratalotTodoProfil
          ] as OrganizationStratalotTodoProfilEntityState,
          profils,
          organizationStratalotTodos
        )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationStratalotTodoProfil: OrganizationStratalotTodoProfilEntityState | null;
} {
  const { organizationStratalotTodoProfils, profils, organizationStratalotTodos } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationStratalotTodoProfil = Object.values(organizationStratalotTodoProfils)[0];
  return {
    organizationStratalotTodoProfil: hydrate(
      organizationStratalotTodoProfil as OrganizationStratalotTodoProfilEntityState,
      profils,
      organizationStratalotTodos
    )
  };
}

function hydrate(
  organizationStratalotTodoProfil: OrganizationStratalotTodoProfilEntityState,
  profilEntities?: Dictionary<ProfilEntityState>,
  organizationStratalotTodoEntities?: Dictionary<OrganizationStratalotTodoEntityState>
): OrganizationStratalotTodoProfil | null {
  if (!organizationStratalotTodoProfil) {
    return null;
  }

  const organizationStratalotTodoProfilHydrated: OrganizationStratalotTodoProfilEntityState = {
    ...organizationStratalotTodoProfil
  };
  if (profilEntities) {
    organizationStratalotTodoProfilHydrated.profil = profilEntities[
      organizationStratalotTodoProfil.profil as number
    ] as Profil;
  } else {
    delete organizationStratalotTodoProfilHydrated.profil;
  }
  if (organizationStratalotTodoEntities) {
    organizationStratalotTodoProfilHydrated.organizationStratalotTodo = organizationStratalotTodoEntities[
      organizationStratalotTodoProfil.organizationStratalotTodo as number
    ] as OrganizationStratalotTodo;
  } else {
    delete organizationStratalotTodoProfilHydrated.organizationStratalotTodo;
  }

  return organizationStratalotTodoProfilHydrated as OrganizationStratalotTodoProfil;
}
