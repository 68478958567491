import { StepProgressFamilyState, initialState } from './step-progress-family.state';
import { Action, createReducer } from '@ngrx/store';
import { stepProgressFamilyReducersGeneratedFunctions } from './step-progress-family-generated.reducer';

const stepProgressFamilyReducersFunctions = [...stepProgressFamilyReducersGeneratedFunctions];

const stepProgressFamilyReducer = createReducer(initialState, ...stepProgressFamilyReducersFunctions);

export function reducer(state: StepProgressFamilyState | undefined, action: Action) {
  return stepProgressFamilyReducer(state, action);
}
