import { createAction, props } from '@ngrx/store';
import { GeneratedDocument } from '@_model/interfaces/generated-document.model';

export * from './generated-document-generated.actions';

export const addOneGeneratedDocument = createAction(
  '[GeneratedDocument] Add One GeneratedDocument',
  props<{
    generatedDocument: Partial<GeneratedDocument>;
    idCompany?: number[];
    idResidence?: number;
  }>()
);
export const getManyGeneratedDocumentsByCompanies = createAction(
  '[GeneratedDocument] Get Many GeneratedDocument By Companies',
  props<{
    idsCompanies?: number[];
    page?: string;
  }>()
);
export const getManyGeneratedDocumentsByResidences = createAction(
  '[GeneratedDocument] Get Many GeneratedDocument By Residences',
  props<{
    idsResidences?: number[];
  }>()
);
