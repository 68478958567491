import { ResidenceStudyLotState, initialState } from './residence-study-lot.state';
import { Action, createReducer } from '@ngrx/store';
import { residenceStudyLotReducersGeneratedFunctions } from './residence-study-lot-generated.reducer';

const residenceStudyLotReducersFunctions = [...residenceStudyLotReducersGeneratedFunctions];

const residenceStudyLotReducer = createReducer(initialState, ...residenceStudyLotReducersFunctions);

export function reducer(state: ResidenceStudyLotState | undefined, action: Action) {
  return residenceStudyLotReducer(state, action);
}
