import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationProspectOriginEntityState } from '@_model/interfaces/organization-prospect-origin.model';

export interface OrganizationProspectOriginState extends EntityState<OrganizationProspectOriginEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationProspectOriginEntityState> =
  createEntityAdapter<OrganizationProspectOriginEntityState>({
    selectId: o => o.idOrganizationProspectOrigin
  });
export const initialState: OrganizationProspectOriginState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationProspectOriginFeatureKey = 'organizationProspectOrigin';
