import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ResidenceStudyTodo, ResidenceStudyTodoEntityState } from '@_model/interfaces/residence-study-todo.model';
import { ResidenceStudyTodoRelationsIds } from './residence-study-todo-generated.effects';

export const getOneResidenceStudyTodo = createAction(
  '[ResidenceStudyTodo] Get One ResidenceStudyTodo',
  props<{ idResidenceStudyTodo: number; params?: any }>()
);

export const getManyResidenceStudyTodos = createAction(
  '[ResidenceStudyTodo] Get Many ResidenceStudyTodos',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ResidenceStudyTodo] Add Many Actives ResidenceStudyTodo',
  props<{ idResidenceStudyTodos: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidenceStudyTodo] Delete One Active ResidenceStudyTodo',
  props<{ idResidenceStudyTodo: number }>()
);

export const clearActive = createAction('[ResidenceStudyTodo] Clear Active ResidenceStudyTodo');

export const upsertOneResidenceStudyTodo = createAction(
  '[ResidenceStudyTodo] Upsert One ResidenceStudyTodo',
  props<{
    residenceStudyTodo: Partial<ResidenceStudyTodo>;
    ids?: ResidenceStudyTodoRelationsIds;
  }>()
);

export const upsertManyResidenceStudyTodos = createAction(
  '[ResidenceStudyTodo] Upsert Many ResidenceStudyTodos',
  props<{
    residenceStudyTodos: Partial<ResidenceStudyTodo>[];
    ids?: ResidenceStudyTodoRelationsIds;
  }>()
);

export const upsertManyResidenceStudyTodosSuccess = createAction(
  '[ResidenceStudyTodo] Create Many ResidenceStudyTodos Success',
  props<{ residenceStudyTodos: ResidenceStudyTodoEntityState[] }>()
);

export const deleteOneResidenceStudyTodo = createAction(
  '[ResidenceStudyTodo] Delete One ResidenceStudyTodo',
  props<{ idResidenceStudyTodo: number }>()
);

export const deleteOneResidenceStudyTodoSuccess = createAction(
  '[ResidenceStudyTodo] Delete One ResidenceStudyTodo Success',
  props<{ idResidenceStudyTodo: number }>()
);

export const normalizeManyResidenceStudyTodosAfterUpsert = createAction(
  '[ResidenceStudyTodo] Normalize Many ResidenceStudyTodos After Upsert',
  props<{ residenceStudyTodos: ResidenceStudyTodoEntityState[] }>()
);

export const residenceStudyTodosFailure = createAction(
  '[ResidenceStudyTodo] ResidenceStudyTodos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidenceStudyTodo] Clear ResidenceStudyTodos');

export const addOrganizationResidenceStudyTodoSuccess = createAction(
  '[ResidenceStudyTodo] Add OrganizationResidenceStudyTodo',
  props<{ idResidenceStudyTodo: number; idOrganizationResidenceStudyTodo: number }>()
);

export const deleteManyOrganizationResidenceStudyTodoSuccess = createAction(
  '[ResidenceStudyTodo] Delete Many OrganizationResidenceStudyTodo',
  props<{ idOrganizationResidenceStudyTodos: number[]; idResidenceStudyTodos: number[] }>()
);

export const addResidenceStudySuccess = createAction(
  '[ResidenceStudyTodo] Add ResidenceStudy',
  props<{ idResidenceStudyTodo: number; idResidenceStudy: number }>()
);

export const deleteManyResidenceStudySuccess = createAction(
  '[ResidenceStudyTodo] Delete Many ResidenceStudy',
  props<{ idResidenceStudies: number[]; idResidenceStudyTodos: number[] }>()
);
