export * from './company-generated.actions';
import { createAction, props } from '@ngrx/store';

export const uploadLogo = createAction(
  '[Company] Upload One Logo',
  props<{
    idCompany: number;
    files: File[];
    logoNum: number;
  }>()
);
