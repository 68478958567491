import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyUbiflow, CompanyUbiflowEntityState } from '@_model/interfaces/company-ubiflow.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, companyUbiflowFeatureKey, CompanyUbiflowState } from './company-ubiflow.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyUbiflowRelations: string[] = ['companies'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyUbiflowState = createFeatureSelector<CompanyUbiflowState>(companyUbiflowFeatureKey);

export const selectIsLoadedCompanyUbiflow = createSelector(
  selectCompanyUbiflowState,
  (state: CompanyUbiflowState) => state.isLoaded
);

export const selectIsLoadingCompanyUbiflow = createSelector(
  selectCompanyUbiflowState,
  (state: CompanyUbiflowState) => state.isLoading
);

export const selectIsReadyCompanyUbiflow = createSelector(
  selectCompanyUbiflowState,
  (state: CompanyUbiflowState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyUbiflow = createSelector(
  selectCompanyUbiflowState,
  (state: CompanyUbiflowState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyUbiflowModel: SelectorModel = {
  name: 'companyUbiflows',
  getSelector: selectAllCompanyUbiflowsDictionary,
  isReady: selectIsReadyCompanyUbiflow
};

export const selectCompanyUbiflowsEntities = createSelector(selectCompanyUbiflowState, selectEntities);

export const selectCompanyUbiflowsArray = createSelector(selectCompanyUbiflowState, selectAll);

export const selectIdCompanyUbiflowsActive = createSelector(
  selectCompanyUbiflowState,
  (state: CompanyUbiflowState) => state.actives
);

const companyUbiflowsInObject = (companyUbiflows: Dictionary<CompanyUbiflowEntityState>) => ({ companyUbiflows });

const selectCompanyUbiflowsEntitiesDictionary = createSelector(selectCompanyUbiflowsEntities, companyUbiflowsInObject);

const selectAllCompanyUbiflowsObject = createSelector(selectCompanyUbiflowsEntities, companyUbiflows => {
  return hydrateAll({ companyUbiflows });
});

const selectOneCompanyUbiflowDictionary = (idCompanyUbiflow: number) =>
  createSelector(selectCompanyUbiflowsEntities, companyUbiflows => {
    return { companyUbiflows: { [idCompanyUbiflow]: companyUbiflows[idCompanyUbiflow] } };
  });

const selectOneCompanyUbiflowDictionaryWithoutChild = (idCompanyUbiflow: number) =>
  createSelector(selectCompanyUbiflowsEntities, companyUbiflows => {
    return { companyUbiflow: companyUbiflows[idCompanyUbiflow] };
  });

const selectActiveCompanyUbiflowsEntities = createSelector(
  selectIdCompanyUbiflowsActive,
  selectCompanyUbiflowsEntities,
  (actives: number[], companyUbiflows: Dictionary<CompanyUbiflowEntityState>) =>
    getCompanyUbiflowsFromActives(actives, companyUbiflows)
);

function getCompanyUbiflowsFromActives(
  actives: number[],
  companyUbiflows: Dictionary<CompanyUbiflowEntityState>
): Dictionary<CompanyUbiflowEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyUbiflows[idActive]) {
      acc[idActive] = companyUbiflows[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyUbiflowEntityState>);
}

const selectAllCompanyUbiflowsSelectors: Dictionary<Selector> = {};
export function selectAllCompanyUbiflows(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyUbiflow>(
      schema,
      selectAllCompanyUbiflowsSelectors,
      selectCompanyUbiflowsEntitiesDictionary,
      getRelationSelectors,
      companyUbiflowRelations,
      hydrateAll,
      'companyUbiflow'
    );
  } else {
    return selectAllCompanyUbiflowsObject;
  }
}

export function selectAllCompanyUbiflowsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyUbiflows'
): Selector {
  return createSelector(selectAllCompanyUbiflows(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyUbiflowEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyUbiflows.length; i++) {
      res[customKey][result.companyUbiflows[i].idCompanyUbiflow] = result.companyUbiflows[i];
    }
    return res;
  });
}

export function selectOneCompanyUbiflow(schema: SelectSchema = {}, idCompanyUbiflow: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyUbiflowDictionary(idCompanyUbiflow)];
    selectors.push(...getRelationSelectors(schema, companyUbiflowRelations, 'companyUbiflow'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyUbiflowDictionaryWithoutChild(idCompanyUbiflow);
  }
}

export function selectActiveCompanyUbiflows(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyUbiflowsEntities, companyUbiflows => ({ companyUbiflows }))
  ];
  selectors.push(...getRelationSelectors(schema, companyUbiflowRelations, 'companyUbiflow'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyUbiflows: Dictionary<CompanyUbiflowEntityState>;
  companies?: Dictionary<CompanyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { companyUbiflows: (CompanyUbiflow | null)[] } {
  const { companyUbiflows, companies } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    companyUbiflows: Object.keys(companyUbiflows).map(idCompanyUbiflow =>
      hydrate(companyUbiflows[idCompanyUbiflow] as CompanyUbiflowEntityState, companies)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { companyUbiflow: CompanyUbiflowEntityState | null } {
  const { companyUbiflows, companies } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const companyUbiflow = Object.values(companyUbiflows)[0];
  return { companyUbiflow: hydrate(companyUbiflow as CompanyUbiflowEntityState, companies) };
}

function hydrate(
  companyUbiflow: CompanyUbiflowEntityState,
  companyEntities?: Dictionary<CompanyEntityState>
): CompanyUbiflow | null {
  if (!companyUbiflow) {
    return null;
  }

  const companyUbiflowHydrated: CompanyUbiflowEntityState = { ...companyUbiflow };
  if (companyEntities) {
    companyUbiflowHydrated.company = companyEntities[companyUbiflow.company as number] as Company;
  } else {
    delete companyUbiflowHydrated.company;
  }

  return companyUbiflowHydrated as CompanyUbiflow;
}
