import { CompanyTerritoireState, initialState } from './company-territoire.state';
import { Action, createReducer } from '@ngrx/store';
import { companyTerritoireReducersGeneratedFunctions } from './company-territoire-generated.reducer';

const companyTerritoireReducersFunctions = [...companyTerritoireReducersGeneratedFunctions];

const companyTerritoireReducer = createReducer(initialState, ...companyTerritoireReducersFunctions);

export function reducer(state: CompanyTerritoireState | undefined, action: Action) {
  return companyTerritoireReducer(state, action);
}
