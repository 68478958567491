export * from './prospect-generated.actions';
import { createAction, props } from '@ngrx/store';

export const getManyProspectsByResidence = createAction(
  '[Prospect] Get Many Prospects From Residence',
  props<{ idResidence: number; params?: any }>()
);

export const deleteManyProspectsByOrganization = createAction(
  '[Prospect] Delete Many Prospects From Organization',
  props<{ idOrganization: number }>()
);

export const deleteManyProspectsSuccess = createAction(
  '[Prospect] Delete Many Prospects Success',
  props<{ idProspects: number[] }>()
);
