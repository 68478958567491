export enum NatureRevenuEnum {
  CDI = 'CDI',
  CDD = 'CDD',
  contractuel = 'Contractuel',
  fonctionnaire = 'Fonctionnaire',
  agent = 'Agent',
  intérimaire = 'Intérimaire',
  indépendant = 'Indépendant',
  invalidité = 'Invalidité',
  retraité = 'Retraité',
  rsaChômage = 'RSA/Chômage',
  neTravaillePas = 'Ne travaille pas',
  autre = 'Autre'
}
