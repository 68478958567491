import { RepositoryService } from '@_services/api/repository.service';
import { StratalotCampaign } from '@_model/interfaces/stratalot-campaign.model';
import { Observable } from 'rxjs';

export class GeneratedStratalotCampaignApiService {
  constructor(protected repo: RepositoryService) {}

  public getStratalotCampaigns(params?: any): Observable<StratalotCampaign[]> {
    return this.repo.getData<StratalotCampaign[]>('stratalot-campaign', params);
  }

  public getStratalotCampaign(params: { idStratalotCampaign: number; params?: any }): Observable<StratalotCampaign> {
    return this.repo.getData<StratalotCampaign>('stratalot-campaign/' + params.idStratalotCampaign, params.params);
  }

  public addStratalotCampaign(stratalotCampaign: Partial<StratalotCampaign>): Observable<StratalotCampaign> {
    return this.repo.create<StratalotCampaign>('stratalot-campaign', stratalotCampaign);
  }

  public updateStratalotCampaign(stratalotCampaign: Partial<StratalotCampaign>): Observable<StratalotCampaign> {
    return this.repo.update('stratalot-campaign', stratalotCampaign);
  }

  public deleteStratalotCampaign(idStratalotCampaign: number): Observable<number> {
    return this.repo.delete('stratalot-campaign/' + +idStratalotCampaign);
  }
}
