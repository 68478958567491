import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationThirdPartyActions from './organization-third-party.actions';
import { adapter, initialState, OrganizationThirdPartyState } from './organization-third-party.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationThirdPartyEntityState } from '@_model/interfaces/organization-third-party.model';

export const organizationThirdPartyReducersGeneratedFunctions: ReducerTypes<
  OrganizationThirdPartyState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationThirdPartyActions.getOneOrganizationThirdParty, (state: OrganizationThirdPartyState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationThirdPartyActions.getManyOrganizationThirdParties, (state: OrganizationThirdPartyState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationThirdPartyActions.upsertOneOrganizationThirdParty, (state: OrganizationThirdPartyState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationThirdPartyActions.upsertManyOrganizationThirdPartiesSuccess,
    (state: OrganizationThirdPartyState, { organizationThirdParties }) =>
      adapter.upsertMany(organizationThirdParties, setLoadingsState(state, false))
  ),
  on(OrganizationThirdPartyActions.deleteOneOrganizationThirdParty, (state: OrganizationThirdPartyState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationThirdPartyActions.deleteOneOrganizationThirdPartySuccess,
    (state: OrganizationThirdPartyState, { idOrganizationThirdParty }) =>
      adapter.removeOne(idOrganizationThirdParty, setLoadingsState(state, false))
  ),
  on(OrganizationThirdPartyActions.clearActive, (state: OrganizationThirdPartyState) => ({ ...state, actives: [] })),
  on(
    OrganizationThirdPartyActions.addManyActives,
    (state: OrganizationThirdPartyState, { idOrganizationThirdParties }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationThirdParties)
    })
  ),
  on(
    OrganizationThirdPartyActions.deleteOneActive,
    (state: OrganizationThirdPartyState, { idOrganizationThirdParty }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationThirdParty)
    })
  ),

  on(OrganizationThirdPartyActions.clearStore, () => initialState),

  on(
    OrganizationThirdPartyActions.addManyResidenceContactSuccess,
    (state: OrganizationThirdPartyState, { idOrganizationThirdParty, idResidenceContacts }) => {
      if (!state.entities[idOrganizationThirdParty]) {
        return state;
      }
      const residenceContacts = (state.entities[idOrganizationThirdParty]?.residenceContacts as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationThirdParty]: {
            ...state.entities[idOrganizationThirdParty],
            residenceContacts: residenceContacts.concat(
              idResidenceContacts.filter(id => residenceContacts.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationThirdPartyActions.deleteManyResidenceContactSuccess,
    (state: OrganizationThirdPartyState, { idResidenceContacts, idOrganizationThirdParties }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationThirdParties.reduce((entities, idOrganizationThirdParty) => {
            if (!state.entities[idOrganizationThirdParty]?.residenceContacts) {
              return entities;
            }
            entities[idOrganizationThirdParty] = {
              ...state.entities[idOrganizationThirdParty],
              residenceContacts: (state.entities[idOrganizationThirdParty]?.residenceContacts as number[])?.filter(
                (idResidenceContact: number) => !idResidenceContacts.some((id: number) => id === idResidenceContact)
              )
            } as OrganizationThirdPartyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationThirdPartyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationThirdPartyActions.addOrganizationSuccess,
    (state: OrganizationThirdPartyState, { idOrganizationThirdParty, idOrganization }) => {
      if (!state.entities[idOrganizationThirdParty]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationThirdParty]: {
            ...state.entities[idOrganizationThirdParty],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationThirdPartyActions.deleteManyOrganizationSuccess,
    (state: OrganizationThirdPartyState, { idOrganizations, idOrganizationThirdParties }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationThirdParties.reduce((entities, idOrganizationThirdParty) => {
            if (!state.entities[idOrganizationThirdParty]?.organization) {
              return entities;
            }
            entities[idOrganizationThirdParty] = {
              ...state.entities[idOrganizationThirdParty],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationThirdParty]?.organization
              )
                ? undefined
                : state.entities[idOrganizationThirdParty]?.organization
            } as OrganizationThirdPartyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationThirdPartyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationThirdPartyActions.addOrganizationSocieteSuccess,
    (state: OrganizationThirdPartyState, { idOrganizationThirdParty, idOrganizationSociete }) => {
      if (!state.entities[idOrganizationThirdParty]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationThirdParty]: {
            ...state.entities[idOrganizationThirdParty],
            organizationSociete: idOrganizationSociete
          }
        }
      };
    }
  ),

  on(
    OrganizationThirdPartyActions.deleteManyOrganizationSocieteSuccess,
    (state: OrganizationThirdPartyState, { idOrganizationSocietes, idOrganizationThirdParties }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationThirdParties.reduce((entities, idOrganizationThirdParty) => {
            if (!state.entities[idOrganizationThirdParty]?.organizationSociete) {
              return entities;
            }
            entities[idOrganizationThirdParty] = {
              ...state.entities[idOrganizationThirdParty],
              organizationSociete: idOrganizationSocietes.some(
                (idOrganizationSociete: number) =>
                  idOrganizationSociete === state.entities[idOrganizationThirdParty]?.organizationSociete
              )
                ? undefined
                : state.entities[idOrganizationThirdParty]?.organizationSociete
            } as OrganizationThirdPartyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationThirdPartyEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationThirdPartyActions.addOrganizationMetierSuccess,
    (state: OrganizationThirdPartyState, { idOrganizationThirdParty, idOrganizationMetier }) => {
      if (!state.entities[idOrganizationThirdParty]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationThirdParty]: {
            ...state.entities[idOrganizationThirdParty],
            organizationMetier: idOrganizationMetier
          }
        }
      };
    }
  ),

  on(
    OrganizationThirdPartyActions.deleteManyOrganizationMetierSuccess,
    (state: OrganizationThirdPartyState, { idOrganizationMetiers, idOrganizationThirdParties }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationThirdParties.reduce((entities, idOrganizationThirdParty) => {
            if (!state.entities[idOrganizationThirdParty]?.organizationMetier) {
              return entities;
            }
            entities[idOrganizationThirdParty] = {
              ...state.entities[idOrganizationThirdParty],
              organizationMetier: idOrganizationMetiers.some(
                (idOrganizationMetier: number) =>
                  idOrganizationMetier === state.entities[idOrganizationThirdParty]?.organizationMetier
              )
                ? undefined
                : state.entities[idOrganizationThirdParty]?.organizationMetier
            } as OrganizationThirdPartyEntityState;
            return entities;
          }, {} as Dictionary<OrganizationThirdPartyEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationThirdPartyState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationThirdPartyState {
  return { ...state, isLoaded, isLoading };
}
