import { BoardStateState, initialState } from './board-state.state';
import { Action, createReducer } from '@ngrx/store';
import { boardStateReducersGeneratedFunctions } from './board-state-generated.reducer';

const boardStateReducersFunctions = [...boardStateReducersGeneratedFunctions];

const boardStateReducer = createReducer(initialState, ...boardStateReducersFunctions);

export function reducer(state: BoardStateState | undefined, action: Action) {
  return boardStateReducer(state, action);
}
