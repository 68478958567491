import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StratalotPriceList, StratalotPriceListEntityState } from '@_model/interfaces/stratalot-price-list.model';
import { ResidencePriceGrid, ResidencePriceGridEntityState } from '@_model/interfaces/residence-price-grid.model';
import { CompanyStratalotType, CompanyStratalotTypeEntityState } from '@_model/interfaces/company-stratalot-type.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, stratalotPriceListFeatureKey, StratalotPriceListState } from './stratalot-price-list.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const stratalotPriceListRelations: string[] = ['residencePriceGrids', 'companyStratalotTypes'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectStratalotPriceListState =
  createFeatureSelector<StratalotPriceListState>(stratalotPriceListFeatureKey);

export const selectIsLoadedStratalotPriceList = createSelector(
  selectStratalotPriceListState,
  (state: StratalotPriceListState) => state.isLoaded
);

export const selectIsLoadingStratalotPriceList = createSelector(
  selectStratalotPriceListState,
  (state: StratalotPriceListState) => state.isLoading
);

export const selectIsReadyStratalotPriceList = createSelector(
  selectStratalotPriceListState,
  (state: StratalotPriceListState) => !state.isLoading
);

export const selectIsReadyAndLoadedStratalotPriceList = createSelector(
  selectStratalotPriceListState,
  (state: StratalotPriceListState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const StratalotPriceListModel: SelectorModel = {
  name: 'stratalotPriceLists',
  getSelector: selectAllStratalotPriceListsDictionary,
  isReady: selectIsReadyStratalotPriceList
};

export const selectStratalotPriceListsEntities = createSelector(selectStratalotPriceListState, selectEntities);

export const selectStratalotPriceListsArray = createSelector(selectStratalotPriceListState, selectAll);

export const selectIdStratalotPriceListsActive = createSelector(
  selectStratalotPriceListState,
  (state: StratalotPriceListState) => state.actives
);

const stratalotPriceListsInObject = (stratalotPriceLists: Dictionary<StratalotPriceListEntityState>) => ({
  stratalotPriceLists
});

const selectStratalotPriceListsEntitiesDictionary = createSelector(
  selectStratalotPriceListsEntities,
  stratalotPriceListsInObject
);

const selectAllStratalotPriceListsObject = createSelector(selectStratalotPriceListsEntities, stratalotPriceLists => {
  return hydrateAll({ stratalotPriceLists });
});

const selectOneStratalotPriceListDictionary = (idStratalotPriceList: number) =>
  createSelector(selectStratalotPriceListsEntities, stratalotPriceLists => {
    return { stratalotPriceLists: { [idStratalotPriceList]: stratalotPriceLists[idStratalotPriceList] } };
  });

const selectOneStratalotPriceListDictionaryWithoutChild = (idStratalotPriceList: number) =>
  createSelector(selectStratalotPriceListsEntities, stratalotPriceLists => {
    return { stratalotPriceList: stratalotPriceLists[idStratalotPriceList] };
  });

const selectActiveStratalotPriceListsEntities = createSelector(
  selectIdStratalotPriceListsActive,
  selectStratalotPriceListsEntities,
  (actives: number[], stratalotPriceLists: Dictionary<StratalotPriceListEntityState>) =>
    getStratalotPriceListsFromActives(actives, stratalotPriceLists)
);

function getStratalotPriceListsFromActives(
  actives: number[],
  stratalotPriceLists: Dictionary<StratalotPriceListEntityState>
): Dictionary<StratalotPriceListEntityState> {
  return actives.reduce((acc, idActive) => {
    if (stratalotPriceLists[idActive]) {
      acc[idActive] = stratalotPriceLists[idActive];
    }
    return acc;
  }, {} as Dictionary<StratalotPriceListEntityState>);
}

const selectAllStratalotPriceListsSelectors: Dictionary<Selector> = {};
export function selectAllStratalotPriceLists(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<StratalotPriceList>(
      schema,
      selectAllStratalotPriceListsSelectors,
      selectStratalotPriceListsEntitiesDictionary,
      getRelationSelectors,
      stratalotPriceListRelations,
      hydrateAll,
      'stratalotPriceList'
    );
  } else {
    return selectAllStratalotPriceListsObject;
  }
}

export function selectAllStratalotPriceListsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'stratalotPriceLists'
): Selector {
  return createSelector(selectAllStratalotPriceLists(schema), result => {
    const res = { [customKey]: {} as Dictionary<StratalotPriceListEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.stratalotPriceLists.length; i++) {
      res[customKey][result.stratalotPriceLists[i].idStratalotPriceList] = result.stratalotPriceLists[i];
    }
    return res;
  });
}

export function selectOneStratalotPriceList(schema: SelectSchema = {}, idStratalotPriceList: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneStratalotPriceListDictionary(idStratalotPriceList)];
    selectors.push(...getRelationSelectors(schema, stratalotPriceListRelations, 'stratalotPriceList'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneStratalotPriceListDictionaryWithoutChild(idStratalotPriceList);
  }
}

export function selectActiveStratalotPriceLists(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveStratalotPriceListsEntities, stratalotPriceLists => ({ stratalotPriceLists }))
  ];
  selectors.push(...getRelationSelectors(schema, stratalotPriceListRelations, 'stratalotPriceList'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  stratalotPriceLists: Dictionary<StratalotPriceListEntityState>;
  residencePriceGrids?: Dictionary<ResidencePriceGridEntityState>;
  companyStratalotTypes?: Dictionary<CompanyStratalotTypeEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { stratalotPriceLists: (StratalotPriceList | null)[] } {
  const { stratalotPriceLists, residencePriceGrids, companyStratalotTypes } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    stratalotPriceLists: Object.keys(stratalotPriceLists).map(idStratalotPriceList =>
      hydrate(
        stratalotPriceLists[idStratalotPriceList] as StratalotPriceListEntityState,
        residencePriceGrids,
        companyStratalotTypes
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { stratalotPriceList: StratalotPriceListEntityState | null } {
  const { stratalotPriceLists, residencePriceGrids, companyStratalotTypes } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const stratalotPriceList = Object.values(stratalotPriceLists)[0];
  return {
    stratalotPriceList: hydrate(
      stratalotPriceList as StratalotPriceListEntityState,
      residencePriceGrids,
      companyStratalotTypes
    )
  };
}

function hydrate(
  stratalotPriceList: StratalotPriceListEntityState,
  residencePriceGridEntities?: Dictionary<ResidencePriceGridEntityState>,
  companyStratalotTypeEntities?: Dictionary<CompanyStratalotTypeEntityState>
): StratalotPriceList | null {
  if (!stratalotPriceList) {
    return null;
  }

  const stratalotPriceListHydrated: StratalotPriceListEntityState = { ...stratalotPriceList };
  if (residencePriceGridEntities) {
    stratalotPriceListHydrated.residencePriceGrid = residencePriceGridEntities[
      stratalotPriceList.residencePriceGrid as number
    ] as ResidencePriceGrid;
  } else {
    delete stratalotPriceListHydrated.residencePriceGrid;
  }
  if (companyStratalotTypeEntities) {
    stratalotPriceListHydrated.companyStratalotType = companyStratalotTypeEntities[
      stratalotPriceList.companyStratalotType as number
    ] as CompanyStratalotType;
  } else {
    delete stratalotPriceListHydrated.companyStratalotType;
  }

  return stratalotPriceListHydrated as StratalotPriceList;
}
