import { RepositoryService } from '@_services/api/repository.service';
import { Association } from '@_model/interfaces/association.model';
import { Observable } from 'rxjs';

export class GeneratedAssociationApiService {
  constructor(protected repo: RepositoryService) {}

  public getAssociations(params?: any): Observable<Association[]> {
    return this.repo.getData<Association[]>('association', params);
  }

  public getAssociation(params: { idAssociation: number; params?: any }): Observable<Association> {
    return this.repo.getData<Association>('association/' + params.idAssociation, params.params);
  }

  public addAssociation(association: Partial<Association>): Observable<Association> {
    return this.repo.create<Association>('association', association);
  }

  public updateAssociation(association: Partial<Association>): Observable<Association> {
    return this.repo.update('association', association);
  }

  public deleteAssociation(idAssociation: number): Observable<number> {
    return this.repo.delete('association/' + +idAssociation);
  }
}
