import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyCommunicationRecipientActions from './company-communication-recipient.actions';
import { adapter, initialState, CompanyCommunicationRecipientState } from './company-communication-recipient.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyCommunicationRecipientEntityState } from '@_model/interfaces/company-communication-recipient.model';

export const companyCommunicationRecipientReducersGeneratedFunctions: ReducerTypes<
  CompanyCommunicationRecipientState,
  readonly ActionCreator[]
>[] = [
  on(
    CompanyCommunicationRecipientActions.getOneCompanyCommunicationRecipient,
    (state: CompanyCommunicationRecipientState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationRecipientActions.getManyCompanyCommunicationRecipients,
    (state: CompanyCommunicationRecipientState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationRecipientActions.upsertOneCompanyCommunicationRecipient,
    (state: CompanyCommunicationRecipientState) => setLoadingsState(state, true)
  ),

  on(
    CompanyCommunicationRecipientActions.upsertManyCompanyCommunicationRecipientsSuccess,
    (state: CompanyCommunicationRecipientState, { companyCommunicationRecipients }) =>
      adapter.upsertMany(companyCommunicationRecipients, setLoadingsState(state, false))
  ),
  on(
    CompanyCommunicationRecipientActions.deleteOneCompanyCommunicationRecipient,
    (state: CompanyCommunicationRecipientState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationRecipientActions.deleteOneCompanyCommunicationRecipientSuccess,
    (state: CompanyCommunicationRecipientState, { idCompanyCommunicationRecipient }) =>
      adapter.removeOne(idCompanyCommunicationRecipient, setLoadingsState(state, false))
  ),
  on(CompanyCommunicationRecipientActions.clearActive, (state: CompanyCommunicationRecipientState) => ({
    ...state,
    actives: []
  })),
  on(
    CompanyCommunicationRecipientActions.addManyActives,
    (state: CompanyCommunicationRecipientState, { idCompanyCommunicationRecipients }) => ({
      ...state,
      actives: state.actives.concat(idCompanyCommunicationRecipients)
    })
  ),
  on(
    CompanyCommunicationRecipientActions.deleteOneActive,
    (state: CompanyCommunicationRecipientState, { idCompanyCommunicationRecipient }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCompanyCommunicationRecipient)
    })
  ),

  on(CompanyCommunicationRecipientActions.clearStore, () => initialState),

  on(
    CompanyCommunicationRecipientActions.addCompanyCommunicationSuccess,
    (state: CompanyCommunicationRecipientState, { idCompanyCommunicationRecipient, idCompanyCommunication }) => {
      if (!state.entities[idCompanyCommunicationRecipient]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationRecipient]: {
            ...state.entities[idCompanyCommunicationRecipient],
            companyCommunication: idCompanyCommunication
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationRecipientActions.deleteCompanyCommunicationSuccess,
    (state: CompanyCommunicationRecipientState, { idCompanyCommunications, idCompanyCommunicationRecipients }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationRecipients.reduce((entities, idCompanyCommunicationRecipient) => {
            if (!state.entities[idCompanyCommunicationRecipient]?.companyCommunication) {
              return entities;
            }
            entities[idCompanyCommunicationRecipient] = {
              ...state.entities[idCompanyCommunicationRecipient],
              companyCommunication: idCompanyCommunications.some(
                (idCompanyCommunication: number) =>
                  idCompanyCommunication === state.entities[idCompanyCommunicationRecipient].companyCommunication
              )
                ? undefined
                : state.entities[idCompanyCommunicationRecipient].companyCommunication
            };
            return entities;
          }, {})
        }
      };
    }
  ),
  on(
    CompanyCommunicationRecipientActions.addManyUserSuccess,
    (state: CompanyCommunicationRecipientState, { idCompanyCommunicationRecipient, idUsers }) => {
      if (!state.entities[idCompanyCommunicationRecipient]) {
        return state;
      }
      const users = (state.entities[idCompanyCommunicationRecipient]?.users as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationRecipient]: {
            ...state.entities[idCompanyCommunicationRecipient],
            users: users.concat(idUsers.filter(id => users.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationRecipientActions.deleteManyUserSuccess,
    (state: CompanyCommunicationRecipientState, { idUsers, idCompanyCommunicationRecipients }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationRecipients.reduce((entities, idCompanyCommunicationRecipient) => {
            if (!state.entities[idCompanyCommunicationRecipient]?.users) {
              return entities;
            }
            entities[idCompanyCommunicationRecipient] = {
              ...state.entities[idCompanyCommunicationRecipient],
              users: (state.entities[idCompanyCommunicationRecipient]?.users as number[])?.filter(
                (idUser: number) => !idUsers.some((id: number) => id === idUser)
              )
            } as CompanyCommunicationRecipientEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationRecipientEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationRecipientActions.addManyCompanyCommunicationRecipientUserSuccess,
    (
      state: CompanyCommunicationRecipientState,
      { idCompanyCommunicationRecipient, idCompanyCommunicationRecipientUsers }
    ) => {
      if (!state.entities[idCompanyCommunicationRecipient]) {
        return state;
      }
      const companyCommunicationRecipientUsers =
        (state.entities[idCompanyCommunicationRecipient]?.companyCommunicationRecipientUsers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationRecipient]: {
            ...state.entities[idCompanyCommunicationRecipient],
            companyCommunicationRecipientUsers: companyCommunicationRecipientUsers.concat(
              idCompanyCommunicationRecipientUsers.filter(id => companyCommunicationRecipientUsers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationRecipientActions.deleteManyCompanyCommunicationRecipientUserSuccess,
    (
      state: CompanyCommunicationRecipientState,
      { idCompanyCommunicationRecipientUsers, idCompanyCommunicationRecipients }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationRecipients.reduce((entities, idCompanyCommunicationRecipient) => {
            if (!state.entities[idCompanyCommunicationRecipient]?.companyCommunicationRecipientUsers) {
              return entities;
            }
            entities[idCompanyCommunicationRecipient] = {
              ...state.entities[idCompanyCommunicationRecipient],
              companyCommunicationRecipientUsers: (
                state.entities[idCompanyCommunicationRecipient]?.companyCommunicationRecipientUsers as number[]
              )?.filter(
                (idCompanyCommunicationRecipientUser: number) =>
                  !idCompanyCommunicationRecipientUsers.some((id: number) => id === idCompanyCommunicationRecipientUser)
              )
            } as CompanyCommunicationRecipientEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationRecipientEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationRecipientActions.addManyOrganizationMetierSuccess,
    (state: CompanyCommunicationRecipientState, { idCompanyCommunicationRecipient, idOrganizationMetiers }) => {
      if (!state.entities[idCompanyCommunicationRecipient]) {
        return state;
      }
      const organizationMetiers =
        (state.entities[idCompanyCommunicationRecipient]?.organizationMetiers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationRecipient]: {
            ...state.entities[idCompanyCommunicationRecipient],
            organizationMetiers: organizationMetiers.concat(
              idOrganizationMetiers.filter(id => organizationMetiers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationRecipientActions.deleteManyOrganizationMetierSuccess,
    (state: CompanyCommunicationRecipientState, { idOrganizationMetiers, idCompanyCommunicationRecipients }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationRecipients.reduce((entities, idCompanyCommunicationRecipient) => {
            if (!state.entities[idCompanyCommunicationRecipient]?.organizationMetiers) {
              return entities;
            }
            entities[idCompanyCommunicationRecipient] = {
              ...state.entities[idCompanyCommunicationRecipient],
              organizationMetiers: (
                state.entities[idCompanyCommunicationRecipient]?.organizationMetiers as number[]
              )?.filter(
                (idOrganizationMetier: number) =>
                  !idOrganizationMetiers.some((id: number) => id === idOrganizationMetier)
              )
            } as CompanyCommunicationRecipientEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationRecipientEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationRecipientActions.addManyCompanyCommunicationRecipientMetierSuccess,
    (
      state: CompanyCommunicationRecipientState,
      { idCompanyCommunicationRecipient, idCompanyCommunicationRecipientMetiers }
    ) => {
      if (!state.entities[idCompanyCommunicationRecipient]) {
        return state;
      }
      const companyCommunicationRecipientMetiers =
        (state.entities[idCompanyCommunicationRecipient]?.companyCommunicationRecipientMetiers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationRecipient]: {
            ...state.entities[idCompanyCommunicationRecipient],
            companyCommunicationRecipientMetiers: companyCommunicationRecipientMetiers.concat(
              idCompanyCommunicationRecipientMetiers.filter(id => companyCommunicationRecipientMetiers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationRecipientActions.deleteManyCompanyCommunicationRecipientMetierSuccess,
    (
      state: CompanyCommunicationRecipientState,
      { idCompanyCommunicationRecipientMetiers, idCompanyCommunicationRecipients }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationRecipients.reduce((entities, idCompanyCommunicationRecipient) => {
            if (!state.entities[idCompanyCommunicationRecipient]?.companyCommunicationRecipientMetiers) {
              return entities;
            }
            entities[idCompanyCommunicationRecipient] = {
              ...state.entities[idCompanyCommunicationRecipient],
              companyCommunicationRecipientMetiers: (
                state.entities[idCompanyCommunicationRecipient]?.companyCommunicationRecipientMetiers as number[]
              )?.filter(
                (idCompanyCommunicationRecipientMetier: number) =>
                  !idCompanyCommunicationRecipientMetiers.some(
                    (id: number) => id === idCompanyCommunicationRecipientMetier
                  )
              )
            } as CompanyCommunicationRecipientEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationRecipientEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyCommunicationRecipientState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyCommunicationRecipientState {
  return { ...state, isLoaded, isLoading };
}
