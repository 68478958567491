import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResidenceTodo, ResidenceTodoEntityState } from '@_model/interfaces/residence-todo.model';
import {
  OrganizationResidenceTodo,
  OrganizationResidenceTodoEntityState
} from '@_model/interfaces/organization-residence-todo.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, residenceTodoFeatureKey, ResidenceTodoState } from './residence-todo.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residenceTodoRelations: string[] = ['organizationResidenceTodos', 'residences'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidenceTodoState = createFeatureSelector<ResidenceTodoState>(residenceTodoFeatureKey);

export const selectIsLoadedResidenceTodo = createSelector(
  selectResidenceTodoState,
  (state: ResidenceTodoState) => state.isLoaded
);

export const selectIsLoadingResidenceTodo = createSelector(
  selectResidenceTodoState,
  (state: ResidenceTodoState) => state.isLoading
);

export const selectIsReadyResidenceTodo = createSelector(
  selectResidenceTodoState,
  (state: ResidenceTodoState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidenceTodo = createSelector(
  selectResidenceTodoState,
  (state: ResidenceTodoState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidenceTodoModel: SelectorModel = {
  name: 'residenceTodos',
  getSelector: selectAllResidenceTodosDictionary,
  isReady: selectIsReadyResidenceTodo
};

export const selectResidenceTodosEntities = createSelector(selectResidenceTodoState, selectEntities);

export const selectResidenceTodosArray = createSelector(selectResidenceTodoState, selectAll);

export const selectIdResidenceTodosActive = createSelector(
  selectResidenceTodoState,
  (state: ResidenceTodoState) => state.actives
);

const residenceTodosInObject = (residenceTodos: Dictionary<ResidenceTodoEntityState>) => ({ residenceTodos });

const selectResidenceTodosEntitiesDictionary = createSelector(selectResidenceTodosEntities, residenceTodosInObject);

const selectAllResidenceTodosObject = createSelector(selectResidenceTodosEntities, residenceTodos => {
  return hydrateAll({ residenceTodos });
});

const selectOneResidenceTodoDictionary = (idResidenceTodo: number) =>
  createSelector(selectResidenceTodosEntities, residenceTodos => {
    return { residenceTodos: { [idResidenceTodo]: residenceTodos[idResidenceTodo] } };
  });

const selectOneResidenceTodoDictionaryWithoutChild = (idResidenceTodo: number) =>
  createSelector(selectResidenceTodosEntities, residenceTodos => {
    return { residenceTodo: residenceTodos[idResidenceTodo] };
  });

const selectActiveResidenceTodosEntities = createSelector(
  selectIdResidenceTodosActive,
  selectResidenceTodosEntities,
  (actives: number[], residenceTodos: Dictionary<ResidenceTodoEntityState>) =>
    getResidenceTodosFromActives(actives, residenceTodos)
);

function getResidenceTodosFromActives(
  actives: number[],
  residenceTodos: Dictionary<ResidenceTodoEntityState>
): Dictionary<ResidenceTodoEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residenceTodos[idActive]) {
      acc[idActive] = residenceTodos[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidenceTodoEntityState>);
}

const selectAllResidenceTodosSelectors: Dictionary<Selector> = {};
export function selectAllResidenceTodos(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidenceTodo>(
      schema,
      selectAllResidenceTodosSelectors,
      selectResidenceTodosEntitiesDictionary,
      getRelationSelectors,
      residenceTodoRelations,
      hydrateAll,
      'residenceTodo'
    );
  } else {
    return selectAllResidenceTodosObject;
  }
}

export function selectAllResidenceTodosDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residenceTodos'
): Selector {
  return createSelector(selectAllResidenceTodos(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidenceTodoEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residenceTodos.length; i++) {
      res[customKey][result.residenceTodos[i].idResidenceTodo] = result.residenceTodos[i];
    }
    return res;
  });
}

export function selectOneResidenceTodo(schema: SelectSchema = {}, idResidenceTodo: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidenceTodoDictionary(idResidenceTodo)];
    selectors.push(...getRelationSelectors(schema, residenceTodoRelations, 'residenceTodo'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidenceTodoDictionaryWithoutChild(idResidenceTodo);
  }
}

export function selectActiveResidenceTodos(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidenceTodosEntities, residenceTodos => ({ residenceTodos }))
  ];
  selectors.push(...getRelationSelectors(schema, residenceTodoRelations, 'residenceTodo'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residenceTodos: Dictionary<ResidenceTodoEntityState>;
  organizationResidenceTodos?: Dictionary<OrganizationResidenceTodoEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { residenceTodos: (ResidenceTodo | null)[] } {
  const { residenceTodos, organizationResidenceTodos, residences } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    residenceTodos: Object.keys(residenceTodos).map(idResidenceTodo =>
      hydrate(residenceTodos[idResidenceTodo] as ResidenceTodoEntityState, organizationResidenceTodos, residences)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { residenceTodo: ResidenceTodoEntityState | null } {
  const { residenceTodos, organizationResidenceTodos, residences } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const residenceTodo = Object.values(residenceTodos)[0];
  return { residenceTodo: hydrate(residenceTodo as ResidenceTodoEntityState, organizationResidenceTodos, residences) };
}

function hydrate(
  residenceTodo: ResidenceTodoEntityState,
  organizationResidenceTodoEntities?: Dictionary<OrganizationResidenceTodoEntityState>,
  residenceEntities?: Dictionary<ResidenceEntityState>
): ResidenceTodo | null {
  if (!residenceTodo) {
    return null;
  }

  const residenceTodoHydrated: ResidenceTodoEntityState = { ...residenceTodo };
  if (organizationResidenceTodoEntities) {
    residenceTodoHydrated.organizationResidenceTodo = organizationResidenceTodoEntities[
      residenceTodo.organizationResidenceTodo as number
    ] as OrganizationResidenceTodo;
  } else {
    delete residenceTodoHydrated.organizationResidenceTodo;
  }
  if (residenceEntities) {
    residenceTodoHydrated.residence = residenceEntities[residenceTodo.residence as number] as Residence;
  } else {
    delete residenceTodoHydrated.residence;
  }

  return residenceTodoHydrated as ResidenceTodo;
}
