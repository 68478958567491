import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { City, CityEntityState } from '@_model/interfaces/city.model';
import { CityApiService } from '@_services/api/city-api.service';
import * as cityActions from '@_store/city/city.actions';
import { getActionsToNormalizeCity } from '@_config/store/normalization.generated';
import { selectCityState } from './city-generated.selectors';
import * as companyActions from '@_store/company/company.actions';
import * as prospectBuyingWishActions from '@_store/prospect-buying-wish/prospect-buying-wish.actions';
import { ProspectBuyingWish } from '@_model/interfaces/prospect-buying-wish.model';
import * as residenceActions from '@_store/residence/residence.actions';
import { Residence } from '@_model/interfaces/residence.model';

export interface CityRelationsIds {
  prospectBuyingWishs?: number | number[];
  residences?: number | number[];
  company?: number;
}

export function getDefaultAddCityActions(city: CityEntityState, ids?: CityRelationsIds): Action[] {
  const actions: Action[] = [cityActions.normalizeManyCitiesAfterUpsert({ cities: [city] })];

  if (ids?.company) {
    actions.push(
      companyActions.addManyCitySuccess({
        idCompany: ids.company,
        idCities: [city.idCity]
      })
    );
    actions.push(
      cityActions.addCompanySuccess({
        idCity: city.idCity,
        idCompany: ids.company
      })
    );
  }

  if (ids?.prospectBuyingWishs) {
    if (!Array.isArray(ids.prospectBuyingWishs)) {
      actions.push(
        prospectBuyingWishActions.upsertOneProspectBuyingWish({
          prospectBuyingWish: {
            idCity: city.idCity,
            idProspectBuyingWish: ids.prospectBuyingWishs as number
          } as ProspectBuyingWish & any
        })
      );
      actions.push(
        cityActions.addManyProspectBuyingWishSuccess({
          idCity: city.idCity,
          idProspectBuyingWishs: [ids.prospectBuyingWishs as number]
        })
      );
    } else {
      actions.push(
        prospectBuyingWishActions.upsertManyProspectBuyingWishs({
          prospectBuyingWishs: (ids.prospectBuyingWishs as number[]).map((idProspectBuyingWish: number) => ({
            idCity: city.idCity,
            idProspectBuyingWish
          })) as ProspectBuyingWish[] & any[]
        })
      );
      actions.push(
        cityActions.addManyProspectBuyingWishSuccess({
          idCity: city.idCity,
          idProspectBuyingWishs: ids.prospectBuyingWishs as number[]
        })
      );
    }
  }

  if (ids?.residences) {
    if (!Array.isArray(ids.residences)) {
      actions.push(
        residenceActions.upsertOneResidence({
          residence: {
            idCity: city.idCity,
            idResidence: ids.residences as number
          } as Residence
        })
      );
      actions.push(
        cityActions.addManyResidenceSuccess({
          idCity: city.idCity,
          idResidences: [ids.residences as number]
        })
      );
    } else {
      actions.push(
        residenceActions.upsertManyResidences({
          residences: (ids.residences as number[]).map((idResidence: number) => ({
            idCity: city.idCity,
            idResidence
          })) as Residence[]
        })
      );
      actions.push(
        cityActions.addManyResidenceSuccess({
          idCity: city.idCity,
          idResidences: ids.residences as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCityActions(city: CityEntityState): Action[] {
  const actions: Action[] = [cityActions.deleteOneCitySuccess({ idCity: city.idCity })];

  if (city.company) {
    actions.push(
      companyActions.deleteManyCitySuccess({
        idCities: [city.idCity],
        idCompanies: [city.company as number]
      })
    );
  }

  if (city.prospectBuyingWishs) {
    actions.push(
      prospectBuyingWishActions.deleteManyCitySuccess({
        idCities: [city.idCity],
        idProspectBuyingWishs: city.prospectBuyingWishs as number[]
      })
    );
  }

  if (city.residences) {
    actions.push(
      residenceActions.deleteManyCitySuccess({
        idCities: [city.idCity],
        idResidences: city.residences as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCityEffects {
  constructor(
    protected actions$: Actions,
    protected cityApiService: CityApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cityActions.getManyCities),
      switchMap(({ params }) =>
        this.cityApiService.getCities(params).pipe(
          map((cities: City[]) => {
            return cityActions.normalizeManyCitiesAfterUpsert({ cities });
          }),
          catchError(error => of(cityActions.citiesFailure({ error })))
        )
      )
    );
  });

  getOneCity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cityActions.getOneCity),
      switchMap(idCity =>
        this.cityApiService.getCity(idCity).pipe(
          map((city: City) => {
            return cityActions.normalizeManyCitiesAfterUpsert({ cities: [city] });
          }),
          catchError(error => of(cityActions.citiesFailure({ error })))
        )
      )
    );
  });

  upsertOneCity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cityActions.upsertOneCity),
      concatMap(({ city, ids }: { city: Partial<City>; ids?: CityRelationsIds }) => {
        if (city.idCity) {
          return this.cityApiService.updateCity(city).pipe(
            map((cityReturned: City) => {
              return cityActions.normalizeManyCitiesAfterUpsert({ cities: [cityReturned] });
            }),
            catchError(error => of(cityActions.citiesFailure({ error })))
          );
        } else {
          return this.cityApiService.addCity(city).pipe(
            mergeMap((cityReturned: City) => getDefaultAddCityActions(cityReturned, ids)),
            catchError(error => of(cityActions.citiesFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneCity$ = createEffect(() => {
    const selectCityState$ = this.store$.select(selectCityState);
    return this.actions$.pipe(
      ofType(cityActions.deleteOneCity),
      withLatestFrom(selectCityState$),
      concatMap(([{ idCity }, state]) =>
        this.cityApiService.deleteCity(idCity).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCityActions(state.entities[idCity] as CityEntityState),
              cityActions.deleteOneCity.type
            )
          ]),
          catchError(error => of(cityActions.citiesFailure({ error })))
        )
      )
    );
  });

  normalizeManyCitiesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(cityActions.normalizeManyCitiesAfterUpsert),
      concatMap(({ cities }) => {
        const actions: Action[] = getActionsToNormalizeCity(cities, StoreActionType.upsert);
        return [getMultiAction(actions, '[City] Normalization After Upsert Success')];
      })
    );
  });
}
