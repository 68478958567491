import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ResidenceShare, ResidenceShareEntityState } from '@_model/interfaces/residence-share.model';
import { ResidenceShareRelationsIds } from './residence-share-generated.effects';

export const getOneResidenceShare = createAction(
  '[ResidenceShare] Get One ResidenceShare',
  props<{ idResidenceShare: number; params?: any }>()
);

export const getManyResidenceShares = createAction(
  '[ResidenceShare] Get Many ResidenceShares',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ResidenceShare] Add Many Actives ResidenceShare',
  props<{ idResidenceShares: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidenceShare] Delete One Active ResidenceShare',
  props<{ idResidenceShare: number }>()
);

export const clearActive = createAction('[ResidenceShare] Clear Active ResidenceShare');

export const upsertOneResidenceShare = createAction(
  '[ResidenceShare] Upsert One ResidenceShare',
  props<{
    residenceShare: Partial<ResidenceShare>;
    ids?: ResidenceShareRelationsIds;
  }>()
);

export const upsertManyResidenceShares = createAction(
  '[ResidenceShare] Upsert Many ResidenceShares',
  props<{
    residenceShares: Partial<ResidenceShare>[];
    ids?: ResidenceShareRelationsIds;
  }>()
);

export const upsertManyResidenceSharesSuccess = createAction(
  '[ResidenceShare] Create Many ResidenceShares Success',
  props<{ residenceShares: ResidenceShareEntityState[] }>()
);

export const deleteOneResidenceShare = createAction(
  '[ResidenceShare] Delete One ResidenceShare',
  props<{ idResidenceShare: number }>()
);

export const deleteOneResidenceShareSuccess = createAction(
  '[ResidenceShare] Delete One ResidenceShare Success',
  props<{ idResidenceShare: number }>()
);

export const normalizeManyResidenceSharesAfterUpsert = createAction(
  '[ResidenceShare] Normalize Many ResidenceShares After Upsert',
  props<{ residenceShares: ResidenceShareEntityState[] }>()
);

export const residenceSharesFailure = createAction(
  '[ResidenceShare] ResidenceShares Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidenceShare] Clear ResidenceShares');

export const addManyStratalotRcpSuccess = createAction(
  '[ResidenceShare] Add Many stratalot-rcp',
  props<{ idResidenceShare: number; idStratalotRcps: number[] }>()
);

export const deleteManyStratalotRcpSuccess = createAction(
  '[ResidenceShare] Delete Many StratalotRcps',
  props<{ idStratalotRcps: number[]; idResidenceShares: number[] }>()
);

export const addResidenceSuccess = createAction(
  '[ResidenceShare] Add Residence',
  props<{ idResidenceShare: number; idResidence: number }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[ResidenceShare] Delete Many Residence',
  props<{ idResidences: number[]; idResidenceShares: number[] }>()
);
