import { Action, createReducer, on } from '@ngrx/store';
import * as leadActions from '@_store/lead/lead.actions';
import { leadReducersGeneratedFunctions } from './lead-generated.reducer';
import { initialState, LeadState } from './lead.state';

const leadReducersFunctions = [
  ...leadReducersGeneratedFunctions,
  on(leadActions.deleteManyLeadsByProspectSuccess, (state: LeadState, { idProspect }) => {
    return {
      ...state,
      entities: Object.keys(state.entities)
        .filter((idLead: string) => state.entities[idLead].prospect !== idProspect)
        .map((idLead: string) => {
          return { ...state.entities[idLead] };
        })
        .reduce((acc, curr) => {
          acc[curr.idLead] = curr;
          return acc;
        }, {})
    };
  })
];

const leadReducer = createReducer(initialState, ...leadReducersFunctions);

export function reducer(state: LeadState | undefined, action: Action) {
  return leadReducer(state, action);
}
