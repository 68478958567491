import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationForecastRateActions from './organization-forecast-rate.actions';
import { adapter, initialState, OrganizationForecastRateState } from './organization-forecast-rate.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationForecastRateEntityState } from '@_model/interfaces/organization-forecast-rate.model';

export const organizationForecastRateReducersGeneratedFunctions: ReducerTypes<
  OrganizationForecastRateState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationForecastRateActions.getOneOrganizationForecastRate, (state: OrganizationForecastRateState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationForecastRateActions.getManyOrganizationForecastRates, (state: OrganizationForecastRateState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationForecastRateActions.upsertOneOrganizationForecastRate, (state: OrganizationForecastRateState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationForecastRateActions.upsertManyOrganizationForecastRatesSuccess,
    (state: OrganizationForecastRateState, { organizationForecastRates }) =>
      adapter.upsertMany(organizationForecastRates, setLoadingsState(state, false))
  ),
  on(OrganizationForecastRateActions.deleteOneOrganizationForecastRate, (state: OrganizationForecastRateState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationForecastRateActions.deleteOneOrganizationForecastRateSuccess,
    (state: OrganizationForecastRateState, { idOrganizationForecastRate }) =>
      adapter.removeOne(idOrganizationForecastRate, setLoadingsState(state, false))
  ),
  on(OrganizationForecastRateActions.clearActive, (state: OrganizationForecastRateState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationForecastRateActions.addManyActives,
    (state: OrganizationForecastRateState, { idOrganizationForecastRates }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationForecastRates)
    })
  ),
  on(
    OrganizationForecastRateActions.deleteOneActive,
    (state: OrganizationForecastRateState, { idOrganizationForecastRate }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationForecastRate)
    })
  ),

  on(OrganizationForecastRateActions.clearStore, () => initialState),

  on(
    OrganizationForecastRateActions.addManyResidenceSuccess,
    (state: OrganizationForecastRateState, { idOrganizationForecastRate, idResidences }) => {
      if (!state.entities[idOrganizationForecastRate]) {
        return state;
      }
      const residences = (state.entities[idOrganizationForecastRate]?.residences as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationForecastRate]: {
            ...state.entities[idOrganizationForecastRate],
            residences: residences.concat(idResidences.filter(id => residences.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationForecastRateActions.deleteManyResidenceSuccess,
    (state: OrganizationForecastRateState, { idResidences, idOrganizationForecastRates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationForecastRates.reduce((entities, idOrganizationForecastRate) => {
            if (!state.entities[idOrganizationForecastRate]?.residences) {
              return entities;
            }
            entities[idOrganizationForecastRate] = {
              ...state.entities[idOrganizationForecastRate],
              residences: (state.entities[idOrganizationForecastRate]?.residences as number[])?.filter(
                (idResidence: number) => !idResidences.some((id: number) => id === idResidence)
              )
            } as OrganizationForecastRateEntityState;
            return entities;
          }, {} as Dictionary<OrganizationForecastRateEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationForecastRateActions.addOrganizationSuccess,
    (state: OrganizationForecastRateState, { idOrganizationForecastRate, idOrganization }) => {
      if (!state.entities[idOrganizationForecastRate]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationForecastRate]: {
            ...state.entities[idOrganizationForecastRate],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationForecastRateActions.deleteManyOrganizationSuccess,
    (state: OrganizationForecastRateState, { idOrganizations, idOrganizationForecastRates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationForecastRates.reduce((entities, idOrganizationForecastRate) => {
            if (!state.entities[idOrganizationForecastRate]?.organization) {
              return entities;
            }
            entities[idOrganizationForecastRate] = {
              ...state.entities[idOrganizationForecastRate],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationForecastRate]?.organization
              )
                ? undefined
                : state.entities[idOrganizationForecastRate]?.organization
            } as OrganizationForecastRateEntityState;
            return entities;
          }, {} as Dictionary<OrganizationForecastRateEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationForecastRateState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationForecastRateState {
  return { ...state, isLoaded, isLoading };
}
