import { StratalotAssociationState, initialState, adapter } from './stratalot-association.state';
import { Action, createReducer, on } from '@ngrx/store';
import {
  setLoadingsState,
  stratalotAssociationReducersGeneratedFunctions
} from './stratalot-association-generated.reducer';
import * as StratalotAssociationActions from '@_store/stratalot-association/stratalot-association.actions';

const stratalotAssociationReducersFunctions = [
  ...stratalotAssociationReducersGeneratedFunctions,
  // TODO A supprimer lorsque seront MAJ pour gérer le deleteMany
  on(
    StratalotAssociationActions.deleteManyStratalotAssociationSuccess,
    (state: StratalotAssociationState, { idStratalotAssociations }) =>
      adapter.removeMany(idStratalotAssociations, setLoadingsState(state, false))
  )
];

const stratalotAssociationReducer = createReducer(initialState, ...stratalotAssociationReducersFunctions);

export function reducer(state: StratalotAssociationState | undefined, action: Action) {
  return stratalotAssociationReducer(state, action);
}
