import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Residence, ResidenceWithSoldStratalots } from '@_model/interfaces/residence.model';
import { DashBoardApiService } from '@_services/api/dashboard-api.service';
import { ResidenceApiService } from '@_services/api/residence-api.service';
import * as companyActions from '@_store/company/company.actions';
import * as diffusionVisualActions from '@_store/diffusion-visual/diffusion-visual.actions';
import { AppState } from '@_store/index.reducers';
import * as residenceContactActions from '@_store/residence-contact/residence-contact.actions';
import * as residenceShareActions from '@_store/residence-share/residence-share.actions';
import * as residenceTodoActions from '@_store/residence-todo/residence-todo.actions';
import * as residenceWorkActions from '@_store/residence-work/residence-work.actions';
import * as residenceActions from '@_store/residence/residence.actions';
import * as residencePriceGridActions from '@_store/residence-price-grid/residence-price-grid.actions';
import * as stratalotRcpActions from '@_store/stratalot-rcp/stratalot-rcp.actions';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import * as userActions from '@_store/user/user.actions';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { GeneratedResidenceEffects } from './residence-generated.effects';
import { selectResidenceState } from './residence-generated.selectors';

@Injectable()
export class ResidenceEffects extends GeneratedResidenceEffects {
  constructor(
    actions$: Actions,
    residenceApiService: ResidenceApiService,
    store$: Store<AppState>,
    private dashboardApiService: DashBoardApiService
  ) {
    super(actions$, residenceApiService, store$);
  }

  public getResidencesByCompanies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceActions.getResidencesByCompanies),
      concatMap(({ params }) =>
        this.residenceApiService.getResidencesByCompanies(params).pipe(
          map((residences: Residence[]) => {
            return residenceActions.normalizeManyResidencesAfterUpsert({ residences });
          }),
          catchError(error => of(residenceActions.residencesFailure({ error })))
        )
      )
    );
  });

  public getTinyResidenceByCompanies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceActions.getTinyResidenceByCompanies),
      concatMap(({ params }) =>
        this.residenceApiService.getTinyResidenceByCompanies(params).pipe(
          map((residences: Residence[]) => {
            return residenceActions.normalizeManyResidencesAfterUpsert({ residences });
          }),
          catchError(error => of(residenceActions.residencesFailure({ error })))
        )
      )
    );
  });

  public getForecastByResidence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceActions.getForecastByResidence),
      concatMap(({ idResidence }) =>
        this.residenceApiService.getForecastByResidence(idResidence).pipe(
          map((residences: ResidenceWithSoldStratalots[]) => {
            return residenceActions.normalizeManyResidencesAfterUpsert({ residences });
          }),
          catchError(error => of(residenceActions.residencesFailure({ error })))
        )
      )
    );
  });

  public getDashboardResidenceForecast$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceActions.getDashboardResidenceForecast),
      concatMap(params =>
        this.dashboardApiService.getDataResidenceForecast(params).pipe(
          map((residences: ResidenceWithSoldStratalots[]) => {
            return residenceActions.normalizeManyResidencesAfterUpsert({ residences });
          }),
          catchError(error => of(residenceActions.residencesFailure({ error })))
        )
      )
    );
  });

  upsertOneResidenceForecast$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceActions.upsertOneResidenceForecast),
      concatMap(({ residence }: { residence: Partial<Residence> }) => {
        return this.residenceApiService.updateResidenceForecast(residence).pipe(
          map((residenceReturned: Residence) => {
            return residenceActions.normalizeManyResidencesAfterUpsert({ residences: [residenceReturned] });
          }),
          catchError(error => of(residenceActions.residencesFailure({ error })))
        );
      })
    );
  });

  public deleteResidencesByCompany$ = createEffect(() => {
    const selectResidenceState$ = this.store$.select(selectResidenceState);
    return this.actions$.pipe(
      ofType(residenceActions.deleteManyResidencesByCompany),
      withLatestFrom(selectResidenceState$),
      map(([{ idCompany }, state]) => [{ idCompany }, { entities: state.entities }]),
      concatMap(([{ idCompany }, { entities }]) =>
        this.residenceApiService.deleteResidenceByCompany(idCompany).pipe(
          mergeMap(idResidences => {
            const residences = idResidences.map(idResidence => entities[idResidence]);

            const idUsers = [...new Set([residences.map(residence => residence.responsable)].flat(2))] as number[];
            const idDiffusionVisuals = [
              ...new Set([residences.map(residence => residence.diffusionVisuals)].flat(2))
            ] as number[];
            const idResidenceTodos = [
              ...new Set([residences.map(residence => residence.residenceTodos)].flat(2))
            ] as number[];
            const idResidenceWorks = [
              ...new Set([residences.map(residence => residence.residenceWorks)].flat(2))
            ] as number[];
            const idResidenceContacts = [
              ...new Set([residences.map(residence => residence.residenceContacts)].flat(2))
            ] as number[];
            const idStratalots = [...new Set([residences.map(residence => residence.stratalots)].flat(2))] as number[];
            const idResidencePriceGrids = [
              ...new Set([residences.map(residence => residence.residencePriceGrids)].flat(2))
            ] as number[];
            const idResidenceShares = [
              ...new Set([residences.map(residence => residence.residenceShares)].flat(2))
            ] as number[];
            const idStratalotRcps = [
              ...new Set([residences.map(residence => residence.stratalotRcps)].flat(2))
            ] as number[];

            const actions: Action[] = [
              residenceActions.deleteManyResidencesSuccess({ idResidences }),
              companyActions.deleteManyResidenceSuccess({ idResidences: idResidences, idCompanies: [idCompany] })
            ];
            if (idUsers.length) {
              actions.push(userActions.deleteManyResidenceSuccess({ idResidence: idResidences, idUsers }));
            }
            if (idDiffusionVisuals.length) {
              actions.push(
                diffusionVisualActions.deleteManyResidenceSuccess({ idResidences: idResidences, idDiffusionVisuals })
              );
            }
            if (idResidenceTodos.length) {
              actions.push(
                residenceTodoActions.deleteManyResidenceSuccess({ idResidences: idResidences, idResidenceTodos })
              );
            }
            if (idResidenceWorks.length) {
              actions.push(
                residenceWorkActions.deleteManyResidenceSuccess({ idResidences: idResidences, idResidenceWorks })
              );
            }
            if (idResidenceContacts.length) {
              actions.push(
                residenceContactActions.deleteManyResidenceSuccess({ idResidences: idResidences, idResidenceContacts })
              );
            }
            if (idStratalots.length) {
              actions.push(stratalotActions.deleteManyResidenceSuccess({ idResidences: idResidences, idStratalots }));
            }
            if (idResidencePriceGrids.length) {
              actions.push(
                residencePriceGridActions.deleteManyResidenceSuccess({
                  idResidences: idResidences,
                  idResidencePriceGrids
                })
              );
            }
            if (idResidenceShares.length) {
              actions.push(
                residenceShareActions.deleteManyResidenceSuccess({ idResidences: idResidences, idResidenceShares })
              );
            }
            if (idStratalotRcps.length) {
              actions.push(
                stratalotRcpActions.deleteManyResidenceSuccess({ idResidences: idResidences, idStratalotRcps })
              );
            }
            return actions;
          }),
          catchError(error => of(residenceActions.residencesFailure({ error })))
        )
      )
    );
  });

  public getResidencesForSales$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceActions.getResidencesForSales),
      concatMap(({ params }) =>
        this.residenceApiService.getResidencesForSales(params).pipe(
          map((residences: Residence[]) => {
            return residenceActions.normalizeManyResidencesAfterUpsert({ residences });
          }),
          catchError(error => of(residenceActions.residencesFailure({ error })))
        )
      )
    );
  });

  public getResidencesOccupants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceActions.getResidencesOccupants),
      concatMap(({ params }) =>
        this.residenceApiService.getResidencesOccupants(params).pipe(
          map((residences: Residence[]) => {
            return residenceActions.normalizeManyResidencesAfterUpsert({ residences });
          }),
          catchError(error => of(residenceActions.residencesFailure({ error })))
        )
      )
    );
  });

  public getResidencesMontage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceActions.getResidencesMontage),
      concatMap(({ params }) =>
        this.residenceApiService.getResidencesMontage(params).pipe(
          map((residences: Residence[]) => {
            return residenceActions.normalizeManyResidencesAfterUpsert({ residences });
          }),
          catchError(error => of(residenceActions.residencesFailure({ error })))
        )
      )
    );
  });

  public activeResidenceFromCampaign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceActions.activateResidenceFromCampaign),
      concatMap(({ idCompany, envoiUbiflowDateDebut, envoiUbiflowDateFin, idResidence }) => {
        return this.residenceApiService
          .activateResidenceFromCampaign({ idCompany, envoiUbiflowDateDebut, envoiUbiflowDateFin, idResidence })
          .pipe(
            map((residenceReturned: Residence) => {
              return residenceActions.normalizeManyResidencesAfterUpsert({ residences: [residenceReturned] });
            }),
            catchError(error => of(residenceActions.residencesFailure({ error })))
          );
      })
    );
  });
}
