import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationStratalotAvancement } from '@_model/interfaces/organization-stratalot-avancement.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationStratalotAvancementApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationStratalotAvancements(params?: any): Observable<OrganizationStratalotAvancement[]> {
    return this.repo.getData<OrganizationStratalotAvancement[]>('organization-stratalot-avancement', params);
  }

  public getOrganizationStratalotAvancement(params: {
    idOrganizationStratalotAvancement: number;
    params?: any;
  }): Observable<OrganizationStratalotAvancement> {
    return this.repo.getData<OrganizationStratalotAvancement>(
      'organization-stratalot-avancement/' + params.idOrganizationStratalotAvancement,
      params.params
    );
  }

  public addOrganizationStratalotAvancement(
    organizationStratalotAvancement: Partial<OrganizationStratalotAvancement>
  ): Observable<OrganizationStratalotAvancement> {
    return this.repo.create<OrganizationStratalotAvancement>(
      'organization-stratalot-avancement',
      organizationStratalotAvancement
    );
  }

  public updateOrganizationStratalotAvancement(
    organizationStratalotAvancement: Partial<OrganizationStratalotAvancement>
  ): Observable<OrganizationStratalotAvancement> {
    return this.repo.update('organization-stratalot-avancement', organizationStratalotAvancement);
  }

  public deleteOrganizationStratalotAvancement(idOrganizationStratalotAvancement: number): Observable<number> {
    return this.repo.delete('organization-stratalot-avancement/' + +idOrganizationStratalotAvancement);
  }
}
