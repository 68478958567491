import { CalculPrixMinore } from '@_shared/interfaces/calcul-prix-minore';
import {
  LeadTodoByStepCommertialisationAndValideOffer,
  LeadTodoByStepSaleInProggess
} from '@_shared/interfaces/lead-todo-by-type.interface';
import { BuyingWish } from '@_shared/models/enums/buying-wish.enum';
import { LeadStatusEnum } from '@_shared/models/enums/lead-status.enum';
import { LeadAvancementEnum } from '@_shared/models/enums/lead-statut-avancement.enum';
import { OrganizationLeadTodoType } from '@_shared/models/enums/organization-lead-todo-type.enum';
import { Profil, ProfilProspectOccupant } from '@_shared/models/enums/profil-enum';
import { Statut } from '@_shared/models/enums/statut.enum';
import { StepName } from '@_shared/models/enums/step-name.enum';
import { LeadStratalot } from '@_shared/models/interfaces/lead-stratalot.model';
import { LeadTodo } from '@_shared/models/interfaces/lead-todo.model';
import { Lead } from '@_shared/models/interfaces/lead.model';
import { ProspectEvent } from '@_shared/models/interfaces/prospect-event.model';
import { Stratalot } from '@_shared/models/interfaces/stratalot.model';
import { sortByMultipleKey } from '@_utils/common-utils/arrays';
import SortUtils from '@_utils/common-utils/custom-sort';
import DatesUtils from '@_utils/common-utils/dates.utils';
import { SaleFamilyCategoryRoutingEnum } from '@_utils/router/path-enum/sale-family-category-routing.enum';
import SaleFamilyCategoryUtils from '@_utils/router/sale-family-category.util';
import LeadAvancementUtils from './lead-avancement.utils';
import ProspectUtils from './prospects.utils';
import StratalotUtils from './stratalot.utils';

namespace LeadUtils {
  export function getLeadNewStatus(currentLeadStatut: LeadStatusEnum): LeadStatusEnum {
    switch (currentLeadStatut) {
      case LeadStatusEnum.toQualify:
        return LeadStatusEnum.disabled;
      case LeadStatusEnum.disabled:
        return LeadStatusEnum.toQualify;
      default:
        throw new Error('Statut de la vente invalide');
    }
  }

  export function isLeadOnStep(avancement: LeadAvancementEnum, expectedStepname: StepName[]): boolean {
    return expectedStepname.includes(LeadAvancementUtils.leadAvancementToStepNameDictionnary[avancement]);
  }

  export function getMainStratalotId(lead: Partial<Lead>): number {
    const result = lead.leadStratalots?.find((leadStratalot: LeadStratalot) => leadStratalot?.isTheMain)?.idStratalot;
    if (!result) {
      return lead.stratalots.find(StratalotUtils.isStratalotTypeIsMain)?.idStratalot || lead.stratalots[0].idStratalot;
    }
    return result;
  }

  export function getMainStratalot(lead: Partial<Lead>): Stratalot {
    return lead?.stratalots?.find(stratalot => stratalot?.idStratalot === getMainStratalotId(lead));
  }

  export function getLastProspectEventOfLead(lead: Lead): ProspectEvent {
    const prospectEvents: ProspectEvent[] = lead?.prospect?.prospectEvents.filter(e => e.idLead === lead.idLead);
    if (!prospectEvents) {
      return null;
    }
    return ProspectUtils.getLastEvent(prospectEvents);
  }

  export function findTodoByOrganizationLeadTodoType(leadTodos: LeadTodo[], type: OrganizationLeadTodoType): LeadTodo {
    return leadTodos?.find((todo: LeadTodo) => todo.organizationLeadTodo?.type === type);
  }

  export function isSomeLeadsHaveActiveStatusAndOfferValue(leads: Lead[]): boolean {
    return leads.some((lead: Lead) => lead.statut === LeadStatusEnum.toQualify && lead.valeurOffreAcquereur);
  }

  export function isSomeLeadsHaveActiveStatus(leads: Lead[]): boolean {
    return leads.some((lead: Lead) => isLeadActive(lead));
  }

  export function isLeadActive(lead: Lead): boolean {
    return lead.statut === LeadStatusEnum.toQualify;
  }

  export function getLeadPriceValue(stratalots: Stratalot[], isOccupant: boolean): number {
    let priceValue = 0;

    stratalots.forEach(stratalot => {
      const stratalotPrice = stratalot.stratalotPrices?.find(sp => sp?.residencePriceGrid?.grilleActive);
      const price = stratalotPrice?.stratalotPriceValues.find(
        spv =>
          (isOccupant ? spv.companyPriceLabel.occupantPrice : spv.companyPriceLabel.prixDefaut) &&
          spv.companyPriceLabel.idSaleCategoryFamily === stratalot.organizationSaleCategory.idSaleCategoryFamily
      );
      priceValue += price?.prixAcquereur || 0;
    });

    return priceValue;
  }

  export function getAllTodoByStepCommertialisationAndValideOffer(
    lead: Lead
  ): LeadTodoByStepCommertialisationAndValideOffer {
    return {
      todoPremierContact: findTodoByOrganizationLeadTodoType(lead.leadTodos, OrganizationLeadTodoType.premierContact),
      todoOffre: findTodoByOrganizationLeadTodoType(lead.leadTodos, OrganizationLeadTodoType.depotOffre),
      todoCommission: findTodoByOrganizationLeadTodoType(
        lead.leadTodos,
        OrganizationLeadTodoType.offreSoumiseValidation
      )
    };
  }

  export function getAllTodoByStepSaleInProgress(lead: Lead): LeadTodoByStepSaleInProggess {
    if (SaleFamilyCategoryUtils.getActiveSaleFamilyCategory() === SaleFamilyCategoryRoutingEnum.ancien) {
      return {
        todoPremierContact: findTodoByOrganizationLeadTodoType(lead.leadTodos, OrganizationLeadTodoType.premierContact),
        todoOffre: findTodoByOrganizationLeadTodoType(lead.leadTodos, OrganizationLeadTodoType.depotOffre),
        todoCompromis: findTodoByOrganizationLeadTodoType(lead.leadTodos, OrganizationLeadTodoType.compromis),
        todoActe: findTodoByOrganizationLeadTodoType(lead.leadTodos, OrganizationLeadTodoType.acte)
      };
    } else if (SaleFamilyCategoryUtils.getActiveSaleFamilyCategory() === SaleFamilyCategoryRoutingEnum.neuf) {
      return {
        todoPremierContact: findTodoByOrganizationLeadTodoType(lead.leadTodos, OrganizationLeadTodoType.premierContact),
        todoOffre: findTodoByOrganizationLeadTodoType(lead.leadTodos, OrganizationLeadTodoType.depotOffre),
        todoContratLocationAccession: findTodoByOrganizationLeadTodoType(
          lead.leadTodos,
          OrganizationLeadTodoType.contratLocationAccession
        ),
        todoReservation: findTodoByOrganizationLeadTodoType(lead.leadTodos, OrganizationLeadTodoType.reservation),
        todoLeveOption: findTodoByOrganizationLeadTodoType(lead.leadTodos, OrganizationLeadTodoType.leveeOption),
        todoActe: findTodoByOrganizationLeadTodoType(lead.leadTodos, OrganizationLeadTodoType.acte),
        todoNonLeveeOption: findTodoByOrganizationLeadTodoType(lead.leadTodos, OrganizationLeadTodoType.nonLeveeOption),
        todoResiliation: findTodoByOrganizationLeadTodoType(lead.leadTodos, OrganizationLeadTodoType.resiliation),
        todoEdls: findTodoByOrganizationLeadTodoType(lead.leadTodos, OrganizationLeadTodoType.edls),
        todoEdle: findTodoByOrganizationLeadTodoType(lead.leadTodos, OrganizationLeadTodoType.edle)
      };
    } else {
      throw new Error('categorie non gérée');
    }
  }

  export function getLastEventOnLeadTodo(lead: Lead): Date {
    return lead.leadTodos
      .filter((leadTodo: LeadTodo) => leadTodo.statut === Statut.done)
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]?.date;
  }
  export function calculatePrixMinore(
    partAcquisitive: number,
    dateEdle: Date,
    dateLeveeOption: Date,
    valeurOffreAcquereur: number
  ): CalculPrixMinore {
    if (
      partAcquisitive &&
      valeurOffreAcquereur &&
      DatesUtils.isValid(dateLeveeOption) &&
      DatesUtils.isValid(dateEdle)
    ) {
      const yearsPassed: number = DatesUtils.diff(dateLeveeOption, dateEdle, 'year');
      const decote = yearsPassed * (valeurOffreAcquereur / 100);
      const { monthsPassed, partAcquisitiveCumulee: partAcquisitiveCumulee } =
        CalculateMonthsPassedSinceEdleAndCumulAcquisitivePart(dateLeveeOption, dateEdle, partAcquisitive);
      const value = +(valeurOffreAcquereur - (decote + partAcquisitiveCumulee)).toFixed(2);

      return {
        value,
        monthsPassed,
        partAcquisitiveCumulee,
        yearsPassed,
        decote
      };
    }
    return null;
  }
  function CalculateMonthsPassedSinceEdleAndCumulAcquisitivePart(
    leveeOption: string | Date,
    edle: string | Date,
    partAcquisitive: number
  ): { monthsPassed: number; partAcquisitiveCumulee: number } {
    const lastDayOfMonthEdle = DatesUtils.daysInMonth(edle);
    const daysProrataEdle = lastDayOfMonthEdle - DatesUtils.daysOfMonth(edle) + 1;
    const prorataEdle = daysProrataEdle / lastDayOfMonthEdle;
    const partAcquisitiveProrataEdle = prorataEdle * partAcquisitive;

    const lastDayOfMonthLeveeOption = DatesUtils.daysInMonth(leveeOption);
    const daysProrataLeveeOption = DatesUtils.daysOfMonth(leveeOption);

    const prorataLeveeOption = daysProrataLeveeOption / lastDayOfMonthLeveeOption;
    const partAcquisitiveProrataLeveeOption = prorataLeveeOption * partAcquisitive;

    const lowerLeveeOption = DatesUtils.subtract(leveeOption, 1, 'month');
    const upperEdle = DatesUtils.goToNextMonthIfExpectedDayPast(DatesUtils.add(edle, 1, 'month'), 1);
    const moisPlein = DatesUtils.diff(lowerLeveeOption, upperEdle, 'month');
    const partAcquisitiveMoisPlein = moisPlein * partAcquisitive;

    return {
      monthsPassed: moisPlein + prorataEdle + prorataLeveeOption,
      partAcquisitiveCumulee: partAcquisitiveMoisPlein + partAcquisitiveProrataEdle + partAcquisitiveProrataLeveeOption
    };
  }

  export function getProspectType(lead: Lead): 'occupant' | 'tiers' {
    if (
      lead?.profilProspectOccupant === ProfilProspectOccupant.occupant ||
      lead?.profilProspectOccupant === ProfilProspectOccupant.ascendantOccupant ||
      lead?.profilProspectOccupant === ProfilProspectOccupant.descendantOccupant ||
      lead?.profilProspectOccupant === ProfilProspectOccupant.conjointOccupant
    ) {
      return 'occupant';
    } else if (lead?.profilProspectOccupant === ProfilProspectOccupant.non || !lead) {
      return 'tiers';
    } else {
      throw new Error('Profil inconnu');
    }
  }

  export function getPrioriteByProfile(lead: Lead): string {
    const priorite1 = [Profil.locataireDuParc, Profil.locataireBailleurSocial, Profil.gardienBailleurSocial];
    const priorite2 = [Profil.personneMoraleDroitPublic];

    if (!lead.prospect.profil) {
      return '';
    }
    if (priorite1.includes(lead.prospect.profil)) {
      if (lead.plafondHlmRetenu > 0 && lead.prospect.rfrValeurN2 <= lead.plafondHlmRetenu) {
        return 'P1.2';
      }
      return 'P1.1';
    } else if (priorite2.includes(lead.prospect.profil)) {
      return 'P2';
    } else {
      return 'P3';
    }
  }

  export function getMostAdvanceLead(leads: Lead[]): Lead {
    return leads
      .filter(l => l.statut === LeadStatusEnum.toQualify)
      .sort(sortByMultipleKey([{ name: 'statutAvancement', cb: SortUtils.sortByLeadAvancement }]))[0];
  }

  export function filterLeadOfStratalotByValidOffreAquereurAndSortByDepotOffreDate(stratalot: Stratalot): Lead[] {
    return stratalot.leads
      .sort(
        (a, b) =>
          new Date(
            LeadUtils.findTodoByOrganizationLeadTodoType(a.leadTodos, OrganizationLeadTodoType.depotOffre)?.date
          ).getTime() -
          new Date(
            LeadUtils.findTodoByOrganizationLeadTodoType(b.leadTodos, OrganizationLeadTodoType.depotOffre)?.date
          ).getTime()
      )
      .filter(o => o.valeurOffreAcquereur !== null && o.valeurOffreAcquereur !== undefined);
  }

  export function getValeurOffreRetenue(stratalot: Stratalot): { valeurOffre: number } {
    return {
      valeurOffre:
        LeadUtils.filterLeadOfStratalotByValidOffreAquereurAndSortByDepotOffreDate(stratalot)[0]?.valeurOffreAcquereur
    };
  }

  export function calulateOfferNbAndDateExamen(leads: Lead[]): {
    nbOffres: number;
    nbAQualifier: number;
    nbCandidatures: number;
    demandeExamen: Date;
  } {
    const dossiersDeVenteActifs = leads.filter(o => o.statut === LeadStatusEnum.toQualify);
    let nbOffres = 0;
    let nbAQualifier = 0;
    let nbCandidatures = 0;
    const demandeExamen = dossiersDeVenteActifs.length
      ? LeadUtils.getMostAdvanceLead(dossiersDeVenteActifs).leadTodos.find(
          leadTodo =>
            leadTodo?.organizationLeadTodo?.type === OrganizationLeadTodoType.offreSoumiseValidation && leadTodo.date
        )?.date
      : null;
    dossiersDeVenteActifs.forEach(dossiersDeVentes => {
      if (
        dossiersDeVentes.leadTodos?.find(
          leadTodo => leadTodo.organizationLeadTodo?.type === OrganizationLeadTodoType.depotOffre && leadTodo.date
        )
      ) {
        nbOffres++;
      } else if (
        dossiersDeVentes.leadTodos?.find(
          leadTodo => leadTodo.organizationLeadTodo?.type === OrganizationLeadTodoType.premierContact && leadTodo.date
        ) &&
        (!dossiersDeVentes.prospect?.prospectBuyingWishs?.length ||
          ProspectUtils.getlastProspectBuyingWish(dossiersDeVentes.prospect).organizationBuyingWish.type ===
            BuyingWish.toQualify)
      ) {
        nbAQualifier++;
      }

      nbCandidatures++;
    });
    return { nbOffres, nbAQualifier, nbCandidatures: nbCandidatures, demandeExamen };
  }

  export function calculateUbiflowNbToQualify(leads: Lead[]): {
    contactsUbiflow: number;
    nbAQualifier: number;
  } {
    const dossiersDeVenteActifs = leads?.filter(l => l.statut === LeadStatusEnum.toQualify);
    let contactsUbiflow = 0;
    let nbAQualifier = 0;

    dossiersDeVenteActifs.forEach(lead => {
      if (lead.statutAvancement === LeadAvancementEnum.premierContact) {
        if (lead.createdByUbiflow) {
          contactsUbiflow++;
          if (
            ProspectUtils.getlastProspectBuyingWish(lead.prospect)?.organizationBuyingWish.type === BuyingWish.toQualify
          ) {
            nbAQualifier++;
          }
        }
      }
    });

    return { contactsUbiflow, nbAQualifier };
  }

  export const leadTodoForNeuf = ['cla', 'reservation', 'leveeOption', 'edle', 'edls', 'resiliation'];

  export interface LeadInfoDataRowProspect {
    idLead: number;
    mainStratalot: Stratalot;
    identifier: string;
    priceOffer: number;
    area: number;
    adress: string;
    floor: string;
    statut: LeadStatusEnum;
  }
}

export default LeadUtils;
