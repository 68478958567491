import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as BoardStateActions from './board-state.actions';
import { adapter, initialState, BoardStateState } from './board-state.state';
import { Dictionary } from '@ngrx/entity';
import { BoardStateEntityState } from '@_model/interfaces/board-state.model';

export const boardStateReducersGeneratedFunctions: ReducerTypes<BoardStateState, readonly ActionCreator[]>[] = [
  on(BoardStateActions.getOneBoardState, (state: BoardStateState) => setLoadingsState(state, true)),
  on(BoardStateActions.getManyBoardStates, (state: BoardStateState) => setLoadingsState(state, true)),
  on(BoardStateActions.upsertOneBoardState, (state: BoardStateState) => setLoadingsState(state, true)),

  on(BoardStateActions.upsertManyBoardStatesSuccess, (state: BoardStateState, { boardStates }) =>
    adapter.upsertMany(boardStates, setLoadingsState(state, false))
  ),
  on(BoardStateActions.deleteOneBoardState, (state: BoardStateState) => setLoadingsState(state, true)),
  on(BoardStateActions.deleteOneBoardStateSuccess, (state: BoardStateState, { idBoardState }) =>
    adapter.removeOne(idBoardState, setLoadingsState(state, false))
  ),
  on(BoardStateActions.clearActive, (state: BoardStateState) => ({ ...state, actives: [] })),
  on(BoardStateActions.addManyActives, (state: BoardStateState, { idBoardStates }) => ({
    ...state,
    actives: state.actives.concat(idBoardStates)
  })),
  on(BoardStateActions.deleteOneActive, (state: BoardStateState, { idBoardState }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idBoardState)
  })),

  on(BoardStateActions.clearStore, () => initialState),

  on(BoardStateActions.addUserSuccess, (state: BoardStateState, { idBoardState, idUser }) => {
    if (!state.entities[idBoardState]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idBoardState]: {
          ...state.entities[idBoardState],
          user: idUser
        }
      }
    };
  }),

  on(BoardStateActions.deleteManyUserSuccess, (state: BoardStateState, { idUsers, idBoardStates }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idBoardStates.reduce((entities, idBoardState) => {
          if (!state.entities[idBoardState]?.user) {
            return entities;
          }
          entities[idBoardState] = {
            ...state.entities[idBoardState],
            user: idUsers.some((idUser: number) => idUser === state.entities[idBoardState]?.user)
              ? undefined
              : state.entities[idBoardState]?.user
          } as BoardStateEntityState;
          return entities;
        }, {} as Dictionary<BoardStateEntityState>)
      }
    };
  }),

  on(
    BoardStateActions.addSaleCategoryFamilySuccess,
    (state: BoardStateState, { idBoardState, idSaleCategoryFamily }) => {
      if (!state.entities[idBoardState]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idBoardState]: {
            ...state.entities[idBoardState],
            saleCategoryFamily: idSaleCategoryFamily
          }
        }
      };
    }
  ),

  on(
    BoardStateActions.deleteManySaleCategoryFamilySuccess,
    (state: BoardStateState, { idFamilySaleCategories, idBoardStates }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idBoardStates.reduce((entities, idBoardState) => {
            if (!state.entities[idBoardState]?.saleCategoryFamily) {
              return entities;
            }
            entities[idBoardState] = {
              ...state.entities[idBoardState],
              saleCategoryFamily: idFamilySaleCategories.some(
                (idSaleCategoryFamily: number) =>
                  idSaleCategoryFamily === state.entities[idBoardState]?.saleCategoryFamily
              )
                ? undefined
                : state.entities[idBoardState]?.saleCategoryFamily
            } as BoardStateEntityState;
            return entities;
          }, {} as Dictionary<BoardStateEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: BoardStateState,
  isLoading: boolean,
  isLoaded: boolean = true
): BoardStateState {
  return { ...state, isLoaded, isLoading };
}
