import { RepositoryService } from '@_services/api/repository.service';
import { Occupant } from '@_model/interfaces/occupant.model';
import { Observable } from 'rxjs';

export class GeneratedOccupantApiService {
  constructor(protected repo: RepositoryService) {}

  public getOccupants(params?: any): Observable<Occupant[]> {
    return this.repo.getData<Occupant[]>('occupant', params);
  }

  public getOccupant(params: { idOccupant: number; params?: any }): Observable<Occupant> {
    return this.repo.getData<Occupant>('occupant/' + params.idOccupant, params.params);
  }

  public addOccupant(occupant: Partial<Occupant>): Observable<Occupant> {
    return this.repo.create<Occupant>('occupant', occupant);
  }

  public updateOccupant(occupant: Partial<Occupant>): Observable<Occupant> {
    return this.repo.update('occupant', occupant);
  }

  public deleteOccupant(idOccupant: number): Observable<number> {
    return this.repo.delete('occupant/' + +idOccupant);
  }
}
