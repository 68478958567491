import { DiffusionVisualState, initialState } from './diffusion-visual.state';
import { Action, createReducer } from '@ngrx/store';
import { diffusionVisualReducersGeneratedFunctions } from './diffusion-visual-generated.reducer';

const diffusionVisualReducersFunctions = [...diffusionVisualReducersGeneratedFunctions];

const diffusionVisualReducer = createReducer(initialState, ...diffusionVisualReducersFunctions);

export function reducer(state: DiffusionVisualState | undefined, action: Action) {
  return diffusionVisualReducer(state, action);
}
