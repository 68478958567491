import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  CompanyCommunicationMedia,
  CompanyCommunicationMediaEntityState
} from '@_model/interfaces/company-communication-media.model';
import { CompanyCommunicationMediaApiService } from '@_services/api/company-communication-media-api.service';
import * as companyCommunicationMediaActions from '@_store/company-communication-media/company-communication-media.actions';
import { getActionsToNormalizeCompanyCommunicationMedia } from '@_config/store/normalization.generated';
import { selectCompanyCommunicationMediaState } from './company-communication-media-generated.selectors';
import * as companyCommunicationActions from '@_store/company-communication/company-communication.actions';
import { CompanyCommunication } from '@_model/interfaces/company-communication.model';

export interface CompanyCommunicationMediaRelationsIds {
  companyCommunication?: number;
}

export function getDefaultAddCompanyCommunicationMediaActions(
  companyCommunicationMedia: CompanyCommunicationMediaEntityState,
  ids?: CompanyCommunicationMediaRelationsIds
): Action[] {
  const actions: Action[] = [
    companyCommunicationMediaActions.normalizeManyCompanyCommunicationMediasAfterUpsert({
      companyCommunicationMedias: [companyCommunicationMedia]
    })
  ];

  if (ids?.companyCommunication) {
    actions.push(
      companyCommunicationActions.upsertOneCompanyCommunication({
        companyCommunication: {
          idCompanyCommunicationMedia: companyCommunicationMedia.idCompanyCommunicationMedia,
          idCompanyCommunication: ids.companyCommunication as number
        } as CompanyCommunication
      })
    );
    actions.push(
      companyCommunicationMediaActions.addCompanyCommunicationSuccess({
        idCompanyCommunicationMedia: companyCommunicationMedia.idCompanyCommunicationMedia,
        idCompanyCommunication: ids.companyCommunication
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCompanyCommunicationMediaActions(
  companyCommunicationMedia: CompanyCommunicationMediaEntityState
): Action[] {
  const actions: Action[] = [
    companyCommunicationMediaActions.deleteOneCompanyCommunicationMediaSuccess({
      idCompanyCommunicationMedia: companyCommunicationMedia.idCompanyCommunicationMedia
    })
  ];

  if (companyCommunicationMedia.companyCommunication) {
    actions.push(
      companyCommunicationActions.deleteManyCompanyCommunicationMediaSuccess({
        idCompanyCommunicationMedias: [companyCommunicationMedia.idCompanyCommunicationMedia],
        idCompanyCommunications: [companyCommunicationMedia.companyCommunication] as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyCommunicationMediaEffects {
  constructor(
    protected actions$: Actions,
    protected companyCommunicationMediaApiService: CompanyCommunicationMediaApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyCommunicationMedias$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationMediaActions.getManyCompanyCommunicationMedias),
      switchMap(({ params }) =>
        this.companyCommunicationMediaApiService.getCompanyCommunicationMedias(params).pipe(
          map((companyCommunicationMedias: CompanyCommunicationMedia[]) => {
            return companyCommunicationMediaActions.normalizeManyCompanyCommunicationMediasAfterUpsert({
              companyCommunicationMedias
            });
          }),
          catchError(error => of(companyCommunicationMediaActions.companyCommunicationMediasFailure({ error })))
        )
      )
    );
  });

  getOneCompanyCommunicationMedia$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationMediaActions.getOneCompanyCommunicationMedia),
      switchMap(idCompanyCommunicationMedia =>
        this.companyCommunicationMediaApiService.getCompanyCommunicationMedia(idCompanyCommunicationMedia).pipe(
          map((companyCommunicationMedia: CompanyCommunicationMedia) => {
            return companyCommunicationMediaActions.normalizeManyCompanyCommunicationMediasAfterUpsert({
              companyCommunicationMedias: [companyCommunicationMedia]
            });
          }),
          catchError(error => of(companyCommunicationMediaActions.companyCommunicationMediasFailure({ error })))
        )
      )
    );
  });

  upsertOneCompanyCommunicationMedia$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationMediaActions.upsertOneCompanyCommunicationMedia),
      concatMap(
        ({
          companyCommunicationMedia,
          ids
        }: {
          companyCommunicationMedia: Partial<CompanyCommunicationMedia>;
          ids?: CompanyCommunicationMediaRelationsIds;
        }) => {
          if (companyCommunicationMedia.idCompanyCommunicationMedia) {
            return this.companyCommunicationMediaApiService
              .updateCompanyCommunicationMedia(companyCommunicationMedia)
              .pipe(
                map((companyCommunicationMediaReturned: CompanyCommunicationMedia) => {
                  return companyCommunicationMediaActions.normalizeManyCompanyCommunicationMediasAfterUpsert({
                    companyCommunicationMedias: [companyCommunicationMediaReturned]
                  });
                }),
                catchError(error => of(companyCommunicationMediaActions.companyCommunicationMediasFailure({ error })))
              );
          } else {
            return this.companyCommunicationMediaApiService
              .addCompanyCommunicationMedia(companyCommunicationMedia)
              .pipe(
                mergeMap((companyCommunicationMediaReturned: CompanyCommunicationMedia) =>
                  getDefaultAddCompanyCommunicationMediaActions(companyCommunicationMediaReturned, ids)
                ),
                catchError(error => of(companyCommunicationMediaActions.companyCommunicationMediasFailure({ error })))
              );
          }
        }
      )
    );
  });

  deleteOneCompanyCommunicationMedia$ = createEffect(() => {
    const selectCompanyCommunicationMediaState$ = this.store$.select(selectCompanyCommunicationMediaState);
    return this.actions$.pipe(
      ofType(companyCommunicationMediaActions.deleteOneCompanyCommunicationMedia),
      withLatestFrom(selectCompanyCommunicationMediaState$),
      concatMap(([{ idCompanyCommunicationMedia }, state]) =>
        this.companyCommunicationMediaApiService.deleteCompanyCommunicationMedia(idCompanyCommunicationMedia).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCompanyCommunicationMediaActions(
                state.entities[idCompanyCommunicationMedia] as CompanyCommunicationMediaEntityState
              ),
              companyCommunicationMediaActions.deleteOneCompanyCommunicationMedia.type
            )
          ]),
          catchError(error => of(companyCommunicationMediaActions.companyCommunicationMediasFailure({ error })))
        )
      )
    );
  });

  normalizeManyCompanyCommunicationMediasAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationMediaActions.normalizeManyCompanyCommunicationMediasAfterUpsert),
      concatMap(({ companyCommunicationMedias }) => {
        const actions: Action[] = getActionsToNormalizeCompanyCommunicationMedia(
          companyCommunicationMedias,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CompanyCommunicationMedia] Normalization After Upsert Success')];
      })
    );
  });
}
