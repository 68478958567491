import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  GeneratedDocumentsResidence,
  GeneratedDocumentsResidenceEntityState
} from '@_model/interfaces/generated-documents-residence.model';
import { GeneratedDocumentsResidenceApiService } from '@_services/api/generated-documents-residence-api.service';
import * as generatedDocumentsResidenceActions from '@_store/generated-documents-residence/generated-documents-residence.actions';
import { getActionsToNormalizeGeneratedDocumentsResidence } from '@_config/store/normalization.generated';
import { selectGeneratedDocumentsResidenceState } from './generated-documents-residence-generated.selectors';
import * as residenceActions from '@_store/residence/residence.actions';
import * as generatedDocumentActions from '@_store/generated-document/generated-document.actions';

export interface GeneratedDocumentsResidenceRelationsIds {
  residence?: number;
  generatedDocument?: number;
}

export function getDefaultAddGeneratedDocumentsResidenceActions(
  generatedDocumentsResidence: GeneratedDocumentsResidenceEntityState,
  ids?: GeneratedDocumentsResidenceRelationsIds
): Action[] {
  const actions: Action[] = [
    generatedDocumentsResidenceActions.normalizeManyGeneratedDocumentsResidencesAfterUpsert({
      generatedDocumentsResidences: [generatedDocumentsResidence]
    })
  ];

  if (ids?.residence) {
    actions.push(
      residenceActions.addManyGeneratedDocumentsResidenceSuccess({
        idResidence: ids.residence,
        idGeneratedDocumentsResidences: [generatedDocumentsResidence.idGeneratedDocumentsResidence]
      })
    );
    actions.push(
      generatedDocumentsResidenceActions.addResidenceSuccess({
        idGeneratedDocumentsResidence: generatedDocumentsResidence.idGeneratedDocumentsResidence,
        idResidence: ids.residence
      })
    );
  }

  if (ids?.generatedDocument) {
    actions.push(
      generatedDocumentActions.addManyGeneratedDocumentsResidenceSuccess({
        idGeneratedDocument: ids.generatedDocument,
        idGeneratedDocumentsResidences: [generatedDocumentsResidence.idGeneratedDocumentsResidence]
      })
    );
    actions.push(
      generatedDocumentsResidenceActions.addGeneratedDocumentSuccess({
        idGeneratedDocumentsResidence: generatedDocumentsResidence.idGeneratedDocumentsResidence,
        idGeneratedDocument: ids.generatedDocument
      })
    );
  }

  return actions;
}

export function getDefaultDeleteGeneratedDocumentsResidenceActions(
  generatedDocumentsResidence: GeneratedDocumentsResidenceEntityState
): Action[] {
  const actions: Action[] = [
    generatedDocumentsResidenceActions.deleteOneGeneratedDocumentsResidenceSuccess({
      idGeneratedDocumentsResidence: generatedDocumentsResidence.idGeneratedDocumentsResidence
    })
  ];

  if (generatedDocumentsResidence.residence) {
    actions.push(
      residenceActions.deleteManyGeneratedDocumentsResidenceSuccess({
        idGeneratedDocumentsResidences: [generatedDocumentsResidence.idGeneratedDocumentsResidence],
        idResidences: [generatedDocumentsResidence.residence as number]
      })
    );
  }

  if (generatedDocumentsResidence.generatedDocument) {
    actions.push(
      generatedDocumentActions.deleteManyGeneratedDocumentsResidenceSuccess({
        idGeneratedDocumentsResidences: [generatedDocumentsResidence.idGeneratedDocumentsResidence],
        idGeneratedDocuments: [generatedDocumentsResidence.generatedDocument as number]
      })
    );
  }

  return actions;
}

export class GeneratedGeneratedDocumentsResidenceEffects {
  constructor(
    protected actions$: Actions,
    protected generatedDocumentsResidenceApiService: GeneratedDocumentsResidenceApiService,
    protected store$: Store<AppState>
  ) {}

  getManyGeneratedDocumentsResidences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(generatedDocumentsResidenceActions.getManyGeneratedDocumentsResidences),
      switchMap(({ params }) =>
        this.generatedDocumentsResidenceApiService.getGeneratedDocumentsResidences(params).pipe(
          map((generatedDocumentsResidences: GeneratedDocumentsResidence[]) => {
            return generatedDocumentsResidenceActions.normalizeManyGeneratedDocumentsResidencesAfterUpsert({
              generatedDocumentsResidences
            });
          }),
          catchError(error => of(generatedDocumentsResidenceActions.generatedDocumentsResidencesFailure({ error })))
        )
      )
    );
  });

  getOneGeneratedDocumentsResidence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(generatedDocumentsResidenceActions.getOneGeneratedDocumentsResidence),
      switchMap(idGeneratedDocumentsResidence =>
        this.generatedDocumentsResidenceApiService.getGeneratedDocumentsResidence(idGeneratedDocumentsResidence).pipe(
          map((generatedDocumentsResidence: GeneratedDocumentsResidence) => {
            return generatedDocumentsResidenceActions.normalizeManyGeneratedDocumentsResidencesAfterUpsert({
              generatedDocumentsResidences: [generatedDocumentsResidence]
            });
          }),
          catchError(error => of(generatedDocumentsResidenceActions.generatedDocumentsResidencesFailure({ error })))
        )
      )
    );
  });

  upsertOneGeneratedDocumentsResidence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(generatedDocumentsResidenceActions.upsertOneGeneratedDocumentsResidence),
      concatMap(
        ({
          generatedDocumentsResidence,
          ids
        }: {
          generatedDocumentsResidence: Partial<GeneratedDocumentsResidence>;
          ids?: GeneratedDocumentsResidenceRelationsIds;
        }) => {
          if (generatedDocumentsResidence.idGeneratedDocumentsResidence) {
            return this.generatedDocumentsResidenceApiService
              .updateGeneratedDocumentsResidence(generatedDocumentsResidence)
              .pipe(
                map((generatedDocumentsResidenceReturned: GeneratedDocumentsResidence) => {
                  return generatedDocumentsResidenceActions.normalizeManyGeneratedDocumentsResidencesAfterUpsert({
                    generatedDocumentsResidences: [generatedDocumentsResidenceReturned]
                  });
                }),
                catchError(error =>
                  of(generatedDocumentsResidenceActions.generatedDocumentsResidencesFailure({ error }))
                )
              );
          } else {
            return this.generatedDocumentsResidenceApiService
              .addGeneratedDocumentsResidence(generatedDocumentsResidence)
              .pipe(
                mergeMap((generatedDocumentsResidenceReturned: GeneratedDocumentsResidence) =>
                  getDefaultAddGeneratedDocumentsResidenceActions(generatedDocumentsResidenceReturned, ids)
                ),
                catchError(error =>
                  of(generatedDocumentsResidenceActions.generatedDocumentsResidencesFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneGeneratedDocumentsResidence$ = createEffect(() => {
    const selectGeneratedDocumentsResidenceState$ = this.store$.select(selectGeneratedDocumentsResidenceState);
    return this.actions$.pipe(
      ofType(generatedDocumentsResidenceActions.deleteOneGeneratedDocumentsResidence),
      withLatestFrom(selectGeneratedDocumentsResidenceState$),
      concatMap(([{ idGeneratedDocumentsResidence }, state]) =>
        this.generatedDocumentsResidenceApiService
          .deleteGeneratedDocumentsResidence(idGeneratedDocumentsResidence)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteGeneratedDocumentsResidenceActions(
                  state.entities[idGeneratedDocumentsResidence] as GeneratedDocumentsResidenceEntityState
                ),
                generatedDocumentsResidenceActions.deleteOneGeneratedDocumentsResidence.type
              )
            ]),
            catchError(error => of(generatedDocumentsResidenceActions.generatedDocumentsResidencesFailure({ error })))
          )
      )
    );
  });

  normalizeManyGeneratedDocumentsResidencesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(generatedDocumentsResidenceActions.normalizeManyGeneratedDocumentsResidencesAfterUpsert),
      concatMap(({ generatedDocumentsResidences }) => {
        const actions: Action[] = getActionsToNormalizeGeneratedDocumentsResidence(
          generatedDocumentsResidences,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[GeneratedDocumentsResidence] Normalization After Upsert Success')];
      })
    );
  });
}
