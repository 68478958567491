import { RepositoryService } from '@_services/api/repository.service';
import { Diagnostic } from '@_model/interfaces/diagnostic.model';
import { Observable } from 'rxjs';

export class GeneratedDiagnosticApiService {
  constructor(protected repo: RepositoryService) {}

  public getDiagnostics(params?: any): Observable<Diagnostic[]> {
    return this.repo.getData<Diagnostic[]>('diagnostic', params);
  }

  public getDiagnostic(params: { idDiagnostic: number; params?: any }): Observable<Diagnostic> {
    return this.repo.getData<Diagnostic>('diagnostic/' + params.idDiagnostic, params.params);
  }

  public addDiagnostic(diagnostic: Partial<Diagnostic>): Observable<Diagnostic> {
    return this.repo.create<Diagnostic>('diagnostic', diagnostic);
  }

  public updateDiagnostic(diagnostic: Partial<Diagnostic>): Observable<Diagnostic> {
    return this.repo.update('diagnostic', diagnostic);
  }

  public deleteDiagnostic(idDiagnostic: number): Observable<number> {
    return this.repo.delete('diagnostic/' + +idDiagnostic);
  }
}
