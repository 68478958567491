import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationResidenceTodoRule,
  OrganizationResidenceTodoRuleEntityState
} from '@_model/interfaces/organization-residence-todo-rule.model';
import { OrganizationResidenceTodoRuleRelationsIds } from './organization-residence-todo-rule-generated.effects';

export const getOneOrganizationResidenceTodoRule = createAction(
  '[OrganizationResidenceTodoRule] Get One OrganizationResidenceTodoRule',
  props<{ idOrganizationResidenceTodoRule: number; params?: any }>()
);

export const getManyOrganizationResidenceTodoRules = createAction(
  '[OrganizationResidenceTodoRule] Get Many OrganizationResidenceTodoRules',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationResidenceTodoRule] Add Many Actives OrganizationResidenceTodoRule',
  props<{ idOrganizationResidenceTodoRules: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationResidenceTodoRule] Delete One Active OrganizationResidenceTodoRule',
  props<{ idOrganizationResidenceTodoRule: number }>()
);

export const clearActive = createAction('[OrganizationResidenceTodoRule] Clear Active OrganizationResidenceTodoRule');

export const upsertOneOrganizationResidenceTodoRule = createAction(
  '[OrganizationResidenceTodoRule] Upsert One OrganizationResidenceTodoRule',
  props<{
    organizationResidenceTodoRule: Partial<OrganizationResidenceTodoRule>;
    ids?: OrganizationResidenceTodoRuleRelationsIds;
  }>()
);

export const upsertManyOrganizationResidenceTodoRules = createAction(
  '[OrganizationResidenceTodoRule] Upsert Many OrganizationResidenceTodoRules',
  props<{
    organizationResidenceTodoRules: Partial<OrganizationResidenceTodoRule>[];
    ids?: OrganizationResidenceTodoRuleRelationsIds;
  }>()
);

export const upsertManyOrganizationResidenceTodoRulesSuccess = createAction(
  '[OrganizationResidenceTodoRule] Create Many OrganizationResidenceTodoRules Success',
  props<{ organizationResidenceTodoRules: OrganizationResidenceTodoRuleEntityState[] }>()
);

export const deleteOneOrganizationResidenceTodoRule = createAction(
  '[OrganizationResidenceTodoRule] Delete One OrganizationResidenceTodoRule',
  props<{ idOrganizationResidenceTodoRule: number }>()
);

export const deleteOneOrganizationResidenceTodoRuleSuccess = createAction(
  '[OrganizationResidenceTodoRule] Delete One OrganizationResidenceTodoRule Success',
  props<{ idOrganizationResidenceTodoRule: number }>()
);

export const normalizeManyOrganizationResidenceTodoRulesAfterUpsert = createAction(
  '[OrganizationResidenceTodoRule] Normalize Many OrganizationResidenceTodoRules After Upsert',
  props<{ organizationResidenceTodoRules: OrganizationResidenceTodoRuleEntityState[] }>()
);

export const organizationResidenceTodoRulesFailure = createAction(
  '[OrganizationResidenceTodoRule] OrganizationResidenceTodoRules Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationResidenceTodoRule] Clear OrganizationResidenceTodoRules');

export const addManyOrganizationResidenceTodoSuccess = createAction(
  '[OrganizationResidenceTodoRule] Add Many organization-residence-todo',
  props<{ idOrganizationResidenceTodoRule: number; idOrganizationResidenceTodos: number[] }>()
);

export const deleteManyOrganizationResidenceTodoSuccess = createAction(
  '[OrganizationResidenceTodoRule] Delete Many OrganizationResidenceTodos',
  props<{ idOrganizationResidenceTodos: number[]; idOrganizationResidenceTodoRules: number[] }>()
);

export const addManyOrganizationResidenceTodoAppliedRuleSuccess = createAction(
  '[OrganizationResidenceTodoRule] Add Many organization-residence-todo-applied-rule',
  props<{ idOrganizationResidenceTodoRule: number; idOrganizationResidenceTodoAppliedRules: number[] }>()
);

export const deleteManyOrganizationResidenceTodoAppliedRuleSuccess = createAction(
  '[OrganizationResidenceTodoRule] Delete Many OrganizationResidenceTodoAppliedRules',
  props<{ idOrganizationResidenceTodoAppliedRules: number[]; idOrganizationResidenceTodoRules: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[OrganizationResidenceTodoRule] Add Organization',
  props<{ idOrganizationResidenceTodoRule: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationResidenceTodoRule] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationResidenceTodoRules: number[] }>()
);

export const addOrganizationSaleCategorySuccess = createAction(
  '[OrganizationResidenceTodoRule] Add OrganizationSaleCategory',
  props<{ idOrganizationResidenceTodoRule: number; idOrganizationSaleCategory: number }>()
);

export const deleteManyOrganizationSaleCategorySuccess = createAction(
  '[OrganizationResidenceTodoRule] Delete Many OrganizationSaleCategory',
  props<{ idOrganizationSaleCategories: number[]; idOrganizationResidenceTodoRules: number[] }>()
);

export const addSaleCategoryFamilySuccess = createAction(
  '[OrganizationResidenceTodoRule] Add SaleCategoryFamily',
  props<{ idOrganizationResidenceTodoRule: number; idSaleCategoryFamily: number }>()
);

export const deleteManySaleCategoryFamilySuccess = createAction(
  '[OrganizationResidenceTodoRule] Delete Many SaleCategoryFamily',
  props<{ idFamilySaleCategories: number[]; idOrganizationResidenceTodoRules: number[] }>()
);

export const addCompanySuccess = createAction(
  '[OrganizationResidenceTodoRule] Add Company',
  props<{ idOrganizationResidenceTodoRule: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[OrganizationResidenceTodoRule] Delete Many Company',
  props<{ idCompanies: number[]; idOrganizationResidenceTodoRules: number[] }>()
);
