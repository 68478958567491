import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  CompanyCommunicationRecipientUser,
  CompanyCommunicationRecipientUserEntityState
} from '@_model/interfaces/company-communication-recipient-user.model';
import { CompanyCommunicationRecipientUserApiService } from '@_services/api/company-communication-recipient-user-api.service';
import * as companyCommunicationRecipientUserActions from '@_store/company-communication-recipient-user/company-communication-recipient-user.actions';
import { getActionsToNormalizeCompanyCommunicationRecipientUser } from '@_config/store/normalization.generated';
import { selectCompanyCommunicationRecipientUserState } from './company-communication-recipient-user-generated.selectors';
import * as companyCommunicationRecipientActions from '@_store/company-communication-recipient/company-communication-recipient.actions';
import * as userActions from '@_store/user/user.actions';

export interface CompanyCommunicationRecipientUserRelationsIds {
  companyCommunicationRecipient?: number;
  user?: number;
}

export function getDefaultAddCompanyCommunicationRecipientUserActions(
  companyCommunicationRecipientUser: CompanyCommunicationRecipientUserEntityState,
  ids?: CompanyCommunicationRecipientUserRelationsIds
): Action[] {
  const actions: Action[] = [
    companyCommunicationRecipientUserActions.normalizeManyCompanyCommunicationRecipientUsersAfterUpsert({
      companyCommunicationRecipientUsers: [companyCommunicationRecipientUser]
    })
  ];

  if (ids?.companyCommunicationRecipient) {
    actions.push(
      companyCommunicationRecipientActions.addManyCompanyCommunicationRecipientUserSuccess({
        idCompanyCommunicationRecipient: ids.companyCommunicationRecipient,
        idCompanyCommunicationRecipientUsers: [companyCommunicationRecipientUser.idCompanyCommunicationRecipientUser]
      })
    );
    actions.push(
      companyCommunicationRecipientUserActions.addCompanyCommunicationRecipientSuccess({
        idCompanyCommunicationRecipientUser: companyCommunicationRecipientUser.idCompanyCommunicationRecipientUser,
        idCompanyCommunicationRecipient: ids.companyCommunicationRecipient
      })
    );
  }

  if (ids?.user) {
    actions.push(
      userActions.addManyCompanyCommunicationRecipientUserSuccess({
        idUser: ids.user,
        idCompanyCommunicationRecipientUsers: [companyCommunicationRecipientUser.idCompanyCommunicationRecipientUser]
      })
    );
    actions.push(
      companyCommunicationRecipientUserActions.addUserSuccess({
        idCompanyCommunicationRecipientUser: companyCommunicationRecipientUser.idCompanyCommunicationRecipientUser,
        idUser: ids.user
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCompanyCommunicationRecipientUserActions(
  companyCommunicationRecipientUser: CompanyCommunicationRecipientUserEntityState
): Action[] {
  const actions: Action[] = [
    companyCommunicationRecipientUserActions.deleteOneCompanyCommunicationRecipientUserSuccess({
      idCompanyCommunicationRecipientUser: companyCommunicationRecipientUser.idCompanyCommunicationRecipientUser
    })
  ];

  if (companyCommunicationRecipientUser.companyCommunicationRecipient) {
    actions.push(
      companyCommunicationRecipientActions.deleteManyCompanyCommunicationRecipientUserSuccess({
        idCompanyCommunicationRecipientUsers: [companyCommunicationRecipientUser.idCompanyCommunicationRecipientUser],
        idCompanyCommunicationRecipients: [companyCommunicationRecipientUser.companyCommunicationRecipient as number]
      })
    );
  }

  if (companyCommunicationRecipientUser.user) {
    actions.push(
      userActions.deleteManyCompanyCommunicationRecipientUserSuccess({
        idCompanyCommunicationRecipientUsers: [companyCommunicationRecipientUser.idCompanyCommunicationRecipientUser],
        idUsers: [companyCommunicationRecipientUser.user as number]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyCommunicationRecipientUserEffects {
  constructor(
    protected actions$: Actions,
    protected companyCommunicationRecipientUserApiService: CompanyCommunicationRecipientUserApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyCommunicationRecipientUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationRecipientUserActions.getManyCompanyCommunicationRecipientUsers),
      switchMap(({ params }) =>
        this.companyCommunicationRecipientUserApiService.getCompanyCommunicationRecipientUsers(params).pipe(
          map((companyCommunicationRecipientUsers: CompanyCommunicationRecipientUser[]) => {
            return companyCommunicationRecipientUserActions.normalizeManyCompanyCommunicationRecipientUsersAfterUpsert({
              companyCommunicationRecipientUsers
            });
          }),
          catchError(error =>
            of(companyCommunicationRecipientUserActions.companyCommunicationRecipientUsersFailure({ error }))
          )
        )
      )
    );
  });

  getOneCompanyCommunicationRecipientUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationRecipientUserActions.getOneCompanyCommunicationRecipientUser),
      switchMap(idCompanyCommunicationRecipientUser =>
        this.companyCommunicationRecipientUserApiService
          .getCompanyCommunicationRecipientUser(idCompanyCommunicationRecipientUser)
          .pipe(
            map((companyCommunicationRecipientUser: CompanyCommunicationRecipientUser) => {
              return companyCommunicationRecipientUserActions.normalizeManyCompanyCommunicationRecipientUsersAfterUpsert(
                { companyCommunicationRecipientUsers: [companyCommunicationRecipientUser] }
              );
            }),
            catchError(error =>
              of(companyCommunicationRecipientUserActions.companyCommunicationRecipientUsersFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneCompanyCommunicationRecipientUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationRecipientUserActions.upsertOneCompanyCommunicationRecipientUser),
      concatMap(
        ({
          companyCommunicationRecipientUser,
          ids
        }: {
          companyCommunicationRecipientUser: Partial<CompanyCommunicationRecipientUser>;
          ids?: CompanyCommunicationRecipientUserRelationsIds;
        }) => {
          if (companyCommunicationRecipientUser.idCompanyCommunicationRecipientUser) {
            return this.companyCommunicationRecipientUserApiService
              .updateCompanyCommunicationRecipientUser(companyCommunicationRecipientUser)
              .pipe(
                map((companyCommunicationRecipientUserReturned: CompanyCommunicationRecipientUser) => {
                  return companyCommunicationRecipientUserActions.normalizeManyCompanyCommunicationRecipientUsersAfterUpsert(
                    { companyCommunicationRecipientUsers: [companyCommunicationRecipientUserReturned] }
                  );
                }),
                catchError(error =>
                  of(companyCommunicationRecipientUserActions.companyCommunicationRecipientUsersFailure({ error }))
                )
              );
          } else {
            return this.companyCommunicationRecipientUserApiService
              .addCompanyCommunicationRecipientUser(companyCommunicationRecipientUser)
              .pipe(
                mergeMap((companyCommunicationRecipientUserReturned: CompanyCommunicationRecipientUser) =>
                  getDefaultAddCompanyCommunicationRecipientUserActions(companyCommunicationRecipientUserReturned, ids)
                ),
                catchError(error =>
                  of(companyCommunicationRecipientUserActions.companyCommunicationRecipientUsersFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneCompanyCommunicationRecipientUser$ = createEffect(() => {
    const selectCompanyCommunicationRecipientUserState$ = this.store$.select(
      selectCompanyCommunicationRecipientUserState
    );
    return this.actions$.pipe(
      ofType(companyCommunicationRecipientUserActions.deleteOneCompanyCommunicationRecipientUser),
      withLatestFrom(selectCompanyCommunicationRecipientUserState$),
      concatMap(([{ idCompanyCommunicationRecipientUser }, state]) =>
        this.companyCommunicationRecipientUserApiService
          .deleteCompanyCommunicationRecipientUser(idCompanyCommunicationRecipientUser)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteCompanyCommunicationRecipientUserActions(
                  state.entities[idCompanyCommunicationRecipientUser] as CompanyCommunicationRecipientUserEntityState
                ),
                companyCommunicationRecipientUserActions.deleteOneCompanyCommunicationRecipientUser.type
              )
            ]),
            catchError(error =>
              of(companyCommunicationRecipientUserActions.companyCommunicationRecipientUsersFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyCompanyCommunicationRecipientUsersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationRecipientUserActions.normalizeManyCompanyCommunicationRecipientUsersAfterUpsert),
      concatMap(({ companyCommunicationRecipientUsers }) => {
        const actions: Action[] = getActionsToNormalizeCompanyCommunicationRecipientUser(
          companyCommunicationRecipientUsers,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CompanyCommunicationRecipientUser] Normalization After Upsert Success')];
      })
    );
  });
}
