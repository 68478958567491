import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as residencePriceGridHistoryActions from '@_store/residence-price-grid-history/residence-price-grid-history.actions';
import { ResidencePriceGridHistoryApiService } from '@_services/api/residence-price-grid-history-api.service';
import { GeneratedResidencePriceGridHistoryEffects } from './residence-price-grid-history-generated.effects';
import { ResidencePriceGridHistory } from '@_shared/models/interfaces/residence-price-grid-history.model';
import { catchError, map, of, switchMap } from 'rxjs';

@Injectable()
export class ResidencePriceGridHistoryEffects extends GeneratedResidencePriceGridHistoryEffects {
  constructor(
    actions$: Actions,
    residencePriceGridHistoryApiService: ResidencePriceGridHistoryApiService,
    store$: Store<AppState>
  ) {
    super(actions$, residencePriceGridHistoryApiService, store$);
  }

  getLastUpdateResidencePriceGridHistories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residencePriceGridHistoryActions.getLastUpdateResidencePriceGridHistories),
      switchMap(({ params }) =>
        this.residencePriceGridHistoryApiService.getLastUpdateResidencePriceGridHistories(params).pipe(
          map((residencePriceGridHistories: ResidencePriceGridHistory[]) => {
            return residencePriceGridHistoryActions.normalizeLastUpdateResidencePriceGridHistoriesAfterUpsert({
              residencePriceGridHistories
            });
          }),
          catchError(error => of(residencePriceGridHistoryActions.residencePriceGridHistoriesFailure({ error })))
        )
      )
    );
  });
}
