import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceStudyWork } from '@_model/interfaces/residence-study-work.model';
import { Observable } from 'rxjs';

export class GeneratedResidenceStudyWorkApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidenceStudyWorks(params?: any): Observable<ResidenceStudyWork[]> {
    return this.repo.getData<ResidenceStudyWork[]>('residence-study-work', params);
  }

  public getResidenceStudyWork(params: { idResidenceStudyWork: number; params?: any }): Observable<ResidenceStudyWork> {
    return this.repo.getData<ResidenceStudyWork>('residence-study-work/' + params.idResidenceStudyWork, params.params);
  }

  public addResidenceStudyWork(residenceStudyWork: Partial<ResidenceStudyWork>): Observable<ResidenceStudyWork> {
    return this.repo.create<ResidenceStudyWork>('residence-study-work', residenceStudyWork);
  }

  public updateResidenceStudyWork(residenceStudyWork: Partial<ResidenceStudyWork>): Observable<ResidenceStudyWork> {
    return this.repo.update('residence-study-work', residenceStudyWork);
  }

  public deleteResidenceStudyWork(idResidenceStudyWork: number): Observable<number> {
    return this.repo.delete('residence-study-work/' + +idResidenceStudyWork);
  }
}
