import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { GeneratedDocument, GeneratedDocumentEntityState } from '@_model/interfaces/generated-document.model';
import { GeneratedDocumentApiService } from '@_services/api/generated-document-api.service';
import * as generatedDocumentActions from '@_store/generated-document/generated-document.actions';
import { getActionsToNormalizeGeneratedDocument } from '@_config/store/normalization.generated';
import { selectGeneratedDocumentState } from './generated-document-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as generatedDocumentsCompanyActions from '@_store/generated-documents-company/generated-documents-company.actions';
import { GeneratedDocumentsCompany } from '@_model/interfaces/generated-documents-company.model';
import * as companyActions from '@_store/company/company.actions';
import { Company } from '@_model/interfaces/company.model';
import * as residenceActions from '@_store/residence/residence.actions';
import { Residence } from '@_model/interfaces/residence.model';
import * as generatedDocumentsResidenceActions from '@_store/generated-documents-residence/generated-documents-residence.actions';
import { GeneratedDocumentsResidence } from '@_model/interfaces/generated-documents-residence.model';
import * as companyCommunicationActions from '@_store/company-communication/company-communication.actions';
import { CompanyCommunication } from '@_model/interfaces/company-communication.model';
import * as companyCommunicationGeneratedDocumentActions from '@_store/company-communication-generated-document/company-communication-generated-document.actions';
import { CompanyCommunicationGeneratedDocument } from '@_model/interfaces/company-communication-generated-document.model';

export interface GeneratedDocumentRelationsIds {
  generatedDocumentsCompanies?: number | number[];
  companies?: number | number[];
  residences?: number | number[];
  generatedDocumentsResidences?: number | number[];
  companyCommunications?: number | number[];
  companyCommunicationGeneratedDocuments?: number | number[];
  organization?: number;
}

export function getDefaultAddGeneratedDocumentActions(
  generatedDocument: GeneratedDocumentEntityState,
  ids?: GeneratedDocumentRelationsIds
): Action[] {
  const actions: Action[] = [
    generatedDocumentActions.normalizeManyGeneratedDocumentsAfterUpsert({ generatedDocuments: [generatedDocument] })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyGeneratedDocumentSuccess({
        idOrganization: ids.organization,
        idGeneratedDocuments: [generatedDocument.idGeneratedDocument]
      })
    );
    actions.push(
      generatedDocumentActions.addOrganizationSuccess({
        idGeneratedDocument: generatedDocument.idGeneratedDocument,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.generatedDocumentsCompanies) {
    if (!Array.isArray(ids.generatedDocumentsCompanies)) {
      actions.push(
        generatedDocumentsCompanyActions.upsertOneGeneratedDocumentsCompany({
          generatedDocumentsCompany: {
            idGeneratedDocument: generatedDocument.idGeneratedDocument,
            idGeneratedDocumentsCompany: ids.generatedDocumentsCompanies as number
          } as GeneratedDocumentsCompany
        })
      );
      actions.push(
        generatedDocumentActions.addManyGeneratedDocumentsCompanySuccess({
          idGeneratedDocument: generatedDocument.idGeneratedDocument,
          idGeneratedDocumentsCompanies: [ids.generatedDocumentsCompanies as number]
        })
      );
    } else {
      actions.push(
        generatedDocumentsCompanyActions.upsertManyGeneratedDocumentsCompanies({
          generatedDocumentsCompanies: (ids.generatedDocumentsCompanies as number[]).map(
            (idGeneratedDocumentsCompany: number) => ({
              idGeneratedDocument: generatedDocument.idGeneratedDocument,
              idGeneratedDocumentsCompany
            })
          ) as GeneratedDocumentsCompany[]
        })
      );
      actions.push(
        generatedDocumentActions.addManyGeneratedDocumentsCompanySuccess({
          idGeneratedDocument: generatedDocument.idGeneratedDocument,
          idGeneratedDocumentsCompanies: ids.generatedDocumentsCompanies as number[]
        })
      );
    }
  }

  if (ids?.companies) {
    if (!Array.isArray(ids.companies)) {
      actions.push(
        companyActions.upsertOneCompany({
          company: {
            idGeneratedDocument: generatedDocument.idGeneratedDocument,
            idCompany: ids.companies as number
          } as Company & any
        })
      );
      actions.push(
        generatedDocumentActions.addManyCompanySuccess({
          idGeneratedDocument: generatedDocument.idGeneratedDocument,
          idCompanies: [ids.companies as number]
        })
      );
    } else {
      actions.push(
        companyActions.upsertManyCompanies({
          companies: (ids.companies as number[]).map((idCompany: number) => ({
            idGeneratedDocument: generatedDocument.idGeneratedDocument,
            idCompany
          })) as Company[] & any[]
        })
      );
      actions.push(
        generatedDocumentActions.addManyCompanySuccess({
          idGeneratedDocument: generatedDocument.idGeneratedDocument,
          idCompanies: ids.companies as number[]
        })
      );
    }
  }

  if (ids?.residences) {
    if (!Array.isArray(ids.residences)) {
      actions.push(
        residenceActions.upsertOneResidence({
          residence: {
            idGeneratedDocument: generatedDocument.idGeneratedDocument,
            idResidence: ids.residences as number
          } as Residence & any
        })
      );
      actions.push(
        generatedDocumentActions.addManyResidenceSuccess({
          idGeneratedDocument: generatedDocument.idGeneratedDocument,
          idResidences: [ids.residences as number]
        })
      );
    } else {
      actions.push(
        residenceActions.upsertManyResidences({
          residences: (ids.residences as number[]).map((idResidence: number) => ({
            idGeneratedDocument: generatedDocument.idGeneratedDocument,
            idResidence
          })) as Residence[] & any[]
        })
      );
      actions.push(
        generatedDocumentActions.addManyResidenceSuccess({
          idGeneratedDocument: generatedDocument.idGeneratedDocument,
          idResidences: ids.residences as number[]
        })
      );
    }
  }

  if (ids?.generatedDocumentsResidences) {
    if (!Array.isArray(ids.generatedDocumentsResidences)) {
      actions.push(
        generatedDocumentsResidenceActions.upsertOneGeneratedDocumentsResidence({
          generatedDocumentsResidence: {
            idGeneratedDocument: generatedDocument.idGeneratedDocument,
            idGeneratedDocumentsResidence: ids.generatedDocumentsResidences as number
          } as GeneratedDocumentsResidence
        })
      );
      actions.push(
        generatedDocumentActions.addManyGeneratedDocumentsResidenceSuccess({
          idGeneratedDocument: generatedDocument.idGeneratedDocument,
          idGeneratedDocumentsResidences: [ids.generatedDocumentsResidences as number]
        })
      );
    } else {
      actions.push(
        generatedDocumentsResidenceActions.upsertManyGeneratedDocumentsResidences({
          generatedDocumentsResidences: (ids.generatedDocumentsResidences as number[]).map(
            (idGeneratedDocumentsResidence: number) => ({
              idGeneratedDocument: generatedDocument.idGeneratedDocument,
              idGeneratedDocumentsResidence
            })
          ) as GeneratedDocumentsResidence[]
        })
      );
      actions.push(
        generatedDocumentActions.addManyGeneratedDocumentsResidenceSuccess({
          idGeneratedDocument: generatedDocument.idGeneratedDocument,
          idGeneratedDocumentsResidences: ids.generatedDocumentsResidences as number[]
        })
      );
    }
  }

  if (ids?.companyCommunications) {
    if (!Array.isArray(ids.companyCommunications)) {
      actions.push(
        companyCommunicationActions.upsertOneCompanyCommunication({
          companyCommunication: {
            idGeneratedDocument: generatedDocument.idGeneratedDocument,
            idCompanyCommunication: ids.companyCommunications as number
          } as CompanyCommunication & any
        })
      );
      actions.push(
        generatedDocumentActions.addManyCompanyCommunicationSuccess({
          idGeneratedDocument: generatedDocument.idGeneratedDocument,
          idCompanyCommunications: [ids.companyCommunications as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationActions.upsertManyCompanyCommunications({
          companyCommunications: (ids.companyCommunications as number[]).map((idCompanyCommunication: number) => ({
            idGeneratedDocument: generatedDocument.idGeneratedDocument,
            idCompanyCommunication
          })) as CompanyCommunication[] & any[]
        })
      );
      actions.push(
        generatedDocumentActions.addManyCompanyCommunicationSuccess({
          idGeneratedDocument: generatedDocument.idGeneratedDocument,
          idCompanyCommunications: ids.companyCommunications as number[]
        })
      );
    }
  }

  if (ids?.companyCommunicationGeneratedDocuments) {
    if (!Array.isArray(ids.companyCommunicationGeneratedDocuments)) {
      actions.push(
        companyCommunicationGeneratedDocumentActions.upsertOneCompanyCommunicationGeneratedDocument({
          companyCommunicationGeneratedDocument: {
            idGeneratedDocument: generatedDocument.idGeneratedDocument,
            idCompanyCommunicationGeneratedDocument: ids.companyCommunicationGeneratedDocuments as number
          } as CompanyCommunicationGeneratedDocument
        })
      );
      actions.push(
        generatedDocumentActions.addManyCompanyCommunicationGeneratedDocumentSuccess({
          idGeneratedDocument: generatedDocument.idGeneratedDocument,
          idCompanyCommunicationGeneratedDocuments: [ids.companyCommunicationGeneratedDocuments as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationGeneratedDocumentActions.upsertManyCompanyCommunicationGeneratedDocuments({
          companyCommunicationGeneratedDocuments: (ids.companyCommunicationGeneratedDocuments as number[]).map(
            (idCompanyCommunicationGeneratedDocument: number) => ({
              idGeneratedDocument: generatedDocument.idGeneratedDocument,
              idCompanyCommunicationGeneratedDocument
            })
          ) as CompanyCommunicationGeneratedDocument[]
        })
      );
      actions.push(
        generatedDocumentActions.addManyCompanyCommunicationGeneratedDocumentSuccess({
          idGeneratedDocument: generatedDocument.idGeneratedDocument,
          idCompanyCommunicationGeneratedDocuments: ids.companyCommunicationGeneratedDocuments as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteGeneratedDocumentActions(generatedDocument: GeneratedDocumentEntityState): Action[] {
  const actions: Action[] = [
    generatedDocumentActions.deleteOneGeneratedDocumentSuccess({
      idGeneratedDocument: generatedDocument.idGeneratedDocument
    })
  ];

  if (generatedDocument.organization) {
    actions.push(
      organizationActions.deleteManyGeneratedDocumentSuccess({
        idGeneratedDocuments: [generatedDocument.idGeneratedDocument],
        idOrganizations: [generatedDocument.organization as number]
      })
    );
  }

  if (generatedDocument.generatedDocumentsCompanies) {
    actions.push(
      generatedDocumentsCompanyActions.deleteManyGeneratedDocumentSuccess({
        idGeneratedDocuments: [generatedDocument.idGeneratedDocument],
        idGeneratedDocumentsCompanies: generatedDocument.generatedDocumentsCompanies as number[]
      })
    );
  }

  if (generatedDocument.companies) {
    actions.push(
      companyActions.deleteManyGeneratedDocumentSuccess({
        idGeneratedDocuments: [generatedDocument.idGeneratedDocument],
        idCompanies: generatedDocument.companies as number[]
      })
    );
  }

  if (generatedDocument.residences) {
    actions.push(
      residenceActions.deleteManyGeneratedDocumentSuccess({
        idGeneratedDocuments: [generatedDocument.idGeneratedDocument],
        idResidences: generatedDocument.residences as number[]
      })
    );
  }

  if (generatedDocument.generatedDocumentsResidences) {
    actions.push(
      generatedDocumentsResidenceActions.deleteManyGeneratedDocumentSuccess({
        idGeneratedDocuments: [generatedDocument.idGeneratedDocument],
        idGeneratedDocumentsResidences: generatedDocument.generatedDocumentsResidences as number[]
      })
    );
  }

  if (generatedDocument.companyCommunications) {
    actions.push(
      companyCommunicationActions.deleteManyGeneratedDocumentSuccess({
        idGeneratedDocuments: [generatedDocument.idGeneratedDocument],
        idCompanyCommunications: generatedDocument.companyCommunications as number[]
      })
    );
  }

  if (generatedDocument.companyCommunicationGeneratedDocuments) {
    actions.push(
      companyCommunicationGeneratedDocumentActions.deleteManyGeneratedDocumentSuccess({
        idGeneratedDocuments: [generatedDocument.idGeneratedDocument],
        idCompanyCommunicationGeneratedDocuments: generatedDocument.companyCommunicationGeneratedDocuments as number[]
      })
    );
  }

  return actions;
}

export class GeneratedGeneratedDocumentEffects {
  constructor(
    protected actions$: Actions,
    protected generatedDocumentApiService: GeneratedDocumentApiService,
    protected store$: Store<AppState>
  ) {}

  getManyGeneratedDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(generatedDocumentActions.getManyGeneratedDocuments),
      switchMap(({ params }) =>
        this.generatedDocumentApiService.getGeneratedDocuments(params).pipe(
          map((generatedDocuments: GeneratedDocument[]) => {
            return generatedDocumentActions.normalizeManyGeneratedDocumentsAfterUpsert({ generatedDocuments });
          }),
          catchError(error => of(generatedDocumentActions.generatedDocumentsFailure({ error })))
        )
      )
    );
  });

  getOneGeneratedDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(generatedDocumentActions.getOneGeneratedDocument),
      switchMap(idGeneratedDocument =>
        this.generatedDocumentApiService.getGeneratedDocument(idGeneratedDocument).pipe(
          map((generatedDocument: GeneratedDocument) => {
            return generatedDocumentActions.normalizeManyGeneratedDocumentsAfterUpsert({
              generatedDocuments: [generatedDocument]
            });
          }),
          catchError(error => of(generatedDocumentActions.generatedDocumentsFailure({ error })))
        )
      )
    );
  });

  upsertOneGeneratedDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(generatedDocumentActions.upsertOneGeneratedDocument),
      concatMap(
        ({
          generatedDocument,
          ids
        }: {
          generatedDocument: Partial<GeneratedDocument>;
          ids?: GeneratedDocumentRelationsIds;
        }) => {
          if (generatedDocument.idGeneratedDocument) {
            return this.generatedDocumentApiService.updateGeneratedDocument(generatedDocument).pipe(
              map((generatedDocumentReturned: GeneratedDocument) => {
                return generatedDocumentActions.normalizeManyGeneratedDocumentsAfterUpsert({
                  generatedDocuments: [generatedDocumentReturned]
                });
              }),
              catchError(error => of(generatedDocumentActions.generatedDocumentsFailure({ error })))
            );
          } else {
            return this.generatedDocumentApiService.addGeneratedDocument(generatedDocument).pipe(
              mergeMap((generatedDocumentReturned: GeneratedDocument) =>
                getDefaultAddGeneratedDocumentActions(generatedDocumentReturned, ids)
              ),
              catchError(error => of(generatedDocumentActions.generatedDocumentsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneGeneratedDocument$ = createEffect(() => {
    const selectGeneratedDocumentState$ = this.store$.select(selectGeneratedDocumentState);
    return this.actions$.pipe(
      ofType(generatedDocumentActions.deleteOneGeneratedDocument),
      withLatestFrom(selectGeneratedDocumentState$),
      concatMap(([{ idGeneratedDocument }, state]) =>
        this.generatedDocumentApiService.deleteGeneratedDocument(idGeneratedDocument).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteGeneratedDocumentActions(
                state.entities[idGeneratedDocument] as GeneratedDocumentEntityState
              ),
              generatedDocumentActions.deleteOneGeneratedDocument.type
            )
          ]),
          catchError(error => of(generatedDocumentActions.generatedDocumentsFailure({ error })))
        )
      )
    );
  });

  normalizeManyGeneratedDocumentsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(generatedDocumentActions.normalizeManyGeneratedDocumentsAfterUpsert),
      concatMap(({ generatedDocuments }) => {
        const actions: Action[] = getActionsToNormalizeGeneratedDocument(generatedDocuments, StoreActionType.upsert);
        return [getMultiAction(actions, '[GeneratedDocument] Normalization After Upsert Success')];
      })
    );
  });
}
