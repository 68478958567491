import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationEnergie, OrganizationEnergieEntityState } from '@_model/interfaces/organization-energie.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, organizationEnergieFeatureKey, OrganizationEnergieState } from './organization-energie.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationEnergieRelations: string[] = ['organizations'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationEnergieState =
  createFeatureSelector<OrganizationEnergieState>(organizationEnergieFeatureKey);

export const selectIsLoadedOrganizationEnergie = createSelector(
  selectOrganizationEnergieState,
  (state: OrganizationEnergieState) => state.isLoaded
);

export const selectIsLoadingOrganizationEnergie = createSelector(
  selectOrganizationEnergieState,
  (state: OrganizationEnergieState) => state.isLoading
);

export const selectIsReadyOrganizationEnergie = createSelector(
  selectOrganizationEnergieState,
  (state: OrganizationEnergieState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationEnergie = createSelector(
  selectOrganizationEnergieState,
  (state: OrganizationEnergieState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationEnergieModel: SelectorModel = {
  name: 'organizationEnergies',
  getSelector: selectAllOrganizationEnergiesDictionary,
  isReady: selectIsReadyOrganizationEnergie
};

export const selectOrganizationEnergiesEntities = createSelector(selectOrganizationEnergieState, selectEntities);

export const selectOrganizationEnergiesArray = createSelector(selectOrganizationEnergieState, selectAll);

export const selectIdOrganizationEnergiesActive = createSelector(
  selectOrganizationEnergieState,
  (state: OrganizationEnergieState) => state.actives
);

const organizationEnergiesInObject = (organizationEnergies: Dictionary<OrganizationEnergieEntityState>) => ({
  organizationEnergies
});

const selectOrganizationEnergiesEntitiesDictionary = createSelector(
  selectOrganizationEnergiesEntities,
  organizationEnergiesInObject
);

const selectAllOrganizationEnergiesObject = createSelector(selectOrganizationEnergiesEntities, organizationEnergies => {
  return hydrateAll({ organizationEnergies });
});

const selectOneOrganizationEnergieDictionary = (idOrganizationEnergie: number) =>
  createSelector(selectOrganizationEnergiesEntities, organizationEnergies => {
    return { organizationEnergies: { [idOrganizationEnergie]: organizationEnergies[idOrganizationEnergie] } };
  });

const selectOneOrganizationEnergieDictionaryWithoutChild = (idOrganizationEnergie: number) =>
  createSelector(selectOrganizationEnergiesEntities, organizationEnergies => {
    return { organizationEnergie: organizationEnergies[idOrganizationEnergie] };
  });

const selectActiveOrganizationEnergiesEntities = createSelector(
  selectIdOrganizationEnergiesActive,
  selectOrganizationEnergiesEntities,
  (actives: number[], organizationEnergies: Dictionary<OrganizationEnergieEntityState>) =>
    getOrganizationEnergiesFromActives(actives, organizationEnergies)
);

function getOrganizationEnergiesFromActives(
  actives: number[],
  organizationEnergies: Dictionary<OrganizationEnergieEntityState>
): Dictionary<OrganizationEnergieEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationEnergies[idActive]) {
      acc[idActive] = organizationEnergies[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationEnergieEntityState>);
}

const selectAllOrganizationEnergiesSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationEnergies(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationEnergie>(
      schema,
      selectAllOrganizationEnergiesSelectors,
      selectOrganizationEnergiesEntitiesDictionary,
      getRelationSelectors,
      organizationEnergieRelations,
      hydrateAll,
      'organizationEnergie'
    );
  } else {
    return selectAllOrganizationEnergiesObject;
  }
}

export function selectAllOrganizationEnergiesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationEnergies'
): Selector {
  return createSelector(selectAllOrganizationEnergies(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationEnergieEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationEnergies.length; i++) {
      res[customKey][result.organizationEnergies[i].idOrganizationEnergie] = result.organizationEnergies[i];
    }
    return res;
  });
}

export function selectOneOrganizationEnergie(schema: SelectSchema = {}, idOrganizationEnergie: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationEnergieDictionary(idOrganizationEnergie)];
    selectors.push(...getRelationSelectors(schema, organizationEnergieRelations, 'organizationEnergie'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationEnergieDictionaryWithoutChild(idOrganizationEnergie);
  }
}

export function selectActiveOrganizationEnergies(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationEnergiesEntities, organizationEnergies => ({ organizationEnergies }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationEnergieRelations, 'organizationEnergie'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationEnergies: Dictionary<OrganizationEnergieEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationEnergies: (OrganizationEnergie | null)[] } {
  const { organizationEnergies, organizations } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationEnergies: Object.keys(organizationEnergies).map(idOrganizationEnergie =>
      hydrate(organizationEnergies[idOrganizationEnergie] as OrganizationEnergieEntityState, organizations)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationEnergie: OrganizationEnergieEntityState | null } {
  const { organizationEnergies, organizations } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationEnergie = Object.values(organizationEnergies)[0];
  return { organizationEnergie: hydrate(organizationEnergie as OrganizationEnergieEntityState, organizations) };
}

function hydrate(
  organizationEnergie: OrganizationEnergieEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>
): OrganizationEnergie | null {
  if (!organizationEnergie) {
    return null;
  }

  const organizationEnergieHydrated: OrganizationEnergieEntityState = { ...organizationEnergie };
  if (organizationEntities) {
    organizationEnergieHydrated.organization = organizationEntities[
      organizationEnergie.organization as number
    ] as Organization;
  } else {
    delete organizationEnergieHydrated.organization;
  }

  return organizationEnergieHydrated as OrganizationEnergie;
}
