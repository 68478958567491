import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { ResidenceShareRcp, ResidenceShareRcpEntityState } from '@_model/interfaces/residence-share-rcp.model';
import { ResidenceShareRcpApiService } from '@_services/api/residence-share-rcp-api.service';
import * as residenceShareRcpActions from '@_store/residence-share-rcp/residence-share-rcp.actions';
import { getActionsToNormalizeResidenceShareRcp } from '@_config/store/normalization.generated';
import { selectResidenceShareRcpState } from './residence-share-rcp-generated.selectors';
import * as stratalotRcpActions from '@_store/stratalot-rcp/stratalot-rcp.actions';

export interface ResidenceShareRcpRelationsIds {
  stratalotRcp?: number;
}

export function getDefaultAddResidenceShareRcpActions(
  residenceShareRcp: ResidenceShareRcpEntityState,
  ids?: ResidenceShareRcpRelationsIds
): Action[] {
  const actions: Action[] = [
    residenceShareRcpActions.normalizeManyResidenceShareRcpsAfterUpsert({ residenceShareRcps: [residenceShareRcp] })
  ];

  if (ids?.stratalotRcp) {
    actions.push(
      stratalotRcpActions.addManyResidenceShareRcpSuccess({
        idStratalotRcp: ids.stratalotRcp,
        idResidenceShareRcps: [residenceShareRcp.idResidenceShareRcp]
      })
    );
    actions.push(
      residenceShareRcpActions.addStratalotRcpSuccess({
        idResidenceShareRcp: residenceShareRcp.idResidenceShareRcp,
        idStratalotRcp: ids.stratalotRcp
      })
    );
  }

  return actions;
}

export function getDefaultDeleteResidenceShareRcpActions(residenceShareRcp: ResidenceShareRcpEntityState): Action[] {
  const actions: Action[] = [
    residenceShareRcpActions.deleteOneResidenceShareRcpSuccess({
      idResidenceShareRcp: residenceShareRcp.idResidenceShareRcp
    })
  ];

  if (residenceShareRcp.stratalotRcp) {
    actions.push(
      stratalotRcpActions.deleteManyResidenceShareRcpSuccess({
        idResidenceShareRcps: [residenceShareRcp.idResidenceShareRcp],
        idStratalotRcps: [residenceShareRcp.stratalotRcp as number]
      })
    );
  }

  return actions;
}

export class GeneratedResidenceShareRcpEffects {
  constructor(
    protected actions$: Actions,
    protected residenceShareRcpApiService: ResidenceShareRcpApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidenceShareRcps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceShareRcpActions.getManyResidenceShareRcps),
      switchMap(({ params }) =>
        this.residenceShareRcpApiService.getResidenceShareRcps(params).pipe(
          map((residenceShareRcps: ResidenceShareRcp[]) => {
            return residenceShareRcpActions.normalizeManyResidenceShareRcpsAfterUpsert({ residenceShareRcps });
          }),
          catchError(error => of(residenceShareRcpActions.residenceShareRcpsFailure({ error })))
        )
      )
    );
  });

  getOneResidenceShareRcp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceShareRcpActions.getOneResidenceShareRcp),
      switchMap(idResidenceShareRcp =>
        this.residenceShareRcpApiService.getResidenceShareRcp(idResidenceShareRcp).pipe(
          map((residenceShareRcp: ResidenceShareRcp) => {
            return residenceShareRcpActions.normalizeManyResidenceShareRcpsAfterUpsert({
              residenceShareRcps: [residenceShareRcp]
            });
          }),
          catchError(error => of(residenceShareRcpActions.residenceShareRcpsFailure({ error })))
        )
      )
    );
  });

  upsertOneResidenceShareRcp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceShareRcpActions.upsertOneResidenceShareRcp),
      concatMap(
        ({
          residenceShareRcp,
          ids
        }: {
          residenceShareRcp: Partial<ResidenceShareRcp>;
          ids?: ResidenceShareRcpRelationsIds;
        }) => {
          if (residenceShareRcp.idResidenceShareRcp) {
            return this.residenceShareRcpApiService.updateResidenceShareRcp(residenceShareRcp).pipe(
              map((residenceShareRcpReturned: ResidenceShareRcp) => {
                return residenceShareRcpActions.normalizeManyResidenceShareRcpsAfterUpsert({
                  residenceShareRcps: [residenceShareRcpReturned]
                });
              }),
              catchError(error => of(residenceShareRcpActions.residenceShareRcpsFailure({ error })))
            );
          } else {
            return this.residenceShareRcpApiService.addResidenceShareRcp(residenceShareRcp).pipe(
              mergeMap((residenceShareRcpReturned: ResidenceShareRcp) =>
                getDefaultAddResidenceShareRcpActions(residenceShareRcpReturned, ids)
              ),
              catchError(error => of(residenceShareRcpActions.residenceShareRcpsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneResidenceShareRcp$ = createEffect(() => {
    const selectResidenceShareRcpState$ = this.store$.select(selectResidenceShareRcpState);
    return this.actions$.pipe(
      ofType(residenceShareRcpActions.deleteOneResidenceShareRcp),
      withLatestFrom(selectResidenceShareRcpState$),
      concatMap(([{ idResidenceShareRcp }, state]) =>
        this.residenceShareRcpApiService.deleteResidenceShareRcp(idResidenceShareRcp).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidenceShareRcpActions(
                state.entities[idResidenceShareRcp] as ResidenceShareRcpEntityState
              ),
              residenceShareRcpActions.deleteOneResidenceShareRcp.type
            )
          ]),
          catchError(error => of(residenceShareRcpActions.residenceShareRcpsFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidenceShareRcpsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceShareRcpActions.normalizeManyResidenceShareRcpsAfterUpsert),
      concatMap(({ residenceShareRcps }) => {
        const actions: Action[] = getActionsToNormalizeResidenceShareRcp(residenceShareRcps, StoreActionType.upsert);
        return [getMultiAction(actions, '[ResidenceShareRcp] Normalization After Upsert Success')];
      })
    );
  });
}
