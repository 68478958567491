import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  CompanyCommunicationGeneratedDocument,
  CompanyCommunicationGeneratedDocumentEntityState
} from '@_model/interfaces/company-communication-generated-document.model';
import { CompanyCommunicationGeneratedDocumentRelationsIds } from './company-communication-generated-document-generated.effects';

export const getOneCompanyCommunicationGeneratedDocument = createAction(
  '[CompanyCommunicationGeneratedDocument] Get One CompanyCommunicationGeneratedDocument',
  props<{ idCompanyCommunicationGeneratedDocument: number; params?: any }>()
);

export const getManyCompanyCommunicationGeneratedDocuments = createAction(
  '[CompanyCommunicationGeneratedDocument] Get Many CompanyCommunicationGeneratedDocuments',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyCommunicationGeneratedDocument] Add Many Actives CompanyCommunicationGeneratedDocument',
  props<{ idCompanyCommunicationGeneratedDocuments: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyCommunicationGeneratedDocument] Delete One Active CompanyCommunicationGeneratedDocument',
  props<{ idCompanyCommunicationGeneratedDocument: number }>()
);

export const clearActive = createAction(
  '[CompanyCommunicationGeneratedDocument] Clear Active CompanyCommunicationGeneratedDocument'
);

export const upsertOneCompanyCommunicationGeneratedDocument = createAction(
  '[CompanyCommunicationGeneratedDocument] Upsert One CompanyCommunicationGeneratedDocument',
  props<{
    companyCommunicationGeneratedDocument: Partial<CompanyCommunicationGeneratedDocument>;
    ids?: CompanyCommunicationGeneratedDocumentRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationGeneratedDocuments = createAction(
  '[CompanyCommunicationGeneratedDocument] Upsert Many CompanyCommunicationGeneratedDocuments',
  props<{
    companyCommunicationGeneratedDocuments: Partial<CompanyCommunicationGeneratedDocument>[];
    ids?: CompanyCommunicationGeneratedDocumentRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationGeneratedDocumentsSuccess = createAction(
  '[CompanyCommunicationGeneratedDocument] Create Many CompanyCommunicationGeneratedDocuments Success',
  props<{ companyCommunicationGeneratedDocuments: CompanyCommunicationGeneratedDocumentEntityState[] }>()
);

export const deleteOneCompanyCommunicationGeneratedDocument = createAction(
  '[CompanyCommunicationGeneratedDocument] Delete One CompanyCommunicationGeneratedDocument',
  props<{ idCompanyCommunicationGeneratedDocument: number }>()
);

export const deleteOneCompanyCommunicationGeneratedDocumentSuccess = createAction(
  '[CompanyCommunicationGeneratedDocument] Delete One CompanyCommunicationGeneratedDocument Success',
  props<{ idCompanyCommunicationGeneratedDocument: number }>()
);

export const normalizeManyCompanyCommunicationGeneratedDocumentsAfterUpsert = createAction(
  '[CompanyCommunicationGeneratedDocument] Normalize Many CompanyCommunicationGeneratedDocuments After Upsert',
  props<{ companyCommunicationGeneratedDocuments: CompanyCommunicationGeneratedDocumentEntityState[] }>()
);

export const companyCommunicationGeneratedDocumentsFailure = createAction(
  '[CompanyCommunicationGeneratedDocument] CompanyCommunicationGeneratedDocuments Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction(
  '[CompanyCommunicationGeneratedDocument] Clear CompanyCommunicationGeneratedDocuments'
);

export const addCompanyCommunicationSuccess = createAction(
  '[CompanyCommunicationGeneratedDocument] Add CompanyCommunication',
  props<{ idCompanyCommunicationGeneratedDocument: number; idCompanyCommunication: number }>()
);

export const deleteManyCompanyCommunicationSuccess = createAction(
  '[CompanyCommunicationGeneratedDocument] Delete Many CompanyCommunication',
  props<{ idCompanyCommunications: number[]; idCompanyCommunicationGeneratedDocuments: number[] }>()
);

export const addGeneratedDocumentSuccess = createAction(
  '[CompanyCommunicationGeneratedDocument] Add GeneratedDocument',
  props<{ idCompanyCommunicationGeneratedDocument: number; idGeneratedDocument: number }>()
);

export const deleteManyGeneratedDocumentSuccess = createAction(
  '[CompanyCommunicationGeneratedDocument] Delete Many GeneratedDocument',
  props<{ idGeneratedDocuments: number[]; idCompanyCommunicationGeneratedDocuments: number[] }>()
);
