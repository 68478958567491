import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceStudyFounding } from '@_model/interfaces/residence-study-founding.model';
import { Observable } from 'rxjs';

export class GeneratedResidenceStudyFoundingApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidenceStudyFoundings(params?: any): Observable<ResidenceStudyFounding[]> {
    return this.repo.getData<ResidenceStudyFounding[]>('residence-study-founding', params);
  }

  public getResidenceStudyFounding(params: {
    idResidenceStudyFounding: number;
    params?: any;
  }): Observable<ResidenceStudyFounding> {
    return this.repo.getData<ResidenceStudyFounding>(
      'residence-study-founding/' + params.idResidenceStudyFounding,
      params.params
    );
  }

  public addResidenceStudyFounding(
    residenceStudyFounding: Partial<ResidenceStudyFounding>
  ): Observable<ResidenceStudyFounding> {
    return this.repo.create<ResidenceStudyFounding>('residence-study-founding', residenceStudyFounding);
  }

  public updateResidenceStudyFounding(
    residenceStudyFounding: Partial<ResidenceStudyFounding>
  ): Observable<ResidenceStudyFounding> {
    return this.repo.update('residence-study-founding', residenceStudyFounding);
  }

  public deleteResidenceStudyFounding(idResidenceStudyFounding: number): Observable<number> {
    return this.repo.delete('residence-study-founding/' + +idResidenceStudyFounding);
  }
}
