import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationResidenceTodoRuleEntityState } from '@_model/interfaces/organization-residence-todo-rule.model';

export interface OrganizationResidenceTodoRuleState extends EntityState<OrganizationResidenceTodoRuleEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationResidenceTodoRuleEntityState> =
  createEntityAdapter<OrganizationResidenceTodoRuleEntityState>({
    selectId: o => o.idOrganizationResidenceTodoRule
  });
export const initialState: OrganizationResidenceTodoRuleState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationResidenceTodoRuleFeatureKey = 'organizationResidenceTodoRule';
