import { RepositoryService } from '@_services/api/repository.service';
import { Organization } from '@_model/interfaces/organization.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizations(params?: any): Observable<Organization[]> {
    return this.repo.getData<Organization[]>('organization', params);
  }

  public getOrganization(params: { idOrganization: number; params?: any }): Observable<Organization> {
    return this.repo.getData<Organization>('organization/' + params.idOrganization, params.params);
  }

  public addOrganization(organization: Partial<Organization>): Observable<Organization> {
    return this.repo.create<Organization>('organization', organization);
  }

  public updateOrganization(organization: Partial<Organization>): Observable<Organization> {
    return this.repo.update('organization', organization);
  }

  public deleteOrganization(idOrganization: number): Observable<number> {
    return this.repo.delete('organization/' + +idOrganization);
  }
}
