import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationSociete, OrganizationSocieteEntityState } from '@_model/interfaces/organization-societe.model';
import {
  OrganizationThirdParty,
  OrganizationThirdPartyEntityState
} from '@_model/interfaces/organization-third-party.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, organizationSocieteFeatureKey, OrganizationSocieteState } from './organization-societe.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationSocieteRelations: string[] = ['organizationThirdParties', 'organizations'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationSocieteState =
  createFeatureSelector<OrganizationSocieteState>(organizationSocieteFeatureKey);

export const selectIsLoadedOrganizationSociete = createSelector(
  selectOrganizationSocieteState,
  (state: OrganizationSocieteState) => state.isLoaded
);

export const selectIsLoadingOrganizationSociete = createSelector(
  selectOrganizationSocieteState,
  (state: OrganizationSocieteState) => state.isLoading
);

export const selectIsReadyOrganizationSociete = createSelector(
  selectOrganizationSocieteState,
  (state: OrganizationSocieteState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationSociete = createSelector(
  selectOrganizationSocieteState,
  (state: OrganizationSocieteState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationSocieteModel: SelectorModel = {
  name: 'organizationSocietes',
  getSelector: selectAllOrganizationSocietesDictionary,
  isReady: selectIsReadyOrganizationSociete
};

export const selectOrganizationSocietesEntities = createSelector(selectOrganizationSocieteState, selectEntities);

export const selectOrganizationSocietesArray = createSelector(selectOrganizationSocieteState, selectAll);

export const selectIdOrganizationSocietesActive = createSelector(
  selectOrganizationSocieteState,
  (state: OrganizationSocieteState) => state.actives
);

const organizationSocietesInObject = (organizationSocietes: Dictionary<OrganizationSocieteEntityState>) => ({
  organizationSocietes
});

const selectOrganizationSocietesEntitiesDictionary = createSelector(
  selectOrganizationSocietesEntities,
  organizationSocietesInObject
);

const selectAllOrganizationSocietesObject = createSelector(selectOrganizationSocietesEntities, organizationSocietes => {
  return hydrateAll({ organizationSocietes });
});

const selectOneOrganizationSocieteDictionary = (idOrganizationSociete: number) =>
  createSelector(selectOrganizationSocietesEntities, organizationSocietes => {
    return { organizationSocietes: { [idOrganizationSociete]: organizationSocietes[idOrganizationSociete] } };
  });

const selectOneOrganizationSocieteDictionaryWithoutChild = (idOrganizationSociete: number) =>
  createSelector(selectOrganizationSocietesEntities, organizationSocietes => {
    return { organizationSociete: organizationSocietes[idOrganizationSociete] };
  });

const selectActiveOrganizationSocietesEntities = createSelector(
  selectIdOrganizationSocietesActive,
  selectOrganizationSocietesEntities,
  (actives: number[], organizationSocietes: Dictionary<OrganizationSocieteEntityState>) =>
    getOrganizationSocietesFromActives(actives, organizationSocietes)
);

function getOrganizationSocietesFromActives(
  actives: number[],
  organizationSocietes: Dictionary<OrganizationSocieteEntityState>
): Dictionary<OrganizationSocieteEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationSocietes[idActive]) {
      acc[idActive] = organizationSocietes[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationSocieteEntityState>);
}

const selectAllOrganizationSocietesSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationSocietes(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationSociete>(
      schema,
      selectAllOrganizationSocietesSelectors,
      selectOrganizationSocietesEntitiesDictionary,
      getRelationSelectors,
      organizationSocieteRelations,
      hydrateAll,
      'organizationSociete'
    );
  } else {
    return selectAllOrganizationSocietesObject;
  }
}

export function selectAllOrganizationSocietesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationSocietes'
): Selector {
  return createSelector(selectAllOrganizationSocietes(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationSocieteEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationSocietes.length; i++) {
      res[customKey][result.organizationSocietes[i].idOrganizationSociete] = result.organizationSocietes[i];
    }
    return res;
  });
}

export function selectOneOrganizationSociete(schema: SelectSchema = {}, idOrganizationSociete: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationSocieteDictionary(idOrganizationSociete)];
    selectors.push(...getRelationSelectors(schema, organizationSocieteRelations, 'organizationSociete'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationSocieteDictionaryWithoutChild(idOrganizationSociete);
  }
}

export function selectActiveOrganizationSocietes(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationSocietesEntities, organizationSocietes => ({ organizationSocietes }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationSocieteRelations, 'organizationSociete'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationSocietes: Dictionary<OrganizationSocieteEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  organizationThirdParties?: Dictionary<OrganizationThirdPartyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationSocietes: (OrganizationSociete | null)[] } {
  const { organizationSocietes, organizations, organizationThirdParties } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationSocietes: Object.keys(organizationSocietes).map(idOrganizationSociete =>
      hydrate(
        organizationSocietes[idOrganizationSociete] as OrganizationSocieteEntityState,
        organizations,
        organizationThirdParties
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationSociete: OrganizationSocieteEntityState | null } {
  const { organizationSocietes, organizations, organizationThirdParties } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationSociete = Object.values(organizationSocietes)[0];
  return {
    organizationSociete: hydrate(
      organizationSociete as OrganizationSocieteEntityState,
      organizations,
      organizationThirdParties
    )
  };
}

function hydrate(
  organizationSociete: OrganizationSocieteEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  organizationThirdPartyEntities?: Dictionary<OrganizationThirdPartyEntityState>
): OrganizationSociete | null {
  if (!organizationSociete) {
    return null;
  }

  const organizationSocieteHydrated: OrganizationSocieteEntityState = { ...organizationSociete };
  if (organizationEntities) {
    organizationSocieteHydrated.organization = organizationEntities[
      organizationSociete.organization as number
    ] as Organization;
  } else {
    delete organizationSocieteHydrated.organization;
  }

  if (organizationThirdPartyEntities) {
    organizationSocieteHydrated.organizationThirdParties = (
      (organizationSocieteHydrated.organizationThirdParties as number[]) || []
    ).map(id => organizationThirdPartyEntities[id]) as OrganizationThirdParty[];
  } else {
    delete organizationSocieteHydrated.organizationThirdParties;
  }

  return organizationSocieteHydrated as OrganizationSociete;
}
