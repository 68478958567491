import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ProfilEntityState } from '@_model/interfaces/profil.model';

export interface ProfilState extends EntityState<ProfilEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ProfilEntityState> = createEntityAdapter<ProfilEntityState>({
  selectId: o => o.idProfil
});
export const initialState: ProfilState = adapter.getInitialState({ isLoaded: false, isLoading: false, actives: [] });

export const profilFeatureKey = 'profil';
