import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidenceStudyWorkEntityState } from '@_model/interfaces/residence-study-work.model';

export interface ResidenceStudyWorkState extends EntityState<ResidenceStudyWorkEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidenceStudyWorkEntityState> = createEntityAdapter<ResidenceStudyWorkEntityState>(
  {
    selectId: o => o.idResidenceStudyWork
  }
);
export const initialState: ResidenceStudyWorkState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residenceStudyWorkFeatureKey = 'residenceStudyWork';
