import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ResidenceStudyCriteria,
  ResidenceStudyCriteriaEntityState
} from '@_model/interfaces/residence-study-criteria.model';
import { ResidenceStudy, ResidenceStudyEntityState } from '@_model/interfaces/residence-study.model';
import { CompanyStudyCriteria, CompanyStudyCriteriaEntityState } from '@_model/interfaces/company-study-criteria.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  residenceStudyCriteriaFeatureKey,
  ResidenceStudyCriteriaState
} from './residence-study-criteria.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residenceStudyCriteriaRelations: string[] = ['residenceStudies', 'companyStudyCriterias'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidenceStudyCriteriaState = createFeatureSelector<ResidenceStudyCriteriaState>(
  residenceStudyCriteriaFeatureKey
);

export const selectIsLoadedResidenceStudyCriteria = createSelector(
  selectResidenceStudyCriteriaState,
  (state: ResidenceStudyCriteriaState) => state.isLoaded
);

export const selectIsLoadingResidenceStudyCriteria = createSelector(
  selectResidenceStudyCriteriaState,
  (state: ResidenceStudyCriteriaState) => state.isLoading
);

export const selectIsReadyResidenceStudyCriteria = createSelector(
  selectResidenceStudyCriteriaState,
  (state: ResidenceStudyCriteriaState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidenceStudyCriteria = createSelector(
  selectResidenceStudyCriteriaState,
  (state: ResidenceStudyCriteriaState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidenceStudyCriteriaModel: SelectorModel = {
  name: 'residenceStudyCriterias',
  getSelector: selectAllResidenceStudyCriteriasDictionary,
  isReady: selectIsReadyResidenceStudyCriteria
};

export const selectResidenceStudyCriteriasEntities = createSelector(selectResidenceStudyCriteriaState, selectEntities);

export const selectResidenceStudyCriteriasArray = createSelector(selectResidenceStudyCriteriaState, selectAll);

export const selectIdResidenceStudyCriteriasActive = createSelector(
  selectResidenceStudyCriteriaState,
  (state: ResidenceStudyCriteriaState) => state.actives
);

const residenceStudyCriteriasInObject = (residenceStudyCriterias: Dictionary<ResidenceStudyCriteriaEntityState>) => ({
  residenceStudyCriterias
});

const selectResidenceStudyCriteriasEntitiesDictionary = createSelector(
  selectResidenceStudyCriteriasEntities,
  residenceStudyCriteriasInObject
);

const selectAllResidenceStudyCriteriasObject = createSelector(
  selectResidenceStudyCriteriasEntities,
  residenceStudyCriterias => {
    return hydrateAll({ residenceStudyCriterias });
  }
);

const selectOneResidenceStudyCriteriaDictionary = (idResidenceStudyCriteria: number) =>
  createSelector(selectResidenceStudyCriteriasEntities, residenceStudyCriterias => {
    return {
      residenceStudyCriterias: { [idResidenceStudyCriteria]: residenceStudyCriterias[idResidenceStudyCriteria] }
    };
  });

const selectOneResidenceStudyCriteriaDictionaryWithoutChild = (idResidenceStudyCriteria: number) =>
  createSelector(selectResidenceStudyCriteriasEntities, residenceStudyCriterias => {
    return { residenceStudyCriteria: residenceStudyCriterias[idResidenceStudyCriteria] };
  });

const selectActiveResidenceStudyCriteriasEntities = createSelector(
  selectIdResidenceStudyCriteriasActive,
  selectResidenceStudyCriteriasEntities,
  (actives: number[], residenceStudyCriterias: Dictionary<ResidenceStudyCriteriaEntityState>) =>
    getResidenceStudyCriteriasFromActives(actives, residenceStudyCriterias)
);

function getResidenceStudyCriteriasFromActives(
  actives: number[],
  residenceStudyCriterias: Dictionary<ResidenceStudyCriteriaEntityState>
): Dictionary<ResidenceStudyCriteriaEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residenceStudyCriterias[idActive]) {
      acc[idActive] = residenceStudyCriterias[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidenceStudyCriteriaEntityState>);
}

const selectAllResidenceStudyCriteriasSelectors: Dictionary<Selector> = {};
export function selectAllResidenceStudyCriterias(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidenceStudyCriteria>(
      schema,
      selectAllResidenceStudyCriteriasSelectors,
      selectResidenceStudyCriteriasEntitiesDictionary,
      getRelationSelectors,
      residenceStudyCriteriaRelations,
      hydrateAll,
      'residenceStudyCriteria'
    );
  } else {
    return selectAllResidenceStudyCriteriasObject;
  }
}

export function selectAllResidenceStudyCriteriasDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residenceStudyCriterias'
): Selector {
  return createSelector(selectAllResidenceStudyCriterias(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidenceStudyCriteriaEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residenceStudyCriterias.length; i++) {
      res[customKey][result.residenceStudyCriterias[i].idResidenceStudyCriteria] = result.residenceStudyCriterias[i];
    }
    return res;
  });
}

export function selectOneResidenceStudyCriteria(schema: SelectSchema = {}, idResidenceStudyCriteria: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidenceStudyCriteriaDictionary(idResidenceStudyCriteria)];
    selectors.push(...getRelationSelectors(schema, residenceStudyCriteriaRelations, 'residenceStudyCriteria'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidenceStudyCriteriaDictionaryWithoutChild(idResidenceStudyCriteria);
  }
}

export function selectActiveResidenceStudyCriterias(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidenceStudyCriteriasEntities, residenceStudyCriterias => ({
      residenceStudyCriterias
    }))
  ];
  selectors.push(...getRelationSelectors(schema, residenceStudyCriteriaRelations, 'residenceStudyCriteria'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residenceStudyCriterias: Dictionary<ResidenceStudyCriteriaEntityState>;
  residenceStudies?: Dictionary<ResidenceStudyEntityState>;
  companyStudyCriterias?: Dictionary<CompanyStudyCriteriaEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { residenceStudyCriterias: (ResidenceStudyCriteria | null)[] } {
  const { residenceStudyCriterias, residenceStudies, companyStudyCriterias } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    residenceStudyCriterias: Object.keys(residenceStudyCriterias).map(idResidenceStudyCriteria =>
      hydrate(
        residenceStudyCriterias[idResidenceStudyCriteria] as ResidenceStudyCriteriaEntityState,
        residenceStudies,
        companyStudyCriterias
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { residenceStudyCriteria: ResidenceStudyCriteriaEntityState | null } {
  const { residenceStudyCriterias, residenceStudies, companyStudyCriterias } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const residenceStudyCriteria = Object.values(residenceStudyCriterias)[0];
  return {
    residenceStudyCriteria: hydrate(
      residenceStudyCriteria as ResidenceStudyCriteriaEntityState,
      residenceStudies,
      companyStudyCriterias
    )
  };
}

function hydrate(
  residenceStudyCriteria: ResidenceStudyCriteriaEntityState,
  residenceStudyEntities?: Dictionary<ResidenceStudyEntityState>,
  companyStudyCriteriaEntities?: Dictionary<CompanyStudyCriteriaEntityState>
): ResidenceStudyCriteria | null {
  if (!residenceStudyCriteria) {
    return null;
  }

  const residenceStudyCriteriaHydrated: ResidenceStudyCriteriaEntityState = { ...residenceStudyCriteria };
  if (residenceStudyEntities) {
    residenceStudyCriteriaHydrated.residenceStudy = residenceStudyEntities[
      residenceStudyCriteria.residenceStudy as number
    ] as ResidenceStudy;
  } else {
    delete residenceStudyCriteriaHydrated.residenceStudy;
  }
  if (companyStudyCriteriaEntities) {
    residenceStudyCriteriaHydrated.companyStudyCriteria = companyStudyCriteriaEntities[
      residenceStudyCriteria.companyStudyCriteria as number
    ] as CompanyStudyCriteria;
  } else {
    delete residenceStudyCriteriaHydrated.companyStudyCriteria;
  }

  return residenceStudyCriteriaHydrated as ResidenceStudyCriteria;
}
