import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CompanyStratalotVacant,
  CompanyStratalotVacantEntityState
} from '@_model/interfaces/company-stratalot-vacant.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  companyStratalotVacantFeatureKey,
  CompanyStratalotVacantState
} from './company-stratalot-vacant.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyStratalotVacantRelations: string[] = ['stratalots', 'companies'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyStratalotVacantState = createFeatureSelector<CompanyStratalotVacantState>(
  companyStratalotVacantFeatureKey
);

export const selectIsLoadedCompanyStratalotVacant = createSelector(
  selectCompanyStratalotVacantState,
  (state: CompanyStratalotVacantState) => state.isLoaded
);

export const selectIsLoadingCompanyStratalotVacant = createSelector(
  selectCompanyStratalotVacantState,
  (state: CompanyStratalotVacantState) => state.isLoading
);

export const selectIsReadyCompanyStratalotVacant = createSelector(
  selectCompanyStratalotVacantState,
  (state: CompanyStratalotVacantState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyStratalotVacant = createSelector(
  selectCompanyStratalotVacantState,
  (state: CompanyStratalotVacantState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyStratalotVacantModel: SelectorModel = {
  name: 'companyStratalotVacants',
  getSelector: selectAllCompanyStratalotVacantsDictionary,
  isReady: selectIsReadyCompanyStratalotVacant
};

export const selectCompanyStratalotVacantsEntities = createSelector(selectCompanyStratalotVacantState, selectEntities);

export const selectCompanyStratalotVacantsArray = createSelector(selectCompanyStratalotVacantState, selectAll);

export const selectIdCompanyStratalotVacantsActive = createSelector(
  selectCompanyStratalotVacantState,
  (state: CompanyStratalotVacantState) => state.actives
);

const companyStratalotVacantsInObject = (companyStratalotVacants: Dictionary<CompanyStratalotVacantEntityState>) => ({
  companyStratalotVacants
});

const selectCompanyStratalotVacantsEntitiesDictionary = createSelector(
  selectCompanyStratalotVacantsEntities,
  companyStratalotVacantsInObject
);

const selectAllCompanyStratalotVacantsObject = createSelector(
  selectCompanyStratalotVacantsEntities,
  companyStratalotVacants => {
    return hydrateAll({ companyStratalotVacants });
  }
);

const selectOneCompanyStratalotVacantDictionary = (idCompanyStratalotVacant: number) =>
  createSelector(selectCompanyStratalotVacantsEntities, companyStratalotVacants => {
    return {
      companyStratalotVacants: { [idCompanyStratalotVacant]: companyStratalotVacants[idCompanyStratalotVacant] }
    };
  });

const selectOneCompanyStratalotVacantDictionaryWithoutChild = (idCompanyStratalotVacant: number) =>
  createSelector(selectCompanyStratalotVacantsEntities, companyStratalotVacants => {
    return { companyStratalotVacant: companyStratalotVacants[idCompanyStratalotVacant] };
  });

const selectActiveCompanyStratalotVacantsEntities = createSelector(
  selectIdCompanyStratalotVacantsActive,
  selectCompanyStratalotVacantsEntities,
  (actives: number[], companyStratalotVacants: Dictionary<CompanyStratalotVacantEntityState>) =>
    getCompanyStratalotVacantsFromActives(actives, companyStratalotVacants)
);

function getCompanyStratalotVacantsFromActives(
  actives: number[],
  companyStratalotVacants: Dictionary<CompanyStratalotVacantEntityState>
): Dictionary<CompanyStratalotVacantEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyStratalotVacants[idActive]) {
      acc[idActive] = companyStratalotVacants[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyStratalotVacantEntityState>);
}

const selectAllCompanyStratalotVacantsSelectors: Dictionary<Selector> = {};
export function selectAllCompanyStratalotVacants(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyStratalotVacant>(
      schema,
      selectAllCompanyStratalotVacantsSelectors,
      selectCompanyStratalotVacantsEntitiesDictionary,
      getRelationSelectors,
      companyStratalotVacantRelations,
      hydrateAll,
      'companyStratalotVacant'
    );
  } else {
    return selectAllCompanyStratalotVacantsObject;
  }
}

export function selectAllCompanyStratalotVacantsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyStratalotVacants'
): Selector {
  return createSelector(selectAllCompanyStratalotVacants(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyStratalotVacantEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyStratalotVacants.length; i++) {
      res[customKey][result.companyStratalotVacants[i].idCompanyStratalotVacant] = result.companyStratalotVacants[i];
    }
    return res;
  });
}

export function selectOneCompanyStratalotVacant(schema: SelectSchema = {}, idCompanyStratalotVacant: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyStratalotVacantDictionary(idCompanyStratalotVacant)];
    selectors.push(...getRelationSelectors(schema, companyStratalotVacantRelations, 'companyStratalotVacant'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyStratalotVacantDictionaryWithoutChild(idCompanyStratalotVacant);
  }
}

export function selectActiveCompanyStratalotVacants(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyStratalotVacantsEntities, companyStratalotVacants => ({
      companyStratalotVacants
    }))
  ];
  selectors.push(...getRelationSelectors(schema, companyStratalotVacantRelations, 'companyStratalotVacant'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyStratalotVacants: Dictionary<CompanyStratalotVacantEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { companyStratalotVacants: (CompanyStratalotVacant | null)[] } {
  const { companyStratalotVacants, companies, stratalots } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    companyStratalotVacants: Object.keys(companyStratalotVacants).map(idCompanyStratalotVacant =>
      hydrate(
        companyStratalotVacants[idCompanyStratalotVacant] as CompanyStratalotVacantEntityState,
        companies,
        stratalots
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { companyStratalotVacant: CompanyStratalotVacantEntityState | null } {
  const { companyStratalotVacants, companies, stratalots } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const companyStratalotVacant = Object.values(companyStratalotVacants)[0];
  return {
    companyStratalotVacant: hydrate(companyStratalotVacant as CompanyStratalotVacantEntityState, companies, stratalots)
  };
}

function hydrate(
  companyStratalotVacant: CompanyStratalotVacantEntityState,
  companyEntities?: Dictionary<CompanyEntityState>,
  stratalotEntities?: Dictionary<StratalotEntityState>
): CompanyStratalotVacant | null {
  if (!companyStratalotVacant) {
    return null;
  }

  const companyStratalotVacantHydrated: CompanyStratalotVacantEntityState = { ...companyStratalotVacant };
  if (companyEntities) {
    companyStratalotVacantHydrated.company = companyEntities[companyStratalotVacant.company as number] as Company;
  } else {
    delete companyStratalotVacantHydrated.company;
  }

  if (stratalotEntities) {
    companyStratalotVacantHydrated.stratalots = ((companyStratalotVacantHydrated.stratalots as number[]) || []).map(
      id => stratalotEntities[id]
    ) as Stratalot[];
  } else {
    delete companyStratalotVacantHydrated.stratalots;
  }

  return companyStratalotVacantHydrated as CompanyStratalotVacant;
}
