import { CompanyCommunicationRecipientState, initialState } from './company-communication-recipient.state';
import { Action, createReducer } from '@ngrx/store';
import { companyCommunicationRecipientReducersGeneratedFunctions } from './company-communication-recipient-generated.reducer';

const companyCommunicationRecipientReducersFunctions = [...companyCommunicationRecipientReducersGeneratedFunctions];

const companyCommunicationRecipientReducer = createReducer(
  initialState,
  ...companyCommunicationRecipientReducersFunctions
);

export function reducer(state: CompanyCommunicationRecipientState | undefined, action: Action) {
  return companyCommunicationRecipientReducer(state, action);
}
