import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StratalotRcp, StratalotRcpEntityState } from '@_model/interfaces/stratalot-rcp.model';
import { ResidenceShare, ResidenceShareEntityState } from '@_model/interfaces/residence-share.model';
import { ResidenceShareRcp, ResidenceShareRcpEntityState } from '@_model/interfaces/residence-share-rcp.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, stratalotRcpFeatureKey, StratalotRcpState } from './stratalot-rcp.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const stratalotRcpRelations: string[] = ['residenceShares', 'residenceShareRcps', 'residences', 'stratalots'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectStratalotRcpState = createFeatureSelector<StratalotRcpState>(stratalotRcpFeatureKey);

export const selectIsLoadedStratalotRcp = createSelector(
  selectStratalotRcpState,
  (state: StratalotRcpState) => state.isLoaded
);

export const selectIsLoadingStratalotRcp = createSelector(
  selectStratalotRcpState,
  (state: StratalotRcpState) => state.isLoading
);

export const selectIsReadyStratalotRcp = createSelector(
  selectStratalotRcpState,
  (state: StratalotRcpState) => !state.isLoading
);

export const selectIsReadyAndLoadedStratalotRcp = createSelector(
  selectStratalotRcpState,
  (state: StratalotRcpState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const StratalotRcpModel: SelectorModel = {
  name: 'stratalotRcps',
  getSelector: selectAllStratalotRcpsDictionary,
  isReady: selectIsReadyStratalotRcp
};

export const selectStratalotRcpsEntities = createSelector(selectStratalotRcpState, selectEntities);

export const selectStratalotRcpsArray = createSelector(selectStratalotRcpState, selectAll);

export const selectIdStratalotRcpsActive = createSelector(
  selectStratalotRcpState,
  (state: StratalotRcpState) => state.actives
);

const stratalotRcpsInObject = (stratalotRcps: Dictionary<StratalotRcpEntityState>) => ({ stratalotRcps });

const selectStratalotRcpsEntitiesDictionary = createSelector(selectStratalotRcpsEntities, stratalotRcpsInObject);

const selectAllStratalotRcpsObject = createSelector(selectStratalotRcpsEntities, stratalotRcps => {
  return hydrateAll({ stratalotRcps });
});

const selectOneStratalotRcpDictionary = (idStratalotRcp: number) =>
  createSelector(selectStratalotRcpsEntities, stratalotRcps => {
    return { stratalotRcps: { [idStratalotRcp]: stratalotRcps[idStratalotRcp] } };
  });

const selectOneStratalotRcpDictionaryWithoutChild = (idStratalotRcp: number) =>
  createSelector(selectStratalotRcpsEntities, stratalotRcps => {
    return { stratalotRcp: stratalotRcps[idStratalotRcp] };
  });

const selectActiveStratalotRcpsEntities = createSelector(
  selectIdStratalotRcpsActive,
  selectStratalotRcpsEntities,
  (actives: number[], stratalotRcps: Dictionary<StratalotRcpEntityState>) =>
    getStratalotRcpsFromActives(actives, stratalotRcps)
);

function getStratalotRcpsFromActives(
  actives: number[],
  stratalotRcps: Dictionary<StratalotRcpEntityState>
): Dictionary<StratalotRcpEntityState> {
  return actives.reduce((acc, idActive) => {
    if (stratalotRcps[idActive]) {
      acc[idActive] = stratalotRcps[idActive];
    }
    return acc;
  }, {} as Dictionary<StratalotRcpEntityState>);
}

const selectAllStratalotRcpsSelectors: Dictionary<Selector> = {};
export function selectAllStratalotRcps(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<StratalotRcp>(
      schema,
      selectAllStratalotRcpsSelectors,
      selectStratalotRcpsEntitiesDictionary,
      getRelationSelectors,
      stratalotRcpRelations,
      hydrateAll,
      'stratalotRcp'
    );
  } else {
    return selectAllStratalotRcpsObject;
  }
}

export function selectAllStratalotRcpsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'stratalotRcps'
): Selector {
  return createSelector(selectAllStratalotRcps(schema), result => {
    const res = { [customKey]: {} as Dictionary<StratalotRcpEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.stratalotRcps.length; i++) {
      res[customKey][result.stratalotRcps[i].idStratalotRcp] = result.stratalotRcps[i];
    }
    return res;
  });
}

export function selectOneStratalotRcp(schema: SelectSchema = {}, idStratalotRcp: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneStratalotRcpDictionary(idStratalotRcp)];
    selectors.push(...getRelationSelectors(schema, stratalotRcpRelations, 'stratalotRcp'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneStratalotRcpDictionaryWithoutChild(idStratalotRcp);
  }
}

export function selectActiveStratalotRcps(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveStratalotRcpsEntities, stratalotRcps => ({ stratalotRcps }))
  ];
  selectors.push(...getRelationSelectors(schema, stratalotRcpRelations, 'stratalotRcp'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  stratalotRcps: Dictionary<StratalotRcpEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
  residenceShares?: Dictionary<ResidenceShareEntityState>;
  residenceShareRcps?: Dictionary<ResidenceShareRcpEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { stratalotRcps: (StratalotRcp | null)[] } {
  const { stratalotRcps, residences, stratalots, residenceShares, residenceShareRcps } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    stratalotRcps: Object.keys(stratalotRcps).map(idStratalotRcp =>
      hydrate(
        stratalotRcps[idStratalotRcp] as StratalotRcpEntityState,
        residences,
        stratalots,
        residenceShares,
        residenceShareRcps
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { stratalotRcp: StratalotRcpEntityState | null } {
  const { stratalotRcps, residences, stratalots, residenceShares, residenceShareRcps } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const stratalotRcp = Object.values(stratalotRcps)[0];
  return {
    stratalotRcp: hydrate(
      stratalotRcp as StratalotRcpEntityState,
      residences,
      stratalots,
      residenceShares,
      residenceShareRcps
    )
  };
}

function hydrate(
  stratalotRcp: StratalotRcpEntityState,
  residenceEntities?: Dictionary<ResidenceEntityState>,
  stratalotEntities?: Dictionary<StratalotEntityState>,
  residenceShareEntities?: Dictionary<ResidenceShareEntityState>,
  residenceShareRcpEntities?: Dictionary<ResidenceShareRcpEntityState>
): StratalotRcp | null {
  if (!stratalotRcp) {
    return null;
  }

  const stratalotRcpHydrated: StratalotRcpEntityState = { ...stratalotRcp };
  if (residenceEntities) {
    stratalotRcpHydrated.residence = residenceEntities[stratalotRcp.residence as number] as Residence;
  } else {
    delete stratalotRcpHydrated.residence;
  }
  if (stratalotEntities) {
    stratalotRcpHydrated.stratalot = stratalotEntities[stratalotRcp.stratalot as number] as Stratalot;
  } else {
    delete stratalotRcpHydrated.stratalot;
  }

  if (residenceShareEntities) {
    stratalotRcpHydrated.residenceShares = ((stratalotRcpHydrated.residenceShares as number[]) || []).map(
      id => residenceShareEntities[id]
    ) as ResidenceShare[];
  } else {
    delete stratalotRcpHydrated.residenceShares;
  }

  if (residenceShareRcpEntities) {
    stratalotRcpHydrated.residenceShareRcps = ((stratalotRcpHydrated.residenceShareRcps as number[]) || []).map(
      id => residenceShareRcpEntities[id]
    ) as ResidenceShareRcp[];
  } else {
    delete stratalotRcpHydrated.residenceShareRcps;
  }

  return stratalotRcpHydrated as StratalotRcp;
}
