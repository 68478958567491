import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidenceStudyCriteriaActions from './residence-study-criteria.actions';
import { adapter, initialState, ResidenceStudyCriteriaState } from './residence-study-criteria.state';
import { Dictionary } from '@ngrx/entity';
import { ResidenceStudyCriteriaEntityState } from '@_model/interfaces/residence-study-criteria.model';

export const residenceStudyCriteriaReducersGeneratedFunctions: ReducerTypes<
  ResidenceStudyCriteriaState,
  readonly ActionCreator[]
>[] = [
  on(ResidenceStudyCriteriaActions.getOneResidenceStudyCriteria, (state: ResidenceStudyCriteriaState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceStudyCriteriaActions.getManyResidenceStudyCriterias, (state: ResidenceStudyCriteriaState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceStudyCriteriaActions.upsertOneResidenceStudyCriteria, (state: ResidenceStudyCriteriaState) =>
    setLoadingsState(state, true)
  ),

  on(
    ResidenceStudyCriteriaActions.upsertManyResidenceStudyCriteriasSuccess,
    (state: ResidenceStudyCriteriaState, { residenceStudyCriterias }) =>
      adapter.upsertMany(residenceStudyCriterias, setLoadingsState(state, false))
  ),
  on(ResidenceStudyCriteriaActions.deleteOneResidenceStudyCriteria, (state: ResidenceStudyCriteriaState) =>
    setLoadingsState(state, true)
  ),
  on(
    ResidenceStudyCriteriaActions.deleteOneResidenceStudyCriteriaSuccess,
    (state: ResidenceStudyCriteriaState, { idResidenceStudyCriteria }) =>
      adapter.removeOne(idResidenceStudyCriteria, setLoadingsState(state, false))
  ),
  on(ResidenceStudyCriteriaActions.clearActive, (state: ResidenceStudyCriteriaState) => ({ ...state, actives: [] })),
  on(
    ResidenceStudyCriteriaActions.addManyActives,
    (state: ResidenceStudyCriteriaState, { idResidenceStudyCriterias }) => ({
      ...state,
      actives: state.actives.concat(idResidenceStudyCriterias)
    })
  ),
  on(
    ResidenceStudyCriteriaActions.deleteOneActive,
    (state: ResidenceStudyCriteriaState, { idResidenceStudyCriteria }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idResidenceStudyCriteria)
    })
  ),

  on(ResidenceStudyCriteriaActions.clearStore, () => initialState),

  on(
    ResidenceStudyCriteriaActions.addResidenceStudySuccess,
    (state: ResidenceStudyCriteriaState, { idResidenceStudyCriteria, idResidenceStudy }) => {
      if (!state.entities[idResidenceStudyCriteria]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudyCriteria]: {
            ...state.entities[idResidenceStudyCriteria],
            residenceStudy: idResidenceStudy
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyCriteriaActions.deleteManyResidenceStudySuccess,
    (state: ResidenceStudyCriteriaState, { idResidenceStudies, idResidenceStudyCriterias }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudyCriterias.reduce((entities, idResidenceStudyCriteria) => {
            if (!state.entities[idResidenceStudyCriteria]?.residenceStudy) {
              return entities;
            }
            entities[idResidenceStudyCriteria] = {
              ...state.entities[idResidenceStudyCriteria],
              residenceStudy: idResidenceStudies.some(
                (idResidenceStudy: number) =>
                  idResidenceStudy === state.entities[idResidenceStudyCriteria]?.residenceStudy
              )
                ? undefined
                : state.entities[idResidenceStudyCriteria]?.residenceStudy
            } as ResidenceStudyCriteriaEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyCriteriaEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceStudyCriteriaActions.addCompanyStudyCriteriaSuccess,
    (state: ResidenceStudyCriteriaState, { idResidenceStudyCriteria, idCompanyStudyCriteria }) => {
      if (!state.entities[idResidenceStudyCriteria]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudyCriteria]: {
            ...state.entities[idResidenceStudyCriteria],
            companyStudyCriteria: idCompanyStudyCriteria
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyCriteriaActions.deleteManyCompanyStudyCriteriaSuccess,
    (state: ResidenceStudyCriteriaState, { idCompanyStudyCriterias, idResidenceStudyCriterias }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudyCriterias.reduce((entities, idResidenceStudyCriteria) => {
            if (!state.entities[idResidenceStudyCriteria]?.companyStudyCriteria) {
              return entities;
            }
            entities[idResidenceStudyCriteria] = {
              ...state.entities[idResidenceStudyCriteria],
              companyStudyCriteria: idCompanyStudyCriterias.some(
                (idCompanyStudyCriteria: number) =>
                  idCompanyStudyCriteria === state.entities[idResidenceStudyCriteria]?.companyStudyCriteria
              )
                ? undefined
                : state.entities[idResidenceStudyCriteria]?.companyStudyCriteria
            } as ResidenceStudyCriteriaEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyCriteriaEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidenceStudyCriteriaState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidenceStudyCriteriaState {
  return { ...state, isLoaded, isLoading };
}
