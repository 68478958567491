import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationResidenceTodoRule,
  OrganizationResidenceTodoRuleEntityState
} from '@_model/interfaces/organization-residence-todo-rule.model';
import {
  OrganizationResidenceTodo,
  OrganizationResidenceTodoEntityState
} from '@_model/interfaces/organization-residence-todo.model';
import {
  OrganizationResidenceTodoAppliedRule,
  OrganizationResidenceTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-residence-todo-applied-rule.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import {
  OrganizationSaleCategory,
  OrganizationSaleCategoryEntityState
} from '@_model/interfaces/organization-sale-category.model';
import { SaleCategoryFamily, SaleCategoryFamilyEntityState } from '@_model/interfaces/sale-category-family.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationResidenceTodoRuleFeatureKey,
  OrganizationResidenceTodoRuleState
} from './organization-residence-todo-rule.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationResidenceTodoRuleRelations: string[] = [
  'organizationResidenceTodos',
  'organizationResidenceTodoAppliedRules',
  'organizations',
  'organizationSaleCategories',
  'familySaleCategories',
  'companies'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationResidenceTodoRuleState = createFeatureSelector<OrganizationResidenceTodoRuleState>(
  organizationResidenceTodoRuleFeatureKey
);

export const selectIsLoadedOrganizationResidenceTodoRule = createSelector(
  selectOrganizationResidenceTodoRuleState,
  (state: OrganizationResidenceTodoRuleState) => state.isLoaded
);

export const selectIsLoadingOrganizationResidenceTodoRule = createSelector(
  selectOrganizationResidenceTodoRuleState,
  (state: OrganizationResidenceTodoRuleState) => state.isLoading
);

export const selectIsReadyOrganizationResidenceTodoRule = createSelector(
  selectOrganizationResidenceTodoRuleState,
  (state: OrganizationResidenceTodoRuleState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationResidenceTodoRule = createSelector(
  selectOrganizationResidenceTodoRuleState,
  (state: OrganizationResidenceTodoRuleState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationResidenceTodoRuleModel: SelectorModel = {
  name: 'organizationResidenceTodoRules',
  getSelector: selectAllOrganizationResidenceTodoRulesDictionary,
  isReady: selectIsReadyOrganizationResidenceTodoRule
};

export const selectOrganizationResidenceTodoRulesEntities = createSelector(
  selectOrganizationResidenceTodoRuleState,
  selectEntities
);

export const selectOrganizationResidenceTodoRulesArray = createSelector(
  selectOrganizationResidenceTodoRuleState,
  selectAll
);

export const selectIdOrganizationResidenceTodoRulesActive = createSelector(
  selectOrganizationResidenceTodoRuleState,
  (state: OrganizationResidenceTodoRuleState) => state.actives
);

const organizationResidenceTodoRulesInObject = (
  organizationResidenceTodoRules: Dictionary<OrganizationResidenceTodoRuleEntityState>
) => ({ organizationResidenceTodoRules });

const selectOrganizationResidenceTodoRulesEntitiesDictionary = createSelector(
  selectOrganizationResidenceTodoRulesEntities,
  organizationResidenceTodoRulesInObject
);

const selectAllOrganizationResidenceTodoRulesObject = createSelector(
  selectOrganizationResidenceTodoRulesEntities,
  organizationResidenceTodoRules => {
    return hydrateAll({ organizationResidenceTodoRules });
  }
);

const selectOneOrganizationResidenceTodoRuleDictionary = (idOrganizationResidenceTodoRule: number) =>
  createSelector(selectOrganizationResidenceTodoRulesEntities, organizationResidenceTodoRules => {
    return {
      organizationResidenceTodoRules: {
        [idOrganizationResidenceTodoRule]: organizationResidenceTodoRules[idOrganizationResidenceTodoRule]
      }
    };
  });

const selectOneOrganizationResidenceTodoRuleDictionaryWithoutChild = (idOrganizationResidenceTodoRule: number) =>
  createSelector(selectOrganizationResidenceTodoRulesEntities, organizationResidenceTodoRules => {
    return { organizationResidenceTodoRule: organizationResidenceTodoRules[idOrganizationResidenceTodoRule] };
  });

const selectActiveOrganizationResidenceTodoRulesEntities = createSelector(
  selectIdOrganizationResidenceTodoRulesActive,
  selectOrganizationResidenceTodoRulesEntities,
  (actives: number[], organizationResidenceTodoRules: Dictionary<OrganizationResidenceTodoRuleEntityState>) =>
    getOrganizationResidenceTodoRulesFromActives(actives, organizationResidenceTodoRules)
);

function getOrganizationResidenceTodoRulesFromActives(
  actives: number[],
  organizationResidenceTodoRules: Dictionary<OrganizationResidenceTodoRuleEntityState>
): Dictionary<OrganizationResidenceTodoRuleEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationResidenceTodoRules[idActive]) {
      acc[idActive] = organizationResidenceTodoRules[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationResidenceTodoRuleEntityState>);
}

const selectAllOrganizationResidenceTodoRulesSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationResidenceTodoRules(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationResidenceTodoRule>(
      schema,
      selectAllOrganizationResidenceTodoRulesSelectors,
      selectOrganizationResidenceTodoRulesEntitiesDictionary,
      getRelationSelectors,
      organizationResidenceTodoRuleRelations,
      hydrateAll,
      'organizationResidenceTodoRule'
    );
  } else {
    return selectAllOrganizationResidenceTodoRulesObject;
  }
}

export function selectAllOrganizationResidenceTodoRulesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationResidenceTodoRules'
): Selector {
  return createSelector(selectAllOrganizationResidenceTodoRules(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationResidenceTodoRuleEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationResidenceTodoRules.length; i++) {
      res[customKey][result.organizationResidenceTodoRules[i].idOrganizationResidenceTodoRule] =
        result.organizationResidenceTodoRules[i];
    }
    return res;
  });
}

export function selectOneOrganizationResidenceTodoRule(
  schema: SelectSchema = {},
  idOrganizationResidenceTodoRule: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationResidenceTodoRuleDictionary(idOrganizationResidenceTodoRule)];
    selectors.push(
      ...getRelationSelectors(schema, organizationResidenceTodoRuleRelations, 'organizationResidenceTodoRule')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationResidenceTodoRuleDictionaryWithoutChild(idOrganizationResidenceTodoRule);
  }
}

export function selectActiveOrganizationResidenceTodoRules(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationResidenceTodoRulesEntities, organizationResidenceTodoRules => ({
      organizationResidenceTodoRules
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, organizationResidenceTodoRuleRelations, 'organizationResidenceTodoRule')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationResidenceTodoRules: Dictionary<OrganizationResidenceTodoRuleEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  organizationSaleCategories?: Dictionary<OrganizationSaleCategoryEntityState>;
  familySaleCategories?: Dictionary<SaleCategoryFamilyEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  organizationResidenceTodos?: Dictionary<OrganizationResidenceTodoEntityState>;
  organizationResidenceTodoAppliedRules?: Dictionary<OrganizationResidenceTodoAppliedRuleEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationResidenceTodoRules: (OrganizationResidenceTodoRule | null)[];
} {
  const {
    organizationResidenceTodoRules,
    organizations,
    organizationSaleCategories,
    familySaleCategories,
    companies,
    organizationResidenceTodos,
    organizationResidenceTodoAppliedRules
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationResidenceTodoRules: Object.keys(organizationResidenceTodoRules).map(idOrganizationResidenceTodoRule =>
      hydrate(
        organizationResidenceTodoRules[idOrganizationResidenceTodoRule] as OrganizationResidenceTodoRuleEntityState,
        organizations,
        organizationSaleCategories,
        familySaleCategories,
        companies,
        organizationResidenceTodos,
        organizationResidenceTodoAppliedRules
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationResidenceTodoRule: OrganizationResidenceTodoRuleEntityState | null;
} {
  const {
    organizationResidenceTodoRules,
    organizations,
    organizationSaleCategories,
    familySaleCategories,
    companies,
    organizationResidenceTodos,
    organizationResidenceTodoAppliedRules
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationResidenceTodoRule = Object.values(organizationResidenceTodoRules)[0];
  return {
    organizationResidenceTodoRule: hydrate(
      organizationResidenceTodoRule as OrganizationResidenceTodoRuleEntityState,
      organizations,
      organizationSaleCategories,
      familySaleCategories,
      companies,
      organizationResidenceTodos,
      organizationResidenceTodoAppliedRules
    )
  };
}

function hydrate(
  organizationResidenceTodoRule: OrganizationResidenceTodoRuleEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  organizationSaleCategoryEntities?: Dictionary<OrganizationSaleCategoryEntityState>,
  saleCategoryFamilyEntities?: Dictionary<SaleCategoryFamilyEntityState>,
  companyEntities?: Dictionary<CompanyEntityState>,
  organizationResidenceTodoEntities?: Dictionary<OrganizationResidenceTodoEntityState>,
  organizationResidenceTodoAppliedRuleEntities?: Dictionary<OrganizationResidenceTodoAppliedRuleEntityState>
): OrganizationResidenceTodoRule | null {
  if (!organizationResidenceTodoRule) {
    return null;
  }

  const organizationResidenceTodoRuleHydrated: OrganizationResidenceTodoRuleEntityState = {
    ...organizationResidenceTodoRule
  };
  if (organizationEntities) {
    organizationResidenceTodoRuleHydrated.organization = organizationEntities[
      organizationResidenceTodoRule.organization as number
    ] as Organization;
  } else {
    delete organizationResidenceTodoRuleHydrated.organization;
  }
  if (organizationSaleCategoryEntities) {
    organizationResidenceTodoRuleHydrated.organizationSaleCategory = organizationSaleCategoryEntities[
      organizationResidenceTodoRule.organizationSaleCategory as number
    ] as OrganizationSaleCategory;
  } else {
    delete organizationResidenceTodoRuleHydrated.organizationSaleCategory;
  }
  if (saleCategoryFamilyEntities) {
    organizationResidenceTodoRuleHydrated.saleCategoryFamily = saleCategoryFamilyEntities[
      organizationResidenceTodoRule.saleCategoryFamily as number
    ] as SaleCategoryFamily;
  } else {
    delete organizationResidenceTodoRuleHydrated.saleCategoryFamily;
  }
  if (companyEntities) {
    organizationResidenceTodoRuleHydrated.company = companyEntities[
      organizationResidenceTodoRule.company as number
    ] as Company;
  } else {
    delete organizationResidenceTodoRuleHydrated.company;
  }

  if (organizationResidenceTodoEntities) {
    organizationResidenceTodoRuleHydrated.organizationResidenceTodos = (
      (organizationResidenceTodoRuleHydrated.organizationResidenceTodos as number[]) || []
    ).map(id => organizationResidenceTodoEntities[id]) as OrganizationResidenceTodo[];
  } else {
    delete organizationResidenceTodoRuleHydrated.organizationResidenceTodos;
  }

  if (organizationResidenceTodoAppliedRuleEntities) {
    organizationResidenceTodoRuleHydrated.organizationResidenceTodoAppliedRules = (
      (organizationResidenceTodoRuleHydrated.organizationResidenceTodoAppliedRules as number[]) || []
    ).map(id => organizationResidenceTodoAppliedRuleEntities[id]) as OrganizationResidenceTodoAppliedRule[];
  } else {
    delete organizationResidenceTodoRuleHydrated.organizationResidenceTodoAppliedRules;
  }

  return organizationResidenceTodoRuleHydrated as OrganizationResidenceTodoRule;
}
