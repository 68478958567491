import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ResidenceWork, ResidenceWorkEntityState } from '@_model/interfaces/residence-work.model';
import { ResidenceWorkRelationsIds } from './residence-work-generated.effects';

export const getOneResidenceWork = createAction(
  '[ResidenceWork] Get One ResidenceWork',
  props<{ idResidenceWork: number; params?: any }>()
);

export const getManyResidenceWorks = createAction('[ResidenceWork] Get Many ResidenceWorks', props<{ params: any }>());

export const addManyActives = createAction(
  '[ResidenceWork] Add Many Actives ResidenceWork',
  props<{ idResidenceWorks: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidenceWork] Delete One Active ResidenceWork',
  props<{ idResidenceWork: number }>()
);

export const clearActive = createAction('[ResidenceWork] Clear Active ResidenceWork');

export const upsertOneResidenceWork = createAction(
  '[ResidenceWork] Upsert One ResidenceWork',
  props<{
    residenceWork: Partial<ResidenceWork>;
    ids?: ResidenceWorkRelationsIds;
  }>()
);

export const upsertManyResidenceWorks = createAction(
  '[ResidenceWork] Upsert Many ResidenceWorks',
  props<{
    residenceWorks: Partial<ResidenceWork>[];
    ids?: ResidenceWorkRelationsIds;
  }>()
);

export const upsertManyResidenceWorksSuccess = createAction(
  '[ResidenceWork] Create Many ResidenceWorks Success',
  props<{ residenceWorks: ResidenceWorkEntityState[] }>()
);

export const deleteOneResidenceWork = createAction(
  '[ResidenceWork] Delete One ResidenceWork',
  props<{ idResidenceWork: number }>()
);

export const deleteOneResidenceWorkSuccess = createAction(
  '[ResidenceWork] Delete One ResidenceWork Success',
  props<{ idResidenceWork: number }>()
);

export const normalizeManyResidenceWorksAfterUpsert = createAction(
  '[ResidenceWork] Normalize Many ResidenceWorks After Upsert',
  props<{ residenceWorks: ResidenceWorkEntityState[] }>()
);

export const residenceWorksFailure = createAction(
  '[ResidenceWork] ResidenceWorks Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidenceWork] Clear ResidenceWorks');

export const addResidenceSuccess = createAction(
  '[ResidenceWork] Add Residence',
  props<{ idResidenceWork: number; idResidence: number }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[ResidenceWork] Delete Many Residence',
  props<{ idResidences: number[]; idResidenceWorks: number[] }>()
);
