export enum StudySheetPages {
  // etude
  funnel = 'funnel',
  residenceStudy = 'residence-studies',
  studySynthesis = 'study-synthesis',
  // residence detail
  detailEtude = 'detail-etude',
  details = 'details',
  todosStudy = 'todos-study',
  documentResidenceEtude = 'document-residence-etude',
  travauxResidenceEtude = 'travaux-residence-etude'
}
