import { Injectable } from '@angular/core';
import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedResidenceStudyApiService } from './residence-study-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ResidenceStudyApiService extends GeneratedResidenceStudyApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
