import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationLeadTodoProfil } from '@_model/interfaces/organization-lead-todo-profil.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationLeadTodoProfilApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationLeadTodoProfils(params?: any): Observable<OrganizationLeadTodoProfil[]> {
    return this.repo.getData<OrganizationLeadTodoProfil[]>('organization-lead-todo-profil', params);
  }

  public getOrganizationLeadTodoProfil(params: {
    idOrganizationLeadTodoProfil: number;
    params?: any;
  }): Observable<OrganizationLeadTodoProfil> {
    return this.repo.getData<OrganizationLeadTodoProfil>(
      'organization-lead-todo-profil/' + params.idOrganizationLeadTodoProfil,
      params.params
    );
  }

  public addOrganizationLeadTodoProfil(
    organizationLeadTodoProfil: Partial<OrganizationLeadTodoProfil>
  ): Observable<OrganizationLeadTodoProfil> {
    return this.repo.create<OrganizationLeadTodoProfil>('organization-lead-todo-profil', organizationLeadTodoProfil);
  }

  public updateOrganizationLeadTodoProfil(
    organizationLeadTodoProfil: Partial<OrganizationLeadTodoProfil>
  ): Observable<OrganizationLeadTodoProfil> {
    return this.repo.update('organization-lead-todo-profil', organizationLeadTodoProfil);
  }

  public deleteOrganizationLeadTodoProfil(idOrganizationLeadTodoProfil: number): Observable<number> {
    return this.repo.delete('organization-lead-todo-profil/' + +idOrganizationLeadTodoProfil);
  }
}
