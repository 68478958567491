import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedStepProgressApiService } from './step-progress-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class StepProgressApiService extends GeneratedStepProgressApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
