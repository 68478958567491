import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidenceStudyLotEntityState } from '@_model/interfaces/residence-study-lot.model';

export interface ResidenceStudyLotState extends EntityState<ResidenceStudyLotEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidenceStudyLotEntityState> = createEntityAdapter<ResidenceStudyLotEntityState>({
  selectId: o => o.idResidenceStudyLot
});
export const initialState: ResidenceStudyLotState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residenceStudyLotFeatureKey = 'residenceStudyLot';
