import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CompanyCommunication, CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';
import { CompanyCommunicationRelationsIds } from './company-communication-generated.effects';

export const getOneCompanyCommunication = createAction(
  '[CompanyCommunication] Get One CompanyCommunication',
  props<{ idCompanyCommunication: number; params?: any }>()
);

export const getManyCompanyCommunications = createAction(
  '[CompanyCommunication] Get Many CompanyCommunications',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyCommunication] Add Many Actives CompanyCommunication',
  props<{ idCompanyCommunications: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyCommunication] Delete One Active CompanyCommunication',
  props<{ idCompanyCommunication: number }>()
);

export const clearActive = createAction('[CompanyCommunication] Clear Active CompanyCommunication');

export const upsertOneCompanyCommunication = createAction(
  '[CompanyCommunication] Upsert One CompanyCommunication',
  props<{
    companyCommunication: Partial<CompanyCommunication>;
    ids?: CompanyCommunicationRelationsIds;
  }>()
);

export const upsertManyCompanyCommunications = createAction(
  '[CompanyCommunication] Upsert Many CompanyCommunications',
  props<{
    companyCommunications: Partial<CompanyCommunication>[];
    ids?: CompanyCommunicationRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationsSuccess = createAction(
  '[CompanyCommunication] Create Many CompanyCommunications Success',
  props<{ companyCommunications: CompanyCommunicationEntityState[] }>()
);

export const deleteOneCompanyCommunication = createAction(
  '[CompanyCommunication] Delete One CompanyCommunication',
  props<{ idCompanyCommunication: number }>()
);

export const deleteOneCompanyCommunicationSuccess = createAction(
  '[CompanyCommunication] Delete One CompanyCommunication Success',
  props<{ idCompanyCommunication: number }>()
);

export const normalizeManyCompanyCommunicationsAfterUpsert = createAction(
  '[CompanyCommunication] Normalize Many CompanyCommunications After Upsert',
  props<{ companyCommunications: CompanyCommunicationEntityState[] }>()
);

export const companyCommunicationsFailure = createAction(
  '[CompanyCommunication] CompanyCommunications Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyCommunication] Clear CompanyCommunications');

export const addManyOrganizationResidenceTodoSuccess = createAction(
  '[CompanyCommunication] Add Many organization-residence-todo',
  props<{ idCompanyCommunication: number; idOrganizationResidenceTodos: number[] }>()
);

export const deleteManyOrganizationResidenceTodoSuccess = createAction(
  '[CompanyCommunication] Delete Many OrganizationResidenceTodos',
  props<{ idOrganizationResidenceTodos: number[]; idCompanyCommunications: number[] }>()
);

export const addManyCompanyCommunicationResidenceTodoSuccess = createAction(
  '[CompanyCommunication] Add Many company-communication-residence-todo',
  props<{ idCompanyCommunication: number; idCompanyCommunicationResidenceTodos: number[] }>()
);

export const deleteManyCompanyCommunicationResidenceTodoSuccess = createAction(
  '[CompanyCommunication] Delete Many CompanyCommunicationResidenceTodos',
  props<{ idCompanyCommunicationResidenceTodos: number[]; idCompanyCommunications: number[] }>()
);

export const addManyOrganizationStratalotTodoSuccess = createAction(
  '[CompanyCommunication] Add Many organization-stratalot-todo',
  props<{ idCompanyCommunication: number; idOrganizationStratalotTodos: number[] }>()
);

export const deleteManyOrganizationStratalotTodoSuccess = createAction(
  '[CompanyCommunication] Delete Many OrganizationStratalotTodos',
  props<{ idOrganizationStratalotTodos: number[]; idCompanyCommunications: number[] }>()
);

export const addManyCompanyCommunicationStratalotTodoSuccess = createAction(
  '[CompanyCommunication] Add Many company-communication-stratalot-todo',
  props<{ idCompanyCommunication: number; idCompanyCommunicationStratalotTodos: number[] }>()
);

export const deleteManyCompanyCommunicationStratalotTodoSuccess = createAction(
  '[CompanyCommunication] Delete Many CompanyCommunicationStratalotTodos',
  props<{ idCompanyCommunicationStratalotTodos: number[]; idCompanyCommunications: number[] }>()
);

export const addManyOrganizationLeadTodoSuccess = createAction(
  '[CompanyCommunication] Add Many organization-lead-todo',
  props<{ idCompanyCommunication: number; idOrganizationLeadTodos: number[] }>()
);

export const deleteManyOrganizationLeadTodoSuccess = createAction(
  '[CompanyCommunication] Delete Many OrganizationLeadTodos',
  props<{ idOrganizationLeadTodos: number[]; idCompanyCommunications: number[] }>()
);

export const addManyCompanyCommunicationLeadTodoSuccess = createAction(
  '[CompanyCommunication] Add Many company-communication-lead-todo',
  props<{ idCompanyCommunication: number; idCompanyCommunicationLeadTodos: number[] }>()
);

export const deleteManyCompanyCommunicationLeadTodoSuccess = createAction(
  '[CompanyCommunication] Delete Many CompanyCommunicationLeadTodos',
  props<{ idCompanyCommunicationLeadTodos: number[]; idCompanyCommunications: number[] }>()
);

export const addManyGeneratedDocumentSuccess = createAction(
  '[CompanyCommunication] Add Many generated-document',
  props<{ idCompanyCommunication: number; idGeneratedDocuments: number[] }>()
);

export const deleteManyGeneratedDocumentSuccess = createAction(
  '[CompanyCommunication] Delete Many GeneratedDocuments',
  props<{ idGeneratedDocuments: number[]; idCompanyCommunications: number[] }>()
);

export const addManyCompanyCommunicationGeneratedDocumentSuccess = createAction(
  '[CompanyCommunication] Add Many company-communication-generated-document',
  props<{ idCompanyCommunication: number; idCompanyCommunicationGeneratedDocuments: number[] }>()
);

export const deleteManyCompanyCommunicationGeneratedDocumentSuccess = createAction(
  '[CompanyCommunication] Delete Many CompanyCommunicationGeneratedDocuments',
  props<{ idCompanyCommunicationGeneratedDocuments: number[]; idCompanyCommunications: number[] }>()
);

export const addManyCompanyCommunicationFileSuccess = createAction(
  '[CompanyCommunication] Add Many company-communication-file',
  props<{ idCompanyCommunication: number; idCompanyCommunicationFiles: number[] }>()
);

export const deleteManyCompanyCommunicationFileSuccess = createAction(
  '[CompanyCommunication] Delete Many CompanyCommunicationFiles',
  props<{ idCompanyCommunicationFiles: number[]; idCompanyCommunications: number[] }>()
);

export const addCompanySuccess = createAction(
  '[CompanyCommunication] Add Company',
  props<{ idCompanyCommunication: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[CompanyCommunication] Delete Many Company',
  props<{ idCompanies: number[]; idCompanyCommunications: number[] }>()
);

export const addCompanyCommunicationRecipientSuccess = createAction(
  '[CompanyCommunication] Add CompanyCommunicationRecipient',
  props<{ idCompanyCommunication: number; idCompanyCommunicationRecipient: number }>()
);

export const deleteManyCompanyCommunicationRecipientSuccess = createAction(
  '[CompanyCommunication] Delete Many CompanyCommunicationRecipient',
  props<{ idCompanyCommunicationRecipients: number[]; idCompanyCommunications: number[] }>()
);

export const addCompanyCommunicationMediaSuccess = createAction(
  '[CompanyCommunication] Add CompanyCommunicationMedia',
  props<{ idCompanyCommunication: number; idCompanyCommunicationMedia: number }>()
);

export const deleteManyCompanyCommunicationMediaSuccess = createAction(
  '[CompanyCommunication] Delete Many CompanyCommunicationMedia',
  props<{ idCompanyCommunicationMedias: number[]; idCompanyCommunications: number[] }>()
);
