import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyCommunicationActions from './company-communication.actions';
import { adapter, initialState, CompanyCommunicationState } from './company-communication.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';

export const companyCommunicationReducersGeneratedFunctions: ReducerTypes<
  CompanyCommunicationState,
  readonly ActionCreator[]
>[] = [
  on(CompanyCommunicationActions.getOneCompanyCommunication, (state: CompanyCommunicationState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyCommunicationActions.getManyCompanyCommunications, (state: CompanyCommunicationState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyCommunicationActions.upsertOneCompanyCommunication, (state: CompanyCommunicationState) =>
    setLoadingsState(state, true)
  ),

  on(
    CompanyCommunicationActions.upsertManyCompanyCommunicationsSuccess,
    (state: CompanyCommunicationState, { companyCommunications }) =>
      adapter.upsertMany(companyCommunications, setLoadingsState(state, false))
  ),
  on(CompanyCommunicationActions.deleteOneCompanyCommunication, (state: CompanyCommunicationState) =>
    setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationActions.deleteOneCompanyCommunicationSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunication }) =>
      adapter.removeOne(idCompanyCommunication, setLoadingsState(state, false))
  ),
  on(CompanyCommunicationActions.clearActive, (state: CompanyCommunicationState) => ({ ...state, actives: [] })),
  on(CompanyCommunicationActions.addManyActives, (state: CompanyCommunicationState, { idCompanyCommunications }) => ({
    ...state,
    actives: state.actives.concat(idCompanyCommunications)
  })),
  on(CompanyCommunicationActions.deleteOneActive, (state: CompanyCommunicationState, { idCompanyCommunication }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCompanyCommunication)
  })),

  on(CompanyCommunicationActions.clearStore, () => initialState),

  on(
    CompanyCommunicationActions.addManyOrganizationResidenceTodoSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunication, idOrganizationResidenceTodos }) => {
      if (!state.entities[idCompanyCommunication]) {
        return state;
      }
      const organizationResidenceTodos =
        (state.entities[idCompanyCommunication]?.organizationResidenceTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunication]: {
            ...state.entities[idCompanyCommunication],
            organizationResidenceTodos: organizationResidenceTodos.concat(
              idOrganizationResidenceTodos.filter(id => organizationResidenceTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.deleteManyOrganizationResidenceTodoSuccess,
    (state: CompanyCommunicationState, { idOrganizationResidenceTodos, idCompanyCommunications }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunications.reduce((entities, idCompanyCommunication) => {
            if (!state.entities[idCompanyCommunication]?.organizationResidenceTodos) {
              return entities;
            }
            entities[idCompanyCommunication] = {
              ...state.entities[idCompanyCommunication],
              organizationResidenceTodos: (
                state.entities[idCompanyCommunication]?.organizationResidenceTodos as number[]
              )?.filter(
                (idOrganizationResidenceTodo: number) =>
                  !idOrganizationResidenceTodos.some((id: number) => id === idOrganizationResidenceTodo)
              )
            } as CompanyCommunicationEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.addManyCompanyCommunicationResidenceTodoSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunication, idCompanyCommunicationResidenceTodos }) => {
      if (!state.entities[idCompanyCommunication]) {
        return state;
      }
      const companyCommunicationResidenceTodos =
        (state.entities[idCompanyCommunication]?.companyCommunicationResidenceTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunication]: {
            ...state.entities[idCompanyCommunication],
            companyCommunicationResidenceTodos: companyCommunicationResidenceTodos.concat(
              idCompanyCommunicationResidenceTodos.filter(id => companyCommunicationResidenceTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.deleteManyCompanyCommunicationResidenceTodoSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunicationResidenceTodos, idCompanyCommunications }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunications.reduce((entities, idCompanyCommunication) => {
            if (!state.entities[idCompanyCommunication]?.companyCommunicationResidenceTodos) {
              return entities;
            }
            entities[idCompanyCommunication] = {
              ...state.entities[idCompanyCommunication],
              companyCommunicationResidenceTodos: (
                state.entities[idCompanyCommunication]?.companyCommunicationResidenceTodos as number[]
              )?.filter(
                (idCompanyCommunicationResidenceTodo: number) =>
                  !idCompanyCommunicationResidenceTodos.some((id: number) => id === idCompanyCommunicationResidenceTodo)
              )
            } as CompanyCommunicationEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.addManyOrganizationStratalotTodoSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunication, idOrganizationStratalotTodos }) => {
      if (!state.entities[idCompanyCommunication]) {
        return state;
      }
      const organizationStratalotTodos =
        (state.entities[idCompanyCommunication]?.organizationStratalotTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunication]: {
            ...state.entities[idCompanyCommunication],
            organizationStratalotTodos: organizationStratalotTodos.concat(
              idOrganizationStratalotTodos.filter(id => organizationStratalotTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.deleteManyOrganizationStratalotTodoSuccess,
    (state: CompanyCommunicationState, { idOrganizationStratalotTodos, idCompanyCommunications }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunications.reduce((entities, idCompanyCommunication) => {
            if (!state.entities[idCompanyCommunication]?.organizationStratalotTodos) {
              return entities;
            }
            entities[idCompanyCommunication] = {
              ...state.entities[idCompanyCommunication],
              organizationStratalotTodos: (
                state.entities[idCompanyCommunication]?.organizationStratalotTodos as number[]
              )?.filter(
                (idOrganizationStratalotTodo: number) =>
                  !idOrganizationStratalotTodos.some((id: number) => id === idOrganizationStratalotTodo)
              )
            } as CompanyCommunicationEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.addManyCompanyCommunicationStratalotTodoSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunication, idCompanyCommunicationStratalotTodos }) => {
      if (!state.entities[idCompanyCommunication]) {
        return state;
      }
      const companyCommunicationStratalotTodos =
        (state.entities[idCompanyCommunication]?.companyCommunicationStratalotTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunication]: {
            ...state.entities[idCompanyCommunication],
            companyCommunicationStratalotTodos: companyCommunicationStratalotTodos.concat(
              idCompanyCommunicationStratalotTodos.filter(id => companyCommunicationStratalotTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.deleteManyCompanyCommunicationStratalotTodoSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunicationStratalotTodos, idCompanyCommunications }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunications.reduce((entities, idCompanyCommunication) => {
            if (!state.entities[idCompanyCommunication]?.companyCommunicationStratalotTodos) {
              return entities;
            }
            entities[idCompanyCommunication] = {
              ...state.entities[idCompanyCommunication],
              companyCommunicationStratalotTodos: (
                state.entities[idCompanyCommunication]?.companyCommunicationStratalotTodos as number[]
              )?.filter(
                (idCompanyCommunicationStratalotTodo: number) =>
                  !idCompanyCommunicationStratalotTodos.some((id: number) => id === idCompanyCommunicationStratalotTodo)
              )
            } as CompanyCommunicationEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.addManyOrganizationLeadTodoSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunication, idOrganizationLeadTodos }) => {
      if (!state.entities[idCompanyCommunication]) {
        return state;
      }
      const organizationLeadTodos = (state.entities[idCompanyCommunication]?.organizationLeadTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunication]: {
            ...state.entities[idCompanyCommunication],
            organizationLeadTodos: organizationLeadTodos.concat(
              idOrganizationLeadTodos.filter(id => organizationLeadTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.deleteManyOrganizationLeadTodoSuccess,
    (state: CompanyCommunicationState, { idOrganizationLeadTodos, idCompanyCommunications }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunications.reduce((entities, idCompanyCommunication) => {
            if (!state.entities[idCompanyCommunication]?.organizationLeadTodos) {
              return entities;
            }
            entities[idCompanyCommunication] = {
              ...state.entities[idCompanyCommunication],
              organizationLeadTodos: (
                state.entities[idCompanyCommunication]?.organizationLeadTodos as number[]
              )?.filter(
                (idOrganizationLeadTodo: number) =>
                  !idOrganizationLeadTodos.some((id: number) => id === idOrganizationLeadTodo)
              )
            } as CompanyCommunicationEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.addManyCompanyCommunicationLeadTodoSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunication, idCompanyCommunicationLeadTodos }) => {
      if (!state.entities[idCompanyCommunication]) {
        return state;
      }
      const companyCommunicationLeadTodos =
        (state.entities[idCompanyCommunication]?.companyCommunicationLeadTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunication]: {
            ...state.entities[idCompanyCommunication],
            companyCommunicationLeadTodos: companyCommunicationLeadTodos.concat(
              idCompanyCommunicationLeadTodos.filter(id => companyCommunicationLeadTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.deleteManyCompanyCommunicationLeadTodoSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunicationLeadTodos, idCompanyCommunications }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunications.reduce((entities, idCompanyCommunication) => {
            if (!state.entities[idCompanyCommunication]?.companyCommunicationLeadTodos) {
              return entities;
            }
            entities[idCompanyCommunication] = {
              ...state.entities[idCompanyCommunication],
              companyCommunicationLeadTodos: (
                state.entities[idCompanyCommunication]?.companyCommunicationLeadTodos as number[]
              )?.filter(
                (idCompanyCommunicationLeadTodo: number) =>
                  !idCompanyCommunicationLeadTodos.some((id: number) => id === idCompanyCommunicationLeadTodo)
              )
            } as CompanyCommunicationEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.addManyGeneratedDocumentSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunication, idGeneratedDocuments }) => {
      if (!state.entities[idCompanyCommunication]) {
        return state;
      }
      const generatedDocuments = (state.entities[idCompanyCommunication]?.generatedDocuments as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunication]: {
            ...state.entities[idCompanyCommunication],
            generatedDocuments: generatedDocuments.concat(
              idGeneratedDocuments.filter(id => generatedDocuments.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.deleteManyGeneratedDocumentSuccess,
    (state: CompanyCommunicationState, { idGeneratedDocuments, idCompanyCommunications }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunications.reduce((entities, idCompanyCommunication) => {
            if (!state.entities[idCompanyCommunication]?.generatedDocuments) {
              return entities;
            }
            entities[idCompanyCommunication] = {
              ...state.entities[idCompanyCommunication],
              generatedDocuments: (state.entities[idCompanyCommunication]?.generatedDocuments as number[])?.filter(
                (idGeneratedDocument: number) => !idGeneratedDocuments.some((id: number) => id === idGeneratedDocument)
              )
            } as CompanyCommunicationEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.addManyCompanyCommunicationGeneratedDocumentSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunication, idCompanyCommunicationGeneratedDocuments }) => {
      if (!state.entities[idCompanyCommunication]) {
        return state;
      }
      const companyCommunicationGeneratedDocuments =
        (state.entities[idCompanyCommunication]?.companyCommunicationGeneratedDocuments as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunication]: {
            ...state.entities[idCompanyCommunication],
            companyCommunicationGeneratedDocuments: companyCommunicationGeneratedDocuments.concat(
              idCompanyCommunicationGeneratedDocuments.filter(
                id => companyCommunicationGeneratedDocuments.indexOf(id) < 0
              )
            )
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.deleteManyCompanyCommunicationGeneratedDocumentSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunicationGeneratedDocuments, idCompanyCommunications }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunications.reduce((entities, idCompanyCommunication) => {
            if (!state.entities[idCompanyCommunication]?.companyCommunicationGeneratedDocuments) {
              return entities;
            }
            entities[idCompanyCommunication] = {
              ...state.entities[idCompanyCommunication],
              companyCommunicationGeneratedDocuments: (
                state.entities[idCompanyCommunication]?.companyCommunicationGeneratedDocuments as number[]
              )?.filter(
                (idCompanyCommunicationGeneratedDocument: number) =>
                  !idCompanyCommunicationGeneratedDocuments.some(
                    (id: number) => id === idCompanyCommunicationGeneratedDocument
                  )
              )
            } as CompanyCommunicationEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.addManyCompanyCommunicationFileSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunication, idCompanyCommunicationFiles }) => {
      if (!state.entities[idCompanyCommunication]) {
        return state;
      }
      const companyCommunicationFiles =
        (state.entities[idCompanyCommunication]?.companyCommunicationFiles as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunication]: {
            ...state.entities[idCompanyCommunication],
            companyCommunicationFiles: companyCommunicationFiles.concat(
              idCompanyCommunicationFiles.filter(id => companyCommunicationFiles.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.deleteManyCompanyCommunicationFileSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunicationFiles, idCompanyCommunications }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunications.reduce((entities, idCompanyCommunication) => {
            if (!state.entities[idCompanyCommunication]?.companyCommunicationFiles) {
              return entities;
            }
            entities[idCompanyCommunication] = {
              ...state.entities[idCompanyCommunication],
              companyCommunicationFiles: (
                state.entities[idCompanyCommunication]?.companyCommunicationFiles as number[]
              )?.filter(
                (idCompanyCommunicationFile: number) =>
                  !idCompanyCommunicationFiles.some((id: number) => id === idCompanyCommunicationFile)
              )
            } as CompanyCommunicationEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.addCompanySuccess,
    (state: CompanyCommunicationState, { idCompanyCommunication, idCompany }) => {
      if (!state.entities[idCompanyCommunication]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunication]: {
            ...state.entities[idCompanyCommunication],
            company: idCompany
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.deleteManyCompanySuccess,
    (state: CompanyCommunicationState, { idCompanies, idCompanyCommunications }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunications.reduce((entities, idCompanyCommunication) => {
            if (!state.entities[idCompanyCommunication]?.company) {
              return entities;
            }
            entities[idCompanyCommunication] = {
              ...state.entities[idCompanyCommunication],
              company: idCompanies.some(
                (idCompany: number) => idCompany === state.entities[idCompanyCommunication]?.company
              )
                ? undefined
                : state.entities[idCompanyCommunication]?.company
            } as CompanyCommunicationEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.addCompanyCommunicationRecipientSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunication, idCompanyCommunicationRecipient }) => {
      if (!state.entities[idCompanyCommunication]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunication]: {
            ...state.entities[idCompanyCommunication],
            companyCommunicationRecipient: idCompanyCommunicationRecipient
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.deleteManyCompanyCommunicationRecipientSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunicationRecipients, idCompanyCommunications }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunications.reduce((entities, idCompanyCommunication) => {
            if (!state.entities[idCompanyCommunication]?.companyCommunicationRecipient) {
              return entities;
            }
            entities[idCompanyCommunication] = {
              ...state.entities[idCompanyCommunication],
              companyCommunicationRecipient: idCompanyCommunicationRecipients.some(
                (idCompanyCommunicationRecipient: number) =>
                  idCompanyCommunicationRecipient ===
                  state.entities[idCompanyCommunication]?.companyCommunicationRecipient
              )
                ? undefined
                : state.entities[idCompanyCommunication]?.companyCommunicationRecipient
            } as CompanyCommunicationEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.addCompanyCommunicationMediaSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunication, idCompanyCommunicationMedia }) => {
      if (!state.entities[idCompanyCommunication]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunication]: {
            ...state.entities[idCompanyCommunication],
            companyCommunicationMedia: idCompanyCommunicationMedia
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationActions.deleteManyCompanyCommunicationMediaSuccess,
    (state: CompanyCommunicationState, { idCompanyCommunicationMedias, idCompanyCommunications }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunications.reduce((entities, idCompanyCommunication) => {
            if (!state.entities[idCompanyCommunication]?.companyCommunicationMedia) {
              return entities;
            }
            entities[idCompanyCommunication] = {
              ...state.entities[idCompanyCommunication],
              companyCommunicationMedia: idCompanyCommunicationMedias.some(
                (idCompanyCommunicationMedia: number) =>
                  idCompanyCommunicationMedia === state.entities[idCompanyCommunication]?.companyCommunicationMedia
              )
                ? undefined
                : state.entities[idCompanyCommunication]?.companyCommunicationMedia
            } as CompanyCommunicationEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyCommunicationState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyCommunicationState {
  return { ...state, isLoaded, isLoading };
}
