import { RepositoryService } from '@_services/api/repository.service';
import { CompanyCommunicationRecipientUser } from '@_model/interfaces/company-communication-recipient-user.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyCommunicationRecipientUserApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyCommunicationRecipientUsers(params?: any): Observable<CompanyCommunicationRecipientUser[]> {
    return this.repo.getData<CompanyCommunicationRecipientUser[]>('company-communication-recipient-user', params);
  }

  public getCompanyCommunicationRecipientUser(params: {
    idCompanyCommunicationRecipientUser: number;
    params?: any;
  }): Observable<CompanyCommunicationRecipientUser> {
    return this.repo.getData<CompanyCommunicationRecipientUser>(
      'company-communication-recipient-user/' + params.idCompanyCommunicationRecipientUser,
      params.params
    );
  }

  public addCompanyCommunicationRecipientUser(
    companyCommunicationRecipientUser: Partial<CompanyCommunicationRecipientUser>
  ): Observable<CompanyCommunicationRecipientUser> {
    return this.repo.create<CompanyCommunicationRecipientUser>(
      'company-communication-recipient-user',
      companyCommunicationRecipientUser
    );
  }

  public updateCompanyCommunicationRecipientUser(
    companyCommunicationRecipientUser: Partial<CompanyCommunicationRecipientUser>
  ): Observable<CompanyCommunicationRecipientUser> {
    return this.repo.update('company-communication-recipient-user', companyCommunicationRecipientUser);
  }

  public deleteCompanyCommunicationRecipientUser(idCompanyCommunicationRecipientUser: number): Observable<number> {
    return this.repo.delete('company-communication-recipient-user/' + +idCompanyCommunicationRecipientUser);
  }
}
