import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as StratalotCampaignActions from './stratalot-campaign.actions';
import { adapter, initialState, StratalotCampaignState } from './stratalot-campaign.state';
import { Dictionary } from '@ngrx/entity';
import { StratalotCampaignEntityState } from '@_model/interfaces/stratalot-campaign.model';

export const stratalotCampaignReducersGeneratedFunctions: ReducerTypes<
  StratalotCampaignState,
  readonly ActionCreator[]
>[] = [
  on(StratalotCampaignActions.getOneStratalotCampaign, (state: StratalotCampaignState) =>
    setLoadingsState(state, true)
  ),
  on(StratalotCampaignActions.getManyStratalotCampaigns, (state: StratalotCampaignState) =>
    setLoadingsState(state, true)
  ),
  on(StratalotCampaignActions.upsertOneStratalotCampaign, (state: StratalotCampaignState) =>
    setLoadingsState(state, true)
  ),

  on(
    StratalotCampaignActions.upsertManyStratalotCampaignsSuccess,
    (state: StratalotCampaignState, { stratalotCampaigns }) =>
      adapter.upsertMany(stratalotCampaigns, setLoadingsState(state, false))
  ),
  on(StratalotCampaignActions.deleteOneStratalotCampaign, (state: StratalotCampaignState) =>
    setLoadingsState(state, true)
  ),
  on(
    StratalotCampaignActions.deleteOneStratalotCampaignSuccess,
    (state: StratalotCampaignState, { idStratalotCampaign }) =>
      adapter.removeOne(idStratalotCampaign, setLoadingsState(state, false))
  ),
  on(StratalotCampaignActions.clearActive, (state: StratalotCampaignState) => ({ ...state, actives: [] })),
  on(StratalotCampaignActions.addManyActives, (state: StratalotCampaignState, { idStratalotCampaigns }) => ({
    ...state,
    actives: state.actives.concat(idStratalotCampaigns)
  })),
  on(StratalotCampaignActions.deleteOneActive, (state: StratalotCampaignState, { idStratalotCampaign }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idStratalotCampaign)
  })),

  on(StratalotCampaignActions.clearStore, () => initialState),

  on(
    StratalotCampaignActions.addManyCampaignMediaSuccess,
    (state: StratalotCampaignState, { idStratalotCampaign, idCampaignMedias }) => {
      if (!state.entities[idStratalotCampaign]) {
        return state;
      }
      const campaignMedias = (state.entities[idStratalotCampaign]?.campaignMedias as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotCampaign]: {
            ...state.entities[idStratalotCampaign],
            campaignMedias: campaignMedias.concat(idCampaignMedias.filter(id => campaignMedias.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    StratalotCampaignActions.deleteManyCampaignMediaSuccess,
    (state: StratalotCampaignState, { idCampaignMedias, idStratalotCampaigns }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotCampaigns.reduce((entities, idStratalotCampaign) => {
            if (!state.entities[idStratalotCampaign]?.campaignMedias) {
              return entities;
            }
            entities[idStratalotCampaign] = {
              ...state.entities[idStratalotCampaign],
              campaignMedias: (state.entities[idStratalotCampaign]?.campaignMedias as number[])?.filter(
                (idCampaignMedia: number) => !idCampaignMedias.some((id: number) => id === idCampaignMedia)
              )
            } as StratalotCampaignEntityState;
            return entities;
          }, {} as Dictionary<StratalotCampaignEntityState>)
        }
      };
    }
  ),

  on(
    StratalotCampaignActions.addStratalotSuccess,
    (state: StratalotCampaignState, { idStratalotCampaign, idStratalot }) => {
      if (!state.entities[idStratalotCampaign]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotCampaign]: {
            ...state.entities[idStratalotCampaign],
            stratalot: idStratalot
          }
        }
      };
    }
  ),

  on(
    StratalotCampaignActions.deleteManyStratalotSuccess,
    (state: StratalotCampaignState, { idStratalots, idStratalotCampaigns }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotCampaigns.reduce((entities, idStratalotCampaign) => {
            if (!state.entities[idStratalotCampaign]?.stratalot) {
              return entities;
            }
            entities[idStratalotCampaign] = {
              ...state.entities[idStratalotCampaign],
              stratalot: idStratalots.some(
                (idStratalot: number) => idStratalot === state.entities[idStratalotCampaign]?.stratalot
              )
                ? undefined
                : state.entities[idStratalotCampaign]?.stratalot
            } as StratalotCampaignEntityState;
            return entities;
          }, {} as Dictionary<StratalotCampaignEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: StratalotCampaignState,
  isLoading: boolean,
  isLoaded: boolean = true
): StratalotCampaignState {
  return { ...state, isLoaded, isLoading };
}
