import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Step } from '@_model/interfaces/step.model';
import * as AppState from '@_store/index.reducers';
import { StepRelationsIds } from '@_store/step/step-generated.effects';
import * as StepAction from '@_store/step/step.actions';
import {
  selectActiveSteps,
  selectAllSteps,
  selectIdStepsActive,
  selectIsLoadedStep,
  selectIsLoadingStep,
  selectIsReadyAndLoadedStep,
  selectOneStep
} from '@_store/step/step.selectors';
import { catchApiActions } from '@_utils/http.util';
import { getIsReadySelectors, Selector, SelectSchema } from '@_utils/selector.util';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedStepService {
  constructor(protected store$: Store<AppState.AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadedStep));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadingStep));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [selectIsReadyAndLoadedStep as Selector].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllSteps(schema: SelectSchema = {}): Observable<Step[]> {
    return this.store$.pipe(select(selectAllSteps(schema))).pipe(
      switchMap(({ steps }: { steps: Step[] }) => {
        return this.getReady(schema).pipe(mapTo(steps));
      })
    );
  }

  public selectOneStep(idStep: number, schema: SelectSchema = {}): Observable<Step> {
    return this.store$.pipe(select(selectOneStep(schema, idStep))).pipe(
      switchMap(({ step }: { step: Step }) => {
        return this.getReady(schema).pipe(mapTo(step));
      })
    );
  }

  public selectAllActiveSteps(schema: SelectSchema = {}): Observable<Step[]> {
    return this.store$.pipe(select(selectActiveSteps(schema))).pipe(
      switchMap(({ steps }: { steps: Step[] }) => {
        return this.getReady(schema).pipe(mapTo(steps));
      })
    );
  }

  public selectIdStepsActive(): Observable<number[]> {
    return this.store$.pipe(select(selectIdStepsActive)).pipe(
      switchMap((idSteps: number[]) => {
        return this.getReady().pipe(mapTo(idSteps));
      })
    );
  }

  public getOneStep(idStep: number, params: any = {}, getResult?: boolean): void | Observable<Step> {
    this.store$.dispatch(StepAction.getOneStep({ idStep, params }));
    if (getResult) {
      return catchApiActions(this.actions$, StepAction.normalizeManyStepsAfterUpsert, StepAction.stepsFailure, true);
    }
  }

  public getManySteps(params: any = {}, getResult?: boolean): void | Observable<Step[]> {
    this.store$.dispatch(StepAction.getManySteps({ params }));
    if (getResult) {
      return catchApiActions(this.actions$, StepAction.normalizeManyStepsAfterUpsert, StepAction.stepsFailure);
    }
  }

  public upsertOneStep(step: Partial<Step>, ids: StepRelationsIds = {}, getResult?: boolean): void | Observable<Step> {
    this.store$.dispatch(StepAction.upsertOneStep({ step, ids }));
    if (getResult) {
      return catchApiActions(this.actions$, StepAction.normalizeManyStepsAfterUpsert, StepAction.stepsFailure, true);
    }
  }

  public deleteOneStep(idStep: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(StepAction.deleteOneStep({ idStep }));
    if (getResult) {
      return catchApiActions(this.actions$, StepAction.deleteOneStepSuccess, StepAction.stepsFailure);
    }
  }

  public setActiveSteps(idSteps: number[]): void {
    this.store$.dispatch(StepAction.clearActive());
    this.store$.dispatch(StepAction.addManyActives({ idSteps }));
  }
}
