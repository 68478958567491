import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationSaleCategoryApiService } from '@_services/api/organization-sale-category-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedOrganizationSaleCategoryEffects } from './organization-sale-category-generated.effects';

@Injectable()
export class OrganizationSaleCategoryEffects extends GeneratedOrganizationSaleCategoryEffects {
  constructor(
    actions$: Actions,
    organizationSaleCategoryApiService: OrganizationSaleCategoryApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationSaleCategoryApiService, store$);
  }
}
