import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CampaignMediaEntityState } from '@_model/interfaces/campaign-media.model';

export interface CampaignMediaState extends EntityState<CampaignMediaEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CampaignMediaEntityState> = createEntityAdapter<CampaignMediaEntityState>({
  selectId: o => o.idCampaignMedia
});
export const initialState: CampaignMediaState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const campaignMediaFeatureKey = 'campaignMedia';
