import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProspectBuyingWish, ProspectBuyingWishEntityState } from '@_model/interfaces/prospect-buying-wish.model';
import { City, CityEntityState } from '@_model/interfaces/city.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import { StratalotType, StratalotTypeEntityState } from '@_model/interfaces/stratalot-type.model';
import { Prospect, ProspectEntityState } from '@_model/interfaces/prospect.model';
import {
  OrganizationBuyingWish,
  OrganizationBuyingWishEntityState
} from '@_model/interfaces/organization-buying-wish.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, prospectBuyingWishFeatureKey, ProspectBuyingWishState } from './prospect-buying-wish.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const prospectBuyingWishRelations: string[] = [
  'cities',
  'residences',
  'stratalotTypes',
  'prospects',
  'organizationBuyingWishes'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectProspectBuyingWishState =
  createFeatureSelector<ProspectBuyingWishState>(prospectBuyingWishFeatureKey);

export const selectIsLoadedProspectBuyingWish = createSelector(
  selectProspectBuyingWishState,
  (state: ProspectBuyingWishState) => state.isLoaded
);

export const selectIsLoadingProspectBuyingWish = createSelector(
  selectProspectBuyingWishState,
  (state: ProspectBuyingWishState) => state.isLoading
);

export const selectIsReadyProspectBuyingWish = createSelector(
  selectProspectBuyingWishState,
  (state: ProspectBuyingWishState) => !state.isLoading
);

export const selectIsReadyAndLoadedProspectBuyingWish = createSelector(
  selectProspectBuyingWishState,
  (state: ProspectBuyingWishState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ProspectBuyingWishModel: SelectorModel = {
  name: 'prospectBuyingWishs',
  getSelector: selectAllProspectBuyingWishsDictionary,
  isReady: selectIsReadyProspectBuyingWish
};

export const selectProspectBuyingWishsEntities = createSelector(selectProspectBuyingWishState, selectEntities);

export const selectProspectBuyingWishsArray = createSelector(selectProspectBuyingWishState, selectAll);

export const selectIdProspectBuyingWishsActive = createSelector(
  selectProspectBuyingWishState,
  (state: ProspectBuyingWishState) => state.actives
);

const prospectBuyingWishsInObject = (prospectBuyingWishs: Dictionary<ProspectBuyingWishEntityState>) => ({
  prospectBuyingWishs
});

const selectProspectBuyingWishsEntitiesDictionary = createSelector(
  selectProspectBuyingWishsEntities,
  prospectBuyingWishsInObject
);

const selectAllProspectBuyingWishsObject = createSelector(selectProspectBuyingWishsEntities, prospectBuyingWishs => {
  return hydrateAll({ prospectBuyingWishs });
});

const selectOneProspectBuyingWishDictionary = (idProspectBuyingWish: number) =>
  createSelector(selectProspectBuyingWishsEntities, prospectBuyingWishs => {
    return { prospectBuyingWishs: { [idProspectBuyingWish]: prospectBuyingWishs[idProspectBuyingWish] } };
  });

const selectOneProspectBuyingWishDictionaryWithoutChild = (idProspectBuyingWish: number) =>
  createSelector(selectProspectBuyingWishsEntities, prospectBuyingWishs => {
    return { prospectBuyingWish: prospectBuyingWishs[idProspectBuyingWish] };
  });

const selectActiveProspectBuyingWishsEntities = createSelector(
  selectIdProspectBuyingWishsActive,
  selectProspectBuyingWishsEntities,
  (actives: number[], prospectBuyingWishs: Dictionary<ProspectBuyingWishEntityState>) =>
    getProspectBuyingWishsFromActives(actives, prospectBuyingWishs)
);

function getProspectBuyingWishsFromActives(
  actives: number[],
  prospectBuyingWishs: Dictionary<ProspectBuyingWishEntityState>
): Dictionary<ProspectBuyingWishEntityState> {
  return actives.reduce((acc, idActive) => {
    if (prospectBuyingWishs[idActive]) {
      acc[idActive] = prospectBuyingWishs[idActive];
    }
    return acc;
  }, {} as Dictionary<ProspectBuyingWishEntityState>);
}

const selectAllProspectBuyingWishsSelectors: Dictionary<Selector> = {};
export function selectAllProspectBuyingWishs(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProspectBuyingWish>(
      schema,
      selectAllProspectBuyingWishsSelectors,
      selectProspectBuyingWishsEntitiesDictionary,
      getRelationSelectors,
      prospectBuyingWishRelations,
      hydrateAll,
      'prospectBuyingWish'
    );
  } else {
    return selectAllProspectBuyingWishsObject;
  }
}

export function selectAllProspectBuyingWishsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'prospectBuyingWishs'
): Selector {
  return createSelector(selectAllProspectBuyingWishs(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProspectBuyingWishEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.prospectBuyingWishs.length; i++) {
      res[customKey][result.prospectBuyingWishs[i].idProspectBuyingWish] = result.prospectBuyingWishs[i];
    }
    return res;
  });
}

export function selectOneProspectBuyingWish(schema: SelectSchema = {}, idProspectBuyingWish: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneProspectBuyingWishDictionary(idProspectBuyingWish)];
    selectors.push(...getRelationSelectors(schema, prospectBuyingWishRelations, 'prospectBuyingWish'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProspectBuyingWishDictionaryWithoutChild(idProspectBuyingWish);
  }
}

export function selectActiveProspectBuyingWishs(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveProspectBuyingWishsEntities, prospectBuyingWishs => ({ prospectBuyingWishs }))
  ];
  selectors.push(...getRelationSelectors(schema, prospectBuyingWishRelations, 'prospectBuyingWish'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  prospectBuyingWishs: Dictionary<ProspectBuyingWishEntityState>;
  prospects?: Dictionary<ProspectEntityState>;
  organizationBuyingWishes?: Dictionary<OrganizationBuyingWishEntityState>;
  cities?: Dictionary<CityEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
  stratalotTypes?: Dictionary<StratalotTypeEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { prospectBuyingWishs: (ProspectBuyingWish | null)[] } {
  const { prospectBuyingWishs, prospects, organizationBuyingWishes, cities, residences, stratalotTypes } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    prospectBuyingWishs: Object.keys(prospectBuyingWishs).map(idProspectBuyingWish =>
      hydrate(
        prospectBuyingWishs[idProspectBuyingWish] as ProspectBuyingWishEntityState,
        prospects,
        organizationBuyingWishes,
        cities,
        residences,
        stratalotTypes
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { prospectBuyingWish: ProspectBuyingWishEntityState | null } {
  const { prospectBuyingWishs, prospects, organizationBuyingWishes, cities, residences, stratalotTypes } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const prospectBuyingWish = Object.values(prospectBuyingWishs)[0];
  return {
    prospectBuyingWish: hydrate(
      prospectBuyingWish as ProspectBuyingWishEntityState,
      prospects,
      organizationBuyingWishes,
      cities,
      residences,
      stratalotTypes
    )
  };
}

function hydrate(
  prospectBuyingWish: ProspectBuyingWishEntityState,
  prospectEntities?: Dictionary<ProspectEntityState>,
  organizationBuyingWishEntities?: Dictionary<OrganizationBuyingWishEntityState>,
  cityEntities?: Dictionary<CityEntityState>,
  residenceEntities?: Dictionary<ResidenceEntityState>,
  stratalotTypeEntities?: Dictionary<StratalotTypeEntityState>
): ProspectBuyingWish | null {
  if (!prospectBuyingWish) {
    return null;
  }

  const prospectBuyingWishHydrated: ProspectBuyingWishEntityState = { ...prospectBuyingWish };
  if (prospectEntities) {
    prospectBuyingWishHydrated.prospect = prospectEntities[prospectBuyingWish.prospect as number] as Prospect;
  } else {
    delete prospectBuyingWishHydrated.prospect;
  }
  if (organizationBuyingWishEntities) {
    prospectBuyingWishHydrated.organizationBuyingWish = organizationBuyingWishEntities[
      prospectBuyingWish.organizationBuyingWish as number
    ] as OrganizationBuyingWish;
  } else {
    delete prospectBuyingWishHydrated.organizationBuyingWish;
  }

  if (cityEntities) {
    prospectBuyingWishHydrated.cities = ((prospectBuyingWishHydrated.cities as number[]) || []).map(
      id => cityEntities[id]
    ) as City[];
  } else {
    delete prospectBuyingWishHydrated.cities;
  }

  if (residenceEntities) {
    prospectBuyingWishHydrated.residences = ((prospectBuyingWishHydrated.residences as number[]) || []).map(
      id => residenceEntities[id]
    ) as Residence[];
  } else {
    delete prospectBuyingWishHydrated.residences;
  }

  if (stratalotTypeEntities) {
    prospectBuyingWishHydrated.stratalotTypes = ((prospectBuyingWishHydrated.stratalotTypes as number[]) || []).map(
      id => stratalotTypeEntities[id]
    ) as StratalotType[];
  } else {
    delete prospectBuyingWishHydrated.stratalotTypes;
  }

  return prospectBuyingWishHydrated as ProspectBuyingWish;
}
