<app-update *ngIf="updateApp"></app-update>
<mat-sidenav-container class="main-side-nav full-height">
  <mat-sidenav
    #sidenav
    [autoFocus]="false"
    [mode]="sideNavMode"
    [opened]="sideNavOpened"
    [disableClose]="true"
    class="side-menu full-height"
    id="app-side-menu-component"
  >
    <div
      *ngIf="development"
      style="
        position: absolute;
        background-color: red;
        width: 100%;
        color: white;
        text-align: center;
        font-size: 24px;
        padding: 8px 0;
      "
    >
      Développement
    </div>
    <div
      *ngIf="production && qualif"
      style="
        position: absolute;
        background-color: rgb(111, 0, 255);
        width: 100%;
        color: white;
        text-align: center;
        font-size: 24px;
        padding: 8px 0;
      "
    >
      Qualif
    </div>
    <div
      *ngIf="production && integration"
      style="
        position: absolute;
        background-color: rgb(255, 193, 7);
        width: 100%;
        color: white;
        text-align: center;
        font-size: 24px;
        padding: 8px 0;
      "
    >
      Integration
    </div>
    <div
      *ngIf="production && staging"
      style="
        position: absolute;
        background-color: rgb(229, 16, 88);
        width: 100%;
        color: white;
        text-align: center;
        font-size: 24px;
        padding: 8px 0;
      "
    >
      Staging
    </div>
    <div
      *ngIf="production && test"
      style="
        position: absolute;
        background-color: rgb(70, 186, 208);
        width: 100%;
        color: white;
        text-align: center;
        font-size: 24px;
        padding: 8px 0;
      "
    >
      {{ branch }}
    </div>
    <app-side-menu
      fxFlex
      [menuSections]="menuSections"
      [companies]="companies"
      [organization]="organization"
      [isUserAdmin]="isUserAdmin"
      [activeSaleCategorie]="activeSaleCategorie"
      [categorieVentes]="categorieVentes"
      (companyChange)="onCompanyChange($event)"
      (navigateToLoginPage)="onNavigateToLoginPage()"
      (saleCategoryChange)="onCategorieVenteChange($event)"
      (sectionChange)="onSectionChange()"
      fxLayout="column"
      fxLayoutAlign="start stretch"
    ></app-side-menu>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
