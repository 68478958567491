import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyUbiflowActions from './company-ubiflow.actions';
import { adapter, initialState, CompanyUbiflowState } from './company-ubiflow.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyUbiflowEntityState } from '@_model/interfaces/company-ubiflow.model';

export const companyUbiflowReducersGeneratedFunctions: ReducerTypes<CompanyUbiflowState, readonly ActionCreator[]>[] = [
  on(CompanyUbiflowActions.getOneCompanyUbiflow, (state: CompanyUbiflowState) => setLoadingsState(state, true)),
  on(CompanyUbiflowActions.getManyCompanyUbiflows, (state: CompanyUbiflowState) => setLoadingsState(state, true)),
  on(CompanyUbiflowActions.upsertOneCompanyUbiflow, (state: CompanyUbiflowState) => setLoadingsState(state, true)),

  on(CompanyUbiflowActions.upsertManyCompanyUbiflowsSuccess, (state: CompanyUbiflowState, { companyUbiflows }) =>
    adapter.upsertMany(companyUbiflows, setLoadingsState(state, false))
  ),
  on(CompanyUbiflowActions.deleteOneCompanyUbiflow, (state: CompanyUbiflowState) => setLoadingsState(state, true)),
  on(CompanyUbiflowActions.deleteOneCompanyUbiflowSuccess, (state: CompanyUbiflowState, { idCompanyUbiflow }) =>
    adapter.removeOne(idCompanyUbiflow, setLoadingsState(state, false))
  ),
  on(CompanyUbiflowActions.clearActive, (state: CompanyUbiflowState) => ({ ...state, actives: [] })),
  on(CompanyUbiflowActions.addManyActives, (state: CompanyUbiflowState, { idCompanyUbiflows }) => ({
    ...state,
    actives: state.actives.concat(idCompanyUbiflows)
  })),
  on(CompanyUbiflowActions.deleteOneActive, (state: CompanyUbiflowState, { idCompanyUbiflow }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCompanyUbiflow)
  })),

  on(CompanyUbiflowActions.clearStore, () => initialState),

  on(CompanyUbiflowActions.addCompanySuccess, (state: CompanyUbiflowState, { idCompanyUbiflow, idCompany }) => {
    if (!state.entities[idCompanyUbiflow]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idCompanyUbiflow]: {
          ...state.entities[idCompanyUbiflow],
          company: idCompany
        }
      }
    };
  }),

  on(
    CompanyUbiflowActions.deleteManyCompanySuccess,
    (state: CompanyUbiflowState, { idCompanies, idCompanyUbiflows }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyUbiflows.reduce((entities, idCompanyUbiflow) => {
            if (!state.entities[idCompanyUbiflow]?.company) {
              return entities;
            }
            entities[idCompanyUbiflow] = {
              ...state.entities[idCompanyUbiflow],
              company: idCompanies.some((idCompany: number) => idCompany === state.entities[idCompanyUbiflow]?.company)
                ? undefined
                : state.entities[idCompanyUbiflow]?.company
            } as CompanyUbiflowEntityState;
            return entities;
          }, {} as Dictionary<CompanyUbiflowEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyUbiflowState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyUbiflowState {
  return { ...state, isLoaded, isLoading };
}
