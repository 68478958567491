import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Profil, ProfilEntityState } from '@_model/interfaces/profil.model';
import { User, UserEntityState } from '@_model/interfaces/user.model';
import { Droit, DroitEntityState } from '@_model/interfaces/droit.model';
import {
  OrganizationLeadTodoProfil,
  OrganizationLeadTodoProfilEntityState
} from '@_model/interfaces/organization-lead-todo-profil.model';
import {
  OrganizationResidenceTodoProfil,
  OrganizationResidenceTodoProfilEntityState
} from '@_model/interfaces/organization-residence-todo-profil.model';
import {
  OrganizationStratalotTodoProfil,
  OrganizationStratalotTodoProfilEntityState
} from '@_model/interfaces/organization-stratalot-todo-profil.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, profilFeatureKey, ProfilState } from './profil.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const profilRelations: string[] = [
  'users',
  'droits',
  'organizationLeadTodoProfils',
  'organizationResidenceTodoProfils',
  'organizationStratalotTodoProfils',
  'organizations'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectProfilState = createFeatureSelector<ProfilState>(profilFeatureKey);

export const selectIsLoadedProfil = createSelector(selectProfilState, (state: ProfilState) => state.isLoaded);

export const selectIsLoadingProfil = createSelector(selectProfilState, (state: ProfilState) => state.isLoading);

export const selectIsReadyProfil = createSelector(selectProfilState, (state: ProfilState) => !state.isLoading);

export const selectIsReadyAndLoadedProfil = createSelector(
  selectProfilState,
  (state: ProfilState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ProfilModel: SelectorModel = {
  name: 'profils',
  getSelector: selectAllProfilsDictionary,
  isReady: selectIsReadyProfil
};

export const selectProfilsEntities = createSelector(selectProfilState, selectEntities);

export const selectProfilsArray = createSelector(selectProfilState, selectAll);

export const selectIdProfilsActive = createSelector(selectProfilState, (state: ProfilState) => state.actives);

const profilsInObject = (profils: Dictionary<ProfilEntityState>) => ({ profils });

const selectProfilsEntitiesDictionary = createSelector(selectProfilsEntities, profilsInObject);

const selectAllProfilsObject = createSelector(selectProfilsEntities, profils => {
  return hydrateAll({ profils });
});

const selectOneProfilDictionary = (idProfil: number) =>
  createSelector(selectProfilsEntities, profils => {
    return { profils: { [idProfil]: profils[idProfil] } };
  });

const selectOneProfilDictionaryWithoutChild = (idProfil: number) =>
  createSelector(selectProfilsEntities, profils => {
    return { profil: profils[idProfil] };
  });

const selectActiveProfilsEntities = createSelector(
  selectIdProfilsActive,
  selectProfilsEntities,
  (actives: number[], profils: Dictionary<ProfilEntityState>) => getProfilsFromActives(actives, profils)
);

function getProfilsFromActives(
  actives: number[],
  profils: Dictionary<ProfilEntityState>
): Dictionary<ProfilEntityState> {
  return actives.reduce((acc, idActive) => {
    if (profils[idActive]) {
      acc[idActive] = profils[idActive];
    }
    return acc;
  }, {} as Dictionary<ProfilEntityState>);
}

const selectAllProfilsSelectors: Dictionary<Selector> = {};
export function selectAllProfils(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Profil>(
      schema,
      selectAllProfilsSelectors,
      selectProfilsEntitiesDictionary,
      getRelationSelectors,
      profilRelations,
      hydrateAll,
      'profil'
    );
  } else {
    return selectAllProfilsObject;
  }
}

export function selectAllProfilsDictionary(schema: SelectSchema = {}, customKey: string = 'profils'): Selector {
  return createSelector(selectAllProfils(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProfilEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.profils.length; i++) {
      res[customKey][result.profils[i].idProfil] = result.profils[i];
    }
    return res;
  });
}

export function selectOneProfil(schema: SelectSchema = {}, idProfil: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneProfilDictionary(idProfil)];
    selectors.push(...getRelationSelectors(schema, profilRelations, 'profil'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProfilDictionaryWithoutChild(idProfil);
  }
}

export function selectActiveProfils(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [createSelector(selectActiveProfilsEntities, profils => ({ profils }))];
  selectors.push(...getRelationSelectors(schema, profilRelations, 'profil'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  profils: Dictionary<ProfilEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  users?: Dictionary<UserEntityState>;
  droits?: Dictionary<DroitEntityState>;
  organizationLeadTodoProfils?: Dictionary<OrganizationLeadTodoProfilEntityState>;
  organizationResidenceTodoProfils?: Dictionary<OrganizationResidenceTodoProfilEntityState>;
  organizationStratalotTodoProfils?: Dictionary<OrganizationStratalotTodoProfilEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { profils: (Profil | null)[] } {
  const {
    profils,
    organizations,
    users,
    droits,
    organizationLeadTodoProfils,
    organizationResidenceTodoProfils,
    organizationStratalotTodoProfils
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    profils: Object.keys(profils).map(idProfil =>
      hydrate(
        profils[idProfil] as ProfilEntityState,
        organizations,
        users,
        droits,
        organizationLeadTodoProfils,
        organizationResidenceTodoProfils,
        organizationStratalotTodoProfils
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { profil: ProfilEntityState | null } {
  const {
    profils,
    organizations,
    users,
    droits,
    organizationLeadTodoProfils,
    organizationResidenceTodoProfils,
    organizationStratalotTodoProfils
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const profil = Object.values(profils)[0];
  return {
    profil: hydrate(
      profil as ProfilEntityState,
      organizations,
      users,
      droits,
      organizationLeadTodoProfils,
      organizationResidenceTodoProfils,
      organizationStratalotTodoProfils
    )
  };
}

function hydrate(
  profil: ProfilEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  userEntities?: Dictionary<UserEntityState>,
  droitEntities?: Dictionary<DroitEntityState>,
  organizationLeadTodoProfilEntities?: Dictionary<OrganizationLeadTodoProfilEntityState>,
  organizationResidenceTodoProfilEntities?: Dictionary<OrganizationResidenceTodoProfilEntityState>,
  organizationStratalotTodoProfilEntities?: Dictionary<OrganizationStratalotTodoProfilEntityState>
): Profil | null {
  if (!profil) {
    return null;
  }

  const profilHydrated: ProfilEntityState = { ...profil };
  if (organizationEntities) {
    profilHydrated.organization = organizationEntities[profil.organization as number] as Organization;
  } else {
    delete profilHydrated.organization;
  }

  if (userEntities) {
    profilHydrated.users = ((profilHydrated.users as number[]) || []).map(id => userEntities[id]) as User[];
  } else {
    delete profilHydrated.users;
  }

  if (droitEntities) {
    profilHydrated.droits = ((profilHydrated.droits as number[]) || []).map(id => droitEntities[id]) as Droit[];
  } else {
    delete profilHydrated.droits;
  }

  if (organizationLeadTodoProfilEntities) {
    profilHydrated.organizationLeadTodoProfils = ((profilHydrated.organizationLeadTodoProfils as number[]) || []).map(
      id => organizationLeadTodoProfilEntities[id]
    ) as OrganizationLeadTodoProfil[];
  } else {
    delete profilHydrated.organizationLeadTodoProfils;
  }

  if (organizationResidenceTodoProfilEntities) {
    profilHydrated.organizationResidenceTodoProfils = (
      (profilHydrated.organizationResidenceTodoProfils as number[]) || []
    ).map(id => organizationResidenceTodoProfilEntities[id]) as OrganizationResidenceTodoProfil[];
  } else {
    delete profilHydrated.organizationResidenceTodoProfils;
  }

  if (organizationStratalotTodoProfilEntities) {
    profilHydrated.organizationStratalotTodoProfils = (
      (profilHydrated.organizationStratalotTodoProfils as number[]) || []
    ).map(id => organizationStratalotTodoProfilEntities[id]) as OrganizationStratalotTodoProfil[];
  } else {
    delete profilHydrated.organizationStratalotTodoProfils;
  }

  return profilHydrated as Profil;
}
