import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { MetierFamilieEntityState } from '@_model/interfaces/metier-familie.model';

export interface MetierFamilieState extends EntityState<MetierFamilieEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<MetierFamilieEntityState> = createEntityAdapter<MetierFamilieEntityState>({
  selectId: o => o.idMetierFamilie
});
export const initialState: MetierFamilieState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const metierFamilieFeatureKey = 'metierFamilie';
