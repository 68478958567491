import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as MetierFamilieActions from './metier-familie.actions';
import { adapter, initialState, MetierFamilieState } from './metier-familie.state';
import { Dictionary } from '@ngrx/entity';
import { MetierFamilieEntityState } from '@_model/interfaces/metier-familie.model';

export const metierFamilieReducersGeneratedFunctions: ReducerTypes<MetierFamilieState, readonly ActionCreator[]>[] = [
  on(MetierFamilieActions.getOneMetierFamilie, (state: MetierFamilieState) => setLoadingsState(state, true)),
  on(MetierFamilieActions.getManyMetierFamilies, (state: MetierFamilieState) => setLoadingsState(state, true)),
  on(MetierFamilieActions.upsertOneMetierFamilie, (state: MetierFamilieState) => setLoadingsState(state, true)),

  on(MetierFamilieActions.upsertManyMetierFamiliesSuccess, (state: MetierFamilieState, { metierFamilies }) =>
    adapter.upsertMany(metierFamilies, setLoadingsState(state, false))
  ),
  on(MetierFamilieActions.deleteOneMetierFamilie, (state: MetierFamilieState) => setLoadingsState(state, true)),
  on(MetierFamilieActions.deleteOneMetierFamilieSuccess, (state: MetierFamilieState, { idMetierFamilie }) =>
    adapter.removeOne(idMetierFamilie, setLoadingsState(state, false))
  ),
  on(MetierFamilieActions.clearActive, (state: MetierFamilieState) => ({ ...state, actives: [] })),
  on(MetierFamilieActions.addManyActives, (state: MetierFamilieState, { idMetierFamilies }) => ({
    ...state,
    actives: state.actives.concat(idMetierFamilies)
  })),
  on(MetierFamilieActions.deleteOneActive, (state: MetierFamilieState, { idMetierFamilie }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idMetierFamilie)
  })),

  on(MetierFamilieActions.clearStore, () => initialState),

  on(
    MetierFamilieActions.addManyOrganizationMetierSuccess,
    (state: MetierFamilieState, { idMetierFamilie, idOrganizationMetiers }) => {
      if (!state.entities[idMetierFamilie]) {
        return state;
      }
      const organizationMetiers = (state.entities[idMetierFamilie]?.organizationMetiers as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idMetierFamilie]: {
            ...state.entities[idMetierFamilie],
            organizationMetiers: organizationMetiers.concat(
              idOrganizationMetiers.filter(id => organizationMetiers.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    MetierFamilieActions.deleteManyOrganizationMetierSuccess,
    (state: MetierFamilieState, { idOrganizationMetiers, idMetierFamilies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idMetierFamilies.reduce((entities, idMetierFamilie) => {
            if (!state.entities[idMetierFamilie]?.organizationMetiers) {
              return entities;
            }
            entities[idMetierFamilie] = {
              ...state.entities[idMetierFamilie],
              organizationMetiers: (state.entities[idMetierFamilie]?.organizationMetiers as number[])?.filter(
                (idOrganizationMetier: number) =>
                  !idOrganizationMetiers.some((id: number) => id === idOrganizationMetier)
              )
            } as MetierFamilieEntityState;
            return entities;
          }, {} as Dictionary<MetierFamilieEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: MetierFamilieState,
  isLoading: boolean,
  isLoaded: boolean = true
): MetierFamilieState {
  return { ...state, isLoaded, isLoading };
}
