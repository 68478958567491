import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidenceStudyReasonActions from './residence-study-reason.actions';
import { adapter, initialState, ResidenceStudyReasonState } from './residence-study-reason.state';
import { Dictionary } from '@ngrx/entity';
import { ResidenceStudyReasonEntityState } from '@_model/interfaces/residence-study-reason.model';

export const residenceStudyReasonReducersGeneratedFunctions: ReducerTypes<
  ResidenceStudyReasonState,
  readonly ActionCreator[]
>[] = [
  on(ResidenceStudyReasonActions.getOneResidenceStudyReason, (state: ResidenceStudyReasonState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceStudyReasonActions.getManyResidenceStudyReasons, (state: ResidenceStudyReasonState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceStudyReasonActions.upsertOneResidenceStudyReason, (state: ResidenceStudyReasonState) =>
    setLoadingsState(state, true)
  ),

  on(
    ResidenceStudyReasonActions.upsertManyResidenceStudyReasonsSuccess,
    (state: ResidenceStudyReasonState, { residenceStudyReasons }) =>
      adapter.upsertMany(residenceStudyReasons, setLoadingsState(state, false))
  ),
  on(ResidenceStudyReasonActions.deleteOneResidenceStudyReason, (state: ResidenceStudyReasonState) =>
    setLoadingsState(state, true)
  ),
  on(
    ResidenceStudyReasonActions.deleteOneResidenceStudyReasonSuccess,
    (state: ResidenceStudyReasonState, { idResidenceStudyReason }) =>
      adapter.removeOne(idResidenceStudyReason, setLoadingsState(state, false))
  ),
  on(ResidenceStudyReasonActions.clearActive, (state: ResidenceStudyReasonState) => ({ ...state, actives: [] })),
  on(ResidenceStudyReasonActions.addManyActives, (state: ResidenceStudyReasonState, { idResidenceStudyReasons }) => ({
    ...state,
    actives: state.actives.concat(idResidenceStudyReasons)
  })),
  on(ResidenceStudyReasonActions.deleteOneActive, (state: ResidenceStudyReasonState, { idResidenceStudyReason }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idResidenceStudyReason)
  })),

  on(ResidenceStudyReasonActions.clearStore, () => initialState),

  on(
    ResidenceStudyReasonActions.addResidenceStudySuccess,
    (state: ResidenceStudyReasonState, { idResidenceStudyReason, idResidenceStudy }) => {
      if (!state.entities[idResidenceStudyReason]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudyReason]: {
            ...state.entities[idResidenceStudyReason],
            residenceStudy: idResidenceStudy
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyReasonActions.deleteManyResidenceStudySuccess,
    (state: ResidenceStudyReasonState, { idResidenceStudies, idResidenceStudyReasons }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudyReasons.reduce((entities, idResidenceStudyReason) => {
            if (!state.entities[idResidenceStudyReason]?.residenceStudy) {
              return entities;
            }
            entities[idResidenceStudyReason] = {
              ...state.entities[idResidenceStudyReason],
              residenceStudy: idResidenceStudies.some(
                (idResidenceStudy: number) =>
                  idResidenceStudy === state.entities[idResidenceStudyReason]?.residenceStudy
              )
                ? undefined
                : state.entities[idResidenceStudyReason]?.residenceStudy
            } as ResidenceStudyReasonEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyReasonEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceStudyReasonActions.addCompanyStudyReasonSuccess,
    (state: ResidenceStudyReasonState, { idResidenceStudyReason, idCompanyStudyReason }) => {
      if (!state.entities[idResidenceStudyReason]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudyReason]: {
            ...state.entities[idResidenceStudyReason],
            companyStudyReason: idCompanyStudyReason
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyReasonActions.deleteManyCompanyStudyReasonSuccess,
    (state: ResidenceStudyReasonState, { idCompanyStudyReasons, idResidenceStudyReasons }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudyReasons.reduce((entities, idResidenceStudyReason) => {
            if (!state.entities[idResidenceStudyReason]?.companyStudyReason) {
              return entities;
            }
            entities[idResidenceStudyReason] = {
              ...state.entities[idResidenceStudyReason],
              companyStudyReason: idCompanyStudyReasons.some(
                (idCompanyStudyReason: number) =>
                  idCompanyStudyReason === state.entities[idResidenceStudyReason]?.companyStudyReason
              )
                ? undefined
                : state.entities[idResidenceStudyReason]?.companyStudyReason
            } as ResidenceStudyReasonEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyReasonEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidenceStudyReasonState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidenceStudyReasonState {
  return { ...state, isLoaded, isLoading };
}
