import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationStratalotAvancementActions from './organization-stratalot-avancement.actions';
import { adapter, initialState, OrganizationStratalotAvancementState } from './organization-stratalot-avancement.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationStratalotAvancementEntityState } from '@_model/interfaces/organization-stratalot-avancement.model';

export const organizationStratalotAvancementReducersGeneratedFunctions: ReducerTypes<
  OrganizationStratalotAvancementState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationStratalotAvancementActions.getOneOrganizationStratalotAvancement,
    (state: OrganizationStratalotAvancementState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationStratalotAvancementActions.getManyOrganizationStratalotAvancements,
    (state: OrganizationStratalotAvancementState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationStratalotAvancementActions.upsertOneOrganizationStratalotAvancement,
    (state: OrganizationStratalotAvancementState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationStratalotAvancementActions.upsertManyOrganizationStratalotAvancementsSuccess,
    (state: OrganizationStratalotAvancementState, { organizationStratalotAvancements }) =>
      adapter.upsertMany(organizationStratalotAvancements, setLoadingsState(state, false))
  ),
  on(
    OrganizationStratalotAvancementActions.deleteOneOrganizationStratalotAvancement,
    (state: OrganizationStratalotAvancementState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationStratalotAvancementActions.deleteOneOrganizationStratalotAvancementSuccess,
    (state: OrganizationStratalotAvancementState, { idOrganizationStratalotAvancement }) =>
      adapter.removeOne(idOrganizationStratalotAvancement, setLoadingsState(state, false))
  ),
  on(OrganizationStratalotAvancementActions.clearActive, (state: OrganizationStratalotAvancementState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationStratalotAvancementActions.addManyActives,
    (state: OrganizationStratalotAvancementState, { idOrganizationStratalotAvancements }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationStratalotAvancements)
    })
  ),
  on(
    OrganizationStratalotAvancementActions.deleteOneActive,
    (state: OrganizationStratalotAvancementState, { idOrganizationStratalotAvancement }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationStratalotAvancement)
    })
  ),

  on(OrganizationStratalotAvancementActions.clearStore, () => initialState),

  on(
    OrganizationStratalotAvancementActions.addManyStratalotSuccess,
    (state: OrganizationStratalotAvancementState, { idOrganizationStratalotAvancement, idStratalots }) => {
      if (!state.entities[idOrganizationStratalotAvancement]) {
        return state;
      }
      const stratalots = (state.entities[idOrganizationStratalotAvancement]?.stratalots as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotAvancement]: {
            ...state.entities[idOrganizationStratalotAvancement],
            stratalots: stratalots.concat(idStratalots.filter(id => stratalots.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotAvancementActions.deleteManyStratalotSuccess,
    (state: OrganizationStratalotAvancementState, { idStratalots, idOrganizationStratalotAvancements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotAvancements.reduce((entities, idOrganizationStratalotAvancement) => {
            if (!state.entities[idOrganizationStratalotAvancement]?.stratalots) {
              return entities;
            }
            entities[idOrganizationStratalotAvancement] = {
              ...state.entities[idOrganizationStratalotAvancement],
              stratalots: (state.entities[idOrganizationStratalotAvancement]?.stratalots as number[])?.filter(
                (idStratalot: number) => !idStratalots.some((id: number) => id === idStratalot)
              )
            } as OrganizationStratalotAvancementEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotAvancementEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotAvancementActions.addOrganizationSuccess,
    (state: OrganizationStratalotAvancementState, { idOrganizationStratalotAvancement, idOrganization }) => {
      if (!state.entities[idOrganizationStratalotAvancement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotAvancement]: {
            ...state.entities[idOrganizationStratalotAvancement],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotAvancementActions.deleteManyOrganizationSuccess,
    (state: OrganizationStratalotAvancementState, { idOrganizations, idOrganizationStratalotAvancements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotAvancements.reduce((entities, idOrganizationStratalotAvancement) => {
            if (!state.entities[idOrganizationStratalotAvancement]?.organization) {
              return entities;
            }
            entities[idOrganizationStratalotAvancement] = {
              ...state.entities[idOrganizationStratalotAvancement],
              organization: idOrganizations.some(
                (idOrganization: number) =>
                  idOrganization === state.entities[idOrganizationStratalotAvancement]?.organization
              )
                ? undefined
                : state.entities[idOrganizationStratalotAvancement]?.organization
            } as OrganizationStratalotAvancementEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotAvancementEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotAvancementActions.addStepSuccess,
    (state: OrganizationStratalotAvancementState, { idOrganizationStratalotAvancement, idStep }) => {
      if (!state.entities[idOrganizationStratalotAvancement]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotAvancement]: {
            ...state.entities[idOrganizationStratalotAvancement],
            step: idStep
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotAvancementActions.deleteManyStepSuccess,
    (state: OrganizationStratalotAvancementState, { idSteps, idOrganizationStratalotAvancements }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotAvancements.reduce((entities, idOrganizationStratalotAvancement) => {
            if (!state.entities[idOrganizationStratalotAvancement]?.step) {
              return entities;
            }
            entities[idOrganizationStratalotAvancement] = {
              ...state.entities[idOrganizationStratalotAvancement],
              step: idSteps.some((idStep: number) => idStep === state.entities[idOrganizationStratalotAvancement]?.step)
                ? undefined
                : state.entities[idOrganizationStratalotAvancement]?.step
            } as OrganizationStratalotAvancementEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotAvancementEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationStratalotAvancementState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationStratalotAvancementState {
  return { ...state, isLoaded, isLoading };
}
