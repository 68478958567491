import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyMediaEntityState } from '@_model/interfaces/company-media.model';

export interface CompanyMediaState extends EntityState<CompanyMediaEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyMediaEntityState> = createEntityAdapter<CompanyMediaEntityState>({
  selectId: o => o.idCompanyMedia
});
export const initialState: CompanyMediaState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyMediaFeatureKey = 'companyMedia';
