import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationResidenceStudyTodo,
  OrganizationResidenceStudyTodoEntityState
} from '@_model/interfaces/organization-residence-study-todo.model';
import { ResidenceStudyTodo, ResidenceStudyTodoEntityState } from '@_model/interfaces/residence-study-todo.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationResidenceStudyTodoFeatureKey,
  OrganizationResidenceStudyTodoState
} from './organization-residence-study-todo.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationResidenceStudyTodoRelations: string[] = ['residenceStudyTodos', 'organizations'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationResidenceStudyTodoState = createFeatureSelector<OrganizationResidenceStudyTodoState>(
  organizationResidenceStudyTodoFeatureKey
);

export const selectIsLoadedOrganizationResidenceStudyTodo = createSelector(
  selectOrganizationResidenceStudyTodoState,
  (state: OrganizationResidenceStudyTodoState) => state.isLoaded
);

export const selectIsLoadingOrganizationResidenceStudyTodo = createSelector(
  selectOrganizationResidenceStudyTodoState,
  (state: OrganizationResidenceStudyTodoState) => state.isLoading
);

export const selectIsReadyOrganizationResidenceStudyTodo = createSelector(
  selectOrganizationResidenceStudyTodoState,
  (state: OrganizationResidenceStudyTodoState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationResidenceStudyTodo = createSelector(
  selectOrganizationResidenceStudyTodoState,
  (state: OrganizationResidenceStudyTodoState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationResidenceStudyTodoModel: SelectorModel = {
  name: 'organizationResidenceStudyTodos',
  getSelector: selectAllOrganizationResidenceStudyTodosDictionary,
  isReady: selectIsReadyOrganizationResidenceStudyTodo
};

export const selectOrganizationResidenceStudyTodosEntities = createSelector(
  selectOrganizationResidenceStudyTodoState,
  selectEntities
);

export const selectOrganizationResidenceStudyTodosArray = createSelector(
  selectOrganizationResidenceStudyTodoState,
  selectAll
);

export const selectIdOrganizationResidenceStudyTodosActive = createSelector(
  selectOrganizationResidenceStudyTodoState,
  (state: OrganizationResidenceStudyTodoState) => state.actives
);

const organizationResidenceStudyTodosInObject = (
  organizationResidenceStudyTodos: Dictionary<OrganizationResidenceStudyTodoEntityState>
) => ({ organizationResidenceStudyTodos });

const selectOrganizationResidenceStudyTodosEntitiesDictionary = createSelector(
  selectOrganizationResidenceStudyTodosEntities,
  organizationResidenceStudyTodosInObject
);

const selectAllOrganizationResidenceStudyTodosObject = createSelector(
  selectOrganizationResidenceStudyTodosEntities,
  organizationResidenceStudyTodos => {
    return hydrateAll({ organizationResidenceStudyTodos });
  }
);

const selectOneOrganizationResidenceStudyTodoDictionary = (idOrganizationResidenceStudyTodo: number) =>
  createSelector(selectOrganizationResidenceStudyTodosEntities, organizationResidenceStudyTodos => {
    return {
      organizationResidenceStudyTodos: {
        [idOrganizationResidenceStudyTodo]: organizationResidenceStudyTodos[idOrganizationResidenceStudyTodo]
      }
    };
  });

const selectOneOrganizationResidenceStudyTodoDictionaryWithoutChild = (idOrganizationResidenceStudyTodo: number) =>
  createSelector(selectOrganizationResidenceStudyTodosEntities, organizationResidenceStudyTodos => {
    return { organizationResidenceStudyTodo: organizationResidenceStudyTodos[idOrganizationResidenceStudyTodo] };
  });

const selectActiveOrganizationResidenceStudyTodosEntities = createSelector(
  selectIdOrganizationResidenceStudyTodosActive,
  selectOrganizationResidenceStudyTodosEntities,
  (actives: number[], organizationResidenceStudyTodos: Dictionary<OrganizationResidenceStudyTodoEntityState>) =>
    getOrganizationResidenceStudyTodosFromActives(actives, organizationResidenceStudyTodos)
);

function getOrganizationResidenceStudyTodosFromActives(
  actives: number[],
  organizationResidenceStudyTodos: Dictionary<OrganizationResidenceStudyTodoEntityState>
): Dictionary<OrganizationResidenceStudyTodoEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationResidenceStudyTodos[idActive]) {
      acc[idActive] = organizationResidenceStudyTodos[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationResidenceStudyTodoEntityState>);
}

const selectAllOrganizationResidenceStudyTodosSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationResidenceStudyTodos(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationResidenceStudyTodo>(
      schema,
      selectAllOrganizationResidenceStudyTodosSelectors,
      selectOrganizationResidenceStudyTodosEntitiesDictionary,
      getRelationSelectors,
      organizationResidenceStudyTodoRelations,
      hydrateAll,
      'organizationResidenceStudyTodo'
    );
  } else {
    return selectAllOrganizationResidenceStudyTodosObject;
  }
}

export function selectAllOrganizationResidenceStudyTodosDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationResidenceStudyTodos'
): Selector {
  return createSelector(selectAllOrganizationResidenceStudyTodos(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationResidenceStudyTodoEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationResidenceStudyTodos.length; i++) {
      res[customKey][result.organizationResidenceStudyTodos[i].idOrganizationResidenceStudyTodo] =
        result.organizationResidenceStudyTodos[i];
    }
    return res;
  });
}

export function selectOneOrganizationResidenceStudyTodo(
  schema: SelectSchema = {},
  idOrganizationResidenceStudyTodo: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationResidenceStudyTodoDictionary(idOrganizationResidenceStudyTodo)];
    selectors.push(
      ...getRelationSelectors(schema, organizationResidenceStudyTodoRelations, 'organizationResidenceStudyTodo')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationResidenceStudyTodoDictionaryWithoutChild(idOrganizationResidenceStudyTodo);
  }
}

export function selectActiveOrganizationResidenceStudyTodos(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationResidenceStudyTodosEntities, organizationResidenceStudyTodos => ({
      organizationResidenceStudyTodos
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, organizationResidenceStudyTodoRelations, 'organizationResidenceStudyTodo')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationResidenceStudyTodos: Dictionary<OrganizationResidenceStudyTodoEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  residenceStudyTodos?: Dictionary<ResidenceStudyTodoEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationResidenceStudyTodos: (OrganizationResidenceStudyTodo | null)[];
} {
  const { organizationResidenceStudyTodos, organizations, residenceStudyTodos } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationResidenceStudyTodos: Object.keys(organizationResidenceStudyTodos).map(
      idOrganizationResidenceStudyTodo =>
        hydrate(
          organizationResidenceStudyTodos[
            idOrganizationResidenceStudyTodo
          ] as OrganizationResidenceStudyTodoEntityState,
          organizations,
          residenceStudyTodos
        )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationResidenceStudyTodo: OrganizationResidenceStudyTodoEntityState | null;
} {
  const { organizationResidenceStudyTodos, organizations, residenceStudyTodos } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationResidenceStudyTodo = Object.values(organizationResidenceStudyTodos)[0];
  return {
    organizationResidenceStudyTodo: hydrate(
      organizationResidenceStudyTodo as OrganizationResidenceStudyTodoEntityState,
      organizations,
      residenceStudyTodos
    )
  };
}

function hydrate(
  organizationResidenceStudyTodo: OrganizationResidenceStudyTodoEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  residenceStudyTodoEntities?: Dictionary<ResidenceStudyTodoEntityState>
): OrganizationResidenceStudyTodo | null {
  if (!organizationResidenceStudyTodo) {
    return null;
  }

  const organizationResidenceStudyTodoHydrated: OrganizationResidenceStudyTodoEntityState = {
    ...organizationResidenceStudyTodo
  };
  if (organizationEntities) {
    organizationResidenceStudyTodoHydrated.organization = organizationEntities[
      organizationResidenceStudyTodo.organization as number
    ] as Organization;
  } else {
    delete organizationResidenceStudyTodoHydrated.organization;
  }

  if (residenceStudyTodoEntities) {
    organizationResidenceStudyTodoHydrated.residenceStudyTodos = (
      (organizationResidenceStudyTodoHydrated.residenceStudyTodos as number[]) || []
    ).map(id => residenceStudyTodoEntities[id]) as ResidenceStudyTodo[];
  } else {
    delete organizationResidenceStudyTodoHydrated.residenceStudyTodos;
  }

  return organizationResidenceStudyTodoHydrated as OrganizationResidenceStudyTodo;
}
