import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationResidenceTodoAppliedRuleEntityState } from '@_model/interfaces/organization-residence-todo-applied-rule.model';

export interface OrganizationResidenceTodoAppliedRuleState
  extends EntityState<OrganizationResidenceTodoAppliedRuleEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationResidenceTodoAppliedRuleEntityState> =
  createEntityAdapter<OrganizationResidenceTodoAppliedRuleEntityState>({
    selectId: o => o.idOrganizationResidenceTodoAppliedRule
  });
export const initialState: OrganizationResidenceTodoAppliedRuleState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationResidenceTodoAppliedRuleFeatureKey = 'organizationResidenceTodoAppliedRule';
