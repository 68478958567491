import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CampaignMedia, CampaignMediaEntityState } from '@_model/interfaces/campaign-media.model';
import { CampaignMediaRelationsIds } from './campaign-media-generated.effects';

export const getOneCampaignMedia = createAction(
  '[CampaignMedia] Get One CampaignMedia',
  props<{ idCampaignMedia: number; params?: any }>()
);

export const getManyCampaignMedias = createAction('[CampaignMedia] Get Many CampaignMedias', props<{ params: any }>());

export const addManyActives = createAction(
  '[CampaignMedia] Add Many Actives CampaignMedia',
  props<{ idCampaignMedias: number[] }>()
);

export const deleteOneActive = createAction(
  '[CampaignMedia] Delete One Active CampaignMedia',
  props<{ idCampaignMedia: number }>()
);

export const clearActive = createAction('[CampaignMedia] Clear Active CampaignMedia');

export const upsertOneCampaignMedia = createAction(
  '[CampaignMedia] Upsert One CampaignMedia',
  props<{
    campaignMedia: Partial<CampaignMedia>;
    ids?: CampaignMediaRelationsIds;
  }>()
);

export const upsertManyCampaignMedias = createAction(
  '[CampaignMedia] Upsert Many CampaignMedias',
  props<{
    campaignMedias: Partial<CampaignMedia>[];
    ids?: CampaignMediaRelationsIds;
  }>()
);

export const upsertManyCampaignMediasSuccess = createAction(
  '[CampaignMedia] Create Many CampaignMedias Success',
  props<{ campaignMedias: CampaignMediaEntityState[] }>()
);

export const deleteOneCampaignMedia = createAction(
  '[CampaignMedia] Delete One CampaignMedia',
  props<{ idCampaignMedia: number }>()
);

export const deleteOneCampaignMediaSuccess = createAction(
  '[CampaignMedia] Delete One CampaignMedia Success',
  props<{ idCampaignMedia: number }>()
);

export const normalizeManyCampaignMediasAfterUpsert = createAction(
  '[CampaignMedia] Normalize Many CampaignMedias After Upsert',
  props<{ campaignMedias: CampaignMediaEntityState[] }>()
);

export const campaignMediasFailure = createAction(
  '[CampaignMedia] CampaignMedias Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CampaignMedia] Clear CampaignMedias');

export const addCompanyMediaSuccess = createAction(
  '[CampaignMedia] Add CompanyMedia',
  props<{ idCampaignMedia: number; idCompanyMedia: number }>()
);

export const deleteManyCompanyMediaSuccess = createAction(
  '[CampaignMedia] Delete Many CompanyMedia',
  props<{ idCompanyMedias: number[]; idCampaignMedias: number[] }>()
);

export const addStratalotCampaignSuccess = createAction(
  '[CampaignMedia] Add StratalotCampaign',
  props<{ idCampaignMedia: number; idStratalotCampaign: number }>()
);

export const deleteManyStratalotCampaignSuccess = createAction(
  '[CampaignMedia] Delete Many StratalotCampaign',
  props<{ idStratalotCampaigns: number[]; idCampaignMedias: number[] }>()
);
