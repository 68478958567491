import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { StratalotPriceValue, StratalotPriceValueEntityState } from '@_model/interfaces/stratalot-price-value.model';
import { StratalotPriceValueApiService } from '@_services/api/stratalot-price-value-api.service';
import * as stratalotPriceValueActions from '@_store/stratalot-price-value/stratalot-price-value.actions';
import { getActionsToNormalizeStratalotPriceValue } from '@_config/store/normalization.generated';
import { selectStratalotPriceValueState } from './stratalot-price-value-generated.selectors';
import * as companyPriceLabelActions from '@_store/company-price-label/company-price-label.actions';
import * as stratalotPriceActions from '@_store/stratalot-price/stratalot-price.actions';

export interface StratalotPriceValueRelationsIds {
  companyPriceLabel?: number;
  stratalotPrice?: number;
}

export function getDefaultAddStratalotPriceValueActions(
  stratalotPriceValue: StratalotPriceValueEntityState,
  ids?: StratalotPriceValueRelationsIds
): Action[] {
  const actions: Action[] = [
    stratalotPriceValueActions.normalizeManyStratalotPriceValuesAfterUpsert({
      stratalotPriceValues: [stratalotPriceValue]
    })
  ];

  if (ids?.companyPriceLabel) {
    actions.push(
      companyPriceLabelActions.addManyStratalotPriceValueSuccess({
        idCompanyPriceLabel: ids.companyPriceLabel,
        idStratalotPriceValues: [stratalotPriceValue.idStratalotPriceValue]
      })
    );
    actions.push(
      stratalotPriceValueActions.addCompanyPriceLabelSuccess({
        idStratalotPriceValue: stratalotPriceValue.idStratalotPriceValue,
        idCompanyPriceLabel: ids.companyPriceLabel
      })
    );
  }

  if (ids?.stratalotPrice) {
    actions.push(
      stratalotPriceActions.addManyStratalotPriceValueSuccess({
        idStratalotPrice: ids.stratalotPrice,
        idStratalotPriceValues: [stratalotPriceValue.idStratalotPriceValue]
      })
    );
    actions.push(
      stratalotPriceValueActions.addStratalotPriceSuccess({
        idStratalotPriceValue: stratalotPriceValue.idStratalotPriceValue,
        idStratalotPrice: ids.stratalotPrice
      })
    );
  }

  return actions;
}

export function getDefaultDeleteStratalotPriceValueActions(
  stratalotPriceValue: StratalotPriceValueEntityState
): Action[] {
  const actions: Action[] = [
    stratalotPriceValueActions.deleteOneStratalotPriceValueSuccess({
      idStratalotPriceValue: stratalotPriceValue.idStratalotPriceValue
    })
  ];

  if (stratalotPriceValue.companyPriceLabel) {
    actions.push(
      companyPriceLabelActions.deleteManyStratalotPriceValueSuccess({
        idStratalotPriceValues: [stratalotPriceValue.idStratalotPriceValue],
        idCompanyPriceLabels: [stratalotPriceValue.companyPriceLabel as number]
      })
    );
  }

  if (stratalotPriceValue.stratalotPrice) {
    actions.push(
      stratalotPriceActions.deleteManyStratalotPriceValueSuccess({
        idStratalotPriceValues: [stratalotPriceValue.idStratalotPriceValue],
        idStratalotPrices: [stratalotPriceValue.stratalotPrice as number]
      })
    );
  }

  return actions;
}

export class GeneratedStratalotPriceValueEffects {
  constructor(
    protected actions$: Actions,
    protected stratalotPriceValueApiService: StratalotPriceValueApiService,
    protected store$: Store<AppState>
  ) {}

  getManyStratalotPriceValues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotPriceValueActions.getManyStratalotPriceValues),
      switchMap(({ params }) =>
        this.stratalotPriceValueApiService.getStratalotPriceValues(params).pipe(
          map((stratalotPriceValues: StratalotPriceValue[]) => {
            return stratalotPriceValueActions.normalizeManyStratalotPriceValuesAfterUpsert({ stratalotPriceValues });
          }),
          catchError(error => of(stratalotPriceValueActions.stratalotPriceValuesFailure({ error })))
        )
      )
    );
  });

  getOneStratalotPriceValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotPriceValueActions.getOneStratalotPriceValue),
      switchMap(idStratalotPriceValue =>
        this.stratalotPriceValueApiService.getStratalotPriceValue(idStratalotPriceValue).pipe(
          map((stratalotPriceValue: StratalotPriceValue) => {
            return stratalotPriceValueActions.normalizeManyStratalotPriceValuesAfterUpsert({
              stratalotPriceValues: [stratalotPriceValue]
            });
          }),
          catchError(error => of(stratalotPriceValueActions.stratalotPriceValuesFailure({ error })))
        )
      )
    );
  });

  upsertOneStratalotPriceValue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotPriceValueActions.upsertOneStratalotPriceValue),
      concatMap(
        ({
          stratalotPriceValue,
          ids
        }: {
          stratalotPriceValue: Partial<StratalotPriceValue>;
          ids?: StratalotPriceValueRelationsIds;
        }) => {
          if (stratalotPriceValue.idStratalotPriceValue) {
            return this.stratalotPriceValueApiService.updateStratalotPriceValue(stratalotPriceValue).pipe(
              map((stratalotPriceValueReturned: StratalotPriceValue) => {
                return stratalotPriceValueActions.normalizeManyStratalotPriceValuesAfterUpsert({
                  stratalotPriceValues: [stratalotPriceValueReturned]
                });
              }),
              catchError(error => of(stratalotPriceValueActions.stratalotPriceValuesFailure({ error })))
            );
          } else {
            return this.stratalotPriceValueApiService.addStratalotPriceValue(stratalotPriceValue).pipe(
              mergeMap((stratalotPriceValueReturned: StratalotPriceValue) =>
                getDefaultAddStratalotPriceValueActions(stratalotPriceValueReturned, ids)
              ),
              catchError(error => of(stratalotPriceValueActions.stratalotPriceValuesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneStratalotPriceValue$ = createEffect(() => {
    const selectStratalotPriceValueState$ = this.store$.select(selectStratalotPriceValueState);
    return this.actions$.pipe(
      ofType(stratalotPriceValueActions.deleteOneStratalotPriceValue),
      withLatestFrom(selectStratalotPriceValueState$),
      concatMap(([{ idStratalotPriceValue }, state]) =>
        this.stratalotPriceValueApiService.deleteStratalotPriceValue(idStratalotPriceValue).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteStratalotPriceValueActions(
                state.entities[idStratalotPriceValue] as StratalotPriceValueEntityState
              ),
              stratalotPriceValueActions.deleteOneStratalotPriceValue.type
            )
          ]),
          catchError(error => of(stratalotPriceValueActions.stratalotPriceValuesFailure({ error })))
        )
      )
    );
  });

  normalizeManyStratalotPriceValuesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotPriceValueActions.normalizeManyStratalotPriceValuesAfterUpsert),
      concatMap(({ stratalotPriceValues }) => {
        const actions: Action[] = getActionsToNormalizeStratalotPriceValue(
          stratalotPriceValues,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[StratalotPriceValue] Normalization After Upsert Success')];
      })
    );
  });
}
