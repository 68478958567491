import { OrganizationProfilState, initialState } from './organization-profil.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationProfilReducersGeneratedFunctions } from './organization-profil-generated.reducer';

const organizationProfilReducersFunctions = [...organizationProfilReducersGeneratedFunctions];

const organizationProfilReducer = createReducer(initialState, ...organizationProfilReducersFunctions);

export function reducer(state: OrganizationProfilState | undefined, action: Action) {
  return organizationProfilReducer(state, action);
}
