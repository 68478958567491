import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  CompanyCommunicationGeneratedDocument,
  CompanyCommunicationGeneratedDocumentEntityState
} from '@_model/interfaces/company-communication-generated-document.model';
import { CompanyCommunicationGeneratedDocumentApiService } from '@_services/api/company-communication-generated-document-api.service';
import * as companyCommunicationGeneratedDocumentActions from '@_store/company-communication-generated-document/company-communication-generated-document.actions';
import { getActionsToNormalizeCompanyCommunicationGeneratedDocument } from '@_config/store/normalization.generated';
import { selectCompanyCommunicationGeneratedDocumentState } from './company-communication-generated-document-generated.selectors';
import * as companyCommunicationActions from '@_store/company-communication/company-communication.actions';
import * as generatedDocumentActions from '@_store/generated-document/generated-document.actions';

export interface CompanyCommunicationGeneratedDocumentRelationsIds {
  companyCommunication?: number;
  generatedDocument?: number;
}

export function getDefaultAddCompanyCommunicationGeneratedDocumentActions(
  companyCommunicationGeneratedDocument: CompanyCommunicationGeneratedDocumentEntityState,
  ids?: CompanyCommunicationGeneratedDocumentRelationsIds
): Action[] {
  const actions: Action[] = [
    companyCommunicationGeneratedDocumentActions.normalizeManyCompanyCommunicationGeneratedDocumentsAfterUpsert({
      companyCommunicationGeneratedDocuments: [companyCommunicationGeneratedDocument]
    })
  ];

  if (ids?.companyCommunication) {
    actions.push(
      companyCommunicationActions.addManyCompanyCommunicationGeneratedDocumentSuccess({
        idCompanyCommunication: ids.companyCommunication,
        idCompanyCommunicationGeneratedDocuments: [
          companyCommunicationGeneratedDocument.idCompanyCommunicationGeneratedDocument
        ]
      })
    );
    actions.push(
      companyCommunicationGeneratedDocumentActions.addCompanyCommunicationSuccess({
        idCompanyCommunicationGeneratedDocument:
          companyCommunicationGeneratedDocument.idCompanyCommunicationGeneratedDocument,
        idCompanyCommunication: ids.companyCommunication
      })
    );
  }

  if (ids?.generatedDocument) {
    actions.push(
      generatedDocumentActions.addManyCompanyCommunicationGeneratedDocumentSuccess({
        idGeneratedDocument: ids.generatedDocument,
        idCompanyCommunicationGeneratedDocuments: [
          companyCommunicationGeneratedDocument.idCompanyCommunicationGeneratedDocument
        ]
      })
    );
    actions.push(
      companyCommunicationGeneratedDocumentActions.addGeneratedDocumentSuccess({
        idCompanyCommunicationGeneratedDocument:
          companyCommunicationGeneratedDocument.idCompanyCommunicationGeneratedDocument,
        idGeneratedDocument: ids.generatedDocument
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCompanyCommunicationGeneratedDocumentActions(
  companyCommunicationGeneratedDocument: CompanyCommunicationGeneratedDocumentEntityState
): Action[] {
  const actions: Action[] = [
    companyCommunicationGeneratedDocumentActions.deleteOneCompanyCommunicationGeneratedDocumentSuccess({
      idCompanyCommunicationGeneratedDocument:
        companyCommunicationGeneratedDocument.idCompanyCommunicationGeneratedDocument
    })
  ];

  if (companyCommunicationGeneratedDocument.companyCommunication) {
    actions.push(
      companyCommunicationActions.deleteManyCompanyCommunicationGeneratedDocumentSuccess({
        idCompanyCommunicationGeneratedDocuments: [
          companyCommunicationGeneratedDocument.idCompanyCommunicationGeneratedDocument
        ],
        idCompanyCommunications: [companyCommunicationGeneratedDocument.companyCommunication as number]
      })
    );
  }

  if (companyCommunicationGeneratedDocument.generatedDocument) {
    actions.push(
      generatedDocumentActions.deleteManyCompanyCommunicationGeneratedDocumentSuccess({
        idCompanyCommunicationGeneratedDocuments: [
          companyCommunicationGeneratedDocument.idCompanyCommunicationGeneratedDocument
        ],
        idGeneratedDocuments: [companyCommunicationGeneratedDocument.generatedDocument as number]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyCommunicationGeneratedDocumentEffects {
  constructor(
    protected actions$: Actions,
    protected companyCommunicationGeneratedDocumentApiService: CompanyCommunicationGeneratedDocumentApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyCommunicationGeneratedDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationGeneratedDocumentActions.getManyCompanyCommunicationGeneratedDocuments),
      switchMap(({ params }) =>
        this.companyCommunicationGeneratedDocumentApiService.getCompanyCommunicationGeneratedDocuments(params).pipe(
          map((companyCommunicationGeneratedDocuments: CompanyCommunicationGeneratedDocument[]) => {
            return companyCommunicationGeneratedDocumentActions.normalizeManyCompanyCommunicationGeneratedDocumentsAfterUpsert(
              { companyCommunicationGeneratedDocuments }
            );
          }),
          catchError(error =>
            of(companyCommunicationGeneratedDocumentActions.companyCommunicationGeneratedDocumentsFailure({ error }))
          )
        )
      )
    );
  });

  getOneCompanyCommunicationGeneratedDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationGeneratedDocumentActions.getOneCompanyCommunicationGeneratedDocument),
      switchMap(idCompanyCommunicationGeneratedDocument =>
        this.companyCommunicationGeneratedDocumentApiService
          .getCompanyCommunicationGeneratedDocument(idCompanyCommunicationGeneratedDocument)
          .pipe(
            map((companyCommunicationGeneratedDocument: CompanyCommunicationGeneratedDocument) => {
              return companyCommunicationGeneratedDocumentActions.normalizeManyCompanyCommunicationGeneratedDocumentsAfterUpsert(
                { companyCommunicationGeneratedDocuments: [companyCommunicationGeneratedDocument] }
              );
            }),
            catchError(error =>
              of(companyCommunicationGeneratedDocumentActions.companyCommunicationGeneratedDocumentsFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneCompanyCommunicationGeneratedDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationGeneratedDocumentActions.upsertOneCompanyCommunicationGeneratedDocument),
      concatMap(
        ({
          companyCommunicationGeneratedDocument,
          ids
        }: {
          companyCommunicationGeneratedDocument: Partial<CompanyCommunicationGeneratedDocument>;
          ids?: CompanyCommunicationGeneratedDocumentRelationsIds;
        }) => {
          if (companyCommunicationGeneratedDocument.idCompanyCommunicationGeneratedDocument) {
            return this.companyCommunicationGeneratedDocumentApiService
              .updateCompanyCommunicationGeneratedDocument(companyCommunicationGeneratedDocument)
              .pipe(
                map((companyCommunicationGeneratedDocumentReturned: CompanyCommunicationGeneratedDocument) => {
                  return companyCommunicationGeneratedDocumentActions.normalizeManyCompanyCommunicationGeneratedDocumentsAfterUpsert(
                    { companyCommunicationGeneratedDocuments: [companyCommunicationGeneratedDocumentReturned] }
                  );
                }),
                catchError(error =>
                  of(
                    companyCommunicationGeneratedDocumentActions.companyCommunicationGeneratedDocumentsFailure({
                      error
                    })
                  )
                )
              );
          } else {
            return this.companyCommunicationGeneratedDocumentApiService
              .addCompanyCommunicationGeneratedDocument(companyCommunicationGeneratedDocument)
              .pipe(
                mergeMap((companyCommunicationGeneratedDocumentReturned: CompanyCommunicationGeneratedDocument) =>
                  getDefaultAddCompanyCommunicationGeneratedDocumentActions(
                    companyCommunicationGeneratedDocumentReturned,
                    ids
                  )
                ),
                catchError(error =>
                  of(
                    companyCommunicationGeneratedDocumentActions.companyCommunicationGeneratedDocumentsFailure({
                      error
                    })
                  )
                )
              );
          }
        }
      )
    );
  });

  deleteOneCompanyCommunicationGeneratedDocument$ = createEffect(() => {
    const selectCompanyCommunicationGeneratedDocumentState$ = this.store$.select(
      selectCompanyCommunicationGeneratedDocumentState
    );
    return this.actions$.pipe(
      ofType(companyCommunicationGeneratedDocumentActions.deleteOneCompanyCommunicationGeneratedDocument),
      withLatestFrom(selectCompanyCommunicationGeneratedDocumentState$),
      concatMap(([{ idCompanyCommunicationGeneratedDocument }, state]) =>
        this.companyCommunicationGeneratedDocumentApiService
          .deleteCompanyCommunicationGeneratedDocument(idCompanyCommunicationGeneratedDocument)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteCompanyCommunicationGeneratedDocumentActions(
                  state.entities[
                    idCompanyCommunicationGeneratedDocument
                  ] as CompanyCommunicationGeneratedDocumentEntityState
                ),
                companyCommunicationGeneratedDocumentActions.deleteOneCompanyCommunicationGeneratedDocument.type
              )
            ]),
            catchError(error =>
              of(companyCommunicationGeneratedDocumentActions.companyCommunicationGeneratedDocumentsFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyCompanyCommunicationGeneratedDocumentsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        companyCommunicationGeneratedDocumentActions.normalizeManyCompanyCommunicationGeneratedDocumentsAfterUpsert
      ),
      concatMap(({ companyCommunicationGeneratedDocuments }) => {
        const actions: Action[] = getActionsToNormalizeCompanyCommunicationGeneratedDocument(
          companyCommunicationGeneratedDocuments,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CompanyCommunicationGeneratedDocument] Normalization After Upsert Success')];
      })
    );
  });
}
