import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationResidenceStudyTodo } from '@_model/interfaces/organization-residence-study-todo.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationResidenceStudyTodoApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationResidenceStudyTodos(params?: any): Observable<OrganizationResidenceStudyTodo[]> {
    return this.repo.getData<OrganizationResidenceStudyTodo[]>('organization-residence-study-todo', params);
  }

  public getOrganizationResidenceStudyTodo(params: {
    idOrganizationResidenceStudyTodo: number;
    params?: any;
  }): Observable<OrganizationResidenceStudyTodo> {
    return this.repo.getData<OrganizationResidenceStudyTodo>(
      'organization-residence-study-todo/' + params.idOrganizationResidenceStudyTodo,
      params.params
    );
  }

  public addOrganizationResidenceStudyTodo(
    organizationResidenceStudyTodo: Partial<OrganizationResidenceStudyTodo>
  ): Observable<OrganizationResidenceStudyTodo> {
    return this.repo.create<OrganizationResidenceStudyTodo>(
      'organization-residence-study-todo',
      organizationResidenceStudyTodo
    );
  }

  public updateOrganizationResidenceStudyTodo(
    organizationResidenceStudyTodo: Partial<OrganizationResidenceStudyTodo>
  ): Observable<OrganizationResidenceStudyTodo> {
    return this.repo.update('organization-residence-study-todo', organizationResidenceStudyTodo);
  }

  public deleteOrganizationResidenceStudyTodo(idOrganizationResidenceStudyTodo: number): Observable<number> {
    return this.repo.delete('organization-residence-study-todo/' + +idOrganizationResidenceStudyTodo);
  }
}
