import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationLeadTodoEntityState } from '@_model/interfaces/organization-lead-todo.model';

export interface OrganizationLeadTodoState extends EntityState<OrganizationLeadTodoEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationLeadTodoEntityState> =
  createEntityAdapter<OrganizationLeadTodoEntityState>({
    selectId: o => o.idOrganizationLeadTodo
  });
export const initialState: OrganizationLeadTodoState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationLeadTodoFeatureKey = 'organizationLeadTodo';
