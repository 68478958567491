import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { CompanyPriceLabel, CompanyPriceLabelEntityState } from '@_model/interfaces/company-price-label.model';
import { CompanyPriceLabelApiService } from '@_services/api/company-price-label-api.service';
import * as companyPriceLabelActions from '@_store/company-price-label/company-price-label.actions';
import { getActionsToNormalizeCompanyPriceLabel } from '@_config/store/normalization.generated';
import { selectCompanyPriceLabelState } from './company-price-label-generated.selectors';
import * as companyActions from '@_store/company/company.actions';
import * as saleCategoryFamilyActions from '@_store/sale-category-family/sale-category-family.actions';
import * as stratalotPriceActions from '@_store/stratalot-price/stratalot-price.actions';
import { StratalotPrice } from '@_model/interfaces/stratalot-price.model';
import * as stratalotPriceValueActions from '@_store/stratalot-price-value/stratalot-price-value.actions';
import { StratalotPriceValue } from '@_model/interfaces/stratalot-price-value.model';
import * as residencePriceGridModifierActions from '@_store/residence-price-grid-modifier/residence-price-grid-modifier.actions';
import { ResidencePriceGridModifier } from '@_model/interfaces/residence-price-grid-modifier.model';
import * as organizationProfilActions from '@_store/organization-profil/organization-profil.actions';
import { OrganizationProfil } from '@_model/interfaces/organization-profil.model';

export interface CompanyPriceLabelRelationsIds {
  stratalotPrices?: number | number[];
  stratalotPriceValues?: number | number[];
  residencePriceGridModifiers?: number | number[];
  organizationProfils?: number | number[];
  company?: number;
  saleCategoryFamily?: number;
}

export function getDefaultAddCompanyPriceLabelActions(
  companyPriceLabel: CompanyPriceLabelEntityState,
  ids?: CompanyPriceLabelRelationsIds
): Action[] {
  const actions: Action[] = [
    companyPriceLabelActions.normalizeManyCompanyPriceLabelsAfterUpsert({ companyPriceLabels: [companyPriceLabel] })
  ];

  if (ids?.company) {
    actions.push(
      companyActions.addManyCompanyPriceLabelSuccess({
        idCompany: ids.company,
        idCompanyPriceLabels: [companyPriceLabel.idCompanyPriceLabel]
      })
    );
    actions.push(
      companyPriceLabelActions.addCompanySuccess({
        idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
        idCompany: ids.company
      })
    );
  }

  if (ids?.saleCategoryFamily) {
    actions.push(
      saleCategoryFamilyActions.addManyCompanyPriceLabelSuccess({
        idSaleCategoryFamily: ids.saleCategoryFamily,
        idCompanyPriceLabels: [companyPriceLabel.idCompanyPriceLabel]
      })
    );
    actions.push(
      companyPriceLabelActions.addSaleCategoryFamilySuccess({
        idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
        idSaleCategoryFamily: ids.saleCategoryFamily
      })
    );
  }

  if (ids?.stratalotPrices) {
    if (!Array.isArray(ids.stratalotPrices)) {
      actions.push(
        stratalotPriceActions.upsertOneStratalotPrice({
          stratalotPrice: {
            idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
            idStratalotPrice: ids.stratalotPrices as number
          } as StratalotPrice & any
        })
      );
      actions.push(
        companyPriceLabelActions.addManyStratalotPriceSuccess({
          idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
          idStratalotPrices: [ids.stratalotPrices as number]
        })
      );
    } else {
      actions.push(
        stratalotPriceActions.upsertManyStratalotPrices({
          stratalotPrices: (ids.stratalotPrices as number[]).map((idStratalotPrice: number) => ({
            idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
            idStratalotPrice
          })) as StratalotPrice[] & any[]
        })
      );
      actions.push(
        companyPriceLabelActions.addManyStratalotPriceSuccess({
          idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
          idStratalotPrices: ids.stratalotPrices as number[]
        })
      );
    }
  }

  if (ids?.stratalotPriceValues) {
    if (!Array.isArray(ids.stratalotPriceValues)) {
      actions.push(
        stratalotPriceValueActions.upsertOneStratalotPriceValue({
          stratalotPriceValue: {
            idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
            idStratalotPriceValue: ids.stratalotPriceValues as number
          } as StratalotPriceValue
        })
      );
      actions.push(
        companyPriceLabelActions.addManyStratalotPriceValueSuccess({
          idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
          idStratalotPriceValues: [ids.stratalotPriceValues as number]
        })
      );
    } else {
      actions.push(
        stratalotPriceValueActions.upsertManyStratalotPriceValues({
          stratalotPriceValues: (ids.stratalotPriceValues as number[]).map((idStratalotPriceValue: number) => ({
            idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
            idStratalotPriceValue
          })) as StratalotPriceValue[]
        })
      );
      actions.push(
        companyPriceLabelActions.addManyStratalotPriceValueSuccess({
          idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
          idStratalotPriceValues: ids.stratalotPriceValues as number[]
        })
      );
    }
  }

  if (ids?.residencePriceGridModifiers) {
    if (!Array.isArray(ids.residencePriceGridModifiers)) {
      actions.push(
        residencePriceGridModifierActions.upsertOneResidencePriceGridModifier({
          residencePriceGridModifier: {
            idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
            idResidencePriceGridModifier: ids.residencePriceGridModifiers as number
          } as ResidencePriceGridModifier
        })
      );
      actions.push(
        companyPriceLabelActions.addManyResidencePriceGridModifierSuccess({
          idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
          idResidencePriceGridModifiers: [ids.residencePriceGridModifiers as number]
        })
      );
    } else {
      actions.push(
        residencePriceGridModifierActions.upsertManyResidencePriceGridModifiers({
          residencePriceGridModifiers: (ids.residencePriceGridModifiers as number[]).map(
            (idResidencePriceGridModifier: number) => ({
              idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
              idResidencePriceGridModifier
            })
          ) as ResidencePriceGridModifier[]
        })
      );
      actions.push(
        companyPriceLabelActions.addManyResidencePriceGridModifierSuccess({
          idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
          idResidencePriceGridModifiers: ids.residencePriceGridModifiers as number[]
        })
      );
    }
  }

  if (ids?.organizationProfils) {
    if (!Array.isArray(ids.organizationProfils)) {
      actions.push(
        organizationProfilActions.upsertOneOrganizationProfil({
          organizationProfil: {
            idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
            idOrganizationProfil: ids.organizationProfils as number
          } as OrganizationProfil
        })
      );
      actions.push(
        companyPriceLabelActions.addManyOrganizationProfilSuccess({
          idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
          idOrganizationProfils: [ids.organizationProfils as number]
        })
      );
    } else {
      actions.push(
        organizationProfilActions.upsertManyOrganizationProfils({
          organizationProfils: (ids.organizationProfils as number[]).map((idOrganizationProfil: number) => ({
            idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
            idOrganizationProfil
          })) as OrganizationProfil[]
        })
      );
      actions.push(
        companyPriceLabelActions.addManyOrganizationProfilSuccess({
          idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel,
          idOrganizationProfils: ids.organizationProfils as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCompanyPriceLabelActions(companyPriceLabel: CompanyPriceLabelEntityState): Action[] {
  const actions: Action[] = [
    companyPriceLabelActions.deleteOneCompanyPriceLabelSuccess({
      idCompanyPriceLabel: companyPriceLabel.idCompanyPriceLabel
    })
  ];

  if (companyPriceLabel.company) {
    actions.push(
      companyActions.deleteManyCompanyPriceLabelSuccess({
        idCompanyPriceLabels: [companyPriceLabel.idCompanyPriceLabel],
        idCompanies: [companyPriceLabel.company as number]
      })
    );
  }

  if (companyPriceLabel.saleCategoryFamily) {
    actions.push(
      saleCategoryFamilyActions.deleteManyCompanyPriceLabelSuccess({
        idCompanyPriceLabels: [companyPriceLabel.idCompanyPriceLabel],
        idFamilySaleCategories: [companyPriceLabel.saleCategoryFamily as number]
      })
    );
  }

  if (companyPriceLabel.stratalotPrices) {
    actions.push(
      stratalotPriceActions.deleteManyCompanyPriceLabelSuccess({
        idCompanyPriceLabels: [companyPriceLabel.idCompanyPriceLabel],
        idStratalotPrices: companyPriceLabel.stratalotPrices as number[]
      })
    );
  }

  if (companyPriceLabel.stratalotPriceValues) {
    actions.push(
      stratalotPriceValueActions.deleteManyCompanyPriceLabelSuccess({
        idCompanyPriceLabels: [companyPriceLabel.idCompanyPriceLabel],
        idStratalotPriceValues: companyPriceLabel.stratalotPriceValues as number[]
      })
    );
  }

  if (companyPriceLabel.residencePriceGridModifiers) {
    actions.push(
      residencePriceGridModifierActions.deleteManyCompanyPriceLabelSuccess({
        idCompanyPriceLabels: [companyPriceLabel.idCompanyPriceLabel],
        idResidencePriceGridModifiers: companyPriceLabel.residencePriceGridModifiers as number[]
      })
    );
  }

  if (companyPriceLabel.organizationProfils) {
    actions.push(
      organizationProfilActions.deleteManyCompanyPriceLabelSuccess({
        idCompanyPriceLabels: [companyPriceLabel.idCompanyPriceLabel],
        idOrganizationProfils: companyPriceLabel.organizationProfils as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyPriceLabelEffects {
  constructor(
    protected actions$: Actions,
    protected companyPriceLabelApiService: CompanyPriceLabelApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyPriceLabels$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyPriceLabelActions.getManyCompanyPriceLabels),
      switchMap(({ params }) =>
        this.companyPriceLabelApiService.getCompanyPriceLabels(params).pipe(
          map((companyPriceLabels: CompanyPriceLabel[]) => {
            return companyPriceLabelActions.normalizeManyCompanyPriceLabelsAfterUpsert({ companyPriceLabels });
          }),
          catchError(error => of(companyPriceLabelActions.companyPriceLabelsFailure({ error })))
        )
      )
    );
  });

  getOneCompanyPriceLabel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyPriceLabelActions.getOneCompanyPriceLabel),
      switchMap(idCompanyPriceLabel =>
        this.companyPriceLabelApiService.getCompanyPriceLabel(idCompanyPriceLabel).pipe(
          map((companyPriceLabel: CompanyPriceLabel) => {
            return companyPriceLabelActions.normalizeManyCompanyPriceLabelsAfterUpsert({
              companyPriceLabels: [companyPriceLabel]
            });
          }),
          catchError(error => of(companyPriceLabelActions.companyPriceLabelsFailure({ error })))
        )
      )
    );
  });

  upsertOneCompanyPriceLabel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyPriceLabelActions.upsertOneCompanyPriceLabel),
      concatMap(
        ({
          companyPriceLabel,
          ids
        }: {
          companyPriceLabel: Partial<CompanyPriceLabel>;
          ids?: CompanyPriceLabelRelationsIds;
        }) => {
          if (companyPriceLabel.idCompanyPriceLabel) {
            return this.companyPriceLabelApiService.updateCompanyPriceLabel(companyPriceLabel).pipe(
              map((companyPriceLabelReturned: CompanyPriceLabel) => {
                return companyPriceLabelActions.normalizeManyCompanyPriceLabelsAfterUpsert({
                  companyPriceLabels: [companyPriceLabelReturned]
                });
              }),
              catchError(error => of(companyPriceLabelActions.companyPriceLabelsFailure({ error })))
            );
          } else {
            return this.companyPriceLabelApiService.addCompanyPriceLabel(companyPriceLabel).pipe(
              mergeMap((companyPriceLabelReturned: CompanyPriceLabel) =>
                getDefaultAddCompanyPriceLabelActions(companyPriceLabelReturned, ids)
              ),
              catchError(error => of(companyPriceLabelActions.companyPriceLabelsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCompanyPriceLabel$ = createEffect(() => {
    const selectCompanyPriceLabelState$ = this.store$.select(selectCompanyPriceLabelState);
    return this.actions$.pipe(
      ofType(companyPriceLabelActions.deleteOneCompanyPriceLabel),
      withLatestFrom(selectCompanyPriceLabelState$),
      concatMap(([{ idCompanyPriceLabel }, state]) =>
        this.companyPriceLabelApiService.deleteCompanyPriceLabel(idCompanyPriceLabel).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCompanyPriceLabelActions(
                state.entities[idCompanyPriceLabel] as CompanyPriceLabelEntityState
              ),
              companyPriceLabelActions.deleteOneCompanyPriceLabel.type
            )
          ]),
          catchError(error => of(companyPriceLabelActions.companyPriceLabelsFailure({ error })))
        )
      )
    );
  });

  normalizeManyCompanyPriceLabelsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyPriceLabelActions.normalizeManyCompanyPriceLabelsAfterUpsert),
      concatMap(({ companyPriceLabels }) => {
        const actions: Action[] = getActionsToNormalizeCompanyPriceLabel(companyPriceLabels, StoreActionType.upsert);
        return [getMultiAction(actions, '[CompanyPriceLabel] Normalization After Upsert Success')];
      })
    );
  });
}
