import { StratalotFeeState, initialState } from './stratalot-fee.state';
import { Action, createReducer } from '@ngrx/store';
import { stratalotFeeReducersGeneratedFunctions } from './stratalot-fee-generated.reducer';

const stratalotFeeReducersFunctions = [...stratalotFeeReducersGeneratedFunctions];

const stratalotFeeReducer = createReducer(initialState, ...stratalotFeeReducersFunctions);

export function reducer(state: StratalotFeeState | undefined, action: Action) {
  return stratalotFeeReducer(state, action);
}
