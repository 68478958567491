import { Actions, ofType } from '@ngrx/effects';
import { ActionCreator } from '@ngrx/store';
import { Observable, race, throwError } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';

export function catchApiActions(
  actions$: Actions,
  successAction: ActionCreator<any, any>,
  errorAction: ActionCreator<any, any>,
  firstItem?: boolean
): Observable<any> {
  const observable: Observable<any> = race(
    actions$.pipe(
      ofType(successAction),
      map((obj: any) => obj[Object.keys(obj)[0]])
    ),
    actions$.pipe(
      ofType(errorAction),
      mergeMap(({ error }) => throwError(error))
    )
  );
  if (firstItem) {
    return observable.pipe(
      map((datas: any[]) => datas[0]),
      first()
    );
  }
  return observable.pipe(first());
}
