import { MetierFamilieState, initialState } from './metier-familie.state';
import { Action, createReducer } from '@ngrx/store';
import { metierFamilieReducersGeneratedFunctions } from './metier-familie-generated.reducer';

const metierFamilieReducersFunctions = [...metierFamilieReducersGeneratedFunctions];

const metierFamilieReducer = createReducer(initialState, ...metierFamilieReducersFunctions);

export function reducer(state: MetierFamilieState | undefined, action: Action) {
  return metierFamilieReducer(state, action);
}
