import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { DiffusionVisual, DiffusionVisualEntityState } from '@_model/interfaces/diffusion-visual.model';
import { DiffusionVisualApiService } from '@_services/api/diffusion-visual-api.service';
import * as diffusionVisualActions from '@_store/diffusion-visual/diffusion-visual.actions';
import { getActionsToNormalizeDiffusionVisual } from '@_config/store/normalization.generated';
import { selectDiffusionVisualState } from './diffusion-visual-generated.selectors';
import * as residenceActions from '@_store/residence/residence.actions';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';

export interface DiffusionVisualRelationsIds {
  residence?: number;
  stratalot?: number;
}

export function getDefaultAddDiffusionVisualActions(
  diffusionVisual: DiffusionVisualEntityState,
  ids?: DiffusionVisualRelationsIds
): Action[] {
  const actions: Action[] = [
    diffusionVisualActions.normalizeManyDiffusionVisualsAfterUpsert({ diffusionVisuals: [diffusionVisual] })
  ];

  if (ids?.residence) {
    actions.push(
      residenceActions.addManyDiffusionVisualSuccess({
        idResidence: ids.residence,
        idDiffusionVisuals: [diffusionVisual.idDiffusionVisual]
      })
    );
    actions.push(
      diffusionVisualActions.addResidenceSuccess({
        idDiffusionVisual: diffusionVisual.idDiffusionVisual,
        idResidence: ids.residence
      })
    );
  }

  if (ids?.stratalot) {
    actions.push(
      stratalotActions.addManyDiffusionVisualSuccess({
        idStratalot: ids.stratalot,
        idDiffusionVisuals: [diffusionVisual.idDiffusionVisual]
      })
    );
    actions.push(
      diffusionVisualActions.addStratalotSuccess({
        idDiffusionVisual: diffusionVisual.idDiffusionVisual,
        idStratalot: ids.stratalot
      })
    );
  }

  return actions;
}

export function getDefaultDeleteDiffusionVisualActions(diffusionVisual: DiffusionVisualEntityState): Action[] {
  const actions: Action[] = [
    diffusionVisualActions.deleteOneDiffusionVisualSuccess({ idDiffusionVisual: diffusionVisual.idDiffusionVisual })
  ];

  if (diffusionVisual.residence) {
    actions.push(
      residenceActions.deleteManyDiffusionVisualSuccess({
        idDiffusionVisuals: [diffusionVisual.idDiffusionVisual],
        idResidences: [diffusionVisual.residence as number]
      })
    );
  }

  if (diffusionVisual.stratalot) {
    actions.push(
      stratalotActions.deleteManyDiffusionVisualSuccess({
        idDiffusionVisuals: [diffusionVisual.idDiffusionVisual],
        idStratalots: [diffusionVisual.stratalot as number]
      })
    );
  }

  return actions;
}

export class GeneratedDiffusionVisualEffects {
  constructor(
    protected actions$: Actions,
    protected diffusionVisualApiService: DiffusionVisualApiService,
    protected store$: Store<AppState>
  ) {}

  getManyDiffusionVisuals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(diffusionVisualActions.getManyDiffusionVisuals),
      switchMap(({ params }) =>
        this.diffusionVisualApiService.getDiffusionVisuals(params).pipe(
          map((diffusionVisuals: DiffusionVisual[]) => {
            return diffusionVisualActions.normalizeManyDiffusionVisualsAfterUpsert({ diffusionVisuals });
          }),
          catchError(error => of(diffusionVisualActions.diffusionVisualsFailure({ error })))
        )
      )
    );
  });

  getOneDiffusionVisual$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(diffusionVisualActions.getOneDiffusionVisual),
      switchMap(idDiffusionVisual =>
        this.diffusionVisualApiService.getDiffusionVisual(idDiffusionVisual).pipe(
          map((diffusionVisual: DiffusionVisual) => {
            return diffusionVisualActions.normalizeManyDiffusionVisualsAfterUpsert({
              diffusionVisuals: [diffusionVisual]
            });
          }),
          catchError(error => of(diffusionVisualActions.diffusionVisualsFailure({ error })))
        )
      )
    );
  });

  upsertOneDiffusionVisual$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(diffusionVisualActions.upsertOneDiffusionVisual),
      concatMap(
        ({
          diffusionVisual,
          ids
        }: {
          diffusionVisual: Partial<DiffusionVisual>;
          ids?: DiffusionVisualRelationsIds;
        }) => {
          if (diffusionVisual.idDiffusionVisual) {
            return this.diffusionVisualApiService.updateDiffusionVisual(diffusionVisual).pipe(
              map((diffusionVisualReturned: DiffusionVisual) => {
                return diffusionVisualActions.normalizeManyDiffusionVisualsAfterUpsert({
                  diffusionVisuals: [diffusionVisualReturned]
                });
              }),
              catchError(error => of(diffusionVisualActions.diffusionVisualsFailure({ error })))
            );
          } else {
            return this.diffusionVisualApiService.addDiffusionVisual(diffusionVisual).pipe(
              mergeMap((diffusionVisualReturned: DiffusionVisual) =>
                getDefaultAddDiffusionVisualActions(diffusionVisualReturned, ids)
              ),
              catchError(error => of(diffusionVisualActions.diffusionVisualsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneDiffusionVisual$ = createEffect(() => {
    const selectDiffusionVisualState$ = this.store$.select(selectDiffusionVisualState);
    return this.actions$.pipe(
      ofType(diffusionVisualActions.deleteOneDiffusionVisual),
      withLatestFrom(selectDiffusionVisualState$),
      concatMap(([{ idDiffusionVisual }, state]) =>
        this.diffusionVisualApiService.deleteDiffusionVisual(idDiffusionVisual).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteDiffusionVisualActions(state.entities[idDiffusionVisual] as DiffusionVisualEntityState),
              diffusionVisualActions.deleteOneDiffusionVisual.type
            )
          ]),
          catchError(error => of(diffusionVisualActions.diffusionVisualsFailure({ error })))
        )
      )
    );
  });

  normalizeManyDiffusionVisualsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(diffusionVisualActions.normalizeManyDiffusionVisualsAfterUpsert),
      concatMap(({ diffusionVisuals }) => {
        const actions: Action[] = getActionsToNormalizeDiffusionVisual(diffusionVisuals, StoreActionType.upsert);
        return [getMultiAction(actions, '[DiffusionVisual] Normalization After Upsert Success')];
      })
    );
  });
}
