import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  CompanyCommunicationStratalotTodo,
  CompanyCommunicationStratalotTodoEntityState
} from '@_model/interfaces/company-communication-stratalot-todo.model';
import { CompanyCommunicationStratalotTodoApiService } from '@_services/api/company-communication-stratalot-todo-api.service';
import * as companyCommunicationStratalotTodoActions from '@_store/company-communication-stratalot-todo/company-communication-stratalot-todo.actions';
import { getActionsToNormalizeCompanyCommunicationStratalotTodo } from '@_config/store/normalization.generated';
import { selectCompanyCommunicationStratalotTodoState } from './company-communication-stratalot-todo-generated.selectors';
import * as companyCommunicationActions from '@_store/company-communication/company-communication.actions';
import * as organizationStratalotTodoActions from '@_store/organization-stratalot-todo/organization-stratalot-todo.actions';

export interface CompanyCommunicationStratalotTodoRelationsIds {
  companyCommunication?: number;
  organizationStratalotTodo?: number;
}

export function getDefaultAddCompanyCommunicationStratalotTodoActions(
  companyCommunicationStratalotTodo: CompanyCommunicationStratalotTodoEntityState,
  ids?: CompanyCommunicationStratalotTodoRelationsIds
): Action[] {
  const actions: Action[] = [
    companyCommunicationStratalotTodoActions.normalizeManyCompanyCommunicationStratalotTodosAfterUpsert({
      companyCommunicationStratalotTodos: [companyCommunicationStratalotTodo]
    })
  ];

  if (ids?.companyCommunication) {
    actions.push(
      companyCommunicationActions.addManyCompanyCommunicationStratalotTodoSuccess({
        idCompanyCommunication: ids.companyCommunication,
        idCompanyCommunicationStratalotTodos: [companyCommunicationStratalotTodo.idCompanyCommunicationStratalotTodo]
      })
    );
    actions.push(
      companyCommunicationStratalotTodoActions.addCompanyCommunicationSuccess({
        idCompanyCommunicationStratalotTodo: companyCommunicationStratalotTodo.idCompanyCommunicationStratalotTodo,
        idCompanyCommunication: ids.companyCommunication
      })
    );
  }

  if (ids?.organizationStratalotTodo) {
    actions.push(
      organizationStratalotTodoActions.addManyCompanyCommunicationStratalotTodoSuccess({
        idOrganizationStratalotTodo: ids.organizationStratalotTodo,
        idCompanyCommunicationStratalotTodos: [companyCommunicationStratalotTodo.idCompanyCommunicationStratalotTodo]
      })
    );
    actions.push(
      companyCommunicationStratalotTodoActions.addOrganizationStratalotTodoSuccess({
        idCompanyCommunicationStratalotTodo: companyCommunicationStratalotTodo.idCompanyCommunicationStratalotTodo,
        idOrganizationStratalotTodo: ids.organizationStratalotTodo
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCompanyCommunicationStratalotTodoActions(
  companyCommunicationStratalotTodo: CompanyCommunicationStratalotTodoEntityState
): Action[] {
  const actions: Action[] = [
    companyCommunicationStratalotTodoActions.deleteOneCompanyCommunicationStratalotTodoSuccess({
      idCompanyCommunicationStratalotTodo: companyCommunicationStratalotTodo.idCompanyCommunicationStratalotTodo
    })
  ];

  if (companyCommunicationStratalotTodo.companyCommunication) {
    actions.push(
      companyCommunicationActions.deleteManyCompanyCommunicationStratalotTodoSuccess({
        idCompanyCommunicationStratalotTodos: [companyCommunicationStratalotTodo.idCompanyCommunicationStratalotTodo],
        idCompanyCommunications: [companyCommunicationStratalotTodo.companyCommunication as number]
      })
    );
  }

  if (companyCommunicationStratalotTodo.organizationStratalotTodo) {
    actions.push(
      organizationStratalotTodoActions.deleteManyCompanyCommunicationStratalotTodoSuccess({
        idCompanyCommunicationStratalotTodos: [companyCommunicationStratalotTodo.idCompanyCommunicationStratalotTodo],
        idOrganizationStratalotTodos: [companyCommunicationStratalotTodo.organizationStratalotTodo as number]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyCommunicationStratalotTodoEffects {
  constructor(
    protected actions$: Actions,
    protected companyCommunicationStratalotTodoApiService: CompanyCommunicationStratalotTodoApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyCommunicationStratalotTodos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationStratalotTodoActions.getManyCompanyCommunicationStratalotTodos),
      switchMap(({ params }) =>
        this.companyCommunicationStratalotTodoApiService.getCompanyCommunicationStratalotTodos(params).pipe(
          map((companyCommunicationStratalotTodos: CompanyCommunicationStratalotTodo[]) => {
            return companyCommunicationStratalotTodoActions.normalizeManyCompanyCommunicationStratalotTodosAfterUpsert({
              companyCommunicationStratalotTodos
            });
          }),
          catchError(error =>
            of(companyCommunicationStratalotTodoActions.companyCommunicationStratalotTodosFailure({ error }))
          )
        )
      )
    );
  });

  getOneCompanyCommunicationStratalotTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationStratalotTodoActions.getOneCompanyCommunicationStratalotTodo),
      switchMap(idCompanyCommunicationStratalotTodo =>
        this.companyCommunicationStratalotTodoApiService
          .getCompanyCommunicationStratalotTodo(idCompanyCommunicationStratalotTodo)
          .pipe(
            map((companyCommunicationStratalotTodo: CompanyCommunicationStratalotTodo) => {
              return companyCommunicationStratalotTodoActions.normalizeManyCompanyCommunicationStratalotTodosAfterUpsert(
                { companyCommunicationStratalotTodos: [companyCommunicationStratalotTodo] }
              );
            }),
            catchError(error =>
              of(companyCommunicationStratalotTodoActions.companyCommunicationStratalotTodosFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneCompanyCommunicationStratalotTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationStratalotTodoActions.upsertOneCompanyCommunicationStratalotTodo),
      concatMap(
        ({
          companyCommunicationStratalotTodo,
          ids
        }: {
          companyCommunicationStratalotTodo: Partial<CompanyCommunicationStratalotTodo>;
          ids?: CompanyCommunicationStratalotTodoRelationsIds;
        }) => {
          if (companyCommunicationStratalotTodo.idCompanyCommunicationStratalotTodo) {
            return this.companyCommunicationStratalotTodoApiService
              .updateCompanyCommunicationStratalotTodo(companyCommunicationStratalotTodo)
              .pipe(
                map((companyCommunicationStratalotTodoReturned: CompanyCommunicationStratalotTodo) => {
                  return companyCommunicationStratalotTodoActions.normalizeManyCompanyCommunicationStratalotTodosAfterUpsert(
                    { companyCommunicationStratalotTodos: [companyCommunicationStratalotTodoReturned] }
                  );
                }),
                catchError(error =>
                  of(companyCommunicationStratalotTodoActions.companyCommunicationStratalotTodosFailure({ error }))
                )
              );
          } else {
            return this.companyCommunicationStratalotTodoApiService
              .addCompanyCommunicationStratalotTodo(companyCommunicationStratalotTodo)
              .pipe(
                mergeMap((companyCommunicationStratalotTodoReturned: CompanyCommunicationStratalotTodo) =>
                  getDefaultAddCompanyCommunicationStratalotTodoActions(companyCommunicationStratalotTodoReturned, ids)
                ),
                catchError(error =>
                  of(companyCommunicationStratalotTodoActions.companyCommunicationStratalotTodosFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneCompanyCommunicationStratalotTodo$ = createEffect(() => {
    const selectCompanyCommunicationStratalotTodoState$ = this.store$.select(
      selectCompanyCommunicationStratalotTodoState
    );
    return this.actions$.pipe(
      ofType(companyCommunicationStratalotTodoActions.deleteOneCompanyCommunicationStratalotTodo),
      withLatestFrom(selectCompanyCommunicationStratalotTodoState$),
      concatMap(([{ idCompanyCommunicationStratalotTodo }, state]) =>
        this.companyCommunicationStratalotTodoApiService
          .deleteCompanyCommunicationStratalotTodo(idCompanyCommunicationStratalotTodo)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteCompanyCommunicationStratalotTodoActions(
                  state.entities[idCompanyCommunicationStratalotTodo] as CompanyCommunicationStratalotTodoEntityState
                ),
                companyCommunicationStratalotTodoActions.deleteOneCompanyCommunicationStratalotTodo.type
              )
            ]),
            catchError(error =>
              of(companyCommunicationStratalotTodoActions.companyCommunicationStratalotTodosFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyCompanyCommunicationStratalotTodosAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationStratalotTodoActions.normalizeManyCompanyCommunicationStratalotTodosAfterUpsert),
      concatMap(({ companyCommunicationStratalotTodos }) => {
        const actions: Action[] = getActionsToNormalizeCompanyCommunicationStratalotTodo(
          companyCommunicationStratalotTodos,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CompanyCommunicationStratalotTodo] Normalization After Upsert Success')];
      })
    );
  });
}
