import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationStratalotTodoAppliedRuleActions from './organization-stratalot-todo-applied-rule.actions';
import {
  adapter,
  initialState,
  OrganizationStratalotTodoAppliedRuleState
} from './organization-stratalot-todo-applied-rule.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationStratalotTodoAppliedRuleEntityState } from '@_model/interfaces/organization-stratalot-todo-applied-rule.model';

export const organizationStratalotTodoAppliedRuleReducersGeneratedFunctions: ReducerTypes<
  OrganizationStratalotTodoAppliedRuleState,
  readonly ActionCreator[]
>[] = [
  on(
    OrganizationStratalotTodoAppliedRuleActions.getOneOrganizationStratalotTodoAppliedRule,
    (state: OrganizationStratalotTodoAppliedRuleState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationStratalotTodoAppliedRuleActions.getManyOrganizationStratalotTodoAppliedRules,
    (state: OrganizationStratalotTodoAppliedRuleState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationStratalotTodoAppliedRuleActions.upsertOneOrganizationStratalotTodoAppliedRule,
    (state: OrganizationStratalotTodoAppliedRuleState) => setLoadingsState(state, true)
  ),

  on(
    OrganizationStratalotTodoAppliedRuleActions.upsertManyOrganizationStratalotTodoAppliedRulesSuccess,
    (state: OrganizationStratalotTodoAppliedRuleState, { organizationStratalotTodoAppliedRules }) =>
      adapter.upsertMany(organizationStratalotTodoAppliedRules, setLoadingsState(state, false))
  ),
  on(
    OrganizationStratalotTodoAppliedRuleActions.deleteOneOrganizationStratalotTodoAppliedRule,
    (state: OrganizationStratalotTodoAppliedRuleState) => setLoadingsState(state, true)
  ),
  on(
    OrganizationStratalotTodoAppliedRuleActions.deleteOneOrganizationStratalotTodoAppliedRuleSuccess,
    (state: OrganizationStratalotTodoAppliedRuleState, { idOrganizationStratalotTodoAppliedRule }) =>
      adapter.removeOne(idOrganizationStratalotTodoAppliedRule, setLoadingsState(state, false))
  ),
  on(OrganizationStratalotTodoAppliedRuleActions.clearActive, (state: OrganizationStratalotTodoAppliedRuleState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationStratalotTodoAppliedRuleActions.addManyActives,
    (state: OrganizationStratalotTodoAppliedRuleState, { idOrganizationStratalotTodoAppliedRules }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationStratalotTodoAppliedRules)
    })
  ),
  on(
    OrganizationStratalotTodoAppliedRuleActions.deleteOneActive,
    (state: OrganizationStratalotTodoAppliedRuleState, { idOrganizationStratalotTodoAppliedRule }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationStratalotTodoAppliedRule)
    })
  ),

  on(OrganizationStratalotTodoAppliedRuleActions.clearStore, () => initialState),

  on(
    OrganizationStratalotTodoAppliedRuleActions.addOrganizationStratalotTodoSuccess,
    (
      state: OrganizationStratalotTodoAppliedRuleState,
      { idOrganizationStratalotTodoAppliedRule, idOrganizationStratalotTodo }
    ) => {
      if (!state.entities[idOrganizationStratalotTodoAppliedRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodoAppliedRule]: {
            ...state.entities[idOrganizationStratalotTodoAppliedRule],
            organizationStratalotTodo: idOrganizationStratalotTodo
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoAppliedRuleActions.deleteManyOrganizationStratalotTodoSuccess,
    (
      state: OrganizationStratalotTodoAppliedRuleState,
      { idOrganizationStratalotTodos, idOrganizationStratalotTodoAppliedRules }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodoAppliedRules.reduce((entities, idOrganizationStratalotTodoAppliedRule) => {
            if (!state.entities[idOrganizationStratalotTodoAppliedRule]?.organizationStratalotTodo) {
              return entities;
            }
            entities[idOrganizationStratalotTodoAppliedRule] = {
              ...state.entities[idOrganizationStratalotTodoAppliedRule],
              organizationStratalotTodo: idOrganizationStratalotTodos.some(
                (idOrganizationStratalotTodo: number) =>
                  idOrganizationStratalotTodo ===
                  state.entities[idOrganizationStratalotTodoAppliedRule]?.organizationStratalotTodo
              )
                ? undefined
                : state.entities[idOrganizationStratalotTodoAppliedRule]?.organizationStratalotTodo
            } as OrganizationStratalotTodoAppliedRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoAppliedRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoAppliedRuleActions.addOrganizationStratalotTodoRuleSuccess,
    (
      state: OrganizationStratalotTodoAppliedRuleState,
      { idOrganizationStratalotTodoAppliedRule, idOrganizationStratalotTodoRule }
    ) => {
      if (!state.entities[idOrganizationStratalotTodoAppliedRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationStratalotTodoAppliedRule]: {
            ...state.entities[idOrganizationStratalotTodoAppliedRule],
            organizationStratalotTodoRule: idOrganizationStratalotTodoRule
          }
        }
      };
    }
  ),

  on(
    OrganizationStratalotTodoAppliedRuleActions.deleteManyOrganizationStratalotTodoRuleSuccess,
    (
      state: OrganizationStratalotTodoAppliedRuleState,
      { idOrganizationStratalotTodoRules, idOrganizationStratalotTodoAppliedRules }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationStratalotTodoAppliedRules.reduce((entities, idOrganizationStratalotTodoAppliedRule) => {
            if (!state.entities[idOrganizationStratalotTodoAppliedRule]?.organizationStratalotTodoRule) {
              return entities;
            }
            entities[idOrganizationStratalotTodoAppliedRule] = {
              ...state.entities[idOrganizationStratalotTodoAppliedRule],
              organizationStratalotTodoRule: idOrganizationStratalotTodoRules.some(
                (idOrganizationStratalotTodoRule: number) =>
                  idOrganizationStratalotTodoRule ===
                  state.entities[idOrganizationStratalotTodoAppliedRule]?.organizationStratalotTodoRule
              )
                ? undefined
                : state.entities[idOrganizationStratalotTodoAppliedRule]?.organizationStratalotTodoRule
            } as OrganizationStratalotTodoAppliedRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationStratalotTodoAppliedRuleEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationStratalotTodoAppliedRuleState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationStratalotTodoAppliedRuleState {
  return { ...state, isLoaded, isLoading };
}
