import { RepositoryService } from '@_services/api/repository.service';
import { Stratalot } from '@_model/interfaces/stratalot.model';
import { Observable } from 'rxjs';

export class GeneratedStratalotApiService {
  constructor(protected repo: RepositoryService) {}

  public getStratalots(params?: any): Observable<Stratalot[]> {
    return this.repo.getData<Stratalot[]>('stratalot', params);
  }

  public getStratalot(params: { idStratalot: number; params?: any }): Observable<Stratalot> {
    return this.repo.getData<Stratalot>('stratalot/' + params.idStratalot, params.params);
  }

  public addStratalot(stratalot: Partial<Stratalot>): Observable<Stratalot> {
    return this.repo.create<Stratalot>('stratalot', stratalot);
  }

  public updateStratalot(stratalot: Partial<Stratalot>): Observable<Stratalot> {
    return this.repo.update('stratalot', stratalot);
  }

  public deleteStratalot(idStratalot: number): Observable<number> {
    return this.repo.delete('stratalot/' + +idStratalot);
  }
}
