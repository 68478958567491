import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationStratalotTodoRule,
  OrganizationStratalotTodoRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-rule.model';
import {
  OrganizationStratalotTodo,
  OrganizationStratalotTodoEntityState
} from '@_model/interfaces/organization-stratalot-todo.model';
import {
  OrganizationStratalotTodoAppliedRule,
  OrganizationStratalotTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-applied-rule.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import {
  OrganizationSaleCategory,
  OrganizationSaleCategoryEntityState
} from '@_model/interfaces/organization-sale-category.model';
import { SaleCategoryFamily, SaleCategoryFamilyEntityState } from '@_model/interfaces/sale-category-family.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { CompanyStratalotType, CompanyStratalotTypeEntityState } from '@_model/interfaces/company-stratalot-type.model';
import { StratalotType, StratalotTypeEntityState } from '@_model/interfaces/stratalot-type.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationStratalotTodoRuleFeatureKey,
  OrganizationStratalotTodoRuleState
} from './organization-stratalot-todo-rule.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationStratalotTodoRuleRelations: string[] = [
  'organizationStratalotTodos',
  'organizationStratalotTodoAppliedRules',
  'organizations',
  'organizationSaleCategories',
  'familySaleCategories',
  'companies',
  'companyStratalotTypes',
  'stratalotTypes'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationStratalotTodoRuleState = createFeatureSelector<OrganizationStratalotTodoRuleState>(
  organizationStratalotTodoRuleFeatureKey
);

export const selectIsLoadedOrganizationStratalotTodoRule = createSelector(
  selectOrganizationStratalotTodoRuleState,
  (state: OrganizationStratalotTodoRuleState) => state.isLoaded
);

export const selectIsLoadingOrganizationStratalotTodoRule = createSelector(
  selectOrganizationStratalotTodoRuleState,
  (state: OrganizationStratalotTodoRuleState) => state.isLoading
);

export const selectIsReadyOrganizationStratalotTodoRule = createSelector(
  selectOrganizationStratalotTodoRuleState,
  (state: OrganizationStratalotTodoRuleState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationStratalotTodoRule = createSelector(
  selectOrganizationStratalotTodoRuleState,
  (state: OrganizationStratalotTodoRuleState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationStratalotTodoRuleModel: SelectorModel = {
  name: 'organizationStratalotTodoRules',
  getSelector: selectAllOrganizationStratalotTodoRulesDictionary,
  isReady: selectIsReadyOrganizationStratalotTodoRule
};

export const selectOrganizationStratalotTodoRulesEntities = createSelector(
  selectOrganizationStratalotTodoRuleState,
  selectEntities
);

export const selectOrganizationStratalotTodoRulesArray = createSelector(
  selectOrganizationStratalotTodoRuleState,
  selectAll
);

export const selectIdOrganizationStratalotTodoRulesActive = createSelector(
  selectOrganizationStratalotTodoRuleState,
  (state: OrganizationStratalotTodoRuleState) => state.actives
);

const organizationStratalotTodoRulesInObject = (
  organizationStratalotTodoRules: Dictionary<OrganizationStratalotTodoRuleEntityState>
) => ({ organizationStratalotTodoRules });

const selectOrganizationStratalotTodoRulesEntitiesDictionary = createSelector(
  selectOrganizationStratalotTodoRulesEntities,
  organizationStratalotTodoRulesInObject
);

const selectAllOrganizationStratalotTodoRulesObject = createSelector(
  selectOrganizationStratalotTodoRulesEntities,
  organizationStratalotTodoRules => {
    return hydrateAll({ organizationStratalotTodoRules });
  }
);

const selectOneOrganizationStratalotTodoRuleDictionary = (idOrganizationStratalotTodoRule: number) =>
  createSelector(selectOrganizationStratalotTodoRulesEntities, organizationStratalotTodoRules => {
    return {
      organizationStratalotTodoRules: {
        [idOrganizationStratalotTodoRule]: organizationStratalotTodoRules[idOrganizationStratalotTodoRule]
      }
    };
  });

const selectOneOrganizationStratalotTodoRuleDictionaryWithoutChild = (idOrganizationStratalotTodoRule: number) =>
  createSelector(selectOrganizationStratalotTodoRulesEntities, organizationStratalotTodoRules => {
    return { organizationStratalotTodoRule: organizationStratalotTodoRules[idOrganizationStratalotTodoRule] };
  });

const selectActiveOrganizationStratalotTodoRulesEntities = createSelector(
  selectIdOrganizationStratalotTodoRulesActive,
  selectOrganizationStratalotTodoRulesEntities,
  (actives: number[], organizationStratalotTodoRules: Dictionary<OrganizationStratalotTodoRuleEntityState>) =>
    getOrganizationStratalotTodoRulesFromActives(actives, organizationStratalotTodoRules)
);

function getOrganizationStratalotTodoRulesFromActives(
  actives: number[],
  organizationStratalotTodoRules: Dictionary<OrganizationStratalotTodoRuleEntityState>
): Dictionary<OrganizationStratalotTodoRuleEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationStratalotTodoRules[idActive]) {
      acc[idActive] = organizationStratalotTodoRules[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationStratalotTodoRuleEntityState>);
}

const selectAllOrganizationStratalotTodoRulesSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationStratalotTodoRules(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationStratalotTodoRule>(
      schema,
      selectAllOrganizationStratalotTodoRulesSelectors,
      selectOrganizationStratalotTodoRulesEntitiesDictionary,
      getRelationSelectors,
      organizationStratalotTodoRuleRelations,
      hydrateAll,
      'organizationStratalotTodoRule'
    );
  } else {
    return selectAllOrganizationStratalotTodoRulesObject;
  }
}

export function selectAllOrganizationStratalotTodoRulesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationStratalotTodoRules'
): Selector {
  return createSelector(selectAllOrganizationStratalotTodoRules(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationStratalotTodoRuleEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationStratalotTodoRules.length; i++) {
      res[customKey][result.organizationStratalotTodoRules[i].idOrganizationStratalotTodoRule] =
        result.organizationStratalotTodoRules[i];
    }
    return res;
  });
}

export function selectOneOrganizationStratalotTodoRule(
  schema: SelectSchema = {},
  idOrganizationStratalotTodoRule: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationStratalotTodoRuleDictionary(idOrganizationStratalotTodoRule)];
    selectors.push(
      ...getRelationSelectors(schema, organizationStratalotTodoRuleRelations, 'organizationStratalotTodoRule')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationStratalotTodoRuleDictionaryWithoutChild(idOrganizationStratalotTodoRule);
  }
}

export function selectActiveOrganizationStratalotTodoRules(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationStratalotTodoRulesEntities, organizationStratalotTodoRules => ({
      organizationStratalotTodoRules
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, organizationStratalotTodoRuleRelations, 'organizationStratalotTodoRule')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationStratalotTodoRules: Dictionary<OrganizationStratalotTodoRuleEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  organizationSaleCategories?: Dictionary<OrganizationSaleCategoryEntityState>;
  familySaleCategories?: Dictionary<SaleCategoryFamilyEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  companyStratalotTypes?: Dictionary<CompanyStratalotTypeEntityState>;
  stratalotTypes?: Dictionary<StratalotTypeEntityState>;
  organizationStratalotTodos?: Dictionary<OrganizationStratalotTodoEntityState>;
  organizationStratalotTodoAppliedRules?: Dictionary<OrganizationStratalotTodoAppliedRuleEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationStratalotTodoRules: (OrganizationStratalotTodoRule | null)[];
} {
  const {
    organizationStratalotTodoRules,
    organizations,
    organizationSaleCategories,
    familySaleCategories,
    companies,
    companyStratalotTypes,
    stratalotTypes,
    organizationStratalotTodos,
    organizationStratalotTodoAppliedRules
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    organizationStratalotTodoRules: Object.keys(organizationStratalotTodoRules).map(idOrganizationStratalotTodoRule =>
      hydrate(
        organizationStratalotTodoRules[idOrganizationStratalotTodoRule] as OrganizationStratalotTodoRuleEntityState,
        organizations,
        organizationSaleCategories,
        familySaleCategories,
        companies,
        companyStratalotTypes,
        stratalotTypes,
        organizationStratalotTodos,
        organizationStratalotTodoAppliedRules
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationStratalotTodoRule: OrganizationStratalotTodoRuleEntityState | null;
} {
  const {
    organizationStratalotTodoRules,
    organizations,
    organizationSaleCategories,
    familySaleCategories,
    companies,
    companyStratalotTypes,
    stratalotTypes,
    organizationStratalotTodos,
    organizationStratalotTodoAppliedRules
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const organizationStratalotTodoRule = Object.values(organizationStratalotTodoRules)[0];
  return {
    organizationStratalotTodoRule: hydrate(
      organizationStratalotTodoRule as OrganizationStratalotTodoRuleEntityState,
      organizations,
      organizationSaleCategories,
      familySaleCategories,
      companies,
      companyStratalotTypes,
      stratalotTypes,
      organizationStratalotTodos,
      organizationStratalotTodoAppliedRules
    )
  };
}

function hydrate(
  organizationStratalotTodoRule: OrganizationStratalotTodoRuleEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  organizationSaleCategoryEntities?: Dictionary<OrganizationSaleCategoryEntityState>,
  saleCategoryFamilyEntities?: Dictionary<SaleCategoryFamilyEntityState>,
  companyEntities?: Dictionary<CompanyEntityState>,
  companyStratalotTypeEntities?: Dictionary<CompanyStratalotTypeEntityState>,
  stratalotTypeEntities?: Dictionary<StratalotTypeEntityState>,
  organizationStratalotTodoEntities?: Dictionary<OrganizationStratalotTodoEntityState>,
  organizationStratalotTodoAppliedRuleEntities?: Dictionary<OrganizationStratalotTodoAppliedRuleEntityState>
): OrganizationStratalotTodoRule | null {
  if (!organizationStratalotTodoRule) {
    return null;
  }

  const organizationStratalotTodoRuleHydrated: OrganizationStratalotTodoRuleEntityState = {
    ...organizationStratalotTodoRule
  };
  if (organizationEntities) {
    organizationStratalotTodoRuleHydrated.organization = organizationEntities[
      organizationStratalotTodoRule.organization as number
    ] as Organization;
  } else {
    delete organizationStratalotTodoRuleHydrated.organization;
  }
  if (organizationSaleCategoryEntities) {
    organizationStratalotTodoRuleHydrated.organizationSaleCategory = organizationSaleCategoryEntities[
      organizationStratalotTodoRule.organizationSaleCategory as number
    ] as OrganizationSaleCategory;
  } else {
    delete organizationStratalotTodoRuleHydrated.organizationSaleCategory;
  }
  if (saleCategoryFamilyEntities) {
    organizationStratalotTodoRuleHydrated.saleCategoryFamily = saleCategoryFamilyEntities[
      organizationStratalotTodoRule.saleCategoryFamily as number
    ] as SaleCategoryFamily;
  } else {
    delete organizationStratalotTodoRuleHydrated.saleCategoryFamily;
  }
  if (companyEntities) {
    organizationStratalotTodoRuleHydrated.company = companyEntities[
      organizationStratalotTodoRule.company as number
    ] as Company;
  } else {
    delete organizationStratalotTodoRuleHydrated.company;
  }
  if (companyStratalotTypeEntities) {
    organizationStratalotTodoRuleHydrated.companyStratalotType = companyStratalotTypeEntities[
      organizationStratalotTodoRule.companyStratalotType as number
    ] as CompanyStratalotType;
  } else {
    delete organizationStratalotTodoRuleHydrated.companyStratalotType;
  }
  if (stratalotTypeEntities) {
    organizationStratalotTodoRuleHydrated.stratalotType = stratalotTypeEntities[
      organizationStratalotTodoRule.stratalotType as number
    ] as StratalotType;
  } else {
    delete organizationStratalotTodoRuleHydrated.stratalotType;
  }

  if (organizationStratalotTodoEntities) {
    organizationStratalotTodoRuleHydrated.organizationStratalotTodos = (
      (organizationStratalotTodoRuleHydrated.organizationStratalotTodos as number[]) || []
    ).map(id => organizationStratalotTodoEntities[id]) as OrganizationStratalotTodo[];
  } else {
    delete organizationStratalotTodoRuleHydrated.organizationStratalotTodos;
  }

  if (organizationStratalotTodoAppliedRuleEntities) {
    organizationStratalotTodoRuleHydrated.organizationStratalotTodoAppliedRules = (
      (organizationStratalotTodoRuleHydrated.organizationStratalotTodoAppliedRules as number[]) || []
    ).map(id => organizationStratalotTodoAppliedRuleEntities[id]) as OrganizationStratalotTodoAppliedRule[];
  } else {
    delete organizationStratalotTodoRuleHydrated.organizationStratalotTodoAppliedRules;
  }

  return organizationStratalotTodoRuleHydrated as OrganizationStratalotTodoRule;
}
