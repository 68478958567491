import { Injectable } from '@angular/core';
import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedOrganizationApiService } from './organization-api-generated.service';
import { Observable } from 'rxjs';
import { Organization } from '@_shared/models/interfaces/organization.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationApiService extends GeneratedOrganizationApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public duplicateOrganizationAndCompanies(params): Observable<number> {
    return this.repo.create('organization/duplicate', params);
  }

  public getAllOrganizations(): Observable<Organization[]> {
    return this.repo.getData('organization/all', {});
    // public getAllOrganizations(params?: any): Observable<Organization[]> {
    //   return this.repo.getData<Organization[]>('organization/all', params);
  }
}
