import { Stratalot } from '@_shared/models/interfaces/stratalot.model';
import { StatutVente } from '@_shared/models/enums/stratalot-status.enum';
import { ResidenceStatutEnum } from '@_shared/models/enums/residence-statut.enum';
import { StepName } from '@_shared/models/enums/step-name.enum';
import { StratalotTypeAggrege } from '@_shared/enum/stratalot-type-aggrege';
import { Residence } from '@_shared/models/interfaces/residence.model';
import { TypeLotGroupEnum } from '@_shared/models/enums/type-lot-group.enum';
import { StratalotOccupationStatutEnum } from '@_shared/models/enums/stratalot-occupation-statut.enum';
import { StratalotCount } from '@_shared/interfaces/stratalot-count.interface';
import { User } from '@_shared/models/interfaces/user.model';
import { StratalotType } from '@_shared/models/interfaces/stratalot-type.model';
import { StratalotTodoApplicationEnum as StratalotTodoTypeEnum } from '@_shared/enum/stratalot-todo-application.enum';
import { StratalotTodo } from '@_model/interfaces/stratalot-todo.model';
import { SaleCategoryFamilyName } from '@_shared/models/enums/sale-category-family.enum';

namespace StratalotUtils {
  export function findStratalotTodoDateByStatalotTodoType(
    stratalot: Partial<Stratalot>,
    todoLibelle: StratalotTodoTypeEnum
  ): Date {
    if (!stratalot.stratalotTodos) {
      throw new Error('Aucun stratalotTodos trouver');
    }
    return findStratalotTodo(stratalot, todoLibelle)?.dateReception;
  }

  export function findStratalotTodo(stratalot: Partial<Stratalot>, todoLibelle: StratalotTodoTypeEnum): StratalotTodo {
    if (!stratalot?.stratalotTodos) {
      throw new Error('Aucun stratalotTodos trouver');
    }
    return stratalot?.stratalotTodos.find(el => el.organizationStratalotTodo?.libelle === todoLibelle);
  }

  export function getStratalotTypesGroupByStratalotTypeAggrege(stratalotTypes: StratalotType[]): {
    value: StratalotTypeAggrege;
    children: StratalotType[];
  }[] {
    return Object.entries(
      stratalotTypes?.reduce((acc, curr) => {
        return acc[StratalotUtils.getTypeAggrege(curr)]
          ? {
              ...acc,
              [StratalotUtils.getTypeAggrege(curr)]: acc[StratalotUtils.getTypeAggrege(curr)].concat([curr])
            }
          : { ...acc, [StratalotUtils.getTypeAggrege(curr)]: [curr] };
      }, {})
    )?.map(([key, value]: [StratalotTypeAggrege, StratalotType[]]) => ({ value: key, children: value }));
  }

  export function getStratalotTypesByStratalotTypeAgregee(
    stratalotTypes: StratalotType[],
    stratalotTypeAggrege: StratalotTypeAggrege
  ): StratalotType[] {
    return stratalotTypes.filter(st => {
      return getTypeAggrege(st) === stratalotTypeAggrege;
    });
  }

  export function getTypeAggrege(stratalotType: StratalotType): StratalotTypeAggrege {
    return stratalotType?.principal ? StratalotTypeAggrege.lotsPrincipaux : StratalotTypeAggrege.lotsAnnexes;
  }

  export function isStratalotTypeIsMain(stratalot: Stratalot): boolean {
    return isTypeLotIsMain(stratalot.companyStratalotType?.stratalotType);
  }
  export function isStratalotBelongToTerritories(stratalot: Stratalot, selectedCompanyTerritoires: number[]): boolean {
    if (!selectedCompanyTerritoires.length) {
      return true;
    }
    return stratalot?.residence?.companyTerritoires.some(territoire =>
      selectedCompanyTerritoires.includes(territoire.idCompanyTerritoire)
    );
  }
  export function isStratalotIsFreeAndOnSale(stratalot: Stratalot): boolean {
    return (
      stratalot.statutVente === StatutVente.onSale &&
      stratalot.residence?.statut === ResidenceStatutEnum.enVente &&
      (!!findStratalotTodoDateByStatalotTodoType(stratalot, StratalotTodoTypeEnum.Vacance) ||
        stratalot.idOccupant === null) &&
      (stratalot.stepProgress?.idStepProgress === null ||
        stratalot.stepProgress?.step?.libelle === StepName.commercialisation)
    );
  }
  export function isStratalotCampaignActive(stratalot: Stratalot): boolean {
    const stratalotCampaignActive = stratalot.stratalotCampaigns?.find(sc => sc.actif);

    return stratalotCampaignActive !== undefined;
  }

  export function getStratalotOccupationStatutClass(value: string): string | undefined {
    if (!value) {
      return undefined;
    }
    switch (value) {
      case StratalotOccupationStatutEnum.libre:
        return 'app-stratalot-libre';
      case StratalotOccupationStatutEnum.congé:
        return 'app-stratalot-conge';
      case StratalotOccupationStatutEnum.vendu:
        return 'app-stratalot-vendu';
      default:
        break;
    }
  }

  export function calcOccupationStatut(
    idOccupant: number,
    statutVente: StatutVente | undefined,
    dateVacance: Date
  ): StratalotOccupationStatutEnum {
    if (idOccupant && statutVente === StatutVente.onSale) {
      if (dateVacance) {
        const dateVacanceTmp = new Date(dateVacance);
        if (dateVacanceTmp.getTime() > new Date().getTime()) {
          // Si congé dont date passée alors libre
          return StratalotOccupationStatutEnum.congé;
        } else {
          // Si congé dont date futur alors occupé
          return StratalotOccupationStatutEnum.libre;
        }
      } else {
        return StratalotOccupationStatutEnum.occupé;
      }
    } else if (statutVente === StatutVente.sold) {
      return StratalotOccupationStatutEnum.vendu;
    } else if (statutVente === StatutVente.blocked) {
      return StratalotOccupationStatutEnum.bloqué;
    } else if (statutVente === StatutVente.notOnSale) {
      return StratalotOccupationStatutEnum.horsVente;
    } else {
      return StratalotOccupationStatutEnum.libre;
    }
  }

  export function getStratalotOccupationStatut(stratalot: Stratalot): StratalotOccupationStatutEnum {
    return calcOccupationStatut(
      stratalot.idOccupant,
      stratalot.statutVente,
      findStratalotTodoDateByStatalotTodoType(stratalot, StratalotTodoTypeEnum.Vacance)
    );
  }

  export function getAdresse(stratalot: Stratalot, residence?: Residence): string {
    let address = '';
    if (stratalot) {
      if (stratalot.adresse1) {
        address += stratalot.adresse1;
      }
      if (stratalot.adresse2) {
        address += ` ${stratalot.adresse2}`;
      }
      if (!stratalot.adresse1 && residence) {
        address = residence.adresse1;
      }
    }
    return address;
  }

  export function isTypeLotIsMain(stratalotType: StratalotType): boolean {
    return stratalotType?.principal;
  }

  export function isStratalotDisponible(stratalot: Stratalot): boolean {
    return stratalot && !isStratalotOccupe(stratalot) && !isStratalotWorkInProgress(stratalot);
  }

  export function isStratalotOccupe(stratalot: Stratalot): boolean {
    // La date de vacance doit avec une date de vacance non passée
    return (
      stratalot &&
      stratalot.idOccupant &&
      (!findStratalotTodoDateByStatalotTodoType(stratalot, StratalotTodoTypeEnum.Vacance) ||
        new Date(findStratalotTodoDateByStatalotTodoType(stratalot, StratalotTodoTypeEnum.Vacance)).getTime() >
          new Date().getTime())
    );
  }

  export function isStratalotWorkInProgress(stratalot: Stratalot): boolean {
    return (
      stratalot &&
      findStratalotTodoDateByStatalotTodoType(stratalot, StratalotTodoTypeEnum['Fin travaux']) &&
      new Date(findStratalotTodoDateByStatalotTodoType(stratalot, StratalotTodoTypeEnum['Fin travaux'])).getTime() >
        new Date().getTime()
    );
  }

  export function getDisplayTypologie(stratalot: Stratalot): string {
    if (stratalot.companyStratalotType?.stratalotType?.libelle === TypeLotGroupEnum.appartement) {
      return stratalot.pieces ? 'T' + stratalot.pieces : '';
    } else {
      return stratalot?.companyStratalotType?.libelle;
    }
  }

  export function getStratalotsCountByTypeLotGroup(stratalots: Stratalot[]): StratalotCount {
    const mainStratalots = stratalots.filter(
      (stratalot: Stratalot) =>
        stratalot.companyStratalotType?.stratalotType?.libelle === TypeLotGroupEnum.appartement ||
        stratalot.companyStratalotType?.stratalotType?.libelle === TypeLotGroupEnum.maison
    );

    const garageStratalots = stratalots.filter(
      (stratalot: Stratalot) => stratalot.companyStratalotType?.stratalotType?.libelle === TypeLotGroupEnum.parking
    );

    const othersStratalots = stratalots.filter(
      (stratalot: Stratalot) =>
        stratalot.companyStratalotType?.stratalotType?.libelle === TypeLotGroupEnum.autre ||
        stratalot.companyStratalotType?.stratalotType?.libelle === TypeLotGroupEnum.annexe
    );

    function filterByOnSaleStatus(stratalot: Stratalot): boolean {
      return stratalot.statutVente === StatutVente.onSale;
    }

    function filterBySoldStatus(stratalot: Stratalot): boolean {
      return stratalot.statutVente === StatutVente.sold;
    }

    return {
      allMainStratalots: mainStratalots.length,
      allStationnementStratalots: garageStratalots.length,
      allOtherStratalots: othersStratalots.length,
      mainStratalotsInSale: mainStratalots.filter(filterByOnSaleStatus).length,
      stationnementStratalotsInSale: garageStratalots.filter(filterByOnSaleStatus).length,
      othersStratalotsInSale: othersStratalots.filter(filterByOnSaleStatus).length,
      mainStratalotsSold: mainStratalots.filter(filterBySoldStatus).length,
      stationnementStratalotsSold: garageStratalots.filter(filterBySoldStatus).length,
      othersStratalotsSold: othersStratalots.filter(filterBySoldStatus).length
    };
  }

  export function getResponsableDetail(stratalot: Stratalot): { actualResponsible: User; inheritedResponsible: User } {
    if (stratalot) {
      if (!stratalot.residence) {
        throw new Error('residence obligatoire');
      }
      return {
        inheritedResponsible: stratalot.residence.responsable,
        actualResponsible: stratalot.responsable
      };
    }
  }

  export function getResponsable(stratalot: Stratalot): User {
    if (stratalot) {
      if (!stratalot.residence) {
        throw new Error('residence obligatoire');
      }
      const { inheritedResponsible, actualResponsible } = getResponsableDetail(stratalot);

      return actualResponsible ? actualResponsible : inheritedResponsible ? inheritedResponsible : null;
    }
  }

  export function getResidenceResponsable(stratalot: Stratalot): User {
    const { inheritedResponsible } = getResponsableDetail(stratalot);
    return inheritedResponsible ? inheritedResponsible : null;
  }

  export function isStratalotSaleCategoryFamilyPsla(stratalot: Stratalot): boolean {
    return stratalot?.organizationSaleCategory?.saleCategoryFamily.libelle === SaleCategoryFamilyName.psla;
  }
}

export default StratalotUtils;
