import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as DiffusionVisualActions from './diffusion-visual.actions';
import { adapter, initialState, DiffusionVisualState } from './diffusion-visual.state';
import { Dictionary } from '@ngrx/entity';
import { DiffusionVisualEntityState } from '@_model/interfaces/diffusion-visual.model';

export const diffusionVisualReducersGeneratedFunctions: ReducerTypes<DiffusionVisualState, readonly ActionCreator[]>[] =
  [
    on(DiffusionVisualActions.getOneDiffusionVisual, (state: DiffusionVisualState) => setLoadingsState(state, true)),
    on(DiffusionVisualActions.getManyDiffusionVisuals, (state: DiffusionVisualState) => setLoadingsState(state, true)),
    on(DiffusionVisualActions.upsertOneDiffusionVisual, (state: DiffusionVisualState) => setLoadingsState(state, true)),

    on(DiffusionVisualActions.upsertManyDiffusionVisualsSuccess, (state: DiffusionVisualState, { diffusionVisuals }) =>
      adapter.upsertMany(diffusionVisuals, setLoadingsState(state, false))
    ),
    on(DiffusionVisualActions.deleteOneDiffusionVisual, (state: DiffusionVisualState) => setLoadingsState(state, true)),
    on(DiffusionVisualActions.deleteOneDiffusionVisualSuccess, (state: DiffusionVisualState, { idDiffusionVisual }) =>
      adapter.removeOne(idDiffusionVisual, setLoadingsState(state, false))
    ),
    on(DiffusionVisualActions.clearActive, (state: DiffusionVisualState) => ({ ...state, actives: [] })),
    on(DiffusionVisualActions.addManyActives, (state: DiffusionVisualState, { idDiffusionVisuals }) => ({
      ...state,
      actives: state.actives.concat(idDiffusionVisuals)
    })),
    on(DiffusionVisualActions.deleteOneActive, (state: DiffusionVisualState, { idDiffusionVisual }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idDiffusionVisual)
    })),

    on(DiffusionVisualActions.clearStore, () => initialState),

    on(
      DiffusionVisualActions.addResidenceSuccess,
      (state: DiffusionVisualState, { idDiffusionVisual, idResidence }) => {
        if (!state.entities[idDiffusionVisual]) {
          return state;
        }
        return {
          ...state,
          entities: {
            ...state.entities,
            [idDiffusionVisual]: {
              ...state.entities[idDiffusionVisual],
              residence: idResidence
            }
          }
        };
      }
    ),

    on(
      DiffusionVisualActions.deleteManyResidenceSuccess,
      (state: DiffusionVisualState, { idResidences, idDiffusionVisuals }) => {
        return {
          ...state,
          entities: {
            ...state.entities,
            ...idDiffusionVisuals.reduce((entities, idDiffusionVisual) => {
              if (!state.entities[idDiffusionVisual]?.residence) {
                return entities;
              }
              entities[idDiffusionVisual] = {
                ...state.entities[idDiffusionVisual],
                residence: idResidences.some(
                  (idResidence: number) => idResidence === state.entities[idDiffusionVisual]?.residence
                )
                  ? undefined
                  : state.entities[idDiffusionVisual]?.residence
              } as DiffusionVisualEntityState;
              return entities;
            }, {} as Dictionary<DiffusionVisualEntityState>)
          }
        };
      }
    ),

    on(
      DiffusionVisualActions.addStratalotSuccess,
      (state: DiffusionVisualState, { idDiffusionVisual, idStratalot }) => {
        if (!state.entities[idDiffusionVisual]) {
          return state;
        }
        return {
          ...state,
          entities: {
            ...state.entities,
            [idDiffusionVisual]: {
              ...state.entities[idDiffusionVisual],
              stratalot: idStratalot
            }
          }
        };
      }
    ),

    on(
      DiffusionVisualActions.deleteManyStratalotSuccess,
      (state: DiffusionVisualState, { idStratalots, idDiffusionVisuals }) => {
        return {
          ...state,
          entities: {
            ...state.entities,
            ...idDiffusionVisuals.reduce((entities, idDiffusionVisual) => {
              if (!state.entities[idDiffusionVisual]?.stratalot) {
                return entities;
              }
              entities[idDiffusionVisual] = {
                ...state.entities[idDiffusionVisual],
                stratalot: idStratalots.some(
                  (idStratalot: number) => idStratalot === state.entities[idDiffusionVisual]?.stratalot
                )
                  ? undefined
                  : state.entities[idDiffusionVisual]?.stratalot
              } as DiffusionVisualEntityState;
              return entities;
            }, {} as Dictionary<DiffusionVisualEntityState>)
          }
        };
      }
    )
  ];

export function setLoadingsState(
  state: DiffusionVisualState,
  isLoading: boolean,
  isLoaded: boolean = true
): DiffusionVisualState {
  return { ...state, isLoaded, isLoading };
}
