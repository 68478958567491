import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  CompanyCommunicationStratalotTodo,
  CompanyCommunicationStratalotTodoEntityState
} from '@_model/interfaces/company-communication-stratalot-todo.model';
import { CompanyCommunicationStratalotTodoRelationsIds } from './company-communication-stratalot-todo-generated.effects';

export const getOneCompanyCommunicationStratalotTodo = createAction(
  '[CompanyCommunicationStratalotTodo] Get One CompanyCommunicationStratalotTodo',
  props<{ idCompanyCommunicationStratalotTodo: number; params?: any }>()
);

export const getManyCompanyCommunicationStratalotTodos = createAction(
  '[CompanyCommunicationStratalotTodo] Get Many CompanyCommunicationStratalotTodos',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyCommunicationStratalotTodo] Add Many Actives CompanyCommunicationStratalotTodo',
  props<{ idCompanyCommunicationStratalotTodos: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyCommunicationStratalotTodo] Delete One Active CompanyCommunicationStratalotTodo',
  props<{ idCompanyCommunicationStratalotTodo: number }>()
);

export const clearActive = createAction(
  '[CompanyCommunicationStratalotTodo] Clear Active CompanyCommunicationStratalotTodo'
);

export const upsertOneCompanyCommunicationStratalotTodo = createAction(
  '[CompanyCommunicationStratalotTodo] Upsert One CompanyCommunicationStratalotTodo',
  props<{
    companyCommunicationStratalotTodo: Partial<CompanyCommunicationStratalotTodo>;
    ids?: CompanyCommunicationStratalotTodoRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationStratalotTodos = createAction(
  '[CompanyCommunicationStratalotTodo] Upsert Many CompanyCommunicationStratalotTodos',
  props<{
    companyCommunicationStratalotTodos: Partial<CompanyCommunicationStratalotTodo>[];
    ids?: CompanyCommunicationStratalotTodoRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationStratalotTodosSuccess = createAction(
  '[CompanyCommunicationStratalotTodo] Create Many CompanyCommunicationStratalotTodos Success',
  props<{ companyCommunicationStratalotTodos: CompanyCommunicationStratalotTodoEntityState[] }>()
);

export const deleteOneCompanyCommunicationStratalotTodo = createAction(
  '[CompanyCommunicationStratalotTodo] Delete One CompanyCommunicationStratalotTodo',
  props<{ idCompanyCommunicationStratalotTodo: number }>()
);

export const deleteOneCompanyCommunicationStratalotTodoSuccess = createAction(
  '[CompanyCommunicationStratalotTodo] Delete One CompanyCommunicationStratalotTodo Success',
  props<{ idCompanyCommunicationStratalotTodo: number }>()
);

export const normalizeManyCompanyCommunicationStratalotTodosAfterUpsert = createAction(
  '[CompanyCommunicationStratalotTodo] Normalize Many CompanyCommunicationStratalotTodos After Upsert',
  props<{ companyCommunicationStratalotTodos: CompanyCommunicationStratalotTodoEntityState[] }>()
);

export const companyCommunicationStratalotTodosFailure = createAction(
  '[CompanyCommunicationStratalotTodo] CompanyCommunicationStratalotTodos Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyCommunicationStratalotTodo] Clear CompanyCommunicationStratalotTodos');

export const addCompanyCommunicationSuccess = createAction(
  '[CompanyCommunicationStratalotTodo] Add CompanyCommunication',
  props<{ idCompanyCommunicationStratalotTodo: number; idCompanyCommunication: number }>()
);

export const deleteManyCompanyCommunicationSuccess = createAction(
  '[CompanyCommunicationStratalotTodo] Delete Many CompanyCommunication',
  props<{ idCompanyCommunications: number[]; idCompanyCommunicationStratalotTodos: number[] }>()
);

export const addOrganizationStratalotTodoSuccess = createAction(
  '[CompanyCommunicationStratalotTodo] Add OrganizationStratalotTodo',
  props<{ idCompanyCommunicationStratalotTodo: number; idOrganizationStratalotTodo: number }>()
);

export const deleteManyOrganizationStratalotTodoSuccess = createAction(
  '[CompanyCommunicationStratalotTodo] Delete Many OrganizationStratalotTodo',
  props<{ idOrganizationStratalotTodos: number[]; idCompanyCommunicationStratalotTodos: number[] }>()
);
