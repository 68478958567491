import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyStratalotVacantEntityState } from '@_model/interfaces/company-stratalot-vacant.model';

export interface CompanyStratalotVacantState extends EntityState<CompanyStratalotVacantEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyStratalotVacantEntityState> =
  createEntityAdapter<CompanyStratalotVacantEntityState>({
    selectId: o => o.idCompanyStratalotVacant
  });
export const initialState: CompanyStratalotVacantState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyStratalotVacantFeatureKey = 'companyStratalotVacant';
