export enum StepProgressName {
  aCalculer = 'Non définie',
  nonDisponible = 'Non disponible',
  occupe = 'Occupé',

  // Disponible
  sousDedite = 'Sous dédite',
  conge = 'Congé',
  disponible = 'Disponible',
  disponibleAvecProspects = 'Disponible avec prospects',
  disponibleAvecOffres = 'Disponible avec offres',

  // Offres à valider
  offresAValider = 'Offres à valider',

  // Offre acceptée
  offreAcceptee = 'Offre acceptée',
  compromisPlanifie = 'Offre acceptée - Compromis planifié',
  reservationPlanifiee = 'Offre acceptée - Réservation planifiée',

  // Compromis
  compromisSigne = 'Compromis',
  venteAncienPlanifie = 'Compromis - Vente planifiée',

  // Réservé
  reserve = 'Réservé',
  contratLocationAccessionPlanifie = 'Réservé - Contrat location accession planifié',

  // CLA

  contratLocationAccession = 'Contrat location accession',
  leveeOptionPlanifie = 'Contrat location accession - Levée option planifiée',

  // Levée option
  leveeOption = 'Levée option',

  // Non levée option
  nonLeveeOptionPlanifie = 'Non levée option planifiée',
  nonLeveeOption = 'Non levée option',
  resiliationPlanifiee = 'Non levée option - Résiliation planifiée',

  // Résiliation
  resilie = 'Résilié',

  bloqué = 'Bloqué',
  horsVente = 'Hors vente',

  venteVefaPlanifie = 'Réservé - Acte planifié',
  ventePslaPlanifie = 'Levée option - Vente planifiée',

  // Vendu

  venteACloturer = 'Vente à clôturer',
  //FIXME:faire migration et bougé front bouton
  venteArchive = 'Vente archivée'
}
