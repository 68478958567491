import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyStudyCriteriaActions from './company-study-criteria.actions';
import { adapter, initialState, CompanyStudyCriteriaState } from './company-study-criteria.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyStudyCriteriaEntityState } from '@_model/interfaces/company-study-criteria.model';

export const companyStudyCriteriaReducersGeneratedFunctions: ReducerTypes<
  CompanyStudyCriteriaState,
  readonly ActionCreator[]
>[] = [
  on(CompanyStudyCriteriaActions.getOneCompanyStudyCriteria, (state: CompanyStudyCriteriaState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyStudyCriteriaActions.getManyCompanyStudyCriterias, (state: CompanyStudyCriteriaState) =>
    setLoadingsState(state, true)
  ),
  on(CompanyStudyCriteriaActions.upsertOneCompanyStudyCriteria, (state: CompanyStudyCriteriaState) =>
    setLoadingsState(state, true)
  ),

  on(
    CompanyStudyCriteriaActions.upsertManyCompanyStudyCriteriasSuccess,
    (state: CompanyStudyCriteriaState, { companyStudyCriterias }) =>
      adapter.upsertMany(companyStudyCriterias, setLoadingsState(state, false))
  ),
  on(CompanyStudyCriteriaActions.deleteOneCompanyStudyCriteria, (state: CompanyStudyCriteriaState) =>
    setLoadingsState(state, true)
  ),
  on(
    CompanyStudyCriteriaActions.deleteOneCompanyStudyCriteriaSuccess,
    (state: CompanyStudyCriteriaState, { idCompanyStudyCriteria }) =>
      adapter.removeOne(idCompanyStudyCriteria, setLoadingsState(state, false))
  ),
  on(CompanyStudyCriteriaActions.clearActive, (state: CompanyStudyCriteriaState) => ({ ...state, actives: [] })),
  on(CompanyStudyCriteriaActions.addManyActives, (state: CompanyStudyCriteriaState, { idCompanyStudyCriterias }) => ({
    ...state,
    actives: state.actives.concat(idCompanyStudyCriterias)
  })),
  on(CompanyStudyCriteriaActions.deleteOneActive, (state: CompanyStudyCriteriaState, { idCompanyStudyCriteria }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idCompanyStudyCriteria)
  })),

  on(CompanyStudyCriteriaActions.clearStore, () => initialState),

  on(
    CompanyStudyCriteriaActions.addManyResidenceStudyCriteriaSuccess,
    (state: CompanyStudyCriteriaState, { idCompanyStudyCriteria, idResidenceStudyCriterias }) => {
      if (!state.entities[idCompanyStudyCriteria]) {
        return state;
      }
      const residenceStudyCriterias =
        (state.entities[idCompanyStudyCriteria]?.residenceStudyCriterias as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStudyCriteria]: {
            ...state.entities[idCompanyStudyCriteria],
            residenceStudyCriterias: residenceStudyCriterias.concat(
              idResidenceStudyCriterias.filter(id => residenceStudyCriterias.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    CompanyStudyCriteriaActions.deleteManyResidenceStudyCriteriaSuccess,
    (state: CompanyStudyCriteriaState, { idResidenceStudyCriterias, idCompanyStudyCriterias }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStudyCriterias.reduce((entities, idCompanyStudyCriteria) => {
            if (!state.entities[idCompanyStudyCriteria]?.residenceStudyCriterias) {
              return entities;
            }
            entities[idCompanyStudyCriteria] = {
              ...state.entities[idCompanyStudyCriteria],
              residenceStudyCriterias: (
                state.entities[idCompanyStudyCriteria]?.residenceStudyCriterias as number[]
              )?.filter(
                (idResidenceStudyCriteria: number) =>
                  !idResidenceStudyCriterias.some((id: number) => id === idResidenceStudyCriteria)
              )
            } as CompanyStudyCriteriaEntityState;
            return entities;
          }, {} as Dictionary<CompanyStudyCriteriaEntityState>)
        }
      };
    }
  ),

  on(
    CompanyStudyCriteriaActions.addCompanySuccess,
    (state: CompanyStudyCriteriaState, { idCompanyStudyCriteria, idCompany }) => {
      if (!state.entities[idCompanyStudyCriteria]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyStudyCriteria]: {
            ...state.entities[idCompanyStudyCriteria],
            company: idCompany
          }
        }
      };
    }
  ),

  on(
    CompanyStudyCriteriaActions.deleteManyCompanySuccess,
    (state: CompanyStudyCriteriaState, { idCompanies, idCompanyStudyCriterias }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyStudyCriterias.reduce((entities, idCompanyStudyCriteria) => {
            if (!state.entities[idCompanyStudyCriteria]?.company) {
              return entities;
            }
            entities[idCompanyStudyCriteria] = {
              ...state.entities[idCompanyStudyCriteria],
              company: idCompanies.some(
                (idCompany: number) => idCompany === state.entities[idCompanyStudyCriteria]?.company
              )
                ? undefined
                : state.entities[idCompanyStudyCriteria]?.company
            } as CompanyStudyCriteriaEntityState;
            return entities;
          }, {} as Dictionary<CompanyStudyCriteriaEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyStudyCriteriaState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyStudyCriteriaState {
  return { ...state, isLoaded, isLoading };
}
