import { createReducer, on, Action } from '@ngrx/store';
import { initialState, FilterState } from './filter.state';
import * as filterActions from '@_store/filter/filter.actions';
const filterReducer = createReducer(
  initialState,
  on(filterActions.setFilterOccupedStratalot, (state, { occupedStratalotFilter }) => ({
    ...state,
    activeOccupedStratalotFilter: occupedStratalotFilter
  })),
  on(filterActions.setFilterProspect, (state, { prospectFilter }) => ({
    ...state,
    activeProspectFilter: prospectFilter
  }))
);

export function reducer(state: FilterState | undefined, action: Action) {
  return filterReducer(state, action);
}
