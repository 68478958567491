import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProspectEvent, ProspectEventEntityState } from '@_model/interfaces/prospect-event.model';
import { Prospect, ProspectEntityState } from '@_model/interfaces/prospect.model';
import { Lead, LeadEntityState } from '@_model/interfaces/lead.model';
import { User, UserEntityState } from '@_model/interfaces/user.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, prospectEventFeatureKey, ProspectEventState } from './prospect-event.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const prospectEventRelations: string[] = ['prospects', 'leads', 'users'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectProspectEventState = createFeatureSelector<ProspectEventState>(prospectEventFeatureKey);

export const selectIsLoadedProspectEvent = createSelector(
  selectProspectEventState,
  (state: ProspectEventState) => state.isLoaded
);

export const selectIsLoadingProspectEvent = createSelector(
  selectProspectEventState,
  (state: ProspectEventState) => state.isLoading
);

export const selectIsReadyProspectEvent = createSelector(
  selectProspectEventState,
  (state: ProspectEventState) => !state.isLoading
);

export const selectIsReadyAndLoadedProspectEvent = createSelector(
  selectProspectEventState,
  (state: ProspectEventState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ProspectEventModel: SelectorModel = {
  name: 'prospectEvents',
  getSelector: selectAllProspectEventsDictionary,
  isReady: selectIsReadyProspectEvent
};

export const selectProspectEventsEntities = createSelector(selectProspectEventState, selectEntities);

export const selectProspectEventsArray = createSelector(selectProspectEventState, selectAll);

export const selectIdProspectEventsActive = createSelector(
  selectProspectEventState,
  (state: ProspectEventState) => state.actives
);

const prospectEventsInObject = (prospectEvents: Dictionary<ProspectEventEntityState>) => ({ prospectEvents });

const selectProspectEventsEntitiesDictionary = createSelector(selectProspectEventsEntities, prospectEventsInObject);

const selectAllProspectEventsObject = createSelector(selectProspectEventsEntities, prospectEvents => {
  return hydrateAll({ prospectEvents });
});

const selectOneProspectEventDictionary = (idProspectEvent: number) =>
  createSelector(selectProspectEventsEntities, prospectEvents => {
    return { prospectEvents: { [idProspectEvent]: prospectEvents[idProspectEvent] } };
  });

const selectOneProspectEventDictionaryWithoutChild = (idProspectEvent: number) =>
  createSelector(selectProspectEventsEntities, prospectEvents => {
    return { prospectEvent: prospectEvents[idProspectEvent] };
  });

const selectActiveProspectEventsEntities = createSelector(
  selectIdProspectEventsActive,
  selectProspectEventsEntities,
  (actives: number[], prospectEvents: Dictionary<ProspectEventEntityState>) =>
    getProspectEventsFromActives(actives, prospectEvents)
);

function getProspectEventsFromActives(
  actives: number[],
  prospectEvents: Dictionary<ProspectEventEntityState>
): Dictionary<ProspectEventEntityState> {
  return actives.reduce((acc, idActive) => {
    if (prospectEvents[idActive]) {
      acc[idActive] = prospectEvents[idActive];
    }
    return acc;
  }, {} as Dictionary<ProspectEventEntityState>);
}

const selectAllProspectEventsSelectors: Dictionary<Selector> = {};
export function selectAllProspectEvents(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ProspectEvent>(
      schema,
      selectAllProspectEventsSelectors,
      selectProspectEventsEntitiesDictionary,
      getRelationSelectors,
      prospectEventRelations,
      hydrateAll,
      'prospectEvent'
    );
  } else {
    return selectAllProspectEventsObject;
  }
}

export function selectAllProspectEventsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'prospectEvents'
): Selector {
  return createSelector(selectAllProspectEvents(schema), result => {
    const res = { [customKey]: {} as Dictionary<ProspectEventEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.prospectEvents.length; i++) {
      res[customKey][result.prospectEvents[i].idProspectEvent] = result.prospectEvents[i];
    }
    return res;
  });
}

export function selectOneProspectEvent(schema: SelectSchema = {}, idProspectEvent: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneProspectEventDictionary(idProspectEvent)];
    selectors.push(...getRelationSelectors(schema, prospectEventRelations, 'prospectEvent'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneProspectEventDictionaryWithoutChild(idProspectEvent);
  }
}

export function selectActiveProspectEvents(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveProspectEventsEntities, prospectEvents => ({ prospectEvents }))
  ];
  selectors.push(...getRelationSelectors(schema, prospectEventRelations, 'prospectEvent'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  prospectEvents: Dictionary<ProspectEventEntityState>;
  prospects?: Dictionary<ProspectEntityState>;
  leads?: Dictionary<LeadEntityState>;
  users?: Dictionary<UserEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { prospectEvents: (ProspectEvent | null)[] } {
  const { prospectEvents, prospects, leads, users } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    prospectEvents: Object.keys(prospectEvents).map(idProspectEvent =>
      hydrate(prospectEvents[idProspectEvent] as ProspectEventEntityState, prospects, leads, users)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { prospectEvent: ProspectEventEntityState | null } {
  const { prospectEvents, prospects, leads, users } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const prospectEvent = Object.values(prospectEvents)[0];
  return { prospectEvent: hydrate(prospectEvent as ProspectEventEntityState, prospects, leads, users) };
}

function hydrate(
  prospectEvent: ProspectEventEntityState,
  prospectEntities?: Dictionary<ProspectEntityState>,
  leadEntities?: Dictionary<LeadEntityState>,
  userEntities?: Dictionary<UserEntityState>
): ProspectEvent | null {
  if (!prospectEvent) {
    return null;
  }

  const prospectEventHydrated: ProspectEventEntityState = { ...prospectEvent };
  if (prospectEntities) {
    prospectEventHydrated.prospect = prospectEntities[prospectEvent.prospect as number] as Prospect;
  } else {
    delete prospectEventHydrated.prospect;
  }
  if (leadEntities) {
    prospectEventHydrated.lead = leadEntities[prospectEvent.lead as number] as Lead;
  } else {
    delete prospectEventHydrated.lead;
  }
  if (userEntities) {
    prospectEventHydrated.user = userEntities[prospectEvent.user as number] as User;
  } else {
    delete prospectEventHydrated.user;
  }

  return prospectEventHydrated as ProspectEvent;
}
