import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { CompanyStudyCriteria, CompanyStudyCriteriaEntityState } from '@_model/interfaces/company-study-criteria.model';
import { CompanyStudyCriteriaApiService } from '@_services/api/company-study-criteria-api.service';
import * as companyStudyCriteriaActions from '@_store/company-study-criteria/company-study-criteria.actions';
import { getActionsToNormalizeCompanyStudyCriteria } from '@_config/store/normalization.generated';
import { selectCompanyStudyCriteriaState } from './company-study-criteria-generated.selectors';
import * as companyActions from '@_store/company/company.actions';
import * as residenceStudyCriteriaActions from '@_store/residence-study-criteria/residence-study-criteria.actions';
import { ResidenceStudyCriteria } from '@_model/interfaces/residence-study-criteria.model';

export interface CompanyStudyCriteriaRelationsIds {
  residenceStudyCriterias?: number | number[];
  company?: number;
}

export function getDefaultAddCompanyStudyCriteriaActions(
  companyStudyCriteria: CompanyStudyCriteriaEntityState,
  ids?: CompanyStudyCriteriaRelationsIds
): Action[] {
  const actions: Action[] = [
    companyStudyCriteriaActions.normalizeManyCompanyStudyCriteriasAfterUpsert({
      companyStudyCriterias: [companyStudyCriteria]
    })
  ];

  if (ids?.company) {
    actions.push(
      companyActions.addManyCompanyStudyCriteriaSuccess({
        idCompany: ids.company,
        idCompanyStudyCriterias: [companyStudyCriteria.idCompanyStudyCriteria]
      })
    );
    actions.push(
      companyStudyCriteriaActions.addCompanySuccess({
        idCompanyStudyCriteria: companyStudyCriteria.idCompanyStudyCriteria,
        idCompany: ids.company
      })
    );
  }

  if (ids?.residenceStudyCriterias) {
    if (!Array.isArray(ids.residenceStudyCriterias)) {
      actions.push(
        residenceStudyCriteriaActions.upsertOneResidenceStudyCriteria({
          residenceStudyCriteria: {
            idCompanyStudyCriteria: companyStudyCriteria.idCompanyStudyCriteria,
            idResidenceStudyCriteria: ids.residenceStudyCriterias as number
          } as ResidenceStudyCriteria
        })
      );
      actions.push(
        companyStudyCriteriaActions.addManyResidenceStudyCriteriaSuccess({
          idCompanyStudyCriteria: companyStudyCriteria.idCompanyStudyCriteria,
          idResidenceStudyCriterias: [ids.residenceStudyCriterias as number]
        })
      );
    } else {
      actions.push(
        residenceStudyCriteriaActions.upsertManyResidenceStudyCriterias({
          residenceStudyCriterias: (ids.residenceStudyCriterias as number[]).map(
            (idResidenceStudyCriteria: number) => ({
              idCompanyStudyCriteria: companyStudyCriteria.idCompanyStudyCriteria,
              idResidenceStudyCriteria
            })
          ) as ResidenceStudyCriteria[]
        })
      );
      actions.push(
        companyStudyCriteriaActions.addManyResidenceStudyCriteriaSuccess({
          idCompanyStudyCriteria: companyStudyCriteria.idCompanyStudyCriteria,
          idResidenceStudyCriterias: ids.residenceStudyCriterias as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCompanyStudyCriteriaActions(
  companyStudyCriteria: CompanyStudyCriteriaEntityState
): Action[] {
  const actions: Action[] = [
    companyStudyCriteriaActions.deleteOneCompanyStudyCriteriaSuccess({
      idCompanyStudyCriteria: companyStudyCriteria.idCompanyStudyCriteria
    })
  ];

  if (companyStudyCriteria.company) {
    actions.push(
      companyActions.deleteManyCompanyStudyCriteriaSuccess({
        idCompanyStudyCriterias: [companyStudyCriteria.idCompanyStudyCriteria],
        idCompanies: [companyStudyCriteria.company as number]
      })
    );
  }

  if (companyStudyCriteria.residenceStudyCriterias) {
    actions.push(
      residenceStudyCriteriaActions.deleteManyCompanyStudyCriteriaSuccess({
        idCompanyStudyCriterias: [companyStudyCriteria.idCompanyStudyCriteria],
        idResidenceStudyCriterias: companyStudyCriteria.residenceStudyCriterias as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyStudyCriteriaEffects {
  constructor(
    protected actions$: Actions,
    protected companyStudyCriteriaApiService: CompanyStudyCriteriaApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyStudyCriterias$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStudyCriteriaActions.getManyCompanyStudyCriterias),
      switchMap(({ params }) =>
        this.companyStudyCriteriaApiService.getCompanyStudyCriterias(params).pipe(
          map((companyStudyCriterias: CompanyStudyCriteria[]) => {
            return companyStudyCriteriaActions.normalizeManyCompanyStudyCriteriasAfterUpsert({ companyStudyCriterias });
          }),
          catchError(error => of(companyStudyCriteriaActions.companyStudyCriteriasFailure({ error })))
        )
      )
    );
  });

  getOneCompanyStudyCriteria$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStudyCriteriaActions.getOneCompanyStudyCriteria),
      switchMap(idCompanyStudyCriteria =>
        this.companyStudyCriteriaApiService.getCompanyStudyCriteria(idCompanyStudyCriteria).pipe(
          map((companyStudyCriteria: CompanyStudyCriteria) => {
            return companyStudyCriteriaActions.normalizeManyCompanyStudyCriteriasAfterUpsert({
              companyStudyCriterias: [companyStudyCriteria]
            });
          }),
          catchError(error => of(companyStudyCriteriaActions.companyStudyCriteriasFailure({ error })))
        )
      )
    );
  });

  upsertOneCompanyStudyCriteria$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStudyCriteriaActions.upsertOneCompanyStudyCriteria),
      concatMap(
        ({
          companyStudyCriteria,
          ids
        }: {
          companyStudyCriteria: Partial<CompanyStudyCriteria>;
          ids?: CompanyStudyCriteriaRelationsIds;
        }) => {
          if (companyStudyCriteria.idCompanyStudyCriteria) {
            return this.companyStudyCriteriaApiService.updateCompanyStudyCriteria(companyStudyCriteria).pipe(
              map((companyStudyCriteriaReturned: CompanyStudyCriteria) => {
                return companyStudyCriteriaActions.normalizeManyCompanyStudyCriteriasAfterUpsert({
                  companyStudyCriterias: [companyStudyCriteriaReturned]
                });
              }),
              catchError(error => of(companyStudyCriteriaActions.companyStudyCriteriasFailure({ error })))
            );
          } else {
            return this.companyStudyCriteriaApiService.addCompanyStudyCriteria(companyStudyCriteria).pipe(
              mergeMap((companyStudyCriteriaReturned: CompanyStudyCriteria) =>
                getDefaultAddCompanyStudyCriteriaActions(companyStudyCriteriaReturned, ids)
              ),
              catchError(error => of(companyStudyCriteriaActions.companyStudyCriteriasFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCompanyStudyCriteria$ = createEffect(() => {
    const selectCompanyStudyCriteriaState$ = this.store$.select(selectCompanyStudyCriteriaState);
    return this.actions$.pipe(
      ofType(companyStudyCriteriaActions.deleteOneCompanyStudyCriteria),
      withLatestFrom(selectCompanyStudyCriteriaState$),
      concatMap(([{ idCompanyStudyCriteria }, state]) =>
        this.companyStudyCriteriaApiService.deleteCompanyStudyCriteria(idCompanyStudyCriteria).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCompanyStudyCriteriaActions(
                state.entities[idCompanyStudyCriteria] as CompanyStudyCriteriaEntityState
              ),
              companyStudyCriteriaActions.deleteOneCompanyStudyCriteria.type
            )
          ]),
          catchError(error => of(companyStudyCriteriaActions.companyStudyCriteriasFailure({ error })))
        )
      )
    );
  });

  normalizeManyCompanyStudyCriteriasAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyStudyCriteriaActions.normalizeManyCompanyStudyCriteriasAfterUpsert),
      concatMap(({ companyStudyCriterias }) => {
        const actions: Action[] = getActionsToNormalizeCompanyStudyCriteria(
          companyStudyCriterias,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CompanyStudyCriteria] Normalization After Upsert Success')];
      })
    );
  });
}
