import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ProspectEntityState } from '@_model/interfaces/prospect.model';

export interface ProspectState extends EntityState<ProspectEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ProspectEntityState> = createEntityAdapter<ProspectEntityState>({
  selectId: o => o.idProspect
});
export const initialState: ProspectState = adapter.getInitialState({ isLoaded: false, isLoading: false, actives: [] });

export const prospectFeatureKey = 'prospect';
