import { ProspectBuyingWishState, initialState } from './prospect-buying-wish.state';
import { Action, createReducer, on } from '@ngrx/store';
import { prospectBuyingWishReducersGeneratedFunctions } from './prospect-buying-wish-generated.reducer';
import * as prospectBuyingWishActions from '@_store/prospect-buying-wish/prospect-buying-wish.actions';

const prospectBuyingWishReducersFunctions = [
  ...prospectBuyingWishReducersGeneratedFunctions,
  on(
    prospectBuyingWishActions.deleteManyProspectBuyingWishByProspectSuccess,
    (state: ProspectBuyingWishState, { idProspect }) => {
      return {
        ...state,
        entities: Object.keys(state.entities)
          .filter((idProspectBuyingWish: string) => state.entities[idProspectBuyingWish].prospect !== idProspect)
          .map((idProspectBuyingWish: string) => {
            return { ...state.entities[idProspectBuyingWish] };
          })
          .reduce((acc, curr) => {
            acc[curr.idProspectBuyingWish] = curr;
            return acc;
          }, {})
      };
    }
  )
];

const prospectBuyingWishReducer = createReducer(initialState, ...prospectBuyingWishReducersFunctions);

export function reducer(state: ProspectBuyingWishState | undefined, action: Action) {
  return prospectBuyingWishReducer(state, action);
}
