import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MetierFamilie, MetierFamilieEntityState } from '@_model/interfaces/metier-familie.model';
import { OrganizationMetier, OrganizationMetierEntityState } from '@_model/interfaces/organization-metier.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, metierFamilieFeatureKey, MetierFamilieState } from './metier-familie.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const metierFamilieRelations: string[] = ['organizationMetiers'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectMetierFamilieState = createFeatureSelector<MetierFamilieState>(metierFamilieFeatureKey);

export const selectIsLoadedMetierFamilie = createSelector(
  selectMetierFamilieState,
  (state: MetierFamilieState) => state.isLoaded
);

export const selectIsLoadingMetierFamilie = createSelector(
  selectMetierFamilieState,
  (state: MetierFamilieState) => state.isLoading
);

export const selectIsReadyMetierFamilie = createSelector(
  selectMetierFamilieState,
  (state: MetierFamilieState) => !state.isLoading
);

export const selectIsReadyAndLoadedMetierFamilie = createSelector(
  selectMetierFamilieState,
  (state: MetierFamilieState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const MetierFamilieModel: SelectorModel = {
  name: 'metierFamilies',
  getSelector: selectAllMetierFamiliesDictionary,
  isReady: selectIsReadyMetierFamilie
};

export const selectMetierFamiliesEntities = createSelector(selectMetierFamilieState, selectEntities);

export const selectMetierFamiliesArray = createSelector(selectMetierFamilieState, selectAll);

export const selectIdMetierFamiliesActive = createSelector(
  selectMetierFamilieState,
  (state: MetierFamilieState) => state.actives
);

const metierFamiliesInObject = (metierFamilies: Dictionary<MetierFamilieEntityState>) => ({ metierFamilies });

const selectMetierFamiliesEntitiesDictionary = createSelector(selectMetierFamiliesEntities, metierFamiliesInObject);

const selectAllMetierFamiliesObject = createSelector(selectMetierFamiliesEntities, metierFamilies => {
  return hydrateAll({ metierFamilies });
});

const selectOneMetierFamilieDictionary = (idMetierFamilie: number) =>
  createSelector(selectMetierFamiliesEntities, metierFamilies => {
    return { metierFamilies: { [idMetierFamilie]: metierFamilies[idMetierFamilie] } };
  });

const selectOneMetierFamilieDictionaryWithoutChild = (idMetierFamilie: number) =>
  createSelector(selectMetierFamiliesEntities, metierFamilies => {
    return { metierFamilie: metierFamilies[idMetierFamilie] };
  });

const selectActiveMetierFamiliesEntities = createSelector(
  selectIdMetierFamiliesActive,
  selectMetierFamiliesEntities,
  (actives: number[], metierFamilies: Dictionary<MetierFamilieEntityState>) =>
    getMetierFamiliesFromActives(actives, metierFamilies)
);

function getMetierFamiliesFromActives(
  actives: number[],
  metierFamilies: Dictionary<MetierFamilieEntityState>
): Dictionary<MetierFamilieEntityState> {
  return actives.reduce((acc, idActive) => {
    if (metierFamilies[idActive]) {
      acc[idActive] = metierFamilies[idActive];
    }
    return acc;
  }, {} as Dictionary<MetierFamilieEntityState>);
}

const selectAllMetierFamiliesSelectors: Dictionary<Selector> = {};
export function selectAllMetierFamilies(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<MetierFamilie>(
      schema,
      selectAllMetierFamiliesSelectors,
      selectMetierFamiliesEntitiesDictionary,
      getRelationSelectors,
      metierFamilieRelations,
      hydrateAll,
      'metierFamilie'
    );
  } else {
    return selectAllMetierFamiliesObject;
  }
}

export function selectAllMetierFamiliesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'metierFamilies'
): Selector {
  return createSelector(selectAllMetierFamilies(schema), result => {
    const res = { [customKey]: {} as Dictionary<MetierFamilieEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.metierFamilies.length; i++) {
      res[customKey][result.metierFamilies[i].idMetierFamilie] = result.metierFamilies[i];
    }
    return res;
  });
}

export function selectOneMetierFamilie(schema: SelectSchema = {}, idMetierFamilie: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneMetierFamilieDictionary(idMetierFamilie)];
    selectors.push(...getRelationSelectors(schema, metierFamilieRelations, 'metierFamilie'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneMetierFamilieDictionaryWithoutChild(idMetierFamilie);
  }
}

export function selectActiveMetierFamilies(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveMetierFamiliesEntities, metierFamilies => ({ metierFamilies }))
  ];
  selectors.push(...getRelationSelectors(schema, metierFamilieRelations, 'metierFamilie'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  metierFamilies: Dictionary<MetierFamilieEntityState>;
  organizationMetiers?: Dictionary<OrganizationMetierEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { metierFamilies: (MetierFamilie | null)[] } {
  const { metierFamilies, organizationMetiers } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    metierFamilies: Object.keys(metierFamilies).map(idMetierFamilie =>
      hydrate(metierFamilies[idMetierFamilie] as MetierFamilieEntityState, organizationMetiers)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { metierFamilie: MetierFamilieEntityState | null } {
  const { metierFamilies, organizationMetiers } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const metierFamilie = Object.values(metierFamilies)[0];
  return { metierFamilie: hydrate(metierFamilie as MetierFamilieEntityState, organizationMetiers) };
}

function hydrate(
  metierFamilie: MetierFamilieEntityState,
  organizationMetierEntities?: Dictionary<OrganizationMetierEntityState>
): MetierFamilie | null {
  if (!metierFamilie) {
    return null;
  }

  const metierFamilieHydrated: MetierFamilieEntityState = { ...metierFamilie };

  if (organizationMetierEntities) {
    metierFamilieHydrated.organizationMetiers = ((metierFamilieHydrated.organizationMetiers as number[]) || []).map(
      id => organizationMetierEntities[id]
    ) as OrganizationMetier[];
  } else {
    delete metierFamilieHydrated.organizationMetiers;
  }

  return metierFamilieHydrated as MetierFamilie;
}
