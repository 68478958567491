import { RepositoryService } from '@_services/api/repository.service';
import { BoardState } from '@_model/interfaces/board-state.model';
import { Observable } from 'rxjs';

export class GeneratedBoardStateApiService {
  constructor(protected repo: RepositoryService) {}

  public getBoardStates(params?: any): Observable<BoardState[]> {
    return this.repo.getData<BoardState[]>('board-state', params);
  }

  public getBoardState(params: { idBoardState: number; params?: any }): Observable<BoardState> {
    return this.repo.getData<BoardState>('board-state/' + params.idBoardState, params.params);
  }

  public addBoardState(boardState: Partial<BoardState>): Observable<BoardState> {
    return this.repo.create<BoardState>('board-state', boardState);
  }

  public updateBoardState(boardState: Partial<BoardState>): Observable<BoardState> {
    return this.repo.update('board-state', boardState);
  }

  public deleteBoardState(idBoardState: number): Observable<number> {
    return this.repo.delete('board-state/' + +idBoardState);
  }
}
