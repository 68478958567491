import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidenceWorkActions from './residence-work.actions';
import { adapter, initialState, ResidenceWorkState } from './residence-work.state';
import { Dictionary } from '@ngrx/entity';
import { ResidenceWorkEntityState } from '@_model/interfaces/residence-work.model';

export const residenceWorkReducersGeneratedFunctions: ReducerTypes<ResidenceWorkState, readonly ActionCreator[]>[] = [
  on(ResidenceWorkActions.getOneResidenceWork, (state: ResidenceWorkState) => setLoadingsState(state, true)),
  on(ResidenceWorkActions.getManyResidenceWorks, (state: ResidenceWorkState) => setLoadingsState(state, true)),
  on(ResidenceWorkActions.upsertOneResidenceWork, (state: ResidenceWorkState) => setLoadingsState(state, true)),

  on(ResidenceWorkActions.upsertManyResidenceWorksSuccess, (state: ResidenceWorkState, { residenceWorks }) =>
    adapter.upsertMany(residenceWorks, setLoadingsState(state, false))
  ),
  on(ResidenceWorkActions.deleteOneResidenceWork, (state: ResidenceWorkState) => setLoadingsState(state, true)),
  on(ResidenceWorkActions.deleteOneResidenceWorkSuccess, (state: ResidenceWorkState, { idResidenceWork }) =>
    adapter.removeOne(idResidenceWork, setLoadingsState(state, false))
  ),
  on(ResidenceWorkActions.clearActive, (state: ResidenceWorkState) => ({ ...state, actives: [] })),
  on(ResidenceWorkActions.addManyActives, (state: ResidenceWorkState, { idResidenceWorks }) => ({
    ...state,
    actives: state.actives.concat(idResidenceWorks)
  })),
  on(ResidenceWorkActions.deleteOneActive, (state: ResidenceWorkState, { idResidenceWork }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idResidenceWork)
  })),

  on(ResidenceWorkActions.clearStore, () => initialState),

  on(ResidenceWorkActions.addResidenceSuccess, (state: ResidenceWorkState, { idResidenceWork, idResidence }) => {
    if (!state.entities[idResidenceWork]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idResidenceWork]: {
          ...state.entities[idResidenceWork],
          residence: idResidence
        }
      }
    };
  }),

  on(
    ResidenceWorkActions.deleteManyResidenceSuccess,
    (state: ResidenceWorkState, { idResidences, idResidenceWorks }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceWorks.reduce((entities, idResidenceWork) => {
            if (!state.entities[idResidenceWork]?.residence) {
              return entities;
            }
            entities[idResidenceWork] = {
              ...state.entities[idResidenceWork],
              residence: idResidences.some(
                (idResidence: number) => idResidence === state.entities[idResidenceWork]?.residence
              )
                ? undefined
                : state.entities[idResidenceWork]?.residence
            } as ResidenceWorkEntityState;
            return entities;
          }, {} as Dictionary<ResidenceWorkEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidenceWorkState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidenceWorkState {
  return { ...state, isLoaded, isLoading };
}
