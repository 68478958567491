import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationLeadTodoRuleActions from './organization-lead-todo-rule.actions';
import { adapter, initialState, OrganizationLeadTodoRuleState } from './organization-lead-todo-rule.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationLeadTodoRuleEntityState } from '@_model/interfaces/organization-lead-todo-rule.model';

export const organizationLeadTodoRuleReducersGeneratedFunctions: ReducerTypes<
  OrganizationLeadTodoRuleState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationLeadTodoRuleActions.getOneOrganizationLeadTodoRule, (state: OrganizationLeadTodoRuleState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationLeadTodoRuleActions.getManyOrganizationLeadTodoRules, (state: OrganizationLeadTodoRuleState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationLeadTodoRuleActions.upsertOneOrganizationLeadTodoRule, (state: OrganizationLeadTodoRuleState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationLeadTodoRuleActions.upsertManyOrganizationLeadTodoRulesSuccess,
    (state: OrganizationLeadTodoRuleState, { organizationLeadTodoRules }) =>
      adapter.upsertMany(organizationLeadTodoRules, setLoadingsState(state, false))
  ),
  on(OrganizationLeadTodoRuleActions.deleteOneOrganizationLeadTodoRule, (state: OrganizationLeadTodoRuleState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationLeadTodoRuleActions.deleteOneOrganizationLeadTodoRuleSuccess,
    (state: OrganizationLeadTodoRuleState, { idOrganizationLeadTodoRule }) =>
      adapter.removeOne(idOrganizationLeadTodoRule, setLoadingsState(state, false))
  ),
  on(OrganizationLeadTodoRuleActions.clearActive, (state: OrganizationLeadTodoRuleState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationLeadTodoRuleActions.addManyActives,
    (state: OrganizationLeadTodoRuleState, { idOrganizationLeadTodoRules }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationLeadTodoRules)
    })
  ),
  on(
    OrganizationLeadTodoRuleActions.deleteOneActive,
    (state: OrganizationLeadTodoRuleState, { idOrganizationLeadTodoRule }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationLeadTodoRule)
    })
  ),

  on(OrganizationLeadTodoRuleActions.clearStore, () => initialState),

  on(
    OrganizationLeadTodoRuleActions.addManyOrganizationLeadTodoSuccess,
    (state: OrganizationLeadTodoRuleState, { idOrganizationLeadTodoRule, idOrganizationLeadTodos }) => {
      if (!state.entities[idOrganizationLeadTodoRule]) {
        return state;
      }
      const organizationLeadTodos =
        (state.entities[idOrganizationLeadTodoRule]?.organizationLeadTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodoRule]: {
            ...state.entities[idOrganizationLeadTodoRule],
            organizationLeadTodos: organizationLeadTodos.concat(
              idOrganizationLeadTodos.filter(id => organizationLeadTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoRuleActions.deleteManyOrganizationLeadTodoSuccess,
    (state: OrganizationLeadTodoRuleState, { idOrganizationLeadTodos, idOrganizationLeadTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodoRules.reduce((entities, idOrganizationLeadTodoRule) => {
            if (!state.entities[idOrganizationLeadTodoRule]?.organizationLeadTodos) {
              return entities;
            }
            entities[idOrganizationLeadTodoRule] = {
              ...state.entities[idOrganizationLeadTodoRule],
              organizationLeadTodos: (
                state.entities[idOrganizationLeadTodoRule]?.organizationLeadTodos as number[]
              )?.filter(
                (idOrganizationLeadTodo: number) =>
                  !idOrganizationLeadTodos.some((id: number) => id === idOrganizationLeadTodo)
              )
            } as OrganizationLeadTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoRuleActions.addManyOrganizationLeadTodoAppliedRuleSuccess,
    (state: OrganizationLeadTodoRuleState, { idOrganizationLeadTodoRule, idOrganizationLeadTodoAppliedRules }) => {
      if (!state.entities[idOrganizationLeadTodoRule]) {
        return state;
      }
      const organizationLeadTodoAppliedRules =
        (state.entities[idOrganizationLeadTodoRule]?.organizationLeadTodoAppliedRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodoRule]: {
            ...state.entities[idOrganizationLeadTodoRule],
            organizationLeadTodoAppliedRules: organizationLeadTodoAppliedRules.concat(
              idOrganizationLeadTodoAppliedRules.filter(id => organizationLeadTodoAppliedRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoRuleActions.deleteManyOrganizationLeadTodoAppliedRuleSuccess,
    (state: OrganizationLeadTodoRuleState, { idOrganizationLeadTodoAppliedRules, idOrganizationLeadTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodoRules.reduce((entities, idOrganizationLeadTodoRule) => {
            if (!state.entities[idOrganizationLeadTodoRule]?.organizationLeadTodoAppliedRules) {
              return entities;
            }
            entities[idOrganizationLeadTodoRule] = {
              ...state.entities[idOrganizationLeadTodoRule],
              organizationLeadTodoAppliedRules: (
                state.entities[idOrganizationLeadTodoRule]?.organizationLeadTodoAppliedRules as number[]
              )?.filter(
                (idOrganizationLeadTodoAppliedRule: number) =>
                  !idOrganizationLeadTodoAppliedRules.some((id: number) => id === idOrganizationLeadTodoAppliedRule)
              )
            } as OrganizationLeadTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoRuleActions.addOrganizationSuccess,
    (state: OrganizationLeadTodoRuleState, { idOrganizationLeadTodoRule, idOrganization }) => {
      if (!state.entities[idOrganizationLeadTodoRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodoRule]: {
            ...state.entities[idOrganizationLeadTodoRule],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoRuleActions.deleteManyOrganizationSuccess,
    (state: OrganizationLeadTodoRuleState, { idOrganizations, idOrganizationLeadTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodoRules.reduce((entities, idOrganizationLeadTodoRule) => {
            if (!state.entities[idOrganizationLeadTodoRule]?.organization) {
              return entities;
            }
            entities[idOrganizationLeadTodoRule] = {
              ...state.entities[idOrganizationLeadTodoRule],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationLeadTodoRule]?.organization
              )
                ? undefined
                : state.entities[idOrganizationLeadTodoRule]?.organization
            } as OrganizationLeadTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoRuleActions.addOrganizationSaleCategorySuccess,
    (state: OrganizationLeadTodoRuleState, { idOrganizationLeadTodoRule, idOrganizationSaleCategory }) => {
      if (!state.entities[idOrganizationLeadTodoRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodoRule]: {
            ...state.entities[idOrganizationLeadTodoRule],
            organizationSaleCategory: idOrganizationSaleCategory
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoRuleActions.deleteManyOrganizationSaleCategorySuccess,
    (state: OrganizationLeadTodoRuleState, { idOrganizationSaleCategories, idOrganizationLeadTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodoRules.reduce((entities, idOrganizationLeadTodoRule) => {
            if (!state.entities[idOrganizationLeadTodoRule]?.organizationSaleCategory) {
              return entities;
            }
            entities[idOrganizationLeadTodoRule] = {
              ...state.entities[idOrganizationLeadTodoRule],
              organizationSaleCategory: idOrganizationSaleCategories.some(
                (idOrganizationSaleCategory: number) =>
                  idOrganizationSaleCategory === state.entities[idOrganizationLeadTodoRule]?.organizationSaleCategory
              )
                ? undefined
                : state.entities[idOrganizationLeadTodoRule]?.organizationSaleCategory
            } as OrganizationLeadTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoRuleActions.addSaleCategoryFamilySuccess,
    (state: OrganizationLeadTodoRuleState, { idOrganizationLeadTodoRule, idSaleCategoryFamily }) => {
      if (!state.entities[idOrganizationLeadTodoRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodoRule]: {
            ...state.entities[idOrganizationLeadTodoRule],
            saleCategoryFamily: idSaleCategoryFamily
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoRuleActions.deleteManySaleCategoryFamilySuccess,
    (state: OrganizationLeadTodoRuleState, { idFamilySaleCategories, idOrganizationLeadTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodoRules.reduce((entities, idOrganizationLeadTodoRule) => {
            if (!state.entities[idOrganizationLeadTodoRule]?.saleCategoryFamily) {
              return entities;
            }
            entities[idOrganizationLeadTodoRule] = {
              ...state.entities[idOrganizationLeadTodoRule],
              saleCategoryFamily: idFamilySaleCategories.some(
                (idSaleCategoryFamily: number) =>
                  idSaleCategoryFamily === state.entities[idOrganizationLeadTodoRule]?.saleCategoryFamily
              )
                ? undefined
                : state.entities[idOrganizationLeadTodoRule]?.saleCategoryFamily
            } as OrganizationLeadTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoRuleActions.addCompanySuccess,
    (state: OrganizationLeadTodoRuleState, { idOrganizationLeadTodoRule, idCompany }) => {
      if (!state.entities[idOrganizationLeadTodoRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodoRule]: {
            ...state.entities[idOrganizationLeadTodoRule],
            company: idCompany
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoRuleActions.deleteManyCompanySuccess,
    (state: OrganizationLeadTodoRuleState, { idCompanies, idOrganizationLeadTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodoRules.reduce((entities, idOrganizationLeadTodoRule) => {
            if (!state.entities[idOrganizationLeadTodoRule]?.company) {
              return entities;
            }
            entities[idOrganizationLeadTodoRule] = {
              ...state.entities[idOrganizationLeadTodoRule],
              company: idCompanies.some(
                (idCompany: number) => idCompany === state.entities[idOrganizationLeadTodoRule]?.company
              )
                ? undefined
                : state.entities[idOrganizationLeadTodoRule]?.company
            } as OrganizationLeadTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoRuleActions.addCompanyStratalotTypeSuccess,
    (state: OrganizationLeadTodoRuleState, { idOrganizationLeadTodoRule, idCompanyStratalotType }) => {
      if (!state.entities[idOrganizationLeadTodoRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodoRule]: {
            ...state.entities[idOrganizationLeadTodoRule],
            companyStratalotType: idCompanyStratalotType
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoRuleActions.deleteManyCompanyStratalotTypeSuccess,
    (state: OrganizationLeadTodoRuleState, { idCompanyStratalotTypes, idOrganizationLeadTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodoRules.reduce((entities, idOrganizationLeadTodoRule) => {
            if (!state.entities[idOrganizationLeadTodoRule]?.companyStratalotType) {
              return entities;
            }
            entities[idOrganizationLeadTodoRule] = {
              ...state.entities[idOrganizationLeadTodoRule],
              companyStratalotType: idCompanyStratalotTypes.some(
                (idCompanyStratalotType: number) =>
                  idCompanyStratalotType === state.entities[idOrganizationLeadTodoRule]?.companyStratalotType
              )
                ? undefined
                : state.entities[idOrganizationLeadTodoRule]?.companyStratalotType
            } as OrganizationLeadTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoRuleEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoRuleActions.addStratalotTypeSuccess,
    (state: OrganizationLeadTodoRuleState, { idOrganizationLeadTodoRule, idStratalotType }) => {
      if (!state.entities[idOrganizationLeadTodoRule]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationLeadTodoRule]: {
            ...state.entities[idOrganizationLeadTodoRule],
            stratalotType: idStratalotType
          }
        }
      };
    }
  ),

  on(
    OrganizationLeadTodoRuleActions.deleteManyStratalotTypeSuccess,
    (state: OrganizationLeadTodoRuleState, { idStratalotTypes, idOrganizationLeadTodoRules }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationLeadTodoRules.reduce((entities, idOrganizationLeadTodoRule) => {
            if (!state.entities[idOrganizationLeadTodoRule]?.stratalotType) {
              return entities;
            }
            entities[idOrganizationLeadTodoRule] = {
              ...state.entities[idOrganizationLeadTodoRule],
              stratalotType: idStratalotTypes.some(
                (idStratalotType: number) =>
                  idStratalotType === state.entities[idOrganizationLeadTodoRule]?.stratalotType
              )
                ? undefined
                : state.entities[idOrganizationLeadTodoRule]?.stratalotType
            } as OrganizationLeadTodoRuleEntityState;
            return entities;
          }, {} as Dictionary<OrganizationLeadTodoRuleEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationLeadTodoRuleState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationLeadTodoRuleState {
  return { ...state, isLoaded, isLoading };
}
