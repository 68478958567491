import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationProfil, OrganizationProfilEntityState } from '@_model/interfaces/organization-profil.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { CompanyPriceLabel, CompanyPriceLabelEntityState } from '@_model/interfaces/company-price-label.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, organizationProfilFeatureKey, OrganizationProfilState } from './organization-profil.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationProfilRelations: string[] = ['organizations', 'companyPriceLabels'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationProfilState =
  createFeatureSelector<OrganizationProfilState>(organizationProfilFeatureKey);

export const selectIsLoadedOrganizationProfil = createSelector(
  selectOrganizationProfilState,
  (state: OrganizationProfilState) => state.isLoaded
);

export const selectIsLoadingOrganizationProfil = createSelector(
  selectOrganizationProfilState,
  (state: OrganizationProfilState) => state.isLoading
);

export const selectIsReadyOrganizationProfil = createSelector(
  selectOrganizationProfilState,
  (state: OrganizationProfilState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationProfil = createSelector(
  selectOrganizationProfilState,
  (state: OrganizationProfilState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationProfilModel: SelectorModel = {
  name: 'organizationProfils',
  getSelector: selectAllOrganizationProfilsDictionary,
  isReady: selectIsReadyOrganizationProfil
};

export const selectOrganizationProfilsEntities = createSelector(selectOrganizationProfilState, selectEntities);

export const selectOrganizationProfilsArray = createSelector(selectOrganizationProfilState, selectAll);

export const selectIdOrganizationProfilsActive = createSelector(
  selectOrganizationProfilState,
  (state: OrganizationProfilState) => state.actives
);

const organizationProfilsInObject = (organizationProfils: Dictionary<OrganizationProfilEntityState>) => ({
  organizationProfils
});

const selectOrganizationProfilsEntitiesDictionary = createSelector(
  selectOrganizationProfilsEntities,
  organizationProfilsInObject
);

const selectAllOrganizationProfilsObject = createSelector(selectOrganizationProfilsEntities, organizationProfils => {
  return hydrateAll({ organizationProfils });
});

const selectOneOrganizationProfilDictionary = (idOrganizationProfil: number) =>
  createSelector(selectOrganizationProfilsEntities, organizationProfils => {
    return { organizationProfils: { [idOrganizationProfil]: organizationProfils[idOrganizationProfil] } };
  });

const selectOneOrganizationProfilDictionaryWithoutChild = (idOrganizationProfil: number) =>
  createSelector(selectOrganizationProfilsEntities, organizationProfils => {
    return { organizationProfil: organizationProfils[idOrganizationProfil] };
  });

const selectActiveOrganizationProfilsEntities = createSelector(
  selectIdOrganizationProfilsActive,
  selectOrganizationProfilsEntities,
  (actives: number[], organizationProfils: Dictionary<OrganizationProfilEntityState>) =>
    getOrganizationProfilsFromActives(actives, organizationProfils)
);

function getOrganizationProfilsFromActives(
  actives: number[],
  organizationProfils: Dictionary<OrganizationProfilEntityState>
): Dictionary<OrganizationProfilEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationProfils[idActive]) {
      acc[idActive] = organizationProfils[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationProfilEntityState>);
}

const selectAllOrganizationProfilsSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationProfils(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationProfil>(
      schema,
      selectAllOrganizationProfilsSelectors,
      selectOrganizationProfilsEntitiesDictionary,
      getRelationSelectors,
      organizationProfilRelations,
      hydrateAll,
      'organizationProfil'
    );
  } else {
    return selectAllOrganizationProfilsObject;
  }
}

export function selectAllOrganizationProfilsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationProfils'
): Selector {
  return createSelector(selectAllOrganizationProfils(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationProfilEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationProfils.length; i++) {
      res[customKey][result.organizationProfils[i].idOrganizationProfil] = result.organizationProfils[i];
    }
    return res;
  });
}

export function selectOneOrganizationProfil(schema: SelectSchema = {}, idOrganizationProfil: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneOrganizationProfilDictionary(idOrganizationProfil)];
    selectors.push(...getRelationSelectors(schema, organizationProfilRelations, 'organizationProfil'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationProfilDictionaryWithoutChild(idOrganizationProfil);
  }
}

export function selectActiveOrganizationProfils(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationProfilsEntities, organizationProfils => ({ organizationProfils }))
  ];
  selectors.push(...getRelationSelectors(schema, organizationProfilRelations, 'organizationProfil'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationProfils: Dictionary<OrganizationProfilEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  companyPriceLabels?: Dictionary<CompanyPriceLabelEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { organizationProfils: (OrganizationProfil | null)[] } {
  const { organizationProfils, organizations, companyPriceLabels } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationProfils: Object.keys(organizationProfils).map(idOrganizationProfil =>
      hydrate(
        organizationProfils[idOrganizationProfil] as OrganizationProfilEntityState,
        organizations,
        companyPriceLabels
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { organizationProfil: OrganizationProfilEntityState | null } {
  const { organizationProfils, organizations, companyPriceLabels } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationProfil = Object.values(organizationProfils)[0];
  return {
    organizationProfil: hydrate(organizationProfil as OrganizationProfilEntityState, organizations, companyPriceLabels)
  };
}

function hydrate(
  organizationProfil: OrganizationProfilEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  companyPriceLabelEntities?: Dictionary<CompanyPriceLabelEntityState>
): OrganizationProfil | null {
  if (!organizationProfil) {
    return null;
  }

  const organizationProfilHydrated: OrganizationProfilEntityState = { ...organizationProfil };
  if (organizationEntities) {
    organizationProfilHydrated.organization = organizationEntities[
      organizationProfil.organization as number
    ] as Organization;
  } else {
    delete organizationProfilHydrated.organization;
  }
  if (companyPriceLabelEntities) {
    organizationProfilHydrated.companyPriceLabel = companyPriceLabelEntities[
      organizationProfil.companyPriceLabel as number
    ] as CompanyPriceLabel;
  } else {
    delete organizationProfilHydrated.companyPriceLabel;
  }

  return organizationProfilHydrated as OrganizationProfil;
}
