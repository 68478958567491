import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OrganizationLeadTodoAppliedRule,
  OrganizationLeadTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-lead-todo-applied-rule.model';
import { OrganizationLeadTodo, OrganizationLeadTodoEntityState } from '@_model/interfaces/organization-lead-todo.model';
import {
  OrganizationLeadTodoRule,
  OrganizationLeadTodoRuleEntityState
} from '@_model/interfaces/organization-lead-todo-rule.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  organizationLeadTodoAppliedRuleFeatureKey,
  OrganizationLeadTodoAppliedRuleState
} from './organization-lead-todo-applied-rule.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const organizationLeadTodoAppliedRuleRelations: string[] = [
  'organizationLeadTodos',
  'organizationLeadTodoRules'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrganizationLeadTodoAppliedRuleState = createFeatureSelector<OrganizationLeadTodoAppliedRuleState>(
  organizationLeadTodoAppliedRuleFeatureKey
);

export const selectIsLoadedOrganizationLeadTodoAppliedRule = createSelector(
  selectOrganizationLeadTodoAppliedRuleState,
  (state: OrganizationLeadTodoAppliedRuleState) => state.isLoaded
);

export const selectIsLoadingOrganizationLeadTodoAppliedRule = createSelector(
  selectOrganizationLeadTodoAppliedRuleState,
  (state: OrganizationLeadTodoAppliedRuleState) => state.isLoading
);

export const selectIsReadyOrganizationLeadTodoAppliedRule = createSelector(
  selectOrganizationLeadTodoAppliedRuleState,
  (state: OrganizationLeadTodoAppliedRuleState) => !state.isLoading
);

export const selectIsReadyAndLoadedOrganizationLeadTodoAppliedRule = createSelector(
  selectOrganizationLeadTodoAppliedRuleState,
  (state: OrganizationLeadTodoAppliedRuleState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const OrganizationLeadTodoAppliedRuleModel: SelectorModel = {
  name: 'organizationLeadTodoAppliedRules',
  getSelector: selectAllOrganizationLeadTodoAppliedRulesDictionary,
  isReady: selectIsReadyOrganizationLeadTodoAppliedRule
};

export const selectOrganizationLeadTodoAppliedRulesEntities = createSelector(
  selectOrganizationLeadTodoAppliedRuleState,
  selectEntities
);

export const selectOrganizationLeadTodoAppliedRulesArray = createSelector(
  selectOrganizationLeadTodoAppliedRuleState,
  selectAll
);

export const selectIdOrganizationLeadTodoAppliedRulesActive = createSelector(
  selectOrganizationLeadTodoAppliedRuleState,
  (state: OrganizationLeadTodoAppliedRuleState) => state.actives
);

const organizationLeadTodoAppliedRulesInObject = (
  organizationLeadTodoAppliedRules: Dictionary<OrganizationLeadTodoAppliedRuleEntityState>
) => ({ organizationLeadTodoAppliedRules });

const selectOrganizationLeadTodoAppliedRulesEntitiesDictionary = createSelector(
  selectOrganizationLeadTodoAppliedRulesEntities,
  organizationLeadTodoAppliedRulesInObject
);

const selectAllOrganizationLeadTodoAppliedRulesObject = createSelector(
  selectOrganizationLeadTodoAppliedRulesEntities,
  organizationLeadTodoAppliedRules => {
    return hydrateAll({ organizationLeadTodoAppliedRules });
  }
);

const selectOneOrganizationLeadTodoAppliedRuleDictionary = (idOrganizationLeadTodoAppliedRule: number) =>
  createSelector(selectOrganizationLeadTodoAppliedRulesEntities, organizationLeadTodoAppliedRules => {
    return {
      organizationLeadTodoAppliedRules: {
        [idOrganizationLeadTodoAppliedRule]: organizationLeadTodoAppliedRules[idOrganizationLeadTodoAppliedRule]
      }
    };
  });

const selectOneOrganizationLeadTodoAppliedRuleDictionaryWithoutChild = (idOrganizationLeadTodoAppliedRule: number) =>
  createSelector(selectOrganizationLeadTodoAppliedRulesEntities, organizationLeadTodoAppliedRules => {
    return { organizationLeadTodoAppliedRule: organizationLeadTodoAppliedRules[idOrganizationLeadTodoAppliedRule] };
  });

const selectActiveOrganizationLeadTodoAppliedRulesEntities = createSelector(
  selectIdOrganizationLeadTodoAppliedRulesActive,
  selectOrganizationLeadTodoAppliedRulesEntities,
  (actives: number[], organizationLeadTodoAppliedRules: Dictionary<OrganizationLeadTodoAppliedRuleEntityState>) =>
    getOrganizationLeadTodoAppliedRulesFromActives(actives, organizationLeadTodoAppliedRules)
);

function getOrganizationLeadTodoAppliedRulesFromActives(
  actives: number[],
  organizationLeadTodoAppliedRules: Dictionary<OrganizationLeadTodoAppliedRuleEntityState>
): Dictionary<OrganizationLeadTodoAppliedRuleEntityState> {
  return actives.reduce((acc, idActive) => {
    if (organizationLeadTodoAppliedRules[idActive]) {
      acc[idActive] = organizationLeadTodoAppliedRules[idActive];
    }
    return acc;
  }, {} as Dictionary<OrganizationLeadTodoAppliedRuleEntityState>);
}

const selectAllOrganizationLeadTodoAppliedRulesSelectors: Dictionary<Selector> = {};
export function selectAllOrganizationLeadTodoAppliedRules(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<OrganizationLeadTodoAppliedRule>(
      schema,
      selectAllOrganizationLeadTodoAppliedRulesSelectors,
      selectOrganizationLeadTodoAppliedRulesEntitiesDictionary,
      getRelationSelectors,
      organizationLeadTodoAppliedRuleRelations,
      hydrateAll,
      'organizationLeadTodoAppliedRule'
    );
  } else {
    return selectAllOrganizationLeadTodoAppliedRulesObject;
  }
}

export function selectAllOrganizationLeadTodoAppliedRulesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'organizationLeadTodoAppliedRules'
): Selector {
  return createSelector(selectAllOrganizationLeadTodoAppliedRules(schema), result => {
    const res = { [customKey]: {} as Dictionary<OrganizationLeadTodoAppliedRuleEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.organizationLeadTodoAppliedRules.length; i++) {
      res[customKey][result.organizationLeadTodoAppliedRules[i].idOrganizationLeadTodoAppliedRule] =
        result.organizationLeadTodoAppliedRules[i];
    }
    return res;
  });
}

export function selectOneOrganizationLeadTodoAppliedRule(
  schema: SelectSchema = {},
  idOrganizationLeadTodoAppliedRule: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [
      selectOneOrganizationLeadTodoAppliedRuleDictionary(idOrganizationLeadTodoAppliedRule)
    ];
    selectors.push(
      ...getRelationSelectors(schema, organizationLeadTodoAppliedRuleRelations, 'organizationLeadTodoAppliedRule')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneOrganizationLeadTodoAppliedRuleDictionaryWithoutChild(idOrganizationLeadTodoAppliedRule);
  }
}

export function selectActiveOrganizationLeadTodoAppliedRules(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveOrganizationLeadTodoAppliedRulesEntities, organizationLeadTodoAppliedRules => ({
      organizationLeadTodoAppliedRules
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, organizationLeadTodoAppliedRuleRelations, 'organizationLeadTodoAppliedRule')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  organizationLeadTodoAppliedRules: Dictionary<OrganizationLeadTodoAppliedRuleEntityState>;
  organizationLeadTodos?: Dictionary<OrganizationLeadTodoEntityState>;
  organizationLeadTodoRules?: Dictionary<OrganizationLeadTodoRuleEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  organizationLeadTodoAppliedRules: (OrganizationLeadTodoAppliedRule | null)[];
} {
  const { organizationLeadTodoAppliedRules, organizationLeadTodos, organizationLeadTodoRules } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    organizationLeadTodoAppliedRules: Object.keys(organizationLeadTodoAppliedRules).map(
      idOrganizationLeadTodoAppliedRule =>
        hydrate(
          organizationLeadTodoAppliedRules[
            idOrganizationLeadTodoAppliedRule
          ] as OrganizationLeadTodoAppliedRuleEntityState,
          organizationLeadTodos,
          organizationLeadTodoRules
        )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  organizationLeadTodoAppliedRule: OrganizationLeadTodoAppliedRuleEntityState | null;
} {
  const { organizationLeadTodoAppliedRules, organizationLeadTodos, organizationLeadTodoRules } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const organizationLeadTodoAppliedRule = Object.values(organizationLeadTodoAppliedRules)[0];
  return {
    organizationLeadTodoAppliedRule: hydrate(
      organizationLeadTodoAppliedRule as OrganizationLeadTodoAppliedRuleEntityState,
      organizationLeadTodos,
      organizationLeadTodoRules
    )
  };
}

function hydrate(
  organizationLeadTodoAppliedRule: OrganizationLeadTodoAppliedRuleEntityState,
  organizationLeadTodoEntities?: Dictionary<OrganizationLeadTodoEntityState>,
  organizationLeadTodoRuleEntities?: Dictionary<OrganizationLeadTodoRuleEntityState>
): OrganizationLeadTodoAppliedRule | null {
  if (!organizationLeadTodoAppliedRule) {
    return null;
  }

  const organizationLeadTodoAppliedRuleHydrated: OrganizationLeadTodoAppliedRuleEntityState = {
    ...organizationLeadTodoAppliedRule
  };
  if (organizationLeadTodoEntities) {
    organizationLeadTodoAppliedRuleHydrated.organizationLeadTodo = organizationLeadTodoEntities[
      organizationLeadTodoAppliedRule.organizationLeadTodo as number
    ] as OrganizationLeadTodo;
  } else {
    delete organizationLeadTodoAppliedRuleHydrated.organizationLeadTodo;
  }
  if (organizationLeadTodoRuleEntities) {
    organizationLeadTodoAppliedRuleHydrated.organizationLeadTodoRule = organizationLeadTodoRuleEntities[
      organizationLeadTodoAppliedRule.organizationLeadTodoRule as number
    ] as OrganizationLeadTodoRule;
  } else {
    delete organizationLeadTodoAppliedRuleHydrated.organizationLeadTodoRule;
  }

  return organizationLeadTodoAppliedRuleHydrated as OrganizationLeadTodoAppliedRule;
}
