import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  CompanyStudyNextAction,
  CompanyStudyNextActionEntityState
} from '@_model/interfaces/company-study-next-action.model';
import { CompanyStudyNextActionRelationsIds } from './company-study-next-action-generated.effects';

export const getOneCompanyStudyNextAction = createAction(
  '[CompanyStudyNextAction] Get One CompanyStudyNextAction',
  props<{ idCompanyStudyNextAction: number; params?: any }>()
);

export const getManyCompanyStudyNextActions = createAction(
  '[CompanyStudyNextAction] Get Many CompanyStudyNextActions',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyStudyNextAction] Add Many Actives CompanyStudyNextAction',
  props<{ idCompanyStudyNextActions: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyStudyNextAction] Delete One Active CompanyStudyNextAction',
  props<{ idCompanyStudyNextAction: number }>()
);

export const clearActive = createAction('[CompanyStudyNextAction] Clear Active CompanyStudyNextAction');

export const upsertOneCompanyStudyNextAction = createAction(
  '[CompanyStudyNextAction] Upsert One CompanyStudyNextAction',
  props<{
    companyStudyNextAction: Partial<CompanyStudyNextAction>;
    ids?: CompanyStudyNextActionRelationsIds;
  }>()
);

export const upsertManyCompanyStudyNextActions = createAction(
  '[CompanyStudyNextAction] Upsert Many CompanyStudyNextActions',
  props<{
    companyStudyNextActions: Partial<CompanyStudyNextAction>[];
    ids?: CompanyStudyNextActionRelationsIds;
  }>()
);

export const upsertManyCompanyStudyNextActionsSuccess = createAction(
  '[CompanyStudyNextAction] Create Many CompanyStudyNextActions Success',
  props<{ companyStudyNextActions: CompanyStudyNextActionEntityState[] }>()
);

export const deleteOneCompanyStudyNextAction = createAction(
  '[CompanyStudyNextAction] Delete One CompanyStudyNextAction',
  props<{ idCompanyStudyNextAction: number }>()
);

export const deleteOneCompanyStudyNextActionSuccess = createAction(
  '[CompanyStudyNextAction] Delete One CompanyStudyNextAction Success',
  props<{ idCompanyStudyNextAction: number }>()
);

export const normalizeManyCompanyStudyNextActionsAfterUpsert = createAction(
  '[CompanyStudyNextAction] Normalize Many CompanyStudyNextActions After Upsert',
  props<{ companyStudyNextActions: CompanyStudyNextActionEntityState[] }>()
);

export const companyStudyNextActionsFailure = createAction(
  '[CompanyStudyNextAction] CompanyStudyNextActions Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyStudyNextAction] Clear CompanyStudyNextActions');

export const addManyResidenceStudySuccess = createAction(
  '[CompanyStudyNextAction] Add Many residence-study',
  props<{ idCompanyStudyNextAction: number; idResidenceStudies: number[] }>()
);

export const deleteManyResidenceStudySuccess = createAction(
  '[CompanyStudyNextAction] Delete Many ResidenceStudies',
  props<{ idResidenceStudies: number[]; idCompanyStudyNextActions: number[] }>()
);

export const addCompanySuccess = createAction(
  '[CompanyStudyNextAction] Add Company',
  props<{ idCompanyStudyNextAction: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[CompanyStudyNextAction] Delete Many Company',
  props<{ idCompanies: number[]; idCompanyStudyNextActions: number[] }>()
);
