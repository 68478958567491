import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidenceTodoActions from './residence-todo.actions';
import { adapter, initialState, ResidenceTodoState } from './residence-todo.state';
import { Dictionary } from '@ngrx/entity';
import { ResidenceTodoEntityState } from '@_model/interfaces/residence-todo.model';

export const residenceTodoReducersGeneratedFunctions: ReducerTypes<ResidenceTodoState, readonly ActionCreator[]>[] = [
  on(ResidenceTodoActions.getOneResidenceTodo, (state: ResidenceTodoState) => setLoadingsState(state, true)),
  on(ResidenceTodoActions.getManyResidenceTodos, (state: ResidenceTodoState) => setLoadingsState(state, true)),
  on(ResidenceTodoActions.upsertOneResidenceTodo, (state: ResidenceTodoState) => setLoadingsState(state, true)),

  on(ResidenceTodoActions.upsertManyResidenceTodosSuccess, (state: ResidenceTodoState, { residenceTodos }) =>
    adapter.upsertMany(residenceTodos, setLoadingsState(state, false))
  ),
  on(ResidenceTodoActions.deleteOneResidenceTodo, (state: ResidenceTodoState) => setLoadingsState(state, true)),
  on(ResidenceTodoActions.deleteOneResidenceTodoSuccess, (state: ResidenceTodoState, { idResidenceTodo }) =>
    adapter.removeOne(idResidenceTodo, setLoadingsState(state, false))
  ),
  on(ResidenceTodoActions.clearActive, (state: ResidenceTodoState) => ({ ...state, actives: [] })),
  on(ResidenceTodoActions.addManyActives, (state: ResidenceTodoState, { idResidenceTodos }) => ({
    ...state,
    actives: state.actives.concat(idResidenceTodos)
  })),
  on(ResidenceTodoActions.deleteOneActive, (state: ResidenceTodoState, { idResidenceTodo }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idResidenceTodo)
  })),

  on(ResidenceTodoActions.clearStore, () => initialState),

  on(
    ResidenceTodoActions.addOrganizationResidenceTodoSuccess,
    (state: ResidenceTodoState, { idResidenceTodo, idOrganizationResidenceTodo }) => {
      if (!state.entities[idResidenceTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceTodo]: {
            ...state.entities[idResidenceTodo],
            organizationResidenceTodo: idOrganizationResidenceTodo
          }
        }
      };
    }
  ),

  on(
    ResidenceTodoActions.deleteManyOrganizationResidenceTodoSuccess,
    (state: ResidenceTodoState, { idOrganizationResidenceTodos, idResidenceTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceTodos.reduce((entities, idResidenceTodo) => {
            if (!state.entities[idResidenceTodo]?.organizationResidenceTodo) {
              return entities;
            }
            entities[idResidenceTodo] = {
              ...state.entities[idResidenceTodo],
              organizationResidenceTodo: idOrganizationResidenceTodos.some(
                (idOrganizationResidenceTodo: number) =>
                  idOrganizationResidenceTodo === state.entities[idResidenceTodo]?.organizationResidenceTodo
              )
                ? undefined
                : state.entities[idResidenceTodo]?.organizationResidenceTodo
            } as ResidenceTodoEntityState;
            return entities;
          }, {} as Dictionary<ResidenceTodoEntityState>)
        }
      };
    }
  ),

  on(ResidenceTodoActions.addResidenceSuccess, (state: ResidenceTodoState, { idResidenceTodo, idResidence }) => {
    if (!state.entities[idResidenceTodo]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idResidenceTodo]: {
          ...state.entities[idResidenceTodo],
          residence: idResidence
        }
      }
    };
  }),

  on(
    ResidenceTodoActions.deleteManyResidenceSuccess,
    (state: ResidenceTodoState, { idResidences, idResidenceTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceTodos.reduce((entities, idResidenceTodo) => {
            if (!state.entities[idResidenceTodo]?.residence) {
              return entities;
            }
            entities[idResidenceTodo] = {
              ...state.entities[idResidenceTodo],
              residence: idResidences.some(
                (idResidence: number) => idResidence === state.entities[idResidenceTodo]?.residence
              )
                ? undefined
                : state.entities[idResidenceTodo]?.residence
            } as ResidenceTodoEntityState;
            return entities;
          }, {} as Dictionary<ResidenceTodoEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidenceTodoState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidenceTodoState {
  return { ...state, isLoaded, isLoading };
}
