import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class UpdateStoreGuard implements CanDeactivate<any> {
  public canDeactivate(): boolean {
    setTimeout(() => window.location.reload(), 500);
    return true;
  }
}
