import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as StratalotTodoActions from './stratalot-todo.actions';
import { adapter, initialState, StratalotTodoState } from './stratalot-todo.state';
import { Dictionary } from '@ngrx/entity';
import { StratalotTodoEntityState } from '@_model/interfaces/stratalot-todo.model';

export const stratalotTodoReducersGeneratedFunctions: ReducerTypes<StratalotTodoState, readonly ActionCreator[]>[] = [
  on(StratalotTodoActions.getOneStratalotTodo, (state: StratalotTodoState) => setLoadingsState(state, true)),
  on(StratalotTodoActions.getManyStratalotTodos, (state: StratalotTodoState) => setLoadingsState(state, true)),
  on(StratalotTodoActions.upsertOneStratalotTodo, (state: StratalotTodoState) => setLoadingsState(state, true)),

  on(StratalotTodoActions.upsertManyStratalotTodosSuccess, (state: StratalotTodoState, { stratalotTodos }) =>
    adapter.upsertMany(stratalotTodos, setLoadingsState(state, false))
  ),
  on(StratalotTodoActions.deleteOneStratalotTodo, (state: StratalotTodoState) => setLoadingsState(state, true)),
  on(StratalotTodoActions.deleteOneStratalotTodoSuccess, (state: StratalotTodoState, { idStratalotTodo }) =>
    adapter.removeOne(idStratalotTodo, setLoadingsState(state, false))
  ),
  on(StratalotTodoActions.clearActive, (state: StratalotTodoState) => ({ ...state, actives: [] })),
  on(StratalotTodoActions.addManyActives, (state: StratalotTodoState, { idStratalotTodos }) => ({
    ...state,
    actives: state.actives.concat(idStratalotTodos)
  })),
  on(StratalotTodoActions.deleteOneActive, (state: StratalotTodoState, { idStratalotTodo }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idStratalotTodo)
  })),

  on(StratalotTodoActions.clearStore, () => initialState),

  on(
    StratalotTodoActions.addOrganizationStratalotTodoSuccess,
    (state: StratalotTodoState, { idStratalotTodo, idOrganizationStratalotTodo }) => {
      if (!state.entities[idStratalotTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStratalotTodo]: {
            ...state.entities[idStratalotTodo],
            organizationStratalotTodo: idOrganizationStratalotTodo
          }
        }
      };
    }
  ),

  on(
    StratalotTodoActions.deleteManyOrganizationStratalotTodoSuccess,
    (state: StratalotTodoState, { idOrganizationStratalotTodos, idStratalotTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotTodos.reduce((entities, idStratalotTodo) => {
            if (!state.entities[idStratalotTodo]?.organizationStratalotTodo) {
              return entities;
            }
            entities[idStratalotTodo] = {
              ...state.entities[idStratalotTodo],
              organizationStratalotTodo: idOrganizationStratalotTodos.some(
                (idOrganizationStratalotTodo: number) =>
                  idOrganizationStratalotTodo === state.entities[idStratalotTodo]?.organizationStratalotTodo
              )
                ? undefined
                : state.entities[idStratalotTodo]?.organizationStratalotTodo
            } as StratalotTodoEntityState;
            return entities;
          }, {} as Dictionary<StratalotTodoEntityState>)
        }
      };
    }
  ),

  on(StratalotTodoActions.addStratalotSuccess, (state: StratalotTodoState, { idStratalotTodo, idStratalot }) => {
    if (!state.entities[idStratalotTodo]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStratalotTodo]: {
          ...state.entities[idStratalotTodo],
          stratalot: idStratalot
        }
      }
    };
  }),

  on(
    StratalotTodoActions.deleteManyStratalotSuccess,
    (state: StratalotTodoState, { idStratalots, idStratalotTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStratalotTodos.reduce((entities, idStratalotTodo) => {
            if (!state.entities[idStratalotTodo]?.stratalot) {
              return entities;
            }
            entities[idStratalotTodo] = {
              ...state.entities[idStratalotTodo],
              stratalot: idStratalots.some(
                (idStratalot: number) => idStratalot === state.entities[idStratalotTodo]?.stratalot
              )
                ? undefined
                : state.entities[idStratalotTodo]?.stratalot
            } as StratalotTodoEntityState;
            return entities;
          }, {} as Dictionary<StratalotTodoEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: StratalotTodoState,
  isLoading: boolean,
  isLoaded: boolean = true
): StratalotTodoState {
  return { ...state, isLoaded, isLoading };
}
