import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { User } from '@_model/interfaces/user.model';
import * as AppState from '@_store/index.reducers';
import { UserRelationsIds } from '@_store/user/user-generated.effects';
import * as UserAction from '@_store/user/user.actions';
import {
  selectActiveUsers,
  selectAllUsers,
  selectIdUsersActive,
  selectIsLoadedUser,
  selectIsLoadingUser,
  selectIsReadyAndLoadedUser,
  selectOneUser
} from '@_store/user/user.selectors';
import { catchApiActions } from '@_utils/http.util';
import { getIsReadySelectors, Selector, SelectSchema } from '@_utils/selector.util';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedUserService {
  constructor(protected store$: Store<AppState.AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadedUser));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadingUser));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [selectIsReadyAndLoadedUser as Selector].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllUsers(schema: SelectSchema = {}): Observable<User[]> {
    return this.store$.pipe(select(selectAllUsers(schema))).pipe(
      switchMap(({ users }: { users: User[] }) => {
        return this.getReady(schema).pipe(mapTo(users));
      })
    );
  }

  public selectOneUser(idUser: number, schema: SelectSchema = {}): Observable<User> {
    return this.store$.pipe(select(selectOneUser(schema, idUser))).pipe(
      switchMap(({ user }: { user: User }) => {
        return this.getReady(schema).pipe(mapTo(user));
      })
    );
  }

  public selectAllActiveUsers(schema: SelectSchema = {}): Observable<User[]> {
    return this.store$.pipe(select(selectActiveUsers(schema))).pipe(
      switchMap(({ users }: { users: User[] }) => {
        return this.getReady(schema).pipe(mapTo(users));
      })
    );
  }

  public selectIdUsersActive(): Observable<number[]> {
    return this.store$.pipe(select(selectIdUsersActive)).pipe(
      switchMap((idUsers: number[]) => {
        return this.getReady().pipe(mapTo(idUsers));
      })
    );
  }

  public getOneUser(idUser: number, params: any = {}, getResult?: boolean): void | Observable<User> {
    this.store$.dispatch(UserAction.getOneUser({ idUser, params }));
    if (getResult) {
      return catchApiActions(this.actions$, UserAction.normalizeManyUsersAfterUpsert, UserAction.usersFailure, true);
    }
  }

  public getManyUsers(params: any = {}, getResult?: boolean): void | Observable<User[]> {
    this.store$.dispatch(UserAction.getManyUsers({ params }));
    if (getResult) {
      return catchApiActions(this.actions$, UserAction.normalizeManyUsersAfterUpsert, UserAction.usersFailure);
    }
  }

  public upsertOneUser(user: Partial<User>, ids: UserRelationsIds = {}, getResult?: boolean): void | Observable<User> {
    this.store$.dispatch(UserAction.upsertOneUser({ user, ids }));
    if (getResult) {
      return catchApiActions(this.actions$, UserAction.normalizeManyUsersAfterUpsert, UserAction.usersFailure, true);
    }
  }

  public deleteOneUser(idUser: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(UserAction.deleteOneUser({ idUser }));
    if (getResult) {
      return catchApiActions(this.actions$, UserAction.deleteOneUserSuccess, UserAction.usersFailure);
    }
  }

  public setActiveUsers(idUsers: number[]): void {
    this.store$.dispatch(UserAction.clearActive());
    this.store$.dispatch(UserAction.addManyActives({ idUsers }));
  }
}
