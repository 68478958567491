import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ForecastPeriod } from '@_model/interfaces/forecast-period.model';
import * as AppState from '@_store/index.reducers';
import { ForecastPeriodRelationsIds } from '@_store/forecast-period/forecast-period-generated.effects';
import * as ForecastPeriodAction from '@_store/forecast-period/forecast-period.actions';
import {
  selectActiveForecastPeriods,
  selectAllForecastPeriods,
  selectIdForecastPeriodsActive,
  selectIsLoadedForecastPeriod,
  selectIsLoadingForecastPeriod,
  selectIsReadyAndLoadedForecastPeriod,
  selectOneForecastPeriod
} from '@_store/forecast-period/forecast-period.selectors';
import { catchApiActions } from '@_utils/http.util';
import { getIsReadySelectors, Selector, SelectSchema } from '@_utils/selector.util';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedForecastPeriodService {
  constructor(protected store$: Store<AppState.AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadedForecastPeriod));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadingForecastPeriod));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [selectIsReadyAndLoadedForecastPeriod as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllForecastPeriods(schema: SelectSchema = {}): Observable<ForecastPeriod[]> {
    return this.store$.pipe(select(selectAllForecastPeriods(schema))).pipe(
      switchMap(({ forecastPeriods }: { forecastPeriods: ForecastPeriod[] }) => {
        return this.getReady(schema).pipe(mapTo(forecastPeriods));
      })
    );
  }

  public selectOneForecastPeriod(idForecastPeriod: number, schema: SelectSchema = {}): Observable<ForecastPeriod> {
    return this.store$.pipe(select(selectOneForecastPeriod(schema, idForecastPeriod))).pipe(
      switchMap(({ forecastPeriod }: { forecastPeriod: ForecastPeriod }) => {
        return this.getReady(schema).pipe(mapTo(forecastPeriod));
      })
    );
  }

  public selectAllActiveForecastPeriods(schema: SelectSchema = {}): Observable<ForecastPeriod[]> {
    return this.store$.pipe(select(selectActiveForecastPeriods(schema))).pipe(
      switchMap(({ forecastPeriods }: { forecastPeriods: ForecastPeriod[] }) => {
        return this.getReady(schema).pipe(mapTo(forecastPeriods));
      })
    );
  }

  public selectIdForecastPeriodsActive(): Observable<number[]> {
    return this.store$.pipe(select(selectIdForecastPeriodsActive)).pipe(
      switchMap((idForecastPeriods: number[]) => {
        return this.getReady().pipe(mapTo(idForecastPeriods));
      })
    );
  }

  public getOneForecastPeriod(
    idForecastPeriod: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<ForecastPeriod> {
    this.store$.dispatch(ForecastPeriodAction.getOneForecastPeriod({ idForecastPeriod, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ForecastPeriodAction.normalizeManyForecastPeriodsAfterUpsert,
        ForecastPeriodAction.forecastPeriodsFailure,
        true
      );
    }
  }

  public getManyForecastPeriods(params: any = {}, getResult?: boolean): void | Observable<ForecastPeriod[]> {
    this.store$.dispatch(ForecastPeriodAction.getManyForecastPeriods({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ForecastPeriodAction.normalizeManyForecastPeriodsAfterUpsert,
        ForecastPeriodAction.forecastPeriodsFailure
      );
    }
  }

  public upsertOneForecastPeriod(
    forecastPeriod: Partial<ForecastPeriod>,
    ids: ForecastPeriodRelationsIds = {},
    getResult?: boolean
  ): void | Observable<ForecastPeriod> {
    this.store$.dispatch(ForecastPeriodAction.upsertOneForecastPeriod({ forecastPeriod, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ForecastPeriodAction.normalizeManyForecastPeriodsAfterUpsert,
        ForecastPeriodAction.forecastPeriodsFailure,
        true
      );
    }
  }

  public deleteOneForecastPeriod(idForecastPeriod: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(ForecastPeriodAction.deleteOneForecastPeriod({ idForecastPeriod }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ForecastPeriodAction.deleteOneForecastPeriodSuccess,
        ForecastPeriodAction.forecastPeriodsFailure
      );
    }
  }

  public setActiveForecastPeriods(idForecastPeriods: number[]): void {
    this.store$.dispatch(ForecastPeriodAction.clearActive());
    this.store$.dispatch(ForecastPeriodAction.addManyActives({ idForecastPeriods }));
  }
}
