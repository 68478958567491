import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  CompanyCommunicationRecipient,
  CompanyCommunicationRecipientEntityState
} from '@_model/interfaces/company-communication-recipient.model';
import { CompanyCommunicationRecipientRelationsIds } from './company-communication-recipient-generated.effects';

export const getOneCompanyCommunicationRecipient = createAction(
  '[CompanyCommunicationRecipient] Get One CompanyCommunicationRecipient',
  props<{ idCompanyCommunicationRecipient: number; params?: any }>()
);

export const getManyCompanyCommunicationRecipients = createAction(
  '[CompanyCommunicationRecipient] Get Many CompanyCommunicationRecipients',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyCommunicationRecipient] Add Many Actives CompanyCommunicationRecipient',
  props<{ idCompanyCommunicationRecipients: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyCommunicationRecipient] Delete One Active CompanyCommunicationRecipient',
  props<{ idCompanyCommunicationRecipient: number }>()
);

export const clearActive = createAction('[CompanyCommunicationRecipient] Clear Active CompanyCommunicationRecipient');

export const upsertOneCompanyCommunicationRecipient = createAction(
  '[CompanyCommunicationRecipient] Upsert One CompanyCommunicationRecipient',
  props<{
    companyCommunicationRecipient: Partial<CompanyCommunicationRecipient>;
    ids?: CompanyCommunicationRecipientRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationRecipients = createAction(
  '[CompanyCommunicationRecipient] Upsert Many CompanyCommunicationRecipients',
  props<{
    companyCommunicationRecipients: Partial<CompanyCommunicationRecipient>[];
    ids?: CompanyCommunicationRecipientRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationRecipientsSuccess = createAction(
  '[CompanyCommunicationRecipient] Create Many CompanyCommunicationRecipients Success',
  props<{ companyCommunicationRecipients: CompanyCommunicationRecipientEntityState[] }>()
);

export const deleteOneCompanyCommunicationRecipient = createAction(
  '[CompanyCommunicationRecipient] Delete One CompanyCommunicationRecipient',
  props<{ idCompanyCommunicationRecipient: number }>()
);

export const deleteOneCompanyCommunicationRecipientSuccess = createAction(
  '[CompanyCommunicationRecipient] Delete One CompanyCommunicationRecipient Success',
  props<{ idCompanyCommunicationRecipient: number }>()
);

export const normalizeManyCompanyCommunicationRecipientsAfterUpsert = createAction(
  '[CompanyCommunicationRecipient] Normalize Many CompanyCommunicationRecipients After Upsert',
  props<{ companyCommunicationRecipients: CompanyCommunicationRecipientEntityState[] }>()
);

export const companyCommunicationRecipientsFailure = createAction(
  '[CompanyCommunicationRecipient] CompanyCommunicationRecipients Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyCommunicationRecipient] Clear CompanyCommunicationRecipients');

export const addCompanyCommunicationSuccess = createAction(
  '[CompanyCommunicationRecipient] Add CompanyCommunication',
  props<{ idCompanyCommunicationRecipient: number; idCompanyCommunication: number }>()
);

export const deleteCompanyCommunicationSuccess = createAction(
  '[CompanyCommunicationRecipient] Delete CompanyCommunications',
  props<{ idCompanyCommunications: number[]; idCompanyCommunicationRecipients: number[] }>()
);

export const addManyUserSuccess = createAction(
  '[CompanyCommunicationRecipient] Add Many user',
  props<{ idCompanyCommunicationRecipient: number; idUsers: number[] }>()
);

export const deleteManyUserSuccess = createAction(
  '[CompanyCommunicationRecipient] Delete Many Users',
  props<{ idUsers: number[]; idCompanyCommunicationRecipients: number[] }>()
);

export const addManyCompanyCommunicationRecipientUserSuccess = createAction(
  '[CompanyCommunicationRecipient] Add Many company-communication-recipient-user',
  props<{ idCompanyCommunicationRecipient: number; idCompanyCommunicationRecipientUsers: number[] }>()
);

export const deleteManyCompanyCommunicationRecipientUserSuccess = createAction(
  '[CompanyCommunicationRecipient] Delete Many CompanyCommunicationRecipientUsers',
  props<{ idCompanyCommunicationRecipientUsers: number[]; idCompanyCommunicationRecipients: number[] }>()
);

export const addManyOrganizationMetierSuccess = createAction(
  '[CompanyCommunicationRecipient] Add Many organization-metier',
  props<{ idCompanyCommunicationRecipient: number; idOrganizationMetiers: number[] }>()
);

export const deleteManyOrganizationMetierSuccess = createAction(
  '[CompanyCommunicationRecipient] Delete Many OrganizationMetiers',
  props<{ idOrganizationMetiers: number[]; idCompanyCommunicationRecipients: number[] }>()
);

export const addManyCompanyCommunicationRecipientMetierSuccess = createAction(
  '[CompanyCommunicationRecipient] Add Many company-communication-recipient-metier',
  props<{ idCompanyCommunicationRecipient: number; idCompanyCommunicationRecipientMetiers: number[] }>()
);

export const deleteManyCompanyCommunicationRecipientMetierSuccess = createAction(
  '[CompanyCommunicationRecipient] Delete Many CompanyCommunicationRecipientMetiers',
  props<{ idCompanyCommunicationRecipientMetiers: number[]; idCompanyCommunicationRecipients: number[] }>()
);
