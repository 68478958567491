import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationThirdParty,
  OrganizationThirdPartyEntityState
} from '@_model/interfaces/organization-third-party.model';
import { OrganizationThirdPartyRelationsIds } from './organization-third-party-generated.effects';

export const getOneOrganizationThirdParty = createAction(
  '[OrganizationThirdParty] Get One OrganizationThirdParty',
  props<{ idOrganizationThirdParty: number; params?: any }>()
);

export const getManyOrganizationThirdParties = createAction(
  '[OrganizationThirdParty] Get Many OrganizationThirdParties',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationThirdParty] Add Many Actives OrganizationThirdParty',
  props<{ idOrganizationThirdParties: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationThirdParty] Delete One Active OrganizationThirdParty',
  props<{ idOrganizationThirdParty: number }>()
);

export const clearActive = createAction('[OrganizationThirdParty] Clear Active OrganizationThirdParty');

export const upsertOneOrganizationThirdParty = createAction(
  '[OrganizationThirdParty] Upsert One OrganizationThirdParty',
  props<{
    organizationThirdParty: Partial<OrganizationThirdParty>;
    ids?: OrganizationThirdPartyRelationsIds;
  }>()
);

export const upsertManyOrganizationThirdParties = createAction(
  '[OrganizationThirdParty] Upsert Many OrganizationThirdParties',
  props<{
    organizationThirdParties: Partial<OrganizationThirdParty>[];
    ids?: OrganizationThirdPartyRelationsIds;
  }>()
);

export const upsertManyOrganizationThirdPartiesSuccess = createAction(
  '[OrganizationThirdParty] Create Many OrganizationThirdParties Success',
  props<{ organizationThirdParties: OrganizationThirdPartyEntityState[] }>()
);

export const deleteOneOrganizationThirdParty = createAction(
  '[OrganizationThirdParty] Delete One OrganizationThirdParty',
  props<{ idOrganizationThirdParty: number }>()
);

export const deleteOneOrganizationThirdPartySuccess = createAction(
  '[OrganizationThirdParty] Delete One OrganizationThirdParty Success',
  props<{ idOrganizationThirdParty: number }>()
);

export const normalizeManyOrganizationThirdPartiesAfterUpsert = createAction(
  '[OrganizationThirdParty] Normalize Many OrganizationThirdParties After Upsert',
  props<{ organizationThirdParties: OrganizationThirdPartyEntityState[] }>()
);

export const organizationThirdPartiesFailure = createAction(
  '[OrganizationThirdParty] OrganizationThirdParties Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationThirdParty] Clear OrganizationThirdParties');

export const addManyResidenceContactSuccess = createAction(
  '[OrganizationThirdParty] Add Many residence-contact',
  props<{ idOrganizationThirdParty: number; idResidenceContacts: number[] }>()
);

export const deleteManyResidenceContactSuccess = createAction(
  '[OrganizationThirdParty] Delete Many ResidenceContacts',
  props<{ idResidenceContacts: number[]; idOrganizationThirdParties: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[OrganizationThirdParty] Add Organization',
  props<{ idOrganizationThirdParty: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationThirdParty] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationThirdParties: number[] }>()
);

export const addOrganizationSocieteSuccess = createAction(
  '[OrganizationThirdParty] Add OrganizationSociete',
  props<{ idOrganizationThirdParty: number; idOrganizationSociete: number }>()
);

export const deleteManyOrganizationSocieteSuccess = createAction(
  '[OrganizationThirdParty] Delete Many OrganizationSociete',
  props<{ idOrganizationSocietes: number[]; idOrganizationThirdParties: number[] }>()
);

export const addOrganizationMetierSuccess = createAction(
  '[OrganizationThirdParty] Add OrganizationMetier',
  props<{ idOrganizationThirdParty: number; idOrganizationMetier: number }>()
);

export const deleteManyOrganizationMetierSuccess = createAction(
  '[OrganizationThirdParty] Delete Many OrganizationMetier',
  props<{ idOrganizationMetiers: number[]; idOrganizationThirdParties: number[] }>()
);
