import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidenceStudyTodoEntityState } from '@_model/interfaces/residence-study-todo.model';

export interface ResidenceStudyTodoState extends EntityState<ResidenceStudyTodoEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidenceStudyTodoEntityState> = createEntityAdapter<ResidenceStudyTodoEntityState>(
  {
    selectId: o => o.idResidenceStudyTodo
  }
);
export const initialState: ResidenceStudyTodoState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residenceStudyTodoFeatureKey = 'residenceStudyTodo';
