import { buyingProbabilityDefault } from '@_config/iconsHelper.config';
import { CategorieCSPEnum } from '@_shared/enum/categorie-CSP.enum';
import { NatureRevenuEnum } from '@_shared/enum/natureRevenu.enum';
import { CreateProspectDefaults } from '@_shared/interfaces/prospect-modal.interface';
import { BuyingProbability } from '@_shared/models/enums/buying-probability.enum';
import { BuyingWish } from '@_shared/models/enums/buying-wish.enum';
import { LeadAvancementEnum } from '@_shared/models/enums/lead-statut-avancement.enum';
import { Profil, ProfilProspectOccupant } from '@_shared/models/enums/profil-enum';
import { SaleCategoryFamilyName } from '@_shared/models/enums/sale-category-family.enum';
import { StepName } from '@_shared/models/enums/step-name.enum';
import { Lead } from '@_shared/models/interfaces/lead.model';
import { ProspectBuyingWish } from '@_shared/models/interfaces/prospect-buying-wish.model';
import { ProspectEvent } from '@_shared/models/interfaces/prospect-event.model';
import { Prospect } from '@_shared/models/interfaces/prospect.model';
import DatesUtils from '@_utils/common-utils/dates.utils';
import OrganizationParamsUtils from './organization-params.utils';

namespace ProspectUtils {
  export const revenus = Object.values(NatureRevenuEnum);
  export const categoryCSP = Object.values(CategorieCSPEnum).concat(null);

  export const prospectProfilsWithBailleurInfo: Profil[] = [
    Profil.locataireBailleurPrive,
    Profil.locataireBailleurSocial,
    Profil.gardienBailleurSocial
  ];

  export function getProspectsNamesDisplay(prospect: Partial<Prospect>, lead?: Partial<Lead>): string {
    let retour = '-';
    if (prospect) {
      retour = '';
      retour += prospect.personne1Civilite ? ' ' + prospect.personne1Civilite : '';
      retour += prospect.personne1Prenom ? ' ' + prospect.personne1Prenom : '';
      retour += prospect.personne1Nom ? ' ' + prospect.personne1Nom.toUpperCase() : '';
      if (prospect.personne2Civilite || prospect.personne2Prenom || prospect.personne2Nom) {
        retour += ' &';
        retour += prospect.personne2Civilite ? ' ' + prospect.personne2Civilite : '';
        retour += prospect.personne2Prenom ? ' ' + prospect.personne2Prenom : '';
        retour += prospect.personne2Nom ? ' ' + prospect.personne2Nom.toUpperCase() : '';
      }

      if (lead?.profilProspectOccupant) {
        switch (lead.profilProspectOccupant) {
          case ProfilProspectOccupant.occupant:
            retour += ' (occ)';
            break;
          case ProfilProspectOccupant.ascendantOccupant:
            retour += ' (asc)';
            break;
          case ProfilProspectOccupant.descendantOccupant:
            retour += ' (desc)';
            break;
          case ProfilProspectOccupant.conjointOccupant:
            retour += ' (conj)';
            break;
        }
      }
    }

    return retour;
  }

  export function getlastProspectBuyingWish(
    prospect: Prospect,
    occupedStratalot = false,
    defaultValues: CreateProspectDefaults = {}
  ): ProspectBuyingWish {
    let found = null;
    const saleCategory: SaleCategoryFamilyName[] = defaultValues?.saleCategoryFamilyName;
    const defaultBuyingWish: Partial<ProspectBuyingWish> = {
      idProspectBuyingWish: null,
      idProspect: prospect?.idProspect,
      idOrganizationBuyingWish: OrganizationParamsUtils.getDefaultOrganizationBuyingWishByBuyingWishAndTarget(
        defaultValues?.buyinwish || BuyingWish.toQualify,
        occupedStratalot
      )?.idOrganizationBuyingWish,
      idStratalotTypes: defaultValues?.typelot?.idStratalotType ? [defaultValues.typelot.idStratalotType] : [],
      stratalotTypes: defaultValues?.typelot?.stratalotType ? [defaultValues.typelot.stratalotType] : [],
      prospect: null,
      probabiliteVente: defaultValues?.probability || (buyingProbabilityDefault.value as BuyingProbability),
      organizationBuyingWish: OrganizationParamsUtils.getDefaultOrganizationBuyingWishByBuyingWishAndTarget(
        defaultValues?.buyinwish || BuyingWish.toQualify,
        occupedStratalot
      ),
      lotOccupe: occupedStratalot,
      typoMin: defaultValues?.pieceMin || null,
      motif: '',
      ancien: saleCategory?.includes(SaleCategoryFamilyName.ancien),
      neuf: saleCategory?.includes(SaleCategoryFamilyName.psla) || saleCategory?.includes(SaleCategoryFamilyName.vefa),
      user: null,
      cities: defaultValues?.idsCommune || [],
      residences:
        saleCategory?.includes(SaleCategoryFamilyName.psla) || saleCategory?.includes(SaleCategoryFamilyName.vefa)
          ? defaultValues?.residence || []
          : [],
      prixMax: null,
      surfMin: null
    };
    if (prospect.prospectBuyingWishs?.length) {
      found = prospect.prospectBuyingWishs
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        .find((buyingWish: ProspectBuyingWish) => !!buyingWish.lotOccupe === occupedStratalot);
    }
    return found ? found : defaultBuyingWish;
  }

  export function getLastEvent(prospectEvents: ProspectEvent[]): ProspectEvent {
    const todayTime: number = new Date().getTime();
    let lastEvent: ProspectEvent = null;

    if (prospectEvents) {
      prospectEvents.forEach(prospectEvent => {
        // Selection du plus récent événement passé
        if (
          new Date(prospectEvent.dateEvenement).getTime() <= todayTime &&
          (!lastEvent || new Date(prospectEvent.dateEvenement).getTime() > new Date(lastEvent.dateEvenement).getTime())
        ) {
          lastEvent = prospectEvent;
        }
        return;
      });
    }
    return lastEvent;
  }

  export function getNextEvent(prospectEvents: ProspectEvent[]): ProspectEvent {
    const todayTime: number = new Date().getTime();
    let nextEvent: ProspectEvent = null;

    if (prospectEvents) {
      prospectEvents.forEach(prospectEvent => {
        if (
          new Date(prospectEvent.dateProchainEvenement).getTime() >= todayTime &&
          (!nextEvent ||
            new Date(prospectEvent.dateEvenement).getTime() < new Date(nextEvent.dateProchainEvenement).getTime())
        ) {
          nextEvent = prospectEvent;
        }
        return;
      });
    }
    return nextEvent;
  }

  export function checkBuyingWishRefus(buyingWish: BuyingWish): boolean {
    return buyingWish === BuyingWish.refusal;
  }

  export function checkBuyingWishInterestOrReflextion(buyingWish: BuyingWish): boolean {
    return buyingWish === BuyingWish.interestedIn || buyingWish === BuyingWish.reflexion;
  }

  export function calculateAgeFromYearOfBrid(yearOfBrid: number): number {
    const currentYear = DatesUtils.currentYear();
    if (!isNaN(yearOfBrid) && yearOfBrid >= 1900) {
      return currentYear - yearOfBrid;
    }
    return null;
  }

  export function checkProspectCommercialisation(prospect: Prospect, isArchive: boolean = false): boolean {
    isArchive;
    return (
      ((!prospect.occupant && !prospect.idOccupant) || isInterestedByOtherStratalot(prospect)) &&
      isArchiveIsActiveBool(prospect, isArchive) &&
      isNotInVentesEnCours(prospect)
    );
  }

  function isInterestedByOtherStratalot(prospect: Prospect): boolean {
    return (
      prospect.prospectBuyingWishs
        ?.filter(buyingWish => buyingWish.lotOccupe === false)
        .sort(
          (prospectBuyingWishA, prospectBuyingWishB) =>
            new Date(prospectBuyingWishA.createdAt).getTime() - new Date(prospectBuyingWishB.createdAt).getTime()
        )[0]?.organizationBuyingWish.type === BuyingWish.interestedIn
    );
  }

  function isArchiveIsActiveBool(prospect: Prospect, isArchive: boolean): boolean {
    let retArchive = false;
    if (isArchive) {
      retArchive = prospect?.leads?.some(l => l?.statutAvancement === LeadAvancementEnum.venteArchivee);
    }
    return retArchive || prospect.leads.every(l => l?.statutAvancement !== LeadAvancementEnum.venteArchivee);
  }

  function isNotInVentesEnCours(prospect: Prospect): boolean {
    return prospect?.leads?.every(l =>
      l?.stratalots?.every(s => s?.stepProgress?.step?.libelle !== StepName.ventesEnCours)
    );
  }
}
export default ProspectUtils;
