import { Injectable } from '@angular/core';
import { RepositoryService } from '@_services/api/repository.service';
import { SaisieConge } from '@_shared/dumb/free-stratalot-modal/SaisieConge';
import { StratalotAnnonce } from '@_shared/interfaces/annonce.interface';
import { GetManyStratalotsByStepProgressesDTO } from '@_shared/interfaces/get-many-stratalots-by-step-progresses.dto';
import { Stratalot } from '@_shared/models/interfaces/stratalot.model';
import { Observable } from 'rxjs';
import { GeneratedStratalotApiService } from './stratalot-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class StratalotApiService extends GeneratedStratalotApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public getFreeStratalots(params: Partial<Stratalot> & { idsCompanies: number[] }): Observable<Stratalot[]> {
    return this.repo.getData<Stratalot[]>('stratalot/free', params);
  }

  public getFreeStratalotsOfProgram(params: Partial<Stratalot> & { idsOSTypes: number[] }): Observable<Stratalot[]> {
    return this.repo.getData<Stratalot[]>('stratalot/freeProgram', params);
  }

  public getManyUbiflowMonitoringStratalots(
    params: Partial<Stratalot> & { idsCompanies: number[]; idsOSTypes: number[] }
  ): Observable<Stratalot[]> {
    return this.repo.getData<Stratalot[]>('stratalot/ubiflowMonitoring', params);
  }

  public getOccupedStratalots(params: Partial<Stratalot> & { idsResidence: number[] }): Observable<Stratalot[]> {
    return this.repo.getData<Stratalot[]>('stratalot/occupedByResidence', params);
  }

  public getAllOccupedStratalots(params: Partial<Stratalot> & { idsCompanies: number[] }): Observable<Stratalot[]> {
    return this.repo.getData<Stratalot[]>('stratalot/occupedByCompany', params);
  }

  public getStratalotsFromResidence(params: Partial<Stratalot> & { idResidence: number }): Observable<Stratalot[]> {
    return this.repo.getData<Stratalot[]>('stratalot/from-residence', params);
  }

  public getLeadsForValidateOfferBoard(params?: any): Observable<Stratalot[]> {
    return this.repo.getData<Stratalot[]>('stratalot/forValidateOfferBoard', { params });
  }

  public getDefaultPrice(params?: any): Observable<Stratalot[]> {
    return this.repo.getData<Stratalot[]>('stratalot/prix-acquereur', { params });
  }

  public updateStratalot(stratalot: Partial<Stratalot>): Observable<Stratalot> {
    const newStratalot: Partial<Stratalot> = { ...stratalot };
    return this.repo.update('stratalot', newStratalot);
  }

  public activateStratalotFromCampaign({
    idCompany,
    envoiUbiflowDateDebut,
    envoiUbiflowDateFin,
    idStratalot
  }): Observable<Stratalot> {
    return this.repo.update('stratalot/' + idStratalot + '/activateUbiflow', {
      idCompany,
      envoiUbiflowDateDebut,
      envoiUbiflowDateFin
    });
  }

  updateStratalotAd(params: { idStratalot: number; stratalotAnnonce: StratalotAnnonce }): Observable<Stratalot> {
    return this.repo.update('stratalot/ad/' + params.idStratalot, {
      stratalotAnnonce: params.stratalotAnnonce
    });
  }

  public updateVacance(params: { idStratalot: number; saisieconge: SaisieConge }): Observable<Stratalot> {
    return this.repo.update('stratalot/vacance/' + params.idStratalot, { saisieconge: params.saisieconge });
  }

  public getStratalotsByStepProgresses(patch: GetManyStratalotsByStepProgressesDTO): Observable<Stratalot[]> {
    return this.repo.getData<Stratalot[]>('stratalot/by-step-progresses', patch);
  }

  public duplicateDiffusionVisualsFromResidence(params: {
    idResidence: number;
    idStratalot: number;
  }): Observable<Stratalot> {
    return this.repo.update(`stratalot/${params.idStratalot}/duplicateDiffusionVisualsFromResidence`, {
      idResidence: params.idResidence
    });
  }

  public calculateActualStepProgressAndAllLeadStatus(): Observable<any> {
    return this.repo.getData('stratalot/stratalotData');
  }

  public getStratalotsByOrganization(param: { idOrganization: number }): Observable<Stratalot[]> {
    return this.repo.getData('stratalot/stratalotsByOrganization/' + param.idOrganization);
  }
}
