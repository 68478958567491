import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StratalotEntityState } from '@_model/interfaces/stratalot.model';

export interface StratalotState extends EntityState<StratalotEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<StratalotEntityState> = createEntityAdapter<StratalotEntityState>({
  selectId: o => o.idStratalot
});
export const initialState: StratalotState = adapter.getInitialState({ isLoaded: false, isLoading: false, actives: [] });

export const stratalotFeatureKey = 'stratalot';
