import { RepositoryService } from '@_services/api/repository.service';
import { ForecastPeriod } from '@_model/interfaces/forecast-period.model';
import { Observable } from 'rxjs';

export class GeneratedForecastPeriodApiService {
  constructor(protected repo: RepositoryService) {}

  public getForecastPeriods(params?: any): Observable<ForecastPeriod[]> {
    return this.repo.getData<ForecastPeriod[]>('forecast-period', params);
  }

  public getForecastPeriod(params: { idForecastPeriod: number; params?: any }): Observable<ForecastPeriod> {
    return this.repo.getData<ForecastPeriod>('forecast-period/' + params.idForecastPeriod, params.params);
  }

  public addForecastPeriod(forecastPeriod: Partial<ForecastPeriod>): Observable<ForecastPeriod> {
    return this.repo.create<ForecastPeriod>('forecast-period', forecastPeriod);
  }

  public updateForecastPeriod(forecastPeriod: Partial<ForecastPeriod>): Observable<ForecastPeriod> {
    return this.repo.update('forecast-period', forecastPeriod);
  }

  public deleteForecastPeriod(idForecastPeriod: number): Observable<number> {
    return this.repo.delete('forecast-period/' + +idForecastPeriod);
  }
}
