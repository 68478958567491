import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { StratalotApiService } from '@_services/api/stratalot-api.service';
import * as stratalotActions from '@_store/stratalot/stratalot.actions';
import { getActionsToNormalizeStratalot } from '@_config/store/normalization.generated';
import { selectStratalotState } from './stratalot-generated.selectors';
import * as residenceActions from '@_store/residence/residence.actions';
import * as occupantActions from '@_store/occupant/occupant.actions';
import * as companyStratalotTypeActions from '@_store/company-stratalot-type/company-stratalot-type.actions';
import * as companyStratalotVacantActions from '@_store/company-stratalot-vacant/company-stratalot-vacant.actions';
import * as organizationSaleCategoryActions from '@_store/organization-sale-category/organization-sale-category.actions';
import * as stepProgressActions from '@_store/step-progress/step-progress.actions';
import * as userActions from '@_store/user/user.actions';
import * as organizationStratalotAvancementActions from '@_store/organization-stratalot-avancement/organization-stratalot-avancement.actions';
import * as stratalotCampaignActions from '@_store/stratalot-campaign/stratalot-campaign.actions';
import { StratalotCampaign } from '@_model/interfaces/stratalot-campaign.model';
import * as diffusionVisualActions from '@_store/diffusion-visual/diffusion-visual.actions';
import { DiffusionVisual } from '@_model/interfaces/diffusion-visual.model';
import * as stratalotAssociationActions from '@_store/stratalot-association/stratalot-association.actions';
import { StratalotAssociation } from '@_model/interfaces/stratalot-association.model';
import * as stratalotFeeActions from '@_store/stratalot-fee/stratalot-fee.actions';
import { StratalotFee } from '@_model/interfaces/stratalot-fee.model';
import * as associationActions from '@_store/association/association.actions';
import { Association } from '@_model/interfaces/association.model';
import * as leadActions from '@_store/lead/lead.actions';
import { Lead } from '@_model/interfaces/lead.model';
import * as leadStratalotActions from '@_store/lead-stratalot/lead-stratalot.actions';
import { LeadStratalot } from '@_model/interfaces/lead-stratalot.model';
import * as stratalotPriceActions from '@_store/stratalot-price/stratalot-price.actions';
import { StratalotPrice } from '@_model/interfaces/stratalot-price.model';
import * as stratalotRcpActions from '@_store/stratalot-rcp/stratalot-rcp.actions';
import { StratalotRcp } from '@_model/interfaces/stratalot-rcp.model';
import * as stratalotTodoActions from '@_store/stratalot-todo/stratalot-todo.actions';
import { StratalotTodo } from '@_model/interfaces/stratalot-todo.model';

export interface StratalotRelationsIds {
  stratalotCampaigns?: number | number[];
  diffusionVisuals?: number | number[];
  stratalotAssociations?: number | number[];
  stratalotFees?: number | number[];
  associations?: number | number[];
  leads?: number | number[];
  leadStratalots?: number | number[];
  stratalotPrices?: number | number[];
  stratalotRcp?: number;
  stratalotTodos?: number | number[];
  residence?: number;
  occupant?: number;
  companyStratalotType?: number;
  companyStratalotVacant?: number;
  organizationSaleCategory?: number;
  stepProgress?: number;
  responsable?: number;
  organizationStratalotAvancement?: number;
}

export function getDefaultAddStratalotActions(stratalot: StratalotEntityState, ids?: StratalotRelationsIds): Action[] {
  const actions: Action[] = [stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots: [stratalot] })];

  if (ids?.residence) {
    actions.push(
      residenceActions.addManyStratalotSuccess({
        idResidence: ids.residence,
        idStratalots: [stratalot.idStratalot]
      })
    );
    actions.push(
      stratalotActions.addResidenceSuccess({
        idStratalot: stratalot.idStratalot,
        idResidence: ids.residence
      })
    );
  }

  if (ids?.occupant) {
    actions.push(
      occupantActions.addManyStratalotSuccess({
        idOccupant: ids.occupant,
        idStratalots: [stratalot.idStratalot]
      })
    );
    actions.push(
      stratalotActions.addOccupantSuccess({
        idStratalot: stratalot.idStratalot,
        idOccupant: ids.occupant
      })
    );
  }

  if (ids?.companyStratalotType) {
    actions.push(
      companyStratalotTypeActions.addManyStratalotSuccess({
        idCompanyStratalotType: ids.companyStratalotType,
        idStratalots: [stratalot.idStratalot]
      })
    );
    actions.push(
      stratalotActions.addCompanyStratalotTypeSuccess({
        idStratalot: stratalot.idStratalot,
        idCompanyStratalotType: ids.companyStratalotType
      })
    );
  }

  if (ids?.companyStratalotVacant) {
    actions.push(
      companyStratalotVacantActions.addManyStratalotSuccess({
        idCompanyStratalotVacant: ids.companyStratalotVacant,
        idStratalots: [stratalot.idStratalot]
      })
    );
    actions.push(
      stratalotActions.addCompanyStratalotVacantSuccess({
        idStratalot: stratalot.idStratalot,
        idCompanyStratalotVacant: ids.companyStratalotVacant
      })
    );
  }

  if (ids?.organizationSaleCategory) {
    actions.push(
      organizationSaleCategoryActions.addManyStratalotSuccess({
        idOrganizationSaleCategory: ids.organizationSaleCategory,
        idStratalots: [stratalot.idStratalot]
      })
    );
    actions.push(
      stratalotActions.addOrganizationSaleCategorySuccess({
        idStratalot: stratalot.idStratalot,
        idOrganizationSaleCategory: ids.organizationSaleCategory
      })
    );
  }

  if (ids?.stepProgress) {
    actions.push(
      stepProgressActions.addManyStratalotSuccess({
        idStepProgress: ids.stepProgress,
        idStratalots: [stratalot.idStratalot]
      })
    );
    actions.push(
      stratalotActions.addStepProgressSuccess({
        idStratalot: stratalot.idStratalot,
        idStepProgress: ids.stepProgress
      })
    );
  }

  if (ids?.responsable) {
    actions.push(
      userActions.addManyStratalotSuccess({
        idUser: ids.responsable,
        idStratalot: [stratalot.idStratalot]
      })
    );
    actions.push(
      stratalotActions.addResponsableSuccess({
        idStratalot: stratalot.idStratalot,
        idResponsable: ids.responsable
      })
    );
  }

  if (ids?.organizationStratalotAvancement) {
    actions.push(
      organizationStratalotAvancementActions.addManyStratalotSuccess({
        idOrganizationStratalotAvancement: ids.organizationStratalotAvancement,
        idStratalots: [stratalot.idStratalot]
      })
    );
    actions.push(
      stratalotActions.addOrganizationStratalotAvancementSuccess({
        idStratalot: stratalot.idStratalot,
        idOrganizationStratalotAvancement: ids.organizationStratalotAvancement
      })
    );
  }

  if (ids?.stratalotCampaigns) {
    if (!Array.isArray(ids.stratalotCampaigns)) {
      actions.push(
        stratalotCampaignActions.upsertOneStratalotCampaign({
          stratalotCampaign: {
            idStratalot: stratalot.idStratalot,
            idStratalotCampaign: ids.stratalotCampaigns as number
          } as StratalotCampaign
        })
      );
      actions.push(
        stratalotActions.addManyStratalotCampaignSuccess({
          idStratalot: stratalot.idStratalot,
          idStratalotCampaigns: [ids.stratalotCampaigns as number]
        })
      );
    } else {
      actions.push(
        stratalotCampaignActions.upsertManyStratalotCampaigns({
          stratalotCampaigns: (ids.stratalotCampaigns as number[]).map((idStratalotCampaign: number) => ({
            idStratalot: stratalot.idStratalot,
            idStratalotCampaign
          })) as StratalotCampaign[]
        })
      );
      actions.push(
        stratalotActions.addManyStratalotCampaignSuccess({
          idStratalot: stratalot.idStratalot,
          idStratalotCampaigns: ids.stratalotCampaigns as number[]
        })
      );
    }
  }

  if (ids?.diffusionVisuals) {
    if (!Array.isArray(ids.diffusionVisuals)) {
      actions.push(
        diffusionVisualActions.upsertOneDiffusionVisual({
          diffusionVisual: {
            idStratalot: stratalot.idStratalot,
            idDiffusionVisual: ids.diffusionVisuals as number
          } as DiffusionVisual
        })
      );
      actions.push(
        stratalotActions.addManyDiffusionVisualSuccess({
          idStratalot: stratalot.idStratalot,
          idDiffusionVisuals: [ids.diffusionVisuals as number]
        })
      );
    } else {
      actions.push(
        diffusionVisualActions.upsertManyDiffusionVisuals({
          diffusionVisuals: (ids.diffusionVisuals as number[]).map((idDiffusionVisual: number) => ({
            idStratalot: stratalot.idStratalot,
            idDiffusionVisual
          })) as DiffusionVisual[]
        })
      );
      actions.push(
        stratalotActions.addManyDiffusionVisualSuccess({
          idStratalot: stratalot.idStratalot,
          idDiffusionVisuals: ids.diffusionVisuals as number[]
        })
      );
    }
  }

  if (ids?.stratalotAssociations) {
    if (!Array.isArray(ids.stratalotAssociations)) {
      actions.push(
        stratalotAssociationActions.upsertOneStratalotAssociation({
          stratalotAssociation: {
            idStratalot: stratalot.idStratalot,
            idStratalotAssociation: ids.stratalotAssociations as number
          } as StratalotAssociation
        })
      );
      actions.push(
        stratalotActions.addManyStratalotAssociationSuccess({
          idStratalot: stratalot.idStratalot,
          idStratalotAssociations: [ids.stratalotAssociations as number]
        })
      );
    } else {
      actions.push(
        stratalotAssociationActions.upsertManyStratalotAssociations({
          stratalotAssociations: (ids.stratalotAssociations as number[]).map((idStratalotAssociation: number) => ({
            idStratalot: stratalot.idStratalot,
            idStratalotAssociation
          })) as StratalotAssociation[]
        })
      );
      actions.push(
        stratalotActions.addManyStratalotAssociationSuccess({
          idStratalot: stratalot.idStratalot,
          idStratalotAssociations: ids.stratalotAssociations as number[]
        })
      );
    }
  }

  if (ids?.stratalotFees) {
    if (!Array.isArray(ids.stratalotFees)) {
      actions.push(
        stratalotFeeActions.upsertOneStratalotFee({
          stratalotFee: {
            idStratalot: stratalot.idStratalot,
            idStratalotFee: ids.stratalotFees as number
          } as StratalotFee
        })
      );
      actions.push(
        stratalotActions.addManyStratalotFeeSuccess({
          idStratalot: stratalot.idStratalot,
          idStratalotFees: [ids.stratalotFees as number]
        })
      );
    } else {
      actions.push(
        stratalotFeeActions.upsertManyStratalotFees({
          stratalotFees: (ids.stratalotFees as number[]).map((idStratalotFee: number) => ({
            idStratalot: stratalot.idStratalot,
            idStratalotFee
          })) as StratalotFee[]
        })
      );
      actions.push(
        stratalotActions.addManyStratalotFeeSuccess({
          idStratalot: stratalot.idStratalot,
          idStratalotFees: ids.stratalotFees as number[]
        })
      );
    }
  }

  if (ids?.associations) {
    if (!Array.isArray(ids.associations)) {
      actions.push(
        associationActions.upsertOneAssociation({
          association: {
            idStratalot: stratalot.idStratalot,
            idAssociation: ids.associations as number
          } as Association & any
        })
      );
      actions.push(
        stratalotActions.addManyAssociationSuccess({
          idStratalot: stratalot.idStratalot,
          idAssociations: [ids.associations as number]
        })
      );
    } else {
      actions.push(
        associationActions.upsertManyAssociations({
          associations: (ids.associations as number[]).map((idAssociation: number) => ({
            idStratalot: stratalot.idStratalot,
            idAssociation
          })) as Association[] & any[]
        })
      );
      actions.push(
        stratalotActions.addManyAssociationSuccess({
          idStratalot: stratalot.idStratalot,
          idAssociations: ids.associations as number[]
        })
      );
    }
  }

  if (ids?.leads) {
    if (!Array.isArray(ids.leads)) {
      actions.push(
        leadActions.upsertOneLead({
          lead: {
            idStratalot: stratalot.idStratalot,
            idLead: ids.leads as number
          } as Lead & any
        })
      );
      actions.push(
        stratalotActions.addManyLeadSuccess({
          idStratalot: stratalot.idStratalot,
          idLeads: [ids.leads as number]
        })
      );
    } else {
      actions.push(
        leadActions.upsertManyLeads({
          leads: (ids.leads as number[]).map((idLead: number) => ({
            idStratalot: stratalot.idStratalot,
            idLead
          })) as Lead[] & any[]
        })
      );
      actions.push(
        stratalotActions.addManyLeadSuccess({
          idStratalot: stratalot.idStratalot,
          idLeads: ids.leads as number[]
        })
      );
    }
  }

  if (ids?.leadStratalots) {
    if (!Array.isArray(ids.leadStratalots)) {
      actions.push(
        leadStratalotActions.upsertOneLeadStratalot({
          leadStratalot: {
            idStratalot: stratalot.idStratalot,
            idLeadStratalot: ids.leadStratalots as number
          } as LeadStratalot
        })
      );
      actions.push(
        stratalotActions.addManyLeadStratalotSuccess({
          idStratalot: stratalot.idStratalot,
          idLeadStratalots: [ids.leadStratalots as number]
        })
      );
    } else {
      actions.push(
        leadStratalotActions.upsertManyLeadStratalots({
          leadStratalots: (ids.leadStratalots as number[]).map((idLeadStratalot: number) => ({
            idStratalot: stratalot.idStratalot,
            idLeadStratalot
          })) as LeadStratalot[]
        })
      );
      actions.push(
        stratalotActions.addManyLeadStratalotSuccess({
          idStratalot: stratalot.idStratalot,
          idLeadStratalots: ids.leadStratalots as number[]
        })
      );
    }
  }

  if (ids?.stratalotPrices) {
    if (!Array.isArray(ids.stratalotPrices)) {
      actions.push(
        stratalotPriceActions.upsertOneStratalotPrice({
          stratalotPrice: {
            idStratalot: stratalot.idStratalot,
            idStratalotPrice: ids.stratalotPrices as number
          } as StratalotPrice
        })
      );
      actions.push(
        stratalotActions.addManyStratalotPriceSuccess({
          idStratalot: stratalot.idStratalot,
          idStratalotPrices: [ids.stratalotPrices as number]
        })
      );
    } else {
      actions.push(
        stratalotPriceActions.upsertManyStratalotPrices({
          stratalotPrices: (ids.stratalotPrices as number[]).map((idStratalotPrice: number) => ({
            idStratalot: stratalot.idStratalot,
            idStratalotPrice
          })) as StratalotPrice[]
        })
      );
      actions.push(
        stratalotActions.addManyStratalotPriceSuccess({
          idStratalot: stratalot.idStratalot,
          idStratalotPrices: ids.stratalotPrices as number[]
        })
      );
    }
  }

  if (ids?.stratalotRcp) {
    actions.push(
      stratalotRcpActions.upsertOneStratalotRcp({
        stratalotRcp: {
          idStratalot: stratalot.idStratalot,
          idStratalotRcp: ids.stratalotRcp as number
        } as StratalotRcp
      })
    );
    actions.push(
      stratalotActions.addStratalotRcpSuccess({
        idStratalot: stratalot.idStratalot,
        idStratalotRcp: ids.stratalotRcp
      })
    );
  }

  if (ids?.stratalotTodos) {
    if (!Array.isArray(ids.stratalotTodos)) {
      actions.push(
        stratalotTodoActions.upsertOneStratalotTodo({
          stratalotTodo: {
            idStratalot: stratalot.idStratalot,
            idStratalotTodo: ids.stratalotTodos as number
          } as StratalotTodo
        })
      );
      actions.push(
        stratalotActions.addManyStratalotTodoSuccess({
          idStratalot: stratalot.idStratalot,
          idStratalotTodos: [ids.stratalotTodos as number]
        })
      );
    } else {
      actions.push(
        stratalotTodoActions.upsertManyStratalotTodos({
          stratalotTodos: (ids.stratalotTodos as number[]).map((idStratalotTodo: number) => ({
            idStratalot: stratalot.idStratalot,
            idStratalotTodo
          })) as StratalotTodo[]
        })
      );
      actions.push(
        stratalotActions.addManyStratalotTodoSuccess({
          idStratalot: stratalot.idStratalot,
          idStratalotTodos: ids.stratalotTodos as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteStratalotActions(stratalot: StratalotEntityState): Action[] {
  const actions: Action[] = [stratalotActions.deleteOneStratalotSuccess({ idStratalot: stratalot.idStratalot })];

  if (stratalot.residence) {
    actions.push(
      residenceActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idResidences: [stratalot.residence as number]
      })
    );
  }

  if (stratalot.occupant) {
    actions.push(
      occupantActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idOccupants: [stratalot.occupant as number]
      })
    );
  }

  if (stratalot.companyStratalotType) {
    actions.push(
      companyStratalotTypeActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idCompanyStratalotTypes: [stratalot.companyStratalotType as number]
      })
    );
  }

  if (stratalot.companyStratalotVacant) {
    actions.push(
      companyStratalotVacantActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idCompanyStratalotVacants: [stratalot.companyStratalotVacant as number]
      })
    );
  }

  if (stratalot.organizationSaleCategory) {
    actions.push(
      organizationSaleCategoryActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idOrganizationSaleCategories: [stratalot.organizationSaleCategory as number]
      })
    );
  }

  if (stratalot.stepProgress) {
    actions.push(
      stepProgressActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idStepProgresses: [stratalot.stepProgress as number]
      })
    );
  }

  if (stratalot.responsable) {
    actions.push(
      userActions.deleteManyStratalotSuccess({
        idStratalot: [stratalot.idStratalot],
        idUsers: [stratalot.responsable as number]
      })
    );
  }

  if (stratalot.organizationStratalotAvancement) {
    actions.push(
      organizationStratalotAvancementActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idOrganizationStratalotAvancements: [stratalot.organizationStratalotAvancement as number]
      })
    );
  }

  if (stratalot.stratalotCampaigns) {
    actions.push(
      stratalotCampaignActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idStratalotCampaigns: stratalot.stratalotCampaigns as number[]
      })
    );
  }

  if (stratalot.diffusionVisuals) {
    actions.push(
      diffusionVisualActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idDiffusionVisuals: stratalot.diffusionVisuals as number[]
      })
    );
  }

  if (stratalot.stratalotAssociations) {
    actions.push(
      stratalotAssociationActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idStratalotAssociations: stratalot.stratalotAssociations as number[]
      })
    );
  }

  if (stratalot.stratalotFees) {
    actions.push(
      stratalotFeeActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idStratalotFees: stratalot.stratalotFees as number[]
      })
    );
  }

  if (stratalot.associations) {
    actions.push(
      associationActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idAssociations: stratalot.associations as number[]
      })
    );
  }

  if (stratalot.leads) {
    actions.push(
      leadActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idLeads: stratalot.leads as number[]
      })
    );
  }

  if (stratalot.leadStratalots) {
    actions.push(
      leadStratalotActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idLeadStratalots: stratalot.leadStratalots as number[]
      })
    );
  }

  if (stratalot.stratalotPrices) {
    actions.push(
      stratalotPriceActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idStratalotPrices: stratalot.stratalotPrices as number[]
      })
    );
  }

  if (stratalot.stratalotRcp) {
    actions.push(
      stratalotRcpActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idStratalotRcps: [stratalot.stratalotRcp] as number[]
      })
    );
  }

  if (stratalot.stratalotTodos) {
    actions.push(
      stratalotTodoActions.deleteManyStratalotSuccess({
        idStratalots: [stratalot.idStratalot],
        idStratalotTodos: stratalot.stratalotTodos as number[]
      })
    );
  }

  return actions;
}

export class GeneratedStratalotEffects {
  constructor(
    protected actions$: Actions,
    protected stratalotApiService: StratalotApiService,
    protected store$: Store<AppState>
  ) {}

  getManyStratalots$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.getManyStratalots),
      switchMap(({ params }) =>
        this.stratalotApiService.getStratalots(params).pipe(
          map((stratalots: Stratalot[]) => {
            return stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots });
          }),
          catchError(error => of(stratalotActions.stratalotsFailure({ error })))
        )
      )
    );
  });

  getOneStratalot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.getOneStratalot),
      switchMap(idStratalot =>
        this.stratalotApiService.getStratalot(idStratalot).pipe(
          map((stratalot: Stratalot) => {
            return stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots: [stratalot] });
          }),
          catchError(error => of(stratalotActions.stratalotsFailure({ error })))
        )
      )
    );
  });

  upsertOneStratalot$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.upsertOneStratalot),
      concatMap(({ stratalot, ids }: { stratalot: Partial<Stratalot>; ids?: StratalotRelationsIds }) => {
        if (stratalot.idStratalot) {
          return this.stratalotApiService.updateStratalot(stratalot).pipe(
            map((stratalotReturned: Stratalot) => {
              return stratalotActions.normalizeManyStratalotsAfterUpsert({ stratalots: [stratalotReturned] });
            }),
            catchError(error => of(stratalotActions.stratalotsFailure({ error })))
          );
        } else {
          return this.stratalotApiService.addStratalot(stratalot).pipe(
            mergeMap((stratalotReturned: Stratalot) => getDefaultAddStratalotActions(stratalotReturned, ids)),
            catchError(error => of(stratalotActions.stratalotsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneStratalot$ = createEffect(() => {
    const selectStratalotState$ = this.store$.select(selectStratalotState);
    return this.actions$.pipe(
      ofType(stratalotActions.deleteOneStratalot),
      withLatestFrom(selectStratalotState$),
      concatMap(([{ idStratalot }, state]) =>
        this.stratalotApiService.deleteStratalot(idStratalot).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteStratalotActions(state.entities[idStratalot] as StratalotEntityState),
              stratalotActions.deleteOneStratalot.type
            )
          ]),
          catchError(error => of(stratalotActions.stratalotsFailure({ error })))
        )
      )
    );
  });

  normalizeManyStratalotsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stratalotActions.normalizeManyStratalotsAfterUpsert),
      concatMap(({ stratalots }) => {
        const actions: Action[] = getActionsToNormalizeStratalot(stratalots, StoreActionType.upsert);
        return [getMultiAction(actions, '[Stratalot] Normalization After Upsert Success')];
      })
    );
  });
}
