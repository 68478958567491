import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StratalotTypeEntityState } from '@_model/interfaces/stratalot-type.model';

export interface StratalotTypeState extends EntityState<StratalotTypeEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<StratalotTypeEntityState> = createEntityAdapter<StratalotTypeEntityState>({
  selectId: o => o.idStratalotType
});
export const initialState: StratalotTypeState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const stratalotTypeFeatureKey = 'stratalotType';
