import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceWork } from '@_model/interfaces/residence-work.model';
import { Observable } from 'rxjs';

export class GeneratedResidenceWorkApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidenceWorks(params?: any): Observable<ResidenceWork[]> {
    return this.repo.getData<ResidenceWork[]>('residence-work', params);
  }

  public getResidenceWork(params: { idResidenceWork: number; params?: any }): Observable<ResidenceWork> {
    return this.repo.getData<ResidenceWork>('residence-work/' + params.idResidenceWork, params.params);
  }

  public addResidenceWork(residenceWork: Partial<ResidenceWork>): Observable<ResidenceWork> {
    return this.repo.create<ResidenceWork>('residence-work', residenceWork);
  }

  public updateResidenceWork(residenceWork: Partial<ResidenceWork>): Observable<ResidenceWork> {
    return this.repo.update('residence-work', residenceWork);
  }

  public deleteResidenceWork(idResidenceWork: number): Observable<number> {
    return this.repo.delete('residence-work/' + +idResidenceWork);
  }
}
