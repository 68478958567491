import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as GeneratedDocumentsCompanyActions from './generated-documents-company.actions';
import { adapter, initialState, GeneratedDocumentsCompanyState } from './generated-documents-company.state';
import { Dictionary } from '@ngrx/entity';
import { GeneratedDocumentsCompanyEntityState } from '@_model/interfaces/generated-documents-company.model';

export const generatedDocumentsCompanyReducersGeneratedFunctions: ReducerTypes<
  GeneratedDocumentsCompanyState,
  readonly ActionCreator[]
>[] = [
  on(GeneratedDocumentsCompanyActions.getOneGeneratedDocumentsCompany, (state: GeneratedDocumentsCompanyState) =>
    setLoadingsState(state, true)
  ),
  on(GeneratedDocumentsCompanyActions.getManyGeneratedDocumentsCompanies, (state: GeneratedDocumentsCompanyState) =>
    setLoadingsState(state, true)
  ),
  on(GeneratedDocumentsCompanyActions.upsertOneGeneratedDocumentsCompany, (state: GeneratedDocumentsCompanyState) =>
    setLoadingsState(state, true)
  ),

  on(
    GeneratedDocumentsCompanyActions.upsertManyGeneratedDocumentsCompaniesSuccess,
    (state: GeneratedDocumentsCompanyState, { generatedDocumentsCompanies }) =>
      adapter.upsertMany(generatedDocumentsCompanies, setLoadingsState(state, false))
  ),
  on(GeneratedDocumentsCompanyActions.deleteOneGeneratedDocumentsCompany, (state: GeneratedDocumentsCompanyState) =>
    setLoadingsState(state, true)
  ),
  on(
    GeneratedDocumentsCompanyActions.deleteOneGeneratedDocumentsCompanySuccess,
    (state: GeneratedDocumentsCompanyState, { idGeneratedDocumentsCompany }) =>
      adapter.removeOne(idGeneratedDocumentsCompany, setLoadingsState(state, false))
  ),
  on(GeneratedDocumentsCompanyActions.clearActive, (state: GeneratedDocumentsCompanyState) => ({
    ...state,
    actives: []
  })),
  on(
    GeneratedDocumentsCompanyActions.addManyActives,
    (state: GeneratedDocumentsCompanyState, { idGeneratedDocumentsCompanies }) => ({
      ...state,
      actives: state.actives.concat(idGeneratedDocumentsCompanies)
    })
  ),
  on(
    GeneratedDocumentsCompanyActions.deleteOneActive,
    (state: GeneratedDocumentsCompanyState, { idGeneratedDocumentsCompany }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idGeneratedDocumentsCompany)
    })
  ),

  on(GeneratedDocumentsCompanyActions.clearStore, () => initialState),

  on(
    GeneratedDocumentsCompanyActions.addCompanySuccess,
    (state: GeneratedDocumentsCompanyState, { idGeneratedDocumentsCompany, idCompany }) => {
      if (!state.entities[idGeneratedDocumentsCompany]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idGeneratedDocumentsCompany]: {
            ...state.entities[idGeneratedDocumentsCompany],
            company: idCompany
          }
        }
      };
    }
  ),

  on(
    GeneratedDocumentsCompanyActions.deleteManyCompanySuccess,
    (state: GeneratedDocumentsCompanyState, { idCompanies, idGeneratedDocumentsCompanies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idGeneratedDocumentsCompanies.reduce((entities, idGeneratedDocumentsCompany) => {
            if (!state.entities[idGeneratedDocumentsCompany]?.company) {
              return entities;
            }
            entities[idGeneratedDocumentsCompany] = {
              ...state.entities[idGeneratedDocumentsCompany],
              company: idCompanies.some(
                (idCompany: number) => idCompany === state.entities[idGeneratedDocumentsCompany]?.company
              )
                ? undefined
                : state.entities[idGeneratedDocumentsCompany]?.company
            } as GeneratedDocumentsCompanyEntityState;
            return entities;
          }, {} as Dictionary<GeneratedDocumentsCompanyEntityState>)
        }
      };
    }
  ),

  on(
    GeneratedDocumentsCompanyActions.addGeneratedDocumentSuccess,
    (state: GeneratedDocumentsCompanyState, { idGeneratedDocumentsCompany, idGeneratedDocument }) => {
      if (!state.entities[idGeneratedDocumentsCompany]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idGeneratedDocumentsCompany]: {
            ...state.entities[idGeneratedDocumentsCompany],
            generatedDocument: idGeneratedDocument
          }
        }
      };
    }
  ),

  on(
    GeneratedDocumentsCompanyActions.deleteManyGeneratedDocumentSuccess,
    (state: GeneratedDocumentsCompanyState, { idGeneratedDocuments, idGeneratedDocumentsCompanies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idGeneratedDocumentsCompanies.reduce((entities, idGeneratedDocumentsCompany) => {
            if (!state.entities[idGeneratedDocumentsCompany]?.generatedDocument) {
              return entities;
            }
            entities[idGeneratedDocumentsCompany] = {
              ...state.entities[idGeneratedDocumentsCompany],
              generatedDocument: idGeneratedDocuments.some(
                (idGeneratedDocument: number) =>
                  idGeneratedDocument === state.entities[idGeneratedDocumentsCompany]?.generatedDocument
              )
                ? undefined
                : state.entities[idGeneratedDocumentsCompany]?.generatedDocument
            } as GeneratedDocumentsCompanyEntityState;
            return entities;
          }, {} as Dictionary<GeneratedDocumentsCompanyEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: GeneratedDocumentsCompanyState,
  isLoading: boolean,
  isLoaded: boolean = true
): GeneratedDocumentsCompanyState {
  return { ...state, isLoaded, isLoading };
}
