import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationSocieteActions from './organization-societe.actions';
import { adapter, initialState, OrganizationSocieteState } from './organization-societe.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationSocieteEntityState } from '@_model/interfaces/organization-societe.model';

export const organizationSocieteReducersGeneratedFunctions: ReducerTypes<
  OrganizationSocieteState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationSocieteActions.getOneOrganizationSociete, (state: OrganizationSocieteState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationSocieteActions.getManyOrganizationSocietes, (state: OrganizationSocieteState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationSocieteActions.upsertOneOrganizationSociete, (state: OrganizationSocieteState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationSocieteActions.upsertManyOrganizationSocietesSuccess,
    (state: OrganizationSocieteState, { organizationSocietes }) =>
      adapter.upsertMany(organizationSocietes, setLoadingsState(state, false))
  ),
  on(OrganizationSocieteActions.deleteOneOrganizationSociete, (state: OrganizationSocieteState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationSocieteActions.deleteOneOrganizationSocieteSuccess,
    (state: OrganizationSocieteState, { idOrganizationSociete }) =>
      adapter.removeOne(idOrganizationSociete, setLoadingsState(state, false))
  ),
  on(OrganizationSocieteActions.clearActive, (state: OrganizationSocieteState) => ({ ...state, actives: [] })),
  on(OrganizationSocieteActions.addManyActives, (state: OrganizationSocieteState, { idOrganizationSocietes }) => ({
    ...state,
    actives: state.actives.concat(idOrganizationSocietes)
  })),
  on(OrganizationSocieteActions.deleteOneActive, (state: OrganizationSocieteState, { idOrganizationSociete }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idOrganizationSociete)
  })),

  on(OrganizationSocieteActions.clearStore, () => initialState),

  on(
    OrganizationSocieteActions.addManyOrganizationThirdPartySuccess,
    (state: OrganizationSocieteState, { idOrganizationSociete, idOrganizationThirdParties }) => {
      if (!state.entities[idOrganizationSociete]) {
        return state;
      }
      const organizationThirdParties =
        (state.entities[idOrganizationSociete]?.organizationThirdParties as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationSociete]: {
            ...state.entities[idOrganizationSociete],
            organizationThirdParties: organizationThirdParties.concat(
              idOrganizationThirdParties.filter(id => organizationThirdParties.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationSocieteActions.deleteManyOrganizationThirdPartySuccess,
    (state: OrganizationSocieteState, { idOrganizationThirdParties, idOrganizationSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSocietes.reduce((entities, idOrganizationSociete) => {
            if (!state.entities[idOrganizationSociete]?.organizationThirdParties) {
              return entities;
            }
            entities[idOrganizationSociete] = {
              ...state.entities[idOrganizationSociete],
              organizationThirdParties: (
                state.entities[idOrganizationSociete]?.organizationThirdParties as number[]
              )?.filter(
                (idOrganizationThirdParty: number) =>
                  !idOrganizationThirdParties.some((id: number) => id === idOrganizationThirdParty)
              )
            } as OrganizationSocieteEntityState;
            return entities;
          }, {} as Dictionary<OrganizationSocieteEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationSocieteActions.addOrganizationSuccess,
    (state: OrganizationSocieteState, { idOrganizationSociete, idOrganization }) => {
      if (!state.entities[idOrganizationSociete]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationSociete]: {
            ...state.entities[idOrganizationSociete],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationSocieteActions.deleteManyOrganizationSuccess,
    (state: OrganizationSocieteState, { idOrganizations, idOrganizationSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSocietes.reduce((entities, idOrganizationSociete) => {
            if (!state.entities[idOrganizationSociete]?.organization) {
              return entities;
            }
            entities[idOrganizationSociete] = {
              ...state.entities[idOrganizationSociete],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationSociete]?.organization
              )
                ? undefined
                : state.entities[idOrganizationSociete]?.organization
            } as OrganizationSocieteEntityState;
            return entities;
          }, {} as Dictionary<OrganizationSocieteEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationSocieteState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationSocieteState {
  return { ...state, isLoaded, isLoading };
}
