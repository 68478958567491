import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as OrganizationSaleCategoryActions from './organization-sale-category.actions';
import { adapter, initialState, OrganizationSaleCategoryState } from './organization-sale-category.state';
import { Dictionary } from '@ngrx/entity';
import { OrganizationSaleCategoryEntityState } from '@_model/interfaces/organization-sale-category.model';

export const organizationSaleCategoryReducersGeneratedFunctions: ReducerTypes<
  OrganizationSaleCategoryState,
  readonly ActionCreator[]
>[] = [
  on(OrganizationSaleCategoryActions.getOneOrganizationSaleCategory, (state: OrganizationSaleCategoryState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationSaleCategoryActions.getManyOrganizationSaleCategories, (state: OrganizationSaleCategoryState) =>
    setLoadingsState(state, true)
  ),
  on(OrganizationSaleCategoryActions.upsertOneOrganizationSaleCategory, (state: OrganizationSaleCategoryState) =>
    setLoadingsState(state, true)
  ),

  on(
    OrganizationSaleCategoryActions.upsertManyOrganizationSaleCategoriesSuccess,
    (state: OrganizationSaleCategoryState, { organizationSaleCategories }) =>
      adapter.upsertMany(organizationSaleCategories, setLoadingsState(state, false))
  ),
  on(OrganizationSaleCategoryActions.deleteOneOrganizationSaleCategory, (state: OrganizationSaleCategoryState) =>
    setLoadingsState(state, true)
  ),
  on(
    OrganizationSaleCategoryActions.deleteOneOrganizationSaleCategorySuccess,
    (state: OrganizationSaleCategoryState, { idOrganizationSaleCategory }) =>
      adapter.removeOne(idOrganizationSaleCategory, setLoadingsState(state, false))
  ),
  on(OrganizationSaleCategoryActions.clearActive, (state: OrganizationSaleCategoryState) => ({
    ...state,
    actives: []
  })),
  on(
    OrganizationSaleCategoryActions.addManyActives,
    (state: OrganizationSaleCategoryState, { idOrganizationSaleCategories }) => ({
      ...state,
      actives: state.actives.concat(idOrganizationSaleCategories)
    })
  ),
  on(
    OrganizationSaleCategoryActions.deleteOneActive,
    (state: OrganizationSaleCategoryState, { idOrganizationSaleCategory }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idOrganizationSaleCategory)
    })
  ),

  on(OrganizationSaleCategoryActions.clearStore, () => initialState),

  on(
    OrganizationSaleCategoryActions.addManyStratalotSuccess,
    (state: OrganizationSaleCategoryState, { idOrganizationSaleCategory, idStratalots }) => {
      if (!state.entities[idOrganizationSaleCategory]) {
        return state;
      }
      const stratalots = (state.entities[idOrganizationSaleCategory]?.stratalots as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationSaleCategory]: {
            ...state.entities[idOrganizationSaleCategory],
            stratalots: stratalots.concat(idStratalots.filter(id => stratalots.indexOf(id) < 0))
          }
        }
      };
    }
  ),

  on(
    OrganizationSaleCategoryActions.deleteManyStratalotSuccess,
    (state: OrganizationSaleCategoryState, { idStratalots, idOrganizationSaleCategories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSaleCategories.reduce((entities, idOrganizationSaleCategory) => {
            if (!state.entities[idOrganizationSaleCategory]?.stratalots) {
              return entities;
            }
            entities[idOrganizationSaleCategory] = {
              ...state.entities[idOrganizationSaleCategory],
              stratalots: (state.entities[idOrganizationSaleCategory]?.stratalots as number[])?.filter(
                (idStratalot: number) => !idStratalots.some((id: number) => id === idStratalot)
              )
            } as OrganizationSaleCategoryEntityState;
            return entities;
          }, {} as Dictionary<OrganizationSaleCategoryEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationSaleCategoryActions.addManyOrganizationLeadTodoRuleSuccess,
    (state: OrganizationSaleCategoryState, { idOrganizationSaleCategory, idOrganizationLeadTodoRules }) => {
      if (!state.entities[idOrganizationSaleCategory]) {
        return state;
      }
      const organizationLeadTodoRules =
        (state.entities[idOrganizationSaleCategory]?.organizationLeadTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationSaleCategory]: {
            ...state.entities[idOrganizationSaleCategory],
            organizationLeadTodoRules: organizationLeadTodoRules.concat(
              idOrganizationLeadTodoRules.filter(id => organizationLeadTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationSaleCategoryActions.deleteManyOrganizationLeadTodoRuleSuccess,
    (state: OrganizationSaleCategoryState, { idOrganizationLeadTodoRules, idOrganizationSaleCategories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSaleCategories.reduce((entities, idOrganizationSaleCategory) => {
            if (!state.entities[idOrganizationSaleCategory]?.organizationLeadTodoRules) {
              return entities;
            }
            entities[idOrganizationSaleCategory] = {
              ...state.entities[idOrganizationSaleCategory],
              organizationLeadTodoRules: (
                state.entities[idOrganizationSaleCategory]?.organizationLeadTodoRules as number[]
              )?.filter(
                (idOrganizationLeadTodoRule: number) =>
                  !idOrganizationLeadTodoRules.some((id: number) => id === idOrganizationLeadTodoRule)
              )
            } as OrganizationSaleCategoryEntityState;
            return entities;
          }, {} as Dictionary<OrganizationSaleCategoryEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationSaleCategoryActions.addManyOrganizationStratalotTodoRuleSuccess,
    (state: OrganizationSaleCategoryState, { idOrganizationSaleCategory, idOrganizationStratalotTodoRules }) => {
      if (!state.entities[idOrganizationSaleCategory]) {
        return state;
      }
      const organizationStratalotTodoRules =
        (state.entities[idOrganizationSaleCategory]?.organizationStratalotTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationSaleCategory]: {
            ...state.entities[idOrganizationSaleCategory],
            organizationStratalotTodoRules: organizationStratalotTodoRules.concat(
              idOrganizationStratalotTodoRules.filter(id => organizationStratalotTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationSaleCategoryActions.deleteManyOrganizationStratalotTodoRuleSuccess,
    (state: OrganizationSaleCategoryState, { idOrganizationStratalotTodoRules, idOrganizationSaleCategories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSaleCategories.reduce((entities, idOrganizationSaleCategory) => {
            if (!state.entities[idOrganizationSaleCategory]?.organizationStratalotTodoRules) {
              return entities;
            }
            entities[idOrganizationSaleCategory] = {
              ...state.entities[idOrganizationSaleCategory],
              organizationStratalotTodoRules: (
                state.entities[idOrganizationSaleCategory]?.organizationStratalotTodoRules as number[]
              )?.filter(
                (idOrganizationStratalotTodoRule: number) =>
                  !idOrganizationStratalotTodoRules.some((id: number) => id === idOrganizationStratalotTodoRule)
              )
            } as OrganizationSaleCategoryEntityState;
            return entities;
          }, {} as Dictionary<OrganizationSaleCategoryEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationSaleCategoryActions.addManyOrganizationResidenceTodoRuleSuccess,
    (state: OrganizationSaleCategoryState, { idOrganizationSaleCategory, idOrganizationResidenceTodoRules }) => {
      if (!state.entities[idOrganizationSaleCategory]) {
        return state;
      }
      const organizationResidenceTodoRules =
        (state.entities[idOrganizationSaleCategory]?.organizationResidenceTodoRules as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationSaleCategory]: {
            ...state.entities[idOrganizationSaleCategory],
            organizationResidenceTodoRules: organizationResidenceTodoRules.concat(
              idOrganizationResidenceTodoRules.filter(id => organizationResidenceTodoRules.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    OrganizationSaleCategoryActions.deleteManyOrganizationResidenceTodoRuleSuccess,
    (state: OrganizationSaleCategoryState, { idOrganizationResidenceTodoRules, idOrganizationSaleCategories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSaleCategories.reduce((entities, idOrganizationSaleCategory) => {
            if (!state.entities[idOrganizationSaleCategory]?.organizationResidenceTodoRules) {
              return entities;
            }
            entities[idOrganizationSaleCategory] = {
              ...state.entities[idOrganizationSaleCategory],
              organizationResidenceTodoRules: (
                state.entities[idOrganizationSaleCategory]?.organizationResidenceTodoRules as number[]
              )?.filter(
                (idOrganizationResidenceTodoRule: number) =>
                  !idOrganizationResidenceTodoRules.some((id: number) => id === idOrganizationResidenceTodoRule)
              )
            } as OrganizationSaleCategoryEntityState;
            return entities;
          }, {} as Dictionary<OrganizationSaleCategoryEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationSaleCategoryActions.addOrganizationSuccess,
    (state: OrganizationSaleCategoryState, { idOrganizationSaleCategory, idOrganization }) => {
      if (!state.entities[idOrganizationSaleCategory]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationSaleCategory]: {
            ...state.entities[idOrganizationSaleCategory],
            organization: idOrganization
          }
        }
      };
    }
  ),

  on(
    OrganizationSaleCategoryActions.deleteManyOrganizationSuccess,
    (state: OrganizationSaleCategoryState, { idOrganizations, idOrganizationSaleCategories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSaleCategories.reduce((entities, idOrganizationSaleCategory) => {
            if (!state.entities[idOrganizationSaleCategory]?.organization) {
              return entities;
            }
            entities[idOrganizationSaleCategory] = {
              ...state.entities[idOrganizationSaleCategory],
              organization: idOrganizations.some(
                (idOrganization: number) => idOrganization === state.entities[idOrganizationSaleCategory]?.organization
              )
                ? undefined
                : state.entities[idOrganizationSaleCategory]?.organization
            } as OrganizationSaleCategoryEntityState;
            return entities;
          }, {} as Dictionary<OrganizationSaleCategoryEntityState>)
        }
      };
    }
  ),

  on(
    OrganizationSaleCategoryActions.addSaleCategoryFamilySuccess,
    (state: OrganizationSaleCategoryState, { idOrganizationSaleCategory, idSaleCategoryFamily }) => {
      if (!state.entities[idOrganizationSaleCategory]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idOrganizationSaleCategory]: {
            ...state.entities[idOrganizationSaleCategory],
            saleCategoryFamily: idSaleCategoryFamily
          }
        }
      };
    }
  ),

  on(
    OrganizationSaleCategoryActions.deleteManySaleCategoryFamilySuccess,
    (state: OrganizationSaleCategoryState, { idFamilySaleCategories, idOrganizationSaleCategories }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idOrganizationSaleCategories.reduce((entities, idOrganizationSaleCategory) => {
            if (!state.entities[idOrganizationSaleCategory]?.saleCategoryFamily) {
              return entities;
            }
            entities[idOrganizationSaleCategory] = {
              ...state.entities[idOrganizationSaleCategory],
              saleCategoryFamily: idFamilySaleCategories.some(
                (idSaleCategoryFamily: number) =>
                  idSaleCategoryFamily === state.entities[idOrganizationSaleCategory]?.saleCategoryFamily
              )
                ? undefined
                : state.entities[idOrganizationSaleCategory]?.saleCategoryFamily
            } as OrganizationSaleCategoryEntityState;
            return entities;
          }, {} as Dictionary<OrganizationSaleCategoryEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: OrganizationSaleCategoryState,
  isLoading: boolean,
  isLoaded: boolean = true
): OrganizationSaleCategoryState {
  return { ...state, isLoaded, isLoading };
}
