import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationResidenceTodoRule } from '@_model/interfaces/organization-residence-todo-rule.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationResidenceTodoRuleApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationResidenceTodoRules(params?: any): Observable<OrganizationResidenceTodoRule[]> {
    return this.repo.getData<OrganizationResidenceTodoRule[]>('organization-residence-todo-rule', params);
  }

  public getOrganizationResidenceTodoRule(params: {
    idOrganizationResidenceTodoRule: number;
    params?: any;
  }): Observable<OrganizationResidenceTodoRule> {
    return this.repo.getData<OrganizationResidenceTodoRule>(
      'organization-residence-todo-rule/' + params.idOrganizationResidenceTodoRule,
      params.params
    );
  }

  public addOrganizationResidenceTodoRule(
    organizationResidenceTodoRule: Partial<OrganizationResidenceTodoRule>
  ): Observable<OrganizationResidenceTodoRule> {
    return this.repo.create<OrganizationResidenceTodoRule>(
      'organization-residence-todo-rule',
      organizationResidenceTodoRule
    );
  }

  public updateOrganizationResidenceTodoRule(
    organizationResidenceTodoRule: Partial<OrganizationResidenceTodoRule>
  ): Observable<OrganizationResidenceTodoRule> {
    return this.repo.update('organization-residence-todo-rule', organizationResidenceTodoRule);
  }

  public deleteOrganizationResidenceTodoRule(idOrganizationResidenceTodoRule: number): Observable<number> {
    return this.repo.delete('organization-residence-todo-rule/' + +idOrganizationResidenceTodoRule);
  }
}
