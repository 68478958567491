import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CompanyCommunicationLeadTodo,
  CompanyCommunicationLeadTodoEntityState
} from '@_model/interfaces/company-communication-lead-todo.model';
import { CompanyCommunication, CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';
import { OrganizationLeadTodo, OrganizationLeadTodoEntityState } from '@_model/interfaces/organization-lead-todo.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  companyCommunicationLeadTodoFeatureKey,
  CompanyCommunicationLeadTodoState
} from './company-communication-lead-todo.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyCommunicationLeadTodoRelations: string[] = ['companyCommunications', 'organizationLeadTodos'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyCommunicationLeadTodoState = createFeatureSelector<CompanyCommunicationLeadTodoState>(
  companyCommunicationLeadTodoFeatureKey
);

export const selectIsLoadedCompanyCommunicationLeadTodo = createSelector(
  selectCompanyCommunicationLeadTodoState,
  (state: CompanyCommunicationLeadTodoState) => state.isLoaded
);

export const selectIsLoadingCompanyCommunicationLeadTodo = createSelector(
  selectCompanyCommunicationLeadTodoState,
  (state: CompanyCommunicationLeadTodoState) => state.isLoading
);

export const selectIsReadyCompanyCommunicationLeadTodo = createSelector(
  selectCompanyCommunicationLeadTodoState,
  (state: CompanyCommunicationLeadTodoState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyCommunicationLeadTodo = createSelector(
  selectCompanyCommunicationLeadTodoState,
  (state: CompanyCommunicationLeadTodoState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyCommunicationLeadTodoModel: SelectorModel = {
  name: 'companyCommunicationLeadTodos',
  getSelector: selectAllCompanyCommunicationLeadTodosDictionary,
  isReady: selectIsReadyCompanyCommunicationLeadTodo
};

export const selectCompanyCommunicationLeadTodosEntities = createSelector(
  selectCompanyCommunicationLeadTodoState,
  selectEntities
);

export const selectCompanyCommunicationLeadTodosArray = createSelector(
  selectCompanyCommunicationLeadTodoState,
  selectAll
);

export const selectIdCompanyCommunicationLeadTodosActive = createSelector(
  selectCompanyCommunicationLeadTodoState,
  (state: CompanyCommunicationLeadTodoState) => state.actives
);

const companyCommunicationLeadTodosInObject = (
  companyCommunicationLeadTodos: Dictionary<CompanyCommunicationLeadTodoEntityState>
) => ({ companyCommunicationLeadTodos });

const selectCompanyCommunicationLeadTodosEntitiesDictionary = createSelector(
  selectCompanyCommunicationLeadTodosEntities,
  companyCommunicationLeadTodosInObject
);

const selectAllCompanyCommunicationLeadTodosObject = createSelector(
  selectCompanyCommunicationLeadTodosEntities,
  companyCommunicationLeadTodos => {
    return hydrateAll({ companyCommunicationLeadTodos });
  }
);

const selectOneCompanyCommunicationLeadTodoDictionary = (idCompanyCommunicationLeadTodo: number) =>
  createSelector(selectCompanyCommunicationLeadTodosEntities, companyCommunicationLeadTodos => {
    return {
      companyCommunicationLeadTodos: {
        [idCompanyCommunicationLeadTodo]: companyCommunicationLeadTodos[idCompanyCommunicationLeadTodo]
      }
    };
  });

const selectOneCompanyCommunicationLeadTodoDictionaryWithoutChild = (idCompanyCommunicationLeadTodo: number) =>
  createSelector(selectCompanyCommunicationLeadTodosEntities, companyCommunicationLeadTodos => {
    return { companyCommunicationLeadTodo: companyCommunicationLeadTodos[idCompanyCommunicationLeadTodo] };
  });

const selectActiveCompanyCommunicationLeadTodosEntities = createSelector(
  selectIdCompanyCommunicationLeadTodosActive,
  selectCompanyCommunicationLeadTodosEntities,
  (actives: number[], companyCommunicationLeadTodos: Dictionary<CompanyCommunicationLeadTodoEntityState>) =>
    getCompanyCommunicationLeadTodosFromActives(actives, companyCommunicationLeadTodos)
);

function getCompanyCommunicationLeadTodosFromActives(
  actives: number[],
  companyCommunicationLeadTodos: Dictionary<CompanyCommunicationLeadTodoEntityState>
): Dictionary<CompanyCommunicationLeadTodoEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyCommunicationLeadTodos[idActive]) {
      acc[idActive] = companyCommunicationLeadTodos[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyCommunicationLeadTodoEntityState>);
}

const selectAllCompanyCommunicationLeadTodosSelectors: Dictionary<Selector> = {};
export function selectAllCompanyCommunicationLeadTodos(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyCommunicationLeadTodo>(
      schema,
      selectAllCompanyCommunicationLeadTodosSelectors,
      selectCompanyCommunicationLeadTodosEntitiesDictionary,
      getRelationSelectors,
      companyCommunicationLeadTodoRelations,
      hydrateAll,
      'companyCommunicationLeadTodo'
    );
  } else {
    return selectAllCompanyCommunicationLeadTodosObject;
  }
}

export function selectAllCompanyCommunicationLeadTodosDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyCommunicationLeadTodos'
): Selector {
  return createSelector(selectAllCompanyCommunicationLeadTodos(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyCommunicationLeadTodoEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyCommunicationLeadTodos.length; i++) {
      res[customKey][result.companyCommunicationLeadTodos[i].idCompanyCommunicationLeadTodo] =
        result.companyCommunicationLeadTodos[i];
    }
    return res;
  });
}

export function selectOneCompanyCommunicationLeadTodo(
  schema: SelectSchema = {},
  idCompanyCommunicationLeadTodo: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyCommunicationLeadTodoDictionary(idCompanyCommunicationLeadTodo)];
    selectors.push(
      ...getRelationSelectors(schema, companyCommunicationLeadTodoRelations, 'companyCommunicationLeadTodo')
    );
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyCommunicationLeadTodoDictionaryWithoutChild(idCompanyCommunicationLeadTodo);
  }
}

export function selectActiveCompanyCommunicationLeadTodos(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyCommunicationLeadTodosEntities, companyCommunicationLeadTodos => ({
      companyCommunicationLeadTodos
    }))
  ];
  selectors.push(
    ...getRelationSelectors(schema, companyCommunicationLeadTodoRelations, 'companyCommunicationLeadTodo')
  );
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyCommunicationLeadTodos: Dictionary<CompanyCommunicationLeadTodoEntityState>;
  companyCommunications?: Dictionary<CompanyCommunicationEntityState>;
  organizationLeadTodos?: Dictionary<OrganizationLeadTodoEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  companyCommunicationLeadTodos: (CompanyCommunicationLeadTodo | null)[];
} {
  const { companyCommunicationLeadTodos, companyCommunications, organizationLeadTodos } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    companyCommunicationLeadTodos: Object.keys(companyCommunicationLeadTodos).map(idCompanyCommunicationLeadTodo =>
      hydrate(
        companyCommunicationLeadTodos[idCompanyCommunicationLeadTodo] as CompanyCommunicationLeadTodoEntityState,
        companyCommunications,
        organizationLeadTodos
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  companyCommunicationLeadTodo: CompanyCommunicationLeadTodoEntityState | null;
} {
  const { companyCommunicationLeadTodos, companyCommunications, organizationLeadTodos } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const companyCommunicationLeadTodo = Object.values(companyCommunicationLeadTodos)[0];
  return {
    companyCommunicationLeadTodo: hydrate(
      companyCommunicationLeadTodo as CompanyCommunicationLeadTodoEntityState,
      companyCommunications,
      organizationLeadTodos
    )
  };
}

function hydrate(
  companyCommunicationLeadTodo: CompanyCommunicationLeadTodoEntityState,
  companyCommunicationEntities?: Dictionary<CompanyCommunicationEntityState>,
  organizationLeadTodoEntities?: Dictionary<OrganizationLeadTodoEntityState>
): CompanyCommunicationLeadTodo | null {
  if (!companyCommunicationLeadTodo) {
    return null;
  }

  const companyCommunicationLeadTodoHydrated: CompanyCommunicationLeadTodoEntityState = {
    ...companyCommunicationLeadTodo
  };
  if (companyCommunicationEntities) {
    companyCommunicationLeadTodoHydrated.companyCommunication = companyCommunicationEntities[
      companyCommunicationLeadTodo.companyCommunication as number
    ] as CompanyCommunication;
  } else {
    delete companyCommunicationLeadTodoHydrated.companyCommunication;
  }
  if (organizationLeadTodoEntities) {
    companyCommunicationLeadTodoHydrated.organizationLeadTodo = organizationLeadTodoEntities[
      companyCommunicationLeadTodo.organizationLeadTodo as number
    ] as OrganizationLeadTodo;
  } else {
    delete companyCommunicationLeadTodoHydrated.organizationLeadTodo;
  }

  return companyCommunicationLeadTodoHydrated as CompanyCommunicationLeadTodo;
}
