import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidenceStudyCriteriaEntityState } from '@_model/interfaces/residence-study-criteria.model';

export interface ResidenceStudyCriteriaState extends EntityState<ResidenceStudyCriteriaEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidenceStudyCriteriaEntityState> =
  createEntityAdapter<ResidenceStudyCriteriaEntityState>({
    selectId: o => o.idResidenceStudyCriteria
  });
export const initialState: ResidenceStudyCriteriaState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residenceStudyCriteriaFeatureKey = 'residenceStudyCriteria';
