import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedCompanyTerritoireApiService } from './company-territoire-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyTerritoireApiService extends GeneratedCompanyTerritoireApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
