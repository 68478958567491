import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UserApiService } from '@_services/api/user-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedUserEffects } from './user-generated.effects';

@Injectable()
export class UserEffects extends GeneratedUserEffects {
  constructor(actions$: Actions, userApiService: UserApiService, store$: Store<AppState>) {
    super(actions$, userApiService, store$);
  }
}
