import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GeneratedDocument, GeneratedDocumentEntityState } from '@_model/interfaces/generated-document.model';
import {
  GeneratedDocumentsCompany,
  GeneratedDocumentsCompanyEntityState
} from '@_model/interfaces/generated-documents-company.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import {
  GeneratedDocumentsResidence,
  GeneratedDocumentsResidenceEntityState
} from '@_model/interfaces/generated-documents-residence.model';
import { CompanyCommunication, CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';
import {
  CompanyCommunicationGeneratedDocument,
  CompanyCommunicationGeneratedDocumentEntityState
} from '@_model/interfaces/company-communication-generated-document.model';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, generatedDocumentFeatureKey, GeneratedDocumentState } from './generated-document.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const generatedDocumentRelations: string[] = [
  'generatedDocumentsCompanies',
  'companies',
  'residences',
  'generatedDocumentsResidences',
  'companyCommunications',
  'companyCommunicationGeneratedDocuments',
  'organizations'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectGeneratedDocumentState = createFeatureSelector<GeneratedDocumentState>(generatedDocumentFeatureKey);

export const selectIsLoadedGeneratedDocument = createSelector(
  selectGeneratedDocumentState,
  (state: GeneratedDocumentState) => state.isLoaded
);

export const selectIsLoadingGeneratedDocument = createSelector(
  selectGeneratedDocumentState,
  (state: GeneratedDocumentState) => state.isLoading
);

export const selectIsReadyGeneratedDocument = createSelector(
  selectGeneratedDocumentState,
  (state: GeneratedDocumentState) => !state.isLoading
);

export const selectIsReadyAndLoadedGeneratedDocument = createSelector(
  selectGeneratedDocumentState,
  (state: GeneratedDocumentState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const GeneratedDocumentModel: SelectorModel = {
  name: 'generatedDocuments',
  getSelector: selectAllGeneratedDocumentsDictionary,
  isReady: selectIsReadyGeneratedDocument
};

export const selectGeneratedDocumentsEntities = createSelector(selectGeneratedDocumentState, selectEntities);

export const selectGeneratedDocumentsArray = createSelector(selectGeneratedDocumentState, selectAll);

export const selectIdGeneratedDocumentsActive = createSelector(
  selectGeneratedDocumentState,
  (state: GeneratedDocumentState) => state.actives
);

const generatedDocumentsInObject = (generatedDocuments: Dictionary<GeneratedDocumentEntityState>) => ({
  generatedDocuments
});

const selectGeneratedDocumentsEntitiesDictionary = createSelector(
  selectGeneratedDocumentsEntities,
  generatedDocumentsInObject
);

const selectAllGeneratedDocumentsObject = createSelector(selectGeneratedDocumentsEntities, generatedDocuments => {
  return hydrateAll({ generatedDocuments });
});

const selectOneGeneratedDocumentDictionary = (idGeneratedDocument: number) =>
  createSelector(selectGeneratedDocumentsEntities, generatedDocuments => {
    return { generatedDocuments: { [idGeneratedDocument]: generatedDocuments[idGeneratedDocument] } };
  });

const selectOneGeneratedDocumentDictionaryWithoutChild = (idGeneratedDocument: number) =>
  createSelector(selectGeneratedDocumentsEntities, generatedDocuments => {
    return { generatedDocument: generatedDocuments[idGeneratedDocument] };
  });

const selectActiveGeneratedDocumentsEntities = createSelector(
  selectIdGeneratedDocumentsActive,
  selectGeneratedDocumentsEntities,
  (actives: number[], generatedDocuments: Dictionary<GeneratedDocumentEntityState>) =>
    getGeneratedDocumentsFromActives(actives, generatedDocuments)
);

function getGeneratedDocumentsFromActives(
  actives: number[],
  generatedDocuments: Dictionary<GeneratedDocumentEntityState>
): Dictionary<GeneratedDocumentEntityState> {
  return actives.reduce((acc, idActive) => {
    if (generatedDocuments[idActive]) {
      acc[idActive] = generatedDocuments[idActive];
    }
    return acc;
  }, {} as Dictionary<GeneratedDocumentEntityState>);
}

const selectAllGeneratedDocumentsSelectors: Dictionary<Selector> = {};
export function selectAllGeneratedDocuments(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<GeneratedDocument>(
      schema,
      selectAllGeneratedDocumentsSelectors,
      selectGeneratedDocumentsEntitiesDictionary,
      getRelationSelectors,
      generatedDocumentRelations,
      hydrateAll,
      'generatedDocument'
    );
  } else {
    return selectAllGeneratedDocumentsObject;
  }
}

export function selectAllGeneratedDocumentsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'generatedDocuments'
): Selector {
  return createSelector(selectAllGeneratedDocuments(schema), result => {
    const res = { [customKey]: {} as Dictionary<GeneratedDocumentEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.generatedDocuments.length; i++) {
      res[customKey][result.generatedDocuments[i].idGeneratedDocument] = result.generatedDocuments[i];
    }
    return res;
  });
}

export function selectOneGeneratedDocument(schema: SelectSchema = {}, idGeneratedDocument: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneGeneratedDocumentDictionary(idGeneratedDocument)];
    selectors.push(...getRelationSelectors(schema, generatedDocumentRelations, 'generatedDocument'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneGeneratedDocumentDictionaryWithoutChild(idGeneratedDocument);
  }
}

export function selectActiveGeneratedDocuments(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveGeneratedDocumentsEntities, generatedDocuments => ({ generatedDocuments }))
  ];
  selectors.push(...getRelationSelectors(schema, generatedDocumentRelations, 'generatedDocument'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  generatedDocuments: Dictionary<GeneratedDocumentEntityState>;
  organizations?: Dictionary<OrganizationEntityState>;
  generatedDocumentsCompanies?: Dictionary<GeneratedDocumentsCompanyEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
  generatedDocumentsResidences?: Dictionary<GeneratedDocumentsResidenceEntityState>;
  companyCommunications?: Dictionary<CompanyCommunicationEntityState>;
  companyCommunicationGeneratedDocuments?: Dictionary<CompanyCommunicationGeneratedDocumentEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { generatedDocuments: (GeneratedDocument | null)[] } {
  const {
    generatedDocuments,
    organizations,
    generatedDocumentsCompanies,
    companies,
    residences,
    generatedDocumentsResidences,
    companyCommunications,
    companyCommunicationGeneratedDocuments
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    generatedDocuments: Object.keys(generatedDocuments).map(idGeneratedDocument =>
      hydrate(
        generatedDocuments[idGeneratedDocument] as GeneratedDocumentEntityState,
        organizations,
        generatedDocumentsCompanies,
        companies,
        residences,
        generatedDocumentsResidences,
        companyCommunications,
        companyCommunicationGeneratedDocuments
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { generatedDocument: GeneratedDocumentEntityState | null } {
  const {
    generatedDocuments,
    organizations,
    generatedDocumentsCompanies,
    companies,
    residences,
    generatedDocumentsResidences,
    companyCommunications,
    companyCommunicationGeneratedDocuments
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const generatedDocument = Object.values(generatedDocuments)[0];
  return {
    generatedDocument: hydrate(
      generatedDocument as GeneratedDocumentEntityState,
      organizations,
      generatedDocumentsCompanies,
      companies,
      residences,
      generatedDocumentsResidences,
      companyCommunications,
      companyCommunicationGeneratedDocuments
    )
  };
}

function hydrate(
  generatedDocument: GeneratedDocumentEntityState,
  organizationEntities?: Dictionary<OrganizationEntityState>,
  generatedDocumentsCompanyEntities?: Dictionary<GeneratedDocumentsCompanyEntityState>,
  companyEntities?: Dictionary<CompanyEntityState>,
  residenceEntities?: Dictionary<ResidenceEntityState>,
  generatedDocumentsResidenceEntities?: Dictionary<GeneratedDocumentsResidenceEntityState>,
  companyCommunicationEntities?: Dictionary<CompanyCommunicationEntityState>,
  companyCommunicationGeneratedDocumentEntities?: Dictionary<CompanyCommunicationGeneratedDocumentEntityState>
): GeneratedDocument | null {
  if (!generatedDocument) {
    return null;
  }

  const generatedDocumentHydrated: GeneratedDocumentEntityState = { ...generatedDocument };
  if (organizationEntities) {
    generatedDocumentHydrated.organization = organizationEntities[
      generatedDocument.organization as number
    ] as Organization;
  } else {
    delete generatedDocumentHydrated.organization;
  }

  if (generatedDocumentsCompanyEntities) {
    generatedDocumentHydrated.generatedDocumentsCompanies = (
      (generatedDocumentHydrated.generatedDocumentsCompanies as number[]) || []
    ).map(id => generatedDocumentsCompanyEntities[id]) as GeneratedDocumentsCompany[];
  } else {
    delete generatedDocumentHydrated.generatedDocumentsCompanies;
  }

  if (companyEntities) {
    generatedDocumentHydrated.companies = ((generatedDocumentHydrated.companies as number[]) || []).map(
      id => companyEntities[id]
    ) as Company[];
  } else {
    delete generatedDocumentHydrated.companies;
  }

  if (residenceEntities) {
    generatedDocumentHydrated.residences = ((generatedDocumentHydrated.residences as number[]) || []).map(
      id => residenceEntities[id]
    ) as Residence[];
  } else {
    delete generatedDocumentHydrated.residences;
  }

  if (generatedDocumentsResidenceEntities) {
    generatedDocumentHydrated.generatedDocumentsResidences = (
      (generatedDocumentHydrated.generatedDocumentsResidences as number[]) || []
    ).map(id => generatedDocumentsResidenceEntities[id]) as GeneratedDocumentsResidence[];
  } else {
    delete generatedDocumentHydrated.generatedDocumentsResidences;
  }

  if (companyCommunicationEntities) {
    generatedDocumentHydrated.companyCommunications = (
      (generatedDocumentHydrated.companyCommunications as number[]) || []
    ).map(id => companyCommunicationEntities[id]) as CompanyCommunication[];
  } else {
    delete generatedDocumentHydrated.companyCommunications;
  }

  if (companyCommunicationGeneratedDocumentEntities) {
    generatedDocumentHydrated.companyCommunicationGeneratedDocuments = (
      (generatedDocumentHydrated.companyCommunicationGeneratedDocuments as number[]) || []
    ).map(id => companyCommunicationGeneratedDocumentEntities[id]) as CompanyCommunicationGeneratedDocument[];
  } else {
    delete generatedDocumentHydrated.companyCommunicationGeneratedDocuments;
  }

  return generatedDocumentHydrated as GeneratedDocument;
}
