import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyCommunicationStratalotTodoActions from './company-communication-stratalot-todo.actions';
import {
  adapter,
  initialState,
  CompanyCommunicationStratalotTodoState
} from './company-communication-stratalot-todo.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyCommunicationStratalotTodoEntityState } from '@_model/interfaces/company-communication-stratalot-todo.model';

export const companyCommunicationStratalotTodoReducersGeneratedFunctions: ReducerTypes<
  CompanyCommunicationStratalotTodoState,
  readonly ActionCreator[]
>[] = [
  on(
    CompanyCommunicationStratalotTodoActions.getOneCompanyCommunicationStratalotTodo,
    (state: CompanyCommunicationStratalotTodoState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationStratalotTodoActions.getManyCompanyCommunicationStratalotTodos,
    (state: CompanyCommunicationStratalotTodoState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationStratalotTodoActions.upsertOneCompanyCommunicationStratalotTodo,
    (state: CompanyCommunicationStratalotTodoState) => setLoadingsState(state, true)
  ),

  on(
    CompanyCommunicationStratalotTodoActions.upsertManyCompanyCommunicationStratalotTodosSuccess,
    (state: CompanyCommunicationStratalotTodoState, { companyCommunicationStratalotTodos }) =>
      adapter.upsertMany(companyCommunicationStratalotTodos, setLoadingsState(state, false))
  ),
  on(
    CompanyCommunicationStratalotTodoActions.deleteOneCompanyCommunicationStratalotTodo,
    (state: CompanyCommunicationStratalotTodoState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationStratalotTodoActions.deleteOneCompanyCommunicationStratalotTodoSuccess,
    (state: CompanyCommunicationStratalotTodoState, { idCompanyCommunicationStratalotTodo }) =>
      adapter.removeOne(idCompanyCommunicationStratalotTodo, setLoadingsState(state, false))
  ),
  on(CompanyCommunicationStratalotTodoActions.clearActive, (state: CompanyCommunicationStratalotTodoState) => ({
    ...state,
    actives: []
  })),
  on(
    CompanyCommunicationStratalotTodoActions.addManyActives,
    (state: CompanyCommunicationStratalotTodoState, { idCompanyCommunicationStratalotTodos }) => ({
      ...state,
      actives: state.actives.concat(idCompanyCommunicationStratalotTodos)
    })
  ),
  on(
    CompanyCommunicationStratalotTodoActions.deleteOneActive,
    (state: CompanyCommunicationStratalotTodoState, { idCompanyCommunicationStratalotTodo }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCompanyCommunicationStratalotTodo)
    })
  ),

  on(CompanyCommunicationStratalotTodoActions.clearStore, () => initialState),

  on(
    CompanyCommunicationStratalotTodoActions.addCompanyCommunicationSuccess,
    (
      state: CompanyCommunicationStratalotTodoState,
      { idCompanyCommunicationStratalotTodo, idCompanyCommunication }
    ) => {
      if (!state.entities[idCompanyCommunicationStratalotTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationStratalotTodo]: {
            ...state.entities[idCompanyCommunicationStratalotTodo],
            companyCommunication: idCompanyCommunication
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationStratalotTodoActions.deleteManyCompanyCommunicationSuccess,
    (
      state: CompanyCommunicationStratalotTodoState,
      { idCompanyCommunications, idCompanyCommunicationStratalotTodos }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationStratalotTodos.reduce((entities, idCompanyCommunicationStratalotTodo) => {
            if (!state.entities[idCompanyCommunicationStratalotTodo]?.companyCommunication) {
              return entities;
            }
            entities[idCompanyCommunicationStratalotTodo] = {
              ...state.entities[idCompanyCommunicationStratalotTodo],
              companyCommunication: idCompanyCommunications.some(
                (idCompanyCommunication: number) =>
                  idCompanyCommunication === state.entities[idCompanyCommunicationStratalotTodo]?.companyCommunication
              )
                ? undefined
                : state.entities[idCompanyCommunicationStratalotTodo]?.companyCommunication
            } as CompanyCommunicationStratalotTodoEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationStratalotTodoEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationStratalotTodoActions.addOrganizationStratalotTodoSuccess,
    (
      state: CompanyCommunicationStratalotTodoState,
      { idCompanyCommunicationStratalotTodo, idOrganizationStratalotTodo }
    ) => {
      if (!state.entities[idCompanyCommunicationStratalotTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationStratalotTodo]: {
            ...state.entities[idCompanyCommunicationStratalotTodo],
            organizationStratalotTodo: idOrganizationStratalotTodo
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationStratalotTodoActions.deleteManyOrganizationStratalotTodoSuccess,
    (
      state: CompanyCommunicationStratalotTodoState,
      { idOrganizationStratalotTodos, idCompanyCommunicationStratalotTodos }
    ) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationStratalotTodos.reduce((entities, idCompanyCommunicationStratalotTodo) => {
            if (!state.entities[idCompanyCommunicationStratalotTodo]?.organizationStratalotTodo) {
              return entities;
            }
            entities[idCompanyCommunicationStratalotTodo] = {
              ...state.entities[idCompanyCommunicationStratalotTodo],
              organizationStratalotTodo: idOrganizationStratalotTodos.some(
                (idOrganizationStratalotTodo: number) =>
                  idOrganizationStratalotTodo ===
                  state.entities[idCompanyCommunicationStratalotTodo]?.organizationStratalotTodo
              )
                ? undefined
                : state.entities[idCompanyCommunicationStratalotTodo]?.organizationStratalotTodo
            } as CompanyCommunicationStratalotTodoEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationStratalotTodoEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyCommunicationStratalotTodoState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyCommunicationStratalotTodoState {
  return { ...state, isLoaded, isLoading };
}
