import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationResidenceTodoAppliedRule,
  OrganizationResidenceTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-residence-todo-applied-rule.model';
import { OrganizationResidenceTodoAppliedRuleApiService } from '@_services/api/organization-residence-todo-applied-rule-api.service';
import * as organizationResidenceTodoAppliedRuleActions from '@_store/organization-residence-todo-applied-rule/organization-residence-todo-applied-rule.actions';
import { getActionsToNormalizeOrganizationResidenceTodoAppliedRule } from '@_config/store/normalization.generated';
import { selectOrganizationResidenceTodoAppliedRuleState } from './organization-residence-todo-applied-rule-generated.selectors';
import * as organizationResidenceTodoActions from '@_store/organization-residence-todo/organization-residence-todo.actions';
import * as organizationResidenceTodoRuleActions from '@_store/organization-residence-todo-rule/organization-residence-todo-rule.actions';

export interface OrganizationResidenceTodoAppliedRuleRelationsIds {
  organizationResidenceTodo?: number;
  organizationResidenceTodoRule?: number;
}

export function getDefaultAddOrganizationResidenceTodoAppliedRuleActions(
  organizationResidenceTodoAppliedRule: OrganizationResidenceTodoAppliedRuleEntityState,
  ids?: OrganizationResidenceTodoAppliedRuleRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationResidenceTodoAppliedRuleActions.normalizeManyOrganizationResidenceTodoAppliedRulesAfterUpsert({
      organizationResidenceTodoAppliedRules: [organizationResidenceTodoAppliedRule]
    })
  ];

  if (ids?.organizationResidenceTodo) {
    actions.push(
      organizationResidenceTodoActions.addManyOrganizationResidenceTodoAppliedRuleSuccess({
        idOrganizationResidenceTodo: ids.organizationResidenceTodo,
        idOrganizationResidenceTodoAppliedRules: [
          organizationResidenceTodoAppliedRule.idOrganizationResidenceTodoAppliedRule
        ]
      })
    );
    actions.push(
      organizationResidenceTodoAppliedRuleActions.addOrganizationResidenceTodoSuccess({
        idOrganizationResidenceTodoAppliedRule:
          organizationResidenceTodoAppliedRule.idOrganizationResidenceTodoAppliedRule,
        idOrganizationResidenceTodo: ids.organizationResidenceTodo
      })
    );
  }

  if (ids?.organizationResidenceTodoRule) {
    actions.push(
      organizationResidenceTodoRuleActions.addManyOrganizationResidenceTodoAppliedRuleSuccess({
        idOrganizationResidenceTodoRule: ids.organizationResidenceTodoRule,
        idOrganizationResidenceTodoAppliedRules: [
          organizationResidenceTodoAppliedRule.idOrganizationResidenceTodoAppliedRule
        ]
      })
    );
    actions.push(
      organizationResidenceTodoAppliedRuleActions.addOrganizationResidenceTodoRuleSuccess({
        idOrganizationResidenceTodoAppliedRule:
          organizationResidenceTodoAppliedRule.idOrganizationResidenceTodoAppliedRule,
        idOrganizationResidenceTodoRule: ids.organizationResidenceTodoRule
      })
    );
  }

  return actions;
}

export function getDefaultDeleteOrganizationResidenceTodoAppliedRuleActions(
  organizationResidenceTodoAppliedRule: OrganizationResidenceTodoAppliedRuleEntityState
): Action[] {
  const actions: Action[] = [
    organizationResidenceTodoAppliedRuleActions.deleteOneOrganizationResidenceTodoAppliedRuleSuccess({
      idOrganizationResidenceTodoAppliedRule:
        organizationResidenceTodoAppliedRule.idOrganizationResidenceTodoAppliedRule
    })
  ];

  if (organizationResidenceTodoAppliedRule.organizationResidenceTodo) {
    actions.push(
      organizationResidenceTodoActions.deleteManyOrganizationResidenceTodoAppliedRuleSuccess({
        idOrganizationResidenceTodoAppliedRules: [
          organizationResidenceTodoAppliedRule.idOrganizationResidenceTodoAppliedRule
        ],
        idOrganizationResidenceTodos: [organizationResidenceTodoAppliedRule.organizationResidenceTodo as number]
      })
    );
  }

  if (organizationResidenceTodoAppliedRule.organizationResidenceTodoRule) {
    actions.push(
      organizationResidenceTodoRuleActions.deleteManyOrganizationResidenceTodoAppliedRuleSuccess({
        idOrganizationResidenceTodoAppliedRules: [
          organizationResidenceTodoAppliedRule.idOrganizationResidenceTodoAppliedRule
        ],
        idOrganizationResidenceTodoRules: [organizationResidenceTodoAppliedRule.organizationResidenceTodoRule as number]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationResidenceTodoAppliedRuleEffects {
  constructor(
    protected actions$: Actions,
    protected organizationResidenceTodoAppliedRuleApiService: OrganizationResidenceTodoAppliedRuleApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationResidenceTodoAppliedRules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceTodoAppliedRuleActions.getManyOrganizationResidenceTodoAppliedRules),
      switchMap(({ params }) =>
        this.organizationResidenceTodoAppliedRuleApiService.getOrganizationResidenceTodoAppliedRules(params).pipe(
          map((organizationResidenceTodoAppliedRules: OrganizationResidenceTodoAppliedRule[]) => {
            return organizationResidenceTodoAppliedRuleActions.normalizeManyOrganizationResidenceTodoAppliedRulesAfterUpsert(
              { organizationResidenceTodoAppliedRules }
            );
          }),
          catchError(error =>
            of(organizationResidenceTodoAppliedRuleActions.organizationResidenceTodoAppliedRulesFailure({ error }))
          )
        )
      )
    );
  });

  getOneOrganizationResidenceTodoAppliedRule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceTodoAppliedRuleActions.getOneOrganizationResidenceTodoAppliedRule),
      switchMap(idOrganizationResidenceTodoAppliedRule =>
        this.organizationResidenceTodoAppliedRuleApiService
          .getOrganizationResidenceTodoAppliedRule(idOrganizationResidenceTodoAppliedRule)
          .pipe(
            map((organizationResidenceTodoAppliedRule: OrganizationResidenceTodoAppliedRule) => {
              return organizationResidenceTodoAppliedRuleActions.normalizeManyOrganizationResidenceTodoAppliedRulesAfterUpsert(
                { organizationResidenceTodoAppliedRules: [organizationResidenceTodoAppliedRule] }
              );
            }),
            catchError(error =>
              of(organizationResidenceTodoAppliedRuleActions.organizationResidenceTodoAppliedRulesFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneOrganizationResidenceTodoAppliedRule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceTodoAppliedRuleActions.upsertOneOrganizationResidenceTodoAppliedRule),
      concatMap(
        ({
          organizationResidenceTodoAppliedRule,
          ids
        }: {
          organizationResidenceTodoAppliedRule: Partial<OrganizationResidenceTodoAppliedRule>;
          ids?: OrganizationResidenceTodoAppliedRuleRelationsIds;
        }) => {
          if (organizationResidenceTodoAppliedRule.idOrganizationResidenceTodoAppliedRule) {
            return this.organizationResidenceTodoAppliedRuleApiService
              .updateOrganizationResidenceTodoAppliedRule(organizationResidenceTodoAppliedRule)
              .pipe(
                map((organizationResidenceTodoAppliedRuleReturned: OrganizationResidenceTodoAppliedRule) => {
                  return organizationResidenceTodoAppliedRuleActions.normalizeManyOrganizationResidenceTodoAppliedRulesAfterUpsert(
                    { organizationResidenceTodoAppliedRules: [organizationResidenceTodoAppliedRuleReturned] }
                  );
                }),
                catchError(error =>
                  of(
                    organizationResidenceTodoAppliedRuleActions.organizationResidenceTodoAppliedRulesFailure({ error })
                  )
                )
              );
          } else {
            return this.organizationResidenceTodoAppliedRuleApiService
              .addOrganizationResidenceTodoAppliedRule(organizationResidenceTodoAppliedRule)
              .pipe(
                mergeMap((organizationResidenceTodoAppliedRuleReturned: OrganizationResidenceTodoAppliedRule) =>
                  getDefaultAddOrganizationResidenceTodoAppliedRuleActions(
                    organizationResidenceTodoAppliedRuleReturned,
                    ids
                  )
                ),
                catchError(error =>
                  of(
                    organizationResidenceTodoAppliedRuleActions.organizationResidenceTodoAppliedRulesFailure({ error })
                  )
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationResidenceTodoAppliedRule$ = createEffect(() => {
    const selectOrganizationResidenceTodoAppliedRuleState$ = this.store$.select(
      selectOrganizationResidenceTodoAppliedRuleState
    );
    return this.actions$.pipe(
      ofType(organizationResidenceTodoAppliedRuleActions.deleteOneOrganizationResidenceTodoAppliedRule),
      withLatestFrom(selectOrganizationResidenceTodoAppliedRuleState$),
      concatMap(([{ idOrganizationResidenceTodoAppliedRule }, state]) =>
        this.organizationResidenceTodoAppliedRuleApiService
          .deleteOrganizationResidenceTodoAppliedRule(idOrganizationResidenceTodoAppliedRule)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteOrganizationResidenceTodoAppliedRuleActions(
                  state.entities[
                    idOrganizationResidenceTodoAppliedRule
                  ] as OrganizationResidenceTodoAppliedRuleEntityState
                ),
                organizationResidenceTodoAppliedRuleActions.deleteOneOrganizationResidenceTodoAppliedRule.type
              )
            ]),
            catchError(error =>
              of(organizationResidenceTodoAppliedRuleActions.organizationResidenceTodoAppliedRulesFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyOrganizationResidenceTodoAppliedRulesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationResidenceTodoAppliedRuleActions.normalizeManyOrganizationResidenceTodoAppliedRulesAfterUpsert),
      concatMap(({ organizationResidenceTodoAppliedRules }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationResidenceTodoAppliedRule(
          organizationResidenceTodoAppliedRules,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationResidenceTodoAppliedRule] Normalization After Upsert Success')];
      })
    );
  });
}
