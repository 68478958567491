import { RepositoryService } from '@_services/api/repository.service';
import { ResidenceStudy } from '@_model/interfaces/residence-study.model';
import { Observable } from 'rxjs';

export class GeneratedResidenceStudyApiService {
  constructor(protected repo: RepositoryService) {}

  public getResidenceStudies(params?: any): Observable<ResidenceStudy[]> {
    return this.repo.getData<ResidenceStudy[]>('residence-study', params);
  }

  public getResidenceStudy(params: { idResidenceStudy: number; params?: any }): Observable<ResidenceStudy> {
    return this.repo.getData<ResidenceStudy>('residence-study/' + params.idResidenceStudy, params.params);
  }

  public addResidenceStudy(residenceStudy: Partial<ResidenceStudy>): Observable<ResidenceStudy> {
    return this.repo.create<ResidenceStudy>('residence-study', residenceStudy);
  }

  public updateResidenceStudy(residenceStudy: Partial<ResidenceStudy>): Observable<ResidenceStudy> {
    return this.repo.update('residence-study', residenceStudy);
  }

  public deleteResidenceStudy(idResidenceStudy: number): Observable<number> {
    return this.repo.delete('residence-study/' + +idResidenceStudy);
  }
}
