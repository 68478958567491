import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { OrganizationMetier, OrganizationMetierEntityState } from '@_model/interfaces/organization-metier.model';
import { OrganizationMetierApiService } from '@_services/api/organization-metier-api.service';
import * as organizationMetierActions from '@_store/organization-metier/organization-metier.actions';
import { getActionsToNormalizeOrganizationMetier } from '@_config/store/normalization.generated';
import { selectOrganizationMetierState } from './organization-metier-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as metierFamilieActions from '@_store/metier-familie/metier-familie.actions';
import * as organizationThirdPartyActions from '@_store/organization-third-party/organization-third-party.actions';
import { OrganizationThirdParty } from '@_model/interfaces/organization-third-party.model';
import * as companyCommunicationRecipientActions from '@_store/company-communication-recipient/company-communication-recipient.actions';
import { CompanyCommunicationRecipient } from '@_model/interfaces/company-communication-recipient.model';
import * as companyCommunicationRecipientMetierActions from '@_store/company-communication-recipient-metier/company-communication-recipient-metier.actions';
import { CompanyCommunicationRecipientMetier } from '@_model/interfaces/company-communication-recipient-metier.model';

export interface OrganizationMetierRelationsIds {
  organizationThirdParties?: number | number[];
  companyCommunicationRecipients?: number | number[];
  companyCommunicationRecipientMetiers?: number | number[];
  organization?: number;
  metierFamilie?: number;
}

export function getDefaultAddOrganizationMetierActions(
  organizationMetier: OrganizationMetierEntityState,
  ids?: OrganizationMetierRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationMetierActions.normalizeManyOrganizationMetiersAfterUpsert({ organizationMetiers: [organizationMetier] })
  ];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationMetierSuccess({
        idOrganization: ids.organization,
        idOrganizationMetiers: [organizationMetier.idOrganizationMetier]
      })
    );
    actions.push(
      organizationMetierActions.addOrganizationSuccess({
        idOrganizationMetier: organizationMetier.idOrganizationMetier,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.metierFamilie) {
    actions.push(
      metierFamilieActions.addManyOrganizationMetierSuccess({
        idMetierFamilie: ids.metierFamilie,
        idOrganizationMetiers: [organizationMetier.idOrganizationMetier]
      })
    );
    actions.push(
      organizationMetierActions.addMetierFamilieSuccess({
        idOrganizationMetier: organizationMetier.idOrganizationMetier,
        idMetierFamilie: ids.metierFamilie
      })
    );
  }

  if (ids?.organizationThirdParties) {
    if (!Array.isArray(ids.organizationThirdParties)) {
      actions.push(
        organizationThirdPartyActions.upsertOneOrganizationThirdParty({
          organizationThirdParty: {
            idOrganizationMetier: organizationMetier.idOrganizationMetier,
            idOrganizationThirdParty: ids.organizationThirdParties as number
          } as OrganizationThirdParty
        })
      );
      actions.push(
        organizationMetierActions.addManyOrganizationThirdPartySuccess({
          idOrganizationMetier: organizationMetier.idOrganizationMetier,
          idOrganizationThirdParties: [ids.organizationThirdParties as number]
        })
      );
    } else {
      actions.push(
        organizationThirdPartyActions.upsertManyOrganizationThirdParties({
          organizationThirdParties: (ids.organizationThirdParties as number[]).map(
            (idOrganizationThirdParty: number) => ({
              idOrganizationMetier: organizationMetier.idOrganizationMetier,
              idOrganizationThirdParty
            })
          ) as OrganizationThirdParty[]
        })
      );
      actions.push(
        organizationMetierActions.addManyOrganizationThirdPartySuccess({
          idOrganizationMetier: organizationMetier.idOrganizationMetier,
          idOrganizationThirdParties: ids.organizationThirdParties as number[]
        })
      );
    }
  }

  if (ids?.companyCommunicationRecipients) {
    if (!Array.isArray(ids.companyCommunicationRecipients)) {
      actions.push(
        companyCommunicationRecipientActions.upsertOneCompanyCommunicationRecipient({
          companyCommunicationRecipient: {
            idOrganizationMetier: organizationMetier.idOrganizationMetier,
            idCompanyCommunicationRecipient: ids.companyCommunicationRecipients as number
          } as CompanyCommunicationRecipient & any
        })
      );
      actions.push(
        organizationMetierActions.addManyCompanyCommunicationRecipientSuccess({
          idOrganizationMetier: organizationMetier.idOrganizationMetier,
          idCompanyCommunicationRecipients: [ids.companyCommunicationRecipients as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationRecipientActions.upsertManyCompanyCommunicationRecipients({
          companyCommunicationRecipients: (ids.companyCommunicationRecipients as number[]).map(
            (idCompanyCommunicationRecipient: number) => ({
              idOrganizationMetier: organizationMetier.idOrganizationMetier,
              idCompanyCommunicationRecipient
            })
          ) as CompanyCommunicationRecipient[] & any[]
        })
      );
      actions.push(
        organizationMetierActions.addManyCompanyCommunicationRecipientSuccess({
          idOrganizationMetier: organizationMetier.idOrganizationMetier,
          idCompanyCommunicationRecipients: ids.companyCommunicationRecipients as number[]
        })
      );
    }
  }

  if (ids?.companyCommunicationRecipientMetiers) {
    if (!Array.isArray(ids.companyCommunicationRecipientMetiers)) {
      actions.push(
        companyCommunicationRecipientMetierActions.upsertOneCompanyCommunicationRecipientMetier({
          companyCommunicationRecipientMetier: {
            idOrganizationMetier: organizationMetier.idOrganizationMetier,
            idCompanyCommunicationRecipientMetier: ids.companyCommunicationRecipientMetiers as number
          } as CompanyCommunicationRecipientMetier
        })
      );
      actions.push(
        organizationMetierActions.addManyCompanyCommunicationRecipientMetierSuccess({
          idOrganizationMetier: organizationMetier.idOrganizationMetier,
          idCompanyCommunicationRecipientMetiers: [ids.companyCommunicationRecipientMetiers as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationRecipientMetierActions.upsertManyCompanyCommunicationRecipientMetiers({
          companyCommunicationRecipientMetiers: (ids.companyCommunicationRecipientMetiers as number[]).map(
            (idCompanyCommunicationRecipientMetier: number) => ({
              idOrganizationMetier: organizationMetier.idOrganizationMetier,
              idCompanyCommunicationRecipientMetier
            })
          ) as CompanyCommunicationRecipientMetier[]
        })
      );
      actions.push(
        organizationMetierActions.addManyCompanyCommunicationRecipientMetierSuccess({
          idOrganizationMetier: organizationMetier.idOrganizationMetier,
          idCompanyCommunicationRecipientMetiers: ids.companyCommunicationRecipientMetiers as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationMetierActions(organizationMetier: OrganizationMetierEntityState): Action[] {
  const actions: Action[] = [
    organizationMetierActions.deleteOneOrganizationMetierSuccess({
      idOrganizationMetier: organizationMetier.idOrganizationMetier
    })
  ];

  if (organizationMetier.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationMetierSuccess({
        idOrganizationMetiers: [organizationMetier.idOrganizationMetier],
        idOrganizations: [organizationMetier.organization as number]
      })
    );
  }

  if (organizationMetier.metierFamilie) {
    actions.push(
      metierFamilieActions.deleteManyOrganizationMetierSuccess({
        idOrganizationMetiers: [organizationMetier.idOrganizationMetier],
        idMetierFamilies: [organizationMetier.metierFamilie as number]
      })
    );
  }

  if (organizationMetier.organizationThirdParties) {
    actions.push(
      organizationThirdPartyActions.deleteManyOrganizationMetierSuccess({
        idOrganizationMetiers: [organizationMetier.idOrganizationMetier],
        idOrganizationThirdParties: organizationMetier.organizationThirdParties as number[]
      })
    );
  }

  if (organizationMetier.companyCommunicationRecipients) {
    actions.push(
      companyCommunicationRecipientActions.deleteManyOrganizationMetierSuccess({
        idOrganizationMetiers: [organizationMetier.idOrganizationMetier],
        idCompanyCommunicationRecipients: organizationMetier.companyCommunicationRecipients as number[]
      })
    );
  }

  if (organizationMetier.companyCommunicationRecipientMetiers) {
    actions.push(
      companyCommunicationRecipientMetierActions.deleteManyOrganizationMetierSuccess({
        idOrganizationMetiers: [organizationMetier.idOrganizationMetier],
        idCompanyCommunicationRecipientMetiers: organizationMetier.companyCommunicationRecipientMetiers as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationMetierEffects {
  constructor(
    protected actions$: Actions,
    protected organizationMetierApiService: OrganizationMetierApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationMetiers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationMetierActions.getManyOrganizationMetiers),
      switchMap(({ params }) =>
        this.organizationMetierApiService.getOrganizationMetiers(params).pipe(
          map((organizationMetiers: OrganizationMetier[]) => {
            return organizationMetierActions.normalizeManyOrganizationMetiersAfterUpsert({ organizationMetiers });
          }),
          catchError(error => of(organizationMetierActions.organizationMetiersFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationMetier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationMetierActions.getOneOrganizationMetier),
      switchMap(idOrganizationMetier =>
        this.organizationMetierApiService.getOrganizationMetier(idOrganizationMetier).pipe(
          map((organizationMetier: OrganizationMetier) => {
            return organizationMetierActions.normalizeManyOrganizationMetiersAfterUpsert({
              organizationMetiers: [organizationMetier]
            });
          }),
          catchError(error => of(organizationMetierActions.organizationMetiersFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationMetier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationMetierActions.upsertOneOrganizationMetier),
      concatMap(
        ({
          organizationMetier,
          ids
        }: {
          organizationMetier: Partial<OrganizationMetier>;
          ids?: OrganizationMetierRelationsIds;
        }) => {
          if (organizationMetier.idOrganizationMetier) {
            return this.organizationMetierApiService.updateOrganizationMetier(organizationMetier).pipe(
              map((organizationMetierReturned: OrganizationMetier) => {
                return organizationMetierActions.normalizeManyOrganizationMetiersAfterUpsert({
                  organizationMetiers: [organizationMetierReturned]
                });
              }),
              catchError(error => of(organizationMetierActions.organizationMetiersFailure({ error })))
            );
          } else {
            return this.organizationMetierApiService.addOrganizationMetier(organizationMetier).pipe(
              mergeMap((organizationMetierReturned: OrganizationMetier) =>
                getDefaultAddOrganizationMetierActions(organizationMetierReturned, ids)
              ),
              catchError(error => of(organizationMetierActions.organizationMetiersFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneOrganizationMetier$ = createEffect(() => {
    const selectOrganizationMetierState$ = this.store$.select(selectOrganizationMetierState);
    return this.actions$.pipe(
      ofType(organizationMetierActions.deleteOneOrganizationMetier),
      withLatestFrom(selectOrganizationMetierState$),
      concatMap(([{ idOrganizationMetier }, state]) =>
        this.organizationMetierApiService.deleteOrganizationMetier(idOrganizationMetier).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOrganizationMetierActions(
                state.entities[idOrganizationMetier] as OrganizationMetierEntityState
              ),
              organizationMetierActions.deleteOneOrganizationMetier.type
            )
          ]),
          catchError(error => of(organizationMetierActions.organizationMetiersFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationMetiersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationMetierActions.normalizeManyOrganizationMetiersAfterUpsert),
      concatMap(({ organizationMetiers }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationMetier(organizationMetiers, StoreActionType.upsert);
        return [getMultiAction(actions, '[OrganizationMetier] Normalization After Upsert Success')];
      })
    );
  });
}
