import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  ResidenceStudyCriteria,
  ResidenceStudyCriteriaEntityState
} from '@_model/interfaces/residence-study-criteria.model';
import { ResidenceStudyCriteriaRelationsIds } from './residence-study-criteria-generated.effects';

export const getOneResidenceStudyCriteria = createAction(
  '[ResidenceStudyCriteria] Get One ResidenceStudyCriteria',
  props<{ idResidenceStudyCriteria: number; params?: any }>()
);

export const getManyResidenceStudyCriterias = createAction(
  '[ResidenceStudyCriteria] Get Many ResidenceStudyCriterias',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ResidenceStudyCriteria] Add Many Actives ResidenceStudyCriteria',
  props<{ idResidenceStudyCriterias: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidenceStudyCriteria] Delete One Active ResidenceStudyCriteria',
  props<{ idResidenceStudyCriteria: number }>()
);

export const clearActive = createAction('[ResidenceStudyCriteria] Clear Active ResidenceStudyCriteria');

export const upsertOneResidenceStudyCriteria = createAction(
  '[ResidenceStudyCriteria] Upsert One ResidenceStudyCriteria',
  props<{
    residenceStudyCriteria: Partial<ResidenceStudyCriteria>;
    ids?: ResidenceStudyCriteriaRelationsIds;
  }>()
);

export const upsertManyResidenceStudyCriterias = createAction(
  '[ResidenceStudyCriteria] Upsert Many ResidenceStudyCriterias',
  props<{
    residenceStudyCriterias: Partial<ResidenceStudyCriteria>[];
    ids?: ResidenceStudyCriteriaRelationsIds;
  }>()
);

export const upsertManyResidenceStudyCriteriasSuccess = createAction(
  '[ResidenceStudyCriteria] Create Many ResidenceStudyCriterias Success',
  props<{ residenceStudyCriterias: ResidenceStudyCriteriaEntityState[] }>()
);

export const deleteOneResidenceStudyCriteria = createAction(
  '[ResidenceStudyCriteria] Delete One ResidenceStudyCriteria',
  props<{ idResidenceStudyCriteria: number }>()
);

export const deleteOneResidenceStudyCriteriaSuccess = createAction(
  '[ResidenceStudyCriteria] Delete One ResidenceStudyCriteria Success',
  props<{ idResidenceStudyCriteria: number }>()
);

export const normalizeManyResidenceStudyCriteriasAfterUpsert = createAction(
  '[ResidenceStudyCriteria] Normalize Many ResidenceStudyCriterias After Upsert',
  props<{ residenceStudyCriterias: ResidenceStudyCriteriaEntityState[] }>()
);

export const residenceStudyCriteriasFailure = createAction(
  '[ResidenceStudyCriteria] ResidenceStudyCriterias Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidenceStudyCriteria] Clear ResidenceStudyCriterias');

export const addResidenceStudySuccess = createAction(
  '[ResidenceStudyCriteria] Add ResidenceStudy',
  props<{ idResidenceStudyCriteria: number; idResidenceStudy: number }>()
);

export const deleteManyResidenceStudySuccess = createAction(
  '[ResidenceStudyCriteria] Delete Many ResidenceStudy',
  props<{ idResidenceStudies: number[]; idResidenceStudyCriterias: number[] }>()
);

export const addCompanyStudyCriteriaSuccess = createAction(
  '[ResidenceStudyCriteria] Add CompanyStudyCriteria',
  props<{ idResidenceStudyCriteria: number; idCompanyStudyCriteria: number }>()
);

export const deleteManyCompanyStudyCriteriaSuccess = createAction(
  '[ResidenceStudyCriteria] Delete Many CompanyStudyCriteria',
  props<{ idCompanyStudyCriterias: number[]; idResidenceStudyCriterias: number[] }>()
);
