import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ProspectEventEntityState } from '@_model/interfaces/prospect-event.model';

export interface ProspectEventState extends EntityState<ProspectEventEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ProspectEventEntityState> = createEntityAdapter<ProspectEventEntityState>({
  selectId: o => o.idProspectEvent
});
export const initialState: ProspectEventState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const prospectEventFeatureKey = 'prospectEvent';
