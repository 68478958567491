import { RepositoryService } from '@_services/api/repository.service';
import { StratalotPriceList } from '@_model/interfaces/stratalot-price-list.model';
import { Observable } from 'rxjs';

export class GeneratedStratalotPriceListApiService {
  constructor(protected repo: RepositoryService) {}

  public getStratalotPriceLists(params?: any): Observable<StratalotPriceList[]> {
    return this.repo.getData<StratalotPriceList[]>('stratalot-price-list', params);
  }

  public getStratalotPriceList(params: { idStratalotPriceList: number; params?: any }): Observable<StratalotPriceList> {
    return this.repo.getData<StratalotPriceList>('stratalot-price-list/' + params.idStratalotPriceList, params.params);
  }

  public addStratalotPriceList(stratalotPriceList: Partial<StratalotPriceList>): Observable<StratalotPriceList> {
    return this.repo.create<StratalotPriceList>('stratalot-price-list', stratalotPriceList);
  }

  public updateStratalotPriceList(stratalotPriceList: Partial<StratalotPriceList>): Observable<StratalotPriceList> {
    return this.repo.update('stratalot-price-list', stratalotPriceList);
  }

  public deleteStratalotPriceList(idStratalotPriceList: number): Observable<number> {
    return this.repo.delete('stratalot-price-list/' + +idStratalotPriceList);
  }
}
