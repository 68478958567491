import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationEntityState } from '@_model/interfaces/organization.model';

export interface OrganizationState extends EntityState<OrganizationEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationEntityState> = createEntityAdapter<OrganizationEntityState>({
  selectId: o => o.idOrganization
});
export const initialState: OrganizationState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationFeatureKey = 'organization';
