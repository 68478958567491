import { RepositoryService } from '@_services/api/repository.service';
import { CompanyCommunicationLeadTodo } from '@_model/interfaces/company-communication-lead-todo.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyCommunicationLeadTodoApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyCommunicationLeadTodos(params?: any): Observable<CompanyCommunicationLeadTodo[]> {
    return this.repo.getData<CompanyCommunicationLeadTodo[]>('company-communication-lead-todo', params);
  }

  public getCompanyCommunicationLeadTodo(params: {
    idCompanyCommunicationLeadTodo: number;
    params?: any;
  }): Observable<CompanyCommunicationLeadTodo> {
    return this.repo.getData<CompanyCommunicationLeadTodo>(
      'company-communication-lead-todo/' + params.idCompanyCommunicationLeadTodo,
      params.params
    );
  }

  public addCompanyCommunicationLeadTodo(
    companyCommunicationLeadTodo: Partial<CompanyCommunicationLeadTodo>
  ): Observable<CompanyCommunicationLeadTodo> {
    return this.repo.create<CompanyCommunicationLeadTodo>(
      'company-communication-lead-todo',
      companyCommunicationLeadTodo
    );
  }

  public updateCompanyCommunicationLeadTodo(
    companyCommunicationLeadTodo: Partial<CompanyCommunicationLeadTodo>
  ): Observable<CompanyCommunicationLeadTodo> {
    return this.repo.update('company-communication-lead-todo', companyCommunicationLeadTodo);
  }

  public deleteCompanyCommunicationLeadTodo(idCompanyCommunicationLeadTodo: number): Observable<number> {
    return this.repo.delete('company-communication-lead-todo/' + +idCompanyCommunicationLeadTodo);
  }
}
