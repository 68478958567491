import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationStratalotTodoApiService } from '@_services/api/organization-stratalot-todo-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedOrganizationStratalotTodoEffects } from './organization-stratalot-todo-generated.effects';

@Injectable()
export class OrganizationStratalotTodoEffects extends GeneratedOrganizationStratalotTodoEffects {
  constructor(
    actions$: Actions,
    organizationStratalotTodoApiService: OrganizationStratalotTodoApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationStratalotTodoApiService, store$);
  }
}
