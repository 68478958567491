import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  CompanyCommunicationRecipientMetier,
  CompanyCommunicationRecipientMetierEntityState
} from '@_model/interfaces/company-communication-recipient-metier.model';
import { CompanyCommunicationRecipientMetierRelationsIds } from './company-communication-recipient-metier-generated.effects';

export const getOneCompanyCommunicationRecipientMetier = createAction(
  '[CompanyCommunicationRecipientMetier] Get One CompanyCommunicationRecipientMetier',
  props<{ idCompanyCommunicationRecipientMetier: number; params?: any }>()
);

export const getManyCompanyCommunicationRecipientMetiers = createAction(
  '[CompanyCommunicationRecipientMetier] Get Many CompanyCommunicationRecipientMetiers',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyCommunicationRecipientMetier] Add Many Actives CompanyCommunicationRecipientMetier',
  props<{ idCompanyCommunicationRecipientMetiers: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyCommunicationRecipientMetier] Delete One Active CompanyCommunicationRecipientMetier',
  props<{ idCompanyCommunicationRecipientMetier: number }>()
);

export const clearActive = createAction(
  '[CompanyCommunicationRecipientMetier] Clear Active CompanyCommunicationRecipientMetier'
);

export const upsertOneCompanyCommunicationRecipientMetier = createAction(
  '[CompanyCommunicationRecipientMetier] Upsert One CompanyCommunicationRecipientMetier',
  props<{
    companyCommunicationRecipientMetier: Partial<CompanyCommunicationRecipientMetier>;
    ids?: CompanyCommunicationRecipientMetierRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationRecipientMetiers = createAction(
  '[CompanyCommunicationRecipientMetier] Upsert Many CompanyCommunicationRecipientMetiers',
  props<{
    companyCommunicationRecipientMetiers: Partial<CompanyCommunicationRecipientMetier>[];
    ids?: CompanyCommunicationRecipientMetierRelationsIds;
  }>()
);

export const upsertManyCompanyCommunicationRecipientMetiersSuccess = createAction(
  '[CompanyCommunicationRecipientMetier] Create Many CompanyCommunicationRecipientMetiers Success',
  props<{ companyCommunicationRecipientMetiers: CompanyCommunicationRecipientMetierEntityState[] }>()
);

export const deleteOneCompanyCommunicationRecipientMetier = createAction(
  '[CompanyCommunicationRecipientMetier] Delete One CompanyCommunicationRecipientMetier',
  props<{ idCompanyCommunicationRecipientMetier: number }>()
);

export const deleteOneCompanyCommunicationRecipientMetierSuccess = createAction(
  '[CompanyCommunicationRecipientMetier] Delete One CompanyCommunicationRecipientMetier Success',
  props<{ idCompanyCommunicationRecipientMetier: number }>()
);

export const normalizeManyCompanyCommunicationRecipientMetiersAfterUpsert = createAction(
  '[CompanyCommunicationRecipientMetier] Normalize Many CompanyCommunicationRecipientMetiers After Upsert',
  props<{ companyCommunicationRecipientMetiers: CompanyCommunicationRecipientMetierEntityState[] }>()
);

export const companyCommunicationRecipientMetiersFailure = createAction(
  '[CompanyCommunicationRecipientMetier] CompanyCommunicationRecipientMetiers Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction(
  '[CompanyCommunicationRecipientMetier] Clear CompanyCommunicationRecipientMetiers'
);

export const addCompanyCommunicationRecipientSuccess = createAction(
  '[CompanyCommunicationRecipientMetier] Add CompanyCommunicationRecipient',
  props<{ idCompanyCommunicationRecipientMetier: number; idCompanyCommunicationRecipient: number }>()
);

export const deleteManyCompanyCommunicationRecipientSuccess = createAction(
  '[CompanyCommunicationRecipientMetier] Delete Many CompanyCommunicationRecipient',
  props<{ idCompanyCommunicationRecipients: number[]; idCompanyCommunicationRecipientMetiers: number[] }>()
);

export const addOrganizationMetierSuccess = createAction(
  '[CompanyCommunicationRecipientMetier] Add OrganizationMetier',
  props<{ idCompanyCommunicationRecipientMetier: number; idOrganizationMetier: number }>()
);

export const deleteManyOrganizationMetierSuccess = createAction(
  '[CompanyCommunicationRecipientMetier] Delete Many OrganizationMetier',
  props<{ idOrganizationMetiers: number[]; idCompanyCommunicationRecipientMetiers: number[] }>()
);
