import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationForecastRate } from '@_model/interfaces/organization-forecast-rate.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationForecastRateApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationForecastRates(params?: any): Observable<OrganizationForecastRate[]> {
    return this.repo.getData<OrganizationForecastRate[]>('organization-forecast-rate', params);
  }

  public getOrganizationForecastRate(params: {
    idOrganizationForecastRate: number;
    params?: any;
  }): Observable<OrganizationForecastRate> {
    return this.repo.getData<OrganizationForecastRate>(
      'organization-forecast-rate/' + params.idOrganizationForecastRate,
      params.params
    );
  }

  public addOrganizationForecastRate(
    organizationForecastRate: Partial<OrganizationForecastRate>
  ): Observable<OrganizationForecastRate> {
    return this.repo.create<OrganizationForecastRate>('organization-forecast-rate', organizationForecastRate);
  }

  public updateOrganizationForecastRate(
    organizationForecastRate: Partial<OrganizationForecastRate>
  ): Observable<OrganizationForecastRate> {
    return this.repo.update('organization-forecast-rate', organizationForecastRate);
  }

  public deleteOrganizationForecastRate(idOrganizationForecastRate: number): Observable<number> {
    return this.repo.delete('organization-forecast-rate/' + +idOrganizationForecastRate);
  }
}
