export enum StatutVente {
  notOnSale = 'hors vente',
  onSale = 'en vente',
  sold = 'vendu',
  blocked = 'bloqué'
}

export enum StratalotStatus {
  disponible = 'disponible',
  congé = 'congé',
  travaux = 'sous Travaux',
  offre = 'offre en cours',
  deliberation = 'attente délibération commission',
  occuped = 'occupé'
}
