import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationLeadTodoProfilEntityState } from '@_model/interfaces/organization-lead-todo-profil.model';

export interface OrganizationLeadTodoProfilState extends EntityState<OrganizationLeadTodoProfilEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationLeadTodoProfilEntityState> =
  createEntityAdapter<OrganizationLeadTodoProfilEntityState>({
    selectId: o => o.idOrganizationLeadTodoProfil
  });
export const initialState: OrganizationLeadTodoProfilState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationLeadTodoProfilFeatureKey = 'organizationLeadTodoProfil';
