import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyStudyNextActionEntityState } from '@_model/interfaces/company-study-next-action.model';

export interface CompanyStudyNextActionState extends EntityState<CompanyStudyNextActionEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyStudyNextActionEntityState> =
  createEntityAdapter<CompanyStudyNextActionEntityState>({
    selectId: o => o.idCompanyStudyNextAction
  });
export const initialState: CompanyStudyNextActionState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyStudyNextActionFeatureKey = 'companyStudyNextAction';
