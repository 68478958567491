import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { StratalotType, StratalotTypeEntityState } from '@_model/interfaces/stratalot-type.model';
import { StratalotTypeRelationsIds } from './stratalot-type-generated.effects';

export const getOneStratalotType = createAction(
  '[StratalotType] Get One StratalotType',
  props<{ idStratalotType: number; params?: any }>()
);

export const getManyStratalotTypes = createAction('[StratalotType] Get Many StratalotTypes', props<{ params: any }>());

export const addManyActives = createAction(
  '[StratalotType] Add Many Actives StratalotType',
  props<{ idStratalotTypes: number[] }>()
);

export const deleteOneActive = createAction(
  '[StratalotType] Delete One Active StratalotType',
  props<{ idStratalotType: number }>()
);

export const clearActive = createAction('[StratalotType] Clear Active StratalotType');

export const upsertOneStratalotType = createAction(
  '[StratalotType] Upsert One StratalotType',
  props<{
    stratalotType: Partial<StratalotType>;
    ids?: StratalotTypeRelationsIds;
  }>()
);

export const upsertManyStratalotTypes = createAction(
  '[StratalotType] Upsert Many StratalotTypes',
  props<{
    stratalotTypes: Partial<StratalotType>[];
    ids?: StratalotTypeRelationsIds;
  }>()
);

export const upsertManyStratalotTypesSuccess = createAction(
  '[StratalotType] Create Many StratalotTypes Success',
  props<{ stratalotTypes: StratalotTypeEntityState[] }>()
);

export const deleteOneStratalotType = createAction(
  '[StratalotType] Delete One StratalotType',
  props<{ idStratalotType: number }>()
);

export const deleteOneStratalotTypeSuccess = createAction(
  '[StratalotType] Delete One StratalotType Success',
  props<{ idStratalotType: number }>()
);

export const normalizeManyStratalotTypesAfterUpsert = createAction(
  '[StratalotType] Normalize Many StratalotTypes After Upsert',
  props<{ stratalotTypes: StratalotTypeEntityState[] }>()
);

export const stratalotTypesFailure = createAction(
  '[StratalotType] StratalotTypes Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[StratalotType] Clear StratalotTypes');

export const addManyCompanyStratalotTypeSuccess = createAction(
  '[StratalotType] Add Many company-stratalot-type',
  props<{ idStratalotType: number; idCompanyStratalotTypes: number[] }>()
);

export const deleteManyCompanyStratalotTypeSuccess = createAction(
  '[StratalotType] Delete Many CompanyStratalotTypes',
  props<{ idCompanyStratalotTypes: number[]; idStratalotTypes: number[] }>()
);

export const addManyProspectBuyingWishSuccess = createAction(
  '[StratalotType] Add Many prospect-buying-wish',
  props<{ idStratalotType: number; idProspectBuyingWishs: number[] }>()
);

export const deleteManyProspectBuyingWishSuccess = createAction(
  '[StratalotType] Delete Many ProspectBuyingWishs',
  props<{ idProspectBuyingWishs: number[]; idStratalotTypes: number[] }>()
);

export const addManyOrganizationLeadTodoRuleSuccess = createAction(
  '[StratalotType] Add Many organization-lead-todo-rule',
  props<{ idStratalotType: number; idOrganizationLeadTodoRules: number[] }>()
);

export const deleteManyOrganizationLeadTodoRuleSuccess = createAction(
  '[StratalotType] Delete Many OrganizationLeadTodoRules',
  props<{ idOrganizationLeadTodoRules: number[]; idStratalotTypes: number[] }>()
);

export const addManyOrganizationStratalotTodoRuleSuccess = createAction(
  '[StratalotType] Add Many organization-stratalot-todo-rule',
  props<{ idStratalotType: number; idOrganizationStratalotTodoRules: number[] }>()
);

export const deleteManyOrganizationStratalotTodoRuleSuccess = createAction(
  '[StratalotType] Delete Many OrganizationStratalotTodoRules',
  props<{ idOrganizationStratalotTodoRules: number[]; idStratalotTypes: number[] }>()
);
