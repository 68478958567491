import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidenceForecastEntityState } from '@_model/interfaces/residence-forecast.model';

export interface ResidenceForecastState extends EntityState<ResidenceForecastEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidenceForecastEntityState> = createEntityAdapter<ResidenceForecastEntityState>({
  selectId: o => o.idResidenceForecast
});
export const initialState: ResidenceForecastState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residenceForecastFeatureKey = 'residenceForecast';
