import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CompanyCommunicationMedia,
  CompanyCommunicationMediaEntityState
} from '@_model/interfaces/company-communication-media.model';
import { CompanyCommunication, CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import {
  adapter,
  companyCommunicationMediaFeatureKey,
  CompanyCommunicationMediaState
} from './company-communication-media.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyCommunicationMediaRelations: string[] = ['companyCommunications'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyCommunicationMediaState = createFeatureSelector<CompanyCommunicationMediaState>(
  companyCommunicationMediaFeatureKey
);

export const selectIsLoadedCompanyCommunicationMedia = createSelector(
  selectCompanyCommunicationMediaState,
  (state: CompanyCommunicationMediaState) => state.isLoaded
);

export const selectIsLoadingCompanyCommunicationMedia = createSelector(
  selectCompanyCommunicationMediaState,
  (state: CompanyCommunicationMediaState) => state.isLoading
);

export const selectIsReadyCompanyCommunicationMedia = createSelector(
  selectCompanyCommunicationMediaState,
  (state: CompanyCommunicationMediaState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyCommunicationMedia = createSelector(
  selectCompanyCommunicationMediaState,
  (state: CompanyCommunicationMediaState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyCommunicationMediaModel: SelectorModel = {
  name: 'companyCommunicationMedias',
  getSelector: selectAllCompanyCommunicationMediasDictionary,
  isReady: selectIsReadyCompanyCommunicationMedia
};

export const selectCompanyCommunicationMediasEntities = createSelector(
  selectCompanyCommunicationMediaState,
  selectEntities
);

export const selectCompanyCommunicationMediasArray = createSelector(selectCompanyCommunicationMediaState, selectAll);

export const selectIdCompanyCommunicationMediasActive = createSelector(
  selectCompanyCommunicationMediaState,
  (state: CompanyCommunicationMediaState) => state.actives
);

const companyCommunicationMediasInObject = (
  companyCommunicationMedias: Dictionary<CompanyCommunicationMediaEntityState>
) => ({ companyCommunicationMedias });

const selectCompanyCommunicationMediasEntitiesDictionary = createSelector(
  selectCompanyCommunicationMediasEntities,
  companyCommunicationMediasInObject
);

const selectAllCompanyCommunicationMediasObject = createSelector(
  selectCompanyCommunicationMediasEntities,
  companyCommunicationMedias => {
    return hydrateAll({ companyCommunicationMedias });
  }
);

const selectOneCompanyCommunicationMediaDictionary = (idCompanyCommunicationMedia: number) =>
  createSelector(selectCompanyCommunicationMediasEntities, companyCommunicationMedias => {
    return {
      companyCommunicationMedias: {
        [idCompanyCommunicationMedia]: companyCommunicationMedias[idCompanyCommunicationMedia]
      }
    };
  });

const selectOneCompanyCommunicationMediaDictionaryWithoutChild = (idCompanyCommunicationMedia: number) =>
  createSelector(selectCompanyCommunicationMediasEntities, companyCommunicationMedias => {
    return { companyCommunicationMedia: companyCommunicationMedias[idCompanyCommunicationMedia] };
  });

const selectActiveCompanyCommunicationMediasEntities = createSelector(
  selectIdCompanyCommunicationMediasActive,
  selectCompanyCommunicationMediasEntities,
  (actives: number[], companyCommunicationMedias: Dictionary<CompanyCommunicationMediaEntityState>) =>
    getCompanyCommunicationMediasFromActives(actives, companyCommunicationMedias)
);

function getCompanyCommunicationMediasFromActives(
  actives: number[],
  companyCommunicationMedias: Dictionary<CompanyCommunicationMediaEntityState>
): Dictionary<CompanyCommunicationMediaEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyCommunicationMedias[idActive]) {
      acc[idActive] = companyCommunicationMedias[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyCommunicationMediaEntityState>);
}

const selectAllCompanyCommunicationMediasSelectors: Dictionary<Selector> = {};
export function selectAllCompanyCommunicationMedias(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyCommunicationMedia>(
      schema,
      selectAllCompanyCommunicationMediasSelectors,
      selectCompanyCommunicationMediasEntitiesDictionary,
      getRelationSelectors,
      companyCommunicationMediaRelations,
      hydrateAll,
      'companyCommunicationMedia'
    );
  } else {
    return selectAllCompanyCommunicationMediasObject;
  }
}

export function selectAllCompanyCommunicationMediasDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyCommunicationMedias'
): Selector {
  return createSelector(selectAllCompanyCommunicationMedias(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyCommunicationMediaEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyCommunicationMedias.length; i++) {
      res[customKey][result.companyCommunicationMedias[i].idCompanyCommunicationMedia] =
        result.companyCommunicationMedias[i];
    }
    return res;
  });
}

export function selectOneCompanyCommunicationMedia(
  schema: SelectSchema = {},
  idCompanyCommunicationMedia: number
): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyCommunicationMediaDictionary(idCompanyCommunicationMedia)];
    selectors.push(...getRelationSelectors(schema, companyCommunicationMediaRelations, 'companyCommunicationMedia'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyCommunicationMediaDictionaryWithoutChild(idCompanyCommunicationMedia);
  }
}

export function selectActiveCompanyCommunicationMedias(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyCommunicationMediasEntities, companyCommunicationMedias => ({
      companyCommunicationMedias
    }))
  ];
  selectors.push(...getRelationSelectors(schema, companyCommunicationMediaRelations, 'companyCommunicationMedia'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyCommunicationMedias: Dictionary<CompanyCommunicationMediaEntityState>;
  companyCommunications?: Dictionary<CompanyCommunicationEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): {
  companyCommunicationMedias: (CompanyCommunicationMedia | null)[];
} {
  const { companyCommunicationMedias, companyCommunications } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    companyCommunicationMedias: Object.keys(companyCommunicationMedias).map(idCompanyCommunicationMedia =>
      hydrate(
        companyCommunicationMedias[idCompanyCommunicationMedia] as CompanyCommunicationMediaEntityState,
        companyCommunications
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): {
  companyCommunicationMedia: CompanyCommunicationMediaEntityState | null;
} {
  const { companyCommunicationMedias, companyCommunications } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const companyCommunicationMedia = Object.values(companyCommunicationMedias)[0];
  return {
    companyCommunicationMedia: hydrate(
      companyCommunicationMedia as CompanyCommunicationMediaEntityState,
      companyCommunications
    )
  };
}

function hydrate(
  companyCommunicationMedia: CompanyCommunicationMediaEntityState,
  companyCommunicationEntities?: Dictionary<CompanyCommunicationEntityState>
): CompanyCommunicationMedia | null {
  if (!companyCommunicationMedia) {
    return null;
  }

  const companyCommunicationMediaHydrated: CompanyCommunicationMediaEntityState = { ...companyCommunicationMedia };

  if (companyCommunicationEntities) {
    companyCommunicationMediaHydrated.companyCommunication = companyCommunicationEntities[
      companyCommunicationMedia.companyCommunication as number
    ] as CompanyCommunication;
  } else {
    delete companyCommunicationMediaHydrated.companyCommunication;
  }

  return companyCommunicationMediaHydrated as CompanyCommunicationMedia;
}
