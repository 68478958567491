import { GeneratedDocumentsResidenceState, initialState } from './generated-documents-residence.state';
import { Action, createReducer } from '@ngrx/store';
import { generatedDocumentsResidenceReducersGeneratedFunctions } from './generated-documents-residence-generated.reducer';

const generatedDocumentsResidenceReducersFunctions = [...generatedDocumentsResidenceReducersGeneratedFunctions];

const generatedDocumentsResidenceReducer = createReducer(initialState, ...generatedDocumentsResidenceReducersFunctions);

export function reducer(state: GeneratedDocumentsResidenceState | undefined, action: Action) {
  return generatedDocumentsResidenceReducer(state, action);
}
