import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StratalotPrice, StratalotPriceEntityState } from '@_model/interfaces/stratalot-price.model';
import { CompanyPriceLabel, CompanyPriceLabelEntityState } from '@_model/interfaces/company-price-label.model';
import { StratalotPriceValue, StratalotPriceValueEntityState } from '@_model/interfaces/stratalot-price-value.model';
import { Stratalot, StratalotEntityState } from '@_model/interfaces/stratalot.model';
import { ResidencePriceGrid, ResidencePriceGridEntityState } from '@_model/interfaces/residence-price-grid.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, stratalotPriceFeatureKey, StratalotPriceState } from './stratalot-price.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const stratalotPriceRelations: string[] = [
  'companyPriceLabels',
  'stratalotPriceValues',
  'stratalots',
  'residencePriceGrids'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectStratalotPriceState = createFeatureSelector<StratalotPriceState>(stratalotPriceFeatureKey);

export const selectIsLoadedStratalotPrice = createSelector(
  selectStratalotPriceState,
  (state: StratalotPriceState) => state.isLoaded
);

export const selectIsLoadingStratalotPrice = createSelector(
  selectStratalotPriceState,
  (state: StratalotPriceState) => state.isLoading
);

export const selectIsReadyStratalotPrice = createSelector(
  selectStratalotPriceState,
  (state: StratalotPriceState) => !state.isLoading
);

export const selectIsReadyAndLoadedStratalotPrice = createSelector(
  selectStratalotPriceState,
  (state: StratalotPriceState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const StratalotPriceModel: SelectorModel = {
  name: 'stratalotPrices',
  getSelector: selectAllStratalotPricesDictionary,
  isReady: selectIsReadyStratalotPrice
};

export const selectStratalotPricesEntities = createSelector(selectStratalotPriceState, selectEntities);

export const selectStratalotPricesArray = createSelector(selectStratalotPriceState, selectAll);

export const selectIdStratalotPricesActive = createSelector(
  selectStratalotPriceState,
  (state: StratalotPriceState) => state.actives
);

const stratalotPricesInObject = (stratalotPrices: Dictionary<StratalotPriceEntityState>) => ({ stratalotPrices });

const selectStratalotPricesEntitiesDictionary = createSelector(selectStratalotPricesEntities, stratalotPricesInObject);

const selectAllStratalotPricesObject = createSelector(selectStratalotPricesEntities, stratalotPrices => {
  return hydrateAll({ stratalotPrices });
});

const selectOneStratalotPriceDictionary = (idStratalotPrice: number) =>
  createSelector(selectStratalotPricesEntities, stratalotPrices => {
    return { stratalotPrices: { [idStratalotPrice]: stratalotPrices[idStratalotPrice] } };
  });

const selectOneStratalotPriceDictionaryWithoutChild = (idStratalotPrice: number) =>
  createSelector(selectStratalotPricesEntities, stratalotPrices => {
    return { stratalotPrice: stratalotPrices[idStratalotPrice] };
  });

const selectActiveStratalotPricesEntities = createSelector(
  selectIdStratalotPricesActive,
  selectStratalotPricesEntities,
  (actives: number[], stratalotPrices: Dictionary<StratalotPriceEntityState>) =>
    getStratalotPricesFromActives(actives, stratalotPrices)
);

function getStratalotPricesFromActives(
  actives: number[],
  stratalotPrices: Dictionary<StratalotPriceEntityState>
): Dictionary<StratalotPriceEntityState> {
  return actives.reduce((acc, idActive) => {
    if (stratalotPrices[idActive]) {
      acc[idActive] = stratalotPrices[idActive];
    }
    return acc;
  }, {} as Dictionary<StratalotPriceEntityState>);
}

const selectAllStratalotPricesSelectors: Dictionary<Selector> = {};
export function selectAllStratalotPrices(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<StratalotPrice>(
      schema,
      selectAllStratalotPricesSelectors,
      selectStratalotPricesEntitiesDictionary,
      getRelationSelectors,
      stratalotPriceRelations,
      hydrateAll,
      'stratalotPrice'
    );
  } else {
    return selectAllStratalotPricesObject;
  }
}

export function selectAllStratalotPricesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'stratalotPrices'
): Selector {
  return createSelector(selectAllStratalotPrices(schema), result => {
    const res = { [customKey]: {} as Dictionary<StratalotPriceEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.stratalotPrices.length; i++) {
      res[customKey][result.stratalotPrices[i].idStratalotPrice] = result.stratalotPrices[i];
    }
    return res;
  });
}

export function selectOneStratalotPrice(schema: SelectSchema = {}, idStratalotPrice: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneStratalotPriceDictionary(idStratalotPrice)];
    selectors.push(...getRelationSelectors(schema, stratalotPriceRelations, 'stratalotPrice'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneStratalotPriceDictionaryWithoutChild(idStratalotPrice);
  }
}

export function selectActiveStratalotPrices(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveStratalotPricesEntities, stratalotPrices => ({ stratalotPrices }))
  ];
  selectors.push(...getRelationSelectors(schema, stratalotPriceRelations, 'stratalotPrice'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  stratalotPrices: Dictionary<StratalotPriceEntityState>;
  stratalots?: Dictionary<StratalotEntityState>;
  residencePriceGrids?: Dictionary<ResidencePriceGridEntityState>;
  companyPriceLabels?: Dictionary<CompanyPriceLabelEntityState>;
  stratalotPriceValues?: Dictionary<StratalotPriceValueEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { stratalotPrices: (StratalotPrice | null)[] } {
  const { stratalotPrices, stratalots, residencePriceGrids, companyPriceLabels, stratalotPriceValues } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    stratalotPrices: Object.keys(stratalotPrices).map(idStratalotPrice =>
      hydrate(
        stratalotPrices[idStratalotPrice] as StratalotPriceEntityState,
        stratalots,
        residencePriceGrids,
        companyPriceLabels,
        stratalotPriceValues
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { stratalotPrice: StratalotPriceEntityState | null } {
  const { stratalotPrices, stratalots, residencePriceGrids, companyPriceLabels, stratalotPriceValues } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const stratalotPrice = Object.values(stratalotPrices)[0];
  return {
    stratalotPrice: hydrate(
      stratalotPrice as StratalotPriceEntityState,
      stratalots,
      residencePriceGrids,
      companyPriceLabels,
      stratalotPriceValues
    )
  };
}

function hydrate(
  stratalotPrice: StratalotPriceEntityState,
  stratalotEntities?: Dictionary<StratalotEntityState>,
  residencePriceGridEntities?: Dictionary<ResidencePriceGridEntityState>,
  companyPriceLabelEntities?: Dictionary<CompanyPriceLabelEntityState>,
  stratalotPriceValueEntities?: Dictionary<StratalotPriceValueEntityState>
): StratalotPrice | null {
  if (!stratalotPrice) {
    return null;
  }

  const stratalotPriceHydrated: StratalotPriceEntityState = { ...stratalotPrice };
  if (stratalotEntities) {
    stratalotPriceHydrated.stratalot = stratalotEntities[stratalotPrice.stratalot as number] as Stratalot;
  } else {
    delete stratalotPriceHydrated.stratalot;
  }
  if (residencePriceGridEntities) {
    stratalotPriceHydrated.residencePriceGrid = residencePriceGridEntities[
      stratalotPrice.residencePriceGrid as number
    ] as ResidencePriceGrid;
  } else {
    delete stratalotPriceHydrated.residencePriceGrid;
  }

  if (companyPriceLabelEntities) {
    stratalotPriceHydrated.companyPriceLabels = ((stratalotPriceHydrated.companyPriceLabels as number[]) || []).map(
      id => companyPriceLabelEntities[id]
    ) as CompanyPriceLabel[];
  } else {
    delete stratalotPriceHydrated.companyPriceLabels;
  }

  if (stratalotPriceValueEntities) {
    stratalotPriceHydrated.stratalotPriceValues = ((stratalotPriceHydrated.stratalotPriceValues as number[]) || []).map(
      id => stratalotPriceValueEntities[id]
    ) as StratalotPriceValue[];
  } else {
    delete stratalotPriceHydrated.stratalotPriceValues;
  }

  return stratalotPriceHydrated as StratalotPrice;
}
