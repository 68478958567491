import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Company } from '@_model/interfaces/company.model';
import * as AppState from '@_store/index.reducers';
import { CompanyRelationsIds } from '@_store/company/company-generated.effects';
import * as CompanyAction from '@_store/company/company.actions';
import {
  selectActiveCompanies,
  selectAllCompanies,
  selectIdCompaniesActive,
  selectIsLoadedCompany,
  selectIsLoadingCompany,
  selectIsReadyAndLoadedCompany,
  selectOneCompany
} from '@_store/company/company.selectors';
import { catchApiActions } from '@_utils/http.util';
import { getIsReadySelectors, Selector, SelectSchema } from '@_utils/selector.util';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedCompanyService {
  constructor(protected store$: Store<AppState.AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadedCompany));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadingCompany));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [selectIsReadyAndLoadedCompany as Selector].concat(getIsReadySelectors(schema));
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllCompanies(schema: SelectSchema = {}): Observable<Company[]> {
    return this.store$.pipe(select(selectAllCompanies(schema))).pipe(
      switchMap(({ companies }: { companies: Company[] }) => {
        return this.getReady(schema).pipe(mapTo(companies));
      })
    );
  }

  public selectOneCompany(idCompany: number, schema: SelectSchema = {}): Observable<Company> {
    return this.store$.pipe(select(selectOneCompany(schema, idCompany))).pipe(
      switchMap(({ company }: { company: Company }) => {
        return this.getReady(schema).pipe(mapTo(company));
      })
    );
  }

  public selectAllActiveCompanies(schema: SelectSchema = {}): Observable<Company[]> {
    return this.store$.pipe(select(selectActiveCompanies(schema))).pipe(
      switchMap(({ companies }: { companies: Company[] }) => {
        return this.getReady(schema).pipe(mapTo(companies));
      })
    );
  }

  public selectIdCompaniesActive(): Observable<number[]> {
    return this.store$.pipe(select(selectIdCompaniesActive)).pipe(
      switchMap((idCompanies: number[]) => {
        return this.getReady().pipe(mapTo(idCompanies));
      })
    );
  }

  public getOneCompany(idCompany: number, params: any = {}, getResult?: boolean): void | Observable<Company> {
    this.store$.dispatch(CompanyAction.getOneCompany({ idCompany, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CompanyAction.normalizeManyCompaniesAfterUpsert,
        CompanyAction.companiesFailure,
        true
      );
    }
  }

  public getManyCompanies(params: any = {}, getResult?: boolean): void | Observable<Company[]> {
    this.store$.dispatch(CompanyAction.getManyCompanies({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CompanyAction.normalizeManyCompaniesAfterUpsert,
        CompanyAction.companiesFailure
      );
    }
  }

  public upsertOneCompany(
    company: Partial<Company>,
    ids: CompanyRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Company> {
    this.store$.dispatch(CompanyAction.upsertOneCompany({ company, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CompanyAction.normalizeManyCompaniesAfterUpsert,
        CompanyAction.companiesFailure,
        true
      );
    }
  }

  public deleteOneCompany(idCompany: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(CompanyAction.deleteOneCompany({ idCompany }));
    if (getResult) {
      return catchApiActions(this.actions$, CompanyAction.deleteOneCompanySuccess, CompanyAction.companiesFailure);
    }
  }

  public setActiveCompanies(idCompanies: number[]): void {
    this.store$.dispatch(CompanyAction.clearActive());
    this.store$.dispatch(CompanyAction.addManyActives({ idCompanies }));
  }
}
