export * from './residence-generated.actions';
import { createAction, props } from '@ngrx/store';
import { Residence } from '@_shared/models/interfaces/residence.model';

export const getResidencesByCompanies = createAction(
  '[Residence] Get Residences By Companies',
  props<{ params: any }>()
);

export const deleteManyResidencesByCompany = createAction(
  '[Residence] Delete Many Residences From Company',
  props<{ idCompany: number }>()
);

export const deleteManyResidencesSuccess = createAction(
  '[Residence] Delete Many Residences Success',
  props<{ idResidences: number[] }>()
);

export const getTinyResidenceByCompanies = createAction(
  '[Residence] Get Tiny Residences By Companies',
  props<{ params: any }>()
);

export const getForecastByResidence = createAction(
  '[Residence] Get Forecast By Residence',
  props<{ idResidence: number }>()
);

export const getDashboardResidenceForecast = createAction(
  '[Residence] Get Dashboard Residence Forecast',
  props<{ params: any }>()
);

export const upsertOneResidenceForecast = createAction(
  '[Residence] Upsert One Residence Forecast',
  props<{
    residence: Partial<Residence>;
  }>()
);

export const getResidencesForSales = createAction('[Residence] Get Residences For Sales', props<{ params: any }>());

export const getResidencesOccupants = createAction('[Residence] Get Residences Occupants', props<{ params: any }>());

export const getResidencesMontage = createAction('[Residence] Get Residences Montage', props<{ params: any }>());

export const activateResidenceFromCampaign = createAction(
  '[Residence] Activate one residence from a campaign',
  props<{
    idCompany: number;
    envoiUbiflowDateDebut: Date;
    envoiUbiflowDateFin: Date;
    // envoiUbiflowIdPriceLabel: number;
    idResidence: number;
  }>()
);
