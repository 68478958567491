import { ResidencePriceGridHistoryState, initialState } from './residence-price-grid-history.state';
import { Action, createReducer } from '@ngrx/store';
import { residencePriceGridHistoryReducersGeneratedFunctions } from './residence-price-grid-history-generated.reducer';

const residencePriceGridHistoryReducersFunctions = [...residencePriceGridHistoryReducersGeneratedFunctions];

const residencePriceGridHistoryReducer = createReducer(initialState, ...residencePriceGridHistoryReducersFunctions);

export function reducer(state: ResidencePriceGridHistoryState | undefined, action: Action) {
  return residencePriceGridHistoryReducer(state, action);
}
