import { LicenseManager } from '@ag-grid-enterprise/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '@_environments/environment';
import { AuthService } from '@_services/auth.service';
import { CheckForUpdateService } from '@_services/check-for-update.service';
import { BoardStateService } from '@_services/store/board-state.service';
import { CompanyService } from '@_services/store/company.service';
import { OrganizationService } from '@_services/store/organization.service';
import { ResidenceService } from '@_services/store/residence.service';
import { SaleCategoryFamilyService } from '@_services/store/sale-category-family.service';
import { UserService } from '@_services/store/user.service';
import { ConfirmationComponent } from '@_shared/dumb/modal/confirmation/confirmation.component';
import { ConfirmationEnum } from '@_shared/dumb/modal/confirmation/confirmation.enum';
import { MenuSection } from '@_shared/interfaces/menu-section.interface';
import { Company } from '@_shared/models/interfaces/company.model';
import { Organization } from '@_shared/models/interfaces/organization.model';
import { CompanyStratalotTypeModel } from '@_store/company-stratalot-type/company-stratalot-type.selectors';
import { OrganizationBuyingWishModel } from '@_store/organization-buying-wish/organization-buying-wish-generated.selectors';
import { OrganizationSaleCategoryModel } from '@_store/organization-sale-category/organization-sale-category-generated.selectors';
import { OrganizationThirdPartyModel } from '@_store/organization-third-party/organization-third-party.selectors';
import { CompanyModel } from '@_store/company/company-generated.selectors';
import { ResidenceModel } from '@_store/residence/residence-generated.selectors';
import { StratalotTypeModel } from '@_store/stratalot-type/stratalot-type-generated.selectors';
import { sortByMultipleKey } from '@_utils/common-utils/arrays';
import { ModulePathKeyEnum } from '@_utils/router/path-enum/module-path-key.enum';
import { SaleFamilyCategoryRoutingEnum } from '@_utils/router/path-enum/sale-family-category-routing.enum';
import { UpModulePathEnum } from '@_utils/router/path-enum/up-module-path-enum';
import RouterUtils from '@_utils/router/router.utils';
import SaleFamilyCategoryUtils from '@_utils/router/sale-family-category.util';
import CompanyParamsUtils from '@_utils/up-utils/company-params.utils';
import MenuUtils from '@_utils/up-utils/menu.utils';
import OrganizationParamsUtils from '@_utils/up-utils/organization-params.utils';
import { first, map, tap } from 'rxjs/operators';
import ActiveCompanyUtils from '@_utils/router/active-company.utils';

LicenseManager.setLicenseKey(
  'CompanyName=CELLANCE,LicensedApplication=ouassim_ben_mosbah,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-021580,ExpiryDate=9_November_2022_[v2]_MTY2Nzk1MjAwMDAwMA==253c8c8af561b66e7e575d4fc512d7a4'
);

@UntilDestroy()
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  public sideNavOpened = true;
  public sideNavMode = 'side';
  public menuSections: MenuSection[] = [];
  public qualif = environment.qualif;
  public production = environment.production;
  public development = environment.development;
  public integration = environment.integration;
  public staging = environment.staging;
  public test = environment.test;
  public branch = '';
  public updateApp = false;
  public isUserAdmin: boolean;
  public categorieVentes: SaleFamilyCategoryRoutingEnum[] = [];
  public activeSaleCategorie: SaleFamilyCategoryRoutingEnum;
  public companies: Company[] = [];
  public organization: Organization;

  constructor(
    private organizationService: OrganizationService,
    private saleCategoryfamilyService: SaleCategoryFamilyService,
    private boardStateService: BoardStateService,
    private companyService: CompanyService,
    private authService: AuthService,
    private matDialog: MatDialog,
    private router: Router,
    private swUpdate: SwUpdate,
    private userService: UserService,
    private readonly residenceService: ResidenceService,
    _checkForUpdate: CheckForUpdateService
  ) {}

  public ngOnInit(): void {
    this.checkVersion();

    /**Récupération de la branch déployé sur cette environment*/
    fetch('/assets/app.version')
      .then(async response => (this.branch = await response.text()))
      .catch(() => null);

    this.categorieVentes = SaleFamilyCategoryUtils.categorieVentes;
    this.boardStateService.getManyBoardStates();

    // TODO: re-draw le side menu sur un user update
    // this.userService.userUpdate$

    this.organizationService
      .selectAllOrganizations({
        include: [
          OrganizationSaleCategoryModel,
          OrganizationThirdPartyModel,
          OrganizationBuyingWishModel,
          {
            model: CompanyModel,
            include: [{ model: CompanyStratalotTypeModel, include: [StratalotTypeModel] }, ResidenceModel]
          }
        ]
      })
      .pipe(
        tap(organizations => {
          if (organizations.length === 1) {
            this.organization = organizations[0];
            this.companies = organizations[0].companies.sort(sortByMultipleKey([{ name: 'nom' }]));
            this.setNewOrganizationParams(organizations[0]);
          } else {
            throw new Error('multi Organization Non Gérer');
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
    this.userService
      .userHasUpdate()
      .pipe(
        tap(() => {
          this.setMenu(this.activeSaleCategorie);
        }),
        untilDestroyed(this)
      )
      .subscribe();
    this.isUserAdmin = this.userService.isUserAdmin();
    if (this.userService.userHaveNoAccessPage()) {
      this.router.navigate([`/app/${UpModulePathEnum.noAccess}`]);
    }
  }

  private setNewOrganizationParams(organization: Organization): void {
    OrganizationParamsUtils.setCurrentOrganisationId(organization.idOrganization);
    ActiveCompanyUtils.setActiveCompanies(ActiveCompanyUtils.mapToCompaniesIds(organization.companies));
    CompanyParamsUtils.setCompanyStratalotTypesByCompanies(organization.companies);
    OrganizationParamsUtils.setOrganizationThirdParties(organization.organizationThirdParties);
    OrganizationParamsUtils.setOrganizationSaleCategories(organization.organizationSaleCategories);
    OrganizationParamsUtils.setOrganizationBuyingWishes(organization.organizationBuyingWishes);
    if (!this.activeSaleCategorie) {
      this.saleCategoryfamilyService
        .selectAllFamilySaleCategories()
        .pipe(
          map(saleCategoryFamilies =>
            this.saleCategoryfamilyService.setActiveFamilySaleCategories(
              saleCategoryFamilies
                .filter(s => SaleFamilyCategoryUtils.getActiveSaleCategoryFamilyNameByRoutingEnum().includes(s.libelle))
                .map(s => s.idSaleCategoryFamily)
            )
          ),
          untilDestroyed(this)
        )
        .subscribe();
      if (this.router.url.includes(`/app/${UpModulePathEnum.ancien}/`)) {
        SaleFamilyCategoryUtils.setActiveSaleFamilyCategory(SaleFamilyCategoryRoutingEnum.ancien);
      } else if (this.router.url.includes(`/app/${UpModulePathEnum.neuf}/`)) {
        SaleFamilyCategoryUtils.setActiveSaleFamilyCategory(SaleFamilyCategoryRoutingEnum.neuf);
      } else if (!this.isAdminPath()) {
        throw new Error('Sale category non gérée');
      }
    }
    this.activeSaleCategorie = SaleFamilyCategoryUtils.getActiveSaleFamilyCategory();
    this.setMenu(this.activeSaleCategorie);
  }
  private isAdminPath(): boolean {
    return (
      this.router.url.includes(`/app/${UpModulePathEnum.admin}`) ||
      this.router.url.includes(`/app/${UpModulePathEnum.user}`) ||
      this.router.url.includes(`/app/${UpModulePathEnum.noAccess}`)
    );
  }

  public onCompanyChange(companies: Company[]): void {
    this.companyService.switchCompany(companies);
    this.residenceService.getResidencesByCompanies({ idsCompanies: ActiveCompanyUtils.mapToCompaniesIds(companies) });
  }
  public onSectionChange(): void {
    this.companyService.clearState();
  }

  public onNavigateToLoginPage(): void {
    const dialogRef = this.matDialog.open(ConfirmationComponent, {
      width: '250px',
      data: {
        title: 'Confirmation',
        question: 'Voulez-vous vous déconnecter ?',
        theme: 'danger'
      }
    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result === ConfirmationEnum.valid) {
          this.authService.logout();
          this.router.navigate(['/auth/login']);
        }
      });
  }

  public onCategorieVenteChange(categoryVente: SaleFamilyCategoryRoutingEnum): void {
    SaleFamilyCategoryUtils.setActiveSaleFamilyCategory(categoryVente);
    this.companyService.clearState();
    this.boardStateService.getManyBoardStates();
    this.residenceService.getResidencesByCompanies({
      idsCompanies: ActiveCompanyUtils.mapToCompaniesIds(this.companies)
    });
    this.setMenu(categoryVente);
    this.router.navigateByUrl(RouterUtils.getSaleCategoryUrl(ModulePathKeyEnum.salePlan));
  }

  private filterMenuSection(menus): MenuSection[] {
    return menus
      .map(menu => ({ ...menu }))
      .filter(menu => {
        return !menu.right || this.userService.userHaveRight(menu.right) || menu.children;
      })
      .map(menu => {
        if (menu.children) {
          menu.children = menu.children.filter(
            sousMenu => !sousMenu.right || this.userService.userHaveRight(sousMenu.right)
          );
          if (menu.children.length === 0) {
            return null;
          }
        }
        return menu;
      })
      .filter(it => it);
  }

  private setMenu(categoryVente: SaleFamilyCategoryRoutingEnum): void {
    if (categoryVente === SaleFamilyCategoryRoutingEnum.ancien) {
      this.menuSections = this.filterMenuSection(MenuUtils.existingMenu);
    }
    if (categoryVente === SaleFamilyCategoryRoutingEnum.neuf) {
      this.menuSections = this.filterMenuSection(MenuUtils.newMenu);
    }
    const index = this.menuSections.findIndex(it => it?.label === 'User');
    if (index && this.menuSections[index]?.label) {
      this.menuSections[index].label =
        this.userService.currentUser.firstName + ' ' + this.userService.currentUser.lastName;
    }
  }

  private checkVersion(): void {
    if (this.swUpdate?.isEnabled) {
      this.swUpdate.versionUpdates.pipe(untilDestroyed(this)).subscribe(() => {
        this.updateApp = true;
      });
    }
  }
}
