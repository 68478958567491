import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StratalotTodoApiService } from '@_services/api/stratalot-todo-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedStratalotTodoEffects } from './stratalot-todo-generated.effects';

@Injectable()
export class StratalotTodoEffects extends GeneratedStratalotTodoEffects {
  constructor(actions$: Actions, stratalotTodoApiService: StratalotTodoApiService, store$: Store<AppState>) {
    super(actions$, stratalotTodoApiService, store$);
  }
}
