import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidenceForecastValueEntityState } from '@_model/interfaces/residence-forecast-value.model';

export interface ResidenceForecastValueState extends EntityState<ResidenceForecastValueEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidenceForecastValueEntityState> =
  createEntityAdapter<ResidenceForecastValueEntityState>({
    selectId: o => o.idResidenceForecastValue
  });
export const initialState: ResidenceForecastValueState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residenceForecastValueFeatureKey = 'residenceForecastValue';
