import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ResidenceForecast, ResidenceForecastEntityState } from '@_model/interfaces/residence-forecast.model';
import { ResidenceForecastRelationsIds } from './residence-forecast-generated.effects';

export const getOneResidenceForecast = createAction(
  '[ResidenceForecast] Get One ResidenceForecast',
  props<{ idResidenceForecast: number; params?: any }>()
);

export const getManyResidenceForecasts = createAction(
  '[ResidenceForecast] Get Many ResidenceForecasts',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ResidenceForecast] Add Many Actives ResidenceForecast',
  props<{ idResidenceForecasts: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidenceForecast] Delete One Active ResidenceForecast',
  props<{ idResidenceForecast: number }>()
);

export const clearActive = createAction('[ResidenceForecast] Clear Active ResidenceForecast');

export const upsertOneResidenceForecast = createAction(
  '[ResidenceForecast] Upsert One ResidenceForecast',
  props<{
    residenceForecast: Partial<ResidenceForecast>;
    ids?: ResidenceForecastRelationsIds;
  }>()
);

export const upsertManyResidenceForecasts = createAction(
  '[ResidenceForecast] Upsert Many ResidenceForecasts',
  props<{
    residenceForecasts: Partial<ResidenceForecast>[];
    ids?: ResidenceForecastRelationsIds;
  }>()
);

export const upsertManyResidenceForecastsSuccess = createAction(
  '[ResidenceForecast] Create Many ResidenceForecasts Success',
  props<{ residenceForecasts: ResidenceForecastEntityState[] }>()
);

export const deleteOneResidenceForecast = createAction(
  '[ResidenceForecast] Delete One ResidenceForecast',
  props<{ idResidenceForecast: number }>()
);

export const deleteOneResidenceForecastSuccess = createAction(
  '[ResidenceForecast] Delete One ResidenceForecast Success',
  props<{ idResidenceForecast: number }>()
);

export const normalizeManyResidenceForecastsAfterUpsert = createAction(
  '[ResidenceForecast] Normalize Many ResidenceForecasts After Upsert',
  props<{ residenceForecasts: ResidenceForecastEntityState[] }>()
);

export const residenceForecastsFailure = createAction(
  '[ResidenceForecast] ResidenceForecasts Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidenceForecast] Clear ResidenceForecasts');

export const addManyResidenceForecastValueSuccess = createAction(
  '[ResidenceForecast] Add Many residence-forecast-value',
  props<{ idResidenceForecast: number; idResidenceForecastValues: number[] }>()
);

export const deleteManyResidenceForecastValueSuccess = createAction(
  '[ResidenceForecast] Delete Many ResidenceForecastValues',
  props<{ idResidenceForecastValues: number[]; idResidenceForecasts: number[] }>()
);

export const addResidenceSuccess = createAction(
  '[ResidenceForecast] Add Residence',
  props<{ idResidenceForecast: number; idResidence: number }>()
);

export const deleteManyResidenceSuccess = createAction(
  '[ResidenceForecast] Delete Many Residence',
  props<{ idResidences: number[]; idResidenceForecasts: number[] }>()
);

export const addForecastPeriodSuccess = createAction(
  '[ResidenceForecast] Add ForecastPeriod',
  props<{ idResidenceForecast: number; idForecastPeriod: number }>()
);

export const deleteManyForecastPeriodSuccess = createAction(
  '[ResidenceForecast] Delete Many ForecastPeriod',
  props<{ idForecastPeriods: number[]; idResidenceForecasts: number[] }>()
);
