import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationResidenceTodoAppliedRule,
  OrganizationResidenceTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-residence-todo-applied-rule.model';
import { OrganizationResidenceTodoAppliedRuleRelationsIds } from './organization-residence-todo-applied-rule-generated.effects';

export const getOneOrganizationResidenceTodoAppliedRule = createAction(
  '[OrganizationResidenceTodoAppliedRule] Get One OrganizationResidenceTodoAppliedRule',
  props<{ idOrganizationResidenceTodoAppliedRule: number; params?: any }>()
);

export const getManyOrganizationResidenceTodoAppliedRules = createAction(
  '[OrganizationResidenceTodoAppliedRule] Get Many OrganizationResidenceTodoAppliedRules',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationResidenceTodoAppliedRule] Add Many Actives OrganizationResidenceTodoAppliedRule',
  props<{ idOrganizationResidenceTodoAppliedRules: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationResidenceTodoAppliedRule] Delete One Active OrganizationResidenceTodoAppliedRule',
  props<{ idOrganizationResidenceTodoAppliedRule: number }>()
);

export const clearActive = createAction(
  '[OrganizationResidenceTodoAppliedRule] Clear Active OrganizationResidenceTodoAppliedRule'
);

export const upsertOneOrganizationResidenceTodoAppliedRule = createAction(
  '[OrganizationResidenceTodoAppliedRule] Upsert One OrganizationResidenceTodoAppliedRule',
  props<{
    organizationResidenceTodoAppliedRule: Partial<OrganizationResidenceTodoAppliedRule>;
    ids?: OrganizationResidenceTodoAppliedRuleRelationsIds;
  }>()
);

export const upsertManyOrganizationResidenceTodoAppliedRules = createAction(
  '[OrganizationResidenceTodoAppliedRule] Upsert Many OrganizationResidenceTodoAppliedRules',
  props<{
    organizationResidenceTodoAppliedRules: Partial<OrganizationResidenceTodoAppliedRule>[];
    ids?: OrganizationResidenceTodoAppliedRuleRelationsIds;
  }>()
);

export const upsertManyOrganizationResidenceTodoAppliedRulesSuccess = createAction(
  '[OrganizationResidenceTodoAppliedRule] Create Many OrganizationResidenceTodoAppliedRules Success',
  props<{ organizationResidenceTodoAppliedRules: OrganizationResidenceTodoAppliedRuleEntityState[] }>()
);

export const deleteOneOrganizationResidenceTodoAppliedRule = createAction(
  '[OrganizationResidenceTodoAppliedRule] Delete One OrganizationResidenceTodoAppliedRule',
  props<{ idOrganizationResidenceTodoAppliedRule: number }>()
);

export const deleteOneOrganizationResidenceTodoAppliedRuleSuccess = createAction(
  '[OrganizationResidenceTodoAppliedRule] Delete One OrganizationResidenceTodoAppliedRule Success',
  props<{ idOrganizationResidenceTodoAppliedRule: number }>()
);

export const normalizeManyOrganizationResidenceTodoAppliedRulesAfterUpsert = createAction(
  '[OrganizationResidenceTodoAppliedRule] Normalize Many OrganizationResidenceTodoAppliedRules After Upsert',
  props<{ organizationResidenceTodoAppliedRules: OrganizationResidenceTodoAppliedRuleEntityState[] }>()
);

export const organizationResidenceTodoAppliedRulesFailure = createAction(
  '[OrganizationResidenceTodoAppliedRule] OrganizationResidenceTodoAppliedRules Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction(
  '[OrganizationResidenceTodoAppliedRule] Clear OrganizationResidenceTodoAppliedRules'
);

export const addOrganizationResidenceTodoSuccess = createAction(
  '[OrganizationResidenceTodoAppliedRule] Add OrganizationResidenceTodo',
  props<{ idOrganizationResidenceTodoAppliedRule: number; idOrganizationResidenceTodo: number }>()
);

export const deleteManyOrganizationResidenceTodoSuccess = createAction(
  '[OrganizationResidenceTodoAppliedRule] Delete Many OrganizationResidenceTodo',
  props<{ idOrganizationResidenceTodos: number[]; idOrganizationResidenceTodoAppliedRules: number[] }>()
);

export const addOrganizationResidenceTodoRuleSuccess = createAction(
  '[OrganizationResidenceTodoAppliedRule] Add OrganizationResidenceTodoRule',
  props<{ idOrganizationResidenceTodoAppliedRule: number; idOrganizationResidenceTodoRule: number }>()
);

export const deleteManyOrganizationResidenceTodoRuleSuccess = createAction(
  '[OrganizationResidenceTodoAppliedRule] Delete Many OrganizationResidenceTodoRule',
  props<{ idOrganizationResidenceTodoRules: number[]; idOrganizationResidenceTodoAppliedRules: number[] }>()
);
