import { Actions } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { StepProgress } from '@_model/interfaces/step-progress.model';
import * as AppState from '@_store/index.reducers';
import { StepProgressRelationsIds } from '@_store/step-progress/step-progress-generated.effects';
import * as StepProgressAction from '@_store/step-progress/step-progress.actions';
import {
  selectActiveStepProgresses,
  selectAllStepProgresses,
  selectIdStepProgressesActive,
  selectIsLoadedStepProgress,
  selectIsLoadingStepProgress,
  selectIsReadyAndLoadedStepProgress,
  selectOneStepProgress
} from '@_store/step-progress/step-progress.selectors';
import { catchApiActions } from '@_utils/http.util';
import { getIsReadySelectors, Selector, SelectSchema } from '@_utils/selector.util';
import { combineLatest, Observable } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';

export class GeneratedStepProgressService {
  constructor(protected store$: Store<AppState.AppState>, protected actions$: Actions) {}

  public getLoaded(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadedStepProgress));
  }

  public getLoading(): Observable<boolean> {
    return this.store$.pipe(select(selectIsLoadingStepProgress));
  }

  public getReady(schema: SelectSchema = {}): Observable<boolean> {
    const readySelectors: Selector[] = [selectIsReadyAndLoadedStepProgress as Selector].concat(
      getIsReadySelectors(schema)
    );
    const readyObservables: Observable<boolean>[] = readySelectors.map((selector: Selector) =>
      this.store$.pipe(select(selector))
    );
    return combineLatest(readyObservables).pipe(
      map((values: boolean[]) => values.reduce((acc, curr) => acc && curr), true),
      first((isReady: boolean) => isReady)
    );
  }

  public selectAllStepProgresses(schema: SelectSchema = {}): Observable<StepProgress[]> {
    return this.store$.pipe(select(selectAllStepProgresses(schema))).pipe(
      switchMap(({ stepProgresses }: { stepProgresses: StepProgress[] }) => {
        return this.getReady(schema).pipe(mapTo(stepProgresses));
      })
    );
  }

  public selectOneStepProgress(idStepProgress: number, schema: SelectSchema = {}): Observable<StepProgress> {
    return this.store$.pipe(select(selectOneStepProgress(schema, idStepProgress))).pipe(
      switchMap(({ stepProgress }: { stepProgress: StepProgress }) => {
        return this.getReady(schema).pipe(mapTo(stepProgress));
      })
    );
  }

  public selectAllActiveStepProgresses(schema: SelectSchema = {}): Observable<StepProgress[]> {
    return this.store$.pipe(select(selectActiveStepProgresses(schema))).pipe(
      switchMap(({ stepProgresses }: { stepProgresses: StepProgress[] }) => {
        return this.getReady(schema).pipe(mapTo(stepProgresses));
      })
    );
  }

  public selectIdStepProgressesActive(): Observable<number[]> {
    return this.store$.pipe(select(selectIdStepProgressesActive)).pipe(
      switchMap((idStepProgresses: number[]) => {
        return this.getReady().pipe(mapTo(idStepProgresses));
      })
    );
  }

  public getOneStepProgress(
    idStepProgress: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<StepProgress> {
    this.store$.dispatch(StepProgressAction.getOneStepProgress({ idStepProgress, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        StepProgressAction.normalizeManyStepProgressesAfterUpsert,
        StepProgressAction.stepProgressesFailure,
        true
      );
    }
  }

  public getManyStepProgresses(params: any = {}, getResult?: boolean): void | Observable<StepProgress[]> {
    this.store$.dispatch(StepProgressAction.getManyStepProgresses({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        StepProgressAction.normalizeManyStepProgressesAfterUpsert,
        StepProgressAction.stepProgressesFailure
      );
    }
  }

  public upsertOneStepProgress(
    stepProgress: Partial<StepProgress>,
    ids: StepProgressRelationsIds = {},
    getResult?: boolean
  ): void | Observable<StepProgress> {
    this.store$.dispatch(StepProgressAction.upsertOneStepProgress({ stepProgress, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        StepProgressAction.normalizeManyStepProgressesAfterUpsert,
        StepProgressAction.stepProgressesFailure,
        true
      );
    }
  }

  public deleteOneStepProgress(idStepProgress: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(StepProgressAction.deleteOneStepProgress({ idStepProgress }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        StepProgressAction.deleteOneStepProgressSuccess,
        StepProgressAction.stepProgressesFailure
      );
    }
  }

  public setActiveStepProgresses(idStepProgresses: number[]): void {
    this.store$.dispatch(StepProgressAction.clearActive());
    this.store$.dispatch(StepProgressAction.addManyActives({ idStepProgresses }));
  }
}
