import { initialState, OrganizationMetierState } from './organization-metier.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationMetierReducersGeneratedFunctions } from './organization-metier-generated.reducer';

const organizationMetierReducersFunctions = [...organizationMetierReducersGeneratedFunctions];

const organizationMetierReducer = createReducer(initialState, ...organizationMetierReducersFunctions);

export function reducer(state: OrganizationMetierState | undefined, action: Action) {
  return organizationMetierReducer(state, action);
}
