import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedCompanyCommunicationRecipientApiService } from './company-communication-recipient-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyCommunicationRecipientApiService extends GeneratedCompanyCommunicationRecipientApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }
}
