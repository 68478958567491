import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ResidenceShareRcp, ResidenceShareRcpEntityState } from '@_model/interfaces/residence-share-rcp.model';
import { ResidenceShareRcpRelationsIds } from './residence-share-rcp-generated.effects';

export const getOneResidenceShareRcp = createAction(
  '[ResidenceShareRcp] Get One ResidenceShareRcp',
  props<{ idResidenceShareRcp: number; params?: any }>()
);

export const getManyResidenceShareRcps = createAction(
  '[ResidenceShareRcp] Get Many ResidenceShareRcps',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[ResidenceShareRcp] Add Many Actives ResidenceShareRcp',
  props<{ idResidenceShareRcps: number[] }>()
);

export const deleteOneActive = createAction(
  '[ResidenceShareRcp] Delete One Active ResidenceShareRcp',
  props<{ idResidenceShareRcp: number }>()
);

export const clearActive = createAction('[ResidenceShareRcp] Clear Active ResidenceShareRcp');

export const upsertOneResidenceShareRcp = createAction(
  '[ResidenceShareRcp] Upsert One ResidenceShareRcp',
  props<{
    residenceShareRcp: Partial<ResidenceShareRcp>;
    ids?: ResidenceShareRcpRelationsIds;
  }>()
);

export const upsertManyResidenceShareRcps = createAction(
  '[ResidenceShareRcp] Upsert Many ResidenceShareRcps',
  props<{
    residenceShareRcps: Partial<ResidenceShareRcp>[];
    ids?: ResidenceShareRcpRelationsIds;
  }>()
);

export const upsertManyResidenceShareRcpsSuccess = createAction(
  '[ResidenceShareRcp] Create Many ResidenceShareRcps Success',
  props<{ residenceShareRcps: ResidenceShareRcpEntityState[] }>()
);

export const deleteOneResidenceShareRcp = createAction(
  '[ResidenceShareRcp] Delete One ResidenceShareRcp',
  props<{ idResidenceShareRcp: number }>()
);

export const deleteOneResidenceShareRcpSuccess = createAction(
  '[ResidenceShareRcp] Delete One ResidenceShareRcp Success',
  props<{ idResidenceShareRcp: number }>()
);

export const normalizeManyResidenceShareRcpsAfterUpsert = createAction(
  '[ResidenceShareRcp] Normalize Many ResidenceShareRcps After Upsert',
  props<{ residenceShareRcps: ResidenceShareRcpEntityState[] }>()
);

export const residenceShareRcpsFailure = createAction(
  '[ResidenceShareRcp] ResidenceShareRcps Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[ResidenceShareRcp] Clear ResidenceShareRcps');

export const addStratalotRcpSuccess = createAction(
  '[ResidenceShareRcp] Add StratalotRcp',
  props<{ idResidenceShareRcp: number; idStratalotRcp: number }>()
);

export const deleteManyStratalotRcpSuccess = createAction(
  '[ResidenceShareRcp] Delete Many StratalotRcp',
  props<{ idStratalotRcps: number[]; idResidenceShareRcps: number[] }>()
);
