import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';

export interface CompanyCommunicationState extends EntityState<CompanyCommunicationEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyCommunicationEntityState> =
  createEntityAdapter<CompanyCommunicationEntityState>({
    selectId: o => o.idCompanyCommunication
  });
export const initialState: CompanyCommunicationState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyCommunicationFeatureKey = 'companyCommunication';
