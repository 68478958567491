import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { ResidenceForecast, ResidenceForecastEntityState } from '@_model/interfaces/residence-forecast.model';
import { ResidenceForecastApiService } from '@_services/api/residence-forecast-api.service';
import * as residenceForecastActions from '@_store/residence-forecast/residence-forecast.actions';
import { getActionsToNormalizeResidenceForecast } from '@_config/store/normalization.generated';
import { selectResidenceForecastState } from './residence-forecast-generated.selectors';
import * as residenceActions from '@_store/residence/residence.actions';
import * as forecastPeriodActions from '@_store/forecast-period/forecast-period.actions';
import * as residenceForecastValueActions from '@_store/residence-forecast-value/residence-forecast-value.actions';
import { ResidenceForecastValue } from '@_model/interfaces/residence-forecast-value.model';

export interface ResidenceForecastRelationsIds {
  residenceForecastValues?: number | number[];
  residence?: number;
  forecastPeriod?: number;
}

export function getDefaultAddResidenceForecastActions(
  residenceForecast: ResidenceForecastEntityState,
  ids?: ResidenceForecastRelationsIds
): Action[] {
  const actions: Action[] = [
    residenceForecastActions.normalizeManyResidenceForecastsAfterUpsert({ residenceForecasts: [residenceForecast] })
  ];

  if (ids?.residence) {
    actions.push(
      residenceActions.addManyResidenceForecastSuccess({
        idResidence: ids.residence,
        idResidenceForecasts: [residenceForecast.idResidenceForecast]
      })
    );
    actions.push(
      residenceForecastActions.addResidenceSuccess({
        idResidenceForecast: residenceForecast.idResidenceForecast,
        idResidence: ids.residence
      })
    );
  }

  if (ids?.forecastPeriod) {
    actions.push(
      forecastPeriodActions.addManyResidenceForecastSuccess({
        idForecastPeriod: ids.forecastPeriod,
        idResidenceForecasts: [residenceForecast.idResidenceForecast]
      })
    );
    actions.push(
      residenceForecastActions.addForecastPeriodSuccess({
        idResidenceForecast: residenceForecast.idResidenceForecast,
        idForecastPeriod: ids.forecastPeriod
      })
    );
  }

  if (ids?.residenceForecastValues) {
    if (!Array.isArray(ids.residenceForecastValues)) {
      actions.push(
        residenceForecastValueActions.upsertOneResidenceForecastValue({
          residenceForecastValue: {
            idResidenceForecast: residenceForecast.idResidenceForecast,
            idResidenceForecastValue: ids.residenceForecastValues as number
          } as ResidenceForecastValue
        })
      );
      actions.push(
        residenceForecastActions.addManyResidenceForecastValueSuccess({
          idResidenceForecast: residenceForecast.idResidenceForecast,
          idResidenceForecastValues: [ids.residenceForecastValues as number]
        })
      );
    } else {
      actions.push(
        residenceForecastValueActions.upsertManyResidenceForecastValues({
          residenceForecastValues: (ids.residenceForecastValues as number[]).map(
            (idResidenceForecastValue: number) => ({
              idResidenceForecast: residenceForecast.idResidenceForecast,
              idResidenceForecastValue
            })
          ) as ResidenceForecastValue[]
        })
      );
      actions.push(
        residenceForecastActions.addManyResidenceForecastValueSuccess({
          idResidenceForecast: residenceForecast.idResidenceForecast,
          idResidenceForecastValues: ids.residenceForecastValues as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteResidenceForecastActions(residenceForecast: ResidenceForecastEntityState): Action[] {
  const actions: Action[] = [
    residenceForecastActions.deleteOneResidenceForecastSuccess({
      idResidenceForecast: residenceForecast.idResidenceForecast
    })
  ];

  if (residenceForecast.residence) {
    actions.push(
      residenceActions.deleteManyResidenceForecastSuccess({
        idResidenceForecasts: [residenceForecast.idResidenceForecast],
        idResidences: [residenceForecast.residence as number]
      })
    );
  }

  if (residenceForecast.forecastPeriod) {
    actions.push(
      forecastPeriodActions.deleteManyResidenceForecastSuccess({
        idResidenceForecasts: [residenceForecast.idResidenceForecast],
        idForecastPeriods: [residenceForecast.forecastPeriod as number]
      })
    );
  }

  if (residenceForecast.residenceForecastValues) {
    actions.push(
      residenceForecastValueActions.deleteManyResidenceForecastSuccess({
        idResidenceForecasts: [residenceForecast.idResidenceForecast],
        idResidenceForecastValues: residenceForecast.residenceForecastValues as number[]
      })
    );
  }

  return actions;
}

export class GeneratedResidenceForecastEffects {
  constructor(
    protected actions$: Actions,
    protected residenceForecastApiService: ResidenceForecastApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidenceForecasts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceForecastActions.getManyResidenceForecasts),
      switchMap(({ params }) =>
        this.residenceForecastApiService.getResidenceForecasts(params).pipe(
          map((residenceForecasts: ResidenceForecast[]) => {
            return residenceForecastActions.normalizeManyResidenceForecastsAfterUpsert({ residenceForecasts });
          }),
          catchError(error => of(residenceForecastActions.residenceForecastsFailure({ error })))
        )
      )
    );
  });

  getOneResidenceForecast$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceForecastActions.getOneResidenceForecast),
      switchMap(idResidenceForecast =>
        this.residenceForecastApiService.getResidenceForecast(idResidenceForecast).pipe(
          map((residenceForecast: ResidenceForecast) => {
            return residenceForecastActions.normalizeManyResidenceForecastsAfterUpsert({
              residenceForecasts: [residenceForecast]
            });
          }),
          catchError(error => of(residenceForecastActions.residenceForecastsFailure({ error })))
        )
      )
    );
  });

  upsertOneResidenceForecast$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceForecastActions.upsertOneResidenceForecast),
      concatMap(
        ({
          residenceForecast,
          ids
        }: {
          residenceForecast: Partial<ResidenceForecast>;
          ids?: ResidenceForecastRelationsIds;
        }) => {
          if (residenceForecast.idResidenceForecast) {
            return this.residenceForecastApiService.updateResidenceForecast(residenceForecast).pipe(
              map((residenceForecastReturned: ResidenceForecast) => {
                return residenceForecastActions.normalizeManyResidenceForecastsAfterUpsert({
                  residenceForecasts: [residenceForecastReturned]
                });
              }),
              catchError(error => of(residenceForecastActions.residenceForecastsFailure({ error })))
            );
          } else {
            return this.residenceForecastApiService.addResidenceForecast(residenceForecast).pipe(
              mergeMap((residenceForecastReturned: ResidenceForecast) =>
                getDefaultAddResidenceForecastActions(residenceForecastReturned, ids)
              ),
              catchError(error => of(residenceForecastActions.residenceForecastsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneResidenceForecast$ = createEffect(() => {
    const selectResidenceForecastState$ = this.store$.select(selectResidenceForecastState);
    return this.actions$.pipe(
      ofType(residenceForecastActions.deleteOneResidenceForecast),
      withLatestFrom(selectResidenceForecastState$),
      concatMap(([{ idResidenceForecast }, state]) =>
        this.residenceForecastApiService.deleteResidenceForecast(idResidenceForecast).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidenceForecastActions(
                state.entities[idResidenceForecast] as ResidenceForecastEntityState
              ),
              residenceForecastActions.deleteOneResidenceForecast.type
            )
          ]),
          catchError(error => of(residenceForecastActions.residenceForecastsFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidenceForecastsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceForecastActions.normalizeManyResidenceForecastsAfterUpsert),
      concatMap(({ residenceForecasts }) => {
        const actions: Action[] = getActionsToNormalizeResidenceForecast(residenceForecasts, StoreActionType.upsert);
        return [getMultiAction(actions, '[ResidenceForecast] Normalization After Upsert Success')];
      })
    );
  });
}
