import { CompanyStudyReasonState, initialState } from './company-study-reason.state';
import { Action, createReducer } from '@ngrx/store';
import { companyStudyReasonReducersGeneratedFunctions } from './company-study-reason-generated.reducer';

const companyStudyReasonReducersFunctions = [...companyStudyReasonReducersGeneratedFunctions];

const companyStudyReasonReducer = createReducer(initialState, ...companyStudyReasonReducersFunctions);

export function reducer(state: CompanyStudyReasonState | undefined, action: Action) {
  return companyStudyReasonReducer(state, action);
}
