import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { CompanyApiService } from '@_services/api/company-api.service';
import * as companyActions from '@_store/company/company.actions';
import { getActionsToNormalizeCompany } from '@_config/store/normalization.generated';
import { selectCompanyState } from './company-generated.selectors';
import * as organizationActions from '@_store/organization/organization.actions';
import * as occupantActions from '@_store/occupant/occupant.actions';
import { Occupant } from '@_model/interfaces/occupant.model';
import * as companyMediaActions from '@_store/company-media/company-media.actions';
import { CompanyMedia } from '@_model/interfaces/company-media.model';
import * as cityActions from '@_store/city/city.actions';
import { City } from '@_model/interfaces/city.model';
import * as companyCommunicationActions from '@_store/company-communication/company-communication.actions';
import { CompanyCommunication } from '@_model/interfaces/company-communication.model';
import * as residenceActions from '@_store/residence/residence.actions';
import { Residence } from '@_model/interfaces/residence.model';
import * as companyPriceLabelActions from '@_store/company-price-label/company-price-label.actions';
import { CompanyPriceLabel } from '@_model/interfaces/company-price-label.model';
import * as companyTerritoireActions from '@_store/company-territoire/company-territoire.actions';
import { CompanyTerritoire } from '@_model/interfaces/company-territoire.model';
import * as organizationLeadTodoRuleActions from '@_store/organization-lead-todo-rule/organization-lead-todo-rule.actions';
import { OrganizationLeadTodoRule } from '@_model/interfaces/organization-lead-todo-rule.model';
import * as organizationStratalotTodoRuleActions from '@_store/organization-stratalot-todo-rule/organization-stratalot-todo-rule.actions';
import { OrganizationStratalotTodoRule } from '@_model/interfaces/organization-stratalot-todo-rule.model';
import * as companyStratalotTypeActions from '@_store/company-stratalot-type/company-stratalot-type.actions';
import { CompanyStratalotType } from '@_model/interfaces/company-stratalot-type.model';
import * as organizationResidenceTodoRuleActions from '@_store/organization-residence-todo-rule/organization-residence-todo-rule.actions';
import { OrganizationResidenceTodoRule } from '@_model/interfaces/organization-residence-todo-rule.model';
import * as companyStratalotVacantActions from '@_store/company-stratalot-vacant/company-stratalot-vacant.actions';
import { CompanyStratalotVacant } from '@_model/interfaces/company-stratalot-vacant.model';
import * as generatedDocumentsCompanyActions from '@_store/generated-documents-company/generated-documents-company.actions';
import { GeneratedDocumentsCompany } from '@_model/interfaces/generated-documents-company.model';
import * as generatedDocumentActions from '@_store/generated-document/generated-document.actions';
import { GeneratedDocument } from '@_model/interfaces/generated-document.model';
import * as companyUbiflowActions from '@_store/company-ubiflow/company-ubiflow.actions';
import { CompanyUbiflow } from '@_model/interfaces/company-ubiflow.model';
import * as companyMyNotaryActions from '@_store/company-my-notary/company-my-notary.actions';
import { CompanyMyNotary } from '@_model/interfaces/company-my-notary.model';
import * as companyStudyNextActionActions from '@_store/company-study-next-action/company-study-next-action.actions';
import { CompanyStudyNextAction } from '@_model/interfaces/company-study-next-action.model';
import * as companyStudyConclusionActions from '@_store/company-study-conclusion/company-study-conclusion.actions';
import { CompanyStudyConclusion } from '@_model/interfaces/company-study-conclusion.model';
import * as companyStudyReasonActions from '@_store/company-study-reason/company-study-reason.actions';
import { CompanyStudyReason } from '@_model/interfaces/company-study-reason.model';
import * as companyStudyCriteriaActions from '@_store/company-study-criteria/company-study-criteria.actions';
import { CompanyStudyCriteria } from '@_model/interfaces/company-study-criteria.model';
import * as residenceStudyActions from '@_store/residence-study/residence-study.actions';
import { ResidenceStudy } from '@_model/interfaces/residence-study.model';

export interface CompanyRelationsIds {
  occupants?: number | number[];
  companyMedias?: number | number[];
  cities?: number | number[];
  companyCommunications?: number | number[];
  residences?: number | number[];
  companyPriceLabels?: number | number[];
  companyTerritoires?: number | number[];
  organizationLeadTodoRules?: number | number[];
  organizationStratalotTodoRules?: number | number[];
  companyStratalotTypes?: number | number[];
  organizationResidenceTodoRules?: number | number[];
  companyStratalotVacants?: number | number[];
  generatedDocumentsCompanies?: number | number[];
  generatedDocuments?: number | number[];
  companyUbiflows?: number | number[];
  companyMyNotaries?: number | number[];
  companyStudyNextActions?: number | number[];
  companyStudyConclusions?: number | number[];
  companyStudyReasons?: number | number[];
  companyStudyCriterias?: number | number[];
  residenceStudies?: number | number[];
  organization?: number;
}

export function getDefaultAddCompanyActions(company: CompanyEntityState, ids?: CompanyRelationsIds): Action[] {
  const actions: Action[] = [companyActions.normalizeManyCompaniesAfterUpsert({ companies: [company] })];

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyCompanySuccess({
        idOrganization: ids.organization,
        idCompanies: [company.idCompany]
      })
    );
    actions.push(
      companyActions.addOrganizationSuccess({
        idCompany: company.idCompany,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.occupants) {
    if (!Array.isArray(ids.occupants)) {
      actions.push(
        occupantActions.upsertOneOccupant({
          occupant: {
            idCompany: company.idCompany,
            idOccupant: ids.occupants as number
          } as Occupant
        })
      );
      actions.push(
        companyActions.addManyOccupantSuccess({
          idCompany: company.idCompany,
          idOccupants: [ids.occupants as number]
        })
      );
    } else {
      actions.push(
        occupantActions.upsertManyOccupants({
          occupants: (ids.occupants as number[]).map((idOccupant: number) => ({
            idCompany: company.idCompany,
            idOccupant
          })) as Occupant[]
        })
      );
      actions.push(
        companyActions.addManyOccupantSuccess({
          idCompany: company.idCompany,
          idOccupants: ids.occupants as number[]
        })
      );
    }
  }

  if (ids?.companyMedias) {
    if (!Array.isArray(ids.companyMedias)) {
      actions.push(
        companyMediaActions.upsertOneCompanyMedia({
          companyMedia: {
            idCompany: company.idCompany,
            idCompanyMedia: ids.companyMedias as number
          } as CompanyMedia
        })
      );
      actions.push(
        companyActions.addManyCompanyMediaSuccess({
          idCompany: company.idCompany,
          idCompanyMedias: [ids.companyMedias as number]
        })
      );
    } else {
      actions.push(
        companyMediaActions.upsertManyCompanyMedias({
          companyMedias: (ids.companyMedias as number[]).map((idCompanyMedia: number) => ({
            idCompany: company.idCompany,
            idCompanyMedia
          })) as CompanyMedia[]
        })
      );
      actions.push(
        companyActions.addManyCompanyMediaSuccess({
          idCompany: company.idCompany,
          idCompanyMedias: ids.companyMedias as number[]
        })
      );
    }
  }

  if (ids?.cities) {
    if (!Array.isArray(ids.cities)) {
      actions.push(
        cityActions.upsertOneCity({
          city: {
            idCompany: company.idCompany,
            idCity: ids.cities as number
          } as City
        })
      );
      actions.push(
        companyActions.addManyCitySuccess({
          idCompany: company.idCompany,
          idCities: [ids.cities as number]
        })
      );
    } else {
      actions.push(
        cityActions.upsertManyCities({
          cities: (ids.cities as number[]).map((idCity: number) => ({
            idCompany: company.idCompany,
            idCity
          })) as City[]
        })
      );
      actions.push(
        companyActions.addManyCitySuccess({
          idCompany: company.idCompany,
          idCities: ids.cities as number[]
        })
      );
    }
  }

  if (ids?.companyCommunications) {
    if (!Array.isArray(ids.companyCommunications)) {
      actions.push(
        companyCommunicationActions.upsertOneCompanyCommunication({
          companyCommunication: {
            idCompany: company.idCompany,
            idCompanyCommunication: ids.companyCommunications as number
          } as CompanyCommunication
        })
      );
      actions.push(
        companyActions.addManyCompanyCommunicationSuccess({
          idCompany: company.idCompany,
          idCompanyCommunications: [ids.companyCommunications as number]
        })
      );
    } else {
      actions.push(
        companyCommunicationActions.upsertManyCompanyCommunications({
          companyCommunications: (ids.companyCommunications as number[]).map((idCompanyCommunication: number) => ({
            idCompany: company.idCompany,
            idCompanyCommunication
          })) as CompanyCommunication[]
        })
      );
      actions.push(
        companyActions.addManyCompanyCommunicationSuccess({
          idCompany: company.idCompany,
          idCompanyCommunications: ids.companyCommunications as number[]
        })
      );
    }
  }

  if (ids?.residences) {
    if (!Array.isArray(ids.residences)) {
      actions.push(
        residenceActions.upsertOneResidence({
          residence: {
            idCompany: company.idCompany,
            idResidence: ids.residences as number
          } as Residence
        })
      );
      actions.push(
        companyActions.addManyResidenceSuccess({
          idCompany: company.idCompany,
          idResidences: [ids.residences as number]
        })
      );
    } else {
      actions.push(
        residenceActions.upsertManyResidences({
          residences: (ids.residences as number[]).map((idResidence: number) => ({
            idCompany: company.idCompany,
            idResidence
          })) as Residence[]
        })
      );
      actions.push(
        companyActions.addManyResidenceSuccess({
          idCompany: company.idCompany,
          idResidences: ids.residences as number[]
        })
      );
    }
  }

  if (ids?.companyPriceLabels) {
    if (!Array.isArray(ids.companyPriceLabels)) {
      actions.push(
        companyPriceLabelActions.upsertOneCompanyPriceLabel({
          companyPriceLabel: {
            idCompany: company.idCompany,
            idCompanyPriceLabel: ids.companyPriceLabels as number
          } as CompanyPriceLabel
        })
      );
      actions.push(
        companyActions.addManyCompanyPriceLabelSuccess({
          idCompany: company.idCompany,
          idCompanyPriceLabels: [ids.companyPriceLabels as number]
        })
      );
    } else {
      actions.push(
        companyPriceLabelActions.upsertManyCompanyPriceLabels({
          companyPriceLabels: (ids.companyPriceLabels as number[]).map((idCompanyPriceLabel: number) => ({
            idCompany: company.idCompany,
            idCompanyPriceLabel
          })) as CompanyPriceLabel[]
        })
      );
      actions.push(
        companyActions.addManyCompanyPriceLabelSuccess({
          idCompany: company.idCompany,
          idCompanyPriceLabels: ids.companyPriceLabels as number[]
        })
      );
    }
  }

  if (ids?.companyTerritoires) {
    if (!Array.isArray(ids.companyTerritoires)) {
      actions.push(
        companyTerritoireActions.upsertOneCompanyTerritoire({
          companyTerritoire: {
            idCompany: company.idCompany,
            idCompanyTerritoire: ids.companyTerritoires as number
          } as CompanyTerritoire
        })
      );
      actions.push(
        companyActions.addManyCompanyTerritoireSuccess({
          idCompany: company.idCompany,
          idCompanyTerritoires: [ids.companyTerritoires as number]
        })
      );
    } else {
      actions.push(
        companyTerritoireActions.upsertManyCompanyTerritoires({
          companyTerritoires: (ids.companyTerritoires as number[]).map((idCompanyTerritoire: number) => ({
            idCompany: company.idCompany,
            idCompanyTerritoire
          })) as CompanyTerritoire[]
        })
      );
      actions.push(
        companyActions.addManyCompanyTerritoireSuccess({
          idCompany: company.idCompany,
          idCompanyTerritoires: ids.companyTerritoires as number[]
        })
      );
    }
  }

  if (ids?.organizationLeadTodoRules) {
    if (!Array.isArray(ids.organizationLeadTodoRules)) {
      actions.push(
        organizationLeadTodoRuleActions.upsertOneOrganizationLeadTodoRule({
          organizationLeadTodoRule: {
            idCompany: company.idCompany,
            idOrganizationLeadTodoRule: ids.organizationLeadTodoRules as number
          } as OrganizationLeadTodoRule
        })
      );
      actions.push(
        companyActions.addManyOrganizationLeadTodoRuleSuccess({
          idCompany: company.idCompany,
          idOrganizationLeadTodoRules: [ids.organizationLeadTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationLeadTodoRuleActions.upsertManyOrganizationLeadTodoRules({
          organizationLeadTodoRules: (ids.organizationLeadTodoRules as number[]).map(
            (idOrganizationLeadTodoRule: number) => ({
              idCompany: company.idCompany,
              idOrganizationLeadTodoRule
            })
          ) as OrganizationLeadTodoRule[]
        })
      );
      actions.push(
        companyActions.addManyOrganizationLeadTodoRuleSuccess({
          idCompany: company.idCompany,
          idOrganizationLeadTodoRules: ids.organizationLeadTodoRules as number[]
        })
      );
    }
  }

  if (ids?.organizationStratalotTodoRules) {
    if (!Array.isArray(ids.organizationStratalotTodoRules)) {
      actions.push(
        organizationStratalotTodoRuleActions.upsertOneOrganizationStratalotTodoRule({
          organizationStratalotTodoRule: {
            idCompany: company.idCompany,
            idOrganizationStratalotTodoRule: ids.organizationStratalotTodoRules as number
          } as OrganizationStratalotTodoRule
        })
      );
      actions.push(
        companyActions.addManyOrganizationStratalotTodoRuleSuccess({
          idCompany: company.idCompany,
          idOrganizationStratalotTodoRules: [ids.organizationStratalotTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotTodoRuleActions.upsertManyOrganizationStratalotTodoRules({
          organizationStratalotTodoRules: (ids.organizationStratalotTodoRules as number[]).map(
            (idOrganizationStratalotTodoRule: number) => ({
              idCompany: company.idCompany,
              idOrganizationStratalotTodoRule
            })
          ) as OrganizationStratalotTodoRule[]
        })
      );
      actions.push(
        companyActions.addManyOrganizationStratalotTodoRuleSuccess({
          idCompany: company.idCompany,
          idOrganizationStratalotTodoRules: ids.organizationStratalotTodoRules as number[]
        })
      );
    }
  }

  if (ids?.companyStratalotTypes) {
    if (!Array.isArray(ids.companyStratalotTypes)) {
      actions.push(
        companyStratalotTypeActions.upsertOneCompanyStratalotType({
          companyStratalotType: {
            idCompany: company.idCompany,
            idCompanyStratalotType: ids.companyStratalotTypes as number
          } as CompanyStratalotType
        })
      );
      actions.push(
        companyActions.addManyCompanyStratalotTypeSuccess({
          idCompany: company.idCompany,
          idCompanyStratalotTypes: [ids.companyStratalotTypes as number]
        })
      );
    } else {
      actions.push(
        companyStratalotTypeActions.upsertManyCompanyStratalotTypes({
          companyStratalotTypes: (ids.companyStratalotTypes as number[]).map((idCompanyStratalotType: number) => ({
            idCompany: company.idCompany,
            idCompanyStratalotType
          })) as CompanyStratalotType[]
        })
      );
      actions.push(
        companyActions.addManyCompanyStratalotTypeSuccess({
          idCompany: company.idCompany,
          idCompanyStratalotTypes: ids.companyStratalotTypes as number[]
        })
      );
    }
  }

  if (ids?.organizationResidenceTodoRules) {
    if (!Array.isArray(ids.organizationResidenceTodoRules)) {
      actions.push(
        organizationResidenceTodoRuleActions.upsertOneOrganizationResidenceTodoRule({
          organizationResidenceTodoRule: {
            idCompany: company.idCompany,
            idOrganizationResidenceTodoRule: ids.organizationResidenceTodoRules as number
          } as OrganizationResidenceTodoRule
        })
      );
      actions.push(
        companyActions.addManyOrganizationResidenceTodoRuleSuccess({
          idCompany: company.idCompany,
          idOrganizationResidenceTodoRules: [ids.organizationResidenceTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationResidenceTodoRuleActions.upsertManyOrganizationResidenceTodoRules({
          organizationResidenceTodoRules: (ids.organizationResidenceTodoRules as number[]).map(
            (idOrganizationResidenceTodoRule: number) => ({
              idCompany: company.idCompany,
              idOrganizationResidenceTodoRule
            })
          ) as OrganizationResidenceTodoRule[]
        })
      );
      actions.push(
        companyActions.addManyOrganizationResidenceTodoRuleSuccess({
          idCompany: company.idCompany,
          idOrganizationResidenceTodoRules: ids.organizationResidenceTodoRules as number[]
        })
      );
    }
  }

  if (ids?.companyStratalotVacants) {
    if (!Array.isArray(ids.companyStratalotVacants)) {
      actions.push(
        companyStratalotVacantActions.upsertOneCompanyStratalotVacant({
          companyStratalotVacant: {
            idCompany: company.idCompany,
            idCompanyStratalotVacant: ids.companyStratalotVacants as number
          } as CompanyStratalotVacant
        })
      );
      actions.push(
        companyActions.addManyCompanyStratalotVacantSuccess({
          idCompany: company.idCompany,
          idCompanyStratalotVacants: [ids.companyStratalotVacants as number]
        })
      );
    } else {
      actions.push(
        companyStratalotVacantActions.upsertManyCompanyStratalotVacants({
          companyStratalotVacants: (ids.companyStratalotVacants as number[]).map(
            (idCompanyStratalotVacant: number) => ({
              idCompany: company.idCompany,
              idCompanyStratalotVacant
            })
          ) as CompanyStratalotVacant[]
        })
      );
      actions.push(
        companyActions.addManyCompanyStratalotVacantSuccess({
          idCompany: company.idCompany,
          idCompanyStratalotVacants: ids.companyStratalotVacants as number[]
        })
      );
    }
  }

  if (ids?.generatedDocumentsCompanies) {
    if (!Array.isArray(ids.generatedDocumentsCompanies)) {
      actions.push(
        generatedDocumentsCompanyActions.upsertOneGeneratedDocumentsCompany({
          generatedDocumentsCompany: {
            idCompany: company.idCompany,
            idGeneratedDocumentsCompany: ids.generatedDocumentsCompanies as number
          } as GeneratedDocumentsCompany
        })
      );
      actions.push(
        companyActions.addManyGeneratedDocumentsCompanySuccess({
          idCompany: company.idCompany,
          idGeneratedDocumentsCompanies: [ids.generatedDocumentsCompanies as number]
        })
      );
    } else {
      actions.push(
        generatedDocumentsCompanyActions.upsertManyGeneratedDocumentsCompanies({
          generatedDocumentsCompanies: (ids.generatedDocumentsCompanies as number[]).map(
            (idGeneratedDocumentsCompany: number) => ({
              idCompany: company.idCompany,
              idGeneratedDocumentsCompany
            })
          ) as GeneratedDocumentsCompany[]
        })
      );
      actions.push(
        companyActions.addManyGeneratedDocumentsCompanySuccess({
          idCompany: company.idCompany,
          idGeneratedDocumentsCompanies: ids.generatedDocumentsCompanies as number[]
        })
      );
    }
  }

  if (ids?.generatedDocuments) {
    if (!Array.isArray(ids.generatedDocuments)) {
      actions.push(
        generatedDocumentActions.upsertOneGeneratedDocument({
          generatedDocument: {
            idCompany: company.idCompany,
            idGeneratedDocument: ids.generatedDocuments as number
          } as GeneratedDocument & any
        })
      );
      actions.push(
        companyActions.addManyGeneratedDocumentSuccess({
          idCompany: company.idCompany,
          idGeneratedDocuments: [ids.generatedDocuments as number]
        })
      );
    } else {
      actions.push(
        generatedDocumentActions.upsertManyGeneratedDocuments({
          generatedDocuments: (ids.generatedDocuments as number[]).map((idGeneratedDocument: number) => ({
            idCompany: company.idCompany,
            idGeneratedDocument
          })) as GeneratedDocument[] & any[]
        })
      );
      actions.push(
        companyActions.addManyGeneratedDocumentSuccess({
          idCompany: company.idCompany,
          idGeneratedDocuments: ids.generatedDocuments as number[]
        })
      );
    }
  }

  if (ids?.companyUbiflows) {
    if (!Array.isArray(ids.companyUbiflows)) {
      actions.push(
        companyUbiflowActions.upsertOneCompanyUbiflow({
          companyUbiflow: {
            idCompany: company.idCompany,
            idCompanyUbiflow: ids.companyUbiflows as number
          } as CompanyUbiflow
        })
      );
      actions.push(
        companyActions.addManyCompanyUbiflowSuccess({
          idCompany: company.idCompany,
          idCompanyUbiflows: [ids.companyUbiflows as number]
        })
      );
    } else {
      actions.push(
        companyUbiflowActions.upsertManyCompanyUbiflows({
          companyUbiflows: (ids.companyUbiflows as number[]).map((idCompanyUbiflow: number) => ({
            idCompany: company.idCompany,
            idCompanyUbiflow
          })) as CompanyUbiflow[]
        })
      );
      actions.push(
        companyActions.addManyCompanyUbiflowSuccess({
          idCompany: company.idCompany,
          idCompanyUbiflows: ids.companyUbiflows as number[]
        })
      );
    }
  }

  if (ids?.companyMyNotaries) {
    if (!Array.isArray(ids.companyMyNotaries)) {
      actions.push(
        companyMyNotaryActions.upsertOneCompanyMyNotary({
          companyMyNotary: {
            idCompany: company.idCompany,
            idCompanyMyNotary: ids.companyMyNotaries as number
          } as CompanyMyNotary
        })
      );
      actions.push(
        companyActions.addManyCompanyMyNotarySuccess({
          idCompany: company.idCompany,
          idCompanyMyNotaries: [ids.companyMyNotaries as number]
        })
      );
    } else {
      actions.push(
        companyMyNotaryActions.upsertManyCompanyMyNotaries({
          companyMyNotaries: (ids.companyMyNotaries as number[]).map((idCompanyMyNotary: number) => ({
            idCompany: company.idCompany,
            idCompanyMyNotary
          })) as CompanyMyNotary[]
        })
      );
      actions.push(
        companyActions.addManyCompanyMyNotarySuccess({
          idCompany: company.idCompany,
          idCompanyMyNotaries: ids.companyMyNotaries as number[]
        })
      );
    }
  }

  if (ids?.companyStudyNextActions) {
    if (!Array.isArray(ids.companyStudyNextActions)) {
      actions.push(
        companyStudyNextActionActions.upsertOneCompanyStudyNextAction({
          companyStudyNextAction: {
            idCompany: company.idCompany,
            idCompanyStudyNextAction: ids.companyStudyNextActions as number
          } as CompanyStudyNextAction
        })
      );
      actions.push(
        companyActions.addManyCompanyStudyNextActionSuccess({
          idCompany: company.idCompany,
          idCompanyStudyNextActions: [ids.companyStudyNextActions as number]
        })
      );
    } else {
      actions.push(
        companyStudyNextActionActions.upsertManyCompanyStudyNextActions({
          companyStudyNextActions: (ids.companyStudyNextActions as number[]).map(
            (idCompanyStudyNextAction: number) => ({
              idCompany: company.idCompany,
              idCompanyStudyNextAction
            })
          ) as CompanyStudyNextAction[]
        })
      );
      actions.push(
        companyActions.addManyCompanyStudyNextActionSuccess({
          idCompany: company.idCompany,
          idCompanyStudyNextActions: ids.companyStudyNextActions as number[]
        })
      );
    }
  }

  if (ids?.companyStudyConclusions) {
    if (!Array.isArray(ids.companyStudyConclusions)) {
      actions.push(
        companyStudyConclusionActions.upsertOneCompanyStudyConclusion({
          companyStudyConclusion: {
            idCompany: company.idCompany,
            idCompanyStudyConclusion: ids.companyStudyConclusions as number
          } as CompanyStudyConclusion
        })
      );
      actions.push(
        companyActions.addManyCompanyStudyConclusionSuccess({
          idCompany: company.idCompany,
          idCompanyStudyConclusions: [ids.companyStudyConclusions as number]
        })
      );
    } else {
      actions.push(
        companyStudyConclusionActions.upsertManyCompanyStudyConclusions({
          companyStudyConclusions: (ids.companyStudyConclusions as number[]).map(
            (idCompanyStudyConclusion: number) => ({
              idCompany: company.idCompany,
              idCompanyStudyConclusion
            })
          ) as CompanyStudyConclusion[]
        })
      );
      actions.push(
        companyActions.addManyCompanyStudyConclusionSuccess({
          idCompany: company.idCompany,
          idCompanyStudyConclusions: ids.companyStudyConclusions as number[]
        })
      );
    }
  }

  if (ids?.companyStudyReasons) {
    if (!Array.isArray(ids.companyStudyReasons)) {
      actions.push(
        companyStudyReasonActions.upsertOneCompanyStudyReason({
          companyStudyReason: {
            idCompany: company.idCompany,
            idCompanyStudyReason: ids.companyStudyReasons as number
          } as CompanyStudyReason
        })
      );
      actions.push(
        companyActions.addManyCompanyStudyReasonSuccess({
          idCompany: company.idCompany,
          idCompanyStudyReasons: [ids.companyStudyReasons as number]
        })
      );
    } else {
      actions.push(
        companyStudyReasonActions.upsertManyCompanyStudyReasons({
          companyStudyReasons: (ids.companyStudyReasons as number[]).map((idCompanyStudyReason: number) => ({
            idCompany: company.idCompany,
            idCompanyStudyReason
          })) as CompanyStudyReason[]
        })
      );
      actions.push(
        companyActions.addManyCompanyStudyReasonSuccess({
          idCompany: company.idCompany,
          idCompanyStudyReasons: ids.companyStudyReasons as number[]
        })
      );
    }
  }

  if (ids?.companyStudyCriterias) {
    if (!Array.isArray(ids.companyStudyCriterias)) {
      actions.push(
        companyStudyCriteriaActions.upsertOneCompanyStudyCriteria({
          companyStudyCriteria: {
            idCompany: company.idCompany,
            idCompanyStudyCriteria: ids.companyStudyCriterias as number
          } as CompanyStudyCriteria
        })
      );
      actions.push(
        companyActions.addManyCompanyStudyCriteriaSuccess({
          idCompany: company.idCompany,
          idCompanyStudyCriterias: [ids.companyStudyCriterias as number]
        })
      );
    } else {
      actions.push(
        companyStudyCriteriaActions.upsertManyCompanyStudyCriterias({
          companyStudyCriterias: (ids.companyStudyCriterias as number[]).map((idCompanyStudyCriteria: number) => ({
            idCompany: company.idCompany,
            idCompanyStudyCriteria
          })) as CompanyStudyCriteria[]
        })
      );
      actions.push(
        companyActions.addManyCompanyStudyCriteriaSuccess({
          idCompany: company.idCompany,
          idCompanyStudyCriterias: ids.companyStudyCriterias as number[]
        })
      );
    }
  }

  if (ids?.residenceStudies) {
    if (!Array.isArray(ids.residenceStudies)) {
      actions.push(
        residenceStudyActions.upsertOneResidenceStudy({
          residenceStudy: {
            idCompany: company.idCompany,
            idResidenceStudy: ids.residenceStudies as number
          } as ResidenceStudy
        })
      );
      actions.push(
        companyActions.addManyResidenceStudySuccess({
          idCompany: company.idCompany,
          idResidenceStudies: [ids.residenceStudies as number]
        })
      );
    } else {
      actions.push(
        residenceStudyActions.upsertManyResidenceStudies({
          residenceStudies: (ids.residenceStudies as number[]).map((idResidenceStudy: number) => ({
            idCompany: company.idCompany,
            idResidenceStudy
          })) as ResidenceStudy[]
        })
      );
      actions.push(
        companyActions.addManyResidenceStudySuccess({
          idCompany: company.idCompany,
          idResidenceStudies: ids.residenceStudies as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCompanyActions(company: CompanyEntityState): Action[] {
  const actions: Action[] = [companyActions.deleteOneCompanySuccess({ idCompany: company.idCompany })];

  if (company.organization) {
    actions.push(
      organizationActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idOrganizations: [company.organization as number]
      })
    );
  }

  if (company.occupants) {
    actions.push(
      occupantActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idOccupants: company.occupants as number[]
      })
    );
  }

  if (company.companyMedias) {
    actions.push(
      companyMediaActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idCompanyMedias: company.companyMedias as number[]
      })
    );
  }

  if (company.cities) {
    actions.push(
      cityActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idCities: company.cities as number[]
      })
    );
  }

  if (company.companyCommunications) {
    actions.push(
      companyCommunicationActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idCompanyCommunications: company.companyCommunications as number[]
      })
    );
  }

  if (company.residences) {
    actions.push(
      residenceActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idResidences: company.residences as number[]
      })
    );
  }

  if (company.companyPriceLabels) {
    actions.push(
      companyPriceLabelActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idCompanyPriceLabels: company.companyPriceLabels as number[]
      })
    );
  }

  if (company.companyTerritoires) {
    actions.push(
      companyTerritoireActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idCompanyTerritoires: company.companyTerritoires as number[]
      })
    );
  }

  if (company.organizationLeadTodoRules) {
    actions.push(
      organizationLeadTodoRuleActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idOrganizationLeadTodoRules: company.organizationLeadTodoRules as number[]
      })
    );
  }

  if (company.organizationStratalotTodoRules) {
    actions.push(
      organizationStratalotTodoRuleActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idOrganizationStratalotTodoRules: company.organizationStratalotTodoRules as number[]
      })
    );
  }

  if (company.companyStratalotTypes) {
    actions.push(
      companyStratalotTypeActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idCompanyStratalotTypes: company.companyStratalotTypes as number[]
      })
    );
  }

  if (company.organizationResidenceTodoRules) {
    actions.push(
      organizationResidenceTodoRuleActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idOrganizationResidenceTodoRules: company.organizationResidenceTodoRules as number[]
      })
    );
  }

  if (company.companyStratalotVacants) {
    actions.push(
      companyStratalotVacantActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idCompanyStratalotVacants: company.companyStratalotVacants as number[]
      })
    );
  }

  if (company.generatedDocumentsCompanies) {
    actions.push(
      generatedDocumentsCompanyActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idGeneratedDocumentsCompanies: company.generatedDocumentsCompanies as number[]
      })
    );
  }

  if (company.generatedDocuments) {
    actions.push(
      generatedDocumentActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idGeneratedDocuments: company.generatedDocuments as number[]
      })
    );
  }

  if (company.companyUbiflows) {
    actions.push(
      companyUbiflowActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idCompanyUbiflows: company.companyUbiflows as number[]
      })
    );
  }

  if (company.companyMyNotaries) {
    actions.push(
      companyMyNotaryActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idCompanyMyNotaries: company.companyMyNotaries as number[]
      })
    );
  }

  if (company.companyStudyNextActions) {
    actions.push(
      companyStudyNextActionActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idCompanyStudyNextActions: company.companyStudyNextActions as number[]
      })
    );
  }

  if (company.companyStudyConclusions) {
    actions.push(
      companyStudyConclusionActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idCompanyStudyConclusions: company.companyStudyConclusions as number[]
      })
    );
  }

  if (company.companyStudyReasons) {
    actions.push(
      companyStudyReasonActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idCompanyStudyReasons: company.companyStudyReasons as number[]
      })
    );
  }

  if (company.companyStudyCriterias) {
    actions.push(
      companyStudyCriteriaActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idCompanyStudyCriterias: company.companyStudyCriterias as number[]
      })
    );
  }

  if (company.residenceStudies) {
    actions.push(
      residenceStudyActions.deleteManyCompanySuccess({
        idCompanies: [company.idCompany],
        idResidenceStudies: company.residenceStudies as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyEffects {
  constructor(
    protected actions$: Actions,
    protected companyApiService: CompanyApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyActions.getManyCompanies),
      switchMap(({ params }) =>
        this.companyApiService.getCompanies(params).pipe(
          map((companies: Company[]) => {
            return companyActions.normalizeManyCompaniesAfterUpsert({ companies });
          }),
          catchError(error => of(companyActions.companiesFailure({ error })))
        )
      )
    );
  });

  getOneCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyActions.getOneCompany),
      switchMap(idCompany =>
        this.companyApiService.getCompany(idCompany).pipe(
          map((company: Company) => {
            return companyActions.normalizeManyCompaniesAfterUpsert({ companies: [company] });
          }),
          catchError(error => of(companyActions.companiesFailure({ error })))
        )
      )
    );
  });

  upsertOneCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyActions.upsertOneCompany),
      concatMap(({ company, ids }: { company: Partial<Company>; ids?: CompanyRelationsIds }) => {
        if (company.idCompany) {
          return this.companyApiService.updateCompany(company).pipe(
            map((companyReturned: Company) => {
              return companyActions.normalizeManyCompaniesAfterUpsert({ companies: [companyReturned] });
            }),
            catchError(error => of(companyActions.companiesFailure({ error })))
          );
        } else {
          return this.companyApiService.addCompany(company).pipe(
            mergeMap((companyReturned: Company) => getDefaultAddCompanyActions(companyReturned, ids)),
            catchError(error => of(companyActions.companiesFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneCompany$ = createEffect(() => {
    const selectCompanyState$ = this.store$.select(selectCompanyState);
    return this.actions$.pipe(
      ofType(companyActions.deleteOneCompany),
      withLatestFrom(selectCompanyState$),
      concatMap(([{ idCompany }, state]) =>
        this.companyApiService.deleteCompany(idCompany).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteCompanyActions(state.entities[idCompany] as CompanyEntityState),
              companyActions.deleteOneCompany.type
            )
          ]),
          catchError(error => of(companyActions.companiesFailure({ error })))
        )
      )
    );
  });

  normalizeManyCompaniesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyActions.normalizeManyCompaniesAfterUpsert),
      concatMap(({ companies }) => {
        const actions: Action[] = getActionsToNormalizeCompany(companies, StoreActionType.upsert);
        return [getMultiAction(actions, '[Company] Normalization After Upsert Success')];
      })
    );
  });
}
