import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CompanyTerritoireUserApiService } from '@_services/api/company-territoire-user-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedCompanyTerritoireUserEffects } from './company-territoire-user-generated.effects';

@Injectable()
export class CompanyTerritoireUserEffects extends GeneratedCompanyTerritoireUserEffects {
  constructor(
    actions$: Actions,
    companyTerritoireUserApiService: CompanyTerritoireUserApiService,
    store$: Store<AppState>
  ) {
    super(actions$, companyTerritoireUserApiService, store$);
  }
}
