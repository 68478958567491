import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyTerritoireUserEntityState } from '@_model/interfaces/company-territoire-user.model';

export interface CompanyTerritoireUserState extends EntityState<CompanyTerritoireUserEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyTerritoireUserEntityState> =
  createEntityAdapter<CompanyTerritoireUserEntityState>({
    selectId: o => o.idCompanyTerritoireUser
  });
export const initialState: CompanyTerritoireUserState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyTerritoireUserFeatureKey = 'companyTerritoireUser';
