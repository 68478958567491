import { LeadAvancementEnum } from '@_shared/models/enums/lead-statut-avancement.enum';

export type LeadAvancementGroup = LeadAvancementEnum | LeadAvancementGroupEnum;

export enum LeadAvancementGroupEnum {
  vendu = 'Vendu',
  sansSuite = 'Sans suite',
  // eslint-disable-next-line @typescript-eslint/quotes
  statutIndetermine = "Statut d'avancement indéterminé"
}
