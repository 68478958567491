import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as StepProgressActions from './step-progress.actions';
import { adapter, initialState, StepProgressState } from './step-progress.state';
import { Dictionary } from '@ngrx/entity';
import { StepProgressEntityState } from '@_model/interfaces/step-progress.model';

export const stepProgressReducersGeneratedFunctions: ReducerTypes<StepProgressState, readonly ActionCreator[]>[] = [
  on(StepProgressActions.getOneStepProgress, (state: StepProgressState) => setLoadingsState(state, true)),
  on(StepProgressActions.getManyStepProgresses, (state: StepProgressState) => setLoadingsState(state, true)),
  on(StepProgressActions.upsertOneStepProgress, (state: StepProgressState) => setLoadingsState(state, true)),

  on(StepProgressActions.upsertManyStepProgressesSuccess, (state: StepProgressState, { stepProgresses }) =>
    adapter.upsertMany(stepProgresses, setLoadingsState(state, false))
  ),
  on(StepProgressActions.deleteOneStepProgress, (state: StepProgressState) => setLoadingsState(state, true)),
  on(StepProgressActions.deleteOneStepProgressSuccess, (state: StepProgressState, { idStepProgress }) =>
    adapter.removeOne(idStepProgress, setLoadingsState(state, false))
  ),
  on(StepProgressActions.clearActive, (state: StepProgressState) => ({ ...state, actives: [] })),
  on(StepProgressActions.addManyActives, (state: StepProgressState, { idStepProgresses }) => ({
    ...state,
    actives: state.actives.concat(idStepProgresses)
  })),
  on(StepProgressActions.deleteOneActive, (state: StepProgressState, { idStepProgress }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idStepProgress)
  })),

  on(StepProgressActions.clearStore, () => initialState),

  on(StepProgressActions.addManyStratalotSuccess, (state: StepProgressState, { idStepProgress, idStratalots }) => {
    if (!state.entities[idStepProgress]) {
      return state;
    }
    const stratalots = (state.entities[idStepProgress]?.stratalots as number[]) || [];
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStepProgress]: {
          ...state.entities[idStepProgress],
          stratalots: stratalots.concat(idStratalots.filter(id => stratalots.indexOf(id) < 0))
        }
      }
    };
  }),

  on(StepProgressActions.deleteManyStratalotSuccess, (state: StepProgressState, { idStratalots, idStepProgresses }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idStepProgresses.reduce((entities, idStepProgress) => {
          if (!state.entities[idStepProgress]?.stratalots) {
            return entities;
          }
          entities[idStepProgress] = {
            ...state.entities[idStepProgress],
            stratalots: (state.entities[idStepProgress]?.stratalots as number[])?.filter(
              (idStratalot: number) => !idStratalots.some((id: number) => id === idStratalot)
            )
          } as StepProgressEntityState;
          return entities;
        }, {} as Dictionary<StepProgressEntityState>)
      }
    };
  }),

  on(StepProgressActions.addStepSuccess, (state: StepProgressState, { idStepProgress, idStep }) => {
    if (!state.entities[idStepProgress]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idStepProgress]: {
          ...state.entities[idStepProgress],
          step: idStep
        }
      }
    };
  }),

  on(StepProgressActions.deleteManyStepSuccess, (state: StepProgressState, { idSteps, idStepProgresses }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        ...idStepProgresses.reduce((entities, idStepProgress) => {
          if (!state.entities[idStepProgress]?.step) {
            return entities;
          }
          entities[idStepProgress] = {
            ...state.entities[idStepProgress],
            step: idSteps.some((idStep: number) => idStep === state.entities[idStepProgress]?.step)
              ? undefined
              : state.entities[idStepProgress]?.step
          } as StepProgressEntityState;
          return entities;
        }, {} as Dictionary<StepProgressEntityState>)
      }
    };
  }),

  on(
    StepProgressActions.addStepProgressFamilySuccess,
    (state: StepProgressState, { idStepProgress, idStepProgressFamily }) => {
      if (!state.entities[idStepProgress]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idStepProgress]: {
            ...state.entities[idStepProgress],
            stepProgressFamily: idStepProgressFamily
          }
        }
      };
    }
  ),

  on(
    StepProgressActions.deleteManyStepProgressFamilySuccess,
    (state: StepProgressState, { idStepProgressesFamilies, idStepProgresses }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idStepProgresses.reduce((entities, idStepProgress) => {
            if (!state.entities[idStepProgress]?.stepProgressFamily) {
              return entities;
            }
            entities[idStepProgress] = {
              ...state.entities[idStepProgress],
              stepProgressFamily: idStepProgressesFamilies.some(
                (idStepProgressFamily: number) =>
                  idStepProgressFamily === state.entities[idStepProgress]?.stepProgressFamily
              )
                ? undefined
                : state.entities[idStepProgress]?.stepProgressFamily
            } as StepProgressEntityState;
            return entities;
          }, {} as Dictionary<StepProgressEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: StepProgressState,
  isLoading: boolean,
  isLoaded: boolean = true
): StepProgressState {
  return { ...state, isLoaded, isLoading };
}
