import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { GeneratedDocumentsCompanyApiService } from '@_services/api/generated-documents-company-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedGeneratedDocumentsCompanyEffects } from './generated-documents-company-generated.effects';

@Injectable()
export class GeneratedDocumentsCompanyEffects extends GeneratedGeneratedDocumentsCompanyEffects {
  constructor(
    actions$: Actions,
    generatedDocumentsCompanyApiService: GeneratedDocumentsCompanyApiService,
    store$: Store<AppState>
  ) {
    super(actions$, generatedDocumentsCompanyApiService, store$);
  }
}
