import { RepositoryService } from '@_services/api/repository.service';
import { City } from '@_model/interfaces/city.model';
import { Observable } from 'rxjs';

export class GeneratedCityApiService {
  constructor(protected repo: RepositoryService) {}

  public getCities(params?: any): Observable<City[]> {
    return this.repo.getData<City[]>('city', params);
  }

  public getCity(params: { idCity: number; params?: any }): Observable<City> {
    return this.repo.getData<City>('city/' + params.idCity, params.params);
  }

  public addCity(city: Partial<City>): Observable<City> {
    return this.repo.create<City>('city', city);
  }

  public updateCity(city: Partial<City>): Observable<City> {
    return this.repo.update('city', city);
  }

  public deleteCity(idCity: number): Observable<number> {
    return this.repo.delete('city/' + +idCity);
  }
}
