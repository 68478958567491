import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { OrganizationLeadTodoAppliedRuleEntityState } from '@_model/interfaces/organization-lead-todo-applied-rule.model';

export interface OrganizationLeadTodoAppliedRuleState extends EntityState<OrganizationLeadTodoAppliedRuleEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<OrganizationLeadTodoAppliedRuleEntityState> =
  createEntityAdapter<OrganizationLeadTodoAppliedRuleEntityState>({
    selectId: o => o.idOrganizationLeadTodoAppliedRule
  });
export const initialState: OrganizationLeadTodoAppliedRuleState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const organizationLeadTodoAppliedRuleFeatureKey = 'organizationLeadTodoAppliedRule';
