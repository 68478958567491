import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  CompanyTerritoireUser,
  CompanyTerritoireUserEntityState
} from '@_model/interfaces/company-territoire-user.model';
import { CompanyTerritoireUserRelationsIds } from './company-territoire-user-generated.effects';

export const getOneCompanyTerritoireUser = createAction(
  '[CompanyTerritoireUser] Get One CompanyTerritoireUser',
  props<{ idCompanyTerritoireUser: number; params?: any }>()
);

export const getManyCompanyTerritoireUsers = createAction(
  '[CompanyTerritoireUser] Get Many CompanyTerritoireUsers',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyTerritoireUser] Add Many Actives CompanyTerritoireUser',
  props<{ idCompanyTerritoireUsers: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyTerritoireUser] Delete One Active CompanyTerritoireUser',
  props<{ idCompanyTerritoireUser: number }>()
);

export const clearActive = createAction('[CompanyTerritoireUser] Clear Active CompanyTerritoireUser');

export const upsertOneCompanyTerritoireUser = createAction(
  '[CompanyTerritoireUser] Upsert One CompanyTerritoireUser',
  props<{
    companyTerritoireUser: Partial<CompanyTerritoireUser>;
    ids?: CompanyTerritoireUserRelationsIds;
  }>()
);

export const upsertManyCompanyTerritoireUsers = createAction(
  '[CompanyTerritoireUser] Upsert Many CompanyTerritoireUsers',
  props<{
    companyTerritoireUsers: Partial<CompanyTerritoireUser>[];
    ids?: CompanyTerritoireUserRelationsIds;
  }>()
);

export const upsertManyCompanyTerritoireUsersSuccess = createAction(
  '[CompanyTerritoireUser] Create Many CompanyTerritoireUsers Success',
  props<{ companyTerritoireUsers: CompanyTerritoireUserEntityState[] }>()
);

export const deleteOneCompanyTerritoireUser = createAction(
  '[CompanyTerritoireUser] Delete One CompanyTerritoireUser',
  props<{ idCompanyTerritoireUser: number }>()
);

export const deleteOneCompanyTerritoireUserSuccess = createAction(
  '[CompanyTerritoireUser] Delete One CompanyTerritoireUser Success',
  props<{ idCompanyTerritoireUser: number }>()
);

export const normalizeManyCompanyTerritoireUsersAfterUpsert = createAction(
  '[CompanyTerritoireUser] Normalize Many CompanyTerritoireUsers After Upsert',
  props<{ companyTerritoireUsers: CompanyTerritoireUserEntityState[] }>()
);

export const companyTerritoireUsersFailure = createAction(
  '[CompanyTerritoireUser] CompanyTerritoireUsers Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyTerritoireUser] Clear CompanyTerritoireUsers');

export const addCompanyTerritoireSuccess = createAction(
  '[CompanyTerritoireUser] Add CompanyTerritoire',
  props<{ idCompanyTerritoireUser: number; idCompanyTerritoire: number }>()
);

export const deleteManyCompanyTerritoireSuccess = createAction(
  '[CompanyTerritoireUser] Delete Many CompanyTerritoire',
  props<{ idCompanyTerritoires: number[]; idCompanyTerritoireUsers: number[] }>()
);

export const addUserSuccess = createAction(
  '[CompanyTerritoireUser] Add User',
  props<{ idCompanyTerritoireUser: number; idUser: number }>()
);

export const deleteManyUserSuccess = createAction(
  '[CompanyTerritoireUser] Delete Many User',
  props<{ idUsers: number[]; idCompanyTerritoireUsers: number[] }>()
);
