import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CompanyTerritoireApiService } from '@_services/api/company-territoire-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedCompanyTerritoireEffects } from './company-territoire-generated.effects';

@Injectable()
export class CompanyTerritoireEffects extends GeneratedCompanyTerritoireEffects {
  constructor(actions$: Actions, companyTerritoireApiService: CompanyTerritoireApiService, store$: Store<AppState>) {
    super(actions$, companyTerritoireApiService, store$);
  }
}
