import { StratalotCampaignState, initialState } from './stratalot-campaign.state';
import { Action, createReducer } from '@ngrx/store';
import { stratalotCampaignReducersGeneratedFunctions } from './stratalot-campaign-generated.reducer';

const stratalotCampaignReducersFunctions = [...stratalotCampaignReducersGeneratedFunctions];

const stratalotCampaignReducer = createReducer(initialState, ...stratalotCampaignReducersFunctions);

export function reducer(state: StratalotCampaignState | undefined, action: Action) {
  return stratalotCampaignReducer(state, action);
}
