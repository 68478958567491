import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrganizationLeadTodoAppliedRuleApiService } from '@_services/api/organization-lead-todo-applied-rule-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedOrganizationLeadTodoAppliedRuleEffects } from './organization-lead-todo-applied-rule-generated.effects';

@Injectable()
export class OrganizationLeadTodoAppliedRuleEffects extends GeneratedOrganizationLeadTodoAppliedRuleEffects {
  constructor(
    actions$: Actions,
    organizationLeadTodoAppliedRuleApiService: OrganizationLeadTodoAppliedRuleApiService,
    store$: Store<AppState>
  ) {
    super(actions$, organizationLeadTodoAppliedRuleApiService, store$);
  }
}
