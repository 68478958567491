import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidenceShareEntityState } from '@_model/interfaces/residence-share.model';

export interface ResidenceShareState extends EntityState<ResidenceShareEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidenceShareEntityState> = createEntityAdapter<ResidenceShareEntityState>({
  selectId: o => o.idResidenceShare
});
export const initialState: ResidenceShareState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residenceShareFeatureKey = 'residenceShare';
