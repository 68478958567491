import { StratalotPriceListState, initialState } from './stratalot-price-list.state';
import { Action, createReducer } from '@ngrx/store';
import { stratalotPriceListReducersGeneratedFunctions } from './stratalot-price-list-generated.reducer';

const stratalotPriceListReducersFunctions = [...stratalotPriceListReducersGeneratedFunctions];

const stratalotPriceListReducer = createReducer(initialState, ...stratalotPriceListReducersFunctions);

export function reducer(state: StratalotPriceListState | undefined, action: Action) {
  return stratalotPriceListReducer(state, action);
}
