import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationStratalotTodoAppliedRule,
  OrganizationStratalotTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-stratalot-todo-applied-rule.model';
import { OrganizationStratalotTodoAppliedRuleApiService } from '@_services/api/organization-stratalot-todo-applied-rule-api.service';
import * as organizationStratalotTodoAppliedRuleActions from '@_store/organization-stratalot-todo-applied-rule/organization-stratalot-todo-applied-rule.actions';
import { getActionsToNormalizeOrganizationStratalotTodoAppliedRule } from '@_config/store/normalization.generated';
import { selectOrganizationStratalotTodoAppliedRuleState } from './organization-stratalot-todo-applied-rule-generated.selectors';
import * as organizationStratalotTodoActions from '@_store/organization-stratalot-todo/organization-stratalot-todo.actions';
import * as organizationStratalotTodoRuleActions from '@_store/organization-stratalot-todo-rule/organization-stratalot-todo-rule.actions';

export interface OrganizationStratalotTodoAppliedRuleRelationsIds {
  organizationStratalotTodo?: number;
  organizationStratalotTodoRule?: number;
}

export function getDefaultAddOrganizationStratalotTodoAppliedRuleActions(
  organizationStratalotTodoAppliedRule: OrganizationStratalotTodoAppliedRuleEntityState,
  ids?: OrganizationStratalotTodoAppliedRuleRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationStratalotTodoAppliedRuleActions.normalizeManyOrganizationStratalotTodoAppliedRulesAfterUpsert({
      organizationStratalotTodoAppliedRules: [organizationStratalotTodoAppliedRule]
    })
  ];

  if (ids?.organizationStratalotTodo) {
    actions.push(
      organizationStratalotTodoActions.addManyOrganizationStratalotTodoAppliedRuleSuccess({
        idOrganizationStratalotTodo: ids.organizationStratalotTodo,
        idOrganizationStratalotTodoAppliedRules: [
          organizationStratalotTodoAppliedRule.idOrganizationStratalotTodoAppliedRule
        ]
      })
    );
    actions.push(
      organizationStratalotTodoAppliedRuleActions.addOrganizationStratalotTodoSuccess({
        idOrganizationStratalotTodoAppliedRule:
          organizationStratalotTodoAppliedRule.idOrganizationStratalotTodoAppliedRule,
        idOrganizationStratalotTodo: ids.organizationStratalotTodo
      })
    );
  }

  if (ids?.organizationStratalotTodoRule) {
    actions.push(
      organizationStratalotTodoRuleActions.addManyOrganizationStratalotTodoAppliedRuleSuccess({
        idOrganizationStratalotTodoRule: ids.organizationStratalotTodoRule,
        idOrganizationStratalotTodoAppliedRules: [
          organizationStratalotTodoAppliedRule.idOrganizationStratalotTodoAppliedRule
        ]
      })
    );
    actions.push(
      organizationStratalotTodoAppliedRuleActions.addOrganizationStratalotTodoRuleSuccess({
        idOrganizationStratalotTodoAppliedRule:
          organizationStratalotTodoAppliedRule.idOrganizationStratalotTodoAppliedRule,
        idOrganizationStratalotTodoRule: ids.organizationStratalotTodoRule
      })
    );
  }

  return actions;
}

export function getDefaultDeleteOrganizationStratalotTodoAppliedRuleActions(
  organizationStratalotTodoAppliedRule: OrganizationStratalotTodoAppliedRuleEntityState
): Action[] {
  const actions: Action[] = [
    organizationStratalotTodoAppliedRuleActions.deleteOneOrganizationStratalotTodoAppliedRuleSuccess({
      idOrganizationStratalotTodoAppliedRule:
        organizationStratalotTodoAppliedRule.idOrganizationStratalotTodoAppliedRule
    })
  ];

  if (organizationStratalotTodoAppliedRule.organizationStratalotTodo) {
    actions.push(
      organizationStratalotTodoActions.deleteManyOrganizationStratalotTodoAppliedRuleSuccess({
        idOrganizationStratalotTodoAppliedRules: [
          organizationStratalotTodoAppliedRule.idOrganizationStratalotTodoAppliedRule
        ],
        idOrganizationStratalotTodos: [organizationStratalotTodoAppliedRule.organizationStratalotTodo as number]
      })
    );
  }

  if (organizationStratalotTodoAppliedRule.organizationStratalotTodoRule) {
    actions.push(
      organizationStratalotTodoRuleActions.deleteManyOrganizationStratalotTodoAppliedRuleSuccess({
        idOrganizationStratalotTodoAppliedRules: [
          organizationStratalotTodoAppliedRule.idOrganizationStratalotTodoAppliedRule
        ],
        idOrganizationStratalotTodoRules: [organizationStratalotTodoAppliedRule.organizationStratalotTodoRule as number]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationStratalotTodoAppliedRuleEffects {
  constructor(
    protected actions$: Actions,
    protected organizationStratalotTodoAppliedRuleApiService: OrganizationStratalotTodoAppliedRuleApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationStratalotTodoAppliedRules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotTodoAppliedRuleActions.getManyOrganizationStratalotTodoAppliedRules),
      switchMap(({ params }) =>
        this.organizationStratalotTodoAppliedRuleApiService.getOrganizationStratalotTodoAppliedRules(params).pipe(
          map((organizationStratalotTodoAppliedRules: OrganizationStratalotTodoAppliedRule[]) => {
            return organizationStratalotTodoAppliedRuleActions.normalizeManyOrganizationStratalotTodoAppliedRulesAfterUpsert(
              { organizationStratalotTodoAppliedRules }
            );
          }),
          catchError(error =>
            of(organizationStratalotTodoAppliedRuleActions.organizationStratalotTodoAppliedRulesFailure({ error }))
          )
        )
      )
    );
  });

  getOneOrganizationStratalotTodoAppliedRule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotTodoAppliedRuleActions.getOneOrganizationStratalotTodoAppliedRule),
      switchMap(idOrganizationStratalotTodoAppliedRule =>
        this.organizationStratalotTodoAppliedRuleApiService
          .getOrganizationStratalotTodoAppliedRule(idOrganizationStratalotTodoAppliedRule)
          .pipe(
            map((organizationStratalotTodoAppliedRule: OrganizationStratalotTodoAppliedRule) => {
              return organizationStratalotTodoAppliedRuleActions.normalizeManyOrganizationStratalotTodoAppliedRulesAfterUpsert(
                { organizationStratalotTodoAppliedRules: [organizationStratalotTodoAppliedRule] }
              );
            }),
            catchError(error =>
              of(organizationStratalotTodoAppliedRuleActions.organizationStratalotTodoAppliedRulesFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneOrganizationStratalotTodoAppliedRule$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotTodoAppliedRuleActions.upsertOneOrganizationStratalotTodoAppliedRule),
      concatMap(
        ({
          organizationStratalotTodoAppliedRule,
          ids
        }: {
          organizationStratalotTodoAppliedRule: Partial<OrganizationStratalotTodoAppliedRule>;
          ids?: OrganizationStratalotTodoAppliedRuleRelationsIds;
        }) => {
          if (organizationStratalotTodoAppliedRule.idOrganizationStratalotTodoAppliedRule) {
            return this.organizationStratalotTodoAppliedRuleApiService
              .updateOrganizationStratalotTodoAppliedRule(organizationStratalotTodoAppliedRule)
              .pipe(
                map((organizationStratalotTodoAppliedRuleReturned: OrganizationStratalotTodoAppliedRule) => {
                  return organizationStratalotTodoAppliedRuleActions.normalizeManyOrganizationStratalotTodoAppliedRulesAfterUpsert(
                    { organizationStratalotTodoAppliedRules: [organizationStratalotTodoAppliedRuleReturned] }
                  );
                }),
                catchError(error =>
                  of(
                    organizationStratalotTodoAppliedRuleActions.organizationStratalotTodoAppliedRulesFailure({ error })
                  )
                )
              );
          } else {
            return this.organizationStratalotTodoAppliedRuleApiService
              .addOrganizationStratalotTodoAppliedRule(organizationStratalotTodoAppliedRule)
              .pipe(
                mergeMap((organizationStratalotTodoAppliedRuleReturned: OrganizationStratalotTodoAppliedRule) =>
                  getDefaultAddOrganizationStratalotTodoAppliedRuleActions(
                    organizationStratalotTodoAppliedRuleReturned,
                    ids
                  )
                ),
                catchError(error =>
                  of(
                    organizationStratalotTodoAppliedRuleActions.organizationStratalotTodoAppliedRulesFailure({ error })
                  )
                )
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationStratalotTodoAppliedRule$ = createEffect(() => {
    const selectOrganizationStratalotTodoAppliedRuleState$ = this.store$.select(
      selectOrganizationStratalotTodoAppliedRuleState
    );
    return this.actions$.pipe(
      ofType(organizationStratalotTodoAppliedRuleActions.deleteOneOrganizationStratalotTodoAppliedRule),
      withLatestFrom(selectOrganizationStratalotTodoAppliedRuleState$),
      concatMap(([{ idOrganizationStratalotTodoAppliedRule }, state]) =>
        this.organizationStratalotTodoAppliedRuleApiService
          .deleteOrganizationStratalotTodoAppliedRule(idOrganizationStratalotTodoAppliedRule)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteOrganizationStratalotTodoAppliedRuleActions(
                  state.entities[
                    idOrganizationStratalotTodoAppliedRule
                  ] as OrganizationStratalotTodoAppliedRuleEntityState
                ),
                organizationStratalotTodoAppliedRuleActions.deleteOneOrganizationStratalotTodoAppliedRule.type
              )
            ]),
            catchError(error =>
              of(organizationStratalotTodoAppliedRuleActions.organizationStratalotTodoAppliedRulesFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyOrganizationStratalotTodoAppliedRulesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationStratalotTodoAppliedRuleActions.normalizeManyOrganizationStratalotTodoAppliedRulesAfterUpsert),
      concatMap(({ organizationStratalotTodoAppliedRules }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationStratalotTodoAppliedRule(
          organizationStratalotTodoAppliedRules,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationStratalotTodoAppliedRule] Normalization After Upsert Success')];
      })
    );
  });
}
