import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Diagnostic, DiagnosticEntityState } from '@_model/interfaces/diagnostic.model';
import { DiagnosticRelationsIds } from './diagnostic-generated.effects';

export const getOneDiagnostic = createAction(
  '[Diagnostic] Get One Diagnostic',
  props<{ idDiagnostic: number; params?: any }>()
);

export const getManyDiagnostics = createAction('[Diagnostic] Get Many Diagnostics', props<{ params: any }>());

export const addManyActives = createAction(
  '[Diagnostic] Add Many Actives Diagnostic',
  props<{ idDiagnostics: number[] }>()
);

export const deleteOneActive = createAction(
  '[Diagnostic] Delete One Active Diagnostic',
  props<{ idDiagnostic: number }>()
);

export const clearActive = createAction('[Diagnostic] Clear Active Diagnostic');

export const upsertOneDiagnostic = createAction(
  '[Diagnostic] Upsert One Diagnostic',
  props<{
    diagnostic: Partial<Diagnostic>;
    ids?: DiagnosticRelationsIds;
  }>()
);

export const upsertManyDiagnostics = createAction(
  '[Diagnostic] Upsert Many Diagnostics',
  props<{
    diagnostics: Partial<Diagnostic>[];
    ids?: DiagnosticRelationsIds;
  }>()
);

export const upsertManyDiagnosticsSuccess = createAction(
  '[Diagnostic] Create Many Diagnostics Success',
  props<{ diagnostics: DiagnosticEntityState[] }>()
);

export const deleteOneDiagnostic = createAction(
  '[Diagnostic] Delete One Diagnostic',
  props<{ idDiagnostic: number }>()
);

export const deleteOneDiagnosticSuccess = createAction(
  '[Diagnostic] Delete One Diagnostic Success',
  props<{ idDiagnostic: number }>()
);

export const normalizeManyDiagnosticsAfterUpsert = createAction(
  '[Diagnostic] Normalize Many Diagnostics After Upsert',
  props<{ diagnostics: DiagnosticEntityState[] }>()
);

export const diagnosticsFailure = createAction(
  '[Diagnostic] Diagnostics Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[Diagnostic] Clear Diagnostics');

export const addManyOrganizationStratalotTodoSuccess = createAction(
  '[Diagnostic] Add Many organization-stratalot-todo',
  props<{ idDiagnostic: number; idOrganizationStratalotTodos: number[] }>()
);

export const deleteManyOrganizationStratalotTodoSuccess = createAction(
  '[Diagnostic] Delete Many OrganizationStratalotTodos',
  props<{ idOrganizationStratalotTodos: number[]; idDiagnostics: number[] }>()
);

export const addManyOrganizationResidenceTodoSuccess = createAction(
  '[Diagnostic] Add Many organization-residence-todo',
  props<{ idDiagnostic: number; idOrganizationResidenceTodos: number[] }>()
);

export const deleteManyOrganizationResidenceTodoSuccess = createAction(
  '[Diagnostic] Delete Many OrganizationResidenceTodos',
  props<{ idOrganizationResidenceTodos: number[]; idDiagnostics: number[] }>()
);
