import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyCommunication, CompanyCommunicationEntityState } from '@_model/interfaces/company-communication.model';
import {
  OrganizationResidenceTodo,
  OrganizationResidenceTodoEntityState
} from '@_model/interfaces/organization-residence-todo.model';
import {
  CompanyCommunicationResidenceTodo,
  CompanyCommunicationResidenceTodoEntityState
} from '@_model/interfaces/company-communication-residence-todo.model';
import {
  OrganizationStratalotTodo,
  OrganizationStratalotTodoEntityState
} from '@_model/interfaces/organization-stratalot-todo.model';
import {
  CompanyCommunicationStratalotTodo,
  CompanyCommunicationStratalotTodoEntityState
} from '@_model/interfaces/company-communication-stratalot-todo.model';
import { OrganizationLeadTodo, OrganizationLeadTodoEntityState } from '@_model/interfaces/organization-lead-todo.model';
import {
  CompanyCommunicationLeadTodo,
  CompanyCommunicationLeadTodoEntityState
} from '@_model/interfaces/company-communication-lead-todo.model';
import { GeneratedDocument, GeneratedDocumentEntityState } from '@_model/interfaces/generated-document.model';
import {
  CompanyCommunicationGeneratedDocument,
  CompanyCommunicationGeneratedDocumentEntityState
} from '@_model/interfaces/company-communication-generated-document.model';
import {
  CompanyCommunicationFile,
  CompanyCommunicationFileEntityState
} from '@_model/interfaces/company-communication-file.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import {
  CompanyCommunicationRecipient,
  CompanyCommunicationRecipientEntityState
} from '@_model/interfaces/company-communication-recipient.model';
import {
  CompanyCommunicationMedia,
  CompanyCommunicationMediaEntityState
} from '@_model/interfaces/company-communication-media.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, companyCommunicationFeatureKey, CompanyCommunicationState } from './company-communication.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyCommunicationRelations: string[] = [
  'organizationResidenceTodos',
  'companyCommunicationResidenceTodos',
  'organizationStratalotTodos',
  'companyCommunicationStratalotTodos',
  'organizationLeadTodos',
  'companyCommunicationLeadTodos',
  'generatedDocuments',
  'companyCommunicationGeneratedDocuments',
  'companyCommunicationFiles',
  'companies',
  'companyCommunicationRecipients',
  'companyCommunicationMedias'
];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyCommunicationState =
  createFeatureSelector<CompanyCommunicationState>(companyCommunicationFeatureKey);

export const selectIsLoadedCompanyCommunication = createSelector(
  selectCompanyCommunicationState,
  (state: CompanyCommunicationState) => state.isLoaded
);

export const selectIsLoadingCompanyCommunication = createSelector(
  selectCompanyCommunicationState,
  (state: CompanyCommunicationState) => state.isLoading
);

export const selectIsReadyCompanyCommunication = createSelector(
  selectCompanyCommunicationState,
  (state: CompanyCommunicationState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyCommunication = createSelector(
  selectCompanyCommunicationState,
  (state: CompanyCommunicationState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyCommunicationModel: SelectorModel = {
  name: 'companyCommunications',
  getSelector: selectAllCompanyCommunicationsDictionary,
  isReady: selectIsReadyCompanyCommunication
};

export const selectCompanyCommunicationsEntities = createSelector(selectCompanyCommunicationState, selectEntities);

export const selectCompanyCommunicationsArray = createSelector(selectCompanyCommunicationState, selectAll);

export const selectIdCompanyCommunicationsActive = createSelector(
  selectCompanyCommunicationState,
  (state: CompanyCommunicationState) => state.actives
);

const companyCommunicationsInObject = (companyCommunications: Dictionary<CompanyCommunicationEntityState>) => ({
  companyCommunications
});

const selectCompanyCommunicationsEntitiesDictionary = createSelector(
  selectCompanyCommunicationsEntities,
  companyCommunicationsInObject
);

const selectAllCompanyCommunicationsObject = createSelector(
  selectCompanyCommunicationsEntities,
  companyCommunications => {
    return hydrateAll({ companyCommunications });
  }
);

const selectOneCompanyCommunicationDictionary = (idCompanyCommunication: number) =>
  createSelector(selectCompanyCommunicationsEntities, companyCommunications => {
    return { companyCommunications: { [idCompanyCommunication]: companyCommunications[idCompanyCommunication] } };
  });

const selectOneCompanyCommunicationDictionaryWithoutChild = (idCompanyCommunication: number) =>
  createSelector(selectCompanyCommunicationsEntities, companyCommunications => {
    return { companyCommunication: companyCommunications[idCompanyCommunication] };
  });

const selectActiveCompanyCommunicationsEntities = createSelector(
  selectIdCompanyCommunicationsActive,
  selectCompanyCommunicationsEntities,
  (actives: number[], companyCommunications: Dictionary<CompanyCommunicationEntityState>) =>
    getCompanyCommunicationsFromActives(actives, companyCommunications)
);

function getCompanyCommunicationsFromActives(
  actives: number[],
  companyCommunications: Dictionary<CompanyCommunicationEntityState>
): Dictionary<CompanyCommunicationEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyCommunications[idActive]) {
      acc[idActive] = companyCommunications[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyCommunicationEntityState>);
}

const selectAllCompanyCommunicationsSelectors: Dictionary<Selector> = {};
export function selectAllCompanyCommunications(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyCommunication>(
      schema,
      selectAllCompanyCommunicationsSelectors,
      selectCompanyCommunicationsEntitiesDictionary,
      getRelationSelectors,
      companyCommunicationRelations,
      hydrateAll,
      'companyCommunication'
    );
  } else {
    return selectAllCompanyCommunicationsObject;
  }
}

export function selectAllCompanyCommunicationsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyCommunications'
): Selector {
  return createSelector(selectAllCompanyCommunications(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyCommunicationEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyCommunications.length; i++) {
      res[customKey][result.companyCommunications[i].idCompanyCommunication] = result.companyCommunications[i];
    }
    return res;
  });
}

export function selectOneCompanyCommunication(schema: SelectSchema = {}, idCompanyCommunication: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyCommunicationDictionary(idCompanyCommunication)];
    selectors.push(...getRelationSelectors(schema, companyCommunicationRelations, 'companyCommunication'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyCommunicationDictionaryWithoutChild(idCompanyCommunication);
  }
}

export function selectActiveCompanyCommunications(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyCommunicationsEntities, companyCommunications => ({ companyCommunications }))
  ];
  selectors.push(...getRelationSelectors(schema, companyCommunicationRelations, 'companyCommunication'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyCommunications: Dictionary<CompanyCommunicationEntityState>;
  companies?: Dictionary<CompanyEntityState>;
  companyCommunicationRecipients?: Dictionary<CompanyCommunicationRecipientEntityState>;
  companyCommunicationMedias?: Dictionary<CompanyCommunicationMediaEntityState>;
  organizationResidenceTodos?: Dictionary<OrganizationResidenceTodoEntityState>;
  companyCommunicationResidenceTodos?: Dictionary<CompanyCommunicationResidenceTodoEntityState>;
  organizationStratalotTodos?: Dictionary<OrganizationStratalotTodoEntityState>;
  companyCommunicationStratalotTodos?: Dictionary<CompanyCommunicationStratalotTodoEntityState>;
  organizationLeadTodos?: Dictionary<OrganizationLeadTodoEntityState>;
  companyCommunicationLeadTodos?: Dictionary<CompanyCommunicationLeadTodoEntityState>;
  generatedDocuments?: Dictionary<GeneratedDocumentEntityState>;
  companyCommunicationGeneratedDocuments?: Dictionary<CompanyCommunicationGeneratedDocumentEntityState>;
  companyCommunicationFiles?: Dictionary<CompanyCommunicationFileEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { companyCommunications: (CompanyCommunication | null)[] } {
  const {
    companyCommunications,
    companies,
    companyCommunicationRecipients,
    companyCommunicationMedias,
    organizationResidenceTodos,
    companyCommunicationResidenceTodos,
    organizationStratalotTodos,
    companyCommunicationStratalotTodos,
    organizationLeadTodos,
    companyCommunicationLeadTodos,
    generatedDocuments,
    companyCommunicationGeneratedDocuments,
    companyCommunicationFiles
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    companyCommunications: Object.keys(companyCommunications).map(idCompanyCommunication =>
      hydrate(
        companyCommunications[idCompanyCommunication] as CompanyCommunicationEntityState,
        companies,
        companyCommunicationRecipients,
        companyCommunicationMedias,
        organizationResidenceTodos,
        companyCommunicationResidenceTodos,
        organizationStratalotTodos,
        companyCommunicationStratalotTodos,
        organizationLeadTodos,
        companyCommunicationLeadTodos,
        generatedDocuments,
        companyCommunicationGeneratedDocuments,
        companyCommunicationFiles
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { companyCommunication: CompanyCommunicationEntityState | null } {
  const {
    companyCommunications,
    companies,
    companyCommunicationRecipients,
    companyCommunicationMedias,
    organizationResidenceTodos,
    companyCommunicationResidenceTodos,
    organizationStratalotTodos,
    companyCommunicationStratalotTodos,
    organizationLeadTodos,
    companyCommunicationLeadTodos,
    generatedDocuments,
    companyCommunicationGeneratedDocuments,
    companyCommunicationFiles
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const companyCommunication = Object.values(companyCommunications)[0];
  return {
    companyCommunication: hydrate(
      companyCommunication as CompanyCommunicationEntityState,
      companies,
      companyCommunicationRecipients,
      companyCommunicationMedias,
      organizationResidenceTodos,
      companyCommunicationResidenceTodos,
      organizationStratalotTodos,
      companyCommunicationStratalotTodos,
      organizationLeadTodos,
      companyCommunicationLeadTodos,
      generatedDocuments,
      companyCommunicationGeneratedDocuments,
      companyCommunicationFiles
    )
  };
}

function hydrate(
  companyCommunication: CompanyCommunicationEntityState,
  companyEntities?: Dictionary<CompanyEntityState>,
  companyCommunicationRecipientEntities?: Dictionary<CompanyCommunicationRecipientEntityState>,
  companyCommunicationMediaEntities?: Dictionary<CompanyCommunicationMediaEntityState>,
  organizationResidenceTodoEntities?: Dictionary<OrganizationResidenceTodoEntityState>,
  companyCommunicationResidenceTodoEntities?: Dictionary<CompanyCommunicationResidenceTodoEntityState>,
  organizationStratalotTodoEntities?: Dictionary<OrganizationStratalotTodoEntityState>,
  companyCommunicationStratalotTodoEntities?: Dictionary<CompanyCommunicationStratalotTodoEntityState>,
  organizationLeadTodoEntities?: Dictionary<OrganizationLeadTodoEntityState>,
  companyCommunicationLeadTodoEntities?: Dictionary<CompanyCommunicationLeadTodoEntityState>,
  generatedDocumentEntities?: Dictionary<GeneratedDocumentEntityState>,
  companyCommunicationGeneratedDocumentEntities?: Dictionary<CompanyCommunicationGeneratedDocumentEntityState>,
  companyCommunicationFileEntities?: Dictionary<CompanyCommunicationFileEntityState>
): CompanyCommunication | null {
  if (!companyCommunication) {
    return null;
  }

  const companyCommunicationHydrated: CompanyCommunicationEntityState = { ...companyCommunication };
  if (companyEntities) {
    companyCommunicationHydrated.company = companyEntities[companyCommunication.company as number] as Company;
  } else {
    delete companyCommunicationHydrated.company;
  }
  if (companyCommunicationRecipientEntities) {
    companyCommunicationHydrated.companyCommunicationRecipient = companyCommunicationRecipientEntities[
      companyCommunication.companyCommunicationRecipient as number
    ] as CompanyCommunicationRecipient;
  } else {
    delete companyCommunicationHydrated.companyCommunicationRecipient;
  }
  if (companyCommunicationMediaEntities) {
    companyCommunicationHydrated.companyCommunicationMedia = companyCommunicationMediaEntities[
      companyCommunication.companyCommunicationMedia as number
    ] as CompanyCommunicationMedia;
  } else {
    delete companyCommunicationHydrated.companyCommunicationMedia;
  }

  if (organizationResidenceTodoEntities) {
    companyCommunicationHydrated.organizationResidenceTodos = (
      (companyCommunicationHydrated.organizationResidenceTodos as number[]) || []
    ).map(id => organizationResidenceTodoEntities[id]) as OrganizationResidenceTodo[];
  } else {
    delete companyCommunicationHydrated.organizationResidenceTodos;
  }

  if (companyCommunicationResidenceTodoEntities) {
    companyCommunicationHydrated.companyCommunicationResidenceTodos = (
      (companyCommunicationHydrated.companyCommunicationResidenceTodos as number[]) || []
    ).map(id => companyCommunicationResidenceTodoEntities[id]) as CompanyCommunicationResidenceTodo[];
  } else {
    delete companyCommunicationHydrated.companyCommunicationResidenceTodos;
  }

  if (organizationStratalotTodoEntities) {
    companyCommunicationHydrated.organizationStratalotTodos = (
      (companyCommunicationHydrated.organizationStratalotTodos as number[]) || []
    ).map(id => organizationStratalotTodoEntities[id]) as OrganizationStratalotTodo[];
  } else {
    delete companyCommunicationHydrated.organizationStratalotTodos;
  }

  if (companyCommunicationStratalotTodoEntities) {
    companyCommunicationHydrated.companyCommunicationStratalotTodos = (
      (companyCommunicationHydrated.companyCommunicationStratalotTodos as number[]) || []
    ).map(id => companyCommunicationStratalotTodoEntities[id]) as CompanyCommunicationStratalotTodo[];
  } else {
    delete companyCommunicationHydrated.companyCommunicationStratalotTodos;
  }

  if (organizationLeadTodoEntities) {
    companyCommunicationHydrated.organizationLeadTodos = (
      (companyCommunicationHydrated.organizationLeadTodos as number[]) || []
    ).map(id => organizationLeadTodoEntities[id]) as OrganizationLeadTodo[];
  } else {
    delete companyCommunicationHydrated.organizationLeadTodos;
  }

  if (companyCommunicationLeadTodoEntities) {
    companyCommunicationHydrated.companyCommunicationLeadTodos = (
      (companyCommunicationHydrated.companyCommunicationLeadTodos as number[]) || []
    ).map(id => companyCommunicationLeadTodoEntities[id]) as CompanyCommunicationLeadTodo[];
  } else {
    delete companyCommunicationHydrated.companyCommunicationLeadTodos;
  }

  if (generatedDocumentEntities) {
    companyCommunicationHydrated.generatedDocuments = (
      (companyCommunicationHydrated.generatedDocuments as number[]) || []
    ).map(id => generatedDocumentEntities[id]) as GeneratedDocument[];
  } else {
    delete companyCommunicationHydrated.generatedDocuments;
  }

  if (companyCommunicationGeneratedDocumentEntities) {
    companyCommunicationHydrated.companyCommunicationGeneratedDocuments = (
      (companyCommunicationHydrated.companyCommunicationGeneratedDocuments as number[]) || []
    ).map(id => companyCommunicationGeneratedDocumentEntities[id]) as CompanyCommunicationGeneratedDocument[];
  } else {
    delete companyCommunicationHydrated.companyCommunicationGeneratedDocuments;
  }

  if (companyCommunicationFileEntities) {
    companyCommunicationHydrated.companyCommunicationFiles = (
      (companyCommunicationHydrated.companyCommunicationFiles as number[]) || []
    ).map(id => companyCommunicationFileEntities[id]) as CompanyCommunicationFile[];
  } else {
    delete companyCommunicationHydrated.companyCommunicationFiles;
  }

  return companyCommunicationHydrated as CompanyCommunication;
}
