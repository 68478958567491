import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { ResidenceStudy, ResidenceStudyEntityState } from '@_model/interfaces/residence-study.model';
import { ResidenceStudyApiService } from '@_services/api/residence-study-api.service';
import * as residenceStudyActions from '@_store/residence-study/residence-study.actions';
import { getActionsToNormalizeResidenceStudy } from '@_config/store/normalization.generated';
import { selectResidenceStudyState } from './residence-study-generated.selectors';
import * as companyStudyNextActionActions from '@_store/company-study-next-action/company-study-next-action.actions';
import * as companyStudyConclusionActions from '@_store/company-study-conclusion/company-study-conclusion.actions';
import * as companyActions from '@_store/company/company.actions';
import * as residenceStudyReasonActions from '@_store/residence-study-reason/residence-study-reason.actions';
import { ResidenceStudyReason } from '@_model/interfaces/residence-study-reason.model';
import * as residenceStudyCriteriaActions from '@_store/residence-study-criteria/residence-study-criteria.actions';
import { ResidenceStudyCriteria } from '@_model/interfaces/residence-study-criteria.model';
import * as residenceStudyWorkActions from '@_store/residence-study-work/residence-study-work.actions';
import { ResidenceStudyWork } from '@_model/interfaces/residence-study-work.model';
import * as residenceStudyTodoActions from '@_store/residence-study-todo/residence-study-todo.actions';
import { ResidenceStudyTodo } from '@_model/interfaces/residence-study-todo.model';
import * as residenceStudyLotActions from '@_store/residence-study-lot/residence-study-lot.actions';
import { ResidenceStudyLot } from '@_model/interfaces/residence-study-lot.model';
import * as residenceStudyFoundingActions from '@_store/residence-study-founding/residence-study-founding.actions';
import { ResidenceStudyFounding } from '@_model/interfaces/residence-study-founding.model';
import * as companyStudyReasonActions from '@_store/company-study-reason/company-study-reason.actions';
import { CompanyStudyReason } from '@_model/interfaces/company-study-reason.model';

export interface ResidenceStudyRelationsIds {
  residenceStudyReasons?: number | number[];
  residenceStudyCriterias?: number | number[];
  residenceStudyWorks?: number | number[];
  residenceStudyTodos?: number | number[];
  residenceStudyLots?: number | number[];
  residenceStudyFoundings?: number | number[];
  companyStudyReasons?: number | number[];
  companyStudyNextAction?: number;
  companyStudyConclusion?: number;
  company?: number;
}

export function getDefaultAddResidenceStudyActions(
  residenceStudy: ResidenceStudyEntityState,
  ids?: ResidenceStudyRelationsIds
): Action[] {
  const actions: Action[] = [
    residenceStudyActions.normalizeManyResidenceStudiesAfterUpsert({ residenceStudies: [residenceStudy] })
  ];

  if (ids?.companyStudyNextAction) {
    actions.push(
      companyStudyNextActionActions.addManyResidenceStudySuccess({
        idCompanyStudyNextAction: ids.companyStudyNextAction,
        idResidenceStudies: [residenceStudy.idResidenceStudy]
      })
    );
    actions.push(
      residenceStudyActions.addCompanyStudyNextActionSuccess({
        idResidenceStudy: residenceStudy.idResidenceStudy,
        idCompanyStudyNextAction: ids.companyStudyNextAction
      })
    );
  }

  if (ids?.companyStudyConclusion) {
    actions.push(
      companyStudyConclusionActions.addManyResidenceStudySuccess({
        idCompanyStudyConclusion: ids.companyStudyConclusion,
        idResidenceStudies: [residenceStudy.idResidenceStudy]
      })
    );
    actions.push(
      residenceStudyActions.addCompanyStudyConclusionSuccess({
        idResidenceStudy: residenceStudy.idResidenceStudy,
        idCompanyStudyConclusion: ids.companyStudyConclusion
      })
    );
  }

  if (ids?.company) {
    actions.push(
      companyActions.addManyResidenceStudySuccess({
        idCompany: ids.company,
        idResidenceStudies: [residenceStudy.idResidenceStudy]
      })
    );
    actions.push(
      residenceStudyActions.addCompanySuccess({
        idResidenceStudy: residenceStudy.idResidenceStudy,
        idCompany: ids.company
      })
    );
  }

  if (ids?.residenceStudyReasons) {
    if (!Array.isArray(ids.residenceStudyReasons)) {
      actions.push(
        residenceStudyReasonActions.upsertOneResidenceStudyReason({
          residenceStudyReason: {
            idResidenceStudy: residenceStudy.idResidenceStudy,
            idResidenceStudyReason: ids.residenceStudyReasons as number
          } as ResidenceStudyReason
        })
      );
      actions.push(
        residenceStudyActions.addManyResidenceStudyReasonSuccess({
          idResidenceStudy: residenceStudy.idResidenceStudy,
          idResidenceStudyReasons: [ids.residenceStudyReasons as number]
        })
      );
    } else {
      actions.push(
        residenceStudyReasonActions.upsertManyResidenceStudyReasons({
          residenceStudyReasons: (ids.residenceStudyReasons as number[]).map((idResidenceStudyReason: number) => ({
            idResidenceStudy: residenceStudy.idResidenceStudy,
            idResidenceStudyReason
          })) as ResidenceStudyReason[]
        })
      );
      actions.push(
        residenceStudyActions.addManyResidenceStudyReasonSuccess({
          idResidenceStudy: residenceStudy.idResidenceStudy,
          idResidenceStudyReasons: ids.residenceStudyReasons as number[]
        })
      );
    }
  }

  if (ids?.residenceStudyCriterias) {
    if (!Array.isArray(ids.residenceStudyCriterias)) {
      actions.push(
        residenceStudyCriteriaActions.upsertOneResidenceStudyCriteria({
          residenceStudyCriteria: {
            idResidenceStudy: residenceStudy.idResidenceStudy,
            idResidenceStudyCriteria: ids.residenceStudyCriterias as number
          } as ResidenceStudyCriteria
        })
      );
      actions.push(
        residenceStudyActions.addManyResidenceStudyCriteriaSuccess({
          idResidenceStudy: residenceStudy.idResidenceStudy,
          idResidenceStudyCriterias: [ids.residenceStudyCriterias as number]
        })
      );
    } else {
      actions.push(
        residenceStudyCriteriaActions.upsertManyResidenceStudyCriterias({
          residenceStudyCriterias: (ids.residenceStudyCriterias as number[]).map(
            (idResidenceStudyCriteria: number) => ({
              idResidenceStudy: residenceStudy.idResidenceStudy,
              idResidenceStudyCriteria
            })
          ) as ResidenceStudyCriteria[]
        })
      );
      actions.push(
        residenceStudyActions.addManyResidenceStudyCriteriaSuccess({
          idResidenceStudy: residenceStudy.idResidenceStudy,
          idResidenceStudyCriterias: ids.residenceStudyCriterias as number[]
        })
      );
    }
  }

  if (ids?.residenceStudyWorks) {
    if (!Array.isArray(ids.residenceStudyWorks)) {
      actions.push(
        residenceStudyWorkActions.upsertOneResidenceStudyWork({
          residenceStudyWork: {
            idResidenceStudy: residenceStudy.idResidenceStudy,
            idResidenceStudyWork: ids.residenceStudyWorks as number
          } as ResidenceStudyWork
        })
      );
      actions.push(
        residenceStudyActions.addManyResidenceStudyWorkSuccess({
          idResidenceStudy: residenceStudy.idResidenceStudy,
          idResidenceStudyWorks: [ids.residenceStudyWorks as number]
        })
      );
    } else {
      actions.push(
        residenceStudyWorkActions.upsertManyResidenceStudyWorks({
          residenceStudyWorks: (ids.residenceStudyWorks as number[]).map((idResidenceStudyWork: number) => ({
            idResidenceStudy: residenceStudy.idResidenceStudy,
            idResidenceStudyWork
          })) as ResidenceStudyWork[]
        })
      );
      actions.push(
        residenceStudyActions.addManyResidenceStudyWorkSuccess({
          idResidenceStudy: residenceStudy.idResidenceStudy,
          idResidenceStudyWorks: ids.residenceStudyWorks as number[]
        })
      );
    }
  }

  if (ids?.residenceStudyTodos) {
    if (!Array.isArray(ids.residenceStudyTodos)) {
      actions.push(
        residenceStudyTodoActions.upsertOneResidenceStudyTodo({
          residenceStudyTodo: {
            idResidenceStudy: residenceStudy.idResidenceStudy,
            idResidenceStudyTodo: ids.residenceStudyTodos as number
          } as ResidenceStudyTodo
        })
      );
      actions.push(
        residenceStudyActions.addManyResidenceStudyTodoSuccess({
          idResidenceStudy: residenceStudy.idResidenceStudy,
          idResidenceStudyTodos: [ids.residenceStudyTodos as number]
        })
      );
    } else {
      actions.push(
        residenceStudyTodoActions.upsertManyResidenceStudyTodos({
          residenceStudyTodos: (ids.residenceStudyTodos as number[]).map((idResidenceStudyTodo: number) => ({
            idResidenceStudy: residenceStudy.idResidenceStudy,
            idResidenceStudyTodo
          })) as ResidenceStudyTodo[]
        })
      );
      actions.push(
        residenceStudyActions.addManyResidenceStudyTodoSuccess({
          idResidenceStudy: residenceStudy.idResidenceStudy,
          idResidenceStudyTodos: ids.residenceStudyTodos as number[]
        })
      );
    }
  }

  if (ids?.residenceStudyLots) {
    if (!Array.isArray(ids.residenceStudyLots)) {
      actions.push(
        residenceStudyLotActions.upsertOneResidenceStudyLot({
          residenceStudyLot: {
            idResidenceStudy: residenceStudy.idResidenceStudy,
            idResidenceStudyLot: ids.residenceStudyLots as number
          } as ResidenceStudyLot
        })
      );
      actions.push(
        residenceStudyActions.addManyResidenceStudyLotSuccess({
          idResidenceStudy: residenceStudy.idResidenceStudy,
          idResidenceStudyLots: [ids.residenceStudyLots as number]
        })
      );
    } else {
      actions.push(
        residenceStudyLotActions.upsertManyResidenceStudyLots({
          residenceStudyLots: (ids.residenceStudyLots as number[]).map((idResidenceStudyLot: number) => ({
            idResidenceStudy: residenceStudy.idResidenceStudy,
            idResidenceStudyLot
          })) as ResidenceStudyLot[]
        })
      );
      actions.push(
        residenceStudyActions.addManyResidenceStudyLotSuccess({
          idResidenceStudy: residenceStudy.idResidenceStudy,
          idResidenceStudyLots: ids.residenceStudyLots as number[]
        })
      );
    }
  }

  if (ids?.residenceStudyFoundings) {
    if (!Array.isArray(ids.residenceStudyFoundings)) {
      actions.push(
        residenceStudyFoundingActions.upsertOneResidenceStudyFounding({
          residenceStudyFounding: {
            idResidenceStudy: residenceStudy.idResidenceStudy,
            idResidenceStudyFounding: ids.residenceStudyFoundings as number
          } as ResidenceStudyFounding
        })
      );
      actions.push(
        residenceStudyActions.addManyResidenceStudyFoundingSuccess({
          idResidenceStudy: residenceStudy.idResidenceStudy,
          idResidenceStudyFoundings: [ids.residenceStudyFoundings as number]
        })
      );
    } else {
      actions.push(
        residenceStudyFoundingActions.upsertManyResidenceStudyFoundings({
          residenceStudyFoundings: (ids.residenceStudyFoundings as number[]).map(
            (idResidenceStudyFounding: number) => ({
              idResidenceStudy: residenceStudy.idResidenceStudy,
              idResidenceStudyFounding
            })
          ) as ResidenceStudyFounding[]
        })
      );
      actions.push(
        residenceStudyActions.addManyResidenceStudyFoundingSuccess({
          idResidenceStudy: residenceStudy.idResidenceStudy,
          idResidenceStudyFoundings: ids.residenceStudyFoundings as number[]
        })
      );
    }
  }

  if (ids?.companyStudyReasons) {
    if (!Array.isArray(ids.companyStudyReasons)) {
      actions.push(
        companyStudyReasonActions.upsertOneCompanyStudyReason({
          companyStudyReason: {
            idResidenceStudy: residenceStudy.idResidenceStudy,
            idCompanyStudyReason: ids.companyStudyReasons as number
          } as CompanyStudyReason & any
        })
      );
      actions.push(
        residenceStudyActions.addManyCompanyStudyReasonSuccess({
          idResidenceStudy: residenceStudy.idResidenceStudy,
          idCompanyStudyReasons: [ids.companyStudyReasons as number]
        })
      );
    } else {
      actions.push(
        companyStudyReasonActions.upsertManyCompanyStudyReasons({
          companyStudyReasons: (ids.companyStudyReasons as number[]).map((idCompanyStudyReason: number) => ({
            idResidenceStudy: residenceStudy.idResidenceStudy,
            idCompanyStudyReason
          })) as CompanyStudyReason[] & any[]
        })
      );
      actions.push(
        residenceStudyActions.addManyCompanyStudyReasonSuccess({
          idResidenceStudy: residenceStudy.idResidenceStudy,
          idCompanyStudyReasons: ids.companyStudyReasons as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteResidenceStudyActions(residenceStudy: ResidenceStudyEntityState): Action[] {
  const actions: Action[] = [
    residenceStudyActions.deleteOneResidenceStudySuccess({ idResidenceStudy: residenceStudy.idResidenceStudy })
  ];

  if (residenceStudy.companyStudyNextAction) {
    actions.push(
      companyStudyNextActionActions.deleteManyResidenceStudySuccess({
        idResidenceStudies: [residenceStudy.idResidenceStudy],
        idCompanyStudyNextActions: [residenceStudy.companyStudyNextAction as number]
      })
    );
  }

  if (residenceStudy.companyStudyConclusion) {
    actions.push(
      companyStudyConclusionActions.deleteManyResidenceStudySuccess({
        idResidenceStudies: [residenceStudy.idResidenceStudy],
        idCompanyStudyConclusions: [residenceStudy.companyStudyConclusion as number]
      })
    );
  }

  if (residenceStudy.company) {
    actions.push(
      companyActions.deleteManyResidenceStudySuccess({
        idResidenceStudies: [residenceStudy.idResidenceStudy],
        idCompanies: [residenceStudy.company as number]
      })
    );
  }

  if (residenceStudy.residenceStudyReasons) {
    actions.push(
      residenceStudyReasonActions.deleteManyResidenceStudySuccess({
        idResidenceStudies: [residenceStudy.idResidenceStudy],
        idResidenceStudyReasons: residenceStudy.residenceStudyReasons as number[]
      })
    );
  }

  if (residenceStudy.residenceStudyCriterias) {
    actions.push(
      residenceStudyCriteriaActions.deleteManyResidenceStudySuccess({
        idResidenceStudies: [residenceStudy.idResidenceStudy],
        idResidenceStudyCriterias: residenceStudy.residenceStudyCriterias as number[]
      })
    );
  }

  if (residenceStudy.residenceStudyWorks) {
    actions.push(
      residenceStudyWorkActions.deleteManyResidenceStudySuccess({
        idResidenceStudies: [residenceStudy.idResidenceStudy],
        idResidenceStudyWorks: residenceStudy.residenceStudyWorks as number[]
      })
    );
  }

  if (residenceStudy.residenceStudyTodos) {
    actions.push(
      residenceStudyTodoActions.deleteManyResidenceStudySuccess({
        idResidenceStudies: [residenceStudy.idResidenceStudy],
        idResidenceStudyTodos: residenceStudy.residenceStudyTodos as number[]
      })
    );
  }

  if (residenceStudy.residenceStudyLots) {
    actions.push(
      residenceStudyLotActions.deleteManyResidenceStudySuccess({
        idResidenceStudies: [residenceStudy.idResidenceStudy],
        idResidenceStudyLots: residenceStudy.residenceStudyLots as number[]
      })
    );
  }

  if (residenceStudy.residenceStudyFoundings) {
    actions.push(
      residenceStudyFoundingActions.deleteManyResidenceStudySuccess({
        idResidenceStudies: [residenceStudy.idResidenceStudy],
        idResidenceStudyFoundings: residenceStudy.residenceStudyFoundings as number[]
      })
    );
  }

  if (residenceStudy.companyStudyReasons) {
    actions.push(
      companyStudyReasonActions.deleteManyResidenceStudySuccess({
        idResidenceStudies: [residenceStudy.idResidenceStudy],
        idCompanyStudyReasons: residenceStudy.companyStudyReasons as number[]
      })
    );
  }

  return actions;
}

export class GeneratedResidenceStudyEffects {
  constructor(
    protected actions$: Actions,
    protected residenceStudyApiService: ResidenceStudyApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidenceStudies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyActions.getManyResidenceStudies),
      switchMap(({ params }) =>
        this.residenceStudyApiService.getResidenceStudies(params).pipe(
          map((residenceStudies: ResidenceStudy[]) => {
            return residenceStudyActions.normalizeManyResidenceStudiesAfterUpsert({ residenceStudies });
          }),
          catchError(error => of(residenceStudyActions.residenceStudiesFailure({ error })))
        )
      )
    );
  });

  getOneResidenceStudy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyActions.getOneResidenceStudy),
      switchMap(idResidenceStudy =>
        this.residenceStudyApiService.getResidenceStudy(idResidenceStudy).pipe(
          map((residenceStudy: ResidenceStudy) => {
            return residenceStudyActions.normalizeManyResidenceStudiesAfterUpsert({
              residenceStudies: [residenceStudy]
            });
          }),
          catchError(error => of(residenceStudyActions.residenceStudiesFailure({ error })))
        )
      )
    );
  });

  upsertOneResidenceStudy$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyActions.upsertOneResidenceStudy),
      concatMap(
        ({ residenceStudy, ids }: { residenceStudy: Partial<ResidenceStudy>; ids?: ResidenceStudyRelationsIds }) => {
          if (residenceStudy.idResidenceStudy) {
            return this.residenceStudyApiService.updateResidenceStudy(residenceStudy).pipe(
              map((residenceStudyReturned: ResidenceStudy) => {
                return residenceStudyActions.normalizeManyResidenceStudiesAfterUpsert({
                  residenceStudies: [residenceStudyReturned]
                });
              }),
              catchError(error => of(residenceStudyActions.residenceStudiesFailure({ error })))
            );
          } else {
            return this.residenceStudyApiService.addResidenceStudy(residenceStudy).pipe(
              mergeMap((residenceStudyReturned: ResidenceStudy) =>
                getDefaultAddResidenceStudyActions(residenceStudyReturned, ids)
              ),
              catchError(error => of(residenceStudyActions.residenceStudiesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneResidenceStudy$ = createEffect(() => {
    const selectResidenceStudyState$ = this.store$.select(selectResidenceStudyState);
    return this.actions$.pipe(
      ofType(residenceStudyActions.deleteOneResidenceStudy),
      withLatestFrom(selectResidenceStudyState$),
      concatMap(([{ idResidenceStudy }, state]) =>
        this.residenceStudyApiService.deleteResidenceStudy(idResidenceStudy).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidenceStudyActions(state.entities[idResidenceStudy] as ResidenceStudyEntityState),
              residenceStudyActions.deleteOneResidenceStudy.type
            )
          ]),
          catchError(error => of(residenceStudyActions.residenceStudiesFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidenceStudiesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyActions.normalizeManyResidenceStudiesAfterUpsert),
      concatMap(({ residenceStudies }) => {
        const actions: Action[] = getActionsToNormalizeResidenceStudy(residenceStudies, StoreActionType.upsert);
        return [getMultiAction(actions, '[ResidenceStudy] Normalization After Upsert Success')];
      })
    );
  });
}
