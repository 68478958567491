import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidenceStudyWorkActions from './residence-study-work.actions';
import { adapter, initialState, ResidenceStudyWorkState } from './residence-study-work.state';
import { Dictionary } from '@ngrx/entity';
import { ResidenceStudyWorkEntityState } from '@_model/interfaces/residence-study-work.model';

export const residenceStudyWorkReducersGeneratedFunctions: ReducerTypes<
  ResidenceStudyWorkState,
  readonly ActionCreator[]
>[] = [
  on(ResidenceStudyWorkActions.getOneResidenceStudyWork, (state: ResidenceStudyWorkState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceStudyWorkActions.getManyResidenceStudyWorks, (state: ResidenceStudyWorkState) =>
    setLoadingsState(state, true)
  ),
  on(ResidenceStudyWorkActions.upsertOneResidenceStudyWork, (state: ResidenceStudyWorkState) =>
    setLoadingsState(state, true)
  ),

  on(
    ResidenceStudyWorkActions.upsertManyResidenceStudyWorksSuccess,
    (state: ResidenceStudyWorkState, { residenceStudyWorks }) =>
      adapter.upsertMany(residenceStudyWorks, setLoadingsState(state, false))
  ),
  on(ResidenceStudyWorkActions.deleteOneResidenceStudyWork, (state: ResidenceStudyWorkState) =>
    setLoadingsState(state, true)
  ),
  on(
    ResidenceStudyWorkActions.deleteOneResidenceStudyWorkSuccess,
    (state: ResidenceStudyWorkState, { idResidenceStudyWork }) =>
      adapter.removeOne(idResidenceStudyWork, setLoadingsState(state, false))
  ),
  on(ResidenceStudyWorkActions.clearActive, (state: ResidenceStudyWorkState) => ({ ...state, actives: [] })),
  on(ResidenceStudyWorkActions.addManyActives, (state: ResidenceStudyWorkState, { idResidenceStudyWorks }) => ({
    ...state,
    actives: state.actives.concat(idResidenceStudyWorks)
  })),
  on(ResidenceStudyWorkActions.deleteOneActive, (state: ResidenceStudyWorkState, { idResidenceStudyWork }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idResidenceStudyWork)
  })),

  on(ResidenceStudyWorkActions.clearStore, () => initialState),

  on(
    ResidenceStudyWorkActions.addResidenceStudySuccess,
    (state: ResidenceStudyWorkState, { idResidenceStudyWork, idResidenceStudy }) => {
      if (!state.entities[idResidenceStudyWork]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudyWork]: {
            ...state.entities[idResidenceStudyWork],
            residenceStudy: idResidenceStudy
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyWorkActions.deleteManyResidenceStudySuccess,
    (state: ResidenceStudyWorkState, { idResidenceStudies, idResidenceStudyWorks }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudyWorks.reduce((entities, idResidenceStudyWork) => {
            if (!state.entities[idResidenceStudyWork]?.residenceStudy) {
              return entities;
            }
            entities[idResidenceStudyWork] = {
              ...state.entities[idResidenceStudyWork],
              residenceStudy: idResidenceStudies.some(
                (idResidenceStudy: number) => idResidenceStudy === state.entities[idResidenceStudyWork]?.residenceStudy
              )
                ? undefined
                : state.entities[idResidenceStudyWork]?.residenceStudy
            } as ResidenceStudyWorkEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyWorkEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidenceStudyWorkState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidenceStudyWorkState {
  return { ...state, isLoaded, isLoading };
}
