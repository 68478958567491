import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResidenceShareRcpApiService } from '@_services/api/residence-share-rcp-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedResidenceShareRcpEffects } from './residence-share-rcp-generated.effects';

@Injectable()
export class ResidenceShareRcpEffects extends GeneratedResidenceShareRcpEffects {
  constructor(actions$: Actions, residenceShareRcpApiService: ResidenceShareRcpApiService, store$: Store<AppState>) {
    super(actions$, residenceShareRcpApiService, store$);
  }
}
