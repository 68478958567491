import { CompanyCommunicationRecipientMetierState, initialState } from './company-communication-recipient-metier.state';
import { Action, createReducer } from '@ngrx/store';
import { companyCommunicationRecipientMetierReducersGeneratedFunctions } from './company-communication-recipient-metier-generated.reducer';

const companyCommunicationRecipientMetierReducersFunctions = [
  ...companyCommunicationRecipientMetierReducersGeneratedFunctions
];

const companyCommunicationRecipientMetierReducer = createReducer(
  initialState,
  ...companyCommunicationRecipientMetierReducersFunctions
);

export function reducer(state: CompanyCommunicationRecipientMetierState | undefined, action: Action) {
  return companyCommunicationRecipientMetierReducer(state, action);
}
