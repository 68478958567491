import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResidenceContact, ResidenceContactEntityState } from '@_model/interfaces/residence-contact.model';
import { Residence, ResidenceEntityState } from '@_model/interfaces/residence.model';
import {
  OrganizationThirdParty,
  OrganizationThirdPartyEntityState
} from '@_model/interfaces/organization-third-party.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, residenceContactFeatureKey, ResidenceContactState } from './residence-contact.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const residenceContactRelations: string[] = ['residences', 'organizationThirdParties'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectResidenceContactState = createFeatureSelector<ResidenceContactState>(residenceContactFeatureKey);

export const selectIsLoadedResidenceContact = createSelector(
  selectResidenceContactState,
  (state: ResidenceContactState) => state.isLoaded
);

export const selectIsLoadingResidenceContact = createSelector(
  selectResidenceContactState,
  (state: ResidenceContactState) => state.isLoading
);

export const selectIsReadyResidenceContact = createSelector(
  selectResidenceContactState,
  (state: ResidenceContactState) => !state.isLoading
);

export const selectIsReadyAndLoadedResidenceContact = createSelector(
  selectResidenceContactState,
  (state: ResidenceContactState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const ResidenceContactModel: SelectorModel = {
  name: 'residenceContacts',
  getSelector: selectAllResidenceContactsDictionary,
  isReady: selectIsReadyResidenceContact
};

export const selectResidenceContactsEntities = createSelector(selectResidenceContactState, selectEntities);

export const selectResidenceContactsArray = createSelector(selectResidenceContactState, selectAll);

export const selectIdResidenceContactsActive = createSelector(
  selectResidenceContactState,
  (state: ResidenceContactState) => state.actives
);

const residenceContactsInObject = (residenceContacts: Dictionary<ResidenceContactEntityState>) => ({
  residenceContacts
});

const selectResidenceContactsEntitiesDictionary = createSelector(
  selectResidenceContactsEntities,
  residenceContactsInObject
);

const selectAllResidenceContactsObject = createSelector(selectResidenceContactsEntities, residenceContacts => {
  return hydrateAll({ residenceContacts });
});

const selectOneResidenceContactDictionary = (idResidenceContact: number) =>
  createSelector(selectResidenceContactsEntities, residenceContacts => {
    return { residenceContacts: { [idResidenceContact]: residenceContacts[idResidenceContact] } };
  });

const selectOneResidenceContactDictionaryWithoutChild = (idResidenceContact: number) =>
  createSelector(selectResidenceContactsEntities, residenceContacts => {
    return { residenceContact: residenceContacts[idResidenceContact] };
  });

const selectActiveResidenceContactsEntities = createSelector(
  selectIdResidenceContactsActive,
  selectResidenceContactsEntities,
  (actives: number[], residenceContacts: Dictionary<ResidenceContactEntityState>) =>
    getResidenceContactsFromActives(actives, residenceContacts)
);

function getResidenceContactsFromActives(
  actives: number[],
  residenceContacts: Dictionary<ResidenceContactEntityState>
): Dictionary<ResidenceContactEntityState> {
  return actives.reduce((acc, idActive) => {
    if (residenceContacts[idActive]) {
      acc[idActive] = residenceContacts[idActive];
    }
    return acc;
  }, {} as Dictionary<ResidenceContactEntityState>);
}

const selectAllResidenceContactsSelectors: Dictionary<Selector> = {};
export function selectAllResidenceContacts(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ResidenceContact>(
      schema,
      selectAllResidenceContactsSelectors,
      selectResidenceContactsEntitiesDictionary,
      getRelationSelectors,
      residenceContactRelations,
      hydrateAll,
      'residenceContact'
    );
  } else {
    return selectAllResidenceContactsObject;
  }
}

export function selectAllResidenceContactsDictionary(
  schema: SelectSchema = {},
  customKey: string = 'residenceContacts'
): Selector {
  return createSelector(selectAllResidenceContacts(schema), result => {
    const res = { [customKey]: {} as Dictionary<ResidenceContactEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.residenceContacts.length; i++) {
      res[customKey][result.residenceContacts[i].idResidenceContact] = result.residenceContacts[i];
    }
    return res;
  });
}

export function selectOneResidenceContact(schema: SelectSchema = {}, idResidenceContact: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneResidenceContactDictionary(idResidenceContact)];
    selectors.push(...getRelationSelectors(schema, residenceContactRelations, 'residenceContact'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneResidenceContactDictionaryWithoutChild(idResidenceContact);
  }
}

export function selectActiveResidenceContacts(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveResidenceContactsEntities, residenceContacts => ({ residenceContacts }))
  ];
  selectors.push(...getRelationSelectors(schema, residenceContactRelations, 'residenceContact'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  residenceContacts: Dictionary<ResidenceContactEntityState>;
  residences?: Dictionary<ResidenceEntityState>;
  organizationThirdParties?: Dictionary<OrganizationThirdPartyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { residenceContacts: (ResidenceContact | null)[] } {
  const { residenceContacts, residences, organizationThirdParties } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  return {
    residenceContacts: Object.keys(residenceContacts).map(idResidenceContact =>
      hydrate(
        residenceContacts[idResidenceContact] as ResidenceContactEntityState,
        residences,
        organizationThirdParties
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { residenceContact: ResidenceContactEntityState | null } {
  const { residenceContacts, residences, organizationThirdParties } = args.reduce(
    (acc, value) => ({ ...acc, ...value }),
    {} as hydrateArgs
  );

  const residenceContact = Object.values(residenceContacts)[0];
  return {
    residenceContact: hydrate(residenceContact as ResidenceContactEntityState, residences, organizationThirdParties)
  };
}

function hydrate(
  residenceContact: ResidenceContactEntityState,
  residenceEntities?: Dictionary<ResidenceEntityState>,
  organizationThirdPartyEntities?: Dictionary<OrganizationThirdPartyEntityState>
): ResidenceContact | null {
  if (!residenceContact) {
    return null;
  }

  const residenceContactHydrated: ResidenceContactEntityState = { ...residenceContact };
  if (residenceEntities) {
    residenceContactHydrated.residence = residenceEntities[residenceContact.residence as number] as Residence;
  } else {
    delete residenceContactHydrated.residence;
  }
  if (organizationThirdPartyEntities) {
    residenceContactHydrated.organizationThirdParty = organizationThirdPartyEntities[
      residenceContact.organizationThirdParty as number
    ] as OrganizationThirdParty;
  } else {
    delete residenceContactHydrated.organizationThirdParty;
  }

  return residenceContactHydrated as ResidenceContact;
}
