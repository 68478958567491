import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as AppState from '@_store/index.reducers';
import { GeneratedStepService } from './step-generated.service';
import { Actions } from '@ngrx/effects';

@Injectable({
  providedIn: 'root'
})
export class StepService extends GeneratedStepService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }
}
