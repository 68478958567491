import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationLeadTodoProfil,
  OrganizationLeadTodoProfilEntityState
} from '@_model/interfaces/organization-lead-todo-profil.model';
import { OrganizationLeadTodoProfilApiService } from '@_services/api/organization-lead-todo-profil-api.service';
import * as organizationLeadTodoProfilActions from '@_store/organization-lead-todo-profil/organization-lead-todo-profil.actions';
import { getActionsToNormalizeOrganizationLeadTodoProfil } from '@_config/store/normalization.generated';
import { selectOrganizationLeadTodoProfilState } from './organization-lead-todo-profil-generated.selectors';
import * as profilActions from '@_store/profil/profil.actions';
import * as organizationLeadTodoActions from '@_store/organization-lead-todo/organization-lead-todo.actions';

export interface OrganizationLeadTodoProfilRelationsIds {
  profil?: number;
  organizationLeadTodo?: number;
}

export function getDefaultAddOrganizationLeadTodoProfilActions(
  organizationLeadTodoProfil: OrganizationLeadTodoProfilEntityState,
  ids?: OrganizationLeadTodoProfilRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationLeadTodoProfilActions.normalizeManyOrganizationLeadTodoProfilsAfterUpsert({
      organizationLeadTodoProfils: [organizationLeadTodoProfil]
    })
  ];

  if (ids?.profil) {
    actions.push(
      profilActions.addManyOrganizationLeadTodoProfilSuccess({
        idProfil: ids.profil,
        idOrganizationLeadTodoProfils: [organizationLeadTodoProfil.idOrganizationLeadTodoProfil]
      })
    );
    actions.push(
      organizationLeadTodoProfilActions.addProfilSuccess({
        idOrganizationLeadTodoProfil: organizationLeadTodoProfil.idOrganizationLeadTodoProfil,
        idProfil: ids.profil
      })
    );
  }

  if (ids?.organizationLeadTodo) {
    actions.push(
      organizationLeadTodoActions.addManyOrganizationLeadTodoProfilSuccess({
        idOrganizationLeadTodo: ids.organizationLeadTodo,
        idOrganizationLeadTodoProfils: [organizationLeadTodoProfil.idOrganizationLeadTodoProfil]
      })
    );
    actions.push(
      organizationLeadTodoProfilActions.addOrganizationLeadTodoSuccess({
        idOrganizationLeadTodoProfil: organizationLeadTodoProfil.idOrganizationLeadTodoProfil,
        idOrganizationLeadTodo: ids.organizationLeadTodo
      })
    );
  }

  return actions;
}

export function getDefaultDeleteOrganizationLeadTodoProfilActions(
  organizationLeadTodoProfil: OrganizationLeadTodoProfilEntityState
): Action[] {
  const actions: Action[] = [
    organizationLeadTodoProfilActions.deleteOneOrganizationLeadTodoProfilSuccess({
      idOrganizationLeadTodoProfil: organizationLeadTodoProfil.idOrganizationLeadTodoProfil
    })
  ];

  if (organizationLeadTodoProfil.profil) {
    actions.push(
      profilActions.deleteManyOrganizationLeadTodoProfilSuccess({
        idOrganizationLeadTodoProfils: [organizationLeadTodoProfil.idOrganizationLeadTodoProfil],
        idProfils: [organizationLeadTodoProfil.profil as number]
      })
    );
  }

  if (organizationLeadTodoProfil.organizationLeadTodo) {
    actions.push(
      organizationLeadTodoActions.deleteManyOrganizationLeadTodoProfilSuccess({
        idOrganizationLeadTodoProfils: [organizationLeadTodoProfil.idOrganizationLeadTodoProfil],
        idOrganizationLeadTodos: [organizationLeadTodoProfil.organizationLeadTodo as number]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationLeadTodoProfilEffects {
  constructor(
    protected actions$: Actions,
    protected organizationLeadTodoProfilApiService: OrganizationLeadTodoProfilApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationLeadTodoProfils$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadTodoProfilActions.getManyOrganizationLeadTodoProfils),
      switchMap(({ params }) =>
        this.organizationLeadTodoProfilApiService.getOrganizationLeadTodoProfils(params).pipe(
          map((organizationLeadTodoProfils: OrganizationLeadTodoProfil[]) => {
            return organizationLeadTodoProfilActions.normalizeManyOrganizationLeadTodoProfilsAfterUpsert({
              organizationLeadTodoProfils
            });
          }),
          catchError(error => of(organizationLeadTodoProfilActions.organizationLeadTodoProfilsFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationLeadTodoProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadTodoProfilActions.getOneOrganizationLeadTodoProfil),
      switchMap(idOrganizationLeadTodoProfil =>
        this.organizationLeadTodoProfilApiService.getOrganizationLeadTodoProfil(idOrganizationLeadTodoProfil).pipe(
          map((organizationLeadTodoProfil: OrganizationLeadTodoProfil) => {
            return organizationLeadTodoProfilActions.normalizeManyOrganizationLeadTodoProfilsAfterUpsert({
              organizationLeadTodoProfils: [organizationLeadTodoProfil]
            });
          }),
          catchError(error => of(organizationLeadTodoProfilActions.organizationLeadTodoProfilsFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationLeadTodoProfil$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadTodoProfilActions.upsertOneOrganizationLeadTodoProfil),
      concatMap(
        ({
          organizationLeadTodoProfil,
          ids
        }: {
          organizationLeadTodoProfil: Partial<OrganizationLeadTodoProfil>;
          ids?: OrganizationLeadTodoProfilRelationsIds;
        }) => {
          if (organizationLeadTodoProfil.idOrganizationLeadTodoProfil) {
            return this.organizationLeadTodoProfilApiService
              .updateOrganizationLeadTodoProfil(organizationLeadTodoProfil)
              .pipe(
                map((organizationLeadTodoProfilReturned: OrganizationLeadTodoProfil) => {
                  return organizationLeadTodoProfilActions.normalizeManyOrganizationLeadTodoProfilsAfterUpsert({
                    organizationLeadTodoProfils: [organizationLeadTodoProfilReturned]
                  });
                }),
                catchError(error => of(organizationLeadTodoProfilActions.organizationLeadTodoProfilsFailure({ error })))
              );
          } else {
            return this.organizationLeadTodoProfilApiService
              .addOrganizationLeadTodoProfil(organizationLeadTodoProfil)
              .pipe(
                mergeMap((organizationLeadTodoProfilReturned: OrganizationLeadTodoProfil) =>
                  getDefaultAddOrganizationLeadTodoProfilActions(organizationLeadTodoProfilReturned, ids)
                ),
                catchError(error => of(organizationLeadTodoProfilActions.organizationLeadTodoProfilsFailure({ error })))
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationLeadTodoProfil$ = createEffect(() => {
    const selectOrganizationLeadTodoProfilState$ = this.store$.select(selectOrganizationLeadTodoProfilState);
    return this.actions$.pipe(
      ofType(organizationLeadTodoProfilActions.deleteOneOrganizationLeadTodoProfil),
      withLatestFrom(selectOrganizationLeadTodoProfilState$),
      concatMap(([{ idOrganizationLeadTodoProfil }, state]) =>
        this.organizationLeadTodoProfilApiService.deleteOrganizationLeadTodoProfil(idOrganizationLeadTodoProfil).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOrganizationLeadTodoProfilActions(
                state.entities[idOrganizationLeadTodoProfil] as OrganizationLeadTodoProfilEntityState
              ),
              organizationLeadTodoProfilActions.deleteOneOrganizationLeadTodoProfil.type
            )
          ]),
          catchError(error => of(organizationLeadTodoProfilActions.organizationLeadTodoProfilsFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationLeadTodoProfilsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadTodoProfilActions.normalizeManyOrganizationLeadTodoProfilsAfterUpsert),
      concatMap(({ organizationLeadTodoProfils }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationLeadTodoProfil(
          organizationLeadTodoProfils,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationLeadTodoProfil] Normalization After Upsert Success')];
      })
    );
  });
}
