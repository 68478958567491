import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidenceStudyActions from './residence-study.actions';
import { adapter, initialState, ResidenceStudyState } from './residence-study.state';
import { Dictionary } from '@ngrx/entity';
import { ResidenceStudyEntityState } from '@_model/interfaces/residence-study.model';

export const residenceStudyReducersGeneratedFunctions: ReducerTypes<ResidenceStudyState, readonly ActionCreator[]>[] = [
  on(ResidenceStudyActions.getOneResidenceStudy, (state: ResidenceStudyState) => setLoadingsState(state, true)),
  on(ResidenceStudyActions.getManyResidenceStudies, (state: ResidenceStudyState) => setLoadingsState(state, true)),
  on(ResidenceStudyActions.upsertOneResidenceStudy, (state: ResidenceStudyState) => setLoadingsState(state, true)),

  on(ResidenceStudyActions.upsertManyResidenceStudiesSuccess, (state: ResidenceStudyState, { residenceStudies }) =>
    adapter.upsertMany(residenceStudies, setLoadingsState(state, false))
  ),
  on(ResidenceStudyActions.deleteOneResidenceStudy, (state: ResidenceStudyState) => setLoadingsState(state, true)),
  on(ResidenceStudyActions.deleteOneResidenceStudySuccess, (state: ResidenceStudyState, { idResidenceStudy }) =>
    adapter.removeOne(idResidenceStudy, setLoadingsState(state, false))
  ),
  on(ResidenceStudyActions.clearActive, (state: ResidenceStudyState) => ({ ...state, actives: [] })),
  on(ResidenceStudyActions.addManyActives, (state: ResidenceStudyState, { idResidenceStudies }) => ({
    ...state,
    actives: state.actives.concat(idResidenceStudies)
  })),
  on(ResidenceStudyActions.deleteOneActive, (state: ResidenceStudyState, { idResidenceStudy }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idResidenceStudy)
  })),

  on(ResidenceStudyActions.clearStore, () => initialState),

  on(
    ResidenceStudyActions.addManyResidenceStudyReasonSuccess,
    (state: ResidenceStudyState, { idResidenceStudy, idResidenceStudyReasons }) => {
      if (!state.entities[idResidenceStudy]) {
        return state;
      }
      const residenceStudyReasons = (state.entities[idResidenceStudy]?.residenceStudyReasons as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudy]: {
            ...state.entities[idResidenceStudy],
            residenceStudyReasons: residenceStudyReasons.concat(
              idResidenceStudyReasons.filter(id => residenceStudyReasons.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.deleteManyResidenceStudyReasonSuccess,
    (state: ResidenceStudyState, { idResidenceStudyReasons, idResidenceStudies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudies.reduce((entities, idResidenceStudy) => {
            if (!state.entities[idResidenceStudy]?.residenceStudyReasons) {
              return entities;
            }
            entities[idResidenceStudy] = {
              ...state.entities[idResidenceStudy],
              residenceStudyReasons: (state.entities[idResidenceStudy]?.residenceStudyReasons as number[])?.filter(
                (idResidenceStudyReason: number) =>
                  !idResidenceStudyReasons.some((id: number) => id === idResidenceStudyReason)
              )
            } as ResidenceStudyEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.addManyResidenceStudyCriteriaSuccess,
    (state: ResidenceStudyState, { idResidenceStudy, idResidenceStudyCriterias }) => {
      if (!state.entities[idResidenceStudy]) {
        return state;
      }
      const residenceStudyCriterias = (state.entities[idResidenceStudy]?.residenceStudyCriterias as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudy]: {
            ...state.entities[idResidenceStudy],
            residenceStudyCriterias: residenceStudyCriterias.concat(
              idResidenceStudyCriterias.filter(id => residenceStudyCriterias.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.deleteManyResidenceStudyCriteriaSuccess,
    (state: ResidenceStudyState, { idResidenceStudyCriterias, idResidenceStudies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudies.reduce((entities, idResidenceStudy) => {
            if (!state.entities[idResidenceStudy]?.residenceStudyCriterias) {
              return entities;
            }
            entities[idResidenceStudy] = {
              ...state.entities[idResidenceStudy],
              residenceStudyCriterias: (state.entities[idResidenceStudy]?.residenceStudyCriterias as number[])?.filter(
                (idResidenceStudyCriteria: number) =>
                  !idResidenceStudyCriterias.some((id: number) => id === idResidenceStudyCriteria)
              )
            } as ResidenceStudyEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.addManyResidenceStudyWorkSuccess,
    (state: ResidenceStudyState, { idResidenceStudy, idResidenceStudyWorks }) => {
      if (!state.entities[idResidenceStudy]) {
        return state;
      }
      const residenceStudyWorks = (state.entities[idResidenceStudy]?.residenceStudyWorks as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudy]: {
            ...state.entities[idResidenceStudy],
            residenceStudyWorks: residenceStudyWorks.concat(
              idResidenceStudyWorks.filter(id => residenceStudyWorks.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.deleteManyResidenceStudyWorkSuccess,
    (state: ResidenceStudyState, { idResidenceStudyWorks, idResidenceStudies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudies.reduce((entities, idResidenceStudy) => {
            if (!state.entities[idResidenceStudy]?.residenceStudyWorks) {
              return entities;
            }
            entities[idResidenceStudy] = {
              ...state.entities[idResidenceStudy],
              residenceStudyWorks: (state.entities[idResidenceStudy]?.residenceStudyWorks as number[])?.filter(
                (idResidenceStudyWork: number) =>
                  !idResidenceStudyWorks.some((id: number) => id === idResidenceStudyWork)
              )
            } as ResidenceStudyEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.addManyResidenceStudyTodoSuccess,
    (state: ResidenceStudyState, { idResidenceStudy, idResidenceStudyTodos }) => {
      if (!state.entities[idResidenceStudy]) {
        return state;
      }
      const residenceStudyTodos = (state.entities[idResidenceStudy]?.residenceStudyTodos as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudy]: {
            ...state.entities[idResidenceStudy],
            residenceStudyTodos: residenceStudyTodos.concat(
              idResidenceStudyTodos.filter(id => residenceStudyTodos.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.deleteManyResidenceStudyTodoSuccess,
    (state: ResidenceStudyState, { idResidenceStudyTodos, idResidenceStudies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudies.reduce((entities, idResidenceStudy) => {
            if (!state.entities[idResidenceStudy]?.residenceStudyTodos) {
              return entities;
            }
            entities[idResidenceStudy] = {
              ...state.entities[idResidenceStudy],
              residenceStudyTodos: (state.entities[idResidenceStudy]?.residenceStudyTodos as number[])?.filter(
                (idResidenceStudyTodo: number) =>
                  !idResidenceStudyTodos.some((id: number) => id === idResidenceStudyTodo)
              )
            } as ResidenceStudyEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.addManyResidenceStudyLotSuccess,
    (state: ResidenceStudyState, { idResidenceStudy, idResidenceStudyLots }) => {
      if (!state.entities[idResidenceStudy]) {
        return state;
      }
      const residenceStudyLots = (state.entities[idResidenceStudy]?.residenceStudyLots as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudy]: {
            ...state.entities[idResidenceStudy],
            residenceStudyLots: residenceStudyLots.concat(
              idResidenceStudyLots.filter(id => residenceStudyLots.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.deleteManyResidenceStudyLotSuccess,
    (state: ResidenceStudyState, { idResidenceStudyLots, idResidenceStudies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudies.reduce((entities, idResidenceStudy) => {
            if (!state.entities[idResidenceStudy]?.residenceStudyLots) {
              return entities;
            }
            entities[idResidenceStudy] = {
              ...state.entities[idResidenceStudy],
              residenceStudyLots: (state.entities[idResidenceStudy]?.residenceStudyLots as number[])?.filter(
                (idResidenceStudyLot: number) => !idResidenceStudyLots.some((id: number) => id === idResidenceStudyLot)
              )
            } as ResidenceStudyEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.addManyResidenceStudyFoundingSuccess,
    (state: ResidenceStudyState, { idResidenceStudy, idResidenceStudyFoundings }) => {
      if (!state.entities[idResidenceStudy]) {
        return state;
      }
      const residenceStudyFoundings = (state.entities[idResidenceStudy]?.residenceStudyFoundings as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudy]: {
            ...state.entities[idResidenceStudy],
            residenceStudyFoundings: residenceStudyFoundings.concat(
              idResidenceStudyFoundings.filter(id => residenceStudyFoundings.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.deleteManyResidenceStudyFoundingSuccess,
    (state: ResidenceStudyState, { idResidenceStudyFoundings, idResidenceStudies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudies.reduce((entities, idResidenceStudy) => {
            if (!state.entities[idResidenceStudy]?.residenceStudyFoundings) {
              return entities;
            }
            entities[idResidenceStudy] = {
              ...state.entities[idResidenceStudy],
              residenceStudyFoundings: (state.entities[idResidenceStudy]?.residenceStudyFoundings as number[])?.filter(
                (idResidenceStudyFounding: number) =>
                  !idResidenceStudyFoundings.some((id: number) => id === idResidenceStudyFounding)
              )
            } as ResidenceStudyEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.addManyCompanyStudyReasonSuccess,
    (state: ResidenceStudyState, { idResidenceStudy, idCompanyStudyReasons }) => {
      if (!state.entities[idResidenceStudy]) {
        return state;
      }
      const companyStudyReasons = (state.entities[idResidenceStudy]?.companyStudyReasons as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudy]: {
            ...state.entities[idResidenceStudy],
            companyStudyReasons: companyStudyReasons.concat(
              idCompanyStudyReasons.filter(id => companyStudyReasons.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.deleteManyCompanyStudyReasonSuccess,
    (state: ResidenceStudyState, { idCompanyStudyReasons, idResidenceStudies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudies.reduce((entities, idResidenceStudy) => {
            if (!state.entities[idResidenceStudy]?.companyStudyReasons) {
              return entities;
            }
            entities[idResidenceStudy] = {
              ...state.entities[idResidenceStudy],
              companyStudyReasons: (state.entities[idResidenceStudy]?.companyStudyReasons as number[])?.filter(
                (idCompanyStudyReason: number) =>
                  !idCompanyStudyReasons.some((id: number) => id === idCompanyStudyReason)
              )
            } as ResidenceStudyEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.addCompanyStudyNextActionSuccess,
    (state: ResidenceStudyState, { idResidenceStudy, idCompanyStudyNextAction }) => {
      if (!state.entities[idResidenceStudy]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudy]: {
            ...state.entities[idResidenceStudy],
            companyStudyNextAction: idCompanyStudyNextAction
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.deleteManyCompanyStudyNextActionSuccess,
    (state: ResidenceStudyState, { idCompanyStudyNextActions, idResidenceStudies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudies.reduce((entities, idResidenceStudy) => {
            if (!state.entities[idResidenceStudy]?.companyStudyNextAction) {
              return entities;
            }
            entities[idResidenceStudy] = {
              ...state.entities[idResidenceStudy],
              companyStudyNextAction: idCompanyStudyNextActions.some(
                (idCompanyStudyNextAction: number) =>
                  idCompanyStudyNextAction === state.entities[idResidenceStudy]?.companyStudyNextAction
              )
                ? undefined
                : state.entities[idResidenceStudy]?.companyStudyNextAction
            } as ResidenceStudyEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.addCompanyStudyConclusionSuccess,
    (state: ResidenceStudyState, { idResidenceStudy, idCompanyStudyConclusion }) => {
      if (!state.entities[idResidenceStudy]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceStudy]: {
            ...state.entities[idResidenceStudy],
            companyStudyConclusion: idCompanyStudyConclusion
          }
        }
      };
    }
  ),

  on(
    ResidenceStudyActions.deleteManyCompanyStudyConclusionSuccess,
    (state: ResidenceStudyState, { idCompanyStudyConclusions, idResidenceStudies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudies.reduce((entities, idResidenceStudy) => {
            if (!state.entities[idResidenceStudy]?.companyStudyConclusion) {
              return entities;
            }
            entities[idResidenceStudy] = {
              ...state.entities[idResidenceStudy],
              companyStudyConclusion: idCompanyStudyConclusions.some(
                (idCompanyStudyConclusion: number) =>
                  idCompanyStudyConclusion === state.entities[idResidenceStudy]?.companyStudyConclusion
              )
                ? undefined
                : state.entities[idResidenceStudy]?.companyStudyConclusion
            } as ResidenceStudyEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyEntityState>)
        }
      };
    }
  ),

  on(ResidenceStudyActions.addCompanySuccess, (state: ResidenceStudyState, { idResidenceStudy, idCompany }) => {
    if (!state.entities[idResidenceStudy]) {
      return state;
    }
    return {
      ...state,
      entities: {
        ...state.entities,
        [idResidenceStudy]: {
          ...state.entities[idResidenceStudy],
          company: idCompany
        }
      }
    };
  }),

  on(
    ResidenceStudyActions.deleteManyCompanySuccess,
    (state: ResidenceStudyState, { idCompanies, idResidenceStudies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceStudies.reduce((entities, idResidenceStudy) => {
            if (!state.entities[idResidenceStudy]?.company) {
              return entities;
            }
            entities[idResidenceStudy] = {
              ...state.entities[idResidenceStudy],
              company: idCompanies.some((idCompany: number) => idCompany === state.entities[idResidenceStudy]?.company)
                ? undefined
                : state.entities[idResidenceStudy]?.company
            } as ResidenceStudyEntityState;
            return entities;
          }, {} as Dictionary<ResidenceStudyEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidenceStudyState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidenceStudyState {
  return { ...state, isLoaded, isLoading };
}
