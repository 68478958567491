import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { ResidencePriceGridHistory } from '@_shared/models/interfaces/residence-price-grid-history.model';
import { Observable } from 'rxjs';
import { GeneratedResidencePriceGridHistoryApiService } from './residence-price-grid-history-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class ResidencePriceGridHistoryApiService extends GeneratedResidencePriceGridHistoryApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public getLastUpdateResidencePriceGridHistories(params?: any): Observable<ResidencePriceGridHistory[]> {
    return this.repo.getData<ResidencePriceGridHistory[]>('residence-price-grid-history/lastUpdate', params);
  }
}
