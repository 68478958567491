import { RepositoryService } from '@_services/api/repository.service';
import { ProspectEvent } from '@_model/interfaces/prospect-event.model';
import { Observable } from 'rxjs';

export class GeneratedProspectEventApiService {
  constructor(protected repo: RepositoryService) {}

  public getProspectEvents(params?: any): Observable<ProspectEvent[]> {
    return this.repo.getData<ProspectEvent[]>('prospect-event', params);
  }

  public getProspectEvent(params: { idProspectEvent: number; params?: any }): Observable<ProspectEvent> {
    return this.repo.getData<ProspectEvent>('prospect-event/' + params.idProspectEvent, params.params);
  }

  public addProspectEvent(prospectEvent: Partial<ProspectEvent>): Observable<ProspectEvent> {
    return this.repo.create<ProspectEvent>('prospect-event', prospectEvent);
  }

  public updateProspectEvent(prospectEvent: Partial<ProspectEvent>): Observable<ProspectEvent> {
    return this.repo.update('prospect-event', prospectEvent);
  }

  public deleteProspectEvent(idProspectEvent: number): Observable<number> {
    return this.repo.delete('prospect-event/' + +idProspectEvent);
  }
}
