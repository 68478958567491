import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { Occupant } from '@_shared/models/interfaces/occupant.model';
import { Prospect } from '@_shared/models/interfaces/prospect.model';
import { Observable } from 'rxjs';
import { GeneratedOccupantApiService } from './occupant-api-generated.service';

@Injectable({
  providedIn: 'root'
})
export class OccupantApiService extends GeneratedOccupantApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public addOccupantWithProspectOnStratalot(idStratalot: number, prospect: Partial<Prospect>): Observable<Occupant> {
    return this.repo.create<Occupant>('occupant/stratalot/' + +idStratalot, prospect);
  }

  public updateOccupantWithProspect(prospect: Partial<Prospect>): Observable<Occupant> {
    return this.repo.update<Occupant>('occupant/prospect', prospect);
  }
}
