import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyMyNotary, CompanyMyNotaryEntityState } from '@_model/interfaces/company-my-notary.model';
import { Company, CompanyEntityState } from '@_model/interfaces/company.model';
import { findOrCreateSelector } from '@_services/ngrx-helper.service';
import { adapter, companyMyNotaryFeatureKey, CompanyMyNotaryState } from './company-my-notary.state';
import { getRelationSelectors, Selector, SelectorModel, SelectSchema } from '@_utils/selector.util';

export const companyMyNotaryRelations: string[] = ['companies'];

export const { selectEntities, selectAll } = adapter.getSelectors();

export const selectCompanyMyNotaryState = createFeatureSelector<CompanyMyNotaryState>(companyMyNotaryFeatureKey);

export const selectIsLoadedCompanyMyNotary = createSelector(
  selectCompanyMyNotaryState,
  (state: CompanyMyNotaryState) => state.isLoaded
);

export const selectIsLoadingCompanyMyNotary = createSelector(
  selectCompanyMyNotaryState,
  (state: CompanyMyNotaryState) => state.isLoading
);

export const selectIsReadyCompanyMyNotary = createSelector(
  selectCompanyMyNotaryState,
  (state: CompanyMyNotaryState) => !state.isLoading
);

export const selectIsReadyAndLoadedCompanyMyNotary = createSelector(
  selectCompanyMyNotaryState,
  (state: CompanyMyNotaryState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const CompanyMyNotaryModel: SelectorModel = {
  name: 'companyMyNotaries',
  getSelector: selectAllCompanyMyNotariesDictionary,
  isReady: selectIsReadyCompanyMyNotary
};

export const selectCompanyMyNotariesEntities = createSelector(selectCompanyMyNotaryState, selectEntities);

export const selectCompanyMyNotariesArray = createSelector(selectCompanyMyNotaryState, selectAll);

export const selectIdCompanyMyNotariesActive = createSelector(
  selectCompanyMyNotaryState,
  (state: CompanyMyNotaryState) => state.actives
);

const companyMyNotariesInObject = (companyMyNotaries: Dictionary<CompanyMyNotaryEntityState>) => ({
  companyMyNotaries
});

const selectCompanyMyNotariesEntitiesDictionary = createSelector(
  selectCompanyMyNotariesEntities,
  companyMyNotariesInObject
);

const selectAllCompanyMyNotariesObject = createSelector(selectCompanyMyNotariesEntities, companyMyNotaries => {
  return hydrateAll({ companyMyNotaries });
});

const selectOneCompanyMyNotaryDictionary = (idCompanyMyNotary: number) =>
  createSelector(selectCompanyMyNotariesEntities, companyMyNotaries => {
    return { companyMyNotaries: { [idCompanyMyNotary]: companyMyNotaries[idCompanyMyNotary] } };
  });

const selectOneCompanyMyNotaryDictionaryWithoutChild = (idCompanyMyNotary: number) =>
  createSelector(selectCompanyMyNotariesEntities, companyMyNotaries => {
    return { companyMyNotary: companyMyNotaries[idCompanyMyNotary] };
  });

const selectActiveCompanyMyNotariesEntities = createSelector(
  selectIdCompanyMyNotariesActive,
  selectCompanyMyNotariesEntities,
  (actives: number[], companyMyNotaries: Dictionary<CompanyMyNotaryEntityState>) =>
    getCompanyMyNotariesFromActives(actives, companyMyNotaries)
);

function getCompanyMyNotariesFromActives(
  actives: number[],
  companyMyNotaries: Dictionary<CompanyMyNotaryEntityState>
): Dictionary<CompanyMyNotaryEntityState> {
  return actives.reduce((acc, idActive) => {
    if (companyMyNotaries[idActive]) {
      acc[idActive] = companyMyNotaries[idActive];
    }
    return acc;
  }, {} as Dictionary<CompanyMyNotaryEntityState>);
}

const selectAllCompanyMyNotariesSelectors: Dictionary<Selector> = {};
export function selectAllCompanyMyNotaries(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CompanyMyNotary>(
      schema,
      selectAllCompanyMyNotariesSelectors,
      selectCompanyMyNotariesEntitiesDictionary,
      getRelationSelectors,
      companyMyNotaryRelations,
      hydrateAll,
      'companyMyNotary'
    );
  } else {
    return selectAllCompanyMyNotariesObject;
  }
}

export function selectAllCompanyMyNotariesDictionary(
  schema: SelectSchema = {},
  customKey: string = 'companyMyNotaries'
): Selector {
  return createSelector(selectAllCompanyMyNotaries(schema), result => {
    const res = { [customKey]: {} as Dictionary<CompanyMyNotaryEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.companyMyNotaries.length; i++) {
      res[customKey][result.companyMyNotaries[i].idCompanyMyNotary] = result.companyMyNotaries[i];
    }
    return res;
  });
}

export function selectOneCompanyMyNotary(schema: SelectSchema = {}, idCompanyMyNotary: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneCompanyMyNotaryDictionary(idCompanyMyNotary)];
    selectors.push(...getRelationSelectors(schema, companyMyNotaryRelations, 'companyMyNotary'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCompanyMyNotaryDictionaryWithoutChild(idCompanyMyNotary);
  }
}

export function selectActiveCompanyMyNotaries(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveCompanyMyNotariesEntities, companyMyNotaries => ({ companyMyNotaries }))
  ];
  selectors.push(...getRelationSelectors(schema, companyMyNotaryRelations, 'companyMyNotary'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  companyMyNotaries: Dictionary<CompanyMyNotaryEntityState>;
  companies?: Dictionary<CompanyEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { companyMyNotaries: (CompanyMyNotary | null)[] } {
  const { companyMyNotaries, companies } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    companyMyNotaries: Object.keys(companyMyNotaries).map(idCompanyMyNotary =>
      hydrate(companyMyNotaries[idCompanyMyNotary] as CompanyMyNotaryEntityState, companies)
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { companyMyNotary: CompanyMyNotaryEntityState | null } {
  const { companyMyNotaries, companies } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const companyMyNotary = Object.values(companyMyNotaries)[0];
  return { companyMyNotary: hydrate(companyMyNotary as CompanyMyNotaryEntityState, companies) };
}

function hydrate(
  companyMyNotary: CompanyMyNotaryEntityState,
  companyEntities?: Dictionary<CompanyEntityState>
): CompanyMyNotary | null {
  if (!companyMyNotary) {
    return null;
  }

  const companyMyNotaryHydrated: CompanyMyNotaryEntityState = { ...companyMyNotary };
  if (companyEntities) {
    companyMyNotaryHydrated.company = companyEntities[companyMyNotary.company as number] as Company;
  } else {
    delete companyMyNotaryHydrated.company;
  }

  return companyMyNotaryHydrated as CompanyMyNotary;
}
