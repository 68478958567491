import { RepositoryService } from '@_services/api/repository.service';
import { CompanyCommunicationGeneratedDocument } from '@_model/interfaces/company-communication-generated-document.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyCommunicationGeneratedDocumentApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyCommunicationGeneratedDocuments(params?: any): Observable<CompanyCommunicationGeneratedDocument[]> {
    return this.repo.getData<CompanyCommunicationGeneratedDocument[]>(
      'company-communication-generated-document',
      params
    );
  }

  public getCompanyCommunicationGeneratedDocument(params: {
    idCompanyCommunicationGeneratedDocument: number;
    params?: any;
  }): Observable<CompanyCommunicationGeneratedDocument> {
    return this.repo.getData<CompanyCommunicationGeneratedDocument>(
      'company-communication-generated-document/' + params.idCompanyCommunicationGeneratedDocument,
      params.params
    );
  }

  public addCompanyCommunicationGeneratedDocument(
    companyCommunicationGeneratedDocument: Partial<CompanyCommunicationGeneratedDocument>
  ): Observable<CompanyCommunicationGeneratedDocument> {
    return this.repo.create<CompanyCommunicationGeneratedDocument>(
      'company-communication-generated-document',
      companyCommunicationGeneratedDocument
    );
  }

  public updateCompanyCommunicationGeneratedDocument(
    companyCommunicationGeneratedDocument: Partial<CompanyCommunicationGeneratedDocument>
  ): Observable<CompanyCommunicationGeneratedDocument> {
    return this.repo.update('company-communication-generated-document', companyCommunicationGeneratedDocument);
  }

  public deleteCompanyCommunicationGeneratedDocument(
    idCompanyCommunicationGeneratedDocument: number
  ): Observable<number> {
    return this.repo.delete('company-communication-generated-document/' + +idCompanyCommunicationGeneratedDocument);
  }
}
