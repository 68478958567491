import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedDocumentsCompany } from '@_model/interfaces/generated-documents-company.model';
import { Observable } from 'rxjs';

export class GeneratedGeneratedDocumentsCompanyApiService {
  constructor(protected repo: RepositoryService) {}

  public getGeneratedDocumentsCompanies(params?: any): Observable<GeneratedDocumentsCompany[]> {
    return this.repo.getData<GeneratedDocumentsCompany[]>('generated-documents-company', params);
  }

  public getGeneratedDocumentsCompany(params: {
    idGeneratedDocumentsCompany: number;
    params?: any;
  }): Observable<GeneratedDocumentsCompany> {
    return this.repo.getData<GeneratedDocumentsCompany>(
      'generated-documents-company/' + params.idGeneratedDocumentsCompany,
      params.params
    );
  }

  public addGeneratedDocumentsCompany(
    generatedDocumentsCompany: Partial<GeneratedDocumentsCompany>
  ): Observable<GeneratedDocumentsCompany> {
    return this.repo.create<GeneratedDocumentsCompany>('generated-documents-company', generatedDocumentsCompany);
  }

  public updateGeneratedDocumentsCompany(
    generatedDocumentsCompany: Partial<GeneratedDocumentsCompany>
  ): Observable<GeneratedDocumentsCompany> {
    return this.repo.update('generated-documents-company', generatedDocumentsCompany);
  }

  public deleteGeneratedDocumentsCompany(idGeneratedDocumentsCompany: number): Observable<number> {
    return this.repo.delete('generated-documents-company/' + +idGeneratedDocumentsCompany);
  }
}
