import { Company } from '@_shared/models/interfaces/company.model';

namespace ActiveCompanyUtils {
  let _activeCompanies: number[] = reset();

  function reset(): number[] {
    return [];
  }

  export function getActiveCompaniesForRequest(): string[] {
    return _activeCompanies.map(num => num.toString());
  }
  export function setActiveCompanies(actives: number[]): void {
    _activeCompanies = actives;
  }
  export function mapToCompaniesIds(companies: Company[]): number[] {
    return companies.map(company => company?.idCompany).filter(it => it);
  }
}
export default ActiveCompanyUtils;
