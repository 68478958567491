import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DiagnosticApiService } from '@_services/api/diagnostic-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedDiagnosticEffects } from './diagnostic-generated.effects';

@Injectable()
export class DiagnosticEffects extends GeneratedDiagnosticEffects {
  constructor(actions$: Actions, diagnosticApiService: DiagnosticApiService, store$: Store<AppState>) {
    super(actions$, diagnosticApiService, store$);
  }
}
