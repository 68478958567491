import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { Organization, OrganizationEntityState } from '@_model/interfaces/organization.model';
import { OrganizationApiService } from '@_services/api/organization-api.service';
import * as organizationActions from '@_store/organization/organization.actions';
import { getActionsToNormalizeOrganization } from '@_config/store/normalization.generated';
import { selectOrganizationState } from './organization-generated.selectors';
import * as companyActions from '@_store/company/company.actions';
import { Company } from '@_model/interfaces/company.model';
import * as userActions from '@_store/user/user.actions';
import { User } from '@_model/interfaces/user.model';
import * as organizationMetierActions from '@_store/organization-metier/organization-metier.actions';
import { OrganizationMetier } from '@_model/interfaces/organization-metier.model';
import * as organizationSocieteActions from '@_store/organization-societe/organization-societe.actions';
import { OrganizationSociete } from '@_model/interfaces/organization-societe.model';
import * as organizationLeadTodoActions from '@_store/organization-lead-todo/organization-lead-todo.actions';
import { OrganizationLeadTodo } from '@_model/interfaces/organization-lead-todo.model';
import * as organizationLeadTodoRuleActions from '@_store/organization-lead-todo-rule/organization-lead-todo-rule.actions';
import { OrganizationLeadTodoRule } from '@_model/interfaces/organization-lead-todo-rule.model';
import * as organizationStratalotTodoRuleActions from '@_store/organization-stratalot-todo-rule/organization-stratalot-todo-rule.actions';
import { OrganizationStratalotTodoRule } from '@_model/interfaces/organization-stratalot-todo-rule.model';
import * as organizationResidenceTodoRuleActions from '@_store/organization-residence-todo-rule/organization-residence-todo-rule.actions';
import { OrganizationResidenceTodoRule } from '@_model/interfaces/organization-residence-todo-rule.model';
import * as organizationResidenceTodoActions from '@_store/organization-residence-todo/organization-residence-todo.actions';
import { OrganizationResidenceTodo } from '@_model/interfaces/organization-residence-todo.model';
import * as organizationStratalotTodoActions from '@_store/organization-stratalot-todo/organization-stratalot-todo.actions';
import { OrganizationStratalotTodo } from '@_model/interfaces/organization-stratalot-todo.model';
import * as organizationResidenceStudyTodoActions from '@_store/organization-residence-study-todo/organization-residence-study-todo.actions';
import { OrganizationResidenceStudyTodo } from '@_model/interfaces/organization-residence-study-todo.model';
import * as organizationProfilActions from '@_store/organization-profil/organization-profil.actions';
import { OrganizationProfil } from '@_model/interfaces/organization-profil.model';
import * as organizationProspectOriginActions from '@_store/organization-prospect-origin/organization-prospect-origin.actions';
import { OrganizationProspectOrigin } from '@_model/interfaces/organization-prospect-origin.model';
import * as prospectActions from '@_store/prospect/prospect.actions';
import { Prospect } from '@_model/interfaces/prospect.model';
import * as profilActions from '@_store/profil/profil.actions';
import { Profil } from '@_model/interfaces/profil.model';
import * as generatedDocumentActions from '@_store/generated-document/generated-document.actions';
import { GeneratedDocument } from '@_model/interfaces/generated-document.model';
import * as organizationThirdPartyActions from '@_store/organization-third-party/organization-third-party.actions';
import { OrganizationThirdParty } from '@_model/interfaces/organization-third-party.model';
import * as organizationStratalotAvancementActions from '@_store/organization-stratalot-avancement/organization-stratalot-avancement.actions';
import { OrganizationStratalotAvancement } from '@_model/interfaces/organization-stratalot-avancement.model';
import * as organizationLeadAvancementActions from '@_store/organization-lead-avancement/organization-lead-avancement.actions';
import { OrganizationLeadAvancement } from '@_model/interfaces/organization-lead-avancement.model';
import * as organizationSaleCategoryActions from '@_store/organization-sale-category/organization-sale-category.actions';
import { OrganizationSaleCategory } from '@_model/interfaces/organization-sale-category.model';
import * as organizationBuyingWishActions from '@_store/organization-buying-wish/organization-buying-wish.actions';
import { OrganizationBuyingWish } from '@_model/interfaces/organization-buying-wish.model';
import * as organizationEnergieActions from '@_store/organization-energie/organization-energie.actions';
import { OrganizationEnergie } from '@_model/interfaces/organization-energie.model';
import * as organizationForecastRateActions from '@_store/organization-forecast-rate/organization-forecast-rate.actions';
import { OrganizationForecastRate } from '@_model/interfaces/organization-forecast-rate.model';

export interface OrganizationRelationsIds {
  companies?: number | number[];
  users?: number | number[];
  organizationMetiers?: number | number[];
  organizationSocietes?: number | number[];
  organizationLeadTodos?: number | number[];
  organizationLeadTodoRules?: number | number[];
  organizationStratalotTodoRules?: number | number[];
  organizationResidenceTodoRules?: number | number[];
  organizationResidenceTodos?: number | number[];
  organizationStratalotTodos?: number | number[];
  organizationResidenceStudyTodos?: number | number[];
  organizationProfils?: number | number[];
  organizationProspectOrigins?: number | number[];
  prospects?: number | number[];
  profils?: number | number[];
  generatedDocuments?: number | number[];
  organizationThirdParties?: number | number[];
  organizationStratalotAvancements?: number | number[];
  organizationLeadAvancements?: number | number[];
  organizationSaleCategories?: number | number[];
  organizationBuyingWishes?: number | number[];
  organizationEnergies?: number | number[];
  organizationForecastRates?: number | number[];
}

export function getDefaultAddOrganizationActions(
  organization: OrganizationEntityState,
  ids?: OrganizationRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationActions.normalizeManyOrganizationsAfterUpsert({ organizations: [organization] })
  ];

  if (ids?.companies) {
    if (!Array.isArray(ids.companies)) {
      actions.push(
        companyActions.upsertOneCompany({
          company: {
            idOrganization: organization.idOrganization,
            idCompany: ids.companies as number
          } as Company
        })
      );
      actions.push(
        organizationActions.addManyCompanySuccess({
          idOrganization: organization.idOrganization,
          idCompanies: [ids.companies as number]
        })
      );
    } else {
      actions.push(
        companyActions.upsertManyCompanies({
          companies: (ids.companies as number[]).map((idCompany: number) => ({
            idOrganization: organization.idOrganization,
            idCompany
          })) as Company[]
        })
      );
      actions.push(
        organizationActions.addManyCompanySuccess({
          idOrganization: organization.idOrganization,
          idCompanies: ids.companies as number[]
        })
      );
    }
  }

  if (ids?.users) {
    if (!Array.isArray(ids.users)) {
      actions.push(
        userActions.upsertOneUser({
          user: {
            idOrganization: organization.idOrganization,
            idUser: ids.users as number
          } as User
        })
      );
      actions.push(
        organizationActions.addManyUserSuccess({
          idOrganization: organization.idOrganization,
          idUsers: [ids.users as number]
        })
      );
    } else {
      actions.push(
        userActions.upsertManyUsers({
          users: (ids.users as number[]).map((idUser: number) => ({
            idOrganization: organization.idOrganization,
            idUser
          })) as User[]
        })
      );
      actions.push(
        organizationActions.addManyUserSuccess({
          idOrganization: organization.idOrganization,
          idUsers: ids.users as number[]
        })
      );
    }
  }

  if (ids?.organizationMetiers) {
    if (!Array.isArray(ids.organizationMetiers)) {
      actions.push(
        organizationMetierActions.upsertOneOrganizationMetier({
          organizationMetier: {
            idOrganization: organization.idOrganization,
            idOrganizationMetier: ids.organizationMetiers as number
          } as OrganizationMetier
        })
      );
      actions.push(
        organizationActions.addManyOrganizationMetierSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationMetiers: [ids.organizationMetiers as number]
        })
      );
    } else {
      actions.push(
        organizationMetierActions.upsertManyOrganizationMetiers({
          organizationMetiers: (ids.organizationMetiers as number[]).map((idOrganizationMetier: number) => ({
            idOrganization: organization.idOrganization,
            idOrganizationMetier
          })) as OrganizationMetier[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationMetierSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationMetiers: ids.organizationMetiers as number[]
        })
      );
    }
  }

  if (ids?.organizationSocietes) {
    if (!Array.isArray(ids.organizationSocietes)) {
      actions.push(
        organizationSocieteActions.upsertOneOrganizationSociete({
          organizationSociete: {
            idOrganization: organization.idOrganization,
            idOrganizationSociete: ids.organizationSocietes as number
          } as OrganizationSociete
        })
      );
      actions.push(
        organizationActions.addManyOrganizationSocieteSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationSocietes: [ids.organizationSocietes as number]
        })
      );
    } else {
      actions.push(
        organizationSocieteActions.upsertManyOrganizationSocietes({
          organizationSocietes: (ids.organizationSocietes as number[]).map((idOrganizationSociete: number) => ({
            idOrganization: organization.idOrganization,
            idOrganizationSociete
          })) as OrganizationSociete[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationSocieteSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationSocietes: ids.organizationSocietes as number[]
        })
      );
    }
  }

  if (ids?.organizationLeadTodos) {
    if (!Array.isArray(ids.organizationLeadTodos)) {
      actions.push(
        organizationLeadTodoActions.upsertOneOrganizationLeadTodo({
          organizationLeadTodo: {
            idOrganization: organization.idOrganization,
            idOrganizationLeadTodo: ids.organizationLeadTodos as number
          } as OrganizationLeadTodo
        })
      );
      actions.push(
        organizationActions.addManyOrganizationLeadTodoSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationLeadTodos: [ids.organizationLeadTodos as number]
        })
      );
    } else {
      actions.push(
        organizationLeadTodoActions.upsertManyOrganizationLeadTodos({
          organizationLeadTodos: (ids.organizationLeadTodos as number[]).map((idOrganizationLeadTodo: number) => ({
            idOrganization: organization.idOrganization,
            idOrganizationLeadTodo
          })) as OrganizationLeadTodo[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationLeadTodoSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationLeadTodos: ids.organizationLeadTodos as number[]
        })
      );
    }
  }

  if (ids?.organizationLeadTodoRules) {
    if (!Array.isArray(ids.organizationLeadTodoRules)) {
      actions.push(
        organizationLeadTodoRuleActions.upsertOneOrganizationLeadTodoRule({
          organizationLeadTodoRule: {
            idOrganization: organization.idOrganization,
            idOrganizationLeadTodoRule: ids.organizationLeadTodoRules as number
          } as OrganizationLeadTodoRule
        })
      );
      actions.push(
        organizationActions.addManyOrganizationLeadTodoRuleSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationLeadTodoRules: [ids.organizationLeadTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationLeadTodoRuleActions.upsertManyOrganizationLeadTodoRules({
          organizationLeadTodoRules: (ids.organizationLeadTodoRules as number[]).map(
            (idOrganizationLeadTodoRule: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationLeadTodoRule
            })
          ) as OrganizationLeadTodoRule[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationLeadTodoRuleSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationLeadTodoRules: ids.organizationLeadTodoRules as number[]
        })
      );
    }
  }

  if (ids?.organizationStratalotTodoRules) {
    if (!Array.isArray(ids.organizationStratalotTodoRules)) {
      actions.push(
        organizationStratalotTodoRuleActions.upsertOneOrganizationStratalotTodoRule({
          organizationStratalotTodoRule: {
            idOrganization: organization.idOrganization,
            idOrganizationStratalotTodoRule: ids.organizationStratalotTodoRules as number
          } as OrganizationStratalotTodoRule
        })
      );
      actions.push(
        organizationActions.addManyOrganizationStratalotTodoRuleSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationStratalotTodoRules: [ids.organizationStratalotTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotTodoRuleActions.upsertManyOrganizationStratalotTodoRules({
          organizationStratalotTodoRules: (ids.organizationStratalotTodoRules as number[]).map(
            (idOrganizationStratalotTodoRule: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationStratalotTodoRule
            })
          ) as OrganizationStratalotTodoRule[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationStratalotTodoRuleSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationStratalotTodoRules: ids.organizationStratalotTodoRules as number[]
        })
      );
    }
  }

  if (ids?.organizationResidenceTodoRules) {
    if (!Array.isArray(ids.organizationResidenceTodoRules)) {
      actions.push(
        organizationResidenceTodoRuleActions.upsertOneOrganizationResidenceTodoRule({
          organizationResidenceTodoRule: {
            idOrganization: organization.idOrganization,
            idOrganizationResidenceTodoRule: ids.organizationResidenceTodoRules as number
          } as OrganizationResidenceTodoRule
        })
      );
      actions.push(
        organizationActions.addManyOrganizationResidenceTodoRuleSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationResidenceTodoRules: [ids.organizationResidenceTodoRules as number]
        })
      );
    } else {
      actions.push(
        organizationResidenceTodoRuleActions.upsertManyOrganizationResidenceTodoRules({
          organizationResidenceTodoRules: (ids.organizationResidenceTodoRules as number[]).map(
            (idOrganizationResidenceTodoRule: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationResidenceTodoRule
            })
          ) as OrganizationResidenceTodoRule[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationResidenceTodoRuleSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationResidenceTodoRules: ids.organizationResidenceTodoRules as number[]
        })
      );
    }
  }

  if (ids?.organizationResidenceTodos) {
    if (!Array.isArray(ids.organizationResidenceTodos)) {
      actions.push(
        organizationResidenceTodoActions.upsertOneOrganizationResidenceTodo({
          organizationResidenceTodo: {
            idOrganization: organization.idOrganization,
            idOrganizationResidenceTodo: ids.organizationResidenceTodos as number
          } as OrganizationResidenceTodo
        })
      );
      actions.push(
        organizationActions.addManyOrganizationResidenceTodoSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationResidenceTodos: [ids.organizationResidenceTodos as number]
        })
      );
    } else {
      actions.push(
        organizationResidenceTodoActions.upsertManyOrganizationResidenceTodos({
          organizationResidenceTodos: (ids.organizationResidenceTodos as number[]).map(
            (idOrganizationResidenceTodo: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationResidenceTodo
            })
          ) as OrganizationResidenceTodo[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationResidenceTodoSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationResidenceTodos: ids.organizationResidenceTodos as number[]
        })
      );
    }
  }

  if (ids?.organizationStratalotTodos) {
    if (!Array.isArray(ids.organizationStratalotTodos)) {
      actions.push(
        organizationStratalotTodoActions.upsertOneOrganizationStratalotTodo({
          organizationStratalotTodo: {
            idOrganization: organization.idOrganization,
            idOrganizationStratalotTodo: ids.organizationStratalotTodos as number
          } as OrganizationStratalotTodo
        })
      );
      actions.push(
        organizationActions.addManyOrganizationStratalotTodoSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationStratalotTodos: [ids.organizationStratalotTodos as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotTodoActions.upsertManyOrganizationStratalotTodos({
          organizationStratalotTodos: (ids.organizationStratalotTodos as number[]).map(
            (idOrganizationStratalotTodo: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationStratalotTodo
            })
          ) as OrganizationStratalotTodo[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationStratalotTodoSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationStratalotTodos: ids.organizationStratalotTodos as number[]
        })
      );
    }
  }

  if (ids?.organizationResidenceStudyTodos) {
    if (!Array.isArray(ids.organizationResidenceStudyTodos)) {
      actions.push(
        organizationResidenceStudyTodoActions.upsertOneOrganizationResidenceStudyTodo({
          organizationResidenceStudyTodo: {
            idOrganization: organization.idOrganization,
            idOrganizationResidenceStudyTodo: ids.organizationResidenceStudyTodos as number
          } as OrganizationResidenceStudyTodo
        })
      );
      actions.push(
        organizationActions.addManyOrganizationResidenceStudyTodoSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationResidenceStudyTodos: [ids.organizationResidenceStudyTodos as number]
        })
      );
    } else {
      actions.push(
        organizationResidenceStudyTodoActions.upsertManyOrganizationResidenceStudyTodos({
          organizationResidenceStudyTodos: (ids.organizationResidenceStudyTodos as number[]).map(
            (idOrganizationResidenceStudyTodo: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationResidenceStudyTodo
            })
          ) as OrganizationResidenceStudyTodo[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationResidenceStudyTodoSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationResidenceStudyTodos: ids.organizationResidenceStudyTodos as number[]
        })
      );
    }
  }

  if (ids?.organizationProfils) {
    if (!Array.isArray(ids.organizationProfils)) {
      actions.push(
        organizationProfilActions.upsertOneOrganizationProfil({
          organizationProfil: {
            idOrganization: organization.idOrganization,
            idOrganizationProfil: ids.organizationProfils as number
          } as OrganizationProfil
        })
      );
      actions.push(
        organizationActions.addManyOrganizationProfilSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationProfils: [ids.organizationProfils as number]
        })
      );
    } else {
      actions.push(
        organizationProfilActions.upsertManyOrganizationProfils({
          organizationProfils: (ids.organizationProfils as number[]).map((idOrganizationProfil: number) => ({
            idOrganization: organization.idOrganization,
            idOrganizationProfil
          })) as OrganizationProfil[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationProfilSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationProfils: ids.organizationProfils as number[]
        })
      );
    }
  }

  if (ids?.organizationProspectOrigins) {
    if (!Array.isArray(ids.organizationProspectOrigins)) {
      actions.push(
        organizationProspectOriginActions.upsertOneOrganizationProspectOrigin({
          organizationProspectOrigin: {
            idOrganization: organization.idOrganization,
            idOrganizationProspectOrigin: ids.organizationProspectOrigins as number
          } as OrganizationProspectOrigin
        })
      );
      actions.push(
        organizationActions.addManyOrganizationProspectOriginSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationProspectOrigins: [ids.organizationProspectOrigins as number]
        })
      );
    } else {
      actions.push(
        organizationProspectOriginActions.upsertManyOrganizationProspectOrigins({
          organizationProspectOrigins: (ids.organizationProspectOrigins as number[]).map(
            (idOrganizationProspectOrigin: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationProspectOrigin
            })
          ) as OrganizationProspectOrigin[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationProspectOriginSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationProspectOrigins: ids.organizationProspectOrigins as number[]
        })
      );
    }
  }

  if (ids?.prospects) {
    if (!Array.isArray(ids.prospects)) {
      actions.push(
        prospectActions.upsertOneProspect({
          prospect: {
            idOrganization: organization.idOrganization,
            idProspect: ids.prospects as number
          } as Prospect
        })
      );
      actions.push(
        organizationActions.addManyProspectSuccess({
          idOrganization: organization.idOrganization,
          idProspects: [ids.prospects as number]
        })
      );
    } else {
      actions.push(
        prospectActions.upsertManyProspects({
          prospects: (ids.prospects as number[]).map((idProspect: number) => ({
            idOrganization: organization.idOrganization,
            idProspect
          })) as Prospect[]
        })
      );
      actions.push(
        organizationActions.addManyProspectSuccess({
          idOrganization: organization.idOrganization,
          idProspects: ids.prospects as number[]
        })
      );
    }
  }

  if (ids?.profils) {
    if (!Array.isArray(ids.profils)) {
      actions.push(
        profilActions.upsertOneProfil({
          profil: {
            idOrganization: organization.idOrganization,
            idProfil: ids.profils as number
          } as Profil
        })
      );
      actions.push(
        organizationActions.addManyProfilSuccess({
          idOrganization: organization.idOrganization,
          idProfils: [ids.profils as number]
        })
      );
    } else {
      actions.push(
        profilActions.upsertManyProfils({
          profils: (ids.profils as number[]).map((idProfil: number) => ({
            idOrganization: organization.idOrganization,
            idProfil
          })) as Profil[]
        })
      );
      actions.push(
        organizationActions.addManyProfilSuccess({
          idOrganization: organization.idOrganization,
          idProfils: ids.profils as number[]
        })
      );
    }
  }

  if (ids?.generatedDocuments) {
    if (!Array.isArray(ids.generatedDocuments)) {
      actions.push(
        generatedDocumentActions.upsertOneGeneratedDocument({
          generatedDocument: {
            idOrganization: organization.idOrganization,
            idGeneratedDocument: ids.generatedDocuments as number
          } as GeneratedDocument
        })
      );
      actions.push(
        organizationActions.addManyGeneratedDocumentSuccess({
          idOrganization: organization.idOrganization,
          idGeneratedDocuments: [ids.generatedDocuments as number]
        })
      );
    } else {
      actions.push(
        generatedDocumentActions.upsertManyGeneratedDocuments({
          generatedDocuments: (ids.generatedDocuments as number[]).map((idGeneratedDocument: number) => ({
            idOrganization: organization.idOrganization,
            idGeneratedDocument
          })) as GeneratedDocument[]
        })
      );
      actions.push(
        organizationActions.addManyGeneratedDocumentSuccess({
          idOrganization: organization.idOrganization,
          idGeneratedDocuments: ids.generatedDocuments as number[]
        })
      );
    }
  }

  if (ids?.organizationThirdParties) {
    if (!Array.isArray(ids.organizationThirdParties)) {
      actions.push(
        organizationThirdPartyActions.upsertOneOrganizationThirdParty({
          organizationThirdParty: {
            idOrganization: organization.idOrganization,
            idOrganizationThirdParty: ids.organizationThirdParties as number
          } as OrganizationThirdParty
        })
      );
      actions.push(
        organizationActions.addManyOrganizationThirdPartySuccess({
          idOrganization: organization.idOrganization,
          idOrganizationThirdParties: [ids.organizationThirdParties as number]
        })
      );
    } else {
      actions.push(
        organizationThirdPartyActions.upsertManyOrganizationThirdParties({
          organizationThirdParties: (ids.organizationThirdParties as number[]).map(
            (idOrganizationThirdParty: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationThirdParty
            })
          ) as OrganizationThirdParty[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationThirdPartySuccess({
          idOrganization: organization.idOrganization,
          idOrganizationThirdParties: ids.organizationThirdParties as number[]
        })
      );
    }
  }

  if (ids?.organizationStratalotAvancements) {
    if (!Array.isArray(ids.organizationStratalotAvancements)) {
      actions.push(
        organizationStratalotAvancementActions.upsertOneOrganizationStratalotAvancement({
          organizationStratalotAvancement: {
            idOrganization: organization.idOrganization,
            idOrganizationStratalotAvancement: ids.organizationStratalotAvancements as number
          } as OrganizationStratalotAvancement
        })
      );
      actions.push(
        organizationActions.addManyOrganizationStratalotAvancementSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationStratalotAvancements: [ids.organizationStratalotAvancements as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotAvancementActions.upsertManyOrganizationStratalotAvancements({
          organizationStratalotAvancements: (ids.organizationStratalotAvancements as number[]).map(
            (idOrganizationStratalotAvancement: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationStratalotAvancement
            })
          ) as OrganizationStratalotAvancement[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationStratalotAvancementSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationStratalotAvancements: ids.organizationStratalotAvancements as number[]
        })
      );
    }
  }

  if (ids?.organizationLeadAvancements) {
    if (!Array.isArray(ids.organizationLeadAvancements)) {
      actions.push(
        organizationLeadAvancementActions.upsertOneOrganizationLeadAvancement({
          organizationLeadAvancement: {
            idOrganization: organization.idOrganization,
            idOrganizationLeadAvancement: ids.organizationLeadAvancements as number
          } as OrganizationLeadAvancement
        })
      );
      actions.push(
        organizationActions.addManyOrganizationLeadAvancementSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationLeadAvancements: [ids.organizationLeadAvancements as number]
        })
      );
    } else {
      actions.push(
        organizationLeadAvancementActions.upsertManyOrganizationLeadAvancements({
          organizationLeadAvancements: (ids.organizationLeadAvancements as number[]).map(
            (idOrganizationLeadAvancement: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationLeadAvancement
            })
          ) as OrganizationLeadAvancement[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationLeadAvancementSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationLeadAvancements: ids.organizationLeadAvancements as number[]
        })
      );
    }
  }

  if (ids?.organizationSaleCategories) {
    if (!Array.isArray(ids.organizationSaleCategories)) {
      actions.push(
        organizationSaleCategoryActions.upsertOneOrganizationSaleCategory({
          organizationSaleCategory: {
            idOrganization: organization.idOrganization,
            idOrganizationSaleCategory: ids.organizationSaleCategories as number
          } as OrganizationSaleCategory
        })
      );
      actions.push(
        organizationActions.addManyOrganizationSaleCategorySuccess({
          idOrganization: organization.idOrganization,
          idOrganizationSaleCategories: [ids.organizationSaleCategories as number]
        })
      );
    } else {
      actions.push(
        organizationSaleCategoryActions.upsertManyOrganizationSaleCategories({
          organizationSaleCategories: (ids.organizationSaleCategories as number[]).map(
            (idOrganizationSaleCategory: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationSaleCategory
            })
          ) as OrganizationSaleCategory[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationSaleCategorySuccess({
          idOrganization: organization.idOrganization,
          idOrganizationSaleCategories: ids.organizationSaleCategories as number[]
        })
      );
    }
  }

  if (ids?.organizationBuyingWishes) {
    if (!Array.isArray(ids.organizationBuyingWishes)) {
      actions.push(
        organizationBuyingWishActions.upsertOneOrganizationBuyingWish({
          organizationBuyingWish: {
            idOrganization: organization.idOrganization,
            idOrganizationBuyingWish: ids.organizationBuyingWishes as number
          } as OrganizationBuyingWish
        })
      );
      actions.push(
        organizationActions.addManyOrganizationBuyingWishSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationBuyingWishes: [ids.organizationBuyingWishes as number]
        })
      );
    } else {
      actions.push(
        organizationBuyingWishActions.upsertManyOrganizationBuyingWishes({
          organizationBuyingWishes: (ids.organizationBuyingWishes as number[]).map(
            (idOrganizationBuyingWish: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationBuyingWish
            })
          ) as OrganizationBuyingWish[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationBuyingWishSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationBuyingWishes: ids.organizationBuyingWishes as number[]
        })
      );
    }
  }

  if (ids?.organizationEnergies) {
    if (!Array.isArray(ids.organizationEnergies)) {
      actions.push(
        organizationEnergieActions.upsertOneOrganizationEnergie({
          organizationEnergie: {
            idOrganization: organization.idOrganization,
            idOrganizationEnergie: ids.organizationEnergies as number
          } as OrganizationEnergie
        })
      );
      actions.push(
        organizationActions.addManyOrganizationEnergieSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationEnergies: [ids.organizationEnergies as number]
        })
      );
    } else {
      actions.push(
        organizationEnergieActions.upsertManyOrganizationEnergies({
          organizationEnergies: (ids.organizationEnergies as number[]).map((idOrganizationEnergie: number) => ({
            idOrganization: organization.idOrganization,
            idOrganizationEnergie
          })) as OrganizationEnergie[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationEnergieSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationEnergies: ids.organizationEnergies as number[]
        })
      );
    }
  }

  if (ids?.organizationForecastRates) {
    if (!Array.isArray(ids.organizationForecastRates)) {
      actions.push(
        organizationForecastRateActions.upsertOneOrganizationForecastRate({
          organizationForecastRate: {
            idOrganization: organization.idOrganization,
            idOrganizationForecastRate: ids.organizationForecastRates as number
          } as OrganizationForecastRate
        })
      );
      actions.push(
        organizationActions.addManyOrganizationForecastRateSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationForecastRates: [ids.organizationForecastRates as number]
        })
      );
    } else {
      actions.push(
        organizationForecastRateActions.upsertManyOrganizationForecastRates({
          organizationForecastRates: (ids.organizationForecastRates as number[]).map(
            (idOrganizationForecastRate: number) => ({
              idOrganization: organization.idOrganization,
              idOrganizationForecastRate
            })
          ) as OrganizationForecastRate[]
        })
      );
      actions.push(
        organizationActions.addManyOrganizationForecastRateSuccess({
          idOrganization: organization.idOrganization,
          idOrganizationForecastRates: ids.organizationForecastRates as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationActions(organization: OrganizationEntityState): Action[] {
  const actions: Action[] = [
    organizationActions.deleteOneOrganizationSuccess({ idOrganization: organization.idOrganization })
  ];

  if (organization.companies) {
    actions.push(
      companyActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idCompanies: organization.companies as number[]
      })
    );
  }

  if (organization.users) {
    actions.push(
      userActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idUsers: organization.users as number[]
      })
    );
  }

  if (organization.organizationMetiers) {
    actions.push(
      organizationMetierActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationMetiers: organization.organizationMetiers as number[]
      })
    );
  }

  if (organization.organizationSocietes) {
    actions.push(
      organizationSocieteActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationSocietes: organization.organizationSocietes as number[]
      })
    );
  }

  if (organization.organizationLeadTodos) {
    actions.push(
      organizationLeadTodoActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationLeadTodos: organization.organizationLeadTodos as number[]
      })
    );
  }

  if (organization.organizationLeadTodoRules) {
    actions.push(
      organizationLeadTodoRuleActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationLeadTodoRules: organization.organizationLeadTodoRules as number[]
      })
    );
  }

  if (organization.organizationStratalotTodoRules) {
    actions.push(
      organizationStratalotTodoRuleActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationStratalotTodoRules: organization.organizationStratalotTodoRules as number[]
      })
    );
  }

  if (organization.organizationResidenceTodoRules) {
    actions.push(
      organizationResidenceTodoRuleActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationResidenceTodoRules: organization.organizationResidenceTodoRules as number[]
      })
    );
  }

  if (organization.organizationResidenceTodos) {
    actions.push(
      organizationResidenceTodoActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationResidenceTodos: organization.organizationResidenceTodos as number[]
      })
    );
  }

  if (organization.organizationStratalotTodos) {
    actions.push(
      organizationStratalotTodoActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationStratalotTodos: organization.organizationStratalotTodos as number[]
      })
    );
  }

  if (organization.organizationResidenceStudyTodos) {
    actions.push(
      organizationResidenceStudyTodoActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationResidenceStudyTodos: organization.organizationResidenceStudyTodos as number[]
      })
    );
  }

  if (organization.organizationProfils) {
    actions.push(
      organizationProfilActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationProfils: organization.organizationProfils as number[]
      })
    );
  }

  if (organization.organizationProspectOrigins) {
    actions.push(
      organizationProspectOriginActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationProspectOrigins: organization.organizationProspectOrigins as number[]
      })
    );
  }

  if (organization.prospects) {
    actions.push(
      prospectActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idProspects: organization.prospects as number[]
      })
    );
  }

  if (organization.profils) {
    actions.push(
      profilActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idProfils: organization.profils as number[]
      })
    );
  }

  if (organization.generatedDocuments) {
    actions.push(
      generatedDocumentActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idGeneratedDocuments: organization.generatedDocuments as number[]
      })
    );
  }

  if (organization.organizationThirdParties) {
    actions.push(
      organizationThirdPartyActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationThirdParties: organization.organizationThirdParties as number[]
      })
    );
  }

  if (organization.organizationStratalotAvancements) {
    actions.push(
      organizationStratalotAvancementActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationStratalotAvancements: organization.organizationStratalotAvancements as number[]
      })
    );
  }

  if (organization.organizationLeadAvancements) {
    actions.push(
      organizationLeadAvancementActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationLeadAvancements: organization.organizationLeadAvancements as number[]
      })
    );
  }

  if (organization.organizationSaleCategories) {
    actions.push(
      organizationSaleCategoryActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationSaleCategories: organization.organizationSaleCategories as number[]
      })
    );
  }

  if (organization.organizationBuyingWishes) {
    actions.push(
      organizationBuyingWishActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationBuyingWishes: organization.organizationBuyingWishes as number[]
      })
    );
  }

  if (organization.organizationEnergies) {
    actions.push(
      organizationEnergieActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationEnergies: organization.organizationEnergies as number[]
      })
    );
  }

  if (organization.organizationForecastRates) {
    actions.push(
      organizationForecastRateActions.deleteManyOrganizationSuccess({
        idOrganizations: [organization.idOrganization],
        idOrganizationForecastRates: organization.organizationForecastRates as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationEffects {
  constructor(
    protected actions$: Actions,
    protected organizationApiService: OrganizationApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationActions.getManyOrganizations),
      switchMap(({ params }) =>
        this.organizationApiService.getOrganizations(params).pipe(
          map((organizations: Organization[]) => {
            return organizationActions.normalizeManyOrganizationsAfterUpsert({ organizations });
          }),
          catchError(error => of(organizationActions.organizationsFailure({ error })))
        )
      )
    );
  });

  getOneOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationActions.getOneOrganization),
      switchMap(idOrganization =>
        this.organizationApiService.getOrganization(idOrganization).pipe(
          map((organization: Organization) => {
            return organizationActions.normalizeManyOrganizationsAfterUpsert({ organizations: [organization] });
          }),
          catchError(error => of(organizationActions.organizationsFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationActions.upsertOneOrganization),
      concatMap(({ organization, ids }: { organization: Partial<Organization>; ids?: OrganizationRelationsIds }) => {
        if (organization.idOrganization) {
          return this.organizationApiService.updateOrganization(organization).pipe(
            map((organizationReturned: Organization) => {
              return organizationActions.normalizeManyOrganizationsAfterUpsert({
                organizations: [organizationReturned]
              });
            }),
            catchError(error => of(organizationActions.organizationsFailure({ error })))
          );
        } else {
          return this.organizationApiService.addOrganization(organization).pipe(
            mergeMap((organizationReturned: Organization) =>
              getDefaultAddOrganizationActions(organizationReturned, ids)
            ),
            catchError(error => of(organizationActions.organizationsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneOrganization$ = createEffect(() => {
    const selectOrganizationState$ = this.store$.select(selectOrganizationState);
    return this.actions$.pipe(
      ofType(organizationActions.deleteOneOrganization),
      withLatestFrom(selectOrganizationState$),
      concatMap(([{ idOrganization }, state]) =>
        this.organizationApiService.deleteOrganization(idOrganization).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOrganizationActions(state.entities[idOrganization] as OrganizationEntityState),
              organizationActions.deleteOneOrganization.type
            )
          ]),
          catchError(error => of(organizationActions.organizationsFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationActions.normalizeManyOrganizationsAfterUpsert),
      concatMap(({ organizations }) => {
        const actions: Action[] = getActionsToNormalizeOrganization(organizations, StoreActionType.upsert);
        return [getMultiAction(actions, '[Organization] Normalization After Upsert Success')];
      })
    );
  });
}
