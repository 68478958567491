import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as ResidenceSaleCategoryFamilyActions from './residence-sale-category-family.actions';
import { adapter, initialState, ResidenceSaleCategoryFamilyState } from './residence-sale-category-family.state';
import { Dictionary } from '@ngrx/entity';
import { ResidenceSaleCategoryFamilyEntityState } from '@_model/interfaces/residence-sale-category-family.model';

export const residenceSaleCategoryFamilyReducersGeneratedFunctions: ReducerTypes<
  ResidenceSaleCategoryFamilyState,
  readonly ActionCreator[]
>[] = [
  on(ResidenceSaleCategoryFamilyActions.getOneResidenceSaleCategoryFamily, (state: ResidenceSaleCategoryFamilyState) =>
    setLoadingsState(state, true)
  ),
  on(
    ResidenceSaleCategoryFamilyActions.getManyResidenceSaleCategoryFamilies,
    (state: ResidenceSaleCategoryFamilyState) => setLoadingsState(state, true)
  ),
  on(
    ResidenceSaleCategoryFamilyActions.upsertOneResidenceSaleCategoryFamily,
    (state: ResidenceSaleCategoryFamilyState) => setLoadingsState(state, true)
  ),

  on(
    ResidenceSaleCategoryFamilyActions.upsertManyResidenceSaleCategoryFamiliesSuccess,
    (state: ResidenceSaleCategoryFamilyState, { residenceSaleCategoryFamilies }) =>
      adapter.upsertMany(residenceSaleCategoryFamilies, setLoadingsState(state, false))
  ),
  on(
    ResidenceSaleCategoryFamilyActions.deleteOneResidenceSaleCategoryFamily,
    (state: ResidenceSaleCategoryFamilyState) => setLoadingsState(state, true)
  ),
  on(
    ResidenceSaleCategoryFamilyActions.deleteOneResidenceSaleCategoryFamilySuccess,
    (state: ResidenceSaleCategoryFamilyState, { idResidenceSaleCategoryFamily }) =>
      adapter.removeOne(idResidenceSaleCategoryFamily, setLoadingsState(state, false))
  ),
  on(ResidenceSaleCategoryFamilyActions.clearActive, (state: ResidenceSaleCategoryFamilyState) => ({
    ...state,
    actives: []
  })),
  on(
    ResidenceSaleCategoryFamilyActions.addManyActives,
    (state: ResidenceSaleCategoryFamilyState, { idResidenceSaleCategoryFamilies }) => ({
      ...state,
      actives: state.actives.concat(idResidenceSaleCategoryFamilies)
    })
  ),
  on(
    ResidenceSaleCategoryFamilyActions.deleteOneActive,
    (state: ResidenceSaleCategoryFamilyState, { idResidenceSaleCategoryFamily }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idResidenceSaleCategoryFamily)
    })
  ),

  on(ResidenceSaleCategoryFamilyActions.clearStore, () => initialState),

  on(
    ResidenceSaleCategoryFamilyActions.addResidenceSuccess,
    (state: ResidenceSaleCategoryFamilyState, { idResidenceSaleCategoryFamily, idResidence }) => {
      if (!state.entities[idResidenceSaleCategoryFamily]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceSaleCategoryFamily]: {
            ...state.entities[idResidenceSaleCategoryFamily],
            residence: idResidence
          }
        }
      };
    }
  ),

  on(
    ResidenceSaleCategoryFamilyActions.deleteManyResidenceSuccess,
    (state: ResidenceSaleCategoryFamilyState, { idResidences, idResidenceSaleCategoryFamilies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceSaleCategoryFamilies.reduce((entities, idResidenceSaleCategoryFamily) => {
            if (!state.entities[idResidenceSaleCategoryFamily]?.residence) {
              return entities;
            }
            entities[idResidenceSaleCategoryFamily] = {
              ...state.entities[idResidenceSaleCategoryFamily],
              residence: idResidences.some(
                (idResidence: number) => idResidence === state.entities[idResidenceSaleCategoryFamily]?.residence
              )
                ? undefined
                : state.entities[idResidenceSaleCategoryFamily]?.residence
            } as ResidenceSaleCategoryFamilyEntityState;
            return entities;
          }, {} as Dictionary<ResidenceSaleCategoryFamilyEntityState>)
        }
      };
    }
  ),

  on(
    ResidenceSaleCategoryFamilyActions.addSaleCategoryFamilySuccess,
    (state: ResidenceSaleCategoryFamilyState, { idResidenceSaleCategoryFamily, idSaleCategoryFamily }) => {
      if (!state.entities[idResidenceSaleCategoryFamily]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idResidenceSaleCategoryFamily]: {
            ...state.entities[idResidenceSaleCategoryFamily],
            saleCategoryFamily: idSaleCategoryFamily
          }
        }
      };
    }
  ),

  on(
    ResidenceSaleCategoryFamilyActions.deleteManySaleCategoryFamilySuccess,
    (state: ResidenceSaleCategoryFamilyState, { idFamilySaleCategories, idResidenceSaleCategoryFamilies }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idResidenceSaleCategoryFamilies.reduce((entities, idResidenceSaleCategoryFamily) => {
            if (!state.entities[idResidenceSaleCategoryFamily]?.saleCategoryFamily) {
              return entities;
            }
            entities[idResidenceSaleCategoryFamily] = {
              ...state.entities[idResidenceSaleCategoryFamily],
              saleCategoryFamily: idFamilySaleCategories.some(
                (idSaleCategoryFamily: number) =>
                  idSaleCategoryFamily === state.entities[idResidenceSaleCategoryFamily]?.saleCategoryFamily
              )
                ? undefined
                : state.entities[idResidenceSaleCategoryFamily]?.saleCategoryFamily
            } as ResidenceSaleCategoryFamilyEntityState;
            return entities;
          }, {} as Dictionary<ResidenceSaleCategoryFamilyEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: ResidenceSaleCategoryFamilyState,
  isLoading: boolean,
  isLoaded: boolean = true
): ResidenceSaleCategoryFamilyState {
  return { ...state, isLoaded, isLoading };
}
