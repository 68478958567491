import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResidenceWorkApiService } from '@_services/api/residence-work-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedResidenceWorkEffects } from './residence-work-generated.effects';

@Injectable()
export class ResidenceWorkEffects extends GeneratedResidenceWorkEffects {
  constructor(actions$: Actions, residenceWorkApiService: ResidenceWorkApiService, store$: Store<AppState>) {
    super(actions$, residenceWorkApiService, store$);
  }
}
