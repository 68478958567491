import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ResidenceContactApiService } from '@_services/api/residence-contact-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedResidenceContactEffects } from './residence-contact-generated.effects';

@Injectable()
export class ResidenceContactEffects extends GeneratedResidenceContactEffects {
  constructor(actions$: Actions, residenceContactApiService: ResidenceContactApiService, store$: Store<AppState>) {
    super(actions$, residenceContactApiService, store$);
  }
}
