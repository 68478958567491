import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CompanyCommunicationRecipientMetierEntityState } from '@_model/interfaces/company-communication-recipient-metier.model';

export interface CompanyCommunicationRecipientMetierState
  extends EntityState<CompanyCommunicationRecipientMetierEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<CompanyCommunicationRecipientMetierEntityState> =
  createEntityAdapter<CompanyCommunicationRecipientMetierEntityState>({
    selectId: o => o.idCompanyCommunicationRecipientMetier
  });
export const initialState: CompanyCommunicationRecipientMetierState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const companyCommunicationRecipientMetierFeatureKey = 'companyCommunicationRecipientMetier';
