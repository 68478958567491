import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { Step, StepEntityState } from '@_model/interfaces/step.model';
import { StepApiService } from '@_services/api/step-api.service';
import * as stepActions from '@_store/step/step.actions';
import { getActionsToNormalizeStep } from '@_config/store/normalization.generated';
import { selectStepState } from './step-generated.selectors';
import * as stepProgressActions from '@_store/step-progress/step-progress.actions';
import { StepProgress } from '@_model/interfaces/step-progress.model';
import * as organizationLeadTodoActions from '@_store/organization-lead-todo/organization-lead-todo.actions';
import { OrganizationLeadTodo } from '@_model/interfaces/organization-lead-todo.model';
import * as organizationStratalotAvancementActions from '@_store/organization-stratalot-avancement/organization-stratalot-avancement.actions';
import { OrganizationStratalotAvancement } from '@_model/interfaces/organization-stratalot-avancement.model';
import * as organizationLeadAvancementActions from '@_store/organization-lead-avancement/organization-lead-avancement.actions';
import { OrganizationLeadAvancement } from '@_model/interfaces/organization-lead-avancement.model';

export interface StepRelationsIds {
  stepProgresses?: number | number[];
  organizationLeadTodos?: number | number[];
  organizationStratalotAvancements?: number | number[];
  organizationLeadAvancements?: number | number[];
}

export function getDefaultAddStepActions(step: StepEntityState, ids?: StepRelationsIds): Action[] {
  const actions: Action[] = [stepActions.normalizeManyStepsAfterUpsert({ steps: [step] })];

  if (ids?.stepProgresses) {
    if (!Array.isArray(ids.stepProgresses)) {
      actions.push(
        stepProgressActions.upsertOneStepProgress({
          stepProgress: {
            idStep: step.idStep,
            idStepProgress: ids.stepProgresses as number
          } as StepProgress
        })
      );
      actions.push(
        stepActions.addManyStepProgressSuccess({
          idStep: step.idStep,
          idStepProgresses: [ids.stepProgresses as number]
        })
      );
    } else {
      actions.push(
        stepProgressActions.upsertManyStepProgresses({
          stepProgresses: (ids.stepProgresses as number[]).map((idStepProgress: number) => ({
            idStep: step.idStep,
            idStepProgress
          })) as StepProgress[]
        })
      );
      actions.push(
        stepActions.addManyStepProgressSuccess({
          idStep: step.idStep,
          idStepProgresses: ids.stepProgresses as number[]
        })
      );
    }
  }

  if (ids?.organizationLeadTodos) {
    if (!Array.isArray(ids.organizationLeadTodos)) {
      actions.push(
        organizationLeadTodoActions.upsertOneOrganizationLeadTodo({
          organizationLeadTodo: {
            idStep: step.idStep,
            idOrganizationLeadTodo: ids.organizationLeadTodos as number
          } as OrganizationLeadTodo
        })
      );
      actions.push(
        stepActions.addManyOrganizationLeadTodoSuccess({
          idStep: step.idStep,
          idOrganizationLeadTodos: [ids.organizationLeadTodos as number]
        })
      );
    } else {
      actions.push(
        organizationLeadTodoActions.upsertManyOrganizationLeadTodos({
          organizationLeadTodos: (ids.organizationLeadTodos as number[]).map((idOrganizationLeadTodo: number) => ({
            idStep: step.idStep,
            idOrganizationLeadTodo
          })) as OrganizationLeadTodo[]
        })
      );
      actions.push(
        stepActions.addManyOrganizationLeadTodoSuccess({
          idStep: step.idStep,
          idOrganizationLeadTodos: ids.organizationLeadTodos as number[]
        })
      );
    }
  }

  if (ids?.organizationStratalotAvancements) {
    if (!Array.isArray(ids.organizationStratalotAvancements)) {
      actions.push(
        organizationStratalotAvancementActions.upsertOneOrganizationStratalotAvancement({
          organizationStratalotAvancement: {
            idStep: step.idStep,
            idOrganizationStratalotAvancement: ids.organizationStratalotAvancements as number
          } as OrganizationStratalotAvancement
        })
      );
      actions.push(
        stepActions.addManyOrganizationStratalotAvancementSuccess({
          idStep: step.idStep,
          idOrganizationStratalotAvancements: [ids.organizationStratalotAvancements as number]
        })
      );
    } else {
      actions.push(
        organizationStratalotAvancementActions.upsertManyOrganizationStratalotAvancements({
          organizationStratalotAvancements: (ids.organizationStratalotAvancements as number[]).map(
            (idOrganizationStratalotAvancement: number) => ({
              idStep: step.idStep,
              idOrganizationStratalotAvancement
            })
          ) as OrganizationStratalotAvancement[]
        })
      );
      actions.push(
        stepActions.addManyOrganizationStratalotAvancementSuccess({
          idStep: step.idStep,
          idOrganizationStratalotAvancements: ids.organizationStratalotAvancements as number[]
        })
      );
    }
  }

  if (ids?.organizationLeadAvancements) {
    if (!Array.isArray(ids.organizationLeadAvancements)) {
      actions.push(
        organizationLeadAvancementActions.upsertOneOrganizationLeadAvancement({
          organizationLeadAvancement: {
            idStep: step.idStep,
            idOrganizationLeadAvancement: ids.organizationLeadAvancements as number
          } as OrganizationLeadAvancement
        })
      );
      actions.push(
        stepActions.addManyOrganizationLeadAvancementSuccess({
          idStep: step.idStep,
          idOrganizationLeadAvancements: [ids.organizationLeadAvancements as number]
        })
      );
    } else {
      actions.push(
        organizationLeadAvancementActions.upsertManyOrganizationLeadAvancements({
          organizationLeadAvancements: (ids.organizationLeadAvancements as number[]).map(
            (idOrganizationLeadAvancement: number) => ({
              idStep: step.idStep,
              idOrganizationLeadAvancement
            })
          ) as OrganizationLeadAvancement[]
        })
      );
      actions.push(
        stepActions.addManyOrganizationLeadAvancementSuccess({
          idStep: step.idStep,
          idOrganizationLeadAvancements: ids.organizationLeadAvancements as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteStepActions(step: StepEntityState): Action[] {
  const actions: Action[] = [stepActions.deleteOneStepSuccess({ idStep: step.idStep })];

  if (step.stepProgresses) {
    actions.push(
      stepProgressActions.deleteManyStepSuccess({
        idSteps: [step.idStep],
        idStepProgresses: step.stepProgresses as number[]
      })
    );
  }

  if (step.organizationLeadTodos) {
    actions.push(
      organizationLeadTodoActions.deleteManyStepSuccess({
        idSteps: [step.idStep],
        idOrganizationLeadTodos: step.organizationLeadTodos as number[]
      })
    );
  }

  if (step.organizationStratalotAvancements) {
    actions.push(
      organizationStratalotAvancementActions.deleteManyStepSuccess({
        idSteps: [step.idStep],
        idOrganizationStratalotAvancements: step.organizationStratalotAvancements as number[]
      })
    );
  }

  if (step.organizationLeadAvancements) {
    actions.push(
      organizationLeadAvancementActions.deleteManyStepSuccess({
        idSteps: [step.idStep],
        idOrganizationLeadAvancements: step.organizationLeadAvancements as number[]
      })
    );
  }

  return actions;
}

export class GeneratedStepEffects {
  constructor(
    protected actions$: Actions,
    protected stepApiService: StepApiService,
    protected store$: Store<AppState>
  ) {}

  getManySteps$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stepActions.getManySteps),
      switchMap(({ params }) =>
        this.stepApiService.getSteps(params).pipe(
          map((steps: Step[]) => {
            return stepActions.normalizeManyStepsAfterUpsert({ steps });
          }),
          catchError(error => of(stepActions.stepsFailure({ error })))
        )
      )
    );
  });

  getOneStep$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stepActions.getOneStep),
      switchMap(idStep =>
        this.stepApiService.getStep(idStep).pipe(
          map((step: Step) => {
            return stepActions.normalizeManyStepsAfterUpsert({ steps: [step] });
          }),
          catchError(error => of(stepActions.stepsFailure({ error })))
        )
      )
    );
  });

  upsertOneStep$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stepActions.upsertOneStep),
      concatMap(({ step, ids }: { step: Partial<Step>; ids?: StepRelationsIds }) => {
        if (step.idStep) {
          return this.stepApiService.updateStep(step).pipe(
            map((stepReturned: Step) => {
              return stepActions.normalizeManyStepsAfterUpsert({ steps: [stepReturned] });
            }),
            catchError(error => of(stepActions.stepsFailure({ error })))
          );
        } else {
          return this.stepApiService.addStep(step).pipe(
            mergeMap((stepReturned: Step) => getDefaultAddStepActions(stepReturned, ids)),
            catchError(error => of(stepActions.stepsFailure({ error })))
          );
        }
      })
    );
  });

  deleteOneStep$ = createEffect(() => {
    const selectStepState$ = this.store$.select(selectStepState);
    return this.actions$.pipe(
      ofType(stepActions.deleteOneStep),
      withLatestFrom(selectStepState$),
      concatMap(([{ idStep }, state]) =>
        this.stepApiService.deleteStep(idStep).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteStepActions(state.entities[idStep] as StepEntityState),
              stepActions.deleteOneStep.type
            )
          ]),
          catchError(error => of(stepActions.stepsFailure({ error })))
        )
      )
    );
  });

  normalizeManyStepsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(stepActions.normalizeManyStepsAfterUpsert),
      concatMap(({ steps }) => {
        const actions: Action[] = getActionsToNormalizeStep(steps, StoreActionType.upsert);
        return [getMultiAction(actions, '[Step] Normalization After Upsert Success')];
      })
    );
  });
}
