import { RepositoryService } from '@_services/api/repository.service';
import { CompanyUbiflow } from '@_model/interfaces/company-ubiflow.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyUbiflowApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyUbiflows(params?: any): Observable<CompanyUbiflow[]> {
    return this.repo.getData<CompanyUbiflow[]>('company-ubiflow', params);
  }

  public getCompanyUbiflow(params: { idCompanyUbiflow: number; params?: any }): Observable<CompanyUbiflow> {
    return this.repo.getData<CompanyUbiflow>('company-ubiflow/' + params.idCompanyUbiflow, params.params);
  }

  public addCompanyUbiflow(companyUbiflow: Partial<CompanyUbiflow>): Observable<CompanyUbiflow> {
    return this.repo.create<CompanyUbiflow>('company-ubiflow', companyUbiflow);
  }

  public updateCompanyUbiflow(companyUbiflow: Partial<CompanyUbiflow>): Observable<CompanyUbiflow> {
    return this.repo.update('company-ubiflow', companyUbiflow);
  }

  public deleteCompanyUbiflow(idCompanyUbiflow: number): Observable<number> {
    return this.repo.delete('company-ubiflow/' + +idCompanyUbiflow);
  }
}
