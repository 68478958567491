import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidenceStudyFoundingEntityState } from '@_model/interfaces/residence-study-founding.model';

export interface ResidenceStudyFoundingState extends EntityState<ResidenceStudyFoundingEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidenceStudyFoundingEntityState> =
  createEntityAdapter<ResidenceStudyFoundingEntityState>({
    selectId: o => o.idResidenceStudyFounding
  });
export const initialState: ResidenceStudyFoundingState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residenceStudyFoundingFeatureKey = 'residenceStudyFounding';
