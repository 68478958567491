import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { StratalotPriceValue, StratalotPriceValueEntityState } from '@_model/interfaces/stratalot-price-value.model';
import { StratalotPriceValueRelationsIds } from './stratalot-price-value-generated.effects';

export const getOneStratalotPriceValue = createAction(
  '[StratalotPriceValue] Get One StratalotPriceValue',
  props<{ idStratalotPriceValue: number; params?: any }>()
);

export const getManyStratalotPriceValues = createAction(
  '[StratalotPriceValue] Get Many StratalotPriceValues',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[StratalotPriceValue] Add Many Actives StratalotPriceValue',
  props<{ idStratalotPriceValues: number[] }>()
);

export const deleteOneActive = createAction(
  '[StratalotPriceValue] Delete One Active StratalotPriceValue',
  props<{ idStratalotPriceValue: number }>()
);

export const clearActive = createAction('[StratalotPriceValue] Clear Active StratalotPriceValue');

export const upsertOneStratalotPriceValue = createAction(
  '[StratalotPriceValue] Upsert One StratalotPriceValue',
  props<{
    stratalotPriceValue: Partial<StratalotPriceValue>;
    ids?: StratalotPriceValueRelationsIds;
  }>()
);

export const upsertManyStratalotPriceValues = createAction(
  '[StratalotPriceValue] Upsert Many StratalotPriceValues',
  props<{
    stratalotPriceValues: Partial<StratalotPriceValue>[];
    ids?: StratalotPriceValueRelationsIds;
  }>()
);

export const upsertManyStratalotPriceValuesSuccess = createAction(
  '[StratalotPriceValue] Create Many StratalotPriceValues Success',
  props<{ stratalotPriceValues: StratalotPriceValueEntityState[] }>()
);

export const deleteOneStratalotPriceValue = createAction(
  '[StratalotPriceValue] Delete One StratalotPriceValue',
  props<{ idStratalotPriceValue: number }>()
);

export const deleteOneStratalotPriceValueSuccess = createAction(
  '[StratalotPriceValue] Delete One StratalotPriceValue Success',
  props<{ idStratalotPriceValue: number }>()
);

export const normalizeManyStratalotPriceValuesAfterUpsert = createAction(
  '[StratalotPriceValue] Normalize Many StratalotPriceValues After Upsert',
  props<{ stratalotPriceValues: StratalotPriceValueEntityState[] }>()
);

export const stratalotPriceValuesFailure = createAction(
  '[StratalotPriceValue] StratalotPriceValues Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[StratalotPriceValue] Clear StratalotPriceValues');

export const addCompanyPriceLabelSuccess = createAction(
  '[StratalotPriceValue] Add CompanyPriceLabel',
  props<{ idStratalotPriceValue: number; idCompanyPriceLabel: number }>()
);

export const deleteManyCompanyPriceLabelSuccess = createAction(
  '[StratalotPriceValue] Delete Many CompanyPriceLabel',
  props<{ idCompanyPriceLabels: number[]; idStratalotPriceValues: number[] }>()
);

export const addStratalotPriceSuccess = createAction(
  '[StratalotPriceValue] Add StratalotPrice',
  props<{ idStratalotPriceValue: number; idStratalotPrice: number }>()
);

export const deleteManyStratalotPriceSuccess = createAction(
  '[StratalotPriceValue] Delete Many StratalotPrice',
  props<{ idStratalotPrices: number[]; idStratalotPriceValues: number[] }>()
);
