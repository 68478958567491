import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ResidenceShareRcpEntityState } from '@_model/interfaces/residence-share-rcp.model';

export interface ResidenceShareRcpState extends EntityState<ResidenceShareRcpEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<ResidenceShareRcpEntityState> = createEntityAdapter<ResidenceShareRcpEntityState>({
  selectId: o => o.idResidenceShareRcp
});
export const initialState: ResidenceShareRcpState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const residenceShareRcpFeatureKey = 'residenceShareRcp';
