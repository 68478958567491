namespace ReduceUtils {
  export function numbersSum(result: number, inc: number): number {
    return result + inc;
  }

  export function arrayConcatenation<T>(array: T[], item: T): T[] {
    return [...array, item];
  }
}

export default ReduceUtils;
