import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyCommunicationMediaApiService } from '@_services/api/company-communication-media-api.service';
import { GeneratedCompanyCommunicationMediaEffects } from './company-communication-media-generated.effects';

@Injectable()
export class CompanyCommunicationMediaEffects extends GeneratedCompanyCommunicationMediaEffects {
  constructor(
    actions$: Actions,
    companyCommunicationMediaApiService: CompanyCommunicationMediaApiService,
    store$: Store<AppState>
  ) {
    super(actions$, companyCommunicationMediaApiService, store$);
  }
}
