import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  OrganizationLeadAvancement,
  OrganizationLeadAvancementEntityState
} from '@_model/interfaces/organization-lead-avancement.model';
import { OrganizationLeadAvancementApiService } from '@_services/api/organization-lead-avancement-api.service';
import * as organizationLeadAvancementActions from '@_store/organization-lead-avancement/organization-lead-avancement.actions';
import { getActionsToNormalizeOrganizationLeadAvancement } from '@_config/store/normalization.generated';
import { selectOrganizationLeadAvancementState } from './organization-lead-avancement-generated.selectors';
import * as stepActions from '@_store/step/step.actions';
import * as organizationActions from '@_store/organization/organization.actions';
import * as leadActions from '@_store/lead/lead.actions';
import { Lead } from '@_model/interfaces/lead.model';

export interface OrganizationLeadAvancementRelationsIds {
  leads?: number | number[];
  step?: number;
  organization?: number;
}

export function getDefaultAddOrganizationLeadAvancementActions(
  organizationLeadAvancement: OrganizationLeadAvancementEntityState,
  ids?: OrganizationLeadAvancementRelationsIds
): Action[] {
  const actions: Action[] = [
    organizationLeadAvancementActions.normalizeManyOrganizationLeadAvancementsAfterUpsert({
      organizationLeadAvancements: [organizationLeadAvancement]
    })
  ];

  if (ids?.step) {
    actions.push(
      stepActions.addManyOrganizationLeadAvancementSuccess({
        idStep: ids.step,
        idOrganizationLeadAvancements: [organizationLeadAvancement.idOrganizationLeadAvancement]
      })
    );
    actions.push(
      organizationLeadAvancementActions.addStepSuccess({
        idOrganizationLeadAvancement: organizationLeadAvancement.idOrganizationLeadAvancement,
        idStep: ids.step
      })
    );
  }

  if (ids?.organization) {
    actions.push(
      organizationActions.addManyOrganizationLeadAvancementSuccess({
        idOrganization: ids.organization,
        idOrganizationLeadAvancements: [organizationLeadAvancement.idOrganizationLeadAvancement]
      })
    );
    actions.push(
      organizationLeadAvancementActions.addOrganizationSuccess({
        idOrganizationLeadAvancement: organizationLeadAvancement.idOrganizationLeadAvancement,
        idOrganization: ids.organization
      })
    );
  }

  if (ids?.leads) {
    if (!Array.isArray(ids.leads)) {
      actions.push(
        leadActions.upsertOneLead({
          lead: {
            idOrganizationLeadAvancement: organizationLeadAvancement.idOrganizationLeadAvancement,
            idLead: ids.leads as number
          } as Lead
        })
      );
      actions.push(
        organizationLeadAvancementActions.addManyLeadSuccess({
          idOrganizationLeadAvancement: organizationLeadAvancement.idOrganizationLeadAvancement,
          idLeads: [ids.leads as number]
        })
      );
    } else {
      actions.push(
        leadActions.upsertManyLeads({
          leads: (ids.leads as number[]).map((idLead: number) => ({
            idOrganizationLeadAvancement: organizationLeadAvancement.idOrganizationLeadAvancement,
            idLead
          })) as Lead[]
        })
      );
      actions.push(
        organizationLeadAvancementActions.addManyLeadSuccess({
          idOrganizationLeadAvancement: organizationLeadAvancement.idOrganizationLeadAvancement,
          idLeads: ids.leads as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteOrganizationLeadAvancementActions(
  organizationLeadAvancement: OrganizationLeadAvancementEntityState
): Action[] {
  const actions: Action[] = [
    organizationLeadAvancementActions.deleteOneOrganizationLeadAvancementSuccess({
      idOrganizationLeadAvancement: organizationLeadAvancement.idOrganizationLeadAvancement
    })
  ];

  if (organizationLeadAvancement.step) {
    actions.push(
      stepActions.deleteManyOrganizationLeadAvancementSuccess({
        idOrganizationLeadAvancements: [organizationLeadAvancement.idOrganizationLeadAvancement],
        idSteps: [organizationLeadAvancement.step as number]
      })
    );
  }

  if (organizationLeadAvancement.organization) {
    actions.push(
      organizationActions.deleteManyOrganizationLeadAvancementSuccess({
        idOrganizationLeadAvancements: [organizationLeadAvancement.idOrganizationLeadAvancement],
        idOrganizations: [organizationLeadAvancement.organization as number]
      })
    );
  }

  if (organizationLeadAvancement.leads) {
    actions.push(
      leadActions.deleteManyOrganizationLeadAvancementSuccess({
        idOrganizationLeadAvancements: [organizationLeadAvancement.idOrganizationLeadAvancement],
        idLeads: organizationLeadAvancement.leads as number[]
      })
    );
  }

  return actions;
}

export class GeneratedOrganizationLeadAvancementEffects {
  constructor(
    protected actions$: Actions,
    protected organizationLeadAvancementApiService: OrganizationLeadAvancementApiService,
    protected store$: Store<AppState>
  ) {}

  getManyOrganizationLeadAvancements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadAvancementActions.getManyOrganizationLeadAvancements),
      switchMap(({ params }) =>
        this.organizationLeadAvancementApiService.getOrganizationLeadAvancements(params).pipe(
          map((organizationLeadAvancements: OrganizationLeadAvancement[]) => {
            return organizationLeadAvancementActions.normalizeManyOrganizationLeadAvancementsAfterUpsert({
              organizationLeadAvancements
            });
          }),
          catchError(error => of(organizationLeadAvancementActions.organizationLeadAvancementsFailure({ error })))
        )
      )
    );
  });

  getOneOrganizationLeadAvancement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadAvancementActions.getOneOrganizationLeadAvancement),
      switchMap(idOrganizationLeadAvancement =>
        this.organizationLeadAvancementApiService.getOrganizationLeadAvancement(idOrganizationLeadAvancement).pipe(
          map((organizationLeadAvancement: OrganizationLeadAvancement) => {
            return organizationLeadAvancementActions.normalizeManyOrganizationLeadAvancementsAfterUpsert({
              organizationLeadAvancements: [organizationLeadAvancement]
            });
          }),
          catchError(error => of(organizationLeadAvancementActions.organizationLeadAvancementsFailure({ error })))
        )
      )
    );
  });

  upsertOneOrganizationLeadAvancement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadAvancementActions.upsertOneOrganizationLeadAvancement),
      concatMap(
        ({
          organizationLeadAvancement,
          ids
        }: {
          organizationLeadAvancement: Partial<OrganizationLeadAvancement>;
          ids?: OrganizationLeadAvancementRelationsIds;
        }) => {
          if (organizationLeadAvancement.idOrganizationLeadAvancement) {
            return this.organizationLeadAvancementApiService
              .updateOrganizationLeadAvancement(organizationLeadAvancement)
              .pipe(
                map((organizationLeadAvancementReturned: OrganizationLeadAvancement) => {
                  return organizationLeadAvancementActions.normalizeManyOrganizationLeadAvancementsAfterUpsert({
                    organizationLeadAvancements: [organizationLeadAvancementReturned]
                  });
                }),
                catchError(error => of(organizationLeadAvancementActions.organizationLeadAvancementsFailure({ error })))
              );
          } else {
            return this.organizationLeadAvancementApiService
              .addOrganizationLeadAvancement(organizationLeadAvancement)
              .pipe(
                mergeMap((organizationLeadAvancementReturned: OrganizationLeadAvancement) =>
                  getDefaultAddOrganizationLeadAvancementActions(organizationLeadAvancementReturned, ids)
                ),
                catchError(error => of(organizationLeadAvancementActions.organizationLeadAvancementsFailure({ error })))
              );
          }
        }
      )
    );
  });

  deleteOneOrganizationLeadAvancement$ = createEffect(() => {
    const selectOrganizationLeadAvancementState$ = this.store$.select(selectOrganizationLeadAvancementState);
    return this.actions$.pipe(
      ofType(organizationLeadAvancementActions.deleteOneOrganizationLeadAvancement),
      withLatestFrom(selectOrganizationLeadAvancementState$),
      concatMap(([{ idOrganizationLeadAvancement }, state]) =>
        this.organizationLeadAvancementApiService.deleteOrganizationLeadAvancement(idOrganizationLeadAvancement).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteOrganizationLeadAvancementActions(
                state.entities[idOrganizationLeadAvancement] as OrganizationLeadAvancementEntityState
              ),
              organizationLeadAvancementActions.deleteOneOrganizationLeadAvancement.type
            )
          ]),
          catchError(error => of(organizationLeadAvancementActions.organizationLeadAvancementsFailure({ error })))
        )
      )
    );
  });

  normalizeManyOrganizationLeadAvancementsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(organizationLeadAvancementActions.normalizeManyOrganizationLeadAvancementsAfterUpsert),
      concatMap(({ organizationLeadAvancements }) => {
        const actions: Action[] = getActionsToNormalizeOrganizationLeadAvancement(
          organizationLeadAvancements,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[OrganizationLeadAvancement] Normalization After Upsert Success')];
      })
    );
  });
}
