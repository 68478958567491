import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { StratalotCampaign, StratalotCampaignEntityState } from '@_model/interfaces/stratalot-campaign.model';
import { StratalotCampaignRelationsIds } from './stratalot-campaign-generated.effects';

export const getOneStratalotCampaign = createAction(
  '[StratalotCampaign] Get One StratalotCampaign',
  props<{ idStratalotCampaign: number; params?: any }>()
);

export const getManyStratalotCampaigns = createAction(
  '[StratalotCampaign] Get Many StratalotCampaigns',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[StratalotCampaign] Add Many Actives StratalotCampaign',
  props<{ idStratalotCampaigns: number[] }>()
);

export const deleteOneActive = createAction(
  '[StratalotCampaign] Delete One Active StratalotCampaign',
  props<{ idStratalotCampaign: number }>()
);

export const clearActive = createAction('[StratalotCampaign] Clear Active StratalotCampaign');

export const upsertOneStratalotCampaign = createAction(
  '[StratalotCampaign] Upsert One StratalotCampaign',
  props<{
    stratalotCampaign: Partial<StratalotCampaign>;
    ids?: StratalotCampaignRelationsIds;
  }>()
);

export const upsertManyStratalotCampaigns = createAction(
  '[StratalotCampaign] Upsert Many StratalotCampaigns',
  props<{
    stratalotCampaigns: Partial<StratalotCampaign>[];
    ids?: StratalotCampaignRelationsIds;
  }>()
);

export const upsertManyStratalotCampaignsSuccess = createAction(
  '[StratalotCampaign] Create Many StratalotCampaigns Success',
  props<{ stratalotCampaigns: StratalotCampaignEntityState[] }>()
);

export const deleteOneStratalotCampaign = createAction(
  '[StratalotCampaign] Delete One StratalotCampaign',
  props<{ idStratalotCampaign: number }>()
);

export const deleteOneStratalotCampaignSuccess = createAction(
  '[StratalotCampaign] Delete One StratalotCampaign Success',
  props<{ idStratalotCampaign: number }>()
);

export const normalizeManyStratalotCampaignsAfterUpsert = createAction(
  '[StratalotCampaign] Normalize Many StratalotCampaigns After Upsert',
  props<{ stratalotCampaigns: StratalotCampaignEntityState[] }>()
);

export const stratalotCampaignsFailure = createAction(
  '[StratalotCampaign] StratalotCampaigns Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[StratalotCampaign] Clear StratalotCampaigns');

export const addManyCampaignMediaSuccess = createAction(
  '[StratalotCampaign] Add Many campaign-media',
  props<{ idStratalotCampaign: number; idCampaignMedias: number[] }>()
);

export const deleteManyCampaignMediaSuccess = createAction(
  '[StratalotCampaign] Delete Many CampaignMedias',
  props<{ idCampaignMedias: number[]; idStratalotCampaigns: number[] }>()
);

export const addStratalotSuccess = createAction(
  '[StratalotCampaign] Add Stratalot',
  props<{ idStratalotCampaign: number; idStratalot: number }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[StratalotCampaign] Delete Many Stratalot',
  props<{ idStratalots: number[]; idStratalotCampaigns: number[] }>()
);
