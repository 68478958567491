import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CampaignMediaApiService } from '@_services/api/campaign-media-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedCampaignMediaEffects } from './campaign-media-generated.effects';

@Injectable()
export class CampaignMediaEffects extends GeneratedCampaignMediaEffects {
  constructor(actions$: Actions, campaignMediaApiService: CampaignMediaApiService, store$: Store<AppState>) {
    super(actions$, campaignMediaApiService, store$);
  }
}
