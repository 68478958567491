import { GeneratedDocumentState, initialState } from './generated-document.state';
import { Action, createReducer } from '@ngrx/store';
import { generatedDocumentReducersGeneratedFunctions } from './generated-document-generated.reducer';

const generatedDocumentReducersFunctions = [...generatedDocumentReducersGeneratedFunctions];

const generatedDocumentReducer = createReducer(initialState, ...generatedDocumentReducersFunctions);

export function reducer(state: GeneratedDocumentState | undefined, action: Action) {
  return generatedDocumentReducer(state, action);
}
