import { RepositoryService } from '@_services/api/repository.service';
import { StratalotType } from '@_model/interfaces/stratalot-type.model';
import { Observable } from 'rxjs';

export class GeneratedStratalotTypeApiService {
  constructor(protected repo: RepositoryService) {}

  public getStratalotTypes(params?: any): Observable<StratalotType[]> {
    return this.repo.getData<StratalotType[]>('stratalot-type', params);
  }

  public getStratalotType(params: { idStratalotType: number; params?: any }): Observable<StratalotType> {
    return this.repo.getData<StratalotType>('stratalot-type/' + params.idStratalotType, params.params);
  }

  public addStratalotType(stratalotType: Partial<StratalotType>): Observable<StratalotType> {
    return this.repo.create<StratalotType>('stratalot-type', stratalotType);
  }

  public updateStratalotType(stratalotType: Partial<StratalotType>): Observable<StratalotType> {
    return this.repo.update('stratalot-type', stratalotType);
  }

  public deleteStratalotType(idStratalotType: number): Observable<number> {
    return this.repo.delete('stratalot-type/' + +idStratalotType);
  }
}
