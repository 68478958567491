import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { ResidenceWork, ResidenceWorkEntityState } from '@_model/interfaces/residence-work.model';
import { ResidenceWorkApiService } from '@_services/api/residence-work-api.service';
import * as residenceWorkActions from '@_store/residence-work/residence-work.actions';
import { getActionsToNormalizeResidenceWork } from '@_config/store/normalization.generated';
import { selectResidenceWorkState } from './residence-work-generated.selectors';
import * as residenceActions from '@_store/residence/residence.actions';

export interface ResidenceWorkRelationsIds {
  residence?: number;
}

export function getDefaultAddResidenceWorkActions(
  residenceWork: ResidenceWorkEntityState,
  ids?: ResidenceWorkRelationsIds
): Action[] {
  const actions: Action[] = [
    residenceWorkActions.normalizeManyResidenceWorksAfterUpsert({ residenceWorks: [residenceWork] })
  ];

  if (ids?.residence) {
    actions.push(
      residenceActions.addManyResidenceWorkSuccess({
        idResidence: ids.residence,
        idResidenceWorks: [residenceWork.idResidenceWork]
      })
    );
    actions.push(
      residenceWorkActions.addResidenceSuccess({
        idResidenceWork: residenceWork.idResidenceWork,
        idResidence: ids.residence
      })
    );
  }

  return actions;
}

export function getDefaultDeleteResidenceWorkActions(residenceWork: ResidenceWorkEntityState): Action[] {
  const actions: Action[] = [
    residenceWorkActions.deleteOneResidenceWorkSuccess({ idResidenceWork: residenceWork.idResidenceWork })
  ];

  if (residenceWork.residence) {
    actions.push(
      residenceActions.deleteManyResidenceWorkSuccess({
        idResidenceWorks: [residenceWork.idResidenceWork],
        idResidences: [residenceWork.residence as number]
      })
    );
  }

  return actions;
}

export class GeneratedResidenceWorkEffects {
  constructor(
    protected actions$: Actions,
    protected residenceWorkApiService: ResidenceWorkApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidenceWorks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceWorkActions.getManyResidenceWorks),
      switchMap(({ params }) =>
        this.residenceWorkApiService.getResidenceWorks(params).pipe(
          map((residenceWorks: ResidenceWork[]) => {
            return residenceWorkActions.normalizeManyResidenceWorksAfterUpsert({ residenceWorks });
          }),
          catchError(error => of(residenceWorkActions.residenceWorksFailure({ error })))
        )
      )
    );
  });

  getOneResidenceWork$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceWorkActions.getOneResidenceWork),
      switchMap(idResidenceWork =>
        this.residenceWorkApiService.getResidenceWork(idResidenceWork).pipe(
          map((residenceWork: ResidenceWork) => {
            return residenceWorkActions.normalizeManyResidenceWorksAfterUpsert({ residenceWorks: [residenceWork] });
          }),
          catchError(error => of(residenceWorkActions.residenceWorksFailure({ error })))
        )
      )
    );
  });

  upsertOneResidenceWork$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceWorkActions.upsertOneResidenceWork),
      concatMap(
        ({ residenceWork, ids }: { residenceWork: Partial<ResidenceWork>; ids?: ResidenceWorkRelationsIds }) => {
          if (residenceWork.idResidenceWork) {
            return this.residenceWorkApiService.updateResidenceWork(residenceWork).pipe(
              map((residenceWorkReturned: ResidenceWork) => {
                return residenceWorkActions.normalizeManyResidenceWorksAfterUpsert({
                  residenceWorks: [residenceWorkReturned]
                });
              }),
              catchError(error => of(residenceWorkActions.residenceWorksFailure({ error })))
            );
          } else {
            return this.residenceWorkApiService.addResidenceWork(residenceWork).pipe(
              mergeMap((residenceWorkReturned: ResidenceWork) =>
                getDefaultAddResidenceWorkActions(residenceWorkReturned, ids)
              ),
              catchError(error => of(residenceWorkActions.residenceWorksFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneResidenceWork$ = createEffect(() => {
    const selectResidenceWorkState$ = this.store$.select(selectResidenceWorkState);
    return this.actions$.pipe(
      ofType(residenceWorkActions.deleteOneResidenceWork),
      withLatestFrom(selectResidenceWorkState$),
      concatMap(([{ idResidenceWork }, state]) =>
        this.residenceWorkApiService.deleteResidenceWork(idResidenceWork).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidenceWorkActions(state.entities[idResidenceWork] as ResidenceWorkEntityState),
              residenceWorkActions.deleteOneResidenceWork.type
            )
          ]),
          catchError(error => of(residenceWorkActions.residenceWorksFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidenceWorksAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceWorkActions.normalizeManyResidenceWorksAfterUpsert),
      concatMap(({ residenceWorks }) => {
        const actions: Action[] = getActionsToNormalizeResidenceWork(residenceWorks, StoreActionType.upsert);
        return [getMultiAction(actions, '[ResidenceWork] Normalization After Upsert Success')];
      })
    );
  });
}
