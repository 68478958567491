import { OccupantState, initialState } from './occupant.state';
import { Action, createReducer } from '@ngrx/store';
import { occupantReducersGeneratedFunctions } from './occupant-generated.reducer';

const occupantReducersFunctions = [...occupantReducersGeneratedFunctions];

const occupantReducer = createReducer(initialState, ...occupantReducersFunctions);

export function reducer(state: OccupantState | undefined, action: Action) {
  return occupantReducer(state, action);
}
