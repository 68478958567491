import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { ResidenceStudyReason, ResidenceStudyReasonEntityState } from '@_model/interfaces/residence-study-reason.model';
import { ResidenceStudyReasonApiService } from '@_services/api/residence-study-reason-api.service';
import * as residenceStudyReasonActions from '@_store/residence-study-reason/residence-study-reason.actions';
import { getActionsToNormalizeResidenceStudyReason } from '@_config/store/normalization.generated';
import { selectResidenceStudyReasonState } from './residence-study-reason-generated.selectors';
import * as residenceStudyActions from '@_store/residence-study/residence-study.actions';
import * as companyStudyReasonActions from '@_store/company-study-reason/company-study-reason.actions';

export interface ResidenceStudyReasonRelationsIds {
  residenceStudy?: number;
  companyStudyReason?: number;
}

export function getDefaultAddResidenceStudyReasonActions(
  residenceStudyReason: ResidenceStudyReasonEntityState,
  ids?: ResidenceStudyReasonRelationsIds
): Action[] {
  const actions: Action[] = [
    residenceStudyReasonActions.normalizeManyResidenceStudyReasonsAfterUpsert({
      residenceStudyReasons: [residenceStudyReason]
    })
  ];

  if (ids?.residenceStudy) {
    actions.push(
      residenceStudyActions.addManyResidenceStudyReasonSuccess({
        idResidenceStudy: ids.residenceStudy,
        idResidenceStudyReasons: [residenceStudyReason.idResidenceStudyReason]
      })
    );
    actions.push(
      residenceStudyReasonActions.addResidenceStudySuccess({
        idResidenceStudyReason: residenceStudyReason.idResidenceStudyReason,
        idResidenceStudy: ids.residenceStudy
      })
    );
  }

  if (ids?.companyStudyReason) {
    actions.push(
      companyStudyReasonActions.addManyResidenceStudyReasonSuccess({
        idCompanyStudyReason: ids.companyStudyReason,
        idResidenceStudyReasons: [residenceStudyReason.idResidenceStudyReason]
      })
    );
    actions.push(
      residenceStudyReasonActions.addCompanyStudyReasonSuccess({
        idResidenceStudyReason: residenceStudyReason.idResidenceStudyReason,
        idCompanyStudyReason: ids.companyStudyReason
      })
    );
  }

  return actions;
}

export function getDefaultDeleteResidenceStudyReasonActions(
  residenceStudyReason: ResidenceStudyReasonEntityState
): Action[] {
  const actions: Action[] = [
    residenceStudyReasonActions.deleteOneResidenceStudyReasonSuccess({
      idResidenceStudyReason: residenceStudyReason.idResidenceStudyReason
    })
  ];

  if (residenceStudyReason.residenceStudy) {
    actions.push(
      residenceStudyActions.deleteManyResidenceStudyReasonSuccess({
        idResidenceStudyReasons: [residenceStudyReason.idResidenceStudyReason],
        idResidenceStudies: [residenceStudyReason.residenceStudy as number]
      })
    );
  }

  if (residenceStudyReason.companyStudyReason) {
    actions.push(
      companyStudyReasonActions.deleteManyResidenceStudyReasonSuccess({
        idResidenceStudyReasons: [residenceStudyReason.idResidenceStudyReason],
        idCompanyStudyReasons: [residenceStudyReason.companyStudyReason as number]
      })
    );
  }

  return actions;
}

export class GeneratedResidenceStudyReasonEffects {
  constructor(
    protected actions$: Actions,
    protected residenceStudyReasonApiService: ResidenceStudyReasonApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidenceStudyReasons$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyReasonActions.getManyResidenceStudyReasons),
      switchMap(({ params }) =>
        this.residenceStudyReasonApiService.getResidenceStudyReasons(params).pipe(
          map((residenceStudyReasons: ResidenceStudyReason[]) => {
            return residenceStudyReasonActions.normalizeManyResidenceStudyReasonsAfterUpsert({ residenceStudyReasons });
          }),
          catchError(error => of(residenceStudyReasonActions.residenceStudyReasonsFailure({ error })))
        )
      )
    );
  });

  getOneResidenceStudyReason$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyReasonActions.getOneResidenceStudyReason),
      switchMap(idResidenceStudyReason =>
        this.residenceStudyReasonApiService.getResidenceStudyReason(idResidenceStudyReason).pipe(
          map((residenceStudyReason: ResidenceStudyReason) => {
            return residenceStudyReasonActions.normalizeManyResidenceStudyReasonsAfterUpsert({
              residenceStudyReasons: [residenceStudyReason]
            });
          }),
          catchError(error => of(residenceStudyReasonActions.residenceStudyReasonsFailure({ error })))
        )
      )
    );
  });

  upsertOneResidenceStudyReason$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyReasonActions.upsertOneResidenceStudyReason),
      concatMap(
        ({
          residenceStudyReason,
          ids
        }: {
          residenceStudyReason: Partial<ResidenceStudyReason>;
          ids?: ResidenceStudyReasonRelationsIds;
        }) => {
          if (residenceStudyReason.idResidenceStudyReason) {
            return this.residenceStudyReasonApiService.updateResidenceStudyReason(residenceStudyReason).pipe(
              map((residenceStudyReasonReturned: ResidenceStudyReason) => {
                return residenceStudyReasonActions.normalizeManyResidenceStudyReasonsAfterUpsert({
                  residenceStudyReasons: [residenceStudyReasonReturned]
                });
              }),
              catchError(error => of(residenceStudyReasonActions.residenceStudyReasonsFailure({ error })))
            );
          } else {
            return this.residenceStudyReasonApiService.addResidenceStudyReason(residenceStudyReason).pipe(
              mergeMap((residenceStudyReasonReturned: ResidenceStudyReason) =>
                getDefaultAddResidenceStudyReasonActions(residenceStudyReasonReturned, ids)
              ),
              catchError(error => of(residenceStudyReasonActions.residenceStudyReasonsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneResidenceStudyReason$ = createEffect(() => {
    const selectResidenceStudyReasonState$ = this.store$.select(selectResidenceStudyReasonState);
    return this.actions$.pipe(
      ofType(residenceStudyReasonActions.deleteOneResidenceStudyReason),
      withLatestFrom(selectResidenceStudyReasonState$),
      concatMap(([{ idResidenceStudyReason }, state]) =>
        this.residenceStudyReasonApiService.deleteResidenceStudyReason(idResidenceStudyReason).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidenceStudyReasonActions(
                state.entities[idResidenceStudyReason] as ResidenceStudyReasonEntityState
              ),
              residenceStudyReasonActions.deleteOneResidenceStudyReason.type
            )
          ]),
          catchError(error => of(residenceStudyReasonActions.residenceStudyReasonsFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidenceStudyReasonsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceStudyReasonActions.normalizeManyResidenceStudyReasonsAfterUpsert),
      concatMap(({ residenceStudyReasons }) => {
        const actions: Action[] = getActionsToNormalizeResidenceStudyReason(
          residenceStudyReasons,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[ResidenceStudyReason] Normalization After Upsert Success')];
      })
    );
  });
}
