import { RepositoryService } from '@_services/api/repository.service';
import { CompanyStudyConclusion } from '@_model/interfaces/company-study-conclusion.model';
import { Observable } from 'rxjs';

export class GeneratedCompanyStudyConclusionApiService {
  constructor(protected repo: RepositoryService) {}

  public getCompanyStudyConclusions(params?: any): Observable<CompanyStudyConclusion[]> {
    return this.repo.getData<CompanyStudyConclusion[]>('company-study-conclusion', params);
  }

  public getCompanyStudyConclusion(params: {
    idCompanyStudyConclusion: number;
    params?: any;
  }): Observable<CompanyStudyConclusion> {
    return this.repo.getData<CompanyStudyConclusion>(
      'company-study-conclusion/' + params.idCompanyStudyConclusion,
      params.params
    );
  }

  public addCompanyStudyConclusion(
    companyStudyConclusion: Partial<CompanyStudyConclusion>
  ): Observable<CompanyStudyConclusion> {
    return this.repo.create<CompanyStudyConclusion>('company-study-conclusion', companyStudyConclusion);
  }

  public updateCompanyStudyConclusion(
    companyStudyConclusion: Partial<CompanyStudyConclusion>
  ): Observable<CompanyStudyConclusion> {
    return this.repo.update('company-study-conclusion', companyStudyConclusion);
  }

  public deleteCompanyStudyConclusion(idCompanyStudyConclusion: number): Observable<number> {
    return this.repo.delete('company-study-conclusion/' + +idCompanyStudyConclusion);
  }
}
