import { defaultBuyingWishIconList } from '@_config/iconsHelper.config';
import { BuyingWish } from '@_shared/models/enums/buying-wish.enum';
import { DisplayIconHelper } from '@_shared/models/interfaces/displayIconHelper';
import { OrganizationBuyingWish } from '@_shared/models/interfaces/organization-buying-wish.model';
import { OrganizationSaleCategory } from '@_shared/models/interfaces/organization-sale-category.model';
import { OrganizationThirdParty } from '@_shared/models/interfaces/organization-third-party.model';

namespace OrganizationParamsUtils {
  let _currentOrganisationId: number;
  let _organizationThirdParties: OrganizationThirdParty[] = [];
  let _organizationSaleCategories: OrganizationSaleCategory[] = [];
  let _organizationBuyingWishes: OrganizationBuyingWish[] = [];

  export function setCurrentOrganisationId(id: number): void {
    _currentOrganisationId = id;
  }

  export function getCurrentOrganisationId(): number {
    return _currentOrganisationId;
  }

  export function setOrganizationThirdParties(organizationThirdParties: OrganizationThirdParty[]): void {
    _organizationThirdParties = organizationThirdParties;
  }

  export function getOrganizationThirdParties(): OrganizationThirdParty[] {
    return _organizationThirdParties;
  }

  export function setOrganizationSaleCategories(organizationSaleCategories: OrganizationSaleCategory[]): void {
    _organizationSaleCategories = organizationSaleCategories;
  }

  export function getOrganizationSaleCategories(): OrganizationSaleCategory[] {
    return _organizationSaleCategories;
  }

  export function setOrganizationBuyingWishes(organizationBuyingWishes: OrganizationBuyingWish[]): void {
    _organizationBuyingWishes = organizationBuyingWishes;
  }

  export function getOrganizationBuyingWishes(): OrganizationBuyingWish[] {
    return _organizationBuyingWishes;
  }

  export function getOrganizationBuyingWishByTarget(isOccuped: boolean): OrganizationBuyingWish[] {
    return _organizationBuyingWishes?.filter(buyingWish => {
      return isOccuped ? buyingWish.occupant : buyingWish.prospect;
    });
  }

  export function getDefaultOrganizationBuyingWishByBuyingWishAndTarget(
    buyingWish: BuyingWish,
    isOccuped: boolean
  ): OrganizationBuyingWish {
    if (_organizationBuyingWishes.length) {
      return _organizationBuyingWishes?.find(
        orgaBuyingWish =>
          orgaBuyingWish.defaut === true &&
          orgaBuyingWish.type === buyingWish &&
          (isOccuped ? orgaBuyingWish.occupant : orgaBuyingWish.prospect)
      );
    } else {
      return {
        type: BuyingWish.toQualify
      };
    }
  }

  export function getOrganizationBuyingWishIconList(isOccuped: boolean): DisplayIconHelper[] {
    return defaultBuyingWishIconList
      .map(buyingWishIcon => {
        return {
          ...buyingWishIcon,
          children: getOrganizationBuyingWishByTarget(isOccuped)
            ?.sort((x, y) => x.ordre - y.ordre)
            ?.filter(organizationBuyingWish => organizationBuyingWish.type === buyingWishIcon.value)
            ?.map((organizationBuyingWish): DisplayIconHelper => {
              return {
                value: organizationBuyingWish.idOrganizationBuyingWish.toString(),
                valueInteger: organizationBuyingWish.idOrganizationBuyingWish,
                text: organizationBuyingWish.libelle,
                icon: buyingWishIcon.icon
              };
            })
        };
      })
      .filter(buyingWish => buyingWish.children?.length);
  }
}
export default OrganizationParamsUtils;
