import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StratalotPriceValueEntityState } from '@_model/interfaces/stratalot-price-value.model';

export interface StratalotPriceValueState extends EntityState<StratalotPriceValueEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<StratalotPriceValueEntityState> =
  createEntityAdapter<StratalotPriceValueEntityState>({
    selectId: o => o.idStratalotPriceValue
  });
export const initialState: StratalotPriceValueState = adapter.getInitialState({
  isLoaded: false,
  isLoading: false,
  actives: []
});

export const stratalotPriceValueFeatureKey = 'stratalotPriceValue';
