export function create0ToNLess1Array(n: number): Array<number> {
  return Array.from(Array(n).keys());
}

// https://stackoverflow.com/a/47424938
export function sortByMultipleKey(keys: { name: string; cb?: (a, b, key?) => 1 | 0 | -1 }[]): (a, b) => 1 | 0 | -1 {
  return (a, b): 1 | 0 | -1 => {
    if (keys.length === 0) {
      return 0; // force to equal if keys run out
    }
    let result: 1 | 0 | -1;

    const key = keys[0]; // take out the first key
    if (!key.cb) {
      result = defaultStringSort(a[key.name], b[key.name]);
    } else {
      result = key.cb(a, b, key);
    }
    return result === 0 ? sortByMultipleKey(keys.slice(1))(a, b) : result;
  };
}

function defaultStringSort(a: string, b: string): 1 | 0 | -1 {
  if (typeof a === 'string') {
    if (a?.toLocaleLowerCase() < b?.toLocaleLowerCase()) {
      // will be 1 if DESC
      return -1;
    } else if (a?.toLocaleLowerCase() > b?.toLocaleLowerCase()) {
      // will be -1 if DESC
      return 1;
    } else {
      return 0;
    }
  } else {
    if (a < b) {
      // will be 1 if DESC
      return -1;
    } else if (a > b) {
      // will be -1 if DESC
      return 1;
    } else {
      return 0;
    }
  }
}

export function sortEmptyLast(a: string, b: string, key: { name: string }): 1 | 0 | -1 {
  if (a[key.name] === b[key.name]) {
    return 0;
  } else if (a[key.name] === '') {
    return 1;
  } else if (b[key.name] === '') {
    return -1;
  }
}

export function sortNumber(a: object, b: object, key: { name: string }): 1 | 0 | -1 {
  const x: number = parseInt(a[key.name]);
  const y: number = parseInt(b[key.name]);
  if (x > y) {
    return 1;
  }
  if (y > x) {
    return -1;
  }
  if (x === y) {
    return 0;
  }
}

export function sortBooleanDesc(a: string, b: string, key: { name: string }): 1 | 0 | -1 {
  if (a[key.name] === b[key.name]) {
    return 0;
  } else if (a[key.name]) {
    return -1;
  } else if (b[key.name]) {
    return 1;
  }
}

export function checkstringBelongto(str: string, list: string[]): boolean {
  if (!str) {
    return true;
  }
  return list.some(s => s === str);
}

export function arraysAreEqual(a, b): boolean {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}
