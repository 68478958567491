import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { LeadTodo, LeadTodoEntityState } from '@_model/interfaces/lead-todo.model';
import { LeadTodoRelationsIds } from './lead-todo-generated.effects';

export const getOneLeadTodo = createAction(
  '[LeadTodo] Get One LeadTodo',
  props<{ idLeadTodo: number; params?: any }>()
);

export const getManyLeadTodos = createAction('[LeadTodo] Get Many LeadTodos', props<{ params: any }>());

export const addManyActives = createAction('[LeadTodo] Add Many Actives LeadTodo', props<{ idLeadTodos: number[] }>());

export const deleteOneActive = createAction('[LeadTodo] Delete One Active LeadTodo', props<{ idLeadTodo: number }>());

export const clearActive = createAction('[LeadTodo] Clear Active LeadTodo');

export const upsertOneLeadTodo = createAction(
  '[LeadTodo] Upsert One LeadTodo',
  props<{
    leadTodo: Partial<LeadTodo>;
    ids?: LeadTodoRelationsIds;
  }>()
);

export const upsertManyLeadTodos = createAction(
  '[LeadTodo] Upsert Many LeadTodos',
  props<{
    leadTodos: Partial<LeadTodo>[];
    ids?: LeadTodoRelationsIds;
  }>()
);

export const upsertManyLeadTodosSuccess = createAction(
  '[LeadTodo] Create Many LeadTodos Success',
  props<{ leadTodos: LeadTodoEntityState[] }>()
);

export const deleteOneLeadTodo = createAction('[LeadTodo] Delete One LeadTodo', props<{ idLeadTodo: number }>());

export const deleteOneLeadTodoSuccess = createAction(
  '[LeadTodo] Delete One LeadTodo Success',
  props<{ idLeadTodo: number }>()
);

export const normalizeManyLeadTodosAfterUpsert = createAction(
  '[LeadTodo] Normalize Many LeadTodos After Upsert',
  props<{ leadTodos: LeadTodoEntityState[] }>()
);

export const leadTodosFailure = createAction('[LeadTodo] LeadTodos Failure', props<{ error: HttpErrorResponse }>());

export const clearStore = createAction('[LeadTodo] Clear LeadTodos');

export const addOrganizationLeadTodoSuccess = createAction(
  '[LeadTodo] Add OrganizationLeadTodo',
  props<{ idLeadTodo: number; idOrganizationLeadTodo: number }>()
);

export const deleteManyOrganizationLeadTodoSuccess = createAction(
  '[LeadTodo] Delete Many OrganizationLeadTodo',
  props<{ idOrganizationLeadTodos: number[]; idLeadTodos: number[] }>()
);

export const addLeadSuccess = createAction('[LeadTodo] Add Lead', props<{ idLeadTodo: number; idLead: number }>());

export const deleteManyLeadSuccess = createAction(
  '[LeadTodo] Delete Many Lead',
  props<{ idLeads: number[]; idLeadTodos: number[] }>()
);
