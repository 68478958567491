import { RepositoryService } from '@_services/api/repository.service';
import { OrganizationResidenceTodo } from '@_model/interfaces/organization-residence-todo.model';
import { Observable } from 'rxjs';

export class GeneratedOrganizationResidenceTodoApiService {
  constructor(protected repo: RepositoryService) {}

  public getOrganizationResidenceTodos(params?: any): Observable<OrganizationResidenceTodo[]> {
    return this.repo.getData<OrganizationResidenceTodo[]>('organization-residence-todo', params);
  }

  public getOrganizationResidenceTodo(params: {
    idOrganizationResidenceTodo: number;
    params?: any;
  }): Observable<OrganizationResidenceTodo> {
    return this.repo.getData<OrganizationResidenceTodo>(
      'organization-residence-todo/' + params.idOrganizationResidenceTodo,
      params.params
    );
  }

  public addOrganizationResidenceTodo(
    organizationResidenceTodo: Partial<OrganizationResidenceTodo>
  ): Observable<OrganizationResidenceTodo> {
    return this.repo.create<OrganizationResidenceTodo>('organization-residence-todo', organizationResidenceTodo);
  }

  public updateOrganizationResidenceTodo(
    organizationResidenceTodo: Partial<OrganizationResidenceTodo>
  ): Observable<OrganizationResidenceTodo> {
    return this.repo.update('organization-residence-todo', organizationResidenceTodo);
  }

  public deleteOrganizationResidenceTodo(idOrganizationResidenceTodo: number): Observable<number> {
    return this.repo.delete('organization-residence-todo/' + +idOrganizationResidenceTodo);
  }
}
