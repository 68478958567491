import { StepProgressState, initialState } from './step-progress.state';
import { Action, createReducer } from '@ngrx/store';
import { stepProgressReducersGeneratedFunctions } from './step-progress-generated.reducer';

const stepProgressReducersFunctions = [...stepProgressReducersGeneratedFunctions];

const stepProgressReducer = createReducer(initialState, ...stepProgressReducersFunctions);

export function reducer(state: StepProgressState | undefined, action: Action) {
  return stepProgressReducer(state, action);
}
