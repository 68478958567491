import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { DroitEntityState } from '@_model/interfaces/droit.model';

export interface DroitState extends EntityState<DroitEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<DroitEntityState> = createEntityAdapter<DroitEntityState>({
  selectId: o => o.idDroit
});
export const initialState: DroitState = adapter.getInitialState({ isLoaded: false, isLoading: false, actives: [] });

export const droitFeatureKey = 'droit';
