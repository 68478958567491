import { LeadAvancementEnum } from '@_shared/models/enums/lead-statut-avancement.enum';
import { StepName } from '@_shared/models/enums/step-name.enum';

namespace LeadAvancementUtils {
  export const leadAvancementToStepNameDictionnary: { [key in LeadAvancementEnum]: StepName } = {
    [LeadAvancementEnum.offreExamineeNonRetenue]: StepName.commercialisation,
    [LeadAvancementEnum.resiliation]: StepName.commercialisation,
    [LeadAvancementEnum.depotOffre]: StepName.commercialisation,
    [LeadAvancementEnum.premierContact]: StepName.commercialisation,
    [LeadAvancementEnum.pasDate]: StepName.commercialisation,
    [LeadAvancementEnum.offreSoumiseValidation]: StepName.offresAValider,

    [LeadAvancementEnum.resiliationPlanifiee]: StepName.ventesEnCours,
    [LeadAvancementEnum.nonLeveeOption]: StepName.ventesEnCours,
    [LeadAvancementEnum.nonLeveeOptionPlanifiee]: StepName.ventesEnCours,
    [LeadAvancementEnum.offreAcceptee]: StepName.ventesEnCours,
    [LeadAvancementEnum.acteSigne]: StepName.ventesEnCours,

    [LeadAvancementEnum.compromisPlanifie]: StepName.ventesEnCours,
    [LeadAvancementEnum.compromisSigne]: StepName.ventesEnCours,
    [LeadAvancementEnum.acteAncienPlanifie]: StepName.ventesEnCours,

    [LeadAvancementEnum.acteVefaPlanifie]: StepName.ventesEnCours,

    [LeadAvancementEnum.reservation]: StepName.ventesEnCours,
    [LeadAvancementEnum.reservationPlanifiee]: StepName.ventesEnCours,
    [LeadAvancementEnum.contratLocationAccession]: StepName.ventesEnCours,
    [LeadAvancementEnum.contratLocationAccessionPlanifie]: StepName.ventesEnCours,
    [LeadAvancementEnum.leveeOption]: StepName.ventesEnCours,
    [LeadAvancementEnum.leveeOptionPlanifiee]: StepName.ventesEnCours,
    [LeadAvancementEnum.actePslaPlanifie]: StepName.ventesEnCours,

    [LeadAvancementEnum.venteArchivee]: StepName.vendu
  };
}

export default LeadAvancementUtils;
