import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { CompanyStratalotType, CompanyStratalotTypeEntityState } from '@_model/interfaces/company-stratalot-type.model';
import { CompanyStratalotTypeRelationsIds } from './company-stratalot-type-generated.effects';

export const getOneCompanyStratalotType = createAction(
  '[CompanyStratalotType] Get One CompanyStratalotType',
  props<{ idCompanyStratalotType: number; params?: any }>()
);

export const getManyCompanyStratalotTypes = createAction(
  '[CompanyStratalotType] Get Many CompanyStratalotTypes',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[CompanyStratalotType] Add Many Actives CompanyStratalotType',
  props<{ idCompanyStratalotTypes: number[] }>()
);

export const deleteOneActive = createAction(
  '[CompanyStratalotType] Delete One Active CompanyStratalotType',
  props<{ idCompanyStratalotType: number }>()
);

export const clearActive = createAction('[CompanyStratalotType] Clear Active CompanyStratalotType');

export const upsertOneCompanyStratalotType = createAction(
  '[CompanyStratalotType] Upsert One CompanyStratalotType',
  props<{
    companyStratalotType: Partial<CompanyStratalotType>;
    ids?: CompanyStratalotTypeRelationsIds;
  }>()
);

export const upsertManyCompanyStratalotTypes = createAction(
  '[CompanyStratalotType] Upsert Many CompanyStratalotTypes',
  props<{
    companyStratalotTypes: Partial<CompanyStratalotType>[];
    ids?: CompanyStratalotTypeRelationsIds;
  }>()
);

export const upsertManyCompanyStratalotTypesSuccess = createAction(
  '[CompanyStratalotType] Create Many CompanyStratalotTypes Success',
  props<{ companyStratalotTypes: CompanyStratalotTypeEntityState[] }>()
);

export const deleteOneCompanyStratalotType = createAction(
  '[CompanyStratalotType] Delete One CompanyStratalotType',
  props<{ idCompanyStratalotType: number }>()
);

export const deleteOneCompanyStratalotTypeSuccess = createAction(
  '[CompanyStratalotType] Delete One CompanyStratalotType Success',
  props<{ idCompanyStratalotType: number }>()
);

export const normalizeManyCompanyStratalotTypesAfterUpsert = createAction(
  '[CompanyStratalotType] Normalize Many CompanyStratalotTypes After Upsert',
  props<{ companyStratalotTypes: CompanyStratalotTypeEntityState[] }>()
);

export const companyStratalotTypesFailure = createAction(
  '[CompanyStratalotType] CompanyStratalotTypes Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[CompanyStratalotType] Clear CompanyStratalotTypes');

export const addManyStratalotSuccess = createAction(
  '[CompanyStratalotType] Add Many stratalot',
  props<{ idCompanyStratalotType: number; idStratalots: number[] }>()
);

export const deleteManyStratalotSuccess = createAction(
  '[CompanyStratalotType] Delete Many Stratalots',
  props<{ idStratalots: number[]; idCompanyStratalotTypes: number[] }>()
);

export const addManyStratalotPriceListSuccess = createAction(
  '[CompanyStratalotType] Add Many stratalot-price-list',
  props<{ idCompanyStratalotType: number; idStratalotPriceLists: number[] }>()
);

export const deleteManyStratalotPriceListSuccess = createAction(
  '[CompanyStratalotType] Delete Many StratalotPriceLists',
  props<{ idStratalotPriceLists: number[]; idCompanyStratalotTypes: number[] }>()
);

export const addManyOrganizationLeadTodoRuleSuccess = createAction(
  '[CompanyStratalotType] Add Many organization-lead-todo-rule',
  props<{ idCompanyStratalotType: number; idOrganizationLeadTodoRules: number[] }>()
);

export const deleteManyOrganizationLeadTodoRuleSuccess = createAction(
  '[CompanyStratalotType] Delete Many OrganizationLeadTodoRules',
  props<{ idOrganizationLeadTodoRules: number[]; idCompanyStratalotTypes: number[] }>()
);

export const addManyOrganizationStratalotTodoRuleSuccess = createAction(
  '[CompanyStratalotType] Add Many organization-stratalot-todo-rule',
  props<{ idCompanyStratalotType: number; idOrganizationStratalotTodoRules: number[] }>()
);

export const deleteManyOrganizationStratalotTodoRuleSuccess = createAction(
  '[CompanyStratalotType] Delete Many OrganizationStratalotTodoRules',
  props<{ idOrganizationStratalotTodoRules: number[]; idCompanyStratalotTypes: number[] }>()
);

export const addCompanySuccess = createAction(
  '[CompanyStratalotType] Add Company',
  props<{ idCompanyStratalotType: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[CompanyStratalotType] Delete Many Company',
  props<{ idCompanies: number[]; idCompanyStratalotTypes: number[] }>()
);

export const addStratalotTypeSuccess = createAction(
  '[CompanyStratalotType] Add StratalotType',
  props<{ idCompanyStratalotType: number; idStratalotType: number }>()
);

export const deleteManyStratalotTypeSuccess = createAction(
  '[CompanyStratalotType] Delete Many StratalotType',
  props<{ idStratalotTypes: number[]; idCompanyStratalotTypes: number[] }>()
);
