import { Component } from '@angular/core';
import { IconRegistrationService } from '@_services/icon-registration.service';
import { Title } from '@angular/platform-browser';
import { environment } from '@_environments/environment';
import { OrganizationService } from '@_services/store/organization.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { UserService } from '@_services/store/user.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  constructor(
    private customIconService: IconRegistrationService,
    private titleService: Title,
    private organizationService: OrganizationService,
    private readonly userService: UserService
  ) {
    combineLatest([this.userService.selectAllActiveUsers(), this.organizationService.selectAllOrganizations()])
      .pipe(untilDestroyed(this))
      .subscribe(([[user], [organization]]) => {
        if (environment.integration) {
          this.titleService.setTitle('Int ' + organization.nom);
        } else if (environment.test) {
          this.titleService.setTitle('Test ' + organization.nom);
        } else if (environment.qualif) {
          this.titleService.setTitle('Qualif ' + organization.nom);
        } else if (environment.development) {
          this.titleService.setTitle('Dev ' + organization.nom);
        } else if (environment.staging) {
          this.titleService.setTitle('Staging ' + organization.nom);
        } else if (environment.production && user?.email?.includes('@cellance.com')) {
          this.titleService.setTitle('Up ' + organization.nom);
        }
      });

    if (environment.integration) {
      this.titleService.setTitle('Int Up by Cellance');
    } else if (environment.qualif) {
      this.titleService.setTitle('Qualif Up by Cellance');
    } else if (environment.development) {
      this.titleService.setTitle('Dev Up by Cellance');
    } else if (environment.staging) {
      this.titleService.setTitle('Staging Up by Cellance');
    }

    this.customIconService.init();
  }
}
