import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationLeadTodoAppliedRule,
  OrganizationLeadTodoAppliedRuleEntityState
} from '@_model/interfaces/organization-lead-todo-applied-rule.model';
import { OrganizationLeadTodoAppliedRuleRelationsIds } from './organization-lead-todo-applied-rule-generated.effects';

export const getOneOrganizationLeadTodoAppliedRule = createAction(
  '[OrganizationLeadTodoAppliedRule] Get One OrganizationLeadTodoAppliedRule',
  props<{ idOrganizationLeadTodoAppliedRule: number; params?: any }>()
);

export const getManyOrganizationLeadTodoAppliedRules = createAction(
  '[OrganizationLeadTodoAppliedRule] Get Many OrganizationLeadTodoAppliedRules',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationLeadTodoAppliedRule] Add Many Actives OrganizationLeadTodoAppliedRule',
  props<{ idOrganizationLeadTodoAppliedRules: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationLeadTodoAppliedRule] Delete One Active OrganizationLeadTodoAppliedRule',
  props<{ idOrganizationLeadTodoAppliedRule: number }>()
);

export const clearActive = createAction(
  '[OrganizationLeadTodoAppliedRule] Clear Active OrganizationLeadTodoAppliedRule'
);

export const upsertOneOrganizationLeadTodoAppliedRule = createAction(
  '[OrganizationLeadTodoAppliedRule] Upsert One OrganizationLeadTodoAppliedRule',
  props<{
    organizationLeadTodoAppliedRule: Partial<OrganizationLeadTodoAppliedRule>;
    ids?: OrganizationLeadTodoAppliedRuleRelationsIds;
  }>()
);

export const upsertManyOrganizationLeadTodoAppliedRules = createAction(
  '[OrganizationLeadTodoAppliedRule] Upsert Many OrganizationLeadTodoAppliedRules',
  props<{
    organizationLeadTodoAppliedRules: Partial<OrganizationLeadTodoAppliedRule>[];
    ids?: OrganizationLeadTodoAppliedRuleRelationsIds;
  }>()
);

export const upsertManyOrganizationLeadTodoAppliedRulesSuccess = createAction(
  '[OrganizationLeadTodoAppliedRule] Create Many OrganizationLeadTodoAppliedRules Success',
  props<{ organizationLeadTodoAppliedRules: OrganizationLeadTodoAppliedRuleEntityState[] }>()
);

export const deleteOneOrganizationLeadTodoAppliedRule = createAction(
  '[OrganizationLeadTodoAppliedRule] Delete One OrganizationLeadTodoAppliedRule',
  props<{ idOrganizationLeadTodoAppliedRule: number }>()
);

export const deleteOneOrganizationLeadTodoAppliedRuleSuccess = createAction(
  '[OrganizationLeadTodoAppliedRule] Delete One OrganizationLeadTodoAppliedRule Success',
  props<{ idOrganizationLeadTodoAppliedRule: number }>()
);

export const normalizeManyOrganizationLeadTodoAppliedRulesAfterUpsert = createAction(
  '[OrganizationLeadTodoAppliedRule] Normalize Many OrganizationLeadTodoAppliedRules After Upsert',
  props<{ organizationLeadTodoAppliedRules: OrganizationLeadTodoAppliedRuleEntityState[] }>()
);

export const organizationLeadTodoAppliedRulesFailure = createAction(
  '[OrganizationLeadTodoAppliedRule] OrganizationLeadTodoAppliedRules Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationLeadTodoAppliedRule] Clear OrganizationLeadTodoAppliedRules');

export const addOrganizationLeadTodoSuccess = createAction(
  '[OrganizationLeadTodoAppliedRule] Add OrganizationLeadTodo',
  props<{ idOrganizationLeadTodoAppliedRule: number; idOrganizationLeadTodo: number }>()
);

export const deleteManyOrganizationLeadTodoSuccess = createAction(
  '[OrganizationLeadTodoAppliedRule] Delete Many OrganizationLeadTodo',
  props<{ idOrganizationLeadTodos: number[]; idOrganizationLeadTodoAppliedRules: number[] }>()
);

export const addOrganizationLeadTodoRuleSuccess = createAction(
  '[OrganizationLeadTodoAppliedRule] Add OrganizationLeadTodoRule',
  props<{ idOrganizationLeadTodoAppliedRule: number; idOrganizationLeadTodoRule: number }>()
);

export const deleteManyOrganizationLeadTodoRuleSuccess = createAction(
  '[OrganizationLeadTodoAppliedRule] Delete Many OrganizationLeadTodoRule',
  props<{ idOrganizationLeadTodoRules: number[]; idOrganizationLeadTodoAppliedRules: number[] }>()
);
