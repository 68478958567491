import { ResidenceSaleCategoryFamilyState, initialState } from './residence-sale-category-family.state';
import { Action, createReducer } from '@ngrx/store';
import { residenceSaleCategoryFamilyReducersGeneratedFunctions } from './residence-sale-category-family-generated.reducer';

const residenceSaleCategoryFamilyReducersFunctions = [...residenceSaleCategoryFamilyReducersGeneratedFunctions];

const residenceSaleCategoryFamilyReducer = createReducer(initialState, ...residenceSaleCategoryFamilyReducersFunctions);

export function reducer(state: ResidenceSaleCategoryFamilyState | undefined, action: Action) {
  return residenceSaleCategoryFamilyReducer(state, action);
}
