import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import {
  CompanyCommunicationRecipientMetier,
  CompanyCommunicationRecipientMetierEntityState
} from '@_model/interfaces/company-communication-recipient-metier.model';
import { CompanyCommunicationRecipientMetierApiService } from '@_services/api/company-communication-recipient-metier-api.service';
import * as companyCommunicationRecipientMetierActions from '@_store/company-communication-recipient-metier/company-communication-recipient-metier.actions';
import { getActionsToNormalizeCompanyCommunicationRecipientMetier } from '@_config/store/normalization.generated';
import { selectCompanyCommunicationRecipientMetierState } from './company-communication-recipient-metier-generated.selectors';
import * as companyCommunicationRecipientActions from '@_store/company-communication-recipient/company-communication-recipient.actions';
import * as organizationMetierActions from '@_store/organization-metier/organization-metier.actions';

export interface CompanyCommunicationRecipientMetierRelationsIds {
  companyCommunicationRecipient?: number;
  organizationMetier?: number;
}

export function getDefaultAddCompanyCommunicationRecipientMetierActions(
  companyCommunicationRecipientMetier: CompanyCommunicationRecipientMetierEntityState,
  ids?: CompanyCommunicationRecipientMetierRelationsIds
): Action[] {
  const actions: Action[] = [
    companyCommunicationRecipientMetierActions.normalizeManyCompanyCommunicationRecipientMetiersAfterUpsert({
      companyCommunicationRecipientMetiers: [companyCommunicationRecipientMetier]
    })
  ];

  if (ids?.companyCommunicationRecipient) {
    actions.push(
      companyCommunicationRecipientActions.addManyCompanyCommunicationRecipientMetierSuccess({
        idCompanyCommunicationRecipient: ids.companyCommunicationRecipient,
        idCompanyCommunicationRecipientMetiers: [
          companyCommunicationRecipientMetier.idCompanyCommunicationRecipientMetier
        ]
      })
    );
    actions.push(
      companyCommunicationRecipientMetierActions.addCompanyCommunicationRecipientSuccess({
        idCompanyCommunicationRecipientMetier:
          companyCommunicationRecipientMetier.idCompanyCommunicationRecipientMetier,
        idCompanyCommunicationRecipient: ids.companyCommunicationRecipient
      })
    );
  }

  if (ids?.organizationMetier) {
    actions.push(
      organizationMetierActions.addManyCompanyCommunicationRecipientMetierSuccess({
        idOrganizationMetier: ids.organizationMetier,
        idCompanyCommunicationRecipientMetiers: [
          companyCommunicationRecipientMetier.idCompanyCommunicationRecipientMetier
        ]
      })
    );
    actions.push(
      companyCommunicationRecipientMetierActions.addOrganizationMetierSuccess({
        idCompanyCommunicationRecipientMetier:
          companyCommunicationRecipientMetier.idCompanyCommunicationRecipientMetier,
        idOrganizationMetier: ids.organizationMetier
      })
    );
  }

  return actions;
}

export function getDefaultDeleteCompanyCommunicationRecipientMetierActions(
  companyCommunicationRecipientMetier: CompanyCommunicationRecipientMetierEntityState
): Action[] {
  const actions: Action[] = [
    companyCommunicationRecipientMetierActions.deleteOneCompanyCommunicationRecipientMetierSuccess({
      idCompanyCommunicationRecipientMetier: companyCommunicationRecipientMetier.idCompanyCommunicationRecipientMetier
    })
  ];

  if (companyCommunicationRecipientMetier.companyCommunicationRecipient) {
    actions.push(
      companyCommunicationRecipientActions.deleteManyCompanyCommunicationRecipientMetierSuccess({
        idCompanyCommunicationRecipientMetiers: [
          companyCommunicationRecipientMetier.idCompanyCommunicationRecipientMetier
        ],
        idCompanyCommunicationRecipients: [companyCommunicationRecipientMetier.companyCommunicationRecipient as number]
      })
    );
  }

  if (companyCommunicationRecipientMetier.organizationMetier) {
    actions.push(
      organizationMetierActions.deleteManyCompanyCommunicationRecipientMetierSuccess({
        idCompanyCommunicationRecipientMetiers: [
          companyCommunicationRecipientMetier.idCompanyCommunicationRecipientMetier
        ],
        idOrganizationMetiers: [companyCommunicationRecipientMetier.organizationMetier as number]
      })
    );
  }

  return actions;
}

export class GeneratedCompanyCommunicationRecipientMetierEffects {
  constructor(
    protected actions$: Actions,
    protected companyCommunicationRecipientMetierApiService: CompanyCommunicationRecipientMetierApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCompanyCommunicationRecipientMetiers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationRecipientMetierActions.getManyCompanyCommunicationRecipientMetiers),
      switchMap(({ params }) =>
        this.companyCommunicationRecipientMetierApiService.getCompanyCommunicationRecipientMetiers(params).pipe(
          map((companyCommunicationRecipientMetiers: CompanyCommunicationRecipientMetier[]) => {
            return companyCommunicationRecipientMetierActions.normalizeManyCompanyCommunicationRecipientMetiersAfterUpsert(
              { companyCommunicationRecipientMetiers }
            );
          }),
          catchError(error =>
            of(companyCommunicationRecipientMetierActions.companyCommunicationRecipientMetiersFailure({ error }))
          )
        )
      )
    );
  });

  getOneCompanyCommunicationRecipientMetier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationRecipientMetierActions.getOneCompanyCommunicationRecipientMetier),
      switchMap(idCompanyCommunicationRecipientMetier =>
        this.companyCommunicationRecipientMetierApiService
          .getCompanyCommunicationRecipientMetier(idCompanyCommunicationRecipientMetier)
          .pipe(
            map((companyCommunicationRecipientMetier: CompanyCommunicationRecipientMetier) => {
              return companyCommunicationRecipientMetierActions.normalizeManyCompanyCommunicationRecipientMetiersAfterUpsert(
                { companyCommunicationRecipientMetiers: [companyCommunicationRecipientMetier] }
              );
            }),
            catchError(error =>
              of(companyCommunicationRecipientMetierActions.companyCommunicationRecipientMetiersFailure({ error }))
            )
          )
      )
    );
  });

  upsertOneCompanyCommunicationRecipientMetier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationRecipientMetierActions.upsertOneCompanyCommunicationRecipientMetier),
      concatMap(
        ({
          companyCommunicationRecipientMetier,
          ids
        }: {
          companyCommunicationRecipientMetier: Partial<CompanyCommunicationRecipientMetier>;
          ids?: CompanyCommunicationRecipientMetierRelationsIds;
        }) => {
          if (companyCommunicationRecipientMetier.idCompanyCommunicationRecipientMetier) {
            return this.companyCommunicationRecipientMetierApiService
              .updateCompanyCommunicationRecipientMetier(companyCommunicationRecipientMetier)
              .pipe(
                map((companyCommunicationRecipientMetierReturned: CompanyCommunicationRecipientMetier) => {
                  return companyCommunicationRecipientMetierActions.normalizeManyCompanyCommunicationRecipientMetiersAfterUpsert(
                    { companyCommunicationRecipientMetiers: [companyCommunicationRecipientMetierReturned] }
                  );
                }),
                catchError(error =>
                  of(companyCommunicationRecipientMetierActions.companyCommunicationRecipientMetiersFailure({ error }))
                )
              );
          } else {
            return this.companyCommunicationRecipientMetierApiService
              .addCompanyCommunicationRecipientMetier(companyCommunicationRecipientMetier)
              .pipe(
                mergeMap((companyCommunicationRecipientMetierReturned: CompanyCommunicationRecipientMetier) =>
                  getDefaultAddCompanyCommunicationRecipientMetierActions(
                    companyCommunicationRecipientMetierReturned,
                    ids
                  )
                ),
                catchError(error =>
                  of(companyCommunicationRecipientMetierActions.companyCommunicationRecipientMetiersFailure({ error }))
                )
              );
          }
        }
      )
    );
  });

  deleteOneCompanyCommunicationRecipientMetier$ = createEffect(() => {
    const selectCompanyCommunicationRecipientMetierState$ = this.store$.select(
      selectCompanyCommunicationRecipientMetierState
    );
    return this.actions$.pipe(
      ofType(companyCommunicationRecipientMetierActions.deleteOneCompanyCommunicationRecipientMetier),
      withLatestFrom(selectCompanyCommunicationRecipientMetierState$),
      concatMap(([{ idCompanyCommunicationRecipientMetier }, state]) =>
        this.companyCommunicationRecipientMetierApiService
          .deleteCompanyCommunicationRecipientMetier(idCompanyCommunicationRecipientMetier)
          .pipe(
            mergeMap(_success => [
              getMultiAction(
                getDefaultDeleteCompanyCommunicationRecipientMetierActions(
                  state.entities[
                    idCompanyCommunicationRecipientMetier
                  ] as CompanyCommunicationRecipientMetierEntityState
                ),
                companyCommunicationRecipientMetierActions.deleteOneCompanyCommunicationRecipientMetier.type
              )
            ]),
            catchError(error =>
              of(companyCommunicationRecipientMetierActions.companyCommunicationRecipientMetiersFailure({ error }))
            )
          )
      )
    );
  });

  normalizeManyCompanyCommunicationRecipientMetiersAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyCommunicationRecipientMetierActions.normalizeManyCompanyCommunicationRecipientMetiersAfterUpsert),
      concatMap(({ companyCommunicationRecipientMetiers }) => {
        const actions: Action[] = getActionsToNormalizeCompanyCommunicationRecipientMetier(
          companyCommunicationRecipientMetiers,
          StoreActionType.upsert
        );
        return [getMultiAction(actions, '[CompanyCommunicationRecipientMetier] Normalization After Upsert Success')];
      })
    );
  });
}
