import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { LeadTodoEntityState } from '@_model/interfaces/lead-todo.model';

export interface LeadTodoState extends EntityState<LeadTodoEntityState> {
  isLoaded: boolean;
  isLoading: boolean;
  actives: number[];
}

export const adapter: EntityAdapter<LeadTodoEntityState> = createEntityAdapter<LeadTodoEntityState>({
  selectId: o => o.idLeadTodo
});
export const initialState: LeadTodoState = adapter.getInitialState({ isLoaded: false, isLoading: false, actives: [] });

export const leadTodoFeatureKey = 'leadTodo';
