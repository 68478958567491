import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StepApiService } from '@_services/api/step-api.service';
import { AppState } from '@_store/index.reducers';
import { GeneratedStepEffects } from './step-generated.effects';

@Injectable()
export class StepEffects extends GeneratedStepEffects {
  constructor(actions$: Actions, stepApiService: StepApiService, store$: Store<AppState>) {
    super(actions$, stepApiService, store$);
  }
}
