import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { ResidenceTodo, ResidenceTodoEntityState } from '@_model/interfaces/residence-todo.model';
import { ResidenceTodoApiService } from '@_services/api/residence-todo-api.service';
import * as residenceTodoActions from '@_store/residence-todo/residence-todo.actions';
import { getActionsToNormalizeResidenceTodo } from '@_config/store/normalization.generated';
import { selectResidenceTodoState } from './residence-todo-generated.selectors';
import * as organizationResidenceTodoActions from '@_store/organization-residence-todo/organization-residence-todo.actions';
import * as residenceActions from '@_store/residence/residence.actions';

export interface ResidenceTodoRelationsIds {
  organizationResidenceTodo?: number;
  residence?: number;
}

export function getDefaultAddResidenceTodoActions(
  residenceTodo: ResidenceTodoEntityState,
  ids?: ResidenceTodoRelationsIds
): Action[] {
  const actions: Action[] = [
    residenceTodoActions.normalizeManyResidenceTodosAfterUpsert({ residenceTodos: [residenceTodo] })
  ];

  if (ids?.organizationResidenceTodo) {
    actions.push(
      organizationResidenceTodoActions.addManyResidenceTodoSuccess({
        idOrganizationResidenceTodo: ids.organizationResidenceTodo,
        idResidenceTodos: [residenceTodo.idResidenceTodo]
      })
    );
    actions.push(
      residenceTodoActions.addOrganizationResidenceTodoSuccess({
        idResidenceTodo: residenceTodo.idResidenceTodo,
        idOrganizationResidenceTodo: ids.organizationResidenceTodo
      })
    );
  }

  if (ids?.residence) {
    actions.push(
      residenceActions.addManyResidenceTodoSuccess({
        idResidence: ids.residence,
        idResidenceTodos: [residenceTodo.idResidenceTodo]
      })
    );
    actions.push(
      residenceTodoActions.addResidenceSuccess({
        idResidenceTodo: residenceTodo.idResidenceTodo,
        idResidence: ids.residence
      })
    );
  }

  return actions;
}

export function getDefaultDeleteResidenceTodoActions(residenceTodo: ResidenceTodoEntityState): Action[] {
  const actions: Action[] = [
    residenceTodoActions.deleteOneResidenceTodoSuccess({ idResidenceTodo: residenceTodo.idResidenceTodo })
  ];

  if (residenceTodo.organizationResidenceTodo) {
    actions.push(
      organizationResidenceTodoActions.deleteManyResidenceTodoSuccess({
        idResidenceTodos: [residenceTodo.idResidenceTodo],
        idOrganizationResidenceTodos: [residenceTodo.organizationResidenceTodo as number]
      })
    );
  }

  if (residenceTodo.residence) {
    actions.push(
      residenceActions.deleteManyResidenceTodoSuccess({
        idResidenceTodos: [residenceTodo.idResidenceTodo],
        idResidences: [residenceTodo.residence as number]
      })
    );
  }

  return actions;
}

export class GeneratedResidenceTodoEffects {
  constructor(
    protected actions$: Actions,
    protected residenceTodoApiService: ResidenceTodoApiService,
    protected store$: Store<AppState>
  ) {}

  getManyResidenceTodos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceTodoActions.getManyResidenceTodos),
      switchMap(({ params }) =>
        this.residenceTodoApiService.getResidenceTodos(params).pipe(
          map((residenceTodos: ResidenceTodo[]) => {
            return residenceTodoActions.normalizeManyResidenceTodosAfterUpsert({ residenceTodos });
          }),
          catchError(error => of(residenceTodoActions.residenceTodosFailure({ error })))
        )
      )
    );
  });

  getOneResidenceTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceTodoActions.getOneResidenceTodo),
      switchMap(idResidenceTodo =>
        this.residenceTodoApiService.getResidenceTodo(idResidenceTodo).pipe(
          map((residenceTodo: ResidenceTodo) => {
            return residenceTodoActions.normalizeManyResidenceTodosAfterUpsert({ residenceTodos: [residenceTodo] });
          }),
          catchError(error => of(residenceTodoActions.residenceTodosFailure({ error })))
        )
      )
    );
  });

  upsertOneResidenceTodo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceTodoActions.upsertOneResidenceTodo),
      concatMap(
        ({ residenceTodo, ids }: { residenceTodo: Partial<ResidenceTodo>; ids?: ResidenceTodoRelationsIds }) => {
          if (residenceTodo.idResidenceTodo) {
            return this.residenceTodoApiService.updateResidenceTodo(residenceTodo).pipe(
              map((residenceTodoReturned: ResidenceTodo) => {
                return residenceTodoActions.normalizeManyResidenceTodosAfterUpsert({
                  residenceTodos: [residenceTodoReturned]
                });
              }),
              catchError(error => of(residenceTodoActions.residenceTodosFailure({ error })))
            );
          } else {
            return this.residenceTodoApiService.addResidenceTodo(residenceTodo).pipe(
              mergeMap((residenceTodoReturned: ResidenceTodo) =>
                getDefaultAddResidenceTodoActions(residenceTodoReturned, ids)
              ),
              catchError(error => of(residenceTodoActions.residenceTodosFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneResidenceTodo$ = createEffect(() => {
    const selectResidenceTodoState$ = this.store$.select(selectResidenceTodoState);
    return this.actions$.pipe(
      ofType(residenceTodoActions.deleteOneResidenceTodo),
      withLatestFrom(selectResidenceTodoState$),
      concatMap(([{ idResidenceTodo }, state]) =>
        this.residenceTodoApiService.deleteResidenceTodo(idResidenceTodo).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteResidenceTodoActions(state.entities[idResidenceTodo] as ResidenceTodoEntityState),
              residenceTodoActions.deleteOneResidenceTodo.type
            )
          ]),
          catchError(error => of(residenceTodoActions.residenceTodosFailure({ error })))
        )
      )
    );
  });

  normalizeManyResidenceTodosAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(residenceTodoActions.normalizeManyResidenceTodosAfterUpsert),
      concatMap(({ residenceTodos }) => {
        const actions: Action[] = getActionsToNormalizeResidenceTodo(residenceTodos, StoreActionType.upsert);
        return [getMultiAction(actions, '[ResidenceTodo] Normalization After Upsert Success')];
      })
    );
  });
}
