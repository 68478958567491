import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationLeadTodoProfil,
  OrganizationLeadTodoProfilEntityState
} from '@_model/interfaces/organization-lead-todo-profil.model';
import { OrganizationLeadTodoProfilRelationsIds } from './organization-lead-todo-profil-generated.effects';

export const getOneOrganizationLeadTodoProfil = createAction(
  '[OrganizationLeadTodoProfil] Get One OrganizationLeadTodoProfil',
  props<{ idOrganizationLeadTodoProfil: number; params?: any }>()
);

export const getManyOrganizationLeadTodoProfils = createAction(
  '[OrganizationLeadTodoProfil] Get Many OrganizationLeadTodoProfils',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationLeadTodoProfil] Add Many Actives OrganizationLeadTodoProfil',
  props<{ idOrganizationLeadTodoProfils: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationLeadTodoProfil] Delete One Active OrganizationLeadTodoProfil',
  props<{ idOrganizationLeadTodoProfil: number }>()
);

export const clearActive = createAction('[OrganizationLeadTodoProfil] Clear Active OrganizationLeadTodoProfil');

export const upsertOneOrganizationLeadTodoProfil = createAction(
  '[OrganizationLeadTodoProfil] Upsert One OrganizationLeadTodoProfil',
  props<{
    organizationLeadTodoProfil: Partial<OrganizationLeadTodoProfil>;
    ids?: OrganizationLeadTodoProfilRelationsIds;
  }>()
);

export const upsertManyOrganizationLeadTodoProfils = createAction(
  '[OrganizationLeadTodoProfil] Upsert Many OrganizationLeadTodoProfils',
  props<{
    organizationLeadTodoProfils: Partial<OrganizationLeadTodoProfil>[];
    ids?: OrganizationLeadTodoProfilRelationsIds;
  }>()
);

export const upsertManyOrganizationLeadTodoProfilsSuccess = createAction(
  '[OrganizationLeadTodoProfil] Create Many OrganizationLeadTodoProfils Success',
  props<{ organizationLeadTodoProfils: OrganizationLeadTodoProfilEntityState[] }>()
);

export const deleteOneOrganizationLeadTodoProfil = createAction(
  '[OrganizationLeadTodoProfil] Delete One OrganizationLeadTodoProfil',
  props<{ idOrganizationLeadTodoProfil: number }>()
);

export const deleteOneOrganizationLeadTodoProfilSuccess = createAction(
  '[OrganizationLeadTodoProfil] Delete One OrganizationLeadTodoProfil Success',
  props<{ idOrganizationLeadTodoProfil: number }>()
);

export const normalizeManyOrganizationLeadTodoProfilsAfterUpsert = createAction(
  '[OrganizationLeadTodoProfil] Normalize Many OrganizationLeadTodoProfils After Upsert',
  props<{ organizationLeadTodoProfils: OrganizationLeadTodoProfilEntityState[] }>()
);

export const organizationLeadTodoProfilsFailure = createAction(
  '[OrganizationLeadTodoProfil] OrganizationLeadTodoProfils Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationLeadTodoProfil] Clear OrganizationLeadTodoProfils');

export const addProfilSuccess = createAction(
  '[OrganizationLeadTodoProfil] Add Profil',
  props<{ idOrganizationLeadTodoProfil: number; idProfil: number }>()
);

export const deleteManyProfilSuccess = createAction(
  '[OrganizationLeadTodoProfil] Delete Many Profil',
  props<{ idProfils: number[]; idOrganizationLeadTodoProfils: number[] }>()
);

export const addOrganizationLeadTodoSuccess = createAction(
  '[OrganizationLeadTodoProfil] Add OrganizationLeadTodo',
  props<{ idOrganizationLeadTodoProfil: number; idOrganizationLeadTodo: number }>()
);

export const deleteManyOrganizationLeadTodoSuccess = createAction(
  '[OrganizationLeadTodoProfil] Delete Many OrganizationLeadTodo',
  props<{ idOrganizationLeadTodos: number[]; idOrganizationLeadTodoProfils: number[] }>()
);
