import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  OrganizationLeadTodoRule,
  OrganizationLeadTodoRuleEntityState
} from '@_model/interfaces/organization-lead-todo-rule.model';
import { OrganizationLeadTodoRuleRelationsIds } from './organization-lead-todo-rule-generated.effects';

export const getOneOrganizationLeadTodoRule = createAction(
  '[OrganizationLeadTodoRule] Get One OrganizationLeadTodoRule',
  props<{ idOrganizationLeadTodoRule: number; params?: any }>()
);

export const getManyOrganizationLeadTodoRules = createAction(
  '[OrganizationLeadTodoRule] Get Many OrganizationLeadTodoRules',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationLeadTodoRule] Add Many Actives OrganizationLeadTodoRule',
  props<{ idOrganizationLeadTodoRules: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationLeadTodoRule] Delete One Active OrganizationLeadTodoRule',
  props<{ idOrganizationLeadTodoRule: number }>()
);

export const clearActive = createAction('[OrganizationLeadTodoRule] Clear Active OrganizationLeadTodoRule');

export const upsertOneOrganizationLeadTodoRule = createAction(
  '[OrganizationLeadTodoRule] Upsert One OrganizationLeadTodoRule',
  props<{
    organizationLeadTodoRule: Partial<OrganizationLeadTodoRule>;
    ids?: OrganizationLeadTodoRuleRelationsIds;
  }>()
);

export const upsertManyOrganizationLeadTodoRules = createAction(
  '[OrganizationLeadTodoRule] Upsert Many OrganizationLeadTodoRules',
  props<{
    organizationLeadTodoRules: Partial<OrganizationLeadTodoRule>[];
    ids?: OrganizationLeadTodoRuleRelationsIds;
  }>()
);

export const upsertManyOrganizationLeadTodoRulesSuccess = createAction(
  '[OrganizationLeadTodoRule] Create Many OrganizationLeadTodoRules Success',
  props<{ organizationLeadTodoRules: OrganizationLeadTodoRuleEntityState[] }>()
);

export const deleteOneOrganizationLeadTodoRule = createAction(
  '[OrganizationLeadTodoRule] Delete One OrganizationLeadTodoRule',
  props<{ idOrganizationLeadTodoRule: number }>()
);

export const deleteOneOrganizationLeadTodoRuleSuccess = createAction(
  '[OrganizationLeadTodoRule] Delete One OrganizationLeadTodoRule Success',
  props<{ idOrganizationLeadTodoRule: number }>()
);

export const normalizeManyOrganizationLeadTodoRulesAfterUpsert = createAction(
  '[OrganizationLeadTodoRule] Normalize Many OrganizationLeadTodoRules After Upsert',
  props<{ organizationLeadTodoRules: OrganizationLeadTodoRuleEntityState[] }>()
);

export const organizationLeadTodoRulesFailure = createAction(
  '[OrganizationLeadTodoRule] OrganizationLeadTodoRules Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationLeadTodoRule] Clear OrganizationLeadTodoRules');

export const addManyOrganizationLeadTodoSuccess = createAction(
  '[OrganizationLeadTodoRule] Add Many organization-lead-todo',
  props<{ idOrganizationLeadTodoRule: number; idOrganizationLeadTodos: number[] }>()
);

export const deleteManyOrganizationLeadTodoSuccess = createAction(
  '[OrganizationLeadTodoRule] Delete Many OrganizationLeadTodos',
  props<{ idOrganizationLeadTodos: number[]; idOrganizationLeadTodoRules: number[] }>()
);

export const addManyOrganizationLeadTodoAppliedRuleSuccess = createAction(
  '[OrganizationLeadTodoRule] Add Many organization-lead-todo-applied-rule',
  props<{ idOrganizationLeadTodoRule: number; idOrganizationLeadTodoAppliedRules: number[] }>()
);

export const deleteManyOrganizationLeadTodoAppliedRuleSuccess = createAction(
  '[OrganizationLeadTodoRule] Delete Many OrganizationLeadTodoAppliedRules',
  props<{ idOrganizationLeadTodoAppliedRules: number[]; idOrganizationLeadTodoRules: number[] }>()
);

export const addOrganizationSuccess = createAction(
  '[OrganizationLeadTodoRule] Add Organization',
  props<{ idOrganizationLeadTodoRule: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationLeadTodoRule] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationLeadTodoRules: number[] }>()
);

export const addOrganizationSaleCategorySuccess = createAction(
  '[OrganizationLeadTodoRule] Add OrganizationSaleCategory',
  props<{ idOrganizationLeadTodoRule: number; idOrganizationSaleCategory: number }>()
);

export const deleteManyOrganizationSaleCategorySuccess = createAction(
  '[OrganizationLeadTodoRule] Delete Many OrganizationSaleCategory',
  props<{ idOrganizationSaleCategories: number[]; idOrganizationLeadTodoRules: number[] }>()
);

export const addSaleCategoryFamilySuccess = createAction(
  '[OrganizationLeadTodoRule] Add SaleCategoryFamily',
  props<{ idOrganizationLeadTodoRule: number; idSaleCategoryFamily: number }>()
);

export const deleteManySaleCategoryFamilySuccess = createAction(
  '[OrganizationLeadTodoRule] Delete Many SaleCategoryFamily',
  props<{ idFamilySaleCategories: number[]; idOrganizationLeadTodoRules: number[] }>()
);

export const addCompanySuccess = createAction(
  '[OrganizationLeadTodoRule] Add Company',
  props<{ idOrganizationLeadTodoRule: number; idCompany: number }>()
);

export const deleteManyCompanySuccess = createAction(
  '[OrganizationLeadTodoRule] Delete Many Company',
  props<{ idCompanies: number[]; idOrganizationLeadTodoRules: number[] }>()
);

export const addCompanyStratalotTypeSuccess = createAction(
  '[OrganizationLeadTodoRule] Add CompanyStratalotType',
  props<{ idOrganizationLeadTodoRule: number; idCompanyStratalotType: number }>()
);

export const deleteManyCompanyStratalotTypeSuccess = createAction(
  '[OrganizationLeadTodoRule] Delete Many CompanyStratalotType',
  props<{ idCompanyStratalotTypes: number[]; idOrganizationLeadTodoRules: number[] }>()
);

export const addStratalotTypeSuccess = createAction(
  '[OrganizationLeadTodoRule] Add StratalotType',
  props<{ idOrganizationLeadTodoRule: number; idStratalotType: number }>()
);

export const deleteManyStratalotTypeSuccess = createAction(
  '[OrganizationLeadTodoRule] Delete Many StratalotType',
  props<{ idStratalotTypes: number[]; idOrganizationLeadTodoRules: number[] }>()
);
