import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BoardStateName } from '@_shared/models/enums/board-state-name';
import { BoardState } from '@_shared/models/interfaces/board-state.model';
import * as AppState from '@_store/index.reducers';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GeneratedBoardStateService } from './board-state-generated.service';

@Injectable({
  providedIn: 'root'
})
export class BoardStateService extends GeneratedBoardStateService {
  constructor(store$: Store<AppState.AppState>, actions$: Actions) {
    super(store$, actions$);
  }

  public createIfNotExists(name: BoardStateName): Observable<BoardState> {
    return this.selectAllBoardStates().pipe(
      map(boardStates => boardStates.find(boardState => boardState.name === name)),
      switchMap(boardState =>
        !!boardState ? of(boardState) : (this.upsertOneBoardState({ name }, {}, true) as Observable<BoardState>)
      )
    );
  }
}
