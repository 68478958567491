import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { ProspectApiService } from '@_services/api/prospect-api.service';
import { Prospect } from '@_shared/models/interfaces/prospect.model';
import { AppState } from '@_store/index.reducers';
import * as leadActions from '@_store/lead/lead.actions';
import * as occupantActions from '@_store/occupant/occupant.actions';
import * as organizationActions from '@_store/organization/organization.actions';
import * as prospectBuyingWishActions from '@_store/prospect-buying-wish/prospect-buying-wish.actions';
import * as prospectEventActions from '@_store/prospect-event/prospect-event.actions';
import * as prospectActions from '@_store/prospect/prospect.actions';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { GeneratedProspectEffects } from './prospect-generated.effects';
import { selectProspectState } from './prospect-generated.selectors';

@Injectable()
export class ProspectEffects extends GeneratedProspectEffects {
  constructor(actions$: Actions, prospectApiService: ProspectApiService, store$: Store<AppState>) {
    super(actions$, prospectApiService, store$);
  }

  public getManyProspectsByResidence$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(prospectActions.getManyProspectsByResidence),
      concatMap(({ idResidence, params }) =>
        this.prospectApiService.getProspectsByResidence(idResidence, params).pipe(
          map((prospects: Prospect[]) => {
            return prospectActions.normalizeManyProspectsAfterUpsert({ prospects });
          }),
          catchError(error => of(prospectActions.prospectsFailure({ error })))
        )
      )
    );
  });

  // public deleteOneProspect$ = createEffect(() => {
  //   const selectProspectState$ = this.store$.select(selectProspectState);
  //   return this.actions$.pipe(
  //     ofType(prospectActions.deleteOneProspect),
  //     withLatestFrom(selectProspectState$),
  //     map(([{ idProspect }, state]) => [{ idProspect }, { prospect: state.entities[idProspect] }]),
  //     concatMap(([{ idProspect }, { prospect }]) =>
  //       this.prospectApiService.deleteProspect(idProspect).pipe(
  //         mergeMap(_success => {
  //           const actions: Action[] = [prospectActions.deleteOneProspectSuccess({ idProspect: prospect.idProspect })];

  //           if (prospect.occupant) {
  //             actions.push(
  //               occupantActions.deleteManyProspectSuccess({
  //                 idProspects: [prospect.idProspect],
  //                 idOccupants: [prospect.occupant as number]
  //               })
  //             );
  //           }

  //           if (prospect.organization) {
  //             actions.push(
  //               organizationActions.deleteManyProspectSuccess({
  //                 idProspects: [prospect.idProspect],
  //                 idOrganizations: [prospect.organization as number]
  //               })
  //             );
  //           }

  //           if (prospect.organizationProspectOrigin) {
  //             actions.push(
  //               organizationProspectOriginActions.deleteManyProspectSuccess({
  //                 idProspects: [prospect.idProspect],
  //                 idOrganizationProspectOrigins: [prospect.organizationProspectOrigin as number]
  //               })
  //             );
  //           }

  //           if (prospect.responsable) {
  //             actions.push(
  //               userActions.deleteManyProspectSuccess({
  //                 idProspect: [prospect.idProspect],
  //                 idUsers: [prospect.responsable as number]
  //               })
  //             );
  //           }

  //           // Actions personnalisées pour enlever les éléments du store et pas simplement enlever la liaison
  //           if (prospect.prospectEvents) {
  //             actions.push(prospectEventActions.deleteManyProspectEventByProspectSuccess({ idProspect }));
  //             // actions.push(
  //             //   prospectEventActions.deleteManyProspectSuccess({
  //             //     idProspects: [prospect.idProspect],
  //             //     idProspectEvents: prospect.prospectEvents as number[]
  //             //   })
  //             // );
  //           }

  //           if (prospect.prospectBuyingWishs) {
  //             actions.push(prospectBuyingWishActions.deleteManyProspectBuyingWishByProspectSuccess({ idProspect }));
  //             // actions.push(
  //             //   prospectBuyingWishActions.deleteManyProspectSuccess({
  //             //     idProspects: [prospect.idProspect],
  //             //     idProspectBuyingWishs: prospect.prospectBuyingWishs as number[]
  //             //   })
  //             // );
  //           }

  //           if (prospect.leads) {
  //             actions.push(leadActions.deleteManyLeadsByProspectSuccess({ idProspect }));
  //             // actions.push(
  //             //   leadActions.deleteManyProspectSuccess({
  //             //     idProspects: [prospect.idProspect],
  //             //     idLeads: prospect.leads as number[]
  //             //   })
  //             // );
  //           }

  //           return [getMultiAction(actions, prospectActions.deleteOneProspect.type)];
  //         }),
  //         catchError(error => of(prospectActions.prospectsFailure({ error })))
  //       )
  //     )
  //   );
  // }) as any;

  public deleteProspectsByOrganization$ = createEffect(() => {
    const selectProspectState$ = this.store$.select(selectProspectState);
    return this.actions$.pipe(
      ofType(prospectActions.deleteManyProspectsByOrganization),
      withLatestFrom(selectProspectState$),
      map(([{ idOrganization }, state]) => [{ idOrganization }, { entities: state.entities }]),
      concatMap(([{ idOrganization }, { entities }]) =>
        this.prospectApiService.deleteProspectByOrganization(idOrganization).pipe(
          mergeMap(idProspects => {
            const prospects = idProspects.map(idProspect => entities[idProspect]);

            const idOccupants = [...new Set([prospects.map(prospect => prospect.occupant)].flat(2))] as number[];
            const idProspectEvents = [
              ...new Set([prospects.map(prospect => prospect.prospectEvents)].flat(2))
            ] as number[];
            const idProspectBuyingWishs = [
              ...new Set([prospects.map(prospect => prospect.prospectBuyingWishs)].flat(2))
            ] as number[];
            const idLeads = [...new Set([prospects.map(prospect => prospect.leads)].flat(2))] as number[];

            const actions: Action[] = [
              prospectActions.deleteManyProspectsSuccess({ idProspects }),
              organizationActions.deleteManyProspectSuccess({ idProspects, idOrganizations: [idOrganization] })
            ];
            if (idOccupants.length) {
              actions.push(occupantActions.deleteManyProspectSuccess({ idProspects, idOccupants }));
            }
            if (idProspectEvents.length) {
              actions.push(prospectEventActions.deleteManyProspectSuccess({ idProspects, idProspectEvents }));
            }
            if (idProspectBuyingWishs.length) {
              actions.push(prospectBuyingWishActions.deleteManyProspectSuccess({ idProspects, idProspectBuyingWishs }));
            }
            if (idLeads.length) {
              actions.push(leadActions.deleteManyProspectSuccess({ idProspects, idLeads }));
            }
            return actions;
          }),
          catchError(error => of(prospectActions.prospectsFailure({ error })))
        )
      )
    );
  });
}
