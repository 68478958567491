import { Actions } from '@ngrx/effects';
import { AppState } from '@_store/index.reducers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyCommunicationFileApiService } from '@_services/api/company-communication-file-api.service';
import { GeneratedCompanyCommunicationFileEffects } from './company-communication-file-generated.effects';

@Injectable()
export class CompanyCommunicationFileEffects extends GeneratedCompanyCommunicationFileEffects {
  constructor(
    actions$: Actions,
    companyCommunicationFileApiService: CompanyCommunicationFileApiService,
    store$: Store<AppState>
  ) {
    super(actions$, companyCommunicationFileApiService, store$);
  }
}
