import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@_store/index.reducers';
import { StoreActionType } from '@_shared/models/enums/store-action-type.enum';
import { getMultiAction } from '@_store/batched-actions';
import { ForecastPeriod, ForecastPeriodEntityState } from '@_model/interfaces/forecast-period.model';
import { ForecastPeriodApiService } from '@_services/api/forecast-period-api.service';
import * as forecastPeriodActions from '@_store/forecast-period/forecast-period.actions';
import { getActionsToNormalizeForecastPeriod } from '@_config/store/normalization.generated';
import { selectForecastPeriodState } from './forecast-period-generated.selectors';
import * as residenceForecastActions from '@_store/residence-forecast/residence-forecast.actions';
import { ResidenceForecast } from '@_model/interfaces/residence-forecast.model';

export interface ForecastPeriodRelationsIds {
  residenceForecasts?: number | number[];
}

export function getDefaultAddForecastPeriodActions(
  forecastPeriod: ForecastPeriodEntityState,
  ids?: ForecastPeriodRelationsIds
): Action[] {
  const actions: Action[] = [
    forecastPeriodActions.normalizeManyForecastPeriodsAfterUpsert({ forecastPeriods: [forecastPeriod] })
  ];

  if (ids?.residenceForecasts) {
    if (!Array.isArray(ids.residenceForecasts)) {
      actions.push(
        residenceForecastActions.upsertOneResidenceForecast({
          residenceForecast: {
            idForecastPeriod: forecastPeriod.idForecastPeriod,
            idResidenceForecast: ids.residenceForecasts as number
          } as ResidenceForecast
        })
      );
      actions.push(
        forecastPeriodActions.addManyResidenceForecastSuccess({
          idForecastPeriod: forecastPeriod.idForecastPeriod,
          idResidenceForecasts: [ids.residenceForecasts as number]
        })
      );
    } else {
      actions.push(
        residenceForecastActions.upsertManyResidenceForecasts({
          residenceForecasts: (ids.residenceForecasts as number[]).map((idResidenceForecast: number) => ({
            idForecastPeriod: forecastPeriod.idForecastPeriod,
            idResidenceForecast
          })) as ResidenceForecast[]
        })
      );
      actions.push(
        forecastPeriodActions.addManyResidenceForecastSuccess({
          idForecastPeriod: forecastPeriod.idForecastPeriod,
          idResidenceForecasts: ids.residenceForecasts as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteForecastPeriodActions(forecastPeriod: ForecastPeriodEntityState): Action[] {
  const actions: Action[] = [
    forecastPeriodActions.deleteOneForecastPeriodSuccess({ idForecastPeriod: forecastPeriod.idForecastPeriod })
  ];

  if (forecastPeriod.residenceForecasts) {
    actions.push(
      residenceForecastActions.deleteManyForecastPeriodSuccess({
        idForecastPeriods: [forecastPeriod.idForecastPeriod],
        idResidenceForecasts: forecastPeriod.residenceForecasts as number[]
      })
    );
  }

  return actions;
}

export class GeneratedForecastPeriodEffects {
  constructor(
    protected actions$: Actions,
    protected forecastPeriodApiService: ForecastPeriodApiService,
    protected store$: Store<AppState>
  ) {}

  getManyForecastPeriods$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(forecastPeriodActions.getManyForecastPeriods),
      switchMap(({ params }) =>
        this.forecastPeriodApiService.getForecastPeriods(params).pipe(
          map((forecastPeriods: ForecastPeriod[]) => {
            return forecastPeriodActions.normalizeManyForecastPeriodsAfterUpsert({ forecastPeriods });
          }),
          catchError(error => of(forecastPeriodActions.forecastPeriodsFailure({ error })))
        )
      )
    );
  });

  getOneForecastPeriod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(forecastPeriodActions.getOneForecastPeriod),
      switchMap(idForecastPeriod =>
        this.forecastPeriodApiService.getForecastPeriod(idForecastPeriod).pipe(
          map((forecastPeriod: ForecastPeriod) => {
            return forecastPeriodActions.normalizeManyForecastPeriodsAfterUpsert({ forecastPeriods: [forecastPeriod] });
          }),
          catchError(error => of(forecastPeriodActions.forecastPeriodsFailure({ error })))
        )
      )
    );
  });

  upsertOneForecastPeriod$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(forecastPeriodActions.upsertOneForecastPeriod),
      concatMap(
        ({ forecastPeriod, ids }: { forecastPeriod: Partial<ForecastPeriod>; ids?: ForecastPeriodRelationsIds }) => {
          if (forecastPeriod.idForecastPeriod) {
            return this.forecastPeriodApiService.updateForecastPeriod(forecastPeriod).pipe(
              map((forecastPeriodReturned: ForecastPeriod) => {
                return forecastPeriodActions.normalizeManyForecastPeriodsAfterUpsert({
                  forecastPeriods: [forecastPeriodReturned]
                });
              }),
              catchError(error => of(forecastPeriodActions.forecastPeriodsFailure({ error })))
            );
          } else {
            return this.forecastPeriodApiService.addForecastPeriod(forecastPeriod).pipe(
              mergeMap((forecastPeriodReturned: ForecastPeriod) =>
                getDefaultAddForecastPeriodActions(forecastPeriodReturned, ids)
              ),
              catchError(error => of(forecastPeriodActions.forecastPeriodsFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneForecastPeriod$ = createEffect(() => {
    const selectForecastPeriodState$ = this.store$.select(selectForecastPeriodState);
    return this.actions$.pipe(
      ofType(forecastPeriodActions.deleteOneForecastPeriod),
      withLatestFrom(selectForecastPeriodState$),
      concatMap(([{ idForecastPeriod }, state]) =>
        this.forecastPeriodApiService.deleteForecastPeriod(idForecastPeriod).pipe(
          mergeMap(_success => [
            getMultiAction(
              getDefaultDeleteForecastPeriodActions(state.entities[idForecastPeriod] as ForecastPeriodEntityState),
              forecastPeriodActions.deleteOneForecastPeriod.type
            )
          ]),
          catchError(error => of(forecastPeriodActions.forecastPeriodsFailure({ error })))
        )
      )
    );
  });

  normalizeManyForecastPeriodsAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(forecastPeriodActions.normalizeManyForecastPeriodsAfterUpsert),
      concatMap(({ forecastPeriods }) => {
        const actions: Action[] = getActionsToNormalizeForecastPeriod(forecastPeriods, StoreActionType.upsert);
        return [getMultiAction(actions, '[ForecastPeriod] Normalization After Upsert Success')];
      })
    );
  });
}
