import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import * as CompanyCommunicationLeadTodoActions from './company-communication-lead-todo.actions';
import { adapter, initialState, CompanyCommunicationLeadTodoState } from './company-communication-lead-todo.state';
import { Dictionary } from '@ngrx/entity';
import { CompanyCommunicationLeadTodoEntityState } from '@_model/interfaces/company-communication-lead-todo.model';

export const companyCommunicationLeadTodoReducersGeneratedFunctions: ReducerTypes<
  CompanyCommunicationLeadTodoState,
  readonly ActionCreator[]
>[] = [
  on(
    CompanyCommunicationLeadTodoActions.getOneCompanyCommunicationLeadTodo,
    (state: CompanyCommunicationLeadTodoState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationLeadTodoActions.getManyCompanyCommunicationLeadTodos,
    (state: CompanyCommunicationLeadTodoState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationLeadTodoActions.upsertOneCompanyCommunicationLeadTodo,
    (state: CompanyCommunicationLeadTodoState) => setLoadingsState(state, true)
  ),

  on(
    CompanyCommunicationLeadTodoActions.upsertManyCompanyCommunicationLeadTodosSuccess,
    (state: CompanyCommunicationLeadTodoState, { companyCommunicationLeadTodos }) =>
      adapter.upsertMany(companyCommunicationLeadTodos, setLoadingsState(state, false))
  ),
  on(
    CompanyCommunicationLeadTodoActions.deleteOneCompanyCommunicationLeadTodo,
    (state: CompanyCommunicationLeadTodoState) => setLoadingsState(state, true)
  ),
  on(
    CompanyCommunicationLeadTodoActions.deleteOneCompanyCommunicationLeadTodoSuccess,
    (state: CompanyCommunicationLeadTodoState, { idCompanyCommunicationLeadTodo }) =>
      adapter.removeOne(idCompanyCommunicationLeadTodo, setLoadingsState(state, false))
  ),
  on(CompanyCommunicationLeadTodoActions.clearActive, (state: CompanyCommunicationLeadTodoState) => ({
    ...state,
    actives: []
  })),
  on(
    CompanyCommunicationLeadTodoActions.addManyActives,
    (state: CompanyCommunicationLeadTodoState, { idCompanyCommunicationLeadTodos }) => ({
      ...state,
      actives: state.actives.concat(idCompanyCommunicationLeadTodos)
    })
  ),
  on(
    CompanyCommunicationLeadTodoActions.deleteOneActive,
    (state: CompanyCommunicationLeadTodoState, { idCompanyCommunicationLeadTodo }) => ({
      ...state,
      actives: state.actives.filter(id => id !== idCompanyCommunicationLeadTodo)
    })
  ),

  on(CompanyCommunicationLeadTodoActions.clearStore, () => initialState),

  on(
    CompanyCommunicationLeadTodoActions.addCompanyCommunicationSuccess,
    (state: CompanyCommunicationLeadTodoState, { idCompanyCommunicationLeadTodo, idCompanyCommunication }) => {
      if (!state.entities[idCompanyCommunicationLeadTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationLeadTodo]: {
            ...state.entities[idCompanyCommunicationLeadTodo],
            companyCommunication: idCompanyCommunication
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationLeadTodoActions.deleteManyCompanyCommunicationSuccess,
    (state: CompanyCommunicationLeadTodoState, { idCompanyCommunications, idCompanyCommunicationLeadTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationLeadTodos.reduce((entities, idCompanyCommunicationLeadTodo) => {
            if (!state.entities[idCompanyCommunicationLeadTodo]?.companyCommunication) {
              return entities;
            }
            entities[idCompanyCommunicationLeadTodo] = {
              ...state.entities[idCompanyCommunicationLeadTodo],
              companyCommunication: idCompanyCommunications.some(
                (idCompanyCommunication: number) =>
                  idCompanyCommunication === state.entities[idCompanyCommunicationLeadTodo]?.companyCommunication
              )
                ? undefined
                : state.entities[idCompanyCommunicationLeadTodo]?.companyCommunication
            } as CompanyCommunicationLeadTodoEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationLeadTodoEntityState>)
        }
      };
    }
  ),

  on(
    CompanyCommunicationLeadTodoActions.addOrganizationLeadTodoSuccess,
    (state: CompanyCommunicationLeadTodoState, { idCompanyCommunicationLeadTodo, idOrganizationLeadTodo }) => {
      if (!state.entities[idCompanyCommunicationLeadTodo]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idCompanyCommunicationLeadTodo]: {
            ...state.entities[idCompanyCommunicationLeadTodo],
            organizationLeadTodo: idOrganizationLeadTodo
          }
        }
      };
    }
  ),

  on(
    CompanyCommunicationLeadTodoActions.deleteManyOrganizationLeadTodoSuccess,
    (state: CompanyCommunicationLeadTodoState, { idOrganizationLeadTodos, idCompanyCommunicationLeadTodos }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idCompanyCommunicationLeadTodos.reduce((entities, idCompanyCommunicationLeadTodo) => {
            if (!state.entities[idCompanyCommunicationLeadTodo]?.organizationLeadTodo) {
              return entities;
            }
            entities[idCompanyCommunicationLeadTodo] = {
              ...state.entities[idCompanyCommunicationLeadTodo],
              organizationLeadTodo: idOrganizationLeadTodos.some(
                (idOrganizationLeadTodo: number) =>
                  idOrganizationLeadTodo === state.entities[idCompanyCommunicationLeadTodo]?.organizationLeadTodo
              )
                ? undefined
                : state.entities[idCompanyCommunicationLeadTodo]?.organizationLeadTodo
            } as CompanyCommunicationLeadTodoEntityState;
            return entities;
          }, {} as Dictionary<CompanyCommunicationLeadTodoEntityState>)
        }
      };
    }
  )
];

export function setLoadingsState(
  state: CompanyCommunicationLeadTodoState,
  isLoading: boolean,
  isLoaded: boolean = true
): CompanyCommunicationLeadTodoState {
  return { ...state, isLoaded, isLoading };
}
