import { ActivatedRouteSnapshot, Route } from '@angular/router';
import { DroitInterneEnum } from '@_model/enums/droit.enum';
import { ELeadSheetPages } from '@_shared/enum/pages/lead-page.enum';
import { MenuComponentData } from '@_shared/interfaces/menu-component-data.interface';
import { Lead } from '@_shared/models/interfaces/lead.model';
import { Stratalot } from '@_shared/models/interfaces/stratalot.model';
import LeadUtils from '../up-utils/lead.utils';
import { AncienModulePathEnum } from './path-enum/ancien-module-path-enum';
import { DetailLeadPathEnum } from './path-enum/detail-lead-path-enum';
import { DetailLotPathEnum } from './path-enum/detail-lot-path-enum';
import { ModulePathKeyEnum } from './path-enum/module-path-key.enum';
import { NeufModulePathEnum } from './path-enum/neuf-module-path-enum';
import { OccupedStratalotPatchEnum } from './path-enum/occuped-stratalot-patch-enum';
import { ProspectPathEnum } from './path-enum/prospect-path-enum';
import { SaleFamilyCategoryRoutingEnum } from './path-enum/sale-family-category-routing.enum';
import { SalePlanPathEnum } from './path-enum/sale-plan-path-enum';
import { UpModulePathEnum } from './path-enum/up-module-path-enum';
import SaleFamilyCategoryUtils from './sale-family-category.util';

export interface DroitPath {
  droit: DroitInterneEnum;
  path: string;
}

namespace RouterUtils {
  export function pageUrlRegex(url: string): string {
    return `^${url}/[0-9]*`;
  }

  export const droitPath: DroitPath[] = [
    {
      droit: DroitInterneEnum.accessResidenceMontagePage,
      path: `/app/ancien/${AncienModulePathEnum.salePlan}`
    },
    {
      droit: DroitInterneEnum.accessResidenceVentePage,
      path: `/app/ancien/${AncienModulePathEnum.residenceForSale}`
    },
    {
      droit: DroitInterneEnum.accessSaleInProgressPage,
      path: `/app/ancien/${AncienModulePathEnum.salesInProgress}`
    },
    {
      droit: DroitInterneEnum.accessDashboardPage,
      path: `/app/ancien/${AncienModulePathEnum.dashboard}`
    },
    {
      droit: DroitInterneEnum.accessFreeStratalotPage,
      path: `/app/ancien/${AncienModulePathEnum.freeStratalots}`
    },
    {
      droit: DroitInterneEnum.accessOccupiedStratalotPage,
      path: `/app/ancien/${AncienModulePathEnum.occupedStratalots}`
    },
    {
      droit: DroitInterneEnum.accessToValidateOfferPage,
      path: `/app/ancien/${AncienModulePathEnum.validateOffer}`
    },
    {
      droit: DroitInterneEnum.manageAdmin,
      path: '/app/admin'
    }
  ];

  export function getSaleCategoryArrayUrl(path: ModulePathKeyEnum): string[] {
    const {
      categorieVentePathEnum,
      categorieVentePath
    }: {
      categorieVentePathEnum: NeufModulePathEnum | AncienModulePathEnum;
      categorieVentePath: UpModulePathEnum;
    } = getActiveEnum();
    if (path === ModulePathKeyEnum.admin || path === ModulePathKeyEnum.user) {
      return ['app', UpModulePathEnum[path]];
    }

    if (!categorieVentePathEnum[path]) {
      throw new Error(`${path} path inexistant`);
    }
    return ['/app', categorieVentePath, categorieVentePathEnum[path]];
  }

  export function getActiveEnum(): {
    categorieVentePathEnum: NeufModulePathEnum | AncienModulePathEnum;
    categorieVentePath: UpModulePathEnum;
  } {
    const categorieVente: SaleFamilyCategoryRoutingEnum = SaleFamilyCategoryUtils.getActiveSaleFamilyCategory();
    let categorieVentePath: UpModulePathEnum;
    let categorieVentePathEnum;
    if (categorieVente === SaleFamilyCategoryRoutingEnum.ancien) {
      categorieVentePathEnum = AncienModulePathEnum;
      categorieVentePath = UpModulePathEnum.ancien;
    } else if (categorieVente === SaleFamilyCategoryRoutingEnum.neuf) {
      categorieVentePath = UpModulePathEnum.neuf;
      categorieVentePathEnum = NeufModulePathEnum;
    }
    return { categorieVentePathEnum, categorieVentePath };
  }

  export function getSaleCategoryUrl(path: ModulePathKeyEnum): string {
    return getSaleCategoryArrayUrl(path).join('/');
  }

  export function getOneStratalotDetailOnSalePlanUrl(stratalot: {
    idResidence: number;
    idStratalot: number;
  }): string[] {
    return [
      ...getSaleCategoryArrayUrl(ModulePathKeyEnum.salePlan),
      getSalePlanContext(),
      stratalot.idResidence.toString(),
      SalePlanPathEnum.lotsLocatifs,
      stratalot.idStratalot.toString(),
      DetailLotPathEnum.lotDetail
    ];
  }

  export function getProgramCommercialisationSheetUrl(
    idResidence: number,
    baseUrl: ModulePathKeyEnum = ModulePathKeyEnum.salePlan
  ): string {
    // prettier-ignore
    return `/${getSaleCategoryUrl(baseUrl)}/${idResidence}/${SalePlanPathEnum.detailList}`;
  }

  export function getOneFreeStratalotDetailUrl(idResidence: number, idStratalot: number): string[] {
    return [
      ...getSaleCategoryArrayUrl(ModulePathKeyEnum.salePlan),
      getSalePlanContext(),
      idResidence.toString(),
      SalePlanPathEnum.lotsLocatifs,
      idStratalot.toString(),
      DetailLotPathEnum.lotDetail
    ];
  }

  export function getFreeStratalotSaleStateUrl(idStratalot: number): string {
    return `${RouterUtils.getSaleCategoryUrl(ModulePathKeyEnum.freeStratalots)}/${idStratalot}/${
      DetailLotPathEnum.etatVente
    }`;
  }

  export function getOccupantDetailsUrl(idProspect: number, idStratalot: number): string {
    return `${RouterUtils.getSaleCategoryUrl(ModulePathKeyEnum.occupedStratalots)}/${idStratalot}/${idProspect}/${
      OccupedStratalotPatchEnum.occupant
    }`;
  }

  export function getProspectDetailUrl(idProspect: number, residenceId?: number): string {
    if (residenceId) {
      return `${RouterUtils.getSaleCategoryUrl(ModulePathKeyEnum.programCommercialisation)}/${residenceId}/${
        SalePlanPathEnum.prospects
      }/${idProspect}/${ProspectPathEnum.prospect}`;
    }
    return `${RouterUtils.getSaleCategoryUrl(ModulePathKeyEnum.prospects)}/${idProspect}/${ProspectPathEnum.prospect}`;
  }

  export function getSalePlanContext(): string {
    return SaleFamilyCategoryUtils.isUnbuilt() ? SalePlanPathEnum.programme : SalePlanPathEnum.residence;
  }

  export function getResidenceSheetUrl(
    idResidence: number,
    baseUrl: ModulePathKeyEnum = ModulePathKeyEnum.salePlan
  ): string {
    return `/${getSaleCategoryUrl(baseUrl)}/${getSalePlanContext()}/${idResidence}/${SalePlanPathEnum.detail}`;
  }

  export function getProgramSheetUrl(
    idResidence: number,
    baseUrl: ModulePathKeyEnum = ModulePathKeyEnum.salePlan
  ): string {
    return `/${getSaleCategoryUrl(baseUrl)}/${getSalePlanContext()}/${idResidence}/${SalePlanPathEnum.detailList}`;
  }

  export function getResidenceDiffusionUrl(idResidence: number): string {
    return `/${getSaleCategoryUrl(ModulePathKeyEnum.salePlan)}/${getSalePlanContext()}/${idResidence}/${
      SalePlanPathEnum.diffusion
    }`;
  }

  export function getStratalotValidateOfferBoardUrl(idStratalot: number): string {
    return `${RouterUtils.getSaleCategoryUrl(ModulePathKeyEnum.validateOffer)}/${idStratalot}/${
      DetailLotPathEnum.offres
    }`;
  }

  export function getOfferDetailUrl(mainStratalotId: number): string {
    return `${getSaleCategoryUrl(ModulePathKeyEnum.validateOffer)}?idStratalot=${mainStratalotId}`;
  }

  export function getLeadDetailUrl(lead: Lead): string {
    const mainStratalotId: number = LeadUtils.getMainStratalotId(lead);
    return `${getSaleCategoryUrl(ModulePathKeyEnum.freeStratalots)}/${mainStratalotId}/${DetailLotPathEnum.contacts}/${
      lead.idLead
    }/${DetailLeadPathEnum.process}`;
  }

  export function getOccupedStratalotsResidenceBoardUrl(idResidence?: number): string {
    if (idResidence) {
      return `${RouterUtils.getSaleCategoryUrl(ModulePathKeyEnum.occupedStratalots)}/${idResidence}`;
    } else {
      return `${RouterUtils.getSaleCategoryUrl(ModulePathKeyEnum.occupedStratalots)}/showAll`;
    }
  }

  export function getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot.map(v => v.url.map(segment => segment.toString()).join('/')).join('/');
  }

  export function getResolvedUrlWithParams(route: ActivatedRouteSnapshot, param: string): string {
    if (!param) {
      return getResolvedUrl(route);
    }
    return route.pathFromRoot
      .map(v =>
        v.url
          .map(segment => segment.path)
          .filter(segment => segment !== '' && segment !== param)
          .join('/')
      )
      .filter(segment => segment !== '' && segment !== param)
      .join('/');
  }

  export function commercialisationStratalotDetailUrlBySalecategory(stratalot: Stratalot): string {
    // prettier ignore
    return SaleFamilyCategoryUtils.isUnbuilt()
      ? `${getProgramCommercialisationSheetUrl(stratalot.idResidence, ModulePathKeyEnum.programCommercialisation)}/${
          stratalot.idStratalot
        }/${DetailLotPathEnum.contacts}`
      : `${RouterUtils.getSaleCategoryUrl(ModulePathKeyEnum.freeStratalots)}/${stratalot.idStratalot}`;
  }

  export function getRegexUrlBySaleCategory(): RegExp {
    return SaleFamilyCategoryUtils.isUnbuilt()
      ? new RegExp(RouterUtils.pageUrlRegex(RouterUtils.getSaleCategoryUrl(ModulePathKeyEnum.programCommercialisation)))
      : new RegExp(RouterUtils.pageUrlRegex(RouterUtils.getSaleCategoryUrl(ModulePathKeyEnum.freeStratalots)));
  }

  export const premierContactData: MenuComponentData<ELeadSheetPages> = {
    mainMenuItems: [
      {
        key: ELeadSheetPages.tache,
        route: 'tache',
        label: 'Suivi vente',
        status: 'active'
      },
      {
        key: ELeadSheetPages.contact,
        route: 'contacts',
        label: 'Contacts',
        status: 'disabled'
      },
      { key: ELeadSheetPages.lot, route: 'lot', label: 'Lots vente', status: 'disabled' },
      { key: ELeadSheetPages.documents, route: DetailLeadPathEnum.documents, label: 'Documents', status: 'disabled' }
    ],
    defaultPage: { pageKey: ELeadSheetPages.tache, racine: '' },
    breadcrumb: 'Candidat'
  };
  export const offerData: MenuComponentData<ELeadSheetPages> = {
    mainMenuItems: [
      {
        key: ELeadSheetPages.tache,
        route: 'tache',
        label: 'Suivi vente',
        status: 'active'
      },
      {
        key: ELeadSheetPages.contact,
        route: 'contacts',
        label: 'Contacts',
        status: 'disabled'
      },
      { key: ELeadSheetPages.lot, route: 'lot', label: 'Lots vente', status: 'disabled' },
      { key: ELeadSheetPages.documents, route: DetailLeadPathEnum.documents, label: 'Documents', status: 'disabled' }
    ],
    defaultPage: { pageKey: ELeadSheetPages.tache, racine: '' },
    breadcrumb: 'Offre à valider'
  };
  export const historiqueData: MenuComponentData<ELeadSheetPages> = {
    mainMenuItems: [
      {
        key: ELeadSheetPages.tache,
        route: DetailLeadPathEnum.process,
        label: 'Suivi vente',
        status: 'active'
      },
      {
        key: ELeadSheetPages.contact,
        route: DetailLeadPathEnum.contacts,
        label: 'Contacts',
        status: 'disabled'
      },
      { key: ELeadSheetPages.lot, route: DetailLeadPathEnum.lots, label: 'Lots vente', status: 'disabled' },
      { key: ELeadSheetPages.documents, route: DetailLeadPathEnum.documents, label: 'Documents', status: 'disabled' }
    ],
    defaultPage: { pageKey: ELeadSheetPages.tache, racine: '' },
    breadcrumb: 'Dossier Vente'
  };

  export function flatRoutesWithCumulativePath(routes: Route[], path = ''): any[] {
    const flatedRoutes: any[] = [];
    routes.forEach(route => {
      if (route.children) {
        flatedRoutes.push(
          ...flatRoutesWithCumulativePath(route.children, path + (path.length ? '/' : '') + route.path)
        );
      } else {
        flatedRoutes.push({ ...route, path: path + (path.length ? '/' : '') + route.path });
      }
    });
    return flatedRoutes.sort((a, b) => (a.path === 'admin' ? 1 : a.path < b.path ? -1 : a.path > b.path ? 1 : 0));
  }
}
export default RouterUtils;
