import { OrganizationLeadAvancementState, initialState } from './organization-lead-avancement.state';
import { Action, createReducer } from '@ngrx/store';
import { organizationLeadAvancementReducersGeneratedFunctions } from './organization-lead-avancement-generated.reducer';

const organizationLeadAvancementReducersFunctions = [...organizationLeadAvancementReducersGeneratedFunctions];

const organizationLeadAvancementReducer = createReducer(initialState, ...organizationLeadAvancementReducersFunctions);

export function reducer(state: OrganizationLeadAvancementState | undefined, action: Action) {
  return organizationLeadAvancementReducer(state, action);
}
