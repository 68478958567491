import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { OrganizationProfil, OrganizationProfilEntityState } from '@_model/interfaces/organization-profil.model';
import { OrganizationProfilRelationsIds } from './organization-profil-generated.effects';

export const getOneOrganizationProfil = createAction(
  '[OrganizationProfil] Get One OrganizationProfil',
  props<{ idOrganizationProfil: number; params?: any }>()
);

export const getManyOrganizationProfils = createAction(
  '[OrganizationProfil] Get Many OrganizationProfils',
  props<{ params: any }>()
);

export const addManyActives = createAction(
  '[OrganizationProfil] Add Many Actives OrganizationProfil',
  props<{ idOrganizationProfils: number[] }>()
);

export const deleteOneActive = createAction(
  '[OrganizationProfil] Delete One Active OrganizationProfil',
  props<{ idOrganizationProfil: number }>()
);

export const clearActive = createAction('[OrganizationProfil] Clear Active OrganizationProfil');

export const upsertOneOrganizationProfil = createAction(
  '[OrganizationProfil] Upsert One OrganizationProfil',
  props<{
    organizationProfil: Partial<OrganizationProfil>;
    ids?: OrganizationProfilRelationsIds;
  }>()
);

export const upsertManyOrganizationProfils = createAction(
  '[OrganizationProfil] Upsert Many OrganizationProfils',
  props<{
    organizationProfils: Partial<OrganizationProfil>[];
    ids?: OrganizationProfilRelationsIds;
  }>()
);

export const upsertManyOrganizationProfilsSuccess = createAction(
  '[OrganizationProfil] Create Many OrganizationProfils Success',
  props<{ organizationProfils: OrganizationProfilEntityState[] }>()
);

export const deleteOneOrganizationProfil = createAction(
  '[OrganizationProfil] Delete One OrganizationProfil',
  props<{ idOrganizationProfil: number }>()
);

export const deleteOneOrganizationProfilSuccess = createAction(
  '[OrganizationProfil] Delete One OrganizationProfil Success',
  props<{ idOrganizationProfil: number }>()
);

export const normalizeManyOrganizationProfilsAfterUpsert = createAction(
  '[OrganizationProfil] Normalize Many OrganizationProfils After Upsert',
  props<{ organizationProfils: OrganizationProfilEntityState[] }>()
);

export const organizationProfilsFailure = createAction(
  '[OrganizationProfil] OrganizationProfils Failure',
  props<{ error: HttpErrorResponse }>()
);

export const clearStore = createAction('[OrganizationProfil] Clear OrganizationProfils');

export const addOrganizationSuccess = createAction(
  '[OrganizationProfil] Add Organization',
  props<{ idOrganizationProfil: number; idOrganization: number }>()
);

export const deleteManyOrganizationSuccess = createAction(
  '[OrganizationProfil] Delete Many Organization',
  props<{ idOrganizations: number[]; idOrganizationProfils: number[] }>()
);

export const addCompanyPriceLabelSuccess = createAction(
  '[OrganizationProfil] Add CompanyPriceLabel',
  props<{ idOrganizationProfil: number; idCompanyPriceLabel: number }>()
);

export const deleteManyCompanyPriceLabelSuccess = createAction(
  '[OrganizationProfil] Delete Many CompanyPriceLabel',
  props<{ idCompanyPriceLabels: number[]; idOrganizationProfils: number[] }>()
);
