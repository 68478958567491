import { Injectable } from '@angular/core';

import { RepositoryService } from '@_services/api/repository.service';
import { GeneratedResidenceApiService } from './residence-api-generated.service';
import { Observable } from 'rxjs';
import { Residence, ResidenceWithSoldStratalots } from '@_shared/models/interfaces/residence.model';

@Injectable({
  providedIn: 'root'
})
export class ResidenceApiService extends GeneratedResidenceApiService {
  constructor(repo: RepositoryService) {
    super(repo);
  }

  public getResidencesByCompanies(params?: any): Observable<Residence[]> {
    return this.repo.getData<Residence[]>('residence/getResidencesByCompanies', params);
  }

  public getTinyResidenceByCompanies(params?: any): Observable<Residence[]> {
    return this.repo.getData<Residence[]>('residence/getTinyResidenceByCompanies', params);
  }

  public getForecastByResidence(idResidence: number): Observable<ResidenceWithSoldStratalots[]> {
    return this.repo.getData<ResidenceWithSoldStratalots[]>('residence/forecast/' + +idResidence, {});
  }

  public updateResidenceForecast(residence: Partial<Residence>): Observable<Residence> {
    return this.repo.update('residence/forecast', residence);
  }

  public deleteResidenceByCompany(idCompany: number): Observable<number[]> {
    return this.repo.deleteMany('residence/deleteByCompany/' + +idCompany);
  }

  public getResidencesForSales(params?: any): Observable<Residence[]> {
    return this.repo.getData<Residence[]>('residence/forSales', params);
  }

  public getResidencesOccupants(params?: any): Observable<Residence[]> {
    return this.repo.getData<Residence[]>('residence/getResidencesOccupation', params);
  }

  public getResidencesMontage(params?: any): Observable<Residence[]> {
    return this.repo.getData<Residence[]>('residence/montage', params);
  }

  public activateResidenceFromCampaign({
    idCompany,
    envoiUbiflowDateDebut,
    envoiUbiflowDateFin,
    idResidence
  }): Observable<Residence> {
    return this.repo.update('residence/' + idResidence + '/activateUbiflow', {
      idCompany,
      envoiUbiflowDateDebut,
      envoiUbiflowDateFin
    });
  }

  public calculateTodosForAllResidence(): Observable<any> {
    return this.repo.create('residence/calculateTodosForAllResidence', {});
  }

  public insertMissingResidenceSaleCategoryFamily(): Observable<any> {
    return this.repo.create('residence/insertMissingResidenceSaleCategoryFamily', {});
  }
}
